<div class="slideout-content default-slideout">
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
		<div class="modal-dialog sidebar-sm">
			<form class="modal-content pt-0" #tagAddForm="ngForm">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">{{ UIEvent }}</h5>
					<button
						id="btnClose"
						name="close"
						type="button"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						(click)="toggleSidebar(tagAddForm)"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body flex-auto-height">
					<div class="flex-1">
						<ng-scrollbar class="custom-scrollbar section-scrollbar">
							<div class="container-fluid py-1">
									<div *ngIf="event == 'Select'" class="col-md-12">
										<div class="form-group">
											<label for="selTag">{{'UI.k_select_tag' | translate}}<span class="required-field">*</span></label>
											<!-- <span id="btnAddTag" class="pull-right" (click)="onCreateTag()">
												<i class="fa-light fa-plus-circle mb-0 mr-sm-25 mr-0 pull-right"></i>
											</span> -->
											<ng-select
												required
												[(ngModel)]="selectedTags"
												#TDSelTypeRef="ngModel"
												[multiple]="true"
												[searchable]="true"
												[closeOnSelect]="true"
												[items]="tags"
												[searchable]="true"
												bindLabel="name"
												bindValue="tag_id"
												hideSelected="true"
												placeholder="{{'UI.k_select_tag' | translate}}"
												notFoundText="{{
													'UI.k_no_items_found' | translate
												  }}"
												name="selTag"
												id="selTag"
												[addTag]="selectAddTagMethod"
												addTagText="{{ 'UI.k_add_tag' | translate }}"
												[class.error]="!TDSelTypeRef.valid && tagAddForm.submitted"
											>
											</ng-select>
											<span
												*ngIf="tagAddForm.submitted && TDSelTypeRef.invalid"
												class="invalid-form"
											>
												<small
													class="form-text text-danger"
													*ngIf="TDSelTypeRef.errors.required"
													>{{"UI.k_field_required" | translate}}</small
												>
											</span>
										</div>
									</div>
							</div>
						</ng-scrollbar>
					</div>
					<div class="flex-initial">
						<div class="d-flex justify-content-end border-top p-1">
							<button
								(click)="onSubmit(tagAddForm)"
								type="submit"
								class="btn btn-primary"
								rippleEffect
								id="btnTagSave"
								name="btnTagSave"
							>
							<i class="fa-light fa-save align-middle mr-50 ml-0"></i>
								<span class="align-middle d-sm-inline-block d-none"
									>{{ label}} 
									
								</span>
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>

<ng-template #modAddTagPopup let-modal>
	<div class="modal-header">
	  <h4 class="modal-title" id="myModalLabel1">
		{{ "UI.k_add_tag" | translate }}
	  </h4>
	  <button
		id="btnClose"
		name="btnClose"
		data-dismiss="modal"
		aria-label="Close"
		(click)="modal.dismiss('Cross click')"
		class="cursor-pointer btn btn-flat-secondary p-1"
	  >
		<i class="fa-light fa-xmark mb-0 mr-sm-25 mr-0 pull-right"></i>
	  </button>
	</div>
	<div class="modal-body" tabindex="0" ngbAutofocus>
	  <form #addTagForm="ngForm">
		<div class="row mb-2">
		  <div class="col-xs-12 col-md-6 col-sm-6 col-lg-6">
			<div class="form-group">
			  <label for="txtTName"
				>{{ "UI.k_tag_name" | translate
				}}<span class="required-field">&nbsp;*</span></label
			  >
			  <input
				#TDTagNameRef="ngModel"
				required
				type="text"
				class="form-control"
				placeholder="{{ 'UI.k_tag_name' | translate }}"
				name="grpTagName"
				[(ngModel)]="tag.name"
				id="txtTName"
				minlength="3"
				maxlength="40"
				[appWhiteSpaceCheck]="tag?.name"
				[class.error]="!TDTagNameRef.valid && addTagForm.submitted"
			  />
			  <span
				*ngIf="addTagForm.submitted && TDTagNameRef.invalid"
				class="invalid-form"
			  >
				<small
				  class="form-text text-danger font-small-1"
				  *ngIf="TDTagNameRef.errors.required"
				  >{{ "UI.k_field_required" | translate }}</small
				>
				<small
				  class="form-text text-danger font-small-1"
				  *ngIf="
					TDTagNameRef.errors.minlength || TDTagNameRef.errors.maxlength
				  "
				>
				  {{ "UI.k_tag_validation" | translate }}</small
				>
				<small
				  class="form-text text-danger font-small-1"
				  *ngIf="TDTagNameRef.errors?.whitespace"
				  >{{ "UI.k_leading_trailing_space_validation" | translate }}
				</small>
			  </span>
			</div>
		  </div>
		  <div class="col-xs-12 col-md-6 col-sm-6 col-lg-6">
			<div class="form-group">
				<label for="selTagType">{{"UI.k_type" | translate}}<span class="required-field">*</span></label>
				<ng-select
					[(ngModel)]="tag.type"
					#TDTypeRef="ngModel"
					[items]="tag_type_list"
					[closeOnSelect]="true"
					[searchable]="true"
					bindLabel="name"
					bindValue="id"
					placeholder="{{'UI.k_sel_type' | translate}}"
					name="selTagType"
					id="selTagType"
					disabled
				>
				</ng-select>
			</div>
		  </div>
		</div>
		<div class="d-flex pull-right">
		  <div class="mr-1">
			<button
			(click)="onCreateTag(addTagForm)"
			  type="submit"
			  class="btn btn-primary"
			  rippleEffect
			  id="btnAcntSave"
			  name="btnAcntSave"
			  [disabled]="is_tag_save_disabled"
			>
			<i class="fa-light fa-save align-middle mr-50 ml-0"></i>
			  <span class="align-middle d-sm-inline-block d-none">{{
				"UI.k_submit" | translate
			  }}</span>
			</button>
		  </div>
		</div>
	  </form>
	</div>
  </ng-template>
