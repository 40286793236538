<div class="item-sec">
	<div class="current-selection">
		<ng-container *ngFor="let option of elemOption">
			<ng-container *ngIf="option.isActive === true">
				<div class="badge badge-light-{{ option.color }}">
					{{ option.name }}
				</div>
			</ng-container>
		</ng-container>
		<div class="item-list card-bg">
			<ng-container *ngFor="let option of elemOption">
				<div class="current-element" (click)="changeEvent(option)">
					<div
						class="badge badge-light-{{ option?.color }}"
						[ngClass]="{
							selected: option.isActive === true
						}"
					>
						{{ option.name }}
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>
