<div>
	<div class="row" *ngFor="let res of result; let i = index" [attr.data-index]="i" @heightIn>
		<div class="d-flex justify-content-end col-md-12 col-sm-12 col-xs-12">
			<span class="mb-1 cursor-pointer" (click)="addRemove(i)">
				<i
					class="fa-light fa-circle-plus text-primary"
					placement="left"
					ngbTooltip="{{ 'UI.k_add' | translate }}"
					*ngIf="i == 0 && options?.length > result?.length"
				></i>
				<i
					class="fa-light fa-circle-minus text-danger"
					placement="left"
					ngbTooltip="{{ 'UI.k_delete' | translate }}"
					*ngIf="i > 0"
				></i>
			</span>
		</div>
		<div class="col-xs-12 col-6">
			<div class="form-group">
				<label> {{ "UI.k_operand" | translate }}{{ isRequired ? "*" : "" }} </label>
				<ng-select
					[items]="options"
					[closeOnSelect]="true"
					[searchable]="true"
					bindLabel="name"
					bindValue="macro"
					name="ngSelectSource{{ i }}"
					id="ngSelectSource{{ i }}"
					#TDSoruce="ngModel"
					(change)="onChange($event, i)"
					[clearable]="false"
					[required]="isRequired"
					notFoundText="{{ 'UI.k_no_items_found' | translate }}"
					placeholder="{{ 'UI.k_select_operand_placeholder' | translate }}"
					[(ngModel)]="res.operand"
				></ng-select>
				<span class="error" *ngIf="TDSoruce?.invalid && dataForm?.submitted">
					{{ "UI.k_field_required" | translate }}
				</span>
				<span
					class="error"
					*ngIf="this.conditionsOptions['rowConditions' + i]?.is_duplicate && dataForm?.submitted"
				>
					{{ "Message.msg_duplicate_not_allow" | translate }}
				</span>
			</div>
		</div>
		<div class="col-xs-12 col-6">
			<div class="form-group">
				<dns-option-picker
					[inputConfig]="{
						isTextMode: true,
						bindLabel: conditionsOptions['rowConditions' + i]?.bindKey || 'name',
						bindValue: conditionsOptions['rowConditions' + i]?.bindValue || '',
						notFoundText: ('UI.k_no_items_found' | translate),
						id: 'ngSelectCondition' + i,
						header: ('UI.k_value' | translate),
						name: 'ngSelectCondition' + i,
						placeholder: ('UI.k_select_value' | translate)
					}"
					[options]="conditionsOptions['rowConditions' + i]?.options"
					[isclearable]="false"
					(setValue)="onChange($event, i)"
					[value]="res.value"
					[is_required]="isRequired"
					[form_ref]="dataForm"
				></dns-option-picker>
				<!--				<ng-select-->
				<!--					[items]="conditionsOptions['rowConditions' + i]?.options"-->
				<!--					[closeOnSelect]="true"-->
				<!--					name="ngSelectCondition{{ i }}"-->
				<!--					[bindLabel]="conditionsOptions['rowConditions' + i]?.bindKey || 'name'"-->
				<!--					[bindValue]="conditionsOptions['rowConditions' + i]?.bindValue || ''"-->
				<!--					notFoundText="{{ 'UI.k_no_items_found' | translate }}"-->
				<!--					id="ngSelectCondition{{ i }}"-->
				<!--					(change)="onChange($event, i)"-->
				<!--                    #TDVal="ngModel"-->
				<!--					[required]="isRequired"-->
				<!--					placeholder="{{ 'UI.k_select_value_placeholder' | translate }}"-->
				<!--					[(ngModel)]="res.value"-->
				<!--				></ng-select>-->
				<!--				<span class="error" *ngIf="TDVal.invalid && dataForm?.submitted">-->
				<!--					{{ "UI.k_field_required" | translate }}-->
				<!--				</span>-->
			</div>
		</div>
	</div>
</div>
