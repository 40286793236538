import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileAvatarRoutingModule } from './profile-avatar-routing.module';
import { ProfileAvatarComponent } from './components/profile-avatar/profile-avatar.component';
import { ImgUploadFeatureModule } from '../img-upload-feature/img-upload-feature.module';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [
    ProfileAvatarComponent,
  ],
  imports: [
    CommonModule,
    ProfileAvatarRoutingModule,
    ImgUploadFeatureModule,
    NgbNavModule,
    CorePipesModule,
    FormsModule,
    TranslateModule

  ],
  exports: [ProfileAvatarComponent]
})
export class ProfileAvatarModule { }
