import { CoreMenu } from "@core/types";

export const menu: CoreMenu[] = [
	// {
	// 	id: "setting",
	// 	title: "Getting Started",
	// 	type: "item",
	// 	icon: "getting-started",
	// 	url: "get-started",
	// 	permission: {
	// 		moduleList: [
	// 			"organization",
	// 			"organization_location",
	// 			"user",
	// 			"userroles",
	// 		],
	// 		keyList: ["add", "edit", "delete", "view"],
	// 	},
	// 	// hidden: false,
	// 	// hidden: checkPermission(['organization', 'organization_location', 'user', 'userroles'], ['add', 'edit', 'delete', 'view']),
	// },
	// Workspace
	{
		id: "workspace",
		title: "UI.k_my_workspace",
		subTitle: "Beta",
		type: "item",
		icon: "workspace",
		url: "workspace",
		permission: {
			moduleList: ["workspace"],
			keyList: ["view", "add", "edit"],
		},
		license: {
			module_key: "SERVICEDESK",
			keys: ["WORKSPACE"],
		},
	},
	//Dashboard
	{
		id: "dashboard",
		title: "UI.k_dashboard",
		// translate: 'MENU.DASHBOARD.COLLAPSIBLE',
		type: "item",
		icon: "dashboard",
		position: "top",
		url: "dashboard",
		permission: {
			moduleList: ["dashboard"],
			keyList: ["add", "edit", "delete", "view"],
		},
		license: {
			module_key: "BASE",
			keys: ["DASHBOARD"],
		},
		// children: [
		// 	{
		// 		id: "allDashboard",
		// 		title: "UI.k_all_dashboard",
		// 		// translate: 'MENU.DASHBOARD.ANALYTICS',
		// 		type: "item",
		// 		url: "dashboard",
		// 		permission: {
		// 			moduleList: ["dashboard"],
		// 			keyList: ["view"],
		// 		},
		// 	},
		// 	//{
		// 	//	id: "addDashboard",
		// 	//	title: "UI.k_add_dashboard",
		// 	// translate: 'MENU.DASHBOARD.ANALYTICS',
		// 	//	type: "item",
		// 	//	url: "dashboard/add",
		// 	//	permission: {
		// 	//		moduleList: ["dashboard"],
		// 	//		keyList: ["add", "edit", "delete", "view"],
		// 	//	},
		// 	//},
		// ],
	},
	// Assets
	{
		id: "Assets",
		title: "UI.k_assets",
		type: "collapsible",
		icon: "asset",
		position: "top",
		// hidden: false,
		permission: {
			moduleList: ["cmdb"],
			keyList: ["add", "edit", "delete", "view"],
		},
		//hidden: checkPermission(['cmdb'], ['add', 'edit', 'delete', 'view']),
		children: [
			{
				id: "itAssets",
				title: "UI.k_it_assets",
				type: "item",
				url: "it-asset",
				permission: {
					moduleList: ["cmdb"],
					keyList: ["add", "edit", "delete", "view"],
				},
				license: {
					module_key: "ASSET",
					keys: ["ASSET_IT"],
				},
			},
			{
				id: "fixedAssets",
				title: "UI.k_fixed_assets",
				type: "item",
				url: "fixed-asset",
				permission: {
					moduleList: ["cmdb"],
					keyList: ["add", "edit", "delete", "view"],
				},
				license: {
					module_key: "ASSET",
					keys: ["ASSET_FIXED"],
				},
			},
			{
				id: "consumableAssets",
				title: "UI.k_consumable_assets",
				type: "item",
				url: "consumable-asset",
				// subTitle: "Beta",
				permission: {
					moduleList: ["cmdb"],
					keyList: ["add", "edit", "delete", "view"],
				},
				license: {
					module_key: "ASSET",
					keys: ["ASSET_CONSUMABLE"],
				},
				featureKeys: ["Consumable"],
			},
			// commented for beta relese
			{
				id: "softwareAssets",
				title: "UI.k_software",
				type: "item",
				url: "/software-inventory/inventory",
				permission: {
					moduleList: ["cmdb"],
					keyList: ["add", "edit", "delete", "view"],
				},
				license: {
					module_key: "ASSET",
					keys: ["ASSET_SOFTWARE"],
				},
			},
			{
				id: "softwareLicense;",
				title: "UI.k_software_license",
				type: "item",
				url: "/software-inventory/license",
				permission: {
					moduleList: ["software_license"],
					keyList: ["add", "edit", "delete", "view"],
				},
				license: {
					module_key: "ASSET",
					keys: ["ASSET_SOFTWARE"],
				},
			},
			{
				id: "cmdbci",
				title: "UI.k_cmdb_view",
				type: "item",
				url: "cmdb-view",
				permission: {
					moduleList: ["cmdb"],
					keyList: ["add", "edit", "delete", "view"],
				},
				license: {
					module_key: "ASSET",
					keys: ["ASSET_IT", "ASSET_FIXED"],
				},
			},
		],
	},
	// contract management
	{
		id: "contract",
		title: "UI.k_contract_management",
		type: "item",
		icon: "contract",
		url: "contract-management",
		// subTitle: "Beta",
		permission: {
			moduleList: ["contract"],
			keyList: ["add", "edit", "delete", "view"],
		},
		license: {
			module_key: "BASE",
			keys: ["CONTRACT_MANAGEMENT"],
		},
		// featureKeys: ["ContractManagement"],
	},
	// Purchase Order Management
	{
		id: "purchase_order",
		title: "UI.k_purchase_order",
		type: "item",
		icon: "purchaseorder",
		url: "purchase-order",
		subTitle: "Beta",
		permission: {
			moduleList: ["purchase_order"],
			keyList: ["add", "edit", "delete", "view"],
		},
		license: {
			module_key: "BASE",
			keys: ["PO_MANAGEMENT"],
		},
		featureKeys: ["PurchaseOrderManagement"],
	},
	{
		id: "incident",
		title: "UI.k_incident",
		type: "item",
		icon: "ticket",
		url: "incident_view",
		// hidden: false,
		permission: {
			moduleList: ["incident"],
			keyList: ["add", "edit", "delete", "view"],
		},
		license: {
			module_key: "SERVICEDESK",
			keys: ["INCIDENT_MANAGEMENT"],
		},
		// hidden: checkPermission(['incident'], ['add', 'edit', 'delete', 'view']),
	},
	{
		id: "imacd",
		title: "UI.k_imacd",
		type: "item",
		icon: "imacd",
		url: "imacd",
		// subTitle: "Beta",
		// hidden: false,
		permission: {
			moduleList: ["imacd"],
			keyList: ["add", "edit", "delete", "view"],
		},
		license: {
			module_key: "BASE",
			keys: ["IMACD"],
		},
		featureKeys: ["IMACD"],
		// hidden: checkPermission(['incident'], ['add', 'edit', 'delete', 'view']),
	},
	// request
	{
		id: "request",
		title: "UI.k_request",
		type: "item",
		icon: "request",
		url: "request_list",
		// hidden: false,
		permission: {
			moduleList: ["request_process"],
			keyList: ["add", "edit", "delete", "view"],
		},
		license: {
			module_key: "SERVICEDESK",
			keys: ["REQUEST_MANAGEMENT"],
		},
	},
	//problem
	{
		id: "problem",
		title: "UI.k_problem",
		type: "item",
		icon: "problem",
		url: "problem_management",
		// hidden: false,
		permission: {
			moduleList: ["problem"],
			keyList: ["add", "edit", "delete", "view"],
		},
		license: {
			module_key: "SERVICEDESK",
			keys: ["PROBLEM_MANAGEMENT"],
		},
		// hidden: checkPermission(['incident'], ['add', 'edit', 'delete', 'view']),
	},
	{
		id: "change",
		title: "UI.k_change",
		type: "item",
		icon: "change",
		url: "change",
		permission: {
			moduleList: ["change"],
			keyList: ["add", "edit", "delete", "view"],
		},
		license: {
			module_key: "SERVICEDESK",
			keys: ["CHANGE_MANAGEMENT"],
		},
	},
	{
		id: "release",
		title: "UI.k_release",
		type: "item",
		icon: "release-manage",
		url: "rel",
		permission: {
			moduleList: ["release"],
			keyList: ["add", "edit", "delete", "view"],
		},
		license: {
			module_key: "SERVICEDESK",
			keys: ["RELEASE_MANAGEMENT"],
		},
	},
	// Events
	{
		id: "events",
		title: "UI.k_events",
		type: "item",
		icon: "notification",
		url: "events",
		// hidden: false,
		// hidden: checkPermission(['events'], ['edit', 'view']),
		permission: { moduleList: ["events"], keyList: ["edit", "view"] },
		license: {
			module_key: "MONITORING",
			keys: ["EVENT_MANAGEMENT"],
		},
	},
	{
		id: "reports",
		title: "UI.k_report",
		type: "item",
		icon: "report-file",
		url: "report",
		// hidden: false,
		// hidden: checkPermission(['events'], ['edit', 'view']),
		permission: { moduleList: ["report"], keyList: ["view"] },
		license: {
			module_key: "BASE",
			keys: ["REPORT"],
		},
	},
	{
		id: "kb",
		title: "UI.k_knowledgebase",
		type: "item",
		icon: "book",
		url: "kb/all",
		// hidden: false,
		// hidden: checkPermission(['knowledgebase'], ['add', 'edit', 'delete', 'view']),
		permission: {
			moduleList: ["knowledgebase"],
			keyList: ["add", "edit", "delete", "view"],
		},
		license: {
			module_key: "BASE",
			keys: ["KNOWLEDGE_BASE"],
		},
	},
	// SLA
	{
		id: "sla",
		title: "UI.k_sla",
		type: "collapsible",
		icon: "sla",
		position: "top",
		permission: { moduleList: ["sla"], keyList: ["add", "edit", "delete", "view"] },
		license: {
			module_key: "BASE",
			keys: ["SLA"],
		},
		children: [
			{
				id: "slaProfile",
				title: "UI.k_profile",
				type: "item",
				url: "sla/profile",
				permission: { moduleList: ["sla"], keyList: ["add", "edit", "delete", "view"] },
				license: {
					module_key: "BASE",
					keys: ["SLA"],
				},
				// license: {
				// 	module_key: "BASE",
				// 	keys: [],
				// },
			},
			{
				id: "slaMetric",
				title: "UI.k_metric",
				type: "item",
				url: "sla/metric",
				license: {
					module_key: "BASE",
					keys: ["SLA"],
				},
				permission: { moduleList: ["sla"], keyList: ["add", "edit", "delete", "view"] },
				// license: {
				// 	module_key: "BASE",
				// 	keys: [],
				// },
			},
		],
	},
	// {
	// 	id: "reports",
	// 	title: "Reports",
	// 	type: "item",
	// 	icon: "report-file",
	// 	url: "report",
	// 	hidden: false,
	// },

	{
		id: "geomap",
		title: "UI.k_geomap",
		type: "item",
		icon: "geomap",
		url: "geomap",
		permission: {
			moduleList: ["geomap"],
			keyList: ["add", "edit", "delete", "view"],
		},
		// license: {
		// 	module_key: "BASE",
		// 	keys: ["GEOMAP"],
		// },
		// featureKeys: ["Geomap"],
	},

	{
		id: "networkdiagram",
		title: "UI.k_networkdiagram",
		type: "item",
		icon: "network",
		url: "networkdiagram",
		// subTitle: "Beta",
		permission: {
			moduleList: ["network_diagram"],
			keyList: ["add", "edit", "delete", "view"],
		},
		license: {
			module_key: "BASE",
			keys: ["NETWORK_DIAGRAM"],
		},
		// featureKeys: ["NetworkDiagram"],
	},
	{
		id: "topology",
		title: "UI.k_topology",
		type: "item",
		url: "topology/view",
		icon: "topology",
		position: "top",
		permission: {
			moduleList: ["topology"],
			keyList: ["view"],
		},
		license: {
			module_key: "BASE",
			keys: ["TOPOLOGY"],
		},
		// featureKeys: ["Topology"],

		// children: [
		// 	{
		// 		id: "topologyView",
		// 		title: "UI.k_topology_view",
		// 		type: "item",
		// 		url: "topology/view",
		// 		permission: {
		// 			moduleList: ["topology"],
		// 			keyList: ["view"],
		// 		},
		// 	},
		// 	{
		// 		id: "topologyLinks",
		// 		title: "UI.k_topology_links",
		// 		type: "item",
		// 		url: "topology/links",
		// 		permission: {
		// 			moduleList: ["topology"],
		// 			keyList: ["view"],
		// 		},
		// 	},
		// ],
	},

	{
		id: "infraonConf",
		title: "UI.k_infron_conf",
		type: "item",
		icon: "settings",
		url: "configuration",
		// hidden: false,
		permission: {
			moduleList: [
				"agent",
				"api_register",
				"audit",
				"biz_hour",
				"businesscatalogue",
				"department",
				"device_profile",
				"discovery",
				"leaves",
				"messenger",
				"organization",
				"organization_location",
				"requester",
				"servicecatalogue",
				"tag",
				"teams",
				"user",
				"userroles",
				"workflow",
				"sms_config",
				"bots_configuration",
			],
			keyList: ["add", "edit", "delete", "view"],
		},
		// hidden: checkPermission(['agent', 'api_register', 'audit', 'biz_hour', 'businesscatalogue','department',
		// 'device_profile', 'discovery', 'leaves', 'messenger', 'organization', 'organization_location', 'requester',
		// 'servicecatalogue', 'tag', 'teams', 'user', 'userroles', 'workflow'],  ['add', 'edit', 'delete', 'view', 'approve', 'copy', 'config']),
	},
	{
		id: "marketplace",
		title: "UI.k_marketplace",
		subTitle: "Beta",
		type: "item",
		icon: "marketplace",
		url: "marketplace",
		permission: {
			moduleList: ["marketplace"],
			keyList: ["add", "edit", "delete", "view"],
		},
		// featureKeys: ["MarketPlace"],
	},
];
