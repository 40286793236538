<div class="slideout-content default-slideout" id="modals-slide-in">
	<div class="modal-dialog">
		<div class="modal-content add-new-user pt-0">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">
					{{  header_label | translate }}
					<span *ngIf="task_obj?.display_id" class="feature-view-title">{{task_obj?.display_id}}</span>
				</h5>
				<div class="d-flex">
					<button
						type="button"
						id="btnClose"
						name="btnClose"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						(click)="toggleSidebar()"
						[attr.data-testid]="'btnClose'" 
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
			</div>
			<div class="modal-body flex-1">
				<form #newTaskForm="ngForm" class="flex-auto-height">
					<ng-scrollbar class="custom-scrollbar section-scrollbar">
						<div
							class="input-group"
							style="width: 98%; margin: 0 auto; margin-top: 10px; margin-bottom: 10px"
						>
							<div class="input-group-prepend view-task-title-section">
								<span class="input-group-text" id="title">{{ "UI.k_title" | translate }}&nbsp;*</span>
							</div>
							<input
								type="text"
								class="form-control"
								name="txtTitle"
								id="txtTitle"
								[attr.data-testid]="'txtTitle'" 
								placeholder="Type title here"
								aria-label="txtTitle"
								aria-describedby="basic-addon1"
								required
								minlength="3"
								maxlength="1000"
								[(ngModel)]="task_obj.name"
								#submitVlanNameInput="ngModel"
								[class.error]="submitVlanNameInput.invalid && newTaskForm.submitted"
								[appWhiteSpaceCheck]="task_obj.name"
							/>
						</div>
						<span class="invalid-form" *ngIf="newTaskForm.submitted && submitVlanNameInput.invalid">
							<small class="col-12 form-text text-danger" *ngIf="submitVlanNameInput.errors.required"
								>{{ "UI.k_field_required" | translate }}
							</small>
							<small
								class="col-12 form-text text-danger"
								*ngIf="submitVlanNameInput.errors.minlength || submitVlanNameInput.errors.maxlength"
								>{{ "UI.k_title_name_validation" | translate }}
							</small>
						</span>
						<div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
							<div class="row">
								<div class="col-xs-4 col-md-4 col-xl-4 col-lg-4">
									<app-status-picker
										[inputConfig]="{
											isTextMode: true,
											required: true
										}"
										[options]="options?.status"
										(setStatus)="onStatusChange($event)"
										[value]="task_obj?.status?.id"
									>
									</app-status-picker>
								</div>
								<div class="col-xs-4 col-md-4 col-xl-4 col-lg-4" style="padding: 0">
									<div class="panel-edit-select-box">
										<label>{{ "UI.k_priority" | translate }}</label>
										<app-select-badge
											[inputConfig]="{
												hideLabel: true,
												inputOptions: options?.task_priority,
												id: 'priority',
												model: this.task_obj?.priority?.id
											}"
											(onValueChange)="statusBadgeChange($event)"
										></app-select-badge>
									</div>
								</div>
								<div class="col-xs-4 col-md-4 col-xl-4 col-lg-4">
									<div class="panel-edit-select-box">
										<label for="selAssignee" class="form-label">{{
											"UI.k_assignee" | translate
										}}</label>
										<ng-select
											[items]="options?.users"
											[searchable]="true"
											bindLabel="full_name"
											[searchFn]="customSearchFn"
											bindValue="profile_id"
											name="selAssignee"
											id="selAssignee"
											placeholder="{{ 'UI.k_select' | translate }}"
											class=""
											[(ngModel)]="task_obj.assignee"
											(change)="onAssigneeChange($event)"
										>
											<ng-template
												ng-option-tmp
												let-item="item"
												let-index="index"
												let-search="searchTerm"
											>
												<span class="d-flex">
													<div *ngIf="item?.profile_image">
														<div class="avatar mr-50 ml-25">
															<span class="avatar-content">
																<img
																	class="rounded-circle"
																	src="{{ item.profile_image }}"
																	alt="User"
																	width="32"
																	height="32"
																/>
															</span>
														</div>
													</div>
													<div *ngIf="!item?.profile_image">
														<div
															class="avatar mr-50 ml-25"
															[ngStyle]="{
																'background-color': item?.avatar_color
																	? item?.avatar_color
																	: '#f59518'
															}"
															style="align-content: center; width: 32px; height: 32px"
														>
															<div class="avatar-content">
																{{ item.full_name | initials | slice: 0:2 }}
															</div>
														</div>
													</div>

													<div class="cell-line-height">
														<p class="font-medium-1 font-weight-bold line-height-1 mb-0">
															{{ item?.full_name | slice: 0:28 }}
															{{ item?.full_name?.length > 28 ? ".." : "" }}
														</p>
														<span class="text-muted font-small-2">
															{{ item?.email | slice: 0:28 }}
															{{ item?.email.length > 28 ? ".." : "" }}</span
														>
													</div>
												</span>
											</ng-template>
										</ng-select>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="!is_ssp_login || (is_ssp_login && !is_my_task)" class="col-xs-12 col-md-12 col-xl-12 col-lg-12" style="margin-bottom: 10px">
							<div class="row">
								<div class="col-xs-4 col-md-4 col-xl-4 col-lg-4">
									<div class="panel-edit-select-box">
										<label for="taskDueDate">{{ "UI.k_due_date" | translate }} </label>
										<div class="input-group custom-input-group">
											<ng2-flatpickr
												#datepicker
												[config]="basicDateTimeOptions"
												name="Basic Date Picker"
												id="txtDueDate"
												[(ngModel)]="task_obj.due_date"
												[setDate]="task_obj.due_date"
												placeholder="{{ 'UI.k_placeholder_yyyy_mm_dd' | translate }}"
											>
											</ng2-flatpickr>
											<div class="input-group-append">
												<button
													class="btn btn-outline-secondary feather icon-calendar"
													(click)="datepicker.flatpickr.toggle()"
													type="button"
													rippleEffect
												></button>
											</div>
										</div>
									</div>
								</div>
								<ng-container *appFeatureActive="['RecurringTask']">
									<div class="col-xs-4 col-md-4 col-xl-4 col-lg-4 px-0">
										<div class="panel-edit-select-box">
											<div class="form-group">
												<label for="selServiceState" class="form-label">
													{{ "UI.k_repeat" | translate }}</label
												>
												<ng-select
													bindLabel="name"
													bindValue="key"
													class="form-control"
													name="selRepeat"
													id="selRepeat"
													[(ngModel)]="task_obj.repeat_config.repeat"
													(change)="onRepeatChange($event)"
													[attr.data-testid]="'selRepeat'" 
													placeholder="{{ 'UI.k_select' | translate }}"
													[items]="repeatOption"
												>
												</ng-select>
											</div>
										</div>
									</div>
									<div class="col-xs-4 col-md-4 col-xl-4 col-lg-4 d-flex align-items-center">
										<ng-container *ngIf="task_obj?.repeat_config?.repeat !== 'no_repeat'">
											<div class="panel-edit-select-box d-flex align-items-center gap-50 full-height mt-75">
												<div class="custom-control custom-radio">
													<input type="radio" id="radForever" name="repeatType" value="forever" (change)="changeRepeatType($event)" class="custom-control-input" [checked]="task_obj?.repeat_config?.repeat_type == 'forever'"/>
													<label class="custom-control-label" for="radForever">{{ "UI.k_forever" | translate }}</label>
												</div>
												<div class="custom-control custom-radio">
													<input type="radio" id="radUntil" name="repeatType" value="until" (change)="changeRepeatType($event)" class="custom-control-input" [checked]="task_obj?.repeat_config?.repeat_type == 'until'"/>
													<label class="custom-control-label" for="radUntil">
														<span>{{ "UI.k_until" | translate }}</span>
													</label>
												</div>
												<ng-container *ngIf="task_obj?.repeat_config?.repeat_type == 'until'">
													<ng2-flatpickr
													#untillDatepicker
													[config]="untillDateTimeOptions"
													name="Until Date Picker"
													id="txtUntillDate"
													[(ngModel)]="task_obj.repeat_config.until_date"
													[setDate]="task_obj.repeat_config.until_date"
													placeholder="{{ 'UI.k_placeholder_yyyy_mm_dd' | translate }}"
												>
												</ng2-flatpickr>
												</ng-container>
											</div>
										</ng-container>
									</div>
								</ng-container>
							</div>
						</div>

						<div *ngIf="is_ssp_login && is_my_task" class="col-xs-12 col-md-12 col-xl-12 col-lg-12" style="margin-bottom: 10px">
							<div class="row">
								<div class="col-xs-4 col-md-4 col-xl-4 col-lg-4">
									<div class="" >
										<div class="search-info">
											<label for="txtInciImpact" class="add-user-label w-100 reg-font fw-500"
												>{{ "UI.k_assignee" | translate}}
											</label>
											<div *ngIf="isshownAssignee" class="requester-detail d-flex justify-content-between align-items-center">
												<div class="d-flex align-items-center mb-1">
													<div class="avatar mr-half">
														<div
															class="avatar"
															*ngIf="task_obj?.assignee_info?.profile_image"
														>
															<img
																[src]="task_obj?.assignee_info?.profile_image"
																alt="Avatar"
																width="32"
																height="32"
															/>
														</div>
														<div
															*ngIf="!task_obj?.assignee_info?.profile_image"
															id="divInitial"
															name="divInitial"
															[attr.data-testid]="'divInitial'"
															class="avatar-content"
															[ngStyle]="{
																'background-color':
																task_obj?.assignee_info?.avatar_color
																		? task_obj?.assignee_info?.avatar_color
																		: '#f59518'
															}"
														>
															{{
																task_obj?.assignee_info?.full_name
																	| initials
																	| slice: 0:2
															}}
														</div>
													</div>
													<div>
														<div
															class="fw-500 text-dark mb-0 text-break"
															id="divFullname"
															name="divFullname"
															[attr.data-testid]="'divFullname'">
															{{ task_obj?.assignee_info?.full_name }}
														</div>
														<span class="text-break" id="spEmail" name="spEmail" [attr.data-testid]="'spEmail'">{{ task_obj?.assignee_info?.email }}</span>
													</div>
												</div>
												<button 
													ngbTooltip="{{ 'UI.k_remove' | translate }}" 
													type="button" 
													id="btnRemoveRequester" 
													name="btnRemoveRequester" 
													[attr.data-testid]="'btnRemoveRequester'" 
													class="btn btn-sm btn-icon btn-icon btn-flat-danger" 
													*ngIf="!is_my_task"
													rippleEffect 
													(click)="removeAssignee()">
													<i class="fa-light fa-xmark lg-font fw-500"></i>
												</button>
											</div>

											<div *ngIf="!isshownAssignee" class="input-group input-group-merge">
												<input
													type="text"
													[(ngModel)]="searchStr"
													name="txtUserReqSearch"
													id="txtUserReqSearch"
													[attr.data-testid]="'txtUserReqSearch'"
													class="form-control"
													placeholder="{{ 'UI.k_search_user_requester' | translate }}"
													maxlength="100"
													#TDUserRequesterRef="ngModel"
													required
													(keyup)="searchRequester(searchStr, 'change')"
												/>
												<div class="input-group-append border-right rounded">
													<span class="input-group-text cursor-pointer" id="basic-addon-search2"
														><i class="fa-light fa-magnifying-glass"></i
													></span>
												</div>
											</div>
											<ng-container *ngIf="searchStr && userRequesterList" class="overlay comment cursor-pointer">
												<ng-container *ngIf="userRequesterList?.length > 0">
													<ng-container *ngFor="let requester of userRequesterList; let i = index">
														<div
															class="w-100 px-1 single-user-card"
															id="divrequester_{{ i }}"
															name="divrequester_{{ i }}"
															attr.data-testid="divrequester_{{ i }}"
															(click)="shownAssignee(requester)"
														>
															<div class="align-items-center mt-75">
																<div class="media">
																	<div class="avatar mr-75 avatar-sm" id="divImg">
																		<span class="avatar-content">
																			<ng-container *ngIf="requester?.profile_image">
																				<img
																				[src]="requester?.profile_image"
																				alt="Avatar"
																				width="32"
																				height="32"
																			/>
																		</ng-container>
																		<ng-container *ngIf="!requester?.profile_image">
																				<div
																				id="divReqAvatar"
																				class="avatar-content"
																				[ngStyle]="{
																					'background-color': requester?.avatar_color
																						? requester?.avatar_color
																						: '#f59518'
																				}"
																			>
																				{{ requester.full_name | initials }}
																			</div>
																		</ng-container>
																		</span>
																	</div>
																	<div class="media-body">
																		<div class="d-flex justify-content-between w-100 align-items-center">
																			<div class="mb-0 fw-500" id="spCard" class="req-info">
																				<div class="title-color text-truncate fw-500 sm-font line-height-1">
																					{{ requester.full_name | slice: 0:28 }}
																					{{ requester.full_name?.length > 28 ? ".." : "" }}
																				</div>
																				<div
																					placement="top"
																					ngbTooltip="{{ requester.email }}"
																					class="req-mail xs-font text-truncate line-height-1"
																				>
																					{{ requester?.email | slice: 0:28 }}
																					{{ requester?.email.length > 28 ? ".." : "" }}
																				</div>
																			</div>
																			<div id="divActive">
																				<span *ngIf="requester?.is_staff" class="badge badge-light-success">
																					{{ "UI.k_user" | translate }}
																				</span>
																				<span *ngIf="!requester?.is_staff" class="badge badge-light-success">
																					{{ "UI.k_requester" | translate }}
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</ng-container>
												</ng-container>
												<ng-container *ngIf="userRequesterList?.length === 0">
													<div class="no-data-found d-flex p-1 justify-content-center align-items-center full-height">
														<span class="title-color fw-500">{{ "Error.err_no_filter_result" | translate }}</span>
													</div>
												</ng-container>
											</ng-container>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
							<div class="row">
								<div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
									<div class="panel-edit-select-box">
										<label style="font-size: 14px; font-weight: 600">
											{{ aboutTaskMapping?.[type_id] | translate}}&nbsp;*
										</label>
									</div>
									<div class="p-0 card border quill-card">
										<quill-editor
											[(ngModel)]="task_obj.description"
											id="qlDescription"
											name="qlDescription"
											#qlDescription
											[trimOnValidation]="true"
											[appQuillValidator]="qlDescription"
											[appQuillForm]="newTaskForm"
											placeholder="{{ 'UI.k_add_content' | translate }}"
											[minLength]="3"
											[class.error]="qlDescription?.errors && newTaskForm.submitted"
											[required]="true"
										>
											<div
												quill-editor-toolbar
												class="border-0 border-bottom px-0"
												style="padding: 0px"
											>
												<div class="border-bottom editor-item-flex">
													<span
														class="ql-formats"
														style="margin: 0; border-right: 1px solid #e5e6f0"
													>
														<select class="ql-header">
															<option value="1">Heading 1</option>
															<option value="2">Heading 2</option>
															<option value="3">Heading 3</option>
															<option value="">Normal</option>
														</select>
													</span>
													<span
														class="ql-formats"
														style="margin: 0; border-right: 1px solid #e5e6f0"
													>
														<button
															id="qlBoldNoteEmail-Inc"
															name="qlBold"
															class="ql-bold"
															title="{{ 'UI.k_bold' | translate }}"
														></button>
													</span>
													<span
														class="ql-formats"
														style="margin: 0; border-right: 1px solid #e5e6f0"
													>
														<button class="ql-align" value=""></button>
														<button class="ql-align" value="center"></button>
														<button class="ql-align" value="right"></button>
														<button class="ql-align" value="justify"></button>
													</span>
												</div>
											</div>
										</quill-editor>
									</div>
									<div>
										<span
											class="row col-12 error"
											*ngIf="newTaskForm.submitted && qlDescription?.errors"
											class="invalid-form"
										>
											<small
												*ngIf="qlDescription?.errors?.minLengthError"
												class="form-text text-danger xs-font"
												>{{ "Error.err_min_3_character" | translate }}</small
											>
											<small
												*ngIf="qlDescription?.errors?.requiredError?.empty"
												class="form-text text-danger xs-font"
											>
												{{ "UI.k_field_required" | translate }}
											</small>
										</span>
									</div>
								</div>
							</div>
						</div>
					</ng-scrollbar>
					<div class="modal-btn-group flex-initial border-top sticky-footer">
						<div class="d-flex justify-content-end stepper-btn flex-initial feature-cancel">
							<button
								class="btn btn-sm btn-light btn-next ml-1 d-cancel mr-1"
								rippleEffect
								(click)="toggleSidebar()"
								style="background: transparent"
								id="btTaskCancel"
								name="btTaskCancel"
								type="button"
								[attr.data-testid]="'btTaskCancel'" 
							>
								<span class="align-middle d-sm-inline-block"> {{ "UI.k_cancel" | translate }}</span>
							</button>
							<button
								*ngIf="
									!(process_data?.is_exit && process_data?.basic_info?.status?.state_id == 5) &&
									process_data.basic_info?.state?.id != 6
								"
								[disabled]="disableSaveBtn"
								type="submit"
								class="btn btn-primary btn-next"
								(click)="SaveTask(newTaskForm)"
								rippleEffect
								id="btTaskSave"
								name="btTaskSave"
								[attr.data-testid]="'btTaskSave'" 
							>
								<i class="fa-light fa-floppy-disk align-middle mr-50 ml-0"></i>
								<span class="align-middle d-sm-inline-block d-none">{{
									"UI.k_submit" | translate
								}}</span>
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
