import { Component, OnInit, OnDestroy, ViewEncapsulation, Output, EventEmitter, Input } from "@angular/core";
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from "@angular/forms";

import { Subject, throwError } from "rxjs";
import { catchError, map, takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { CoreConfigService } from "@core/services/config.service";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DiscoveryService } from "app/ims/discovery/services/discovery.service";
import { environment } from "environments/environment";
import { OrganizationService } from "app/base/organization/services/organization.service";
import { UserService } from "app/base/users/services/user.service";

const URL = `${environment.instance_url}` + "media";

@Component({
	selector: "app-notification",
	templateUrl: "./notification.component.html",
	styleUrls: ["./notification.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent implements OnInit {
	public selectedItem = 0;
	coreConfig: any;
	form: UntypedFormGroup;
	isRead = true;
	public sidebar_name;
	public count = 4;
	public showData = false;
	public currentTab: any = "alerts";
	public newFeatureCount = 0;
	public loading = false;
	menu: any = "manageNotify";

	// Private
	private _unsubscribeAll: Subject<any>;

	@Output("hideSidebar") hideSidebar = new EventEmitter<any>();
	@Output("notificationCount") notificationCount = new EventEmitter<any>();
	@Input() feature: Array<any>;

	public notification = [
		{
			name: "Failed to add asset(s)",
			description: "Failed to add asset(s) from the CSV. Check CSV error log, rectify errors and try again",
			time: "MAR 15 12:50PM",
			handle: "/teams",
			id: 1,
			isRead: true,
			icon: "fa-times-circle text-danger",
			isActive: true,
			btnName: "New Asset",
			btnIcon: "",
			sidebar: false,
			window: true,
		},
		{
			name: "Incident Updated Successfully",
			description: "New Task TSK-10 assign to you. Email Id creation on AD/LDAP",
			time: "MAR 15 12:50PM",
			handle: "/incident_view",
			id: 2,
			icon: "fa-check-circle text-success",
			isRead: false,
			sidebar: false,
			isActive: true,
			btnName: "View Here",
		},
		{
			name: "Failed to add Requester(s)",
			description: "Failed to add requester(s) from the CSV. Check CSV error log, rectify errors and try again",
			time: "MAR 15 12:50PM",
			handle: "/requester",
			id: 3,
			icon: "fa-check-circle text-success",
			isActive: true,
			btnName: "Add Requester",
			btnIcon: "fa-plus",
			isRead: true,
			sidebar: true,
		},
		{
			name: "Report generated successfully",
			description: "The report #REPO123456 has been updated successfully.",
			time: "MAR 15 12:50PM",
			id: 4,
			icon: "fa-file-check text-success",
			isActive: true,
			btnName: "Download",
			btnIcon: "fa-arrow-down",
			isRead: true,
			download: true,
		},
		{
			name: "modal pop up",
			description: "The report #REPO123456 has been updated successfully.",
			time: "MAR 15 12:50PM",
			id: 4,
			icon: "fa-file-check text-success",
			isActive: true,
			btnName: "Modal pop-up",
			btnIcon: "fa-arrow-down",
			isRead: true,
			modalOpen: true,
		},
	];
	public notificationData = [];
	public alert_notifications = [];
	alert_Count: any;
	// imgSrc = "assets/images/login_sidepanel_content.svg";
	/**
	 * Constructor
	 * @param {FormBuilder} _formBuilder
	 * @param {CoreConfigService} _coreConfigService
	 * @param {CoreSidebarService} _coreSidebarService
	 * */
	constructor(
		private _formBuilder: UntypedFormBuilder,
		private _coreConfigService: CoreConfigService,
		private _coreSidebarService: CoreSidebarService,
		private _translateService: TranslateService,
		private router: Router,
		private _modalService: NgbModal,
		private _discoveryService: DiscoveryService,
		private _orgService: OrganizationService,
		private _userService: UserService
	) {}

	toggleSidebar(key): void {
		this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
	}
	selectAlertNotification(i): void {

	}
	//ui activation for each notification
	selectNotification(i): void {
		this.notification[i]["isRead"] = false;
		this.count = this.count - 1;
	}
	selectNotificationItem(i, conf): void {
		if (!conf?.isRead) {
			this.notificationData[i]["isRead"] = true;
			this.newFeatureCount = this.newFeatureCount - 1;
			this.notificationCount.emit(this.newFeatureCount);
			this.updateViewCount(conf);
		}
	}
	ngOnInit(): void {
		this.getAllFeatures();
		this.getSystemNotifications();
	}

	toggleclose(args) {
		if (args.handle) {
			window.open(args.handle, "_blank");
		}
		this._coreSidebarService.getSidebarRegistry("notification").toggleOpen();
		this._modalService.dismissAll();
	}

	sliderOpen() {
		this._coreSidebarService.getSidebarRegistry("notification").toggleOpen();
		this._modalService.dismissAll();
	}

	downloadFile() {
		this._discoveryService.downloadSampleCSV({}).subscribe(
			(response) => {
				if (response) {
					let path = response.toString();
					let file_path = URL + path.split("media")[1];
					let downloadable_file_path = file_path;
					window.open(downloadable_file_path, "_blank");
				}
			},
			(error) => {
				//todo
			}
		);
	}

	notificationlistView(): void {
		this.router.navigateByUrl("/notification/list");
		this._coreSidebarService.getSidebarRegistry("notification").toggleOpen();
	}
	onTabChange(data) {
		this.currentTab = data;
	}
	onSelectAlerts(data) {
		this.currentTab = data;
		this.getSystemNotifications()
	}

	getSystemNotifications(){
		const payload = {
			page: 1,
			items_per_page: 10,
			sort: "creation_time",
			reverse: "-",
			module: "system_notification",
		};
		this._orgService.getSystemNotifications(payload).subscribe(
			(response) => {
				if (response) {
					this.alert_notifications = response.results;
					this.alert_Count = response.count;
					this.showData = true;
					// this.filterData = false;
				}
			},
			(error) => {
				//todo
			}
		);
	};
	// modal Basic
	modalOpen(modalBasic) {
		this._modalService.open(modalBasic);
	}
	closeSidebar() {
		this.hideSidebar.emit("hideSidebar");
	}
	rediectTOContent(url) {
		window.open(url, "_blank");
	}
	redirectToBlog() {
		let url = "https://docs.infraon.io/workspace.htm";
		window.open(url, "_blank");
	}

	funcManage() {
		this._coreSidebarService.getSidebarRegistry("notification").toggleOpen();
		localStorage.setItem("lastMenuSelected", this.menu);
		localStorage.setItem("lastMenuIndex", "3");
		this.router.navigate(["/account-settings"], { queryParams: { globalSearch: true } });
	}

	getAllFeatures = () => {
		this._orgService
			.getNewFeatures()
			.pipe(
				map((response: any) => {
					if (response) {
						if(Object.keys(this.notificationData).length > 0) {
						this.notificationData = response;
						this.newFeatureCount = this.notificationData.filter((obj) => !obj.isRead).length;
						}
					}
					// Success
				}),
				catchError((error) => {
					return throwError(error.statusText);
				})
			)
			.subscribe();
	};
	updateViewCount = (feature) => {
		this._userService
			.saveNotificationSeen({ notification_id: feature?.id })
			.pipe(
				map((response: any) => {
					if (response) {
						// console.log("response", response);
					}
					// Success
				}),
				catchError((error) => {
					return throwError(error.statusText);
				})
			)
			.subscribe();
	};
}
