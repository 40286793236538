import { Component, Input, OnInit } from "@angular/core";
import { NgEventBus } from "ng-event-bus";

@Component({
	selector: "app-request-process-summary",
	templateUrl: "./request-process-summary.component.html",
	styleUrls: ["./request-process-summary.component.scss"],
})
export class RequestProcessSummaryComponent implements OnInit {
	@Input() config: any;
	@Input() modifiedtime: any;
	constructor(
		private eventBus: NgEventBus
	) {}

	ngOnInit(): void {
		//console.log("config", this.config);
	}

	showInciSidebar(dat){
		if (dat?.value > 0 && dat?.item_filter){
			let payload = {
				"title": "Requests - " + dat.name,
				"app_type": "req-process-grid",
				"item_filter": dat.item_filter,
				"timescale" : this.config?.timescale
			}
			// console.log("in inci summary widget -> ", payload)
			this.eventBus.cast('dashboard-view:sidebar', payload);
		}
	}
}
