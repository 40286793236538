import { NgModule } from "@angular/core";
import { SupportPortalRoutingModule } from "./support-portal-routing.module";
import { SupportHomeComponent } from "./components/support-home/support-home.component";
import { OrganizationGridComponent } from "./components/organization-grid/organization-grid.component";
import { NgxDropdownTreeviewModule } from "app/common/dns/components/ngx-dropdown-treeview-select/ngx-dropdown-treeview.module";
import { SharedModule } from "app/shared.module";
import { OrganizationAddComponent } from "./components/organization-add/organization-add.component";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { FileUploadModule } from "ng2-file-upload";
import { ProfileAvatarModule } from "app/common/dns/components/profile-avatar/profile-avatar.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { NgScrollbarModule } from "ngx-scrollbar";
import { TranslateModule } from "@ngx-translate/core";
import { CorePipesModule } from "@core/pipes/pipes.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DnsModule } from "app/common/dns/dns.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { CoreSidebarModule } from "@core/components";
import { CoreCommonModule } from "@core/common.module";
import { DndModule } from "ngx-drag-drop";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { UsersModule } from "app/base/users/users.module";
import { PartnersListComponent } from "./components/partners-list/partners-list.component";
import { CreatePartnerComponent } from "./components/create-partner/create-partner.component";
import { InvitePartneruserComponent } from "./components/invite-partneruser/invite-partneruser.component";
import { PartnerNameTagComponent } from "./components/partner-name-tag/partner-name-tag.component";
import { PartnerOperatorsComponent } from "./components/partner-operators/partner-operators.component";
import { SupportAuditComponent } from "./components/support-audit/support-audit.component";
import { AuditModule } from "app/base/audit/audit.module";
import { OrganizationInvoicesComponent } from "./components/organization-invoices/organization-invoices.component";
import { AdminModule } from "app/admin/admin.module";

@NgModule({
	declarations: [
		SupportHomeComponent,
		OrganizationGridComponent,
		OrganizationAddComponent,
		PartnersListComponent,
		CreatePartnerComponent,
		InvitePartneruserComponent,
		PartnerNameTagComponent,
		PartnerOperatorsComponent,
		SupportAuditComponent,
		OrganizationInvoicesComponent,
	],
	imports: [
		SharedModule,
		SupportPortalRoutingModule,
		NgxDropdownTreeviewModule,
		SweetAlert2Module.forRoot(),
		NgxIntlTelInputModule,
		FileUploadModule,
		ProfileAvatarModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		NgScrollbarModule,
		TranslateModule,
		CorePipesModule,
		NgbModule,
		SharedModule,
		DnsModule,
		FormsModule,
		NgSelectModule,
		CoreSidebarModule,
		NgxIntlTelInputModule,
		CoreCommonModule,
		DndModule,
		FileUploadModule,
		InfiniteScrollModule,
		NgScrollbarModule,
		NgxIntlTelInputModule,
		UsersModule,
		ReactiveFormsModule,
		AuditModule,
		AdminModule,
	],
})
export class SupportPortalModule {}
