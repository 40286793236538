<div class="slideout-content">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog sidebar-md">
            <div class="modal-content add-new-user py-0">
                <div class="modal-header d-flex align-items-center justify-content-between border-bottom">
                    <div class="d-flex align-items-center">
                        <h5 class="modal-title" id="exampleModalLabel">{{ risk_title }}</h5>
                        <div 
                            class="badge badge-light-secondary reg-font text-black-50 fw-600 ml-1"
                            *ngIf="risk_obj.display_id"
                        >
                            {{ risk_obj.display_id }}
                        </div>
                    </div>
                    <button type="button" data-dismiss="modal" id="btnClose" name="btnClose" aria-label="Close" [attr.data-testid]="'btnClose'"
                        (click)="toggleSidebar('risk-sidebar')" class="btn btn-flat-secondary btn-icon" rippleEffect>
                        <i class="fa-light fa-xmark"></i>
                    </button>
                </div>
                <div class="modal-body flex-1 p-0">
                    <form #submissionForm="ngForm" class="flex-auto-height">
                        <ng-scrollbar class="custom-scrollbar section-scrollbar">
                            <div class="modal-body-inner flex-1 p-1">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="submissionFormType" class="form-label"
                                                > {{ 'UI.k_name' | translate }}*</label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="submitVlanName"
                                                name="submitVlanName"
                                                placeholder="{{ 'UI.k_type_here' | translate }}"
                                                required
                                                minlength="3"
                                                maxlength="40"
                                                [(ngModel)]="risk_obj.name"
                                                #submitVlanNameInput="ngModel"
                                                [class.error]="submitVlanNameInput.invalid && submissionForm.submitted"
                                            />
                                            <span class="invalid-form" *ngIf="submissionForm.submitted && submitVlanNameInput.invalid">
                                                <small class="form-text text-danger" *ngIf="submitVlanNameInput.errors.required"
                                                    >{{ 'UI.k_field_required' | translate }}
                                                </small>
                                            </span>
                                            <span class="invalid-form" *ngIf="submissionForm.submitted && submitVlanNameInput.invalid">
                                                <small class="form-text text-danger" *ngIf="submitVlanNameInput.errors.minlength">
                                                    {{ 'Error.err_input_min_char' | translate }}
                                                </small>
                                            </span>
                                        </div>

                                    </div>
                                    <div class="col-lg-6 col-md-6 col-xs-12">

                                        <div class="form-group">
                                            <label for="selrisk" class="form-label">
                                                {{ 'UI.k_risk' | translate }} {{ 'UI.k_type' | translate }}*</label
                                            >
                                            <ng-select
                                                [items]="riskOption"
                                                bindLabel="display_key"
                                                class="form-control"
                                                [(ngModel)]="risk_obj.type"
                                                name="selrisk"
                                                id="selrisk"
                                                placeholder="{{ 'UI.k_select' | translate }}"
                                                #TDSelRisk="ngModel"
                                                [class.error]="TDSelRisk.invalid && submissionForm.submitted"
                                                required
                                            >
                                            </ng-select>
                                            <span class="invalid-form" *ngIf="submissionForm.submitted && TDSelRisk.invalid">
                                                <small class="form-text text-danger" *ngIf="TDSelRisk.errors.required"
                                                    >{{ 'UI.k_field_required' | translate }}
                                                </small>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-xs-12">
                                        <div class="form-group">
                                            <label for="txtMitigation"> {{"UI.k_mitigation_plan" | translate}} </label>
                                            <textarea type="text" id="txtMitigation" class="form-control"
                                                name="mitigation" placeholder="{{'UI.k_mitigation_plan' | translate}}"
                                                [(ngModel)]="risk_obj.mitigation_plan"
                                                maxlength="3000"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-xs-12">
                                        <div class="form-group">
                                            <label for="txtDescription"> {{"UI.k_description" | translate}} </label>
                                            <textarea type="text" id="txtDescription" class="form-control"
                                                name="description" placeholder="{{'UI.k_description' | translate}}"
                                                [(ngModel)]="risk_obj.description"
                                                maxlength="3000"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-scrollbar>
                        <div class="d-flex justify-content-end border-top py-1 px-2 stepper-btn">
                            <button 
                                *ngIf="!(change_data?.is_exit && 
                                change_data?.basic_info?.status?.state_id == 5) &&
                                change_data.basic_info?.state?.id != 6"
                                [disabled]="disableSaveBtn" 
                                type="submit"
                                class="btn btn-primary btn-next" 
                                (click)="SaveRisk(submissionForm)"
                                rippleEffect
                                id="btnSubmit"
                                name="btnSubmit"
                                [attr.data-testid]="'btnSubmit'"
                            >
                            <i
                                class="fa-light fa-floppy-disk align-middle mr-50 ml-0"
                            ></i>
                            <span class="align-middle d-sm-inline-block d-none">{{'UI.k_submit' | translate }}</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
