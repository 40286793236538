import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { CoreCommonModule } from "@core/common.module";
import { CoreMenuModule } from "@core/components";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SystemMenuModule } from "app/base/system-menu/system-menu.module";
import { SharedModule } from "app/shared.module";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { SupportPortalModule } from "app/support-portal/support-portal.module";
import { SupportMenuComponent } from "app/common/layout/components/menu/support-menu/support-menu.component";
import { NgScrollbarModule } from "ngx-scrollbar";

@NgModule({
	declarations: [SupportMenuComponent],
	imports: [
		CoreMenuModule,
		CommonModule,
		CoreCommonModule,
		PerfectScrollbarModule,
		RouterModule,
		SystemMenuModule,
		NgbModule,
		SharedModule,
		SupportPortalModule,
		NgScrollbarModule,
	],
	exports: [SupportMenuComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SupportMenuModule {}
