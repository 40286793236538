export class TaskRequest {
	constructor(task: any) {
		this.status = task?.status || {};
		this.priority = task?.priority || null;
		this.due_date = task?.due_date || null;
		
		this.assignee = {
			assignee: task?.assignee || null,
			assignee_profile: task?.assignee_profile || null,
		};
	}
	assignee?: any;
	priority: any;
	requested_by: {};
	status: {
		name: string
		state: string
		state_id: number
		guid: string
		color: string
		id: string
		background_color: string
		workflow_id: string
		workflow_name: string
		transition_state: Array<string>
	};
	due_date: any;
}
