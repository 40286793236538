import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { CoreSidebarService } from "../../../../../@core/components/core-sidebar/core-sidebar.service";
import { TranslateService } from "@ngx-translate/core";
import { StatusPickerComponent } from "../../../../common/dns/components/status-picker/status-picker.component";

@Component({
	selector: "app-priority-change",
	templateUrl: "./priority-change.component.html",
	styleUrls: ["./priority-change.component.scss"],
})
export class PriorityChangeComponent implements OnInit {
	priority_change_comment: any;
	@Input("config_data") config_data: any;
	@Output("onPriorityChangeSubmit") onPriorityChangeSubmit: EventEmitter<any> = new EventEmitter<any>();
	@Output("onPrioritySliderClose") onPrioritySliderClose: EventEmitter<any> = new EventEmitter<any>();
	@Input() options: any;
	priority_change_data: any = {};
	@Input() sidebarName = "priority-change-config";
	@Input() callFrom: any;

	constructor(private _configSidebarService: CoreSidebarService, private _translateService: TranslateService) {
	}

	ngOnInit(): void {}

	onSubmit = (PriorityChangeForm: NgForm) => {
		if (PriorityChangeForm.valid) {
			this.config_data["comment"] = {
				description: `${this.priority_change_data.comment}`,
				type: "Priority Change",
				is_private: false,
			};
			if(this.callFrom === 'panelCard'){
				this.onPriorityChangeSubmit.emit(this.config_data);
			}else{
				this.onPriorityChangeSubmit.emit(this.config_data);
				this.toggleSidebar(this.sidebarName, PriorityChangeForm, 'submit');
			}
		}
	};

	toggleSidebar = (sidebarName: string, dataForm: NgForm, type: any = 'close') => {
		if(this.callFrom === 'panelCard'){
			this.onPrioritySliderClose.emit(type);
			if (dataForm) {
				dataForm.resetForm();
				this.priority_change_data = {
					custom_field_data: {},
				};
			}
		}else{
			this._configSidebarService.getSidebarRegistry(sidebarName).toggleOpen();
			if (!this._configSidebarService.getSidebarRegistry(sidebarName).isOpened) {
				this.onPrioritySliderClose.emit(type);
				if (dataForm) {
					dataForm.resetForm();
					this.priority_change_data = {
						custom_field_data: {},
					};
				}
			}
		}

	};

}
