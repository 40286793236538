<div class="single-ticket-status">
	<div class="ticket-item" id="divTicket" name="divTicket">
		<div class="main-card-view">
			<div class="card-view">
				<div class="single-card" style="height: 100%">
					<div style="display: flex; align-items: center; justify-content: space-between; padding: 0.5em">
						<div class="d-flex" style="padding: 1rem">
							<div>
								<h6 class="profile-name" style="font-size: 3.4rem !important; margin-bottom: 8px">
									{{ widgetData.value }}
								</h6>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
