<div class="slideout-content default-slideout">
  <div class="modalsd modal-slide-in sdfade" id="modals-slide-in">
    <div class="modal-dialog">      
      <div class="modal-content pt-0 pb-0">        
        <div class="flex-auto-height">
          <div class="modal-header d-flex justify-content-between align-items-center">
            <h5 class="modal-title fw-600 mb-0 lg-font">{{'UI.k_quick_response_template' | translate}}</h5>
            <button type="button" data-dismiss="modal" id="btnClose" name="btnClose" aria-label="Close"
              (click)="toggleSidebar('quick-response-template')" class="btn btn-flat-secondary btn-icon" rippleEffect>
              <i class="fa-light fa-xmark"></i>
            </button>
          </div>
          <div class="action-bar">
            <div class="inner-section">
              <div class="left-section">
                <div class="subheader">
                  <div class="toggale-submenu">
                    <button
                      class="submenu-toggle btn btn-icon btn-sm"
                      id="btnSubmenu"
                      name="btnSubmenu"                
                    >
                      <div placement="left" class="lg-font">
                        <i class="fa-light fa-angle-left"></i>
                      </div>                
                    </button>
                  </div>
                </div>
                <div class="action-search">
                  <app-search></app-search>
                </div>          
              </div>
              <div class="right-section">
                <div class="pull-right">														
                  <button
                      type="button"
                      class="btn btn-primary"																	
                      id="btnDownload"
                      name="btnDownload"
                      rippleEffect
                      tooltipClass="fadeInAnimation"
                      ngbTooltip="{{ 'UI.k_new_template' | translate }}"
                      placement="bottom"		
                      (click)="toggleSidebar('add-quick-response-template')"
                      																																
                    >								
                        <i class="fa-light fa-plus"></i>
                      <span class="align-middle">{{ 'UI.k_new_template' | translate }}</span>
                    </button>                     					
                </div>
              </div>
            </div>
          </div>   
          <div class="action-section">
            <div class="table-view">
              <dns-server-grid
                [rows]="responseEmailTemplate"
                [columns]="responseEmailTemplateColumns"
                [count]="totalResponseEmailTemplate"
                (page)="setPage($event)"
                (sort)="onSort($event)"                
                [isEditable]="false"
                (setlimit)="setLimit($event)"
                class="bootstrap core-bootstrap"						
                [limit]="limit"  
                [permissions]="permissions"   
                [selectionType]="SelectionType.checkbox"
                (onSelectCheckbox)="onSelectRow($event)" 
                [mselectMenu]="multiselectOptions"                                                            
                [scrollbarH]="true"
                [hideSelectAllCheckbox]="true"
                [selectBoxColumnLabel]="selectBoxColumnLabel"
              ></dns-server-grid>
            </div>
          </div> 
          <div class="flex-initial">
            <div class="d-flex justify-content-end border-top px-1 py-1">
              <button type="button" (click)="toggleSidebar('quick-response-template')"
                class="btn btn-outline-tertiary btn-prev mr-1" rippleEffect>
                <span class="align-middle"> {{ 'UI.k_cancel' | translate }}</span>
              </button>
              <button type="button" (click)="toggleSidebar('quick-response-template')"
                class="btn btn-primary btn-next" rippleEffect>
                <span class="align-middle">{{ 'UI.k_save' | translate }}</span>
              </button>
            </div>
          </div>          
        </div>
      </div>
    </div>
  </div>
</div>
