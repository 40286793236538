import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { CoreConfigService } from "@core/services/config.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import store from "store";
import { TranslateService } from "@ngx-translate/core";
import { CoreTranslationService } from "@core/services/translation.service";
import * as UserActions from "app/store/user/actions";
import { AuthService } from "app/base/auth/services/auth.service";
import { UserService } from "app/base/users/services/user.service";
import { GlobalConfig, ToastrService } from "ngx-toastr";
import { CookieService } from "ngx-cookie-service";
import { environment } from "environments/environment";
import { DOCUMENT } from "@angular/common";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from "@azure/msal-angular";
import {
	AuthenticationResult,
	InteractionStatus,
	PopupRequest,
	RedirectRequest,
	EventMessage,
	EventType,
} from "@azure/msal-browser";
import { SocialAuthService, GoogleSigninButtonDirective } from "@abacritt/angularx-social-login";
import { GoogleLoginProvider } from "@abacritt/angularx-social-login";

var AES = require("crypto-js/aes");

@Component({
	selector: "app-admin-login",
	templateUrl: "./admin-login.component.html",
	styleUrls: ["./admin-login.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class AdminLoginComponent implements OnInit, OnDestroy, AfterViewInit {
	//  Public
	public coreConfig: any;
	public loginForm: UntypedFormGroup;
	public loading = false;
	public submitted = false;
	public returnUrl: string;
	public error = "";
	public passwordTextType: boolean;
	public captcha_temp = "";
	public codeImage: any;
	public captchaCode = "";
	public captchaToken = "";
	private cookieValue: string;
	public showCaptcha: boolean = false;
	private _options: GlobalConfig;
	is_token_expired = false;

	// Private
	private _unsubscribeAll: Subject<any>;
	private recaptchaSubscription: Subscription;
	isIframe = false;
	private readonly _destroying$ = new Subject<void>();
	private googleAccessToken = "";
	public showImageCaptcha: boolean = false;
	/**
	 * Constructor
	 *
	 * @param {CoreConfigService} _coreConfigService
	 */
	constructor(
		private _coreConfigService: CoreConfigService,
		private _formBuilder: UntypedFormBuilder,
		private _authService: AuthService,
		private _notificationService: ToastrService,
		private _router: Router,
		private cookieService: CookieService,
		public route: ActivatedRoute,
		private _translateService: TranslateService,
		private _coreTranslationService: CoreTranslationService,
		private _userService: UserService,
		private reCaptchaV3Service: ReCaptchaV3Service,
		@Inject(DOCUMENT) private _document: Document,
		@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
		private mauthService: MsalService,
		private msalBroadcastService: MsalBroadcastService,
		private socialAuthService: SocialAuthService
	) {
		this._unsubscribeAll = new Subject();
		this._options = this._notificationService.toastrConfig;

		// Configure the layout
		this._coreConfigService.config = {
			layout: {
				navbar: {
					hidden: true,
				},
				menu: {
					hidden: true,
				},
				footer: {
					hidden: true,
				},
				customizer: false,
				enableLocalStorage: false,
				notifications: false,
			},
		};

		this.is_token_expired = this.route.snapshot.queryParams?.is_token_expired ? true : false;
	}
	// convenience getter for easy access to form fields
	get f() {
		return this.loginForm.controls;
	}

	/**
	 * Toggle password
	 */
	togglePasswordTextType() {
		this.passwordTextType = !this.passwordTextType;
	}

	onSubmit() {
		this.updateEmail();
		this.submitted = true;

		// stop here if form is invalid
		if (this.loginForm.invalid) {
			return;
		}

		// Login
		this.loading = true;
		this.recaptchaSubscription = this.reCaptchaV3Service.execute("importantAction").subscribe((token: string) => {
			//preapre payload
			const payload = {
				username: this.encryptPassword(this.f.email.value),
				password: this.encryptPassword(this.f.password.value),
				captchaOpt: this.captchaCode,
				captchaToken: this.captchaToken,
				loginCaptcha: this.captcha_temp,
				CaptchaValidation: this.showCaptcha,
				Gtoken: token,
			};
			if (this.showImageCaptcha) {
				payload["CaptchaValidation"] = true;
				payload["Gtoken"] = "";
			}
			this._authService.getHealthCheck({}).subscribe(
				(response: any) => {
					if(response.ttoken){
						let login_header = {username: this.encryptPassword(this.f.email.value),
							password: this.encryptPassword(this.f.password.value), ttok: response.ttoken}
						let login_header_enc = btoa(JSON.stringify(login_header))
						let payLoadHeader = {"Authorization": login_header_enc}	
						//service call to network
							this._authService.doLogin(JSON.stringify(payload), payLoadHeader).subscribe(
								(response: any) => {
									this.loading = true;
									if (response && response.token) {
										store.set("accessToken", response.token);
										store.set("image_per", response.img_per);
										store.set("fflags", response.fflags);
										store.set("is_admin", response.is_admin);
										store.set("is_expired", response.is_expired);
										store.set("is_management", true);
										store.set("is_partner", response?.is_partner);
										store.set("is_partner_org", response?.is_partner_org);
										this.cookieService.set("captch-cookie", "2", 1);
										// this._notificationService.success(
										// 	"Logged In",
										// 	"You have successfully logged in!"
										// );
										// if (!environment.management) {
										// 	if (this.route.snapshot.queryParams.returnUrl) {
										// 		this._router.navigate([`/auth/redirect${this.route.snapshot.queryParams.returnUrl}`]);
										// 	} else {
										// 		this._router.navigate(["/auth/redirect/get-started"]);
										// 		// this._router.navigate(["/auth/redirect/incident_view"]);
										// 	}
										// 	this._router.navigate([response.redirect]);
										// } else {
										if (response?.is_admin) {
											if (this.route.snapshot.queryParams.returnUrl) {
												// this._router.navigate([this.route.snapshot.queryParams.returnUrl]);
												this._document.location.href = `/auth/redirect${this.route.snapshot.queryParams.returnUrl}`;
											} else {
												// this._router.navigate(["/get-started"]);
												this._document.location.href = `/admin/dashboard`;
												// this._router.navigate(["/admin/dashboard"]);
												// this._document.location.href = `/auth/redirect/admin/dashboard`;
											}
										} else {
											let tempSupportRoles = [5, 6, 7, 8];
											if (tempSupportRoles.indexOf(response?.access_type) !== -1) {
												this._document.location.href = `/support/home`;
												// this._router.navigate(["/support"]);
											} else {
												this._router.navigate(["/error/403"]);
											}
										}
										// }
										return new UserActions.LoginSuccessful(response);
									} else if (response && response.redirect) {
										this._notificationService.warning(
											this._translateService.instant("Error.err_please_change_password"),
											this._translateService.instant(response.ui_msg)
										);
										this._router.navigate([response.redirect]);
									} else if (response.status == "error") {
										if (response?.data?.show_image_captcha) {
											this.showImageCaptcha = true;
											this.getCaptcha();
										} else {
											this._notificationService.error(
												this._translateService.instant(response?.message),
												this._translateService.instant("Error.err_login_failed"),

												{
													toastClass: "toast ngx-toastr",

													closeButton: true,

													positionClass: "toast-top-right",
												}
											);
										}
									}
									this.loading = false;
									// this._notificationService.warning(response.msg,"Auth Failed");
									// this.showCaptcha = false;
									// this.getCaptcha();
								},
								(err) => {
									this.loading = false;
									// this._notificationService.error("Login Failed", "Something went wrong");
									this._notificationService.error(
										this._translateService.instant("Error.err_invalid_username_password"),
										this._translateService.instant("Error.err_login_failed"),
										{
											toastClass: "toast ngx-toastr",
											closeButton: true,
											positionClass: "toast-top-right",
										}
									);
								}
							);
						}	
				},
				(err) => {
					this.loading = false;
					// this._notificationService.error("Login Failed", "Something went wrong");
					this._notificationService.error(
						this._translateService.instant("Error.err_invalid_username_password"),
						this._translateService.instant("Error.err_login_failed"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
				}
			);
			
		});
	}

	encryptPassword(password: string) {
		var iv = "d99630bcc7058639e7e80df239f38a32";
		var ciphertext = AES.encrypt(password, iv).toString();
		var aesPassword = iv + "::" + ciphertext;
		return btoa(aesPassword);
	}

	// Lifecycle Hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.loginForm = this._formBuilder.group({
			email: ["", [Validators.required, Validators.email]],
			password: ["", Validators.required],
			captcha: [""],
			captchaInput: [""],
		});

		// Subscribe to config changes
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
			this.coreConfig = config;
		});
		/// For microsoft Login
		this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
		this.mauthService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
		// for Google Login
		this.socialAuthService.authState.subscribe((user) => {
			this.getAccessToken();
			var loggedIn = user != null;
			if (loggedIn == true) {
				var token = user.idToken;
				var username = user.email;
				this.validateLoginPortal(token, username, "google");
			}
		});
	}

	ngAfterViewInit(): void {
		if (environment.captcha) {
			// // if (this.cookieService.check("captch-cookie")) {
			// // 	this.showCaptcha = false;
			// // } else {
			// // 	this.getCaptcha();
			// // }
			// // this.cookieService.set('captch-cookie','2',1)
			// this.cookieValue = this.cookieService.get("captch-cookie");
		}
		const element = document.getElementsByClassName("grecaptcha-badge")[0] as HTMLElement;
		if (element) {
			element.style.visibility = "visible";
		}
	}
	redirectToForgetPassword() {
		this._router.navigate(["/auth/forgot-password"], {
			queryParams: {
				auth_name: "admin",
			},
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(undefined);
		this._unsubscribeAll.complete();
		if (this.recaptchaSubscription) {
			this.recaptchaSubscription.unsubscribe();
		}
		const element = document.getElementsByClassName("grecaptcha-badge")[0] as HTMLElement;
		if (element) {
			element.style.visibility = "hidden";
		}
		this._destroying$.next(undefined);
		this._destroying$.complete();
	}

	getCaptcha() {
		var code = "";
		this.captcha_temp = "";
		this._authService.getCaptcha({ type: "login" }).subscribe(
			(response: any) => {
				this.codeImage = response.captchaImage;
				this.captchaCode = response.captchaCode;
				this.captchaToken = response.captchaToken;
				this.showCaptcha = true;
			},
			(err) => {
				this._notificationService.error(
					this._translateService.instant("Error.err_captcha_failed"),
					this._translateService.instant("Error.err_something_went_wrong")
				);
			}
		);
	}
	validateCaptcha() {
		if (this.captcha_temp.split(" ").join("") == this.f.captchaInput.value.split(" ").join("")) {
			return true;
		} else {
			return false;
		}
	}

	updateEmail() {
		this.f.email.setValue(this.f.email.value.trim());
	}
	// for microsoft login
	loginWithMicrosoft() {
		if (this.msalGuardConfig.authRequest) {
			this.mauthService
				.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
				.subscribe((response: AuthenticationResult) => {
					var token = response.accessToken;
					var username = response.account.username;
					this.mauthService.instance.setActiveAccount(response.account);
					this.validateLoginPortal(token, username, "microsoft");
				});
		} else {
			this.mauthService.loginPopup().subscribe((response: AuthenticationResult) => {
				var token = response.accessToken;
				var username = response.account.username;
				this.mauthService.instance.setActiveAccount(response.account);
				this.validateLoginPortal(token, username, "microsoft");
			});
		}
	}

	validateLoginPortal(token, username, provider) {
		var payload = {
			token: token,
			username: this.encryptPassword(username),
			login_auth_type: this.route.snapshot.queryParams?.auth_type == 2 ? 2 : 1,
			provider: provider,
		};
		this._authService.doMicrosoftLogin(JSON.stringify(payload)).subscribe(
			(response: any) => {
				this.loading = true;
				if (response && response.token) {
					store.set("accessToken", response.token);
					store.set("image_per", response.img_per);
					store.set("fflags", response.fflags);
					store.set("is_admin", response.is_admin);
					store.set("is_expired", response.is_expired);
					store.set("is_management", true);
					store.set("is_partner", response?.is_partner);
					store.set("is_partner_org", response?.is_partner_org);
					this.cookieService.set("captch-cookie", "2", 1);
					// this._notificationService.success(
					// 	"Logged In",
					// 	"You have successfully logged in!"
					// );
					// if (!environment.management) {
					// 	if (this.route.snapshot.queryParams.returnUrl) {
					// 		this._router.navigate([`/auth/redirect${this.route.snapshot.queryParams.returnUrl}`]);
					// 	} else {
					// 		this._router.navigate(["/auth/redirect/get-started"]);
					// 		// this._router.navigate(["/auth/redirect/incident_view"]);
					// 	}
					// 	this._router.navigate([response.redirect]);
					// } else {
					if (response?.is_admin) {
						if (this.route.snapshot.queryParams.returnUrl) {
							// this._router.navigate([this.route.snapshot.queryParams.returnUrl]);
							this._document.location.href = `/auth/redirect${this.route.snapshot.queryParams.returnUrl}`;
						} else {
							// this._router.navigate(["/get-started"]);
							this._document.location.href = `/admin/dashboard`;
							// this._router.navigate(["/admin/dashboard"]);
							// this._document.location.href = `/auth/redirect/admin/dashboard`;
						}
					} else {
						let tempSupportRoles = [5, 6, 7, 8];
						if (tempSupportRoles.indexOf(response?.access_type) !== -1) {
							this._document.location.href = `/support/home`;
							// this._router.navigate(["/support"]);
						} else {
							this._router.navigate(["/error/403"]);
						}
					}
					// }
					return new UserActions.LoginSuccessful(response);
				} else if (response && response.redirect) {
					this._notificationService.warning(
						this._translateService.instant("Error.err_please_change_password"),
						this._translateService.instant(response.ui_msg)
					);
					this._router.navigate([response.redirect]);
				}
				this.loading = false;
				// this._notificationService.warning(response.msg,"Auth Failed");
				this.showCaptcha = false;
				// this.getCaptcha();
			},
			(err) => {
				this.loading = false;
				// this._notificationService.error("Login Failed", "Something went wrong");
				let msg = err?.error?.ui_msg;
				if (msg == "Unknown Exception") {
					msg = "Error.err_invalid_username_password";
				} else if (msg == undefined || msg === "") {
					msg = "Error.err_bad_request";
				}
				this._notificationService.error(
					this._translateService.instant(msg),
					this._translateService.instant("Error.err_login_failed"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
			}
		);
	}
	// below code for Google Login
	getAccessToken(): void {
		this.socialAuthService
			.getAccessToken(GoogleLoginProvider.PROVIDER_ID)
			.then((accessToken) => (this.googleAccessToken = accessToken));
	}
}
