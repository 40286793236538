import { Component, OnInit, Input } from '@angular/core';
import { NgEventBus } from "ng-event-bus";

@Component({
  selector: 'app-release-summary',
  templateUrl: './release-summary.component.html',
  styleUrls: ['./release-summary.component.scss']
})
export class ReleaseSummaryComponent implements OnInit {
  @Input() config: any;
	@Input() modifiedtime: any;

  constructor(
    private eventBus: NgEventBus
  ) { }

  ngOnInit(): void {
    //console.log("config", this.config);
  }

  showReleaseSidebar(dat){
    //console.log(dat, "dat-------------")
		if (dat?.value > 0 && dat?.item_filter){
			let payload = {
				"title": "Releases - " + dat.name,
				"app_type": "release-grid",
				"item_filter": dat.item_filter,
        "timescale" : this.config?.timescale
			}
			// //console.log("in inci summary widget -> ", payload)
			this.eventBus.cast('dashboard-view:sidebar', payload);
		}
	}


}
