<div class="server-count">
	<ng-container *ngIf="widgetData?.widget?.option_type">
		<ng-container *ngFor="let item of widgetData?.data">
			<div class="single-server border-bottom ml-1 mr-1 cursor-pointer" id="divServerCount" name="divServerCount">
				<div class="icon-sec ml-50">
					<img [src]="getImg(item)" width="16" height="16" class="imgColor" />
				</div>
				<div class="info-sec">
					<div class="server-count set-color" (click)="showImacdSidebar(item)">
						{{ item.value }}
					</div>
					<div class="server-info">{{ item.name | translate }}</div>
				</div>
			</div>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="!widgetData?.widget?.option_type">
		<ng-container *ngFor="let item of widgetData?.data">
			<div class="single-server border-bottom ml-1 mr-1 cursor-pointer" id="divServerCount" name="divServerCount">
				<div class="icon-sec ml-50">
					<img [src]="getImg(item)" width="16" height="16" class="imgColor" />
				</div>
				<div class="info-sec">
					<div class="server-count set-color" (click)="showImacdSidebar(item)">
						{{ item.count }}
					</div>
					<div class="server-info">{{ item.id | translate }}</div>
				</div>
			</div>
		</ng-container>
	</ng-container>
</div>
