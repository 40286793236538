import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SystemMenuRoutingModule } from "./system-menu-routing.module";
import { CoreCommonModule } from "@core/common.module";
import { SearchMenuComponent } from "./components/search-menu/search-menu.component";
import { AdminPopoverComponent } from "./components/admin-popover/admin-popover.component";
import { AccountSettingsComponent } from "./components/account-settings/account-settings.component";
import { NavbarModule } from "app/common/layout/components/navbar/navbar.module";

import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from "@angular/forms";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { FileUploadModule } from "ng2-file-upload";
import { SharedModule } from "../../shared.module";
import { HotkeyModule } from "angular2-hotkeys";
import { KeyboardShortcutComponent } from "./components/keyboard-shortcut/keyboard-shortcut.component";
import { AccountSettingSkeletonComponent } from "./components/account-setting-skeleton/account-setting-skeleton.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { AccountSidebarComponent } from './components/account-sidebar/account-sidebar.component';
import { NgScrollbarModule } from "ngx-scrollbar";
// import { ProfileAvatarModule } from "app/common/dns/components/profile-avatar/profile-avatar.module";
import { TranslateModule } from "@ngx-translate/core";
import { ProfileAvatarModule } from "app/common/dns/components/profile-avatar/profile-avatar.module";
import { AddSignatureComponent } from './components/add-signature/add-signature.component';
import { DnsModule } from "app/common/dns/dns.module";
import { QuillModule } from "ngx-quill";
@NgModule({
	declarations: [
		SearchMenuComponent,
		AdminPopoverComponent,
		AccountSettingsComponent,
		KeyboardShortcutComponent,
		AccountSettingSkeletonComponent,
		AccountSidebarComponent,
        AddSignatureComponent,
	],
	imports: [
		CommonModule,
		SystemMenuRoutingModule,
		CoreCommonModule,
		FileUploadModule,
		NgSelectModule,
		SweetAlert2Module.forRoot(),
		NgbModule,
		NavbarModule,
		FormsModule,
		SharedModule,
		HotkeyModule.forRoot(),
		NgxIntlTelInputModule,
		NgxSkeletonLoaderModule,
		NgScrollbarModule,
		ProfileAvatarModule,
		TranslateModule,
		SharedModule,
		DnsModule,
		QuillModule.forRoot(),
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	exports: [
		SearchMenuComponent,
		AdminPopoverComponent,
		AccountSettingsComponent,
		KeyboardShortcutComponent,
	],
})
export class SystemMenuModule {}
