import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import cloneDeep from "lodash-es/cloneDeep";
import { FlatpickrOptions } from 'ng2-flatpickr';
import { InfinityModules } from "app/common/dns/types/modules";
import { GlobalConstants } from "app/app.globalConstants";
import { ToastrService } from "ngx-toastr";
import {  throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { convertToCalendarTime } from 'app/common/utils/utils';
import { RequestViewService } from 'app/servicedesk/request/services/request-view.service';

@Component({
  selector: 'app-dns-request-resolve',
  templateUrl: './dns-request-resolve.component.html',
  styleUrls: ['./dns-request-resolve.component.scss']
})
export class DnsRequestResolveComponent implements OnInit {
	@Output("onHideResolve") onHideResolve = new EventEmitter<any>();
	@Output("getRequests") getRequests = new EventEmitter<any>();
	@Input() request_id = "";
	@Input() options;
	@Input() stateKeyIdMap = {};
	@Input() selected_status: any;
	public request_data;
	public request_data_copy;
	currentUserProfileId: any;
	hideInlineEdit: boolean = false;
	req_extra_info: any = {};
	resolve_title: string;
	resolve_btn_text: string;
	resolved_status = []
	sourceIconMap = {
		1: "globe",
		2: "envelope",
		3: "bell",
		4: "mobile",
		5: "phone",
		6: "comment-lines",
		7: "comment-dots",
		8: "microsoft"
	}

	public dateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: true,
		dateFormat: "%b %d, %Y %I:%M %p",
		altFormat: "M d, Y H:i K",
		mode: "single",
		altInputClass: "form-control flat-picker flatpickr-input",
	};
	public editorCreated(event): void {
		// Example on how to add new table to editor
		// this.addNewtable();
	}
	userTZ: string | undefined;
	asset_module = InfinityModules.CMDB;
	asset_permissions: any;
	is_submit_disabled = false

  constructor(
    private _requestViewService: RequestViewService,
    private _translateService: TranslateService,
	private _toastr: ToastrService,
    ) { 
    
  }

  ngOnInit(): void {
	this.onResolve(this.request_id)
	let userParams = JSON.parse(localStorage.getItem("userParams"));
	this.userTZ =  userParams?.user_tz;
	try {
		let today = new Date(new Date().toLocaleString("en-US", { timeZone: this.userTZ }));
		this.dateTimeOptions["defaultDate"] = today
	} catch (e) {
		//console.log("Error in selecting current time based on timezone ",  e);
	}
	let user_permissions = {};
	if (GlobalConstants.dnsPermissions != undefined) {
		user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
	}
	this.asset_permissions = user_permissions[this.asset_module];
  }

onResolve(request_id){
	this._requestViewService
	.getRequestData(request_id)
	.pipe(
		map(
			(response) => {
				this.request_data = response?.request_process;
				this.request_data_copy = cloneDeep(this.request_data);
				this.currentUserProfileId = response["current_user_id"];
				this.req_extra_info["selected_assignee_info"] =  response["selected_assignee_info"];
				this.req_extra_info["custom_field_config"] = response["custom_field_config"]
				this.req_extra_info["group_type"] = response["group_type"];
				this.hideInlineEdit = true;
				if (this.request_data?.basic_info?.state?.id < 4){
					this.request_data["request_resolution_time"] = null
				}
				this.resolve_title = this._translateService.instant("UI.k_resolve_request")
				this.resolve_btn_text = this._translateService.instant("UI.k_resolve")
				this.resolved_status = response?.status?.filter((e)=>
						e['state_id'] == this.stateKeyIdMap["Resolved"]
					)
				if (!this.selected_status?.state_id){
						this.request_data["state_id"] = this.resolved_status[0]["state_id"];
						this.request_data.basic_info["status"] = this.resolved_status[0];
				}
				else{
					this.resolved_status = response?.status?.filter((e)=>
						e['state_id'] == this.selected_status?.state_id
					)
					this.request_data["state_id"] = this.selected_status?.state_id
					this.request_data.basic_info["status"] = this.selected_status
				}
			},
			(error) => {
				//todo
			}
			)
		)
		.subscribe();
	}

	toggleSidebar = (key)=>{
		this.onHideResolve.emit();
	}
  onResolveSubmit(data, args){
		if (data.form.status === 'VALID'){	
			this.is_submit_disabled = true
			this.request_data.basic_info["state"] = this.options?.state_id_map[this.request_data.basic_info?.status?.state_id];
				let cf_data = this.req_extra_info?.custom_field_config?.custom_fields?.filter((d) => d?.section == "resolution");
				this.request_data['custom_field_data'] = this.request_data?.custom_field_data
				this.request_data['custom_fields'] = cf_data
			this.saveRequestData(this.request_data)
		}
	}
	saveRequestData(data) {
		if (data.request_resolution_time instanceof Array) {
			data.request_resolution_time = data.request_resolution_time[0];
		}
		data.request_resolution_time = convertToCalendarTime(data.request_resolution_time)
		if (data.request_resolution_time && data.resolution_comment) {
			let payload = {
				ref_id: data.request_id,
				display_id: data.display_id,
				rich_text_content: {
					description: data.resolution_comment,
					type: "Resolution",
					is_private: false,
					resolution_date: data.request_resolution_time,
				},
				custom_field_data: data?.custom_field_data,
				custom_fields: data?.custom_fields,
			};
			this._requestViewService.saveRichTextData(payload).subscribe((response) => {
				if (response["status"] == "success") {

					// saved rich text
				}
			});
		}
		this._requestViewService
			.editRequest(data.request_id, data)
			.pipe(
				map((response: any) => {
					if (response) {
						this.getRequests.emit()
						this.onHideResolve.emit();
						this.is_submit_disabled = false
						this._toastr.success(
							this._translateService.instant(response["message"]),
							this._translateService.instant("Message.msg_save_success"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
					} else {
						this.is_submit_disabled = false
						this._toastr.error(
							this._translateService.instant("Error.err_request_save_failed"),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
					}
					this.getRequests.emit();
					// Success
				}),
				catchError((error) => {
					this.is_submit_disabled = false
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}
	onStatusChange = (data) => {
		this.request_data["status_id"] = data?.id;
		this.request_data.basic_info["status"] = data;
		this.request_data["state_id"] = data?.state_id;
		this.request_data.basic_info["state"] = this.options?.state_id_map[data?.state_id];
	};	

	updateFieldName(evt, id, dropdown_name, set_field,ref_field){
		let selected = this.options[dropdown_name].filter((e)=>
			e[id] == evt
		)
		set_field  = selected[0][ref_field]
	}

	get_custom_data(config_data, custom_fields){
		let actual_conf_data = {}
		if (config_data && custom_fields){
			config_data.forEach(conf => {
				let data_key = conf?.data_key
				let label = conf?.label
				let value = custom_fields[data_key]
				if (conf?.type == 'date' || conf?.type == 'datetime-local'){
					if (this.request_data.custom_field_data[data_key] instanceof Array){
						this.request_data.custom_field_data[data_key] = this.request_data.custom_field_data[data_key][0]
					}
					if (value instanceof Array){
						value = value[0]
					}
				}
				if (value){
					actual_conf_data[label] = value
				}
			});
		}
		return actual_conf_data
	};

}
