<!-- new UI  -->
<!-- w-manager (for width class)  -->
<!-- <div class="dns-panel-body-sec" *ngIf="releaseManager">
    <div class="panel-data-block">
        <div class="panel-data-title">
            {{ panelConfig?.section_1?.title | translate }}
        </div>
        <div class="custom-drop-down-block">
            <ng-select 
            [items]="panelConfig?.section_1?.options" 
            [(ngModel)]="assigneeName" 
            bindLabel="value" 
            bindValue="value"
            [clearable]="false"
            (change)="onChangeData($event)"
            >
                <ng-template ng-label-tmp let-item="item">
                    <div class="list-item-block">
                        <span>{{nameExtractorHandler(item.value) | uppercase}}</span>
                        <span>{{item.value}}</span>
                    </div>                    
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="custom-option-dropdown">
                        <span>{{nameExtractorHandler(item.value) | uppercase}}</span>
                        <span>{{item.value}}</span>
                    </div>                    
                </ng-template>
            </ng-select>
        </div>
    </div>
</div> -->

<!-- new UI -->
<!-- w-manager-assignee (for width class)  -->
<!-- <div class="dns-panel-body-sec" *ngIf="assignee">
    <div class="panel-data-block">
        <div class="panel-data-title">
            {{ panelConfig?.section_2?.title | translate}}
        </div>
        <div class="custom-drop-down-block">
            <ng-select 
            [items]="panelConfig?.section_2?.options" 
            [(ngModel)]="assigneeName" 
            bindLabel="value" 
            bindValue="value"
            [clearable]="false"
            (change)="onChangeData($event)"
            >
                <ng-template ng-label-tmp let-item="item">
                    <div class="list-item-block">
                        <span>{{nameExtractorHandler(item.value) | uppercase}}</span>
                        <span>{{item.value}}</span>
                    </div>                    
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="custom-option-dropdown">
                        <span>{{nameExtractorHandler(item.value) | uppercase}}</span>
                        <span>{{item.value}}</span>
                    </div>                    
                </ng-template>
            </ng-select>
        </div>
    </div>    
</div> -->

<!-- new UI  -->
<!-- w-status (for width class)  -->
<!-- <div class="dns-panel-body-sec" *ngIf="status">
    <div class="panel-data-block">
        <div class="panel-data-title">
            {{ panelConfig?.section_3?.title | translate}}
        </div>
        
        <app-status-picker
            [inputConfig]="{
                isTextMode: true,
                hideLabel: true
            }"
            [options]="panelConfig?.section_3?.options"
            (setStatus)="onStatusChangeEdit($event)"
            [value]="panelConfig?.section_3?.options?.id"
            [showLabel]="false"
        ></app-status-picker>
    </div>
</div> -->

<!-- new UI  -->
<!-- <div class="dns-panel-body-sec" *ngIf="priority">
    <div class="panel-data-block">
        <div class="panel-data-title">
            {{ panelConfig?.section_4?.title | translate}}
        </div>
        <app-select-badge
            [inputConfig]="{
                hideLabel: true,
                inputOptions: panelConfig?.section_4?.options,
                id: 'priority',
                isEditDisabled: disable_config
            }"
            (onValueChange)="statusBadgeChange($event)"
		></app-select-badge>    
    </div>    
</div> -->

<!-- new UI  -->
<!-- <div class="dns-panel-body-sec" *ngIf="risk">
    <div class="panel-data-block">
        <div class="panel-data-title">
            {{ panelConfig?.section_5?.title | translate}}
        </div>
        <app-select-badge	
            [inputConfig]="{
                hideLabel: true,
                inputOptions: panelConfig?.section_5?.options,
                id: 'risk',
                isEditDisabled: disable_config
            }"
            (onValueChange)="statusBadgeChange($event)"
		></app-select-badge>
    </div>
</div> -->

<!-- <div class="dns-panel-body-sec" *ngIf="releaseType">
    <div class="panel-data-block">
        <div class="panel-data-title">
            {{ panelConfig?.section_6?.title | translate }}
        </div>
        <app-select-badge	
            [inputConfig]="{
                hideLabel: true,
                inputOptions: panelConfig?.section_6?.options,
                id: 'release_type',
                isEditDisabled: disable_config
            }"
            (onValueChange)="statusBadgeChange($event)"
		></app-select-badge>
    </div>
</div> -->

<div class="dns-panel-body-sec" *ngIf="dueDate">
    <div class="panel-data-block">
        <div class="panel-data-title">
            {{ panelConfig?.dueDate?.title | translate}}
        </div>
        <div class="panel-edit-select-box releaseDueDate">
            <div class="input-group">
                <input
                    class="form-control"
                    placeholder="YY-MM-DD"
                    name="dp"
                    ngbDatepicker
                    #end="ngbDatepicker"
                    id="datepicker"
                    [(ngModel)]="defaultDueDate"
                    (ngModelChange)="isSelectedDate(defaultDueDate)"
                />
                <span class="fa-light fa-calendar custom-calender" (click)="end.toggle()" type="button"></span>
            </div>
        </div>        
    </div>
</div>

