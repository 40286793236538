<div class="slideout-content default-slideout">
	<div
		class="modalsd modal-slide-in sdfade new-user-modal"
		id="modals-slide-in"
	>
		<div class="modal-dialog sidebar-md">
			<div class="modal-content add-new-user pt-0">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">
						{{ "Message.msg_invite_partneruser_header" | translate }}.
					</h5>
					<button
						type="button"
						id="btnInviteClose"
						name="btnInviteClose"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						(click)="
							toggleSidebar('invite-partneruser', inviteUserForm)
						"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body">
					<div class="modal-content-header">
						<div
							class="content-header p-1 mb-2 border-bottom card-text"
						>
							<div class="font-small-3">
								{{ "Message.msg_invite_partneruser_descr" | translate }}
							</div>
						</div>
					</div>
					<form #inviteUserForm="ngForm" class="flex-auto-height">
						<div class="modal-content-body flex-1">
							<ng-scrollbar
								class="custom-scrollbar section-scrollbar"
							>
								<div class="px-1">
									<label
										class="form-label"
										for="txtUsrEmail0"
										>{{
											"Message.msg_add_partneruser_email" | translate
										}}</label
									>
									<div
										*ngFor="
											let item of defaultInvites;
											let i = index;
											let first = first;
											let last = last
										"
									>
										<!-- @heightIn -->
										<div class="row">
											<div
												class="form-group col-xs-9 col-md-9 col-xl-9 col-lg-9"
											>
												<input
													type="text"
													class="form-control"
													placeholder="it-agent@example.com"
													id="txtUsrEmail{{ i }}"
													name="txtUsrEmail{{ i }}"
													[(ngModel)]="
														defaultInvites[i]
															.userEmail
													"
													#TDEmailRef="ngModel"
													required
													placeholder="john.doe@email.com"
													[appEmailCheck]="
														defaultInvites[i]
															.userEmail
													"
													[class.error]="
														TDEmailRef.errors
															?.invalidemail &&
														inviteUserForm.submitted
													"
												/>
												<span
													id="formMsg{{ i }}"
													*ngIf="
														inviteUserForm.submitted
													"
													class="invalid-form"
												>
													<small
														class="form-text text-danger"
														*ngIf="
															TDEmailRef.errors
																?.invalidemail
														"
														>{{
															"UI.k_alert_email_invalid"
																| translate
														}}</small
													>
													<small class="form-text text-danger"
													*ngIf="TDEmailRef.errors?.max_email">
													{{	"UI.k_email_max_validation"	| translate:{"maxLimit": TDEmailRef.errors?.limit} }}
													</small>
												</span>
											</div>
											<div
												*ngIf="!first"
												class="form-group col-xs-1 col-md-1 col-xl-1 col-lg-1 px-0 mt-25"
											>
												<button
													id="btnDelete__{{ i }}"
													class="btn"
													(click)="deleteItem(i)"
													rippleEffect
												>
													<i
														class="fa-light fa-trash-can mr-25 text-danger"
														style="font-size: 16px"
													></i>
												</button>
											</div>
										</div>
										<div *ngIf="last" class="form-group">
											<button
												id="btnAdd__{{ i }}"
												class="btn btn-flat-primary btn-sm"
												(click)="addItem()"
												rippleEffect
											>
												<i
													class="fa-light fa-plus mr-25"
												></i>
												<span>{{
													"UI.k_add_invite_usr"
														| translate
												}}</span>
											</button>
										</div>
									</div>
									<div class="row">
										<div class="col-6">
											<div
												*ngIf="
													defaultInvites?.length == 0
												"
											>
												<button
													class="btn btn-icon btn-primary"
													(click)="addItem()"
													rippleEffect
													id="btnAddRow"
													name="btnAddRow"
												>
													{{
														"UI.k_add_rows"
															| translate
													}}
													<i
														class="fa-light fa-plus-circle mr-25"
													></i>
												</button>
											</div>
										</div>
									</div>
								</div>
							</ng-scrollbar>
						</div>
						<div class="modal-content-footer flex-initial">
							<div
								class="d-flex justify-content-end border-top px-1 mt-1 py-1"
							>
								<button
									id="btnSubmit"
									class="btn btn-primary"
									type="submit"
									(click)="
										inviteUsers(
											'invite-partneruser',
											inviteUserForm,
											defaultInvites
										)
									"
									rippleEffect
								>
									<i class="fa-light fa-envelope"></i>
									<span>{{
										"UI.k_send_invite" | translate
									}}</span>
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
