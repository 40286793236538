<div class="selct-feature group-list-feature status-list-picker">
	<div class="select-sec">
		<div class="form-group">
			<label *ngIf="showLabel" class="form-label" [for]="inputConfig?.id || 'UI.k_status'">
				{{ inputConfig?.header | translate }}{{ isRequired ? "*" : "" }}
			</label>
			<ng-select
				[items]="options"
				[bindLabel]="inputConfig?.bindLabel || 'name'"
				[bindValue]="inputConfig?.bindValue || 'id'"
				[name]="inputConfig?.name || 'selStatus'"
				[id]="inputConfig?.id || 'selStatus'"
				[dropdownPosition]="dropdownPos"
				[placeholder]="inputConfig?.placeholder || 'UI.k_sel_status' | translate"
				[notFoundText]="inputConfig?.notFoundText || 'UI.k_no_status' | translate"
				(change)="onSelect($event)"
				[(ngModel)]="value"
				[searchFn]="customSearchFn"
				[hideSelected]="true"
				[closeOnSelect]="true"
				[multiple]="inputConfig?.is_multiple"
				[clearable]="isclearable"
				[disabled]="inputConfig?.isEditDisabled"
				[required]="isRequired"
				#TDOptionsVal="ngModel"
			>
				<ng-template ng-option-tmp ng-label-tmp let-item="item">
					<ng-container>
						<div class="group-lists">
							<div *ngIf="item?.icon" class="badge badge-light-{{ item.priorityColor || item.color }}">
								<i class="fa-light mr-25 {{ item.priorityIcon || item.icon }}"></i>
								{{
									(inputConfig?.bindLabel
										? item[inputConfig?.bindLabel]
										: item.priorityName || item.name
									) | translate
								}}
							</div>
							<div
								*ngIf="!item?.icon"
								class="custom-squre"
								[ngClass]="'bg-' + item?.color"
								[ngStyle]="{
									'background-color': item?.color
								}"
							></div>
							<span *ngIf="!item?.icon">{{
								(inputConfig?.bindLabel ? item[inputConfig?.bindLabel] : item.priorityName || item.name)
									| translate
							}}</span>
							<div
								class="right-arrow custom-text"
								*ngIf="inputConfig?.isTextMode"
								[ngStyle]="{
									'background-color': item?.background_color,
									color: item?.text_color || item?.color
								}"
							>
								{{ item.state | translate }}
							</div>
							<div
								class="right-arrow custom-icon"
								*ngIf="this.inputConfig && !inputConfig?.isTextMode"
								[ngStyle]="{
									'background-color': item?.background_color,
									color: item?.text_color || item?.color
								}"
							>
								<i [class]="item.icon"></i>
							</div>
						</div>
					</ng-container>
				</ng-template>
			</ng-select>
			<span class="error" *ngIf="TDOptionsVal?.invalid && formRef?.submitted">
				{{ "UI.k_field_required" | translate }}
			</span>
		</div>
	</div>
</div>
<!-- </div>
</div> -->
