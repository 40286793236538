import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomThemeRoutingModule } from './custom-theme-routing.module';
import { CustomThemeComponent } from './custom-theme.component';
import { DnsModule } from '../../dns.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { CoreDirectivesModule } from '@core/directives/directives';

@NgModule({
  declarations: [CustomThemeComponent],
  imports: [
    CommonModule,
    CustomThemeRoutingModule,
    DnsModule,
    NgSelectModule,
    CoreDirectivesModule
  ],
  exports: [CustomThemeComponent]
})
export class CustomThemeModule { }
