<div class="custom-display">
	<h4>{{ categoryObject.value.title }}</h4>
	<div>
		<ng-container *ngFor="let itemIcon of categoryObject.iconList">
			<i
				class="{{ itemIcon.class }}"
				(click)="buttonEvent(itemIcon?.iconName)"
			></i>
		</ng-container>
	</div>
</div>
<ng-container *ngFor="let item of categoryObject.value.options">
	<div *ngIf="item?.isCollapsible" class="btn-group custom-listDown">
		<div ngbDropdown>
			<button
				ngbDropdownToggle
				class="btn btn-flat-primary"
				type="button"
				id="dropdownMenuButton100"
				rippleEffect
			>
				<!-- <span [data-feather]="'database'" [class]="'mr-25'"></span> -->
				<span><i class="fa-light fa-database mr-25"></i></span>
				{{ item?.title }}
			</button>
			<div ngbDropdownMenu aria-labelledby="dropdownMenuButton100">
				<a
					ngbDropdownItem
					(click)="editSelectedItem(item?.subcategory1, item)"
					[ngClass]="{
						selected:
							item?.ng_model_value &&
							item?.ng_model_value === item?.subcategory1
					}"
					>{{ item?.subcategory1 }}
					<span>
						<!-- <span [data-feather]="'edit-2'" [class]="'mr-25'"></span> -->
						<span><i class="fa-light fa-pen mr-25"></i></span> </span
				></a>
				<a
					ngbDropdownItem
					(click)="editSelectedItem(item?.subcategory2, item)"
					[ngClass]="{
						active:
							item?.ng_model_value &&
							item?.ng_model_value === item?.subcategory2
					}"
					>{{ item?.subcategory2 }}
					<span>
						<!-- <span [data-feather]="'edit-2'" [class]="'mr-25'"></span> -->
						<span><i class="fa-light fa-pen mr-25"></i></span>
					</span>
				</a>
				<a
					ngbDropdownItem
					(click)="editSelectedItem(item?.subcategory3, item)"
					[ngClass]="{
						selected:
							item?.ng_model_value &&
							item?.ng_model_value === item?.subcategory3
					}"
					>{{ item?.subcategory3 }}
					<span>
						<!-- <span [data-feather]="'edit-2'" [class]="'mr-25'"></span> -->
						<span><i class="fa-light fa-pen mr-25"></i></span> </span
				></a>
			</div>
		</div>
	</div>
	<div *ngIf="!item?.isCollapsible">
		<button
			type="button"
			id="dropdownMenuButton100"
			rippleeffect=""
			class="dropdown-toggle btn custom-btn"
			aria-expanded="true"
		>
			Server
		</button>
	</div>
</ng-container>
