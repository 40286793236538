import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotificationGridComponent } from "./components/notification-grid/notification-grid.component";
import { AuthGuard } from "app/common/guards/auth.guards";

const routes: Routes = [
	{
		path: "list",
		component: NotificationGridComponent,
		canActivate: [AuthGuard],
		data: {
			module: ["user"],
			keyList: ["add", "edit", "delete", "view"],
		},
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class NotificationRoutingModule {}
