import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {CoreSidebarService} from "@core/components/core-sidebar/core-sidebar.service";
import {TranslateService} from "@ngx-translate/core";
import {CustomerCard} from "app/admin/models";
import {AdminService} from "app/admin/service";
import {User} from "app/auth/models";
import {CountryISO, PhoneNumberFormat, SearchCountryField} from "ngx-intl-tel-input";
import {ToastrService} from "ngx-toastr";
import {throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Component({
	selector: "app-edit-admin-user",
	templateUrl: "./edit-admin-user.component.html",
	styleUrls: ["./edit-admin-user.component.scss"],
})
export class EditAdminUserComponent implements OnInit {
	logo: any;
	logo_height: string;
	logo_width: string;
	public MAX_IMAGE_SIZE = 20971520;
	public ALLOWED_TYPES = ["image/png", "image/jpeg"];
	public showQueue = false;
	public multipleFiles = false;
	public displayResoluion = false;
	public squareImage = false;
	public imageType = "AdminUserDetail";
	public imgDetailsObject = {
		aspectRatio: "3 / 1",
		cropperMinWidth: "128",
		maintainAspectRatio: false,
	};
	currentUser: User;
	public tempImage;
	public avatarColor;
	public fullName: string;
	public currentImgDetails;
	public currentAvatarColor;
	public activeAvatar;
	public file_uploaded: boolean = false;
	CountryISO = CountryISO;
	separateDialCode = true;
	SearchCountryField = SearchCountryField;
	PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom, CountryISO.India];
	organizationData: any;
	@Output("onHideProfile") onHideProfile = new EventEmitter<any>();
	public disableSave = false;

	constructor(
		private _coreSidebarService: CoreSidebarService,
		private _service: AdminService,
		private _toastr: ToastrService,
		private _translateService: TranslateService
	) {}

	ngOnInit(): void {
		this.getOrgDetails();
	}
	toggleSidebar = (name): void => {
		this.onHideProfile.emit("hide");
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
	};
	removeImage = (imageType) => {
		if (imageType == "logo") {
			this.logo = null;
			localStorage.removeItem("imglogo");
		}
	};
	updateImageDimension(imgUrl, scope) {
		let img = new Image();
		img.src = imgUrl;
		img.onload = function (event) {
			let loadedImage = event.currentTarget;
			if (loadedImage["height"] > loadedImage["width"]) {
				scope.logo_height = "113";
				scope.logo_width = "";
			} else {
				scope.logo_height = "";
				scope.logo_width = "287";
			}
		};
	}
	uploadLogo = ($event) => {
		this.logo = $event;
		this.updateImageDimension(this.logo, this);
	};
	onFormChange = (form) => {
		form.form.touched = true;
	};
	ProfileBgColor = ($event) => {
		this.avatarColor = $event;
	};
	removeUserImage($event, form): void {
		this.tempImage = this.currentUser.profile_image;
		this.currentUser.profile_image = "";
		this.organizationData.user.profile_image = "";
		this.organizationData.user.delete_image = true;
		this.file_uploaded = false;
		form.form.touched = true;
	}
	profileImage = ($event, form) => {
		this.organizationData.user.profile_image = $event;
		this.organizationData.user.new_image = true;
		form.form.touched = true;
	};
	currrentColor = ($event) => {
		if ($event == "image") {
			this.currentUser.profile_image = this.tempImage;
		} else {
		}
	};

	getOrgDetails = (): void => {
		this._service.getCustomerData({}).subscribe({
			next: (response: CustomerCard) => {
				this.organizationData = response;
				this.logo = this.organizationData.organization.logo;
			},
			error: (e: any) => {
				console.error(e);
			},
		});
	};

	onSubmit = (data) => {
		if (data.form.valid === true) {
			// this.organizationData.organization["logo"] = this.logo;
			this.disableSave = true;
			let payload = {};
			payload["full_name"] = this.organizationData.user.full_name;
			payload["phone"] = this.organizationData.user.phone_number;
			payload["company_name"] = this.organizationData.organization.company_name;
			payload["email_id"] = this.organizationData.user.email;
			this._service
				.saveOrgConfig(payload)
				.pipe(
					map((response: any) => {
						if (response) {
							if (response["status"] == "success") {
								this._toastr.success(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Message.msg_save_success"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
								this.onHideProfile.emit("hide");
								this._coreSidebarService.getSidebarRegistry("edit-user-detail").toggleOpen();
							} else {
								this._toastr.error(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Error.err_save_failed"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							}
							this.disableSave = false;
						}
						// Success
					}),
					catchError((error) => {
						this._toastr.error(
							this._translateService.instant("Error.err_save_failed"),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						this.disableSave = false;
						return throwError(error.statusText);
					})
				)
				.subscribe();
		}
	};
}
