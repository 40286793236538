<section class="main-congratulation body-bg">
	<div class="inner-congratulation box-shadow-3">
		<div class="congratulation-containt">
			<h1 class="fw-500 mb-1">
				{{ "UI.k_congratulations" | translate }} <span>{{ userName }}</span>
			</h1>
			<p>
				{{
					"Message.msg_purchase_success"
						| translate
							: {
									product_name: productName
							  }
				}}
			</p>
			<button type="button" routerLink="/admin/dashboard" class="btn btn-lg btn-white primary-color" rippleEffect>
				{{ "UI.k_go_to_dashboard" | translate }}
			</button>
		</div>
	</div>
</section>
