<div class="full-body-height">
	<div class="main-content flex-height">
		<div class="main-sec">
			<div class="action-bar" [ngClass]="isExpandSearchWidth ? 'expanded-search' : ''" *ngIf="!fromExternalComponent">
				<div class="inner-section">
					<div class="left-section">
						<ng-container *ngIf="(users?.length > 0 && showData) || filterData">
							<div class="action-search">
								<app-search
								(getfilterData)="getFilterSearch($event)"
								[filterDetails]="getUserList"
								[showDataTable]="showDataTable"
								[columnOptions]="columnOptions"
								[conditionOptions]="conditionOptions"
								[showRightsection]="showRightsection"
								[savedData]="savedData"
								[Options]="option"
								[module]="module"
								(genericFilter)="genericSearchFilter($event)"
								(isExpandSearchWidth)="getSearchWidth($event)"
								></app-search>
							</div>
						</ng-container>
					</div>
					<div class="right-section">
						<div class="pull-right align-items-center d-flex p-0 m-0">
							<div
								class="btn-group cursor-pointer mr-1"
								role="group"
								aria-label="User Toggle View"
								*ngIf="users.length > 0 && showData"
							>
								<button
									type="button"
									class="btn btn-outline-tertiary btn-icon"
									id="btnUsrGrid"
									name="btnUsrGrid"
									(click)="userGrid()"
									rippleEffect
									routerLinkActive="active"
									[routerLink]="['/users']"
									tooltipClass="fadeInAnimation"
									ngbTooltip="{{ 'UI.k_grid_view' | translate }}"
									placement="bottom"
									[attr.data-testid]="'btnGridView'"
								>
									<i class="fa-light fa-table"></i>
								</button>
								<button
									type="button"
									class="btn btn-outline-tertiary btn-icon"
									(click)="userCard()"
									id="btnUsrCard"
									name="btnUsrCard"
									rippleEffect
									tooltipClass="fadeInAnimation"
									ngbTooltip="{{ 'UI.k_card_view' | translate }}"
									placement="bottom"
									[attr.data-testid]="'btnCardView'"
								>
									<i class="fa-light fa-grid-2"></i>
								</button>
							</div>
							<!--*ngIf="pendingInvitations > 0"-->
							<a
								class="nav-link nav-link-style mr-2 position-relative d-inline-block"
								tooltipClass="fadeInAnimation"
								ngbTooltip="{{ 'UI.k_pending_invitations' | translate }}"
								placement="bottom"
								(click)="toggleInvitedUsers('invite-user-grid')"
								id="btnInviteGrid"
								name="btnInviteGrid"
								[attr.data-testid]="'btnInviteGrid'"
							>
								<i class="fa-light fa-envelope font-medium-5"></i>
								<span
									class="badge badge-warning font-small-1 badge-up"
									[ngClass]="{ 'count-animation': this.countClass === true }"
								>
									{{ pendingInvitations }}
								</span>
							</a>
							<button
								*ngIf="permissions?.add"
								class="btn btn-primary"
								rippleEffect
								(click)="toggleAddUser('add-user-profile')"
								tooltipClass="fadeInAnimation"
								id="btnInviteUser"
								name="btnInviteUser"
								[attr.data-testid]="'btnInviteUser'"
							>
								<i class="fa-light fa-plus font-small-3"></i>&nbsp;
								<span class="d-none d-sm-inline-block font-small-3">
									{{ "UI.k_invite_user" | translate }}
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="action-section">
				<div class="table-view flex-height">
					<dns-server-grid
						[rows]="users"
						[columns]="columns"
						[count]="userCount"
						(editCallback)="onUpdate($event)"
						(page)="setPage($event)"
						(sort)="onSort($event)"
						[options]="options"
						[isEditable]="permissions.edit"
						(setlimit)="setLimit($event)"
						class="bootstrap core-bootstrap"
						[permissions]="permissions"
						[emptyData]="empty_data"
						[showData]="showData"
						[filterData]="filterData"
						[limit]="limit"
						[offset]="pageNumber"
					></dns-server-grid>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- <div class="content-wrapper">
	<div class="content-body">
		<div class="align-items-center d-flex mb-1 justify-content-between">
			<app-search
				(getfilterData)="getFilterSearch($event)"
				[filterDetails]="getUserList"
				[showDataTable]= showDataTable
				[columnOptions]= columnOptions
				[conditionOptions] = conditionOptions
				[showRightsection] = showRightsection
				[savedData]="savedData"
				[Options] = "option"
			></app-search>
			<div class="pull-right align-items-center d-flex p-0 m-0">
				<div
					class="btn-group cursor-pointer"
					*ngIf="users.length > 0 && showData"
				>
					<div ngbDropdown class="no-arrow">
						<span
							ngbDropdownToggle
							id="drpMenuBtn"
							tooltipClass="fadeInAnimation"
							ngbTooltip="{{ 'UI.k_toggle' | translate }} {{
								'UI.k_view' | translate
							}}"
						>
							<i class="fa-light fa-table font-medium-5 mr-1"></i>
						</span>
						<div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
							<a
								ngbDropdownItem
								href="javascript:void(0)"
								(click)="userCard()"
								id="linkUsrCard"
								name="linkUsrCard"
								>{{ "UI.k_card_view" | translate }}</a
							>
							<a
								ngbDropdownItem
								href="javascript:void(0)"
								(click)="userGrid()"
								id="linkUsrGrid"
								name="linkUsrGrid"
								>{{ "UI.k_grid_view" | translate }}</a
							>
						</div>
					</div>
				</div>
				<a
					class="nav-link nav-link-style mr-2 position-relative d-inline-block pt-0 pb-0"
					tooltipClass="fadeInAnimation"
					ngbTooltip="{{ 'UI.k_pending_invitations' | translate }}"
					(click)="toggleInvitedUsers('invite-user-grid')"
					id="btnInviteGrid"
					name="btnInviteGrid"
					*ngIf="pendingInvitations > 0"
				>
					<i class="fa-light fa-users font-medium-5"></i>
					<span class="badge badge-warning font-small-1 badge-up">
						{{ pendingInvitations }}
					</span>
				</a>
				<button
					*ngIf="permissions?.add"
					class="btn btn-primary"
					rippleEffect
					(click)="toggleAddUser('add-user-profile')"
					tooltipClass="fadeInAnimation"
					ngbTooltip="{{ 'UI.k_invite_user' | translate }}"
					id="btnInviteUser"
					name="btnInviteUser"
				>
					<i class="fa-light fa-plus font-small-3"></i>&nbsp;
					<span class="d-none d-sm-inline-block font-small-3">
						{{ "UI.k_invite_user" | translate }}
					</span>
				</button>
			</div>
		</div>
		<div>
			<dns-server-grid
				[rows]="users"
				[columns]="columns"
				[count]="userCount"
				(editCallback)="onUpdate($event)"
				(page)="setPage($event)"
				(sort)="onSort($event)"
				[options]="options"
				[isEditable]="permissions.edit"
				(setlimit)="setLimit($event)"
				class="bootstrap core-bootstrap"
				[permissions]="permissions"
				[emptyData]="empty_data"
				[showData]="showData"
				[filterData]="filterData"
			>
			</dns-server-grid>
		</div>
	</div>
</div> -->

<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="user-profile" overlayClass="modal-backdrop">
	<app-user-profile
		[action]="currentEvent"
		[user]="currentUser"
		(getUserList)="getOptions()"
		*ngIf="hideProfile"
		(onHideProfile)="onHideProfile($event)"
	></app-user-profile>
</core-sidebar>

<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="add-user-profile"
	overlayClass="modal-backdrop"
>
	<app-invite-users *ngIf="hideInvite" (onHideInvite)="onHideInvite($event)"> </app-invite-users>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="invite-user-grid"
	overlayClass="modal-backdrop"
>
	<app-invite-user-grid *ngIf="hideInviteGrid" (onHideInviteGrid)="onHideInviteGrid($event)"> </app-invite-user-grid>
</core-sidebar>

<ng-template #modDeletePopup let-modal>
	<div class="modal-header">
		<h5 class="modal-title" id="myModalLabel1">
			{{ "UI.k_current_user_mapping" | translate }}
		</h5>
		<span
			id="btnClose"
			name="btnClose"
			data-dismiss="modal"
			aria-label="Close"
			(click)="modal.dismiss('Cross click')"
		>
			<i class="fa-light fa-xmark mb-0 mr-sm-25 mr-0 pull-right"></i>
			<!--<i class="fa-light fa-times mb-0 mr-sm-25 mr-0 pull-right font-small-4"></i>-->
		</span>
	</div>
	<div class="modal-body" tabindex="0" ngbAutofocus>
		<form #userForm="ngForm">
			<div class="">
				<div *ngFor="let item of used_modules | keyvalue">
					<div *ngIf="item.key == 'Team'" class="alert alert-primary mb-50" role="alert">
						<div class="alert-heading d-flex justify-content-center">
							{{
								"Message.msg_validate_user_team"
									| translate
										: {
												profile_name: selectedUser.profile.name,
												value_length: item.value.length
										  }
							}}
						</div>
					</div>
					<div *ngIf="item.key == 'Requester'" class="alert alert-primary mb-50" role="alert">
						<div class="alert-heading d-flex justify-content-center">
							{{
								"Message.msg_validate_user_requester"
									| translate
										: {
												profile_name: selectedUser.profile.name,
												value_length: item.value.length
										  }
							}}
						</div>
					</div>
					<div *ngIf="item.key == 'Department'" class="alert alert-primary mb-50" role="alert">
						<div class="alert-heading d-flex justify-content-center">
							{{
								"Message.msg_validate_user_department"
									| translate
										: {
												profile_name: selectedUser.profile.name,
												value_length: item.value.length
										  }
							}}
						</div>
					</div>
					<div *ngIf="item.key == 'Service'" class="alert alert-primary mb-50" role="alert">
						<div class="alert-heading d-flex justify-content-center">
							{{
								"Message.msg_validate_user_service"
									| translate
										: {
												profile_name: selectedUser.profile.name,
												value_length: item.value.length
										  }
							}}
						</div>
					</div>
					<div *ngIf="item.key == 'KB'" class="alert alert-primary mb-50" role="alert">
						<div class="alert-heading d-flex justify-content-center">
							{{
								"Message.msg_validate_user_kb"
									| translate
										: {
												profile_name: selectedUser.profile.name,
												value_length: item.value.length
										  }
							}}
						</div>
					</div>
				</div>
			</div>
			<div class="row mt-2 mb-2">
				<div class="form-group col-md-4">
					<label for="selDeptHead"
						>{{ "UI.k_replacement" | translate }}<span class="required-class"> &nbsp;&nbsp;*</span>
					</label>
					<ng-select
						[items]="user_list"
						[searchable]="true"
						bindLabel="full_name"
						bindValue="profile_id"
						[searchFn]="customSearchFn"
						placeholder="{{ 'UI.k_select_user' | translate }}"
						name="selDeptHead"
						id="selUser"
						[(ngModel)]="selectedProfile"
						#TDNameRef="ngModel"
						required
						[class.error]="!TDNameRef.valid && userForm.submitted"
						notFoundText="{{ 'UI.k_no_items_found' | translate }}"
					>
					<ng-template
							ng-label-tmp
							let-item="item"
						>
						<span
							class="d-flex align-items-center"
							>
							<div class="avatar avatar-sm">
							<div
								*ngIf="
									item?.profile_image;
									else customAvatar
								"
							>
								<img
									class="rounded-circle mr-1"
									src="{{ item.profile_image }}"
									alt="datatable-avatar"
								/>
							</div>
							<ng-template #customAvatar>
								<div
									class="avatar avatar-sm mr-50 ml-0"
									[ngStyle]="{
										'background-color':
											item.avatar_color
												? item.avatar_color
												: '#f59518'
									}"
								>
									<div class="avatar-content">
										{{
											item.full_name
												| initials
												| slice: O:2
										}}
									</div>
								</div>
							</ng-template>
						</div>
								
							<span class="font-small-2 fw-500 mb-0">
								{{ item?.full_name | slice: 0:18 }}
								{{ item?.full_name?.length > 18 ? ".." : "" }}
							</span>
							
						</span>
					</ng-template>
					<ng-template
					ng-option-tmp
					let-item="item"
					let-index="index"
					let-search="searchTerm"
				>
					<span class="d-flex">
						<div
							class="avatar mr-50 ml-0"
							[ngClass]="bg-light-primary"
							style="
								align-content: center;
								width: 32px;
								height: 32px;
							"
						>
							<div
								*ngIf="
									item?.profile_image;
									else customAvatar
								"
							>
								<img
									class="rounded-circle mr-1"
									src="{{ item.profile_image }}"
									height="32"
									width="32"
									alt="datatable-avatar"
								/>
							</div>
							<ng-template #customAvatar>
								<div
									class="avatar mr-1 ml-0"
									[ngStyle]="{
										'background-color':
											item.avatar_color
												? item.avatar_color
												: '#f59518'
									}"
								>
									<div class="avatar-content">
										{{
											item.full_name
												| initials
												| slice: O:2
										}}
									</div>
								</div>
							</ng-template>
						</div>
						<div class="cell-line-height">
							<p
								class="font-medium-1 font-weight-bold line-height-1 mb-25"
							>
								{{ item.full_name }}
							</p>
							<span class="text-muted font-small-2">
								{{ item.email }}</span
							>
						</div>
					</span>

					</ng-template>


					</ng-select>
					<span *ngIf="TDNameRef.invalid && userForm.submitted" class="invalid-form">
						<small *ngIf="TDNameRef.errors.required" class="form-text text-danger">
							{{ "UI.k_field_required" | translate }}
						</small>
					</span>
				</div>
				<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 mt-2">
					<div class="alert alert-danger mb-50" role="alert">
						<div class="alert-heading">
							{{ "UI.k_replace_user" | translate }}
						</div>
					</div>
				</div>
			</div>
			<div class="row d-flex justify-content-end mr-0">
				<button
					(click)="onSubmitReplacement()"
					type="submit"
					class="btn btn-primary"
					rippleEffect
					id="btnCommentSave"
					name="btnCommentSave"
					[attr.data-testid]="'btnCommentSave'"
				>
					<span class="align-middle d-sm-inline-block d-none">{{ "UI.k_save" | translate }} </span>
				</button>
			</div>
		</form>
	</div>
</ng-template>
