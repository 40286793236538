import { NgForm } from '@angular/forms';
import { Component, Input, OnInit, Output, ViewChild } from "@angular/core";
import { repeaterAnimation } from "app/common/dns/animations/animations";

@Component({
	selector: "dns-sms-config",
	templateUrl: "./dns-sms-config.component.html",
	styleUrls: ["./dns-sms-config.component.scss"],
	animations: [repeaterAnimation],
})
export class DnsSmsConfigComponent implements OnInit {
	@Input() config: any;
	@ViewChild("smsConfigForm", {static: true}) smsForm: NgForm;

	constructor() {
		this.config = {
			url: "",
			title: "",
			is_get_method: false,
			is_https: false,
			add_dial_code:false,
			params: [],
		};
	}

	ngOnInit(): void {
		if(this.config?.params){
		    this.config?.params?.forEach((element, i) => {
		        element.id = i+1;
		    })
		}
	}

	onAddRequestParameter = () => {
		if (this.config?.params && this.config?.params.length > 0) {
		    let id = this.config?.params[this.config?.params?.length - 1]?.id + 1;
			this.config.params.push({ key: "", value: "", id:  id});
		} else {
			this.config["params"] = [];
			this.config.params.push({ key: "", value: "", id: 1 });
		}
		this.smsForm.control.markAsDirty()
	};

	onDeleteRequestParameter = (id) => {
	    let index = this.config?.params?.findIndex(d => d?.id == id);
		//console.log(index)
	    if(index != -1){
		    this.config.params.splice(index, 1);
	    }
		if (this.config.params.length == 0) {
			this.config.params = [];
		}
		this.smsForm.control.markAsDirty()
	};

	onCheckboxChange = (event, type) => {
		switch (type) {
			case "http":
				this.config.is_https = event?.target?.checked;
				break;
			case "method":
				this.config.is_get_method = event?.target?.checked;
				break;
			case "dial":
				this.config.add_dial_code = event?.target?.checked;
				break;
				
		}
	};

	public onSave = (cb = null) => {
		let payload = JSON.parse(JSON.stringify(this.config));
        this.smsForm.onSubmit(undefined);
		if (this.smsForm.valid && cb) {
			cb(payload);
		}
	};

	public onDelete = (cb = null) => {
        this.smsForm.onReset();
	};
}
