<div class="mspdropdown">
	<ng-select
		[items]="customerDropDownList"
		[searchable]="false"
		[(ngModel)]="selected_customer"
		name="selCustomerDetails"
		id="selCustomerDetails"
		(change)="onOptionSelectEvent($event)"
		notFoundText="{{ 'UI.k_no_items_found' | translate }}"
		[attr.data-testid]="'selCustomerDetails'"
		[readonly]="readonly"
		[placeholder]="placeholder"
		
	>
		<ng-template ng-header-tmp>
			<div class="custom-input">
				<i class="fa fa-search search-icon"></i>
				<input
					class="w-100 search-input"
					type="text"
					(input)="searchCustomerEvent($event.target.value)"
					placeholder="Search"
				/>
			</div>
		</ng-template>
		<ng-template ng-label-tmp let-item="item" let-clear="clear">
			<div *ngIf="item?.isParent == true" class="w-100">
				<div class="mainclass justify-content-between">
					<div class="d-flex align-items-center w-100">
						<div class="ng-value-label">
							<span *ngIf="!item?.showAvatar; else customAvatar">
								<img class="rounded-circle" [src]="item.logo_or_icon_path" width="28px" height="28px" (error)="onImgError(item)" />
							</span>
							<ng-template #customAvatar>
								<div class="avatar mr-50" style="width: 28px; height: 28px" [ngStyle]="{
															'background-color': item.avatar_color ? item.avatar_color : '#f59518'
														}">
									<div class="avatar-content">
										{{ item.customer_name | initials | slice: O:2 }}
									</div>
								</div>
							</ng-template>
						</div>
						<div class="Parent-content ml-25">
							{{ item?.customer_name | slice: 0:12 }}
							{{ item.customer_name.length > 12 ? "..." : "" }}
						</div>
						
					</div>
					<div class="d-flex justify-content-end gap-25">
						<span class="badge mr-1">
							{{ item?.customer_code | slice: 0:8 }}
							{{ item.customer_code.length > 8 ? "..." : ""}}
						</span>
						
						<div class="badge text-primary">
							{{ "C" }}
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="item?.isParent == false">
				<div class="mainclass justify-content-between">
					<div class="d-flex align-items-center">
						<div class="ng-value-label w-100">
							<span *ngIf="!item?.showAvatar; else customAvatar">
								<img class="rounded-circle" [src]="item.logo_or_icon_path" width="28px" height="28px" (error)="onImgError(item)" />
							</span>
							<ng-template #customAvatar>
								<div class="avatar mr-50" style="width: 28px; height: 28px" [ngStyle]="{
															'background-color': item.avatar_color ? item.avatar_color : '#f59518'
														}">
									<div class="avatar-content">
										{{ item.entity_name | initials | slice: O:2 }}
									</div>
								</div>
							</ng-template>
						</div>
						<div class="d-flex justify-content-start align-items-center">
							<div class="companyclass ml-25">
								{{ item?.entity_name | slice: 0:12 }}
								{{ item.entity_name.length > 12 ? "..." : "" }}
							</div>
							
						</div>
					</div>
					<div class="d-flex justify-content-end gap-25">
						<span class="badge pr-1">
							{{ item?.entity_code | slice: 0:8 }}
							{{ item.entity_code.length > 8 ? "..." : "" }}
						</span>
						
						<div class="badge">
							{{ "E" }}
						</div>
					</div>
					
				</div>
			</div>
		</ng-template>
		<ng-template ng-option-tmp let-item="item">
			<div *ngIf="item?.isParent == true" class="w-100">
				<div class="mainclass justify-content-between">
					<div class="d-flex justify-content-start align-items-center">
						<div class="ng-value-label w-100">
							<span *ngIf="!item?.showAvatar; else customAvatar">
								<img class="rounded-circle" [src]="item.logo_or_icon_path" width="28px" height="28px" (error)="onImgError(item)" />
							</span>
							<ng-template #customAvatar>
								<div class="avatar mr-50" style="width: 28px; height: 28px" [ngStyle]="{
															'background-color': item.avatar_color ? item.avatar_color : '#f59518'
														}">
									<div class="avatar-content">
										{{ item.customer_name | initials | slice: O:2 }}
									</div>
								</div>
							</ng-template>
						</div>
						<div class="Parent-content ml-25">
							{{ item?.customer_name | slice: 0:12 }}
							{{ item.customer_name.length > 12 ? "..." : "" }}
						</div>
						
					</div>
					<div class="d-flex justify-content-end">
						<span class="badge mr-1">
							{{ item?.customer_code | slice: 0:8 }}
							{{ item.customer_code.length > 8 ? "..." : "" }}
						</span>
						<div class="badge text-primary">
							{{ "C" }}
						</div>
					</div>

				</div>
			</div>
			<div *ngIf="item?.isParent == false">
				<div class="mainclass justify-content-between">
					<div class="d-flex">
						<div class="ng-value-label">
							<span *ngIf="!item?.showAvatar; else customAvatar">
								<img class="rounded-circle" [src]="item.logo_or_icon_path" width="28px" height="28px" (error)="onImgError(item)" />
							</span>
							<ng-template #customAvatar>
								<div class="avatar mr-50" style="width: 28px; height: 28px" [ngStyle]="{
															'background-color': item.avatar_color ? item.avatar_color : '#f59518'
														}">
									<div class="avatar-content">
										{{ item.entity_name | initials | slice: O:2 }}
									</div>
								</div>
							</ng-template>
						</div>
						<div class="d-flex justify-content-start align-items-center">
							<div class="companyclass ml-25">{{ item?.entity_name | slice: 0:12 }}{{ item.entity_name.length > 12 ? "..." : "" }}</div>
						</div>
					</div>
					<div class="d-flex justify-content-end">
						<span class="badge cpy-class mr-1">{{ item?.entity_code | slice: 0:8 }}{{ item.location_name.length > 8 ? "..." : "" }}</span>
						
						<div class="badge">
							{{ "E" }}
						</div>
					</div>
					
				</div>
			</div>
		</ng-template>
	</ng-select>
</div>
