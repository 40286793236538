import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss']
})
export class StatusBadgeComponent implements OnInit {
  data: any;
	public rowindex: any;
	constructor() {
		this.data = {};
	}


  ngOnInit(): void { 
  }


}
