<div class="selct-feature group-list-feature" *ngIf="isExisting">

    <div class="select-sec">
      <div class="form-group create-new-team" style="margin-bottom: 2px;">
        <label class="form-label"> 
            {{listData.length ? ("UI.k_new_team" | translate) : ("UI.k_sel_team" |   translate)}} 
        </label>
        <ng-container *ngIf="!listData.length; else inputTemp">
            <ng-select
                [items]="selectTeamData"
                [bindLabel]="name"
                [bindValue]="name"
                name="id"
                id="id"
                placeholder="{{ 'UI.k_select' | translate }}"
                [dropdownPosition]="'bottom'"
                class="custom-drop-down"
                [(ngModel)]="defaultVal"
            >
                <ng-template ng-label-tmp let-item="item">            
                    <div>
                        {{ item.name }}
                    </div>
                </ng-template>
                <ng-template ng-header-tmp>
                    <div class="crealte-label-option" (click)="createNeGroupHandler()">
                        Create New 
                    </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="list-of-value">
                        <div class="list-of-images">
                            <ng-container *ngFor="let imgItem of item.images; let i=index">
                                <ng-container *ngIf="i <= 1">
                                    <img [src]="imgItem"/>                            
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="item.images.length > 3">
                                <div class="team-length">
                                    +{{item.images.length - 2}}
                                </div>
                            </ng-container>
                        </div>
                        <div class="list-of-name">
                            <ng-container >
                                {{ item.name }}
                            </ng-container>
                        </div>
                    </div>
                </ng-template>            
            </ng-select>
        </ng-container>
        
        <ng-template #inputTemp>
            <div class="create-new-team" style="padding: 0px;">
                <div class="selected-show-data">
                    <div class="search-item" style="width: 100%;">
                        <input  
                            type="text" 
                            class="form-control bind-value" 
                            [(ngModel)]="defaultVal"
                            #inputVal
                            >
                            <!-- disabled -->
                    </div>
                </div>
            </div>
        </ng-template>
        
      </div>
    </div>

    <div class="create-new-team" *ngIf="listData.length" style="padding-top: 0;">
        <div class="selected-show-data">
            <div style="font-weight: 500;">{{"UI.k_team_member" | translate}}</div>
            <div class="search-item">
                <i class="fa-light fa-magnifying-glass"></i>
                <input  
                type="text" 
                id="search" 
                name="search" 
                class="form-control" 
                placeholder="{{'UI.k_new_team_name' | translate}}"
                [(ngModel)]="searchVal"
                style="border-radius:4px"
                >
            </div>
        </div>
        <ng-container *ngFor="let listDataIitem of listData | filter:searchVal; let i=index">
            <div style="padding: 10px 0px;">
                <div class="team-list-main-sec">
                    <div class="team-member-list">
                        <div class="img-and-name-block">
                            <div>
                                <img [src]="listDataIitem.images"/>
                            </div>
                            <div style="margin-left: 0.5rem;">
                                <div style="font-weight: 500;font-size: 14px;">
                                    {{ listDataIitem.name }}
                                </div>
                                <!-- <div>
                                    {{ listDataIitem.email }}
                                </div> -->
                            </div> 
                        </div>                                                   
                        <div>
                            <div class="round">
                                <input id="item-{{i}}" type="checkbox" checked/>
                                <label for="checkbox"></label>
                            </div>
                        </div>
                    </div>                        
                </div>
            </div>
        </ng-container>
        <div class="modal-btn-group flex-initial border-top sticky-footer footer-btn">
            <div class="d-flex justify-content-end stepper-btn flex-initial">
                <button
                    class="btn btn-sm btn-default btn-next ml-1"
                    rippleEffect
                    (click)="cancelNewTeamHandler()"
                    >
                    <span class="align-middle d-sm-inline-block d-none">
                        {{ "UI.k_cancel" | translate }}
                        </span>
                </button>
                <button
                    class="btn btn-sm btn-primary btn-next ml-1"
                    rippleEffect
                    type="submit"
                    (click)="createNewSubmitHandler()"
                    >
                    <span class="align-middle d-sm-inline-block d-none">
                        {{ "UI.k_save" | translate }}
                        </span>
                </button>
            </div>
        </div>              
    </div>
    
</div>


<div class="selct-feature group-list-feature" *ngIf="!isExisting">
    <div class="select-sec create-group-main-block">
        <label class="form-label"> {{"UI.k_create_team" | translate}} </label>
        <div  class="input-controller">
            <input  
                type="text" 
                id="version" 
                name="version" 
                class="form-control" 
                placeholder="{{'UI.k_new_team_name' | translate}}"
                #inputVal
                style="border-bottom-left-radius: 0;border-bottom-right-radius: 0;"
                (keyup)="changeHandler($event)"
                >
        </div>
        <div class="form-group create-new-team brd-radius" style="padding-top: 0;">            
            <ng-select
                [items]="selectTeamMemberData"
                bindLabel="name"
                bindValue="id"
                name="id"
                id="id"
                placeholder="{{ 'UI.k_select_member' | translate }}"
                [dropdownPosition]="'bottom'"
                class="custom-drop-down"
                [multiple]="true"
                [closeOnSelect]="true"
                clearAllText="Clear"
                [searchFn]="searchHandler"
                #select
                [searchable]="false"
                (change)="onGroupItemChange($event)"
                [readonly]="isEnableDropDown"
            >
                <!-- <ng-template ng-label-tmp let-item="item">            
                    <div class="label-selected">
                        {{ item.name }}
                    </div>
                </ng-template> -->
                <ng-template ng-header-tmp>
                    <div class="search-item">
                        <i class="fa-light fa-magnifying-glass"></i>
                        <input  type="text" id="version" name="version" class="form-control" placeholder="Search" (input)="select.filter($event.target.value)">
                    </div>
                    <!-- <div class="select-block">
                        <button (click)="selectAll()" class="btn btn-sm btn-primary">Select all</button>
                        <button (click)="unselectAll()" class="btn btn-sm btn-primary">Unselect all</button>
                    </div> -->
                    
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                    <div class="team-list-main-sec">
                        <div class="team-member-list">
                            <div class="img-and-name-block">
                                <div>
                                    <img [src]="item.images"/>
                                </div>
                                <div style="margin-left: 0.5rem;">
                                    <div style="font-weight: 500;font-size: 12px;">
                                        {{ item.name }}
                                    </div>
                                    <div style="font-weight: 400;font-size: 10px;">
                                        {{ item.email }}
                                    </div>
                                </div> 
                            </div>                                                   
                            <div>
                                <!-- <input  type="checkbox" id="{{item.id}}" name="{{item.name}}" class="form-control" (change)="selectedTeamHandler($event.target.checked, item)" [checked]="item.checked" [value]="item.name"> -->
                                <div class="round">
                                    <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"   [ngModelOptions]="{standalone: true}"/>
                                    <label for="checkbox"></label>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </ng-template>

                <!-- <ng-template ng-multi-label-tmp let-items="items">
                    <div class="ng-value selected-member" *ngFor="let item of items | slice:0:2">                        
                        {{item.name}}
                    </div>
                    <div class="ng-value selected-member" *ngIf="items.length > 2">
                        <span class="ng-value-label">{{items.length - 2}} more...</span>
                    </div>
                </ng-template> -->

                <ng-template ng-footer-tmp>
                    <span class="create-flex">
                        <button
                            class="btn btn-sm btn-default btn-next ml-1 d-cancel"
                            rippleEffect
                            (click)="backStepHandler()"
                        >
                            <span class="align-middle d-sm-inline-block" style="color: #1A5AD9;">
                                <i class="fa-thin fa-arrow-left" style="font-size: 20px;margin: 0;"></i> 
                                {{ "UI.k_back" | translate }}
                            </span>
                        </button>
                        <button
                            class="btn btn-sm btn-primary btn-next ml-1"
                            rippleEffect
                            type="submit"
                            (click)="onSubmitHandler()"
                            >
                            <!-- [disabled]="isDisabled" -->
                            <span class="align-middle d-sm-inline-block d-none">{{ "UI.k_create" | translate }}</span>
                        </button>
                    </span>
                </ng-template>           
            </ng-select>
        </div>
    </div>
</div>

