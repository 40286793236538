<div class="full-body-height">
	<div class="main-content flex-height">
		<div class="main-sec">
			<div class="action-bar" [ngClass]="isExpandSearchWidth ? 'expanded-search' : ''">
				<div class="inner-section">
					<div class="left-section">
						<div class="action-search">
							<ng-container *ngIf="(users_card?.length > 0 && showData) || filterData">
								<app-search
									(getfilterData)="getFilterSearch($event)"
									[filterDetails]="getUserList"
									[showDataTable]="showDataTable"
									[columnOptions]="columnOptions"
									[conditionOptions]="conditionOptions"
									[showRightsection]="showRightsection"
									[savedData]="savedData"
									[Options]="option"
									[module]="module"
									(genericFilter)="genericSearchFilter($event)"
									(isExpandSearchWidth)="getSearchWidth($event)"
								></app-search>
							</ng-container>
						</div>
					</div>
					<div class="right-section">
						<div class="pull-right align-items-center d-flex p-0 m-0">
							<div
								class="btn-group mr-1"
								role="group"
								aria-label="User Toggle View"
								*ngIf="showData && users_card?.length > 0"
							>
								<button
									type="button"
									class="btn btn-outline-tertiary btn-icon"
									id="btnUsrGrid"
									name="btnUsrGrid"
									(click)="userGrid('Grid')"
									rippleEffect
									tooltipClass="fadeInAnimation"
									placement="bottom"
									ngbTooltip="{{ 'UI.k_grid_view' | translate }}"
									[attr.data-testid]="'btnGridView'"
								>
									<i class="fa-light fa-table"></i>
								</button>
								<button
									type="button"
									class="btn btn-outline-tertiary btn-icon"
									(click)="userCard('Card')"
									id="btnUsrCard"
									name="btnUsrCard"
									rippleEffect
									placement="bottom"
									tooltipClass="fadeInAnimation"
									ngbTooltip="{{ 'UI.k_card_view' | translate }}"
									routerLinkActive="active"
									[routerLink]="['/users/card']"
									[attr.data-testid]="'btnCardView'"
								>
									<i class="fa-light fa-grid-2"></i>
								</button>
							</div>
							<a
								class="nav-link nav-link-style mr-2 position-relative d-inline-block pt-0 pb-0"
								tooltipClass="fadeInAnimation"
								placement="bottom"
								ngbTooltip="{{ 'UI.k_pending_invitations' | translate }}"
								(click)="toggleInvitedUsers('invite-user-grid')"
								id="btnInviteGrid"
								name="btnInviteGrid"
								*ngIf="pendingInvitations > 0"
								[attr.data-testid]="'btnInviteGrid'"
							>
								<span><i class="fa-light fa-envelope font-medium-5"></i></span>
								<span class="badge badge-warning font-small-1 badge-up">
									{{ pendingInvitations }}
								</span>
							</a>
							<button
								*ngIf="permissions?.add"
								class="btn btn-primary"
								rippleEffect
								(click)="toggleAddUser('add-user-profile')"
								tooltipClass="fadeInAnimation"
								id="btnInvUsr"
								name="btnInvUsr"
								[attr.data-testid]="'btnInvUsr'"
							>
								<i class="fa-light fa-plus font-small-3"></i>&nbsp;
								<span class="d-none d-sm-inline-block font-small-3">
									{{ "UI.k_invite_user" | translate }}
								</span>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div class="action-section">
				<div class="main-card-view">
					<ng-container *ngIf="users_card?.length == 0 || !showData">
						<div class="skeleton-data" [ngClass]="showData ? 'py-0' : 'py-1'">
							<ng-container *ngIf="users_card?.length == 0 && showData && !filterData">
								<app-dns-empty-grid-data [emptyData]="empty_data" [permissions]="permissions">
								</app-dns-empty-grid-data>
							</ng-container>
							<div *ngIf="users_card?.length == 0 && showData && filterData">
								<app-dns-empty-grid-data [noFilterData]="true"></app-dns-empty-grid-data>
							</div>
							<ng-container *ngIf="!showData">
								<app-card-skeleton></app-card-skeleton>
							</ng-container>
						</div>
					</ng-container>

					<ng-container *ngIf="users_card?.length > 0 && showData">
						<div class="card-view">
							<ng-scrollbar class="custom-scrollbar section-scrollbar">
								<div class="inner-card-view">
										<!-- <div class="container"> -->
										<!-- <div class="row card-row-padding"> -->
										<div *ngFor="let user of users_card; let i = index">
											<div
												class="card single-card m-0"
												(mouseover)="user.isShowAction = true"
												(mouseleave)="user.isShowAction = false"
											>
												<div id="divEdit_{{ i }}" class="px-1">
													<div class="align-items-center mb-75 mt-75">
														<div class="media">
															<div class="avatar mr-75" id="divImg">
																<span
																	*ngIf="!user.profile_image"
																	class="avatar-content"
																	[ngStyle]="{
																		'background-color': user.profile.avatar_color
																			? user.profile.avatar_color
																			: defaultAvatarColor
																	}"
																>
																	{{ user.profile.name | initials | slice: O:2 }}
																</span>
																<span class="avatar-content" *ngIf="user.profile_image">
																	<img
																		[src]="user.profile_image"
																		width="32"
																		height="32"
																		alt=""
																		id="imgCard_{{ i }}"
																	/>
																</span>
															</div>
															<div class="media-body">
																<div class="d-flex justify-content-between">
																	<div class="profile-info">
																		<h6
																			class="mb-0 profile-name"
																			title="{{ user.profile.name }}"
																			id="user-{{ user.profile.name }}"
																		>
																			{{ user.profile.name | slice: 0:18
																			}}{{
																				user.profile.name.length > 18 ? "..." : ""
																			}}
																		</h6>
																		<small class="mb-0">{{ user.role }}</small>
																	</div>
																	<div class="d-flex align-items-baseline">
																		<span
																			class="badge mr-50"
																			[ngClass]="
																				user.profile.is_active
																					? 'badge-light-success'
																					: 'badge-light-danger'
																			"
																		>
																			{{
																				(user.profile.is_active
																					? "UI.k_active"
																					: "UI.k_inactive"
																				) | translate
																			}}
																		</span>
																		<div ngbDropdown class="no-arrow" *ngIf="permissions?.edit || permissions?.delete">
																			<button
																				ngbDropdownToggle
																				class="btn btn-sm btn-flat-secondary p-50"
																				type="button"
																				id="drdActionUser"
																				*ngIf="!user?.hide_action"
																			>
																				<i
																					class="fa-light fa-ellipsis-vertical fw-500 font-medium-1"
																				></i>
																			</button>
																			<div
																				ngbDropdownMenu
																				aria-labelledby="dropdownMenuButton"
																			>
																				<a
																					ngbDropdownItem
																					href="javascript:void(0)"
																					(click)="editUser(user)"
																					id="editUser{{ i }}"
																					name="editUser{{ i }}"
																					*ngIf="permissions?.edit"
																					class="text-primary"
																					attr.data-testid="editUser{{ i }}"
																					><i
																						class="fa-light fa-pen-to-square mr-75"
																					></i
																					>{{ "UI.k_edit" | translate }}
																				</a>
																				<a
																					ngbDropdownItem
																					href="javascript:void(0)"
																					id="deleteUser{{ i }}"
																					name="deleteUser{{ i }}"
																					(click)="deleteUser(user)"
																					*ngIf="permissions?.delete"
																					class="text-danger"
																					attr.data-testid="deleteUser{{ i }}"
																					><i
																						class="fa-light fa-trash-can mr-75"
																					></i
																					>{{ "UI.k_delete" | translate }}</a
																				>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div class="border-bottom"></div>
												<div class="px-1">
													<!-- <div class="col-md-6" style="padding:9px;"> -->
													<div class="mt-50">
														<!--<i class=" feather icon-phone-call [size]= 8  text-primary mr-50 mb-50  ml-75"></i>-->
														<i class="fa-light fa-phone text-primary mr-50 mb-50"></i>
														<small class="text-primary" style="font-size: 0.757rem">{{
															user.profile.phone
														}}</small>
													</div>
	
													<!-- </div>
															<div class="col-md-6 border-left" style="padding:9px;"> -->
													<div class="mb-50">
														<!--<i class=" feather icon-mail [size]= 8  text-primary mr-50 ml-75"></i>-->
														<i class="fa-light fa-envelope text-primary mr-50"></i>
														<small
															class="text-primary"
															title="{{ user.profile.email }}"
															style="font-size: 0.757rem"
															id="email-{{ user.profile.email }}"
														>
															{{ user.profile.email | slice: 0:40
															}}{{ user.profile.email.length > 40 ? "..." : "" }}</small
														>
													</div>
													<!-- </div> -->
												</div>
											</div>
										</div>
	
										<!-- </div> -->
										<!--										<ng-container *ngIf="!finished" class="text-center mb-2">-->
										<!--											<button-->
										<!--																	class="btn btn-outline-primary"-->
										<!--												type="button"-->
										<!--												class="btn btn-outline-tertiary btn-icon"-->
										<!--												(click)="userCard('Card')"-->
										<!--												id="btnUsrCard"-->
										<!--												name="btnUsrCard"-->
										<!--												rippleEffect-->
										<!--											>-->
										<!--												<span-->
										<!--													class="spinner-border spinner-border-sm"-->
										<!--													role="status"-->
										<!--													aria-hidden="true"-->
										<!--												></span>-->
										<!--												<span class="ml-25 align-middle">Loading...</span>-->
										<!--											</button>-->
										<!--										</ng-container >-->
										<!-- </div> -->
	
										<!--								<ng-container  *ngIf="users_card?.length == 0 && showData">-->
										<!--										<app-dns-empty-grid-data-->
										<!--											[emptyData]="empty_data"-->
										<!--											[permissions]="permissions"-->
										<!--										></app-dns-empty-grid-data>-->
										<!--								</ng-container >-->
										<!--								<ng-container  *ngIf="!showData">-->
										<!--									<app-card-skeleton></app-card-skeleton>-->
										<!--								</ng-container >-->
								</div>
							</ng-scrollbar>
						</div>
					</ng-container>
				</div>

				<div class="card-pagination" *ngIf="users_card?.length > 0 && showData">
					<div class="pagination-inner">
						<div *ngIf="finished" class="w-100">
							<dns-pagination
								[totalCount]="totalCount"
								[currentCount]="userCount"
								(setPage)="loadPage($event)"
								(setLimit)="onLimitChange($event)"
								[page]="pageNumber"
								[pageSize]="limit"
							>
							</dns-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="add-user-profile"
		overlayClass="modal-backdrop"
	>
		<app-invite-users></app-invite-users>
	</core-sidebar>

	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="user-profile"
		overlayClass="modal-backdrop"
	>
		<app-user-profile
			*ngIf="hideProfile"
			(onHideProfile)="onHideProfile($event)"
			[action]="currentEvent"
			[user]="currentUser"
			(callback)="getUserList()"
			(getUserList)="getUserList()"
		></app-user-profile>
	</core-sidebar>
	<!-- <core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="invite-user-grid"
	overlayClass="modal-backdrop"
>
	<app-invite-user-grid>
	</app-invite-user-grid>
</core-sidebar> -->

	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="invite-user-grid"
		overlayClass="modal-backdrop"
	>
		<app-invite-user-grid *ngIf="hideInviteGrid" (onHideInviteGrid)="onHideInviteGrid($event)">
		</app-invite-user-grid>
	</core-sidebar>
	<ng-template #modDeletePopup let-modal>
		<div class="modal-header">
			<h5 class="modal-title" id="myModalLabel1">
				{{ "UI.k_current_user_mapping" | translate }}
			</h5>
			<span
				id="btnClose"
				name="btnClose"
				data-dismiss="modal"
				aria-label="Close"
				(click)="modal.dismiss('Cross click')"
			>
				<i class="fa-light fa-xmark mb-0 mr-sm-25 mr-0 pull-right"></i>
			</span>
		</div>
		<div class="modal-body" tabindex="0" ngbAutofocus>
			<form #userForm="ngForm">
				<div class="">
					<div *ngFor="let item of used_modules | keyvalue">
						<div *ngIf="item.key == 'Team'" class="alert alert-primary mb-50" role="alert">
							<div class="alert-heading d-flex justify-content-center">
								{{
									"Message.msg_validate_user_team"
										| translate
											: {
													profile_name: selectedUser.profile.name,
													value_length: item.value.length
											  }
								}}
							</div>
						</div>
						<div *ngIf="item.key == 'Requester'" class="alert alert-primary mb-50" role="alert">
							<div class="alert-heading d-flex justify-content-center">
								{{
									"Message.msg_validate_user_requester"
										| translate
											: {
													profile_name: selectedUser.profile.name,
													value_length: item.value.length
											  }
								}}
							</div>
						</div>
						<div *ngIf="item.key == 'Department'" class="alert alert-primary mb-50" role="alert">
							<div class="alert-heading d-flex justify-content-center">
								{{
									"Message.msg_validate_user_department"
										| translate
											: {
													profile_name: selectedUser.profile.name,
													value_length: item.value.length
											  }
								}}
							</div>
						</div>
						<div *ngIf="item.key == 'Service'" class="alert alert-primary mb-50" role="alert">
							<div class="alert-heading d-flex justify-content-center">
								{{
									"Message.msg_validate_user_service"
										| translate
											: {
													profile_name: selectedUser.profile.name,
													value_length: item.value.length
											  }
								}}
							</div>
						</div>
						<div *ngIf="item.key == 'KB'" class="alert alert-primary mb-50" role="alert">
							<div class="alert-heading d-flex justify-content-center">
								{{
									"Message.msg_validate_user_kb"
										| translate
											: {
													profile_name: selectedUser.profile.name,
													value_length: item.value.length
											  }
								}}
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-2 mb-2">
					<div class="form-group col-md-4">
						<label for="selDeptHead"
							>{{ "UI.k_replacement" | translate }}<span class="required-class"> &nbsp;&nbsp;*</span>
						</label>
						<ng-select
							[items]="user_list"
							[searchable]="true"
							bindLabel="full_name"
							bindValue="profile_id"
							[searchFn]="customSearchFn"
							placeholder="{{ 'UI.k_select_user' | translate }}"
							name="selDeptHead"
							id="selUser"
							[(ngModel)]="selectedProfile"
							#TDNameRef="ngModel"
							required
							[class.error]="!TDNameRef.valid && userForm.submitted"
						>
							<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
								<span class="d-flex">
									<div
										class="avatar mr-1 ml-0"
										[ngClass]="bg - light - primary"
										style="align-content: center; width: 32px; height: 32px"
									>
										<div *ngIf="item?.profile_image; else customAvatar">
											<img
												class="rounded-circle mr-1"
												src="{{ item.profile_image }}"
												height="32"
												width="32"
												alt="datatable-avatar"
											/>
										</div>
										<ng-template #customAvatar>
											<div
												class="avatar mr-1 ml-0"
												[ngStyle]="{
													'background-color': item.avatar_color
														? item.avatar_color
														: '#f59518'
												}"
											>
												<div class="avatar-content">
													{{ item.full_name | initials | slice: O:2 }}
												</div>
											</div>
										</ng-template>
									</div>
									<div class="cell-line-height">
										<p class="font-medium-1 font-weight-bold line-height-1 mb-25">
											{{ item.full_name }}
										</p>
										<span class="text-muted font-small-2"> {{ item.email }}</span>
									</div>
								</span>
							</ng-template>
						</ng-select>
						<span *ngIf="TDNameRef.invalid && userForm.submitted" class="invalid-form">
							<small *ngIf="TDNameRef.errors.required" class="form-text text-danger">
								{{ "UI.k_field_required" | translate }}
							</small>
						</span>
					</div>
					<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 mt-2">
						<div class="alert alert-danger mb-50" role="alert">
							<div class="alert-heading">
								{{ "UI.k_replace_user" | translate }}
							</div>
						</div>
					</div>
				</div>
				<div class="row d-flex justify-content-end mr-0">
					<button
						(click)="onSubmitReplacement()"
						type="submit"
						class="btn btn-primary"
						rippleEffect
						id="btnCommentSave"
						name="btnCommentSave"
						[attr.data-testid]="'btnCommentSave'"
					>
						<span class="align-middle d-sm-inline-block d-none">{{ "UI.k_save" | translate }} </span>
					</button>
				</div>
			</form>
		</div>
	</ng-template>
</div>
