import { NgbNav } from "@ng-bootstrap/ng-bootstrap";
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from "@angular/core";
import flowy from "@core/lib/flowy";
import { CoreSidebarService } from "../../../../../@core/components/core-sidebar/core-sidebar.service";
import { FIELD_TYPE, WORKFLOW_BLOCK_TYPE, WORKFLOW_TEMPLATE_TYPE } from "../../types";
import { WorkflowConditionsComponent } from "../workflow-conditions/workflow-conditions.component";
import { EMPTY, from, Observable, OperatorFunction } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, groupBy, mergeMap, switchMap, toArray } from "rxjs/operators";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { NgForm } from "@angular/forms";
import { WorkflowService } from "../../../../base/workflow/services/workflow.service";
import { _deepCopyJson, generate_uuid, getIntersectionOfArray } from "../../../utils/utils";
import { DnsValueSelectorComponent } from "../dns-value-selector/dns-value-selector.component";
import { TitleCasePipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { InfinityModuleIDs } from "../../types/module_type";
import { PermissionService } from "../../../permission/service";

interface State {
	value: any;
	transition_states: Array<string>;
}

const CONDITIONS_JSON = {
	source: [
		{
			id: 1,
			name: "UI.k_priority",
			type: "list",
			key: "priority",
			macroKey: "priority_id",
			bindKey: "name",
			bindValue: "id",
			options: [],
			excluded_modules: [29],
		},
		{
			id: 3,
			name: "UI.k_status",
			type: "list",
			key: "status",
			macroKey: "status_id",
			bindKey: "name",
			bindValue: "id",
			options: [],
			excluded_modules: [29],
		},
		{
			id: 4,
			name: "UI.k_impact",
			type: "list",
			key: "impact",
			macroKey: "impact_id",
			bindKey: "name",
			bindValue: "id",
			options: [],
			excluded_modules: [29, 50, 66, 69],
		},
		{
			id: 7,
			name: "UI.k_urgency",
			type: "list",
			key: "urgency",
			macroKey: "urgency_id",
			bindKey: "name",
			bindValue: "id",
			options: [],
			excluded_modules: [29, 50, 66, 69],
		},
		{
			id: 5,
			name: "UI.k_incident_source",
			type: "list",
			key: "incident_source",
			macroKey: "incident_source",
			bindKey: "name",
			bindValue: "id",
			options: [],
			excluded_modules: [47, 45, 42, 53, 29, 50, 66, 69],
		},
		{
			id: 5,
			name: "UI.k_problem_source",
			type: "list",
			key: "problem_source",
			macroKey: "problem_source",
			bindKey: "name",
			bindValue: "id",
			options: [],
			excluded_modules: [47, 10, 42, 53, 29, 50, 66, 69],
		},
		{
			id: 5,
			name: "UI.k_change_source",
			type: "list",
			key: "change_source",
			macroKey: "change_source",
			bindKey: "name",
			bindValue: "id",
			options: [],
			excluded_modules: [10, 45, 42, 53, 29, 50, 66, 69],
		},
		{
			id: 5,
			name: "UI.k_request_source",
			type: "list",
			key: "request_source",
			macroKey: "request_source",
			bindKey: "name",
			bindValue: "id",
			options: [],
			excluded_modules: [47, 45, 10, 53, 29, 50, 66, 69],
		},
		{
			id: 6,
			name: "UI.k_severity",
			type: "list",
			key: "severity",
			macroKey: "severity_id",
			bindKey: "name",
			bindValue: "id",
			options: [],
			excluded_modules: [29, 50, 66, 69],
		},
		{
			id: 7,
			name: "UI.k_operational_status",
			type: "list",
			key: "operational_status",
			macroKey: "operational_status_id",
			bindKey: "name",
			bindValue: "id",
			options: [],
			excluded_modules: [10, 47, 45, 10, 53, 50, 66, 69],
		},
		{
			id: 8,
			name: "UI.k_criticality",
			type: "list",
			key: "criticality",
			macroKey: "criticality_id",
			bindKey: "name",
			bindValue: "id",
			options: [],
			excluded_modules: [10, 47, 45, 10, 53, 50, 66, 69],
		},
		{
			id: 9,
			name: "UI.k_service_status",
			type: "list",
			key: "service_status",
			macroKey: "service_status_id",
			bindKey: "name",
			bindValue: "id",
			options: [],
			excluded_modules: [10, 47, 45, 10, 53, 50, 66, 69],
		},
	],
};

const SET_VALUE_OPERAND = {
	source: [
		{
			id: 1,
			name: "UI.k_priority",
			type: "list",
			key: "priority",
			macroKey: "priority_id",
			bindKey: "name",
			bindValue: "id",
			options: [],
			exclude_module: [29, 69],
		},
		{
			id: 1,
			name: "UI.k_priority",
			type: "list",
			key: "priority",
			macroKey: "basic_info.priority.id",
			bindKey: "name",
			bindValue: "id",
			options: [],
			exclude_module: [10, 47, 45, 10, 53, 50, 66, 29],
		},
		// {
		// 	id: 3,
		// 	name: "UI.k_status",
		// 	type: "list",
		// 	key: "status",
		// 	macroKey: "status_id",
		// 	bindKey: "name",
		// 	bindValue: "id",
		// 	options: [],
		// },
		{
			id: 4,
			name: "UI.k_impact",
			type: "list",
			key: "impact",
			macroKey: "impact_id",
			bindKey: "name",
			bindValue: "id",
			options: [],
			exclude_module: [29, 69],
		},
		{
			id: 7,
			name: "UI.k_urgency",
			type: "list",
			key: "urgency",
			macroKey: "urgency_id",
			bindKey: "name",
			bindValue: "id",
			options: [],
			exclude_module: [29, 69],
		},
		{
			id: 6,
			name: "UI.k_severity",
			type: "list",
			key: "severity",
			macroKey: "severity_id",
			bindKey: "name",
			bindValue: "id",
			options: [],
			exclude_module: [29, 69],
		},
		{
			id: 7,
			name: "UI.k_criticality",
			type: "list",
			key: "criticality",
			macroKey: "common_info.criticality",
			bindKey: "name",
			bindValue: "id",
			options: [],
			exclude_module: [10, 47, 45, 42, 53, 69],
		},
		{
			id: 8,
			name: "UI.k_operational_status",
			type: "list",
			key: "operational_status",
			macroKey: "common_info.operational_status",
			bindKey: "name",
			bindValue: "id",
			options: [],
			exclude_module: [10, 47, 45, 42, 53, 69],
		},
		{
			id: 9,
			name: "UI.k_service_status",
			type: "list",
			key: "service_status",
			macroKey: "common_info.service_status",
			bindKey: "name",
			bindValue: "id",
			options: [],
			exclude_module: [10, 47, 45, 42, 53, 69],
		},
	],
};

const STATE = [
	{
		name: "Open",
		id: 1,
	},
	{
		name: "In Progress",
		id: 2,
	},
	{
		name: "OnHold",
		id: 3,
	},
	{
		name: "Resolve",
		id: 4,
	},
	{
		name: "Close",
		id: 5,
	},
];

const MACRO_DATA = [
	{
		name: "Ticket",
		tags: [
			{
				name: "Status",
				macro: "{{basic_info.status.name}}",
			},
			{
				name: "State",
				macro: "{{basic_info.state.name}}",
			},
			{
				name: "Assignee",
				macro: "{{current_assignment_info.assignee}}",
			},
			{
				name: "Priority",
				macro: "{{basic_info.priority.name}}",
			},
			{
				name: "DisplayID",
				macro: "{{display_id}}",
			},
			{
				name: "Summary",
				macro: "{{basic_info.summary}}",
			},
			{
				name: "Description",
				macro: "{{basic_info.description}}",
			},
			{
				name: "Urgency",
				macro: "{{basic_info.urgency.name}}",
			},
			{
				name: "Severity",
				macro: "{{basic_info.severity.name}}",
			},
		],
	},
	{
		name: "Requester",
		tags: [
			{
				name: "Name",
				macro: "{{requester.full_name}}",
			},
			{
				name: "Email",
				macro: "{{requester.email}}",
			},
			{
				name: "Phone",
				macro: "{{requester.contact_number}}",
			},
		],
	},
];

// 1. Incident Requester
// 2. Incident Assignee
// 3. Team Lead (Owner)
// 4.  Include all team member

const EMAIL_SELECTOR_LIST = [
	{
		name: "Requester",
		macro: "{{requester.email}}",
		excluded_module: [29, 66, 69],
	},
	{
		name: "Team Lead/Owner",
		macro: "{{wfi_em_team_lead}}",
		excluded_module: [29, 66, 69],
	},
	{
		name: "Assignee",
		macro: "{{wfi_em_current_assignee}}",
		excluded_module: [29, 66],
	},
	{
		name: "Submitter",
		macro: "{{wfi_em_ticket_submitter}}",
		excluded_module: [29, 66],
	},
	{
		name: "Asset Requester",
		macro: "{{wfi_em_asset_requester}}",
		excluded_module: [10, 47, 45, 10, 53, 66, 69],
	},
	{
		name: "Owner",
		macro: "{{wfi_em_asset_owner}}",
		excluded_module: [10, 47, 45, 10, 53, 66, 69],
	},
	{
		name: "Tagged Users",
		macro: "{{wfi_em_tagged_user}}",
		excluded_module: [10, 47, 45, 53, 66, 69],
	},
	{
		name: "Follower",
		macro: "{{wfi_em_follower}}",
		excluded_module: [29, 53, 66, 69],
	},
	{
		name: "Reported By",
		macro: "{{wfi_em_reported_by}}",
		excluded_module: [29, 53, 66, 69],
	},
	{
		name: "Creator",
		macro: "{{created_by}}",
		excluded_module: [10, 47, 45, 53, 29, 69],
	},
	{
		name: "Managed By",
		macro: "{{managed_by}}",
		excluded_module: [10, 47, 45, 53, 29, 69],
	},
	{
		name: "Visibility To",
		macro: "{{visibility}}",
		excluded_module: [10, 47, 45, 53, 29, 69],
	},
	{
		name: "Asset Vendor",
		macro: "{{wfi_em_avendor_email}}",
		excluded_module: [47, 45, 53, 29, 69, 66],
	}
];

const SMS_SELECTOR_LIST = [
	{
		name: "Requester",
		macro: "{{requester.contact_number}}",
		excluded_module: [29, 66],
	},
	{
		name: "Team Lead/Owner",
		macro: "{{wfi_sm_team_lead}}",
		excluded_module: [29, 66],
	},
	{
		name: "Assignee",
		macro: "{{wfi_sm_current_assignee}}",
		excluded_module: [29, 66],
	},
	{
		name: "Submitter",
		macro: "{{wfi_sm_ticket_submitter}}",
		excluded_module: [29, 66],
	},
	{
		name: "Requester",
		macro: "{{wfi_sm_asset_requester}}",
		excluded_module: [10, 47, 45, 53, 66],
	},
	{
		name: "Owner",
		macro: "{{wfi_sm_asset_owner}}",
		excluded_module: [10, 47, 45, 53, 66],
	},
	{
		name: "Tagged Users",
		macro: "{{wfi_sm_tagged_user}}",
		excluded_module: [10, 47, 45, 53, 66],
	},
	{
		name: "Follower",
		macro: "{{wfi_sms_follower}}",
		excluded_module: [29, 53, 66],
	},
	{
		name: "Reported By",
		macro: "{{wfi_sms_reported_by}}",
		excluded_module: [29, 53, 66],
	},
	{
		name: "Creator",
		macro: "{{created_by}}",
		excluded_module: [10, 47, 45, 53, 29],
	},
	{
		name: "Managed By",
		macro: "{{managed_by}}",
		excluded_module: [10, 47, 45, 53, 29],
	},
	{
		name: "visibility To",
		macro: "{{visibility}}",
		excluded_module: [10, 47, 45, 53, 29],
	},
	{
		name: "Asset Vendor",
		macro: "{{wfi_sms_avendor_phone}}",
		excluded_module: [47, 45, 53, 29, 69, 66],
	}
];

const START_STATE = [
	{
		name: "Open",
		id: 1,
	},
];

const STATUS = [];

const TABLE_FILEDS = [
	{
		name: "Status",
		type: FIELD_TYPE.DROPDOWN,
		key: "status",
		id: 1,
	},
	{
		name: "State",
		type: FIELD_TYPE.DROPDOWN,
		key: "state",
		id: 2,
	},
	{
		name: "Add Comment",
		type: FIELD_TYPE.TEXTFIELD,
		key: "comment",
		id: 3,
	},
	{
		name: "Set Location",
		type: FIELD_TYPE.TEXTFIELD,
		key: "location",
		id: 4,
	},
	{
		name: "Update Last updated datetime",
		type: FIELD_TYPE.DATETIME,
		key: "last_updated",
		id: 5,
	},
];

interface FlowItem {
	type: number;
	data: any;
	level: number;
	is_start: boolean;
	is_end: boolean;
	flow_id: number;
}

const STATE_ALLOWED_CONFIG = {
	1: [2, 3, 4, 5],
	2: [1],
	3: [1],
	4: [1],
	5: [1, 2, 3, 4],
};

enum WorkflowErrorMessage {
	REQUIRED_STATE_OPEN = "Error.err_required_state_open",
	REQUIRED_ASSET_STATE_OPEN = "Error.err_required_asset_state_open",
	REQUIRED_STATE_CLOSE = "Error.err_required_state_close",
	REQUIRED_ASSET_STATE_CLOSE = "Error.err_required_asset_state_close",
	REQUIRED_ASSET_REQUIRED_STATUS = "Error.err_required_asset_status",
	REQUIRED_STATE_RESOLVE = "Error.err_required_state_resolve",
	VALIDATE_FORM_DATA = "Error.err_form_data",
	FLOW_CLOSE = "Error.err_flow_close",
	ACTION_BRANCH_NOT_ALLOWED = "Error.err_action_branch_not_allowed",
	STATE_ACTION_BRANCH_NOT_ALLOWED = "Error.err_state_action_branch_not_allowed",
	SELECTED_STATUS_NOT_AVAILABLE = "Error.err_selected_status_not_available",
	CONDITION_BLOCK_DEFINITION_REQUIRED = "Error.err_condition_block_definition_required",
	APPROVAL_ALLOWED_BLOCK = "Error.err_approval_allowed_block",
}

@Component({
	selector: "dns-workflow",
	templateUrl: "./dns-workflow.component.html",
	styleUrls: ["./dns-workflow.component.scss"],
	providers: [TitleCasePipe],
})
export class DnsWorkflowComponent implements OnInit, AfterViewInit, OnDestroy {
	spacingX = 0;
	spacingY = 0;
	workflowTemplateType = WORKFLOW_TEMPLATE_TYPE;
	tabSelection = "triggers";
	flowItemList: Array<FlowItem> = new Array<FlowItem>();
	flowSequence: Array<number> = new Array<number>();
	@Input("leftmenu-data") elementData: any;
	tempElementData: any;
	radialMenu;
	@ViewChild("canvas", { static: true }) canvas: ElementRef;
	@ViewChild("container", { static: true }) container: ElementRef;
	@ViewChild("wfCondition", { static: true }) wfCondition: WorkflowConditionsComponent;
	@ViewChild("nav", { static: true }) nav: NgbNav;
	stateStatusList: Array<any>;
	conditions: Array<any> = new Array<any>();
	setValueOperands: Array<any> = new Array<any>();
	workflowActionTitle = "Configure Status";
	state: any;
	workflowBlockData: any;
	showSkeleton = true;
	@Input() rightPanelName: string;
	options: any;
	tableFields: any;
	isFirstBlock = false;
	workflowDataById: any;
	isMacro: boolean;
	private tempblock2: any;
	private selectedBlockId: number;
	private prevSelectedBlockId: number;
	isOpenCC: boolean;
	isOpenBCC: boolean;
	emailList: Array<any> = new Array<any>();
	smsList: Array<any> = new Array<any>();
	workflowData: any;
	private fieldFocus = "message_body";
	private isSnapping = false;
	macroData: any;
	macroMap: any = {};
	ngSelectLoading = false;
	selectedStateStatus: any;
	blockLength: any;
	prevBlockLength: any;
	@ViewChild("wfPropertiesForm") public wfPropertiesForm: NgForm;
	currentFormRef: NgForm;
	searchText: string;
	isWorkflowConditionSelected: boolean;
	wf_conditions_error_message: string;
	showStatusColour = false;
	showStatusBgColour = false;
	defaultStatusBgColor = ["#E94B3B", "#FFB61C", "#BFBF66", "#23AE89", "#2EC1CC"];
	defaultStatusColor = ["#E94B3B", "#FFB61C", "#BFBF66", "#23AE89", "#2EC1CC"];
	private stateMap: any = {};
	public duplicate = false;
	currentSelectedOrDragElement: any;
	@Input("wf_gui_map") wfGuidMap = {};
	macroLabelMap = {};
	errorBlockMap = [];
	@ViewChild("smsForm") configSmsForm: NgForm;
	@ViewChild("emailForm") configEmailForm: NgForm;
	@ViewChild("setValueForm") configValueForm: NgForm;
	@ViewChild("transferStateStatusForm") configTransferStateForm: NgForm;
	public headerParamList = [];
	public staticParamList = [];
	public showMacroSection = false;

	public emailEdit = false;
	public quillEdit = false;
	public showMacros: any = false;
	emailMacrosList = [];
	approvalEmailMacrosList = [];
	_workflowDataSubscriber: any;
	allowed_state_map = {
		1: [1, 2, 3, 4, 6, 7],
		2: [2, 3, 4, 6, 7],
		3: [2, 3, 4],
		4: [4, 5],
		5: [4, 5],
		6: [],
		7: [],
	};
	readonly change_allowed_state_map = {
		1: [1, 2, 3, 5, 6, 7],
		2: [2, 3, 5, 6, 7],
		3: [2, 3, 5],
		4: [4, 5],
		5: [4, 5],
		6: [],
		7: [],
	};
	readonly PIR_ENABLED_MODULE = [47];
	readonly IS_DESTROY_ENABLED_MODULE = [InfinityModuleIDs.CMDB];
	statusUpdateList = []; // we will use it for adding status which not been selected from autosuggestion or updated
	is_form_submitted = false;
	state_transition_state_map: any;
	deleted_block = [];
	private readonly ESCALATION_MODULES = [InfinityModuleIDs.INCIDENT];
	private readonly AUTO_CLOSURE_MODULES = [InfinityModuleIDs.INCIDENT, InfinityModuleIDs.REQUEST_PROCESS];
	private readonly AUTO_SUBMIT_MODULE = [
		InfinityModuleIDs.INCIDENT,
		InfinityModuleIDs.REQUEST_PROCESS,
		InfinityModuleIDs.IMACD,
	];
	private readonly REPORTING_MANAGER_MODULE = [InfinityModuleIDs.INCIDENT, InfinityModuleIDs.REQUEST_PROCESS];
	private readonly RESOLUTION_NOTIFICATION_MODULE = [InfinityModuleIDs.PROBLEM];
	private readonly ALLOCATION_DEALLOCATION_MODULES = [InfinityModuleIDs.CMDB];
	private readonly ASSET_MAINTENACE_MODULES = [InfinityModuleIDs.CMDB];
	private readonly ASSET_IN_STOCK_MODULES = [InfinityModuleIDs.CMDB];
	private readonly RESOLVED_BY_EVENT_MODULES = [InfinityModuleIDs.INCIDENT];
	private readonly ALLOWED_TRANSISTION_STATE = [
		InfinityModuleIDs.CMDB,
		InfinityModuleIDs.CONTRACT,
		InfinityModuleIDs.IMACD,
	];
	private readonly RESPONSE_TIME_MODULES = [InfinityModuleIDs.INCIDENT];
	private MODULE_WITHOUT_RESOLVE_STATE = [47, 53, 50, 66, 69];
	isEnableTransitionState = false;

	/**
	 * returns currently active form if any
	 */
	getActiveForm = (): NgForm => {
		let ngForm = this.wfPropertiesForm;
		switch (this.workflowBlockData?.templateType) {
			case WORKFLOW_TEMPLATE_TYPE.SEND_SMS:
				ngForm = this.configSmsForm;
				break;
			case WORKFLOW_TEMPLATE_TYPE.SEND_EMAIL:
				ngForm = this.configEmailForm;
				break;
			case WORKFLOW_TEMPLATE_TYPE.SET_STATUS:
				ngForm = this.configTransferStateForm;
				break;
			case WORKFLOW_TEMPLATE_TYPE.SET_VALUE:
				ngForm = this.configValueForm;
				break;
		}
		return ngForm;
	};

	module_map_config = {
		10: this._translateService.instant("UI.k_incident"),
		42: this._translateService.instant("UI.k_request"),
		45: this._translateService.instant("UI.k_problem"),
		47: this._translateService.instant("UI.k_change"),
		53: this._translateService.instant("UI.k_release"),
		29: this._translateService.instant("UI.k_asset"),
		50: this._translateService.instant("UI.k_task"),
		66: this._translateService.instant("UI.k_contract"),
		69: this._translateService.instant("UI.k_imacd"),
	};

	constructor(
		private _sidebarService: CoreSidebarService,
		private _translateService: TranslateService,
		private _wfService: WorkflowService,
		private renderer: Renderer2,
		private titleCase: TitleCasePipe,
		private _toastr: ToastrService,
		private _permissionService: PermissionService
	) {
		this.renderer.listen("window", "click", (e: Event) => {
			if (
				(e &&
					this.workflowBlockData.subject &&
					this.workflowBlockData.subject.indexOf("{{") > -1 &&
					e["srcElement"] &&
					e["srcElement"]["className"].indexOf("macros") === -1) ||
				(e &&
					this.workflowBlockData["quillText"] &&
					this.workflowBlockData["quillText"].indexOf("{{") > -1 &&
					e["srcElement"] &&
					e["srcElement"]["className"].indexOf("macros") === -1)
			) {
				this.showMacroSection = false;
			}
		});
		this.workflowActionTitle = this._translateService.instant("UI.k_configure_status");
		this.stateStatusList = [
			{
				id: 1,
				name: "New",
				state_id: 1,
			},
		];
		this.state = START_STATE;
		this.workflowBlockData = {};
		this.options = {
			status: STATUS,
			state: STATE,
			fields: TABLE_FILEDS,
		};
		this.tableFields = TABLE_FILEDS;
		this.workflowDataById = {};
		this.workflowBlockData.headerParamList = [{}];
	}

	toggleSidebar(key) {
		this._sidebarService.getSidebarRegistry(key).toggleOpen();
	}

	output(is_transist_state = true) {
		let data;
		try {
			data = this.prepareFlowData(flowy.output(), is_transist_state);
		} catch (e) {
			console.warn("error while getting flowy output data", e);
			data = this.prepareFlowData(this.workflowData?.draft_flow, is_transist_state);
		}
		return data;
	}

	/**
	 * Workflow exit point check, here we will check if workflow have exit point or not.
	 * if exit point is valid then it will return Boolean, else will return block ids
	 */
	checkForExitBlock = () => {};

	/**
	 * @method validatePublishFlow: It will take flow data & returns if flow have invalid data
	 * @param wfData: it is flow list of data
	 * @return Boolean
	 */
	validatePublishFlow = (wfData) => {
		const err_msg_map = {
			required_state_flow: [],
		};
		if (wfData) {
			const invalid_flow = [];
			let is_valid_flow = true;
			const wfBlocks = wfData?.flow;
			let isOpenState = false;
			let isResolveState = false;
			let isCloseState = false;
			const cond_block_type = [
				WORKFLOW_BLOCK_TYPE.CONDITION,
				WORKFLOW_BLOCK_TYPE.CONDITION_SUCCESS,
				WORKFLOW_BLOCK_TYPE.CONDITION_FAILED,
				WORKFLOW_BLOCK_TYPE.APPROVAL_REJECTED,
				WORKFLOW_BLOCK_TYPE.APPROVAL_ACCEPTED,
				WORKFLOW_BLOCK_TYPE.REVIEW_ACCEPTED,
				WORKFLOW_BLOCK_TYPE.REVIEW_REJECTED,
			];
			let cmdb_allocation_state_validation = [];
			for (const blockData of wfBlocks) {
				const childrenBlock = wfBlocks.filter((item) => item.parent === blockData?.flow_id);
				const type = parseInt(blockData.type);
				const templateType = parseInt(blockData?.data?.templateType);

				if (type === WORKFLOW_BLOCK_TYPE.STATE) {
					if (this.workflowData?.module_id !== InfinityModuleIDs.CMDB) {
						// checking if we have at least one open state
						if (!isOpenState && blockData?.data?.state_id === 1) {
							isOpenState = true;
						} else if (
							!isOpenState &&
							!err_msg_map.required_state_flow.includes(WorkflowErrorMessage.REQUIRED_STATE_OPEN)
						) {
							err_msg_map.required_state_flow.push(WorkflowErrorMessage.REQUIRED_STATE_OPEN);
						}

						// checking if we have atleast one close state
						if (!isCloseState && blockData?.data?.state_id === 5) {
							isCloseState = true;
						} else if (
							!isCloseState &&
							!err_msg_map.required_state_flow.includes(WorkflowErrorMessage.REQUIRED_STATE_CLOSE)
						) {
							err_msg_map.required_state_flow.push(WorkflowErrorMessage.REQUIRED_STATE_CLOSE);
						}

						// checking if we have atleast one resolve state
						if (
							(!isResolveState && blockData?.data?.state_id === 4) ||
							this.MODULE_WITHOUT_RESOLVE_STATE.includes(this.workflowData?.module_id)
						) {
							isResolveState = true;
						} else if (
							!isResolveState &&
							!err_msg_map.required_state_flow.includes(WorkflowErrorMessage.REQUIRED_STATE_RESOLVE)
						) {
							err_msg_map.required_state_flow.push(WorkflowErrorMessage.REQUIRED_STATE_RESOLVE);
						}
					} else if (this.workflowData?.module_id === InfinityModuleIDs.CMDB) {
						// checking if we have at least one open state
						if (!isOpenState && blockData?.data?.state_id === 1) {
							isOpenState = true;
						} else if (
							!isOpenState &&
							!err_msg_map.required_state_flow.includes(WorkflowErrorMessage.REQUIRED_ASSET_STATE_OPEN)
						) {
							err_msg_map.required_state_flow.push(WorkflowErrorMessage.REQUIRED_ASSET_STATE_OPEN);
						}

						// checking if we have atleast one close state
						if (!isCloseState && blockData?.data?.state_id === 5) {
							isCloseState = true;
						} else if (
							!isCloseState &&
							!err_msg_map.required_state_flow.includes(WorkflowErrorMessage.REQUIRED_ASSET_STATE_CLOSE)
						) {
							err_msg_map.required_state_flow.push(WorkflowErrorMessage.REQUIRED_ASSET_STATE_CLOSE);
						} else if (
							isCloseState &&
							err_msg_map.required_state_flow.includes(WorkflowErrorMessage.REQUIRED_ASSET_STATE_CLOSE)
						) {
							const index = err_msg_map.required_state_flow.indexOf(
								WorkflowErrorMessage.REQUIRED_ASSET_STATE_CLOSE,
								0
							);
							if (index > -1) {
								err_msg_map.required_state_flow.splice(index, 1);
							}
						}

						if (blockData?.data?.state_id === 2) {
							if (blockData?.data.is_allocation === true) {
								cmdb_allocation_state_validation.push(1);
							} else if (blockData?.data.is_deallocation === true) {
								cmdb_allocation_state_validation.push(2);
							} else if (blockData?.data.is_allocation_rejection === true) {
								cmdb_allocation_state_validation.push(3);
							}
						} else if (blockData?.data?.state_id === 3) {
							if (blockData?.data.is_allocation_acceptance === true) {
								cmdb_allocation_state_validation.push(4);
							} else if (blockData?.data.is_maintenance === true) {
								cmdb_allocation_state_validation.push(5);
							} else if (blockData?.data.is_in_stock === true) {
								cmdb_allocation_state_validation.push(6);
							}
						}
					}
				}

				// checking if user has configured block or if configured then valid or not
				if (
					(blockData?.data === undefined && !cond_block_type.includes(type)) ||
					(!blockData?.data?.is_valid && !cond_block_type.includes(type))
				) {
					if (!invalid_flow.includes(blockData?.flow_id)) {
						invalid_flow.push(blockData?.flow_id);
						err_msg_map[blockData?.flow_id] = WorkflowErrorMessage.VALIDATE_FORM_DATA;
					}
					this.toggleBorder(blockData?.flow_id, false);
				} else if (
					childrenBlock?.length === 0 &&
					!blockData?.is_end &&
					blockData?.sub_type !== WORKFLOW_TEMPLATE_TYPE.SET_STATUS
				) {
					// check for exit flow
					const temp_transition_states = blockData?.data?.transition_states || [];
					if (!invalid_flow.includes(blockData?.flow_id) && temp_transition_states?.length === 0) {
						invalid_flow.push(blockData?.flow_id);
						err_msg_map[blockData?.flow_id] = WorkflowErrorMessage.FLOW_CLOSE;
					}
					if (invalid_flow.includes(blockData?.flow_id)) {
						this.toggleBorder(blockData?.flow_id, false);
					}
				} else if (
					type === WORKFLOW_BLOCK_TYPE.ACTION &&
					childrenBlock.length > 1 &&
					childrenBlock.findIndex((x) => parseInt(x.type) === WORKFLOW_BLOCK_TYPE.ACTION) !== -1
				) {
					if (!invalid_flow.includes(blockData?.flow_id)) {
						invalid_flow.push(blockData?.flow_id);

						err_msg_map[blockData?.flow_id] = WorkflowErrorMessage.ACTION_BRANCH_NOT_ALLOWED;
					}
					this.toggleBorder(blockData?.flow_id, false);
				} else if (
					type === WORKFLOW_BLOCK_TYPE.STATE &&
					childrenBlock.length > 1 &&
					childrenBlock.findIndex((x) =>
						[WORKFLOW_BLOCK_TYPE.ACTION, WORKFLOW_BLOCK_TYPE.CONDITION].includes(parseInt(x.type))
					) !== -1
				) {
					// restricting as we are not allowing any action to be branched
					if (!invalid_flow.includes(blockData?.flow_id)) {
						invalid_flow.push(blockData?.flow_id);

						err_msg_map[blockData?.flow_id] = WorkflowErrorMessage.STATE_ACTION_BRANCH_NOT_ALLOWED;
					}
					this.toggleBorder(blockData?.flow_id, false);
				} else if (
					templateType === WORKFLOW_TEMPLATE_TYPE.SET_STATUS &&
					this.validateTransferState(blockData)
				) {
					// restricting as status not available in current flow
					if (!invalid_flow.includes(blockData?.flow_id)) {
						invalid_flow.push(blockData?.flow_id);

						err_msg_map[blockData?.flow_id] = WorkflowErrorMessage.SELECTED_STATUS_NOT_AVAILABLE;
					}

					this.toggleBorder(blockData?.flow_id, false);
				} else if (
					[WORKFLOW_BLOCK_TYPE.APPROVAL_ACCEPTED, WORKFLOW_BLOCK_TYPE.APPROVAL_REJECTED].includes(type) &&
					childrenBlock.findIndex((x) =>
						[WORKFLOW_BLOCK_TYPE.ACTION, WORKFLOW_BLOCK_TYPE.CONDITION].includes(parseInt(x.type))
					) !== -1
				) {
					err_msg_map[blockData?.flow_id] = WorkflowErrorMessage.APPROVAL_ALLOWED_BLOCK;
					invalid_flow.push(blockData?.flow_id);
				} else if (
					[WORKFLOW_BLOCK_TYPE.REVIEW_ACCEPTED].includes(type) &&
					childrenBlock.findIndex((x) =>
						[WORKFLOW_BLOCK_TYPE.ACTION, WORKFLOW_BLOCK_TYPE.CONDITION].includes(parseInt(x.type))
					) !== -1
				) {
					err_msg_map[blockData?.flow_id] = WorkflowErrorMessage.APPROVAL_ALLOWED_BLOCK;
					invalid_flow.push(blockData?.flow_id);
				} else {
					// checking if block belong to condition type
					if (cond_block_type.includes(type)) {
						if (childrenBlock.length === 0) {
							if (!invalid_flow.includes(blockData?.flow_id)) {
								invalid_flow.push(blockData?.flow_id);
								err_msg_map[blockData?.flow_id] =
									WorkflowErrorMessage.CONDITION_BLOCK_DEFINITION_REQUIRED;
							}
							if (type === WORKFLOW_BLOCK_TYPE.CONDITION_FAILED) {
								this.toggleBorder(blockData?.flow_id, false, "border-warning");
							} else {
								this.toggleBorder(blockData?.flow_id, false);
							}
						}
						// checking if flow exist in invalid flow
					} else if (invalid_flow.includes(blockData?.flow_id)) {
						invalid_flow.splice(
							invalid_flow.findIndex((x) => x === blockData?.flow_id),
							1
						);
						delete err_msg_map[blockData?.flow_id];
						this.toggleBorder(blockData?.flow_id, true);
					}
				}
			}
			// if any block have invalid data so making flow as invalid
			if (invalid_flow.length > 0) {
				is_valid_flow = false;
			} else if (
				cmdb_allocation_state_validation?.length < 6 &&
				this.workflowData?.module_id === InfinityModuleIDs.CMDB
			) {
				if (!err_msg_map.required_state_flow.includes(WorkflowErrorMessage.REQUIRED_ASSET_REQUIRED_STATUS)) {
					err_msg_map.required_state_flow.push(WorkflowErrorMessage.REQUIRED_ASSET_REQUIRED_STATUS);
				}
				is_valid_flow = false;
			} else if (this.workflowData?.module_id !== InfinityModuleIDs.CMDB) {
				is_valid_flow = isOpenState && isResolveState && isCloseState;
			} else if (this.workflowData?.module_id === InfinityModuleIDs.CMDB) {
				is_valid_flow = isOpenState && isCloseState;
			}
			if (is_valid_flow) {
				this.removeAllErrorBorderFromBlock();
				return { is_valid: true };
			}
		}
		return { is_valid: false, error_map: err_msg_map };
	};

	/**
	 * it will remove all error marked border for blocks, if somehow its not cleared
	 */
	removeAllErrorBorderFromBlock = () => {
		const elements = document.querySelectorAll(".border-danger");
		elements?.forEach((v, i) => {
			v.classList.remove("border-danger");
			v.classList.remove("border");
		});
	};

	/**
	 * It will verify if selected status is available or not
	 * @param blockData
	 * @return: boolean
	 */
	validateTransferState = (blockData) => {
		if (blockData && blockData?.status) {
			const blockIndex = this.options.status.findIndex((x) => x.guid === blockData.status.guid);
			return blockIndex !== -1;
		}
		return false;
	};

	// preparing final flow data
	prepareFlowData = (data: any, is_transist_state) => {
		if (data) {
			for (const item of data.flow) {
				item.data = this.workflowDataById[item.flow_id];
				item.sub_type = item?.data?.sub_type || -1;
				if (item?.data && item.data?.is_end) {
					item.is_end = item.data.is_end;
				}

				if (item?.data && item.data?.is_escalated) {
					item.is_escalated = item.data.is_escalated;
				}
				// if(item.data?.transition_states){
				//
				// }
			}
			if (is_transist_state) {
				this.state_transition_state_map = this.parseStateData(data.flow);
				data.flow.map((f) => {
					if (f.data) {
						f.data.transition_states = this.state_transition_state_map[f.flow_id]?.transition_states || [];
					}
					return f;
				});
			}
			data.state = this.options.state;
			data.status = this.options.status;
			data.workflow_guid_data_map = this.wfGuidMap;
			data.status_update_list = this.statusUpdateList;
		}
		return data;
	};

	public render = (templateData) => {
		flowy.import(templateData);
		if (templateData?.workflow_guid_data_map) {
			this.wfGuidMap = templateData?.workflow_guid_data_map;
		} else {
			this.wfGuidMap = {};
		}
		if (this.workflowData && this.workflowData?.draft_flow?.status_update_list) {
			this.statusUpdateList = this.workflowData?.draft_flow?.status_update_list || [];
			this.state_transition_state_map = this.parseStateData(this.workflowData?.draft_flow?.flow);
		}
		this.selectedBlockId = flowy.getCurrentId();
		this.prevSelectedBlockId = flowy.getCurrentId();
		this.isEnableTransitionState = this.ALLOWED_TRANSISTION_STATE.includes(this.workflowData?.module_id);
	};

	updateOptions = (data: any) => {
		this.showSkeleton = false;
		this.workflowData = data;
		if (data.options) {
			this.options = data.options;
			if (this.options?.state) {
				this.defaultStatusColor = [];
				this.defaultStatusBgColor = [];
				for (const stateElement of this.options?.state) {
					this.defaultStatusColor.push(stateElement?.color_hex);
					this.defaultStatusBgColor.push(stateElement?.background_color);
					this.stateMap[stateElement?.id] = stateElement;
				}
			}

			// updating left panel configuration based on backend response
			if (this.options?.left_panel_config) {
				this.elementData = this.options?.left_panel_config;
				this.tempElementData = JSON.parse(JSON.stringify(this.elementData));
			}

			if (data.options?.allowed_state_map) {
				this.allowed_state_map = data.options?.allowed_state_map;
			}
		}

		this.options.status = this.workflowData.draft_flow.status;
		this.emailMacrosList = this.options?.macros?.filter((d) => d.show_on_ui);
		this.prepareApprovalEmailMacros();
		const filteredEmailSelector = EMAIL_SELECTOR_LIST.filter(
			(es) => !es.excluded_module.includes(this.workflowData.module_id)
		);
		const filteredSmsSelector = SMS_SELECTOR_LIST.filter(
			(es) => !es.excluded_module.includes(this.workflowData.module_id)
		);
		this.emailList = _deepCopyJson(filteredEmailSelector);
		this.smsList = _deepCopyJson(filteredSmsSelector);
		this.setWorkflowDataById();
		this.prepareConditionOptions();
		this.prepareSetValueOperands();
		this.setStateTransitionMap();
	};

	setStateTransitionMap = () => {
		this.state_transition_state_map = this.parseStateData(this.workflowData?.draft_flow?.flow);
	};

	prepareApprovalEmailMacros = () => {
		this.approvalEmailMacrosList = _deepCopyJson(this.emailMacrosList);
		const tempMacros = this.options?.approval_email_macros?.filter((d) => d.show_on_ui);
		for (const tempMacro of tempMacros) {
			this.approvalEmailMacrosList.push(tempMacro);
		}
	};

	onStateSelection = (data) => {
		this.selectedStateStatus = [];
		for (const item of this.options["status"]) {
			if (item?.state_id == data.id && !item?.is_deleted) {
				this.selectedStateStatus.push(item);
			}
		}
		this.workflowBlockData.status = null;
	};

	onNavChange = (event) => {
		this.searchText = "";
		this.elementData[event.activeId] = this.tempElementData[event.activeId];
		this.updateLangForTempElementData();
	};

	onSearchWorkflowItem = (event) => {
		this.elementData[this.nav.activeId] = this.tempElementData[this.nav.activeId].filter((config) => {
			return (
				config?.title.toLowerCase().includes(this.searchText.toLowerCase()) ||
				config?.description.toLowerCase().includes(this.searchText.toLowerCase())
			);
		});
	};

	prepareSetValueOperands = () => {
		this.generateMacroKeyMap();
		this.setValueOperands = SET_VALUE_OPERAND.source.filter(
			(sv) => !sv.exclude_module.includes(this.workflowData.module_id)
		);
		for (let i = 0; i < this.setValueOperands.length; i++) {
			const key = this.setValueOperands[i]["key"];
			const macroKey = this.setValueOperands[i]["macroKey"];
			this.setValueOperands[i]["name"] = this._translateService.instant(this.setValueOperands[i]["name"]);
			const optionsData = this.options[key];
			if (optionsData) {
				this.setValueOperands[i]["options"] = optionsData;
			}
			this.setValueOperands[i]["macro"] =
				this.macroMap[macroKey]?.if_key || this.macroMap[macroKey]?.map_key || macroKey;
		}
	};

	prepareConditionOptions = () => {
		this.generateMacroKeyMap();
		this.conditions = CONDITIONS_JSON.source.filter(
			(cond) => !cond?.excluded_modules.includes(this.workflowData?.module_id)
		);
		for (let i = 0; i < this.conditions.length; i++) {
			const key = this.conditions[i]["key"];
			const macroKey = this.conditions[i]["macroKey"];
			this.conditions[i]["name"] = this._translateService.instant(this.conditions[i]["name"]);
			const optionsData = this.options[key];
			if (optionsData) {
				this.conditions[i]["options"] = optionsData;
			}
			this.conditions[i]["macro"] =
				this.macroMap[macroKey]?.if_key || this.macroMap[macroKey]?.map_key || macroKey;
		}
	};

	handleColorChange = ($event, item, key = "background_color") => {
		if (item) {
			item[key] = $event;
			this.showStatusColour = false;
			this.showStatusBgColour = false;
			this.workflowBlockData[key] = $event;
		}
	};

	setWorkflowDataById() {
		if (this.workflowData?.draft_flow?.flow) {
			for (const item of this.workflowData.draft_flow.flow) {
				this.workflowDataById[item.flow_id] = item.data;
			}
		}
	}

	ngOnInit(): void {
		flowy(
			this.canvas.nativeElement,
			this.drag,
			this.release,
			this.snapping,
			this.rearrange,
			87,
			87,
			this.onAdd,
			this.wfGuidMap,
			this.handleAttachable
		);
		// this.toggleRightPanel(this.rightPanelName);
		this.macroData = MACRO_DATA;
		if (this.elementData) {
			this.tempElementData = JSON.parse(JSON.stringify(this.elementData));
		}
	}

	updateLangForTempElementData = () => {
		if (this.tempElementData && this.nav.activeId) {
			this.tempElementData[this.nav.activeId].forEach((v, i) => {
				v["title"] = this._translateService.instant(v?.title);
				v["description"] = this._translateService.instant(v?.description);
			});
		}
	};

	// encryptPassword(password: string) {
	// 	var iv = "d99630bcc7058639e7e80df239f38a32";
	// 	var ciphertext = AES.encrypt(password, iv).toString();
	// 	var aesPassword = iv + "::" + ciphertext;
	// 	return btoa(aesPassword);
	// }

	addItem() {
		if (!this.workflowBlockData.headerParamList) {
			this.workflowBlockData.headerParamList = [];
		}
		this.workflowBlockData.headerParamList.push({
			keys: "",
			values: "",
			queryparam: "",
			id: generate_uuid("api"),
		});
	}

	addstaticItem() {
		if (!this.workflowBlockData.staticParamList) {
			this.workflowBlockData.staticParamList = [];
		}
		this.workflowBlockData.staticParamList.push({
			keys: "",
			values: "",
			queryparam: "",
			id: generate_uuid("api"),
		});
	}

	deleteItem = (id) => {
		const index = this.workflowBlockData.headerParamList?.findIndex((param) => param.id === id);
		if (index !== -1) {
			this.workflowBlockData.headerParamList.splice(index, 1);
		}
	};

	deletestaticItem = (id) => {
		const index = this.workflowBlockData.staticParamList?.findIndex((param) => param.id === id);
		if (index !== -1) {
			this.workflowBlockData.staticParamList.splice(index, 1);
		}
	};

	ngOnDestroy(): void {
		if (flowy) {
			flowy.destroy();
		}
		Swal.close();
	}

	generateMacroKeyMap() {
		this.workflowData?.options?.macros?.map((data) => (this.macroMap[data.key] = data));
		this.prepareMacroData();
		Object.keys(this.macroMap).forEach((e) => {
			this.macroLabelMap[this.macroMap[e]?.if_key || this.macroMap[e]?.map_key || e] = this.macroMap[e]?.label;
		});
	}

	setBlockSliderTitle = (type: number) => {
		switch (type) {
			case WORKFLOW_BLOCK_TYPE.STATE:
				this.workflowActionTitle = this._translateService.instant("UI.k_configure_status");
				break;
			case WORKFLOW_BLOCK_TYPE.ACTION:
				this.workflowActionTitle = this._translateService.instant("UI.k_set_action");
				break;
			case WORKFLOW_BLOCK_TYPE.CONDITION:
				this.workflowActionTitle = this._translateService.instant("UI.k_set_condition");
				break;
		}
	};

	prepareMacroData() {
		if (this.workflowData?.options?.macros) {
			const obser = from(this.workflowData?.options?.macros);
			this.macroData = [];
			const map = obser
				.pipe(
					groupBy((data) => data["group_label"]),
					mergeMap((group) => group.pipe(toArray()))
				)
				.subscribe((data) => this.updateMacrosdata(data));
		}
	}

	updateMacrosdata = (data) => {
		if (data && data.length > 0) {
			const obj = {
				name: data[0]["group_label"],
				tags: data,
			};
			this.macroData.push(obj);
		}
	};

	rearrange = () => {
		return true;
	};

	ngAfterViewInit() {
		// this.addEventListenerMulti('click', this.onRightPanel, false, '.blockyright');
		// let elem = document.getElementById("#canvas");
		// elem.querySelector(".blockelem").addEventListener("click", this.onBlockClick)
		this.updateLangForTempElementData();
	}

	onRightPanel() {
		// console.log("right panel clicked")
	}

	toggleRightPanel(key) {
		if (this.workflowBlockData.templateType === WORKFLOW_TEMPLATE_TYPE.SEND_SMS) {
			if (this.workflowBlockData?.to) {
				for (const workflowBlockDatum of this.workflowBlockData.to) {
					if (!workflowBlockDatum.includes("{{")) {
						const tempSmsSelector = { name: workflowBlockDatum, macro: workflowBlockDatum };
						if (this.smsList.filter((x) => x.name === workflowBlockDatum)?.length === 0) {
							this.smsList.push(tempSmsSelector);
						}
					}
				}
			}
		}

		if (this.workflowBlockData.templateType === WORKFLOW_TEMPLATE_TYPE.SEND_EMAIL) {
			if (this.workflowBlockData?.to) {
				for (const workflowBlockDatum of this.workflowBlockData.to) {
					if (!workflowBlockDatum.includes("{{")) {
						const tempEmailSelector = { name: workflowBlockDatum, macro: workflowBlockDatum };
						if (this.emailList.filter((x) => x.name === workflowBlockDatum)?.length === 0) {
							this.emailList.push(tempEmailSelector);
						}
					}
				}
			}

			if (this.workflowBlockData?.cc) {
				for (const workflowBlockDatum of this.workflowBlockData?.cc) {
					if (!workflowBlockDatum.includes("{{")) {
						const tempEmailSelector = { name: workflowBlockDatum, macro: workflowBlockDatum };
						if (this.emailList.filter((x) => x.name === workflowBlockDatum)?.length === 0) {
							this.emailList.push(tempEmailSelector);
						}
					}
				}
			}
		}
		this.togglePanel(key);
	}

	onRemoveBlock = () => {
		flowy.deleteBlocks();
	};

	onPublish = () => {
		// TBD
	};

	onAdd = (drag) => {
		this.handleSlider(drag);
	};

	addEventListenerMulti = (type, listener, capture, selector) => {
		const nodes = this.container.nativeElement.querySelectorAll(selector);
		for (let i = 0; i < nodes.length; i++) {
			nodes[i].addEventListener(type, listener, capture);
		}
	};

	emailSubjectEvent(event) {
		if (
			event &&
			event.indexOf("{{") > -1 &&
			this.workflowBlockData.subject.split("{{")[this.workflowBlockData.subject.split("{{").length - 1] === ""
		) {
			this.showMacroSection = true;
			this.emailEdit = true;
			this.quillEdit = false;
		} else {
			this.showMacroSection = false;
			this.emailEdit = false;
		}
	}

	macrosSelectEvent(event) {
		if (event && event.item && event?.currentEdit && event.currentEdit === "quillEdit") {
			if (this.workflowBlockData["quillText"]) {
				let displayString = "";
				displayString = (event?.item?.map_key || event?.item?.macro_key) + "}}";
				const tempDisplayString = this.workflowBlockData["quillText"]?.trim() + displayString;
				this.workflowBlockData["quillText"] = tempDisplayString;
				this.workflowBlockData["erichTextData"] = tempDisplayString;
				const qlEditorRef = document.getElementsByClassName("ql-editor")[0];
				const lastChildContentRef =
					qlEditorRef.childNodes[qlEditorRef.childNodes.length > 0 ? qlEditorRef.childNodes.length - 1 : 0];
				lastChildContentRef.textContent = lastChildContentRef.textContent?.trim() + displayString;
			}
		} else if (event && event.item && event?.currentEdit && event.currentEdit === "emailEdit") {
			let displayString = "";
			displayString = (event?.item?.map_key || event?.item?.macro_key) + "}}";
			displayString = this.workflowBlockData.subject + displayString;
			this.workflowBlockData.subject = displayString;
		}
	}

	getEnrichTextData = (event, validation) => {
		this.workflowBlockData.erichTextData = event.html;
		if (
			event &&
			event.text &&
			event.text.indexOf("{{") > -1 &&
			event.text.split("{{")[event.text.split("{{").length - 1] === "\n"
		) {
			this.quillEdit = true;
			this.emailEdit = false;
			this.showMacroSection = true;
			if (validation?.invalid) {
				this.workflowBlockData["message_body"] = "";
			}
			this.workflowBlockData["quillText"] = event.text;
			this.showMacros = true;
			const iDiv = document.createElement("div");
			iDiv.className = "email-block";
		} else {
			this.workflowBlockData["quillText"] = "";
			this.quillEdit = false;
			if (validation?.invalid) {
				this.workflowBlockData["message_body"] = "";
			}
		}
	};

	snapping = (drag, first, parent, wfGuidMap = {}) => {
		if (wfGuidMap) {
			this.wfGuidMap = wfGuidMap;
		}
		this.currentSelectedOrDragElement = drag;
		this.isSnapping = true;
		this.isFirstBlock = first;
		const grab = drag.querySelector(".grabme");
		grab.parentNode.removeChild(grab);
		const blockin = drag.querySelector(".blockin");
		const blockID = drag.querySelector(".blockid").value;
		const blockElementType = parseInt(drag.querySelector(".blockelemtype").value);
		blockin?.parentNode.removeChild(blockin);
		if (!this.wfGuidMap[blockID]) {
			const tempElement = drag.querySelector(".blockelemtype");
			let tempData = tempElement.getAttribute("data-extra");
			tempData = JSON.parse(tempData);
			if (first) {
				tempData.is_first = first;
			}
			if (tempData?.templateType === WORKFLOW_TEMPLATE_TYPE.ASK_FOR_APPROVAL) {
				tempData["subject"] = this.options?.approval_default_template?.subject;
				tempData["message_body"] = this.options?.approval_default_template?.body;
			}
			this.wfGuidMap[blockID] = tempData;
			tempElement.removeAttribute("data-extra");
		}
		this.workflowBlockData = this.wfGuidMap[blockID];
		if (blockID) {
			this.workflowBlockData.guid = blockID;
		}
		// drag.querySelector(".blockelemtype").setAttribute("data-extra", JSON.stringify(this.workflowBlockData));
		if (blockElementType === WORKFLOW_BLOCK_TYPE.STATE) {
			this.setBlockSliderTitle(WORKFLOW_BLOCK_TYPE.STATE);
			this.workflowBlockData["background_color"] =
				this.stateMap[this.workflowBlockData.state?.id]?.background_color;
			this.workflowBlockData["color"] = this.stateMap[this.workflowBlockData.state?.id]?.color_hex;
			drag.innerHTML += `<div class="blockyleft">
                            <span class="img ico-event feather icon-${this.workflowBlockData.icon}"></span>
                            <p class="blockyname" id="blockTitle${
								this.workflowBlockData.guid
							}">${this._translateService.instant(this.workflowBlockData.state.name)}</p>
                        </div>
                        <div class="blockyright"><span class="fal fa-trash-alt text-danger"></span></div>
                        <div class="blockydiv"></div>
                        <div class="blockyinfo" id="blockDesc${
							this.workflowBlockData.guid
						}">${this._translateService.instant(this.workflowBlockData.description)}</div>
                        <div class="blockplaceholder">${this._translateService.instant("UI.k_drop_here")}</div>`;
		} else if (blockElementType === WORKFLOW_BLOCK_TYPE.ACTION) {
			this.setBlockSliderTitle(WORKFLOW_BLOCK_TYPE.ACTION);
			drag.innerHTML += `<div class="blockyleft">
                            <span class="img ico-actions feather icon-${this.workflowBlockData.icon}"></span>
                            <p class="blockyname" id="blockTitle${this.workflowBlockData.guid}" >${
				this._translateService.instant(this.workflowBlockData.title) || ""
			}</p>
                        </div>
                        <div class="blockyright"><span class="fal fa-trash-alt text-danger"></span></div>
                        <div class="blockydiv"></div>
                        <div class="blockyinfo" id="blockDesc${this.workflowBlockData.guid}" >${
				this._translateService.instant(this.workflowBlockData.description) || ""
			}</div>
                        <div class="blockplaceholder">${this._translateService.instant("UI.k_drop_here")}</div>`;
		} else if (blockElementType === WORKFLOW_BLOCK_TYPE.CONDITION) {
			this.setBlockSliderTitle(WORKFLOW_BLOCK_TYPE.CONDITION);
			drag.innerHTML += `<div class="blockyleft">
          <span class="img ico-conditions feather icon-${this.workflowBlockData.icon}"></span>
          <p class="blockyname" id="blockTitle${this.workflowBlockData.guid}">${
				this._translateService.instant(this.workflowBlockData.title) || ""
			}</p>
      </div>
      <div class="blockyright"><span class="fa fa-trash-alt text-danger"></span></div>
      <div class="blockydiv"></div>
      <div class="blockyinfo" id="blockDesc${this.workflowBlockData.guid}">${
				this._translateService.instant(this.workflowBlockData.description) || ""
			}</div>`;
			if (!this.workflowBlockData?.condition) {
				this.workflowBlockData["condition"] = [{}];
			}
		}
		this.isSnapping = false;
		this.wfGuidMap[this.workflowBlockData?.guid] = this.workflowBlockData;
		return true;
	};

	drag = (block) => {
		block.classList.add("blockdisabled");
		this.tempblock2 = block;
	};

	release = (drag = null) => {
		if (drag && drag?.classList?.contains("blockdisabled")) {
			drag.classList.remove("blockdisabled");
		}
		if (drag && drag?.classList?.contains("dragging")) {
			drag.classList.remove("dragging");
		}
	};

	handleSlider = (drag) => {
		this.selectedBlockId = flowy.getCurrentId();
		if (this.selectedBlockId != this.prevSelectedBlockId) {
			this.prevSelectedBlockId = this.selectedBlockId;
		}

		if (this.snapping) {
			this.togglePanel(this.rightPanelName);
			this.isSnapping = !this.isSnapping;
		}
	};

	addActionBlock = () => {};

	onCloseLeftMenu = (event) => {
		const ele = this.container.nativeElement.querySelector("#leftcard");
		if (ele.classList.contains("leftcard-collapsed")) {
			ele.classList.remove("leftcard-collapsed");
			ele.classList.add("leftcard-expanded");
		} else {
			ele.classList.add("leftcard-collapsed");
			ele.classList.remove("leftcard-expanded");
		}
	};

	doneTouch = (event) => {
		this.togglePanel(this.rightPanelName);
	};

	onStateStatusAdd() {
		const stateStatusData = {
			state_id: 1,
			status: "",
		};
		this.stateStatusList.push(stateStatusData);
	}

	onSaveapi = (formRef) => {
		if (formRef?.form?.valid) {
			this.workflowBlockData.is_valid = true;
			document.querySelector("#blockTitle" + this.workflowBlockData.guid).innerHTML =
				this._translateService.instant(this.workflowBlockData.title);
			document.querySelector("#blockDesc" + this.workflowBlockData.guid).innerHTML =
				this.workflowBlockData?.api_list_data?.name;

			this.workflowDataById[this.workflowBlockData.guid] = this.workflowBlockData;
			this.toggleBorder(this.workflowBlockData.guid, true);
			this.toggleRightPanel(this.rightPanelName);
		} else {
			this.workflowBlockData.is_valid = false;
		}
	};

	onSaveStateStatus = (formRef) => {
		if (formRef?.form?.valid) {
			const tempGuid = this.workflowBlockData?.guid;
			const parent = flowy.getParentBlockGuid(tempGuid);
			let parentData = null;
			if (parent) {
				parentData = this.workflowDataById[parent];
			}

			if (
				parentData &&
				parseInt(parentData.type) === WORKFLOW_BLOCK_TYPE.STATE &&
				parentData.status === this.workflowBlockData.status.name
			) {
				this.workflowBlockData.is_valid = false;
			} else {
				this.workflowBlockData.is_valid = true;
			}
			document.querySelector("#blockTitle" + tempGuid).innerHTML =
				this.workflowBlockData.state?.name || this.workflowBlockData.state;
			document.querySelector("#blockDesc" + tempGuid).innerHTML = this.workflowBlockData.status.name;
			this.workflowBlockData.update = [];
			this.workflowBlockData.update.push({
				operand: "basic_info.state",
				value: this.workflowBlockData.state,
			});
			this.workflowBlockData.update.push({
				operand: "basic_info.status",
				value: this.workflowBlockData.status,
			});
			this.workflowDataById[tempGuid] = this.workflowBlockData;
			this.wfGuidMap[tempGuid] = this.workflowBlockData;
			this.toggleBorder(tempGuid, this.workflowBlockData.is_valid);
			this.toggleRightPanel(this.rightPanelName);
		} else {
			this.workflowBlockData.is_valid = false;
		}
	};

	saveValue = (setValueForm, compRef: DnsValueSelectorComponent) => {
		const is_duplicate = compRef.isDuplicate();
		const isValid = compRef.validateSetValueData(this.workflowBlockData?.update);
		if (setValueForm?.form?.valid && !is_duplicate && isValid) {
			this.workflowBlockData.is_valid = true;
			document.querySelector("#blockDesc" + this.workflowBlockData.guid).innerHTML = this.getSetValueText();
			this.workflowDataById[this.workflowBlockData.guid] = this.workflowBlockData;
			this.toggleBorder(this.workflowBlockData.guid, true);
			this.toggleRightPanel(this.rightPanelName);
		} else {
			this.workflowBlockData.is_valid = false;
			this.toggleBorder(this.workflowBlockData.guid, false);
		}
	};

	getSetValueText() {
		const test = {
			test: "",
		};
		let tempValueText = "";
		if (this.workflowBlockData?.update) {
			if (this.workflowBlockData.update?.length > 0) {
				const updateElement = this.workflowBlockData.update[0];
				const label = this._translateService.instant(this.macroLabelMap[updateElement?.operand] || "");
				tempValueText = this._translateService.instant("UI.k_set_value_args", {
					prop: label,
					value: updateElement?.value?.name ? updateElement?.value?.name : updateElement?.name,
				});
				if (this.workflowBlockData.update?.length > 1) {
					tempValueText += ` & + ${this.workflowBlockData.update?.length - 1}`;
				}
			} else {
				tempValueText = "Set Value";
			}
			return tempValueText;
		}
		return tempValueText;
	}

	togglCcBcc(toggleRef) {
		if (toggleRef == "cc") {
			this.isOpenCC = !this.isOpenCC;
		} else {
			this.isOpenBCC = !this.isOpenBCC;
		}
	}

	onSubmit = (event) => {
		const id = "btn_save_";
		switch (event?.submitter?.name) {
			case id + this.workflowTemplateType.CONFIG_STATUS:
				// this.onSaveStatus();
				break;
		}
	};

	onSaveStatus = (formRef) => {
		this.is_form_submitted = true;
		if (formRef?.form?.invalid) {
			this.workflowBlockData.is_valid = false;
			return;
		}
		// update name to capitalize
		if (
			this.workflowBlockData?.message_body &&
			!this.workflowBlockData.message_body.includes("{{approval_link}}")
		) {
			this._toastr.error(
				this._translateService.instant("Message.msg_missing_approval_link"),
				this._translateService.instant("Message.msg_error")
			);
			return;
		}
		const wfStatus = this.workflowBlockData.status;
		if (typeof wfStatus === "object") {
			this.workflowBlockData.status = this.titleCase.transform(wfStatus?.name);
			this.workflowBlockData.id = wfStatus?.id;
			this.workflowBlockData.block_id = wfStatus?.id;
		} else {
			this.workflowBlockData.status = this.titleCase.transform(this.workflowBlockData.status);
		}
		let existing_obj = this.getObjectByKeyValue(
			this.options?.status || [],
			"name",
			this.workflowBlockData.status,
			this.workflowBlockData,
			"guid"
		);
		existing_obj = existing_obj ? (existing_obj?.is_deleted ? null : existing_obj) : null;
		if (!existing_obj) {
			this.workflowBlockData.is_error = false;
			const tempBlockID = this.workflowBlockData?.guid;
			const statusIndex = this.options?.status?.findIndex((s) => s.guid === tempBlockID);
			const descBlockElement = document.querySelector("#blockDesc" + tempBlockID);
			if (statusIndex === -1) {
				const obj = {
					name: this.workflowBlockData.status,
					state: this.workflowBlockData.state.name,
					state_id: this.workflowBlockData?.state?.id,
					guid: tempBlockID,
					color: this.workflowBlockData?.color,
					background_color: this.workflowBlockData?.background_color,
					id: tempBlockID,
				};
				// obj["id"] = this.workflowBlockData.id;
				if (this.options?.status) {
					this.options.status.push(obj);
				} else {
					this.options.status = [];
					this.options.status.push(obj);
				}
				// checking if status been updated
				if (this.workflowBlockData.is_dirty && !this.statusUpdateList.includes(this.workflowBlockData.guid)) {
					this.statusUpdateList.push(this.workflowBlockData.guid);
				}
				this.toggleRightPanel(this.rightPanelName);
				this.workflowBlockData["state_name"] = obj.state;
				this.workflowBlockData["state_id"] = obj.state_id;
				this.workflowDataById[this.workflowBlockData.guid] = this.workflowBlockData;
				if (this.conditions) {
					for (const item of this.conditions) {
						if (item?.key == "status") {
							item.options = this.options.status;
							break;
						}
					}
				}
			} else {
				this.options.status[statusIndex].name = this.workflowBlockData.status;
				if (this.workflowBlockData?.color) {
					this.options.status[statusIndex].color = this.workflowBlockData.color;
				}
				if (this.workflowBlockData?.background_color) {
					this.options.status[statusIndex].background_color = this.workflowBlockData.status.background_color;
				}
				// checking if status is been updated
				if (this.workflowBlockData.is_dirty) {
					if (!this.statusUpdateList.includes(this.workflowBlockData.guid)) {
						this.statusUpdateList.push(this.workflowBlockData.guid);
					}
				}
				this.toggleRightPanel(this.rightPanelName);
			}
			if (typeof this.workflowBlockData.status === "object") {
				descBlockElement.innerHTML = this.workflowBlockData?.status?.name;
			} else {
				descBlockElement.innerHTML = this.workflowBlockData?.status;
			}
			this.wfGuidMap[this.workflowBlockData?.guid] = this.workflowBlockData;
			this.workflowDataById[this.workflowBlockData.guid] = this.workflowBlockData;
			this.workflowBlockData.is_valid = true;
			this.toggleBorder(this.workflowBlockData?.guid, this.workflowBlockData?.is_valid);
		} else {
			this.workflowBlockData.is_error = true;
			this.workflowBlockData.is_valid = false;
			this.toggleBorder(this.workflowBlockData?.guid, this.workflowBlockData?.is_valid);
		}
		this.is_form_submitted = false;
	};

	/**
	 * it will add/remove error border based on is_valid & guid passed
	 * @param guid: ui id for block
	 * @param is_valid: either block is valid or not
	 * @param className
	 */
	toggleBorder = (guid, is_valid, className = "border-danger") => {
		const currentBlockElement = document.querySelector(`[name="blockid"][value="${guid}"]`)?.parentElement;
		if (currentBlockElement) {
			if (currentBlockElement?.classList?.contains(className)) {
				if (is_valid) {
					currentBlockElement?.classList?.remove("border", className);
				}
			} else {
				if (!is_valid) {
					currentBlockElement?.classList?.add("border", className);
				}
			}
		}
	};

	getConditionText(data) {
		//console.log(data, this.macroLabelMap);
		let str = "If ";
		if (data.length > 0) {
			let index = 0;
			for (const item of data) {
				const label = this._translateService.instant(this.macroLabelMap[item?.operand] || "");
				str += `${label} ${item.comparison_operator} ${item?.name}`;
				if (index == 0 && data?.length > 1) {
					str += ` & +${data.length - 1} condition(s)`;
					break;
				}
				index++;
			}
		}
		return str;
	}

	onClose = (key) => {
		const is_valid = this.getActiveForm().valid;
		this.is_form_submitted = false;
		if (!is_valid) {
			this.workflowBlockData.is_valid = false;
		}
		this.toggleBorder(this.workflowBlockData.guid, this.workflowBlockData?.is_valid);
		this.togglePanel(key);
	};

	togglePanel = (key) => {
		this.isMacro = false;
		const sidebar = this._sidebarService.getSidebarRegistry(key);
		sidebar?.toggleOpen();
	};

	getObjectByKeyValue(list: any, key: string, value: any, itemData = null, itemCompareKey = "id") {
		let obj: any;
		for (const listElement of list) {
			let tempValue = listElement[key];
			if (typeof tempValue === "string" && typeof value === "string") {
				tempValue = tempValue.toLowerCase();
				value = value.toLowerCase();
			}
			if (tempValue == value) {
				if (listElement[itemCompareKey] === itemData[itemCompareKey]) {
					continue;
				}
				obj = listElement;
				break;
			}
		}
		return obj;
	}

	saveEmail = (emailForm) => {
		const tempBlockID = this.workflowBlockData?.guid;
		if (emailForm?.form.valid) {
			this.workflowBlockData.is_valid = true;
			this.workflowDataById[tempBlockID] = this.workflowBlockData;
			this.wfGuidMap[tempBlockID] = this.workflowBlockData;
			if (
				this.workflowBlockData?.to &&
				this.workflowBlockData.to?.length > 0 &&
				![
					WORKFLOW_TEMPLATE_TYPE.CONFIG_APPROVAL_NOTIFICATION,
					WORKFLOW_TEMPLATE_TYPE.CONFIG_REVIEW_NOTIFICATION,
				].includes(this.workflowBlockData?.templateType)
			) {
				const descBlockElement = document.querySelector("#blockDesc" + tempBlockID);
				descBlockElement.innerHTML = this.getSmsEmailToText(this.workflowBlockData?.to, EMAIL_SELECTOR_LIST);
			}
			this.toggleBorder(tempBlockID, true);
			this.toggleRightPanel(this.rightPanelName);
		} else {
			this.workflowBlockData.is_valid = false;
			this.toggleBorder(tempBlockID, false);
		}
	};

	getSmsEmailToText = (to = [], selector) => {
		let tempSmsText = "";
		for (const smsselectorlistElement of selector) {
			if (to.includes(smsselectorlistElement.macro)) {
				tempSmsText += smsselectorlistElement?.name + ", ";
			}
		}
		return tempSmsText.substring(0, tempSmsText?.length - 2);
	};

	saveSms = (smsForm) => {
		const tempBlockID = this.workflowBlockData?.guid;
		if (smsForm?.form.valid) {
			this.workflowBlockData.is_valid = true;
			this.workflowDataById[tempBlockID] = this.workflowBlockData;
			this.wfGuidMap[tempBlockID] = this.workflowBlockData;
			if (this.workflowBlockData?.to && this.workflowBlockData.to?.length > 0) {
				const descBlockElement = document.querySelector("#blockDesc" + tempBlockID);
				descBlockElement.innerHTML = this.getSmsEmailToText(this.workflowBlockData?.to, SMS_SELECTOR_LIST);
			}
			this.toggleBorder(tempBlockID, true);
			this.toggleRightPanel(this.rightPanelName);
		} else {
			this.workflowBlockData.is_valid = true;
			this.toggleBorder(tempBlockID, false);
		}
	};

	onConditionsChange($event: any) {
		this.workflowBlockData.condition = $event;
		this.workflowDataById[this.selectedBlockId] = this.workflowBlockData;
		this.wfGuidMap[this.selectedBlockId] = this.workflowBlockData;
	}

	saveConditions = (compRef) => {
		const result = [];
		for (const dic of compRef?.result) {
			const condition_s = {
				operand: dic["operand"],
				comparison_operator: dic["comparison_operator"],
				value: dic["value"],
			};
			const condition_str = JSON.stringify(condition_s);
			const compare = result.includes(condition_str);
			if (compare) {
				this.duplicate = true;
				break;
			} else {
				result.push(condition_str);
				this.duplicate = false;
			}
		}

		this.wf_conditions_error_message = compRef?.validateWfCondition();
		this.wfPropertiesForm.onSubmit(undefined);
		if (this.wf_conditions_error_message == "" && !this.duplicate) {
			this.workflowBlockData.is_valid = true;
			this.isWorkflowConditionSelected = true;
			this.workflowDataById[this.workflowBlockData.guid] = this.workflowBlockData;
			this.wfGuidMap[this.workflowBlockData.guid] = this.workflowBlockData;
			const descElem = document.querySelector("#blockDesc" + this.workflowBlockData.guid);
			descElem.innerHTML = this.getConditionText(this.workflowBlockData.condition);
			descElem.classList.add("text-center");
			this.toggleBorder(this.workflowBlockData.guid, true);
			this.toggleRightPanel(this.rightPanelName);
		} else {
			this.isWorkflowConditionSelected = false;
			this.workflowBlockData.is_valid = false;
			this.toggleBorder(this.workflowBlockData.guid, false);
		}
	};

	onMacroSelected = (data: any) => {
		if (this.workflowBlockData[this.fieldFocus]) {
			this.workflowBlockData[this.fieldFocus] += "{{" + (data?.if_key || data?.map_key) + "}}";
		} else {
			this.workflowBlockData[this.fieldFocus] = "{{" + (data?.if_key || data?.map_key) + "}}";
		}
	};

	onFieldFocus = (field: string) => {
		this.fieldFocus = field;
	};

	checkForTargetBlockClassList = (target) => {
		let tempElement = null;
		const blockClassList = [
			"blockelem",
			"blockleft",
			"blockyinfo",
			"ico-actions",
			"blockyname",
			"ico-conditions",
			"ico-event",
		];
		for (const str of blockClassList) {
			if (target.classList.contains(str)) {
				if (str === "blockleft" || str === "blockyinfo") {
					tempElement = target.parentNode;
				} else if (["ico-actions", "blockyname", "ico-conditions", "ico-event"].includes(str)) {
					tempElement = target.parentNode.parentNode;
				} else {
					tempElement = target;
				}
				break;
			}
		}
		return tempElement;
	};

	onBlockClick = (event: any) => {
		event.stopImmediatePropagation();
		this.is_form_submitted = false;
		if (event && event.target) {
			this.currentSelectedOrDragElement = event?.target;
			const targetElement = this.checkForTargetBlockClassList(event.target);
			if (targetElement != null) {
				this.workflowBlockData = {};
				const blockElementRefType = targetElement.querySelector(".blockelemtype");
				//console.log(blockElementRefType?.value);
				if (
					[WORKFLOW_BLOCK_TYPE.CONDITION_FAILED, WORKFLOW_BLOCK_TYPE.CONDITION_SUCCESS].includes(
						blockElementRefType?.value
					)
				) {
					return;
				}
				const blockID = targetElement.querySelector(".blockid")?.value;
				//console.log(blockID);
				let data = {};
				if (this.wfGuidMap[blockID]) {
					data = this.wfGuidMap[blockID];
				} else {
					const tempData = blockElementRefType.getAttribute("data-extra");
					if (tempData) {
						data = typeof tempData === "string" ? JSON.parse(tempData) : tempData;
					} else if (
						[WORKFLOW_BLOCK_TYPE.APPROVAL_ACCEPTED, WORKFLOW_BLOCK_TYPE.APPROVAL_REJECTED].includes(
							parseInt(blockElementRefType?.value)
						)
					) {
						data = {
							message_body:
								parseInt(blockElementRefType?.value) === WORKFLOW_BLOCK_TYPE.APPROVAL_ACCEPTED
									? this.options?.approval_email_default_template?.accepted_template?.body
									: this.options?.approval_email_default_template?.rejection_template?.body,
							subject:
								parseInt(blockElementRefType?.value) === WORKFLOW_BLOCK_TYPE.APPROVAL_ACCEPTED
									? this.options?.approval_email_default_template?.accepted_template?.subject
									: this.options?.approval_email_default_template?.rejection_template?.subject,
							to: this.options?.approval_email_default_template?.to,
							cc: this.options?.approval_email_default_template?.cc,
							guid: blockID,
							templateType: WORKFLOW_TEMPLATE_TYPE.CONFIG_APPROVAL_NOTIFICATION,
							type: parseInt(blockElementRefType?.value),
						};
					} else if (
						[WORKFLOW_BLOCK_TYPE.REVIEW_ACCEPTED, WORKFLOW_BLOCK_TYPE.REVIEW_REJECTED].includes(
							parseInt(blockElementRefType?.value)
						)
					) {
						data = {
							message_body:
								parseInt(blockElementRefType?.value) === WORKFLOW_BLOCK_TYPE.REVIEW_ACCEPTED
									? this.options?.review_email_default_template?.accepted_template?.body
									: this.options?.review_email_default_template?.rejection_template?.body,
							subject:
								parseInt(blockElementRefType?.value) === WORKFLOW_BLOCK_TYPE.REVIEW_ACCEPTED
									? this.options?.review_email_default_template?.accepted_template?.subject
									: this.options?.review_email_default_template?.rejection_template?.subject,
							to: this.options?.review_email_default_template?.to,
							cc: this.options?.review_email_default_template?.cc,
							guid: blockID,
							templateType: WORKFLOW_TEMPLATE_TYPE.CONFIG_REVIEW_NOTIFICATION,
							type: parseInt(blockElementRefType?.value),
						};
					} else {
						data = null;
					}
				}
				if (data && !data["guid"]) {
					data["guid"] = blockID;
				}
				if (blockID && data) {
					this.workflowBlockData = _deepCopyJson(
						this.workflowDataById[blockID] ? this.workflowDataById[blockID] : data
					);
					if (
						this.workflowBlockData?.type === 1 &&
						!this.workflowBlockData.hasOwnProperty("transition_states")
					) {
						this.workflowBlockData.transition_states =
							this.state_transition_state_map[blockID]?.transition_states?.map((st) => st.guid) || [];
					}
					// if (!this.workflowBlockData.hasOwnProperty("user_transition_states")) {
					// 	this.workflowBlockData.user_transition_states = [];
					// }
					this.setBlockSliderTitle(this.workflowBlockData?.type);
					this.toggleRightPanel(this.rightPanelName);
				}
			} else if (
				event.target?.classList.contains("fa-trash-alt") ||
				event.target?.classList.contains("fa-trash-can")
			) {
				const tempElement = event.target?.parentNode?.parentNode;
				const id = tempElement?.querySelector(".blockid")?.value;
				Swal.fire({
					title: this._translateService.instant("Message.msg_delete_title"),
					text: this._translateService.instant("Message.msg_delete_wf_block_text"),
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: this._translateService.instant("Message.msg_delete_confirm"),
					cancelButtonText: this._translateService.instant("Message.msg_delete_cancel"),
					customClass: {
						confirmButton: "btn btn-primary",
						cancelButton: "btn btn-danger ml-1",
					},
				}).then((result) => {
					if (result.value) {
						const flowy_output = this.output();
						if (
							this.checkForChildForAssignedStatus(
								id,
								flowy_output?.flow || [],
								this.options?.assigned_status || []
							)
						) {
							this._toastr.error(
								this._translateService.instant("Message.msg_status_in_use_delete", {
									process: this.module_map_config[this.workflowData?.module_id],
								})
							);
						} else {
							this.deleted_block.push(id);
							this.deleteBlock(id, flowy_output);
							flowy.deleteBlock(id);
							this.output();
						}
					}
				});
			}
		}
	};

	checkForChildForAssignedStatus = (id: string, flowy_data: any[], assigned_status: string[]) => {
		if (assigned_status.includes(id)) {
			return true;
		} else {
			const tempFlow = flowy_data?.filter((d: any) => d.flow_id == id);
			if (tempFlow && tempFlow?.length > 0) {
				const tempFlowData = tempFlow[0];
				const getChildBlockList = flowy_data?.filter((c: any) => c.parent == tempFlowData?.flow_id);
				if (getChildBlockList?.length > 0) {
					for (const childBlockListElement of getChildBlockList) {
						if (assigned_status.includes(childBlockListElement?.flow_id)) {
							return true;
						} else if (
							this.checkForChildForAssignedStatus(
								childBlockListElement?.flow_id,
								flowy_data,
								assigned_status
							)
						) {
							return true;
						}
					}
					return false;
				} else {
					return false;
				}
			} else {
				return false;
			}
		}
	};

	deleteBlock = (id, flowy_data) => {
		const tempFlow = flowy_data.flow.filter((d) => d.flow_id == id);
		// checking if give id is part of current flow list or not
		if (tempFlow && tempFlow?.length > 0) {
			const tempFlowData = tempFlow[0];
			const getChildBlockList = flowy_data?.flow?.filter((c) => c.parent == tempFlowData?.flow_id);
			// checking for if type is state so we can update the status
			if (tempFlowData?.type == WORKFLOW_BLOCK_TYPE.STATE) {
				this.updateOptionStatusOnBlockDelete(id, flowy_data);
			}
			if (getChildBlockList?.length > 0) {
				for (const childBlockListElement of getChildBlockList) {
					this.deleteBlock(childBlockListElement?.flow_id, flowy_data);
				}
			}
		}
	};

	updateOptionStatusOnBlockDelete = (id, flowy_data) => {
		if (id) {
			const index = this.options.status.findIndex((obj) => obj.guid == id);
			if (index != -1) {
				const selectedStatus = this.options.status[index];
				if (selectedStatus?.id) {
					selectedStatus["is_deleted"] = true;
				} else {
					this.options.status.splice(index, 1);
				}
				const selectedUpdateStatus = this.statusUpdateList.findIndex((x) => x == id);
				if (selectedUpdateStatus != -1) {
					this.statusUpdateList.splice(selectedUpdateStatus, 1);
				}
				const tempStatus = _deepCopyJson(this.options.status);
				const blocksGuidParentMap = Object.assign({}, ...flowy_data.blockarr.map((x) => ({ [x.id]: x })));
				for (const status of tempStatus) {
					status.parent = blocksGuidParentMap[status.guid]?.parent;
				}
				const children = tempStatus.filter((i) => i.parent === id);
				if (children?.length > 0) {
					for (const child of children) {
						this.updateOptionStatusOnBlockDelete(child.guid, flowy_data);
					}
				}
			}
		}
	};

	onSetValueChange($event: any) {
		this.workflowBlockData.update = $event;
		this.workflowDataById[this.selectedBlockId] = this.workflowBlockData;
		this.wfGuidMap[this.selectedBlockId] = this.workflowBlockData;
	}

	addTagPromise(name) {
		return new Promise((resolve) => {
			resolve({ name: name, tag: true, macro: name });
		});
	}

	/**
	 * @method searchStatus
	 * @param keyword$: it will be observable of string
	 * @return []
	 */
	searchStatus: OperatorFunction<string, readonly any[]> = (keyword$: Observable<string>) => {
		return keyword$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			switchMap((term) => {
				if (term) {
					this.workflowBlockData["is_existing_status"] = false;
					this.workflowBlockData["is_dirty"] = true;
					return this._wfService.searchStatus(this.workflowBlockData.state.id, term).pipe(
						catchError((err) => {
							return EMPTY;
						})
					);
				} else {
					return EMPTY;
				}
			})
		);
	};

	onSearchStatusSelect = (event) => {
		if (typeof event === "object") {
			const id = event?.item?.id;
			this.workflowBlockData["id"] = id;
			this.workflowBlockData["status"] = event?.item?.name;
			this.workflowBlockData["is_existing_status"] = true;
			this.workflowBlockData["is_dirty"] = false;
		}
	};

	updateElementId = (element, oldId, newId) => {
		const eleRef = element.querySelector(oldId);
		eleRef.id = newId;
	};

	statusResultFormatter = (item) => {
		if (typeof item === "string") {
			return item;
		} else {
			return item.name;
		}
	};

	onStatusSelectAction = (evt) => {
		// console.log(evt);
	};

	/**
	 * @method handleAttachable: Function will check if a block is attachable to target block or not
	 * @param blockArray: current list of blocks
	 * @param blockData: current block data
	 * @param itemType: type of item
	 */
	handleAttachable = (blockArray: any[], blockData: any, itemType, draggedBlockData): boolean => {
		if (itemType && typeof itemType === "string") {
			itemType = parseInt(itemType);
		}
		let isAttachable = true;
		const children = this.getBlockChildren(blockData?.guid, blockArray);

		if (typeof draggedBlockData === "string") {
			try {
				draggedBlockData = JSON.parse(draggedBlockData);
			} catch (e) {}
		}
		switch (itemType) {
			case WORKFLOW_BLOCK_TYPE.ACTION:
				isAttachable = this.handleActionAttachable(draggedBlockData, children, blockData);
				break;
			case WORKFLOW_BLOCK_TYPE.STATE:
				isAttachable = this.handleStateAttachable(draggedBlockData, children, blockData);
				break;
			case WORKFLOW_BLOCK_TYPE.CONDITION:
				isAttachable = this.handleConditionAttachable(draggedBlockData, children, blockData);
				break;
			case WORKFLOW_BLOCK_TYPE.CONDITION_SUCCESS:
			case WORKFLOW_BLOCK_TYPE.CONDITION_FAILED:
			case WORKFLOW_BLOCK_TYPE.APPROVAL_ACCEPTED:
			case WORKFLOW_BLOCK_TYPE.APPROVAL_REJECTED:
			case WORKFLOW_BLOCK_TYPE.REVIEW_ACCEPTED:
			case WORKFLOW_BLOCK_TYPE.REVIEW_REJECTED:
				isAttachable = false;
				break;
		}
		return isAttachable;
	};

	isChildrenHasItem = (children: any[], itemType): boolean => {
		const filteredChildren = children.filter((c: any) => c?.type === itemType);
		return filteredChildren?.length > 0;
	};

	handleStateAttachable = (blockData: any, children: any[], toBlockData): boolean => {
		if (toBlockData && blockData) {
			// restricting state to be added to immediate child of success/failed block of condition item
			if (toBlockData?.type === WORKFLOW_BLOCK_TYPE.CONDITION) {
				return false;
			}

			// restricting more branch on approval state
			if (
				toBlockData?.type === WORKFLOW_BLOCK_TYPE.STATE &&
				toBlockData?.templateType === WORKFLOW_TEMPLATE_TYPE.ASK_FOR_APPROVAL
			) {
				return false;
			}
			// restricting more branch on review state
			if (
				toBlockData?.type === WORKFLOW_BLOCK_TYPE.STATE &&
				toBlockData?.templateType === WORKFLOW_TEMPLATE_TYPE.ASK_FOR_REVIEW
			) {
				return false;
			}

			// restricting block drag for (is_exit)is_end block
			if (toBlockData?.is_end) {
				return false;
			}

			if (toBlockData.hasOwnProperty("is_deattachable") && !toBlockData.is_deattachable) {
				return false;
			}

			// restricting branch to be created if action already have child
			if (
				toBlockData?.type === WORKFLOW_BLOCK_TYPE.ACTION &&
				children.length > 0 &&
				this.isChildrenHasItem(children, WORKFLOW_BLOCK_TYPE.ACTION)
			) {
				// refer to action
				blockData.is_valid = false;
				this.toggleBorder(blockData?.guid, false);
			}
			if (toBlockData?.type === WORKFLOW_BLOCK_TYPE.STATE) {
				const allowed_state = ![47, 53, 66].includes(this.workflowData?.module_id)
					? this.allowed_state_map[toBlockData?.state?.id]
					: this.change_allowed_state_map[toBlockData?.state?.id];
				if (allowed_state && allowed_state?.length > 0 && !allowed_state.includes(blockData?.state?.id)) {
					return false;
				}
			}

			const blockIndex = this.errorBlockMap.findIndex((x) => x === blockData?.guid);
			if (blockIndex > 0) {
				this.errorBlockMap.slice(blockIndex, 1);
			}
		}
		return true;
	};

	handleActionAttachable = (blockData: any, children: any[], toBlockData): boolean => {
		if (toBlockData?.type === WORKFLOW_BLOCK_TYPE.CONDITION) {
			return false;
		}

		// restricting more branch on approval state
		if (
			toBlockData?.type === WORKFLOW_BLOCK_TYPE.STATE &&
			toBlockData?.templateType === WORKFLOW_TEMPLATE_TYPE.ASK_FOR_APPROVAL
		) {
			return false;
		}
		// restricting more branch on review state
		if (
			toBlockData?.type === WORKFLOW_BLOCK_TYPE.STATE &&
			toBlockData?.templateType === WORKFLOW_TEMPLATE_TYPE.ASK_FOR_REVIEW
		) {
			return false;
		}
		// // restricting sibling for action, if dragged on state
		// if (toBlockData?.type === WORKFLOW_BLOCK_TYPE.STATE && children.length > 0) {
		// 	return false;
		// }

		// restricting more item after transfer state
		if (
			toBlockData?.type === WORKFLOW_BLOCK_TYPE.ACTION &&
			toBlockData?.templateType === WORKFLOW_TEMPLATE_TYPE.SET_STATUS
		) {
			return false;
		}

		// restricting block drag for (is_exit)is_end block
		if (toBlockData?.is_end) {
			return false;
		}

		if (blockData.hasOwnProperty("is_deattachable") && !blockData.is_deattachable) {
			return false;
		}

		// restricting branch to be created if action already have child & its action
		if (
			toBlockData?.type === WORKFLOW_BLOCK_TYPE.ACTION &&
			children.length > 0 &&
			this.isChildrenHasItem(children, WORKFLOW_BLOCK_TYPE.ACTION)
		) {
			// allowed but need to show error on block
			blockData.is_valid = false;
			this.toggleBorder(blockData?.guid, false);
			this.errorBlockMap.push(blockData?.guid);
			return true;
		}
		const blockIndex = this.errorBlockMap.findIndex((x) => x === blockData?.guid);
		if (blockIndex > 0) {
			this.errorBlockMap.slice(blockIndex, 1);
		}
		return true;
	};

	handleConditionAttachable = (blockData: any, children: any[], toBlockData): boolean => {
		const templateType = blockData?.templateType;
		if (toBlockData?.templateType === WORKFLOW_TEMPLATE_TYPE.SET_STATUS) {
			return false;
		}

		if (toBlockData?.type === WORKFLOW_BLOCK_TYPE.CONDITION) {
			return false;
		}

		// restricting more branch on approval state
		if (
			toBlockData?.type === WORKFLOW_BLOCK_TYPE.STATE &&
			toBlockData?.templateType === WORKFLOW_TEMPLATE_TYPE.ASK_FOR_APPROVAL
		) {
			return false;
		}

		// restricting more branch on review state
		if (
			toBlockData?.type === WORKFLOW_BLOCK_TYPE.STATE &&
			toBlockData?.templateType === WORKFLOW_TEMPLATE_TYPE.ASK_FOR_REVIEW
		) {
			return false;
		}

		// restricting block drag for (is_exit)is_end block
		if (toBlockData?.is_end) {
			return false;
		}

		const blockIndex = this.errorBlockMap.findIndex((x) => x === blockData?.guid);
		if (blockIndex > 0) {
			this.errorBlockMap.slice(blockIndex, 1);
		}
		// restricting branch to be created if action already have child
		if (
			toBlockData?.type === WORKFLOW_BLOCK_TYPE.ACTION &&
			children.length > 0 &&
			this.isChildrenHasItem(children, WORKFLOW_BLOCK_TYPE.ACTION)
		) {
			blockData.is_valid = false;
			this.toggleBorder(blockData?.guid, false);
			this.errorBlockMap.push(blockData?.guid);
			return true;
		}
		return true;
	};

	/**
	 * Function will return immediate children of block & their type
	 * @param guid: guid for block
	 * @param blockArray: current block array from lib
	 * return: {children: [{guid: string, itemType: number}]}
	 */
	getBlockChildren = (guid: string, blockArray: any[]): any => {
		const children = blockArray
			.filter((obj) => obj.parent === guid)
			.map((value) => ({ guid: value?.guid, itemType: value?.type }));
		return children;
	};

	onStatusExitCheck = (is_checked) => {
		this.wfGuidMap[this.workflowBlockData.guid]["is_end"] = is_checked;
	};

	onStatusDestroyCheck = (is_destroyed) => {
		this.wfGuidMap[this.workflowBlockData.guid]["is_destroy"] = is_destroyed;
	};

	onStatusEscalatedCheck = (is_checked) => {
		this.wfGuidMap[this.workflowBlockData.guid]["is_escalated"] = is_checked;
	};

	checkIsExitAllowed = (guid) => {
		if (
			this.workflowBlockData.type === WORKFLOW_BLOCK_TYPE.STATE &&
			(this.workflowBlockData.state_id === 5 || this.workflowBlockData?.state?.id === 5) &&
			!this.workflowBlockData?.is_pir
		) {
			// const wfData = this.output(false);
			// const flow = wfData?.flow;
			// if (flow?.filter((d) => d.parent === this.workflowBlockData.guid)?.length === 0) {
			// 	return true;
			// }
			return true;
		}
		return false;
	};

	checkIsDestroyAllowed = (guid) => {
		if (
			this.workflowBlockData.type === WORKFLOW_BLOCK_TYPE.STATE &&
			(this.workflowBlockData.state_id === 5 || this.workflowBlockData?.state?.id === 5) &&
			this.IS_DESTROY_ENABLED_MODULE.includes(this.workflowData.module_id)
		) {
			const wfData = this.output(false);
			const flow = wfData?.flow;
			const is_destroy = flow?.filter((d) => d.data?.is_destroy);
			if (is_destroy?.length === 0 || is_destroy[0]?.flow_id === guid) {
				return true;
			}
		}
		return false;
	};

	/**
	 * @method checkIsPIRAllowed: it will check if PIR feature will be visible or not
	 * @param guid: unique id which been set to block
	 */
	checkIsPIRAllowed = (guid) => {
		if (
			this.workflowBlockData.type === WORKFLOW_BLOCK_TYPE.STATE &&
			(this.workflowBlockData.state_id === 5 || this.workflowBlockData?.state?.id === 5) &&
			this.PIR_ENABLED_MODULE.includes(this.workflowData?.module_id) &&
			!this.workflowBlockData.is_end
		) {
			const wfData = this.output(false);
			const flow = wfData?.flow;
			const pir_block = flow?.filter((d) => d.data?.is_pir);
			if (pir_block?.length === 0 || pir_block[0]?.flow_id === guid) {
				return true;
			}
		}
		return false;
	};

	checkIsEscalatedAllowed = (guid) => {
		if (
			this.workflowBlockData.type === WORKFLOW_BLOCK_TYPE.STATE &&
			(this.workflowBlockData.state_id || this.workflowBlockData?.state?.id) === 2 &&
			this.ESCALATION_MODULES.includes(this.workflowData?.module_id)
		) {
			return true;
		}
		return false;
	};

	parseStateData = (args_data) => {
		const data = _deepCopyJson(args_data);
		const state_map = {};
		const flow_data_map = data.reduce((map, obj) => {
			map[obj.flow_id] = obj;
			return map;
		}, {});
		const state_list = data.filter((stat) => [1, "1"].includes(stat.type)).map((s) => s.flow_id);
		for (const state of state_list) {
			let state_data = _deepCopyJson(flow_data_map[state]);
			let state_children = data.filter((d) => d.parent == state).map((s) => s.flow_id);
			let myCurrentState = {
				value: state_data.data.status,
				transition_states: state_data.data?.transition_states || [],
			};
			this.getRecursiveChild(flow_data_map, state_children, myCurrentState, data);
			// removing deleted block status from transistion states
			myCurrentState.transition_states = myCurrentState.transition_states.filter(
				(ts) => !this.deleted_block.includes(ts)
			);
			myCurrentState.transition_states = getIntersectionOfArray(state_list, myCurrentState.transition_states);
			state_map[state] = myCurrentState;
		}
		return state_map;
	};

	getRecursiveChild = (flow_data_map, children, state: State, states) => {
		for (const flow of children) {
			const flow_data = flow_data_map[flow];
			if ([1, "1"].includes(flow_data?.type)) {
				if (!state.transition_states.includes(flow_data?.flow_id)) {
					state.transition_states.push(flow_data?.flow_id);
				}
				continue;
			}
			const state_children = states?.filter((d) => d.parent == flow_data.flow_id).map((s) => s.flow_id);
			if (state_children?.length > 0) {
				this.getRecursiveChild(flow_data_map, state_children, state, states);
			}
		}
	};

	setUiTransitionState($event: any, accessKey = "guid") {
		this.workflowBlockData.transition_states = $event.map((s) => s[accessKey]);
	}

	onTeamChange = ($event: Event) => {
		this.workflowBlockData.team = null;
	};

	onPIRStateChange(checked: any) {
		this.wfGuidMap[this.workflowBlockData.guid]["is_pir"] = checked;
	}

	onReviewRequiredChange(checked: any) {
		this.wfGuidMap[this.workflowBlockData.guid]["is_review_required"] = checked;
	}

	checkIsAutoCloseAllowed = (guid: any) => {
		if (
			this.workflowBlockData.type === WORKFLOW_BLOCK_TYPE.STATE &&
			(this.workflowBlockData.state_id === 5 || this.workflowBlockData?.state?.id === 5) &&
			this.AUTO_CLOSURE_MODULES.includes(this.workflowData?.module_id)
		) {
			const wfData = this.output(false);
			const flow = wfData?.flow;
			const is_autoclosure = flow?.filter((d) => d.data?.is_autoclosure);
			if (is_autoclosure?.length === 0 || is_autoclosure[0]?.flow_id === guid) {
				return true;
			}
		}
		return false;
	};

	onAutoClosureCheck = (checked: any) => {
		this.wfGuidMap[this.workflowBlockData.guid]["is_autoclosure"] = checked;
	};

	onStatusAutoSubmit(checked: any) {
		this.wfGuidMap[this.workflowBlockData.guid]["is_auto_submit"] = checked;
	}

	onReportingSubmit(checked: any) {
		this.wfGuidMap[this.workflowBlockData.guid]["is_reporting_manger"] = checked;
	}

	onResolveNotifyChange(checked: any) {
		this.wfGuidMap[this.workflowBlockData.guid]["is_resolve_notify_required"] = checked;
	}

	checkIsAllocationAllowed(guid: any) {
		if (
			this.workflowBlockData.type === WORKFLOW_BLOCK_TYPE.STATE &&
			(this.workflowBlockData.state_id === 2 || this.workflowBlockData?.state?.id === 2) &&
			this.ALLOCATION_DEALLOCATION_MODULES.includes(this.workflowData?.module_id) &&
			!(this.workflowBlockData["is_allocation_rejection"] || this.workflowBlockData["is_deallocation"])
		) {
			const wfData = this.output(false);
			const flow = wfData?.flow;
			const is_allocation = flow?.filter((d) => d.data?.is_allocation);
			if (is_allocation?.length === 0 || is_allocation[0]?.flow_id === guid) {
				return true;
			}
		}
		return false;
	}

	checkIsDeallocationAllowed = (guid: any) => {
		if (
			this.workflowBlockData.type === WORKFLOW_BLOCK_TYPE.STATE &&
			(this.workflowBlockData.state_id === 2 || this.workflowBlockData?.state?.id === 2) &&
			this.ALLOCATION_DEALLOCATION_MODULES.includes(this.workflowData?.module_id) &&
			!(this.workflowBlockData["is_allocation_rejection"] || this.workflowBlockData["is_allocation"])
		) {
			const wfData = this.output(false);
			const flow = wfData?.flow;
			const is_deallocation = flow?.filter((d) => d.data?.is_deallocation);
			if (is_deallocation?.length === 0 || is_deallocation[0]?.flow_id === guid) {
				return true;
			}
		}
		return false;
	};

	onAllocationCheck(checked: any) {
		this.wfGuidMap[this.workflowBlockData.guid]["is_allocation"] = checked;
	}

	onDeallocationCheck(checked: any) {
		this.wfGuidMap[this.workflowBlockData.guid]["is_deallocation"] = checked;
	}

	checkIsAllocationAcceptance(guid: any) {
		if (
			this.workflowBlockData.type === WORKFLOW_BLOCK_TYPE.STATE &&
			(this.workflowBlockData.state_id === 3 || this.workflowBlockData?.state?.id === 3) &&
			this.ALLOCATION_DEALLOCATION_MODULES.includes(this.workflowData?.module_id) &&
			!this.workflowBlockData["is_maintenance"] &&
			!this.workflowBlockData["is_in_stock"]
		) {
			const wfData = this.output(false);
			const flow = wfData?.flow;
			const is_allocation_acceptance = flow?.filter((d) => d.data?.is_allocation_acceptance);
			if (is_allocation_acceptance?.length === 0 || is_allocation_acceptance[0]?.flow_id === guid) {
				return true;
			}
		}
		return false;
	}

	checkIsAllocationRejection = (guid: any) => {
		if (
			this.workflowBlockData.type === WORKFLOW_BLOCK_TYPE.STATE &&
			(this.workflowBlockData.state_id === 2 || this.workflowBlockData?.state?.id === 2) &&
			this.ALLOCATION_DEALLOCATION_MODULES.includes(this.workflowData?.module_id) &&
			!(this.workflowBlockData["is_deallocation"] || this.workflowBlockData["is_allocation"])
		) {
			const wfData = this.output(false);
			const flow = wfData?.flow;
			const is_allocation_rejection = flow?.filter((d) => d.data?.is_allocation_rejection);
			if (is_allocation_rejection?.length === 0 || is_allocation_rejection[0]?.flow_id === guid) {
				return true;
			}
		}
		return false;
	};

	onAllocationAcceptedCheck(checked: any) {
		this.wfGuidMap[this.workflowBlockData.guid]["is_allocation_acceptance"] = checked;
	}

	onAllocationRejectedCheck(checked: any) {
		this.wfGuidMap[this.workflowBlockData.guid]["is_allocation_rejection"] = checked;
	}

	checkIsMaintenance = (guid: any) => {
		if (
			this.workflowBlockData.type === WORKFLOW_BLOCK_TYPE.STATE &&
			(this.workflowBlockData.state_id === 3 || this.workflowBlockData?.state?.id === 3) &&
			this.ASSET_MAINTENACE_MODULES.includes(this.workflowData?.module_id) &&
			!this.workflowBlockData["is_allocation_acceptance"] &&
			!this.workflowBlockData["is_in_stock"]
		) {
			const wfData = this.output(false);
			const flow = wfData?.flow;
			const is_maintenance = flow?.filter((d) => d.data?.is_maintenance);
			if (is_maintenance?.length === 0 || is_maintenance[0]?.flow_id === guid) {
				return true;
			}
		}
		return false;
	};

	onMaintenanceCheck = (checked: any) => {
		this.wfGuidMap[this.workflowBlockData.guid]["is_maintenance"] = checked;
	};

	checkInStock(guid: any) {
		if (
			this.workflowBlockData.type === WORKFLOW_BLOCK_TYPE.STATE &&
			(this.workflowBlockData.state_id === 3 || this.workflowBlockData?.state?.id === 3) &&
			this.ASSET_IN_STOCK_MODULES.includes(this.workflowData?.module_id) &&
			!this.workflowBlockData["is_allocation_acceptance"] &&
			!this.workflowBlockData["is_maintenance"]
		) {
			const wfData = this.output(false);
			const flow = wfData?.flow;
			const is_in_stock = flow?.filter((d) => d.data?.is_in_stock);
			if (is_in_stock?.length === 0 || is_in_stock[0]?.flow_id === guid) {
				return true;
			}
		}
		return false;
	}

	onInStockCheck(checked: any) {
		this.wfGuidMap[this.workflowBlockData.guid]["is_in_stock"] = checked;
	}

	onResolveByEventCheck = (checked: any) => {
		this.wfGuidMap[this.workflowBlockData.guid]["is_resolve_by_event"] = checked;
	};

	checkIsResolveByEventAllowed = (guid: any) => {
		const is_permission_granted = this._permissionService.isGranted(["events"], ["add", "edit"]);
		if (
			is_permission_granted &&
			this.workflowBlockData.type === WORKFLOW_BLOCK_TYPE.STATE &&
			(this.workflowBlockData.state_id === 4 || this.workflowBlockData?.state?.id === 4) &&
			this.RESOLVED_BY_EVENT_MODULES.includes(this.workflowData?.module_id)
		) {
			const wfData = this.output(false);
			const flow = wfData?.flow;
			const is_resolve_by_event = flow?.filter((d) => d.data?.is_resolve_by_event);
			if (is_resolve_by_event?.length === 0 || is_resolve_by_event[0]?.flow_id === guid) {
				return true;
			}
		}
		return false;
	};

	checkIsResponseTimeAllowed(guid: any) {
		const response_time_state = [1];
		if (
			this.workflowBlockData.type === WORKFLOW_BLOCK_TYPE.STATE &&
			(response_time_state.includes(this.workflowBlockData.state_id) ||
				response_time_state.includes(this.workflowBlockData?.state?.id)) &&
			this.RESPONSE_TIME_MODULES.includes(this.workflowData?.module_id)
		) {
			const wfData = this.output(false);
			const flow = wfData?.flow;
			const is_response_time = flow?.filter((d) => d.data?.is_response_time);
			if (is_response_time?.length === 0 || is_response_time[0]?.flow_id === guid) {
				return true;
			}
		}
		return false;
	}

	onStatusResponseTimeCheck(checked: any) {
		this.wfGuidMap[this.workflowBlockData.guid]["is_response_time"] = checked;
	}
}
