<div *ngIf="selected.length > 0 && show === true" class="bg-primary selected-data flex-initial table-grid-top-bar">
	<div class="px-1 d-flex align-items-center justify-content-between">
		<div class="d-flex align-items-center">
			<span class="mr-50 bullet bg-white bullet-xs"> </span>
			<span class="text-white font-small-2 text-nowrap">
				{{ selected.length }}&nbsp; {{ "UI.k_selected" | translate }}</span
			>
		</div>
		<div class="d-flex align-items-center">
			<ng-container *ngIf="mselectMenu?.length <= 5">
				<ul class="list-inline text-center mb-0">
					<ng-container *ngFor="let menu of mselectMenu; let i = index">
						<li *ngIf="!menu?.min_rows" class="list-inline-item m-0">
							<button
								*ngIf="permissions[menu.permissionKey]"
								class="btn btn-primary bg-darken-2 px-75"
								(click)="menu.listner(menu)"
								tooltipClass="fadeInAnimation"
								[ngbTooltip]="menu.label"
								placement="bottom"
								id="btn{{ menu.key }}"
								attr.data-testid="btn{{ menu.key | titlecase }}"
							>
								<i class="fa-light fa-{{ menu.icon }} fa-sm text-white"></i>&nbsp;
								<span class="font-small-3">{{ menu.label }}</span>
							</button>
						</li>
						<li *ngIf="menu.min_rows" class="list-inline-item m-0">
							<button
								*ngIf="selected.length >= menu.min_rows && permissions[menu.permissionKey]"
								class="btn btn-primary bg-darken-2 px-75"
								(click)="menu.listner(menu)"
								tooltipClass="fadeInAnimation"
								[ngbTooltip]="menu.label"
								placement="bottom"
								id="btn{{ menu.key }}"
								attr.data-testid="btn{{ menu.key | titlecase }}"
							>
								<i class="fa-light fa-{{ menu.icon }} fa-sm text-white"></i>&nbsp;
								<span class="font-small-3">{{ menu.label }}</span>
							</button>
						</li>
					</ng-container>
				</ul>
			</ng-container>
			<ng-container *ngIf="mselectMenu?.length > 5">
				<ul class="list-inline text-center mb-0">
					<ng-container *ngFor="let menu of mselectMenu; let i = index">
						<li class="list-inline-item m-0" *ngIf="i < 4">
							<button
								*ngIf="permissions[menu.permissionKey]"
								class="btn btn-primary bg-darken-2 px-75"
								(click)="menu.listner(menu)"
								tooltipClass="fadeInAnimation"
								[ngbTooltip]="menu.label"
								placement="bottom"
								attr.data-testid="btn{{ menu.key | titlecase }}"
							>
								<i class="fa-light fa-{{ menu.icon }} fa-sm text-white mr-50"></i>
								<span class="font-small-3">{{ menu.label }}</span>
							</button>
						</li>
					</ng-container>
				</ul>
			</ng-container>
			<ng-container *ngIf="mselectMenu?.length > 5">
				<div ngbDropdown>
					<button
						ngbDropdownToggle
						class="btn btn-primary bg-darken-2"
						type="button"
						id="dropdownMenuButton100"
						[attr.data-testid]="'dropdownMenuButton100'"
					>
						{{ "UI.k_more" | translate }}
					</button>
					<div
						ngbDropdownMenu
						aria-labelledby="dropdownMenuButton100"
						class="dns-grid-header-dropdown"
						style="width: 250px"
					>
						<ng-scrollbar class="custom-scrollbar section-scrollbar">
							<ng-container *ngFor="let menu of mselectMenu; let i = index">
								<ng-container *ngIf="i >= 4">
									<a
										ngbDropdownItem
										href="javascript:void(0)"
										*ngIf="permissions[menu.permissionKey]"
										(click)="menu.listner(menu)"
										id="btn{{ menu.key }}"
										attr.data-testid="btn{{ menu.key | titlecase }}"
									>
										<i class="fa-light fa-{{ menu.icon }} fa-sm mr-50"></i> {{ menu.label }}</a
									>
								</ng-container>
							</ng-container>
						</ng-scrollbar>
					</div>
				</div>
			</ng-container>
		</div>
		<div (click)="toggle()" class="btn btn-primary btn-icon">
			<i class="fa-light fa-xmark"></i>
		</div>
	</div>
</div>
<div class="card flex-1" *ngIf="(rows?.length > 0 && showData) || filterData">
	<ngx-datatable
		#dataTable
		[rows]="rows"
		[headerHeight]="headerHeight"
		[rowHeight]="'auto'"
		[limit]="limit"
		[footerHeight]="hideFooter ? 0 : footerHeight"
		[columnMode]="columnMode"
		[externalPaging]="externalPaging"
		[externalSorting]="externalSorting"
		(sort)="onSort($event)"
		(setlimit)="setLimit($event)"
		[count]="count"
		[offset]="offset"
		[loadingIndicator]="loadingIndicator"
		(page)="setPage($event)"
		class="bootstrap core-bootstrap"
		[selected]="selected"
		[selectionType]="selectionType"
		(select)="onSelect($event)"
		[rowClass]="getRowClass"
		[ngClass]="(rows?.length > 0 && showData) || !filterData ? '' : 'hide-footer'"
		[hideSelectAllCheckbox]="hideSelectAllCheckbox"
		[selectBoxColumnLabel]="selectBoxColumnLabel"
		[scrollbarH]="true"
		[virtualization]="false"
	>
		<!-- [scrollbarH]="true"
	[virtualization]="false" -->
		<!--[rowHeight]="50"-->
		<!--style="height:560px;overflow-y:visible"-->
		<ngx-datatable-column
			*ngIf="selectionType == 'checkbox'"
			[sortable]="false"
			[canAutoResize]="true"
			[draggable]="false"
			[resizeable]="false"
			[width]="hideSelectAllCheckbox ? 150 : 40"
			[maxWidth]="hideSelectAllCheckbox ? 150 : 40"
			[minWidth]="32"
		>
			<ng-template
				ngx-datatable-header-template
				let-value="value"
				let-allRowsSelected="allRowsSelected"
				let-selectFn="selectFn"
			>
				<div *ngIf="!hideSelectAllCheckbox">
					<div class="custom-control custom-checkbox" attr.data-testid="cb__multiselect{{model}}">
						<input
							type="checkbox"
							class="custom-control-input"
							[checked]="allRowsSelected"
							(change)="selectFn(!allRowsSelected)"
							id="cb__multiselect{{model}}"
						/>
						<label class="custom-control-label" for="cb__multiselect{{model}}"></label>
					</div>
				</div>
				<div *ngIf="hideSelectAllCheckbox">
					<span class="datatable-header-cell-wrapper">
						<span class="datatable-header-cell-label">
							{{ selectBoxColumnLabel }}
						</span>
					</span>
				</div>
			</ng-template>
			<ng-template
				ngx-datatable-cell-template
				let-rowIndex="rowIndex"
				let-value="value"
				let-isSelected="isSelected"
				let-onCheckboxChangeFn="onCheckboxChangeFn"
			>
				<div class="custom-control custom-checkbox" attr.data-testid="multiSel_{{ rowIndex }}{{model}}">
					<input
						type="checkbox"
						class="custom-control-input"
						[checked]="isSelected"
						(change)="onCheckboxChangeFn($event)"
						id="cb_row{{ rowIndex }}{{model}}"
					/>
					<label class="custom-control-label" for="cb_row{{ rowIndex }}{{model}}"></label>
				</div>
			</ng-template>
		</ngx-datatable-column>
		<ngx-datatable-column
			[name]="col.label"
			*ngFor="let col of columns"
			[sortable]="
				col.hasOwnProperty('actions') && !col.hasOwnProperty('showActionSort')
					? false
					: col.hasOwnProperty('sortable')
					? col.sortable
					: true
			"
			[resizeable]="col.resizable ? col.resizable : true"
			[canAutoResize]="col.canAutoResize ? col.canAutoResize : true"
			[draggable]="col.draggable"
			[minWidth]="col.width"
			[prop]="col?.sortKey ? col?.sortKey : col.key"
		>
			<ng-template
				ngx-datatable-header-template
				id="{{ col?.label }}"
				let-value="value"
				let-allRowsSelected="allRowsSelected"
				let-selectFn="selectFn"
				attr.data-testid="{{ col?.label }}"
			>
				<span class="datatable-header-cell-wrapper">
					<span
						id="tblHeader{{ col?.key }}"
						attr.data-testid="tblHeader{{ col?.key }}"
						class="datatable-header-cell-label draggable"
						[innerHTML]="col?.label"
					>
					</span>
				</span>
			</ng-template>

			<ng-template let-row="row" ngx-datatable-cell-template let-rowIndex="rowIndex">
				<div
					[ngClass]="col?.isCellClickable ? 'btn-link cursor-pointer' : ''"
					(click)="col?.isCellClickable ? onCellActionClick(row, col) : null"
				>
					<!--Custom component on start-->
					<div
						*ngIf="col?.component; else dataTemplate"
						appComponentLoader
						[cl-component]="col?.component"
						[cl-key]="col?.key"
						[cl-subkey]="col?.subkey"
						[cl-url]="col?.url"
						[cl-id]="col?.id"
						[cl-data]="row"
						[cl-rowindex]="rowIndex"
						[cl-target]="col?.target"
						(cl-callback)="col?.callback($event)"
						attr.data-testid="custom_{{ col?.key }}{{ rowIndex }}"
					></div>
					<!--Custom component end -->
					<ng-template #dataTemplate>
						<!--Default text/html text container start-->
						<div
							[innerHTML]="(col.subkey ? row[col.subkey][col.key] : row[col.key]) | safe: 'html'"
							*ngIf="col.type == 'html'"
							dnsReadMore
							[readMore-length]="readMoreLength"
							id="div{{ col?.type + col?.key + rowIndex }}"
							attr.data-testid="div{{ col?.type + col?.key + rowIndex }}"
						></div>
						<!--Default text/html text container ends-->
						<div
							*ngIf="
								(!isEditable || !col.type) &&
								!col.isEditable &&
								col.type !== 'switch' &&
								col.type !== 'html' &&
								col.type !== 'rich_text' &&
								col.type !== 'select' &&
								col.type !== 'tel' &&
								col.type !== 'select-template-status' &&
								col.type !== 'tree_view_select' &&
								col.type !== 'tree_view_parent_select' &&
								col.type !== 'badge_select' &&
								col.type !== 'multi-select' &&
								col.type !== 'tag' &&
								col.type !== 'requester_tag' &&
								col.type !== 'link' &&
								col.type !== 'link_sidebar'
							"
							[ngClass]="{
								'cursor-pointer': col.clickable === true
							}"
						>
							<!-- (click)="onClick(col.clickable, col.listener, rowIndex, row, col)" -->
							<div
								(click)="onClick(col.clickable, col.listener, row, col.key, rowIndex)"
								(dblclick)="
									doEdit((col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex, col)
								"
								*ngIf="!editData[(col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex]"
								class="text-break"
								id="{{ col.key }}_{{ rowIndex }}"
								attr.data-testid="{{ col.key }}_{{ rowIndex }}"
							>
								{{ getTranslatableKey(col.subkey ? row[col.subkey][col.key] : row[col.key]) }}
								<span *ngIf="col?.key_for_next_line">{{
									getTranslatableKey(row[col.key_for_next_line])
								}}</span>
							</div>
							<span
								*ngIf="editData[(col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex]"
							>
								<input
									autofocus
									class="form-control form-control-sm"
									(blur)="
										updateInlineData(
											$event,
											col.key,
											col.subkey,
											row,
											(col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex,
											col,
											TDInputVar
										)
									"
									(keyup)="
										onEnterInlineEdit(
											$event,
											col.key,
											col.subkey,
											row,
											(col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex,
											col,
											TDInputVar
										)
									"
									[minlength]="col?.min_char ? col?.min_char : defaultMinLength"
									[maxlength]="col?.max_char ? col?.max_char : defaultMaxLength"
									required="col?.required ? col?.required: false"
									[class.error]="TDInputVar?.invalid"
									[appWhiteSpaceCheck]="col.subkey ? row[col.subkey][col.key] : row[col.key]"
									#TDInputVar="ngModel"
									[(ngModel)]="col.subkey ? row[col.subkey][col.key] : row[col.key]"
									type="text"
									[value]="col.subkey ? row[col.subkey][col.key] : row[col.key]"
									[pattern]="col?.regex"
									id="txt{{ col.key }}_{{ rowIndex }}"
									attr.data-testid="txt{{ col.key }}_{{ rowIndex }}"
								/>
								<span *ngIf="TDInputVar?.invalid" class="invalid-form">
									<small *ngIf="TDInputVar?.errors?.required" class="form-text text-danger">{{
										"UI.k_field_required" | translate
									}}</small>
									<small class="form-text text-danger" *ngIf="TDInputVar.errors?.pattern">
										{{
											(col?.errorText ? col.errorText : "Error.err_enter_valid_data") | translate
										}}</small
									>
									<small *ngIf="TDInputVar.errors?.whitespace" class="form-text text-danger">{{
										"Error.err_ltspace" | translate
									}}</small>
									<small
										class="form-text text-danger"
										*ngIf="TDInputVar.errors?.minlength || TDInputVar.errors?.maxlength"
									>
										{{
											"Error.err_min_max_length"
												| translate
													: {
															min_char: col?.min_char ? col?.min_char : defaultMinLength,
															max_char: col?.max_char ? col?.max_char : defaultMaxLength
													  }
										}}</small
									>
								</span>
							</span>
						</div>
						<!-- Input type tel start -->
						<div *ngIf="col.type == 'tel'">
							<div
								(click)="onClick(col.clickable, col.listener, row, col.key, rowIndex)"
								(dblclick)="
									doEdit((col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex, col)
								"
								*ngIf="!editData[(col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex]"
								class="text-break"
								id="tel_{{ col.key }}_{{ rowIndex }}"
								attr.data-testid="tel_{{ col.key }}_{{ rowIndex }}"
							>
								{{ col.subkey ? row[col.subkey][col.display_tel_key] : row[col.display_tel_key] }}
							</div>
							<ngx-intl-tel-input
								class="w-100"
								[class.error]="telField?.invalid"
								[cssClass]="'custom form-control w-100'"
								[preferredCountries]="preferredCountries"
								[enableAutoCountrySelect]="true"
								[enablePlaceholder]="true"
								[selectedCountryISO]="CountryISO.UnitedStates"
								[searchCountryFlag]="true"
								[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
								[selectFirstCountry]="true"
								[maxLength]="15"
								[phoneValidation]="true"
								[separateDialCode]="separateDialCode"
								[numberFormat]="PhoneNumberFormat.National"
								(onTouched)="
									updateInlineData(
										$event,
										col.key,
										col.subkey,
										row,
										(col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex,
										col,
										telField
									)
								"
								(keyup)="
									onEnterInlineEdit(
										$event,
										col.key,
										col.subkey,
										row,
										(col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex,
										col,
										telField
									)
								"
								name="txtRegPhone{{ col.key }}_{{ rowIndex }}"
								id="txtRegPhone{{ col.key }}_{{ rowIndex }}"
								attr.data-testid="txtRegPhone{{ col.key }}_{{ rowIndex }}"
								tabindex="4"
								*ngIf="
									isEditable &&
									editData[(col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex]
								"
								[(ngModel)]="col.subkey ? row[col.subkey][col.key] : row[col.key]"
								#telField="ngModel"
								[ngClass]="{
									'is-invalid': telField.errors
								}"
							>
							</ngx-intl-tel-input>
							<span
								*ngIf="
									formCompModel?.invalid &&
									editData[(col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex]
								"
								class="invalid-form"
							>
								<small class="form-text text-danger" *ngIf="formCompModel.errors">{{
									"UI.k_phone_number_validation" | translate
								}}</small>
							</span>
						</div>
						<!-- Input type tel Ends -->

						<!-- Input type link start -->
						<div *ngIf="col.type == 'link'">
							<span
								class="cursor-pointer text-primary"
								(click)="redirectLink(col, row)"
								id="spView_{{ rowIndex }}"
								attr.data-testid="spView_{{ rowIndex }}"
							>
								{{ col.subkey ? row[col.subkey][col.key] : row[col.key] }}
							</span>
						</div>
						<!-- Input type link ends -->

						<!-- Input type link_sidebar start -->
						<div *ngIf="col.type == 'link_sidebar'">
							<span
								class="cursor-pointer text-primary"
								(click)="col.listener(row)"
								id="sidebarView_{{ rowIndex }}"
								attr.data-testid="sidebarView_{{ rowIndex }}"
							>
								{{ col.subkey ? row[col.subkey][col.key] : row[col.key] }}
							</span>
						</div>
						<!-- Input type link_sidebar end -->

						<!-- Input type rich text starts -->
						<div *ngIf="col.type == 'rich_text'">
							<div
								class="text-break"
								[innerHTML]="col.subkey ? row[col.subkey][col.key] : row[col.key]"
								id="rText{{ col.key }}_{{ rowIndex }}"
								attr.data-testid="rText{{ col.key }}_{{ rowIndex }}"
							></div>
						</div>
						<!-- Input type rich text ends -->

						<!-- Input type switch start -->
						<div
							class="custom-control custom-control-primary custom-switch custom-switch-sm"
							*ngIf="
								(col.isEditable === undefined &&
									row[col.isEditable] === undefined &&
									isEditable &&
									col.type == 'switch') ||
								(col.isEditable == true && col.type == 'switch') ||
								(row[col.isEditable] == true && col.type == 'switch')
							"
						>
							<input
								type="checkbox"
								(change)="
									updateInlineData(
										$event,
										col.key,
										col.subkey,
										row,
										(col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex,
										col
									)
								"
								[checked]="col.subkey ? row[col.subkey][col.key] : row[col.key]"
								class="custom-control-input"
								id="customSwitch{{ col?.key + rowIndex }}"
								attr.data-testid="customSwitch{{ col?.key + rowIndex }}"
								#switchView
							/>
							<label
								class="custom-control-label"
								for="customSwitch{{ col?.key + rowIndex }}"
								id="lblCustomSwitch{{ col?.key + rowIndex }}"
								attr.data-testid="lblCustomSwitch{{ col?.key + rowIndex }}"
								[class.text-primary]="switchView.checked"
								[class.text-inactive]="!switchView.checked"
								>{{ switchView.checked ? col?.activeText : col?.inactiveText }}</label
							>
						</div>

						<div
							class="custom-control custom-control-primary custom-switch custom-switch-sm"
							*ngIf="
								(!isEditable && col.type == 'switch' && !col.isEditable) ||
								(col.type == 'switch' && col.isEditable !== true && !row[col.isEditable]) ||
								(col.type == 'switch' && col.isEditable === false && row[col.isEditable] == undefined)
							"
						>
							<input
								type="checkbox"
								disabled="true"
								[checked]="col.subkey ? row[col.subkey][col.key] : row[col.key]"
								class="custom-control-input"
								id="customSwitch{{ col?.key + rowIndex }}"
								attr.data-testid="customSwitch{{ col?.key + rowIndex }}"
								#switchView
							/>
							<label
								class="custom-control-label"
								for="customSwitch{{ col?.key + rowIndex }}"
								id="lblCustomSwitch{{ col?.key + rowIndex }}"
								attr.data-testid="lblCustomSwitch{{ col?.key + rowIndex }}"
								[class.text-primary]="switchView.checked"
								[class.text-inactive]="!switchView.checked"
								>{{ switchView.checked ? col?.activeText : col?.inactiveText }}</label
							>
						</div>
						<!-- Input type switch ends -->
						<!-- Input type select start -->
						<div
							*ngIf="
								(col.isEditable === undefined &&
									row[col.isEditable] === undefined &&
									isEditable &&
									col.type == 'select') ||
								(col.isEditable == true && col.type == 'select') ||
								(row[col.isEditable] == true && col.type == 'select')
							"
						>
							<div
								(dblclick)="
									doEdit(
										(col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex,
										col,
										row, 
										rowIndex
									)
								"
								*ngIf="!editData[(col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex]"
								id="selContainer{{ col.key }}_{{ rowIndex }}"
								attr.data-testid="selContainer{{ col.key }}_{{ rowIndex }}"
								class="cursor-pointer"
							>
								{{
									searchObjectByKeyValue(
										col.optionKey
											? col?.optionSubKey
												? options[col?.optionSubKey][col.optionKey]
												: options[col.optionKey]
											: options[col.key],
										col.optionSearchKey ? col.optionSearchKey : "id",
										col.subkey ? row[col.subkey][col.key] : row[col.key]
									)?.name
								}}
							</div>
							<select
								*ngIf="editData[(col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex]"
								id="sel{{ col.key }}_{{ rowIndex }}"
								attr.data-testid="sel{{ col.key }}_{{ rowIndex }}"
								class="form-control"
								(focusout)="
								disableEditableInput(col, (col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex)"
								(change)="
									updateInlineData(
										$event,
										col.key,
										col.subkey,
										row,
										(col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex,
										col
									)
								"
							>
								<option
									[value]="opt[col.optionSearchKey ? col.optionSearchKey : 'id']"
									*ngFor="
										let opt of col.optionKey
											? col?.optionSubKey
												? options[col?.optionSubKey][col.optionKey]
												: options[col.optionKey]
											: options[col.key]
									"
									[selected]="
										(opt[col.optionSearchKey ? col.optionSearchKey : 'id'] ==
											(col.subkey ? row[col.subkey][col.key] : row[col.key])) ==
										true
									"
								>
									{{ opt[col.optionLabelKey ? col.optionLabelKey : "name"] }}
								</option>
							</select>
						</div>
						<ng-container *ngIf="row[col.isEditable] === false && col.type == 'select'">
							<span class="cursor-not-allowed">
								{{ col.displayKey ? row[col.displayKey] : row[col.key] }}</span
							>
						</ng-container>
						<ng-container *ngIf="row[col.isEditable] !== false && col.type == 'select'">
							<div
								*ngIf="
									(!isEditable && col.type == 'select' && col.isEditable !== true) ||
									(col.type == 'select' &&
										col.isEditable !== true &&
										col.isEditable !== false &&
										row[col.isEditable] === false) ||
									(col.type == 'select' &&
										col.isEditable === false &&
										row[col.isEditable] === undefined)
								"
								id="dependentSelContainer{{ col.key }}_{{ rowIndex }}"
								attr.data-testid="dependentSelContainer{{ col.key }}_{{ rowIndex }}"
								class="cursor-not-allowed"
							>
								{{
									searchObjectByKeyValue(
										col.optionKey
											? col?.optionSubKey
												? options[col?.optionSubKey][col.optionKey]
												: options[col.optionKey]
											: options[col.key],
										col.optionSearchKey ? col.optionSearchKey : "id",
										col.subkey ? row[col.subkey][col.key] : row[col.key]
									)?.name
								}}
							</div>
						</ng-container>

						<!-- Input type select ends -->

						<div
							*ngIf="
								(isEditable && col.type == 'select-template-status') ||
								(col.isEditable && col.type == 'select-template-status')
							"
						>
							<ng-container
								*ngIf="
									searchObjectByKeyValue(
										options[col.key],
										'id',
										col.subkey ? row[col.subkey][col.key]?.id : row[col.key]?.id
									) as rowItem
								"
							>
								<div
									(dblclick)="
										doEdit(
											(col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex,
											col,
											row
										)
									"
									id="newSelectContainer{{ col.key }}_{{ rowIndex }}"
									*ngIf="
										!editData[(col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex]
									"
									id="ngSelectContainer{{ col.key }}_{{ rowIndex }}"
									attr.data-testid="ngSelectContainer{{ col.key }}_{{ rowIndex }}"
									class="badge badge-pill fw-500"
									[style.background-color]="rowItem?.background_color + ' !important'"
									[style.color]="rowItem?.color + ' !important'"
								>
									{{ rowItem?.name | translate }}
								</div>
							</ng-container>
							<div
								class="selct-feature width-200 group-list-feature status-list-picker"
								*ngIf="
									editData[(col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex] &&
									isEditableOption(
										col?.option_map,
										col.subkey ? row[col.subkey][col.key]['id'] : row[col.key]['id']
									)
								"
							>
								<ng-select
									[items]="
										remapOptionData(
											options[col.key],
											col?.option_map,
											col.subkey ? row[col.subkey][col.key]['id'] : row[col.key]['id'],
											rowIndex
										)
									"
									bindValue="col?.optionSearchKey || id"
									id="txtIncidentServiceCatalogue{{ col.key }}_{{ rowIndex }}"
									attr.data-testid="txtIncidentServiceCatalogue{{ col.key }}_{{ rowIndex }}"
									name="txtIncidentServiceCatalogue{{ col.key }}_{{ rowIndex }}"
									[(ngModel)]="col.subkey ? row[col.subkey][col.key].id : row[col.key].id"
									[clearable]="false"
									placeholder="{{ 'UI.k_select_status' | translate }}"
									(change)="
										updateInlineData(
											$event,
											col.key,
											col.subkey,
											row,
											(col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex,
											col,
											rowIndex
										)
									"
									bindLabel="name"
									class="form-control sm-form-control"
									[dropdownPosition]="rowIndex < 3 ? 'bottom' : 'top'"
								>
									<ng-template ng-option-tmp ng-label-tmp let-item="item">
										<ng-container>
											<div class="group-lists">
												<div
													class="custom-squre"
													[ngStyle]="{
														'background-color': item?.color
													}"
												></div>
												<span>{{ item?.name | translate }}</span>
												<div
													class="right-arrow custom-text"
													[ngStyle]="{
														'background-color': item?.background_color,
														color: item?.text_color
													}"
												>
													{{ item?.state | translate }}
												</div>
												<div
													class="right-arrow custom-icon"
													*ngIf="col?.inputConfig && !col?.inputConfig?.isTextMode"
													[ngStyle]="{
														'background-color': item?.background_color,
														color: item?.text_color
													}"
												>
													<i [class]="item?.icon"></i>
												</div>
											</div>
										</ng-container>
									</ng-template>
								</ng-select>
							</div>
						</div>
						<div *ngIf="!isEditable && col.type == 'select-template-status' && !col.isEditable">
							<ng-container>
								<div
									id="newSelectContainer{{ col.key }}_{{ rowIndex }}"
									id="ngSelectContainer{{ col.key }}_{{ rowIndex }}"
									class="badge badge-pill fw-500"
									[style.background-color]="row[col.key]?.background_color + ' !important'"
									[style.color]="row[col.key]?.color + ' !important'"
									attr.data-testid="ngSelectContainer{{ col.key }}_{{ rowIndex }}"
								>
									{{ row[col.key]?.name | translate }}
								</div>
							</ng-container>
						</div>

						<!-- Input type multi-select start -->
						<div *ngIf="col.type == 'multi-select'">
							<div
								class=""
								(dblclick)="
									doEdit((col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex, col)
								"
								*ngIf="!editData[(col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex]"
								id="sel__{{ col.key }}__{{ row.display_id }}"
								attr.data-testid="sel__{{ col.key }}__{{ row.display_id }}"
							>
								{{
									searchMultiObjectByKeyValue(
										options[col?.optionKey ? col?.optionKey : col?.key],
										"id",
										col.subkey ? row[col.subkey][col.key] : row[col.key]
									)
								}}
							</div>
							<div
								*ngIf="editData[(col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex]"
							>
								<ng-select
									[items]="options[col?.optionKey ? col?.optionKey : col?.key]"
									id="selMulti__{{ rowIndex }}"
									name="selMulti__{{ rowIndex }}"
									attr.data-testid="selMulti__{{ rowIndex }}"
									[(ngModel)]="row[col.key]"
									(change)="
										updateInlineData(
											$event,
											col.key,
											col.subkey,
											row,
											(col.subkey ? col.subkey + col.key : col.key) + 'editable' + rowIndex,
											col
										)
									"
									[multiple]="true"
									[searchable]="true"
									[closeOnSelect]="true"
									bindLabel="name"
									bindValue="col?.optionSearchKey || id"
									hideSelected="true"
								>
								</ng-select>
							</div>
						</div>
						<!-- Input type multi-select ends -->

						<!--Input type tree view select allow only child selection-->
						<div
							*ngIf="
								!isEditable && (col.type == 'tree_view_select' || col.type == 'tree_view_parent_select')
							"
						>
							<div id="treeView{{ col.key }}_{{ rowIndex }}" attr.data-testid="treeView{{ col.key }}_{{ rowIndex }}">
								<label class="form-check-label">{{ row[col?.value] }}</label>
							</div>
						</div>
						<ng-container *ngIf="col.type == 'tree_view_select' && isEditable">
							<app-single-tree-dropdown
								class="w-100"
								[items]="row?.dropdown_options[col?.optionKey]"
								[value]="row[col?.key]"
								id="tree_{{ rowIndex }}"
							>
							</app-single-tree-dropdown>
						</ng-container>
						<!--Input type tree view parent select allow only child selection ends-->

						<!--Input type tree view select allow only child selection-->
						<ng-container *ngIf="col.type == 'tree_view_parent_select' && isEditable">
							<app-single-tree-dropdown
								class="w-100"
								[items]="
									row[col?.parent]
										? row?.dropdown_options[col?.map_key][row[col?.parent]][col?.optionKey]
										: []
								"
								[value]="row[col?.key]"
								[treeInputConfig]="{ isParentAllowed: true }"
								id="tree_parent_{{ rowIndex }}"
								attr.data-testid="tree_parent_{{ rowIndex }}"
							>
							</app-single-tree-dropdown>
						</ng-container>
						<!--Input type tree view parent select allow only child selection ends-->
						<!-- Input type badge selection starts here -->
						<div
							*ngIf="!isEditable && col.type == 'badge_select'"
							id="badgeSelect{{ col.key }}_{{ rowIndex }}"
							attr.data-testid="badgeSelect{{ col.key }}_{{ rowIndex }}"
						>
							<div
								class="badge badge-light-{{
									searchObjectByKeyValue(
										col.optionKey ? options[col.optionKey] : options[col.key],
										col.optionSearchKey ? col.optionSearchKey : 'id',
										col.subkey ? row[col.subkey][col.key] : row[col.key]
									)?.color
								}}"
							>
								<i
									class="fa-light mr-25 {{
										searchObjectByKeyValue(
											col.optionKey ? options[col.optionKey] : options[col.key],
											col.optionSearchKey ? col.optionSearchKey : 'id',
											col.subkey ? row[col.subkey][col.key] : row[col.key]
										)?.icon
									}}"
								></i>
								{{
									searchObjectByKeyValue(
										col.optionKey ? options[col.optionKey] : options[col.key],
										col.optionSearchKey ? col.optionSearchKey : "id",
										col.subkey ? row[col.subkey][col.key] : row[col.key]
									)?.name
								}}
							</div>
						</div>
						<ng-container *ngIf="col.type == 'badge_select' && isEditable">
							<app-select-badge
								[inputConfig]="{
									hideLabel: true,
									inputOptions: options[col.optionKey],
									id: col.key,
									model: row[col.key]
								}"
							></app-select-badge>
						</ng-container>

						<ng-container *ngIf="col.type == 'requester_tag'">
							<div class="my-25" attr.data-testid="requester_tag_{{ rowIndex }}">
								<div *ngFor="let req_tag of row[col.subkey][col.key]; let i = index">
									<div class="badge {{ colorArray[i % 6] }} remove-wrap mr-50">
										<i class="fa-tag fa-light mr-25"></i> {{ req_tag }}
									</div>
								</div>
							</div>
						</ng-container>
						<ng-container *ngIf="col.type == 'tag'">
							<div class="remove-wrap my-25" attr.data-testid="tag_{{ rowIndex }}">
								<div *ngFor="let tag of row[col.key]; let i = index">
									<div class="badge {{ colorArray[i % 6] }} remove-wrap mr-50">
										<i class="fa-tag fa-light mr-25"></i> {{ tag }}
									</div>
								</div>
							</div>
						</ng-container>
						<ng-container *ngIf="col?.addtag">
							<div class="my-25">
								<div *ngFor="let tag of row[col?.tagName]; let i = index">
									<div class="badge badge-light-info remove-wrap mr-50">
										<i class="fa-tag fa-light mr-25"></i> {{ tag }}
									</div>
								</div>
							</div>
						</ng-container>

						<!-- Input type badge selection ends here -->
						<div>
							<ng-container *ngFor="let action of col.actions">
								<a
									class="mr-10"
									*ngIf="
										permissions[action?.permissionKey ? action.permissionKey : action.key] &&
										!row[action?.hide_key] && action.key != 'requester_calling'
									"
									(click)="action.listener(row)"
									attr.data-testid="btn{{ action.key }}_{{ rowIndex }}"
								>
									<span
										tooltipClass="fadeInAnimation"
										placement="left"
										class="mr-half"
										[ngbTooltip]="action.label"
										id="btn{{ action.key }}_{{ rowIndex }}"
									>
										<!--<i-->
										<!--[data-feather]="action.icon"-->
										<!--[class]="action.class"-->
										<!--[size]="18"-->
										<!--&gt;</i>-->
										<i class="fa-light {{ action.icon }} fa-lg {{ action.class }}"></i>
									</span>
									<span *ngIf="action.showData">
										{{ row[action.value_key] }}
									</span>
									<span
										class="badge badge-light-{{ action.class }} font-small-3"
										*ngIf="action.showLabel"
									>
										{{ action.actionText }}
									</span>
								</a>
								<a
									class="mr-10"
									*ngIf="
										permissions[action?.permissionKey ? action.permissionKey : action.key] &&
										 action.key === 'requester_calling' && row?.requester?.requester_phone
									"
									attr.data-testid="btn{{ action.key }}_{{ rowIndex }}"
								>
									<app-infraon-call
										[requester]="row?.requester"
										[requester_profile]="row?.requester_profile"
										[view_type]="'normal_grid'"
										[action]="action"
										[currentCardDisplayId]="row.display_id"
									>
									</app-infraon-call>
								</a>
							</ng-container>
						</div>
					</ng-template>
				</div>
			</ng-template>
		</ngx-datatable-column>
		<ngx-datatable-footer>
			<ng-template
				let-rowCount="rowCount"
				let-pageSize="pageSize"
				let-selectedCount="selectedCount"
				let-curPage="curPage"
				let-offset="offset"
				let-selected="selected"
				ngx-datatable-footer-template
			>
				<div
					class="w-100 d-flex justify-content-between border-top pt-75"
					*ngIf="(rows?.length > 0 && showData) || !filterData"
				>
					<div class="page-info ml-2 d-block">
						<span *ngIf="selected">
							{{ selectedCount }}
							{{ "UI.k_selected" | translate }} / {{ rowCount }}
							{{ "UI.k_total" | translate }}
						</span>
						<span *ngIf="!selected">
							<!-- {{'UI.k_showing_pagination' | translate:{currentCount: rows ? rows.length : 0, totalCount: rowCount} }} -->
							{{
								"UI.k_showing_pagination"
									| translate
										: {
												currentCount: (curPage - 1) * limit + 1,
												totalCount: rowCount,
												maxLimit: curPage * limit >= count ? count : curPage * limit
										  }
							}}
							<span *ngIf="dataLoading == true" class="spinner-border spinner-border-sm ml-25"></span>
						</span>
					</div>
					<div class="pagination">
						<datatable-pager
							[page]="curPage"
							pagerPreviousIcon="datatable-icon-prev"
							pagerLeftArrowIcon="datatable-icon-left"
							pagerNextIcon="datatable-icon-skip"
							pagerRightArrowIcon="datatable-icon-right"
							[size]="pageSize"
							[count]="rowCount"
							class="datatable-pager"
							[hidden]="false"
							(change)="dataTable.onFooterPage($event)"
						>
						</datatable-pager>
					</div>
					<div class="page-entries d-flex-inline d-block mr-2">
						<label class="d-flex align-items-center"
							>{{ "UI.k_show" | translate }}
							<select
								id="selItems"
								name="items"
								class="form-control form-control-sm mx-25"
								(change)="setLimit($event)"
								[(ngModel)]="pageLimit"
							>
								<option id="opt10" name="opt10" value="10">10</option>
								<option id="opt25" name="opt25" value="25">25</option>
								<option id="opt50" name="opt50" value="50">50</option>
								<option id="opt100" name="opt100" value="100">100</option>
								<option id="opt200" name="opt200" value="200">200</option>
								<!-- <option id="optShowAll" name="optShowAll" [value]="'all'">
									{{ "UI.k_all" | translate }}
								</option> -->
							</select>
							{{ "UI.k_entries" | translate }}</label
						>
					</div>
				</div>
			</ng-template>
		</ngx-datatable-footer>
	</ngx-datatable>
</div>
<div *ngIf="rows?.length == 0 && showData && !filterData">
	<app-dns-empty-grid-data [emptyData]="emptyData" [permissions]="permissions"></app-dns-empty-grid-data>
</div>
<div *ngIf="rows?.length == 0 && showData && filterData">
	<app-dns-empty-grid-data [noFilterData]="true"></app-dns-empty-grid-data>
</div>
<div *ngIf="!showData">
	<app-grid-skeleton [columns]="columns"></app-grid-skeleton>
</div>
