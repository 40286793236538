<div class="d-flex col-md-12">
	<div class="col-md-9">
		<div class="d-flex col-md-12 p-0 mt-1 justify-content-between">
			<div class="col-md-10 p-0">
				<ngx-skeleton-loader [theme]="{ height: '15px', width: '20%' }"></ngx-skeleton-loader>
			</div>
			<div class="col-md-2">
				<ngx-skeleton-loader [theme]="{ height: '35px', width: '30%' }"></ngx-skeleton-loader>
			</div>
		</div>
		<div class="list-wrapper">
			<div class="skeleton" class="pr-50 mb-1">
				<div class="sekeleton-card item-wrap col-md-12 d-flex pb-0 pt-0">
					<div class="skeleton" class="p-1 col-md-4 d-flex border-right">
						<ngx-skeleton-loader
							appearance="square"
							[theme]="{ height: '45px', width: '45px', 'margin-right': '0.5rem', 'margin-left': '2rem' }"
						></ngx-skeleton-loader>
						<div>
							<ngx-skeleton-loader
								count="2"
								[theme]="{
									height: '13px',
									width: '94%',
									'margin-left': '1rem',
									'margin-right': '2rem'
								}"
							></ngx-skeleton-loader>
						</div>
					</div>

					<div class="col-md-4 p-1 border-right">
						<ngx-skeleton-loader
							count="2"
							[theme]="{ height: '40px', width: '100%' }"
						></ngx-skeleton-loader>
					</div>
					<div class="col-md-4 p-1">
						<ngx-skeleton-loader [theme]="{ height: '13px', width: '100%' }"></ngx-skeleton-loader>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="list-wrapper col-md-3 mt-3">
		<div class="skeleton" class="pr-50 mb-1">
			<div class="sekeleton-card item-wrap col-md-12 pb-0 pt-0">
				<div class="d-flex">
					<div class="d-flex col-md-12 p-0 mt-1 justify-content-between">
						<div class="d-flex col-md-10 p-0 justify-content-start">
							<div class="col-md-8 mr-1 p-0">
								<ngx-skeleton-loader [theme]="{ height: '15px', width: '100%' }"></ngx-skeleton-loader>
							</div>
						</div>
						<div class="col-md-1 p-0">
							<ngx-skeleton-loader [theme]="{ height: '15px', width: '100%' }"></ngx-skeleton-loader>
						</div>
					</div>
				</div>
				<hr class="mb-1 mt-0" />
				<div class="d-flex col-md-12 p-0 justify-content-between">
					<div class="col-md-4 mr-1 p-0">
						<ngx-skeleton-loader [theme]="{ height: '15px', width: '100%' }"></ngx-skeleton-loader>
					</div>
					<div class="col-md-1 p-0">
						<ngx-skeleton-loader [theme]="{ height: '15px', width: '100%' }"></ngx-skeleton-loader>
					</div>
				</div>
				<div>
					<ngx-skeleton-loader [theme]="{ height: '40px', width: '100%' }"></ngx-skeleton-loader>
				</div>
			</div>
		</div>
	</div>
</div>


<div class="d-flex flex-wrap col-md-12 p-0 ">
    <div class="skeleton" class="col-md-9 p-0">
		<div class="skeleton" class="col-md-12 d-flex px-75">
			<div class="skeleton" class="col-md-6">
				<ngx-skeleton-loader [theme]="{ height: '15px', width: '100%' }"></ngx-skeleton-loader>
			</div>
			<div class="skeleton" class="col-md-6">
				<ngx-skeleton-loader [theme]="{ height: '15px', width: '100%' }"></ngx-skeleton-loader>
			</div>
		</div>

		<div class="skeleton" class="px-2">
			<ngx-skeleton-loader [theme]="{ height: '15px', width: '100%' }"></ngx-skeleton-loader>
		</div>

		<div class="skeleton" class="px-2">
			<ngx-skeleton-loader [theme]="{ height: '15px', width: '100%' }"></ngx-skeleton-loader>
		</div>
		
		<div class="skeleton" class="px-2">
			<ngx-skeleton-loader [theme]="{ height: '45px', width: '100%'}"></ngx-skeleton-loader>
		</div>
		
		<div class="skeleton" class="px-2 col-md-4 d-flex">
			<ngx-skeleton-loader
				appearance="square"
				[theme]="{ height: '25px', width: '25px', 'margin-right': '0.5rem' }"
			></ngx-skeleton-loader>
			<div>
				<ngx-skeleton-loader
					
					[theme]="{
						height: '13px',
						width: '260%',
						'margin-left': '1rem',
						'margin-right': '2rem'
					}"
				></ngx-skeleton-loader>
			</div>
		</div>
		
		<div class="skeleton" class="px-2">
			<ngx-skeleton-loader [theme]="{ height: '45px', width: '100%'}"></ngx-skeleton-loader>
		</div>
	</div>
		<div class="list-wrapper col-md-3">
			<div class="skeleton" class="pr-50 mb-1">
				<div class="sekeleton-card item-wrap col-md-12 pb-0 pt-0">
					<div class="d-flex">
						<div class="d-flex col-md-12 p-0 mt-1 justify-content-between">
							<div class="d-flex col-md-10 p-0 justify-content-start">
								<div class="col-md-8 mr-1 p-0">
									<ngx-skeleton-loader [theme]="{ height: '15px', width: '100%' }"></ngx-skeleton-loader>
								</div>
							</div>
							<div class="col-md-1 p-0">
								<ngx-skeleton-loader [theme]="{ height: '15px', width: '100%' }"></ngx-skeleton-loader>
							</div>
						</div>
					</div>
					<hr class="mb-1 mt-0" />
					<div class="d-flex col-md-12 p-0 justify-content-between">
						<div class="col-md-4 mr-1 p-0">
							<ngx-skeleton-loader [theme]="{ height: '15px', width: '100%' }"></ngx-skeleton-loader>
						</div>
						<div class="col-md-1 p-0">
							<ngx-skeleton-loader [theme]="{ height: '15px', width: '100%' }"></ngx-skeleton-loader>
						</div>
					</div>
					<div>
						<ngx-skeleton-loader [theme]="{ height: '30px', width: '100%' }"></ngx-skeleton-loader>
					</div>
					<div>
						<ngx-skeleton-loader [theme]="{ height: '30px', width: '100%' }"></ngx-skeleton-loader>
					</div>
				</div>
			</div>
		</div>
	</div>



