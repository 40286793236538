import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule} from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreCommonModule } from '@core/common.module';
import { CorePipesModule } from "@core/pipes/pipes.module";
import { SharedModule } from "../../shared.module";
import { CoreDirectivesModule } from '@core/directives/directives';
import { NotificationComponent } from './components/notification/notification.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NotificationGridComponent } from './components/notification-grid/notification-grid.component';
import { NotificationRoutingModule } from './notification-routing.module';

@NgModule({
  declarations: [NotificationComponent, NotificationGridComponent],
  imports: [
    NotificationRoutingModule,
    CommonModule,
    FormsModule,
    CoreDirectivesModule,
    CorePipesModule,
    NgbModule,
    CoreCommonModule,
    SharedModule,
    NgScrollbarModule
  ],
  providers: [ ],
  exports: [NotificationComponent, NotificationGridComponent]
})
export class NotificationModule { }
