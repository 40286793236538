<div class="selct-feature group-list-feature status-list-picker">
	<div class="select-sec">
		<div class="form-group">
			<label *ngIf="showLabel" class="form-label" [for]="inputConfig?.id || 'UI.k_status'">
				{{ inputConfig?.header || "UI.k_status" | translate }} {{ inputConfig.required ? "*" : "" }}
			</label>
			<ng-select
				[items]="status_options"
				[class.hide-arrow]="hideArrow"
				[bindLabel]="inputConfig?.bindLabel || 'name'"
				[bindValue]="inputConfig?.bindValue || 'id'"
				[name]="inputConfig?.name || 'selStatus'"
				[id]="'selStatus_' + inputConfig?.id || 'selStatus'"
				[dropdownPosition]="dropdownPos"
				[placeholder]="inputConfig?.placeholder || 'UI.k_sel_status' | translate"
				[notFoundText]="inputConfig?.notFoundText || 'UI.k_no_status' | translate"
				(change)="onSelect($event)"
				[class.error]="inputConfig?.required && aspStatus.errors && inputConfig?.formRef?.submitted"
				[(ngModel)]="value"
				[searchFn]="customSearchFn"
				[clearable]="isclearable"
				[disabled]="inputConfig?.isEditDisabled"
				[multiple]="inputConfig?.multiple"
				[required]="inputConfig?.required"
				#aspStatus="ngModel"
				[attr.data-testid]="'selStatus'"
			>
				<ng-template ng-option-tmp ng-label-tmp let-item="item">
					<ng-container>
						<div class="group-lists d-flex align-items-center justify-content-between" [class.p-25]="inputConfig?.multiple">
							<!-- <div class="custom-squre" [ngClass]="'bg-' + item?.color"></div> -->
							<div class="d-flex align-items-center gap-50">
								<div
								class="custom-squre m-0"
								[ngStyle]="{
									'background-color': item?.color || inputConfig?.stateOptionsMap?.[item?.state_id]?.color_hex
								}"
							></div>
							<span class="fw-500 sm-font group-label">{{ inputConfig?.bindLabel || item.name | translate }}</span>
							</div>

							<div
								class="right-arrow custom-text"
								*ngIf="this.inputConfig && inputConfig?.isTextMode"
								style="{{ item?.text_color ? 'background-color: ' + item?.text_color + '1f' : 'background-color: ' + item?.color + '1f'
								}}"
							>
								<div class="sm-font line-height-1"
									style="{{ item?.text_color ? 'color: ' + item?.text_color + '!important' : 'color: ' + item?.color + '!important'
									}}"
								>
									{{ item.state | translate }}
								</div>
							</div>
							<div
								class="right-arrow custom-icon"
								*ngIf="this.inputConfig && !inputConfig?.isTextMode"
								[ngStyle]="{
									'background-color': item?.background_color,
									color: item?.text_color || item?.color
								}"
							>
								<i [class]="item.icon"></i>
							</div>
						</div>
					</ng-container>
				</ng-template>
			</ng-select>
			<ng-container *ngIf="inputConfig?.required">
				<dns-error-validation [formRef]="inputConfig?.formRef" [uiRef]="aspStatus"></dns-error-validation>
			</ng-container>
		</div>
	</div>
</div>
<!-- </div>
</div> -->
