<div class="sla-main-container border" *ngFor="let item of matricsData">
	<div class="sla-header-item">
		<h6 class="title-color fw-500">{{ item.profile }}</h6>
	</div>
	<ng-container *ngFor="let matricesItem of item.metrices">
		<div class="h-100 w-100 d-flex position-relative border-top">
			<div class="sla-main-item">
				<div class="sla-inner-header">
					<div
						class="badge"
						style="height: fit-content"
						[style.background]="getRunningStatusTitle(matricesItem)?.color_name"
					>
						{{ getRunningStatusTitle(matricesItem)?.name }}
					</div>
				</div>
				<table cellpadding="5">
					<tbody>
						<tr>
							<td style="width: 150px">{{ "UI.k_metric" | translate }}</td>
							<td>{{ matricesItem?.metric_name }}</td>
						</tr>
						<tr>
							<td style="width: 150px">{{ "UI.k_target" | translate }}</td>
							<td>{{ matricesItem?.metric_target }} {{ "UI.k_min" | translate }}</td>
						</tr>
						<tr>
							<td style="width: 150px">{{ "UI.k_hold" | translate }}</td>
							<td>{{ matricesItem?.total_hold }} {{ "UI.k_min" | translate }}</td>
						</tr>
						<tr *ngIf="matricesItem?.end_time">
							<td style="width: 150px">{{ "UI.k_end" | translate }}</td>
							<td>{{ matricesItem?.end_time }}</td>
						</tr>
						<tr *ngIf="!matricesItem?.end_time">
							<td style="width: 150px">{{ "UI.k_expected_end" | translate }}</td>
							<td>{{ matricesItem?.expected_end_time }}</td>
						</tr>
						<tr>
							<td style="width: 150px">{{ "UI.k_remaining" | translate }}</td>
							<td>{{ matricesItem?.remaining_time }} {{ "UI.k_min" | translate }}</td>
						</tr>
						<tr>
							<td style="width: 150px">{{ "UI.k_total_elapsed" | translate }}</td>
							<td>{{ matricesItem?.total_elapsed }} {{ "UI.k_min" | translate }}</td>
						</tr>
						<tr>
							<td style="width: 150px">{{ "UI.k_business_elapse" | translate }}</td>
							<td>{{ matricesItem?.biz_elapsed }} {{ "UI.k_min" | translate }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="sla-divider border-right"></div>
			<div class="sla-main-item">
				<div class="sla-timer-container border">
					<div class="sla-timer-header border-bottom">
						<h6 class="title-color fw-500 mb-0">{{ "UI.k_timer" | translate }}</h6>
					</div>
					<div class="sla-timer-item">
						<div
							class="sla-timer-progress"
							[style.background]="
								getChartColor(
									getColorName(
										matricesItem,
										matricesItem?.end_time ? true : false,
										matricesItem?.completed_time
									)
								)
							"
							*ngIf="getRunningStatusTitle(matricesItem)?.status != 1 && !is_achieved"
						>
							<ng-container *ngIf="getTimerData(matricesItem) as timerData">
								<div class="sla-timer-day">
									<label class="sla-text-time"
										>{{ matricesItem?.is_breached ? "+" : "" }}
										{{ timerData?.days?.toString().padStart(2, "0") }}</label
									>
									<label class="sla-text-label">{{ "UI.k_days" | translate }}</label>
								</div>

								<div class="sla-timer-time">
									<label class="sla-text-time">{{
										timerData?.hours?.toString().padStart(2, "0")
									}}</label>
									<label class="sla-text-label">{{ "UI.k_hours" | translate }}</label>
								</div>
								<div class="sla-timer-time">
									<label class="sla-text-time">{{
										timerData?.minutes?.toString().padStart(2, "0")
									}}</label>
									<label class="sla-text-label">{{ "UI.k_minute_s" | translate }}</label>
								</div>
							</ng-container>
						</div>

						<div *ngIf="is_achieved" class="card card-congratulation-medal card-achieved mb-0">
							<div class="card-body">
								<div style="font-size: 30px">
									{{ getRunningStatusTitle(matricesItem)?.status_name }}
								</div>
								<ng-container *ngIf="getTimerData(matricesItem) as timerData">
									<div class="d-flex justify-content-start align-items-center">
										<span *ngIf="is_achieved" class="mr-25">{{ "UI.k_with" | translate }}</span>
										<span class="mb-0 fw-500">
											{{ isBreached ? "+" : ""
											}}<span *ngIf="timerData?.days">{{ timerData?.days }}d</span>
											{{ timerData?.hours?.toString().padStart(2, "0") }}h
											{{ timerData?.minutes?.toString().padStart(2, "0") }}m
										</span>
										<span class="ml-25">{{ "UI.k_left" | translate }}</span>
									</div>
								</ng-container>
								<img
									src="assets/images/illustration/badge.svg"
									alt="Medal Pic"
									width="50"
									height="62"
									class="congratulation-medal"
								/>
							</div>
						</div>
						<div
							class="sla-timer-progress"
							[style.background]="'#B4B6BD'"
							*ngIf="getRunningStatusTitle(matricesItem)?.status == 1"
						>
							<div class="sla-timer-day">
								<label class="sla-text-time"> 00 </label>
								<label class="sla-text-label">{{ "UI.k_days" | translate }}</label>
							</div>
							<div class="sla-timer-time">
								<label class="sla-text-time">00</label>
								<label class="sla-text-label">{{ "UI.k_hours" | translate }}</label>
							</div>
							<div class="sla-timer-time">
								<label class="sla-text-time">00</label>
								<label class="sla-text-label">{{ "UI.k_minute_s" | translate }}</label>
							</div>
						</div>
						<!-- <span class="sla-timer-status" *ngIf="matricesItem?.is_breached" [style.color]="getChartColor('danger')">
							<i
								aria-hidden="true"
								class="fa fa-exclamation-triangle"
								style="margin-right: 10.15px"
							></i
							>{{ 'UI.k_breached' | translate }}
						</span>
						<span class="sla-timer-status" *ngIf="!matricesItem?.is_breached">
							{{ 'UI.k_left' | translate }}
						</span> -->
					</div>
					<div
						*ngIf="!is_achieved"
						class="d-flex justify-content-center align-items-center"
						style="height: fit-content"
						[style.color]="getRunningStatusTitle(matricesItem)?.color_name"
					>
						<i class="fa {{ getRunningStatusTitle(matricesItem)?.icon }} mr-25"></i>
						{{ getRunningStatusTitle(matricesItem)?.status_name }}
					</div>
				</div>
			</div>
		</div>
		<div class="sla-event-container border-top">
			<!-- <span class="mb-0 cursor-pointer sla-timer-label" (click)="toggleGrid(matricesItem)">{{
				matricesItem?.is_event_collapse == false ? "View Events" : "Hide Events"
			}}</span> -->
			<span class="title-color my-1 fw-500 d-flex align-items-center justify-content-center">{{"UI.k_events" | translate}}</span>
			<div
				class="matric-grid my-1 has-empty-data"
			>
				<dns-server-grid
					[rows]="matricesItem?.metric_events"
					[emptyData]="emptyData"
					[columns]="gridColumns"
					[count]="matricesItem?.metric_events.length"
					[permissions]="null"
					class="bootstrap core-bootstrap"
				>
				</dns-server-grid>
			</div>
		</div>
	</ng-container>
</div>
