import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-user-picker',
  templateUrl: './user-picker.component.html',
  styleUrls: ['./user-picker.component.scss']
})
export class UserPickerComponent implements OnInit {
  // customClass = 'right'
 @Input() inputConfig: any;
 toggleSelectOption = false
  team_user_map = [

    {
      "id":"t1",
      "team_id":"109788974896766784481",
      "is_deleted":false,
      "organization":"109788721938225435624",
      "creation_time":"2021-12-17T17:56:43.061000Z",
      "last_update_time":"2022-02-03T06:53:54.582000Z",
      "group_type":"Approval",
      "group_id":1,
      "name":"NOC",
      "description":"test description",
      "avatar_list": ["assets/images/portrait/small/avatar-s-5.jpg","assets/images/portrait/small/avatar-s-7.jpg","assets/images/portrait/small/avatar-s-10.jpg"],
      active: false,
      "users":[
            {"profile_id":"1","email":"test9@gm.com","full_name":"Test 111",'avatar':"assets/images/portrait/small/avatar-s-5.jpg"},
            {"profile_id":"2","email":"test3@gm.com","full_name":"Test 112",'avatar':"assets/images/portrait/small/avatar-s-5.jpg"},
            {"profile_id":"3","email":"test7@gm.com","full_name":"Test 113",'avatar':"assets/images/portrait/small/avatar-s-5.jpg"}
          ]
    },
    {
      "id":"t2",
      "team_id":"109788974896766784482",
      "is_deleted":false,
      "organization":"109788721938225435624",
      "creation_time":"2021-12-17T17:56:43.061000Z",
      "last_update_time":"2022-02-03T06:53:54.582000Z",
      "group_type":"Approval",
      "group_id":1,
      "name":"UI Team",
      "description":"test description",
      "avatar_list": ["assets/images/portrait/small/avatar-s-5.jpg","assets/images/portrait/small/avatar-s-7.jpg","assets/images/portrait/small/avatar-s-10.jpg"],
      active: false,
  
      "users":[
            {"profile_id":"4","email":"test9@gm.com","full_name":"UI 9",'avatar':"assets/images/portrait/small/avatar-s-5.jpg"},
            {"profile_id":"5","email":"test3@gm.com","full_name":"UI 3",'avatar':"assets/images/portrait/small/avatar-s-5.jpg"},
            {"profile_id":"6","email":"test7@gm.com","full_name":"UI 7",'avatar':"assets/images/portrait/small/avatar-s-5.jpg"}
          ]
    },
  {
    "id":"t3",
    "team_id":"109788974896766784483",
    "is_deleted":false,
    "organization":"109788721938225435624",
    "creation_time":"2021-12-17T17:56:43.061000Z",
    "last_update_time":"2022-02-03T06:53:54.582000Z",
    "group_type":"Approval",
    "group_id":1,
    "name":"Team 1",
    "description":"test description",
    "avatar_list": ["assets/images/portrait/small/avatar-s-5.jpg","assets/images/portrait/small/avatar-s-7.jpg","assets/images/portrait/small/avatar-s-10.jpg"],
    active: false,
  
    "users":[
          {"profile_id":"7","email":"test9@gm.com","full_name":"Test 11",'avatar':"assets/images/portrait/small/avatar-s-5.jpg"},
          {"profile_id":"8","email":"test3@gm.com","full_name":"Test 12",'avatar':"assets/images/portrait/small/avatar-s-5.jpg"},
          {"profile_id":"9","email":"test7@gm.com","full_name":"Test 13",'avatar':"assets/images/portrait/small/avatar-s-5.jpg"}
        ]
  },
  {
    "id":"t4",
    "team_id":"109788974896766784484",
    "is_deleted":false,
    "organization":"109788721938225435624",
    "creation_time":"2021-12-17T17:56:43.061000Z",
    "last_update_time":"2022-02-03T06:53:54.582000Z",
    "group_type":"Approval",
    "group_id":1,
    "name":"Team 2",
    "description":"test description",
    "avatar_list": ["assets/images/portrait/small/avatar-s-5.jpg","assets/images/portrait/small/avatar-s-7.jpg","assets/images/portrait/small/avatar-s-10.jpg"],
    active: false,
    "users":[
          {"profile_id":"10","email":"test9@gm.com","full_name":"Test 21",'avatar':"assets/images/portrait/small/avatar-s-5.jpg"},
          {"profile_id":"11","email":"test3@gm.com","full_name":"Test 22",'avatar':"assets/images/portrait/small/avatar-s-5.jpg"},
          {"profile_id":"12","email":"test7@gm.com","full_name":"Test 23",'avatar':"assets/images/portrait/small/avatar-s-5.jpg"}
        ]
  },
  {
    "id":"t5",
    "team_id":"109788974896766784485",
    "is_deleted":false,
    "organization":"109788721938225435624",
    "creation_time":"2021-12-17T17:56:43.061000Z",
    "last_update_time":"2022-02-03T06:53:54.582000Z",
    "group_type":"Approval",
    "group_id":1,
    "name":"Team 3",
    "description":"test description",
    "avatar_list": ["assets/images/portrait/small/avatar-s-5.jpg","assets/images/portrait/small/avatar-s-7.jpg","assets/images/portrait/small/avatar-s-10.jpg"],
    active: false,
    "users":[
          {"profile_id":"13","email":"test9@gm.com","full_name":"Test 31",'avatar':"assets/images/portrait/small/avatar-s-5.jpg"},
          {"profile_id":"14","email":"test3@gm.com","full_name":"Test 32",'avatar':"assets/images/portrait/small/avatar-s-5.jpg"},
          {"profile_id":"15","email":"test7@gm.com","full_name":"Test 33",'avatar':"assets/images/portrait/small/avatar-s-5.jpg"}
        ]
  },
  {
    "id":"t6",
    "team_id":"109788974896766784486",
    "is_deleted":false,
    "organization":"109788721938225435624",
    "creation_time":"2021-12-17T17:56:43.061000Z",
    "last_update_time":"2022-02-03T06:53:54.582000Z",
    "group_type":"Approval",
    "group_id":1,
    "name":"Team 4",
    "description":"test description",
    "avatar_list": ["assets/images/portrait/small/avatar-s-5.jpg","assets/images/portrait/small/avatar-s-7.jpg","assets/images/portrait/small/avatar-s-10.jpg"],
    active: false,
    "users":[
          {"profile_id":"16","email":"test9@gm.com","full_name":"Test 41",'avatar':"assets/images/portrait/small/avatar-s-5.jpg"},
          {"profile_id":"17","email":"test3@gm.com","full_name":"Test 42",'avatar':"assets/images/portrait/small/avatar-s-5.jpg"},
          {"profile_id":"18","email":"test7@gm.com","full_name":"Test 43",'avatar':"assets/images/portrait/small/avatar-s-5.jpg"}
        ]
  },
  {
    "id":"t7",
    "team_id":"109788974896766784487",
    "is_deleted":false,
    "organization":"109788721938225435624",
    "creation_time":"2021-12-17T17:56:43.061000Z",
    "last_update_time":"2022-02-03T06:53:54.582000Z",
    "group_type":"Approval",
    "group_id":1,
    "name":"Team 5",
    "description":"test description",
    "avatar_list": ["assets/images/portrait/small/avatar-s-5.jpg","assets/images/portrait/small/avatar-s-7.jpg","assets/images/portrait/small/avatar-s-10.jpg"],
    active: false,
    "users":[
          {"profile_id":"19","email":"test9@gm.com","full_name":"Test 51",'avatar':"assets/images/portrait/small/avatar-s-5.jpg"},
          {"profile_id":"20","email":"test3@gm.com","full_name":"Test 52",'avatar':"assets/images/portrait/small/avatar-s-5.jpg"},
          {"profile_id":"21","email":"test7@gm.com","full_name":"Test 53",'avatar':"assets/images/portrait/small/avatar-s-5.jpg"}
        ]
  }
  ];
  
  userPickerObject = {
    userData: null,
    selectedUser: null,
    searchData: null,
    copyOfOptionsList : [],
    showUserDetails: false,
    assignTeamConfig: {

    }
  }
  
  
  @Input("options") public optionList = this.team_user_map;
  @Input() customClass : string = 'right';
  // public customClass = 'right'
  @ViewChild("dummyClick") dummyClick: ElementRef;

  constructor() { }

  ngOnInit(): void {
  }

  
	ngOnChanges(changes: any) {
		if(changes) {
			this.customClass = this.customClass
		} else {
      this.customClass = 'right'
    }
	}

  dropdownEventTriggered(e, data) {
    e.open();
    this.toggleSelectOption = true
    // if (data.target.className !== "ng-arrow-wrapper") {
    //   e.open();
    // } else {
    //   this.optionList.forEach(e => {
    //     e.active = false
    //   })
    // }
    //console.log(data.target.className);

  }

  userSelectedEvent(e,item) {
    item["is_selected"] = true;
   if(document.getElementsByClassName('group-lists') && document.getElementsByClassName('group-lists').length > 0) {
    var iDiv = document.createElement("div");
    iDiv.innerHTML = `
    <img
    class="media-object"
    src="assets/images/portrait/small/avatar-s-5.jpg"
    alt="Avatar"
    height="32"
    width="32"
  />
    `
    iDiv.className = "avatar pull-up avatar-sm";
    document.getElementById(this.inputConfig?.id).childNodes[0].appendChild(iDiv)
    document.getElementById(this.inputConfig?.id).childNodes[0]["innerText"] = item.full_name;
    document.getElementById(this.inputConfig?.id).childNodes[1].remove();
    
   }

    // this.userPickerObject.selectedUser = item.full_name
    this.optionList.forEach(e => {
      e['active'] = false
      this.toggleSelectOption = false
    })
  }

  blurEventHandling() {
    this.optionList.forEach(e => {
      e['active'] = false
      this.toggleSelectOption = false
    })
  }

  assignToTeamEvent(e) {
    this.optionList.forEach(e => {
      e['active'] = false
      this.toggleSelectOption = false
    });
    this.userPickerObject.assignTeamConfig = {
      "assign_to_team" : true,
      "team_id": e.team_id

    }
    console.log(e,this.userPickerObject.assignTeamConfig);
  }

  showDetails(item) {
    this.optionList.forEach(e => {
      if (e.id === item.id) {
        e['active'] = !item.active
        if(e['active'] = true){
          this.toggleSelectOption = true
        }
        this.userPickerObject.showUserDetails = true;
      } else {
        this.userPickerObject.showUserDetails = false;
        e['active'] = false
      }
    })
    //console.log("List : ", this.optionList);

  }
  removeSec() {
    this.optionList.forEach(e => {
      e['active'] = false
      this.toggleSelectOption = false
    })
    // this.selectedValue = null;
  }

  removeSelectedData(e) {
    if (!e) {
      this.optionList.forEach(e => {
      e['active'] = false
      })
    }
  }

  searchUser(value,event,mainItem){
    // userSelect.open();
    // console.log("..........",value,mainItem);
    if(value && mainItem.active && mainItem.searchConfig) {
      const filter  =   mainItem.users.filter(id => (id?.full_name?.indexOf(value.toLowerCase()) > -1) || (id?.level?.indexOf(value.toLowerCase()) > -1) || ((id?.expertise?.indexOf(value.toLowerCase()) > -1)))
      if(filter && filter?.length) {
        mainItem.users = filter
      }
    // this.userPickerObject.copyOfOptionsList.forEach(element => {
    //  const filter  = element.users.filter(id => (id?.full_name?.indexOf(value.toLowerCase()) > -1) || (id?.level?.indexOf(value.toLowerCase()) > -1) || ((id?.expertise?.indexOf(value.toLowerCase()) > -1)))
    //  console.log(filter,"llllllllllll");
    //  if(filter && filter?.length) {
    //    element.users = filter
    //  }
     
    // });
    // this.optionList = this.userPickerObject.copyOfOptionsList
  } else {
    if(this.inputConfig?.userInput){
      this.inputConfig.userInput.forEach(ele => {
        if(ele.id === mainItem.id) {
          mainItem.users = ele.users
        }
      })
    }
  }
    if(event) {
      // event.open();
    }
  }

  searchFocusEvent(event) {
    if(event) {
      event.open();
    }
  }
}
