<div class="configuration-widgets">
	<div class="widget-inner-style">
		<span
			class="widget-head-style"
			[ngStyle]="{
				'background-color': widgetData?.bg_color,
				color: widgetData?.bg_color ? 'rgb(247, 248, 248)' : '',
				'border-bottom': widgetData?.bg_color ? '' : '1px solid #ebe9f1'
			}"
		>
			{{ widgetData?.text_title }}
		</span>

		<div
			class="panel-info-sec"
			
			[ngStyle]="{ 'background-color': widgetData?.bg_color }"
			id="divPanel"
			name="divPanel"
		>
			<div class="left-sec mt-50">
				<div class="value-sec"
					(click)="showDataSidebar(widgetData)"
				>
					<span
						class="current-value"
						[ngStyle]="{
							'font-style':
								widgetData?.text_style === 'Regular'
									? 'initial'
									: widgetData?.text_style === 'Italic'
									? 'italic'
									: '',
							'font-weight': widgetData?.text_style === 'Bold' ? 'bold' : '',
							'font-size': widgetData?.font_style + 'px',
							color: widgetData?.font_color
						}"
						>{{ widgetData?.data[0] }}</span
					>
					/
					<span
						class="total-value"
						[ngStyle]="{
							'font-style':
								widgetData?.text_style === 'Regular'
									? 'initial'
									: widgetData?.text_style === 'Italic'
									? 'italic'
									: '',
							'font-weight': widgetData?.text_style === 'Bold' ? 'bold' : '',
							'font-size': widgetData?.font_style + 'px',
							color: widgetData?.font_color
						}"
						>{{ widgetData?.data[1] }}</span
					>
				</div>
			</div>
			<div class="right-sec">
				<div class="icon-sec">
					<!-- <img src="../../../../../assets/images/API/redhat.png" alt=""> -->
					<i class="fas fa-link"></i>
				</div>
			</div>
		</div>
	</div>
</div>
