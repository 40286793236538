<div class="slideout-content default-stepping">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <form #AnalysisSubmissionForm="ngForm" (ngSubmit)="submitForm(AnalysisSubmissionForm)"
                class="modal-content add-new-user pt-0 pb-0">
                <div class="modal-header">
                    <h4 class="modal-title fw-500" id="exampleModalLabel">
                        {{"UI.k_analysis_&_submission" | translate }}
                    </h4>
                    <button type="button" id="btnRequesterClose" [attr.data-testid]="'btnRequesterClose'" name="btnRequesterClose"
                        class="btn btn-flat-secondary btn-icon" data-dismiss="modal" aria-label="Close"
                        (click)="hideAnaysisModal()">
                        <i class="fa-light fa-xmark"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <section class="horizontal-wizard" style="height: 100%">
                        <div id="stepper1" class="bs-stepper horizontal-wizard-example">
                            <div class="bs-stepper-header">
                                <div id="divCauseDetailsTab" name="divCauseDetailsTab" class="step"
                                    data-target="#cause-details">
                                    <button class="step-trigger" id="btnCauseDetail" [attr.data-testid]="'btnCauseDetail'" name="causeDetail">
                                        <span class="bs-stepper-box">1</span>
                                        <span class="bs-stepper-label">
                                            <span class="bs-stepper-title">{{"UI.k_cause_details" | translate}}
                                            </span>
                                        </span>
                                    </button>
                                </div>
                                <div class="line">
                                    <i class="fa-light fa-chevron-right font-medium-2"></i>
                                </div>
                                <div id="divTechniqueDetailsTab" name="divTechniqueDetailsTab" class="step"
                                    data-target="#technique-details">
                                    <button class="step-trigger" id="btnTechniqueDetails"  [attr.data-testid]="'btnTechniqueDetails'" name="workDetail" >
                                        <span class="bs-stepper-box">2</span>
                                        <span class="bs-stepper-label">
                                            <span class="bs-stepper-title">{{ "UI.k_technique_details" | translate }}
                                            </span>
                                        </span>
                                    </button>
                                </div>

                            </div>
                            <div class="bs-stepper-content">
                                <div class="bs-stepper-inner">
                                    <div id="cause-details" class="content">
                                        <form #causeDetailsForm = "ngForm" class="stepper-inner">
                                            <div class="stepper-body py-1">
                                                <ng-scrollbar class="custom-scrollbar section-scrollbar">
                                                    <div class="d-flex flex-wrap col-xs-12 col-md-12 col-xl-12 col-lg-12">
                                                        <div class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6">
                                                            <label class="form-label" for="selSubmitter">{{ "UI.k_submitter"| translate }}
                                                                <span
                                                                class="required-field txt-danger"
                                                                >&nbsp;*
                                                                </span>
                                                            </label>
                                                            <ng-select 
                                                                [items]="submittorList" 
                                                                [(ngModel)]="causeData.submitter"
                                                                #TDSubmitter="ngModel"
                                                                bindLabel="name" 
                                                                bindValue="profile_id" 
                                                                name="submitter"
                                                                notFoundText="{{
                                                                    'UI.k_no_items_found' | translate
                                                                }}"
                                                                id="selSubmitter"
                                                                placeholder="{{'UI.k_submitter'| translate}}"
                                                                [class.error]="!TDSubmitter.valid && causeDetailsForm.submitted"
                                                                (change)="onSubmitterChange($event)"
                                                                required>
                                                                <ng-template
                                                                    ng-option-tmp
                                                                    let-item="item"
                                                                    let-index="index"
                                                                    let-search="searchTerm"
                                                                >
                                                                    <span class="d-flex">
                                                                        <ng-container *ngIf="item?.profile_image">
                                                                            <div class="avatar mr-50 ml-25">
                                                                                <span class="avatar-content">
                                                                                    <img
                                                                                        class="rounded-circle"
                                                                                        src="{{ item.profile_image }}"
                                                                                        alt="User"
                                                                                        width="32"
                                                                                        height="32"
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                        </ng-container>
                                                                        <div *ngIf="!item?.profile_image">
                                                                            <div
                                                                                class="avatar mr-50 ml-25"
                                                                                [ngStyle]="{
                                                                                    'background-color':
                                                                                        item?.avatar_color
                                                                                            ? item?.avatar_color
                                                                                            : '#f59518'
                                                                                }"
                                                                                style="
                                                                                    align-content: center;
                                                                                    width: 32px;
                                                                                    height: 32px;
                                                                                "
                                                                            >
                                                                                <div class="avatar-content">
                                                                                    {{
                                                                                        item.full_name
                                                                                            | initials
                                                                                            | slice: 0:2
                                                                                    }}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    
                                                                        <div class="cell-line-height">
                                                                            <p
                                                                                class="font-medium-1 font-weight-bold line-height-1 mb-0"
                                                                            >
                                                                                {{ item?.full_name | slice: 0:28 }}
                                                                                {{
                                                                                    item?.full_name?.length > 28
                                                                                        ? ".."
                                                                                        : ""
                                                                                }}
                                                                            </p>
                                                                            <span class="text-muted font-small-2">
                                                                                {{ item?.email | slice: 0:28 }}
                                                                                {{
                                                                                    item?.email.length > 28 ? ".." : ""
                                                                                }}</span
                                                                            >
                                                                        </div>
                                                                    </span>
                                                                </ng-template>

                                                            </ng-select>
                                                            <span *ngIf="causeDetailsForm.submitted && TDSubmitter.invalid" class="invalid-form">
                                                                <small class="form-text text-danger font-small-1" *ngIf="TDSubmitter.errors.required">{{
                                                                    "UI.k_field_required" | translate
                                                                }}</small>
                                                            </span>
                                                        </div>
                                                        <div class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6">
                                                            <label class="form-label" for="selAnalysisTechnique">{{"UI.k_anaylsis_technique"| translate }}</label>
                                                            <ng-select 
                                                                [items]="analysisTechniqueList" 
                                                                [(ngModel)]="causeData.analysis_technique"
                                                                (change)="onChangeOfTechnqiue($event)"
                                                                bindLabel="display_key"
                                                                name="analysisTechnique"
                                                                notFoundText="{{
                                                                    'UI.k_no_items_found' | translate
                                                                }}" 
                                                                id="selAnalysisTechnique"
                                                                placeholder="{{'UI.k_anaylsis_technique'| translate}}"
                                                                [clearable]="false"
                                                            >
                                                            </ng-select>
                                                        </div>

                                                        <div class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6">
                                                            <label class="form-label" for="selType">{{"UI.k_type"| translate }}</label>
                                                            <ng-select 
                                                                [items]="typeList" 
                                                                [(ngModel)]="causeData.analysis_type"
                                                                bindLabel="display_key" 
                                                                name="selAnalysisType"
                                                                notFoundText="{{
                                                                    'UI.k_no_items_found' | translate
                                                                }}"
                                                                id="selAnalysisType"
                                                                placeholder="{{'UI.k_type'| translate}}">
                                                            </ng-select>
                                                        </div>

                                                        <div class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6">
                                                            <label class="form-label" for="selCategory">{{"UI.k_category"| translate }}
                                                            </label>
                                                            <ng-select 
                                                                [items]="categoryList" 
                                                                [(ngModel)]="causeData.category"
                                                                name="category"
                                                                notFoundText="{{
                                                                    'UI.k_no_items_found' | translate
                                                                }}"
                                                                [addTag]="true"
                                                                id="selCategory"
                                                                placeholder="{{'UI.k_category'| translate}}"
                                                            >
                                                            </ng-select>
                                                        </div>
                                                        <div class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6">
                                                            <label
																class="form-label"
																for="txtSubmissionDate"
																>{{"UI.k_submission_date"| translate }}</label
															>

															<ng2-flatpickr
                                                                #datepicker
																[config]="basicDateOptions"
																name="joiningDate"
																id="txtJoiningDate"
																[(ngModel)]="causeData.submission_date"
																[setDate]="causeData.submission_date"
																placeholder="{{'UI.k_placeholder_yyyy_mm_dd' | translate}}"
															>
															</ng2-flatpickr>

                                                            <button type="button" (click)="onCalenderClick()" class="btn btn-sm btn-icon btn-flat-primary mr-25" id="btnOnCalenderclick" [attr.data-testid]="'btnOnCalenderclick'" rippleEffect>
                                                                <i class="fa-light fa-calendar-day body-color"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </ng-scrollbar>
                                            </div>
                                            <div class="stepper-btn d-flex justify-content-end">
                                                <button (click)="horizontalWizardStepperNext(causeDetailsForm)" type="submit" class="btn btn-primary btn-next" rippleEffect id="btnAcntNxt" name="btnAcntNxt" [attr.data-testid]="'btnAcntNxt'">
                                                    <span class="align-middle d-sm-inline-block d-none">{{"UI.k_next" | translate }}</span>
                                                </button>
                                            </div>
                                        </form>                                       
                                    </div>
                                    <div id="technique-details" class="content">
                                        <form #techniqueDetailsForm="ngForm" class="stepper-inner">
                                            <div class="stepper-body py-1">
                                                <ng-scrollbar class="custom-scrollbar section-scrollbar">
                                                    <div class="why-technique-container" *ngIf="this.causeData?.analysis_technique?.name === '5 Why Analysis Technique'">
                                                        <div class="col-xs-12 col-md-12 col-xl-12 col-lg-12 pl-2">
                                                            <h4 class="fw-600 pb-2">
                                                                {{"UI.k_5_whys_teq" | translate }} 
                                                            </h4>
    
                                                            <h5 class="fw-500 pb-2">
                                                                {{"UI.k_problem" | translate }} : <span class="primary-color fw-400"> {{ problem_data?.basic_info?.summary }}</span>
                                                            </h5>
                                                        </div>
                                                        <div class="d-flex flex-wrap col-xs-12 col-md-12 col-xl-12 col-lg-12">
                                                            <div *ngFor="let technique of techniqueDetailsWhy; let i = index"  class="d-flex justify-content-end w-100" >
                                                                <ng-container *ngIf="technique.active">
                                                                    <p class="primary-color fw-500"> {{"UI.k_why" | translate }} ?                                                                     <span
                                                                        class="required-field txt-danger"
                                                                        >&nbsp;*
                                                                        </span>
                                                                    </p>
                                                                    <div [ngClass]="['form-group', 'col-xs-' + (11-i),'col-md-' + (11-i),'col-xl-' + (11-i),'col-lg-' + (11-i) ]">
                                                                        <textarea
                                                                            class="form-control"
                                                                            id="reason-{{ i }}"
                                                                            name="'reason-{{ i }}"
                                                                            rows="3"
                                                                            placeholder="{{ 'UI.k_reason' | translate }}"
                                                                            [(ngModel)]="technique.reason"
                                                                            (change)="setCurrentIndex(i)"
                                                                            #TDDetails="ngModel"
                                                                            [class.error]="!TDDetails.valid && causeDetailsForm.submitted"
                                                                            required
                                                                        ></textarea>
                                                                        <span *ngIf="causeDetailsForm.submitted && TDDetails.invalid" class="invalid-form">
                                                                            <small class="form-text text-danger font-small-1" *ngIf="TDDetails.errors.required">{{
                                                                                "UI.k_field_required" | translate
                                                                            }}</small>
                                                                        </span>
                                                                    </div>
                                                                </ng-container>
                                                            </div>

                                                            <div *ngIf="!rootLoad" class="d-flex justify-content-end w-100 py-2" >
                                                                <p class="primary-color fw-500 pr-2"> {{"UI.k_is_actual_root_cause" | translate}}</p>
                                                                <div class="form-group">
                                                                    <div class="form-check form-check-inline">
                                                                        <input
                                                                            class="form-check-input"
                                                                            type="radio"
                                                                            name="isRootCause"
                                                                            id="isRootCauseYes"
                                                                            (change)="activateNextWhy()"
                                                                            [value]="true"
                                                                            [(ngModel)]="isRootCauseFoundWhy.isRootCauseFound"
                                                                        />
                                                                        <label class="form-check-label" for="isRootCauseYes">{{"UI.k_yes" | translate}}</label>
                                                                    </div>

                                                                    <div class="form-check form-check-inline">
                                                                        <input
                                                                            class="form-check-input"
                                                                            type="radio"
                                                                            name="isRootCause"
                                                                            id="isRootCauseNo"
                                                                            (change)="activateNextWhy()"
                                                                            [value]="false"
                                                                            [(ngModel)]="isRootCauseFoundWhy.isRootCauseFound"
                                                                        />
                                                                        <label class="form-check-label" for="isRootCauseNo">{{"UI.k_no" | translate}}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="d-flex flex-wrap" *ngIf="this.causeData?.analysis_technique?.name === 'Chronological Analysis Technique'">
                                                        <div class="d-flex justify-content-between col-xs-12 col-md-12 col-xl-12 col-lg-12 pl-2">
                                                            <h4 class="fw-600 pb-2">
                                                                {{"UI.k_chronological_teq" | translate}}
                                                            </h4>
    
                                                            <div class="d-flex">                                                                
                                                                <p class="primary-color fw-500 pr-2"> {{"UI.k_is_actual_root_cause" | translate}}</p>
                                                                <div class="form-group">
                                                                    <div class="custom-control custom-control-primary custom-switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            name="isRootCause"
                                                                            [checked]="isRootCauseFoundWhy.isRootCauseFound"
                                                                            [(ngModel)]="isRootCauseFoundWhy.isRootCauseFound"
                                                                            class="custom-control-input"
                                                                            id="isRootCause"
                                                                        />
                                                                        <label class="custom-control-label" for="isRootCause"></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div ngModelGroup="chronologicalData" class="d-flex flex-wrap col-xs-12 col-md-12 col-xl-12 col-lg-12 pl-2">
                                                            <div class="d-flex flex-wrap justify-content-end w-100" >
                                                                <p class="fw-400 w-100 lg-font"> {{"UI.k_details" | translate}} 
                                                                    <span
                                                                    class="required-field txt-danger"
                                                                    >&nbsp;*
                                                                    </span>
                                                                </p>

                                                                <div class="form-group w-100">
                                                                    <textarea
                                                                        class="form-control"
                                                                        id="reason"
                                                                        name="chronologicalDetails"
                                                                        rows="10"
                                                                        placeholder="{{ 'UI.k_details' | translate }}"
                                                                        [(ngModel)]="techniqueDetailsChronological.details"
                                                                        #TDDetails="ngModel"
                                                                        [class.error]="!TDDetails.valid && causeDetailsForm.submitted"
                                                                        required
                                                                    ></textarea>
                                                                    <span *ngIf="causeDetailsForm.submitted && TDDetails.invalid" class="invalid-form">
                                                                        <small class="form-text text-danger font-small-1" *ngIf="TDDetails.errors.required">{{
                                                                            "UI.k_field_required" | translate
                                                                        }}</small>
                                                                    </span>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="d-flex flex-wrap" *ngIf="this.causeData?.analysis_technique?.name === 'Kepner-Tregoe Technique'">
                                                        <div class="d-flex justify-content-between col-xs-12 col-md-12 col-xl-12 col-lg-12 pl-2">
                                                            <h4 class="fw-600 pb-2">
                                                                {{"UI.k_kepner_tregoe_teq" | translate}}
                                                            </h4>
    
                                                            <div class="d-flex">                                                                
                                                                <p class="primary-color fw-500 pr-2"> {{"UI.k_is_actual_root_cause" | translate}}</p>
                                                                <div class="form-group">
                                                                    <div class="custom-control custom-control-primary custom-switch">
                                                                        <input
                                                                            type="checkbox"
                                                                            name="isRootCause"
                                                                            [checked]="isRootCauseFoundWhy.isRootCauseFound"
                                                                            [(ngModel)]="isRootCauseFoundWhy.isRootCauseFound"
                                                                            class="custom-control-input"
                                                                            id="isRootCause"
                                                                        />
                                                                        <label class="custom-control-label" for="isRootCause"></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div ngModelGroup="kepnerData" class="d-flex flex-wrap col-xs-12 col-md-12 col-xl-12 col-lg-12 pl-2">
                                                            <div class="d-flex flex-wrap justify-content-end w-100" >
                                                                <p class="fw-400 w-100 lg-font"> {{ 'UI.k_define_the_problem' | translate}}
                                                                    <span
                                                                    class="required-field txt-danger"
                                                                    >&nbsp;*
                                                                    </span>
                                                                </p>

                                                                <div class="form-group w-100">
                                                                    <textarea
                                                                        class="form-control"
                                                                        id="chronologicalDefinition"
                                                                        name="chronologicalDefinition"
                                                                        rows="5"
                                                                        placeholder="{{ 'UI.k_details' | translate }}"
                                                                        [(ngModel)]="techniqueDetailsKepner.definition"
                                                                        #TDDetails="ngModel"
                                                                        [class.error]="!TDDetails.valid && causeDetailsForm.submitted"
                                                                        required
                                                                    ></textarea>
                                                                    <span *ngIf="causeDetailsForm.submitted && TDDetails.invalid" class="invalid-form">
                                                                        <small class="form-text text-danger font-small-1" *ngIf="TDDetails.errors.required">{{
                                                                            "UI.k_field_required" | translate
                                                                        }}</small>
                                                                    </span>
                                                                </div>
                                                            </div>

                                                            <div class="d-flex flex-wrap justify-content-end w-100" >
                                                                <p class="fw-400 w-100 lg-font"> {{ 'UI.k_problem_occur' | translate}}?
                                                                </p>

                                                                <div class="form-group w-100">
                                                                    <textarea
                                                                        class="form-control"
                                                                        id="chronologicalLocation"
                                                                        name="chronologicalLocation"
                                                                        rows="5"
                                                                        placeholder="{{ 'UI.k_details' | translate }}"
                                                                        [(ngModel)]="techniqueDetailsKepner.location"
                                                                    ></textarea>
                                                                </div>
                                                            </div>

                                                            <div class="d-flex flex-wrap justify-content-end w-100" >
                                                                <p class="fw-400 w-100 lg-font"> {{ 'UI.k_problem_start_occur' | translate}}?
                                                                </p>

                                                                <div class="form-group w-100">
                                                                    <textarea
                                                                        class="form-control"
                                                                        id="chronologicalTime"
                                                                        name="chronologicalTime"
                                                                        rows="10"
                                                                        placeholder="{{ 'UI.k_details' | translate }}"
                                                                        [(ngModel)]="techniqueDetailsKepner.time"
                                                                    ></textarea>
                                                                </div>
                                                            </div>

                                                            <div class="d-flex flex-wrap justify-content-end w-100" >
                                                                <p class="fw-400 w-100 lg-font"> {{ 'UI.k_problem_frequently_occur' | translate}}?
                                                                </p>

                                                                <div class="form-group w-100">
                                                                    <textarea
                                                                        class="form-control"
                                                                        id="chronologicalFrequency"
                                                                        name="chronologicalFrequency"
                                                                        rows="10"
                                                                        placeholder="{{ 'UI.k_details' | translate }}"
                                                                        [(ngModel)]="techniqueDetailsKepner.frequency"
                                                                    ></textarea>
                                                                </div>
                                                            </div>

                                                            <div class="d-flex flex-wrap justify-content-end w-100" >
                                                                <p class="fw-400 w-100 lg-font"> {{ 'UI.k_problem_size' | translate}}
                                                                </p>

                                                                <div class="form-group w-100">
                                                                    <textarea
                                                                        class="form-control"
                                                                        id="chronologicalSize"
                                                                        name="chronologicalSize"
                                                                        rows="10"
                                                                        placeholder="{{ 'UI.k_details' | translate }}"
                                                                        [(ngModel)]="techniqueDetailsKepner.size"
                                                                    ></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-scrollbar>
                                            </div>
                                            <div class="stepper-btn d-flex justify-content-end">
                                                <button (click)="horizontalWizardStepperPrevious()
                                                    " type="submit" [disabled]="" class="btn btn-outline-tertiary mr-1" rippleEffect
                                                    id="btnTechniqueSave" name="btnTechniqueSave">
                                                    <span class="align-middle d-sm-inline-block d-none">{{ "UI.k_back" | translate }}</span>
                                                </button>
    
                                                <button *ngIf="isRootCauseFoundWhy?.isRootCauseFound == false  && !(problem_data?.basic_info?.state?.id == 5 &&
                                                    problem_data?.is_exit)" (click)="onSubmit(techniqueDetailsForm)" [disabled]="is_save_disabled"
                                                    type="submit" class="btn btn-primary btn-next" rippleEffect id="btnTechniqueNxt" name="btnTechniqueNxt" [attr.data-testid]="'btnTechniqueNxt'">
                                                    <i
                                                        class="
                                                            fa-light fa-floppy-disk
                                                            align-middle
                                                            mr-sm-25 mr-0
                                                        "
                                                    ></i>
                                                    <span class="align-middle d-sm-inline-block d-none"> {{ "UI.k_save" | translate }}  {{"UI.k_draft" | translate }} </span>
                                                </button>
                                                <button *ngIf="isRootCauseFoundWhy?.isRootCauseFound == true&& !(problem_data?.basic_info?.state?.id == 5 &&
                                                    problem_data?.is_exit)" (click)="onSubmit(techniqueDetailsForm)" [disabled]="is_save_disabled"
                                                    type="submit" class="btn btn-primary btn-next" rippleEffect id="btnTechniqueNxt" name="btnTechniqueNxt" [attr.data-testid]="'btnTechniqueNxt'">
                                                     <i
                                                        class="
                                                            fa-light fa-floppy-disk
                                                            align-middle
                                                            mr-sm-25 mr-0
                                                        "
                                                    ></i>
                                                    <span class="align-middle d-sm-inline-block d-none">{{ "UI.k_save" | translate }}</span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </form>
        </div>
    </div>
</div>
