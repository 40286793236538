import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-assign-user-sidebar',
  templateUrl: './assign-user-sidebar.component.html',
  styleUrls: ['./assign-user-sidebar.component.scss']
})
export class AssignUserSidebarComponent implements OnInit {

  public orgData = [];
  public isAssignedOrg = true;
  public availableSearch

  @Output("onHideSidebar") onHideSidebar: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    @Inject(DOCUMENT) private document,
		private _activatedRoute: ActivatedRoute,
		private _coreSidebarService: CoreSidebarService,
		private modalService: NgbModal,
		private _coreConfigService: CoreConfigService,
		private _translateService: TranslateService,
		private _router: Router,
		private _toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.orgData = [
      {
        org_id:"ORG3426612",
        brand:"Faderal Bank",
        name:"infroan",
        logo:"../../../../assets/images/msp/image 21.svg",
        email:"muhammad.n@infraon.io",
        phone_number:" +91 8304926433",
        assigned:true,
        address:{city:"Kochi",state:"kerla",country:"india",pincode:"683580"}
      },
      {
        org_id:"ORG3426111",
        brand:"Jio",
        name:"infroan",
        logo:"../../../../assets/images/msp/image 23.svg",
        email:"muhammad.n@jio.com",
        phone_number:" +91 8304926433",
        assigned:true,
        address:{city:"Kochi",state:"kerla",country:"india",pincode:"683580"}
      },
      {
        org_id:"ORG3426111",
        brand:"Microsoft 365 License",
        name:"infroan",
        logo:"../../../../assets/images/asset/brand/microsoft.svg",
        email:"muhammad.n@mic.com",
        phone_number:" +91 8304926433",
        assigned:false,
        address:{city:"Kochi",state:"kerla",country:"india",pincode:"683580"}
      },
      {
        org_id:"ORG3426111",
        brand:"Samsung",
        name:"infroan",
        logo:"../../../../assets/images/asset/brand/samsung.svg",
        email:"muhammad.n@sam.com",
        phone_number:" +91 8304926433",
        assigned:false,
        address:{city:"Kochi",state:"kerla",country:"india",pincode:"683580"}
      }
    ]
  }
  closeEvent() {
    this.onHideSidebar.emit('hide');
  }
  onAssignedOrg(value) {
    if (value === 'assigned_org') {
      this.isAssignedOrg = true;
    } else {
      this.isAssignedOrg = false;
    }
  }
  onSelectAssign(action,data) {
    if(action==='unassign') {
      this.unAssignOrg(data);
    } else {
      this.assignOrg(data);
    }
  }
  unAssignOrg(data) {
    console.log(data,'unassign');
  }
  assignOrg(data) {
    console.log(data,'assign');
  }

}
