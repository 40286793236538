import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { GlobalConstants } from "app/app.globalConstants";
import { GridNameAvatarComponent } from "app/common/dns/components/grid-name-avatar/grid-name-avatar.component";
import { InfinityModules } from "app/common/dns/types/modules";

@Component({
	selector: "app-users",
	templateUrl: "./users.component.html",
	styleUrls: ["./users.component.scss"],
})
export class UsersComponent implements OnInit {
	columns: Array<any>;
	public ColumnMode = ColumnMode;
	public basicSelectedOption: number = 10;
	pageNumber: any = 1;
	sortKey: any = "display_id";
	reverse: any = "";
	limit: any = 10;
	totalCount: any;
	permissions: any;
	SelectionType = SelectionType;
	module = InfinityModules.CMDB;
	public users: any;

	constructor(private _translateService: TranslateService) {}

	ngOnInit(): void {
    let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}

		this.permissions = user_permissions[this.module];
		this.users = [
			{
				id: 1,
				status: "active",
				avatar: "assets/images/portrait/small/avatar-s-11.jpg",
				email: "roshan@gmail.com",
				name: "Roshan ashraf",
        type:"Admin",
        joined:"22 May 2021",
				last_active: "May 22, 2022 09:29 AM",
			},
			{
				id: 2,
				status: "invited",
				avatar: null,
				email: "sandy@gmail.com",
				name: null,
        type:"Finance Admin",
        joined:"",
				last_active: null,
			},
			{
				id: 3,
				status: "active",
				avatar: null,
				email: "roshan@gmail.com",
				name: "Theresa Webb",
        type:"Finance Admin",
        joined:"22 May 2021",
				last_active: "May 22, 2022 09:29 AM",
			},
			{
				id: 4,
				status: "active",
				avatar: null,
				email: "wadewarrenww@gmail.com",
				name: "Wade Warren",
        type:"Admin",
        joined:"22 May 2021",
				last_active: "May 22, 2022 09:29 AM",
			},
		];
		this.totalCount = this.users.length;

		this.columns = [
			{
				label: this._translateService.instant("UI.k_username"),
				key: "name",
				width: 200,
				component: GridNameAvatarComponent,
			},
			{
				label: this._translateService.instant("UI.k_email"),
				key: "email",
			},

			{
				label: this._translateService.instant("UI.k_user_type"),
				key: "type",
			},
			{
				label: this._translateService.instant("UI.k_status"),
				key: "status",
			},
			{
				label: this._translateService.instant("UI.k_joined"),
				key: "joined",
			},
			{
				label: this._translateService.instant("UI.k_last_active"),
				key: "last_active",
			},
			{
				label: this._translateService.instant("UI.k_action"),
				key: "action",
				actions: [
					{
						label: this._translateService.instant("UI.k_edit"),
						icon: "fa-pen-to-square",
						key: "edit",
						class: "text-primary",
						listener: () => {},
					},
					{
						label: this._translateService.instant("UI.k_delete"),
						icon: "fa-trash-can",
						key: "delete",
						class: "text-danger mr-50",
						listener: () => {},
					},
				],
			},
		];
	}

	editUser = (args): void => {};

	deleteUser = (row) => {};

	onSelectRow(data) {}

	setPage(pageInfo) {
		this.pageNumber = pageInfo.offset;
	}
	onSort(args): void {
		if (args.sorts) {
			this.pageNumber = 0;
			let sortKey = args.sorts[0].prop;
			this.sortKey = sortKey;
			if (args.sorts[0].dir == "desc") {
				this.reverse = "-";
			} else {
				this.reverse = "";
			}
		}
	}
	setLimit(args): void {
		this.pageNumber = 0;
		this.limit = args.target.value;
	}
}
