import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-category-list',
  templateUrl: './custom-category-list.component.html',
  styleUrls: ['./custom-category-list.component.scss']
})
export class CustomCategoryListComponent implements OnInit {
  @Input() categoryObject: any;
  @Output() categoryData: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    //console.log("Category List Object: ", this.categoryObject);

  }

  editSelectedItem(e, item) {
    //console.log("Selected Sub-Item: ", e);
    //console.log(item);
    item.ng_model_value = e;

  }

  buttonEvent(buttonData) {
    //console.log("Button Details: ", buttonData);
    this.categoryData.emit({ "data": buttonData });
  }

}
