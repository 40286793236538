<div *ngIf="key == 'device_type'">
	<div class="d-flex align-items-center">
		<div *ngIf="getImageSrc(data?.device_type, key) != ''">
			<img class="thumbnail-image mr-25" [src]="getImageSrc(data?.device_type, key)" width="20" height="20" />
		</div>
		<div class="datatable-body-cell-label">
			<p class="text-break mb-0">
				{{ data?.device_type }}
			</p>
		</div>
	</div>
</div>

<div *ngIf="key == 'os_name'">
	<div class="d-flex align-items-center">
		<div *ngIf="data?.os_name && getImageSrc(data?.os_name, key) != ''">
			<img class="thumbnail-image mr-25" [src]="getImageSrc(data?.os_name, key)" width="20" height="20" />
		</div>
		<div *ngIf="data?.hardware?.os_name && getImageSrc(data?.hardware?.os_name, key) != ''">
			<img class="thumbnail-image mr-25" [src]="getImageSrc(data?.hardware?.os_name, key)" width="20"
				height="20" />
		</div>
		<div class="datatable-body-cell-label">
			<p *ngIf="data?.os_name" class="text-break mb-0">
				{{ data?.os_name }}
			</p>
			<p *ngIf="data?.hardware?.os_name" class="text-break mb-0">
				{{ data?.hardware?.os_name }}
			</p>
		</div>
	</div>
</div>
<div *ngIf="key == 'make'">
	<div class="d-flex align-items-center">
		<div *ngIf="data?.make && getImageSrc(data?.make, key) != ''">
			<img class="thumbnail-image mr-25" [src]="getImageSrc(data?.make, key)" width="20" height="20" />
		</div>
		<div *ngIf="data?.hardware?.make && getImageSrc(data?.hardware?.make, key) != ''">
			<img class="thumbnail-image mr-25" [src]="getImageSrc(data?.hardware?.make, key)" width="20" height="20" />
		</div>
		<div class="datatable-body-cell-label">
			<p *ngIf="data?.make" class="text-break mb-0">
				{{ data?.make }}
			</p>
			<p *ngIf="data?.hardware?.make" class="text-break mb-0">
				{{ data?.hardware?.make }}
			</p>
		</div>
	</div>
</div>
<div *ngIf="key == 'operational_status'">
	<ng-container *ngIf="data?.operational_status">
		<div *ngIf="data?.operational_status?.name == 'UP'" class="badge badge-pill badge-light-success"
			style="font-size: 12px">
			<i class="fa-solid fa-up"></i>
			{{ "UI.k_up" | translate }}
		</div>
		<div *ngIf="data?.operational_status?.name == 'DOWN'" class="badge badge-pill badge-light-danger"
			style="font-size: 12px">
			<i class="fa-solid fa-down"></i>
			{{ "UI.k_down" | translate }}
		</div>
		<div *ngIf="data?.operational_status?.name == 'UNKNOWN'" class="badge badge-pill badge-light-secondary"
			style="font-size: 12px">
			<i class="fa-solid fa-question"></i>
			{{ "UI.k_unknown" | translate }}
		</div>
	</ng-container>
	<ng-container *ngIf="data?.common_info?.operational_status">
		<div *ngIf="data?.common_info?.operational_status?.name == 'UP'" class="badge badge-pill badge-light-success"
			style="font-size: 12px">
			<i class="fa-solid fa-up"></i>
			{{ "UI.k_up" | translate }}
		</div>
		<div *ngIf="data?.common_info?.operational_status?.name == 'DOWN'" class="badge badge-pill badge-light-danger"
			style="font-size: 12px">
			<i class="fa-solid fa-down"></i>
			{{ "UI.k_down" | translate }}
		</div>
		<div *ngIf="data?.common_info?.operational_status?.name == 'UNKNOWN'"
			class="badge badge-pill badge-light-secondary" style="font-size: 12px">
			<i class="fa-solid fa-question"></i>
			{{ "UI.k_unknown" | translate }}
		</div>
	</ng-container>
</div>
<div *ngIf="key == 'state'">
	<span *ngIf="data?.status" class="d-flex mt-half">
		<ng-container>
			<div class="group-lists d-flex align-items-center justify-content-start">
				<div class="custom-squre" [ngClass]="'bg-' + data?.status?.color" [ngStyle]="{
						'background-color': data?.status?.color
					}"></div>
				<span class="mr-50">{{ data?.status?.name | translate }}</span>
				<div class="right-arrow custom-text" [ngStyle]="{
						'background-color': data?.status?.background_color,
						color: data?.status?.text_color || data?.status?.color
					}">
					{{ data?.status?.state | translate }}
				</div>
			</div>
		</ng-container>
	</span>
	<span *ngIf="data?.common_info?.status" class="d-flex mt-half">
		<ng-container>
			<div class="group-lists d-flex align-items-center justify-content-start">
				<div class="custom-squre" [ngClass]="'bg-' + data?.common_info?.status?.color" [ngStyle]="{
						'background-color': data?.common_info?.status?.color
					}"></div>
				<span class="mr-50">{{ data?.common_info?.status?.name | translate }}</span>
				<div class="right-arrow custom-text" [ngStyle]="{
						'background-color': data?.common_info?.status?.background_color,
						color: data?.common_info?.status?.text_color || data?.common_info?.status?.color
					}">
					{{ data?.common_info?.status?.state | translate }}
				</div>
			</div>
		</ng-container>
	</span>
</div>
<div *ngIf="key == 'ci_name'">
	<h5 class="mb-0">
		{{ data?.ci_name }}
	</h5>
	<span class="text-muted font-small-2"> {{ data?.name }}</span>
</div>
<div *ngIf="key == 'status'">
	<div class="d-flex align-items-center">
		<div *ngIf="data?.status == 0" class="badge badge-pill badge-light-danger font-weight-normal">
			{{ "UI.k_not_started" | translate }}
		</div>
		<div *ngIf="data?.status == 1" class="badge badge-pill badge-light-success font-weight-normal">
			{{ "UI.k_In_progress" | translate }}
		</div>
		<div *ngIf="data?.status == 2" class="badge badge-pill badge-light-warning font-weight-normal">
			{{ "UI.k_completed" | translate }}
		</div>
		<div *ngIf="data?.status == 3" class="badge badge-pill badge-light-warning font-weight-normal">
			{{ "UI.k_stopped" | translate }}
		</div>
	</div>
</div>
<div *ngIf="key == 'is_manual'">
	<div class="d-flex align-items-center">
		<div *ngIf="data?.is_manual == true">Manual</div>
		<div *ngIf="data?.is_manual == false">Auto</div>
	</div>
</div>
<div *ngIf="key == 'recurrence'">
	<div class="d-flex align-items-center">
		<div *ngIf="data?.recurrence == 'Once'" class="badge badge-light-primary font-weight-normal">
			<span class="font-small-1 mr-25">1</span>
			<span class="font-small-1">Once</span>
		</div>
		<div *ngIf="data?.recurrence != 'Once'" class="badge badge-light-primary font-weight-normal">
			<i class="fa-light fa-rotate text-primary font-small-2 mr-25"></i>
			<span class="font-small-1">{{ data?.recurrence }}</span>
		</div>
	</div>
</div>
<ng-container *ngIf="key === 'whatsapp_template_status'">
	<ng-container *ngIf="data.whatsapp_template_status === 'In review'">
		<div class="transparent-primary badge d-flex align-items-center justify-content-center">
			<span class="text-primary">{{ data.whatsapp_template_status }}</span>
		</div>
	</ng-container>
	<ng-container *ngIf="data.whatsapp_template_status === 'Rejected'">
		<div class="transparent-danger badge d-flex align-items-center justify-content-center">
			<span class="text-danger">{{ data.whatsapp_template_status }}</span>
		</div>
	</ng-container>
	<ng-container *ngIf="data.whatsapp_template_status === 'APPROVED'">
		<div class="transparent-success badge d-flex align-items-center justify-content-center">
			<span class="text-success">{{ data.whatsapp_template_status }}</span>
		</div>
	</ng-container>
	<ng-container *ngIf="data.whatsapp_template_status === 'Disabled'">
		<div class="transparent-warning badge d-flex align-items-center justify-content-center">
			<span class="text-warning">{{ data.whatsapp_template_status }}</span>
		</div>
	</ng-container>
	<ng-container *ngIf="
			data.whatsapp_template_status !== 'Disabled' &&
			data.whatsapp_template_status !== 'APPROVED' &&
			data.whatsapp_template_status !== 'Rejected' &&
			data.whatsapp_template_status !== 'In review'
		">
		<div class="transparent-info badge d-flex align-items-center justify-content-center">
			<span class="text-info">{{ data.whatsapp_template_status }}</span>
		</div>
	</ng-container>
</ng-container>
<div *ngIf="key == 'is_enabled'">
	<label id="lblCustomSwitch{{ key }}">{{
		data?.is_enabled ? ("UI.k_enabled" | translate) : ("UI.k_disabled" | translate)
		}}</label>
</div>

<div *ngIf="key == 'service_name'">
	<div class="d-flex justify-content-start align-items-center">
		<div *ngIf="data.service_image" class="avatar-content">
			<img [src]="data.service_image" alt="service image" width="28" />
		</div>
		<div *ngIf="!data.service_image" class="container bg-light-primary border rounded">
			<span class="avatar-content font-small-2">{{ this.data?.name | initials | slice: O:2 }}</span>
		</div>
		<a [href]="url + data[id]" class="cursor-pointer font-small-2 title-blue ml-1" id="serviceName_{{ rowindex }}">
			{{ data?.name }}
		</a>
	</div>
</div>
<ng-container *ngIf="key == 'service_status' && this.data?.name">
	<div class="badge badge-pill fw-500" [ngStyle]="{
			'background-color': data.info.status?.background_color,
			color: data.info.status?.text_color
		}">
		{{ data.info.status?.name }}
	</div>
</ng-container>
<ng-container *ngIf="key == 'relation_map'">
	<div class="d-flex justify-content-start align-items-center">
		<div class="bg-body border mr-50 px-50" style="border-radius: 16px">
			<i class="fa-light fa-ticket font-small-2"></i>
			<span class="ml-25 font-small-2">{{ data.incident_count }}</span>
		</div>
		<!--		<div class="bg-body border mr-50 px-50" style="border-radius: 16px">-->
		<!--			<i class="fa-light fa-circle-exclamation font-small-2"></i>-->
		<!--			<span class="ml-25 font-small-2">{{ data.incident_count }}</span>-->
		<!--		</div>-->
		<!--		<div class="bg-body border px-50" style="border-radius: 16px">-->
		<!--			<i class="fa-light fa-circle-exclamation font-small-2"></i>-->
		<!--			<span class="ml-25 font-small-2">{{ data.incident_count }}</span>-->
		<!--		</div>-->
	</div>
</ng-container>
<ng-container *ngIf="key == 'requester_tag' && this.data?.requester?.tag">
	<div class="badge badge-light-info"><i class="fa-tag fa-light mr-25"></i> {{ data.requester?.tag }}</div>
</ng-container>
<ng-container *ngIf="key == 'wf_conditions_text'">
	{{ data?.conditions_text | translate }}&nbsp;
	<div class="badge badge-danger" *ngIf="data?.is_service_deleted">{{ "UI.k_deleted" | translate }}</div>
</ng-container>
<ng-container *ngIf="key == 'requester_name'">
	<div *ngIf="data?.requester?.requester_name || data?.requester?.requester_email"
		class="d-flex align-items-center justify-content-start">
		<div class="avatar avatar-sm mr-75">
			<div class="avatar-content">
				<span class="avatar-content" *ngIf="!data?.requester_profile && data?.requester?.requester_name"
					[ngStyle]="{
						'background-color': data?.requester_avatar_color
							? data?.requester_avatar_color
							: defaultAvatarColor
					}">
					{{ data?.requester?.requester_name | initials | slice: O:2 }}
				</span>
				<span *ngIf="!data?.requester_profile && !data?.requester?.requester_name" class="avatar-content"
					[ngStyle]="{
						'background-color': data?.requester_avatar_color
							? data?.requester_avatar_color
							: defaultAvatarColor
					}">
					{{ data?.requester?.requester_email | initials | slice: O:1 }}
				</span>
				<span class="avatar-content" *ngIf="data?.requester_profile">
					<img [src]="data?.requester_profile" alt="avatar" />
				</span>
			</div>
		</div>

		<div class="cell-line-height d-grid">
			<span class="fw-500 line-height-1 mb-0">
				{{ data?.requester?.requester_name | slice: 0:28 }}
				{{ data?.requester?.requester_name?.length > 28 ? ".." : "" }}
			</span>
			<span class="sm-font">
				{{ data?.requester?.requester_email }}
			</span>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="key === 'assignee'">
	<div class="d-flex align-items-center justify-content-start">
		<div class="avatar avatar-sm mr-75">
			<div class="avatar-content">
				<span *ngIf="
						!data?.assignment_info?.assignee_profile_image && data?.assignment_info?.assignee != undefined
					" class="avatar-content" [ngStyle]="{
						'background-color': data?.assignment_info?.assignee_avatar_color
							? data?.assignment_info?.assignee_avatar_color
							: defaultAvatarColor
					}">
					{{ data?.assignment_info?.assignee | initials | slice: O:2 }}
				</span>
				<span class="avatar-content" *ngIf="data?.assignment_info?.assignee_profile_image">
					<img [src]="data?.assignment_info?.assignee_profile_image" alt="avatar" />
				</span>
			</div>
		</div>
		<span class="sm-font">
			{{ data?.assignment_info?.assignee }}
		</span>
	</div>
</ng-container>

<ng-container *ngIf="key === 'imacdID'">
	<div class="d-flex align-items-center justify-content-center" (click)="openDetailSidebar(data)">
		<span class="fw-500 text-primary cursor-pointer">{{ data.display_id }}</span>
	</div>
</ng-container>
<ng-container *ngIf="key === 'assignee_user'">
	<div class="d-flex align-items-center justify-content-center">
		<div class="avatar avatar-sm mr-1 ml-0">
			<span class="avatar-content" *ngIf="!data?.assignee_user?.assignee_profile_image" [ngStyle]="{
					'background-color': data?.current_assignment_info?.assignee_profile?.avatar_color
						? data?.current_assignment_info?.assignee_profile?.avatar_color
						: '#f59518'
				}">
				{{ data?.current_assignment_info?.assignee_profile?.full_name | initials }}
			</span>
			<span class="avatar-content" *ngIf="data?.assignee_user?.assignee_profile_image">
				<img src="{{ data?.current_assignment_info?.assignee_profile?.profile_image }}" alt="" />
			</span>
		</div>
		<div>
			<div class="title-color fw-500">
				{{ data?.current_assignment_info?.assignee_profile?.full_name }}
			</div>
			<div class="sm-font fw-400">
				{{ data?.current_assignment_info?.assignee_profile?.email }}
			</div>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="key === 'requester_user'">
	<div class="d-flex align-items-center justify-content-center">
		<div class="avatar avatar-sm mr-1 ml-0">
			<span class="avatar-content" *ngIf="!data?.requester_user?.requester_profile_image" [ngStyle]="{
					'background-color': data?.change_manager?.avatar_color
						? data.change_manager?.avatar_color
						: '#f59518'
				}">
				{{ data?.requester?.requester_name | initials }}
			</span>
			<span class="avatar-content" *ngIf="data?.requester_user?.requester_profile_image">
				<img src="{{ data?.requester?.requester_profile }}" alt="" />
			</span>
		</div>
		<div>
			<div class="title-color fw-500">
				{{ data?.requester?.requester_name }}
			</div>
			<div class="sm-font fw-400">
				{{ data?.requester?.requester_email }}
			</div>
			<span></span>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="key === 'process_type'">
	<div class="d-flex align-items-center justify-content-center">
		<div class="reg-font text-primary mr-50 fw-500 text-center transparent-primary"
			[ngStyle]="{ width: '20px', 'border-radius': '6px' }">
			<span><i class="fal {{ data?.basic_info?.process_type?.icon }} sm-font"></i></span>
		</div>

		<div class="title-color fw-500">
			{{ data?.basic_info?.process_type?.name | translate }}
		</div>
	</div>
</ng-container>
<!-- <ng-container *ngIf="key === 'asset_count'">
	<div class="d-flex align-items-center justify-content-center">
		1
	</div>
</ng-container> -->
<ng-container *ngIf="key === 'priority'">
	{{ data?.basic_info?.priority | json }} 234567
	<ng-container *ngIf="data?.basic_info?.priority.name === 'Critical'">
		<div class="priority-container transparent-danger px-50 py-25 text-danger fw-500"
			[ngStyle]="{ 'border-radius': '5px' }">
			<span> {{ data?.basic_info?.priority?.name }}</span>
		</div>
	</ng-container>
	<ng-container *ngIf="data?.basic_info?.priority.name === 'Major'">
		<div class="priority-container transparent-warning px-50 py-25 text-warning fw-500"
			[ngStyle]="{ 'border-radius': '5px' }">
			<span> <i class="fal fal fa-angle-double-up"></i> {{ data?.basic_info?.priority?.name }}</span>
		</div>
	</ng-container>
	<ng-container *ngIf="data?.basic_info?.priority.name === 'Minor'">
		<div class="priority-container transparent-success px-50 py-25 text-success fw-500"
			[ngStyle]="{ 'border-radius': '5px' }">
			<span><i class="fal {{ data?.basic_info?.priority?.icon }}"></i> {{ data?.basic_info?.priority?.name
				}}</span>
		</div>
	</ng-container>
</ng-container>
<ng-container *ngIf="key === 'state'">
	<ng-container *ngIf="data?.state?.name === 'New'">
		<div class="d-flex align-items-center gap-50">
			<div class="status custom-squre m-0 p-25" [ngStyle]="{ background: data?.state.color }"></div>
			<div class="state fw-500">
				{{ data?.state.name }}
				<span class="sm-font line-height-1 custom-padding ml-25"
					[ngStyle]="{ background: data?.state.background_color, color: data?.state.color }">{{
					data?.state.state | translate }}</span>
			</div>
		</div>
	</ng-container>
</ng-container>

<ng-container *ngIf="subkey == 'change_manager'">
	<span class="d-flex pl-1">
		<div class="avatar avatar-sm mr-1 ml-0">
			<span class="avatar-content" *ngIf="!data?.change_manager?.profile_image" [ngStyle]="{
					'background-color': data?.change_manager?.avatar_color
						? data.change_manager?.avatar_color
						: '#f59518'
				}">
				{{ data?.change_manager?.full_name | initials }}
			</span>
			<span class="avatar-content" *ngIf="data?.change_manager?.profile_image">
				<img class="rounded-circle" src="{{ data?.change_manager?.profile_image }}" alt="User" />
			</span>
		</div>
		<div class="cell-line-height d-grid">
			<span class="fw-500 line-height-1 mb-0">
				{{ data?.change_manager?.full_name | slice: 0:28 }}
				{{ data?.change_manager?.full_name?.length > 28 ? ".." : "" }}
			</span>
			<span class="sm-font">
				{{ data?.change_manager?.email }}
			</span>
		</div>
	</span>
</ng-container>

<ng-container *ngIf="subkey == 'release_manager'">
	<span class="d-flex pl-1">
		<div class="avatar avatar-sm mr-1 ml-0">
			<span class="avatar-content"
				*ngIf="!data?.release_manager?.profile_image && data?.release_manager?.profile_id != undefined"
				[ngStyle]="{
					'background-color': data?.release_manager?.avatar_color
						? data.release_manager?.avatar_color
						: '#f59518'
				}">
				{{ data?.release_manager?.full_name | initials }}
			</span>
			<span class="avatar-content" *ngIf="data?.release_manager?.profile_image">
				<img class="rounded-circle" src="{{ data?.release_manager?.profile_image }}" alt="User" />
			</span>
		</div>
		<div class="cell-line-height d-grid">
			<span class="fw-500 line-height-1 mb-0">
				{{ data?.release_manager?.full_name | slice: 0:28 }}
				{{ data?.release_manager?.full_name?.length > 28 ? ".." : "" }}
			</span>
			<span class="sm-font">
				{{ data?.release_manager?.email }}
			</span>
		</div>
	</span>
</ng-container>

<ng-container *ngIf="key === 'deployment_technician_assignee'">
	<div class="d-flex align-items-center justify-content-start">
		<div class="avatar avatar-sm mr-75">
			<div class="avatar-content">
				<span *ngIf="
						!data?.deployment_technician_info?.assignee_profile_image &&
						data?.deployment_technician_info?.deployment_technician_assignee != undefined
					" class="avatar-content" [ngStyle]="{
						'background-color': data?.deployment_technician_info?.assignee_avatar_color
							? data?.deployment_technician_info?.assignee_avatar_color
							: defaultAvatarColor
					}">
					{{ data?.deployment_technician_info?.deployment_technician_assignee | initials | slice: O:2 }}
				</span>
				<span class="avatar-content" *ngIf="data?.deployment_technician_info?.assignee_profile_image">
					<img [src]="data?.deployment_technician_info?.assignee_profile_image" alt="avatar" />
				</span>
			</div>
		</div>
		<span class="sm-font">
			{{ data?.deployment_technician_info?.deployment_technician_assignee }}
		</span>
	</div>
</ng-container>
<div *ngIf="key == 'license_status'">
	<ng-container>
		<div *ngIf="data?.license_status == 'Active'" class="badge badge-pill badge-light-success"
			style="font-size: 12px">
			{{ "UI.k_active" | translate }}
		</div>
		<div *ngIf="data?.license_status == 'Expired'" class="badge badge-pill badge-light-danger"
			style="font-size: 12px">
			{{ "UI.k_pw_expired" | translate }}
		</div>
	</ng-container>
</div>
<ng-container *ngIf="key == 'asset_name'">
	<div class="d-flex align-items-center justify-content-center">
		<ng-container *ngIf="data?.images?.length > 0">
			<div class="item-image cursor-pointer" id="viewSlider__{{ data?.ci_id || data?.id }}"
				(click)="onclick('viewimageCarusal', data)">
				<!-- <img src="../../../../../assets/images/asset/battery.svg" style="width: 100%;height: 100%;" alt=""> -->
				<ngb-carousel class="h-100 w-100 carousel-fade" [showNavigationArrows]="false"
					[showNavigationIndicators]="true" [wrap]="true" [interval]="3000">
					<ng-template ngbSlide class="" id="slide{{ i }}" *ngFor="let item of data?.images; let i = index">
						<div class="d-flex align-items-center carousal-image-container justify-content-center">
							<img [src]="item?.file_data || item" class="h-100 w-100" />
						</div>
					</ng-template>
				</ngb-carousel>
			</div>
		</ng-container>
		<ng-container *ngIf="data?.images?.length === 0">
			<div class="item-image border d-flex align-items-center justify-content-center">
				<img height="24" alt="" src="assets/images/asset/type/unidentified.svg" />
			</div>
		</ng-container>
		<div class="item-content ml-1">
			<div class="title-color fw-500 title-content reg-font cursor-pointer"
				id="viewAssetItem__{{ data?.ci_id || data?.id }}" (click)="onclick('viewSideBar', data)">
				{{ data?.ci_name }}
			</div>
			<div class="text-muted">
				{{ data?.common_info?.consumable_model }}
			</div>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="key == 'asset_id'">
	<div class="d-flex align-items-center justify-content-center">
		<span class="title-color fw-500">{{ data?.asset_id }}</span>
	</div>
</ng-container>

<ng-container *ngIf="key == 'vendor'">
	<div class="d-flex align-items-center justify-content-center">
		<ng-container *ngIf="data?.vendors?.length > 0 && Object.keys(data?.vendors[0])?.length > 0">
			<div class="avatar avatar-sm mr-1 ml-0">
				<span class="avatar-content" *ngIf="!data?.vendors[0]?.profile_image" [ngStyle]="{
						'background-color': data?.change_manager?.avatar_color
							? data.change_manager?.avatar_color
							: '#f59518'
					}">
					{{ data?.vendors[0]?.vendor_name | initials }}
				</span>
				<span class="avatar-content" *ngIf="data?.vendors[0]?.profile_image">
					<img src="{{ data?.vendors[0]?.profile_image }}" alt="" />
				</span>
			</div>
			<!-- <div class="avatar avatar-sm mr-75 border " style="width: 25px;height: 25px;">
				<img src="{{data?.profile_image}}"  alt="">
			</div> -->
			<div class="title-color fw-500">
				{{ data?.vendors[0]?.vendor_name }}
			</div>
		</ng-container>
		<ng-container *ngIf="data?.vendors?.length == 0 || Object.keys(data?.vendors[0])?.length == 0">
			<div class="mr-1 ml-0 no-data">-</div>
		</ng-container>
	</div>
</ng-container>
<ng-container *ngIf="key == 'stock_vendor'">
	<div class="d-flex align-items-center justify-content-center">
		<ng-container *ngIf="Object.keys(data?.vendor)?.length > 0">
			<div class="avatar avatar-sm mr-1 ml-0">
				<span class="avatar-content" *ngIf="!data?.vendor?.profile_image" [ngStyle]="{
						'background-color': data?.change_manager?.avatar_color
							? data.change_manager?.avatar_color
							: '#f59518'
					}">
					{{ data?.vendor?.vendor_name | initials }}
				</span>
				<span class="avatar-content" *ngIf="data?.vendor?.profile_image">
					<img src="{{ data?.vendor?.profile_image }}" alt="" />
				</span>
			</div>
			<!-- <div class="avatar avatar-sm mr-75 border " style="width: 25px;height: 25px;">
				<img src="{{data?.profile_image}}"  alt="">
			</div> -->
			<div class="title-color fw-500">
				{{ data?.vendor?.vendor_name }}
			</div>
		</ng-container>
		<ng-container *ngIf="Object.keys(data?.vendor)?.length == 0">
			<div class="mr-1 ml-0 no-data">-</div>
		</ng-container>
	</div>
</ng-container>
<ng-container *ngIf="key == 'type'">
	<div class="d-flex align-items-center justify-content-center">
		<span class="title-color fw-500">{{data?.[key] ? data[key] : data?.[subkey]?.[key]?.name}}</span>
	</div>
</ng-container>
<ng-container *ngIf="key == 'quantity'">
	<div class="item-quantity d-flex align-items-center justify-content-center">
		<div class="quantity border p-1 text-center">
			<span class="title-color fw-500 reg-font">{{ data?.available_quantity }}</span>
		</div>
		<div class="item-plus cursor-pointer" id="btnAddStock__{{ data?.ci_id || data?.id }}"
			(click)="onclick('add', data)">
			<i class="fal fa-plus"></i>
		</div>
		<div class="item-minus cursor-pointer" id="btnReduceStock__{{ data?.ci_id || data?.id }}"
			(click)="onclick('remove', data)">
			<i class="fal fa-minus"></i>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="key == 'status'">
	<div class="status-container text-primary pl-25 pr-25 fw-500" *ngIf="data?.status === 'In Stock'"
		[ngStyle]="{ 'background-color': 'rgb(0 0 240 / 8%)', 'border-radius': '3px' }">
		{{ data?.status }}
	</div>
	<div class="status-container text-danger pl-25 pr-25 fw-500" *ngIf="data?.status === 'Expired'"
		[ngStyle]="{ 'background-color': 'rgb(234 84 85 / 8%)', 'border-radius': '3px' }">
		{{ data?.status }}
	</div>
	<div class="status-container text-warning pl-25 pr-25 fw-500" *ngIf="data?.status === 'Expires Soon'"
		[ngStyle]="{ 'background-color': '#ff9f431c', 'border-radius': '3px' }">
		{{ data?.status }}
	</div>
	<div class="status-container text-danger pl-25 pr-25 fw-500" *ngIf="data?.status === 'Consumed'"
		[ngStyle]="{ 'background-color': 'rgb(234 84 85 / 20%)', 'border-radius': '3px' }">
		{{ data?.status }}
	</div>
</ng-container>
<ng-container *ngIf="key == 'user_name'">
	<div class="d-flex align-items-center justify-content-center">
		<div class="avatar avatar-sm mr-1 ml-0">
			<span class="avatar-content" *ngIf="!data?.profile_image" [ngStyle]="{
					'background-color': data?.change_manager?.avatar_color
						? data.change_manager?.avatar_color
						: '#f59518'
				}">
				{{ data?.user_name | initials }}
			</span>
			<span class="avatar-content" *ngIf="data?.profile_image">
				<img src="{{ data?.profile_image }}" alt="" />
			</span>
		</div>
		<!-- <div class="avatar avatar-sm mr-75 border " style="width: 25px;height: 25px;">
			<img src="{{data?.profile_image}}"  alt="">
		</div> -->
		<div class="user-container">
			<div class="title-color fw-500">
				{{ data?.user_name }}
			</div>
			<div class="sm-font text-muted fw-400">
				{{ data?.email }}
			</div>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="key == 'feedback_name'">
	<div class="d-flex align-items-center justify-content-center">
		<div class="avatar avatar-sm mr-1 ml-0" *ngIf="!data?.basic_details?.requester?.profile_image">
			<span class="avatar-content" [ngStyle]="{
					'background-color': data?.basic_details?.requester?.avatar_color
						? data.basic_details?.requester?.avatar_color
						: '#f59518'
				}">
				{{ data?.basic_details?.requester?.full_name | initials }}
			</span>
		</div>
		<div class="avatar avatar-sm mr-75 border" *ngIf="data?.basic_details?.requester?.profile_image"
			style="width: 25px;height: 25px;">
			<img src="{{data?.basic_details?.requester?.profile_image}}" alt="">
		</div>
		<div class="user-container">
			<div class="title-color fw-500">
				{{ data?.basic_details?.requester?.full_name }}
			</div>
			<div class="sm-font text-muted fw-400">
				{{ data?.basic_details?.requester?.email }}
			</div>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="key == 'feedback_raiting'">
	<ng-template #t let-fill="fill"><span class="custom-star" [class.custom-full]="fill===100"><span class="custom-half"
				[style.width.%]="fill">&#9733;</span>&#9733; </span></ng-template>
	<ngb-rating [(rate)]="data.rating" [starTemplate]="t" [max]="5" (rateChange)="onRatingChange($event)"
		[readonly]="true" [style.pointer-events]="'none'"></ngb-rating>
</ng-container>
<ng-container *ngIf="key == 'feedback_assignee'">
	<div class="d-flex align-items-center justify-content-center">
		<div class="avatar avatar-sm mr-1 ml-0"
			*ngIf="!data.basic_details?.current_assignment_info?.assignee_profile?.profile_image">
			<span class="avatar-content" [ngStyle]="{
					'background-color': data.basic_details?.current_assignment_info?.assignee_profile?.avatar_color
				}">
				{{ data.basic_details?.current_assignment_info?.assignee_profile?.full_name | initials }}
			</span>
		</div>
		<div class="avatar avatar-sm mr-75 border"
			*ngIf="data.basic_details?.current_assignment_info?.assignee_profile?.profile_image"
			style="width: 25px;height: 25px;">
			<img src="{{data.basic_details?.current_assignment_info?.assignee_profile?.profile_image}}" alt="">
		</div>
		<div class="user-container">
			<div class="title-color fw-500">
				{{ data.basic_details?.current_assignment_info?.assignee_profile?.full_name }}
			</div>
			<div class="sm-font text-muted fw-400">
				{{ data.basic_details?.current_assignment_info?.assignee_profile?.email }}
			</div>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="key == 'feedback_ticket_details'">
	<div class="">
		<div class="text-primary reg-font fw-600 cursor-pointer" (click) = "onclick('showTicketDetails',data)" >
			#{{data.basic_details.display_id}}
		</div>
		<div class="sm-font" 
		ngbTooltip="{{ data.basic_details.basic_info.summary }}"
		>
			{{ data.basic_details.basic_info.summary | slice: 0:25 }}
			{{
			data.basic_details.basic_info.summary?.length > 25
			? ".."
			: ""
			}}
		</div>
	</div>
</ng-container>
<ng-container *ngIf="key == 'feedback_time'">
	<span class="fw-600">
		{{data?.creation_time | date:'medium'}}
	</span>
</ng-container>
<ng-container *ngIf="key == 'feedback_view_details'">
	<div class="text-primary fw-600 reg-font cursor-pointer" (click)="onclick('showfeedbackDetails',data)">
		{{'UI.k_view_details' | translate}}
	</div>
</ng-container>
<!-- msp -->
<ng-container *ngIf="key == 'organization_name'">
	<div class="d-flex msp-organization align-items-center gap-1">
		<div *ngIf="data?.organization_name?.profile_image" class="organization-image px-25 border">
			<img class="w-100" src="{{data?.organization_name?.profile_image}}" alt="">
		</div>
		<div>
			<span class="reg-font">{{data?.organization_name?.name}}</span>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="key == 'org_id'">
	<div>
		<span class="reg-font">{{data?.org_id}}</span>
	</div>
</ng-container>
<ng-container *ngIf="key == 'licence'">
	<div class="d-flex msp-organization align-items-center gap-1">
		<div *ngIf="data?.licence?.icon" class="organization-image  border">
			<img class="w-100" src="{{data?.licence?.icon}}" alt="">
		</div>
		<div>
			<span class="reg-font">{{data?.licence?.name}}</span>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="key == 'plan'">
	<div class="d-flex msp-plan align-items-center gap-1">
		<div class="transparent-primary badge d-flex align-items-center justify-content-center">
			<span class="text-primary reg-font fw-500">{{data?.plan}}</span>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="key == 'msp_user'">
	<div class="prograss-container d-flex align-items-center gap-50">
		<div class="prograss-bar">
			<ngb-progressbar type="{{data?.msp_user?.status}}" [max]="data?.msp_user?.max_count"
				[value]="data?.msp_user?.count"></ngb-progressbar>
		</div>
		<div class="prograss-details">
			<span class="fw-500"> {{data?.msp_user?.count}} / {{data?.msp_user?.max_count}} Users</span>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="key == 'msp_it_asset'">
	<div class="prograss-container d-flex align-items-center gap-50">
		<div class="prograss-bar">
			<ngb-progressbar type="{{data?.msp_it_asset?.status}}" [max]="data?.msp_it_asset?.max_count"
				[value]="data?.msp_it_asset?.count"></ngb-progressbar>
		</div>
		<div class="prograss-details">
			<span class="fw-500"> {{data?.msp_it_asset?.count}} / {{data?.msp_it_asset?.max_count}} Asset</span>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="key == 'msp_non_it_asset'">
	<div class="prograss-container d-flex align-items-center gap-50">
		<div class="prograss-bar">
			<ngb-progressbar type="{{data?.msp_non_it_asset?.status}}" [max]="data?.msp_non_it_asset?.max_count"
				[value]="data?.msp_non_it_asset?.count"></ngb-progressbar>
		</div>
		<div class="prograss-details">
			<span class="fw-500"> {{data?.msp_non_it_asset?.count}} / {{data?.msp_non_it_asset?.max_count}} Asset</span>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="key == 'msp_availability_monitoring'">
	<div class="prograss-container d-flex align-items-center gap-50">
		<div class="prograss-bar">
			<ngb-progressbar type="{{data?.msp_availability_monitoring?.status}}"
				[max]="data?.msp_availability_monitoring?.max_count"
				[value]="data?.msp_availability_monitoring?.count"></ngb-progressbar>
		</div>
		<div class="prograss-details">
			<span class="fw-500"> {{data?.msp_availability_monitoring?.count}} /
				{{data?.msp_availability_monitoring?.max_count}} Asset</span>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="key == 'msp_network_monitoring'">
	<div class="prograss-container d-flex align-items-center gap-50">
		<div class="prograss-bar">
			<ngb-progressbar type="{{data?.msp_network_monitoring?.status}}"
				[max]="data?.msp_network_monitoring?.max_count"
				[value]="data?.msp_network_monitoring?.count"></ngb-progressbar>
		</div>
		<div class="prograss-details">
			<span class="fw-500"> {{data?.msp_network_monitoring?.count}} / {{data?.msp_network_monitoring?.max_count}}
				Asset</span>
		</div>
	</div>
</ng-container>
<ng-container *ngIf="key == 'assigned_msp_user'">
	<ng-container *ngIf="data?.assigned_msp_user.length === 0">
		-
	</ng-container>
	<ng-container *ngIf="data?.assigned_msp_user.length === 1">
		<div class="d-flex align-items-center justify-content-center">
			<div class="avatar avatar-sm mr-1 ml-0">
				<span class="avatar-content" *ngIf="!data?.assigned_msp_user[0].profile_image" [ngStyle]="{
						'background-color' : '#f59518'
					}">
					{{ data?.assigned_msp_user[0].name | initials }}
				</span>
				<span class="avatar-content" *ngIf="data?.assigned_msp_user[0].profile_image">
					<img src="{{ data?.assigned_msp_user[0].profile_image }}" alt="" />
				</span>
			</div>
			<div class="user-container">
				<div class="title-color fw-500">
					{{ data?.assigned_msp_user[0].name }}
				</div>
				<div class="sm-font text-muted fw-400">
					{{ data?.assigned_msp_user[0].email }}
				</div>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="data?.assigned_msp_user.length > 1">
		<ng-container class="d-flex asigned-msp-user align-items-center justify-content-between">
			<ng-container class="d-flex align-items-center assigned-msp-user-container">
				<div class="avatar avatar-sm" tooltipClass="fadeInAnimation"
					ngbTooltip="{{data?.assigned_msp_user[0].name}}">
					<span class="avatar-content" *ngIf="!data?.assigned_msp_user[0].profile_image" [ngStyle]="{
												'background-color' : '#f59518'
											}">
						{{ data?.assigned_msp_user[0].name | initials }}
					</span>
					<span class="avatar-content" *ngIf="data?.assigned_msp_user[0].profile_image">
						<img src="{{ data?.assigned_msp_user[0].profile_image }}" alt="" />
					</span>
				</div>
				<ng-container *ngFor="let user of data?.assigned_msp_user.slice(1,15)">
					<div class="avatar assigned-msp-user-body avatar-sm" tooltipClass="fadeInAnimation"
						ngbTooltip="{{user.name}}">
						<span class="avatar-content" *ngIf="!user.profile_image" [ngStyle]="{
													'background-color' : '#f59518'
												}">
							{{ user.name | initials }}
						</span>
						<span class="avatar-content" *ngIf="user.profile_image">
							<img src="{{ user.profile_image }}" alt="" />
						</span>
					</div>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="data?.assigned_msp_user.length >= 15">
				<span class="text-primary reg-font fw-500">+{{data?.assigned_msp_user.length}}</span>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-container>

<ng-container *ngIf="key == 'msp_user_name'">
	<div class="d-flex align-items-center justify-content-center" *ngIf="data?.user_name?.name">
		<div class="avatar avatar-sm mr-1 ml-0">
			<span class="avatar-content" *ngIf="!data?.user_name?.profile_image" [ngStyle]="{
					'background-color':'#f59518'}">
				{{data?.user_name?.name | initials }}
			</span>
			<span class="avatar-content" *ngIf="data?.user_name?.profile_image">
				<img src="{{ data?.user_name?.profile_image }}" alt="" />
			</span>
		</div>
		<div class="user-container">
			<div class="title-color">
				{{ data?.user_name?.name }}
			</div>
		</div>
	</div>
	<div class="d-flex align-items-center justify-content-center" *ngIf="!data?.user_name?.name">
		-
	</div>
</ng-container>

<ng-container *ngIf="key == 'msp_user_email'">
	<div class="title-color" *ngIf="data?.user_name?.email">
		{{ data?.user_name?.email }}
	</div>
	<div class="title-color" *ngIf="!data?.user_name?.email">
		-
	</div>
</ng-container>

<ng-container *ngIf="key == 'msp_status'">
	<ng-container *ngIf="data?.status === 'active'">
		<div class="transparent-success badge d-flex align-items-center justify-content-center p-25"
			style="font-size: 12px">
			<span class="text-success fw-500">{{ "UI.k_active" | translate }}</span>
		</div>
	</ng-container>
	<ng-container *ngIf="data?.status === 'invited'">
		<div class="transparent-warning badge d-flex align-items-center justify-content-center p-25"
			style="font-size: 12px">
			<span class="text-warning fw-500">{{ "UI.k_invited" | translate }}</span>
		</div>
	</ng-container>
	<ng-container *ngIf="data?.status === 'inactive'">
		<div class="transparent-danger badge d-flex align-items-center justify-content-center p-25"
			style="font-size: 12px">
			<span class="text-danger fw-500">{{ "UI.k_inactive" | translate }}</span>
		</div>
	</ng-container>
</ng-container>
<ng-container *ngIf="key == 'msp_last_active_user'">
	<span class="title-color">{{data?.last_active}}</span>
</ng-container>

<ng-container *ngIf="key == 'assigned_organization'">
	<ng-container *ngIf="data?.assigned_org.length === 0">
		<div class="d-flex align-items-center gap-1">
			<div class="avatar avatar-sm border">
				<span class="avatar-content text-primary"><i class="fa-light fa-plus"></i></span>
			</div>
			<div class="text-primary sm-font">{{'UI.k_assign' | translate}}</div>
		</div>
	</ng-container>
	<ng-container *ngIf="data?.assigned_org.length === 1">
		<div class="d-flex align-items-center justify-content-between" style="width: 200px;">
			<div class="d-flex align-items-center justify-content-center">
				<div class="avatar border avatar-sm mr-1 ml-0">
					<span class="avatar-content" *ngIf="!data?.assigned_org[0].profile_image" [ngStyle]="{
							'background-color':'#f59518'}">
						{{data?.assigned_org[0].name | initials }}
					</span>
					<div class="avatar-content p-25" *ngIf="data?.assigned_org[0].profile_image"
						style="width:25px;height:25px;">
						<img class="w-100 h-100" src="{{ data?.assigned_org[0].profile_image }}" alt="" />
					</div>
				</div>
				<div class="user-container">
					<div class="title-color">
						{{ data?.assigned_org[0].name }}
					</div>
				</div>
			</div>
			<div>
				<div class="avatar avatar-sm border">
					<span class="avatar-content text-primary"><i class="fa-light fa-plus"></i></span>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="data?.assigned_org.length > 1">
		<div class="d-flex align-items-center justify-content-between" style="width: 200px;">
			<div class="d-flex align-items-center justify-content-center">
				<ng-container class="d-flex assigned-msp-user-container">
					<ng-container *ngFor="let user of data?.assigned_org.slice(0,10)">
						<div class="avatar assigned-org-body avatar-sm" tooltipClass="fadeInAnimation"
							ngbTooltip="{{user.name}}">
							<span class="avatar-content" *ngIf="!user.profile_image" [ngStyle]="{
														'background-color' : '#f59518'
													}">
								{{ user.name | initials }}
							</span>
							<span class="avatar-content border p-25" *ngIf="user.profile_image"
								style="width:25px;height:25px;background-color:'#fff'">
								<img class="w-100 h-100" src="{{ user.profile_image }}" alt="" />
							</span>
						</div>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="data?.assigned_org.length >= 11">
					<span class="text-primary fw-500">+{{data?.assigned_org.length}}</span>
				</ng-container>
			</div>
			<div>
				<div class="avatar avatar-sm border" (click)="openAddAssignSidebar(data)">
					<span class="avatar-content text-primary"><i class="fa-light fa-plus"></i></span>
				</div>
			</div>
		</div>
	</ng-container>
</ng-container>

<!-- msp -->