<div class="slideout-content default-slideout">
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
		<div class="modal-dialog">
			<div class="modal-content pt-0">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">
						{{ "UI.k_add" | translate }} {{ "UI.k_ticket" | translate }}
					</h5>
					<button
						id="btnClose"
						name="Close"
						[attr.data-testid]="'btnClose'"
						type="button"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						(click)="closeSidebar('problem-to-incident')"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body flex-auto-height">
					<div class="full-body-height">
						<div class="main-content flex-height activeSidebarPanel">
							<div class="main-sec">
								<div class="action-section">
									<form #addIncidentMainForm="ngForm" class="full-height">
										<div class="add-incident">
											<div class="left-sec">
												<ng-scrollbar class="custom-scrollbar section-scrollbar">
													<div class="left-inner-sec">
														<div class="p-1 border-bottom">
															<div>
																<div
																	class="d-flex justify-content-between align-items-center mb-75"
																>
																	<div class="fw-500 text-dark" for="basic-request">
																		{{ "UI.k_requester_details" | translate }}
																	</div>
																</div>
																<div class="d-flex align-items-center mb-1">
																	<div class="avatar mr-half">
																		<div
																			class="avatar"
																			*ngIf="requester?.profile_image"
																		>
																			<img
																				[src]="requester?.profile_image"
																				alt="Avatar"
																				width="32"
																				height="32"
																			/>
																		</div>
																		<div
																			*ngIf="!requester?.profile_image"
																			id="divInitial"
																			class="avatar-content"
																			[ngStyle]="{
																				'background-color':
																					requester?.avatar_color
																						? requester?.avatar_color
																						: '#f59518'
																			}"
																		>
																			{{
																				requester?.full_name
																					| initials
																					| slice: 0:2
																			}}
																		</div>
																	</div>
																	<div>
																		<div
																			class="fw-500 text-dark mb-0 text-break"
																			id="divFullname"
																		>
																			{{ requester?.full_name }}
																		</div>

																		<span class="text-break" id="divEmail">{{
																			requester?.email
																		}}</span>
																	</div>
																</div>
															</div>
															<div
																class="d-flex justify-content-start align-items-center"
															>
																<div *ngIf="requester?.base_location?.city" class="mr-1">
																	<span class="mr-50 xs-font">
																		<i class="fa-light fa-location-dot"></i>
																	</span>
																	<span class="sm-font">{{
																		requester?.base_location?.city
																	}}</span>
																</div>
																<div
																	*ngIf="requester?.phone_number?.internationalNumber"
																>
																	<span class="mr-50 xs-font">
																		<i class="fa-light fa-phone"></i>
																	</span>
																	<span class="sm-font" id="spPhone">{{
																		requester?.phone_number?.internationalNumber
																	}}</span>
																</div>
															</div>
															<div
																class="card bg-body p-1 mt-1 border"
																*ngIf="
																	addIncident?.asset?.ip_address ||
																	addIncident?.asset?.hostname ||
																	addIncident?.asset?.asset_id
																"
															>
																<div
																	class="d-flex justify-content-between align-items-center"
																>
																	<span class="sm-font fw-500 title-color">{{
																		"UI.k_asset" | translate
																	}}</span>
																</div>
																<div
																	class="d-flex mt-50 align-items-baseline justify-content-between"
																>
																	<div class="">
																		<span class="xs-font">{{
																			"UI.k_ip_address" | translate
																		}}</span>
																		<div class="">
																			<span
																				*ngIf="
																					addIncident?.asset &&
																					!addIncident?.asset?.ip_address
																				"
																				>-</span
																			>
																			<span
																				*ngIf="
																					addIncident?.asset &&
																					addIncident?.asset?.ip_address
																				"
																				class="xs-font title-color"
																			>
																				{{ addIncident?.asset?.ip_address }}
																			</span>
																		</div>
																	</div>
																	<div class="">
																		<div>
																			<span class="xs-font">{{
																				"UI.k_ci_id" | translate
																			}}</span>
																		</div>
																		<div>
																			<span
																				*ngIf="
																					addIncident?.asset &&
																					!addIncident?.asset?.asset_id
																				"
																				>-</span
																			>
																			<span
																				*ngIf="
																					addIncident?.asset &&
																					addIncident?.asset?.asset_id
																				"
																				class="xs-font title-color"
																			>
																				{{ addIncident?.asset?.asset_id }}
																			</span>
																		</div>
																	</div>
																	<div class="">
																		<div>
																			<span class="xs-font">{{
																				"UI.k_hostname" | translate
																			}}</span>
																		</div>
																		<div>
																			<span
																				*ngIf="
																					addIncident?.asset &&
																					!addIncident?.asset?.hostname
																				"
																				>-</span
																			>
																			<span
																				*ngIf="
																					addIncident?.asset &&
																					addIncident?.asset?.hostname &&
																					addIncident?.asset?.hostname
																						?.length < 20
																				"
																				class="xs-font title-color"
																				>{{
																					addIncident?.asset?.hostname
																				}}</span
																			>
																			<span
																				*ngIf="
																					addIncident?.asset &&
																					addIncident?.asset?.hostname &&
																					addIncident?.asset?.hostname
																						?.length >= 20
																				"
																				class="xs-font title-color"
																				container="body"
																				ngbTooltip="{{
																					addIncident?.asset?.hostname
																				}}"
																				>{{
																					(addIncident?.asset?.hostname
																						| slice: 0:18) + "..."
																				}}
																			</span>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div
															class="form-group d-flex justify-content-between cursor-pointer align-items-center border-bottom pt-1 px-1 highlighted"
															(click)="toggleReport()"
															id="lblReport"
															[ngClass]="
																isReport === false ? 'border-bottom  pb-1' : 'border-0'
															"
														>
															<div
																id="btnReported"
																class="title-color fw-500"
																for="basicInput"
															>
																{{ "UI.k_reportedby" | translate }}
															</div>
															<span *ngIf="!isReport" id="btnReporter" name="reporter">
																<span
																	id="btnReportedPlus"
																	placement="left"
																	ngbTooltip="{{ 'UI.k_reporter_info' | translate }}"
																>
																	<i
																		class="fa-light fa-plus text-primary cursor-pointer ml-50"
																	></i>
																</span>
															</span>
															<span *ngIf="isReport" id="btnReporter" name="reporter">
																<span id="btnReportedMinus">
																	<i
																		class="fa-light fa-minus text-primary cursor-pointer ml-50"
																	></i>
																</span>
															</span>
														</div>
														<div *ngIf="isReport" class="border-bottom my-1 px-1">
															<div class="form-group">
																<label for="txtInciReporterName"
																	>{{ "UI.k_name" | translate }}
																</label>
																<input
																	type="text"
																	[(ngModel)]="addIncident.reporter_name"
																	#TDReqNameRef="ngModel"
																	name="txtInciReporterName"
																	id="txtInciReporterName"
																	class="form-control form-control-sm"
																	placeholder="{{ 'UI.k_name' | translate }}"
																	minlength="3"
																	maxlength="40"
																	[class.error]="
																		TDReqNameRef.invalid &&
																		addIncidentMainForm.submitted
																	"
																	[appWhiteSpaceCheck]="addIncident.reporter_name"
																/>
																<!-- pattern = "^[a-zA-Z]+[a-zA-Z0-9_@. -]*$" -->
																<span
																	*ngIf="
																		addIncidentMainForm.submitted &&
																		TDReqNameRef.invalid
																	"
																	class="invalid-form"
																>
																	<!-- <small
																	class="form-text text-danger"
																	*ngIf="TDReqNameRef.errors.required"
																	>This field is required!</small
																> -->
																	<small
																		class="form-text text-danger xs-font"
																		*ngIf="
																			TDReqNameRef.errors.minlength ||
																			TDReqNameRef.errors.maxlength
																		"
																	>
																		{{
																			"UI.k_requester_validation" | translate
																		}}</small
																	>
																	<small
																		class="form-text text-danger"
																		*ngIf="TDReqNameRef.errors?.whitespace"
																		>{{
																			"UI.k_leading_trailing_space_validation"
																				| translate
																		}}</small
																	>
																</span>
															</div>
															<div class="form-group">
																<label for="txtInciRequesterEmail"
																	>{{ "UI.k_email" | translate }}
																</label>
																<input
																	type="text"
																	[(ngModel)]="addIncident.reporter_email"
																	#TDReqMailRef="ngModel"
																	name="txtInciRequesterEmail"
																	id="txtInciRequesterEmail"
																	class="form-control form-control-sm"
																	placeholder="{{ 'UI.k_email' | translate }}"
																	pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$"
																	placeholder="john.doe@email.com"
																	aria-label="john.doe"
																	[appWhiteSpaceCheck]="addIncident.reporter_email"
																/>
																<span
																	*ngIf="
																		addIncidentMainForm.submitted &&
																		TDReqMailRef.invalid
																	"
																	class="invalid-form"
																>
																	<small
																		class="form-text text-danger"
																		*ngIf="TDReqMailRef.errors.pattern"
																		>{{
																			"UI.k_alert_email_invalid" | translate
																		}}</small
																	>
																	<small
																		class="form-text text-danger"
																		*ngIf="TDReqMailRef.errors?.whitespace"
																		>{{
																			"UI.k_leading_trailing_space_validation"
																				| translate
																		}}</small
																	>
																</span>
															</div>
															<div class="form-group">
																<label for="txtInciRequesterPhone"
																	>{{ "UI.k_contact_number" | translate }}
																</label>
																<div class="w-100">
																	<ngx-intl-tel-input
																		class="w-100"
																		[cssClass]="'custom form-control w-100'"
																		[preferredCountries]="preferredCountries"
																		[enableAutoCountrySelect]="true"
																		[enablePlaceholder]="true"
																		[searchCountryFlag]="true"
																		[searchCountryField]="[
																			SearchCountryField.Iso2,
																			SearchCountryField.Name
																		]"
																		[selectFirstCountry]="false"
																		[selectedCountryISO]="CountryISO.UnitedStates"
																		[maxLength]="15"
																		[phoneValidation]="true"
																		[separateDialCode]="separateDialCode"
																		[numberFormat]="PhoneNumberFormat.National"
																		name="txtPhoneNumber"
																		id="txtPhoneNumber"
																		[(ngModel)]="addIncident.reporter_phone"
																		#TDPhoneRef="ngModel"
																		[ngClass]="{
																			'is-invalid':
																				addIncidentMainForm.submitted &&
																				TDPhoneRef.errors
																		}"
																	>
																	</ngx-intl-tel-input>
																	<span
																		*ngIf="
																			addIncidentMainForm.submitted &&
																			TDPhoneRef.invalid
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDPhoneRef.errors"
																			>{{
																				"UI.k_phone_number_validation"
																					| translate
																			}}</small
																		>
																	</span>
																</div>
															</div>
														</div>
														<div class="px-1">
															<div class="form-group">
																<label for="selAlarmSeverity">{{
																	"UI.k_priority" | translate
																}}</label>
																<div class="form-group">
																	<app-select-badge
																		[inputConfig]="{
																			hideLabel: true,
																			inputOptions: this.dropdown['priority'],
																			id: 'priority',
																			model: addIncident.priority_id
																		}"
																		(onValueChange)="statusBadgeChange($event)"
																	></app-select-badge>
																</div>
															</div>
															<div
																class="form-group input-tree-dropdown"
																*appDnsLicenseHandler="{
																	module_key: 'SERVICEDESK',
																	keys: ['SERVICE_CATALOGUE']
																}"
															>
																<label class="">{{
																	"UI.k_impact_service" | translate
																}}</label>
																<ng-container *ngIf="service_config?.is_editable">
																	<app-single-tree-dropdown
																		class="w-100"
																		[items]="dropdown?.formatted_services"
																		[value]="addIncident.impact_service"
																		[id]="'inciService'"
																		(itemChange)="serviceValueChange($event)"
																	>
																	</app-single-tree-dropdown>
																</ng-container>
																<div
																	class="ng-select ng-select-single ng-select-searchable ng-select-clearable ng-untouched ng-pristine ng-valid"
																	*ngIf="!service_config?.is_editable"
																	(click)="
																		onImpactServiceClick($event, 'impact_service')
																	"
																>
																	<div class="ng-select-container">
																		<div class="ng-value-container">
																			<span class="font-small-2 truncated-impact">
																				{{
																					(addIncident?.impact_service_tree_path
																						| slice: 0:38) ||
																						("UI.k_select_option"
																							| translate)
																				}}
																				{{
																					addIncident
																						?.impact_service_tree_path
																						?.length > 30
																						? "..."
																						: ""
																				}}
																			</span>
																			<!-- <div class="">{{ addIncident?.impact_service_tree_path || ("UI.k_select_option" | translate) }} -->
																			<!-- </div> -->
																			<div role="combobox" class="ng-input">
																				<input type="text" />
																			</div>
																		</div>
																		<span class="ng-arrow-wrapper"
																			><span class="ng-arrow"></span
																		></span>
																		<span
																			*ngIf="service_config?.is_loading"
																			class="fa fa-spinner fa-spin fa-2x"
																		>
																		</span>
																	</div>
																</div>
															</div>

															<div
																class="form-group input-tree-dropdown"
																*appDnsLicenseHandler="{
																	module_key: 'SERVICEDESK',
																	keys: ['SERVICE_CATALOGUE']
																}"
															>
																<label for="txtIncidentServiceClass" class=""
																	>{{ "UI.k_service_classification" | translate }}
																</label>
																<!-- [treeInputConfig]="{'isParentAllowed': true}"   -->
																<ng-container
																	*ngIf="classification_config?.is_editable"
																>
																	<app-single-tree-dropdown
																		class="w-100"
																		[items]="
																			dropdown?.formatted_classification_data
																		"
																		[value]="addIncident.service_classification"
																		[id]="'inciClassification'"
																		[treeInputConfig]="{ isParentAllowed: true }"
																		(itemChange)="classificationValueChange($event)"
																	>
																	</app-single-tree-dropdown>
																</ng-container>
																<div
																	class="ng-select ng-select-single ng-select-searchable ng-select-clearable ng-untouched ng-pristine ng-valid"
																	*ngIf="!classification_config?.is_editable"
																	(click)="
																		onImpactServiceClick(
																			$event,
																			'service_classification'
																		)
																	"
																>
																	<div class="ng-select-container">
																		<div class="ng-value-container">
																			<span class="font-small-2 truncated-impact">
																				{{
																					(addIncident?.service_classification_path
																						| slice: 0:38) ||
																						("UI.k_select_option"
																							| translate)
																				}}
																				{{
																					addIncident
																						?.service_classification_path
																						?.length > 38
																						? "..."
																						: ""
																				}}
																			</span>
																			<!-- <div class="">{{ addIncident?.service_classification_path || ("UI.k_select_option" | translate) }} -->
																			<!-- </div> -->
																			<div role="combobox" class="ng-input">
																				<input type="text" />
																			</div>
																		</div>
																		<span class="ng-arrow-wrapper"
																			><span class="ng-arrow"></span
																		></span>
																		<span
																			*ngIf="classification_config?.is_loading"
																			class="fa fa-spinner fa-spin fa-2x"
																		>
																		</span>
																	</div>
																</div>
															</div>
														</div>
														<div class="px-1">
															<div class="form-group">
																<div
																	class="d-flex justify-content-between align-items-center"
																>
																	<label class="" for="selApprovers">{{
																		"UI.k_sel_team" | translate
																	}}</label>
																	<small
																		id="selApprovers"
																		name="selApprovers"
																		class="text-primary cursor-pointer"
																		(click)="assignToMyself()"
																		>{{ "UI.k_take_it" | translate }}</small
																	>
																</div>
																<ng-select
																	[items]="dropdown?.teams_and_self_assign"
																	(clear)="selfAssignSel = false"
																	[multiple]="false"
																	[closeOnSelect]="true"
																	[(ngModel)]="addIncident.support_group_tmp"
																	[searchable]="true"
																	(change)="groupChange(dropdown.teams)"
																	bindLabel="name"
																	bindValue="team_id"
																	name="selAssignee"
																	id="selAssignee"
																	class=""
																	placeholder="{{ 'UI.k_select' | translate }} {{
																		'UI.k_group' | translate
																	}}"
																>
																	<ng-template ng-label-tmp let-item="item">
																		<span class="d-flex">
																			<span
																				class="avatar mr-50 avatar-sm"
																				[ngClass]="bg-light-primary"
																			>
																				<div
																					class="avatar-content"
																					[ngStyle]="{
																						'background-color':
																							stringToColor(item.name)
																					}"
																				>
																					{{
																						item.name
																							| initials
																							| slice: 0:2
																					}}
																				</div>
																			</span>
																			<div class="cell-line-height">
																				<span
																					class="font-small-2 fw-500 line-height-1 mb-0"
																					[ngbTooltip]="item?.name"
																				>
																					{{ item?.name | slice: 0:16 }}
																					{{
																						item?.name.length > 16
																							? ".."
																							: ""
																					}}
																				</span>
																			</div>
																		</span>
																	</ng-template>
																	<ng-template
																		ng-option-tmp
																		let-item="item"
																		let-index="index"
																		let-search="searchTerm"
																	>
																		<span
																			class="d-flex"
																			(click)="
																				modalOpenGroup(modalGroup);
																				selfAssignSel = false
																			"
																			*ngIf="item.team_id != 'self_assign'"
																		>
																			<span
																				class="avatar mr-50"
																				[ngClass]="bg-light-primary"
																				style="
																					align-content: center;
																					width: 32px;
																					height: 32px;
																				"
																			>
																				<div
																					class="avatar-content"
																					[ngStyle]="{
																						'background-color':
																							stringToColor(item.name)
																					}"
																				>
																					{{
																						item.name
																							| initials
																							| slice: 0:2
																					}}
																				</div>
																			</span>
																			<div class="cell-line-height">
																				<p
																					class="font-medium-1 fw-500 line-height-1 mb-0"
																					container="body"
																					[ngbTooltip]="item.name"
																				>
																					{{ item?.name | slice: 0:28 }}
																					{{
																						item?.name?.length > 28
																							? ".."
																							: ""
																					}}
																				</p>
																				<span class="text-muted sm-font">
																					{{ item.group_type }}</span
																				>
																			</div>
																		</span>
																		<div
																			class="cursor-pointer"
																			*ngIf="item.team_id == 'self_assign'"
																		>
																			<div
																				class="media"
																				(click)="assignToMyself()"
																			>
																				<div class="avatar mr-1">
																					<div
																						class="avatar-content"
																						[ngStyle]="{
																							'background-color':
																								stringToColor(
																									currentUser.first_name +
																										'' +
																										currentUser.last_name
																								)
																						}"
																					>
																						{{
																							this.currentUser
																								.first_name +
																								"" +
																								this.currentUser
																									.last_name
																								| initials
																								| slice: 0:2
																						}}
																					</div>
																				</div>
																				<div class="media-body">
																					<h5 class="mb-0">
																						{{
																							"UI.k_self_assign"
																								| translate
																						}}
																					</h5>
																					<small class="text-muted">{{
																						this.currentUser.email
																					}}</small>
																				</div>
																			</div>
																		</div>
																	</ng-template>
																</ng-select>
															</div>

															<div
																*ngIf="
																	selfAssignSel &&
																	dropdown?.current_user_group?.length > 1
																"
																class="form-group"
															>
																<label for="txtInciAssignGroup"
																	>{{ "UI.k_select_group" | translate }}
																	<span class="required-field">&nbsp;*</span>
																</label>
																<ng-select
																	[items]="dropdown.current_user_group"
																	[hideSelected]="true"
																	[multiple]="false"
																	[closeOnSelect]="true"
																	(change)="groupChange(dropdown?.current_user_group)"
																	placeholder="{{ 'UI.k_sel_group' | translate }}"
																	#TDInciAddSelGroup="ngModel"
																	bindLabel="name"
																	bindValue="team_id"
																	id="txtInciAddAssignGroup"
																	name="txtInciAddAssignGroup"
																	[(ngModel)]="addIncident.support_group"
																	[class.error]="
																		!TDInciAddSelGroup.valid &&
																		addIncidentMainForm.submitted
																	"
																	notFoundText="{{
																		'UI.k_no_items_found' | translate
																	}}"
																	required
																>
																</ng-select>
																<span
																	*ngIf="
																		addIncidentMainForm.submitted &&
																		TDInciAddSelGroup.invalid
																	"
																	class="invalid-form"
																>
																	<small
																		class="form-text text-danger"
																		*ngIf="TDInciAddSelGroup.errors.required"
																		>{{ "UI.k_field_required" | translate }}</small
																	>
																</span>
															</div>
															<div
																*ngIf="
																	selfAssignSel &&
																	dropdown?.current_user_expertise?.length > 1
																"
																class="form-group"
															>
																<label for="txtInciExpertise"
																	>{{ "UI.k_select_expertise" | translate }}
																	<span class="required-field">&nbsp;*</span>
																</label>
																<ng-select
																	[items]="dropdown?.current_user_expertise"
																	[hideSelected]="true"
																	[multiple]="false"
																	[closeOnSelect]="true"
																	[searchable]="false"
																	bindLabel="expertise"
																	bindValue="expertise"
																	#TDSelfAssignExpertise="ngModel"
																	id="txtInciExpertiseSelfAssign"
																	name="txtInciExpertiseSelfAssign"
																	(change)="
																		expertiseChange(
																			dropdown?.current_user_expertise
																		)
																	"
																	placeholder="{{
																		'UI.k_select_expertise' | translate
																	}}"
																	[(ngModel)]="addIncident.expertise"
																	notFoundText="{{
																		'UI.k_no_items_found' | translate
																	}}"
																	[class.error]="
																		!TDSelfAssignExpertise.valid &&
																		addIncidentMainForm.submitted
																	"
																	required
																>
																</ng-select>
																<span
																	*ngIf="
																		addIncidentMainForm.submitted &&
																		TDSelfAssignExpertise.invalid
																	"
																	class="invalid-form"
																>
																	<small
																		class="form-text text-danger"
																		*ngIf="TDSelfAssignExpertise.errors.required"
																		>{{ "UI.k_field_required" | translate }}</small
																	>
																</span>
															</div>
															<ng-container
																*appDnsLicenseHandler="{
																	module_key: 'BASE',
																	keys: ['TEAMS_PER_TECH']
																}"
															>
																<div
																	*ngIf="
																		!selfAssignSel &&
																		addIncident.group_type_tmp ===
																			'Operation and Technical'
																	"
																	class="form-group"
																>
																	<label for="txtInciExpertise"
																		>{{ "UI.k_expertise" | translate }}
																	</label>
																	<ng-select
																		[items]="dropdown?.expertise"
																		[hideSelected]="true"
																		[multiple]="false"
																		[closeOnSelect]="true"
																		[searchable]="false"
																		bindLabel="expertise"
																		bindValue="expertise"
																		(change)="expertiseChange(dropdown?.expertise)"
																		#TDExpertise="ngModel"
																		id="txtInciExpertise"
																		name="txtInciExpertise"
																		placeholder="{{
																			'UI.k_select_expertise' | translate
																		}}"
																		[(ngModel)]="addIncident.expertise"
																		notFoundText="{{
																			'UI.k_no_items_found' | translate
																		}}"
																		[class.error]="
																			!TDExpertise.valid &&
																			addIncidentMainForm.submitted
																		"
																	>
																	</ng-select>
																</div>

																<div
																	*ngIf="
																		!selfAssignSel &&
																		addIncident.group_type_tmp ===
																			'Operation and Technical'
																	"
																	class="form-group"
																>
																	<label for="txtInciReporterLevel"
																		>{{ "UI.k_level" | translate }}
																	</label>

																	<ng-select
																		*ngIf="!addIncident?.expertise"
																		[items]="dropdown.level"
																		[hideSelected]="true"
																		[multiple]="false"
																		[closeOnSelect]="true"
																		[searchable]="false"
																		bindLabel="level"
																		bindValue="level"
																		(change)="levelChange()"
																		id="txtInciReporterLevel"
																		name="txtInciReporterLevel"
																		[(ngModel)]="addIncident.level"
																		notFoundText="{{
																			'UI.k_no_items_found' | translate
																		}}"
																		placeholder="{{ 'UI.k_sel_level' | translate }}"
																	>
																	</ng-select>
																	<ng-container *ngIf="addIncident?.expertise">
																		<ng-select
																			[items]="dropdown.level"
																			[hideSelected]="true"
																			[multiple]="false"
																			[closeOnSelect]="true"
																			[searchable]="false"
																			#TDLevel="ngModel"
																			bindLabel="level"
																			bindValue="level"
																			(change)="levelChange()"
																			id="txtInciReporterLevel"
																			name="txtInciReporterLevel"
																			[(ngModel)]="addIncident.level"
																			notFoundText="{{
																				'UI.k_no_items_found' | translate
																			}}"
																			placeholder="{{
																				'UI.k_sel_level' | translate
																			}}"
																			[class.error]="
																				!TDLevel.valid &&
																				addIncidentMainForm.submitted
																			"
																			required
																		>
																		</ng-select>
																		<span
																			*ngIf="
																				addIncidentMainForm.submitted &&
																				TDLevel?.invalid
																			"
																			class="invalid-form"
																		>
																			<small class="form-text text-danger">{{
																				"UI.k_field_required" | translate
																			}}</small>
																		</span>
																	</ng-container>
																</div>
																<div *ngIf="!selfAssignSel" class="form-group">
																	<label for="txtInciAssignee"
																		>{{ "UI.k_assignee" | translate }}
																	</label>
																	<ng-select
																		[items]="dropdown.assignee"
																		[(ngModel)]="addIncident.assignee_profile"
																		(change)="assigneeChange()"
																		bindLabel="full_name"
																		name="txtInciAssignee"
																		id="txtInciAssignee"
																		placeholder="{{ 'UI.k_sel_team' | translate }}"
																		[ngModelOptions]="{ standalone: true }"
																		[multiple]="false"
																		#TDAssignee="ngModel"
																		[closeOnSelect]="true"
																		notFoundText="{{
																			'UI.k_no_items_found' | translate
																		}}"
																	>
																	<ng-template
																	ng-label-tmp
																	let-item="item"
																>
																<span
																		class="d-flex"
																	>
																		<span
																			class="avatar mr-50 avatar-sm"
																		>
																			<div
																				class="avatar-content"
																				[ngStyle]="{
																					'background-color': stringToColor(item.full_name)
																				}"
																			>
																				{{ item.full_name | initials | slice: 0:2 }}
																			</div>
																		</span>
																		<div class="cell-line-height">
																			<span class="font-medium-1 fw-500 line-height-1 mb-0" [ngbTooltip]="item?.full_name">
																				{{ item?.full_name | slice: 0:16 }}
																				{{ item?.full_name?.length > 16 ? ".." : "" }}
																			</span>
																		</div>
																</span>
																	</ng-template>	
																		<ng-template
																			ng-option-tmp
																			let-item="item"
																			let-index="index"
																			let-search="searchTerm"
																		>
																			<span class="d-flex align-items-center">
																				<div
																					*ngIf="
																						dropdown?.team_user_profile_map[
																							item?.profile_id
																						]?.profile_image
																					"
																				>
																					<div class="avatar mr-1">
																						<span class="avatar-content">
																							<img
																								class="rounded-circle"
																								src="{{
																									dropdown
																										?.team_user_profile_map[
																										item?.profile_id
																									]?.profile_image
																								}}"
																								alt="User"
																								width="32"
																								height="32"
																							/>
																						</span>
																					</div>
																				</div>
																				<div
																					*ngIf="
																						!dropdown
																							?.team_user_profile_map[
																							item?.profile_id
																						]?.profile_image
																					"
																				>
																					<div
																						class="avatar mr-50 ml-25"
																						[ngStyle]="{
																							'background-color': dropdown
																								?.team_user_profile_map[
																								item.profile_id
																							]?.avatar_color
																								? dropdown
																										.team_user_profile_map[
																										item?.profile_id
																								  ]?.avatar_color
																								: '#f59518'
																						}"
																						style="
																							align-content: center;
																							width: 32px;
																							height: 32px;
																						"
																					>
																						<div class="avatar-content">
																							{{
																								item.full_name
																									| initials
																									| slice: 0:2
																							}}
																						</div>
																					</div>
																				</div>
																				<div class="cell-line-height">
																					<p
																						class="font-medium-1 fw-500 line-height-1 mb-0"
																					>
																						{{
																							item?.full_name
																								| slice: 0:28
																						}}
																						{{
																							item?.full_name?.length > 28
																								? ".."
																								: ""
																						}}
																					</p>
																					<span class="text-muted sm-font">
																						{{ item?.email | slice: 0:28 }}
																						{{
																							item?.email?.length > 28
																								? ".."
																								: ""
																						}}</span
																					>
																				</div>
																			</span>
																		</ng-template>
																	</ng-select>
																</div>
															</ng-container>
															<div
																class="form-group"
																*ngIf="addIncident.support_group_tmp == 'self_assign'"
															>
																<div
																	class="p-50 d-flex align-items-center justify-content-start bg-light-secondary rounded"
																>
																	<span>
																		<i class="fa-light fa-user mr-25"></i>
																	</span>
																	<span class="text-dark">{{
																		"UI.k_user" | translate
																	}}</span>
																	<span class="text-dark">:</span>
																	<span
																		class="fw-500 text-dark"
																		name="txtCurrentUser"
																		[(ngModel)]="addIncident.support_group"
																		id="txtCurrentUser"
																		>{{ currentUser.full_name }}</span
																	>
																</div>
															</div>
															<div class="col-12 pl-0">
																<app-dns-custom-field
																	placeholder="assignment"
																	[formRef]="addIncidentMainForm"
																	[data]="custom_field_data"
																	[config]="custom_field_config"
																></app-dns-custom-field>
															</div>

															<div class="form-group">
																<div
																	class="d-flex justify-content-between align-items-center"
																>
																	<label for="selApprovers">{{
																		"UI.k_followers" | translate
																	}}</label>
																	<small
																		id="followers"
																		name="followers"
																		class="text-primary cursor-pointer"
																		(click)="updateWatcher()"
																		>{{ "UI.k_follow" | translate }}</small
																	>
																</div>
																<ng-select
																	[items]="dropdown.users"
																	[multiple]="true"
																	[closeOnSelect]="true"
																	[searchable]="true"
																	[searchFn]="customSearchFn"
																	bindLabel="full_name"
																	name="selectFollowers"
																	id="selectFollowers"
																	placeholder="{{ 'UI.k_select' | translate }} {{
																		'UI.k_followers' | translate
																	}}"
																	class=""
																	[(ngModel)]="addIncident.current_watcher"
																	hideSelected="true"
																>
																	<ng-template ng-label-tmp let-item="item">
																		<span class="d-flex align-items-center">
																			<span
																				class="avatar mr-50 avatar-sm"
																				[ngClass]="bg-light-primary"
																			>
																				<div
																					class="avatar-content"
																					[ngStyle]="{
																						'background-color':
																							stringToColor(
																								item.full_name
																							)
																					}"
																				>
																					{{
																						item.name
																							| initials
																							| slice: 0:2
																					}}
																				</div>
																			</span>
																		
																				<span
																					class="font-small-2"
																					[ngbTooltip]="item?.full_name"
																				>
																					{{ item?.full_name | slice: 0:20 }}
																					{{
																						item?.full_name?.length > 20
																							? ".."
																							: ""
																					}}
																				</span>
																			
																		</span>
																	</ng-template>
																	<ng-template
																		ng-option-tmp
																		let-item="item"
																		let-index="index"
																		let-search="searchTerm"
																	>
																		<span class="d-flex">
																			<div *ngIf="item?.profile_image">
																				<div class="avatar mr-50 ml-25">
																					<span class="avatar-content">
																						<img
																							class="rounded-circle"
																							src="{{
																								item?.profile_image
																							}}"
																							alt="User"
																							width="32"
																							height="32"
																						/>
																					</span>
																				</div>
																			</div>
																			<div *ngIf="!item?.profile_image">
																				<div
																					class="avatar mr-50 ml-25"
																					[ngStyle]="{
																						'background-color':
																							item.avatar_color
																								? item.avatar_color
																								: '#f59518'
																					}"
																					style="
																						align-content: center;
																						width: 32px;
																						height: 32px;
																					"
																				>
																					<div class="avatar-content">
																						{{
																							item.full_name
																								| initials
																								| slice: 0:2
																						}}
																					</div>
																				</div>
																			</div>

																			<div class="cell-line-height">
																				<p
																					class="font-medium-1 font-weight-bold line-height-1 mb-0"
																				>
																					{{ item?.full_name | slice: 0:28 }}
																					{{
																						item?.full_name?.length > 28
																							? ".."
																							: ""
																					}}
																				</p>
																				<span class="text-muted font-small-2">
																					{{ item?.email | slice: 0:28 }}
																					{{
																						item?.email?.length > 28
																							? ".."
																							: ""
																					}}</span
																				>
																			</div>
																		</span>
																	</ng-template>
																</ng-select>
															</div>
														</div>

														<div class="card border-top border-bottom mb-0 square">
															<div class="card-body pl-0 pt-1 pr-0 pb-1">
																<div class="d-flex align-items-center mb-1">
																	<span
																		class="text-left fw-500 title-color reg-font mr-50"
																		>{{ "UI.k_attachment" | translate }}</span
																	>
																</div>

																<div
																	class="d-flex justify-content-center mt-1"
																	*ngIf="
																		this.filesList?.length == 0 || !this.filesList
																	"
																>
																	<img
																		class="rounded"
																		src="assets/images/incident/files_empty_state.svg"
																		alt="nodata-bg"
																		width="200"
																		height="106"
																	/>
																</div>
																<div
																	class="d-flex justify-content-center mt-1"
																	*ngIf="
																		this.filesList?.length == 0 || !this.filesList
																	"
																>
																	{{ "UI.k_no_attachment" | translate }}
																</div>
																<div
																	*ngFor="let file of this.filesList; let i = index"
																	class="category-attachment-list d-flex justify-content-between align-items-baseline border-bottom py-50"
																>
																	<div
																		id="attachment-{{ file.id }}"
																		class="left-info-sec d-flex"
																	>
																		<div class="text-center">
																			<div
																				class="avatar"
																				[ngClass]="
																					file.file_name.split('.')[1] ==
																					'txt'
																						? 'bg-light-primary'
																						: file.file_name.split(
																								'.'
																						  )[1] == 'pdf'
																						? 'bg-light-danger'
																						: file.file_name.split(
																								'.'
																						  )[1] == 'xls' ||
																						  file.name == 'xlsx'
																						? 'bg-light-success'
																						: file.file_name.split(
																								'.'
																						  )[1] == 'doc' ||
																						  file.file_name.split(
																								'.'
																						  )[1] == 'docx'
																						? 'bg-light-info'
																						: 'bg-light-primary'
																				"
																			>
																				<div
																					class="avatar-content"
																					id="feather_size"
																				>
																					<i
																						[ngClass]="
																							file.file_name.split(
																								'.'
																							)[1] == 'txt'
																								? 'fa-file-lines'
																								: file.file_name.split(
																										'.'
																								  )[1] == 'pdf'
																								? 'fa-file-pdf'
																								: file.file_name.split(
																										'.'
																								  )[1] == 'xls' ||
																								  file.file_name.split(
																										'.'
																								  )[1] == 'xlsx'
																								? 'fa-file-excel'
																								: file.file_name.split(
																										'.'
																								  )[1] == 'doc' ||
																								  file.file_name.split(
																										'.'
																								  )[1] == 'docx'
																								? 'fa-file-word'
																								: 'fa-file'
																						"
																						class="fa-light lg-font"
																						aria-hidden="true"
																					></i>
																				</div>
																			</div>
																		</div>
																		<div class="media-body d-flex flex-column justify-content-between">
																			<span
																			class="sm-font overflow-wrap file_position title-color xs-font mb-0"
																		>
																			<span
																				class="fw-500 mr-50 file-name"
																				tooltipClass="fadeInAnimation"
																				ngbTooltip="{{ file.file_name }}"
																				>{{ file.file_name }}</span
																			><span class="file-version">v{{ file.version }}</span>
																		</span>
																			<span class="pl-50 xs-font line-height-1"
																				>{{
																					file.file_size / 1024 / 1024
																						| number: ".2"
																				}}
																				MB
																				<b class="fw-600 xl-font">.</b>
																				{{
																					file.file_date
																						| date
																							: "d MMM y" ||
																									file.file_date
																						| date: "d MMM y"
																				}}</span
																			>
																		</div>
																	</div>
																	<div
																		class="right-btn-sec d-flex justify-content-center align-items-center"
																	>
																		<span
																			*ngIf="file?.description"
																			class="pull-right"
																			tooltipClass="fadeInAnimation"
																			ngbTooltip="{{ file.description }}"
																			container="body"
																		>
																			<i
																				class="fa-light fa-info-circle mr-half avatar-icon font-small-3 text-primary"
																			></i>
																		</span>
																		<span
																			class="pull-right"
																			id="btnRemoveMail"
																			(click)="removeMainFile(i)"
																			tooltipClass="fadeInAnimation"
																			ngbTooltip="{{ 'UI.k_delete' | translate }}"
																			container="body"
																		>
																			<i
																				class="fa-light fa-trash-can mr-half cursor-pointer avatar-icon font-small-4 text-danger"
																			></i
																		></span>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</ng-scrollbar>
											</div>
											<div class="middle-sec">
												<div
													id="divChangeWidth"
													class="flex-auto-height"
													[ngClass]="showHide === true ? '' : ''"
												>
													<div class="border-bottom p-1 flex-initial">
														<div class="input-group">
															<div class="input-group-prepend">
																<span
																	class="input-group-text bg-light-secondary"
																	id="basic-addon-search1"
																	>{{ "UI.k_subject" | translate
																	}}<span class="required-field">&nbsp;*</span>
																</span>
															</div>
															<input
																type="text"
																class="form-control"
																placeholder="												
																{{ 'UI.k_incident_subject' | translate }}"
																id="txtSubject"
																name="txtSubject"
																minlength="3"
																maxlength="1000"
																[(ngModel)]="addIncident.summary"
																#TDSubjectRef="ngModel"
																[class.error]="
																	!TDSubjectRef.valid && addIncidentMainForm.submitted
																"
																[appWhiteSpaceCheck]="addIncident.summary"
																required
															/>
														</div>
														<div
															*ngIf="
																addIncidentMainForm.submitted && TDSubjectRef.invalid
															"
															class="invalid-form"
														>
															<small
																class="form-text text-danger xs-font"
																*ngIf="TDSubjectRef.errors.required"
																>{{ "UI.k_field_required" | translate }}
															</small>
															<small
																class="form-text text-danger xs-font"
																*ngIf="
																	TDSubjectRef.errors.minlength ||
																	TDSubjectRef.errors.maxlength
																"
																>{{ "UI.k_title_name_validation" | translate }}
															</small>
															<small
																class="form-text text-danger xs-font"
																*ngIf="TDSubjectRef.errors?.whitespace"
																>{{
																	"UI.k_leading_trailing_space_validation" | translate
																}}</small
															>
														</div>
													</div>
													<div class="mb-25 flex-1">
														<ng-scrollbar class="custom-scrollbar section-scrollbar">
															<div class="p-1">
																<div class="d-flex wrap">
																	<div class="p-0">
																		<div>
																			<span>
																				{{ typeDisplayName }}
																				<span class="required-field"
																					>&nbsp;*</span
																				>
																			</span>
																			<!-- <button
																				class="btn btn-flat-secondary bg-transparent title-color"
																				type="button"
																				id="btnRecentAlert"
																				name="recentAlert"
																				rippleEffect
																			>
																				{{ typeDisplayName }} <span
																				class="required-field"
																				>&nbsp;*</span>
																			</button> -->
																		</div>
																	</div>
																</div>

																<div class="p-0 card border quill-card">
																	<quill-editor
																		id="inci-add-tiny"
																		[(ngModel)]="addIncident.erichTextData"
																		(onContentChanged)="getEnrichTextData($event)"
																		id="articleNote"
																		name="articleNote"
																		placeholder="{{
																			'UI.k_insert_text_here' | translate
																		}}..."
																		[minLength]="3"
																		[required]="true"
																		[trimOnValidation]="true"
																		[appQuillValidator]="qlInciDescr"
																		[appQuillForm]="addIncidentMainForm"
																		#qlInciDescr
																		[class.error]="
																			qlInciDescr?.errors &&
																			addIncidentMainForm.submitted
																		"
																	>
																		<div
																			quill-editor-toolbar
																			class="border-0 border-bottom px-0 py-50"
																		>
																			<div class="border-bottom p-0">
																				<span class="ql-formats pb-50">
																					<button
																						id="qlBoldNoteEmail-Inc"
																						name="qlBold"
																						class="ql-bold"
																						title="{{
																							'UI.k_bold' | translate
																						}}"
																					></button>
																					<button
																						id="qlItalicNoteEmail-Inc"
																						name="qlItalic"
																						class="ql-italic"
																						title="{{
																							'UI.k_italic' | translate
																						}}"
																					></button>
																					<button
																						id="qlUnderlineNoteEmail-Inc"
																						name="qlUnderline"
																						class="ql-underline"
																						title="{{
																							'UI.k_underline' | translate
																						}}"
																					></button>
																				</span>
																				<span class="ql-formats">
																					<select
																						id="qlAlignNote"
																						class="ql-align"
																						title="{{
																							'UI.k_alignment' | translate
																						}}"
																					>
																						<option selected></option>
																						<option value="center"></option>
																						<option value="right"></option>
																						<option
																							value="justify"
																						></option>
																					</select>
																				</span>
																				<span class="ql-formats">
																					<button
																						id="btnqlListNote"
																						name="qlList"
																						class="ql-list"
																						value="ordered"
																						type="button"
																					></button>
																				</span>
																				<span class="ql-formats">
																					<button
																						id="btnqlLinkNoteEmail-Inc"
																						class="ql-link"
																						value="ordered"
																						type="button"
																					></button>
																					<button
																						id="btnqlImageNoteEmail-Inc"
																						class="ql-image"
																						value="ordered"
																						type="button"
																					></button>
																					<button
																						id="btnqlVideoNoteEmail-Inc"
																						class="ql-video"
																						value="ordered"
																						type="button"
																					></button>
																				</span>
																				<span class="ql-formats">
																					<button
																						id="btn-ql-formula-incid-Descr"
																						class="ql-formula"
																						value="ordered"
																						type="button"
																					></button>
																					<button
																						id="btn-ql-code-block-serviceDescr"
																						class="ql-code-block"
																						value="ordered"
																						type="button"
																					></button>
																				</span>
																				<span class="ql-formats">
																					<button
																						id="btnqlCleanNoteEmail-Inc"
																						class="ql-clean"
																						value="ordered"
																						type="button"
																					></button>
																				</span>
																			</div>
																		</div>
																	</quill-editor>
																	<div class="pl-2 pr-2 row">
																		<span
																			*ngFor="
																				let file of filesList;
																				let i = index
																			"
																			class="text-primary border rounded m-half p-25"
																			><i class="fa-light fa-file-lines"></i
																			>&nbsp; {{ file.file_name | slice: 0:35}} {{ file.file_name.length > 35 ? ".." : " "}} &nbsp;
																			<span
																				(click)="
																					removeAttachedFile(i, filesList)
																				"
																				id="btnFileAttachmentClose{{ i }}"
																			>
																				<i class="fa-light fa-xmark"></i>
																			</span>
																		</span>
																	</div>
																	<div
																		class="compose-footer-wrapper email-footer p-75 d-flex"
																	>
																		<div
																			class="btn-wrapper d-flex align-items-center"
																		>
																			<!-- add attachment -->
																			<div class="email-attachement">
																				<label
																					for="file-input"
																					class="add_attachment"
																				>
																					<img
																						src="assets/images/icons/icon_link.svg"
																						width="12"
																						height="12"
																					/>
																					<span
																						class="fw-500 text-primary sm-font ml-50 cursor-pointer"
																						>{{
																							"UI.k_add_attachment"
																								| translate
																						}}
																					</span>
																				</label>
																				<input
																					(change)="fileUpload($event)"
																					id="file-input"
																					type="file"
																					class="d-none"
																				/>
																			</div>
																		</div>
																		<br />
																	</div>
																	<div
																		class="error"
																		*ngIf="
																			addIncidentMainForm.submitted &&
																			qlInciDescr?.errors
																		"
																	>
																		<small
																			*ngIf="
																				qlInciDescr?.errors?.requiredError
																					?.empty
																			"
																		>
																			{{ "UI.k_field_required" | translate }}
																		</small>
																		<small
																			*ngIf="qlInciDescr?.errors?.minLengthError"
																		>
																			{{
																				"Error.err_min_3_character" | translate
																			}}
																		</small>
																	</div>
																</div>
																<div class="col-12 pl-0">
																	<app-dns-custom-field
																		placeholder="general"
																		[formRef]="addIncidentMainForm"
																		[data]="custom_field_data"
																		[config]="custom_field_config"
																	></app-dns-custom-field>
																</div>
															</div>
														</ng-scrollbar>
													</div>
													<div class="border-top card-bg mb-25 flex-initial">
														<div class="p-1">
															<div class="d-flex justify-content-end">
																<div ngbDropdown class="btn-group">
																	<button
																		id="btnSubmitNew"
																		type="submit"
																		name="btnSubmitNew"
																		(click)="onSubmitNew(addIncidentMainForm)"
																		class="btn btn-primary"
																		rippleEffect
																		[disabled]="disableSaveBtn"
																	>
																		{{ "UI.k_submit_new" | translate }}
																	</button>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
