
<div class="d-flex w-100 justify-content-between">
    <button *ngIf="!presentationMode" type="button" class="btn btn-icon btn-flat-primary" (click)="openFullscreen()" rippleEffect>
        <i class="fa-light fa-expand-wide"></i>
    </button>
    <!--<button *ngIf="presentationMode" type="button" class="btn btn-icon btn-flat-primary mt-1" (click)="openFullscreen()" rippleEffect>-->
        <!--<i class="fa-light fa-compress-wide fa-lg"></i>-->
    <!--</button>-->
    <button *ngIf="presentationMode" type="button" class="btn btn-icon" (click)="openFullscreen()" rippleEffect>
        <i class="fa-light fa-xmark fa-lg"></i>
    </button>
</div>
