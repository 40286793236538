<div class="full-body-height">
    <div class="main-content flex-height">
        <div class="main-sec">
            <div class="action-bar">
                <div class="inner-section">
                    <div class="left-section">
                        <div class="action-search">
							<app-search
								(getfilterData)="getFilterSearch($event)"
								(genericFilter)="genericSearchFilter($event)"
								[showDataTable]="showDataTable"
								[columnOptions]="searchOptions"
								[conditionOptions]="conditionOptions"
								[showRightsection]="showRightsection"
								[savedData]="savedData"
								[Options]="dropdownOptions"
								[module]="module"
								(isExpandSearchWidth)="getSearchWidth($event)"
							></app-search>
						</div>
                    </div>
                    <div class="right-section">
                        <div class="pull-right align-items-center d-flex p-0 m-0">
                            <button
                               *ngIf="permissions && permissions?.add && userData.length > 0"
                                rippleeffect=""
                                tooltipclass="fadeInAnimation"
                                data-keyboard="false"
                                id="assign_user"
                                class="btn bt-sm btn-primary waves-effect waves-float waves-light"
                                [attr.data-testid]="'assign_user'"
                                (click)="unAssignUser()"
                            >
                                <i class="fa-light fa-plus font-small-3"></i>&nbsp;
                                <span class="d-none d-sm-inline-block font-small-3">{{ "UI.k_assign_users" | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="action-section">
                <div *ngIf = "userData.length > 0" class="table-view">
                    <dns-server-grid
						[rows]="userData"
						[columns]="columnData"
						[count]="ciCount"
						[offset]="pageNumberGrid"
					    [loadingIndicator]="true"
						[permissions]="permissions"
						class="bootstrap core-bootstrap d-grid flex-auto-height"
					>
					</dns-server-grid>
                </div>
                <ng-container *ngIf = "userData === undefined || userData.length === 0">
                    <app-dns-empty-grid-data [emptyData]="empty_data" [permissions]="permissions"></app-dns-empty-grid-data>
                </ng-container>
            </div>
        </div>
    </div>
</div>
