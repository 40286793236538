import { Injectable } from '@angular/core';
import { TreeviewItem,TreeItem } from 'ngx-treeview';
@Injectable({
  providedIn: 'root'
})
export class TreeDataService {

  constructor() { }

  getBooks(): TreeviewItem[] {
    const childrenCategory = new TreeviewItem({
      text: 'Children', value: 1, collapsed: true, children: [
        { text: 'Baby 3-5', value: 11 },
        { text: 'Baby 6-8', value: 12 },
        { text: 'Baby 9-12', value: 13 }
      ]
    });
    const itCategory = new TreeviewItem({
      text: 'IT', value: 9, children: [
        {
          text: 'Programming', value: 91, children: [{
            text: 'Frontend', value: 911, children: [
              { text: 'Angular 1', value: 9111 },
              { text: 'Angular 2', value: 9112 },
              { text: 'ReactJS', value: 9113 }
            ]
          }, {
            text: 'Backend', value: 912, children: [
              { text: 'C#', value: 9121 },
              { text: 'Java', value: 9122 },
              { text: 'Python', value: 9123 }
            ]
          }]
        },
        {
          text: 'Networking', value: 92, children: [
            { text: 'Internet', value: 921 },
            { text: 'Security', value: 922 }
          ]
        }
      ]
    });
    // const teenCategory = new TreeviewItem({
    //   text: 'Teen', value: 2, collapsed: true, disabled: true, children: [
    //     { text: 'Adventure', value: 21 },
    //     { text: 'Science', value: 22 }
    //   ]
    // });
    // const othersCategory = new TreeviewItem({ text: 'Others', value: 3, checked: false, disabled: true });
    // return [childrenCategory, itCategory, teenCategory, othersCategory];

    return [childrenCategory, itCategory];

  }

  getProducts(): TreeviewItem[] {
    const fruitCategory = new TreeviewItem({
      text: 'Fruit', value: 1, checked: false, children: [
        {
          text: 'Apple', value: 11, checked: false,
          children: [
            { text: 'A1', checked: false, value: 'a1' },
            { text: 'B1', checked: false, value: 'b1' },
            { text: 'C1', checked: false, value: 'c1' },

          ]
        },
        { text: 'Mango', checked: false, value: 12 }
      ]
    });
    const vegetableCategory = new TreeviewItem({
      text: 'Vegetable', value: 2, checked: false, children: [
        { text: 'Salad', checked: false, value: 21 },
        { text: 'Potato', checked: false, value: 22 }
      ]
    });
    vegetableCategory.children.push(new TreeviewItem({ text: 'Mushroom', value: 23, checked: false }));
    vegetableCategory.correctChecked(); // need this to make 'Vegetable' node to change checked value from true to false
    return [fruitCategory, vegetableCategory];
  }
  getEmailTemplate(): TreeviewItem[] {
    const customerNotResponding = new TreeviewItem({
      text: 'Customer not responding', value: 1, collapsed: true, children: [
        { text: 'Customer not responding #1', value: 11 },
        { text: 'Customer not responding #2', value: 12 },
      ]
    });
    const resolveTickets = new TreeviewItem({
      text: 'Resolve tickets', value: 2, collapsed: true, children: [
        { text: 'Resolve tickets #1', value: 21 },
        { text: 'Resolve tickets #2', value: 22 },
      ]
    });
    const downgradeInform = new TreeviewItem({
      text: 'Downgrade and inform', value: 3, collapsed: true, children: [
        { text: 'Downgrade and inform #1', value: 31 },
        { text: 'Downgrade and inform #2', value: 32 },
      ]
    });

    return [customerNotResponding, resolveTickets,downgradeInform];

  }
  // getSignature(): TreeItem[] {
  //   const signature :TreeItem[] = [
  //      {
  //         text:'signature',
  //         value:1,

  //      },
  //      {
  //       text:'signature',
  //       value:2,
  //     }
  //   ]
   

  //   return signature;

  // }
  getSignature(): TreeviewItem[] {
    const items: TreeviewItem[] = [];
    for (let i = 0; i < 10; i++) {
      const value: any = i;
      const item = new TreeviewItem({
        text: `Signature ${i}`,
        value
      });
      items.push(item);
    }

    return items;
  }
  
}
