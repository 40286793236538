<div class="full-body-height">
	<div class="main-content flex-height map-content">
		<div class="main-sec">
			<div class="action-bar">
				<div class="inner-section">
					<div class="left-section">
						<div class="form-group mb-0">
							<ng-select
								*ngIf="disableMapOtions == 0"
								class="form-control"
								[items]="mapOptions"
								[clearable]="false"
								[(ngModel)]="selectedMap"
								bindLabel="name"
								name="selMapOption"
								id="selMapOption"
								placeholder="{{ 'UI.k_search' | translate }}"
								#parentClass="ngModel"
								(change)="changeMapOption($event, zoom_level)"
							>
							</ng-select>
						</div>
						<div class="form-group mb-0 ml-1">
							<!-- <ng-select
						class="form-control"
						[items]="locationData"
						bindLabel="name"
						bindValue="geometry.coordinates"
						name="selNodeSearch"
						id="selNodeSearch"
						placeholder="{{
							'UI.k_search' | translate
						}}"
						(change)="changeNodeSearch($event)"
					>
					</ng-select> -->
						</div>
					</div>
					<div class="right-section">
						<div class="zoom-button d-flex">
							<button
								type="button"
								class="btn btn-icon btn-flat-primary"
								(click)="resetgeomap()"
								ngbTooltip="{{ 'UI.k_reset' | translate }}"
								rippleEffect
							>
								<i class="fa-light fa-undo fa-lg font-small-4"></i>
							</button>
							<app-presentation></app-presentation>
						</div>
					</div>
				</div>
			</div>
			<div class="action-section">
				<div class="customised-map">
					<div id="olMap" class="olMap" style="height: 100%; width: 100%">
						<div id="tooltip"></div>
						<!-- <div id="info"></div></div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
