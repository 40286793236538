<div class="slideout-content default-stepping">
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
		<div class="modal-dialog sidebar-custom">
			<!-- form-width -->
			<div class="modal-content pt-0">
				<div class="modal-header">
					<h5 class="modal-title" id="addTitle">
						{{
							(action !== "edit" ? "Message.msg_add_organization" : "Message.msg_edit_organization")
								| translate
						}}
					</h5>
					<button
						type="button"
						id="btnClose"
						name="btnClose"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						(click)="toggleSidebar('add-organization')"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body">
					<!-- Modern Horizontal Wizard -->
					<section class="modern-horizontal-wizard full-height">
						<div id="stepper3" class="bs-stepper wizard-modern modern-wizard-example">
							<div class="bs-stepper-header border-bottom">
								<div id="divContentTab" name="divContentTab" class="step" data-target="#basic-detail">
									<button type="button" class="step-trigger">
										<span class="bs-stepper-box align-items-center"> 1 </span>
										<span class="bs-stepper-label">
											<span class="bs-stepper-title">{{ "UI.k_org_details" | translate }}</span>
											<!-- <span class="bs-stepper-subtitle">
												{{ "UI.k_basic" | translate }} {{ "UI.k_details " | translate }}
											</span> -->
										</span>
									</button>
								</div>
								<div class="line">
									<i class="fa-light fa-chevron-right font-medium-2"></i>
								</div>
								<div id="divDetail" name="divDetail" class="step" data-target="#license-details">
									<button type="button" class="step-trigger">
										<span class="bs-stepper-box align-items-center"> 2 </span>
										<span class="bs-stepper-label">
											<span class="bs-stepper-title">{{ "UI.k_plan_details" | translate }}</span>
											<!-- <span class="bs-stepper-subtitle"
												>{{ "UI.k_license" | translate }} {{ "UI.k_information " | translate }}
											</span> -->
										</span>
									</button>
								</div>
								<div class="line">
									<i class="fa-light fa-chevron-right font-medium-2"></i>
								</div>
								<div
									id="divUserDetail"
									name="divUserDetail"
									class="step"
									data-target="#download-details"
								>
									<button type="button" class="step-trigger">
										<span class="bs-stepper-box align-items-center"> 3 </span>
										<span class="bs-stepper-label">
											<span class="bs-stepper-title">{{ "UI.k_license_info" | translate }} </span>
											<!-- <span class="bs-stepper-subtitle"
												>{{ "UI.k_license" | translate }} {{ "UI.k_download" | translate }}
												{{ "UI.k_details" | translate }}
											</span> -->
										</span>
									</button>
								</div>
							</div>
							<div class="bs-stepper-content card-bg">
								<!-- <form #kbAddForm="ngForm" class="px-2 form-validate">	 -->
								<div class="bs-stepper-inner">
									<div id="basic-detail" class="content">
										<form
											[formGroup]="basicDetailForm"
											class="stepper-inner"
											(keydown.enter)="$event.preventDefault()"
										>
											<div class="stepper-body">
												<ng-scrollbar class="custom-scrollbar section-scrollbar">
													<div class="stepper-main">
														<!-- <div class="fileUploader">
															<app-profile-avatar
																(avatarDetails)="profileImage($event)"
																(resetImage)="removeImage($event)"
																(avatarBgColor)="ProfileBgColor($event)"
																[profileName]="fullName"
																[profile_image]="currentImgDetails"
																[avatarBackgroundColor]="currentAvatarColor"
																[activeColor]="activeAvatar"
																(currentAvatar)="currrentColor($event)"
															>
															</app-profile-avatar>
														</div> -->
														<div class="row">
															<div class="col-sm-6">
																<div class="form-group">
																	<label class="form-label" for="txtRegCmp"
																		>{{ "UI.k_company_name" | translate }}
																		<span class="required-field">&nbsp;*</span>
																	</label>
																	<input
																		class="form-control lg-form-control"
																		id="txtRegCmp"
																		type="text"
																		name="txtRegCmp"
																		placeholder="{{
																			'UI.k_company_placeholder' | translate
																		}}"
																		aria-describedby="txtRegCmp"
																		autofocus=""
																		minlength="3"
																		maxlength="50"
																		[class.error]="
																			submitted &&
																			basicDetailFormControl.company_name.errors
																		"
																		formControlName="company_name"
																		required
																		[(ngModel)]="organization.company_name"
																		tabindex="1"
																	/>
																	<span
																		*ngIf="false"
																		class="spinner-border spinner-border-sm mr-1 email-check-loader"
																	></span>
																	<span
																		*ngIf="false"
																		class="mr-1 email-check-loader c-is-valid"
																	>
																		<img
																			src="assets/images/icons/checked-round.svg"
																		/>
																	</span>
																	<span
																		*ngIf="
																			submitted &&
																			basicDetailFormControl.company_name.errors
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="
																				basicDetailFormControl.company_name
																					.errors.required
																			"
																			>{{
																				"UI.k_field_required" | translate
																			}}</small
																		>
																		<small
																			class="form-text text-danger"
																			*ngIf="
																				basicDetailFormControl.company_name
																					.errors.minlength ||
																				basicDetailFormControl.company_name
																					.errors.maxlength
																			"
																		>
																			{{
																				"UI.k_company_validation" | translate
																			}}</small
																		>
																		<small
																			class="form-text text-danger"
																			*ngIf="
																				basicDetailFormControl.company_name
																					.errors.whitespace
																			"
																		>
																			{{
																				"UI.k_company_min_validation"
																					| translate
																			}}</small
																		>
																	</span>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="form-group">
																	<label for="selCompanySize" class="form-label">{{
																		"UI.k_company_size" | translate
																	}}</label>
																	<ng-select
																		[items]="dropdownOptions?.companySizes"
																		[closeOnSelect]="true"
																		[searchable]="false"
																		bindLabel="label"
																		bindValue="value"
																		placeholder="{{
																			'UI.k_select_company_size' | translate
																		}}"
																		class="form-control"
																		name="selCompanySize"
																		id="selCompanySize"
																		formControlName="company_size"
																		[(ngModel)]="organization.company_size"
																		notFoundText="{{
																			'UI.k_no_items_found' | translate
																		}}"
																		[hideSelected]="true"
																		tabindex="2"
																	>
																	</ng-select>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="form-group">
																	<label class="form-label" for="txtFName"
																		>{{ "UI.k_organization_admin" | translate }}
																		<span class="required-field"
																			>&nbsp;*</span
																		></label
																	>
																	<input
																		class="form-control lg-form-control"
																		id="txtFName"
																		type="text"
																		formControlName="fullname"
																		placeholder="{{
																			'UI.k_full_name_placeholder' | translate
																		}}"
																		aria-describedby="txtFName"
																		autofocus=""
																		minlength="3"
																		maxlength="100"
																		[class.error]="
																			submitted &&
																			basicDetailFormControl.fullname.errors
																		"
																		required
																		[(ngModel)]="organization.full_name"
																		tabindex="3"
																	/>
																	<span
																		*ngIf="false"
																		class="spinner-border spinner-border-sm mr-1 email-check-loader"
																	></span>
																	<span
																		*ngIf="false"
																		class="mr-1 email-check-loader c-is-valid"
																	>
																		<img
																			src="assets/images/icons/checked-round.svg"
																		/>
																	</span>
																	<span
																		*ngIf="
																			submitted &&
																			basicDetailFormControl.fullname.errors
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="
																				basicDetailFormControl.fullname.errors
																					.required
																			"
																			>{{
																				"UI.k_field_required" | translate
																			}}</small
																		>
																		<small
																			class="form-text text-danger"
																			*ngIf="
																				basicDetailFormControl.fullname.errors
																					.minlength ||
																				basicDetailFormControl.fullname.errors
																					.maxlength
																			"
																		>
																			{{
																				"UI.k_fullname_validation" | translate
																			}}</small
																		>
																		<small
																			class="form-text text-danger"
																			*ngIf="
																				basicDetailFormControl.fullname.errors
																					.whitespace
																			"
																		>
																			{{
																				"UI.k_full_name_min" | translate
																			}}</small
																		>
																	</span>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="form-group">
																	<label class="form-label" for="txtEmail"
																		>{{ "UI.k_business_email" | translate
																		}}<span class="required-field">&nbsp;*</span>
																	</label>
																	<input
																		class="form-control lg-form-control"
																		[class.is-valid]="false"
																		id="txtEmail"
																		(blur)="checkEmailAvailability()"
																		formControlName="business_email"
																		type="text"
																		name="txtEmail"
																		placeholder="{{
																			'UI.k_business_placeholder' | translate
																		}}"
																		aria-describedby="txtEmail"
																		[class.is-invalid]="
																			basicDetailFormControl.business_email
																				.touched &&
																			(submitted || !isEmailAvailable) &&
																			basicDetailFormControl.business_email.errors
																		"
																		required
																		pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$"
																		[(ngModel)]="organization.business_email"
																		tabindex="4"
																	/>
																	<span
																		*ngIf="emailCheckLoading"
																		class="spinner-border spinner-border-sm mr-1 email-check-loader"
																	></span>
																	<span
																		*ngIf="false"
																		class="mr-1 email-check-loader c-is-valid"
																	>
																		<img
																			src="assets/images/icons/checked-round.svg"
																		/>
																	</span>
																	<span
																		*ngIf="
																			submitted &&
																			basicDetailFormControl.business_email.errors
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="
																				basicDetailFormControl.business_email
																					.errors.required
																			"
																			>{{
																				"UI.k_field_required" | translate
																			}}</small
																		>
																	</span>
																	<span
																		*ngIf="
																			basicDetailFormControl.business_email.errors
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="
																				basicDetailFormControl.business_email
																					.errors.email_exists
																			"
																			>{{ emailExistsMessage }}</small
																		>
																	</span>
																	<span
																		*ngIf="
																			basicDetailFormControl.business_email
																				.errors &&
																			(basicDetailFormControl.business_email
																				.touched ||
																				basicDetailFormControl.business_email
																					.dirty)
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			[hidden]="
																				!basicDetailFormControl.business_email
																					.errors?.pattern
																			"
																		>
																			{{ "Error.err_invalid_email" | translate }}
																		</small>
																	</span>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="form-group">
																	<label class="form-label" for="txtRegPhone"
																		>{{ "UI.k_phone_no" | translate
																		}}<span class="required-field">&nbsp;*</span>
																	</label>
																	<div class="w-100">
																		<ngx-intl-tel-input
																			class="w-100"
																			[cssClass]="
																				'custom form-control w-100 lg-form-control'
																			"
																			[preferredCountries]="preferredCountries"
																			[enableAutoCountrySelect]="true"
																			[enablePlaceholder]="true"
																			[searchCountryFlag]="true"
																			[selectedCountryISO]="
																				CountryISO.UnitedStates
																			"
																			[searchCountryField]="[
																				SearchCountryField.Iso2,
																				SearchCountryField.Name
																			]"
																			[selectFirstCountry]="true"
																			[maxLength]="15"
																			[phoneValidation]="true"
																			[separateDialCode]="separateDialCode"
																			[numberFormat]="PhoneNumberFormat.National"
																			name="txtRegPhone"
																			id="txtRegPhone"
																			formControlName="phone"
																			[ngClass]="{
																				'is-invalid':
																					submitted &&
																					basicDetailFormControl.phone.errors
																			}"
																			[(ngModel)]="organization.phone"
																			tabindex="5"
																		>
																			<!-- [selectedCountryISO]="CountryISO.India" -->
																			<!-- [selectedCountryISO]="CountryISO.India"  make this dynamic based on country api hit : shyam change this later-->
																		</ngx-intl-tel-input>
																	</div>
																	<span
																		*ngIf="false"
																		class="spinner-border spinner-border-sm mr-1 email-check-loader"
																	></span>
																	<span
																		*ngIf="false"
																		class="mr-1 email-check-loader c-is-valid"
																	>
																		<img
																			src="assets/images/icons/checked-round.svg"
																		/>
																	</span>
																	<span
																		*ngIf="
																			submitted &&
																			basicDetailFormControl.phone.errors
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="
																				basicDetailFormControl.phone.errors
																					.required
																			"
																			>{{
																				"UI.k_field_required" | translate
																			}}</small
																		>
																	</span>
																	<span
																		*ngIf="
																			basicDetailFormControl.phone.errors &&
																			basicDetailFormControl.phone.touched &&
																			basicDetailFormControl.phone.dirty
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			[hidden]="
																				!basicDetailFormControl.phone.errors
																			"
																		>
																			{{
																				"UI.k_phone_number_validation"
																					| translate
																			}}
																		</small>
																	</span>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="form-group">
																	<label for="selRegion" class="form-label">{{
																		"UI.k_region" | translate
																	}}</label>
																	<ng-select
																		[items]="dropdownOptions?.regions"
																		[closeOnSelect]="true"
																		bindLabel="region"
																		bindValue="dc_id"
																		placeholder="{{
																			'UI.k_select_center' | translate
																		}}"
																		class="form-control"
																		name="selRegion"
																		id="selRegion"
																		formControlName="datacenter"
																		[(ngModel)]="organization.datacenter"
																		notFoundText="{{
																			'UI.k_no_items_found' | translate
																		}}"
																		[hideSelected]="true"
																		[searchable]="true"
																		[clearable]="false"
																		tabindex="6"
																	>
																	</ng-select>
																</div>
															</div>
															<div class="col-sm-6" *ngIf="!isPartner">
																<div class="form-group">
																	<label for="selHostType" class="form-label"
																		>{{ "UI.k_host_type" | translate }}
																	</label>
																	<ng-select
																		[items]="dropdownOptions?.hostTypes"
																		[closeOnSelect]="true"
																		[searchable]="false"
																		bindLabel="label"
																		bindValue="value"
																		placeholder="{{ 'UI.k_host_type' | translate }}"
																		class="form-control"
																		name="selHostType"
																		id="selHostType"
																		[(ngModel)]="organization.host_type"
																		notFoundText="{{
																			'UI.k_no_items_found' | translate
																		}}"
																		[hideSelected]="true"
																		formControlName="hostType"
																		[clearable]="false"
																		[readonly]="currentEvent === 'edit'"
																		tabindex="7"
																	>
																	</ng-select>
																</div>
															</div>
															<div class="col-sm-6" *ngIf="!isPartner">
																<div class="form-group">
																	<label for="selPartner" class="form-label"
																		>{{ "UI.k_partner" | translate }}
																	</label>
																	<ng-select
																		[items]="dropdownOptions?.partners"
																		[closeOnSelect]="true"
																		[searchable]="true"
																		bindLabel="partner_name"
																		bindValue="partner_id"
																		placeholder="{{
																			'Message.msg_sel_partner' | translate
																		}}"
																		class="form-control"
																		name="selPartner"
																		id="selPartner"
																		[(ngModel)]="organization.partner"
																		notFoundText="{{
																			'UI.k_no_items_found' | translate
																		}}"
																		[hideSelected]="true"
																		formControlName="partner"
																		[clearable]="true"
																		tabindex="8"
																	>
																	</ng-select>
																	<!-- <span
																		*ngIf="
																			submitted &&
																			basicDetailFormControl.partner.errors
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="
																				basicDetailFormControl.partner.errors
																					.required
																			"
																			>{{
																				"UI.k_field_required" | translate
																			}}</small
																		>
																	</span> -->
																</div>
															</div>

															<div class="col-sm-6">
																<div class="form-group">
																	<label class="form-label" for="txtComment"
																		>{{ "UI.k_comment" | translate }}
																	</label>
																	<input
																		class="form-control lg-form-control"
																		id="txtComment"
																		type="text"
																		name="txtComment"
																		placeholder="{{
																			'Message.msg_leave_comment' | translate
																		}}"
																		aria-describedby="txtComment"
																		autofocus=""
																		tabindex="9"
																		maxlength="50"
																		formControlName="comment"
																		[(ngModel)]="organization.comment"
																	/>
																</div>
															</div>
														</div>
													</div>
												</ng-scrollbar>
											</div>
											<div class="stepper-btn d-flex border-top justify-content-end py-1 px-2">
												<div>
													<button
														(click)="modernHorizontalNext(basicDetailForm, 'org')"
														type="submit"
														class="btn btn-primary btn-next"
														rippleEffect
														id="btnCntntNxt"
														name="btnCntntNxt"
														tabindex="10"
													>
														<span class="align-middle d-sm-inline-block d-none">{{
															"UI.k_next" | translate
														}}</span>
														<i class="fa-light fa-arrow-right align-middle ml-sm-25"></i>
													</button>
												</div>
											</div>
										</form>
									</div>
									<div id="license-details" class="content">
										<form
											#licenseDetailsForm="ngForm"
											class="stepper-inner"
											(keydown.enter)="$event.preventDefault()"
										>
											<div class="stepper-body">
												<ng-scrollbar class="custom-scrollbar section-scrollbar">
													<div class="stepper-main">
														<div class="row">
															<div class="col-sm-6">
																<div class="form-group">
																	<label for="selProduct" class="form-label">
																		{{ "UI.k_product" | translate }} *</label
																	>
																	<ng-select
																		required
																		bindLabel="product_title"
																		class="form-control"
																		name="selProduct"
																		id="selProduct"
																		bindValue="key"
																		placeholder="{{ 'UI.k_select' | translate }}"
																		[(ngModel)]="organization.product_name"
																		#serTypeInput="ngModel"
																		[items]="dropdownOptions?.products"
																		[clearable]="false"
																		(change)="onChangeProduct($event)"
																		tabindex="1"
																	>
																		<ng-template
																			ng-option-tmp
																			ng-label-tmp
																			let-item="item"
																		>
																			<img
																				src="{{ item.product_img }}"
																				alt="item.product_title"
																				width="16"
																				height="16"
																				class="mr-50"
																			/>
																			{{ item.product_title }}
																		</ng-template>
																	</ng-select>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="form-group">
																	<label for="selPlan">{{
																		"UI.k_plan" | translate
																	}}</label>
																	<!-- <input
                                                                        type="text"
                                                                        class="form-control"
                                                                        id="txtPlan"
                                                                        name="txtPlan"
                                                                        [(ngModel)]="activePlan"
                                                                        placeholder="{{ 'UI.k_type_here' | translate }}"
                                                                    /> -->
																	<ng-select
																		required
																		class="form-control"
																		name="selPlan"
																		id="selPlan"
																		placeholder="{{ 'UI.k_select' | translate }}"
																		[(ngModel)]="organization.plan"
																		#planTypeInput="ngModel"
																		[items]="dropdownOptions?.plans"
																		[clearable]="false"
																		(change)="onChangePlan($event)"
																		tabindex="2"
																	></ng-select>
																</div>
															</div>
															<ng-container *ngIf="currentPlan === 'Infraon-ITSM'">
																<ng-container
																	*ngIf="organization?.plan !== 'Essential'"
																>
																	<div class="col-sm-6">
																		<div
																			class="product-info-sec asset-info-sec card-bg mb-1"
																		>
																			<label
																				for="selAssetCount"
																				class="form-label"
																			>
																				{{ "UI.k_assets" | translate }}
																				*</label
																			>
																			<div class="dropdown-sec border-right">
																				<div class="form-group w-100 mb-0">
																					<ng-select
																						required
																						class="form-control"
																						name="selAssetCount"
																						id="selAssetCount"
																						placeholder="{{
																							'UI.k_select' | translate
																						}}"
																						[(ngModel)]="organization.asset"
																						#userCountTypeInput="ngModel"
																						[items]="
																							dropdownOptions?.itsmCount
																						"
																						(change)="
																							onChangeAssetCount($event)
																						"
																						[clearable]="false"
																						tabindex="3"
																					>
																						<ng-template
																							ng-option-tmp
																							ng-label-tmp
																							let-item="item"
																						>
																							<!-- <i class="fa-light fa-box-taped mr-50"></i> -->
																							{{ item }}
																						</ng-template>
																					</ng-select>
																					<div
																						*ngIf="
																							licenseDetailsForm.submitted &&
																							userCountTypeInput.invalid
																						"
																						class="invalid-form"
																					>
																						<small
																							class="form-text text-danger"
																							*ngIf="
																								userCountTypeInput
																									.errors.required
																							"
																						>
																							{{
																								"UI.k_field_required"
																									| translate
																							}}
																						</small>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</ng-container>
																<ng-container>
																	<div class="col-sm-6">
																		<div class="form-group">
																			<label class="form-label" for="seluser">
																				{{ "UI.k_user" | translate }}
																			</label>
																			<input
																				[(ngModel)]="organization.user"
																				#TDUserInput="ngModel"
																				required
																				type="number"
																				name="inpUserCount"
																				id="inpUserCount"
																				class="form-control"
																				[class.error]="TDUserInput.invalid"
																				min="3"
																				max="999"
																				tabindex="4"
																			/>
																		</div>
																		<div
																			class="invalid-form"
																			*ngIf="TDUserInput.invalid"
																		>
																			<small
																				class="form-text text-danger"
																				*ngIf="TDUserInput.errors.required"
																				>{{ "UI.k_field_required" | translate }}
																			</small>
																			<small
																				class="form-text text-danger"
																				*ngIf="TDUserInput.errors.min"
																			>
																				{{
																					"Error.err_min_val"
																						| translate: { minValue: 3 }
																				}}
																			</small>
																			<small
																				class="form-text text-danger"
																				*ngIf="TDUserInput.errors.max"
																			>
																				{{ "UI.k_please_mail" | translate }}
																				<a
																					class="reg-font text-danger text-decoration-underline"
																					href="mailto:sales@infraon.io"
																				>
																					<span>sales@infraon.io</span>
																				</a>
																				{{
																					"Message.msg_contact_sales"
																						| translate
																				}}
																			</small>
																		</div>
																	</div>
																</ng-container>
															</ng-container>
															<ng-container *ngIf="currentPlan === 'Infraon-Assets'">
																<div class="col-sm-6">
																	<div
																		class="product-info-sec asset-info-sec card-bg mb-1"
																	>
																		<label for="selAssetCount" class="form-label">
																			{{ "UI.k_assets" | translate }}
																			*</label
																		>
																		<div class="dropdown-sec border-right">
																			<div class="form-group w-100 mb-0">
																				<ng-select
																					required
																					class="form-control"
																					name="selAssetCount"
																					id="selAssetCount"
																					placeholder="{{
																						'UI.k_select' | translate
																					}}"
																					[(ngModel)]="organization.asset"
																					#userCountTypeInput="ngModel"
																					[items]="assetCount"
																					(change)="
																						onChangeAssetCount($event)
																					"
																					[clearable]="false"
																					tabindex="3"
																				>
																					<ng-template
																						ng-option-tmp
																						ng-label-tmp
																						let-item="item"
																					>
																						<!-- <i class="fa-light fa-box-taped mr-50"></i> -->
																						{{ item }}
																					</ng-template>
																				</ng-select>
																				<div
																					*ngIf="
																						licenseDetailsForm.submitted &&
																						userCountTypeInput.invalid
																					"
																					class="invalid-form"
																				>
																					<small
																						class="form-text text-danger"
																						*ngIf="
																							userCountTypeInput.errors
																								.required
																						"
																					>
																						{{
																							"UI.k_field_required"
																								| translate
																						}}
																					</small>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div class="col-sm-6">
																	<div class="form-group">
																		<label class="form-label" for="seluser">
																			{{ "UI.k_user" | translate }}
																		</label>
																		<input
																			[(ngModel)]="organization.user"
																			#TDUserInput="ngModel"
																			required
																			type="number"
																			name="inpUserCount"
																			id="inpUserCount"
																			class="form-control"
																			[disabled]="true"
																			[class.error]="TDUserInput.invalid"
																			min="3"
																			max="999"
																			tabindex="4"
																		/>
																	</div>
																	<div
																		class="invalid-form"
																		*ngIf="TDUserInput.invalid"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDUserInput.errors.required"
																			>{{ "UI.k_field_required" | translate }}
																		</small>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDUserInput.errors.min"
																		>
																			{{
																				"Error.err_min_val"
																					| translate: { minValue: 3 }
																			}}
																		</small>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDUserInput.errors.max"
																		>
																			{{ "UI.k_please_mail" | translate }}
																			<a
																				class="reg-font text-danger text-decoration-underline"
																				href="mailto:sales@infraon.io"
																			>
																				<span>sales@infraon.io</span>
																			</a>
																			{{
																				"Message.msg_contact_sales" | translate
																			}}
																		</small>
																	</div>
																</div>
															</ng-container>
															<ng-container
																*ngIf="
																	currentPlan === 'Infraon-OSS' ||
																	currentPlan === 'Infraon-UNMS'
																"
															>
																<div class="col-sm-6">
																	<div class="form-group">
																		<label class="form-label" for="txtAssetCount">
																			{{ "UI.k_assets" | translate }} *
																		</label>
																		<input
																			[(ngModel)]="organization.asset"
																			#TDAssetCountInput="ngModel"
																			required
																			type="number"
																			name="txtAssetCount"
																			id="txtAssetCount"
																			class="form-control"
																			[class.error]="TDAssetCountInput.invalid"
																			min="0"
																		/>
																	</div>
																	<div
																		class="invalid-form"
																		*ngIf="TDAssetCountInput.invalid"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDAssetCountInput.errors.required"
																			>{{ "UI.k_field_required" | translate }}
																		</small>
																	</div>
																</div>
																<div class="col-sm-6">
																	<div class="form-group">
																		<label class="form-label" for="txtUserCount">
																			{{ "UI.k_user" | translate }} *
																		</label>
																		<input
																			[(ngModel)]="organization.user"
																			#TDUserInput="ngModel"
																			required
																			type="number"
																			name="txtUserCount"
																			id="txtUserCount"
																			class="form-control"
																			[class.error]="TDUserInput.invalid"
																			min="3"
																			max="999"
																		/>
																	</div>
																	<div
																		class="invalid-form"
																		*ngIf="TDUserInput.invalid"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDUserInput.errors.required"
																			>{{ "UI.k_field_required" | translate }}
																		</small>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDUserInput.errors.min"
																		>
																			{{
																				"Error.err_min_val"
																					| translate: { minValue: 3 }
																			}}
																		</small>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDUserInput.errors.max"
																		>
																			{{ "UI.k_please_mail" | translate }}
																			<a
																				class="reg-font text-danger text-decoration-underline"
																				href="mailto:sales@infraon.io"
																			>
																				<span>sales@infraon.io</span>
																			</a>
																			{{
																				"Message.msg_contact_sales" | translate
																			}}
																		</small>
																	</div>
																</div>
																<div class="col-sm-6">
																	<div class="form-group">
																		<label class="form-label" for="txtServices">
																			{{ "UI.k_services" | translate }}
																		</label>
																		<input
																			[(ngModel)]="organization.services"
																			#TDServicesInput="ngModel"
																			type="number"
																			name="txtServices"
																			id="txtServices"
																			class="form-control"
																			[class.error]="
																				TDServicesInput.invalid &&
																				licenseDetailsForm.submitted
																			"
																			min="0"
																			pattern="[+-]?([0-9]*[.])?[0-9]+"
																		/>
																	</div>
																	<div
																		class="invalid-form"
																		*ngIf="
																			licenseDetailsForm.submitted &&
																			TDServicesInput.invalid
																		"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDServicesInput?.errors?.pattern"
																		>
																			{{
																				"Message.msg_valid_numeric_field"
																					| translate
																			}}
																		</small>
																	</div>
																</div>
																<div class="col-sm-6">
																	<div class="form-group">
																		<label class="form-label" for="txtCircuits">
																			{{ "UI.k_circuits" | translate }}
																		</label>
																		<input
																			[(ngModel)]="organization.circuits"
																			#TDCircuitsInput="ngModel"
																			type="number"
																			name="txtCircuits"
																			id="txtCircuits"
																			class="form-control"
																			[class.error]="
																				TDCircuitsInput.invalid &&
																				licenseDetailsForm.submitted
																			"
																			min="0"
																			pattern="[+-]?([0-9]*[.])?[0-9]+"
																		/>
																	</div>
																	<div
																		class="invalid-form"
																		*ngIf="
																			TDCircuitsInput.invalid &&
																			licenseDetailsForm.submitted
																		"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDCircuitsInput?.errors?.pattern"
																		>
																			{{
																				"Message.msg_valid_numeric_field"
																					| translate
																			}}
																		</small>
																	</div>
																</div>
																<div class="col-sm-6">
																	<div class="form-group">
																		<label class="form-label" for="txtEMS">
																			{{ "UI.k_ems" | translate }}
																		</label>
																		<input
																			[(ngModel)]="organization.ems"
																			#TDEMSInput="ngModel"
																			pattern="[+-]?([0-9]*[.])?[0-9]+"
																			type="number"
																			name="txtEMS"
																			id="txtEMS"
																			class="form-control"
																			[class.error]="
																				TDEMSInput.invalid &&
																				licenseDetailsForm.submitted
																			"
																			min="0"
																		/>
																	</div>
																	<div
																		class="invalid-form"
																		*ngIf="
																			TDEMSInput.invalid &&
																			licenseDetailsForm.submitted
																		"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDEMSInput?.errors?.pattern"
																		>
																			{{
																				"Message.msg_valid_numeric_field"
																					| translate
																			}}
																		</small>
																	</div>
																</div>
																<div class="col-sm-6">
																	<div class="form-group">
																		<label class="form-label" for="txtQOS">
																			{{ "UI.k_qos" | translate }}
																		</label>
																		<input
																			[(ngModel)]="organization.qos"
																			#TDQOSInput="ngModel"
																			pattern="[+-]?([0-9]*[.])?[0-9]+"
																			type="number"
																			name="txtQOS"
																			id="txtQOS"
																			class="form-control"
																			[class.error]="
																				TDQOSInput.invalid &&
																				licenseDetailsForm.submitted
																			"
																			min="0"
																		/>
																	</div>
																	<div
																		class="invalid-form"
																		*ngIf="
																			TDQOSInput.invalid &&
																			licenseDetailsForm.submitted
																		"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDQOSInput?.errors?.pattern"
																		>
																			{{
																				"Message.msg_valid_numeric_field"
																					| translate
																			}}
																		</small>
																	</div>
																</div>
																<div class="col-sm-6">
																	<div class="form-group">
																		<label class="form-label" for="txtCOS">
																			{{ "UI.k_cos" | translate }}
																		</label>
																		<input
																			[(ngModel)]="organization.cos"
																			#TDCOSInput="ngModel"
																			pattern="[+-]?([0-9]*[.])?[0-9]+"
																			type="number"
																			name="txtCOS"
																			id="txtCOS"
																			class="form-control"
																			[class.error]="
																				TDCOSInput.invalid &&
																				licenseDetailsForm.submitted
																			"
																			min="0"
																		/>
																	</div>
																	<div
																		class="invalid-form"
																		*ngIf="
																			TDCOSInput.invalid &&
																			licenseDetailsForm.submitted
																		"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDCOSInput?.errors?.pattern"
																		>
																			{{
																				"Message.msg_valid_numeric_field"
																					| translate
																			}}
																		</small>
																	</div>
																</div>
																<div class="col-sm-6">
																	<div class="form-group">
																		<label class="form-label" for="txtInterfaces">
																			{{ "UI.k_interfaces" | translate }}
																		</label>
																		<input
																			[(ngModel)]="organization.interfaces"
																			#TDInterfacesInput="ngModel"
																			pattern="[+-]?([0-9]*[.])?[0-9]+"
																			type="number"
																			name="txtInterfaces"
																			id="txtInterfaces"
																			class="form-control"
																			[class.error]="
																				TDInterfacesInput.invalid &&
																				licenseDetailsForm.submitted
																			"
																			min="0"
																		/>
																	</div>
																	<div
																		class="invalid-form"
																		*ngIf="
																			TDInterfacesInput.invalid &&
																			licenseDetailsForm.submitted
																		"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDInterfacesInput?.errors?.pattern"
																		>
																			{{
																				"Message.msg_valid_numeric_field"
																					| translate
																			}}
																		</small>
																	</div>
																</div>
															</ng-container>
															<div class="col-sm-6">
																<div class="form-group">
																	<label class="form-label" for="txtOrderNumber">
																		{{ "UI.k_sales_order" | translate }}
																	</label>
																	<input
																		[(ngModel)]="organization.sales_order_no"
																		#TDOrderNoRef="ngModel"
																		type="text"
																		name="orderNumber"
																		id="txtOrderNumber"
																		class="form-control"
																		placeholder="{{
																			'Message.msg_enter_sales_order_no'
																				| translate
																		}}"
																		[appWhiteSpaceCheck]="
																			organization.sales_order_no
																		"
																		tabindex="5"
																	/>
																</div>
															</div>
															<div class="col-sm-6">
																<div class="form-group">
																	<label class="form-label" for="txtOrderNumber">
																		{{ "UI.k_purchase_order_no" | translate }}
																	</label>
																	<input
																		[(ngModel)]="organization.purchase_order_no"
																		#TDpurchaseOrderNoRef="ngModel"
																		type="text"
																		name="purchaseorderNumber"
																		id="txtpurchaseOrderNumber"
																		class="form-control"
																		placeholder="{{
																			'Message.msg_purchase_sales_order_no'
																				| translate
																		}}"
																		[appWhiteSpaceCheck]="
																			organization.purchase_order_no
																		"
																		tabindex="6"
																	/>
																</div>
															</div>
														</div>
													</div>
												</ng-scrollbar>
											</div>

											<div
												class="stepper-btn d-flex justify-content-between border-top py-1 px-2 mt-2"
											>
												<button
													type="button"
													class="btn btn-flat-primary btn-prev mr-1"
													(click)="modernHorizontalPrevious()"
													rippleEffect
													id="btnLicPrev"
													name="btnLicPrev"
													tabindex="7"
												>
													<i class="fa-light fa-arrow-left align-middle mr-sm-25"></i>
													<span class="align-middle d-sm-inline-block d-none">{{
														"UI.k_previous" | translate
													}}</span>
												</button>
												<button
													class="btn btn-primary btn-next"
													(click)="modernHorizontalNext(licenseDetailsForm, 'license')"
													rippleEffect
													type="submit"
													id="btnDetNxt"
													name="btnDetNxt"
													tabindex="8"
												>
													<span class="align-middle d-sm-inline-block d-none">{{
														"UI.k_next" | translate
													}}</span>
													<i class="fa-light fa-arrow-right align-middle ml-sm-25"></i>
												</button>
											</div>
										</form>
									</div>
									<div id="download-details" class="content">
										<form
											#downloadForm="ngForm"
											class="stepper-inner"
											(keydown.enter)="$event.preventDefault()"
										>
											<div class="stepper-body">
												<ng-scrollbar class="custom-scrollbar section-scrollbar">
													<div class="stepper-main">
														<div class="form-group" *ngIf="isOnPremise">
															<div class="custom-control custom-checkbox">
																<input
																	type="checkbox"
																	class="custom-control-input"
																	id="cbCheckAvailability"
																	[checked]="organization.is_high_availability"
																	(change)="onCheck($event)"
																/>
																<label
																	class="custom-control-label"
																	for="cbCheckAvailability"
																>
																	{{ "Message.msg_high_availability" | translate }}
																</label>
															</div>
														</div>
														<ng-container *ngIf="checkAvailability && isOnPremise">
															<div class="form-group">
																<label class="form-label" for="selMachineID">
																	{{ "UI.k_machine_id" | translate }}
																	<span class="required-field">&nbsp;*</span>
																</label>
																<ng-select
																	[items]="[]"
																	[multiple]="true"
																	id="selMachineID"
																	name="selMachineID"
																	placeholder="{{
																		'UI.k_add_machine_id' | translate
																	}}"
																	[addTag]="true"
																	[closeOnSelect]="true"
																	[(ngModel)]="organization.machine_ids"
																	addTagText="{{ 'UI.k_add_item' | translate }}"
																	hideSelected="true"
																	#TDMachineIDS="ngModel"
																	required
																	class="form-control"
																	notFoundText="{{
																		'UI.k_no_items_found' | translate
																	}}"
																	[class.error]="
																		!TDMachineIDS.valid && downloadForm.submitted
																	"
																	tabindex="1"
																>
																</ng-select>
																<div
																	*ngIf="
																		downloadForm.submitted && TDMachineIDS.invalid
																	"
																	class="invalid-form mb-1"
																>
																	<small
																		class="form-text text-danger"
																		*ngIf="TDMachineIDS.errors.required"
																	>
																		{{ "UI.k_field_required" | translate }}
																	</small>
																</div>
															</div>
														</ng-container>
														<ng-container *ngIf="!checkAvailability && isOnPremise">
															<div class="form-group">
																<label class="form-label" for="inpMachineID">
																	{{ "UI.k_machine_id" | translate }}
																	<span class="required-field">&nbsp;*</span>
																</label>
																<input
																	[(ngModel)]="organization.machine_ids"
																	#TDMachineID="ngModel"
																	type="text"
																	name="inpMachineID"
																	id="inpMachineID"
																	class="form-control"
																	placeholder="{{
																		'UI.k_add_machine_id' | translate
																	}}"
																	[appWhiteSpaceCheck]="organization.machine_ids"
																	required
																	[class.error]="
																		TDMachineID.invalid && downloadForm.submitted
																	"
																	tabindex="2"
																/>
															</div>
															<div
																class="invalid-form mb-1"
																*ngIf="TDMachineID.invalid && downloadForm.submitted"
															>
																<small
																	class="form-text text-danger"
																	*ngIf="TDMachineID.errors.required"
																	>{{ "UI.k_field_required" | translate }}
																</small>
															</div>
														</ng-container>

														<div class="form-group" *ngIf="isSupportManager && isOnPremise">
															<div class="custom-control custom-checkbox">
																<input
																	type="checkbox"
																	class="custom-control-input"
																	id="inpPerpetual"
																	[(ngModel)]="organization.is_perpetual"
																	(change)="onToggle($event.target)"
																	tabindex="2"
																/>
																<label
																	class="custom-control-label"
																	for="inpPerpetual"
																	>{{ "Message.msg_is_perp_lic" | translate }}</label
																>
															</div>
														</div>

														<div
															class="form-group col-50"
															*ngIf="!organization?.is_perpetual"
														>
															<label for="dtlicExpiry" class="form-label">
																{{ "Message.msg_lic_expire_date" | translate }}
																<span
																	class="required-field"
																	*ngIf="!organization?.is_perpetual"
																	>&nbsp;*</span
																>
															</label>
															<div class="input-group">
																<input
																	class="form-control"
																	placeholder="{{
																		'UI.k_placeholder_yyyy_mm_dd' | translate
																	}}"
																	name="dtlicExpiry"
																	id="dtlicExpiry"
																	[(ngModel)]="organization.expiry_date"
																	ngbDatepicker
																	#basicDP="ngbDatepicker"
																	#checkLicenseExpire="ngModel"
																	[required]="!organization?.is_perpetual"
																	[minDate]="minDate"
																	[readonly]="true"
																	[class.error]="
																		checkLicenseExpire?.invalid &&
																		downloadForm.submitted
																	"
																	tabindex="3"
																/>
																<div class="input-group-append">
																	<button
																		class="btn btn-outline-secondary feather icon-calendar"
																		(click)="basicDP.toggle()"
																		type="button"
																		[class.error]="
																			downloadForm.submitted &&
																			checkLicenseExpire?.invalid
																		"
																		rippleEffect
																	></button>
																</div>
															</div>
															<span
																*ngIf="
																	checkLicenseExpire?.invalid &&
																	downloadForm.submitted
																"
																class="invalid-form"
															>
																<small
																	class="form-text text-danger"
																	*ngIf="checkLicenseExpire?.status == 'INVALID'"
																>
																	{{ "Message.msg_sel_lic_expiry" | translate }}
																</small>
															</span>
														</div>
														<div
															class="form-group col-50"
															*ngIf="!isPartner && isOnPremise"
														>
															<label for="dtsupportExpiry" class="form-label">
																{{ "Message.msg_support_expire_date" | translate }}
																<span class="required-field">&nbsp;*</span>
															</label>
															<div class="input-group">
																<input
																	class="form-control"
																	placeholder="{{
																		'UI.k_placeholder_yyyy_mm_dd' | translate
																	}}"
																	name="dtsupportExpiry"
																	id="dtsupportExpiry"
																	[(ngModel)]="organization.support_expiry_date"
																	ngbDatepicker
																	#supportDP="ngbDatepicker"
																	#checkSupportExpire="ngModel"
																	[minDate]="minDate"
																	required
																	[readonly]="true"
																	[class.error]="
																		checkSupportExpire?.invalid &&
																		downloadForm.submitted
																	"
																	tabindex="4"
																/>
																<div class="input-group-append">
																	<button
																		class="btn btn-outline-secondary feather icon-calendar"
																		(click)="supportDP.toggle()"
																		type="button"
																		[class.error]="
																			downloadForm.submitted &&
																			checkSupportExpire?.invalid
																		"
																		rippleEffect
																	></button>
																</div>
															</div>
															<span
																*ngIf="
																	checkSupportExpire?.invalid &&
																	downloadForm.submitted
																"
																class="invalid-form"
															>
																<small
																	class="form-text text-danger"
																	*ngIf="checkSupportExpire?.status == 'INVALID'"
																>
																	{{ "Message.msg_sel_support_expiry" | translate }}
																</small>
															</span>
														</div>
													</div>
												</ng-scrollbar>
											</div>
											<div
												class="stepper-btn d-flex justify-content-between border-top px-2 py-1"
											>
												<button
													type="button"
													class="btn btn-flat-primary btn-prev mr-1"
													(click)="modernHorizontalPrevious()"
													rippleEffect
													id="btnDwnloadPrev"
													name="btnDwnloadPrev"
													tabindex="5"
												>
													<i class="fa-light fa-arrow-left align-middle mr-sm-25"></i>
													<span class="align-middle d-sm-inline-block d-none">{{
														"UI.k_previous" | translate
													}}</span>
												</button>
												<button
													type="submit"
													id="btnDwnloadSubmit"
													name="btnDwnloadSubmit"
													rippleEffect
													class="btn btn-primary pull-right"
													(click)="onSave(downloadForm)"
													[disabled]="is_save_disabled"
													tabindex="6"
												>
													{{ "UI.k_save" | translate }}
												</button>
											</div>
										</form>
									</div>
								</div>
								<!-- </form> -->
							</div>
						</div>
					</section>
					<!-- /Modern Horizontal Wizard -->
				</div>
			</div>
		</div>
	</div>
</div>
