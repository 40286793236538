import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ProblemService } from "app/servicedesk/problem/services/problem.service";
import { map, catchError } from "rxjs/operators";

@Component({
  selector: 'app-common-problem-view',
  templateUrl: './common-problem-view.component.html',
  styleUrls: ['./common-problem-view.component.scss']
})
export class CommonProblemViewComponent implements OnInit {

	@Output() onHideProblemView = new EventEmitter(false);
  @Input() public problem_data: any = {};
  @Input() public problem_id : any = "";
  dataLoaded : boolean = false
  	location_name = ""

	constructor(
		private _problemService: ProblemService,
	) { }


	ngOnInit(): void {
		if (!this.problem_data.hasOwnProperty("display_id")) {
			this._problemService
			.getProblem(this.problem_id,{'return_main_info':true})
			.pipe(
				map(
					(response) => {
						this.problem_data = response?.problem;
						this.dataLoaded = true						
					},
					(error) => {
						//todo
					}
					)
		)
		.subscribe();
		}
		else{
			this.dataLoaded = true
		}

		this.location_name = this?.problem_data?.requester?.location?.location_name ||
				this?.problem_data?.requester?.location?.city ||
				this?.problem_data?.requester?.location?.state ||
				this?.problem_data?.requester?.location?.country;
	}

	closeSidebar = (): void => {
		this.onHideProblemView.emit(true);
	};

}
