import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { map } from 'rxjs/operators';
import { TranslateService } from "@ngx-translate/core";
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import cloneDeep from "lodash-es/cloneDeep";
import { FlatpickrOptions } from 'ng2-flatpickr';
import { InfinityModules } from "app/common/dns/types/modules";
import { GlobalConstants } from "app/app.globalConstants";
import { IncidentViewService } from 'app/servicedesk/incident-view/services/incident-view.service';

@Component({
  selector: 'app-dns-resolve-status',
  templateUrl: './dns-resolve-status.component.html',
  styleUrls: ['./dns-resolve-status.component.scss']
})
export class DnsResolveStatusComponent implements OnInit {
  @Output("onHideResolve") onHideResolve = new EventEmitter<any>();
	@Output("onUpdateResolveClose") onUpdateResolveClose = new EventEmitter<any>();
	@Input() incident_id = "";
	@Input() options;
	@Input() stateKeyIdMap = {};
	@Input() selected_status: any;
	@Input() is_call_from_edit = false;
	@Input() callFrom: any;
	public incident_data;
	public incident_data_copy;
	currentUserProfileId: any;
	hideInlineEdit: boolean = false;
	inci_extra_info: any = {};
	resolve_title: string;
	resolve_btn_text: string;
	resolved_status = []
	public saveIncidentData;
	sourceIconMap = {
		1: "globe",
		2: "envelope",
		3: "bell",
		4: "mobile",
		5: "phone",
		6: "comment-lines",
		7: "comment-dots",
		8: "microsoft"
	}

	public dateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: true,
		dateFormat: "%b %d, %Y %I:%M %p",
		altFormat: "M d, Y H:i K",
		mode: "single",
		altInputClass: "form-control flat-picker flatpickr-input",
	};
	public agreedDateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: true,
		dateFormat: "%b %d, %Y %I:%M %p",
		altFormat: "M d, Y H:i K",
		mode: "single",
		altInputClass: "form-control flat-picker flatpickr-input",
	};
	public editorCreated(event): void {
		// Example on how to add new table to editor
		// this.addNewtable();
	}
	userTZ: string | undefined;
	asset_module = InfinityModules.CMDB;
	asset_permissions: any;
	is_submit_disabled = false

  constructor(
    private _incidentViewService: IncidentViewService,
    private _translateService: TranslateService,
    private _coreSidebar: CoreSidebarService,
    ) { 
    
  }

  ngOnInit(): void {
	this.onResolve(this.incident_id)
	let userParams = JSON.parse(localStorage.getItem("userParams"));
	this.userTZ =  userParams?.user_tz;
	try {
		let today = new Date(new Date().toLocaleString("en-US", { timeZone: this.userTZ }));
		this.dateTimeOptions["defaultDate"] = today
	} catch (e) {
		//console.log("Error in selecting current time based on timezone ",  e);
	}
	let user_permissions = {};
	if (GlobalConstants.dnsPermissions != undefined) {
		user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
	}
	this.asset_permissions = user_permissions[this.asset_module];
  }

onResolve(incident_id){
	this._incidentViewService
	.getIncidentData(incident_id)
	.pipe(
		map(
			(response) => {
				this.incident_data = response["incident"];
				if (this.incident_data?.close_info == null || this.incident_data?.close_info == undefined){
					this.incident_data["close_info"] = {
						"closed_type": {}
					}
				}
				this.incident_data_copy = cloneDeep(this.incident_data);
				this.currentUserProfileId = response["current_user_id"];
				this.inci_extra_info["selected_assignee_info"] =  response["selected_assignee_info"];
				this.inci_extra_info["custom_field_config"] = response["custom_field_config"]
				this.inci_extra_info["group_type"] = response["group_type"];
				this.hideInlineEdit = true;
				if (this.incident_data?.basic_info?.state?.id < 4){
					this.incident_data["inci_resolution_time"] = null
				}
				this.resolve_title = this._translateService.instant("UI.k_resolve_incident")
				this.resolve_btn_text = this._translateService.instant("UI.k_resolve")
				this.resolved_status = response?.status?.filter((e)=>
						e['state_id'] == this.stateKeyIdMap["Resolved"]
					)
				if (this.incident_data?.basic_info?.state?.id >= this.stateKeyIdMap["Resolved"]){
					this.incident_data["inci_add_flag"] = undefined
					this.resolve_title = this._translateService.instant("UI.k_close_incident")
					this.resolved_status = response?.status?.filter((e)=>
						e['state_id'] == this.stateKeyIdMap["Close"]
					)
					this.resolve_btn_text = this._translateService.instant("UI.k_complete")
				}
				if (!this.selected_status?.state_id){
					if (this.incident_data?.basic_info?.state?.id == 5){
						this.incident_data["resolved_status_id"] = this.incident_data?.basic_info?.status?.id
						this.incident_data["state_id"] = this.incident_data?.basic_info?.status?.state_id
					}
					if (this.resolved_status && this.incident_data?.basic_info?.state?.id != 5) {
						this.incident_data["resolved_status_id"] = this.selected_status?.id;
						this.incident_data["state_id"] = this.selected_status?.state_id;
						this.incident_data.basic_info["status"] = this.selected_status;
					}
				}
				else{
					this.resolved_status = response?.status?.filter((e)=>
						e['state_id'] == this.selected_status?.state_id
					)
					this.incident_data["resolved_status_id"] = this.selected_status?.id
					this.incident_data["state_id"] = this.selected_status?.state_id
					this.incident_data.basic_info["status"] = this.selected_status
				}
			},
			(error) => {
				//todo
			}
			)
		)
		.subscribe();
	}

  toggleSidebar(key) {
		if(this.callFrom === 'panelCard'){
			this.onHideResolve.emit();
		}else{
			this.onHideResolve.emit();
			if (this.is_call_from_edit){
				key = "close-resolve-incident"
			}
			let sidebarRef = this._coreSidebar.getSidebarRegistry(key);
			sidebarRef.toggleOpen();
		}
		// let sidebarRef = this._coreSidebar.getSidebarRegistry(key);
		// sidebarRef.toggleOpen();
	}

  onResolveSubmit(data, args){
		if (data.form.status === 'VALID'){	
			this.is_submit_disabled = true		
			this.incident_data.basic_info["state"] = this.options?.state_id_map[this.incident_data.basic_info?.status?.state_id];
			if (this.incident_data.basic_info?.status?.state_id > 4){
				let cf_data = this.inci_extra_info?.custom_field_config?.custom_fields?.filter((d) => d?.section == "closure");
				this.incident_data.close_info['custom_field_data'] = this.get_custom_data(cf_data,this.incident_data?.custom_field_data);
			}
			if (this.incident_data.basic_info?.status?.state_id == 4){
				let cf_data = this.inci_extra_info?.custom_field_config?.custom_fields?.filter((d) => d?.section == "resolution");
				this.incident_data['custom_field_data'] = this.incident_data?.custom_field_data
				this.incident_data['custom_fields'] = cf_data
			}
			let key = "resolve-incident"
			if (this.is_call_from_edit){
				key = "close-resolve-incident"
			}
			this.toggleSidebar(key)
			this.onUpdateResolveClose.emit(this.incident_data);
			this.is_submit_disabled = false
		}
	}

	onStatusChange = (data) => {
		if (data?.state_id == 4){
			this.incident_data["resolved_status_id"] = data?.id;
		}
		
		this.incident_data["status_id"] = data?.id;
		this.incident_data.basic_info["status"] = data;
		this.incident_data["state_id"] = data?.state_id;
		this.incident_data.basic_info["state"] = this.options?.state_id_map[data?.state_id];
	};	

	getClosureNote = (evt) => {
		this.incident_data.close_info.closure_note= evt.html;
	}

	updateFieldName(evt, id, dropdown_name, set_field,ref_field){
		let selected = this.options[dropdown_name].filter((e)=>
			e[id] == evt
		)
		set_field  = selected[0][ref_field]
	}

	get_custom_data(config_data, custom_fields){
		let actual_conf_data = {}
		if (config_data && custom_fields){
			config_data.forEach(conf => {
				let data_key = conf?.data_key
				let label = conf?.label
				let value = custom_fields[data_key]
				if (conf?.type == 'date' || conf?.type == 'datetime-local'){
					if (this.incident_data.custom_field_data[data_key] instanceof Array){
						this.incident_data.custom_field_data[data_key] = this.incident_data.custom_field_data[data_key][0]
					}
					if (value instanceof Array){
						value = value[0]
					}
				}
				if (value){
					actual_conf_data[label] = value
				}
			});
		}
		return actual_conf_data
	};

}
