<div *ngIf="data?.aging_period?.is_running">
    <ng-container>
        <!-- <div class="timer-container px-50 transparent-primary">
            <div class="timer">
                <span class="text-primary timer-hour fw-500">{{ data.aging_period.Days }}</span>
                <span class="text-primary mx-25">|</span> <span class="text-primary timer-minute fw-500">{{ data.aging_period.Hours }}</span>
            </div>
            <div class="timer-labels text-primary d-flex gap-25">
                <span class="fw-500 xs-font">Days</span><span class="fw-500 xs-font">Hours</span>
            </div>
        </div> -->
        <div class="aging-timer transparent-primary txt-primary fw-500 d-flex align-items-center gap-25">
            <i class="fa-light fa-clock"></i>
            <div class="d-flex align-items-center gap-25">
                <span>{{ data.aging_period.Days }}</span><span>{{ "UI.k_days" | translate }},</span>
            </div>
            <div class="d-flex align-items-center gap-25"><span class="text-nowrap">{{ data.aging_period.Hours }}</span><span>{{ "UI.k_hours" | translate }}</span></div>
        </div>
    </ng-container>
</div>

<div *ngIf="!data?.aging_period?.is_running">
    <ng-container>
        <!-- <div class="close-timer-container p-50 bg-muted">
            <div class="close-timer">
                <span class="title-color close-timer-hour fw-500">{{ data.aging_period.Days }}</span>
                <span class="title-color mx-25">|</span> <span class="title-color close-timer-minute fw-500">{{ data.aging_period.Hours }}</span>
            </div>
            <div class="close-timer-labels title-color d-flex gap-25">
                <span class="fw-500 xs-font">Days</span><span class="fw-500 xs-font">Hours</span>
            </div>
        </div> -->
        <div class="aging-timer close-aging-timer transparent-body body-color fw-500 d-flex align-items-center gap-25">
            <i class="fa-light fa-clock"></i>
            <div class="d-flex align-items-center gap-25">
                <span>{{ data.aging_period.Days }}</span><span>{{ "UI.k_days" | translate }},</span>
            </div>
            <div class="d-flex align-items-center gap-25"><span class="text-nowrap">{{ data.aging_period.Hours }}</span><span>{{ "UI.k_hours" | translate }}</span></div>
        </div>
    </ng-container>
</div>



