<div class="list-wrapper">
    <div class="d-flex flex-wrap">
        <div class="skeleton" *ngFor="let item of generateFake(8)" class="col-md-3 pr-50 mb-1">
            <div class="sekeleton-card item-wrap col-md-12 pb-0 pt-0">
                <div class="d-flex">
                    <div class="col-md-3 pl-0 mt-25">
                        <ngx-skeleton-loader appearance="circle" [theme]="{ height: '50px',width: '50px'}"></ngx-skeleton-loader>
                    </div>
                    <div class="col-md-9 p-0 mt-1">
                        <div class="d-flex col-md-12 p-0 justify-content-between">
                            <div class="col-md-6 p-0"><ngx-skeleton-loader [theme]="{ height: '12px',width: '100%'}"></ngx-skeleton-loader></div>
                            <div class="col-md-4 p-0"><ngx-skeleton-loader  [theme]="{ height: '12px',width: '100%'}"></ngx-skeleton-loader></div>
                        </div>
                        <div class="col-md-12 p-0">
                            <ngx-skeleton-loader [theme]="{ height: '12px',width: '100%'}"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
                <hr class="mb-1 mt-0">
                <div class="col-md-12 p-0">
                    <ngx-skeleton-loader count="2" [theme]="{ height: '10px',width: '100%'}"></ngx-skeleton-loader>
                </div>
            </div>
        </div>
    </div>
    <div>
        <ngx-skeleton-loader [theme]="{ height: '45px',width: '100%'}"></ngx-skeleton-loader>
    </div>
</div>
