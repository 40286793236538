import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SearchMenuComponent } from "./components/search-menu/search-menu.component";
import {
	Component,
	OnInit,
	OnDestroy,
	ViewChild,
	HostListener,
	ViewEncapsulation,
} from "@angular/core";
import { AdminPopoverComponent } from "./components/admin-popover/admin-popover.component";
import { AccountSettingsComponent } from "./components/account-settings/account-settings.component";
import { AuthGuard } from "app/common/guards/auth.guards";
import { from } from 'rxjs';

const routes: Routes = [
	{
		path: "search",
		component: SearchMenuComponent,
		// canActivate: [AuthGuard],
	},
	{
		path: "admin",
		component: AdminPopoverComponent,
		// canActivate: [AuthGuard],
	},
	{
		path: "",
		component: AccountSettingsComponent,
		// canActivate: [AuthGuard],
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class SystemMenuRoutingModule {}
