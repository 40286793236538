<div class="d-flex align-items-center cursor-pointer {{ timerClass }}">
	<ng-container *ngIf="expectedEndTime">
		<apx-chart
			[chart]="is_achieved ? chartachieve.chart : chartoptions.chart"
			[grid]="is_achieved ? chartachieve.grid : chartoptions.grid"
			[colors]="[getChartColor(getColorName(startTime, expectedEndTime))]"
			#chartObj
			[series]="[metricData?.elapsed_percentage]"
			[plotOptions]="is_achieved ? chartachieve.plotOptions : chartoptions.plotOptions "
			[stroke]="is_achieved ? chartachieve.stroke : chartoptions.stroke"
		>
		</apx-chart>
	</ng-container>
	<div class="ml-50 mb-0 cp-timer" *ngIf="expectedEndTime">
		<ng-container *ngIf="getRunningStatusTitle(metricData) as slaTitleData" >
			<span [style.color]="slaTitleData?.color_name">
				<i class="fa {{ slaTitleData?.icon }}" aria-hidden="true"></i> <b>{{ slaTitleData?.status_name }}</b> 
			</span>
		</ng-container>
		
		<ng-container *ngIf="getTimerData(metricData) as timerData">
			<div class="d-flex justify-content-start align-items-center">
				<span *ngIf="is_achieved" class="mr-25">{{ "UI.k_with" | translate }}</span>
				<label class="mb-0 fw-500">
					{{ isBreached ? "+" : "" }}<span *ngIf="timerData?.days">{{ timerData?.days }}d</span>
					{{ timerData?.hours?.toString().padStart(2, "0") }}h
					{{ timerData?.minutes?.toString().padStart(2, "0") }}m
				</label>
				<span *ngIf="is_achieved" class="ml-25">{{ "UI.k_left" | translate }}</span>
			</div>
		</ng-container>
	</div>
	<div class="ml-50 mb-0 cp-timer" *ngIf="!expectedEndTime">
		<label class="mb-0 text-secondary"> NA </label>
		<!-- <span *ngIf="isBreached" class="breached-status"
			><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>{{ "UI.k_breached" | translate }}</span
		>
		<span *ngIf="!isBreached">{{ "UI.k_left" | translate }}</span> -->
	</div>
</div>
