<div class="subheader">
	<div class="toggale-submenu">
		<button
			class="submenu-toggle btn"
			id="btnSubmenu"
			name="btnSubmenu"
			(click)="submenuToggaleClick()"
		>
			<div
				*ngIf="submenu"
				tooltipClass="fadeInAnimation"
				[ngbTooltip]="'Minimize Left Panel'"
				placement="right"
			>
				<!-- <i [data-feather]="'chevron-left'"></i> -->
				<i class="fa-light fa-chevron-left"></i>
			</div>
			<div
				*ngIf="!submenu"
				tooltipClass="fadeInAnimation"
				[ngbTooltip]="'Expand Left Panel'"
				placement="right"
				class="pl-25"
			>
				<!-- <i [data-feather]="'chevron-right'"></i> -->
				<i class="fa-light fa-chevron-right"></i>
			</div>
		</button>
	</div>
</div>

<div class="email-application">
	<div
		class="content-area-wrapper border-0"
		style="display: unset; z-index: -1"
	>
		<div
			class="side-pannel shadow-none p-0"
			[perfectScrollbar]
			[ngClass]="{ activeSidebar: submenu === true }"
		>
			<!--*ngFor="let item of mainArray; let j=index;"-->
			<ng-container>
				<div *ngIf="mainArray['type'] === 'incident_option'" class="mb-2">
					<div class="sidebar">
						<div *ngFor="let drpOption of mainArray['filterData']">
							<div class="btn-group" *ngIf="drpOption.filType == 'Multiple'">
								<div ngbDropdown>
									<button
										ngbDropdownToggle
										class="
											btn btn-flat-secondary
											bg-transparent
											font-style font-small-4
											mb-25
										"
										type="button"
										id="btnRecentAlert"
										name="recentAlert"
										rippleEffect
									>
										{{ mainArray["incidentOptType"] | translate }}
									</button>
									<div ngbDropdownMenu aria-labelledby="dropdownMenuButton100">
										<div *ngFor="let fil of mainArray['dropDownOption']">
											<a
												ngbDropdownItem
												id="btnType_{{ fil.id }}"
												name="days"
												(click)="ChangeType(fil.id, fil.name)"
												>{{ fil.name | translate }}</a
											>
										</div>
									</div>
								</div>
							</div>
							<div *ngIf="drpOption.filType == 'Single'" class="m-1 ml-2">
								<span class="font-style font-small-4">Views</span>
							</div>
							<div class="sidebar-menu-list">
								<div class="list-group list-group-messages">
									<div *ngFor="let opt of drpOption.filterData; let i = index">
										<a
											aria-controls="collapseExample1"
											data-toggle="collapse"
											id="btn-{{ i }}"
											name="menu-{{ i }}"
											class="
												list-group-item list-group-item-action
												cursor-pointer
											"
											[ngClass]="{ active: selectedItem === opt.id }"
											(click)="onSelect(opt, i)"
										>
											<span
												class="align-middle font-weight-normal text-color"
												style="width: 12em"
												ngbTooltip="{{ opt.filter_item }}"
												placement="top"
											>
												{{ opt.filter_item }}
											</span>
											<span
												class="badge ml-auto badge-gray badge-pill"
												style="max-width: 8em"
												ngbTooltip="{{ opt.value }}"
												placement="left"
												[ngStyle]="{
													'background-color':
														selectedItem === opt.id ? '#7367f0' : '#b9b9c3',
													overflow: 'initial'
												}"
												>{{ opt.value }}</span
											>
										</a>
									</div>
								</div>
							</div>
							<div
								class="border-top mt-50 mb-50"
								*ngIf="drpOption.filType == 'Single'"
							></div>
						</div>
					</div>
				</div>
				<div *ngIf="mainArray['type'] === 'filterComponent'" class="mb-2 p-1">
					<app-filter-bar
						[filterBarObject]="filterBarObject"
						(filterDetails)="emittedFilterValues($event)"
					></app-filter-bar>
				</div>
				<div *ngIf="mainArray['type'] === 'categoryComponent'" class="p-1 mb-2">
					<app-custom-category-list
						[categoryObject]="categoryObject"
						(categoryData)="emittedCategoryValues($event)"
					></app-custom-category-list>
				</div>
				<div *ngIf="mainArray['type'] === 'customComponent'" class="mb-2 p-1">
					<div class="custom-input-list">
						<div class="row">
							<div class="col-12 mb-1 form-group">
								<label for="basicInput">Basic Input</label>
								<ng-container
									*ngFor="let item of this.customInputObject.inputTypeList"
								>
									<input
										type="text"
										class="form-control"
										id="{{ item?.id }}"
										name="{{ item?.name }}"
										placeholder="Enter data"
										[(ngModel)]="item.ng_model_value"
										(ngModelChange)="emittedCustomInputData(item)"
									/>
								</ng-container>
							</div>
						</div>
						<div class="row">
							<div class="col-12 mb-1 form-group">
								<ng-container
									*ngFor="
										let checkboxItem of this.customInputObject.checkBoxList
									"
								>
									<div class="custom-control custom-checkbox">
										<input
											type="checkbox"
											class="custom-control-input"
											id="{{ checkboxItem?.id }}"
											name="{{ checkboxItem?.name }}"
											(change)="emittedCustomInputData(checkboxItem)"
										/>
										<label
											class="custom-control-label"
											for="{{ checkboxItem?.id }}"
											>{{ checkboxItem?.label }}</label
										>
									</div>
								</ng-container>
							</div>
						</div>
						<div class="row">
							<div class="col-12 mb-1 form-group">
								<label for="version">Radio Options:</label>
								<div fxLayout="row" fxLayoutGap="32px">
									<ng-container
										*ngFor="let radioItem of this.customInputObject.radioList"
									>
										<div class="custom-control custom-radio">
											<input
												id="{{ radioItem?.id }}"
												type="radio"
												value="{{ radioItem?.value }}"
												name="{{ radioItem?.name }}"
												class="custom-control-input"
												(click)="emittedCustomInputData(radioItem)"
											/>
											<label
												class="custom-control-label"
												for="{{ radioItem?.id }}"
												>{{ radioItem?.label }}</label
											>
										</div>
									</ng-container>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>
