import { Injectable } from "@angular/core";
import store from "store";
import ReconnectingWebSocket from "reconnecting-websocket";
import { NgEventBus } from "ng-event-bus";
import { generate_uuid } from "app/common/utils/utils";
import { environment } from "environments/environment";
import { BehaviorSubject } from "rxjs";

const reconnectionDelay = 1000;

@Injectable({
  providedIn: 'root'
})
export class WssAgentService {
	public client_uuid: string;
	private wssUrl: string;
	private wss: any;
	private wsDataStore = new BehaviorSubject({});

	constructor(private eventBus: NgEventBus) {}

	public connect() {
		try {
			if (!this.client_uuid) {
				this.client_uuid = generate_uuid("wss");
			}
			let accessToken = store.get("accessToken");
			this.wssUrl =
				environment.wss_agent_url + "msgexchange?" + "token=" + accessToken + "&clientid=" + this.client_uuid;
			let options = {
				connectionTimeout: 5000,
				maxEnqueuedMessages: 50,
			};
			if (this.wss) {
				this.wss.removeEventListener("open");
				this.wss.removeEventListener("message");
				this.wss.removeEventListener("close");
				this.wss.removeEventListener("error");
				this.disconnect();
			}
			// console.log("this.wssUrl > ", this.wssUrl)
			this.wss = new ReconnectingWebSocket(this.wssUrl, [], options);
			this.wss.addEventListener("open", () => {
				console.log(`WSS connection successful - ${environment.wss_url}, clientid - ${this.client_uuid}`);
			});

			this.wss.addEventListener("message", (event) => {
				let msg = JSON.parse(event.data);
				if (msg.payload.message.wss__channel && msg.payload.message.wss__uuid) {
					let cast_key = `${msg.payload.message.wss__channel}:${msg.payload.message.wss__uuid}`;
					this.wsDataStore.next({ channel: cast_key, data: msg }); // added for center store in memory
					this.eventBus.cast(cast_key, msg.payload.message);
				} else {
					this.eventBus.cast("msgexchange:bell", msg);
				}
				// //console.log("WSS connection successful - " + environment.wss_url + ", clientid - " + this.client_uuid)
			});

			this.wss.addEventListener("close", (event) => {
				//console.log(
				//	`WSS connection closed - ${environment.wss_url}, clientid - ${this.client_uuid}, Exit Code - ${event?.code}`
				//);
			});

			this.wss.addEventListener("error", (event) => {
				//console.log(
				//	`WSS connection error - ${environment.wss_url}, clientid - ${this.client_uuid}, Error - ${event?.message}`
				//);
			});
		} catch (e) {
			//console.log("Error in initializing websocket component", e);
		}
	}

	public disconnect() {
		try {
			// disconnect existing
			//console.log(`WSS Disconnecting - ${environment.wss_url} , clientid - ${this.client_uuid}`);
			this.wss.close();
			this.wss = null;
		} catch (e) {
			//console.log("Error in disconnecting websocket", e);
		}
	}

	public send(handlerType, payload, reply_only_to_this_client: boolean = false) {
		if (reply_only_to_this_client) {
			payload["to_client"] = this.client_uuid;
		}
		let data = {
			handle: handlerType,
			payload: {
				data: payload,
			},
			msgid: generate_uuid(),
		};
		this.wss.send(JSON.stringify(data));
	}

	public getWsDataStore = () => {
		return this.wsDataStore;
	};
}

/*
type Options = {
    WebSocket?: any; // WebSocket constructor, if none provided, defaults to global WebSocket
    maxReconnectionDelay?: number; // max delay in ms between reconnections
    minReconnectionDelay?: number; // min delay in ms between reconnections
    reconnectionDelayGrowFactor?: number; // how fast the reconnection delay grows
    minUptime?: number; // min time in ms to consider connection as stable
    connectionTimeout?: number; // retry connect if not connected after this time, in ms
    maxRetries?: number; // maximum number of retries
    maxEnqueuedMessages?: number; // maximum number of messages to buffer until reconnection
    startClosed?: boolean; // start websocket in CLOSED state, call `.reconnect()` to connect
    debug?: boolean; // enables debug output
};

Default values

WebSocket: undefined,
maxReconnectionDelay: 10000,
minReconnectionDelay: 1000 + Math.random() * 4000,
reconnectionDelayGrowFactor: 1.3,
minUptime: 5000,
connectionTimeout: 4000,
maxRetries: Infinity,
maxEnqueuedMessages: Infinity,
startClosed: false,
debug: false,
*/
