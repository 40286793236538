import { Component, OnInit, Input } from '@angular/core';
import { NgEventBus } from "ng-event-bus";

@Component({
  selector: 'app-request-sla-response-count',
  templateUrl: './request-sla-response-count.component.html',
  styleUrls: ['./request-sla-response-count.component.scss']
})
export class RequestSlaResponseCountComponent implements OnInit {

  @Input() tableHeader: any;
  @Input() tableData: any;
  @Input() widgetData:any;


  constructor(
    private eventBus: NgEventBus
  ) { }

  ngOnInit(): void {
    if (this.tableHeader){
      //console.log(this.tableHeader,this.tableData)
    }
 
  }
  showRequestSlaSidebar= (data, col, row) => {
    // return false
    if(row?.item_filter){
      if(col) {
				row.item_filter.filters['request_id__in'] = row?.[col + "_list"]
			}
			let payload = {
				"title": "Request - " + row.name,
				"app_type": "req-process-grid",
				"item_filter": row.item_filter,
			}
			this.eventBus.cast('dashboard-view:sidebar', payload);		
	}
}

}
