<div class="slideout-content default-stepping sm-stepping">
	<div class="modalsd modal-slide-in sdfade" id="modals-slide-in">
		<div class="modal-dialog sidebar-md">
			<!-- form-width -->
			<div class="modal-content pt-0 pb-0">
				<div class="modal-header d-flex justify-content-between align-items-center border">
					<h5
						*ngIf="addIncident?.submit_type == 'ticket'"
						class="modal-title mb-0 fw-600"
						id="exampleModalLabel"
					>
						{{ "UI.k_submit_a_ticket" | translate }}
					</h5>
					<h5
						*ngIf="addIncident?.submit_type == 'request'"
						class="modal-title mb-0 fw-600"
						id="exampleModalLabel"
					>
						{{ "UI.k_submit_a_request" | translate }}
					</h5>
					<button
						type="button"
						data-dismiss="modal"
						id="btnClose"
						name="btnClose"
						aria-label="Close"
						(click)="toggleSidebar()"
						class="btn btn-flat-secondary btn-icon"
						rippleEffect
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<form #ticketForm="ngForm" class="stepper-inner flex-auto-height">
					<div class="modal-body flex-auto-height">
						<div class="flex-1">
							<ng-scrollbar class="custom-scrollbar section-scrollbar">
								<div class="modal-body-inner py-2 px-1">
									<div class="two-column-grid">
										<div class="form-group">
											<label for="txtName" class="form-label">
												{{ "UI.k_name" | translate }} *</label
											>
											<input
												type="text"
												class="form-control"
												id="txtName"
												name="txtName"
												[attr.data-testid]="'txtName'"
												placeholder="{{ 'UI.k_type_here' | translate }}"
												required
												minlength="3"
												maxlength="40"
												[(ngModel)]="addIncident.requester.full_name"
												#txtNameInput="ngModel"
												[class.error]="txtNameInput.invalid && ticketForm.submitted"
												disabled="true"
											/>
											<span
												class="invalid-form"
												*ngIf="ticketForm.submitted && txtNameInput.invalid"
											>
												<small
													class="form-text text-danger"
													*ngIf="txtNameInput.errors.required"
													>{{ "UI.k_field_required" | translate }}
												</small>
											</span>
											<span
												class="invalid-form"
												*ngIf="ticketForm.submitted && txtNameInput.invalid"
											>
												<small
													class="form-text text-danger"
													*ngIf="txtNameInput.errors.minlength"
												>
													{{ "Error.err_input_min_char" | translate }}
												</small>
											</span>
										</div>
										<div class="form-group">
											<label for="txtName" class="form-label">
												{{ "UI.k_email_address" | translate }} *</label
											>
											<input
												type="text"
												class="form-control"
												id="txtEmail"
												name="txtEmail"
												[attr.data-testid]="'txtEmail'"
												placeholder="{{ 'UI.k_type_here' | translate }}"
												required
												minlength="3"
												maxlength="40"
												[(ngModel)]="addIncident.requester.email"
												#txtEmailInput="ngModel"
												[class.error]="txtEmailInput.invalid && ticketForm.submitted"
												disabled="true"
											/>
											<span
												class="invalid-form"
												*ngIf="ticketForm.submitted && txtEmailInput.invalid"
											>
												<small
													class="form-text text-danger"
													*ngIf="txtEmailInput.errors.required"
													>{{ "UI.k_field_required" | translate }}
												</small>
											</span>
											<span
												class="invalid-form"
												*ngIf="ticketForm.submitted && txtEmailInput.invalid"
											>
												<small
													class="form-text text-danger"
													*ngIf="txtEmailInput.errors.minlength"
												>
													{{ "Error.err_input_min_char" | translate }}
												</small>
											</span>
										</div>
									</div>
									<div
										class="card bg-body p-1 mt-1 border"
										*ngIf="
											addIncident?.asset?.ip_address ||
											addIncident?.asset?.hostname ||
											addIncident?.asset?.asset_id
										"
									>
										<div class="d-flex justify-content-between align-items-center">
											<span class="sm-font fw-500 title-color">{{
												"UI.k_asset" | translate
											}}</span>
											<!-- <div class="d-flex align-items-center">
                                            <button
                                                type="button"
                                                (click)="info_action()"
                                                class="btn btn-sm btn-icon btn-flat-primary waves-effect"
                                                rippleEffect
                                            >
                                                <i class="fa-light fa-pen-to-square"></i>
                                            </button>
                                        </div> -->
										</div>
										<div class="d-flex mt-50 align-items-baseline justify-content-between">
											<div class="">
												<span class="xs-font">{{ "UI.k_ip_address" | translate }}</span>
												<div class="">
													<span *ngIf="addIncident?.asset && !addIncident?.asset?.ip_address"
														>-</span
													>
													<span
														*ngIf="addIncident?.asset && addIncident?.asset?.ip_address"
														class="xs-font title-color"
													>
														{{ addIncident?.asset?.ip_address }}
													</span>
												</div>
											</div>
											<div class="">
												<div>
													<span class="xs-font">{{ "UI.k_ci_id" | translate }}</span>
												</div>
												<div>
													<span *ngIf="addIncident?.asset && !addIncident?.asset?.asset_id"
														>-</span
													>
													<span
														*ngIf="addIncident?.asset && addIncident?.asset?.asset_id"
														class="xs-font title-color"
													>
														{{ addIncident?.asset?.asset_id }}
													</span>
												</div>
											</div>
											<div class="">
												<div>
													<span class="xs-font">{{ "UI.k_hostname" | translate }}</span>
												</div>
												<div>
													<span *ngIf="addIncident?.asset && !addIncident?.asset?.hostname"
														>-</span
													>
													<span
														*ngIf="
															addIncident?.asset &&
															addIncident?.asset?.hostname &&
															addIncident?.asset?.hostname.length < 20
														"
														class="xs-font title-color"
														>{{ addIncident?.asset?.hostname }}</span
													>
													<span
														*ngIf="
															addIncident?.asset &&
															addIncident?.asset?.hostname &&
															addIncident?.asset?.hostname.length >= 20
														"
														class="xs-font title-color"
														container="body"
														ngbTooltip="{{ addIncident?.asset?.hostname }}"
														>{{ (addIncident?.asset?.hostname | slice: 0:18) + "..." }}
													</span>
												</div>
											</div>
										</div>
									</div>

									<div class="form-group">
										<label for="txtRequestType" class="form-label">
											{{ "UI.k_type" | translate }}
										</label>
										<ng-select
											[items]="dropdown.ticket_type_list"
											[(ngModel)]="addIncident.submit_type"
											bindLabel="display_key"
											bindValue="id"
											name="txtRequestType"
											id="txtRequestType"
											placeholder="{{ 'UI.k_select_request_type' | translate }}"
											[hideSelected]="true"
											[clearable]="false"
											[disabled]="addIncident?.asset?.asset_id"
											(change)="onRequestTypeChange($event)"
											[attr.data-testid]="'txtRequestType'"
										>
											<ng-template
												ng-option-tmp
												let-item="item"
												let-index="index"
												let-search="searchTerm"
											>
												<span>
													{{ item?.display_key | translate }}
												</span>
											</ng-template>
											<ng-template ng-label-tmp let-item="item">
												{{ item?.display_key | translate }}
											</ng-template>
										</ng-select>
									</div>
									<div
										class="form-group input-tree-dropdown"
										*appDnsLicenseHandler="{
											module_key: 'SERVICEDESK',
											keys: ['SERVICE_CATALOGUE']
										}"
										[attr.data-testid]="'needHelpWith'"
									>
										<label for="selHelpOptions" class="form-label">{{
											"UI.k_need_help_with" | translate
										}}</label>
										<!-- <ng-select [items]="helpOptions" bindLabel="name" bindValue="name"
                                            class="form-control" name="selHelpOptions" id="selHelpOptions"
                                            placeholder="{{ 'UI.k_select' | translate }}"
                                            [(ngModel)]="addIncident.help" #helpOptionsSel="ngModel"
                                            [class.error]="helpOptionsSel.invalid && ticketForm.submitted">
                                        </ng-select> -->
										<ng-container *ngIf="service_config?.is_editable">
											<app-single-tree-dropdown
												class="w-100"
												[items]="dropdown?.formatted_services"
												[value]="addIncident.impact_service"
												[id]="'inciService'"
												(itemChange)="serviceValueChange($event)"
											></app-single-tree-dropdown>
										</ng-container>
										<div
											class="ng-select ng-select-single ng-select-searchable ng-select-clearable ng-untouched ng-pristine ng-valid"
											*ngIf="!service_config?.is_editable"
											(click)="onImpactServiceClick($event, 'impact_service')"
										>
											<div class="ng-select-container">
												<div class="ng-value-container">
													<div class="">
														{{
															addIncident?.impact_service_tree_path ||
																("UI.k_select_option" | translate)
														}}
													</div>
													<div role="combobox" class="ng-input">
														<input type="text" />
													</div>
												</div>
												<span class="ng-arrow-wrapper" id="arrow-self"
													><span class="ng-arrow"></span
												></span>
												<span
													*ngIf="service_config?.is_loading"
													class="fa fa-spinner fa-spin fa-2x"
												>
												</span>
											</div>
										</div>
										<!-- <span class="invalid-form"
                                            *ngIf="ticketForm.submitted && helpOptionsSel.invalid">
                                            <small class="form-text text-danger"
                                                *ngIf="helpOptionsSel.errors.required">{{ 'UI.k_field_required' |
                                                translate }}
                                            </small>
                                        </span> -->
									</div>
									<div
										class="form-group input-tree-dropdown"
										*appDnsLicenseHandler="{
											module_key: 'SERVICEDESK',
											keys: ['SERVICE_CATALOGUE']
										}"
										[attr.data-testid]="'serviceClassification'"
									>
										<label for="txtIncidentServiceClass" class=""
											>{{ "UI.k_service_classification" | translate }}
										</label>
										<!-- [treeInputConfig]="{'isParentAllowed': true}"   -->
										<ng-container *ngIf="classification_config?.is_editable">
											<app-single-tree-dropdown
												class="w-100"
												[items]="dropdown?.formatted_classification_data"
												[value]="addIncident.service_classification"
												[id]="'inciClassification'"
												[treeInputConfig]="{ isParentAllowed: true }"
												(itemChange)="classificationValueChange($event)"
											>
											</app-single-tree-dropdown>
										</ng-container>
										<div
											class="ng-select ng-select-single ng-select-searchable ng-select-clearable ng-untouched ng-pristine ng-valid"
											*ngIf="!classification_config?.is_editable"
											(click)="onImpactServiceClick($event, 'service_classification')"
										>
											<div class="ng-select-container">
												<div class="ng-value-container">
													<div class="">
														{{
															addIncident?.service_classification_path ||
																("UI.k_select_option" | translate)
														}}
													</div>
													<div role="combobox" class="ng-input">
														<input type="text" />
													</div>
												</div>
												<span class="ng-arrow-wrapper"><span class="ng-arrow"></span></span>
												<span
													*ngIf="classification_config?.is_loading"
													class="fa fa-spinner fa-spin fa-2x"
												>
												</span>
											</div>
										</div>
									</div>
									<div
										class="row"
										*ngIf="
											service_form_details?.request_form?.length > 0 &&
											addIncident.submit_type == 'request'
										"
									>
										<div class="form col-12">
											<a class="text-primary specification"
												>{{ "UI.k_add" | translate }} {{ "UI.k_specification" | translate }}</a
											>
										</div>
										<div class="col-12">
											<app-dns-service-form
												placeholder="requirementSection"
												[formRef]="ticketForm"
												[data]="service_form_data"
												[config]="service_form_details"
												[selfservice]="true"
											></app-dns-service-form>
										</div>
									</div>

									<div class="form-group">
										<label for="txtSubject" class="form-label">
											{{ "UI.k_subject" | translate }} *</label
										>
										<input
											type="text"
											class="form-control"
											id="txtSubject"
											name="txtSubject"
											placeholder="{{ 'UI.k_type_here' | translate }}"
											required
											minlength="3"
											maxlength="1000"
											[(ngModel)]="addIncident.summary"
											#txtSubjectInput="ngModel"
											[appWhiteSpaceCheck]="addIncident.summary"
											[attr.data-testid]="'txtSubject'"
											[class.error]="txtSubjectInput.invalid && ticketForm.submitted"
										/>
										<span
											class="invalid-form"
											*ngIf="ticketForm.submitted && txtSubjectInput.invalid"
										>
											<small
												class="form-text text-danger"
												*ngIf="txtSubjectInput?.errors?.required"
												>{{ "UI.k_field_required" | translate }}
											</small>
											<small
												class="form-text text-danger xs-font"
												*ngIf="
													txtSubjectInput.errors.minlength || txtSubjectInput.errors.maxlength
												"
												>{{ "UI.k_subject_name_validation" | translate }}
											</small>
											<small
												class="form-text text-danger xs-font"
												*ngIf="txtSubjectInput.errors?.whitespace"
												>{{ "UI.k_leading_trailing_space_validation" | translate }}</small
											>
										</span>
									</div>
									<div class="form-group impact-asset-dropdown"  *appDnsLicenseHandler="{'module_key': 'ASSET', keys: ['ASSET_MANAGEMENT']}">
										<div *ngIf="from_ticket_add && show_asset_drpdwn && (addIncident?.submit_type == 'ticket')">
										<label for="selImpactAsset" class="form-label">
											{{ "UI.k_impacted_asset" | translate }}</label
										>
										<ng-select
											[items]='impacted_asset_list'
											[multiple]=false
											[closeOnSelect]="true"
											name="selImpactAsset"
											id="selImpactAsset"
											class="form-control no-padding-select"
											placeholder="{{
												'UI.k_src_impacted_asset'
													| translate
											}}"
											notFoundText="{{
												'UI.k_no_items_found'
													| translate
											}}"
											[attr.data-testid]="'selImpactAsset'"
											[searchFn]="filterImpactedAssetList"
											[(ngModel)]="addIncident.impacted_asset"
										> 
										<ng-template ng-label-tmp let-item="item" let-clear="clear">
											<div class="mainclass d-flex justify-content-between mr-1 gap-2">
												<div class="d-flex justify-content-start align-items-center gap-1">
													<span>{{ item?.ci_name }}</span>
													<div *ngIf = "item?.ip_address">
														<i class="fa-light fa-globe text-primary font-small-5"></i>
															<span>
																{{
																	item?.ip_address?.length > 32
																	? (item?.ip_address | slice: 0:32) + "..."
																	: item?.ip_address
																}}
															</span>
													</div>
												</div>
												<div class="d-flex gap-1">
													<div *ngIf="item?.common_info?.status" class="d-flex">
														<ng-container>
															<div
																class="group-lists flex-wrap d-flex align-items-center"
															>
																<div
																	class="custom-squre ml-0"
																	[ngClass]="'bg-' + item?.common_info?.status?.color"
																	[ngStyle]="{
																		'background-color':
																			item?.common_info?.status?.color
																	}"
																></div>
																<span
																	class="sm-font fw-500"
																>
																	{{
																		item?.common_info?.status?.name?.length > 12
																			? (item?.common_info?.status?.name
																					| slice: 0:12) + ".."
																			: item?.common_info?.status?.name
																	}}
																</span>
															</div>
														</ng-container>
													</div>
													<span
														*ngIf="
														item?.device_type &&
														getImageSrc(item?.device_type, 'device_type') != ''
														"
													>
														<img
															class="thumbnail-image"
															[src]="getImageSrc(item?.device_type, 'device_type')"
															height="24"
														/>
													</span>
													<span
														*ngIf="
														item?.hardware?.make &&
														getImageSrc(item?.hardware?.make, 'make') != ''
														"
													>
														<img
															class="thumbnail-image"
															[src]="getImageSrc(item?.hardware?.make, 'make')"
															height="24"
														/>
													</span>
													<span
														*ngIf="
															item?.hardware?.os_name &&
															getImageSrc(item?.hardware?.os_name, 'os_name') != ''
														"
													>
														<img
															class="thumbnail-image"
															[src]="getImageSrc(item?.hardware?.os_name, 'os_name')"
															height="24"
														/>
													</span>
												</div>
											</div>
										</ng-template>
											<ng-template ng-option-tmp let-item="item">
												<div class="mainclass d-flex justify-content-between">
													<div class="d-flex gap-1">
														<div class="d-flex flex-column">
															<span>{{ item?.ci_name }}</span>
															<span>{{ item?.asset_id }}</span>
														</div>
														<div class="pt-50" *ngIf = "item?.ip_address">
															<i class="fa-light fa-globe text-primary font-small-5"></i>
																<span>
																	{{
																		item?.ip_address?.length > 32
																		? (item?.ip_address | slice: 0:32) + "..."
																		: item?.ip_address
																	}}
																</span>
														</div>
													</div>
													<div class="pull-right d-flex gap-1">
														<div *ngIf="item?.common_info?.status" class="d-flex">
															<ng-container>
																<div
																	class="group-lists flex-wrap d-flex align-items-center"
																>
																	<div
																		class="custom-squre ml-0"
																		[ngClass]="'bg-' + item?.common_info?.status?.color"
																		[ngStyle]="{
																			'background-color':
																				item?.common_info?.status?.color
																		}"
																	></div>
																	<span
																		class="sm-font fw-500"
																	>
																		{{
																			item?.common_info?.status?.name?.length > 12
																				? (item?.common_info?.status?.name
																						| slice: 0:12) + ".."
																				: item?.common_info?.status?.name
																		}}
																	</span>
																</div>
															</ng-container>
														</div>
														<span
															class="pt-50"
															*ngIf="
															item?.device_type &&
															getImageSrc(item?.device_type, 'device_type') != ''
															"
														>
															<img
																class="thumbnail-image"
																[src]="getImageSrc(item?.device_type, 'device_type')"
																height="24"
															/>
														</span>
														<span
															class="pt-50"
															*ngIf="
															item?.hardware?.make &&
															getImageSrc(item?.hardware?.make, 'make') != ''
															"
														>
															<img
																class="thumbnail-image"
																[src]="getImageSrc(item?.hardware?.make, 'make')"
																height="24"
															/>
														</span>
														<span
															class="pt-50"
															*ngIf="
																item?.hardware?.os_name &&
																getImageSrc(item?.hardware?.os_name, 'os_name') != ''
															"
														>
															<img
																class="thumbnail-image"
																[src]="getImageSrc(item?.hardware?.os_name, 'os_name')"
																height="24"
															/>
														</span>
													</div>
												</div>
											</ng-template>
										</ng-select>
										</div>
									</div>
									<div class="form-group">
										<label for="profileName" class="form-label">
											{{ "UI.k_lets_hear_details" | translate }} *
										</label>
										<div class="p-0 card border quill-card">
											<textarea
												name="txtaDetails"
												id="txtaDetails"
												[attr.data-testid]="'txtaDetails'"
												rows="5"
												class="form-control border-0"
												placeholder="{{ 'UI.k_let_us_know_what_you_need' | translate }}"
												required
												minlength="3"
												[(ngModel)]="addIncident.erichTextData"
												#txtDetailInput="ngModel"
												[appWhiteSpaceCheck]="addIncident.erichTextData"
												[class.error]="txtDetailInput.invalid && ticketForm.submitted"
											></textarea>
											<div class="pl-2 pr-2 row">
												<span
													*ngFor="let file of addIncident?.files; let i = index"
													class="text-primary border rounded m-half p-25"
													><i class="fa-light fa-file-lines"></i>&nbsp;{{
														file.file_name
													}}&nbsp;
													<span
														(click)="removeAttachedFile(i, addIncident?.files)"
														id="btnFileAttachmentClose{{ i }}"
													>
														<i class="fa-light fa-xmark"></i>
													</span>
												</span>
											</div>
											<div class="compose-footer-wrapper email-footer p-75 d-flex">
												<div class="btn-wrapper d-flex align-items-center">
													<!-- add attachment -->
													<div class="email-attachement">
														<label
															for="file-input"
															class="add_attachment"
															[attr.data-testid]="'addAttachment'"
														>
															<img
																src="assets/images/icons/icon_link.svg"
																width="12"
																height="12"
															/>
															<span
																class="fw-500 text-primary sm-font ml-50 cursor-pointer"
																>{{ "UI.k_add_attachment" | translate }}
															</span>
														</label>
														<input
															(change)="fileUpload($event)"
															id="file-input"
															type="file"
															class="d-none"
														/>
													</div>
												</div>
												<br />
											</div>
										</div>
										<span
											class="invalid-form"
											*ngIf="ticketForm.submitted && txtDetailInput.invalid"
										>
											<small class="form-text text-danger" *ngIf="txtDetailInput.errors.required"
												>{{ "UI.k_field_required" | translate }}
											</small>
											<small
												class="form-text text-danger xs-font"
												*ngIf="txtDetailInput.errors.minlength"
												>{{ "Error.err_min_3_character" | translate }}
											</small>
											<small
												class="form-text text-danger xs-font"
												*ngIf="txtDetailInput.errors?.whitespace"
												>{{ "UI.k_leading_trailing_space_validation" | translate }}</small
											>
										</span>
									</div>
									<div class="col-12 pl-0">
										<app-dns-custom-field
											placeholder="general"
											[formRef]="ticketForm"
											[data]="custom_field_data"
											[config]="custom_field_config"
											[selfservice]="true"
										></app-dns-custom-field>
									</div>
								</div>
							</ng-scrollbar>
						</div>
						<div class="modal-btn-group flex-initial border-top">
							<div class="d-flex justify-content-end stepper-btn flex-initial">
								<button
									type="button"
									(click)="toggleSidebar()"
									class="btn btn-outline-tertiary btn-prev"
									rippleEffect
								>
									<span class="align-middle"> {{ "UI.k_cancel" | translate }} </span>
								</button>
								<button
									type="submit"
									(click)="onSubmit(ticketForm)"
									class="btn btn-primary btn-next ml-1"
									[disabled]="disableSaveBtn"
									[attr.data-testid]="'btn_Save'"
									rippleEffect
								>
									<span *ngIf="disableSaveBtn" class="spinner-border spinner-border-sm mr-1"></span>
									<span class="align-middle">
										<i class="fa-light fa-floppy-disk"></i>&nbsp;&nbsp;
										{{ "UI.k_save" | translate }}</span
									>
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
