import {
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
} from "@angular/core";
import { CoreConfigService } from "@core/services/config.service";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { interval, Subject, Subscription, throwError } from "rxjs";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GridNameDesignComponent } from "../../../../common/dns/components/grid-name-design/grid-name-design.component";
import { Breadcrumb } from "app/common/layout/components/content-header/breadcrumb/breadcrumb.component";
import { ActivatedRoute, Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { GlobalConstants } from "app/app.globalConstants";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { catchError, debounceTime, distinctUntilChanged, map, takeUntil } from "rxjs/operators";

import { AssetGridNameComponent } from "app/base/asset/components/asset-grid-name/asset-grid-name.component";
import { InfinityModules } from "app/common/dns/types/modules";
import { StatusBadgeComponent } from "app/common/dns/components/status-badge/status-badge.component";
import { _deepCopyJson, getAssetImageSrc, getTranslatableKey } from "app/common/utils/utils";
import { environment } from "environments/environment";
import { auto } from "@popperjs/core";
import { WSSService } from "../../../../common/services/wss/wss.service";
import { DndDropEvent, DropEffect } from "ngx-drag-drop";
import { InfraonMixpanelService } from "app/services/mixpanel/infraon-mixpanel.service";
import { AssetService } from "app/base/asset/services/asset.service";
import { NgScrollbar } from "ngx-scrollbar";
var AES = require("crypto-js/aes");

@Component({
	selector: "dns-asset-select",
	templateUrl: "./dns-asset-select.component.html",
	styleUrls: ["./dns-asset-select.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class DnsAssetSelectComponent implements OnInit, OnChanges {
	@ViewChild("modCloseIncidentPopup") modCloseIncidentPopup: ElementRef;
	@Input() filter: any = [];
	@Input() assetType: string;
	@Input() requestFrom = "";
	@Input() extraFilters = {};
	@Input() extraColumns = [];
	@Output() getAllCategories: EventEmitter<any> = new EventEmitter();
	@Output() leftSidebar: EventEmitter<boolean> = new EventEmitter();
	@Output() hideAddAsset = new EventEmitter(false);
	@Input() submenuValue: boolean;
	@Input() assetCount: any;
	@Input() sortKey: any = "asset_id";
	@Input() reverse: any = "";
	@Input() requester: any = {};
	@Input() selected_impacted_asset: any = [];
	@ViewChild("itAssetItemGrid") itAssetItemGrid;
	public showSidebarDetails: any = {};
	isScanDevice = false;
	deviceFound = false;
	isDisableDevice = false;
	showFilter: boolean = true;
	showRightActions: boolean = true;
	selectionCheckboxType: any;
	event: any;
	currentEvent: String;
	columns: any;
	SelectionType = SelectionType;
	ColumnMode = ColumnMode;
	pageNumberGrid = 0;
	pageNumberCard = 1;
	limit = 20;
	cmdbCI: any;
	ciCount = 0;
	scheduleData = { schedule_mode: "immediate", schedule_period: "executenow" };
	@ViewChild('pannelViewScroll') pannelViewScroll: NgScrollbar;
	private _scrollSubscription = Subscription.EMPTY;
	scrollPercentage: number;
	showDataOnscrollFrom: number = 90;
	loadData: boolean = true;
	metaCount: number;
	changeResponseResult: any;
	isBulkPrint = false;
	public assetcard;
	finished = false;
	selectedOption = 10;
	cardCount = 0;
	firstValue = 0;
	lastValue = this.selectedOption;
	totalAsset;
	noAssetMsg = "k_no_assets";
	public breadcrumbLeft: Breadcrumb;
	showBreadcrumb: any = false;
	module = InfinityModules.CMDB;
	maintenance_module = InfinityModules.MAINTENANCE;
	permissions: any;
	maintenance_permissions: any;
	asset_ci_id: any;

	showItemSlider = false;
	hideSidebar: boolean = true;
	currentItem = -1;
	state = -1;
	common_col = [];
	common_actions = [];
	public showData = false;
	public empty_data;
	public selectedData = [];
	private _unsubscribeAll: Subject<any>;
	public rowData;
	public multiselectOptions = [];
	public multioption;
	showBulkUpdateSidebar = false;
	showAllocateSidebar = false;
	public showAssigneeSidebar: any = false;
	showTag = false;
	public filterData = false;
	public filters = [];
	showImportAsset = false;
	showExportAsset = false;
	assetData: any;
	agent_type: string = "inventory";
	// Search
	public showRightsection: false;
	public showDataTable = false;
	public savedData = [];
	public columnOptions = [];
	public conditionOptions = [];
	public option: any;
	public showAll = false;
	public DupFilterstring;
	public pageReload: any;
	public isPause: boolean = false;
	public showAPI: boolean = false;
	// for card view
	showCardHeader = false;
	selectedCards = [];
	//sidebar data
	public sidebar_data: any;
	public getImageSrc = getAssetImageSrc;
	public itemInput;
	public checkfilter = false;
	public scanInputData = null;
	public serialNo = null;
	public scanTooltip = "Message.msg_scan_searchadd";
	modelChanged: Subject<string> = new Subject<string>();
	fromExternalComponent: boolean = false;
	public req_base_incident = {
		assetIdList: [],
		asset_inci_count: [],
		closure_note: "",
	};
	public imgHeightWidthStr = "img height='400px' width='100%'";
	public selAction;
	public getTranslatableKey = getTranslatableKey;
	public actionList = [];
	public is_processing = false;
	isAllocate;
	loading = false;
	is_form_submitted = false;
	totalAssetCount: any = 0;
	showAssetCsvEdit: boolean = false;
	loadCategories: boolean = false;
	public isNCCMenabled: boolean = false;
	categoryProperties: any;
	page_data_loading = false;
	enabledColumnType: any;
	public licenseData: any = {};
	public showCurrentCost = 0;
	assetPrefix;
	selected_key: any = "asset_prefix_config";
	availCols: any = [];
	availableColumns: Array<any>;
	confDisplayedColumns: any = [];
	selectedCols: any = [];
	public actual_selected_cols = [];
	gridColumns: any[];
	public responseGridColumns = {};
	grid_columns: any = [];
	VISIBILITY_IGNORE: any = [
		"ci_id",
		"device_type",
		"class_name",
		"object_type",
		"organization",
		"ci_type",
		"ci_category",
		"ci_sub_category",
		"asset_id",
		"is_enabled",
		"is_demo_data",
		"is_disabled",
		"isAssign",
		"isDeallocate",
		"owner",
		"requester",
		"owners",
		"used_by",
		"managed_by",
		"maintenance_scheduled",
		"maintenance_started",
		"maintenance_ids",
		"maintenance_details",
	];
	isLoading: boolean = true;
	selected_asset: any;
	searchObject = {
		advannceFilerPopup: false,
		userEnterPopup: false,
		multipleFilterPopup: false,
		userSearchData: [
			{
				"keyFilterValue": null,
				"key": "Requesters",
				"selected": true
			}
		],
		userSearchString: [{
            "key": "Requesters",
            "condition": "equal to",
            "value": null,
            "db_col": "requester__email",
            "type": "dropdown",
            "subkey": ""
        }],
		userDispalyString: null,
		userInputSearchData: null,
		filterData: [],
		previousSearchedData: null,
		searchCount: 0,
		copyOfMainDatasource: [],
		addFilterArray: [
			{
				id: 1,
				selectedColumn: null,
				selectedCondition: null,
				dataConfig: null,
				optionList: []
			}
		],
		multiPleFilterCondition: [],
		savedFilterData: [],
		searchedData: {},
		duplicatesavedFilterData: [],
		recentSearchedData: [],
		dupSearchedData: [],
		filterItem: [],
		isScroll: false,
	};

	constructor(
		@Inject(DOCUMENT) private document,
		private _activatedRoute: ActivatedRoute,
		private _coreSidebarService: CoreSidebarService,
		private modalService: NgbModal,
		private _coreConfigService: CoreConfigService,
		private _service: AssetService,
		private _translateService: TranslateService,
		private _assetService: AssetService,
		private router: Router,
		private _toastr: ToastrService,
		private elementRef: ElementRef,
		private _wssService: WSSService,
		private _mixpanelService: InfraonMixpanelService
	) {
		this._unsubscribeAll = new Subject();
		this.modelChanged
			.pipe(
				debounceTime(300), // wait 300ms after the last event before emitting last event
				distinctUntilChanged()
			) // only emit if value is different from previous value
			.subscribe((model) => {
				this.scanInputData = model;
				// this.serialNo = this.scanInputData;
				if (this.scanInputData) {
					let payload = { asset_id: this.scanInputData, asset_type: this.assetType };
					this._service.checkCMDBExists(payload).subscribe(
						(response) => {
							if (response?.status === "success") {
								let url = "/" + response?.asset_type + "/asset/";
								let ci_id = response?.ci_id;
								if (response?.is_enabled == false) {
									this.deviceFound = false;
									this.isScanDevice = false;
									this.isDisableDevice = true;
								} else {
									this.router.navigateByUrl(url + ci_id.toString());
									this.isScanDevice = false;
									this.deviceFound = true;
									this.isDisableDevice = false;
									this.modalService.dismissAll();
									this.scanInputData = null;
								}
							} else {
								this.isScanDevice = false;
								this.deviceFound = false;
								this.serialNo = this.scanInputData;
								this.isDisableDevice = false;

								// this.scanInputData = null;
							}
						},
						(error) => {
							//todo
							this.isScanDevice = false;
							this.deviceFound = false;
							this.scanInputData = null;
							this.isDisableDevice = false;
						}
					);
				}
			});
	}

	toggleSidebar = (name, key): void => {
		this.showSidebarDetails[key] = true;
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
	};

	searchUpdate(event) {
		const val = event.target.value.toLowerCase();
	}


	ngOnInit(): void {
		this.getAssetCategoryProperties();
		this.getFilterOption();
		this.getSavedFilters();
		this.getInitOptionConfig();
		this.empty_data = {
			icon: "fa-box-taped",
			msg: this._translateService.instant("Message.msg_asset_skeleton_heading"),
			description: this._translateService.instant("Message.msg_asset_skeleton_descr"),
			sidebar_name: "item-sidebar",
			label: "UI.k_add_item",
			import_csv: false,
			id: "btnAddItem",
			type: "btn-dropdown",
		};
		let user_permissions: any = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		if (Object.keys(user_permissions).length != 0) {
			this.licenseData = user_permissions?.usr_lic?.keys_dict?.MONITORING;
		}
		this.permissions = user_permissions[this.module];
		if (this.assetType == "cmdb-view") {
			if (this.permissions?.view == true) {
				this.permissions = { view: true };
			} else {
				this.permissions = {};
			}
		}
		this.totalAsset = this.cmdbCI?.length;
		if (this.assetType === "it-asset") {
			this.scanTooltip = "Message.msg_scan_search";
		}
		if (this.requester){
			let req_email = this.requester?.email;
			this.searchObject.userSearchData[0].keyFilterValue = "equal to(" + req_email + ")";
			this.searchObject.userSearchString[0].value = req_email;
			this.searchObject.userDispalyString = "Requesters:equal to(" + req_email + ")";
			this.searchObject.addFilterArray[0].dataConfig = req_email
		}
		setTimeout(() => {
			Object.assign(this.searchObject.filterItem, this.searchObject.addFilterArray)
			this.getFilterSearch(this.searchObject)
		}, 600);
		// this.getItemList(this.filters, true);
		this._wssService
			.getWsDataStore()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((res: any) => {
				if (res && res?.channel === "cmdb:update") {
					if (!this.isPause) {
						this.getItemList(this.filter);
					}
				}
			});
		if (this.assetType === "it-asset") {
			this._mixpanelService.trackEvent("IT Asset", { view: "Data loaded" });
		} else if (this.assetType == "cmdb-view") {
			this._mixpanelService.trackEvent("CMDB View", { view: "Data loaded" });
		} else {
			this._mixpanelService.trackEvent("Fixed Asset", { view: "Data loaded" });
		}
		if (this.selected_impacted_asset?.length > 0){
			this.selected_asset = this.selected_impacted_asset[0]?.ci_id
		}
	}

	
	ngOnDestroy(): void {
		Swal.close();
		this.modalService.dismissAll();
	}


	onSelect = (args) => {};

	showSuccessMsg(response, msg) {
		if (response.status == "success") {
			this._toastr.success(
				this._translateService.instant(response["message"]),
				this._translateService.instant(msg),
				{
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				}
			);
		} else {
			this._toastr.error(
				this._translateService.instant(response["message"]),
				this._translateService.instant("Error.err_delete_failed"),
				{
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				}
			);
		}
	}



	getAssetCategoryProperties() {
		this._service.getAssetCategoryProperties({}).subscribe((response) => {
			if (response) {
				this.categoryProperties = response;
			}
		});
	}

	getItemList(filter, is_init: any = false) {
		// this.showData = false;
		this.page_data_loading = true;
		this.selectedData = [];
		let temp = [];

		if (!this.checkfilter) {
			this.filters = [];
		} else {
			this.filters[0]["filter_string"] = JSON.parse(JSON.stringify(this.DupFilterstring));
			for (let i = 0; i < filter?.length; i++) {
				if (!filter[i]?.page) {
					this.filters[0]["filter_string"].push(filter[i]);
				}
			}
		}
		temp["type"] = "apply";
		temp["module"] = "cmdb";
		temp["sub_type"] = this.assetType;
		for (let i = 0; i < filter?.length; i++) {
			if (filter[i]?.page == "reset") {
				this.pageNumberCard = 1;
				this.filter[i]["page"] = "";
			}
		}
		if (filter?.length > 1) {
			this.filters.push({ filter_string: filter });
		} else {
			this.filters.push({ filter_string: temp });

			filter = "";
			if (this.common_actions.length > 0) {
				this.multiselectOptions = [...this.common_actions];
			}
			if (this.common_col.length > 0) {
				this.columns = [...this.common_col];
			}
		}
		let payload = {
			items_per_page: this.showAll && this.event === "add" ? Number(this.limit) + 1 : Number(this.limit),
			sort: this.sortKey,
			reverse: this.reverse,
			// filterString: "object_type:(Node) " + filter,
			view: "card",
			filter: JSON.stringify(this.filters),
			module: "cmdb",
			type: "apply",
			asset_type: this.assetType,
			from_external: this.fromExternalComponent ? 1 : 0,
			find_current_cost: this.showCurrentCost,
			check_enabled: true
		};
		if (this.assetType == "cmdb-view") {
			payload["marked_as_ci"] = true;
		}

		if (this.pageNumberCard == 0) {
			this.pageNumberCard = 1;
		}
		payload["page"] = this.pageNumberCard;

		if (this.extraFilters) {
			payload["extraFilters"] = JSON.stringify(this.extraFilters);
		}
		console.time("getCMDBCis");
		this._service.getCMDBCis(payload).subscribe(
			(response) => {
				if (response) {
					console.timeEnd("getCMDBCis");
					this.page_data_loading = false;
					if (this.showAll && this.event === "add") {
						this.limit = Number(this.limit) + 1;
					}
					this.cmdbCI = response.results;
					this.changeResponseResult = this.cmdbCI;
					this.metaCount = response.count;
					this.totalAsset = response.count;
					this.cardCount = this.cmdbCI?.length;
					this.finished = true;
					this.cmdbCI.forEach((e) => {
						if (e?.owner?.owners?.length === 0 || e?.owner?.owners?.length === undefined) {
							e["isAssign"] = false;
							e["isAllocate"] = true;
							e["isDeallocate"] = true;
						}
						if (e?.requester?.length === 0 && e?.owner?.owners?.length != 0) {
							e["isAllocate"] = false;
							e["isDeallocate"] = true;
							e["isAssign"] = true;
						}
						if (e?.requester?.length != 0 && e?.requester?.length != undefined) {
							e["isDeallocate"] = false;
							e["isAllocate"] = true;
							e["isAssign"] = true;
						}
						if (!e?.is_enabled) {
							e["isAssign"] = true;
							e["isAllocate"] = true;
							e["isDeallocate"] = true;
						}
					});
					this.showData = true;
					this.selectedData = [];
					this.filterData = this.checkfilter;
					// if(this.loadCategories == true || response.count != this.totalAssetCount){
					// 	this.getAllCategories.emit({"loadCategories": true});
					// }
					this.loadCategories = false;
					setTimeout(() => {
						this.updateDataonscroll();
					}, 600);
				} else {
					this.page_data_loading = false;
				}
			},
			(error) => {
				//todo
				this.page_data_loading = false;
				this.showData = true;
			}
		);
		if (filter && is_init) {
			let tmpFilter: any = {};
			this.filters[0]["filter_string"]?.forEach((filter) => {
				if (filter?.db_col == "ci_category" || filter?.db_col == "ci_sub_category") {
					tmpFilter[filter?.db_col] = filter?.name;
				}
			});
		}
	}

	setPage(pageInfo) {
		this.pageNumberGrid = pageInfo.offset;
		this.getItemList(this.filter);
	}

	setLimit(limit): void {
		this.pageNumberGrid = 0;
		if (limit === "all") {
			this.showAll = true;
			this.limit = this.ciCount;
		} else {
			this.limit = Number(limit);
			this.showAll = false;
		}
		this.getItemList(this.filter);
	}

	ngOnChanges(changes: SimpleChanges) {
		this.itemInput = { categoryID: "", subCategoryID: "" };
		if (this.filter?.length > 0) {
			this.filter?.forEach((element) => {
				if (element?.db_col == "ci_category") {
					this.itemInput.categoryID = element?.value;
				}
				if (element?.db_col == "ci_sub_category") {
					this.itemInput.subCategoryID = element?.value;
				}
			});
			this.getItemList(this.filter, true);
		}
		this.totalAssetCount = changes?.assetCount?.currentValue;
		if (this.totalAssetCount == undefined) {
			this.totalAssetCount = this.limit;
		}
		if (changes?.assetCount?.currentValue && changes?.assetCount?.currentValue !== 0 && this.showAll) {
			this.limit = changes?.assetCount?.currentValue;
			this.getItemList(this.filter, true);
		}
	}

	checkView(viewName) {
		this.sortKey = "asset_id";
		this.limit = 10;
		this.pageNumberCard = 1;
		this.pageNumberGrid = 0;
		this.showAll = false;
		this.getItemList(this.filter);
		// this.showData = false
	}

	onSelectRow(data) {
		this.selectedData = data;
	}

	selectOneToaster() {
		this._toastr.error(
			this._translateService.instant("Message.msg_error"),
			this._translateService.instant("Error.err_select_one"),
			{
				toastClass: "toast ngx-toastr",
				closeButton: true,
				positionClass: "toast-top-right",
			}
		);
	}

	genericSearchFilter = ($event) => {
		let search_str = $event.userSearchData.trim();
		let search_db_cols = [
			{ db_col: "ci_name", cond: "contains" },
			{ db_col: "asset_id", cond: "contains" },
			{ db_col: "hostname", cond: "contains" },
			{ db_col: "ip_address", cond: "contains" },
			{ db_col: "device_type", cond: "contains" },
			{ db_col: "hardware__make", cond: "contains" },
			{ db_col: "hardware__model", cond: "contains" },
			{ db_col: "hardware__tag_no", cond: "contains" },
			{ db_col: "hardware__os_name", cond: "contains" },
			{ db_col: "hardware__serial_number", cond: "contains" },
			{ db_col: "requester__full_name", cond: "contains", islist: true },
			{ db_col: "requester__email", cond: "contains", islist: true },
			{ db_col: "owner__owners__full_name", cond: "contains", islist: true },
			{ db_col: "owner__owners__email", cond: "contains", islist: true },
			{ db_col: "location__location_name", cond: "contains" },
			{ db_col: "owner__department__dept_name", cond: "contains" },
			{ db_col: "common_info__status__name", cond: "contains" },
			{ db_col: "common_info__status__state", cond: "contains" },
			// { db_col: "common_info__operational_status__name", cond: "contains" },
		];
		let filter_options = [];
		search_db_cols.forEach((item) => {
			filter_options.push({
				condition: item.cond,
				db_col: item.db_col,
				value: search_str,
				relation: "or",
				islist: item.islist,
			});
			if (filter_options.length == search_db_cols.length) {
				this.filters = [];
				let dict = {};
				dict["ui_display"] = $event.userSearchData;
				dict["filter_string"] = filter_options;
				dict["filter_item"] = $event.filterItem;
				for (let i = 0; i < this.filter.length; i++) {
					dict["filter_string"].push(this.filter[i]);
				}
				this.DupFilterstring = JSON.parse(JSON.stringify(dict["filter_string"]));
				this.checkfilter = true;
				dict["type"] = $event.type;
				dict["module"] = "cmdb";
				dict["sub_type"] = this.assetType;
				this.filters.push(dict);
				this.pageNumberGrid = 0;
				this.pageNumberCard = 1;

				const payload = {
					items_per_page: this.limit,
					sort: this.sortKey,
					reverse: this.reverse,
					// filterString: "object_type:(Node) " + this.filter,
					filter: JSON.stringify(this.filters),
					module: "cmdb",
					view: "card",
					asset_type: this.assetType,
				};

				payload["page"] = this.pageNumberCard;
				if (this.assetType == "cmdb-view") {
					payload["marked_as_ci"] = true;
				}
				this._service.getCMDBCis(payload).subscribe(
					(response) => {
						if (response) {
							this.finished = true;
							this.cmdbCI = response.results;
							this.changeResponseResult = this.cmdbCI;
							this.metaCount = response.count;
							this.totalAsset = response.count;
							this.cardCount = this.cmdbCI.length;
							this.showData = true;
							this.filterData = true;
							this.showCardHeader = false;
							this.selectedData = [];
							setTimeout(() => {
								this.updateDataonscroll();
							}, 600);
						}
						this.getSavedFilters();
					},
					(error) => {
						//todo
						this.showData = true;
					}
				);
			}
		});
	};

	getFilterSearch = ($event) => {
		//this.showData = false;
		let dict = {};
		this.filters = [];
		dict["ui_display"] = $event.userSearchData;
		dict["filter_string"] = $event.userSearchString;
		dict["filter_item"] = $event.filterItem;
		dict["type"] = $event.type;
		dict["module"] = "cmdb";
		dict["sub_type"] = this.assetType;
		let temp = [];
		temp["type"] = $event.type;
		temp["module"] = "cmdb";
		temp["sub_type"] = this.assetType;
		if (dict["filter_string"]) {
			this.DupFilterstring = JSON.parse(JSON.stringify(dict["filter_string"]));
			this.checkfilter = true;
			for (let i = 0; i < this.filter.length; i++) {
				dict["filter_string"].push(this.filter[i]);
			}
		} else {
			this.checkfilter = false;
			this.DupFilterstring = JSON.parse(JSON.stringify(dict["filter_string"]));
			this.filters.push({ filter_string: temp });
		}
		this.filters.push(dict);

		this.pageNumberGrid = 0;
		this.pageNumberCard = 1;

		// this.empty_data = {
		// 	icon: "fa-user-group",
		// 	msg: this._translateService.instant("UI.k_no_filtered_data"),
		// };
		if (this.filters?.length > 0){
			if (this.filters[0]?.optionList?.length > 0){
				delete this.filters[0]?.optionList
			}
		}
		
		const payload = {
			items_per_page: this.limit,
			sort: this.sortKey,
			reverse: this.reverse,
			// filterString: "object_type:(Node) " + this.filter,
			filter: JSON.stringify(this.filters),
			module: "cmdb",
			view: "card",
			asset_type: this.assetType,
			check_enabled: true
		};

		payload["page"] = this.pageNumberCard;
		if (this.assetType == "cmdb-view") {
			payload["marked_as_ci"] = true;
		}
		this._service.getCMDBCis(payload).subscribe(
			(response) => {
				if (response) {
					this.finished = true;
					this.cmdbCI = response.results;
					this.changeResponseResult = this.cmdbCI;
					this.metaCount = response.count;
					this.totalAsset = response.count;
					this.cardCount = this.cmdbCI.length;
					this.showData = true;
					this.filterData = true;
					this.selectedData = [];
					this.showCardHeader = true;
					if (this.selected_asset != undefined){
						this.cmdbCI.forEach((el) => {
							el.isChecked = false;
							if (el.ci_id === this.selected_asset) {
								el.isChecked = true;
								this.selectedData = el
							}
						});
					}
					setTimeout(() => {
						this.updateDataonscroll();
					}, 600);
				}
				this.getSavedFilters();
			},
			(error) => {
				//todo
				this.showData = true;
			}
		);
	};

	getFilterOption(): void {
		if (this.fromExternalComponent) return;
		this._service.getFilterOptions({ moduleName: "cmdb" }).subscribe(
			(response) => {
				if (response) {
					// if(this.assetType == 'fixed-asset'){
					// 	for(let i=0;i<response.length;i++){
					// 		if(response[i]["category_type"] == 'all' || response[i]["category_type"] == 'fixed-asset'){
					// 			this.columnOptions.push(response[i])
					// 		}
					// 	}
					// }
					// else {
					// 	for(let i=0;i<response.length;i++){
					// 		if(response[i]["category_type"] == 'all' || response[i]["category_type"] == 'itasset'){
					// 			this.columnOptions.push(response[i])
					// 		}
					// 	}
					// }
					this.columnOptions = response;
					const selected_search = this.columnOptions?.filter(
						(d) => d?.key == "requester__email"
					);
					if (selected_search?.length > 0){
						this.searchObject.addFilterArray[0].selectedColumn = selected_search[0]
						const selected_cond = selected_search[0]?.operatorOptions?.filter(
							(d) => d?.key == "equal to"
						);
						if (selected_cond?.length > 0){
							this.searchObject.addFilterArray[0].selectedCondition = selected_cond[0]
						}
					}
				} else {
					this.columnOptions = [];
				}
			},
			(error) => {
				this.columnOptions = [];
			}
		);
	}

	getSavedFilters(): void {
		if (this.fromExternalComponent) return;
		this._service
			.getSavedFilter({ module: "cmdb", sub_type: this.assetType })
			.pipe(
				map((response: any) => {
					if (response) {
						this.savedData = response;
					}
				})
			)
			.subscribe();
	}

	getInitOptionConfig = () => {
		if (this.fromExternalComponent) return;
		let payload = {
			asset_type: this.assetType,
			module: "cmdb",
			// event: "filter", # enable this if u want suggetions in filter dropdown
		};
		this._service.getCMDBOptions(payload).subscribe((response) => {
			this.option = response;
			if (this.searchObject.addFilterArray?.length > 0){
				this.searchObject.addFilterArray[0].optionList = this.option?.requester_list
			}
		});
	};

	setCardsCheckedFalse = () => {
		this.cmdbCI.forEach((el) => {
			el["isChecked"] = false;
		});
	};

	onCardSelect = (asset) => {
		this.selectedData = asset;
		this.cmdbCI.forEach((el) => {
			el.isChecked = false;
			if (el.ci_id === asset.ci_id) {
				el.isChecked = true;
			}
		});	
	};

	toggleCardHeader() {
		this.showCardHeader = false;
		this.selectedData = [];
		this.setCardsCheckedFalse();
	}
	loadPage = (data) => {
		this.pageNumberCard = data?.page;
		this.getItemList(this.filter);
	};

	onLimitChange = (limit) => {
		this.pageNumberCard = 1;
		if (limit === "all") {
			this.showAll = true;
			this.limit = Number(this.totalAsset);
		} else {
			this.limit = Number(limit);
			this.showAll = false;
		}

		this.getItemList(this.filter);
	};

	removeSelection = () => {
		this.toggleCardHeader();
	};

	toggleCloseSidebar = (name): void => {
		this.hideAddAsset.emit(true);
	};

	assetSelect = (): void => {
		this.hideAddAsset.emit({"selected_asset": this.selectedData});
	};
	updateDataonscroll = () =>{
		this._scrollSubscription = this.pannelViewScroll.verticalScrolled
		?.pipe(
			map((e: any) => {
				const secHeight = e.target.clientHeight;
				const scrollHeight = e.target.scrollHeight;
				const scrollTop = scrollHeight - secHeight;
				this.scrollPercentage = (100 * e.target.scrollTop) / scrollTop;
				if(this.scrollPercentage > this.showDataOnscrollFrom && this.changeResponseResult?.length < this.metaCount && this.loadData){
					this.updateMetaDataOnscroll()
				}
			})
		).subscribe();
	}

	updateMetaDataOnscroll = () => {
		this.loadData = false;
		const payload = {
			items_per_page: Number(this.changeResponseResult.length) + 10,
			sort: this.sortKey,
			reverse: this.reverse,
			filter: JSON.stringify(this.filters),
			module: "cmdb",
			view: "card",
			asset_type: this.assetType,
			check_enabled: true
		};
		payload["page"] = this.pageNumberCard;
		if (this.assetType == "cmdb-view") {
			payload["marked_as_ci"] = true;
		}
		this.showData = false
		this._service.getCMDBCis(payload).subscribe(
			(response) => {
				if (response) {
					this.cmdbCI = response.results;
					this.changeResponseResult = this.cmdbCI;
					this.metaCount = response.count;
					this.showData = true;
					this.filterData = true;
					this.showCardHeader = false;
					this.selectedData = [];
					setTimeout(() => {
						this.loadData = true;
					}, 500);
				}
			})
	}
}
