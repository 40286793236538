import { Injectable } from "@angular/core";
import { ApiService } from "app/common/services/api/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class KBService extends ApiService {
	constructor(httpClient: HttpClient) {
		super(httpClient);
	}
	getKBProfiles(payload = {}) {
		return this.get("ux/common/kb/kb/", payload);
	}

	getOptionsData(payload = {}) {
		return this.get("ux/common/kb/kb/options/", payload);
	}

	saveKB(payload: any) {
		return this.post("ux/common/kb/kb/", payload);
	}

	getKBProfile(id) {
		return this.get("ux/common/kb/kb/" + id.toString() + "/");
	}

	deleteKB(id) {
		return this.delete("ux/common/kb/kb/" + id.toString() + "/");
	}

	saveKBComment(payload) {
		return this.post("ux/common/kb/kb/comment/", payload);
	}

	getRecentPosts(payload = {}) {
		return this.get("ux/common/kb/kb/recent/", payload);
	}

	getRelatedPosts(payload = {}) {
		return this.get("ux/common/kb/kb/related/", payload);
	}

	getBookmarkedPosts(payload = {}) {
		return this.get("ux/common/kb/kb/bookmarks/", payload);
	}

	saveKBFeedback(payload) {
		return this.post("ux/common/kb/kb/feedback/", payload);
	}

	getAttachmentDetails(payload = {}) {
		return this.get("ux/common/kb/kb/attachment/", payload);
	}

	addAttachments(payload: any) {
		return this.post("ux/common/kb/kb/add-attachment/", payload);
	}

	delAttachment(attachmentId: any, payload: {}) {
		return this.put("ux/common/kb/kb/delete-attachment/" + attachmentId.toString() + "/", payload);
	}
	getKBViewers(payload = {}) {
		return this.get("ux/common/kb/kb/viewers/", payload);
	}

	getHistoryDetails(payload: any) {
		return this.get("ux/common/kb/kb/history/", payload);
	}

	getKBRatings(payload = {}) {
		return this.get("ux/common/kb/kb/ratings/", payload);
	}
	getKBComments(payload = {}) {
		return this.get("ux/common/kb/kb/comments/", payload);
	}

	getCategories(payload = {}) {
		return this.get("ux/common/kb/kb/categories/", payload);
	}

	getKBViews(payload = {}) {
		return this.get("ux/common/kb/kb/views/", payload);
	}
}
