import { Injectable } from "@angular/core";
import { ApiService } from "app/common/services/api/api.service";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class GetStartedService extends ApiService {
	selectedStep = new BehaviorSubject<any>(null);
	updateStepProgress = new BehaviorSubject<boolean>(false);
	constructor(http: HttpClient) {
		super(http);
	}
	loadOrganiationProfile() {
		return this.get("ux/org/profile/Null/", {});
	}
	checkAccessURLAvail(payload) {
		return this.get("ux/org/profile/urlavail/", payload);
	}
	saveOrganizationProfile(payload) {
		return this.post("ux/org/profile/", payload);
	}
	loadOrganiationConfig(payload: {}) {
		return this.get("ux/org/profile/orgconfig/", payload);
	}
	saveOrganizationConfig(payload) {
		return this.post("ux/org/profile/config/", payload);
	}
	getSMTPServerConfig(payload: {}) {
		return this.get("ux/org/profile/smtp_config/", payload);
	}
	saveSMTPServerConfig(payload) {
		return this.post("ux/org/profile/smtpconfig/", payload);
	}
	testSMTPServerConfig(payload) {
		return this.post("ux/org/profile/testsmtp/", payload);
	}
	getConfigurationProgress() {
		return this.get("ux/org/profile/confprogress/", {});
	}
	getAllVideos() {
		return this.get("ux/org/profile/get_video_list/", {});
	}
}
