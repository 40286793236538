import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

import { CoreCommonModule } from "@core/common.module";

import { MenuComponent } from "app/common/layout/components/menu/menu.component";
import { VerticalMenuModule } from "app/common/layout/components/menu/vertical-menu/vertical-menu.module";
import { HorizontalMenuModule } from "app/common/layout/components/menu/horizontal-menu/horizontal-menu.module";
import { SupportMenuModule } from "app/common/layout/components/menu/support-menu/support-menu.module";
import { AdminMenuModule } from "./admin-menu/admin-menu.module";
import { MspMenuModule } from "./msp-menu/msp-menu.module";
@NgModule({
	declarations: [MenuComponent],
	imports: [CoreCommonModule, VerticalMenuModule, HorizontalMenuModule, AdminMenuModule, SupportMenuModule , MspMenuModule],
	exports: [MenuComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MenuModule {}
