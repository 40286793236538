import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { TranslateService } from "@ngx-translate/core";
import { InfinityModules } from "app/common/dns/types/modules";
import { GlobalConstants } from "app/app.globalConstants";
import { SupportPortalService } from "app/support-portal/services/support-portal.service";
import { catchError, map } from "rxjs/operators";
import { DnsGridTagComponent } from "app/common/dns/components/dns-grid-tag/dns-grid-tag.component";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { throwError } from "rxjs";
import { environment } from "environments/environment";
import store from "store";

const URL = `${environment.instance_url}` + "media";

@Component({
	selector: "app-organization-grid",
	templateUrl: "./organization-grid.component.html",
	styleUrls: ["./organization-grid.component.scss"],
})
export class OrganizationGridComponent implements OnInit {
	@Output("hideShowDetails") hideShowDetails = new EventEmitter();
	@Input() options: any = {};
	public organizations: any;
	orgCount = 0;
	showOrgAdd = false;
	pageNumber: any = 0;
	sortKey: any = "company_name";
	reverse: any = "";
	limit: any = 10;
	permissions: any;
	module = InfinityModules.ORGANIZATION;
	columns: Array<any>;
	submenu: boolean;
	public isSidebarOpen: boolean = true;
	public showAll = false;
	leftCountFilter: any = [];
	checkboxModel = 1;
	public currentEvent: String = "grid";
	public filter = [];
	public savedData = [];
	public columnOptions = [];
	public dropdownOptions: any = [];
	public filterData = false;
	public showData = false;
	search_db_cols = [
		{ db_col: "full_name", cond: "contains" },
		{ db_col: "business_email", cond: "contains" },
		{ db_col: "company_name", cond: "contains" },
	];

	public showRightsection: false;
	public showDataTable = false;
	public conditionOptions = [];
	public timeFilterObj: any = {};
	public selectedItem = 1;
	public isLeftFilter: boolean = false;
	public leftFilterString = "";
	public organization_id = -1;
	public isPartner: boolean;
	public callType: String = "direct";
	public secondLastIndex = -1;
	public showPartner: boolean = false;
	public partner_id = "";

	constructor(
		private _translateService: TranslateService,
		private _coreSidebarService: CoreSidebarService,
		private _supportService: SupportPortalService,
		private _toastr: ToastrService
	) {}

	ngOnInit(): void {
		this.isSidebarOpen = JSON.parse(localStorage.getItem("organization-sidebar")) || true;
		this.submenu = this.isSidebarOpen;
		this.getFilterOptions();
		this.getSavedFilters();
		this.isPartner = store.get("is_partner");

		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}

		this.permissions = user_permissions[this.module];
		this.getOrganizations();
		this.getOptions();
		this.getOrgFilters();
		this.columns = [
			{
				label: this._translateService.instant("UI.k_organization_name"),
				key: "company_name",
				component: DnsGridTagComponent,
				width: 220,
				clickable: true,
				subkey: "img_str",
				listener: () => {},
			},
			{
				label: this._translateService.instant("UI.k_product_plan"),
				key: "product",
				component: DnsGridTagComponent,
				subkey: "img_str",
				width: 220,
				sortable: false,
			},
			{
				label: this._translateService.instant("UI.k_license_type"),
				key: "license_type",
				component: DnsGridTagComponent,
				subkey: "badge",
				width: 220,
				sortable: false,
			},
			{
				label: this._translateService.instant("UI.k_expiry_date"),
				key: "expiry_date",
				component: DnsGridTagComponent,
				width: 320,
				subkey: "img_str",
				sortable: false,
			},
			{
				label: this._translateService.instant("UI.k_payment_status"),
				key: "payment_status",
				component: DnsGridTagComponent,
				subkey: "payment-badge",
				width: 220,
				sortable: false,
			},
			// {
			// 	label: this._translateService.instant("Message.msg_support_expire_date"),
			// 	key: "support_expiry_date",
			// 	component: DnsGridTagComponent,
			// 	width: 320,
			// 	subkey: "img_str",
			// },
			{
				label: this._translateService.instant("UI.k_deployment"),
				key: "host_type",
				width: 220,
				sortable: false,
			},
			// {
			// 	label: this._translateService.instant("UI.k_partner_company"),
			// 	key: "partner",
			// 	component: DnsGridTagComponent,
			// 	width: 320,
			// 	subkey: "img_str",
			// },
			{
				label: this._translateService.instant("UI.k_email"),
				key: "business_email",
				width: 220,
			},
			{
				label: this._translateService.instant("UI.k_admin_user"),
				key: "full_name",
				width: 220,
			},
			{
				label: this._translateService.instant("UI.k_contact_number"),
				key: "phone_number",
				subkey: "phone_number",
				type: "tel",
				width: 220,
				display_tel_key: "internationalNumber",
				sortable: false,
			},
			{
				label: this._translateService.instant("UI.k_action"),
				key: "action",
				width: 220,
				actions: [
					{
						label: this._translateService.instant("UI.k_edit"),
						icon: "fa-pen-to-square",
						key: "edit",
						class: "text-primary btn btn-icon btn-outline-secondary",
						listener: this.editOrganization,
						hide_key: "hide_edit",
					},
					{
						label: this._translateService.instant("UI.k_delete"),
						icon: "fa-trash-can",
						key: "delete",
						class: "text-danger btn btn-icon btn-outline-secondary",
						listener: this.deleteOrganization,
					},
					{
						label: this._translateService.instant("UI.k_download"),
						icon: "fa-download",
						key: "download",
						class: "text-warning btn btn-icon btn-outline-secondary",
						listener: this.downloadLicense,
						hide_key: "is_on_premise",
					},
				],
			},
		];
		// this.columns = [
		// 	{
		// 		label: this._translateService.instant("UI.k_organization_name"),
		// 		key: "organizationName",
		// 		component: OrganizationTagInfoComponent,
		// 		subkey: "organizationName",
		// 		width: 220,
		// 	},
		// 	{
		// 		label: this._translateService.instant("UI.k_product"),
		// 		key: "product",
		// 		component: ProfileInfoTagComponent,
		// 		subkey: "product",
		// 		width: 220,
		// 	},
		// 	{
		// 		label: this._translateService.instant("UI.k_license_type"),
		// 		key: "license_Type",
		// 		component: LicenseTypeTagComponent,
		// 		width: 220,
		// 	},
		// 	{
		// 		label: this._translateService.instant("UI.k_organization_ID"),
		// 		key: "organization_ID",
		// 		width: 320,
		// 	},
		// 	{
		// 		label: this._translateService.instant("UI.k_expiry_date"),
		// 		key: "expiry_Date",
		// 		component: DateIconFormateComponent,
		// 		width: 320,
		// 	},
		// 	{
		// 		label: this._translateService.instant("UI.k_deployment"),
		// 		key: "Deployment",
		// 		width: 220,
		// 	},
		// 	{
		// 		label: this._translateService.instant("UI.k_purchase_order_no"),
		// 		key: "purchase_Order_NO",
		// 		component: PurchaseOrderPdfTagComponent,
		// 		width: 320,
		// 	},
		// 	{
		// 		label: this._translateService.instant("UI.k_plan_type"),
		// 		key: "plan_Type",
		// 		component: PlanTypeTagComponent,
		// 		width: 320,
		// 	},
		// 	{
		// 		label: this._translateService.instant("UI.k_partner_company"),
		// 		key: "partner_company",
		// 		component: ProductTagInfoComponent,
		// 		width: 320,
		// 	},
		// 	{
		// 		label: this._translateService.instant("UI.k_action"),
		// 		key: "action",
		// 		width: 220,
		// 		actions: [
		// 			{
		// 				label: this._translateService.instant("UI.k_edit"),
		// 				icon: "fa-pen-to-square",
		// 				key: "edit",
		// 				class: "text-primary btn btn-icon btn-outline-secondary",
		// 				listener: () => {},
		// 			},
		// 			{
		// 				label: this._translateService.instant("UI.k_delete"),
		// 				icon: "fa-trash-can",
		// 				key: "delete",
		// 				class: "text-danger btn btn-icon btn-outline-secondary",
		// 				listener: () => {},
		// 			},
		// 		],
		// 	},
		// ];
		if (!this.isPartner) {
			this.secondLastIndex = this.columns.length - 1;
			this.columns.splice(this.secondLastIndex, 0, {
				label: this._translateService.instant("UI.k_partner"),
				key: "partner_name",
				width: 220,
				type: "link_sidebar",
				sortable: false,
				listener: this.showPartnerDetails,
				clickable: true,
			});
		}
	}

	toggleSidebar = (name, call_type): void => {
		this.showOrgAdd = true;
		this.currentEvent = "add";
		this.organization_id = -1;
		this.callType = call_type;
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
	};

	setPage = (pageInfo) => {
		this.pageNumber = pageInfo.offset;
		this.getOrganizations();
	};

	onSort = (args): void => {
		if (args.sorts) {
			this.pageNumber = 0;
			let sortKey = args.sorts[0].prop;
			this.sortKey = sortKey;
			if (args.sorts[0].dir == "desc") {
				this.reverse = "-";
			} else {
				this.reverse = "";
			}
		}
		this.getOrganizations();
	};

	updateSidebar = () => {
		if (window.innerWidth >= 1200) {
			this.submenu = true; // lg
		} else {
			this.submenu = false; //md
			localStorage.setItem("organization-sidebar", JSON.stringify(this.submenu));
		}
	};

	setLimit = (args): void => {
		this.pageNumber = 0;
		this.limit = args; //args.target.value;
		if (this.limit === "all") {
			this.showAll = true;
			this.limit = this.orgCount;
		} else {
			this.limit = Number(this.limit);
			this.showAll = false;
		}
		this.getOrganizations();
	};

	submenuToggaleClick = (): void => {
		this.submenu = !this.submenu;
		localStorage.setItem("organization-sidebar", JSON.stringify(this.submenu));
		this.organizations = [...this.organizations];
	};

	getOrganizations = () => {
		const payload = {
			page: this.pageNumber + 1,
			items_per_page: this.showAll && this.currentEvent === "add" ? Number(this.limit) + 1 : Number(this.limit),
			sort: this.sortKey,
			reverse: this.reverse,
			filter: JSON.stringify(this.filter),
			time_filter: JSON.stringify(this.timeFilterObj),
			module: "organization",
			left_filter: this.isLeftFilter,
			left_filter_string: this.leftFilterString,
		};
		this._supportService.getOrganizations(payload).subscribe(
			(response) => {
				if (response) {
					this.orgCount = response?.count;
					if (this.showAll && this.currentEvent === "add") {
						this.limit = Number(this.limit) + 1;
					}
					this.organizations = response?.results;
					this.filterData = true;
					this.showData = true;
					this.getSavedFilters();
				}
			},
			(error) => {
				//todo
			}
		);
	};

	getSavedFilters = (): void => {
		this._supportService
			.getSavedFilters({ module: "organization" })
			.pipe(
				map((response: any) => {
					if (response) {
						this.savedData = response;
					}
				})
			)
			.subscribe();
	};

	getFilterOptions = (): void => {
		this._supportService.getFilterOptions({ moduleName: "organization" }).subscribe(
			(response) => {
				if (response) {
					this.columnOptions = response;
				} else {
					this.columnOptions = [];
				}
			},
			(error) => {
				this.columnOptions = [];
			}
		);
	};

	getFilterSearch = ($event) => {
		this.pageNumber = 0;
		let dict = {};
		this.filter = [];
		dict["ui_display"] = $event.userSearchData;
		dict["filter_string"] = $event.userSearchString;
		dict["filter_item"] = $event.filterItem;
		dict["type"] = $event.type;
		dict["module"] = "organization";
		this.filter.push(dict);
		let payload = {
			page: 1,
			items_per_page: this.limit,
			sort: this.sortKey,
			reverse: this.reverse,
			filter: JSON.stringify(this.filter),
			module: "organization",
		};
		this.getOrgFilters();
		this.getOrganizations();
	};

	getOptions = () => {
		this._supportService
			.getOptions({ type: "organization" })
			.pipe(
				map((response: any) => {
					this.dropdownOptions = response;
				})
			)
			.subscribe();
	};

	genericSearchFilter = ($event) => {
		let search_str = $event.userSearchData.trim();

		let filter_options: any = [];
		this.search_db_cols.forEach((item) => {
			filter_options.push({
				condition: item.cond,
				db_col: item.db_col,
				value: search_str,
				relation: "or",
			});
			let filter = [];
			if (filter_options?.length == this.search_db_cols?.length) {
				let dict = {};
				dict["ui_display"] = $event.userSearchData;
				dict["filter_string"] = filter_options;
				dict["filter_item"] = $event.filterItem;
				dict["type"] = $event.type;
				dict["module"] = "organization";
				for (let i = 0; i < this.filter?.length; i++) {
					dict["filter_string"].push(this.filter[i]);
				}

				filter.push(dict);
				this.filter = filter;
				this.pageNumber = 0;

				// this.filterData = true;
				this.getOrgFilters();
				this.getOrganizations();
				this.getSavedFilters();
			}
		});
	};

	onRangeSelect($event) {
		this.timeFilterObj = $event;
		this.pageNumber = 0;
		// this.filterData = true;
		if (this.timeFilterObj?.label != undefined) {
			this.filterData = true;
		} else {
			this.filterData = false;
		}
		this.getOrgFilters();
		this.getOrganizations();
	}

	getOrgFilters = () => {
		const payload = {
			filter: JSON.stringify(this.filter),
			time_filter: JSON.stringify(this.timeFilterObj),
		};
		this._supportService.getOrgFilters(payload).subscribe(
			(response) => {
				if (response) {
					this.leftCountFilter = JSON.parse(response);
					// this.getSidebarData()
					if (this.isPartner) {
						this.leftCountFilter = this.leftCountFilter.filter(
							(column) => column.view_map_key !== "partner"
						);
					}
				}
			},
			(error) => {
				// todo
			}
		);
	};

	onSelect = ($evt, itemid) => {
		this.showData = true;
		this.filterData = true;
		this.pageNumber = 0;
		this.selectedItem = $evt["sequence"];
		this.isLeftFilter = true;
		this.leftFilterString = $evt["view_map_key"];

		if ($evt["view_map_key"] == "all") {
			this.isLeftFilter = false;
			this.leftFilterString = "all";
		}
		this.getOrganizations();
	};

	onHideEvent = (event) => {
		this.showOrgAdd = false;
		if (event === "add" || event === "edit") {
			this.currentEvent = event;
		}
		this.getOrgFilters();
		this.getOrganizations();
	};

	editOrganization = (args) => {
		this.currentEvent = "edit";
		this.organization_id = args.organization;
		this.showOrgAdd = true;
		this._coreSidebarService.getSidebarRegistry("add-organization").toggleOpen();
	};

	downloadLicense = (args) => {
		this.organization_id = args.organization;
		this._supportService.downloadLicense({ organization: this.organization_id }).subscribe(
			(response) => {
				if (response) {
					let path = response.toString();
					let downloadable_file_path = URL + path.split("media")[1];
					let a = document.createElement("a");
					a.href = downloadable_file_path;
					a.target = "_self";
					a.download = "license.cert";
					a.click();
					// window.open(downloadable_file_path, "_blank");
				}
			},
			(error) => {
				// todo
			}
		);
	};

	deleteOrganization = (row) => {
		Swal.fire({
			title: this._translateService.instant("Message.msg_delete_title"),
			text: this._translateService.instant("Message.msg_delete_text"),
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: this._translateService.instant("Message.msg_delete_confirm"),
			cancelButtonText: this._translateService.instant("Message.msg_delete_cancel"),
			customClass: {
				confirmButton: "btn btn-primary",
				cancelButton: "btn btn-danger ml-1",
			},
		}).then((result) => {
			if (result.value) {
				this._supportService
					.deleteOrganization(row.organization)
					.pipe(
						map((response: any) => {
							if (response.status == "success") {
								// subtracting 1 from page number if count equals to items per page limit after delete
								if (this.orgCount - 1 < this.pageNumber * this.limit + 1) {
									this.pageNumber = this.pageNumber - 1;
								}
								if (this.showAll) {
									this.limit = this.limit - 1;
								}
								this.getOrgFilters();
								this.getOrganizations();
								this._toastr.success(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Message.msg_delete_success"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							} else {
								this._toastr.error(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Error.err_delete_failed"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							}
							// Success
						}),
						catchError((error) => {
							this._toastr.error(
								"Failed to delete Organization",
								this._translateService.instant("Error.err_delete_failed"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
							return throwError(error.statusText);
						})
					)
					.subscribe();
			}
		});
	};

	showPartnerDetails = (args) => {
		this.showPartner = true;
		this.partner_id = args.partner;
		this._coreSidebarService.getSidebarRegistry("create-partner").toggleOpen();
	};

	onHidePartner = (event) => {
		this.showPartner = false;
	};

	getPartnerList = () => {
		this.getOrgFilters();
		this.getOrganizations();
	};
}
