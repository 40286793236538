<div class="slideout-content default-stepping">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog sidebar-md">
            <div class="modal-content add-new-user pt-0 pb-0">
                <div class="modal-header">
                    <h4 class="modal-title fw-500" id="exampleModalLabel">
                       {{root_cause_id === '-1' ? add_text: edit_text }} 
                    </h4>
                    <button type="button" id="btnRequesterClose" name="btnRequesterClose" [attr.data-testid]="'btnRequesterClose'"
                        class="btn btn-flat-secondary btn-icon" data-dismiss="modal" aria-label="Close"
                        (click)="hideAnaysisModal()">
                        <i class="fa-light fa-xmark"></i>
                    </button>
                </div>
                <form #SolutionDetailsForm="ngForm" class="modal-body">
                    <div class="flex-auto-height">
                        <ng-scrollbar class="custom-scrollbar section-scrollbar">
                            <div class="modal-body-inner p-1">
                                <div class="d-flex flex-wrap row">
                                    <div class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6">
                                        <label class="form-label" for="selType">{{ "UI.k_type"| translate }}
                                            <span
                                            class="required-field txt-danger"
                                            >&nbsp;*
                                            </span>
                                        </label>
                                        <ng-select 
                                            [items]="solutionList" 
                                            [(ngModel)]="solutionObj.type"
                                            #TDType="ngModel"
                                            bindLabel="display_key"
                                            bindValue="id"
                                            name="selType"
                                            notFoundText="{{
                                                'UI.k_no_items_found' | translate
                                            }}"
                                            id="selType"
                                            placeholder="{{'UI.k_type'| translate}}"
                                            [class.error]="TDType.invalid && SolutionDetailsForm.submitted"
                                            [disabled]="is_edit"
                                            required>
                                        </ng-select>
                                        <span *ngIf="SolutionDetailsForm.submitted && TDType.invalid" class="invalid-form">
                                            <small class="form-text text-danger font-small-1" *ngIf="TDType.errors.required">{{
                                                "UI.k_field_required" | translate
                                            }}</small>
                                        </span>
                                    </div>
                                    <div class="form-group col-xs-12 col-md-12 col-xl-12 col-lg-12">
                                        <label class="form-label" for="selType">{{"UI.k_details"| translate }}
                                            <span
                                            class="required-field txt-danger"
                                            >&nbsp;*
                                            </span>
                                        </label>
                                        <div class="form-group w-100">
                                            <textarea
                                                class="form-control"
                                                id="txtDetails"
                                                name="txtDetails"
                                                rows="10"
                                                placeholder="{{ 'UI.k_details' | translate }}"
                                                [(ngModel)]="solutionObj.details"
                                                #solutionDesc="ngModel"
                                                required
                                                [class.error]="solutionDesc.invalid && SolutionDetailsForm.submitted"
                                            ></textarea>
                                            <span class="invalid-form" *ngIf="SolutionDetailsForm.submitted && solutionDesc.invalid">
                                                <small class="form-text text-danger" *ngIf="solutionDesc.errors.required"
                                                    >{{ 'UI.k_field_required' | translate }}
                                                </small>
                                            </span>
                                        </div>
                                    </div>
                                    <div *ngIf="is_know_error && solutionObj.type == 1" class="col-md-12 pr-75 pl-0 mt-1 known-error-card ">
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                <div
                                                    class="font-small-4 font-weight-bolder m-1"
                                                >
                                                    {{
                                                        "UI.k_known_error" | translate
                                                    }}
                                                </div>
                                            </div>
                                            <div
                                                class="custom-control custom-control-primary custom-switch mt-1"
                                            >
                                                <input
                                                    type="checkbox"
                                                    class="custom-control-input"
                                                    id="knownError"
                                                    name="knownError"
                                                    [(ngModel)]="
                                                        problem_data.is_known_error_enable
                                                    "
                                                />
                                                <label
                                                    class="custom-control-label"
                                                    for="knownError"
                                                >
                                                </label>
                                            </div>
                                        </div>
                                        <ngb-alert class="p-75 m-1" [type]="'primary'" [dismissible]="false">
                                            {{
                                                "UI.k_known_error_soln_descr"
                                                    | translate
                                            }}
                                        </ngb-alert>
                                        <!-- <div class="d-flex justify-content-center align-items-center badge badge-light-primary waves-effect p-50 m-1 ">
                                            <span class="">
                                                {{
                                                    "UI.k_known_error_soln_descr"
                                                        | translate
                                                }}
                                            </span>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </ng-scrollbar>
                        <div class="stepper-btn d-flex justify-content-end px-1 py-1 border-top">
                            <button type="submit" 
                                (click)="onSubmit(SolutionDetailsForm)" 
                                class="btn btn-primary btn-next" 
                                rippleEffect id="btnTechniqueNxt" 
                                name="btnTechniqueNxt"
                                [attr.data-testid]="'btnTechniqueNxt'"
                                *ngIf="!(problem_data?.basic_info?.state?.id == 5 &&
                                problem_data?.is_exit)"
                                [disabled]="is_save_disabled"
                            >
                            <i
                                class="
                                    fa-light fa-floppy-disk
                                    align-middle
                                    mr-sm-25 mr-0
                                "
                            ></i>
                                <span class="align-middle d-sm-inline-block d-none">{{ "UI.k_submit" | translate }}</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
