<div class="slideout-content default-slideout">
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
		<div class="modal-dialog sidebar-md">
			<div class="modal-content pt-0">
				<div class="modal-header">
					<h5 class="modal-title" id="priorityChange">
						{{
							"UI.k_priority_change_detected_display_id"
								| translate: { display_id: config_data?.display_id || "" }
						}}
					</h5>
					<button
						type="button"
						id="btnPriorityChangeClose"
						name="btnPriorityChangeClose"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						(click)="toggleSidebar(sidebarName, PriorityChangeForm)"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<form
					#PriorityChangeForm="ngForm"
					class="modal-body"
					(ngSubmit)="onSubmit(PriorityChangeForm)"
				>
					<div class="flex-auto-height">
						<ng-scrollbar class="custom-scrollbar section-scrollbar">
							<div class="modal-body-inner p-1">
								<div>
									<div class="col-md-12 p-0">
										<div class="form-group">
											<label for="txtAreaSmartGridPriorityChange" class="sm-font">
												{{ "UI.k_reason_for_priority_change" | translate
												}}<span class="required-field">&nbsp;*</span>
											</label>
											<div class="card p-0 pb-50">
												<quill-editor
													[(ngModel)]="priority_change_data.comment"
													#TDSmartGridPriorityChangeComment
													id="txtAreaSmartGridPriorityChange"
													name="txtAreaSmartGridPriorityChange"
													[required]="true"
													[trimOnValidation]="true"
													[appQuillValidator]="TDSmartGridPriorityChangeComment"
													[appQuillForm]="PriorityChangeForm"
													[minLength]="3"
													[class.error]="
														TDSmartGridPriorityChangeComment?.errors &&
														PriorityChangeForm.submitted
													"
													placeholder="{{ 'UI.k_insert_text_here' | translate }}..."
												>
													<div quill-editor-toolbar class="px-0 py-50">
														<div class="p-0">
															<span class="ql-formats pb-50">
																<button
																	class="ql-bold"
																	title="{{ 'UI.k_bold' | translate }}"
																></button>
																<button
																	class="ql-italic"
																	title="{{ 'UI.k_italic' | translate }}"
																></button>
																<button
																	class="ql-underline"
																	title="{{ 'UI.k_underline' | translate }}"
																></button>
															</span>
															<span class="ql-formats">
																<select
																	class="ql-align"
																	title="{{ 'UI.k_alignment' | translate }}"
																>
																	<option selected></option>
																	<option value="center"></option>
																	<option value="right"></option>
																	<option value="justify"></option>
																</select>
															</span>
															<span class="ql-formats">
																<button
																	class="ql-list"
																	value="ordered"
																	type="button"
																></button>
															</span>
															<span class="ql-formats">
																<button
																	class="ql-link"
																	value="ordered"
																	type="button"
																></button>
																<button
																	class="ql-image"
																	value="ordered"
																	type="button"
																></button>
																<button
																	class="ql-video"
																	value="ordered"
																	type="button"
																></button>
															</span>
															<span class="ql-formats">
																<button
																	class="ql-formula"
																	value="ordered"
																	type="button"
																></button>
																<button
																	class="ql-code-block"
																	value="ordered"
																	type="button"
																></button>
															</span>
															<span class="ql-formats">
																<button
																	class="ql-clean"
																	value="ordered"
																	type="button"
																></button>
															</span>
														</div>
													</div>
												</quill-editor>
											</div>
											<div>
												<span
													class="row col-12 error"
													*ngIf="
														PriorityChangeForm.submitted &&
														TDSmartGridPriorityChangeComment?.errors
													"
													class="invalid-form"
												>
													<small
														*ngIf="
															TDSmartGridPriorityChangeComment?.errors?.requiredError
																?.empty
														"
														class="form-text text-danger xs-font"
														>{{ "UI.k_field_required" | translate }}</small
													>
													<small
														*ngIf="TDSmartGridPriorityChangeComment?.errors?.minLengthError"
														class="form-text text-danger xs-font"
														>{{ "Error.err_min_3_character" | translate }}</small
													>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</ng-scrollbar>
						<div class="modal-btn-group flex-initial border-top">
							<div class="d-flex justify-content-end stepper-btn flex-initial">
								<button
									class="btn btn-sm btn-primary btn-next ml-1"
									id="btnPriorityChangeSubmit"
									name="btnPriorityChangeSubmit"
									[attr.data-testid]="'btnPriorityChangeSubmit'"
									rippleEffect
									type="submit"
								>
									<span class="align-middle d-sm-inline-block d-none">
										{{ "UI.k_submit" | translate }}</span
									>
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
