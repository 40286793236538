<div class="slideout-content default-slideout">
  <div class="modalsd modal-slide-in sdfade" id="modals-slide-in">
    <div class="modal-dialog">
      <div class="modal-content pt-0 pb-0">
        <form class="flex-auto-height" #addSignatureForm="ngForm">
          <div class="modal-header d-flex justify-content-between align-items-center">
            <h5 class="modal-title fw-600 mb-0 lg-font">
              <ng-container *ngIf="event == 'add'">
                {{'UI.k_add' | translate}} {{'UI.k_new' | translate}} {{'UI.k_signature' | translate}}
              </ng-container>
              <ng-container *ngIf="event == 'edit'">
                {{'UI.k_edit' | translate}} {{'UI.k_signature' | translate}}
              </ng-container>
              <ng-container *ngIf="event == 'view'">
                {{'UI.k_view' | translate}} {{'UI.k_signature' | translate}}
              </ng-container>
            </h5>
            <button type="button" data-dismiss="modal" id="btnClose" name="btnClose" aria-label="Close"
              (click)="toggleSidebar('add-signature')" class="btn btn-flat-secondary btn-icon" rippleEffect>
              <i class="fa-light fa-xmark"></i>
            </button>
          </div>
          <div class="modal-content-body flex-1">
            <ng-scrollbar class="custom-scrollbar section-scrollbar">
              <div class="p-1">
                <div class="row">
                  <div class="col-12">
                    <div class="input-group form-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-addon sm-font">{{ 'UI.k_title' | translate }}</span>
                      </div>
                      <input type="text" class="form-control" id="titleText" name="titleText"
                        placeholder="{{ 'UI.k_type_title_here' | translate }}" minlength="3" maxlength="1000"
                        [(ngModel)]="sign_obj.name" #TDSubjectRef="ngModel"
                        [class.error]="!TDSubjectRef.valid && addSignatureForm.submitted"
                        [appWhiteSpaceCheck]="sign_obj.name" required />
                    </div>
                    <div *ngIf="addSignatureForm.submitted && TDSubjectRef.invalid" class="invalid-form">
                      <small class="form-text text-danger xs-font" *ngIf="TDSubjectRef.errors.required">{{
                        "UI.k_field_required" | translate }}
                      </small>
                      <small class="form-text text-danger xs-font"
                        *ngIf="TDSubjectRef.errors.minlength || TDSubjectRef.errors.maxlength">{{
                        "UI.k_title_name_validation" | translate }}
                      </small>
                      <small class="form-text text-danger xs-font" *ngIf="TDSubjectRef.errors?.whitespace">{{
                        "UI.k_leading_trailing_space_validation" | translate }}</small>
                    </div>
                  </div>
                  <div class="col-12 mb-1">
                    <div class="txt-editor">
                      <quill-editor [(ngModel)]="sign_obj.content" (onContentChanged)="getEnrichTextData($event)"
                        id="SignatureQuill" name="SignatureQuill" class="quick-response" [required]="true"
                        [minLength]="3" [ngModelOptions]="{
                          standalone: true
                        }" [disabled]="false" #SignatureQuill [trimOnValidation]="true"
                        [appQuillValidator]="SignatureQuill" [appQuillForm]="addSignatureForm" [class.error]="
                        SignatureQuill?.errors && addSignatureForm.submitted
                        ">
                        <div quill-editor-toolbar>
                          <div class="desc-toolbar border-top-0">
                            <span class="ql-formats mr-0">
                              <select class="ql-header">
                                <option value="1"></option>
                                <option value="2"></option>
                                <option value="3"></option>
                                <option value="4"></option>
                                <option value="5"></option>
                                <option value="6"></option>
                                <option selected></option>
                              </select>
                              <button class="ql-bold"></button>
                              <button class="ql-italic" title="{{
                                'UI.k_italic' | translate
                              }}"></button>
                              <button class="ql-underline" title="{{
                                'UI.k_underline' | translate
                              }}"></button>
                            </span>
                            <span class="ql-formats">
                              <button class="ql-image" value="ordered" type="button"></button>
                            </span>
                            <select class="ql-font">
                              <option selected value="calibri">Calibri</option>
                              <option value="monospace">Monospace</option>
                              <option value="serif">Sans Serif</option>
                            </select>
                            <select class="ql-color">
                            </select>
                            <button class="ql-link" value="ordered" type="button"></button>
                          </div>
                        </div>
                      </quill-editor>
                      <div *ngIf="addSignatureForm.submitted && SignatureQuill?.invalid" class="invalid-form error">
                        <small *ngIf="SignatureQuill?.errors?.requiredError?.empty">
                          {{ "UI.k_field_required" | translate }}
                        </small>
                        <small *ngIf="SignatureQuill?.errors?.minLengthError">
                          {{ "Error.err_min_3_character" | translate }}
                        </small>
                      </div>
                      <div class="file-upload-below-quill border border-top-0">
                        <!-- <span
                          *ngFor="let file of filesList"
                          class="text-primary sm-font attach-file-border m-half"
                          ><i class="fa-light fa-file-lines"></i>&nbsp;<b>{{
                            file.file_name
                          }}</b>&nbsp;
                          <span
                            (click)="removeAttachedFile($index, filesList)"
                            id="btnFileAttachmentClose"
                          >
                            <i class="fa-light fa-xmark"></i>
                          </span>
                        </span>
                        <div>
                          <label for="file-input" class="add_attachment c-fs-12">
                            <img
                              src="assets/images/icons/icon_link.svg"
                              width="18"
                              height="18"
                            />
                            <span class="fw-500 text-primary ml-50 align-middle"
                              >{{ "UI.k_add" | translate }}
                              {{ "UI.k_attachment" | translate }}
                            </span>
                          </label>
                          <input
                            (change)="fileUpload($event)"
                            id="file-input"
                            type="file"
                            class="d-none"
                          />
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-scrollbar>
          </div>
          <div class="modal-content-footer flex-initial">
            <div class="d-flex justify-content-end border-top px-1 py-1">
              <button type="button" (click)="toggleSidebar('add-signature')"
                class="btn btn-outline-tertiary btn-prev mr-1" rippleEffect>
                <span class="align-middle"> {{ 'UI.k_cancel' | translate }}</span>
              </button>
              <button type="submit" class="btn btn-primary btn-next" (click)="onSubmit(addSignatureForm)" rippleEffect>
                <span class="align-middle">{{ 'UI.k_save' | translate }}</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>