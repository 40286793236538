import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import cloneDeep from "lodash-es/cloneDeep";
import { FlatpickrOptions } from "ng2-flatpickr";
import { InfinityModules } from "app/common/dns/types/modules";
import { GlobalConstants } from "app/app.globalConstants";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { throwError } from "rxjs";
import { convertToCalendarTime } from "app/common/utils/utils";
import { ProblemService } from "app/servicedesk/problem/services/problem.service";

@Component({
  selector: 'app-dns-problem-close',
  templateUrl: './dns-problem-close.component.html',
  styleUrls: ['./dns-problem-close.component.scss']
})
export class DnsProblemCloseComponent implements OnInit {
  @Output("onHideClose") onHideClose = new EventEmitter<any>();
	@Output("getproblems") getproblems = new EventEmitter<any>();
	@Input() problem_id = "";
	@Input() options;
	@Input() stateKeyIdMap = {};
	@Input() selected_status: any;
	public problem_data;
	public problem_data_copy;
	currentUserProfileId: any;
	hideInlineEdit = false;
	req_extra_info: any = {};
	close_status = [];
	sourceIconMap = {
		1: "globe",
		2: "envelope",
		3: "bell",
		4: "mobile",
		5: "phone",
		6: "comment-lines",
		7: "comment-dots",
		8: "microsoft"
	};

	public dateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: true,
		dateFormat: "%b %d, %Y %I:%M %p",
		altFormat: "M d, Y H:i K",
		mode: "single",
		altInputClass: "form-control flat-picker flatpickr-input",
	};
	public agreedDateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: true,
		dateFormat: "%b %d, %Y %I:%M %p",
		altFormat: "M d, Y H:i K",
		mode: "single",
		altInputClass: "form-control flat-picker flatpickr-input",
	};

	public editorCreated(event): void {
		// Example on how to add new table to editor
		// this.addNewtable();
	}

	userTZ: string | undefined;
	asset_module = InfinityModules.CMDB;
	asset_permissions: any;
	is_submit_disabled = false

	constructor(
		private _problemViewService: ProblemService,
		private _coreSidebar: CoreSidebarService,
		private _translateService: TranslateService,
		private _toastr: ToastrService
	) {}

	ngOnInit(): void {
    console.log("Current Status", this.selected_status)
		this.onClose(this.problem_id);
		let userParams = JSON.parse(localStorage.getItem("userParams"));
		this.userTZ = userParams?.user_tz;
		try {
			let today = new Date(new Date().toLocaleString("en-US", { timeZone: this.userTZ }));
			this.dateTimeOptions["defaultDate"] = today;
		} catch (e) {
			console.log("Error in selecting current time based on timezone ", e);
		}
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.asset_permissions = user_permissions[this.asset_module];
	}

	toggleSidebar = (key) => {
		this.onHideClose.emit();
	};

	onClose(problem_id) {
		this._problemViewService
			.getProblemData(problem_id)
			.pipe(
				map(
					(response) => {
						this.problem_data = response?.problem;
						if (this.problem_data?.close_info == null || this.problem_data?.close_info == undefined){
							this.problem_data["close_info"] = {
								"closed_type": {}
							}
						}
            console.log("problem Data", this.problem_data)
						this.problem_data_copy = cloneDeep(this.problem_data);
						this.currentUserProfileId = response["current_user_id"];
						this.req_extra_info["custom_field_config"] = response["custom_field_config"];
						this.hideInlineEdit = true;
						if (this.problem_data?.close_info === undefined) {
							this.problem_data["close_info"] = {
								closed_type: {},
							};
						}
						this.close_status = response?.transition_status?.filter(
							(e) => e["state_id"] == this.stateKeyIdMap["Close"]
						);
						if (!this.selected_status?.state_id) {
							if (this.problem_data?.basic_info?.state?.id == 5) {
								this.problem_data["resolved_status_id"] = this.problem_data?.basic_info?.status?.id;
								this.problem_data["state_id"] = this.problem_data?.basic_info?.status?.state_id;
							} else {
								this.problem_data["state_id"] = this.close_status[0]["state_id"];
								this.problem_data.basic_info["status"] = this.close_status[0];
							}
						} else {
							this.close_status = response?.transition_status?.filter(
								(e) => e["state_id"] == this.selected_status?.state_id
							);
							this.problem_data["state_id"] = this.selected_status?.state_id;
							this.problem_data.basic_info["status"] = this.selected_status;
						}
					},
					(error) => {
						//todo
					}
				)
			)
			.subscribe();
	}

	onCloseSubmit(data, selected_status) {
		if (data.form.status === "VALID") {
			this.is_submit_disabled = true
			this.problem_data.basic_info["state"] =
				this.options?.state_id_map[this.problem_data.basic_info?.status?.state_id];
			let cf_data = this.req_extra_info?.custom_field_config?.custom_fields?.filter(
				(d) => d?.section == "closure"
			);
			this.problem_data.close_info["custom_field_data"] = this.get_custom_data(
				cf_data,
				this.problem_data?.custom_field_data
			);
			this.saveproblemData(this.problem_data);
		}
	}

	saveproblemData(data) {
		if (data.actual_start_time instanceof Array) {
			data.actual_start_time = data.actual_start_time[0];
		}
		if (data.agreed_closure_date instanceof Array) {
			data.agreed_closure_date = data.agreed_closure_date[0];
		}
		if (data.actual_closure_date instanceof Array) {
			data.actual_closure_date = data.actual_closure_date[0];
		}
		data.actual_start_time = convertToCalendarTime(data.actual_start_time)
		data.agreed_closure_date = convertToCalendarTime(data.agreed_closure_date)
		data.actual_closure_date = convertToCalendarTime(data.actual_closure_date)

		this._problemViewService
			.editProblem(data.problem_id, data)
			.pipe(
				map((response: any) => {
					if (response) {
						this.getproblems.emit();
						this.onHideClose.emit();
						this.is_submit_disabled = false
						this._toastr.success(
							this._translateService.instant(response["message"]),
							this._translateService.instant("Message.msg_save_success"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
					} else {
						this.is_submit_disabled = false
						this._toastr.error(
							this._translateService.instant("Error.err_incident_save_failed"),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
					}
					// Success
				}),
				catchError((error) => {
					this.is_submit_disabled = false
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}

	onStatusChange = (data) => {
		this.problem_data["status_id"] = data?.id;
		this.problem_data.basic_info["status"] = data;
		this.problem_data["state_id"] = data?.state_id;
		this.problem_data.basic_info["state"] = this.options?.state_id_map[data?.state_id];
	};

	getClosureNote = (evt) => {
		this.problem_data.close_info.closure_note = evt.html;
	};

	updateFieldName(evt, id, dropdown_name, set_field, ref_field) {
		let selected = this.options[dropdown_name].filter((e) => e[id] == evt);
		set_field = selected[0][ref_field];
	}

	get_custom_data(config_data, custom_fields) {
		let actual_conf_data = {};
		if (config_data && custom_fields) {
			config_data.forEach((conf) => {
				let data_key = conf?.data_key;
				let label = conf?.label;
				let value = custom_fields[data_key];
				if (conf?.type == "date" || conf?.type == "datetime-local") {
					if (this.problem_data.custom_field_data[data_key] instanceof Array) {
						this.problem_data.custom_field_data[data_key] =
							this.problem_data.custom_field_data[data_key][0];
					}
					if (value instanceof Array) {
						value = value[0];
					}
				}
				if (value) {
					actual_conf_data[label] = value;
				}
			});
		}
		return actual_conf_data;
	}
}
