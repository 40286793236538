import { Injectable } from "@angular/core";
import { ApiService } from "app/common/services/api/api.service";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class SelfServicePortalService extends ApiService {
	sspConfig = new BehaviorSubject<any[]>([]);
	constructor(httpClient: HttpClient) {
		super(httpClient);
	}

	getIncidents(payload = {}) {
		return this.get("ux/sd/inci/incident/", payload);
	}

	saveIncident(payload: any) {
		return this.post("ux/sd/inci/incident/", payload);
	}

	getIncident(incident_id: any, payload: any) {
		return this.get("ux/sd/inci/incident/" + incident_id + "/", payload);
	}

	editIncident(incident_id: any, payload: any) {
		return this.put("ux/sd/inci/incident/" + incident_id + "/", payload);
	}
	deleteIncident(payload: any) {
		return this.post("ux/sd/inci/incident/delete_incident/", payload);
	}
	getIncidentColumns(payload = {}) {
		return this.get("ux/sd/inci/incident/page_config/", payload);
	}
	getInciAttachmentDetails(payload = {}) {
		return this.get("ux/sd/inci/incident/attachment/", payload);
	}

	getInciHistoryDetails(payload = {}) {
		return this.get("ux/sd/inci/incident/history/", payload);
	}

	addInciAttachments(payload: any) {
		return this.post("ux/sd/inci/incident/add-attachment/", payload);
	}

	savePageConfig(payload) {
		return this.post("ux/sd/inci/incident/page/", payload);
	}
	getOptions(payload = {}) {
		return this.get("ux/sd/inci/incident/options/", payload);
	}
	getExpertise(payload = {}) {
		return this.get("ux/sd/inci/incident/expertise/", payload);
	}
	getExpertiseLevels(payload = {}) {
		return this.get("ux/sd/inci/incident/team_level/", payload);
	}
	getAssigneeList(payload = {}) {
		return this.get("ux/sd/inci/incident/assignee/", payload);
	}
	getUserProfile(userid: Number) {
		return this.get("ux/common/user/profile/" + userid.toString() + "/");
	}
	getServicesForSelectedCatalogue(payload: any) {
		return this.get("ux/common/catalogue/servicecatalogue/service-list/", payload);
	}
	getServiceDetails(serviceId: Number) {
		return this.get("ux/common/catalogue/servicecatalogue/" + serviceId.toString() + "/");
	}
	updateIncidentAssignee(payload = {}) {
		return this.put("ux/sd/inci/incident/update-assignee/", payload);
	}
	getFilteredIncident(payload = {}) {
		return this.get("ux/sd/inci/incident/top_filter/", payload);
	}
	saveConversation(payload) {
		return this.post("ux/sd/inci/conversation/", payload);
	}
	getMailConversations(payload) {
		return this.get("ux/sd/inci/conversation/", payload);
	}
	updateIncidentTag(payload = {}) {
		return this.put("ux/sd/inci/incident/update-tag/", payload);
	}
	saveRichTextData(payload: {}) {
		return this.post("ux/sd/inci/incident/save-rich-text/", payload);
	}

	getRichTextData(payload: {}) {
		return this.get("ux/sd/inci/incident/process-rich-text/", payload);
	}
	getRequesterList(payload: {}) {
		return this.get("ux/sd/inci/incident/requester_search/", payload);
	}

	processIncidentRelations(payload: {}) {
		return this.get("ux/sd/inci/incident/process-relations/", payload);
	}

	mergeIncident(payload: {}) {
		return this.post("ux/sd/inci/incident/merge/", payload);
	}
	getReqLatestIncidents(payload: {}) {
		return this.get("ux/sd/inci/incident/latestincidents/", payload);
	}
	deleteAttachment(attachmentId: any, payload: {}) {
		return this.put("ux/sd/inci/incident/delete-attachment/" + attachmentId.toString() + "/", payload);
	}
	convertIncidentToKB(kbId: any, payload: {}) {
		return this.put("ux/sd/inci/incident/incident-to-kb/" + kbId.toString() + "/", payload);
	}

	getAllKBs(payload: {}) {
		return this.get("ux/sd/inci/incident/incident-kbs/", payload);
	}

	getFilterOption(payload: any) {
		return this.get("ux/common/filters/filters/filter_options/", payload);
	}

	getSavedFilter(payload) {
		return this.get("ux/common/filters/filters/filter/", payload);
	}

	getServiceBaseWorkflowStatus(payload: any) {
		return this.get("ux/sd/inci/incident/workflow_status/", payload);
	}

	setEditIncidentSidebar(flag) {
		localStorage.setItem("incident-edit-flag", JSON.stringify(flag));
	}

	getIncidentSidebar() {
		return JSON.parse(localStorage.getItem("incident-edit-flag"));
	}

	editIncidentAssignee(payload) {
		return this.post("ux/sd/inci/incident/update_incident_assignee/", payload);
	}
	getKBProfiles(payload = {}) {
		return this.get("ux/common/kb/kb/", payload);
	}

	getOptionsData(payload = {}) {
		return this.get("ux/common/kb/kb/options/", payload);
	}

	saveKB(payload: any) {
		return this.post("ux/common/kb/kb/", payload);
	}

	getKBProfile(id) {
		return this.get("ux/common/kb/kb/" + id.toString() + "/");
	}

	deleteKB(id) {
		return this.delete("ux/common/kb/kb/" + id.toString() + "/");
	}

	saveKBComment(payload) {
		return this.post("ux/common/kb/kb/comment/", payload);
	}

	getRecentPosts(payload = {}) {
		return this.get("ux/common/kb/kb/recent/", payload);
	}

	getRelatedPosts(payload = {}) {
		return this.get("ux/common/kb/kb/related/", payload);
	}

	getBookmarkedPosts(payload = {}) {
		return this.get("ux/common/kb/kb/bookmarks/", payload);
	}

	saveKBFeedback(payload) {
		return this.post("ux/common/kb/kb/feedback/", payload);
	}

	getAttachmentDetails(payload = {}) {
		return this.get("ux/common/kb/kb/attachment/", payload);
	}

	addAttachments(payload: any) {
		return this.post("ux/common/kb/kb/add-attachment/", payload);
	}

	delAttachment(attachmentId: any, payload: {}) {
		return this.put("ux/common/kb/kb/delete-attachment/" + attachmentId.toString() + "/", payload);
	}
	getKBViewers(payload = {}) {
		return this.get("ux/common/kb/kb/viewers/", payload);
	}

	getHistoryDetails(payload: any) {
		return this.get("ux/common/kb/kb/history/", payload);
	}

	getKBRatings(payload = {}) {
		return this.get("ux/common/kb/kb/ratings/", payload);
	}
	getKBComments(payload = {}) {
		return this.get("ux/common/kb/kb/comments/", payload);
	}

	getCategories(payload = {}) {
		return this.get("ux/common/kb/kb/categories/", payload);
	}
	getCMDBCis(payload: any) {
		return this.get("ux/common/cmdb/profile/", payload);
	}
	getAssetSummary(payload: any) {
		return this.get("ux/common/cmdb/profile/asset-summary/", payload);
	}
	checkValidAssetRequest(token: String) {
		return this.get("ux/asset/" + token + "/");
	}

	allocate_asset(payload: any) {
		return this.post("ux/common/cmdb/atk/allocate/", payload);
	}

	deallocate_asset(payload: any) {
		return this.post("ux/common/cmdb/atk/deallocate/", payload);
	}
	getRequesterImpactService(payload) {
		return this.get("ux/common/catalogue/servicecatalogue/req_services/", payload);
	}

	getRequests(payload = {}) {
		return this.get("ux/sd/req/request_process/", payload);
	}

	saveRequest(payload: any) {
		return this.post("ux/sd/req/request_process/", payload);
	}

	getRequest(request_id: any, payload: any) {
		return this.get("ux/sd/req/request_process/" + request_id + "/", payload);
	}

	editRequest(request_id: any, payload: any) {
		return this.put("ux/sd/req/request_process/" + request_id + "/", payload);
	}
	deleteRequest(payload: any) {
		return this.post("ux/sd/req/request_process/delete_request/", payload);
	}
	getReqMailConversations(payload) {
		return this.get("ux/sd/req/conversation/", payload);
	}
	getRequestAttachmentDetails(payload = {}) {
		return this.get("ux/sd/req/request_process/attachment/", payload);
	}

	getRequestRichTextData(payload: {}) {
		return this.get("ux/sd/req/request_process/process-rich-text/", payload);
	}
	saveRequestRichTextData(payload: {}) {
		return this.post("ux/sd/req/request_process/save-rich-text/", payload);
	}
	getRequestOptions(payload = {}) {
		return this.get("ux/sd/req/request_process/options/", payload);
	}

	getAllRequestKBs(payload: {}) {
		return this.get("ux/sd/req/request_process/request-kbs/", payload);
	}
	getRequestServiceBaseWorkflowStatus(payload: any) {
		return this.get("ux/sd/req/request_process/workflow_status/", payload);
	}
	getAssetDetails(id: any) {
		return this.get("ux/common/cmdb/profile/" + id.toString() + "/");
	}

	getImpactServiceClassification(payload) {
		return this.get("ux/common/catalogue/servicecatalogue/service_classes/", payload);
	}

	getDefaultRequesterDashboard(payload) {
        return this.get("ux/common/dashboard/dashboard/requester-default-dashboard/", payload);
    }
	getFeedbackData(payload){
		return this.get("ux/sd/inci/incident/feedback/", payload)
	}
	saveFeedbackData(payload){
		return this.post("ux/common/csat/response/", payload)
	}
	getImpactedAssetList(payload){
		return this.get("ux/sd/inci/incident/impacted_asset/", payload)
	}
}
