import { findIndex, filter } from "rxjs/operators";
import { ControlContainer, NgForm } from "@angular/forms";

import { Component, OnInit, EventEmitter, Input, OnChanges, SimpleChanges, Output, AfterViewInit } from "@angular/core";
import { FlatpickrOptions } from "ng2-flatpickr";
import { TranslateService } from "@ngx-translate/core";

const REQUEST_FORM_DATA = {
	request_form: [
		{
			type: "text",
			icon: "fa-font",
			label: "Text",
			description: "Enter your name",
			placeholder: "Enter your name",
			className: "form-control",
			subtype: "text",
			regex: "",
			handle: true,
			roles: [],
			col_val: "col-6",
			is_customfield: true,
			name: "col_1626977658624",
			data_key: "col_1626977658624",
		},
	],
	form_layout: {},
};

@Component({
	selector: "app-dns-service-form",
	templateUrl: "./dns-service-form.component.html",
	styleUrls: ["./dns-service-form.component.scss"],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class DnsServiceFormComponent implements OnInit, OnChanges, AfterViewInit {
	@Input("config") requestFormConfig: any;
	@Input("data") requestFormData: any;
	filterConfigData: any;
	@Input("placeholder") section: any = 1;
	@Input("formRef") formRef: NgForm;
	@Input("selfservice") selfservice: boolean = false;
	@Input("disable") disableField: boolean = false;

	public basicDateOptions: FlatpickrOptions = { altInput: true,
		// disable: [
		// 	(date) => {
		// 		return this.disableField ? false : false; // for disabling picker we need to pass function with truth value or dates list
		// 	},
		// ],
	 };
	public dateTimeOptions: FlatpickrOptions = { altInput: true, enableTime: true ,
		// disable: [
		// 	(date) => {
		// 		return this.disableField ? [] : false; // for disabling picker we need to pass function with truth value or dates list
		// 	},
		// ],
	};

	constructor(private _translateServce: TranslateService) {
		this.requestFormConfig = REQUEST_FORM_DATA;
		this.requestFormData = {};
	}

	ngOnInit(): void {
		if (this.requestFormConfig) {
			this.filterConfigData = JSON.parse(JSON.stringify(this.requestFormConfig));
			if (this.section) {
				let cf_data = this.requestFormConfig?.request_form?.filter((d) => d?.section == this.section);
				this.filterConfigData.request_form = cf_data;
			}
		}
	}

	ngAfterViewInit(): void {}

	getTranslatedText = (text, param = {}) => {
		if (text) {
			return this._translateServce.instant(text, param);
		}
		return text;
	};

	toggleValue = (item, key) => {
		item.selected = !item.selected;
		if (!this.requestFormData[key]) {
			this.requestFormData[key] = {};
		}
		const data = { key: item?.id, value: item?.value };
		if (this.requestFormData[key][item.value]) {
			delete this.requestFormData[key][item.value];
		} else {
			this.requestFormData[key][item.value] = data;
		}
	};

	ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			if (this.requestFormConfig) {
				this.filterConfigData = JSON.parse(JSON.stringify(this.requestFormConfig));
				if (this.section) {
					let cf_data = this.requestFormConfig?.request_form?.filter((d) => d?.section == this.section);
					this.filterConfigData.request_form = cf_data;
				}
			}
		}
	}

	mergeText = (txt) => {
		return txt ? txt.toLowerCase().split(" ").join("-") : "";
	};

	onCheckBoxLoad = (cbData, data) => {
		if (!this.requestFormData[data?.name]) {
			this.requestFormData[data?.name] = {};
		}
		if (cbData && data) {
			return this.requestFormData[data?.name][cbData["value"]] ? true : false;
		} else {
			return false;
		}
	};

	onChange(event) {
		event.srcElement.value = "";
	}
}
