import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { RouterModule } from "@angular/router";

import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CoreMenuModule } from "@core/components";
import { CoreCommonModule } from "@core/common.module";
import { SystemMenuModule } from "../../../../../base/system-menu/system-menu.module";
import { VerticalMenuComponent } from "app/common/layout/components/menu/vertical-menu/vertical-menu.component";
import { SharedModule } from "app/shared.module";
import { NgScrollbarModule } from "ngx-scrollbar";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true,
	wheelPropagation: false,
};
@NgModule({
	imports: [
		CoreMenuModule,
		CoreCommonModule,
		PerfectScrollbarModule,
		RouterModule,
		SystemMenuModule,
		NgbModule,
		SharedModule,
		NgScrollbarModule,
	],
	providers: [
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
		},
	],
	exports: [VerticalMenuComponent],
	declarations: [VerticalMenuComponent],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	],
})
export class VerticalMenuModule {}
