<div class="slideout-content default-stepping">
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
		<div class="modal-dialog">
			<!-- form-width -->
			<div class="modal-content add-new-user pt-0 pb-0">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">
						{{ "UI.k_edit_user" | translate }}
					</h5>
					<button
						type="button"
						id="btnClose"
						name="btnClose"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						(click)="toggleSidebar('user-profile')"
						[attr.data-testid]="'btnClose'"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body">
					<section class="horizontal-wizard" style="height: 100%">
						<div id="stepper1" class="bs-stepper horizontal-wizard-example">
							<div class="bs-stepper-header">
								<div id="divAccntTab" name="divAccntTab" class="step" data-target="#account-details">
									<button id="btnUserAccConf" name="btnUserAccConf" class="step-trigger" [attr.data-testid]="'btnUserAccConf'">
										<span class="bs-stepper-box">1</span>
										<span class="bs-stepper-label">
											<span class="bs-stepper-title">{{ "UI.k_user" | translate }} </span>
											<span class="bs-stepper-subtitle"
												>{{ "UI.k_add_user_info" | translate }}
											</span>
										</span>
									</button>
								</div>
								<div class="line">
									<i class="fa-light fa-chevron-right font-medium-2"></i>
								</div>
								<div id="divPsnlInfo" name="divPsnlInfo" class="step" data-target="#personal-info">
									<button id="btnUserPsnlConf" name="btnUserPsnlConf" class="step-trigger" [attr.data-testid]="'btnUserPsnlConf'">
										<span class="bs-stepper-box">2</span>
										<span class="bs-stepper-label">
											<span class="bs-stepper-title">{{ "UI.k_work_details" | translate }} </span>
											<span class="bs-stepper-subtitle"
												>{{ "UI.k_add_work_details" | translate }}
											</span>
										</span>
									</button>
								</div>
								<div class="line">
									<i class="fa-light fa-chevron-right font-medium-2"></i>
								</div>
								<div id="divAddr" name="divAddr" class="step" data-target="#address">
									<button class="step-trigger" [attr.data-testid]="'btnAddLocInfo'">
										<span class="bs-stepper-box">3</span>
										<span class="bs-stepper-label">
											<span class="bs-stepper-title">{{ "UI.k_address" | translate }}</span>
											<span class="bs-stepper-subtitle"
												>{{ "UI.k_add_loc_info" | translate }}
											</span>
										</span>
									</button>
								</div>
							</div>
							<div class="bs-stepper-content">
								<!-- (ngSubmit)="(UserAddForm.form.valid)" -->
								<div class="bs-stepper-inner">
									<form #UserAddForm="ngForm">
										<div id="account-details" class="content">
											<form #accountDetailsForm="ngForm" class="stepper-inner">
												<div class="stepper-body">
													<ng-scrollbar class="custom-scrollbar section-scrollbar">
														<div class="stepper-main">
															<div class="row">
																<div class="col-xs-12 col-md-2 col-xl-2 col-lg-2 px-2">
																	<app-profile-avatar
																		(avatarDetails)="profileImage($event)"
																		(resetImage)="removeImage($event)"
																		(avatarBgColor)="ProfileBgColor($event)"
																		[profileName]="fullName"
																		[profile_image]="currentImgDetails"
																		[avatarBackgroundColor]="currentAvatarColor"
																		[activeColor]="activeAvatar"
																		(currentAvatar)="currrentColor($event)"
																	></app-profile-avatar>
																</div>
																<div class="col-xs-12 col-md-10 col-xl-10 col-lg-10">
																	<div class="row">
																		<div
																			class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6"
																		>
																			<label class="form-label" for="txtFName"
																				>{{ "UI.k_fname" | translate
																				}}<span class="required-field"
																					>&nbsp;*</span
																				></label
																			>
																			<!-- formControlName="userName" -->
																			<input
																				[(ngModel)]="userProfile.full_name"
																				#TDNameRef="ngModel"
																				required
																				type="text"
																				name="txtFName"
																				id="txtFName"
																				class="form-control"
																				placeholder="{{
																					'UI.k_placeholder_name' | translate
																				}}"
																				minlength="3"
																				maxlength="40"
																				[appWhiteSpaceCheck]="
																					userProfile.full_name
																				"
																				[class.error]="
																					!TDNameRef.valid &&
																					accountDetailsForm.submitted
																				"
																			/>

																			<span
																				*ngIf="
																					accountDetailsForm.submitted &&
																					TDNameRef.invalid
																				"
																				class="invalid-form"
																			>
																				<small
																					class="form-text text-danger"
																					*ngIf="TDNameRef.errors.required"
																					>{{
																						"UI.k_field_required"
																							| translate
																					}}
																				</small>
																				<small
																					class="form-text text-danger"
																					*ngIf="
																						TDNameRef.errors.minlength ||
																						TDNameRef.errors.maxlength
																					"
																					>{{
																						"UI.k_name_char_validation"
																							| translate
																					}}
																				</small>
																				<small
																					class="form-text text-danger"
																					*ngIf="TDNameRef.errors?.whitespace"
																					>{{
																						"UI.k_space_validation"
																							| translate
																					}}
																				</small>
																			</span>
																		</div>
																		<div
																			class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6"
																		>
																			<label class="form-label" for="txtEmail"
																				>{{ "UI.k_email" | translate
																				}}<span class="required-field"
																					>&nbsp;*</span
																				></label
																			>
																			<input
																				[(ngModel)]="userProfile.email"
																				#TDEmailRef="ngModel"
																				required
																				email
																				type="email"
																				name="txtEmail"
																				id="txtEmail"
																				class="form-control"
																				[class.error]="
																					!TDEmailRef.valid &&
																					accountDetailsForm.submitted
																				"
																				placeholder="{{
																					'UI.k_email_placeholder' | translate
																				}}"
																				aria-label="john.doe"
																				readonly
																			/>
																			<span
																				*ngIf="accountDetailsForm.submitted"
																				class="invalid-form"
																			>
																				<small
																					class="form-text text-danger"
																					*ngIf="!TDEmailRef.valid"
																					>{{
																						"UI.k_alert_email_invalid"
																							| translate
																					}}</small
																				>
																			</span>
																		</div>
																	</div>
																	<div class="row">
																		<div
																			class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6"
																		>
																			<label for="txtUsrPwd"
																				>{{ "UI.k_password" | translate
																				}}<span class="required-field"
																					>&nbsp;*</span
																				></label
																			>
																			<div
																				class="input-group input-group-merge form-password-toggle"
																			>
																				<!-- placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" -->
																				<input
																					[(ngModel)]="userProfile.password"
																					#TDPasswordOnlyRef="ngModel"
																					required
																					[type]="
																						userPwdShow
																							? 'text'
																							: 'password'
																					"
																					class="form-control"
																					id="txtUsrPwd"
																					name="txtUsrPwd"
																					placeholder="{{
																						'UI.k_password' | translate
																					}}"
																					aria-describedby="txtUsrPwd"
																					class="form-control form-control-merge"
																					[class.error]="
																						!TDPasswordOnlyRef.valid &&
																						accountDetailsForm.submitted &&
																						is_password_invalid
																					"
																					(ngModelChange)="
																						validatePassword(
																							this.userProfile.password
																						)
																					"
																				/>
																				<div
																					class="input-group-append"
																					(click)="userPwdShow = !userPwdShow"
																				>
																					<span
																						class="input-group-text cursor-pointer"
																						id="passwordeye"
																						><i
																							class="feather"
																							[ngClass]="{
																								'icon-eye-off':
																									userPwdShow,
																								'icon-eye': !userPwdShow
																							}"
																						></i
																					></span>
																				</div>
																			</div>
																			<span
																				*ngIf="is_password_invalid"
																				class="invalid-form"
																			>
																				<small
																					class="form-text text-danger"
																					[innerHtml]="
																						passwordToaster | translate
																					"
																				>
																				</small>
																			</span>
																			<span
																				*ngIf="
																					TDPasswordOnlyRef.invalid &&
																					accountDetailsForm.submitted
																				"
																				class="invalid-form"
																			>
																				<small
																					*ngIf="
																						TDPasswordOnlyRef.errors
																							.required
																					"
																					class="form-text text-danger"
																				>
																					{{
																						"UI.k_field_required"
																							| translate
																					}}
																				</small>
																			</span>
																		</div>

																		<div
																			class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6"
																		>
																			<label class="form-label" for="selUsrRole">
																				{{ "UI.k_role" | translate }}
																				<span class="required-field"
																					>*</span
																				></label
																			>
																			<ng-select
																				required
																				[items]="roleList"
																				[(ngModel)]="userProfile.group_id"
																				bindLabel="name"
																				bindValue="group_id"
																				name="selUsrRole"
																				id="selUsrRole"
																				placeholder="{{
																					'UI.k_select_role' | translate
																				}}"
																				#TDRoleIDRef="ngModel"
																				notFoundText="{{
																					'UI.k_no_items_found' | translate
																				}}"
																			>
																			</ng-select>
																			<span
																				*ngIf="
																					TDRoleIDRef.invalid &&
																					accountDetailsForm.submitted
																				"
																				class="invalid-form"
																			>
																				<small
																					*ngIf="TDRoleIDRef.errors.required"
																					class="form-text text-danger"
																				>
																					{{
																						"UI.k_field_required"
																							| translate
																					}}
																				</small>
																			</span>
																		</div>
																	</div>

																	<div class="row">
																		<div
																			class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6"
																		>
																			<label
																				class="form-label"
																				for="selUsrDashboard"
																				>{{
																					"UI.k_default_dashboard" | translate
																				}}</label
																			>
																			<ng-select
																				[items]="dashboardList"
																				[(ngModel)]="
																					userProfile.profile.dashboard
																				"
																				bindLabel="name"
																				bindValue="dashboard_id"
																				name="selUsrDashboard"
																				id="selUsrDashboard"
																				placeholder="{{
																					'UI.k_select_dashboard' | translate
																				}}"
																				notFoundText="{{
																					'UI.k_no_dashboard' | translate
																				}}"
																			>
																			</ng-select>
																		</div>

																		<div
																			class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6"
																		>
																			<label class="form-label" for="selUsrTzone">
																				{{ "UI.k_timezone" | translate
																				}}<span class="required-field"
																					>&nbsp;*</span
																				></label
																			>
																			<ng-select
																				[items]="timezoneList"
																				[closeOnSelect]="true"
																				[searchable]="true"
																				bindLabel="name"
																				placeholder="{{
																					'UI.k_select_timezone' | translate
																				}}"
																				[(ngModel)]="
																					userProfile.profile.timezone
																				"
																				name="selUsrTzone"
																				id="selUsrTzone"
																				notFoundText="{{
																					'UI.k_no_timezone' | translate
																				}}"
																				[class.error]="
																					!TDTimezoneRef.valid &&
																					accountDetailsForm.submitted
																				"
																				#TDTimezoneRef="ngModel"
																				required
																			>
																			</ng-select>
																			<span
																				*ngIf="
																					accountDetailsForm.submitted &&
																					TDTimezoneRef.invalid
																				"
																				class="invalid-form"
																			>
																				<small
																					class="form-text text-danger"
																					*ngIf="
																						TDTimezoneRef.errors.required
																					"
																					>{{
																						"UI.k_field_required"
																							| translate
																					}}</small
																				>
																			</span>
																		</div>
																	</div>
																	<div class="row">
																		<div
																			class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6"
																		>
																			<label class="form-label" for="txtPhone">{{
																				"UI.k_contact_number" | translate
																			}}</label>
																			<!-- formControlName="userName" -->
																			<div class="w-100">
																				<ngx-intl-tel-input
																					class="w-100"
																					[cssClass]="
																						'custom form-control w-100'
																					"
																					[preferredCountries]="
																						preferredCountries
																					"
																					[enableAutoCountrySelect]="true"
																					[enablePlaceholder]="true"
																					[searchCountryFlag]="true"
																					[searchCountryField]="[
																						SearchCountryField.Iso2,
																						SearchCountryField.Name
																					]"
																					[selectFirstCountry]="false"
																					[selectedCountryISO]="
																						CountryISO.UnitedStates
																					"
																					[maxLength]="15"
																					[phoneValidation]="true"
																					[separateDialCode]="
																						separateDialCode
																					"
																					[numberFormat]="
																						PhoneNumberFormat.National
																					"
																					name="txtPhone"
																					id="txtPhone"
																					[(ngModel)]="
																						userProfile.profile.phone_number
																					"
																					#TDPhoneRef="ngModel"
																					[ngClass]="{
																						'is-invalid':
																							accountDetailsForm.submitted &&
																							TDPhoneRef.errors
																					}"
																				>
																				</ngx-intl-tel-input>
																				<span
																					*ngIf="
																						accountDetailsForm.submitted &&
																						TDPhoneRef.invalid
																					"
																					class="invalid-form"
																				>
																					<small
																						class="form-text text-danger"
																						*ngIf="TDPhoneRef.errors"
																						>{{
																							"UI.k_phone_number_validation"
																								| translate
																						}}</small
																					>
																				</span>
																			</div>
																			<!-- <input
																				[(ngModel)]="userProfile.profile.contact_number"
																				#TDPhoneRef="ngModel"
																				type="text"
																				name="txtPhone"
																				id="txtPhone"
																				class="form-control"
																				placeholder="xxxxxxxxxx"
																				pattern="^[0-9]{6,10}$"
																				[class.error]="
																					!TDPhoneRef.valid &&
																					accountDetailsForm.submitted
																				"
																			/> -->

																			<!-- <span
																				*ngIf="
																					accountDetailsForm.submitted &&
																					TDPhoneRef.invalid
																				"
																				class="invalid-form"
																			>
																				<small
																					class="form-text text-danger"
																					*ngIf="TDPhoneRef.errors.pattern"
																					>{{
																						"UI.k_pattern_validation" | translate
																					}}</small
																				>
																			</span> -->
																		</div>
																		<div
																			class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6"
																		>
																			<label
																				class="form-label"
																				for="txtLandline"
																				>{{
																					"UI.k_landline" | translate
																				}}</label
																			>
																			<!-- formControlName="landline" -->
																			<input
																				[(ngModel)]="
																					userProfile.profile.landline
																				"
																				#TDLandLineRef="ngModel"
																				type="text"
																				name="txtLandline"
																				id="txtLandline"
																				landline
																				class="form-control"
																				placeholder="xxxxxxxxxx"
																				pattern="[0-9-]{6,12}$"
																				[class.error]="
																					!TDLandLineRef.valid &&
																					accountDetailsForm.submitted
																				"
																			/>

																			<span
																				*ngIf="
																					accountDetailsForm.submitted &&
																					TDLandLineRef.invalid
																				"
																				class="invalid-form"
																			>
																				<small
																					class="form-text text-danger"
																					*ngIf="TDLandLineRef.errors.pattern"
																					>{{
																						"UI.k_pattern_validation"
																							| translate
																					}}</small
																				>
																			</span>
																		</div>
																	</div>
																	<div class="row">
																		<div
																			class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6"
																		>
																			<label
																				class="d-block mb-1"
																				for="selUsrGender"
																				>{{ "UI.k_gender" | translate
																				}}<span class="required-field"
																					>&nbsp;*</span
																				></label
																			>
																			<div
																				class="custom-control custom-radio custom-control-inline"
																			>
																				<input
																					id="male"
																					type="radio"
																					value="1"
																					name="version"
																					(change)="changeGender($event)"
																					class="custom-control-input"
																					[checked]="
																						userProfile.profile.gender == 1
																					"
																				/>
																				<label
																					class="custom-control-label"
																					for="male"
																					>&nbsp;{{
																						"UI.k_male" | translate
																					}}</label
																				>
																			</div>
																			<div
																				class="custom-control custom-radio custom-control-inline"
																			>
																				<input
																					id="female"
																					type="radio"
																					value="2"
																					name="version"
																					(change)="changeGender($event)"
																					class="custom-control-input"
																					[checked]="
																						userProfile.profile.gender == 2
																					"
																				/>
																				<label
																					class="custom-control-label"
																					for="female"
																					>&nbsp;{{
																						"UI.k_female" | translate
																					}}</label
																				>
																			</div>
																			<div
																				class="custom-control custom-radio custom-control-inline"
																			>
																				<input
																					id="others"
																					type="radio"
																					value="3"
																					name="version"
																					(change)="changeGender($event)"
																					class="custom-control-input"
																					[checked]="
																						userProfile.profile.gender == 3
																					"
																				/>
																				<label
																					class="custom-control-label"
																					for="others"
																					>&nbsp;{{
																						"UI.k_others" | translate
																					}}</label
																				>
																			</div>
																		</div>
																		<div
																			class="form-group col-xs-12 col-md-3 col-xl-3 col-lg-3 pl-1 custom-control custom-control-primary custom-switch d-flex justify-content-between"
																		>
																			<span
																				><b>{{
																					"UI.k_is_active" | translate
																				}}</b></span
																			>
																			<input
																				type="checkbox"
																				[checked]="userProfile.is_active"
																				[(ngModel)]="userProfile.is_active"
																				class="custom-control-input"
																				id="togUsrActv"
																				name="togUsrActv"
																				(change)="onUserActive()"
																			/>
																			<label
																				class="custom-control-label"
																				for="togUsrActv"
																			>
																			</label>
																		</div>
																	</div>
																</div>
															</div>
															<div class="d-flex">
																<div class="mr-auto">
																	<!--<button-->
																	<!--(click)="horizontalWizardStepperPrevious()"-->
																	<!--class="btn btn-outline-secondary btn-prev"-->
																	<!--disabled-->
																	<!--rippleEffect-->
																	<!--id="btnAcntPrev"-->
																	<!--name="btnAcntPrev"-->
																	<!--&gt;-->

																	<!--<i class="fa-light fa-arrow-left align-middle mr-sm-25 mr-0"></i>-->

																	<!--<span class="align-middle d-sm-inline-block d-none"-->
																	<!--&gt;{{ 'UI.k_back' | translate}}</span-->
																	<!--&gt;-->
																	<!--</button>-->
																</div>
															</div>
														</div>
													</ng-scrollbar>
												</div>
												<div class="stepper-btn d-flex justify-content-end">
													<button
														(click)="onSubmit(accountDetailsForm)"
														type="submit"
														[disabled]="is_save_disabled"
														class="btn btn-outline-tertiary mr-1"
														rippleEffect
														id="btnAcntSave"
														name="btnAcntSave"
														[attr.data-testid]="'btnAcntSave'"
													>
														<i class="fa-light fa-floppy-disk align-middle mr-50 ml-0"></i>
														<span class="align-middle d-sm-inline-block d-none">{{
															"UI.k_submit" | translate
														}}</span>
													</button>
													<!-- </div>
													<div> -->
													<button
														(click)="horizontalWizardStepperNext(accountDetailsForm)"
														type="submit"
														class="btn btn-primary btn-next"
														rippleEffect
														id="btnAcntNxt"
														name="btnAcntNxt"
														[attr.data-testid]="'btnAcntNxt'"
													>
														<span class="align-middle d-sm-inline-block d-none">{{
															"UI.k_next" | translate
														}}</span>

														<i
															class="fa-light fa-arrow-right align-middle ml-sm-25 ml-0"
														></i>
													</button>
												</div>
											</form>
										</div>
										<div id="personal-info" class="content">
											<form #personalInfoForm="ngForm" class="stepper-inner">
												<!-- <div class="content-header">
													<h5 class="mb-0">Work Info</h5>
													<small>Enter Your Work Info.</small>
												</div> -->

												<div class="stepper-body">
													<ng-scrollbar class="custom-scrollbar section-scrollbar">
														<div class="stepper-main">
															<div class="row">
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-label" for="txtDsgn">{{
																		"UI.k_designation" | translate
																	}}</label>
																	<input
																		[(ngModel)]="userProfile.profile.designation"
																		#TDDesignationRef="ngModel"
																		type="text"
																		name="txtDsgn"
																		id="txtDsgn"
																		class="form-control"
																		pattern="^[^\s]+(\s+[^\s]+)*$"
																		[class.error]="
																			personalInfoForm.submitted &&
																			TDDesignationRef.invalid
																		"
																		placeholder="{{
																			'UI.k_place_designation' | translate
																		}}"
																	/>
																	<span
																		*ngIf="
																			personalInfoForm.submitted &&
																			TDDesignationRef.invalid &&
																			(TDDesignationRef.dirty ||
																				TDDesignationRef.touched)
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDDesignationRef.errors.pattern"
																			>{{ "UI.k_space_validation" | translate }}
																		</small>
																	</span>
																</div>
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-label" for="txtOrgRole">{{
																		"UI.k_organization_role" | translate
																	}}</label>
																	<input
																		[(ngModel)]="
																			userProfile.profile.organization_role
																		"
																		#TDOrgRoleRef="ngModel"
																		type="text"
																		name="txtOrgRole"
																		id="txtOrgRole"
																		class="form-control"
																		pattern="^[^\s]+(\s+[^\s]+)*$"
																		[class.error]="
																			personalInfoForm.submitted &&
																			TDOrgRoleRef.invalid
																		"
																		placeholder="{{
																			'UI.k_place_orgrole' | translate
																		}}"
																	/>
																	<span
																		*ngIf="
																			personalInfoForm.submitted &&
																			TDOrgRoleRef.invalid &&
																			(TDOrgRoleRef.dirty || TDOrgRoleRef.touched)
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDOrgRoleRef.errors.pattern"
																			>{{ "UI.k_space_validation" | translate }}
																		</small>
																	</span>
																</div>
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-label" for="selDptmt">{{
																		"UI.k_department" | translate
																	}}</label>
																	<ng-select
																		[items]="departmentList"
																		[(ngModel)]="userProfile.profile.department"
																		bindLabel="name"
																		bindValue="dept_id"
																		name="selDptmt"
																		id="selDptmt"
																		class="form-control"
																		placeholder="{{
																			'UI.k_select_department' | translate
																		}}"
																		notFoundText="{{
																			'UI.k_no_department' | translate
																		}}"
																	>
																	</ng-select>
																</div>
															</div>
															<div class="row">
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-label" for="selUsrRepMgr">{{
																		"UI.k_reporting_manager" | translate
																	}}</label>
																	<ng-select
																		[items]="managerList"
																		[(ngModel)]="
																			userProfile.profile.reporting_manager
																		"
																		bindLabel="full_name"
																		bindValue="profile_id"
																		[searchable]="true"
																		[searchFn]="customSearchFn"
																		name="selUsrRepMgr"
																		id="selUsrRepMgr"
																		placeholder="{{
																			'UI.k_select_reporting_manager' | translate
																		}}"
																		notFoundText="{{
																			'UI.k_no_reporting_manger' | translate
																		}}"
																	>
																		<ng-template
																			ng-option-tmp
																			let-item="item"
																			let-index="index"
																			let-search="searchTerm"
																		>
																			<span
																				class="d-flex"
																			>
																				<div
																					class="avatar mr-1 ml-0"
																					[ngClass]="bg - light - primary"
																					style="
																						align-content: center;
																						width: 32px;
																						height: 32px;
																					"
																				>
																					<div
																						*ngIf="
																							item?.profile_image;
																							else customAvatar
																						"
																					>
																						<img
																							class="rounded-circle mr-1"
																							src="{{
																								item.profile_image
																							}}"
																							height="32"
																							width="32"
																							alt="datatable-avatar"
																						/>
																					</div>
																					<ng-template #customAvatar>
																						<div
																							class="avatar mr-1 ml-0"
																							[ngStyle]="{
																								'background-color':
																									item.avatar_color
																										? item.avatar_color
																										: '#f59518'
																							}"
																						>
																							<div class="avatar-content">
																								{{
																									item.full_name
																										| initials
																										| slice: O:2
																								}}
																							</div>
																						</div>
																					</ng-template>
																				</div>
																				<div class="cell-line-height">
																					<p
																						class="font-medium-1 font-weight-bold line-height-1 mb-25"
																					>
																						{{ item.full_name }}
																					</p>
																					<span
																						class="text-muted font-small-2"
																					>
																						{{ item.email }}</span
																					>
																				</div>
																			</span>
																		</ng-template>
																	</ng-select>
																</div>
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-control-label" for="txtExpYr">{{
																		"UI.k_experience_years" | translate
																	}}</label>
																	<input
																		class="form-control input-md"
																		[class.error]="
																			TDExperYearRef.invalid &&
																			personalInfoForm.submitted
																		"
																		#TDExperYearRef="ngModel"
																		id="txtExpYr"
																		name="txtExpYr"
																		type="text"
																		[(ngModel)]="
																			userProfile.profile.experience_years
																		"
																		placeholder="{{
																			'UI.k_place_ex_year' | translate
																		}}"
																		pattern="^(0?[0-9]|[1-9][0-9])$"
																	/>
																	<div
																		class="error"
																		*ngIf="
																			personalInfoForm.submitted &&
																			TDExperYearRef.invalid
																		"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDExperYearRef.errors.pattern"
																		>
																			{{
																				"Message.msg_validate_exp_years"
																					| translate
																			}}
																		</small>
																	</div>
																	<!-- <small
																		class="form-text text-danger"
																		*ngIf="
																			TDExperYearRef.invalid && personalInfoForm.submitted
																		"
																		>{{ "UI.k_alert_numeric_field" | translate }}</small
																	> -->
																</div>
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-control-label" for="txtExpMth">{{
																		"UI.k_experience_months" | translate
																	}}</label>
																	<input
																		class="form-control input-md"
																		[class.error]="
																			TDExperMonthRef.invalid &&
																			personalInfoForm.submitted
																		"
																		[(ngModel)]="
																			userProfile.profile.experience_months
																		"
																		#TDExperMonthRef="ngModel"
																		pattern="([0-9]|10|11)"
																		id="txtExpMth"
																		name="txtExpMth"
																		type="text"
																		placeholder="{{
																			'UI.k_place_ex_month' | translate
																		}}"
																	/>
																	<div
																		class="error"
																		*ngIf="
																			personalInfoForm.submitted &&
																			TDExperMonthRef.invalid
																		"
																	>
																		<!-- <small
																			class="form-text text-danger"
																			*ngIf="
																				TDExperMonthRef.invalid &&
																				personalInfoForm.submitted
																			"
																			>{{ "UI.k_alert_numeric_field" | translate }}</small
																		> -->
																		<small
																			class="form-text text-danger"
																			*ngIf="TDExperMonthRef.errors.pattern"
																		>
																			{{
																				"Message.msg_validate_number_0_11"
																					| translate
																			}}
																		</small>
																	</div>
																</div>
															</div>

															<div class="row">
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-label" for="selSkills">{{
																		"UI.k_skills" | translate
																	}}</label>
																	<ng-select
																		[(ngModel)]="userProfile.profile.skills"
																		#TDSkillsRef="ngModel"
																		name="selSkills"
																		id="selSkills"
																		[items]="[]"
																		[addTag]="true"
																		[multiple]="true"
																		class="form-control"
																		placeholder="{{
																			'UI.k_enter_skills' | translate
																		}}"
																		[class.error]="
																			personalInfoForm.submitted &&
																			TDSkillsRef.invalid
																		"
																		notFoundText="{{
																			'UI.k_no_skills' | translate
																		}}"
																		addTagText="{{ 'UI.k_add_item' | translate }}"
																		hideSelected="true"
																	></ng-select>
																</div>

																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-label" for="selTags">{{
																		"UI.k_user_tag" | translate
																	}}</label>
																	<!-- <span
																		*ngIf="
																			tagPermission.add
																		"
																		class="pull-right cursor-pointer"
																		(click)="
																			openModalAD(
																				TagComp, 'UI.k_add_user_tag'
																			)
																		"
																		id="btnAddTag"
																	>
																		<i
																			class="fa-light fa-circle-plus mb-0 mr-sm-25 mr-0 pull-right"
																		></i>
																	</span> -->
																	<ng-select
																		[(ngModel)]="userProfile.profile.tags"
																		#TDTagsRef="ngModel"
																		name="selTags"
																		id="selTags"
																		[items]="tagList"
																		bindLabel="name"
																		bindValue="tag_id"
																		[closeOnSelect]="true"
																		[multiple]="true"
																		(remove)="
																			onRemoveTag(
																				userProfile.profile.tags,
																				$event
																			)
																		"
																		class="form-control"
																		placeholder="{{
																			'UI.k_select_tags' | translate
																		}}"
																		[class.error]="
																			personalInfoForm.submitted &&
																			TDTagsRef.invalid
																		"
																		notFoundText="{{ 'UI.k_no_tags' | translate }}"
																		[addTag]="selectAddUserTagMethod"
																		addTagText="{{ 'UI.k_add_tag' | translate }}"
																		[ngModelOptions]="{ standalone: true }"
																		hideSelected="true"
																	></ng-select>
																	<ng-template #tagComp let-modal>
																		<div class="modal-header">
																			<h4 class="modal-title" id="myModalLabel1">
																				{{ "UI.k_add_user_tag" | translate }}
																			</h4>
																			<button
																				type="button"
																				id="btnTagClose"
																				name="btnTagClose"
																				class="close"
																				(click)="modal.dismiss('Cross click')"
																				aria-label="Close"
																				[attr.data-testid]="'btnTagClose'"
																			>
																				<span aria-hidden="true">&times;</span>
																			</button>
																		</div>
																		<div
																			class="modal-body"
																			tabindex="0"
																			ngbAutofocus
																		>
																			<form #addTagForm="ngForm">
																				<div class="row mb-2">
																					<div
																						class="col-xs-12 col-md-6 col-sm-6 col-lg-6"
																					>
																						<div class="form-group">
																							<label for="txtTName">
																								{{
																									"UI.k_tag_name"
																										| translate
																								}}<span
																									class="required-field"
																									>&nbsp;*</span
																								></label
																							>
																							<input
																								#TDTagNameRef="ngModel"
																								required
																								type="text"
																								class="form-control form-control-sm"
																								placeholder="{{
																									'UI.k_tag'
																										| translate
																								}} {{
																									'UI.k_name'
																										| translate
																								}}"
																								name="usrTagName"
																								[appWhiteSpaceCheck]="
																									tag?.name
																								"
																								[(ngModel)]="tag.name"
																								id="txtTName"
																								minlength="3"
																								maxlength="40"
																								[class.error]="
																									!TDTagNameRef.valid &&
																									addTagForm.submitted
																								"
																							/>
																							<span
																								*ngIf="
																									addTagForm.submitted &&
																									TDTagNameRef.invalid
																								"
																								class="invalid-form"
																							>
																								<small
																									class="form-text text-danger xs-font"
																									*ngIf="
																										TDTagNameRef
																											.errors
																											.required
																									"
																									>{{
																										"UI.k_field_required"
																											| translate
																									}}</small
																								>
																								<small
																									class="form-text text-danger xs-font"
																									*ngIf="
																										TDTagNameRef
																											.errors
																											.minlength ||
																										TDTagNameRef
																											.errors
																											.maxlength
																									"
																								>
																									{{
																										"UI.k_tag_validation"
																											| translate
																									}}</small
																								>
																								<small
																									class="form-text text-danger xs-font"
																									*ngIf="
																										TDTagNameRef
																											.errors
																											?.whitespace
																									"
																									>{{
																										"UI.k_leading_trailing_space_validation"
																											| translate
																									}}</small
																								>
																							</span>
																						</div>
																					</div>
																					<div
																						class="col-xs-12 col-md-6 col-sm-6 col-lg-6"
																					>
																						<div class="form-group">
																							<label for="drpType">{{
																								"UI.k_type" | translate
																							}}</label>
																							<ng-select
																								[items]="tagTypeList"
																								[multiple]="false"
																								[closeOnSelect]="true"
																								[searchable]="false"
																								bindLabel="name"
																								bindValue="id"
																								placeholder="{{
																									'UI.k_select'
																										| translate
																								}} {{
																									'UI.k_type'
																										| translate
																								}}"
																								name="type"
																								id="drpType"
																								class=""
																								[(ngModel)]="tag.type"
																								disabled
																							>
																							</ng-select>
																						</div>
																					</div>
																				</div>
																				<div class="d-flex pull-right">
																					<div class="mb-1">
																						<button
																							(click)="
																								onCreateTag(addTagForm)
																							"
																							[disabled]="
																								is_tag_save_disabled
																							"
																							type="submit"
																							class="btn btn-primary"
																							rippleEffect
																							id="btnTagSave"
																							name="btnTagSave"
																							[attr.data-testid]="'btnTagSave'"
																						>
																							<span
																								class="align-middle d-sm-inline-block d-none"
																								>{{
																									"UI.k_create_tags"
																										| translate
																								}}
																							</span>
																						</button>
																					</div>
																				</div>
																			</form>
																		</div>
																	</ng-template>
																</div>

																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-label" for="selTags">{{
																		"UI.k_asset_tag" | translate
																	}}</label>
																	<!-- <span
																		*ngIf="
																			tagPermission.add
																		"
																		class="pull-right cursor-pointer"
																		(click)="
																			openModalAD(
																				TagComp, 'UI.k_add_asset_tag', 'Asset'
																			)
																		"
																		id="btnAddAssetTag"
																	>
																		<i
																			class="fa-light fa-circle-plus mb-0 mr-sm-25 mr-0 pull-right"
																		></i>
																	</span> -->
																	<ng-select
																		[(ngModel)]="userProfile.profile.asset_tags"
																		#TDATagsRef="ngModel"
																		name="selAssetTags"
																		id="selAssetTags"
																		[items]="assetTagList"
																		bindLabel="name"
																		bindValue="tag_id"
																		[closeOnSelect]="true"
																		[multiple]="true"
																		(remove)="
																			onRemoveTag(
																				userProfile.profile.asset_tags,
																				$event
																			)
																		"
																		class="form-control"
																		placeholder="{{
																			'UI.k_select_tags' | translate
																		}}"
																		[class.error]="
																			personalInfoForm.submitted &&
																			TDATagsRef.invalid
																		"
																		notFoundText="{{ 'UI.k_no_tags' | translate }}"
																		[addTag]="selectAddAssetTagMethod"
																		addTagText="{{ 'UI.k_add_tag' | translate }}"
																		[ngModelOptions]="{ standalone: true }"
																		hideSelected="true"
																	></ng-select>
																	<ng-template #tagCompAsset let-modal>
																		<div class="modal-header">
																			<h4 class="modal-title" id="myModalLabel1">
																				{{ "UI.k_add_asset_tag" | translate }}
																			</h4>
																			<button
																				type="button"
																				id="btnAssetCloseTag"
																				name="btnAssetCloseTag"
																				class="close"
																				(click)="modal.dismiss('Cross click')"
																				aria-label="Close"
																				[attr.data-testid]="'btnAssetCloseTag'"
																			>
																				<span aria-hidden="true">&times;</span>
																			</button>
																		</div>
																		<div
																			class="modal-body"
																			tabindex="0"
																			ngbAutofocus
																		>
																			<form #addTagForm="ngForm">
																				<div class="row mb-2">
																					<div
																						class="col-xs-12 col-md-6 col-sm-6 col-lg-6"
																					>
																						<div class="form-group">
																							<label for="txtTName"
																								>{{
																									"UI.k_tags"
																										| translate
																								}}
																								{{
																									"UI.k_name"
																										| translate
																								}}<span
																									class="required-field"
																									>&nbsp;*</span
																								></label
																							>
																							<input
																								#TDTagNameRef="ngModel"
																								required
																								type="text"
																								class="form-control form-control-sm"
																								placeholder="{{
																									'UI.k_tag'
																										| translate
																								}} {{
																									'UI.k_name'
																										| translate
																								}}"
																								name="usrTagName"
																								[appWhiteSpaceCheck]="
																									tag?.name
																								"
																								[(ngModel)]="tag.name"
																								id="txtTName"
																								minlength="3"
																								maxlength="40"
																								[class.error]="
																									!TDTagNameRef.valid &&
																									addTagForm.submitted
																								"
																							/>
																							<span
																								*ngIf="
																									addTagForm.submitted &&
																									TDTagNameRef.invalid
																								"
																								class="invalid-form"
																							>
																								<small
																									class="form-text text-danger xs-font"
																									*ngIf="
																										TDTagNameRef
																											.errors
																											.required
																									"
																									>{{
																										"UI.k_field_required"
																											| translate
																									}}</small
																								>
																								<small
																									class="form-text text-danger xs-font"
																									*ngIf="
																										TDTagNameRef
																											.errors
																											.minlength ||
																										TDTagNameRef
																											.errors
																											.maxlength
																									"
																								>
																									{{
																										"UI.k_tag_validation"
																											| translate
																									}}</small
																								>
																								<small
																									class="form-text text-danger xs-font"
																									*ngIf="
																										TDTagNameRef
																											.errors
																											?.whitespace
																									"
																									>{{
																										"UI.k_leading_trailing_space_validation"
																											| translate
																									}}</small
																								>
																							</span>
																						</div>
																					</div>
																					<div
																						class="col-xs-12 col-md-6 col-sm-6 col-lg-6"
																					>
																						<div class="form-group">
																							<label for="drpType">{{
																								"UI.k_type" | translate
																							}}</label>
																							<ng-select
																								[items]="tagTypeList"
																								[multiple]="false"
																								[closeOnSelect]="true"
																								[searchable]="false"
																								bindLabel="name"
																								bindValue="id"
																								placeholder="{{
																									'UI.k_select'
																										| translate
																								}} {{
																									'UI.k_type'
																										| translate
																								}}"
																								name="type"
																								id="drpType"
																								class=""
																								[(ngModel)]="tag.type"
																								disabled
																							>
																							</ng-select>
																						</div>
																					</div>
																				</div>
																				<div class="d-flex pull-right">
																					<div class="mb-1">
																						<button
																							(click)="
																								onCreateTag(addTagForm)
																							"
																							[disabled]="
																								is_tag_save_disabled
																							"
																							type="submit"
																							class="btn btn-primary"
																							rippleEffect
																							id="btnTagSave"
																							name="tagSave"
																							[attr.data-testid]="'btnSubmit'"
																						>
																							<span
																								class="align-middle d-sm-inline-block d-none"
																								>{{
																									"UI.k_submit"
																										| translate
																								}}
																							</span>
																						</button>
																					</div>
																				</div>
																			</form>
																		</div>
																	</ng-template>
																</div>
															</div>

															<div class="row">
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-label" for="selNtfyEmail">{{
																		"UI.k_notify_email" | translate
																	}}</label
																	><span
																		id="notifyEmailTooltip"
																		name="notifyEmailTooltip"
																		class="ml-50"
																		ngbTooltip="{{
																			'UI.k_notify_email_descr' | translate
																		}}"
																		placement="right"
																		tooltipClass="custom-class"
																		><i class="fa-light fa-info-circle"></i
																	></span>
																	<ng-select
																		[(ngModel)]="userProfile.profile.notify_email"
																		#TDNotifyEmailRef="ngModel"
																		name="selNtfyEmail"
																		id="selNtfyEmail"
																		[items]="[]"
																		class="form-control"
																		[addTag]="true"
																		[multiple]="true"
																		[appEmailCheck]="
																			userProfile.profile.notify_email
																		"
																		placeholder="{{
																			'UI.k_email_placeholder' | translate
																		}}"
																		[class.error]="
																			personalInfoForm.submitted &&
																			TDNotifyEmailRef.errors?.invalidemail
																		"
																		notFoundText="{{
																			'UI.k_no_notify_email' | translate
																		}}"
																		addTagText="{{ 'UI.k_add_item' | translate }}"
																		hideSelected="true"
																	></ng-select>
																	<span
																		*ngIf="personalInfoForm.submitted"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="
																				TDNotifyEmailRef.errors?.invalidemail
																			"
																			>{{
																				"UI.k_notify_email_invalid" | translate
																			}}</small
																		>
																		<small class="form-text text-danger"
																			*ngIf="TDNotifyEmailRef.errors?.max_email">{{"UI.k_email_max_validation"	| translate:{"maxLimit": TDNotifyEmailRef.errors?.limit} }}
																		</small>
																	</span>
																</div>
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-label" for="selNtfyPhn">{{
																		"UI.k_notify_phone" | translate
																	}}</label>
																	<span
																		id="notifyPhoneTooltip"
																		name="notifyPhoneTooltip"
																		class="ml-50"
																		ngbTooltip="{{
																			'UI.k_notify_phone_descr' | translate
																		}}"
																		placement="right"
																		tooltipClass="custom-class"
																		><i class="fa-light fa-info-circle"></i
																	></span>
																	<ng-select
																		[(ngModel)]="userProfile.profile.notify_phone"
																		#TDNotifyPhoneRef="ngModel"
																		name="selNtfyPhn"
																		id="selNtfyPhn"
																		[items]="[]"
																		[addTag]="true"
																		[multiple]="true"
																		class="form-control"
																		placeholder="xxxxxxxxxx"
																		[class.error]="
																			personalInfoForm.submitted &&
																			TDNotifyPhoneRef.invalid
																		"
																		[appPhoneCheck]="
																			userProfile.profile.notify_phone
																		"
																		notFoundText="{{
																			'UI.k_no_notify_phone' | translate
																		}}"
																		addTagText="{{ 'UI.k_add_item' | translate }}"
																		hideSelected="true"
																	></ng-select>
																	<span
																		*ngIf="
																			personalInfoForm.submitted &&
																			TDNotifyPhoneRef.invalid
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="
																				TDNotifyPhoneRef.errors?.invalidphone
																			"
																			>{{
																				"UI.k_pattern_validation" | translate
																			}}</small
																		>
																	</span>
																</div>
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-label" for="selRequesters">{{
																		"UI.k_site_engineer_requester" | translate
																	}}</label>
																	<ng-select
																		[items]="requesterList"
																		[multiple]="true"
																		[closeOnSelect]="true"
																		[searchable]="true"
																		bindLabel="full_name"
																		[searchFn]="customSearchFn"
																		bindValue=""
																		hideSelected="true"
																		name="selRequesters"
																		id="selRequesters"
																		placeholder="{{ 'UI.k_select' | translate }} {{
																			'UI.k_site_engineer_requester' | translate
																		}}"
																		class=""
																		[(ngModel)]="userProfile.profile.requester"
																	>
																		<ng-template
																			ng-option-tmp
																			let-item="item"
																			let-index="index"
																			let-search="searchTerm"
																		>
																			<span class="d-flex">
																				<div *ngIf="item?.profile_image">
																					<div class="avatar mr-50 ml-25">
																						<span class="avatar-content">
																							<img
																								class="rounded-circle"
																								src="{{ item.profile_image }}"
																								alt="User"
																								width="32"
																								height="32"
																							/>
																						</span>
																					</div>
																				</div>
																				<div *ngIf="!item?.profile_image">
																					<div
																						class="avatar mr-50 ml-25"
																						[ngStyle]="{
																							'background-color': item?.avatar_color
																								? item?.avatar_color
																								: '#f59518'
																						}"
																						style="
																							align-content: center;
																							width: 32px;
																							height: 32px;
																						"
																					>
																						<div class="avatar-content">
																							{{ item.full_name | initials | slice: 0:2 }}
																						</div>
																					</div>
																				</div>

																				<div class="cell-line-height">
																					<p
																						class="font-medium-1 font-weight-bold line-height-1 mb-0"
																					>
																						{{ item?.full_name | slice: 0:28 }}
																						{{ item?.full_name?.length > 28 ? ".." : "" }}
																					</p>
																					<span class="text-muted font-small-2">
																						{{ item?.email | slice: 0:28 }}
																						{{ item?.email.length > 28 ? ".." : "" }}</span
																					>
																				</div>
																			</span>
																		</ng-template>
																	</ng-select>
																</div>
															</div>

															<div class="row">
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-label" for="txtBizEnt">{{
																		"UI.k_business_entity" | translate
																	}}</label>
																	<input
																		[(ngModel)]="
																			userProfile.profile.business_entity
																		"
																		#TDBizEntityRef="ngModel"
																		type="text"
																		name="txtBizEnt"
																		id="txtBizEnt"
																		class="form-control"
																		pattern="^[^\s]+(\s+[^\s]+)*$"
																		[class.error]="
																			personalInfoForm.submitted &&
																			TDBizEntityRef.invalid
																		"
																		placeholder="{{
																			'UI.k_enter_biz_entity' | translate
																		}}"
																	/>
																	<span
																		*ngIf="
																			personalInfoForm.submitted &&
																			TDBizEntityRef.invalid &&
																			(TDBizEntityRef.dirty ||
																				TDBizEntityRef.touched)
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDBizEntityRef.errors.pattern"
																			>{{ "UI.k_space_validation" | translate }}
																		</small>
																	</span>
																</div>
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-label" for="txtBizSeg">{{
																		"UI.k_business_segment" | translate
																	}}</label>
																	<input
																		[(ngModel)]="
																			userProfile.profile.business_segment
																		"
																		#TDBizSegmentRef="ngModel"
																		type="text"
																		name="txtBizSeg"
																		id="txtBizSeg"
																		class="form-control"
																		pattern="^[^\s]+(\s+[^\s]+)*$"
																		[class.error]="
																			personalInfoForm.submitted &&
																			TDBizSegmentRef.invalid
																		"
																		placeholder="{{
																			'UI.k_enter_biz_segment' | translate
																		}}"
																	/>
																	<span
																		*ngIf="
																			personalInfoForm.submitted &&
																			TDBizSegmentRef.invalid &&
																			(TDBizSegmentRef.dirty ||
																				TDBizSegmentRef.touched)
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDBizSegmentRef.errors.pattern"
																			>{{ "UI.k_space_validation" | translate }}
																		</small>
																	</span>
																</div>
															</div>

															<div class="row">
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-label" for="txtPName">{{
																		"UI.k_partner_name" | translate
																	}}</label>
																	<input
																		[(ngModel)]="userProfile.profile.partner_name"
																		#TDPartnerNameRef="ngModel"
																		type="text"
																		name="txtPName"
																		id="txtPName"
																		class="form-control"
																		pattern="^[^\s]+(\s+[^\s]+)*$"
																		[class.error]="
																			personalInfoForm.submitted &&
																			TDPartnerNameRef.invalid
																		"
																		placeholder="{{
																			'UI.k_enter_partner_name' | translate
																		}} "
																	/>
																	<span
																		*ngIf="
																			personalInfoForm.submitted &&
																			TDPartnerNameRef.invalid &&
																			(TDPartnerNameRef.dirty ||
																				TDPartnerNameRef.touched)
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDPartnerNameRef.errors.pattern"
																			>{{ "UI.k_space_validation" | translate }}
																		</small>
																	</span>
																</div>
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-label" for="txtSub">{{
																		"UI.k_subsidiary" | translate
																	}}</label>
																	<input
																		[(ngModel)]="userProfile.profile.subsidiary"
																		#TDSubsidiaryRef="ngModel"
																		type="text"
																		name="txtSub"
																		id="txtSub"
																		class="form-control"
																		pattern="^[^\s]+(\s+[^\s]+)*$"
																		[class.error]="
																			personalInfoForm.submitted &&
																			TDSubsidiaryRef.invalid
																		"
																		placeholder="{{
																			'UI.k_enter_subsidiary' | translate
																		}}"
																	/>
																	<span
																		*ngIf="
																			personalInfoForm.submitted &&
																			TDSubsidiaryRef.invalid &&
																			(TDSubsidiaryRef.dirty ||
																				TDSubsidiaryRef.touched)
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDSubsidiaryRef.errors.pattern"
																			>{{ "UI.k_space_validation" | translate }}
																		</small>
																	</span>
																</div>
															</div>
														</div>
													</ng-scrollbar>
												</div>

												<div class="stepper-btn d-flex justify-content-between">
													<button
														(click)="horizontalWizardStepperPrevious()"
														class="btn btn-flat-primary btn-prev"
														rippleEffect
														id="btnPrsnPrev"
														name="btnPrsnPrev"
														[attr.data-testid]="'btnPrsnPrev'"
													>
														<i class="fa-light fa-arrow-left align-middle mr-50 mr-0"></i>

														<span class="align-middle d-sm-inline-block d-none">{{
															"UI.k_back" | translate
														}}</span>
													</button>
													<button
														(click)="horizontalWizardStepperNext(personalInfoForm)"
														class="btn btn-primary btn-next"
														rippleEffect
														id="btnPrsnNxt"
														name="btnPrsnNxt"
														[attr.data-testid]="'btnPrsnNxt'"
													>
														<span class="align-middle d-sm-inline-block d-none">{{
															"UI.k_next" | translate
														}}</span>

														<i class="fa-light fa-arrow-right align-middle ml-50 ml-0"></i>
													</button>
												</div>
												<!-- <div class="mt-5">
													<ngb-alert [type]="'primary'" [dismissible]="false">
														<h4 class="alert-heading">
															{{ "UI.k_primary" | translate }}
														</h4>
														<div class="alert-body">
															{{ "UI.k_user_alert_footer" | translate }}
														</div>
													</ngb-alert>
												</div> -->
											</form>
										</div>
										<div id="address" class="content">
											<form #addressForm="ngForm" class="stepper-inner">
												<!-- <div class="content-header">
													<h5 class="mb-0">Address</h5>
													<small>Enter Your Address.</small>
												</div> -->
												<div class="stepper-body">
													<ng-scrollbar class="custom-scrollbar section-scrollbar">
														<div class="stepper-main">
															<div class="row">
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<div class="d-flex justify-content-between align-items-center">
																		<label class="form-label" for="txtLoc">{{
																			"UI.k_base_location" | translate
																		}}</label>
																	</div>
																	<ng-select
																		[(ngModel)]="BaseLocation"
																		[items]="locationList"
																		[multiple]="false"
																		[closeOnSelect]="true"
																		[searchable]="true"
																		bindLabel="location_name"
																		name="sel_Location"
																		id="sel_Location"
																		placeholder="{{
																			'UI.k_sel_base_location'
																			| translate
																		}}"
																		notFoundText="{{
																			'UI.k_no_items_found'
																			| translate
																		}}"
																		[attr.data-testid]="'sel_Location'"
																		(change)="LocationMap($event, addressForm)"
																	>
																	</ng-select>
																</div>
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<div class="d-flex justify-content-between align-items-center">
																		<label class="form-label" for="txtLoc">{{
																			"UI.k_work_location" | translate
																		}}</label>
																	</div>
																	<ng-select
																		[(ngModel)]="selectedWorkLocation"
																		[items]="workLocationList"
																		[multiple]="false"
																		[closeOnSelect]="true"
																		[searchable]="true"
																		bindLabel="location_name"
																		name="sel_work_Location"
																		id="sel_work_Location"
																		placeholder="{{
																			'UI.k_select_work_location'
																			| translate
																		}}"
																		notFoundText="{{
																			'UI.k_no_items_found'
																			| translate
																		}}"
																		[attr.data-testid]="'sel_work_Location'"
																		(change)="WorkLocationMap($event, addressForm)"
																	>
																	</ng-select>
																</div>
															</div>
															<div *ngIf="home_address">
															<hr/>
															<h6 class="mb-1">Work From Home Address</h6>
															<div class="row">
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-label" for="txtLoc">{{
																		"UI.k_location_name" | translate
																	}}</label>
																	<input
																		[(ngModel)]="userProfile.profile.location"
																		#locationRef="ngModel"
																		type="text"
																		id="txtLoc"
																		name="txtLoc"
																		class="form-control"
																		pattern="^[^\s]+(\s+[^\s]+)*$"
																		[class.error]="
																			addressForm.submitted && locationRef.invalid
																		"
																		placeholder="{{
																			'UI.k_placeholder_org_location' | translate
																		}}"
																	/>
																	<span
																		*ngIf="
																			addressForm.submitted &&
																			locationRef.invalid &&
																			(locationRef.dirty || locationRef.touched)
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="locationRef.errors.pattern"
																			>{{ "UI.k_space_validation" | translate }}
																		</small>
																	</span>
																	<span
																		*ngIf="
																			addressForm.submitted && locationRef.invalid
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="locationRef.errors.required"
																			>{{
																				"UI.k_field_required" | translate
																			}}</small
																		>
																	</span>
																</div>
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-label" for="txtCtry">{{
																		"UI.k_country" | translate
																	}}</label>
																	<input
																		[(ngModel)]="userProfile.profile.country"
																		#countryRef="ngModel"
																		type="text"
																		id="txtCtry"
																		name="txtCtry"
																		class="form-control"
																		pattern="^[^\s]+(\s+[^\s]+)*$"
																		[class.error]="
																			addressForm.submitted && countryRef.invalid
																		"
																		placeholder="{{
																			'UI.k_placeholder_country' | translate
																		}}"
																	/>
																	<span
																		*ngIf="
																			addressForm.submitted &&
																			countryRef.invalid &&
																			(countryRef.dirty || countryRef.touched)
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="countryRef.errors.pattern"
																			>{{ "UI.k_space_validation" | translate }}
																		</small>
																	</span>
																</div>
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-label" for="txtCity">{{
																		"UI.k_city" | translate
																	}}</label>
																	<input
																		[(ngModel)]="userProfile.profile.city"
																		#cityRef="ngModel"
																		type="text"
																		id="txtCity"
																		name="txtCity"
																		class="form-control"
																		pattern="^[^\s]+(\s+[^\s]+)*$"
																		[class.error]="
																			addressForm.submitted && cityRef.invalid
																		"
																		placeholder="{{
																			'UI.k_placeholder_city' | translate
																		}}"
																	/>
																	<span
																		*ngIf="
																			addressForm.submitted &&
																			cityRef.invalid &&
																			(cityRef.dirty || cityRef.touched)
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="cityRef.errors.pattern"
																			>{{ "UI.k_space_validation" | translate }}
																		</small>
																	</span>
																</div>
															</div>
															<div class="row">
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-label" for="txtState">{{
																		"UI.k_state" | translate
																	}}</label>
																	<input
																		[(ngModel)]="userProfile.profile.state"
																		#stateRef="ngModel"
																		type="text"
																		id="txtState"
																		name="txtState"
																		class="form-control"
																		pattern="^[^\s]+(\s+[^\s]+)*$"
																		[class.error]="
																			addressForm.submitted && stateRef.invalid
																		"
																		placeholder="{{
																			'UI.k_placeholder_state' | translate
																		}}"
																	/>
																	<span
																		*ngIf="
																			addressForm.submitted &&
																			stateRef.invalid &&
																			(stateRef.dirty || stateRef.touched)
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="stateRef.errors.pattern"
																			>{{ "UI.k_space_validation" | translate }}
																		</small>
																	</span>
																</div>
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-label" for="txtPinCode">{{
																		"UI.k_postal_code" | translate
																	}}</label>
																	<input
																		type="text"
																		id="txtPinCode"
																		name="txtPinCode"
																		class="form-control"
																		placeholder="{{
																			'UI.k_placeholder_pincode' | translate
																		}}"
																		[(ngModel)]="userProfile.profile.pin"
																		pattern="^[^\s]+(\s+[^\s]+)*$"
																		#TDPostalRef="ngModel"
																		[class.error]="
																			addressForm.submitted && TDPostalRef.invalid
																		"
																	/>
																	<span
																		*ngIf="
																			addressForm.submitted &&
																			TDPostalRef.invalid &&
																			(TDPostalRef.dirty || TDPostalRef.touched)
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDPostalRef.errors.pattern"
																			>{{ "UI.k_space_validation" | translate }}
																		</small>
																	</span>
																</div>
																<!-- <div class="form-group col-md-6">
																	<label class="form-label" for="city1">City</label>
																	<input
																		type="text"
																		id="city1"
																		class="form-control"
																		placeholder="Birmingham"
																	/>
																</div> -->
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-label" for="txtLndmrk">{{
																		"UI.k_landmark" | translate
																	}}</label>
																	<input
																		[(ngModel)]="userProfile.profile.landmark"
																		#landmarkRef="ngModel"
																		type="text"
																		name="txtLndmrk"
																		id="txtLndmrk"
																		class="form-control"
																		pattern="^[^\s]+(\s+[^\s]+)*$"
																		[class.error]="
																			addressForm.submitted && landmarkRef.invalid
																		"
																		placeholder="{{
																			'UI.k_placeholder_landmark' | translate
																		}}"
																	/>
																	<span
																		*ngIf="
																			addressForm.submitted &&
																			landmarkRef.invalid &&
																			(landmarkRef.dirty || landmarkRef.touched)
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="landmarkRef.errors.pattern"
																			>{{ "UI.k_space_validation" | translate }}
																		</small>
																	</span>
																</div>
															</div>
															<div class="row">
																<div
																	class="form-group col-xs-12 col-md-8 col-xl-8 col-lg-8"
																>
																	<label class="form-label" for="txtUsrBldng"
																		>{{ "UI.k_flat" | translate }},
																		{{ "UI.k_house_no" | translate }},
																		{{ "UI.k_building" | translate }},
																		{{ "UI.k_company" | translate }}</label
																	>
																	<input
																		[(ngModel)]="userProfile.profile.building"
																		#buildingRef="ngModel"
																		type="text"
																		id="txtUsrBldng"
																		name="txtUsrBldng"
																		class="form-control"
																		pattern="^[^\s]+(\s+[^\s]+)*$"
																		[class.error]="
																			addressForm.submitted && buildingRef.invalid
																		"
																		placeholder="{{
																			'UI.k_placeholder_building' | translate
																		}}"
																	/>
																	<span
																		*ngIf="
																			addressForm.submitted &&
																			buildingRef.invalid &&
																			(buildingRef.dirty || buildingRef.touched)
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="buildingRef.errors.pattern"
																			>{{ "UI.k_space_validation" | translate }}
																		</small>
																	</span>
																</div>
															</div>

															<div class="row">
																<div
																	class="form-group col-xs-12 col-md-8 col-xl-8 col-lg-8"
																>
																	<label class="form-label" for="txtUsrArea"
																		>{{ "UI.k_area" | translate }},
																		{{ "UI.k_colony" | translate }},
																		{{ "UI.k_street" | translate }},
																		{{ "UI.k_sector" | translate }},
																		{{ "UI.k_village" | translate }}</label
																	>
																	<input
																		[(ngModel)]="userProfile.profile.area"
																		#areaRef="ngModel"
																		type="text"
																		id="txtUsrArea"
																		name="txtUsrArea"
																		class="form-control"
																		pattern="^[^\s]+(\s+[^\s]+)*$"
																		[class.error]="
																			addressForm.submitted && areaRef.invalid
																		"
																		placeholder="{{
																			'UI.k_placeholder_area' | translate
																		}}"
																	/>
																	<span
																		*ngIf="
																			addressForm.submitted &&
																			areaRef.invalid &&
																			(areaRef.dirty || areaRef.touched)
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="areaRef.errors.pattern"
																			>{{ "UI.k_space_validation" | translate }}
																		</small>
																	</span>
																</div>
															</div>

															<div class="row">
																<div
																	class="form-group col-xs-12 col-md-8 col-xl-8 col-lg-8"
																>
																	<label class="form-label" for="txtUsrAddr">{{
																		"UI.k_add_info" | translate
																	}}</label>
																	<input
																		[(ngModel)]="userProfile.profile.sub_area"
																		#descRef="ngModel"
																		type="text"
																		id="txtUsrAddr"
																		name="txtUsrAddr"
																		class="form-control"
																		pattern="^[^\s]+(\s+[^\s]+)*$"
																		[class.error]="
																			addressForm.submitted && descRef.invalid
																		"
																		placeholder="{{
																			'UI.k_address_description' | translate
																		}}"
																	/>
																	<span
																		*ngIf="
																			addressForm.submitted &&
																			descRef.invalid &&
																			(descRef.dirty || descRef.touched)
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="descRef.errors.pattern"
																			>{{ "UI.k_space_validation" | translate }}
																		</small>
																	</span>
																</div>
															</div>
														</div>
														</div>
													</ng-scrollbar>
												</div>
												<div class="d-flex justify-content-between stepper-btn">
													<button
														(click)="horizontalWizardStepperPrevious()"
														class="btn btn-flat-primary btn-prev"
														rippleEffect
														id="btnLocPrev"
														name="btnLocPrev"
														[attr.data-testid]="'btnLocPrev'"
													>
														<i class="fa-light fa-arrow-left align-middle mr-50 mr-0"></i>

														<span class="align-middle d-sm-inline-block d-none">{{
															"UI.k_back" | translate
														}}</span>
													</button>
													<button
														class="btn btn-primary btn-submit"
														(click)="onSubmit(addressForm)"
														[disabled]="is_save_disabled"
														rippleEffect
														id="btnLocSub"
														name="btnLocSub"
														[attr.data-testid]="'btnLocSub'"
													>
														<i class="fa-light fa-floppy-disk align-middle mr-50 ml-0"></i>
														<span class="align-middle d-sm-inline-block d-none"
															>{{ submitButton | translate }}
														</span>

														<!-- <i
															class="fa-light fa-arrow-right align-middle ml-50 ml-0"
														></i> -->
													</button>
												</div>
											</form>
										</div>
										<!-- </ng-scrollbar> -->
										<!-- </div> -->
										<!-- </form> -->
										<!-- </div> -->
									</form>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>
</div>
