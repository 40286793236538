<div
	class="upload-file-sec d-flex justify-content-center"
	[ngStyle]="{
		height: imgDetailsObject?.aspectRatio == '4 / 1' ? '100px' : '200px'
	}"
>
	<div class="custom-img-uploader" [ngClass]="{ hasCropedImg: this.hasCropedImg === true }">
		<div>
			<a href="javascript:void(0)" (click)="file.click()">
				<!--py-4 -->
				<div
					ng2FileDrop
					ng2FileSelect
					(change)="onChange($event);"
					[ngClass]="{
						'file-drop-over': hasBaseDropZoneOver
					}"
					(fileOver)="fileOverBase($event)"
					(onFileDrop)="onUpload($event)"
					[uploader]="uploader"
					class="d-flex text-center font-small-4 file-drop-zone px-2"
					id="drpZoneImg"
					name="drpZoneImg"
					[attr.data-testid]="'drpZoneImg'"
				>
					<div class="align-items-center">
						<div class="mr-1">
							<span class="mt-50"><i class="fa-light fa-upload font-small-3"></i></span>
						</div>
						<div class="">
							<span class="font-small-3">
								{{ "UI.k_drag_drop_file" | translate }}
								{{ "UI.k_or" | translate | lowercase }}
								{{ "UI.k_browse_to_upload" | translate }}
							</span>
							<span *ngIf="displayResoluion">(
								<span *ngIf="ALLOWED_TYPES" class="text-primary font-small-2">
									{{ "UI.k_formats" | translate }} : {{ allowedfileType }}</span
								>
								<span *ngIf="MAX_IMAGE_SIZE" class="text-primary font-small-2">
									| {{ "UI.k_max_size" | translate }} : {{ fileSize }}
								</span>
								<span *ngIf="imgDetailsObject?.aspectRatio" class="text-primary font-small-2">
									| {{ "UI.k_aspect_ratio" | translate }} : {{ imgDetailsObject?.aspectRatio }}</span
								> 
								<span *ngIf="maxRowLimit" class="text-primary font-small-2">
									| {{ "UI.k_row_limit" | translate }} : {{ maxRowLimit }}</span
								> )
							</span>
						</div>
						<input
							type="file"
                            #file
							[multiple]="multipleFiles"
							(change)="fileChangeEvent($event)"
							style="display: none"
							id="fileTest"
							[attr.data-testid]="'fileTest'"
							accept="{{ this.ALLOWED_TYPES }}"
							(onFileSelected)="getSelectedFiles($event)"
						/>
					</div>
				</div>
			</a>
		</div>
		<div class="invalid-feedback d-block" *ngIf="error">
			{{ "UI.k_only_one_file" | translate }}
		</div>
		<div class="uploaded-image" [ngClass]="{ hasImg: this.setImage !== null }">
			<img src="{{ this.setImage }}"/>
			<div class="remove-btn" (click)="removeImage()">
				<!-- <i data-feather="trash"></i> -->
				<i class="fa-light fa-trash-can"></i>
			</div>
		</div>
	</div>
	<!--Show Queue section start-->
	<div class="overflow-auto upload-file-list" *ngIf="showQueue">
		<div class="d-flex justify-content-between">
			<h3>{{ "UI.k_show_queue" | translate }}</h3>
		</div>
		<table class="table mb-2">
			<thead>
			<tr>
				<th width="50%">{{ "UI.k_name" | translate }}</th>
				<th>{{ "UI.k_size" | translate }}</th>
				<th>{{ "UI.k_actions" | translate }}</th>
			</tr>
			</thead>
			<tbody *ngIf="allFileList">
			<tr *ngFor="let item of allFileList">
				<td>{{ item?.name }}</td>
				<td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.size / 1000 }} MB</td>
				<td nowrap>
					<button
						type="button"
						class="btn btn-raised btn-outline-success btn-sm mr-50"
						rippleEffect
						[attr.data-testid]="'uploadFile'"
						(click)="uploadFile(item)"
					>
						<!-- <span data-feather="upload"></span> -->
						<span><i class="fa-light fa-upload"></i></span>
					</button>
					<button
						type="button"
						class="btn btn-raised btn-outline-danger btn-sm cursor-pointer"
						rippleEffect
						[attr.data-testid]="'removeFiles'"
						(click)="removeFiles(item)"
					>
						<!-- <i data-feather="trash"></i> -->
						<i class="fa-light fa-trash"></i>
					</button>
				</td>
			</tr>
			</tbody>
		</table>
		<div id="">
			<button
				type="button"
				class="btn mr-1 btn-primary mb-1 mb-sm-0"
				rippleEffect
				(click)="uploadAllFiles()"
				id="btnUploadAll"
				name="btnUploadAll"
				[attr.data-testid]="'btnUploadAll'"
			>
				<!-- <span data-feather="upload" class="mr-25"></span> -->
				<span><i class="fa-light fa-upload mr-25"></i></span>
				{{ "UI.k_upload_all" | translate }}
			</button>

			<button
				type="button"
				class="btn btn-outline-danger mb-1 mb-sm-0"
				rippleEffect
				(click)="removeAllFiles()"
				id="btnRemoveAll"
				name="btnRemoveAll"
				[attr.data-testid]="'btnRemoveAll'"
			>
				<!-- <span data-feather="trash" class="mr-25"></span>  -->
				<span><i class="fa-light fa-trash mr-25"></i></span>
				{{ "UI.k_remove_all" | translate }}
			</button>
		</div>
	</div>
	<!--Show Queue section ends-->
</div>
