import { SmsService } from "./base/sms-config/services/sms.service";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// layouts & notfound
import { SharedModule } from "./shared.module";
import { environment } from "../environments/environment";
import { ServiceModule } from "./base/services/service.module";
import { OpenLayersComponent } from "./common/dns/components/open-layers/open-layers.component";

//management portal routes will go here
const management_routes: Routes = [
	{
		path: "admin",
		loadChildren: () => import("./../app/admin/admin.module").then((m) => m.AdminModule),
	},
	{
		path: "auth",
		loadChildren: () => import("./base/auth/auth.module").then((m) => m.AuthModule),
	},
	{
		path: "error",
		loadChildren: () => import("./base/miscellaneous/miscellaneous.module").then((m) => m.MiscellaneousModule),
	},
	{
		path: "pages",
		loadChildren: () => import("./common/pages/pages.module").then((m) => m.PagesModule),
	},
	{
		path: "redirect",
		loadChildren: () => import("./base/redirect/redirect.module").then((m) => m.RedirectModule),
	},
	{
		path: "support",
		loadChildren: () => import("./support-portal/support-portal.module").then((m) => m.SupportPortalModule),
	},
	{
		path: "invite",
		loadChildren: () => import("./base/external/external.module").then((m) => m.ExternalModule),
	},
	// {
	// 	path: "",
	// 	redirectTo: "auth/register",
	// 	pathMatch: "full",
	// },
	// {
	// 	path: "**",
	// 	redirectTo: "auth/register",
	// },
];

//instance portal routes will go here
const instance_routes: Routes = [
	{
		path: "",
		redirectTo: "auth/login",
		pathMatch: "full",
	},
	{
		path: "authsso",
		loadChildren: () => import("./base/authsso/authsso.module").then((m) => m.AuthssoModule),
	},

	{
		path: "ssologin",
		loadChildren: () => import("./base/sso/sso.module").then((m) => m.SsoModule),
	},
	{
		path: "ssologout",
		loadChildren: () => import("./base/ssologout/ssologout.module").then((m) => m.SsologoutModule),
	},
	{
		path: "auth",
		loadChildren: () => import("./base/auth/auth.module").then((m) => m.AuthModule),
	},
	{
		path: "error",
		loadChildren: () => import("./base/miscellaneous/miscellaneous.module").then((m) => m.MiscellaneousModule),
	},
	{
		path: "users",
		loadChildren: () => import("./base/users/users.module").then((m) => m.UsersModule),
	},
	{
		path: "service-catalogue",
		loadChildren: () =>
			import("./base/service-catalogue/service-catalogue.module").then((m) => m.ServiceCatalogueModule),
	},
	{
		path: "business-catalogue",
		loadChildren: () =>
			import("./base/businesscatalogue/businesscatalogue.module").then((m) => m.BusinesscatalogueModule),
	},
	{
		path: "bots-configuration",
		loadChildren: () =>
			import("./base/bots-configuration/bots-configuration.module").then((m) => m.BotsConfigurationModule),
	},

	{
		path: "roles",
		loadChildren: () => import("./base/roles/roles.module").then((m) => m.RolesModule),
	},
	{
		path: "get-started",
		loadChildren: () => import("./base/get-started/get-started.module").then((m) => m.GetStartedModule),
	},
	{
		path: "configuration-parameters",
		loadChildren: () =>
			import("./nccm/global-parameters/global-parameters.module").then((m) => m.GlobalParametersModule),
	},
	{
		path: "organization",
		loadChildren: () => import("./base/organization/organization.module").then((m) => m.OrganizationModule),
	},
	{
		path: "passwordpolicy",
		loadChildren: () => import("./base/policy/policy.module").then((m) => m.PolicyModule),
	},
	{
		path: "requester",
		loadChildren: () => import("./base/requester/requester.module").then((m) => m.RequesterModule),
	},
	{
		path: "member",
		loadChildren: () => import("./base/external/external.module").then((m) => m.ExternalModule),
	},
	{
		path: "invite",
		loadChildren: () => import("./base/external/external.module").then((m) => m.ExternalModule),
	},
	{
		path: "discovery",
		loadChildren: () => import("./ims/discovery/discovery.module").then((m) => m.DiscoveryModule),
	},
	{
		path: "login_profile",
		loadChildren: () => import("./ims/device_credentials/loginprofile.module").then((m) => m.LoginProfileModule),
	},
	{
		path: "ems",
		loadChildren: () => import("./base/ems/ems.module").then((m) => m.EmsModule),
	},
	{
		path: "services",
		loadChildren: () => import("./base/services/service.module").then((m) => m.ServiceModule),
	},
	{
		path: "system",
		loadChildren: () => import("./base/system-menu/system-menu.module").then((m) => m.SystemMenuModule),
	},
	{
		path: "pages",
		loadChildren: () => import("./common/pages/pages.module").then((m) => m.PagesModule),
	},
	{
		path: "tags",
		loadChildren: () => import("./base/tags/tags.module").then((m) => m.TagsModule),
	},
	{
		path: "template",
		loadChildren: () => import("./base/template/template.module").then((m) => m.TemplateModule),
	},
	{
		path: "teams",
		loadChildren: () => import("./base/teams/teams.module").then((m) => m.TeamsModule),
	},
	{
		path: "agent-token",
		loadChildren: () => import("./base/agent-token/agent-token.module").then((m) => m.AgentTokenModule),
	},
	{
		path: "agent/inventory",
		loadChildren: () => import("./base/agent/agent.module").then((m) => m.AgentModule),
	},
	{
		path: "agent/datacollector",
		loadChildren: () => import("./base/agent/agent.module").then((m) => m.AgentModule),
	},
	{
		path: "apiregister",
		loadChildren: () =>
			import("./base/api-registration/api-registration.module").then((m) => m.ApiRegistrationModule),
	},
	{
		path: "business_hours",
		loadChildren: () => import("./base/business-hour/business-hour.module").then((m) => m.BusinessProfileModule),
	},
	{
		path: "configuration",
		loadChildren: () =>
			import("./base/infraon-configuration/infraon-configuration.module").then(
				(m) => m.InfraonConfigurationModule
			),
	},
	{
		path: "department",
		loadChildren: () => import("./base/department/department.module").then((m) => m.DepartmentModule),
	},
	{
		path: "workflow",
		loadChildren: () => import("./base/workflow/workflow.module").then((m) => m.WorkflowModule),
	},
	{
		path: "dynamic_form",
		loadChildren: () => import("./base/dynamic-form/dynamic-form.module").then((m) => m.DynamicFormModule),
	},
	{
		path: "audit",
		loadChildren: () => import("./base/audit/audit.module").then((m) => m.AuditModule),
	},
	{
		path: "imap-config",
		loadChildren: () =>
			import("./base/imap-configuration/imap-configuration.module").then((m) => m.ImapConfigurationModule),
	},
	{
		path: "job-progress",
		loadChildren: () => import("./base/job-progress/job-progress.module").then((m) => m.JobProgressModule),
	},
	{
		path: "leaves",
		loadChildren: () => import("./base/leaves/leaves.module").then((m) => m.LeavesModule),
	},
	{
		path: "my-leaves",
		loadChildren: () => import("./base/myleaves/myleaves.module").then((m) => m.MyleavesModule),
	},
	{
		path: "account-settings",
		loadChildren: () => import("./base/system-menu/system-menu.module").then((m) => m.SystemMenuModule),
	},
	{
		path: "incident_view",
		loadChildren: () =>
			import("./servicedesk/incident-view/incident-view.module").then((m) => m.IncidentViewModule),
	},
	{
		path: "problem_management",
		loadChildren: () => import("./servicedesk/problem/problem.module").then((m) => m.ProblemModule),
	},
	{
		path: "request_list",
		loadChildren: () => import("./servicedesk/request/request.module").then((m) => m.RequestModule),
	},
	{
		path: "threshold",
		loadChildren: () => import("./base/threshold/threshold.module").then((m) => m.ThresholdModule),
	},
	{
		path: "messenger-audit",
		loadChildren: () => import("./base/messenger-audit/messenger-audit.module").then((m) => m.MessengerAuditModule),
	},
	{
		path: "kb",
		loadChildren: () => import("./base/knowledge-base/knowledge-base.module").then((m) => m.KnowledgeBaseModule),
	},
	{
		path: "smsConfig",
		loadChildren: () => import("./base/sms-config/sms-config.module").then((m) => m.SmsConfigModule),
	},
	{
		path: "it-asset",
		loadChildren: () => import("./base/asset/asset.module").then((m) => m.AssetModule),
	},
	{
		path: "fixed-asset",
		loadChildren: () => import("./base/asset/asset.module").then((m) => m.AssetModule),
	},
	{
		path: "cmdb-view",
		loadChildren: () => import("./base/asset/asset.module").then((m) => m.AssetModule),
	},
	{
		path: "software-asset",
		loadChildren: () => import("./base/asset/asset.module").then((m) => m.AssetModule),
	},
	{
		path: "consumable-asset",
		loadChildren: () =>
			import("./base/asset-consumable/asset-consumable.module").then((m) => m.AssetConsumableModule),
	},
	{
		path: "blacklist_whitelist",
		loadChildren: () =>
			import("./base/blacklist-whitelist/blacklist-whitelist.module").then((m) => m.BlacklistWhitelistModule),
	},
	{
		path: "events",
		loadChildren: () => import("./base/events/events.module").then((m) => m.EventsModule),
	},
	{
		path: "report",
		loadChildren: () => import("./base/reports/reports.module").then((m) => m.ReportsModule),
	},
	{
		path: "img-feature",
		loadChildren: () =>
			import("./../app/common/dns/components/img-upload-feature/img-upload-feature.module").then(
				(m) => m.ImgUploadFeatureModule
			),
	},
	{
		path: "profile-avatar",
		loadChildren: () =>
			import("./../app/common/dns/components/profile-avatar/profile-avatar.module").then(
				(m) => m.ProfileAvatarModule
			),
	},
	{
		path: "dashboard",
		loadChildren: () => import("./base/dashboard/dashboard.module").then((m) => m.DashboardModule),
	},
	{
		path: "support-email",
		loadChildren: () => import("./base/support-email/support-email.module").then((m) => m.SupportEmailModule),
	},
	{
		path: "brand-styling",
		loadChildren: () => import("./base/brand-styling/brand-styling.module").then((m) => m.BrandStylingModule),
	},
	{
		path: "infraon-url",
		loadChildren: () => import("./base/infraon-url/infraon-url.module").then((m) => m.InfraonUrlModule),
	},
	{
		path: "signup",
		loadChildren: () => import("./base/account-signup/account-signup.module").then((m) => m.AccountSignupModule),
	},
	{
		path: "email-template",
		loadChildren: () => import("./base/email-template/email-template.module").then((m) => m.EmailTemplateModule),
	},
	{
		path: "asset-pso",
		loadChildren: () => import("./base/asset-pso/asset-pso.module").then((m) => m.AssetPsoModule),
	},
	{
		path: "sla/profile",
		loadChildren: () => import("./base/sla/sla.module").then((m) => m.SlaModule),
	},
	{
		path: "sla/metric",
		loadChildren: () => import("./base/metric/metric.module").then((m) => m.MetricModule),
	},
	{
		path: "trigger",
		loadChildren: () =>
			import("./base/trigger-configuration/trigger-configuration.module").then(
				(m) => m.TriggerConfigurationModule
			),
	},
	{
		path: "tree-crud-view",
		loadChildren: () => import("./base/tree-crud-view/tree-crud-view.module").then((m) => m.TreeCrudViewModule),
	},
	{
		path: "feature",
		loadChildren: () =>
			import("./base/select-picker-feature/select-picker-feature.module").then(
				(m) => m.SelectPickerFeatureModule
			),
	},
	{
		path: "csv-feature",
		loadChildren: () =>
			import("./../app/common/dns/components/csv-uploader/csv-upload-feature.module").then(
				(m) => m.CsvUploadFeatureModule
			),
	},
	{
		path: "self-service",
		loadChildren: () =>
			import("./../app/self-service-portal/self-service-portal.module").then((m) => m.SelfServicePortalModule),
	},
	{
		path: "msp",
		loadChildren: () => import("./msp-portal/msp-portal.module").then((m) => m.MspPortalModule),
	},
	{
		path: "escalation",
		loadChildren: () => import("./base/escalation/escalation.module").then((m) => m.EscalationModule),
	},
	{
		path: "business_rule",
		loadChildren: () => import("./base/business_rule/business_rule.module").then((m) => m.BusinessRuleModule),
	},
	{
		path: "redirect",
		loadChildren: () => import("./base/redirect/redirect.module").then((m) => m.RedirectModule),
	},
	{
		path: "notification",
		loadChildren: () => import("./base/notification/notification.module").then((m) => m.NotificationModule),
	},
	{
		path: "software-inventory",
		loadChildren: () => import("./base/asset-software/asset-software.module").then((m) => m.AssetSoftwareModule),
	},
	{
		path: "geomap",
		loadChildren: () => import("./base/geomap/geomap.module").then((m) => m.GeomapModule),
	},
	{
		path: "change",
		loadChildren: () =>
			import("./servicedesk/change-management/change-management.module").then((m) => m.ChangeManagementModule),
	},
	{
		path: "networkdiagram",
		loadChildren: () => import("./base/network-diagram/network-diagram.module").then((m) => m.NetworkDiagramModule),
	},
	{
		path: "download-job",
		loadChildren: () => import("./nccm/download-job/download-job.module").then((m) => m.DownloadJobModule),
	},
	{
		path: "org-setting",
		loadChildren: () => import("./base/org-setting/org-setting.module").then((m) => m.OrgSettingModule),
	},
	{
		path: "topology",
		loadChildren: () => import("./base/topology/topology.module").then((m) => m.TopologyModule),
	},
	{
		path: "rel",
		loadChildren: () => import("./servicedesk/release-manage/release.module").then((m) => m.ReleaseManageModule),
	},
	{
		path: "correlation-rules",
		loadChildren: () =>
			import("./base/correlation-rules-configuration/correlation-rules-configuration.module").then(
				(m) => m.CorrelationRulesConfigurationModule
			),
	},
	{
		path: "setting",
		loadChildren: () => import("./base/setting/setting.module").then((m) => m.SettingModule),
	},
	{
		path: "baseline-scheduler",
		loadChildren: () =>
			import("./nccm/base-scheduler/baseline-scheduler.module").then((m) => m.BaselineSchedulerModule),
	},
	{
		path: "marketplace",
		loadChildren: () => import("./../app/marketplace/marketplace.module").then((m) => m.MarketplaceModule),
	},
	{
		path: "workspace",
		loadChildren: () => import("./base/work-space/work-space.module").then((m) => m.WorkSpaceModule),
	},
	{
		path: "network-configuration",
		loadChildren: () => import("./nccm/network-configuration/network.module").then((m) => m.NetworkModule),
	},
	{
		path: "vendor",
		loadChildren: () => import("./base/vendor/vendor.module").then((m) => m.VendorModule),
	},
	{
		path: "external",
		loadChildren: () => import("./base/external/external.module").then((m) => m.ExternalModule),
	},
	{
		path: "license",
		loadChildren: () => import("./base/license/license.module").then((m) => m.LicenseModule),
	},
	{
		path: "chat-playground",
		loadChildren: () => import("./base/chat-workspace/chat-workspace.module").then((m) => m.chatWorkspaceModule),
	},
	{
		path: "diagnosis-tools",
		loadChildren: () => import("./ims/diagnosis_tools/tool.module").then((m) => m.ToolModule),
	},
	{
		path: "shift",
		loadChildren: () =>
			import("./base/shift-management/shift-management.module").then((m) => m.ShiftManagementModule),
	},
	{
		path: "imacd",
		loadChildren: () =>
			import("./base/imacd/imacd.module").then((m) => m.ImacdModule),
	},
	{
		path: "customer-feedback",
		loadChildren: () =>
			import("./base/customer-feedback/customer-feedback.module").then((m) => m.CustomerFeedbackModule),
	},
	{
		path: "ci-rule-config",
		loadChildren: () =>
			import("./base/ci-relation-rules/ci-relation-rules.module").then((m) => m.CiRelationRulesModule),
	},
	{
		path: "contract-management",
		loadChildren: () =>
			import("./base/contract-management/contract-management.module").then((m) => m.ContractManagementModule),
	},
	{
		path: "purchase-order",
		loadChildren: () => import("./base/purchase-order/purchase-order.module").then((m) => m.PurchaseOrderModule),
	},
	{
        path: "client-registration",
        loadChildren: () => 
			import("./base/customer-registration/customer-registration.module").then((m) => m.CustomerRegistrationModule),
    },
	{
		path: "ssp-configuration",
		loadChildren: () => import("./base/ssp-configuration/ssp-configuration.module").then((m) => m.SspConfigurationModule),
	},
	{
		path: "403",
		redirectTo: "/error/403",
	},
	{
		path: "**",
		redirectTo: "/error/404",
	},
	{
		path: "401",
		redirectTo: "/",
	},
];

//management portal routes will go here
const service_portal_routes: Routes = [
	{
		path: "",
		redirectTo: "auth/login",
		pathMatch: "full",
	},
	{
		path: "auth",
		loadChildren: () => import("./base/auth/auth.module").then((m) => m.AuthModule),
	},
	{
		path: "error",
		loadChildren: () => import("./base/miscellaneous/miscellaneous.module").then((m) => m.MiscellaneousModule),
	},
	{
		path: "self-service",
		loadChildren: () =>
			import("./../app/self-service-portal/self-service-portal.module").then((m) => m.SelfServicePortalModule),
	},
	{
		path: "403",
		redirectTo: "/error/403",
	},
	{
		path: "**",
		redirectTo: "/error/404",
	},
	{
		path: "401",
		redirectTo: "/",
	},
];

// const routeMap = {
// 	"management_routes": management_routes,
// 	"instance_routes": instance_routes,
// 	"service_portal_routes": service_portal_routes,
// }

// const route_key = environment["route_key"] || "instance_routes";
@NgModule({
	imports: [
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule.forRoot(environment?.management ? management_routes : instance_routes, {
			useHash: false,
			scrollPositionRestoration: "enabled", // Add options right here
			relativeLinkResolution: "legacy",
		}),
	],
	providers: [],
	exports: [RouterModule],
})
export class AppRoutingModule {}
