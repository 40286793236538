import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { TranslateService } from "@ngx-translate/core";
import { IncidentViewService } from "app/servicedesk/incident-view/services/incident-view.service";
import { SelectionType } from '@swimlane/ngx-datatable';
import { ToastrService } from "ngx-toastr";
import { InfinityModuleIDs } from "app/common/dns/types/module_type";
import { catchError, map, takeUntil } from "rxjs/operators";
import { InfinityModules } from "app/common/dns/types/modules";


@Component({
  selector: 'app-associate-relation',
  templateUrl: './associate-relation.component.html',
  styleUrls: ['./associate-relation.component.scss']
})
export class AssociateRelationComponent implements OnInit {
  @Input() permissions: any = {}
	@Input() options: any = {}
  @Input() parent_obj: any = {}
  @Input() relations: any = [];
  @Input() mapping: any = {};
  @Input() module_id;
  @Input() relationModule;
  @Input() module;
  @Output("onHideAssociateRelations") onHideAssociateRelations = new EventEmitter<any>();
  @Output("getRelations") getRelations = new EventEmitter<any>();
  selected_relation_mapping :any= {}
  child_module_id: any;
  columns: Array<any>;
  pageNumber: any = 0;
	sortKey: any = "display_id";
	reverse: any = '-';
	limit: any = 10;
  public model = 'slider'
  child_objs: any  = [];
	filterString: any = "";
  relationType: any = 1;
  public SelectionType = SelectionType;
  selectionCheckboxType: any; 
  dataLoaded: boolean = false;
  public selectedData: any = [];
  public associated_ids: any = [];
  public selected_relations : any = [];
  public selected_reln_ids: any = [];
  disableSubmit: boolean = true;
  child_objs_count: any;
  module_label: any;
  title: any;
  public filter = [];
  public filterData = false;
  public savedData = [];
  searchOptions: any;
  childModuleOptions : any;
  showData: boolean = false;
  search_db_cols = [
		{ name: "Ticket", db_col: "display_id", cond: "contains" },
		{ name: "Subject", db_col: "basic_info__summary", cond: "contains" },
		{ name: "Status", db_col: "basic_info__status__name", cond: "contains" },
		{ name: "State", db_col: "basic_info__state__name", cond: "contains" },
		{ name: "Priority", db_col: "basic_info__priority__name", cond: "contains" },
		{ name: "Requester Name", db_col: "requester__full_name", cond: "contains" },
		{ name: "Team", db_col: "current_assignment_info__group_name", cond: "contains" },
		{ name: "Assignee", db_col: "current_assignment_info__assignee_profile__full_name", cond: "contains" },
		{ name: "Severity", db_col: "basic_info__severity__name", cond: "contains" },
		{ name: "Impact Service", db_col: "basic_info__impact_service_tree_path", cond: "contains" },
	];
  process_key_mapping = {
    [InfinityModules.INCIDENT]: 'incident_id',
		[InfinityModules.PROBLEM]: 'problem_id',
		[InfinityModules.CHANGE]: 'change_id',
		[InfinityModules.REQUEST_PROCESS]: 'request_id',
		[InfinityModules.RELEASE]: 'release_id'
  }
  process_key;
  child_process_key;
  constructor(
    private _toastr: ToastrService,
    private _service: IncidentViewService,
    private _translateService: TranslateService,
		private _coreSidebarService: CoreSidebarService,
	) { }

  ngOnInit(): void {
    if (this.relationModule == InfinityModules?.INCIDENT){
      this.child_module_id = InfinityModuleIDs.INCIDENT
      this.module_label = 'UI.k_incident';
      this.title = "UI.k_associate_incident";
    }else if(this.relationModule == InfinityModules?.PROBLEM){
      this.child_module_id = InfinityModuleIDs.PROBLEM
      this.module_label = 'UI.k_problem';
      this.title = "UI.k_associate_problem";
    }
    else if(this.relationModule == InfinityModules?.CHANGE){
      this.child_module_id = InfinityModuleIDs.CHANGE
      this.module_label = 'UI.k_change';
      this.title = "UI.k_associate_change";
    }
    else if(this.relationModule == InfinityModules?.REQUEST_PROCESS){
      this.child_module_id = InfinityModuleIDs.REQUEST_PROCESS
      this.module_label = 'UI.k_request';
      this.title = "UI.k_associate_request";
    }
    else if(this.relationModule == InfinityModules?.RELEASE){
      this.child_module_id = InfinityModuleIDs.RELEASE
      this.module_label = 'UI.k_release';
      this.title = "UI.k_associate_release";
    }
    this.process_key = this.process_key_mapping?.[this.module]
    this.child_process_key = this.process_key_mapping?.[this.relationModule]
    this.getFilterOptions();
    this.getOptions();
    this.selectionCheckboxType = SelectionType.checkbox;
    this.relations = this.relations?.filter((relation_data) => 
    relation_data?.relation_type == 1 && (relation_data?.parent_module_id == this.child_module_id || relation_data?.child_module_id == this.child_module_id)
    )
    if (this.child_module_id == this.module_id){
      this.relations = this.relations?.filter((relation_data) => 
    relation_data?.parent_module_id == this.child_module_id && relation_data?.child_module_id == this.child_module_id
    )
    }
    this.relations?.forEach((relation_data) => {  
      if (relation_data?.parent_module_id == this.module_id && relation_data?.child_module_id == this.child_module_id){
        this.associated_ids.push(relation_data?.child_ref_id)
        this.selected_relation_mapping[relation_data?.child_ref_id]=relation_data
      }
      else if (relation_data?.child_module_id == this.module_id && relation_data?.parent_module_id == this.child_module_id){
        this.associated_ids.push(relation_data?.parent_ref_id)
        this.selected_relation_mapping[relation_data?.child_ref_id]=relation_data
      }
    });
    this.getChildObjs()
    this.columns = [
      {
				label: this._translateService.instant('UI.k_relation'),
				key: "relation",
				width:100,
        draggable: true,
			},
			{
				label: this._translateService.instant(this.module_label),
				key: "display_id",
				width:70
			},
			{
				label: this._translateService.instant('UI.k_status'),
				key: "status",
				width:100,
				type: 'select-template-status',
				isEditable: false
			},
			{
        "label": this._translateService.instant('UI.k_impact_service'),
        "key": "impact_service",
        "value": "impact_service_tree_path",
        "optionKey": "formatted_services",
        "type": "tree_view_select",
				isEditable: false
			},
			{
        "label": this._translateService.instant('UI.k_priority'),
        "key": "priority_id",
        "optionKey": "priority",
        "type": "badge_select",
				isEditable: false
		   },
			{
				label: this._translateService.instant('UI.k_summary'),
				key: "summary",
				width:100,
				isEditable: false
			},
			{
				label: this._translateService.instant('UI.k_assignee'),
				subkey: "assignment_info",
				key: "assignee",
				width:100
			},
		]
  }

setPage(pageInfo) {
    this.pageNumber = pageInfo.offset;
    this.getChildObjs();
}

onSort(args): void {
if (args.sorts) {
  this.pageNumber = 0;
  let sortKey = args.sorts[0].prop
  this.sortKey = sortKey
  if (args.sorts[0].dir == "desc") {
    this.reverse = "-";
  } else {
    this.reverse = "";
  }
}
this.getChildObjs();
}
setLimit(args): void {
this.pageNumber = 0
if (args === "all") {
  this.limit = this.child_objs_count;
} else {
  this.limit = parseInt(args);
}
this.getChildObjs();
}
getChildObjs() {
this.selected_relations = [];
this.filterString =  JSON.stringify({"display_id__not__iexact": this.parent_obj?.display_id});
if (this.relationModule == InfinityModules?.INCIDENT){
  this.getIncidentData()
}
else if (this.relationModule == InfinityModules?.PROBLEM){
  this.getProblemData()
}
else if (this.relationModule == InfinityModules?.CHANGE){
  this.getChangeData()
}
else if (this.relationModule == InfinityModules?.REQUEST_PROCESS){
  this.getRequestData()
}
else if (this.relationModule == InfinityModules?.RELEASE){
  this.getReleaseData()
}


}
  toggleSidebar = (): void => {
    this.onHideAssociateRelations.emit("hide");
	};
  onSelectRow(data) {
		this.selectedData = [];
    this.selected_reln_ids = [];
    data?.forEach(ele => {
      ele['module_id'] = this.child_module_id;
      this.selected_reln_ids.push(ele?.[this.child_process_key])
      this.selectedData.push(ele)
      if(this.disableSubmit){
        this.disableSubmit = false;
      } 
    })
	}
  handleRelationsubmit = (response) =>{
    if(response["status"] == "success") {
      this._toastr.success(
        this._translateService.instant(
          response["message"]
        ),
        this._translateService.instant(
          "Message.msg_save_success"
        ),
        {
          toastClass: "toast ngx-toastr",
          closeButton: true,
          positionClass: "toast-top-right",
        }
      );
      this.disableSubmit = false;
      this.getRelations.emit(this.module);
      this.onHideAssociateRelations.emit("hide");
    }
    else{
      this._toastr.error(
        this._translateService.instant(
          response["message"]
        ),
        this._translateService.instant(
          "Error.err_save_failed"
        ),
        {
          toastClass: "toast ngx-toastr",
          closeButton: true,
          positionClass: "toast-top-right",
        }
      );
      this.disableSubmit = true;
    }
  }
  addIncidentRelation = (rel_obj) =>{
    this._service.addIncidentRelation(rel_obj).subscribe(
      (response) => {
       this.handleRelationsubmit(response)
      }
      )
  }
  addProblemRelation = (rel_obj) =>{
    this._service.addProblemRelation(rel_obj).subscribe(
      (response) => {
       this.handleRelationsubmit(response)
      }
      )
  }
  addChangeRelation = (rel_obj) =>{
    this._service.addChangeRelation(rel_obj).subscribe(
      (response) => {
       this.handleRelationsubmit(response)
      }
      )
  }
  addRequestRelation = (rel_obj) =>{
    this._service.addRequestRelation(rel_obj).subscribe(
      (response) => {
       this.handleRelationsubmit(response)
      }
      )
  }
  addReleaseRelation = (rel_obj) =>{
    this._service.addReleaseRelation(rel_obj).subscribe(
      (response) => {
       this.handleRelationsubmit(response)
      }
      )
  }
  associateRelation (){
    let removed_reln_ids:any = []
    this.selected_relations?.forEach(ele => {
      if (this.selected_reln_ids.indexOf(ele?.[this.child_process_key]) === -1){
        removed_reln_ids.push(this.selected_relation_mapping?.[ele?.[this.child_process_key]]?.relation_id)
      }
    })
    let rel_obj = {}
    this.parent_obj['module_id'] = this.module_id
    rel_obj['parent_obj'] = this.parent_obj
    rel_obj['child_obj'] = this.selectedData
    rel_obj['relation_type'] = this.relationType
    rel_obj["removed_reln_ids"] = removed_reln_ids
    this.disableSubmit = true;
    if (this.module == InfinityModules?.INCIDENT){
      this.addIncidentRelation(rel_obj)
    }else if(this.module == InfinityModules?.PROBLEM){
      this.addProblemRelation(rel_obj)
    }
    else if(this.module == InfinityModules?.CHANGE){
      this.addChangeRelation(rel_obj)
    }else if(this.module == InfinityModules?.REQUEST_PROCESS){
      this.addRequestRelation(rel_obj)
    }else if(this.module == InfinityModules?.RELEASE){
      this.addReleaseRelation(rel_obj)
    } 
  }
  getIncidentData = () =>{
    const payload = {
      page: this.pageNumber+1,
      items_per_page: this.limit,
      sort: this.sortKey,
      reverse: this.reverse,
      filterString: this.filterString,
      moduleName: this.relationModule,
      module: this.relationModule,
      filter: JSON.stringify(this.filter),
    };
    this._service.getIncidents(payload).subscribe(
      (response) => {
        if (response) {
          this.child_objs = response.results;
          this.child_objs_count = response.count;
          this.showData = true;
          this.selectedData = [];
          this.child_objs.forEach( (value) => {
            if (this.associated_ids.indexOf(value?.[this.child_process_key]) !== -1){
              value['module_id'] = this.module_id;
              this.selectedData.push(value)
              this.disableSubmit = false;
              this.selected_relations.push(value)
              this.selected_reln_ids.push(value?.[this.child_process_key])
              value["relation"] = this._translateService.instant("UI.k_associated")
            }
          });
          this.dataLoaded = true;
        }
      },
      (error) => {
        //todo
      }
    );
  }
  getProblemData = () =>{
    const payload = {
      page: this.pageNumber+1,
      items_per_page: this.limit,
      sort: this.sortKey,
      reverse: this.reverse,
      filterString: this.filterString,
      moduleName: this.relationModule,
      module: this.relationModule,
      filter: JSON.stringify(this.filter),
    };
    this._service.getProblems(payload).subscribe(
      (response) => {
        if (response) {
          this.child_objs = response.results;
          this.child_objs_count = response.count;
          this.showData = true;
          this.selectedData = []
          this.child_objs.forEach( (value) => {
            if (this.associated_ids.indexOf(value?.[this.child_process_key]) !== -1){
              value['module_id'] = this.child_module_id;
              this.selectedData.push(value)
              this.disableSubmit = false;
              this.selected_relations.push(value)
              this.selected_reln_ids.push(value?.[this.child_process_key])
              value["relation"] = this._translateService.instant("UI.k_associated")
            }
          });
          this.dataLoaded = true;
        }
      },
      (error) => {
        //todo
      }
    );
  }
  getChangeData = () =>{
    const payload = {
      page: this.pageNumber+1,
      items_per_page: this.limit,
      sort: this.sortKey,
      reverse: this.reverse,
      filterString: this.filterString,
      moduleName: this.relationModule,
      module: this.relationModule,
      filter: JSON.stringify(this.filter),
    };
    this._service.getChanges(payload).subscribe(
      (response) => {
        if (response) {
          this.child_objs = response.results;
          this.child_objs_count = response.count;
          this.showData = true;
          this.selectedData = []
          this.child_objs.forEach( (value) => {
            if (this.associated_ids.indexOf(value?.[this.child_process_key]) !== -1){
              value['module_id'] = this.child_module_id;
              this.selectedData.push(value)
              this.disableSubmit = false;
              this.selected_relations.push(value)
              this.selected_reln_ids.push(value?.[this.child_process_key])
              value["relation"] = this._translateService.instant("UI.k_associated")
            }
          });
          this.dataLoaded = true;
        }
      },
      (error) => {
        //todo
      }
    );
  }
  getRequestData = () =>{
    const payload = {
      page: this.pageNumber+1,
      items_per_page: this.limit,
      sort: this.sortKey,
      reverse: this.reverse,
      filterString: this.filterString,
      moduleName: this.relationModule,
      module: this.relationModule,
      filter: JSON.stringify(this.filter),
    };
    this._service.getRequests(payload).subscribe(
      (response) => {
        if (response) {
          this.child_objs = response.results;
          this.child_objs_count = response.count;
          this.showData = true;
          this.selectedData = []
          this.child_objs.forEach( (value) => {
            if (this.associated_ids.indexOf(value?.[this.child_process_key]) !== -1){
              value['module_id'] = this.child_module_id;
              this.selectedData.push(value)
              this.disableSubmit = false;
              this.selected_relations.push(value)
              this.selected_reln_ids.push(value?.[this.child_process_key])
              value["relation"] = this._translateService.instant("UI.k_associated")
            }
          });
          this.dataLoaded = true;
        }
      },
      (error) => {
        //todo
      }
    );
  }
  getReleaseData = () =>{
    const payload = {
      page: this.pageNumber+1,
      items_per_page: this.limit,
      sort: this.sortKey,
      reverse: this.reverse,
      filterString: this.filterString,
      moduleName: this.relationModule,
      module: this.relationModule,
      filter: JSON.stringify(this.filter),
    };
    this._service.getReleases(payload).subscribe(
      (response) => {
        if (response) {
          this.child_objs = response.results;
          this.child_objs_count = response.count;
          this.showData = true;
          this.selectedData = []
          this.child_objs.forEach( (value) => {
            if (this.associated_ids.indexOf(value?.[this.child_process_key]) !== -1){
              value['module_id'] = this.child_module_id;
              this.selectedData.push(value)
              this.disableSubmit = false;
              this.selected_relations.push(value)
              this.selected_reln_ids.push(value?.[this.child_process_key])
              value["relation"] = this._translateService.instant("UI.k_associated")
            }
          });
          this.dataLoaded = true;
        }
      },
      (error) => {
        //todo
      }
    );
  }
  getFilterOptions(): void {
		this._service.getFilterOption({ moduleName: this.relationModule }).subscribe(
			(response) => {
				if (response) {
					this.searchOptions = response;
				} else {
					this.searchOptions = {};
				}
			},
			(error) => {
				this.searchOptions = {};
			}
		);
	}
  getFilterSearch = ($event) => {
		const dict = {};
		const filter = [];
		dict["ui_display"] = $event.userSearchData;
		dict["filter_string"] = $event.userSearchString;
		dict["filter_item"] = $event.filterItem;
		dict["type"] = $event.type;
		dict["module"] = this.relationModule;
		filter.push(dict);
		this.filter = [];
		this.filter = filter;
		this.pageNumber = 0;
		if ($event?.filterItem?.length > 0) {
			this.filterData = true;
		} else {
			this.filterData = false;
		}
		this.getChildObjs();
		this.getSavedFilters();
	};
  getSavedFilters(): void {
		this._service
			.getSavedFilter({ module: this.relationModule })
			.pipe(
				map((response: any) => {
					if (response) {
						this.savedData = response;
					}
				})
			)
			.subscribe();
	}
  genericSearchFilter = ($event) => {
		let search_str = $event.userSearchData.trim();

		const filter_options = [];
		this.search_db_cols.forEach((item) => {
			filter_options.push({
				condition: item.cond,
				db_col: item.db_col,
				value: search_str,
				relation: "or",
			});
			const filter = [];
			if (filter_options.length == this.search_db_cols.length) {
				let dict = {};
				dict["ui_display"] = $event.userSearchData;
				dict["filter_string"] = filter_options;
				dict["filter_item"] = $event.filterItem;
				dict["type"] = $event.type;
				dict["module"] = this.relationModule;
				for (let i = 0; i < this.filter.length; i++) {
					dict["filter_string"].push(this.filter[i]);
				}

				filter.push(dict);
				this.filter = filter;
				this.pageNumber = 0;

				this.filterData = true;
				this.getChildObjs();
				this.getSavedFilters();
			}
		});
	};
  getOptions = () =>{
    if (this.relationModule == InfinityModules?.INCIDENT){
      this._service.getOptions({ call_for_grid: 1 }).subscribe(
        (response) => {
          if (response) {
            this.childModuleOptions = response;
          }
        },
        (error) => {
          // todo
        }
      );
    }
    else if (this.relationModule == InfinityModules?.PROBLEM){
      this._service.getProblemOptions({ call_for_grid: 1 }).subscribe(
        (response) => {
          if (response) {
            this.childModuleOptions = response;
          }
        },
        (error) => {
          // todo
        }
      );
    }else if (this.relationModule == InfinityModules?.CHANGE){
      this._service.getChangeOptions({ call_for_grid: 1 }).subscribe(
        (response) => {
          if (response) {
            this.childModuleOptions = response;
          }
        },
        (error) => {
          // todo
        }
      );
    }else if (this.relationModule == InfinityModules?.REQUEST_PROCESS){
      this._service.getRequestOptions({ call_for_grid: 1 }).subscribe(
        (response) => {
          if (response) {
            this.childModuleOptions = response;
          }
        },
        (error) => {
          // todo
        }
      );
    }else if (this.relationModule == InfinityModules?.RELEASE){
      this._service.getReleaseOptions({ call_for_grid: 1 }).subscribe(
        (response) => {
          if (response) {
            this.childModuleOptions = response;
          }
        },
        (error) => {
          // todo
        }
      );
    }
    else{
      //
    }
    } 

}
