import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "app/common/services/api/api.service";
// import * as  getAllData from "./../components/work-space/workspace_json.json"
// import { of } from 'rxjs';

@Injectable({
	providedIn: "root",
})
export class WorkSpaceService extends ApiService {
	constructor(httpClient: HttpClient) {
		super(httpClient);
	}

	getWorkSpaceData = (payload) => {
		return this.get("ux/common/workspace/workspace/", payload);
	};

	getTaskOptions(payload: {}) {
		return this.get("ux/sd/task/task/options/");
	}

	getTaskData(pk, payload) {
		return this.get(`ux/org/profile/task-data/${pk}/`, payload);
	}

	saveTask(payload) {
		return this.post("ux/org/profile/save-task/", payload);
	}

	getInfiBotReply(payload) {
		return this.post("ux/org/profile/infi-bot/", payload);
	}

	initInfiBot(payload) {
		return this.post("ux/org/profile/infi-bot/", payload);
	}

	createInfiBotSession(payload) {
		return this.post("ux/chatgpt/chatgpt/", payload);
	}

	getInfiReply(payload) {
		return this.post("ux/common/bots/bot_config/conversation/", payload);
	}

	getMyTickets(payload) {
		return this.post("ux/chatgpt/chatgpt/my-tickets/", payload);
	}
	createDraft(payload) {
		return this.post("ux/chatgpt/chatgpt/create-draft/", payload);
	}

	clearConversation(payload) {
		return this.post("ux/chatgpt/chatgpt/clear/", payload);
	}
	createIncident(payload) {
		return this.post("ux/chatgpt/chatgpt/create_inci/", payload);
	}
	fetchIncident(payload) {
		return this.get("ux/sd/inci/incident/fetch_inci/", payload);
	}
	assignToUser(payload) {
		return this.post("ux/sd/inci/incident/assign-to-user/", payload);
	}
	paraphraseText(payload) {
		return this.post("ux/common/bots/bot_config/paraphrase/", payload);
	}
	getChatInfiReply(payload) {
		return this.post("ux/chatgpt/chatgpt/chat_conversation/", payload);
	}
	uploadChatContent(payload) {
		return this.post("ux/chatgpt/chatgpt/upload_chat_content/", payload);
	}
	deleteChatContent(payload) {
		return this.post("ux/chatgpt/chatgpt/delete_chat_content/", payload);
	}
	downloadChatContent(payload) {
		return this.post("ux/chatgpt/chatgpt/download_chat_content/", payload);
	}
	getOptions(payload: {}) {
		return this.get("ux/common/workspace/channel/options/", payload);
	}
	saveChannel(payload) {
		return this.post('ux/common/workspace/channel/', payload)
	}
	getChannel(id) {
		return this.get('ux/common/workspace/channel/'+id+'/')
	}
	getChannelList(payload) {
		return this.get('ux/common/workspace/channel/', payload)
	}
	deleteChannel(id) {
		return this.delete('ux/common/workspace/channel/'+id+'/')
	}
	saveMetaData(payload) {
		return this.post("ux/common/workspace/workspace/save_meta_data/", payload);
	}
	getMetaList(payload) {
		return this.get('ux/common/workspace/workspace/meta_data_list/', payload)
	}
	getConversationChatList(payload) {
		return this.get('ux/common/workspace/workspace/conv_chat_list/', payload)
	}
	searchProcessData = (payload) => {
		return this.get("ux/common/workspace/workspace/search_process/", payload);
	};
	getIncident(incident_id: any) {
		return this.get("ux/sd/inci/incident/" + incident_id + "/");
	}
	getMetaData(payload) {
		return this.get('ux/common/workspace/workspace/meta_data/', payload)
	};
	AIBasedKBCreate(payload) {
		return this.post("ux/common/bots/bot_config/ai_kb/", payload);
	}
	searchUserRequester(payload) {
		return this.get("ux/common/workspace/channel/search_user_req/", payload);
	}
}
