import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {customSearch, encryptData} from "app/common/utils/utils";
import store from 'store'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from "environments/environment";

@Component({
	selector: 'app-network-diagram-widget',
	templateUrl: './network-diagram-widget.component.html',
	styleUrls: ['./network-diagram-widget.component.scss']
})
export class NetworkDiagramWidgetComponent implements OnInit {

	@Input() widgetData: any;
	@Input() modifiedtime: any;

	payload: string;
	iframe_url: SafeResourceUrl;
	constructor(
		public sanitizer: DomSanitizer,
	) { }

	ngOnInit(): void {
		this.drawNetworkDiagram()
	}

	ngOnChanges(changes: SimpleChanges) {
		if(changes?.widgetData == undefined){
			this.drawNetworkDiagram()
		}
	}

	drawNetworkDiagram(){
		this.payload = encryptData(JSON.stringify({
			"baseurl": environment.instance_url,
			"headers": {"Authorization": store.get('accessToken')},
			"event": "add",
			"param": {event: "add"},
			"params": {event: "add"}
		}))
		let nd_id = this.widgetData?.network_diagram
		if (nd_id != undefined){
			this.iframe_url= this.sanitizer.bypassSecurityTrustResourceUrl(environment.instance_url + 'mx/view/'+nd_id+'?payload='+this.payload );
		}
	}
}
