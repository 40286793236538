import { Component, OnInit, Input } from "@angular/core";
import { getAssetImageSrc, getImgSrcforType } from "app/common/utils/utils";
import { NgEventBus } from "ng-event-bus";

@Component({
	selector: "app-contract-summary-widget",
	templateUrl: "./contract-summary-widget.component.html",
	styleUrls: ["./contract-summary-widget.component.scss"],
})
export class ContractSummaryWidgetComponent implements OnInit {
	@Input() widgetData: any;
	@Input() modifiedtime: any;

	constructor(private eventBus: NgEventBus) {}

	ngOnInit(): void {}

	getImg(item) {
		return getAssetImageSrc(item.id, this.widgetData?.data?.groupby);
	}

	showContractSidebar(dat) {
		let payload = {
			title: dat.id + " List",
			app_type: "contract-grid",
			item_filter: dat.item_filter,
		};
		// console.log("in AssetSummaryViewWidgetComponent widget -> ", this.widgetData, _wdgt_filter)
		this.eventBus.cast("dashboard-view:sidebar", payload);
	}
}
