import {
	Component,
	OnInit,
	Input,
	OnChanges,
	Output,
	EventEmitter,
	SimpleChanges,
	ViewChild,
	ElementRef,
} from "@angular/core";
import Feature from "ol/Feature";
import Geolocation from "ol/Geolocation";
import Map from "ol/Map";
import Point from "ol/geom/Point";
import View from "ol/View";
import { Circle as CircleStyle, Fill, Stroke, Style, Icon } from "ol/style";
import { ImageWMS, OSM } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { defaults as defaultControls, ScaleLine, Zoom, ZoomSlider } from "ol/control";
import XYZ from "ol/source/XYZ";
import TileJSON from "ol/source/TileJSON";
import ZoomToExtent from "ol/control/ZoomToExtent";
import VectorSource from "ol/source/Vector";
import { Modify } from "ol/interaction";
import { fromLonLat, transform } from "ol/proj";
import LineString from "ol/geom/LineString";
import { toStringXY } from "ol/coordinate";
import MultiPoint from "ol/geom/MultiPoint";
import GeoJSON from "ol/format/GeoJSON";
import { Polygon } from "ol/geom";
import BingMaps from "ol/source/BingMaps";
import { Toast } from "bootstrap";
import { style } from "@angular/animations";
import Text from "ol/style/Text";
import EsriJSON from "ol/format/EsriJSON";
import Overlay from "ol/Overlay";
import { boundingExtent } from "ol/extent";
import { TranslateService } from "@ngx-translate/core";
import { CoreConfigService } from "@core/services/config.service";
import ImageLayer from "ol/layer/Image";
import { zoomIn } from "@core/animations/core.animation";

// import { link } from 'fs';
@Component({
	selector: "app-open-layers",
	templateUrl: "./open-layers.component.html",
	styleUrls: ["./open-layers.component.scss"],
})
export class OpenLayersComponent implements OnInit, OnChanges {
	checked = false;
	map: Map;
	updatedMap;
	vectorLayer;
	featureOverlay;
	rasterLayer;
	lineFeature;
	target = document.getElementById("olMap");
	esrijsonFormat = new EsriJSON();
	searchNodelat;
	searchNodelong;
	@Input() locationData?: any;
	@Input() zoom_level: any;
	@Input() topologyConnenctions: any = [];
	@Input() mapOptions: any = [];
	@Input() selectedMap;
	@Input() disableMapOtions: any = 0;
	@Input() currentLevel: any;
	@Output("onClick") callback = new EventEmitter
	@Output() resetMap = new EventEmitter(false);

	mapType: any;
	addedLinks: any = [];
	resetdraw = false

	constructor(private _translateService: TranslateService, private _coreConfigService: CoreConfigService) {}

	ngOnInit(): void {
		this.drawGraph(this.callback, this.zoom_level, true, this.currentLevel, false)
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(this.resetdraw){
			if (this.mapType?.name != this.selectedMap?.name) {
				this.selectedMap = this.mapType;

			}
			this.drawGraph(this.callback, this.zoom_level, true, this.currentLevel, false)
			this.resetdraw = false
		}
		else{
		if (this.rasterLayer != undefined){
			if (this.mapType?.name != this.selectedMap?.name) {
				this.selectedMap = this.mapType;

			}
			let features = this.vectorLayer.getSource().getFeatures();
			features.forEach((feature) => {
				this.vectorLayer.getSource().removeFeature(feature);
			});
			this.addedLinks.forEach((layer) => {
				this.map.removeLayer(layer);
			});
			this.drawGraph(this.callback, this.zoom_level, false, this.currentLevel, false)
		}
	}
	}
	
	drawGraph(callback, curr_zoom, drawMap, currentLevel, changeMap){
		const styleCache = {};
		this.mapType = this.selectedMap;
		const styleFunction = function (feature) {
			const name = feature.get("name");
			const magnitude = parseFloat(name.substr(2));
			const radius = 5 + 20 * (magnitude - 5);
			let style = styleCache[radius];
			if (!style) {
				style = new Style({
					image: new CircleStyle({
						radius: radius,
						fill: new Fill({
							color: "red",
						}),
					}),
					stroke: new Stroke({
						color: "green",
						width: 1,
					}),
				});
				styleCache[radius] = style;
			}
			return style;
		};
		const nodeFeature = this.locationData.map((i) => {
			var coordinates = i.geometry.coordinates;
			let iconFeature = new Feature({
				geometry: new Point(transform(coordinates, 'EPSG:4326',
					'EPSG:3857')),
				type: i.geometry.type,
				name: i.name,
				discription: i.description,
				item_filter: i.item_filter
			}),
				iconStyle = new Style({
					image: new Icon( /** @type {module:ol/style/Icon~Options} */({
						crossOrigin: 'anonymous',
						src: i.imgIcon,
						opacity: i.opacity,
						scale: i.icon_scale,
					})),
				})
			iconFeature.setStyle(iconStyle);
			return iconFeature;
		});

		const vectorSource = new VectorSource({
			features: nodeFeature,
		});

		this.vectorLayer = new VectorLayer({
			source: vectorSource,
			style: styleFunction,
		});
		
		if (drawMap == true) {
			if (this.selectedMap.mapOrigin === "osm") {
				this.rasterLayer = new TileLayer({
					source: new OSM(),
				});
			} else if (this.selectedMap.mapOrigin === "Google") {
				this.rasterLayer = new TileLayer({
					source: new XYZ({
						url: this.selectedMap.mapSource,
					}),
				});
			} else if (this.selectedMap.mapOrigin === "bing") {
				this.rasterLayer = new TileLayer({
					source: new BingMaps({
						key: this.selectedMap.mapKey,
						imagerySet: this.selectedMap.mapImageStyle,
					}),
				});
			} else if (this.selectedMap.mapOrigin === "geoserver") {
				this.rasterLayer = new ImageLayer({
					source: new ImageWMS({
						url: this.selectedMap.mapUrl,
						params: {
							LAYERS: this.selectedMap.layers,
						},
						serverType: "geoserver",
						crossOrigin: "anonymous",
						projection: "EPSG:4326",
					}),
				});
			} else {
				this.rasterLayer = new TileLayer({
					source: new OSM(),
				});
			}
			if (changeMap == true){
				let layers = this.map.getAllLayers()
				layers.forEach((layer) => {
					this.map.removeLayer(layer);
				});
				this.map.addLayer(this.rasterLayer)
			}
			else{
				this.map = new Map({
					target: document.getElementById("olMap"),
					layers: [this.rasterLayer],
					view: new View({
						center: transform([10, 10], "EPSG:4326", "EPSG:3857"),						
						zoom: curr_zoom,
						maxZoom: 18,
					}),
					controls: [
						new Zoom({
							className: "ol-zoom",
							zoomOutClassName: "ol-zoom-out",
							zoomInClassName: "ol-zoom-in",
						}),
					],
				});
			}
		}
		var map = this.map;
		const edage = this.topologyConnenctions.map((i) => {
			let src_coordinates = i.src_coordinates;
			let dest_coordinates = i?.dest_coordinates;
			let discription = i.node_info.node_details;
			let line = new LineString([fromLonLat(src_coordinates), fromLonLat(dest_coordinates)]);
			let lineFeature = new Feature({
				geometry: line,
				name: i.name,
				type: "link",
				discription: discription,
			});
			let iconStyle = new Style({
				stroke: new Stroke({
					color: i.col,
					width: 1,
				}),
			});
			lineFeature.setStyle(iconStyle);

			const vectorEdageSource = new VectorSource({
				features: [lineFeature],
				// features: [line]
			});
			this.featureOverlay = new VectorLayer({
				source: vectorEdageSource,
			});

			map.addLayer(this.featureOverlay);
			this.addedLinks.push(this.featureOverlay);
		});
		map.addLayer(this.vectorLayer);
		map.on("pointermove", function (evt) {
			if (evt && evt.coordinate) {
				var tooltip = document.getElementById("tooltip");
				var overlay = new Overlay({
					element: tooltip,
					offset: [0, 0],
					positioning: "top-left",
				});
				map.addOverlay(overlay);
				var pixel = evt.pixel;
				var feature = map.forEachFeatureAtPixel(pixel, function (feature) {
					return feature;
				});
				tooltip.style.display = feature ? "" : "none";
				tooltip.className = 'map-tooltip'
				let featureType = feature?.get("type")
			if (featureType === 'point' || featureType === 'link') {
				let discription = feature?.['values_']?.discription
				overlay.setPosition(evt.coordinate);
				let ParamStr = ''
				for (let key in discription){
					// let des_key = this._translateService.instant("UI.k_edit")
					ParamStr += "<div class='tooltipData'>" +
										"<div class='name-info'>" + discription[key].name + "</div>" +
										"<div class='vale-info'>" + (discription[key].value || "-") + "</div>" +
										"</div>";
				}
				tooltip.innerHTML = ParamStr							
					let mapWidth = document.getElementById("olMap").offsetWidth
					let mapHeight = document.getElementById("olMap").offsetHeight
					let tooltipWidth = tooltip.offsetWidth 
					let tooltipHeight = tooltip.offsetHeight	
					if(pixel[0] + tooltipWidth > mapWidth && pixel[1] + tooltipHeight > mapHeight){
						overlay.setPositioning('bottom-right')
					} else if (pixel[1] + tooltipHeight > mapHeight){
						overlay.setPositioning('bottom-left')
					} else if (pixel[0] + tooltipWidth > mapWidth){
						overlay.setPositioning('top-right')
					}else{
						overlay.setPositioning('top-left')
					}
				
				}
				else{
					overlay.setPosition(evt.coordinate);
					tooltip.innerHTML = `<div class="tooltipData">
					<h6 class="white-color mb-0">${feature?.get("name")}</h6>
					<div>`;
					let mapWidth = document.getElementById("olMap").offsetWidth
					let mapHeight = document.getElementById("olMap").offsetHeight
					let tooltipWidth = tooltip.offsetWidth 
					let tooltipHeight = tooltip.offsetHeight	
					if(pixel[0] + tooltipWidth > mapWidth && pixel[1] + tooltipHeight > mapHeight){
						overlay.setPositioning('bottom-right')
					} else if (pixel[1] + tooltipHeight > mapHeight){
						overlay.setPositioning('bottom-left')
					} else if (pixel[0] + tooltipWidth > mapWidth){
						overlay.setPositioning('top-right')
					}else{
						overlay.setPositioning('top-left')
					}
				}
			}
		});
		map.addEventListener("dblclick", function (e) {
			let zoom_level = map.getView().getZoom();
			var map_zoom_level = parseFloat(zoom_level.toString()).toFixed(1);
			if (map_zoom_level < curr_zoom){
				if ([3.5, 5.5, 7.5].indexOf(parseFloat(map_zoom_level)) != -1 && map_zoom_level != curr_zoom) {
					curr_zoom = parseFloat(map_zoom_level);
					callback.emit({ zoom_level: parseFloat(map_zoom_level), loadPage: true, defaultMap: this.mapType});
				}
			}
			else{
				if ([2.5, 4.5, 6.5, 8.5].indexOf(parseFloat(map_zoom_level)) != -1 && map_zoom_level != curr_zoom) {
					curr_zoom = parseFloat(map_zoom_level);
					callback.emit({ zoom_level: parseFloat(map_zoom_level), loadPage: true });
				}
			}
		  })
		map.getViewport().addEventListener("click", function (e) {
			var feature = map.forEachFeatureAtPixel(map.getEventPixel(e),
			function (feature, layer) {
				return feature;
			});
			if (feature?.['values_']?.item_filter && currentLevel == "city") {
				let zoom_level = map.getView().getZoom()
				var map_zoom_level =  parseFloat(zoom_level.toString()).toFixed(1)
				let filter = feature?.['values_']?.item_filter
				callback.emit({zoom_level: parseFloat(map_zoom_level), redirectToAsset: true, filters: filter})
			}
		})
		  map.on('click',function(evt) {
			if (evt && evt.coordinate) {
				const feature = map.getFeaturesAtPixel(evt.pixel)[0];
				if (!feature) {
					return;
				}
				if (feature?.['values_']?.item_filter && currentLevel == "city") {
					let zoom_level = map.getView().getZoom()
					var map_zoom_level =  parseFloat(zoom_level.toString()).toFixed(1)
					let filter = feature?.['values_']?.item_filter
					callback.emit({zoom_level: parseFloat(map_zoom_level), redirectToAsset: true, filters: filter})
				}
			}
		  })

		map.getView().on('change:resolution',function(e) {
			let zoom_level = map.getView().getZoom()
			var map_zoom_level =  parseFloat(zoom_level.toString()).toFixed(1)
			if (map_zoom_level < curr_zoom){
				if ([3.5, 5.5, 7.5].indexOf(parseFloat(map_zoom_level)) != -1 && map_zoom_level != curr_zoom) {
					curr_zoom = parseFloat(map_zoom_level);
					callback.emit({ zoom_level: parseFloat(map_zoom_level), loadPage: true });
				}
			}
			else{
				if ([2.5, 4.5, 6.5, 8.5].indexOf(parseFloat(map_zoom_level)) != -1 &&  parseFloat(map_zoom_level) != parseFloat(curr_zoom)){
					curr_zoom = parseFloat(map_zoom_level)
					callback.emit({zoom_level: parseFloat(map_zoom_level), loadPage: true})
				}
			}
		});
	}
	changeMapOption(e, curr_zoom) {
		this.selectedMap = e;
		this.drawGraph(this.callback, this.zoom_level, true, this.currentLevel, true)
	}
	changeNodeSearch(e) {
		let nodeLocation = e.geometry.coordinates;
		this.searchNodelong = nodeLocation[0];
		this.searchNodelat = nodeLocation[1];
		this.refreshMap(this.searchNodelat, this.searchNodelong);
	}
	refreshMap = function (lat, long) {
		var map = this.map;
		map.on("pointermove", function (evt) {
			if (evt && evt.coordinate) {
				var tooltip = document.getElementById("tooltip");
				var overlay = new Overlay({
					element: tooltip,
					offset: [0, 0],
					positioning: "bottom-left",
				});
				map.addOverlay(overlay);
				var pixel = evt.pixel;
				var feature = map.forEachFeatureAtPixel(pixel, function (feature) {
					return feature;
				});
				tooltip.style.display = feature ? "" : "none";
				let featureType = feature.get("type");

				if (featureType === "point") {
					let discription = feature?.["values_"]?.discription;
					overlay.setPosition(evt.coordinate);
					let ParamStr = "";
					for (let key in discription) {
						// let des_key = this._translateService.instant("UI.k_edit")
						ParamStr +=
							"<div class='tooltipData'>" +
							"<div class='name-info'>" +
							key +
							"</div>" +
							"<div class='vale-info'>" +
							(discription[key] || "-") +
							"</div>" +
							"</div>";
					}
					tooltip.innerHTML = ParamStr;
				} else {
					overlay.setPosition(evt.coordinate);
					tooltip.innerHTML = `<div class="tooltipData">
					<h6 class="white-color mb-0">${feature.get("name")}</h6>
					<div>`;
				}
			}
		});
		this.map = new Map({
			controls: [],
			target: document.getElementById("olMap"),
			layers: [this.rasterLayer, this.featureOverlay, this.vectorLayer],
			view: new View({
				projection: "EPSG:4326",
				center: [long, lat],
				zoom: 10,
			}),
		});
	};

	resetgeomap = () => {
		this.resetdraw = true
		this.resetMap.emit(true);
	}
}
