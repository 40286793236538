import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleTreeDropdownComponent } from '../single-tree-dropdown/single-tree-dropdown.component';
import { FormsModule } from '@angular/forms';
import { TreeviewModule } from 'ngx-treeview';
import { DnsModule } from '../../dns.module';
import { NgxDropdownTreeviewSelectComponent } from './ngx-dropdown-treeview-select.component';
import { MultiselectTreeDropdownComponent } from '../multiselect-tree-dropdown/multiselect-tree-dropdown.component';



@NgModule({
  declarations: [SingleTreeDropdownComponent, NgxDropdownTreeviewSelectComponent,MultiselectTreeDropdownComponent],
  imports: [
    FormsModule,
    CommonModule,
    TreeviewModule.forRoot(),
  ],
  exports: [SingleTreeDropdownComponent,MultiselectTreeDropdownComponent]
})
export class NgxDropdownTreeviewModule { }
