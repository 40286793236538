import { Injectable } from "@angular/core";
import { ApiService } from "app/common/services/api/api.service";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class RequestViewService extends ApiService {
	getRequests(payload = {}) {
		return this.get("ux/sd/req/request_process/", payload);
	}

	saveRequest(payload: any) {
		return this.post("ux/sd/req/request_process/", payload);
	}

	getRequest(request_id: any, payload = {}) {
		return this.get("ux/sd/req/request_process/" + request_id + "/", payload);
	}

	editRequest(request_id: any, payload: any) {
		return this.put("ux/sd/req/request_process/" + request_id + "/", payload);
	}
	deleteRequest(payload: any) {
		return this.post("ux/sd/req/request_process/delete_request/", payload);
	}
	getRequestColumns(payload = {}) {
		return this.get("ux/sd/req/request_process/page_config/", payload);
	}
	getAttachmentDetails(payload = {}) {
		return this.get("ux/sd/req/request_process/attachment/", payload);
	}

	getHistoryDetails(payload = {}) {
		return this.get("ux/sd/req/request_process/history/", payload);
	}

	addAttachments(payload: any) {
		return this.post("ux/sd/req/request_process/add-attachment/", payload);
	}

	savePageConfig(payload) {
		return this.post("ux/sd/req/request_process/page/", payload);
	}
	getOptions(payload = {}) {
		return this.get("ux/sd/req/request_process/options/", payload);
	}
	getExpertise(payload = {}) {
		return this.get("ux/sd/req/request_process/expertise/", payload);
	}
	getExpertiseLevels(payload = {}) {
		return this.get("ux/sd/req/request_process/team_level/", payload);
	}
	getAssigneeList(payload = {}) {
		return this.get("ux/sd/req/request_process/assignee/", payload);
	}
	getUserProfile(userid: Number) {
		return this.get("ux/common/user/profile/" + userid.toString() + "/");
	}
	getServicesForSelectedCatalogue(payload: any) {
		return this.get("ux/common/catalogue/servicecatalogue/service-list/", payload);
	}
	getServiceDetails(serviceId: Number) {
		return this.get("ux/common/catalogue/servicecatalogue/" + serviceId.toString() + "/");
	}
	updateRequestAssignee(payload = {}) {
		return this.put("ux/sd/req/request_process/update-assignee/", payload);
	}
	getFilteredRequest(payload = {}) {
		return this.get("ux/sd/req/request_process/top_filter/", payload);
	}
	saveConversation(payload) {
		return this.post("ux/sd/req/conversation/", payload);
	}
	getMailConversations(payload) {
		return this.get("ux/sd/req/conversation/", payload);
	}
	updateRequestTag(payload = {}) {
		return this.put("ux/sd/req/request_process/update-tag/", payload);
	}
	loadOrganiationConfig(payload: {}) {
		return this.get("ux/org/profile/orgconfig/", payload);
	}

	saveRichTextData(payload: {}) {
		return this.post("ux/sd/req/request_process/save-rich-text/", payload);
	}

	getRichTextData(payload: {}) {
		return this.get("ux/sd/req/request_process/process-rich-text/", payload);
	}
	getRequesterList(payload: {}) {
		return this.get("ux/sd/req/request_process/requester_search/", payload);
	}

	processRequestRelations(payload: {}) {
		return this.get("ux/sd/req/request_process/process-relations/", payload);
	}

	mergeRequest(payload: {}) {
		return this.post("ux/sd/req/request_process/merge/", payload);
	}
	getReqLatestRequests(payload: {}) {
		return this.get("ux/sd/req/request_process/latestrequests/", payload);
	}
	deleteAttachment(attachmentId: any, payload: {}) {
		return this.put("ux/sd/req/request_process/delete-attachment/" + attachmentId.toString() + "/", payload);
	}
	convertRequestToKB(kbId: any, payload: {}) {
		return this.put("ux/sd/req/request_process/request-to-kb/" + kbId.toString() + "/", payload);
	}
	convertRequestToIncident(incidentId: any, payload: {}) {
		return this.put("ux/sd/req/request_process/request-to-inci/" + incidentId.toString() + "/", payload);
	}

	getAllKBs(payload: {}) {
		return this.get("ux/sd/req/request_process/request-kbs/", payload);
	}
	getSavedFilter(payload) {
		return this.get("ux/common/filters/filters/filter/", payload);
	}

	getServiceBaseWorkflowStatus(payload: any) {
		return this.get("ux/sd/req/request_process/workflow_status/", payload);
	}

	setEditRequestSidebar(flag) {
		localStorage.setItem("request/request-edit-flag", JSON.stringify(flag));
	}

	getRequestSidebar() {
		return JSON.parse(localStorage.getItem("request-edit-flag"));
	}

	editRequestAssignee(payload) {
		return this.post("ux/sd/req/request_process/update_request_assignee/", payload);
	}
	addRequestRelation(payload) {
		return this.post("ux/sd/req/request_process/relation/", payload);
	}

	getWorkflowOptions(payload) {
		return this.post("ux/sd/req/request_process/workflow_options_map/", payload);
	}

	bulkRequestResolve(payload) {
		return this.post("ux/sd/req/request_process/bulk-resolve/", payload);
	}

	bulkRequestAssignment(payload) {
		return this.post("ux/sd/req/request_process/bulk-assignment/", payload);
	}
	getFilterOptions(payload: any) {
		return this.get("ux/common/filters/filters/filter_options/", payload);
	}
	getKBProfiles(payload = {}) {
		return this.get("ux/common/kb/kb/", payload);
	}
	getRequesterImpactService(payload) {
		return this.get("ux/common/catalogue/servicecatalogue/req_services/", payload);
	}

	getImpactServiceClassification(payload) {
		return this.get("ux/common/catalogue/servicecatalogue/service_classes/", payload);
	}
	getTeamUserMap(payload) {
		return this.get("ux/sd/req/request_process/team_user_map/", payload);
	}
	getRequestData(pk, payload = {}) {
		return this.get(`ux/sd/req/request_process/request-data/${pk}/`, payload);
	}
	getApprovalStatus = (payload): Observable<any> => {
		return this.get("ux/sd/req/request_process/check_approval_user/", payload);
	};
	getIncidentData(pk, payload = {}) {
		return this.get(`ux/sd/inci/incident/incident-data/${pk}/`, payload);
	}
	getIncident(incident_id: any, payload={}) {
		return this.get("ux/sd/inci/incident/" + incident_id + "/", payload);
	}
	convertReqToChange(changeId: any, payload: {}) {
		return this.put("ux/sd/req/request_process/req-to-change/" + changeId.toString() + "/", payload);
	}
	getChange(change_id: any, payload: any ={}) {
		return this.get("ux/sd/change/change/" + change_id + "/", payload);
	}
	getSlaData(payload = {}) {
		return this.get("ux/common/sla/processdata/", payload);
	}
}
