<div class="slideout-content default-slideout assignee-modal">
	<div class="modalsd modal-slide-in sdfade new-api-modal" id="modals-slide-in">
		<div class="modal-dialog sidebar-sm">
			<div class="modal-content add-new-agent pt-0">
				<div class="modal-header">
					<h5 class="modal-title">{{ "UI.k_assignee" | translate }}</h5>
					<button
						type="button"
						class="btn btn-flat-secondary btn-icon"
						id="btnAssigneeClose"
						name="btnAssigneeClose"
						data-dismiss="modal"
						aria-label="Close"
						(click)="toggleSidebar('assignee-data')"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body card-bg">
					<ng-container>
						<ngb-accordion
							[closeOthers]="false"
							[activeIds]="['userSelPanel', 'teamsSelPanel']"
							[destroyOnHide]="false"
						>
							<ngb-panel
								id="userSelPanel"
								name="userSelPanel"
								[cardClass]="'collapse-margin collapse-icon'"
							>
								<ng-template ngbPanelTitle>
									<span class="lead collapse-title">
										{{ "UI.k_users" | translate }}
									</span>
								</ng-template>
								<ng-template ngbPanelContent>
									<ng-scrollbar class="custom-scrollbar section-scrollbar">
										<div class="assignee-card">
											<div class="user-sec">
												<div class="icon-search">
													<div class="form-group">
														<input
															type="text"
															class="form-control"
															id="txtUserSearch"
															name="txtUserSearch"
															placeholder="{{ 'UI.k_search' | translate }}"
															[(ngModel)]="this.userObject.searchUserText"
															(ngModelChange)="searchUserEvent($event)"
														/>
													</div>
												</div>
												<div class="user-list">
													<ng-scrollbar class="custom-scrollbar section-scrollbar">
														<ng-container
															*ngFor="let item of this.userObject.userList; let i = index"
														>
															<div
																class="single-user"
																[ngClass]="item?.selected ? 'selected' : ''"
																(click)="selectUserItem(item)"
																id="divUser"
																name="divUser"
															>
																<div *ngIf="item?.profile_image; else customAvatar">
																	<div class="avatar avatar-sm">
																		<span class="avatar-content">
																			<img
																				class="rounded-circle"
																				src="{{ item.profile_image }}"
																				alt="User"
																			/>
																		</span>
																	</div>
																</div>
																<ng-template #customAvatar>
																	<div
																		class="avatar avatar-sm ml-0"
																		[ngStyle]="{
																			'background-color': item.avatar_color
																				? item.avatar_color
																				: '#f59518'
																		}"
																	>
																		<div class="avatar-content xs-font">
																			{{ item.full_name | initials | slice: O:2 }}
																		</div>
																	</div>
																</ng-template>
																<div class="user-info">
																	<div class="user-name">
																		{{ item?.full_name | slice: 0:28 }}
																		{{ item?.full_name?.length > 28 ? ".." : "" }}
																	</div>
																	<div class="user-mail text-muted">
																		{{ item?.email | slice: 0:28 }}
																		{{ item?.email.length > 28 ? ".." : "" }}
																	</div>
																</div>
																<div class="user-select">
																	<div class="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			class="custom-control-input"
																			id="chkUser__{{ i }}"
																			[checked]="item?.selected"
																			(click)="selectUserItem(item)"
																		/>
																		<label
																			class="custom-control-label"
																			for="chkUser__{{ i }}"
																		></label>
																	</div>
																</div>
															</div>
														</ng-container>
													</ng-scrollbar>
												</div>
											</div>
										</div>
									</ng-scrollbar>
								</ng-template>
							</ngb-panel>
							<ngb-panel
								*appDnsLicenseHandler="teamLicense || {}"
								id="teamsSelPanel"
								name="teamsSelPanel"
								[cardClass]="'collapse-margin collapse-icon'"
							>
								<ng-template ngbPanelTitle>
									<span class="lead collapse-title">
										{{ "UI.k_teams" | translate }}
									</span>
								</ng-template>
								<ng-template ngbPanelContent>
									<ng-scrollbar class="custom-scrollbar section-scrollbar">
										<div class="assignee-card">
											<div class="team-sec">
												<div class="icon-search">
													<div class="form-group">
														<input
															type="text"
															class="form-control"
															id="txtTeamSearch"
															name="txtTeamSearch"
															placeholder="{{ 'UI.k_search' | translate }}"
															[(ngModel)]="this.userObject.searchGroupText"
															(ngModelChange)="searchTeamEvent($event)"
														/>
													</div>
												</div>
												<div class="team-list">
													<ng-scrollbar class="custom-scrollbar section-scrollbar">
														<ng-container
															*ngFor="
																let groupItem of this.userObject.groupList;
																let i = index
															"
														>
															<div
																class="single-team"
																[ngClass]="groupItem?.selected ? 'selected' : ''"
																(click)="selectGroupEvent(groupItem)"
																id="divGroup"
																name="divGroup"
															>
																<div class="team-img">
																	<div class="d-flex align-items-center">
																		<div class="avatar-group">
																			<ng-container
																				*ngFor="
																					let staff of groupItem?.staff_list
																						| slice: 0:3
																				"
																			>
																				<div
																					placement="top"
																					class="avatar avatar-sm pull-up"
																					placement="top"
																					container="body"
																					*ngIf="
																						staff?.profile_image;
																						else customAvatar
																					"
																				>
																					<img
																						class="rounded-circle"
																						src="{{ staff.profile_image }}"
																						alt="datatable-avatar"
																					/>
																				</div>
																				<ng-template #customAvatar>
																					<div
																						placement="top"
																						class="avatar avatar-sm pull-up"
																						placement="top"
																						container="body"
																						[ngStyle]="{
																							'background-color':
																								staff.avatar_color
																									? staff.avatar_color
																									: '#f59518'
																						}"
																					>
																						<div
																							class="avatar-content xs-font"
																						>
																							{{
																								staff.full_name
																									| initials
																									| slice: O:2
																							}}
																						</div>
																					</div>
																				</ng-template>
																			</ng-container>
																			<ng-container
																				*ngIf="
																					groupItem?.staff_list.length == 0
																				"
																			>
																				<div
																					placement="top"
																					class="avatar pull-up"
																					placement="top"
																					container="body"
																					[ngStyle]="{
																						'background-color':
																							groupItem?.avatar_color
																								? groupItem.avatar_color
																								: '#f59518'
																					}"
																				>
																					<div class="avatar-content">
																						{{
																							groupItem.name
																								| initials
																								| slice: O:2
																						}}
																					</div>
																				</div>
																			</ng-container>
																		</div>
																	</div>
																</div>
																<div class="team-info">
																	<div class="team-name">
																		{{ groupItem.name }}
																	</div>
																	<div class="user-mail text-muted">
																		{{ item?.email }}
																	</div>
																</div>
																<div class="team-select">
																	<div class="custom-control custom-checkbox">
																		<input
																			type="checkbox"
																			class="custom-control-input"
																			id="chkTeam__{{ i }}"
																			name="chkTeam__{{ i }}"
																			[checked]="groupItem?.selected"
																			(click)="selectGroupEvent(groupItem)"
																		/>
																		<label
																			class="custom-control-label"
																			for="chkTeam__{{ i }}"
																		></label>
																	</div>
																</div>
															</div>
														</ng-container>
													</ng-scrollbar>
												</div>
											</div>
										</div>
									</ng-scrollbar>
								</ng-template>
							</ngb-panel>
						</ngb-accordion>
					</ng-container>
					<div class="assignee-footer">
						<button
							class="btn btn-outline-primary btn-prev mr-1"
							rippleEffect
							(click)="closeEvent()"
							id="btnAssigneeCancel"
							name="btnAssigneeCancel"
						>
							<span class="align-middle d-sm-inline-block d-none">{{ "UI.k_cancel" | translate }}</span>
						</button>
						<button
							type="submit"
							class="btn btn-primary btn-next"
							rippleEffect
							(click)="userSaveEvent()"
							id="btnAssigneeSave"
							name="btnAssigneeSave"
						>
							<span class="align-middle d-sm-inline-block d-none"
								>{{ "UI.k_submit" | translate }}
								<i class="fa-light fa-save align-middle mr-50 ml-0"></i>
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<!-- <div class="assignee-dashboard">
	<div class="assignee-dashboard-inner">
		<div class="assignee-head">
			<h5 class="modal-title">{{ "UI.k_assignee" | translate }}</h5>
			<button
				type="button"
				class="btn btn-icon btn-flat-secondary"
				rippleEffect
				(click)="closeEvent()"
			>
				<i class="far fa-times"></i>
			</button>
		</div>

		<div class="assignee-body">
			<ng-container>
				<ngb-accordion
					[closeOthers]="false"
					[activeIds]="['userSelPanel', 'teamsSelPanel']"
					[destroyOnHide]="false"
				>
					<ngb-panel
						id="userSelPanel"
						[cardClass]="'collapse-margin collapse-icon'"
						[perfectScrollbar]
					>
						<ng-template ngbPanelTitle>
							<span class="lead collapse-title">
								{{ "UI.k_users" | translate }}
							</span>
						</ng-template>
						<ng-template ngbPanelContent>
							<div class="assignee-card">
								<div class="user-sec">
									<div class="icon-search">
										<div class="form-group">
											<input
												type="text"
												class="form-control"
												id="txtUserSearch"
												name="txtUserSearch"
												placeholder="{{ 'UI.k_search' | translate }}"
												[(ngModel)]="this.userObject.searchUserText"
												(ngModelChange)="searchUserEvent($event)"
											/>
										</div>
									</div>
									<div class="user-list">
										<ng-container *ngFor="let item of this.userObject.userList">
											<div
												class="single-user"
												[ngClass]="item?.selected ? 'selected' : ''"
												(click)="selectUserItem(item)"
											>
												<div class="user-img">
													<img
														src="../../../../../assets/images/avatars/1.jpg"
														alt="User"
													/>
												</div>
												<div class="user-info">
													<div class="user-name">{{ item?.full_name }}</div>
													<div class="user-mail">
														{{ item?.email }} {{ item?.selected }}
													</div>
												</div>
												<div class="user-select">
													<div class="custom-control custom-checkbox">
														<input
															type="checkbox"
															class="custom-control-input"
															id="chkUser1"
															[checked]="item?.selected"
														/>
														<label
															class="custom-control-label"
															for="chkUser1"
														></label>
													</div>
												</div>
											</div>
										</ng-container>
									</div>
								</div>
							</div>
						</ng-template>
					</ngb-panel>
					<ngb-panel
						id="teamsSelPanel"
						[cardClass]="'collapse-margin collapse-icon'"
						[perfectScrollbar]
					>
						<ng-template ngbPanelTitle>
							<span class="lead collapse-title">
								{{ "UI.k_teams" | translate }}
							</span>
						</ng-template>
						<ng-template ngbPanelContent>
							<div class="assignee-card">
								<div class="team-sec">
									<div class="icon-search">
										<div class="form-group">
											<input
												type="text"
												class="form-control"
												id="txtTeamSearch"
												name="txtTeamSearch"
												placeholder="{{ 'UI.k_search' | translate }}"
												[(ngModel)]="this.userObject.searchGroupText"
												(ngModelChange)="searchTeamEvent($event)"
											/>
										</div>
									</div>
									<div class="team-list">
										<ng-container
											*ngFor="let groupItem of this.userObject.groupList"
										>
											<div
												class="single-team"
												[ngClass]="groupItem?.selected ? 'selected' : ''"
												(click)="selectGroupEvent(groupItem)"
											>
												<div class="team-img">
													<div class="d-flex align-items-center">
														<div class="avatar-group">
															<div
																placement="top"
																class="avatar pull-up"
																ngbTooltip="User 1"
																placement="top"
																container="body"
															>
																<img
																	class="media-object"
																	src="assets/images/portrait/small/avatar-s-5.jpg"
																	alt="Avatar"
																	height="32"
																	width="32"
																/>
															</div>
															<div
																placement="top"
																class="avatar pull-up"
																ngbTooltip="Elicia Rieske"
																placement="top"
																container="body"
															>
																<img
																	class="media-object"
																	src="assets/images/portrait/small/avatar-s-7.jpg"
																	alt="Avatar"
																	height="32"
																	width="32"
																/>
															</div>
															<div
																placement="top"
																class="avatar pull-up"
																ngbTooltip="Julee Rossignol"
																placement="top"
																container="body"
															>
																<img
																	class="media-object"
																	src="assets/images/portrait/small/avatar-s-10.jpg"
																	alt="Avatar"
																	height="32"
																	width="32"
																/>
															</div>
															<div
																placement="top"
																class="avatar pull-up"
																ngbTooltip="Darcey Nooner"
																placement="top"
																container="body"
															>
																<img
																	class="media-object"
																	src="assets/images/portrait/small/avatar-s-8.jpg"
																	alt="Avatar"
																	height="32"
																	width="32"
																/>
															</div>
															<div
																placement="top"
																class="avatar pull-up"
																ngbTooltip="Jenny Looper"
																placement="top"
																container="body"
															>
																<img
																	class="media-object"
																	src="assets/images/portrait/small/avatar-s-20.jpg"
																	alt="Avatar"
																	height="32"
																	width="32"
																/>
															</div>
														</div>
													</div>
												</div>
												<div class="team-info">
													<div class="team-name">
														{{ groupItem.name }} {{ groupItem.selected }}
													</div>
												</div>
												<div class="team-select">
													<div class="custom-control custom-checkbox">
														<input
															type="checkbox"
															class="custom-control-input"
															id="chkUser1"
															[checked]="groupItem?.selected"
														/>
														<label
															class="custom-control-label"
															for="chkUser1"
														></label>
													</div>
												</div>
											</div>
										</ng-container>
									</div>
								</div>
							</div>
						</ng-template>
					</ngb-panel>
				</ngb-accordion>
			</ng-container>
		</div>

		<div class="assignee-footer">
			<button
				class="btn btn-outline-primary btn-prev mr-1"
				rippleEffect
				(click)="closeEvent()"
			>
				<span class="align-middle d-sm-inline-block d-none">{{
					"UI.k_cancel" | translate
				}}</span>
			</button>
			<button
				type="submit"
				class="btn btn-primary btn-next"
				rippleEffect
				(click)="userSaveEvent()"
			>
				<span class="align-middle d-sm-inline-block d-none">{{
					"UI.k_save" | translate
				}}</span>
				<i data-feather="arrow-right" class="align-middle ml-sm-25 ml-0"></i>
			</button>
		</div>
	</div>
</div> -->
