import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { PermissionService } from "./service";

/**
 * usages on html: *appFeatureActive="['NAME_OF_THE_FEATURE_DEFINED']"
 * USAGE IN MENU COMPONENTS:
	* key defined on menu object - featureKeys: ["NAME_OF_THE_FEATURE_DEFINED"]
	* usages on html: *appFeatureActive="MENU_OBJ.NAME_OF_THE_KEY_DEFINED"
 */
@Directive({
	selector: "[appFeatureActive]",
})
export class IsFeatureActiveDirective {
	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private permissionManagerS: PermissionService
	) {}

	@Input() set appFeatureActive(feature_list: any) {
		if (feature_list) {
			this.isFeatureAvailable(feature_list);
		}
		else{
			this.viewContainer.createEmbeddedView(this.templateRef);
		}
	}

	private isFeatureAvailable(keyList: string[]) {
		const featureActive = this.permissionManagerS.is_feature_active(keyList);
		if (featureActive) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}
}
