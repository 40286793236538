<div class="slideout-content default-stepping">
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
		<div class="modal-dialog sidebar-md">
			<div class="modal-content add-new-user pt-0">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel" *ngIf = "!isEdit">
						{{ "UI.k_create_new_bot" | translate }}
					</h5>
					<h5 class="modal-title" id="exampleModalLabel" *ngIf = "isEdit" >
						{{ botConfigDate?.configTitle | translate }}
					</h5>
					<button
						id="btnClose"
						name="close"
						type="button"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						[attr.data-testid]="'btnClose'"
						(click)="closeCreateBotSidebar()"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body">
					<section class="stepping-form">
						<div id="botConfigurationStepper" class="bs-stepper horizontal-wizard-example">
							<div class="bs-stepper-header">
								<div class="step" data-target="#botConfiguration">
									<button class="step-trigger">
										<span class="bs-stepper-box">1</span>
										<span class="bs-stepper-label">
											<span class="bs-stepper-title">{{
												"UI.k_bot_configuration" | translate
											}}</span>
										</span>
									</button>
								</div>
								<div class="line">
									<i data-feather="chevron-right" class="font-medium-2"></i>
								</div>
								<div class="step" data-target="#botValidation">
									<button class="step-trigger">
										<span class="bs-stepper-box">2</span>
										<span class="bs-stepper-label">
											<span class="bs-stepper-title">{{
												"UI.k_bot_validation" | translate
											}}</span>
										</span>
									</button>
								</div>
								<div class="line">
									<i data-feather="chevron-right" class="font-medium-2"></i>
								</div>
							</div>
							<div class="bs-stepper-content">
								<div class="bs-stepper-inner">
									<form>
										<div id="botConfiguration" class="content">
											<form #botConfigureForm="ngForm" class="stepper-inner">
												<div class="stepper-body">
													<ng-scrollbar class="custom-scrollbar section-scrollbar"   [visibility]="'always'">
														<div class="stepper-main pt-0">
															<div class="row">
																<div class="col-md-12 mt-1 mb-1">
																	<div class="form-group">
																		<label
																			for="botName"
																			class="fw-400 title-color reg-font"
																			>{{ "UI.k_bot_name" | translate }}
																			<span class="required-field text-danger"
																				>&nbsp;*</span
																			></label
																		>
																		<input
																			type="text"
																			id="botName"
																			class="form-control"
																			placeholder="{{
																				'UI.k_enter_bot_name' | translate
																			}}"
																			name="botName"
																			[(ngModel)]="itemObj.name"
																			#BTNameRef="ngModel"
																			[class.error]="
																				!BTNameRef.valid &&
																				botConfigureForm.submitted
																			"
																			autocomplete="off"
																			required
																			[min]="3"
																			maxlength="40"
																			pattern="^[^\s]+(\s+[^\s]+)*$"
																			[attr.data-testid]="'botName'"
																			[disabled]="itemObj?.pre_defined == true"
																		/>
																				<dns-error-validation
																				[uiRef]="BTNameRef"
																				[formRef]="botConfigureForm"
																			></dns-error-validation>
																	</div>
																	<div
																		class="form-group"
																	>
																		<label class="fw-400 title-color reg-font" for="txtaDescription"
																			>{{ "UI.k_description" | translate
																			}}<span class="required-field text-danger"
																				>&nbsp;*</span
																			></label
																		>
																		<textarea
																			[(ngModel)]="itemObj.description"
																			#TDDescRef="ngModel"
																			type="text"
																			name="txtaDescription"
																			id="txtaDescription"
																			class="form-control"
																			placeholder="{{
																				'UI.k_description' | translate
																			}}"
																			pattern="^[^\s]+(\s+[^\s]+)*$"
																			[minlength]="3"
																			rows="2"
																			#description="ngModel"
																			[class.error]="
																				!TDDescRef.valid && botConfigureForm.submitted
																			"
																			required
																			[appWhiteSpaceCheck]="
																			itemObj.description
																			"
																		></textarea>
																		<dns-error-validation
																		[uiRef]="TDDescRef"
																		[formRef]="botConfigureForm"
																		[validationMsgConfig]="{
																			minlength:
																				'Error.err_min_val'
																				| translate
																					: {
																							minValue: 3
																					  }
																		}"
																	></dns-error-validation>
																	</div>
																</div>
																<div class="col-md-12 border-top">
																	<div class="topic-title mt-1">
																		<span class="fw-600 title-color d-block">{{
																			"UI.k_topics" | translate
																		}} </span>
																		<span class="sm-font text-muted">
																			{{
																				"UI.k_topics_description" | translate
																			}}
																		</span>
																	</div>
																	<div
																		class="topic-container border p-50 mt-1"
																		*ngFor="let obj of itemObj?.topics; let topicIndx = index" id = "topic_{{topicIndx}}"
																	>
																		<div
																			class="form-group topic-custom-dropdown"
																		>
																			<div
																				class="dropdown-title d-flex align-items-center justify-content-between"
																			>
																				<span class="fw-400 title-color">{{
																					"UI.k_topics_name" | translate
																				}}<span class="required-field text-danger"
																				>&nbsp;*</span
																			></span>
																				<span
																					class="fw-400 text-danger cursor-pointer"
																					id="removeBtn-{{topicIndx}}"
																					[attr.data-testid]="'removeBtn'"
																					(click)="removeTopicData(topicIndx)"
																					>{{
																						"UI.k_delete" | translate
																					}}</span
																				>
																			</div>
																			<input
																				type="text"
																				id="topicName-{{topicIndx}}"
																				class="form-control"
																				placeholder="{{
																					'UI.k_enter_bot_name' | translate
																				}}"
																				name="topicName-{{topicIndx}}"
																				[attr.data-testid]="'topicName'+ topicIndx"
																				[(ngModel)]="obj.topic_name"
																				(focus)="showDropdown(obj)"
																				(change)="hideDropdown($event, obj)"
																				#BTTopicsRef="ngModel"
																				[class.error]="
																					!BTTopicsRef.valid &&
																					botConfigureForm.submitted
																				"
																				autocomplete="off"
																				ngModel
																				required
																				maxlength="40"
																				pattern="^[^\s]+(\s+[^\s]+)*$"
																			/>
																			<span [ngClass]="{'custom-position': !BTTopicsRef.valid && botConfigureForm.submitted, 'dropdown_icon': BTTopicsRef.valid || !botConfigureForm.submitted}"
																				><i class="far fa-angle-down"></i
																			></span>
																			<dns-error-validation
																				[uiRef]="BTTopicsRef"
																				[formRef]="botConfigureForm"
																			></dns-error-validation>
																			
																			<ng-container *ngIf="obj?.isDropdownVisible">
																				<div
																			class="custom-dropdown-card border" 
																			id="topicDropdown-{{topicIndx}}"
																		>
																			<ng-scrollbar
																				class="custom-scrollbar section-scrollbar"
																			>
																				<div
																					class="card-title text-muted p-1 mb-0"
																				>
																					<span>{{
																						"UI.k_suggestions" | translate
																					}}</span>
																				</div>
																				<ul class="dropdown-list pl-0">
																					<li
																						class="p-1 cursor-pointer"
																						id="custom-dropdown-item"
																						[attr.data-testid]="'custom-dropdown-item'"
																						*ngFor="
																							let option of all_topic_options | sort:'name' | filter:obj.topic_name
																						"
																						(click)="
																							selectOption(obj, option?.name, topicIndx)
																						"
																					>
																						{{ option?.name }}
																					</li>
																				</ul>
																			</ng-scrollbar>
																		</div>
																			</ng-container>

																			
																		</div>
																		<ng-container
																			*ngFor="
																				let file of obj.attachment_data;
																				let fileIndx = index
																			"
																		>
																			<div
																				class="csv-upload-container mb-1 d-flex justify-content-between align-items-center p-50 border"
																			>
																				<div
																					class="csv-content d-flex gap-1 align-items-center"
																				>
																					<div class="text-center">
																						<div
																							class="avatar"
																							[ngClass]="
																								file.file_type == '.txt'
																									? 'bg-light-primary'
																									: file.file_type == '.pdf'
																									? 'bg-light-danger'
																									: file.file_type == '.xls' ||
																									file.file_type == '.xlsx'
																									? 'bg-light-success'
																									: file.file_type == '.doc' ||
																									file.file_type == '.docx'
																									? 'bg-light-info'
																									: 'bg-light-primary'
																							"
																						>
																							<div class="avatar-content" id="feather_size" *ngIf="file.attachment_id">
																								<i
																									class="{{ file.file_icon }} avatar-icon"
																									aria-hidden="true"
																								></i>
																							</div>
																							<div class="avatar-content" id="feather_size" *ngIf="!file.attachment_id">
																								<i class="fa-light fa-file-lines avatar-icon"
																								aria-hidden="true"></i>
																							</div>
																						</div>
																					</div>
																					<div class="csv-content">
																						<span>File</span>
																						<span class="ml-50">{{
																							file?.file_name
																						}}</span>
																					</div>
																					<div
																						class="pdf-details text-muted sm-font"
																					>
																						<span
																							>{{
																								file?.file_size / 1024
																									| number: "1.2-2"
																							}}KB</span
																						>
																						<span class="ml-50">{{
																							file?.file_date
																								| date: "d MMM y"
																						}}</span>
																					</div>
																				</div>
																				<div class="right-btn-sec d-flex csv-action">
																					<span
																						class="text-danger xl-font mr-50"
																						id="removeAttachedFile-{{fileIndx}}"
																						[attr.data-testid]="'removeAttachedFile'"
																						(click)="removeList(obj, fileIndx)"
																						ngbTooltip="{{ 'UI.k_delete' | translate }}"
																						><i class="far fa-times"></i
																					></span>
																					<span
																						*ngIf="file?.attachment_id"
																						class="text-danger xl-font"
																						id="btnDownloadAttachment-{{fileIndx}}"
																						(click)="downloadAttachment(file.file_path, file.zip_file_name)"
																						tooltipClass="fadeInAnimation"
																						placement="left"
																						ngbTooltip="{{ 'UI.k_download' | translate }}"
																					>
																						<i
																							class="fa-light fa-download avatar-icon cursor-pointer reg-font text-primary"
																						></i>
																					</span>
																					
																				</div>
																			</div>
																		</ng-container>

																		<div class="csv-upload-btn">
																			<button
																				type="button"
																				rippleeffect=""
																				class="btn btn-sm btn-flat-primary waves-effect ng-star-inserted"
																				id="attachmentBtn{{ topicIndx }}"
																				name="attachmentBtn{{ topicIndx }}"
																				(click)="file.click()"
																				data-testid="attachmentbtn{{ topicIndx }}"
																			>
																				<i class="fa-light fa-plus"></i>
																				{{ "UI.k_add_attachment" | translate }}
																			</button>
																			<input
																				type="file"
																				#file
																				name="attachedfile{{ topicIndx }}"
																				style="display: none"
																				id="attachedfile{{ topicIndx }}"
																				accept="{{ this.ALLOWED_TYPES }}"
																				(change)="
																				fileUpload($event, obj)
																				"
																			/>
																		</div>
																		<div
																			*ngIf="obj.err_msg"
																			class="invalid-form"
																		>
																			<small
																				class="form-text text-danger xs-font"
																				>{{ obj.err_msg }}
																			</small>
																		</div>
																	</div>
																	<div *ngIf = "isDummyContainer"
																		class="topic-container border cursor-pointer p-50 mt-75 mb-75 faded"
																		(click)="addTopicData()"
																	>
																		<div class="form-group topic-custom-dropdown">
																			<span class="fw-400 title-color">{{
																				"UI.k_topics_name" | translate
																			}}</span>
																			<input
																				type="text"
																				id="topicName"
																				class="form-control"
																				placeholder="{{
																					'UI.k_enter_bot_name' | translate
																				}}"
																				name="topicName"
																				required
																			/>

																			<span class="dropdown_icon"
																				><i class="far fa-angle-down"></i
																			></span>
																		</div>
																		<div class="csv-upload-btn">
																			<button
																				type="button"
																				rippleeffect=""
																				class="btn btn-sm btn-flat-primary waves-effect ng-star-inserted"
																				id="nextAttachmentBtn"
																				name="forward"
																				data-testid="nextAttachmentBtn"
																			>
																				<i class="fa-light fa-plus"></i
																				>{{ "UI.k_add_attachment" | translate }}
																			</button>
																		</div>
																	</div>
																	<div class="input" #inputElement></div>
																</div>
															</div>
														</div>
													</ng-scrollbar>
												</div>
												<div
													class="d-flex justify-content-end border-top py-1 px-2 stepper-btn"
												>
													<button
														[disabled]="disableSaveBtn"
														type="submit"
														class="btn btn-primary btn-next ml-1"
														rippleEffect
														id="btnSysNxt"
														[attr.data-testid]="'btnSysNxt'"
														name="btnSysNxt"
														(click)="submitBotConfiguration(botConfigureForm)"
													>
													<span class="spinner-border spinner-border-sm mr-50" *ngIf = "isLoading" role="status" aria-hidden="true"></span>
														<span class="align-middle d-sm-inline-block d-none">{{
															"UI.k_save_continue" | translate
														}}</span>
														<i class="fa-light fa-arrow-right align-middle ml-sm-25"></i>
													</button>
												</div>
											</form>
										</div>
										<div id="botValidation" class="content">
											<form #botValidation="ngForm" class="stepper-inner">
												<div class="stepper-body">
													<ng-scrollbar class="custom-scrollbar section-scrollbar" #scrollbar>
														<ng-container *ngIf="chartData.length === 0">
															<div class="no-chart-container mt-5">
																<div class="no-chart-box text-center">
																	<div class="no-chart-image">
																		<img
																			src="../../../../../assets/images/bots-configuration/infi-bot-logo.svg"
																			alt=""
																		/>
																	</div>
																	<div class="no-chart-content">
																		<div class="no-chart-title mt-50">
																			{{
																				"UI.k_test_me" | translate
																			}}
																		</div>
																		<div class="no-chart-description p-1">
																			<div class="my-2">
																				{{
																					"Message.msg_no_chat_message" | translate
																				}}
																			</div>
																			<div class="my-2">
																				{{
																					"Message.msg_Infi_long_weekends_message" | translate
																				}}
																			</div>
																			<div class="my-2">
																				{{
																					"Message.msg_Infi_paid_leaves_message" | translate
																				}}
																			</div>
																		</div>
																		
																	</div>
																</div>
															</div>
														</ng-container>
														<ng-container *ngIf="chartData.length > 0">
															<div class="m-1" *ngFor="let chat of chartData ; let i = index">
																<div class="chart-container" *ngIf="chat.senderId === currentUser?.profile?.profile_id">
																	<div class="user-chart-box d-flex gap-50">
																		<div class="chart-content">
																			<div
																				class="chart-header d-flex gap-50 justify-content-end"
																			>
																				<div
																					class="chart-time title-color xs-font"
																				>
																					{{ chat.time | date: "shortTime" }}
																				</div>
																				<div class="chart-user fw-500">
																					{{ chat.name }}
																				</div>
																			</div>
																			<div class="d-flex justify-content-end">
																				<span
																					class="chart-message sm-font p-50"
																					>{{ chat.message }}</span
																				>
																			</div>
																		</div>
																		<div class="user-profile">
																			<ng-container *ngIf="chat.avatarSrc">
																				<img src="{{ chat.avatarSrc }}" alt="avatar" height="36" width="36" />
																			</ng-container>
																			<ng-container *ngIf="!chat.avatarSrc">
																				<img src="assets/images/avatars/avatar.png" alt="avatar" height="36" width="36" />
																			</ng-container>
																		</div>
																		<div class="refresh-icon" *ngIf="chat?.extra_params?.error"
																			(click)= "retrySend(chat)">
																			<span class="text-danger"  
																			ngbTooltip="{{ 'UI.k_retry' | translate }}:{{chat?.extra_params?.error}}"
																			placement="left"
																			container="body"
																			><i class="far fa-sync"></i></span>
																		</div>
																	</div>
																</div>
																
																<ng-container >
																	<div class="bot-container d-flex gap-50" *ngIf="chat.senderId === -1">
																		<div class="bot-logo">
																			<img
																				src={{chat.avatarSrc}}
																				alt=""
																			/>
																		</div>
																		<div class="bot-content mt-25">
																			<div class="bot-content-header d-flex  justify-content-between">
																				<div class="d-flex gap-50">
																						<div class="title-color">{{
																							chat.name
																						}}</div>
																						<div class="xs-font">
																							{{ chat.time | date: "shortTime" }}
																						</div>
																				</div>
																				<div class="header-icon d-flex gap-1 mb-1" *ngIf = "chat?.isChartActionShow">
																						<button  
																						class="btn btn-xs sm-font btn-outline-danger" 
																						id="rejectBtn" 
																						data-testid="rejectBtn"
																						(click) = "onchatAction('wrong', chat)"
																						><span><i class="fal fa-times"></i>{{
																							"UI.k_wrong" | translate
																						}}</span>
																					</button>
																					<button  
																						class="btn btn-xs sm-font btn-outline-success" 
																						id="approve" 
																						data-testid="approve"
																						(click) = "onchatAction('correct',chat)"
																						><span><i class="fal fa-check"></i> {{
																							"UI.k_correct" | translate
																						}}</span>
																					</button>
																				</div>
																			</div>
																			<ng-container *ngIf="chat.message">
																				<div class="bot-content-body p-50 border">
																					<span>
																					{{ chat.message}}
																					</span>
																				</div>
																			</ng-container>
																			<!-- <div
																				*ngIf = "!chat.message && chat.error"
																				class="no-data-container no-data-container mt-1 d-flex gap-1 align-items-center mt-1 d-flex"
																			>
																				<div class="no-data-box p-1 d-flex gap-1 text-danger">
																					<div class="error-icon">
																						<span
																							><i
																								class="far fa-exclamation-triangle"
																							></i
																						></span>
																					</div>
																					<div class="error-data fw-400 word-wrap">
																						<span
																							><b>{{ "UI.k_errors" | translate }}:</b>
																							{{ chat?.err_msg ?  chat.err_msg : ("Error.err_something_went_wrong_try_again" | translate)}}</span
																						>
																					</div>
																				</div>
																				<div class="refresh-icon">
																					<span class="text-primary"><i class="far fa-sync"></i></span>
																				</div>
																			</div> -->
																		</div>
																	</div>
																</ng-container>
															</div>
															
														</ng-container>
														<ng-container *ngIf="showLoader">
															<div class="bot-container d-flex ml-1 gap-50">
																<div class="bot-logo">
																	<img
																		src="assets/images/workspace/infi-logo.svg"
																		alt=""
																	/>
																</div>
																<div class="bot-content">
																	<div class="bot-content-header d-flex align-items-center justify-content-between">
																		<div class="d-flex gap-50 mt-50">
																			<span class="sm-font fw-400 "><div class="dots-7"></div></span>
																		</div>
																	</div>
																</div>
															</div>
														</ng-container>
													</ng-scrollbar>
												</div>
												<div
													class="cart-action-container d-flex align-items-center p-1 border-top"
												>
													<div class="chart-action-box row m-0">
														<div
															class="form-group col-md-10 new-thread-input pl-0 pr-0 mb-0"
														>
															<input
																type="text"
																class="form-control lg-form-control"
																(keyup.enter)="updateChat($event)"
																placeholder="Start a new message"
																id="txtNewThread"
																name="txtNewThread"
																[(ngModel)]="chatMessage"
																autocomplete="off"
															/>
														</div>

														<div class="input-group-append mx-50">
															<span class="input-group-text p-0">
																<label
																	for="attachEmoji"
																	class="attachment-icon mb-0 text-primary xl-font mx-25"
																>
																	<i class="fa-light fa-face-smile"></i>
																	<input type="file" id="attachEmoji" hidden />
																</label>
																<label
																	for="attachFile"
																	class="attachment-icon mb-0 text-primary xl-font mx-25"
																>
																	<i class="fa-light fa-paperclip"></i>
																	<input type="file" id="attachFile" hidden />
																</label>
																<label
																	for=""
																	class="attachment-icon mb-0 text-primary xl-font mx-25"
																>
																	<i class="fa-light fa-image"></i>
																	<input type="file" id="attachImg" hidden />
																</label>
															</span>
														</div>
													</div>
													<div class="chart-btn">
														<button
															type="button"
															class="btn p-50 input-add-chart"
															(click)="updateChat($event)"
															rippleEffect
														>
															<i class="fa-light fa-paper-plane-top mr-0"></i>
														</button>
													</div>
												</div>

												<div
													class="footer-content border-top d-flex align-items-center justify-content-between"
												>
													<div class="chat-count text-primary ml-1 p-50" [ngClass]="{'transparent-sucess': count >= totalCount}" >
														<span>{{ count }}/{{ totalCount }} <span>Validated</span></span>
													</div>
													<div class="d-flex justify-content-end py-1 px-2">
														<button
															type="button"
															(click)="backBotConfigurationForm()"
															class="btn btn-outline-tertiary btn-prev"
															rippleEffect
														>
															<i
																class="fa-light fa-arrow-left align-middle mr-50 mr-0"
															></i>
															<span class="align-middle d-sm-inline-block d-none">
																{{ "UI.k_back" | translate }}
															</span>
														</button>
														<!-- <button
															type="submit"
															class="btn btn-primary btn-next ml-1"
															rippleEffect
															id="btnSysNxt"
															name="btnSysNxt"
															[disabled] = "count < totalCount"
															(click)="submitBotConfigurationData()"
														>
															<span class="align-middle d-sm-inline-block d-none">{{
																"UI.k_submit" | translate
															}}</span>
														</button> -->
														<button
															type="submit"
															class="btn btn-primary btn-next ml-1"
															rippleEffect
															id="btnSysNxt"
															name="btnSysNxt"
															[disabled] = "count < totalCount"
															(click)="closeCreateBotSidebar()"
														>
															<span class="align-middle d-sm-inline-block d-none">{{
																"UI.k_ok" | translate
															}}</span>
														</button>
													</div>
												</div>
											</form>
										</div>
									</form>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>
</div>
