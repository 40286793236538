import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-premium-plan-skeleton',
  templateUrl: './premium-plan-skeleton.component.html',
  styleUrls: ['./premium-plan-skeleton.component.scss']
})
export class PremiumPlanSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.generateFake(3);
  }

  generateFake(count: number): Array<number> {
        const indexes = [];
        for (let i = 0; i < count; i++) {
            indexes.push(i);
        }
        return indexes;
    }


}
