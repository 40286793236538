<div class="slideout-content default-slideout">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog sidebar-md">
            <div *ngIf="request_data != undefined" class="modal-content pt-0">
                <div class="modal-header">
                    <h5 class="modal-title" id="closeReq">
                        {{ "UI.k_close_request" | translate }}
                    </h5>
                        <button
                        type="button"
                        id="btnClose"
                        name="btnClose"
                        [attr.data-testid]="'btnClose'"
                        class="btn btn-flat-secondary btn-icon"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="toggleSidebar('process-detail-request-close')"
                    >
                        <i class="fa-light fa-xmark"></i>
                    </button>
                </div>
                <form #closeRequestForm="ngForm" class="modal-body">
                    <div class="flex-auto-height">
                        <ng-scrollbar class="custom-scrollbar section-scrollbar">
                            <div class="modal-body-inner p-1">
                                <div class="card border border-rounded">
                                    <div class="summary-info p-1">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex justify-content-start align-items-center">
                                                <div>
                                                    <span class="fw-500 reg-font primary-color">{{
                                                        this.request_data?.display_id
                                                    }}</span>
                                                </div>
                                                <div class="rounded icon-class mx-50">
                                                    <div class="close-card-config"
                                                    ngbTooltip ="{{request_data_copy?.basic_info?.request_source?.name }}"
                                                    placement="bottom" id="btnGlobe">
                                                        <i
                                                            class="fa-light fa-{{
                                                                sourceIconMap[
                                                                    request_data?.basic_info?.request_source?.id
                                                                ]
                                                            }}"
                                                        ></i>
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="request_data?.is_parent_request"
                                                    class="rounded"
                                                    id="btnShare"
                                                >
                                                    <div class="cloass-card-config">
                                                        <i class="fa-light fa-share-nodes"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="mx-50"
                                                *ngIf="this.request_data?.basic_info?.priority?.name"
                                            >
                                                <!-- <div *ngIf="this.request_data?.basic_info?.priority?.name"> -->
                                                <div
                                                    class="badge badge-light-{{
                                                        request_data?.basic_info?.priority?.color
                                                    }}"
                                                >
                                                    <!-- <span class="sm-font fw-500">Priority:</span> -->
                                                    {{
                                                        this.request_data?.basic_info?.priority?.display_key
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex justify-content-start align-items-center">
                                                <div *ngIf="request_data?.basic_info?.impact_service_tree_path">
                                                    <span class="sm-font"
                                                        >{{ "UI.k_service" | translate }} :
                                                    </span>
                                                    <span
                                                        class="sm-font"
                                                        title="{{ request_data?.basic_info?.impact_service_tree_path }}"
                                                    >
                                                        {{ request_data?.basic_info?.impact_service_tree_path | slice: 0:19
                                                        }}{{
                                                            request_data?.basic_info?.impact_service_tree_path?.length > 19
                                                                ? ".."
                                                                : ""
                                                        }}</span
                                                    >
                                                </div>

                                                <div *ngIf="request_data?.asset_id && request_data?.cmdb_id">
                                                    <span class="sm-font"
                                                        >{{ "UI.k_asset_id" | translate }} :
                                                    </span>
                                                    <span
                                                        *ngIf="asset_permissions?.view && request_data?.is_asset_disabled"
                                                    >
                                                        <a
                                                            href="/it-asset/asset/{{ request_data?.cmdb_id }}"
                                                            target="_blank"
                                                            class="sm-font"
                                                            title="{{ request_data?.asset_id }}"
                                                        >
                                                            {{ request_data?.asset_id | slice: 0:19
                                                            }}{{
                                                                request_data?.asset_id?.length > 19 ? ".." : ""
                                                            }}</a
                                                        >
                                                    </span>
                                                    <span
                                                        *ngIf="!asset_permissions?.view || !request_data?.is_asset_disabled"
                                                        class="sm-font"
                                                        title="{{ request_data?.asset_id }}"
                                                    >
                                                        {{ request_data?.asset_id | slice: 0:19
                                                        }}{{
                                                            request_data?.asset_id?.length > 19 ? ".." : ""
                                                        }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="my-50 d-flex justify-content-start align-items-center">
                                            <div class="avatar avatar-xs mr-50">
                                                <!-- <ng-container > -->
                                                <span
                                                    class="avatar-content" *ngIf="!request_data?.requester_profile"
                                                    [ngStyle]="{
                                                        'background-color':
                                                            request_data?.requester_avatar_color != undefined
                                                                ? request_data?.requester_avatar_color
                                                                : '#f59518'
                                                    }"
                                                    >{{
                                                        this.request_data?.requester?.full_name
                                                            | initials
                                                            | slice: 0:2
                                                    }}</span
                                                >
                                                <!-- </ng-container> -->
                                                <ng-container *ngIf="request_data?.requester_profile">
                                                    <img
                                                        class="avatar-content"
                                                        class=""
                                                        [src]="request_data?.requester_profile"
                                                        alt=""
                                                    />
                                                </ng-container>
                                            </div>

                                            <span
                                                class="sm-font fw-500 title-color summary-ellipse"
                                                ngbTooltip="{{ request_data?.basic_info?.summary  | slice: 0:200 }}"
                                                placement="bottom"
                                                >{{ request_data?.basic_info?.summary | slice: 0:150 }}
                                                {{
                                                    request_data?.basic_info?.summary.length > 150 ? ".." : ""
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row p-0" >
                                    <div class="form-group col-xs-6 col-md-6 col-xl-6 col-lg-6">
                                        <app-status-picker
                                            [inputConfig]="{ isTextMode: true, stateOptionsMap:  options?.state_id_map}"
                                            [options]="close_status"
                                            (setStatus)="onStatusChange($event)"
                                            [value]="request_data.basic_info?.status?.id"
                                        ></app-status-picker>
                                    </div>

                                    <div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
                                        <div class="form-group">
                                            <label for="txtReqCloserInfo"
                                                >{{ "UI.k_close_request" | translate }}
                                                <span class="required-field">&nbsp;*</span>
                                            </label>
                                            <div>
                                                <quill-editor
                                                    [(ngModel)]="request_data.close_info.closure_note"
                                                    id="smartQuillReqClosure"
                                                    name="smartQuillReqClosure"
                                                    (onContentChanged)="getClosureNote($event)"
                                                    (onEditorCreated)="editorCreated($event)"
                                                    [trimOnValidation]="true"
                                                    #TDReqClosureNoteRef
                                                    [appQuillValidator]="TDReqClosureNoteRef"
                                                    [appQuillForm]="closeRequestForm"
                                                    [minLength]="3"
                                                    [required]="true"
                                                    [class.error]="
                                                        TDReqClosureNoteRef?.errors &&
                                                        closeRequestForm.submitted
                                                    "
                                                    placeholder="{{ 'UI.k_closure_note' | translate }}"
                                                >
                                                    <div id="quill-toolbar" quill-editor-toolbar>
                                                        <div class="">
                                                            <span class="ql-formats">
                                                                <button class="ql-bold" title="{{ 'UI.k_bold' | translate }}"></button>
                                                                <button class="ql-italic" title="{{ 'UI.k_italic' | translate }}"></button>
                                                                <button
                                                                    class="ql-underline"
                                                                    title="{{ 'UI.k_underline' | translate }}"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <select class="ql-align" title="{{ 'UI.k_alignment' | translate }}">
                                                                    <option selected></option>
                                                                    <option value="center"></option>
                                                                    <option value="right"></option>
                                                                    <option value="justify"></option>
                                                                </select>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    class="ql-list"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    class="ql-link"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                                <button
                                                                    class="ql-image"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                                <button
                                                                    class="ql-video"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    class="ql-formula"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                                <button
                                                                    class="ql-code-block"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    class="ql-clean"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    id="insert-table"
                                                                    type="button"
                                                                    (click)="addNewtable($event)"
                                                                >
                                                                    Table
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </quill-editor>
                                            </div>
                                            
                                            <div
                                                class="error"
                                                *ngIf="
                                                    closeRequestForm.submitted &&
                                                    TDReqClosureNoteRef?.errors
                                                "
                                            >
                                                <small
                                                    *ngIf="TDReqClosureNoteRef?.errors?.requiredError?.empty"
                                                >
                                                    {{ "UI.k_field_required" | translate }}
                                                </small>
                                                <small *ngIf="TDReqClosureNoteRef?.errors?.minLengthError">
                                                    {{ "Error.err_closure_note_min" | translate }}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6 col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label for="txtReqClosedBy"
                                                >{{ "UI.k_closedby" | translate }}
                                            </label>
                                            <ng-select
                                                [items]="options.users"
                                                [(ngModel)]="request_data.close_info.closed_by"
                                                bindLabel="full_name"
                                                bindValue="profile_id"
                                                name="txtReqClosedBy"
                                                id="txtReqClosedBy"
                                                placeholder="{{ 'UI.k_select_users' | translate }}"
                                                [hideSelected]="true"
                                            >
                                                <ng-template
                                                    ng-option-tmp
                                                    let-item="item"
                                                    let-index="index"
                                                    let-search="searchTerm"
                                                >
                                                    <span class="d-flex">
                                                        <div
                                                            class="avatar avatar-sm mr-1 ml-0"
                                                        >
                                                        <span class="avatar-content"
                                                            *ngIf="!item?.profile_image" 
                                                            [ngStyle]="{
                                                                'background-color': item.avatar_color
                                                                    ? item?.avatar_color
                                                                    : '#f59518'
                                                            }"
                                                            >
                                                                {{ item.full_name | initials }}
                                                        </span>
                                                            <span class="avatar-content"
                                                                *ngIf="item?.profile_image" >
                                                            <img
                                                              class="rounded-circle"
                                                              src="{{
                                                                item?.profile_image
                                                              }}"
                                                              alt="User"
                                                            />
                                                            </span>
                                                        </div>
                                                        <div class="cell-line-height">
                                                            <p
                                                                class="font-medium-1 font-weight-bold line-height-1 mb-0"
                                                            >
                                                                {{ item.full_name }}
                                                            </p>
                                                            <span class="text-muted font-small-2">
                                                                {{ item.email }}</span
                                                            >
                                                        </div>
                                                    </span>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-md-6 col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label for="txtReqCloseType"
                                                >{{ "UI.k_close_type" | translate }}
                                            </label>
                                            <ng-select
                                                [items]="options.close_type"
                                                [hideSelected]="true"
                                                bindLabel="name"
                                                bindValue="id"
                                                (change)="
                                                    updateFieldName(
                                                        request_data.basic_info?.closed_type?.id,
                                                        'id',
                                                        'closed_type',
                                                        request_data.basic_info?.closed_type?.name,
                                                        'name'
                                                    )
                                                "
                                                [(ngModel)]="request_data.close_info.closed_type.id"
                                                name="txtReqCloseType"
                                                id="txtReqCloseType"
                                                placeholder="{{ 'UI.k_sel_close_type' | translate }}"
                                            >
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6 col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label for="txtReqActlClsrDate"
                                                >{{ "UI.k_actual_closure_date" | translate }}
                                            </label>
                                            <ng2-flatpickr
                                                [config]="dateTimeOptions"
                                                name="txtReqActlClsrDate"
                                                [setDate]="request_data?.actual_closure_date ? request_data?.actual_closure_date : request_data['actual_closure_date'] = dateTimeOptions?.defaultDate"
                                                id="txtReqActlClsrDate"
                                                placeholder="{{ 'UI.k_sel_date' | translate }}"
                                                [(ngModel)]="request_data.actual_closure_date"
                                            ></ng2-flatpickr>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6 col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label for="txtReqclsrAgrdTime"
                                                >{{ "UI.k_agreed_closure_date" | translate }}
                                            </label>
                                            <ng2-flatpickr
                                                [config]="agreedDateTimeOptions"
                                                name="txtReqclsrAgrdTime"
                                                [setDate]="request_data.agreed_closure_date"
                                                id="txtReqclsrAgrdTime"
                                                placeholder="{{ 'UI.k_sel_date' | translate }}"
                                                [(ngModel)]="request_data.agreed_closure_date"
                                            ></ng2-flatpickr>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
                                        <app-dns-custom-field
                                            [formRef]="closeRequestForm"
                                            placeholder="closure"
                                            [data]="request_data?.custom_field_data"
                                            [config]="req_extra_info?.custom_field_config"
                                        ></app-dns-custom-field>
                                    </div>
                                </div>
                            </div>
                        </ng-scrollbar>
                        <div class="modal-btn-group flex-initial border-top">
                            <div class="d-flex justify-content-end stepper-btn flex-initial">
                                <button
                                    class="btn btn-sm btn-primary btn-next ml-1"
                                    id="btnComplete"
                                    [attr.data-testid]="'btnComplete'"
                                    rippleEffect
                                    type="submit"
                                    [disabled]="is_submit_disabled"
                                    (click)="onCloseSubmit(closeRequestForm, request_data)"
                                >
                                    <span class="align-middle d-sm-inline-block d-none">
                                        {{ "UI.k_complete" | translate }}</span
                                    >
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
