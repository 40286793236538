import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "app/common/guards/auth.guards";
import { SupportHomeComponent } from "./components/support-home/support-home.component";
import { PartnersListComponent } from "./components/partners-list/partners-list.component";
import { SupportAuditComponent } from "./components/support-audit/support-audit.component";
import { PartnerOperatorsComponent } from "./components/partner-operators/partner-operators.component";
import { OrganizationInvoicesComponent } from "./components/organization-invoices/organization-invoices.component";

const routes: Routes = [
	{
		path: "home",
		component: SupportHomeComponent,
		canActivate: [AuthGuard],
		data: {
			module: ["infinity_support"],
			keyList: ["add", "edit", "delete", "view"],
		},
	},
	{
		path: "audit",
		component: SupportAuditComponent,
		canActivate: [AuthGuard],
		data: {
			module: ["infinity_support"],
			keyList: ["add", "edit", "delete", "view"],
		},
	},
	{
		path: "partner",
		component: PartnersListComponent,
		canActivate: [AuthGuard],
		data: {
			module: ["infinity_partner"],
			keyList: ["add", "edit", "delete", "view"],
		},
	},
	{
		path: "invoices",
		component: OrganizationInvoicesComponent,
		canActivate: [AuthGuard],
		data: {
			module: ["infinity_support"],
			keyList: ["add", "edit", "delete", "view"],
		},
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class SupportPortalRoutingModule {}
