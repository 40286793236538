/*
Used for define the const global variable for DNS
*/

export class GlobalConstants {
	public static dnsPermissions: string;
	public static dnsLavModules: any;
	public static dnsFlags: any;

	public static globalSearch = [
		{
			icon: "user",
			name: "Users",
			handle: "/users",
			sidebar: false,
			shortKey: "g u",
		},
		{
			icon: "user",
			name: "Requester",
			handle: "/requester",
			sidebar: false,
			shortKey: "g r",
		},
		{
			icon: "ticket",
			name: "Knowledge Base",
			handle: "/kb/all",
			shortKey: "g k",
			sidebar: false,
			module_key: "BASE",
			keys: ["KNOWLEDGE_BASE"],
		},
		{
			icon: "tachometer-alt-slowest",
			name: "Dashboard",
			handle: "/dashboard",
			sidebar: false,
			shortKey: "g d",
			module_key: "BASE",
			keys: ["DASHBOARD"],
		},
		{
			icon: "tachometer-alt-slowest",
			name: "Dashboard Add",
			handle: "/dashboard/add",
			sidebar: false,
			shortKey: "f d",
			module_key: "BASE",
			keys: ["DASHBOARD"],
		},
		{
			icon: "ticket",
			name: "Incident",
			handle: "/incident_view",
			shortKey: "g i",
			sidebar: false,
			module_key: "SERVICEDESK",
			keys: ["INCIDENT_MANAGEMENT"],
		},
		{
			icon: "ticket",
			name: "Incident Add",
			handle: "/incident_view/add",
			shortKey: "f i",
			sidebar: false,
			module_key: "SERVICEDESK",
			keys: ["INCIDENT_MANAGEMENT"],
		},
		{
			icon: "ticket",
			name: "Events",
			handle: "/events",
			shortKey: "g e",
			sidebar: false,
			module_key: "MONITORING",
			keys: ["EVENT_MANAGEMENT"],
		},
		{
			icon: "ticket",
			name: "Asset",
			handle: "/it-asset",
			shortKey: "g a",
			sidebar: false,
			module_key: "ASSET",
			keys: ["ASSET_MANAGEMENT"],
		},
		{
			icon: "microchip",
			name: "View Agents",
			handle: "/agent/datacollector",
			sidebar: false,
			shortKey: "g c",
			module_key: "AGENT",
			keys: ["AGENT"],
		},
		{
			icon: "microchip",
			name: "View Agents",
			handle: "/agent/inventory",
			sidebar: false,
			shortKey: "g l",
			module_key: "AGENT",
			keys: ["DESKTOP_AGENT"],
		},
		{
			icon: "globe",
			name: "Schedule discovery",
			handle: "/discovery",
			sidebar: false,
			shortKey: "g j",
			module_key: "MONITORING",
			keys: ["DISCOVERY"],
		},
		{
			icon: "calendar-day",
			name: "My Leaves",
			handle: "/my-leaves",
			sidebar: false,
			shortKey: "g z",
			module_key: "BASE",
			keys: ["MY_LEAVE"],
		},
		{
			name: "Teams",
			handle: "/teams",
			sidebar: false,
			shortKey: "g t",
			module_key: "BASE",
			keys: ["TEAMS_CONFIG"],
		},
		{
			icon: "tag",
			name: "Tags",
			handle: "/tags",
			sidebar: false,
			shortKey: "g x",
			module_key: "BASE",
			keys: ["TAG_MANAGEMENT"],
		},
		{
			icon: "global",
			name: "Configuration Parameters",
			handle: "/configuration-parameters",
			sidebar: false,
			shortKey: "g p",
			module_key: "NCCM",
			keys: ["CONFIGURATION_DOWNLOAD"],
		},
		{
			icon: "folder arrow down",
			name: "Download Jobs",
			handle: "/download-job",
			sidebar: false,
			shortKey: "d j",
			module_key: "NCCM",
			keys: ["CONFIGURATION_DOWNLOAD"],
		},
		{
		    icon:"fa-light fa-gears",
		    name:"Baseline Scheduler", 
		    handle: "/baseline-scheduler",
		    sidebar:false,
		    shortKey: 'b s',
		    module_key: 'NCCM',
				keys: ['CONFIGURATION_DOWNLOAD']
		},
		{
			icon: "stopwatch",
			name: "Threshold",
			handle: "/threshold",
			sidebar: false,
			shortKey: "g n",
			module_key: "MONITORING",
			keys: ["THRESHOLDS_CONFIG"],
		},

		{
			icon: "",
			name: "Add Requester",
			handle: "/requester",
			sidebar: true,
			shortKey: "f r",
		},
		{
			icon: "file",
			name: "Service Catalogue",
			handle: "/service-catalogue",
			sidebar: false,
			shortKey: "g s",
			module_key: "SERVICEDESK",
			keys: ["SERVICE_CATALOGUE"],
		},
		{
			icon: "file",
			name: "Workflow",
			handle: "/workflow",
			sidebar: false,
			shortKey: "g w",
			module_key: "BASE",
			keys: ["WORKFLOW"],
		},
		{
			icon: "file",
			name: "Report",
			handle: "/report",
			sidebar: false,
			shortKey: "g g",
			module_key: "BASE",
			keys: ["REPORT"],
		},
		{
			icon: "fa-magnifying-glass",
			name: "Diagnosis Tools",
			handle: "/diagnosis-tools",
			sidebar: false,
			shortKey: "d t",
			module_key: "MONITORING",
		}
		// {
		// 	icon: "",
		// 	name: "Topology",
		// 	handle: "/topology",
		// 	sidebar: false,
		// 	shortKey: "g y",
		// },
	];
}
