import { Component, OnInit } from '@angular/core';
// import { MSPService } from 'app/msp-portal/services/msp.service';
import { ProductPlansWithPrices, Product, PlanWithPrices, UnitOfMeasure } from 'app/admin/models';
// import { ComputeService } from 'app/admin/service';

@Component({
  selector: 'app-organization-product-details',
  templateUrl: './organization-product-details.component.html',
  styleUrls: ['./organization-product-details.component.scss']
})
export class OrganizationProductDetailsComponent implements OnInit {
	orgData:any = {}
	productPlansWithPricesRaw: ProductPlansWithPrices;
	infraonProduct: Product;
	pricingType = "year"
	pricingTypeData: PlanWithPrices[];
	unitData: UnitOfMeasure[];
	features: any = []

  	constructor(
		// private _service: MSPService,
		// private _compute: ComputeService
	) { 
	}

  	ngOnInit(): void {
    this.orgData = {
			name: "Alex",
			org_id: "#ORG3426612",
			website: "Federalbank.infraon.io",
			organization_name: {
				name: "Faderal Bank",
				profile_image: "../../../../assets/images/msp/image 21.svg",
				avatar_color: "#f59518",
			},
			licence: {
				name: "Infraon Assets",
				key: "asset",
				units: ["user", "asset"],
				default_products: ["Infy_Ats_Pro_1"],
				icon: "assets/images/products/product-asset.svg",
			},
			plan: "Professional Plan",
			msp_user: {
				max_count: 500,
				count: 450,
				status:'danger'
			},
			msp_it_asset: {
				max_count: 500,
				count: 400,
				status:'warning'
			},
			msp_non_it_asset: {
				max_count: 500,
				count: 200,
				status:'success'
			},
			msp_availability_monitoring: {
				max_count: 500,
				count: 300,
				status:'success'
			},
			msp_network_monitoring: {
				max_count: 500,
				count: 200,
				status:'success'
			},
			assigned_msp_user:[
				{name:"Theresa Webb",email:'theresa@gmail.com',profile_image:'../../../../assets/images/avatars/1.png'},
				{name:"Wade Warren",email:'Wade@gmail.com',profile_image:'../../../../assets/images/avatars/2.png'},
				{name:"Alex Warren",email:'alex@gmail.com',profile_image:'../../../../assets/images/avatars/3.png'},
				{name:"Alex Warren",email:'alex@gmail.com',profile_image:''},
				{name:"Alex Warren",email:'alex@gmail.com',profile_image:'../../../../assets/images/avatars/4.png'},
				{name:"Alex Warren",email:'alex@gmail.com',profile_image:'../../../../assets/images/avatars/5.png'},
				{name:"Alex Warren",email:'alex@gmail.com',profile_image:'../../../../assets/images/avatars/6.png'},
				{name:"Alex Warren",email:'alex@gmail.com',profile_image:'../../../../assets/images/avatars/7.png'},
				{name:"Theresa Webb",email:'theresa@gmail.com',profile_image:'../../../../assets/images/avatars/1.png'},
			]
		}

		this.features = [
			"Hardware Asset Management",
			"Spare Management",
			"Assets Tracking",
			"Asset Lifecycle Management",
			"Maintenance",
			"Custom State",
			"Access Control",
			"Tags",
			"User Group",
			"Assets Dashboard",
			"Assets Report",
			"Onboarding Assistance"
		  ]

		// this._service.getProductPlans({ productId: "Infy_ITSM_Pro_1" }).subscribe({
		// 	next: (response: any) => {
		// 		console.log("response", response)
		// 		this.productPlansWithPricesRaw = <ProductPlansWithPrices>response;
		// 		this.infraonProduct = this.productPlansWithPricesRaw.product;
		// 		for (let mapped of this.productPlansWithPricesRaw.mapped) {
		// 			if (mapped.data.length > 0 && mapped.period === this.pricingType)
		// 				this.pricingTypeData = mapped.data;
		// 		}
		// 		console.log("this.priceTypeData", this.pricingTypeData)
		// 		this.unitData = this._compute.unitsData(this.infraonProduct?.unit_of_measure);
		// 	},
		// 	error: (e: any) => {
		// 		this.unitData = [];
		// 		console.error(e);
		// 	},
		// });
  }

}
