import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ColumnMode, DatatableComponent, SelectionType } from "@swimlane/ngx-datatable";
import { SearchCountryField, CountryISO, PhoneNumberFormat } from "ngx-intl-tel-input";
import { GridNameDesignComponent } from "../grid-name-design/grid-name-design.component";

@Component({
	selector: "dns-server-grid",
	templateUrl: "./dns-grid.component.html",
	styleUrls: ["./dns-grid.component.scss"],
})
export class DnsGridComponent implements OnInit {
	/*
	 * Mandatory fields
	 * rows: [],
	 * columns: []
	 * count: number
	 * */
	SelectionType = SelectionType;
	public show = false;
	public colorArray = [
		"badge-light-info",
		"badge-light-danger",
		"badge-light-primary",
		"badge-light-success",
		"badge-light-warning",
		"badge-light-secondary",
	];
	@Input() mselectMenu: Array<any>;
	@Input() rows: Array<any>;
	@Input() columns: Array<any>;
	@Input() options: any;
	@Input() selectionType: SelectionType;
	@Input() columnMode = ColumnMode.force;
	@Input() pageNumber = 0;
	@Input() limit = 10;
	@Input() count = 0;
	@Input() headerHeight = 40;
	@Input() footerHeight = 60;
	@Input() permissions = {};
	@Input() dataLoading = undefined;
	@Output() page: EventEmitter<any> = new EventEmitter<any>();
	@Output() sort: EventEmitter<any> = new EventEmitter<any>();
	@Output() setlimit: EventEmitter<any> = new EventEmitter<any>();
	@Output() editCallback: EventEmitter<any> = new EventEmitter<any>();
	@Output() onSelectCheckbox: EventEmitter<any> = new EventEmitter<any>();
	@Output() onCellClick: EventEmitter<any> = new EventEmitter<any>();
	@Output() resourceCellClick: EventEmitter<any> = new EventEmitter<any>();
	@Input() externalPaging = true;
	@Input() externalSorting = true;
	@Input() loadingIndicator = true;
	@Input() scrollbarH = false;
	@Input() hideFooter = false;
	@Input() selectedRow;
	@Input() rowHeight = "auto";
	@Input() model;
	@Input() virtualization = true;
	@Input() offset = 0;
	@Input() isEditable = false;
	@Input() selected: any = [];
	@ViewChild("dataTable") dataTable: DatatableComponent;
	editData: any;
	@Input() emptyMessage = "Data not available.";
	@Input() emptyData: any;
	@Input() showData = true;
	@Input() filterData = false;
	@Input("textLength") readMoreLength = 350; // used to make string to limit to given length & more/less action will be provided
	defaultMinLength = 2; // used for inline edit validation of min character for text field
	defaultMaxLength = 1000; // used for inline edit validation of max character for text field
	CountryISO = CountryISO;
	PhoneNumberFormat = PhoneNumberFormat;
	SearchCountryField = SearchCountryField;
	private latestWidth: number;
	@Input() preferredCountries: CountryISO[] = [
		CountryISO.UnitedStates,
		CountryISO.UnitedKingdom,
		CountryISO.Australia,
		CountryISO.NewZealand,
		CountryISO.Germany,
		CountryISO.Spain,
		CountryISO.India,
		CountryISO.Japan,
		CountryISO.China,
		CountryISO.Singapore,
		CountryISO.UnitedArabEmirates,
		CountryISO.Brazil,
	];
	separateDialCode = true;
	formCompModel: any;
	is_editing = false;
	public pageLimit = 10;
	@Input() hideSelectAllCheckbox = false;
	@Input() selectBoxColumnLabel: any;
	constructor(private _translateService: TranslateService, private router: Router) {}

	ngOnInit(): void {
		for (const col of this.columns) {
			if (col.width === 0 || col.width === null || col.width === undefined) {
				col.width = 220;
			}
		}
		this.editData = {};
		if (this.selected?.length > 0) {
			this.show = true;
			this.pageLimit = this.limit;
		}
		// this.GridNameDesign.onclick();
	}

	ngAfterContentChecked() {
		for (const col of this.columns) {
			if (col.width === 0 || col.width === null || col.width === undefined) {
				col.width = 220;
			}
		}
		if (this.dataTable && this.dataTable.element.clientWidth !== this.latestWidth) {
			this.latestWidth = this.dataTable.element.clientWidth;
			this.dataTable.recalculate();
		}
	}
	onSelect({ selected }) {
		this.selected.splice(0, this.selected?.length);
		this.selected.push(...selected);
		this.onSelectCheckbox.emit(this.selected);
		if (this.selected?.length > 0) {
			this.show = true;
		}
	}

	onClick(clickable, listener, row, col, rIndex): void {
		if (clickable) {
			listener(row, col, rIndex);
		}
	}

	toggle() {
		this.show = false;
		this.selected = [];
		this.onSelectCheckbox.emit([]);
	}

	redirectLink(col, row) {
		if (col?.url) {
			this.router.navigateByUrl(col.url + (col?.id ? row[col?.id].toString() : ""));
		}
	}

	/**
	 * function: setPage
	 * @param args
	 */
	setPage(args): void {
		this.page?.emit(args);
	}

	/**
	 * function: onSort
	 * @param args
	 */
	onSort(args): void {
		this.sort?.emit(args);
	}

	/**
	 *
	 * @param args
	 */
	setLimit(args): void {
		if (args.target.value === "all") {
			this.limit = this.count;
		} else {
			this.limit = args.target.value;
		}
		this.setlimit?.emit(this.pageLimit);
	}

	/**
	 * function: updateInlineData
	 * @param args
	 * @param key
	 * @param subkey
	 * @param row
	 * @param editDataIndex
	 */
	updateInlineData = (args, key, subkey, row, editDataIndex, col, formField = null, rowIndex = null): void => {
		row["key"] = key;
		row["subkey"] = subkey;
		this.formCompModel = formField;
		if (args.keyCode === 13 && args.target?.id == "country-search-box") {
			// 13 = enter
			return;
		}
		if (this.isEditable || col.isEditable) {
			if (["select-template", "select-template-status", "multi-select"].indexOf(col.type) == -1) {
				if ((!col?.type && formField?.invalid) || (col?.type == "tel" && formField?.errors)) {
					return;
				}
				this.editData[editDataIndex] = false;
				this.is_editing = false;
				if (args.target.value !== "") {
					if (subkey) {
						if (args.target.type === "checkbox") {
							row[subkey][key] = args.target.checked;
						} else {
							if (args.target.type === "tel") {
								row[subkey][key] = formField?.viewModel;
							} else {
								row[subkey][key] = args.target.value;
							}
						}
					} else if (args.target.type === "tel") {
					} else {
						if (args.target.type === "checkbox") {
							row[key] = args.target.checked;
						} else {
							row[key] = args.target.value;
						}
					}
					this.editCallback?.emit(row);
				}
			} else if (args.target?.type === "tel") {
			} else {
				if (col?.type === "select-template-status") {
					let row_key = {};
					if (col?.subkey) {
						row[subkey][key] = args;
						row_key = row[subkey][key];
					} else {
						row[key] = args;
						row_key = row[key];
					}
					if (rowIndex != null) {
						this.remapOptionData(this.options[col.key], col?.option_map, row_key["id"], rowIndex);
					}

					this.is_editing = false;
				}
				this.editData[editDataIndex] = false;
				this.editCallback?.emit(row);
			}
		}
	};
	disableEditableInput = (col, editDataIndex) => {
		if (this.isEditable || col.isEditable) {
			this.editData[editDataIndex] = false;
			this.is_editing = false;
		}
	}
	onEnterInlineEdit = (args, key, subkey, row, editDataIndex, col, formField = null): void => {
		this.formCompModel = formField;
		if (args.keyCode === 13) {
			this.updateInlineData(args, key, subkey, row, editDataIndex, col, formField);
		}
	};

	/**
	 * function: doEdit
	 * @param args: it will have unique id.
	 */
	doEdit(args, col, rowVal = null, rowIndex): void {
		let val_id = "";
		if (col?.subkey) {
			val_id = rowVal[col.subkey][col.key]["id"];
		} else {
			val_id = rowVal[col.key]["id"];
		}
		if (col?.option_map) {
			if (!this.isEditableOption(col?.option_map, val_id)) {
				return;
			}
		}
		if (this.isEditable && col.isEditable != false && !this.is_editing) {
			this.is_editing = true;
			this.editData[args] = true;
		}
		if(col?.type === 'select'){
			setTimeout(() => {
				document.getElementById('sel'+ col?.key + "_" + rowIndex).focus();
			}, 200);
		}
	}

	searchObjectByKeyValue(arr, key, value) {
		return arr?.find((obj) => obj[key] == value);
	}

	searchStatusByKeyValue(arr, key, value) {
		return arr?.find((obj) => (obj[key.id] = value.id));
	}

	searchStatusColor(arr, key, value) {
		const result = arr?.find((obj) => (obj[key.id] = value.id));
		return {
			"background-color": result?.background_color + " !important",
			color: result?.text_color + " !important",
		};
	}

	searchMultiObjectByKeyValue(arr, key, value) {
		const value_li = [];
		if (arr != undefined) {
			for (const obj of arr) {
				if (value.indexOf(obj.id) != -1) {
					value_li.push(obj.name);
				}
			}
			return value_li.join(", ");
		}
	}

	onCellActionClick = (item, columnData) => {
		if (this.onCellClick) {
			const data = {
				rowData: item,
				columnData: columnData,
			};
			this.onCellClick.emit(data);
		}
	};

	optionDataMap = {};

	isEditableOption = (optionMap, value) => {
		return optionMap[value]?.length > 1;
	};

	remapOptionData = (options: any, option_map: any, value: any, index) => {
		if (!this.optionDataMap[index]) {
			this.optionDataMap[index] = {
				value: value,
			};
			this.optionDataMap[index]["options"] = options.filter((item) => {
				if (option_map[value].includes(item?.id)) {
					return item;
				}
			});
		}
		let optionsList = [];
		if (this.optionDataMap[index]?.value !== value) {
			optionsList = options.filter((item) => {
				if (option_map[value].includes(item?.id)) {
					return item;
				}
			});
			this.optionDataMap[index].value = value;
			this.optionDataMap[index]["options"] = optionsList;
		} else {
			optionsList = this.optionDataMap[index]["options"];
		}
		return optionsList;
	};

	resourceEvent() {
		this.resourceCellClick.emit(true);
	}

	getTranslatableKey = (key) => {
		if (key && typeof key === "string") {
			if (key.startsWith("k_")) {
				key = `UI.${key}`;
			} else if (key.startsWith("msg_")) {
				key = `Message.${key}`;
			} else if (key.startsWith("err_")) {
				key = `Error.${key}`;
			}
			return this._translateService.instant(key);
		}
		return key;
	};

	getRowClass(row) {
		return {
			"row-highlight": row?.isHighlighted,
		};
	}
}
