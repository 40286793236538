<div class="card">
	<div class="card-header">
		<div class="card-title">
			<span>{{ name }}</span>
		</div>
		<!-- <i [data-feather]="'close'"></i> -->
		<i class="fa-light fa-times"></i>
	</div>
	<div class="nav-vertical">
		<ul
			ngbNav
			#navVertical="ngbNav"
			class="nav nav-tabs nav-left flex-column mb-0"
		>
			<li ngbNavItem *ngFor="let item of macroData">
				<a ngbNavLink>{{ item.name | translate }}</a>
				<ng-template ngbNavContent>
					<div>
						<div
							class="btn btn-sm btn-outline-primary mr-1 mb-1"
							(click)="onTagClick(tag)"
							*ngFor="let tag of item.tags"
						>
							{{ tag.label | translate }}
						</div>
					</div>
				</ng-template>
			</li>
		</ul>
		<div [ngbNavOutlet]="navVertical" class="p-1"></div>
	</div>
</div>
