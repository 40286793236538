import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CoreConfigService } from "@core/services/config.service";
import { TranslateService } from "@ngx-translate/core";
import { flow } from "lodash";
import { flatMap, filter } from "lodash/fp";
import { Router } from "@angular/router";
import { FlatpickrOptions } from "ng2-flatpickr";
import { User } from "app/auth/models";
import { AuthService } from "app/base/auth/services/auth.service";

@Component({
	selector: "app-dns-panel-view",
	templateUrl: "./dns-panel-view.component.html",
	styleUrls: ["./dns-panel-view.component.scss"],
})
export class DnsPanelViewComponent implements OnInit {
	@Input() panelData;
	@Input() panelConfig;
	@Input() actionList: any = {};
	@Input() size;
	@Input() page;
	@Input() columns;
	@Input() options;
	@Input() permissions;
	@Output() callback: EventEmitter<any> = new EventEmitter<any>();
	@Output() onSetLimit: EventEmitter<any> = new EventEmitter<any>();
	@Output() onSort: EventEmitter<any> = new EventEmitter<any>();
	panelResult: any = [];
	pageStart: any = 0;
	pageEnd: any;
	currentUser: User;

	panelViewForm = new FormGroup({
		checkedData: new FormControl(this.selectedHandler(this.panelResult)),
	});

	checkedData = this.panelViewForm.get("checkedData");

	inputValue: any;
	isExpanded: boolean = false;
	actionCallbackMap: any = {};

	showQuickEdit = false;
	rowData: any;
	showSidePanel: boolean = true;
	submenu = true;
	public dateOptions: FlatpickrOptions = {
		altInput: true,
		onChange: (selectedDates: any, dateStr, instance) => {
			if (selectedDates instanceof Array) {
				let selectedDates_tmp: any = selectedDates[0];
				let args = {};
				args["event"] = selectedDates_tmp;
				// args["column"] =  column
				// args["rowData"] = rowData
				// this.callback.emit(args)
			}
		},
	};
	leftCountFilter: any = [
		{
			view_name: "All",
			value: 7,
			sequence: 1,
		},
		{
			view_name: " My Unsolved requests ",
			value: 5,
			sequence: 2,
		},
		{
			view_name: " Unassigned requests ",
			value: 7,
			sequence: 3,
		},
		{
			view_name: " New requests in my groups ",
			value: 7,
			sequence: 4,
		},
		{
			view_name: " My solved requests ",
			value: 7,
			sequence: 5,
		},
		{
			view_name: "  Pending requests  ",
			value: 7,
			sequence: 6,
		},
		{
			view_name: "  All unsolved requests  ",
			value: 7,
			sequence: 7,
		},
		{
			view_name: "   My group solved requests   ",
			value: 7,
			sequence: 8,
		},
		{
			view_name: "   UI.k_my_approval   ",
			value: 7,
			sequence: 9,
		},
	];

	selectedItem: any;

	isMultipleSelected: any = [];
	isAllSelected: boolean = false;

	statusName: any;
	statusBg: any;
	statusColor: any;

	priorityName: any;
	priorityIcon: any;
	priorityBg: any;
	priorityColor: any;

	riskName: any;
	riskIcon: any;
	riskBg: any;
	riskColor: any;

	managerName: any;
	assigneeName: any;

	defaultDueDate: any;
	isOpen: boolean = false;

	constructor(
		public _translateService: TranslateService,
		public _coreSidebarService: CoreSidebarService,
		public _coreConfigService: CoreConfigService,
		public _router: Router,
		public _authService: AuthService
	) {
		this._authService.currentUser.subscribe((x) => (this.currentUser = x));
		this._coreConfigService.config = {
			layout: {
				navbar: {
					loadHTML: false,
					hidden: false,
					pageIcon: "fa-gear",
					pageTitle: "Release View",
					moduleName: "release",
					bookmark: false,
					// backgroundColor: "",
					breadcrumb: "",
					buttonsRight: [],
				},
			},
		};
	}

	ngOnInit(): void {
		// console.log("panel cofig", this.panelConfig.dropdown_section[0].options[0].value);
		// console.log("panelData", this.panelData);
		this.panelResult = this.panelData.results;

		// this.statusName = this.panelData.status.name;
		// this.statusBg = this.panelData.status.background_color;
		// this.statusColor = this.panelData.status.color;

		// this.priorityName = this.panelData.priority_name;
		// this.priorityBg = this.panelData.priority_color;
		// this.priorityIcon = 'fa-angles-up';
		// this.priorityColor = this.panelData.priority_color;

		// this.riskName = this.panelData.priority_name;
		// this.riskBg = this.panelData.priority_color;
		// this.riskIcon = 'fa-angles-up';
		// this.riskColor = this.panelData.priority_color;

		// this.managerName = ['Chiru_01'];
		// this.assigneeName = this.panelData.requester_name;
	}

	onChecklistChangeHandler(checked, itm) {
		itm.checked = checked;
		this.checkedData.setValue(this.selectedHandler(this.panelData.results));
		this.isMultipleSelected = [itm];
		this.isMultipleSelected = this.panelData.results.filter((itm: any) => itm.checked === true);
	}

	selectedHandler(itmList) {
		const flattenedValues = flow([
			filter((checkbox) => checkbox.checked),
			flatMap((checkbox) => checkbox.request_id),
		])(itmList);
		return flattenedValues.join(",");
	}

	searchConfig(evt: any) {
		let searchStr = evt.target.value;
		const filteredConfigs = this.panelData.results.filter((item) => {
			return this._translateService.instant(item.created_by_name).toLowerCase().includes(searchStr.toLowerCase());
		});
		this.panelData.results = filteredConfigs;
	}

	collapseHandler() {
		this.isExpanded = !this.isExpanded;
	}

	onHideQuickEdit = (e) => {
		this.showQuickEdit = false;
	};

	onSelect(data: any, id: any) {
		this.selectedItem = id;
	}

	addNewReleaseUrl(addUrl) {
		this._router.navigateByUrl(addUrl);
	}

	emptySelected() {
		this.isMultipleSelected = [];
		this.panelData.results.forEach((item: any) => {
			item.checked = false;
		});
	}

	allSelectHandler(evt: any) {
		if (evt.target.checked) {
			this.panelData.results.forEach((item: any) => {
				item.checked = true;
			});
			this.isMultipleSelected = this.panelData.results.filter((itm: any) => itm.checked === true);
		} else {
			this.panelData.results.forEach((item: any) => {
				item.checked = false;
			});
			this.isMultipleSelected = this.panelData.results.filter((itm: any) => itm.checked === true);
		}
	}

	nameExtractorHandler(data: any) {
		return data
			?.split(" ")
			.map((n) => n[0])
			.join("");
	}

	isOpenDrowHandler(id, event) {
		// this.isOpen = !this.isOpen
		if (id == 1) {
			this.isOpen = !this.isOpen;
			event.stopPropagation();
		} else {
			this.isOpen = false;
		}
	}

	statusUpdateHandler(item) {
		this.isOpen = false;
		this.statusName = item.name;
		this.statusBg = item.background_color;
		this.statusColor = item.color;
	}

	priorityUpdate(item) {
		this.isOpen = false;
		this.priorityName = item.name;
		this.priorityBg = item.color;
		this.priorityIcon = item.icon;
		this.priorityColor = item.color_hex;
	}

	riskUpdate(item) {
		this.isOpen = false;
		this.riskName = item.name;
		this.riskBg = item.color;
		this.riskIcon = item.icon;
		this.riskColor = item.color_hex;
	}

	managerUpdate(item) {
		this.isOpen = false;
		this.managerName = item.name;
	}

	assigneeUpdate(item) {
		this.isOpen = false;
		this.assigneeName = item.name;
	}

	paginate(page) {
		page = page?.page ? parseInt(page.page) : page;
		this.pageStart = this.size * (page - 1) + 1;
		this.pageEnd = this.size * page;
		let params = { page: page, limit: this.size };

		this.onSetLimit.emit(params);
	}
	setLimitGrid($event) {
		let page = 1;
		this.size = $event;
		if ($event == "all") {
			this.size = this.panelData?.count;
		}
		this.pageStart = this.size * (page - 1) + 1;
		this.pageEnd = this.size * page;
		let params = { page: page, limit: this.size };
		this.onSetLimit.emit(params);
	}
	buildOnCallBack = ($event, column, rowData) => {
		let args = {};
		args["event"] = $event;
		args["column"] = column;
		args["rowData"] = rowData;
		this.callback.emit(args);
	};
	statusBadgeChange = ($event, column, rowData) => {
		let args = {};
		args["event"] = $event?.event;
		args["column"] = column;
		//bind label will be id
		rowData[column?.key] = $event?.event?.id;
		args["rowData"] = rowData;
		this.callback.emit(args);
	};
	stringToColor(reqName) {
		if (!reqName) {
			reqName = "anaymous";
		}

		let hash = 0;
		let i;

		/* eslint-disable no-bitwise */
		for (i = 0; i < reqName.length; i += 1) {
			hash = reqName.charCodeAt(i) + ((hash << 5) - hash);
		}

		let colour = "#";

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff;
			colour += `00${value.toString(16)}`.substr(-2);
		}
		/* eslint-enable no-bitwise */

		return colour;
	}
	checkControlledActions(element, action_list) {
		let comp = action_list?.filter((e) => e?.enable(e, element));
		if (comp && comp?.length > 0) {
			return true;
		} else {
			return false;
		}
	}
}
