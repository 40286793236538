<div class="slideout-content default-slideout">
  <div class="modalsd modal-slide-in sdfade" id="modals-slide-in">
    <div class="modal-dialog">      
      <div class="modal-content pt-0 pb-0">        
        <form class="flex-auto-height" #addQuickResponseTempForm="ngForm">
          <div class="modal-header d-flex justify-content-between align-items-center">
            <h5 class="modal-title fw-600 mb-0 lg-font">
              <ng-container *ngIf="event == 'add'">
                {{'UI.k_add' | translate}} {{'UI.k_quick_response_template' | translate}}
              </ng-container>
              <ng-container *ngIf="event == 'edit'">
                {{'UI.k_edit' | translate}} {{'UI.k_quick_response_template' | translate}}
              </ng-container>
              <ng-container *ngIf="event == 'view'">
                {{'UI.k_view' | translate}} {{'UI.k_quick_response_template' | translate}}
              </ng-container>
            </h5>
            <button type="button" data-dismiss="modal" id="btnClose" name="btnClose" aria-label="Close"
              (click)="toggleSidebar('add-quick-response-template')" class="btn btn-flat-secondary btn-icon" rippleEffect>
              <i class="fa-light fa-xmark"></i>
            </button>
          </div>
          <div class="modal-content-body flex-1">
            <ng-scrollbar class="custom-scrollbar section-scrollbar">
              <div class="p-1">                
                <div class="row">
                  <div class="col-12">
                    <div class="input-group form-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-addon sm-font">{{ 'UI.k_title' | translate }}</span>
                      </div>
                      <input
                        type="text"
                        [(ngModel)]="currentItem.title"
                        class="form-control"
                        placeholder="{{ 'UI.k_type_title_here' | translate }}"                   
                      />
                    </div>
                  </div>  
                  <div class="col-12 mb-1">
                    <div class="txt-editor">
                      <quill-editor
                        required
                        [(ngModel)]="currentItem.tempText"
                        id="templateText"
                        name="templateText"
                        [ngModelOptions]="{ standalone: false }"
                        class="quick-response"
                      >
                      <div quill-editor-toolbar>
                        <div class="desc-toolbar border-top-0">
                          <span class="ql-formats mr-0">
                            <select class="ql-header">                              
                              <option value="1"></option>
                              <option value="2"></option>
                              <option value="3"></option>
                              <option value="4"></option>
                              <option value="5"></option>
                              <option value="6"></option>
                              <option selected></option>
                            </select>
                            <button class="ql-bold"></button>                         
                            <select class="ql-align" [title]="'Alignment'">
                              <option selected></option>
                              <option value="center"></option>
                              <option value="right"></option>
                              <option value="justify"></option>
                            </select>
                          </span>                          
                        </div>
                      </div>
                      </quill-editor>
                      <div class="file-upload-below-quill border border-top-0">
                        <span
                          *ngFor="let file of filesList"
                          class="text-primary sm-font attach-file-border m-half"
                          ><i class="fa-light fa-file-lines"></i>&nbsp;<b>{{
                            file.file_name
                          }}</b>&nbsp;
                          <span
                            (click)="removeAttachedFile($index, filesList)"
                            id="btnFileAttachmentClose"
                          >
                            <i class="fa-light fa-xmark"></i>
                          </span>
                        </span>
                        <div>
                          <label for="file-input" class="add_attachment c-fs-12">
                            <img
                              src="assets/images/icons/icon_link.svg"
                              width="18"
                              height="18"
                            />
                            <span class="fw-500 text-primary ml-50 align-middle"
                              >{{ "UI.k_add" | translate }}
                              {{ "UI.k_attachment" | translate }}
                            </span>
                          </label>
                          <input
                            (change)="fileUpload($event)"
                            id="file-input"
                            type="file"
                            class="d-none"
                          />
                        </div>
                      </div>
                    </div>
                  </div>  
                  <div class="col-12">
                    <div class="row">
                      <div class="col-sm-4">
                          <div class="form-group">
                              <label for="selType" class="form-label">
                                  {{ 'UI.k_change' | translate }} {{ 'UI.k_type' | translate }}<span class="required-field">*</span></label>
                              <ng-select
                                  [items]="changeType"
                                  bindLabel="name"
                                  bindValue="name"
                                  class="form-control"
                                  [(ngModel)]="selectedItem"
                                  name="selType"
                                  id="selType"
                                  placeholder="{{ 'UI.k_select' | translate }}"
                              >
                              </ng-select>
                          </div>
                      </div>
                      <div class="col-sm-4">
                          <app-status-picker
                              [inputConfig]="{ isTextMode: true }"
                              [options]="statusList"
                              (setStatus)="onStatusChange($event)"
                          ></app-status-picker>
                      </div>
                      <div class="col-sm-4">
                          <div class="form-group">
                              <label for="selPriority" class="form-label">
                                  {{ 'UI.k_priority' | translate }}<span class="required-field">*</span></label
                              >
                              <ng-select
                                [items]="priorities"
                                bindLabel="label"
                                bindValue="priority"
                                class="form-control"
                                name="selPriority"
                                id="selPriority"
                                [(ngModel)]="priorityField"
                                placeholder="{{ 'Message.msg_select_property' | translate }}"
                                #priorityType="ngModel"
                                [clearable]="false"
                              >
                                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                    <ng-container>
                                        <div class="badge badge-light-{{item.class}} d-flex align-items-center w-auto" style="max-width: max-content;">                                                  
                                            <span>{{ item.display_label | translate }}</span>
                                        </div>
                                    </ng-container>
                                </ng-template>
                            </ng-select>
                          </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group">
                            <label for="selRiskfactor" class="form-label">
                              {{ 'UI.k_risk' | translate }}<span class="required-field">*</span></label>
                            <ng-select
                                    
                              [items]="priorities"
                              bindLabel="label"
                              bindValue="priority"
                              class="form-control"
                              name="selRiskfactor"
                              id="selRiskfactor"
                              [(ngModel)]="riskField"
                              placeholder="select"
                              #priorityType="ngModel"
                              [clearable]="false"
                            >
                              <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                  <ng-container>
                                      <div class="badge badge-light-{{item.class}} d-flex align-items-center w-auto" style="max-width: max-content;">                                                  
                                          <span>{{ item.display_label | translate }}</span>
                                      </div>
                                  </ng-container>
                              </ng-template>
                            </ng-select>
                        </div>
                      </div>
                      <div class="col-sm-4">
                          <div class="form-group custom-date-picker">
                            <label for="dtDueDate" class="form-label">
                              {{ 'UI.k_due_date' | translate }}<span class="required-field">*</span></label>
                            <div class="input-group">
                              <input
                                  class="form-control"
                                  placeholder="yyyy-mm-dd"
                                  name="dtDueDate"
                                  id="dtDueDate"
                                  ngbDatepicker
                                  #dueDateDP="ngbDatepicker"
                                  
                              />
                              <div class="input-group-append">
                                  <button
                                      class="btn btn-outline-secondary feather icon-calendar"
                                      (click)="dueDateDP.toggle()"
                                      type="button"
                                      rippleEffect
                                  ></button>
                              </div>
                            </div>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group">
                            <label class="form-label" for="selImpact">
                              {{"UI.k_impact_service" | translate}}</label>
                            <ng-select
                                [items]="dropdown.all_services"
                                [multiple]="false"
                                [closeOnSelect]="true"
                                [searchable]="false"
                                bindLabel="name"
                                bindValue="service_id"
                                name="selImpact"
                                id="selImpact"
                                placeholder="{{ 'UI.k_select' | translate }} {{
                                    'UI.k_impact' | translate
                                }}"
                                class=""
                                [(ngModel)]="addIncident.impact_service"
                            >
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <div class="d-flex justify-content-between align-items-center">
                                <label class="form-label" for="selAssignee">{{"UI.k_change" | translate }} {{"UI.k_implementer" | translate }}</label>
                            </div>
                            <ng-select
                                [items]="dropdown.teams_and_self_assign"
                                [multiple]="false"
                                [closeOnSelect]="true"
                                [(ngModel)]="team_id"
                                [searchable]="false"
                                (change)="groupChange(dropdown.teams)"
                                bindLabel="name"
                                bindValue="team_id"
                                name="selAssignee"
                                id="selAssignee"
                                class="form-control"
                                placeholder="{{ 'UI.k_select' | translate }} {{
                                    'UI.k_assignee' | translate
                                }}"
                            >                               		                                                            
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label for="txtIncidentServiceClass" class=""
                                >{{ "UI.k_service_classification" | translate }}
                            </label>
                            <ng-select
                                [items]="dropdown.classifications"
                                id="txtIncidentServiceClass"
                                name="txtIncidentServiceClass"
                                [(ngModel)]="addIncident.service_classification"
                                placeholder="{{ 'UI.k_select_dropdown' | translate }}"
                                bindLabel="name"
                                class=""
                                
                                bindValue="class_id"
                                [ngModelOptions]="{ standalone: true }"
                            >
                                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                    <i [data-feather]="item.child_icon" [size]="16"></i>
                                    {{ item.name }}
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                      <div class="col-sm-4">
                          <div class="form-group">
                              <div class="d-flex justify-content-between align-items-center">
                                <label class="" for="txtIncidentTags">{{
                                    "UI.k_tags" | translate
                                }}</label>
                                <small
                                  id="btnAddmoreTag"
                                  name="addMoreTag"
                                  class="text-primary cursor-pointer"                                      
                                  >{{ "UI.k_new" | translate }}
                                  {{ "UI.k_tag" | translate }}
                                </small>
                              </div>
                              <ng-select
                                  [(ngModel)]="addIncident.tag"
                                  #TDTagsRef="ngModel"
                                  name="txtIncidentTags"
                                  id="txtIncidentTags"
                                  [items]="dropdown.tagList"
                                  bindLabel="name"
                                  bindValue="tag_id"
                                  [closeOnSelect]="true"
                                  [addTag]="false"
                                  [multiple]="true"
                                  [hideSelected]="true"
                                  class=""
                                  [ngModelOptions]="{ standalone: true }"
                                  placeholder="{{ 'UI.k_select' | translate }} {{
                                      'UI.k_tag' | translate
                                  }}"
                              ></ng-select>
                          </div>
                      </div>
                      
                  </div>
                  </div>                
                </div>
              </div>              
            </ng-scrollbar>          
          </div> 
          <div class="modal-content-footer flex-initial">
            <div class="d-flex justify-content-end border-top px-1 py-1">
              <button type="button" (click)="toggleSidebar('add-quick-response-template')"
                class="btn btn-outline-tertiary btn-prev mr-1" rippleEffect>
                <span class="align-middle"> {{ 'UI.k_cancel' | translate }}</span>
              </button>
              <button type="submit"
                class="btn btn-primary btn-next" rippleEffect>
                <span class="align-middle">{{ 'UI.k_save' | translate }}</span>
              </button>
            </div>
          </div>     
        </form>
      </div>
    </div>
  </div>
</div>