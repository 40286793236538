<!-- Menu header -->

<div
	class="navbar-header admin-navbar-header"
	[ngClass]="
		coreConfig.layout.menu.logoCustomBackgroundColor === true ? coreConfig.layout.menu.logoBackgroundColor : ''
	"
	[ngStyle]="{
		'background-color': coreConfig.layout.menu.logoBackgroundColor.includes('#')
			? coreConfig.layout.menu.logoBackgroundColor
			: null
	}"
>
	<ul class="nav navbar-nav flex-row">
		<li class="nav-item mr-auto">
			<!-- App Branding -->
			<a class="navbar-brand">
				<span class="brand-logo" *ngIf="coreConfig.layout.menu.collapsed">
					<img src="{{ coreConfig.app.appIconImage }}" alt="brand-logo" height="56" />
					<!-- style="margin-left: -3.5rem"
						width="150px"
						height="50px" -->
				</span>
				<span class="brand-logo" *ngIf="!coreConfig.layout.menu.collapsed">
					<img src="{{ coreConfig.app.appAdminLogo }}" alt="brand-logo" height="21" width="159" />
				</span>
				<!-- <h2
					class="brand-text mb-0"
					style="
						font-family: Recharge !important;
						font-size: 18px;
						font-weight: 900;
						color: #4d5ea6;
					"
				>
					{{ coreConfig.app.appName }}
				</h2> -->
			</a>
		</li>

		<!-- Menu Toggler -->
		<li class="nav-item nav-toggle">
			<!-- <a
				class="nav-link modern-nav-toggle d-none d-xl-block pr-0"
				(click)="toggleSidebarCollapsible()"
				id="toggleLeftPanel"
			>
				<i
					*ngIf="coreConfig.layout.menu.logoBackgroundColor"
					[ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'"
					class="toggle-icon feather font-medium-4 collapse-toggle-icon text-white"
				></i>
				<i
					*ngIf="!coreConfig.layout.menu.logoBackgroundColor"
					[ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'"
					class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary"
				></i>
			</a>
			<a
				class="nav-link modern-nav-toggle d-block d-xl-none pr-0"
				(click)="toggleSidebar()"
			>
				<i
					[data-feather]="'x'"
					class="font-medium-4 text-primary toggle-icon"
				></i>
			</a>-->
		</li>
	</ul>
</div>
<!--/ Menu header -->

<!-- Navbar shadow -->
<!-- <div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div> -->

<!-- Main menu -->
<!-- <div class="main-menu-content" [perfectScrollbar] (scroll)="onSidebarScroll()">
	<ul class="navigation navigation-main" layout="vertical" core-menu></ul>
</div> -->
<div class="main-menu-content">
	<ng-scrollbar class="custom-scrollbar section-scrollbar main-menu-scroll">
		<ul class="navigation navigation-main" layout="vertical" core-menu></ul>
	</ng-scrollbar>
</div>
<!--/ Main menu -->

<!-- Footer menu -->
<div class="footer-menu-content border-top" *ngIf="showGetInTouch">
	<span class="sm-font mb-1 mt-75 d-block"> Our Customer Success team can help you acheive your goals </span>
	<button
		type="button"
		class="btn btn-outline-primary mb-2"
		id="linkGetInTouch"
		name="linkGetInTouch"
		(click)="toggleGetInTouchSidebar('get-in-touch')"
		rippleEffect
	>
		{{ "UI.k_get_in_touch" | translate }}
	</button>
	<!-- <ul class="navigation navigation-main getStart">
      <li
      class="border-top pt-1"
      routerLinkActive="active"
      [routerLinkActive]="'active'"
     
      >

      <a
        class="d-flex align-items-center"
        target="_self"
        id="linkgetting_start"
        name="linkgetting_start"
        [routerLink]="['/get-started']"
      >
      <span
        id="get-trigger"
        name="get-trigger"
        class="d-flex justify-content-center align-items-center"
      >
        <div
          style="width:24px; height: 24px"
          background="transparent"
          speed="1"
          hover
        >
          <span id="get-container"></span>
        </div>

        <span class="menu-title text-truncate">
        &nbsp;&nbsp;
          {{ "UI.k_getting_started" | translate}}</span
        >
        </span>
      </a>
      </li>
  </ul> -->
</div>
<!-- <span><i class="fa-light fa-screwdriver-wrench fa-lg"></i></span> -->
<!--/ Footer menu -->

<!-- <core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="get-in-touch"
	overlayClass="modal-backdrop"
>
	<app-get-in-touch
		*ngIf="showSidebarDetails?.getInTouch" 
	></app-get-in-touch>
</core-sidebar> -->
