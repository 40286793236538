import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-skeleton',
  templateUrl: './admin-skeleton.component.html',
  styleUrls: ['./admin-skeleton.component.scss']
})
export class AdminSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.generateFake(3);
  }

  generateFake(count: number): Array<number> {
        const indexes = [];
        for (let i = 0; i < count; i++) {
            indexes.push(i);
        }
        return indexes;
    }

}

