<div class="slideout-content default-slideout">
	<div class="modal-dialog">
		<div class="modal-content add-new-user pt-0">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">
					{{ typeMapping?.[featureViewTaskData?.type?.id] | translate }}
                    <span class="feature-view-title">{{featureViewTaskData.display_id}}</span>
				</h5>
				<div class="d-flex">					
					<button
						type="button"
						id="btnClose"
						name="btnClose"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						(click)="toggleSidebar('view-task')"
                        [attr.data-testid]="'btnClose'"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
			</div>
            
			<div class="modal-body flex-auto-height">
                <div class="flex-auto-height">
                    <ng-scrollbar class="custom-scrollbar section-scrollbar">
                        <form #newTaskForm="ngForm" class="panel-edit-form-sec-main-block">

                            <div class="input-group" style="width: 98%; margin: 0 auto; margin-top: 10px; margin-bottom: 10px;">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1">{{ "UI.k_title" | translate }}*</span>
                                </div>
                                <input
                                    type="text"
                                    class="form-control"
                                    name="txtTitle"
                                    id="txtTitle"
                                    placeholder="Type title here"
                                    aria-label="txtTitle"
                                    aria-describedby="basic-addon1"
                                    required
                                    minlength="3"
                                    maxlength="1000"
                                    [(ngModel)]="task_obj.name"
                                    #submitVlanNameInput="ngModel"
                                    [class.error]="submitVlanNameInput.invalid && newTaskForm.submitted"
                                    [disabled]="disable_config || !featureViewTaskData?.permissions?.edit || featureViewTaskData?.status?.state_id ==5"
                                    [appWhiteSpaceCheck]="task_obj.name"
                                />
                            </div>
                            <span
                                class="invalid-form"
                                *ngIf="newTaskForm.submitted && submitVlanNameInput.invalid"
                            >
                                <small
                                    class="col-12 form-text text-danger"
                                    *ngIf="submitVlanNameInput.errors.required"
                                    >{{ "UI.k_field_required" | translate }}
                                </small>
                                <small
                                    class="col-12 form-text text-danger"
                                    *ngIf="submitVlanNameInput.errors.minlength || submitVlanNameInput.errors.maxlength"
                                    >{{ "UI.k_title_name_validation" | translate }}
                                </small>
                            </span>
                            <div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
                                <div class="row">
                                    <div class="col-xs-4 col-md-4 col-xl-4 col-lg-4">
                                        <app-status-picker
                                            [inputConfig]="{
                                                isTextMode: true,
                                                required:true,
                                                isEditDisabled: disable_config || !featureViewTaskData?.permissions?.edit || featureViewTaskData?.status?.state_id ==5
                                                }"
                                            [options]="options?.task_status"
                                            (setStatus)="onStatusChange($event)"
											[value]="task_obj?.status?.id"
                                        >
                                        </app-status-picker>
                                    </div>
                                    <div class="col-xs-4 col-md-4 col-xl-4 col-lg-4" style="padding: 0;">
                                        <div class="panel-edit-select-box">
                                            <label>{{"UI.k_priority" | translate }}</label>
                                            <app-select-badge
                                            [inputConfig]="{
                                                hideLabel: true,
                                                inputOptions: options?.priority,
                                                id: 'priority',
                                                model: this.task_obj?.priority?.id,
                                                isEditDisabled: disable_config || !featureViewTaskData?.permissions?.edit || featureViewTaskData?.status?.state_id ==5
                                            }"
                                            (onValueChange)="statusBadgeChange($event)"
                                            ></app-select-badge>
                                        </div>
                                    </div>
                                    <div class="col-xs-4 col-md-4 col-xl-4 col-lg-4">
                                        <div class="panel-edit-select-box">
                                            <label class="form-label" for="txtDueDate"> {{ "UI.k_due_date" | translate }} </label>
											<form class="form-inline">
												<div class="form-group">
													<div class="input-group">
                                                        <ng2-flatpickr
                                                            #datepicker
                                                            [config]="basicDateTimeOptions"
                                                            name="txtDueDate"
                                                            id="txtDueDate"
                                                            [(ngModel)]="task_obj.due_date"
                                                            [setDate]="task_obj.due_date"
                                                            placeholder="{{'UI.k_placeholder_yyyy_mm_dd' | translate}}"
                                                            [disabled]="disable_config || !featureViewTaskData?.permissions?.edit  || featureViewTaskData?.status?.state_id ==5"
                                                        >
                                                        </ng2-flatpickr>
														<div class="input-group-append">
															<button
																class="btn btn-outline-secondary feather icon-calendar"
																(click)="datepicker.flatpickr.toggle()"
																type="button"
																rippleEffect
															></button>
														</div>
													</div>
												</div>
											</form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-12 col-xl-12 col-lg-12" style="margin-bottom: 10px;">
                                <div class="row">
                                    <div class="col-xs-4 col-md-4 col-xl-4 col-lg-4">
                                        <div class="panel-edit-select-box">
                                            <label for="selAssignee" class="form-label">{{
												"UI.k_assignee" | translate
											}}</label>
											<ng-select
                                                [items]="options?.users"
                                                [searchable]="true"
                                                bindLabel="full_name"
                                                [searchFn]="customSearchFn"
                                                bindValue="profile_id"
                                                name="selAssignee"
                                                id="selAssignee"
                                                placeholder="{{ 'UI.k_select' | translate }}"
                                                class=""
                                                [(ngModel)]="task_obj.assignee"
                                                (change)="onAssigneeChange($event)"
                                                [disabled]="disable_config || !featureViewTaskData?.permissions?.edit || featureViewTaskData?.status?.state_id ==5"
                                            >
                                                <ng-template
                                                    ng-option-tmp
                                                    let-item="item"
                                                    let-index="index"
                                                    let-search="searchTerm"
                                                >
                                                    <span class="d-flex">
                                                        <div *ngIf="item?.profile_image">
                                                            <div class="avatar mr-50 ml-25">
                                                                <span class="avatar-content">
                                                                    <img
                                                                        class="rounded-circle"
                                                                        src="{{ item.profile_image }}"
                                                                        alt="User"
                                                                        width="32"
                                                                        height="32"
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="!item?.profile_image">
                                                            <div
                                                                class="avatar mr-50 ml-25"
                                                                [ngStyle]="{
                                                                    'background-color': item?.avatar_color
                                                                        ? item?.avatar_color
                                                                        : '#f59518'
                                                                }"
                                                                style="
                                                                    align-content: center;
                                                                    width: 32px;
                                                                    height: 32px;
                                                                "
                                                            >
                                                                <div class="avatar-content">
                                                                    {{ item.full_name | initials | slice: 0:2 }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="cell-line-height">
                                                            <p
                                                                class="font-medium-1 font-weight-bold line-height-1 mb-0"
                                                            >
                                                                {{ item?.full_name | slice: 0:28 }}
                                                                {{ item?.full_name?.length > 28 ? ".." : "" }}
                                                            </p>
                                                            <span class="text-muted font-small-2">
                                                                {{ item?.email | slice: 0:28 }}
                                                                {{ item?.email.length > 28 ? ".." : "" }}</span
                                                            >
                                                        </div>
                                                    </span>
                                                </ng-template>
											</ng-select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
                                <div class="row">
                                    <div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
                                        <div class="panel-edit-select-box">
                                            <label style="font-size: 14px;font-weight: 600;">
                                                {{aboutTaskMapping?.[task_obj?.type?.id] | translate}}&nbsp;*
                                            </label>
                                        </div>
                                        <div class="p-0 card border quill-card">
                                            <quill-editor
                                                [(ngModel)]="task_obj.description"
                                                id="qlDescription"
                                                name="qlDescription"
                                                #qlDescription
                                                [trimOnValidation]="true"
                                                [appQuillValidator]="qlDescription"
                                                [appQuillForm]="newTaskForm"
                                                placeholder="{{ 'UI.k_add_content' | translate }}"
                                                [minLength]="3"
                                                [class.error]="qlDescription?.errors && newTaskForm.submitted"
                                                [required]="true"
                                            >
                                                <div quill-editor-toolbar class="border-0 border-bottom px-0" style="padding: 0px;">
                                                    <div class="border-bottom editor-item-flex">   
                                                        <span class="ql-formats" style="margin: 0;border-right: 1px solid #e5e6f0;">
                                                            <select class="ql-header">
                                                                <option value="1">Heading 1</option>
                                                                <option value="2">Heading 2</option>
                                                                <option value="3">Heading 3</option>
                                                                <option value="">Normal</option>
                                                            </select>
                                                        </span>
                                                        <span class="ql-formats" style="margin: 0;border-right: 1px solid #e5e6f0;">
                                                            <button
                                                            id="qlBoldNoteEmail-Inc"
                                                            name="qlBold"
                                                            class="ql-bold"
                                                            title="{{ 'UI.k_bold' | translate }}"
                                                            ></button>
                                                        </span>             
                                                        <span class="ql-formats" style="margin: 0;border-right: 1px solid #e5e6f0;">
                                                            <button class="ql-align" value=""></button>
                                                            <button class="ql-align" value="center"></button>
                                                            <button class="ql-align" value="right"></button>
                                                            <button class="ql-align" value="justify"></button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </quill-editor>                           
                                        </div>
                                        <div>
                                            <span
                                                class="row col-12 error"
                                                *ngIf="newTaskForm.submitted && qlDescription?.errors"
                                                class="invalid-form"
                                            >
                                                <small
                                                    *ngIf="qlDescription?.errors?.minLengthError"
                                                    class="form-text text-danger xs-font"
                                                    >{{ "Error.err_min_3_character" | translate }}</small
                                                >
                                                <small
                                                    *ngIf="qlDescription?.errors?.requiredError?.empty"
                                                    class="form-text text-danger xs-font"
                                                >
                                                    {{ "UI.k_field_required" | translate }}
                                                </small>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
                                <div class="row">
                                    <div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
                                        <div class="panel-edit-select-box">
                                            <label style="font-size: 14px;font-weight: 600;">
                                                {{"UI.k_note" | translate}}
                                            </label>
                                        </div>
                                        <div class="p-0 card border quill-card">
                                            <quill-editor
                                                id="req-add-tiny"
                                                id="articleNote"
                                                name="articleNote"
                                                placeholder="Write about the task and test cases"
                                                [minLength]="3"
                                                [required]="true"
                                                [trimOnValidation]="true"
                                                [appQuillValidator]="qlReqDescr"
                                                #qlReqDescr
                                            >
                                                <div quill-editor-toolbar class="border-0 border-bottom px-0" style="padding: 0px;">
                                                    <div class="border-bottom editor-item-flex">   
                                                        <span class="ql-formats" style="margin: 0;border-right: 1px solid #e5e6f0;">
                                                            <select class="ql-header">
                                                                <option value="1">Heading 1</option>
                                                                <option value="2">Heading 2</option>
                                                                <option value="3">Heading 3</option>
                                                                <option value="">Normal</option>
                                                            </select>
                                                        </span>
                                                        <span class="ql-formats" style="margin: 0;border-right: 1px solid #e5e6f0;">
                                                            <button
                                                            id="qlBoldNoteEmail-Inc"
                                                            name="qlBold"
                                                            class="ql-bold"
                                                            title="{{ 'UI.k_bold' | translate }}"
                                                            ></button>
                                                        </span>             
                                                        <span class="ql-formats" style="margin: 0;border-right: 1px solid #e5e6f0;">
                                                            <button class="ql-align" value=""></button>
                                                            <button class="ql-align" value="center"></button>
                                                            <button class="ql-align" value="right"></button>
                                                            <button class="ql-align" value="justify"></button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </quill-editor>                           
                                        </div>
                                    </div>
                                </div>
                            </div> -->

                            <div class="d-flex justify-content-end border-top py-1 px-2 stepper-btn">
                                <button
                                    *ngIf="
                                        !(release_data?.is_exit && release_data?.basic_info?.status?.state_id == 5) &&
                                        !disable_config &&
                                        featureViewTaskData?.permissions?.edit
                                        && featureViewTaskData?.status?.state_id !=5

                                    "
                                    [disabled]="disableSaveBtn"
                                    type="submit"
                                    class="btn btn-primary btn-next"
                                    (click)="SaveTask(newTaskForm)"
                                    rippleEffect
                                    id="btnSubmit"
                                    name="btnSubmit"
                                    [attr.data-testid]="'btnSubmit'"
                                >
                                    <i class="fa-light fa-floppy-disk align-middle mr-50 ml-0"></i>
                                    <span class="align-middle d-sm-inline-block d-none">{{
                                        "UI.k_submit" | translate
                                    }}</span>
                                </button>
                            </div>

                            <div class="col-xs-12 col-md-12 col-xl-12 col-lg-12" 
                                *ngIf="taskMappingDropdown?.[task_obj?.type?.id]">
                                <div class="row">
                                    <div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
                                        <div class="task-main-block">
                                            <div class="task-filter" *ngIf="featureViewTaskData?.permissions?.edit">
                                                <div  class="inner-section pos">
                                                    <div  class="left-section" style="width: 24%;">
                                                        <h5  class="mb-0 font-weight-bolder text-break page-title" style="font-size: 14px !important;"> 
                                                            {{relationMapping?.[task_obj?.type?.id] | translate}}
                                                        </h5>
                                                    </div>                                                
                                                    <div  class="right-section filter-pos" >                                            
                                                        <div ngbDropdown class="d-inline-block" *ngIf="
                                                                   !(
                                                                        release_data?.is_exit &&
                                                                        release_data?.basic_info?.status
                                                                            ?.state_id == 5
                                                                    ) &&
                                                                    !disable_config
                                                                    && featureViewTaskData?.status?.state_id !=5
                                                                ">
                                                            <button type="button" class="btn btn-primary" id="taskdropdown" name="taskdropdown" [attr.data-testid]="'taskdropdown'" ngbDropdownToggle>
                                                                <!-- <i class="fa fa-bug"></i> -->
                                                                <i class="fa-light fa-bezier-curve"></i>
                                                                {{  relateNameMapping?.[task_obj?.type?.id] | translate}}
                                                            </button>
                                                            <div ngbDropdownMenu aria-labelledby="taskdropdown" style="width: 100%;">
                                                                <ng-container *ngFor="let taskName of taskMappingDropdown?.[task_obj?.type?.id]">
                                                                    <button ngbDropdownItem style="width: 100%;" (click)="relateTaskHandler(taskName)">{{taskName.name | translate}}</button>
                                                                </ng-container>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-container *ngFor="let taskItem of featureViewTaskData.related_task; let i = index">
                                                <div class="task-list no-cursor">
                                                    <div class="task-left-side">
                                                        <div class="f-color">
                                                            {{taskItem?.display_id}}
                                                        </div>
                                                        <div
                                                        >
                                                            {{
                                                                taskItem?.name?.length > 100
                                                                    ? (taskItem?.name | slice: 0:100) +
                                                                    "..."
                                                                    : taskItem?.name
                                                            }}
                                                        </div>
                                                    </div>
                                                    <div class="task-right-side">
                                                        <div class="pbr" style="background-color: {{taskItem?.type?.color}}">
                                                            <i class="fa-light" class="{{taskItem?.type?.class}}"></i>
                                                            {{taskItem?.type?.name}}
                                                        </div>
                                                        <div
                                                            class="pbr"
                                                            class="pbr badge badge-light-{{
                                                            taskItem?.status?.state_color
                                                                }}"
                                                        >
                                                            {{ taskItem?.status?.state | translate }}
                                                        </div>
                                                        <div class="pbr p-all-edit badge badge-light-{{taskItem.priority?.color}}" style="background-color: {{taskItem.priority?.background_color}}">
                                                            <i class="fa-light" class="{{taskItem.priority?.icon}}"></i>
                                                            {{taskItem.priority?.name}}
                                                        </div>
                                                        <div class="pbr">
                                                            {{taskItem?.due_date}}
                                                        </div>
                                                        <div class="avatar avatar-76-80 rounded-circle"
                                                            id="divAssigneeTask{{ i }}"
                                                            tooltipClass="fadeInAnimation"
                                                            ngbTooltip="{{ taskItem?.assignee_profile?.full_name }}"
                                                            placement="left"
                                                            *ngIf="options?.user_profile_map?.[taskItem?.assignee]?.profile_image">
                                                            <span class="avatar-content">
                                                                <img [src]="options?.user_profile_map?.[taskItem?.assignee]?.profile_image"
                                                                    class="rounded-circle"
                                                                    alt="profile image"
                                                                    width="100%" />
                                                            </span>
                                                        </div>
                                                        <div *ngIf="!options?.user_profile_map?.[taskItem?.assignee]?.profile_image && taskItem?.assignee_profile?.full_name"
                                                            class="avatar avatar-76-80 rounded-circle"
                                                            [ngStyle]="{ 'background-color': options?.user_profile_map[taskItem?.assignee].avatar_color }">
                                                            <span
                                                                class="avatar-content reg-font"
                                                                [ngStyle]="{ 'background-color': options?.user_profile_map[taskItem?.assignee].avatar_color }">
                                                                {{
                                                                    taskItem?.assignee_profile?.full_name
                                                                | initials | slice: 0:2
                                                                }}</span>
                                                        </div>
                                                        <div *ngIf="!options?.user_profile_map?.[taskItem?.assignee]?.profile_image && !taskItem?.assignee_profile?.full_name"
                                                            [ngStyle]="{ 'background-color': '' }"
                                                            class="avatar avatar-76-80 rounded-circle mr-50">
                                                            <span class="avatar-content">
                                                                <i
                                                                    class="fa-light fa-user fa-1x user-icon-clr"></i>
                                                            </span>
                                                        </div>
                                                        <!-- <div class="task-assignee pbr" *ngIf="taskItem.assignee_profile?.full_name">
                                                            {{nameExtractorHandler(taskItem.assignee_profile?.full_name)}}
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </ng-container>  
                                            <ng-container *ngIf="(featureViewTaskData?.related_task?.length == 0 || !featureViewTaskData?.related_task)">
                                                <div class="d-flex flex-column align-items-center"
                                                    style="padding: 10rem;">
                                                    <div class="avatar bg-secondary rounded-lg mb-25"
                                                        id="BtnincIcons">
                                                        <div class="avatar-content">
                                                            <span><i
                                                                    class="fa-light fa-book-open fa-lg"></i></span>
                                                        </div>
                                                    </div>
                                                    <span class="sm-font text-muted">{{
                                                        "UI.k_no_records_yet" | translate
                                                        }}</span>
                                                </div>
                                            </ng-container>                                      
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </ng-scrollbar>
                </div>
			</div>
		</div>
	</div>
</div>

<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="relate-task"
	overlayClass="modal-backdrop"
>
	<app-dns-release-related-task
        *ngIf="isRelateTask"
        (onHideRelateHandler)="onHideRelateHandler($event)"
        [featureViewTaskData]="featureViewTaskData"
        [options]="options"
        (getTaskData)="getTaskData()"
        
    >
    </app-dns-release-related-task>
</core-sidebar>

<!-- <core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="new-task"
	overlayClass="modal-backdrop"
>
	<app-new-task
        *ngIf="isNewTask"
        (onHideNewTaskHandler)="onHideNewTaskHandler($event)"
        (newTaskCreateHandler)="newTaskCreateHandler($event)"
    >
    </app-new-task>
</core-sidebar> -->

<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="add-task-from-view"
	overlayClass="modal-backdrop"
>
	<app-dns-release-add-task
        *ngIf="isAddTask"
        [release_data]="release_data" 
        [options]="options"
        (onHideTask)="onHideTask($event)"
        [parentTask]="featureViewTaskData"
        [type_id] = "featureViewTaskData?.relate_type"
    ></app-dns-release-add-task>
</core-sidebar>
