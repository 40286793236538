import { Component, OnInit, Input } from '@angular/core';
import { NgEventBus } from "ng-event-bus";

@Component({
  selector: 'app-sla-response-count',
  templateUrl: './sla-response-count.component.html',
  styleUrls: ['./sla-response-count.component.scss']
})
export class SlaResponseCountComponent implements OnInit {
  @Input() tableHeader: any;
  @Input() tableData: any;
  @Input() widgetData:any;


  constructor(
    private eventBus: NgEventBus
  ) { }

  ngOnInit(): void {
    if (this.tableHeader){
      //console.log(this.tableHeader,this.tableData)
    }
 
  }
  showInciSlaSidebar= (data, col, row) => {
    // return false
    if(row?.item_filter){
      if(col) {
				row.item_filter.filters['incident_id__in'] = row?.[col + "_list"]
			}
			let payload = {
				"title": "Tickets - " + row.name,
				"app_type": "inci-grid",
				"item_filter": row.item_filter,
			}
			this.eventBus.cast('dashboard-view:sidebar', payload);		
	}
}
}
