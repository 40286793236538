import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MspPortalRoutingModule } from "./msp-portal-routing.module";
import { OrganizationsComponent } from "./component/organizations/organizations.component";
import { NgScrollbarModule } from "ngx-scrollbar";
import { TranslateModule } from "@ngx-translate/core";
import { CorePipesModule } from "@core/pipes/pipes.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedModule } from "app/shared.module";
import { DnsModule } from "app/common/dns/dns.module";
import { CoreTouchspinModule } from "@core/components/core-touchspin/core-touchspin.module";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { CoreSidebarModule } from "@core/components";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { GoogleSigninButtonModule } from "@abacritt/angularx-social-login";
import { AddOrganizationSidebarComponent } from "./component/add-organization-sidebar/add-organization-sidebar.component";
import { AdminModule } from "app/admin/admin.module";
import { MspUserComponent } from "./component/msp-user/msp-user.component";
import { FileUploadModule } from "ng2-file-upload";
import { NgxDropdownTreeviewModule } from "app/common/dns/components/ngx-dropdown-treeview-select/ngx-dropdown-treeview.module";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ToastrModule } from "ngx-toastr";
import { RouterModule } from "@angular/router";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { BreadcrumbModule } from "app/common/layout/components/content-header/breadcrumb/breadcrumb.module";
import { QuillModule } from "ngx-quill";
import { AssignUserSidebarComponent } from './component/assign-user-sidebar/assign-user-sidebar.component';
import { OrganisationViewComponent } from './component/organisation-view/organisation-view.component';
import { MSPGridNameDesignComponent } from './component/msp-grid-name-design/msp-grid-name-design.component';
import { UsersModule } from "app/base/users/users.module";
import { OrganizationProductDetailsComponent } from './component/organization-product-details/organization-product-details.component';
import { MspAssignedUserComponent } from './component/msp-assigned-user/msp-assigned-user.component';

@NgModule({
	declarations: [OrganizationsComponent, AddOrganizationSidebarComponent, MspUserComponent, AssignUserSidebarComponent, OrganisationViewComponent, MSPGridNameDesignComponent, OrganizationProductDetailsComponent, MspAssignedUserComponent],
	imports: [
		CommonModule,
		MspPortalRoutingModule,
		CommonModule,
		AdminModule,
		NgScrollbarModule,
		TranslateModule,
		CorePipesModule,
		NgbModule,
		SharedModule,
		DnsModule,
		CoreTouchspinModule,
		FormsModule,
		NgSelectModule,
		CoreSidebarModule,
		NgxIntlTelInputModule,
		GoogleSigninButtonModule,
		CommonModule,
		NgbModule,
		FileUploadModule,
		NgxDropdownTreeviewModule,
		InfiniteScrollModule,
		PerfectScrollbarModule,
		ToastrModule,
		FormsModule,
		RouterModule,
		SweetAlert2Module.forRoot(),
		BreadcrumbModule,
		QuillModule.forRoot(),
		UsersModule
	],
})
export class MspPortalModule {}
