import {Component, OnInit} from "@angular/core";
import {CoreConfigService} from "@core/services/config.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

@Component({
	selector: "app-terms",
	templateUrl: "./terms.component.html",
	styleUrls: ["./terms.component.scss"],
})
export class TermsComponent implements OnInit {
	public coreConfig: any;
	term = {
		effective_date: "[●]",
		last_updated: "[●]",
		privacy_policy_location: "[]",
	};

	// Private
	private _unsubscribeAll: Subject<any>;

	/**
	 * Constructor
	 *
	 * @param {CoreConfigService} _coreConfigService
	 */
	constructor(private _coreConfigService: CoreConfigService) {
		this._unsubscribeAll = new Subject();

		// Configure the layout
		this._coreConfigService.config = {
			layout: {
				navbar: {
					hidden: true,
				},
				footer: {
					hidden: true,
				},
				menu: {
					hidden: true,
				},
				customizer: false,
				enableLocalStorage: false,
			},
		};
	}

	// Lifecycle Hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Subscribe to config changes
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
			this.coreConfig = config;
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(undefined);
		this._unsubscribeAll.complete();
	}
}
