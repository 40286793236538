<!-- fluent Theme menu -->
<ng-container *ngIf="currentscreen === 'fluent'">
	<!-- Vertical Menu -->
	<ng-container *ngIf="layout === 'vertical'">
		<ng-container *ngFor="let item of menu">
			<!-- section -->
			<li core-menu-vertical-section *ngIf="item.type == 'section'" [item]="item" class="navigation-header"></li>

			<!-- sub-menu item/collapsible of sections  -->
			<ng-container *ngIf="item.type == 'section'">
				<ng-container *ngFor="let item of item.children">
					<!-- item | if openInNewTab set routerLink & routerLinkActive blank -->
					<li
						core-menu-vertical-item
						*ngIf="item.type == 'item'"
						[item]="item"
						[title]="item.title"
						[ngClass]="{ disabled: item.disabled === true }"
						[routerLink]="item.openInNewTab ? [] : [item.url]"
						[routerLinkActive]="!item.openInNewTab ? 'active' : ''"
						[routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
						attr.data-testid="btn_{{ item.id }}"
					></li>
					<!-- collapsible -->
					<li
						core-menu-vertical-collapsible
						*ngIf="item.type == 'collapsible'"
						[item]="item"
						[title]="item.title"
						class="nav-item has-sub"
						attr.data-testid="btn_{{ item.id }}"
					></li>
				</ng-container>
			</ng-container>

			<!-- item -->
			<li
				core-menu-vertical-item
				*ngIf="item.type == 'item'"
				[item]="item"
				[title]="item.title"
				[ngClass]="{ disabled: item.disabled === true }"
				[routerLink]="item.openInNewTab ? [] : [item.url]"
				[routerLinkActive]="!item.openInNewTab ? 'active' : ''"
				[routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
				attr.data-testid="btn_{{ item.id }}"
			></li>
			<!-- collapsible -->
			<li
				core-menu-vertical-collapsible
				*ngIf="item.type == 'collapsible'"
				[item]="item"
				class="nav-item has-sub"
				attr.data-testid="btn_{{ item.id }}"
			></li>
		</ng-container>
	</ng-container>
	<!--/ Vertical Menu -->
	<!-- <ng-container *ngIf="layout === 'vertical'">
    <ng-container *ngFor="let item of menu">
      <li
      core-menu-vertical-item
      *ngIf="item.type == 'item'"
      [item]="item"
      [ngClass]="{ disabled: item.disabled === true }"
      [routerLink]="item.openInNewTab ? [] : [item.url]"
      [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
      [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
      class="navigation-header"
      [title]="item.title"
    ></li>
      <li
        core-menu-vertical-section
        *ngIf="
          item.type == 'collapsible'"
        [item]="item"
        class="navigation-header"
        (click)="showSubMenu(item.id)"
        [title]="item.title"
        [ngClass]="hoverClass" 
        (mouseover)="changeStyle($event, item.id)" 
        (mouseout)="changeStyle($event, item.id)"
      >
    </li>
    </ng-container>
  </ng-container>
  <div class="sub-sec" 
  (mouseover)="changeStyle($event)" 
  (mouseout)="changeStyle($event)">
    <ul class="active-subsec" [perfectScrollbar]>
      <ng-container *ngFor="let item of activeSubmenu">
        <li
          core-menu-vertical-item
          class="sub-menu-item"
          *ngIf="
            item.type == 'item'"
          [item]="item"
          [title]="item.title"
          [ngClass]="{ disabled: item.disabled === true }"
          [routerLink]="item.openInNewTab ? [] : [item.url]"
          [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
          [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
        ></li>
  
        <li
          core-menu-vertical-collapsible
          *ngIf="
            item.type == 'collapsible' &&
            (item.role
              ? item.role.includes(currentUser.role)
              : false || item.role == undefined)
          "
          [item]="item"
          [title]="item.title"
          class="nav-item has-sub"
        ></li>
      </ng-container>
    </ul>
  </div> -->
</ng-container>

<!-- End of fluent Theme menu -->
<ng-container *ngIf="currentscreen !== 'fluent'">
	<!-- Vertical Menu -->
	<ng-container *ngIf="layout === 'vertical'">
		<ng-container *ngFor="let item of menu">
			<!-- section -->
			<li core-menu-vertical-section *ngIf="item.type == 'section'" [item]="item" class="navigation-header"></li>

			<!-- sub-menu item/collapsible of sections  -->
			<ng-container *ngIf="item.type == 'section'">
				<ng-container *ngFor="let item of item.children">
					<!-- item | if openInNewTab set routerLink & routerLinkActive blank -->
					<li
						core-menu-vertical-item
						*ngIf="item.type == 'item'"
						[item]="item"
						[ngClass]="{ disabled: item.disabled === true }"
						[routerLink]="item.openInNewTab ? [] : [item.url]"
						[routerLinkActive]="!item.openInNewTab ? 'active' : ''"
						[routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
						attr.data-testid="btn_{{ item.id }}"
					></li>
					<!-- collapsible -->
					<li
						core-menu-vertical-collapsible
						*ngIf="item.type == 'collapsible'"
						[item]="item"
						class="nav-item has-sub"
						attr.data-testid="btn_{{ item.id }}"
					></li>
				</ng-container>
			</ng-container>

			<!-- item -->
			<li
				core-menu-vertical-item
				*ngIf="item.type == 'item'"
				[item]="item"
				[ngClass]="{ disabled: item.disabled === true }"
				[routerLink]="item.openInNewTab ? [] : [item.url]"
				[routerLinkActive]="!item.openInNewTab ? 'active' : ''"
				[routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
				attr.data-testid="btn_{{ item.id }}"
			></li>
			<!-- collapsible -->
			<li
				core-menu-vertical-collapsible
				*ngIf="item.type == 'collapsible'"
				[item]="item"
				class="nav-item has-sub"
				attr.data-testid="btn_{{ item.id }}"
			></li>
		</ng-container>
	</ng-container>
	<!--/ Vertical Menu -->

	<!-- Horizontal Menu -->
	<ng-container *ngIf="layout === 'horizontal'">
		<ng-container *ngFor="let item of menu">
			<!-- section -->
			<li
				core-menu-horizontal-collapsible
				*ngIf="item.type == 'section'"
				[item]="item"
				class="dropdown nav-item"
				[ngClass]="[item.position === 'bottom' ? 'menu-bottom' : '']"
				attr.data-testid="btn_{{ item.id }}"
			></li>

			<!-- item | if openInNewTab set routerLink & routerLinkActive blank -->
			<li
				core-menu-horizontal-item
				*ngIf="item.type == 'item'"
				[item]="item"
				[ngClass]="{ disabled: item.disabled === true }"
				[routerLink]="item.openInNewTab ? [] : [item.url]"
				[routerLinkActive]="!item.openInNewTab ? 'active' : ''"
				[routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
				attr.data-testid="btn_{{ item.id }}"
			></li>

			<!-- collapsible -->
			<li
				core-menu-horizontal-collapsible
				*ngIf="item.type == 'collapsible'"
				[item]="item"
				class="dropdown dropdown-submenu"
				attr.data-testid="btn_{{ item.id }}"
			></li>
		</ng-container>
	</ng-container>
	<!--/ Horizontal Menu -->
</ng-container>
