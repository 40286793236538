import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
	selector: "app-pretty-select",
	templateUrl: "./pretty-select.component.html",
	styleUrls: ["./pretty-select.component.scss"],
})
export class PrettySelectComponent implements OnInit {
	@Input() defaultOption: any;
	@Input() optionList: any;
	@Output() onItemChange: EventEmitter<any> = new EventEmitter<any>();
	public elemOption = [];

	constructor() {}

	ngOnInit(): void {
		this.elemOption = this.optionList;
		if (this.defaultOption) {
			this.elemOption.forEach((e) => {
				e["isActive"] = false;
				if (e.id === this.defaultOption) {
					e["isActive"] = true;
				}
			});
		}
	}

	changeEvent = (item) => {
		this.elemOption.forEach((e) => {
			e["isActive"] = false;
			if (e.id === item.id) {
				e["isActive"] = true;
			}
		});
		this.onItemChange.emit(item);
	};
}
