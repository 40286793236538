<div class="slideout-content default-slideout">
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
		<div class="modal-dialog">
			<div class="modal-content pt-0">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">
						{{ title | translate }}
					</h5>
					<button
						id="btnClose"
						name="Close"
						type="button"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						(click)="toggleSidebar()"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body p-0">
					<div class="slider-height">
						<div class="incident-info flex-initial border-bottom">
							<div class="modal-content-header d-flex justify-content-between align-items-center m-25">
								<div class="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12 action-search" 
								*ngIf="child_objs?.length > 0 || filterData">
									<label for="searchConfig"
										>
									</label>
									<app-search
										(getfilterData)="getFilterSearch($event)"
										(genericFilter)="genericSearchFilter($event)"
										[columnOptions]="searchOptions"
										[savedData]="savedData"
										[Options]="childModuleOptions"
										[module]="relationModule"
									></app-search>
								</div>
								<div class="form-group col-lg-5 col-md-5 col-sm-12 col-xs-12" *ngIf="child_objs?.length > 0">
									<label for="txtName"
										>{{ "UI.k_relation_type" | translate }} <span class="required-field">*</span>
									</label>
									<ng-select
										required
										[(ngModel)]="relationType"
										[items]="options?.relation_type"
										[addTag]="false"
										[closeOnSelect]="true"
										[searchable]="true"
										bindValue="id"
										bindLabel="name"
										placeholder="{{'UI.k_select' | translate}}"
										name="selRealtion"
										id="selRealtion"
										[clearable]="false"
									>
									</ng-select>
								</div>
							</div>
						</div>
					
					<ng-container *ngIf="dataLoaded">
					<div class="main-content flex-height detail-sec flex-1">
						<div class="main-sec">
						<div class="action-section">
								<div class="table-view">
									<dns-server-grid
											[rows]="child_objs"
											[options]="options"
											(page)="setPage($event)"
											[count]="child_objs_count"
											[columns]="columns"
											[isEditable]="false"
											(sort)="onSort($event)"
											(setlimit)="setLimit($event)"
											class="bootstrap core-bootstrap"
											[permissions]="permissions"
											[scrollbarH]="true"
											[virtualization]="false"
											(onSelectCheckbox)="onSelectRow($event)"
											[selectionType]="selectionCheckboxType"
											[selected]="selectedData"
											[filterData]="filterData"
											[offset]="pageNumber"
											[limit]="limit"
											[loadingIndicator]="true"
											[model] = "model"
										>
									</dns-server-grid>
								</div>
						</div>
						</div>
					</div>
						<div class="detail-btn-sec d-flex justify-content-end pt-1 border-top flex-initial" *ngIf="child_objs?.length > 0">
							<button
								type="submit"
								class="btn btn-primary mr-1"
								rippleEffect
								id="btnAssociate"
								name="btnAssociate"
								(click)="associateRelation()"
								[disabled]="disableSubmit"
							>
								<i class="fa-light fa-save align-middle mr-50 ml-0"></i>
								<span class="align-middle d-sm-inline-block d-none">
									{{ "UI.k_submit" | translate }}								
								</span>
							</button>
						</div>
					</ng-container>
					</div>
				<div *ngIf="!dataLoaded" class="d-flex justify-content-center align-items-center height-400">
					<div class="spinner-border text-primary" aria-hidden="true"></div>
				</div>
			`	</div>
			</div>
		</div>
	</div>
</div>