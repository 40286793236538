<div class="d-flex justify-content-between">
	<div class="skeleton" class="px-2">
		<ngx-skeleton-loader animation="pulse" [theme]="{ height: '15px', width: '140px' }"></ngx-skeleton-loader>
	</div>
	<div class="d-flex">
		<div class="skeleton" class="px-2">
			<ngx-skeleton-loader animation="pulse" [theme]="{ height: '15px', width: '50px' }"></ngx-skeleton-loader>
		</div>
		<div class="skeleton" class="px-2">
			<ngx-skeleton-loader animation="pulse" [theme]="{ height: '15px', width: '50px' }"></ngx-skeleton-loader>
		</div>
		<div class="skeleton" class="px-2">
			<ngx-skeleton-loader animation="pulse" [theme]="{ height: '15px', width: '50px' }"></ngx-skeleton-loader>
		</div>
		<div class="skeleton" class="px-2">
			<ngx-skeleton-loader animation="pulse" [theme]="{ height: '15px', width: '50px' }"></ngx-skeleton-loader>
		</div>
	</div>
</div>
<div class="d-flex justify-content-between p-1">
	<div class="d-flex justify-content-between" *ngFor="let item of generateFake(4)">
		<ngx-skeleton-loader
			animation="pulse"
			[theme]="{ height: '500px', width: '300px', 'margin-top': '0.5rem', 'margin-bottom': '0.5rem' }"
		></ngx-skeleton-loader>
	</div>
</div>
