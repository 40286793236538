import { Injectable } from '@angular/core'
import { ApiService } from 'app/common/services/api/api.service'
import { HttpClient } from '@angular/common/http'

@Injectable({
  	providedIn: 'root'
})
export class TagService extends ApiService {
	constructor(http: HttpClient) {
	  	super(http)
	}
	 getTags(payload) {
		return this.get('ux/common/tag/tag/', payload)
	}
	getTag(id) {
		return this.get('ux/common/tag/tag/'+id+'/')
	}
	saveTag(payload) {
		return this.post('ux/common/tag/tag/', payload)
	}
	deleteTag(id) {
		return this.delete('ux/common/tag/tag/'+id+'/')
	}
	getOptions(payload: {}) {
		return this.get("ux/common/tag/tag/options/", payload);
	}
	getFilterOptions(payload: any) {
		return this.get("ux/common/filters/filters/filter_options/", payload);
	}

	getSavedFilter(payload) {
        return this.get("ux/common/filters/filters/filter/", payload);
	}
	savePageFilter(payload: any) {
	    //console.log("comin indid")
		return this.post("ux/common/user/profile/preference/", payload);
	}

	validateTagUsages(tag, payload: any) {
		return this.get(`ux/common/tag/tag/validate_tag_user_usages/${tag}/`, payload);
	}


}
