import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { TranslateService } from '@ngx-translate/core';
import { customSearch, isValidDateRange } from 'app/common/utils/utils';
import { environment } from 'environments/environment';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { ToastrService } from 'ngx-toastr';
// import { throwError } from 'rxjs';
// import { catchError, map } from 'rxjs/operators';

const URL = `${environment.instance_url}` + "media";
@Component({
  selector: 'app-common-view-task',
  templateUrl: './common-view-task.component.html',
  styleUrls: ['./common-view-task.component.scss']
})
export class CommonViewTaskComponent implements OnInit {
    @Input() options: any = [];
    @Input() ref_data: any = {};
    @Input() task_obj: any = {}
    @Output() onHideTask = new EventEmitter(false);
    @Output() onHideSaveTask = new EventEmitter(false);
    public disableSaveBtn: boolean = false;
    public task_title = this._translateService.instant("UI.k_view_task");
    public priority: any = []
    public status: any = []
    public users_list: any = []
    public basicDateTimeOptions: FlatpickrOptions = {
        enableTime:true,
        altInput: true
    }
	public basicDateOptions: FlatpickrOptions = {
		altInput: true,
		// dateFormat: "%b %d, %Y",
		// altFormat: "M d, Y",
		altInputClass: "form-control flat-picker flatpickr-input",
	};
    show_due_date = false;    

    constructor(private _coreSidebarService: CoreSidebarService,
    private _toastr: ToastrService,
    private _translateService: TranslateService,
    ) { 

    }

    ngOnInit(): void {
        this.priority = this.options?.priority
        this.status = this.options?.task_status
        this.users_list = this.options?.users
        this.show_due_date
        // this.task_obj["ref_id"] = this.ref_data?.change_id
        // this.task_obj["ref_display_id"] = this.ref_data?.display_id
        setTimeout(() => {
            if (this.ref_data?.due_date){
                this.basicDateTimeOptions["maxDate"] = this.ref_data?.due_date;                
            }  
            this.show_due_date = true
        }, 300);
        this.task_title = this._translateService.instant("UI.k_task_edit");
    }
    toggleSidebar = (name): void => {
        this.onHideTask.emit()
    };
    
    SaveTask = (data) => {
        if (data.form.valid === true) {
            if (this.task_obj.due_date instanceof Array) {
                this.task_obj.due_date = this.task_obj.due_date[0];
                this.task_obj.due_date = this.task_obj.due_date.toDateString()

            }
            // this.disableSaveBtn = true
            this.onHideSaveTask.emit(this.task_obj)
        }
    };

    onStatusChange = (data) => {
      this.task_obj.status = data;
    };

    statusBadgeChange = (data) => {
      this.task_obj[data["field"]] = data?.event ? data.event : {};
    };

    customSearchFn(term: string, item: any) {
		return customSearch(term, item, "full_name", "email");
	}

    onAssigneeChange = ($event) => {
        this.task_obj["assignee_profile"] = {
            "profile_id": $event?.profile_id,
            "name": $event?.full_name,
            "full_name": $event?.full_name, 
            "email": $event?.email,
        }
    }

}