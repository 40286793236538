import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
	ViewEncapsulation,
} from "@angular/core";
import { FlatpickrOptions } from "ng2-flatpickr";
import { catchError, map } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { NgbCalendar, NgbDateParserFormatter, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { throwError } from "rxjs";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { ChangeViewService } from "app/servicedesk/change-management/services/change-view.service";
import { TagService } from "app/base/tags/services/tag.service";
import { _deepCopyJson, customSearch, convertToCalendarTime } from "app/common/utils/utils";
import { User } from "app/auth/models";
import { AuthService } from "app/base/auth/services/auth.service";
import { GlobalConstants } from "app/app.globalConstants";
import { CountryISO, PhoneNumberFormat, SearchCountryField } from "ngx-intl-tel-input";
import { TreeviewItem } from "ngx-treeview";
import { StatusPickerComponent } from "../../../../common/dns/components/status-picker/status-picker.component";
import Swal from "sweetalert2";
import moment from "moment";
interface Quill {
	getModule(moduleName: string);
}

interface BetterTableModule {
	insertTable(rows: number, columns: number): void;
}

@Component({
	selector: "app-dns-change-edit-sidebar",
	templateUrl: "./dns-change-edit-sidebar.component.html",
	styleUrls: ["./dns-change-edit-sidebar.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class DnsChangeEditSidebarComponent implements OnInit {
	public quill: Quill;
	@ViewChild("tagComp") tagComp: ElementRef;
	@ViewChild("scrollBottom") private scrollContainer: ElementRef;
	@Input() dropdown;
	@Input() change_data;
	@Input() change_data_copy;
	@Input() currentUserProfileId;
	@Input() change_extra_info;
	@Input() stateKeyIdMap;
	@Input() permissions: any;
	@Output("onhideInlineEdit") onhideInlineEdit = new EventEmitter<any>();
	@Output("reloadCommunicationData") reloadCommunicationData = new EventEmitter<any>();
	@Output("getChange") getChange = new EventEmitter<any>();
	@Output("saveChange") saveChange = new EventEmitter<any>();
	@ViewChild("changeDuedate") Duedate: FlatpickrOptions;
	@ViewChild("actualcloserTime") actualcloserTime: FlatpickrOptions;
	@ViewChild("agreedClosuredate") agreedClosuredate: FlatpickrOptions;
	@ViewChild("actualstarttime") actualstarttime: FlatpickrOptions;
	@ViewChild("sliderStatusPickerComponent") sliderStatusPickerComponent: StatusPickerComponent;
	@Input() taskMaxDueDate: any
	public imgHeightWidthStr = "img height='400px' width='100%'";
	public showTable = false;
	public tag: any = {};
	tagPermission: any;
	showResln: any = false;
	notClosedTask : any = [];
	// Date
	public dateOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: true,
	};
	public dueDateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: true
	};
	// Date and Time
	public dateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: true,
		dateFormat: "%b",
		altFormat: "M d, Y H:i K",
		mode: "single",
		altInputClass: "form-control flat-picker flatpickr-input",
		disable: [
			(date) => {
				return this.disable_config;
			},
		],
	};
	public agreedDateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: true,
		dateFormat: "%b %d, %Y %I:%M %p",
		altFormat: "M d, Y H:i K",
		mode: "single",
		altInputClass: "form-control flat-picker flatpickr-input",
	};
	public disableDatepickOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: true,
		clickOpens: false,
		dateFormat: "Z",
		altFormat: "Y-m-d h:i K",
		altInputClass: "form-control flat-picker flatpickr-input disble-field",
	};
	selfAssignSel = false;
	team_id;
	currentUser: User;
	custom_field_config: any;
	custom_field_data: any;
	// is_save_disabled = false;
	is_tag_save_disabled = false;
	is_change_sidebar_save_disabled = false;
	// treeInputData = [];
	separateDialCode = true;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
	PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom, CountryISO.India];
	userTZ: any;
	is_service_change = false;
	service_change_data: any;
	service_config = {};
	classification_config = {};
	disable_config = false;
	prev_current_assignment_info: any = {};
	startEndDateInvalid: boolean = false;
	startEndDateToaster: any;

	constructor(
		private _coreSidebarService: CoreSidebarService,
		private _changeViewService: ChangeViewService,
		private _toastr: ToastrService,
		private _modalService: NgbModal,
		private _tagService: TagService,
		private _translateService: TranslateService,
		private calendar: NgbCalendar,
		public formatter: NgbDateParserFormatter,
		private _authService: AuthService
	) {
		this._authService.currentUser.subscribe((x) => (this.currentUser = x));
	}

	public get tableModule(): BetterTableModule {
		return this.quill.getModule("better-table");
	}

	public editorCreated(event: Quill): void {
		this.quill = event;
		// console.log(this.quill,"11111111111111111");

		// Example on how to add new table to editor
		// this.addNewtable();
	}

	addNewtable(e: Quill): void {
		const table = this.quill.getModule("better-table");
		document.querySelector("#insert-table").addEventListener("click", function () {
			table.insertTable(2, 2);
		});
		// this.tableModule.insertTable(4, 4);
	}

	ngOnInit(): void {
		let userParams = JSON.parse(localStorage.getItem("userParams"));
		this.userTZ = userParams?.user_tz;
		try {
			let today = new Date(new Date().toLocaleString("en-US", { timeZone: this.userTZ }));
			this.dateTimeOptions["defaultDate"] = today;
		} catch (e) {
			//console.log("Error in selecting current time based on timezone ", e);
		}
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.tagPermission = user_permissions["tag"];
		this.team_id = this.change_data?.current_assignment_info?.group;
		if (
			this.change_data?.current_assignment_info &&
			this.change_data?.current_assignment_info?.group == undefined &&
			this.change_data?.current_assignment_info?.assignee != undefined
		) {
			this.team_id = "self_assign";
			this.selfAssignSel = true;
		}

		if (
			[6, 7].includes(this.change_data.basic_info?.status?.state_id) ||
			this.change_data?.basic_info?.status?.is_pir ||
			this.change_extra_info?.disable_config == true
		) {
			this.disable_config = true;
		}
	}

	toggleSidebar = (name, form): void => {
		this.onhideInlineEdit.emit("hide");
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
	};

	openModalAD(TagComp, name) {
		this.tag["name"] = name;
		if (this.dropdown.tagTypeList != undefined) {
			this.tag["type"] = this.dropdown.tagTypeList[0].id;
		}
		this._modalService.open(TagComp, {
			centered: true,
			animation: false,
			size: "md",
		});
		this.is_tag_save_disabled = false;
	}

	onCreateTag(data) {
		this.is_tag_save_disabled = true;
		if (data.form.valid === true) {
			this._tagService
				.saveTag(this.tag)
				.pipe(
					map((response: any) => {
						if (response) {
							let tag_id = response?.id;
							if (response["status"] == "success") {
								this._toastr.success(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Message.msg_save_success"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
								this._modalService.dismissAll();
								this._changeViewService
									.getOptions({ call_for_tags: true })
									.pipe(
										map((response: any) => {
											this.dropdown.tagList = response["tags"];
											this.change_data.tag.push(tag_id);
											this.change_data.tag = [...this.change_data.tag];
										})
									)
									.subscribe();
							} else {
								this.is_tag_save_disabled = false;
								this._toastr.error(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Error.err_save_failed"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							}
						}
						// Success
					}),
					catchError((error) => {
						this.is_tag_save_disabled = false;
						this._toastr.error(
							this._translateService.instant("Error.err_tag_save_failed"),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						return throwError(error.statusText);
					})
				)
				.subscribe();
		} else {
			this.is_tag_save_disabled = false;
		}
	}

	callBackOption(name) {
		this.change_data.basic_info.callback = name;
	}

	addCustomField() {}

	//data-form reference, payload-change data
	saveChangeData(data) {
		this.is_change_sidebar_save_disabled = true;
		if (data.form.valid === true) {
			this.get_custom_data(
				this.change_extra_info?.custom_field_config?.custom_fields,
				this.change_data?.custom_field_data
			);
			// this.change_data["service_form_data"] = this.change_data?.service_form_data
			this.change_data["custom_field"] = this.change_extra_info?.custom_field_config?.custom_fields
			if (this.change_data?.actual_start_time instanceof Array) {
				this.change_data.actual_start_time = this.change_data.actual_start_time[0];
			}
			if (this.change_data?.actual_closure_time instanceof Array) {
				this.change_data.actual_closure_time = this.change_data.actual_closure_time[0];
			}
			if (this.change_data?.agreed_closure_date instanceof Array) {
				this.change_data.agreed_closure_date = this.change_data.agreed_closure_date[0];
			}
			if (this.change_data.due_date instanceof Array) {
				this.change_data.due_date = this.change_data.due_date[0];
				// this.change_data.due_date = this.change_data.due_date.toDateString();
			}
			this.change_data.actual_start_time = convertToCalendarTime(this.change_data.actual_start_time);
			this.change_data.agreed_closure_date = convertToCalendarTime(this.change_data.agreed_closure_date);
			this.change_data.actual_closure_date = convertToCalendarTime(this.change_data.actual_closure_date);

			this.saveChangeCall(this.change_data);
		} else {
			this.is_change_sidebar_save_disabled = false;
		}
	}

	saveChangeCall = (data) => {
		this._changeViewService
			.editChange(this.change_data?.change_id, data)
			.pipe(
				map((response: any) => {
					if (response) {
						this._toastr.success(
							this._translateService.instant(response["message"]),
							this._translateService.instant("Message.msg_save_success"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						this._modalService.dismissAll();
						this.change_data_copy = data;
						// this.is_save_disabled = false
						this.getChange.emit();
						this.reloadCommunicationData.emit();
					} else {
						this._toastr.error(
							this._translateService.instant("Error.err_change_save_failed"),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						// this.is_save_disabled = false
					}
					this.onhideInlineEdit.emit("hide");
					this._coreSidebarService.getSidebarRegistry("change-edit-sidebar").toggleOpen();
					// Success
					this.is_change_sidebar_save_disabled = false;
				}),
				catchError((error) => {
					// this.is_save_disabled = false
					return throwError(error.statusText);
				})
			)
			.subscribe();
	};

	groupAssignChange(dropdown, team_id) {
		if (team_id == "self_assign") {
			if (this.dropdown?.current_user_group?.length < 1) {
				this.change_data["current_assignment_info"]["level"] = null;
				this.change_data["current_assignment_info"]["expertise"] = null;
				this.change_data["current_assignment_info"]["level_id"] = null;
				this.change_data["current_assignment_info"]["expertise_id"] = null;
				this.change_data["current_assignment_info"]["group"] = null;
				this.change_data["current_assignment_info"]["group_name"] = null;
				this.change_data["current_assignment_info"]["group_type"] = null;
				this.change_data["current_assignment_info"]["assignee"] = this.currentUser?.profile?.profile_id;
				this.change_data["current_assignment_info"]["assignee_profile"] = this.currentUser?.profile;
			}
		} else {
			this.groupChange(dropdown, team_id);
		}
	}

	clearExpertiselevelAssignee(assignment) {
		assignment["level"] = null;
		assignment["expertise"] = null;
		assignment["level_id"] = null;
		assignment["expertise_id"] = null;
		assignment["assignee"] = null;
		assignment["assignee_profile"] = null;
		this.change_extra_info["selected_assignee_info"] = {
			profile_image: "",
			full_name: "",
			email: "",
			profile: { avatar_color: "" },
		};
	}

	clearlevelAssignee(assignment) {
		assignment["level"] = null;
		assignment["level_id"] = null;
		assignment["assignee"] = null;
		assignment["assignee_profile"] = null;
		this.change_extra_info["selected_assignee_info"] = {
			profile_image: "",
			full_name: "",
			email: "",
			profile: { avatar_color: "" },
		};
	}

	groupChange(options, team_id) {
		this.change_data["current_assignment_info"]["group"] = team_id;
		let selected_group = options.filter((e) => e.team_id == this.change_data["current_assignment_info"]["group"]);
		this._changeViewService
			.getTeamUserMap({ team_id: this.change_data.current_assignment_info.group })
			.subscribe((response) => {
				this.dropdown.team_user_profile_map = response;
			});

		if (selected_group.length > 0) {
			this.change_extra_info["group_type"] = selected_group[0]["group_type"];
			this.change_data["current_assignment_info"]["group_type"] = selected_group[0]["group_type"];
			this.change_data["current_assignment_info"]["group_name"] = selected_group[0]["name"];
			if (selected_group[0]["group_type"] === "Expert") {
				this.dropdown["assignee"] = selected_group[0]["staffs"];
				this.dropdown["expertise"] = [];
			} else {
				this.dropdown["expertise"] = selected_group[0]["level_staff"];
				this.dropdown["assignee"] = [];
			}
			this.clearExpertiselevelAssignee(this.change_data?.current_assignment_info);
			this.change_extra_info["selected_assignee_info"] = {
				profile_image: "",
				full_name: "",
				email: "",
				profile: { avatar_color: "" },
			};
			this.dropdown["level"] = [];
			if (this.selfAssignSel) {
				if (selected_group[0]["group_type"] === "Expert") {
					this.change_data["current_assignment_info"]["assignee"] = this.currentUserProfileId;
					this.change_data["current_assignment_info"]["assignee_profile"] = this.currentUser?.profile;
					this.change_data["current_assignment_info"]["level"] = null;
					this.change_data["current_assignment_info"]["expertise"] = null;
					this.change_data["current_assignment_info"]["level_id"] = null;
					this.change_data["current_assignment_info"]["expertise_id"] = null;
					this.dropdown["current_user_expertise"] = [];
				} else {
					if (selected_group[0]["level_staff"].length > 1) {
						this.dropdown["current_user_expertise"] = selected_group[0]["level_staff"];
						this.change_data["current_assignment_info"]["expertise"] = null;
						this.change_data["current_assignment_info"]["level"] = null;
						this.change_data["current_assignment_info"]["level_id"] = null;
						this.change_data["current_assignment_info"]["expertise_id"] = null;
					} else {
						this.change_data["current_assignment_info"]["expertise"] =
							selected_group[0]["level_staff"][0]["expertise"];
						this.change_data["current_assignment_info"]["level"] =
							selected_group[0]["level_staff"][0]["expertGroupLevel"][0]["level"];
						this.change_data["current_assignment_info"]["expertise_id"] =
							selected_group[0]["level_staff"][0]["expertise_id"];
						this.change_data["current_assignment_info"]["level_id"] =
							selected_group[0]["level_staff"][0]["expertGroupLevel"][0]["level_id"];
					}
					this.change_data["current_assignment_info"]["assignee"] = this.currentUserProfileId;
					this.change_data["current_assignment_info"]["assignee_profile"] = this.currentUser?.profile;
				}
				this.assigneeChange();
			} else {
				this.clearExpertiselevelAssignee(this.change_data?.current_assignment_info);
			}
		} else {
			if (!this.selfAssignSel) {
				this.dropdown["expertise"] = [];
				this.dropdown["level"] = [];
				this.dropdown["assignee"] = [];
			} else if (this.selfAssignSel) {
				this.dropdown["current_user_expertise"] = [];
			}
			this.change_data["current_assignment_info"]["group_name"] = "";
			this.clearExpertiselevelAssignee(this.change_data?.current_assignment_info);
		}
	}

	//evt-expertise
	expertiseChange(dropdown) {
		if (!this.selfAssignSel) {
			this.dropdown["assignee"] = [];
			this.change_extra_info["selected_assignee_info"] = {
				profile_image: "",
				full_name: "",
				email: "",
				profile: { avatar_color: "" },
			};
			this.clearlevelAssignee(this.change_data.current_assignment_info);
		}
		if (this.change_data?.current_assignment_info?.expertise) {
			let selected_expertise = dropdown?.filter(
				(e) => e.expertise == this.change_data["current_assignment_info"]["expertise"]
			);
			this.change_data["current_assignment_info"]["expertise_id"] = selected_expertise[0]["expertise_id"];
			if (this.selfAssignSel) {
				this.change_data["current_assignment_info"]["level"] =
					selected_expertise[0]["expertGroupLevel"][0]["level"];
				this.change_data["current_assignment_info"]["level_id"] =
					selected_expertise[0]["expertGroupLevel"][0]["level_id"];
			} else {
				this.dropdown["level"] = selected_expertise[0]["expertGroupLevel"];
			}
		} else {
			this.clearExpertiselevelAssignee(this.change_data?.current_assignment_info);
			this.dropdown["level"] = [];
			this.dropdown["assignee"] = [];
		}
	}

	//evt-level
	levelChange() {
		let selected_level = this.dropdown["level"].filter(
			(e) => e.level == this.change_data["current_assignment_info"]["level"]
		);
		if (selected_level?.length > 0) {
			this.change_data["current_assignment_info"]["level_id"] = selected_level[0]["level_id"];
			this.dropdown["assignee"] = selected_level[0]["users"];
			this.change_extra_info["selected_assignee_info"] = {
				profile_image: "",
				full_name: "",
				email: "",
				profile: { avatar_color: "" },
			};
			this.change_data["current_assignment_info"]["assignee"] = null;
			this.change_data["current_assignment_info"]["assignee_profile"] = null;
		} else {
			this.dropdown["assignee"] = [];
			this.clearExpertiselevelAssignee(this.change_data);
		}
	}

	assigneeChange() {
		let selected_assignee = this.dropdown?.team_user_profile_map?.[
			this.change_data?.current_assignment_info?.assignee
		]
		if (selected_assignee){
			this.change_extra_info["selected_assignee_info"] = selected_assignee
			this.change_data["current_assignment_info"]["assignee_profile"] = selected_assignee
		}
	}

	getClosureNote = (evt) => {
		let tmpHTML = evt.html.replace("img", this.imgHeightWidthStr);
		this.change_data.close_info.closure_note = tmpHTML;
		let cf_data = this.change_extra_info?.custom_field_config?.custom_fields?.filter(
			(d) => d?.section == "closure"
		);
		this.change_data.close_info["custom_field_data"] = this.get_custom_data(
			cf_data,
			this.change_data?.custom_field_data
		);
	};

	updateFieldName(evt, id, dropdown_name, set_field, ref_field) {
		let selected = this.dropdown[dropdown_name].filter((e) => e[id] == evt);
		set_field = selected[0][ref_field];
	}

	assignToMyself() {
		if (this.dropdown.current_user_group.length == 1) {
			this.change_extra_info["selected_assignee_info"]["profile_image"] =
				this.dropdown.current_user_group[0].profile_image;
			this.change_extra_info["selected_assignee_info"]["profile"] = this.dropdown.current_user_group[0].profile;
			this.change_data["current_assignment_info"]["group"] = this.dropdown.current_user_group[0].team_id;
			this.change_data["current_assignment_info"]["group_type"] = this.dropdown.current_user_group[0].group_type;
			this.change_data["current_assignment_info"]["group_name"] = this.dropdown.current_user_group[0].name;
			this._changeViewService
				.getTeamUserMap({ team_id: this.change_data.current_assignment_info.group })
				.subscribe((response) => {
					this.dropdown.team_user_profile_map = response;
				});
			if (this.dropdown.current_user_group[0].group_type === "Expert") {
				this.change_data["current_assignment_info"]["assignee"] = this.currentUserProfileId;
				this.change_data["current_assignment_info"]["assignee_profile"] = this.currentUser?.profile;
				this.change_data["current_assignment_info"]["expertise"] = null;
				this.change_data["current_assignment_info"]["level"] = null;
			} else {
				if (this.dropdown.current_user_group[0]["level_staff"].length > 1) {
					this.dropdown["current_user_expertise"] = this.dropdown.current_user_group[0]["level_staff"];
					this.change_data["current_assignment_info"]["expertise"] = null;
					this.change_data["current_assignment_info"]["level"] = null;
					this.change_data["current_assignment_info"]["expertise_id"] = null;
					this.change_data["current_assignment_info"]["level_id"] = null;
				} else {
					this.change_data["current_assignment_info"]["expertise"] =
						this.dropdown.current_user_group[0]["level_staff"][0]["expertise"];
					this.change_data["current_assignment_info"]["level"] =
						this.dropdown.current_user_group[0]["level_staff"][0]["expertGroupLevel"][0]["level"];
					this.change_data["current_assignment_info"]["expertise_id"] =
						this.dropdown.current_user_group[0]["level_staff"][0]["expertise_id"];
					this.change_data["current_assignment_info"]["level_id"] =
						this.dropdown.current_user_group[0]["level_staff"][0]["expertGroupLevel"][0]["level_id"];
				}
				this.change_data["current_assignment_info"]["assignee"] = this.currentUserProfileId;
				this.change_data["current_assignment_info"]["assignee_profile"] = this.currentUser?.profile;
			}
			this.assigneeChange();
		} else if (this.dropdown.current_user_group.length > 1) {
			this.change_data["current_assignment_info"]["group"] = null;
		}
		this.selfAssignSel = true;
	}

	stringToColor(string) {
		if (string && string != undefined) {
			let hash = 0;
			let i;

			/* eslint-disable no-bitwise */
			for (i = 0; i < string.length; i += 1) {
				hash = string.charCodeAt(i) + ((hash << 5) - hash);
			}

			let colour = "#";

			for (i = 0; i < 3; i += 1) {
				const value = (hash >> (i * 8)) & 0xff;
				colour += `00${value.toString(16)}`.substr(-2);
			}
			/* eslint-enable no-bitwise */

			return colour;
		} else {
			return "#2d2d2d";
		}
	}

	onServiceChangeStatus = (data) => {
		this.change_data["service_change_status"] = data;
	};

	onStatusChange = (data) => {
		if (data?.state_id == 5) {
			this.checkAllTaskClosed(data);
		} else {
		this.change_data.basic_info["status"] = data;
		this.change_data.basic_info["state"] = this.dropdown?.state_id_map[data?.state_id];
		if (this.change_data?.basic_info?.state?.id != 5) {
			//not close
			this.change_data.close_info = {};
			this.change_data["actual_closure_date"] = null;
			this.change_data["agreed_closure_date"] = null;
		}
	}
	};
	serviceValueChange = (item) => {
		// to do - handle wokkflow change on change type changs
		this.handleWorkflowOnServiceChange(item);
	};

	handleWorkflowOnServiceChange = (selected_service) => {
		this.is_service_change = true;
		this._changeViewService
			.getChangeTypeBaseWorkflowStatus({ change_type_id: this.change_data?.basic_info?.change_type?.id })
			.subscribe((res) => {
				this.prepareServiceChangeData(res, selected_service);
			});
	};

	classificationValueChange = (item) => {
		this.change_data.basic_info.service_classification = item?.value;
		this.change_data.basic_info.service_classification_name = item?.text;
		this.change_data.basic_info.service_classification_path = item?.full_path_text;
	};
	statusBadgeChange = (data) => {
		this.change_data.basic_info[data["field"]] = data?.event ? data.event : {};
	};

	selectAddTagMethod = (name) => {
		if (this.tagPermission.add) {
			this.openModalAD(this.tagComp, name);
		}
	};

	copyDisplayId(inputTextValue) {
		const selectBox = document.createElement("textarea");
		selectBox.style.position = "fixed";
		selectBox.value = inputTextValue;
		document.body.appendChild(selectBox);
		selectBox.focus();
		selectBox.select();
		document.execCommand("copy");
		document.body.removeChild(selectBox);
		this._toastr.success("", this._translateService.instant("Message.msg_copy_success"), {
			toastClass: "toast ngx-toastr",
			closeButton: true,
		});
	}

	get_custom_data(config_data, custom_fields) {
		let actual_conf_data = {};
		if (config_data && custom_fields) {
			config_data.forEach((conf) => {
				let data_key = conf?.data_key;
				let label = conf?.label;
				let value = custom_fields[data_key];
				if (conf?.type == "date" || conf?.type == "datetime-local") {
					if (this.change_data.custom_field_data[data_key] instanceof Array) {
						this.change_data.custom_field_data[data_key] = this.change_data.custom_field_data[data_key][0];
					}
					if (value instanceof Array) {
						value = value[0];
					}
				}
				if (value) {
					actual_conf_data[label] = value;
				}
			});
		}
		return actual_conf_data;
	}

	customSearchFn(term: string, item: any) {
		return customSearch(term, item, "full_name", "email");
	}

	onImpactServiceClick = (event, key) => {
		event.preventDefault();
		if (![1, 2].includes(this.change_data?.basic_info?.state?.id)) {
			return;
		}
		if (key == "impact_service") {
			if (!this.service_config) {
				this.service_config = {};
			}
			this.service_config["is_loading"] = true;
			this.dropdown["formatted_services"] = [];
			this._changeViewService
				.getRequesterImpactService({
					requester_id: this.change_data.requester?.requester_id,
					is_published: true,
					bind_classification: false,
					module_name_list: JSON.stringify(["change"]),
				})
				.subscribe(
					(res) => {
						this.service_config["is_editable"] = true;
						this.dropdown["formatted_services"] = [];
						res?.forEach((ele) => {
							const data = new TreeviewItem(ele);
							this.dropdown["formatted_services"].push(data);
						});
						this.service_config["is_loading"] = false;
					},
					(error) => {
						//console.log("error_while_fetching impact service", error);
						this.service_config["is_loading"] = false;
					}
				);
		} else if (key == "service_classification") {
			if (!this.classification_config) {
				this.classification_config = {};
			}
			this.classification_config["is_loading"] = true;
			this.dropdown["formatted_classification_data"] = [];
			if (this.change_data.basic_info?.impact_service) {
				this._changeViewService
					.getImpactServiceClassification({
						service_id: this.change_data.basic_info?.impact_service,
						format_to_treeview: true,
					})
					.subscribe(
						(res) => {
							this.classification_config["is_editable"] = true;
							this.dropdown["formatted_classification_data"] = [];
							res?.classification?.forEach((ele) => {
								const data = new TreeviewItem(ele);
								this.dropdown["formatted_classification_data"].push(data);
							});
							this.classification_config["is_loading"] = false;
						},
						(error) => {
							//console.log("error_while_fetching impact service", error);
							this.classification_config["is_loading"] = false;
						}
					);
			} else {
				this._toastr.error(this._translateService.instant("Error.err_select_impact_service"));
				this.classification_config["is_loading"] = false;
			}
		}
	};

	ngOnDestroy() {
		this._modalService.dismissAll();
		this.Duedate?.flatpickr.close();
		this.agreedClosuredate?.flatpickr?.close();
		this.actualcloserTime?.flatpickr?.close();
		this.actualstarttime?.flatpickr?.close();
	}

	prepareServiceChangeData = (res: any, item) => {
		if (res) {
			const workflow_id = res?.options[0]?.workflow_id;
			const current_item = _deepCopyJson(this.change_data);
			const current_workflow_id = current_item.workflow_id || -1;
			this.dropdown.service_change_status = res?.options.filter((x) => [1, 2].includes(x.state_id)) || [];
			const workflow_name = res?.options[0]?.workflow_name || "Untitled";
			const current_workflow_name = current_item?.workflow_name || "Untitled";
			let basic_info = {
				impact_service: item?.value,
				impact_service_name: item?.text,
				service_classification: "",
				service_classification_name: "",
				service_classification_path: "",
				impact_service_tree_path: item?.full_path_text,
			};
			basic_info["catalogue"] = item?.sel_tree_view_data[item?.sel_tree_view_data?.length - 1]["value"];
			basic_info["catalogue_name"] = item?.sel_tree_view_data[item?.sel_tree_view_data?.length - 1]["text"];
			if (item?.sel_tree_view_data && item?.sel_tree_view_data?.length > 2) {
				basic_info["category"] = item?.sel_tree_view_data[1]["value"];
				basic_info["category_name"] = item?.sel_tree_view_data[1]["text"];
			} else {
				basic_info["category"] = "";
				basic_info["category_name"] = "";
			}
			const obj = {
				value: basic_info,
				key: "basic_info",
				change_id: current_item?.change_id,
				element: current_item,
				display_id: current_item?.display_id,
			};
			if (workflow_id !== current_workflow_id) {
				this.service_change_data = {
					selected_service_config: {
						status: this.dropdown.service_change_status,
						workflow_title: workflow_name,
						custom_field_config: res?.custom_filed_options || {},
						service_name: item.text,
					},
					current_workflow: {
						status: this.dropdown.status || [],
						workflow_title: current_workflow_name,
						service_name: current_item?.basic_info?.impact_service_name,
					},
					current_status: current_item?.basic_info?.status,
					args: obj,
				};
			} else {
				this.service_change_data = {
					args: obj,
				};
			}
			this._coreSidebarService.getSidebarRegistry("service-change-sidebar-inner").toggleOpen();
		} else {
			this.is_service_change = false;
		}
	};

	updateObject = (inputObject, newObject) => {
		if (!inputObject) {
			inputObject = {};
		}
		for (const key in newObject) {
			if (newObject?.[key] != undefined) {
				inputObject[key] = newObject[key];
			}
		}
	};

	onServiceSliderClose = () => {
		this.is_service_change = false;
	};

	onServiceChangeSubmit = ($event: any) => {
		let temp_change_data = _deepCopyJson(this.change_data);
		if ($event["is_comment_only"]) {
			temp_change_data["comment"] = $event["comment"];
			this.updateObject(temp_change_data["basic_info"], $event["value"]);
		} else {
			this.updateObject(temp_change_data["basic_info"], $event["value"]);
			this.updateObject(temp_change_data["config"], $event["config"]);

			temp_change_data["state_id"] = $event["state_id"];
			temp_change_data["comment"] = $event["comment"];
			if ($event?.custom_field_data) {
				temp_change_data["custom_field_data"] = $event?.custom_field_data || {};
			}
		}
		delete temp_change_data["workflow_config"];
		delete temp_change_data["workflow_id"];
		delete temp_change_data["workflow_name"];
		temp_change_data["service_form_data"] = {};
		this.saveChangeCall(temp_change_data);
	};
	validateDateFields() {
		this.startEndDateInvalid = false;
		this.startEndDateToaster = ''
        let due_date = this.change_data.due_date
		due_date = moment(due_date).format("YYYY-MM-DDTHH:mm:ssZ");
        if (due_date instanceof Array) {
            due_date = due_date[0];
        }
		if (Date.parse(this.change_data?.basic_info?.plan_end_date) > Date.parse(due_date)){
			this.startEndDateToaster +=  this._translateService.instant("Message.msg_release_due_plan_end_date_validation")+ "</br>"
			this.startEndDateInvalid = true;
		}
		if (Date.parse(this.change_data?.basic_info?.plan_start_date) > Date.parse(due_date)){
			this.startEndDateToaster += this._translateService.instant("Message.msg_release_due_plan_start_date_validation")+ "</br>"
			this.startEndDateInvalid = true;
		}
		if ( Date.parse(this.taskMaxDueDate) > Date.parse(due_date)){
			this.startEndDateToaster += this._translateService.instant("Message.msg_release_due_task_due_validation")+ "</br>"
			this.startEndDateInvalid = true;
		}
		if (this.startEndDateInvalid) {
			return false;
		}
		return true;
	}
	checkAllTaskClosed = (data) => {
		const payload = {
			page: 1,
			items_per_page: 1000,
			sort: "name",
			reverse: "",
			module: "task",
			only_open_task: true,
		};
		const that = this;
		this._changeViewService.getChangeTaskList(payload, this.change_data?.change_id).subscribe(
			(response) => {
				if (response) {
					this.notClosedTask = response["results"];
					if (this.notClosedTask?.length > 0) {
						that.change_data.basic_info["status"] = that.change_data_copy?.basic_info?.status;
						that.change_data.basic_info["state"] = that.change_data_copy?.basic_info?.state;
						const tempStatusId = that.change_data_copy?.basic_info?.status.id;
						this.sliderStatusPickerComponent?.updateValue(tempStatusId);
						let notClosedTaskDisplayIDs = "";
						for (let index = 0; index < this.notClosedTask.length; index++) {
							notClosedTaskDisplayIDs += this.notClosedTask[index]?.display_id + ", ";
						}
						notClosedTaskDisplayIDs = notClosedTaskDisplayIDs.replace(/, ([^, ]*)$/, "$1");
						Swal.fire({
							title: this._translateService.instant("Message.msg_task_not_completed"),
							text: notClosedTaskDisplayIDs,
							icon: "warning",
							confirmButtonText: this._translateService.instant("UI.k_ok"),
							customClass: {
								confirmButton: "btn btn-primary",
								cancelButton: "btn btn-danger ml-1",
							},
						});
					} else {
						this.change_data.basic_info["status"] = data;
						this.change_data.basic_info["state"] = this.dropdown?.state_id_map[data?.state_id];
					}
				}
			},
			(error) => {
				//todo
			}
		);
	}
}
