import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, AfterViewInit} from "@angular/core";
import Stepper from "bs-stepper";
import { BotCongigurationData } from "../../bot-congiguration-data";
import { FileUploader } from "ng2-file-upload";
import { NgForm } from "@angular/forms";
import { NgScrollbar } from "ngx-scrollbar";
import { ToastrService } from "ngx-toastr";
import { environment } from "environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { BotConfigService } from '../../services/bots-configuration.service';
import { default as _map } from "lodash-es/map";
import { from, throwError, Subject } from "rxjs";
import { catchError, map, reduce, takeUntil } from "rxjs/operators";
import {  getFileSizeText, validateFile } from "../../../../common/utils/utils";
import { AuthService } from  "app/base/auth/services/auth.service";
import {  User } from "app/auth/models";
const URL = `${environment.instance_url}` + "media";
import {WSSService} from "app/common/services/wss/wss.service";

@Component({
	selector: "app-create-bot",
	templateUrl: "./create-bot.component.html",
	styleUrls: ["./create-bot.component.scss"],
})
export class CreateBotComponent implements OnInit, AfterViewInit {
	public botConfigurationData = new BotCongigurationData();
	public newChat;
	@Output() onHideSidebar: EventEmitter<any> = new EventEmitter();
	@Input() isEdit: any;
	public horizontalWizardStepper: Stepper;
	@Input() itemObj: any = {};
	@ViewChild("file") fileInput: ElementRef;
	@ViewChild("scrollbar") scrollbar: NgScrollbar;
	@ViewChild("inputElement") inputElement: ElementRef;
	selectedValue: string = "";
	public Data: any;
	public ALLOWED_TYPES: any;
	public MAX_FILE_SIZE;
	public chartData: Array<any> = [];
	public isDummyContainer = false;
	public isLoading = false;
	totalCount = 5;
	count = 0;
	chatMessage;

	public uploadSize: any;
	public index = 0;
	public uploadDate: any;
	numberOfInputs:any;
	options: any  = [];
	all_topic_options : any = [];
	uploader: FileUploader = new FileUploader({
		url: URL,
		isHTML5: true,
	});
	bot_config_id : any;
	public predefined_bot_topics_map = {
		'IT Assistance':"it_topics",
		'HR Assistance':"hr_topics",
		'Finance Assistance':"finance_topics",
		'Legal Assistance':"legal_topics",
		'OPs Assistance':"operation_topics"
		  }
	showLoader: boolean = false;
	currentUser: User;
	_unsubscribe: any;
	_unsubscribeAll: any;
	wss_channel: any = "workspace_chat";
	disableSaveBtn: boolean = false;
	numberOfBotConversation: any;
	botDocStorage: any;
	constructor(
    private _toastr: ToastrService,
    private _translateService: TranslateService,
	private _botConfigService: BotConfigService,
	private _authService: AuthService,
	private _wssService: WSSService,
  ) {
		this.uploader.onAfterAddingFile = (fileItem) => {
			fileItem.withCredentials = false;
		};
		this._unsubscribeAll = new Subject();
		this._unsubscribe = new Subject()
		this._authService.currentUser.subscribe(x => ( this.currentUser = x))
		
	}

	ngOnInit(): void {
		this._wssService.connect();
		if(this.isEdit){
			this.bot_config_id = this?.itemObj?.bot_config_id;
			let is_enabled = this?.itemObj?.is_enabled;
			let payload = {}
			payload['bot_config_id'] = this.bot_config_id;
			this._botConfigService.getBotConfigDetails(payload, this.bot_config_id).subscribe(
				(response) => {
					this.itemObj = response
					this.itemObj['is_enabled'] = is_enabled
					if(!(this?.itemObj?.topics?.length > 0)){
						this.itemObj?.topics?.push(this.botConfigurationData)
					}else{
						this.isDummyContainer = true;
					}
				})
			
		}else{
			this.itemObj['topics'] = []
			this.itemObj?.topics?.push(this.botConfigurationData)
		}
		this.horizontalWizardStepper = new Stepper(document.querySelector("#botConfigurationStepper"), {});
		this.getBotConfigOptions()
	}
	ngAfterViewInit() {
			this._wssService
			.getWsDataStore()
			.pipe(takeUntil(this._unsubscribe))
			.subscribe((res: any) => {
				if (res && res?.channel === "workspace_chat:infiy_chat_validate") {
					let message_obj = res?.data?.payload?.message?.message_obj
					let ref_id = res?.data?.payload?.message?.ref_id
					if(message_obj?.message_start == true && ref_id == this.bot_config_id){
						this.newChat = {
								message: "",
								time: this.getCurrentDateTime(),
								senderId: -1,
								name: this._translateService.instant("UI.k_infi_bot"),
								avatarSrc: "assets/images/workspace/infi-logo.svg",
							};
							this.newChat['isChartActionShow'] = true;
							this.chartData.push(this.newChat);
							this.showLoader = false
					}
					else if(message_obj?.message && this.chartData.length > 0 && ref_id == this.bot_config_id){
						let lastIndex = this.chartData.length - 1
						if(this.chartData[lastIndex]?.senderId == -1){
							this.chartData[lastIndex]['message'] = this.chartData[lastIndex]['message'] + message_obj?.message;
						}
					}else if(message_obj?.message_start == true && ref_id == this.bot_config_id){
						let lastIndex = this.chartData.length - 1
						if(this.chartData[lastIndex]?.senderId == -1){
							this.chartData[lastIndex]['message'] = this.chartData[lastIndex]['message'] + message_obj?.message;
						}
					}
					this.scrollbar.scrollTo({
						bottom: 0,
						duration: 500
					});
					
				}

			});
			setTimeout(()=>{
				this.scrollbar.scrollTo({
					bottom: 0,
					duration: 500
				});
			}, 300);
	}
	getBotConfigOptions() {
		this._botConfigService.getBotConfigOptions({}).subscribe(
			(response) => {
				if (response) {
					this.options = response;
					this.numberOfBotConversation = this.options?.NUMBER_OF_BOT_CONVERSION;
					this.botDocStorage = this.options?.BOT_DOCUMENT_STORAGE;
					this.ALLOWED_TYPES = this.options?.allowed_file_types;
					this.MAX_FILE_SIZE = this.options?.allowed_file_max_size;
					if(this.itemObj?.pre_defined){
						this.options?.[this.predefined_bot_topics_map?.[this.itemObj?.name]]?.forEach(element => {
							this.all_topic_options.push(element)
						});
					}else{
						this.options?.finance_topics?.forEach(element => {
							this.all_topic_options.push(element)
						});
						this.options?.hr_topics?.forEach(element => {
							this.all_topic_options.push(element)
						});
						this.options?.it_topics?.forEach(element => {
							this.all_topic_options.push(element)
						});
						this.options?.legal_topics?.forEach(element => {
							this.all_topic_options.push(element)
						});
						this.options?.operation_topics?.forEach(element => {
							this.all_topic_options.push(element)
						});
					}
					
				}
			},
			(error) => {
				//todo
			}
		);
	}
	closeCreateBotSidebar() {
		this.onHideSidebar.emit("hide");
	}

	showDropdown(obj) {
		obj['isDropdownVisible'] = true
		// this.isDropdownVisible[index] = true;
	}
	hideDropdown(event, obj) {
		obj['isDropdownVisible'] = false
	}
	selectOption(topicObj, value, index) {
		topicObj["topic_name"] = value;
		topicObj['isDropdownVisible'] = false
	}
	fileUpload(event: any, obj): void {
		console.log("event------", event, obj)
		var files = event.target.files;
		var new_file_len = event.target.files.length;
		var added_file_len = obj?.attachment_data.length;
		// if(!obj?.attachment_names && obj?.attachment_data){
		// 	obj['attachment_names'] = []
		// 	obj?.attachment_data.forEach(element => {
		// 		obj['attachment_names'].push(element?.)
		// 	});
		// }
		for (var i = 0; i < new_file_len; i++) {
			var file = files[i];
			const data = validateFile(file, this.ALLOWED_TYPES);
			if (data && data["is_valid"]) {
				// if (obj?.attachment_names?.indexOf(file.name) == -1) {
					// obj?.attachment_names.push(file.name);
					var pos = added_file_len + i;
					var reader = new FileReader();
					reader.onload = (function (filesList, file, pos) {
						return function (e) {
							var data = e.target.result;
							filesList[pos] = {
								file_name: file.name,
								file_type: file.type,
								file_size: file.size,
								file_data: data,
								file_date: new Date(),
								//'file_date': this.datepipe.transform(new Date(),'MMM dd,yyyy hh:mm a')
							};
						};
					})(obj?.attachment_data, file, pos);
					reader.readAsDataURL(file);
				// }
			} else if (data && !data["is_valid"]) {
				this.showFileValidationError(data);
			}
		}
		event.srcElement.value = "";
		obj["err_msg"] = null
		this.isDummyContainer = true;
	}
	showFileValidationError = (data) => {
		if (data) {
			// invalid file requirement
			const errors: any = [];
			if (data["is_allowed"] === false) {
				errors.push(
					this._translateService.instant("UI.k_supported_format") +
						_map(this.ALLOWED_TYPES, (val) => (val.includes(".") || val.includes("/") ? val : " ." + val))
				);
			}

			if (data["size"]) {
				errors.push(
					this._translateService.instant("Message.msg_max_size") + " " + getFileSizeText(this.MAX_FILE_SIZE)
				);
			}

			from(errors)
				.pipe(
					map((err) => `<li>${err}</li>`),
					reduce((acc, value) => acc.concat(value), "")
				)
				.subscribe((res) => {
					this._toastr.warning(
						`<b>${data["name"]}</b></br><ul class="pl-1">${res}</ul`,
						this._translateService.instant("Error.err_file_upload_failed"),
						{
							enableHtml: true,
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
				});
		}
	};
	removeList(obj, index) {
		if (index >= 0 && index < obj?.attachment_data.length) {
			obj?.attachment_data?.splice(index, 1);
		}
	}

	submitBotConfiguration= (botConfigureForm: NgForm) =>{
		if(botConfigureForm.form.valid){

			let isTopicsDetailsAvail = true
				this.itemObj?.topics?.forEach(element => {
					if(element.topic_name && element.attachment_data.length === 0){
						element['err_msg'] = this._translateService.instant("Please Add Attachment")
						isTopicsDetailsAvail = false
					}
				});
				if (isTopicsDetailsAvail){
					this.disableSaveBtn = true;
					this.isLoading = true;
					this._botConfigService.saveBotConfig(this.itemObj).pipe(
						map((response: any) => {
							if(response?.status == "success"){
								this._toastr.success(
									this._translateService.instant(response["message"]),
								this._translateService.instant("Message.msg_save_success"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
								this.isLoading = false;
								this.disableSaveBtn = false;
								this.bot_config_id = response?.id;
								this.isEdit = true
								let payload = {}
								payload['bot_config_id'] = this.bot_config_id;
								this._botConfigService.getBotConfigDetails(payload, this.bot_config_id).subscribe(
									(response) => {
										this.itemObj = response
										if(!(this?.itemObj?.topics?.length > 0)){
											this.itemObj?.topics?.push(this.botConfigurationData)
										}
									})
								this.horizontalWizardStepper.next();
							}
							else{
								this.isLoading = false;
								this._toastr.error(
									this._translateService.instant(response["message"]),
								this._translateService.instant("Error.err_save_failed"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
								this.disableSaveBtn = false;
							}
						}),
						catchError((error) => {
							this._toastr.error(
								this._translateService.instant(error.error.error.message),
							this._translateService.instant("Error.err_save_failed"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
							this.isLoading = false;
							this.disableSaveBtn = false;
							return throwError(error.statusText);
						})).subscribe()
				}
				
		}
		
	}
	backBotConfigurationForm() {
		this.horizontalWizardStepper.previous();
	}
	addTopicData() {
		this.botConfigurationData = new BotCongigurationData();
		this.itemObj?.topics.push(this.botConfigurationData);
		this.isDummyContainer = !this.isDummyContainer;
	}
	removeTopicData(index) {
		if (index >= 0  && index < this.itemObj?.topics.length && this.itemObj?.topics.length > 1) {
			this.itemObj?.topics.splice(index, 1);
		}
	}
	submitBotConfigurationData() {
	this._botConfigService.saveBotConfig(this.itemObj).subscribe(
		(response) => {
		})
    // this.onHideSidebar.emit("hide");
  }
	getCurrentDateTime(){
		const date = new Date();
		// let currentDateTime = this.datePipe.transform(date, "dd MMMM yyyy HH:mm a");
		return date;
	};
	getBotResponse = () =>{
		let payload = {}
			payload['query'] = this.chatMessage
			payload['name'] = this.itemObj?.name
			payload['description'] = this.itemObj?.description
			payload['wss_uuid'] = "infiy_chat_validate"
			if(this.isEdit){
				payload['bot_config_id'] = this.itemObj?.bot_config_id
			}
			this.showLoader = true
			payload['history'] = this.chartData.slice(0, 2*this.numberOfBotConversation)
		this._botConfigService.getBotResponse(payload).pipe(
			map((response: any) => {
				if(response?.status == "success"){
					try {
						let lastIndex = this.chartData?.length - 1 - 1;
						if(this.chartData[lastIndex]?.senderId != -1){
							this.chartData[lastIndex]['extra_params'] = {}
						}
					  } catch (error) {
					  }
				}else{
					let lastIndex = this.chartData?.length - 1
						if(this.chartData[lastIndex]?.senderId != -1){
							this.chartData[lastIndex]['extra_params'] = {}
							this.chartData[lastIndex]['extra_params']['error'] = response?.msg;
						}
						this.showLoader = false
				}
			}),
			catchError((error) => {
				// this.newChat = {
				// 	error: true,
				// 	message: '',
				// 	err_msg: error.error.error.msg,
				// 	time: this.getCurrentDateTime(),
				// 	senderId: -1,
				// 	name: this._translateService.instant("UI.k_infi_bot"),
				// 	avatarSrc: "../../../../../assets/images/workspace/infi-logo.svg",
				// }
				// this.newChat['isChartActionShow'] = true;
				// this.chartData.push(this.newChat);
				this._toastr.error(
					this._translateService.instant(error?.error?.error?.msg),
					this._translateService.instant("Error.Failed"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
				this.showLoader = false
			return throwError(error.statusText);
	})).subscribe()
		this.chatMessage = "";
		this.index++;
		setTimeout(()=>{
			this.scrollbar.scrollTo({
				bottom: 0,
				duration: 500
			});
		}, 200);
	}
		
	updateChat(e) {
		if (this.chatMessage) {
			this.newChat = {
				message: this.chatMessage,
				time: this.getCurrentDateTime(),
				senderId: this.currentUser?.profile?.profile_id,
				name: this.currentUser?.profile?.full_name,
				avatarSrc: this.currentUser?.profile?.profile_image,
			};
			this.chartData.push(this.newChat);
			this.getBotResponse()
			
		}
	}
	
	onchatAction(value, chat) {
		chat['isChartActionShow'] = false
		if (value === 'correct') {
			this.count = this.count + 1;
		}
		if (value === 'wrong') {
			return false;
		}
	}
	downloadAttachment = (path, file) => {
		const file_path = URL + path.split("media")[1];
		const downloadable_file_path = file_path + "/" + file;
		let params = `?storage=${this.botDocStorage}`;
		window.open(downloadable_file_path+params, "_blank");
	};
	ngOnDestroy() {
		this._unsubscribeAll.next(undefined);
		this._unsubscribeAll.complete();
	}
	retrySend = (data) =>{
		this.getBotResponse()
	}

}
