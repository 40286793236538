<div class="list-wrapper workflow-skeleton-wrapper">
    <div class="d-flex flex-wrap">
        <div class="workflow-skeleton">
            <div class="sekeleton-card card-bg item-wrap col-md-3 mx-auto mt-4 pb-0 pt-0">
                <div class="d-flex">
                    <div class="col-md-2 pl-0 mt-25">
                        <ngx-skeleton-loader appearance="circle" [theme]="{ height: '38px',width: '38px'}"></ngx-skeleton-loader>
                    </div>
                    <div class="col-md-10 p-0">
                        <div class="d-flex col-md-12 p-0 justify-content-between">
                            <div class="col-md-6 p-0 mt-1"><ngx-skeleton-loader [theme]="{ height: '16px',width: '100%'}"></ngx-skeleton-loader></div>
                            <div class="col-md-2 p-0 mt-50"><ngx-skeleton-loader appearance="circle" [theme]="{ height: '28px',width: '28px'}"></ngx-skeleton-loader></div>
                        </div>
                    </div>
                </div>
                <hr class="mb-1 mt-0">
                <div class="col-md-12 p-0">
                    <ngx-skeleton-loader count="1" [theme]="{ height: '10px',width: '100%'}"></ngx-skeleton-loader>
                </div>
                <span class="down-arron txt-primary">
                    <i class="fa-solid fa-chevron-down"></i>
                </span>
            </div>

            <div class="sekeleton-card card-bg item-wrap col-md-3 mx-auto mt-4 pb-0 pt-0">
                <div class="d-flex">
                    <div class="col-md-2 pl-0 mt-25">
                        <ngx-skeleton-loader appearance="circle" [theme]="{ height: '38px',width: '38px'}"></ngx-skeleton-loader>
                    </div>
                    <div class="col-md-10 p-0">
                        <div class="d-flex col-md-12 p-0 justify-content-between">
                            <div class="col-md-6 p-0 mt-1"><ngx-skeleton-loader [theme]="{ height: '16px',width: '100%'}"></ngx-skeleton-loader></div>
                            <div class="col-md-2 p-0 mt-50"><ngx-skeleton-loader appearance="circle" [theme]="{ height: '28px',width: '28px'}"></ngx-skeleton-loader></div>
                        </div>
                        <div class="col-md-12 p-0">
                            <ngx-skeleton-loader [theme]="{ height: '12px',width: '100%'}"></ngx-skeleton-loader>
                        </div>
                    </div>
                </div>
                <hr class="mb-1 mt-0">
                <div class="col-md-12 p-0">
                    <ngx-skeleton-loader count="1" [theme]="{ height: '10px',width: '100%'}"></ngx-skeleton-loader>
                </div>
                <div class="bottom-line">

                </div>
            </div>
            <div class="two-skeleton-card d-flex">
                <div class="sekeleton-card card-bg item-wrap col-md-3 mx-auto mt-4 pb-0 pt-0">
                    <div class="d-flex">
                        <div class="col-md-2 pl-0 mt-25">
                            <ngx-skeleton-loader appearance="circle" [theme]="{ height: '38px',width: '38px'}"></ngx-skeleton-loader>
                        </div>
                        <div class="col-md-10 p-0">
                            <div class="d-flex col-md-12 p-0 justify-content-between">
                                <div class="col-md-6 p-0 mt-1"><ngx-skeleton-loader [theme]="{ height: '16px',width: '100%'}"></ngx-skeleton-loader></div>
                                <div class="col-md-2 p-0 mt-50"><ngx-skeleton-loader appearance="circle" [theme]="{ height: '28px',width: '28px'}"></ngx-skeleton-loader></div>
                            </div>
                            <div class="col-md-12 p-0">
                                <ngx-skeleton-loader [theme]="{ height: '12px',width: '100%'}"></ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>
                    <hr class="mb-1 mt-0">
                    <div class="col-md-12 p-0">
                        <ngx-skeleton-loader count="1" [theme]="{ height: '10px',width: '100%'}"></ngx-skeleton-loader>
                    </div>
                    <span class="down-top txt-primary">
                        <i class="fa-solid fa-chevron-down"></i>
                    </span>
                </div>
                <div class="sekeleton-card card-bg item-wrap col-md-3 mx-auto mt-4 pb-0 pt-0">
                    <div class="d-flex">
                        <div class="col-md-2 pl-0 mt-25">
                            <ngx-skeleton-loader appearance="circle" [theme]="{ height: '38px',width: '38px'}"></ngx-skeleton-loader>
                        </div>
                        <div class="col-md-10 p-0">
                            <div class="d-flex col-md-12 p-0 justify-content-between">
                                <div class="col-md-6 p-0 mt-1"><ngx-skeleton-loader [theme]="{ height: '16px',width: '100%'}"></ngx-skeleton-loader></div>
                                <div class="col-md-2 p-0 mt-50"><ngx-skeleton-loader appearance="circle" [theme]="{ height: '28px',width: '28px'}"></ngx-skeleton-loader></div>
                            </div>
                            <div class="col-md-12 p-0">
                                <ngx-skeleton-loader [theme]="{ height: '12px',width: '100%'}"></ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>
                    <hr class="mb-1 mt-0">
                    <div class="col-md-12 p-0">
                        <ngx-skeleton-loader count="1" [theme]="{ height: '10px',width: '100%'}"></ngx-skeleton-loader>
                    </div>
                    <span class="down-top txt-primary">
                        <i class="fa-solid fa-chevron-down"></i>
                    </span>
                </div>
            </div>
            <div class="two-skeleton-card no-line d-flex">
                <div class="sekeleton-card card-bg item-wrap col-md-3 mx-auto mt-4 pb-0 pt-0">
                    <div class="d-flex">
                        <div class="col-md-2 pl-0 mt-25">
                            <ngx-skeleton-loader appearance="circle" [theme]="{ height: '38px',width: '38px'}"></ngx-skeleton-loader>
                        </div>
                        <div class="col-md-10 p-0">
                            <div class="d-flex col-md-12 p-0 justify-content-between">
                                <div class="col-md-6 p-0 mt-1"><ngx-skeleton-loader [theme]="{ height: '16px',width: '100%'}"></ngx-skeleton-loader></div>
                                <div class="col-md-2 p-0 mt-50"><ngx-skeleton-loader appearance="circle" [theme]="{ height: '28px',width: '28px'}"></ngx-skeleton-loader></div>
                            </div>
                            <div class="col-md-12 p-0">
                                <ngx-skeleton-loader [theme]="{ height: '12px',width: '100%'}"></ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>
                    <hr class="mb-1 mt-0">
                    <div class="col-md-12 p-0">
                        <ngx-skeleton-loader count="1" [theme]="{ height: '10px',width: '100%'}"></ngx-skeleton-loader>
                    </div>
                    <span class="down-top txt-primary">
                        <i class="fa-solid fa-chevron-down"></i>
                    </span>
                </div>
                <div class="sekeleton-card card-bg item-wrap col-md-3 mx-auto mt-4 pb-0 pt-0">
                    <div class="d-flex">
                        <div class="col-md-2 pl-0 mt-25">
                            <ngx-skeleton-loader appearance="circle" [theme]="{ height: '38px',width: '38px'}"></ngx-skeleton-loader>
                        </div>
                        <div class="col-md-10 p-0">
                            <div class="d-flex col-md-12 p-0 justify-content-between">
                                <div class="col-md-6 p-0 mt-1"><ngx-skeleton-loader [theme]="{ height: '16px',width: '100%'}"></ngx-skeleton-loader></div>
                                <div class="col-md-2 p-0 mt-50"><ngx-skeleton-loader appearance="circle" [theme]="{ height: '28px',width: '28px'}"></ngx-skeleton-loader></div>
                            </div>
                            <div class="col-md-12 p-0">
                                <ngx-skeleton-loader [theme]="{ height: '12px',width: '100%'}"></ngx-skeleton-loader>
                            </div>
                        </div>
                    </div>
                    <hr class="mb-1 mt-0">
                    <div class="col-md-12 p-0">
                        <ngx-skeleton-loader count="1" [theme]="{ height: '10px',width: '100%'}"></ngx-skeleton-loader>
                    </div>
                    <span class="down-top txt-primary">
                        <i class="fa-solid fa-chevron-down"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
