import { Component, OnInit, Input } from "@angular/core";
import { getAssetImageSrc } from "app/common/utils/utils";
import { NgEventBus } from "ng-event-bus";

@Component({
	selector: "app-imacd-summary-widget",
	templateUrl: "./imacd-summary-widget.component.html",
	styleUrls: ["./imacd-summary-widget.component.scss"],
})
export class ImacdSummaryWidgetComponent implements OnInit {
	@Input() widgetData: any;
	@Input() modifiedtime: any;

	constructor(private eventBus: NgEventBus) {}

	ngOnInit(): void {}

	getImg(item) {
		return getAssetImageSrc(item.id, this.widgetData?.data?.groupby);
	}

	showImacdSidebar(dat) {
		if (dat?.count > 0 && dat?.item_filter) {
			let payload = {
				title: "IMACD - " + dat.type,
				app_type: "imacd-grid",
				item_filter: dat.item_filter,
				timescale: this.widgetData?.timescale,
			};
			this.eventBus.cast("dashboard-view:sidebar", payload);
		}
	}
}
