import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
	selector: "dns-sidebar",
	templateUrl: "./dns-sidebar.component.html",
	styleUrls: ["./dns-sidebar.component.scss"],
})
export class DnsSidebarComponent implements OnInit {
	@Input("dnsTitle") title: string = "";
	@Output() close: EventEmitter<any> = new EventEmitter<any>();
	@Input() sidebarName: string;
	@Input() sidebarSize = "small" || "medium" || "large" || "x-large";
	@Input() sidebarCustomClass: string = "";
	@Input("stepForm") is_wizard_content = false;
	modalBackDropClass: string = ''
	constructor() {}

	ngOnInit(): void {
		if(this.sidebarCustomClass === 'modal-higher'){
			this.modalBackDropClass = 'modal-backdrop-higher';
		}else{
			this.modalBackDropClass = 'modal-backdrop';
		}
	}

	toggleSidebar(key) {
		this.close.emit(key);
	}
}
