<div class="row auth-page">
	<div class="auth-wrapper auth-v2">
		<ng-scrollbar class="custom-scrollbar section-scrollbar">
			<div class="auth-wrapper auth-v1 px-2">
				<div class="pt-5">
					<div class="card admin-card p-1 border">
						<div class="card-body">
							<div class="text-center">
								<a class="infraon-logo d-flex align-items-center justify-content-center mb-2" href="javascript:void(0);">
									<img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="50%" />
								</a>
								<h3 class="fw-600">
									{{
										is_token_expired
											? ("UI.k_token_expire" | translate)
											: ("UI.k_welcome_admin_message" | translate)
									}}
								</h3>
								<p class="">{{ "UI.k_signin_admin_message" | translate }}</p>
							</div>
							<ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
								<div class="alert-body" id="divError" name="divError">
									<p>{{ error }}</p>
								</div>
							</ngb-alert>

							<form class="auth-login-form mt-2" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
								<div class="form-group">
									<label class="form-label" for="txtEmail">{{ "UI.k_email" | translate }}</label>
									<input
										type="text"
										formControlName="email"
										class="form-control lg-form-control"
										[ngClass]="{ 'is-invalid': submitted && f.email.errors }"
										aria-describedby="txtEmail"
										autofocus=""
										tabindex="1"
										id="txtEmail"
										name="txtEmail"
									/>
									<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
										<div *ngIf="f.email.errors.required">
											{{ "UI.k_email_required" | translate }}
										</div>
										<div *ngIf="f.email.errors.email">
											{{ "UI.k_email_error" | translate }}
										</div>
									</div>
								</div>
								<div class="form-group">
									<div class="d-flex justify-content-between">
										<label for="txtPwd">{{ "UI.k_password" | translate }}</label>
										<a id="linkFPass" name="linkFPass"
										(click) = "redirectToForgetPassword()"
											><small class="primary-color">{{
												"UI.k_forgot_password" | translate
											}}</small></a
										>
									</div>
									<div
										class="input-group input-group-merge form-password-toggle"
										[ngClass]="{
											'is-invalid error': submitted && f.password.errors
										}"
									>
										<input
											[type]="passwordTextType ? 'text' : 'password'"
											formControlName="password"
											class="form-control form-control-merge lg-form-control"
											[ngClass]="{
												'is-invalid error': submitted && f.password.errors
											}"
											aria-describedby="txtPwd"
											tabindex="2"
											id="txtPwd"
											name="txtPwd"
										/>
										<div class="input-group-append">
											<span
												class="input-group-text cursor-pointer"
												id="spPassIcon"
												name="spPassIcon"
												><i
													class="feather font-small-4"
													[ngClass]="{
														'icon-eye-off': passwordTextType,
														'icon-eye': !passwordTextType
													}"
													(click)="togglePasswordTextType()"
												></i
											></span>
										</div>
									</div>
									<div
										*ngIf="submitted && f.password.errors"
										class="invalid-feedback"
										[ngClass]="{ 'd-block': submitted && f.password.errors }"
									>
										<div *ngIf="f.password.errors.required">
											{{ "UI.k_password_required" | translate }}
										</div>
									</div>
								</div>
								<!-- <div class="form-group">
										<div class="custom-control custom-checkbox">
											<input
												class="custom-control-input"
												id="remember-me"
												type="checkbox"
												tabindex="4"
											/>
											<label class="custom-control-label" for="remember-me">
												Remember Me</label
											>
										</div>
									</div> -->

								<div *ngIf="showCaptcha" class="form-group">
									<label class="form-label" for="imgCaptcha">{{
										"UI.k_captcha_code" | translate
									}}</label>
									<div class="input-group-merge form-password-toggle d-flex">
										<img
											src="{{ codeImage }}"
											id="imgCaptcha"
											name="imgCaptcha"
											class="border-captcha"
											height="60"
											width="100%"
										/>
										<div class="input-group-append">
											<span class="input-group-text cursor-pointer">
												<a
													id="linkReloadCaptcha"
													name="linkReloadCaptcha"
													class="justify-content-center"
													placement="top"
													ngbTooltip="Reload"
													(click)="getCaptcha()"
												>
													<i class="fa-light fa-arrows-rotate fa-lg text-primary"></i>
												</a>
											</span>
										</div>
									</div>
								</div>
								<div *ngIf="showCaptcha" class="form-group">
									<label class="form-label" for="txtCaptcha"
										>{{ "UI.k_captcha_message" | translate }}
									</label>
									<input
										class="form-control lg-form-control"
										id="txtCaptcha"
										name="txtCaptcha"
										type="text"
										value="captcha-input"
										placeholder="Enter Captcha"
										aria-describedby="captcha-input"
										autofocus=""
										tabindex="3"
										[(ngModel)]="captcha_temp"
										formControlName="captchaInput"
										required
									/>
									<span *ngIf="submitted && f.captchaInput.errors" class="invalid-form">
										<small class="form-text text-danger" *ngIf="f.captchaInput.errors.required">{{
											"UI.k_field_required" | translate
										}}</small>
									</span>
									<span *ngIf="submitted && f.captchaInput.invalid" class="invalid-form">
										<small class="form-text text-danger" *ngIf="f.captchaInput.errors.is - invalid">
											{{ "Error.err_invalid_captcha" | translate }}</small
										>
									</span>
								</div>

								<!-- <div class="form-group">
										<div class="custom-control custom-checkbox">
											<input
												class="custom-control-input"
												id="remember-me"
												type="checkbox"
												tabindex="4"
											/>
											<label class="custom-control-label" for="remember-me">
												Remember Me</label
											>
										</div>
									</div> -->

								<button
									[disabled]="loading"
									class="btn btn-primary btn-block btn-lg"
									tabindex="5"
									rippleEffect
									id="btnSignIn"
									name="btnSignIn"
								>
									<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span
									>{{ "UI.k_sign_in" | translate }}
								</button>
							</form>
							<p class="text-center mt-2">
								<span>{{ "UI.k_new_platform" | translate }}</span
								><a id="linkAccCreate" name="linkAccCreate" routerLink="/auth/register"
									><span class="fw-500"> {{ "UI.k_create_account" | translate }}</span></a
								>
							</p>
							<div class="multipul-login-container">
								<div class="container-title">
									<p class="text-center text-muted">{{ "UI.k_login_with" | translate }}</p>
								</div>
								<div class="btn-container d-flex align-items-center justify-content-between gap-1">
									<div  rippleeffect="" class="microsoft-btn px-75">
										<a id="btnPwdMicroSignIn" class="d-flex align-items-center" name="btnPwdMicroSignIn" [routerLink]="" (click)="loginWithMicrosoft()">
											<img src="../../../../assets/images/API/windows-s.png" alt="Logo" />
											<span class="ml-50 fw-500">{{ "UI.k_microsoft" | translate }}</span>
										</a>
									</div>
									<asl-google-signin-button type='standard' size='medium'></asl-google-signin-button>
									<!-- <div  rippleeffect="" class="google-btn btn btn-outline-tertiary btn-sm mb-0 waves-effect">
											<img src="../../../../assets/images/admin/google-icon.png" alt="Logo" />
											{{ "UI.k_google" | translate }}
									</div> -->
										 
								</div>
							</div>
							<!-- <div class="divider my-2">
									<div class="divider-text">or</div>
								</div>
								<div class="auth-footer-btn d-flex justify-content-center">
									<a
										id="linkGoogleLogin"
										name="linkGoogleLogin"
										class="btn btn-google"
										href="javascript:void(0)"
										><i class="fab fa-google"></i
									></a>
								</div> -->
						</div>
					</div>
				</div>
			</div>
			<!-- /Login-->
			<!-- </div> -->
		</ng-scrollbar>
	</div>
	<div class="content-image">
		<img src="assets/images/admin/admin-side-icon.svg" class="text-center side-lower-icon" width="20%" alt="" srcset="" />
	    <img src="assets/images/admin/admin-side-icon-2.svg" class="text-center side-upper-icon" width="9%" alt="" srcset="" />
	</div>
</div>
