import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	ViewChild,
	ViewEncapsulation,
} from "@angular/core";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { UserService } from "../../services/user.service";
import { PolicyService } from "../../../policy/services/policy.service";
import Stepper from "bs-stepper";
import { catchError, map } from "rxjs/operators";
import { GlobalConfig, ToastrService } from "ngx-toastr";
import { FileUploader } from "ng2-file-upload";
import { TranslateService } from "@ngx-translate/core";
import includes from "lodash-es/includes";
// import { ImagepreviewDirective } from "./../../../../common/directives/imagepreview.directive";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TagService } from "../../../tags/services/tag.service";
import { GlobalConstants } from "app/app.globalConstants";
import { customSearch } from "app/common/utils/utils";
import { CountryISO, PhoneNumberFormat, SearchCountryField } from "ngx-intl-tel-input";
import Swal from "sweetalert2";
import { throwError } from "rxjs";

const AES = require("crypto-js/aes");
const URL = "http://localhost:8000/common/user/profileimage/";

@Component({
	selector: "app-user-profile",
	templateUrl: "./user.component.html",
	styleUrls: ["./user.component.scss"],
	encapsulation: ViewEncapsulation.None,
	// viewProviders: [ImagepreviewDirective],
})
export class UserProfileComponent implements OnInit {
	@ViewChild("tagComp") tagComp: ElementRef;
	@ViewChild("tagCompAsset") tagCompAsset: ElementRef;
	@Input() action: String;
	@Input() user: Number;
	@Output("getUserList") getUserList = new EventEmitter<any>();
	@Output("onHideProfile") onHideProfile = new EventEmitter<any>();
	public uploader: FileUploader = new FileUploader({
		url: URL,
		isHTML5: true,
	});
	public imgDetailsObject = {
		aspectRatio: "1/1",
		resizeToWidth: "128",
		cropperMinWidth: "128",
		maintainAspectRatio: true,
	};
	public activeAvatar;
	public currentAvatarColor;
	public showQueue = false;
	public multipleFiles = true;
	public displayResoluion = false;
	public squareImage = true;
	public currentImgDetails;
	public avatarBackgroundColor: string = "";
	public active;
	public imageDetail: boolean;
	public setImage: boolean;
	public change: boolean;
	public fullName;
	public tempImage;
	public changePassword;
	public passwordHistory;
	public minPasswordLength;
	public maxPasswordLength;
	public minUpperCase;
	public minLowerCase;
	public minNumChar;
	public minSplChar;
	public contUpperCase;
	public contLowerCase;
	public contNumChar;
	public contSplChar;
	public hasBaseDropZoneOver: boolean = false;
	private _horizontalWizardStepper: Stepper;
	private _bsStepper;
	private _options: GlobalConfig;
	public contentHeader: object;
	public userName;
	public email;
	public passwordToaster;
	public passwordPattern;
	public is_password_invalid;
	public userProfile;
	public file: any;
	public userConfirmPassword;
	public userPwdShow = false;
	public showPreview = false;
	public TDValidationForm: any; //TODO change to relevant one
	ALLOWED_TYPES: Array<any>;
	MAX_IMAGE_SIZE;
	MAX_HEIGHT;
	MAX_WIDTH;
	tagList: Array<any>;
	assetTagList: Array<any>;
	tagTypeList: Array<any>;
	dashboardList: Array<any>;
	timezoneList: Array<any>;
	managerList: Array<any>;
	roleList: Array<any>;
	departmentList: Array<any>;
	requesterList: Array<any>;
	public selectedDashboard;
	public selectedTimezone;
	public imageBase64: string;
	public tag;
	public submitButton;
	tagPermission: any;
	public is_save_disabled = false;
	separateDialCode = true;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
	PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom, CountryISO.India];
	tagTypeMap: {};
	active_users_count: any;
	is_tag_save_disabled = false;
	locationList = [];
	showAddNewUserLocationSidebar = false;
	BaseLocation = null;
	selectedWorkLocation = null;
	workLocationList = [];
	home_address: any = false;
	location_obj = {
		'location': '',
		'state': '',
		'country': '',
		'landmark': '',
		'sub_area': '',
		'city': '',
		'pin': '',
		'building': '',
		'area': ''
	}

	constructor(
		private _coreSidebarService: CoreSidebarService,
		private _userService: UserService,
		private _policyService: PolicyService,
		private _toastr: ToastrService,
		private _modalService: NgbModal,
		private _tagService: TagService,
		private _translateService: TranslateService
	) {
		this._options = this._toastr.toastrConfig;
	}

	ngOnInit(): void {
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.tagPermission = user_permissions["tag"];
		if (this.action == "Add") {
			this.submitButton = "UI.k_submit";
			this._horizontalWizardStepper = new Stepper(document.querySelector("#stepper1"), {
				linear: true,
				animation: true,
			});
		} else {
			this.submitButton = "UI.k_submit";
			this._horizontalWizardStepper = new Stepper(document.querySelector("#stepper1"), {
				linear: true,
				animation: true,
			});
		}
		// this.getDefaultOptions();
		this.userProfile = {};
		this.userProfile["is_active"] = true;
		this.userProfile["profile"] = {
			gender: 1,
		};
		this._bsStepper = document.querySelectorAll(".bs-stepper");
	}

	ngOnChanges() {
		if (this.user && this.user !== -1) {
			this.getDefaultOptions();
			this.getUserProfile(this.user);
		}
	}

	getDefaultOptions() {
		const payload = {
			user_id: this.user,
		};
		this._userService.getOptions(payload).subscribe(
			(response) => {
				if (response) {
					this.dashboardList = response["dashboards"];
					this.timezoneList = response["timezones"];
					this.managerList = response["managers"];
					this.departmentList = response["departments"];
					this.roleList = response["roles"];
					let imageDefaults = response["image_upload_defaults"];
					this.ALLOWED_TYPES = imageDefaults["allowed_types"];
					this.MAX_IMAGE_SIZE = imageDefaults["max_size"];
					this.MAX_HEIGHT = imageDefaults["max_height"];
					this.MAX_WIDTH = imageDefaults["max_width"];
					this.tagList = response["TAGS_LIST"];
					this.assetTagList = response["ASSET_TAGS_LIST"];
					this.tagTypeList = response["TAG_TYPE_LIST"];
					this.tagTypeMap = {};
					this.tagTypeList.forEach((tobj) => {
						this.tagTypeMap[tobj.name] = tobj.id;
					});
					this.active_users_count = response?.active_users_count;
					this.requesterList = response["requesters"];
					this.locationList = response["LOCATION_LIST"];
					this.workLocationList =  this.locationList.concat([{ 'location_name': 'Home Address' }]);
					//console.log("this.tagTypeList >> ", this.tagTypeList, this.tagTypeMap)
				}
			},
			(error) => {
				//todo
			}
		);
	}

	generatePasswordPolicy(policy_objs) {
		this.changePassword = policy_objs.changePassword;
		this.passwordHistory = policy_objs.passwordHistory;
		this.minPasswordLength = policy_objs.minPasswordLength;
		this.maxPasswordLength = policy_objs.maxPasswordLength;
		this.minUpperCase = policy_objs.minUpperCase;
		this.minLowerCase = policy_objs.minLowerCase;
		this.minNumChar = policy_objs.minNumChar;
		this.minSplChar = policy_objs.minSplChar;
		this.contUpperCase = policy_objs.contUpperCase;
		this.contLowerCase = policy_objs.contLowerCase;
		this.contNumChar = policy_objs.contNumChar;
		this.contSplChar = policy_objs.contSplChar;
	}

	validatePassword(password) {
		this.is_password_invalid = false;
		this.passwordToaster = this._translateService.instant("Message.msg_pwd_contain") + "</br>";
		if (password.length < this.minPasswordLength) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_minimum_character", {
				minimum_character: this.minPasswordLength,
			});
			this.is_password_invalid = true;
		}
		if (password.length > this.maxPasswordLength) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_maximum_character", {
				maximum_character: this.maxPasswordLength,
			});
			this.is_password_invalid = true;
		}
		if (
			password.replace(/[^\\!\\@\\#\\$\\%\\^\\&\\*\\(\\)\\_\\?\\<\\>]/g, "").length < this.minSplChar &&
			this.minSplChar != this.contSplChar
		) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_minimum_special_character", {
				minimum_special_character: this.minSplChar,
			});
			this.is_password_invalid = true;
		}
		let pattern = "([\\!\\@\\#\\$\\%\\^\\&\\*\\(\\)\\_\\?\\<\\>])" + "{" + this.contSplChar + "}";
		if (!password.match(pattern)) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_continous_special_character", {
				continous_special_character: this.contSplChar,
			});
			this.is_password_invalid = true;
		}
		if (password.replace(/[^0-9]/g, "").length < this.minNumChar && this.minNumChar != this.contNumChar) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_minimum_number", {
				minimum_number: this.minNumChar,
			});
			this.is_password_invalid = true;
		}
		pattern = "([\\d])" + "{" + this.contNumChar + "}";
		if (!password.match(pattern)) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_continous_number", {
				continous_number: this.contNumChar,
			});
			this.is_password_invalid = true;
		}
		if (password.replace(/[^a-z]/g, "").length < this.minLowerCase && this.minLowerCase != this.contLowerCase) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_minimum_lowercase_letter", {
				minimum_lowercase_letter: this.minLowerCase,
			});
			this.is_password_invalid = true;
		}
		pattern = "([a-z])" + "{" + this.contLowerCase + "}";
		if (!password.match(pattern)) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_continous_lowercase_letter", {
				continous_lowercase_letter: this.contLowerCase,
			});
			this.is_password_invalid = true;
		}
		if (password.replace(/[^A-Z]/g, "").length < this.minUpperCase && this.minUpperCase != this.contUpperCase) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_minimum_uppercase_letter", {
				minimum_uppercase_letter: this.minUpperCase,
			});
			this.is_password_invalid = true;
		}
		pattern = "([A-Z])" + "{" + this.contUpperCase + "}";
		if (!password.match(pattern)) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_continous_uppercase_letter", {
				continous_uppercase_letter: this.contUpperCase,
			});
			this.is_password_invalid = true;
		}
	}

	fileOverBase(e: any): void {
		this.hasBaseDropZoneOver = e;
	}

	imageDetails = ($event) => {
		this.imageBase64 = $event;
		this.userProfile.profile_image = $event;
		this.imageDetail = true;
		this.change = true;
	};

	removeImage = ($event) => {
		this.tempImage = this.userProfile["profile_image"];
		this.userProfile["profile_image"] = "";
		this.imageBase64 = null;
		// this.showPreview = false;
		this.change = true;
		this.imageDetail = false;
		this.userProfile.delete_image = true;
	};
	onUpload(files: any): void {
		if (files.length > 0) {
			this.file = files[0];
			if (this.file && this.file["size"] > this.MAX_IMAGE_SIZE) {
				this._toastr.warning(
					this._translateService.instant("Message.msg_max_size") + this.MAX_IMAGE_SIZE / 1000 + "Mb",
					this._translateService.instant("Error.err_upload_fail"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
			}
			if (!includes(this.ALLOWED_TYPES, this.file["type"])) {
				this._toastr.warning(
					this._translateService.instant("Message.msg_image_allowed"),
					this._translateService.instant("Error.err_upload_fail"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
			}
			const reader = new FileReader();
			reader.onload = (e: any) => {
				const image = new Image();
				image.src = e.target.result;
				image.onload = (rs) => {
					const img_height = rs.currentTarget["height"];
					const img_width = rs.currentTarget["width"];
					if (img_height > this.MAX_HEIGHT && img_width > this.MAX_WIDTH) {
						this._toastr.warning(
							this._translateService.instant("Message.msg_max_dimension") +
								this.MAX_HEIGHT +
								"*" +
								this.MAX_WIDTH +
								"px",
							this._translateService.instant("Error.err_upload_fail"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
					} else {
						const imgBase64Path = e.target.result;
						this.imageBase64 = imgBase64Path;
						this.userProfile.profile_image = imgBase64Path;
						this.userProfile.new_image = true;
						// this.previewImagePath = imgBase64Path;
					}
				};
			};
			reader.readAsDataURL(this.file);
			this.showPreview = true;
		}
	}

	/**
	 * On Submit
	 */
	onSubmit = (data) => {
		if (data.form.valid === true) {
			this.userProfile["email"] = this.email;
			if (this.is_password_invalid === true) {
				return;
			}
			if (this.userProfile["full_name"] !== null && this.userProfile["full_name"] !== undefined) {
				this.userProfile["first_name"] = this.userProfile.full_name;
			}
			this.userProfile["username"] = this.userProfile.email;
			this.userProfile["action"] = this.action;
			if (this.userProfile["password"] != "************Aa1") {
				this.userProfile["password"] = this.encryptPassword(this.userProfile.password);
			}

			if (this.imageDetail === true) {
				this.userProfile["profile_image"] = this.imageBase64;
				this.userProfile["new_image"] = true;
			}
			this.userProfile["profile"]["avatar_color"] = this.currentAvatarColor;

			// if (this.uploader.queue.length > 0) {
			// 	this.userProfile["profile_image"] = this.imageBase64;
			// }
			// if (!this.userProfile.profile_image) {
			// 	this.userProfile.delete_image = true;
			// }
			this.userProfile["profile"]["full_name"] = this.userProfile.full_name;
			// this.userProfile["profile_image"] = this.imageBase64;
			if(!this.userProfile.profile.base_location) {
				this.userProfile.profile["base_location"] = {}
			}
			if(!this.userProfile.profile.work_location) {
				this.userProfile.profile["work_location"] = {}
			}
			this.saveProfile(this.userProfile);
		}
	};

	getUserProfile(userID: Number): void {
		this._userService
			.getUserProfile(userID)
			.pipe(
				map((response: any) => {
					if (response) {
						if (response["id"] !== null || response["id"] !== undefined) {
							this.userProfile = response;
							this.fullName = this.userProfile["profile"]["full_name"];
							//this.currentAvatarColor = '#f59518';
							//this.activeAvatar = '#f59518';
							if (response.profile.avatar_color) {
								this.currentAvatarColor = response.profile.avatar_color;
								this.activeAvatar = response.profile.avatar_color;
							} else {
								this.currentAvatarColor = "#f59518";
								this.activeAvatar = "#f59518";
							}
							this.userProfile["group_id"] = response["profile"]["group_id"];
							this.action = "Edit";
							this.showPreview = true;
							this.email = this.userProfile["email"];
							if (
								this.userProfile["profile_image"] !== null &&
								this.userProfile["profile_image"] !== undefined
							) {
								this.imageBase64 = this.userProfile["profile_image"];
								this.currentImgDetails = this.imageBase64;
								// this.showPreview = true;
							}
							this.format_location_obj(this?.userProfile?.profile)
							if (this.userProfile?.profile?.base_location?.location_name){
								this.BaseLocation = this?.userProfile?.profile?.base_location
							}
							if (this.userProfile?.profile?.work_location?.location_name){
								this.home_address = false
								this.selectedWorkLocation = this?.userProfile?.profile?.work_location
							}
							const payload = {
								page: 1,
								items_per_page: 10,
								sort: "policyName",
								reverse: "",
								group_id: response["profile"]["group_id"],
							};
							this._policyService
								.fetchPasswordPolicy(payload)
								.pipe(
									map((response: any) => {
										if (response) {
											this.generatePasswordPolicy(response);
										}
										// Success
									})
								)
								.subscribe();
						}
					}
					// Success
				}),
				catchError((error) => {
					//console.log("error", error);
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}

	encryptPassword(password: string) {
		var iv = "d99630bcc7058639e7e80df239f38a32";
		var ciphertext = AES.encrypt(password, iv).toString();
		var aesPassword = iv + "::" + ciphertext;
		return btoa(aesPassword);
	}
	
	toggleSidebar = (name): void => {
		this._coreSidebarService.getSidebarRegistry("user-profile").toggleOpen();
		this.action = "Add";
		this.user = -1;
		this.userProfile = {};
		this.userProfile["profile"] = {};
		this.onHideProfile.emit("hide");
		// this.uploader.uploadAll();
	};
	/**
	 * Horizontal Wizard Stepper Next
	 *
	 * @param data
	 */
	horizontalWizardStepperNext = (data) => {
		if (data.form.valid === true) {
			if (this.is_password_invalid === true) {
				return;
			}
			this._horizontalWizardStepper.next();
		}
	};
	/**
	 * Horizontal Wizard Stepper Previous
	 */
	horizontalWizardStepperPrevious() {
		this._horizontalWizardStepper.previous();
	}

	changeGender(args) {
		this.userProfile.profile.gender = args.target.defaultValue;
	}

	saveProfile = (userObj: {}): void => {
		this.is_save_disabled = true;
		this._userService
			.saveUserProfile(userObj)
			.pipe(
				map((response: any) => {
					if (response) {
						// this.notification.success('Saved User', 'User has been saved successfully')
						if (response["status"] == "success") {
							this.getUserList.emit();
							//console.log("", this.getUserList.emit());
							this.onHideProfile.emit("hide");
							this._coreSidebarService.getSidebarRegistry("user-profile").toggleOpen();
							this._toastr.success(
								this._translateService.instant(response["message"]),
								this._translateService.instant("Message.msg_save_success"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
						} else {
							this._toastr.error(
								this._translateService.instant(response["message"]),
								this._translateService.instant("Error.err_save_failed"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
						}
					}
					this.is_save_disabled = false;
					// Success
				}),
				catchError((error) => {
					this.is_save_disabled = false;
					//console.log("error", error);
					this._toastr.error(
						this._translateService.instant(error.error.error.message),
						this._translateService.instant("Error.err_save_failed"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					//   this.notification.error('Failed User Creation', 'Something went wrong')
					return throwError(error.statusText);
				})
			)
			.subscribe();

		// Closing the tool bar
	};
	openUserModalAD(TagComp, name) {
		this.tag = {};
		this.tag["name"] = name;
		if (this.tagTypeList != undefined) {
			this.tag["type"] = this.tagTypeList[0].id;
		}
		this._modalService.open(TagComp, {
			centered: true,
			animation: false,
			size: "md",
		});
		this.is_tag_save_disabled = false;
	}
	openAssetModalAD(tagCompAsset, name) {
		this.tag = {};
		this.tag["name"] = name;
		if (this.tagTypeList != undefined) {
			this.tag["type"] = this.tagTypeList[1].id;
		}

		this._modalService.open(tagCompAsset, {
			centered: true,
			animation: false,
			size: "md",
		});
	}

	selectAddAssetTagMethod = (name) => {
		if (this.tagPermission.add) {
			this.openAssetModalAD(this.tagCompAsset, name);
		}
	};

	selectAddUserTagMethod = (name) => {
		if (this.tagPermission.add) {
			this.openUserModalAD(this.tagComp, name);
		}
	};

	onCreateTag(data) {
		this.is_tag_save_disabled = true;
		if (data.form.valid === true) {
			this._tagService
				.saveTag(this.tag)
				.pipe(
					map((response: any) => {
						if (response) {
							if (response["status"] == "success") {
								this._toastr.success(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Message.msg_save_success"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
								this._modalService.dismissAll();
								this._userService
									.getOptions({ tag_option: this.tag["tagtype"] })
									.pipe(
										map((response: any) => {
											if (this.tag["tagtype"] == "User") this.tagList = response["TAGS"];
											else if (this.tag["tagtype"] == "Asset")
												this.assetTagList = response["TAGS"];
										})
									)
									.subscribe();
								this.getDefaultOptions();
							} else {
								this._toastr.error(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Error.err_save_failed"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							}
							this.is_tag_save_disabled = false;
						}
						// Success
					}),
					catchError((error) => {
						this.is_tag_save_disabled = false;
						this._toastr.error(
							this._translateService.instant("Error.err_tag_save_failed"),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						return throwError(error.statusText);
					})
				)
				.subscribe();
		} else {
			this.is_tag_save_disabled = false;
		}
	}

	profileImage = ($event) => {
		this.imageBase64 = $event;
		this.userProfile.profile_image = $event;
		this.imageDetail = true;
		this.change = true;
	};
	ProfileBgColor = ($event) => {
		this.currentAvatarColor = $event;
	};
	currrentColor = ($event) => {
		if ($event == "image") {
			this.userProfile["profile_image"] = this.tempImage;
		} else {
			this.getUserProfile($event);
		}
	};

	@HostListener("keydown", ["$event"]) //keydown, not keyup
	onKeyDown(event: any) {
		if (event.keyCode === 13 && event.target?.tagName != "BUTTON") {
			// 13 = enter
			event.preventDefault();
			return false;
		}
	}

	onRemoveTag = (tag: any, event: any) => {
		this._tagService
			.validateTagUsages(event?.value?.tag_id, {
				user_profile_id: this.userProfile.profile.profile_id,
			})
			.subscribe((res) => {
				if (res) {
					if (!res?.is_removalble) {
						this._toastr.error(
							this._translateService.instant(res["message"]),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						if (this.userProfile.profile.tags && this.userProfile.profile.tags?.length > 0) {
							this.userProfile.profile.tags?.push(event?.value?.tag_id);
						} else {
							this.userProfile.profile.tags = [event?.value?.tag_id];
						}
					}
				}
			});
	};
	ngOnDestroy() {
		this._modalService.dismissAll();
	}

	customSearchFn(term: string, item: any) {
		return customSearch(term, item, "full_name", "email");
	}

	onUserActive() {
		if (this.userProfile.is_active && this.active_users_count >= GlobalConstants?.dnsLavModules?.USER_COUNT) {
			Swal.fire({
				title: this._translateService.instant("Message.msg_users_limit_exceed"),
				text: this._translateService.instant("Message.msg_users_max_limit"),
				icon: "warning",
				showCancelButton: false,
				confirmButtonText: this._translateService.instant("UI.k_ok"),
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-danger ml-1",
				},
			}).then((result) => {
				if (result.value) {
					this.userProfile["is_active"] = false;
				}
			});
		}
	}

	LocationMap(event, form) {
		if (event !== undefined) {
			event["pin"] = event?.pincode;
			event["building"] = event?.address;
			event["sub_area"] = event?.description;
			this.userProfile.profile["base_location"] = event
			// this.userProfile.profile = { ...this.userProfile.profile, ...event };
		} else {
			this.userProfile.profile["base_location"] = {}
		}
	}

	format_location_obj(userData) {
		let location_keys = ["location", "city", "country", "state", "pin", "landmark", "building", "area", "sub_area"];
		this.home_address = location_keys.some(item => userData[item]);
		if(this.home_address) {
			this.selectedWorkLocation = { 'location_name': 'Home Address'}
		}
	}

	WorkLocationMap(event, form) {
		if (event?.location_name == 'Home Address') {
			this.home_address = true
			this.userProfile.profile["work_location"] = {}
		}
		else if (event !== undefined) {
			event["pin"] = event?.pincode;
			event["building"] = event?.address;
			event["sub_area"] = event?.description;		
			this.userProfile.profile["work_location"] = event
			this.userProfile.profile = { ...this.userProfile.profile, ...this.location_obj}
			this.home_address = false
			// this.userProfile.profile = { ...this.userProfile.profile, ...event };
		} else {
			this.home_address = false
			this.userProfile.profile["work_location"] = {}
			this.userProfile.profile = { ...this.userProfile.profile, ...this.location_obj}
		}
	}
}
