import {Component, OnInit} from "@angular/core";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SystemMenuService} from "../../services/system-menu.service";
import {catchError, map} from "rxjs/operators";
import { throwError } from "rxjs";

@Component({
	selector: "app-search-menu",
	templateUrl: "./search-menu.component.html",
	styleUrls: ["./search-menu.component.scss"],
})
export class SearchMenuComponent implements OnInit {
	modalService: any;
	favourites: [];

	/**
	 * Constructor
	 *
	 * @param {NgbModal} modalService
	 */
	constructor(
		public activeModal: NgbActiveModal,
		private _configService: SystemMenuService
	) {}

	openModal() {
		this.modalService.open(SearchMenuComponent);
	}
	ngOnInit() {
		this._configService
			.getFavourites()
			.pipe(
				map((response: any) => {
					if (response) {
						this.favourites = response;
					} else {
						//console.log("failed to get favourites", response);
					}
					// Success
				}),
				catchError((error) => {
					//console.log("error", error);
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}
}
