import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConstants } from 'app/app.globalConstants';
import { User } from 'app/auth/models';
import { AuthService } from 'app/base/auth/services/auth.service';
import { KBService } from 'app/base/knowledge-base/services/kb.service';
import { TagService } from 'app/base/tags/services/tag.service';
import { validateFile, getFileSizeText, customSearch } from 'app/common/utils/utils';
import { ChangeViewService } from 'app/servicedesk/change-management/services/change-view.service';
import { FileUploader } from 'ng2-file-upload';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ToastrService } from 'ngx-toastr';
import { TreeviewItem } from 'ngx-treeview';
import { from, map, reduce, catchError, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { InfinityModules } from '../../types/modules';
import { default as _map } from "lodash-es/map";
import { environment } from 'environments/environment';
import { Location } from "@angular/common";
const URL = `${environment.instance_url}` + "media";
@Component({
  selector: 'app-dns-incident-to-change',
  templateUrl: './dns-incident-to-change.component.html',
  styleUrls: ['./dns-incident-to-change.component.scss']
})
export class DnsIncidentToChangeComponent implements OnInit {
  @ViewChild("tagComp") tagComp: ElementRef;
	@Input() fromEditCall: boolean = true;
	@Input() incident_data;
	@Output("onHideConvInciToChange") onHideConvInciToChange = new EventEmitter<any>();
	@Output() addedChangeID = new EventEmitter<object>();
	@ViewChild("incidentDuedate") actualDate: FlatpickrOptions;

	requester: any = {};
	separateDialCode = true;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
	PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom, CountryISO.India];
	item: any = [];
	graphicsCard: any = [];
	kbPageNumber = 1;
	selectedCount = 3;
	cardCount = 3;
	showSuggestedKb = false;
	selectedOption = 3;
	kbCount = 0;
	totalCount = 0;
	viewCount = 0;
	private timer: any;
	public uploaded_files = [];
	typeDisplayName = this._translateService.instant("UI.k_communication");
	public isHideAddAttachment = true;
	public attachmentFile = [];
	public allAttachments: any = [];
	statusInput = [];
	team_id;
	user_type;
	public addChange: any = {};
	public quillEditorStyles = { "min-height": "300px" };
	isSpecifications: boolean = false;
	public dropdown = {
		assignee: [],
		classifications: [],
		state: [],
		status: [],
		urgency: [],
		priority: [],
		severity: [],
		event_type: [],
		type: [],
		impact: [],
		tagList: [],
		tagTypeList: [],
		users: [],
		teams: [],
		assignee_group: {
			self: [],
			group: [],
		},
		all_services: [],
		change_source: [],
		groupt_type_list: [],
		close_type: [],
		closure_category_list: [],
		catalogue_list: [],
		current_user_group: [],

		state_id_map: {},
		teams_and_self_assign: [],
		formatted_services: [],
		formatted_classification_data: [],
		change_type: [],
		team_user_profile_map: {},
		risk: [],
	};
	change_state_flag: any = {};
	currentUser: User;
	tag = {};
	public ALLOWED_TYPES;
	public MAX_IMAGE_SIZE;
	public MAX_HEIGHT;
	public MAX_WIDTH;
	selectedFiles = [];
	assignment_data: any = {};
	public config: PerfectScrollbarConfigInterface = { suppressScrollX: true };
	selfAssignSel = false;
	some = true;
	public type = "Internal Note";
	public checkCC = false;
	showClosedKb: any = false;
	showClosedInteraction: any = true;
	showHide = true;
	public emailToSelect = [];
	public showInteraction = true;
	public showKb = true;
	public dataDup = [];
	public kbDataDup = [];

	multiple: boolean = true;
	public basicDPdata: NgbDateStruct;
	public filesList: any = [];
	public suggested_kb = [];
	isReport: boolean = false;
	disableSaveBtn: boolean = false;
	attachments: any = [];
	emailAttachments: any = [];
	public defaultAvatarColor = "#f59518";
	treeInputData = [];
	public kbInput = {};
	selectedItem: any = [];
	module = InfinityModules.CHANGE;
	module_requester = InfinityModules.REQUESTER;
	module_tag = InfinityModules.TAG;
	module_kb = InfinityModules.KNOWLEDGEBASE;
	permissions: any;
	tag_permissions;
	requester_permissions: any;
	kb_permissions: any;
	stateKeyIdMap: any = {};
	custom_field_config: any = {};
	custom_field_data: any = {};
	is_tag_save_disabled = false;
	//   userTZ: any;
	service_config: any = {};
	classification_config: any = {};
	public imgHeightWidthStr = "img height='150px' width='300px'";
	fileUpload = function (evt) {
		var files = evt.target.files;
		var new_file_len = evt.target.files?.length;
		var added_file_len = this.filesList?.length;
		for (var i = 0; i < new_file_len; i++) {
			var file = files[i];
			const data = validateFile(file, this.ALLOWED_TYPES);
			if (data && data["is_valid"]) {
				if (this.emailAttachments.indexOf(file.name) == -1) {
					this.emailAttachments.push(file.name);
					var pos = added_file_len + i;
					var reader = new FileReader();
					reader.onload = (function (filesList, file, pos) {
						return function (e) {
							var data = e.target.result;
							filesList[pos] = {
								file_name: file.name,
								file_type: file.type,
								file_size: file.size,
								file_data: data,
								file_date: new Date(),
								//'file_date': this.datepipe.transform(new Date(),'MMM dd,yyyy hh:mm a')
							};
						};
					})(this.filesList, file, pos);
					reader.readAsDataURL(file);
				}
			} else if (data && !data["is_valid"]) {
				this.showFileValidationError(data);
			}
		}
		evt.srcElement.value = "";
	};
	showFileValidationError = (data) => {
		if (data) {
			// invalid file requirement
			const errors: any = [];
			if (data["is_allowed"] === false) {
				errors.push(
					this._translateService.instant("UI.k_supported_format") +
						_map(this.ALLOWED_TYPES, (val) => (val.includes(".") || val.includes("/") ? val : " ." + val))
				);
			}

			if (data["size"]) {
				errors.push(
					this._translateService.instant("Message.msg_max_size") + " " + getFileSizeText(this.MAX_IMAGE_SIZE)
				);
			}

			from(errors)
				.pipe(
					map((err) => `<li>${err}</li>`),
					reduce((acc, value) => acc.concat(value), "")
				)
				.subscribe((res) => {
					this._toastr.warning(
						`<b>${data["name"]}</b></br><ul class="pl-1">${res}</ul`,
						this._translateService.instant("Error.err_file_upload_failed"),
						{
							enableHtml: true,
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
				});
		}
	};
	// removing the file from POP UI.
	removeAttachedFile = function (index, filesList) {
		filesList.splice(index, 1);
		this.emailAttachments.splice(index, 1);
	};

	copyCode(inputTextValue) {
		const selectBox = document.createElement("textarea");
		selectBox.style.position = "fixed";
		selectBox.value = inputTextValue;
		document.body.appendChild(selectBox);
		selectBox.focus();
		selectBox.select();
		document.execCommand("copy");
		document.body.removeChild(selectBox);
		this._toastr.success("", this._translateService.instant("Message.msg_copy_success"), {
			toastClass: "toast ngx-toastr",
			closeButton: true,
		});
	}

	/**
	 * Constructor
	 *
	 * @param {NgbModal} _modalService
	 */
	constructor(
		// private modalService: NgbModal,
		private _modalService: NgbModal,
		private _coreConfigService: CoreConfigService,
		private _activatedRoute: ActivatedRoute,
		private _coreSidebarService: CoreSidebarService,
		private _toastr: ToastrService,
		private _translateService: TranslateService,
		private _tagService: TagService,
		private _router: Router,
		private _location: Location,
		private _authService: AuthService,
		public _kbService: KBService,
		public _changeService: ChangeViewService
	) {
		this._authService.currentUser.subscribe((x) => (this.currentUser = x));
		this.item = [
			{ id: "1", name: "Laptop" },
			{ id: "2", name: "Desktop" },
			{ id: "3", name: "tablet" },
		];
		this.graphicsCard = [
			{ id: "1", name: "4 gb Geforce GTX 1650" },
			{ id: "2", name: "8 gb Geforce GTX 1650" },
		];
		this.addChange.change_type_id = 1;
	}
	ngOnInit(): void {
		this.getOptions();
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.requester_permissions = user_permissions[this.module_requester];
		this.permissions = user_permissions[this.module];
		this.tag_permissions = user_permissions[this.module_tag];
		this.kb_permissions = user_permissions[this.module_kb];
		this.addChange = {};
		this.addChange["params"] = {};
		this.addChange["tag"] = [];
		this.addChange["current_watcher"] = [];
		this.requester = this.incident_data?.requester;
		if (this.fromEditCall) {
			this.addChange["priority_id"] = this.incident_data?.basic_info?.priority?.id;
			this.addChange["summary"] = this.incident_data?.basic_info?.summary;
			// this.addChange["description"] = this.incident_data?.basic_info?.description
			this.addChange["service_classification_name"] = this.incident_data?.basic_info?.service_classification_name;
			this.addChange["impact_service_name"] = this.incident_data?.basic_info?.impact_service_name;
			this.addChange["impact_service"] = this.incident_data?.basic_info?.impact_service;
			this.addChange["impact_service_tree_path"] = this.incident_data?.basic_info?.impact_service_tree_path;
			this.addChange["service_classification"] = this.incident_data?.basic_info?.service_classification;
			this.addChange["service_classification_path"] = this.incident_data?.basic_info?.service_classification_path;
			this.addChange["reporter_name"] = this.incident_data?.reporter_info?.name;
			this.addChange["reporter_email"] = this.incident_data?.reporter_info?.email;
			this.addChange["reporter_phone"] = this.incident_data?.reporter_info?.phone_number;
		} else {
			this.requester["full_name"] = this.incident_data?.requester?.requester_name;
			this.requester["email"] = this.incident_data?.requester?.requester_email;
			this.requester["contact_number"] = this.incident_data?.requester?.requester_phone;
			this.requester["requester_id"] = this.incident_data?.requester_id;
			this.addChange["priority_id"] = this.incident_data?.priority_id;
			this.addChange["summary"] = this.incident_data?.summary;
			// this.addChange["description"] = this.incident_data?.description;
			this.addChange["impact_service"] = this.incident_data?.impact_service;
			this.addChange["service_classification_name"] = this.incident_data?.service_classification_name;
			this.addChange["impact_service_name"] = this.incident_data?.impact_service_name;
			this.addChange["impact_service_tree_path"] = this.incident_data?.impact_service_tree_path;
			this.addChange["service_classification"] = this.incident_data?.service_classification;
			this.addChange["service_classification_path"] = this.incident_data?.service_classification_path;
			this.addChange["reporter_name"] = this.incident_data?.reporter?.reporter_name;
			this.addChange["reporter_email"] = this.incident_data?.reporter?.reporter_email;
			this.addChange["reporter_phone"] = this.incident_data?.reporter?.phone_number;
		}
		this.addChange["current_watcher"] = this.incident_data?.current_watcher || [];
		this.addChange["catalogue"] = this.incident_data?.basic_info?.catalogue;
		this.addChange["category"] = this.incident_data?.basic_info?.category;
		this.addChange["catalogue_name"] = this.incident_data?.basic_info?.catalogue_name;
		this.addChange["category_name"] = this.incident_data?.basic_info?.category_name;
		this.requester["profile_image"] = this.incident_data?.requester_profile;
	}

	public dateOptions: FlatpickrOptions = {
		altInput: true,
	};

	public attachmentUploader: FileUploader = new FileUploader({
		url: URL,
		isHTML5: true,
	});
	toggleReport() {
		this.isReport = !this.isReport;
	}

	isShownCc: boolean = false; // hidden by default
	toggleCc() {
		this.isShownCc = !this.isShownCc;
	}

	// modal Open Default
	modalOpenGroup(modalGroup) {
		this._modalService.open(modalGroup, {
			centered: true,
		});
	}

	onAssigneeSubmit(data) {
		if (data.form.valid === true) {
			this.addChange = Object.assign({}, this.addChange, this.assignment_data);
			this._modalService.dismissAll();
		}
	}
	onSubmitNew = (data) => {
		if (this.addChange.due_date instanceof Array) {
			this.addChange.due_date = this.addChange.due_date[0];
			this.addChange.due_date = this.addChange.due_date.toDateString();
		}
		if (data.form.valid === true) {
			this.addChange["inci_add_flag"] = this.change_state_flag["New"];
			let option_status = this.dropdown?.status.filter((e) => e["state_id"] == this.stateKeyIdMap["Open"]);
			this.addChange["status_id"] = option_status?.[0]["id"];
			this.addChange["state_id"] = option_status?.[0]["state_id"];
			this.saveChange();
		}
	};
	saveChange() {
		this.addChange["attached_files"] = this.filesList;
		this.addChange["custom_field_data"] = this.custom_field_data;
		this.get_custom_data(this.custom_field_config?.custom_fields, this.addChange?.custom_field_data);
		this.addChange["requester_name"] = this.requester["full_name"];
		this.addChange["requester_email"] = this.requester["email"];
		this.addChange["requester_phone_number"] = this.requester["phone_number"];
		this.addChange["requester_id"] = this.requester?.requester_id;
		this.addChange["closure_note"] = "";
		this.addChange["communication_type"] = this.type;
		this.disableSaveBtn = true;
		this._changeService
			.saveChange(this.addChange)
			.pipe(
				map((response: any) => {
					if (response.status == "success") {
						this._toastr.success(
							this._translateService.instant(response["message"], { id: response?.id }),
							this._translateService.instant("Message.msg_save_success"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						this.addedChangeID.emit(response?.data);
						this.onHideConvInciToChange.emit("hide");
					} else {
						this._toastr.error(
							this._translateService.instant(response["message"]),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						this.disableSaveBtn = false;
					}
					// Success
				}),
				catchError((error) => {
					this.disableSaveBtn = false;
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}
	getEnrichTextData = (event) => {
		this.addChange.erichTextData = event.html;
	};
	getPulicReplyData = (event) => {
		this.addChange.public_reply = event.html;
	};
	removeMainFile = (idx) => {
		Swal.fire({
			title: this._translateService.instant("Message.msg_delete_title"),
			text: this._translateService.instant("Message.msg_delete_text"),
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: this._translateService.instant("Message.msg_delete_confirm"),
			customClass: {
				confirmButton: "btn btn-primary",
				cancelButton: "btn btn-danger ml-1",
			},
		}).then((result) => {
			if (result.isConfirmed) {
				this.filesList.splice(idx, 1);
				this.emailAttachments.splice(idx, 1);
			}
		});
	};
	onCreateTag(data) {
		this.is_tag_save_disabled = true;
		if (data.form.valid === true) {
			this._tagService
				.saveTag(this.tag)
				.pipe(
					map((response: any) => {
						if (response) {
							let tag_id = response?.id;
							if (response["status"] == "success") {
								this._toastr.success(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Message.msg_save_success"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);

								this._modalService.dismissAll();
								this._changeService
									.getOptions({ call_for_tags: true })
									.pipe(
										map((response: any) => {
											this.dropdown.tagList = response["tags"];
											this.addChange.tag.push(tag_id);
											this.addChange.tag = [...this.addChange.tag];
										})
									)
									.subscribe();
							} else {
								this.is_tag_save_disabled = false;
								this._toastr.error(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Error.err_save_failed"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							}
						}
					}),
					catchError((error) => {
						this.is_tag_save_disabled = false;
						this._toastr.error(
							this._translateService.instant("Error.err_tag_save_failed"),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);

						this._toastr.error(
							this._translateService.instant("Error.err_tag_save_failed"),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);

						return throwError(error.statusText);
					})
				)
				.subscribe();
		} else {
			this.is_tag_save_disabled = false;
		}
	}

	getOptions = () => {
		this._changeService.getOptions({ for_add_change: true }).subscribe(
			(response) => {
				if (response) {
					if (response) {
						this.change_state_flag = response["change_state_flag"];
						this.dropdown["state"] = response["state"];
						this.dropdown["status"] = response?.status;
						this.stateKeyIdMap = response["state_key_id_map"];
						this.custom_field_config = response?.custom_filed_options;
						this.dropdown["urgency"] = response["urgency"];
						this.dropdown["priority"] = response["priority"];
						this.dropdown["urgency"] = response["urgency"];
						this.dropdown["severity"] = response["severity"];
						this.dropdown["event_type"] = response["event_type"];
						this.dropdown["type"] = response["type"];
						this.dropdown["impact"] = response["impact"];
						this.dropdown["tagList"] = response["tags"];
						this.dropdown["tagTypeList"] = response["TAG_TYPE_LIST"];
						this.dropdown["users"] = response["users"];
						this.dropdown["teams"] = response["teams"];
						this.dropdown["change_type"] = response["change_type"];
						this.dropdown["risk"] = response["risk"];
						this.dropdown["teams_and_self_assign"] = [
							{ team_id: "self_assign", name: "Self Assign" },
						].concat(response["teams"]);
						this.dropdown["assignee_group"]["group"] = response["teams"];
						this.dropdown["change_source"] = response["change_source"];
						this.dropdown["groupt_type_list"] = response["groupt_type_list"];
						this.dropdown["attach_category_list"] = response["attach_category_list"];
						this.dropdown["file_icon_color_map"] = response["file_icon_color_map"];
						let imageDefaults = response["image_upload_defaults"];
						this.ALLOWED_TYPES = imageDefaults["allowed_types"];
						this.MAX_IMAGE_SIZE = imageDefaults["max_size"];
						this.MAX_HEIGHT = imageDefaults["max_height"];
						this.MAX_WIDTH = imageDefaults["max_width"];
						this.dropdown["close_type"] = response["close_type"];
						this.dropdown["closure_category_list"] = response["closure_category"];
						this.dropdown["current_user_group"] = response["current_user_group"];
						this.dropdown["state_id_map"] = response["state_id_map"];
						this.addChange.change_type_id = response["CHANGE_STANDARD_CHANGE_TYPE"];
						this.changeTypeChange();
						this.addChange.risk_id = response["CHANGE_DEFAULT_RISK"];
					}
				}
			},
			(error) => {
				//todo
			}
		);
	}

	clearAssignee(assignment) {
		assignment["assignee"] = null;
		assignment["assignee_profile"] = null;
		this.addChange = Object.assign({}, this.addChange, this.assignment_data);
	}

	groupChange(options) {
		// this.assignment_data = {};
		if (this.addChange.support_group_tmp != "self_assign") {
			this.assignment_data.support_group = this.addChange.support_group_tmp;
		}
		let selected_group = options.filter(
			(e) => e.team_id == this.addChange.support_group_tmp || e.team_id == this.assignment_data.support_group
		);

		if (selected_group?.length > 0) {
			this._changeService.getTeamUserMap({ team_id: selected_group[0]["team_id"] }).subscribe((response) => {
				this.dropdown.team_user_profile_map = response;
				this.dropdown.team_user_profile_map = response;
			});
			this.assignment_data["group_name"] = selected_group[0]["name"];
			this.assignment_data["group_type"] = selected_group[0]["group_type"];
			this.addChange["group_type_tmp"] = selected_group[0]["group_type"];
			if (selected_group[0]["group_type"] === "Expert") {
				this.dropdown["assignee"] = selected_group[0]["staffs"];
			}
			this.clearAssignee(this.assignment_data);
			if (this.selfAssignSel) {
				if (selected_group[0]["group_type"] === "Expert") {
					this.assignment_data["assignee"] = selected_group[0]["staffs"][0]["profile_id"];
					this.assignment_data["assignee_profile"] = selected_group[0]["staffs"][0];
				}
			} else {
				this.clearAssignee(this.assignment_data);
			}
		} else {
			if (!this.selfAssignSel) {
				this.dropdown["assignee"] = [];
			}
			this.assignment_data["group_name"] = "";
			this.clearAssignee(this.assignment_data);
		}
	}

	cancel() {
		this._location.back();
	}


	downloadAttachment = (path, file) => {
		let file_path = URL + path.split("media")[1];
		let downloadable_file_path = file_path + "/" + file;
		window.open(downloadable_file_path, "_blank");
	};

	stringToColor(reqName) {
		if (!reqName) {
			reqName = "anaymous";
		}

		let hash = 0;
		let i;

		/* eslint-disable no-bitwise */
		for (i = 0; i < reqName?.length; i += 1) {
			hash = reqName.charCodeAt(i) + ((hash << 5) - hash);
		}

		let colour = "#";

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff;
			colour += `00${value.toString(16)}`.substr(-2);
		}

		return colour;
	}
	assignToMyself(modalGroup) {
		this.selfAssignSel = true;
		if (this.dropdown?.current_user_group?.length == 1) {
			this.assignment_data["support_group"] = this.dropdown?.current_user_group[0].team_id;
			this.assignment_data["group_type"] = this.dropdown?.current_user_group[0].group_type;
			this.assignment_data["group_name"] = this.dropdown?.current_user_group[0].name;
			if (this.dropdown.current_user_group[0]?.group_type === "Expert") {
				this.assignment_data["assignee"] = this.currentUser.profile.profile_id;
				this.assignment_data["assignee_profile"] = this.currentUser.profile;
				this.addChange = Object.assign({}, this.addChange, this.assignment_data);
			}
		} else if (this.dropdown?.current_user_group?.length > 1) {
			this._modalService.open(modalGroup, {
				centered: true,
			});
		} else if (this.dropdown?.current_user_group?.length < 1) {
			this.assignment_data["group"] = null;
			this.assignment_data["group_name"] = null;
			this.assignment_data["group_type"] = null;
			this.assignment_data["assignee"] = this.currentUser?.profile?.profile_id;
			this.assignment_data["assignee_profile"] = this.currentUser?.profile;
			this.addChange = Object.assign({}, this.addChange, this.assignment_data);
		}
		this.addChange.support_group_tmp = "self_assign";
	}

	closeChangePopup(form) {
		this._modalService.dismissAll();
	}
	updateWatcher() {
		let watcher = {};
		let profile = {};
		profile["profile_id"] = this.currentUser.profile.profile_id;
		profile["full_name"] = this.currentUser.profile.full_name;
		profile["email"] = this.currentUser.profile.email;
		profile["tags"] = this.currentUser.profile.tags;
		profile["role_type"] = this.currentUser.profile.role_type;
		profile["user"] = this.currentUser.profile.user;
		profile["name"] = this.currentUser.profile.full_name;
		profile["contact_number"] = this.currentUser.profile.contact_number;
		this.addChange.current_watcher = [profile];
		watcher["Email_id"] = [this.currentUser.profile.email];
		watcher["phone"] = [this.currentUser.profile.contact_number];
	}

	ChangeType(name, key_name) {
		this.type = key_name;
		this.typeDisplayName = this._translateService.instant(name);
	}

	changeTypeChange = () => {
		// to do- on change type check for workflow
		this._changeService
			.getChangeTypeBaseWorkflowStatus({ change_type_id: this.addChange.change_type_id })
			.subscribe((response) => {
				this.dropdown["status"] = response.options;
				this.custom_field_config = response?.custom_filed_options;
				if (response?.custom_field_data) {
					this.custom_field_data = response?.custom_field_data;
				}
			});
	};

	CC() {
		this.isShownCc = !this.isShownCc;
	}

	selectAddEmailMethod(name) {
		return { name: name, tag: true };
	}

	moveToEdit(id) {
		const url = this._router.serializeUrl(this._router.createUrlTree(["change/edit/" + id]));
		window.open(url, "_blank");
	}

	selectAddCC(name) {
		return { name: name, tag: true };
	}

	assigneeChange() {
		this.assignment_data["assignee"] = this.assignment_data?.assignee_profile?.profile_id;
	}

	closeCurrAssignPopup() {
		this.assignment_data["support_group"] = this.addChange?.support_group || null;
		this.assignment_data["assignee"] = this.addChange?.assignee || null;
		this.assignment_data["assignee"] = this.addChange?.assignee_profile || {};
		this.addChange["support_group_tmp"] = null;
		this.selfAssignSel = false;
		this._modalService.dismissAll();
	}

	statusBadgeChange = (data) => {
		this.addChange[data["field"]] = data["event"];
		if (data?.field == "priority_id" || data?.field == "risk_id") {
			this.addChange[data["field"]] = data["event"]["id"];
		}
	};
	getClassificationdata() {
		this._changeService
			.getImpactServiceClassification({ service_id: this.addChange?.impact_service, format_to_treeview: true })
			.subscribe(
				(res) => {
					this.dropdown["formatted_classification_data"] = [];
					this.addChange.priority_id = res?.priority;
					res?.classification?.forEach((ele) => {
						const data = new TreeviewItem(ele);
						this.dropdown["formatted_classification_data"].push(data);
					});
				},
				(error) => {
					//console.log("error_while_fetching impact service", error);
				}
			);
	}
	serviceValueChange = (item) => {
		this.addChange.impact_service = item?.value;
		this.addChange.impact_service_name = item?.text;
		this.addChange.impact_service_tree_path = item?.full_path_text;
		this.addChange.service_classification = "";
		this.addChange.service_classification_name = "";
		this.addChange.service_classification_path = "";
		this.addChange["catalogue"] = item?.sel_tree_view_data[item?.sel_tree_view_data?.length - 1]["value"];
		this.addChange["catalogue_name"] = item?.sel_tree_view_data[item?.sel_tree_view_data?.length - 1]["text"];
		if (item?.sel_tree_view_data && item?.sel_tree_view_data?.length > 2) {
			this.addChange["category"] = item?.sel_tree_view_data[1]["value"];
			this.addChange["category_name"] = item?.sel_tree_view_data[1]["text"];
		} else {
			this.addChange["category"] = "";
			this.addChange["category_name"] = "";
		}
		this.getClassificationdata();
	};
	classificationValueChange = (item) => {
		this.addChange.service_classification = item?.value;
		this.addChange.service_classification_name = item?.text;
		this.addChange.service_classification_path = item?.full_path_text;
	};

	openModalAD(TagComp, name) {
		this.tag = {};
		this.tag["name"] = name;
		if (this.dropdown.tagTypeList != undefined) {
			this.tag["type"] = this.dropdown.tagTypeList[0].id;
		}
		this._modalService.open(TagComp, {
			centered: true,
			animation: false,
			size: "md",
		});
		this.is_tag_save_disabled = false;
	}

	selectAddTagMethod = (name) => {
		if (this.permissions.add) {
			this.openModalAD(this.tagComp, name);
		}
	};

	updateFieldName(evt, id, dropdown_name, set_field, ref_field) {
		let selected = this.dropdown[dropdown_name].filter((e) => e[id] == evt);
		set_field = selected[0][ref_field];
	}

	info_action = () => {
		if (this.addChange?.asset?.ci_id) {
			window.open("/it-asset/asset/" + this.addChange?.asset?.ci_id, "_blank");
		}
	};

	get_custom_data(config_data, custom_fields) {
		let actual_conf_data = {};
		if (config_data && custom_fields) {
			config_data.forEach((conf) => {
				let data_key = conf?.data_key;
				let label = conf?.label;
				let value = custom_fields[data_key];
				if (conf?.type == "date" || conf?.type == "datetime-local") {
					if (this.addChange.custom_field_data[data_key] instanceof Array) {
						this.addChange.custom_field_data[data_key] = this.addChange.custom_field_data[data_key][0];
					}
					if (value instanceof Array) {
						value = value[0];
					}
				}
				if (value) {
					actual_conf_data[label] = value;
				}
			});
		}
		return actual_conf_data;
	}
	customSearchFn = (term: string, item: any) => {
		return customSearch(term, item, "full_name", "email");
	};

	ngOnDestroy() {
		this._modalService.dismissAll();
		Swal.close();
		this.actualDate?.flatpickr.close();
	}

	handleEvent(e) {}
	handleSpecification() {
		this.isSpecifications = true;
		// this.isSpecifications = !this.isSpecifications
	}
	onImpactServiceClick = (event, key) => {
		event.preventDefault();
		if (key == "impact_service") {
			if (!this.service_config) {
				this.service_config = {};
			}
			this.service_config["is_loading"] = true;
			this.dropdown["formatted_services"] = [];
			this._changeService
				.getRequesterImpactService({
					requester_id: this.requester?.requester_id,
					is_published: true,
					bind_classification: false,
					module_name_list: JSON.stringify(["change"]),
				})
				.subscribe(
					(res) => {
						this.service_config["is_editable"] = true;
						this.dropdown["formatted_services"] = [];
						res?.forEach((ele) => {
							const data = new TreeviewItem(ele);
							this.dropdown["formatted_services"].push(data);
						});
						this.service_config["is_loading"] = false;
					},
					(error) => {
						//console.log("error_while_fetching impact service", error);
						this.service_config["is_loading"] = false;
					}
				);
		} else if (key == "service_classification") {
			if (!this.classification_config) {
				this.classification_config = {};
			}
			this.classification_config["is_loading"] = true;
			this.dropdown["formatted_classification_data"] = [];
			if (this.addChange?.impact_service) {
				this._changeService
					.getImpactServiceClassification({
						service_id: this.addChange?.impact_service,
						format_to_treeview: true,
					})
					.subscribe(
						(res) => {
							this.classification_config["is_editable"] = true;
							this.dropdown["formatted_classification_data"] = [];
							this.addChange.priority_id = res?.priority;
							res?.classification?.forEach((ele) => {
								const data = new TreeviewItem(ele);
								this.dropdown["formatted_classification_data"].push(data);
							});
							this.classification_config["is_loading"] = false;
						},
						(error) => {
							//console.log("error_while_fetching impact service", error);
							this.classification_config["is_loading"] = false;
						}
					);
			} else {
				this._toastr.error(this._translateService.instant("Error.err_select_impact_service"));
				this.classification_config["is_loading"] = false;
			}
		}
	};

	closeSidebar() {
		this.onHideConvInciToChange.emit();
	}
}
