import { Component, HostListener, Input, OnInit, EventEmitter, Output, ViewChild, ElementRef } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FileUploader } from "ng2-file-upload";
import { ToastrService } from "ngx-toastr";
import { Dimensions, ImageCroppedEvent, ImageTransform } from "./image-cropper/interfaces/index";
import {default as _map } from "lodash-es/map";
import without from "lodash-es/without";
import { base64StringToBlob } from "blob-util";
import { TranslateService } from "@ngx-translate/core";
import { getFileSizeText, validateFile } from "../../../../common/utils/utils";
import { from } from "rxjs";
import { map, reduce } from "rxjs/operators";

const URL = "http://localhost:8080/common/user/profileimage/";

@Component({
	selector: "app-img-upload-feature",
	templateUrl: "./img-upload-feature.component.html",
	styleUrls: ["./img-upload-feature.component.scss"],
})
export class ImgUploadFeatureComponent implements OnInit {
	@Output() imgUploaderDetails: EventEmitter<any> = new EventEmitter<any>();
	@Output() fileUploader: EventEmitter<any> = new EventEmitter<any>();
	@Output() Removeimg: EventEmitter<any> = new EventEmitter<any>();
	@Input() showQueue: boolean;
	@Input() multipleFiles: boolean = false;
	@Input() displayResoluion: boolean;
	@Input() customW: any;
	@Input() customH: any;
	@Input() imgDetailsObject: any;
	@Input() imgType: any = "";
	// @Input() imgDetailsObject = {
	//   aspectRatio: "4/4",
	//   resizeToWidth: "128",
	//   cropperMinWidth: "128",
	//   maintainAspectRatio: boolean,
	// }
	@Input() squareImage: boolean;
	@Input() cropImage: boolean;
	@Input() flipImages: boolean;
	@Input() ALLOWED_TYPES: Array<any>;
	@Input() CSVFileType = false;
	@Input() MAX_IMAGE_SIZE;
	@ViewChild("file") fileInput: ElementRef;
	@Input() is_cover = false;
	@Input() isOnlyImage: any;
	@Input() validation_type = false;
	@Input() containWithinAspectRatio = false;
	cropper: any = { x1: 0, y1: 0, x2: 600, y2: 300 };
	error: string;
	dragAreaClass: string;
	imageChangedEvent: any = "";
	croppedImage: any = "";
	originalImage: any = "";
	canvasRotation = 0;
	rotation = 0;
	scale = 1;
	showCropper = false;
	hasCropedImg = false;
	setImage = null;
	isDefault = false;
	// containWithinAspectRatio = false;
	transform: ImageTransform = {};
	public hasBaseDropZoneOver: boolean = false;
	public uploader: FileUploader = new FileUploader({
		url: URL,
		isHTML5: true,
	});
	// public MAX_IMAGE_SIZE = 20971520;
	public MAX_HEIGHT = 15200;
	public MAX_WIDTH = 25600;
	public file: any;
	// public ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];
	public allFileList: any = [];
	public copyOfAllFileList: any;
	public imgCropRef: any;
	allowedfileType = [];
	public fileSize;
	// public imgDetailsObject = {
	//  aspectRatio: "4 / 4",
	//  resizeToWidth: "128",
	//  cropperMinWidth: "128",
	//  maintainAspectRatio: false
	// }
	// public showQueue = false;
	// public multipleFiles = false;
	img_height: any;
	img_width: any;
	fileBlob: any;

	fileOverBase(e: any): void {
		this.hasBaseDropZoneOver = e;
	}

	checkValidation(files) {
		if (this.CSVFileType && this.file["type"] != "application/vnd.ms-excel") {
			this._toastr.warning(
				this._translateService.instant("UI.k_supported_format") + " " + _map(this.ALLOWED_TYPES),
				this._translateService.instant("Error.err_file_upload_failed"),
				{
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				}
			);
			return false;
		}
		if (files) {
			const InvalidFileArray = [];
			Array.from(files).forEach((file) => {
				if (file["size"] > this.MAX_IMAGE_SIZE) {
					InvalidFileArray.push(file);
				}
			});
			if (InvalidFileArray.length > 0) {
				this._toastr.warning(
					this._translateService.instant("Message.msg_max_size") + " " + getFileSizeText(this.MAX_IMAGE_SIZE),
					//  +
					// "    " +
					// this._translateService.instant("Message.msg_invalid_file") +
					// ": " +
					// _.map(InvalidFileArray, "name"),
					this._translateService.instant("Error.err_file_upload_failed"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
				return false;
			} else if (files.length === 1 && this.ALLOWED_TYPES.includes(files[0].type) && this.isOnlyImage) {
				const reader = new FileReader();
				reader.readAsDataURL(this.file);
				reader.onload = (e: any) => {
					const image = new Image();
					image.src = e.target.result;

					image.onload = (rs) => {
						this.img_height = rs.currentTarget["height"];
						this.img_width = rs.currentTarget["width"];
					};
				};

				if (this.img_height > this.MAX_HEIGHT && this.img_width > this.MAX_WIDTH) {
					this._toastr.warning(
						"Maximum dimentions allowed " + this.MAX_HEIGHT + "*" + this.MAX_WIDTH + "px",
						"Failed Upload",
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					return false;
				} else {
					return true;
				}
			} else {
				let is_valid = true;
				Array.from(files)?.every((file, i) => {
					const fileValidationData = validateFile(
						file,
						this.ALLOWED_TYPES,
						this.MAX_IMAGE_SIZE,
						this.validation_type
					);
					const errors = [];
					if (fileValidationData && !fileValidationData["is_valid"]) {
						is_valid = false;
						if (fileValidationData["is_allowed"] === false) {
							errors.push(
								this._translateService.instant("UI.k_supported_format") +
									_map(this.ALLOWED_TYPES, (val) => " ." + val)
							);
						}

						if (fileValidationData["size"]) {
							errors.push(
								this._translateService.instant("Message.msg_max_size") +
									" " +
									getFileSizeText(this.MAX_IMAGE_SIZE)
							);
						}

						from(errors)
							.pipe(
								map((err) => `<li>${err}</li>`),
								reduce((acc, value) => acc.concat(value), "")
							)
							.subscribe((res) => {
								this._toastr.warning(
									`<b>${fileValidationData["name"]}</b></br><ul class="pl-1">${res}</ul`,
									this._translateService.instant("Error.err_file_upload_failed"),
									{
										enableHtml: true,
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							});
						return false;
					}
					return true;
				});
				return is_valid;
			}
		}
	}

	check() {
		this.isDefault = !this.isDefault;
		const reader = new FileReader();
		reader.readAsDataURL(this.file);
		reader.onload = (e: any) => {
			if (reader.result) {
				this.originalImage = reader.result;
				this.croppedImage = this.originalImage;
				// this.setImage = this.originalImage
				// console.log(this.croppedImage);
			}
		};

		// this.isDefault = false;
	}

	/***
	 * @method onUpload: will be trigger when we drop files to draggable area
	 * @param files
	 * @param modalVC
	 */
	onUpload(files: any, modalVC): void {
		this.handleFileDropOrSelection(Array.from(files), modalVC);
	}

	removeFiles(item) {
		// console.log(item);
		this.allFileList = without(this.allFileList, item);
		this.allFileList = this.allFileList;
	}

	removeAllFiles() {
		this.allFileList = [];
	}

	uploadAllFiles() {
		// console.log("All File List:  ", this.allFileList);
	}

	uploadFile(item) {
		// console.log("Upload Single File: ", item);
	}

	showFileValidationError = (data) => {
		if (data) {
			// invalid file requirement
			const errors = [];
			if (data["is_allowed"] === false) {
				errors.push(
					this._translateService.instant("UI.k_supported_format") +
						_map(this.ALLOWED_TYPES, (val) => (val.includes(".") || val.includes("/") ? val : " ." + val))
				);
			}

			if (data["size"]) {
				errors.push(
					this._translateService.instant("Message.msg_max_size") + " " + getFileSizeText(this.MAX_IMAGE_SIZE)
				);
			}

			from(errors)
				.pipe(
					map((err) => `<li>${err}</li>`),
					reduce((acc, value) => acc.concat(value), "")
				)
				.subscribe((res) => {
					this._toastr.warning(
						`<b>${data["name"]}</b></br><ul class="pl-1">${res}</ul`,
						this._translateService.instant("Error.err_file_upload_failed"),
						{
							enableHtml: true,
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
				});
		}
	};

	handleFileDropOrSelection = (files: Array<any>, modalVC) => {
		this.canvasRotation = 0;
		if (files) {
			if (this.multipleFiles && files.length > 1) {
				// handling for multi file selection
				this.allFileList = [];
				this.convertIntoBase64(files);
				files.forEach((file, index) => {
					const data = validateFile(file, this.ALLOWED_TYPES, this.MAX_IMAGE_SIZE, this.validation_type);
					if (data && data["is_valid"]) {
						file["formatted_size"] = getFileSizeText(file?.size || file?.file_size);
						this.allFileList.push(file);
					} else {
						this.showFileValidationError(data);
					}
				});
				this.fileUploader.emit(this.allFileList);
			} else if (files?.length === 1) {
				// handling for single file selection
				const file = files[0];
				const data = validateFile(file, this.ALLOWED_TYPES, this.MAX_IMAGE_SIZE, this.validation_type);
				if (data && data["is_valid"]) {
					this.convertIntoBase64(files);
					file["formatted_size"] = getFileSizeText(file?.size || file?.file_size);
					// handle files
					if (this.isOnlyImage ){
						// handled image
						this.imgCropRef = this.modalService.open(modalVC, {
							centered: true,
							windowClass: "file-upload-modal",
						});
						this.file = file;
						this.isDefault = false;
						this.imageChangedEvent = {
							type: "change",
							target: {
								files: files,
							},
						};
					} else {
						
						this.allFileList = files;
						this.fileUploader.emit(this.allFileList);
						// console.log("test",this.ALLOWED_TYPES, this.allFileList  )
					}
				} else if (data && !data["is_valid"]) {
					this.fileInput.nativeElement.value = null;
					//this.fileInput.nativeElement.removeFile(file);
					this.showFileValidationError(data);
				}
			}
		}
	};

	/**
	 * @method fileChangeEvent:will be trigger when we click & select files
	 * @param event
	 * @param modalVC
	 */
	fileChangeEvent(event: any, modalVC): void {
		// this.file = event.target.files[0];
		this.handleFileDropOrSelection(Array.from(event.target.files), modalVC);
	}

	convertIntoBase64(data) {
		Array.from(data).forEach((item, index) => {
			const reader = new FileReader();
			reader.readAsDataURL(data[index]);
			reader.onload = () => {
				item["file_data"] = reader.result;
				let object = "";
				object = reader.result.toString();
				const blob = base64StringToBlob(object.split(",")[1], data[index].type);
				item["blob"] = blob;
			};
		});
	}

	changeFile(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	onFileChange(event: any) {
		const files: FileList = event.target.files;
		this.saveFiles(files);
		// this.modalService.open(modalVC, {
		//   centered: true
		// });
	}

	constructor(
		private modalService: NgbModal,
		private _toastr: ToastrService,
		private _translateService: TranslateService
	) {}

	ngOnInit(): void {
		if (this.isOnlyImage === undefined) {
			this.isOnlyImage = true;
		}
		for (let i = 0; i < this.ALLOWED_TYPES.length; i++) {
			if (this.ALLOWED_TYPES[i] == "image/png") {
				this.allowedfileType.push("png");
			}
			else if (this.ALLOWED_TYPES[i] == "image/jpeg") {
				this.allowedfileType.push(" jpeg");
			}
			else if (this.ALLOWED_TYPES[i] == "image/jpg") {
				this.allowedfileType.push(" jpg");
			}
			else if (this.ALLOWED_TYPES[i] == "csv") {
				this.allowedfileType.push(" csv");
			}
			else{
				this.allowedfileType.push(" " + this.ALLOWED_TYPES[i]);
			}
		}

		this.fileSize = getFileSizeText(this.MAX_IMAGE_SIZE);
		this.dragAreaClass = "dragarea";
	}

	@HostListener("dragover", ["$event"]) onDragOver(event: any) {
		this.dragAreaClass = "droparea";
		event.preventDefault();
	}

	@HostListener("dragenter", ["$event"]) onDragEnter(event: any) {
		this.dragAreaClass = "droparea";
		event.preventDefault();
	}

	@HostListener("dragend", ["$event"]) onDragEnd(event: any) {
		this.dragAreaClass = "dragarea";
		event.preventDefault();
	}

	@HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
		this.dragAreaClass = "dragarea";
		event.preventDefault();
	}

	@HostListener("drop", ["$event"]) onDrop(event: any) {
		this.dragAreaClass = "dragarea";
		event.preventDefault();
		event.stopPropagation();
		// this.fileChangeEvent(event);
		// event.target.value = "C:\\fakepath\\" + event?.dataTransfer?.files[0].name
		if (event.dataTransfer.files) {
			const files: FileList = event.dataTransfer.files;
			this.saveFiles(files);
		}
	}
	onChange(event){
		event.srcElement.value = "";
	}
	
	saveFiles(files: FileList) {
		if (files.length > 1) {
			this.error = "Only one file at time allow";
		} else {
			this.error = "";
			//  console.log(files[0].size, files[0].name, files[0].type);
		}
	}

	imageCropped(event: ImageCroppedEvent) {
		this.hasCropedImg = true;
		this.croppedImage = event.base64;
		// console.log(event, base64ToFile(event.base64));
	}

	imageLoaded() {
		this.showCropper = true;
	}

	cropperReady(sourceImageDimensions: Dimensions) {
		// console.log('Cropper ready', sourceImageDimensions);
	}

	loadImageFailed() {
		// console.log('Load failed');
	}

	rotateLeft() {
		this.canvasRotation--;
		this.flipAfterRotate();
	}

	rotateRight() {
		this.canvasRotation++;
		this.flipAfterRotate();
	}

	private flipAfterRotate() {
		const flippedH = this.transform.flipH;
		const flippedV = this.transform.flipV;
		this.transform = {
			...this.transform,
			flipH: flippedV,
			flipV: flippedH,
		};
	}

	flipHorizontal() {
		this.transform = {
			...this.transform,
			flipH: !this.transform.flipH,
		};
	}

	flipVertical() {
		this.transform = {
			...this.transform,
			flipV: !this.transform.flipV,
		};
	}

	resetImage() {
		this.scale = 1;
		this.rotation = 0;
		this.canvasRotation = 0;
		this.transform = {};

		this.isDefault = !this.isDefault;
		const reader = new FileReader();
		reader.readAsDataURL(this.file);
		reader.onload = (e: any) => {
			if (reader.result) {
				this.originalImage = reader.result;
				this.croppedImage = this.originalImage;
				// this.setImage = this.originalImage
				// console.log(this.croppedImage);
			}
		};

		// this.isDefault = false;
	}

	zoomOut() {
		this.scale -= 0.1;
		this.transform = {
			...this.transform,
			scale: this.scale,
		};
	}

	zoomIn() {
		this.scale += 0.1;
		this.transform = {
			...this.transform,
			scale: this.scale,
		};
	}

	toggleContainWithinAspectRatio() {
		this.containWithinAspectRatio = !this.containWithinAspectRatio;
	}

	updateRotation() {
		this.transform = {
			...this.transform,
			rotate: this.rotation,
		};
	}

	removeImage() {
		this.setImage = null;
		this.allFileList = [];
		this.Removeimg.emit(this.setImage);
	}

	setCroppedImage() {
		this.setImage = this.croppedImage;
		this.modalService.dismissAll();
		this.isDefault = false;
		this.imgUploaderDetails.emit(this.setImage);
		// console.log("File List: ", this.allFileList);
	}

	closeModal() {
		this.fileInput.nativeElement.value = "";
		this.imgCropRef.close();
		this.isDefault = false;
	}

	defaultImage() {
		this.isDefault = !this.isDefault;
		this.aspectRatio();
	}

	aspectRatio(): boolean {
		return this.isDefault ? false : true;
	}

	getSelectedFiles = function (evt: EventEmitter<File[]>) {
		this.selectedFiles = [];
		// for (let idx = 0; idx < this.uploader.queue.length; idx++) {
		const tempFile = {};
		const file = this.file;
		const reader = new FileReader();
		const fileLoaded = (event) => (tempFile["file_data"] = event.target.result);
		reader.onload = fileLoaded;
		reader.readAsDataURL(file);
		tempFile["file_name"] = file.name;
		tempFile["file_size"] = file.size;
		tempFile["file_date"] = file.lastModified;
		tempFile["file_type"] = file.type;
		tempFile["category_id"] = this.attachment_category;
		tempFile["description"] = this.attachment_description;
		this.selectedFiles.push(tempFile);
		// }
		// this.fileUploader.emit(this.selectedFiles);
	};
	
}
