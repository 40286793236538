<div class="full-body-height incident-grid-data p-1">
	<div class="main-content border border-radius-6 flex-height" [ngClass]="{ activeSidebarPanel: submenu === true }">
		<div class="left-sec" style="width: 240px">
			<div class="side-pannel">
				<div class="sidebar py-0">
					<!-- <ng-container *ngIf="incidentArray['type'] === 'incident_option'"> </ng-container> -->
					<div class="filter-side-menu full-height pb-1">
						<ng-scrollbar class="custom-scrollbar section-scrollbar">
							<div>
								<div class="filter-head">
									<span class="title-color fw-500">{{ "UI.k_views" | translate }}</span>
								</div>
								<div class="sidebar-menu-list">
									<div class="sidebar-nav d-flex flex-column">
										<ng-container *ngFor="let drpOption of leftCountFilter; let i = index">
											<a
												aria-controls="collapseExample1"
												data-toggle="collapse"
												id="btn-{{ i }}"
												name="menu-{{ i }}"
												ngbTooltip="{{ drpOption.view_desc | translate }}"
												class="sidebar-item d-flex align-items-baseline justify-content-between"
												[ngClass]="{ active: selectedItem === drpOption.sequence }"
												(click)="onSelect(drpOption, i)"
											>
												<span class="text-truncate" style="width: 13em">
													{{ drpOption.view_name | translate }}
												</span>
												<ng-container *ngIf="drpOption.filter_item">
													<div class="popover-comment card-bg border">
														<div class="comment-txt">
															<span class="sm-font"> {{ drpOption.filter_item }}</span>
														</div>
													</div>
												</ng-container>

												<span class="" ngbTooltip="{{ drpOption.value }}" placement="left">{{
													drpOption.value
												}}</span>
											</a>
										</ng-container>
									</div>
								</div>
							</div>
						</ng-scrollbar>
					</div>
				</div>
			</div>
		</div>
		<div class="main-sec">
			<div class="action-bar">
				<div class="inner-section">
					<div class="left-section">
						<div class="subheader">
							<div class="toggale-submenu">
								<button
									class="submenu-toggle btn btn-icon btn-sm"
									id="btnSubmenu"
									name="btnSubmenu"
									(click)="submenuToggaleClick()"
								>
									<div *ngIf="submenu" placement="left" class="lg-font">
										<i class="fa-light fa-angle-left"></i>
									</div>
									<div *ngIf="!submenu" placement="right" class="lg-font">
										<i class="fa-light fa-angle-right"></i>
									</div>
								</button>
							</div>
						</div>
						<div class="action-search">
							<!-- <app-search></app-search> -->
							<app-search
								[columnOptions]="columnOptions"
								(getfilterData)="getFilterSearch($event)"
								[filterDetails]="getOrganizations"
								[savedData]="savedData"
								[Options]="dropdownOptions"
								[module]="module"
								(genericFilter)="genericSearchFilter($event)"
								[showDataTable]="showDataTable"
								[conditionOptions]="conditionOptions"
								[showRightsection]="showRightsection"
							></app-search>
						</div>
						<div class="form-group mb-0 custom-range-select-group">
							<div class="input-group custom-input-group2">
								<div class="input-group-prepend">
									<span class="input-group-text"><i class="fa-light fa-calendar"></i></span>
								</div>
								<div id="dtRange" name="datePicker" style="min-width: 240px">
									<date-range-picker
										(select)="onRangeSelect($event)"
										[selectedDuration]="timeFilterObj"
									></date-range-picker>
								</div>
							</div>
						</div>
					</div>
					<div class="right-section">
						<div class="pull-right align-items-center d-flex p-0 m-0">
							<!-- <ng-container>
								<div ngbDropdown>
									<button
										id="btnAddItem"
										*ngIf="permissions?.add && !isPartner"
										class="btn btn-primary"
										rippleEffect
										ngbDropdownToggle
										tooltipClass="fadeInAnimation"
										placement="left"
									>
										<i class="fa-light fa-plus"></i>
										<span class="d-none d-sm-inline-block">
											{{ "UI.k_add" | translate }}
										</span>
									</button>

									<div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
										<a
											ngbDropdownItem
											href="javascript:void(0)"
											(click)="toggleSidebar('add-organization', 'direct')"
										>
											{{ "UI.k_organization" | translate }}
										</a>
										<a
											ngbDropdownItem
											href="javascript:void(0)"
											(click)="toggleSidebar('add-organization', 'partner')"
										>
											{{ "UI.k_partner_organization" | translate }}
										</a>
									</div>
								</div>
							</ng-container> -->
							<ng-container>
								<div>
									<button
										id="btnAddItem"
										*ngIf="permissions?.add"
										class="btn btn-primary"
										rippleEffect
										tooltipClass="fadeInAnimation"
										placement="left"
										(click)="toggleSidebar('add-organization', 'direct')"
									>
										<i class="fa-light fa-plus"></i>
										<span class="d-none d-sm-inline-block">
											{{ "Message.msg_add_organization" | translate }}
										</span>
									</button>
								</div>
							</ng-container>
							<!--*ngIf="pendingInvitations > 0"-->
						</div>
					</div>
				</div>
			</div>
			<div class="action-section">
				<div class="table-view">
					<ng-container>
						<dns-server-grid
							[rows]="organizations"
							[columns]="columns"
							[count]="orgCount"
							(sort)="onSort($event)"
							[isEditable]="true"
							[permissions]="permissions"
							class="bootstrap core-bootstrap"
							[scrollbarH]="true"
							[virtualization]="false"
							(page)="setPage($event)"
							(setlimit)="setLimit($event)"
							[limit]="limit"
							[options]="options"
							[showData]="showData"
							[filterData]="filterData"
							[offset]="pageNumber"
						>
						</dns-server-grid>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="add-organization"
	overlayClass="modal-backdrop"
>
	<app-organization-add
		*ngIf="showOrgAdd"
		(onHideEvent)="onHideEvent($event)"
		[action]="currentEvent"
		[organization_id]="organization_id"
		[currentEvent]="currentEvent"
		[callType]="callType"
	></app-organization-add>
</core-sidebar>

<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="create-partner" overlayClass="modal-backdrop">
	<app-create-partner
		[action]="'view'"
		[partnerid]="partner_id"
		(callback)="getPartnerList($event)"
		*ngIf="showPartner"
		(onHideProfile)="onHidePartner($event)"
	></app-create-partner>
</core-sidebar>
