<div class="status-panel">
	<div class="panel-container" id="divPanel" name="divPanel">
		<div class="panel-data card border" [ngStyle]="{ 'background-color': widgetData?.bgcolor }">
			<div class="pl-1" [ngStyle]="{ color: widgetData?.bgcolor ? '#fff' : '' }">
				<span class="actual-value cursor-pointer" (click)="showDataSidebar(widgetData)">{{
					widgetData?.total_up
				}}</span>
				<span class="total-value"> / {{ widgetData?.total }}</span>
			</div>
		</div>
		<div class="title-style pl-25">
			<span class="title">{{ widgetData?.text_title }}</span>
			<span class="sub-title" *ngIf="widgetData?.panel_sub_title">{{ widgetData?.panel_sub_title }}</span>
		</div>
	</div>
</div>
