<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
	<!-- Navbar brand -->
	<ul class="nav navbar-nav flex-row">
		<li class="nav-item">
			<span class="navbar-brand">
				<span class="brand-logo">
					<img src="{{ logo }}" alt="brand-logo" height="56" />
				</span>
				<h2 class="brand-text mb-0">{{ coreConfig.app.appName | translate }}</h2>
			</span>
		</li>
	</ul>
	<!--/ Navbar brand -->
</div>
<!-- <a
	class="navbar-brand"
	[routerLink]="['/']"
	[ngClass]="
		coreConfig.layout.menu.logoCustomBackgroundColor === true ? coreConfig.layout.menu.logoBackgroundColor : ''
	"
	[ngStyle]="{
		'background-color': coreConfig.layout.menu.logoBackgroundColor.includes('#')
			? coreConfig.layout.menu.logoBackgroundColor
			: null
	}"
>
	<span class="brand-logo" *ngIf="(icon == 'null' || icon == 'undefined' || !icon)">
		<img src="../../../../../assets/images/infraon-icon.svg" alt="brand-logo" height="24" />
	</span>

	<span class="brand-logo" *ngIf="icon != 'null' && icon !== 'undefined' && icon">
		<img src="{{ icon }}" alt="brand-logo" height="24" />
	</span>
	</a> -->

<div [ngClass]="isSelfServicePortal ? 'self-service-nav-container' : 'w-100 d-flex'">
	<ng-container *ngIf="!isSelfServicePortal">
		<span
			class="navbar-brand"
			[ngClass]="
				coreConfig?.layout?.navbar?.backgroundColor === true
					? coreConfig?.layout?.navbar?.backgroundColor
					: ''
			"
			[ngStyle]="{
				'background-color': coreConfig?.layout?.navbar?.backgroundColor?.includes('#')
					? coreConfig?.layout?.navbar?.backgroundColor
					: null
			}"
		>
			<span class="brand-logo">
				<img src="../../../../../assets/images/infraon-icon.svg" alt="brand-logo" height="24" />
			</span>
		</span>
	</ng-container> 
	<ng-container *ngIf="isSelfServicePortal">
		<span
			class="navbar-brand self-service-navbar-brand mr-0"
			[ngClass]="
				coreConfig.layout.menu.logoCustomBackgroundColor === true
					? coreConfig?.layout?.menu?.logoBackgroundColor
					: ''
			"
			[ngStyle]="{
				'background-color': coreConfig?.layout?.menu?.logoBackgroundColor?.includes('#')
					? coreConfig?.layout?.menu?.logoBackgroundColor
					: null
			}"
		>
			<span class="brand-logo" *ngIf="logo == 'null' || logo == 'undefined' || !logo">
				<img src="{{ coreConfig.app.appIconImage }}" alt="brand-logo" height="50" />
				<!-- style="margin-left: -3.5rem"
						  width="150px"
						  height="50px" -->
			</span>
			<span class="brand-logo" *ngIf="logo != 'null' && logo !== 'undefined' && logo">
				<img src="{{ logo }}" alt="brand-logo" height="42" />
			</span>
		</span>
	</ng-container>
	<ng-container *ngIf="isSelfServicePortal">
		<button
			class="navbar-toggler"
			type="button"
			data-toggle="collapse"
			data-target="#navbarSupportedContent"
			aria-controls="navbarSupportedContent"
			aria-expanded="false"
			aria-label="Toggle navigation"
			(click)="toggleSsResponsiveNav()"
			[class.border-primary]="ssResNavOpen"
		>
			<span class="navbar-toggler-icon"></span>
		</button>
		<nav class="navbar navbar-expand-lg pull-right right-nav self-service-nav w-100">
			<div class="collapse navbar-collapse" id="navbarSupportedContent" [class.show]="ssResNavOpen">
				<div class="container px-0">
					<ul class="navbar-nav mr-auto w-100 ssp-menu">
						<ng-container *ngFor="let item of sspNavMenu">
							<li *ngIf="item.is_enabled" 
								[ngClass]="{
									'nav-item': true, 
									'dropdown': item.menu_type === 'dropdown',
									'active': item.menu_type === 'dropdown' && activeModuleKey === item.module_key
								}" 
								ngbDropdown 
								[routerLink]="item.menu_type === 'regular' ? [item.route] : null" 
								routerLinkActive="active" 
								[routerLinkActiveOptions]="{ exact: true }" 
								(click)="item.menu_type === 'regular' ? toggleSsResponsiveNav() : null" 
								[attr.data-testid]="'btn' + item.module_key">
								<a *ngIf="item.menu_type === 'regular'" class="nav-link text-nowrap">{{ item.module_name | translate }}</a>
								<a *ngIf="item.menu_type === 'dropdown'" 
								   ngbDropdownToggle 
								   class="nav-link dropdown-toggle" 
								   [id]="item.module_key + 'Dropdown'" 
								   role="button">
									{{ item.module_name | translate }}
								</a>
								<div *ngIf="item.menu_type === 'dropdown'" 
									 ngbDropdownMenu 
									 [attr.aria-labelledby]="item.module_key + 'Dropdown'">
									<a *ngFor="let subItem of item.data" 
									   (click)="selectItem(subItem, item.route, item.module_key)" 
									   [class.active]="subItem === activeDataItem" 
									   class="dropdown-item">
									   {{ subItem.name | translate }}
									</a>
								</div>
							</li>
						</ng-container>
					</ul>
				</div>
			</div>
		</nav>
	</ng-container>

	<div class="navbar-container d-flex content">
		<div class="bookmark-wrapper d-flex align-items-center">
			<!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
			<ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
				<li class="nav-item">
					<a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
						<!--<span [data-feather]="'menu'" [class]="'ficon'"></span>-->
						<i class="fal fa-bars ficon mt-25"></i>
					</a>
				</li>
			</ul>
			<!--/ Menu Toggler -->
			<ul class="nav navbar-nav left-nav" *ngIf="currentscreen === 'fluent'">
				<!-- <li class="header-menu-item">
            <button class="menulink-navmenu btn " (click)="productToggale()">
            <span [data-feather]="'align-justify'" ></span>
            </button>
          </li> -->
				<li
					class="head-text"
					[ngClass]="
						coreConfig.layout.menu.logoCustomBackgroundColor === true
							? coreConfig.layout.menu.logoBackgroundColor
							: ''
					"
					[ngStyle]="{
						'background-color': coreConfig?.layout?.menu?.logoBackgroundColor.includes('#')
							? coreConfig?.layout?.menu?.logoBackgroundColor
							: null
					}"
				>
					<img src="assets/images/infraon_logoDNS.svg" width="auto" height="28" alt="Infraon" />
				</li>
				<!-- src="../../../../assets/images/infraon_logoDNS_white.svg" -->
				<!-- <app-navbar-search class=""></app-navbar-search> -->
			</ul>

			<!-- Toggle skin -->

			<ul class="nav navbar-nav" (mouseover)="isDisplay = true" (mouseleave)="isDisplay = false">
				<ng-container *ngIf="!isSelfServicePortal">
					<li class="nav-item">
						<!-- app-breadcrumb component -->
						<a
							*ngIf="coreConfig?.layout?.navbar?.breadcrumb"
							class="align-items-center my-auto"
							id="breadCrumb"
						>
							<app-breadcrumb [breadcrumb]="coreConfig?.layout?.navbar?.breadcrumb"></app-breadcrumb>
						</a>
						<!-- <div> -->
						<span class="nav-link nav-link-style px-0 cursor-none">
							<div class="d-flex align-items-center">
								<!--<span-->
								<!--*ngIf="coreConfig.layout.navbar.pageIcon"-->
								<!--innerHTML="<h5><i class='fa-light {{-->
								<!--coreConfig.layout.navbar.pageIcon-->
								<!--}}  fa-lg mt-25'></i></h5>"-->
								<!--&gt;-->
								<!--&lt;!&ndash;<i *ngIf="!coreConfig.layout.navbar.pageIcon.includes('fa-')" &ndash;&gt;-->
								<!--&lt;!&ndash;[data-feather]="coreConfig.layout.navbar.pageIcon" &ndash;&gt;-->
								<!--&lt;!&ndash;class="font-medium-3 ficon"></i>&ndash;&gt;-->
								<!--&lt;!&ndash;*ngIf="coreConfig.layout.navbar.pageIcon.includes('fa-')" &ndash;&gt;-->
								<!--&lt;!&ndash; <i-->
								<!--class="fa-light {{-->
								<!--coreConfig.layout.navbar.pageIcon-->
								<!--}} fa-lg mt-25"-->
								<!--&gt;</i> &ndash;&gt;-->
								<!--</span>-->
								<!-- <span *ngIf="coreConfig.layout.navbar.pageIcon" class="">
                    <i [data-feather]="'bookmark'" class="font-medium-4"></i>
                  </span> -->
								<!-- <div class="bg-darken-2"> -->
								<h5 class="mb-0 font-weight-bolder text-break mr-50 page-title">
									{{ coreConfig.layout.navbar.pageTitle | translate }}
								</h5>
								<!-- <span class="badge badge-pill badge-primary badge-up">
                      <i [data-feather]="'bookmark'" class="font-medium-4"></i>
                    </span> -->
								<!-- </div> -->
								<ng-container *ngIf="this.coreConfig.layout.navbar?.isAllowEdit">
									<div class="mr-50 d-flex">
										<a
											id="btnWorkflowEdit"
											name="inciEdit"
											tooltipClass="fadeInAnimation"
											ngbTooltip="{{ 'UI.k_edit' | translate }}"
											placement="left"
											(click)="this.toggleSidebarEvent('add-workflow')"
											class="pull-right"
										>
											<i class="fa-light fa-pen-to-square reg-font primary-color"></i>
										</a>
									</div>
								</ng-container>

								<ng-container *ngIf="!isBookmarked">
									<div class="nav-item bookmark-btn" *ngIf="isDisplay || isBookmarked">
										<a
											id="linkBookmark"
											name="linkBookmark"
											class="justify-content-center"
											placement="bottom"
											ngbTooltip="{{ 'UI.k_bookmark' | translate }}"
											(click)="bookMark(coreConfig.layout.navbar.pageTitle)"
										>
											<i class="fa-light fa-bookmark"></i>
										</a>
									</div>
								</ng-container>
								<ng-container *ngIf="isBookmarked">
									<div class="nav-item bookmark-btn" *ngIf="isDisplay">
										<a
											id="linkBookmark"
											name="linkBookmark"
											class="justify-content-center"
											placement="bottom"
											ngbTooltip="{{ 'UI.k_bookmark' | translate }}"
											(click)="bookMark(coreConfig.layout.navbar.pageTitle)"
										>
											<!--&nbsp;&nbsp;<i [data-feather]="'bookmark'" class="text-primary" [size]="22"></i>-->
											<i class="fa-solid fa-bookmark"></i>
										</a>
									</div>
								</ng-container>
								<!-- <ng-container *ngIf="coreConfig.layout.navbar.pageTitle === 'Messenger'">
									<ul class="nav-tabs nav messanger-tabs-button mb-0 pl-1">
										<li (click)="messengerTabEvent('outgoing')">
											<a class="nav-link" [ngClass]="currentMsgTab === 'outgoing' ? 'active' : ''"
												>Outgoing</a
											>
										</li>
										<li (click)="messengerTabEvent('incoming')">
											<a class="nav-link" [ngClass]="currentMsgTab === 'incoming' ? 'active' : ''"
												>Incoming</a
											>
										</li>
									</ul>
								</ng-container> -->
								<!-- <div
                  *ngIf="coreConfig.layout.navbar.loadHTML"
                  class="ml-2 d-none d-lg-block"
                >
                  <apx-chart
                    [chart]="goalChartoptions.chart"
                    [colors]="goalChartoptions.colors"
                    [plotOptions]="goalChartoptions.plotOptions"
                    [fill]="goalChartoptions.fill"
                    [series]="coreConfig.layout.navbar.configProgress.completed_per"
                    [stroke]="goalChartoptions.stroke"
                    [grid]="goalChartoptions.grid"
                    [labels]="coreConfig.layout.navbar.configProgress.label"
                  ></apx-chart>
                </div> -->
							</div>
						</span>
						<!-- </div> -->
						<!-- {{ coreConfig.layout.test.subtitle }} -->
					</li>
				</ng-container>
			</ul>

			<!--/ Toggle skin -->

			<!-- Search -->
			<!-- <app-navbar-search></app-navbar-search> -->
			<!--/ Search -->
		</div>

		<ng-container *ngIf="!coreConfig.layout.adminPortal">
			<div
				class="nav-item d-flex align-items-center"
				*ngIf="coreConfig.layout.navbar.showCalendar.showCalendarDate"
				style="margin-left: 15%"
			>
				<span class="d-none d-sm-inline-block font-small-4"
					><b>< {{ startDate }} To {{ endDate }} ></b></span
				>
				<!--<app-navbar-search></app-navbar-search>-->
			</div>
		</ng-container>

		<!-- <ul class="nav navbar-nav align-items-center ml-auto"> -->

		<!--/ Language selection -->
		<!-- ? Language selection | Uncomment if needed
          <li ngbDropdown class="nav-item dropdown dropdown-language">
              <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
                  <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i>
          <span class="selected-language">{{ languageOptions[_translateService.currentLang].title }}</span>
        </a>
        <div ngbDropdownMenu aria-labelledby="dropdown-flag">
          <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem (click)="setLanguage(lang)">
            <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i> {{ languageOptions[lang].title }}
          </a>
        </div>
      </li>
      -->
		<ul class="nav navbar-nav align-items-center pull-right ml-auto">
			<ng-container *ngIf="currentscreen !== 'fluent'">
				<ng-container *ngIf="!coreConfig.layout.adminPortal">
					<li class="nav-item d-flex align-items-center" *ngIf="coreConfig.layout.navbar.showSearch">
						<!-- <app-navbar-search
              [popup]="config.popup"
              (onSearchClick)="config.callback($event)"
            ></app-navbar-search> -->

						<!-- <a
							*ngFor="let config of coreConfig.layout.navbar.search"
							class="search-input-icon"
							placement="bottom"
							ngbTooltip="{{ 'UI.k_search' | translate }}"
							id="search"
							name="search"
						>
							<i
								class="fa-light fa-magnifying-glass mt-25 mr-75 font-medium-3"
								(click)="openSearch()"
							></i>
						</a> -->
						<!--<app-navbar-search></app-navbar-search>-->
					</li>
				</ng-container>
			</ng-container>
			<!--Marketplace Shortcut-->
			<!-- <ng-container *ngIf="!coreConfig.layout.adminPortal && !isSelfServicePortal">
				<li class="nav-item d-none d-lg-block">
					<a
						type="button"
						placement="bottom"
						ngbTooltip="{{ 'UI.k_marketplace' | translate }}"
						class="nav-link nav-link-style btn"
						(click)="goToMarketplace()"
						id="marketplaceLink"
						name="marketplaceLink"
					>
						<i class="fa-light fa-store m-0 font-medium-3"></i>
					</a>
				</li>
			</ng-container> -->
			<!--Marketplace Shortcut-->
			<!-- Toggle skin -->
			<!-- <ng-container *ngIf="!coreConfig.layout.adminPortal && showDarkLightSwitch">
				<ng-container *ngIf="!isSelfServicePortal">
					<li class="nav-item d-none d-lg-block">
						<a
							type="button"
							placement="bottom"
							ngbTooltip="{{ 'UI.k_dark_light_mode' | translate }}"
							class="nav-link nav-link-style btn"
							(click)="toggleDarkSkin()"
							id="linkToglMode"
							name="linkToglMode"
						>
							<span *ngIf="currentscreen !== 'fluent'">
								<span *ngIf="themeColor === 'dark'"
									><i class="font-medium-5 fa-light fa-sun-bright"></i
								></span>
								<span *ngIf="themeColor != 'dark'"><i class="font-medium-5 fa-light fa-moon"></i></span>
							</span>
							<span *ngIf="currentscreen === 'fluent'">
								<span *ngIf="themeColor === 'dark'"
									><i class="font-medium-5 fa-light fa-sun-bright" style="color: #fff"></i
								></span>
								<span *ngIf="themeColor != 'dark'"
									><i class="font-medium-5 fa-light fa-moon" style="color: #fff"></i
								></span>
							</span>
						</a>
					</li>
				</ng-container>
			</ng-container> -->

			<!-- <li
          class="nav-item d-flex align-items-center"
          *ngFor="let config of buttonsRight"
        >
          <a
            class="nav-link nav-link-style"
            *ngIf="config.type == 'icon'"
            tooltipClass="fadeInAnimation"
            [ngbTooltip]="config.tooltip"
            (click)="config.callback(config.attrs)"
          >
            <i [data-feather]="config.icon" class="font-medium-1"></i>
          </a>
          <button
            *ngIf="config.type == 'button'"
            id="btn{{ config.id }}"
            class="btn ml-1"
            [ngClass]="config.class ? config.class : 'btn-sm btn-warning'"
            rippleEffect
            (click)="config.callback(config.attrs)"
            tooltipClass="fadeInAnimation"
            [ngbTooltip]="config.tooltip"
          >
            <i [data-feather]="config.icon" class="font-small-3"></i
            ><span class="d-none d-sm-inline-block font-small-3">
              &nbsp;{{ config.label }}</span
            >
          </button>
          <ul
            ngbDropdown
            [placement]="'bottom-left'"
            *ngIf="config.type == 'dropdown'"
          >
            <button
              ngbDropdownToggle
              id="btn{{ config.id }}"
              class="btn ml-1"
              [ngClass]="config.class ? config.class : 'btn-sm btn-warning'"
              type="button"
              id="dropdownMenuButton"
              rippleEffect
            >
              {{ config.label }}
            </button>
            <div
              aria-labelledby="dropdownMenuButton"
              class="dropdown-menu dropdown-menu-right"
            >
              <a
                *ngFor="let option of config.options"
                id="btn{{ option.id }}"
                ngbDropdownItem
                href="javascript:void(0)"
                (click)="option.callback(config.attrs)"
                ><span [class]="'mr-50'"></span>{{ option.name }}</a
              >
            </div>
          </ul>

          <button
            *ngIf="config.type == 'popover'"
            class="btn ml-1"
            [ngClass]="config.class ? config.class : 'btn-sm btn-warning'"
            rippleEffect
            tooltipClass="fadeInAnimation"
            [ngbTooltip]="config.tooltip"
            [ngbPopover]="popContent" popoverClass="fadeInAnimation" container="body"
          >
            <i [data-feather]="config.icon" class="font-small-3"></i
            ><span class="d-none d-sm-inline-block font-small-3">
              &nbsp;{{ config.label }}</span
            >
          </button>
          <ng-template #popContent>
            <div class="modal-body popover-width" tabindex="0" ngbAutofocus>
              <div class="row">
              <div class="form-group col-xs-4 col-md-4 col-xl-4 col-lg-4" *ngFor="let action of config.actions">

                  <a

                    class="mr-10"
                    (click)="action.listener(action.label)"
                  >
                    <span
                      tooltipClass="fadeInAnimation"
                      placement="top"
                      [ngbTooltip]="action.label"
                    >
                      <i
                        [data-feather]="action.icon"
                        [class]="action.class"
                        [size]="18"
                      ></i>
                    </span>
                    <label>{{action.label}}</label>
                  </a>
                </div>
              </div>
                </div>
          </ng-template>
          

        </li> -->
			<!-- Getting Started Steps -->
			<ng-container
				*ngIf="
					gettingStartedStep &&
					!isSelfServicePortal &&
					!coreConfig.layout.adminPortal &&
					!coreConfig.layout.supportPortal &&
					!coreConfig.layout.mspPortal
				"
			>
				<li
					class="nav-item d-none d-lg-block getting-started-item pl-50 pr-25"
					*ngIf="showRemainDays || showGettingStarted"
				>
					<div class="getting-started-sec">
						<div class="remian-day-sec" *ngIf="showRemainDays">
							<ng-container *ngIf="rem_days">
								<div class="day-left card-bg primary-border">
									<span class="primary-color fw-600">{{ rem_days }}</span>
								</div>
								<span class="xs-font fw-600 title-color">{{ "UI.k_days_left" | translate }}</span>
							</ng-container>
							<span class="buy-product">
								<a
									class="primary-color xs-font fw-600"
									href="javascript:void(0)"
									(click)="goToSubscription($event)"
									>{{ "UI.k_buy_now" | translate }}</a
								>
							</span>
						</div>
						<div
							class="getting-stared-step cursor-pointer"
							*ngIf="showGettingStarted"
							(click)="toggleSidebar('getting-stared')"
						>
							<div class="step-info">
								<div class="fw-600 title-color">
									<span class="completed-step fw-600">{{ completedStep.length }}</span
									>/<span class="total-step">{{ totalGettingStep.length }}</span>
								</div>
								<span class="title-color">{{ "UI.k_completed" | translate }}</span>
							</div>
							<div class="steps">
								<div class="total-step">
									<ng-container *ngFor="let item of totalGettingStep; let i = index">
										<span class="step step-{{ i }}"></span>
									</ng-container>
								</div>
								<div class="completed-step">
									<ng-container *ngFor="let Cstep of completedStep; let j = index">
										<span class="done done-{{ j }}"></span>
									</ng-container>
								</div>
							</div>
						</div>
					</div>
				</li>
			</ng-container>
			<!-- End of Getting Started Steps -->
			<!-- Demo Video -->
			<ng-container
				*ngIf="
					showVideo &&
					!isSelfServicePortal &&
					!coreConfig.layout.adminPortal &&
					!coreConfig.layout.supportPortal &&
					!coreConfig.layout.mspPortal
				"
			>
				<li class="nav-item d-none d-lg-block" [ngClass]="isAnimated ? 'px-50' : ''">
					<a
						type="button"
						class="demo-video nav-link-style btn fw-600"
						[ngClass]="isAnimated ? 'show-animation' : ''"
						(click)="modalDemoVideo(demoVideo)"
						placement="bottom"
						ngbTooltip="{{ 'UI.k_demo' | translate }} "
						id="linkDemoVideo"
						name="linkDemoVideo"
						[attr.data-testid]="'linkDemoVideo'"
					>
						<span> <i class="fa-light fa-play mr-50"></i>{{ "UI.k_demo" | translate }} </span>
					</a>
				</li>
			</ng-container>
			<!-- End of Demo Video -->
			<!-- Help Icon Integration Start-->
			<ng-container *ngIf="!coreConfig.layout.adminPortal">
				<li class="nav-item d-none d-lg-block" *ngIf="moduleData">
					<a
						type="button"
						class="nav-link nav-link-style btn fw-600"
						(click)="toggleHelp('help-menu')"
						placement="bottom"
						ngbTooltip="{{ 'UI.k_help' | translate }} "
						id="help"
						name="help"
						[attr.data-testid]="'linkHelp'"
					>
						<span> <i class="fa-light fa-circle-question mr-50"></i>{{ "UI.k_help" | translate }} </span>
					</a>
				</li>
			</ng-container>

			<!-- Help Icon Integration Start-->
			<!-- User Dropdown -->
			<ng-container *ngIf="isSelfServicePortal && (incident_permission?.add || request_permission?.add)">
				<li
					class="nav-item mx-50 ss-ticket-btn-desktop-wrapper"
					ngbTooltip="{{ 'UI.k_submit_a_ticket' | translate }}"
					placement="bottom"
				>
					<button
						type="button"
						class="btn btn-outline-tertiary ss-ticket-btn-desktop"
						(click)="toggleTicketSidebar()"
						rippleEffect
						[attr.data-testid]="'btn_SubmitTicket'"
					>
						<span class="icon">
							<i class="fa-light fa-ticket"></i>
						</span>
						<span class="text">
							{{ "UI.k_submit_a_ticket" | translate }}
						</span>
					</button>
				</li>
			</ng-container>
			<!-- Toggle skin -->
			<ng-container *ngIf="!coreConfig.layout.adminPortal && showDarkLightSwitch">
				<ng-container *ngIf="!isSelfServicePortal">
					<li class="nav-item d-none d-lg-block">
						<a
							type="button"
							placement="bottom"
							ngbTooltip="{{ 'UI.k_dark_light_mode' | translate }}"
							class="nav-link nav-link-style"
							(click)="toggleDarkSkin()"
							id="linkToglMode"
							name="linkToglMode"
						>
							<span *ngIf="currentscreen !== 'fluent'">
								<!--<i [ngClass]="currentSkin === 'dark' ? 'fa-sun-bright' : 'fa-moon'" class="font-medium-5 fa-light" ></i>-->
								<span *ngIf="themeColor === 'dark'"
									><i class="xl-font fw-300 fa-light fa-sun-bright"></i
								></span>
								<span *ngIf="themeColor != 'dark'"
									><i class="xl-font fw-300 fa-light fa-moon"></i
								></span>
							</span>
							<span *ngIf="currentscreen === 'fluent'">
								<!--<i [ngClass]="currentSkin === 'dark' ? 'fa-sun-bright' : 'fa-moon'" class="font-medium-5 fa-light" ></i>-->
								<span *ngIf="themeColor === 'dark'"
									><i class="xl-font fw-300 fa-light fa-sun-bright" style="color: #fff"></i
								></span>
								<span *ngIf="themeColor != 'dark'"
									><i class="xl-font fw-300 fa-light fa-moon" style="color: #fff"></i
								></span>
							</span>
						</a>
					</li>
				</ng-container>
			</ng-container>

			<!-- Notification panel -->
			<ng-container *ngIf="!isSelfServicePortal">
				<li class="nav-item notification d-none d-lg-block">
					<a
						type="button"
						placement="bottom"
						ngbTooltip="{{ 'UI.k_notifications' | translate }}"
						class="nav-link nav-link-style"
						href="javascript:void(0)"
						(click)="showNotificationSidebar('notification-panel')"
						id="linkToglMode"
						name="linkToglMode"
					>
						<span><i class="notification-icon fal fa-bell"></i></span>
						<span class="notification-count" *ngIf="notificationCount > 0">{{ notificationCount }}</span>
					</a>
				</li>
			</ng-container>
			<!-- Notification panel -->

			<!--/ Toggle skin -->
			<li class="nav-item dropdown-user">
				<a
					class="nav-link dropdown-toggle dropdown-user-link pr-0"
					id="linkUserDropdown"
					name="linkUserDropdown"
					aria-haspopup="true"
					aria-expanded="false"
					(click)="openModalAD()"
					[attr.data-testid]="'linkUserDropdown'"
				>
					<ng-container *ngIf="this.currentUser">
						<ng-container *ngIf="!isSelfServicePortal">
							<div class="user-nav d-sm-flex d-none">
								<span class="sm-font fw-500 title-color"
									>{{ this.currentUser.first_name | slice: 0:18
									}}{{ this.currentUser.first_name.length > 18 ? "..." : "" }}
									{{ this.currentUser.last_name | slice: 0:18
									}}{{ this.currentUser.last_name.length > 18 ? "..." : "" }}</span
								>
								<span class="user-status">{{ this.currentUser.role_name }}</span>
							</div>
						</ng-container>
						<span class="avatar">
							<img
								*ngIf="currentUser.profile_image"
								class="round"
								width="32"
								height="32"
								[src]="currentUser.profile_image"
								alt="avatar" />
							<div
								*ngIf="!currentUser.profile_image"
								class="avatar"
								[ngStyle]="{
									'background-color': currentUser.profile.avatar_color
										? currentUser.profile.avatar_color
										: '#a5c1d4'
								}"
							>
								<span class="avatar-content"
									>{{
										this.currentUser.first_name + " " + this.currentUser.last_name
											| initials
											| slice: O:2
									}}
								</span>
							</div>
							<span> </span>
							<span class="avatar-status-online"></span>
							<span class="avatar-status-online"></span
						></span>
					</ng-container>
				</a>
			</li>
			<!--/ User Dropdown -->
		</ul>
		<!-- </ul> -->
	</div>
</div>

<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="help-menu" *ngIf="moduleData">
	<app-dns-help *ngIf="moduleData" [data]="moduleData"></app-dns-help>
</core-sidebar>

<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade submit-ticket-sidebar-modal"
	name="submit-ticket-sidebar"
	overlayClass="modal-backdrop"
>
	<app-submit-ticket-sidebar
		*ngIf="showTicketSidebar"
		[asset_id]="asset_id"
		(hideShowDetails)="toggleTicketSidebar()"
	></app-submit-ticket-sidebar>
</core-sidebar>

<!-- Demo Video Modal -->
<ng-template #demoVideo let-modal>
	<div class="modal-body demo-video-modal" tabindex="0" ngbAutofocus>
		<button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
			<i class="fa-light fa-xmark"></i>
		</button>
		<video
		controls
		autoplay
		muted
		disablePictureInPicture
		controlslist="nodownload noplaybackrate"
		[src]="currentFeatureVideo"
		type="video/mp4"
	></video>
	</div>
</ng-template>
<!-- End of Demo Video Modal -->

<!-- Getting Started Modal -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="getting-stared" overlayClass="modal-backdrop">
	<app-getting-started-sidebar *ngIf="showGettingStarted"></app-getting-started-sidebar>
</core-sidebar>
<!-- End of Getting Started Modal -->

<!-- Notification Model -->

<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="notification-panel">
	<app-notification
		*ngIf="showNotification"
		[feature]="isNewFeatureSidebar"
		(notificationCount)="notificationCountData($event)"
		(hideSidebar)="hideNotificationSidebar($event)"
	></app-notification>
</core-sidebar>
