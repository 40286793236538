import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { CoreSidebarService } from "../../../../../@core/components/core-sidebar/core-sidebar.service";
import { TranslateService } from "@ngx-translate/core";
import { WorkflowService } from "app/base/workflow/services/workflow.service";
import { customSearch } from "app/common/utils/utils";
import { RequesterService } from "app/base/requester/services/requester.service";
import { catchError, map, throwError } from "rxjs";
import { InfinityModuleIDs } from "../../types/module_type";
import { WF_RESTRICTED_REPORTING_MANAGER_MODULES } from "../../types";

@Component({
	selector: "app-approval-config",
	templateUrl: "./approval-config.component.html",
	styleUrls: ["./approval-config.component.scss"],
})
export class ApprovalConfigComponent implements OnInit {
	@Input("process_data") process_data: any;
	@Input("approval_status") approval_status: any;
	@Output("onApprovalConfigSubmit") onApprovalConfigSubmit: EventEmitter<any> = new EventEmitter<any>();
	@Output("onApprovalConfigClose") onApprovalConfigClose: EventEmitter<any> = new EventEmitter<any>();
	@Input() options: any = {};
	@Input() module_id: any;
	priority_change_data: any = {};
	@Input() sidebarName = "approval-config-config";
	@Input() selected_teams: any = [];
	apprChnDisabled = false;
	team_list: any = [];
	reporting_manager_list: any = [];
	is_send_disabled = false;
	requester: any;
	actual_reporting_mgr: any;
	readonly RESTRICTED_REPORTING_MANAGER_MODULES = WF_RESTRICTED_REPORTING_MANAGER_MODULES;
	is_cab_approval = false;

	constructor(
		private _configSidebarService: CoreSidebarService,
		private _translateService: TranslateService,
		private _workflowService: WorkflowService,
		private _requesterService: RequesterService
	) {}

	ngOnInit(): void {
		this.process_data["approval_config"] = {
			is_reporting_manager: false,
		};
		let payload = {
			flow_id: this.approval_status?.guid,
			workflow_id: this.approval_status?.workflow_id,
			requester_id: this.process_data?.requester_id,
		};
		this._workflowService
			.getApprovalOptionsByModuleId(this.module_id, payload)
			.subscribe((res) => this.initializeOptions(res));
	}

	initializeOptions = (res) => {
		this.team_list = res?.team_list;
		this.process_data.approval_config["team"] = res?.approval_data?.team;
		this.is_cab_approval = res?.approval_data?.is_cab;
		if (this.selected_teams && this.selected_teams !== undefined && this.selected_teams?.length > 0) {
			this.process_data.approval_config.team = null;
			this.process_data.approval_config.team = this.selected_teams[0];
		}
		this.process_data.approval_config["is_reporting_manager"] = res?.approval_data?.is_reporting_manager;
		if (res?.approval_data?.is_reporting_manager) {
			this.is_send_disabled = true;
		}

		this.reporting_manager_list = res?.reporting_manager_list;
		if (this.process_data?.requester_id && this.process_data?.requester_id != undefined) {
			this.getRequester(this.process_data?.requester_id);
		}
	};

	onReportingEnable = ($event) => {
		if (this.process_data.approval_config.is_reporting_manager) {
			this.process_data.approval_config.team = null;
		} else {
			this.process_data.approval_config.reporting_manager = null;
		}
		this.getRequester(this.process_data?.requester_id);
	};

	onSubmit = (data) => {
		if (data.valid) {
			if (
				this.process_data.approval_config.is_reporting_manager &&
				this.actual_reporting_mgr != this.process_data.approval_config.reporting_manager
			) {
				this.saveRequesterProfile(this.requester);
			}
			this.onApprovalConfigSubmit.emit(this.process_data.approval_config);
		}
	};

	toggleSidebar = (dataForm: NgForm, type: any = "close") => {
		this.onApprovalConfigClose.emit(type);
		if (dataForm) {
			dataForm.resetForm();
		}
	};

	customSearchFn(term: string, item: any) {
		return customSearch(term, item, "full_name", "email");
	}

	stringToColor(string) {
		if (string && string != undefined) {
			let hash = 0;
			let i;

			/* eslint-disable no-bitwise */
			for (i = 0; i < string.length; i += 1) {
				hash = string.charCodeAt(i) + ((hash << 5) - hash);
			}

			let colour = "#";

			for (i = 0; i < 3; i += 1) {
				const value = (hash >> (i * 8)) & 0xff;
				colour += `00${value.toString(16)}`.substr(-2);
			}
			/* eslint-enable no-bitwise */

			return colour;
		} else {
			return "#2d2d2d";
		}
	}

	getRequester(requesterId: Number): void {
		this._requesterService
			.getRequesterProfile(requesterId)
			.pipe(
				map((response: any) => {
					if (response) {
						if (response["id"] !== null || response["id"] !== undefined) {
							this.requester = response;
							if (this.process_data?.approval_config.is_reporting_manager) {
								this.actual_reporting_mgr = this.requester.user_details.reporting_manager;
								this.process_data.approval_config.reporting_manager =
									this.requester.user_details.reporting_manager;
							}
						}
					}
					// Success
				}),
				catchError((error) => {
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}

	saveRequesterProfile = (userObj: any): void => {
		if (typeof userObj.type == "string") {
			userObj.type = parseInt(userObj.type);
		}

		this._requesterService
			.saveRequesterProfile(userObj)
			.pipe(
				map((response: any) => {
					if (response) {
						if (response["status"] == "success") {
							//success
						}
					}
					// Success
				}),
				catchError((error) => {
					return throwError(error.statusText);
				})
			)
			.subscribe();
	};

	onManagerChange($event) {
		this.requester["user_details"]["is_reporting_manager"] = true;
		this.requester["user_details"]["reporting_manager"] = this.process_data.approval_config.reporting_manager;
	}
}
