import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-radio-button-progressbar',
  templateUrl: './radio-button-progressbar.component.html',
  styleUrls: ['./radio-button-progressbar.component.scss']
})
export class RadioButtonProgressbarComponent implements OnInit {

  @Input() radioButtonRatingConfig: any;

  constructor() { }

  ngOnInit(): void {
    //console.log("radioButtonRatingConfig", this.radioButtonRatingConfig);
  }

}
