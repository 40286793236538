<div class="slideout-content default-slideout">
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
		<div *ngIf="keytoshortcut" class="modal-dialog sidebar-md">
			<form class="modal-content pt-0">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">
						{{ "UI.k_shortcuts" | translate }}
					</h5>
					<button
						id="btnkeyboardshortClose"
						name="close"
						type="button"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						(click)="toggleSidebarshortkey('shortcutkey')"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body flex-grow-1 pt-2">
					<ng-scrollbar class="custom-scrollbar section-scrollbar">
						<div class="px-1">
							<div class="input-group input-group-merge mb-2">
								<div class="input-group-prepend">
									<span class="input-group-text" id="basic-addon-search2">
										<i class="fa-light fa-magnifying-glass reg-font"></i
									></span>
								</div>
								<input
									type="text"
									class="form-control form-control-sm"
									placeholder="Search..."
									aria-label="Search..."
									[(ngModel)]="searchText"
									aria-describedby="basic-addon-search2"
									(ngModelChange)="filterPageModules(searchText)"
								/>
							</div>
						</div>

						<ng-container *ngFor="let item of selectedList; let i = index">
							<ng-container *appDnsLicenseHandler="{ module_key: item?.module_key, keys: item?.keys }">
								<ng-container *ngIf="item.sidebar">
									<div
										class="d-flex justify-content-between align-items-center profileset border-bottom py-50 cursor-pointer px-1"
										id="btnshortkey{{ i }}"
										name="btnshortkey{{ i }}"
										[routerLink]="[item.handle]"
										[routerLinkActive]="'active'"
										[queryParams]="{ globalSearch: true }"
										(click)="toggleclose()"
									>
										<span class="reg-font title-color link-name">{{ item.name }}</span>

										<div class="d-flex justify-content-start align-items-center key-btn">
											<ng-container *ngFor="let key of item.shortKeys; let i = index">
												<span class="keybtn mr-50 reg-font fw-500">{{ key }}</span>
											</ng-container>
										</div>
									</div>
								</ng-container>
								<ng-container *ngIf="!item.sidebar">
									<div
										class="d-flex justify-content-between align-items-center border-bottom py-50 px-1 profileset cursor-pointer"
										id="btnshortkey{{ i }}"
										name="btnshortkey{{ i }}"
										[routerLink]="[item.handle]"
										[routerLinkActive]="'active'"
										(click)="toggleclose()"
										cursor-pointer
									>
										<span class="reg-font title-color link-name">{{ item.name }}</span>
										<div class="d-flex justify-content-start align-items-center">
											<ng-container *ngFor="let key of item.shortKeys; let i = index">
												<span class="keybtn mr-50 reg-font fw-500">{{ key }}</span>
											</ng-container>
										</div>
									</div>
								</ng-container>
							</ng-container>
						</ng-container>
					</ng-scrollbar>
				</div>
			</form>
		</div>
	</div>
</div>
