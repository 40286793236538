<div class="slideout-content default-slideout">
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
		<div class="modal-dialog">
			<div class="modal-content pt-0">
				<div class="modal-header">
					<h5 class="modal-title" id="releaseEditSlider">
						{{ "UI.k_edit" | translate }} {{ "UI.k_release" | translate }}
					</h5>
					<button type="button" id="btnClose" name="btnClose" class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal" aria-label="Close"
						(click)="toggleSidebar('release-edit-sidebar', releaseEditSideForm)" [attr.data-testid]="'btnClose'">
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body p-0">
					<form #releaseEditSideForm="ngForm" class="flex-auto-height">
						<div class="release-info flex-initial border-bottom">
							<div class="release-name fw-500 bg-body body-color" tooltipClass="fadeInAnimation"
								ngbTooltip="{{ 'UI.k_copy' | translate }} {{ 'UI.k_release_display_id' | translate }}"
								(click)="copyDisplayId(release_data_copy?.display_id)">
								#{{ release_data_copy?.display_id }}
								<span class="primary-color ml-1"><i class="fa-light fa-copy"></i></span>
							</div>
							<div class="time-info">
								<div class="icon-sec sm-font text-muted-color"><i class="fa-light fa-clock"></i></div>
								<div class="time-detail">
									<span class="xs-font text-muted-color">{{ "UI.k_created_on" | translate }} :</span>
									<span class="sm-font body-color">{{
										release_data_copy?.creation_time | date: "MMM d, y, h:mm a"
										}}</span>
								</div>
							</div>
							<div class="time-info">
								<div class="icon-sec sm-font text-muted-color"><i class="fa-light fa-clock"></i></div>
								<div class="time-detail">
									<span class="xs-font text-muted-color">{{ "UI.k_last_updated_on" | translate }}
										:</span>
									<span class="sm-font body-color">{{
										release_data_copy?.last_update_time | date: "MMM d, y, h:mm a"
										}}</span>
								</div>
							</div>
						</div>
						<div class="detail-sec flex-1">
							<ng-scrollbar class="custom-scrollbar section-scrollbar">
								<div class="py-1 container-fluid">
									<!-- details Section -->

									<!-- general details -->
									<div class="row">
										<div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
											<h6 class="header-title">
												{{ "UI.k_general_details" | translate }}
											</h6>
										</div>
										<div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
											<div class="form-group">
												<label for="txtReleaseSummary">{{ "UI.k_subject" | translate }}
													<span class="required-field">&nbsp;*</span>
												</label>
												<input type="text" [(ngModel)]="release_data.basic_info.summary"
													#TDSummaryRef="ngModel" id="txtReleaseSummary" class="form-control"
													placeholder="{{ 'UI.k_summary' | translate }}"
													name="txtReleaseSummary" minlength="3" maxlength="1000"
													[appWhiteSpaceCheck]="release_data.basic_info.summary"
													[disabled]="disable_config" required
													[class.error]="!TDSummaryRef.valid && releaseEditSideForm.submitted" />
												<span *ngIf="releaseEditSideForm.submitted && TDSummaryRef.invalid"
													class="invalid-form">
													<small class="form-text text-danger font-small-1"
														*ngIf="TDSummaryRef.errors.required">{{ "UI.k_field_required" |
														translate }}</small>
													<small class="form-text text-danger font-small-1" *ngIf="
															TDSummaryRef.errors.minlength ||
															TDSummaryRef.errors.maxlength
														">{{ "UI.k_summary_validation" | translate }}
													</small>
													<small class="form-text text-danger font-small-1"
														*ngIf="TDSummaryRef.errors?.whitespace">{{
														"UI.k_leading_trailing_space_validation" | translate
														}}</small>
												</span>
											</div>
										</div>
										<div class="col-xs-12 col-md-6 col-xl-4 col-lg-4">
											<div class="form-group">
												<label class="form-label" for="releaseType">{{ "UI.k_release_type" |
													translate }}
												</label>
												<ng-select [items]="dropdown.release_type" [hideSelected]="true"
													[clearable]="false" bindLabel="name"
													[(ngModel)]="release_data.basic_info.release_type"
													name="releaseType" [disabled]="true" id="releaseType">
												</ng-select>
											</div>
										</div>
										<div class="col-xs-12 col-md-6 col-xl-4 col-lg-4">
											<div class="form-group">
												<label class="form-label" for="releaseType">{{ "UI.k_risk" | translate
													}}
												</label>
												<div class="form-group">
													<app-select-badge [inputConfig]="{
															hideLabel: true,
															inputOptions: this.dropdown.risk,
															id: 'risk',
															model: this.release_data.basic_info.risk.id,
															isEditDisabled: disable_config
														}" (onValueChange)="statusBadgeChange($event)"></app-select-badge>
												</div>
											</div>
										</div>
										<div class="col-xs-12 col-md-6 col-xl-4 col-lg-4">
											<div class="form-group">
												<label for="releaseDueDate" id="LblActualStart-time">{{ "UI.k_due_date"
													| translate }}
													<span class="required-field">&nbsp;*</span>
												</label>
												<ng2-flatpickr #relstartEndDateInvalid [config]="dueDateTimeOptions" name="releaseDueDate"
													[setDate]="release_data.due_date" id="releaseDueDate "
													[disabled]="disable_config"
													placeholder="{{ 'UI.k_sel_date' | translate }}"
													[(ngModel)]="release_data.due_date"></ng2-flatpickr>
												<span *ngIf="startEndDateInvalid" class="col-12 invalid-form">
													<small class="form-text text-danger"
														[innerHtml]="startEndDateToaster">
													</small>
												</span>
											</div>
										</div>
										<div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
											<label for="txtaDescription" class="form-label">
												{{ "UI.k_description" | translate }}<span
													class="required-field">&nbsp;*</span></label>
											<textarea [disabled]="disable_config" #TDDescrRef="ngModel"
												name="txtaDescription" id="txtaDescription" rows="3"
												class="form-control" [(ngModel)]="release_data.basic_info.description"
												placeholder="{{ 'UI.k_let_us_know_what_you_need' | translate }}"
												required minlength="3" maxlength="3000"
												[class.error]="!TDDescrRef.valid && releaseEditSideForm.submitted"
												[appWhiteSpaceCheck]="release_data.basic_info.description">
											</textarea>
											<span *ngIf="releaseEditSideForm.submitted && TDDescrRef.invalid"
												class="invalid-form">
												<small class="form-text text-danger font-small-1"
													*ngIf="TDDescrRef.errors.required">{{ "UI.k_field_required" |
													translate }}</small>
												<small class="form-text text-danger font-small-1" *ngIf="
												TDDescrRef.errors.minlength ||
												TDDescrRef.errors.maxlength
												">{{ "UI.k_description_validation" | translate }}
												</small>
												<small class="form-text text-danger font-small-1"
													*ngIf="TDDescrRef.errors?.whitespace">{{
													"UI.k_leading_trailing_space_validation" | translate
													}}</small>
											</span>
										</div>
										<div class="col-xs-12 col-md-6 col-xl-3 col-lg-3">
											<div class="form-group">
												<label for="txtReqImpact">{{ "UI.k_impact" | translate }} </label>
												<ng-select [items]="dropdown.impact" [hideSelected]="true"
													bindLabel="name" bindValue="id"
													[(ngModel)]="release_data.basic_info.impact.id" name="txtReqImpact"
													id="txtReqImpact" placeholder="{{ 'UI.k_select' | translate }} {{
														'UI.k_impact' | translate
													}}" [disabled]="disable_config">
												</ng-select>
											</div>
										</div>
										<div class="col-xs-12 col-md-6 col-xl-3 col-lg-3">
											<div class="form-group">
												<label for="txtReqPriority">{{ "UI.k_priority" | translate }} </label>

												<div class="form-group">
													<app-select-badge [inputConfig]="{
															hideLabel: true,
															inputOptions: this.dropdown.priority,
															id: 'priority',
															model: this.release_data.basic_info.priority.id,
															isEditDisabled: disable_config
														}" (onValueChange)="statusBadgeChange($event)"></app-select-badge>
												</div>
											</div>
										</div>
										<div class="col-xs-12 col-md-6 col-xl-3 col-lg-3">
											<div class="form-group">
												<label for="txtReqSeverity">{{ "UI.k_severity" | translate }} </label>

												<div class="form-group">
													<app-select-badge [inputConfig]="{
															hideLabel: true,
															inputOptions: this.dropdown['severity'],
															id: 'severity',
															model: release_data.basic_info.severity.id,
															isEditDisabled: disable_config
														}" (onValueChange)="statusBadgeChange($event)"></app-select-badge>
												</div>
											</div>
										</div>
										<div class="col-xs-12 col-md-6 col-xl-3 col-lg-3">
											<div class="form-group">
												<label for="txtReqUrgency">{{ "UI.k_urgency" | translate }} </label>
												<div class="form-group">
													<app-select-badge [inputConfig]="{
															hideLabel: true,
															inputOptions: this.dropdown.urgency,
															id: 'urgency',
															model: release_data.basic_info.urgency.id,
															isEditDisabled: disable_config
														}" (onValueChange)="statusBadgeChange($event)"></app-select-badge>
												</div>
											</div>
										</div>
										<div class="col-xs-12 col-md-6 col-xl-4 col-lg-4">
											<app-status-picker #sliderStatusPickerComponent [inputConfig]="{
													isTextMode: true,
													stateOptionsMap: dropdown?.state_id_map,
													isEditDisabled: disable_config
												}" [options]="dropdown.transition_status" (setStatus)="onStatusChange($event)"
												[value]="release_data.basic_info.status.id"></app-status-picker>
										</div>
										<div *appDnsLicenseHandler="{
												module_key: 'SERVICEDESK',
												keys: ['SERVICE_CATALOGUE']
											}" class="col-xs-12 col-md-4 col-xl-4 col-lg-4">
											<div class="form-group input-tree-dropdown">
												<label for="txtReqImpactService">
													{{ "UI.k_service_category" | translate }}
												</label>
												<ng-container *ngIf="service_config?.is_editable">
													<app-single-tree-dropdown class="w-100"
														[items]="this.dropdown?.formatted_services"
														[value]="this.release_data?.basic_info?.impact_service"
														[id]="release_data?.release_id"
														(itemChange)="this.serviceValueChange($event)">
													</app-single-tree-dropdown>
												</ng-container>
												<div class="ng-select ng-select-single ng-select-searchable ng-select-clearable ng-untouched ng-pristine ng-valid"
													*ngIf="!service_config?.is_editable"
													(click)="onImpactServiceClick($event, 'impact_service')">
													<div class="ng-select-container">
														<div class="ng-value-container">
															<div class="">
																{{
																this.release_data?.basic_info
																?.impact_service_tree_path ||
																("UI.k_select_option" | translate)
																}}
															</div>
															<div role="combobox" class="ng-input">
																<input type="text" />
															</div>
														</div>
														<span class="ng-arrow-wrapper"><span
																class="ng-arrow"></span></span>
														<span *ngIf="service_config?.is_loading"
															class="fa fa-spinner fa-spin fa-2x">
														</span>
													</div>
												</div>
											</div>
										</div>
										<div class="col-xs-12 col-md-6 col-xl-4 col-lg-4">
											<div class="form-group">
												<label for="txtRelVersion">{{ "UI.k_version" | translate }} </label>
												<input type="text" class="form-control" id="txtRelVersion"
													name="txtRelVersion" placeholder="{{ 'UI.k_version' | translate}}"
													[(ngModel)]="release_data.basic_info.version" #TDVersion="ngModel"
													maxlength="40"
													[appWhiteSpaceCheck]="release_data.basic_info.version"
													[class.error]="
														!TDVersion.valid && releaseEditSideForm.submitted
													" [class.error]="!TDVersion.valid && releaseEditSideForm.submitted" />
												<div *ngIf="releaseEditSideForm.submitted && TDVersion.invalid"
													class="invalid-form">
													<small class="form-text text-danger xs-font"
														*ngIf="TDVersion.errors.maxlength">{{
														"UI.k_title_name_validation" | translate }}
													</small>
												</div>
											</div>
										</div>
										<div class="col-xs-12 col-md-6 col-xl-4 col-lg-4">
											<div class="form-group">
												<label for="txtReleaseTags">{{ "UI.k_tags" | translate }} </label>
												<ng-select [(ngModel)]="release_data.tag" name="txtReleaseTags"
													id="txtReleaseTags" [items]="dropdown.tagList" bindLabel="name"
													bindValue="tag_id" [closeOnSelect]="true"
													[addTag]="selectAddTagMethod"
													notFoundText="{{ 'UI.k_no_items_found' | translate }}"
													addTagText="{{ 'UI.k_add_tag' | translate }}"
													[ngModelOptions]="{ standalone: true }" [multiple]="true"
													[hideSelected]="true"
													placeholder="{{ 'UI.k_select_tags' | translate }}"
													[disabled]="disable_config"></ng-select>
												<ng-template #tagComp let-modal>
													<div class="modal-header">
														<h4 class="modal-title" id="myModalLabel1">
															{{ "UI.k_add_tags" | translate }}
														</h4>
														<span id="btnClose" name="btnClose" data-dismiss="modal"
															aria-label="Close" (click)="modal.dismiss('Cross click')">
															<i
																class="fa-light fa-xmark mb-0 mr-sm-25 mr-0 pull-right"></i>
														</span>
													</div>
													<div class="modal-body" tabindex="0" ngbAutofocus>
														<form #addTagForm="ngForm">
															<div class="row mb-2">
																<div class="col-xs-12 col-md-6 col-sm-6 col-lg-6">
																	<div class="form-group">
																		<label for="txtTName">{{ "UI.k_tag_name" |
																			translate }}
																			<span
																				class="required-field">&nbsp;*</span></label>
																		<input #TDTagNameRef="ngModel" required
																			type="text" class="form-control"
																			placeholder="{{
																				'UI.k_tag_name' | translate
																			}}" name="usrTagName" [(ngModel)]="tag.name" [appWhiteSpaceCheck]="tag.name" id="txtTName"
																			minlength="3" maxlength="40" [class.error]="
																				!TDTagNameRef.valid &&
																				addTagForm.submitted
																			" />
																		<span *ngIf="
																				addTagForm.submitted &&
																				TDTagNameRef.invalid
																			" class="invalid-form">
																			<small
																				class="form-text text-danger font-small-1"
																				*ngIf="TDTagNameRef.errors.required">{{
																				"UI.k_field_required" | translate
																				}}</small>
																			<small
																				class="form-text text-danger font-small-1"
																				*ngIf="
																					TDTagNameRef.errors.minlength ||
																					TDTagNameRef.errors.maxlength
																				">
																				{{
																				"UI.k_tag_validation" | translate
																				}}</small>
																			<small
																				class="form-text text-danger font-small-1"
																				*ngIf="TDTagNameRef.errors?.whitespace">{{
																				"UI.k_leading_trailing_space_validation"
																				| translate
																				}}</small>
																		</span>
																	</div>
																</div>
																<div class="col-xs-12 col-md-6 col-sm-6 col-lg-6">
																	<div class="form-group">
																		<label for="drpType">{{
																			"UI.k_type" | translate
																			}}</label>
																		<ng-select [items]="dropdown.tagTypeList"
																			[multiple]="false" [closeOnSelect]="true"
																			[searchable]="false" bindLabel="name"
																			bindValue="id" placeholder="{{
																				'UI.k_select' | translate
																			}}" name="type" id="drpType" [(ngModel)]="tag.type" disabled>
																		</ng-select>
																	</div>
																</div>
															</div>
															<div class="d-flex pull-right">
																<div class="mr-1">
																	<button (click)="onCreateTag(addTagForm)"
																		[disabled]="is_tag_save_disabled" type="submit"
																		class="btn btn-primary" rippleEffect
																		id="btnAcntSave" name="btnAcntSave" [attr.data-testid]="'btnAcntSave'">
																		<span
																			class="align-middle d-sm-inline-block d-none">{{
																			"UI.k_submit" | translate }}</span>

																		<i
																			class="fa-light fa-arrow-right align-middle ml-sm-25 mr-0"></i>
																	</button>
																</div>
															</div>
														</form>
													</div>
												</ng-template>
											</div>
										</div>
										<div class="col-xs-12 col-md-6 col-xl-4 col-lg-4">
											<div class="form-group">
												<label for="txtReleaseUpdateDate">{{ "UI.k_actual_start_time" |
													translate }}
												</label>
												<ng2-flatpickr #actualStarttime [config]="dateTimeOptions" name="txtReleaseUpdateDate"
													[setDate]="release_data.actual_start_time"
													placeholder="{{ 'UI.k_sel_date' | translate }}"
													id="txtReleaseUpdateDate " [disabled]="disable_config"
													[(ngModel)]="release_data.actual_start_time"></ng2-flatpickr>
											</div>
										</div>
										<div class="col-xs-12 col-md-6 col-xl-4 col-lg-4">
											<div class="form-group d-flex flex-column">
												<label>{{ "UI.k_callback_options" | translate }} </label>

												<div class="btn-group cursor-pointer call-btn-group" role="group"
													aria-label="Release Toggle View">
													<button type="button" class="btn btn-outline-tertiary"
														(click)="callBackOption('phone')" id="btnPhone" name="btnPhone"
														[disabled]="disable_config" rippleEffect [ngClass]="{
															'contact-selection':
																release_data.basic_info.callback === 'phone',
															'px-1': true
														}" placement="bottom"  [attr.data-testid]="'btnPhone'">
														<i class="fa-light fa-phone"></i>{{ "UI.k_phone" | translate }}
													</button>
													<button type="button" class="btn btn-outline-tertiary"
														(click)="callBackOption('email')" id="btnMail"
														[disabled]="disable_config" name="btnMail" rippleEffect
														[ngClass]="{
															'contact-selection':
																release_data.basic_info.callback === 'email',
															'px-1': true
														}" [attr.data-testid]="'btnMail'">
														<i class="fa-light fa-envelope"></i>
														{{ "UI.k_email" | translate }}
													</button>
													<button type="button" class="btn btn-outline-tertiary"
														(click)="callBackOption('self_service')" id="btnUser"
														name="btnUser" rippleEffect [ngClass]="{
															'contact-selection':
																release_data.basic_info.callback === 'self_service',
															'px-1': true
														}" [attr.data-testid]="'btnUser'">
														<i class="fa-light fa-user"></i>{{ "UI.k_self_service" |
														translate }}
													</button>
												</div>
											</div>
										</div>
										<div *ngIf="release_data?.basic_info?.event"
											class="col-xs-12 col-md-6 col-xl-3 col-lg-3">
											<ng-container *appDnsLicenseHandler="{
													module_key: 'MONITORING',
													keys: ['EVENT_MANAGEMENT']
												}">
												<div class="form-group">
													<label for="txtReqEventType">{{ "UI.k_event_status" | translate }}
													</label>
													<ng-select [items]="dropdown.event_status" [hideSelected]="true"
														bindLabel="name" bindValue="id"
														[(ngModel)]="release_data.basic_info.event_status"
														name="txtReqEventType" id="txtReqEventType"
														placeholder="{{ 'UI.k_select_event_status' | translate }}"
														[disabled]="disable_config">
													</ng-select>
												</div>
											</ng-container>
										</div>
									</div>
									<div class="col-12 p-0">
										<app-dns-custom-field [formRef]="releaseEditSideForm" placeholder="general"
											[data]="release_data?.custom_field_data" [disable]="disable_config"
											[config]="release_extra_info?.custom_field_config"></app-dns-custom-field>
									</div>

									<!-- <hr /> -->
									<!-- closer details -->
									<div class="row" *ngIf="release_data?.basic_info?.state?.id == 5">
										<div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
											<h6 class="header-title">{{ "UI.k_close_release" | translate }}</h6>
										</div>
										<div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
											<div class="form-group">
												<label for="txtReqCloserInfo">{{ "UI.k_close_release" | translate }}
													<span class="required-field">&nbsp;*</span>
												</label>
												<quill-editor [(ngModel)]="release_data.close_info.closure_note"
													id="quillReleaseClosure" name="quillReleaseClosure"
													(onContentChanged)="getClosureNote($event)"
													(onEditorCreated)="editorCreated($event)" [trimOnValidation]="true"
													#TDReqClosureNoteRef [appQuillValidator]="TDReqClosureNoteRef"
													[appQuillForm]="releaseEditSideForm" [minLength]="3"
													[required]="true" [class.error]="
														TDReqClosureNoteRef?.errors && releaseEditSideForm.submitted
													" placeholder="{{ 'UI.k_closure_note' | translate }}">
													<div id="quill-toolbar" quill-editor-toolbar>
														<div class="">
															<span class="ql-formats">
																<button class="ql-bold"
																	title="{{ 'UI.k_bold' | translate }}"></button>
																<button class="ql-italic"
																	title="{{ 'UI.k_italic' | translate }}"></button>
																<button class="ql-underline"
																	title="{{ 'UI.k_underline' | translate }}"></button>
															</span>
															<span class="ql-formats">
																<select class="ql-align"
																	title="{{ 'UI.k_alignment' | translate }}">
																	<option selected></option>
																	<option value="center"></option>
																	<option value="right"></option>
																	<option value="justify"></option>
																</select>
															</span>
															<span class="ql-formats">
																<button class="ql-list" value="ordered"
																	type="button"></button>
															</span>
															<span class="ql-formats">
																<button class="ql-link" value="ordered"
																	type="button"></button>
																<button class="ql-image" value="ordered"
																	type="button"></button>
																<button class="ql-video" value="ordered"
																	type="button"></button>
															</span>
															<span class="ql-formats">
																<button class="ql-formula" value="ordered"
																	type="button"></button>
																<button class="ql-code-block" value="ordered"
																	type="button"></button>
															</span>
															<span class="ql-formats">
																<button class="ql-clean" value="ordered"
																	type="button"></button>
															</span>
															<span class="ql-formats">
																<button id="insert-table" type="button"
																	(click)="addNewtable($event)">
																	Table
																</button>
															</span>
														</div>
													</div>
												</quill-editor>
												<div class="error"
													*ngIf="releaseEditSideForm.submitted && TDReqClosureNoteRef?.errors">
													<small *ngIf="TDReqClosureNoteRef?.errors?.requiredError?.empty">
														{{ "UI.k_field_required" | translate }}
													</small>
													<small *ngIf="TDReqClosureNoteRef?.errors?.minLengthError">
														{{ "Error.err_closure_note_min" | translate }}
													</small>
												</div>
											</div>
										</div>
										<div class="col-xs-12 col-md-6 col-xl-3 col-lg-3">
											<div class="form-group">
												<label for="txtReqClosedBy">{{ "UI.k_closedby" | translate }} </label>
												<ng-select [items]="dropdown.users"
													[(ngModel)]="release_data.close_info.closed_by"
													bindLabel="full_name" bindValue="profile_id" name="txtReqClosedBy"
													id="txtReqClosedBy"
													placeholder="{{ 'UI.k_select_users' | translate }}"
													[hideSelected]="true">
													<ng-template ng-option-tmp let-item="item" let-index="index"
														let-search="searchTerm">
														<span class="d-flex">
															<div *ngIf="
																	item?.profile_image
																">
																<div class="avatar">
																	<span class="avatar-content">
																		<img class="rounded-circle" src="{{
																				item.profile_image
																			}}" alt="User" width="32" height="32" />
																	</span>
																</div>
															</div>
															<div *ngIf="
																	!item?.profile_image
																">
																<div class="avatar mr-50 ml-25" [ngStyle]="{
																		'background-color': item.avatar_color
																		? item.avatar_color
																		: '#f59518'
																	}" style="
																		align-content: center;
																		width: 32px;
																		height: 32px;
																	">
																	<div class="avatar-content">
																		{{ item.full_name | initials | slice: 0:2 }}
																	</div>
																</div>
															</div>
															<div class="cell-line-height">
																<p
																	class="font-medium-1 font-weight-bold line-height-1 mb-0">
																	{{ item?.full_name | slice: 0:28 }}
																	{{ item?.full_name?.length > 28 ? ".." : "" }}
																</p>
																<span class="text-muted font-small-2">
																	{{ item.email }}</span>
															</div>
														</span>
													</ng-template>
												</ng-select>
											</div>
										</div>
										<div class="col-xs-12 col-md-6 col-xl-3 col-lg-3">
											<div class="form-group">
												<label for="txtReqCloseType">{{ "UI.k_close_type" | translate }}
												</label>
												<ng-select [items]="dropdown.close_type" [hideSelected]="true"
													bindLabel="name" bindValue="id" (change)="
														updateFieldName(
															release_data.basic_info.closed_type.id,
															'id',
															'closed_type',
															release_data.basic_info.closed_type.name,
															'name'
														)
													" [(ngModel)]="release_data.close_info.closed_type.id" name="txtReqCloseType" id="txtReqCloseType"
													placeholder="{{ 'UI.k_sel_close_type' | translate }}">
												</ng-select>
											</div>
										</div>
										<div class="col-xs-12 col-md-6 col-xl-3 col-lg-3">
											<div class="form-group">
												<label for="txtReleaseActlClsrDate">{{ "UI.k_actual_closure_date" |
													translate }}
												</label>
												<ng2-flatpickr #actualStarttime [config]="dateTimeOptions" name="txtReleaseActlClsrDate"
													[setDate]="
														release_data?.actual_closure_date
															? release_data?.actual_closure_date
															: (release_data['actual_closure_date'] =
																	dateTimeOptions?.defaultDate)
													" id="txtReleaseActlClsrDate" placeholder="{{ 'UI.k_sel_date' | translate }}" [(ngModel)]="release_data.actual_closure_date"></ng2-flatpickr>
											</div>
										</div>
										<div class="col-xs-12 col-md-6 col-xl-3 col-lg-3">
											<div class="form-group">
												<label for="txtReqclsrAgrdTime">{{ "UI.k_agreed_closure_date" |
													translate }}
												</label>
												<ng2-flatpickr #agreedClosureDate [config]="agreedDateTimeOptions"
													name="txtReqclsrAgrdTime"
													placeholder="{{ 'UI.k_sel_date' | translate }}"
													[setDate]="release_data.agreed_closure_date" id="txtReqclsrAgrdTime"
													[(ngModel)]="release_data.agreed_closure_date"></ng2-flatpickr>
											</div>
										</div>
										<div class="col-12">
											<app-dns-custom-field [formRef]="releaseEditSideForm" placeholder="closure"
												[data]="release_data?.custom_field_data" [disable]="disable_config"
												[config]="release_extra_info?.custom_field_config"></app-dns-custom-field>
										</div>
									</div>
									<!-- End of details Section -->

									<!-- Reporter details -->
									<hr />

									<!-- release manager details -->
									<div class="row">
										<div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
											<h6 class="header-title">
												{{ "UI.k_release_manager" | translate }}
											</h6>
										</div>
										<div class="col-xs-12 col-md-6 col-xl-6 col-lg-6">
											<div class="form-group">
												<label for="selDepartment" class="form-label">
													{{ "UI.k_release_manager" | translate }}</label>
												<ng-select [items]="dropdown.users" bindLabel="full_name"
													class="form-control" [(ngModel)]="release_data.release_manager"
													name="selManager" id="selManager" placeholder="{{ 'UI.k_select' | translate }} {{
														'UI.k_release_manager' | translate
													}}" [multiple]="false" [closeOnSelect]="true" [searchable]="true" #TDReleaseManagerRef
													[disabled]="disable_config">
													<ng-template ng-option-tmp let-item="item" let-index="index"
														let-search="searchTerm">
														<span class="d-flex">
															<div *ngIf="item?.profile_image">
																<div class="avatar mr-50 ml-25">
																	<span class="avatar-content">
																		<img class="rounded-circle"
																			src="{{ item?.profile_image }}" alt="User"
																			width="32" height="32" />
																	</span>
																</div>
															</div>
															<div *ngIf="!item?.profile_image">
																<div class="avatar mr-50 ml-25" [ngStyle]="{
																		'background-color': item.avatar_color
																			? item.avatar_color
																			: '#f59518'
																	}" style="
																		align-content: center;
																		width: 32px;
																		height: 32px;
																	">
																	<div class="avatar-content">
																		{{ item.full_name | initials | slice: 0:2 }}
																	</div>
																</div>
															</div>

															<div class="cell-line-height">
																<p
																	class="font-medium-1 font-weight-bold line-height-1 mb-0">
																	{{ item?.full_name | slice: 0:28 }}
																	{{ item?.full_name?.length > 28 ? ".." : "" }}
																</p>
																<span class="text-muted font-small-2">
																	{{ item?.email | slice: 0:28 }}
																	{{ item?.email.length > 28 ? ".." : "" }}</span>
															</div>
														</span>
													</ng-template>
												</ng-select>
												<!-- <div
													*ngIf="releaseEditSideForm.submitted && TDReleaseManagerRef.invalid"
													class="invalid-form"
												>
													<small
														class="form-text text-danger xs-font"
														*ngIf="TDReleaseManagerRef.errors.required"
														>{{ "UI.k_field_required" | translate }}
													</small>
												</div> -->
											</div>
										</div>
									</div>

									<hr />
									<!-- Assignee Details -->
									<div class="row">
										<div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
											<div class="form-group">
												<h6 class="header-title" type="text">
													{{ "UI.k_assignment" | translate }}
												</h6>
											</div>
										</div>
										<div class="col-xs-12 col- col-md-10 col-xl-10 col-lg-10">
											<div class="row">
												<div class="col-xs-6 col-md-4 col-xl-4 col-lg-4">
													<div class="form-group">
														<label for="txtReqSidebarAssignGroup">{{ "UI.k_select" |
															translate }}
															{{ "UI.k_team" | translate }}
														</label>
														<ng-select [items]="dropdown.teams_and_self_assign"
															(clear)="selfAssignSel=false;" [hideSelected]="true"
															bindLabel="name" bindValue="team_id" [(ngModel)]="team_id"
															(change)="groupAssignChange(dropdown.teams, team_id)"
															(clear)="selfAssignSel = false"
															name="txtReqSidebarAssignGroup"
															id="txtReqSidebarAssignGroup" placeholder="{{ 'UI.k_select' | translate }} {{
																'UI.k_group' | translate
															}}" #TDAsisignment="ngModel" [disabled]="disable_config">
															<ng-template ng-option-tmp let-item="item" let-index="index"
																let-search="searchTerm">
																<span class="d-flex" (click)="selfAssignSel = false"
																	*ngIf="item.team_id != 'self_assign'">
																	<div class="avatar mr-50 ml-25" [ngStyle]="{
																			'background-color':
																				item?.user_profile?.avatar_color !=
																				undefined
																					? item?.user_profile?.avatar_color
																					: '#f59518'
																		}" style="
																			align-content: center;
																			width: 32px;
																			height: 32px;
																		">
																		<div class="avatar-content" [ngStyle]="{
																				'background-color': stringToColor(
																					item.name
																				)
																			}">
																			{{ item.name | initials | slice: 0:2 }}
																		</div>
																	</div>
																	<div class="cell-line-height">
																		<p
																			class="font-medium-1 font-weight-bold line-height-1 mb-0">
																			{{ item?.name | slice: 0:28 }}
																			{{ item?.name.length > 28 ? ".." : "" }}
																		</p>
																		<span class="text-muted font-small-2">
																			{{ item.group_type }}</span>
																	</div>
																</span>
																<div class="cursor-pointer"
																	*ngIf="item.team_id == 'self_assign'">
																	<div class="media" (click)="assignToMyself()">
																		<div class="avatar mr-1">
																			<div class="avatar-content" [ngStyle]="{
																					'background-color':
																						currentUser?.profile
																							?.avatar_color != undefined
																							? currentUser?.profile
																									?.avatar_color
																							: '#f59518'
																				}">
																				{{
																				this.currentUser.first_name +
																				"" +
																				this.currentUser.last_name
																				| initials
																				| slice: 0:2
																				}}
																			</div>
																		</div>
																		<div class="media-body">
																			<h5 class="mb-0">Self Assign</h5>
																			<small class="text-muted">{{
																				this.currentUser.email
																				}}</small>
																		</div>
																	</div>
																</div>
															</ng-template>
														</ng-select>
													</div>
												</div>
												<div class="form-group col-xs-6 col-md-4 col-xl-4 col-lg-4"
													*ngIf="selfAssignSel && dropdown?.current_user_group.length > 1">
													<label for="txtReqAssignGroup">{{ "UI.k_select_group" | translate }}
														<span class="required-field">&nbsp;*</span>
													</label>
													<ng-select [items]="dropdown.current_user_group"
														[hideSelected]="true" (change)="
															groupChange(
																dropdown.current_user_group,
																release_data.current_assignment_info.group
															)
														" bindLabel="name" bindValue="team_id" [(ngModel)]="release_data.current_assignment_info.group"
														name="txtReqAssignGroup" id="txtReqAssignGroup" placeholder="{{ 'UI.k_select' | translate }} {{
															'UI.k_group' | translate
														}}" #TDGroup="ngModel" [closeOnSelect]="true"
														[class.error]="!TDGroup.valid && releaseEditSideForm.submitted"
														required="team_id != undefined && team != null && team_id"
														[disabled]="disable_config">
														<ng-template ng-option-tmp let-item="item" let-index="index"
															let-search="searchTerm">
															<span class="d-flex" style="padding-left: 1rem">
																<span class="avatar mr-50" [ngStyle]="{
																		'background-color': item.avatar_color
																			? item.avatar_color
																			: '#f59518'
																	}" style="
																		align-content: center;
																		width: 32px;
																		height: 32px;
																	">
																	<div class="avatar-content" [ngStyle]="{
																			'background-color': stringToColor(item.name)
																		}">
																		{{ item.name | initials | slice: 0:2 }}
																	</div>
																</span>
																<div class="cell-line-height">
																	<p
																		class="font-medium-1 font-weight-bold line-height-1 mb-0">
																		{{ item?.name | slice: 0:28 }}
																		{{ item?.name.length > 28 ? ".." : "" }}
																	</p>
																	<span class="text-muted font-small-2">
																		{{ item.group_type }}</span>
																</div>
															</span>
														</ng-template>
													</ng-select>
													<span *ngIf="TDGroup.invalid && releaseEditSideForm.submitted"
														class="invalid-form">
														<small class="form-text text-danger">{{
															"UI.k_field_required" | translate
															}}</small>
													</span>
												</div>
												<ng-container *appDnsLicenseHandler="{
														module_key: 'BASE',
														keys: ['TEAMS_PER_TECH']
													}">
													<div class="form-group col-xs-6 col-md-4 col-xl-4 col-lg-4" *ngIf="
															selfAssignSel &&
															dropdown?.current_user_expertise?.length > 1
														">
														<label for="txtReqSideExpertise">{{ "UI.k_select_expertise" |
															translate }}
															<span class="required-field">&nbsp;*</span>
														</label>
														<ng-select [items]="dropdown?.current_user_expertise"
															[hideSelected]="true"
															(change)="expertiseChange(dropdown?.current_user_expertise)"
															bindLabel="expertise" bindValue="expertise"
															[(ngModel)]="release_data.current_assignment_info.expertise"
															name="txtReqSideExpertise" id="txtReqSideExpertise"
															#TDReqSideexpertise="ngModel" placeholder="{{ 'UI.k_select' | translate }} {{
																'UI.k_expertise' | translate
															}}" [class.error]="
																!TDReqSideexpertise.valid &&
																releaseEditSideForm.submitted
															" required [disabled]="disable_config">
														</ng-select>
														<span *ngIf="
																TDReqSideexpertise.invalid &&
																releaseEditSideForm.submitted
															" class="invalid-form">
															<small class="form-text text-danger"
																*ngIf="TDReqSideexpertise.errors.required">{{
																"UI.k_field_required" | translate }}</small>
														</span>
													</div>
													<div class="col-xs-6 col-md-4 col-xl-4 col-lg-4" *ngIf="
															!selfAssignSel &&
															release_extra_info.group_type === 'Operation and Technical'
														">
														<div class="form-group">
															<label for="txtReqExpertise">{{ "UI.k_expertise" | translate
																}}
															</label>
															<ng-select [items]="dropdown?.expertise"
																[hideSelected]="true"
																(change)="expertiseChange(dropdown?.expertise)"
																bindLabel="expertise" bindValue="expertise" [(ngModel)]="
																	release_data.current_assignment_info.expertise
																" name="txtReqExpertise" id="txtReqExpertise" #TDexpertise="ngModel" placeholder="{{ 'UI.k_select' | translate }} {{
																	'UI.k_expertise' | translate
																}}" [disabled]="disable_config">
															</ng-select>
															<!-- <span
																*ngIf="
																	releaseEditSideForm.submitted &&
																	TDexpertise?.errors?.required
																"
																class="invalid-form"
															>
																<small class="form-text text-danger">{{
																	"UI.k_field_required" | translate
																}}</small>
															</span> -->
														</div>
													</div>
													<div class="col-xs-6 col-md-4 col-xl-4 col-lg-4" *ngIf="
															!selfAssignSel &&
															release_extra_info?.group_type === 'Operation and Technical'
														">
														<div class="form-group">
															<label for="txtReqReporterLevel">{{ "UI.k_level" | translate
																}}
																<span *ngIf="
																		release_data?.current_assignment_info?.expertise
																	" class="required-field">&nbsp;*</span>
															</label>
															<ng-container
																*ngIf="release_data?.current_assignment_info?.expertise">
																<ng-select [items]="dropdown.level"
																	[hideSelected]="true" (change)="levelChange()"
																	bindLabel="level" bindValue="level"
																	[(ngModel)]="release_data.current_assignment_info.level"
																	name="txtReqReporterLevel" id="txtReqReporterLevel"
																	placeholder="{{ 'UI.k_select' | translate }} {{
																		'UI.k_level' | translate
																	}}" #TDLevel="ngModel" [closeOnSelect]="true" [class.error]="
																		releaseEditSideForm.submitted && !TDLevel.valid
																	" required [disabled]="disable_config">
																</ng-select>
																<span
																	*ngIf="releaseEditSideForm.submitted && TDLevel?.invalid"
																	class="form-text text-danger">
																	<small class="form-text text-danger">{{
																		"UI.k_field_required" | translate
																		}}</small>
																</span>
															</ng-container>

															<ng-select
																*ngIf="!release_data?.current_assignment_info?.expertise"
																[items]="dropdown.level" [hideSelected]="true"
																(change)="levelChange()" bindLabel="level"
																bindValue="level"
																[(ngModel)]="release_data.current_assignment_info.level"
																name="txtReqReporterManLevel"
																id="txtReqReporterManLevel" placeholder="{{ 'UI.k_select' | translate }} {{
																	'UI.k_level' | translate
																}}" [closeOnSelect]="true">
															</ng-select>
														</div>
													</div>
												</ng-container>

												<div class="col-xs-6 col-md-4 col-xl-4 col-lg-4" *ngIf="!selfAssignSel">
													<div class="form-group">
														<label for="txtReqAssignee">{{ "UI.k_assignee" | translate }}
														</label>
														<ng-select [items]="dropdown?.assignee" [hideSelected]="true"
															[(ngModel)]="release_data.current_assignment_info.assignee"
															(change)="assigneeChange()" [searchFn]="customSearchFn"
															bindLabel="full_name" bindValue="profile_id"
															name="txtReqAssignee" id="txtReqAssignee" placeholder="{{ 'UI.k_select' | translate }} {{
																'UI.k_assignee' | translate
															}}" #TDAssignee="ngModel" [closeOnSelect]="true" [disabled]="disable_config">
															<ng-template ng-label-tmp let-item="item">
																<span class="d-flex ng-value-label align-items-center"
																	[ngbTooltip]="item.name">
																	<span class="d-flex"
																		>
																		<div *ngIf="
																			dropdown.team_user_profile_map[
																				item.profile_id
																			]?.profile_image
																		">
																			<div class="avatar avatar-sm mr-50">
																				<span class="avatar-content">
																					<img class="rounded-circle" src="{{
																						dropdown.team_user_profile_map[
																							item.profile_id
																						].profile_image
																					}}" alt="User"/>
																				</span>
																			</div>
																		</div>
																		<div *ngIf="
																			!dropdown.team_user_profile_map[
																				item.profile_id
																			]?.profile_image
																		">
																			<div class="avatar avatar-sm mr-50" [ngStyle]="{
																				'background-color': dropdown
																					.team_user_profile_map[
																					item.profile_id
																				]?.avatar_color
																					? dropdown.team_user_profile_map[
																							item.profile_id
																					  ]?.avatar_color
																					: '#f59518'
																			}" 
																			>
																				<div class="avatar-content">
																					{{
																					item.full_name
																					| initials
																					| slice: 0:2
																					}}
																				</div>
																			</div>
																		</div>
																	</span>
																	<div class="cell-line-height">
																		<span
																			class="font-small-2 fw-500 line-height-1 mb-0">
																			{{ item?.full_name | slice: 0:18 }}
																			{{ item?.full_name?.length > 18 ? ".." : ""
																			}}
																		</span>
																	</div>
																</span>
															</ng-template>
															<ng-template ng-option-tmp let-item="item" let-index="index"
																let-search="searchTerm">
																<span class="d-flex">
																	<div *ngIf="
																			dropdown.team_user_profile_map[
																				item.profile_id
																			]?.profile_image
																		">
																		<div class="avatar mr-50">
																			<span class="avatar-content">
																				<img class="rounded-circle" src="{{
																						dropdown.team_user_profile_map[
																							item.profile_id
																						].profile_image
																					}}" alt="User" width="32" height="32" />
																			</span>
																		</div>
																	</div>
																	<div *ngIf="
																			!dropdown.team_user_profile_map[
																				item.profile_id
																			]?.profile_image
																		">
																		<div class="avatar mr-50" [ngStyle]="{
																				'background-color': dropdown
																					.team_user_profile_map[
																					item.profile_id
																				]?.avatar_color
																					? dropdown.team_user_profile_map[
																							item.profile_id
																					  ]?.avatar_color
																					: '#f59518'
																			}" style="
																				align-content: center;																			">
																			<div class="avatar-content">
																				{{
																				item.full_name
																				| initials
																				| slice: 0:2
																				}}
																			</div>
																		</div>
																	</div>

																	<div class="cell-line-height">
																		<p
																			class="font-medium-1 font-weight-bold line-height-1 mb-0">
																			{{ item?.full_name | slice: 0:28 }}
																			{{
																			item?.full_name?.length > 28 ? ".." : ""
																			}}
																		</p>
																		<span class="text-muted font-small-2">
																			{{ item?.email | slice: 0:28 }}
																			{{
																			item?.email.length > 28 ? ".." : ""
																			}}</span>
																	</div>
																</span>
															</ng-template>
														</ng-select>
														<!-- <span
                                                            *ngIf="
                                                                releaseEditSideForm.submitted &&
                                                                TDAssignee?.errors?.required
                                                            "
                                                            class="invalid-form"
                                                        >
                                                            <small class="form-text text-danger">{{
                                                                "UI.k_field_required" | translate
                                                            }}</small>
                                                        </span> -->
													</div>
												</div>
											</div>
										</div>
										<div class="col-xs-12 col-md-2 col-xl-2 col-lg-2">
											<div class="row">
												<div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
													<div class="form-group">
														<label>{{ "UI.k_assignee_img" | translate }}</label>
														<div class="media">
															<a href="javascript:void(0);">
																<img *ngIf="
																		release_extra_info?.selected_assignee_info
																			?.profile_image
																	" [src]="
																		release_extra_info?.selected_assignee_info
																			?.profile_image
																	" class="rounded mr-50" height="100" width="150" />
																<div *ngIf="
																		!release_extra_info?.selected_assignee_info
																			?.profile_image &&
																		!release_extra_info?.selected_assignee_info
																			?.full_name
																	" class="avatar bg-light-primary avatar-110-150 rounded mr-50">
																	<span class="avatar-content">
																		<i
																			class="fa-light fa-user fa-1x user-icon-clr"></i>
																		<!--<i [size]="[100]"	[data-feather]="'user'" class="user-icon-clr"></i>-->
																	</span>
																</div>
																<div *ngIf="
																		!release_extra_info?.selected_assignee_info
																			?.profile_image &&
																		release_extra_info?.selected_assignee_info
																			?.full_name
																	" class="avatar avatar-110-150 rounded mr-50" [ngStyle]="{
																		'background-color':
																			release_extra_info?.selected_assignee_info
																				?.profile?.avatar_color != undefined
																				? release_extra_info
																						?.selected_assignee_info
																						?.profile?.avatar_color
																				: '#f59518'
																	}">
																	<span class="avatar-content">{{
																		this.release_extra_info.selected_assignee_info
																		.full_name | initials
																		}}</span>
																</div>
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-12">
											<app-dns-custom-field placeholder="assignment"
												[formRef]="releaseEditSideForm" [data]="release_data?.custom_field_data"
												[disable]="disable_config"
												[config]="release_extra_info?.custom_field_config"></app-dns-custom-field>
										</div>
									</div>
								</div>
							</ng-scrollbar>
						</div>
						<div class="detail-btn-sec flex-initial d-flex justify-content-end p-1 border-top">
							<button id="btnReleaseSaveChanges" name="btnReleaseSaveChanges" (click)="saveReleaseData(releaseEditSideForm)" *ngIf="
									!disable_config &&
									!(release_data?.is_exit && release_data?.basic_info?.status?.state_id == 5) &&
									permissions?.edit
								" type="submit" class="btn btn-primary" [disabled]="is_release_sidebar_save_disabled" rippleEffect
								[attr.data-testid]="'btnReleaseSaveChanges'">
								{{ "UI.k_save_changes" | translate }}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="service-change-sidebar-inner"
	overlayClass="modal-backdrop">
	<ng-container *ngIf="is_service_change">
		<app-service-change [options]="dropdown" sidebarName="service-change-sidebar-inner"
			[service_config]="service_release_data" (onServiceChangeSubmit)="onServiceChangeSubmit($event)"
			(onServiceSliderClose)="onServiceSliderClose()">
		</app-service-change>
	</ng-container>
</core-sidebar>
