import {
	Component,
	Input,
	Output,
	EventEmitter,
	ViewChild,
	OnChanges,
	Injectable,
	OnInit,
	SimpleChange,
} from "@angular/core";
import {
	TreeviewI18n,
	TreeviewItem,
	TreeviewConfig,
	DropdownTreeviewComponent,
	TreeviewHelper,
	DownlineTreeviewItem,
	TreeviewEventParser,
	OrderDownlineTreeviewEventParser,
} from "ngx-treeview";
import { DropdownTreeviewSelectI18n } from "./dropdown-treeview-select-i18n";
import { isNil, remove, reverse } from "lodash";
import { TreeDataService } from "../single-tree-dropdown/tree-data.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class ProductTreeviewConfig extends TreeviewConfig {
	hasAllCheckBox = true;
	hasFilter = true;
	hasCollapseExpand = false;
	maxHeight = 400;
}

@Component({
	selector: "app-ngx-dropdown-treeview-select",
	templateUrl: "./ngx-dropdown-treeview-select.component.html",
	styleUrls: ["./ngx-dropdown-treeview-select.component.scss"],
	providers: [
		TreeDataService,
		{ provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser },
		{ provide: TreeviewConfig, useClass: ProductTreeviewConfig },
	],
})
export class NgxDropdownTreeviewSelectComponent implements OnInit {
	@Input() config: TreeviewConfig;
	@Input() items: TreeviewItem[];
	@Input() value: any;
	@Input() id: any;
	@Input() treeInputConfig: any;
	@Output() itemChange = new EventEmitter<any>();
	@ViewChild(DropdownTreeviewComponent, { static: false }) dropdownTreeviewComponent: DropdownTreeviewComponent;
	filterText: string;
	private dropdownTreeviewSelectI18n: DropdownTreeviewSelectI18n;
	rows: string[];
	allTreeData: any;
	selectedTreeData = this._translateService.instant("UI.k_select_option");
	buttonClasses = [
		"btn-outline-primary",
		"btn-outline-secondary",
		"btn-outline-success",
		"btn-outline-danger",
		"btn-outline-warning",
		"btn-outline-info",
		"btn-outline-light",
		"btn-outline-dark",
	];
	buttonClass = this.buttonClasses[0];
	itemTemplate = [];
	dynamicText = [];

	constructor(public i18n: TreeviewI18n, private _translateService: TranslateService) {
		this.config = TreeviewConfig.create({
			hasAllCheckBox: false,
			hasCollapseExpand: false,
			hasFilter: true,
			maxHeight: 500,
		});
		this.dropdownTreeviewSelectI18n = i18n as DropdownTreeviewSelectI18n;
	}

	ngOnInit(): void {}

	addDynamicId(array) {
		array.forEach((ele) => {
			if (ele["internalChildren"]) {
				this.addDynamicId(ele["internalChildren"]);
			} else {
				var iDiv = document.createElement("span");
				iDiv.id = "block" + ele.value;
				iDiv.className = "block";
				document.getElementsByTagName("ngx-dropdown-treeview")[0].childNodes[0].childNodes[0].appendChild(iDiv);
			}
		});
	}

	ngOnChanges(changes: SimpleChange): void {
		// console.log("ngOnChange called", changes, this.value);
		// this.updateSelectedItem();
	}

	select(item: DownlineTreeviewItem[]): void {
		this.treeviewFilter(item, true);
	}

	treeviewFilter(item, emit_event = false) {
		let customTreeData = "";
		let customTreeData_list = [];
		let temp_obj = {}
		this.allTreeData?.forEach((e, index) => {
			if (item && item?.value === e?.item?.value) {
				const item = e["item"];
				const value = item.value;
				item["id"] = this.id;
				const texts = [item?.text];
				temp_obj = {} 
				temp_obj["text"] = value
				temp_obj["value"] = item?.text
				customTreeData_list.push( temp_obj )
				let parentId = "";
				let parent = e?.parent;
				while (!isNil(parent)) {
					texts.push(parent?.item.text);
					let parent_text = parent?.item.text
					parentId = parent?.item.value;
					parent = parent?.parent;
					temp_obj = {} 
					temp_obj["text"] = parent_text
					temp_obj["value"] = parentId
					customTreeData_list.push( temp_obj )
				}
				const reverseTexts = reverse(texts);
				const row = `${reverseTexts.join(" / ")}`;
				customTreeData = row;
				item["parent"] = parentId;
			} else {
				// console.log("minaz",item['value'] === e['parent']['item'].value, item['value'] , e['parent']);
				if (item && item?.value && e?.parent && e?.parent?.item && item?.value === e?.parent?.item?.value) {
					const texts = [];
					let parent = e?.parent;
					while (!isNil(parent)) {
						texts.push(parent.item.text);
						temp_obj = {} 
						temp_obj["text"] = parent?.item?.text
						temp_obj["value"] = parent?.item?.value
						customTreeData_list.push( temp_obj )
						parent = parent?.parent;
					}
					const reverseTexts = reverse(texts);
					const row = `${reverseTexts.join(" / ")}`;
					customTreeData = row;
				} else if (
					item &&
					item?.value &&
					e?.parent?.parent &&
					e?.parent?.parent?.item &&
					item["value"] === e?.parent?.parent?.item?.value
				) {
					// console.log("else exe", item['text'] , item['internalChildren'][0].text,item['internalChildren'][0].value);
					// console.log(item['value'] === e['parent']['parent']['item'].value, e['parent']['parent']);
					let parent = e?.parent?.parent?.parent;
					if (parent) {
						customTreeData = parent.item.text + "/" + item["text"];
						temp_obj = {} 
						temp_obj["text"] = parent.item.text
						temp_obj["value"] = parent?.item?.value
						customTreeData_list.push( temp_obj )
						temp_obj = {} 
						temp_obj["text"] = item.text
						temp_obj["value"] = item?.value
						customTreeData_list.push( temp_obj )
					} else {
						customTreeData = item["text"];
						temp_obj = {} 
						temp_obj["text"] = item.text
						temp_obj["value"] = item?.value
						customTreeData_list.push( temp_obj )
					}
				}
			}
		});
		if (customTreeData) {
			this.selectedTreeData = customTreeData;
			if (emit_event) {
				item["full_path_text"] = this.selectedTreeData;
				item["sel_tree_view_data"] = customTreeData_list;
				this.itemChange.emit(item);
			}
		} else {
			this.selectedTreeData = this._translateService.instant("UI.k_select_option");
		}
		var iDiv = document.createElement("span");
		iDiv.id = "block" + this.id + item?.value;
		iDiv.className = "block text-truncate pb-25";
		document.getElementById(this.id).childNodes[0].childNodes[0].textContent = "  ";
		document.getElementById(this.id)?.childNodes[0]?.childNodes[0]?.appendChild(iDiv);
		document.getElementById("block" + this.id + item?.value).innerHTML = this.selectedTreeData;
		document.getElementById(this.id)?.childNodes[0]["classList"]?.remove("show");
		document.getElementById(this.id)?.childNodes[0]?.childNodes[1]["classList"]?.remove("show");
		if(document.getElementById("block" + this.id + item?.value).innerHTML === "Select Option"){
			document.getElementById("block" + this.id + item?.value).classList.add("placeholder-txt")
		}
	}

	// updateSelectedItem(): void {
	// 	if (!isNil(this.items)) {
	// 		const selectedItem = TreeviewHelper.findItemInList(this.items, this.value);
	// 		this.treeviewFilter(selectedItem);
	// 	}
	// }

	// private updateSelectedItem(): void {
	//   if (!isNil(this.items)) {
	//     const selectedItem = TreeviewHelper.findItemInList(this.items, this.value);
	//     this.selectItem(selectedItem);
	//   }
	// }

	// private selectItem(item: TreeviewItem): void {
	//   console.log("item........", item);
	//   // console.log("this.dropdownTreeviewSelectI18n.selectedItem ........", this.dropdownTreeviewSelectI18n.selectedItem);

	//   if (this.dropdownTreeviewSelectI18n.selectedItem !== item) {
	//     this.dropdownTreeviewSelectI18n.selectedItem = item;
	//     if (this.dropdownTreeviewComponent) {
	//       this.dropdownTreeviewComponent.onSelectedChange([item]);
	//     }

	//     if (item) {
	//       if (this.value !== item.value) {
	//         this.value = item.value;
	//         this.valueChange.emit(item.value);
	//       }
	//     }
	//   }
	// }

	setTextEvent(e) {
		const item = e["item"];
		const value = item.value;

		this.itemChange.emit(item);
		const texts = [item.text];
		let parent = e["parent"];
		while (!isNil(parent)) {
			texts.push(parent.item.text);
			parent = parent?.parent;
		}
		const reverseTexts = reverse(texts);
		const row = `${reverseTexts.join(" / ")}`;
		return row;
	}

	onSelectedChange(downlineItems: DownlineTreeviewItem[]): void {
		this.allTreeData = downlineItems;
		this.rows = [];
		downlineItems.forEach((downlineItem) => {
			const item = downlineItem.item;
			const value = item.value;
			const texts = [item.text];
			let parent = downlineItem?.parent;
			while (!isNil(parent)) {
				texts.push(parent.item.text);
				parent = parent?.parent;
			}
			const reverseTexts = reverse(texts);
			const row = `${reverseTexts.join(" / ")}`;
			this.rows.push(row);
		});
		if (!isNil(this.items)) {
			const selectedItem = TreeviewHelper.findItemInList(this.items, this.value);
			this.treeviewFilter(selectedItem);
		}
	}
}
