import { Injectable } from "@angular/core";
import { ApiService } from "app/common/services/api/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: 'root'
})
export class PolicyService extends ApiService{
	constructor(httpClient: HttpClient) {
		super(httpClient);
	}

	getPasswordPolicies(payload = {}) {
		return this.get("ux/common/user/policy/", payload);
	}

	savePasswordPolicy(payload: any) {
		return this.post("ux/common/user/policy/", payload);
	}

	getPasswordPolicy(id: Number) {
		return this.get("ux/common/user/policy/" + id.toString() + "/");
	}

	deletePasswordPolicy(id: Number) {
		return this.delete("ux/common/user/policy/" + id.toString() + "/");
	}

	fetchPasswordPolicy(payload: any){
		return this.get("ux/common/user/policy/fetch_policy/",payload)
	}

	getSavedFilter(payload) {
	    return this.get("ux/common/filters/filters/filter/", payload);
		//return this.get('ux/org/profile/filter/', payload)
	}


	getFilterOptions(payload: any) {
		return this.get("ux/common/filters/filters/filter_options/", payload);
	}
}
