<div class="slideout-content">
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
		<div class="modal-dialog sidebar-md">
			<div class="modal-content add-new-user py-0">
				<div class="modal-header d-flex align-items-center justify-content-between border-bottom">
					<div class="d-flex align-items-center">
						<h5 class="modal-title fw-600" id="exampleModalLabel">
							{{ (taskDataEditTemp.name ? "UI.k_edit" :"UI.k_add") | translate }} {{ "UI.k_task" | translate }}
						</h5>
					</div>
					<button
						type="button"
						data-dismiss="modal"
						id="btnClose"
						name="btnClose"
						aria-label="Close"
						(click)="toggleSidebar('create-task-sidebar')"
						class="btn btn-flat-secondary btn-icon"
						rippleEffect
						[attr.data-testid]="'btnClose'"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body flex-1 pl-1">
					<form #newTaskForm="ngForm" class="flex-auto-height">
						<ng-scrollbar class="custom-scrollbar section-scrollbar">
							<div class="modal-body-inner flex-1 py-1">
								<div class="row">
									<div class="col-sm-12">
										<div class="form-group">
											<div class="input-group mb-2">
												<div class="input-group-prepend">
													<span class="input-group-text" id="basic-addon1">Title*</span>
												</div>
												<input
													type="text"
													class="form-control"
													name="txtTitle"
													id="txtTitle"
													placeholder="Type title here"
													aria-label="txtTitle"
													aria-describedby="basic-addon1"
													required
													minlength="3"
													maxlength="40"
													[(ngModel)]="task_obj.name"
													[appWhiteSpaceCheck]="task_obj.name"
													#submitVlanNameInput="ngModel"
													[class.error]="submitVlanNameInput.invalid && newTaskForm.submitted"
												/>
												
											</div>
                                            <span
                                                class="invalid-form"
                                                *ngIf="newTaskForm.submitted && submitVlanNameInput.invalid"
                                            >
                                                <small
                                                    class="form-text text-danger"
                                                    *ngIf="submitVlanNameInput.errors.required"
                                                    >{{ "UI.k_field_required" | translate }}
                                                </small>
                                            </span>
                                            <span
                                                class="invalid-form"
                                                *ngIf="newTaskForm.submitted && submitVlanNameInput.invalid"
                                            >
                                                <small
                                                    class="form-text text-danger"
                                                    *ngIf="submitVlanNameInput.errors.minlength"
                                                >
                                                    {{ "Error.err_input_min_char" | translate }}
                                                </small>
                                            </span>
											<span class="invalid-form"
												*ngIf="newTaskForm.submitted && newTaskForm.invalid">
												<small class="form-text text-danger"
													*ngIf="newTaskForm.errors.whitespace">
													{{ "UI.k_space_validation" |
													translate }}
												</small>
											</span>
										</div>
									</div>
									<div class="col-sm-12">
										<div class="form-group">
											<div class="p-0 card border quill-card">
												<quill-editor
													[(ngModel)]="task_obj.description"
													id="qlDescription"
													name="qlDescription"
													#qlDescription
													[trimOnValidation]="true"
													[modules]="modules"
													[appQuillValidator]="qlDescription"
													[appQuillForm]="newTaskForm"
													placeholder="{{ 'UI.k_add_content' | translate }}"
													[minLength]="3"
													[class.error]="qlDescription?.errors && newTaskForm.submitted"
												>
												</quill-editor>
											</div>
											<div>
												<span
													class="row col-12 error"
													*ngIf="newTaskForm.submitted && qlDescription?.errors"
													class="invalid-form"
												>
													<small
														*ngIf="qlDescription?.errors?.minLengthError"
														class="form-text text-danger xs-font"
														>{{ "Error.err_min_3_character" | translate }}</small
													>
												</span>
											</div>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="form-group">
											<app-status-picker
												[inputConfig]="{ isTextMode: true, stateOptionsMap:  options?.state_id_map }"
												[options]="status"
												(setStatus)="onStatusChange($event)"
												[value]="task_obj?.status?.id"
											>
											</app-status-picker>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="form-group">
											<label class="form-label"> Priority </label>
											<app-select-badge
												[inputConfig]="{
													hideLabel: true,
													inputOptions: priority,
													id: 'priority',
													model: this.task_obj?.priority?.id
												}"
												(onValueChange)="statusBadgeChange($event)"
											>
											</app-select-badge>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="form-group">
											<label for="selAssignee" class="form-label">{{
												"UI.k_assignee" | translate
											}}</label>
											<ng-select
                                                [items]="users_list"
                                                [searchable]="true"
                                                bindLabel="full_name"
                                                [searchFn]="customSearchFn"
                                                bindValue="profile_id"
                                                name="selAssignee"
                                                id="selAssignee"
                                                placeholder="{{ 'UI.k_select' | translate }}"
                                                class=""
                                                [(ngModel)]="task_obj.assignee"
                                                (change)="onAssigneeChange($event)"
                                            >
                                                <ng-template
                                                    ng-option-tmp
                                                    let-item="item"
                                                    let-index="index"
                                                    let-search="searchTerm"
                                                >
                                                    <span class="d-flex">
                                                        <div *ngIf="item?.profile_image">
                                                            <div class="avatar mr-50 ml-25">
                                                                <span class="avatar-content">
                                                                    <img
                                                                        class="rounded-circle"
                                                                        src="{{ item.profile_image }}"
                                                                        alt="User"
                                                                        width="32"
                                                                        height="32"
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div *ngIf="!item?.profile_image">
                                                            <div
                                                                class="avatar mr-50 ml-25"
                                                                [ngStyle]="{
                                                                    'background-color': item?.avatar_color
                                                                        ? item?.avatar_color
                                                                        : '#f59518'
                                                                }"
                                                                style="
                                                                    align-content: center;
                                                                    width: 32px;
                                                                    height: 32px;
                                                                "
                                                            >
                                                                <div class="avatar-content">
                                                                    {{ item.full_name | initials | slice: 0:2 }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="cell-line-height">
                                                            <p
                                                                class="font-medium-1 font-weight-bold line-height-1 mb-0"
                                                            >
                                                                {{ item?.full_name | slice: 0:28 }}
                                                                {{ item?.full_name?.length > 28 ? ".." : "" }}
                                                            </p>
                                                            <span class="text-muted font-small-2">
                                                                {{ item?.email | slice: 0:28 }}
                                                                {{ item?.email.length > 28 ? ".." : "" }}</span
                                                            >
                                                        </div>
                                                    </span>
                                                </ng-template>
											</ng-select>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="form-group"  *ngIf="!flag">
											<label class="form-label"> {{ "UI.k_due_date" | translate }} </label>
											<form class="form-inline">
												<div class="form-group">
													<div class="input-group">
														<!-- <input
															class="form-control"
															placeholder="yyyy-mm-dd"
															name="Basic Date Picker"
															[(ngModel)]="task_obj.due_date"
															ngbDatepicker
															#basicDP="ngbDatepicker"
														/> -->
                                                        <ng2-flatpickr
                                                            #datepicker
                                                            [config]="basicDateTimeOptions"
                                                            name="Basic Date Picker"
                                                            id="txtDueDate"
                                                            [(ngModel)]="task_obj.due_date"
                                                            [setDate]="task_obj.due_date"
                                                            placeholder="{{'UI.k_placeholder_yyyy_mm_dd' | translate}}"
                                                        >
                                                        </ng2-flatpickr>
														<div class="input-group-append">
															<button
																class="btn btn-outline-secondary feather icon-calendar"
																(click)="datepicker.flatpickr.toggle()"
																type="button"
																rippleEffect
															></button>
														</div>
													</div>
												</div>
											</form>
										</div>
										<div  *ngIf="flag" >
											<div class="d-flex w-100 align-items-center">
												<div class="form-group w-100 custom-date-picker pr-50">
													<label for="dtDueDate" class="form-label pr-50">
														{{ 'UI.k_due_in_date' | translate
														}}</label>
													<span placement="right" ngbTooltip="{{
														'UI.k_due_date_days_task' | translate
													}}"><i
															class="fa-light fa-circle-exclamation"></i></span>
													<ng-select
														[items]="disableData"
														bindLabel="name" bindValue="id"
														id="auto_close_request"
														name="auto_close_request"
														[(ngModel)]="task_obj.due_date"
														[clearable]="false">
													</ng-select>
												</div>
												<span>{{"UI.k_days" | translate}}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</ng-scrollbar>
						<div class="d-flex justify-content-end border-top py-1 px-2 stepper-btn">
							<button
								(click)="toggleSidebar('create-task-sidebar')"
								class="btn btn-outline-tertiary btn-prev mr-1"
								rippleEffect
							>
								<span class="align-middle d-sm-inline-block d-none">
									{{ "UI.k_cancel" | translate }}
								</span>
							</button>
							<button
								*ngIf="
									!(change_data?.is_exit && change_data?.basic_info?.status?.state_id == 5) &&
									change_data.basic_info?.state?.id != 6
								"
								[disabled]="disableSaveBtn"
								type="submit"
								class="btn btn-primary btn-next"
								(click)="SaveTask(newTaskForm)"
								rippleEffect
								id="btnSubmit"
								name="btnSubmit"
								[attr.data-testid]="'btnSubmit'"
							>
								<i class="fa-light fa-floppy-disk align-middle mr-50 ml-0"></i>
								<span class="align-middle d-sm-inline-block d-none">{{
									"UI.k_submit" | translate
								}}</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
