<div
	*ngIf="scrollTable"
	class="table-responsive"
	style="
		width: 100% !important;
		height: 95% !important;
		position: absolute !important;
		overflow-x: auto !important;
		overflow-y: auto !important;
	"
>
	<!-- <ng-scrollbar class="custom-scrollbar section-scrollbar" track="all"> -->
	<table class="table">
		<thead>
			<tr>
				<th *ngIf="showSlno" class="simple-table-header" style="width: 40px">Sl.&nbsp;No.</th>
				<th class="simple-table-header" *ngFor="let col of tableObj.headers">
					<span
						*ngIf="col.label.includes('k_') || col.label.includes('msg_') || col.label.includes('err_')"
						>{{ getTranslatableKey(col.label) | translate }}</span
					>
					<span *ngIf="!col.label.includes('k_')">{{ col.label }}</span>
				</th>
			</tr>
		</thead>
		<tbody>
			<tr
				*ngFor="let row of tableObj.rows; odd as isOdd; even as isEven; index as indx"
				[ngClass]="{ 'even-row': isOdd, 'odd-row': isEven }"
			>
				<td *ngIf="showSlno" class="simple-table-cell">{{ indx + 1 }}</td>
				<td class="simple-table-cell" *ngFor="let col of tableObj.headers">
					<div *ngIf="tableObj.slaclickable">
						<a class="simple-data" (click)="showInciSlaSidebar(row, col, row[col.key] || 0)"
							><span [innerHTML]="row[col.key] || 0 | safe: 'html'"></span
						></a>
					</div>
					<div *ngIf="tableObj.requestSlaClickable">
						<a class="simple-data" (click)="showRequestSlaSidebar(row, col, row[col.key] || 0)"
							><span [innerHTML]="row[col.key] || 0 | safe: 'html'"></span
						></a>
					</div>
					<div *ngIf="tableObj.clickable">
						<a class="simple-data" (click)="showInciSidebar(row, col, row[col.key] || 0)"
							><span [innerHTML]="row[col.key] || 0 | safe: 'html'"></span
						></a>
					</div>
					<div *ngIf="tableObj.cmdbclickable">
						<a class="simple-data" (click)="showLocSidebar(row, col, row[col.key] || 0)"
							><span [innerHTML]="row[col.key] || 0 | safe: 'html'"></span
						></a>
					</div>
					<div
						*ngIf="!tableObj.clickable && !tableObj.slaclickable && !tableObj.requestSlaClickable && !tableObj.cmdbclickable"
						[innerHTML]="row[col.key] || '' | translate | safe: 'html'"
					></div>

					<!-- <a (click)="showInciSidebar(row[col.key])"><span [innerHTML]="(row[col.key] || '') | safe: 'html'"></span></a> -->
				</td>
			</tr>
		</tbody>
	</table>
	<!-- </ng-scrollbar> -->
</div>

<div *ngIf="!scrollTable" class="table-responsive">
	<!-- <div class="flex-auto-height">
		<div class="flex-1"> -->
	<!-- <ng-scrollbar class="custom-scrollbar section-scrollbar" track="all"> -->
	<table class="table">
		<thead>
			<tr>
				<th *ngIf="showSlno" class="simple-table-header" style="width: 40px">Sl.&nbsp;No.</th>
				<th class="simple-table-header" *ngFor="let col of tableObj.headers">
					<span
						*ngIf="col.label.includes('k_') || col.label.includes('msg_') || col.label.includes('err_')"
						>{{ getTranslatableKey(col.label) | translate }}</span
					>
					<span *ngIf="!col.label.includes('k_')">{{ col.label }}</span>
				</th>
			</tr>
		</thead>
		<tbody>
			<tr
				*ngFor="let row of tableObj.rows; odd as isOdd; even as isEven; index as indx"
				[ngClass]="{ 'even-row': isOdd, 'odd-row': isEven }"
			>
				<td *ngIf="showSlno" class="simple-table-cell">{{ indx + 1 }}</td>
				<td class="simple-table-cell" *ngFor="let col of tableObj.headers">
					<div [innerHTML]="row[col.key] || emptyData | safe: 'html'"></div>
				</td>
			</tr>
		</tbody>
	</table>
	<!-- </ng-scrollbar> -->
	<!-- </div>
	</div> -->
</div>
