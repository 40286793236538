
<ng-container *appDnsLicenseHandler="item?.license || {}">
	<ng-container *appIsGranted="item.permission">
		<ng-container *appFeatureActive="item.featureKeys">
			<!-- item with router url -->
			<a
				[id]="item.id"
				class="d-flex align-items-center"
				[ngClass]="item.classes"
				*ngIf="item.url && !item.externalUrl"
				[routerLink]="[item.url]"
				[target]="item.openInNewTab ? '_blank' : '_self'"
			>
				<ng-container *ngTemplateOutlet="itemContent"></ng-container>
			</a>

			<!-- item with external url -->
			<a
				[id]="item.id"
				class="d-flex align-items-center"
				[ngClass]="item.classes"
				*ngIf="item.url && item.externalUrl"
				[href]="item.url"
				[target]="item.openInNewTab ? '_blank' : '_self'"
			>
				<ng-container *ngTemplateOutlet="itemContent"></ng-container>
			</a>

			<ng-template #itemContent>
				<!--<span [data-feather]="item.icon" *ngIf="item.icon"></span>-->
				<i *ngIf="item.icon && item.position == 'sub'" class="fa-light fa-{{ item.icon }} fa-3x"></i>
				<!-- <lottie-player *ngIf="item.position!='sub'" class="lottyicon-left" src="../../../../../assets/images/lotty/{{item.icon}}.json"
		  background="transparent" speed="1" style="width: 24px; height: 24px;" hover>
	  </lottie-player> -->
				<div
					id="{{ item.icon }}-trigger"
					name="{{ item.icon }}-trigger"
					class="d-flex justify-content-center align-items-center"
				>
					<div
						*ngIf="item.position != 'sub'"
						style="width: 24px; height: 24px"
						background="transparent"
						speed="1"
						hover
					>
						<span id="{{ item.icon }}-container"></span>
					</div>

					<span class="menu-title text-truncate" [translate]="item.translate">
						<span *ngIf="item.position != 'sub'">&nbsp;&nbsp;</span>
						{{ item.title | translate }}</span
					>
					<span *ngIf="!onPremise && item?.subTitle" class="menu-title sub-title ml-25 text-primary fw-500"
						><sup> {{ item?.subTitle }}</sup></span
					>
				</div>
				<span
					class="badge ml-auto mr-1"
					*ngIf="item.badge"
					[translate]="item.badge.translate"
					[ngClass]="item.badge.classes"
				>
					{{ item.badge.title }}
				</span>
			</ng-template>
		</ng-container>
	</ng-container>
</ng-container>
