import { keyframes } from "@angular/animations";
import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, Validators, FormControl } from "@angular/forms";

@Directive({
    selector: '[appNumberCheck]',
    providers: [{provide: NG_VALIDATORS, useExisting: NumberValidator, multi: true}]
  })
  export class NumberValidator implements Validator {
    @Input('appNumberCheck') invalidnumber: '';

	validate(control: AbstractControl): ValidationErrors | [] {
		let numInput = control.value;
		let isValid = true;
		let pattern_us = "[0-9]$";
		if (typeof numInput == "string") {
			numInput = numInput.split(",").join(";").split(";");
		}
		if (numInput) {
			for (let id = 0; id < numInput?.length; id++) {
				if (numInput[id]?.name) {
					if (!numInput[id]?.name.match(pattern_us)) {
						return { invalidnumber: true };
					}
				} else {
					if (!numInput[id].toString().match(pattern_us)) {
						return { invalidnumber: true };
					}
				}
			}
			return isValid ? null : { invalidnumber: true };
		}
	}
}

