import { Component, OnInit, Input } from '@angular/core';
import { getAssetImageSrc, getImgSrcforType } from 'app/common/utils/utils';
import { NgEventBus } from "ng-event-bus";


@Component({
  selector: 'app-consumable-summary-widget',
  templateUrl: './consumable-summary-widget.component.html',
  styleUrls: ['./consumable-summary-widget.component.scss']
})
export class ConsumableSummaryWidgetComponent implements OnInit {
  @Input() widgetData: any;
	@Input() modifiedtime: any;

  constructor(
    private eventBus: NgEventBus
  ) { }

  ngOnInit(): void {
  }

  getImg(item) {
		return getAssetImageSrc(item.id, this.widgetData?.data?.groupby) 
	}
}
