import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-sms',
	templateUrl: './sms.component.html',
	styleUrls: ['./sms.component.scss']
})
export class SmsComponent implements OnInit {
	@Input() initOption: any = {};
    @Input() smsConfig: any = {};
	public msgPlaceholder: any = '{{specific_problem}}: {{hostname}}'

	constructor() { }

	ngOnInit(): void {
	}
}