//Utility methods
import { default as _map } from "lodash-es/map";
import moment from "moment";
import store from "store";
import CryptoJS from "crypto-js";
import { PhoneNumberUtil } from "google-libphonenumber";
import { environment } from "environments/environment";

const srcMap = require("app/base/asset/config/asset-image-src.json");
/***
 * handleViewEditPermission: this method check for edit/view based on given permission;
 * @param permissions: should be module permission object
 * @param positiveValue: can be any type based on requirement
 * @param otherValue: can be any type based on requirement
 * return values based on condition match
 */

export const handleViewEditPermission = (permissions, positiveValue, otherValue) => {
	if (permissions) {
		if (permissions?.edit && permissions?.view) {
			return positiveValue;
		} else if (permissions?.edit) {
			return positiveValue;
		}
	}
	return otherValue;
};

export const setStorageHash = (key, data, hash_name = "localStore") => {
	// To Do . change this to rxdx store in future
	if (data) {
		let storage = store.get(hash_name);
		if (!storage) storage = {};
		else storage = JSON.parse(store.get(hash_name));
		storage[key] = data;
		store.set(hash_name, JSON.stringify(storage));
	}
};

export const getStorageHash = (key, default_return = {}, hash_name = "localStore") => {
	// To Do . change this to rxdx store in future
	let storage = store.get(hash_name);
	if (!storage) return default_return;
	else {
		storage = JSON.parse(storage);
		return storage[key] || default_return;
	}
};

export const clearStore = (keys = []) => {
	keys.forEach((key) => {
		try {
			store.remove(key);
		} catch (e) {}
	});
};

export const getFileSizeText = (bytes, decimals = 2) => {
	//references: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
	if (bytes === 0) return "0 Bytes";
	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

/*
 * generate_uuid: generates and return unique id;
 * @param prefix: optional parameter, if provided unique id ll have the prefix given
 */
export const generate_uuid = (prefix?: string) => {
	let s4 = () => {
		// Math.random should be unique because of its seeding algorithm.
		// Convert it to base 36 (numbers + letters), and grab the first 9 characters
		return Math.random().toString(36).substring(2, 16);
		// return Math.floor((1 + Math.random()) * 0x10000)
		//     .toString(16)
		//     .substring(1);
	};
	if (prefix) {
		return prefix + "_" + s4() + "_" + s4();
	} else {
		return s4() + "_" + s4();
	}
	//return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
	// return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

// // function uuidv4() {
// let uuidv4 = () => {
//     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
//         var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
//         return v.toString(16);
//     });
// }

export const validateFile = (file: any, allowed_file_types = [], allowed_size = 20971520, type = false) => {
	const fileError = { is_valid: true };
	if (file && file?.name) {
		const name = file?.name;
		const ext = name?.substring(name.lastIndexOf(".") + 1);
		let file_type = null;
		if (type) {
			file_type = file?.type;
		}
		allowed_file_types = _map(allowed_file_types, (val) => (val.includes(".") ? val.replace(".", "") + "" : val));
		const file_size = file?.size;
		if (allowed_file_types.length > 0 || allowed_size) {
			if (!allowed_file_types.includes(type ? file_type : ext)) {
				fileError["name"] = name;
				fileError["is_valid"] = false;
				fileError["is_allowed"] = false;
				fileError["extension"] = ext;
			}

			if (file_size > allowed_size) {
				fileError["name"] = name;
				fileError["is_valid"] = false;
				fileError["size"] = file_size;
			}
		}
	}
	return fileError;
};

export const getTranslatableKey = (key: string) => {
	if (key && typeof key === "string") {
		if (key.startsWith("k_")) {
			key = `UI.${key}`;
		} else if (key.startsWith("msg_")) {
			key = `Message.${key}`;
		} else if (key.startsWith("err_")) {
			key = `Error.${key}`;
		}
	}
	return key;
};

var math_round = function (num, decimals) {
	return num.toFixed(decimals);
};

export const formatValue = (value, unit, with_out_unit = 0, fixed_decimal = 2) => {
	//console.log('unit >>', unit)
	if (value == undefined) return "";
	if (!unit) return value;
	// %% means value is from 0 - 1, but should be displayed as a percentage
	if (unit == "%%") {
		value = value * 100;
		unit = "%";
	}
	if (unit == "%") return math_round(value, fixed_decimal) + " %";
	if (unit == "hrs") {
		if (with_out_unit == 1) return math_round(value / 3600, 0);
		else return math_round(value / 3600, 0) + " Hrs";
	}
	if (unit == "mins") {
		if (with_out_unit == 1) return math_round(value / 60, 0);
		else return math_round(value / 60, 0) + " Mins";
	}
	if (unit == "outagesec") return value + " Sec"; // handle later
	let ret;
	if (unit == "timetick") {
		// Scale the number of seconds automatically:
		ret = makeTimeDelta(value);
		ret = ret.replaceAll(", 0 hr, 0 min, 0 sec", "");
		ret = ret.replaceAll(", 0 min, 0 sec", "");
		ret = ret.replaceAll(", 0 sec", "");
		// ret = ret.replaceAll('0 d, 0 hr, 0 min, 0 sec','')
		if (!ret) {
			ret = "0 sec";
		}
		return ret;
		//return value + ' ' + unit ; // handle later
	}
	if (unit == "time") {
		return moment(value).format("Do MMM - hh A");
	}
	let mfactor = 1024.0;
	let scaleNumber = unit == "bps";
	if (unit[0] == "*") {
		scaleNumber = true;
		unit = unit.slice(1);
	}
	if (unit == "*bytes") {
		mfactor = 1000.0;
		unit = unit.slice(1);
	}
	// let a = Math.abs(value)
	if (scaleNumber) {
		return bytesToSize(unit, value, mfactor, fixed_decimal);
	} else {
		if (Number.isInteger(value)) ret = value;
		else ret = math_round(value, fixed_decimal);
	}
	if (with_out_unit == 1) return ret;
	else return ret + " " + unit;
};

var makeTimeDelta = function (t: number) {
	// return time in x days, y hours, z mins, u seconds
	let out = "";
	let days = (t / 86400).toFixed(0);
	t = t % 86400;
	let hours = (t / 3600).toFixed(0);
	t = t % 3600;
	let mins = (t / 60).toFixed(0);
	t = t % 60;
	let secs = t.toFixed(0);
	let start = 0;
	if (days) {
		out = out + days + " d, ";
		start = 1;
	}
	if (hours || start) {
		out = out + hours + " hr, ";
		start = 1;
	}
	if (mins || start) {
		out = out + mins + " min, ";
		start = 1;
	}
	out = out + secs + " sec";
	return out;
};

export const bytesToSize = (unit, value, mfactor = 1000, decimals = 2) => {
	let sizes = [];
	if (unit == "bytes") {
		sizes = [unit, "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	} else {
		sizes = [unit, "K" + unit, "M" + unit, "G" + unit, "T" + unit, "P" + unit, "E" + unit, "Z" + unit, "Y" + unit];
	}
	if (value === 0) return "0 " + unit;
	let i = Math.floor(Math.log(Math.abs(value)) / Math.log(mfactor));
	i = i < 0 ? 0 : i;
	decimals = decimals < 0 ? 0 : decimals;
	return parseFloat((value / Math.pow(mfactor, i)).toFixed(decimals)) + " " + sizes[i];
};

//helper function for generatePalette function
const hexPaletteColor = function (color, percent) {
	let f = parseInt(color.slice(1), 16);
	let t = percent < 0 ? 0 : 255;
	let p = percent < 0 ? percent * -1 : percent;
	let R = f >> 16;
	let G = (f >> 8) & 0x00ff;
	let B = f & 0x0000ff;
	return (
		"#" +
		(
			0x1000000 +
			(Math.round((t - R) * p) + R) * 0x10000 +
			(Math.round((t - G) * p) + G) * 0x100 +
			(Math.round((t - B) * p) + B)
		)
			.toString(16)
			.slice(1)
	);
};

// used to generate color pallete for graphs
// colorPalleteArray = ["#5B9BD5", "#ED7D31", "#A5A5A5", "#FFC000", "#4472C4", "#70AD47", "#255E91", "#9E480E", "#636363", "#997300", "#264478", "#43682B"]
// percentageChange = 0.15
// paletteArray = generatePalette(colorPalleteArray[0], percentageChange, 12);
const generatePalette = function (color, percent, paletteCount) {
	let hexColor = color;
	let paletteColor = [hexColor];
	for (let i = 0; i < paletteCount - 1; i++) {
		hexColor = hexPaletteColor(hexColor, percent);
		paletteColor.push(hexColor);
	}
	return paletteColor;
};

export const getImgSrcforType = function (matchstr, srcType) {
	let src = srcMap[srcType] || {};
	matchstr = matchstr.toLowerCase();
	let img_path = src[matchstr];
	if (!img_path) {
		matchstr = matchstr.split(" ")[0];
		return src[matchstr] || "assets/images/asset/type/other.png";
	} else {
		return img_path;
	}
};

export const _deepCopyJson = function (data: any) {
	try {
		return JSON.parse(JSON.stringify(data));
	} catch (e) {
		return data;
	}
};
/*
 * getAssetImageSrc: return image path according to data;
 * @param asset_data: asset json
 * @param key: image type
 */
export const getAssetImageSrc = function (asset_data, key) {
	let src = "";
	if (typeof asset_data === "object" && asset_data !== null) {
		if (asset_data.hasOwnProperty(key)) {
			asset_data = asset_data[key];
		}
	}
	if (key == "device_type") {
		if (asset_data != undefined && asset_data != "") {
			if (srcMap["type"].hasOwnProperty(asset_data?.toLowerCase())) {
				src = srcMap["type"][asset_data?.toLowerCase()];
			}
		}
	} else if (key == "os_name") {
		if (/win/i.test(asset_data)) {
			asset_data = "windows";
		} else if (/mac/i.test(asset_data)) {
			asset_data = "mac";
		} else if (/oracle/i.test(asset_data)) {
			asset_data = "oraclelinux";
		} else if (/red/i.test(asset_data)) {
			asset_data = "redhat";
		} else if (/ios/i.test(asset_data)) {
			asset_data = "ciscoios";
		} else if (/centos/i.test(asset_data)) {
			asset_data = "centos";
		} else if (/ubuntu/i.test(asset_data)) {
			asset_data = "ubuntu";
		} else if (/vmware/i.test(asset_data)) {
			asset_data = "vmware";
		} else if (/linux/i.test(asset_data)) {
			asset_data = "linux";
		} else if (/brocade/i.test(asset_data)) {
			asset_data = "brocade";
		} else if (/dlink/i.test(asset_data)) {
			asset_data = "dlink";
		}
		if (asset_data != undefined && asset_data != "") {
			if (srcMap["operating_system"].hasOwnProperty(asset_data?.toLowerCase())) {
				src = srcMap["operating_system"][asset_data?.toLowerCase()];
			}
		}
	} else if (key == "make") {
		if (/vmware/i.test(asset_data)) {
			asset_data = "vmware";
		}
		if (/red/i.test(asset_data)) {
			asset_data = "redhat";
		}
		if (/dell/i.test(asset_data)) {
			asset_data = "dell";
		}
		if (/hewlett/i.test(asset_data)) {
			asset_data = "hp";
		}
		if (asset_data != undefined && asset_data != "") {
			if (srcMap["make"].hasOwnProperty(asset_data?.toLowerCase())) {
				src = srcMap["make"][asset_data?.toLowerCase()];
			}
		}
	} else if (key == "model") {
		src = getAssetImageSrc(asset_data, "device_type");
		if (src.endsWith("unknown.png")) {
			return getAssetImageSrc(asset_data, "make");
		} else return src;
	} else if (key == "processor") {
		if (asset_data != undefined && asset_data != "") {
			if (/i3/i.test(asset_data)) {
				asset_data = "inteli3";
			} else if (/i5/i.test(asset_data)) {
				asset_data = "inteli5";
			} else if (/i7/i.test(asset_data)) {
				asset_data = "inteli7";
			} else if (/pentium/i.test(asset_data)) {
				asset_data = "intelpentium";
			} else if (/xeon/i.test(asset_data)) {
				asset_data = "intelxeon";
			} else if (/celeron/i.test(asset_data)) {
				asset_data = "intelceleron";
			} else if (/intel/i.test(asset_data)) {
				asset_data = "intel";
			} else if (/ryzen/i.test(asset_data)) {
				asset_data = "ryzen5";
			} else if (/amd/i.test(asset_data)) {
				asset_data = "amd";
			} else if (/m1/i.test(asset_data) && /pro/i.test(asset_data)) {
				asset_data = "m1pro";
			} else if (/m1/i.test(asset_data) && /ultra/i.test(asset_data)) {
				asset_data = "m1ultra";
			} else if (/m1/i.test(asset_data) && /max/i.test(asset_data)) {
				asset_data = "m1max";
			} else if (/m1/i.test(asset_data)) {
				asset_data = "m1";
			} else if (/m2/i.test(asset_data)) {
				asset_data = "m2";
			}
			if (srcMap["processor"].hasOwnProperty(asset_data?.toLowerCase())) {
				src = srcMap["processor"][asset_data?.toLowerCase()];
			}
		}
	} else if (key == "topology") {
		if (asset_data != undefined && asset_data != "") {
			if (srcMap["topology"].hasOwnProperty(asset_data?.toLowerCase())) {
				src = srcMap["topology"][asset_data?.toLowerCase()];
			}
			// if (src == undefined || src == "") {
			// 	src = srcMap["topology"]["unknown"];
			// }
		}
		return src;
	}
	if (src == undefined || src == "") {
		src = srcMap["type"]["unknown"];
	}
	return src;
};

export const getElementByIDPromise = function (divID) {
	return new Promise((resolve) => {
		if (document.querySelector(divID)) {
			return resolve(document.getElementById(divID));
		}

		const observer = new MutationObserver((mutations) => {
			if (document.getElementById(divID)) {
				resolve(document.getElementById(divID));
				observer.disconnect();
			}
		});

		observer.observe(document.body, {
			childList: true,
			subtree: true,
		});
	});
};

export const customSearch = function (term: string, item: any, primaryKey: any, secondaryKey?: any) {
	term = term.toLowerCase();
	// Creating and array of space saperated term and removinf the empty values using filter
	const splitTerm = term.split(" ").filter((t) => t);

	let isWordThere = [];

	// Pushing True/False if match is found
	splitTerm.forEach((arr_term) => {
		let searchStatus = ""
		// check if key exist
		if (Object.keys(item).includes(primaryKey)){
			searchStatus = item[primaryKey]?.toLowerCase()|| "";
		}
		let searchState = ""
		if (Object.keys(item).includes(secondaryKey)){
			searchState = item[secondaryKey]?.toLowerCase() || "";
		}
		isWordThere.push(searchStatus.indexOf(arr_term) !== -1 || searchState.indexOf(arr_term) !== -1);
	});
	const all_words = (this_word) => this_word;
	// Every method will return true if all values are true in isWordThere.
	return isWordThere.every(all_words);
};

export const echartTypes = ["area", "line", "trend", "bar", "trendBar", "pie"];
export const echartDefaultColors = [
	"#298ECC",
	"#4FD66B",
	"#FFCA3F",
	"#FE9531",
	"#D32EE3",
	"#535AD2",
	"#FE4334",
	"#84BFFC",
	"#70DA94",
	"#FFE373",
	"#FFC26F",
	"#D185D8",
	"#8784D7",
	"#FE7C69",
	"#CAEEFB",
	"#9BDBAF",
	"#FFF1B1",
	"#FFE0B2",
	"#FFBEB2",
	"#B1AFDB",
	"#0B62A4",
	"#7A92A3",
	"#4DA74D",
	"#AFD8F8",
	"#EDC240",
	"#CB4B4B",
	"#9440ED",
	"#2EC7C9",
	"#B6A2DE",
	"#27727B",
	"#FFB980",
	"#D87A80",
	"#F4E001",
	"#C1232B",
	"#B5C334",
	"#FCCE10",
	"#E87C25",
	"#F3A43B",
	"#F0805A",
	"#FE8463",
	"#9BCA63",
	"#FAD860",
	"#5AB1EF",
	"#C6E579",
	"#26C0C0",
];

export const getDefaultEchartsOptions = function (widget, module) {
	let etype = widget?.data?.xAxis?.type;
	let ebartype = widget?.data?.yAxis?.type;
	let chart_unit = widget?.data?.chart_unit || "";
	let chart_colors = widget?.data?.chart_colors || echartDefaultColors;
	let time_format = widget?.data?.time_format || "Do MMM - hh:mm:ss A";
	let force_display_legend = widget?.force_display_legend || false;
	if (!widget?.data?.series) {
		//console.log("No Series data in widget - >", widget);
		return {};
	}
	let default_grid = {
		top: "25",
		bottom: "60",
		left: "80",
		right: "20",
	};
	let category_display_legend = false;
	if (module == "report") {
		category_display_legend = true;
		default_grid = {
			top: "25",
			bottom: "80",
			left: "80",
			right: "20",
		};
	}
	if (force_display_legend) {
		category_display_legend = true;
	}
	let split_line_color = "#f6f7fa";
	if (localStorage.getItem("themeColor") == "dark") {
		split_line_color = "#262f41";
	}
	let x_axis_splitLine = {
		show: false,
	};
	let y_axis_splitLine = {
		show: true,
		interval: "2",
		lineStyle: {
			color: split_line_color,
		},
	};

	let renderType = widget.renderType;
	if (!renderType || renderType == "echart") renderType = widget.render_type;
	if (renderType == "pie") {
		// let graph_options = {}
		let graph_options = Object.assign({}, widget.data);

		let tooltip = {
			trigger: "item",
			padding: 0,
			formatter: (series) => {
				// var content = '<div style="font-size:12px;font-family:Roboto;"><span style="display:inline-block;margin-right:5px;width:9px;height:9px;background-color:' + series.color + '"></span>' + series.name + '<BR>'+ word_wrap_fn(graph_title, 50) +':&nbsp;<strong>' + formatValue(series.value, chart_unit) + '</strong><BR>Percentage:&nbsp;<strong>'+ series.percent +'%&nbsp;</strong></div>'
				let content =
					`<div class="${
						localStorage.getItem("themeColor") == "dark" ? "bg-dark" : "bg-white"
					}"  style="padding:8px;font-size:13px;font-family:inherit;color:${
						localStorage.getItem("themeColor") == "dark" ? "#cfd6e5" : "#626262"
					}"><span style="display:inline-block;margin-right:5px;width:12px;height:12px;border-radius:4px;background-color:` +
					series.color +
					'"></span>' +
					series.name +
					"&nbsp;<BR> &nbsp;&nbsp;&nbsp;&nbsp;Value:&nbsp;<strong>" +
					formatValue(series.value, chart_unit) +
					"</strong></div>";
				return content;
			},
		};
		if (graph_options["legend"]) {
			if (!graph_options["legend"]["textStyle"]) {
				graph_options["legend"]["textStyle"] = {
					color: "#a2a3a56",
				};
			}
		}
		graph_options["tooltip"] = tooltip;
		let processed_p = 0;
		for (let ii = 0; ii < widget.data.series.length; ii++) {
			widget.data.series[ii]["label"] = { textStyle: { color: "#626262" } };
			widget.data.series[ii]["type"] = renderType;
			widget.data.series[ii]["connectNulls"] = true;
			processed_p = processed_p + 1;

			if (widget.data.series.length == processed_p) {
				graph_options["series"] = widget.data.series;
				// //console.log("pie graph_options >> ",graph_options)
				return graph_options;
			}
		}
		return graph_options;
	} else if (etype == "time" || etype == "timeseries") {
		let graph_options = {
			// title: { text: widget.title },
			grid: default_grid,
			legend: {
				show: true,
				bottom: 10,
				type: "scroll",
				orient: "horizontal",
				textStyle: {
					color: "#a2a3a5",
				},
			},
			color: chart_colors,
			tooltip: {
				trigger: "axis",
				padding: 0,
				formatter: (series) => {
					if (series.componentType == "markLine") {
						return (
							'<div style="font-size:13px;font-family:inherit;"><span style="display:inline-block;margin-right:5px;width:9px;height:9px;background-color:' +
							series.data.lineStyle.normal.color +
							'"></span>' +
							series.data.name +
							"&nbsp;<BR> &nbsp;&nbsp;&nbsp;&nbsp;Value:&nbsp;<strong>" +
							formatValue(series.data.value, chart_unit) +
							"</strong></div>"
						);
					}
					let content =
						`<div class="${
							localStorage.getItem("themeColor") == "dark" ? "bg-dark" : "bg-white"
						}" style="padding:8px;font-size:13px;font-family:inherit;color:${
							localStorage.getItem("themeColor") == "dark" ? "#cfd6e5" : "#626262"
						}"><span style="font-weight:600;">Time : ` +
						moment(series[0]["data"][0]).format(time_format) +
						"</span></BR>";
					series.forEach((d) => {
						content =
							content +
							'<span style="display:inline-block;margin-right:5px;width:12px;height:12px;border-radius:4px;background-color:' +
							d.color +
							'"></span>' +
							d.seriesName +
							'<span style="font-weight:600;"> : ' +
							formatValue(d["data"][1], chart_unit) +
							"</span><br>";
					});
					return content + "</div>";
				},
				axisPointer: {
					animation: false,
				},
			},
			xAxis: {
				type: "time",
				axisLabel: {
					show: true,
					interval: "auto",
					hideOverlap: true,
				},
				axisLine: {
					lineStyle: {
						color: "#a2a3a5",
					},
				},
			},
			yAxis: {
				type: "value",
				axisLabel: {
					show: true,
					interval: "auto",
				},
				axisLine: {
					show: false,
					lineStyle: {
						color: "#a2a3a5",
					},
				},
				splitLine: {
					lineStyle: {
						color: "#ebe9f1",
					},
				},
			},
			series: [],
		};
		if (widget.data.xAxis) {
			Object.assign(graph_options["xAxis"], widget.data.xAxis);
		}
		graph_options["xAxis"]["splitLine"] = x_axis_splitLine;

		if (widget.data.yAxis) {
			Object.assign(graph_options["yAxis"], widget.data.yAxis);
		}
		graph_options["yAxis"]["splitLine"] = y_axis_splitLine;
		if (widget.data.legend) {
			Object.assign(graph_options["legend"], widget.data.legend);
		}
		if (!widget.data.yAxis?.axisLabel?.formatter) {
			graph_options.yAxis.axisLabel["formatter"] = function (value) {
				return formatValue(value, chart_unit, 0, 0);
			};
		}
		let processed_t = 0;
		for (let jj = 0; jj < widget.data.series.length; jj++) {
			widget.data.series[jj]["connectNulls"] = true;
			widget.data.series[jj]["smooth"] = true;
			if (renderType == "area") {
				widget.data.series[jj]["type"] = "line";
				widget.data.series[jj]["itemStyle"] = { normal: { areaStyle: { type: "default" } } };
			} else {
				widget.data.series[jj]["type"] = renderType;
				if (renderType == "bar") {
					widget.data.series[jj]["itemStyle"] = { borderRadius: [5, 5, 0, 0] };
				}
			}
			processed_t = processed_t + 1;
			if (widget.data.series.length == processed_t) {
				graph_options["series"] = widget.data.series;
				//console.log("timeseries graph_options >> ",JSON.stringify(graph_options, null, 2))
				return graph_options;
			}
		}
	} else if (etype == "category") {
		let graph_options = {
			grid: default_grid,
			legend: {
				show: category_display_legend,
				bottom: 10,
				type: "scroll",
				orient: "horizontal",
				textStyle: {
					color: "#a2a3a5",
				},
			},
			color: chart_colors,
			tooltip: {
				trigger: "axis",
				padding: 0,
				formatter: (series) => {
					let tcontent = `<div class="${
						localStorage.getItem("themeColor") == "dark" ? "bg-dark" : "bg-white"
					}" style="padding:8px;font-size:13px;font-family:inherit;color:${
						localStorage.getItem("themeColor") == "dark" ? "#cfd6e5" : "#626262"
					}">`;
					// Added xaxis label
					if (widget?.data.series[0]?.dataType == "bar_stack") {
						tcontent =
							tcontent + '<span style="font-size:13px;margin-bottom:5px">' + series[0]?.name + "</span>";
					}
					series.forEach((d) => {
						if (d.value != undefined) {
							tcontent =
								tcontent +
								'<div style="font-size:13px;font-family:inherit;"><span style="display:inline-block;margin-right:5px;width:12px;height:12px;border-radius:4px;background-color:' +
								d.color +
								'"></span>' +
								d.name +
								"<br>" +
								(d.seriesName.startsWith("series") ? d.name : d.seriesName) +
								"&nbsp;:&nbsp;<strong>" +
								formatValue(d.value, chart_unit) +
								"</strong><br>";
						}
					});
					return tcontent + "</div>";
				},
			},
			xAxis: {
				type: "time",
				axisLabel: {
					show: true,
					interval: "0",
					hideOverlap: true,
				},
				axisLine: {
					lineStyle: {
						color: "#a2a3a5",
					},
				},
			},
			yAxis: {
				type: "value",
				axisLabel: {
					show: true,
					interval: "auto",
				},
				axisLine: {
					show: false,
					lineStyle: {
						color: "#a2a3a5",
					},
				},
				splitLine: {
					lineStyle: {
						color: "#ebe9f1",
					},
				},
			},
		};
		if (widget.data.xAxis) {
			Object.assign(graph_options["xAxis"], widget.data.xAxis);
		}
		graph_options["xAxis"]["splitLine"] = x_axis_splitLine;
		if (widget.data.yAxis) {
			Object.assign(graph_options["yAxis"], widget.data.yAxis);
		}
		graph_options["yAxis"]["splitLine"] = y_axis_splitLine;
		if (widget.data?.legend?.show && widget.data?.legend?.data) {
			graph_options["legend"]["show"] = true;
			graph_options["legend"]["data"] = widget.data?.legend?.data;
			// Object.assign(graph_options["legend"], widget.data.legend);
		}
		if (widget.data.xAxis?.axisLabel?.formatter) {
			graph_options.xAxis.axisLabel["formatter"] = function (value) {
				return value.slice(0, 8) + "....";
			};
		}
		if (!widget.data.yAxis?.axisLabel?.formatter) {
			graph_options.yAxis.axisLabel["formatter"] = function (value) {
				return formatValue(value, chart_unit, 0, 0);
			};
		}
		let processed_c = 0;
		for (let kk = 0; kk < widget.data.series.length; kk++) {
			widget.data.series[kk]["connectNulls"] = true;
			widget.data.series[kk]["smooth"] = true;
			if (renderType == "area") {
				widget.data.series[kk]["type"] = "line";
				widget.data.series[kk]["itemStyle"] = { normal: { areaStyle: { type: "default" } } };
			} else {
				widget.data.series[kk]["type"] = renderType;
				if (renderType == "bar") {
					widget.data.series[kk]["itemStyle"] = { borderRadius: [5, 5, 0, 0] };
				}
			}
			if (renderType === "pie") {
				widget.data.series[kk]["label"] = { textStyle: { color: "#626262" } };
			}
			processed_c = processed_c + 1;

			if (widget.data.series.length == processed_c) {
				graph_options["series"] = widget.data.series;
				// console.log("category graph_options >> ",JSON.stringify(graph_options, null, 2))
				return graph_options;
			}
		}
	} else if (ebartype == "category") {
		let graph_options = {
			grid: {
				top: '25',
				bottom: '60',
				left: '120',
				right: '20'
			},
			legend: {
				show: category_display_legend,
				bottom: 10,
				type: "scroll",
				orient: "horizontal",
				textStyle: {
					color: "#a2a3a5",
				},
			},
			color: chart_colors,
			tooltip: {
				trigger: "axis",
				axisPointer: {
					type: 'shadow'
				},
				confine: true
			},
			xAxis: {
				type: "time",
				axisLabel: {
					show: true,
					interval: "0",
					hideOverlap: true,
				},
				axisLine: {
					lineStyle: {
						color: "#a2a3a5",
					},
				},
			},
			yAxis: {
				type: "value",
				axisLabel: {
					show: true,
					interval: "auto",
				},
				axisLine: {
					show: false,
					lineStyle: {
						color: "#a2a3a5",
					},
				},
				splitLine: {
					lineStyle: {
						color: "#ebe9f1",
					},
				},
			},
		};
		if (widget.data.xAxis) {
			Object.assign(graph_options["xAxis"], widget.data.xAxis);
		}
		let x_axis_splitLine = {
			show: true,
		};
		let y_axis_splitLine = {
			show: false,
			interval: "2",
			lineStyle: {
				color: split_line_color,
			},
		};
		graph_options["xAxis"]["splitLine"] = x_axis_splitLine;
		if (widget.data.yAxis) {
			Object.assign(graph_options["yAxis"], widget.data.yAxis);
		}
		graph_options["yAxis"]["splitLine"] = y_axis_splitLine;
		if (widget.data?.legend?.show && widget.data?.legend?.data) {
			graph_options["legend"]["show"] = true;
			graph_options["legend"]["data"] = widget.data?.legend?.data;
			// Object.assign(graph_options["legend"], widget.data.legend);
		}
		if (widget.data.yAxis?.axisLabel?.formatter) {
			graph_options.yAxis.axisLabel["formatter"] = function (value) {
				return value.slice(0, 8) + "....";
			};
		}
		// if (!widget.data.xAxis?.axisLabel?.formatter) {
		// 	graph_options.xAxis.axisLabel["formatter"] = function (value) {
		// 		return formatValue(value, chart_unit, 0, 0);
		// 	};
		// }
		let processed_c = 0;
		for (let kk = 0; kk < widget.data.series.length; kk++) {
			widget.data.series[kk]["connectNulls"] = true;
			widget.data.series[kk]["smooth"] = true;
			if (renderType == "area") {
				widget.data.series[kk]["type"] = "line";
				widget.data.series[kk]["itemStyle"] = { normal: { areaStyle: { type: "default" } } };
			} else {
				widget.data.series[kk]["type"] = renderType;
				if (renderType == "bar") {
					widget.data.series[kk]["itemStyle"] = { borderRadius: [0, 0, 0, 0] };
				}
			}
			if (renderType === "pie") {
				widget.data.series[kk]["label"] = { textStyle: { color: "#626262" } };
			}
			processed_c = processed_c + 1;

			if (widget.data.series.length == processed_c) {
				graph_options["series"] = widget.data.series;
				// console.log("category graph_options >> ",JSON.stringify(graph_options, null, 2))
				return graph_options;
			}
		}
	} else {
		return {};
	}
};

export const encryptData = function (data: any) {
	let iv = "d99630bcc7058639e7e80df239f38a32";
	let ciphertext = CryptoJS.AES.encrypt(data, iv).toString();
	let aesData = iv + "::" + ciphertext;
	return btoa(aesData);
};

export const decryptData = function (data: any) {
	let parts = atob(data).split("::");
	let bytes = CryptoJS.AES.decrypt(parts[1], parts[0], {
		iv: parts[0],
	});
	return bytes.toString(CryptoJS.enc.Utf8);
};

export const validatePhoneNumber = (phone_number_obj: any) => {
	if (phone_number_obj) {
		const util = PhoneNumberUtil.getInstance({});
		try {
			const tempNumber = util.parse(phone_number_obj.number, phone_number_obj.countryCode);
			if (util.isValidNumberForRegion(tempNumber, phone_number_obj.countryCode)) {
				return true;
			}
		} catch (e: any) {}
	}
	return false;
};

export const isValidDateRange = (startDate, endDate) => {
	let duration = undefined;
	if (endDate && startDate) {
		const startTime = moment(
			startDate.year.toString() + "-" + startDate.month.toString() + "-" + startDate.day.toString(),
			"YYYY-MM-DD"
		);
		const endTime = moment(
			endDate.year.toString() + "-" + endDate.month.toString() + "-" + endDate.day.toString(),
			"YYYY-MM-DD"
		);
		duration = moment.duration(endTime.diff(startTime));
		if (duration !== undefined && duration.asSeconds() > 0) {
			return true;
		} else {
			return false;
		}
	}
	return undefined;
};

export const getIntersectionOfArray = (array1: Array<string | number>, array2: Array<string | number>) => {
	const set1 = new Set(array1);
	const set2 = new Set(array2);
	let intersection = [];
	for (let i of set2) {
		if (set1.has(i)) {
			intersection.push(i);
		}
	}
	return intersection;
};

export const convertToCalendarTime = (dateTime) => {
	let converted_dateTime = dateTime;
	let temp_datetime = dateTime;
	try {
		const userParams = JSON.parse(localStorage.getItem("userParams"));
		let timezone_offset = 0;
		if (userParams && userParams["user_tz"]) {
			timezone_offset = userParams["timezone_offset_str"];
		}
		if (temp_datetime) {
			let conv_date =
				temp_datetime.getFullYear() +
				"-" +
				(temp_datetime.getMonth() + 1) +
				"-" +
				temp_datetime.getDate() +
				" " +
				temp_datetime.getHours() +
				":" +
				temp_datetime.getMinutes() +
				":00" +
				" GMT" +
				timezone_offset;
			converted_dateTime = new Date(conv_date);
		}
		return converted_dateTime;
	} catch (e) {
		//console.log(e);
		return dateTime;
	}
};

export const decryptAngularData = (data: string): any => {
	const Base64CBC = data.replace('"', "").replace('"', "");
	const iv = CryptoJS.enc.Utf8.parse("Sa2017GO2021AR86");
	let key = "d99630sam7058639"; //key used in Python
	key = CryptoJS.enc.Utf8.parse(key);
	const decrypted = CryptoJS.AES.decrypt(Base64CBC, key, { iv: iv, mode: CryptoJS.mode.CBC });
	const decrypted_data = decrypted.toString(CryptoJS.enc.Utf8);
	return decrypted_data;
};

export const removeAndReorder = <T>(list: T[], removedIndex: number, seqKey = "level"): T[] => {
	const result = Array.from(list);
	const [removed] = result.splice(removedIndex, 1);
	let removed_seq = removed?.[seqKey];
	if (list.length - 1 != removedIndex) {
		for (let i = removedIndex; i < result.length; i++) {
			result[i][seqKey] = removed_seq;
			removed_seq++;
		}
	}
	return result;
};

/**
 * This method will return max value from array of object on given search key,
 * if given search key is not defined then it will return 0
 * @param list
 * @param searchKey
 */
export const getMaxValue = <T>(list: T[], searchKey: string) => {
	let max = list.reduce(function (prev, current) {
		return prev && prev[searchKey] > current[searchKey] ? prev : current;
	});
	return max[searchKey] ? max[searchKey] : 0;
};

export const decryptRequesterList = (data: any[]): any[] => {
    const decryptKeyList = new Set(["full_name", "email", "name"]);
    return data.map((item: any) => {
        const newItem: any = { ...item };
        decryptKeyList.forEach(key => {
            if (item[key]) {
                try {
                    newItem[key] = JSON.parse(decryptAngularData(item[key]));
                } catch (error) {
                    newItem[key] = item[key];
                }
            }
        });
        return newItem;
    });
};

export const decryptRequester = (data: any): any => {
    if (data?.requester?.full_name){
		data['requester']['full_name'] = JSON.parse(decryptAngularData(data?.requester?.full_name))
	}
	if (data?.requester?.email){
		data['requester']['email'] = JSON.parse(decryptAngularData(data?.requester?.email))
	}
	return data
};

export const mask_requester_email = (email: string): any => {
	 const [localPart, domain] = email.split('@');
	 const maskedLocalPart = localPart.slice(0, 3) + '******';
	 const domainParts = domain.split('.');
	 const maskedDomain = '***.' + domainParts.slice(1).join('.');
	 return maskedLocalPart + '@' + maskedDomain;
};

export const mask_requester_name = (name: string): any => {
	return name.slice(0, 3) + '******';
};

export const constructMediaDownloadPath = (url: string): any => {
	console.log("constructMediaDownloadPath | SRC Url", url);
	if (url.includes("media")) {
		/* example
		file path - /media/downloads//<org-id>/CSV/asset_network_96_28_Oct_24_12_32.csv
		url path - http://localhost:9090/media/downloads//<org-id>/CSV/asset_network_96_28_Oct_24_12_32.csv
		*/
		let ret = url.replace(/^.*media/, environment.instance_url + "media");
		console.log("constructMediaDownloadPath | Return Url [0]", ret);
		return ret;
	} else if (url.toString().startsWith("http")) {
		// example http://localhost:9090/downloads//<org-id>/CSV/asset_network_96_28_Oct_24_12_32.csv
		console.log("constructMediaDownloadPath | Return Url [1]", url);
		return url;
	}
};