import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class ChargebeeService {
	_window?: any;

	constructor(@Inject(DOCUMENT) private document: Document) {
		this._window = this.document.defaultView;
	}

	public get chargebee(): any {
		return this._window["Chargebee"].getInstance();
	}

	initChargebee(publishableKey: string, site: string): void {
		// 'infraoncorp-test'
		this._window["Chargebee"].init({
			site,
			publishableKey,
		});
	}
}
