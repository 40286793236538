import { Injectable } from "@angular/core";
import { ApiService } from "app/common/services/api/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class BotConfigService extends ApiService {
	constructor(httpClient: HttpClient) {
		super(httpClient);
	}

	getBotList(payload = {}) {
		return this.get("ux/common/bots/bot_config/", payload);
	}
	getBotConfigDetails(payload = {}, id){
		return this.get("ux/common/bots/bot_config/" + id + "/", payload);
	}
	getBotConfigOptions(payload={}) {
		return this.get("ux/common/bots/bot_config/botconfig_options/", payload);
	}
	// validateBotDocs(payload={}){
	// 	return this.post("ux/common/bots/validate_botdocs/", payload);
	// }
	saveBotConfig(payload={}){
		return this.post("ux/common/bots/bot_config/", payload);
	}
	getBotResponse(payload={}){
		return this.post("ux/common/bots/bot_config/bot_response/", payload);
	}
	deleteBotConfig(id){
		return this.delete("ux/common/bots/bot_config/" + id + "/"); 
	}
}
