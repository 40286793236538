import { Component, EventEmitter, Input, OnInit, Output,ViewEncapsulation } from '@angular/core';
import { IncidentViewService } from "app/servicedesk/incident-view/services/incident-view.service";
import { TranslateService } from "@ngx-translate/core";
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { InfinityModuleIDs } from "app/common/dns/types/module_type";
import { InfinityModules } from "app/common/dns/types/modules";
import { catchError, map, takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import { GlobalConstants } from "app/app.globalConstants";

@Component({
  selector: 'app-process-relation',
  templateUrl: './process-relations.component.html',
  styleUrls: ['./process-relations.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProcessRelationComponent implements OnInit {
	@Output("onHideRelation") onHideRelation = new EventEmitter<any>();
	@Input() process_data: any;
	@Input() options;
	@Input() permissions;
	@Input() module: any;
	@Input() module_id: any;
	relationModuleIDSel : any;
	showAddRelation = false;
	relations: any = [];
	allRelations: any = [];
	mapping: any = {};
	incident_module_id = InfinityModuleIDs?.INCIDENT;;
	request_module_id = InfinityModuleIDs?.REQUEST_PROCESS;
	change_module_id = InfinityModuleIDs?.CHANGE;
	kb_module_id = InfinityModuleIDs?.KNOWLEDGEBASE;
	problem_module_id = InfinityModuleIDs?.PROBLEM;
	release_module_id = InfinityModuleIDs?.RELEASE;
	process_id: any;
	userTZ: any;
	process_key: any;
	relationModule: any;
	infinity_modules = InfinityModules;
	change_permissions: any;
	request_permissions: any;
	problem_permissions: any;
	release_permissions: any;
	incident_permissions: any;
	disable_config:boolean = false;
	process_key_mapping = {
		[InfinityModules.INCIDENT]: 'incident_id',
		[InfinityModules.PROBLEM]: 'problem_id',
		[InfinityModules.CHANGE]: 'change_id',
		[InfinityModules.REQUEST_PROCESS]: 'request_id',
		[InfinityModules.RELEASE]: 'release_id'
	  }
	source_id_mapping = {
		[InfinityModules.INCIDENT]: 'incident_source_id',
		[InfinityModules.PROBLEM]: 'problem_source_id',
		[InfinityModules.CHANGE]: 'change_source_id',
		[InfinityModules.REQUEST_PROCESS]: 'request_source_id',
		[InfinityModules.RELEASE]: 'release_source_id'
	}
	source_name_mapping = {
		[InfinityModules.INCIDENT]: 'incident_source_name',
		[InfinityModules.PROBLEM]: 'problem_source_name',
		[InfinityModules.CHANGE]: 'change_source_name',
		[InfinityModules.REQUEST_PROCESS]: 'request_source_name',
		[InfinityModules.RELEASE]: 'release_source_name'
	}
	sourceIconMap = {
		1: "globe",
		2: "envelope",
		3: "bell",
		4: "mobile",
		5: "phone",
		6: "comment-lines",
		7: "comment-dots",
		8: "microsoft"
	};
	constructor(
		private _service: IncidentViewService,
		private _translateService: TranslateService,
		private _coreSidebarService: CoreSidebarService,
		private _router: Router
		) { 
		
	}

	ngOnInit(): void {
		this.relationModuleIDSel = this.module_id;
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.release_permissions = user_permissions[InfinityModules?.RELEASE];
		this.change_permissions = user_permissions[InfinityModules.CHANGE]
		this.request_permissions = user_permissions[InfinityModules?.REQUEST_PROCESS];
		this.problem_permissions = user_permissions[InfinityModules.PROBLEM];
		this.incident_permissions = user_permissions[InfinityModules.INCIDENT];
		this.process_key = this.process_key_mapping?.[this.module]
		this.process_id = this.process_data?.[this.process_key]
		if (
			[6, 7].includes(this.process_data?.state_id) ||
			this.process_data?.basic_info?.status?.is_pir
		) {
			this.disable_config = true;
		}
		this.getRelations(this.module)
	}
	getRelations = (module) =>{
		if (this.module == InfinityModules?.INCIDENT) this.getIncidentRelations(module)
		else if (this.module == InfinityModules?.PROBLEM) this.getProblemRelations(module)
		else if (this.module == InfinityModules?.CHANGE) this.getChangeRelations(module)
		else if (this.module == InfinityModules?.REQUEST_PROCESS) this.getRequestRelations(module)
		else if (this.module == InfinityModules?.RELEASE) this.getReleaseRelations(module)
		else {
			//
		}
	}
	getIncidentRelations = (relId) => {
		const payload = {
			relation_id: relId,
			ref_id: this.process_id,
		};
		this._service
			.processIncidentRelations(payload)
			.pipe(
				map((response: any) => {
					this.allRelations = response?.process_relation_data;
					this.filterRelations()
					this.mapping = response?.relation_mapping;
				})
			)
			.subscribe();
	};
	getProblemRelations = (relId) => {
		const payload = {
			relation_id: relId,
			ref_id: this.process_id,
		};
		//change to probelm url
		this._service
			.getProblemRelations(payload)
			.pipe(
				map((response: any) => {
					this.allRelations = response?.process_relation_data;
					this.filterRelations();
					this.mapping = response?.relation_mapping;
				})
			)
			.subscribe();
	};
	getChangeRelations = (relId) => {
		const payload = {
			relation_id: relId,
			ref_id: this.process_id,
		};
		//change to probelm url
		this._service
			.getChangeRelations(payload)
			.pipe(
				map((response: any) => {
					this.allRelations = response?.process_relation_data;
					this.filterRelations();
					this.mapping = response?.relation_mapping;
				})
			)
			.subscribe();
	};
	getRequestRelations = (relId) => {
		const payload = {
			relation_id: relId,
			ref_id: this.process_id,
		};
		//change to probelm url
		this._service
			.getRequestRelations(payload)
			.pipe(
				map((response: any) => {
					this.allRelations = response?.process_relation_data;
					this.filterRelations();
					this.mapping = response?.relation_mapping;
				})
			)
			.subscribe();
	};
	getReleaseRelations = (relId) => {
		const payload = {
			relation_id: relId,
			ref_id: this.process_id,
		};
		//change to probelm url
		this._service
			.getReleaseRelations(payload)
			.pipe(
				map((response: any) => {
					this.allRelations = response?.process_relation_data;
					this.filterRelations();
					this.mapping = response?.relation_mapping;
				})
			)
			.subscribe();
	};
	addRelations = (relationModule) => {
		this.relationModule = relationModule;
		this._coreSidebarService.getSidebarRegistry("app-associate").open();
		this.showAddRelation = true;
	};
	onHideAssociateRelations = ($event) => {
		this._coreSidebarService.getSidebarRegistry('app-associate').close();
		this.showAddRelation = false;
	};
	toggleSidebar() {
    	this.onHideRelation.emit();
	}
	moveToEdit(rel) {
		let related_process = this.mapping[rel?.parent_ref_id == this.process_id ? rel?.child_ref_id : rel?.parent_ref_id]
		let related_process_id = related_process?.[this.process_key]
		if (this.module == InfinityModules?.INCIDENT) return "/incident_view/incident-edit/" + related_process_id
		else if (this.module == InfinityModules?.PROBLEM) return "/problem_management/problem-edit/" + related_process_id
		else if (this.module == InfinityModules?.CHANGE) return"/change/edit/" + related_process_id
		else if (this.module == InfinityModules?.REQUEST_PROCESS) return "/request_list/request-edit/" + related_process_id
		else if (this.module == InfinityModules?.RELEASE) return "/rel/edit/" + related_process_id
		else {
			//
		}
		
	}
	checkInterModuleAssociation = (reln, inter_module_id) => {
		if(reln?.parent_module_id == inter_module_id || reln?.child_module_id == inter_module_id){
			if (inter_module_id != this.module_id) return true
		}
	}
	checkSourceName = (relationMapping, req, parent_id, module_id)=>{
		if (req?.parent_module_id == module_id && req?.child_module_id == module_id){
			if (relationMapping[req?.parent_ref_id == parent_id ? req?.child_ref_id : req?.parent_ref_id]?.incident_source_name) return true
		}
		else if(req?.parent_module_id == this.request_module_id || req?.child_module_id == this.request_module_id){
			if (relationMapping[req?.parent_ref_id == parent_id ? req?.child_ref_id : req?.parent_ref_id]?.request_source_name) return true
		}else if(req?.parent_module_id == this.problem_module_id || req?.child_module_id == this.problem_module_id){
			if (relationMapping[req?.parent_ref_id == parent_id ? req?.child_ref_id : req?.parent_ref_id]?.problem_source_name) return true
		}else if(req?.parent_module_id == this.change_module_id || req?.child_module_id == this.change_module_id){
			if (relationMapping[req?.parent_ref_id == parent_id ? req?.child_ref_id : req?.parent_ref_id]?.change_source_name) return true
		}else if(req?.parent_module_id == this.release_module_id || req?.child_module_id == this.release_module_id){
			if (relationMapping[req?.parent_ref_id == parent_id ? req?.child_ref_id : req?.parent_ref_id]?.release_source_name) return true
		}
	}
	relationFilter(id) {
		this.relationModuleIDSel = id;
		this.filterRelations()
	}
	filterRelations = () =>{
		this.relations = this.allRelations?.filter(
			(relation) =>
				relation.parent_module_id !== this.kb_module_id &&
				relation.child_module_id !== this.kb_module_id &&
					((relation?.parent_module_id == this.relationModuleIDSel && relation?.child_module_id == this.relationModuleIDSel) || 
				(this.module_id != this.relationModuleIDSel  && 
				(relation?.parent_module_id == this.relationModuleIDSel || relation?.child_module_id == this.relationModuleIDSel)))
		);
	}


}
