import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { SelectPickerFeatureService } from "app/base/select-picker-feature/services/select-picker-feature.service";
import * as _ from "lodash";
import { _deepCopyJson } from "../../../utils/utils";

@Component({
	selector: "app-status-picker",
	templateUrl: "./status-picker.component.html",
	styleUrls: ["./status-picker.component.scss"],
})
export class StatusPickerComponent implements OnInit, OnChanges {
	//   optionList: any;
	@Input() inputConfig: any;
	@Input() options: any = [];
	status_options: any = [];
	@Input() value: any = null;
	@Input() isclearable = false;
	@Input() showLabel = true;
	@Input() exclude_option = [];
	@Input() exclude_option_compare_key = "";
	@Output() setStatus = new EventEmitter<any>();
	@Input("search-primary-key") searchPrimaryKey = "name";
	@Input("search-secondary-key") searchSecondaryKey = "state";
	@Input() dropdownPos: string = "auto";
	@Input() hideArrow: boolean = false;

	constructor() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			// if we wanted to exclude some row form option we can pass list of status in exclude options,
			// so it will be not shown on ui
			if (this.options && this.exclude_option && this.exclude_option_compare_key != "") {
				const temp_option = _deepCopyJson(this.options);
				const filtered_options = temp_option.filter(
					(x) => !this.exclude_option.includes(x[this.exclude_option_compare_key])
				);
				this.status_options = filtered_options;
			} else {
				this.status_options = this.options;
			}
		}
	}

	ngOnInit(): void {}

	// ngOnChanges(changes: any) {

	// 	if (changes) {

	// 		this.options = this.inputConfig.statusInput;
	// 	}
	// }

	onSelect = ($event) => {
		this.setStatus.emit($event);
	};

	customSearchFn = (term: string, item: any) => {
		term = term.toLowerCase();

		// Creating and array of space saperated term and removinf the empty values using filter
		const splitTerm = term.split(" ").filter((t) => t);

		let isWordThere = [];

		// Pushing True/False if match is found
		splitTerm.forEach((arr_term) => {
			const searchStatus = item[this.searchPrimaryKey]?.toLowerCase();
			const searchState = item[this.searchSecondaryKey]?.toLowerCase() || "";
			isWordThere.push(searchStatus.indexOf(arr_term) !== -1 || searchState.indexOf(arr_term) !== -1);
		});
		const all_words = (this_word) => this_word;
		// Every method will return true if all values are true in isWordThere.
		return isWordThere.every(all_words);
	};

	reset = () => {
		this.value = null;
	};

	updateValue = (value: any) => {
		this.value = value;
	};
}
