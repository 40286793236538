<div class="d-flex align-items-center {{ timerClass }} progress-timer">
	<apx-chart
			[chart]="is_achieved ? chartachieve.chart : chartoptions.chart"
			[grid]="is_achieved ? chartachieve.grid : chartoptions.grid"
			[colors]="[getChartColor(getColorName(startTime, expectedEndTime))]"
			#chartObj
			[series]="[getPercent(startTime, expectedEndTime)]"
			[plotOptions]="is_achieved ? chartachieve.plotOptions : chartoptions.plotOptions"
			[stroke]="is_achieved ? chartachieve.stroke : chartoptions.stroke"
		>
		</apx-chart>
	<div class="ml-50 mb-0 cp-timer" *ngIf="expectedEndTime">
		<ng-container *ngIf="getRunningStatusTitle(metricData) as slaTitleData">
			<span [style.color]="slaTitleData?.color_name" class="sla-status">
				<i class="fa {{ slaTitleData?.icon }}" aria-hidden="true"></i> <b> {{ slaTitleData?.status_name }} </b>
			</span>
		</ng-container>

				<label *ngIf="!endTime" class="mb-0 fw-500">
					<ng-container *ngIf="getTimerData(metricData) as timer_data">
						<div class="d-flex justify-content-start align-items-center">
							<span *ngIf="is_achieved" class="mr-25">{{ "UI.k_with" | translate }}</span>
							<label class="mb-0 fw-500 sla-timer">
							{{ metricData?.is_breached ? "+" : ""
							}}<span *ngIf="timer_data?.days"
								>{{ timer_data.days ? timer_data?.days?.toString().padStart(2, "0") : "" }}d </span
							>{{ timer_data?.hours?.toString().padStart(2, "0") }}h
							{{ timer_data?.minutes?.toString().padStart(2, "0") }}m
							</label>
							<span *ngIf="is_achieved" class="ml-25">{{ "UI.k_left" | translate }}</span>
						</div>
					</ng-container>
				</label>
	
		</div>
	<div class="ml-50 mb-0 cp-timer" *ngIf="!expectedEndTime">
		<label class="mb-0  sla-timer"> 00:00 hrs </label>
		<!-- <span *ngIf="isBreached" class="breached-status"
			><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>{{ "UI.k_breached" | translate }}</span
		>
		<span *ngIf="!isBreached">{{ "UI.k_left" | translate }}</span> -->
	</div>
</div>
