<div class="content-wrapper" appCheckRouteCondition>
  <div class="content-body">
    <div
      class="custom-feature"
      [ngStyle]="{
        padding: incidentTab === true ? '0' : ''
      }"
    >
      <div class="custom-main">
        <div class="custom-row">
          <ng-container *ngFor="let item of stageArray">
            <div *ngIf="!item?.is_disabled " ngbDropdown class="dropdown">
              <hr
                [ngClass]="{ 'hr-custom': item.active === true }"
                [ngStyle]="{
                  'border-color': item.active === true ? item.borderColorCode : '',
                  opacity: item.id === 'closure' ? '0' : '1'
                }"
              />
              <button
                class="dot dropdown-toggle"
                type="button"
                id="{{ item.id }}"
                data-toggle="dropdown"
                ngbDropdownToggle
                aria-haspopup="true"
                aria-expanded="false"
                [ngClass]="{ 'stage-active': item.active === true }"
                [ngStyle]="{
                  background: item.active === true ? item.colorCode : '',
                  'border-color': item.active === true ? item.colorCode : ''
                }"
                [disabled]="disableConfig"
                (click)="checkValidation(item)"
                attr.data-testid="bth___{{ item.id }}"
              >
                <ng-container>
                  <i class="fa fa-check" aria-hidden="true" *ngIf="item.active"></i>
                </ng-container>
              </button>
              <span class="sm-font" attr.data-testid="sp_label{{ item.label }}">{{ item.label | translate }}</span>
              <ul
                class="dropdown-menu multi-level"
                role="menu"
                *ngIf="!item.active"
                ngbDropdownMenu
                aria-labelledby="responded"
                style="margin-top: 36px"
                [attr.data-testid]="'ul__menu'"
              >
                <i class="arrow down custom-down"></i>
                <ng-container *ngFor="let subItem of item.optionList">
                  <li class="dropdown-divider"></li>
                  <li
                    (click)="stageSelection(item, subItem, subItem.label)"
                    *ngIf="!subItem?.is_disabled"
                    [ngClass]="subItem?.subOptionList ? 'dropdown-submenu' : 'dropdown-item'"
                  >
                    <a class="dropdown-item" tabindex="-1">{{ subItem.label | translate }}</a>

                    <ul class="dropdown-menu">
                      <ng-container *ngFor="let subOpt of subItem?.subOptionList">
                        <li class="dropdown-item" (click)="subStageSelection(item, subItem, subOpt)" attr.data-testid="btn_sub{{ subOpt?.name }}">
                          <a> {{ subOpt?.name | translate}}</a>
                        </li>
                      </ng-container>
                    </ul>
                  </li>
                </ng-container>
              </ul>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
