import { Component, Input, OnInit } from "@angular/core";

@Component({
	selector: "app-congratulations",
	templateUrl: "./congratulations.component.html",
	styleUrls: ["./congratulations.component.scss"],
})
export class CongratulationsComponent implements OnInit {
	@Input() userName: string;
	@Input() productName: string;
	constructor() {}

	ngOnInit(): void {}
}
