<ng-container *appDnsLicenseHandler="item?.license || {}">
	<ng-container *appIsGranted="item.permission">
		<!-- item.url -->
		<a
			[id]="[item.id]"
			class="dropdown-item d-flex align-items-center"
			[ngClass]="item.classes"
			*ngIf="item.url && !item.externalUrl"
			[routerLink]="[item.url]"
			[target]="item.openInNewTab ? '_blank' : '_self'"
		>
			<ng-container *ngTemplateOutlet="itemContent"></ng-container>
		</a>

		<!-- item.externalUrl -->
		<a
			[id]="item.id"
			class="dropdown-item d-flex align-items-center"
			[ngClass]="item.classes"
			*ngIf="item.url && item.externalUrl"
			[href]="item.url"
			[target]="item.openInNewTab ? '_blank' : '_self'"
		>
			<ng-container *ngTemplateOutlet="itemContent"></ng-container>
		</a>

		<ng-template #itemContent>
			<span *ngIf="item.icon"><i class="fa-light {{ item.icon }} fa-lg"></i></span>
			<span [translate]="item.translate">{{ item.title }}</span>
		</ng-template>
	</ng-container>
</ng-container>

