import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from './service';

@Directive({
  selector: '[appIsGranted]'
})

export class IsGrantedDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionManagerS: PermissionService
  ) { }
  @Input() set appIsGranted(moduleData: any) {
    if (moduleData){
        this.isGranted(moduleData.moduleList, moduleData.keyList);
    }    
  }
  private isGranted(moduleList: string[], keyList: string[]) {
      let granted = this.permissionManagerS.isGranted(moduleList, keyList);      
      if (granted) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
  }
}