import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { GlobalConstants } from "../../../app.globalConstants";
import { PermissionService } from "app/common/permission/service";

@Directive({
	selector: "[appDnsLicenseHandler]",
})
export class DnsLicenseHandlerDirective {
	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef,
		private permissionManagerS: PermissionService
	) {}

	/**
	 * Here we are initializing data with list of license keys
	 * @param license_data
	 */
	@Input() set appDnsLicenseHandler(license_data: any) {
		if (license_data.module_key && license_data.keys) {
			if (license_data !== undefined) {
				if (this.permissionManagerS.hasValidLav(license_data.module_key, license_data.keys)) {
					this.viewContainer.createEmbeddedView(this.templateRef);
				} else {
					this.viewContainer.clear();
				}
			}
		} else {
			this.viewContainer.createEmbeddedView(this.templateRef);
		}
	}
}
