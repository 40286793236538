<div class="selct-feature group-list-feature">
	<div class="select-sec"
	[ngClass]="{
		'show-detail-wrapper': this.userPickerObject.showUserDetails === true
	}"
	>
		<div class="form-group mb-0">
			<label class="form-label" for="formSelect" [hidden]="inputConfig && inputConfig.isLabelHide">
				{{ "UI.k_user" | translate }}
			</label>
			<ng-select
				#userSelect 
				[isOpen]="toggleSelectOption"
				[items]="optionList"
				bindLabel="name"
				name="formSelect"
				id="formSelect"
				placeholder="Select Option"
				[dropdownPosition]="'bottom'"
				(clear)="removeSelectedData($event)"
				(click)="dropdownEventTriggered(dummyClick, $event)"
				[(ngModel)]="userPickerObject.selectedUser"
				#dummyClick
			>
				<ng-template ng-option-tmp ng-label-tmp let-item="item">
					<ng-container>
						<div class="group-lists uniq-avatar-cls" [id]="this.inputConfig?.id">
							<div class="avatar-group">
								<div class="avatar pull-up avatar-sm" *ngFor="let avatarItem of item.avatar_list">
									<img
										class="media-object"
										src="{{ avatarItem }}"
										alt="Avatar"
										height="32"
										width="32"
									/>
								</div>
							</div>
							<span (click)="showDetails(item)">{{ item.name }}</span>
							<div class="right-arrow">
								<i class="fa-light fa-angle-right"></i>
							</div>
						</div>
					</ng-container>
				</ng-template>
			</ng-select>
		</div>
		<ng-container *ngFor="let e of optionList">
			<div
				class="detail-wrapper"
				[ngClass]="{
					show: e.active === true,
					topSec: customClass === 'top',
					bottomSec: customClass === 'bottom',
					leftSec: customClass === 'left',
					rightSec: customClass === 'right'
				}"
				*ngIf="e.active"
			>
				<div class="detail-sec">
					<div class="remove-btn" (click)="removeSec()">
						<i class="fa fa-times"></i>
					</div>
					<div class="single-user-list">
						<!-- <button type="button" class="btn btn-flat-primary" rippleEffect><i class="fa-light fa-arrow-left"></i>Back</button> -->
						<div class="user-list-sec">
							<div
								class="group-name d-flex cursor-pointer"
								style="color: #7367f0"
								(click)="assignToTeamEvent(e)"
							>
								<div class="avatar-group">
									<div class="avatar pull-up avatar-sm" *ngFor="let avatarItem of e.avatar_list">
										<img
											class="media-object"
											src="{{ avatarItem }}"
											alt="Avatar"
											height="32"
											width="32"
										/>
									</div>
								</div>
								<span>
									{{ "UI.k_assign_team" | translate }}
								</span>
							</div>
							<div class="search-user px-1">
								<div class="input-group search-form-group">
									<div class="input-group-prepend">
										<span class="input-group-text" id="basic-addon-search2"><i
												class="fa-light fa-magnifying-glass"></i></span>
									</div>

									<input type="text" class="form-control sm-form-control" id="txtSearchUser-{{e.id}}"
										name="txtSearchUser-{{e.id}}" placeholder="{{ 'UI.k_search_user' | translate }}"
										aria-label="Search..." aria-describedby="search-user"
										[(ngModel)]='e.searchConfig'
										(ngModelChange)="searchUser($event,dummyClick,e)" 
										/>
										<!-- (click)="searchFocusEvent(dummyClick)"
										(ngModelChange)="searchUser($event,dummyClick)" 
										-->
								</div>
							</div>
							<div class="group-list">
								<ng-scrollbar class="custom-scrollbar section-scrollbar">
								<ng-container *ngFor="let userItem of e.users; let i = index">
									<div class="single-user">
										<div class="custom-control custom-radio">
											<div class="avatar pull-up avatar-sm">
												<img
													class="media-object"
													src="{{ userItem.avatar }}"
													alt="Avatar"
													height="32"
													width="32"
												/>
											</div>
											<input
												type="radio"
												id="userRadio_{{ i }}"
												name="customRadio"
												class="custom-control-input"
												[value]="userItem.full_name"
												[checked]="userItem?.is_selected"
												(click)="userSelectedEvent($event, userItem)" />
											<label class="custom-control-label" for="userRadio_{{ i }}">
												<div class="user-info">
													<span class="user-name mr-25">{{userItem.full_name}}</span>
													<div class="d-flex align-items-center">
														<span class="user-expertise">{{userItem.expertise}}</span>
														<span class="user-level">(lvl {{userItem.level}})</span>
													</div>
													<!-- <span class="badge badge-light-info">{{userItem.expertise}}</span> -->
													
												</div>
											</label>
										</div>
									</div>
								</ng-container>
							</ng-scrollbar>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>
