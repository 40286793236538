import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { UserService } from "../../services/user.service";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { ToastrService } from "ngx-toastr";
import { catchError, map } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { GlobalConstants } from "app/app.globalConstants";
import { InfinityModules } from "app/common/dns/types/modules";
import Swal from "sweetalert2";
import { throwError } from "rxjs";
import { encryptData } from "app/common/utils/utils";

@Component({
	selector: "app-invite-user-grid",
	templateUrl: "./invite-user-grid.component.html",
	styleUrls: ["./invite-user-grid.component.scss"],
})
export class InviteUserGridComponent implements OnInit {
	@Output("onHideInviteGrid") onHideInviteGrid = new EventEmitter<any>();
	users: any;
	columns: Array<any>;
	options: any;
	SelectionType = SelectionType;
	ColumnMode = ColumnMode;
	pageNumber = 0;
	limit = 10;
	userCount = 0;
	currentEvent: String;
	currentUser: Number;
	sortKey: any = "email";
	reverse: any = "";
	module = InfinityModules.USER;
	permissions: any;

	constructor(
		private _coreSidebarService: CoreSidebarService,
		private _userService: UserService,
		private _toastr: ToastrService,
		private _translateService: TranslateService
	) {}

	ngOnInit(): void {
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.permissions = user_permissions[this.module];
		this.getOptions();
		this.columns = [
			{
				label: this._translateService.instant("UI.k_email"),
				key: "email",
				subkey: "profile",
			},
			{
				label: this._translateService.instant("UI.k_role"),
				key: "role",
			},
			{
				label: this._translateService.instant("UI.k_expires_at"),
				key: "validity",
			},
			{
				label: this._translateService.instant("UI.k_action"),
				key: "action",
				subkey: "profile",
				width: 100,
				actions: [
					{
						label: this._translateService.instant("UI.k_resend"),
						icon: "fa-paper-plane",
						key: "add",
						class: "text-primary",
						listener: this.resendInvitation,
					},
					{
						label: this._translateService.instant("UI.k_revoke"),
						icon: "fa-rotate-left",
						key: "delete",
						class: "text-danger mr-50",
						listener: this.removeUser,
					},
				],
			},
		];
	}
	getOptions = (): void => {
		this._userService.getOptions().subscribe(
			(response) => {
				if (response) {
					this.options = {};
					this.options["role"] = response["roles"];
					this.getUserList();
				}
			},
			(error) => {
				// 		//todo
				//console.log("error", error);
			}
		);
	};
	getUserList() {
		const payload = {
			page: this.pageNumber + 1,
			items_per_page: this.limit,
			sort: this.sortKey,
			reverse: this.reverse,
			invited_user: true,
		};
		this._userService.getUserProfiles(payload).subscribe(
			(response) => {
				if (response) {
					this.users = response.results;
					this.userCount = response.count;
				}
			},
			(error) => {
				//todo
			}
		);
	}

	setPage(pageInfo) {
		this.pageNumber = pageInfo.offset;
		this.getUserList();
	}

	setLimit(limit): void {
		this.pageNumber = 0;
		// this.limit = args.target.value;
		if (limit === "all") {
			this.limit = this.userCount;
		} else {
			this.limit = Number(limit);
		}
		this.getUserList();
	}
	onSort(args): void {
		if (args.sorts) {
			this.pageNumber = 0;
			this.sortKey = args.sorts[0].prop;
			if (this.sortKey == "role") {
				this.sortKey = "role_type";
			} else if (this.sortKey == "expiresAt") {
				this.sortKey = "creation_time";
			} else {
				this.sortKey = "email";
			}
			if (args.sorts[0].dir == "desc") {
				this.reverse = "-";
			} else {
				this.reverse = "";
			}
		}
		this.getUserList();
	}
	toggleSidebar = (name): void => {
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
		this.onHideInviteGrid.emit("hide");
	};
	resendInvitation = (args): void => {
		let invitedUsers = {};
		invitedUsers["profile_id"] = args.profile_id;
		invitedUsers["userEmail"] = args.profile.email;
		invitedUsers["userRole"] = args.profile.role;
		this._userService
			.sendUserInvite({
				data: encryptData(JSON.stringify([invitedUsers])),
				module_name: "User",
				resend_link: true,
			})
			.pipe(
				map((response: any) => {
					if (response) {
						// this.notification.success('Saved User', 'User has been saved successfully')
						this.getUserList();
						let email_ids_list = response.data?.existing_profiles;
						let pending_email_ids = response.data?.pending_profiles;
						if (response["status"] == "success") {
							setTimeout(() => {
								this._toastr.success(
									this._translateService.instant(response["message"], {
										email_ids_list: email_ids_list,
										pending_email_ids: pending_email_ids,
									}),
									this._translateService.instant("Message.msg_user_invite"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							}, 500);
						} else {
							this._toastr.error(
								this._translateService.instant(response["message"], {
									email_ids_list: email_ids_list,
									pending_email_ids: pending_email_ids,
								}),
								this._translateService.instant("Error.err_invite_fail"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
						}
					}
					// Success
				})
			)
			.subscribe();
	};

	removeUser = (row) => {
		//console.log("row", row);
		Swal.fire({
			title: this._translateService.instant("Message.msg_delete_title"),
			text: this._translateService.instant("Message.msg_delete_text"),
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: this._translateService.instant("Message.msg_revoke_user"),
			cancelButtonText: this._translateService.instant("Message.msg_delete_cancel"),
			customClass: {
				confirmButton: "btn btn-primary",
				cancelButton: "btn btn-danger ml-1",
			},
		}).then((result) => {
			if (result.value) {
				this._userService
					.deleteUserProfile(row.profile_id)
					.pipe(
						map((response: any) => {
							if (response.status == "success") {
								this._toastr.success(
									this._translateService.instant("Message.msg_user_deleted"),
									this._translateService.instant("Message.msg_delete_success"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
								// subtracting 1 from page number if count equals to items per page limit after delete
								if (this.userCount != 1 && this.userCount - 1 < this.pageNumber * this.limit + 1) {
									this.pageNumber = this.pageNumber - 1;
								}
								this.getUserList();
							} else {
								this._toastr.error(
									this._translateService.instant("Error.err_delete_user"),
									this._translateService.instant("Error.err_delete_failed"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							}
							// Success
						}),
						catchError((error) => {
							this._toastr.error(
								this._translateService.instant("Error.err_delete_user"),
								this._translateService.instant("Error.err_delete_failed"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
							return throwError(error.statusText);
						})
					)
					.subscribe();
			}
		});
	};
}
