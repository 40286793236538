import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { DOCUMENT, Location } from "@angular/common";
import { Title } from "@angular/platform-browser";

import { Subject, throwError, Observable, Observer, fromEvent, merge } from "rxjs";
import { catchError, map, takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import * as Waves from "node-waves";
import { CoreMenuService } from "@core/components/core-menu/core-menu.service";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CoreConfigService } from "@core/services/config.service";
import { CoreLoadingScreenService } from "@core/services/loading-screen.service";
import { CoreTranslationService } from "@core/services/translation.service";
import { CommonService } from "./services/common.service";
import { menu } from "app/common/menu/menu";
import { adminMenu } from "app/common/menu/admin-menu";
import { infraonAdminMenu } from "app/common/menu/infraon-admin-menu";
import { AuthService } from "app/base/auth/services/auth.service";
import { GlobalConstants } from "app/app.globalConstants";
import { NavigationEnd, Router } from "@angular/router";
import { Hotkey, HotkeysService } from "angular2-hotkeys";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { WSSService } from "./common/services/wss/wss.service";
import { NgEventBus } from "ng-event-bus";
import { MetaData } from "ng-event-bus/lib/meta-data";
import { UserService } from "./base/users/services/user.service";
import store from "store";
import { clearStore } from "./common/utils/utils";
import { OrgDataService } from "app/services/org-data-service.service";
import menuEnglish from "app/common/menu/i18n/en.json";
import menuHindi from "app/common/menu/i18n/hi.json";
import menuDanish from "app/common/menu/i18n/da.json";
import menuArabic from "app/common/menu/i18n/ar.json";
import { SupportMenu } from "./common/menu/support-menu";
import { MspMenu } from "./common/menu/msp-menu";
import { WssAgentService } from "./common/services/wss-agent/wss-agent.service";
import { IdleService } from "./services/idle.service";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
	coreConfig: any;
	hasNotification: boolean = false;
	hideProfile = false;
	menu: any;
	defaultLanguage: "en"; // This language will be used as a fallback when a translation isn't found in the current language
	appLanguage: "en"; // Set application default language i.e fr
	submenuToggle: boolean;
	show = false; //pop-up for menu
	public rem_days;
	public path;
	hasCountNotification = false;
	isOnline: any;
	idleState = 'Not started.';
	timedOut = false;
	banner_msg = "";
	onShow() {
		this.show = true;
	}
	showProgressBar: boolean = false; //demodata status bar
	demoDataLoadedSuccess: boolean = false;
	progressbarHeight = 5;
	currentValue = 0;
	timeoutDuration : number;
	showProgressDefaultTxt: boolean = true;
	showProgressCompleteTxt: boolean = false;
	progressBarType: string = "primary";
	public ignorePaths = ["/dashboard"]
	// Private
	private _unsubscribeAll: Subject<any>;
	isMaintainanceWindowActive = true;
	maintainance_msg = "";
	layoutConfig = {
		layoutStyle: "",
		borderStyle: "",
		themeColor: "",
	};
	navbarColorValue: any;
	logoContainerColor: any;
	/**
	 * Constructor
	 *
	 * @param {DOCUMENT} document
	 * @param {Title} _title
	 * @param {Renderer2} _renderer
	 * @param {ElementRef} _elementRef
	 * @param {CoreConfigService} _coreConfigService
	 * @param {CoreSidebarService} _coreSidebarService
	 * @param {CoreLoadingScreenService} _coreLoadingScreenService
	 * @param {CoreMenuService} _coreMenuService
	 * @param {CoreTranslationService} _coreTranslationService
	 * @param {TranslateService} _translateService
	 */
	constructor(
		@Inject(DOCUMENT) private document: any,
		private hotkeysService: HotkeysService,
		private _router: Router,
		private _location: Location,
		private _title: Title,
		private _renderer: Renderer2,
		private _elementRef: ElementRef,
		public _coreConfigService: CoreConfigService,
		private _coreSidebarService: CoreSidebarService,
		private _coreLoadingScreenService: CoreLoadingScreenService,
		private _coreMenuService: CoreMenuService,
		private _coreTranslationService: CoreTranslationService,
		private _translateService: TranslateService,
		private _commonService: CommonService,
		private modalService: NgbModal,
		private _authService: AuthService,
		private _wssService: WSSService,
		private _userService: UserService,
		private eventBus: NgEventBus,
		private _orgDataService: OrgDataService,
		private _wssAgentService: WssAgentService,
		private idleService: IdleService
	) {
		GlobalConstants.globalSearch?.forEach((pageObj) => {
			if (this._translateService.instant(pageObj?.shortKey) && !pageObj?.sidebar) {
				this.hotkeysService.add(
					new Hotkey(
						this._translateService.instant(pageObj?.shortKey), //  key combination
						(): boolean => {
							this.hideProfile = false;
							this._router.navigateByUrl(pageObj.handle);
							// callback function to execute after key combination
							if (this._coreSidebarService.getSidebarRegistry("shortcutkey").isOpened) {
								this._coreSidebarService.getSidebarRegistry("shortcutkey").toggleOpen();
								this.modalService.dismissAll();
							}
							return false; // prevent bubbling
						}
					)
				);
			}
			if (this._translateService.instant(pageObj?.shortKey) && pageObj?.sidebar) {
				this.hotkeysService.add(
					new Hotkey(
						this._translateService.instant(pageObj?.shortKey), //  key combination
						(): boolean => {
							this.hideProfile = false;
							// callback function to execute after key
							this._router.navigate([pageObj.handle], { queryParams: { globalSearch: true } });
							if (this._coreSidebarService.getSidebarRegistry("shortcutkey").isOpened) {
								this._coreSidebarService.getSidebarRegistry("shortcutkey").toggleOpen();
								this.modalService.dismissAll();
							}

							return false; // prevent bubbling
						}
					)
				);
			}
		});
		this.getOrgProfile();

		// Get the application main menu
		// this.menu = menu;
		this._router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				const allPath = event.url.split("/");
				const url = event.url;
				this.path = event.url;
				const mainPath = allPath[1];
				if (mainPath !== "dashboard") {
					localStorage.setItem("selectedDashbodId", null);
				}
				let redirectPath = "";
				if (allPath.length > 2) {
					redirectPath = allPath[2];
				}
				if (redirectPath === "partneruser") {
					this._commonService.currentRoute.next(redirectPath);
				}
				if (mainPath === "admin" || redirectPath === "admin") {
					this._coreMenuService.unregister("main");
					this.menu = adminMenu;
					this._coreMenuService.register("main", this.menu);
					this._coreMenuService.setCurrentMenu("main");
				} else if (mainPath === "infraon-admin") {
					this._coreMenuService.unregister("main");
					this.menu = infraonAdminMenu;
					this._coreMenuService.register("main", this.menu);
					this._coreMenuService.setCurrentMenu("main");
				} else if (mainPath === "support") {
					this._coreMenuService.unregister("main");
					this.menu = SupportMenu;
					this._coreMenuService.register("main", this.menu);
					this._coreMenuService.setCurrentMenu("main");
				} else if (mainPath === "msp") {
					this._coreMenuService.unregister("main");
					this.menu = MspMenu;
					this._coreMenuService.register("main", this.menu);
					this._coreMenuService.setCurrentMenu("main");
				} else {
					this._coreMenuService.unregister("main");
					this.menu = menu;
					this._coreMenuService.register("main", this.menu);
					this._coreMenuService.setCurrentMenu("main");
				}
				if (this.timeoutDuration && !this.ignorePaths.includes(url)) {
					this.idleService.startWatching(this.timeoutDuration, () => this.onTimeout());
				  } else {
					// console.log(`Ignoring idle detection for path: ${url}`);
				  }
			}
		});

		this.createOnline$().subscribe((isOnline) => (this.isOnline = isOnline));

		// Register the menu to the menu service
		this._coreMenuService.register("main", this.menu);

		// Set the main menu as our current menu
		this._coreMenuService.setCurrentMenu("main");

		// Add languages to the translation service
		// "da"
		this._translateService.addLangs(["en", "hi" , "ar"]);

		// Set the translations for the menu
		// menuDanish
		this._coreTranslationService.translate(menuEnglish, menuHindi, menuArabic);

		let language = localStorage.getItem("language");

		if (language) {
			// This language will be used as a fallback when a translation isn't found in the current language
			this._translateService.setDefaultLang('en');
			// Set application default language.
			// Change application language? Read the ngxTranslate Fix
			this._translateService.use(language);
		} else {
			// This language will be used as a fallback when a translation isn't found in the current language
			this._translateService.setDefaultLang("en");
			// Set application default language.
			// Change application language? Read the ngxTranslate Fix
			this._translateService.use("en");
		}
		// ? OR
		// ? User the current browser lang if available, if undefined use 'en'
		// const browserLang = this._translateService.getBrowserLang();
		// this._translateService.use(browserLang.match(/en|hi/) ? browserLang : 'en');

		/**
		 * ! Fix : ngxTranslate
		 * ----------------------------------------------------------------------------------------------------
		 */

		/**
		 *
		 * Using different language than the default ('en') one i.e French?
		 * In this case, you may find the issue where application is not properly translated when your app is initialized.
		 *
		 * It's due to ngxTranslate module and below is a fix for that.
		 * Eventually we will move to the multi language implementation over to the Angular's core language service.
		 *
		 **/

		// Set the default language to 'en' and then back to 'fr'.

		// setTimeout(() => {
		//   this._translateService.setDefaultLang('en');
		//   this._translateService.setDefaultLang('fr');
		// });

		/**
		 * !Fix: ngxTranslate
		 * ----------------------------------------------------------------------------------------------------
		 */
		this._commonService.submenuToggal.subscribe((val) => {
			this.submenuToggle = val;
		});

		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	// Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		/*
		// To do : check Token And redirect to landing page and remove the condition from below if statement
		if (!this._location.path()){
			// write check and redirect logic here
		}
		*/
		if (!this._location.path() || this._location.path() == "/auth/login") {
			// clear local storage if the user is on login page.
			clearStore([
				"accessToken",
				"image_per",
				"accessType",
				"is_expired",
				"remaining_days",
				"show_banner",
				"count_dict",
				"is_admin",
				"product",
			]);
		}
		// Init wave effect (Ripple effect)
		Waves.init();

		// Subscribe to config changes
		this._coreConfigService.getConfig().pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
			this.coreConfig = config;
			localStorage.setItem("config", JSON.stringify(this.coreConfig));
			// localStorage.setItem("themeColor", config?.layout?.themeColor || "default");
			// Layout
			//--------

			this.navbarColorValue = this.coreConfig.layout.navbar.backgroundColor &&  this.coreConfig.layout.navbar.backgroundColor !== '' ? this.coreConfig.layout.navbar.backgroundColor : JSON.parse(localStorage.getItem("config")).layout.navbar.backgroundColor;
			this.logoContainerColor = this.coreConfig.layout.menu.logoBackgroundColor &&  this.coreConfig.layout.menu.logoBackgroundColor !== '' ? this.coreConfig.layout.menu.logoBackgroundColor : JSON.parse(localStorage.getItem("config")).layout.menu.logoBackgroundColor;
			if(this.coreConfig?.layout?.themeColor || this.coreConfig?.layout?.themeColor !== ''){
				this.layoutConfig.themeColor = this.coreConfig?.layout?.themeColor
			}else{
				this.layoutConfig.themeColor = JSON.parse(localStorage.getItem("config")).layout.themeColor;
			}
			if(this.coreConfig?.layout?.layoutStyle || this.coreConfig?.layout?.layoutStyle !== ''){
				this.layoutConfig.layoutStyle = this.coreConfig?.layout?.layoutStyle
			}else{
				this.layoutConfig.layoutStyle = JSON.parse(localStorage.getItem("config")).layout.layoutStyle;
			}
			this.layoutConfig.borderStyle = 'bordered'
			if(this.document.body.classList.contains('dark-layout') && (this.layoutConfig.themeColor !== 'dark')){
				this.document.body.classList.remove("dark-layout", 'semi-dark-layout');
				this.document.body.classList.add(this.layoutConfig.themeColor + "-layout");
			}else {
				this.document.body.classList.remove("default-layout", 'semi-dark-layout');
				this.document.body.classList.add(this.layoutConfig.themeColor + "-layout");
			}
			if(this.layoutConfig.layoutStyle === 'spaced-out'){
				this.document.body.classList.remove("fixed-layout");
				this.document.body.classList.add(this.layoutConfig.layoutStyle + "-layout");
			}else{
				this.document.body.classList.remove("spaced-out-layout");
				this.document.body.classList.add(this.layoutConfig.layoutStyle + "-layout");
			}
			if(this.layoutConfig.borderStyle === 'bordered'){
				this.document.body.classList.remove("shadow-layout");
				this.document.body.classList.add(this.layoutConfig.borderStyle + "-layout");
			}else{
				this.document.body.classList.remove("bordered-layout");
				this.document.body.classList.add(this.layoutConfig.borderStyle + "-layout");
			}
			// Remove default classes first
			this._elementRef.nativeElement.classList.remove(
				"vertical-layout",
				"vertical-menu-modern",
				"horizontal-layout",
				"horizontal-menu"
			);
			// Add class based on config options
			if (this.coreConfig.layout.type === "vertical") {
				this._elementRef.nativeElement.classList.add("vertical-layout", "vertical-menu-modern");
			} else if (this.coreConfig.layout.type === "horizontal") {
				this._elementRef.nativeElement.classList.add("horizontal-layout", "horizontal-menu");
			}

			// Navbar
			//--------
			// Remove default classes first
			this._elementRef.nativeElement.classList.remove(
				"navbar-floating",
				"navbar-static",
				"navbar-sticky",
				"navbar-hidden"
			);
			// Add class based on config options
			if (this.coreConfig.layout.navbar.type === "navbar-static-top") {
				this._elementRef.nativeElement.classList.add("navbar-static");
			} else if (this.coreConfig.layout.navbar.type === "fixed-top") {
				this._elementRef.nativeElement.classList.add("navbar-sticky");
			} else if (this.coreConfig.layout.navbar.type === "floating-nav") {
				this._elementRef.nativeElement.classList.add("navbar-floating");
			} else {
				this._elementRef.nativeElement.classList.add("navbar-hidden");
			}

			// Footer
			//--------

			// Remove default classes first
			this._elementRef.nativeElement.classList.remove("footer-fixed", "footer-static", "footer-hidden");

			// Add class based on config options
			if (this.coreConfig.layout.footer.type === "footer-sticky") {
				this._elementRef.nativeElement.classList.add("footer-fixed");
			} else if (this.coreConfig.layout.footer.type === "footer-static") {
				this._elementRef.nativeElement.classList.add("footer-static");
			} else {
				this._elementRef.nativeElement.classList.add("footer-hidden");
			}

			// Blank layout
			if (
				this.coreConfig.layout.menu.hidden &&
				this.coreConfig.layout.navbar.hidden &&
				this.coreConfig.layout.footer.hidden
			) {
				this._elementRef.nativeElement.classList.add("blank-page");
				// ! Fix: Transition issue while coming from blank page
				this._renderer.setAttribute(
					this._elementRef.nativeElement.getElementsByClassName("app-content")[0],
					"style",
					"transition:none"
				);
			} else {
				this._elementRef.nativeElement.classList.remove("blank-page");
				// ! Fix: Transition issue while coming from blank page
				setTimeout(() => {
					this._renderer.setAttribute(
						this._elementRef.nativeElement.getElementsByClassName("app-content")[0],
						"style",
						"transition:300ms ease all"
					);
				}, 0);
				// If navbar hidden
				if (this.coreConfig.layout.navbar.hidden) {
					this._elementRef.nativeElement.classList.add("navbar-hidden");
				}
				// Menu (Vertical menu hidden)
				if (this.coreConfig.layout.menu.hidden) {
					this._renderer.setAttribute(this._elementRef.nativeElement, "data-col", "1-column");
				} else {
					this._renderer.removeAttribute(this._elementRef.nativeElement, "data-col");
				}
				// Footer
				if (this.coreConfig.layout.footer.hidden) {
					this._elementRef.nativeElement.classList.add("footer-hidden");
				}
			}

			// Skin Class (Adding to body as it requires highest priority)
			if (this.coreConfig.layout.skin !== "" && this.coreConfig.layout.skin !== undefined) {
				let prvTheme: any;
				let isDarkMode: boolean;
				this._commonService.prevSkin.subscribe((val) => {
					prvTheme = val;
				});
				// prvTheme = JSON.parse(localStorage.getItem("prevSkin"));
				this._commonService.enableDarkMode.subscribe((val) => {
					isDarkMode = val;
				});
				// if (prvTheme === "fluent" && isDarkMode === true) {
				// 	this.document.body.classList.remove(
				// 		"default-layout",
				// 		"bordered-layout",
				// 		"semi-dark-layout"
				// 	);
				// 	this.document.body.classList.add(
				// 		this.coreConfig.layout.skin + "-layout"
				// 	);
				// } else {
				// 	this.document.body.classList.remove(
				// 		"default-layout",
				// 		"bordered-layout",
				// 		"dark-layout",
				// 		"semi-dark-layout",
				// 		"fluent-layout"
				// 	);
				// 	this.document.body.classList.add(
				// 		this.coreConfig.layout.skin + "-layout"
				// 	);
				// }
			}
		});
		this.uploadimagelog();

		// Set the application page title
		const wss_response_key = `mail:countBreach`;
		this._wssService.getWsDataStore().subscribe((res: any) => {
			if (res && res?.channel === wss_response_key) {
				this.mailCountCheck(res?.data?.payload?.message?.data);
			}
		});

		this._coreConfigService._demoDataLoadingStatus.subscribe((res) => {
			if (res === true) {
				this.showProgressBar = true;
			}
		});

		this._coreConfigService._demoDataLoadSuccess.subscribe((res) => {
			if (res === true) {
				this.showProgressBar = false;
			}
		});
		this.startProgressBar();
	}
	closeAlertModel() {
		this.isOnline = true;
	}
	onTimeout(): void {
		console.log('User has been idle for 5 minutes.');
		store.remove("accessToken");
		store.remove("image_per");
		store.remove("ogdata");
		this._router.navigate(["/auth/login"], {
			queryParams: { is_token_expired: true },
		});
	  }

	ngAfterViewInit() {
		// this.hotkeysService.add(
		// 	new Hotkey(
		// 		"alt+g", // key combination
		// 		(): boolean => {
		// 			// callback function to execute after key combination
		// 			this.hideProfile = false;
		// 			if (this._coreSidebarService.getSidebarRegistry("shortcutkey").isOpened) {
		// 				this._coreSidebarService.getSidebarRegistry("shortcutkey").toggleOpen();
		// 				this.modalService.dismissAll();
		// 			}
		// 			let cssClass = "animation-disable modal-dialog-search";
		// 			this.modalService.open(GlobalSearchComponent, {
		// 				centered: false,
		// 				windowClass: cssClass,
		// 				//animation: false,
		// 				size: "xl",
		// 			});
		// 			return false; // prevent bubbling
		// 		}
		// 	)
		// );

		this.hotkeysService.add(
			new Hotkey(
				"?", // key combination
				(): boolean => {
					// callback function to execute after key combination
					this.hideProfile = true;
					this._coreSidebarService.getSidebarRegistry("shortcutkey").toggleOpen();
					return false; // prevent bubbling
				}
			)
		);
		this.uploadimagelog();

		this.initAfterLogin();
	}

	public preparNotifyMessage = (count_dict: any | string) => {
		if (typeof count_dict == "string") {
			return count_dict;
		} else {
			if (count_dict?.hour_mail_breach) {
				return this._translateService.instant("Message.msg_hourly_mail_count");
			}
			if (count_dict?.day_mail_breach) {
				return this._translateService.instant("Message.msg_daily_mail_count");
			}
		}
	};
	public mailCountCheck = (count_dict: any) => {
		if (count_dict?.hour_mail_breach || count_dict?.day_mail_breach) {
			this.hasCountNotification = true;
			this.banner_msg = this.preparNotifyMessage(count_dict);
			this.document.body.classList.add("has-notification");
		} else {
			this.hasCountNotification = false;
		}
	};
	public initAfterLogin(): void {
		let user_closed = false;
		let user_count_closed = false;
		let accessToken = store.get("accessToken");
		let showBanner = store.get("show_banner");
		let count_dict = store.get("count_dict");
		let isExpired = store.get("is_expired");
		let banner_closed = store.get("bannerClosed");
		let banner_count_closed = store.get("bannerCountClosed");
		this.isMaintainanceWindowActive = store.get("is_maintenance");
		if (this.isMaintainanceWindowActive && store.get("maintenance_msg") !== undefined) {
			this.maintainance_msg = store.get("maintenance_msg");
		} else {
			this.isMaintainanceWindowActive = false;
		}
		let product = store.get("product");
		if (banner_closed !== undefined) {
			let today = new Date().toLocaleDateString();
			if (today === banner_closed) {
				user_closed = true;
			}
		}
		if (banner_count_closed !== undefined) {
			let today = new Date().toLocaleDateString();
			if (today === banner_count_closed) {
				user_count_closed = true;
			}
		}
		if (accessToken) {
			// this.getUserPreferences("");
			this._wssService.client_uuid = "";
			this._wssService.connect();
			this._wssAgentService.client_uuid = "";
			this._wssAgentService.connect();
			// this.updateMenu({});
			if (showBanner && !user_closed && !isExpired) {
				this.hasNotification = false;
				this.rem_days = store.get("remaining_days");

				// this.document.body.classList.add("has-notification");
			}
			this.mailCountCheck(count_dict);
		}
		// this.eventBus.on("menu:update").subscribe((meta: MetaData) => {
		// 	this.updateMenu(meta.data);
		// });
	}

	uploadimagelog() {
		let payload = {};
		let accessType = store.get("accessType");
		if (accessType == 2) {
			payload["login_auth_type"] = 2;
		}
		this._authService.getPreferences(payload).pipe(
			map((response: any) => {
				let logo_path = response.user_pref.logo;
				this._orgDataService.setData(response);
				if (logo_path) {
					localStorage.setItem("imglogo", logo_path);
				}
				// this.setvalue(response, url, 0);
			})
		);
	}
	createOnline$() {
		return merge(
			fromEvent(window, "offline").pipe(map(() => false)),
			fromEvent(window, "online").pipe(map(() => true)),
			new Observable((sub: Observer<boolean>) => {
				sub.next(navigator.onLine);
				sub.complete();
			})
		);
	}
	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._wssService.disconnect();
		this._wssAgentService.disconnect();
		this._unsubscribeAll.next(undefined);
		this._unsubscribeAll.complete();
	}

	// Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle sidebar open
	 *
	 * @param key
	 */
	toggleSidebar(key): void {
		this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
	}

	getOrgProfile(): void {
		this._authService
			.getLoginSettingsConfig({ config_type: "branding" })
			.pipe(
				map((response: any) => {
					if (response) {
						if (response?.company_name){
							this._title.setTitle(response?.company_name);
						}
						else{
							this._title.setTitle(this.coreConfig.app.appTitle);
						}
						if(response?.logo_path){
							this.setFavicon(response?.logo_path);
						}
						else{
							this.setFavicon("assets/images/logo/infraon-Logo-square.svg");
						}
						if (response?.idle_timeout_duration){
							this.timeoutDuration = response?.idle_timeout_duration
						}
					}
					// Success
				}),
				catchError((error) => {
					this.setFavicon("assets/images/logo/infraon-Logo-square.svg");
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}
	// updateMenu(data: any) {
	// 	this._userService.getUserMenu({ module: data.id || "all" }).subscribe(
	// 		(response) => {
	// 			console.log("menu", response)
	// 			let user_menus = response;
	// 			if (!this.menu) {
	// 				this.menu = menu;
	// 			}
	// 			user_menus?.forEach((menuObj) => {
	// 				this.menu?.forEach((menu) => {
	// 					if (menuObj["id"] == menu["id"]) {
	// 						if (menuObj["position"] == "children") {
	// 							menu["children"] = menuObj["menus"];
	// 							this._coreMenuService.register("main", this.menu);
	// 							this._coreMenuService.setCurrentMenu("main");
	// 						} else {
	// 							//ToDo: update main mennu
	// 						}
	// 					}
	// 				});
	// 			});
	// 		},
	// 		(error) => {
	// 			//todo
	// 		}
	// 	);
	// }

	setvalue = (response, url, flag) => {
		if (response) {
			let language = response.user_pref.language;
			let logo_path = response.user_pref.logo ? response.user_pref.logo : "assets/images/infraon_logo.svg";
			let icon_path = response.user_pref.icon ? response.user_pref.icon : "assets/images/infraon_logoDNS.svg";
			let theme = response.user_pref.theme;
			let layoutStyle = response.user_pref.layout_style;
			let logocontainer = response.user_pref.logocontainer || "bg-white";
			let navbar = response.user_pref.navbar;
			// let icon_path = response.user_pref.icon;
			if (response["profile"]) {
				let user_params = {};
				let timezone = response["profile"]["profile"].timezone_id || response["profile"].timezone_id;
				user_params["user_tz"] = timezone ? timezone : "UTC";
				user_params["timezone_offset_str"] = response["profile"]["profile"].timezone_offset_str || "";
				user_params["user_language"] = language ? language : "en";
				localStorage.setItem("userParams", JSON.stringify(user_params));
				this._authService.currentUserSubject.next(response["profile"]);
			}
			if (language) {
				this._translateService.setDefaultLang(language);
				this._translateService.use(language);
				localStorage.setItem("language", language);
			}
			if (logo_path) {
				localStorage.setItem("imglogo", logo_path);
			}
			if (icon_path) {
				localStorage.setItem("imgicon", icon_path);
			}
			if (theme) {
				localStorage.setItem("themeColor", theme);
			}
			if (navbar) {
				localStorage.setItem("navbar", navbar);
			}

			if (logocontainer) {
				localStorage.setItem("logoColor", logocontainer);
			}
			if (flag == 1) {
				let perm = response?.img_per;
				if (perm !== "" && perm !== undefined) {
					localStorage.setItem("image_per", JSON.stringify(perm));
				}
				localStorage.setItem("fflags", JSON.stringify(response.fflags || ""));
			}
			// // Configure the layout
			this._coreConfigService.config = {
				layout: {
					navbar: {
						hidden: false,
						// backgroundColor: navbar,
					},
					footer: {
						hidden: true,
					},
					menu: {
						hidden: false,
						logo: logo_path,
						icon: icon_path,
						logoBackgroundColor: logocontainer,
					},
					customizer: false,
					notifications: false,
					enableLocalStorage: false,
					themeColor: theme,
					layoutStyle: layoutStyle,
				},
			};
			// this.router.navigate([url]);
		}
	};

	getUserPreferences = (url) => {
		let payload = {};
		this._authService
			.getPreferences(payload)
			.pipe(
				map((response: any) => {
					this.setvalue(response, url, 0);
					this._orgDataService.setData(response);
				}),
				catchError((error) => {
					if (error.statusText == "Unauthorized") {
						this._router.navigate(["/error/403"]);
					}
					if (error.status == 401) {
						//this.store.dispatch(new UserActions.Logout())
						store.remove("accessToken");
						store.remove("image_per");
						store.remove("ogdata");
						this._router.navigate(["/auth/login"], {
							queryParams: { is_token_expired: true },
						});
					}
					return throwError(error.statusText);
				})
			)
			.subscribe();
	};

	onHideProfile = (e) => {
		this.hideProfile = false;
	};
	getNotificationToggle($event) {
		this.hasNotification = false;
		if (this.hasNotification) {
			this.document.body.classList.add("has-notification");
		} else {
			this.document.body.classList.remove("has-notification");
			localStorage.setItem("bannerClosed", new Date().toLocaleDateString());
		}
	}
	getNotificationCountToggle($event) {
		this.hasCountNotification = $event;
		if (this.hasCountNotification) {
			this.document.body.classList.add("has-notification");
		} else {
			this.document.body.classList.remove("has-notification");
			localStorage.setItem("bannerCountClosed", new Date().toLocaleDateString());
		}
	}

	startProgressBar() {
		const timer = setInterval(() => {
			if (this.currentValue < 80) {
				this.currentValue += 5;
			} else {
				this.pauseProgressBar();
			}
		}, 500);
	}

	pauseProgressBar() {
		setInterval(() => {
			if (this.demoDataLoadedSuccess && this.currentValue < 100) {
				setTimeout(() => {
					if (this.currentValue === 100) {
						this.showProgressBar = false;
					}
				}, 500);
				this.currentValue += 20;
				this.showProgressDefaultTxt = false;
				this.showProgressCompleteTxt = true;
			}
		}, 500);
	}

	onRemoveMaintenance() {
		this.isMaintainanceWindowActive = false;
		this.maintainance_msg = "";
		store.remove("is_maintenance");
		store.remove("maintenance_msg");
	}
	setFavicon(url: string): void {
		const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement('link');
		link.type = 'image/x-icon';
		link.rel = 'shortcut icon';
		link.href = url;
		document.getElementsByTagName('head')[0].appendChild(link);
	}
}
