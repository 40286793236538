<div class="full-body-height incident-grid-data p-1">
	<div class="main-content flex-height">
		<div class="main-sec">
			<div class="action-bar" *ngIf="invoices?.length !== 0 && showSearch">
				<div class="inner-section">
					<div class="left-section">
						<!-- <div class="action-search">
							<app-search></app-search>
						</div> -->
					</div>
					<div class="right-section">

					</div>
				</div>
			</div>
			<div class="action-section invoice-grid">
				<ng-scrollbar class="custom-scrollbar section-scrollbar">

					<div class="table-view">
						<!-- <ng-container>
							<dns-server-grid
								[rows]="invoices"
								[columns]="columns"
								[count]="totalCount"
								(page)="setPage($event)"
								(sort)="onSort($event)"
								(setlimit)="setLimitGrid($event)"
								[isEditable]="true"
								[options]="options"
								[permissions]="permissions"
								class="bootstrap core-bootstrap"
								[scrollbarH]="true"
								[virtualization]="false"
								[showData]="showData"
								[filterData]="filterData"
								[emptyData]="empty_data"
								[hideFooter]="true"
								[limit]="limit"
							>
							</dns-server-grid>
						</ng-container> -->

						<div class="table-responsive">
							<table class="table">
								<thead>
									<tr>
										<!-- <ng-container *ngFor="let col of invoices"> -->
											<ng-container *ngIf="trigger === 'support'">
												<th class="simple-table-header">
													<span>
														{{ "UI.k_organization" | translate }}
													</span>
												</th>
											</ng-container>
												<th class="simple-table-header">
													<span>
														{{ "UI.k_payment_status" | translate }}
													</span>
												</th>
												<th class="simple-table-header">
													<span>
														{{ "UI.k_invoice_amount" | translate }}
													</span>
												</th>
												<th class="simple-table-header">
													<span>
														{{ "UI.k_generated_date" | translate }}
													</span>
												</th>
												<th class="simple-table-header">
													<span>
														{{ "UI.k_paid_on" | translate }}
													</span>
												</th>
												<th class="simple-table-header">
													<span>
														{{ "UI.k_due_date" | translate }}
													</span>
												</th>
												<th class="simple-table-header">
													<span>
														{{ "UI.k_action" | translate }}
													</span>
												</th>
										<!-- </ng-container> -->
									</tr>
								</thead>
								<tbody>
									<tr
									*ngFor="let row of invoices; odd as isOdd; even as isEven;index as indx"
									[ngClass]="{ 'even-row': isOdd, 'odd-row': isEven }"
								>
								<ng-container *ngIf="trigger === 'support'">
									<td class="simple-table-cell">
										{{row.billing_address.company}}
									</td>
								</ng-container>
								<td class="simple-table-cell">
									<ng-container *ngIf="row.status === 'paid'">
										<div class="badge badge-light-success">{{row.status}}</div>
									</ng-container>
									<ng-container *ngIf="row.status !== 'paid'">
										<div class="badge badge-light-danger">{{row.status}}</div>
									</ng-container>
								</td>
								<td class="simple-table-cell">
									{{row.amount_paid / 100 | currency: row.currency_code:"symbol" }}
								</td>
								<td class="simple-table-cell">
									{{row.generated_at * 1000 | date: "mediumDate"}}
								</td>
								<td class="simple-table-cell">
									{{row.paid_at * 1000 | date: "mediumDate"}}
								</td>
								<td class="simple-table-cell">
									{{row.due_date * 1000 | date: "mediumDate"}}
								</td>
								<td class="simple-table-cell">
									<button type="button" class="btn btn-icon btn-flat-primary" rippleEffect (click)="downloadInvoice(row.id)">
										<i class="fa-light fa-download"></i>
									</button>
								</td>
								</tr>
								</tbody>
							</table>
						</div>




						<div
						class="d-flex loadBtn-scroll justify-content-center align-items-center"
						*ngIf="showLoadMore && invoiceLoaded"
					>
						<button
							class="btn btn-sm btn-primary d-flex align-items-center justify-content-between"
							type="button"
							(click)="loadNextData()"
						>
							{{ "UI.k_load_more" | translate }}
						</button>
					</div>
					<div *ngIf="!invoiceLoaded && showLoadMore" class="d-flex loadBtn-scroll justify-content-center align-items-center">
						<button class="btn-sm btn btn-primary" type="button" disabled rippleEffect>
							<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							<span class="ml-25 align-middle">{{ "UI.k_loading" | translate }}...</span>
						</button>
					</div>
					</div>

				</ng-scrollbar>

			</div>
		</div>
	</div>
</div>
