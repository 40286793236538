<div class="slideout-content default-slideout">
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
		<div class="modal-dialog sidebar-md">
			<div *ngIf="problem_data != undefined" class="modal-content pt-0">
				<div class="modal-header">
					<h5 class="modal-title" id="closeReq">
						{{ "UI.k_close_problem" | translate }}
					</h5>
					<button
						type="button"
						id="btnClose"
						name="btnClose"
						[attr.data-testid]="'btnClose'"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						(click)="toggleSidebar('process-detail-problem-close')"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<form #closeproblemForm="ngForm" class="modal-body">
					<div class="flex-auto-height">
						<ng-scrollbar class="custom-scrollbar section-scrollbar">
							<div class="modal-body-inner p-1">
								<div class="card border border-rounded">
									<div class="summary-info p-1">
										<div class="d-flex justify-content-between align-items-center">
											<div class="d-flex justify-content-start align-items-center">
												<div>
													<span class="fw-500 reg-font primary-color">{{
														this.problem_data?.display_id
													}}</span>
												</div>
												<div class="rounded icon-class mx-50">
													<div class="close-card-config" ngbTooltip ="{{ problem_data?.basic_info?.problem_source?.name }}"
													placement="right" id="btnSource">
														<i
															class="fa-light fa-{{
																sourceIconMap[
																	problem_data?.basic_info?.problem_source?.id
																]
															}}"
														></i>
													</div>
												</div>
												<div
													*ngIf="problem_data?.is_parent_problem"
													class="rounded"
													id="btnShare"
												>
													<div class="cloass-card-config">
														<i class="fa-light fa-share-nodes"></i>
													</div>
												</div>
											</div>

											<div class="mx-50" *ngIf="this.problem_data?.basic_info?.priority?.name">
												<!-- <div *ngIf="this.problem_data?.basic_info?.priority?.name"> -->
												<div
													class="badge badge-light-{{
														problem_data?.basic_info?.priority?.color
													}}"
												>
													<!-- <span class="sm-font fw-500">Priority:</span> -->
													{{
														this.problem_data?.basic_info?.priority?.display_key | translate
													}}
												</div>
											</div>
										</div>
										<div class="d-flex justify-content-between align-items-center">
											<div class="d-flex justify-content-start align-items-center">
												<div *ngIf="problem_data?.basic_info?.impact_service_tree_path">
													<span class="sm-font">{{ "UI.k_service" | translate }} : </span>
													<span
														class="sm-font"
														title="{{ problem_data?.basic_info?.impact_service_tree_path }}"
													>
														{{
															problem_data?.basic_info?.impact_service_tree_path
																| slice: 0:19
														}}{{
															problem_data?.basic_info?.impact_service_tree_path?.length >
															19
																? ".."
																: ""
														}}</span
													>
												</div>

												<div *ngIf="problem_data?.asset_id && problem_data?.cmdb_id">
													<span class="sm-font">{{ "UI.k_asset_id" | translate }} : </span>
													<span
														*ngIf="
															asset_permissions?.view && problem_data?.is_asset_disabled
														"
													>
														<a
															href="/it-asset/asset/{{ problem_data?.cmdb_id }}"
															target="_blank"
															class="sm-font"
															title="{{ problem_data?.asset_id }}"
														>
															{{ problem_data?.asset_id | slice: 0:19
															}}{{ problem_data?.asset_id?.length > 19 ? ".." : "" }}</a
														>
													</span>
													<span
														*ngIf="
															!asset_permissions?.view || !problem_data?.is_asset_disabled
														"
														class="sm-font"
														title="{{ problem_data?.asset_id }}"
													>
														{{ problem_data?.asset_id | slice: 0:19
														}}{{ problem_data?.asset_id?.length > 19 ? ".." : "" }}
													</span>
												</div>
											</div>
										</div>

										<div class="my-50 d-flex justify-content-start align-items-center">
											<div class="avatar avatar-xs mr-50">
												<!-- <ng-container > -->
												<span
													class="avatar-content"
													*ngIf="!problem_data?.requester_profile"
													[ngStyle]="{
														'background-color':
															problem_data?.requester_avatar_color != undefined
																? problem_data?.requester_avatar_color
																: '#f59518'
													}"
													>{{
														this.problem_data?.requester?.full_name | initials | slice: 0:2
													}}</span
												>
												<!-- </ng-container> -->
												<ng-container *ngIf="problem_data?.requester_profile">
													<img
														class="avatar-content"
														class=""
														[src]="problem_data?.requester_profile"
														alt=""
													/>
												</ng-container>
											</div>

											<span
												class="sm-font fw-500 title-color summary-ellipse"
												ngbTooltip="{{ problem_data?.basic_info?.summary | slice: 0:200 }}"
												placement="bottom"
												>{{ problem_data?.basic_info?.summary | slice: 0:150 }}
												{{ problem_data?.basic_info?.summary.length > 150 ? ".." : "" }}
											</span>
										</div>
									</div>
								</div>
								<div class="row p-0">
									<div class="form-group col-xs-6 col-md-6 col-xl-6 col-lg-6">
                                        {{problem_data.basic_info?.status?.id}}
										<app-status-picker
											[inputConfig]="{ isTextMode: true, stateOptionsMap: options?.state_id_map }"
											[options]="close_status"
											(setStatus)="onStatusChange($event)"
											[value]="problem_data.basic_info?.status?.id"
										></app-status-picker>
									</div>

									<div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
										<div class="form-group">
											<label for="txtReqCloserInfo"
												>{{ "UI.k_close_problem" | translate }}
												<span class="required-field">&nbsp;*</span>
											</label>
											<div>
												<quill-editor
													[(ngModel)]="problem_data.close_info.closure_note"
													id="smartQuillReqClosure"
													name="smartQuillReqClosure"
													(onContentChanged)="getClosureNote($event)"
													(onEditorCreated)="editorCreated($event)"
													[trimOnValidation]="true"
													#TDReqClosureNoteRef
													[appQuillValidator]="TDReqClosureNoteRef"
													[appQuillForm]="closeproblemForm"
													[minLength]="3"
													[required]="true"
													[class.error]="
														TDReqClosureNoteRef?.errors && closeproblemForm.submitted
													"
													placeholder="{{ 'UI.k_closure_note' | translate }}"
												>
													<div id="quill-toolbar" quill-editor-toolbar>
														<div class="">
															<span class="ql-formats">
																<button
																	class="ql-bold"
																	title="{{ 'UI.k_bold' | translate }}"
																></button>
																<button
																	class="ql-italic"
																	title="{{ 'UI.k_italic' | translate }}"
																></button>
																<button
																	class="ql-underline"
																	title="{{ 'UI.k_underline' | translate }}"
																></button>
															</span>
															<span class="ql-formats">
																<select
																	class="ql-align"
																	title="{{ 'UI.k_alignment' | translate }}"
																>
																	<option selected></option>
																	<option value="center"></option>
																	<option value="right"></option>
																	<option value="justify"></option>
																</select>
															</span>
															<span class="ql-formats">
																<button
																	class="ql-list"
																	value="ordered"
																	type="button"
																></button>
															</span>
															<span class="ql-formats">
																<button
																	class="ql-link"
																	value="ordered"
																	type="button"
																></button>
																<button
																	class="ql-image"
																	value="ordered"
																	type="button"
																></button>
																<button
																	class="ql-video"
																	value="ordered"
																	type="button"
																></button>
															</span>
															<span class="ql-formats">
																<button
																	class="ql-formula"
																	value="ordered"
																	type="button"
																></button>
																<button
																	class="ql-code-block"
																	value="ordered"
																	type="button"
																></button>
															</span>
															<span class="ql-formats">
																<button
																	class="ql-clean"
																	value="ordered"
																	type="button"
																></button>
															</span>
															<span class="ql-formats">
																<button
																	id="insert-table"
																	type="button"
																	(click)="addNewtable($event)"
																>
																	Table
																</button>
															</span>
														</div>
													</div>
												</quill-editor>
											</div>

											<div
												class="error"
												*ngIf="closeproblemForm.submitted && TDReqClosureNoteRef?.errors"
											>
												<small *ngIf="TDReqClosureNoteRef?.errors?.requiredError?.empty">
													{{ "UI.k_field_required" | translate }}
												</small>
												<small *ngIf="TDReqClosureNoteRef?.errors?.minLengthError">
													{{ "Error.err_closure_note_min" | translate }}
												</small>
											</div>
										</div>
									</div>
									<div class="col-xs-12 col-md-6 col-xl-6 col-lg-6">
										<div class="form-group">
											<label for="txtReqClosedBy">{{ "UI.k_closedby" | translate }} </label>
											<ng-select
												[items]="options.users"
												[(ngModel)]="problem_data.close_info.closed_by"
												bindLabel="full_name"
												bindValue="profile_id"
												name="txtReqClosedBy"
												id="txtReqClosedBy"
												placeholder="{{ 'UI.k_select_users' | translate }}"
												[hideSelected]="true"
											>
												<ng-template
													ng-option-tmp
													let-item="item"
													let-index="index"
													let-search="searchTerm"
												>
													<span class="d-flex">
														<div
															class="avatar mr-1 ml-0"
															[ngStyle]="{
																'background-color': item.avatar_color
																	? item.avatar_color
																	: '#f59518'
															}"
															style="align-content: center; width: 32px; height: 32px"
														>
															<div class="avatar-content">
																{{ item.full_name | initials }}
															</div>
														</div>
														<div class="cell-line-height">
															<p
																class="font-medium-1 font-weight-bold line-height-1 mb-25"
															>
																{{ item.full_name }}
															</p>
															<span class="text-muted font-small-2">
																{{ item.email }}</span
															>
														</div>
													</span>
												</ng-template>
											</ng-select>
										</div>
									</div>

									<div class="col-xs-12 col-md-6 col-xl-6 col-lg-6">
										<div class="form-group">
											<label for="txtReqCloseType">{{ "UI.k_close_type" | translate }} </label>
											<ng-select
												[items]="options.close_type"
												[hideSelected]="true"
												bindLabel="name"
												bindValue="id"
												(change)="
													updateFieldName(
														problem_data.basic_info?.closed_type?.id,
														'id',
														'closed_type',
														problem_data.basic_info?.closed_type?.name,
														'name'
													)
												"
												[(ngModel)]="problem_data.close_info.closed_type.id"
												name="txtReqCloseType"
												id="txtReqCloseType"
												placeholder="{{ 'UI.k_sel_close_type' | translate }}"
											>
											</ng-select>
										</div>
									</div>
									<div class="col-xs-12 col-md-6 col-xl-6 col-lg-6">
										<div class="form-group">
											<label for="txtReqActlClsrDate"
												>{{ "UI.k_actual_closure_date" | translate }}
											</label>
											<ng2-flatpickr
												[config]="dateTimeOptions"
												name="txtReqActlClsrDate"
												[setDate]="
													problem_data?.actual_closure_date
														? problem_data?.actual_closure_date
														: (problem_data['actual_closure_date'] =
																dateTimeOptions?.defaultDate)
												"
												id="txtReqActlClsrDate"
												[(ngModel)]="problem_data.actual_closure_date"
												placeholder="{{ 'UI.k_sel_date' | translate }}"
											></ng2-flatpickr>
										</div>
									</div>
									<div class="col-xs-12 col-md-6 col-xl-6 col-lg-6">
										<div class="form-group">
											<label for="txtReqclsrAgrdTime"
												>{{ "UI.k_agreed_closure_date" | translate }}
											</label>
											<ng2-flatpickr
												[config]="agreedDateTimeOptions"
												name="txtReqclsrAgrdTime"
												[setDate]="problem_data.agreed_closure_date"
												placeholder="{{ 'UI.k_sel_date' | translate }}"
												id="txtReqclsrAgrdTime"
												[(ngModel)]="problem_data.agreed_closure_date"
											></ng2-flatpickr>
										</div>
									</div>
									<div class="w-100 px-1">
										<app-dns-custom-field
											[formRef]="closeproblemForm"
											placeholder="closure"
											[data]="problem_data?.custom_field_data"
											[config]="req_extra_info?.custom_field_config"
										></app-dns-custom-field>
									</div>
								</div>
							</div>
						</ng-scrollbar>
						<div class="modal-btn-group flex-initial border-top">
							<div class="d-flex justify-content-end stepper-btn flex-initial">
								<button
									class="btn btn-sm btn-primary btn-next ml-1"
									id="btnComplete"
									[attr.data-testid]="'btnComplete'"
									rippleEffect
									type="submit"
									[disabled]="is_submit_disabled"
									(click)="onCloseSubmit(closeproblemForm, problem_data)"
								>
									<span class="align-middle d-sm-inline-block d-none">
										{{ "UI.k_complete" | translate }}</span
									>
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
