import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { none } from 'ol/centerconstraint';

@Component({
  selector: 'app-dns-aging-timer',
  templateUrl: './dns-aging-timer.component.html',
  styleUrls: ['./dns-aging-timer.component.scss']
})
export class DnsAgingTimerComponent implements OnInit {
  @Input() key : any;
	@Input() subkey : any;
	@Input() id : any;
	@Input() data : any;
	@Input() rowindex: any;
  @Input() timerClass: any = "";
 

  private $trackBgColor = "#D8D8E3";
	public chartoptions;
	public chartachieve;
	constructor(private _translateService: TranslateService) {}

  ngOnInit(): void {
  }

  // getTimerData(data: any): any {
	// }



}
