import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { TranslateService } from '@ngx-translate/core';
import { KBService } from "app/base/knowledge-base/services/kb.service";

@Component({
  selector: 'app-attach-knowledge-base',
  templateUrl: './attach-knowledge-base.component.html',
  styleUrls: ['./attach-knowledge-base.component.scss']
})
export class AttachKnowledgeBaseComponent implements OnInit {
	@Input() rowCount;
	@Input() kbInput: any;
	pageStart:any = 0
	pageEnd:any
	@Output() onSetLimit: EventEmitter<any> = new EventEmitter<any>();
	@Output() hideAllKb = new EventEmitter(false);
	@Output() updateAllKb = new EventEmitter(false);
	public suggested_kb = [];
	public attachedKb = [];
	public KbCategories = [
		{name: `${this._translateService.instant('UI.k_article')}`, id: 'article',
		},
		{
		name: `${this._translateService.instant('UI.k_faq')}`, id: 'faq',
		},
		{
		name: `${this._translateService.instant('UI.k_information')}`, id: 'information',
		},
		{
		name: `${this._translateService.instant('UI.k_known_error')}`, id: 'known_error',
		},
		{
		name: `${this._translateService.instant('UI.k_solution')}`, id: 'solution',
		}
	];
	public kbData = [];
	public isAttachFlag = true;
	cardCount = 10;
	kbCount = 0;
	filter_string = ""
	size = 10;
	page = 1;
	timer: any;
	suggestion_filter = "";
	category_filter: any;

  constructor(
	  private _coreSidebarService: CoreSidebarService, 
	  private _translateService: TranslateService,
	  public _kbService: KBService
	  ) 
	{ }

  ngOnInit(): void {
	  if(this.kbInput) {
		//   this.suggested_kb = this.kbInput['suggested-kb'];
		  this.attachedKb = this.kbInput['attached-kb'];
		  this.filter_string = this.kbInput['filter_string'];
		  this.suggestion_filter = this.kbInput['filter_string'];
		  this.getAllSuggestedKb(this.suggestion_filter, "")
		  if (this.attachedKb == undefined){
			this.attachedKb = []
		  }
	  }

    this.pageStart = 0
    if (this.rowCount > 0) {
			this.pageStart = this.size * (this.page - 1) + 1
		}
		this.pageEnd = this.size * (this.page)
		if (this.pageEnd > this.rowCount){
			this.pageEnd = this.rowCount
		}
    // this.getSuggestedKb();
  }

  ngOnChanges() {
	if(this.kbInput) {
		//   this.suggested_kb = this.kbInput['suggested-kb'];
		  this.attachedKb = this.kbInput['attached-kb'];
		  this.filter_string = this.kbInput['filter_string'];
		  this.suggestion_filter = this.kbInput['filter_string'];
		  this.getAllSuggestedKb(this.suggestion_filter, "")
		  if (this.attachedKb == undefined){
			this.attachedKb = []
		  }
	  }
}

	toggleSidebar = (name : string): void => {
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
		let hide_response = {
			'summary': this.suggestion_filter,
			'flag': true,
			'attachedKb': this.attachedKb
		}
		this.hideAllKb.emit(hide_response);
	};


	getAllSuggestedKb(suggestion_filter, category_filter){
		const payload = {
			page: this.page,
			items_per_page: this.size,
			cardCount: this.cardCount,
			sortKey: "creation_time",
			filter: this.suggestion_filter,
			is_external: true,
		};

		if (category_filter !== null && category_filter !== undefined && category_filter !== "") {
			payload["kb_type_filter"] = category_filter;
		}
		if (suggestion_filter !== null && suggestion_filter !== undefined && suggestion_filter !== "") {
			payload["filter"] = suggestion_filter;
			this._kbService.getKBProfiles(payload).subscribe(
				(response) => {
					if (response) {
						// if (this.ShowAllKb) {
						// 	this.limit = Number(this.limit) + 1;
						// }
						this.suggested_kb = response.results;
						this.rowCount = response.count;
						this.cardCount = response.count
						this.kbCount = this.suggested_kb.length;
						this.suggested_kb.forEach((ele, index) => {
							this.suggested_kb[index].content = this.suggested_kb?.[index]?.content?.replace(/&nbsp;/g, " ");
							this.suggested_kb[index].content = this.suggested_kb?.[index]?.content?.replace(/&amp;/g, "&");
							this.suggested_kb[index].content = this.suggested_kb?.[index]?.content?.replace(/<img[^>]*>/g, "");
						});
						if (this.attachedKb?.length > 0){
							this.attachedKb.forEach((attach_ele) => {
								this.suggested_kb.forEach((ele, index) => {
									if (attach_ele.kb_id === ele.kb_id) {
										this.suggested_kb.splice(index, 1);
									}
								});
							});
						}
					}
				},
				(error) => {
					//todo
				}
			);

		}
		else {
			this.suggested_kb = []
		}

		// if (filter !== null && filter !== undefined && filter !== "") {
		// 	payload["filter"] = filter;
		// }

	};	


//   getSuggestedKb(){
//     this._incidentViewService.getOptions().subscribe(
// 			(response) => {
// 				if (response) {
// 					this.kbData = response['kb_list']
            
//          				   if(this.kbData.length > 0){
// 							this.suggested_kb = []
// 							for(let i=0;i<this.kbData.length;i++){
// 								if(this.kbData[i]){
// 								this.suggested_kb.push(this.kbData[i]);
// 								}
// 							}
// 						}
// 						// this.attachedKb = this.kbDataDup.filter(this.kbDataDup => this.kbDataDup.isAtteched == true)
// 						// if(this.kbDataDup.length != 0){
// 						// 	this.suggested_kb = []
// 						// 	for(let i=0;i<2;i++){
// 						// 		if(this.kbDataDup[i]){
// 						// 		this.suggested_kb.push(this.kbDataDup[i]);
// 						// 		}
// 						// 	}
// 						// }
// 				}
// 			},
// 			(error) => {
// 				//todo
// 			}
// 		);
//   }

  stringToColor(reqName) {
		if(!reqName){
			reqName="anaymous"
		}

		let hash = 0;
		let i;
	  
		/* eslint-disable no-bitwise */
		for (i = 0; i < reqName?.length; i += 1) {
		  hash = reqName.charCodeAt(i) + ((hash << 5) - hash);
		}
	  
		let colour = '#';
	  
		for (i = 0; i < 3; i += 1) {
		  const value = (hash >> (i * 8)) & 0xff;
		  colour += `00${value.toString(16)}`.substr(-2);
		}
		/* eslint-enable no-bitwise */
	  
		return colour;
	  }
    redirectToKb(id){
    }

	AttachToIncident(item) {
		item["is_attached"] = true;
		this.attachedKb.push(item);
		this.suggested_kb.forEach((ele, index) => {
			if (item.kb_id === ele.kb_id) {
				this.suggested_kb.splice(index, 1);
			}
		});
		let hide_response = {
			'summary': this.suggestion_filter,
			'flag': true,
			'attachedKb': this.attachedKb,
			'kb': item, 
			'action': 'attach'
		}
		this.updateAllKb.emit(hide_response);
	}

	DettachToIncident(item) {
		this.attachedKb.forEach((ele, index) => {
			if (item.kb_id === ele.kb_id) {
				this.attachedKb.splice(index, 1);
			}
		});
		let hide_response = {
			'summary': this.suggestion_filter,
			'flag': true,
			'attachedKb': this.attachedKb,
			'kb': item,
			'action': 'detach'
		}
		this.updateAllKb.emit(hide_response);
		this.getAllSuggestedKb(this.suggestion_filter, this.category_filter);
	}


	limit(size){
		this.page = 1
		this.size = size
		this.getAllSuggestedKb(this.suggestion_filter, this.category_filter)
	}

	paginate(page) {
		page = page?.page ? parseInt(page.page) : page
		this.page = page
		this.getAllSuggestedKb(this.suggestion_filter, this.category_filter)
		// this.pageStart = this.size * (page - 1) + 1
		// this.pageEnd = this.size * (page)
		// let params = {page : page, limit : this.size}
		// this.onSetLimit.emit(params)
	}

	searchKB = (e) => {
		if (this.timer) {
			clearTimeout(this.timer);
		}
		var self = this;
		this.timer = setTimeout(function () {
			self.getAllSuggestedKb(self.suggestion_filter, self.category_filter);
		}, 1000);
	};

	searchKBCategory(filter){
		this.getAllSuggestedKb(this.suggestion_filter, this.category_filter);

	};
	attachKbEvent(e) {
		this.isAttachFlag = !this.isAttachFlag
	  }
}
