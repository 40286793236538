import {
	AfterViewInit,
	ChangeDetectorRef,
	Directive,
	ElementRef,
	Input,
	OnChanges,
	Renderer2,
	ViewContainerRef,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

//refer from https://github.com/Oneia/read-more-angular2/blob/master/src/read-more.directive.ts

@Directive({
	selector: "[dnsReadMore]",
})
export class ReadMoreDirective implements AfterViewInit, OnChanges {
	@Input("readMore-length") public maxLength: number;

	private currentText: string;
	private hideToggle: boolean = true;
	private text: string;
	private isCollapsed: boolean = false;

	constructor(
		private el: ElementRef,
		private viewRef: ViewContainerRef,
		private _renderer: Renderer2,
		private _translateService: TranslateService
	) {}

	/**
	 * @inheritDoc
	 */
	public ngAfterViewInit() {
		this.text = this.viewRef.element.nativeElement.innerHTML;
		if (this.text) {
			this.toggleView();
		}
	}

	/**
	 * @inheritDoc
	 */
	public ngOnChanges() {
		if (this.viewRef.element.nativeElement.innerHTML) {
			this.text = this.viewRef.element.nativeElement.innerHTML;
			this.toggleView();
		}
	}

	/**
	 * Toogle view - full text or not
	 */
	private toggleView(): void {
		this.isCollapsed = !this.isCollapsed;
		this.determineView();
	}

	/**
	 * Determine view
	 */
	private determineView(): void {
		const _elementChange = this.viewRef.element.nativeElement;
		let formatted_text = this.text.replace(/<[^>]*>/g, "");
		let text_length = formatted_text?.length;
		let htmlcontent_length = this.text.length - text_length;
		if (text_length <= this.maxLength) {
			this.currentText = this.text;
			_elementChange.innerHTML = this.currentText;
			this.isCollapsed = false;
			this.hideToggle = true;
			return;
		}
		this.hideToggle = false;
		if (this.isCollapsed === false) {
			
			setTimeout(() => {
			this.currentText = this.text.substring(0, this.maxLength + htmlcontent_length) + "...";
			_elementChange.innerHTML = this.currentText;
			_elementChange.insertAdjacentHTML(
				"beforeend",
				`<a style="color: #0d51d9 !important;" id="readMore">${this._translateService.instant("UI.k_read_more")}</a>`
			);
			this.el.nativeElement.querySelector("#readMore").addEventListener("click", this.toggleView.bind(this));
			}, 100);
		
		} else if (this.isCollapsed === true) {
			setTimeout(() => {
			this.currentText = this.text + " ";
			_elementChange.innerHTML = this.currentText;
			_elementChange.insertAdjacentHTML(
				"beforeend",
				`<a style="color: #0d51d9 !important;" id="readLess">${this._translateService.instant("UI.k_read_less")}</a>`
			);
			this.el.nativeElement.querySelector("#readLess").addEventListener("click", this.toggleView.bind(this));
			}, 100);
		}
	}
}
