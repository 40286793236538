<!-- <div class="full-body-height incident-grid-data p-1"> -->
<!-- <div class="organization-info mb-1">
		<div class="row match-height">
			<div
				*ngFor="let fil of organizationInfodata; let j = index"
				class="col-xs-12 col-md-6 col-xl-3 col-lg-3 m-0 p-0"
			>
				<a
					id="btndd"
					name="btnchartType"
					ngDefaultControl
					class="cursor-pointer card card-user-timeline rounded mx-1 my-50 bx-shadow border-radius"
					(click)="onSelectChart(j, fil)"
					[ngStyle]="{
						'background-color': selectedChartItem === fil.id ? '#0d51d91f' : 'transparent',
						border: selectedChartItem === fil.id ? '1px solid #0d51d9' : '1px solid #e5e6f0',
						color: selectedChartItem === fil.id ? '#0d51d9' : none
					}"
					[(ngModel)]="orgObj"
					(click)="onSelectChart(j, fil)"
				>
					<div>
						<div class="card-body">
							<div class="card-title">
								<h5>{{ fil.title }}</h5>
							</div>
							<div class="organization-detail">
								<div class="d-flex align-items-center justify-content-between">
									<div class="numberOfcount">
										<h2>{{ fil.count }}</h2>
									</div>
									<div class="icon">
										<div class="avatar bg-light-{{ fil.backgraound }} avatar-xl">
											<span class="avatar-content">
												<i class="fa-light fa-{{ fil.icon }} mr-0"></i
											></span>
										</div>
									</div>
								</div>
							</div>
							<div class="filter">
								<div ngbDropdown>
									<a
										ngbDropdownToggle
										href="javascript:void(0);"
										class="text-secondary"
										id="filterLicensePurchased"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<span>Last 28 Days</span>
									</a>
									<div
										ngbDropdownMenu
										class="dropdown-menu-right"
										aria-labelledby="dropdownBrowserState"
									>
										<a href="javascript:void(0)" ngbDropdownItem>Last 28 Days</a>
										<a href="javascript:void(0)" ngbDropdownItem>Last Month</a>
										<a href="javascript:void(0)" ngbDropdownItem>Last Year</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</a>
			</div>
		</div>
	</div> -->
<!--Organization Grid Code Begins -->
<div class="full-body-height">
	<app-organization-grid class="full-height"></app-organization-grid>
</div>
<!--Organization Grid Code Ends -->
<!-- </div> -->
