import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { ApiService } from 'app/common/services/api/api.service'
@Injectable({
  providedIn: 'root'
})
export class BrandStylingService  extends ApiService {
	constructor(http: HttpClient) {
		super(http)
	}
	getConfigurationProgress() {
		return this.get('ux/org/profile/confprogress/', {})
	}
  loadOrganiationConfig(payload: {}) {
		return this.get('ux/org/profile/orgconfig/', payload)
	}
  saveOrganizationConfig(payload) {
		return this.post('ux/org/profile/config/', payload)
	}

}
