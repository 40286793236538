import { CsvUploadFeatureComponent } from "./csv-upload-feature.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CsvUploadFeatureRoutingModule } from "./csv-upload-feature-routing.module";

import { ImageCropperModule } from "ngx-image-cropper";
import { FormsModule } from "@angular/forms";
import { FileUploadModule } from "ng2-file-upload";
import { SharedModule } from "app/shared.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CoreCommonModule } from "@core/common.module";
import { TranslateModule } from "@ngx-translate/core";
@NgModule({
	declarations: [CsvUploadFeatureComponent],
	imports: [
		CommonModule,
		CsvUploadFeatureRoutingModule,
		ImageCropperModule,
		FormsModule,
		FileUploadModule,
		NgSelectModule,
		NgbModule,
		CoreCommonModule,
		TranslateModule,
	],
	exports: [CsvUploadFeatureComponent],
})
export class CsvUploadFeatureModule {}
