<div class="full-body-height bg-body">
	<ng-scrollbar class="custom-scrollbar section-scrollbar">
		<ng-container *ngIf="infraonProduct">
			<div class="admin-user-detail border-bottom card-bg p-1">
				<div class="d-flex align-items-center">
					<div class="instance-header-img-box mr-1">
						<img
							src="{{ infraonProduct.img_path || '' }}"
							class="img-fluid"
							alt="{{ infraonProduct.name }}"
						/>
					</div>
					<div class="instance-header-detail-box">
						<div class="d-flex align-items-center mb-50">
							<h5 class="mb-0 mr-50">{{ infraonProduct.name }}</h5>
							<a href="" class="sm-font">
								<i class="fa-light fa-arrow-up-right-from-square mr-50"></i>Learn more</a
							>
						</div>
						<p class="mb-0">{{ infraonProduct.description }}</p>
					</div>
				</div>
			</div>
		</ng-container>
		<div class="border-bottom card-bg p-1" *ngIf="instanceData && instanceData.length !== 0">
			<h4>Instance details</h4>
			<div class="instance-card-container">
				<ng-container *ngFor="let instance of instanceData; let i = index">
					<ng-container *ngFor="let data of instance?.data; let ind = index">
						<div id="{{ ind }}" class="instance-card card border rounded position-relative">
							<ng-container *ngIf="data.instance.domain_type === 0">
								<div class="badge badge-warning pricing-card-active-badge px-1">
									{{ "Trial" | translate }}
								</div>
							</ng-container>
							<div class="p-1">
								<a class="sm-font" href="{{ data.instance.sub_domain }}" target="_blank">
									<h5>{{ data.instance.sub_domain }}</h5>
									<i class="fa-light fa-arrow-up-right-from-square ml-25"></i>
								</a>
							</div>
							<div class="bg-body p-1 d-flex align-items-center justify-content-between">
								<div class="d-flex align-items-center">
									<div class="sm-font title-color py-25 px-50 border-right">XXX Assets</div>
									<div class="sm-font title-color py-25 px-50">xx Users</div>
								</div>
								<div>
									<span class="badge badge-light-warning">{{ data.item.name }}</span>
									<span class="badge badge-light-warning">{{
										data.item_price.name | trim_plan
									}}</span>
								</div>
							</div>
							<div class="p-1 d-flex align-items-center justify-content-between">
								<!-- TODO need to correct the condition-->
								<ng-container *ngIf="data.instance.domain_type === 0">
									<div class="sm-font text-danger">
										<i class="fa-light fa-circle-exclamation mr-50"></i>
										<span>
											Trial ends on {{ data.subscription.trial_end * 1000 | date: "MMM d, y" }}
										</span>
									</div>
									<button
										id="i"
										class="btn btn-primary"
										(click)="togglePublishModal(deskModal, 'prod', data)"
									>
										Buy Now
									</button>
								</ng-container>
								<!-- TODO need to correct the condition-->
								<ng-container *ngIf="data.instance.domain_type !== 0">
									<div class="sm-font">
										<i class="fa-light fa-circle-exclamation mr-50"></i>
										<span>
											Next invoice on
											{{ data.subscription.next_billing_at * 1000 | date: "MMM d, y" }}
										</span>
									</div>
								</ng-container>
							</div>
						</div>
					</ng-container>
				</ng-container>
			</div>
		</div>
		<div class="instace-pricing-section">
			<div class="pricing-section-header">
				<h5 class="modal-title fw-600" id="ModalLabelAsset">{{ "UI.k_choose_a_plan" | translate }}</h5>
				<div class="pricing-modal-header-info d-flex align-items-center">
					<ng-container *ngFor="let inp of unitData; let i = index">
						<div class="d-flex align-items-center mr-1">
							<label class="d-flex align-items-center mr-50 mb-0 fw-500" for="inp.name">
								{{ inp.name }}
							</label>
							<ng-container *ngIf="inp.type == 'input'">
								<input
									type="number"
									id="{{ inp.name }}"
									min="{{ inp.min }}"
									name="{{ inp.name }}"
									value="{{ inp.value }}"
									class="form-control form-control-sm"
									style="width: 60px"
									(change)="onChangeUnits(inp.id, $event.target.value)"
								/>
							</ng-container>
							<ng-container *ngIf="inp.type == 'select'">
								<select
									id="inp.name"
									name="inp.name"
									(change)="onChangeUnits(inp.id, $event.target.value)"
									class="form-control form-control-sm"
								>
									<ng-container *ngFor="let op of inp.options">
										<option
											id="opt{{ op }}"
											name="opt{{ op }}"
											value="{{ op }}"
											[selected]="op == inp.value"
										>
											{{ op }}
										</option>
									</ng-container>
								</select>
							</ng-container>
						</div>
					</ng-container>
					<div class="d-flex align-items-center">
						<div
							class="px-50 cursor-pointer border-right fw-500"
							[ngClass]="this.pricingType === 'month' ? 'text-primary' : 'title-color'"
							(click)="togglePricingType('month')"
						>
							{{ "UI.k_monthly" | translate }}
						</div>
						<div
							class="px-50 cursor-pointer fw-500"
							[ngClass]="this.pricingType === 'year' ? 'text-primary' : 'title-color'"
							(click)="togglePricingType('year')"
						>
							{{ "UI.k_yearly" | translate }}
						</div>
						<div class="badge badge-primary">{{ "UI.k_save" | translate }} 20%</div>
					</div>
				</div>
			</div>
			<div class="pricing-section-body" tabindex="0" ngbAutofocus>
				<div class="pricing-cards-container">
					<ng-container *ngFor="let plan of pricingTypeData; let i = index">
						<div class="pricing-card card border card-bg">
							<ng-container *ngIf="i == 1">
								<div class="badge badge-warning pricing-card-active-badge px-1">
									{{ "UI.k_recommended" | translate }}
								</div>
							</ng-container>
							<div class="p-1">
								<h5 class="pricing-card-plan-name">
									{{ plan.item.name }}
								</h5>
								<div class="sm-font mb-2 fw-500">
									{{ plan.item.description }}
								</div>
								<h4>
									{{ getItemPrice(plan.item_price) | currency: plan.item_price.currency_code }}
								</h4>
								<div class="sm-font">
									{{ "UI.k_per" | translate }} {{ getPerUnit("per") }},
									{{ "UI.k_billed" | translate | lowercase }}
								</div>
							</div>
							<div class="bg-body p-1">
								<h3>
									<span class="font-weight-normal mr-50">Total</span
									><span class="fw-500 mr-50">
										{{ getTotalPrice(plan.item_price) | currency: plan.item_price.currency_code }}
										<!-- <app-animated-counter
											[end]="
												getTotalPrice(1, plan.item_price) / 1000
													| currency: plan.item_price.currency_code
											"
											[countInterval]="200"
										></app-animated-counter> -->
									</span>
									<span class="text-muted"
										><i
											class="fa-light fa-circle-info"
											[ngbTooltip]="plan.item_price.pricing_model"
										></i
									></span>
								</h3>
								<div class="sm-font">{{ "UI.k_for" | translate }} {{ getPerUnit("total") }}</div>
							</div>
							<div class="p-1 border-bottom d-flex align-items-center">
								<button
									class="btn btn-outline-primary mr-1"
									(click)="togglePublishModal(deskModal, 'trial', plan)"
								>
									Try
								</button>
								<button
									class="btn btn-primary flex-1"
									(click)="togglePublishModal(deskModal, 'prod', plan)"
								>
									Buy
								</button>
							</div>
							<div class="p-1">
								<ng-container *ngIf="pricingTypeData[i - 1]">
									<h6 class="fw-600">
										{{ "UI.k_everything_in" | translate }} {{ pricingTypeData[i - 1].item.name }}
										{{ "UI.k_and" | translate | lowercase }}...
									</h6>
								</ng-container>
								<ng-container *ngFor="let feature of plan.item?.features">
									<div class="d-flex align-items-center mt-1">
										<span class="badge badge-round badge-light-success mr-50">
											<i class="fa-light fa-check"></i>
										</span>
										<span>
											{{ feature.name }}
										</span>
									</div>
								</ng-container>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</ng-scrollbar>
</div>

<!--Preview modal template starts-->
<ng-template #deskModal let-modal>
	<div class="modal-header border-bottom">
		<div class="d-flex align-items-center">
			<div class="instance-header-img-box mr-1">
				<img
					src="{{ selectedInstanceProduct.product.img_path || '' }}"
					width="48"
					alt="{{ selectedInstanceProduct.product.name }}"
				/>
			</div>
			<div class="instance-header-detail-box">
				<h5 class="mb-25 mr-50">{{ selectedInstanceProduct.product.name }}</h5>
				<div class="d-flex align-items-center">
					<div class="badge badge-light-warning">{{ selectedInstanceProduct.item.name }}</div>
					<div class="sm-font px-50 border-right">XX Users</div>
					<div class="sm-font px-50">xxxx Assets</div>
				</div>
			</div>
		</div>
	</div>
	<div class="bg-body border-bottom px-1 py-75 d-flex align-items-center">
		<button
			class="btn"
			(click)="toggleModalInstanceType('prod')"
			[ngClass]="modalInstanceType === 'prod' ? 'btn-primary' : 'btn-flat-dark'"
		>
			Production Instances
		</button>
		<button
			class="btn"
			(click)="toggleModalInstanceType('trial')"
			[ngClass]="modalInstanceType === 'trial' ? 'btn-primary' : 'btn-flat-dark'"
		>
			Trial Instances
		</button>
	</div>
	<div class="modal-body card-bg" tabindex="0" ngbAutofocus>
		<form>
			<div>
				<ng-container *ngFor="let data of instanceList; let i = index">
					<div class="custom-control custom-radio custom-card-radio mb-2">
						<input
							type="radio"
							id="{{ data.instance_id }}"
							class="custom-control-input cursor-pointer"
							[formControl]="instanceForm"
							(change)="onInstanceChange(data.instance_id)"
							[checked]="data.instance_id === instanceForm.value"
						/>
						<label class="custom-control-label" for="{{ data.instance_id }}">
							<div class="d-flex align-items-center justify-content-between w-100 pr-2">
								<div class="d-flex align-items-center">
									<h6 class="radio-title mb-0 mr-1 fw-600">{{ data.sub_domain }}</h6>
									<a href="" class="sm-font">
										<i class="fa-light fa-arrow-up-right-from-square ml-50"></i>
									</a>
								</div>
								<div class="d-flex align-items-center">
									<ng-container *ngIf="modalInstanceType === 'trial'">
										<div class="badge badge-light-dark fw-500">Trial Ends on Xxx XX,YYYY</div>
									</ng-container>
									<ng-container *ngIf="modalInstanceType === 'prod'">
										<div class="badge badge-light-dark mr-1 fw-500">
											Next invoice issue date XXX XX,YYYY
										</div>
										<div class="sm-font"><span class="h6">$ XX</span> / month</div>
									</ng-container>
								</div>
							</div>
						</label>
					</div>
				</ng-container>
				<div class="custom-control custom-radio custom-card-radio mb-2 instance-create-radio">
					<input
						class="custom-control-input cursor-pointer"
						type="radio"
						id="chkNewInstance-{{ modalInstanceType }}"
						[formControl]="instanceForm"
						(change)="onInstanceChange(modalInstanceType)"
						[checked]="modalInstanceType === instanceForm.value"
					/>
					<label class="custom-control-label" for="chkNewInstance-{{ modalInstanceType }}">
						<div class="d-flex align-items-center justify-content-between w-100 pr-2">
							<div class="d-flex align-items-center">
								<h6 class="text-primary mb-0 mr-1">Create a new Instance</h6>
								<input
									id="chkNewInstance-{{ modalInstanceType }}-name"
									[formControl]="instanceFormName"
									class="form-control form-control-sm"
									style="width: auto"
									(change)="onChangeInstanceInput($event.target.value)"
								/>
							</div>
						</div>
					</label>
				</div>
			</div>
			<div class="d-flex justify-content-end stepper-btn flex-initial mt-2 pb-0 modal-footer border-top">
				<label *ngIf="instanceForm.invalid" class="text-warn">Please select an instance</label>
				<button type="button" class="btn btn-outline-tertiary btn-prev" (click)="closePopup()" rippleEffect>
					<span class="align-middle d-sm-inline-block d-none">Cancel</span>
				</button>
				<button
					type="submit"
					class="btn btn-primary btn-next ml-1"
					(click)="
						proceedToPayment(
							selectedInstanceProduct.product,
							selectedInstanceProduct.item,
							selectedInstanceProduct.item_price
						)
					"
					rippleEffect
				>
					<span class="align-middle d-sm-inline-block d-none" *ngIf="loader"> Processing ...</span>
					<span class="align-middle d-sm-inline-block d-none" *ngIf="!loader">Proceed to Payment</span>
				</button>
			</div>
		</form>
	</div>
</ng-template>
<!--Preview modal template ends-->
