import { Component, OnInit } from "@angular/core";
import { InfinityModules } from "app/common/dns/types/modules";

@Component({
	selector: "app-organization-invoices",
	templateUrl: "./organization-invoices.component.html",
	styleUrls: ["./organization-invoices.component.scss"],
})
export class OrganizationInvoicesComponent implements OnInit {
	module = InfinityModules.INFINITY_SUPPORT;

	constructor() {}

	ngOnInit(): void {}
}
