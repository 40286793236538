import {Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild, SecurityContext } from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {IncidentViewService} from "app/servicedesk/incident-view/services/incident-view.service";
import {ProblemService} from "app/servicedesk/problem/services/problem.service";
import * as _ from "lodash";
import {ToastrService} from "ngx-toastr";
import {CoreSidebarService} from '@core/components/core-sidebar/core-sidebar.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { WorkSpaceService } from "app/base/work-space/services/work-space.service";
import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";


@Component({
	selector: "app-ai-kb-component",
	templateUrl: "./ai-based-kb.component.html",
	styleUrls: ["./ai-based-kb.component.scss"],
})
export class AIKBComponent implements OnInit {
    @Output() onHideAIKBSolution = new EventEmitter(false);
	@Output() openKbDraftSidebar = new EventEmitter(false)
	@Output() toggleToKbSidebar = new EventEmitter(false)
    @Input() moduleType: any;
    @Input() refData: any;
    selectedOption = 10;
    page = 1;
    firstKey = 0;
    lastKey = this.selectedOption - 1;
    filteredCommunication = [];
	communication = []
	AllCommunicationCnt = 0;
	dropdownOptions : any = {};
	public allAttachments: any = {};
	is_save_disabled: boolean = false;
	ai_kb_data: any ={};
	kbCurrentItem = "solution";
	kbHideProfile = false;
	fileSelected : any = {}
	title : any ;
    constructor(
		private _incidentViewService: IncidentViewService,
		private _problemService: ProblemService,
		private _toastr: ToastrService,
		private _translateService: TranslateService,
		private _coreSidebarService: CoreSidebarService,
		private _modalService: NgbModal,
		private _workspaceService: WorkSpaceService,
		private _domSanitizerService: DomSanitizer,
	) {
	}
    ngOnInit(): void {
		this.is_save_disabled = true
		if (this.moduleType == "INCIDENT") {
			this.title = this._translateService.instant("UI.k_infiy_kb_soln")
		}
		if (this.moduleType == "PROBLEM") {
			this.title = this._translateService.instant("UI.k_infiy_prob_kb_soln")
		}
		if (this.refData?.fault_symptoms){
			let element = {'description': this.refData?.fault_symptoms}
			this.filteredCommunication.push(element)
		}
		this.refData?.analysis?.forEach(element => {
			element['description'] = this._domSanitizerService.bypassSecurityTrustHtml(element?.root_cause_details)
			this.filteredCommunication.push(element)
		});
		this.refData?.submission_solution?.forEach(element => {
			element['description'] = this._domSanitizerService.bypassSecurityTrustHtml(element?.solution)
			this.filteredCommunication.push(element)
		});
		this.refData?.submission_workaround?.forEach(element => {
			element['description'] = this._domSanitizerService.bypassSecurityTrustHtml(element?.workaround)
			this.filteredCommunication.push(element)
		});
        this.getMailConversations()
		this.getAttachmentDetails(this.refData?.ref_id)
		this.dropdownOptions['tagType'] = [
			{'id':'issue ', 'name':'Issue'},
			{'id':'solution', 'name':'Solution'},
			{'id':'conversation', 'name':'Conversation'}
		]
		this.is_save_disabled = false
    }
	getMailConversations() {
		const payload = {
			page: this.page,
			items_per_page: this.selectedOption,
			ref_id: this.refData?.ref_id,
			comments: true,
			emails: true,
			sms: true,
		};
		if(this.moduleType === "INCIDENT"){
			this._incidentViewService.getMailConversations(payload).subscribe(
				(response) => {
					if (response) {
						let newComments = response?.data;
						this.AllCommunicationCnt = response?.count;
						if (newComments.length > 0) {
							this.communication = this.communication.concat(newComments);
							newComments = newComments?.reverse()
							let filteredkbCommn = newComments.filter(
								(item) =>{
									if(item?.is_description == true){
										if(item?.table == 'email'){
											this.refData['description'] = this._domSanitizerService.bypassSecurityTrustHtml(item?.new_reply_mail ? item.new_reply_mail : item?.content)
										}else{
											this.refData['description'] = this._domSanitizerService.bypassSecurityTrustHtml(item?.description)
										}
									}
									return (item?.is_system_gen ? item.is_system_gen==false : true) &&
									item?.type != "Resolution" && (item?.is_description ? item.is_description== false : true)
							})
							filteredkbCommn.forEach(element => {
								if(element?.table == 'email'){
									element['description'] = this._domSanitizerService.bypassSecurityTrustHtml(element?.new_reply_mail ? element.new_reply_mail : element?.content)
								}else{
									element['description'] = this._domSanitizerService.bypassSecurityTrustHtml(element?.description)
								}
							});
							this.filteredCommunication = this.filteredCommunication.concat(filteredkbCommn)
						}
						
					}
				},
				(error) => {
					//todo
				}
			);
		}
		if(this.moduleType === "PROBLEM"){
			this._problemService.getMailConversations(payload).subscribe(
				(response) => {
					if (response) {
						const newComments = response?.data;
						this.AllCommunicationCnt = response?.count;
						if (newComments.length > 0) {
							this.communication = this.communication.concat(newComments);
							let filteredkbCommn = newComments.filter(
								(item) =>{
									if(item?.is_description == true){
										if(item?.table == 'email'){
											this.refData['description'] = this._domSanitizerService.bypassSecurityTrustHtml(item?.new_reply_mail ? item.new_reply_mail : item?.content)
										}else{
											this.refData['description'] = this._domSanitizerService.bypassSecurityTrustHtml(item?.description)
										}
									}
									return (item?.is_system_gen ? item.is_system_gen==false : true) &&
									item?.type != "Resolution" && (item?.is_description ? item.is_description== false : true)
							})
							filteredkbCommn.forEach(element => {
								if(element?.table == 'email'){
									element['description'] = this._domSanitizerService.bypassSecurityTrustHtml(element?.new_reply_mail ? element.new_reply_mail : element?.content)
								}else{
									element['description'] = this._domSanitizerService.bypassSecurityTrustHtml(element?.description)
								}
							});
							this.filteredCommunication = this.filteredCommunication.concat(filteredkbCommn)
						}
						
					}
				},
				(error) => {
					//todo
				}
			);
		}
	}
    loadNextComments = () => {
		this.page = this.page + 1;
		this.getMailConversations()
		
	};
    toggleSidebar = (): void => {
	this.onHideAIKBSolution.emit();
	};
	checkOptions = () =>{

	}
	getAttachmentDetails = (ref_id) => {
		if(this.moduleType === "INCIDENT"){
			const payload = { incident_id: ref_id };
			this._incidentViewService.getAttachmentDetails(payload).subscribe((response) => {
				this.allAttachments = response;
			});
		}
		if(this.moduleType === "PROBLEM"){
			const payload = { problem_id: ref_id };
			this._problemService.getAttachmentDetails(payload).subscribe((response) => {
				this.allAttachments = response;
			});
		}
		
	};
	createAIKB = (data) =>{
		if (data.form.valid === true) {
			this.is_save_disabled = true
			let payload = {
				issue: [],
				solution: [],
				conversation: [],
				ref_id:this.refData?.ref_id,
				module_id :this.refData?.module_id
			}
			payload['issue'].push(this.refData?.content)
			if(this.refData?.description){
				payload['issue'].push(this._domSanitizerService.sanitize(SecurityContext.HTML, this.refData['description']))
			}
			this.refData?.solutions?.forEach(element => {
				if (element){
						payload['solution'].push(this._domSanitizerService.sanitize(SecurityContext.HTML, element))

				}
				
				
			});
			
			this.filteredCommunication.forEach(element => {
				if(element?.selected){
					let description = this._domSanitizerService.sanitize(SecurityContext.HTML, element?.description)
					if(element?.tagType == 'issue'){
						payload['issue'].push(description)
					}
					else if(element?.tagType == 'solution'){
						payload['solution'].push(description)
					}
					else if(element?.tagType == 'conversation'){
						payload['conversation'].push(description)
					}
				}
			});
			this._workspaceService
			.AIBasedKBCreate(payload)
			.pipe(
				map((response: any) => {
					if (response?.status == 'success') {
						this.is_save_disabled = true;
						this.ai_kb_data = response?.data;
						this.ai_kb_data['files']= this.fileSelected
						this.ai_kb_data['content'] = this.ai_kb_data?.problem_details;
						this.ai_kb_data['solutions'] = [{
							'root_cause': this.ai_kb_data?.root_cause,
							'solution': this.ai_kb_data?.solution
						}]
						this.openKbSidebar()
					} else {
						this._toastr.error(
							this._translateService.instant(response["message"]),
							this._translateService.instant("Error.err_fail"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						this.is_save_disabled = false;
					}
					// Success
				}),
				catchError((error) => {
					this.is_save_disabled = false;
					this._toastr.error(
						this._translateService.instant('Message.msg_error'),
						this._translateService.instant("Error.err_fail"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					console.log("err",error)
					return throwError(error.statusText);
				})
			)
			.subscribe();

		}
	}
	onToggle(event, data) {
		if (event.checked) {
			if(!data?.tagType || data?.tagType == undefined || data?.tagType == null){
				data['tagType'] = 'conversation'
			}
		} 
	}
	onToggleFileSelect(event, fileData) {
		if (event.checked) {
			this.fileSelected[fileData.attachment_id] = fileData
		}else{
			delete this.fileSelected[fileData.attachment_id]
		}
	}
	isObjectEmpty(obj) {
        return Object.keys(obj).length === 0;
    }
	openKbSidebar = (): void => {
		this.ai_kb_data['kbtype'] = "solution" // overwriting for othter kb type in corresponding module
		this.openKbDraftSidebar.emit(this.ai_kb_data);
	};
	onManualConvertToKbSidebar = () =>{
		this.onHideAIKBSolution.emit();
		this.toggleToKbSidebar.emit()
	}


}