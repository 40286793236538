import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FileUploader } from 'ng2-file-upload';
import { environment } from 'environments/environment';
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { getFileSizeText, validateFile } from "../../../../common/utils/utils";
const URL = `${environment.instance_url}` + 'media'
import { from } from "rxjs";
import {default as _map} from "lodash-es/map";
import { map, reduce } from "rxjs/operators";
import { InfinityModules } from "app/common/dns/types/modules";
import { GlobalConstants } from "app/app.globalConstants";
import { IncidentViewService } from 'app/servicedesk/incident-view/services/incident-view.service';
@Component({
	selector: 'app-dns-attachment',
	templateUrl: './dns-attachment.component.html',
	styleUrls: ['./dns-attachment.component.scss']
})
export class DnsAttachmentComponent implements OnInit {

	@Output() hideAddAttachment = new EventEmitter(false);
	@Output() loadAttachments = new EventEmitter(false);
	@Input() public incidentId: string;
	@Input() public displayId: string;
	@Input() public summary: string;
	@Input() allowed_file_types = [];
	@Input() max_file_size = 20971520;
	public attachment_category = '1'
	public attachment_description = ''
	public allAttachments: any = [];
	public selectedFiles = []
	public errMsg =this._translateService.instant('UI.k_field_required') 
	public selectCategoryErrMsg = this.errMsg
	public selectCategoryInputErr = false
	public attachment_category_name = undefined;
	public attachmentUploader: FileUploader = new FileUploader({
		url: URL,
		isHTML5: true
	});

	public options: any = {};
	attachments: any = [];
	disableBtn: boolean = false;

	//image uploader
	public imgDetailsObject = {
		resizeToWidth: "128",
		cropperMinWidth: "128",
		maintainAspectRatio: false,
	  };
	  public showQueue = false;
	  public multipleFiles = false;
	  public displayResoluion = true;
	  disableUploadBtn = true;
	  public ALLOWED_TYPES = ["txt", "doc", "docx", "csv", "xls", "pdf"];
	  public MAX_IMAGE_SIZE = 20971520;
	load_attachment_upload = false;
	asset_module = InfinityModules.CMDB;
	asset_permissions: any;

	constructor(
		private _coreSidebarService: CoreSidebarService,
		private _incidentViewService: IncidentViewService,
		private _toastr: ToastrService,
		private _translateService: TranslateService,
	) { }

	getInitOptionConfig = () => {
		let payload = {}
		this._incidentViewService.getOptions(payload).subscribe(
			(response) => {
				this.options = response;			
				this.allowed_file_types = response?.image_upload_defaults?.allowed_types;
				this.max_file_size = response?.image_upload_defaults?.max_size;
				if (this.options?.attach_category_list) {
				  this.options?.attach_category_list?.every((o: any) => {
					if (o?.id === this.attachment_category) {
					  this.attachment_category_name = o?.name;
					  return false;
					}
					return true;
				  });
				}
				this.load_attachment_upload = true
			}
		)
	}

	ngOnInit(): void {
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.asset_permissions = user_permissions[this.asset_module];
		this.getInitOptionConfig()
	}

	toggleSidebar = (name): void => {
		this._coreSidebarService
			.getSidebarRegistry(name)
			.toggleOpen();
		this.hideAddAttachment.emit(true);
	};

	addNewAttachments = (payload) => {
		this.disableBtn = true
		if (this.options?.attachment_id_map != undefined){
			payload['category_name'] = this.options?.attachment_id_map[payload.categoryId]
		}
		this._incidentViewService.addAttachments(payload).subscribe(
			(response) => {
				if (response['status'] == 'success') {
					this.toggleSidebar('app-dns-attachment')			
					this._toastr.success(
					this._translateService.instant(response["message"]),
					this._translateService.instant("Message.msg_success"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
				this.loadAttachments.emit(this.selectedFiles)
				}
				else if (response['status'] == 'failed') {
					this.disableBtn = false
					this._toastr.error(
						this._translateService.instant(response["message"]),
						this._translateService.instant("Error.err_fail"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
				}
			},
			(error) => {
				//todo
				this.disableBtn = false
				//console.log("err")
			}
		)
	}

	removeFile = (idx) => {
		this.selectedFiles.splice(idx, 1)
		this.attachmentUploader.queue.splice(idx, 1);
		if (this.selectedFiles?.length > 0) {
			this.disableBtn = false;
		} else {
			this.disableBtn = true;
		}
		this.attachmentUploader.queue = [];
	}

	deleteAllFiles = () => {
		this.selectedFiles = [];
		this.attachmentUploader.queue = [];
		this.disableBtn = true;
	}

	uploadAttachments = () => {
		let total_file_size = 0;
		this.disableUploadBtn = true
		for (let idx in this.selectedFiles) {
			total_file_size += parseInt(this.selectedFiles[idx]['file_size'])
		}
		total_file_size = (total_file_size / 1024 / 1024)
		for (let key of this.selectedFiles){
			key['categoryId'] = this.attachment_category
			key['category_id'] = this.attachment_category
			key["file_name"] = key?.name;
			key["file_size"] = key?.file_size || key?.size;
			key["description"] = this.attachment_description
		}let payload = {
			files: this.selectedFiles,
			incidentId: this.incidentId,
			displayId: this.displayId,
			summary: this.summary,
			categoryId: this.attachment_category,
			description: this.attachment_description,
			
		}
		if (total_file_size > 5) {
			this._toastr.warning(
				this._translateService.instant("Error.err_file_limit"),
				this._translateService.instant("Message.msg_warning"),
				{
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				}
			);
			this.disableUploadBtn = false
		}
		else {
			if (!this.attachment_category) {
				this.selectCategoryInputErr = true
				this.selectCategoryErrMsg = this._translateService.instant("Message.msg_select_category")
				this.disableUploadBtn = false
			}
			else this.addNewAttachments(payload)
		}
	}

	getSelectedFiles = function (evt: EventEmitter<File[]>) {
		this.selectedFiles = []
		this.attachments = []
		for (let idx = 0; idx < this.attachmentUploader.queue.length; idx++) {
			let tempFile = {};
			let file = this.attachmentUploader.queue[idx]._file
			const fileValidationData = validateFile(file, this.allowed_file_types);
			if (fileValidationData && fileValidationData["is_valid"]) {
			let reader = new FileReader();
			let fileLoaded = (event) => tempFile['file_data'] =  event.target.result;
			reader.onload = fileLoaded;
			reader.readAsDataURL(file)
			tempFile['file_name'] = file.name;
			tempFile['file_size'] = file.size;
			tempFile['file_date'] = file.lastModified;
			tempFile['file_type'] = file.type;
			tempFile['category_id'] = this.attachment_category;
			tempFile['description'] = this.attachment_description;
			this.selectedFiles.push(tempFile)
			} else{
				if (fileValidationData && !fileValidationData["is_valid"]) {
					this.attachmentUploader.queue.splice(idx, 1);
					const errors = [];
					if (fileValidationData["is_allowed"] === false) {
					  errors.push(
						this._translateService.instant("UI.k_supported_format") +
						  _map(this.allowed_file_types, (val) => " ." + val)
					  );
					}
		  
					if (fileValidationData["size"]) {
					  errors.push(
						this._translateService.instant("Message.msg_max_size") +
						  " " +
						  getFileSizeText(this.max_file_size)
					  );
					}
		  
					from(errors)
					  .pipe(
						map((err) => `<li>${err}</li>`),
						reduce((acc, value) => acc.concat(value), "")
					  )
					  .subscribe((res) => {
						this._toastr.warning(
						  `<b>${fileValidationData["name"]}</b></br><ul class="pl-1">${res}</ul`,
						  this._translateService.instant("Error.err_file_upload_failed"),
						  {
							enableHtml: true,
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						  }
						);
					  });
				  }
			}
		}
	}
	getAllowedFileTxt = () => {
		return _map(this.allowed_file_types, (d) => " ." + d);
	  };
	
	  fileDetails = ($event) => {
		if (!this.multipleFiles) {
		  this.selectedFiles = [];
		}
		this.selectedFiles = this.selectedFiles.concat(Array.from($event));
		if (this.selectedFiles?.length > 0) {
		  this.disableUploadBtn = false;
		} else {
		  this.disableUploadBtn = true;
		}
	  };
	
	  onCategoryChange($event: any) {
		this.attachment_category_name = $event?.name;
		this.attachment_category = $event?.id;
	  }
}
