<ng-container *appDnsLicenseHandler="item?.license || {}">
	<ng-container *appIsGranted="item.permission">
		<!-- collapsible title -->
		<a
			class="d-flex align-items-center"
			[ngClass]="item.classes"
			[ngClass]="item.type == 'section' ? 'dropdown-toggle nav-link' : 'dropdown-item dropdown-toggle'"
			*ngIf="!item.url"
		>
			<ng-container *ngTemplateOutlet="itemContent"></ng-container>
		</a>

		<!-- itemContent template -->
		<ng-template #itemContent>
			<span *ngIf="item.icon"><i class="fa-light {{ item.icon }} fa-lg"></i></span>
			<span [translate]="item.translate">{{ item.title }}</span>
		</ng-template>

		<!-- sub-menu item/collapsible -->
		<ul class="dropdown-menu" [ngClass]="{ show: isShow }">
			<ng-container *ngFor="let item of item.children">
				<!-- item -->
				<li
					core-menu-horizontal-item
					*ngIf="item.type == 'item'"
					[item]="item"
					[ngClass]="{ disabled: item.disabled === true }"
					[routerLink]="item.openInNewTab ? [] : [item.url]"
					[routerLinkActive]="!item.openInNewTab ? 'active' : ''"
					[routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
					attr.data-testid="btn_{{ item.id }}"
				></li>
				<!-- collapsible -->
				<li
					core-menu-horizontal-collapsible
					*ngIf="item.type == 'collapsible'"
					[item]="item"
					class="dropdown dropdown-submenu"
				></li>
			</ng-container>
		</ul>
	</ng-container>
</ng-container>
