import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CoreConfigService } from "@core/services/config.service";
import { TranslateService } from "@ngx-translate/core";
import { GlobalConstants } from "app/app.globalConstants";
import { InfinityModules } from "app/common/dns/types/modules";

@Component({
	selector: "app-support-home",
	templateUrl: "./support-home.component.html",
	styleUrls: ["./support-home.component.scss"],
})
export class SupportHomeComponent implements OnInit {
	permissions: any;
	moduleName = InfinityModules.INFINITY_SUPPORT;
	public selectedChartItem: string;
	public orgObj = {};

	constructor(
		private router: Router,
		private _coreConfigService: CoreConfigService,
		private _translateService: TranslateService
	) {}

	ngOnInit(): void {
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.permissions = user_permissions[this.moduleName];
		if (this.permissions == undefined) {
			this.router.navigate(["/error/403"]);
		}
		this.setNavbarConfig();
	}

	onSelectChart = (itemid, item) => {
		this.selectedChartItem = item.id;
	};
	setNavbarConfig(): void {
		this._coreConfigService.config = {
			layout: {
				navbar: {
					hidden: false,
					pageTitle: this._translateService.instant("UI.k_organizations"),
					moduleName: this.moduleName,
					bookmark: false,
					showBreadcrumb: false,
					enableLocalStorage: false,
					pageIcon: "",
					search: [],
					breadcrumb: {},
				},
			},
		};
	}
}
