<div class="content-wrapper" appCheckRouteCondition>
	<div class="content-body">
		<div class="card">
			<div class="card-body">
				<ng-container *ngFor="let item of multiPleComponentArray; let i = index">
					<app-dynamic-dropdown
						[dynamicDropDown]="item"
						(emittedFromDynamicDropdown)="emittedFromParent($event)"
					></app-dynamic-dropdown>
					<!--          <div-->
					<!--            class="bottom-sec"-->
					<!--            *ngIf="optionsList?.ruleOptions"-->
					<!--          >-->
					<!--            <div class="drop-btn">-->
					<!--              <div class="form-group">-->
					<!--                <ng-select-->
					<!--                  [name]="'selAndOr' + item.id"-->
					<!--                  [(ngModel)]="item.operator"-->
					<!--                  style="max-width: 90px"-->
					<!--                  [items]="optionsList.ruleOptions"-->
					<!--                  bindLabel="name"-->
					<!--                  id="selAndOr{{item.id}}"-->
					<!--                  placeholder="select"-->
					<!--                  (change)="operatorDropdownEvent($event, item)"-->
					<!--                  bindValue=""-->
					<!--                >-->
					<!--                </ng-select>-->
					<!--              </div>-->
					<!--            </div>-->
					<!--          </div>-->
				</ng-container>
			</div>
		</div>
	</div>
</div>

<!-- <div class="content-wrapper">
  <div class="content-body">
    <div class="card">
      <div class="card-body">
        <app-asset-life-cycle></app-asset-life-cycle>
      </div>
    </div>
  </div>
</div> -->
