import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { InfinityModules } from 'app/common/dns/types/modules';
import { ToastrService } from 'ngx-toastr';
import { GlobalConstants } from "app/app.globalConstants";
import { GridNameDesignComponent } from 'app/common/dns/components/grid-name-design/grid-name-design.component';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';

@Component({
	selector: "app-msp-user",
	templateUrl: "./msp-user.component.html",
	styleUrls: ["./msp-user.component.scss"],
})
export class MspUserComponent implements OnInit {
	public permissions: any;
	public module = InfinityModules.USER;

	public userData = [];
	public savedData = [];
	public dropdownOptions: any = [];
	public searchOptions: any = {};
	public conditionOptions = [];
	public empty_data: any;
	public ciCount = 3;
	public pageNumberGrid = 0;
	public calendarCustomWidth = "width:224px";
	public showDataTable = false;
	public showRightsection: false;
	public isExpandSearchWidth: boolean;
	public showAssignUserSidebar = false;
	public columnData;

	@Output("rangeSelect") timeFilter: EventEmitter<any> = new EventEmitter<any>();

	constructor(
		@Inject(DOCUMENT) private document,
		private _activatedRoute: ActivatedRoute,
		private _coreSidebarService: CoreSidebarService,
		private modalService: NgbModal,
		private _coreConfigService: CoreConfigService,
		private _translateService: TranslateService,
		private _router: Router,
		private _toastr: ToastrService
	) {}

	ngOnInit(): void {
		this.updateNavBar();
		let user_permissions: any = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		if (Object.keys(user_permissions).length != 0) {
			// this.licenseData = user_permissions?.usr_lic?.keys_dict?.MONITORING;
		}
		this.permissions = user_permissions[this.module];

		this.empty_data = {
			icon: "fa-box-taped",
			msg: this._translateService.instant("Message.msg_user_skeleton_heading"),
			description: this._translateService.instant("Message.msg_user_skeleton_descr"),
			// add_callback: this.toggleAddOrganization,
			// sidebar_name: "add-organization-sidebar",
			button: this._translateService.instant("UI.k_invite_user"),
			import_csv: false,
			id: "invite_user",
		};

		this.columnData = [
			{
				label: "Name",
				key: "msp_user_name",
				pre_selected: true,
				sortkey: "msp_user_name",
				width: 180,
				component: GridNameDesignComponent,
				// callback: this.OnShowDetailSidebar,
			},
			{
				label: "email",
				key: "msp_user_email",
				pre_selected: true,
				sortkey: "msp_user_email",
				width: 200,
				component: GridNameDesignComponent,
			},
			{
				label: "status",
				key: "msp_status",
				pre_selected: true,
				sortkey: "msp_status",
				width: 150,
				component: GridNameDesignComponent,
			},
			{
				label: "assigned organization",
				key: "assigned_organization",
				pre_selected: true,
				sortkey: "assigned_organization",
				width: 230,
				component: GridNameDesignComponent,
				callback: this.AssignUserSidebar,
			},
			{
				label: "Last Active",
				key: "msp_last_active_user",
				pre_selected: true,
				sortkey: "msp_last_active_user",
				width: 200,
				component: GridNameDesignComponent,
			},
			{
				label: this._translateService.instant("UI.k_action"),
				key: "action",
				width: 130,
				actions: [
					{
						label: this._translateService.instant("UI.k_edit"),
						icon: "fa-pen-to-square",
						key: "edit",
						class: "text-primary mr-50",
						listener: (raw: any) => {
							// this.editOrg(raw);
						},
					},
					{
						label: this._translateService.instant("UI.k_delete"),
						icon: "fa-trash-can",
						key: "delete",
						class: "text-danger",
						listener: (raw: any) => {
							// this.deleteOrg(raw);
						},
					},
				],
			},
		];

		this.userData = [
			{
				user_name: {
					name: "Theresa Webb",
					profile_image: "../../../../assets/images/avatars/1.png",
					email: "theresa@gmail.com",
					avatar_color: "#f59518",
				},
				status: "inactive",
				last_active: "22 May 2021",
				assigned_org: [
					{
						name: "Faderal Bank",
						profile_image: "../../../../assets/images/msp/image 21.svg",
						avatar_color: "#f59518",
					},
					{
						name: "Jio",
						profile_image: "../../../../assets/images/msp/image 23.svg",
						avatar_color: "#f59518",
					},
				],
			},
		];
	}

	updateNavBar() {
		// Configure the layout
		this._coreConfigService.config = {
			layout: {
				navbar: {
					hidden: false,
					pageTitle: this._translateService.instant("UI.k_msp_user"),
					moduleName: this.module,
					backgroundColor: "",
					pageIcon: "fa-box-taped",
					showBreadcrumb: false,
					breadcrumb: "",
				},
			},
		};
	}

	getFilterSearch = (e) => {};
	AssignUserSidebar = (data) => {
		this.showAssignUserSidebar = true;
		this._coreSidebarService.getSidebarRegistry("assign-user-sidebar").toggleOpen();
	};
	onHideAssignUserSidebar = (e) => {
		this.showAssignUserSidebar = false;
		this._coreSidebarService.getSidebarRegistry("assign-user-sidebar").close();
	}
	genericSearchFilter = (e) => {};
	getSearchWidth = (e) => {
		if (e) {
			this.isExpandSearchWidth = true;
		} else {
			this.isExpandSearchWidth = false;
		}
	};
	onRangeSelection = ($event) => {
		this.timeFilter.emit($event);
	};
	inviteUser() {}
}
