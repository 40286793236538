import { InfinityModuleIDs } from "./module_type";

export const WORKFLOW_BLOCK_TYPE = {
	STATE: 1,
	CONDITION: 2,
	ACTION: 3,
	CONDITION_SUCCESS: 4,
	CONDITION_FAILED: 5,
	APPROVAL_ACCEPTED: 6,
	APPROVAL_REJECTED: 7,
	REVIEW_ACCEPTED: 8,
	REVIEW_REJECTED: 9,
};

export const WF_RESTRICTED_REPORTING_MANAGER_MODULES = [
	InfinityModuleIDs.CONTRACT,
	InfinityModuleIDs.PROBLEM,
	InfinityModuleIDs.IMACD,
];
