<div class="full-body-height">
	<!-- <button (click)="toggleSidebar('get-in-touch','getInTouch')">Toggle</button> -->
	<!-- <div class="align-items-center d-flex p-1 justify-content-between admin-user-page-header"> -->
	<div class="main-content flex-height admin-user-page-header">
		<div class="main-sec">
			<div class="action-bar">
				<div class="inner-section">
					<div class="left-section">
						<app-search
							(getfilterData)="getFilterSearch($event)"
							[filterDetails]="getAuditList"
							[showDataTable]="showDataTable"
							[columnOptions]="columnOptions"
							[conditionOptions]="conditionOptions"
							[showRightsection]="showRightsection"
							[savedData]="savedData"
							[Options]="option"
							[module]="module"
							(genericFilter)="genericSearchFilter($event)"
						></app-search>
						<div class="date-range" id="dtRange" name="datePicker" style="min-width: 240px">
							<date-range-picker (select)="onRangeSelect($event)" [selectedDuration]="timeFilterObj"></date-range-picker>
						</div>
					</div>
					<div class="right-section"></div>
				</div>
			</div>
			<div class="action-section">
				<div class="table-view flex-height">
					<!-- <dns-server-grid [rows]="audits" [columns]="columns" (page)="setPage($event)" [count]="totalCount"
                        [columns]="columns" [isEditable]="true" (sort)="onSort($event)" (setlimit)="setLimit($event)"
                        [limit]="limit" class="bootstrap core-bootstrap"  scrollbarH="true" [permissions]="permissions"
                        [selectionType]="SelectionType.checkbox" (onSelectCheckbox)="onSelectRow($event)" hideFooter="true">
                    </dns-server-grid> -->
					<dns-server-grid
						[rows]="audits"
						[columns]="columns"
						[count]="auditCount"
						(editCallback)="onUpdate($event)"
						(page)="setPage($event)"
						(sort)="onSort($event)"
						[options]="options"
						[isEditable]="false"
						(setlimit)="setLimit($event)"
						[limit]="limit"
						class="bootstrap core-bootstrap"
						[permissions]="permissions"
						[showData]="showData"
						[emptyData]="empty_data"
						[filterData]="filterData"
					>
					</dns-server-grid>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- <core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="get-in-touch"
	overlayClass="modal-backdrop"
>
	<app-get-in-touch
		*ngIf="showSidebarDetails?.getInTouch" 
	></app-get-in-touch>
</core-sidebar> -->
