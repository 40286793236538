import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AdminService, ComputeService } from "../../service";
import { InstanceIdGroup } from "../../models";
import { Instance } from "flatpickr/dist/types/instance";

@Component({
	selector: "app-dashboard-instance",
	templateUrl: "./dashboard-instance.component.html",
	styleUrls: ["./dashboard-instance.component.scss"],
})
export class DashboardInstanceComponent implements OnInit {
	public activeMenu = "products";
	public instanceData: InstanceIdGroup;
	instanceId: string;
	public instanceInfo: Instance;

	public menuList = [
		{
			id: "products",
			name: this._translateService.instant("UI.k_products"),
			handle: "products",
		},
		{
			id: "invoices",
			name: this._translateService.instant("UI.k_invoices"),
			handle: "invoices",
		},
		// {
		// 	id: "security",
		// 	name: this._translateService.instant("UI.k_security"),
		// 	handle: "security",
		// },
		// {
		// 	id: "settings",
		// 	name: this._translateService.instant("UI.k_settings"),
		// 	handle: "settings",
		// },
	];

	constructor(
		private _route: ActivatedRoute,
		private _translateService: TranslateService,
		private _service: AdminService,
		private _compute: ComputeService
	) {
		this._route.paramMap.subscribe((params: any) => {
			this.instanceId = params.get("id");
		});
	}

	ngOnInit(): void {
		this._service
			.getInstanceSubscriptions({
				type: "instance",
				instanceId: this.instanceId,
			})
			.subscribe({
				next: (response: InstanceIdGroup[]) => {
					if (response.length == 1) this.instanceData = response[0];
				},
				error: (e: any) => {
					console.error(e);
				},
			});
		this._service
			.getInstance({
				instanceId: this.instanceId,
			})
			.subscribe({
				next: (response: Instance) => {
					this.instanceInfo = response;
				},
				error: (e: any) => {
					console.error(e);
				},
			});
	}

	instanceCurrency(): string {
		// TODO - handling need to taken care
		let x = "";
		let data = this.instanceData?.data || [];
		for (let i = 0; i < data.length; i++) {
			x = data[i].customer.preferred_currency_code;
			if (x) {
				break;
			}
		}
		return x;
	}

	totalCredit(): number {
		// TODO - handling need to taken care
		let x = 0;
		let data = this.instanceData?.data || [];
		for (let i = 0; i < data.length; i++) {
			x = this._compute.totalCredits(data[i].customer) / 100;
			if (x) {
				break;
			}
		}
		return x;
	}

	toggleMenu = (handle: string) => {
		this.activeMenu = handle;
	};
}
