import { ModuleWithProviders, NgModule, Optional, SkipSelf, FactoryProvider } from '@angular/core';

import { CORE_CUSTOM_CONFIG, WINDOW } from '@core/services/config.service';

const windowProvider: FactoryProvider = {
	provide: WINDOW,
	useFactory: () => window,
};

@NgModule()
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('Import CoreModule in the AppModule only');
    }
  }

  static forRoot(config): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: CORE_CUSTOM_CONFIG,
          useValue: config
        },
        windowProvider,
      ]
    };
  }
}
