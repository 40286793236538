import {
	ComponentFactoryResolver,
	Directive,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	Type,
	ViewContainerRef,
} from "@angular/core";

@Directive({
	selector: "[appComponentLoader]",
})
export class ComponentLoaderDirective implements OnInit, OnChanges {
	@Input("cl-data") data: any;
	@Input("cl-key") key: any;
	@Input("cl-subkey") subkey: any;
	@Input("cl-url") url: any;
	@Input("cl-id") id: any;
	@Input("cl-component") component: Type<any>;
	@Input("cl-rowindex") rowindex: any;
	@Input("cl-target") target: any;
	componentRef: any;
	@Output("cl-callback") callback = new EventEmitter<object>(); // to give data back to parent component on click action

	constructor(private compRef?: ComponentFactoryResolver, private viewRef?: ViewContainerRef) {}

	ngOnInit() {
		if (this.component) {
			const componentFactory = this.compRef.resolveComponentFactory(this.component);
			this.viewRef.clear();
			this.componentRef = this.viewRef.createComponent<any>(componentFactory);
			this.initializeView();
		}
	}
	ngOnChanges() {
		/**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/
		//Write your code here
		this.initializeView();
	}

	initializeView = () => {
		if (this.componentRef && this.componentRef?.instance) {
			this.componentRef.instance.data = this.data;
			this.componentRef.instance.key = this.key;
			this.componentRef.instance.subkey = this.subkey;
			this.componentRef.instance.rowindex = this.rowindex;
			this.componentRef.instance.url = this.url;
			this.componentRef.instance.id = this.id;
			this.componentRef.instance.target = this.target;
			this.componentRef.instance.callback?.subscribe((res) => {
				this.callback.emit(res);
			});
		}
	};
}
