/**
 * This enum will contain all modules key
 */
export enum InfinityModules {
	USER = "user",
	USER_ROLES = "userroles",
	ORGANIZATION = "organization",
	ORGANIZATION_LOCATION = "organization_location",
	DASHBOARD = "dashboard",
	REPORT = "report",
	REQUESTER = "requester",
	INCIDENT = "incident",
	MY_PROFILE = "myprofile",
	AUDIT = "audit",
	HISTORY = "history",
	BIZ_HOUR = "biz_hour",
	DEVICE_PROFILE = "device_profile",
	HOLIDAY = "holiday",
	MESSENGER = "messenger",
	TAG = "tag",
	CONFIGURATION_PARAMETERS = "configuration_parameters",
	DOWNLOAD_JOBS = "configuration_download_jobs",
	BASELINE_CONFIGURATION = "baseline_scheduler",
	DISCOVERY = "discovery",
	PASSWORD_POLICY = "password_policy",
	DEPARTMENT = "department",
	TEAMS = "teams",
	LEAVES = "leaves",
	API_REGISTER = "api_register",
	SERVICE_CATALOGUE = "servicecatalogue",
	BUSINESS_CATALOGUE = "businesscatalogue",
	WORKFLOW = "workflow",
	AGENT = "agent",
	KNOWLEDGEBASE = "knowledgebase",
	CMDB = "cmdb",
	EVENTS = "events",
	THRESHOLDS = "thresholds",
	SLA = "sla",
	SERVICES = "services",
	JOBS = "jobs",
	SMS_CONFIG = "sms_config",
	IMAP_CONFIG = "imap_configuration",
	TEAM_ESCALATION = "team_escalation",
	REQUEST_PROCESS = "request_process",
	SOFTWARE_LICENSE = "software_license",
	NETWORK_DIAGRAM = "network_diagram",
	PROBLEM = "problem",
	BUSINESS_RULE = "business_rule",
	CHANGE = "change",
	TOPOLOGY = "topology",
	RELEASE = "release",
	INFINITY_SUPPORT = "infinity_support",
	VENDOR = "vendor",
	BASELINE_SCHEDULER = "baseline_scheduler",
	CORRELATION_RULE = "correlation_rule",
	TEMPLATE = "template",
	MAINTENANCE = "maintenance",
	MARKETPLACE = "MARKETPLACE",
	INFINITY_PARTNER = "infinity_partner",
	SUBSCRIPTION = "subscription",
	TASK = "task",
	SHIFTMANAGEMENT = "shift_management",
	CIRELATIONRULE = "ci_relation_rule",
	CONTRACT_MANAGEMENT = "contract",
	IMACD = "imacd",
	BOTS_CONFIGUTATION = "bots_configuration",
	PROJECT = "project",
	CSAT = "csat",
	UNMASK_REQUESTER_CONTACT = "unmask_requester_contact",
	CUSTOMER ="customer",
	UNMASK_REQUESTER_NAME = "unmask_requester_name",
	UNMASK_REQUESTER_EMAIL = "unmask_requester_email",
	WORKSPACE = "workspace",
	PURCHASE_ORDER = "purchase_order",
}
