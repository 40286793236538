<div class="slideout-content default-slideout">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog sidebar-md">
            <div *ngIf="incident_data != undefined" class="modal-content pt-0">
                <div class="modal-header">
                    <h5 class="modal-title" id="resolveIncident">
                        {{ resolve_title }}
                    </h5>
                        <button
                        type="button"
                        id="btnResolveClose"
                        name="btnResolveClose"
                        [attr.data-testid]="'btnResolveClose'"
                        class="btn btn-flat-secondary btn-icon"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="toggleSidebar('resolve-incident')"
                    >
                        <i class="fa-light fa-xmark"></i>
                    </button>
                </div>
                <form #resolvedIncidentForm="ngForm" class="modal-body">
                    <div class="flex-auto-height">
                        <ng-scrollbar class="custom-scrollbar section-scrollbar">
                            <div class="modal-body-inner p-1">
                                <div class="card border border-rounded">
                                    <div class="summary-info p-1">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex justify-content-start align-items-center">
                                                <div>
                                                    <span class="fw-500 reg-font primary-color">{{
                                                        this.incident_data?.display_id
                                                    }}</span>
                                                </div>
                                                <div class="rounded icon-class mx-50">
                                                    <div class="resolve-card-config" id="btnGlobe">
                                                        <i
                                                            class="fa-light fa-{{
                                                                sourceIconMap[
                                                                    incident_data?.basic_info?.incident_source?.id
                                                                ]
                                                            }}"
                                                        ></i>
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="incident_data?.is_parent_incident"
                                                    class="rounded"
                                                    id="btnShare"
                                                >
                                                    <div class="resolve-card-config">
                                                        <i class="fa-light fa-share-nodes"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="mx-50"
                                                *ngIf="this.incident_data?.basic_info?.priority?.name"
                                            >
                                                <!-- <div *ngIf="this.incident_data?.basic_info?.priority?.name"> -->
                                                <div
                                                    class="badge badge-light-{{
                                                        incident_data?.basic_info?.priority?.color
                                                    }}"
                                                >
                                                    <!-- <span class="sm-font fw-500">Priority:</span> -->
                                                    {{
                                                        this.incident_data?.basic_info?.priority?.display_key
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex justify-content-start align-items-center">
                                                <div *ngIf="incident_data?.impact_service_tree_path">
                                                    <span class="sm-font"
                                                        >{{ "UI.k_service" | translate }} :
                                                    </span>
                                                    <span
                                                        class="sm-font"
                                                        title="{{ incident_data?.impact_service_tree_path }}"
                                                    >
                                                        {{ incident_data?.impact_service_tree_path | slice: 0:19
                                                        }}{{
                                                            incident_data?.impact_service_tree_path?.length > 19
                                                                ? ".."
                                                                : ""
                                                        }}</span
                                                    >
                                                </div>

                                                <div *ngIf="incident_data?.asset_id && incident_data?.cmdb_id">
                                                    <span class="sm-font"
                                                        >{{ "UI.k_asset_id" | translate }} :
                                                    </span>
                                                    <span
                                                        *ngIf="asset_permissions?.view && incident_data?.is_asset_disabled"
                                                    >
                                                        <a
                                                            href="/it-asset/asset/{{ incident_data?.cmdb_id }}"
                                                            target="_blank"
                                                            class="sm-font"
                                                            title="{{ incident_data?.asset_id }}"
                                                        >
                                                            {{ incident_data?.asset_id | slice: 0:19
                                                            }}{{
                                                                incident_data?.asset_id?.length > 19 ? ".." : ""
                                                            }}</a
                                                        >
                                                    </span>
                                                    <span
                                                        *ngIf="!asset_permissions?.view || !incident_data?.is_asset_disabled"
                                                        class="sm-font"
                                                        title="{{ inci?.asset_id }}"
                                                    >
                                                        {{ incident_data?.asset_id | slice: 0:19
                                                        }}{{
                                                            incident_data?.asset_id?.length > 19 ? ".." : ""
                                                        }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="my-50 d-flex justify-content-start align-items-center">
                                            <div class="avatar avatar-xs mr-50">
                                                <!-- <ng-container > -->
                                                <span
                                                    class="avatar-content" *ngIf="!incident_data?.requester_profile"
                                                    [ngStyle]="{
                                                        'background-color':
                                                            incident_data?.requester_avatar_color != undefined
                                                                ? incident_data?.requester_avatar_color
                                                                : '#f59518'
                                                    }"
                                                    >{{
                                                        this.incident_data?.requester?.full_name
                                                            | initials
                                                            | slice: 0:2
                                                    }}</span
                                                >
                                                <!-- </ng-container> -->
                                                <ng-container *ngIf="incident_data?.requester_profile">
                                                    <img
                                                        class="avatar-content"
                                                        class=""
                                                        [src]="incident_data?.requester_profile"
                                                        alt=""
                                                    />
                                                </ng-container>
                                            </div>

                                            <span
                                                class="sm-font fw-500 title-color summary-ellipse"
                                                ngbTooltip="{{ incident_data?.basic_info?.summary  | slice: 0:200 }}"
                                                placement="bottom"
                                                >{{ incident_data?.basic_info?.summary | slice: 0:150 }}
                                                {{
                                                    incident_data?.basic_info?.summary.length > 150 ? ".." : ""
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="incident_data?.state_id == 4">
                                    <div class="form-group col-xs-7 col-md-7 col-xl-7 col-lg-7 p-0">
                                        <!-- <label class="sm-font" for="selStatus">{{
                                "UI.k_status" | translate
                            }}</label> -->
                                        <app-status-picker
                                            [inputConfig]="{ isTextMode: true, stateOptionsMap:  options?.state_id_map}"
                                            [options]="resolved_status"
                                            (setStatus)="onStatusChange($event)"
                                            [value]="incident_data?.resolved_status_id"
                                        ></app-status-picker>
                                    </div>

                                    <div class="form-group col-xs-6 col-md-6 col-xl-6 col-lg-6 p-0">
                                        <label for="resolution_date" class="sm-font"
                                            >{{ "UI.k_resolution" | translate }} Date<span
                                                class="required-field"
                                                >&nbsp;*</span
                                            ></label
                                        >
                                        <div>
                                            <ng2-flatpickr
                                                [config]="dateTimeOptions"
                                                name="smartGridResolutionDate"
                                                id="smartGridResolutionDate"
                                                [setDate]="incident_data?.inci_resolution_time ? incident_data?.inci_resolution_time : incident_data['inci_resolution_time'] = dateTimeOptions?.defaultDate"
                                                [(ngModel)]="incident_data.inci_resolution_time"
                                                placeholder="{{ 'UI.k_inci_resolution_time' | translate }}"
                                                class="my-class"
                                                #TDInciSmartResolveDateRef="ngModel"
                                                required
                                            >
                                            </ng2-flatpickr>
                                            <div
                                                *ngIf="
                                                    resolvedIncidentForm.submitted &&
                                                    TDInciSmartResolveDateRef.invalid
                                                "
                                                class="invalid-form"
                                            >
                                                <small
                                                    class="form-text text-danger xs-font"
                                                    *ngIf="TDInciSmartResolveDateRef.errors.required"
                                                    >{{ "UI.k_field_required" | translate }}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 p-0">
                                        <div class="form-group">
                                            <label for="basic-search" class="sm-font">
                                                {{ "UI.k_resolution" | translate
                                                }}<span class="required-field">&nbsp;*</span>
                                            </label>
                                            <div class="card p-0 pb-50">
                                                <quill-editor
                                                    [(ngModel)]="incident_data.resolution_comment"
                                                    #TDSmartGridResoltionComment
                                                    id="txtAreaSmartGridResolution"
                                                    name="txtAreaSmartGridResolution"
                                                    [required]="true"
                                                    [trimOnValidation]="true"
                                                    [appQuillValidator]="TDSmartGridResoltionComment"
                                                    [appQuillForm]="resolvedIncidentForm"
                                                    [minLength]="3"
                                                    [class.error]="
                                                        TDSmartGridResoltionComment?.errors &&
                                                        resolvedIncidentForm.submitted
                                                    "
                                                    placeholder="{{ 'UI.k_insert_text_here' | translate }}..."

                                                >
                                                    <div quill-editor-toolbar class="px-0 py-50">
                                                        <div class="p-0">
                                                            <span class="ql-formats pb-50">
                                                                <button class="ql-bold" title="{{ 'UI.k_bold' | translate }}"></button>
                                                                <button class="ql-italic" title="{{ 'UI.k_italic' | translate }}"></button>
                                                                <button
                                                                    class="ql-underline"
                                                                    title="{{ 'UI.k_underline' | translate }}"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <select class="ql-align" title="{{ 'UI.k_alignment' | translate }}">
                                                                    <option selected></option>
                                                                    <option value="center"></option>
                                                                    <option value="right"></option>
                                                                    <option value="justify"></option>
                                                                </select>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    class="ql-list"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    class="ql-link"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                                <button
                                                                    class="ql-image"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                                <button
                                                                    class="ql-video"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    class="ql-formula"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                                <button
                                                                    class="ql-code-block"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    class="ql-clean"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </quill-editor>
                                            </div>
                                            <div>
                                                <span
                                                    class="row col-12 error"
                                                    *ngIf="
                                                        resolvedIncidentForm.submitted &&
                                                        TDSmartGridResoltionComment?.errors
                                                    "
                                                    class="invalid-form"
                                                >
                                                    <small
                                                        *ngIf="
                                                            TDSmartGridResoltionComment?.errors?.requiredError
                                                                ?.empty
                                                        "
                                                        class="form-text text-danger xs-font"
                                                        >{{ "UI.k_field_required" | translate }}</small
                                                    >
                                                    <small
                                                        *ngIf="
                                                            TDSmartGridResoltionComment?.errors?.minLengthError
                                                        "
                                                        class="form-text text-danger xs-font"
                                                        >{{ "Error.err_min_3_character" | translate }}</small
                                                    >
                                                </span>
                                            </div>
                                        </div>
                                            <app-dns-custom-field
                                                [formRef]="resolvedIncidentForm"
                                                placeholder="resolution"
                                                [data]="incident_data?.custom_field_data"
                                                [config]="inci_extra_info?.custom_field_config"
                                                class="container"
                                            ></app-dns-custom-field>  
                                    </div>
                                </div>
                                <div class="row p-0" *ngIf="incident_data?.state_id == 5">
                                    <div class="form-group col-xs-6 col-md-6 col-xl-6 col-lg-6">
                                        <app-status-picker
                                            [inputConfig]="{ isTextMode: true, stateOptionsMap:  options?.state_id_map}"
                                            [options]="resolved_status"
                                            (setStatus)="onStatusChange($event)"
                                            [value]="incident_data.basic_info?.status?.id"
                                        ></app-status-picker>
                                    </div>

                                    <div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
                                        <div class="form-group">
                                            <label for="txtInciCloserInfo"
                                                >{{ "UI.k_close_incident" | translate }}
                                                <span class="required-field">&nbsp;*</span>
                                            </label>
                                            <div>
                                                <quill-editor
                                                    [(ngModel)]="incident_data.close_info.closure_note"
                                                    id="smartQuillIncidentClosure"
                                                    name="smartQuillIncidentClosure"
                                                    (onContentChanged)="getClosureNote($event)"
                                                    (onEditorCreated)="editorCreated($event)"
                                                    [trimOnValidation]="true"
                                                    #TDInciClosureNoteRef
                                                    [appQuillValidator]="TDInciClosureNoteRef"
                                                    [appQuillForm]="resolvedIncidentForm"
                                                    [minLength]="3"
                                                    [required]="true"
                                                    [class.error]="
                                                        TDInciClosureNoteRef?.errors &&
                                                        resolvedIncidentForm.submitted
                                                    "
                                                    placeholder="{{ 'UI.k_closure_note' | translate }}"
                                                >
                                                    <div id="quill-toolbar" quill-editor-toolbar>
                                                        <div class="">
                                                            <span class="ql-formats">
                                                                <button class="ql-bold" title="{{ 'UI.k_bold' | translate }}"></button>
                                                                <button class="ql-italic" title="{{ 'UI.k_italic' | translate }}"></button>
                                                                <button
                                                                    class="ql-underline"
                                                                    title="{{ 'UI.k_underline' | translate }}"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <select class="ql-align" title="{{ 'UI.k_alignment' | translate }}">
                                                                    <option selected></option>
                                                                    <option value="center"></option>
                                                                    <option value="right"></option>
                                                                    <option value="justify"></option>
                                                                </select>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    class="ql-list"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    class="ql-link"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                                <button
                                                                    class="ql-image"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                                <button
                                                                    class="ql-video"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    class="ql-formula"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                                <button
                                                                    class="ql-code-block"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    class="ql-clean"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    id="insert-table"
                                                                    type="button"
                                                                    (click)="addNewtable($event)"
                                                                >
                                                                    Table
                                                                </button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </quill-editor>
                                            </div>
                                            
                                            <div
                                                class="error"
                                                *ngIf="
                                                    resolvedIncidentForm.submitted &&
                                                    TDInciClosureNoteRef?.errors
                                                "
                                            >
                                                <small
                                                    *ngIf="TDInciClosureNoteRef?.errors?.requiredError?.empty"
                                                >
                                                    {{ "UI.k_field_required" | translate }}
                                                </small>
                                                <small *ngIf="TDInciClosureNoteRef?.errors?.minLengthError">
                                                    {{ "Error.err_closure_note_min" | translate }}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6 col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label for="txtInciClosedBy"
                                                >{{ "UI.k_closedby" | translate }}
                                            </label>
                                            <ng-select
                                                [items]="options.users"
                                                [(ngModel)]="incident_data.close_info.closed_by"
                                                bindLabel="full_name"
                                                bindValue="profile_id"
                                                name="txtInciClosedBy"
                                                id="txtInciClosedBy"
                                                placeholder="{{ 'UI.k_select_users' | translate }}"
                                                [hideSelected]="true"
                                            >
                                                <ng-template
                                                    ng-option-tmp
                                                    let-item="item"
                                                    let-index="index"
                                                    let-search="searchTerm"
                                                >
                                                    <span class="d-flex">
                                                        <div
                                                            class="avatar mr-1 ml-0"
                                                            [ngStyle]="{
                                                                'background-color': item.avatar_color
                                                                    ? item.avatar_color
                                                                    : '#f59518'
                                                            }"
                                                            style="
                                                                align-content: center;
                                                                width: 32px;
                                                                height: 32px;
                                                            "
                                                        >
                                                            <div class="avatar-content">
                                                                {{ item.full_name | initials }}
                                                            </div>
                                                        </div>
                                                        <div class="cell-line-height">
                                                            <p
                                                                class="font-medium-1 font-weight-bold line-height-1 mb-25"
                                                            >
                                                                {{ item.full_name }}
                                                            </p>
                                                            <span class="text-muted font-small-2">
                                                                {{ item.email }}</span
                                                            >
                                                        </div>
                                                    </span>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>

                                    <div class="col-xs-12 col-md-6 col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label for="txtInciCloseType"
                                                >{{ "UI.k_close_type" | translate }}
                                            </label>
                                            <ng-select
                                                [items]="options.close_type"
                                                [hideSelected]="true"
                                                bindLabel="name"
                                                bindValue="id"
                                                (change)="
                                                    updateFieldName(
                                                        incident_data.basic_info.closed_type.id,
                                                        'id',
                                                        'closed_type',
                                                        incident_data.basic_info.closed_type.name,
                                                        'name'
                                                    )
                                                "
                                                [(ngModel)]="incident_data.close_info.closed_type.id"
                                                name="txtInciCloseType"
                                                id="txtInciCloseType"
                                                placeholder="{{ 'UI.k_sel_close_type' | translate }}"
                                            >
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6 col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label for="txtIncidentActlClsrDate"
                                                >{{ "UI.k_actual_closure_date" | translate }}
                                            </label>
                                            <ng2-flatpickr
                                                [config]="dateTimeOptions"
                                                name="txtIncidentActlClsrDate"
                                                [setDate]="incident_data?.actual_closure_date ? incident_data?.actual_closure_date : incident_data['actual_closure_date'] = dateTimeOptions?.defaultDate"
                                                id="txtIncidentActlClsrDate"
                                                placeholder="{{ 'UI.k_sel_date' | translate }}"
                                                [(ngModel)]="incident_data.actual_closure_date"
                                            ></ng2-flatpickr>
                                        </div>
                                    </div>
                                    <div class="col-xs-12 col-md-6 col-xl-6 col-lg-6">
                                        <div class="form-group">
                                            <label for="txtInciclsrAgrdTime"
                                                >{{ "UI.k_agreed_closure_date" | translate }}
                                            </label>
                                            <ng2-flatpickr
                                                [config]="agreedDateTimeOptions"
                                                name="txtInciclsrAgrdTime"
                                                [setDate]="incident_data.agreed_closure_date"
                                                id="txtInciclsrAgrdTime"
                                                placeholder="{{ 'UI.k_sel_date' | translate }}"
                                                [(ngModel)]="incident_data.agreed_closure_date"
                                            ></ng2-flatpickr>
                                        </div>
                                    </div>
                                        <app-dns-custom-field
                                            [formRef]="resolvedIncidentForm"
                                            placeholder="closure"
                                            [data]="incident_data?.custom_field_data"
                                            [config]="inci_extra_info?.custom_field_config"
                                            class="container"
                                        ></app-dns-custom-field>
                                </div>
                            </div>
                        </ng-scrollbar>
                        <div class="modal-btn-group flex-initial border-top">
                            <div class="d-flex justify-content-end stepper-btn flex-initial">
                                <button
                                    class="btn btn-sm btn-primary btn-next ml-1"
                                    id="btnComplete"
                                    name="btnComplete"
                                    [attr.data-testid]="'btnComplete'"
                                    rippleEffect
                                    type="submit"
                                    [disabled]="is_submit_disabled"
                                    (click)="onResolveSubmit(resolvedIncidentForm, incident_data)"
                                >
                                    <span class="align-middle d-sm-inline-block d-none">
                                        {{ resolve_btn_text }}</span
                                    >
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
