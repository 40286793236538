import { Component, Input, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { ChartComponent } from "ng-apexcharts";
import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-dns-progress-timer-component",
	templateUrl: "./dns-progress-timer-component.component.html",
	styleUrls: ["./dns-progress-timer-component.component.scss"],
})
export class DnsProgressTimerComponentComponent implements OnInit {
	@ViewChild("chartObj") chart: ChartComponent;
	@Input() expectedEndTime: any;
	@Input() startTime: any;
	@Input() endTime: any;
	@Input() isBreached: any = false;
	@Input() timerClass: any = "";
	@Input() elapsed_percentage: any = 0;
	@Input() remaining_time: any = 0;
	@Input() chartHeightWidth: any = 39;
	@Input("metricData") metricData: any;
	remaining_delta: any = {};
	public is_achieved :any;
	data: any;
	private $trackBgColor = "#D8D8E3";
	public chartoptions;
	public chartachieve;
	constructor(private _translateService: TranslateService) {}

	ngOnInit(): void {
		this.remaining_delta = this.min_to_day_hours_minutes(this.remaining_time);
		this.chartachieve = {
			chart: {
				height: this.chartHeightWidth,
				width: this.chartHeightWidth,
				type: "radialBar",
			},
			grid: {
				show: false,
				padding: {
					left: -15,
					right: -15,
					top: -12,
					bottom: -15,
				},
			},
			plotOptions: {
				radialBar: {
					hollow: {
						size: "25%",
						margin: 15,
						image: "../../assets/images/tick.svg",
						imageWidth: 12.25,
						imageHeight: 8.31,
						imageClipped: false,		
					},
					track: {
						background: this.$trackBgColor,
					},
					dataLabels: {
						showOn: "always",
						name: {
							show: false,
						},
						value: {
							show: false,
						},
					},
				},
			},
			series: [50],
			stroke: {
				lineCap: "round",
			},
			color: "success",
		};
		this.chartoptions = {
			chart: {
				height: this.chartHeightWidth,
				width: this.chartHeightWidth,
				type: "radialBar",
			},
			grid: {
				show: false,
				padding: {
					left: -15,
					right: -15,
					top: -12,
					bottom: -15,
				},
			},
			plotOptions: {
				radialBar: {
					hollow: {
						size: "22%",
					},
					track: {
						background: this.$trackBgColor,
					},
					dataLabels: {
						showOn: "always",
						name: {
							show: false,
						},
						value: {
							show: false,
						},
					},
				},
			},
			series: [50],
			stroke: {
				lineCap: "round",
			},
			color: "success",
		};
	}

	getRemainTime(endtime, is_completed: boolean = false) {
		let current = is_completed ? this.startTime : moment(new Date()).format("MMM DD, YYYY hh:mm A");
		let end = is_completed ? this.endTime : endtime;
		const total = Date.parse(end) - Date.parse(new Date(current).toString());
		let seconds: any = Math.abs(Math.trunc((total / 1000) % 60));
		let minutes: any = Math.abs(Math.trunc((total / 1000 / 60) % 60));
		let hours: any = Math.abs(Math.trunc((total / (1000 * 60 * 60)) % 24));
		let days: any = Math.abs(Math.trunc(total / (1000 * 60 * 60 * 24)));

		if (hours < 10) {
			hours = "0" + hours;
		}

		if (minutes < 10) {
			minutes = "0" + minutes;
		}

		if (seconds < 10) {
			seconds = "0" + seconds;
		}
		return {
			days,
			hours,
			minutes,
			seconds,
		};
	}

	getPassTime(endtime, is_completed: boolean = false) {
		let current = is_completed ? this.startTime : moment(new Date()).format("MMM DD, YYYY hh:mm A");
		let end = is_completed ? this.endTime : endtime;
		let currentDate = Math.abs(Number((new Date(current).getTime() / 1000).toFixed(0)));
		let futureDate = Math.abs(Number((new Date(end).getTime() / 1000).toFixed(0)));
		let diff = currentDate - futureDate;
		let days: any = Math.abs(Math.trunc(diff / 86400));
		let hours: any = Math.abs(Math.trunc(diff / 3600) % 24);
		let minutes: any = Math.abs(Math.trunc(diff / 60) % 60);
		let seconds: any = diff % 60;

		if (hours < 10) {
			hours = "0" + hours;
		}

		if (minutes < 10) {
			minutes = "0" + minutes;
		}

		if (seconds < 10) {
			seconds = "0" + seconds;
		}
		return {
			days,
			hours,
			minutes,
			seconds,
		};
	}

	isDatePassed(date) {
		const today = new Date();
		today.setHours(0, 0, 0, 0);
		return new Date(moment(new Date()).format("MMM DD, YYYY hh:mm A")) > new Date(date);
	}

	getDay(expDate) {
		let is_completed = this.endTime ? true : false;
		let days = this.isDatePassed(expDate)
			? this.getPassTime(expDate, is_completed).days
			: this.getRemainTime(expDate, is_completed).days;
		if (days == "0") {
			return "";
		} else {
			return `${days} dd `;
		}
	}

	getTime(expDate) {
		let is_completed = this.endTime ? true : false;
		let hours, minutes;
		if (this.isDatePassed(expDate)) {
			let time = this.getPassTime(expDate, is_completed);
			hours = time.hours;
			minutes = time.minutes;
		} else {
			let time = this.getRemainTime(expDate, is_completed);
			hours = time.hours;
			minutes = time.minutes;
		}
		// if (minutes == "00") {
		// 	this.isBreached = true;
		// }
		return `${hours} h ${minutes} m`;
	}

	getChartColor(color) {
		if (color === "danger") {
			return "#EA5455";
		}
		if (color === "warning") {
			return "#FF9F43";
		}
		if (color === "success") {
			return "#28C76F";
		}

		return "#28C76F";
	}

	getPercent(startDate, expDate) {
		if (this.isBreached) {
			return 100;
		} else {
			// const start: any = new Date(startDate);
			// const end: any = new Date(expDate);
			// const current: any = new Date(moment(new Date()).format("MMM DD, YYYY hh:mm A"));
			// const range = end - start;
			// const diff = Math.max(0, end - current);
			let percent = this.elapsed_percentage;
			if (percent > 100) {
				this.isBreached = true;
				return 100;
			} else {
				return percent;
			}
		}
	}

	getColorName(startDate, expDate) {
		let percent = this.elapsed_percentage;
		if (this.isBreached) {
			return "danger";
		} else {
			if (percent > 75) {
				return "warning";
			} else {
				return "success";
			}
		}
	}

	min_to_day_hours_minutes(minutes: number) {
		const days = Math.floor(minutes / 1440);
		const hours = Math.floor((minutes % 1440) / 60);
		const min = minutes % 60;
		return { days: days, hours: hours, minutes: min };
	}

	getTimerData(metricData: any): any {
		const targetTime = metricData?.metric_target;
		const business_elapsed = metricData?.biz_elapsed;
		// Getting remaining time
		const remaining_time: number = targetTime - business_elapsed;
		if (remaining_time > 0) {
			const timer_data = this.min_to_day_hours_minutes(remaining_time);
			return timer_data;
		} else if (remaining_time < 0) {
			const temp_breached_time = metricData?.total_elapsed - targetTime;
			const timer_data = this.min_to_day_hours_minutes(temp_breached_time);
			timer_data["is_breached"] = true;
			return timer_data;
		} else {
			return this.min_to_day_hours_minutes(0);
		}
	}

	getRunningStatusTitle = (obj) => {
		// 1. Start_time is nUll -> Not yet Started
		// 2. if is_breached is False and End_time is null and status is in-procress/pause ---> In progress
		// 3. if is_breached is False and status is end and End_time ---> Achive
		// 4. if is_breached is True -> Brach
		// SLA_METRIC_MONITOR_DICT = {1: 'Running', 2: 'Pause', 3: 'End', 4: 'Cancelled',  5: 'Exclude'}
		let status = 1;
		let status_name = "";
		let color_name = "";
		let icon = "";
		let name = "";
		this.is_achieved = !obj?.is_breached && obj?.end_time && obj?.status === 3
		if (!obj?.start_time && obj?.status === 4) {
			status_name = this._translateService.instant("UI.k_cancelled");
			color_name = "#AC67F0";
			status = 5;
			name = this._translateService.instant("UI.k_cancelled");
		} else if (!obj?.start_time) {
			status_name = this._translateService.instant("UI.k_not_yet_start");
			color_name = "#00CFE8";
			status = 1;
			name = this._translateService.instant("UI.k_not_yet_start");
		} else if (!obj?.is_breached && obj?.end_time && obj?.status === 3) {
			status_name = this._translateService.instant("UI.k_achieved");
			color_name = "#28C76F";
			status = 2;
			//icon = "fa fa-check";
			name = this._translateService.instant("UI.k_achieved");
		} else if (obj?.is_breached) {
			status_name = this._translateService.instant("UI.k_breached");
			color_name = "#EA5455";
			status = 3;
			icon = "fa-exclamation-triangle  mr-25";
			name = this._translateService.instant("UI.k_running");
		} else if (obj?.status === 4) {
			status_name = this._translateService.instant("UI.k_cancelled");
			color_name = "#AC67F0";
			status = 5;
			// icon = "fa-times";
			name = this._translateService.instant("UI.k_cancelled");
		} else if (obj?.status === 5) {
			status_name = this._translateService.instant("UI.k_exclude");
			color_name = "#AC67F0";
			status = 6;
			name = this._translateService.instant("UI.k_exclude");
		} else {
			status_name = this._translateService.instant("UI.k_left");
			color_name = "#FF9F43";
			status = 4;
			name = this._translateService.instant("UI.k_running");
		}
		return {
			status,
			status_name,
			color_name,
			icon,
			name,
		};
	};
}
