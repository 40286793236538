import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { CoreConfigService } from "@core/services/config.service";
import { UserService } from "../../services/user.service";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
// import Stepper from "bs-stepper";
import Swal from "sweetalert2";
import { Subject, throwError } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { catchError, map } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
// import { locale as menuGerman } from "./de";
import { CoreTranslationService } from "@core/services/translation.service";
import { DOCUMENT } from "@angular/common";
import { Breadcrumb } from "app/common/layout/components/content-header/breadcrumb/breadcrumb.component";
import { GridCellCardComponent } from "../../../../common/dns/components/grid-cell-card/grid-cell-card.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GlobalConstants } from "app/app.globalConstants";
import { InfinityModules } from "app/common/dns/types/modules";
import { customSearch } from "app/common/utils/utils";
// import * as snippet from "./../../../../common/utils/sweet-alerts/sweet-alerts.snippetcode";
// import * as snippet from "./../../sweet-alerts.snippetcode";
// import { snippetCode } from "@core/components/card-snippet/card-snippet.component";
import {InfraonMixpanelService} from "app/services/mixpanel/infraon-mixpanel.service";

@Component({
	selector: "app-users",
	templateUrl: "./users-grid.component.html",
	styleUrls: ["./users-grid.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class UsersComponent implements OnInit, OnDestroy {
	@ViewChild("modDeletePopup") modDeletePopup: ElementRef;
	@Input() requestFrom = "";
	users: any;
	columns: Array<any>;
	options: any;
	SelectionType = SelectionType;
	ColumnMode = ColumnMode;
	pageNumber = 0;
	limit = 10;
	userCount = 0;
	currentEvent: String;
	currentUser: Number;
	sortKey: any = "full_name";
	reverse: any = "";
	private bsStepper;
	private _unsubscribeAll: Subject<any>;
	public isBookmarked: boolean = false;
	searchParams: any;
	moduleName = "Users";
	module = InfinityModules.USER;
	permissions: any;
	showBreadcrumb: any = false;
	hideInvite: boolean = false;
	hideInviteGrid: boolean = false;
	hideProfile: boolean = false;
	searchOptions: any;
	public breadcrumbObj: Breadcrumb;
	pendingInvitations: any = 0;
	selectedUser: any = {};
	selGrps: any = [];
	selServices: any = [];
	selDept: any = [];
	user_list: any = [];
	selectedProfile: any = [];
	used_modules: any = {};
	public empty_data = {};
	public showData = false;

	public filterData = false;
	filter = [];
	// Search
	public showRightsection: false;
	public showDataTable = false;
	public savedData = [];
	public columnOptions = [];
	public conditionOptions = [];
	public option: any;
	globalSearch: any = false;
	public countClass = false;
	public showAll = false;
	isExpandSearchWidth: boolean;
	fromExternalComponent: boolean = false;

	constructor(
		@Inject(DOCUMENT)
		private document,
		private _coreConfigService: CoreConfigService,
		private _coreSidebarService: CoreSidebarService,
		private _userService: UserService,
		private _toastr: ToastrService,
		private _activatedRoute: ActivatedRoute,
		private _translateService: TranslateService,
		private _coreTranslationService: CoreTranslationService,
		private _router: Router,
		private _modalService: NgbModal,
		private _mixpanelService: InfraonMixpanelService
	) {
		this._unsubscribeAll = new Subject();
	}

	userCard = (): void => {
		this._router.navigateByUrl("/users/card");
	};
	userGrid = (): void => {
		this._router.navigateByUrl("/users");
	};

	ngOnInit(): void {
		this.getFilterOption();
		this.getSavedFilters();
		this.empty_data = {
			icon: "fa-user",
			msg: this._translateService.instant("Message.msg_user_skeleton_heading"),
			description: this._translateService.instant("Message.msg_user_skeleton_descr"),
			add_callback: this.toggleAddUser,
			sidebar_name: "add-user-profile",
			button: "UI.k_invite_user",
			import_csv: false,
			id: "btnInviteUser",
			csv_callback: this.csvImport,
		};
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}

		this.permissions = user_permissions[this.module];
		// const sub = this._activatedRoute.queryParams.subscribe((params) => {
		// 	if (params.globalSearch) {
		// 		this.globalSearch = true;
		// 	}
		// 	this.updateNavBar();
		// 	this.getFilterOption();
		// });
		this.users = [];
		this.currentEvent = "Add";
		this.currentUser = -1;
		this.getPendingUserCount();
		// this.options = {
		// 	role: [
		// 		{
		// 			name: "Administrator",
		// 			id: 1,
		// 		},
		// 		{
		// 			name: "Manager",
		// 			id: 2,
		// 		},
		// 		{
		// 			name: "Cse",
		// 			id: 3,
		// 		},
		// 	],
		// };
		// todo: to implement the get options for grid
		this.getOptions();
		this.columns = [
			{
				label: this._translateService.instant("UI.k_username"),
				key: "email",
				component: GridCellCardComponent,
			},
			//{
			//	label: this._translateService.instant('UI.k_name'),
			//	key: "name",
			//	subkey: "profile",
			//},
			//{
			//	label: this._translateService.instant('UI.k_email'),
			//	key: "email",
			//	subkey: "profile",
			//},
			{
				label: this._translateService.instant("UI.k_phone"),
				key: "phone_number",
				subkey: "profile",
				type: "tel",
				display_tel_key: "phone",
			},
			{
				label: this._translateService.instant("UI.k_role"),
				key: "role",
				subkey: "profile",
				type: "select",
				sortable: false,
				isEditable: "is_editable",
			},
			{
				label: this._translateService.instant("UI.k_is_active"),
				key: "is_active",
				type: "switch",
				subkey: "profile",
				activeText: this._translateService.instant("UI.k_active"),
				inactiveText: this._translateService.instant("UI.k_inactive"),
				isEditable: "is_editable",
			},
			{
				label: this._translateService.instant("UI.k_last_logged_in"),
				key: "last_loggged_in",
				subkey: "profile",
				sortable: false,
			},
			{
				label: this._translateService.instant("UI.k_action"),
				key: "action",
				subkey: "profile",
				actions: [
					{
						label: this._translateService.instant("UI.k_edit"),
						icon: "fa-pen-to-square",
						key: "edit",
						class: "text-primary",
						listener: this.editUser,
						hide_key: "hide_action",
					},
					{
						label: this._translateService.instant("UI.k_delete"),
						icon: "fa-trash-can",
						key: "delete",
						class: "text-danger mr-50",
						listener: this.deleteUser,
						hide_key: "hide_action",
					},
				],
			},
		];
		this._coreConfigService.config = {
			layout: {
				navbar: {
					moduleName: "Users",
					pageTitle: this._translateService.instant("UI.k_users"),
					breadcrumb: {
						type: "",
						links: [
							{
								name: this._translateService.instant("UI.k_infron_user_conf"),
								isLink: true,
								link: "/configuration",
							},
							{
								name: this._translateService.instant("UI.k_user"),
								isLink: false,
								link: "/",
							},
						],
					},
				},
			},
		};
		this._mixpanelService.trackEvent("User Grid", {"view": "Data loaded"})

		if(this.requestFrom == "msp-view") {
			this.fromExternalComponent = true;
		}
	}

	setNavbarConfig(): void {
		// Configure the layout
		if (this.permissions?.add) {
			this._coreConfigService.config = {
				layout: {
					navbar: {
						loadHTML: false,
						hidden: false,
						showSearch: true,
						pageTitle: this._translateService.instant("UI.k_users"),
						pageIcon: "fa-users",
						moduleName: "Users",
						bookmark: this.isBookmarked,
						search: [
							{
								popup: this.searchOptions, //this.searchParams,
								callback: this.searchUser,
							},
						],
						enableLocalStorage: false,
						breadcrumb: {
							type: "",
							links: [
								{
									name: this._translateService.instant("UI.k_infron_user_conf"),
									isLink: true,
									link: "/configuration",
								},
								{
									name: this._translateService.instant("UI.k_user"),
									isLink: false,
									link: "/",
								},
							],
						},
					},
				},
			};
		} else {
			this._coreConfigService.config = {
				layout: {
					navbar: {
						loadHTML: false,
						hidden: false,
						showSearch: true,
						pageTitle: this._translateService.instant("UI.k_users"),
						pageIcon: "fa-users",
						moduleName: "Users",
						bookmark: this.isBookmarked,
						search: [
							{
								popup: this.searchOptions, //this.searchParams,
								callback: this.searchUser,
							},
						],
						enableLocalStorage: false,
					},
				},
			};
		}
	}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(undefined);
		this._unsubscribeAll.complete();
		Swal.close();
	}

	editUser = (args): void => {
		this.currentEvent = "Edit";
		this.currentUser = args.profile_id;
		this._coreSidebarService.getSidebarRegistry("user-profile").toggleOpen();
		this.hideProfile = true;
	};

	onUpdate(args) {
		if (
			args.key == "is_active" &&
			args?.profile?.is_active &&
			this.option?.active_users_count >= GlobalConstants?.dnsLavModules?.USER_COUNT
		) {
			Swal.fire({
				title: this._translateService.instant("Message.msg_users_limit_exceed"),
				text: this._translateService.instant("Message.msg_users_max_limit"),
				icon: "warning",
				showCancelButton: false,
				confirmButtonText: this._translateService.instant("UI.k_ok"),
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-danger ml-1",
				},
			}).then((result) => {
				if (result.value) {
					this.getUserList();
				}
			});
		} else if (args.key == "is_active" && args?.profile?.is_active) {
			this.option.active_users_count += 1;
			this.saveUser(args);
		} else if (args.key == "is_active" && !args?.profile?.is_active) {
			this.option.active_users_count -= 1;
			this.saveUser(args);
		} else {
			this.saveUser(args);
		}
		//let error_flag = false;
		// if (args?.profile?.phone) {
		// 	let onlyDigits = args.profile.phone.replace(/[^0-9]/g, "");
		// 	if (
		// 		args.profile.phone.length < 6 ||
		// 		args.profile.phone.length > 10 ||
		// 		onlyDigits != args.profile.phone
		// 	) {
		// 		error_flag = true;
		// 		this._toastr.error(
		// 			this._translateService.instant("UI.k_phone_no_pattern"),
		// 			this._translateService.instant("Error.err_save_failed"),
		// 			{
		// 				toastClass: "toast ngx-toastr",
		// 				closeButton: true,
		// 				positionClass: "toast-top-right",
		// 			}
		// 		);
		// 	}
		// }
		// if (!error_flag) {
		// 	this.saveUser(args);
		// }
		//else {
		// 	this.getUserList();
		// }
	}

	onSort(args): void {
		if (args.sorts) {
			this.pageNumber = 0;
			this.sortKey = args.sorts[0].prop;
			if (this.sortKey == "name" || this.sortKey == "email") {
				this.sortKey = "full_name";
			}
			if (this.sortKey == "username") {
				this.sortKey = "full_name";
			}
			if (this.sortKey == "isActive") {
				this.sortKey = "is_active";
			}
			// if (this.sortKey == "phone") {
			// 	this.sortKey = "contact_number";
			// }

			if (args.sorts[0].dir == "desc") {
				this.reverse = "-";
			} else {
				this.reverse = "";
			}
		}
		this.getUserList();
	}

	toggleAddUser = (name: string) => {
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
		this.hideInvite = true;
	};

	searchUser = (args) => {
		const payload = {
			page: this.pageNumber + 1,
			items_per_page: this.limit,
			sort: this.sortKey,
			reverse: this.reverse,
			filterString: args.trim(),
			moduleName: "user",
		};
		this._userService.getUserProfiles(payload).subscribe(
			(response) => {
				if (response) {
					this.userCount = response.count;
					this.users = [];
					setTimeout(() => {
						this.users = response.results;
						this.showData = true;
					}, 300);
				} else {
					this.showData = true;
				}
			},
			(error) => {
				//todo
			}
		);
	};
	toggleInvitedUsers = (name: string) => {
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
		this.hideInviteGrid = true;
	};

	onSearchClick(param) {}

	saveUser = (row) => {
		let name = row.profile.name;
		let profile = row.profile;
		if (name !== null && name !== undefined) {
			let full_name = name.split(" ");
			profile["first_name"] = full_name[0];
			profile["last_name"] = full_name[1];
		}
		profile["username"] = profile.email;
		// profile["action"] = "Edit";
		profile["id"] = row.user;
		profile["group_id"] = profile.role;
		profile["profile_id"] = row.profile_id;
		profile["profile"] = {};
		profile["profile"]["email"] = profile.email;
		profile["profile"]["group_id"] = profile.role;
		profile["profile"]["contact_number"] = profile.phone;
		profile["profile"]["phone_number"] = profile.phone_number;
		profile["profile"]["full_name"] = profile.name;
		profile["inlineEdit"] = true;
		this._userService
			.saveUserProfile(profile)
			.pipe(
				map((response: any) => {
					if (response) {
						// this.notification.success('Saved User', 'User has been saved successfully')
						if (response["status"] == "success") {
							this.getUserList();
							this._toastr.success(
								this._translateService.instant(response["message"]),
								this._translateService.instant("Message.msg_save_success"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
						} else {
							this._toastr.error(
								this._translateService.instant(response["message"]),
								this._translateService.instant("Error.err_save_failed"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
						}
					}
					// Success
				}),
				catchError((error) => {
					this._toastr.error(
						this._translateService.instant("Error.err_save_failed"),
						this._translateService.instant("Error.err_save_failed"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					//   this.notification.error('Failed User Creation', 'Something went wrong')
					return throwError(error.statusText);
				})
			)
			.subscribe();
	};

	deleteUser = (row) => {
		this.selectedUser = row;
		this._userService.getCIRelationMap({ pk: row?.profile_id }).subscribe((response: any) => {
			let msg = "Message.msg_delete_text";
			let add_on_msg = "";
			if (response?.flag == 0) {
				msg = "Error.err_user_ci_relation_mapped";
				if (response?.ci_rel_length > 0 && response?.ci_rel_length != undefined) {
					add_on_msg += " " + this._translateService.instant("UI.k_one_or_more_cis");
				}
			}
			Swal.fire({
				title: this._translateService.instant("Message.msg_delete_title"),
				text: this._translateService.instant(msg, { msg: add_on_msg }),
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: this._translateService.instant("Message.msg_delete_confirm"),
				cancelButtonText: this._translateService.instant("Message.msg_delete_cancel"),
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-danger ml-1",
				},
			}).then((result) => {
				if (result.value) {
					this._userService
						.getAssignedModule({ profile_id: row.profile_id })
						.pipe(
							map((response: any) => {
								this.used_modules = response["modules"] || {};
								this.user_list = response["user_list"] || [];
								if (response["deleteUser"] == true) {
									this.deleteSelectedUser(row.profile_id);
								} else {
									this._modalService.open(this.modDeletePopup, {
										centered: false,
										size: "lg",
										animation: false,
									});
								}
							})
						)
						.subscribe();
				}
			});
		});
	};

	deleteSelectedUser(profile_id) {
		this._userService
			.deleteUserProfile(profile_id)
			.pipe(
				map((response: any) => {
					if (response.status == "success") {
						this._toastr.success(
							this._translateService.instant("Message.msg_user_deleted"),
							this._translateService.instant("Message.msg_delete_success"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						// subtracting 1 from page number if count equals to items per page limit after delete
						if (this.userCount != 1 && this.userCount - 1 < this.pageNumber * this.limit + 1) {
							this.pageNumber = this.pageNumber - 1;
						}
						if (this.showAll) {
							this.limit = this.limit - 1;
						}
						this.getUserList();
					} else {
						this._toastr.error(
							this._translateService.instant("Error.err_delete_user"),
							this._translateService.instant("Error.err_delete_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
					}
					// Success
				}),
				catchError((error) => {
					this._toastr.error(
						this._translateService.instant("Error.err_delete_user"),
						this._translateService.instant("Error.err_delete_failed"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}

	getUserList = () => {
		const payload = {
			page: this.pageNumber + 1,
			items_per_page: this.showAll && this.currentEvent === "Add" ? Number(this.limit) + 1 : Number(this.limit),
			sort: this.sortKey,
			reverse: this.reverse,
			filter: JSON.stringify(this.filter),
			module: "Users",
		};
		this._userService.getUserProfiles(payload).subscribe(
			(response) => {
				if (response) {
					this.userCount = response?.count;
					if (this.showAll && this.currentEvent === "Add") {
						this.limit = Number(this.limit) + 1;
					}
					// this.users = [];
					//setTimeout(() => {
					this.users = response?.results;
					// this.filterData = false;
					//}, 300);
					setTimeout(() => {
						this.showData = true;
					}, 300);
				} else {
					this.showData = true;
				}
			},
			(error) => {
				//todo
			}
		);
		// this._router.navigateByUrl("/users");
	};

	setPage(pageInfo) {
		this.pageNumber = pageInfo.offset;
		this.getUserList();
	}

	setLimit(limit): void {
		this.pageNumber = 0;
		if (limit === "all") {
			this.showAll = true;
			this.limit = this.userCount;
		} else {
			this.limit = Number(limit);
			this.showAll = false;
		}
		// this.limit = args.target.value;
		this.getUserList();
	}

	// getFilterOptions(): void{
	// 	this._userService.getFilterOptions({ moduleName: "user" }).subscribe(
	// 		(response) => {
	// 			if (response) {
	// 				this.searchOptions = response
	// 				this.getUserPreferences();

	// 			}
	// 			else{
	// 				this.searchOptions = {}
	// 			}
	// 		},
	// 		(error) => {
	// 			this.searchOptions = {};
	// 		}
	// 	);
	// }

	getUserPreferences(): void {
		// function to get user page preferences
		this._userService
			.getPreferences()
			.pipe(
				map((response: any) => {
					if (response) {
						let bookmarks = response.bookmarks;
						if (bookmarks && bookmarks.includes(this.moduleName)) {
							this.isBookmarked = true;
						}
					} else {
					}
					// Success
				}),
				catchError((error) => {
					return throwError(error.statusText);
				})
			)
			.subscribe();
		this.setNavbarConfig();
	}

	getOptions = (): void => {
		this._userService.getOptions().subscribe(
			(response) => {
				if (response) {
					this.options = {};
					this.option = response;
					this.options["role"] = response["roles"];
					this.getUserList();
				}
			},
			(error) => {
				// 		//todo
			}
		);
	};

	onHideInvite = (e) => {
		this.hideInvite = false;
		this.getPendingUserCount();
		this.countClass = true;
		if (e == "update"){
			this.getUserList();
		}
	};

	ngDoCheck() {
		if (this.countClass) {
			setTimeout(() => {
				this.countClass = false;
			}, 5000);
		}
	}

	onHideInviteGrid = (e) => {
		this.hideInviteGrid = false;
		this.getPendingUserCount();
	};
	onHideProfile = (e) => {
		this.hideProfile = false;
	};
	getPendingUserCount = (): void => {
		this._userService.getPendingUserCount().subscribe(
			(response) => {
				if (response) {
					this.pendingInvitations = response.count;
				}
			},
			(error) => {
				//todo
			}
		);
	};
	onSubmitReplacement = (): void => {
		let payload = {
			sel_profile_id: this.selectedUser.profile_id,
			profile_id: this.selectedProfile,
			used_modules: this.used_modules,
		};
		this._userService
			.replaceAssignee(payload)
			.pipe(
				map((response: any) => {
					if (response.status == "success") {
						this._toastr.success(
							this._translateService.instant("Message.msg_user_replacement"),
							this._translateService.instant("Message.msg_success"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						this._modalService.dismissAll();
						this.selectedProfile = undefined;
						this.deleteSelectedUser(this.selectedUser.profile_id);
					} else {
						this._toastr.error(
							this._translateService.instant("Error.err_user_replacement"),
							this._translateService.instant("Error.err_update_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
					}
					// Success
				}),
				catchError((error) => {
					this._toastr.error(
						this._translateService.instant("Error.err_user_replacement"),
						this._translateService.instant("Error.err_update_failed"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					return throwError(error.statusText);
				})
			)
			.subscribe();
	};

	csvImport() {
		//console.log("csv import")
	}

	getFilterSearch = ($event) => {
		let dict = {};
		this.filter = [];
		dict["ui_display"] = $event.userSearchData;
		dict["filter_string"] = $event.userSearchString;
		dict["filter_item"] = $event.filterItem;
		dict["type"] = $event.type;
		dict["module"] = "Users";
		//this.users = [];
		this.pageNumber = 0;
		this.filter.push(dict);
		const payload = {
			page: 1,
			items_per_page: this.limit,
			sort: this.sortKey,
			reverse: this.reverse,
			filter: JSON.stringify(this.filter),
			module: "Users",
		};
		this.getUserData(payload);
	};

	getUserData(payload) {
		this._userService.getUserProfiles(payload).subscribe(
			(response) => {
				if (response) {
					this.userCount = response.count;
					this.users = response.results;
					this.showData = true;
					this.filterData = true;
				}
				this.getSavedFilters();
			},
			(error) => {
				//todo
			}
		);
	}

	getFilterOption(): void {
		this._userService.getFilterOption({ moduleName: "Users" }).subscribe(
			(response) => {
				if (response) {
					this.columnOptions = response;
				} else {
					this.columnOptions = [];
				}
			},
			(error) => {
				this.columnOptions = [];
			}
		);
	}

	getSavedFilters(): void {
		this._userService
			.getSavedFilter({ module: "Users" })
			.pipe(
				map((response: any) => {
					if (response) {
						this.savedData = response;
					}
				})
			)
			.subscribe();
	}

	updateNavBar() {
		// Configure the layout
		this._coreConfigService.config = {
			layout: {
				navbar: {
					loadHTML: false,
					hidden: false,
					pageTitle: this._translateService.instant("UI.k_users"),
					pageIcon: "fa-users",
					moduleName: "Users",
					//backgroundColor: "",
					breadcrumb: {
						type: "",
						links: [
							{
								name: "User Management",
								isLink: true,
								link: "/configuration",
							},
							{
								name: "Users",
								isLink: false,
								link: "/",
							},
						],
					},
				},
			},
		};
	}
	customSearchFn(term: string, item: any) {
		return customSearch(term, item, "full_name", "email");
	}

	genericSearchFilter = ($event) => {
		let search_str = $event.userSearchData.trim();
		let search_db_cols = [
			{ db_col: "full_name", cond: "contains" },
			{ db_col: "email", cond: "contains" },
			{ db_col: "contact_number", cond: "contains" },
			{ db_col: "location", cond: "contains" },
		];
		let filter_options = [];
		search_db_cols.forEach((item) => {
			filter_options.push({
				condition: item.cond,
				db_col: item.db_col,
				value: search_str,
				relation: "or",
			});

			if (filter_options.length == search_db_cols.length) {
				this.pageNumber = 0;
				let dict = {};
				dict["ui_display"] = $event.userSearchData;
				dict["filter_string"] = filter_options;
				dict["filter_item"] = $event.filterItem;
				dict["type"] = $event.type;
				dict["module"] = "Users";
				for (let i = 0; i < this.filter.length; i++) {
					dict["filter_string"].push(this.filter[i]);
				}

				this.filter = [];
				this.filter.push(dict);
				const payload = {
					page: 1,
					items_per_page: this.limit,
					sort: this.sortKey,
					reverse: this.reverse,
					filter: JSON.stringify(this.filter),
					module: "Users",
				};
				this.getUserData(payload);
			}
		});
	};
	getSearchWidth = (e) => {
		if(e){
			this.isExpandSearchWidth = true;
		}
		else{
			this.isExpandSearchWidth = false;
		}
	}
}
