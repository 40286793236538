<div class="slideout-content default-slideout">
	<div class="modalsd modal-slide-in sdfade new-api-modal" id="modals-slide-in">
		<div class="modal-dialog sidebar-md">
			<div class="modal-content add-new-api pt-0">
				<div class="modal-header">
					<h5 class="modal-title" id="viewDetails">
						<span>{{ "UI.k_edit_details" | translate }}</span>
					</h5>
					<button
						type="button"
						class="btn btn-flat-secondary btn-icon"
						id="btnClose"
						name="btnClose"
						data-dismiss="modal"
						aria-label="Close"
						(click)="toggleSidebar('edit-user-detail')"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body">
					<form #editAdminUserForm="ngForm" class="flex-auto-height">
						<ng-scrollbar class="custom-scrollbar section-scrollbar">
							<div class="p-1" *ngIf="organizationData">
								<div class="form-group">
									<label for="account-full_name"
										>{{ "UI.k_fname" | translate }}<span class="required">*</span></label
									>
									<input
										type="text"
										[(ngModel)]="organizationData.user.full_name"
										#TDAdminUserRef="ngModel"
										required
										class="form-control"
										id="txtAdminUser"
										name="txtAdminUser"
										minlength="3"
										maxlength="40"
										[appWhiteSpaceCheck]="organizationData.user.full_name"
										[class.error]="!TDAdminUserRef.valid && editAdminUserForm.submitted"
									/>
									<span
										*ngIf="editAdminUserForm.submitted && TDAdminUserRef.invalid"
										class="invalid-form"
									>
										<small class="form-text text-danger" *ngIf="TDAdminUserRef.errors.required">{{
											"UI.k_field_required" | translate
										}}</small>
										<small
											class="form-text text-danger"
											*ngIf="TDAdminUserRef.errors.minlength || TDAdminUserRef.errors.maxlength"
											>{{ "UI.k_name_char_validation" | translate }}
										</small>
										<small class="form-text text-danger" *ngIf="TDAdminUserRef.errors.pattern">{{
											"UI.k_pattern_validation" | translate
										}}</small>
										<small
											class="form-text text-danger"
											*ngIf="TDAdminUserRef.errors?.whitespace"
											>{{ "UI.k_leading_trailing_space_validation" | translate }}</small
										>
									</span>
								</div>
								<div class="form-group">
									<label for="account-full_name"
										>{{ "UI.k_organization_name" | translate
										}}<span class="required">*</span></label
									>
									<input
										type="text"
										[(ngModel)]="organizationData.organization.company_name"
										#TDOrgNameRef="ngModel"
										required
										class="form-control"
										id="txtOrgName"
										name="txtOrgName"
										minlength="3"
										maxlength="40"
										[appWhiteSpaceCheck]="organizationData.organization.company_name"
										[class.error]="!TDOrgNameRef.valid && editAdminUserForm.submitted"
									/>
									<span
										*ngIf="editAdminUserForm.submitted && TDOrgNameRef.invalid"
										class="invalid-form"
									>
										<small class="form-text text-danger" *ngIf="TDOrgNameRef.errors.required">{{
											"UI.k_field_required" | translate
										}}</small>
										<small
											class="form-text text-danger"
											*ngIf="TDOrgNameRef.errors.minlength || TDOrgNameRef.errors.maxlength"
											>{{ "UI.k_name_char_validation" | translate }}
										</small>
										<small class="form-text text-danger" *ngIf="TDOrgNameRef.errors.pattern">{{
											"UI.k_pattern_validation" | translate
										}}</small>
										<small class="form-text text-danger" *ngIf="TDOrgNameRef.errors?.whitespace">{{
											"UI.k_leading_trailing_space_validation" | translate
										}}</small>
									</span>
								</div>
								<div class="form-group">
									<label for="adminEmail" class="form-label">
										{{ "UI.k_admin_email" | translate }} *</label
									>
									<input
										type="email"
										class="form-control"
										id="adminEmail"
										name="adminEmail"
										disabled
										placeholder="{{ 'UI.k_email_placeholder' | translate }}"
										[(ngModel)]="organizationData.user.email"
										pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$"
										#adminEmailInput="ngModel"
										[class.error]="adminEmailInput.invalid && editAdminUserForm.submitted"
									/>
									<span
										class="invalid-form"
										*ngIf="editAdminUserForm.submitted && adminEmailInput.invalid"
									>
										<small class="form-text text-danger" *ngIf="adminEmailInput.errors.required"
											>{{ "UI.k_field_required" | translate }}
										</small>
									</span>
								</div>
								<div class="form-group">
									<label for="account-phone"
										>{{ "UI.k_contact_number" | translate
										}}<span class="required-field">&nbsp;*</span></label
									>
									<ngx-intl-tel-input
										class="w-100"
										[cssClass]="'custom form-control w-100'"
										[preferredCountries]="preferredCountries"
										[enableAutoCountrySelect]="true"
										[enablePlaceholder]="true"
										[searchCountryFlag]="true"
										[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
										[selectFirstCountry]="false"
										[selectedCountryISO]="CountryISO.UnitedStates"
										[maxLength]="15"
										[phoneValidation]="true"
										[separateDialCode]="separateDialCode"
										[numberFormat]="PhoneNumberFormat.National"
										name="account-phone"
										id="account-phone"
										[(ngModel)]="organizationData.user.phone_number"
										#TDPhoneRef="ngModel"
										(click)="onFormChange(editAdminUserForm)"
										[ngClass]="{
											'is-invalid': editAdminUserForm.submitted && TDPhoneRef.errors
										}"
										required
									>
									</ngx-intl-tel-input>
									<span
										*ngIf="editAdminUserForm.submitted && TDPhoneRef.invalid"
										class="invalid-form"
									>
										<small class="form-text text-danger" *ngIf="TDPhoneRef.errors?.required">{{
											"UI.k_field_required" | translate
										}}</small>
										<small
											class="form-text text-danger"
											*ngIf="TDPhoneRef.errors?.validatePhoneNumber"
											>{{ "UI.k_phone_number_validation" | translate }}</small
										>
									</span>
								</div>
								<!-- <div class="brand-logo mt-1 form-group">
									<label class="form-label mb-75">
										<span>{{ "UI.k_organization" | translate }}</span>
										{{ "UI.k_logo" | translate }} *</label
									>
									<ng-container *ngIf="!logo">
										<app-img-upload-feature
											(imgUploaderDetails)="uploadLogo($event)"
											(Removeimg)="removeImage($event)"
											[showQueue]="showQueue"
											[multipleFiles]="multipleFiles"
											[imgDetailsObject]="imgDetailsObject"
											[displayResoluion]="displayResoluion"
											[ALLOWED_TYPES]="ALLOWED_TYPES"
											[MAX_IMAGE_SIZE]="MAX_IMAGE_SIZE"
											[imgType]="imageType"
											[validation_type]="true"
											[isOnlyImage]="true"
											[is_cover]="false"
											[containWithinAspectRatio]="false"
										>
										</app-img-upload-feature>
									</ng-container>

									<img *ngIf="logo && logo_height" [src]="logo" height="{{ logo_height }}" />
									<img *ngIf="logo && logo_width" [src]="logo" width="{{ logo_width }}" />
									<div *ngIf="logo" class="d-flex justify-content-center mt-1">
										<button
											id="btnDelLogo"
											name="btnDelLogo"
											(click)="removeImage('logo')"
											type="button"
											class="btn btn-sm btn-flat-primary"
											rippleEffect
											tooltipClass="fadeInAnimation"
											ngbTooltip="{{ 'UI.k_remove_img' | translate }}"
										>
											{{ "UI.k_remove" | translate }}
										</button>
									</div>
								</div>
								<div class="user-image form-group">
									<label class="form-label"> {{ "UI.k_profile_image" | translate }} *</label>
									<app-profile-avatar
										(avatarDetails)="profileImage($event, editAdminUserForm)"
										(resetImage)="removeUserImage($event, editAdminUserForm)"
										(avatarBgColor)="ProfileBgColor($event)"
										[profileName]="organizationData.user.full_name"
										[profile_image]="organizationData.user.profile_image"
										[avatarBackgroundColor]="currentAvatarColor"
										[activeColor]="activeAvatar"
										(currentAvatar)="currrentColor($event)"
									></app-profile-avatar>
								</div> -->
							</div>
						</ng-scrollbar>
						<!-- </div>  -->
						<div class="modal-content-footer flex-initial">
							<div class="d-flex justify-content-end border-top px-2 py-1">
								<button
									type="submit"
									class="btn btn-primary btn-next"
									rippleEffect
									id="btnSaveAdminUser"
									name="btnSaveAdminUser"
									[disabled]="disableSave"
									(click)="onSubmit(editAdminUserForm)"
								>
									<i class="fa-light fa-floppy-disk align-middle ml-sm-25 ml-0 mr-1"></i>
									<span class="align-middle d-sm-inline-block d-none">
										{{ "UI.k_save" | translate }}
									</span>
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
