<form #profileDataForm="ngForm">
	<div class="media d-flex justify-content-center">
		<a href="javascript:void(0);" class="d-flex justify-content-center">
			<img *ngIf="profile_image" [src]="profile_image" class="rounded-circle" alt="profile image" width="100%" />

			<div
				*ngIf="!profile_image && this.profileName"
				class="avatar avatar-76-80 rounded-circle"
				[ngStyle]="{ 'background-color': avatarBackgroundColor }"
			>
				<span class="avatar-content" [ngStyle]="{ 'background-color': avatarBackgroundColor }">{{
					profileName | initials | slice: 0:2
				}}</span>
			</div>
			<div
				*ngIf="!profile_image && !this.profileName"
				[ngStyle]="{ 'background-color': avatarBackgroundColor }"
				class="avatar avatar-76-80 rounded-circle mr-50"
			>
				<span class="avatar-content">
					<i class="fa-light fa-user fa-1x user-icon-clr"></i>
				</span>
			</div>
		</a>
	</div>
	<br />
	<div class=" ">
		<!-- Button trigger modal -->
		<div class="d-flex justify-content-center" *ngIf="editable">
			<button
				id="btnChange"
				type="button"
				class="btn btn-sm btn-outline-primary"
				(click)="modalOpenSM(modalSM)"
				rippleEffect
			>
				{{ "UI.k_change" | translate }}
			</button>
		</div>
		<div *ngIf="profile_image" class="d-flex justify-content-center">
			<button
				*ngIf="profile_image"
				id="btnRemovePic"
				type="button"
				class="btn btn-sm btn-flat-primary mt-1"
				(click)="removeImage()"
				rippleEffect
			>
				{{ "UI.k_remove" | translate }}
			</button>
		</div>

		<!-- Modal -->
		<ng-template #modalSM let-modal>
			<div tabindex="0" ngbAutofocus>
				<ul ngbNav #nav="ngbNav" class="nav-tabs border-bottom mt-50">
					<li ngbNavItem>
						<a ngbNavLink id="tabcustom" (click)="customProfile()">{{ "UI.k_custom_img" | translate }}</a>
						<ng-template ngbNavContent>
							<!--<div class="px-6 font-small-3" *ngIf="!imageDetail">{{ "UI.k_upload_descr" | translate }}</div>-->

							<div class="text-center pt-1 body-height" *ngIf="imageDetail">
								<img
									*ngIf="profile_image"
									[src]="profile_image"
									class="rounded"
									alt="profile image"
									height="200"
								/>
							</div>
							<div
								class="px-1 pt-75"
								*ngIf="setImage && !imageDetail"
								[ngStyle]="{
									'min-height': change === true ? '225.44px' : '284px'
								}"
							>
								<app-img-upload-feature
									(imgUploaderDetails)="imageDetails($event)"
									(fileUploader)="fileUpload($event)"
									[showQueue]="showQueue"
									[multipleFiles]="multipleFiles"
									[imgDetailsObject]="imgDetailsObject"
									[displayResoluion]="displayResoluion"
									[displayTxt]="true"
									[txtMargin]="txtMargin"
									[squareImage]="true"
									[ALLOWED_TYPES]="ALLOWED_TYPES"
									[MAX_IMAGE_SIZE]="MAX_IMAGE_SIZE"
									[imgType]="imageType"
									[isOnlyImage]="true"
									[validation_type]="true"
								>
								</app-img-upload-feature>
							</div>
							<div class="modal-footer" *ngIf="imageDetail">
								<button
									id="btnDelete"
									type="button"
									class="btn btn-primary btn-sm"
									(click)="removeImage()"
									rippleEffect
								>
									{{ "UI.k_remove" | translate }}
								</button>
							</div>
						</ng-template>
					</li>
					<li ngbNavItem>
						<a id="avtartab" (click)="removeImage()" ngbNavLink>{{ "UI.k_initial_avatar" | translate }}</a>
						<ng-template ngbNavContent>
							<div class="body-height">
								<ul class="list-inline unstyled-list avatar-color-list ml-50">
									<li
										id="ligreenA"
										name="ligreenA"
										class="ball active"
										style="background: #28c76f"
										(click)="onSelectAvatarColor('#28c76f')"
									>
										<span id="spgreenA" class="bg-check" *ngIf="active == '#28c76f'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>

									<li
										id="ligreenB"
										class="ball"
										style="background: #28c76e"
										(click)="onSelectAvatarColor('#28c76e')"
									>
										<span id="spgreenB" class="bg-check" *ngIf="active == '#28c76e'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>
									<li
										id="ligreenC"
										class="ball"
										style="background: #28c77b"
										(click)="onSelectAvatarColor('#28c77b')"
									>
										<span id="spgreenC" class="bg-check" *ngIf="active == '#28c77b'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>
									<li
										id="ligreenD"
										class="ball"
										style="background: #28c753"
										(click)="onSelectAvatarColor('#28c753')"
									>
										<span id="spgreenD" class="bg-check" *ngIf="active == '#28c753'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>
									<li
										id="liredA"
										class="ball"
										style="background: #ea5455"
										(click)="onSelectAvatarColor('#ea5455')"
									>
										<span id="spredA" class="bg-check" *ngIf="active == '#ea5455'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>
									<li
										id="liredB"
										class="ball"
										style="background: #ff7777"
										(click)="onSelectAvatarColor('#ff7777')"
									>
										<span id="spredB" class="bg-check" *ngIf="active == '#ff7777'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>
									<li
										id="liredC"
										class="ball"
										style="background: #f08182"
										(click)="onSelectAvatarColor('#f08182')"
									>
										<span id="spredC" class="bg-check" *ngIf="active == '#f08182'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>
									<li
										id="liredD"
										class="ball"
										style="background: #ff4345"
										(click)="onSelectAvatarColor('#ff4345')"
									>
										<span id="spreD" class="bg-check" *ngIf="active == '#ff4345'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>

									<li
										id="liyellowA"
										class="ball"
										style="background: #ffce43"
										(click)="onSelectAvatarColor('#ffce43')"
									>
										<span id="spyellowA" class="bg-check" *ngIf="active == '#ffce43'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>
									<li
										id="liyellowC"
										class="ball"
										style="background: #ff9f43"
										(click)="onSelectAvatarColor('#ff9f43')"
									>
										<span id="spyellowC" class="bg-check" *ngIf="active == '#ff9f43'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>
									<li
										id="liyellowD"
										class="ball"
										style="background: #75d1ba"
										(click)="onSelectAvatarColor('#75d1ba')"
									>
										<span id="spyellowD" class="bg-check" *ngIf="active == '#75d1ba'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>
									<li
										id="liyellowB"
										class="ball"
										style="background: #f59518"
										(click)="onSelectAvatarColor('#f59518')"
									>
										<span id="spyellowB" class="bg-check" *ngIf="active == '#f59518'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>
									<li
										id="lilemonA"
										class="ball"
										style="background: #baa80b"
										(click)="onSelectAvatarColor('#baa80b')"
									>
										<span id="splemonA" class="bg-check" *ngIf="active == '#baa80b'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>
									<li
										id="librownA"
										class="ball"
										style="background: #642a2a"
										(click)="onSelectAvatarColor('#642a2a')"
									>
										<span id="spbrownA" class="bg-check" *ngIf="active == '#642a2a'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>
									<li
										id="liblueA"
										class="ball"
										style="background: #0057aa"
										(click)="onSelectAvatarColor('#0057aa')"
									>
										<span id="spblueA" class="bg-check" *ngIf="active == '#0057aa'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>

									<li
										id="liblueB"
										class="ball"
										style="background: #007ef6"
										(click)="onSelectAvatarColor('#007ef6')"
									>
										<span id="spblueB" class="bg-check" *ngIf="active == '#007ef6'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>

									<li
										id="liblueC"
										class="ball"
										style="background: #2110e5"
										(click)="onSelectAvatarColor('#2110e5')"
									>
										<span id="spblueC" class="bg-check" *ngIf="active == '#2110e5'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>
									<li
										id="liblueD"
										class="ball"
										style="background: #00bdf7"
										(click)="onSelectAvatarColor('#00bdf7')"
									>
										<span id="spblueD" class="bg-check" *ngIf="active == '#00bdf7'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>
									<li
										id="liDarkViolet"
										class="ball"
										style="background: #0082aa"
										(click)="onSelectAvatarColor('#0082aa')"
									>
										<span id="spDarkViolet" class="bg-check" *ngIf="active == '#0082aa'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>
									<li
										id="lightViolet"
										class="ball"
										style="background: #978ef2"
										(click)="onSelectAvatarColor('#978ef2')"
									>
										<span id="splightViolet" class="bg-check" *ngIf="active == '#978ef2'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>

									<li
										id="liVioletA"
										class="ball"
										style="background: #5a8f93"
										(click)="onSelectAvatarColor('#5a8f93')"
									>
										<span id="spVioletA" class="bg-check" *ngIf="active == '#5a8f93'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>
									<li
										id="liVioletB"
										class="ball"
										style="background: #7e7c8e"
										(click)="onSelectAvatarColor('#7e7c8e')"
									>
										<span id="spVioletB" class="bg-check" *ngIf="active == '#7e7c8e'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>
									<li
										id="liVioletC"
										class="ball"
										style="background: #a5c1d4"
										(click)="onSelectAvatarColor('#a5c1d4')"
									>
										<span id="spVioletC" class="bg-check" *ngIf="active == '#a5c1d4'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>
									<li
										id="liblack"
										class="ball"
										style="background: #000000"
										(click)="onSelectAvatarColor('#000000')"
									>
										<span id="spblack" class="bg-check" *ngIf="active == '#000000'">
											<i class="fa fa-check text-white check"></i>
										</span>
									</li>
								</ul>
							</div>

							<div class="modal-footer">
								<button
									type="button"
									class="btn btn-outline-primary btn-sm"
									(click)="previousColor()"
									rippleEffect
								>
									{{ "UI.k_cancel" | translate }}
								</button>

								<button
									type="submit"
									class="btn btn-primary btn-sm"
									(click)="saveData(profileDataForm)"
									rippleEffect
								>
									{{ "UI.k_accept" | translate }}
								</button>
							</div>
						</ng-template>
					</li>
				</ul>
				<div [ngbNavOutlet]="nav" class="mt-2"></div>
			</div>
		</ng-template>
		<!-- / Modal -->
	</div>
</form>
