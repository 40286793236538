import { Injectable } from '@angular/core';
import { ApiService } from "app/common/services/api/api.service";
import { Observable } from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class ReleaseService extends ApiService {

  getReleases(payload = {}) {
		return this.get("ux/sd/release/release/", payload);
	}

	saveRelease(payload: any) {
		return this.post("ux/sd/release/release/", payload);
	}

	getRelease(release_id: any) {
		return this.get("ux/sd/release/release/" + release_id + "/");
	}

	editRelease(release_id: any, payload: any) {
		return this.put("ux/sd/release/release/" + release_id + "/", payload);
	}
	deleteRelease(payload: any) {
		return this.post("ux/sd/release/release/delete_release/", payload);
	}
	getReleaseColumns(payload = {}) {
		return this.get("ux/sd/release/release/page_config/", payload);
	}
	getAttachmentDetails(payload = {}) {
		return this.get("ux/sd/release/release/attachment/", payload);
	}

	getHistoryDetails(payload = {}) {
		return this.get("ux/sd/release/release/history/", payload);
	}

	addAttachments(payload: any) {
		return this.post("ux/sd/release/release/add-attachment/", payload);
	}

	savePageConfig(payload) {
		return this.post("ux/sd/release/release/page/", payload);
	}
	getOptions(payload = {}) {
		return this.get("ux/sd/release/release/options/", payload);
	}
	getExpertise(payload = {}) {
		return this.get("ux/sd/release/release/expertise/", payload);
	}
	getExpertiseLevels(payload = {}) {
		return this.get("ux/sd/release/release/team_level/", payload);
	}
	getAssigneeList(payload = {}) {
		return this.get("ux/sd/release/release/assignee/", payload);
	}
	getUserProfile(userid: Number) {
		return this.get("ux/common/user/profile/" + userid.toString() + "/");
	}
	getServicesForSelectedCatalogue(payload: any) {
		return this.get("ux/common/catalogue/servicecatalogue/service-list/", payload);
	}
	getServiceDetails(serviceId: Number) {
		return this.get("ux/common/catalogue/servicecatalogue/" + serviceId.toString() + "/");
	}
	updateReleaseAssignee(payload = {}) {
		return this.put("ux/sd/release/release/update-assignee/", payload);
	}
	getFilteredRelease(payload = {}) {
		return this.get("ux/sd/release/release/top_filter/", payload);
	}
	saveConversation(payload) {
		return this.post("ux/sd/release/conversation/", payload);
	}
	getMailConversations(payload) {
		return this.get("ux/sd/release/conversation/", payload);
	}
	updateReleaseTag(payload = {}) {
		return this.put("ux/sd/release/release/update-tag/", payload);
	}
	loadOrganiationConfig(payload: {}) {
		return this.get("ux/org/profile/orgconfig/", payload);
	}

	saveRichTextData(payload: {}) {
		return this.post("ux/sd/release/release/save-rich-text/", payload);
	}

	getRichTextData(payload: {}) {
		return this.get("ux/sd/release/release/process-rich-text/", payload);
	}
	getRequesterList(payload: {}) {
		return this.get("ux/sd/release/release/requester_search/", payload);
	}

	processReleaseRelations(payload: {}) {
		return this.get("ux/sd/release/release/process-relations/", payload);
	}

	mergeRelease(payload: {}) {
		return this.post("ux/sd/release/release/merge/", payload);
	}
	getReqLatestReleases(payload: {}) {
		return this.get("ux/sd/release/release/latest-releases/", payload);
	}
	deleteAttachment(attachmentId: any, payload: {}) {
		return this.put("ux/sd/release/release/delete-attachment/" + attachmentId.toString() + "/", payload);
	}
	convertReleaseToKB(kbId: any, payload: {}) {
		return this.put("ux/sd/release/release/release-to-kb/" + kbId.toString() + "/", payload);
	}
	convertReleaseToIncident(incidentId: any, payload: {}) {
		return this.put("ux/sd/release/release/release-to-inci/" + incidentId.toString() + "/", payload);
	}

	getAllKBs(payload: {}) {
		return this.get("ux/sd/release/release/release-kbs/", payload);
	}
	getSavedFilter(payload) {
		return this.get("ux/common/filters/filters/filter/", payload);
	}

	getReleaseTypeBaseWorkflowStatus(payload: any) {
		return this.get("ux/sd/release/release/workflow_status/", payload);
	}

	setEditReleaseSidebar(flag) {
		localStorage.setItem("release/release-edit-flag", JSON.stringify(flag));
	}

	getReleaseSidebar() {
		return JSON.parse(localStorage.getItem("release-edit-flag"));
	}

	editReleaseAssignee(payload) {
		return this.post("ux/sd/release/release/update_release_assignee/", payload);
	}
	addReleaseRelation(payload) {
		return this.post("ux/sd/release/release/relation/", payload);
	}

	getWorkflowOptions(payload) {
		return this.post("ux/sd/release/release/workflow_options_map/", payload);
	}

	bulkReleaseResolve(payload) {
		return this.post("ux/sd/release/release/bulk-resolve/", payload);
	}

	bulkReleaseAssignment(payload) {
		return this.post("ux/sd/release/release/bulk-assignment/", payload);
	}
	getFilterOptions(payload: any) {
		return this.get("ux/common/filters/filters/filter_options/", payload);
	}
	getKBProfiles(payload = {}) {
		return this.get("ux/common/kb/kb/", payload);
	}
	getRequesterImpactService(payload) {
		return this.get("ux/common/catalogue/servicecatalogue/req_services/", payload);
	}

	getImpactServiceClassification(payload) {
		return this.get("ux/common/catalogue/servicecatalogue/service_classes/", payload);
	}
	getTeamUserMap(payload) {
		return this.get("ux/sd/release/release/team_user_map/", payload);
	}
	getReleaseData(pk, payload = {}) {
		return this.get(`ux/sd/release/release/release-data/${pk}/`, payload);
	}
	getReleaseTaskList(payload, pk) {
		return this.get(`ux/sd/task/task/rel-task-list/${pk}/`, payload);
	}
	getReleaseTask(pk, payload) {
		return this.get(`ux/sd/task/task/release-task/${pk}/`, payload);
	}
	saveReleaseTask(payload) {
		return this.post("ux/sd/task/task/save-release-task/", payload);
	}
	deleteReleaseTask(payload, pk) {
		return this.post(`ux/sd/task/task/delete-release-task/${pk}/`, payload);
	}
	addTaskRelation(payload){
		return this.post("ux/sd/release/release/relate-task/", payload);
	}
	getReqLatestIncidents(payload: {}) {
		return this.get("ux/sd/release/release/latest-releases/", payload);
	}
	getApprovalStatus = (payload): Observable<any> => {
		return this.get("ux/sd/release/release/check_approval_user/", payload);
	};
	saveTask(payload) {
		return this.post("ux/sd/task/task/", payload);
	}
}
