import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminDashboardComponent } from "./components/admin-dashboard/admin-dashboard.component";
import { DashboardInstanceComponent } from "./components/dashboard-instance/dashboard-instance.component";
import { HistoryComponent } from "./components/history/history.component";
import { ProductDetailComponent } from "./components/product-detail/product-detail.component";
import { PaymentComponent } from "./components/payment/payment.component";
import { UsersComponent } from "./components/users/users.component";
import { AdminLoginComponent } from "./components/admin-login/admin-login.component";
import { AuthGuard } from "app/common/guards/auth.guards";
import { PrivacyPolicyComponent } from "app/common/pages/components/privacy-policy/privacy-policy.component";
import { InvoicesComponent } from "./components/invoices/invoices.component";

const routes: Routes = [
	{
		path: "",
		component: AdminLoginComponent,
		data: {
			title: "Login",
		},
	},
	{
		path: "dashboard",
		component: AdminDashboardComponent,
		canActivate: [AuthGuard],
		data: {
			module: ["subscription"],
			keyList: ["add", "edit", "delete", "view"],
		},
	},
	{
		path: "privacy-policy",
		component: PrivacyPolicyComponent,
		canActivate: [AuthGuard],
		data: {
			module: ["subscription"],
			keyList: ["add", "edit", "delete", "view"],
		},
	},
	{
		path: "instance/:id",
		component: DashboardInstanceComponent,
		canActivate: [AuthGuard],
		data: {
			module: ["subscription"],
			keyList: ["add", "edit", "delete", "view"],
		},
	},
	{
		path: "product/:id",
		component: ProductDetailComponent,
		canActivate: [AuthGuard],
		data: {
			module: ["subscription"],
			keyList: ["add", "edit", "delete", "view"],
		},
	},
	{
		path: "payment",
		component: PaymentComponent,
		canActivate: [AuthGuard],
		data: {
			module: ["subscription"],
			keyList: ["add", "edit", "delete", "view"],
		},
	},
	{
		path: "users",
		component: UsersComponent,
		canActivate: [AuthGuard],
		data: {
			module: ["subscription"],
			keyList: ["add", "edit", "delete", "view"],
		},
	},
	{
		path: "audits",
		component: HistoryComponent,
		canActivate: [AuthGuard],
		data: {
			module: ["subscription"],
			keyList: ["add", "edit", "delete", "view"],
		},
	},
	{
		path: "invoices",
		component: InvoicesComponent,
		canActivate: [AuthGuard],
		data: {
			module: ["subscription"],
			keyList: ["add", "edit", "delete", "view"],
		},
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AdminRoutingModule {}
