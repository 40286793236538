import { Component, OnInit,Input} from '@angular/core';

@Component({
  selector: 'app-statistic-panel',
  templateUrl: './statistic-panel.component.html',
  styleUrls: ['./statistic-panel.component.scss']
})
export class StatisticPanelComponent implements OnInit {
  @Input() widgetData: any;
  constructor() { }

  ngOnInit(): void {
    //console.log(">>",this.widgetData)
  }

}
