<!-- Modal -->
<div class="modal-header border-bottom">
  <div class="input-group input-group-merge">
    <div class="input-group-prepend">
      <span class="input-group-text" id="basic-addon-search2"
        ><i data-feather="search"></i
      ></span>
    </div>
    <input
      type="text"
      class="form-control"
      placeholder="Search..."
      aria-label="Search..."
      aria-describedby="basic-addon-search2"
    />
    <div class="input-group-append">
      <span
        class="input-group-text cursor-pointer"
        (click)="activeModal.dismiss('Cross click')"
      >
        <i class="fa-light fa-times" style="font-size: 12px"></i
      ></span>
    </div>
  </div>
</div>
<div class="row px-1">
  <div class="col-md-6 border-right recentSearch" [perfectScrollbar]>
    <div class="d-flex justify-content-between align-items-center my-50">
      <span class="text-muted font-small-2">RECENTLY SEARCHED</span>
      <span type="button" class="btn btn-flat-primary btn-sm font-small-1"
        >Clear</span
      >
    </div>

    <ul class="p-0">
      <li
        *ngFor="let data of searchData.recentsearch; let i = index"
        tabindex="i"
        class="row px-1 justify-content-between align-items-center py-50 cursor-pointer bg-globle"
      >
        <span
          class="font-small-2"
          id=""
          [ngClass]="changeText[i] == true ? 'text-dark' : 'text-muted'"
          >{{ data.name }}</span
        >
        <span class="font-small-1 to-select">
          <span class="text-primary font-small-1 mr-25">to select</span>
          <i
            class="fa-light fa-arrow-turn-down-left text-primary font-small-1"
          ></i>
        </span>
      </li>
    </ul>
  <hr/>
    <div class="border-bottom">
      <div class="d-flex justify-content-between align-items-center my-50">
        <span class="text-muted font-small-2">RECENTLY VIEWED</span>
        <span type="button" class="btn btn-flat-primary btn-sm font-small-1"
          >Clear</span
        >
      </div>

      <div *ngFor="let data of searchData.recentView; let i = index">
        <div class="d-flex align-items-center py-50 mr-25">
          <div
            class="
              badge badge-light-primary
              font-weight-normal
              d-flex
              align-items-center
              justify-content-start
              cursor-pointer
            "
          >
            <i
              class="fa-light fa-{{ data.icon }} text-primary font-small-2"
            ></i>

            <span class="text-primary font-small-2 ml-50">{{ data.key }}</span>
          </div>
          <span class="text-secondary font-small-2 ml-50 cursor-pointer">{{
            data.value
          }}</span>
        </div>
      </div>
    </div>
    <div class="py-50">
      <span class="text-muted font-small-2 mt-1 mb-50">BOOKMARKED PAGES</span>
      <span class="ml-75 text-muted font-small-2"
        >({{ searchData.count.bookMark }})</span
      >
      <div class="d-flex" style="flex-wrap: wrap;">
          <span
          *ngFor="let items of searchData.bookMarkedpages; let i = index"
            class="
              modal-header
              my-25
              px-50
              py-25
              rounded
              d-flex
              align-items-center
              justify-content-start
              cursor-pointer
            "
            style="
              margin-right: 12px;
            "
          >
              <i class="fa-light fa-{{ items.icon }} font-small-2"></i>
            <span class="ml-50 font-small-2 cursor-pointer">
              {{ items?.name }}</span
            >
          </span>
      </div>
    </div>
  </div>

  <div class="col-md-3 border-right recentSearch" [perfectScrollbar]>
    <div class="text-muted font-small-2 mb-50 mt-1">PAGES</div>

    <div
      class="d-flex justify-content-between align-items-center mb-1 mt-75"
      *ngFor="let items of searchData.pages; let i = index"
    >
      <div
        class="d-flex justify-content-between align-items-center navigate cursor-pointer"
        id="navigate{{ i }}"
        name="navigate{{ i }}"
        [routerLink]="[items.handle]"
        [routerLinkActive]="'active'"
        (click)="toggleclose()"
      >
        <i class="fa-light fa-{{ items.icon }} font-small-1"></i>
        <span class="navigate font-small-2 ml-50">{{ items.name }}</span>
        <div
          *ngIf="items.type == 'Setup' || items.type == 'Notifications'"
          class="mx-50"
        >
          <div
            class="badge badge-light-primary font-weight-normal mr-25 p-25"
            style="font-size: 8px; border-radius: 2px"
          >
            CTRL
          </div>

          <i class="fa-light fa-plus text-primary mx-25 font-small-1"  ></i>

          <div
            class="badge badge-light-primary font-weight-normal mr-25 p-25"
            style="font-size: 8px; border-radius: 2px"
          >
            D
          </div>
        </div>
      </div>

      <span class="text-secondary font-small-1">{{ items.type }}</span>
    </div>
  </div>
  <div class="col-md-3 recentSearch" [perfectScrollbar]>
    <!-- <span class="text-muted font-small-2">HELP</span> -->
    <div class="chat-content mt-50 mb-2">
      <div class="text-white font-small-2 mb-75">
        Launching collabrative workspace to get a seamless experience
      </div>
      <div class="badge badge-light-primary waves-effect">
        <span class="font-small-1 text-white">Read more</span>
      </div>
    </div>

    <span class="text-muted font-small-2">NEW KNOWLEDGE BASE</span>
    <div
      class="border rounded my-1"
      *ngFor="let item of searchData.newKB; let i = index"
    >
      <div class="d-flex justify-content-start align-items-center">
        <div>
          <img
            class="rounded-left"
            src="assets/images/content/flowers-pieces/{{ item.icon }}"
            alt="brand-logo"
            width="60px"
          />
        </div>
        <span class="font-small-2 p-1">{{ item.describe }}</span>
      </div>
    </div>
  </div>
</div>

<div class="bg-header border-top pb-50 pt-50 px-75 rounded-bottom" > 
  <div class="d-flex justify-content-start align-items-center">
    <div *ngFor="let item of searchData.footericons; let i = index">
      <div
        class="footer-icon_box d-flex justify-content-start align-items-center"
      >
     
        <i class="fa-light fa-{{ item.icon }} text-primary font-small-1"></i>
      </div>
    </div>

      <div
        class="badge badge-light-primary font-weight-normal ml-25 mr-25 p-25"
        style="font-size: 8px; border-radius: 2px"
      >
        TAB
      </div>
      <span style="font-size: 10px" class="mr-75">To Navigate</span>
 
    <div class="d-flex justify-content-start align-items-center">
      <div
        class="
          footer-icon_box
          mr-25
          d-flex
          justify-content-start
          align-items-center
        "
      >
        <i
          class="fa-light fa-arrow-turn-down-left text-primary font-small-1"
        ></i>
      </div>
      <span style="font-size: 10px;">to Select</span>
    </div>

      <div
        class="badge badge-light-primary font-weight-normal ml-75 mr-25 p-25"
        style="font-size: 8px; border-radius: 2px"
      >
        Esc
      </div>
      <span style="font-size: 10px" class="mr-75">To Dismiss</span>
  </div>
</div>
<!-- / Modal -->
