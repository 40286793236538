import { Component, EventEmitter, Input, OnInit, Output, HostListener, ViewChild } from "@angular/core";
import { FlatpickrOptions } from "ng2-flatpickr";
import { ReleaseService } from "app/servicedesk/release-manage/services/release-services.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { catchError, map, throwError } from "rxjs";
import { convertToCalendarTime, customSearch } from "app/common/utils/utils"
import { TaskRequest } from "../../types/task_request";
import { WorkSpaceService } from "app/base/work-space/services/work-space.service";

@Component({
	selector: "app-dns-add-task",
	templateUrl: "./dns-add-task.component.html",
	styleUrls: ["./dns-add-task.component.scss"],
})
export class DNSAddTaskComponent implements OnInit {
	@Input() options: any = [];
	@Input() task_options: any = {};
	@Input() process_data: any = {};
	@Input() parentTask: any = {};
	@Output() onHideTask = new EventEmitter(false);
	@Input() type_id;
	@Input() task_id: any;
	@Input() is_my_task: boolean = false;
	@Input() current_user_profile: any = {};
	@Input() channel_id: any= "";
	@Output() onEditTask = new EventEmitter(false);
	@ViewChild("datepicker") dueDate: FlatpickrOptions;
	@ViewChild("untillDatepicker") untillDatepicker: FlatpickrOptions;
	public task_obj: any = {};
	public disableSaveBtn: boolean = false;
	typeMapping = {
		1: "UI.k_add_task",
		2: "UI.k_add_feature",
		3: "UI.k_add_issue",
	};
	aboutTaskMapping = {
		1: "UI.k_about_task",
		2: "UI.k_about_feature",
		3: "UI.k_about_issue",
	};
	public basicDateOptions: FlatpickrOptions = {
		altInput: true,
		altInputClass: "form-control flat-picker flatpickr-input",
	};
	public basicDateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: false,
		altInputClass: "form-control flat-picker flatpickr-input",
	};
	public untillDateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: false,
		altInputClass: "form-control flat-picker flatpickr-input",
	};
	header_label: any;
	isshownAssignee: boolean;
	userRequesterList: any[];
	searcRequestLen: any;
	searchFalse: boolean;
	is_requester_selected: boolean;
	searchStr: string;
	isSearchShown: boolean;
	accessType: any;
	is_ssp_login: boolean = false;
	currentRepeatValue: any;
	repeatOption: any;
	showUntillDatePicker: boolean;
	constructor(
		private _toastr: ToastrService,
		private _translateService: TranslateService,
		private _releaseeService: ReleaseService,
		public _workspaceService: WorkSpaceService,
	) {}

	ngOnInit(): void {
		this.repeatOption = this.options?.repeat_options
		this.task_obj["ref_id"] = this.process_data?.release_id;
		this.task_obj["ref_display_id"] = this.process_data?.display_id;
		this.task_obj["repeat_config"] = {}
		if (this.options?.task_priority?.length > 0) {
			this.task_obj["priority"] = this.options?.task_priority[0];
		}
		if (this.options?.status?.length > 0) {
			this.task_obj["status"] = this.options?.status[0];
		}
		if (this.repeatOption?.length > 0){
			this.task_obj.repeat_config["repeat"] = this.repeatOption[0]?.key
		}
		if (this.channel_id){
			this.task_obj.meta_data = {}
			this.task_obj.meta_data.channel_id = this.channel_id
		}
		
		if (this.process_data?.due_date) {
			this.basicDateOptions["maxDate"] = this.process_data?.due_date;
		}
		this.accessType = localStorage.getItem("accessType")
		if (this.accessType == 2){
			this.is_ssp_login = true
		}
		this.header_label = this._translateService.instant("UI.k_add_task")
		if (this.task_id != '-1' && this.task_id != undefined){
			if (this.task_options?.status != undefined){
				this.options = this.task_options
			}
			this.getTaskData();
			this.header_label = this._translateService.instant("UI.k_view_task")
		}
		else{
			if (this.is_my_task){
				this.task_obj["assignee"] = this.current_user_profile?.profile?.profile_id
				this.task_obj["assignee_profile"] = {
					profile_id: this.current_user_profile?.profile?.profile_id,
					name: this.current_user_profile?.profile?.full_name,
					full_name: this.current_user_profile?.profile?.full_name,
					email: this.current_user_profile?.profile?.email,
				}
				this.task_obj["assignee_info"] = {
					profile_id: this.current_user_profile?.profile?.profile_id,
					name: this.current_user_profile?.profile?.full_name,
					full_name: this.current_user_profile?.profile?.full_name,
					email: this.current_user_profile?.profile?.email,
					avatar_color: this.current_user_profile?.profile?.avatar_color,
					profile_image: this.current_user_profile?.profile?.profile_image,
				}
				this.isshownAssignee = true
			}
		}
	}

	toggleSidebar() {
		this.onHideTask.emit();
	}

	SaveTask = (data) => {
		if (data.form.valid === true) {
			if (this.task_obj.due_date instanceof Array) {
				this.task_obj.due_date = this.task_obj.due_date[0];
				this.task_obj.due_date = this.task_obj.due_date.toDateString();
			}
			if (this.task_obj?.repeat_config?.until_date instanceof Array) {
				let until_date = this.task_obj?.repeat_config?.until_date[0];
				this.task_obj.repeat_config.until_date= convertToCalendarTime(until_date);
			}
			
			this.disableSaveBtn = true;
			this.task_obj["type_id"] = this.type_id; //  for task
			if (this.parentTask?.task_id) {
				this.task_obj["parent_obj"] = {
					task_id: this.parentTask?.task_id,
					display_id: this.parentTask?.display_id,
				};
			}
			this._releaseeService
				.saveTask(this.task_obj)
				.pipe(
					map((response: any) => {
						this.disableSaveBtn = false;
						if (response) {
							if (response["status"] == "success") {
								// this.task_obj = {};
								this._toastr.success(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Message.msg_save_success"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
								const taskPayload = Object.assign({}, this.task_obj);
								const taskRequest: TaskRequest = new TaskRequest(taskPayload);
								if (this.task_id != '-1' && this.task_id != undefined){
									this.onEditTask.emit({task_request: taskRequest})
								} else {
									this.onHideTask.emit({task_request: taskRequest, task_payload: this.task_obj, task_response: response})
								}
							} else {
								this._toastr.error(
									this._translateService.instant(response["message"], {
										existing_rule: response?.data?.existing_rule,
									}),
									this._translateService.instant("Error.err_save_failed"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							}
						}
						// Success
					}),
					catchError((error) => {
						this._toastr.error(
							this._translateService.instant("Error.err_save_failed"),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						this.disableSaveBtn = false;
						return throwError(error.statusText);
					})
				)
				.subscribe();
		}
	};

	onStatusChange = (data) => {
		this.task_obj.status = data;
	};

	statusBadgeChange = (data) => {
		this.task_obj[data["field"]] = data?.event ? data.event : {};
	};

	customSearchFn(term: string, item: any) {
		return customSearch(term, item, "full_name", "email");
	}

	onAssigneeChange = ($event) => {
		this.task_obj["assignee_profile"] = {
			profile_id: $event?.profile_id,
			name: $event?.full_name,
			full_name: $event?.full_name,
			email: $event?.email,
		};
	};
	@HostListener("keydown", ["$event"]) //keydown, not keyup
	onKeyDown(event: any) {
		if (event.keyCode === 13 && event.target?.tagName != "BUTTON") {
			// 13 = enter
			event.preventDefault();
			return false;
		}
	}
	nameExtractorHandler(data: any) {
		return data
			?.split(" ")
			.map((n) => n[0])
			.join("");
	}

	ngOnDestroy() {
		this.dueDate?.flatpickr.close();
		this.untillDatepicker?.flatpickr.close();
	}

	getTaskData() {
		this._releaseeService
		.getReleaseTask(this.task_id, {}).subscribe(
			(response) => {
				if (response) {
					this.task_obj = response?.data;
					if (this.task_obj?.assignee && this.task_obj?.assignee != undefined){
						this.isshownAssignee = true
					}
				}
			},
			(error) => {
				//todo
			}
		);
	}

    searchRequester(searchStr, key) {
        this.isshownAssignee = false;
        this.userRequesterList = [];
        this.searcRequestLen = searchStr.length;
        if (searchStr == searchStr.length < 0) {
        this.searchFalse = true;
        }
        if (searchStr != undefined && searchStr.length > 2) {
        this.searchFalse = false;
        this.is_requester_selected = false
		let payload = {
			filter_string: encodeURIComponent(searchStr),
		}
        this._workspaceService.searchUserRequester(payload).subscribe((response) => {
            this.userRequesterList = response;
        });
        }
    };

	shownAssignee(requester) {
		this.userRequesterList = []
		this.isshownAssignee = true;
		this.searchStr = null
		this.task_obj.assignee = requester?.profile_id;
		this.task_obj.assignee_info = requester;
		this.onAssigneeChange(requester)
	}

	removeAssignee = () =>{
		this.isshownAssignee = false;
		this.task_obj.assignee_info = null;
		this.searchStr = null;
	}
	changeRepeatType = (event) => {
		this.task_obj.repeat_config.repeat_type = event.target.defaultValue
		if(event.target.defaultValue === 'untill'){
			this.showUntillDatePicker = true;
		}else{
			this.showUntillDatePicker = false;
		}
	}

	onRepeatChange = (event) => {
		this.task_obj.repeat_config.until_date = null
		this.task_obj.repeat_config.repeat_type = 'forever'
		if (this.task_obj.repeat_config.repeat == "no_repeat"){
			this.task_obj.repeat_config.repeat_type = null
		}
	}
}
