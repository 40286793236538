<div class="event-status">
	<ng-container *ngFor="let item of config.data">
		<div
			id="divevent"
			name="divevent"
			class="event-item border-right"
			[ngClass]="item?.value > 0 && item?.item_filter ? 'cursor-pointer' : ''"
			(click)="showEventSidebar(item)"
		>
			<!-- <div class="icon-sec" [ngClass]="'bg-' + item?.color">
				<i class="{{ item?.icon }}"></i>
			</div> -->
			<div class="icon-sec" *ngIf="item?.color?.startsWith('#')" [ngStyle]="{ 'background-color': item?.color }">
				<i class="{{ item?.icon }}"></i>
			</div>
			<div class="icon-sec" *ngIf="!item?.color?.startsWith('#')" [ngClass]="'bg-' + item?.color">
				<i class="{{ item?.icon }}"></i>
			</div>
			<div class="event-title">{{ item?.name }}</div>
			<div class="event-value">
				{{ item?.value }}
				<ng-container *ngFor="let subItem of item.updates">
					<span
						[ngStyle]="{
							'background-color': subItem.background_color,
							color: subItem.icon_color
						}"
					>
						<div class="icon"><i class="fas fa-sort-up"></i></div>
						{{ subItem?.text }}
					</span>
				</ng-container>
			</div>
			<div class="event-day" *ngIf="item.duration">({{ item?.duration }})</div>
		</div>
	</ng-container>
</div>
