<ng-template #headerTemplate>
  <div class="treeview-header border-bottom py-50">
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="mb-0">{{headingText}}</h6>
      <div class="cursor-pointer" *ngIf="showTemplateEdit" (click)="clickEditTemplate()">
        <i class="fa-light fa-pen-to-square sm-font body-color"></i>
      </div>
    </div>
    
  </div>
</ng-template>

<ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand"
  let-onCheckedChange="onCheckedChange">

  <div class="text-nowrap row-item">
    <span *ngIf="item.children" class="mr-25 item-main" (click)="onCollapseExpand()" aria-hidden="true" [ngSwitch]="item.collapsed">
      <svg  *ngSwitchCase="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right bi bi-caret-right-fill"><polyline points="9 18 15 12 9 6"></polyline></svg>
      <svg *ngSwitchCase="false" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down bi bi-caret-down-fill"><polyline points="6 9 12 15 18 9"></polyline></svg>
    </span>
    <label class="form-check-label" (click)="select(item);onCheckedChange()">{{ item.text }}</label>
    <span *ngIf="showPreviewIcon" class="eye-icon cursor-pointer ml-50" (click)="showTemplateDetail(item)">
      <i class="fa-light fa-eye text-primary"></i>
    </span>
  </div>
</ng-template>
<div 
[ngClass]=" !this.treeInputConfig ? 'tree-dropdown single-select': 'multi-tree-dropdown single-select tree-dropdown' "
>
  <ngx-dropdown-treeview [config]="config" [id]="this.id" [items]="items" [buttonClass]="buttonClass"
    [itemTemplate]="itemTemplate" [headerTemplate]="headerTemplate" (selectedChange)="onSelectedChange($event)">
  </ngx-dropdown-treeview>
</div>