import { Injectable } from "@angular/core";
import { ApiService } from "app/common/services/api/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class SupportPortalService extends ApiService {
	constructor(httpClient: HttpClient) {
		super(httpClient);
	}

	getOrganizations(payload = {}) {
		return this.get("ux/org/orgprofile/", payload);
	}

	getFilterOptions(payload: any) {
		return this.get("ux/common/filters/filters/filter_options/", payload);
	}

	getSavedFilters(payload) {
		return this.get("ux/common/filters/filters/filter/", payload);
	}

	getOptions(payload: {}) {
		return this.get("ux/org/orgprofile/options/", payload);
	}

	getLicense(payload: {}) {
		return this.get("ux/org/orgprofile/download-license/", payload);
	}

	getOrgFilters(payload: {}) {
		return this.get("ux/org/orgprofile/orgfilters/", payload);
	}

	saveOrganization(payload: any) {
		return this.post("ux/org/orgprofile/", payload);
	}

	deleteOrganization(id) {
		return this.delete("ux/org/orgprofile/" + id + "/");
	}

	getOrganization(id) {
		return this.get("ux/org/orgprofile/" + id + "/");
	}

	downloadLicense(payload: any) {
		return this.get("ux/org/orgprofile/download-license/", payload);
	}

	getRoleType(payload: {}) {
		return this.get("ux/org/orgprofile/role/", payload);
	}

	checkEmailAvailability(payload: any) {
		return this.post("ux/org/orgprofile/verifyemail/", payload);
	}

	getPartners(payload) {
		return this.get("ux/common/partner/partner/", payload);
	}

	savePartner(payload) {
		return this.post("ux/common/partner/partner/", payload);
	}

	getPartner(id) {
		return this.get("ux/common/partner/partner/" + id + "/");
	}

	deletePartner(id) {
		return this.delete("ux/common/partner/partner/" + id + "/");
	}

	getSavedFilter(payload) {
		return this.get("ux/common/filters/filters/filter/", payload);
	}
	savePageFilter(payload: any) {
		return this.post("ux/common/user/profile/preference/", payload);
	}

	sendUserInvite(invites: any) {
		return this.post("ux/common/user/profile/user_invite/", invites);
	}

	getAuditList(payload = {}) {
		return this.get("ux/common/audit/auditinfo/", payload);
	}
}
