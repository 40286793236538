import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { WSSService } from 'app/common/services/wss/wss.service';
import { BotConfigService } from '../../services/bots-configuration.service';
import { InfraonMixpanelService } from 'app/services/mixpanel/infraon-mixpanel.service';
import { ToastrService } from 'ngx-toastr';
import Swal from "sweetalert2";
import { catchError, map, reduce } from "rxjs/operators";
import { from, throwError } from "rxjs";

@Component({
  selector: 'app-bots-configuration-grid',
  templateUrl: './bots-configuration-grid.component.html',
  styleUrls: ['./bots-configuration-grid.component.scss']
})
export class BotsConfigurationGridComponent implements OnInit {

  public getItemList:any;
  public showDataTable = false;
  public showCreateBotSlider = false;
  public columnOptions = [];
  public conditionOptions = [];
  public savedData = [];
  public option = [];
  public menu: any; 
  public totalDataStore = 3;
  public isEdit = false;
  public selectedItem:  any = {};
  pageNumber = 1;
  currentEvent: String;
  public showAll = false;
  reverse: string = "";
	sortKey: string = "name";
  filter = [];
  limit = 10;
  botConfigCount = 0;
	currentCount = 0;
  public showData = false;
  public predefined_bot_img_map = {
'IT Assistance':"../../../../../assets/images/bots-configuration/it-assistance.svg",
'HR Assistance':"../../../../../assets/images/bots-configuration/hr-assistance.svg",
'Finance Assistance':"../../../../../assets/images/bots-configuration/finance-assistance.svg",
'Legal Assistance':"../../../../../assets/images/bots-configuration/legal-assistance.svg",
'OPs Assistance':"../../../../../assets/images/bots-configuration/ops-assistant.svg"
  }
  constructor(
    @Inject(DOCUMENT) private document,
		private _activatedRoute: ActivatedRoute,
		private _coreSidebarService: CoreSidebarService,
		private modalService: NgbModal,
		private _coreConfigService: CoreConfigService,
		private _translateService: TranslateService,
		private _botConfigService: BotConfigService,
		private router: Router,
		private _toastr: ToastrService,
		private elementRef: ElementRef,
		private _wssService: WSSService,
		private _mixpanelService: InfraonMixpanelService
  ) { }

  ngOnInit(): void {
    this.updateNavbar();
    this.getBotList();
  }
  getBotList() {
		const payload = {
			page: this.pageNumber,
			items_per_page: this.showAll && this.currentEvent === "Add" ? Number(this.limit) + 1 : Number(this.limit),
			sort: this.sortKey,
			reverse: this.reverse,
			filter: JSON.stringify(this.filter),
			module: "bot_config",
		};
		this._botConfigService.getBotList(payload).subscribe(
			(response) => {
				if (response) {
					if (this.showAll && this.currentEvent === "Add") {
						this.limit = Number(this.limit) + 1;
					}
					this.menu = response.results;
					this.botConfigCount = response.count;
					this.showData = true;
					// this.filterData = false;
					this.currentCount = this.menu?.length || 0;
				}
			},
			(error) => {
				//todo
			}
		);
	}

  updateNavbar(){
    this._coreConfigService.config = {
      layout: {
        navbar: {
          loadHTML: false,
          hidden: false,
          pageTitle: this._translateService.instant('UI.k_infraon_config'),
          moduleName: "bots_configuration",
          //backgroundColor: "",
          pageIcon: "fa-box-taped",
          breadcrumb: {
            type: "",
            links: [
              {
                name: this._translateService.instant(
                  "UI.k_infraon_config"
                ),
                isLink: true,
                link: "/configuration",
              },
              {
                name: this._translateService.instant(
                  "UI.k_bot_upload_config"
                ),
                isLink: false,
                link: "/",
              },
            ],
          },
        },
      },
    };

  }
  getFilterSearch(e) {

  }
  genericSearchFilter(e) {

  }
  getSearchWidth(e) {

  }
  setPage(pageInfo) {
		// this.pageNumber = pageInfo.offset;
		this.pageNumber = pageInfo?.page;
		this.getBotList();
	}
  setLimit(limit): void {
		this.pageNumber = 1;
		// this.limit = args.target.value;
		if (limit === "all") {
			this.showAll = true;
			this.limit = this.botConfigCount;
		} else {
			this.limit = Number(limit);
			this.showAll = false;
		}
		this.getBotList();
	}

  onShowCreateSidebar(){
  }
  openCreateBotSidebar =() =>{
    this.selectedItem = {}
    this.showCreateBotSlider = true;
    this.isEdit = false;
    this._coreSidebarService.getSidebarRegistry("create-bot-sidebar").toggleOpen();
  }
  hidecreateBotSidebar(e) {
    this.showCreateBotSlider = false;
    this._coreSidebarService.getSidebarRegistry("create-bot-sidebar").close();
    this.getBotList();
  }
  initiateBot =(data) =>{
    data['is_enabled'] =true
    this.selectedItem = data
    this.isEdit = true;
    this.showCreateBotSlider = true;
    this._coreSidebarService.getSidebarRegistry("create-bot-sidebar").toggleOpen();
  }
  openEditBotSidebar = (data) =>{
    this.selectedItem = data
    this.isEdit = true;
    this.showCreateBotSlider = true;
    this._coreSidebarService.getSidebarRegistry("create-bot-sidebar").toggleOpen();
  }
  deleteBot = (row) => {
		Swal.fire({
			title: this._translateService.instant("Message.msg_delete_title"),
			text: this._translateService.instant("Message.msg_delete_text"),
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: this._translateService.instant("Message.msg_delete_confirm"),
			cancelButtonText: this._translateService.instant("Message.msg_delete_cancel"),
			customClass: {
				confirmButton: "btn btn-primary",
				cancelButton: "btn btn-danger ml-1",
			},
		}).then((result) => {
			if (result.value) {

				this._botConfigService
								.deleteBotConfig(row?.bot_config_id)
								.pipe(
									map((response: any) => {
										if (response.status == "success") {
											// subtracting 1 from page number if count equals to items per page limit after delete
											if (this.botConfigCount  != 1 && this.botConfigCount - 1 < (this.pageNumber-1) * this.limit + 1) {
												this.pageNumber = this.pageNumber - 1;
											}
											if (this.showAll) {
												this.limit = this.limit - 1;
											}
											this.getBotList();
											this._toastr.success(
												this._translateService.instant(response["message"]),
												this._translateService.instant("Message.msg_delete_success"),
												{
													toastClass: "toast ngx-toastr",
													closeButton: true,
													positionClass: "toast-top-right",
												}
											);
										} else {
											this._toastr.error(
												this._translateService.instant(response["message"]),
												this._translateService.instant("Error.err_delete_failed"),
												{
													toastClass: "toast ngx-toastr",
													closeButton: true,
													positionClass: "toast-top-right",
												}
											);
										}
										// Success
									}),
									catchError((error) => {
										this._toastr.error(
											this._translateService.instant("Error.err_delete_botconfig"),
											this._translateService.instant("Error.err_delete_failed"),
											{
												toastClass: "toast ngx-toastr",
												closeButton: true,
												positionClass: "toast-top-right",
											}
										);
										return throwError(error.statusText);
									})
								)
								.subscribe();
			}
		});
	};

}
