<span *ngIf="templateRef.invalid && formRef?.submitted" class="invalid-form">
	<small class="form-text text-danger" *ngIf="templateRef.errors.pattern">{{
		validationMessageConfig?.pattern || ("Error.err_enter_valid_data" | translate)
	}}</small>
	<small class="form-text text-danger" *ngIf="templateRef.errors.required">{{
		validationMessageConfig?.required || "UI.k_field_required" | translate
	}}</small>
	<small class="form-text text-danger font-small-1" *ngIf="templateRef.errors?.whitespace"
		>{{ validationMessageConfig?.whitespace || "UI.k_leading_trailing_space_validation" | translate }}
	</small>
	<small class="form-text text-danger" *ngIf="templateRef.errors?.invalidemail">{{
		validationMessageConfig?.appEmailCheck || "UI.k_alert_email_invalid" | translate
	}}</small>
	<small class="form-text text-danger" *ngIf="templateRef?.errors?.minlength">{{
		validationMessageConfig?.minlength | translate
	}}</small>
	<small class="form-text text-danger" *ngIf="templateRef?.errors?.maxlength">{{
		validationMessageConfig?.maxlength | translate
	}}</small>
	<small class="form-text text-danger" *ngIf="templateRef?.errors?.min">{{
		validationMessageConfig?.min | translate
	}}</small>
	<small class="form-text text-danger" *ngIf="templateRef?.errors?.max">{{
		validationMessageConfig?.max | translate
	}}</small>
	<small class="form-text text-danger" *ngIf="templateRef?.errors?.dnsMultiSelectMinLimitBreached">{{
		validationMessageConfig?.min || "Error.err_min_selections" | translate
	}}</small>
	<small class="form-text text-danger" *ngIf="templateRef?.errors?.dnsMultiSelectMaxLimitBreached">{{
		validationMessageConfig?.max || "Error.err_max_selections" | translate
	}}</small>
</span>
