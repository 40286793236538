<div class="email-app-list flex-auto-height">
	<div class="flex-initial border-bottom card-bg position-relative">
		<div class="d-flex">
			<div class="d-inline-flex border-right py-1 pl-1 pr-3">
				<div class="org-image-container border ng-star-inserted">
					<img
						src="{{ orgData.organization_name.profile_image || '' }}"
						class="thumbnail-image"
						alt="{{ orgData.organization_name.name }}"
						width="40"
						height="40"
					/>
				</div>
				<div class="m-1">
					<span class="fw-500">{{ orgData.organization_name.name | translate }}</span>
					<br />
					<span class="primary-color">
						{{ orgData.org_id }}
					</span>
				</div>
			</div>
			<div class="border-right py-1 px-3">
				<span>{{ "UI.k_contact_person_name" | translate }}</span>
				<br />
				<span class="fw-500">{{ orgData.name }}</span>
			</div>
			<div class="border-right py-1 px-3">
				<span>{{ "UI.k_contact" | translate }}</span>
				<br />
				<span class="fw-500">David@infraon.io</span>
				<br />
				<span class="fw-500">+919148391117</span>
			</div>
			<div class="px-3 py-1">
				<span>{{ "UI.k_address" | translate }}</span>
				<br />
				<span class="fw-500">Kochi, Kerala, India</span>
			</div>
		</div>
	</div>
	<div class="flex-initial border-bottom card-bg position-relative">
		<div class="d-flex justify-content-between m-1 mr-5">
			<div>
				<span>{{ "UI.k_next_invoice_issue_date" | translate }}</span><br />
				<span class="fw-500">Jan 22,2025</span>
			</div>
			<div>
				<span>{{ "UI.k_monthly_renewal_date" | translate}}</span><br />
				<span class="fw-500">Jan 22,2025</span>
			</div>
			<div class="d-flex">
				<h2 class="ml-3 fw-500">$500&nbsp;</h2>
				<h6 class="pt-50">/ month</h6>
			</div>
		</div>
	</div>
	<div class="email-user-list flex-auto-height">
		<div class="m-1">
			<div class="row card-row-padding">
				<div class="card border card-margin mb-50" style="width: 100%">
					<div class="border-bottom">
						<div class="row px-1">
							<div class="col-md-3 py-1 border-right">
								<div class="row">
									<ng-container class="m-1">
										<div class="d-inline-flex">
											<div class="mx-1">
												<img
													src="{{ orgData.licence.icon || '' }}"
													class="thumbnail-image"
													alt="{{ orgData.licence.name }}"
													width="50px"
													height="50px"
												/>
											</div>
											<div>
												<span class="fw-500">{{ orgData.licence.name | translate }}</span>
												<br />
												<div class="transparent-primary badge">
													<span class="text-primary reg-font fw-500">{{ orgData.plan }}</span>
												</div>
											</div>
										</div>
									</ng-container>
								</div>
							</div>
							<div class="col-xl-9 my-1">
								<div class="d-grid gap-1 grid-col-3">
									<div class="mb-0">
										<span class="fw=500">{{ "UI.k_user" | translate }}
										</span>
										<div
											class="prograss-container d-flex align-items-center gap-50 border ng-star-inserted progress-bar-container msp-navigation p-1"
										>
											<div class="prograss-bar">
												<ngb-progressbar
													type="{{ orgData?.msp_user?.status }}"
													[max]="orgData?.msp_user?.max_count"
													[value]="orgData?.msp_user?.count"
												></ngb-progressbar>
											</div>
											<div class="prograss-details">
												<span class="fw-500">
													{{ orgData?.msp_user?.count }} /
													{{ orgData?.msp_user?.max_count }} Users</span
												>
											</div>
										</div>
									</div>
									<div class="mb-0">
										<span class="fw=500">{{ "UI.k_it_assets" | translate}}</span>
										<div
											class="prograss-container d-flex align-items-center gap-50 border ng-star-inserted progress-bar-container msp-navigation p-1"
										>
											<div class="prograss-bar">
												<ngb-progressbar
													type="{{ orgData?.msp_it_asset?.status }}"
													[max]="orgData?.msp_it_asset?.max_count"
													[value]="orgData?.msp_it_asset?.count"
												></ngb-progressbar>
											</div>
											<div class="prograss-details">
												<span class="fw-500">
													{{ orgData?.msp_it_asset?.count }} /
													{{ orgData?.msp_it_asset?.max_count }} Asset</span
												>
											</div>
										</div>
									</div>
									<div class="mb-0">
										<span class="fw=500">{{ "UI.k_fixed_assets" | translate }}</span>
										<div
											class="prograss-container d-flex align-items-center gap-50 border ng-star-inserted progress-bar-container msp-navigation p-1"
										>
											<div class="prograss-bar">
												<ngb-progressbar
													type="{{ orgData?.msp_non_it_asset?.status }}"
													[max]="orgData?.msp_non_it_asset?.max_count"
													[value]="orgData?.msp_non_it_asset?.count"
												></ngb-progressbar>
											</div>
											<div class="prograss-details">
												<span class="fw-500">
													{{ orgData?.msp_non_it_asset?.count }} /
													{{ orgData?.msp_non_it_asset?.max_count }} Asset</span
												>
											</div>
										</div>
									</div>
									<div class="mb-0">
										<span class="fw=500">{{ "UI.k_availability_monitoring" | translate}}</span>
										<div
											class="prograss-container d-flex align-items-center gap-50 border ng-star-inserted progress-bar-container msp-navigation p-1"
										>
											<div class="prograss-bar">
												<ngb-progressbar
													type="{{ orgData?.msp_availability_monitoring?.status }}"
													[max]="orgData?.msp_availability_monitoring?.max_count"
													[value]="orgData?.msp_availability_monitoring?.count"
												></ngb-progressbar>
											</div>
											<div class="prograss-details">
												<span class="fw-500">
													{{ orgData?.msp_availability_monitoring?.count }} /
													{{ orgData?.msp_availability_monitoring?.max_count }} Asset</span
												>
											</div>
										</div>
									</div>
									<div class="mb-0">
										<span class="fw=500">{{ "UI.k_network_monitoring" | translate }}</span>
										<div
											class="prograss-container d-flex align-items-center gap-50 border ng-star-inserted progress-bar-container msp-navigation p-1"
										>
											<div class="prograss-bar">
												<ngb-progressbar
													type="{{ orgData?.msp_network_monitoring?.status }}"
													[max]="orgData?.msp_network_monitoring?.max_count"
													[value]="orgData?.msp_network_monitoring?.count"
												></ngb-progressbar>
											</div>
											<div class="prograss-details">
												<span class="fw-500">
													{{ orgData?.msp_network_monitoring?.count }} /
													{{ orgData?.msp_network_monitoring?.max_count }} Asset</span
												>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div>
						<ul ngbNav #navFilled="ngbNav" class="nav nav-pills p-1 msp-navigation border-bottom">
							<li id="tb_plan_details" attr.data-testid="tb_plan_details" [ngbNavItem]="1">
								<a id="link_plan_details" attr.data-testid="link_plan_details" ngbNavLink>
									{{ "UI.k_plan_details" | translate }}
								</a>
								<ng-template ngbNavContent>
									<h5 class="mx-1">{{ "UI.k_feature_list" | translate}}</h5>
									<div class="col-12">
										<div class="d-grid gap-1 grid-col-2 mb-1">
											<ng-container *ngFor="let item of features">
												<div class="d-flex align-items-center mt-1">
													<span class="badge badge-round badge-light-success mr-50">
														<i class="fa-light fa-check"></i>
													</span>
													<span>
														{{ item }}
													</span>
												</div>
											</ng-container>
										</div>
									</div>
								</ng-template>
							</li>
							<li id="tb_customer_users" attr.data-testid="tb_customer_users" [ngbNavItem]="2">
								<a id="link_customer_users" attr.data-testid="link_customer_users" ngbNavLink>
									{{ "UI.k_customer_users" | translate}}
								</a>
								<ng-template ngbNavContent>
									<app-users
                                        [requestFrom]="'msp-view'"
                                    >
                                     </app-users>
								</ng-template>
							</li>
							<li id="tb_assets" attr.data-testid="tb_assets" [ngbNavItem]="3">
								<a id="link_assets" attr.data-testid="link_assets" ngbNavLink
									><i class="fa-light fa-external-link"></i>{{ "UI.k_assets" | translate }}
								</a>
							</li>
						</ul>
						<div [ngbNavOutlet]="navFilled"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>