<div class="slideout-content default-stepping">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <div class="modal-content add-new-user pt-0 pb-0">
                <div class="modal-header">
                    <h4 class="modal-title fw-500" id="exampleModalLabel">
                       {{"UI.k_view"| translate}} {{"UI.k_draft"| translate}} 
                    </h4>
                    <button type="button" id="btnRequesterClose" name="btnRequesterClose" [attr.data-testid]="'btnRequesterClose'"
                        class="btn btn-flat-secondary btn-icon" data-dismiss="modal" aria-label="Close"
                        (click)="hideAnaysisModal()">
                        <i class="fa-light fa-xmark"></i>
                    </button>
                </div>
                <div class="modal-body d-flex flex-column justify-content-between">
                    <ng-scrollbar>
                        <div *ngFor="let root_cause of root_cause_data" class="p-1 m-1 card-body border rounded">
                            <div class="d-flex align-items-center justify-content-between">
                                <div id="divAnalysisHeadline" class="d-flex align-items-center justify-content-start mb-50">
                                    <h4> {{root_cause?.analysis_technique?.display_key | translate}}  </h4>
                                    <div class="badge badge-light-warning ml-50"> 
                                        <span class="sm-font">
                                            {{ 'UI.k_draft' | translate }}
                                        </span>
                                    </div>
                                </div>
                                <div id="divAnalysisButtons" *ngIf="!disable_config" class="d-flex align-items-center justify-content-end">			
                                    <button type="button" (click)="toggleAnalysisSideBar('app-problem-analysis-sidebar-draft', root_cause?.problem_root_cause_id)" class="btn btn-sm btn-icon btn-flat-primary mr-25"
                                        id="btnOnEdit" [attr.data-testid]="'btnOnEdit'" rippleEffect>
                                        <i class="fa-light fa-edit cursor-pointer avatar-icon reg-font"></i>
                                    </button>
                                </div>
                            </div>

                            <div id="divAnalysisContent" class="pb-50">
                                {{root_cause?.root_cause_details | translate}}
                            </div>

                            <div class="px-0 py-50 card-bg d-flex communication-badges"  id="divAnalysisCardChips">
                                <div id="btnAnalyisisName" *ngIf="root_cause?.submitter_profile" class="pull-left badge badge-light-secondary">
                                    <span class="sm-font"> {{root_cause?.submitter_profile?.name}}</span>
                                </div>
                                <div id="btnIncidentEmail" *ngIf="root_cause?.analysis_type?.display_key" class="pull-left ml-50 badge badge-light-secondary">
                                    <span class="sm-font"> {{root_cause?.analysis_type?.display_key | translate}}</span>
                                </div>
                                <div id="btnAnalysisDepartment" *ngIf="root_cause?.category" class="pull-left ml-50 badge badge-light-secondary">
                                    <span class="sm-font">  {{root_cause?.category}}</span>
                                </div>
                                <div id="btnAnalysisDate" *ngIf="root_cause?.formatted_submission_date" class="pull-left ml-50 badge badge-light-secondary">
                                    <span class="sm-font">  {{root_cause?.formatted_submission_date}} </span>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="root_cause_data?.length == 0">
                            <div class="d-flex flex-column py-2 align-items-center">
                                <div class="avatar bg-secondary rounded-lg mb-25" id="BtnincIcons">
                                    <div class="avatar-content" style="width: 28px; height: 28px">
                                        <span><i class="fa-light fa-book-open fa-lg"></i></span>
                                    </div>
                                </div>
                                <span class="sm-font text-muted">{{
                                    "UI.k_no_records_yet" | translate
                                }}</span>
                            </div>
                        </ng-container>

                        <!-- <div *ngFor="let in of [0,1,2,3,4]" class="p-1 m-1 card-body border rounded d-flex align-items-center justify-content-between">
                            <div class="">
                                <div id="divAnalysisHeadline" class="d-flex align-items-center justify-content-start mb-50">
                                    <h4> 5 Why Analysis Technique  </h4>
                                    <div class="badge badge-light-warning ml-50"> 
                                        <span class="sm-font">
                                            {{ 'UI.k_draft' | translate }}
                                        </span>
                                    </div>
                                </div>
    
                                <div id="divAnalysisContent" class="pb-50">
                                    Lorem ipsum dolor sit amet consectetur. Dolor tortor dui dignissim purus metus. Tristique nec sed elit nibh diam cursus odio aliquet. Augue ut sit tristique tellus sit in etiam arc
                                </div>
    
                                <div class="px-0 py-50 card-bg d-flex communication-badges"  id="divAnalysisCardChips">
                                    <div id="btnAnalyisisName" class="pull-left badge badge-light-secondary">
                                        <span class="sm-font"> Priya Kumar</span>
                                    </div>
                                    <div id="btnIncidentEmail" class="pull-left ml-50 badge badge-light-secondary">
                                        <span class="sm-font"> Analysis</span>
                                    </div>
                                    <div id="btnAnalysisDepartment" class="pull-left ml-50 badge badge-light-secondary">
                                        <span class="sm-font">  IT Department</span>
                                    </div>
                                    <div id="btnAnalysisDate" class="pull-left ml-50 badge badge-light-secondary">
                                        <span class="sm-font">  Nov 04, 2022 11:50 AM </span>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </ng-scrollbar>
                    <!-- <div class="stepper-btn d-flex justify-content-end px-1 py-1 border-top">
                        <button type="submit" [disabled]="" class="btn btn-outline-tertiary mr-1" rippleEffect
                            id="btnTechniqueSave" name="btnTechniqueSave">
                            <span class="align-middle d-sm-inline-block d-none">{{ "UI.k_back" | translate }}</span>
                        </button>

                        <button (click)="onSubmit()" type="submit" class="btn btn-primary btn-next" rippleEffect id="btnTechniqueNxt" name="btnTechniqueNxt">
                            <span class="align-middle d-sm-inline-block d-none">{{ "UI.k_save" | translate }} {{"UI.k_draft" | translate }}</span>
                        </button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" overlayClass="modal-backdrop" name="app-problem-analysis-sidebar-draft">
	<app-dns-problem-analysis-sidebar 
		*ngIf="!hideProblemSideBar" 
		[problem_data]="problem_data"
		[options]="options"
		[root_cause_id]="root_cause_id"
		(onHideProfile)="hideAnaysisAddModal($event)"
        [core_sidebar_name]="'app-problem-analysis-sidebar-draft'"
	></app-dns-problem-analysis-sidebar>
</core-sidebar>
