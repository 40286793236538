import { Injectable } from "@angular/core";
import { ApiService } from "app/common/services/api/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class AssetService extends ApiService {
	constructor(httpClient: HttpClient) {
		super(httpClient);
	}

	getAllAssetData() {
		return this.get("ux/common/cmdb/profile/");
	}

	getAssetDetails(id: Number) {
		return this.get("ux/common/cmdb/profile/" + id.toString() + "/");
	}

	getAssetBasicDetails(payload) {
		return this.get("ux/common/cmdb/profile/basicview/", payload);
	}

	getCMDBCis(payload: any) {
		return this.get("ux/common/cmdb/profile/", payload);
	}

	getCMDBOptions(payload: {}) {
		return this.get("ux/common/cmdb/profile/options/", payload);
	}

	saveCMDBCi(payload: any) {
		return this.post("ux/common/cmdb/profile/", payload);
	}
	updateCMDBCi(ci_id, payload: any) {
		return this.put("ux/common/cmdb/profile/" + ci_id + "/", payload);
	}
	getAttachmentDetails(payload = {}) {
		return this.get("ux/common/cmdb/profile/attachment/", payload);
	}

	addAttachments(payload: any) {
		return this.post("ux/common/cmdb/profile/add-attachment/", payload);
	}

	deleteAttachment(attachmentId: any, payload: {}) {
		return this.put("ux/common/cmdb/profile/delete-attachment/" + attachmentId.toString() + "/", payload);
	}

	getHistoryDetails(payload: any) {
		return this.get("ux/common/cmdb/profile/history/", payload);
	}

	getAssetSummary(payload: any) {
		return this.get("ux/common/cmdb/profile/asset-summary/", payload);
	}

	getAssetLifeCycle(payload: any) {
		return this.get("ux/common/cmdb/profile/asset-life-cycle/", payload);
	}

	getInventorySummary(payload: any) {
		return this.get("ux/common/cmdb/profile/inventory-tree-data/", payload);
	}

	getONTSummary(payload: any) {
		return this.get("ux/common/cmdb/profile/ont-info/", payload);
	}

	getServicesSummary(payload: any) {
		return this.get("ux/common/cmdb/profile/gpon-services/", payload);
	}

	getInventoryTreeSummary(payload: any) {
		return this.get("ux/common/cmdb/profile/inventory-tree/", payload);
	}

	getAssetHardware(payload: any) {
		return this.get("ux/common/cmdb/profile/hardware/", payload);
	}

	getResourceList(payload: any) {
		return this.get("ux/common/cmdb/profile/resources/", payload);
	}

	getResourceListOptions(payload: any) {
		return this.get("ux/common/cmdb/profile/resources-list-options/", payload);
	}

	getResourceData(payload: any) {
		return this.get("ux/common/cmdb/profile/resource-data/", payload);
	}

	getStatData(payload: any) {
		return this.get("ux/common/cmdb/profile/resource-stat-data/", payload);
	}

	getAssetOS(payload: any) {
		return this.get("ux/common/cmdb/profile/os-data/", payload);
	}

	getSoftwareAppList(payload: any) {
		return this.get("ux/common/cmdb/profile/software-app-list/", payload);
	}

	getSoftwareEnvList(payload: any) {
		return this.get("ux/common/cmdb/profile/software-env-list/", payload);
	}

	getSoftwareServiceList(payload: any) {
		return this.get("ux/common/cmdb/profile/software-service-list/", payload);
	}

	getSoftwareInvList(payload: any) {
		return this.get("ux/common/cmdb/profile/software-inv-list/", payload);
	}

	getAdapterList(payload: any) {
		return this.get("ux/common/cmdb/profile/adapter-list/", payload);
	}

	getDriverList(payload: any) {
		return this.get("ux/common/cmdb/profile/driver-list/", payload);
	}

	getPortInvList(payload: any) {
		return this.get("ux/common/cmdb/profile/port-inventory/", payload);
	}

	deleteAssets(payload: any) {
		return this.put("ux/common/cmdb/profile/bulk-delete/", payload);
	}

	getEnableDisableAssets(payload: any) {
		return this.put("ux/common/cmdb/profile/enable_disable_bulk/", payload);
	}

	deleteAsset(id) {
		return this.delete("ux/common/cmdb/profile/" + id.toString() + "/");
	}

	assignOwnerShip(payload: any) {
		return this.put("ux/common/cmdb/profile/assign-ownership/", payload);
	}

	assignTag(payload: any) {
		return this.put("ux/common/cmdb/profile/assign-tag/", payload);
	}

	rescanAsset(payload: any) {
		return this.put("ux/common/cmdb/profile/rescan-asset/", payload);
	}
	getFilterOptions(payload: any) {
		return this.get("ux/common/filters/filters/filter_options/", payload);
	}

	getSavedFilter(payload) {
		return this.get("ux/common/filters/filters/filter/", payload);
	}

	getColumns(payload) {
		return this.get("ux/common/cmdb/profile/getcols/", payload);
	}

	processJobs(payload) {
		return this.post("ux/common/cmdb/profile/processJob/", payload);
	}

	getOLTFan(payload) {
		return this.post("ux/common/cmdb/profile/getOLTFan/", payload);
	}

	getONTCCU(payload) {
		return this.post("ux/common/cmdb/profile/getONTCCU/", payload);
	}

	getONTOptical(payload) {
		return this.post("ux/common/cmdb/profile/getONTOptical/", payload);
	}

	getONTAES(payload) {
		return this.post("ux/common/cmdb/profile/getONTAES/", payload);
	}

	getONTThreshold(payload) {
		return this.post("ux/common/cmdb/profile/getONTThreshold/", payload);
	}

	getONTPorts(payload) {
		return this.post("ux/common/cmdb/profile/getONTPorts/", payload);
	}

	fetchLatest(payload) {
		return this.post("ux/common/cmdb/profile/fetchLatest/", payload);
	}

	maskUnmaskAlarms(payload) {
		return this.post("ux/common/cmdb/profile/maskunmask/", payload);
	}

	getONTList(payload) {
		return this.get("ux/common/cmdb/profile/ontList/", payload);
	}

	getServiceList(payload) {
		return this.get("ux/common/cmdb/profile/serviceList/", payload);
	}

	getONTOptions(payload) {
		return this.get("ux/common/cmdb/profile/ontOptions/", payload);
	}

	getServiceOptions(payload) {
		return this.get("ux/common/cmdb/profile/servicetOptions/", payload);
	}

	download_services(payload: {}) {
		return this.get("ux/common/export/export/export_data/", payload);
	}

	getTagList(payload: any) {
		return this.get("ux/common/cmdb/profile/get-tags/", payload);
	}
	editItemInline(payload: {}) {
		return this.post("ux/common/cmdb/profile/editItemInline/", payload);
	}

	getResDetails(payload: {}) {
		return this.post("ux/common/cmdb/profile/getResDetails/", payload);
	}

	saveResource(payload: {}) {
		return this.post("ux/common/cmdb/profile/saveResource/", payload);
	}

	getResDropdownList(payload: {}) {
		return this.get("ux/common/cmdb/profile/getResDropdownList/", payload);
	}

	test_diagnosis(payload: {}) {
		return this.post("ux/common/events/events/test_diagnosis/", payload);
	}

	getAllCategories(payload) {
		return this.get("ux/common/cmdb/category/", payload);
	}

	getCMDBCategory(id, payload = {}) {
		return this.get("ux/common/cmdb/category/" + id + "/", payload);
	}

	saveCMDBCategory(payload: any) {
		return this.post("ux/common/cmdb/category/", payload);
	}

	deleteCMDBCategory(id) {
		return this.delete("ux/common/cmdb/category/" + id.toString() + "/");
	}

	deleteCMDBSubCategory(payload) {
		return this.get("ux/common/cmdb/category/delsubcategory/", payload);
	}

	getCMDBSubCategory(payload) {
		return this.get("ux/common/cmdb/category/editsubcategory/", payload);
	}

	getAllOptions(payload) {
		return this.get("ux/common/cmdb/category/all_options/", payload);
	}
	getSlots(payload: {}) {
		return this.get("ux/common/cmdb/profile/hardware-inv/", payload);
	}

	getGpon(payload: {}) {
		return this.get("ux/common/cmdb/profile/interface-gpon/", payload);
	}

	downloadSampleCSV(payload: any) {
		return this.get("ux/common/cmdb/profile/download_csv/", payload);
	}

	getSelectedColList(payload: any) {
		return this.get("ux/common/cmdb/profile/cmdb_cols/", payload);
	}

	validateUploadedFile(payload: any) {
		return this.post("ux/ims/discovery/profile/validate_csv/", payload);
	}
	getSeletedCMDBColumns(payload: any) {
		return this.post("ux/common/cmdb/profile/category-cols/", payload);
	}
	insertAssets(payload: any) {
		return this.post("ux/common/cmdb/profile/upload-csv/", payload);
	}
	addAssets(payload: any) {
		return this.post("ux/common/cmdb/profile/create-csv/", payload);
	}
	getPrintOptions() {
		return this.get("ux/common/cmdb/profile/print-options/");
	}

	printCMDBLabels(payload: any) {
		return this.post("ux/common/cmdb/profile/print-label/", payload);
	}
	getAllCrossConnection(payload: any) {
		return this.get("ux/common/cmdb/profile/connection-data/", payload);
	}
	getAllTopologyLinks(payload: any) {
		return this.get("ux/common/cmdb/profile/topology-links/", payload);
	}
	getAllPortUtilization(payload: any) {
		return this.get("ux/common/cmdb/profile/port-utilization/", payload);
	}

	checkCMDBExists(payload: any) {
		return this.get("ux/common/cmdb/profile/cmdb-exists/", payload);
	}
	getTickets(payload: any) {
		return this.get("ux/common/events/events/asset_inci_relation/", payload);
	}
	uninstallApplication(payload: any) {
		return this.post("ux/common/cmdb/profile/uninstall-app/", payload);
	}
	closeIncidentOnServiceDel(payload) {
		return this.put("ux/sd/inci/incident/close_inci_on_asset_del/", payload);
	}

	bulkUpdate(payload: any) {
		return this.put("ux/common/cmdb/profile/bulk_update/", payload);
	}

	getDataForPanelView(payload: any) {
		return this.get("ux/common/cmdb/profile/panel-view/", payload);
	}

	gethostData(payload: any) {
		return this.get("ux/common/cmdb/hypervisor/host-data/", payload);
	}
	getVMData(payload: any) {
		return this.get("ux/common/cmdb/hypervisor/guest-vms/", payload);
	}
	getDatastores(payload: any) {
		return this.get("ux/common/cmdb/hypervisor/datastores/", payload);
	}
	getHypervisorPerformanceChart(payload: any) {
		return this.get("ux/common/cmdb/hypervisor/performance-chart/", payload);
	}
	getHypervisorVMDetails(payload: any) {
		return this.get("ux/common/cmdb/hypervisor/guest-vm-details/", payload);
	}
	getHypervisorHostDetails(payload: any) {
		return this.get("ux/common/cmdb/hypervisor/host-details/", payload);
	}
	getHypervisorDatastoreDetails(payload: any) {
		return this.get("ux/common/cmdb/hypervisor/datastore-details/", payload);
	}
	getHypervisorClusterDetails(payload: any) {
		return this.get("ux/common/cmdb/hypervisor/cluster-details/", payload);
	}
	getActivity(payload: any) {}
	getHypervisorClusterOptions(payload: any) {
		return this.get("ux/common/cmdb/hypervisor/cluster-options/", payload);
	}

	getNccmUrl(payload: any) {
		return this.get("ux/common/cmdb/profile/nccm_url/", payload);
	}

	getCLIUrl(payload: any) {
		return this.get("ux/common/cmdb/profile/infraon-cli-url/", payload);
	}

	getTopVMData(payload: any) {
		return this.get("ux/common/cmdb/hypervisor/guest-top-vms/", payload);
	}

	checkCustomFieldExists(payload: any) {
		return this.get("ux/common/cmdb/profile/cust-field-exists/", payload);
	}

	getAssetCategoryProperties(payload) {
		return this.get("ux/common/cmdb/category/properties/", payload);
	}

	resendEmail(payload: any) {
		return this.put("ux/common/cmdb/profile/resend_mail/", payload);
	}

	//Advanced-Resource-Configuration
	getARCList(payload: any) {
		return this.get("ux/common/cmdb/resource-configuration/", payload);
	}

	createARConfiguraion(payload: any) {
		return this.post("ux/common/cmdb/resource-configuration/", payload);
	}

	updateARConfiguration(uid, payload: any) {
		return this.put("ux/common/cmdb/resource-configuration/" + uid + "/", payload);
	}

	getARCDetails(id: Number) {
		return this.get("ux/common/cmdb/resource-configuration/" + id.toString() + "/");
	}

	archiveARC(id) {
		return this.delete("ux/common/cmdb/resource-configuration/" + id.toString() + "/");
	}

	getARCOptions(payload = {}) {
		return this.get("ux/common/cmdb/resource-configuration/options/", payload);
	}

	getFilteredData(payload = {}) {
		return this.get("ux/common/cmdb/resource-configuration/get-data/", payload);
	}

	getFilterOption(payload = {}) {
		return this.get("ux/common/cmdb/resource-configuration/filter-option/", payload);
	}

	togglePolling(payload = {}) {
		return this.put("ux/common/cmdb/resource-configuration/toggle-polling/", payload);
	}

	assignResourceTag(payload: any) {
		return this.put("ux/common/cmdb/resource-configuration/assign-tag/", payload);
	}

	setPollPeriod(payload: any) {
		return this.put("ux/common/cmdb/resource-configuration/set-pollperiod/", payload);
	}
	getCommonFields(payload) {
		return this.get("ux/common/cmdb/common_field/", payload);
	}
	addCommonFields(payload) {
		return this.post("ux/common/cmdb/common_field/", payload);
	}
	getCommonFieldsId(id) {
		return this.get("ux/common/cmdb/common_field/" + id.toString() + "/");
	}
	updateCommonField(payload, id) {
		return this.post("ux/common/cmdb/common_field/" + id.toString() + "/", payload);
	}
	deleteCommonField(id) {
		return this.delete("ux/common/cmdb/common_field/" + id.toString() + "/");
	}
	deleteCommonFields(payload) {
		return this.put("ux/common/cmdb/common_field/delete/", payload);
	}
	getCiRelations(payload = {}) {
		return this.get("ux/common/cmdb/relation/", payload);
	}
	getCiRelationOptions(payload: {}) {
		return this.get("ux/common/cmdb/relation/options/", payload);
	}
	getCategoryTree(payload: {}) {
		return this.get("ux/common/cmdb/category/category-tree/", payload);
	}
	getCIImpactedProps(payload: {}) {
		return this.get("ux/common/cmdb/relation/ci_opt/", payload);
	}
	saveCiRelation(payload: any) {
		return this.post("ux/common/cmdb/relation/", payload);
	}
	deleteCiRelation(id, payload) {
		return this.put("ux/common/cmdb/relation/" + id + "/", payload);
	}
	getImpactedCIs(payload = {}) {
		return this.get("ux/common/cmdb/relation/cisearch/", payload);
	}
	getColumnConfig(payload) {
		return this.get("ux/common/cmdb/profile/column-config/", payload);
	}
	savePageConfig(payload) {
		return this.post("ux/common/cmdb/profile/save-column-config/", payload);
	}
	getCIRelationMap(payload: any) {
		return this.post("ux/common/cmdb/relation/ci-rel-map/", payload);
	}
	getlogoninfo(payload = {}) {
		return this.get("ux/common/cmdb/profile/get-login-info/", payload);
	}

	getConsumableOptions(payload = {}) {
		return this.get("ux/common/cmdb/consumable/options/", payload);
	}

	addConsumableStock = (payload: any) => {
		return this.post("ux/common/cmdb/consumable/", payload);
	};

	getConsumableStocks = (ci_id: string, payload = {}) => {
		return this.get(`ux/common/cmdb/consumable/stocks/${ci_id}/`, payload);
	};

	getConsumableStockByLocation = (ci_id: string, payload = {}) => {
		return this.get(`ux/common/cmdb/consumable/stocks_by_location/${ci_id}/`, payload);
	};

	distributeStock = (payload: any) => {
		return this.post("ux/common/cmdb/consumable/distribute_stock/", payload);
	};

	getConsumableStockUsages = (ci_id: string, payload = {}) => {
		return this.get(`ux/common/cmdb/consumable/stocks_usages/${ci_id}/`, payload);
	};

	getConsumableStockByStatusCount = (ci_id: string, payload = {}) => {
		return this.get(`ux/common/cmdb/consumable/stock_by_status/${ci_id}/`, payload);
	};

	getCategoryCountMap(payload = {}) {
		return this.get("ux/common/cmdb/profile/asset-count/", payload);
	};
	
	doRescan = (payload :any)=> {
		return this.post("ux/common/cmdb/profile/rescan-inv/", payload);
	};
	
	getRemoteConnection(payload :any){
		return this.post("ux/common/cmdb/profile/rdp-asset/",payload)
	};

	scheduleRdpUrl(payload :any){
		return this.post("ux/common/cmdb/profile/rdp-schedule/",payload)
	}
	getControllerStatData(payload: any) {
		return this.get("ux/common/cmdb/profile/controller-stats/", payload);
	}
	
	getAccessPoints(payload: any) {
		return this.get("ux/common/cmdb/profile/access-points/", payload);
	}

	getMarketplaceAppDetails(params: any) {
		return this.get("ux/common/marketplace/marketplace/app_details/", params);
	}
	
	getMarketplaceAPIKey() {
		return this.get("ux/common/marketplace/marketplace/apikey/");
	}
}
