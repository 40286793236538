import { keyframes } from "@angular/animations";
import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, Validators, FormControl } from "@angular/forms";

@Directive({
    selector: '[appPhoneCheck]',
    providers: [{provide: NG_VALIDATORS, useExisting: PhoneValidator, multi: true}]
  })
  export class PhoneValidator implements Validator {
    @Input('appPhoneCheck') invalidphone: '';
  

    validate(control: AbstractControl): ValidationErrors | [] {
        let phoneInput = control.value
        let isValid = true
        let pattern_us = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        // let pattern_us = /^[\+]?[(]?[0-9]{3}[)]?[ ]?[0-9]{3}[-]?[0-9]{4,6}$/im;
        if (typeof phoneInput == 'string'){
            phoneInput = phoneInput.split(',').join(';').split(';')
        }
        if (phoneInput){
            for (let id in phoneInput){
                if (!phoneInput[id].match(pattern_us)){
                   return  { invalidphone: true }
                }
            }
            return isValid ? null : { invalidphone: true };
        }
    }
}

