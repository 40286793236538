import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from "@ngx-translate/core";
import { ProblemService } from 'app/servicedesk/problem/services/problem.service';

@Component({
  selector: 'app-dns-problem-view-draft-sidebar',
  templateUrl: './dns-problem-view-draft-sidebar.component.html',
  styleUrls: ['./dns-problem-view-draft-sidebar.component.scss']
})
export class DnsProblemViewDraftSidebarComponent implements OnInit {
  @Output("onHideProfile") onHideProfile = new EventEmitter<any>();
  @Input() root_cause_data: any;
  @Input() problem_id: any;
  @Input() options: any = {};
  @Input() problem_data: any = {};
  @Input() disable_config: boolean = false;
  root_cause_id: any = "-1";
  hideProblemSideBar : boolean = true;

  constructor(private _coreSidebarService :CoreSidebarService,
    private _toastr: ToastrService,
    private _translateService: TranslateService,
    private _problemService: ProblemService,) { }

  ngOnInit(): void {
    this.getDraftRootCauses()
  }

  hideAnaysisModal(){
    this.onHideProfile.emit("hide");
    this._coreSidebarService.getSidebarRegistry("app-view-draft-sidebar").toggleOpen();
  }

	onSubmit() {
    this.hideAnaysisModal()

    this._toastr.success(
      this._translateService.instant('Draft saved successfully'),
      this._translateService.instant("Message.msg_success"),
      {
        toastClass: "toast ngx-toastr",
        closeButton: true,
        positionClass: "toast-bottom-right",
      }
    );
	};

  getDraftRootCauses() {
    this._problemService.getDraftRootCauses(this.problem_id, {"is_draft_call": true}).subscribe(
      (response) => {
        if (response) {
          this.root_cause_data = response
        }
      },
      (error) => {
        //todo
      }
    ); 
  }

	toggleAnalysisSideBar = (name: string, id: any, type: any) => {
		this.hideProblemSideBar = false
		this.root_cause_id = "-1"
		if (id != undefined){
			this.root_cause_id = id
		}
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
	};

	hideAnaysisAddModal($event) {
		this.hideProblemSideBar = true
    this.getDraftRootCauses()
	};
}
