import {
	Component,
	Input,
	Output,
	OnInit,
	ViewEncapsulation,
	EventEmitter,
	AfterViewInit,
	OnChanges,
	SimpleChanges,
	ChangeDetectorRef,
	DoCheck,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { repeaterAnimation } from "app/common/dns/animations/animations";

const BOOLEAN_OPTIONS = [
	{
		name: "is",
		value: "==",
	},
	{
		name: "is not",
		value: "!=",
	},
];

const TEXT_OPTIONS = [
	{
		name: "Equal",
		value: "==",
	},
	{
		name: "Not Equal",
		value: "!=",
	},
];

@Component({
	selector: "workflow-conditions",
	templateUrl: "./workflow-conditions.component.html",
	styleUrls: ["./workflow-conditions.component.scss"],
	encapsulation: ViewEncapsulation.None,
	animations: [repeaterAnimation],
})
export class WorkflowConditionsComponent implements OnInit, AfterViewInit, OnChanges, DoCheck {
	@Input() conditions: any;
	@Output("output") resultData = new EventEmitter<any>();
	@Input() macros: any;
	@Input() result: Array<any>;
	prevResult: Array<any>;
	conditionsOptions: any = {};
	rowIndex: number = 0;
	@Input("assignment") isShowEqualsBox: boolean;
	@Input("is_add") is_add_button = true;
	@Input("is_relation") isRelation = true;
	optionsMap = {};
	conditionMap = {};

	constructor(private _translateService: TranslateService, private _cdRef: ChangeDetectorRef) {
		this.macros = [];
		this.result = [
			{
				comparison_operator: "==",
			},
		];
		this.conditionsOptions = {
			rowConditions0: {
				options: [],
			},
		};
	}

	ngOnInit(): void {
		this.prevResult = this.result;
	}

	public reset = () => {
		this.result = [
			{
				comparison_operator: "in",
			},
		];
		this.conditionsOptions = {
			rowConditions0: {
				options: [],
			},
		};
		this.prepareOptionsMap();
	};

	ngAfterViewInit() {
		this.prepareOptionsMap();
	}

	getConditions = (index) => {
		if (this.conditions && index > 0) {
			let tempCondMaros = [];
			for (const resultElement of this.result) {
				if (resultElement?.operand) {
					tempCondMaros.push(resultElement?.operand);
				}
			}
			const tempCond = this.conditions.filter((x) => !tempCondMaros.includes(x?.macro));
			return tempCond;
		}
		return this.conditions;
	};

	getBooleanOptions() {
		return BOOLEAN_OPTIONS;
	}

	ngDoCheck() {
		if (this.prevResult !== this.result) {
			this._cdRef.detectChanges();
		}
	}

	ngOnChanges = (changes: SimpleChanges) => {
		if (changes) {
			this.prepareOptionsMap();
		}
	};

	updateConditions = () => {
		if (this.result) {
			let tempCond = [];
			for (const resultElement of this.result) {
				if (resultElement?.operand) {
					this.conditionMap[resultElement?.operand] = this.conditions.filter(
						(x) => !tempCond.includes(x?.macro)
					);
				}
			}
		}
	};

	getTextOptions() {
		return TEXT_OPTIONS;
	}

	prepareOperandOptions = () => {
		if (this.result && this.result?.length > 0) {
			for (let i = 0; i < this.result.length; i++) {
				const condIndex = this.conditions.findIndex((x) => x.macro === this.result[i]["operand"]);
				if (condIndex !== -1) {
					const option_data = this.conditions[condIndex];
					if (!this.conditionsOptions["rowConditions" + i]) {
						this.conditionsOptions["rowConditions" + i] = {};
					}
					this.conditionsOptions["rowConditions" + i].options = option_data?.options || [];
					this.conditionsOptions["rowConditions" + i].bindKey = option_data?.bindKey || "name";
					this.conditionsOptions["rowConditions" + i].bindValue = option_data?.bindValue || "id";
					this.conditionsOptions["rowConditions" + i].dropdownPos = option_data?.dropdownPos || "bottom";
				}
			}
		}
	};

	onChange = (args: any, index: any, callFrom = "", currentRef) => {
		if (args) {
			const newArgs = this.conditions.find((res) => res?.macro == args);
			if (newArgs?.type === "list") {
				if (!this.conditionsOptions["rowConditions" + index]) {
					this.conditionsOptions["rowConditions" + index] = {};
				}
				this.conditionsOptions["rowConditions" + index].options = this.transformOptions(newArgs.options) || [];
				this.conditionsOptions["rowConditions" + index].is_multiple = newArgs?.is_multiple || false;
				this.conditionsOptions["rowConditions" + index].bindKey = newArgs?.bindKey;
				this.conditionsOptions["rowConditions" + index].bindValue = newArgs?.bindValue;
				this.conditionsOptions["rowConditions" + index].displayKey = newArgs?.displayKey;
				this.conditionsOptions["rowConditions" + index].dropdownPos = newArgs?.dropdownPos || "bottom";
				this.result[index].value = null;
			}
		}
		if (args?.name && callFrom !== "logical_operator") {
			this.result[index]["name"] = args?.name || "";
			if (callFrom === "value") {
				const bindValue = this.conditionsOptions["rowConditions" + index].bindValue;
				//const newArgs = this.optionsMap[this.result[index]?.operand].find((res) => res?.name == args.name);
				const newArgs = this.conditionsOptions["rowConditions" + index].options?.find(
					(res) => res?.name == args.name
				);
				let tempResult = this.result[index];
				if (tempResult) {
					if (bindValue) {
						tempResult["value"] = newArgs[bindValue];
					} else {
						tempResult["value"] = newArgs?.id;
					}
				}
			}
		}

		this.resultData.emit(this.result);
		this._cdRef.detectChanges();
	};

	transformOptions(options: any[]) {
		let newOptions = [];
		for (const idx in options) {
			newOptions.push(options[idx]);
			if ("children" in options[idx] && options[idx]["children"].length > 0) {
				for (const _id in options[idx]["children"]) newOptions.push(options[idx]["children"][_id]);
			}
		}
		return newOptions;
	}

	addRemove = (i: number) => {
		if (i == 0) {
			this.result.push({ operand: null });
			this.rowIndex++;
			this.conditionsOptions["rowConditions" + this.rowIndex] = [];
			//this.conditionMap[""] = this.getConditions(i + 1);
		} else {
			this.rowIndex--;
			if (this.result.length == i + 1) {
				this.result[i - 1]["logical_operator"] = {};
			}
			this.result.splice(i, 1);
		}
	};

	setCondition(data) {
		//this.result = data;
	}

	public findDuplicate = () => {
		let isDuplicate = false;
		if (this?.result && this.result.length > 0) {
			for (const resultElement of this.result) {
			}
		}
	};

	public validateWfCondition(): string {
		let error_msg = "";
		let index = 0;
		let conditionLength = this?.result?.length;
		if (this?.result?.length > 0) {
			for (const cond of this?.result) {
				if (
					cond.operand &&
					cond.value &&
					(conditionLength > 1
						? index != conditionLength - 1
							? cond.logical_operator
								? true
								: false
							: true
						: true)
				) {
					index++;
				} else {
					error_msg = this._translateService.instant("UI.k_please_select_all");
					break;
				}
			}
		} else {
			error_msg = this._translateService.instant("UI.k_select_conditon_mandatory");
		}
		return error_msg;
	}

	prepareOptionsMap = () => {
		if (this.conditions) {
			for (const condition of this.conditions) {
				if (condition?.type === "list") {
					this.optionsMap[condition?.macro] = condition?.options || [];
				}
			}
			this.prepareOperandOptions();
		}
	};
}
