import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from "@angular/core";
import {CoreSidebarService} from "@core/components/core-sidebar/core-sidebar.service";
import Stepper from "bs-stepper";
import {FileUploader} from "ng2-file-upload";
import {catchError, map} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {default as _map} from "lodash-es/map";
import includes from "lodash-es/includes";
import {getFileSizeText} from "app/common/utils/utils";
import { throwError } from "rxjs";
import { KBService } from "app/base/knowledge-base/services/kb.service";

const URL = "https://your-url.com";
const STATUS_MAP = {
2: [2, 3, 1],
1: [1, 3, 4],
3: [3, 1],
0: [2, 1],
4: [3, 1, 4],
};

@Component({
  selector: 'app-dns-kb-add',
  templateUrl: './dns-kb-add.component.html',
  styleUrls: ['./dns-kb-add.component.scss']
})
export class DnsKbAddComponent implements OnInit {
	@Output("onHideKBProfile") onHideKBProfile = new EventEmitter<any>();
	@Input() event: any;
	@Input() item: string;
	@Input() kbSolution: any;
	@Input() isExternalCall: boolean = false;
	@Input() refId: string;
	@Output("getKBList") getKBList = new EventEmitter<any>();
	public tags;
	public customTag: any[] = [];
	public uploader: FileUploader = new FileUploader({
		url: URL,
		isHTML5: true,
	});
	private modernWizardStepper: Stepper;
	private bsStepper;
	user_type;

	public kb: any = {};
	@Input() public isFromServiceCatalogue: boolean = false;
	@Input() public serviceId: string = "";
	// public file: any;
	// ALLOWED_TYPES: Array<any>;
	// public MAX_IMAGE_SIZE;
	// public MAX_HEIGHT;
	// public MAX_WIDTH;
	public showAccessError = false;

	public imgDetailsObject = {
		aspectRatio: "4 / 1",
		resizeToWidth: "128",
		cropperMinWidth: "128",
		maintainAspectRatio: true,
	};

	public roundedAvatar = false;
	public showQueue = false;
	public multipleFiles = false;
	public displayResoluion = true;
	public ALLOWED_TYPES = ["png", "jpeg", "jpg"];
	public MAX_IMAGE_SIZE = 20971520;
	public MAX_HEIGHT = 400;
	public MAX_WIDTH = 200;
	public imageBase64: string;
	public file: any;
	statusList = [];
	// public customHW = "width:800px;height:113px";
	public imageType = "KB";
	qlEditorData = {};
	langMap = {
		article: this._translateService.instant("UI.k_article"),
		faq: this._translateService.instant("UI.k_faq"),
		information: this._translateService.instant("UI.k_information"),
		known_error: this._translateService.instant("UI.k_known_error"),
		solution: this._translateService.instant("UI.k_solution"),
	};

	imageMap = {
		article: "assets/images/illustration/personalization.svg",
		faq: "assets/images/illustration/marketing.svg",
		information: "assets/images/illustration/api.svg",
		known_error: "assets/images/illustration/demand.svg",
		solution: "assets/images/illustration/email.svg",
	};
	public is_save_disabled = false;

	public options;

	@Output() addedKBID = new EventEmitter<object>();

	/**
	 * Submit
	 *
	 * @param form
	 */
	onSave = (data) => {
		if (data.form.valid === true) {
			this.saveKB();
		}
	};

	/**
	 * Constructor
	 *
	 * @param {NgbModal} modalService
	 */
	constructor(
		private _coreSidebarService: CoreSidebarService,
		private _kbService: KBService,
		private _toastr: ToastrService,
		private _translateService: TranslateService
	) {}

	ngOnInit(): void {
		// Default Initial Values for KB Add
		this.kb["is_private"] = false;
		this.kb["is_security"] = false;
		this.kb["is_disabled_update"] = false;
		this.kb["state"] = 2; // Value corresponding to active
		this.kb["status"] = 5; // Value corresponding to new
		if (this.isExternalCall) {
			this.kb["catalogue_service"] = this.kbSolution["catalogue_service"];
			this.kb["title"] = this.kbSolution["title"];
			this.kb["content"] = this.kbSolution["content"];
			this.kb["kbtype"] = this.kbSolution["kbtype"];
			this.kb["keywords"] = this.kbSolution["keywords"];
			this.kb["solutions"] = this.kbSolution["solutions"];
			this.kb["known_error"] = this.kbSolution["known_error"];
		}
		this.modernWizardStepper = new Stepper(document.querySelector("#stepper3"), {
			linear: true,
			animation: true,
		});

		this.bsStepper = document.querySelectorAll(".bs-stepper");
		// Getting the options
		this._kbService
			.getOptionsData()
			.pipe(
				map((response: any) => {
					this.options = response;
					// setting default state & status
					if (response?.default_state && response?.default_status) {
						this.kb["state"] = response?.default_state;
						this.kb["status"] = response?.default_status;
					}
					if (response?.status && response?.status?.length > 0) {
						this.statusList = response?.status.filter((item) => {
							if (STATUS_MAP[0].includes(item["id"])) {
								return item;
							}
						});
					}
					const imageDefaults = response["image_upload_defaults"];
					this.ALLOWED_TYPES = _map(imageDefaults["allowed_types"], (val) =>
						val.includes(".") ? val : "." + val
					);
					this.MAX_IMAGE_SIZE = imageDefaults["max_size"];
					this.MAX_HEIGHT = imageDefaults["max_height"];
					this.MAX_WIDTH = imageDefaults["max_width"];
					// console.log("this.options : ", this.options)
				})
			)
			.subscribe();

		// console.log("this.kb : ", this.kb)
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.item) {
			this.kb["kbtype"] = changes.item.currentValue.toLowerCase();
			this.kb["api_link_data"] = [{ external_url: "", name: "" }];
			if (changes.item.currentValue === "article" || changes.item.currentValue === "information") {
				this.kb["content"] = "";
			} else if (changes.item.currentValue === "faq") {
				this.kb["faqs"] = [{ question: "", answer: "" }];
			} else if (changes.item.currentValue === "solution") {
				this.kb["solutions"] = [{ root_cause: "", solution: "" }];
			} else {
				this.kb["known_error"] = {
					root_cause: "",
					error_detail: "",
					workaround: "",
				};
			}
		}
	}

	addQuestion() {
		this.kb.faqs.push({
			question: "",
			answer: "",
		});
	}

	addSolution() {
		this.kb.solutions.push({
			root_cause: "",
			solution: "",
		});
	}

	/**
	 * Select Custom Tag
	 *
	 * @param name
	 */
	addKeyword(name) {
		return { name: name, tag: true };
	}

	/**
	 * DeleteItem
	 *
	 * @param id
	 */
	deleteQuestion(id) {
		for (let i = 0; i < this.kb.faqs.length; i++) {
			if (this.kb.faqs.indexOf(this.kb.faqs[i]) === id) {
				this.kb.faqs.splice(i, 1);
				break;
			}
		}
	}

	deleteSolution(id) {
		for (let i = 0; i < this.kb.solutions.length; i++) {
			if (this.kb.solutions.indexOf(this.kb.solutions[i]) === id) {
				this.kb.solutions.splice(i, 1);
				break;
			}
		}
	}

	addLink() {
		this.kb.api_link_data.push({
			name: "",
			external_url: "",
		});
	}

	/**
	 * DeleteItem
	 *
	 * @param id
	 */
	deleteLink(id) {
		for (let i = 0; i < this.kb.api_link_data.length; i++) {
			if (this.kb.api_link_data.indexOf(this.kb.api_link_data[i]) === id) {
				this.kb.api_link_data.splice(i, 1);
				break;
			}
		}
	}

	/**
	 * Modern Horizontal Wizard Stepper Next
	 */
	modernHorizontalNext(data) {
		if (data.form.valid === true) {
			this.modernWizardStepper.next();
		}
	}

	/**
	 * Modern Horizontal Wizard Stepper Previous
	 */
	modernHorizontalPrevious() {
		this.modernWizardStepper.previous();
	}

	changeKBVisibility(args) {
		if (args.target.value == "private") {
			this.kb.is_private = true;
		} else {
			this.kb.is_private = false;
			this.kb["users"] = [];
			this.kb["teams"] = [];
			this.kb["user_tags"] = [];
			this.kb["group_tags"] = [];
			this.is_save_disabled = false;
		}
	}

	toggleSidebar = (name) => {
		this.event = "";
		//console.log(222222222222)
		this.onHideKBProfile.emit();
		// this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
	}

	fileChanged(files: any): void {
		const temp = files.target.files;
		if (temp.length > 0) {
			this.file = temp[0];
			if (this.file && this.file["size"] > this.MAX_IMAGE_SIZE) {
				this._toastr.warning(
					this._translateService.instant("Message.msg_max_size") + " " + getFileSizeText(this.MAX_IMAGE_SIZE),
					this._translateService.instant("Error.err_upload_fail"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
			}

			if (!includes(this.ALLOWED_TYPES, this.file["type"])) {
				this._toastr.warning(
					this._translateService.instant("Message.msg_image_allowed"),
					this._translateService.instant("Error.err_upload_fail"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
			}
			const reader = new FileReader();
			// attach event, that will be fired, when read is end
			reader.onload = (e: any) => {
				const image = new Image();
				image.src = e.target.result;
				image.onload = (rs) => {
					const img_height = rs.currentTarget["height"];
					const img_width = rs.currentTarget["width"];
					if (img_height > this.MAX_HEIGHT && img_width > this.MAX_WIDTH) {
						this._toastr.warning(
							this._translateService.instant("Message.msg_max_dimension") +
								this.MAX_HEIGHT +
								"*" +
								this.MAX_WIDTH +
								"px",
							this._translateService.instant("Error.err_upload_fail"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
					} else {
						const imgBase64Path = e.target.result;
						this.kb.image = imgBase64Path;
						// this.kb.file_type = this.file["type"];
						// this.previewImagePath = imgBase64Path;
					}
				};
			};

			// start reading a loaded file
			reader.readAsDataURL(this.file);
		}
	}

	saveKB = (): void => {
		// Check for Access data if  private is selected
		if (this.kb.is_private) {
			if (
				(this.kb?.users === undefined &&
					this.kb?.teams === undefined &&
					this.kb?.user_tags === undefined &&
					this.kb?.group_tags === undefined) ||
				(this.kb?.users?.length === 0 &&
					this.kb?.teams?.length === 0 &&
					this.kb?.user_tags?.length === 0 &&
					this.kb?.group_tags?.length === 0)
			) {
				this.showAccessError = true;
				// this.is_save_disabled = true;
				return;
			} else {
				this.showAccessError = false;
				this.is_save_disabled = false;
			}
		}
		this.is_save_disabled = true;
		// console.log("kb : ", this.kb, this.isFromServiceCatalogue, this.serviceId)
		if (this.isFromServiceCatalogue) {
			this.kb.catalogue_service = this.serviceId;
		}

		if (this.refId != undefined){
			this.kb["ref_id"] = this.refId
		}
		this._kbService
			.saveKB(this.kb)
			.pipe(
				map((response: any) => {
					// console.log("response : ", response);
					if (response) {
						if (response["status"] == "success") {
							this.getKBList.emit();
							this.onHideKBProfile.emit();
							this.addedKBID.emit(response?.data);
							// this._coreSidebarService.getSidebarRegistry("add-requester-profile").toggleOpen();
							this.modernWizardStepper.reset();
							// setTimeout(() => form.resetForm(), 200);
							this._toastr.success(
								this._translateService.instant(response["message"]),
								this._translateService.instant("Message.msg_save_success"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
						} else {
							let error_msg = "";
							for (const err_msg of Object.values(this._translateService.instant(response["message"]))) {
								error_msg += err_msg + "<br/>";
							}
							this._toastr.error(error_msg, this._translateService.instant("Error.err_save_failed"), {
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
								enableHtml: true,
							});
						}
						this.is_save_disabled = false;
					}
					// Success
				}),
				catchError((error) => {
					this.is_save_disabled = false;
					this._toastr.error(
						this._translateService.instant("Error.err_kb_save_failed"),
						this._translateService.instant("Error.err_save_failed"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					//   this.notification.error('Failed User Creation', 'Something went wrong')
					return throwError(error.statusText);
				})
			)
			.subscribe();
	};

	imageDetails = ($event) => {
		this.imageBase64 = $event;
		// this.imageDetail = true;
		// this.change = true;
		this.kb.image = this.imageBase64;
		this.kb["new_image"] = true;
	};

	removeImage = ($event) => {
		this.kb.image = "";
		this.imageBase64 = null;
		// this.showPreview = false;
		this.kb["new_image"] = false;
	};

	onStatusChange = (data) => {
		this.kb["state"] = data.state_id;
		this.kb["status"] = data.id;
	};

	customSearchFn(term: string, item: any) {term = term.toLowerCase();
		term = term.toLowerCase();
		// Creating and array of space saperated term and removinf the empty values using filter
		const splitTerm = term.split(" ").filter((t) => t);
		let isWordThere = [];
		// Pushing True/False if match is found
		splitTerm.forEach((arr_term) => {
			const searchStatus = item['full_name']?.toLowerCase();
			const searchState = item['email']?.toLowerCase() || "";
			isWordThere.push(searchStatus.indexOf(arr_term) !== -1 || searchState.indexOf(arr_term) !== -1);
		});
		const all_words = (this_word) => this_word;
		// Every method will return true if all values are true in isWordThere.
		return isWordThere.every(all_words);
    }
}
