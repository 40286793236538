import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { GlobalConstants } from "app/app.globalConstants";
import { GridNameAvatarComponent } from "app/common/dns/components/grid-name-avatar/grid-name-avatar.component";
import { InfinityModules } from "app/common/dns/types/modules";
import { InstanceIdGroup, Instance, InstanceData, ChannelChangePayload, ProductPrice } from "../../models";
import { AdminService, ChannelService, ComputeService } from "../../service";

@Component({
	selector: "app-dashboard-instance-products",
	templateUrl: "./dashboard-instance-products.component.html",
	styleUrls: ["./dashboard-instance-products.component.scss"],
})
export class DashboardInstanceProductsComponent implements OnInit {
	productOptions = [];
	@Input() instanceInfo: Instance;
	private _instanceData: InstanceIdGroup;
	@Input() set instanceData(value: InstanceIdGroup) {
		this._instanceData = value;
		this.setProductOptions(value);
	}
	get instanceData(): InstanceIdGroup {
		return this._instanceData;
	}
	selectedChangeData: InstanceData;

	columns: Array<any>;
	public ColumnMode = ColumnMode;
	public basicSelectedOption: number = 10;
	public gponSniConfigData: any;
	pageNumber: any = 1;
	sortKey: any = "display_id";
	reverse: any = "";
	limit: any = 10;
	totalCount: any;
	permissions: any;
	SelectionType = SelectionType;
	module = InfinityModules.CMDB;
	public users: any;

	public otherProducts = [
		{
			productTitle: "Infraon Uptime",
			info: "Asset Management Solution",
			productImg: "assets/images/products/product-helpdesk2.svg",
		},
		{
			productTitle: "Infraon Uptime",
			info: "Asset Management Solution",
			productImg: "assets/images/products/product-helpdesk2.svg",
		},
		{
			productTitle: "Infraon Uptime",
			info: "Asset Management Solution",
			productImg: "assets/images/products/product-helpdesk2.svg",
		},
		{
			productTitle: "Infraon Uptime",
			info: "Asset Management Solution",
			productImg: "assets/images/products/product-helpdesk2.svg",
		},
	];

	constructor(
		private _translateService: TranslateService,
		private _modalService: NgbModal,
		private _service: AdminService,
		private _channel: ChannelService,
		private _compute: ComputeService
	) {
		// console.log("c.....instanceData", this.instanceData);
	}

	ngOnInit(): void {
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}

		this.permissions = user_permissions[this.module];

		this.users = [
			{
				id: 1,
				status: "active",
				avatar: "assets/images/portrait/small/avatar-s-11.jpg",
				email: "roshan@gmail.com",
				name: "Roshan ashraf",
				last_active: "May 22, 2022 09:29 AM",
			},
			{
				id: 2,
				status: "invited",
				avatar: null,
				email: "sandy@gmail.com",
				name: null,
				last_active: null,
			},
			{
				id: 3,
				status: "active",
				avatar: null,
				email: "roshan@gmail.com",
				name: "Theresa Webb",
				last_active: "May 22, 2022 09:29 AM",
			},
			{
				id: 4,
				status: "active",
				avatar: null,
				email: "wadewarrenww@gmail.com",
				name: "Wade Warren",
				last_active: "May 22, 2022 09:29 AM",
			},
		];
		this.totalCount = this.users.length;

		this.columns = [
			{
				label: this._translateService.instant("UI.k_username"),
				key: "name",
				width: 200,
				component: GridNameAvatarComponent,
			},
			{
				label: this._translateService.instant("UI.k_email"),
				key: "email",
			},

			{
				label: this._translateService.instant("UI.k_status"),
				key: "status",
			},
			{
				label: this._translateService.instant("UI.k_last_active"),
				key: "last_active",
			},
			{
				label: this._translateService.instant("UI.k_action"),
				key: "action",
				actions: [
					{
						label: this._translateService.instant("UI.k_edit"),
						icon: "fa-pen-to-square",
						key: "edit",
						class: "text-primary",
						listener: () => {},
					},
					{
						label: this._translateService.instant("UI.k_delete"),
						icon: "fa-trash-can",
						key: "delete",
						class: "text-danger mr-50",
						listener: () => {},
					},
				],
			},
		];
	}

	editUser = (args: any): void => {};

	deleteUser = (row: any) => {};

	onSelectRow(data: any) {}

	setPage(pageInfo: any) {
		this.pageNumber = pageInfo.offset;
	}

	onSort(args: any): void {
		if (args.sorts) {
			this.pageNumber = 0;
			let sortKey = args.sorts[0].prop;
			this.sortKey = sortKey;
			if (args.sorts[0].dir == "desc") {
				this.reverse = "-";
			} else {
				this.reverse = "";
			}
		}
	}

	setLimit(args: any): void {
		this.pageNumber = 0;
		this.limit = args.target.value;
	}

	toggleProductTabs = (idx: any, handle: any) => {
		if (this.productOptions.length == 0) this.setProductOptions(this.instanceData);
		this.productOptions[idx].activeTab = handle;
	};

	setProductOptions(data: InstanceIdGroup) {
		if (data?.data) {
			data.data.forEach(() => {
				this.productOptions.push({ isCollapsed: true, activeTab: "plan" });
			});
		}
	}

	togglePublishModal = (modalPreview: any, data: InstanceData) => {
		const modalRef = this._modalService.open(modalPreview, {
			centered: true,
			size: "xl", // size: 'xs' | 'sm' | 'lg' | 'xl',
			modalDialogClass: "pricing-plan-modal",
		});
		this.selectedChangeData = data;
		let unitData = this._compute.unitsData(data.product?.unit_of_measure);
		let payload = {
			data: {
				product: data.product,
				productItemId: data.item.id,
				pricingType: data.subscription.billing_period_unit,
				unitData: unitData,
			},
			extras: {
				tag: "product_instance",
			},
		};
		// console.log("......upgrading the price model", payload);
		this._channel.pushToChannel(<ChannelChangePayload>payload);
	};

	callbackAfterChange(payload: any) {
		this._modalService.dismissAll();
		// console.log(".....after change", payload, this.selectedChangeData);
		if (this.selectedChangeData.item.id !== payload.data.item.id) {
			let newChangeData = { ...this.selectedChangeData, ...payload.data };
			//console.log("after change....newChangeData - ", newChangeData);
			// Calling again the api on plan change
			for (let i = 0; i < this.instanceData?.data.length; i++) {
				if (this.selectedChangeData.item.id === this.instanceData.data[i].item.id) {
					// TODO - need to update the subscription deatil also
					// to match changed price as well
					this.instanceData.data[i] = newChangeData;
				}
			}
		}
	}

	instanceTotalPrice(): number {
		let sum = 0;
		let data = this.instanceData?.data || [];
		if (data) {
			for (let i = 0; i < data.length; i++) {
				data[i].subscription.subscription_items.forEach((x) => {
					if (data[i].item_price.id === x.item_price_id) sum += x.amount;
				});
			}
		}
		if (sum) sum = sum / 100;
		return sum;
	}

	getItemPrice(data: InstanceData): number {
		let sum = 0;
		data.subscription.subscription_items.forEach((x) => {
			if (data.item_price.id === x.item_price_id) sum += x.amount;
		});
		if (sum) sum /= 100;
		return sum;
	}

	checkTrial(): boolean {
		return this.instanceInfo?.domain_type !== 1;
	}

	nextInvoiceDate(): any {
		let date = 0;
		let data = this.instanceData?.data || [];
		if (data) {
			for (let i = 0; i < data.length; i++) {
				if (date === 0) {
					if (this.checkTrial()) date = data[i].subscription.trial_end;
					else date = data[i].subscription.current_term_end;
				} else {
					break;
				}
			}
		}
		return date * 1000;
	}

	nextRenewalDate(): any {
		let date = 0;
		let data = this.instanceData?.data || [];
		if (data) {
			for (let i = 0; i < data.length; i++) {
				if (date === 0) {
					if (this.checkTrial()) date = data[i].subscription.trial_end;
					else date = data[i].subscription.current_term_end;
				} else {
					break;
				}
			}
		}
		return date * 1000;
	}

	instanceBillingUnit(): string {
		let unit = "";
		let data = this.instanceData?.data || [];
		for (let i = 0; i < data.length; i++) {
			unit = data[i].subscription.billing_period_unit;
			if (unit) {
				break;
			}
		}
		return unit;
	}

	instanceCurrency(): string {
		// TODO - handling need to taken care
		let curr = "";
		let data = this.instanceData?.data || [];
		for (let i = 0; i < data.length; i++) {
			curr = data[i].customer.preferred_currency_code;
			if (curr) {
				break;
			}
		}
		return curr;
	}
}
