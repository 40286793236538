<div id="container" #container>
	<ng-container *ngIf="showSkeleton">
		<app-workflow-skeleton class="d-grid full-height"></app-workflow-skeleton>
	</ng-container>
	<div id="leftcard" class="leftcard-collapsed" [ngClass]="{ 'd-none': showSkeleton }">
		<div id="closecard" (click)="onCloseLeftMenu($event)">
			<img src="../../assets/images/workflow/closeleft.svg" />
		</div>
		<div>
			<div class="p-1">
				<h6 class="mb-1">{{ "UI.k_workflow_item" | translate }}</h6>
				<div id="search">
					<img src="../assets/images/workflow/search.svg" />
					<input
						type="text"
						[(ngModel)]="searchText"
						placeholder="{{ 'UI.k_search_block' | translate }}"
						class="form-control"
						(keyup)="onSearchWorkflowItem($event)"
					/>
				</div>
			</div>
			<ul ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="onNavChange($event)">
				<li [ngbNavItem]="'state'">
					<a ngbNavLink>
						<!-- <span [data-feather]="'activity'"></span>  -->
						<span><i class="fa-light fa-wave-pulse"></i></span>
						{{ "UI.k_state" | translate }}</a
					>
					<ng-template ngbNavContent>
						<ng-scrollbar class="custom-scrollbar section-scrollbar">
							<ng-container *ngFor="let item of elementData['state']">
								<ng-container *ngIf="!item?.excluded_module.includes(workflowData?.module_id)">
									<div
										class="blockelem create-flowy noselect"
										*appDnsLicenseHandler="item?.lav_obj || {}"
									>
										<input
											type="hidden"
											name="blockelemtype"
											class="blockelemtype"
											[value]="item.type"
											[attr.data-extra]="item | json"
										/>
										<input
											type="hidden"
											name="blockelemtemplatetype"
											class="blockelemtemplatetype"
											[value]="item.templateType"
										/>
										<div class="grabme">
											<img src="../assets/images/workflow/grabme.svg" />
										</div>
										<div class="blockin">
											<div class="blockico">
												<span [data-feather]="item.icon"> </span>
											</div>
											<div class="blocktext">
												<p class="blocktitle title-color">{{ item.title | translate }}</p>
												<span class="blockdesc sm-font">{{
													item.description | translate
												}}</span>
											</div>
										</div>
									</div>
								</ng-container>
							</ng-container>
						</ng-scrollbar>
					</ng-template>
				</li>
				<li [ngbNavItem]="'actions'">
					<a ngbNavLink>
						<!-- <span [data-feather]="'play'"></span> -->
						<span><i class="fa-light fa-play"></i></span>
						{{ "UI.k_actions" | translate }}</a
					>
					<ng-template ngbNavContent>
						<ng-scrollbar class="custom-scrollbar section-scrollbar">
							<ng-container *ngFor="let item of elementData['actions']">
								<ng-container *ngIf="!item?.excluded_module.includes(workflowData?.module_id)">
									<div
										*appDnsLicenseHandler="item?.lav_obj || {}"
										class="blockelem create-flowy noselect"
									>
										<input
											type="hidden"
											name="blockelemtype"
											class="blockelemtype"
											[value]="item.type"
											[attr.data-extra]="item | json"
										/>
										<input
											type="hidden"
											name="blockelemtemplatetype"
											class="blockelemtemplatetype"
											[value]="item.templateType"
											[attr.data-extra]="item | json"
										/>
										<div class="grabme">
											<img src="../assets/images/workflow/grabme.svg" />
										</div>
										<div class="blockin">
											<div class="blockico">
												<span [data-feather]="item.icon"> </span>
											</div>
											<div class="blocktext">
												<p class="blocktitle title-color">{{ item.title | translate }}</p>
												<span class="blockdesc sm-font">{{
													item.description | translate
												}}</span>
											</div>
										</div>
									</div>
								</ng-container>
							</ng-container>
						</ng-scrollbar>
					</ng-template>
				</li>
				<li [ngbNavItem]="'conditions'">
					<a ngbNavLink>
						<!-- <span [data-feather]="'repeat'"></span> -->
						<span><i class="fa-light fa-repeat"></i></span>
						{{ "UI.k_conditions" | translate }}</a
					>
					<ng-template ngbNavContent>
						<ng-scrollbar class="custom-scrollbar section-scrollbar">
							<ng-container *ngFor="let item of elementData['conditions']">
								<div
									class="blockelem create-flowy noselect"
									*appDnsLicenseHandler="item?.lav_obj || {}"
								>
									<input
										type="hidden"
										name="blockelemtype"
										class="blockelemtype"
										[value]="item.type"
										[attr.data-extra]="item | json"
									/>
									<input
										type="hidden"
										name="blockelemtemplatetype"
										class="blockelemtemplatetype"
										[value]="item.templateType"
										[attr.data-extra]="item | json"
									/>
									<div class="grabme">
										<img src="../assets/images/workflow/grabme.svg" />
									</div>
									<div class="blockin">
										<div class="blockico">
											<span [data-feather]="item.icon"></span>
										</div>
										<div class="blocktext">
											<p class="blocktitle title-color">{{ item.title | translate }}</p>
											<span class="blockdesc sm-font">{{ item.description | translate }}</span>
										</div>
									</div>
								</div>
							</ng-container>
						</ng-scrollbar>
					</ng-template>
				</li>
			</ul>
		</div>
		<div class="flex-auto-height">
			<div [ngbNavOutlet]="nav" class="full-height"></div>
		</div>
	</div>
	<div
		id="canvas"
		[ngClass]="{ 'd-none': showSkeleton }"
		class="browser-scrollbar workflow-canvas"
		#canvas
		(click)="onBlockClick($event)"
	></div>
</div>
<dns-macro-selector
	class="macros-container"
	[macros]="macroData"
	*ngIf="isMacro"
	(listener)="onMacroSelected($event)"
></dns-macro-selector>
<dns-sidebar
	sidebarName="{{ rightPanelName }}"
	sidebarSize="medium"
	(close)="onClose($event)"
	[dnsTitle]="workflowActionTitle"
>
	<form #wfPropertiesForm="ngForm" name="addWorkflow" (ngSubmit)="onSubmit($event)" class="full-height">
		<div [ngSwitch]="workflowBlockData?.templateType" class="flex-auto-height config-sidebar">
			<!-- ########Api notification##### -->
			<ng-container *ngSwitchCase="workflowTemplateType.CONFIG_NOTIFICATION_API">
				<ul ngbNav #navFilled="ngbNav" class="nav-tabs nav-fill">
					<li ngbNavItem>
						<a ngbNavLink>{{ "UI.k_details" | translate }}</a>
						<ng-template ngbNavContent>
							<div class="container-fluid full-height">
								<form
									#apiForm="ngForm"
									name="setStateStatus"
									class="full-height"
									(ngSubmit)="onSaveapi(apiForm)"
									novalidate
								>
									<div class="flex-auto-height">
										<div class="flex-1">
											<ng-scrollbar class="custom-scrollbar section-scrollbar">
												<div class="d-flex flex-wrap">
													<div class="col-8">
														<div class="form-group">
															<label class="form-label" for="selAuthTyp"
																>{{ "UI.k_api" | translate }}
																<span class="required-field">*</span></label
															>
															<ng-select
																[items]="options?.api"
																bindLabel="name"
																name="api"
																placeholder="{{ 'UI.k_api_type' | translate }}"
																notFoundText="{{ 'UI.k_no_items_found' | translate }}"
																required
																#TDapilist="ngModel"
																[(ngModel)]="workflowBlockData.api_list_data"
																[class.error]="!TDapilist.valid && apiForm.submitted"
															>
																<ng-template
																	ng-option-tmp
																	let-item="item"
																	let-index="index"
																	let-search="searchTerm"
																>
																	<div class="avatar-content"></div>
																	<div class="">
																		<div>
																			<h6>{{ item.name }}</h6>
																		</div>
																		<div class="font-small-1">
																			{{ item?.api_url }}
																		</div>
																	</div>
																</ng-template>
															</ng-select>
															<span
																*ngIf="apiForm.submitted && TDapilist.invalid"
																class="invalid-form"
															>
																<small
																	class="form-text text-danger"
																	*ngIf="TDapilist.errors.required"
																	>{{ "UI.k_field_required" | translate }}</small
																>
															</span>
														</div>
													</div>
													<div class="col-4">
														<div class="form-group">
															<label class="form-label" for="selReqTyp"
																>{{ "UI.k_request_type" | translate }}
																<span class="required-field">&nbsp;*</span></label
															>

															<ng-select
																#TDReqTypeRef="ngModel"
																[items]="options?.api_options?.RequestTypeList"
																[(ngModel)]="workflowBlockData.request_type"
																notFoundText="{{ 'UI.k_no_items_found' | translate }}"
																bindLabel="name"
																bindValue="id"
																name="selReqTyp"
																id="selReqTyp"
																placeholder="{{ 'UI.k_request_type' | translate }}"
																[class.error]="!TDReqTypeRef.valid && apiForm.submitted"
																required
															>
															</ng-select>
															<span
																*ngIf="apiForm.submitted && TDReqTypeRef.invalid"
																class="invalid-form"
															>
																<small
																	class="form-text text-danger"
																	*ngIf="TDReqTypeRef.errors.required"
																	>{{ "UI.k_field_required" | translate }}</small
																>
															</span>
														</div>
													</div>
												</div>

												<div class="d-flex flex-wrap">
													<div class="form-group col-xs-12 col-md-12 col-xl-12 col-lg-12">
														<label class="form-label" for="txtbaseUrl">{{
															"UI.k_path" | translate
														}}</label>
														<input
															[(ngModel)]="workflowBlockData.api_path"
															#TDBaseUrlRef="ngModel"
															type="text"
															name="txtpath"
															id="txtpath"
															class="form-control"
															placeholder="{{ 'UI.k_path' | translate }}"
															[class.error]="!TDBaseUrlRef.valid && apiForm.submitted"
															[appWhiteSpaceCheck]="workflowBlockData.api_path"
														/>
														<span
															*ngIf="apiForm.submitted && TDBaseUrlRef.invalid"
															class="invalid-form"
														>
															<small
																class="form-text text-danger"
																*ngIf="TDBaseUrlRef.errors.required"
																>{{ "UI.k_field_required" | translate }}</small
															>
															<small
																class="form-text text-danger"
																*ngIf="TDBaseUrlRef.errors.pattern"
															>
																{{ "Error.err_invalid_url" | translate }}
																{{ "Error.err_valid_url" | translate }}.
															</small>
															<small
																class="form-text text-danger"
																*ngIf="TDBaseUrlRef.errors?.whitespace"
															>
																{{ "Error.err_ltspace" | translate }}
															</small>
														</span>
													</div>
												</div>

												<!-- ####header param -->
												<div class="">
													<div class="collapse-default">
														<div
															(click)="isCollapsed2 = !isCollapsed2"
															[attr.aria-expanded]="!isCollapsed2"
															aria-controls="collapseExample"
															class="card-header collapse-title modal-header d-flex justify-content-between align-items-center"
															data-toggle="collapse"
														>
															<span class="font-small-4">{{
																"UI.k_parameters" | translate
															}}</span>
															<div class="">
																<i
																	*ngIf="isCollapsed2"
																	class="fa-light fa-chevron-down xs-font mr-50"
																></i>
																<i
																	*ngIf="!isCollapsed2"
																	class="fa-light fa-chevron-up xs-font mr-50"
																></i>
															</div>
														</div>
														<div [ngbCollapse]="isCollapsed2">
															<div class="card">
																<div class="card-body">
																	<div class="container-fluid">
																		<div class="d-flex flex-column">
																			<div
																				*ngFor="
																					let item of workflowBlockData.headerParamList;
																					let i = index;
																					let last = last
																				"
																			>
																				<div class="d-flex row">
																					<div class="form-group col-4">
																						<label
																							class="form-label"
																							for="keys{{ i }}"
																							>{{
																								"UI.k_key" | translate
																							}}</label
																						>
																						<input
																							type="text"
																							class="form-control"
																							id="keys{{ i }}"
																							name="keys{{ i }}"
																							[(ngModel)]="item.keys"
																							#TDKeyRef="ngModel"
																							placeholder="{{
																								'UI.k_key' | translate
																							}}"
																							pattern="^[a-zA-Z]+[a-zA-Z0-9_-]*$"
																							[class.error]="
																								!TDKeyRef.valid &&
																								apiForm.submitted
																							"
																							required
																						/>
																						<dns-error-validation
																							[formRef]="apiForm"
																							[uiRef]="TDKeyRef"
																							[validationMsgConfig]="{
																								pattern:
																									_translateService.instant(
																										'Error.err_name_condition'
																									)
																							}"
																						></dns-error-validation>
																					</div>
																					<div class="form-group col-5">
																						<label
																							class="form-label"
																							for="values{{ i }}"
																							>{{
																								"UI.k_value" | translate
																							}}</label
																						>
																						<ng-select
																							[items]="
																								options?.incident_col_list
																							"
																							bindLabel="name"
																							bindValue="id"
																							name="values{{ i }}"
																							placeholder="{{
																								'UI.k_value' | translate
																							}}"
																							notFoundText="{{
																								'UI.k_no_items_found'
																									| translate
																							}}"
																							[class.error]="
																								!TDRstatus.valid &&
																								apiForm.submitted
																							"
																							required
																							#TDRstatus="ngModel"
																							[(ngModel)]="item.values"
																						>
																							<ng-template
																								ng-label-tmp
																								let-item="item"
																								let-clear="clear"
																							>
																								<span
																									class="ng-value-icon right"
																									(click)="
																										clear(item)
																									"
																									aria-hidden="true"
																									>×</span
																								>
																								<span
																									class="ng-value-label"
																									>{{
																										item.name
																											| translate
																									}}</span
																								>
																							</ng-template>
																							<ng-template
																								ng-option-tmp
																								let-item="item"
																							>
																								<span
																									>{{
																										item.name
																											| translate
																									}}
																								</span>
																							</ng-template>
																						</ng-select>
																						<dns-error-validation
																							[formRef]="apiForm"
																							[uiRef]="TDRstatus"
																						></dns-error-validation>
																					</div>
																					<div class="form-group col-2">
																						<label
																							class="form-label"
																							for="queryparam{{ i }}"
																							style="white-space: nowrap"
																							>{{
																								"UI.k_add_header"
																									| translate
																							}}</label
																						>
																						<div>
																							<div
																								class="custom-control custom-control-primary custom-switch custom-switch-sm"
																							>
																								<input
																									type="checkbox"
																									name="queryparam{{
																										i
																									}}"
																									[checked]="
																										item.queryparam
																									"
																									[(ngModel)]="
																										item.queryparam
																									"
																									class="custom-control-input"
																									id="queryparam{{
																										i
																									}}"
																								/>
																								<label
																									class="custom-control-label"
																									for="queryparam{{
																										i
																									}}"
																								></label>
																							</div>
																						</div>
																					</div>

																					<div class="form-group col-1">
																						<button
																							class="btn mt-2"
																							type="button"
																							(click)="
																								deleteItem(item.id)
																							"
																							id="btnDel{{ item.id }}"
																							name="btnDel{{ item.id }}"
																							rippleEffect
																						>
																							<i
																								class="fa-light fa-trash-can mr-25 text-danger"
																							></i>
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div class="form-group">
																		<button
																			class="btn btn-flat-primary"
																			(click)="addItem()"
																			id="btnaddItem"
																			name="btnaddItem"
																			type="button"
																			rippleEffect
																		>
																			<i
																				class="fa-light fa-plus font-small-1 align-middle mr-sm-25"
																			></i>
																			<span
																				>{{ "UI.k_add_params" | translate }}
																			</span>
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<!-- ####End head param -->
												<!-- #### static param -->
												<div class="">
													<div class="collapse-default">
														<div
															(click)="isCollapsed3 = !isCollapsed3"
															[attr.aria-expanded]="!isCollapsed3"
															aria-controls="collapseExample"
															class="card-header collapse-title modal-header d-flex justify-content-between align-items-center"
															data-toggle="collapse"
														>
															<span class="font-small-4">{{
																"UI.k_static_param" | translate
															}}</span>
															<div class="">
																<i
																	*ngIf="isCollapsed3"
																	class="fa-light fa-chevron-down xs-font mr-50"
																></i>
																<i
																	*ngIf="!isCollapsed3"
																	class="fa-light fa-chevron-up xs-font mr-50"
																></i>
															</div>
														</div>
														<div [ngbCollapse]="isCollapsed3">
															<div class="card">
																<div class="card-body">
																	<div class="container-fluid">
																		<div class="d-flex flex-column">
																			<div
																				*ngFor="
																					let item of workflowBlockData.staticParamList;
																					let i = index;
																					let last = last
																				"
																			>
																				<div class="d-flex row">
																					<div class="form-group col-4">
																						<label
																							class="form-label"
																							for="statickeys{{ i }}"
																							>{{
																								"UI.k_key" | translate
																							}}</label
																						>
																						<input
																							type="text"
																							class="form-control"
																							id="statickeys{{ i }}"
																							name="statickeys{{ i }}"
																							[(ngModel)]="
																								item.statickeys
																							"
																							#TDstaticKeyRef="ngModel"
																							placeholder="{{
																								'UI.k_key' | translate
																							}}"
																							pattern="^[a-zA-Z]+[a-zA-Z0-9_-]*$"
																							[class.error]="
																								!TDstaticKeyRef.valid &&
																								apiForm.submitted
																							"
																							required
																						/>
																						<dns-error-validation
																							[formRef]="apiForm"
																							[uiRef]="TDstaticKeyRef"
																							[validationMsgConfig]="{
																								pattern:
																									_translateService.instant(
																										'Error.err_name_condition'
																									)
																							}"
																						></dns-error-validation>
																					</div>
																					<div class="form-group col-5">
																						<label
																							class="form-label"
																							for="values{{ item.id }}"
																							>{{
																								"UI.k_value" | translate
																							}}</label
																						>
																						<input
																							type="text"
																							class="form-control"
																							id="staticvalues{{
																								item.id
																							}}"
																							name="staticvalues{{
																								item.id
																							}}"
																							[(ngModel)]="
																								item.staticvalues
																							"
																							#TDstaticValueRef="ngModel"
																							placeholder="{{
																								'UI.k_value' | translate
																							}}"
																							[appWhiteSpaceCheck]="
																								item.staticvalues
																							"
																							pattern="^[a-zA-Z]+[a-zA-Z0-9_-]*$"
																							[class.error]="
																								!TDstaticValueRef.valid &&
																								apiForm.submitted
																							"
																							required
																						/>
																						<dns-error-validation
																							[formRef]="apiForm"
																							[uiRef]="TDstaticValueRef"
																						></dns-error-validation>
																					</div>
																					<div class="form-group col-2">
																						<label
																							class="form-label"
																							for="querystaticparam{{
																								item.id
																							}}"
																							style="white-space: nowrap"
																							>{{
																								"UI.k_add_header"
																									| translate
																							}}</label
																						>
																						<div>
																							<div
																								class="custom-control custom-control-primary custom-switch custom-switch-sm"
																							>
																								<input
																									type="checkbox"
																									name="querystaticparam{{
																										item.id
																									}}"
																									[checked]="
																										item.querystaticparam
																									"
																									[(ngModel)]="
																										item.querystaticparam
																									"
																									class="custom-control-input"
																									id="querystaticparam{{
																										item.id
																									}}"
																								/>
																								<label
																									class="custom-control-label"
																									for="querystaticparam{{
																										item.id
																									}}"
																								></label>
																							</div>
																						</div>
																					</div>

																					<div class="form-group col-1">
																						<button
																							type="button"
																							class="btn mt-2"
																							(click)="
																								deletestaticItem(
																									item.id
																								)
																							"
																							id="staticbtnDel{{
																								item.id
																							}}"
																							name="staticbtnDel{{
																								item.id
																							}}"
																							rippleEffect
																						>
																							<i
																								class="fa-light fa-trash-can mr-25 text-danger"
																							></i>
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div class="form-group">
																		<button
																			class="btn btn-flat-primary"
																			(click)="addstaticItem()"
																			id="btnaddItem"
																			name="btnaddItem"
																			type="button"
																			rippleEffect
																		>
																			<i
																				class="fa-light fa-plus font-small-1 align-middle mr-sm-25"
																			></i>
																			<span
																				>{{ "UI.k_add_params" | translate }}
																			</span>
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</ng-scrollbar>
										</div>
										<div class="flex-initial p-1 border-top">
											<div class="justify-content-end d-flex">
												<button class="btn btn-primary" type="submit">
													{{ "UI.k_save" | translate }}
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</ng-template>
					</li>
				</ul>
				<div [ngbNavOutlet]="navFilled" class="mt-2 full-height"></div>
			</ng-container>
			<!-- ###################end -->
			<!-- Template for config sms start -->
			<ng-container *ngSwitchCase="workflowTemplateType.SEND_SMS">
				<ul ngbNav #navFilled="ngbNav" class="nav-tabs nav-fill">
					<li ngbNavItem>
						<a ngbNavLink>{{ "UI.k_configure_sms" | translate }}</a>
						<ng-template ngbNavContent>
							<div class="full-height">
								<form
									#smsForm="ngForm"
									name="configSmsForm"
									class="full-height"
									(ngSubmit)="saveSms(smsForm)"
									novalidate
								>
									<div class="flex-auto-height">
										<div class="flex-1">
											<ng-scrollbar class="custom-scrollbar section-scrollbar">
												<div class="container-fluid">
													<div class="row flex-grow-1 p-0" id="configure-sms">
														<div class="col-12 compose-form">
															<div class="d-flex align-item-center mb-1">
																<label
																	for="sms-to"
																	class="form-label align-self-center mr-25"
																	>{{ "UI.k_to" | translate }}*
																</label>
																<div class="flex-grow-1">
																	<ng-select
																		[items]="smsList"
																		[multiple]="true"
																		bindLabel="name"
																		bindValue="macro"
																		placeholder=""
																		name="selectTo"
																		id="sms-to"
																		required
																		#TdfSmsTo="ngModel"
																		[loading]="ngSelectLoading"
																		[(ngModel)]="workflowBlockData.to"
																		hideSelected="true"
																	>
																	</ng-select>
																	<span
																		*ngIf="smsForm.submitted && TdfSmsTo.invalid"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="TdfSmsTo.errors.required"
																			>{{
																				"UI.k_field_required" | translate
																			}}</small
																		>
																	</span>
																</div>
															</div>
															<ng-container
																*ngIf="
																	(workflowBlockData.subject &&
																		workflowBlockData.subject.indexOf('{{') > -1 &&
																		this.showMacroSection === true) ||
																	(this.workflowBlockData['quillText'] &&
																		this.showMacroSection === true)
																"
															>
																<app-macros-modal
																	class="position-absolute"
																	[inputConfig]="{
																		options: emailMacrosList,
																		currentEdit:
																			this.emailEdit === true
																				? 'emailEdit'
																				: 'quillEdit',
																		display_key: 'label'
																	}"
																	(macrosEmitEvent)="macrosSelectEvent($event)"
																></app-macros-modal>
															</ng-container>
															<div class="p-0 card border">
																<quill-editor
																	name="addDescription"
																	customToolbarPosition="'bottom'"
																	(onFocus)="onFieldFocus('message_body')"
																	placeholder="{{ 'UI.k_message_body' | translate }}"
																	[(ngModel)]="workflowBlockData.message_body"
																	[required]="true"
																	#qlSmsBody
																	format="text"
																	[trimOnValidation]="true"
																	[appQuillValidator]="qlSmsBody"
																	[appQuillForm]="smsForm"
																	(onContentChanged)="
																		getEnrichTextData($event, qlSmsBody?.errors)
																	"
																>
																	<div
																		quill-editor-toolbar
																		class="border-0 border-bottom px-0 py-50"
																	></div>
																</quill-editor>
																<span
																	class="error"
																	*ngIf="smsForm.submitted && qlSmsBody?.errors"
																>
																	<small
																		*ngIf="qlSmsBody?.errors?.requiredError?.empty"
																	>
																		{{ "UI.k_field_required" | translate }}
																	</small>
																</span>
																<div
																	class="compose-footer-wrapper email-footer mac-foot"
																>
																	<div class="btn-wrapper d-flex align-items-center">
																		{{ "UI.k_add_macros_text" | translate }}
																	</div>
																	<br />
																</div>
															</div>
															<div class="col-6 pl-0">
																<div class="form-group d-flex">
																	<div
																		class="custom-control custom-control-primary custom-switch custom-control-inline"
																	>
																		<p class="mb-50 mr-1">
																			{{ "UI.k_is_resend_action" | translate }}
																		</p>
																		<input
																			type="checkbox"
																			[checked]="workflowBlockData.is_recurring"
																			[(ngModel)]="workflowBlockData.is_recurring"
																			class="custom-control-input"
																			id="swActionSMSReOccurring"
																			name="swActionSMSReOccurring"
																		/>
																		<label
																			class="custom-control-label"
																			for="swActionSMSReOccurring"
																		></label>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</ng-scrollbar>
										</div>
										<div class="flex-initial p-1 border-top">
											<div class="justify-content-end d-flex">
												<button class="btn btn-primary">
													{{ "UI.k_save" | translate }}
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</ng-template>
					</li>
				</ul>
				<div [ngbNavOutlet]="navFilled" class="mt-2"></div>
			</ng-container>
			<!-- Template for config sms ends -->
			<!-- Template for config email start -->
			<ng-container *ngSwitchCase="workflowTemplateType.SEND_EMAIL">
				<ul ngbNav #navFilled="ngbNav" class="nav-tabs nav-fill">
					<li ngbNavItem>
						<a ngbNavLink>{{ "UI.k_configure_email" | translate }}</a>
						<ng-template ngbNavContent>
							<div class="full-height">
								<form
									#emailForm="ngForm"
									name="configEmailForm"
									class="full-height"
									(ngSubmit)="saveEmail(emailForm)"
									novalidate
								>
									<div class="flex-auto-height">
										<div class="flex-1">
											<ng-scrollbar class="custom-scrollbar section-scrollbar">
												<div class="px-1">
													<div class="container-fluid">
														<div class="row flex-grow-1 p-0" id="configure-mail">
															<div class="flex-fill mb-1">
																<div class="d-flex align-item-center p-0">
																	<span
																		for="email-to"
																		class="form-label align-self-center mr-25"
																		>{{ "UI.k_to" | translate }}*
																	</span>
																	<div class="flex-grow-1">
																		<ng-select
																			[items]="emailList"
																			[multiple]="true"
																			bindLabel="name"
																			bindValue="macro"
																			placeholder=""
																			required
																			name="selectTo"
																			#TdfEmailTo="ngModel"
																			id="email-to"
																			addTagText="{{
																				'UI.k_add_item' | translate
																			}}"
																			[(ngModel)]="workflowBlockData.to"
																			[addTag]="addTagPromise"
																			hideSelected="true"
																		>
																		</ng-select>
																		<span
																			*ngIf="
																				emailForm.submitted &&
																				TdfEmailTo.invalid
																			"
																			class="invalid-form"
																		>
																			<small
																				class="form-text text-danger"
																				*ngIf="TdfEmailTo.errors.required"
																				>{{
																					"UI.k_field_required" | translate
																				}}</small
																			>
																		</span>
																	</div>
																	<div class="ml-50 pt-50" *ngIf="!isOpenCC">
																		<span
																			class="text-primary cursor-pointer"
																			(click)="togglCcBcc('cc')"
																			>{{ "UI.k_cc" | translate }}</span
																		>
																	</div>
																</div>
																<div class="mt-1" *ngIf="isOpenCC">
																	<div class="d-flex p-0 ml-50">
																		<span class="cursor-pointer pt-50 mr-50">{{
																			"UI.k_cc" | translate
																		}}</span>
																		<div class="form-group flex-grow-1 mb-0">
																			<ng-select
																				[items]="emailList"
																				[multiple]="true"
																				[addTag]="addTagPromise"
																				bindLabel="name"
																				bindValue="macro"
																				placeholder=""
																				name="selectCC"
																				id="email-cc"
																				[(ngModel)]="workflowBlockData.cc"
																				notFoundText="{{
																					'UI.k_no_items_found' | translate
																				}}"
																				hideSelected="true"
																			>
																			</ng-select>
																		</div>
																		<button
																			class="btn btn-icon btn-flat-secondary text-body p-50 ml-50"
																			id="btnselectCC"
																			(click)="togglCcBcc('cc')"
																		>
																			<i class="fa-light fa-xmark"></i>
																		</button>
																	</div>
																</div>
																<div class="d-flex mb-1 mt-1">
																	<label
																		for="emailSubject"
																		class="form-label mr-50 pt-50"
																		style="display: flex; align-items: center"
																		>{{ "UI.k_subject" | translate
																		}}<span class="required-field">&nbsp;*</span>
																	</label>
																	<input
																		type="text"
																		(focus)="onFieldFocus('subject')"
																		[(ngModel)]="workflowBlockData.subject"
																		id="emailSubject"
																		#TDFSubject="ngModel"
																		class="form-control font-small-2"
																		placeholder="{{ 'UI.k_subject' | translate }}"
																		name="emailSubject"
																		maxlength="1000"
																		(ngModelChange)="emailSubjectEvent($event)"
																		required
																	/>
																	<ng-container
																		*ngIf="
																			(workflowBlockData.subject &&
																				workflowBlockData.subject.indexOf(
																					'{{'
																				) > -1 &&
																				this.showMacroSection === true) ||
																			(this.workflowBlockData['quillText'] &&
																				this.showMacroSection === true)
																		"
																	>
																		<app-macros-modal
																			class="position-absolute"
																			[inputConfig]="{
																				options: emailMacrosList,
																				currentEdit:
																					this.emailEdit === true
																						? 'emailEdit'
																						: 'quillEdit',
																				display_key: 'label'
																			}"
																			(macrosEmitEvent)="
																				macrosSelectEvent($event)
																			"
																		></app-macros-modal>
																	</ng-container>
																</div>
																<span
																	class="error"
																	*ngIf="emailForm.submitted && TDFSubject.invalid"
																>
																	<small *ngIf="TDFSubject.errors.required">{{
																		"UI.k_field_required" | translate
																	}}</small>
																</span>
																<div class="p-0 card border">
																	<quill-editor
																		[(ngModel)]="workflowBlockData.message_body"
																		id="articleNote"
																		name="articleNote"
																		customToolbarPosition="'bottom'"
																		class="border-bottom-0"
																		(onFocus)="onFieldFocus('message_body')"
																		placeholder="{{
																			'UI.k_message_body' | translate
																		}}"
																		[required]="true"
																		#qlEmailBody
																		[trimOnValidation]="true"
																		[appQuillValidator]="qlEmailBody"
																		[appQuillForm]="emailForm"
																		(onContentChanged)="
																			getEnrichTextData(
																				$event,
																				qlEmailBody?.errors
																			)
																		"
																	>
																		<div
																			quill-editor-toolbar
																			class="border-0 border-bottom px-0 py-50"
																		>
																			<div class="border-bottom p-0">
																				<span class="ql-formats pb-50">
																					<button
																						id="qlBoldNoteEmail-Inc"
																						name="qlBold"
																						class="ql-bold"
																						title="{{
																							'UI.k_bold' | translate
																						}}"
																					></button>
																					<button
																						id="qlItalicNoteEmail-Inc"
																						name="qlItalic"
																						class="ql-italic"
																						title="{{
																							'UI.k_italic' | translate
																						}}"
																					></button>
																					<button
																						id="qlUnderlineNoteEmail-Inc"
																						name="qlUnderline"
																						class="ql-underline"
																						title="{{
																							'UI.k_underline' | translate
																						}}"
																					></button>
																				</span>
																				<span class="ql-formats">
																					<select
																						id="qlAlignNote"
																						class="ql-align"
																						title="{{
																							'UI.k_alignment' | translate
																						}}"
																					>
																						<option selected></option>
																						<option value="center"></option>
																						<option value="right"></option>
																						<option
																							value="justify"
																						></option>
																					</select>
																				</span>
																				<span class="ql-formats">
																					<button
																						id="btnqlListNote"
																						name="qlList"
																						class="ql-list"
																						value="ordered"
																						type="button"
																					></button>
																				</span>
																				<span class="ql-formats">
																					<button
																						id="btnqlCleanNoteEmail-Inc"
																						class="ql-clean"
																						value="ordered"
																						type="button"
																					></button>
																				</span>
																			</div>
																		</div>
																		<div quill-editor-element *ngIf="isMacro">
																			<div class="form-group">
																				test
																				<!-- Select Group by item -->
																				<!-- <ng-select [items]="this.emailConfig.macrosOption" bindLabel="name" bindValue="name" groupBy="Asset"
																				  [(ngModel)]="this.emailConfig.selectedMacro">
																				  <ng-template ng-optgroup-tmp let-item="item">
																					{{ item.name }}
																				  </ng-template>
																				</ng-select> -->
																				<br />
																			</div>
																		</div>
																	</quill-editor>
																	<span
																		class="error"
																		*ngIf="
																			emailForm.submitted && qlEmailBody?.errors
																		"
																	>
																		<small
																			*ngIf="
																				qlEmailBody?.errors?.requiredError
																					?.empty
																			"
																		>
																			{{ "UI.k_field_required" | translate }}
																		</small>
																	</span>
																	<div
																		class="compose-footer-wrapper email-footer mac-foot"
																	>
																		<div
																			class="btn-wrapper d-flex align-items-center"
																		>
																			{{ "UI.k_add_macros_text" | translate }}
																		</div>
																		<br />
																	</div>
																</div>
																<div class="col-6 pl-0">
																	<div class="form-group d-flex">
																		<div
																			class="custom-control custom-control-primary custom-switch custom-control-inline"
																		>
																			<p class="mb-50 mr-1">
																				{{
																					"UI.k_is_resend_action" | translate
																				}}
																			</p>
																			<input
																				type="checkbox"
																				[checked]="
																					workflowBlockData.is_recurring
																				"
																				[(ngModel)]="
																					workflowBlockData.is_recurring
																				"
																				class="custom-control-input"
																				id="swActionReOccurring"
																				name="swActionReOccurring"
																			/>
																			<label
																				class="custom-control-label"
																				for="swActionReOccurring"
																			></label>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</ng-scrollbar>
										</div>
										<div class="flex-initial p-1 border-top">
											<div class="justify-content-end d-flex">
												<button class="btn btn-primary" type="submit">
													{{ "UI.k_save" | translate }}
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</ng-template>
					</li>
				</ul>
				<div [ngbNavOutlet]="navFilled" class="mt-2"></div>
			</ng-container>
			<!-- Template for config email ends -->
			<!-- Template for config approval notification start -->
			<ng-container *ngSwitchCase="workflowTemplateType.CONFIG_APPROVAL_NOTIFICATION">
				<ul ngbNav #navFilled="ngbNav" class="nav-tabs nav-fill">
					<li ngbNavItem>
						<a ngbNavLink>{{ "UI.k_configure_email" | translate }}</a>
						<ng-template ngbNavContent>
							<div class="full-height">
								<form
									#emailForm="ngForm"
									name="configEmailApprovalForm"
									class="full-height"
									(ngSubmit)="saveEmail(emailForm)"
									novalidate
								>
									<div class="flex-auto-height">
										<div class="flex-1">
											<ng-scrollbar class="custom-scrollbar section-scrollbar">
												<div class="px-1">
													<div class="container-fluid">
														<div class="row flex-grow-1 p-0" id="configure-mail">
															<div class="flex-fill mb-1">
																<div class="d-flex align-item-center p-0">
																	<span
																		for="email-to"
																		class="form-label align-self-center mr-25"
																		>{{ "UI.k_to" | translate }}*
																	</span>
																	<div class="flex-grow-1">
																		<ng-select
																			[items]="emailList"
																			[multiple]="true"
																			bindLabel="name"
																			bindValue="macro"
																			placeholder=""
																			required
																			name="selectTo"
																			#TdfEmailTo="ngModel"
																			id="email-to"
																			addTagText="{{
																				'UI.k_add_item' | translate
																			}}"
																			[(ngModel)]="workflowBlockData.to"
																			[addTag]="addTagPromise"
																			hideSelected="true"
																		>
																		</ng-select>
																		<span
																			*ngIf="
																				emailForm.submitted &&
																				TdfEmailTo.invalid
																			"
																			class="invalid-form"
																		>
																			<small
																				class="form-text text-danger"
																				*ngIf="TdfEmailTo.errors.required"
																				>{{
																					"UI.k_field_required" | translate
																				}}</small
																			>
																		</span>
																	</div>
																	<div class="ml-50 pt-50" *ngIf="!isOpenCC">
																		<span
																			class="text-primary cursor-pointer"
																			(click)="togglCcBcc('cc')"
																			>{{ "UI.k_cc" | translate }}</span
																		>
																	</div>
																</div>
																<div class="mt-1" *ngIf="isOpenCC">
																	<div class="d-flex p-0 ml-50">
																		<span class="cursor-pointer pt-50 mr-50">{{
																			"UI.k_cc" | translate
																		}}</span>
																		<div class="form-group flex-grow-1 mb-0">
																			<ng-select
																				[items]="emailList"
																				[multiple]="true"
																				[addTag]="addTagPromise"
																				bindLabel="name"
																				bindValue="macro"
																				placeholder=""
																				name="selectCC"
																				id="email-cc"
																				[(ngModel)]="workflowBlockData.cc"
																				notFoundText="{{
																					'UI.k_no_items_found' | translate
																				}}"
																				hideSelected="true"
																			>
																			</ng-select>
																		</div>
																		<button
																			class="btn btn-icon btn-flat-secondary text-body p-50 ml-50"
																			id="btnselectCC"
																			(click)="togglCcBcc('cc')"
																		>
																			<i class="fa-light fa-xmark"></i>
																		</button>
																	</div>
																</div>
																<div class="d-flex mb-1 mt-1">
																	<label
																		for="emailSubject"
																		class="form-label mr-50 pt-50"
																		style="display: flex; align-items: center"
																		>{{ "UI.k_subject" | translate
																		}}<span class="required-field">&nbsp;*</span>
																	</label>
																	<input
																		type="text"
																		(focus)="onFieldFocus('subject')"
																		[(ngModel)]="workflowBlockData.subject"
																		id="emailSubject"
																		#TDFSubject="ngModel"
																		class="form-control font-small-2"
																		placeholder="{{ 'UI.k_subject' | translate }}"
																		name="emailSubject"
																		maxlength="1000"
																		(ngModelChange)="emailSubjectEvent($event)"
																		required
																	/>
																	<ng-container
																		*ngIf="
																			(workflowBlockData.subject &&
																				workflowBlockData.subject.indexOf(
																					'{{'
																				) > -1 &&
																				this.showMacroSection === true) ||
																			(this.workflowBlockData['quillText'] &&
																				this.showMacroSection === true)
																		"
																	>
																		<app-macros-modal
																			class="position-absolute"
																			[inputConfig]="{
																				options: emailMacrosList,
																				currentEdit:
																					this.emailEdit === true
																						? 'emailEdit'
																						: 'quillEdit',
																				display_key: 'label'
																			}"
																			(macrosEmitEvent)="
																				macrosSelectEvent($event)
																			"
																		></app-macros-modal>
																	</ng-container>
																</div>
																<span
																	class="error"
																	*ngIf="emailForm.submitted && TDFSubject.invalid"
																>
																	<small *ngIf="TDFSubject.errors.required">{{
																		"UI.k_field_required" | translate
																	}}</small>
																</span>
																<div class="p-0 card border">
																	<quill-editor
																		[(ngModel)]="workflowBlockData.message_body"
																		id="articleNote"
																		name="articleNote"
																		customToolbarPosition="'bottom'"
																		class="border-bottom-0"
																		(onFocus)="onFieldFocus('message_body')"
																		placeholder="{{
																			'UI.k_message_body' | translate
																		}}"
																		[required]="true"
																		#qlEmailBody
																		[trimOnValidation]="true"
																		[appQuillValidator]="qlEmailBody"
																		[appQuillForm]="emailForm"
																		(onContentChanged)="
																			getEnrichTextData(
																				$event,
																				qlEmailBody?.errors
																			)
																		"
																	>
																		<div
																			quill-editor-toolbar
																			class="border-0 border-bottom px-0 py-50"
																		>
																			<div class="border-bottom p-0">
																				<span class="ql-formats pb-50">
																					<button
																						id="qlBoldNoteEmail-Inc"
																						name="qlBold"
																						class="ql-bold"
																						title="{{
																							'UI.k_bold' | translate
																						}}"
																					></button>
																					<button
																						id="qlItalicNoteEmail-Inc"
																						name="qlItalic"
																						class="ql-italic"
																						title="{{
																							'UI.k_italic' | translate
																						}}"
																					></button>
																					<button
																						id="qlUnderlineNoteEmail-Inc"
																						name="qlUnderline"
																						class="ql-underline"
																						title="{{
																							'UI.k_underline' | translate
																						}}"
																					></button>
																				</span>
																				<span class="ql-formats">
																					<select
																						id="qlAlignNote"
																						class="ql-align"
																						title="{{
																							'UI.k_alignment' | translate
																						}}"
																					>
																						<option selected></option>
																						<option value="center"></option>
																						<option value="right"></option>
																						<option
																							value="justify"
																						></option>
																					</select>
																				</span>
																				<span class="ql-formats">
																					<button
																						id="btnqlListNote"
																						name="qlList"
																						class="ql-list"
																						value="ordered"
																						type="button"
																					></button>
																				</span>
																				<span class="ql-formats">
																					<button
																						id="btnqlCleanNoteEmail-Inc"
																						class="ql-clean"
																						value="ordered"
																						type="button"
																					></button>
																				</span>
																			</div>
																		</div>
																		<div quill-editor-element *ngIf="isMacro">
																			<div class="form-group">
																				test
																				<!-- Select Group by item -->
																				<!-- <ng-select [items]="this.emailConfig.macrosOption" bindLabel="name" bindValue="name" groupBy="Asset"
																				  [(ngModel)]="this.emailConfig.selectedMacro">
																				  <ng-template ng-optgroup-tmp let-item="item">
																					{{ item.name }}
																				  </ng-template>
																				</ng-select> -->
																				<br />
																			</div>
																		</div>
																	</quill-editor>
																	<span
																		class="error"
																		*ngIf="
																			emailForm.submitted && qlEmailBody?.errors
																		"
																	>
																		<small
																			*ngIf="
																				qlEmailBody?.errors?.requiredError
																					?.empty
																			"
																		>
																			{{ "UI.k_field_required" | translate }}
																		</small>
																	</span>
																	<div
																		class="compose-footer-wrapper email-footer mac-foot"
																	>
																		<div
																			class="btn-wrapper d-flex align-items-center"
																		>
																			{{ "UI.k_add_macros_text" | translate }}
																		</div>
																		<br />
																	</div>
																</div>
																<div class="col-6 pl-0">
																	<div class="form-group d-flex">
																		<div
																			class="custom-control custom-control-primary custom-switch custom-control-inline"
																		>
																			<p class="mb-50 mr-1">
																				{{
																					"UI.k_is_resend_action" | translate
																				}}
																			</p>
																			<input
																				type="checkbox"
																				[checked]="
																					workflowBlockData.is_recurring
																				"
																				[(ngModel)]="
																					workflowBlockData.is_recurring
																				"
																				class="custom-control-input"
																				id="swActionReOccurring"
																				name="swActionReOccurring"
																			/>
																			<label
																				class="custom-control-label"
																				for="swActionReOccurring"
																			></label>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</ng-scrollbar>
										</div>
										<div class="flex-initial p-1 border-top">
											<div class="justify-content-end d-flex">
												<button class="btn btn-primary" type="submit">
													{{ "UI.k_save" | translate }}
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</ng-template>
					</li>
				</ul>
				<div [ngbNavOutlet]="navFilled" class="mt-2"></div>
			</ng-container>
			<!-- Template for config approval notification ends -->
			<!-- Template for config review notification start -->
			<ng-container *ngSwitchCase="workflowTemplateType.CONFIG_REVIEW_NOTIFICATION">
				<ul ngbNav #navFilled="ngbNav" class="nav-tabs nav-fill">
					<li ngbNavItem>
						<a ngbNavLink>{{ "UI.k_configure_email" | translate }}</a>
						<ng-template ngbNavContent>
							<div class="full-height">
								<form
									#emailForm="ngForm"
									name="configEmailReviewForm"
									class="full-height"
									(ngSubmit)="saveEmail(emailForm)"
									novalidate
								>
									<div class="flex-auto-height">
										<div class="flex-1">
											<ng-scrollbar class="custom-scrollbar section-scrollbar">
												<div class="px-1">
													<div class="container-fluid">
														<div class="row flex-grow-1 p-0" id="configure-mail">
															<div class="flex-fill mb-1">
																<div class="d-flex align-item-center p-0">
																	<span
																		for="email-to"
																		class="form-label align-self-center mr-25"
																		>{{ "UI.k_to" | translate }}*
																	</span>
																	<div class="flex-grow-1">
																		<ng-select
																			[items]="emailList"
																			[multiple]="true"
																			bindLabel="name"
																			bindValue="macro"
																			placeholder=""
																			required
																			name="selectTo"
																			#TdfEmailTo="ngModel"
																			id="email-to"
																			addTagText="{{
																				'UI.k_add_item' | translate
																			}}"
																			[(ngModel)]="workflowBlockData.to"
																			[addTag]="addTagPromise"
																			hideSelected="true"
																		>
																		</ng-select>
																		<span
																			*ngIf="
																				emailForm.submitted &&
																				TdfEmailTo.invalid
																			"
																			class="invalid-form"
																		>
																			<small
																				class="form-text text-danger"
																				*ngIf="TdfEmailTo.errors.required"
																				>{{
																					"UI.k_field_required" | translate
																				}}</small
																			>
																		</span>
																	</div>
																	<div class="ml-50 pt-50" *ngIf="!isOpenCC">
																		<span
																			class="text-primary cursor-pointer"
																			(click)="togglCcBcc('cc')"
																			>{{ "UI.k_cc" | translate }}</span
																		>
																	</div>
																</div>
																<div class="mt-1" *ngIf="isOpenCC">
																	<div class="d-flex p-0 ml-50">
																		<span class="cursor-pointer pt-50 mr-50">{{
																			"UI.k_cc" | translate
																		}}</span>
																		<div class="form-group flex-grow-1 mb-0">
																			<ng-select
																				[items]="emailList"
																				[multiple]="true"
																				[addTag]="addTagPromise"
																				bindLabel="name"
																				bindValue="macro"
																				placeholder=""
																				name="selectCC"
																				id="email-cc"
																				[(ngModel)]="workflowBlockData.cc"
																				notFoundText="{{
																					'UI.k_no_items_found' | translate
																				}}"
																				hideSelected="true"
																			>
																			</ng-select>
																		</div>
																		<button
																			class="btn btn-icon btn-flat-secondary text-body p-50 ml-50"
																			id="btnselectCC"
																			(click)="togglCcBcc('cc')"
																		>
																			<i class="fa-light fa-xmark"></i>
																		</button>
																	</div>
																</div>
																<div class="d-flex mb-1 mt-1">
																	<label
																		for="emailSubject"
																		class="form-label mr-50 pt-50"
																		style="display: flex; align-items: center"
																		>{{ "UI.k_subject" | translate
																		}}<span class="required-field">&nbsp;*</span>
																	</label>
																	<input
																		type="text"
																		(focus)="onFieldFocus('subject')"
																		[(ngModel)]="workflowBlockData.subject"
																		id="emailSubject"
																		#TDFSubject="ngModel"
																		class="form-control font-small-2"
																		placeholder="{{ 'UI.k_subject' | translate }}"
																		name="emailSubject"
																		maxlength="1000"
																		(ngModelChange)="emailSubjectEvent($event)"
																		required
																	/>
																	<ng-container
																		*ngIf="
																			(workflowBlockData.subject &&
																				workflowBlockData.subject.indexOf(
																					'{{'
																				) > -1 &&
																				this.showMacroSection === true) ||
																			(this.workflowBlockData['quillText'] &&
																				this.showMacroSection === true)
																		"
																	>
																		<app-macros-modal
																			class="position-absolute"
																			[inputConfig]="{
																				options: emailMacrosList,
																				currentEdit:
																					this.emailEdit === true
																						? 'emailEdit'
																						: 'quillEdit',
																				display_key: 'label'
																			}"
																			(macrosEmitEvent)="
																				macrosSelectEvent($event)
																			"
																		></app-macros-modal>
																	</ng-container>
																</div>
																<span
																	class="error"
																	*ngIf="emailForm.submitted && TDFSubject.invalid"
																>
																	<small *ngIf="TDFSubject.errors.required">{{
																		"UI.k_field_required" | translate
																	}}</small>
																</span>
																<div class="p-0 card border">
																	<quill-editor
																		[(ngModel)]="workflowBlockData.message_body"
																		id="articleNote"
																		name="articleNote"
																		customToolbarPosition="'bottom'"
																		class="border-bottom-0"
																		(onFocus)="onFieldFocus('message_body')"
																		placeholder="{{
																			'UI.k_message_body' | translate
																		}}"
																		[required]="true"
																		#qlEmailBody
																		[trimOnValidation]="true"
																		[appQuillValidator]="qlEmailBody"
																		[appQuillForm]="emailForm"
																		(onContentChanged)="
																			getEnrichTextData(
																				$event,
																				qlEmailBody?.errors
																			)
																		"
																	>
																		<div
																			quill-editor-toolbar
																			class="border-0 border-bottom px-0 py-50"
																		>
																			<div class="border-bottom p-0">
																				<span class="ql-formats pb-50">
																					<button
																						id="qlBoldNoteEmail-Inc"
																						name="qlBold"
																						class="ql-bold"
																						title="{{
																							'UI.k_bold' | translate
																						}}"
																					></button>
																					<button
																						id="qlItalicNoteEmail-Inc"
																						name="qlItalic"
																						class="ql-italic"
																						title="{{
																							'UI.k_italic' | translate
																						}}"
																					></button>
																					<button
																						id="qlUnderlineNoteEmail-Inc"
																						name="qlUnderline"
																						class="ql-underline"
																						title="{{
																							'UI.k_underline' | translate
																						}}"
																					></button>
																				</span>
																				<span class="ql-formats">
																					<select
																						id="qlAlignNote"
																						class="ql-align"
																						title="{{
																							'UI.k_alignment' | translate
																						}}"
																					>
																						<option selected></option>
																						<option value="center"></option>
																						<option value="right"></option>
																						<option
																							value="justify"
																						></option>
																					</select>
																				</span>
																				<span class="ql-formats">
																					<button
																						id="btnqlListNote"
																						name="qlList"
																						class="ql-list"
																						value="ordered"
																						type="button"
																					></button>
																				</span>
																				<span class="ql-formats">
																					<button
																						id="btnqlCleanNoteEmail-Inc"
																						class="ql-clean"
																						value="ordered"
																						type="button"
																					></button>
																				</span>
																			</div>
																		</div>
																		<div quill-editor-element *ngIf="isMacro">
																			<div class="form-group">
																				test
																				<!-- Select Group by item -->
																				<!-- <ng-select [items]="this.emailConfig.macrosOption" bindLabel="name" bindValue="name" groupBy="Asset"
																				  [(ngModel)]="this.emailConfig.selectedMacro">
																				  <ng-template ng-optgroup-tmp let-item="item">
																					{{ item.name }}
																				  </ng-template>
																				</ng-select> -->
																				<br />
																			</div>
																		</div>
																	</quill-editor>
																	<span
																		class="error"
																		*ngIf="
																			emailForm.submitted && qlEmailBody?.errors
																		"
																	>
																		<small
																			*ngIf="
																				qlEmailBody?.errors?.requiredError
																					?.empty
																			"
																		>
																			{{ "UI.k_field_required" | translate }}
																		</small>
																	</span>
																	<div
																		class="compose-footer-wrapper email-footer mac-foot"
																	>
																		<div
																			class="btn-wrapper d-flex align-items-center"
																		>
																			{{ "UI.k_add_macros_text" | translate }}
																		</div>
																		<br />
																	</div>
																</div>
																<div class="col-6 pl-0">
																	<div class="form-group d-flex">
																		<div
																			class="custom-control custom-control-primary custom-switch custom-control-inline"
																		>
																			<p class="mb-50 mr-1">
																				{{
																					"UI.k_is_resend_action" | translate
																				}}
																			</p>
																			<input
																				type="checkbox"
																				[checked]="
																					workflowBlockData.is_recurring
																				"
																				[(ngModel)]="
																					workflowBlockData.is_recurring
																				"
																				class="custom-control-input"
																				id="swActionReOccurring"
																				name="swActionReOccurring"
																			/>
																			<label
																				class="custom-control-label"
																				for="swActionReOccurring"
																			></label>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</ng-scrollbar>
										</div>
										<div class="flex-initial p-1 border-top">
											<div class="justify-content-end d-flex">
												<button class="btn btn-primary" type="submit">
													{{ "UI.k_save" | translate }}
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</ng-template>
					</li>
				</ul>
				<div [ngbNavOutlet]="navFilled" class="mt-2"></div>
			</ng-container>
			<!-- Template for config review notification ends -->
			<ng-container *ngSwitchCase="workflowTemplateType.CONFIG_CONDITIONS">
				<ul ngbNav #navFilled="ngbNav" class="nav-tabs nav-fill">
					<li ngbNavItem>
						<a ngbNavLink>{{ "UI.k_config_conditions" | translate }}</a>
						<ng-template ngbNavContent>
							<div class="container-fluid full-height">
								<div class="flex-auto-height">
									<div class="flex-1">
										<ng-scrollbar class="custom-scrollbar section-scrollbar">
											<div class="row m-0">
												<workflow-conditions
													#wfCondition
													[conditions]="conditions"
													class="col-12 p-0"
													assignment="true"
													(output)="onConditionsChange($event)"
													[result]="workflowBlockData.condition"
												></workflow-conditions>
												<span
													*ngIf="wfPropertiesForm.submitted && !isWorkflowConditionSelected"
													class="invalid-form"
												>
													<small class="form-text text-danger">{{
														wf_conditions_error_message
													}}</small>
												</span>
												<span
													*ngIf="
														wfPropertiesForm.submitted &&
														duplicate &&
														workflowBlockData.condition?.length > 1
													"
													class="invalid-form"
												>
													<small class="form-text text-danger">{{
														"Error.err_duplicate_conditions" | translate
													}}</small>
												</span>
											</div>
										</ng-scrollbar>
									</div>
									<div class="flex-initial p-1 border-top">
										<div class="justify-content-end d-flex">
											<button class="btn btn-primary" (click)="saveConditions(wfCondition)">
												{{ "UI.k_save" | translate }}
											</button>
										</div>
									</div>
								</div>
							</div>
						</ng-template>
					</li>
				</ul>
				<div [ngbNavOutlet]="navFilled" class="mt-2"></div>
			</ng-container>
			<!-- Template for set value start -->
			<ng-container *ngSwitchCase="workflowTemplateType.SET_VALUE">
				<ul ngbNav #navFilled="ngbNav" class="nav-tabs nav-fill">
					<li ngbNavItem>
						<a ngbNavLink>{{ "UI.k_set_value" | translate }}</a>
						<ng-template ngbNavContent>
							<div class="container-fluid full-height">
								<form
									#setValueForm="ngForm"
									name="configSetValueForm"
									class="full-height"
									(ngSubmit)="saveValue(setValueForm, valueSelectorComponent)"
									novalidate
								>
									<div class="flex-auto-height">
										<div class="flex-1">
											<ng-scrollbar class="custom-scrollbar section-scrollbar">
												<div class="row">
													<dns-value-selector
														class="col-12"
														[options]="setValueOperands"
														#valueSelectorComponent
														(output)="onSetValueChange($event)"
														[data]="workflowBlockData?.update || [{}]"
														[dataForm]="setValueForm"
														[is_required]="true"
													></dns-value-selector>
												</div>
											</ng-scrollbar>
										</div>
										<div class="flex-initial p-1 border-top">
											<div class="justify-content-end d-flex">
												<button class="btn btn-primary" type="submit">
													{{ "UI.k_save" | translate }}
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</ng-template>
					</li>
				</ul>
				<div [ngbNavOutlet]="navFilled" class="mt-2"></div>
			</ng-container>
			<!-- Template for set value ends -->
			<!-- Template for config status start -->
			<ng-container *ngSwitchCase="workflowTemplateType.CONFIG_STATUS">
				<ul ngbNav #navFilled="ngbNav" class="nav-tabs nav-fill">
					<li ngbNavItem>
						<a ngbNavLink>{{ "UI.k_details" | translate }}</a>
						<ng-template ngbNavContent>
							<div class="container-fluid full-height">
								<form
									#setStatusForm="ngForm"
									name="setStateStatus"
									class="full-height"
									(ngSubmit)="onSaveStatus(setStatusForm)"
									novalidate
								>
									<div class="flex-auto-height">
										<div class="flex-1">
											<ng-scrollbar class="custom-scrollbar section-scrollbar">
												<ngb-alert
													[type]="'warning'"
													[dismissible]="false"
													*ngIf="options?.assigned_status?.includes(workflowBlockData?.guid)"
												>
													<div class="alert-body">
														{{
															"Message.msg_status_in_use"
																| translate
																	: {
																			process:
																				module_map_config[
																					workflowData.module_id
																				]
																	  }
														}}
													</div>
												</ngb-alert>
												<div class="row">
													<div class="col-6">
														<div class="form-group">
															<label>{{ "UI.k_state" | translate }}</label>
															<ng-select
																[items]="options.state"
																bindLabel="name"
																name="state"
																placeholder="{{ 'UI.k_select_state' | translate }}"
																[readonly]="true"
																[(ngModel)]="workflowBlockData.state"
															>
																<ng-template ng-label-tmp let-item="item">
																	<span>{{ item.name | translate }}</span>
																</ng-template>
															</ng-select>
														</div>
													</div>
													<div class="col-6">
														<div class="form-group">
															<label>{{ "UI.k_status" | translate }}</label>
															<input
																type="text"
																[(ngModel)]="workflowBlockData.status"
																name="status"
																required
																#TDStatusNameRef="ngModel"
																[disabled]="
																	options?.assigned_status?.includes(
																		workflowBlockData?.guid
																	)
																"
																[class.error]="
																	is_form_submitted &&
																	setStatusForm.submitted &&
																	!TDStatusNameRef.valid
																"
																class="form-control"
																placeholder="{{
																	'UI.k_wf_status_config_placeholder' | translate
																}}"
																maxlength="40"
																[appWhiteSpaceCheck]="workflowBlockData.status"
																[ngbTypeahead]="searchStatus"
																[resultFormatter]="statusResultFormatter"
																[inputFormatter]="statusResultFormatter"
																(selectItem)="onSearchStatusSelect($event)"
																[ngStyle]="{
																	color: workflowBlockData?.color
																}"
															/>
															<span
																class="error text-danger"
																*ngIf="
																	setStatusForm.submitted &&
																	workflowBlockData.is_error
																"
																>{{ "UI.k_status_already_exist" | translate }}</span
															>
															<span
																*ngIf="
																	is_form_submitted &&
																	setStatusForm.submitted &&
																	TDStatusNameRef.invalid
																"
																class="invalid-form"
															>
																<small
																	*ngIf="TDStatusNameRef?.errors?.required"
																	class="form-text text-danger"
																	>{{ "UI.k_field_required" | translate }}</small
																>
																<small
																	*ngIf="TDStatusNameRef.errors.maxlength"
																	class="form-text text-danger"
																	>{{
																		"Error.err_input_max_char"
																			| translate: { max_length: 40 }
																	}}</small
																>
																<small
																	*ngIf="TDStatusNameRef.errors?.whitespace"
																	class="form-text text-danger"
																	>{{ "Error.err_ltspace" | translate }}</small
																>
															</span>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-6">
														<label>{{ "UI.k_select_status_color" | translate }}</label>
														<div class="form-group d-flex">
															<input
																type="color"
																class="form-control"
																[(ngModel)]="workflowBlockData.color"
																name="wfStatusColor"
																[disabled]="
																	options?.assigned_status?.includes(
																		workflowBlockData?.guid
																	)
																"
																[value]="workflowBlockData.color"
															/>
														</div>
													</div>
													<!-- Response time toggle start-->
													<ng-container
														*ngIf="checkIsResponseTimeAllowed(workflowBlockData.guid)"
													>
														<div class="col-6">
															<div class="form-group d-flex">
																<div
																	class="custom-control custom-control-primary custom-switch custom-control-inline"
																>
																	<p class="mb-50 mr-1">
																		{{ "UI.k_mark_as_response_time" | translate }}
																	</p>
																	<input
																		type="checkbox"
																		[checked]="workflowBlockData.is_response_time"
																		[(ngModel)]="workflowBlockData.is_response_time"
																		(change)="
																			onStatusResponseTimeCheck(
																				$event.target.checked
																			)
																		"
																		class="custom-control-input"
																		id="swStateResponseTime"
																		name="swStateResponseTime"
																		[disabled]="
																			options?.assigned_status?.includes(
																				workflowBlockData?.guid
																			)
																		"
																	/>
																	<label
																		class="custom-control-label"
																		for="swStateResponseTime"
																	></label>
																</div>
															</div>
														</div>
													</ng-container>
													<!-- Response time toggle ends-->
													<div
														class="col-6"
														*ngIf="checkIsEscalatedAllowed(workflowBlockData.guid)"
													>
														<div class="form-group d-flex">
															<div
																class="custom-control custom-control-primary custom-switch custom-control-inline"
															>
																<p class="mb-50 mr-1">
																	{{ "UI.k_mark_as_escalated" | translate }}
																</p>
																<input
																	type="checkbox"
																	[checked]="workflowBlockData.is_escalated"
																	[(ngModel)]="workflowBlockData.is_escalated"
																	(change)="
																		onStatusEscalatedCheck($event.target.checked)
																	"
																	class="custom-control-input"
																	id="swStateEscalated"
																	name="swStateEscalated"
																	[disabled]="
																		options?.assigned_status?.includes(
																			workflowBlockData?.guid
																		)
																	"
																/>
																<label
																	class="custom-control-label"
																	for="swStateEscalated"
																></label>
															</div>
														</div>
													</div>
													<!-- Resolve by event toggle start-->
													<ng-container
														*ngIf="checkIsResolveByEventAllowed(workflowBlockData.guid)"
													>
														<div class="col-6">
															<div class="form-group d-flex">
																<div
																	class="custom-control custom-control-primary custom-switch custom-control-inline"
																>
																	<p class="mb-50 mr-1">
																		{{ "UI.k_resolve_by_event" | translate }}
																	</p>
																	<input
																		type="checkbox"
																		[checked]="
																			workflowBlockData.is_resolve_by_event
																		"
																		[(ngModel)]="
																			workflowBlockData.is_resolve_by_event
																		"
																		(change)="
																			onResolveByEventCheck($event.target.checked)
																		"
																		class="custom-control-input"
																		id="swStateResolveByEvent"
																		name="swStateResolveByEvent"
																		[disabled]="
																			options?.assigned_status?.includes(
																				workflowBlockData?.guid
																			)
																		"
																	/>
																	<label
																		class="custom-control-label"
																		for="swStateResolveByEvent"
																	></label>
																</div>
															</div>
														</div>
													</ng-container>
													<!-- Resolve by event toggle ends-->
													<!-- Allocation toggle start-->
													<ng-container
														*ngIf="checkIsAllocationAllowed(workflowBlockData.guid)"
													>
														<div class="col-6">
															<div class="form-group d-flex">
																<div
																	class="custom-control custom-control-primary custom-switch custom-control-inline"
																>
																	<p class="mb-50 mr-1">
																		{{ "UI.k_is_allocation" | translate }}
																	</p>
																	<input
																		type="checkbox"
																		[checked]="workflowBlockData.is_allocation"
																		[(ngModel)]="workflowBlockData.is_allocation"
																		(change)="
																			onAllocationCheck($event.target.checked)
																		"
																		class="custom-control-input"
																		id="swStateAllocation"
																		name="swStateAllocation"
																		[disabled]="
																			options?.assigned_status?.includes(
																				workflowBlockData?.guid
																			)
																		"
																	/>
																	<label
																		class="custom-control-label"
																		for="swStateAllocation"
																	></label>
																</div>
															</div>
														</div>
													</ng-container>
													<!-- Allocation toggle ends-->
													<!-- De-allocation toggle start-->
													<ng-container
														*ngIf="checkIsDeallocationAllowed(workflowBlockData.guid)"
													>
														<div class="col-6">
															<div class="form-group d-flex">
																<div
																	class="custom-control custom-control-primary custom-switch custom-control-inline"
																>
																	<p class="mb-50 mr-1">
																		{{ "UI.k_is_deallocation" | translate }}
																	</p>
																	<input
																		type="checkbox"
																		[checked]="workflowBlockData.is_deallocation"
																		[(ngModel)]="workflowBlockData.is_deallocation"
																		(change)="
																			onDeallocationCheck($event.target.checked)
																		"
																		class="custom-control-input"
																		id="swStateDeAllocation"
																		name="swStateDeAllocation"
																		[disabled]="
																			options?.assigned_status?.includes(
																				workflowBlockData?.guid
																			)
																		"
																	/>
																	<label
																		class="custom-control-label"
																		for="swStateDeAllocation"
																	></label>
																</div>
															</div>
														</div>
													</ng-container>
													<!-- De-allocation toggle ends-->
													<!-- In Stock toggle Starts-->
													<ng-container *ngIf="checkInStock(workflowBlockData.guid)">
														<div class="col-6">
															<div class="form-group d-flex">
																<div
																	class="custom-control custom-control-primary custom-switch custom-control-inline"
																>
																	<p class="mb-50 mr-1">
																		{{ "UI.k_is_in_stock" | translate }}
																	</p>
																	<input
																		type="checkbox"
																		[checked]="workflowBlockData.is_in_stock"
																		[(ngModel)]="workflowBlockData.is_in_stock"
																		(change)="onInStockCheck($event.target.checked)"
																		class="custom-control-input"
																		id="swStateIsInStock"
																		name="swStateIsInStock"
																		[disabled]="
																			options?.assigned_status?.includes(
																				workflowBlockData?.guid
																			)
																		"
																	/>
																	<label
																		class="custom-control-label"
																		for="swStateIsInStock"
																	></label>
																</div>
															</div>
														</div>
													</ng-container>
													<!-- In Stock toggle ends-->
													<!-- Allocation Acceptance toggle Starts-->
													<ng-container
														*ngIf="checkIsAllocationAcceptance(workflowBlockData.guid)"
													>
														<div class="col-6">
															<div class="form-group d-flex">
																<div
																	class="custom-control custom-control-primary custom-switch custom-control-inline"
																>
																	<p class="mb-50 mr-1">
																		{{ "UI.k_allocation_acceptance" | translate }}
																	</p>
																	<input
																		type="checkbox"
																		[checked]="
																			workflowBlockData.is_allocation_acceptance
																		"
																		[(ngModel)]="
																			workflowBlockData.is_allocation_acceptance
																		"
																		(change)="
																			onAllocationAcceptedCheck(
																				$event.target.checked
																			)
																		"
																		class="custom-control-input"
																		id="swStateAllocationAcceptance"
																		name="swStateAllocationAcceptance"
																		[disabled]="
																			options?.assigned_status?.includes(
																				workflowBlockData?.guid
																			)
																		"
																	/>
																	<label
																		class="custom-control-label"
																		for="swStateAllocationAcceptance"
																	></label>
																</div>
															</div>
														</div>
													</ng-container>
													<!-- Allocation Acceptance toggle ends-->
													<!-- Allocation Rejection toggle Starts-->
													<ng-container
														*ngIf="checkIsAllocationRejection(workflowBlockData.guid)"
													>
														<div class="col-6">
															<div class="form-group d-flex">
																<div
																	class="custom-control custom-control-primary custom-switch custom-control-inline"
																>
																	<p class="mb-50 mr-1">
																		{{ "UI.k_on_allocation_rejection" | translate }}
																	</p>
																	<input
																		type="checkbox"
																		[checked]="
																			workflowBlockData.is_allocation_rejection
																		"
																		[(ngModel)]="
																			workflowBlockData.is_allocation_rejection
																		"
																		(change)="
																			onAllocationRejectedCheck(
																				$event.target.checked
																			)
																		"
																		class="custom-control-input"
																		id="swStateAllocationRejection"
																		name="swStateAllocationRejection"
																		[disabled]="
																			options?.assigned_status?.includes(
																				workflowBlockData?.guid
																			)
																		"
																	/>
																	<label
																		class="custom-control-label"
																		for="swStateAllocationRejection"
																	></label>
																</div>
															</div>
														</div>
													</ng-container>
													<!-- Allocation Rejection toggle ends-->
													<!-- Maintenance toggle Starts-->
													<ng-container *ngIf="checkIsMaintenance(workflowBlockData.guid)">
														<div class="col-6">
															<div class="form-group d-flex">
																<div
																	class="custom-control custom-control-primary custom-switch custom-control-inline"
																>
																	<p class="mb-50 mr-1">
																		{{ "UI.k_is_maintenance" | translate }}
																	</p>
																	<input
																		type="checkbox"
																		[checked]="workflowBlockData.is_maintenance"
																		[(ngModel)]="workflowBlockData.is_maintenance"
																		(change)="
																			onMaintenanceCheck($event.target.checked)
																		"
																		class="custom-control-input"
																		id="swStateMaintenance"
																		name="swStateMaintenance"
																		[disabled]="
																			options?.assigned_status?.includes(
																				workflowBlockData?.guid
																			)
																		"
																	/>
																	<label
																		class="custom-control-label"
																		for="swStateMaintenance"
																	></label>
																</div>
															</div>
														</div>
													</ng-container>
													<!-- Allocation Maintenance toggle ends-->
													<ng-container *ngIf="checkIsExitAllowed(workflowBlockData.guid)">
														<div class="col-6">
															<div class="form-group d-flex">
																<div
																	class="custom-control custom-control-primary custom-switch custom-control-inline"
																>
																	<p class="mb-50 mr-1">
																		{{ "UI.k_is_exit" | translate }}
																	</p>
																	<input
																		type="checkbox"
																		[checked]="workflowBlockData.is_end"
																		[(ngModel)]="workflowBlockData.is_end"
																		(change)="
																			onStatusExitCheck($event.target.checked)
																		"
																		class="custom-control-input"
																		[disabled]="
																			options?.assigned_status?.includes(
																				workflowBlockData?.guid
																			)
																		"
																		id="swStateExit"
																		name="swStateExit"
																	/>
																	<label
																		class="custom-control-label"
																		for="swStateExit"
																	></label>
																</div>
															</div>
														</div>
													</ng-container>
													<ng-container *ngIf="checkIsDestroyAllowed(workflowBlockData.guid)">
														<div class="col-6">
															<div class="form-group d-flex">
																<div
																	class="custom-control custom-control-primary custom-switch custom-control-inline"
																>
																	<p class="mb-50 mr-1">
																		{{ "UI.k_is_destroy" | translate }}
																	</p>
																	<input
																		type="checkbox"
																		[checked]="workflowBlockData.is_destroy"
																		[(ngModel)]="workflowBlockData.is_destroy"
																		(change)="
																			onStatusDestroyCheck($event.target.checked)
																		"
																		class="custom-control-input"
																		[disabled]="
																			options?.assigned_status?.includes(
																				workflowBlockData?.guid
																			)
																		"
																		id="swStateDestroy"
																		name="swStateDestroy"
																	/>
																	<label
																		class="custom-control-label"
																		for="swStateDestroy"
																	></label>
																</div>
															</div>
														</div>
													</ng-container>
													<ng-container
														*ngIf="checkIsAutoCloseAllowed(workflowBlockData.guid)"
													>
														<div class="col-6">
															<div class="form-group d-flex">
																<div
																	class="custom-control custom-control-primary custom-switch custom-control-inline"
																>
																	<p class="mb-50 mr-1">
																		{{ "UI.k_is_auto_closure" | translate }}
																	</p>
																	<input
																		type="checkbox"
																		[checked]="workflowBlockData.is_autoclosure"
																		[(ngModel)]="workflowBlockData.is_autoclosure"
																		(change)="
																			onAutoClosureCheck($event.target.checked)
																		"
																		class="custom-control-input"
																		[disabled]="
																			options?.assigned_status?.includes(
																				workflowBlockData?.guid
																			)
																		"
																		id="swStateAutoClosure"
																		name="swStateAutoClosure"
																	/>
																	<label
																		class="custom-control-label"
																		for="swStateAutoClosure"
																	></label>
																</div>
															</div>
														</div>
													</ng-container>
													<!-- PIR BLOCK Starts-->
													<ng-container *ngIf="checkIsPIRAllowed(workflowBlockData?.guid)">
														<div class="col-6">
															<div class="form-group d-flex">
																<div
																	class="custom-control custom-control-primary custom-switch custom-control-inline"
																>
																	<p class="mb-50 mr-1">
																		{{ "UI.k_is_pir" | translate }}
																	</p>
																	<input
																		type="checkbox"
																		[checked]="workflowBlockData.is_pir"
																		[(ngModel)]="workflowBlockData.is_pir"
																		(change)="
																			onPIRStateChange($event.target.checked)
																		"
																		class="custom-control-input"
																		[disabled]="
																			options?.assigned_status?.includes(
																				workflowBlockData?.guid
																			)
																		"
																		id="swPirState"
																		name="swPirState"
																	/>
																	<label
																		class="custom-control-label"
																		for="swPirState"
																	></label>
																</div>
															</div>
														</div>
														<ng-container *ngIf="workflowBlockData.is_pir">
															<div class="col-12">
																<div class="form-group">
																	<ng-container *ngIf="!workflowBlockData?.is_cab">
																		<label
																			>{{
																				"UI.k_review_team" | translate
																			}}
																			*</label
																		>
																		<ng-select
																			[items]="workflowData?.options?.review_team"
																			name="selTeam"
																			id="selTeam"
																			#selTeam="ngModel"
																			[clearable]="false"
																			[required]="true"
																			[placeholder]="'UI.k_sel_team' | translate"
																			[bindLabel]="'name'"
																			[(ngModel)]="workflowBlockData.team"
																			[disabled]="
																				options?.assigned_status?.includes(
																					workflowBlockData?.guid
																				)
																			"
																		></ng-select>
																		<dns-error-validation
																			[formRef]="setStatusForm"
																			[uiRef]="selTeam"
																		></dns-error-validation>
																	</ng-container>

																	<span class="text-muted">{{
																		"UI.k_note_team_member" | translate
																	}}</span>
																</div>
															</div>
															<!--																<div class="col-12 pl-0 pr-0">-->
															<!--																	<div-->
															<!--																		class="custom-control custom-control-primary custom-switch custom-control-inline"-->
															<!--																	>-->
															<!--																		<p class="mb-50 mr-1">-->
															<!--																			{{ "UI.k_is_review_required" | translate }}-->
															<!--																		</p>-->
															<!--																		<input-->
															<!--																			type="checkbox"-->
															<!--																			[checked]="-->
															<!--																				workflowBlockData.is_review_required-->
															<!--																			"-->
															<!--																			[(ngModel)]="-->
															<!--																				workflowBlockData.is_review_required-->
															<!--																			"-->
															<!--																			(change)="-->
															<!--																				onReviewRequiredChange(-->
															<!--																					$event.target.checked-->
															<!--																				)-->
															<!--																			"-->
															<!--																			class="custom-control-input"-->
															<!--																			[disabled]="-->
															<!--																				options?.assigned_status?.includes(-->
															<!--																					workflowBlockData?.guid-->
															<!--																				)-->
															<!--																			"-->
															<!--																			id="swReviewRequiredState"-->
															<!--																			name="swReviewRequiredState"-->
															<!--																		/>-->
															<!--																		<label-->
															<!--																			class="custom-control-label"-->
															<!--																			for="swReviewRequiredState"-->
															<!--																		></label>-->
															<!--																	</div>-->
															<!--																</div>-->
														</ng-container>
													</ng-container>
													<!-- PIR BLOCK ENDS-->
													<div class="col-12" *ngIf="!workflowBlockData?.is_end">
														<div class="form-group">
															<app-status-picker
																[inputConfig]="{
																	bindValue: 'guid',
																	multiple: true,
																	header: 'UI.k_transition_state',
																	isEditDisabled:
																		!isEnableTransitionState ||
																		options?.assigned_status?.includes(
																			workflowBlockData?.guid
																		),
																	isTextMode: true
																}"
																[exclude_option]="[this.workflowBlockData?.guid]"
																exclude_option_compare_key="guid"
																isclearable="true"
																(setStatus)="setUiTransitionState($event)"
																[options]="workflowData?.draft_flow?.status"
																[value]="workflowBlockData.transition_states"
															></app-status-picker>
														</div>
													</div>
													<!-- Resolve Notification BLOCK Starts-->
													<ng-container
														*ngIf="
															workflowBlockData.state_id == 4 &&
															RESOLUTION_NOTIFICATION_MODULE.includes(
																workflowData?.module_id
															)
														"
													>
														<div class="col-6">
															<div class="form-group d-flex">
																<div
																	class="custom-control custom-control-primary custom-switch custom-control-inline"
																>
																	<p class="mb-50 mr-1">
																		{{ "UI.k_resolve_notification" | translate }}
																		<span
																			placement="right"
																			ngbTooltip="{{
																				'UI.k_resolve_notify_info' | translate
																			}}"
																		>
																			<i
																				class="fa-light fa-circle-exclamation"
																			></i>
																		</span>
																	</p>
																	<input
																		type="checkbox"
																		[checked]="
																			workflowBlockData.is_resolve_notify_required
																		"
																		[(ngModel)]="
																			workflowBlockData.is_resolve_notify_required
																		"
																		(change)="
																			onResolveNotifyChange($event.target.checked)
																		"
																		class="custom-control-input"
																		[disabled]="
																			options?.assigned_status?.includes(
																				workflowBlockData?.guid
																			)
																		"
																		id="swResolveNotify"
																		name="swResolveNotify"
																	/>
																	<label
																		class="custom-control-label"
																		for="swResolveNotify"
																	></label>
																</div>
															</div>
														</div>
													</ng-container>
													<!-- Resolve Notification BLOCK ENDS-->
												</div>
											</ng-scrollbar>
										</div>
										<div class="flex-initial p-1 border-top">
											<div class="justify-content-end d-flex">
												<button
													class="btn btn-primary"
													type="submit"
													name="btn_save_{{ workflowTemplateType.CONFIG_STATUS }}"
													id="btn_save_{{ workflowTemplateType.CONFIG_STATUS }}"
													[disabled]="
														options?.assigned_status?.includes(workflowBlockData?.guid)
													"
												>
													{{ "UI.k_save" | translate }}
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</ng-template>
					</li>
				</ul>
				<div [ngbNavOutlet]="navFilled" class="mt-2"></div>
			</ng-container>
			<!-- Template for config status ends -->

			<!-- Template for approval status start -->
			<ng-container *ngSwitchCase="workflowTemplateType.ASK_FOR_APPROVAL">
				<ul ngbNav #navFilled="ngbNav" class="nav-tabs nav-fill">
					<li ngbNavItem>
						<a ngbNavLink>{{ "UI.k_details" | translate }}</a>
						<ng-template ngbNavContent>
							<div class="container-fluid full-height">
								<form
									#setStatusForm="ngForm"
									name="setStateStatus"
									class="full-height"
									(ngSubmit)="onSaveStatus(setStatusForm)"
									novalidate
								>
									<div class="flex-auto-height">
										<div class="flex-1">
											<ng-scrollbar class="custom-scrollbar section-scrollbar">
												<ngb-alert
													[type]="'warning'"
													[dismissible]="false"
													*ngIf="options?.assigned_status?.includes(workflowBlockData?.guid)"
												>
													<div class="alert-body">
														{{
															"Message.msg_status_in_use"
																| translate
																	: {
																			process:
																				module_map_config[
																					workflowData.module_id
																				]
																	  }
														}}
													</div>
												</ngb-alert>
												<div class="row">
													<div class="col-6">
														<div class="form-group">
															<label>{{ "UI.k_state" | translate }}</label>
															<ng-select
																[items]="options.state"
																bindLabel="name"
																name="state"
																placeholder="{{ 'UI.k_select_state' | translate }}"
																[readonly]="true"
																[(ngModel)]="workflowBlockData.state"
															>
																<ng-template ng-label-tmp let-item="item">
																	<span>{{ item.name | translate }}</span>
																</ng-template>
															</ng-select>
														</div>
													</div>
													<div class="col-6">
														<div class="form-group">
															<label>{{ "UI.k_status" | translate }}</label>
															<input
																type="text"
																[(ngModel)]="workflowBlockData.status"
																name="status"
																required
																#TDStatusNameRef="ngModel"
																[disabled]="
																	options?.assigned_status?.includes(
																		workflowBlockData?.guid
																	)
																"
																[class.error]="
																	is_form_submitted &&
																	setStatusForm.submitted &&
																	!TDStatusNameRef.valid
																"
																class="form-control"
																placeholder="{{
																	'UI.k_wf_status_config_placeholder' | translate
																}}"
																maxlength="40"
																[appWhiteSpaceCheck]="workflowBlockData.status"
																[ngbTypeahead]="searchStatus"
																[resultFormatter]="statusResultFormatter"
																[inputFormatter]="statusResultFormatter"
																(selectItem)="onSearchStatusSelect($event)"
																[ngStyle]="{
																	color: workflowBlockData?.color
																}"
															/>
															<span
																class="error text-danger"
																*ngIf="
																	setStatusForm.submitted &&
																	workflowBlockData.is_error
																"
																>{{ "UI.k_status_already_exist" | translate }}</span
															>
															<span
																*ngIf="
																	is_form_submitted &&
																	setStatusForm.submitted &&
																	TDStatusNameRef.invalid
																"
																class="invalid-form"
															>
																<small
																	*ngIf="TDStatusNameRef?.errors?.required"
																	class="form-text text-danger"
																	>{{ "UI.k_field_required" | translate }}</small
																>
																<small
																	*ngIf="TDStatusNameRef.errors.maxlength"
																	class="form-text text-danger"
																	>{{
																		"Error.err_input_max_char"
																			| translate: { max_length: 40 }
																	}}</small
																>
																<small
																	*ngIf="TDStatusNameRef.errors?.whitespace"
																	class="form-text text-danger"
																	>{{ "Error.err_ltspace" | translate }}</small
																>
															</span>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-6">
														<label>{{ "UI.k_select_status_color" | translate }}</label>
														<div class="form-group d-flex">
															<input
																type="color"
																class="form-control"
																[(ngModel)]="workflowBlockData.color"
																name="wfStatusColor"
																[disabled]="
																	options?.assigned_status?.includes(
																		workflowBlockData?.guid
																	)
																"
																[value]="workflowBlockData.color"
																[list]="defaultStatusColor"
															/>
														</div>
													</div>
													<ng-container
														*ngIf="AUTO_SUBMIT_MODULE.includes(workflowData?.module_id)"
													>
														<div class="col-6">
															<div class="form-group d-flex">
																<div
																	class="custom-control custom-control-primary custom-switch custom-control-inline"
																>
																	<p class="mb-50 mr-1">
																		{{ "UI.k_auto_submit" | translate }}
																	</p>
																	<input
																		type="checkbox"
																		[checked]="workflowBlockData.is_auto_submit"
																		[(ngModel)]="workflowBlockData.is_auto_submit"
																		(change)="
																			onStatusAutoSubmit($event.target.checked)
																		"
																		class="custom-control-input"
																		[disabled]="
																			options?.assigned_status?.includes(
																				workflowBlockData?.guid
																			)
																		"
																		id="swStateNext"
																		name="swStateNext"
																	/>
																	<label
																		class="custom-control-label"
																		for="swStateNext"
																	></label>
																</div>
															</div>
														</div>
													</ng-container>
													<div class="col-12" *ngIf="!workflowBlockData?.is_end">
														<div class="form-group">
															<app-status-picker
																[inputConfig]="{
																	bindValue: 'guid',
																	multiple: true,
																	header: 'UI.k_transition_state',
																	isEditDisabled: true,
																	isTextMode: true
																}"
																[exclude_option]="[this.workflowBlockData?.guid]"
																exclude_option_compare_key="guid"
																isclearable="true"
																(setStatus)="setUiTransitionState($event)"
																[options]="workflowData?.draft_flow?.status"
																[value]="workflowBlockData.transition_states"
															></app-status-picker>
														</div>
													</div>
													<h5 class="mb-1 mt-2 ml-1">
														{{ "UI.k_approval_configuration" | translate }}
													</h5>
													<ng-container *ngIf="workflowData?.module_id == 47">
														<div class="col-12 mb-1">
															<div
																class="btn-group btn-group-toggle col-12 px-0"
																ngbRadioGroup
																name="radioBasic"
																[(ngModel)]="workflowBlockData.is_cab"
																(change)="onTeamChange($event)"
															>
																<label
																	ngbButtonLabel
																	class="btn-outline-primary"
																	rippleEffect
																	><input ngbButton type="radio" [value]="false" />{{
																		"UI.k_approval" | translate
																	}}
																</label>
																<label
																	ngbButtonLabel
																	class="btn-outline-primary"
																	rippleEffect
																	><input ngbButton type="radio" [value]="true" />{{
																		"UI.k_is_cab_approval" | translate
																	}}</label
																>
															</div>
														</div>
													</ng-container>
													<ng-container
														*ngIf="
															REPORTING_MANAGER_MODULE.includes(workflowData?.module_id)
														"
													>
														<div class="col-12">
															<div class="form-group d-flex">
																<div
																	class="custom-control custom-control-primary custom-switch custom-control-inline"
																>
																	<p class="mb-50 mr-1">
																		{{
																			"UI.k_send_to_reporting_manager" | translate
																		}}
																		<span
																			placement="right"
																			ngbTooltip="{{
																				'UI.k_workflow_approval_info'
																					| translate
																			}}"
																		>
																			<i
																				class="fa-light fa-circle-exclamation"
																			></i>
																		</span>
																	</p>
																	<input
																		type="checkbox"
																		[checked]="
																			workflowBlockData.is_reporting_manager
																		"
																		[(ngModel)]="
																			workflowBlockData.is_reporting_manager
																		"
																		(change)="
																			onReportingSubmit($event.target.checked)
																		"
																		class="custom-control-input"
																		[disabled]="
																			options?.assigned_status?.includes(
																				workflowBlockData?.guid
																			)
																		"
																		id="swStateReportingManager"
																		name="swStateReportingManager"
																	/>
																	<label
																		class="custom-control-label"
																		for="swStateReportingManager"
																	></label>
																</div>
															</div>
														</div>
													</ng-container>
													<ng-container *ngIf="!workflowBlockData.is_reporting_manager">
														<div class="col-12">
															<div class="form-group">
																<ng-container *ngIf="workflowBlockData?.is_cab">
																	<label>{{ "UI.k_cab_team" | translate }}*</label>
																	<ng-select
																		[items]="workflowData?.options?.cab_team"
																		name="selTeam"
																		id="selTeam"
																		#selTeam="ngModel"
																		[required]="true"
																		[clearable]="false"
																		[placeholder]="'UI.k_sel_team' | translate"
																		[bindLabel]="'name'"
																		[(ngModel)]="workflowBlockData.team"
																	></ng-select>
																	<dns-error-validation
																		[formRef]="setStatusForm"
																		[uiRef]="selTeam"
																	></dns-error-validation>
																</ng-container>
																<ng-container *ngIf="!workflowBlockData?.is_cab">
																	<label>{{ "UI.k_team" | translate }}*</label>
																	<ng-select
																		[items]="workflowData?.options?.teams"
																		name="selTeam"
																		id="selTeam"
																		#selTeam="ngModel"
																		[clearable]="false"
																		[required]="true"
																		[placeholder]="'UI.k_sel_team' | translate"
																		[bindLabel]="'name'"
																		[(ngModel)]="workflowBlockData.team"
																	></ng-select>
																	<dns-error-validation
																		[formRef]="setStatusForm"
																		[uiRef]="selTeam"
																	></dns-error-validation>
																</ng-container>

																<span class="text-muted">{{
																	"UI.k_note_team_member" | translate
																}}</span>
															</div>
														</div>
													</ng-container>
													<h5 class="mb-1 mt-2 ml-1">
														{{ "UI.k_email_approval_template" | translate }}
													</h5>
													<div class="col-12">
														<div class="flex-fill">
															<div class="d-flex mb-1 mt-1">
																<label
																	for="emailSubject"
																	class="form-label mr-50 pt-50"
																	style="display: flex; align-items: center"
																	>{{ "UI.k_subject" | translate
																	}}<span class="required-field">&nbsp;*</span>
																</label>
																<input
																	type="text"
																	(focus)="onFieldFocus('subject')"
																	[(ngModel)]="workflowBlockData.subject"
																	id="emailApprovalSubject"
																	#TDFApprovalSubject="ngModel"
																	class="form-control font-small-2"
																	placeholder="{{ 'UI.k_subject' | translate }}"
																	name="emailSubject"
																	maxlength="1000"
																	(ngModelChange)="emailSubjectEvent($event)"
																	required
																/>
																<ng-container
																	*ngIf="
																		(workflowBlockData.subject &&
																			workflowBlockData.subject.indexOf('{{') >
																				-1 &&
																			this.showMacroSection === true) ||
																		(this.workflowBlockData['quillText'] &&
																			this.showMacroSection === true)
																	"
																>
																	<app-macros-modal
																		class="position-absolute"
																		[inputConfig]="{
																			options: approvalEmailMacrosList,
																			currentEdit:
																				this.emailEdit === true
																					? 'emailEdit'
																					: 'quillEdit',
																			display_key: 'label'
																		}"
																		(macrosEmitEvent)="macrosSelectEvent($event)"
																	></app-macros-modal>
																</ng-container>
															</div>
															<span
																class="error"
																*ngIf="
																	setStatusForm.submitted &&
																	TDFApprovalSubject.invalid
																"
															>
																<small *ngIf="TDFApprovalSubject.errors.required">{{
																	"UI.k_field_required" | translate
																}}</small>
															</span>
															<div class="p-0 card border">
																<quill-editor
																	[(ngModel)]="workflowBlockData.message_body"
																	id="articleApprovalNote"
																	name="articleNote"
																	customToolbarPosition="'bottom'"
																	class="border-bottom-0"
																	(onFocus)="onFieldFocus('message_body')"
																	placeholder="{{ 'UI.k_message_body' | translate }}"
																	[required]="true"
																	#qlEmailApprovalBody
																	[trimOnValidation]="true"
																	[appQuillValidator]="qlEmailApprovalBody"
																	[appQuillForm]="setStatusForm"
																	(onContentChanged)="
																		getEnrichTextData(
																			$event,
																			qlEmailApprovalBody?.errors
																		)
																	"
																>
																	<div
																		quill-editor-toolbar
																		class="border-0 border-bottom px-0 py-50"
																	>
																		<div class="border-bottom p-0">
																			<span class="ql-formats pb-50">
																				<button
																					id="qlBoldNoteEmail-Inc"
																					name="qlBold"
																					class="ql-bold"
																					title="{{
																						'UI.k_bold' | translate
																					}}"
																				></button>
																				<button
																					id="qlItalicNoteEmail-Inc"
																					name="qlItalic"
																					class="ql-italic"
																					title="{{
																						'UI.k_italic' | translate
																					}}"
																				></button>
																				<button
																					id="qlUnderlineNoteEmail-Inc"
																					name="qlUnderline"
																					class="ql-underline"
																					title="{{
																						'UI.k_underline' | translate
																					}}"
																				></button>
																			</span>
																			<span class="ql-formats">
																				<select
																					id="qlAlignNote"
																					class="ql-align"
																					title="{{
																						'UI.k_alignment' | translate
																					}}"
																				>
																					<option selected></option>
																					<option value="center"></option>
																					<option value="right"></option>
																					<option value="justify"></option>
																				</select>
																			</span>
																			<span class="ql-formats">
																				<button
																					id="btnqlListNote"
																					name="qlList"
																					class="ql-list"
																					value="ordered"
																					type="button"
																				></button>
																			</span>
																			<span class="ql-formats">
																				<button
																					id="btnqlCleanNoteEmail-Inc"
																					class="ql-clean"
																					value="ordered"
																					type="button"
																				></button>
																			</span>
																		</div>
																	</div>
																	<div quill-editor-element *ngIf="isMacro">
																		<div class="form-group">
																			test
																			<!-- Select Group by item -->
																			<!-- <ng-select [items]="this.emailConfig.macrosOption" bindLabel="name" bindValue="name" groupBy="Asset"
																			  [(ngModel)]="this.emailConfig.selectedMacro">
																			  <ng-template ng-optgroup-tmp let-item="item">
																				{{ item.name }}
																			  </ng-template>
																			</ng-select> -->
																			<br />
																		</div>
																	</div>
																</quill-editor>
																<span
																	class="error"
																	*ngIf="
																		setStatusForm.submitted && qlEmailBody?.errors
																	"
																>
																	<small
																		*ngIf="
																			qlEmailApprovalBody?.errors?.requiredError
																				?.empty
																		"
																	>
																		{{ "UI.k_field_required" | translate }}
																	</small>
																</span>
																<div
																	class="compose-footer-wrapper email-footer mac-foot"
																>
																	<div class="btn-wrapper d-flex align-items-center">
																		{{ "UI.k_add_macros_text" | translate }}
																	</div>
																	<br />
																</div>
															</div>
														</div>
													</div>
												</div>
											</ng-scrollbar>
										</div>
										<div class="flex-initial p-1 border-top">
											<div class="justify-content-end d-flex">
												<button
													class="btn btn-primary"
													type="submit"
													name="btn_save_{{ workflowTemplateType.ASK_FOR_APPROVAL }}"
													id="btn_save_{{ workflowTemplateType.ASK_FOR_APPROVAL }}"
													[disabled]="
														options?.assigned_status?.includes(workflowBlockData?.guid)
													"
												>
													{{ "UI.k_save" | translate }}
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</ng-template>
					</li>
				</ul>
				<div [ngbNavOutlet]="navFilled" class="mt-2"></div>
			</ng-container>
			<!-- Template for approval status ends -->

			<!-- Template for review status start -->
			<ng-container *ngSwitchCase="workflowTemplateType.ASK_FOR_REVIEW">
				<ul ngbNav #navFilled="ngbNav" class="nav-tabs nav-fill">
					<li ngbNavItem>
						<a ngbNavLink>{{ "UI.k_details" | translate }}</a>
						<ng-template ngbNavContent>
							<div class="container-fluid full-height">
								<form
									#setStatusForm="ngForm"
									name="setStateStatus"
									class="full-height"
									(ngSubmit)="onSaveStatus(setStatusForm)"
									novalidate
								>
									<div class="flex-auto-height">
										<div class="flex-1">
											<ng-scrollbar class="custom-scrollbar section-scrollbar">
												<ngb-alert
													[type]="'warning'"
													[dismissible]="false"
													*ngIf="options?.assigned_status?.includes(workflowBlockData?.guid)"
												>
													<div class="alert-body">
														{{
															"Message.msg_status_in_use"
																| translate
																	: {
																			process:
																				module_map_config[
																					workflowData.module_id
																				]
																	  }
														}}
													</div>
												</ngb-alert>
												<div class="row">
													<div class="col-6">
														<div class="form-group">
															<label>{{ "UI.k_state" | translate }}</label>
															<ng-select
																[items]="options.state"
																bindLabel="name"
																name="state"
																placeholder="{{ 'UI.k_select_state' | translate }}"
																[readonly]="true"
																[(ngModel)]="workflowBlockData.state"
															>
																<ng-template ng-label-tmp let-item="item">
																	<span>{{ item.name | translate }}</span>
																</ng-template>
															</ng-select>
														</div>
													</div>
													<div class="col-6">
														<div class="form-group">
															<label>{{ "UI.k_status" | translate }}</label>
															<input
																type="text"
																[(ngModel)]="workflowBlockData.status"
																name="status"
																required
																#TDStatusNameRef="ngModel"
																[disabled]="
																	options?.assigned_status?.includes(
																		workflowBlockData?.guid
																	)
																"
																[class.error]="
																	is_form_submitted &&
																	setStatusForm.submitted &&
																	!TDStatusNameRef.valid
																"
																class="form-control"
																placeholder="{{
																	'UI.k_wf_status_config_placeholder' | translate
																}}"
																maxlength="40"
																[appWhiteSpaceCheck]="workflowBlockData.status"
																[ngbTypeahead]="searchStatus"
																[resultFormatter]="statusResultFormatter"
																[inputFormatter]="statusResultFormatter"
																(selectItem)="onSearchStatusSelect($event)"
																[ngStyle]="{
																	color: workflowBlockData?.color
																}"
															/>
															<span
																class="error text-danger"
																*ngIf="
																	setStatusForm.submitted &&
																	workflowBlockData.is_error
																"
																>{{ "UI.k_status_already_exist" | translate }}</span
															>
															<span
																*ngIf="
																	is_form_submitted &&
																	setStatusForm.submitted &&
																	TDStatusNameRef.invalid
																"
																class="invalid-form"
															>
																<small
																	*ngIf="TDStatusNameRef?.errors?.required"
																	class="form-text text-danger"
																	>{{ "UI.k_field_required" | translate }}</small
																>
																<small
																	*ngIf="TDStatusNameRef.errors.maxlength"
																	class="form-text text-danger"
																	>{{
																		"Error.err_input_max_char"
																			| translate: { max_length: 40 }
																	}}</small
																>
																<small
																	*ngIf="TDStatusNameRef.errors?.whitespace"
																	class="form-text text-danger"
																	>{{ "Error.err_ltspace" | translate }}</small
																>
															</span>
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-6">
														<label>{{ "UI.k_select_status_color" | translate }}</label>
														<div class="form-group d-flex">
															<input
																type="color"
																class="form-control"
																[(ngModel)]="workflowBlockData.color"
																name="wfStatusColor"
																[disabled]="
																	options?.assigned_status?.includes(
																		workflowBlockData?.guid
																	)
																"
																[value]="workflowBlockData.color"
																[list]="defaultStatusColor"
															/>
														</div>
													</div>
													<div class="col-12" *ngIf="!workflowBlockData?.is_end">
														<div class="form-group">
															<app-status-picker
																[inputConfig]="{
																	bindValue: 'guid',
																	multiple: true,
																	header: 'UI.k_transition_state',
																	isEditDisabled: true,
																	isTextMode: true
																}"
																[exclude_option]="[this.workflowBlockData?.guid]"
																exclude_option_compare_key="guid"
																isclearable="true"
																(setStatus)="setUiTransitionState($event)"
																[options]="workflowData?.draft_flow?.status"
																[value]="workflowBlockData.transition_states"
															></app-status-picker>
														</div>
													</div>
													<div class="col-12">
														<div class="form-group">
															<ng-container *ngIf="!workflowBlockData?.is_cab">
																<label>{{ "UI.k_team" | translate }}*</label>
																<ng-select
																	[items]="workflowData?.options?.review_team"
																	name="selTeam"
																	id="selTeam"
																	#selTeam="ngModel"
																	[clearable]="false"
																	[required]="true"
																	[placeholder]="'UI.k_sel_team' | translate"
																	[bindLabel]="'name'"
																	[(ngModel)]="workflowBlockData.team"
																></ng-select>
																<dns-error-validation
																	[formRef]="setStatusForm"
																	[uiRef]="selTeam"
																></dns-error-validation>
															</ng-container>

															<span class="text-muted">{{
																"UI.k_note_team_member" | translate
															}}</span>
														</div>
													</div>
												</div>
											</ng-scrollbar>
										</div>
										<div class="flex-initial p-1 border-top">
											<div class="justify-content-end d-flex">
												<button
													class="btn btn-primary"
													type="submit"
													name="btn_save_{{ workflowTemplateType.ASK_FOR_REVIEW }}"
													id="btn_save_{{ workflowTemplateType.ASK_FOR_REVIEW }}"
													[disabled]="
														options?.assigned_status?.includes(workflowBlockData?.guid)
													"
												>
													{{ "UI.k_save" | translate }}
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</ng-template>
					</li>
				</ul>
				<div [ngbNavOutlet]="navFilled" class="mt-2"></div>
			</ng-container>
			<!-- Template for review status ends -->

			<!-- Template for set status start -->
			<ng-container *ngSwitchCase="workflowTemplateType.SET_STATUS">
				<ul ngbNav #navFilled="ngbNav" class="nav-tabs nav-fill">
					<li ngbNavItem>
						<a ngbNavLink>{{ "UI.k_details" | translate }}</a>
						<ng-template ngbNavContent>
							<div class="container-fluid full-height">
								<form
									#transferStateStatusForm="ngForm"
									name="setStateStatus"
									class="full-height"
									(ngSubmit)="onSaveStateStatus(transferStateStatusForm)"
									novalidate
								>
									<div class="flex-auto-height">
										<div class="flex-1">
											<ng-scrollbar class="custom-scrollbar section-scrollbar">
												<div class="row">
													<div class="col-6">
														<div class="form-group">
															<label>{{ "UI.k_state" | translate }}*</label>
															<ng-select
																[items]="options.state"
																bindLabel="name"
																name="state"
																placeholder="{{ 'UI.k_select_state' | translate }}"
																notFoundText="{{ 'UI.k_no_items_found' | translate }}"
																[(ngModel)]="workflowBlockData.state"
																#TDRstate="ngModel"
																required
																(change)="onStateSelection($event)"
															></ng-select>
															<span
																*ngIf="
																	transferStateStatusForm.submitted &&
																	TDRstate.invalid
																"
																class="invalid-form"
															>
																<small
																	class="form-text text-danger"
																	*ngIf="TDRstate.errors.required"
																	>{{ "UI.k_field_required" | translate }}</small
																>
															</span>
														</div>
													</div>
													<div class="col-6">
														<div class="form-group">
															<label>{{ "UI.k_status" | translate }}*</label>
															<ng-select
																[items]="selectedStateStatus"
																bindLabel="name"
																name="status"
																(change)="onStatusSelectAction($event)"
																placeholder="{{ 'UI.k_select_status' | translate }}"
																notFoundText="{{ 'UI.k_no_items_found' | translate }}"
																required
																#TDRstatus="ngModel"
																[(ngModel)]="workflowBlockData.status"
															></ng-select>
															<span
																*ngIf="
																	transferStateStatusForm.submitted &&
																	TDRstatus.invalid
																"
																class="invalid-form"
															>
																<small
																	class="form-text text-danger"
																	*ngIf="TDRstatus.errors.required"
																	>{{ "UI.k_field_required" | translate }}</small
																>
															</span>
														</div>
													</div>
												</div>
											</ng-scrollbar>
										</div>
										<div class="flex-initial p-1 border-top">
											<div class="justify-content-end d-flex">
												<button class="btn btn-primary" type="submit">
													{{ "UI.k_save" | translate }}
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</ng-template>
					</li>
				</ul>
				<div [ngbNavOutlet]="navFilled" class="mt-2 full-height"></div>
			</ng-container>
			<!-- Template for set status ends -->
		</div>
	</form>
</dns-sidebar>
<dns-sidebar
	dnsTitle="Configure Status Field "
	sidebarName="configSlider"
	sidebarSize="small"
	(close)="togglePanel('configSlider')"
>
	<status-config></status-config>
</dns-sidebar>
