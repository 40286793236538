<div>
	<div class="row" *ngFor="let res of result; let i = index" [attr.data-index]="i" @heightIn>
		<div class="d-flex justify-content-end col-md-12 col-sm-12 col-xs-12" *ngIf="is_add_button">
			<span class="cursor-pointer" (click)="addRemove(i)">
				<i
					class="fa-light fa-plus-circle text-primary"
					tooltipClass="fadeInAnimation"
					placement="left"
					ngbTooltip="{{ 'UI.k_add' | translate }}"
					*ngIf="i == 0"
				></i>
				<i
					class="fa-light fa-minus-circle text-danger"
					tooltipClass="fadeInAnimation"
					placement="left"
					ngbTooltip="{{ 'UI.k_delete' | translate }}"
					*ngIf="i > 0"
				></i>
			</span>
		</div>
		<div
			class="col-xs-12 {{ isShowEqualsBox ? 'col-md-3' : 'col-md-4' }}"
			[ngClass]="{
				'col-md-3': isShowEqualsBox && isRelation,
				'col-md-4': !isShowEqualsBox && isRelation,
				'col-md-6': !isShowEqualsBox && !isRelation
			}"
		>
			<div class="form-group">
				<label>
					{{ "UI.k_operand" | translate }}
				</label>
				<ng-select
					[items]="conditions"
					[closeOnSelect]="true"
					[searchable]="true"
					bindLabel="name"
					bindValue="macro"
					name="ngSelectSource{{ i }}"
					id="ngSelectSource{{ i }}"
					notFoundText="{{ 'UI.k_no_items_found' | translate }}"
					(ngModelChange)="onChange($event, i, 'operand', res)"
					placeholder="{{ 'UI.k_select_operand_placeholder' | translate }}"
					[(ngModel)]="res.operand"
					[clearable]="false"
					hideSelected="true"
				></ng-select>
			</div>
		</div>
		<div
			class="col-md-3 col-sm-3 col-xs-12"
			*ngIf="isShowEqualsBox"
			[ngClass]="{
				'col-md-3': isShowEqualsBox && isRelation,
				'col-md-4': !isShowEqualsBox && isRelation,
				'col-md-6': !isShowEqualsBox && !isRelation
			}"
		>
			<div class="form-group">
				<label>
					{{ "UI.k_operator" | translate }}
				</label>
				<ng-select
					[items]="getTextOptions()"
					[closeOnSelect]="true"
					[searchable]="true"
					bindLabel="name"
					bindValue="value"
					name="ngSelectSource{{ i }}"
					id="ngSelectAssignment{{ i }}"
					placeholder="{{ 'UI.k_select_operator_placeholder' | translate }}"
					notFoundText="{{ 'UI.k_no_items_found' | translate }}"
					[(ngModel)]="res.comparison_operator"
					hideSelected="true"
				></ng-select>
			</div>
		</div>
		<div
			class="col-xs-12 {{ isShowEqualsBox ? 'col-md-4' : 'col-md-5' }}"
			[ngClass]="{
				'col-md-4': isShowEqualsBox && isRelation,
				'col-md-5': !isShowEqualsBox && isRelation,
				'col-md-6': !isShowEqualsBox && !isRelation
			}"
		>
			<div class="form-group">
				<dns-option-picker
					[inputConfig]="{
						isTextMode: true,
						bindLabel: conditionsOptions['rowConditions' + i]?.bindKey || 'name',
						bindValue: conditionsOptions['rowConditions' + i]?.bindValue || 'id',
						notFoundText: ('UI.k_no_items_found' | translate),
						id: 'ngSelectCondition' + i,
						header: ('UI.k_value' | translate),
						name: 'ngSelectCondition' + i,
						is_multiple: conditionsOptions['rowConditions' + i]?.is_multiple || false
					}"
					[options]="conditionsOptions['rowConditions' + i]?.options"
					[isclearable]="true"
					(setValue)="onChange($event, i, 'value', res)"
					[value]="res.value"
					[dropdownPos]="conditionsOptions['rowConditions' + i]?.dropdownPos"
				></dns-option-picker>
			</div>
		</div>

		<div class="col-xs-12" [ngClass]="!isShowEqualsBox && isRelation ? 'col-md-3' : 'col-md-2'" *ngIf="isRelation">
			<div class="form-group">
				<label>{{ "UI.k_relation" | translate }}</label>
				<ng-select
					[disabled]="result?.length - 1 == i"
					[items]="[
						{ name: 'AND', value: 'and' },
						{ name: 'OR', value: 'or' }
					]"
					[closeOnSelect]="true"
					bindLabel="name"
					bindValue="value"
					id="ngSelectRelation{{ i }}"
					(change)="onChange($event, i, 'logical_operator', res)"
					placeholder="{{ 'UI.k_select_relation_placeholder' | translate }}"
					notFoundText="{{ 'UI.k_no_items_found' | translate }}"
					[(ngModel)]="res.logical_operator"
				></ng-select>
			</div>
		</div>
	</div>
</div>
