import { Component, Input, OnInit } from "@angular/core";
import { NgEventBus } from "ng-event-bus";

@Component({
  selector: 'app-event-count',
  templateUrl: './event-count.component.html',
  styleUrls: ['./event-count.component.scss']
})
export class EventCountComponent implements OnInit {

  @Input() widgetData: any;
	@Input() modifiedtime: any;
	config: any;
	constructor(
		private eventBus: NgEventBus
	) {}

	ngOnInit(): void {
		this.config = this.widgetData?.data
    console.log("widgetdata --- > ",this.widgetData)
		// console.log("config", this.config);

	}

	showInciSidebar(dat){
		if (dat?.count > 0 && dat?.item_filter){
			let payload = {
				"title": "Event - " + dat.name,
				"app_type": "event-grid",
				"item_filter": dat.item_filter,
			}
			// console.log("in inci count widget -> ", payload)
			this.eventBus.cast('dashboard-view:sidebar', payload);
		}
	}
}
