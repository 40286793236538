import { Component, OnInit, Input } from '@angular/core';
import { NgEventBus } from "ng-event-bus";

@Component({
  selector: 'app-change-count',
  templateUrl: './change-count.component.html',
  styleUrls: ['./change-count.component.scss']
})
export class ChangeCountComponent implements OnInit {
  @Input() widgetData: any;
	@Input() modifiedtime: any;
	config: any;

  constructor(
    private eventBus: NgEventBus
  ) { }

  ngOnInit(): void {
    this.config = this.widgetData?.data
		// console.log("config", this.config);

  }
  showChangeSidebar(dat){
		if (dat?.count > 0 && dat?.item_filter){
			let payload = {
				"title": "Changes - " + dat.name,
				"app_type": "change-grid",
				"item_filter": dat.item_filter,
			}
			// console.log("in inci count widget -> ", payload)
			this.eventBus.cast('dashboard-view:sidebar', payload);
		}
	}

}
