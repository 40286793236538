import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CoreSidebarModule } from "@core/components";
import { CoreDirectivesModule } from "@core/directives/directives";
import { UsersRoutingModule } from "./users-routing.module";
import { CoreCommonModule } from "@core/common.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
//import { SharedModule } from "../../shared.module";
import { UsersComponent } from "./components/users-grid/users-grid.component";
import { UserProfileComponent } from "./components/user/user.component";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { FileUploadModule } from "ng2-file-upload";
import { InviteUsersComponent } from "./components/invite-users/invite-users.component";
import { InviteUserGridComponent } from "./components/invite-user-grid/invite-user-grid.component";
import { UsersCardComponent } from "./components/users-card/users-card.component";
import { ToastrModule } from "ngx-toastr";
// import { InfiniteScrollModule } from "ngx-infinite-scroll";
//import { ContentHeaderModule } from "../../common/layout/components/content-header/content-header.module";
import { NavbarModule } from "../../common/layout/components/navbar/navbar.module";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { NgScrollbarModule } from "ngx-scrollbar";
import { ProfileAvatarModule } from "app/common/dns/components/profile-avatar/profile-avatar.module";
import { TranslateModule } from "@ngx-translate/core";
import { DnsModule } from "app/common/dns/dns.module";
@NgModule({
	declarations: [
		UsersComponent,
		UserProfileComponent,
		InviteUsersComponent,
		InviteUserGridComponent,
		UsersCardComponent,
	],
	imports: [
		CommonModule,
		UsersRoutingModule,
		NavbarModule,
		CoreCommonModule,
		CoreSidebarModule,
		CoreDirectivesModule,
		NgbModule,
		NgSelectModule,
		FormsModule,
		//SharedModule,
		SweetAlert2Module.forRoot(),
		FileUploadModule,
		// InfiniteScrollModule,
		// ContentHeaderModule,
		NavbarModule,
		NgxIntlTelInputModule,
		NgScrollbarModule,
		ProfileAvatarModule,
		DnsModule,
		TranslateModule

	],
	exports: [UsersComponent],
})
export class UsersModule {}
