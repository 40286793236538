import {Component, OnInit} from "@angular/core";

import {map, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {CoreConfigService} from "@core/services/config.service";
import store from "store";
import {Router} from "@angular/router";
import {environment} from "environments/environment";
import { UserService } from "app/base/users/services/user.service";

@Component({
	selector: "app-in-active",
	templateUrl: "./in-active.component.html",
	styleUrls: ["./in-active.component.scss"],
})
export class InActiveComponent implements OnInit {
	public coreConfig: any;
	public admins = [];
	public isAdmin: boolean = false;
	public instance_id = -1;
	public isSuperAdminUser: boolean = false

	// Private
	private _unsubscribeAll: Subject<any>;

	/**
	 * Constructor
	 *
	 * @param {CoreConfigService} _coreConfigService
	 */
	constructor(
		private _translateService: TranslateService,
		private _coreConfigService: CoreConfigService,
		private _router: Router,
		private _userService: UserService,
	) {
		this._unsubscribeAll = new Subject();

		// Configure the layout
		this._coreConfigService.config = {
			layout: {
				navbar: {
					hidden: true,
				},
				footer: {
					hidden: true,
				},
				menu: {
					hidden: true,
				},
				customizer: false,
				notifications: false,
				enableLocalStorage: false,
			},
		};
	}

	// Lifecycle Hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Subscribe to config changes
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
			this.coreConfig = config;
		});
		this.getCurrentUserProfile()
		this.admins = store.get("admins");
		this.isAdmin = store.get("is_admin");
		this.instance_id = store.get("instance_id");
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(undefined);
		this._unsubscribeAll.complete();
	}

	goToSubscription = (event) => {
		// this._router.navigate([`/admin/instance/${this.instance_id}`]);
		event.preventDefault();
		let accessToken = store.get("accessToken");
		let abs_domain = environment?.management_url + "redirect/admin/" + accessToken;
		window.open(abs_domain, "_blank");
		// window.open(environment?.management_url, "_blank");
	};
	getCurrentUserProfile = () => { 
		this._userService.getCurrentUserProfile().pipe(
		map((response: any) => {
			if (response) {
				if (response.is_admin) {
					if (response?.profile?.role_type == 3) {
						this.isSuperAdminUser = true;
					}
				}
			}
			// Success
		})
	)
	.subscribe();
	}
}
