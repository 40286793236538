import { Component, OnInit, Input, Output, EventEmitter, Renderer2 } from "@angular/core";
import { FileUploader } from "ng2-file-upload";

@Component({
	selector: "app-email-feature",
	templateUrl: "./email-feature.component.html",
	styleUrls: ["./email-feature.component.scss"],
})
export class EmailFeatureComponent implements OnInit {
	@Input() initOptions: any = {};
	@Input() emailConfig: any = {};
	public isShownCc: any = false;
	public addEscalation: any = false;
	public showMacros: any = false;
	public contentPlaceholder: any = "{{asset_id}}:{{ip_address}}";
	public subPlaceholder: any = "{{specific_problem}}: {{hostname}}";
	public quillEditorStyles = { "min-height": "300px" };
	public filesList: any = [];
	public showMacroSection = false;
	public attachmentUploader: FileUploader = new FileUploader({
		// url: URL,
		isHTML5: true,
	});
	public quillCleared;
	public emailEdit = false;
	public quillEdit = false;
	public checkEmailVal = false;
	public checkCCEmailVal = false;
    public macros: any = [];
	// public macros = [
	// 	{ id: "ip_address", name: "IP Address", group_label: "Asset" },
	// 	{ id: "hostname", name: "Hostname", group_label: "Asset" },
	// 	{ id: "alias", name: "Alias", group_label: "Asset" },
	// 	{ id: "device_type", name: "Device Type", group_label: "Asset" },
	// 	{ id: "os_name", name: "OS Name", group_label: "Asset" },
	// 	{ id: "res_type", name: "Resource Type", group_label: "Asset" },
	// 	{ id: "severity", name: "Severity", group_label: "Basic Info" },
	// 	{ id: "message", name: "Message", group_label: "Basic Info" },
	// 	{ id: "timestamp", name: "Timestamp", group_label: "Basic Info" },
	// 	{ id: "eventid", name: "Event Id", group_label: "Basic Info" },
	// 	{ id: "alarmid", name: "display_id", group_label: "Basic Info" },
	// ];

	fileUpload = function (evt) {
		var files = evt.target.files;
		var new_file_len = evt.target.files.length;
		var added_file_len = this.filesList.length;
		for (var i = 0; i < new_file_len; i++) {
			var file = files[i];
			if (this.emailAttachments.indexOf(file.name) == -1) {
				this.emailAttachments.push(file.name);
				var pos = added_file_len + i;
				var reader = new FileReader();
				reader.onload = (function (filesList, file, pos) {
					return function (e) {
						var data = e.target.result;
						filesList[pos] = {
							file_name: file.name,
							file_type: file.type,
							file_size: file.size,
							file_data: data,
							file_date: new Date(),
							//'file_date': this.datepipe.transform(new Date(),'MMM dd,yyyy hh:mm a')
						};
					};
				})(this.filesList, file, pos);
				reader.readAsDataURL(file);
			}
		}
	};
	// removing the file from POP UI.
	removeAttachedFile = function (index, filesList) {
		filesList.splice(index, 1);
		this.emailAttachments.splice(index, 1);
	};

	constructor(private renderer: Renderer2) {
		this.renderer.listen("window", "click", (e: Event) => {
			if (
				(e &&
					this.emailConfig.subject &&
					this.emailConfig.subject.indexOf("{{") > -1 &&
					e["srcElement"] &&
					e["srcElement"]["className"].indexOf("macros") === -1) ||
				(e &&
					this.emailConfig["quillText"] &&
					this.emailConfig["quillText"].indexOf("{{") > -1 &&
					e["srcElement"] &&
					e["srcElement"]["className"].indexOf("macros") === -1)
			) {
				this.showMacroSection = false;
			}
		});
	}

	ngOnInit(): void {
        //console.log("initOption---------", this.initOptions)
        this.macros = this.initOptions?.placeholders
		this.initOptions["actual_user_list"] = this.initOptions?.user_list
        //console.log("this.macros", this.macros)
        if(this.emailConfig.cc_addr != undefined && this.emailConfig?.cc_addr?.length > 0){
			this.isShownCc = true;
		}
	}

	emailSubjectEvent(event) {
		
		if (event && event.indexOf("{{") > -1 && this.emailConfig.subject.split('{{')[this.emailConfig.subject.split('{{').length-1] === "") {
			this.showMacroSection = true;
			this.emailEdit = true;
			this.quillEdit = false;
		} else {
			this.showMacroSection = false;
			this.emailEdit = false;
		}
	}

	macrosEvent(group, item) {
		if (group && item) {
			this.emailConfig.subject = group.title + "/" + item.name + ",";
		}
	}

	emailEvent(e,formvalidation) {
		if(this.emailConfig.to_addr && this.emailConfig.to_addr.length > 0) {

			this.checkEmailVal = true	
			this.emailConfig["to_isvalid"] = formvalidation		
		} else {
			this.checkEmailVal = false
		}

	}

	CCEvent(e,ccvalidator) {
		if(this.emailConfig.cc_addr && this.emailConfig.cc_addr.length > 0) {
			this.checkCCEmailVal = true
			this.emailConfig["cc_isvalid"] = ccvalidator
		} else {
			this.checkCCEmailVal = false
		}

	}

	macrosSelectEvent(event) {
		if (event && event.item && event?.currentEdit && event.currentEdit === "quillEdit") {
			if (this.emailConfig["quillText"]) {
				let displayString = ''
				displayString =  event.item.id + "}}"
				displayString = this.emailConfig["quillText"] + displayString;
				this.emailConfig["quillText"] = displayString;
				this.emailConfig["erichTextData"] = displayString;
				document.getElementsByClassName("ql-editor")[0].childNodes[0].textContent =
				displayString;
			}
		} else if (event &&  event.item && event?.currentEdit && event.currentEdit === "emailEdit") {
			let displayString = ''
			displayString =  event.item.id + "}}"
			displayString = this.emailConfig.subject + displayString;
			this.emailConfig.subject = displayString;
		}
		

	}

	getEnrichTextData = (event,validation) => {

		this.emailConfig.erichTextData = event.html;
		if (event && event.text && event.text.indexOf("{{") > -1 && event.text.split('{{')[event.text.split('{{').length-1] === "\n") {
			this.quillEdit = true;
			this.emailEdit = false;
			this.showMacroSection = true;
			if(validation?.invalid){
				this.emailConfig["content"] = "";
			}
			this.emailConfig["quillText"] = event.text;
			this.showMacros = true;
			var iDiv = document.createElement("div");
			iDiv.className = "email-block";
			this.quillCleared = event.text;
			// 	document.getElementsByClassName('ql-container')[0].appendChild(iDiv);
			// 	document.getElementsByClassName('email-block')[0].innerHTML = `
			// 	<div class="form-group">
			// 	<ng-select [items]="this.emailConfig.macrosOption" [(ngModel)]="emailConfig.selectedMacro" bindLabel="name"
			// 	  name="formSelect" id="formSelect" placeholder="Select">
			// 	</ng-select>
			//   </div>
			// 	`
		} else {
			this.emailConfig["quillText"] = "";
			this.quillEdit = false;
			this.quillCleared = event.text;
			if(validation?.invalid){
				this.emailConfig["content"] = "";
			}
		}
	};

	CC() {
		this.isShownCc = !this.isShownCc;
	}

	changeEscalationEvent(e) {
		this.addEscalation = true;
	}

	onToRemove($event){
		let toTagList = this.initOptions.actual_user_list
		if(this.initOptions.actual_user_list.indexOf($event) == -1){
			let index = this.initOptions?.user_list.indexOf($event)
			if (index != -1){
				this.initOptions?.user_list.splice(index, 1);
				
			}
			this.initOptions["user_list"] = [...this.initOptions?.user_list]
		}
	}
}
