<div class="slideout-content default-slideout" dns-enter-submit>
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slid">
		<div class="modal-dialog sidebar-md">
			<div class="modal-content pt-0 pb-0">
				<form #assignUserForm="ngForm" class="flex-auto-height">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">
							{{ "UI.k_assign_user_to_organization" | translate }}
						</h5>
						<button
							id="btnClose"
							name="close"
							type="button"
							class="btn btn-flat-secondary btn-icon"
							data-dismiss="modal"
							aria-label="Close"
							(click)="closeEvent()"
							[attr.data-testid]="'btnClose'"
						>
							<i class="fa-light fa-xmark"></i>
						</button>
					</div>
					<div class="modal-body px-0 flex-auto-height">
						<div class="modal-content-body flex-1">
							<ng-scrollbar class="custom-scrollbar section-scrollbar">
								<div class="row p-1 border-bottom">
								  <div class="col-md-12">
                                    <div class="d-flex gap-1">
                                        <button type="button" class="btn  active btn-sm mr-50 waves-effect waves-float waves-light" id="assigned-org"
                                            data-testid="assigned-org" 
                                            [ngClass] = "isAssignedOrg ? 'btn-primary' : 'border'"
                                            (click)="onAssignedOrg('assigned_org')">
                                            {{'UI.k_assigned_org' | translate}}
                                        </button>
                                        <button type="button" class="btn   btn-sm mr-50 waves-effect waves-float waves-light" id="unassigned-org" 
                                            data-testid="unassigned-org" 
                                            [ngClass] = "isAssignedOrg ? 'border' : 'btn-primary'"
                                            (click)="onAssignedOrg('unassigned_org')"> 
                                            {{'UI.k_unassigned_org' | translate}}
                                        </button>
                                    </div>
                                  </div>
								</div>
                                <div class="row p-1 border-bottom">
                                    <div class="col-md-6">
                                        <div class="input-group search-form-group">
                                            <div class="input-group-prepend">
                                                <span
                                                    class="input-group-text"
                                                    id="basicddon-search1"
                                                    ><i class="fa-light fa-magnifying-glass"></i
                                                ></span>
                                            </div>
                                            <input
                                                id="txtSearch"
                                                name="txtSearch"
                                                type="text"
                                                class="form-control"
                                                placeholder="{{ 'UI.k_search' | translate }}"
                                                aria-label="Search..."
                                                aria-describedby="basic-addon-search1"
                                                [(ngModel)]="availableSearch"
                                                [attr.data-testid]="
                                                    'txtSearch'
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                                <ng-container *ngFor = "let org of orgData | filter: availableSearch:'brand';">
                                    <div class="row border m-1 p-1 border-radius" *ngIf = "isAssignedOrg && org.assigned">
                                        <div class="col-md-12">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="d-flex align-items-center gap-1">
                                                    <div class="border org-image-container p-1">
                                                        <div class="org-image">
                                                            <img class="w-100 h-100" src="{{org.logo}}" alt="">
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="d-flex">
                                                            <span class="text-primary xl-font fw-300">#</span>
                                                            <span class="sm-font ml-25 title-color">#{{org.org_id}}</span>
                                                        </div>
                                                        <div>
                                                            <span class="reg-font fw-500">{{org.brand}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button type="button" class="btn btn-outline-primary waves-effect btn-sm px-3" id="assigned-btn"
                                                        data-testid="assigned-btn" (click)="onSelectAssign('unassign',org)">
                                                        {{'UI.k_unassign' | translate}}
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="mt-1 d-grid gap-25 user-details-container">
                                                <div>
                                                    <span class="sm-font"><i class="fal fa-clock text-primary"></i> {{org.name}}</span>
                                                </div>
                                                <div>
                                                    <span class="sm-font"><i class="fal fa-clock text-primary"></i> {{org.email}}</span>
                                                </div>
                                                <div>
                                                    <span class="sm-font"><i class="fal fa-clock text-primary"></i> {{org.phone_number}}</span>
                                                </div>
                                                <div>
                                                    <span class="sm-font"><i class="fal fa-clock text-primary"></i>
                                                        <span *ngIf="org.address.city"> {{org.address.city}}, </span>
                                                        <span *ngIf="org.address.state">{{org.address.state}}, </span>
                                                        <span *ngIf="org.address.country">{{org.address.country}}, </span>
                                                        <span *ngIf="org.address.pincode">{{org.address.pincode}}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row border m-1 p-1 border-radius" *ngIf = "!isAssignedOrg && !org.assigned">
                                        <div class="col-md-12">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <div class="d-flex align-items-center gap-1">
                                                    <div class="border org-image-container p-1">
                                                        <div class="org-image">
                                                            <img class="w-100 h-100" src="{{org.logo}}" alt="">
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="d-flex">
                                                            <span class="text-primary xl-font fw-300">#</span>
                                                            <span class="sm-font ml-25 title-color">#{{org.org_id}}</span>
                                                        </div>
                                                        <div>
                                                            <span class="reg-font fw-500">{{org.brand}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button type="button" class="btn btn-outline-primary waves-effect btn-sm px-3" id="assigned-btn"
                                                        data-testid="assigned-btn" (click)="onSelectAssign('assign',org)">
                                                        {{'UI.k_assign' | translate}}
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="mt-1 d-grid gap-25 user-details-container">
                                                <div>
                                                    <span class="sm-font"><i class="fal fa-clock text-primary"></i> {{org.name}}</span>
                                                </div>
                                                <div>
                                                    <span class="sm-font"><i class="fal fa-clock text-primary"></i> {{org.email}}</span>
                                                </div>
                                                <div>
                                                    <span class="sm-font"><i class="fal fa-clock text-primary"></i> {{org.phone_number}}</span>
                                                </div>
                                                <div>
                                                    <span class="sm-font"><i class="fal fa-clock text-primary"></i>
                                                        <span *ngIf="org.address.city"> {{org.address.city}}, </span>
                                                        <span *ngIf="org.address.state">{{org.address.state}}, </span>
                                                        <span *ngIf="org.address.country">{{org.address.country}}, </span>
                                                        <span *ngIf="org.address.pincode">{{org.address.pincode}}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                
							</ng-scrollbar>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>

