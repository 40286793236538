import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { TranslateService } from '@ngx-translate/core';
import { ChangeViewService } from "app/servicedesk/change-management/services/change-view.service";
import { ToastrService } from 'ngx-toastr';
import { catchError, map, throwError } from 'rxjs';

@Component({
  selector: 'app-dns-change-risk',
  templateUrl: './dns-change-risk.component.html',
  styleUrls: ['./dns-change-risk.component.scss']
})
export class DnsChangeRiskComponent implements OnInit {
  @Input() options: any = [];
  @Input() change_data: any = {};
  @Input() risk_id = "-1"
  @Output() onHideRisk = new EventEmitter(false);
  public risk_obj : any = {}
  public riskOption:any = [];
  public disableSaveBtn: boolean = false;
  public risk_title = this._translateService.instant("UI.k_risk_add");

  
  constructor(private _coreSidebarService: CoreSidebarService,
      private _changeService: ChangeViewService,
      private _toastr: ToastrService,
      private _translateService: TranslateService,) { }

  ngOnInit(): void {
      this.riskOption = this.options?.risk_type
      this.risk_obj["ref_id"] = this.change_data?.change_id
      this.risk_obj["ref_display_id"] = this.change_data?.display_id
      if (this.risk_id != "-1"){
          this.risk_title = this._translateService.instant("UI.k_risk_edit");
          this.getRiskData()
      }
  }
  toggleSidebar = (name): void => {
      this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
      this.onHideRisk.emit()
  };

  getRiskData() {
      this._changeService.getChangeRisk(this.risk_id, {}).subscribe(
      (response) => {
          if (response) {
              this.risk_obj = response
          }
      },
      (error) => {
          //todo
      }
      ); 
  }

  SaveRisk = (data) => {
  if (data.form.valid === true) {
          this.disableSaveBtn = true
          this._changeService.saveChangeRisk(this.risk_obj).pipe(
          map((response: any) => {
              this.disableSaveBtn = false
              if (response) {
                  if (response["status"] == "success") {
                      this.risk_obj = {};
                      this._toastr.success(
                          this._translateService.instant(
                              response["message"]
                          ),
                          this._translateService.instant(
                              "Message.msg_save_success"
                          ),
                          {
                              toastClass: "toast ngx-toastr",
                              closeButton: true,
                              positionClass: "toast-top-right",
                          }
                      );
                      this.toggleSidebar('risk-sidebar')
                  } else {
                      this._toastr.error(
                          this._translateService.instant(
                              response["message"], {existing_rule: response?.data?.existing_rule}
                          ),
                          this._translateService.instant(
                              "Error.err_save_failed"
                          ),
                          {
                              toastClass: "toast ngx-toastr",
                              closeButton: true,
                              positionClass: "toast-top-right",
                          }
                      );
                  }   
              }
              // Success
          }),
          catchError((error) => {
              this._toastr.error(
                  this._translateService.instant("Error.err_save_failed"),
                  this._translateService.instant("Error.err_save_failed"),
                  {
                      toastClass: "toast ngx-toastr",
                      closeButton: true,
                      positionClass: "toast-top-right",
                  }
              );
              this.disableSaveBtn = false
              return throwError(error.statusText);
          })
          ).subscribe();
  }
};

}
