<div class="summary-status">
    <div class="status-sec">
        <div class="info-sec">
            <div
                class="total"
                id="divSummaryStatus"
                name="divSummaryStatus"
            >
                {{ widgetData?.data?.total }}
            </div>
            <div class="status">
                <span
                    class="up-tag tag"
                    id="divStatusUp"
                    name="divStatusUp"
                    ><i class="fas fa-sort-up"></i>
                    <span class="tag-value">{{
                        widgetData?.data?.up
                    }}</span></span
                >
                <span
                    class="down-tag tag"
                    id="divStatusDown"
                    name="divStatusDown"
                    ><i class="fas fa-sort-down"></i
                    ><span class="tag-value">{{
                        widgetData?.data?.down
                    }}</span></span
                >
                <span
                    class="disable-tag tag"
                    ><i class="far fa-circle"></i
                    ><span class="tag-value">{{
                        widgetData?.data?.disabled
                    }}</span></span
                >
            </div>
        </div>
        <div class="img-main">
            <ng-container *ngIf="widgetData?.data?.widget_image_base64; then base64img else block2"></ng-container>
            <ng-template #base64img>
                <div class="img-sec">
                    <img src="{{ widgetData?.data?.widget_image_base64 }}" />
                </div>
            </ng-template>
            <ng-template #block2>
                <ng-container *ngIf="widgetData?.data?.widget_image_loc; then imgSrc else iconSrc"></ng-container>
                <ng-template #imgSrc>
                    <div class="img-sec">
                        <img src="{{ widgetData?.data?.widget_image_loc }}" />
                    </div>
                </ng-template>
                <ng-template #iconSrc>
                    <div class="icon-sec">
                        <i  class="{{ widgetData.widget.selectedIcon }}" 
                            [ngStyle]="{ color: widgetData.widget.selectedIconColor}">
                        </i>
                    </div>
                </ng-template>
            </ng-template>
        </div>
    </div>
    <div class="event-sec border-top mt-2">
        <ng-container *ngFor="let item of eventSummary">
            <div
                class="event"
                id="divEvent"
                name="divEvent"
                (click)="
                    summaryDetailsEvent('summaryStatus', item.event, item.value)
                "
            >
                <span
                    class="event-icon"
                    [ngStyle]="{ 'background-color': item.background_color }"
                >
                    <i
                        class="far fa-chevron-double-up"
                        [ngStyle]="{ color: item.color }"
                    ></i>
                </span>
                <span class="event-value" [ngStyle]="{ color: item.color }">{{
                    item.value
                }}</span>
            </div>
        </ng-container>
    </div>
</div>
