import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpXsrfTokenExtractor } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router, RouterEvent } from "@angular/router";
import store from "store";
import { Store } from "@ngrx/store";
import * as UserActions from "app/store/user/actions";
import { GlobalConstants } from "../../app.globalConstants";

@Injectable({
	providedIn: "root",
})
export class DnsHttpInterceptorService {
	constructor(private router: Router, private tokenExtractor: HttpXsrfTokenExtractor, private store: Store<any>) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const accessToken = store.get("accessToken");
		if (accessToken) {
			const modRequest = request.clone({
				headers: request.headers
					.set("Authorization", `${accessToken}`)
					.set("Content-Type", "application/json")
					.set("x-requested-with", "XMLHttpRequest"),
			});
			return next.handle(modRequest).pipe(
				catchError((error) => {
          //console.log(error)
					if (error.status == 401) {
						//this.store.dispatch(new UserActions.Logout())
						store.remove("accessToken");
						store.remove("image_per");
						store.remove("ogdata");
						GlobalConstants.dnsPermissions = "";
						this.router.navigate(["/auth/login"], {
							queryParams: { is_token_expired: true },
						});
					}
					const errorMessage = error.statusText || error.error.message;
					return throwError({errorMessage, error});
				})
			);
		} else {
			const req = request.clone({
				headers: request.headers
					.set("Content-Type", "application/json")
					.set("x-requested-with", "XMLHttpRequest"),
			});
			return next.handle(req);
		}
	}
}
