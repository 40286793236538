<ng-container *appDnsLicenseHandler="item?.license || {}">
	<ng-container *appIsGranted="item.permission">
		<ng-container *appFeatureActive="item.featureKeys">
			<!-- collapsible title -->
			<a
				class="d-flex align-items-center"
				id="{{ item.id }}"
				[ngClass]="item.classes"
				*ngIf="!item.url"
				[title]="item.title | translate"
				(click)="toggleOpen($event)"
			>
				<ng-container *ngTemplateOutlet="itemContent"></ng-container>
				<span *ngIf="!onPremise && item?.subTitle" class="menu-title sub-title ml-25 text-primary fw-500"
					><sup> {{ item?.subTitle }}</sup></span
				>
			</a>

			<!-- itemContent template -->
			<ng-template #itemContent>
				<!--<span id="{{item.id}}" [data-feather]="item.icon" *ngIf="item.icon" ></span>-->
				<!-- <span *ngIf="item.icon" id="{{item.id}}"><i class="fa-light {{ item.icon }}"></i></span> -->
				<!-- <lottie-player
				*ngIf="item.icon"
				src="../../../../../assets/images/lotty/{{ item.icon }}.json"
				background="transparent"
				speed="1"
				style="width: 24px; height: 24px"
				hover
			>
			</lottie-player> -->
				<div
					id="{{ item.icon }}-triggerCollapse"
					name="{{ item.icon }}-trigger"
					class="d-flex justify-content-center align-items-center"
				>
					<div *ngIf="item.icon" style="width: 24px; height: 24px" background="transparent" speed="1" hover>
						<span id="{{ item.icon }}-containerCollapse"></span>
					</div>
					<span id="menu_{{ item.id }}" class="menu-title text-truncate" [translate]="item.translate"
						>&nbsp;&nbsp;&nbsp;{{ item.title }}</span
					>
				</div>
				<span
					class="badge ml-auto mr-1"
					*ngIf="item.badge"
					[translate]="item.badge.translate"
					[ngClass]="item.badge.classes"
				>
					{{ item.badge.title }}
				</span>
			</ng-template>

			<!-- sub-menu item/collapsible -->
			<ul class="menu-content">
				<ng-container *ngFor="let item of item.children">
					<!-- item -->
					<li
						core-menu-vertical-item
						[item]="item"
						*ngIf="item.type == 'item'"
						[ngClass]="{ disabled: item.disabled === true }"
						[routerLink]="item.openInNewTab ? [] : [item.url]"
						[routerLinkActive]="!item.openInNewTab ? 'active' : ''"
						[routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
						attr.data-testid="btn_{{ item.id }}"
					></li>
					<!-- collapsible -->
					<li
						core-menu-vertical-collapsible
						*ngIf="item.type == 'collapsible'"
						[item]="item"
						class="nav-item has-sub"
					></li>
				</ng-container>
			</ul>
		</ng-container>
	</ng-container>
</ng-container>
