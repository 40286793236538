import { findIndex, filter } from "rxjs/operators";
import { ControlContainer, NgForm } from "@angular/forms";

import { Component, OnInit, EventEmitter, Input, OnChanges, SimpleChanges, Output, AfterViewInit } from "@angular/core";
import { FlatpickrOptions } from "ng2-flatpickr";
import { TranslateService } from "@ngx-translate/core";

const CUSTOM_FIELD_DATA = {
	custom_fields: [
		{
			type: "text",
			icon: "fa-font",
			label: "Text",
			description: "Enter your name",
			placeholder: "Enter your name",
			className: "form-control",
			subtype: "text",
			regex: "",
			handle: true,
			roles: [],
			col_val: "col-6",
			is_customfield: true,
			name: "col_1626977658624",
			data_key: "col_1626977658624",
		},
	],
	form_layout: {},
};

@Component({
	selector: "app-dns-custom-field",
	templateUrl: "./dns-custom-field.component.html",
	styleUrls: ["./dns-custom-field.component.scss"],
	viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class DnsCustomFieldComponent implements OnInit, OnChanges, AfterViewInit {
	@Input("config") customFieldsConfig: any;
	@Input("data") customFieldData: any;
	filterConfigData: any;
	@Input("placeholder") section: any = 1;
	@Input("formRef") formRef: NgForm;
	@Input("selfservice") selfservice: boolean = false;
	@Input("disable") disableField: boolean = false;

	public basicDateOptions: FlatpickrOptions = {
		altInput: true,
		disable: [
			(date) => {
				return this.disableField ? date : false; // for disabling picker we need to pass function with truth value or dates list
			},
		],
	};
	public dateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: true,
		disable: [
			(date) => {
				return this.disableField ? date : false;
			},
		],
	};

	constructor(private _translateServce: TranslateService) {
		this.customFieldsConfig = CUSTOM_FIELD_DATA;
		this.customFieldData = {};
	}

	ngOnInit(): void {
		if (this.customFieldsConfig) {
			this.filterConfigData = JSON.parse(JSON.stringify(this.customFieldsConfig));
			if (this.section) {
				let cf_data = this.customFieldsConfig?.custom_fields?.filter((d) => d?.section == this.section);
				this.filterConfigData.custom_fields = cf_data;
			}
		}
	}

	ngAfterViewInit(): void {}

	getTranslatedText = (text, param = {}) => {
		if (text) {
			return this._translateServce.instant(text, param);
		}
		return text;
	};

	toggleValue = (item, key) => {
		item.selected = !item.selected;
		if (!this.customFieldData[key]) {
			this.customFieldData[key] = {};
		}
		const data = { key: item?.id, value: item?.value };
		if (this.customFieldData[key][item.value]) {
			delete this.customFieldData[key][item.value];
		} else {
			this.customFieldData[key][item.value] = data;
		}
	};

	ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			if (this.customFieldsConfig) {
				this.filterConfigData = JSON.parse(JSON.stringify(this.customFieldsConfig));
				if (this.section) {
					let cf_data = this.customFieldsConfig?.custom_fields?.filter((d) => d?.section == this.section);
					this.filterConfigData.custom_fields = cf_data;
				}
			}
		}
	}

	mergeText = (txt) => {
		return txt ? txt.toLowerCase().split(" ").join("-") : "";
	};

	onCheckBoxLoad = (cbData, data) => {
		if (!this.customFieldData[data.name]) {
			this.customFieldData[data.name] = {};
		}
		if (cbData && data) {
			return this.customFieldData[data.name][cbData["value"]] ? true : false;
		} else {
			return false;
		}
	};

	onChange(event) {
		event.srcElement.value = "";
	}
}
