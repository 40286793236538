<div id="modals-slide-in" class="slideout-content default-slideout">
	<div class="modal-dialog sidebar-sm">
		<div class="modal-content pt-0">
			<div class="modal-header">
				<h5 class="modal-title" id="helpLabel">{{ data?.title | translate }}</h5>
				<button
					type="button"
					id="btnHelpClose"
					name="btnHelpClose"
					class="btn btn-flat-secondary btn-icon"
					data-dismiss="modal"
					aria-label="Close"
					(click)="toggleSidebar('help-menu')"
				>
					<i class="fa-light fa-xmark"></i>
				</button>
			</div>
			<div class="modal-body">
				<div class="modal-content font-small-3 pt-0 pb-0" id="helpContent">
					<ng-scrollbar class="custom-scrollbar section-scrollbar">
						<div class="p-1">
							<div class="p-2 font-small-4" [innerHtml]="data?.content?.header | translate"></div>

							<div class="help" *ngIf="data?.content?.actions.length > 0">
								<div role="tabpanel" class="tab-pane help-tab-pane collapse-icon">
									<div
										class="accordion collapse-margin"
										*ngFor="
											let action_item of data?.content?.actions;
											let i = index;
											let first = first;
											let last = last
										"
									>
										<ngb-accordion [destroyOnHide]="false" [closeOthers]="false">
											<ngb-panel id="ngb-panel-{{ i }}" [cardClass]="'collapse-margin'">
												<ng-template ngbPanelTitle>
													<span
														id="helpTitle{{ i }}"
														class="card-text ellipsis"
														[innerHtml]="action_item.title | translate"
														tooltipClass="fadeInAnimation"
														placement="bottom"
														[ngbTooltip]="action_item.title | translate"
														container="body"
													></span>
												</ng-template>
												<ng-template ngbPanelContent>
													<span
														id="helpContent{{ i }}"
														class="font-small-4 help"
														[innerHtml]="action_item?.body?.content | translate"
													></span>
													<div
														*ngIf="action_item?.body?.alert"
														class="pt-1"
														id="helpAlert{{ i }}"
													>
														<ngb-alert
															[type]="action_item?.body?.alert?.type"
															[dismissible]="false"
														>
															<div class="alert-body">
																<span
																	*ngIf="action_item?.body?.alert?.icon"
																	[data-feather]="action_item?.body?.alert?.icon"
																	[class]="'mr-50 align-middle'"
																></span>
																<span
																	[innerHtml]="
																		action_item?.body?.alert?.text | translate
																	"
																></span>
															</div>
														</ngb-alert>
													</div>
												</ng-template>
											</ngb-panel>
										</ngb-accordion>
									</div>
								</div>
							</div>
							<div *ngIf="data?.seperation">
								<!-- <div class="divider divider-primary">
								<div class="divider-text">
									{{ "UI.k_more_help" | translate }}
								</div>
							</div> -->
							</div>
							<div *ngIf="data?.redirect" class="p-2">
								{{ "UI.k_to_know_more" | translate }}
								<a [href]="data?.redirection_url" target="_blank">{{
									"UI.k_click_here" | translate
								}}</a>
							</div>
						</div>
					</ng-scrollbar>
				</div>
			</div>
		</div>
	</div>
</div>
