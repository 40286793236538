import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { TranslateService } from '@ngx-translate/core';
import { ColumnMode,SelectionType } from '@swimlane/ngx-datatable';
import { GlobalConstants } from "app/app.globalConstants";
import { InfinityModules } from "app/common/dns/types/modules";

@Component({
  selector: 'app-quick-response-template',
  templateUrl: './quick-response-template.component.html',
  styleUrls: ['./quick-response-template.component.scss']
})
export class QuickResponseTemplateComponent implements OnInit {
  public ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  responseEmailTemplateColumns: Array<any>;
  @Input() responseEmailTemplate;
  totalResponseEmailTemplate:any;
  pageNumber = 0;
	sortKey: any ;
	limit: any = 10;
  permissions: any;
  module = InfinityModules.INCIDENT;
  public multiselectOptions;
	event="add";
	currentItem = {};
	@Output() currentItemEmit = new EventEmitter<any>();
    @Output() eventEmit = new EventEmitter<any>();
	@Output() onHideQuickResponseSidbar = new EventEmitter<any>();
	selectBoxColumnLabel = this._translateService.instant("UI.k_quick_selection")
  constructor(
    private _translateService: TranslateService,
    private _coreSidebarService: CoreSidebarService,
  ) { }

  ngOnInit(): void {
    let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.permissions = user_permissions[this.module];

    
  
    this.totalResponseEmailTemplate = this.responseEmailTemplate.length;
    this.responseEmailTemplateColumns = [
      {
        label: this._translateService.instant("UI.k_subject"),
        key: "title",    
				clickable: true,
				listener:this.viewResponseTemp,  
      },            
      {
        label: this._translateService.instant("UI.k_description"),
        key: "tempText",        			
      },
      {
				label: this._translateService.instant('UI.k_action'),
				key: 'actions',
        width: 150,
				actions: [
					{
						label: this._translateService.instant('UI.k_edit'),
						icon: 'fa-pen-to-square',
						key: 'edit',
						class: 'text-primary mr-50',
						listener: this.editResponseTemp,
					},
					{
						label: this._translateService.instant('UI.k_delete'),
						icon: 'fa-trash-can',
						key: 'delete',
						class: 'text-danger mr-50',						
					},  				
				],
			},                     
    ]
		this.multiselectOptions = [							
			{
				icon: "trash-can",
				label: this._translateService.instant("UI.k_delete"),				
				permissionKey: "delete",
			},		
		];
  }
  setPage(pageInfo) {
		this.pageNumber = pageInfo.offset;
	}
  onSort(args): void {
		if (args.sorts) {
			this.pageNumber = 0;
			let sortKey = args.sorts[0].prop;
			this.sortKey = sortKey;
		}
	}
  setLimit(args): void {
		this.pageNumber = 0;
		this.limit = args.target.value;
	}
  onSelectRow(data) {
		
	}
	toggleSidebar = (name) => {
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
		this.event = "add";
		this.currentItem = {};	
		this.currentItemEmit.emit(this.currentItem);
		this.eventEmit.emit(this.event);
		this.onHideQuickResponseSidbar.emit("hide")
  }
  editResponseTemp = (data): void => {
		this.event = "edit";
		this.currentItem = data;
		this._coreSidebarService.getSidebarRegistry("add-quick-response-template").toggleOpen();
		this.currentItemEmit.emit(this.currentItem)
		this.eventEmit.emit(this.event)
	};
	viewResponseTemp = (data): void => {		
		this.event = "view";
		this.currentItem = data;
		this._coreSidebarService.getSidebarRegistry("add-quick-response-template").toggleOpen();
		this.currentItemEmit.emit(this.currentItem)
		this.eventEmit.emit(this.event)
	};
}
