import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-device-summary',
  templateUrl: './device-summary.component.html',
  styleUrls: ['./device-summary.component.scss']
})
export class DeviceSummaryComponent implements OnInit {
  	@Input() widgetData: any;
	@Input() modifiedtime: any;
  	eventSummary: any = {}
  	constructor() { }

  	ngOnInit(): void {
		this.eventSummary =  [
		{
			icon: "double_arrow",
			color: "#EA5455",
			value: this.widgetData.data.events.Critical,
			background_color: "rgba(234, 84, 85, 0.12)",
			event: "warning",
		},
		{
			icon: "arrow",
			color: "#FF9F43",
			value: this.widgetData.data.events.Major,
			background_color: "rgba(255, 159, 67, 0.12)",
			event: "critical",
		},
		{
			icon: "arrow",
			color: "#00CFE8",
			value: this.widgetData.data.events.Information,
			background_color: "rgba(0, 207, 232, 0.12)",
			event: "info",
		},
		]
		// console.log(this.eventSummary)
  }

}
