import {Component, OnInit, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'dns-macro-selector',
  templateUrl: './dns-macro-selector.component.html',
  styleUrls: ['./dns-macro-selector.component.scss']
})
export class DnsMacroSelectorComponent implements OnInit {
  @Input() name: string = "Macros";
  @Input("macros") macroData: any;
  isCollapsed5 = true;
  @Output("listener") listener: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }

  onTagClick(tag: any) {
    this.listener.emit(tag);
  }
}
