<div class="single-imacd-status">
	<div class="imacd-item" id="divimacd" name="divimacd">
		<div
			class="d-flex"
			[ngClass]="config?.data?.count > 0 && config?.data?.item_filter ? 'cursor-pointer' : ''"
			(click)="showimacdSidebar(config?.data)"
		>
			<div class="icon-container">
				<div
					class="icon-sec"
					*ngIf="config?.data?.color?.startsWith('#')"
					[ngStyle]="{ 'background-color': config?.data?.color }"
				>
					<i class="{{ config?.data?.icon }}"></i>
				</div>

				<div
					class="icon-sec"
					*ngIf="!config?.data?.color?.startsWith('#')"
					[ngClass]="'bg-' + (config?.data?.color || 'primary')"
				>
					<i class="far {{ config?.data?.icon }}"></i>
				</div>
			</div>
			<div class="imacd-title align-items-center d-flex">{{ config?.data?.name }}</div>
		</div>
		<div
			class="d-flex text-center"
			[ngClass]="config?.data?.count > 0 && config?.data?.item_filter ? 'cursor-pointer' : ''"
			(click)="showimacdSidebar(config?.data)"
		>
			<div class="imacd-value set-color">{{ config?.data?.count }}</div>
		</div>
		<ng-container *ngIf="config.subdata">
			<div class="sub-data d-flex justify-content-start align-items-center border-top">
				<span class="reg-font text-muted-color">To {{ config.subdata.since_time }}</span>
				<div class="badge d-flex ml-50" [ngClass]="'badge-light-' + config.subdata.bg_color">
					<div class="icon" *ngIf="config.subdata.percent >= 0">
						<i class="fas fa-sort-up" style="margin-top: 0.2rem; margin-bottom: -0.2rem"></i>
					</div>
					<div class="icon" *ngIf="config.subdata.percent < 0">
						<i class="fas fa-sort-down" style="margin-top: -0.2rem"></i>
					</div>
					<span class="font-small-3">&nbsp;{{ config.subdata.percent_value }}</span>
				</div>
			</div>
		</ng-container>
	</div>
</div>
