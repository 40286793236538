import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChannelChangePayload } from "app/admin/models";
import { AdminService, ChannelService } from "app/admin/service";
import Stepper from "bs-stepper";
import { CountryISO, PhoneNumberFormat, SearchCountryField } from "ngx-intl-tel-input";

@Component({
	selector: "app-add-organization-sidebar",
	templateUrl: "./add-organization-sidebar.component.html",
	styleUrls: ["./add-organization-sidebar.component.scss"],
})
export class AddOrganizationSidebarComponent implements OnInit {
	public horizontalWizardStepper: Stepper;
	public SearchCountryField = SearchCountryField;
	public CountryISO = CountryISO;
	public PhoneNumberFormat = PhoneNumberFormat;
	public preferredCountries: CountryISO[] = [
		CountryISO.UnitedStates,
		CountryISO.UnitedKingdom,
		CountryISO.Australia,
		CountryISO.NewZealand,
		CountryISO.Germany,
		CountryISO.Spain,
		CountryISO.India,
		CountryISO.Japan,
		CountryISO.China,
		CountryISO.Singapore,
		CountryISO.UnitedArabEmirates,
		CountryISO.Brazil,
	];

	public itemObj: any = {};
	public ALLOWED_TYPES = ["png", "jpeg", "jpg"];
	public MAX_IMAGE_SIZE = 20971520;
	public currentUserCount = 0;
	public subTotal = 85;
	public dicValue = 0;
	public totalPrice = 85;
	public userCount = 35;
	public defaultCurrency: string = "USD";
	defaultProducts = ["Infraon-Assets", "Infraon-Helpdesk", "Infraon-ITSM"];
	public Options = ["500+ Employee", "1001+ Employee", "5000+ Employee"];
	public productOption = [
		{ name: "UI.k_users", id: "user", icon: "fa-user-group", type: "User" },
		{ name: "UI.k_it_asset", id: "it_asset", icon: "fa-box", type: "Asset" },
		{ name: "UI.k_fixed_asset", id: "fixed_asset", icon: "fa-archive", type: "Asset" },
		{ name: "UI.k_availability_monitor", id: "availability_monitor", icon: "fa-wifi", type: "Asset" },
		{ name: "UI.k_network_monitor", id: "network_monitor", icon: "fa-chart-network", type: "Asset" },
		{ name: "UI.k_server_monitor", id: "server_monitor", icon: "fa-server", type: "Asset" },
	];
	public imgDetailsObject = {
		aspectRatio: "4 / 4",
		resizeToWidth: "300",
		cropperMinWidth: "300",
		maintainAspectRatio: true,
	};
	public productCount = [];

	public showPreview = false;
	public showQueue = false;
	public multipleFiles = false;
	public displayResoluion = true;
	public separateDialCode = true;
	public is_same_as_above = false;
	public isDissabled = false;
	public onInputFocus = false;
	public disableSave = false;

	public infraonProducts = [
		{
			name: "Infraon Assets",
			key: "asset",
			units: ["user", "asset"],
			default_products: ["Infy_Ats_Pro_1"],
		},
		{
			name: "Infraon Helpdesk",
			key: "helpdesk",
			units: ["user"],
			default_products: ["Infy_HD_Ent_1"],
		},
		// {
		// 	name: "Infraon Infinity",
		// 	key: "infinity",
		// 	units: [],
		// 	default_products: [],
		// },
		// {
		// 	name: "Infraon ITIM",
		// 	key: "itim",
		// 	units: [],
		// 	default_products: [],
		// },
		{
			name: "Infraon ITSM",
			key: "itsm",
			units: ["user", "asset"],
			default_products: ["Infy_ITSM_Pro_1"],
		},
		// {
		// 	name: "Infraon NMS",
		// 	key: "nms",
		// 	units: [],
		// 	default_products: [],
		// },
		// {
		// 	name: "Infraon Uptime",
		// 	key: "uptime",
		// 	units: [],
		// 	default_products: [],
		// },
	];
	public logoMap = {
		asset: "assets/images/products/product-asset.svg",
		helpdesk: "assets/images/products/product-helpdesk.svg",
		infinity: "assets/images/products/product-infinity.svg",
		itim: "assets/images/products/product-itim.svg",
		itsm: "assets/images/products/product-itsm.svg",
		nms: "assets/images/products/product-nms.svg",
		uptime: "assets/images/products/product-uptime.svg",
	};

	@Output() onHideSidebar: EventEmitter<any> = new EventEmitter();
	@Input() eventType: any;

	constructor(private _channel: ChannelService, private _modalService: NgbModal, private _service: AdminService) {}

	ngOnInit(): void {
		this.horizontalWizardStepper = new Stepper(document.querySelector("#addOrganizationStepper"), {});
		this.itemObj.activePlan = "Professional";
		this.productOption.forEach((product) => {
			this.productCount[product.id] = 0;
		});
	}
	closeSidebar() {
		this.onHideSidebar.emit("hide");
	}
	onNameChange(e) {
		if (this.is_same_as_above) {
			this.itemObj["billingPerson_name"] = this.itemObj["contactPerson_name"];
			this.isDissabled = true;
		}
	}
	onNumberChange(e) {
		if (this.is_same_as_above) {
			this.itemObj["contact_number"] = this.itemObj["phone"];
			this.isDissabled = true;
		}
	}
	isSameAsAbove(e) {
		if (this.is_same_as_above === true) {
			this.itemObj["billingPerson_name"] = this.itemObj["contactPerson_name"];
			this.itemObj["contact_number"] = this.itemObj["phone"];
			this.isDissabled = true;
		} else {
			this.itemObj["billingPerson_name"] = undefined;
			this.itemObj["contact_number"] = undefined;
			this.isDissabled = false;
		}
	}
	horizontalWizardStepperNext = (data) => {
		if (data.form.valid) {
			this.horizontalWizardStepper.next();
		}
	};
	imageDetails = ($event) => {
		this.itemObj["image"] = $event;
		this.showPreview = true;
		this.itemObj["new_image"] = true;
		this.itemObj["delete_image"] = false;
	};
	removeImage = () => {
		this.itemObj["image"] = null;
		this.showPreview = false;
		this.itemObj["delete_image"] = true;
	};
	onProductChange = (e) => {};
	onFocus(product) {
		this.onInputFocus = true;
		if (product) {
			product["onInputFocus"] = true;
		}
	}
	togglePricingModal = (modalPreview: string) => {
		// console.log("entering togglePricingModal this.pricingTypeData", this.pricingTypeData);
		// opens the plan change model
		const modalRef = this._modalService.open(modalPreview, {
			centered: true,
			size: "xl", // size: 'xs' | 'sm' | 'lg' | 'xl',
			windowClass: "modal-body-inner-footer",
		});
	};
	onBlur(product) {
		console.log("on blur");
		if (product) {
			product["onInputFocus"] = false;
		}
		this.onInputFocus = false;
	}
	callbackAfterChange = (e) => {};
	horizontalWizardStepperPrevious() {
		this.horizontalWizardStepper.previous();
	}
	onOrganizationAdd = (data) => {
		if (data.form.valid) {
		}
	};
}
