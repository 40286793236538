import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-dns-grid-pipe",
	templateUrl: "./dns-grid-pipe.component.html",
	styleUrls: ["./dns-grid-pipe.component.scss"],
})
export class DnsGridPipeComponent implements OnInit {
	data: any;
	key: any;
	subkey: any;
	id: any;
	public rowindex: any;
	constructor() {}

	ngOnInit(): void {}
}
