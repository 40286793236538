import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from "@angular/core";
import { TreeCrudViewService } from "app/base/tree-crud-view/services/tree-crud-view.service";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { _deepCopyJson } from "app/common/utils/utils";

@Component({
	selector: "app-tree-child",
	styleUrls: ["./tree-child.component.scss"],
	inputs: ["childData"],
	outputs: ["selectEvents"],
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ul class="tree">
			<ng-container *ngFor="let child of childData.children; let i = index">
				<form #treeChildForm="ngForm">
					<li id="{{ child.label }}">
						<input type="checkbox" id="{{ child.id }}" [checked]="true" />
						<label
							class="tree-label"
							for="{{ child.id }}"
							[ngClass]="{
								tree_label: child?.children && child.children.length
							}"
							[hidden]="child.isEditable"
							><span>{{ child.label }}</span>
							<div class="child-icon-list-hover">
								<i
									class="fa-light fa-pen-to-square text-primary"
									ngbTooltip="{{ 'UI.k_edit' | translate }}"
									*ngIf="permissions?.edit"
									placement="left"
									tooltipClass="custom-class"
									(click)="editChildNode(child)"
								></i>
								<i
									class="fa-light fa-square-plus"
									ngbTooltip="{{ 'UI.k_add' | translate }}"
									*ngIf="permissions?.add"
									placement="left"
									tooltipClass="custom-class"
									[hidden]="child.id?.split('.')?.length >= depth"
									(click)="addFirstLevelNode(child)"
								></i>
								<i
									class="fa-light fa-trash-can text-danger"
									ngbTooltip="{{ 'UI.k_delete' | translate }}"
									*ngIf="permissions?.delete"
									placement="left"
									tooltipClass="custom-class"
									(click)="deleteChild(child)"
								></i>
							</div>
						</label>
						<div class="edit-input-sec" *ngIf="child.isEditable">
							<button
								type="button"
								class="btn btn-icon btn-flat-secondary"
								rippleEffect
								id="btnClsWidget"
								name="btnClsWidget"
								(click)="cancelEditNode(child, treeChildForm)"
							>
								<i class="fa-light fa-xmark"></i>
							</button>
							<div class="form-group">
								<input
									type="text"
									class="form-control "
									placeholder="{{ 'UI.k_new_classification_placeholder' | translate }}"
									[(ngModel)]="child.label"
									#TDChildLabel="ngModel"
									[appWhiteSpaceCheck]="child?.label"
									treeDuplicateLabelValidator
									[treeConfig]="treeConfig"
									[currentIndex]="rootIndex"
									[currentItem]="child"
									required
									maxlength="40"
									id="cusInputLabel{{ i }}"
									name="cusInputLabel{{ i }}"
									[class.error]="TDChildLabel.invalid && treeChildForm.submitted"
								/>
								<span *ngIf="treeChildForm.submitted && TDChildLabel.invalid" class="invalid-form">
									<small class="form-text text-danger" *ngIf="TDChildLabel.errors.required">{{
										"Error.err_invalid_classification_name" | translate
									}}</small>
									<small class="form-text text-danger" *ngIf="TDChildLabel.errors.maxlength">{{
										"Error.err_max_classification_name" | translate
									}}</small>
									<small class="form-text text-danger" *ngIf="TDChildLabel.errors.whitespace">{{
										"UI.k_leading_trailing_space_validation" | translate
									}}</small>
									<small class="form-text text-danger" *ngIf="TDChildLabel.errors?.duplicateLabel">{{
										"UI.k_duplicate_classification_name" | translate
									}}</small>
								</span>
							</div>

							<button
								type="button"
								class="btn btn-flat-primary btn-sm"
								id="btnSave"
								name="btnSave"
								(click)="saveEditNodeEvent(child, treeChildForm)"
							>
								{{ "UI.k_save" | translate }}
							</button>
						</div>
						<app-tree-child
							[childData]="child"
							*ngIf="child?.children && child?.children.length"
							[permissions]="permissions"
							[rootIndex]="rootIndex"
							[is_edit]="is_edit"
							[depth]="depth"
							[treeConfig]="treeConfig"
							[child]="true"
							(isInputEnable)="onInputEnableCall($event)"
							class="sub-child-tree"
						></app-tree-child>
					</li>
					<li *ngIf="child.addChildNode" class="d-flex">
						<div class="edit-input-sec">
							<button
								type="button"
								class="btn btn-icon btn-flat-secondary"
								rippleEffect
								id="btnClsWidget"
								name="btnClsWidget"
								(click)="cancelCloseNode(child, treeChildForm)"
							>
								<i class="fa-light fa-xmark"></i>
							</button>
							<div class="form-group">
								<input
									type="text"
									class="form-control "
									placeholder="{{ 'UI.k_new_classification_placeholder' | translate }}"
									[(ngModel)]="newNodeChildData"
									[appWhiteSpaceCheck]="newNodeChildData"
									treeDuplicateLabelValidator
									[treeConfig]="treeConfig"
									[currentIndex]="rootIndex"
									#TDChildAddLabel="ngModel"
									maxlength="40"
									required
									id="cusInputLabel{{ i }}"
									name="cusInputLabel{{ i }}"
									[class.error]="TDChildAddLabel.invalid && treeChildForm.submitted"
								/>
								<span *ngIf="treeChildForm.submitted && TDChildAddLabel.invalid" class="invalid-form">
									<small class="form-text text-danger" *ngIf="TDChildAddLabel.errors.required">{{
										"Error.err_invalid_classification_name" | translate
									}}</small>
									<small class="form-text text-danger" *ngIf="TDChildAddLabel.errors.maxlength">{{
										"Error.err_max_classification_name" | translate
									}}</small>
									<small class="form-text text-danger" *ngIf="TDChildAddLabel.errors.whitespace">{{
										"UI.k_leading_trailing_space_validation" | translate
									}}</small>
									<small
										class="form-text text-danger"
										*ngIf="TDChildAddLabel.errors?.duplicateLabel"
										>{{ "UI.k_duplicate_classification_name" | translate }}</small
									>
								</span>
							</div>
							<button
								type="button"
								class="btn btn-flat-primary btn-sm"
								id="btnSave"
								name="btnSave"
								(click)="saveChildNode(child, treeChildForm)"
							>
								{{ "UI.k_save" | translate }}
							</button>
						</div>
					</li>
				</form>
			</ng-container>
		</ul>
	`,
})
export class TreeChildComponent implements OnInit, OnDestroy {
	public selectEvents: EventEmitter<any> = new EventEmitter<any>();
	public selectedNode: null;
	public dataChangesObservable: any;
	@Input() treeConfig = this.treeviewService.getTreeCOnfig();
	@Input() childData: any;
	@Input() is_edit = false;
	@Input("depth") depth = 3;
	@Input() permissions = {};
	public newNodeChildData = null;
	@Input("child") subChild = false;
	@Input() rootIndex = 0;
	@Output("isInputEnable") isInputEnable: EventEmitter<Boolean> = new EventEmitter<Boolean>();

	public tempVal;

	constructor(
		public treeviewService: TreeCrudViewService,
		public _changeDetectorRef: ChangeDetectorRef,
		private _translateService: TranslateService
	) {
		this.selectedNode = null;
	}

	onInputEnableCall = (evt) => {
		this.isInputEnable.emit(evt);
	};

	ngOnInit(): void {}

	deleteChild = (child) => {
		if (this.is_edit) {
			Swal.fire({
				title: this._translateService.instant("Message.msg_delete_title"),
				text: this._translateService.instant("Message.msg_delete_text"),
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: this._translateService.instant("Message.msg_delete_confirm"),
				cancelButtonText: this._translateService.instant("Message.msg_delete_cancel"),
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-danger ml-1",
				},
			}).then((result) => {
				if (result?.isConfirmed) {
					this.deleteChildEvent(child, this.treeConfig[this.rootIndex], this.treeConfig);
				}
			});
		} else {
			//console.log("deleteChild", child);
			this.deleteChildEvent(child, this.treeConfig[this.rootIndex], this.treeConfig);
		}
	};

	deleteChildEvent = (child, treeConfig, parent) => {
		//console.log("deleteChildEvent", child, treeConfig, parent);
		if (Array.isArray(treeConfig)) {
			let index = 0;
			for (const treeConfigElement of treeConfig) {
				if (child?.id == treeConfigElement?.id) {
					if (this.is_edit) {
						child.is_deleted = true;
					} else {
						treeConfig.splice(index, 1);
					}
					this.treeviewService._treeEventSubject.next({
						event: "delete",
						data: child,
						treeConfig: this.treeConfig,
					});
					break;
				} else if (treeConfigElement?.children && treeConfigElement?.children?.length > 0) {
					this.deleteChildEvent(child, treeConfigElement?.children, treeConfig);
				}
				index++;
			}
		} else {
			if (child?.id == treeConfig?.id) {
				if (this.is_edit) {
					child.is_deleted = true;
				} else {
					const index = parent.indexOf(child);
					if (index !== -1) {
						parent.splice(index, 1);
					}
				}

				this.treeviewService._treeEventSubject.next({
					event: "delete",
					data: child,
					treeConfig: this.treeConfig,
				});
			} else if (treeConfig?.children && treeConfig?.children?.length > 0) {
				this.deleteChildEvent(child, treeConfig?.children, treeConfig);
			}
		}
	};

	editChildNode(item) {
		this.isInputEnable.emit(true);
		this.tempVal = _deepCopyJson(item);
		item["isEditable"] = true;
	}

	cancelEditNode(item, form) {
		form.submitted = true;
		this.isInputEnable.emit(false);
		item["isEditable"] = false;
		if (this.tempVal && this.tempVal?.id == item?.id) {
			item["label"] = this.tempVal?.label;
		}

		if (form?.valid) {

			form.submitted = false;
		}
	}

	saveEditNodeEvent = (item, form) => {
		//console.log("saveEditNodeEvent", item);
		form.submitted = true;
		if (form?.valid) {
			this.treeConfig.every((e) => {
				if (e && e.children) {
					e.children.every((element) => {
						if (element.id === item.id) {
							element.label = item.label;
							element.isEditable = false;
							item.isEditable = false;
							return false;
						} else {
							this.editForEveryNode(element, item);
						}
						return true;
					});
				}
			});
			this.treeviewService.setTreeConfig(this.treeConfig);
			this.treeConfig = JSON.parse(JSON.stringify(this.treeConfig));
			this._changeDetectorRef.markForCheck();
			this._changeDetectorRef.detectChanges();
			item.isEditable = false;
			this.treeviewService._treeEventSubject.next({
				event: "edit",
				data: item,
				treeConfig: this.treeConfig,
			});
			this.onInputEnableCall(false);
		}
	};

	editForEveryNode(e, item) {
		//console.log("editForEveryNode", item);
		if (e && e.children) {
			e.children.every((ele) => {
				if (ele.id === item.id) {
					ele.label = item.label;
					ele = item;
					ele.isEditable = false;
					item.isEditable = false;
					// if (!this.is_edit) {
					// 	this.treeviewService._treeEventSubject.next({
					// 		event: "edit",
					// 		data: item,
					// 		treeConfig: this.treeConfig,
					// 	});
					// }

					return false;
				} else {
					this.editForEveryNode(ele, item);
				}
				return true;
			});
		}
	}

	saveChildNode(child, form) {
		form.submitted = true;
		if (form.valid) {
			this.treeConfig.forEach((e, index) => {
				let newId;
				if (child && child.children) {
					newId = child.children.length + 1;
				} else {
					newId = "1";
				}
				if (e && e.children && e.children.length) {
					e.children.forEach((ele) => {
						if (ele.id === child.id) {
							if (ele && ele?.children) {
								ele.children.push({
									id: child.id + "." + newId,
									label: this.newNodeChildData,
									is_add: true,
									isEditable: false,
								});
							} else {
								ele["children"] = [
									{
										id: child.id + "." + newId,
										label: this.newNodeChildData,
										is_add: true,
										isEditable: false,
									},
								];
							}
							child["isEditable"] = false;
							child["addChildNode"] = false;
						} else {
							// console.log("unmatched",ele);
							this.checkChildMatching(ele, child, newId);
						}
					});
				} else {
					if (this.newNodeChildData && e.id === child.id) {
						e["children"] = [
							{
								id: child.id + "." + newId,
								label: this.newNodeChildData,
								is_add: true,
							},
						];
					}
				}
			});
			this.treeviewService.setTreeConfig(this.treeConfig);
			this.childData = JSON.parse(JSON.stringify(this.childData));
			this.treeConfig = JSON.parse(JSON.stringify(this.treeConfig));
			this._changeDetectorRef.markForCheck();
			this._changeDetectorRef.detectChanges();
			const filterData = this.treeConfig.filter((id) => id.id === this.childData.id);
			if (filterData && filterData.length) {
				this.childData = filterData[0];
			}
			this._changeDetectorRef.markForCheck();
			this._changeDetectorRef.detectChanges();
			this.treeviewService._treeEventSubject.next({
				event: "add",
				data: child,
				treeConfig: this.treeConfig,
			});
			this.onInputEnableCall(false);
			this.newNodeChildData = null;
		}
	}

	checkChildMatching(ele, currentData, newId) {
		if (ele.children) {
			ele.children.every((subele) => {
				if (subele.id === currentData.id) {
					// console.log("subele match",subele);
					if (subele && subele.children) {
						subele.children.push({
							id: currentData.id + "." + newId,
							label: this.newNodeChildData,
							is_add: true,
						});
					} else {
						subele["children"] = [
							{
								id: currentData.id + "." + newId,
								label: this.newNodeChildData,
								is_add: true,
							},
						];
					}
					this.newNodeChildData = null;
					currentData["addChildNode"] = false;
					this.treeviewService.setTreeConfig(this.treeConfig);
					this.childData = JSON.parse(JSON.stringify(ele));
					this.treeConfig = JSON.parse(JSON.stringify(this.treeConfig));
					this.treeConfig.forEach((mainEle) => {
						if (mainEle && mainEle.children) {
							mainEle.children.forEach((firstEle) => {
								if (firstEle.id === ele.id) {
									firstEle = ele;
								}
							});
						}
					});
					this._changeDetectorRef.markForCheck();
					this._changeDetectorRef.detectChanges();
					this.treeviewService._treeEventSubject.next({
						event: "add",
						data: currentData,
						treeConfig: this.treeConfig,
					});
					this.treeviewService.setUpdatedData(this.treeConfig);
					return false;
				} else {
					this.checkChildMatching(subele, currentData, newId);
				}
				return true;
			});
		} else {
			// pending
		}
	}

	addFirstLevelNode(child) {
		this.isInputEnable.emit(true);
		child["addChildNode"] = true;
		child["openChildTree"] = true;
	}

	cancelCloseNode(item, form) {
		form.submitted = true;
		item["addChildNode"] = false;
		item["addNode"] = false;
		if (!form.valid) {
			this.isInputEnable.emit(false);
			this.treeConfig = JSON.parse(JSON.stringify(this.treeConfig));
			this._changeDetectorRef.markForCheck();
			this._changeDetectorRef.detectChanges();
			form.submitted = false;
		}
		form?.resetForm();
	}

	ngOnDestroy() {
		if (this.is_edit) {
			this.treeviewService.setTreeConfig([]);
		}
	}
}
