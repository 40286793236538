import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnInit,
	Output,
	Renderer2,
	SimpleChanges,
	ViewChild,
} from "@angular/core";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { default as _map } from "lodash-es/map";
import uniq from "lodash-es/uniq";
import { ToastrService } from "ngx-toastr";
import { CookieService } from "ngx-cookie-service";
import { TranslateService } from "@ngx-translate/core";
import { FlatpickrOptions } from "ng2-flatpickr";
import moment from "moment";
import { _deepCopyJson, getTranslatableKey } from "app/common/utils/utils";
import { TreeviewItem } from "ngx-treeview";

const storageName = "recentFilters";

@Component({
	selector: "app-search",
	templateUrl: "./search.component.html",
	styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit, OnChanges {
	@ViewChild("inputElement") inputElement: ElementRef;

	@Output() getfilterData: EventEmitter<any> = new EventEmitter<any>();
	@Output() isExpandSearchWidth: EventEmitter<any> = new EventEmitter<boolean>();
	@Output() genericFilter: EventEmitter<any> = new EventEmitter<any>();
	@Output("hideShowDetails") hideShowDetails = new EventEmitter();
	@Input() filterDetails: (event: any) => void;
	@Input() requester: any = [];
	@Input() columnOptions = [];
	@Input() showDataTable: boolean;
	@Input() conditionOptions = [];
	@Input() Options = [];
	@Input() savedData = [];
	@Input() showRightsection: boolean;
	@Input() customWidth: any;
	@Input() module: any;
	@Input() searchObject = {
		advannceFilerPopup: false,
		userEnterPopup: false,
		multipleFilterPopup: false,
		userSearchData: null,
		userSearchString: null,
		userDispalyString: null,
		userInputSearchData: null,
		filterData: [],
		previousSearchedData: null,
		searchCount: 0,
		copyOfMainDatasource: [],
		addFilterArray: [
			{
				id: 1,
				selectedColumn: this.columnOptions[0],
				selectedCondition: null,
				dataConfig: null,
				optionList: [],
			},
		],
		multiPleFilterCondition: [],
		savedFilterData: [],
		searchedData: {},
		duplicatesavedFilterData: [],
		recentSearchedData: [],
		dupSearchedData: [],
		filterItem: [],
		isScroll: false,
	};
	public basicSelectedOption: number = 10;
	public SelectionType = SelectionType;
	private tempData = [];
	public ColumnMode = ColumnMode;
	public saveText;
	public searchEvent = false;
	public saveFilter = false;
	public pages = [];
	public activeIndex = 0;
	searchItem: Array<any> = [];
	private cookieValue: string;
	public pageSearchLimit;
	public dupColOption = [];
	public option_id;
	public option_name;
	customW = "0px";
	customH = "0px";

	private storageList = [];
	private storageObj = {};
	public dateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: false,
		static: true,
	};
	public getTranslatableKey = getTranslatableKey;
	public text: String;
	public minimum_search_char = 3;
	public quick_search = false;

	@Input() hideOnEsc: boolean;
	@HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(event: KeyboardEvent) {
		this.close("clicked outside");
	}

	public wasInside = false;

	@HostListener("click")
	clickInside() {
		this.text = "clicked inside";
		this.wasInside = true;

		this.close(this.text);
	}

	@HostListener("document:click")
	clickout() {
		if (!this.wasInside) {
			this.text = "clicked outside";
		}
		this.wasInside = false;
		this.close(this.text);
	}

	public rangeValidation = false;
	public validationFieldId;
	public validationMsg;

	constructor(
		private _toastr: ToastrService,
		private cookieService: CookieService,
		private _translateService: TranslateService,
		private renderer: Renderer2,
		private eRef: ElementRef
	) {
		this.text = "no clicks yet";
		this.saveText = false;
		this.storageObj = JSON.parse(localStorage.getItem(storageName) || "{}");
		this.storageList = this.storageObj[this.module] || [];
	}

	ngOnInit(): void {
		this.isExpandSearchWidth.emit(this.searchEvent);
		this.dupColOption = _deepCopyJson(this.columnOptions);
		this.searchObject.previousSearchedData = JSON.parse(localStorage.getItem("searchData"));
		//if (this.searchObject.previousSearchedData) {
		//   this.searchObject.previousSearchedData = this.searchObject.previousSearchedData.split(':')[1]
		// }
		if (this.savedData) {
			this.searchObject.savedFilterData = this.savedData;
			this.searchObject.duplicatesavedFilterData = this.savedData;
		}
		this.columnOptions.sort((a, b) => (a.column_name > b.column_name ? 1 : -1));
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.dupColOption?.length > 0) {
			this.columnOptions = this.dupColOption;
		}
		this.dupColOption = _deepCopyJson(this.columnOptions);

		//this.searchObject.recentSearchedData = localStorage.getItem('search-item')
		let latestStorageData = JSON.parse(localStorage.getItem(storageName));
		if (latestStorageData) {
			this.searchObject.recentSearchedData = latestStorageData[this.module] || [];
			this.storageList = this.searchObject.recentSearchedData;
			this.searchObject.dupSearchedData = latestStorageData[this.module] || [];
		}

		//if(this.savedData){
		this.searchObject.savedFilterData = this.savedData;
		this.searchObject.duplicatesavedFilterData = this.savedData;
		//}
		if (!this.searchObject?.recentSearchedData) {
			this.searchObject.recentSearchedData = [];
			this.searchObject.dupSearchedData = [];
		}
		if (!this.customWidth) {
			//this.customWidth = 'height:36px'
			this.customW = "300px";
			this.customH = "36px";
		} else {
			this.customW = "224px";
			this.customH = "28px";
		}

		this.columnOptions.sort((a, b) => (a.column_name > b.column_name ? 1 : -1));
		this.getFields();
	}

	selectAddTagMethod(name) {
		return {
			name: name,
			tag: true,
		};
	}

	addFilter(filterForm) {
		filterForm.submitted = true;
		let lastid = this.searchObject.addFilterArray[this.searchObject.addFilterArray?.length - 1].id;
		if (filterForm.form.status === "VALID") {
			this.searchObject.addFilterArray.push({
				id: lastid + 1,
				selectedColumn: null,
				selectedCondition: null,
				dataConfig: null,
				optionList: [],
			});
		}
	}

	deleteFilter(item) {
		if (this.searchObject?.addFilterArray?.length > 1) {
			this.searchObject?.addFilterArray?.forEach((e, index) => {
				if (e.id === item.id) {
					this.searchObject.addFilterArray.splice(index, 1);
				}
			});
		}
		this.getFields();
	}

	formatDate(date) {
		let new_data = moment(date).format("YYYY-MM-DD");
		return new_data;
	}

	columnSelectionEvent(item) {
		item.selectedCondition = null;
		this.getFields();
	}

	conditionSelectionEvent(item, conditions) {
		if (item.selectedCondition) {
			if (item.selectedCondition.type != "calendar") {
				item.optionList = [];
				item.dataConfig = null;
			}
		}
		if (conditions) {
			if (
				conditions.type == "dropdown" ||
				conditions.type == "multiDropdown" ||
				conditions.type == "multiDropdownAdd" ||
				conditions.type == "combobox" ||
				conditions.type == "combobox_multiDropdown" ||
				conditions.type == "badgebox" ||
				conditions.type == "badgebox_multiDropdown" ||
				conditions.type == "treebox" ||
				conditions.type == "treebox_multiDropdown"
			) {
				let name = item.selectedColumn.options;
				if (item.selectedColumn.optionKey) {
					let subkeydata = item.selectedColumn.optionKey;

					item.optionList = this.Options[name][subkeydata];
				} else {
					item.optionList = this.Options[name];
				}

				// this.option_name = item.selectedColumn.option_name;
				// this.option_id = item.selectedColumn.option_id;
			}

			if (conditions.type == "treebox" || conditions.type == "treebox_multiDropdown") {
				let name = item.selectedColumn.options;
				if (item.selectedColumn.optionKey) {
					let subkeydata = item.selectedColumn.optionKey;
					let tree_option_list = [];
					let option_list = this.Options[name][subkeydata];
					option_list?.forEach((ele) => {
						let data = new TreeviewItem(ele);
						tree_option_list.push(data);
					});

					item.optionList = tree_option_list;
				} else {
					let option_list = this.Options[name];
					let tree_option_list = [];
					option_list?.forEach((ele) => {
						let data = new TreeviewItem(ele);
						tree_option_list.push(data);
					});

					item.optionList = tree_option_list;
				}

				// this.option_name = item.selectedColumn.option_name;
				// this.option_id = item.selectedColumn.option_id;
			}
		}
	}

	applyMultipleFilter(filterForm, filterItem, key) {
		filterForm.submitted = true;

		if (filterForm.form.status === "VALID" && !this.rangeValidation) {
			this.searchEvent = false;
			this.isExpandSearchWidth.emit(this.searchEvent);
			let multiFilterTableData = [];
			this.searchObject.userSearchData = [];
			this.searchObject.userSearchString = [];
			this.searchObject.filterItem = [];
			this.searchObject.addFilterArray?.forEach((ele) => {
				if (
					ele.selectedCondition.type == "dropdown" ||
					ele.selectedCondition.type == "combobox" ||
					ele.selectedCondition.type == "badgebox"
				) {
					if (typeof ele.dataConfig === "number" || !ele.dataConfig?.name) {
						let colName = "";
						for (let i = 0; i < ele.optionList?.length; i++) {
							if (ele.dataConfig == ele.optionList[i][ele.selectedColumn.option_id]) {
								colName = ele.optionList[i][ele.selectedColumn.option_name];
							}
						}
						this.searchObject.userSearchData.push({
							keyFilterValue: ele.selectedCondition.name + "(" + colName + ")",
							key: ele.selectedColumn.column_name,
							selected: true,
						});
						this.searchObject.userSearchString.push({
							key: ele.selectedColumn.column_name,
							condition: ele.selectedCondition.key,
							value: ele.dataConfig,
							db_col: ele.selectedColumn.key,
							type: ele.selectedCondition.type,
							subkey: ele.selectedColumn.subkey,
							suffix: ele.selectedColumn.suffix,
							table: ele.selectedColumn.table,
							isint: ele?.selectedColumn?.isint,
							islist: ele?.selectedColumn?.islist,
						});
					} else {
						this.searchObject.userSearchData.push({
							keyFilterValue:
								typeof ele.dataConfig === "string"
									? ele.dataConfig
									: ele.selectedCondition.name + "(" + ele.dataConfig.name + ")",
							key: ele.selectedColumn.column_name,
							selected: true,
						});
						this.searchObject.userSearchString.push({
							key: ele.selectedColumn.column_name,
							condition: ele.selectedCondition.key,
							value: ele.dataConfig,
							db_col: ele.selectedColumn.key,
							type: ele.selectedCondition.type,
							subkey: ele.selectedColumn.subkey,
							suffix: ele.selectedColumn.suffix,
							table: ele.selectedColumn.table,
							isint: ele?.selectedColumn?.isint,
							islist: ele?.selectedColumn?.islist,
						});
					}
				} else if (ele.selectedCondition.type == "treebox") {
					this.searchObject.userSearchData.push({
						keyFilterValue:
							typeof ele.dataConfig === "string"
								? ele.dataConfig
								: ele.selectedCondition.name + "(" + ele.dataConfig.name + ")",
						key: ele.selectedColumn.column_name,
						selected: true,
					});
					this.searchObject.userSearchString.push({
						key: ele.selectedColumn.column_name,
						condition: ele.selectedCondition.key,
						value: ele.dataConfig.id,
						db_col: ele.selectedColumn.key,
						type: ele.selectedCondition.type,
						subkey: ele.selectedColumn.subkey,
						suffix: ele.selectedColumn.suffix,
						table: ele.selectedColumn.table,
						isint: ele?.selectedColumn?.isint,
						islist: ele?.selectedColumn?.islist,
					});
				} else if (
					ele.selectedCondition.type == "multiDropdown" ||
					ele.selectedCondition.type == "combobox_multiDropdown" ||
					ele.selectedCondition.type == "badgebox_multiDropdown" ||
					ele.selectedCondition.type == "treebox_multiDropdown"
				) {
					if (typeof ele.dataConfig === "number" || !ele.dataConfig?.name) {
						let colName = [];
						for (let i = 0; i < ele.optionList?.length; i++) {
							for (let j = 0; j < ele.dataConfig?.length; j++) {
								if (ele.dataConfig[j] == ele.optionList[i][ele.selectedColumn.option_id]) {
									colName.push(ele.optionList[i][ele.selectedColumn.option_name]);
								}
							}
						}
						this.searchObject.userSearchData.push({
							keyFilterValue: ele.selectedCondition.name + "(" + colName + ")",
							key: ele.selectedColumn.column_name,
							selected: true,
						});
						this.searchObject.userSearchString.push({
							key: ele.selectedColumn.column_name,
							condition: ele.selectedCondition.key,
							value: ele.dataConfig,
							db_col: ele.selectedColumn.key,
							type: ele.selectedCondition.type,
							subkey: ele.selectedColumn.subkey,
							suffix: ele.selectedColumn.suffix,
							table: ele.selectedColumn.table,
							isint: ele?.selectedColumn?.isint,
							islist: ele?.selectedColumn?.islist,
						});
					} else {
						this.searchObject.userSearchData.push({
							keyFilterValue:
								typeof ele.dataConfig === "string"
									? ele.dataConfig
									: ele.selectedCondition.name + "(" + ele.dataConfig.name + ")",
							key: ele.selectedColumn.column_name,
							selected: true,
						});
						this.searchObject.userSearchString.push({
							key: ele.selectedColumn.column_name,
							condition: ele.selectedCondition.key,
							value: ele.dataConfig,
							db_col: ele.selectedColumn.key,
							type: ele.selectedCondition.type,
							subkey: ele.selectedColumn.subkey,
							suffix: ele.selectedColumn.suffix,
							table: ele.selectedColumn.table,
							isint: ele?.selectedColumn?.isint,
							islist: ele?.selectedColumn?.islist,
						});
					}
				} else if (ele.selectedCondition.type == "calendar") {
					this.searchObject.userSearchData.push({
						keyFilterValue: ele.selectedCondition.name + "(" + ele.dataConfig + ")",
						key: ele.selectedColumn.column_name,
						selected: true,
					});

					this.searchObject.userSearchString.push({
						key: ele.selectedColumn.column_name,
						condition: ele.selectedCondition.key,
						value: this.formatDate(ele.dataConfig[0]),
						db_col: ele.selectedColumn.key,
						type: ele.selectedCondition.type,
						subkey: ele.selectedColumn.subkey,
						suffix: ele.selectedColumn.suffix,
						table: ele.selectedColumn.table,
						isint: ele?.selectedColumn?.isint,
						islist: ele?.selectedColumn?.islist,
					});
				} else if (ele.selectedCondition.type == "input" || ele.selectedCondition.type == "onlyNumberInput") {
					this.searchObject.userSearchData.push({
						keyFilterValue: ele.selectedCondition.name + "(" + ele.dataConfig + ")",
						key: ele.selectedColumn.column_name,
						selected: true,
					});

					this.searchObject.userSearchString.push({
						key: ele.selectedColumn.column_name,
						condition: ele.selectedCondition.key,
						value: ele.dataConfig,
						db_col: ele.selectedColumn.key,
						type: ele.selectedCondition.type,
						subkey: ele.selectedColumn.subkey,
						suffix: ele.selectedColumn.suffix,
						table: ele.selectedColumn.table,
						isint: ele?.selectedColumn?.isint,
						islist: ele?.selectedColumn?.islist,
					});
				} else if (ele.selectedCondition.type == "toggle") {
					let toggleData = false;
					if (!ele.dataConfig) {
						toggleData = this._translateService.instant("UI.k_inactive");
						ele.dataConfig = false;
					} else {
						toggleData = this._translateService.instant("UI.k_active");
					}
					this.searchObject.userSearchData.push({
						keyFilterValue: ele.selectedCondition.name + "(" + toggleData + ")",
						key: ele.selectedColumn.column_name,
						selected: true,
					});
					this.searchObject.userSearchString.push({
						key: ele.selectedColumn.column_name,
						condition: ele.selectedCondition.key,
						value: ele.dataConfig,
						db_col: ele.selectedColumn.key,
						type: ele.selectedCondition.type,
						subkey: ele.selectedColumn.subkey,
						suffix: ele.selectedColumn.suffix,
						table: ele.selectedColumn.table,
						isint: ele?.selectedColumn?.isint,
						isboolean: ele?.selectedColumn?.isboolean,
						islist: ele?.selectedColumn?.islist,
					});
				} else if (ele.selectedCondition.type == "numInput") {
					let valList = ele.dataConfig.split(",");
					this.searchObject.userSearchData.push({
						keyFilterValue: ele.selectedCondition.name + "(" + ele.dataConfig + ")",
						key: ele.selectedColumn.column_name,
						selected: true,
					});
					this.searchObject.userSearchString.push({
						key: ele.selectedColumn.column_name,
						condition: ele.selectedCondition.key,
						value: valList,
						db_col: ele.selectedColumn.key,
						type: ele.selectedCondition.type,
						subkey: ele.selectedColumn.subkey,
						suffix: ele.selectedColumn.suffix,
						table: ele.selectedColumn.table,
						isint: ele?.selectedColumn?.isint,
						islist: ele?.selectedColumn?.islist,
					});
				} else {
					this.searchObject.userSearchData.push({
						keyFilterValue:
							typeof ele.dataConfig === "string"
								? ele.dataConfig
								: ele.selectedCondition.name + "(" + _map(ele.dataConfig, "name") + ")",
						key: ele.selectedColumn.column_name,
						selected: true,
					});

					this.searchObject.userSearchString.push({
						key: ele.selectedColumn.column_name,
						condition: ele.selectedCondition.key,
						value: _map(ele.dataConfig, "name"),
						db_col: ele.selectedColumn.key,
						type: ele.selectedCondition.type,
						subkey: ele.selectedColumn.subkey,
						suffix: ele.selectedColumn.suffix,
						table: ele.selectedColumn.table,
						isint: ele?.selectedColumn?.isint,
						islist: ele?.selectedColumn?.islist,
					});
				}
				let filterValue;
				this.searchObject.filterItem.push(ele);
			});

			this.searchObject.advannceFilerPopup = false;
			this.searchObject.userEnterPopup = false;
			this.searchObject.multipleFilterPopup = false;
			this.searchObject.searchCount = this.searchObject.userSearchData?.length;
			let Userstring = this.searchObject.userSearchData;

			if (key === "save") {
				let count = 0;

				this.searchObject.savedFilterData = Userstring;
				this.searchObject.duplicatesavedFilterData = Userstring;
				this._toastr.success(
					this._translateService.instant("Message.msg_save_success"),
					this._translateService.instant("Message.msg_save_success"),
					{
						toastClass: "toast ngx-toastr custom-toast",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
			}
			this.searchObject["type"] = key;
			//this.searchObject["filterItem"] = filterItem;
			let arrayUsr = [];
			let strUsr;
			for (let i = 0; i < this.searchObject.userSearchData?.length; i++) {
				strUsr =
					this.searchObject.userSearchData[i].key + ":" + this.searchObject.userSearchData[i].keyFilterValue;
				arrayUsr.indexOf(strUsr) === -1 ? arrayUsr.push(strUsr) : console.log("This item already exists");
			}
			var finalString = arrayUsr.join();
			let count = 0;
			for (let i = 0; i < this.storageList?.length; i++) {
				if (this.storageList[i].name) {
					let recentFilterName = this.storageList[i].name.toLowerCase();
					if (JSON.stringify(recentFilterName) == JSON.stringify(finalString.toLowerCase())) {
						count = 1;
						if (count == 1) {
							this.storageList = this.storageList.filter(
								(item) => item.name.toLowerCase() !== recentFilterName
							);
							count = 0;
						}
						break;
					}
				}
			}
			if (count == 0) {
				this.storageList.splice(0, 0, {
					name: finalString,
				});
				// this.storageList.push({
				//     name: finalString
				// });
				this.storageObj[this.module] = this.storageList;
				localStorage.setItem(storageName, JSON.stringify(this.storageObj));
			}

			let copyOption = [];
			let condCopyOption = [];
			let keyVal = [];
			for (let i = 0; i < this.searchObject.filterItem?.length; i++) {
				let name = this.searchObject.filterItem[i].selectedColumn.column_name;
				copyOption[name] = this.searchObject.filterItem[i].optionList;
				this.searchObject.filterItem[i].optionList = [];
				condCopyOption[name + i] = this.searchObject.filterItem[i].selectedColumn.operatorOptions;
				this.searchObject.filterItem[i].selectedColumn.operatorOptions = [];
			}
			for (let key in condCopyOption) {
				keyVal.push({ key: key.slice(0, -1), val: condCopyOption[key] });
				if (condCopyOption[key]?.length == 0) {
					let sliceKey = key.slice(0, -1);
					let data = keyVal.find((item) => item.key == sliceKey);
					condCopyOption[key] = data.val;
				}
			}
			this.searchObject.userDispalyString = "";
			for (let i = 0; i < this.searchObject.userSearchData?.length; i++) {
				if (this.searchObject.userDispalyString) {
					this.searchObject.userDispalyString =
						this.searchObject.userDispalyString +
						this.searchObject.userSearchData[i]["key"] +
						":" +
						this.searchObject.userSearchData[i]["keyFilterValue"] +
						",";
				} else {
					this.searchObject.userDispalyString =
						this.searchObject.userSearchData[i]["key"] +
						":" +
						this.searchObject.userSearchData[i]["keyFilterValue"] +
						",";
				}
			}

			let searchObject = _deepCopyJson(this.searchObject);

			this.getfilterData.emit(searchObject);

			for (let i = 0; i < this.searchObject.filterItem?.length; i++) {
				let names = this.searchObject.filterItem[i].selectedColumn.column_name;
				this.searchObject.filterItem[i].optionList = copyOption[names];
				this.searchObject.filterItem[i].selectedColumn.operatorOptions = condCopyOption[names + i];
			}
		}
	}

	applySavedFilter(e, filterItem) {
		this.searchObject.userSearchData = e["ui_display"];
		this.searchObject.userSearchString = e["filter_string"];
		this.searchObject["type"] = "apply";
		this.searchObject.advannceFilerPopup = false;
		this.searchObject.userEnterPopup = false;
		this.searchObject.multipleFilterPopup = false;
		this.searchEvent = false;
		this.isExpandSearchWidth.emit(this.searchEvent);
		this.searchObject.searchCount = this.searchObject.userSearchData?.length;
		this.searchObject.addFilterArray = [];
		this.searchObject.addFilterArray = e["filter_item"];
		let userString;
		if (typeof filterItem.userSearchData != "string") {
			for (let i = 0; i < filterItem.userSearchData?.length; i++) {
				if (i == 0) {
					userString = filterItem.userSearchData[i]["key"] + ":";
				} else {
					let userString1 = "," + filterItem.userSearchData[i]["key"] + ":";
					userString = userString + userString1;
				}
				userString = userString.concat(filterItem.userSearchData[i]["keyFilterValue"]);
			}
			if (userString.endsWith(")")) {
				userString = userString + ",";
			}
		}
		this.storeRecentFilter(userString);

		this.searchObject.userDispalyString = "";
		for (let i = 0; i < this.searchObject.userSearchData?.length; i++) {
			if (this.searchObject.userDispalyString) {
				this.searchObject.userDispalyString =
					this.searchObject.userDispalyString +
					this.searchObject.userSearchData[i]["key"] +
					":" +
					this.searchObject.userSearchData[i]["keyFilterValue"] +
					",";
			} else {
				this.searchObject.userDispalyString =
					this.searchObject.userSearchData[i]["key"] +
					":" +
					this.searchObject.userSearchData[i]["keyFilterValue"] +
					",";
			}
		}

		this.getfilterData.emit(this.searchObject);
	}

	searchData(event) {
		if (
			this.searchObject.userSearchData &&
			(this.searchObject.userSearchData?.length > 3 || (event && event?.length > 3))
		) {
			this.searchObject.advannceFilerPopup = true;
			this.searchObject.userEnterPopup = true;
			this.searchObject.multipleFilterPopup = false;
			this.searchObject.filterData = uniq(this.searchObject.filterData);
		} else {
			this.searchObject.filterData = [];
			this.searchObject.advannceFilerPopup = true;
			this.searchObject.userEnterPopup = false;
			this.searchObject.multipleFilterPopup = false;
			this.searchObject.userSearchData = this.searchObject.userSearchData;
			this.searchObject.searchCount = 0;
			//this.searchObject.previousSearchedData = JSON.parse(localStorage.getItem('searchData'))
			if (this.searchObject.previousSearchedData) {
				this.searchObject.previousSearchedData = this.searchObject.previousSearchedData.split(",");
			}
		}
	}

	recentSearchClickedEvent(storedItem) {
		this.quick_search = false;
		this.searchObject.filterData = [];
		this.searchObject.advannceFilerPopup = false;
		this.searchObject.userEnterPopup = false;
		this.searchObject.multipleFilterPopup = false;
		this.searchObject.filterItem = [];
		this.searchObject.userSearchData = [];
		this.searchObject.userSearchData = storedItem.name;
		this.searchObject.addFilterArray = [];
		this.searchObject.addFilterArray.push({
			id: this.searchObject.addFilterArray?.length + 1,
			selectedColumn: this.columnOptions[0],
			selectedCondition: null,
			dataConfig: null,
			optionList: [],
		});
		this.searchObject.searchCount = 0;
		this.searchEvent = false;
		this.isExpandSearchWidth.emit(this.searchEvent);
		if (!storedItem.name.includes("),")) {
			storedItem.name = storedItem.name + ",";
		}
		if (storedItem.name.endsWith(")")) {
			storedItem.name = storedItem.name + ",";
		}
		this.customSearchFilter(storedItem.name);
	}

	applyFilter(item: any) {
		this.searchObject.advannceFilerPopup = false;

		this.searchObject.userEnterPopup = false;
		this.searchObject.multipleFilterPopup = false;
		item["selected"] = true;
		if (typeof this.searchObject.userSearchData === "object") {
			this.searchObject.userSearchData.push({
				keyFilterValue: item[item.keyFilter],
				key: item.keyFilter,
				selected: true,
			});
		} else {
			this.searchObject.userSearchData = [
				{
					keyFilterValue: item[item.keyFilter],
					key: item.keyFilter,
					selected: true,
				},
			];
		}

		//localStorage.setItem("searchData", (JSON.stringify(this.searchObject.userSearchData)))
		this.searchObject.searchCount = this.searchObject.userSearchData?.length;
	}

	redirectToadvanceFilter() {
		this.searchObject.advannceFilerPopup = false;
		this.searchObject.userEnterPopup = false;
		this.searchObject.multipleFilterPopup = true;
		this.saveFilter = true;
		this.searchEvent = true;
		this.isExpandSearchWidth.emit(this.searchEvent);
	}

	backEvent() {
		this.searchObject.advannceFilerPopup = true;
		this.searchObject.userEnterPopup = false;
		this.searchObject.multipleFilterPopup = false;
	}

	popupEvent() {
		this.searchEvent = true;
		this.isExpandSearchWidth.emit(this.searchEvent);
		if (
			!this.searchObject.advannceFilerPopup &&
			!this.searchObject.userEnterPopup &&
			!this.searchObject.multipleFilterPopup
		) {
			this.searchObject.advannceFilerPopup = true;
			this.searchObject.userEnterPopup = false;
			this.searchObject.multipleFilterPopup = false;
		}
	}

	loadAllData() {
		this.searchObject.isScroll = true;
	}

	searchUpdate(event) {
		//fromEvent(this.inputElement.nativeElement, 'input')
		//  .pipe(map((event: Event) => (event.target as HTMLInputElement).value))
		//  .pipe(debounceTime(500))
		//  .subscribe(data => this.autoSuggestion(event));

		this.autoSuggestion(event);

		let duplicateDict = {};
		let duplicateList = [];
		let duplicateRecentList = [];
		this.searchObject.savedFilterData = this.searchObject.duplicatesavedFilterData;
		this.searchObject.recentSearchedData = this.searchObject.dupSearchedData;
		this.searchObject.userSearchData = event.target.value;
		const val = event.target.value.toLowerCase();
		//this.customSearchFilter(event.target.value)
		if (event.target.value) {
			let userFilter = [];
			for (var i = 0; i < this.searchObject.savedFilterData?.length; i++) {
				if (
					this.searchObject.savedFilterData[i].ui_display &&
					typeof this.searchObject.savedFilterData[i].ui_display != "string"
				) {
					userFilter = this.searchObject.savedFilterData[i].ui_display.filter(
						(it) => val.includes(it.keyFilterValue.toLowerCase()) || val.includes(it.key.toLowerCase())
					);
				}
				if (userFilter?.length == 0) {
					if (this.searchObject.savedFilterData[i].filter_string) {
						let key = this.searchObject.savedFilterData[i].filter_string.filter((it) =>
							new RegExp(event.target.value, "i").test(it.key)
						);
						let val = this.searchObject.savedFilterData[i].filter_string.filter((it) =>
							new RegExp(event.target.value, "i").test(it.value)
						);
						let cond = this.searchObject.savedFilterData[i].filter_string.filter((it) =>
							new RegExp(event.target.value, "i").test(it.condition)
						);

						if (key?.length > 0 || val?.length > 0 || cond?.length > 0) {
							//duplicateDict["ui_display"] = (this.searchObject.savedFilterData[i])
							duplicateList.push(this.searchObject.savedFilterData[i]);
						}
					}
				} else {
					duplicateList.push(this.searchObject.savedFilterData[i]);
				}
			}
			this.searchObject.savedFilterData = [];
			this.searchObject.savedFilterData = duplicateList;
			let event_val = event.target.value.toLowerCase();
			let duplicateRecentList = this.searchObject.recentSearchedData.filter((te) =>
				te.name.toLowerCase().includes(event_val.toString())
			);

			this.searchObject.recentSearchedData = [];
			this.searchObject.recentSearchedData = duplicateRecentList;
		} else {
			this.searchObject.userSearchString = [];
			this.searchObject.userSearchData = null;
			this.searchObject.addFilterArray = [];
			this.searchObject.savedFilterData = [];
			this.searchObject.addFilterArray.push({
				id: this.searchObject.addFilterArray?.length + 1,
				selectedColumn: this.columnOptions[0],
				selectedCondition: null,
				dataConfig: null,
				optionList: [],
			});
			this.getfilterData.emit(this.searchObject);
			this.searchObject.advannceFilerPopup = false;
			this.searchObject.searchCount = 0;

			this.searchObject.userEnterPopup = false;
			this.searchObject.multipleFilterPopup = false;
		}
	}

	autoSuggestion(event) {
		const key = 38;
		let val = event.target.value.toLowerCase().replace(" : ", ":");
		let toAppend = event.target.value.replace(" : ", ":");

		if (val.charAt(val?.length - 1) == ",") {
			val = val.substr(0, val?.length - 1);
		}
		if (toAppend.charAt(val?.length - 1) == ",") {
			toAppend = toAppend.substr(0, val?.length - 1);
		}

		if (event.keyCode === Number("13")) {
			this.searchEvent = false;
			this.isExpandSearchWidth.emit(this.searchEvent);
			//this.pages.push(localStorage.getItem('search-item'));
			//this.searchItem = this.pages.slice().reverse();
			this.activeIndex = 0;
			if (event.target.value != "" && event.target.value.trim() != "") {
				let event_dict = {};
				event_dict["name"] = val;
				let count = 0;
				for (let i = 0; i < this.storageList?.length; i++) {
					if (this.storageList[i].name) {
						let recentFilterName = this.storageList[i].name.toLowerCase();
						if (JSON.stringify(recentFilterName) == JSON.stringify(val)) {
							count = 1;
							if (count == 1) {
								this.storageList = this.storageList.filter(
									(item) => item.name.toLowerCase() !== recentFilterName
								);
								count = 0;
							}
							break;
						}
					}
				}
				if (count == 0) {
					if (toAppend.length >= this.minimum_search_char) {
						this.storageList.splice(0, 0, {
							name: toAppend,
						});
						// this.storageList.push({
						//     name: event.target.value
						// });
						this.storageObj[this.module] = this.storageList;
						localStorage.setItem(storageName, JSON.stringify(this.storageObj));
					}
				}

				//let res = this.searchObject.dupSearchedData.filter(it => new RegExp(event.target.value, "i").test(it.name))
				let res = this.searchObject.dupSearchedData.find((item) => item.name == event.target.value);
				let moduleCount = this.storageObj[this.module] || 0;
				if (!res && moduleCount < 5) {
					this.storageObj[this.module] = this.storageList;
					localStorage.setItem(storageName, JSON.stringify(this.storageObj));

					let localStorageData = JSON.parse(localStorage.getItem(storageName));
					this.searchObject.recentSearchedData = localStorageData[this.module] || [];
					this.searchObject.dupSearchedData = localStorageData[this.module] || [];
				} else if (!res && moduleCount >= 5) {
					this.storageObj[this.module] = this.storageList;
					localStorage.setItem(storageName, JSON.stringify(this.storageObj));
					this.searchObject.dupSearchedData.pop();
					this.searchObject.recentSearchedData.pop();
					let localStorageData = JSON.parse(localStorage.getItem(storageName));
					this.searchObject.recentSearchedData = localStorageData[this.module] || [];
					this.searchObject.dupSearchedData = localStorageData[this.module] || [];
				}
				this.searchObject.userEnterPopup = false;
				this.searchObject.advannceFilerPopup = false;
				this.searchObject.multipleFilterPopup = false;
			} else {
				this.searchObject.userSearchString = [];
				this.searchObject.addFilterArray = [];
				this.searchObject.addFilterArray.push({
					id: this.searchObject.addFilterArray?.length + 1,
					selectedColumn: this.columnOptions[0],
					selectedCondition: null,
					dataConfig: null,
					optionList: [],
				});
				this.getfilterData.emit(this.searchObject);
				this.searchObject.advannceFilerPopup = false;
				this.searchObject.userEnterPopup = false;
				this.searchObject.multipleFilterPopup = false;
			}

			this.customSearchFilter(event.target.value);
		}
		//else {
		//	this.searchObject.userSearchString = [];
		//	this.searchObject.addFilterArray = [];
		//	this.searchObject.addFilterArray.push({
		//		id: this.searchObject.addFilterArray.length + 1,
		//		selectedColumn: this.columnOptions[0],
		//		selectedCondition: null,
		//		dataConfig: null,
		//		optionList: [],
		//	});

		//	this.customSearchFilter(event.target.value);
		//}
	}

	customSearchFilter(args, type = "apply") {
		if (args.includes(":") && args.includes("(") && args.includes(")")) {
			if (args.endsWith(")")) {
				args = args + ",";
			}
			this.searchObject.userSearchData = [];
			let filterItem = [];
			this.searchObject.userSearchString = [];
			this.searchObject.addFilterArray = [];
			this.searchObject.filterItem = [];
			this.searchObject.searchCount = 0;
			this.columnOptions = [...this.dupColOption];
			if (args.includes("),")) {
				let customFilter = args.split("),");
				let customFilterLength = customFilter?.length;

				//this.searchObject.searchCount = this.searchObject.userSearchData;

				for (let i = 0; i < customFilter?.length; i++) {
					if (customFilter[i] && customFilter[i].includes(":") && customFilter[i].includes("(")) {
						let data = customFilter[i].split(/:(.*)/s);
						let key = data[0].trim();
						let keyLower = data[0].trim().toLowerCase();
						let condition = data[1].split("(");
						let cond = data[1].split("(")[0].trim();
						let col = "";

						for (let j = 0; j < this.columnOptions?.length; j++) {
							if (keyLower == this.columnOptions[j].column_name.toLowerCase()) {
								col = this.columnOptions[j].key;
							}
						}

						if (col) {
							if (
								cond == "in" ||
								cond == "not in" ||
								cond == this._translateService.instant("UI.k_in") ||
								cond == this._translateService.instant("UI.k_not_in")
							) {
								let colOption = this.columnOptions.find(
									(item) => item.column_name.toLowerCase() == keyLower
								);
								let valueType = colOption.operatorOptions.find(
									(item) => item.name.toLowerCase() == cond.toLowerCase()
								);
								let dataConf = [];
								let val = condition[1].trim();
								let userStringVal = [];
								let optionList = [];

								let values = [];
								values.push(condition[1].trim());

								if (
									valueType.type == "multiDropdown" ||
									valueType.type == "combobox_multiDropdown" ||
									valueType.type == "badgebox_multiDropdown"
								) {
									let optionName = colOption.options;
									let optionbindVal = colOption.option_id;
									let optionbindName = colOption.option_name;
									if (colOption.optionKey) {
										let optionkey = colOption.optionKey;

										optionList = this.Options[optionName][optionkey];
									} else {
										optionList = this.Options[optionName];
									}

									let multipleVal = values[0].split(",");

									for (let i = 0; i < optionList?.length; i++) {
										for (let j = 0; j < multipleVal?.length; j++) {
											if (
												multipleVal[j] &&
												optionList[i][optionbindName].toLowerCase() ==
													multipleVal[j].toLowerCase()
											) {
												dataConf.push(optionList[i][optionbindVal]);
												userStringVal.push(optionList[i][optionbindVal]);
											}
										}
									}
								} else {
									//let dataConf = []

									let valueList = values[0].split(",");
									for (let i = 0; i < valueList?.length; i++) {
										dataConf.push({
											name: valueList[i],
											tag: true,
										});
									}
									userStringVal = valueList;
								}

								this.searchObject.userSearchData.push({
									keyFilterValue: cond + "(" + values + ")",
									key: key,
									selected: true,
								});

								this.searchObject.userSearchString.push({
									key: key,
									condition: valueType.key,
									value: userStringVal,
									db_col: col,
									type: valueType.type,
									subkey: colOption.subkey,
									suffix: colOption.suffix,
									table: colOption.table,
									isint: colOption?.isint,
									islist: colOption?.islist,
								});

								this.searchObject.addFilterArray.push({
									id: this.searchObject?.addFilterArray?.length + 1,
									selectedColumn: colOption,
									selectedCondition: {
										key: cond,
										name: cond,
										type: valueType.type,
									},
									dataConfig: dataConf,
									optionList: optionList,
								});

								this.searchObject.filterItem.push({
									id: this.searchObject?.addFilterArray?.length + 1,
									selectedColumn: colOption,
									selectedCondition: {
										key: cond,
										name: cond,
										type: valueType.type,
									},
									dataConfig: dataConf,
									optionList: optionList,
								});
							} else if (
								[
									"equal to",
									"not equal to",
									"contains",
									"not contains",
									"In Range",
									"Not In Range",
									"after",
									"before",
									this._translateService.instant("UI.k_equal_to"),
									this._translateService.instant("UI.k_not_equal_to"),
									this._translateService.instant("UI.k_contains"),
									this._translateService.instant("UI.k_not_contains"),
									this._translateService.instant("UI.k_in_range"),
									this._translateService.instant("UI.k_not_in_range"),
									this._translateService.instant("UI.k_after"),
									this._translateService.instant("UI.k_before"),
								].includes(cond)
							) {
								let colOption = this.columnOptions.find(
									(item) => item.column_name.toLowerCase() == keyLower
								);
								let valueType = colOption.operatorOptions.find(
									(item) => item.name.toLowerCase() == cond.toLowerCase()
								);
								let dataConf;
								let val = condition[1].trim();
								let userStringVal;

								let optionList = [];

								if (
									valueType.type == "dropdown" ||
									valueType.type == "combobox" ||
									valueType.type == "badgebox"
								) {
									let optionName = colOption.options;
									let optionbindVal = colOption.option_id;
									let optionbindName = colOption.option_name;
									optionList = this.Options[optionName];

									if (colOption.optionKey) {
										let optionkey = colOption.optionKey;

										optionList = this.Options[optionName][optionkey];
									}
									for (let i = 0; i < optionList?.length; i++) {
										if (optionList[i][optionbindName] == condition[1]) {
											dataConf = optionList[i][optionbindVal];
											userStringVal = optionList[i][optionbindVal];
										}
									}
								} else if (valueType.type == "treebox") {
									let optionName = colOption.options;
									let optionbindVal = colOption.option_id;
									let optionbindName = colOption.option_name;
									// let treeList = this.Options["all_services"];

									let option_list = this.Options[optionName];
									option_list?.forEach((ele) => {
										let data = new TreeviewItem(ele);
										optionList.push(data);
									});

									// for (let i = 0; i < treeList?.length; i++) {
									// 	console.log("condition",condition)
									// 	if (treeList[i].name == condition[1]) {
									// 		dataConf = {
									// 			id: treeList[i].service_id,
									// 			name: treeList[i].name,
									// 		};
									// 		userStringVal = treeList[i].service_id;
									// 	}
									// }
								} else if (valueType.type == "toggle") {
									if (condition[1].trim() == this._translateService.instant("UI.k_inactive")) {
										dataConf = false;
										userStringVal = false;
									} else {
										dataConf = true;
										userStringVal = true;
									}
								} else if (valueType.type == "numInput") {
									userStringVal = condition[1].split(",");
									dataConf = condition[1].trim();
								} else {
									userStringVal = condition[1].trim();
									dataConf = condition[1].trim();
								}

								if (cond == "after" || cond == "before") {
									let cal = customFilter[i].split(cond + "(");
									val = cal[1];
									dataConf = this.formatDate(dataConf);
								}
								this.searchObject.userSearchData.push({
									keyFilterValue: cond + "(" + val + ")",
									key: key,
									selected: true,
								});

								this.searchObject.userSearchString.push({
									key: key,
									condition: valueType.key,
									value: userStringVal,
									db_col: col,
									type: valueType.type,
									subkey: colOption.subkey,
									suffix: colOption.suffix,
									table: colOption?.table,
									isint: colOption?.isint,
									isboolean: colOption?.isboolean,
									islist: colOption?.islist,
								});
								this.searchObject.addFilterArray.push({
									id: this.searchObject?.addFilterArray?.length + 1,
									selectedColumn: colOption,
									selectedCondition: {
										key: cond,
										name: cond,
										type: valueType.type,
									},
									dataConfig: dataConf,
									optionList: optionList,
								});

								this.searchObject.filterItem.push({
									id: this.searchObject.addFilterArray?.length + 1,
									selectedColumn: colOption,
									selectedCondition: {
										key: cond,
										name: cond,
										type: valueType.type,
									},
									dataConfig: dataConf,
									optionList: optionList,
								});
							} else {
								this._toastr.error(
									this._translateService.instant("Message.msg_page_search"),
									this._translateService.instant("UI.k_alert"),
									{
										toastClass: "toast ngx-toastr custom-toast",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
								return false;
							}
						} else {
							this._toastr.error(
								this._translateService.instant("Message.msg_page_search"),
								this._translateService.instant("UI.k_alert"),
								{
									toastClass: "toast ngx-toastr custom-toast",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);

							this.searchObject.addFilterArray.push({
								id: this.searchObject?.addFilterArray?.length + 1,
								selectedColumn: this.columnOptions[0],
								selectedCondition: null,
								dataConfig: null,
								optionList: [],
							});
							return false;
						}
					}
				}
				//this.searchObject.userSearchData = userSearchDataDuplicate;
				//this.searchObject.searchCount = this.searchObject.userSearchData.length;

				//this.getfilterData.emit(this.searchObject);
				//this.searchObject.advannceFilerPopup = false;
				//this.searchObject.userEnterPopup = false;
				//this.searchObject.multipleFilterPopup = false;
			} else {
				let data = args.split(/:(.*)/s);
				let key = data[0].trim();
				let keyLower = data[0].trim().toLowerCase();
				let condition = data[1].split("(");
				let cond = data[1].split("(")[0].trim();
				let val = condition[1].split(")")[0];
				let col = "";
				let conditionMatch = "";
				for (let i = 0; i < this.columnOptions.length; i++) {
					if (this.columnOptions[i].column_name.toLowerCase() == keyLower) {
						col = this.columnOptions[i].key;
					}
				}

				if (col) {
					if (
						cond == "in" ||
						cond == "not in" ||
						cond == this._translateService.instant("UI.k_in") ||
						cond == this._translateService.instant("UI.k_not_in")
					) {
						let colOption = this.columnOptions.find((item) => item.column_name.toLowerCase() == keyLower);
						let valueType = colOption.operatorOptions.find(
							(item) => item.name.toLowerCase() == cond.toLowerCase()
						);
						let dataConf = [];
						let val = condition[1].split(")")[0].trim();
						let userStringVal = [];
						let optionList = [];

						let values = [];
						values.push(condition[1].split(")")[0].trim());

						if (valueType.type == "multiDropdown" || valueType.type == "combobox_multiDropdown") {
							let optionName = colOption.options;
							let optionbindVal = colOption.option_id;
							let optionbindName = colOption.option_name;
							optionList = this.Options[optionName];

							let multipleVal = values[0].split(",");

							for (let i = 0; i < this.Options[optionName].length; i++) {
								for (let j = 0; j < multipleVal?.length; j++) {
									if (
										multipleVal[j] &&
										this.Options[optionName][i][optionbindName].toLowerCase() ==
											multipleVal[j].toLowerCase()
									) {
										dataConf.push(this.Options[optionName][i][optionbindVal]);
										userStringVal.push(this.Options[optionName][i][optionbindVal]);
									}
								}
							}
						} else {
							let valueList = values[0].split(",");
							for (let i = 0; i < valueList?.length; i++) {
								dataConf.push({
									name: valueList[i],
									tag: true,
								});
							}
							userStringVal = values;
						}

						this.searchObject.userSearchData.push({
							keyFilterValue: cond + "(" + values + ")",
							key: key,
							selected: true,
						});

						this.searchObject.userSearchString.push({
							key: key,
							condition: valueType.key,
							value: userStringVal,
							db_col: col,
							type: valueType.type,
							subkey: colOption.subkey,
							suffix: colOption.suffix,
							table: colOption.table,
							isint: colOption?.isint,
							islist: colOption?.islist,
						});

						this.searchObject.addFilterArray.push({
							id: this.searchObject.addFilterArray?.length + 1,
							selectedColumn: colOption,
							selectedCondition: {
								key: cond,
								name: cond,
								type: valueType.type,
							},
							dataConfig: dataConf,
							optionList: optionList,
						});

						this.searchObject.filterItem.push({
							id: this.searchObject.addFilterArray?.length + 1,
							selectedColumn: colOption,
							selectedCondition: {
								key: cond,
								name: cond,
								type: valueType.type,
							},
							dataConfig: dataConf,
							optionList: optionList,
						});
					} else if (
						[
							"equal to",
							"not equal to",
							"contains",
							"not contains",
							"In Range",
							"Not In Range",
							this._translateService.instant("UI.k_equal_to"),
							this._translateService.instant("UI.k_not_equal_to"),
							this._translateService.instant("UI.k_contains"),
							this._translateService.instant("UI.k_not_contains"),
							this._translateService.instant("UI.k_in_range"),
							this._translateService.instant("UI.k_not_in_range"),
						].includes(cond)
					) {
						let colOption = this.columnOptions.find((item) => item.column_name.toLowerCase() == keyLower);
						let valueType = colOption.operatorOptions.find(
							(item) => item.name.toLowerCase() == cond.toLowerCase()
						);
						let dataConf;
						let val = condition[1].split(")")[0].trim();
						let userStringVal;

						let optionList = [];

						if (valueType.type == "dropdown" || valueType.type == "combobox") {
							let optionName = colOption.options;
							let optionbindVal = colOption.option_id;
							let optionbindName = colOption.option_name;
							optionList = this.Options[optionName];

							for (let i = 0; i < this.Options[optionName].length; i++) {
								if (this.Options[optionName][i][optionbindName] == val) {
									dataConf = this.Options[optionName][i][optionbindVal];
									userStringVal = this.Options[optionName][i][optionbindVal];
								}
							}
							val = dataConf;
						} else if (valueType.type == "treebox") {
							let optionName = colOption.options;
							let optionbindVal = colOption.option_id;
							let optionbindName = colOption.option_name;
							// let treeList = this.Options["all_services"];
							let option_list = this.Options[optionName];
							option_list?.forEach((ele) => {
								let data = new TreeviewItem(ele);
								optionList.push(data);
							});

							// for (let i = 0; i < treeList?.length; i++) {
							// 	console.log("condition",condition)
							// 	if (treeList[i].name == condition[1]) {
							// 		dataConf = {
							// 			id: treeList[i].service_id,
							// 			name: treeList[i].name,
							// 		};
							// 		userStringVal = treeList[i].service_id;
							// 	}
							// }
						} else if (valueType.type == "toggle") {
							if (condition[1].trim() == this._translateService.instant("UI.k_inactive")) {
								dataConf = false;
								userStringVal = false;
							} else {
								dataConf = true;
								userStringVal = true;
							}
						} else if (valueType.type == "numInput") {
							userStringVal = condition[1].split(",");
							dataConf = val.trim();
						} else {
							userStringVal = condition[1].trim();
							dataConf = val.trim();
						}

						this.searchObject.userSearchData.push({
							keyFilterValue: cond + "(" + val + ")",
							key: key,
							selected: true,
						});
						this.searchObject.userSearchString.push({
							key: key,
							condition: valueType.key,
							value: val,
							db_col: col,
							type: valueType.type,
							subkey: colOption.subkey,
							suffix: colOption.suffix,
							table: colOption.table,
							isint: colOption?.isint,
							isboolean: colOption?.isboolean,
							islist: colOption?.islist,
						});

						this.searchObject.addFilterArray.push({
							id: this.searchObject.addFilterArray.length + 1,
							selectedColumn: colOption,
							selectedCondition: {
								key: cond,
								name: cond,
								type: valueType.type,
							},
							dataConfig: dataConf,
							optionList: optionList,
						});

						this.searchObject.filterItem.push({
							id: this.searchObject.addFilterArray?.length + 1,
							selectedColumn: colOption,
							selectedCondition: {
								key: cond,
								name: cond,
								type: valueType.type,
							},
							dataConfig: dataConf,
							optionList: optionList,
						});

						//this.searchObject.searchCount = this.searchObject.userSearchData.length;
						//this.getfilterData.emit(this.searchObject);

						//this.searchObject.advannceFilerPopup = false;
						//this.searchObject.userEnterPopup = false;
						//this.searchObject.multipleFilterPopup = false;
					}
				} else {
					this._toastr.error(
						this._translateService.instant("Message.msg_page_search"),
						this._translateService.instant("UI.k_alert"),
						{
							toastClass: "toast ngx-toastr custom-toast",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);

					return false;
				}
			}

			this.storeRecentFilter(args);

			this.searchObject.searchCount = this.searchObject.userSearchData?.length;
			let copyOption = [];
			let condCopyOption = [];
			let keyVal = [];
			for (let i = 0; i < this.searchObject.filterItem?.length; i++) {
				let name = this.searchObject.filterItem[i].selectedColumn.column_name;
				copyOption[name] = this.searchObject.filterItem[i].optionList;
				this.searchObject.filterItem[i].optionList = [];
				condCopyOption[name + i] = this.searchObject.filterItem[i].selectedColumn.operatorOptions;
				this.searchObject.filterItem[i].selectedColumn.operatorOptions = [];
			}
			for (let key in condCopyOption) {
				keyVal.push({ key: key.slice(0, -1), val: condCopyOption[key] });

				if (condCopyOption[key].length == 0) {
					let sliceKey = key.slice(0, -1);
					let data = keyVal.find((item) => item.key == sliceKey);

					condCopyOption[key] = data.val;
				}
			}

			this.searchObject.userDispalyString = "";
			for (let i = 0; i < this.searchObject.userSearchData?.length; i++) {
				if (this.searchObject.userDispalyString) {
					this.searchObject.userDispalyString =
						this.searchObject.userDispalyString +
						this.searchObject.userSearchData[i]["key"] +
						":" +
						this.searchObject.userSearchData[i]["keyFilterValue"] +
						",";
				} else {
					this.searchObject.userDispalyString =
						this.searchObject.userSearchData[i]["key"] +
						":" +
						this.searchObject.userSearchData[i]["keyFilterValue"] +
						",";
				}
			}

			this.getfilterData.emit(this.searchObject);

			for (let i = 0; i < this.searchObject.filterItem?.length; i++) {
				let names = this.searchObject.filterItem[i].selectedColumn.column_name;
				this.searchObject.filterItem[i].optionList = copyOption[names];
				this.searchObject.filterItem[i].selectedColumn.operatorOptions = condCopyOption[names + i];
			}

			this.searchObject.advannceFilerPopup = false;
			this.searchObject.userEnterPopup = false;
			this.searchObject.multipleFilterPopup = false;
		} else {
			let search_str = args;
			if (!search_str) {
				search_str = this.searchObject.userDispalyString;
			}
			if (search_str) {
				search_str = search_str.trim();
				if (search_str.endsWith(",")) search_str = search_str.slice(0, args.length - 1);
			}
			if (search_str.length >= this.minimum_search_char) {
				this.storeRecentFilter(search_str + ",");
				this.searchObject.userSearchData = search_str;
				this.searchObject.userDispalyString = search_str;
				this.searchObject["type"] = type;
				if (type == "save") {
					this.quick_search = true;
					this._toastr.success(
						this._translateService.instant("Message.msg_save_success"),
						this._translateService.instant("Message.msg_save_success"),
						{
							toastClass: "toast ngx-toastr custom-toast",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					this.searchObject.userEnterPopup = false;
					this.searchObject.advannceFilerPopup = false;
					this.searchObject.multipleFilterPopup = false;
				}
				this.genericFilter.emit(this.searchObject);
				return true;
			} else {
				this._toastr.error(
					this._translateService.instant("Message.msg_min_search_sel", { value: this.minimum_search_char }),
					this._translateService.instant("UI.k_alert"),
					{
						toastClass: "toast ngx-toastr custom-toast",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
				return false;
			}
			return true;
		}
	}

	nextActiveMatch() {
		this.activeIndex = this.activeIndex < this.pageSearchLimit - 1 ? ++this.activeIndex : this.activeIndex;
	}

	prevActiveMatch() {
		this.activeIndex = this.activeIndex > 0 ? --this.activeIndex : 0;
	}

	close(text) {
		if (text == "clicked outside") {
			this.searchObject.advannceFilerPopup = false;
			this.searchObject.userEnterPopup = false;
			this.searchObject.multipleFilterPopup = false;
			this.searchEvent = false;
			this.isExpandSearchWidth.emit(this.searchEvent);
		}
	}

	saveFilterOnInput(filterItem, type) {
		let filterForm = {};
		filterForm["form"] = {};
		filterForm["form"]["status"] = "VALID";
		let userString;
		let checkCondition = true;
		if (typeof filterItem.userSearchData != "string") {
			for (let i = 0; i < filterItem.userSearchData.length; i++) {
				if (i == 0) {
					userString = filterItem.userSearchData[i]["key"] + ":";
				} else {
					let userString1 = "," + filterItem.userSearchData[i]["key"] + ":";
					userString = userString + userString1;
				}
				userString = userString.concat(filterItem.userSearchData[i]["keyFilterValue"]);
			}
			if (userString.endsWith(")")) {
				userString = userString + ",";
			}
			checkCondition = this.customSearchFilter(userString);
		} else {
			checkCondition = this.customSearchFilter(filterItem.userSearchData, type);
		}
		if (checkCondition != false) {
			this.applyMultipleFilter(filterForm, filterItem, type);
		}
	}

	clearInput() {
		this.quick_search = false;
		this.searchObject.userSearchString = [];
		this.searchObject.filterItem = [];
		this.searchObject.userSearchData = "";
		this.searchObject.userDispalyString = "";
		this.searchObject.addFilterArray = [];
		this.searchObject.addFilterArray.push({
			id: this.searchObject.addFilterArray?.length + 1,
			selectedColumn: this.columnOptions[0],
			selectedCondition: null,
			dataConfig: null,
			optionList: [],
		});
		this.searchObject.searchCount = 0;
		this.getfilterData.emit(this.searchObject);
		this.searchObject.advannceFilerPopup = false;
		this.searchObject.userEnterPopup = false;
		this.searchObject.multipleFilterPopup = false;
	}

	selectAddOption(name) {
		return { name: name, id: name, tag: true };
	}

	storeRecentFilter(args) {
		let newVal = args.toLowerCase().replace(" : ", ":");
		let toAppend = args.replace(" : ", ":");

		if (toAppend.charAt(toAppend?.length - 1) == ",") {
			toAppend = toAppend.substr(0, toAppend?.length - 1);
		}
		let count = 0;
		for (let i = 0; i < this.storageList.length; i++) {
			if (this.storageList[i].name) {
				let recentFilterName = this.storageList[i].name.toLowerCase();
				if (JSON.stringify(recentFilterName) == JSON.stringify(newVal)) {
					count = 1;
					if (count == 1) {
						this.storageList = this.storageList.filter(
							(item) => item.name.toLowerCase() !== recentFilterName
						);
						count = 0;
					}
					break;
				}
			}
		}

		if (count == 0) {
			this.storageList.splice(0, 0, {
				name: toAppend,
			});

			this.storageList = Array.from(this.storageList.reduce((m, t) => m.set(t.name, t), new Map()).values());

			this.storageObj[this.module] = this.storageList;
			localStorage.setItem(storageName, JSON.stringify(this.storageObj));
		}
	}

	validationCheck(data, id) {
		this.validationFieldId = id;
		if (data.includes(",")) {
			let val = data.split(",");
			let range1 = parseInt(val[0]);
			let range2 = parseInt(val[1]);
			if ((range1 >= Number(0) || range1 == 0) && range2 >= Number(0)) {
				if (range1 < Number(100) && range1 < range2 && range2 <= Number(100)) {
					this.rangeValidation = false;
				} else if (range1 > Number(100) && range1 > range2 && range2 > Number(100)) {
					this.validationMsg = "Error.err_range_validation";
					this.rangeValidation = true;
				} else if (range1 > range2) {
					this.validationMsg = "Error.err_range_greater_validation";
					this.rangeValidation = true;
				} else if (range1 > Number(100) || range2 > Number(100)) {
					this.validationMsg = "Error.err_invalid_validation";
					this.rangeValidation = true;
				}
			} else {
				if (range1 > range2) {
					this.validationMsg = "Error.err_range_validation";
					this.rangeValidation = true;
				} else {
					this.validationMsg = "Error.err_invalid_validation";
					this.rangeValidation = true;
				}
			}
		} else {
			this.rangeValidation = false;
		}
	}

	serviceValueChange = (item, filter) => {
		filter["dataConfig"] = {};
		filter.dataConfig["id"] = item.value;
		filter.dataConfig["name"] = item.text;

		// this.filterData.basic_info.impact_service = value
	};

	getFields() {
		this.columnOptions = [...this.dupColOption];
		if (this.searchObject.addFilterArray.length > 0) {
			for (let i = 0; i < this.searchObject.addFilterArray.length; i++) {
				this.columnOptions.forEach((element, index) => {
					if (element.column_name == this.searchObject.addFilterArray[i]?.selectedColumn?.column_name)
						this.columnOptions.splice(index, 1);
				});
			}
			this.columnOptions = [...this.columnOptions];
		}
	}

	isString(value: any): boolean {
		return typeof value === 'string';
	}

	applyGenericSavedFilter(e, filterItem) {
		this.searchObject.userSearchData = e["ui_display"];
		this.searchObject.userSearchString = e["filter_string"];
		this.searchObject["type"] = "apply";
		this.searchObject.advannceFilerPopup = false;
		this.searchObject.userEnterPopup = false;
		this.searchObject.multipleFilterPopup = false;
		this.searchEvent = false;
		let userString;
		if (typeof filterItem.userSearchData == "string") {
			userString = this.searchObject.userSearchData
		}
		this.storeRecentFilter(userString);

		this.searchObject.userDispalyString = this.searchObject.userSearchData;
		this.getfilterData.emit(this.searchObject);
	}
}
