
<div class="full-body-height">
	<div class="main-content flex-height">
		<div class="main-sec">
			<div class="action-bar">
				<div class="inner-section">
					<div class="left-section">
						<!-- <div>
							<ng-container *ngIf="(showData && tags?.length > 0) || filterData">
								<app-search
									[showDataTable]="showDataTable"
									[columnOptions]="columnOptions"
									[conditionOptions]="conditionOptions"
									[showRightsection]="showRightsection"
									(getfilterData)="getFilterSearch($event)"
									[filterDetails]="getTagList"
									[savedData]="savedData"
									[Options]="typeList"
									[module]="module"
									(genericFilter)="genericSearchFilter($event)"
								></app-search>
							</ng-container>
						</div> -->
					</div>
				</div>
			</div>
			<div class="action-section">
				<div class="table-view flex-height">
					<dns-server-grid
						[rows]="notification"
						[columns]="columns"
						[columnMode]="ColumnMode.force"
						[count]="notificationCount"
						[offset]="pageNumber"
						[loadingIndicator]="true"
						[isEditable]="false"
						[limit]="limit"
						[virtualization]="true"
						(page)="setPage($event)"
						[externalSorting]="true"
						(sort)="onSort($event)"
						(setlimit)="setLimit($event)"
						class="bootstrap core-bootstrap"
						[permissions]="permissions"
						[showData]="showData"
						[emptyData]="empty_data"
					>
						<!--[selectionType]="SelectionType.checkbox"-->
					</dns-server-grid>
				</div>
			</div>
		</div>
	</div>
</div>
