import { Injectable } from '@angular/core';
import { ApiService } from "app/common/services/api/api.service";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProblemService extends ApiService {
  private _problemSubject: BehaviorSubject<boolean>;

  constructor(httpClient: HttpClient) { 
    super(httpClient);
  }
    getProblems(payload = {}) {
		return this.get("ux/sd/problem/problem/", payload);
	}

	saveProblem(payload: any) {
		return this.post("ux/sd/problem/problem/", payload);
	}

	getProblem(problem_id: any, payload: any = {}) {
		return this.get("ux/sd/problem/problem/" + problem_id + "/", payload);
	}

	editProblem(problem_id: any, payload: any) {
		return this.put("ux/sd/problem/problem/" + problem_id + "/", payload);
	}

	deleteProblem(payload: any) {
		return this.post("ux/sd/problem/problem/delete_problem/", payload);
	}

	getProblemColumns(payload = {}) {
		return this.get("ux/sd/problem/problem/page_config/", payload);
	}

	getAttachmentDetails(payload = {}) {
		return this.get("ux/sd/problem/problem/attachment/", payload);
	}

	getHistoryDetails(payload = {}) {
		return this.get("ux/sd/problem/problem/history/", payload);
	}

	addAttachments(payload: any) {
		return this.post("ux/sd/problem/problem/add-attachment/", payload);
	}

	savePageConfig(payload) {
		return this.post("ux/sd/problem/problem/page/", payload);
	}

	getOptions(payload = {}) {
		return this.get("ux/sd/problem/problem/options/", payload);
	}

	getExpertise(payload = {}) {
		return this.get("ux/sd/problem/problem/expertise/", payload);
	}

	getExpertiseLevels(payload = {}) {
		return this.get("ux/sd/problem/problem/team_level/", payload);
	}
	getAssigneeList(payload = {}) {
		return this.get("ux/sd/problem/problem/assignee/", payload);
	}
	getUserProfile(userid: Number) {
		return this.get("ux/common/user/profile/" + userid.toString() + "/");
	}
	getServicesForSelectedCatalogue(payload: any) {
		return this.get("ux/common/catalogue/servicecatalogue/service-list/", payload);
	}
	getServiceDetails(serviceId: Number) {
		return this.get("ux/common/catalogue/servicecatalogue/" + serviceId.toString() + "/");
	}
	updateProblemAssignee(payload = {}) {
		return this.put("ux/sd/problem/problem/update-assignee/", payload);
	}
	getFilteredProblem(payload = {}) {
		return this.get("ux/sd/problem/problem/top_filter/", payload);
	}
	saveConversation(payload) {
		return this.post("ux/sd/problem/conversation/", payload);
	}
	getMailConversations(payload) {
		return this.get("ux/sd/problem/conversation/", payload);
	}
	updateProblemTag(payload = {}) {
		return this.put("ux/sd/problem/problem/update-tag/", payload);
	}
	loadOrganiationConfig(payload: {}) {
		return this.get("ux/org/profile/orgconfig/", payload);
	}
	saveRichTextData(payload: {}) {
		return this.post("ux/sd/problem/problem/save-rich-text/", payload);
	}

	getRichTextData(payload: {}) {
		return this.get("ux/sd/problem/problem/process-rich-text/", payload);
	}

	getRequesterList(payload: {}) {
		return this.get("ux/sd/problem/problem/requester_search/", payload);
	}

	processProblemRelations(payload: {}) {
		return this.get("ux/sd/problem/problem/process-relations/", payload);
	}

	mergeProblem(payload: {}) {
		return this.post("ux/sd/problem/problem/merge/", payload);
	}
	getReqLatestProblems(payload: {}) {
		return this.get("ux/sd/problem/problem/latestproblems/", payload);
	}
	deleteAttachment(attachmentId: any, payload: {}) {
		return this.put("ux/sd/problem/problem/delete-attachment/" + attachmentId.toString() + "/", payload);
	}
	convertProblemToKB(kbId: any, payload: {}) {
		return this.put("ux/sd/problem/problem/problem-to-kb/" + kbId.toString() + "/", payload);
	}

	getAllKBs(payload: {}) {
		return this.get("ux/sd/problem/problem/problem-kbs/", payload);
	}

	getFilterOption(payload: any) {
		return this.get("ux/common/filters/filters/filter_options/", payload);
	}

	getSavedFilter(payload) {
		return this.get("ux/common/filters/filters/filter/", payload);
	}

	getServiceBaseWorkflowStatus(payload: any) {
		return this.get("ux/sd/problem/problem/workflow_status/", payload);
	}

	setEditProblemSidebar(flag) {
		localStorage.setItem("problem-edit-flag", JSON.stringify(flag));
	}

	getProblemSidebar() {
		return JSON.parse(localStorage.getItem("problem-edit-flag"));
	}

	editProblemAssignee(payload) {
		return this.post("ux/sd/problem/problem/update_problem_assignee/", payload);
	}
	addProblemRelation(payload) {
		return this.post("ux/sd/problem/problem/relation/", payload);
	}

	getWorkflowOptions(payload) {
		return this.post("ux/sd/problem/problem/workflow_options_map/", payload);
	}

	bulkProblemResolve(payload) {
		return this.post("ux/sd/problem/problem/bulk-resolve/", payload);
	}

	bulkProblemAssignment(payload) {
		return this.post("ux/sd/problem/problem/bulk-assignment/", payload);
	}

	getTeamUserMap(payload) {
		return this.get("ux/sd/problem/problem/team_user_map/", payload);
	}

	getRequesterImpactService(payload) {
		return this.get("ux/common/catalogue/servicecatalogue/req_services/", payload);
	}

	getImpactServiceClassification(payload) {
		return this.get("ux/common/catalogue/servicecatalogue/service_classes/", payload);
	}

	getProblemData(pk, payload={}){
		return this.get(`ux/sd/problem/problem/problem-data/${pk}/`, payload);
	}
	getEscalationData(pk, payload={}){
		return this.get(`ux/sd/problem/problem/escalation-data/${pk}/`, payload);
	}
	getApprovalStatus = (payload) => {
		return this.get(`ux/sd/problem/problem/check_approval_user/`, payload);
	};
	saveProblemCause(payload) {
		return this.post("ux/sd/problem/problem/save_problem_cause/", payload);
	}
	saveProblemSolution(payload) {
		return this.post("ux/sd/problem/problem/save_problem_solution/", payload);
	}

	getRootCauseData(pk, payload={}){
		return this.get(`ux/sd/problem/problem/root_cause_data/${pk}/`, payload);
	}

	getDraftRootCauses(pk, payload={}){
		return this.get(`ux/sd/problem/problem/root_cause_draft/${pk}/`, payload);
	}

	getRootCauseSolutionData(pk, payload={}){
		return this.get(`ux/sd/problem/problem/root_cause_solution_data/${pk}/`, payload);
	}
	convertProblemToChange(problemId: any, payload: {}) {
		return this.put("ux/sd/problem/problem/prob-to-change/" + problemId.toString() + "/", payload);
	}
	getChange(change_id: any, payload: any ={}) {
		return this.get("ux/sd/change/change/" + change_id + "/", payload);
	}
	getIncident(incident_id: any, payload = {}) {
		return this.get("ux/sd/inci/incident/" + incident_id + "/", payload);
	}
	convertProblemToIncident(problemId: any, payload: {}) {
		return this.put("ux/sd/problem/problem/prob-to-incident/" + problemId.toString() + "/", payload);
	}
}
