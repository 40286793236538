import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss']
})
export class FilterBarComponent implements OnInit {
  @Input() filterBarObject: any;
  @Output() filterDetails: EventEmitter<any> = new EventEmitter<any>();

  selectedValue: any;

  constructor() { }

  ngOnInit(): void {
    //console.log("Filter Object: ", this.filterBarObject);

  }

  emitSelectedValue(e, data) {
    this.filterDetails.emit({ "data": data });
  }

}
