import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewEncapsulation,
} from "@angular/core";
import { IActionMapping, ITreeOptions } from "@circlon/angular-tree-component";
import { TreeCrudViewService } from "app/base/tree-crud-view/services/tree-crud-view.service";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import cloneDeep from "lodash-es/cloneDeep";
import { distinct } from "rxjs/operators";
import { _deepCopyJson } from "app/common/utils/utils";

let hoverEventFlag = false;
const actionMapping: IActionMapping = {
	mouse: {
		// contextMenu: (tree, node, $event) => {
		//   $event.preventDefault();
		//   alert(`context menu for ${node.data.name}`);
		// },
		// dblClick: (tree, node, $event) => {
		//   if (node.hasChildren) {
		//     TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
		//   }
		// },
		// click: (tree, node, $event) => {
		//   $event.shiftKey
		//     ? TREE_ACTIONS.TOGGLE_ACTIVE_MULTI(tree, node, $event)
		//     : TREE_ACTIONS.TOGGLE_ACTIVE(tree, node, $event);
		// },
		mouseOver: (tree, node, $event) => {
			$event.preventDefault();
			hoverStyle(true);

			// console.log("..................", document.getElementById(node.id));
			// console.log(".........",document.getElementsByName(`${node.data.name}`));

			// console.log(`mouseOver ${node.data.name}`);
			hoverEventFlag = true;
		},
		mouseOut: (tree, node, $event) => {
			$event.preventDefault();
			hoverStyle(false);
		},
	},
};

@Component({
	selector: "app-tree-crud",
	templateUrl: "./tree-crud.component.html",
	styleUrls: ["./tree-crud.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class TreeCrudComponent implements OnInit, OnChanges {
	@Output() saveEditMainClass: EventEmitter<any> = new EventEmitter<any>();
	@Output() deleteMainClass: EventEmitter<any> = new EventEmitter<any>();
	@Output() deleteChildClass: EventEmitter<any> = new EventEmitter<any>();
	@Input() treeServiceView: any;
	@Input("is_edit_mode") is_edit = false;
	@Input() permissions: any;
	@Input("depth") depth = 3;
	is_add_edit = false;

	public editedNode;

	nodes = [];
	options: ITreeOptions = {
		actionMapping,
	};
	newNodeData = null;
	newNodeChildData = null;
	rootTreeNodeChecked = false;
	editRootData = null;
	public treeConfig = [];
	public treeDataConfig = {
		addFirstNode: false,
		inputData: null,
	};
	public dataChangesObservable: any;
	public tempVal;

	constructor(
		public treeviewService: TreeCrudViewService,
		public _changeDetectorRef: ChangeDetectorRef,
		private _translateService: TranslateService
	) {}

	ngOnInit(): void {
		// if (this.treeServiceView !== undefined) {
		// 	this.treeConfig = this.treeServiceView;
		// } else {
		// }
		// this.initializeDataChangeObservable();
		this.treeviewService.eventObservable.pipe(distinct()).subscribe((res) => {
			this.emittedSelectEvents(res);
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes && this.treeviewService) {
			this.treeConfig = this.treeServiceView;
			this.initializeDataChangeObservable();
		}
	}

	initializeDataChangeObservable() {
		this.dataChangesObservable = this.treeviewService.newTreeConfig$.subscribe((data) => {
			if (data) {
				this.treeConfig = data;
				this.treeviewService.setTreeConfig(this.treeConfig);
				this.treeConfig = JSON.parse(JSON.stringify(this.treeConfig));
				this._changeDetectorRef.markForCheck();
				this._changeDetectorRef.detectChanges();
			}
		});
	}

	deepClone = (data) => {
		return cloneDeep(data);
	};

	cancelFirstNodeEntry(form) {
		this.treeDataConfig.addFirstNode = false;
		form?.resetForm();
	}

	addClassification() {
		this.treeDataConfig.addFirstNode = true;
		this.treeDataConfig.inputData = null;
		// console.log("root data old",this.treeConfig);
		// console.log(" addClassification - root new",this.treeviewService.getTreeCOnfig());
	}

	addFirstNode(form) {
		form.submitted = true;
		if (form.status === "VALID") {
			const updatedData = this.treeviewService.getTreeCOnfig();
			// if (updatedData && updatedData.length) {
			// 	this.treeConfig = updatedData;
			// }
			this.treeConfig.push({
				id: this.treeConfig.length + 1,
				label: this.treeDataConfig.inputData,
				is_add: true,
			});
			this.treeDataConfig.addFirstNode = false;
			this.treeviewService.setTreeConfig(this.treeConfig);
			this.treeConfig = JSON.parse(JSON.stringify(this.treeConfig));
			this._changeDetectorRef.markForCheck();
			this._changeDetectorRef.detectChanges();
			this.saveEditMainClass.emit(this.treeConfig);
			form.resetForm();
		}
	}

	inputAction() {
		this.rootTreeNodeChecked = !this.rootTreeNodeChecked;
	}

	editRootNode(item) {
		item["isEditable"] = true;
		this.tempVal = _deepCopyJson(item);
	}

	cancelEditNode(item, form) {

		item["isEditable"] = false;
		if (this.tempVal && this.tempVal?.id == item?.id) {
			item["label"] = this.tempVal?.label;
		}
		// if (form.valid) {
		// 	form.submitted = false;
		// } else {
		// 	form.submitted = true;
		// }
	}

	saveEditNodeEvent(item, form) {
		form.submitted = true;
		item["isEditable"] = false;
		if (form.valid) {
			this.treeviewService.setTreeConfig(this.treeConfig);
			this.treeConfig = JSON.parse(JSON.stringify(this.treeConfig));
			this._changeDetectorRef.markForCheck();
			this._changeDetectorRef.detectChanges();
			this.saveEditMainClass.emit(this.treeConfig);
			form.submitted = false;
		}
	}

	deleteChildEvent(child) {
		if (this.is_edit) {
			child.is_deleted = true;
		} else {
			this.treeConfig.forEach((e, index) => {
				e.children.forEach((childItem) => {
					if (e.id === childItem.id) {
						this.treeConfig.splice(index, 1);
					}
				});
			});
		}
	}

	addChildNode(item) {
		item["addNode"] = true;
		item["openRootTree"] = true;
	}

	addFirstLevelNode(child) {
		child["addChildNode"] = true;
		child["openChildTree"] = true;
	}

	cancelCloseNode(item, form) {
		form.submitted = true;
		item["addNode"] = false;
		if (form.valid) {
			form.submitted = false;
		}
		form?.resetForm();
	}

	deleteMainEvent(item) {
		if (this.is_edit) {
			Swal.fire({
				title: this._translateService.instant("Message.msg_delete_title"),
				text: this._translateService.instant("Message.msg_delete_text"),
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: this._translateService.instant("Message.msg_delete_confirm"),
				cancelButtonText: this._translateService.instant("Message.msg_delete_cancel"),
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-danger ml-1",
				},
			}).then((result) => {
				if (result.isConfirmed) {
					if (this.is_edit) {
						item.is_deleted = true;
						this.deleteMainClass.emit(this.treeConfig);
					} else {
						this.treeConfig.forEach((e, index) => {
							if (e.id === item.id) {
								this.treeConfig.splice(index, 1);
							}
						});
						this.treeviewService.setTreeConfig(this.treeConfig);
						this.deleteMainClass.emit(this.treeConfig);
					}
					if (this.treeConfig.length === 0) {
						this.treeDataConfig.inputData = null;
						this.newNodeData = null;
					}
				}
			});
		} else {
			this.treeConfig.forEach((e, index) => {
				if (e.id === item.id) {
					this.treeConfig.splice(index, 1);
				}
			});
			this.treeviewService.setTreeConfig(this.treeConfig);
			this.deleteMainClass.emit(this.treeConfig);
			if (this.treeConfig.length === 0) {
				this.treeDataConfig.inputData = null;
				this.newNodeData = null;
			}
		}
	}

	saveNode(item, form) {
		form.submitted = true;
		if (form.valid) {
			if (!this.is_edit) {
				const updatedData = this.treeviewService.getTreeCOnfig();
				if (updatedData && updatedData.length) {
					this.treeConfig = updatedData;
				}
			}
			this.treeConfig.forEach((e, index) => {
				let newId;
				if (item && item.children) {
					newId = item.children.length + 1;
				} else {
					newId = "1";
				}
				if (e.id === item.id) {
					if (e && e.children) {
						e.children.push({
							id: item.id + "." + newId,
							label: this.newNodeData,
						});
					} else {
						e["children"] = [
							{
								id: item.id + "." + newId,
								label: this.newNodeData,
							},
						];
					}

					this.newNodeData = null;
					item["addNode"] = false;
				}
			});

			this.treeviewService.setTreeConfig(this.treeConfig);
			this.treeConfig = JSON.parse(JSON.stringify(this.treeConfig));
			this._changeDetectorRef.markForCheck();
			this._changeDetectorRef.detectChanges();
			this.saveEditMainClass.emit(this.treeConfig);
			form.submitted = false;
		}
	}

	emittedSelectEvents(event) {
		if (event && event.event && event.event === "delete") {
			if (event.treeConfig) {
				this.treeConfig = event.treeConfig;
				this.treeviewService.setTreeConfig(this.treeConfig);
				this.treeConfig = JSON.parse(JSON.stringify(this.treeConfig));
				this._changeDetectorRef.markForCheck();
				this._changeDetectorRef.detectChanges();
				this.deleteChildClass.emit(this.treeConfig);
			}
		} else if (event && event.event && event.event === "add") {
			if (event.treeConfig) {
				this.treeConfig = event.treeConfig;
				this.treeviewService.setTreeConfig(this.treeConfig);
				this.treeConfig = JSON.parse(JSON.stringify(this.treeConfig));
				this._changeDetectorRef.markForCheck();
				this._changeDetectorRef.detectChanges();
				this.saveEditMainClass.emit(this.treeConfig);
			}
		} else if (event && event.event && event.event === "edit") {
			if (event.treeConfig) {
				this.treeConfig = event.treeConfig;
				this.treeviewService.setTreeConfig(this.treeConfig);
				this.treeConfig = JSON.parse(JSON.stringify(this.treeConfig));
				this._changeDetectorRef.markForCheck();
				this._changeDetectorRef.detectChanges();
				this.saveEditMainClass.emit(this.treeConfig);
			}
		}
	}

	deleteSubChildEvent(subchild) {}

	onEvent(e) {}

	onInputAddEdit = (evt) => {
		// TODO event need to be properly handle
		// this.is_add_edit = evt;
		if(!evt){
			const updatedData = this.treeviewService.getTreeCOnfig();
			if (updatedData && updatedData.length) {
				this.treeConfig = updatedData;
			}
		}
	};
}

function hoverStyle(flag) {
	return flag;
}
