import { Component, EventEmitter, OnInit, OnDestroy, Input, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
	ChannelChangePayload,
	Product,
	UnitOfMeasure,
	ProductPlansWithPrices,
	PlanWithPrices,
	ProductPrice,
	ProductPlan,
} from "../../models";
import { ChannelService, AdminService, ComputeService } from "../../service";

@Component({
	selector: "app-pricing-modal",
	templateUrl: "./pricing-modal.component.html",
	styleUrls: ["./pricing-modal.component.scss"],
})
export class PricingModalComponent implements OnInit, OnDestroy {
	@Input() tag: string;
	@Output() callback = new EventEmitter<any>();
	pricingType: string;
	product: Product;
	productItemId: string;
	unitData: UnitOfMeasure[];
	productPlansWithPricesRaw: ProductPlansWithPrices;
	pricingTypeData: PlanWithPrices[];
	activePlan = null;
	loader: boolean = true;
	productPlanSeq = "0";
	planPriceMap = {};
	totalPriceMap = {};
	totalCostMap = {};
	perUnitMap = {};

	constructor(
		private _channel: ChannelService,
		private _service: AdminService,
		private _compute: ComputeService,
		private modalService: NgbModal
	) {}

	ngOnInit(): void {
		this._channel.channelMessage.subscribe((payload: ChannelChangePayload) => {
			if (payload && payload.extras.tag === this.tag) {
				// console.log("......channel payload", payload, this.tag);
				this.product = payload.data.product;
				this.productItemId = payload.data.productItemId;
				this.pricingType = payload.data.pricingType;
				this.unitData = payload.data.unitData;
				this.activePlan = payload.data.productItemId;
				this._channel.pushToChannel(null);
				this._service.getProductPlans({ productId: this.product.id }).subscribe({
					next: (response: any) => {
						this.productPlansWithPricesRaw = <ProductPlansWithPrices>response;
						for (let mapped of this.productPlansWithPricesRaw.mapped) {
							if (mapped.data.length > 0 && mapped.period === this.pricingType) {
								this.pricingTypeData = mapped.data;
								////console.log("pricingTypeData", this.pricingTypeData);
								mapped.data.forEach((d) => {
									if (d.item.id === this.productItemId)
										this.productPlanSeq = d.item?.cf_infraon_seq || "0";
								});
							}
						}
						// this.loader = false;
						this.mapPrice(this.pricingTypeData);
					},
					error: (e: any) => {
						console.error(e);
					},
				});
			}
		});
	}

	ngOnDestroy(): void {
		this.tag = undefined;
	}

	setActivePlan = (name: string) => {
		this.activePlan = name;
	};

	togglePricingType = (name: string) => {
		this.loader = true;
		this.pricingType = name;
		for (let mapped of this.productPlansWithPricesRaw.mapped) {
			if (mapped.period === this.pricingType) this.pricingTypeData = mapped.data;
		}
		this.mapPrice(this.pricingTypeData);
	};

	getItemPrice = (item: ProductPrice) => {
		if (item.price) {
			return item.price / 100;
		} else if (item.tiers && item.tiers.length > 0) {
			return item.tiers[0].price / 100;
		}
	};

	onChangeUnits(t: number, num: number) {
		this.unitData.forEach((e) => {
			if (e.id === t) e.value = +num;
		});
	}

	getTotalPrice = (item: ProductPrice): number => {
		let unit = this.unitData[0].value; // always takes first value to compute - rest value belong sot addons
		return this._compute.computePrice(unit, item) / 100;
	};

	getPerUnit = (name: string) => {
		if (name === "total") {
			return `${this.unitData[0].value} ${this.unitData[0].name}`;
		} else {
			return `${this.unitData[0].min} ${this.unitData[0].name}`;
		}
	};

	changePlan = (data: any) => {
		////console.log("in data", data);
		let payload = {
			data: data,
			productItemId: this.productItemId,
			pricingType: this.pricingType,
			unitData: this.unitData,
		};
		this.callback.emit(payload);
	};

	closePopup = () => {
		this.modalService.dismissAll();
	};

	mapPrice = (pricing) => {
		for (let plan of pricing) {
			let itemPrice = plan?.item_price;
			let unitVal = "0";
			let unitData = "0";
			if (itemPrice?.price) {
				this.planPriceMap[itemPrice?.item_id] = itemPrice?.price / 100;
			} else if (itemPrice.tiers && itemPrice.tiers.length > 0) {
				this.planPriceMap[itemPrice?.item_id] = itemPrice.tiers[0].price / 100;
			} else {
				this.planPriceMap[itemPrice?.item_id] = "0.00";
			}
			let unit = this.unitData[0].value; // always takes first value to compute - rest value belong sot addons
			this.totalPriceMap[itemPrice?.item_id] = this._compute.computePrice(unit, itemPrice) / 100;

			if (itemPrice.item_id === "Infy_Ats_Pro_1") {
				unitVal = "1000";
				unitData = "1000";
			} else if (itemPrice.item_id === "Infy_Ats_Ent_1") {
				unitVal = "5000";
				unitData = "5000";
			} else if (itemPrice.item_id === "Infy_HD_Unltd_1") {
				unitVal = "∞";
				unitData = "∞";
			} else {
				unitVal = this.unitData[0].min.toString();
				unitData = this.unitData[0].value.toString();
			}

			this.perUnitMap[itemPrice?.item_id] = `${unitVal} ${this.unitData[0].name}`;
			this.totalCostMap[itemPrice?.item_id] = `${unitData} ${this.unitData[0].name}`;
		}
		this.loader = false;
	};
}
