import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CoreConfigService } from '@core/services/config.service';
import { InfinityModules } from 'app/common/dns/types/modules';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-organisation-view',
  templateUrl: './organisation-view.component.html',
  styleUrls: ['./organisation-view.component.scss']
})
export class OrganisationViewComponent implements OnInit {
  public module = InfinityModules.USER;
  public currentMenu: any
  org_options = [ {
	
  }]
  orgData:any = {}
  submenu: boolean = true
  reloadPage: any;


  constructor(
    private _translateService: TranslateService,
    private _coreConfigService: CoreConfigService,
	private _activatedRoute: ActivatedRoute,

  ) { }

  ngOnInit(): void {
    this. updateNavBar();

	this.orgData = {
			org_id: "#ORG3426612",
			website: "Federalbank.infraon.io",
			organization_name: {
				name: "Faderal Bank",
				profile_image: "../../../../assets/images/msp/image 21.svg",
				avatar_color: "#f59518",
			},
			licence: {
				name: "Infraon Assets",
				key: "asset",
				units: ["user", "asset"],
				default_products: ["Infy_Ats_Pro_1"],
				icon: "assets/images/products/product-asset.svg",
			},
			plan: "Professional Plan",
			msp_user: {
				max_count: 500,
				count: 450,
				status:'danger'
			},
			msp_it_asset: {
				max_count: 500,
				count: 400,
				status:'warning'
			},
			msp_non_it_asset: {
				max_count: 500,
				count: 200,
				status:'success'
			},
			msp_availability_monitoring: {
				max_count: 500,
				count: 300,
				status:'success'
			},
			msp_network_monitoring: {
				max_count: 500,
				count: 200,
				status:'success'
			},
			assigned_msp_user:[
				{name:"Theresa Webb",email:'theresa@gmail.com',profile_image:'../../../../assets/images/avatars/1.png'},
				{name:"Wade Warren",email:'Wade@gmail.com',profile_image:'../../../../assets/images/avatars/2.png'},
				{name:"Alex Warren",email:'alex@gmail.com',profile_image:'../../../../assets/images/avatars/3.png'},
				{name:"Alex Warren",email:'alex@gmail.com',profile_image:''},
				{name:"Alex Warren",email:'alex@gmail.com',profile_image:'../../../../assets/images/avatars/4.png'},
				{name:"Alex Warren",email:'alex@gmail.com',profile_image:'../../../../assets/images/avatars/5.png'},
				{name:"Alex Warren",email:'alex@gmail.com',profile_image:'../../../../assets/images/avatars/6.png'},
				{name:"Alex Warren",email:'alex@gmail.com',profile_image:'../../../../assets/images/avatars/7.png'},
				{name:"Theresa Webb",email:'theresa@gmail.com',profile_image:'../../../../assets/images/avatars/1.png'},
			]
		}

	this.org_options = [
		{
			id:1,
			handle: "Products",
			title: this._translateService.instant("UI.k_products")
		},
		{
			id:2,
			handle: "MSP Assigned Users",
			title: this._translateService.instant("UI.k_msp_assigned_users")
		}
	]

	this._activatedRoute.params.subscribe((params) => {
		this.currentMenu = "Products";
	});
  }

  updateNavBar() {
		// Configure the layout
		this._coreConfigService.config = {
			layout: {
				navbar: {
					hidden: false,
					pageTitle: this._translateService.instant("UI.k_organization"),
					moduleName: this.module,
					backgroundColor: "",
					pageIcon: "fa-box-taped",
					rumb: false,
					breadcrumb: "",
				},
			},
		};
	}

	filterSelection(selection: string) {
		this.reloadPage = this.getTimestamp();
		this.currentMenu = selection;
		if (window.innerWidth >= 1200) {
			this.submenu = true; // lg
		} else {
			this.submenu = false; //md
		}
	}

	getTimestamp() {
		return Date.now();
	}

}
