import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilService {

  public _sidebarData = new Subject<[]>();
	workflowSidebarEvent$ = this._sidebarData.asObservable();

  constructor() { }

  setAddWorkFlowSidebar(data : any) {
    this._sidebarData.next(data);
  }
}
