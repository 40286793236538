import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { TranslateService } from "@ngx-translate/core";
import { DndDropEvent, DropEffect } from "ngx-drag-drop";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GlobalConstants } from "app/app.globalConstants";
import { NgScrollbar } from "ngx-scrollbar";
import { ActivatedRoute, Router } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { map, tap } from "rxjs/operators";
import { IncidentViewService } from "app/servicedesk/incident-view/services/incident-view.service";
import { FlatpickrOptions } from "ng2-flatpickr";

import { Subject, Subscription } from "rxjs";
import { User } from "app/auth/models";
import { AuthService } from "app/base/auth/services/auth.service";
import { TreeviewItem } from "ngx-treeview";
import cloneDeep from "lodash-es/cloneDeep";
import { InfinityModules } from "app/common/dns/types/modules";
import { ToastrService } from "ngx-toastr";
import { _deepCopyJson } from "app/common/utils/utils";
import { StatusPickerComponent } from "../status-picker/status-picker.component";
import { emojiData } from "app/common/dns/types/emoji_data";

@Component({
	selector: "app-dns-smart-grid",
	templateUrl: "./dns-smart-grid.component.html",
	styleUrls: ["./dns-smart-grid.component.scss"],
})
export class DnsSmartGridComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
	@ViewChild(NgScrollbar) scrollbarRef: NgScrollbar;
	@Input() selectedColumns: Array<any>;
	@Input() availableColumns: Array<any>;
	@Input() multiselect = true;
	@Input() incidentNav = true;
	@Input() tableData = [];
	@Input() rowCount;
	@Input() currentCount;
	@Input() size;
	@Input() page;
	@Input() maxPageLimit: number = 0;
	@Input() truncateValue = 60;
	@Input() sort = true;
	@Input() options;
	@Output() onSetLimit: EventEmitter<any> = new EventEmitter<any>();
	@Output() onSort: EventEmitter<any> = new EventEmitter<any>();
	@Output() onSavePageConfig: EventEmitter<any> = new EventEmitter<any>();
	@Output() onInlineEditCallback: EventEmitter<any> = new EventEmitter<any>();
	@Output() onSelectCheckbox: EventEmitter<any> = new EventEmitter<any>();
	@Output() onActionCallback: EventEmitter<any> = new EventEmitter<any>();
	@Output() saveIncidentData: EventEmitter<any> = new EventEmitter<any>();
	@Output() infoAction: EventEmitter<any> = new EventEmitter<any>();
	@Output() hideAssetSidebar: EventEmitter<any> = new EventEmitter<any>();
	@Output("onApprove") onApprove = new EventEmitter();
	@Output() onOpenSlaInfoView: EventEmitter<any> = new EventEmitter<any>();
	@Input() mselectMenu: Array<any>;
	@ViewChild("sliderStatusPickerComponent") sliderStatusPickerComponent: StatusPickerComponent;
	@ViewChild("appovalChangeComp") appovalChangeComp: ElementRef;

	private _scrollSubscription = Subscription.EMPTY;
	readonly scrollClass = new Subject();
	public colorArray = [
		"badge-light-info",
		"badge-light-danger",
		"badge-light-primary",
		"badge-light-success",
		"badge-light-warning",
		"badge-light-secondary",
	];
	public show: boolean = false;
	public statusResolved: any = "waiting";
	sourceIconMap: any = {};
	callbackIconMap: any = {};
	statusList = [];
	defWidth = "table-column-180";
	pageIndex = 0;
	currentEvent: String;
	pageStart: any = 0;
	pageEnd: any;
	dataSource: any;
	stickyClassMap = {};
	stickyColMap = {};
	selectedRowIndex = -1;
	displayedColumns = [];
	confDisplayedColumns = [];
	actionList = [];
	selActionList = [];
	selectedActionList = [];
	summaryCardConfig = {};
	columns = [];
	isEditable: any = false;
	selCol: any;
	editData: any = {};
	selectedRows: any = [];
	permissions: any;
	kb_permissions: any;
	selected: any = [];
	public historyDataset: any = [];
	public defaultAvatarColor = "#f59518";
	sortKeyMap = {
		incidentId: "display_id",
		priority_id: "basic_info__priority__name",
		impact_id: "basic_info__impact__name",
		// 'state': 'basic_info__state__name',
		status: "basic_info__status__name",
		urgency_id: "basic_info__urgency__name",
		group: "current_assignment_info__group_name",
	};
	summaryInput = {};
	statusInput = [];
	userInput = [];

	inci_extra_info: any = {};
	hideInlineEdit: boolean = false;
	custom_field_config: any;
	public extra_info = {
		selected_assignee_info: {
			profile_image: "",
			full_name: "",
			email: "",
		},
		group_type: "Operation and Technical",
		level: "",
		expertise: "",
		group_name: "",
	};
	public incidentId = undefined;
	public incident_data;
	public incident_data_copy;
	public dateOptions: FlatpickrOptions = {
		altInput: true,
	};
	currentUserProfileId: any;
	priorityInput = [];
	resolve_title: string;
	// severityInput = [];
	// urgencyInput = [];
	public dateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: true,
		dateFormat: "%b %d, %Y %I:%M %p",
		altFormat: "M d, Y H:i K",
		mode: "single",
		altInputClass: "form-control flat-picker flatpickr-input",
	};

	public agreedDateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: true,
		dateFormat: "%b %d, %Y %I:%M %p",
		altFormat: "M d, Y H:i K",
		mode: "single",
		altInputClass: "form-control flat-picker flatpickr-input",
	};

	resolved_status = [];
	// stateStatusMap: any;
	stateKeyIdMap: any = {};
	mail_list = [];
	latest_comment: any;
	loading_comment = false;
	status = "1";
	historydup: any;
	userTZ: any;
	public gridViewRef = false;
	resolve_btn_text: string;
	temp_priority: any = {};
	approval_team_id: any;
	temp_obj: { value: any; key: any; incident_id: any };

	public editorCreated(event): void {
		// Example on how to add new table to editor
		// this.addNewtable();
	}

	team_id: string;
	selfAssignSel = false;
	quickEdit = false;
	currentUser: User;
	showResln = false;
	public column_list_200 = [
		"impact_service",
		"status",
		"service_classification",
		"tag",
		"organization_name",
		"department",
		"reporter_phone",
	];
	asset_module = InfinityModules.CMDB;
	asset_permissions: any;
	is_priority_load: boolean = false;
	is_priority_change: boolean = false;
	temp_status: any;
	emojiData = emojiData;

	ngOnInit(): void {
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.permissions = user_permissions["incident"];
		this.kb_permissions = user_permissions["knowledgebase"];
		this.asset_permissions = user_permissions[this.asset_module];
		let userParams = JSON.parse(localStorage.getItem("userParams"));
		this.userTZ = userParams?.user_tz;
		try {
			let today = new Date(new Date().toLocaleString("en-US", { timeZone: this.userTZ }));
			this.dateTimeOptions["defaultDate"] = today;
		} catch (e) {
			//console.log("Error in selecting current time based on timezone ", e);
		}

		this.route.params.subscribe((params) => {
			this.incidentId = params.id;
		});
		this.columns = this.selectedColumns;
		this.isEditable = false;
		this.editData = {};
		this.displayedColumns = [];
		this.confDisplayedColumns = [];
		if (this.rowCount > 0) {
			this.pageStart = this.size * (this.page - 1) + 1;
		}
		this.pageEnd = this.size * this.page;
		if (this.pageEnd > this.rowCount) {
			this.pageEnd = this.rowCount;
		}
		this.dataSource = new MatTableDataSource<any>(this.tableData);
		this.dataSource.data = this.dataSource.data.slice(0, this.size);
		this.getActions();
		if (this.columns != undefined) {
			this.configureColumns();
			this.makeDict();
		}
		// this.stateStatusMap = this.options["state_status_map"];
	}

	onSelect({ selected }) {
		this.selected.splice(0, this.selected.length);
		this.selected.push(...selected);
		this.onSelectCheckbox.emit(this.selected);
		if (this.selected.length > 0) this.show = true;
	}

	toggle() {
		this.show = false;
		this.selection.clear();
		this.onSelectCheckbox.emit([]);
	}

	getActions() {
		this.actionList = [];
		this.selActionList = [];
		this.selectedActionList = [];
		if (this.columns != undefined) {
			for (let i = 0; i < this.columns.length; i++) {
				if (this.columns[i].type == "actions") {
					for (let actcol of this.columns[i].action_list) {
						if (actcol.type == "icon") {
							if (actcol.selected == true) {
								this.selActionList.push(actcol);
								this.selectedActionList.push(actcol);
							} else {
								this.actionList.push(actcol);
							}
						}
					}
				}
			}
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes?.options?.currentValue !== changes?.options?.previousValue) {
			this.sourceIconMap = this.options.sourceIconMap;
			this.callbackIconMap = this.options.callbackIconMap;
			this.stateKeyIdMap = this.options["state_key_id_map"];
		}
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.permissions = user_permissions["incident"];
		this.pageStart = 0;
		this.isEditable = false;
		if (changes?.editData?.currentValue !== changes?.editData?.previousValue) {
			this.editData = {};
		}
		this.summaryCardConfig = {};
		this.columns = this.selectedColumns;
		this.displayedColumns = [];
		this.confDisplayedColumns = [];
		if (this.rowCount > 0) {
			this.pageStart = this.size * (this.page - 1) + 1;
		}
		this.pageEnd = this.size * this.page;
		if (this.pageEnd > this.rowCount) {
			this.pageEnd = this.rowCount;
		}
		this.dataSource = new MatTableDataSource<any>(this.tableData);
		this.dataSource.data = this.dataSource.data.slice(0, this.size);
		this.getActions();
		if (this.columns != undefined) {
			this.configureColumns();
			this.makeDict();
		}
		this.selection = new SelectionModel<any>(true, []);
	}

	makeDict() {
		this.summaryCardConfig = {};
		let summaryCardData = [];
		if (this.confDisplayedColumns[0].type == "summary") {
			summaryCardData = this.confDisplayedColumns[0].summary_card_data;
		}
		if (summaryCardData != undefined) {
			for (let i = 0; i < summaryCardData.length; i++) {
				let options = [];
				for (let indx = 0; indx < summaryCardData[i].options.length; indx++) {
					if (summaryCardData[i].options[indx].selected) {
						options.splice(0, 0, summaryCardData[i].options[indx]);
					}
				}
				this.summaryCardConfig[summaryCardData[i].key] = {};
				this.summaryCardConfig[summaryCardData[i].key]["options"] = options;
			}
		}
	}

	paginate(page) {
		page = page?.page ? parseInt(page.page) : page;
		this.pageStart = this.size * (page - 1) + 1;
		this.pageEnd = this.size * page;
		let params = { page: page, limit: this.size };

		this.onSetLimit.emit(params);
	}

	setLimitGrid($event) {
		let page = 1;
		this.size = $event;
		if ($event == "all") {
			this.size = this.rowCount;
		}
		this.pageStart = this.size * (page - 1) + 1;
		this.pageEnd = this.size * page;
		let params = { page: page, limit: this.size };
		this.onSetLimit.emit(params);
	}

	constructor(
		private _translateService: TranslateService,
		private _coreSidebar: CoreSidebarService,
		private modalService: NgbModal,
		private _router: Router,
		// private _coreSidebarService: CoreSidebarService,
		private _incidentViewService: IncidentViewService,
		private route: ActivatedRoute,
		private ngZone: NgZone,
		public _authService: AuthService,
		private _toastarService: ToastrService
	) {
		this._authService.currentUser.subscribe((x) => (this.currentUser = x));
	}

	ngAfterViewInit() {
		this._scrollSubscription = this.scrollbarRef.verticalScrolled
			.pipe(
				map((e: any) => (e.target.scrollToRight > 50 ? "notScrol" : "isScrolling")),
				tap((size: string) => this.ngZone.run(() => this.scrollClass.next(size)))
			)
			.subscribe();
		this.sourceIconMap = this.options.sourceIconMap;
		this.callbackIconMap = this.options.callbackIconMap;
		this.stateKeyIdMap = this.options["state_key_id_map"];
	}

	ngOnDestroy() {
		this._scrollSubscription.unsubscribe();
	}

	configureColumns() {
		let summaryExists = false;
		for (let col in this.columns) {
			if (typeof this.columns[col].type != "undefined" && this.columns[col].type == "summary") {
				summaryExists = true;
				let colData = this.columns[col];
				this.displayedColumns.splice(0, 0, colData["label"]);
				this.confDisplayedColumns.splice(0, 0, colData);
			} else if (typeof this.columns[col].type != "undefined" && this.columns[col].type == "actions") {
				let colData = this.columns[col];
				colData["label"] = "UI.k_actions";
				this.displayedColumns.push("actions");
				this.confDisplayedColumns.push(colData);
			} else {
				this.confDisplayedColumns.push(this.columns[col]);
				this.displayedColumns.push(this.columns[col]["label"]);
			}
		}
		if (!summaryExists) {
			let colData = {};
			colData["type"] = "summary";
			colData["label"] = this._translateService.instant("UI.k_summary_card");
			this.displayedColumns.splice(0, 0, this._translateService.instant("UI.k_summary_card"));
			this.confDisplayedColumns.splice(0, 0, colData);
		}
		// if (this.columns != undefined){
		this.setColOrder({ sel_col: this.columns, rearrange: false });
		// }
	}

	modalOpenVC(modalVC) {
		this.modalService.open(modalVC, {
			centered: true,
		});
	}

	modalOpenQE(modalQE) {
		this.modalService.open(modalQE, {
			centered: true,
		});
	}

	getLastStickyCol() {
		for (let key in this.stickyClassMap) {
			this.stickyClassMap[key] = "";
		}
		let lastIndx = 0;
		for (let i = 0; i < this.displayedColumns.length; i++) {
			if (this.stickyColMap[this.displayedColumns[i]]) {
				lastIndx = i;
			}
		}
		this.stickyClassMap[this.displayedColumns[lastIndx]] = "dotted_line";
	}

	sortAsc(col) {
		let key = col.key;
		let sortKey = "";
		if (key in this.sortKeyMap) {
			sortKey = this.sortKeyMap[key];
		} else {
			sortKey = col.db_col;
		}
		if (sortKey == undefined || sortKey == "") {
			sortKey = "display_id";
		}
		let params = { sort: sortKey, reverse: "-" };
		this.onSort.emit(params);
	}

	sortDesc(col) {
		let key = col.key;
		let sortKey = "";
		if (key in this.sortKeyMap) {
			sortKey = this.sortKeyMap[key];
		} else {
			sortKey = col.db_col;
		}
		if (sortKey == undefined || sortKey == "") {
			sortKey = "display_id";
		}
		let params = { sort: sortKey, reverse: "" };
		this.onSort.emit(params);
	}

	setColOrder(selColsData) {
		let selCols = selColsData.sel_col;
		let rearrange = selColsData.rearrange;
		for (let col in this.columns) {
			let label = this.columns[col]["label"];
			this.stickyColMap[label] = false;
		}
		this.displayedColumns = [];
		let freezedColCount = 0;
		for (let i = 0; i < selCols.length; i++) {
			if (selCols[i].freezed && freezedColCount < 3 && selCols[i].type != "summary") {
				freezedColCount += 1;
				this.stickyColMap[selCols[i].label] = true;
				this.displayedColumns.splice(freezedColCount, 0, selCols[i].label);
			} else if (!rearrange && selCols[i].type != "summary" && selCols[i].type != "actions") {
				this.displayedColumns.push(selCols[i].label);
			} else if (selCols[i].type == "summary") {
				this.displayedColumns.splice(0, 0, selCols[i].label);
			}
		}
		if (this.displayedColumns.indexOf("actions") == -1) {
			this.displayedColumns.push("actions");
		}
		this.getLastStickyCol();
	}

	highlight(row, event) {
		if (typeof event.target.innerHTML != "undefined") {
			if (event.target.innerHTML.includes("<div")) {
				if (this.selectedRowIndex == row.incident_id) {
					this.selectedRowIndex = -1;
					this.editData = {};
					this.isEditable = false;
					this.selCol = undefined;
				} else {
					this.selectedRowIndex = row.incident_id;
				}
			} else {
				this.selectedRowIndex = row.incident_id;
			}
		}
	}

	selection = new SelectionModel<any>(true, []);

	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	masterToggle() {
		if (this.isAllSelected()) {
			this.selection.clear();
			this.onSelectCheckbox.emit(this.selection.selected);
			return;
			if (this.dataSource.length > 0) this.show = true;
		}
		this.selection.select(...this.dataSource.data);
		this.onSelectCheckbox.emit(this.selection.selected);
		this.selected.push(this.selection.selected);
		if (this.dataSource.data.length > 0) this.show = true;
	}

	checkboxLabel(row?: any): string {
		if (!row) {
			return `${this.isAllSelected() ? "select" : "deselect"} all`;
		}
		return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.id + 1}`;
	}

	private selectRow(dataSource) {
		this.selection.toggle(dataSource);
		this.onSelectCheckbox.emit(this.selection.selected);
		if (this.selection.selected.length > 0) this.show = true;
	}

	removeSelAction(index) {
		this.actionList.push(this.selActionList[index]);
		this.selActionList.splice(index, 1);
	}

	saveSelActions() {
		let actionList = [];
		this.selectedActionList = this.selActionList.slice(0, 5);
		actionList = this.selectedActionList;
		let buttonList = this.selectedColumns[this.selectedColumns.length - 1]["action_list"].slice(0, 2);
		for (let act of actionList) {
			act.selected = true;
			buttonList.push(act);
		}
		for (let act of this.actionList) {
			act.selected = false;
			buttonList.push(act);
		}
		this.selectedColumns[this.selectedColumns.length - 1]["action_list"] = buttonList;
		if (this.availableColumns[this.availableColumns.length - 1].type == "actions") {
			this.availableColumns[this.availableColumns.length - 1]["action_list"] = buttonList;
		} else {
			this.availableColumns.push(this.selectedColumns[this.selectedColumns.length - 1]);
		}
		this.onSavePageConfig.emit(this.availableColumns);
	}

	onDragStart(event: DragEvent) {}

	onDragEnd(event: DragEvent) {}

	onDraggableCopied(event: DragEvent) {}

	onDraggableLinked(event: DragEvent) {}

	onDragged(effect: DropEffect) {}

	onDragCanceled(event: DragEvent) {}

	onDragover(event) {}

	onDrop(event: DndDropEvent, list?: any[], target_id?: any, type?: any) {
		if (type == "action" && event.dropEffect === "copy") {
			if (list.length < 4) {
				list.push(event.data);
				for (let i = 0; i < this.actionList.length; i++) {
					if (this.actionList[i].action_name == event.data.action_name) {
						this.actionList.splice(i, 1);
					}
				}
			}
		} else if (list && event.dropEffect === "move" && type == "action") {
			let indxStr = target_id.id;
			let indx: number = +indxStr;
			for (let i = 0; i < list.length; i++) {
				if (list[i].action_name == event.data.action_name) {
					list.splice(i, 1);
				}
			}
			list.splice(indx, 0, event.data);
		}
	}

	doEdit(args, col, incident_id, element) {
		this.editData = {};
		this.selCol = args;
		if (
			col.isEditable != false &&
			this.selectedRowIndex == incident_id &&
			this.permissions.edit == true &&
			!(element?.state_id === 6) &&
			!(element?.is_exit && element?.state_id === 5) &&
			this.permissions?.edit
		) {
			this.isEditable = true;
			this.editData[args] = true;
		}
	}

	singleClickEvent(args, col, incident_id, element, summaryData, priorityColor) {
		this.editData = {};
		this.selCol = args;
		if (
			col.isEditable != false &&
			this.selectedRowIndex == incident_id &&
			this.permissions.edit == true &&
			!(element?.state_id === 6) &&
			!(element?.is_exit && element?.state_id == 5) &&
			this.permissions?.edit
		) {
			this.isEditable = true;
			this.editData[args] = true;
		}
		if (col.key === "assignee") {
			// this.isEditable = true
			// this.editData[args] = true;
			// this.editData["isLoadUserPicker"] = true;
		} else if (col.type === "summary") {
			this.isEditable = true;
			this.editData[args] = true;
			this.editData["isLoadSummaryCard"] = true;
			summaryData["priorityColor"] = priorityColor;
			summaryData["priority"] = summaryData.id;
			this.summaryInput = {
				data: summaryData,
				incident_id: incident_id,
			};
		}
		// console.log("singleclick",col,this.editData,summaryData,this.summaryInput);
	}

	onEnterInlineEdit(args) {}

	statusEvent = (args, key, subkey, row, column, name) => {
		let obj = { value: args, key: key, incident_id: row?.incident_id };
		obj["mulitple_key"] = true;
		obj["multiple_ke_vl"] = { state_id: args?.state_id };
		if (
			row?.state_id !== args?.state_id ||
			args?.state_id === this.stateKeyIdMap?.["Resolved"] ||
			args?.state_id === this.stateKeyIdMap?.["Close"] ||
			args?.state_id === this.stateKeyIdMap?.["Approval"]
		) {
			if (
				args?.state_id === this.stateKeyIdMap?.["Resolved"] ||
				args?.state_id === this.stateKeyIdMap?.["Close"]
			) {
				this.onResolve("resolve-incident", row, args);
			} else if (args?.state_id === this.stateKeyIdMap?.["Approval"]) {
				this.temp_status = args;
				this.temp_obj = obj;
				if (args?.state_id == 6) {
					setTimeout(() => {
						this.getIncident(row?.incident_id, undefined, "approval");
					}, 500);
				}
			} else {
				this.onInlineEditCallback?.emit(obj);
			}
		} else {
			this.onInlineEditCallback?.emit(obj);
		}
	};

	updateInlineData(args, key, subkey, row, column) {
		this.isEditable = false;
		this.selCol = undefined;
		this.editData = {};
		let obj = {};
		obj["key"] = key;
		obj["sub_key"] = subkey;
		obj["incident_id"] = row.incident_id;
		obj["inline_edit"] = true;

		if (column.type == "multi-select") {
			if (subkey) {
				obj["value"] = row[subkey][key];
			} else {
				obj["value"] = row[key];
			}
		} else {
			obj["value"] = args.target.value;
			if (subkey) {
				row[subkey][key] = args.target.value;
			} else {
				row[key] = args.target.value;
			}
		}

		obj["row"] = row;
		this.onInlineEditCallback?.emit(obj);
	}

	checkCondition(key, element) {
		if (key == "assign_to") {
			if (
				element.assignment_info.assignee ||
				element?.state_id >= 4 ||
				(element?.is_exit == true && element?.state_id == 5) ||
				!this.permissions?.edit
			) {
				return false;
			}
		}
		if (key == "resolve") {
			if ((element.state_id == 5 && element.is_exit == true) || !this.permissions?.edit) {
				return false;
			}
		}
		if (key == "can_approve") {
			if (element.state_id != 6 || !this.permissions?.edit) {
				return false;
			}
		}
		return true;
	}

	searchObjectByKeyValue(arr, key, value) {
		if (arr != undefined) {
			return arr?.find((obj) => obj[key] == value);
		}
	}

	searchMultiObjectByKeyValue(arr, key, value) {
		let value_li = [];
		if (arr != undefined) {
			for (let obj of arr) {
				if (value.indexOf(obj.id) != -1) {
					value_li.push(obj.name);
				}
			}
			return value_li.join(", ");
		}
	}

	closePopup() {
		this.modalService.dismissAll();
		this.actionList = [];
		this.selActionList = [];
		this.selectedActionList = [];
		this.getActions();
	}

	onQuickEdit(name, element) {
		this.hideInlineEdit = true;
		this.tableData.forEach((e) => {
			e["isQuickEdit"] = false;
			if (e.incident_id === element.incident_id) {
				e["isQuickEdit"] = true;
			}
		});
		this.quickEdit = true;
		this.getIncident(element?.incident_id, name);
	}

	onApproval = (name, element, event: any) => {
		event.stopPropagation();
		this.onApprove.emit(element);
	};

	onResolve(name, element, args: any) {
		try {
			let today = new Date(new Date().toLocaleString("en-US", { timeZone: this.userTZ }));
			this.dateTimeOptions["defaultDate"] = today;
		} catch (e) {
			//console.log("Error in selecting current time based on timezone ", e);
		}
		this._incidentViewService
			.getIncidentData(element.incident_id)
			.pipe(
				map(
					(response) => {
						this.incident_data = response["incident"];
						if (this.incident_data?.close_info == null || this.incident_data?.close_info == undefined) {
							this.incident_data["close_info"] = {
								closed_type: {},
							};
						}
						this.incident_data_copy = cloneDeep(this.incident_data);
						this.currentUserProfileId = response["current_user_id"];
						this.inci_extra_info["selected_assignee_info"] = response["selected_assignee_info"];
						this.inci_extra_info["custom_field_config"] = response["custom_field_config"];
						this.inci_extra_info["group_type"] = response["group_type"];
						this.hideInlineEdit = true;
						this.resolve_title = this._translateService.instant("UI.k_resolve_incident");
						this.resolve_btn_text = this._translateService.instant("UI.k_resolve");
						if (this.incident_data?.basic_info?.state?.id < 4) {
							this.incident_data["inci_resolution_time"] = null;
						}

						this.resolved_status = response?.status?.filter(
							(e) => e["state_id"] == response?.state_key_id_map?.["Resolved"]
						);
						if (
							this.incident_data?.basic_info?.state?.id >= response?.state_key_id_map?.["Resolved"] &&
							!args?.state_id
						) {
							this.incident_data["inci_add_flag"] = undefined;
							this.resolve_title = this._translateService.instant("UI.k_close_incident");
							this.resolved_status = response?.status?.filter(
								(e) => e["state_id"] == response?.state_key_id_map?.["Close"]
							);
							this.resolve_btn_text = this._translateService.instant("UI.k_complete");
						}
						if (!args?.state_id) {
							if (this.incident_data?.basic_info?.state?.id == 5) {
								this.incident_data["resolved_status_id"] = this.incident_data?.basic_info?.status?.id;
								this.incident_data["state_id"] = this.incident_data?.basic_info?.status?.state_id;
							}
							if (this.resolved_status && this.incident_data?.basic_info?.state?.id != 5) {
								this.incident_data["resolved_status_id"] = this.resolved_status[0]["id"];
								this.incident_data["state_id"] = this.resolved_status[0]["state_id"];
								this.incident_data.basic_info["status"] = this.resolved_status[0];
							}
						} else {
							this.resolved_status = response?.status?.filter((e) => e["state_id"] == args?.state_id);
							this.incident_data["resolved_status_id"] = args?.id;
							this.incident_data["state_id"] = args?.state_id;
							this.incident_data.basic_info["status"] = args;
							if (args?.state_id == 5) {
								this.resolve_title = this._translateService.instant("UI.k_close_incident");
								this.resolve_btn_text = this._translateService.instant("UI.k_complete");
							}
						}
						this._coreSidebar.getSidebarRegistry(name).open();
					},
					(error) => {
						//todo
					}
				)
			)
			.subscribe();
	}

	toggleSidebar(key, form) {
		let sidebarRef = this._coreSidebar.getSidebarRegistry(key);
		if (form != undefined && form) {
			// form.reset()
			// this.incident_data = {}
		}
		this.quickEdit = false;
		sidebarRef.toggleOpen();
	}

	onActionIconClick(type, row, is_link) {
		let param = {};
		param["type"] = type;
		param["row"] = row;
		if (is_link) {
			param["is_link"] = true;
		}
		this.onActionCallback.emit(param);
	}

	moveToEdit(id) {
		this._router.navigate(["incident_view/incident-edit/" + id]);
	}

	moveToEditSidebar(event) {
		const filterRow = event.tableData.filter((id) => id.isQuickEdit === true);
		if (filterRow) {
			this._router.navigate(["incident_view/incident-edit/" + filterRow[0].incident_id]);
			this._incidentViewService.setEditIncidentSidebar(true);
		}
	}

	getIncident(id, name, type: any = undefined) {
		this._incidentViewService
			.getIncident(id)
			.pipe(
				map(
					(response) => {
						this.incident_data = response["incident"];
						this.incident_data_copy = cloneDeep(this.incident_data);
						this.currentUserProfileId = response?.current_user_id;
						this.inci_extra_info["selected_assignee_info"] = response["selected_assignee_info"];
						this.inci_extra_info["custom_field_config"] = response?.custom_field_config;
						this.inci_extra_info["group_type"] = response["group_type"];
						this.options["expertise"] = response?.expertise_list;
						this.options["level"] = response?.level_list;
						this.options["assignee"] = response["assignee_list"];
						this.options["services"] = response["service_list"];
						this.options["status"] = response?.status;
						this.options["formatted_services"] = [];
						this.team_id = response?.incident?.current_assignment_info?.group;

						// response?.formatted_services.forEach((ele) => {
						// 	let data = new TreeviewItem(ele);
						// 	this.options["formatted_services"].push(data);
						// });
						// let class_maps = cloneDeep(this.options?.service_category_classification_map);
						// if (class_maps) {
						// 	for (var classs_map in class_maps) {
						// 		this.options["service_category_classification_map"][classs_map][
						// 			"formatted_classification_data"
						// 		] = [];
						// 		class_maps[classs_map]["formatted_classification_data"].forEach((ele) => {
						// 			let data = new TreeviewItem(ele);
						// 			this.options["service_category_classification_map"][classs_map][
						// 				"formatted_classification_data"
						// 			].push(data);
						// 		});
						// 	}
						// }
						this.options["filtered_status"] = this.options?.status.filter(
							(status) => status.state_id === this.incident_data?.basic_info?.state?.id
						);

						if (name) {
							this._coreSidebar.getSidebarRegistry(name).toggleOpen();
							// this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
							this.hideInlineEdit = true;
						}

						this.extra_info["selected_assignee_info"] = response["selected_assignee_info"];
						this.inci_extra_info["selected_assignee_info"] = this.extra_info["selected_assignee_info"];
						this.extra_info["custom_field_config"] = this.custom_field_config;
						this.extra_info["group_name"] = "";
						this.extra_info["level"] = "";
						this.extra_info["expertise"] = "";
						if (response["group_type"]) {
							this.extra_info["group_type"] = response["group_type"];
						}
						if (this.incident_data.current_assignment_info != undefined) {
							if (this.incident_data["current_assignment_info"]["group_name"]) {
								this.extra_info["group_name"] =
									this.incident_data["current_assignment_info"]["group_name"];
							}

							if (this.incident_data["current_assignment_info"]["level"]) {
								this.extra_info["level"] = this.incident_data["current_assignment_info"]["level"];
							}
							if (this.incident_data["current_assignment_info"]["expertise"]) {
								this.extra_info["expertise"] =
									this.incident_data["current_assignment_info"]["expertise"];
							}
						} else {
							this.incident_data["current_assignment_info"] = {};
						}
						let options = response?.options;
						this.options["users"] = options?.users;
						this.options["teams"] = options?.teams;
						this.options["state_id_map"] = options?.state_id_map;
						// if (options?.teams && options.current_user_group.length > 0) {
						this.options["teams_and_self_assign"] = [
							{ team_id: "self_assign", name: "Self Assign" },
						].concat(this.options["teams"]);
						// } else {
						// 	this.options["teams_and_self_assign"] = options?.teams;
						// }
						if (
							this.incident_data?.current_assignment_info &&
							this.incident_data?.current_assignment_info?.group == undefined &&
							this.incident_data?.current_assignment_info?.assignee != undefined
						) {
							this.team_id = "self_assign";
							this.selfAssignSel = true;
						}

						if (type === "approval") {
							this.openApprovalChangeComp();
						}
					},
					(error) => {
						//todo
					}
				)
			)
			.subscribe();
	}

	onhideInlineEdit = (e) => {
		this.hideInlineEdit = false;
		this._incidentViewService.setEditIncidentSidebar(false);
	};

	incidentEditSaveEvent(data, args) {
		if (data.form.status === "VALID") {
			this.incident_data.basic_info["state"] =
				this.options?.state_id_map[this.incident_data.basic_info?.status?.state_id];
			this.toggleSidebar("quick-edit", data);
			this.saveIncidentData?.emit(this.incident_data);
			this.showResln = false;
		}
	}

	onResolveSubmit(data, args) {
		if (data.form.status === "VALID") {
			this.incident_data.basic_info["state"] =
				this.options?.state_id_map[this.incident_data.basic_info?.status?.state_id];
			if (this.incident_data.basic_info?.status?.state_id > 4) {
				let cf_data = this.inci_extra_info?.custom_field_config?.custom_fields?.filter(
					(d) => d?.section == "closure"
				);
				this.incident_data.close_info["custom_field_data"] = this.get_custom_data(
					cf_data,
					this.incident_data?.custom_field_data
				);
			}
			if (this.incident_data.basic_info?.status?.state_id == 4) {
				let cf_data = this.inci_extra_info?.custom_field_config?.custom_fields?.filter(
					(d) => d?.section == "resolution"
				);
				this.incident_data["custom_field_data"] = this.incident_data?.custom_field_data;
				this.incident_data["custom_fields"] = cf_data;
			}
			this.toggleSidebar("resolve-incident", data);
			this.saveIncidentData?.emit(this.incident_data);
		}
	}

	onStatusChange = (data) => {
		if (data?.state_id == 4) {
			this.incident_data["resolved_status_id"] = data?.id;
		}

		this.incident_data["status_id"] = data?.id;
		this.incident_data.basic_info["status"] = data;
		this.incident_data["state_id"] = data?.state_id;
		this.incident_data.basic_info["state"] = this.options?.state_id_map[data?.state_id];
	};

	getClosureNote = (evt) => {
		this.incident_data.close_info.closure_note = evt.html;
	};

	updateFieldName(evt, id, dropdown_name, set_field, ref_field) {
		let selected = this.options[dropdown_name].filter((e) => e[id] == evt);
		set_field = selected[0][ref_field];
	}

	checkConvertToKBCondition(action, element) {
		if (action?.action_name == "UI.k_conv_incident_kb") {
			if (element.is_converted_to_kb || element.state_id < 4 || !this.kb_permissions?.add) {
				return false;
			}
		} else if (action?.controlled_action == true && action?.enable(element, action?.key)) {
			return false;
		}
		return true;
	}

	getComment = (evt) => {
		this.loading_comment = true;
		this.latest_comment = {};
		const payload = {
			page: 1,
			items_per_page: 1,
			ref_id: evt,
			comments: true,
			emails: true,
		};
		this._incidentViewService.getMailConversations(payload).subscribe((response) => {
			if (response) {
				this.loading_comment = false;
				this.mail_list = response?.data;
				this.latest_comment = response?.latest_comment;
				this.latest_comment["mail_content"] = "";
				if (this.latest_comment?.table == "email") {
					this.latest_comment["mail_content"] = this.latest_comment?.new_reply_mail;
					if (!this.latest_comment?.new_reply_mail) {
						this.latest_comment["mail_content"] = this.latest_comment?.content;
					}
					if (this.latest_comment?.user_profile?.full_name == undefined) {
						this.latest_comment["user_profile"] = {};
						this.latest_comment["user_profile"]["full_name"] = this.latest_comment?.to_address;
					}
				} else {
					this.latest_comment["mail_content"] = this.latest_comment?.description;
				}
				let formatted_text = this.latest_comment?.mail_content.replace(/<[^>]*>/g, "");
				if (formatted_text.length > 50) {
					this.latest_comment["mail_content"] = formatted_text + "...";
				} else {
					this.latest_comment["mail_content"] = formatted_text;
				}
			}
		});
	};

	statusBadgeChange = (data, key, subkey, row, column) => {
		if (column.key == "priority_id") {
			this.is_priority_change = true;
			this.incident_data = row;
			this.temp_priority = data?.event || {};
			this.getIncident(row?.incident_id, "incident-priority-change-incident-smart");
		} else {
			let args = {
				target: {
					value: data?.event.id,
				},
			};
			this.updateInlineData(args, key, subkey, row, column);
		}
	};
	serviceValueChange = (item, key, element) => {
		if (item.value != element.impact_service) {
			let basic_info = {
				impact_service: item?.value,
				impact_service_name: item?.text,
				service_classification: "",
				service_classification_name: "",
				service_classification_path: "",
				impact_service_tree_path: item?.full_path_text,
			};
			basic_info["catalogue"] = item?.sel_tree_view_data[item?.sel_tree_view_data?.length - 1]["value"];
			basic_info["catalogue_name"] = item?.sel_tree_view_data[item?.sel_tree_view_data?.length - 1]["text"];
			if (item?.sel_tree_view_data && item?.sel_tree_view_data?.length > 2) {
				basic_info["category"] = item?.sel_tree_view_data[1]["value"];
				basic_info["category_name"] = item?.sel_tree_view_data[1]["text"];
			} else {
				basic_info["category"] = "";
				basic_info["category_name"] = "";
			}
			let obj = {
				value: basic_info,
				key: "basic_info",
				incident_id: element?.incident_id,
				col_key: key,
				element: element,
			};
			this.onInlineEditCallback?.emit(obj);
		}
	};
	classificationValueChange = (item, key, element) => {
		let basic_info = {
			service_classification: item?.value,
			service_classification_name: item?.text,
			service_classification_path: item?.full_path_text,
		};
		let obj = { value: basic_info, key: "basic_info", incident_id: element?.incident_id };
		this.onInlineEditCallback?.emit(obj);
	};

	info_action($event) {
		this.infoAction?.emit($event);
	}

	hide_asset_sidebar($event) {
		this.hideAssetSidebar.emit($event);
	}

	show_sla_info_view(data) {
		this.onOpenSlaInfoView.emit(data);
	}

	getTranslatableKey = (key: string) => {
		if (key) {
			if (key.startsWith("k_")) {
				key = `UI.${key}`;
			} else if (key.startsWith("msg_")) {
				key = `Message.${key}`;
			} else if (key.startsWith("err_")) {
				key = `Error.${key}`;
			}
		}
		return key;
	};

	handleEvent(e) {
		//console.log("handleEvent",e);
	}

	onDisplaykey = (key: string) => {
		if (key) {
			key = key
				.replace(/<.*?>/g, " ")
				.replace(/<img[^>]*>/g, "")
				.replace(/&nbsp;/g, " ")
				.replace(/&amp;/g, "&");
			key = key.slice(0, 120);
		}
		return key;
	};

	get_custom_data(config_data, custom_fields) {
		let actual_conf_data = {};
		if (config_data && custom_fields) {
			config_data.forEach((conf) => {
				let data_key = conf?.data_key;
				let label = conf?.label;
				let value = custom_fields[data_key];
				if (conf?.type == "date" || conf?.type == "datetime-local") {
					if (this.incident_data.custom_field_data[data_key] instanceof Array) {
						this.incident_data.custom_field_data[data_key] =
							this.incident_data.custom_field_data[data_key][0];
					}
					if (value instanceof Array) {
						value = value[0];
					}
				}
				if (value) {
					actual_conf_data[label] = value;
				}
			});
		}
		return actual_conf_data;
	}

	onImpactServiceClick = (element: any, columnObj, event) => {
		event.preventDefault();
		if (![1, 2].includes(element?.state_id)) {
			return;
		}
		if (!element["dropdown_options"][columnObj?.optionKey + "_config"]) {
			element["dropdown_options"][columnObj?.optionKey + "_config"] = {};
		}
		element["dropdown_options"][columnObj?.optionKey + "_config"]["is_loading"] = true;
		element["dropdown_options"][columnObj.optionKey] = [];
		if (columnObj?.key == "impact_service") {
			this._incidentViewService
				.getRequesterImpactService({
					requester_id: element?.requester_id,
					is_published: true,
					module_name_list: JSON.stringify(["incident"]),
				})
				.subscribe(
					(res) => {
						element["dropdown_options"][columnObj?.optionKey + "_config"]["is_editable"] = true;
						element["dropdown_options"][columnObj.optionKey] = [];
						res?.forEach((ele) => {
							const data = new TreeviewItem(ele);
							element["dropdown_options"][columnObj.optionKey].push(data);
						});
						element["dropdown_options"][columnObj?.optionKey + "_config"]["is_loading"] = false;
					},
					(error) => {
						//console.log("error_while_fetching impact service", error);
						element["dropdown_options"][columnObj?.optionKey + "_config"]["is_loading"] = false;
					}
				);
		} else if (columnObj?.key == "service_classification") {
			if (element.impact_service) {
				this._incidentViewService
					.getImpactServiceClassification({ service_id: element.impact_service, format_to_treeview: true })
					.subscribe(
						(res) => {
							element["dropdown_options"][columnObj?.optionKey + "_config"]["is_editable"] = true;
							element["dropdown_options"][columnObj.optionKey] = [];
							res?.classification?.forEach((ele) => {
								const data = new TreeviewItem(ele);
								element["dropdown_options"][columnObj.optionKey].push(data);
							});
							element["dropdown_options"][columnObj?.optionKey + "_config"]["is_loading"] = false;
						},
						(error) => {
							//console.log("error_while_fetching impact service", error);
							element["dropdown_options"][columnObj?.optionKey + "_config"]["is_loading"] = false;
						}
					);
			} else {
				this._toastarService.error(this._translateService.instant("Error.err_select_impact_service"));
				element["dropdown_options"][columnObj?.optionKey + "_config"]["is_loading"] = false;
			}
		}
	};

	onPrioritySliderClose = ($event) => {
		if ($event != "submit") {
			this.is_priority_load = true;
			setTimeout(() => {
				this.is_priority_load = false;
			}, 10);
		}
		this.is_priority_change = false;
	};

	onPriorityChangeSubmit = ($event) => {
		let temp_incident_data = _deepCopyJson(this.incident_data);
		temp_incident_data["comment"] = $event["comment"];
		temp_incident_data.basic_info["priority"] = this.temp_priority;
		this.saveIncidentData.emit(temp_incident_data);
		this.is_priority_change = false;
	};

	closeApprovalPopup() {
		this.approval_team_id = null;
		this.modalService.dismissAll();
	}

	openModalAD(AssignmentComp) {
		this.modalService.open(AssignmentComp, {
			centered: true,
			animation: false,
			size: "md",
		});
	}

	onApprovalConfigClose = ($event) => {
		this.incident_data = {};
		this.temp_obj["value"] = this.incident_data_copy?.basic_info?.status;
		this.onInlineEditCallback?.emit(this.temp_obj);
		this.modalService.dismissAll();
		this.temp_status = null;
		this.temp_obj = null;
	};

	onApprovalConfigSubmit = ($event) => {
		this.modalService.dismissAll();
		this.incident_data = {};
		this.temp_obj["approval_config"] = $event;
		this.onInlineEditCallback?.emit(this.temp_obj);
		this.temp_status = null;
		this.temp_obj = null;
	};

	openApprovalChangeComp() {
		this.modalService.open(this.appovalChangeComp, {
			centered: true,
			animation: false,
			size: "md",
		});
	}

	onImgError(item) {
		item["showavatar"] = true
	}
}
