<div class="full-body-height">
	<div class="main-content flex-height activeSidebarPanel">
		<div class="left-sec">
			<div class="side-pannel">
				<div class="sidebar">
					<div class="asset-sidebar full-height">
						<div class="sidebar-content email-app-sidebar d-flex flex-column full-height">
							<div class="flex-1 full-height">
								<ng-scrollbar class="custom-scrollbar section-scrollbar">
									<div class="px-1 mb-1" *ngIf="instanceInfo">
										<h6 class="mb-50">{{ instanceInfo.sub_domain }}</h6>
										<a class="sm-font" href="{{ instanceInfo.sub_domain }}">
											<i class="fa-light fa-arrow-up-right-from-square ml-25"></i>
										</a>
									</div>
									<div class="sidebar-menu-list mt-25">
										<div class="main-menu-content">
											<ul class="custom-sidebar-menu2">
												<ng-container *ngFor="let menu of menuList">
													<li id="{{ menu.id }}" [class.active]="activeMenu === menu.handle">
														<a (click)="toggleMenu(menu.handle)">
															{{ menu.name }}
														</a>
													</li>
												</ng-container>
											</ul>
										</div>
									</div>
								</ng-scrollbar>
							</div>
							<div class="px-1 mt-1">
								<div class="bg-body card p-1 flex-row align-items-center mb-0">
									<div class="mr-1">
										<svg
											width="20"
											height="18"
											viewBox="0 0 20 18"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M18.125 0.25C18.4688 0.25 18.75 0.529687 18.75 0.875C18.75 1.22031 18.4688 1.5 18.125 1.5H3.125C2.08945 1.5 1.25 2.33945 1.25 3.375V14.625C1.25 15.6602 2.08945 16.5 3.125 16.5H16.875C17.9102 16.5 18.75 15.6602 18.75 14.625V5.875C18.75 4.83984 17.9102 4 16.875 4H4.375C4.03125 4 3.75 3.71875 3.75 3.375C3.75 3.03125 4.03125 2.75 4.375 2.75H16.875C18.6016 2.75 20 4.14844 20 5.875V14.625C20 16.3516 18.6016 17.75 16.875 17.75H3.125C1.39922 17.75 0 16.3516 0 14.625V3.375C0 1.64922 1.39922 0.25 3.125 0.25H18.125ZM14.0625 10.25C14.0625 9.73047 14.4805 9.3125 15 9.3125C15.5195 9.3125 15.9375 9.73047 15.9375 10.25C15.9375 10.7695 15.5195 11.1875 15 11.1875C14.4805 11.1875 14.0625 10.7695 14.0625 10.25Z"
												fill="#313349"
											/>
										</svg>
									</div>
									<div>
										<div class="sm-font fw-500">
											{{ "UI.k_available_credits" | translate }}
										</div>
										<h5 class="fw-600">{{ totalCredit() | currency: instanceCurrency() }}</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="main-sec">
			<div class="full-height">
				<ng-container *ngIf="activeMenu === 'products'">
					<app-dashboard-instance-products
						[instanceData]="instanceData"
						[instanceInfo]="instanceInfo"
					></app-dashboard-instance-products>
				</ng-container>
				<ng-container *ngIf="activeMenu === 'invoices'">
					<app-dashboard-instance-invoices
						[instanceData]="instanceData"
						[instanceInfo]="instanceInfo"
					></app-dashboard-instance-invoices>
				</ng-container>
				<!-- <ng-container *ngIf="activeMenu === 'security'">
					<app-dashboard-instance-security></app-dashboard-instance-security>
				</ng-container>
				<ng-container *ngIf="activeMenu === 'settings'">
					<app-dashboard-instance-settings></app-dashboard-instance-settings>
				</ng-container> -->
			</div>
		</div>
	</div>
</div>
