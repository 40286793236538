<div class="priority-sec">
	<div class="current-priority">
		<ng-container *ngFor="let priority of priorityOption">
			<ng-container *ngIf="priority.isActive === true">
				<div class="badge badge-light-{{ priority.color }}">
					<i class="fa-light mr-25 {{ priority.icon }}"></i> {{ priority.display_key | translate }}
				</div>
			</ng-container>
		</ng-container>
		<div class="priority-list card-bg">
			<ng-container *ngFor="let priority of priorityOption">
				<div class="priority" (click)="priorityEvent(priority)">
					<div
						class="badge badge-light-{{ priority?.color }}"
						[ngClass]="{
							selected: priority.isActive === true
						}"
					>
						<i class="fa-light mr-25 {{ priority.icon }}"></i> {{ priority.display_key | translate }}
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</div>
