import { Component, OnInit, Output, Input, EventEmitter } from "@angular/core";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { SupportPortalService } from "../../services/support-portal.service";
import { encryptData } from "app/common/utils/utils";
import { Validators, ValidatorFn, AbstractControl, UntypedFormControl, FormGroup, FormBuilder } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-invite-partneruser",
	templateUrl: "./invite-partneruser.component.html",
	styleUrls: ["./invite-partneruser.component.scss"],
})
export class InvitePartneruserComponent implements OnInit {
	@Output("onHideInviteProfile") onHideInviteProfile = new EventEmitter<any>();
	@Input() partnerid: Number;
	public defaultInvites = [{ userEmail: "", isValidEmail: true }];
	public isValidEmail = true;
	constructor(
		private _coreSidebarService: CoreSidebarService,
		private _service: SupportPortalService,
		private _toastr: ToastrService,
		private _translateService: TranslateService
	) {}

	ngOnInit(): void {}

	toggleSidebar = (name, form): void => {
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
		this.onHideInviteProfile.emit("hide");
	};

	addItem() {
		// this.commaSepEmail(this.defaultInvites[this.defaultInvites.length - 1].userEmail)
		this.defaultInvites.push({
			userEmail: "",
			isValidEmail: true,
		});
	}

	/**
	 * DeleteItem
	 *
	 * @param id
	 */
	deleteItem(id) {
		for (let i = 0; i < this.defaultInvites.length; i++) {
			if (this.defaultInvites.indexOf(this.defaultInvites[i]) === id) {
				this.defaultInvites.splice(i, 1);
				break;
			}
		}
	}
	commaSepEmail(emailInput, index) {
		if (emailInput) {
			const emails = emailInput.split(",");
			const forbidden = emails.some((email) => Validators.email(new UntypedFormControl(email)));
			this.defaultInvites[index]["isValidEmail"] = !forbidden;
			return !forbidden;
		} else {
			this.defaultInvites[index]["isValidEmail"] = false;
			return false;
		}
	}

	inviteUsers = (name, form, data): void => {
		// this.isValidEmail  = true
		// for (let row in this.defaultInvites ){
		// 	let emailInput = this.defaultInvites[row].userEmail
		// 	let isValidEmail = this.commaSepEmail(emailInput, row)
		// 	if (isValidEmail === false){
		// 		this.isValidEmail = isValidEmail
		// 	}
		// }
		if (form.form.valid == true) {
			if (this.defaultInvites.length == 0) {
				this._toastr.error(
					"Error.err_fail",
					this._translateService.instant("Error.err_invite_email_validation"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
				return;
			}
			this._service
				.sendUserInvite({
					data: encryptData(JSON.stringify(this.defaultInvites)),
					module_name: "Infinity Partner",
					partner_id: this.partnerid,
				})
				.pipe(
					map((response: any) => {
						if (response) {
							let email_ids_list = response.data?.existing_profiles;
							let pending_email_ids = response.data?.pending_profiles;
							// this.notification.success('Saved User', 'User has been saved successfully')
							if (response["status"] == "success") {
								this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
								this.onHideInviteProfile.emit("hide");
								setTimeout(() => {
									this._toastr.success(
										this._translateService.instant(response["message"], {
											email_ids_list: email_ids_list,
											pending_email_ids: pending_email_ids,
										}),
										this._translateService.instant("Message.msg_user_invite"),
										{
											toastClass: "toast ngx-toastr",
											closeButton: true,
											positionClass: "toast-top-right",
										}
									);
								}, 500);
							} else {
								this._toastr.error(
									this._translateService.instant(response["message"], {
										email_ids_list: email_ids_list,
										pending_email_ids: pending_email_ids,
									}),
									this._translateService.instant("Error.err_invite_fail"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							}
						}
						// Success
					})
					// catchError((error) => {
					// 	this._toastr.error("Failed to invite the Requester ", "Save Failed", {
					// 		toastClass: "toast ngx-toastr",
					// 		closeButton: true,
					// 		positionClass: "toast-top-right",
					// 	});
					//   this.notification.error('Failed User Creation', 'Something went wrong')
					// return Observable.throw(error.statusText);
					// })
				)
				.subscribe();
		}
	};
}
