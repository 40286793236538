<div class="d-flex justify-content-center mb-50 font-small-3" *ngIf="!setImage && imgType == 'Profile'">
	{{ "UI.k_upload_descr" | translate }}
</div>

<div
	class="upload-file-sec"
	[ngStyle]="{
		height: imgDetailsObject.aspectRatio == '4 / 1' ? '100px' : '200px'
	}"
>
	<div class="custom-img-uploader" [ngClass]="{ hasCropedImg: this.hasCropedImg === true }">
		<div>
			<a href="javascript:void(0)" (click)="file.click()">
				<!--py-4 -->
				<div
					ng2FileDrop
					ng2FileSelect
					[ngClass]="{
						'file-drop-over': hasBaseDropZoneOver
					}"
					(fileOver)="fileOverBase($event)"
					(onFileDrop)="onUpload($event, modalVC)"
					[uploader]="uploader"
					class="d-flex text-center font-small-4 file-drop-zone px-2"
					(change)="onChange($event);"
					id="drpZoneImg"
					name="drpZoneImg"
					[attr.data-testid]="'drpZoneImg'"
					[ngStyle]="{
						'border-radius': imgType === 'GettingStarted' ? '10px' : ''
					}"
				>
					<div class="align-items-center">
						<div
							class="mr-1"
							[ngStyle]="{
								margin: imgType != 'Profile' ? '0' : ''
							}"
						>
							<span class="mt-50"><i class="fa-light fa-upload font-small-3"></i></span>
						</div>
						<div class="">
							<span
								class="font-small-3"
								[ngStyle]="{
									margin: imgType != 'Profile' ? '0' : ''
								}"
							>
								{{ "UI.k_drag_drop_file" | translate }}
								{{ "UI.k_or" | translate | lowercase }}
								{{ "UI.k_browse_to_upload" | translate }}
							</span>
							<span *ngIf="displayResoluion">(
								<span *ngIf="ALLOWED_TYPES" class="text-primary font-small-2">
									{{ "UI.k_formats" | translate }} : {{ allowedfileType }}</span
								>
								<span *ngIf="MAX_IMAGE_SIZE" class="text-primary font-small-2">
									| {{ "UI.k_max_size" | translate }} : {{ fileSize }}
								</span>
								<span *ngIf="imgDetailsObject.aspectRatio" class="text-primary font-small-2">
									| {{ "UI.k_aspect_ratio" | translate }} : {{ imgDetailsObject.aspectRatio }} </span
								> )
							</span>
						</div>
						<input
							type="file"
							#file
							[multiple]="multipleFiles"
							(change)="fileChangeEvent($event, modalVC)"
							style="display: none"
							id="fileTest"
							[attr.data-testid]="'fileTest'"
							accept="{{ this.ALLOWED_TYPES }}"
							(onFileSelected)="getSelectedFiles($event)"
						/>
					</div>
				</div>
			</a>
		</div>
		<div class="invalid-feedback d-block" *ngIf="error">
			{{ "UI.k_only_one_file" | translate }}
		</div>
		<div class="uploaded-image" [ngClass]="{ hasImg: this.setImage !== null }">
			<img src="{{ this.setImage }}" />
			<div class="remove-btn" (click)="removeImage()">
				<!-- <i data-feather="trash"></i> -->
				<i class="fa-light fa-trash-can"></i>
			</div>
		</div>
	</div>
	<!--Show Queue section start-->
	<div class="overflow-auto upload-file-list" *ngIf="showQueue">
		<div class="d-flex justify-content-between">
			<h3>{{ "UI.k_show_queue" | translate }}</h3>
		</div>
		<table class="table mb-2">
			<thead>
				<tr>
					<th width="50%">{{ "UI.k_name" | translate }}</th>
					<th>{{ "UI.k_size" | translate }}</th>
					<th>{{ "UI.k_actions" | translate }}</th>
				</tr>
			</thead>
			<tbody *ngIf="allFileList">
				<tr *ngFor="let item of allFileList">
					<td>{{ item?.name }}</td>
					<td *ngIf="uploader.options.isHTML5" nowrap>{{ item?.size / 1000 }} MB</td>
					<td nowrap>
						<button
							type="button"
							class="btn btn-raised btn-outline-success btn-sm mr-50"
							rippleEffect
							[attr.data-testid]="'btn_upload_doc'"
							(click)="uploadFile(item)"
						>
							<!-- <span data-feather="upload"></span> -->
							<span><i class="fa-light fa-upload"></i></span>
						</button>
						<button
							type="button"
							class="btn btn-raised btn-outline-danger btn-sm cursor-pointer"
							rippleEffect
							(click)="removeFiles(item)"
						>
							<!-- <i data-feather="trash"></i> -->
							<i class="fa-light fa-trash"></i>
						</button>
					</td>
				</tr>
			</tbody>
		</table>
		<div id="">
			<button
				type="button"
				class="btn mr-1 btn-primary mb-1 mb-sm-0"
				rippleEffect
				(click)="uploadAllFiles()"
				id="btnUploadAll"
				name="btnUploadAll"
				[attr.data-testid]="'btnUploadAll'"
			>
				<!-- <span data-feather="upload" class="mr-25"></span> -->
				<span><i class="fa-light fa-upload mr-25"></i></span>
				{{ "UI.k_upload_all" | translate }}
			</button>

			<button
				type="button"
				class="btn btn-outline-danger mb-1 mb-sm-0"
				rippleEffect
				(click)="removeAllFiles()"
				id="btnRemoveAll"
				name="btnRemoveAll"
				[attr.data-testid]="'btnRemoveAll'"
			>
				<!-- <span data-feather="trash" class="mr-25"></span>  -->
				<span><i class="fa-light fa-trash mr-25"></i></span>
				{{ "UI.k_remove_all" | translate }}
			</button>
		</div>
	</div>
	<!--Show Queue section ends-->
</div>
<br />
<br />

<ng-template #modalVC let-modal>
	<div class="modal-header">
		<h5 class="modal-title" id="exampleModalCenterTitle">
			{{ "UI.k_img_upload" | translate }}
		</h5>

		<button
			type="button"
			class="close"
			(click)="modal.dismiss('Cross click')"
			aria-label="Close"
			id="btnImgUploadCls"
			name="btnImgUploadCls"
			[attr.data-testid]="'btnImgUploadCls'"
		>
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body img-crop-modal" tabindex="0" ngbAutofocus>
		<div class="funtion-btn-group">
			<button
				type="button"
				class="btn btn-sm btn-outline-tertiary mr-1"
				rippleEffect
				(click)="rotateLeft()"
				placement="bottom"
				ngbTooltip="{{ 'UI.k_rotate_left' | translate }}"
				id="btnImgRotleft"
				name="btnImgRotleft"
				[attr.data-testid]="'btnImgRotleft'"
			>
				<!-- <i data-feather="rotate-ccw"></i> -->
				<i class="fa-light fa-rotate-left" aria-hidden="true"></i>
			</button>
			<button
				type="button"
				class="btn btn-sm btn-outline-tertiary mr-1"
				rippleEffect
				placement="bottom"
				ngbTooltip="{{ 'UI.k_rotate_right' | translate }}"
				(click)="rotateRight()"
				id="btnImgRotRight"
				name="btnImgRotRight"
				[attr.data-testid]="'btnImgRotRight'"
			>
				<!-- <i data-feather="rotate-cw"></i> -->
				<i class="fa-light fa-rotate-right" aria-hidden="true"></i>
			</button>
			<div *ngIf="flipImages">
				<button
					type="button"
					class="btn btn-primary mr-1"
					rippleEffect
					placement="bottom"
					ngbTooltip="{{ 'UI.k_flip_horizontal' | translate }}"
					(click)="flipHorizontal()"
					id="btnImgFlipH"
					name="btnImgFlipH"
					[attr.data-testid]="'btnImgFlipH'"
				>
					<img
						src="../../../../../assets/images/flip-horizontal.png"
						alt="{{ 'UI.k_flip_horizontal' | translate }}"
					/>
				</button>
				<button
					type="button"
					class="btn btn-primary mr-1"
					rippleEffect
					placement="bottom"
					ngbTooltip="{{ 'UI.k_flip_vertical' | translate }}"
					(click)="flipVertical()"
					id="btnImgFlipV"
					name="btnImgFlipV"
					[attr.data-testid]="'btnImgFlipV'"
				>
					<img
						src="../../../../../assets/images/flip-vertical.png"
						alt="{{ 'UI.k_flip_vertical' | translate }}"
					/>
				</button>
			</div>
			<button
				type="button"
				class="btn btn-outline-tertiary mr-1"
				rippleEffect
				placement="bottom"
				ngbTooltip="{{ 'UI.k_reset_image' | translate }}"
				(click)="resetImage()"
				id="btnImgReset"
				name="btnImgReset"
				[attr.data-testid]="'btnImgReset'"
			>
				{{ "UI.k_reset" | translate }}
			</button>
			<!-- <button
      type="button"
      class="btn btn-outline-tertiary"
      rippleEffect
      placement="bottom"
      ngbTooltip="Set Default Image"
      (click)="check()"
    >
      Default 
    </button> -->
		</div>
		<!-- [ngClass]="{ noCroped: this.isDefault === true }" -->
		<div class="img-cropp-sec">
			<ng-container *ngIf="(imgDetailsObject?.aspectRatio != '4 / 1' || !imgDetailsObject) && is_cover">
				<image-cropper
					[imageChangedEvent]="imageChangedEvent"
					[maintainAspectRatio]="(imgDetailsObject.hasOwnProperty('maintainAspectRatio') ? imgDetailsObject?.maintainAspectRatio : true)"
					[containWithinAspectRatio]="false"
					[aspectRatio]="4 / 1"
					[onlyScaleDown]="false"
					[roundCropper]="false"
					[canvasRotation]="canvasRotation"
					[transform]="transform"
					[alignImage]="'left'"
					[style.display]="showCropper ? null : 'none'"
					format="png"
					(imageCropped)="imageCropped($event)"
					(imageLoaded)="imageLoaded()"
					(cropperReady)="cropperReady($event)"
					(loadImageFailed)="loadImageFailed()"
				></image-cropper>
			</ng-container>
			<ng-container *ngIf="imgDetailsObject.aspectRatio == '4 / 1'">
				<image-cropper
					[imageChangedEvent]="imageChangedEvent"
					[maintainAspectRatio]="imgDetailsObject?.maintainAspectRatio"
					[containWithinAspectRatio]="containWithinAspectRatio"
					[aspectRatio]="4 / 1"
					[onlyScaleDown]="false"
					[roundCropper]="false"
					[canvasRotation]="canvasRotation"
					[transform]="transform"
					[alignImage]="'left'"
					[style.display]="showCropper ? null : 'none'"
					format="png"
					(imageCropped)="imageCropped($event)"
					(imageLoaded)="imageLoaded()"
					(cropperReady)="cropperReady($event)"
					(loadImageFailed)="loadImageFailed()"
					class="image-cropper"
				></image-cropper>
			</ng-container>
			<ng-container *ngIf="(imgDetailsObject.aspectRatio != '4 / 1' || !imgDetailsObject) && !is_cover">
				<image-cropper
					[imageChangedEvent]="imageChangedEvent"
					[maintainAspectRatio]="(imgDetailsObject.hasOwnProperty('maintainAspectRatio') ? imgDetailsObject?.maintainAspectRatio : true)"
					[containWithinAspectRatio]="containWithinAspectRatio"
					[aspectRatio]="4 / 4"
					[resizeToWidth]="128"
					[multiple]="multipleFiles"
					[cropperMinWidth]="128"
					[onlyScaleDown]="true"
					[roundCropper]="false"
					[canvasRotation]="canvasRotation"
					[transform]="transform"
					[alignImage]="'left'"
					[style.display]="showCropper ? null : 'none'"
					format="png"
					(imageCropped)="imageCropped($event)"
					(imageLoaded)="imageLoaded()"
					(cropperReady)="cropperReady($event)"
					(loadImageFailed)="loadImageFailed()"
					class="image-cropper"
				></image-cropper>
			</ng-container>
		</div>
		<!-- <div class="cropped-btn-grouped mt-1 mb-1 d-flex justify-content-end align-items-center">
      <button
        type="button"
        class="btn btn-outline-tertiary mb-sm-0"
        rippleEffect
        (click)="modal.close('Accept click')"
      >
        Cancel
      </button>
      <button
      type="button"
      class="btn btn-primary ml-1"
      rippleEffect
      (click)="setCroppedImage()"
    >
      Save
    </button>
    </div> -->

		<!-- <p>
      Croissant jelly-o halvah chocolate sesame snaps. Brownie caramels candy canes chocolate cake
      marshmallow icing lollipop I love. Gummies macaroon donut caramels biscuit topping danish.
    </p> -->
	</div>
	<div class="modal-footer border-top">
		<button
			id="btnCropCancel"
			name="btnCropCancel"
			type="button"
			class="btn btn-outline-tertiary mb-sm-0"
			rippleEffect
			(click)="closeModal()"
		>
			{{ "UI.k_cancel" | translate }}
		</button>
		<button
			id="btnCropsave"
			name="btnCropsave"
			type="button"
			class="btn btn-primary ml-1"
			rippleEffect
			(click)="setCroppedImage()"
		>
			{{ "UI.k_save" | translate }}
		</button>
	</div>
</ng-template>
