import { Component, OnInit } from '@angular/core';
import { TriggerService } from 'app/base/trigger-configuration/services/trigger-configuration.service';

@Component({
    selector: 'app-add-escalation',
    templateUrl: './add-escalation.component.html',
    styleUrls: ['./add-escalation.component.scss']
})
export class AddEscalationComponent implements OnInit {
    public escalationConfig = {
        ack_time_limit: null,
        notifyMethods: [],
        emailFeature: false,
        esc_after_ack: true
    }

    constructor(public _notifyService: TriggerService) { }

    ngOnInit(): void {
        this._notifyService.getOptions().subscribe(
            (response) => {
                if (response) {
                    this.escalationConfig.notifyMethods = response.notofierMethods
                }
            },
            (error) => {
                //todo
            }
        );
    }

    notifyButtonEventFromEscalation(item) {
        //console.log(item);
        if (item && item.name === 'Email') {
            this.escalationConfig.emailFeature = true
        } else {
            this.escalationConfig.emailFeature = false
        }

    }

}
