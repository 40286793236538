import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from '@angular/common';
import { AuthGuard } from "app/common/guards/auth.guards";
import { BotsConfigurationGridComponent } from './components/bots-configuration-grid/bots-configuration-grid.component';

const routes: Routes = [
	{
		path: "",
		component: BotsConfigurationGridComponent,
		canActivate: [AuthGuard],
		data: {
			module: ["organization"],
			keyList: ["view","edit"],
			fflag: ["InfraonAIBot"],
		},
	},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class BotsConfigurationRoutingModule { }
