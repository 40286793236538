import { Component, OnInit, EventEmitter, Output, ViewEncapsulation, ViewChild, Input } from "@angular/core";
import * as moment from "moment";
import {
	NgbDateStruct,
	NgbCalendar,
	NgbDate,
	NgbDropdown,
	NgbModal,
	NgbDateParserFormatter,
	NgbTimeStruct,
	ModalDismissReasons,
} from "@ng-bootstrap/ng-bootstrap";
import { NgSelectComponent } from "@ng-select/ng-select";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

interface DnsRange {
	name: string;
	key: string;
	addProfile: string;
	startDate: any;
	endDate: any;
	is_configure: boolean;
	selected: boolean;
}

let momentDate = moment.utc(new Date());

let startOfDay = momentDate.clone().startOf("day");
let endOfDay = momentDate.clone().endOf("day");

@Component({
	selector: "date-range-picker",
	templateUrl: "./date-range-picker.component.html",
	styleUrls: ["./date-range-picker.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class DateRangePickerComponent implements OnInit {
	range: DnsRange[];
	selectedRange: DnsRange;
	public hoveredDate: NgbDate | null = null;
	public fromDate: NgbDate | null;
	public strToDate: string;
	public strFromDate: string;
	public toDate: NgbDate | null;
	@Output("select") onRangeSelect: EventEmitter<any> = new EventEmitter<any>();

	public selectedItem = "";
	selectedDropdownItem: any;
	subCategoryInfo: any;
	days = [];
	customDate = "";
	currentDate: Date = new Date();
	@Input() customWidth: any;
	@Input() customHeight: any;
	@Input() selectedDuration: any = [];
	@Input() customDateRange: any;
	@Input() dateRange: any;
	closeResult = "";

	selectedValue: any;
	prevValue: any;
	profileName: any;
	profileDes: any;
	maxDate: NgbDate;
	// Access ng-select
	@ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

	constructor(
		private modalService: NgbModal,
		private calendar: NgbCalendar,
		public formatter: NgbDateParserFormatter,
		private _toastr: ToastrService,
		private _translateService: TranslateService
	) {
		// if (!this.dateRange) {
		// 	this.maxDate = this.calendar.getToday();
		// }
		let defaultRange: DnsRange[] = [
			{
				name: this._translateService.instant("UI.k_current_hour"),
				key: "currenthour",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			{
				name: this._translateService.instant("UI.k_last_60min"),
				key: "last60min",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			{
				name: this._translateService.instant("UI.k_last_hour"),
				key: "lasthour",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			{
				name: this._translateService.instant("UI.k_last_3hour"),
				key: "last3hrs",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			{
				name: this._translateService.instant("UI.k_last_6hour"),
				key: "last6hrs",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			{
				name: this._translateService.instant("UI.k_last_12hour"),
				key: "last12hrs",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			{
				name: this._translateService.instant("UI.k_last_24hour"),
				key: "last24hrs",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			{
				// name: "Today",
				name: this._translateService.instant("UI.k_today"),
				key: "currentDay",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			{
				name: this._translateService.instant("UI.k_yesterday"),
				key: "lastDay",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			{
				name: this._translateService.instant("UI.k_current_week"),
				key: "currentWeek",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			{
				name: this._translateService.instant("UI.k_last_week"),
				key: "lastWeek",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			{
				name: this._translateService.instant("UI.k_current_month"),
				key: "currentMonth",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			{
				name: this._translateService.instant("UI.k_last_month"),
				key: "lastMonth",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			{
				name: this._translateService.instant("UI.k_last_7_days"),
				key: "last7days",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			{
				name: this._translateService.instant("UI.k_last_30_days"),
				key: "last30days",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			{
				name: this._translateService.instant("UI.k_last_60_days"),
				key: "last60days",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			{
				name: this._translateService.instant("UI.k_last_90_days"),
				key: "last90days",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			{
				name: this._translateService.instant("UI.k_last_180_days"),
				key: "last180days",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			{
				name: this._translateService.instant("UI.k_last_365_days"),
				key: "last365days",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			//last 3 months
			{
				name: this._translateService.instant("UI.k_last_3_months"),
				key: "last3Month",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			//last 6 months
			{
				name: this._translateService.instant("UI.k_last_6_months"),
				key: "last6Month",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			//last 12 months
			{
				name: this._translateService.instant("UI.k_last_12_months"),
				key: "last12Month",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			//current year
			{
				name: this._translateService.instant("UI.k_current_year"),
				key: "currentYear",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			//last year
			{
				name: this._translateService.instant("UI.k_last_year"),
				key: "lastYear",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			//current quarter
			{
				name: this._translateService.instant("UI.k_current_quarter"),
				key: "currentQuarter",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			//next quarter //not required to process modules
			// {
			// 	name: this._translateService.instant("UI.k_next_quarter"),
			// 	key: "nextQuarter",
			// 	addProfile: "",
			// 	startDate: null,
			// 	endDate: null,
			// 	is_configure: false,
			// 	selected: false,
			// },
			//last quarter
			{
				name: this._translateService.instant("UI.k_last_quarter"),
				key: "lastQuarter",
				addProfile: "",
				startDate: null,
				endDate: null,
				is_configure: false,
				selected: false,
			},
			{
				addProfile: "Custom Range",
				name: this._translateService.instant("UI.k_custom_range"),
				key: "custom",
				startDate: moment().subtract(7, "days"),
				endDate: moment(),
				is_configure: false,
				selected: false,
			},
		];

		if (this.customDateRange) {
			this.range = this.customDateRange;
		} else {
			this.range = defaultRange;
		}
		this.toDate = calendar.getToday();
		this.strToDate = formatter.format(this.toDate);
		this.fromDate = calendar.getPrev(calendar.getToday(), "d", 7);
		this.strFromDate = formatter.format(this.fromDate);
	}

	ngOnInit(): void {}
	ngAfterViewInit(): void {
		if (
			this.selectedDuration.time_func === null ||
			this.selectedDuration.time_func === undefined ||
			this.selectedDuration.time_func === ""
		) {
			this.ngSelectComponent?.clearItem(this.selectedValue);
			this.prevValue = this.selectedValue;
		} else {
			this.selectedValue = this.selectedDuration;
			this.prevValue = this.selectedValue;
		}
		if (Object.keys(this.selectedDuration).length !== 0) {
			this.selectedValue = this.selectedDuration;
		}
	}
	ngOnChanges(): void {
		if (this.customDateRange) {
			this.range = this.customDateRange;
		}
		if (
			this.selectedDuration.time_func === null ||
			this.selectedDuration.time_func === undefined ||
			this.selectedDuration.time_func === ""
		) {
			this.ngSelectComponent?.clearItem(this.selectedValue);
			this.prevValue = this.selectedValue;
		} else if (this.selectedValue?.key !== "custom") {
			this.prevValue = this.selectedValue;
		}
	}
	onApply = (item) => {
		this.selectedRange = item;
		this.selectedValue = item;
		//item.selected = !item.selected;
		if (this.selectedItem != "Custom Range") {
			if (item?.key) {
				this.onRangeSelect.emit({
					time_func: item?.key,
					label: item?.name,
					item: item?.name,
				});
			} else {
				let result = {
					time_func: item?.key,
					item: item?.name,
					label: item?.name,
					start_date: item?.startDate?.format(),
					end_date: item?.endDate?.format(),
				};
				this.onRangeSelect.emit(result);
			}
		} else {
			let result = {
				start_date: item.start_date,
				end_date: item.end_date,
				item: "customdate",
				label: "customdate",
			};

			if (!item.start_date && !item.end_date) {
				this.customDate = (this.strFromDate + " to " + this.strToDate).toString();
			} else if (item.start_date && !item.end_date) {
				this.customDate = item.start_date.toString();
				result = {
					start_date: item.start_date,
					end_date: item.start_date,
					item: "customdate",
					label: "customdate",
				};
			} else {
				this.customDate = (item.start_date + " to " + item.end_date).toString();
			}
			this.onRangeSelect.emit(result);

			//this.range.push({addProfile: "",
			//                   name: this.customDate,
			//                   startDate: '',
			//                   endDate: '',
			//                   is_configure: false,
			//                   selected: false})

			this.selectedItem = this.customDate;
			this.selectedValue = {
				addProfile: "",
				name: this.customDate,
				startDate: "",
				endDate: "",
				is_configure: false,
				selected: false,
			};
			this.prevValue = this.selectedValue;
		}
	};

	onDateSelection(date: NgbDate) {
		const today = this.calendar.getToday();
		if (this.dateRange) {
			this.maxDate = this.calendar.getNext(date, "d", this.dateRange);
			// if (this.maxDate.after(today)) {
			// 	this.maxDate = today;
			// }
		}
		if (!this?.fromDate && !this?.toDate) {
			this.fromDate = date;
			// } else if (this.fromDate && !this.toDate && date && !date.after(this.fromDate)) {
		} else if (this?.fromDate && !this?.toDate && date && !date.before(this?.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}

		if (this.selectedItem == "Custom Range") {
			this.selectedDropdownItem["end_date"] = this.formatter.format(this?.toDate);
			this.selectedDropdownItem["start_date"] = this.formatter.format(this?.fromDate);
			this.selectedDropdownItem["startDate"] = new Date(
				this?.fromDate?.year,
				this?.fromDate?.month - 1,
				this?.fromDate?.day
			);
			this.selectedDropdownItem["endDate"] = new Date(
				this?.toDate?.year,
				this?.toDate?.month - 1,
				this?.toDate?.day
			);
		}
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate &&
			!this.toDate &&
			this.hoveredDate &&
			date.after(this.fromDate) &&
			date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}

	onSelectFilter = ($evt, itemid) => {
		this.selectedItem = $evt["name"];
		this.selectedDropdownItem = $evt;
		this.fromDate = this.calendar.getPrev(this.calendar.getToday(), "d", 7);
		this.toDate = this.calendar.getToday();
		if (this.selectedItem) {
			if (this.selectedItem == "Custom Range") {
				this.toDate = this.calendar.getToday();
				this.fromDate = this.calendar.getPrev(this.calendar.getToday(), "d", 7);
			} else {
				let result = {
					start_date: $evt?.startDate?.format(),
					end_date: $evt?.endDate?.format(),
				};
				this.strFromDate = result.start_date.split("T")[0];
				this.strToDate = result.end_date.split("T")[0];

				this.fromDate["day"] = parseInt(this.strFromDate.split("-")[2]);
				this.fromDate["month"] = parseInt(this.strFromDate.split("-")[1]);
				this.fromDate["year"] = parseInt(this.strFromDate.split("-")[0]);
				this.toDate["day"] = parseInt(this.strToDate.split("-")[2]);
				this.toDate["month"] = parseInt(this.strToDate.split("-")[1]);
				this.toDate["year"] = parseInt(this.strToDate.split("-")[0]);
			}
		}
	};

	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			this.selectedValue = this.prevValue;
			return "by pressing ESC";
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			this.selectedValue = this.prevValue;
			return "by clicking on a backdrop";
		} else if (reason === "Cross click") {
			this.selectedValue = this.prevValue;
		} else {
			return `with: ${reason}`;
		}
	}

	modalOpenSM(modalSM, e) {
		if (e && e.addProfile) {
			this.selectedItem = this._translateService.instant("UI.k_custom_range");
			this.selectedDropdownItem = e;
			// this.selectedValue = null
			this.modalService
				.open(modalSM, {
					centered: true,
					size: "md", // size: 'xs' | 'sm' | 'lg' | 'xl'
					scrollable: true,
				})
				.result.then(
					(result) => {
						this.closeResult = `Closed with: ${result}`;
					},
					(reason) => {
						this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
					}
				);
			//this.modalService.open(modalProfile, {
			//  centered: true,
			//  windowClass: 'add-profile',
			//});
		} else {
			let result = {};
			if (e != undefined) {
				this.selectedRange = e;
				this.selectedDropdownItem = e;
				e.selected = !e.selected;
				result = {
					start_date: e?.startDate?.format(),
					end_date: e?.endDate?.format(),
					time_func: e?.key,
					label: e?.name,
				};
			}
			this.onRangeSelect.emit(result);
		}
	}

	clearData() {
		this.modalService.dismissAll();
		this.profileName = null;
		this.profileDes = null;
	}

	ngOnDestroy() {
		this.modalService.dismissAll();
	}
}
