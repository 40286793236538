<div class="no-user">
	<ng-container *ngIf="!noFilterData">
		<div class="d-flex justify-content-center pb-2">
			<i class="fa-light {{ emptyData?.icon }} fa-3x"></i>
		</div>
		<div
			class="d-flex justify-content-center xl-font fw-500 title-color pb-50"
		>
			<span *ngIf="emptyData?.msg">{{ emptyData?.msg | translate }}</span>
			<span *ngIf="(!emptyData || !emptyData?.msg) && !noFilterData">{{
				"Error.err_data_not_available" | translate
			}}</span>
		</div>
		<div *ngIf="permissions && permissions?.add" class="title-color reg-font d-flex flex-wrap justify-content-center pb-2 1">
			{{ emptyData?.description | translate }}
		</div>
		<div *ngIf="permissions && !permissions?.add" class="title-color reg-font d-flex flex-wrap justify-content-center pb-2 2">
			{{ "Message.msg_no_add_privilege" | translate}}
		</div>
		<div
			class="d-flex justify-content-center pb-1"
			*ngIf="emptyData?.button && permissions?.add"
		>
			<button
				class="btn btn-primary"
				rippleEffect
				(click)="emptyData?.add_callback(emptyData?.sidebar_name)"
				id="{{ emptyData?.id ? emptyData?.id : 'btn' }}"
				name="{{ emptyData?.name ? emptyData?.name : 'btn' }}"
				attr.data-testid="{{ emptyData?.id ? emptyData?.id : 'btn' }}"
			>
				<i class="fa-light fa-plus font-small-3"></i>&nbsp;
				<span class="d-none d-sm-inline-block font-small-3">
					{{ emptyData?.button | translate }}
				</span>
			</button>
		</div>

		<div
			class="d-flex justify-content-center pb-1"
			*ngIf="emptyData?.type === 'btn-dropdown' && permissions?.add"
			ngbDropdown
		>
			<button
				ngbDropdownToggle
				class="btn btn-gradient-primary"
				type="button"
				id="{{ emptyData?.id ? emptyData?.id : 'btn' }}"
				rippleEffect
				tooltipClass="fadeInAnimation"
				attr.data-testid="{{ emptyData?.id ? emptyData?.id : 'btn' }}"
			>
				<!--ngbTooltip="{{ emptyData?.tooltip | translate }}"-->

				{{ emptyData?.label | translate }}
			</button>
			<div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
				<a
					*ngFor="let item of emptyData?.config; let i = index"
					ngbDropdownItem
					id="{{item?.id ? item?.id : 'btn{{i}}'}}"
					name="{{item?.id ? item?.id : 'btn{{i}}'}}"
					attr.data-testid="{{item?.id ? item?.id : 'btn{{i}}'}}"
					href="javascript:void(0)"
					(click)="item?.add_callback(item?.sidebar_name)"
				>
					{{ item?.label | translate }}
				</a>
			</div>
		</div>

		<hr *ngIf="emptyData?.import_csv" />
		<div
			class="d-flex justify-content-center font-small-4 font-weight-bold text-color pb-2 pt-50"
			*ngIf="emptyData?.import_csv && permissions?.add"
		>
			{{
				(emptyData?.import_csv_text
					? emptyData.import_csv_text
					: "UI.k_import_others"
				) | translate
			}}
		</div>
		<div
			class="d-flex justify-content-center text-primary cursor-pointer font-small-4"
			(click)="emptyData?.csv_callback()"
			*ngIf="emptyData?.import_csv"
		>
			{{ "UI.k_import_xlsx" | translate }}
		</div>
	</ng-container>

	<div
		*ngIf="noFilterData"
		class="d-flex justify-content-center no-filter font-medium-4 font-weight-bold text-color pb-50"
	>
		<!-- {{ 'UI.k_no_filtered_data' | translate }} -->
		<div class="icon-sec">
			<img
				class="light-icon"
				src="../../../../../assets/images/svg/empty-state-light.svg"
				alt=""
			/>
			<img
				class="dark-icon"
				src="../../../../../assets/images/svg/empty-state-dark.svg"
				alt=""
			/>
		</div>
		<div class="info-sec">
			<h6>{{ "Error.err_no_filter_result" | translate }}</h6>
			<p class="mb-0">{{ "Error.err_search_retry" | translate }}</p>
		</div>
	</div>
</div>
