import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { DashboardService } from "app/base/dashboard/services/dashboard.service";
@Component({
	selector: "app-assignee",
	templateUrl: "./assignee.component.html",
	styleUrls: ["./assignee.component.scss"],
})
export class AssigneeComponent implements OnInit {
	public userObject = {
		userList: [],
		copyUserList: [],
		groupList: [],
		copyGroupList: [],
		searchUserText: "",
		searchGroupText: "",
	};
	// @Output("onHideInvite") onHideInvite = new EventEmitter<any>();
	@Output() emittedFromUser: EventEmitter<any> = new EventEmitter();
	@Input() userList: any;
	@Input() groupList: any;
	teamLicense: any;
	constructor(public dashboardSvc: DashboardService, private _coreSidebarService: CoreSidebarService) {}

	ngOnInit(): void {
		this.userObject.userList = JSON.parse(JSON.stringify(this.userList));
		this.userObject.copyUserList = JSON.parse(JSON.stringify(this.userList));
		this.userObject.groupList = JSON.parse(JSON.stringify(this.groupList));
		this.userObject.copyGroupList = JSON.parse(JSON.stringify(this.groupList));
		this.teamLicense = {
			module_key: "BASE",
			keys: ["TEAMS_CONFIG"],
		};
	}

	searchUserEvent(event) {
		if (event) {
			const filter = [];
			this.userObject.copyUserList.forEach((e) => {
				if (
					e.name.toLowerCase().indexOf(event.toLowerCase()) > -1 ||
					e.email.toLowerCase().indexOf(event.toLowerCase()) > -1
				) {
					filter.push(e);
				}
			});
			if (filter) {
				this.userObject.userList = filter;
			}
		} else {
			this.userObject.userList = this.userObject.copyUserList;
		}
	}

	searchTeamEvent(e) {
		if (e) {
			const filter = this.userObject.copyGroupList.filter(
				(id) => id.name.toLowerCase().indexOf(e.toLowerCase()) > -1
			);
			if (filter) {
				this.userObject.groupList = filter;
			}
		} else {
			this.userObject.groupList = this.userObject.copyGroupList;
		}
	}

	// ngOnChanges(changes: SimpleChanges): void {
	// 	this.userObject.userList = this.userList;
	// 	this.userObject.copyUserList = this.userList;
	// 	this.userObject.groupList = this.groupList;
	// 	this.userObject.copyGroupList = this.groupList;
	// }

	// bindJsonData() {
	// 	this.dashboardSvc.getOptions({}).subscribe(
	// 		(response) => {
	// 			this.userObject.userList = response.users;
	// 			this.userObject.copyUserList = response.users;
	// 			this.userObject.groupList = response.groups;
	// 			this.userObject.copyGroupList = response.groups;
	// 		},
	// 		(error) => {
	// 			//todo
	// 		}
	// 	);
	// }

	selectUserItem(item) {
		this.userObject.userList.forEach((e) => {
			if (e.profile_id === item.profile_id) {
				if (item && item.selected) {
					item["selected"] = !item.selected;
				} else {
					item["selected"] = true;
				}
			}
		});
	}

	selectGroupEvent(item) {
		this.userObject.groupList.forEach((e) => {
			if (e.id === item.id) {
				if (item && item.selected) {
					item["selected"] = !item.selected;
				} else {
					item["selected"] = true;
				}
			}
		});
	}

	userSaveEvent() {
		this.emittedFromUser.emit({
			eventName: "user",
			data: {
				userList: this.userObject.userList,
				groupList: this.userObject.groupList,
			},
		});
	}

	closeEvent() {
		// this._coreSidebarService.getSidebarRegistry('assignee-data').toggleOpen();
		this.emittedFromUser.emit({ eventName: "close", flag: false });
	}
	toggleSidebar = (name): void => {
		// this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
		// form.resetForm();
		// this.onHideInvite.emit("hide");
		this.emittedFromUser.emit({ eventName: "close", flag: false });
	};
}
