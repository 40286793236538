import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { CoreConfigService } from "@core/services/config.service";
@Injectable({
	providedIn: "root",
})
export class CommonService {
	productToggal = new BehaviorSubject<boolean>(false);
	settingToggal = new BehaviorSubject<boolean>(false);
	submenuToggal = new BehaviorSubject<boolean>(false);
	currentscreen = new BehaviorSubject<any>(null);
	themeValue = new BehaviorSubject<any>(null);
	fluentMenu = new BehaviorSubject<any>(null);
	prevSkin = new BehaviorSubject<any>(null);
	layoutStyle = new BehaviorSubject<any>(null);
	enableDarkMode = new BehaviorSubject<boolean>(true);
	submitTicket = new BehaviorSubject<any>('')
	isDarktheme = new BehaviorSubject<boolean>(false);
	logoBgColor = new BehaviorSubject<any>('')
	navBgColor = new BehaviorSubject<any>('')
	currentRoute = new BehaviorSubject<any>('')
	showDemoVideo = new BehaviorSubject<boolean>(false);
	initCallApp = new BehaviorSubject<boolean>(false);
	themeMap = new Map().set("green", 1).set("blue", 2).set("yellow", 3);
	requester = new BehaviorSubject<object>({ req: "", reqProfile: "" });;
	constructor(private _coreConfigService: CoreConfigService) {}

	updateProductToggle(data): void {
		this.productToggal.next(data);
	}
	updateSettnigToggle(data): void {
		this.settingToggal.next(data);
	}
	updateSubMenuToggle(data): void {
		this.submenuToggal.next(data);
	}
	updateThemeValue(data): void {
		localStorage.setItem("theme", data);
		this.themeValue.next(data);
	}
	updateCurrentSceen(data): void {
		this.currentscreen.next(data);
	}
	updateFluentMenu(data): void {
		this.fluentMenu.next(data);
	}
	updatePrevSkin(data): void {
		this.prevSkin.next(data);
	}
	updateEnableDarkMode(data): void {
		this.enableDarkMode.next(data);
	}
	updateLayoutStyle(data) : void {
		localStorage.setItem("previousLayoutConfig", JSON.stringify(data
		));
		this.layoutStyle.next(data);
	}
}
