import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
 name: 'truncate'
})

export class TruncatePipeComponent implements PipeTransform {

transform(value: string, args: any[]): string {
    const limit = 70;
    const trail =  '...';
    return value.length > limit ? value.substring(0, limit) + trail : value;
   }
}