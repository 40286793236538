<div class="slideout-content default-stepping">
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
		<div class="modal-dialog">
			<!-- form-width -->
			<div class="modal-content pt-0">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">
						{{ "UI.k_" + event | translate }} {{ langMap[item] }}
					</h5>
					<button
						type="button"
						id="btnCloseKB"
						name="btnCloseKB"
						[attr.data-testid]="'btnCloseKB'"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						(click)="toggleSidebar('add-KB')"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body">
					<!-- Modern Horizontal Wizard -->
					<section class="modern-horizontal-wizard full-height">
						<div id="stepper3" class="bs-stepper wizard-modern modern-wizard-example">
							<div class="bs-stepper-header border-bottom" [ngClass]="{ 'd-none': isExternalCall === true }">
								<div id="divContentTab" name="divContentTab" class="step" data-target="#kb-content">
									<button type="button" class="step-trigger">
										<span class="bs-stepper-box align-items-center" *ngIf="!isExternalCall">
											<i class="fa-light fa-file-lines font-medium-3"></i>
										</span>
										<span class="bs-stepper-label" *ngIf="!isExternalCall">
											<span class="bs-stepper-title">{{ "UI.k_content" | translate }}</span>
											<span class="bs-stepper-subtitle">
												{{ "UI.k_setup_kb_content" | translate }}
											</span>
										</span>
									</button>
								</div>
								<div class="line" *ngIf="!isExternalCall">
									<i class="fa-light fa-chevron-right font-medium-2"></i>
								</div>
								<div id="divDetail" name="divDetail" class="step" data-target="#kb-details">
									<button type="button" class="step-trigger">
										<span class="bs-stepper-box align-items-center" *ngIf="!isExternalCall">
											<i class="fa-light fa-link font-medium-3"></i>
										</span>
										<span class="bs-stepper-label" *ngIf="!isExternalCall">
											<span class="bs-stepper-title">{{ "UI.k_details" | translate }}</span>
											<span class="bs-stepper-subtitle"
												>{{ "UI.k_setup_kb_details" | translate }}
											</span>
										</span>
									</button>
								</div>
								<div class="line" *ngIf="!isExternalCall">
									<i class="fa-light fa-chevron-right font-medium-2"></i>
								</div>
								<div
									id="divUserDetail"
									name="divUserDetail"
									class="step"
									data-target="#kb-user-details"
								>
									<button type="button" class="step-trigger">
										<span class="bs-stepper-box align-items-center" *ngIf="!isExternalCall">
											<i class="fa-light fa-user font-medium-3"></i>
										</span>
										<span class="bs-stepper-label" *ngIf="!isExternalCall">
											<span class="bs-stepper-title"
												>{{ "UI.k_user_access_info" | translate }}
											</span>
											<span class="bs-stepper-subtitle"
												>{{ "UI.k_add_user_access_info" | translate }}
											</span>
										</span>
									</button>
								</div>
							</div>
							<div class="bs-stepper-content">
								<!-- <form #kbAddForm="ngForm" class="px-2 form-validate">	 -->
								<div class="bs-stepper-inner">
									<div id="kb-content" class="content">
										<form #DnsKbContentForm="ngForm" class="stepper-inner">
											<div class="stepper-body">
												<ng-scrollbar class="custom-scrollbar section-scrollbar" track="all">
													<div class="stepper-main">
														<div class="row">
															<div class="col-md-4">
																<label class="font-small-2"
																	>{{ "UI.k_featured_image" | translate }}
																</label>
																<app-img-upload-feature
																	(imgUploaderDetails)="imageDetails($event)"
																	(Removeimg)="removeImage($event)"
																	[showQueue]="showQueue"
																	[multipleFiles]="multipleFiles"
																	[imgDetailsObject]="imgDetailsObject"
																	[displayResoluion]="displayResoluion"
																	[ALLOWED_TYPES]="ALLOWED_TYPES"
																	[MAX_IMAGE_SIZE]="MAX_IMAGE_SIZE"
																	[imgType]="imageType"
																	[is_cover]="true"
																	[isOnlyImage]="true"
																>
																</app-img-upload-feature>
															</div>
															<div class="col-md-8 col-xs-12 col-xl-8 col-lg-8 p-0">
																<div
																	class="form-group col-xs-12 col-md-12 col-xl-12 col-lg-12"
																>
																	<label for="txtKBTitle"
																		>{{ "UI.k_title" | translate }}
																		<span class="required-field">&nbsp;*</span>
																	</label>
																	<input
																		type="text"
																		id="txtKBTitle"
																		name="txtKBTitle"
																		class="form-control"
																		[class.error]="
																			kbTitle.invalid && DnsKbContentForm.submitted
																		"
																		[(ngModel)]="kb.title"
																		#kbTitle="ngModel"
																		required
																		minlength="3"
																		maxlength="100"
																		[appWhiteSpaceCheck]="kb.title"
																		placeholder="{{
																			'UI.k_enter_title' | translate
																		}}"
																	/>

																	<div
																		class="error"
																		*ngIf="
																			DnsKbContentForm.submitted && kbTitle.invalid
																		"
																	>
																		<small *ngIf="kbTitle.errors.required">
																			{{ "UI.k_field_required" | translate }}
																		</small>
																		<small *ngIf="kbTitle.errors.minlength">
																			{{ "Error.err_title_min" | translate }}
																		</small>
																		<small *ngIf="kbTitle.errors.maxlength">
																			{{ "Error.err_title_max" | translate }}
																		</small>
																		<small
																			class="form-text text-danger"
																			*ngIf="kbTitle.errors?.whitespace"
																			>{{ "Error.err_ltspace" | translate }}
																		</small>
																	</div>
																</div>
																<div
																	class="form-group col-xs-12 col-md-12 col-xl-12 col-lg-12"
																>
																	<label class="form-label" for="selKeywords">{{
																		"UI.k_keywords" | translate
																	}}</label>

																	<div class="form-group">
																		<!-- Select Custom Tag -->
																		<ng-select
																			[items]="customTag"
																			[addTag]="addKeyword"
																			[hideSelected]="true"
																			multiple="true"
																			bindLabel="name"
																			bindValue="name"
																			name="selKeywords"
																			id="selKeywords"
																			[(ngModel)]="kb.keywords"
																			placeholder="{{
																				'UI.k_add_keyword' | translate
																			}}"
																			notFoundText="{{
																				'UI.k_no_keywords' | translate
																			}}"
																			addTagText="{{
																				'UI.k_add_item' | translate
																			}}"
																		>
																		</ng-select>
																	</div>
																</div>
															</div>
														</div>
														<!--Article Code begins-->
														<div *ngIf="item == 'article'" id="divArticle">
															<div
																class="form-group col-xs-12 col-md-12 col-xl-12 col-lg-12 p-0 m-0 custom-quil-editer"
															>
																<label class="form-label" for="qlArticle"
																	>{{ "UI.k_content" | translate }}
																	<span class="required-field">&nbsp;*</span>
																</label>
																<!-- required #kbArticle="ngModel" class="form-control" -->
																<quill-editor
																	[(ngModel)]="kb.content"
																	id="qlArticle"
																	name="qlArticle"
																	[minLength]="3"
																	[required]="true"
																	#qlArticle
																	[trimOnValidation]="true"
																	[appQuillValidator]="qlArticle"
																	[appQuillForm]="DnsKbContentForm"
																	[class.error]="
																		qlArticle?.errors && DnsKbContentForm.submitted
																	"
																	[disabled]="false"
																	placeholder="{{ 'UI.k_add_content' | translate }}"
																>
																	<!-- <div quill-editor-toolbar>
																	<span class="ql-formats">
																		<button
																			class="ql-bold"
																			title="{{ 'UI.k_bold' | translate }}"
																		></button>
																		<button
																			class="ql-italic"
																			title="{{ 'UI.k_italic' | translate }}"
																		></button>
																		<button
																			class="ql-underline"
																			title="{{
																				'UI.k_underline' | translate
																			}}"
																		></button>
																	</span>
																	<span class="ql-formats">
																		<select
																			class="ql-align"
																			title="{{
																				'UI.k_alignment' | translate
																			}}"
																		>
																			<option selected></option>
																			<option value="center"></option>
																			<option value="right"></option>
																			<option value="justify"></option>
																		</select>
																	</span>
																	<span class="ql-formats">
																		<button
																			class="ql-list"
																			value="ordered"
																			type="button"
																		></button>
																	</span>
																	<span class="ql-formats">
																		<button
																			class="ql-link"
																			value="ordered"
																			type="button"
																		></button>
																		<button
																			class="ql-image"
																			value="ordered"
																			type="button"
																		></button>
																		<button
																			class="ql-video"
																			value="ordered"
																			type="button"
																		></button>
																	</span>
																	<span class="ql-formats">
																		<button
																			class="ql-formula"
																			value="ordered"
																			type="button"
																		></button>
																		<button
																			class="ql-code-block"
																			value="ordered"
																			type="button"
																		></button>
																	</span>
																	<span class="ql-formats">
																		<button
																			class="ql-clean"
																			value="ordered"
																			type="button"
																		></button>
																	</span>
																</div> -->
																</quill-editor>
																<div
																	class="error"
																	*ngIf="DnsKbContentForm.submitted && qlArticle?.errors"
																>
																	<small
																		*ngIf="qlArticle?.errors?.requiredError?.empty"
																	>
																		{{ "UI.k_field_required" | translate }}
																	</small>
																	<small *ngIf="qlArticle?.errors?.minLengthError">
																		{{ "Error.err_article_min" | translate }}
																	</small>
																	<small *ngIf="qlArticle?.errors?.maxLengthError">
																		{{ "Error.err_article_max" | translate }}
																	</small>
																</div>
															</div>
														</div>
														<!--Article Code ends-->
														<!--FAQ Code begins-->
														<div *ngIf="item == 'faq'" id="divFaq">
															<div
																*ngFor="
																	let faq_item of kb.faqs;
																	let i = index;
																	let last = last;
																	let first = first
																"
															>
																<div class="border mb-1 p-1">
																	<div class="row d-flex align-items-center">
																		<div
																			class="col-xs-11 col-md-11 col-xl-11 col-lg-11"
																		>
																			<label
																				class="form-label"
																				for="qlQuestion{{ i }}"
																			>
																				{{ "UI.k_question" | translate }}
																				<span class="required-field"
																					>&nbsp;*</span
																				>
																			</label>
																		</div>
																		<div
																			class="col-xs-1 col-md-1 col-xl-1 col-lg-1"
																			*ngIf="!first"
																		>
																			<span
																				class="btn text-danger"
																				(click)="deleteQuestion(i)"
																				rippleEffect
																			>
																				<i
																					class="fa-light fa-trash-can mr-25"
																				></i>
																			</span>
																		</div>
																	</div>
																	<div class="form-group">
																		<quill-editor
																			[(ngModel)]="faq_item.question"
																			id="qlQuestion{{ i }}"
																			name="qlQuestion{{ i }}"
																			#kbQstn
																			[minLength]="3"
																			[required]="true"
																			[trimOnValidation]="true"
																			[appQuillValidator]="kbQstn"
																			[appQuillForm]="DnsKbContentForm"
																			[class.error]="
																				kbQstn?.errors &&
																				DnsKbContentForm.submitted
																			"
																			placeholder="{{
																				'UI.k_add_question' | translate
																			}}"
																		>
																		</quill-editor>
																		<div
																			class="error"
																			*ngIf="
																				DnsKbContentForm.submitted && kbQstn.errors
																			"
																		>
																			<small
																				*ngIf="
																					kbQstn.errors.requiredError?.empty
																				"
																			>
																				{{ "UI.k_field_required" | translate }}
																			</small>
																			<small
																				*ngIf="kbQstn?.errors?.minLengthError"
																			>
																				{{ "Error.err_faq_q_min" | translate }}
																			</small>
																			<small
																				*ngIf="kbQstn?.errors?.maxLengthError"
																			>
																				{{ "Error.err_faq_q_max" | translate }}
																			</small>
																		</div>
																		<br />
																		<div class="form-group">
																			<label
																				class="form-label"
																				for="qlAnswer{{ i }}"
																				>{{ "UI.k_answer" | translate }}
																				<span class="required-field"
																					>&nbsp;*</span
																				>
																			</label>
																			<quill-editor
																				[(ngModel)]="faq_item.answer"
																				id="qlAnswer{{ i }}"
																				name="qlAnswer{{ i }}"
																				#kbAns
																				[minLength]="3"
																				[required]="true"
																				[trimOnValidation]="true"
																				[appQuillValidator]="kbAns"
																				[appQuillForm]="DnsKbContentForm"
																				[class.error]="
																					kbAns?.errors &&
																					DnsKbContentForm.submitted
																				"
																				placeholder="{{
																					'UI.k_add_answer' | translate
																				}}"
																			>
																			</quill-editor>
																			<div
																				class="error"
																				*ngIf="
																					DnsKbContentForm.submitted &&
																					kbAns?.errors
																				"
																			>
																				<small
																					*ngIf="
																						kbAns?.errors?.requiredError
																							?.empty
																					"
																				>
																					{{
																						"UI.k_field_required"
																							| translate
																					}}
																				</small>
																				<small
																					*ngIf="
																						kbAns?.errors?.minLengthError
																					"
																				>
																					{{
																						"Error.err_faq_a_min"
																							| translate
																					}}
																				</small>
																				<small
																					*ngIf="
																						kbAns?.errors?.maxLengthError
																					"
																				>
																					{{
																						"Error.err_faq_a_max"
																							| translate
																					}}
																				</small>
																			</div>
																		</div>
																	</div>
																</div>
																<div *ngIf="last">
																	<span
																		type="button"
																		(click)="addQuestion()"
																		id="spAddQstn"
																		name="spAddQstn"
																	>
																		<i
																			class="fa-light fa-plus text-primary mr-25"
																		></i>
																		<!-- <label>Add Another Question</label> -->
																		<label>
																			{{ "UI.k_add_other_question" | translate }}
																		</label>
																	</span>
																</div>
															</div>
														</div>
														<!--FAQ Code ends-->
														<!--Information Code begins-->
														<div *ngIf="item == 'information'" id="divInfo">
															<div
																class="form-group col-xs-12 col-md-12 col-xl-12 col-lg-12 p-0 m-0"
															>
																<label class="form-label" for="qlInfo"
																	>{{ "UI.k_description" | translate }}
																	<span class="required-field">&nbsp;*</span>
																</label>
																<quill-editor
																	[(ngModel)]="kb.content"
																	id="qlInfo"
																	name="qlInfo"
																	#kbDesc
																	[minLength]="3"
																	[required]="true"
																	[trimOnValidation]="true"
																	[appQuillValidator]="kbDesc"
																	[appQuillForm]="DnsKbContentForm"
																	[class.error]="
																		kbDesc?.errors && DnsKbContentForm.submitted
																	"
																	placeholder="{{ 'UI.k_add_content' | translate }}"
																>
																	<!-- <div quill-editor-toolbar>
																		<span class="ql-formats">
																			<button
																				class="ql-bold"
																				[title]="'Bold'"
																			></button>
																			<button
																				class="ql-italic"
																				[title]="'italic'"
																			></button>
																			<button
																				class="ql-underline"
																				[title]="'underline'"
																			></button>
																		</span>
																		<span class="ql-formats">
																			<select
																				class="ql-align"
																				[title]="'Alignment'"
																			>
																				<option selected></option>
																				<option value="center"></option>
																				<option value="right"></option>
																				<option value="justify"></option>
																			</select>
																		</span>
																		<span class="ql-formats">
																			<button
																				class="ql-list"
																				value="ordered"
																				type="button"
																			></button>
																		</span>
																		<span class="ql-formats">
																			<button
																				class="ql-link"
																				value="ordered"
																				type="button"
																			></button>
																			<button
																				class="ql-image"
																				value="ordered"
																				type="button"
																			></button>
																			<button
																				class="ql-video"
																				value="ordered"
																				type="button"
																			></button>
																		</span>
																		<span class="ql-formats">
																			<button
																				class="ql-formula"
																				value="ordered"
																				type="button"
																			></button>
																			<button
																				class="ql-code-block"
																				value="ordered"
																				type="button"
																			></button>
																		</span>
																		<span class="ql-formats">
																			<button
																				class="ql-clean"
																				value="ordered"
																				type="button"
																			></button>
																		</span>
																	</div> -->
																</quill-editor>
																<div
																	class="error"
																	*ngIf="DnsKbContentForm.submitted && kbDesc?.errors"
																>
																	<small *ngIf="kbDesc?.errors?.requiredError?.empty">
																		{{ "UI.k_field_required" | translate }}
																	</small>
																	<small *ngIf="kbDesc?.errors?.minLengthError">
																		{{ "Error.err_info_min" | translate }}
																	</small>
																	<small *ngIf="kbDesc?.errors?.maxLengthError">
																		{{ "Error.err_info_max" | translate }}
																	</small>
																</div>
															</div>
														</div>
														<!--Information Code ends-->
														<!--Known Error Code begins-->
														<div *ngIf="item == 'known_error'" id="divError">
															<div
																class="form-group col-xs-12 col-md-12 col-xl-12 col-lg-12 p-0 m-0"
															>
																<label class="form-label" for="qlError"
																	>{{ "UI.k_error_detail" | translate }}
																	<span class="required-field">&nbsp;*</span>
																</label>
																<quill-editor
																	[(ngModel)]="kb.known_error.error_detail"
																	name="qlError"
																	id="qlError"
																	#kbErrDet
																	[minLength]="3"
																	[required]="true"
																	[trimOnValidation]="true"
																	[appQuillValidator]="kbErrDet"
																	[appQuillForm]="DnsKbContentForm"
																	[class.error]="
																		kbErrDet?.errors && DnsKbContentForm.submitted
																	"
																	placeholder="{{ 'UI.k_add_errordesc' | translate }}"
																>
																	<!-- <div quill-editor-toolbar>
																		<span class="ql-formats">
																			<button class="ql-bold" [title]="'Bold'"></button>
																			<button
																				class="ql-italic"
																				[title]="'italic'"
																			></button>
																			<button
																				class="ql-underline"
																				[title]="'underline'"
																			></button>
																		</span>
																		<span class="ql-formats">
																			<select class="ql-align" [title]="'Alignment'">
																				<option selected></option>
																				<option value="center"></option>
																				<option value="right"></option>
																				<option value="justify"></option>
																			</select>
																		</span>
																		<span class="ql-formats">
																			<button
																				class="ql-list"
																				value="ordered"
																				type="button"
																			></button>
																		</span>
																		<span class="ql-formats">
																			<button
																				class="ql-link"
																				value="ordered"
																				type="button"
																			></button>
																			<button
																				class="ql-image"
																				value="ordered"
																				type="button"
																			></button>
																			<button
																				class="ql-video"
																				value="ordered"
																				type="button"
																			></button>
																		</span>
																		<span class="ql-formats">
																			<button
																				class="ql-formula"
																				value="ordered"
																				type="button"
																			></button>
																			<button
																				class="ql-code-block"
																				value="ordered"
																				type="button"
																			></button>
																		</span>
																		<span class="ql-formats">
																			<button
																				class="ql-clean"
																				value="ordered"
																				type="button"
																			></button>
																		</span>
																	</div> -->
																</quill-editor>
																<div
																	class="error"
																	*ngIf="DnsKbContentForm.submitted && kbErrDet?.errors"
																>
																	<small
																		*ngIf="kbErrDet?.errors?.requiredError?.empty"
																	>
																		{{ "UI.k_field_required" | translate }}
																	</small>
																	<small *ngIf="kbErrDet?.errors?.minLengthError">
																		{{ "Error.err_edet_min" | translate }}
																	</small>
																	<small *ngIf="kbErrDet?.errors?.maxLengthError">
																		{{ "Error.err_edet_max" | translate }}
																	</small>
																</div>
															</div>
															<br />
															<div
																class="form-group col-xs-12 col-md-12 col-xl-12 col-lg-12 p-0 m-0"
															>
																<label class="form-label" for="qlErrorRootCause"
																	>{{ "UI.k_root_cause" | translate }}
																	<span class="required-field">&nbsp;*</span>
																</label>
																<quill-editor
																	[(ngModel)]="kb.known_error.root_cause"
																	id="qlErrorRootCause"
																	name="qlErrorRootCause"
																	#kbErrRC
																	[minLength]="3"
																	[required]="true"
																	[trimOnValidation]="true"
																	[appQuillValidator]="kbErrRC"
																	[appQuillForm]="DnsKbContentForm"
																	[class.error]="
																		kbErrRC?.errors && DnsKbContentForm.submitted
																	"
																	placeholder="{{ 'UI.k_add_rc' | translate }}"
																>
																	<!-- <div quill-editor-toolbar>
																		<span class="ql-formats">
																			<button class="ql-bold" [title]="'Bold'"></button>
																			<button
																				class="ql-italic"
																				[title]="'italic'"
																			></button>
																			<button
																				class="ql-underline"
																				[title]="'underline'"
																			></button>
																		</span>
																		<span class="ql-formats">
																			<select class="ql-align" [title]="'Alignment'">
																				<option selected></option>
																				<option value="center"></option>
																				<option value="right"></option>
																				<option value="justify"></option>
																			</select>
																		</span>
																		<span class="ql-formats">
																			<button
																				class="ql-list"
																				value="ordered"
																				type="button"
																			></button>
																		</span>
																		<span class="ql-formats">
																			<button
																				class="ql-link"
																				value="ordered"
																				type="button"
																			></button>
																			<button
																				class="ql-image"
																				value="ordered"
																				type="button"
																			></button>
																			<button
																				class="ql-video"
																				value="ordered"
																				type="button"
																			></button>
																		</span>
																		<span class="ql-formats">
																			<button
																				class="ql-formula"
																				value="ordered"
																				type="button"
																			></button>
																			<button
																				class="ql-code-block"
																				value="ordered"
																				type="button"
																			></button>
																		</span>
																		<span class="ql-formats">
																			<button
																				class="ql-clean"
																				value="ordered"
																				type="button"
																			></button>
																		</span>
																	</div> -->
																</quill-editor>
																<div
																	class="error"
																	*ngIf="DnsKbContentForm.submitted && kbErrRC?.errors"
																>
																	<small
																		*ngIf="kbErrRC?.errors?.requiredError?.empty"
																	>
																		{{ "UI.k_field_required" | translate }}
																	</small>
																	<small *ngIf="kbErrRC?.errors?.minLengthError">
																		{{ "Error.err_rootcause_min" | translate }}
																	</small>
																	<small *ngIf="kbErrRC?.errors?.maxLengthError">
																		{{ "Error.err_rootcause_max" | translate }}
																	</small>
																</div>
															</div>
															<br />
															<div
																class="form-group col-xs-12 col-md-12 col-xl-12 col-lg-12 p-0 m-0"
															>
																<label class="form-label" for="qlWorkAround"
																	>{{ "UI.k_workaround" | translate }}
																	<span class="required-field">&nbsp;*</span>
																</label>
																<quill-editor
																	[(ngModel)]="kb.known_error.workaround"
																	id="qlWorkAround"
																	name="qlWorkAround"
																	#kbWrkArnd
																	[minLength]="3"
																	[required]="true"
																	[trimOnValidation]="true"
																	[appQuillValidator]="kbWrkArnd"
																	[appQuillForm]="DnsKbContentForm"
																	[class.error]="
																		kbWrkArnd?.errors && DnsKbContentForm.submitted
																	"
																	placeholder="{{
																		'UI.k_add_workaround' | translate
																	}}"
																>
																	<!-- <div quill-editor-toolbar>
																		<span class="ql-formats">
																			<button class="ql-bold" [title]="'Bold'"></button>
																			<button
																				class="ql-italic"
																				[title]="'italic'"
																			></button>
																			<button
																				class="ql-underline"
																				[title]="'underline'"
																			></button>
																		</span>
																		<span class="ql-formats">
																			<select class="ql-align" [title]="'Alignment'">
																				<option selected></option>
																				<option value="center"></option>
																				<option value="right"></option>
																				<option value="justify"></option>
																			</select>
																		</span>
																		<span class="ql-formats">
																			<button
																				class="ql-list"
																				value="ordered"
																				type="button"
																			></button>
																		</span>
																		<span class="ql-formats">
																			<button
																				class="ql-link"
																				value="ordered"
																				type="button"
																			></button>
																			<button
																				class="ql-image"
																				value="ordered"
																				type="button"
																			></button>
																			<button
																				class="ql-video"
																				value="ordered"
																				type="button"
																			></button>
																		</span>
																		<span class="ql-formats">
																			<button
																				class="ql-formula"
																				value="ordered"
																				type="button"
																			></button>
																			<button
																				class="ql-code-block"
																				value="ordered"
																				type="button"
																			></button>
																		</span>
																		<span class="ql-formats">
																			<button
																				class="ql-clean"
																				value="ordered"
																				type="button"
																			></button>
																		</span>
																	</div> -->
																</quill-editor>
																<div
																	class="error"
																	*ngIf="DnsKbContentForm.submitted && kbWrkArnd?.errors"
																>
																	<small
																		*ngIf="kbWrkArnd?.errors?.requiredError?.empty"
																	>
																		{{ "UI.k_field_required" | translate }}
																	</small>
																	<small *ngIf="kbWrkArnd?.errors?.minLengthError">
																		{{ "Error.err_wrkarnd_min" | translate }}
																	</small>
																	<small *ngIf="kbWrkArnd?.errors?.maxLengthError">
																		{{ "Error.err_wrkarnd_max" | translate }}
																	</small>
																</div>
															</div>
															<br />
														</div>
														<!--Known Error Code ends-->
														<!--Solution Code begins-->
														<div *ngIf="item == 'solution'" id="divSolution">
															<div
																class="form-group col-xs-12 col-md-12 col-xl-12 col-lg-12 p-0 m-0"
															>
																<label class="form-label" for="qlProbDetail"
																	>{{ "UI.k_problem_detail" | translate }}
																	<span class="required-field">&nbsp;*</span>
																</label>
																<quill-editor
																	[(ngModel)]="kb.content"
																	id="qlProbDetail"
																	name="qlProbDetail"
																	#kbProbDet
																	[minLength]="3"
																	[required]="true"
																	[trimOnValidation]="true"
																	[appQuillValidator]="kbProbDet"
																	[appQuillForm]="DnsKbContentForm"
																	[class.error]="
																		kbProbDet?.errors && DnsKbContentForm.submitted
																	"
																	placeholder="{{
																		'UI.k_add_problemdesc' | translate
																	}}"
																>
																	<!-- <div quill-editor-toolbar>
																		<span class="ql-formats">
																			<button class="ql-bold" [title]="'Bold'"></button>
																			<button
																				class="ql-italic"
																				[title]="'italic'"
																			></button>
																			<button
																				class="ql-underline"
																				[title]="'underline'"
																			></button>
																		</span>
																		<span class="ql-formats">
																			<select class="ql-align" [title]="'Alignment'">
																				<option selected></option>
																				<option value="center"></option>
																				<option value="right"></option>
																				<option value="justify"></option>
																			</select>
																		</span>
																		<span class="ql-formats">
																			<button
																				class="ql-list"
																				value="ordered"
																				type="button"
																			></button>
																		</span>
																		<span class="ql-formats">
																			<button
																				class="ql-link"
																				value="ordered"
																				type="button"
																			></button>
																			<button
																				class="ql-image"
																				value="ordered"
																				type="button"
																			></button>
																			<button
																				class="ql-video"
																				value="ordered"
																				type="button"
																			></button>
																		</span>
																		<span class="ql-formats">
																			<button
																				class="ql-formula"
																				value="ordered"
																				type="button"
																			></button>
																			<button
																				class="ql-code-block"
																				value="ordered"
																				type="button"
																			></button>
																		</span>
																		<span class="ql-formats">
																			<button
																				class="ql-clean"
																				value="ordered"
																				type="button"
																			></button>
																		</span>
																	</div> -->
																</quill-editor>
																<div
																	class="error"
																	*ngIf="DnsKbContentForm.submitted && kbProbDet?.errors"
																>
																	<small
																		*ngIf="kbProbDet?.errors?.requiredError?.empty"
																	>
																		{{ "UI.k_field_required" | translate }}
																	</small>
																	<small *ngIf="kbProbDet?.errors?.minLengthError">
																		{{ "Error.err_sol_det_min" | translate }}
																	</small>
																	<small *ngIf="kbProbDet?.errors?.maxLengthError">
																		{{ "Error.err_sol_det_max" | translate }}
																	</small>
																</div>
															</div>
															<br />
															<div
																*ngFor="
																	let sol_item of kb.solutions;
																	let i = index;
																	let first = first;
																	let last = last
																"
															>
																<div class="border mb-1 p-1">
																	<div class="row d-flex align-items-center">
																		<div
																			class="col-xs-11 col-md-11 col-xl-11 col-lg-11"
																		>
																			<label
																				class="form-label"
																				for="qlRootCause{{ i }}"
																				>{{ "UI.k_root_cause" | translate }}
																				<span class="required-field"
																					>&nbsp;*</span
																				>
																			</label>
																		</div>
																		<div
																			class="col-xs-1 col-md-1 col-xl-1 col-lg-1"
																			*ngIf="!first"
																		>
																			<span
																				class="btn text-danger"
																				(click)="deleteSolution(i)"
																				rippleEffect
																			>
																				<i
																					class="fa-light fa-trash-can mr-25"
																				></i>
																			</span>
																		</div>
																	</div>
																	<div class="form-group">
																		<quill-editor
																			[(ngModel)]="sol_item.root_cause"
																			id="qlRootCause{{ i }}"
																			name="qlRootCause{{ i }}"
																			#kbSolRC
																			[minLength]="3"
																			[required]="true"
																			[trimOnValidation]="true"
																			[appQuillValidator]="kbSolRC"
																			[appQuillForm]="DnsKbContentForm"
																			[class.error]="
																				kbSolRC?.errors &&
																				DnsKbContentForm.submitted
																			"
																			placeholder="{{
																				'UI.k_add_rc' | translate
																			}}"
																		>
																			<!-- <div quill-editor-toolbar>
																				<span class="ql-formats">
																					<button
																						class="ql-bold"
																						[title]="'Bold'"
																					></button>
																					<button
																						class="ql-italic"
																						[title]="'italic'"
																					></button>
																					<button
																						class="ql-underline"
																						[title]="'underline'"
																					></button>
																				</span>
																				<span class="ql-formats">
																					<select class="ql-align" [title]="'Alignment'">
																						<option selected></option>
																						<option value="center"></option>
																						<option value="right"></option>
																						<option value="justify"></option>
																					</select>
																				</span>
																				<span class="ql-formats">
																					<button
																						class="ql-list"
																						value="ordered"
																						type="button"
																					></button>
																				</span>
																				<span class="ql-formats">
																					<button
																						class="ql-link"
																						value="ordered"
																						type="button"
																					></button>
																					<button
																						class="ql-image"
																						value="ordered"
																						type="button"
																					></button>
																					<button
																						class="ql-video"
																						value="ordered"
																						type="button"
																					></button>
																				</span>
																				<span class="ql-formats">
																					<button
																						class="ql-formula"
																						value="ordered"
																						type="button"
																					></button>
																					<button
																						class="ql-code-block"
																						value="ordered"
																						type="button"
																					></button>
																				</span>
																				<span class="ql-formats">
																					<button
																						class="ql-clean"
																						value="ordered"
																						type="button"
																					></button>
																				</span>
																			</div> -->
																		</quill-editor>
																		<div
																			class="error"
																			*ngIf="
																				DnsKbContentForm.submitted &&
																				kbSolRC?.errors
																			"
																		>
																			<small
																				*ngIf="
																					kbSolRC?.errors?.requiredError
																						?.empty
																				"
																			>
																				{{ "UI.k_field_required" | translate }}
																			</small>
																			<small
																				*ngIf="kbSolRC?.errors?.minLengthError"
																			>
																				{{
																					"Error.err_rootcause_sol_min"
																						| translate
																				}}
																			</small>
																			<small
																				*ngIf="kbSolRC?.errors?.maxLengthError"
																			>
																				{{
																					"Error.err_rootcause_sol_max"
																						| translate
																				}}
																			</small>
																		</div>
																	</div>
																	<div class="form-group">
																		<label id="lblSol" for="qlSolution{{ i }}"
																			>{{ "UI.k_solution" | translate }}
																			<span class="required-field">&nbsp;*</span>
																		</label>
																		<quill-editor
																			[(ngModel)]="sol_item.solution"
																			id="qlSolution{{ i }}"
																			name="qlSolution{{ i }}"
																			#kbSol
																			[minLength]="3"
																			[required]="true"
																			[trimOnValidation]="true"
																			[appQuillValidator]="kbSol"
																			[appQuillForm]="DnsKbContentForm"
																			[class.error]="
																				kbSol?.errors && DnsKbContentForm.submitted
																			"
																			placeholder="{{
																				'UI.k_add_solution' | translate
																			}}"
																		>
																			<!-- <div quill-editor-toolbar>
																				<span class="ql-formats">
																					<button
																						class="ql-bold"
																						[title]="'Bold'"
																					></button>
																					<button
																						class="ql-italic"
																						[title]="'italic'"
																					></button>
																					<button
																						class="ql-underline"
																						[title]="'underline'"
																					></button>
																				</span>
																				<span class="ql-formats">
																					<select class="ql-align" [title]="'Alignment'">
																						<option selected></option>
																						<option value="center"></option>
																						<option value="right"></option>
																						<option value="justify"></option>
																					</select>
																				</span>
																				<span class="ql-formats">
																					<button
																						class="ql-list"
																						value="ordered"
																						type="button"
																					></button>
																				</span>
																				<span class="ql-formats">
																					<button
																						class="ql-link"
																						value="ordered"
																						type="button"
																					></button>
																					<button
																						class="ql-image"
																						value="ordered"
																						type="button"
																					></button>
																					<button
																						class="ql-video"
																						value="ordered"
																						type="button"
																					></button>
																				</span>
																				<span class="ql-formats">
																					<button
																						class="ql-formula"
																						value="ordered"
																						type="button"
																					></button>
																					<button
																						class="ql-code-block"
																						value="ordered"
																						type="button"
																					></button>
																				</span>
																				<span class="ql-formats">
																					<button
																						class="ql-clean"
																						value="ordered"
																						type="button"
																					></button>
																				</span>
																			</div> -->
																		</quill-editor>
																		<div
																			class="error"
																			*ngIf="
																				DnsKbContentForm.submitted && kbSol?.errors
																			"
																		>
																			<small
																				*ngIf="
																					kbSol?.errors?.requiredError?.empty
																				"
																			>
																				{{ "UI.k_field_required" | translate }}
																			</small>
																			<small
																				*ngIf="kbSol?.errors?.minLengthError"
																			>
																				{{ "Error.err_sol_min" | translate }}
																			</small>
																			<small
																				*ngIf="kbSol?.errors?.maxLengthError"
																			>
																				{{ "Error.err_sol_max" | translate }}
																			</small>
																		</div>
																	</div>
																</div>
																<div *ngIf="last">
																	<span
																		type="button"
																		id="spAddSol"
																		name="spAddSol"
																		(click)="addSolution()"
																	>
																		<i
																			class="fa-light fa-plus text-primary mr-25"
																		></i>
																		<label id="lblSolutions" for="lblSolutions"
																			>{{ "UI.k_add_other_solution" | translate }}
																		</label>
																	</span>
																</div>
															</div>
														</div>
														<!--Solution Code ends-->
													</div>
												</ng-scrollbar>
											</div>
											<div
												class="stepper-btn d-flex border-top justify-content-between py-1 px-2"
											>
												<div class="mr-auto">
													<button
														*ngIf="!isExternalCall"
														(click)="modernHorizontalPrevious()"
														class="btn btn-outline-secondary btn-prev"
														disabled
														rippleEffect
														id="btnCntntPrev"
														name="btnCntntPrev"
														[attr.data-testid]="'btnCntntPrev'"
													>
														<i class="fa-light fa-arrow-left align-middle mr-sm-25"></i>
														<span class="align-middle d-sm-inline-block d-none">{{
															"UI.k_back" | translate
														}}</span>
													</button>
												</div>
												<div>
													<button
														*ngIf="isExternalCall"
														type="submit"
														id="btnKbSubmit"
														name="kbSubmit"
														[attr.data-testid]="'btnKbSubmit'"
														rippleEffect
														class="btn btn-primary pull-right"
														(click)="onSave(DnsKbContentForm)"
														[disabled]="is_save_disabled"
													>
													<i
													class="fa-light fa-floppy-disk align-middle mr-50 ml-0"
													></i>
														{{ "UI.k_submit" | translate }}
													</button>
													<button
														*ngIf="!isExternalCall"
														(click)="modernHorizontalNext(DnsKbContentForm)"
														type="submit"
														class="btn btn-primary btn-next"
														rippleEffect
														id="btnCntntNxt"
														name="btnCntntNxt"
														[attr.data-testid]="'btnCntntNxt'"
													>
														<span class="align-middle d-sm-inline-block d-none">{{
															"UI.k_next" | translate
														}}</span>
														<i class="fa-light fa-arrow-right align-middle ml-sm-25"></i>
													</button>
												</div>
											</div>
										</form>
									</div>
									<div id="kb-details" class="content">
										<form #kbDetailsForm="ngForm" class="stepper-inner">
											<div class="stepper-body">
												<ng-scrollbar class="custom-scrollbar section-scrollbar">
													<div class="stepper-main">
														<div class="row">
															<!-- <div class="form-group col-xs-6 col-md-6 col-xl-6 col-lg-6">
																<label class="form-label" for="selState">{{
																	"UI.k_state" | translate
																}}</label>
																<ng-select
																	[items]="options?.states"
																	[(ngModel)]="kb.state"
																	bindLabel="name"
																	bindValue="id"
																	id="selState"
																	name="selState"
																	placeholder="{{ 'UI.k_sel_state' | translate }}"
																	notFoundText="{{ 'UI.k_no_state' | translate }}"
																>
																</ng-select>
															</div> -->

															<!-- <div class="form-group col-xs-6 col-md-6 col-xl-6 col-lg-6">
																<label class="form-label" for="selStatus">{{
																	"UI.k_status" | translate
																}}</label>
																<ng-select
																	[items]="options?.status"
																	[(ngModel)]="kb.status"
																	bindLabel="name"
																	bindValue="id"
																	id="selStatus"
																	name="selStatus"
																	placeholder="{{ 'UI.k_sel_status' | translate }}"
																	notFoundText="{{ 'UI.k_no_status' | translate }}"
																>
																</ng-select>
															</div> -->
															<!-- <div class="selct-feature group-list-feature status-list-picker">
                                                                <div class="select-sec">
                                                                    <div class="form-group col-xs-6 col-md-6 col-xl-6 col-lg-6">
                                                                        <label class="form-label" for="selStatus">{{
                                                                            "UI.k_status" | translate
                                                                        }}</label> -->

															<!-- [inputConfig]="{ isTextMode: true ,header: 'Status Feature'}" -->

															<div class="col-xs-6 col-md-6 col-xl-6 col-lg-6">
																<app-status-picker
																	[inputConfig]="{ isTextMode: true }"
																	[options]="statusList"
																	(setStatus)="onStatusChange($event)"
																	[value]="options?.default_status"
																></app-status-picker>
															</div>
															<div
																class="form-group col-xs-6 col-md-6 col-xl-6 col-lg-6 mb-0"
															>
																<label
																	id="lblValiddate"
																	name="lblValiddate"
																	for="kbDatepick"
																	>{{ "UI.k_valid_till" | translate }}</label
																>

																<!-- <form class="form-inline"> -->
																<div class="form-group mb-0">
																	<div class="input-group">
																		<input
																			class="form-control pr-5"
																			placeholder="{{
																				'UI.k_placeholder_yyyy_mm_dd'
																					| translate
																			}}"
																			id="kbDatepick"
																			name="kbDatepick"
																			[(ngModel)]="kb.valid_till"
																			ngbDatepicker
																			#basicDP="ngbDatepicker"
																			#TDDatePicker="ngModel"
																			[minDate]="options?.validity_min_date"
																			[maxDate]="options?.validity_max_date"
																			[class.error]="
																				TDDatePicker.invalid &&
																				kbDetailsForm.submitted
																			"
																		/>

																		<div class="input-group-append">
																			<button
																				class="btn btn-outline-secondary feather icon-calendar"
																				(click)="basicDP.toggle()"
																				type="button"
																				rippleEffect
																			></button>
																		</div>
																	</div>
																</div>
																<!-- </form> -->
																<div
																	class="error mb-50"
																	*ngIf="
																		kbDetailsForm.submitted &&
																		TDDatePicker.invalid &&
																		TDDatePicker.invalid
																	"
																>
																	<small
																		class="form-text text-danger"
																		*ngIf="TDDatePicker.errors.ngbDate?.invalid"
																	>
																		{{ "UI.k_valid_till" | translate }}
																		{{ "Message.msg_valid_date" | translate }}
																		(Ex: yyyy-mm-dd).
																	</small>
																	<small
																		class="form-text text-danger"
																		*ngIf="
																			TDDatePicker.dirty &&
																			TDDatePicker.errors?.ngbDate?.minDate
																		"
																	>
																		{{
																			"Message.msg_valid_future_date" | translate
																		}}
																	</small>
																	<small
																		class="form-text text-danger"
																		*ngIf="
																			TDDatePicker.dirty &&
																			TDDatePicker.errors?.ngbDate?.maxDate
																		"
																	>
																		{{ "Message.msg_max_date" | translate }}
																	</small>
																</div>
															</div>
															<!-- </div>
                                                                </div>
                                                            </div> -->
														</div>
														<div class="row">
															<div class="form-group col-xs-6 col-md-6 col-xl-6 col-lg-6">
																<label class="form-label" for="selDepartment">{{
																	"UI.k_department" | translate
																}}</label>
																<ng-select
																	[items]="options?.departments"
																	[(ngModel)]="kb.department"
																	bindLabel="name"
																	bindValue="dept_id"
																	id="selDepartment"
																	name="selDepartment"
																	placeholder="{{
																		'UI.k_sel_department' | translate
																	}}"
																	notFoundText="{{
																		'UI.k_no_department' | translate
																	}}"
																>
																</ng-select>
															</div>
															<div
																*ngIf="!isFromServiceCatalogue"
																class="form-group col-xs-6 col-md-6 col-xl-6 col-lg-6"
															>
																<label class="form-label" for="cataloguetree">{{
																	"UI.k_servicecatalogue" | translate
																}}</label>
																<ng-select
																	[items]="options?.all_services"
																	[(ngModel)]="kb.catalogue_service"
																	name="catalogueServices"
																	id="catalogueServices"
																	bindLabel="name"
																	bindValue="service_id"
																	placeholder="{{ 'UI.k_sel_service' | translate }}"
																	notFoundText="{{
																		'UI.k_no_service_catalogue' | translate
																	}}"
																>
																	<!-- <ng-template ng-option-tmp ng-label-tmp
																				let-item="item">
																				<i [data-feather]="item.icon" [size]="16"></i>
																				{{ item.name }}
																			</ng-template> -->
																</ng-select>
															</div>
														</div>
														<!-- <div class="row">
															<div class="form-group col-xs-6 col-md-6 col-xl-6 col-lg-6">
																<label class="form-label" for="selApprovers">{{
																	"UI.k_approver" | translate
																}}</label>
																<ng-select
																	[items]="options?.approvers"
																	#TDApproverRef="ngModel"
																	[(ngModel)]="kb.approver"
																	bindLabel="full_name"
																	bindValue="profile_id"
																	name="selApprovers"
																	[searchFn]="customSearchFn"
																	id="selApprovers"
																	placeholder="{{ 'UI.k_sel_approver' | translate }}"
																	[closeOnSelect]="true"
																	[class.error]="
																		!TDApproverRef.valid && kbDetailsForm.submitted
																	"
																	notFoundText="{{ 'UI.k_no_approvers' | translate }}"
																>
																	<ng-template
																		ng-option-tmp
																		let-item="item"
																		let-index="index"
																		let-search="searchTerm"
																	>
																		<span class="d-flex">
																			<div
																				class="avatar mr-1 ml-0"
																				[ngClass]="bg - light - primary"
																				style="
																					align-content: center;
																					width: 32px;
																					height: 32px;
																				"
																			>
																				<div
																					*ngIf="
																						item?.profile_image;
																						else customAvatar
																					"
																				>
																					<img
																						class="rounded-circle mr-1"
																						src="{{ item.profile_image }}"
																						height="32"
																						width="32"
																						alt="datatable-avatar"
																					/>
																				</div>
																				<ng-template #customAvatar>
																					<div
																						class="avatar mr-1 ml-0"
																						[ngStyle]="{
																							'background-color':
																								item.avatar_color
																									? item.avatar_color
																									: '#f59518'
																						}"
																					>
																						<div class="avatar-content">
																							{{
																								item.full_name
																									| initials
																									| slice: O:2
																							}}
																						</div>
																					</div>
																				</ng-template>
																			</div>
																			<div class="cell-line-height">
																				<p
																					class="font-medium-1 font-weight-bold line-height-1 mb-25"
																				>
																					{{ item.full_name }}
																				</p>
																				<span class="text-muted font-small-2">
																					{{ item.email }}</span
																				>
																			</div>
																		</span>
																	</ng-template>
																</ng-select>
															</div>

															<div class="form-group col-xs-6 col-md-6 col-xl-6 col-lg-6">
																<label class="form-label" for="selReviewer">{{
																	"UI.k_reviewer" | translate
																}}</label>
																<ng-select
																	[items]="options?.reviewers"
																	#TDReviewerRef="ngModel"
																	[(ngModel)]="kb.reviewer"
																	bindLabel="full_name"
																	[searchFn]="customSearchFn"
																	bindValue="profile_id"
																	name="selReviewer"
																	id="selReviewer"
																	placeholder="{{ 'UI.k_sel_reviewer' | translate }}"
																	[closeOnSelect]="true"
																	[class.error]="
																		!TDReviewerRef.valid && kbDetailsForm.submitted
																	"
																	notFoundText="{{ 'UI.k_no_reviewers' | translate }}"
																>
																	<ng-template
																		ng-option-tmp
																		let-item="item"
																		let-index="index"
																		let-search="searchTerm"
																	>
																		<span class="d-flex">
																			<div
																				class="avatar mr-1 ml-0"
																				[ngClass]="bg - light - primary"
																				style="
																					align-content: center;
																					width: 32px;
																					height: 32px;
																				"
																			>
																				<div
																					*ngIf="
																						item?.profile_image;
																						else customAvatar
																					"
																				>
																					<img
																						class="rounded-circle mr-1"
																						src="{{ item.profile_image }}"
																						height="32"
																						width="32"
																						alt="datatable-avatar"
																					/>
																				</div>
																				<ng-template #customAvatar>
																					<div
																						class="avatar mr-1 ml-0"
																						[ngStyle]="{
																							'background-color':
																								item.avatar_color
																									? item.avatar_color
																									: '#f59518'
																						}"
																					>
																						<div class="avatar-content">
																							{{
																								item.full_name
																									| initials
																									| slice: O:2
																							}}
																						</div>
																					</div>
																				</ng-template>
																			</div>
																			<div class="cell-line-height">
																				<p
																					class="font-medium-1 font-weight-bold line-height-1 mb-25"
																				>
																					{{ item.full_name }}
																				</p>
																				<span class="text-muted font-small-2">
																					{{ item.email }}</span
																				>
																			</div>
																		</span>
																	</ng-template>
																</ng-select>
															</div>
														</div> -->
														<div class="row">
															<!-- <div class="form-group col-xs-2 col-md-2 col-xl-2 col-lg-2">
																<div
																	class="custom-control custom-control-primary custom-switch"
																>
																	<p class="mb-50">
																		{{ "UI.k_security_article" | translate }}
																	</p>
																	<input
																		type="checkbox"
																		[checked]="kb.is_security"
																		class="custom-control-input"
																		id="switchArticle"
																		name="switchArticle"
																		[(ngModel)]="kb.is_security"
																	/>
																	<label
																		class="custom-control-label"
																		for="switchArticle"
																	></label>
																</div>
															</div> -->

															<!-- <div class="form-group col-xs-2 col-md-2 col-xl-2 col-lg-2">
																<div
																	class="custom-control custom-control-primary custom-switch"
																>
																	<p class="mb-50">
																		{{ "UI.k_disabled_update" | translate }}
																	</p>
																	<input
																		type="checkbox"
																		[checked]="kb.is_disabled_update"
																		class="custom-control-input"
																		id="switchDisable"
																		name="switchDisable"
																		[(ngModel)]="kb.is_disabled_update"
																	/>
																	<label
																		class="custom-control-label"
																		for="switchDisable"
																	></label>
																</div>
															</div> -->
														</div>

														<h5 class="mb-1 mt-2">
															{{ "UI.k_references" | translate }}
														</h5>

														<div
															*ngFor="
																let item of kb.api_link_data;
																let i = index;
																let first = first;
																let last = last
															"
															class="width-80-per"
														>
															<div class="row">
																<div
																	class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6"
																>
																	<label id="lblURL" for="apiurl{{ i }}">{{
																		"UI.k_url" | translate
																	}}</label>
																	<!-- pattern="^(http(s)?:\/\/)?(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$" -->
																	<input
																		type="text"
																		id="apiurl{{ i }}"
																		name="apiurl{{ i }}"
																		class="form-control"
																		[class.error]="
																			TDUrlRef.invalid && kbDetailsForm.submitted
																		"
																		[urlValidator]="item.external_url"
																		ngModel
																		#TDUrlRef="ngModel"
																		placeholder="https://www.everestims.com/"
																		[(ngModel)]="item.external_url"
																		[required]="item.name !== ''"
																	/>
																	<!-- [appWhiteSpaceCheck]="item.external_url"
																			minlength="5" -->
																	<small
																		class="form-text text-danger"
																		*ngIf="
																			TDUrlRef.errors?.invalidUrl &&
																			kbDetailsForm.submitted
																		"
																		>{{
																			"Message.msg_valid_url" | translate
																		}}</small
																	>
																	<small
																		class="form-text text-danger"
																		*ngIf="
																			item.external_url == '' &&
																			TDAPINameRef.valid &&
																			TDAPINameRef.dirty &&
																			item.name !== ''
																		"
																		>{{ "Message.msg_empty_url" | translate }}
																	</small>
																</div>
																<div class="col-xs-12 col-md-4 col-xl-4 col-lg-4">
																	<label id="lblName" for="apiName{{ i }}">{{
																		"UI.k_name" | translate
																	}}</label>
																	<input
																		type="text"
																		id="apiName{{ i }}"
																		name="apiName{{ i }}"
																		class="form-control"
																		placeholder="{{
																			'UI.k_placeholder_link' | translate
																		}}"
																		[(ngModel)]="item.name"
																		#TDAPINameRef="ngModel"
																		minlength="3"
																		maxlength="40"
																		[appWhiteSpaceCheck]="item.name"
																		[required]="item.external_url !== ''"
																	/>
																	<div
																		class="error"
																		*ngIf="
																			kbDetailsForm.submitted &&
																			TDAPINameRef.invalid
																		"
																	>
																		<small *ngIf="TDAPINameRef.errors.minlength">
																			{{ "Error.err_name_min" | translate }}
																		</small>
																		<small *ngIf="TDAPINameRef.errors.maxlength">
																			{{ "Error.err_name_max" | translate }}
																		</small>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDAPINameRef.errors?.whitespace"
																			>{{ "Error.err_ltspace" | translate }}
																		</small>
																		<small
																			class="form-text text-danger"
																			*ngIf="
																				item.external_url !== '' &&
																				TDUrlRef.valid &&
																				TDUrlRef.dirty &&
																				item.name == ''
																			"
																			>{{
																				"Error.err_name_validation" | translate
																			}}
																		</small>
																	</div>
																</div>
																<div
																	class="col-xs-1 col-md-1 col-xl-1 col-lg-1 mt-1 p-0 d-flex align-items-center"
																	*ngIf="!first"
																>
																	<span
																		class="btn"
																		(click)="deleteLink(i)"
																		rippleEffect
																	>
																		<i
																			class="fa-light fa-trash-can text-danger"
																		></i>
																	</span>
																</div>
															</div>
															<div *ngIf="last" class="d-flex">
																<span
																	class="btn btn-flat-primary btn-sm"
																	(click)="addLink()"
																	rippleEffect
																>
																	<i class="fa-light fa-plus text-primary mr-25"></i>
																	<span class="text-primary"
																		>{{ "UI.k_add_reference" | translate }}
																	</span>
																</span>
															</div>
														</div>
													</div>
												</ng-scrollbar>
											</div>

											<div
												class="stepper-btn d-flex justify-content-between border-top py-1 px-2 mt-2"
											>
												<button
													class="btn btn-flat-primary btn-prev"
													(click)="modernHorizontalPrevious()"
													rippleEffect
												>
													<i class="fa-light fa-arrow-left align-middle mr-sm-25"></i>
													<span class="align-middle d-sm-inline-block d-none">{{
														"UI.k_previous" | translate
													}}</span>
												</button>
												<button
													class="btn btn-primary btn-next"
													(click)="modernHorizontalNext(kbDetailsForm)"
													rippleEffect
													type="submit"
													id="btnDetNxt"
													name="btnDetNxt"
													[attr.data-testid]="'btnDetNxt'"
												>
													<span class="align-middle d-sm-inline-block d-none">{{
														"UI.k_next" | translate
													}}</span>
													<i class="fa-light fa-arrow-right align-middle ml-sm-25"></i>
												</button>
											</div>
										</form>
									</div>
									<div id="kb-user-details" class="content">
										<form #kbUserForm="ngForm" class="stepper-inner">
											<div class="stepper-body">
												<ng-scrollbar class="custom-scrollbar section-scrollbar">
													<div class="stepper-main">
														<div fxLayout="row" fxLayoutGap="10px">
															<div class="col-md-2 custom-control custom-radio">
																<input
																	id="rdprivateKB"
																	type="radio"
																	value="private"
																	name="access"
																	(change)="changeKBVisibility($event)"
																	class="custom-control-input"
																	[checked]="kb.is_private"
																/>
																<label class="custom-control-label" for="rdprivateKB">{{
																	"UI.k_private" | translate
																}}</label>
															</div>
															<div class="col-md-2 custom-control custom-radio">
																<input
																	id="rdpublicKB"
																	type="radio"
																	value="public"
																	name="access"
																	(change)="changeKBVisibility($event)"
																	class="custom-control-input"
																	[checked]="!kb.is_private"
																/>
																<label class="custom-control-label" for="rdpublicKB">{{
																	"UI.k_public" | translate
																}}</label>
															</div>
														</div>
														<div *ngIf="kb.is_private" class="mt-1">
															<div class="row">
																<div
																	class="form-group col-xs-6 col-md-6 col-xl-6 col-lg-6"
																>
																	<label class="form-label" for="selUsers">{{
																		"UI.k_users" | translate
																	}}</label>

																	<!-- <ng-select
																		[items]="options?.users"
																		[multiple]="true"
																		[closeOnSelect]="true"
																		[searchable]="false"
																		bindLabel="full_name"
																		bindValue="user"
																		name="selUsers"
																		id="selUsers"
																		placeholder="Select User(s)"
																		[(ngModel)]="kb.users"
																	>
																	</ng-select> -->
																	<ng-select
																		[items]="options?.users"
																		#TDUserRef="ngModel"
																		[(ngModel)]="kb.users"
																		bindLabel="full_name"
																		bindValue="user"
																		name="selUsers"
																		[searchFn]="customSearchFn"
																		id="selUsers"
																		placeholder="{{ 'UI.k_sel_users' | translate }}"
																		[closeOnSelect]="true"
																		[multiple]="true"
																		notFoundText="{{ 'UI.k_no_users' | translate }}"
																		hideSelected="true"
																	>
																		<!-- multiple="true" -->
																		<ng-template
																			ng-option-tmp
																			let-item="item"
																			let-index="index"
																			let-search="searchTerm"
																		>
																			<span
																				class="row"
																				style="padding-left: 1rem"
																			>
																				<div
																					class="avatar mr-1 ml-0"
																					[ngClass]="bg - light - primary"
																					style="
																						align-content: center;
																						width: 32px;
																						height: 32px;
																					"
																				>
																					<div
																						*ngIf="
																							item?.profile_image;
																							else customAvatar
																						"
																					>
																						<img
																							class="rounded-circle mr-1"
																							src="{{
																								item.profile_image
																							}}"
																							height="32"
																							width="32"
																							alt="datatable-avatar"
																						/>
																					</div>
																					<ng-template #customAvatar>
																						<div
																							class="avatar mr-1 ml-0"
																							[ngStyle]="{
																								'background-color':
																									item.avatar_color
																										? item.avatar_color
																										: '#f59518'
																							}"
																						>
																							<div class="avatar-content">
																								{{
																									item.full_name
																										| initials
																										| slice: O:2
																								}}
																							</div>
																						</div>
																					</ng-template>
																				</div>
																				<div class="cell-line-height">
																					<p
																						class="font-medium-1 font-weight-bold line-height-1 mb-25"
																					>
																						{{ item.full_name }}
																					</p>
																					<span
																						class="text-muted font-small-2"
																					>
																						{{ item.email }}</span
																					>
																				</div>
																			</span>
																			<!-- </div> -->
																		</ng-template>
																	</ng-select>
																</div>
																<div
																	class="form-group col-xs-6 col-md-6 col-xl-6 col-lg-6"
																>
																	<label class="form-label" for="selTeam">{{
																		"UI.k_teams" | translate
																	}}</label>

																	<ng-select
																		[items]="options?.teams"
																		[multiple]="true"
																		[closeOnSelect]="true"
																		[searchable]="true"
																		bindLabel="name"
																		bindValue="team_id"
																		name="selTeam"
																		id="selTeam"
																		placeholder="{{ 'UI.k_sel_teams' | translate }}"
																		notFoundText="{{ 'UI.k_no_teams' | translate }}"
																		[(ngModel)]="kb.teams"
																		hideSelected="true"
																	>
																	</ng-select>
																</div>
															</div>
															<div class="row">
																<div
																	class="form-group col-xs-6 col-md-6 col-xl-6 col-lg-6"
																>
																	<label class="form-label" for="selTags">{{
																		"UI.k_user_tags" | translate
																	}}</label>

																	<ng-select
																		[items]="options.user_tags"
																		[multiple]="true"
																		[closeOnSelect]="true"
																		[searchable]="true"
																		bindLabel="name"
																		bindValue="tag_id"
																		name="selTags"
																		id="selTags"
																		placeholder="{{
																			'UI.k_sel_user_tags' | translate
																		}}"
																		[(ngModel)]="kb.user_tags"
																		hideSelected="true"
																		notFoundText="{{
																			'UI.k_no_user_tags' | translate
																		}}"
																	>
																	</ng-select>
																</div>
																<div
																	class="form-group col-xs-6 col-md-6 col-xl-6 col-lg-6"
																>
																	<label class="form-label" for="selGroups">{{
																		"UI.k_expertise_tags" | translate
																	}}</label>

																	<ng-select
																		[items]="options.group_tags"
																		[multiple]="true"
																		[closeOnSelect]="true"
																		[searchable]="true"
																		bindLabel="name"
																		bindValue="tag_id"
																		name="selGroups"
																		id="selGroups"
																		placeholder="{{
																			'UI.k_sel_expertise_tags' | translate
																		}}"
																		[(ngModel)]="kb.group_tags"
																		hideSelected="true"
																		notFoundText="{{
																			'UI.k_no_group_tags' | translate
																		}}"
																	>
																	</ng-select>
																</div>
															</div>
															<div class="mt-2">
																<ngb-alert [type]="'primary'" [dismissible]="false">
																	<div class="alert-body">
																		<strong>
																			{{ "UI.k_note" | translate }}:
																			&nbsp;</strong
																		>
																		{{ "UI.k_atleast_one_drpd" | translate }}
																	</div>
																</ngb-alert>
															</div>
															<div *ngIf="showAccessError" class="mt-2">
																<ngb-alert [type]="'danger'" [dismissible]="true">
																	<div class="alert-body">
																		<i
																			class="fa-light fa-circle-info mr-50 align-middle"
																		></i>
																		<span>{{ "UI.k_sel_drpd" | translate }}</span>
																	</div>
																</ngb-alert>
															</div>
														</div>
													</div>
												</ng-scrollbar>
											</div>
											<div
												class="stepper-btn d-flex justify-content-between border-top px-2 py-1"
											>
												<button
													class="btn btn-flat-primary btn-prev"
													(click)="modernHorizontalPrevious()"
													rippleEffect
												>
													<i class="fa-light fa-arrow-left align-middle mr-sm-25"></i>
													<span class="align-middle d-sm-inline-block d-none">{{
														"UI.k_previous" | translate
													}}</span>
												</button>
												<button
													type="submit"
													id="btnKbSubmit"
													name="kbSubmit"
													[attr.data-testid]="'btnKbSubmit'"
													rippleEffect
													class="btn btn-primary pull-right"
													(click)="onSave(kbUserForm)"
													[disabled]="is_save_disabled"
												>
													{{ "UI.k_submit" | translate }}
												</button>
											</div>
										</form>
									</div>
								</div>
								<!-- </form> -->
							</div>
						</div>
					</section>
					<!-- /Modern Horizontal Wizard -->
				</div>
			</div>
		</div>
	</div>
</div>
