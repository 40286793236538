import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, throwError } from 'rxjs';
import { SystemMenuService } from '../../services/system-menu.service';

@Component({
  selector: 'app-add-signature',
  templateUrl: './add-signature.component.html',
  styleUrls: ['./add-signature.component.scss']
})
export class AddSignatureComponent implements OnInit {
  @Input() event;
  @Input() sign_obj:any ={};
  @Input() sign_id = "-1";
  @Output("hideSignSidebar") hideSignSidebar = new EventEmitter<any>();
  public filesList: any = [];
  constructor(
    private _translateService: TranslateService,
    private _coreSidebarService: CoreSidebarService,
	private _configService: SystemMenuService,
	private _toastr: ToastrService,

  ) {
	 }

  ngOnInit(): void {
	if (this.sign_id != '-1'){
		this.getSignatureData()
	}
  }
	getSignatureData() {
		this._configService.getUserSignatureData(this.sign_id).subscribe(
			(response) => {
				if (response) {
					this.sign_obj = response
				}
			},
			(error) => {
				//todo
			}
		);	
	}
	// ngOnChanges(changes: SimpleChanges) {    
	// 	if(this.event = "edit"){
	// 		this.sign_obj.tempText = this.sign_obj.tempText + '<img src="assets/images/infraon_logo.svg"/>'
	// 	}else if (this.event = "add"){
	// 		this.sign_obj.tempText = ""
	// 	}	else{
	// 		this.sign_obj.tempText = this.sign_obj.tempText + '<img src="assets/images/infraon_logo.svg"/>'
	// 	}
	// }
  toggleSidebar = (name) => {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
	this.hideSignSidebar.emit()
  }

  removeAttachedFile = function (index, filesList) {
		filesList.splice(index, 1);
	};
  fileUpload = function (evt) {
		var files = evt.target.files;
		var new_file_len = evt.target.files.length;
		var added_file_len = this.filesList.length;
		for (var i = 0; i < new_file_len; i++) {
			var file = files[i];
			var pos = added_file_len + i;
			var reader = new FileReader();
			reader.onload = (function (filesList, file, pos) {
				return function (e) {
					var data = e.target.result;
					filesList[pos] = {
						file_name: file.name,
						file_type: file.type,
						file_size: file.size,
						file_data: data,
						file_date: new Date(),
						//'file_date': this.datepipe.transform(new Date(),'MMM dd,yyyy hh:mm a')
					};
				};
			})(this.filesList, file, pos);
			reader.readAsDataURL(file);
		}
	};

	onSubmit(data) {
        if (data.form.valid === true) {
            this.saveUserSign(this.sign_obj, data);
        }

	}
	saveUserSign(userdata, form){
		this._configService
			.saveUserSignature(userdata)
			.pipe(
				map((response: any) => {
					if (response) {
						if (response["status"] == "success") {
							this._toastr.success(
								this._translateService.instant(response["message"]),
								this._translateService.instant("Message.msg_save_success"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
							this.toggleSidebar('add-signature')
						}
						else {
							this._toastr.error(
								this._translateService.instant(response["message"]),
								this._translateService.instant("Error.err_save_failed"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
						}

					} else {
						this._toastr.error(
							this._translateService.instant(response["message"]),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
					}
				}),
				catchError((error) => {
					this._toastr.error(
						this._translateService.instant(error.error.error.message),
						this._translateService.instant("Error.err_save_failed"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					//   this.notification.error('Failed User Creation', 'Something went wrong')
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}

	getEnrichTextData = (event) => {
		this.sign_obj.content = event.html;
	};
}
