import { Component, OnInit, Input } from "@angular/core";
import { NgEventBus } from "ng-event-bus";

@Component({
	selector: "app-imacd-count",
	templateUrl: "./imacd-count.component.html",
	styleUrls: ["./imacd-count.component.scss"],
})
export class ImacdCountComponent implements OnInit {
	@Input() widgetData: any;
	@Input() modifiedtime: any;
	config: any;

	constructor(private eventBus: NgEventBus) {}

	ngOnInit(): void {
		this.config = this.widgetData?.data;
	}
	showimacdSidebar(dat) {
		if (dat?.count > 0 && dat?.item_filter) {
			let payload = {
				title: "IMACD - " + dat.name,
				app_type: "imacd-grid",
				item_filter: dat.item_filter,
			};
			// console.log("in inci count widget -> ", payload)
			this.eventBus.cast("dashboard-view:sidebar", payload);
		}
	}
}
