import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-dns-grid-tag",
	templateUrl: "./dns-grid-tag.component.html",
	styleUrls: ["./dns-grid-tag.component.scss"],
})
export class DnsGridTagComponent implements OnInit {
	data: any;
	key: any;
	subkey: any;
	id: any;
	public rowindex: any;
	public logoMap = {
		asset: "assets/images/products/product-asset.svg",
		helpdesk: "assets/images/products/product-helpdesk.svg",
		infinity: "assets/images/products/product-infinity.svg",
		itim: "assets/images/products/product-itim.svg",
		itsm: "assets/images/products/product-itsm.svg",
		nms: "assets/images/products/product-nms.svg",
		uptime: "assets/images/products/product-uptime.svg",
	};

	constructor() {
		this.data = {};
	}

	ngOnInit(): void {}
}
