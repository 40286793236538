<div class="slideout-content default-slideout">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog ">
            <div class="modal-content add-new-user py-0">
                <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="closeSidebar()">
                    ×
                </button> -->
                <div *ngIf="!dataLoaded" class="d-flex justify-content-center align-items-center height-400">
                    <div class="spinner-border text-primary" aria-hidden="true"></div>
                </div>
                <div class="modal-header border-bottom" *ngIf="dataLoaded">
                    <h5 *ngIf="!problem_data?.is_deleted" class="modal-title" id="exampleModalLabel">{{"UI.k_problem" | translate}}</h5>
                    <h5 *ngIf="problem_data?.is_deleted" class="modal-title" id="exampleModalLabel">{{"UI.k_problem" | translate}} 
                        <span class="text-primary">
                            &nbsp;#{{problem_data?.display_id}}
                        </span>
                    </h5>
                    <button
                    type="button"
                    id="btnClose"
                    name="btnClose"
                    class="btn btn-flat-secondary btn-icon"
                    data-dismiss="modal"
                    aria-label="Close"
                    [attr.data-testid]="'btnClose'"
                    (click)="closeSidebar()"
                >
                    <i class="fa-light fa-xmark"></i>
                </button>
                </div>
                <div class="modal-body flex-1 p-0">
                    
                        <ng-scrollbar class="custom-scrollbar section-scrollbar">
                            <div class="p-1">
                            <div class="row kb-search-content-info match-height ml-0 mr-0" *ngIf="!problem_data?.is_deleted && dataLoaded" >
                                <div class="card border p-1">
                                    <div class="d-flex flex-wrap align-items-center">
                                        <div class="pr-1 mr-auto mb-50">
                                            <a routerLink="/problem_management/problem-edit/{{ problem_data?.problem_id }}" target="_blank">
                                                <div class="text-primary font-weight-bold text-uppercase">{{problem_data?.display_id}}</div>
                                            </a>
                                        </div>
                                        <div class="d-flex align-items-center mb-50 sm-font mb-2">
                                            <div
                                                class="custom-squre"
                                                [ngClass]="'bg-' + problem_data?.basic_info?.status?.color"
                                                [ngStyle]="{
                                                    'background-color':
                                                    problem_data?.basic_info?.status?.color
                                                }"
                                            ></div>
                                            <span>{{ problem_data?.basic_info?.status?.name | translate
                                            }}</span>
                                            <div
                                                class="right-arrow custom-text"
                                                [ngStyle]="{
                                                    'background-color':
                                                    problem_data?.basic_info?.state?.background_color,
                                                    color:
                                                    problem_data?.basic_info?.state?.text_color ||
                                                    problem_data?.basic_info?.state?.color_hex
                                                }"
                                            >
                                                {{ problem_data?.basic_info?.status?.state | translate }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex flex-wrap align-items-center sm-font mb-1">
                                        <div class="pr-1 mr-auto mb-50">
                                            <div dnsReadMore
                                            [readMore-length]="200" [innerHTML]="problem_data?.basic_info?.summary"></div>
                                        </div>
                                        <div class="mb-50 gont-sm">
                                            <span>{{ "UI.k_created_on" | translate }} : {{problem_data?.creation_time}}</span>
                                        </div>
                                    </div>
                                            <div class="mb-2">
                                                <div class="bg-body p-1 mb-1">
                                                    <h6 class="font-weight-bolder mb-0">{{ "UI.k_executive_summary" | translate }}</h6>
                                                </div>
                                                <div>
                                                    <div class="row px-1">
                                                        <!-- <div class="col-sm-12">
                                                            <span class="sm-font title-color">{{ "UI.k_planning" | translate }}</span>
                                                            <div class="mb-2 font-weight-bold" [innerHTML]="problem_data?.basic_info?.planning"></div>
                                                        </div>
                                                        <div class="col-sm-12">
                                                            <span class="sm-font title-color">{{ "UI.k_rollout" | translate }}</span>
                                                            <div class="mb-2 font-weight-bold" [innerHTML]="problem_data?.basic_info?.rollout ? problem_data?.basic_info?.rollout : '-'"></div>
                                                        </div>
                                                        <div class="col-sm-12">
                                                            <span class="sm-font title-color">{{ "UI.k_rollback" | translate }}</span>
                                                            <div class="mb-2 font-weight-bold" [innerHTML]="problem_data?.basic_info?.rollback ? problem_data?.basic_info?.rollback : '-'"></div>
                                                        </div> -->
                                                        <div class="col-sm-6">
                                                            <span class="sm-font title-color">{{ "UI.k_catalogue" | translate }}</span>
                                                            <div class="mb-2 font-weight-bold">{{problem_data?.basic_info?.catalogue_name ? problem_data?.basic_info?.catalogue_name : "-"}}</div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <span class="sm-font">{{ "UI.k_category" | translate }}</span>
                                                            <div class="mb-2 font-weight-bold">{{problem_data?.basic_info?.category_name ? problem_data?.basic_info?.category_name : "-"}}</div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <span class="sm-font">{{ "UI.k_service" | translate }}</span>
                                                            <div class="mb-2 font-weight-bold">{{problem_data?.basic_info?.impact_service_name ? problem_data?.basic_info?.impact_service_name : "-" }}</div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <span class="sm-font">{{ "UI.k_classification" | translate }}</span>
                                                            <div class="mb-2 font-weight-bold">{{problem_data?.basic_info?.service_classification_name ? problem_data?.basic_info?.service_classification_name : "-" }}</div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <span class="sm-font">{{ "UI.k_description" | translate }}</span>
                                                            <div class="mb-2 font-weight-bold" [innerHTML]="problem_data?.basic_info?.description ? (problem_data?.basic_info?.description | striphtml) : '-'"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-2">
                                                <div class="bg-body p-1 mb-1">
                                                    <h6 class="font-weight-bolder mb-0">{{ "UI.k_problem_detail" | translate }}:</h6>
                                                </div>
                                                <div>
                                                    <div class="row px-1">
                                                        <div class="col-sm-12">
                                                            <span class="sm-font title-color">{{ "UI.k_customer_details" | translate }}</span>
                                                            <div class="mb-25 font-weight-bold">{{problem_data?.requester?.full_name}}
                                                            </div>
                                                            <div class="d-flex align-items-center flex-wrap mb-2">
                                                                <a [href]="'mailto:'+problem_data?.requester?.email"
                                                                    class="mr-1 sm-font text-body">
                                                                    <i class="fa-light fa-envelope mr-50"></i>
                                                                    <span>{{problem_data?.requester?.email}} </span>
                                                                </a>
                                                                <a class="mr-1 sm-font text-body" *ngIf="problem_data?.requester?.phone_number?.internationalNumber">
                                                                    <i class="fa-light fa-phone mr-50"></i>
                                                                    <span>{{problem_data?.requester?.phone_number?.internationalNumber}}</span>
                                                                </a>
                                                                <a  class="mr-1 sm-font text-body" target="_blank" *ngIf="location_name">
                                                                    <i class="fa-light fa-location-dot mr-50"></i>
                                                                    <span>{{location_name}}</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-sm-12">
                                                            <span class="sm-font title-color">{{ "UI.k_problem_manager" | translate }}</span>
                                                            <div class="mb-25 font-weight-bold">{{problem_data?.problem_manager_profile?.full_name}}
                                                            </div>
                                                            <div class="d-flex align-items-center flex-wrap mb-2">
                                                                <a [href]="'mailto:'+problem_data?.problem_manager_profile?.email"
                                                                    class="mr-1 sm-font text-body">
                                                                    <i class="fa-light fa-envelope mr-50"></i>
                                                                    <span>{{problem_data?.problem_manager_profile?.email}} </span>
                                                                </a>
                                                                <a class="mr-1 sm-font text-body" *ngIf="problem_data?.problem_manager_profile?.phone_number?.internationalNumber">
                                                                    <i class="fa-light fa-phone mr-50"></i>
                                                                    <span>{{problem_data?.problem_manager_profile?.phone_number?.internationalNumber}}</span>
                                                                </a>
                                                            </div>
                                                        </div> -->
                                                        <div class="col-sm-12">
                                                            <span class="sm-font title-color">{{ "UI.k_reportedby" | translate }}</span>
                                                            <div class="mb-25 font-weight-bold">{{problem_data?.reporter_info?.name ? problem_data?.reporter_info?.name : "-"}}
                                                            </div>
                                                            <div class="d-flex align-items-center flex-wrap mb-2">
                                                                <a [href]="'mailto:'+problem_data?.reporter_info?.email" *ngIf="problem_data?.reporter_info?.email"
                                                                    class="mr-1 sm-font text-body">
                                                                    <i class="fa-light fa-envelope mr-50"></i>
                                                                    <span>{{problem_data?.reporter_info?.email}} </span>
                                                                </a>
                                                                <a class="mr-1 sm-font text-body" *ngIf="problem_data?.reporter_info?.phone_number?.e164Number">
                                                                    <i class="fa-light fa-phone mr-50"></i>
                                                                    <span>{{problem_data?.reporter_info?.phone_number?.e164Number}}</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 mb-2">
                                                            <span class="sm-font title-color">{{ "UI.k_followers" | translate }}</span>
                                                            <div class="mb-25 font-weight-bold">
                                                                {{
                                                                problem_data?.followers ? problem_data?.followers : "-"
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 mb-2">
                                                            <span class="sm-font title-color">{{ "UI.k_workflow" | translate }}</span>
                                                            <div class="mb-25 font-weight-bold">
                                                                {{
                                                                    problem_data?.workflow_name
                                                                    ? problem_data?.workflow_name
                                                                    : "-"
                                                                    }}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <span class="sm-font title-color">{{ "UI.k_known_error" | translate }}</span>
                                                            <div class="mb-2 font-weight-bold">{{ problem_data?.is_known_error_enable ? ("UI.k_enabled" | translate )  : ("UI.k_disabled" | translate) }}</div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <span class="sm-font title-color">{{ "UI.k_due_date" | translate }}</span>
                                                            <div class="mb-2 font-weight-bold">{{ problem_data?.due_date ? problem_data?.due_date : "-"}}</div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <span class="sm-font title-color">{{ "UI.k_problem_source" | translate }}</span>
                                                            <div class="mb-2 font-weight-bold">{{ problem_data?.basic_info?.problem_source?.name}}</div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <span class="sm-font title-color">{{ "UI.k_impact" | translate }}</span>
                                                            <div class="mb-2 font-weight-bold">{{problem_data?.basic_info?.impact?.name ? problem_data?.basic_info?.impact?.name : "-"}}</div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <span class="sm-font title-color">{{ "UI.k_priority" | translate }}</span>
                                                            <div>
                                                                <div  class="badge badge-light-{{
                                                                    problem_data?.basic_info?.priority?.color
                                                                }}"><i
                                                                        class="fa-light mr-25 {{
                                                                            this.problem_data?.basic_info?.priority?.icon
                                                                        }}"></i>{{
                                                                            this.problem_data?.basic_info?.priority?.display_key
                                                                                | translate
                                                                        }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <span class="sm-font title-color">{{ "UI.k_urgency" | translate }}</span>
                                                            <div *ngIf="problem_data?.basic_info?.urgency?.name; else noUrgency">
                                                                <div  class="badge badge-light-{{
                                                                    problem_data?.basic_info?.urgency?.color
                                                                }}"><i
                                                                        class="fa-light mr-25 {{
                                                                            this.problem_data?.basic_info?.urgency?.icon
                                                                        }}"></i>{{ problem_data?.basic_info?.urgency?.name ?
                                                                            problem_data?.basic_info?.urgency?.name: "-"
                                                                        }}
                                                                </div>
                                                            </div>
                                                            <ng-template #noUrgency>
                                                                <div class="mb-2 font-weight-bold">{{'-'}}
                                                                </div>
                                                            </ng-template>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-2" *ngIf="problem_data?.current_assignment_info?.group_name || problem_data?.current_assignee_profile?.full_name">
                                                <div class="bg-body p-1 mb-1">
                                                    <h6 class="font-weight-bolder mb-0">{{ "UI.k_team" | translate }}:</h6>
                                                </div>
                                                <div>
                                                    <div class="row px-1">
                                                        <div class="col-sm-6">
                                                            <span class="sm-font title-color">{{ "UI.k_group_name" | translate }}</span>
                                                            <div class="mb-2 font-weight-bold">{{problem_data?.current_assignment_info?.group_name ? problem_data?.current_assignment_info?.group_name : '-'}}</div>
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <span class="sm-font title-color">{{ "UI.k_assignee" | translate }}</span>
                                                            <div class="mr-auto d-flex align-items-center" *ngIf="problem_data?.current_assignee_profile?.full_name; else noAssignee">
                    
                                                                <div class="avatar avatar-sm mr-50" *ngIf="problem_data?.current_assignee_profile?.profile_image">
                                                                    <div class="avatar-content">
                                                                        <img [src]="problem_data?.current_assignee_profile?.profile_image" alt="User" />
                                                                    </div>
                                                                </div>
                                                                <div class="avatar avatar-xs mr-50" *ngIf="!problem_data?.current_assignee_profile?.profile_image" [ngStyle]="{
                                                                    'background-color': problem_data
                                                                        ?.current_assignee_profile?.avatar_color
                                                                        ? problem_data?.current_assignee_profile
                                                                                ?.avatar_color
                                                                        : '#f59518'
                                                                }"
                                                            >
                                                                    <div class="avatar-content">
                                                                        {{
                                                                            problem_data?.current_assignee_profile?.full_name
                                                                                | initials
                                                                                | slice: O:2
                                                                        }}
                                                                    </div>
                                                                </div>
                                                                <span class="sm-font title-color">{{problem_data?.current_assignee_profile?.full_name}}</span>
                                                            </div>
                                                            <ng-template #noAssignee>
                                                                <div class="mb-2 font-weight-bold">{{'-'}}
                                                                </div>
                                                            </ng-template>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                </div> 
                            </div>
                            
                            <!-- kb deleted -->
                            <ng-container *ngIf="problem_data?.is_deleted">
                                <div class="col-12 text-center no-result">
                                    <h4 class="mt-4">
                                        {{"Message.msg_problem_removed" | translate}}
                                    </h4>
                                </div>
                            </ng-container>
                            </div>
                        </ng-scrollbar>
                </div>
            </div>
        </div>
    </div>
</div>
