<ng-scrollbar class="custom-scrollbar section-scrollbar">
	<div class="action-bar">
		<div class="inner-section">
			<div class="top-header-card align-items-start">
				<div>
					<div class="mb-50 fw-500 sm-font title-color">{{ "UI.k_billing_address" | translate }}</div>
					<div class="mb-50 mb-25 billing-address-text">{{ billingAddress() }}</div>
					<button type="button" disabled class="btn btn-flat-primary py-25 px-50 sm-font" rippleEffect>
						{{ "UI.k_change" | translate }}
					</button>
				</div>
				<div>
					<div class="mb-50 fw-500 sm-font title-color">{{ "UI.k_payment_info" | translate }}</div>
					<div class="mb-25 d-flex align-items-start">
						<div class="mr-1">
							<img
								src="../../../../assets/images/admin/{{ cardType() }}.png"
								alt="Logo"
								width="58"
								height="40"
							/>
						</div>
						<div>
							<div class="fw-500 title-color">{{ cardMaskedNumber() }}</div>
							<span class="sm-font"> {{ cardExpiry() }} </span>
						</div>
					</div>
					<button type="button" disabled class="btn btn-flat-primary py-25 px-50 sm-font" rippleEffect>
						{{ "UI.k_change" | translate }}
					</button>
				</div>
				<div>
					<div class="mb-25 title-color">
						<span class="h2 fw-700">{{ instanceTotalPrice() | currency: instanceCurrency() }}</span>
						<span class="h6 fw-400"> / {{ instanceBillingUnit() }} </span>
					</div>
					<div class="sm-font">
						{{ "UI.k_save" | translate }} <span class="text-primary">Upto 20%</span>
						{{ "UI.k_by_moving_to_an" | translate }}
						<a target="_blank" href="#">{{ "UI.k_annual" | translate }} {{ "UI.k_plan" | translate }}</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="p-2" style="position: relative">
		<h6 class="fw-600 mb-1">{{ "UI.k_invoices" | translate }}</h6>
		<dns-server-grid
			[rows]="invoiceData"
			[columns]="columns"
			[options]="options"
			(page)="setPage($event)"
			[count]="totalCount"
			[columns]="columns"
			(sort)="onSort($event)"
			(setlimit)="setLimit($event)"
			[limit]="limit"
			class="bootstrap core-bootstrap"
			scrollbarH="true"
			[permissions]="permissions"
			[selectionType]="SelectionType.checkbox"
			(onSelectCheckbox)="onSelectRow($event)"
		>
		</dns-server-grid>
	</div>
</ng-scrollbar>
