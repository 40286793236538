export class TicketRequest {
	constructor(ticket: any) {
		this.status = ticket?.status || {};
		this.severity = ticket?.severity || {};
		this.catalogue = ticket?.catalogue || null;
		this.catalogue_name = ticket?.catalogue_name || null;
		this.category = ticket?.category || null;
		this.category_name = ticket?.category_name || null;
		this.impact_service = ticket?.impact_service || null;
		this.impact_service_name = ticket?.impact_service_name || null;
		this.impact_service_tree_path = ticket?.impact_service_tree_path || null;
		if (ticket?.asset?.ci_id != undefined){
			this.impacted_assets = [ticket?.asset] || null;
		}
		this.priority = ticket?.priority || null;
		this.requested_by = {
			requester_id: ticket?.requester_id || null,
			full_name: ticket?.requester_name || null,
			email: ticket?.requester_email || null,
			contact_number: ticket?.requester_phone_number || null,
			department: ticket?.requester_department || null,
			partner: ticket?.requester_partner || null,
			sub_partner: ticket?.requester_sub_partner || null,
			subscriber_id: ticket?.requester_subscriber_id || null,
			customer_id: ticket?.requester_customer_id || null,
			organization_name: ticket?.requester_organization_name || null,
			notify_email: ticket?.requester_notify_email || null,
			notify_phone: ticket?.requester_notify_phone || null,

		};
		this.assignee = {
			assignee: ticket?.assignee || null,
			assignee_profile: ticket?.assignee_profile || null,
			completed_level: null,
			cse_assignee: null,
			cse_required: null,
			customer_site_group: null,
			expertise: ticket?.expertise || null,
			expertise_id: ticket?.expertise_id || null,
			group: ticket?.support_group || null,
			group_name: ticket?.group_name || null,
			group_type: ticket?.group_type || null,
			level: ticket?.level,
			level_category: ticket?.level_category || null,
			level_id: ticket?.level_id || null,
			vendor: null,
			vendor_cse: null,
			vendor_team: null
		};
		this.source = ticket?.incident_source || {};
		this.due_date = ticket?.due_date;
		this.release_manager = ticket?.release_manager;
		this.change_manager = ticket?.change_manager;
	}
	assignee?: any;
	catalogue?: any;
	catalogue_name?: any;
	category?: any;
	category_name?: any;
	impact_service?: any;
	impact_service_name?: any;
	impact_service_tree_path?: any;
	impacted_assets?: Array<any>;
	priority: any;
	requested_by: {
		requester_id: string
		full_name: string
		email: string
		contact_number: string
		department: any
		partner: any
		sub_partner: any
		subscriber_id: any
		customer_id: any
		organization_name: any
		notify_email: any
		notify_phone: any
	};
	service_classification?: any;
	service_classification_name?: any;
	severity: {};
	status: {
		name: string
		state: string
		state_id: number
		guid: string
		color: string
		id: string
		background_color: string
		workflow_id: string
		workflow_name: string
		transition_state: Array<string>
	};
	source: {};
	due_date: any;
	release_manager: any;
	change_manager: any;
}
