<form #smsConfigForm="ngForm" name="smsConfigForm" class="form">
	<div class="row p-1">
		<!--Basic Form Container Starts-->
		<div class="col-6 col-xs-12">
			<div class="row margin-0">
				<div class="col-12 col-xs-12">
					<div class="form-group">
						<label for="txtSmsTitle">{{ "UI.k_title" | translate }}*</label>
						<input
							type="text"
							id="txtSmsTitle"
							name="title"
							[(ngModel)]="config.title"
							class="form-control"
							placeholder="{{ 'UI.k_enter_title' | translate }}"
							minlength="3"
							maxlength="100"
							#TDTitleRef="ngModel"
							[appWhiteSpaceCheck]="config.title"
							[class.error]="smsForm.submitted && TDTitleRef.invalid"
							required
						/>
						<span *ngIf="smsForm.submitted && TDTitleRef.invalid" class="invalid-form">
							<small *ngIf="TDTitleRef?.errors?.required" class="form-text text-danger">{{
								"UI.k_field_required" | translate
							}}</small>
							<small
								*ngIf="TDTitleRef?.errors?.minlength || TDTitleRef.errors.maxlength"
								class="form-text text-danger"
								>{{ "UI.k_title_length_validation" | translate }}</small
							>
							<small *ngIf="TDTitleRef.errors?.whitespace" class="form-text text-danger">{{
								"Error.err_ltspace" | translate
							}}</small>
						</span>
					</div>
				</div>
				<div class="col-12 col-xs-12">
					<div class="form-group">
						<span class="pull-right p-b-5px">
							<div class="custom-control custom-switch custom-control-inline">
								<input
									type="checkbox"
									class="custom-control-input"
									name="cbGetDial"
									id="cbGetDial"
									[(ngModel)]="config.add_dial_code"
									(change)="onCheckboxChange($event, 'dial')"
								/>			
								<label class="custom-control-label" for="cbGetDial">{{
									"UI.k_dial_code" | translate
								}}</label>					
							</div>
							<div class="custom-control custom-switch custom-control-inline">
								<input
									type="checkbox"
									class="custom-control-input"
									name="cbGetRequest"
									id="cbGetRequest"
									[(ngModel)]="config.is_get_method"
									(change)="onCheckboxChange($event, 'method')"
								/>
								<label class="custom-control-label" for="cbGetRequest">{{
									"UI.k_use_get_request" | translate
								}}</label>
							</div>
							<div class="custom-control custom-switch custom-control-inline">
								<input
									type="checkbox"
									class="custom-control-input"
									[(ngModel)]="config.is_https"
									name="cbHttps"
									id="cbHttps"
									(change)="onCheckboxChange($event, 'http')"
								/>
								<label class="custom-control-label" for="cbHttps">{{
									"UI.k_use_https" | translate
								}}</label>
							</div>
						</span>
						<label for="Smsurl">{{ "UI.k_url" | translate }}*</label>
						<div class="input-group m-t-1">
							<div class="input-group-prepend">
								<span class="input-group-text">{{ config.is_https ? "https://" : "http://" }}</span>
							</div>
							<input
								type="url"
								id="Smsurl"
								name="url"
								class="form-control"
								placeholder="{{ 'UI.k_enter_url' | translate }}"
								[(ngModel)]="config.url"
								minlength="3"
								maxlength="1000"
								#TDUrlRef="ngModel"
								[class.error]="smsForm.submitted && TDUrlRef.invalid"
								[appWhiteSpaceCheck]="config.url"
								pattern="^(?!www | www\.)[A-Za-z0-9_-]+\.+[A-Za-z0-9.\/%&=\?_:;-]+$"
								required
							/>
						</div>
						<span *ngIf="smsForm.submitted && TDUrlRef.invalid" class="invalid-form">
							<small *ngIf="TDUrlRef?.errors?.required" class="form-text text-danger">{{
								"UI.k_field_required" | translate
							}}</small>
							<small
								*ngIf="TDUrlRef?.errors?.minlength || TDUrlRef.errors.maxlength"
								class="form-text text-danger"
								>{{ "UI.k_url_length_validation" | translate }}</small
							>
							<small *ngIf="TDUrlRef.errors?.whitespace" class="form-text text-danger">{{
								"Error.err_ltspace" | translate
							}}</small>
							<small *ngIf="TDUrlRef.errors?.pattern" class="form-text text-danger">{{
								"UI.k_pattern_validation" | translate
							}}</small>
						</span>
					</div>
				</div>
				<div class="col-12 col-xs-12">
					<div class="form-group">
						<label for="status_code">{{ "UI.k_status_code" | translate }}</label>
						<input
							type="text"
							id="status_code"
							name="status_code"
							class="form-control"
							[(ngModel)]="config.status_code"
							#TDCodeRef="ngModel"
							placeholder="{{ 'UI.k_enter_status_code' | translate }}"
							pattern="(?![0]\b)\d{1,4}"
						/>
					</div>
					<span *ngIf="smsForm.submitted && TDCodeRef.invalid" class="invalid-form text-danger">
						<small *ngIf="TDCodeRef.errors.pattern">
							{{ "Message.msg_1_to_9999_val" | translate }}
						</small>
					</span>
				</div>
			</div>
		</div>
		<!--Basic Form Container Ends-->
		<!--Request Parameter Form Container Starts-->
		<div class="col-6 col-xs-12">
			<div class="request-para-head d-flex justify-content-between align-items-center mb-1">
				<label>{{ "UI.k_request_parameters" | translate }}</label>
				<a
					(click)="onAddRequestParameter()"
					title="Add Request Parameter(s)"
					id="btnAddRequestParams"
					class="btn btn-primary btn-sm pull-right"
					><i class="fa-light fa-plus"></i>&nbsp; {{ "UI.k_add" | translate }}</a
				>
			</div>
			<ngb-alert [type]="'primary'" [dismissible]="false" *ngIf="config?.params?.length == 0">
				<div class="alert-body">
					<p>
						{{ "UI.k_empty_request_param_msg" | translate }}
					</p>
					<br />
					<p [innerHtml]="'UI.k_sms_gateway_note' | translate"></p>
				</div>
			</ngb-alert>
			<div class="add-config-param" @heightIn>
				<ng-container *ngFor="let param of config?.params">
					<div class="config-row">
						<div class="form-group">
							<label for="key{{ param?.id }}">{{ "UI.k_key" | translate }}</label>
							<input
								type="text"
								id="key{{ param?.id }}"
								name="key{{ param?.id }}"
								[(ngModel)]="param.key"
								class="form-control"
								placeholder="{{ 'UI.k_enter_key' | translate }}"
								[class.error]="TDKeyRef.tocuhed && TDKeyRef.invalid"
								#TDKeyRef="ngModel"
								[appWhiteSpaceCheck]="param.key"
								required
							/>
							<span *ngIf="(smsForm.submitted || TDKeyRef.tocuhed) && TDKeyRef.invalid" class="invalid-form">
								<small *ngIf="TDKeyRef?.errors?.required" class="form-text text-danger">{{
									"UI.k_field_required" | translate
								}}</small>
								<small
									*ngIf="TDKeyRef?.errors?.minlength || TDKeyRef.errors.maxlength"
									class="form-text text-danger"
									>{{ "Error.err_input_min_char" | translate }}</small
								>
								<small *ngIf="TDKeyRef.errors?.whitespace" class="form-text text-danger">{{
									"Error.err_ltspace" | translate
								}}</small>
								<small *ngIf="TDKeyRef.errors?.pattern" class="form-text text-danger">{{
									"UI.k_pattern_validation" | translate
								}}</small>
							</span>
						</div>
						<div class="form-group">
							<span class="pass-to-switch">
								<div class="custom-control custom-switch custom-control-inline">
									<input
										type="checkbox"
										class="custom-control-input"
										[(ngModel)]="param.add_to_header"
										name="cbAddToHeader{{ param?.id }}"
										id="cbAddToHeader{{ param?.id }}"
									/>
									<label class="custom-control-label" for="cbAddToHeader{{ param?.id }}">{{
										"UI.k_pass_to_header" | translate
									}}</label>
								</div>
							</span>
							<label for="value{{ param?.id }}">{{ "UI.k_value" | translate }}</label>
							<input
								type="text"
								class="form-control"
								[(ngModel)]="param.value"
								#TDValueRef="ngModel"
								[appWhiteSpaceCheck]="param.value"
								[class.error]="TDValueRef.tocuhed && TDValueRef.invalid"
								id="value{{ param?.id }}"
								required
								name="value{{ param?.id }}"
								placeholder="{{ 'UI.k_enter_value' | translate }}"
							/>
							<span
								*ngIf="(smsForm.submitted || TDValueRef.tocuhed) && TDValueRef.invalid"
								class="invalid-form"
							>
								<small *ngIf="TDValueRef?.errors?.required" class="form-text text-danger">{{
									"UI.k_field_required" | translate
								}}</small>
								<small
									*ngIf="TDValueRef?.errors?.minlength || TDValueRef.errors.maxlength"
									class="form-text text-danger"
									>{{ "Error.err_input_min_char" | translate }}</small
								>
								<small *ngIf="TDValueRef.errors?.whitespace" class="form-text text-danger">{{
									"Error.err_ltspace" | translate
								}}</small>
								<small *ngIf="TDValueRef.errors?.pattern" class="form-text text-danger">{{
									"UI.k_pattern_validation" | translate
								}}</small>
							</span>
						</div>
						<div
						class="form-group mt-1"
						id="btnReqParamDelete{{ param?.id }}"
						ngbTooltip="{{ 'UI.k_delete' | translate }}"
						(click)="onDeleteRequestParameter(param?.id)"
					>
						<i class="fa-light fa-trash-can fa-lg text-danger pt-1 mt-1"></i>
					</div>
					</div>
				</ng-container>
			</div>
		</div>
		<!--Request Parameter Form Container Ends-->
	</div>
</form>
