<div class="card-body pl-1 pb-1">
	<div class="font-small-2 mb-0 text-break" id="loc-name-{{rowindex}}">
		{{ location?.location_name }}
	</div>
	<div class="font-small-2 mb-0 text-break" id="loc-city-{{rowindex}}">
		{{ location?.city }} {{ location?.city ? ',' : ''}} 
		{{ location?.state }} {{ location?.state ? ',' : ''}} 
		{{ location?.country }} {{ location?.country ? ',' : ''}}
		{{ location?.postalcode }}
	</div>
</div>