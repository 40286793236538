<div class="slideout-content">
	<div id="modals-slide-in" class="modalsd modal-slide-in sdfade new-user-modal">
		<div class="modal-dialog sidebar-sm">
			<div class="modal-content notification-modal pt-0 border-0 border-left">
				<div class="modal-header card-bg border-bottom">
					<h5 class="modal-title" id="exampleModalLabel">
						{{ "UI.k_notifications" | translate }}
					</h5>
					<button
						type="button"
						id="btnClose"
						name="btnClose"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						attr.data-testid="'btnClose'"
						(click)="closeSidebar()"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body card-bg">
					<ng-container>
						<ul
							ngbNav
							#navFilled="ngbNav"
							class="nav-tabs mb-0 d-flex border-bottom align-items-center justify-content-space-around"
							id="notify"
						>
						<li ngbNavItem (click) = "onSelectAlerts('alert')">
							<div class="customizer-menu  d-flex justify-content-start c-noti-bedge">
								<a ngbNavLink
									><small><b class="font-small-3 pl-50 pr-50"> {{ "UI.k_alerts" | translate }} </b></small>
									<span class="badge badge-danger font-small-2 ml-50">{{alert_notifications?.length}}</span>
								</a>
							</div>
							<ng-template ngbNavContent>
								<div class="flex-auto-height">
									<div class="flex-1">
										<ng-scrollbar class="custom-scrollbar section-scrollbar">
											<div class="nav-li">
												<ul class="mb-0">
													<li
														*ngFor="let conf of alert_notifications; let i = index"
														[routerLinkActive]="'active'"
														[ngClass]="{ activesidebar: conf?.is_acknowledged }"
														(click)="selectAlertNotification(i)"
													>
														<div class="c-border-left"></div>
														<div class="mt-0 border-bottom"></div>
														<div class="customizer-menu px-2 py-1">
															<div class="d-flex justify-content-start align-items-center">
																<i class="fa-light {{ conf?.config?.icon }} fa-2x"></i>
															<div>
																<span class="contrast-txt ml-1 fw-500"
																	>{{ conf.name | translate }}</span
																>
																<small class="text-muted ml-1 d-block" style="font-size:10px;">
																	{{ conf.time }}
																</small>
															</div>
															</div>
															
															<div class="ml-3 c-mt-6">
																<small class="font-small-2 text-dark">{{ conf?.content | translate }}</small>
															</div>
															<ng-container *ngIf="!conf?.config?.sidebar && !conf?.config?.window && !conf?.config?.download && !conf.modalOpen">
																<button type="button" class="btn btn-primary ml-3 mt-1" 
																[queryParams]="conf.config.queryParams"
																[routerLink]="[conf.config.handle, {queryParams: conf.config.queryParams || {}}]"
																[routerLinkActive]="'active'"
																(click)="sliderOpen()"
																rippleEffect>
																	<i class="fa-light {{ conf?.config?.btnIcon }}"></i>{{ conf?.config?.btnName | translate}}
																</button>
															</ng-container>
															<ng-container *ngIf="conf?.config?.sidebar && !conf?.config?.window">
																<button type="button" class="btn btn-primary ml-3 mt-1" 
																[queryParams]="{ globalSearch: true }"
																[routerLink]="[conf.config.handle]"
																[routerLinkActive]="'active'"
																rippleEffect (click)="sliderOpen()"
																>
																	<i class="fa-light {{ conf?.config?.btnIcon }}"></i>{{ conf?.config?.btnName |translate }}
																</button>
															</ng-container>	
															<ng-container *ngIf="conf?.config?.window">
																<button type="button" class="btn btn-primary ml-3 mt-1" 
																(click)="toggleclose(conf)"
																rippleEffect>
																	<i class="fa-light {{ conf?.config?.btnIcon }}"></i>{{ conf?.config?.btnName | translate }}
																</button>
															</ng-container>	
															<ng-container *ngIf="conf?.config?.download">
																<button type="button" class="btn btn-primary ml-3 mt-1" 
																(click)="downloadFile()"
																rippleEffect>
																	<i class="fa-light {{ conf?.config?.btnIcon }}"></i>{{ conf?.config?.btnName | translate }}
																</button>
															</ng-container>	
															<ng-container *ngIf="conf?.config?.modalOpen && !conf?.config?.download">
																<button type="button" class="btn btn-primary ml-3 mt-1" 
																	(click)="modalOpen(modalBasic)"
																	rippleEffect>
																	<i class="fa-light {{ conf?.config?.btnIcon }}"></i>{{ conf?.config?.btnName | translate }}
																</button>
															</ng-container>	
														</div>
													</li>
												</ul>
											</div>
										</ng-scrollbar>
									</div>
								</div>
							</ng-template>
						</li>

							<!-- <li ngbNavItem (click) = "onTabChange('task')">
								<div class="customizer-menu  d-flex justify-content-start c-noti-bedge">
									<a ngbNavLink
										><small><b class="font-small-3 pl-50 pr-50"> {{ "UI.k_my_task" | translate }} </b></small>
										<span class="badge badge-danger font-small-2 ml-50">{{count}}</span>
									</a>
								</div>
								<ng-template ngbNavContent>
									<div class="flex-auto-height">
										<div class="flex-1">
											<ng-scrollbar class="custom-scrollbar section-scrollbar">
												<div class="nav-li">
													<ul class="mb-0">
														<li
															*ngFor="let conf of notification; let i = index"
															[routerLinkActive]="'active'"
															[ngClass]="{ activesidebar: conf?.isRead }"
															(click)="selectNotification(i)"
														>
															<div class="c-border-left"></div>
															<div class="mt-0 border-bottom"></div>
															<div class="customizer-menu px-2 py-1">
																<div class="d-flex justify-content-start align-items-center">
																	<i class="fa-light {{ conf.icon }} fa-2x"></i>
																<div>
																	<span class="contrast-txt ml-1 fw-500"
																		>{{ conf.name }}</span
																	>
																	<small class="text-muted ml-1 d-block" style="font-size:10px;">
																		{{ conf.time }}
																	</small>
																</div>
																</div>
																
																<div class="ml-3 c-mt-6">
																	<small class="font-small-2 text-dark">{{ conf.description }}</small>
																</div>
																<ng-container *ngIf="conf.isActive && !conf.sidebar && !conf.window && !conf.download && !conf.modalOpen">
																	<button type="button" class="btn btn-primary ml-3 mt-1" 
																	[routerLink]="[conf.handle]"
																	[routerLinkActive]="'active'"
																	(click)="sliderOpen()"
																	rippleEffect>
																		<i class="fa-light {{ conf.btnIcon }}"></i>{{ conf.btnName }}
																	</button>
																</ng-container>
																<ng-container *ngIf="conf.isActive && conf.sidebar && !conf.window">
																	<button type="button" class="btn btn-primary ml-3 mt-1" 
																	[queryParams]="{ globalSearch: true }"
																	[routerLink]="[conf.handle]"
																	[routerLinkActive]="'active'"
																	rippleEffect (click)="sliderOpen()"
																	>
																		<i class="fa-light {{ conf.btnIcon }}"></i>{{ conf.btnName }}
																	</button>
																</ng-container>	
																<ng-container *ngIf="conf?.isActive && conf?.window">
																	<button type="button" class="btn btn-primary ml-3 mt-1" 
																	(click)="toggleclose(conf)"
																	rippleEffect>
																		<i class="fa-light {{ conf.btnIcon }}"></i>{{ conf.btnName }}
																	</button>
																</ng-container>	
																<ng-container *ngIf="conf?.isActive && conf.download">
																	<button type="button" class="btn btn-primary ml-3 mt-1" 
																	(click)="downloadFile()"
																	rippleEffect>
																		<i class="fa-light {{ conf.btnIcon }}"></i>{{ conf.btnName }}
																	</button>
																</ng-container>	
																<ng-container *ngIf="conf?.isActive && conf.modalOpen && !conf.download">
																	<button type="button" class="btn btn-primary ml-3 mt-1" 
																		(click)="modalOpen(modalBasic)"
																		rippleEffect>
																		<i class="fa-light {{ conf.btnIcon }}"></i>{{ conf.btnName }}
																	</button>
																</ng-container>	
															</div>
														</li>
													</ul>
												</div>
											</ng-scrollbar>
										</div>
									</div>
								</ng-template>
							</li> -->
							<!-- <li ngbNavItem class="p-0" (click) = "onTabChange('information')">
								<a ngbNavLink class="c-noti-bedge pl-50 pr-50"
									><small><b class="font-small-3">Information</b></small>
									<span class="badge badge-danger font-small-2 ml-50">{{count}}</span>
								</a>
								<ng-template ngbNavContent>
									<div class="flex-auto-height">
										<div class="flex-1">
											<ng-scrollbar class="custom-scrollbar section-scrollbar">
												<div class="nav-li">
													<ul class="mb-0">
														<li
															*ngFor="let conf of notification; let i = index"
															[routerLink]="[conf.handle]"
															[routerLinkActive]="'active'"
															[ngClass]="{ activesidebar: i == 0 }"
															(click)="selectNotification(i)"
															id="notification"
														>
															<div class="c-border-left"></div>
															<div class="mt-0 border-bottom"></div>
															<div class="customizer-menu px-2 py-1">
																<div class="d-flex justify-content-start align-items-center">
																	<div class="avatar noti-avtar">
																		<img src="https://cdn.pixabay.com/photo/2023/01/14/13/17/bellflower-7718193_640.jpg" class="mw-100">
																	</div>
																	<div>
																		<span class="contrast-txt ml-1 fw-500"
																			>{{ conf.name }}</span
																		>
																		<small class="text-muted ml-1 d-block" style="font-size:10px;">
																			{{ conf.time }}
																		</small>
																	</div>
																</div>
																
																<div class="ml-4 c-mt-6">
																	<small class="font-small-2 text-dark">{{ conf.description }}</small>
																</div>
																<div *ngIf="conf.isActive" class="ml-4 mt-1 d-flex">
																	<button type="button" class="btn btn-outline-primary c-mr-12" rippleEffect
																	>
																		 Button
																	</button>
																	<button type="button" class="btn btn-primary" rippleEffect
																	>
																		Button
																	</button>
																</div>	
															</div>
														</li>
													</ul>
												</div>
											</ng-scrollbar>
										</div>
									</div>
								</ng-template>
							</li> -->
							<li ngbNavItem class="p-0" (click)="onTabChange('new-feature')">
								<a ngbNavLink class="c-noti-bedge"
									><small
										><b class="font-small-3">{{ "UI.k_whats_new" | translate }}</b></small
									>
									<span class="badge badge-danger font-small-2 ml-50" *ngIf="newFeatureCount > 0">{{
										newFeatureCount
									}}</span>
								</a>
								<ng-template ngbNavContent>
									<!-- dynamic rows -->

									<div class="flex-auto-height">
										<div class="flex-1">
											<ng-scrollbar class="custom-scrollbar section-scrollbar">
												<div class="nav-li">
													<div
														*ngIf="!notificationData"
														class="d-flex justify-content-center align-items-center height-400"
													>
														<div
															class="spinner-border text-primary"
															aria-hidden="true"
														></div>
													</div>
													<ul class="mb-0">
														<li
															*ngFor="let conf of notificationData; let i = index"
															[routerLinkActive]="'active'"
															id="notification-item{{ i }}"
															attr.data-testid="'notification-item'"
															class="border-bottom"
															[ngClass]="{ activesidebar: !conf?.isRead }"
															(click)="selectNotificationItem(i, conf)"
														>
															<div class="c-border-left"></div>

															<div
																class="notification-menu gap-1 p-50 d-flex justify-content-start align-items-center"
															>
																<div class="content-image">
																	<img [src]="conf.imgSrc" alt="" />
																</div>
																<div>
																	<span class="contrast-txt fw-500">{{
																		conf.name
																	}}</span>
																	<span
																		class="text-muted d-block"
																		style="font-size: 10px"
																	>
																		{{ conf.time }}
																	</span>
																	<div class="">
																		<small class="font-small-2 text-dark">{{
																			conf.description
																		}}</small>
																	</div>
																	<div class="content-footer" *ngIf="conf.url">
																		<a
																			><span
																				class="text-primary sm-font cursor-pointer"
																				(click)="rediectTOContent(conf.url)"
																				>{{
																					"UI.k_read_more" | translate
																				}}</span
																			></a
																		>
																	</div>
																</div>
															</div>
														</li>
													</ul>
													<ng-container *ngIf = "notificationData.length === 0">
														<div class="noNotification">
															<div class="d-flex justify-content-center align-items-center">
																<img id="noNotificationImg" src="../../../../../assets/images/empty-notification (2).svg" alt="">
															</div>
															<div class="noContentData text-center mt-1">
																<div class="content-header">
																	<span class="title-color">{{"UI.k_no_notification" | translate}}</span>
																</div>
																<div class="content-body mt-50">
																	<span class="text-muted">{{"Message.msg_no_notification" | translate}}</span> <span class="text-muted">{{"Message.msg_come_back_later" | translate}}</span>
																</div>
															</div>
														</div>
													</ng-container>
												</div>
											</ng-scrollbar>
										</div>
									</div>
								</ng-template>
							</li>
						</ul>
						<div [ngbNavOutlet]="navFilled"></div>
					</ng-container>
				</div>
				<div class="model-footer">
					<ng-container *ngIf="currentTab === 'task' || currentTab === 'information'">
						<div class="flex-initial align-items-center flex-column align-items-center flex-column">
							<div class="d-flex justify-content-center pt-1">
								<button
									type="button"
									class="btn btn-primary"
									id="btnNotify"
									name="btnNotify"
									rippleEffect
								>
									{{ "UI.k_show_all_notification" | translate }}
								</button>
							</div>
							<div class="d-flex justify-content-center my-1 align-items-center">
								<i class="fa fa-cog text-muted" aria-hidden="true"></i>
								<div class="sm-font text-center ml-50">
									<a class="text-muted" (click)="funcManage()">
										{{ "UI.k_manage_notification" | translate }}</a
									>
								</div>
							</div>
						</div>
					</ng-container>

					<ng-container *ngIf="currentTab === 'new-feature'">
						<div
							class="flex-initial align-items-center flex-column new-feature-footer cursor-pointer align-items-center flex-column"
							(click)="redirectToBlog()"
						>
							<div class="d-flex align-items-center justify-content-start">
								<div class="footer-img">
									<img src="../../../../../assets/images/notification/footer-image.svg" alt="" />
									<div class="overlay"></div>
								</div>
								<div class="footer-content">
									<span>{{ "Message.msg_notification_content" | translate }}</span>
									<div>
										<span class="fw-400 xs-font"
											>{{ "UI.k_read_more" | translate }}<i class="fal fa-arrow-right ml-25"></i
										></span>
									</div>
								</div>
								<div class="sideimage">
									<img src="../../../../../assets/images/notification/footer-image-icon.svg" alt="" />
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</div>

	<!-- <ng-scrollbar class="custom-scrollbar section-scrollbar"> -->
	<!-- Customizer header -->
	<!-- <div
			class="customizer-header px-2 pt-2 pb-0 mb-0 position-relative d-flex align-items-center justify-content-between"
		>
			<h4 class="mb-0"><b>{{ "UI.k_notifications" | translate }}</b></h4>

			<a (click)="toggleSidebar('notification')"><span [data-feather]="'x'" [size]="16"></span></a>
		</div> -->
	<!-- Nav tabs -->

	<!-- </ng-scrollbar> -->
</div>

<ng-template #modalBasic let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="myModalLabel1">Basic Modal</h4>
		<button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body" tabindex="0" ngbAutofocus>
		<h5>Check First Paragraph</h5>
		<p>
			Oat cake ice cream candy chocolate cake chocolate cake cotton candy dragée apple pie. Brownie carrot cake
			candy canes bonbon fruitcake topping halvah. Cake sweet roll cake cheesecake cookie chocolate cake
			liquorice.
		</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="modal.close('Accept click')" rippleEffect>Accept</button>
	</div>
</ng-template>
