import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { AdminRoutingModule } from "./admin-routing.module";
import { AdminDashboardComponent } from "./components/admin-dashboard/admin-dashboard.component";
import { NgScrollbarModule } from "ngx-scrollbar";
import { CorePipesModule } from "@core/pipes/pipes.module";
import { DashboardInstanceComponent } from "./components/dashboard-instance/dashboard-instance.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { DashboardInstanceProductsComponent } from "./components/dashboard-instance-products/dashboard-instance-products.component";
import { DashboardInstanceInvoicesComponent } from "./components/dashboard-instance-invoices/dashboard-instance-invoices.component";
import { DashboardInstanceSecurityComponent } from "./components/dashboard-instance-security/dashboard-instance-security.component";
import { DashboardInstanceSettingsComponent } from "./components/dashboard-instance-settings/dashboard-instance-settings.component";
import { DnsModule } from "app/common/dns/dns.module";
import { ProductDetailComponent } from "./components/product-detail/product-detail.component";
import { PaymentComponent } from "./components/payment/payment.component";
import { SharedModule } from "app/shared.module";
import { UsersComponent } from "./components/users/users.component";
import { HistoryComponent } from "./components/history/history.component";
import { GetInTouchComponent } from "./components/get-in-touch/get-in-touch.component";
import { AnimatedCounterComponent } from "./components/animated-counter/animated-counter.component";
import { CoreTouchspinModule } from "@core/components/core-touchspin/core-touchspin.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule } from "@angular/forms";
import { AddLoginDetailsSidebarComponent } from "./components/add-login-details-sidebar/add-login-details-sidebar.component";
import { CoreSidebarModule } from "@core/components";
import { PricingModalComponent } from "./components/pricing-modal/pricing-modal.component";
import { TrimPlanPipe } from "./pipes/product.pipe";

import { AdminLoginComponent } from "./components/admin-login/admin-login.component";
import { EditAdminUserComponent } from "./components/edit-admin-user/edit-admin-user.component";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { CongratulationsComponent } from "./components/congratulations/congratulations.component";
import { AdminSkeletonComponent } from "./components/admin-skeleton/admin-skeleton.component";
import { PremiumPlanSkeletonComponent } from "./components/premium-plan-skeleton/premium-plan-skeleton.component";
import { PaymentSkeletonComponent } from "./components/payment-skeleton/payment-skeleton.component";
import { FeaturesSkeletonComponent } from "./components/features-skeleton/features-skeleton.component";
import { InvoicesComponent } from "./components/invoices/invoices.component";
import { SocialLoginModule, SocialAuthServiceConfig, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
// import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
// import { environment } from 'environments/environment';

@NgModule({
	declarations: [
		AdminDashboardComponent,
		DashboardInstanceComponent,
		DashboardInstanceProductsComponent,
		DashboardInstanceInvoicesComponent,
		DashboardInstanceSecurityComponent,
		DashboardInstanceSettingsComponent,
		ProductDetailComponent,
		PaymentComponent,
		UsersComponent,
		HistoryComponent,
		GetInTouchComponent,
		AnimatedCounterComponent,
		AddLoginDetailsSidebarComponent,
		PricingModalComponent,
		TrimPlanPipe,
		AdminLoginComponent,
		EditAdminUserComponent,
		CongratulationsComponent,
		AdminSkeletonComponent,
		PremiumPlanSkeletonComponent,
		PaymentSkeletonComponent,
		FeaturesSkeletonComponent,
		InvoicesComponent,
	],
	imports: [
		CommonModule,
		AdminRoutingModule,
		NgScrollbarModule,
		TranslateModule,
		CorePipesModule,
		NgbModule,
		SharedModule,
		DnsModule,
		CoreTouchspinModule,
		FormsModule,
		NgSelectModule,
		CoreSidebarModule,
		NgxIntlTelInputModule,
		GoogleSigninButtonModule
		// SocialLoginModule
	],
	exports: [GetInTouchComponent, InvoicesComponent, PricingModalComponent],
})
export class AdminModule {}
