<div class="slideout-content default-slideout">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog">
            <div class="modal-content add-new-user pt-0">
                <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="closeSidebar('app-request-incident-view')">
                    ×
                </button> -->

                <div class="modal-header" *ngIf="dataLoaded">
                    <h5 *ngIf="incident_data?.is_deleted == false" class="modal-title" id="exampleModalLabel">{{"UI.k_ticket" | translate}}</h5>
                    <h5 *ngIf="incident_data?.is_deleted == true" class="modal-title" id="exampleModalLabel">{{"UI.k_ticket" | translate}} 
                        <span class="text-primary">
                            &nbsp;#{{incident_data?.display_id}}
                        </span>
                    </h5>
                    <button
                    type="button"
                    id="btnClose"
                    name="btnClose"
                    class="btn btn-flat-secondary btn-icon"
                    data-dismiss="modal"
                    aria-label="Close"
                    [attr.data-testid]="'btnClose'"
                    (click)="closeSidebar('app-request-incident-view')"
                >
                    <i class="fa-light fa-xmark"></i>
                </button>
                </div>
                <div class="modal-body">
                    <ng-scrollbar class="custom-scrollbar section-scrollbar">
                        <div class="p-1">
                            <div *ngIf="!dataLoaded" class="d-flex justify-content-center align-items-center height-400">
                                <div class="spinner-border text-primary" aria-hidden="true"></div>
                            </div>
                        </div>
                        <ng-container *ngIf="dataLoaded">
                            <div class="kb-search-content-info match-height p-half" *ngIf="incident_data?.is_deleted == false && dataLoaded" >
                                <!-- <div class="col-12 kb-search-content">
                                    <div class="card">
                                        <a routerLink="/incident-edit/{{ incident_data?.incident_id }}" target="_blank">
                                            <img
                                                class="card-img-top img-fluid height-100"
                                                [src]="kbSolution?.imag
                                                        ? kbSolution?.imag
                                                        : 'assets/images/illustration/email.svg'
                                                "
                                                alt="Related Post Pic"
                                                            />
                                            <div class="card-body text-center">
                                                <h4>{{ kbSolution?.title }}</h4>
                                                <p class="text-body mt-1 mb-0">{{ kbSolution?.content }}</p>
                                                <div class="text-muted mb-0">
                                                    {{  kbSolution?.last_update_time }}
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>                     -->
            
                                <div class="card border p-2">
                                    <div class="d-flex flex-wrap align-items-center">
                                        <div class="pr-1 mr-auto mb-50">
                                            <a routerLink="/incident_view/incident-edit/{{ incident_data?.incident_id }}" target="_blank">
                                                <div class="text-primary font-weight-bold text-uppercase">{{incident_data?.display_id}}</div>
                                            </a>
                                        </div>
                                        <div class="d-flex align-items-center mb-50 sm-font mb-2">
                                            <!-- <span class="bullet bullet-primary bullet-sm rounded-sm mr-50"></span>
                                            <span class="mr-50">{{incident_data?.basic_info?.state?.name }}</span> -->
                                            <div
                                                class="custom-squre"
                                                [ngClass]="'bg-' + incident_data?.basic_info?.status?.color"
                                                [ngStyle]="{
                                                    'background-color':
                                                    incident_data?.basic_info?.status?.color
                                                }"
                                            ></div>
                                            <span>{{ incident_data?.basic_info?.status?.name | translate
                                            }}</span>
                                            <div
                                                class="right-arrow custom-text"
                                                [ngStyle]="{
                                                    'background-color':
                                                    incident_data?.basic_info?.status?.background_color,
                                                    color:
                                                    incident_data?.basic_info?.status?.text_color ||
                                                    incident_data?.basic_info?.status?.color
                                                }"
                                            >
                                                {{ incident_data?.basic_info?.status?.state | translate }}
                                            </div>
                                            <!-- <div class="badge badge-light-warning">{{incident_data?.basic_info?.status?.name}}</div> -->
                                        </div>
                                    </div>
                                    <div class="d-flex flex-wrap align-items-center sm-font mb-1">
                                        <div class="pr-1 mr-auto mb-50">
                                            <div>{{incident_data?.basic_info?.summary}}</div>
                                        </div>
                                        <div class="mb-50 gont-sm">
                                            <span>{{ "UI.k_created_on" | translate }} : {{incident_data?.creation_time}}</span>
                                        </div>
                                    </div>
                                    <div class="mb-2">
                                        <div class="bg-body p-1 mb-1">
                                            <h6 class="font-weight-bolder mb-0">{{ "UI.k_executive_summary" | translate }}</h6>
                                        </div>
                                        <div>
                                            <div class="row px-1">
                                                <div class="col-sm-6">
                                                    <span class="sm-font title-color">{{ "UI.k_catalogue" | translate }}</span>
                                                    <div class="mb-2 font-weight-bold">{{incident_data?.basic_info?.catalogue_name}}</div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <span class="sm-font">{{ "UI.k_category" | translate }}</span>
                                                    <div class="mb-2 font-weight-bold">{{incident_data?.basic_info?.category_name}}</div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <span class="sm-font">{{ "UI.k_service" | translate }}</span>
                                                    <div class="mb-2 font-weight-bold">{{incident_data?.basic_info?.impact_service_name}}</div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <span class="sm-font">{{ "UI.k_classification" | translate }}</span>
                                                    <div class="mb-2 font-weight-bold">{{incident_data?.basic_info?.service_classification_name}}</div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <span class="sm-font">{{ "UI.k_description" | translate }}</span>
                                                    <div class="mb-2 font-weight-bold" [innerHTML]="incident_data?.basic_info?.description | striphtml"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-2">
                                        <div class="bg-body p-1 mb-1">
                                            <h6 class="font-weight-bolder mb-0">{{ "UI.k_inci_detail" | translate }}:</h6>
                                        </div>
                                        <div>
                                            <div class="row px-1">
                                                <div class="col-sm-12">
                                                    <span class="sm-font title-color">{{ "UI.k_customer_details" | translate }}</span>
                                                    <div class="mb-25 font-weight-bold">{{incident_data?.requester?.full_name}}
                                                    </div>
                                                    <div class="d-flex align-items-center flex-wrap mb-2">
                                                        <a [href]="'mailto:'+incident_data?.requester?.email"
                                                            class="mr-1 sm-font text-body">
                                                            <i class="fa-light fa-envelope mr-50"></i>
                                                            <span>{{incident_data?.requester?.email}} </span>
                                                        </a>
                                                        <a class="mr-1 sm-font text-body" *ngIf="incident_data?.requester?.phone_number?.internationalNumber">
                                                            <i class="fa-light fa-phone mr-50"></i>
                                                            <span>{{incident_data?.requester?.phone_number?.internationalNumber}}</span>
                                                        </a>
                                                        <a  class="mr-1 sm-font text-body" target="_blank" *ngIf="location_name">
                                                            <i class="fa-light fa-location-dot mr-50"></i>
                                                            <span>{{location_name}}</span>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <span class="sm-font title-color">{{ "UI.k_incident_source" | translate }}</span>
                                                    <div class="mb-2 font-weight-bold">{{incident_data?.basic_info?.incident_source?.name}}</div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <span class="sm-font title-color">{{ "UI.k_incident_type" | translate }}</span>
                                                    <div class="mb-2 font-weight-bold">{{ incident_data?.basic_info?.incident_type?.name}}</div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <span class="sm-font title-color">{{ "UI.k_impact" | translate }}</span>
                                                    <div class="mb-2 font-weight-bold">{{incident_data?.basic_info?.impact?.display_key | translate }}</div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <span class="sm-font title-color">{{ "UI.k_priority" | translate }}</span>
                                                    <div>
                                                        <div  class="badge badge-light-{{
                                                            incident_data?.basic_info?.priority?.color
                                                        }}"><i
                                                                class="fa-light mr-25 {{
                                                                    this.incident_data?.basic_info?.priority?.icon
                                                                }}"></i>{{
                                                                    this.incident_data?.basic_info?.priority?.display_key
                                                                        | translate
                                                                }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <span class="sm-font title-color">{{ "UI.k_urgency" | translate }}</span>
                                                    <div>
                                                        <div  class="badge badge-light-{{
                                                            incident_data?.basic_info?.urgency?.color
                                                        }}"><i
                                                                class="fa-light mr-25 {{
                                                                    this.incident_data?.basic_info?.urgency?.icon
                                                                }}"></i>{{
                                                                    this.incident_data?.basic_info?.urgency?.display_key
                                                                        | translate
                                                                }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-2" *ngIf="incident_data?.current_assignment_info?.group_name">
                                        <div class="bg-body p-1 mb-1">
                                            <h6 class="font-weight-bolder mb-0">{{ "UI.k_team" | translate }}:</h6>
                                        </div>
                                        <div>
                                            <div class="row px-1">
                                                <div class="col-sm-6">
                                                    <span class="sm-font title-color">{{ "UI.k_group_name" | translate }}</span>
                                                    <div class="mb-2 font-weight-bold">{{incident_data?.current_assignment_info?.group_name}}</div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <span class="sm-font title-color">{{ "UI.k_level" | translate }}</span>
                                                    <div class="mb-2 font-weight-bold">{{incident_data?.current_assignment_info?.level}}</div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <span class="sm-font title-color">{{ "UI.k_expertise" | translate }}</span>
                                                    <div class="mb-2 font-weight-bold">{{incident_data?.current_assignment_info?.expertise}}</div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <span class="sm-font title-color">{{ "UI.k_assigned_to" | translate }}</span>
                                                    <div class="mr-auto d-flex align-items-center">
                                                        <div class="avatar avatar-sm mr-50" *ngIf="incident_data?.current_assignee_profile?.profile_image">
                                                            <div class="avatar-content">
                                                                <img [src]="incident_data?.current_assignee_profile?.profile_image" alt="User" />
                                                            </div>
                                                        </div>
                                                        <div class="avatar avatar-xs mr-50" *ngIf="!incident_data?.current_assignee_profile?.profile_image" [ngStyle]="{
                                                            'background-color': incident_data
                                                                ?.current_assignee_profile?.avatar_color
                                                                ? incident_data?.current_assignee_profile
                                                                        ?.avatar_color
                                                                : '#f59518'
                                                        }"
                                                    >
                                                            <div class="avatar-content">
                                                                {{
                                                                    incident_data?.current_assignee_profile?.full_name
                                                                        | initials
                                                                        | slice: O:2
                                                                }}
                                                            </div>
                                                        </div>
                                                        <span class="sm-font title-color">{{incident_data?.current_assignee_profile?.full_name}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            
                            <!-- kb deleted -->
                            <ng-container *ngIf="incident_data?.is_deleted == true">
                                <div class="col-12 text-center no-result">
                                    <h4 class="mt-4">
                                        {{"Message.msg_ticket_removed" | translate}}
                                    </h4>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-scrollbar>
                </div>


            </div>
        </div>
    </div>
</div>
