<!-- in-active page-->
<!-- <div class="bg-img"></div> -->
<!-- <div class="misc-wrapper invalid-img align-items-start">
	<a class="brand-logo" href="javascript:void(0);">
		<img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="40" />
	</a>
	<div class="misc-inner top-padding">
		<div class="text-center">
			<h2 class="mb-1 fw-600 font-35">Oops! This account is Frozen 🙁</h2>
			<p class="mb-2 mx-auto font-24" *ngIf="!isAdmin">We can't log you in until your account is renewed.</p>
			<div>
				No worries - your data is safe and sound.
				<span *ngIf="!isAdmin">We've notified the administrator(s).</span>
			</div>
			<div class="mb-2 mx-auto" *ngIf="!isAdmin">Meanwhile you may want to reach out to them directly.</div>
			<div class="mx-auto w-50 mb-half" *ngIf="!isAdmin">Your account administrators:</div>
			<div *ngIf="!isAdmin">
				<div *ngFor="let item of admins; let i = index" class="">
					<span class="font-medium-1 fw-500">{{ item.name }}</span>
				</div>
			</div>
			<div class="mx-auto mt-half" *ngIf="isAdmin">Please renew your license to continue</div>
			<div class="d-flex justify-content-center mt-half" *ngIf="isAdmin">
				<button
					id="btnSubscribe"
					name="btnSubscribe"
					class="btn btn-primary mb-1 mb-sm-0 mr-1 mt-2 pull-right"
					(click)="goToSubscription()"
					rippleEffect
				>
					<span class="align-middle d-sm-inline-block d-none font-medium-2"> Subscribe </span>
				</button>
			</div>
		</div>
	</div>
</div> -->

<!-- in-active page-->
<!-- <div class="in-active-page">
	<div class="misc-wrapper">
		<a class="brand-logo" href="javascript:void(0);">
			<img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="40" />
		</a>
		<div class="misc-inner d-flex justify-content-center align-items-center flex-column">
			<div class="wave-gif mb-2">
				<img src="../../../../../assets/images/in-active/Wire Cut" alt="brand-logo" height="40" />
			</div>
			<ng-container *ngIf="!isUserloggedIn">
				<div class="w-100 text-center">
					<h2 class="mb-1">Oops! This account is Frozen</h2>
					<p class="">We can't log you in until your account is renewed.</p>
					<div class="mb-2">
						No worries - your data is safe and sound. We've notified the administrator(s).<br> Meanwhile you may want to reach out to them directly.
					</div>
					<div class="mx-auto w-50">Your account administrators:</div>
					<div class="administratorsList">
						<ul class="m-0 p-0">
							<li>Arun Prasath</li>
							<li>Satish Kumar</li>
							<li>Prakash Bhat</li>
						</ul>
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="isUserloggedIn">
				<div class="w-100 text-center">
					<h2 class="mb-1">Oops! This account is Frozen</h2>
					<p class="">No worries - your data is safe and sound.<br>
						Please renew your license to continue.</p>
					<div class="mt-2">
						<button
							type="button"
							class="btn btn-outline-tertiary"
							id="btndesktop"
							name="btndesktop"
							tooltipClass="fadeInAnimation"
							ngbTooltip="{{ 'UI.k_subscribe' | translate }}"
							placement="bottom"
						>
						{{'UI.k_subscribe' | translate}}
						</button>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="footer"></div>
</div> -->

<div class="misc-wrapper">
	<a class="brand-logo" href="javascript:void(0);">
		<img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="40" />
	</a>
	<div class="inactive-sec">
		<div class="inactive">
			<div class="inactive-inner">
				<div class="inactive-info">
					<h2 class="mb-1 fw-600 font-35">{{ "Message.msg_account_frozen" | translate }}</h2>
					<p class="mb-2 mx-auto font-24" *ngIf="!isAdmin">
						{{ "Message.msg_login_prevent" | translate }}
					</p>
					<p>
						{{ "Message.msg_data_safe" | translate }}
						<span *ngIf="!isAdmin">{{ "Message.msg_admin_notified" | translate }}</span>
					</p>
					<p class="mb-2 mx-auto" *ngIf="!isAdmin">{{ "Message.msg_reach_admin" | translate }}</p>
					<p class="mx-auto mb-half" *ngIf="!isAdmin">{{ "Message.msg_account_admins" | translate }}</p>
					<ng-container *ngIf="!isAdmin">
						<div *ngFor="let item of admins; let i = index" class="">
							<span class="lg-font fw-500">{{ item.name }}</span>
						</div>
					</ng-container>
					<p class="mx-auto mt-half" *ngIf="isAdmin">{{ "Message.msg_renew_lic" | translate }}</p>
					<div class="d-flex justify-content-center mt-half" *ngIf="isSuperAdminUser">
						<button
							id="btnSubscribe"
							name="btnSubscribe"
							class="btn btn-primary mb-1 mb-sm-0 mr-1 mt-2 pull-right"
							(click)="goToSubscription($event)"
							rippleEffect
						>
							<span class="align-middle"> {{ "UI.k_subscribe" | translate }} </span>
						</button>
					</div>
				</div>
				<div class="bottom-img-section">
					<img src="../../../../../assets/images/inactive/inactive-cat.gif" alt="" />
				</div>
			</div>
		</div>
	</div>
</div>
<!-- / in-active page-->
