import { Component, EventEmitter, Input, OnInit, Output, HostListener, ViewChild } from "@angular/core";
import { FlatpickrOptions } from "ng2-flatpickr";
import { ReleaseService } from "app/servicedesk/release-manage/services/release-services.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { catchError, map, throwError } from "rxjs";
import { customSearch } from "app/common/utils/utils";
import { InfinityModuleIDs } from "app/common/dns/types/module_type";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";

@Component({
  selector: 'app-dns-release-add-task',
  templateUrl: './dns-release-add-task.component.html',
  styleUrls: ['./dns-release-add-task.component.scss']
})
export class DnsReleaseAddTaskComponent implements OnInit {
  @Input() options: any = [];
	@Input() release_data: any = {};
	@Input() parentTask: any = {};
	@Output() onHideTask = new EventEmitter(false);
	@Input() type_id;
	@ViewChild("datepicker") dueDate: FlatpickrOptions;
	public task_obj: any = {};
	public disableSaveBtn: boolean = false;
	module_id = InfinityModuleIDs?.RELEASE;
	typeMapping = {
		1: "UI.k_add_task",
		2: "UI.k_add_feature",
		3: "UI.k_add_issue",
	};
	aboutTaskMapping = {
		1: "UI.k_about_task",
		2: "UI.k_about_feature",
		3: "UI.k_about_issue",
	};
	public basicDateOptions: FlatpickrOptions = {
		altInput: true,
		altInputClass: "form-control flat-picker flatpickr-input",
	};
	public basicDateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime:true,
		altInputClass: "form-control flat-picker flatpickr-input",
	};

	constructor(
		private _toastr: ToastrService,
		private _translateService: TranslateService,
		private _releaseeService: ReleaseService,
    private _coreSidebarService: CoreSidebarService,
	) {}

	ngOnInit(): void {
		this.task_obj["ref_id"] = this.release_data?.release_id;
		this.task_obj["ref_display_id"] = this.release_data?.display_id;
		this.task_obj["module_id"] = this.module_id
		if (this.options?.priority?.length > 0) {
			this.task_obj["priority"] = this.options?.priority[0];
		}
		if (this.options?.task_status?.length > 0) {
			this.task_obj["status"] = this.options?.task_status[0];
		}
		if (this.release_data?.due_date) {
			this.basicDateTimeOptions["maxDate"] = this.release_data?.due_date;
		}
	}

	toggleSidebar(name) {
    this._coreSidebarService.getSidebarRegistry(name).close();
		this.onHideTask.emit();
	}

	SaveTask = (data) => {
		if (data.form.valid === true) {
			if (this.task_obj.due_date instanceof Array) {
				this.task_obj.due_date = this.task_obj.due_date[0];
				// this.task_obj.due_date = this.task_obj.due_date.toDateString();
			}
			this.disableSaveBtn = true;
			this.task_obj["type_id"] = this.type_id; //  for task
			if (this.parentTask?.task_id) {
				this.task_obj["parent_obj"] = {
					task_id: this.parentTask?.task_id,
					display_id: this.parentTask?.display_id,
				};
			}
			this._releaseeService
				.saveReleaseTask(this.task_obj)
				.pipe(
					map((response: any) => {
						this.disableSaveBtn = false;
						if (response) {
							if (response["status"] == "success") {
								this.task_obj = {};
								this._toastr.success(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Message.msg_save_success"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
								this.toggleSidebar('add-task');
							} else {
								this._toastr.error(
									this._translateService.instant(response["message"], {
										existing_rule: response?.data?.existing_rule,
									}),
									this._translateService.instant("Error.err_save_failed"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							}
						}
						// Success
					}),
					catchError((error) => {
						this._toastr.error(
							this._translateService.instant("Error.err_save_failed"),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						this.disableSaveBtn = false;
						return throwError(error.statusText);
					})
				)
				.subscribe();
		}
	};

	onStatusChange = (data) => {
		this.task_obj.status = data;
	};

	statusBadgeChange = (data) => {
		this.task_obj[data["field"]] = data?.event ? data.event : {};
	};

	customSearchFn(term: string, item: any) {
		return customSearch(term, item, "full_name", "email");
	}

	onAssigneeChange = ($event) => {
		this.task_obj["assignee_profile"] = {
			profile_id: $event?.profile_id,
			name: $event?.full_name,
			full_name: $event?.full_name,
			email: $event?.email,
		};
	};
	@HostListener("keydown", ["$event"]) //keydown, not keyup
	onKeyDown(event: any) {
		if (event.keyCode === 13 && event.target?.tagName != "BUTTON") {
			// 13 = enter
			event.preventDefault();
			return false;
		}
	}
	nameExtractorHandler(data: any) {
		return data
			?.split(" ")
			.map((n) => n[0])
			.join("");
	}

	ngOnDestroy() {
		this.dueDate?.flatpickr.close();
	}
}
