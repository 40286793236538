<div

class="flex-fill mb-1"
>
<div class="d-flex ml-50 p-0">
    <span class="mr-50 pt-50 ">{{
        "UI.k_to" | translate
    }}<span class="required-field">&nbsp;*</span></span>

    <div class="flex-grow-1">
        <ng-select
            [items]="initOptions.user_list"
            [addTag]="true"
            [multiple]="true"
            [hideSelected]="true"
	    bindLabel="email"
            bindValue="email"
            id="txtToAddress"
            name="txtToAddress"
            addTagText="{{ 'UI.k_add_item' | translate }}"
            [(ngModel)]="emailConfig.to_addr"
            placeholder="{{ 'UI.k_email_placeholder' | translate }}"
            [appEmailCheck]="emailConfig.to_addr"
            notFoundText="{{'UI.k_no_items_found'| translate }}"
            #TDEmailRef="ngModel"
						required
						[class.error]="
							!TDEmailRef.valid && this.checkEmailVal
						"
            (ngModelChange)="emailEvent($event,TDEmailRef.valid)"
            hideSelected="true"
        >
        </ng-select>
        <span id="formMsg"
        *ngIf="this.checkEmailVal"
        class="invalid-form">
        <small
            class="form-text text-danger"
            *ngIf="TDEmailRef.errors?.invalidemail || !TDEmailRef.valid"
            >{{"UI.k_email_error" | translate}}</small
        >
        <small class="form-text text-danger"
    *ngIf="TDEmailRef.errors?.max_email">{{"UI.k_email_max_validation"	| translate:{"maxLimit": TDEmailRef.errors?.limit} }}
        </small>
</span>
    </div>
    <div
        *ngIf="!isShownCc"
        class="ml-50 pt-50"
    >
        <span
            class="text-primary cursor-pointer"
            id="spbtnCC"
            (click)="CC()"
            >{{ "UI.k_cc" | translate }}</span
        >
    </div>
</div>

<div
    *ngIf="isShownCc"
    class="mt-1"
>
    <div class="d-flex p-0 ml-50">
        <span
            class="cursor-pointer pt-50 mr-50"
            id="spbtntoggleCC"
            >{{ "UI.k_cc" | translate }}<span class="required-field">&nbsp; </span></span
        >
        <div class="form-group flex-grow-1 mb-0">
            <ng-select
                 [items]="initOptions.user_list"
                [addTag]="true"
                [multiple]="true"
                [hideSelected]="true"
		bindLabel="email"
                bindValue="email"
                id="txtCCAddress"
                name="txtCCAddress"
                addTagText="{{ 'UI.k_add_item' | translate }}"
                [(ngModel)]="emailConfig.cc_addr"
                placeholder="{{ 'UI.k_email_placeholder' | translate }}"
                [appEmailCheck]="emailConfig.cc_addr"
                notFoundText="{{'UI.k_no_items_found'| translate }}"
                #TDCCEmailRef="ngModel"
                            required
                            [class.error]="
                                !TDCCEmailRef.valid && this.checkCCEmailVal
                            "
                (ngModelChange)="CCEvent($event,TDCCEmailRef.valid)"
                hideSelected="true"
            >
            </ng-select>    
            <span id="formCCMsg"
            *ngIf="this.checkCCEmailVal"
            class="invalid-form">
            <small
                class="form-text text-danger"
                *ngIf="TDCCEmailRef.errors?.invalidemail || !TDCCEmailRef.valid"
                >{{"UI.k_email_error" | translate}}</small
            >
    </span>
                   
        </div>
        <button
            class="
                btn btn-icon btn-flat-secondary
                text-body
                p-50
                ml-50
            "
            id="btnselectCC"
            (click)="CC()"
        >
            <i class="fa-light fa-xmark"></i>
        </button>
    </div>
</div>
</div>

<div
class="
    compose-mail-form-field
   d-flex
   mb-1
   position-relative
"
>
<label
    for="emailSub"
    class="form-label  mr-50 pt-50"
    style="display: flex;align-items: center;"
    >{{ 'UI.k_subject' | translate}}<span class="required-field">&nbsp;*</span>
</label>
<input
    type="text"
    autocomplete="disabled"
    id="emailSub"
    name="emailSub"
    #TDEmailSub="ngModel"
    class="form-control font-small-2"
    [placeholder]="subPlaceholder"
    [(ngModel)]="emailConfig.subject"
    (ngModelChange)="emailSubjectEvent($event)"

    required

/>
<!-- minlength="3" -->
<!-- maxlength="1000" -->
<ng-container *ngIf="(emailConfig.subject && emailConfig.subject.indexOf('{{') > -1 && this.showMacroSection === true) || (this.emailConfig['quillText'] && this.showMacroSection === true)">
<app-macros-modal class="position-absolute" [inputConfig]="{options: macros,macrosConfig: macros, currentEdit: this.emailEdit === true ? 'emailEdit': 'quillEdit'}" (macrosEmitEvent)="macrosSelectEvent($event)"></app-macros-modal>
</ng-container>
<!-- <div class="macros-modal" *ngIf="(emailConfig.subject && emailConfig.subject.indexOf('{{') > -1 && this.showMacroSection === true) || (this.emailConfig['quillText'] && this.showMacroSection === true)">
    <div class="macros-head">
      <h6>Macros</h6>
      <i class="fa-light fa-circle-question"></i>
    </div>
    <div class="macros-list">
      <ng-scrollbar class="custom-scrollbar section-scrollbar">
        <ng-container *ngFor="let item of macros.group">
          <span class="list-title">{{ item.title }}</span>
          <ul class="group-list">
            <ng-container *ngFor="let i of item.macrosList">
              <li class="cursor-poin" (click)="macrosEvent(item,i)">{{ i.name }}</li>
            </ng-container>
          </ul>
        </ng-container>
      </ng-scrollbar>
    </div>
    <div class="macros-bottom">
      <button class="btn btn-icon">
        <i class="fal fa-search"></i>
      </button>
      <div class="form-group">
        <input
          type="text"
          class="form-control macros"
          id="macrosSearch"
          name="macrosSearch"
          placeholder="Search Macros"
        />
      </div>
    </div>
  </div> -->
</div>

<div class="p-0 card card-bg border quill-card">
    <quill-editor
        [(ngModel)]="emailConfig.content"
        (onContentChanged)="getEnrichTextData($event,qlContent?.errors)"
        id="articleNote"
        name="articleNote"
        [placeholder]="contentPlaceholder"
        [required]="true"
        #qlContent
        [trimOnValidation]="true"
        [appQuillValidator]="qlContent"
        [class.error]="qlContent?.errors"
    >
        <div
            quill-editor-toolbar
            class="border-0 border-bottom px-0 py-50"
        >
            <div class="border-bottom p-0">
                <span class="ql-formats pb-50">
                    <button
                        id="qlBoldNoteEmail-Inc"
                        name="qlBold"
                        class="ql-bold"
                        title="{{ 'UI.k_bold' | translate }}"
                    ></button>
                    <button
                        id="qlItalicNoteEmail-Inc"
                        name="qlItalic"
                        class="ql-italic"
                        title="{{ 'UI.k_italic' | translate }}"
                    ></button>
                    <button
                        id="qlUnderlineNoteEmail-Inc"
                        name="qlUnderline"
                        class="ql-underline"
                        title="{{ 'UI.k_underline' | translate }}"
                    ></button>
                </span>
                <span class="ql-formats">
                    <select
                        id="qlAlignNote"
                        class="ql-align"
                        title="{{ 'UI.k_alignment' | translate }}"
                    >
                        <option selected></option>
                        <option value="center"></option>
                        <option value="right"></option>
                        <option value="justify"></option>
                    </select>
                </span>
                <span class="ql-formats">
                    <button
                        id="btnqlListNote"
                        name="qlList"
                        class="ql-list"
                        value="ordered"
                        type="button"
                    ></button>
                </span>
                <span class="ql-formats">
                    <button
                        id="btnqlLinkNoteEmail-Inc"
                        class="ql-link"
                        value="ordered"
                        type="button"
                    ></button>
                    <button
                        id="btnqlImageNoteEmail-Inc"
                        class="ql-image"
                        value="ordered"
                        type="button"
                    ></button>
                    <!-- <button
                        id="btnqlVideoNoteEmail-Inc"
                        class="ql-video"
                        value="ordered"
                        type="button"
                    ></button> -->
                </span>
                <span class="ql-formats">
                    <!-- <button
                        id="btnqlFormulaNoteEmail-Inc"
                        class="ql-formula"
                        value="ordered"
                        type="button"
                    ></button> -->
                    <button
                        id="btnalCodeBlockNoteEmail-Inc"
                        class="ql-code-block"
                        value="ordered"
                        type="button"
                    ></button>
                </span>
                <span class="ql-formats">
                    <button
                        id="btnqlCleanNoteEmail-Inc"
                        class="ql-clean"
                        value="ordered"
                        type="button"
                    ></button>
                </span>
            </div>
        </div>
        <div quill-editor-element *ngIf="showMacros">
            <div class="form-group">
                test
                <!-- Select Group by item -->
                <!-- <ng-select [items]="this.emailConfig.macrosOption" bindLabel="name" bindValue="name" groupBy="Asset"
                  [(ngModel)]="this.emailConfig.selectedMacro">
                  <ng-template ng-optgroup-tmp let-item="item">
                    {{ item.name }}
                  </ng-template>
                </ng-select> -->
                <br />
              </div>
        </div>
    </quill-editor>
    <div class="pl-2 pr-2 row">
        <span
            *ngFor="let file of filesList"
            class="
                text-primary
                reg-font
                border
                rounded
                m-half
                p-25
            "
            ><i class="fa-light fa-file-lines"></i>&nbsp;{{
                file.file_name
            }}&nbsp;
            <span
                (click)="removeAttachedFile($index, filesList)"
                id="btnFileAttachmentClose{{ i }}"
            >
                <i class="fa-light fa-xmark"></i>
            </span>
        </span>
    </div>
    <div class="compose-footer-wrapper email-footer mac-foot">
        <div class="btn-wrapper d-flex align-items-center">
            {{ 'UI.k_add_macros_text' | translate}}
        </div>
        <br />
    </div>
</div>

<hr>
