<div class="selct-feature" style="{{ customWidth }}">
	<div class="select-sec editable-select">
		<div class="form-group mb-0">
			<ng-select
				[items]="range"
				bindLabel="name"
				name="formSelect"
				[multiple]="false"
				id="formSelect"
				placeholder="{{ 'UI.k_select_date_range' | translate }}"
				[(ngModel)]="selectedValue"
				(change)="modalOpenSM(modalSM, $event)"
				class="form-control {{ customHeight }}"
				#ngSelectComponent
				[attr.data-testid]="'selectDateRange'"
			>
				<ng-template ng-option-tmp ng-label-tmp let-item="item">
					<span *ngIf="!item.addProfile">{{ item?.name }}</span>
					<span *ngIf="item.addProfile" class="add-profile">{{ item.name }}</span>
				</ng-template>
			</ng-select>
		</div>
	</div>
</div>

<ng-template #modalSM let-modal>
	<div class="modal-body p-0 date-range-inner" tabindex="0" ngbAutofocus>
		<div class="date-range-container">
			<!--[perfectScrollbar] style="height:520px"-->
			<!-- <div class="list-group-section border-right">
				<div *ngFor="let fil of range; let i = index" class="list-group list-group-messages border-radius">
					<a
						aria-controls="collapseExample1"
						data-toggle="collapse"
						id="btn-{{ i }}"
						name="menu-{{ i }}"
						class="list-group-item list-group-item-action cursor-pointer border-0"
						[ngClass]="{ active: selectedItem === fil.name }"
						(click)="onSelectFilter(fil, i)"
					>
						<span class="align-middle font-weight-normal font-small-3 pl-1">
							{{ fil.name }}
						</span>
					</a>
				</div>
			</div> -->
			<div class="date-range-section" style="overflow-x: auto">
				<div class="container-fluid flex-initial">
					<div class="row d-flex flex-wrap py-1">
						<div class="form-group col-md-6 mb-0">
							<div class="input-group mb-0">
								<div class="input-group-prepend">
									<span class="input-group-text"><i class="fa-light fa-calendar-day"></i></span>
								</div>
								<input
									#dpFromDate
									class="form-control"
									placeholder="yyyy-mm-dd"
									id="dpFromDate"
									name="dpFromDate"
									[value]="formatter.format(fromDate)"
								/>
							</div>
						</div>
						<div class="form-group col-md-6 mb-0">
							<div class="input-group mb-0">
								<div class="input-group-prepend">
									<span class="input-group-text"><i class="fa-light fa-calendar-day"></i></span>
								</div>
								<input
									#dpToDate
									class="form-control"
									placeholder="yyyy-mm-dd"
									id="dpToDate"
									name="dpToDate"
									[value]="formatter.format(toDate)"
								/>
							</div>
						</div>
					</div>
				</div>

				<div class="flex-1 border-top p-1">
					<div class="q-datepicker">
						<ngb-datepicker
							#dp
							id="dtPicker"
							name="dtPicker"
							(dateSelect)="onDateSelection($event)"
							[displayMonths]="2"
							[dayTemplate]="t"
							placeholder="{{ 'UI.k_select_date_range' | translate }}"
							outsideDays="hidden"
							[navigation]="'arrow'"
							style="border: none"
							[maxDate]="maxDate"
						>
						</ngb-datepicker>
					</div>
					<ng-template #t let-date let-focused="focused">
						<span
							class="custom-day"
							[class.focused]="focused"
							[class.range]="isRange(date)"
							[class.faded]="isHovered(date) || isInside(date)"
							(mouseenter)="hoveredDate = date"
							(mouseleave)="hoveredDate = null"
						>
							{{ date.day }}
						</span>
					</ng-template>
				</div>
				<div class="d-flex justify-content-end border-top p-1 align-items-center flex-initial">
					<!-- <div class="font-small-4 ml-1 font-weight-bold">{{ selectedItem }}</div> -->
					<button
						type="button"
						class="btn btn-outline-tertiary"
						id="btnClose"
						name="btnClose"
						(click)="modal.dismiss('Cross click')"
						rippleEffect
					>
						{{ "UI.k_cancel" | translate }}
					</button>
					<div class="ml-1">
						<button
							type="button"
							class="btn btn-primary"
							id="btnApply"
							name="btnApply"
							(click)="onApply(selectedDropdownItem); modal.dismiss('Cross click')"
							rippleEffect
						>
							{{ "UI.k_save" | translate }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<!--<div ngbDropdownMenu aria-labelledby="dropdownMenuButton">-->
<!--<div class="d-flex flex-row width-auto">-->
<!--<div class="ranges">-->
<!--<ul class="list-group">-->
<!--<li class="list-group-item" [ngClass.active]="item.selected" *ngFor="let item of range" (click)="onSelect(item, drpButton)">{{item.name}}</li>-->
<!--</ul>-->
<!--</div>-->
<!--<div class="range-picker" *ngIf="selectedRange?.is_configure">-->
<!--&lt;!&ndash; Range selection Date Picker &ndash;&gt;-->
<!--<div class="row">-->
<!--<div class="col-6">-->
<!--<input name="datepicker" class="form-control" ngbDatepicker #rangeSelectionDP="ngbDatepicker"-->
<!--[autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"-->
<!--outsideDays="hidden" [startDate]="fromDate!" [value]="formatter.format(today)">-->
<!--<ng-template #t let-date let-focused="focused">-->
<!--<smodalpan class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"-->
<!--[class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"-->
<!--(mouseleave)="hoveredDate = null">-->
<!--{{ date.day }}-->
<!--</span>-->
<!--</ng-template>-->
<!--<div class="input-group-append">-->
<!--<button class="btn btn-outline-secondary feather icon-calendar" (click)="rangeSelectionDP.toggle()"-->
<!--type="button" rippleEffect></button>-->
<!--</div>-->
<!--</div>-->
<!--<div class="col-6">-->
<!--<div class="input-group">-->
<!--<input #dpToDate class="form-control" placeholder="yyyy-mm-dd" name="dpToDate"-->
<!--[value]="formatter.format(toDate)">-->
<!--<div class="input-group-append">-->
<!--<button class="btn btn-outline-secondary feather icon-calendar" (click)="rangeSelectionDP.toggle()"-->
<!--type="button" rippleEffect></button>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--&lt;!&ndash;/ Range selection Date Picker &ndash;&gt;-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->

<!--<div ngbDropdown #drpButton="ngbDropdown">-->
<!--<div ngbDropdownToggle class="avatar bg-primary rounded-lg" id="btnTimeFilter" name="timeFilter" rippleEffect>-->
<!--<div class="avatar-content">-->
<!--<span 	[data-feather]="'calendar'"-->
<!--class="filter_icon avatar-icon font-medium-4">-->
<!--</span>-->
<!--</div>-->
<!--</div>-->
<!--<div ngbDropdownMenu aria-labelledby="dropdownMenuButton">-->
<!--<div class="d-flex flex-row width-auto">-->
<!--<div class="ranges">-->
<!--<ul class="list-group">-->
<!--<li class="list-group-item" [ngClass.active]="item.selected" *ngFor="let item of range" (click)="onSelect(item, drpButton)">{{item.name}}</li>-->
<!--</ul>-->
<!--</div>-->
<!--<div class="range-picker" *ngIf="selectedRange?.is_configure">-->
<!--&lt;!&ndash; Range selection Date Picker &ndash;&gt;-->
<!--<div class="row">-->
<!--<div class="col-6">-->
<!--<input name="datepicker" class="form-control" ngbDatepicker #rangeSelectionDP="ngbDatepicker"-->
<!--[autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"-->
<!--outsideDays="hidden" [startDate]="fromDate!" [value]="formatter.format(today)">-->
<!--<ng-template #t let-date let-focused="focused">-->
<!--<span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"-->
<!--[class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"-->
<!--(mouseleave)="hoveredDate = null">-->
<!--{{ date.day }}-->
<!--</span>-->
<!--</ng-template>-->
<!--<div class="input-group-append">-->
<!--<button class="btn btn-outline-secondary feather icon-calendar" (click)="rangeSelectionDP.toggle()"-->
<!--type="button" rippleEffect></button>-->
<!--</div>-->
<!--</div>-->
<!--<div class="col-6">-->
<!--<div class="input-group">-->
<!--<input #dpToDate class="form-control" placeholder="yyyy-mm-dd" name="dpToDate"-->
<!--[value]="formatter.format(toDate)">-->
<!--<div class="input-group-append">-->
<!--<button class="btn btn-outline-secondary feather icon-calendar" (click)="rangeSelectionDP.toggle()"-->
<!--type="button" rippleEffect></button>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--&lt;!&ndash;/ Range selection Date Picker &ndash;&gt;-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
<!--</div>-->
