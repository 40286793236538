import { Injectable, NgZone } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class IdleService {
	private timeoutId: any;
	private countdownId: any;
	private countdown: number;

	constructor(private ngZone: NgZone) {}

	public startWatching(timeoutDuration: number, idleCallback: () => void): void {
		this.resetTimeout(timeoutDuration, idleCallback);
		this.setupEventListeners(timeoutDuration, idleCallback);
	}

	private setupEventListeners(timeoutDuration: number, idleCallback: () => void): void {
		const events = ["mousemove", "mousedown", "keypress", "touchstart", "scroll"];
		events.forEach((event) => {
			window.addEventListener(event, () => this.resetTimeout(timeoutDuration, idleCallback));
			document.body.addEventListener(event, () => this.resetTimeout(timeoutDuration, idleCallback));
		});
	}

	private resetTimeout(timeoutDuration: number, idleCallback: () => void): void {
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}
		if (this.countdownId) {
			clearInterval(this.countdownId);
		}
		this.countdown = timeoutDuration / 1000; // countdown in seconds
		this.ngZone.runOutsideAngular(() => {
			this.timeoutId = setTimeout(() => {
				this.ngZone.run(idleCallback);
			}, timeoutDuration);
		});
		// this.startCountdown();
		// console.log('Timer reset.');
	}
	// private startCountdown(): void {
	// 	this.ngZone.runOutsideAngular(() => {
	// 	  this.countdownId = setInterval(() => {
	// 		this.countdown--;
	// 		console.log(`Time remaining: ${this.countdown} seconds`);
	// 		if (this.countdown <= 0) {
	// 		  clearInterval(this.countdownId);
	// 		}
	// 	  }, 1000);
	// 	});
	//   }
}
