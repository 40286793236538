import { Injectable } from "@angular/core";
import { ApiService } from "app/common/services/api/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class RequesterService extends ApiService {
	constructor(httpClient: HttpClient) {
		super(httpClient);
	}

	getRequesterProfiles(payload = {}) {
		return this.get("ux/common/requester/profile/", payload);
	}

	saveRequesterProfile(payload: any) {
		return this.post("ux/common/requester/profile/", payload);
	}

	getRequesterProfile(id: Number) {
		return this.get("ux/common/requester/profile/" + id.toString() + "/");
	}

	deleteRequesterProfile(id: Number) {
		return this.delete("ux/common/requester/profile/" + id.toString() + "/");
	}
	deleteRequesterProfiles(payload: any) {
		return this.post("ux/common/requester/profile/bulk_delete/", payload);
	}

	getDropdownData(payload = {}) {
		return this.get("ux/common/requester/profile/initconfig/", payload);
	}

	sendUserInvite(invites: any) {
		return this.post("ux/common/user/profile/user_invite/", invites);
	}

	getPendingReqCount(payload = {}) {
		return this.get("ux/common/requester/profile/requester_count/", payload);
	}

	getFilterOptions(payload: any) {
		return this.get("ux/common/filters/filters/filter_options/", payload);
	}

	getSavedFilter(payload) {
		return this.get("ux/common/filters/filters/filter/", payload);
	}

	closeIncidentOnRequesterDel(payload) {
		return this.put("ux/org/profile/close_tkt_on_req_del/", payload);
	}

	downloadSampleCSV(payload = {}) {
		return this.get("ux/common/requester/profile/download_csv/", payload);
	}

	getCSVColumns(payload: any) {
		return this.post("ux/common/requester/profile/csv_cols/", payload);
	}

	validateCsvRequesters(payload: any) {
		return this.post("ux/common/requester/profile/upload-csv/", payload);
	}

	createRequesters(payload: any) {
		return this.post("ux/common/requester/profile/create-csv/", payload);
	}
	download_requesters(payload: {}) {
		return this.get("ux/common/export/export/export_data/", payload);
	}
	getassignedAssets(payload: {}) {
		return this.post("ux/common/requester/profile/assets/", payload);
	}
	replaceReportingManager(payload = {}) {
		return this.post("ux/common/requester/profile/updateReportingMngr/", payload);
	}
	getCIRelationMap(payload: any) {
		return this.post("ux/common/cmdb/relation/ci-rel-map/", payload);
	}
	getLocations(payload = {}) {
		return this.post("ux/common/cmdb/relation/ci-rel-map/", payload)
	}
	saveOrgLocationProfile(payload) {
		return this.post("ux/common/org/location/", payload);
	}
}
