<div class="slideout-content default-slideout">
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
		<div class="modal-dialog sidebar-md">
			<div class="modal-content pt-0">
				<div class="modal-header">
					<h5 class="modal-title" id="serviceChange">
						{{
							"UI.k_service_change_detected_display_id"
								| translate: { display_id: workflow_config?.args?.display_id || "" }
						}}
					</h5>
					<button
						type="button"
						id="btnServiceChangeClose"
						name="btnServiceChangeClose"
						[attr.data-testid]="'btnServiceChangeClose'"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						(click)="toggleSidebar(sidebarName, ServiceChangeForm)"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<form
					#ServiceChangeForm="ngForm"
					class="modal-body"
					(ngSubmit)="onSubmit(ServiceChangeForm, workflow_config)"
				>
					<div class="flex-auto-height">
						<ng-scrollbar class="custom-scrollbar section-scrollbar">
							<div class="modal-body-inner p-1">
								<div>
									<ng-container *ngIf="workflow_config?.current_workflow">
										<div class="row co-12">
											<div class="col-6">
												<label>{{ "UI.k_impact_service_from" | translate }}</label>
												<div class="form-group d-flex">
													<input
														type="text"
														class="form-control"
														name="inpImpactServiceFrom"
														[attr.data-testid]="'inpImpactServiceFrom'"
														[disabled]="true"
														[(ngModel)]="workflow_config?.current_workflow.service_name"
													/>
												</div>
											</div>
											<div class="col-6">
												<label>{{ "UI.k_impact_service_to" | translate }}</label>
												<div class="form-group d-flex">
													<input
														type="text"
														class="form-control"
														name="inpImpactServiceTo"
														[attr.data-testid]="'inpImpactServiceTo'"
														[disabled]="true"
														[(ngModel)]="
															workflow_config?.selected_service_config.service_name
														"
													/>
												</div>
											</div>
										</div>
										<div class="form-group">
											<h6 class="header-title" type="text">
												{{ "UI.k_from_workflow" | translate }} -
												<label>{{ workflow_config?.current_workflow?.workflow_title }}</label>
											</h6>
										</div>
										<div class="form-group col-12 p-0" [attr.data-testid]="'statusPicker'">
											<app-status-picker
												[inputConfig]="{
													isTextMode: true,
													stateOptionsMap: options?.state_id_map,
													name: 'previous_status',
													id: 'previous_status',
													bindValue: 'id',
													isEditDisabled: true
												}"
												[options]="workflow_config?.current_workflow?.status || []"
												(setStatus)="onStatusChange($event, 'previous_status')"
												[value]="workflow_config?.current_status?.id"
											></app-status-picker>
										</div>
										<div class="form-group">
											<h6 class="header-title" type="text">
												{{ "UI.k_to_workflow" | translate }} -
												<label>{{
													workflow_config?.selected_service_config?.workflow_title
												}}</label>
											</h6>
										</div>
										<div class="form-group col-12 p-0">
											<app-status-picker
												[inputConfig]="{
													isTextMode: true,
													stateOptionsMap: options?.state_id_map,
													name: 'current_status',
													id: 'current_status',
													header: 'UI.k_new_status',
													required: true,
													formRef: ServiceChangeForm
												}"
												#aspSelectedServiceConfig
												name="selected_service_config_status"
												id="selected_service_config_status"
												[attr.data-testid]="'selected_service_config_status'"
												[options]="workflow_config?.selected_service_config?.status || []"
												(setStatus)="onStatusChange($event, 'current_status_obj')"
												[value]="service_change_data.current_status"
											></app-status-picker>
										</div>
										<app-dns-custom-field
											[formRef]="ServiceChangeForm"
											placeholder="general"
											[data]="service_change_data.custom_field_data"
											[config]="workflow_config.selected_service_config?.custom_field_config"
										></app-dns-custom-field>
										<hr />
									</ng-container>
									<div class="col-md-12 p-0">
										<div class="form-group">
											<label for="txtAreaSmartGridServiceChange" class="sm-font">
												{{ "UI.k_reason_for_service_change" | translate
												}}<span class="required-field">&nbsp;*</span>
											</label>
											<div class="card p-0 pb-50">
												<quill-editor
													[(ngModel)]="service_change_data.comment"
													#TDSmartGridServiceChangeComment
													id="txtAreaSmartGridServiceChange"
													name="txtAreaSmartGridServiceChange"
													[attr.data-testid]="'txtAreaSmartGridServiceChange'"
													[required]="true"
													[trimOnValidation]="true"
													[appQuillValidator]="TDSmartGridServiceChangeComment"
													[appQuillForm]="ServiceChangeForm"
													[minLength]="3"
													[class.error]="
														TDSmartGridServiceChangeComment?.errors &&
														ServiceChangeForm.submitted
													"
													placeholder="{{ 'UI.k_insert_text_here' | translate }}..."
												>
													<div quill-editor-toolbar class="px-0 py-50">
														<div class="p-0">
															<span class="ql-formats pb-50">
																<button
																	class="ql-bold"
																	title="{{ 'UI.k_bold' | translate }}"
																></button>
																<button
																	class="ql-italic"
																	title="{{ 'UI.k_italic' | translate }}"
																></button>
																<button
																	class="ql-underline"
																	title="{{ 'UI.k_underline' | translate }}"
																></button>
															</span>
															<span class="ql-formats">
																<select
																	class="ql-align"
																	title="{{ 'UI.k_alignment' | translate }}"
																>
																	<option selected></option>
																	<option value="center"></option>
																	<option value="right"></option>
																	<option value="justify"></option>
																</select>
															</span>
															<span class="ql-formats">
																<button
																	class="ql-list"
																	value="ordered"
																	type="button"
																></button>
															</span>
															<span class="ql-formats">
																<button
																	class="ql-link"
																	value="ordered"
																	type="button"
																></button>
																<button
																	class="ql-image"
																	value="ordered"
																	type="button"
																></button>
																<button
																	class="ql-video"
																	value="ordered"
																	type="button"
																></button>
															</span>
															<span class="ql-formats">
																<button
																	class="ql-formula"
																	value="ordered"
																	type="button"
																></button>
																<button
																	class="ql-code-block"
																	value="ordered"
																	type="button"
																></button>
															</span>
															<span class="ql-formats">
																<button
																	class="ql-clean"
																	value="ordered"
																	type="button"
																></button>
															</span>
														</div>
													</div>
												</quill-editor>
											</div>
											<div>
												<span
													class="row col-12 error"
													*ngIf="
														ServiceChangeForm.submitted &&
														TDSmartGridServiceChangeComment?.errors
													"
													class="invalid-form"
												>
													<small
														*ngIf="
															TDSmartGridServiceChangeComment?.errors?.requiredError
																?.empty
														"
														class="form-text text-danger xs-font"
														>{{ "UI.k_field_required" | translate }}</small
													>
													<small
														*ngIf="TDSmartGridServiceChangeComment?.errors?.minLengthError"
														class="form-text text-danger xs-font"
														>{{ "Error.err_min_3_character" | translate }}</small
													>
												</span>
											</div>
										</div>
									</div>
									<div *ngIf="workflow_config?.args?.current_assignment_info?.group" class="card p-1 border d-grid gap-1">
										<div class="d-flex align-items-center justify-content-between">
											<div class="d-flex gap-50 align-items-center">
												<span class="title-color fw-500">{{ "UI.k_business_rule_associated" | translate }}</span>
												<span>:</span>
												<a
													class="cursor-pointer primary-color fw-500"
													id="btnRedirectToBizRule"
													routerLink="/business_rule"
													ngbTooltip="{{
														'UI.k_view'
															| translate
													}}"
													target="_blank"
												>
													{{workflow_config?.args?.current_assignment_info?.rule_name}}
												</a>
											</div>
											<div class="custom-control custom-checkbox">
												<input type="checkbox" 
												class="custom-control-input" 
												[attr.data-testid]="'ckIncludeBusinessRule'" 
												name="ckIncludeBusinessRule" 
												id="ckIncludeBusinessRule" 
												[(ngModel)]="isIncludeBusRule"
												(change)="changeIncludeBusRule($event)"/>
												<label class="custom-control-label" for="ckIncludeBusinessRule">{{ "UI.k_include_business_rule" | translate }}</label>
											</div>
										</div>
										<div class="teams-data fw-500" [ngClass]="isIncludeBusRule ? 'title-color' : 'text-muted-color'">
											<div class="teams-info">
												<div class="info-label">
													{{ "UI.k_team" | translate }}
												</div>
												<span>:</span>
												<div class="info-value">
													{{workflow_config?.args?.current_assignment_info?.group_name}}
												</div>
											</div>
											<div class="teams-info">
												<div class="info-label">
													{{ "UI.k_expertise" | translate }}
												</div>
												<span>:</span>
												<div class="info-value">
													{{workflow_config?.args?.current_assignment_info?.expertise}}
												</div>
											</div>
											<div class="teams-info">
												<div class="info-label">
													{{ "UI.k_level" | translate }}
												</div>
												<span>:</span>
												<div class="info-value">
													{{workflow_config?.args?.current_assignment_info?.level}}
												</div>
											</div>
											<div class="teams-info">
												<div class="info-label">
													{{ "UI.k_assignee" | translate }}
												</div>
												<span>:</span>
												<div class="info-value">
													{{workflow_config?.args?.current_assignment_info?.assignee_profile?.full_name}} 
												</div>
												<div class="info-value" *ngIf="!workflow_config?.args?.current_assignment_info?.assignee_profile?.full_name">
													-
												</div>
											</div>
										</div>
										<div class="rule-note transparent-primary border-radius-6 d-grid p-1">
											<span class="sm-font fw-500">{{ "UI.k_note" | translate }}</span>
											<span class="sm-font">{{ "Message.msg_include_business_rule_note" | translate }}</span>
										</div>
									</div>
								</div>
							</div>
						</ng-scrollbar>
						<div class="modal-btn-group flex-initial border-top">
							<div class="d-flex justify-content-end stepper-btn flex-initial">
								<button
									class="btn btn-sm btn-primary btn-next ml-1"
									id="btnServiceChangeSubmit"
									rippleEffect
									type="submit"
									[attr.data-testid]="'btnServiceChangeSubmit'"
								>
									<span class="align-middle d-sm-inline-block d-none">
										{{ "UI.k_submit" | translate }}</span
									>
								</button>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
