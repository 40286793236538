<div class="slideout-content default-slideout">
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
		<div class="modal-dialog">
			<div class="modal-content pt-0">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">
						{{ "UI.k_add_asset" | translate }}
					</h5>
					<button
						type="button"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						id="btnAssetListClose"
						name="btnAssetListClose"
						[attr.data-testid]="'btnAssetListClose'"
						(click)="toggleCloseSidebar('app-dns-asset-select')"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body">
					<div class="action-bar px-1 pt-1">
						<div class="inner-section d-flex justify-content-between">
							<div class="left-section ">
								<ng-container *ngIf="showFilter">
									<app-search
										(getfilterData)="getFilterSearch($event)"
										[filterDetails]="getItemList"
										(genericFilter)="genericSearchFilter($event)"
										[showDataTable]="showDataTable"
										[columnOptions]="columnOptions"
										[searchObject]="searchObject"
										[conditionOptions]="conditionOptions"
										[showRightsection]="showRightsection"
										[savedData]="savedData"
										[Options]="option"
										[module]="assetType"
									></app-search>
								</ng-container>
							</div>
							<div class="right-section">

							</div>
						</div>
					</div>
					<div class="action-section flex-auto-height">
						<div class="main-card-view">
							<div class="card-view flex-auto-height">
								<div *ngIf="!showData">
									<app-asset-sekeleton></app-asset-sekeleton>
								</div>
								<div
									*ngIf="selectedData?.length > 0 && showCardHeader === true && showData"
									class="bg-primary selected-data flex-initial table-grid-top-bar"
								>
									<div class="px-1 d-flex align-items-center justify-content-between">
										<div class="d-flex align-items-center">
											<span class="mr-50 bullet bg-white bullet-xs"> </span>
											<span class="text-white font-small-2 text-nowrap">
												{{ selectedData?.length }}&nbsp;
												{{ "UI.k_selected" | translate }}</span
											>
										</div>
										<div (click)="toggleCardHeader()" class="btn btn-primary btn-icon">
											<i
												class="fa-light fa-xmark"
												id="btnXmark"
												[attr.data-testid]="'btnXmark'"
											></i>
										</div>
									</div>
								</div>
								<!-- <div class="card-view position-relative"> -->
								<ng-scrollbar class="custom-scrollbar section-scrollbar" #pannelViewScroll>
									<div class="inner-card-view add-impect-asset-sec">
										<!-- asset-card-view -->
										<ng-container *ngIf="cmdbCI?.length > 0">
											<ng-container
												*ngFor="let asset of changeResponseResult; let i = index"
											>
												<div
													class="single-card p-0 asset-card cursor-pointer"
													id="btnAssetDetail{{ asset?.ci_id || i }}"
												>
													<div
														class="custom-control custom-radio w-100 justify-content-end"
													>
														<input
															type="radio"
															id="RdAsset_{{asset?.ci_id}}"
															name="RdAsset_{{asset?.ci_id}}"
															class="custom-control-input"
															[value]="asset.ci_id"
															[(ngModel)]="
																selected_asset
															"
															[checked]="selected_asset == asset.ci_id"
															(change)="onCardSelect(asset)"
														/>
														<label
															class="custom-control-label w-100"
															for="RdAsset_{{asset?.ci_id}}"
														>
														<div class="card-info py-1 pr-1">
															<div class="info-detail">
																<div
																	class="asset-name d-flex justify-content-between align-items-center"
																>
																	<h6
																		class="cursor-pointer title-blue"
																		id="linkAssetname"
																		[attr.data-testid]="'linkAssetName'"
																	>
																		{{ asset?.ci_name }}
																	</h6>
																	<div class="d-flex align-items-baseline">
																		<span
																			*ngIf="!asset?.is_enabled"
																			class="badge mr-50 badge badge-secondary"
																		>
																			{{ "UI.k_disabled" | translate }}
																		</span>
																	</div>
																</div>
																<div *ngIf="asset?.ip_address" class="mb-1">
																	<span
																		(click)="detailsPage(asset)"
																		id="linkAssetView"
																		[attr.data-testid]="'linkAssetView'"
																		><i class="fa-light fa-globe font-small-5"></i>
																		<span
																			class=""
																			ngbTooltip="{{ asset?.ip_address }}"
																		>
																			{{
																				asset?.ip_address?.length > 32
																					? (asset?.ip_address
																							| slice: 0:32) + "..."
																					: asset?.ip_address
																			}}
																		</span>
																		<!-- <span
																		class="border-right mx-75"
																		ngbTooltip="{{ asset?.device_type }}"
																	></span
																	> -->
																		<!-- #
																	{{
																		asset?.device_type?.length > 10
																			? (asset?.device_type | slice: 0:10) + ".."
																			: asset?.device_type
																	}} -->
																	</span>
																</div>
																<div *ngIf="asset?.asset_id" class="">
																	<span class="" ngbTooltip="{{ asset?.asset_id }}">
																		#{{
																			asset?.asset_id?.length > 32
																				? (asset?.asset_id | slice: 0:32) +
																					"..."
																				: asset?.asset_id
																		}}
																	</span>
																</div>
															</div>
															<div class="d-flex justify-content-between info-bottom">
																<div class="d-flex info-badge flex-wrap">
																	<span
																		*ngIf="asset?.common_info?.status"
																		class="d-flex mt-half"
																	>
																		<ng-container>
																			<div
																				class="group-lists flex-wrap d-flex align-items-center justify-content-start"
																			>
																				<div
																					class="custom-squre ml-0"
																					[ngClass]="
																						'bg-' +
																						asset?.common_info?.status
																							?.color
																					"
																					[ngStyle]="{
																						'background-color':
																							asset?.common_info?.status
																								?.color
																					}"
																				></div>
																				<span
																					class="sm-font fw-500"
																					placement="top"
																					container="body"
																					ngbTooltip="{{
																						asset?.common_info?.status
																							?.state | translate
																					}} - {{
																						asset?.common_info?.status?.name
																					}}"
																				>
																					{{
																						asset?.common_info?.status?.name
																							?.length > 12
																							? (asset?.common_info
																									?.status?.name
																									| slice: 0:12) +
																								".."
																							: asset?.common_info?.status
																									?.name
																					}}
																				</span>
	
																				<!-- <div
																				class="right-arrow custom-text sm-font fw-500"
																				[ngStyle]="{
																					'background-color': asset?.status?.background_color,
																					color:
																						asset?.status?.text_color || asset.status?.color
																				}"
																			>
																				{{ asset?.status?.state }}
																			</div> -->
																			</div>
																		</ng-container>
																	</span>
																	<span
																		*ngIf="asset?.common_info?.operational_status"
																		class="d-flex justify-content-center align-items-center mt-50 ml-half"
																	>
																		<ng-container>
																			<div
																				*ngIf="
																					asset?.common_info
																						?.operational_status?.name ==
																					'UP'
																				"
																				class="badge badge-pill badge-light-success"
																				style="font-size: 12px"
																				ngbTooltip="{{ 'UI.k_up' | translate }}"
																			>
																				<i class="fa-solid fa-up"></i>
																			</div>
																			<div
																				*ngIf="
																					asset?.common_info
																						?.operational_status?.name ==
																					'DOWN'
																				"
																				class="badge badge-pill badge-light-danger"
																				style="font-size: 12px"
																				ngbTooltip="{{
																					'UI.k_down' | translate
																				}}"
																			>
																				<i class="fa-solid fa-down"></i>
																			</div>
																			<div
																				*ngIf="
																					asset?.common_info
																						?.operational_status?.name ==
																					'UNKNOWN'
																				"
																				class="badge badge-pill badge-light-secondary"
																				style="font-size: 12px"
																				ngbTooltip="{{
																					'UI.k_unknown' | translate
																				}}"
																			>
																				<i class="fa-light fa-question"></i>
																			</div>
																			<div
																				*ngIf="
																					asset?.maintenance_scheduled &&
																					maintenance_permissions?.view
																				"
																			>
																				<div
																					id="right-popup"
																					class="right-click-node p-1"
																				></div>
																				<span
																					*ngIf="asset?.maintenance_started"
																					class="badge badge-pill badge-light-success"
																					ngbTooltip="{{
																						'UI.k_In_progress' | translate
																					}}"
																				>
																					<i
																						(click)="
																							show_maintenance_tooltip(
																								$event,
																								'in_progress',
																								asset
																							)
																						"
																						class="fa-light fa-screwdriver-wrench"
																					></i>
																				</span>
																				<span
																					*ngIf="!asset?.maintenance_started"
																					class="badge badge-pill badge-light-danger"
																					ngbTooltip="{{
																						'UI.k_not_started' | translate
																					}}"
																				>
																					<i
																						(click)="
																							show_maintenance_tooltip(
																								$event,
																								'not_started',
																								asset
																							)
																						"
																						class="fa-light fa-screwdriver-wrench"
																					></i>
																				</span>
																			</div>
																		</ng-container>
																	</span>
																</div>
																<div class="d-flex info-img">
																	<span
																		*ngIf="
																			asset?.device_type &&
																			getImageSrc(
																				asset?.device_type,
																				'device_type'
																			) != ''
																		"
																		container="body"
																		placement="left"
																		ngbTooltip="{{
																			'UI.k_device_type' | translate
																		}}-{{ asset?.device_type }}"
																	>
																		<img
																			class="thumbnail-image"
																			[src]="
																				getImageSrc(
																					asset?.device_type,
																					'device_type'
																				)
																			"
																			height="24"
																		/>
																	</span>
																	<span
																		*ngIf="
																			asset?.hardware?.make &&
																			getImageSrc(
																				asset?.hardware?.make,
																				'make'
																			) != ''
																		"
																		container="body"
																		placement="left"
																		ngbTooltip="{{ 'UI.k_make' | translate }}-{{
																			asset?.hardware?.make
																		}}"
																	>
																		<img
																			class="thumbnail-image"
																			[src]="
																				getImageSrc(
																					asset?.hardware?.make,
																					'make'
																				)
																			"
																			height="24"
																		/>
																	</span>
																	<span
																		*ngIf="
																			asset?.hardware?.os_name &&
																			getImageSrc(
																				asset?.hardware?.os_name,
																				'os_name'
																			) != ''
																		"
																		container="body"
																		placement="left"
																		ngbTooltip="{{ 'UI.k_os_short' | translate }}-{{
																			asset?.hardware?.os_name
																		}}"
																	>
																		<img
																			class="thumbnail-image"
																			[src]="
																				getImageSrc(
																					asset?.hardware?.os_name,
																					'os_name'
																				)
																			"
																			height="24"
																		/>
																	</span>
																</div>
															</div>
														</div>
														</label>
													</div>
												</div>
											</ng-container>
											<div class="text-center mt-1 mb-2" *ngIf="!finished">
												<button
													id="btnAssetSpinner"
													class="btn btn-outline-primary"
													type="button"
													disabled
													rippleEffect
													[attr.data-testid]="'btnAssetSpinner'"
												>
													<span
														class="spinner-border spinner-border-sm"
														role="status"
														aria-hidden="true"
													></span>
													<span class="ml-25 align-middle"
														>{{ "UI.k_loading" | translate }}...</span
													>
												</button>
											</div>
										</ng-container>
									</div>
								</ng-scrollbar>
								<div *ngIf="cmdbCI?.length == 0 && showData">
									<app-dns-empty-grid-data
										[emptyData]="empty_data"
										[noFilterData]="filterData"
									></app-dns-empty-grid-data>
								</div>
							</div>
						</div>
						<!-- <div class="card-pagination" *ngIf="cmdbCI?.length > 0 && showData">
							<div class="pagination-inner">
								<div *ngIf="finished" class="col-md-12 col-lg-12 col-sm-12 col-xs 12 px-50 w-100">
									<div class="ngx-datatable">
										<div class="datatable-footer">
											<dns-pagination
												[totalCount]="totalAsset"
												[currentCount]="cmdbCI?.length"
												(setPage)="loadPage($event)"
												(setLimit)="onLimitChange($event)"
												[page]="pageNumberCard"
												[pageSize]="limit"
												[isAll]="showAll"
												[dataLoading]="page_data_loading"
											></dns-pagination>
										</div>
									</div>
								</div>
							</div>
						</div> -->
					</div>
					<div class="flex-initial p-1 border-top d-flex justify-content-end">
						<button
						class="btn btn-primary pull-right ml-25"
						rippleEffect
						id="btnAddasset"
						name="btnAddasset"
						(click)="assetSelect()"
						tooltipClass="fadeInAnimation"
						placement="left"
						[attr.data-testid]="'btnAddasset'"
						>
							<span class="d-none d-sm-inline-block">
								{{ "UI.k_add_asset" | translate }}
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="bulk-update" overlayClass="modal-backdrop">
		<app-asset-bulk-update
			[selectedRows]="rowData"
			*ngIf="showBulkUpdateSidebar"
			(openBulkUpdateSlider)="openBulkUpdateSlider($event)"
			[assetType]="assetType"
			[selAction]="selAction"
		></app-asset-bulk-update>
	</core-sidebar>
	<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="tag" overlayClass="modal-backdrop">
		<app-set-tag
			[selectedTags]="rowData"
			*ngIf="showTag"
			(showTagSlider)="showTagSlider($event)"
			[assetType]="assetType"
			[loading]="loading"
			[is_form_submitted]="is_form_submitted"
		></app-set-tag>
	</core-sidebar>
	<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="allocate" overlayClass="modal-backdrop">
		<app-asset-bulk-update
			*ngIf="showAllocateSidebar"
			[selectedRows]="[assetData]"
			(openBulkUpdateSlider)="openAllocateSlider($event)"
			[assetType]="assetType"
			[selAction]="selAction"
			[closeSider]="true"
			[updateOnlyReq]="true"
		></app-asset-bulk-update>
	</core-sidebar>
	<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="assignee" overlayClass="modal-backdrop">
		<app-asset-bulk-update
			*ngIf="showAssigneeSidebar"
			[asset_ci_id]="asset_ci_id"
			(openBulkUpdateSlider)="openAssigneeSlider($event)"
			[assetType]="assetType"
			[selAction]="selAction"
		></app-asset-bulk-update>
	</core-sidebar>
	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="prefix-configuration"
		overlayClass="modal-backdrop"
	>
		<app-prefix-configuration
			*ngIf="!hideSidebar"
			[key_to_update]="selected_key"
			(onHideSidebar)="onHideSidebar($event)"
			[samplePrefix]="assetPrefix"
		></app-prefix-configuration>
	</core-sidebar>
	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="item-sidebar"
		overlayClass="modal-backdrop"
	>
		<app-item
			[event]="event"
			[itemID]="currentItem"
			[categoryID]="itemInput.categoryID"
			[subCategoryID]="itemInput.subCategoryID"
			[serialNo]="serialNo"
			*ngIf="showItemSlider"
			(onHideEvent)="onHideEvent($event)"
			(updateGrid)="this.getItemList(filter)"
			[assetType]="assetType"
			[state]="state"
		></app-item>
	</core-sidebar>

	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="olt-resync-sidebar"
		overlayClass="modal-backdrop"
	>
		<app-olt-resync-sidebar
			*ngIf="showSidebarDetails?.OLTResync"
			[selected_olts]="selectedData"
		></app-olt-resync-sidebar>
	</core-sidebar>
	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="update-badwidth-sidebar"
		overlayClass="modal-backdrop"
	>
		<app-update-badwidth-sidebar
			*ngIf="showSidebarDetails?.ONTBandwidth"
			[selected_onts]="selectedData"
			[sidebar_data]="sidebar_data"
		></app-update-badwidth-sidebar>
	</core-sidebar>
	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="modify-uni-port-sidebar"
		overlayClass="modal-backdrop"
	>
		<app-modify-uni-port-sidebar
			*ngIf="showSidebarDetails?.ModifyUNIPort"
			[selected_onts]="selectedData"
			[sidebar_data]="sidebar_data"
		></app-modify-uni-port-sidebar>
	</core-sidebar>
	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="ont-ccu-sidebar"
		overlayClass="modal-backdrop"
	>
		<app-ont-ccu-sidebar
			*ngIf="showSidebarDetails?.ONTCCU"
			[selected_onts]="selectedData"
			[sidebar_data]="sidebar_data"
		></app-ont-ccu-sidebar>
	</core-sidebar>

	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="ont-optical-sidebar"
		overlayClass="modal-backdrop"
	>
		<app-ont-optical-sidebar
			*ngIf="showSidebarDetails?.ONTOptical"
			[selected_onts]="selectedData"
			[sidebar_data]="sidebar_data"
		></app-ont-optical-sidebar>
	</core-sidebar>

	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="ont-aes-sidebar"
		overlayClass="modal-backdrop"
	>
		<app-ont-aes-sidebar *ngIf="showSidebarDetails?.AESState" [selected_onts]="selectedData"></app-ont-aes-sidebar>
	</core-sidebar>

	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="ont-serial-modification-sidebar"
		overlayClass="modal-backdrop"
	>
		<app-ont-serial-modification-sidebar
			*ngIf="showSidebarDetails?.ModifySerialNo"
			[selected_onts]="selectedData"
		></app-ont-serial-modification-sidebar>
	</core-sidebar>

	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="olt-backup-sidebar"
		overlayClass="modal-backdrop"
	>
		<app-olt-backup-sidebar
			*ngIf="showSidebarDetails?.OLTBackup"
			[selected_olts]="selectedData"
		></app-olt-backup-sidebar>
	</core-sidebar>

	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="olt-restore-sidebar"
		overlayClass="modal-backdrop"
	>
		<app-olt-restore-sidebar
			*ngIf="showSidebarDetails?.OLTRestore"
			[selected_olts]="selectedData"
		></app-olt-restore-sidebar>
	</core-sidebar>

	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="olt-upgrade-sidebar"
		overlayClass="modal-backdrop"
	>
		<app-olt-upgrade-sidebar
			*ngIf="showSidebarDetails?.OLTUpgrade"
			[selected_olts]="selectedData"
		></app-olt-upgrade-sidebar>
	</core-sidebar>

	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="ont-upgrade-sidebar"
		overlayClass="modal-backdrop"
	>
		<app-ont-upgrade-sidebar
			*ngIf="showSidebarDetails?.ONTUpgrade"
			[selected_onts]="selectedData"
		></app-ont-upgrade-sidebar>
	</core-sidebar>

	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="olt-card-switchover-sidebar"
		overlayClass="modal-backdrop"
	>
		<app-olt-card-switchover-sidebar
			*ngIf="showSidebarDetails?.CardSwicthOver"
			[selected_olts]="selectedData"
		></app-olt-card-switchover-sidebar>
	</core-sidebar>

	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="olt-fan-status-sidebar"
		overlayClass="modal-backdrop"
	>
		<app-olt-fan-status-sidebar
			*ngIf="showSidebarDetails?.ShowFanStatus"
			[selected_olts]="selectedData"
			[sidebar_data]="sidebar_data"
		></app-olt-fan-status-sidebar>
	</core-sidebar>

	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="ont-replace-sidebar"
		overlayClass="modal-backdrop"
	>
		<app-ont-replace-sidebar
			*ngIf="showSidebarDetails?.replaceONT"
			[selected_onts]="selectedData"
		></app-ont-replace-sidebar>
	</core-sidebar>

	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="ont-thresholds-sidebar"
		overlayClass="modal-backdrop"
	>
		<app-ont-thresholds-sidebar
			*ngIf="showSidebarDetails?.ONTThreshold"
			[selected_onts]="selectedData"
			[sidebar_data]="sidebar_data"
		></app-ont-thresholds-sidebar>
	</core-sidebar>
	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="view-onts-sidebar"
		overlayClass="modal-backdrop"
	>
		<app-view-onts-sidebar
			*ngIf="showSidebarDetails?.ONTView"
			[selected_olt]="selectedData"
			(hideShowDetails)="onHideONTGrid($event)"
		></app-view-onts-sidebar>
	</core-sidebar>
	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="view-services-sidebar"
		overlayClass="modal-backdrop"
	>
		<app-view-services-sidebar
			*ngIf="showSidebarDetails?.ServiceView"
			[selected_asset]="selectedData"
			(hideShowDetails)="onHideServiceGrid($event)"
		></app-view-services-sidebar>
	</core-sidebar>

	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="pso-schedule"
		overlayClass="modal-backdrop"
	>
		<app-pso-schedule
			*ngIf="showSidebarDetails?.maintenance"
			[selectedAssets]="selectedData"
			(hideShowDetails)="onHideAssetPSO($event)"
		></app-pso-schedule>
	</core-sidebar>

	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="import-asset-sidebar"
		overlayClass="modal-backdrop"
	>
		<app-import-asset-sidebar
			*ngIf="showImportAsset"
			(onHideEvent)="closeImportAsset($event)"
			[categoryID]="itemInput.categoryID"
			[subCategoryID]="itemInput.subCategoryID"
			[assetType]="assetType"
		></app-import-asset-sidebar>
	</core-sidebar>

	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="export-asset-sidebar"
		overlayClass="modal-backdrop"
	>
		<app-export-asset-sidebar
			*ngIf="showExportAsset"
			(onHideEvent)="closeExportAsset($event)"
			[categoryID]="itemInput.categoryID"
			[subCategoryID]="itemInput.subCategoryID"
			[assetType]="assetType"
			[totalAsset]="totalAssetCount"
		></app-export-asset-sidebar>
	</core-sidebar>

	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="asset-csv-edit-sidebar"
		overlayClass="modal-backdrop"
	>
		<app-asset-csv-edit
			*ngIf="showAssetCsvEdit"
			(onHideEvent)="closeAssetCsvEdit($event)"
			[categoryID]="itemInput.categoryID"
			[subCategoryID]="itemInput.subCategoryID"
			[assetType]="assetType"
			[totalAsset]="totalAsset"
		></app-asset-csv-edit>
	</core-sidebar>

	<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="print-asset" overlayClass="modal-backdrop">
		<app-print-asset-sidebar
			*ngIf="showSidebarDetails?.printAsset"
			[selected_assets]="selectedData"
			(hideShowDetails)="onHidePrint($event)"
			(deselectItems)="removeSelection()"
			[bulkPrint]="isBulkPrint"
			[modalName]="'print-asset'"
			[codeType]="'qr_code'"
		></app-print-asset-sidebar>
	</core-sidebar>
	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="agent-api-token"
		overlayClass="modal-backdrop"
	>
		<app-agent-api-token
			*ngIf="showAPI"
			[agent_type]="agent_type"
			(hideShowAPI)="hideShowAPI($event)"
		></app-agent-api-token>
	</core-sidebar>
	<!-- Modal -->
	<ng-template #scanDevice let-modal>
		<div class="modal-body" tabindex="0" ngbAutofocus>
			<div class="close-modal" (click)="modal.dismiss('Cross click')">
				<i class="fa-light fa-xmark lg-font fw-500"></i>
			</div>
			<div class="scanner-body w-100">
				<ng-container *ngIf="isScanDevice">
					<div class="scan-gif">
						<!-- <img src="../../../../../assets/images/asset/scan-device.gif" alt="" srcset="" /> -->
					</div>
				</ng-container>
				<ng-container *ngIf="deviceFound && !isScanDevice"></ng-container>
				<ng-container *ngIf="!deviceFound && !isScanDevice">
					<div class="icon-sec txt-warning position-relative mb-1 mt-5">
						<i class="fa-light fa-square-dashed"></i>
						<i class="fa-light fa-exclamation position-absolute-center fw-500"></i>
					</div>
					<div class="head3 mb-5" *ngIf="!isDisableDevice">
						{{ "Message.msg_asset_not_found" | translate }}
					</div>
					<div class="head3 mb-5" *ngIf="isDisableDevice">{{ "Message.msg_asset_disabled" | translate }}</div>

					<div class="form-group mb-0 py-1" style="width: 70%">
						<input
							type="text"
							focus="true"
							class="form-control"
							id="txtDeviceId"
							name="txtDeviceId"
							[attr.data-testid]="'txtDeviceId'"
							[(ngModel)]="scanInputData"
							(ngModelChange)="scanAsset($event)"
						/>
					</div>
					<button
						type="button"
						class="btn btn-primary mb-1"
						style="width: 70%"
						(click)="addItem('item-sidebar', scanInputData)"
						*ngIf="permissions?.add && !isDisableDevice"
						rippleEffect
					>
						{{ "Message.msg_add_new_asset" | translate }}
					</button>
				</ng-container>
			</div>
			<ng-container *ngIf="isScanDevice">
				<div class="form-group mb-0 p-1">
					<input
						type="text"
						focus="true"
						class="form-control"
						id="txtDeviceId"
						name="txtDeviceId"
						[(ngModel)]="scanInputData"
						(ngModelChange)="scanAsset($event)"
					/>
				</div>
			</ng-container>
		</div>
	</ng-template>
	<!-- / Modal -->

	<!-- Modal -->
	<ng-template #modCloseIncidentPopup let-modal>
		<form #inciCloseForm="ngForm">
			<div>
				<div class="modal-header py-50">
					<div class="d-flex justify-content-start align-items-center">
						<h5 class="modal-title mr-75" id="deletemodal">{{ "UI.k_inci_closure_note" | translate }}</h5>
					</div>
					<button type="button" class="close" (click)="closeIncidentPopUpClose()" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body modal-max-height" tabindex="0" ngbAutofocus>
					<div>
						<a class="fw-500 text-primary" id="allIncidents" name="allIncidents">
							{{ req_base_incident?.asset_inci_count }} -
						</a>
						<span class="fw-500">
							{{ "Message.msg_incident_close_note_on_asset_del" | translate }}
						</span>
					</div>
					<div *ngIf="!is_processing" class="form-group">
						<quill-editor
							[(ngModel)]="req_base_incident.closure_note"
							id="quillIncidentClosure"
							name="quillIncidentClosure"
							[styles]="quillEditorStyles"
							(onContentChanged)="getClosureNote($event)"
							[trimOnValidation]="true"
							#TDInciClosureNoteRef
							[appQuillValidator]="TDInciClosureNoteRef"
							[appQuillForm]="inciCloseForm"
							[minLength]="3"
							[required]="true"
							[class.error]="TDInciClosureNoteRef?.errors && inciCloseForm.submitted"
							placeholder="{{ 'UI.k_closure_note' | translate }}"
						>
							<div id="quill-toolbar" quill-editor-toolbar>
								<div class="">
									<span class="ql-formats">
										<button class="ql-bold" title="{{ 'UI.k_bold' | translate }}"></button>
										<button class="ql-italic" title="{{ 'UI.k_italic' | translate }}"></button>
										<button
											class="ql-underline"
											title="{{ 'UI.k_underline' | translate }}"
										></button>
									</span>
									<span class="ql-formats">
										<select class="ql-align" title="{{ 'UI.k_alignment' | translate }}">
											<option selected></option>
											<option value="center"></option>
											<option value="right"></option>
											<option value="justify"></option>
										</select>
									</span>
									<span class="ql-formats">
										<button class="ql-list" value="ordered" type="button"></button>
									</span>
									<span class="ql-formats">
										<button class="ql-link" value="ordered" type="button"></button>
										<button class="ql-image" value="ordered" type="button"></button>
										<button class="ql-video" value="ordered" type="button"></button>
									</span>
									<span class="ql-formats">
										<button class="ql-formula" value="ordered" type="button"></button>
										<button class="ql-code-block" value="ordered" type="button"></button>
									</span>
									<span class="ql-formats">
										<button class="ql-clean" value="ordered" type="button"></button>
									</span>
									<span class="ql-formats">
										<button id="insert-table" type="button" (click)="addNewtable($event)">
											Table
										</button>
									</span>
								</div>
							</div>
						</quill-editor>
						<div class="error" *ngIf="inciCloseForm.submitted && TDInciClosureNoteRef?.errors">
							<small *ngIf="TDInciClosureNoteRef?.errors?.requiredError?.empty">
								{{ "UI.k_field_required" | translate }}
							</small>
							<small *ngIf="TDInciClosureNoteRef?.errors?.minLengthError">
								{{ "Error.err_closure_note_min" | translate }}
							</small>
						</div>
					</div>
					<div class="card border d-flex justify-content-center my-2 p-4" *ngIf="is_processing">
						<span class="spinner-border spinner-border-sm mt-1 mx-auto text-success"></span>
						<span class="mx-auto">{{ "UI.k_processing" | translate }}...</span>
					</div>
				</div>

				<div *ngIf="!is_processing" class="modal-footer py-75">
					<button
						type="submit"
						class="btn btn-primary ml-75"
						id="btnUsrReplace"
						name="btnUsrReplace"
						(click)="closeIncidentOnServiceDel(inciCloseForm)"
					>
						{{ "UI.k_submit" | translate }}
					</button>
				</div>
			</div>
		</form>
	</ng-template>
	<!-- / Modal -->
	<ng-template #modalDefault let-modal>
		<form #colForm="ngForm">
			<div class="flex-auto-height column-select-modal">
				<div *ngIf="!hideModel" class="modal-header flex-initial border-bottom">
					<h5 class="modal-title" id="myModalLabel160" [attr.data-testid]="'myModalLabel160'">
						{{ "UI.k_column_selection" | translate }}
					</h5>
					<span
						id="btnClose"
						name="btnClose"
						data-dismiss="modal"
						aria-label="Close"
						(click)="closePopup(colForm)"
						[attr.data-testid]="'btnClose'"
					>
						<i class="fa-light fa-xmark lg-font title-color"></i>
					</span>
				</div>
				<div *ngIf="!hideModel" class="modal-body p-0 flex-1" tabindex="0" ngbAutofocus>
					<div class="column-left-sec bg-body border-right full-height">
						<ng-scrollbar class="custom-scrollbar section-scrollbar">
							<div class="p-1 full-height">
								<span class="col-heading title-color fw-500"
									>{{ "UI.k_available" | translate }} {{ "UI.k_columns" | translate }}</span
								>
								<div class="column-list mt-50">
									<ng-container *ngFor="let col of availCols; let i = index">
										<div
											class="single-column fw-500 sm-font"
											id="column__{{ col.key }}"
											attr.data-testid="column__{{ col.key }}"
										>
											{{ col.label | translate }}
											<span
												[dndDraggable]="col"
												[dndEffectAllowed]="'copy'"
												(dndEnd)="onDragEnd($event)"
											>
												<i class="fa-light fa-grip-vertical"></i>
											</span>
										</div>
									</ng-container>
								</div>
							</div>
						</ng-scrollbar>
					</div>
					<div class="column-right-sec card-bg full-height" style="min-height: 480px">
						<ng-scrollbar class="custom-scrollbar section-scrollbar" #scrollable>
							<div class="full-height flex-auto-height">
								<div class="px-1 pt-1">
									<div class="column-head-info flex-initial">
										<div class="info-box">
											<div class="info-sec">
												{{ "UI.k_column_config_descr" | translate }}
											</div>
										</div>
									</div>
								</div>
								<span class="col-heading px-1 title-color fw-500 flex-initial"
									>{{ "UI.k_selected" | translate }} {{ "UI.k_columns" | translate }}</span
								>
								<div class="selected-col flex-1" style="min-height: 366px">
									<!-- <ng-scrollbar class="custom-scrollbar section-scrollbar"> -->
									<section
										dndDropzone
										(dndDragover)="onDragover($event)"
										(dndDrop)="onDrop($event, selectedCols, $event.event.target, 'column')"
										(dndEnd)="onDragEnd($event)"
										class="dndDropArea px-1 pb-1 pt-50"
									>
										<ng-container *ngFor="let sel_col of selectedCols; let i = index">
											<div
												class="single-selected-col"
												name="col_{{ i }}"
												id="column__{{ sel_col.label }}"
												attr.data-testid="column__{{ sel_col.label }}"
											>
												<div class="common-info">
													<span
														(dndStart)="onDragStart($event)"
														(dndCanceled)="onDragCanceled($event)"
														(dndMoved)="onDragged(sel_col, selectedCols, 'move')"
														(dndDragover)="onDragover($event)"
														(dndEnd)="onDragEnd($event)"
														[dndEffectAllowed]="'move'"
														[dndDraggable]="sel_col"
														class="moving-icon"
													>
														<i
															class="fa-light fa-grip-vertical"
															id="column__{{ sel_col.label }}__{{ i }}_i"
															attr.data-testid="column__{{ sel_col.label }}__{{ i }}_i"
														></i>
													</span>
													<span
														class="column-count"
														id="column__{{ sel_col.label }}__{{ i }}_column"
														attr.data-testid="column__{{ sel_col.label }}__{{ i }}_column"
														>{{ i + 1 }}
													</span>
													<span
														class="column-name"
														id="column__{{ sel_col.label }}"
														attr.data-testid="column__{{ sel_col.label }}"
													>
														{{ sel_col.label | translate }}
													</span>
													<button
														*ngIf="!(sel_col?.disabled == true)"
														type="button"
														(click)="removeSelColumn(i)"
														name="remove__{{ sel_col.label }}"
														id="btnRemove__{{ sel_col.label }}"
														attr.data-testid="btnRemove__{{ sel_col.label }}"
														class="btn btn-icon btn-sm btn-flat-danger"
														rippleEffect
													>
														<i class="fa-light fa-circle-xmark"></i>
													</button>
												</div>
												<!-- <div
												*ngIf="i < 3"
												class="ml-0 custom-control custom-switch custom-switch-sm"
											> -->
												<!-- <ng-container *ngIf="sel_col.freezed == true">
													<button
														type="button"
														[(ngModel)]="sel_col.freezed"
														(click)="unPinnedEvent(i, sel_col?.type)"
														[ngClass]="sel_col.freezed ? 'active' : ''"
														class="btn btn-sm btn-flat-primary"
														rippleEffect
													>
														<i class="fa-solid fa-thumbtack"></i>
														{{ "UI.k_pinned" | translate }}
													</button>
												</ng-container> -->
												<!-- <ng-container *ngIf="sel_col.freezed !== true">
													<button
														type="button"
														[(ngModel)]="sel_col.freezed"
														(click)="pinEvent(i, sel_col?.type)"
														[ngClass]="sel_col.freezed ? 'active' : ''"
														class="btn btn-sm btn-flat-primary"
														rippleEffect
													>
														<i class="fa-solid fa-thumbtack"></i>
														{{ "UI.k_pin" | translate }}
													</button>
												</ng-container> -->

												<!-- <input
                                                type="checkbox"
                                                [(ngModel)]="sel_col.freezed"
                                                [checked]="sel_col.freezed"
                                                [disabled]="sel_col.disabled"
                                                class="custom-control-input"
                                                id="btnFreeze__{{ sel_col.label }}"
                                                name="btnFreeze__{{ sel_col.label }}"
                                            />
                                            <label
                                                class="custom-control-label font-small-2"
                                                for="btnFreeze__{{ sel_col.label }}"
                                                >{{ 'UI.k_freeze_column' | translate }}
                                            </label> -->
												<!-- </div> -->
											</div>
										</ng-container>
									</section>
									<!-- </ng-scrollbar> -->
								</div>
							</div>
						</ng-scrollbar>
					</div>
				</div>
				<div class="modal-footer flex-initial border-top">
					<button
						type="button"
						id="btnClose"
						[attr.data-testid]="'btnClose'"
						name="btnClose"
						class="btn btn-outline-tertiary m-0 mr-1"
						data-dismiss="modal"
						aria-label="Close"
						(click)="closePopup(colForm)"
					>
						Close
					</button>
					<button
						type="button"
						(click)="saveSelCols()"
						class="btn btn-primary m-0"
						id="btnColConfSave"
						name="colConfSave"
						[attr.data-testid]="'btnColConfSave'"
					>
						{{ "UI.k_save" | translate }}
					</button>
				</div>
			</div>
		</form>
	</ng-template>
</div>
