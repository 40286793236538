import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
	name: 'diclen',
  })
  export class DictionarylenPipe implements PipeTransform {
	data:number;

	transform(value:any, args: any[]): any {
		if(typeof(value) == 'string')
		{
			return 0
		} 
		this.data=Object.keys(value).length
	  	return this.data
	}
  }
