<div class="full-body-height bg-body">
	<app-admin-skeleton *ngIf="!instanceSubscriptions"></app-admin-skeleton>
	<ng-scrollbar class="custom-scrollbar section-scrollbar">
		<div class="admin-user-detail border-bottom card-bg" *ngIf="instanceSubscriptions">
			<div class="user-detail" *ngIf="customerData && customerData.user !== undefined">
				<div class="user-basic-info">
					<div class="info-section">
						<div class="avatar" *ngIf="customerData.user?.avatar">
							<span class="avatar-content">
								<!-- <img src="" alt="Avatar" width="48" height="48" /> -->
								<img [src]="customerData.user.avatar" alt="Avatar" width="48" height="48" />
							</span>
						</div>
						<div
							*ngIf="!customerData.user?.avatar"
							class="avatar"
							[ngStyle]="{
								'background-color': customerData.user?.avatar_color
									? customerData.user?.avatar_color
									: '#f59518'
							}"
						>
							<span class="avatar-content">{{ customerData.user.full_name | initials }} </span>
						</div>
						<div class="user-info">
							<span class="title-color">{{ customerData.user.full_name }}</span>
							<div class="badge badge-light-primary">{{ customerData.user.role_name }}</div>
						</div>
					</div>
					<!-- <div class="edit-section primary-color">
						<i class="fa-light fa-pen-to-square mr-50"></i>
						{{ "UI.k_edit" | translate }}
					</div> -->
				</div>
				<div class="user-contact-info">
					<a
						class="sm-font body-color"
						href="mailto:{{ customerData.user.email }}"
						title="{{ customerData.user.email }}"
						><i class="fa-light fa-envelope text-muted-color sm-font mr-50"></i
						>{{ customerData.user.email }}</a
					>
					<a
						class="sm-font body-color"
						href="tel:{{ customerData.user.phone_number.number }}"
						title="{{ customerData.user.phone_number.number }}"
						><i class="fa-light fa-phone text-muted-color sm-font mr-50"></i
						>{{ customerData.user.phone_number.internationalNumber }}</a
					>
					<!-- <a class="sm-font body-color" href="javascript:void(0);">
						<span>{{ "UI.k_organization_name" | translate }} : </span>
						<span>{{ customerData.organization.company_name }}</span>
					</a> -->
				</div>
			</div>
			<div class="company-detail" *ngIf="customerData && customerData.organization !== undefined">
				<!-- <div class="company-logo-deatil">
					<div class="company-logo">
						<img src="assets/images/infraon_logo.svg" alt="{{ customerData.organization.company_name }}" />
					</div>
				</div> -->
				<div class="company-info">
					<div>
						<span class="sm-font body-color">{{ "UI.k_organization_name" | translate }} : </span>
						<span class="sm-font body-color">{{ customerData.organization.company_name }}</span>
					</div>
					<!-- <span class="sm-font body-color company-website">
						{{ customerData.user.email.split("@")[1] }}
					</span> -->
				</div>
			</div>
			<!-- <div class="kyc-detail border-left">
				<span class="title-color sm-font fw-500">{{ "UI.k_complete_kyc" | translate }}</span>
			</div> -->
			<div class="edit-user-details">
				<button
					type="button"
					id="btnEditUser"
					name="btnEditUser"
					class="btn btn-icon btn-flat-primary"
					(click)="toggleCInfo('edit-user-detail')"
					rippleEffect
					placement="left"
					tooltipClass="fadeInAnimation"
					ngbTooltip="{{ 'UI.k_edit_information' | translate }}"
				>
					<i class="fa-light fa-pen-to-square mr-50"></i>
				</button>
			</div>
		</div>
		<div class="instance-list" *ngIf="instanceSubscriptions">
			<ng-container *ngFor="let info of instanceSubscriptions; let i = index">
				<div class="single-instance card-bg border">
					<div class="instance-header d-flex justify-content-between border-bottom">
						<div class="left-sec d-flex align-items-center min-ht">
							<h6
								class="mr-1 mb-0"
								*ngIf="!instanceConfigMap[info.data[0].instance.instance_id]?.is_cancelled"
							>
								<!-- cursor-pointer -->
								<!-- (click)="getInstanceDetail(info.data[0].instance.instance_id)" -->
								{{ info.data[0].instance.sub_domain }}
							</h6>
							<div
								class="instance-url"
								*ngIf="!instanceConfigMap[info.data[0].instance.instance_id]?.is_cancelled"
							>
								<a
									[routerLink]=""
									placement="right"
									tooltipClass="fadeInAnimation"
									ngbTooltip="{{ 'UI.k_login_app' | translate }}"
									(click)="ssoToInstance($event, info.data[0].instance.sub_domain)"
								>
									<i class="fa-light fa-up-right-from-square ml-50"></i>
								</a>
							</div>
						</div>
						<div class="right-sec d-flex align-items-center">
							<ng-container
								*ngIf="
									!instanceConfigMap[info.data[0].instance.instance_id]?.is_trial &&
									instanceConfigMap[info.data[0].instance.instance_id]?.status === 'active'
								"
							>
								<button
									*ngIf="
										instanceConfigMap[info.data[0].instance.instance_id]?.show_buy && !isPartnerOrg
									"
									type="button"
									class="btn btn-primary mr-1"
									(click)="togglePublishModal(deskModal, info.data, 'edit')"
									rippleEffect
								>
									{{ "UI.k_manage_subscription" | translate }}
								</button>
								<div class="bg-body body-color fw-500 sm-font mr-1 invoice-date" *ngIf="!isPartnerOrg">
									<span>{{ "UI.k_next_invoice_issue_date" | translate }}</span>
									<span class="ml-50">{{
										info.data[0].subscription.next_billing_at * 1000 | date: "MMM d, y"
									}}</span>
								</div>
								<div class="prize d-flex align-items-center mr-1" *ngIf="!isPartnerOrg">
									<h5 class="fw-600 mb-0">
										{{
											sumInstanceMap[info.data[0].instance.instance_id]
												| currency: info.data[0].subscription.currency_code
										}}
										/
									</h5>
									<span class="fw-500 title-color ml-50">{{
										info.data[0].subscription.billing_period_unit | translate
									}}</span>
								</div>
								<!-- <div>
									<button
										type="button"
										class="btn btn-primary"
										(click)="togglePublishModal(deskModal, info.data, 'edit')"
										rippleEffect
									>
										{{ "UI.k_manage_plan" | translate }}
									</button>
								</div> -->
							</ng-container>
							<ng-container *ngIf="instanceConfigMap[info.data[0].instance.instance_id]?.is_trial">
								<span
									class="trial-mode"
									*ngIf="!instanceConfigMap[info.data[0].instance.instance_id]?.is_cancelled"
									>{{ "UI.k_trial_mode" | translate }}</span
								>
								<button
									*ngIf="
										instanceConfigMap[info.data[0].instance.instance_id]?.show_buy && !isPartnerOrg
									"
									type="button"
									class="btn btn-primary"
									(click)="togglePublishModal(deskModal, info.data, 'add')"
									rippleEffect
								>
									{{ "UI.k_subscribe_premium_plan" | translate }}
								</button>
								<span
									class="trial-mode"
									*ngIf="instanceConfigMap[info.data[0].instance.instance_id]?.is_cancelled"
									>{{ "UI.k_trial_mode" | translate }} - {{ "UI.k_stopped" | translate }}</span
								>
								<button
									*ngIf="instanceConfigMap[info.data[0].instance.instance_id]?.show_reactivate"
									type="button"
									class="btn btn-primary ml-1"
									(click)="reactivateNextCycle(info.data)"
									rippleEffect
								>
									{{ "Message.msg_reactivate_days" | translate }}
								</button>
							</ng-container>
							<ng-container
								*ngIf="
									!instanceConfigMap[info.data[0].instance.instance_id]?.is_trial &&
									instanceConfigMap[info.data[0].instance.instance_id]?.status === 'cancelled'
								"
							>
								<span
									class="trial-mode bg-danger"
									*ngIf="instanceConfigMap[info.data[0].instance.instance_id]?.is_cancelled"
									>{{ "UI.k_subscription" | translate }} - {{ "UI.k_stopped" | translate }}</span
								>
								<button
									*ngIf="
										instanceConfigMap[info.data[0].instance.instance_id]?.show_buy && !isPartnerOrg
									"
									type="button"
									class="btn btn-primary mr-1"
									(click)="togglePublishModal(deskModal, info.data, 'edit')"
									rippleEffect
								>
									{{ "UI.k_manage_subscription" | translate }}
								</button>
							</ng-container>
						</div>
					</div>
					<div class="instance-body">
						<ng-container *ngFor="let data of info.data">
							<div class="single-product border-right" *ngIf="data?.item?.type === 'plan'">
								<div class="basic-info">
									<div class="product-logo">
										<img src="{{ data.product.img_path || null }}" alt="{{ data.product.name }}" />
									</div>
									<div class="info">
										<span class="title-color fw-600 mb-50">{{ data.item.name }}</span>
										<!-- {{ data.item_price.name | trim_plan }} -->
										<div class="badge badge-light-warning">
											{{ "UI.k_currency" | translate }} :&nbsp;
											{{ data.item_price.currency_code }}
										</div>
										<div class="badge badge-light-warning mt-half">
											{{ "UI.k_billing_cycle" | translate }} :&nbsp;
											{{ data.item_price.period_unit }}
										</div>
									</div>
								</div>
								<div
									class="user-detail inner-detail bg-body"
									*ngIf="data?.subscription?.meta_data?.COUNT?.user"
								>
									<span class="sm-font detail-logo"><i class="fa-light fa-user-group"></i></span>
									<div class="user-detail d-flex align-items-center sm-font mr-1">
										<div class="active-user">{{ usageObj?.user }}</div>
										&nbsp;/&nbsp;
										<div class="total-user">
											{{ allotedUsersMap[info.data[0].instance.instance_id] }}
										</div>
										<!-- <div class="total-user" *ngIf="data?.product?.id === 'Infraon-Helpdesk'">
											&infin;
										</div> -->
										<span class="ml-50">{{ "UI.k_users" | translate }}</span>
									</div>
									<div class="instace-progress user-progres">
										<ngb-progressbar
											[type]="colorInstanceMap[info.data[0].instance.instance_id]?.user"
											[max]="allotedUsersMap[info.data[0].instance.instance_id]"
											[value]="usageObj?.user"
											height=".5rem"
										></ngb-progressbar>
									</div>
								</div>
								<ng-container>
									<div
										class="assets-detail inner-detail bg-body"
										*ngIf="data?.subscription?.meta_data?.COUNT?.asset"
									>
										<span class="sm-font detail-logo"><i class="fa-light fa-box-taped"></i></span>
										<div class="assets-detail d-flex align-items-center sm-font mr-1">
											<div class="active-assets">{{ usageObj?.asset }}</div>
											&nbsp;/&nbsp;
											<div class="total-assets">
												{{ allotedAssetsMap[info.data[0].instance.instance_id] }}
											</div>
											<span class="ml-50">{{ "UI.k_assets" | translate }}</span>
										</div>
										<div class="instace-progress assets-progres">
											<ngb-progressbar
												[type]="colorInstanceMap[info.data[0].instance.instance_id]?.asset"
												[max]="allotedAssetsMap[info.data[0].instance.instance_id]"
												[value]="usageObj?.asset"
												height=".5rem"
											></ngb-progressbar>
										</div>
									</div>
								</ng-container>
							</div>
						</ng-container>
					</div>
				</div>
			</ng-container>
		</div>
		<div class="infraon-product-list border card-bg" *ngIf="instanceSubscriptions">
			<div class="left-section">
				<div class="main-detail">
					<h3 class="fw-600">{{ "Message.msg_explore_prod" | translate }}</h3>
					<span class="sm-font">{{ "Message.msg_explore_prod_details" | translate }}</span>
				</div>
			</div>
			<div class="right-section infraon-product-section">
				<ng-container *ngFor="let productGroup of infraonProduct.groups">
					<div
						class="product-group single-group-row"
						[ngClass]="
							productGroup.productList.length > 2
								? 'three-col'
								: productGroup.productList.length > 1
								? 'two-col'
								: productGroup.productList.length > 0
								? 'one-col'
								: ''
						"
					>
						<h6 class="product-group-title fw-600">{{ productGroup.groupTitle }}</h6>
						<div
							class="product-list"
							[ngClass]="
								productGroup.productList.length > 2
									? 'three-col'
									: productGroup.productList.length > 1
									? 'two-col'
									: productGroup.productList.length > 0
									? 'one-col'
									: ''
							"
						>
							<ng-container *ngFor="let product of productGroup.productList">
								<!-- <div class="single-product cursor-pointer" (click)="getProductDetail(product.id)">
									<div class="product-logo">
										<img src="{{ product.img_path || '' }}" alt="{{ product.name }}" />
									</div>
									<div class="info">
										<span class="title-color fw-500">{{ product.name }}</span>
										<span class="sm-font">{{ product.description }}</span>
									</div>
								</div> -->
								<a
									class="single-product cursor-pointer"
									[href]="product.url"
									target="_blank"
									*ngIf="product.is_category"
								>
									<div class="product-logo">
										<img src="{{ product.img_path || '' }}" alt="{{ product.name }}" />
									</div>
									<div class="info">
										<span class="title-color fw-500">{{ product.name }}</span>
										<span class="sm-font body-color">{{ product.description }}</span>
									</div>
								</a>
							</ng-container>
						</div>
						<hr />
					</div>
				</ng-container>
				<!-- <div -->
				<!-- (click)="getProductDetail(infinityProduct.id)" -->
				<a
					class="infraon-product-info cursor-pointer"
					*ngIf="infinityProduct"
					[href]="infinity_url"
					target="_blank"
				>
					<div class="infraon-product-logo">
						<img src="{{ infinityProduct.img_path }}" alt="infinityProduct.name" />
					</div>
					<div class="infraon-info">
						<h6 class="fw-600 white-color">{{ infinityProduct.name }}</h6>
						<span class="sm-font white-color">{{ "UI.k_infraon_infinity_desc" | translate }}</span>
					</div>
					<!-- </div> -->
				</a>
			</div>
		</div>
	</ng-scrollbar>
</div>

<!--Preview modal template starts-->
<ng-template #deskModal let-modal>
	<div class="modal-header border-bottom">
		<div class="d-flex align-items-center">
			<!-- <div class="instance-header-img-box mr-1">
			</div> -->
			<div class="instance-header-detail-box m-1">
				<h5 class="mb-25 mr-50">A Complete Instance Subscription</h5>
				<!-- <div class="d-flex align-items-center">
				</div> -->
			</div>
		</div>
	</div>
	<div class="modal-body card-bg" tabindex="0" ngbAutofocus>
		<form>
			<div>
				<ng-container *ngFor="let data of instanceList; let i = index">
					<div class="custom-control custom-radio custom-card-radio mb-2">
						<input
							type="radio"
							id="{{ data.instance_id }}"
							class="custom-control-input cursor-pointer"
							[formControl]="instanceForm"
							(change)="onInstanceChange(data.instance_id)"
							[checked]="data.instance_id === instanceForm.value"
						/>
						<label class="custom-control-label" for="{{ data.instance_id }}">
							<div class="d-flex align-items-center justify-content-between w-100 pr-2">
								<div class="d-flex align-items-center">
									<h6 class="radio-title mb-0 mr-1 fw-600">{{ data.sub_domain }}</h6>
									<a href="" class="sm-font">
										<i class="fa-light fa-arrow-up-right-from-square ml-50"></i>
									</a>
								</div>
								<div class="d-flex align-items-center">
									<ng-container *ngIf="modalInstanceType === 'trial'">
										<div class="badge badge-light-dark fw-500">Trial Ends on Xxx XX,YYYY</div>
									</ng-container>
									<ng-container *ngIf="modalInstanceType === 'prod'">
										<div class="badge badge-light-dark mr-1 fw-500">
											Next invoice issue date XXX XX,YYYY
										</div>
										<div class="sm-font"><span class="h6">$ XX</span> / month</div>
									</ng-container>
								</div>
							</div>
						</label>
					</div>
				</ng-container>
				<div class="custom-control custom-radio custom-card-radio mb-2 instance-create-radio">
					<input
						class="custom-control-input cursor-pointer"
						type="radio"
						id="chkNewInstance-{{ modalInstanceType }}"
						[formControl]="instanceForm"
						(change)="onInstanceChange(modalInstanceType)"
						[checked]="modalInstanceType === instanceForm.value"
					/>
					<label class="custom-control-label" for="chkNewInstance-{{ modalInstanceType }}">
						<div class="d-flex align-items-center justify-content-between w-100 pr-2">
							<div class="d-flex align-items-center">
								<h6 class="text-primary mb-0 mr-1">Create a new Instance</h6>
							</div>
						</div>
					</label>
				</div>
			</div>
			<div class="d-flex justify-content-end stepper-btn flex-initial mt-2 pb-0 modal-footer border-top">
				<label *ngIf="instanceForm.invalid" class="text-warn">Please select an instance</label>
				<button type="button" class="btn btn-outline-tertiary btn-prev" (click)="closePopup()" rippleEffect>
					<span class="align-middle d-sm-inline-block d-none">Cancel</span>
				</button>
				<button type="submit" class="btn btn-primary btn-next ml-1" (click)="proceedToPayment()" rippleEffect>
					<span class="align-middle d-sm-inline-block d-none">Proceed to Payment</span>
				</button>
			</div>
		</form>
	</div>
</ng-template>
<!--Preview modal template ends-->

<!-- <core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="edit-org" overlayClass="modal-backdrop">
	<app-org-info *ngIf="hideConfig" (onHideProfile)="onHideProfile($event)"></app-org-info>
</core-sidebar> -->
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="edit-user-detail"
	overlayClass="modal-backdrop"
>
	<app-edit-admin-user *ngIf="hideConfig" (onHideProfile)="onHideProfile($event)"></app-edit-admin-user>
</core-sidebar>
