import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SelectPickerFeatureService } from "../../../../base/select-picker-feature/services/select-picker-feature.service";

@Component({
	selector: "dns-option-picker",
	templateUrl: "./dns-option-picker.component.html",
	styleUrls: ["./dns-option-picker.component.scss"],
})
export class DnsOptionPickerComponent implements OnInit {
	//   optionList: any;
	@Input() inputConfig: any;
	@Input() options: any = [];
	@Input() value: any = null;
	@Input() isclearable = false;
	@Input() showLabel = true;
	@Output() setValue = new EventEmitter<any>();
	@Input("search-primary-key") searchPrimaryKey = "name";
	@Input("search-secondary-key") searchSecondaryKey = "state";
	@Input() dropdownPos: string = "";
	@Input("is_required") isRequired = false;
	@Input("form_ref") formRef;

	constructor(public _statusService: SelectPickerFeatureService) {}

	ngOnInit(): void {}

	onSelect = ($event) => {
		this.setValue.emit($event);
	};

	customSearchFn = (term: string, item: any) => {
		term = term.toLowerCase();

		// Creating and array of space saperated term and removinf the empty values using filter
		const splitTerm = term.split(" ").filter((t) => t);

		let isWordThere = [];

		// Pushing True/False if match is found
		splitTerm.forEach((arr_term) => {
			const searchStatus = item[this.searchPrimaryKey]?.toLowerCase();
			const searchState = item[this.searchSecondaryKey]?.toLowerCase() || "";
			isWordThere.push(searchStatus.indexOf(arr_term) !== -1 || searchState.indexOf(arr_term) !== -1);
		});
		const all_words = (this_word) => this_word;
		// Every method will return true if all values are true in isWordThere.
		return isWordThere.every(all_words);
	};
}
