export const WORKFLOW_TEMPLATE_TYPE = {
	CONFIG_CONDITIONS: 1,
	SEND_EMAIL: 2,
	SET_STATUS: 3,
	SET_VALUE: 4,
	STATE: 5,
	SEND_SMS: 6,
	ASK_FOR_APPROVAL: 7,
	CONFIG_NOTIFICATION_API: 8,
	CONFIG_STATUS: 9,
	CONFIG_SMART_EVENT: 10,
	APPROVAL_ACCEPTED: 11,
	APPROVAL_REJECTED: 12,
	ASK_FOR_REVIEW: 13,
	REVIEW_ACCEPTED: 14,
	REVIEW_REJECTED: 15,
	CONFIG_APPROVAL_NOTIFICATION: 16,
	CONFIG_REVIEW_NOTIFICATION: 17,
};
