import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, DoCheck } from "@angular/core";
import cloneDeep from "lodash-es/cloneDeep";
import uniq from "lodash-es/uniq";

@Component({
	selector: "app-custom-theme",
	templateUrl: "./custom-theme.component.html",
	styleUrls: ["./custom-theme.component.scss"],
})
export class CustomThemeComponent implements OnInit, DoCheck {
	public dynamicDropDown = {
		id: 1,
		label: "three_level",
		length: 3,
		labelArray: ["is", "Associated to"],
		value: [],
		operator: null,
	};
	public multiPleComponentArray: any = [];
	public selectedOperator = null;
	public appliedForTabData = [];

	@Input() dropdownInputDetails: any;
	@Input() optionsList: any;
	@Output() emittedFromCustomTheme: EventEmitter<any> = new EventEmitter();
	@Output() getOptions: EventEmitter<any> = new EventEmitter();
	prev_value_list: any = [];
	current_id: any = -1;

	constructor() {}

	ngOnInit(): void {
		this.dropdownInputDetails.forEach((element) => {
			this.dynamicDropDown.value = [];
			this.dynamicDropDown.operator = element.operator;
			this.setDropdownDetails(this.dynamicDropDown.value, element);
			this.prev_value_list.push(element.assignOptions);
			let dynamic_dropDown = cloneDeep(this.dynamicDropDown);
			this.multiPleComponentArray.push(dynamic_dropDown);
			this.dynamicDropDown.id = this.dynamicDropDown.id + 1;
		});
		this.multiPleComponentArray = uniq(this.multiPleComponentArray, "id");
		this.multiPleComponentArray.forEach((element) => {
			this.formAppliedData({ data: element });
		});
	}

	formAppliedData(event) {
		if (event && event.data) {
			let customObject = this.createAppliedForRawData(event.data.value, event.data.operator, event.data.id);
			this.current_id = event.data.id - 1;
			if (customObject["value"] && customObject["conditionOption"] && customObject["serviceOption"]) {
				if (this.appliedForTabData.filter((id) => id.id === customObject["id"]).length > 0) {
					this.appliedForTabData.forEach((e, index) => {
						if (e.id === customObject["id"]) {
							this.appliedForTabData.splice(index, 1);
						}
					});
				}
				this.appliedForTabData.push(customObject);
				this.appliedForTabData = uniq(this.appliedForTabData, "id");
			}
		}
	}

	ngDoCheck() {
		this.dropdownInputDetails.forEach((element, index) => {
			if (this.prev_value_list[index] != element?.assignOptions) {
				this.multiPleComponentArray[index].value.forEach((e) => {
					if (e.id === "third_dropdown") {
						e.data = element?.assignOptions;
					}
				});
				this.prev_value_list[index] = element?.assignOptions;
			}
		});
	}

	setDropdownDetails(array, dropdown_input) {
		if (this.dropdownInputDetails) {
			const first_dropdown = {
				data: this.optionsList?.condition_type_list,
				id: "first_dropdown",
				ng_model_value: dropdown_input.selectedTypeVal ? dropdown_input.selectedTypeVal : null,
				allow: true,
				multiple: false,
			};

			const second_dropdown = {
				data: this.optionsList?.conditionOptions,
				id: "second_dropdown",
				ng_model_value: dropdown_input.selectedCondVal ? dropdown_input.selectedCondVal : null,
				allow: dropdown_input?.selectedCondVal ? true : false,
				multiple: false,
			};
			const third_dropdown = {
				data: dropdown_input.assignOptions ? dropdown_input.assignOptions : this.optionsList?.assignOptions,
				id: "third_dropdown",
				ng_model_value: dropdown_input.selectedValue ? dropdown_input.selectedValue : null,
				allow: dropdown_input?.selectedValue ? true : false,
				multiple: true,
			};
			array.push(first_dropdown);
			array.push(second_dropdown);
			array.push(third_dropdown);
		}
	}

	createAppliedForTabObject(dataArray, operator, id) {
		let payloadObject = {};
		dataArray.forEach((e) => {
			if (e.id === "first_dropdown" && e.ng_model_value) {
				payloadObject["serviceOption"] = e.ng_model_value;
			}
			if (e.id === "second_dropdown" && e.ng_model_value) {
				payloadObject["conditionOption"] = e.ng_model_value;
			}
			if (e.id === "third_dropdown" && e.ng_model_value) {
				// let customPayload = []
				// e.ng_model_value.forEach(element => {
				//   customPayload.push({ id: element.id, name: element.name })
				// });
				payloadObject["value"] = e.ng_model_value;
			}
			// payloadObject["operator"] = operator
			payloadObject["id"] = id;
		});
		return payloadObject;
	}
	emittedFromParent(event) {
		if (event && event.data) {
			this.multiPleComponentArray.push(event.data);
			this.multiPleComponentArray = uniq(this.multiPleComponentArray, "id");
			let customObject = this.createAppliedForTabObject(event.data.value, event.data.operator, event.data.id);
			this.current_id = event.data.id - 1;
			if (
				customObject["serviceOption"] &&
				customObject["conditionOption"] == undefined &&
				customObject["value"] == undefined
			) {
				this.getOptions.emit(customObject);
			}
			if (customObject["value"] && customObject["conditionOption"] && customObject["serviceOption"]) {
				if (this.appliedForTabData.filter((id) => id.id === customObject["id"]).length > 0) {
					this.appliedForTabData.forEach((e, index) => {
						if (e.id === customObject["id"]) {
							this.appliedForTabData.splice(index, 1);
						}
					});
				}
				this.appliedForTabData.push(customObject);
				this.appliedForTabData = uniq(this.appliedForTabData, "id");
				this.emittedFromCustomTheme.emit(this.appliedForTabData);
			}
		}
	}

	newRowDataEntry(item, e) {
		const newEntry = {
			id: ++this.multiPleComponentArray.length,
			label: "three_level",
			length: 3,
			labelArray: ["is", "Associated to"],
			value: [],
			// operator: e.operator
		};
		this.setDropdownDetails(newEntry.value, {});
		this.multiPleComponentArray.push(newEntry);
		this.multiPleComponentArray = uniq(this.multiPleComponentArray, "id");
		this.multiPleComponentArray.forEach((element, index) => {
			if (element === undefined) {
				this.multiPleComponentArray.splice(index, 1);
			}
			if (element && element.id === item.id) {
				element.operator = e;
			}
		});
		this.prev_value_list.push(this.optionsList?.assignee_options);
	}

	operatorDropdownEvent(e, item) {
		if (this.appliedForTabData.length > 0) {
			this.appliedForTabData.forEach((ele) => {
				if (ele.id === item.id) {
					ele.operator = e;
				}
			});

			if (e && (e.operator === "and" || e.operator === "or") && !item.operator) {
				this.newRowDataEntry(item, e);
			} else if (
				(item.operator === "and" || item.operator === "or") &&
				this.multiPleComponentArray.filter((id) => !id.operator).length === 0
			) {
				this.newRowDataEntry(item, e);
			} else if (e && e.operator === "null" && !item.operator && this.multiPleComponentArray.length > 1) {
				this.multiPleComponentArray.forEach((e, index) => {
					if (e.id === item.id) {
						this.multiPleComponentArray.splice(index, 1);
					}
				});
			}
			this.emittedFromCustomTheme.emit(this.appliedForTabData);
			// if (this.appliedForTabData.filter(id => id.id === item.id).length > 0 && item.operator !== null) {
			//   this.appliedForTabData.forEach(e => {
			//     if (e.id === item.id) {
			//       e.id === e.operator
			//     }
			//   })

			// } else {

			//   if (e && (e.operator === 'and' || e.operator === 'or')) {
			//     const newEntry = {
			//       id: ++this.multiPleComponentArray.length,
			//       label: "three_level",
			//       length: 3,
			//       labelArray: ['is generate', 'Assign it to'],
			//       value: [],
			//       operator: e.operator
			//     };
			//     this.setDropdownDetails(newEntry.value);
			//     this.multiPleComponentArray.push(newEntry);
			//     this.multiPleComponentArray = uniq(this.multiPleComponentArray, 'id');
			//     this.multiPleComponentArray.forEach((e, index) => {
			//       if (e === undefined) {
			//         this.multiPleComponentArray.splice(index, 1)
			//       }

			//     })

			//   }
			// }
		}
	}

	createAppliedForRawData(dataArray, operator, id) {
		let payloadObject = {};
		dataArray.forEach((e) => {
			if (e.id === "first_dropdown" && e.ng_model_value) {
				payloadObject["serviceOption"] = e.ng_model_value;
			}
			if (e.id === "second_dropdown" && e.ng_model_value) {
				payloadObject["conditionOption"] = e.ng_model_value;
			}
			if (e.id === "third_dropdown" && e.ng_model_value) {
				payloadObject["value"] = e.ng_model_value;
			}
			// payloadObject["operator"] = operator
			payloadObject["id"] = id;
		});
		return payloadObject;
	}
}
