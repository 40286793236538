import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import "hammerjs";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgbProgressbarModule } from "@ng-bootstrap/ng-bootstrap"; //demo data progressbar
import { TranslateModule } from "@ngx-translate/core";
// import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from "ngx-toastr"; // For auth after login toast

import { CoreModule } from "@core/core.module";
import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule, CoreThemeCustomizerModule } from "@core/components";

import { coreConfig } from "app/app-config";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/common/layout/layout.module";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { NotificationModule } from "./base/notification/notification.module";
import { reducers, metaReducers } from "./store/reducers";
import { DnsHttpInterceptorService } from "./common/interceptor/dns.http.interceptor.service";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { AppRoutingModule } from "./app-routing.module";
import { HotkeyModule } from "angular2-hotkeys";
import { NavbarModule } from "./common/layout/components/navbar/navbar.module";
import { SystemMenuModule } from "./base/system-menu/system-menu.module";
import { WSSService } from "./common/services/wss/wss.service";
import { NgEventBus } from "ng-event-bus";
import { NotifyBannerModule } from "./common/notify-banner/notify-banner.module";

import { AdminModule } from "./admin/admin.module";
import { NgxCaptchaModule } from "ngx-captcha";
import { environment } from '../environments/environment';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { BotsConfigurationModule } from "./base/bots-configuration/bots-configuration.module";
import { BotsConfigurationRoutingModule } from "./base/bots-configuration/bots-configuration-routing.module";
import { WssAgentService } from "./common/services/wss-agent/wss-agent.service";
import { IdleService } from "./services/idle.service";


const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
//   console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.microsoft_client_id,
      // authority: "https://login.microsoftonline.com/106d58b3-1cea-4f9b-bbcd-526356d9e5f2",
      // clientId: "b5c2e510-4a17-4feb-b219-e55aa5b74144",
      // clientId: '3fba556e-5d4a-48e3-8e1a-fd57c12cb82e', // PPE testing environment
      authority: "https://login.microsoftonline.com/common",
    //   authority: "https://login.microsoftonline.com/organization",
      // authority: 'https://login.windows-ppe.net/common', // PPE testing environment
      redirectUri: '/msso',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  // protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']); // PPE testing environment

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: '/login-failed'
  };
}


@NgModule({
	declarations: [AppComponent],
	imports: [
		NavbarModule,
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		TranslateModule.forRoot(),
		AppRoutingModule,
		HotkeyModule.forRoot(),
		AdminModule,
		// NgBootstrap
		NgbModule,
		ToastrModule.forRoot(),
		RecaptchaV3Module,
		// Core modules
		CoreModule.forRoot(coreConfig),
		CoreCommonModule,
		CoreSidebarModule,
		CoreThemeCustomizerModule,
		NotificationModule,
		// App modules
		LayoutModule,

		// for extracting csrf token
		HttpClientXsrfModule.withOptions({
			cookieName: "csrftoken",
			headerName: "X-CSRFToken",
		}),

		// ngrx
		StoreModule.forRoot(reducers, { metaReducers }),
		EffectsModule.forRoot([]),
		StoreRouterConnectingModule.forRoot(),
		NotifyBannerModule,
		SystemMenuModule,
		NgxCaptchaModule,
		NgbProgressbarModule,
		MsalModule,
		SocialLoginModule,
        BotsConfigurationModule,
        BotsConfigurationRoutingModule
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: DnsHttpInterceptorService,
			multi: true,
		},
		IdleService,
		WSSService,
		WssAgentService,
		NgEventBus,
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useValue: environment.recaptcha.siteKey,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MsalInterceptor,
			multi: true
		  },
		  {
			provide: MSAL_INSTANCE,
			useFactory: MSALInstanceFactory
		  },
		  {
			provide: MSAL_GUARD_CONFIG,
			useFactory: MSALGuardConfigFactory
		  },
		  {
			provide: MSAL_INTERCEPTOR_CONFIG,
			useFactory: MSALInterceptorConfigFactory
		  },
		  MsalService,
		  MsalGuard,
		  MsalBroadcastService,
		  {
			provide: 'SocialAuthServiceConfig',
			useValue: {
			  autoLogin: false,
			  providers: [
				{
				  id: GoogleLoginProvider.PROVIDER_ID,
				  provider: new GoogleLoginProvider(
					environment.google_client_id,
				  )
				},
				
			  ],
			  onError: (err) => {
				console.error(err);
			  }
			} as SocialAuthServiceConfig,
		  }
	],
	bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
