import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-skeleton',
  templateUrl: './card-skeleton.component.html',
  styleUrls: ['./card-skeleton.component.scss']
})
export class CardSkeletonComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
        this.generateFake(5);
    }

    generateFake(count: number): Array<number> {
        const indexes = [];
        for (let i = 0; i < count; i++) {
            indexes.push(i);
        }
        return indexes;
    }

}
