import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgEventBus } from "ng-event-bus";

@Component({
	selector: "simple-table",
	templateUrl: "./simple-table.component.html",
	styleUrls: ["./simple-table.component.scss"],
})
export class SimpleTableComponent implements OnInit {
	@Input() tableObj: any;
	@Input() modifiedtime: any;
	@Input() quickFilter = false;
	@Input() scrollTable = true;
	@Input() showSlno = false;
	@Input() emptyData = "-";
	@Output() onCellClick: EventEmitter<any> = new EventEmitter<any>();
	@Output() sort: EventEmitter<any> = new EventEmitter<any>();
	private enable_sort = false;

	constructor(
		private eventBus: NgEventBus
	) {}

	ngOnInit(): void {
		if (this.sort) {
			this.enable_sort = true;
		}
		// console.log("tableObj >> ", this.tableObj)
	}

	onSearch = (search_str) => {};

	onCellActionClick = (item, columnData) => {
		if (this.onCellClick) {
			const data = {
				rowData: item,
				columnData: columnData,
			};
			this.onCellClick.emit(data);
		}
	};

	getTranslatableKey = (key: string) => {
		if (key) {
			if (key.startsWith("k_")) {
				key = `UI.${key}`;
			} else if (key.startsWith("msg_")) {
				key = `Message.${key}`;
			} else if (key.startsWith("err_")) {
				key = `Error.${key}`;
			}
		}
		return key;
	};

	onSort(args): void {
		this.sort?.emit(args);
	}
	showInciSidebar= (event, col, value) => {
		if (col?.key == "name" || !value){
			return
		}
		if (event?.item_filter) {
			if (col?.key && col?.key != 'total') {
				event.item_filter.filters['basic_info.state.id'] = Number(col?.key)
			}
			else{
				if (event.item_filter.filters['basic_info.state.id'] != undefined){
					delete event.item_filter.filters['basic_info.state.id']
				}
			}
			let payload = {
				"title": "Tickets - " + event.name,
				"app_type": "inci-grid",
				"item_filter": event.item_filter,
			}
			// console.log("in inci summary widget -> ", payload)
			this.eventBus.cast('dashboard-view:sidebar', payload);
		}
	}
	showInciSlaSidebar = (event, col, value) => {
		if (col?.key == "sla_profile" || !value) {
			return
		}
		if(event?.item_filter){
			if(col?.key == 'total_count') {
				event.item_filter.filters['incident_id__in'] = event?.incident_list
			}
			if(col?.key == 'breach_count') {
				event.item_filter.filters['incident_id__in'] = event?.breach_incident_list
			}
			let payload = {
				"title": "Tickets - " + event.sla_profile,
				"app_type": "inci-grid",
				"item_filter": event.item_filter,
			}
			this.eventBus.cast('dashboard-view:sidebar', payload);
		}
	}

	showRequestSlaSidebar = (event, col, value) => {
		if (col?.key == "sla_profile" || !value) {
			return
		}
		if(event?.item_filter){
			if(col?.key == 'total_count') {
				event.item_filter.filters['request_id__in'] = event?.request_list
			}
			if(col?.key == 'breach_count') {
				event.item_filter.filters['request_id__in'] = event?.breach_request_list
			}
			let payload = {
				"title": "Request - " + event.sla_profile,
				"app_type": "req-process-grid",
				"item_filter": event.item_filter,
			}
			this.eventBus.cast('dashboard-view:sidebar', payload);
		}
	}

	showLocSidebar = (event, col, value) => {
		if (col?.key == "name" || !value) {
			return
		}
		if(event?.item_filter && col?.key!= "Others"){
			if (col?.key == "Total") {
				delete event.item_filter["location.location_name"];
			} else {
				event.item_filter["location.location_name"] = col?.key;
			}
		}
		let payload = {
			"title": "Assets - " + event.name + " ( " + col?.key + " )",
			"app_type": "asset-grid",
			"item_filter": event.item_filter,
		}
		this.eventBus.cast('dashboard-view:sidebar', payload);
	}
}
