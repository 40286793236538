import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CoreConfigService } from "@core/services/config.service";
import { GlobalConfig, ToastrService } from "ngx-toastr";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { catchError, map } from "rxjs/operators";
import Swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { DOCUMENT } from "@angular/common";
import { Subject, throwError } from "rxjs";
import { UserService } from "../../services/user.service";
import { Breadcrumb } from "app/common/layout/components/content-header/breadcrumb/breadcrumb.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GlobalConstants } from "app/app.globalConstants";
import { InfinityModules } from "app/common/dns/types/modules";
import { customSearch } from "app/common/utils/utils";

@Component({
	selector: "app-users-card",
	templateUrl: "./users-card.component.html",
	styleUrls: ["./users-card.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class UsersCardComponent implements OnInit {
	@ViewChild("modDeletePopup") modDeletePopup: ElementRef;
	// users_card:any;
	currentEvent: String;
	currentUser: Number;
	private toastRef: any;
	private _options: GlobalConfig;
	showBreadcrumb: any = false;
	private _unsubscribeAll: Subject<any>;
	pageNumber = 1;
	limit = 10;
	sortKey: any = "full_name";
	reverse: any = "";
	ALLOWED_TYPES: Array<any>;
	MAX_IMAGE_SIZE;
	MAX_HEIGHT;
	MAX_WIDTH;
	tagList: Array<any>;
	tagTypeList: Array<any>;
	dashboardList: Array<any>;
	timezoneList: Array<any>;
	managerList: Array<any>;
	roleList: Array<any>;
	userView: any = "";
	firstKey = 0;
	finished = false;
	allUsers = [];
	notEmptyUser = true;
	notScrolly = true;
	selectedOption = 10;
	userCount = 0;
	totalCount = 0;
	cardCount = this.selectedOption;
	lastKey = this.cardCount - 1;
	noUserMsg = "No more users to show.";
	permissions: any;
	moduleName = "Users";
	module = InfinityModules.USER;
	hideProfile: boolean = false;
	public breadcrumbObj: Breadcrumb;
	hideInviteGrid: boolean = false;
	pendingInvitations: any = 0;
	selectedUser: any = {};
	selGrps: any = [];
	selServices: any = [];
	selDept: any = [];
	user_list: any = [];
	selectedProfile: any = [];
	used_modules: any = {};
	public defaultAvatarColor = "#f59518";
	public showData = false;
	public empty_data;

	public filterData = false;
	filter = [];
	// Search
	public showRightsection: false;
	public showDataTable = false;
	public savedData = [];
	public columnOptions = [];
	public conditionOptions = [];
	public option = [];

	public avatarBackgroundColor: string = "";
	options: {};
	public showAll = false;
	isExpandSearchWidth: boolean;
	// @HostListener('scroll', ['$event']) onScroll(event: any) {
	// 	// visible height + pixel scrolled >= total height
	// 	if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
	// 		console.log("End");
	// 	}
	// }
	// @HostListener("window:scroll") onWindowScroll() {
	// 	let scroll = window.pageYOffset ||
	// 				document.documentElement.scrollTop ||
	// 				document.body.scrollTop || 0;

	// 	const max = document.documentElement.scrollHeight -
	// 				document.documentElement.clientHeight;

	// 	if (scroll === max) {
	// 		this.users_card = []
	// 	}
	// }
	constructor(
		@Inject(DOCUMENT)
		private document,
		private _activatedRoute: ActivatedRoute,
		private _coreConfigService: CoreConfigService,
		private _coreSidebarService: CoreSidebarService,
		private router: Router,
		private _toastr: ToastrService,
		private _userService: UserService,
		private _translateService: TranslateService,
		private _modalService: NgbModal
	) {
		this._options = this._toastr.toastrConfig;
		// Configure the layout
	}

	userGrid = (name): void => {
		this.userView = name;
		this.router.navigateByUrl("/users");
	};
	userCard = (name): void => {
		this.userView = name;
		this.router.navigateByUrl("/users/card");
	};

	toggleAddUser = (name: string) => {
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
	};
	// toggleInvitedUsers = (name: string) => {
	// 	this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
	// };

	editUser = (args): void => {
		if (this.permissions?.edit) {
			this.currentEvent = "Edit";
			this.currentUser = args.profile_id;
			this._coreSidebarService.getSidebarRegistry("user-profile").toggleOpen();
			this.hideProfile = true;
		}
	};

	searchUser = (args) => {
		const payload = {
			page: this.pageNumber,
			items_per_page: this.limit,
			sort: this.sortKey,
			reverse: this.reverse,
			filterString: args.trim(),
			moduleName: "user",
		};
		this._userService.getUserProfiles(payload).subscribe(
			(response) => {
				if (response) {
					this.userCount = response.count;
					this.showData = false;
					this.users_card = [];
					setTimeout(() => {
						this.users_card = response.results;
						this.showData = true;
					}, 300);
				} else {
					this.showData = true;
				}
			},
			(error) => {
				//todo
			}
		);
	};

	searchparams = () => {
		var col = [
			{
				label: "Name",
				key: "name",
				type: "text",
				isselected: true,
			},
			{
				label: "Email",
				key: "email",
				type: "text",
				isselected: true,
			},
			{
				label: "Is Active",
				key: "active",
				type: "toggle",
				isselected: true,
			},
			{
				label: "Mobile",
				key: "phone",
				type: "text",
				isselected: true,
			},
			{
				label: "Role",
				key: "role",
				type: "select",
				isselected: true,
				data: [{ name: "Administrator" }, { name: "Manager" }],
			},
			{
				label: "Dashboard",
				key: "dashboard",
				type: "select",
				data: [{ name: "Dashboard1" }, { name: "Dashboard2" }, { name: "Dashboard3" }],
				isselected: false,
			},
			{
				label: "Timezone",
				key: "timezone",
				type: "select",
				data: [
					{
						name: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi",
						id: "+05:50",
					},
					{
						name: "(GMT -8:00) Pacific Time (US & Canada)",
						id: "-08:00",
					},
					{ name: "(GMT +9:30) Adelaide, Darwin", id: "+09:50" },
				],
				isselected: false,
			},
			{
				label: "Landline",
				key: "landline",
				type: "text",
				isselected: false,
			},
			{
				label: "Designation",
				key: "designation",
				type: "text",
				isselected: false,
			},
			{
				label: "Organization Role",
				key: "organization_role",
				type: "text",
				isselected: false,
			},
			{
				label: "Department",
				key: "department",
				type: "text",
				isselected: false,
			},
			{
				label: "Reporting Manager",
				key: "reporting_manager",
				type: "select",
				data: [{ name: "Manager1" }, { name: "Manager2" }, { name: "Manager3" }],
				isselected: false,
			},
		];
		return col;
	};

	public users_card;

	updateNavBar() {
		// Configure the layout
		if (this.permissions?.add) {
			this._coreConfigService.config = {
				layout: {
					navbar: {
						loadHTML: false,
						hidden: false,
						pageTitle: "Users",
						moduleName: "Users",
						pageIcon: "fa-users",
						//backgroundColor: "",
						buttonsRight: [
							{
								id: "btnUserCard",
								type: "button",
								callback: this.userGrid,
								class: "btn btn-flat-secondary",
								tooltip: "Grid View",
								label: "Grid View",
								attrs: "user",
								color: "secondary",
							},
							{
								id: "btnInviteGrid",
								icon: "list",
								type: "button",
								class: "btn-primary",
								tooltip: "Pending Invitations",
								label: "Pending Invitations",
								attrs: "invite-user-grid",
								callback: this.toggleInvitedUsers,
							},
							{
								id: "btnInviteUser",
								icon: "plus",
								type: "button",
								class: "btn-primary",
								tooltip: "Invite User(s)",
								label: "Invite User(s)",
								attrs: "add-user-profile",
								callback: this.toggleAddUser,
							},
						],
						search: [
							{
								popup: this.searchparams(),
								callback: this.searchUser,
							},
						],
						showBreadcrumb: this.showBreadcrumb,
						breadcrumb: {
							type: "",
							links: [
								{
									name: "User Management",
									isLink: true,
									link: "/configuration",
								},
								{
									name: "Users",
									isLink: false,
									link: "/",
								},
							],
						},
					},
				},
			};
		} else {
			this._coreConfigService.config = {
				layout: {
					navbar: {
						loadHTML: false,
						hidden: false,
						pageTitle: "Users",
						moduleName: "Users",
						pageIcon: "fa-users",
						//backgroundColor: "",
						buttonsRight: [
							{
								id: "btnUserCard",
								type: "button",
								callback: this.userGrid,
								class: "btn btn-flat-secondary",
								tooltip: "Grid View",
								label: "Grid View",
								attrs: "user",
								color: "secondary",
							},
							{
								id: "btnInviteGrid",
								icon: "list",
								type: "button",
								class: "btn-primary",
								tooltip: "Pending Invitations",
								label: "Pending Invitations",
								attrs: "invite-user-grid",
								callback: this.toggleInvitedUsers,
							},
						],
						search: [
							{
								popup: this.searchparams(),
								callback: this.searchUser,
							},
						],
						showBreadcrumb: this.showBreadcrumb,
						breadcrumb: {
							type: "",
							links: [
								{
									name: "Infraon Configuration",
									isLink: true,
									link: "/configuration",
								},
								{
									name: "Users",
									isLink: false,
									link: "/",
								},
							],
						},
					},
				},
			};
		}
	}

	ngOnInit(): void {
		this.getFilterOption();
		this.getSavedFilters();
		this.empty_data = {
			icon: "fa-user",
			msg: this._translateService.instant("Message.msg_user_skeleton_heading"),
			description: this._translateService.instant("Message.msg_user_skeleton_descr"),
			add_callback: this.toggleAddUser,
			sidebar_name: "add-user-profile",
			button: "UI.k_invite_user",
			import_csv: false,
			id: "btnInvUsr",
			csv_callback: this.csvImport,
		};

		this.avatarBackgroundColor = "#978ef2";
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.permissions = user_permissions[this.module];
		const sub = this._activatedRoute.queryParams.subscribe((params) => {
			if (params.showBreadcrumb) {
				this.showBreadcrumb = true;
			}
			this.updateNavBar();
		});
		this.getUserList();
		this.getPendingUserCount();
		this.getOptions();
		this.breadcrumbObj = {
			type: "",
			links: [
				{
					name: "User Management",
					isLink: true,
					link: "/configuration",
				},
				{
					name: "Users",
					isLink: false,
					link: "/",
				},
			],
		};
	}

	loadNextUsers = () => {
		// if (this.finished) return
		if (this.userView == undefined || this.userView == "") this.userView = "Card";
		const payload = {
			page: this.pageNumber,
			items_per_page: this.lastKey + 1,
			sort: this.sortKey,
			reverse: this.reverse,
			cardCount: this.cardCount,
			userView: this.userView,
			firstKey: this.firstKey,
			lastKey: this.lastKey,
			filter: JSON.stringify(this.filter),
			module: "Users",
			// lastKey: this.
		};
		// console.log("payload : ", payload)
		this._userService.getUserProfiles(payload).subscribe(
			(response) => {
				if (response) {
					const newUsers = response.results;
					this.finished = false;
					if (newUsers.length === 0) {
						this.notEmptyUser = false;
						this.finished = true;
					}
					this.users_card = this.users_card.concat(newUsers);
					this.userCount = this.users_card.length;
					this.notScrolly = true;
					this.totalCount = response.count;
					setTimeout(() => {
						this.showData = true;
					}, 300);
				} else {
					this.showData = true;
				}
			},
			(error) => {
				//todo
			}
		);
	};

	onScrollDown = () => {
		if (this.notScrolly && this.notEmptyUser) {
			this.finished = true;
			this.notScrolly = false;
			this.firstKey += Number(this.selectedOption);
			this.lastKey += Number(this.selectedOption);
			this.loadNextUsers();
		}
	};

	onScrollUp = () => {
		this.finished = false;
		this.notScrolly = true;
		this.notEmptyUser = true;
	};

	cardCountChange = () => {
		this.selectedOption = Number(this.selectedOption);
		this.cardCount = this.selectedOption;
		this.firstKey = 0;
		this.lastKey = this.cardCount - 1;
		this.getUserList();
	};

	onLimitChange = (limit) => {
		this.pageNumber = 1;
		// this.limit = args?.target?.value;
		if (limit === "all") {
			this.showAll = true;
			this.limit = this.totalCount;
		} else {
			this.limit = Number(limit);
			this.showAll = false;
		}
		this.selectedOption = this.limit;
		this.cardCountChange();
	};

	loadPage = (data) => {
		this.pageNumber = data?.page;
		this.getUserList();
	};

	getUserList() {
		if (this.userView == undefined || this.userView == "") this.userView = "Card";
		const payload = {
			page: this.pageNumber,
			items_per_page: this.selectedOption,
			sort: this.sortKey,
			reverse: this.reverse,
			cardCount: this.cardCount,
			userView: this.userView,
			firstKey: this.firstKey,
			lastKey: this.lastKey,
			filter: JSON.stringify(this.filter),
			module: "Users",
			// lastKey: this.
		};
		this._userService.getUserProfiles(payload).subscribe(
			(response) => {
				if (response) {
					this.users_card = response.results;
					if (this.users_card.length <= this.selectedOption) {
						this.finished = true;
						this.noUserMsg = "";
					}
					this.userCount = this.users_card.length;
					// this.userCount = response.count;
					this.totalCount = response.count;
					setTimeout(() => {
						this.showData = true;
					}, 300);
				} else {
					this.showData = true;
				}
			},
			(error) => {
				//todo
			}
		);
	}

	deleteUser = (row) => {
		this.selectedUser = row;
		this._userService.getCIRelationMap({ pk: row?.profile_id }).subscribe((response: any) => {
			let msg = "Message.msg_delete_text";
			let add_on_msg = "";
			if (response?.flag == 0) {
				msg = "Error.err_user_ci_relation_mapped";
				if (response?.ci_rel_length > 0 && response?.ci_rel_length != undefined) {
					add_on_msg += " " + this._translateService.instant("UI.k_one_or_more_cis");
				}
			}
			Swal.fire({
				title: this._translateService.instant("Message.msg_delete_title"),
				text: this._translateService.instant(msg, { msg: add_on_msg }),
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: this._translateService.instant("Message.msg_delete_confirm"),
				cancelButtonText: this._translateService.instant("Message.msg_delete_cancel"),
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-danger ml-1",
				},
			}).then((result) => {
				if (result.value) {
					this._userService
						.getAssignedModule({ profile_id: row.profile_id })
						.pipe(
							map((response: any) => {
								this.used_modules = response["modules"] || {};
								this.user_list = response["user_list"] || [];
								if (response["deleteUser"] == true) {
									this.deleteSelectedUser(row.profile_id);
								} else {
									this._modalService.open(this.modDeletePopup, {
										centered: false,
										size: "lg",
										animation: false,
									});
								}
							})
						)
						.subscribe();
				}
			});
		});
	};

	ngOnDestroy(): void {
		Swal.close();
	}

	onHideProfile = (e) => {
		this.hideProfile = false;
	};

	getPendingUserCount = (): void => {
		this._userService.getPendingUserCount().subscribe(
			(response) => {
				if (response) {
					this.pendingInvitations = response.count;
				}
			},
			(error) => {
				//todo
			}
		);
	};

	toggleInvitedUsers = (name: string) => {
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
		this.hideInviteGrid = true;
	};

	onHideInviteGrid = (e) => {
		this.hideInviteGrid = false;
	};

	onSubmitReplacement = (): void => {
		let payload = {
			sel_profile_id: this.selectedUser.profile_id,
			profile_id: this.selectedProfile,
			used_modules: this.used_modules,
		};
		this._userService
			.replaceAssignee(payload)
			.pipe(
				map((response: any) => {
					if (response.status == "success") {
						this._toastr.success(
							this._translateService.instant("Message.msg_user_replacement"),
							this._translateService.instant("Message.msg_success"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						this._modalService.dismissAll();
						this.deleteSelectedUser(this.selectedUser.profile_id);
					} else {
						this._toastr.error(
							this._translateService.instant("Error.err_user_replacement"),
							this._translateService.instant("Error.err_update_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
					}
					// Success
				}),
				catchError((error) => {
					this._toastr.error(
						this._translateService.instant("Error.err_user_replacement"),
						this._translateService.instant("Error.err_update_failed"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					return throwError(error.statusText);
				})
			)
			.subscribe();
	};

	deleteSelectedUser(profile_id) {
		this._userService
			.deleteUserProfile(profile_id)
			.pipe(
				map((response: any) => {
					if (response.status == "success") {
						this._toastr.success(
							this._translateService.instant("Message.msg_user_deleted"),
							this._translateService.instant("Message.msg_delete_success"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						// subtracting 1 from page number if count equals to items per page limit after delete
						if (this.userCount != 1 && this.userCount - 1 < (this.pageNumber - 1) * this.limit + 1) {
							this.pageNumber = this.pageNumber - 1;
						}
						if (this.showAll) {
							this.limit = this.limit - 1;
							this.selectedOption = this.selectedOption - 1;
						}
						this.getUserList();
					} else {
						this._toastr.error(
							this._translateService.instant("Error.err_delete_user"),
							this._translateService.instant("Error.err_delete_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
					}
					// Success
				}),
				catchError((error) => {
					this._toastr.error(
						this._translateService.instant("Error.err_delete_user"),
						this._translateService.instant("Error.err_delete_failed"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}

	csvImport() {
		//console.log("csv import")
	}

	getFilterSearch = ($event) => {
		let dict = {};
		this.filter = [];
		dict["ui_display"] = $event.userSearchData;
		dict["filter_string"] = $event.userSearchString;
		dict["filter_item"] = $event.filterItem;
		dict["type"] = $event.type;
		dict["module"] = "Users";
		this.filter.push(dict);

		this.pageNumber = 1;
		this.empty_data = {
			icon: "fa-user-group",
			msg: this._translateService.instant("UI.k_no_filtered_data"),
		};
		const payload = {
			page: 1,
			items_per_page: this.selectedOption,
			sort: this.sortKey,
			reverse: this.reverse,
			cardCount: this.cardCount,
			userView: this.userView,
			firstKey: this.firstKey,
			lastKey: this.lastKey,
			filter: JSON.stringify(this.filter),
			module: "Users",
		};
		this.getUserData(payload);
	};

	getUserData(payload) {
		this._userService.getUserProfiles(payload).subscribe(
			(response) => {
				if (response) {
					this.filterData = true;
					this.users_card = response.results;
					if (this.users_card.length <= this.selectedOption) {
						this.finished = true;
						this.noUserMsg = "";
					}
					this.userCount = this.users_card.length;
					// this.userCount = response.count;
					this.totalCount = response.count;
					setTimeout(() => {
						this.showData = true;
					}, 300);
				} else {
					this.showData = true;
				}
				this.getSavedFilters();
			},
			(error) => {
				//todo
			}
		);
	}

	getFilterOption(): void {
		this._userService.getFilterOption({ moduleName: "Users" }).subscribe(
			(response) => {
				if (response) {
					this.columnOptions = response;
				} else {
					this.columnOptions = [];
				}
			},
			(error) => {
				this.columnOptions = [];
			}
		);
	}

	getSavedFilters(): void {
		this._userService
			.getSavedFilter({ module: "Users" })
			.pipe(
				map((response: any) => {
					if (response) {
						this.savedData = response;
					}
				})
			)
			.subscribe();
	}

	getOptions = (): void => {
		this._userService.getOptions().subscribe(
			(response) => {
				if (response) {
					this.option = response;
				}
			},
			(error) => {
				// 		//todo
			}
		);
	};
	customSearchFn(term: string, item: any) {
		return customSearch(term, item, "full_name", "email");
	}

	genericSearchFilter = ($event) => {
		let search_str = $event.userSearchData.trim();
		let search_db_cols = [
			{ db_col: "full_name", cond: "contains" },
			{ db_col: "email", cond: "contains" },
			{ db_col: "contact_number", cond: "contains" },
			{ db_col: "location", cond: "contains" },
		];
		let filter_options = [];
		search_db_cols.forEach((item) => {
			filter_options.push({
				condition: item.cond,
				db_col: item.db_col,
				value: search_str,
				relation: "or",
			});

			if (filter_options.length == search_db_cols.length) {
				this.pageNumber = 1;
				let dict = {};
				dict["ui_display"] = $event.userSearchData;
				dict["filter_string"] = filter_options;
				dict["filter_item"] = $event.filterItem;
				dict["type"] = $event.type;
				dict["module"] = "Users";
				for (let i = 0; i < this.filter.length; i++) {
					dict["filter_string"].push(this.filter[i]);
				}

				this.filter = [];
				this.filter.push(dict);
				const payload = {
					page: 1,
					items_per_page: this.selectedOption,
					sort: this.sortKey,
					reverse: this.reverse,
					cardCount: this.cardCount,
					userView: this.userView,
					firstKey: this.firstKey,
					lastKey: this.lastKey,
					filter: JSON.stringify(this.filter),
					module: "Users",
				};
				this.getUserData(payload);
			}
		});
	};
	getSearchWidth = (e) => {
		if(e){
			this.isExpandSearchWidth = true;
		}
		else{
			this.isExpandSearchWidth = false;
		}
	}
}
