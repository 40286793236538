<div class="modal-header">
	<h4 class="modal-title" id="myModalLabel1">
		{{ "UI.k_approval" | translate }} {{ "UI.k_configuration" | translate }}
	</h4>
	<br />
	<span
		id="btnAssignClose"
		name="btnAssignClose"
		data-dismiss="modal"
		aria-label="Close"
		(click)="toggleSidebar(currentApprovalForm, 'close')"
	>
		<i class="fa-light fa-xmark mb-0 mr-sm-25 mr-0 pull-right"></i>
	</span>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
	<form #currentApprovalForm="ngForm">
		<div class="row mb-2">
			<div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
				<div *ngIf="!RESTRICTED_REPORTING_MANAGER_MODULES.includes(module_id) && !is_cab_approval" class="form-group">
					<label>{{
						"UI.k_sent_to_report_mangr" | translate
					}}</label>
					<div
						class="custom-control custom-control-primary custom-switch"
					>
						<input
							type="checkbox"
							[(ngModel)]="
								process_data.approval_config
									.is_reporting_manager
							"
							name="enableReporting"
							class="custom-control-input"
							id="cbEableReporting"
							[disabled]="is_send_disabled"
							(change)="onReportingEnable($event)"
						/>
						<label
							class="custom-control-label"
							for="cbEableReporting"
						></label>
					</div>
				</div>
				<div *ngIf="!process_data.approval_config.is_reporting_manager" class="form-group">
					<label for="txtChnSelAssignGroup"
						>{{ "UI.k_sel_team" | translate }}
						<span class="required-field">&nbsp;*</span>
					</label>
					<ng-select
						[items]="team_list"
						[hideSelected]="true"
						bindLabel="name"
						[(ngModel)]="process_data.approval_config.team"
						name="txtChnSelAssignGroup"
						id="txtChnSelAssignGroup"
						placeholder="{{ 'UI.k_select' | translate }} {{
							'UI.k_group' | translate
						}}"
						#TDSelAppGroup="ngModel"
						[closeOnSelect]="true"
						[class.error]="
							!TDSelAppGroup.valid &&
							currentApprovalForm.submitted
						"
						required
					>
						<ng-template
							ng-option-tmp
							let-item="item"
							let-index="index"
							let-search="searchTerm"
						>
							<span
								class="d-flex"
							>
								<div
									class="avatar mr-1 ml-0"
									[ngStyle]="{
										'background-color':
											item.avatar_color
												? item.avatar_color
												: '#f59518'
									}"
									style="
										align-content: center;
										width: 32px;
										height: 32px;
									"
								>
									<div
										class="avatar-content"
										[ngStyle]="{
											'background-color':
											stringToColor(item.name)
										}"
									>
										{{
											item.name
												| initials
												| slice: 0:2
										}}
									</div>
								</div>
								<div class="cell-line-height">
									<p
										class="lg-font fw-500 line-height-1 mb-0"
									>
										{{ item.name | slice: 0:20 }}
										{{
											item?.name?.length > 20
												? ".."
												: ""
										}}
									</p>
									<span class="text-muted xs-font">
										{{ item.group_type }}</span
									>
								</div>
							</span>
						</ng-template>
					</ng-select>
					<span
						*ngIf="
							currentApprovalForm.submitted &&
							TDSelAppGroup.invalid
						"
						class="invalid-form"
					>
						<small
							class="form-text text-danger"
							*ngIf="TDSelAppGroup.errors.required"
							>{{ "UI.k_field_required" | translate }}</small
						>
					</span>
				</div>
				<div class="form-group" *ngIf="process_data.approval_config.is_reporting_manager">
					<label for="reporting_manager">{{
						"UI.k_reporting_manager" | translate
					}}<span class="required-field">&nbsp;*</span>
					</label>
					<ng-select
						[items]="reporting_manager_list"
						[hideSelected]="true"
						bindLabel="full_name"
						bindValue="profile_id"
						[searchable]="true"
						[searchFn]="customSearchFn"
						notFoundText="{{
							'UI.k_no_reporting_manger' | translate
						}}"
						[(ngModel)]="
							process_data.approval_config.reporting_manager
						"
						id="reporting_manager"
						name="reporting_manager"
						placeholder="{{
							'UI.k_select_reporting_manager'
								| translate
						}}"
						[dropdownPosition]="'top'"
						(change)="onManagerChange($event)"
						#TDRptMgr="ngModel"
						required
					>
						<ng-template
							ng-option-tmp
							let-item="item"
							let-index="index"
							let-search="searchTerm"
						>
							<span
								class="row"
								style="padding-left: 1rem"
							>
								<div
									class="avatar mr-1 ml-0"
									[ngClass]="bg - light - primary"
									style="
										align-content: center;
										width: 32px;
										height: 32px;
									"
								>
									<div
										*ngIf="
											item?.profile_image;
											else customAvatar
										"
									>
										<img
											class="rounded-circle mr-1"
											src="{{
												item.profile_image
											}}"
											height="32"
											width="32"
											alt="datatable-avatar"
										/>
									</div>
									<ng-template #customAvatar>
										<div
											class="avatar mr-1 ml-0"
											[ngStyle]="{
												'background-color':
													item.avatar_color
														? item.avatar_color
														: '#f59518'
											}"
										>
											<div
												class="avatar-content"
											>
												{{
													item.full_name
														| initials
														| slice: O:2
												}}
											</div>
										</div>
									</ng-template>
								</div>
								<div class="cell-line-height">
									<p
										class="font-medium-1 font-weight-bold line-height-1 mb-25"
									>
										{{ item.full_name }}
									</p>
									<span
										class="text-muted font-small-2"
									>
										{{ item.email }}</span
									>
								</div>
							</span>
						</ng-template>
						<span
							*ngIf="
								currentApprovalForm.submitted &&
								TDRptMgr.invalid
							"
							class="invalid-form"
						>
							<small
								class="form-text text-danger"
								*ngIf="TDRptMgr.errors.required"
								>{{ "UI.k_field_required" | translate }}</small
							>
						</span>
					</ng-select>
				</div>
			</div>
		</div>
		<div class="d-flex pull-right pb-half">
			<div class="mr-1">
				<button
					type="submit"
					[disabled]="apprChnDisabled"
					class="btn btn-primary"
					rippleEffect
					id="btnAssignSave"
					name="btnAssignSave"
					(click)="onSubmit(currentApprovalForm)"
				>
					<i class="fa-light fa-floppy-disk"></i>&nbsp;&nbsp;{{
						"UI.k_submit" | translate
					}}
				</button>
			</div>
		</div>
	</form>
</div>