import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from "app/common/services/api/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: 'root'
})
export class TriggerService extends ApiService {

	constructor(httpClient: HttpClient) {
		super(httpClient);
	}

	getTriggers(payload = {}) {
		return this.get("ux/common/trigger/config/", payload);
	}

	saveTiggerConfiguration(payload: any) {
		return this.post("ux/common/trigger/config/", payload);
	}

	getTrigger(id: any) {
		return this.get("ux/common/trigger/config/" + id.toString() + "/");
	}

	deleteTrigger(id: any) {
		return this.delete("ux/common/trigger/config/" + id.toString() + "/");
	}

	getOptions(payload = {}) {
		return this.get("ux/common/trigger/config/initconfig/", payload);
	}

	getFilterOptions(payload: any) {
		return this.get("ux/common/filters/filters/filter_options/", payload);
	}
	getSavedFilter(payload) {
		return this.get("ux/common/filters/filters/filter/", payload);
	}
	getImpactService(payload) {
		return this.get("ux/common/catalogue/servicecatalogue/req_services/", payload);
	}
}
