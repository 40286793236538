import { Component, Input, OnInit } from "@angular/core";
import { NgEventBus } from "ng-event-bus";

@Component({
	selector: "app-request-process-count",
	templateUrl: "./request-process-count.component.html",
	styleUrls: ["./request-process-count.component.scss"],
})
export class RequestProcessCountComponent implements OnInit {
	@Input() widgetData: any;
	@Input() modifiedtime: any;
	config: any;
	constructor(
		private eventBus: NgEventBus
	) {}

	ngOnInit(): void {
		this.config = this.widgetData?.data
		// console.log("config", this.config);

	}

	showReqProcessSidebar(dat){
		if (dat?.count > 0 && dat?.item_filter){
			let payload = {
				"title": "Requests - " + dat.name,
				"app_type": "req-process-grid",
				"item_filter": dat.item_filter,
			}
			// console.log("in req count widget -> ", payload)
			this.eventBus.cast('dashboard-view:sidebar', payload);
		}
	}
}
