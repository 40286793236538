<!-- <div class="full-body-height" dns-enter-submit *ngIf="showData"> -->
<div class="full-body-height">
	<div class="main-content flex-height" [ngClass]="{ activeSidebarPanel: submenu === true }">
		<div class="left-sec" #sidebar>
			<div class="side-pannel">
				<div class="sidebar">
					<core-sidebar
						class="sidebar-left email-app-sidebar w-100 full-height"
						name="account-settings-sidebar"
						overlayClass="body-content-overlay"
					>
						<app-account-sidebar
							(onMenuClick)="filterSelection($event)"
							[isSelfServicePortal]="isSelfServicePortal"
						></app-account-sidebar>
					</core-sidebar>
				</div>
			</div>
		</div>
		<div class="main-sec">
			<div class="action-bar">
				<div class="inner-section">
					<div class="left-section d-flex flex-nowrap">
						<div class="subheader d-flex d-xl-none mr-1 mb-0">
							<div class="toggale-submenu">
								<button
									class="submenu-toggle btn btn-icon"
									#toggleSideMenu
									id="btnSubmenu"
									name="btnSubmenu"
									(click)="submenuToggaleClick()"
								>
									<div *ngIf="submenu" placement="left"><i class="fa-light fa-chevron-left"></i></div>
									<div *ngIf="!submenu" placement="right" class="pl-25">
										<i class="fa-light fa-chevron-right"></i>
									</div>
								</button>
							</div>
						</div>
						<!-- <div class="sidebar-toggle d-block d-lg-none">
							<button
								class="btn"
								(click)="toggleSidebar('account-settings-sidebar')"
								id="btnMblExp"
								name="btnMblExp"
							>
								<i class="fa-light fa-list"></i>
							</button>
						</div> -->
						<h6 class="mb-0 ml-1 font-weight-bolder">
							<span *ngIf="currentMenu == 'general'">{{ "UI.k_general" | translate }}</span>
							<span *ngIf="currentMenu == 'changePassword'">{{
								"UI.k_change_password" | translate
							}}</span>
							<span *ngIf="currentMenu == 'information'">{{ "UI.k_information" | translate }}</span>
							<span *ngIf="currentMenu == 'manageNotify'">{{ "UI.k_manage_notification" | translate }}</span>
						</h6>
					</div>
					<div class="right-section">
						<span class="text-muted sm-font">{{ "UI.k_last_logged_in" | translate }}:</span>
						<span class="text-muted sm-font">{{ accountSetting.general.last_login_time }}</span>
					</div>
				</div>
			</div>

			<div class="action-section flex-auto-height">
				<div class="flex-1">
					<ng-scrollbar class="custom-scrollbar section-scrollbar">
						<div class="email-user-list p-1" *ngIf="currentMenu == 'general'">
							<form class="validate-form mt-2" #accountSettingsGeneral="ngForm">
								<div class="row">
									<!-- header media -->
									<div class="col-md-2 col-sm-12 px-2">
										<app-profile-avatar
											(avatarDetails)="profileImage($event, accountSettingsGeneral)"
											(resetImage)="removeImage($event, accountSettingsGeneral)"
											(avatarBgColor)="ProfileBgColor($event)"
											[profileName]="fullName"
											[profile_image]="currentImgDetails"
											[avatarBackgroundColor]="currentAvatarColor"
											[activeColor]="activeAvatar"
											(currentAvatar)="currrentColor($event)"
										></app-profile-avatar>

										<!-- <div class="media-body mt-75 ml-2">
				  <label  for="account-upload" class="btn btn-sm btn-primary mb-75 mr-75" rippleEffect >
					{{'UI.k_upload' | translate }}
				  </label>
				  <input (change)="onUpload($event, accountSettingsGeneral)" type="file"  id="account-upload" hidden accept="image/*" />
				  <button (click)="resetImage(accountSettingsGeneral)" class="btn btn-sm btn-outline-secondary mb-75" rippleEffect>Reset</button>
				  <p> {{'UI.k_file_upload_alert' | translate}} {{MAX_IMAGE_SIZE/1024}} KB</p>
				</div> -->
										<!--/ upload and reset button -->
									</div>
									<!--/ header media -->
									<div class="col-md-10 col-sm-12">
										<div class="row">
											<div class="col-12 col-lg-6">
												<div class="form-group">
													<label for="account-full_name"
														>{{ "UI.k_fname" | translate
														}}<span class="required">*</span></label
													>
													<input
														type="text"
														[(ngModel)]="accountSetting.general.first_name"
														#TDNameRef="ngModel"
														required
														class="form-control"
														id="account-full_name"
														name="full_name"
														placeholder="John"
														minlength="3"
														maxlength="40"
														(click)="onFormChange(accountSettingsGeneral)"
														[appWhiteSpaceCheck]="accountSetting.general.first_name"
														[class.error]="
															!TDNameRef.valid && accountSettingsGeneral.submitted
														"
													/>
													<span
														*ngIf="accountSettingsGeneral.submitted && TDNameRef.invalid"
														class="invalid-form"
													>
														<small
															class="form-text text-danger"
															*ngIf="TDNameRef.errors.required"
															>{{ "UI.k_field_required" | translate }}</small
														>
														<small
															class="form-text text-danger"
															*ngIf="
																TDNameRef.errors.minlength || TDNameRef.errors.maxlength
															"
															>{{ "UI.k_name_char_validation" | translate }}
														</small>
														<small
															class="form-text text-danger"
															*ngIf="TDNameRef.errors.pattern"
															>{{ "UI.k_pattern_validation" | translate }}</small
														>
														<small
															class="form-text text-danger"
															*ngIf="TDNameRef.errors?.whitespace"
															>{{
																"UI.k_leading_trailing_space_validation" | translate
															}}</small
														>
													</span>
												</div>
											</div>
											<!-- <div class="col-12 col-sm-6">
					  <div class="form-group">
						<label for="account-name">{{
						  "UI.k_lname" | translate
						}}</label>
						<input
						  type="text"
						  [(ngModel)]="accountSetting.general.last_name"
						  class="form-control"
						  id="last-name"
						  name="lastName"
						  placeholder="Doe"
						  minlength="1"
						  maxlength="40"
						  (click)="onFormChange(accountSettingsGeneral)"
						  pattern="^[^\s]+(\s+[^\s]+)*$"
						  #TDLastNameRef="ngModel"
						  [class.error]="
							!TDLastNameRef.valid &&
							accountSettingsGeneral.submitted
						  "
						/>
						<span
						  *ngIf="
							accountSettingsGeneral.submitted &&
							TDLastNameRef.invalid
						  "
						  class="invalid-form"
						>
						  <small
							class="form-text text-danger"
							*ngIf="
							  TDLastNameRef.errors.minlength ||
							  TDLastNameRef.errors.maxlength
							"
							>{{
							  "UI.k_last_name_validation" | translate
							}}
						  </small>
						  <small
							class="form-text text-danger"
							*ngIf="TDLastNameRef.errors.pattern"
							>{{
							  "UI.k_leading_trailing_space_validation"
								| translate
							}}</small
						  >
						</span>
					  </div>
					</div> -->
											<div class="col-12 col-lg-6">
												<div class="form-group">
													<label for="account-e-mail"
														>{{ "UI.k_email" | translate
														}}<span class="required">*</span></label
													>
													<input
														type="email"
														#TDEmailRef="ngModel"
														[(ngModel)]="email"
														class="form-control"
														id="account-e-mail"
														name="account-e-mail"
														disabled
														pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$"
														placeholder="{{ 'UI.k_email_placeholder' | translate }}"
														aria-label="john.doe"
													/>
													<!-- <span
						  *ngIf="accountSettingsGeneral.submitted && TDEmailRef.invalid"
						  class="invalid-form"
					  >
					  <small
						  class="form-text text-danger"
						  *ngIf="TDEmailRef.errors.pattern"
						  >{{ 'UI.k_alert_email_invalid' | translate }}</small
						>
					  <small
							class="form-text text-danger"
							*ngIf="TDEmailRef.errors.required"
							>{{ 'UI.k_field_required' | translate}}</small
						>
						  
					  </span> -->
												</div>
											</div>
											<div class="col-12 col-lg-6">
												<div class="form-group">
													<label class="d-block mb-1"
														>{{ "UI.k_gender" | translate
														}}<span class="required-field">&nbsp;*</span></label
													>
													<div class="custom-control custom-radio custom-control-inline">
														<input
															id="male"
															type="radio"
															value="1"
															name="gender"
															(change)="changeGender($event, accountSettingsGeneral)"
															class="custom-control-input form-control"
															[(ngModel)]="accountSetting.general.profile.gender"
															#TDGenderRef="ngModel"
															required
															[class.error]="
																!TDGenderRef.valid && accountSettingsGeneral.submitted
															"
															[checked]="accountSetting.general.profile.gender === 1"
														/>
														<label class="custom-control-label" for="male"
															>&nbsp;{{ "UI.k_male" | translate }}</label
														>
													</div>
													<div class="custom-control custom-radio custom-control-inline">
														<input
															id="female"
															type="radio"
															value="2"
															name="gender"
															(change)="changeGender($event, accountSettingsGeneral)"
															class="custom-control-input form-control"
															[(ngModel)]="accountSetting.general.profile.gender"
															required
															#TDGenderRef="ngModel"
															[class.error]="
																!TDGenderRef.valid && accountSettingsGeneral.submitted
															"
															[checked]="accountSetting.general.profile.gender === 2"
														/>
														<label class="custom-control-label" for="female"
															>&nbsp;{{ "UI.k_female" | translate }}</label
														>
													</div>
													<div class="custom-control custom-radio custom-control-inline">
														<input
															id="others"
															type="radio"
															value="3"
															name="gender"
															(change)="changeGender($event, accountSettingsGeneral)"
															class="custom-control-input form-control"
															[(ngModel)]="accountSetting.general.profile.gender"
															required
															#TDGenderRef="ngModel"
															[class.error]="
																!TDGenderRef.valid && accountSettingsGeneral.submitted
															"
															[checked]="accountSetting.general.profile.gender === 3"
														/>
														<!-- [checked]="
							  accountSetting.general.profile.gender == 3
							" -->
														<label class="custom-control-label" for="others"
															>&nbsp;{{ "UI.k_others" | translate }}</label
														>
													</div>
													<span
														*ngIf="accountSettingsGeneral.submitted && TDGenderRef.invalid"
														class="invalid-form"
													>
														<small
															class="form-text text-danger"
															*ngIf="TDGenderRef.errors.required"
															>{{ "UI.k_field_required" | translate }}</small
														>
													</span>
												</div>
											</div>
											<div class="col-12 col-lg-6">
												<div class="form-group">
													<label for="timezone">{{ "UI.k_timezone" | translate }} *</label>
													<ng-select
														[items]="timezones"
														[hideSelected]="true"
														notFoundText="{{ 'UI.k_no_items_found' | translate }}"
														bindLabel="name"
														[(ngModel)]="accountSetting.general.profile.timezone"
														id="timezone"
														name="timezone"
														(change)="onFormChange(accountSettingsGeneral)"
														(click)="onFormChange(accountSettingsGeneral)"
														placeholder="{{ 'UI.k_select_timezone' | translate }} "
														[class.error]="
															!TDTimezoneRef.valid && accountSettingsGeneral.submitted
														"
														#TDTimezoneRef="ngModel"
														required
													>
													</ng-select>
													<span
														*ngIf="
															accountSettingsGeneral.submitted && TDTimezoneRef.invalid
														"
														class="invalid-form"
													>
														<small
															class="form-text text-danger"
															*ngIf="TDTimezoneRef.errors.required"
															>{{ "UI.k_field_required" | translate }}</small
														>
													</span>
												</div>
											</div>
											<div class="col-12 col-lg-6">
												<div class="form-group">
													<label for="account-phone">{{
														"UI.k_contact_number" | translate
													}}</label>
													<!-- <div class="input-group w-100"> -->
													<!-- <input
						  [(ngModel)]="
							accountSetting.general.profile
							  .contact_number
						  "
						  #TDPhoneRef="ngModel"
						  type="text"
						  required
						  name="phoneNumber"
						  id="account-phone"
						  class="form-control"
						  placeholder="xxxxxxxxxx"
						  pattern="^[0-9]{6,15}$"
						  (click)="onFormChange(accountSettingsGeneral)"
						  [class.error]="
							!TDPhoneRef.valid &&
							accountSettingsGeneral.submitted
						  "
						/>
						<span
						  *ngIf="
							accountSettingsGeneral.submitted &&
							TDPhoneRef.invalid
						  "
						  class="invalid-form"
						>
						  <small
							class="form-text text-danger"
							*ngIf="TDPhoneRef.errors.required"
							>{{
							  "UI.k_field_required" | translate
							}}</small
						  >
						  <small
							class="form-text text-danger"
							*ngIf="TDPhoneRef.errors.pattern"
							>{{
							  "UI.k_pattern_validation" | translate
							}}</small
						  >
						</span> -->
													<div class="w-100">
														<ngx-intl-tel-input
															class="w-100"
															[cssClass]="'custom form-control w-100'"
															[preferredCountries]="preferredCountries"
															[enableAutoCountrySelect]="true"
															[enablePlaceholder]="true"
															[searchCountryFlag]="true"
															[searchCountryField]="[
																SearchCountryField.Iso2,
																SearchCountryField.Name
															]"
															[selectFirstCountry]="false"
															[selectedCountryISO]="CountryISO.UnitedStates"
															[maxLength]="15"
															[phoneValidation]="true"
															[separateDialCode]="separateDialCode"
															[numberFormat]="PhoneNumberFormat.National"
															name="account-phone"
															id="account-phone"
															[(ngModel)]="accountSetting.general.profile.phone_number"
															#TDPhoneRef="ngModel"
															(click)="onFormChange(accountSettingsGeneral)"
															[ngClass]="{
																'is-invalid':
																	accountSettingsGeneral.submitted &&
																	TDPhoneRef.errors
															}"
														>
														</ngx-intl-tel-input>
														<span
															*ngIf="
																accountSettingsGeneral.submitted && TDPhoneRef.invalid
															"
															class="invalid-form"
														>
															<small
																class="form-text text-danger"
																*ngIf="TDPhoneRef.errors"
																>{{ "UI.k_phone_number_validation" | translate }}</small
															>
														</span>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-6 col-lg-3">
												<div class="form-group">
													<label class="form-label" for="txtLandline">{{
														"UI.k_landline" | translate
													}}</label>
													<input
														[(ngModel)]="accountSetting.general.profile.landline"
														#TDLandLineRef="ngModel"
														type="text"
														id="txtLandline"
														name="landline"
														landline
														class="form-control"
														(click)="onFormChange(accountSettingsGeneral)"
														placeholder="xxxxxxxxxx"
														pattern="[0-9-]{6,12}$"
														[class.error]="
															!TDLandLineRef.valid && accountSettingsGeneral.submitted
														"
													/>
													<span
														*ngIf="
															accountSettingsGeneral.submitted && TDLandLineRef.invalid
														"
														class="invalid-form"
													>
														<small
															class="form-text text-danger"
															*ngIf="TDLandLineRef.errors.pattern"
															>{{ "UI.k_pattern_validation" | translate }}</small
														>
													</span>
												</div>
											</div>
											<div class="col-12 col-md-6 col-lg-3">
												<div class="form-group">
													<label class="form-label" for="txtExt">{{
														"UI.k_contact_extension" | translate
													}}</label>
													<input
														TDExtRef
														[(ngModel)]="accountSetting.general.profile.contact_extension"
														#TDExtRef="ngModel"
														type="text"
														id="txtExt"
														name="extension"
														class="form-control"
														placeholder="xxxxxxxxxx"
														(click)="onFormChange(accountSettingsGeneral)"
														pattern="^[0-9-+]{2,10}$"
														[class.error]="
															!TDExtRef.valid && accountSettingsGeneral.submitted
														"
													/>
													<span
														*ngIf="accountSettingsGeneral.submitted && TDExtRef.invalid"
														class="invalid-form"
													>
														<small
															class="form-text text-danger"
															*ngIf="TDExtRef.errors.pattern"
															>{{ "UI.k_pattern_validation" | translate }}</small
														>
													</span>
												</div>
											</div>
											<div class="col-12 col-md-12 col-lg-12">
												<div class="row justify-content-start align-items-center mt-1">
													<div >
														<div class="col-md-10 mb-1">
															<h3>{{ 'Message.msg_two_factor_auth' | translate }}</h3>
														</div>
														<div class="col-md-10 mb-1">
															<span> {{ 'Message.msg_heading_2fa' | translate}}</span>
														</div>
													</div>	
													<div class="custom-control custom-control-primary custom-switch custom-switch-sm">
														<input 
															type="checkbox"  
															class="custom-control-input" 
															id="customSwitchKey2FA"
															name="customSwitchKey2FA"
															[(ngModel)]="accountSetting.general.profile.is_2fa_enabled" 
															#TD2FASwitch="ngModel"
															[checked]="accountSetting.general.profile.is_2fa_enabled"
															(click)="onFormChange(accountSettingsGeneral); onEnable2FA(accountSetting.general.profile.is_2fa_enabled)"
														/>
														<label class="custom-control-label" for="customSwitchKey2FA"></label>
													</div>
												</div>
												<div *ngIf="accountSetting.general.profile.is_2fa_enabled" class="row justify-content-start align-items-center mt-1 mb-1">
													<div class="col-md-6">
														<span><b>{{ 'Message.msg_2fa_instruction' | translate }}:</b></span>
														<ul>
															<li>{{ 'Message.msg_2fa_instruction1' | translate }}</li>
															<li>{{ 'Message.msg_2fa_instruction2' | translate }}</li>
															<li>{{ 'Message.msg_2fa_instruction3' | translate }}</li>
															<li>{{ 'Message.msg_2fa_instruction4' | translate }}</li>
														</ul>
													</div>	
													<div class="col-md-6">
														<img src="{{accountSetting.general?.qr_otp_image}}" alt="QR-Code" style="
														height: 200px;
													" />
													</div>
												</div>
												<div class="row" *ngIf="accountSetting.general.profile.is_2fa_enabled && is_otp_validate">
													<div class="col-12 col-lg-6">
														<div class="form-group">
															<label for="account-full_name"
																>{{ "UI.k_generated_otp" | translate
																}}<span class="required">*</span></label
															>
															<input
																type="text"
																[(ngModel)]="generated_otp"
																#TDOtp="ngModel"
																required
																class="form-control"
																id="account-otp"
																name="otp"
																placeholder=""
																minlength="6"
																maxlength="6"
																[appWhiteSpaceCheck]="generated_otp"
																(click)="onFormChange(accountSettingsGeneral)"
																[class.error]="
																	!TDOtp.valid && accountSettingsGeneral.submitted
																"
															/>
															<span
																*ngIf="accountSettingsGeneral.submitted && TDOtp.invalid"
																class="invalid-form"
															>
																<small
																	class="form-text text-danger"
																	*ngIf="TDOtp.errors.required"
																	>{{ "UI.k_field_required" | translate }}</small
																>
																<small
																	class="form-text text-danger"
																	*ngIf="
																	TDOtp.errors.minlength || TDOtp.errors.maxlength
																	"
																	>{{ "UI.k_name_char_validation" | translate }}
																</small>
																<small
																	class="form-text text-danger"
																	*ngIf="TDOtp.errors.pattern"
																	>{{ "UI.k_pattern_validation" | translate }}</small
																>
																<small
																	class="form-text text-danger"
																	*ngIf="TDOtp.errors?.whitespace"
																	>{{
																		"UI.k_leading_trailing_space_validation" | translate
																	}}</small
																>
															</span>
														</div>
													</div>
												</div>

											</div>
											<!-- <div class="col-12 col-sm-6">
					  <div class="form-group">
						<label class="d-block mb-1"
						  >{{ "UI.k_gender" | translate
						  }}<span class="required-field"
							>&nbsp;*</span
						  ></label
						>
						<div
						  class="custom-control custom-radio custom-control-inline"
						>
						  <input
							id="male"
							type="radio"
							value="1"
							name="gender"
							(change)="
							  changeGender(
								$event,
								accountSettingsGeneral
							  )
							"
							class="custom-control-input form-control"
							[(ngModel)]="accountSetting.general.profile.gender"
							#TDGenderRef="ngModel"
							required
							[class.error]="
							  !TDGenderRef.valid &&
							  accountSettingsGeneral.submitted
							"
							[checked]="
							  accountSetting.general.profile.gender ===
							  1
							"
						  />
						  <label class="custom-control-label" for="male"
							>&nbsp;{{ "UI.k_male" | translate }}</label
						  >
						</div>
						<div
						  class="custom-control custom-radio custom-control-inline"
						>
						  <input
							id="female"
							type="radio"
							value="2"
							name="gender"
							(change)="
							  changeGender(
								$event,
								accountSettingsGeneral
							  )
							"
							class="custom-control-input form-control"
							[(ngModel)]="accountSetting.general.profile.gender"
							required
							#TDGenderRef="ngModel"
							[class.error]="
							  !TDGenderRef.valid &&
							  accountSettingsGeneral.submitted
							"
							[checked]="
							  accountSetting.general.profile.gender ===
							  2
							"
						  />
						  <label
							class="custom-control-label"
							for="female"
							>&nbsp;{{
							  "UI.k_female" | translate
							}}</label
						  >
						</div>
						<div
						  class="custom-control custom-radio custom-control-inline"
						>
						  <input
							id="others"
							type="radio"
							value="3"
							name="gender"
							(change)="
							  changeGender(
								$event,
								accountSettingsGeneral
							  )
							"
							class="custom-control-input form-control"
							[(ngModel)]="accountSetting.general.profile.gender"
							required
							#TDGenderRef="ngModel"
							[class.error]="
							  !TDGenderRef.valid &&
							  accountSettingsGeneral.submitted
							"
							[checked]="
							  accountSetting.general.profile.gender ===
							  3
							"
						  />
						  [checked]="
							  accountSetting.general.profile.gender == 3
							" 
						  <label
							class="custom-control-label"
							for="others"
							>&nbsp;{{
							  "UI.k_others" | translate
							}}</label
						  >
						</div>
						<span
						  *ngIf="
							accountSettingsGeneral.submitted &&
							TDGenderRef.invalid
						  "
						  class="invalid-form"
						>
						  <small
							class="form-text text-danger"
							*ngIf="TDGenderRef.errors.required"
							>{{
							  "UI.k_field_required" | translate
							}}</small
						  >
						</span>
					  </div>
					</div> -->
											<div class="col-12">
												<button
													type="submit"
													id="generalAccountSettings"
													[disabled]="!accountSettingsGeneral.form.touched"
													(click)="saveGeneralSettingsData(accountSettingsGeneral, form)"
													class="btn btn-primary mb-1 mr-1"
													rippleEffect
												>
													<i class="fa-light fa-save align-middle mr-50 ml-0"></i>
													{{ "UI.k_submit" | translate }}
												</button>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
						<div class="email-user-list p-1" *ngIf="currentMenu == 'changePassword'">
							<form class="validate-form" #accountSettingsPassword="ngForm">
								<div class="row">
									<div class="col-12 col-sm-6">
										<div class="form-group">
											<label for="account-old-password"
												>{{ "UI.k_current_password" | translate
												}}<span class="required">*</span></label
											>
											<div class="input-group form-password-toggle input-group-merge">
												<input
													[(ngModel)]="old_password"
													#TDPasswordOldRef="ngModel"
													[type]="userOldPwdShow ? 'text' : 'password'"
													class="form-control"
													[class.error]="
														!TDPasswordOldRef.valid && accountSettingsPassword.submitted
													"
													id="account-old-password"
													name="password"
													placeholder="{{ 'UI.k_old_password' | translate }}"
													required
												/>
												<div
													class="input-group-append"
													(click)="userOldPwdShow = !userOldPwdShow"
												>
													<span class="input-group-text cursor-pointer"
														><i
															class="feather font-small-4"
															[ngClass]="{
																'icon-eye-off': userOldPwdShow,
																'icon-eye': !userOldPwdShow
															}"
														></i
													></span>
												</div>
											</div>
											<span
												*ngIf="TDPasswordOldRef.invalid && accountSettingsPassword.submitted"
												class="invalid-form"
											>
												<small
													class="form-text text-danger"
													*ngIf="TDPasswordOldRef.errors.required"
													>{{ "UI.k_field_required" | translate }}</small
												>
											</span>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-12 col-sm-6">
										<div class="form-group">
											<label for="account-new-password"
												>{{ "UI.k_new_password" | translate
												}}<span class="required">*</span></label
											>
											<div class="input-group form-password-toggle input-group-merge">
												<input
													[(ngModel)]="new_password"
													#TDPasswordNewRef="ngModel"
													[type]="userPwdShow ? 'text' : 'password'"
													id="account-new-password"
													name="new-password"
													class="form-control"
													placeholder="{{ 'UI.k_new_password' | translate }}"
													[class.error]="
														!TDPasswordNewRef.valid &&
														accountSettingsPassword.submitted &&
														!is_password_invalid
													"
													(ngModelChange)="
														validatePassword(new_password, accountSettingsPassword)
													"
													required
												/>
												<div class="input-group-append" (click)="userPwdShow = !userPwdShow">
													<span
														class="input-group-text cursor-pointer"
														id="spNewPwdIcon"
														name="spNewPwdIcon"
														><i
															class="feather"
															[ngClass]="{
																'icon-eye-off': userPwdShow,
																'icon-eye': !userPwdShow
															}"
														></i
													></span>
												</div>
											</div>
											<span *ngIf="is_password_invalid" class="invalid-form">
												<small class="form-text text-danger" [innerHtml]="passwordToaster">
												</small>
											</span>
											<span
												*ngIf="TDPasswordNewRef.invalid && accountSettingsPassword.submitted"
												class="invalid-form"
											>
												<small
													*ngIf="TDPasswordNewRef.errors.required"
													class="form-text text-danger"
												>
													{{ "UI.k_field_required" | translate }}
												</small>
											</span>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-12 col-sm-6">
										<div *ngIf="showCaptcha" class="form-group">
											<label class="form-label" for="imgCaptcha">{{ "UI.k_captcha_code" | translate }}</label>
											<div class="input-group-merge form-password-toggle d-flex">
												<img
													src="{{ codeImage }}"
													id="imgCaptcha"
													name="imgCaptcha"
													class="border-captcha"
													height="60"
													width="100%"
												/>
												<div class="input-group-append">
													<span class="input-group-text cursor-pointer">
														<a
															id="linkReloadCaptcha"
															name="linkReloadCaptcha"
															class="justify-content-center"
															placement="top"
															ngbTooltip="Reload"
															(click)="getCaptcha()"
														>
															<i class="fa-light fa-arrows-rotate fa-lg text-primary"></i>
														</a>
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-12 col-sm-6">
										<div *ngIf="showCaptcha" class="form-group">
											<label class="form-label" for="txtCaptcha">{{ "UI.k_captcha_message" | translate }} </label>
											<input
												class="form-control lg-form-control"
												id="txtCaptcha"
												name="txtCaptcha"
												type="text"
												value="captcha-input"
												placeholder="Enter Captcha"
												aria-describedby="captcha-input"
												autofocus=""
												tabindex="3"
												[(ngModel)]="captcha_temp"
												#captchaInput="ngModel"
												required
											/>
											<span *ngIf="accountSettingsPassword.submitted && captchaInput.errors" class="invalid-form">
												<small class="form-text text-danger" *ngIf="captchaInput.errors.required">{{
													"UI.k_field_required" | translate
												}}</small>
											</span>
										</div>
									</div>
									<div class="col-12">
										<button
											type="submit"
											id="passwordChangeAccountSettings"
											(click)="changeUserPassword(accountSettingsPassword)"
											[disabled]="is_password_invalid"
											class="btn btn-primary mr-1 mt-1"
											rippleEffect
										>
											<i class="fa-light fa-save align-middle mr-50 ml-0"></i>
											{{ "UI.k_submit" | translate }}
										</button>
									</div>
								</div>
							</form>
						</div>
						<div class="email-user-list p-1" *ngIf="currentMenu == 'information'">
							<form class="validate-form mt-2" #accountSettingsInfo="ngForm">
								<div class="row">
									<div class="col-12 col-sm-6">
										<div class="form-group">
											<label class="form-label" for="txtDsgn">{{
												"UI.k_designation" | translate
											}}</label>
											<input
												[(ngModel)]="accountSetting.info.profile.designation"
												#TDDesignationRef="ngModel"
												type="text"
												name="txtDsgn"
												id="txtDsgn"
												class="form-control"
												[appWhiteSpaceCheck]="accountSetting.info.profile.designation"
												(click)="onFormChange(accountSettingsInfo)"
												minlength="3"
												maxlength="40"
												[class.error]="
													accountSettingsInfo.submitted && TDDesignationRef.invalid
												"
												placeholder="{{ 'UI.k_place_designation' | translate }}"
											/>
											<span
												*ngIf="
													accountSettingsInfo.submitted &&
													TDDesignationRef.invalid &&
													(TDDesignationRef.dirty || TDDesignationRef.touched)
												"
												class="invalid-form"
											>
												<small
													class="form-text text-danger"
													*ngIf="TDDesignationRef.errors.pattern"
													>{{ "UI.k_pattern_validation" | translate }}
												</small>
												<small
													class="form-text text-danger"
													*ngIf="TDDesignationRef.errors?.whitespace"
													>{{ "UI.k_leading_trailing_space_validation" | translate }}
												</small>
												<small
													class="form-text text-danger"
													*ngIf="
														TDDesignationRef.errors.minlength ||
														TDDesignationRef.errors.maxlength
													"
													>{{ "UI.k_designation_validation" | translate }}
												</small>
											</span>
										</div>
									</div>
									<div class="col-12 col-sm-6">
										<div class="form-group">
											<label class="form-label" for="txtOrgRole">{{
												"UI.k_organization_role" | translate
											}}</label>
											<input
												[(ngModel)]="accountSetting.info.profile.organization_role"
												#TDOrgRoleRef="ngModel"
												type="text"
												name="txtOrgRole"
												id="txtOrgRole"
												class="form-control"
												[appWhiteSpaceCheck]="accountSetting.info.profile.organization_role"
												(click)="onFormChange(accountSettingsInfo)"
												minlength="3"
												maxlength="40"
												[class.error]="accountSettingsInfo.submitted && TDOrgRoleRef.invalid"
												placeholder="{{ 'UI.k_place_orgrole' | translate }}"
											/>
											<span
												*ngIf="
													accountSettingsInfo.submitted &&
													TDOrgRoleRef.invalid &&
													(TDOrgRoleRef.dirty || TDOrgRoleRef.touched)
												"
												class="invalid-form"
											>
												<small class="form-text text-danger" *ngIf="TDOrgRoleRef.errors.pattern"
													>{{ "UI.k_pattern_validation" | translate }}
												</small>
												<small
													class="form-text text-danger"
													*ngIf="TDOrgRoleRef.errors?.whitespace"
													>{{ "UI.k_leading_trailing_space_validation" | translate }}
												</small>
												<small
													class="form-text text-danger"
													*ngIf="
														TDOrgRoleRef.errors.minlength || TDOrgRoleRef.errors.maxlength
													"
													>{{ "UI.k_organization_role_validation" | translate }}
												</small>
											</span>
										</div>
									</div>
									<div class="col-12 col-sm-6">
										<div class="form-group">
											<label class="form-label" for="txtDptmt">{{
												"UI.k_department" | translate
											}}</label>
											<!-- <input
					  [(ngModel)]="
						accountSetting.info.profile.department
					  "
					  #TDDepartmentRef="ngModel"
					  type="text"
					  name="txtDptmt"
					  id="txtDptmt"
					  class="form-control"
					  [appWhiteSpaceCheck]="
						accountSetting.info.profile.department
					  "
					  (click)="onFormChange(accountSettingsInfo)"
					  minlength="3"
					  maxlength="40"
					  [class.error]="
						accountSettingsInfo.submitted &&
						TDDepartmentRef.invalid
					  "
					  placeholder="{{'UI.k_support' | translate}}"
					/> -->
											<ng-select
												[items]="departmentList"
												[(ngModel)]="accountSetting.info.profile.department"
												bindLabel="name"
												bindValue="dept_id"
												name="txtDptmt"
												id="txtDptmt"
												#TDDepartmentRef="ngModel"
												placeholder="{{ 'UI.k_select_department' | translate }}"
												notFoundText="{{ 'UI.k_no_department' | translate }}"
												[class.error]="accountSettingsInfo.submitted && TDDepartmentRef.invalid"
											>
											</ng-select>
											<span
												*ngIf="
													accountSettingsInfo.submitted &&
													TDDepartmentRef.invalid &&
													(TDDepartmentRef.dirty || TDDepartmentRef.touched)
												"
												class="invalid-form"
											>
												<small
													class="form-text text-danger"
													*ngIf="TDDepartmentRef.errors.pattern"
													>{{ "UI.k_pattern_validation" | translate }}
												</small>
												<small
													class="form-text text-danger"
													*ngIf="TDDepartmentRef.errors?.whitespace"
													>{{ "UI.k_leading_trailing_space_validation" | translate }}
												</small>
												<small
													class="form-text text-danger"
													*ngIf="
														TDDepartmentRef.errors.minlength ||
														TDDepartmentRef.errors.maxlength
													"
													>{{ "UI.k_department_validation" | translate }}
												</small>
											</span>
										</div>
									</div>
									<div class="col-12 col-sm-6">
										<div class="form-group">
											<label class="form-control-label" for="txtExpYr">{{
												"UI.k_experience_years" | translate
											}}</label>
											<input
												class="form-control input-md"
												[class.error]="TDExperYearRef.invalid && accountSettingsInfo.submitted"
												#TDExperYearRef="ngModel"
												id="txtExpYr"
												name="txtExpYr"
												type="number"
												(keypress)="numberOnly($event)"
												(click)="onFormChange(accountSettingsInfo)"
												max="99"
												min="0"
												[(ngModel)]="accountSetting.info.profile.experience_years"
												placeholder="{{ 'UI.k_enter_experience_years' | translate }}"
											/>
											<div
												class="error"
												*ngIf="accountSettingsInfo.submitted && TDExperYearRef.invalid"
											>
												<small
													class="form-text text-danger"
													*ngIf="TDExperYearRef.invalid && accountSettingsInfo.submitted"
													>{{ "UI.k_alert_numeric_field" | translate }}</small
												>
												<small *ngIf="TDExperYearRef.errors.max">
													{{ "Message.msg_validate_number" | translate }}
													0
													{{ "Message.msg_and" | translate }}
													99.
												</small>
											</div>
										</div>
									</div>
									<div class="col-12 col-sm-6">
										<div class="form-group">
											<label class="form-control-label" for="txtExpMth">{{
												"UI.k_experience_months" | translate
											}}</label>
											<input
												class="form-control input-md"
												[class.error]="TDExperMonthRef.invalid && accountSettingsInfo.submitted"
												[(ngModel)]="accountSetting.info.profile.experience_months"
												#TDExperMonthRef="ngModel"
												pattern="([0-9]|10|11)"
												(keypress)="numberOnly($event)"
												(click)="onFormChange(accountSettingsInfo)"
												id="txtExpMth"
												name="txtExpMth"
												type="text"
												placeholder="{{ 'UI.k_enter_experience_months' | translate }}"
											/>
											<div
												class="error"
												*ngIf="accountSettingsInfo.submitted && TDExperMonthRef.invalid"
											>
												<small
													class="form-text text-danger"
													*ngIf="TDExperMonthRef.invalid && accountSettingsInfo.submitted"
													>{{ "UI.k_alert_numeric_field" | translate }}</small
												>
												<small *ngIf="TDExperMonthRef.errors.pattern">
													{{ "Message.msg_validate_number" | translate }}
													0
													{{ "Message.msg_and" | translate }}
													11.
												</small>
											</div>
										</div>
									</div>
									<div class="col-12 col-sm-6">
										<div class="form-group">
											<label class="form-label" for="selSkills">{{
												"UI.k_skills" | translate
											}}</label>
											<ng-select
												[(ngModel)]="accountSetting.info.profile.skills"
												#TDSkillsRef="ngModel"
												notFoundText="{{ 'UI.k_no_items_found' | translate }}"
												name="selSkills"
												id="selSkills"
												[items]="[]"
												[addTag]="true"
												addTagText="{{ 'UI.k_add_item' | translate }}"
												[multiple]="true"
												(click)="onFormChange(accountSettingsInfo)"
												placeholder="{{ 'UI.k_skills' | translate }}"
												[class.error]="accountSettingsInfo.submitted && TDSkillsRef.invalid"
												hideSelected="true"
											></ng-select>
										</div>
									</div>
									<div class="col-12 col-sm-6">
										<div class="form-group">
											<label class="form-label" for="selNtfyEmail">{{
												"UI.k_notify_email" | translate
											}}</label>
											<span
												id="notifyEmailTooltip"
												name="notifyEmailTooltip"
												class="ml-50"
												ngbTooltip="{{ 'UI.k_notify_email_descr' | translate }}"
												placement="right"
												tooltipClass="custom-class"
												><i class="fa-light fa-info-circle"></i
											></span>
											<ng-select
												[(ngModel)]="accountSetting.info.profile.notify_email"
												#TDNotifyEmailRef="ngModel"
												notFoundText="{{ 'UI.k_no_items_found' | translate }}"
												name="selNtfyEmail"
												id="selNtfyEmail"
												[items]="[]"
												[addTag]="true"
												addTagText="{{ 'UI.k_add_item' | translate }}"
												[multiple]="true"
												[appEmailCheck]="accountSetting.info.profile.notify_email"
												placeholder="john.doe@email.com"
												(click)="onFormChange(accountSettingsInfo)"
												[class.error]="
													accountSettingsInfo.submitted &&
													TDNotifyEmailRef.errors?.invalidemail
												"
												hideSelected="true"
											></ng-select>
											<span *ngIf="accountSettingsInfo.submitted" class="invalid-form">
												<small
													class="form-text text-danger"
													*ngIf="TDNotifyEmailRef.errors?.invalidemail"
													>{{ "UI.k_notify_email_invalid" | translate }}</small
												>
												<small class="form-text text-danger"
												*ngIf="TDNotifyEmailRef.errors?.max_email">{{"UI.k_email_max_validation"	| translate:{"maxLimit": TDNotifyEmailRef.errors?.limit} }}
												</small>
											</span>
										</div>
									</div>
									<div class="col-12 col-sm-6">
										<div class="form-group">
											<label class="form-label" for="selNtfyPhn">{{
												"UI.k_notify_phone" | translate
											}}</label>
											<span
												id="notifyPhoneTooltip"
												name="notifyPhoneTooltip"
												class="ml-50"
												ngbTooltip="{{ 'UI.k_notify_phone_descr' | translate }}"
												placement="right"
												tooltipClass="custom-class"
												><i class="fa-light fa-info-circle"></i
											></span>
											<ng-select
												[(ngModel)]="accountSetting.info.profile.notify_phone"
												#TDNotifyPhoneRef="ngModel"
												notFoundText="{{ 'UI.k_no_items_found' | translate }}"
												name="selNtfyPhn"
												id="selNtfyPhn"
												(click)="onFormChange(accountSettingsInfo)"
												[items]="[]"
												[addTag]="true"
												addTagText="{{ 'UI.k_add_item' | translate }}"
												[multiple]="true"
												placeholder="xxxxxxxxxx"
												[class.error]="
													accountSettingsInfo.submitted && TDNotifyPhoneRef.invalid
												"
												[appPhoneCheck]="accountSetting.info.profile.notify_phone"
												hideSelected="true"
											></ng-select>
											<span
												*ngIf="accountSettingsInfo.submitted && TDNotifyPhoneRef.invalid"
												class="invalid-form"
											>
												<small
													class="form-text text-danger"
													*ngIf="TDNotifyPhoneRef.errors?.invalidphone"
													>{{ "UI.k_pattern_validation" | translate }}</small
												>
											</span>
										</div>
									</div>
									<div class="col-12 col-sm-6">
										<div class="form-group">
											<label class="form-label" for="txtBizEnt">{{
												"UI.k_business_entity" | translate
											}}</label>
											<input
												[(ngModel)]="accountSetting.info.profile.business_entity"
												#TDBizEntityRef="ngModel"
												type="text"
												(click)="onFormChange(accountSettingsInfo)"
												name="txtBizEnt"
												id="txtBizEnt"
												class="form-control"
												[appWhiteSpaceCheck]="accountSetting.info.profile.business_entity"
												minlength="3"
												maxlength="40"
												[class.error]="accountSettingsInfo.submitted && TDBizEntityRef.invalid"
												placeholder="{{ 'UI.k_enter_business_entity' | translate }}"
											/>
											<span
												*ngIf="
													accountSettingsInfo.submitted &&
													TDBizEntityRef.invalid &&
													(TDBizEntityRef.dirty || TDBizEntityRef.touched)
												"
												class="invalid-form"
											>
												<small
													class="form-text text-danger"
													*ngIf="TDBizEntityRef.errors.pattern"
													>{{ "UI.k_pattern_validation" | translate }}
												</small>
												<small
													class="form-text text-danger"
													*ngIf="TDBizEntityRef.errors?.whitespace"
													>{{ "UI.k_leading_trailing_space_validation" | translate }}
												</small>
												<small
													class="form-text text-danger"
													*ngIf="
														TDBizEntityRef.errors.minlength ||
														TDBizEntityRef.errors.maxlength
													"
													>{{ "UI.k_business_entity_validation" | translate }}
												</small>
											</span>
										</div>
									</div>
									<div class="col-12 col-sm-6">
										<div class="form-group">
											<label class="form-label" for="txtBizSeg">{{
												"UI.k_business_segment" | translate
											}}</label>
											<input
												[(ngModel)]="accountSetting.info.profile.business_segment"
												#TDBizSegmentRef="ngModel"
												type="text"
												(click)="onFormChange(accountSettingsInfo)"
												name="txtBizSeg"
												id="txtBizSeg"
												class="form-control"
												[appWhiteSpaceCheck]="accountSetting.info.profile.business_segment"
												minlength="3"
												maxlength="40"
												[class.error]="accountSettingsInfo.submitted && TDBizSegmentRef.invalid"
												placeholder="{{ 'UI.k_enter_business_segment' | translate }}"
											/>
											<span
												*ngIf="
													accountSettingsInfo.submitted &&
													TDBizSegmentRef.invalid &&
													(TDBizSegmentRef.dirty || TDBizSegmentRef.touched)
												"
												class="invalid-form"
											>
												<small
													class="form-text text-danger"
													*ngIf="TDBizSegmentRef.errors.pattern"
													>{{ "UI.k_pattern_validation" | translate }}
												</small>
												<small
													class="form-text text-danger"
													*ngIf="TDBizSegmentRef.errors?.whitespace"
													>{{ "UI.k_leading_trailing_space_validation" | translate }}
												</small>

												<small
													class="form-text text-danger"
													*ngIf="
														TDBizSegmentRef.errors.minlength ||
														TDBizSegmentRef.errors.maxlength
													"
													>{{ "UI.k_business_segment_validation" | translate }}
												</small>
											</span>
										</div>
									</div>
									<div class="col-12 col-sm-6">
										<div class="form-group">
											<label class="form-label" for="txtPName">{{
												"UI.k_partner_name" | translate
											}}</label>
											<input
												[(ngModel)]="accountSetting.info.profile.partner_name"
												#TDPartnerNameRef="ngModel"
												type="text"
												name="txtPName"
												id="txtPName"
												class="form-control"
												[appWhiteSpaceCheck]="accountSetting.info.profile.partner_name"
												(click)="onFormChange(accountSettingsInfo)"
												minlength="3"
												maxlength="40"
												[class.error]="
													accountSettingsInfo.submitted && TDPartnerNameRef.invalid
												"
												placeholder="{{ 'UI.k_enter_partner_name' | translate }}"
											/>
											<span
												*ngIf="
													accountSettingsInfo.submitted &&
													TDPartnerNameRef.invalid &&
													(TDPartnerNameRef.dirty || TDPartnerNameRef.touched)
												"
												class="invalid-form"
											>
												<small
													class="form-text text-danger"
													*ngIf="TDPartnerNameRef.errors.pattern"
													>{{ "UI.k_pattern_validation" | translate }}
												</small>
												<small
													class="form-text text-danger"
													*ngIf="TDPartnerNameRef.errors?.whitespace"
													>{{ "UI.k_leading_trailing_space_validation" | translate }}
												</small>
												<small
													class="form-text text-danger"
													*ngIf="
														TDPartnerNameRef.errors.minlength ||
														TDPartnerNameRef.errors.maxlength
													"
													>{{ "UI.k_partner_name_validation" | translate }}
												</small>
											</span>
										</div>
									</div>
									<div class="col-12 col-sm-6">
										<div class="form-group">
											<label class="form-label" for="txtSub">{{
												"UI.k_subsidiary" | translate
											}}</label>
											<input
												[(ngModel)]="accountSetting.info.profile.subsidiary"
												#TDSubsidiaryRef="ngModel"
												type="text"
												name="txtSub"
												id="txtSub"
												class="form-control"
												[appWhiteSpaceCheck]="accountSetting.info.profile.subsidiary"
												(click)="onFormChange(accountSettingsInfo)"
												minlength="3"
												maxlength="40"
												[class.error]="accountSettingsInfo.submitted && TDSubsidiaryRef.invalid"
												placeholder="{{ 'UI.k_enter_subsidiary' | translate }}"
											/>
											<span
												*ngIf="
													accountSettingsInfo.submitted &&
													TDSubsidiaryRef.invalid &&
													(TDSubsidiaryRef.dirty || TDSubsidiaryRef.touched)
												"
												class="invalid-form"
											>
												<small
													class="form-text text-danger"
													*ngIf="TDSubsidiaryRef.errors.pattern"
													>{{ "UI.k_pattern_validation" | translate }}
												</small>
												<small
													class="form-text text-danger"
													*ngIf="TDSubsidiaryRef.errors?.whitespace"
													>{{ "UI.k_leading_trailing_space_validation" | translate }}
												</small>

												<small
													class="form-text text-danger"
													*ngIf="
														TDSubsidiaryRef.errors.minlength ||
														TDSubsidiaryRef.errors.maxlength
													"
													>{{ "UI.k_subsidiary_name_validation" | translate }}
												</small>
											</span>
										</div>
									</div>

									<div class="col-12">
										<button
											id="infoAccountSettings"
											[disabled]="!accountSettingsInfo.form.touched"
											(click)="saveSettingsInfo(accountSettingsInfo, form)"
											class="btn btn-primary mt-1 mr-1"
											rippleEffect
										>
											<i class="fa-light fa-save align-middle mr-50 ml-0"></i>
											{{ "UI.k_submit" | translate }}
										</button>
									</div>
								</div>
							</form>
						</div>
						<div class="email-user-list p-1" *ngIf="currentMenu == 'manageNotify'">
							<ng-container *ngFor="let notify of notification | keyvalue:keepOrder; let i = index" >
							<div class="row mb-1">
								<h6 class="col-md-10">{{notify?.value?.label}}</h6> 
								<div class="col-md-2 custom-control custom-control-primary custom-switch custom-switch-sm">
				
									<input type="checkbox"  class="custom-control-input" id="customSwitchKey{{i}}"
									[(ngModel)]="notify.value.is_selected" [checked]="notify.value.is_selected"
									/>
									<label class="custom-control-label" for="customSwitchKey{{i}}"></label>
								</div>
							</div>
				
							<ng-container *ngFor="let conf of notify.value.list ; let j = index">
								<div class="row mb-1">
									<span class="col-md-10 font-small-2">{{conf.context}}</span>
									<div class="col-md-2 custom-control custom-control-primary custom-switch custom-switch-sm">
										<input type="checkbox" checked class="custom-control-input" id="customSwitch_{{conf.id}}" 
										[(ngModel)]="conf.id" [disabled]="notify?.value?.is_selected"/>
										<label class="custom-control-label" for="customSwitch_{{conf.id}}"></label>
									</div>
								</div>
							</ng-container>
							</ng-container>
						</div>
					</ng-scrollbar>
				</div>
				<div class="flex-auto-height" *ngIf="currentMenu == 'signature'">
					<div class="flex-initial">
						<div class="action-bar">
							<div class="inner-section">
								<div class="left-section">									
									<div class="action-search">
										<!-- <app-search></app-search> -->
									</div>          
								</div>
								<div class="right-section">
									<div class="pull-right">																														
										<button
											*ngIf="permissions?.add"
											type="button"
											class="btn btn-primary"																	
											id="btnDownload"
											name="btnDownload"
											rippleEffect
											tooltipClass="fadeInAnimation"
											ngbTooltip="{{ 'UI.k_new_template' | translate }}"
											placement="bottom"	
											(click)="addSignature('add-signature')"                																																
										>								
											<i class="fa-light fa-plus"></i>
											<span class="align-middle">{{ 'UI.k_new' | translate }} {{ 'UI.k_signature' | translate }}</span>
										</button>                  										  					
									</div>
								</div>
							</div>
						</div> 
					</div>
					<div class="action-section">
						<div class="table-view flex-height">
							<dns-server-grid
								[rows]="signatureTemplate"
								[columns]="signatureTemplateColumns"
								[count]="signatureTemplateCount"
								(page)="setPage($event)"
								(sort)="onSort($event)"                
								[isEditable]="false"
								(setlimit)="setLimit($event)"
								class="bootstrap core-bootstrap"						
								[limit]="limit"     
								[permissions]="permissions"	
								[scrollbarH]="true"								                                                     								
							></dns-server-grid>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- <div *ngIf="!showData">
		  <app-account-setting-skeleton></app-account-setting-skeleton>
		</div> -->
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="add-signature"
	overlayClass="modal-backdrop"
>
	<app-add-signature
		[event]="event"
		[sign_id]="sign_id"
		*ngIf="is_sign_loaded"
		(hideSignSidebar)="hideSignSidebar($event)"
></app-add-signature>
</core-sidebar>