import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'apps-menu',
  templateUrl: './top-apps-menu.component.html',
  styleUrls: ['./top-apps-menu.component.scss'],
})
export class TopAppsMenuComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
