import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-features-skeleton",
	templateUrl: "./features-skeleton.component.html",
	styleUrls: ["./features-skeleton.component.scss"],
})
export class FeaturesSkeletonComponent implements OnInit {
	constructor() {}

	ngOnInit(): void {}

	generateFake(count: number): Array<number> {
		const indexes = [];
		for (let i = 0; i < count; i++) {
			indexes.push(i);
		}
		return indexes;
	}
}
