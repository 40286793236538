import { Component, EventEmitter, Injectable, Input, OnInit, Output, ViewChild } from "@angular/core";
import { TreeDataService } from "./tree-data.service";
import {
	TreeviewI18n,
	TreeviewItem,
	TreeviewConfig,
	DropdownTreeviewComponent,
	TreeviewHelper,
	TreeviewEventParser,
	OrderDownlineTreeviewEventParser,
} from "ngx-treeview";

@Injectable()
export class ProductTreeviewConfig extends TreeviewConfig {
	hasAllCheckBox = true;
	hasFilter = true;
	hasCollapseExpand = false;
	maxHeight = 400;
}
@Component({
	selector: "app-single-tree-dropdown",
	templateUrl: "./single-tree-dropdown.component.html",
	styleUrls: ["./single-tree-dropdown.component.scss"],
	providers: [
		TreeDataService,
		{ provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser },
		{ provide: TreeviewConfig, useClass: ProductTreeviewConfig },
	],
})
export class SingleTreeDropdownComponent implements OnInit {
	buttonClasses = [
		"btn-outline-primary",
		"btn-outline-secondary",
		"btn-outline-success",
		"btn-outline-danger",
		"btn-outline-warning",
		"btn-outline-info",
		"btn-outline-light",
		"btn-outline-dark",
	];
	buttonClass = this.buttonClasses[0];
	@Input() config: TreeviewConfig = TreeviewConfig.create({
		hasFilter: true,
		hasCollapseExpand: true,
	});
	@Input() items: TreeviewItem[];
	@Input() value: any;
	@Input() id: any;
	@Input() treeInputConfig: any;
	@Output() itemChange = new EventEmitter<any>();
	constructor(public i18n: TreeviewI18n, public service: TreeDataService) {}

	ngOnInit(): void {}

	onItemChange(item: any): void {
		if (this.itemChange) {
			this.itemChange.emit(item);
		}
	}
}
