import { Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { ToastrService } from "ngx-toastr";
import { catchError, map } from "rxjs/operators";
import { UserService } from "../../services/user.service";
import { TranslateService } from "@ngx-translate/core";
import { GlobalConstants } from "app/app.globalConstants";
import { throwError } from "rxjs";
import { encryptData } from "app/common/utils/utils";
import Swal from "sweetalert2";

@Component({
	selector: "app-invite-users",
	templateUrl: "./invite-users.component.html",
	styleUrls: ["./invite-users.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class InviteUsersComponent implements OnInit, OnChanges {
	public roleList;
	public defaultInvites = [];
	public newInvite;
	@Output("onHideInvite") onHideInvite = new EventEmitter<any>();
	invite_users_max_limit: any;
	show_max_error_msg = false;
	show_user_disable_badge = false;
	allowed_users: any;
	active_users_count: any;

	constructor(
		private _coreSidebarService: CoreSidebarService,
		private _userService: UserService,
		private _toastr: ToastrService,
		private _translateService: TranslateService
	) {}

	ngOnInit(): void {
		this._userService.getOptions().subscribe(
			(response) => {
				if (response) {
					this.roleList = response["roles"];
					this.invite_users_max_limit = response?.invite_users_max_limit;
					this.active_users_count = response?.active_users_count;
					if (this.active_users_count >= GlobalConstants?.dnsLavModules?.USER_COUNT - 2) {
						this.show_user_disable_badge = true;
						this.allowed_users = GlobalConstants?.dnsLavModules?.USER_COUNT;
					}
					if (this.roleList) {
						this.defaultInvites.push({
							userEmail: "",
							userRole: this.roleList[0].group_id,
						});
						this.newInvite = {
							userEmail: "",
							userRole: this.roleList[0].group_id,
						};
					}
				}
			},
			(error) => {
				//todo
			}
		);
	}

	ngOnChanges(changes: SimpleChanges): void {
		//Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
		//Add '${implements OnChanges}' to the class.
	}

	toggleSidebar = (name, form): void => {
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
		form.resetForm();
		this.onHideInvite.emit("hide");
	};

	addItem() {
		this.show_max_error_msg = false;
		// if (this.defaultInvites.length < this.invite_users_max_limit){
		if (this.roleList) {
			this.defaultInvites.push({
				userEmail: "",
				userRole: this.roleList[0].group_id,
			});
		} else {
			this.defaultInvites.push({
				userEmail: "",
				userRole: -1,
			});
		}
		// }
		// else {
		// 	this.show_max_error_msg = true
		// }
	}

	/**
	 * DeleteItem
	 *
	 * @param id
	 */
	deleteItem(id) {
		for (let i = 0; i < this.defaultInvites.length; i++) {
			if (this.defaultInvites.indexOf(this.defaultInvites[i]) === id) {
				this.defaultInvites.splice(i, 1);
				break;
			}
		}
		if (this.defaultInvites.length < this.invite_users_max_limit) {
			this.show_max_error_msg = false;
		}
	}
	inviteUsers = (name, form, data): void => {
		if (form.valid === true) {
			this._userService
				.sendUserInvite({ data: encryptData(JSON.stringify(this.defaultInvites)), module_name: "User" })
				.pipe(
					map((response: any) => {
						if (response) {
							let email_ids_list = response.data?.existing_profiles;
							let pending_ids_list = response.data?.pending_profiles;
							let user_req_ids_list = response.data?.requester_profiles
							// this.notification.success('Saved User', 'User has been saved successfully')
							if (response["status"] == "success") {
								form.resetForm();
								this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
								this.onHideInvite.emit("hide");
								//console.log("pending_ids_list", pending_ids_list);
								setTimeout(() => {
									this._toastr.success(
										this._translateService.instant(response["message"], {
											email_ids_list: email_ids_list,
											pending_email_ids: pending_ids_list,
										}),
										this._translateService.instant("Message.msg_user_invite"),
										{
											toastClass: "toast ngx-toastr",
											closeButton: true,
											positionClass: "toast-top-right",
										}
									);
								}, 500);
							} else {
								//console.log("in error");
								this._toastr.error(
									this._translateService.instant(response["message"], {
										email_ids_list: email_ids_list,
										pending_email_ids: pending_ids_list,
										req_email_ids_list: user_req_ids_list,
									}),
									this._translateService.instant("Error.err_invite_fail"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							}
							if (user_req_ids_list?.length > 0){
								let req_email_map = []
								
								this.defaultInvites.forEach(element => {
									if (user_req_ids_list.includes(element.userEmail)){
										req_email_map.push({
											email: element.userEmail,
											role_id: element?.userRole
										})
									};
								});
								Swal.fire({
									title: this._translateService.instant("Message.msg_update_requester_user_title"),
									text: this._translateService.instant("Message.msg_delete_text"),
									icon: "warning",
									showCancelButton: true,
									confirmButtonText: this._translateService.instant("UI.k_ok"),
									cancelButtonText: this._translateService.instant("Message.msg_delete_cancel"),
									customClass: {
										confirmButton: "btn btn-primary",
										cancelButton: "btn btn-danger ml-1",
									},
								}).then((result) => {
									if (result.value) {
										this._userService
											.enableRequesterUserProfile({requester_data: req_email_map})
											.pipe(
												map((response: any) => {
													if (response.status == "success") {
														form.resetForm();
														this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
														this.onHideInvite.emit("update");
														this._toastr.success(
															this._translateService.instant("Message.msg_user_updated"),
															this._translateService.instant("Message.msg_update_success"),
															{
																toastClass: "toast ngx-toastr",
																closeButton: true,
																positionClass: "toast-top-right",
															}
														);
													} else {
														this._toastr.error(
															this._translateService.instant("Error.err_user_save_failed"),
															this._translateService.instant("Error.err_fail"),
															{
																toastClass: "toast ngx-toastr",
																closeButton: true,
																positionClass: "toast-top-right",
															}
														);
													}
													// Success
												}),
												catchError((error) => {
													this._toastr.error(
														this._translateService.instant("Error.err_user_save_failed"),
														this._translateService.instant("Error.err_fail"),
														{
															toastClass: "toast ngx-toastr",
															closeButton: true,
															positionClass: "toast-top-right",
														}
													);
													return throwError(error.statusText);
												})
											)
											.subscribe();
									}
								});
							}

						}
						// Success
					}),
					catchError((error) => {
						//console.log("in catch");
						this._toastr.error(
							this._translateService.instant(error.error.error.message),
							this._translateService.instant("Error.err_invite_fail"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						//   this.notification.error('Failed User Creation', 'Something went wrong')
						return throwError(error.statusText);
					})
				)
				.subscribe();
		}
	};
}
