import { NgModule } from '@angular/core';

import { FilterPipe } from '@core/pipes/filter.pipe';

import { InitialsPipe } from '@core/pipes/initials.pipe';
import { DictionarylenPipe } from '@core/pipes/diclen.pipe';


import { SafePipe } from '@core/pipes/safe.pipe';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';
import { PreserveOrderPipePipe } from './preserve-order-pipe.pipe';

@NgModule({
  declarations: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe,DictionarylenPipe, PreserveOrderPipePipe],
  imports: [],
  exports: [InitialsPipe, FilterPipe, StripHtmlPipe, SafePipe,DictionarylenPipe, PreserveOrderPipePipe]
})
export class CorePipesModule {}
