import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "app/common/guards/auth.guards";
import { AuditGridComponent } from "./components/audit-grid/audit-grid.component";

const routes: Routes = [
	{
		path: "",
		component: AuditGridComponent,
		canActivate: [AuthGuard],
		data: {
			module: ["audit"],
			keyList: ["view"],
		},
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class AuditRoutingModule {}
