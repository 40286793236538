<div class="server-count">
	<ng-container *ngFor="let item of widgetData?.data?.summary">
		<div
			class="single-server border-bottom ml-1 mr-1 cursor-pointer"
			id="divServerCount"
			name="divServerCount"
			(click)="showContractSidebar(item)"
		>
			<div class="icon-sec ml-50">
				<img [src]="getImg(item)" widht="16" height="16" class="imgColor" />
			</div>
			<div class="info-sec">
				<div class="server-count set-color">
					{{ item.count }}
				</div>
				<div class="server-info">{{ item.id | translate }}</div>
			</div>
		</div>
	</ng-container>
</div>

