import { SupportCoreMenu } from "@core/types";

export const SupportMenu: SupportCoreMenu[] = [
	{
		id: "support_home",
		title: "UI.k_home",
		type: "item",
		icon: "home",
		url: "support/home",
		position: "sub",
		permission: {
			moduleList: ["infinity_support"],
			keyList: ["view"],
		},
	},
	// {
	// 	id: "support_users",
	// 	title: "UI.k_users",
	// 	type: "item",
	// 	icon: "user-group",
	// 	url: "support/users",
	// position: 'sub',
	// 	permission: {
	// 		moduleList: ["user"],
	// 		keyList: ["view"],
	// 	},
	// },
	{
		id: "audits",
		title: "UI.k_audits",
		type: "item",
		icon: "list-timeline",
		url: "support/audit",
		position: "sub",
		permission: {
			moduleList: ["infinity_support"],
			keyList: ["view"],
		},
	},
	{
		id: "invoice",
		title: "UI.k_invoices",
		type: "item",
		icon: "file-invoice",
		url: "support/invoices",
		position: "sub",
		permission: {
			moduleList: ["infinity_support"],
			keyList: ["view"],
		},
	},
	{
		id: "partner",
		title: "UI.k_partner",
		type: "item",
		icon: "user-tie",
		url: "support/partner",
		position: "sub",
		permission: {
			moduleList: ["infinity_partner"],
			keyList: ["view"],
		},
	},
];
