<div>
  <h3>{{ filterBarObject.value.title }}</h3>
</div>

<ng-container *ngFor="let item of filterBarObject.value.options; let i = index">
  <span>
    <div class="form-group">
      <ng-select
        [items]="item.options"
        [(ngModel)]="item.ng_model_value"
        bindLabel="label"
        id="test"
        [attr.data-testid]="'btn_test1'"
        #dummyClick
        placeholder="Enter or select"
        (ngModelChange)="emitSelectedValue($event, item)"
      >
      </ng-select>
    </div>
  </span>
</ng-container>
