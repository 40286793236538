<div class="slideout-content default-slideout">
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
		<div class="modal-dialog sidebar-md">
			<div class="modal-content add-new-user pt-0">
				<div class="modal-header">
					<div class="d-flex align-items-center gap-1">
						<h5 class="modal-title" id="exampleModalLabel">
							{{ "UI.k_getting_started" | translate }}
						</h5>
						<div class="getting-started-item">
							<div class="getting-started-sec">
								<div class="getting-stared-step">
									<div class="step-info">
										<div class="fw-600 title-color">
											<span class="completed-step fw-600">{{ completedStep.length }}</span
											>/<span class="total-step">{{ totalGettingStep.length }}</span>
										</div>
										<span class="title-color">{{ "UI.k_completed" | translate }}</span>
									</div>
									<div class="steps">
										<div class="total-step">
											<ng-container *ngFor="let item of totalGettingStep; let i = index">
												<span class="step step-{{ i }}"></span>
											</ng-container>
										</div>
										<div class="completed-step">
											<ng-container *ngFor="let Cstep of completedStep; let j = index">
												<span class="done done-{{ j }}"></span>
											</ng-container>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<button
						type="button"
						id="btnClose"
						name="btnClose"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						(click)="toggleSidebar('getting-stared')"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body">
					<div class="modal-content-body flex-1">
						<ng-scrollbar class="custom-scrollbar section-scrollbar grid-full-height">
							<div class="flex-auto-height">
								<div class="p-1 flex-initial">
									<ng-container *ngIf="showRemainDays">
										<div class="remian-day-sec">
											<ng-container *ngIf="rem_days">
												<div class="remain-days">
													<div class="day-left primary-bg primary-border">
														<span class="fw-600">{{ rem_days }}</span>
													</div>
													<span class="sm-font fw-600 title-color">{{
														"UI.k_days_left" | translate
													}}</span>
												</div>
											</ng-container>
											<span class="buy-product">
												<button
													type="button"
													id="btnSubscribe"
													name="btnSubscribe"
													class="btn btn-sm btn-primary"
													rippleEffect
												>
													{{ "UI.k_buy_now" | translate }}
												</button>
											</span>
										</div>
									</ng-container>
									<div class="getting-steps-info">
										<h6>Getting Started</h6>
										<div class="steps-info d-grid grid-col-2 gap-1">
											<ng-container *ngIf="showAccessUrl">
												<div
													class="single-step cursor-pointer step-1 d-flex border justify-content-between align-items-center"
													(click)="goSelectedStep('access_url')"
												>
													<div class="step-info">
														<ng-container *ngIf="!completedStep.includes('access_url')">
															<div class="step-count text-muted-color sm-font">
																{{
																	"UI.k_step_n"
																		| translate: { step: stepCountMap.access_url }
																}}
															</div>
														</ng-container>
														<ng-container *ngIf="completedStep.includes('access_url')">
															<div
																class="step-completed d-flex gap-25 txt-success align-items-center justify-content-center"
															>
																<i class="fa-light fa-circle-check"></i>
																<div class="step-count sm-font">
																	{{
																		"UI.k_step_n"
																			| translate: { step: stepCountMap.access_url }
																	}}
																</div>
															</div>
														</ng-container>
														<div class="step-name fw-600">
															{{ "UI.k_infraon_url" | translate }}
														</div>
													</div>
													<ng-container *ngIf="!completedStep.includes('access_url')">
														<div class="step-btn">
															<button
																type="button"
																class="btn btn-icon btn-sm btn-outline-primary"
																rippleEffect
															>
																<span class=""
																	><i class="lg-font fa-light fa-arrow-right"></i
																></span>
																<!-- <ng-container *ngIf="!completedStep.includes('access_url')"
																	>{{ "UI.k_url" | translate }}</ng-container
																> -->
															</button>
														</div>
													</ng-container>
												</div>
											</ng-container>
											<ng-container *ngIf="is_mail_config">
												<div
													class="single-step cursor-pointer step-2 d-flex border justify-content-between align-items-center"
													(click)="goSelectedStep('support_email')"
												>
													<div class="step-info">
														<ng-container *ngIf="!completedStep.includes('support_email')">
															<div class="step-count text-muted-color sm-font">
																{{
																	"UI.k_step_n"
																		| translate: { step: stepCountMap.support_email }
																}}
															</div>
														</ng-container>
														<ng-container *ngIf="completedStep.includes('support_email')">
															<div
																class="step-completed d-flex gap-25 txt-success align-items-center justify-content-center"
															>
																<i class="fa-light fa-circle-check"></i>
																<div class="step-count sm-font">
																	{{
																		"UI.k_step_n"
																			| translate
																				: { step: stepCountMap.support_email }
																	}}
																</div>
															</div>
														</ng-container>
														<div class="step-name fw-600">
															{{ "UI.k_setup_your_email" | translate }}
														</div>
													</div>
													<ng-container *ngIf="!completedStep.includes('support_email')">
														<div class="step-btn">
															<button
																type="button"
																class="btn btn-icon btn-sm btn-outline-primary"
																rippleEffect
															>
																<span class=""
																	><i class="lg-font fa-light fa-arrow-right"></i
																></span>
																<!-- <ng-container *ngIf="!completedStep.includes('support_email')"
																	>{{ "UI.k_email" | translate }}</ng-container
																> -->
															</button>
														</div>
													</ng-container>
												</div>
											</ng-container>
											<ng-container *ngIf="showRole">
												<div
													class="single-step cursor-pointer step-3 d-flex border justify-content-between align-items-center"
													(click)="goSelectedStep('role')"
												>
													<div class="step-info">
														<ng-container *ngIf="!completedStep.includes('role')">
															<div class="step-count text-muted-color sm-font">
																{{ "UI.k_step_n" | translate: { step: stepCountMap.role } }}
															</div>
														</ng-container>
														<ng-container *ngIf="completedStep.includes('role')">
															<div
																class="step-completed d-flex gap-25 txt-success align-items-center justify-content-center"
															>
																<i class="fa-light fa-circle-check"></i>
																<div class="step-count sm-font">
																	{{
																		"UI.k_step_n"
																			| translate: { step: stepCountMap.role }
																	}}
																</div>
															</div>
														</ng-container>
														<div class="step-name fw-600">
															{{ "UI.k_role_privileges" | translate }}
														</div>
													</div>
													<ng-container *ngIf="!completedStep.includes('role')">
														<div class="step-btn">
															<button
																type="button"
																class="btn btn-icon btn-sm btn-outline-primary"
																rippleEffect
															>
																<span class=""
																	><i class="lg-font fa-light fa-arrow-right"></i
																></span>
																<!-- <ng-container *ngIf="!completedStep.includes('role')"
																	>{{ "UI.k_role" | translate }}</ng-container
																> -->
															</button>
														</div>
													</ng-container>
												</div>
											</ng-container>
											<ng-container *ngIf="showInviteAgent">
												<div
													class="single-step cursor-pointer step-5 d-flex border justify-content-between align-items-center"
													(click)="goSelectedStep('invite_agent')"
												>
													<div class="step-info">
														<ng-container *ngIf="!completedStep.includes('invite_agent')">
															<div class="step-count text-muted-color sm-font">
																{{
																	"UI.k_step_n"
																		| translate: { step: stepCountMap.invite_agent }
																}}
															</div>
														</ng-container>
														<ng-container *ngIf="completedStep.includes('invite_agent')">
															<div
																class="step-completed d-flex gap-25 txt-success align-items-center justify-content-center"
															>
																<i class="fa-light fa-circle-check"></i>
																<div class="step-count sm-font">
																	{{
																		"UI.k_step_n"
																			| translate: { step: stepCountMap.invite_agent }
																	}}
																</div>
															</div>
														</ng-container>
														<div class="step-name fw-600">
															{{ "UI.k_invite_usr" | translate }}
														</div>
													</div>
													<ng-container *ngIf="!completedStep.includes('invite_agent')">
														<div class="step-btn">
															<button
																type="button"
																class="btn btn-icon btn-sm btn-outline-primary"
																rippleEffect
															>
																<span class=""
																	><i class="lg-font fa-light fa-arrow-right"></i
																></span>
																<!-- <ng-container *ngIf="!completedStep.includes('invite_agent')"
																	>{{ "UI.k_invite" | translate }}</ng-container
																> -->
															</button>
														</div>
													</ng-container>
												</div>
											</ng-container>
											<ng-container *ngIf="showItAssetAgents">
												<div
													class="single-step cursor-pointer step-4 d-flex border justify-content-between align-items-center"
													(click)="goSelectedStep('it_asset_agents')"
												>
													<div class="step-info">
														<ng-container *ngIf="!completedStep.includes('it_asset_agents')">
															<div class="step-count text-muted-color sm-font">
																{{
																	"UI.k_step_n"
																		| translate: { step: stepCountMap.it_asset_agents }
																}}
															</div>
														</ng-container>
														<ng-container *ngIf="completedStep.includes('it_asset_agents')">
															<div
																class="step-completed d-flex gap-25 txt-success align-items-center justify-content-center"
															>
																<i class="fa-light fa-circle-check"></i>
																<div class="step-count sm-font">
																	{{
																		"UI.k_step_n"
																			| translate
																				: { step: stepCountMap.it_asset_agents }
																	}}
																</div>
															</div>
														</ng-container>
														<div class="step-name fw-600">
															{{ "UI.k_it_asset_agent" | translate }}
														</div>
													</div>
													<ng-container *ngIf="!completedStep.includes('it_asset_agents')">
														<div class="step-btn">
															<button
																type="button"
																class="btn btn-icon btn-sm btn-outline-primary"
																rippleEffect
															>
																<span class=""
																	><i class="lg-font fa-light fa-arrow-right"></i
																></span>
																<!-- <ng-container *ngIf="!completedStep.includes('it_asset_agents')"
																	>{{ "UI.k_agents" | translate }}</ng-container
																> -->
															</button>
														</div>
													</ng-container>
												</div>
											</ng-container>
											<ng-container *ngIf="showBranch">
												<div
													class="single-step cursor-pointer step-6 d-flex border justify-content-between align-items-center"
													(click)="goSelectedStep('branch')"
												>
													<div class="step-info">
														<ng-container *ngIf="!completedStep.includes('branch')">
															<div class="step-count text-muted-color sm-font">
																{{
																	"UI.k_step_n" | translate: { step: stepCountMap.branch }
																}}
															</div>
														</ng-container>
														<ng-container *ngIf="completedStep.includes('branch')">
															<div
																class="step-completed d-flex gap-25 txt-success align-items-center justify-content-center"
															>
																<i class="fa-light fa-circle-check"></i>
																<div class="step-count sm-font">
																	{{
																		"UI.k_step_n"
																			| translate: { step: stepCountMap.branch }
																	}}
																</div>
															</div>
														</ng-container>
														<div class="step-name fw-600">
															{{ "UI.k_location_info" | translate }}
														</div>
													</div>
													<ng-container *ngIf="!completedStep.includes('branch')">
														<div class="step-btn">
															<button
																type="button"
																class="btn btn-icon btn-sm btn-outline-primary"
																rippleEffect
															>
																<span class=""
																	><i class="lg-font fa-light fa-arrow-right"></i
																></span>
																<!-- <ng-container *ngIf="!completedStep.includes('branch')"
																	>{{ "UI.k_branch" | translate }}</ng-container
																> -->
															</button>
														</div>
													</ng-container>
												</div>
											</ng-container>
											<ng-container *ngIf="showBrandStyling">
												<ng-container *appDnsLicenseHandler="rebrand_obj?.license">
													<ng-container *appFeatureActive="rebrand_obj?.featureKeys">
														<div
														class="single-step cursor-pointer step-7 d-flex border justify-content-between align-items-center"
														(click)="goSelectedStep('branding')"
													>
														<div class="step-info">
															<ng-container *ngIf="!completedStep.includes('branding')">
																<div class="step-count text-muted-color sm-font">
																	{{
																		"UI.k_step_n"
																			| translate: { step: stepCountMap.branding }
																	}}
																</div>
															</ng-container>
															<ng-container *ngIf="completedStep.includes('branding')">
																<div
																	class="step-completed d-flex gap-25 txt-success align-items-center justify-content-center"
																>
																	<i class="fa-light fa-circle-check"></i>
																	<div class="step-count sm-font">
																		{{
																			"UI.k_step_n"
																				| translate: { step: stepCountMap.branding }
																		}}
																	</div>
																</div>
															</ng-container>
															<div class="step-name fw-600">
																{{ "UI.k_rebrand_infraon" | translate }}
															</div>
														</div>
														<ng-container *ngIf="!completedStep.includes('branding')">
															<div class="step-btn">
																<button
																	type="button"
																	class="btn btn-icon btn-sm btn-outline-primary"
																	rippleEffect
																>
																	<span class=""
																		><i class="lg-font fa-light fa-arrow-right"></i
																	></span>
																	<!-- <ng-container *ngIf="!completedStep.includes('branding')"
																		>{{ "UI.k_rebrand" | translate }}</ng-container
																	> -->
																</button>
															</div>
														</ng-container>
													</div>
													</ng-container>
												</ng-container>
											</ng-container>
										</div>
										<div class="video-step-link d-flex justify-content-end mt-50">
											<div class="video-link p-25 cursor-pointer" (click)="goSelectedStep('video')">
												<span class="align-middle d-sm-inline-block d-none underline-txt primary-color fw-500">
													{{ "UI.k_product_video" | translate }}</span
												>
												<i class="fa-light fa-arrow-right align-middle ml-50 primary-color"></i>
											</div>
										</div>
									</div>
								</div>
								<ng-container *ngIf="additionalConfiguration?.length > 0">
									<div class="additinal-configuration border-top bg-body p-1 flex-1">
										<h6 class="fw-600">{{ "UI.k_additional_config" | translate }}</h6>
										<div class="configuration d-grid grid-col-2 gap-1">
											<ng-container *ngFor="let item of additionalConfiguration">
												<ng-container *appDnsLicenseHandler="item?.license || {}">
													<ng-container *appFeatureActive="item?.featureKeys">
														<div
															class="single-config border cursor-pointer card-bg align-items-center"
															(click)="getRoute(item.configRoute)"
															attr.data-testid="btn_{{ item.id }}"
														>
															<div class="config-log">
																<i
																	[style.color]="item.iconColor"
																	class="fa-light {{ item.configIcon }}"
																></i>
															</div>
															<div class="config-info d-grid gap-25">
																<div class="config-name reg-font title-color fw-500">
																	{{ item.configName | translate }}
																</div>
																<div class="config-decr sm-font">
																	{{ item.configDescription | translate }}
																</div>
															</div>
														</div>
													</ng-container>
												</ng-container>
											</ng-container>
										</div>
									</div>
								</ng-container>
							</div>
						</ng-scrollbar>
					</div>
					<div class="modal-content-footer flex-initial">
						<div class="d-flex justify-content-end border-top p-1">
							<button
								id="btnClose"
								name="btnClose"
								class="btn btn-primary"
								type="submit"
								(click)="toggleSidebar('getting-stared')"
								rippleEffect
								[attr.data-testid]="'btnClose_'"
							>
								<span>{{ "UI.k_close" | translate }}</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
