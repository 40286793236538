import { TranslateModule } from "@ngx-translate/core";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { RouterModule } from "@angular/router";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import {
	PerfectScrollbarConfigInterface,
	PerfectScrollbarModule,
	PERFECT_SCROLLBAR_CONFIG,
} from "ngx-perfect-scrollbar";

import { CoreCommonModule } from "@core/common.module";
import { CoreTouchspinModule } from "@core/components/core-touchspin/core-touchspin.module";

import { NavbarComponent } from "app/common/layout/components/navbar/navbar.component";
import { NavbarBookmarkComponent } from "app/common/layout/components/navbar/navbar-bookmark/navbar-bookmark.component";
import { NavbarSearchComponent } from "app/common/layout/components/navbar/navbar-search/navbar-search.component";

import { NavbarNotificationComponent } from "app/common/layout/components/navbar/navbar-notification/navbar-notification.component";
import { NgApexchartsModule } from "ng-apexcharts";
import { BreadcrumbModule } from "../content-header/breadcrumb/breadcrumb.module";
import { CookieService } from "ngx-cookie-service";
import { NgSelectModule } from "@ng-select/ng-select";
import { GlobalSearchComponent } from "./global-search/global-search.component";
import { CoreSidebarModule } from "../../../../../@core/components";
import { DnsModule } from "../../../dns/dns.module";
import { NgScrollbarModule } from "ngx-scrollbar";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NotificationModule } from "app/base/notification/notification.module";
// import { AvatarModule } from 'ngx-avatar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true,
	wheelPropagation: false,
};

@NgModule({
	declarations: [
		NavbarComponent,
		NavbarSearchComponent,
		NavbarBookmarkComponent,
		NavbarNotificationComponent,
		GlobalSearchComponent,
	],
	imports: [
		RouterModule,
		NgbModule,
		CoreCommonModule,
		PerfectScrollbarModule,
		CoreTouchspinModule,
		NgApexchartsModule,
		BreadcrumbModule,
		NgSelectModule,
		TranslateModule,
		DnsModule,
		CoreSidebarModule,
		NgScrollbarModule,
		ReactiveFormsModule,
		FormsModule,
		NotificationModule,
	],
	providers: [
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
		},
		CookieService,
	],
	exports: [NavbarComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NavbarModule {}
