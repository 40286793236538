<div class="slideout-content default-stepping sm-stepping">
    <div class="modalsd modal-slide-in sdfade" id="modals-slide-in">
        <div class="modal-dialog sidebar-md">
            <!-- form-width -->
            <div class="modal-content pt-0 pb-0">
                <div class="modal-header d-flex justify-content-between align-items-center border">
                    <h5 class="modal-title mb-0 fw-600" id="exampleModalLabel">{{ title_text }}</h5>
                    <button type="button" data-dismiss="modal" id="btnClose" name="btnClose" aria-label="Close"
                        (click)="toggleSidebar(sidebarName)" class="btn btn-flat-secondary btn-icon"
                        rippleEffect>
                        <i class="fa-light fa-xmark"></i>
                    </button>
                </div>
                <form #closeTicketForm="ngForm" class="modal-body">
                    <div class="flex-auto-height">
                        <ng-scrollbar *ngIf="!is_loading" class="custom-scrollbar section-scrollbar">
                            <div class="modal-body-inner p-1">
                                <!-- <div class="card border border-rounded">
                                    <div class="summary-info p-1">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex justify-content-start align-items-center">
                                                <div>
                                                    <span class="fw-500 reg-font primary-color">{{
                                                        this.request_data?.display_id
                                                    }}</span>
                                                </div>
                                                <div class="rounded icon-class mx-50">
                                                    <div class="close-card-config"
                                                    ngbTooltip ="{{request_data_copy?.basic_info?.request_source?.name }}"
                                                    placement="bottom" id="btnGlobe">
                                                        <i
                                                            class="fa-light fa-{{
                                                                sourceIconMap[
                                                                    request_data?.basic_info?.request_source?.id
                                                                ]
                                                            }}"
                                                        ></i>
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="request_data?.is_parent_request"
                                                    class="rounded"
                                                    id="btnShare"
                                                >
                                                    <div class="cloass-card-config">
                                                        <i class="fa-light fa-share-nodes"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="mx-50"
                                                *ngIf="this.request_data?.basic_info?.priority?.name"
                                            >
                                                <div
                                                    class="badge badge-light-{{
                                                        request_data?.basic_info?.priority?.color
                                                    }}"
                                                >
                                                    {{
                                                        this.request_data?.basic_info?.priority?.display_key
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex justify-content-start align-items-center">
                                                <div *ngIf="request_data?.basic_info?.impact_service_tree_path">
                                                    <span class="sm-font"
                                                        >{{ "UI.k_service" | translate }} :
                                                    </span>
                                                    <span
                                                        class="sm-font"
                                                        title="{{ request_data?.basic_info?.impact_service_tree_path }}"
                                                    >
                                                        {{ request_data?.basic_info?.impact_service_tree_path | slice: 0:19
                                                        }}{{
                                                            request_data?.basic_info?.impact_service_tree_path?.length > 19
                                                                ? ".."
                                                                : ""
                                                        }}</span
                                                    >
                                                </div>

                                                <div *ngIf="request_data?.asset_id && request_data?.cmdb_id">
                                                    <span class="sm-font"
                                                        >{{ "UI.k_asset_id" | translate }} :
                                                    </span>
                                                    <span
                                                        *ngIf="asset_permissions?.view && request_data?.is_asset_disabled"
                                                    >
                                                        <a
                                                            href="/it-asset/asset/{{ request_data?.cmdb_id }}"
                                                            target="_blank"
                                                            class="sm-font"
                                                            title="{{ request_data?.asset_id }}"
                                                        >
                                                            {{ request_data?.asset_id | slice: 0:19
                                                            }}{{
                                                                request_data?.asset_id?.length > 19 ? ".." : ""
                                                            }}</a
                                                        >
                                                    </span>
                                                    <span
                                                        *ngIf="!asset_permissions?.view || !request_data?.is_asset_disabled"
                                                        class="sm-font"
                                                        title="{{ request_data?.asset_id }}"
                                                    >
                                                        {{ request_data?.asset_id | slice: 0:19
                                                        }}{{
                                                            request_data?.asset_id?.length > 19 ? ".." : ""
                                                        }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="my-50 d-flex justify-content-start align-items-center">
                                            <div class="avatar avatar-xs mr-50">
                                                <span
                                                    class="avatar-content" *ngIf="!request_data?.requester_profile"
                                                    [ngStyle]="{
                                                        'background-color':
                                                            request_data?.requester_avatar_color != undefined
                                                                ? request_data?.requester_avatar_color
                                                                : '#f59518'
                                                    }"
                                                    >{{
                                                        this.request_data?.requester?.full_name
                                                            | initials
                                                            | slice: 0:2
                                                    }}</span
                                                >
                                                <ng-container *ngIf="request_data?.requester_profile">
                                                    <img
                                                        class="avatar-content"
                                                        class=""
                                                        [src]="request_data?.requester_profile"
                                                        alt=""
                                                    />
                                                </ng-container>
                                            </div>

                                            <span
                                                class="sm-font fw-500 title-color summary-ellipse"
                                                ngbTooltip="{{ request_data?.basic_info?.summary  | slice: 0:200 }}"
                                                placement="bottom"
                                                >{{ request_data?.basic_info?.summary | slice: 0:150 }}
                                                {{
                                                    request_data?.basic_info?.summary.length > 150 ? ".." : ""
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="row p-0" >
                                    <div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
                                        <div class="form-group">
                                            <label for="txtReqCloserInfo"
                                                >{{ "UI.k_comment" | translate }}
                                                <span class="required-field">&nbsp;*</span>
                                            </label>
                                            <div>
                                                <quill-editor
                                                    [(ngModel)]="request_data.notes"
                                                    id="smartQuillReqClosure"
                                                    name="smartQuillReqClosure"
                                                    (onContentChanged)="getClosureNote($event)"
                                                    (onEditorCreated)="editorCreated($event)"
                                                    [trimOnValidation]="true"
                                                    #TDReqClosureNoteRef
                                                    [appQuillValidator]="TDReqClosureNoteRef"
                                                    [appQuillForm]="closeTicketForm"
                                                    [minLength]="3"
                                                    [required]="true"
                                                    [class.error]="
                                                        TDReqClosureNoteRef?.errors &&
                                                        closeTicketForm.submitted
                                                    "
                                                    placeholder="Write the comment here..."
                                                >
                                                    <div id="quill-toolbar" quill-editor-toolbar>
                                                        <div class="">
                                                            <span class="ql-formats">
                                                                <button class="ql-bold" title="{{ 'UI.k_bold' | translate }}"></button>
                                                                <button class="ql-italic" title="{{ 'UI.k_italic' | translate }}"></button>
                                                                <button
                                                                    class="ql-underline"
                                                                    title="{{ 'UI.k_underline' | translate }}"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <select class="ql-align" title="{{ 'UI.k_alignment' | translate }}">
                                                                    <option selected></option>
                                                                    <option value="center"></option>
                                                                    <option value="right"></option>
                                                                    <option value="justify"></option>
                                                                </select>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    class="ql-list"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    class="ql-link"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                                <button
                                                                    class="ql-image"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                                <button
                                                                    class="ql-video"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    class="ql-formula"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                                <button
                                                                    class="ql-code-block"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    class="ql-clean"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                            </span>
                                                            <!-- <span class="ql-formats">
                                                                <button
                                                                    id="insert-table"
                                                                    type="button"
                                                                    (click)="addNewtable($event)"
                                                                >
                                                                    Table
                                                                </button>
                                                            </span> -->
                                                        </div>
                                                    </div>
                                                </quill-editor>
                                            </div>
                                            
                                            <div
                                                class="error"
                                                *ngIf="
                                                    closeTicketForm.submitted &&
                                                    TDReqClosureNoteRef?.errors
                                                "
                                            >
                                                <small
                                                    *ngIf="TDReqClosureNoteRef?.errors?.requiredError?.empty"
                                                >
                                                    {{ "UI.k_field_required" | translate }}
                                                </small>
                                                <small *ngIf="TDReqClosureNoteRef?.errors?.minLengthError">
                                                    {{ "Error.err_closure_note_min" | translate }}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-scrollbar>
						<div class="mx-auto my-auto" *ngIf="is_loading">
							<span  class="spinner-border spinner-border-lg my-1 mx-2 text-success" ></span>
						</div>
                        <div *ngIf="!is_loading" class="modal-btn-group flex-initial border-top">
                            <div class="d-flex justify-content-end stepper-btn flex-initial">
                                <!-- <button
                                    type="submit"
                                    class="btn btn-primary btn-sm mr-1"
                                    (click)="onCloseSubmit(closeTicketForm, 'close')"
                                    rippleEffect
                                    id="btnAccept"
                                    name="btnAccept"
                                >
                                    {{ "UI.k_works_for_me" | translate }}
                                </button> -->
                                <button
                                    type="submit"
                                    class="btn btn-primary btn-sm "
                                    (click)="onCloseSubmit(closeTicketForm, action)"
                                    rippleEffect
                                    id="btnSubmit"
                                    name="btnSubmit"
                                    [disabled]="is_submit_disabled"
                                >
                                    <i
                                        class="fa-light fa-floppy-disk align-middle mr-50 ml-0"
                                    ></i>
                                    <span
                                        class="align-middle d-sm-inline-block d-none"
                                    >{{ "UI.k_submit" | translate }}</span
                                    >
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
