import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import {default as _map } from "lodash-es/map";
import groupBy from "lodash-es/groupBy";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-macros-modal",
	templateUrl: "./macros-modal.component.html",
	styleUrls: ["./macros-modal.component.scss"],
})
export class MacrosModalComponent implements OnInit {
	@Input() inputConfig: any;
	@Input() quillCleared: any;
	@Output() macrosEmitEvent: EventEmitter<any> = new EventEmitter();
	display_key = "name";
	macrosObject = {
		searchData: null,
		macrosConfig: [],
		selectedData: [],
		selectedItem: [],
		copyOfMacros: [],
		copymacrosConfig: [],
	};

	constructor(private _translateService: TranslateService) {}

	ngOnChanges(changes: SimpleChanges) {
		if(this.quillCleared && this.quillCleared === '{{\n') {
		  this.inputConfig.macrosConfig = this.inputConfig.macrosConfig.forEach(element => {
			element['isSelected'] = false
		  });
		}
	  }  
	ngOnInit(): void {
		if (this.inputConfig) {
			this.macrosObject.copyOfMacros = Object.assign([], this.inputConfig.placeholders);
			this.macrosObject.macrosConfig = groupBy(this.inputConfig.options, "group_label");
			this.macrosObject.copymacrosConfig = JSON.parse(
				JSON.stringify(groupBy(this.inputConfig.options, "group_label"))
			);
			this.display_key = this.inputConfig?.display_key || "name";
			this.convertMacroConfigDisplayKeyToI18N(this.macrosObject.copymacrosConfig);
		}
	}

	convertMacroConfigDisplayKeyToI18N = (data, displayKey = "label") => {
		for (let key in data) {
			let tempMacroArray = data[key];
			_map(tempMacroArray, (macro) => {
				macro[displayKey] = this._translateService.instant(macro[displayKey]);
				return macro;
			});
		}
	};

	macrosEvent(group, item) {
		if (group && item) {
			this.macrosObject.selectedData.push({
				item: item,
			});
			this.macrosObject.selectedItem = _map(this.macrosObject.selectedData, "item");
			if(!item['isSelected']) {
				item['isSelected'] = true
			  } else {
				if(this.macrosObject.selectedItem.includes(item)) {
				item['isSelected'] = true
				} else {
				item['isSelected'] = false
				}
			  }
			
			this.macrosEmitEvent.emit({
				item: item,
				data: this.macrosObject.selectedData,
				currentEdit: this.inputConfig.currentEdit,
			});
		}
	}

	searchEvent(event) {
		if (event) {
			for (let key in this.macrosObject?.copymacrosConfig) {
				const filterData = this.macrosObject.copymacrosConfig[key].filter(
					(id) =>
						id[this.display_key].toLowerCase().indexOf(event) > -1 ||
						id[this.display_key].toLowerCase().includes(event.toLowerCase())
				);

				if (filterData) {
					this.macrosObject.macrosConfig[key] = filterData;
				}
			}
		} else {
			this.macrosObject.macrosConfig = [];
			this.macrosObject.macrosConfig = JSON.parse(
				JSON.stringify(groupBy(this.inputConfig.options, "group_label"))
			);
		}
	}
}
