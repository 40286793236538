<div class="full-body-height">
    <div class="main-content flex-height">
        <div class="main-sec">
            <div class="action-bar">
                <div class="inner-section">
                    <div class="left-section">
                        <div class="action-search">
							<app-search
								(getfilterData)="getFilterSearch($event)"
								(genericFilter)="genericSearchFilter($event)"
								[showDataTable]="showDataTable"
								[columnOptions]="searchOptions"
								[conditionOptions]="conditionOptions"
								[showRightsection]="showRightsection"
								[savedData]="savedData"
								[Options]="dropdownOptions"
								[module]="module"
								(isExpandSearchWidth)="getSearchWidth($event)"
							></app-search>
						</div>
                    </div>
                    <div class="right-section">
                        <div class="pull-right align-items-center d-flex p-0 m-0">
                            <button
                                *ngIf="permissions && permissions?.add && orgData.length > 0"
                                rippleeffect=""
                                tooltipclass="fadeInAnimation"
                                data-keyboard="false"
                                id="add_organization"
                                class="btn bt-sm btn-primary waves-effect waves-float waves-light"
                                [attr.data-testid]="'add_organization'"
                                (click)="AddOrganization()"
                            >
                                <i class="fa-light fa-plus font-small-3"></i>&nbsp;
                                <span class="d-none d-sm-inline-block font-small-3">{{ "UI.k_add_organization" | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="action-section">
                <div *ngIf = "orgData.length > 0" class="table-view">
                    <dns-server-grid
														[rows]="orgData"
														[columns]="columnData"
														[count]="ciCount"
														[offset]="pageNumberGrid"
														[loadingIndicator]="true"
														[permissions]="permissions"
														class="bootstrap core-bootstrap d-grid flex-auto-height"
													>
						</dns-server-grid>
                </div>
                <ng-container *ngIf = "orgData === undefined || orgData.length === 0">
                    <app-dns-empty-grid-data [emptyData]="empty_data" [permissions]="permissions"></app-dns-empty-grid-data>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="add-organization-sidebar"
    overlayClass="modal-backdrop">
    <app-add-organization-sidebar *ngIf="showAddOrganization" [eventType] = "eventType"
        (onHideSidebar)="hideAddOrganizationSidebar($event)"></app-add-organization-sidebar>
</core-sidebar>
