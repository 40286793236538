<section class="user-datatable" [ngClass]="searchEvent ? 'full-search-width' : ''">
	<div class="main-search">
		<div class="inner-search">
			<div
				class="left-sec search-sec border-0"
				[ngStyle]="{
					'min-width': searchEvent ? '725px' : customW,
					'z-index': searchEvent ? '3' : '3'
				}"
			>
				<div class="search-input">
					<div
						class="form-group search-main-input"
						[ngClass]="{ 'search-input-small': customH === '28px' }"
						(mouseenter)="saveText = true"
						(mouseleave)="saveText = false"
					>
						<span class="input-group-text">
							<i class="fa-light fa-magnifying-glass"></i>
						</span>
						<input
							type="text"
							class="form-control cursor-pointer sm-font"
							autocomplete="off"
							id="txtSearch"
							placeholder="{{ 'UI.k_search' | translate }}"
							[(ngModel)]="searchObject.userDispalyString"
							#inputElement
							(ngModelChange)="searchData($event)"
							(click)="popupEvent()"
							(keyup)="searchUpdate($event)"
							[ngClass]="{
								'sm-form-control': customH === '28px'
							}"
							[attr.data-testid]="'txtSearch'"
						/>

						<div
							*ngIf="saveText && searchObject.userSearchData"
							id="btnInput"
							class="cursor-pointer btnInput-group bg-body"
						>
							<button
								type="button"
								class="btn btn-sm btn-icon btn-flat-primary clear-btn cursor-pointer"
								rippleEffect
								id="btnClear"
								name="btnClear"
								(click)="clearInput()"
							>
								<i class="fa-light fa-xmark"></i>
							</button>
							<button
								*ngIf="!quick_search"
								type="button"
								class="btn btn-sm btn-icon btn-flat-primary save-btn cursor-pointer"
								rippleEffect
								id="btnInputSave"
								name="btnInputSave"
								(click)="saveFilterOnInput(searchObject, 'save')"
							>
								<i class="fa-light fa-save"></i>
							</button>
						</div>

						<!-- (focus)="popupEvent()" -->
						<div
							id="btnfilter"
							class="filter-btn cursor-pointer"
							(click)="redirectToadvanceFilter()"
							[ngStyle]="{
								padding: customH === '28px' ? '0.2rem 0.7rem' : '0.5rem 1rem'
							}"
						>
							<i class="fa-light fa-filter"></i>
							<ng-container *ngIf="this.searchObject.searchCount > 0">
								<span>{{ this.searchObject.searchCount }}</span>
							</ng-container>
						</div>
					</div>
					<div
						class="search-modal"
						*ngIf="
							searchObject.advannceFilerPopup ||
							searchObject.userEnterPopup ||
							searchObject.multipleFilterPopup
						"
					>
						<div class="inner-modal">
							<div *ngIf="searchObject.advannceFilerPopup" class="recent-filter filter-main">
								<ng-scrollbar class="custom-scrollbar section-scrollbar">
									<div class="inner-modal-padding">
										<div
											*ngIf="
												searchObject.userSearchData == null &&
												searchObject?.recentSearchedData.length == 0 &&
												searchObject?.savedFilterData.length == 0
											"
										>
											<div class="filter-inner">
												<div class="filter-head">
													<div class="sm-font title-color">
														{{ "UI.k_type_filter" | translate }}
													</div>
													<button
														type="button"
														id="btnfilter"
														name="btnfilter"
														class="btn btn-flat-primary btn-sm"
														rippleEffect
														(click)="redirectToadvanceFilter()"
													>
														{{ "UI.k_filter" | translate }}
													</button>
												</div>
											</div>
										</div>
										<div
											*ngIf="
												searchObject.userSearchData != null ||
												searchObject?.recentSearchedData.length > 0 ||
												searchObject?.savedFilterData.length > 0
											"
										>
											<div class="filter-inner">
												<div class="filter-head">
													<span class="sm-font title-color">{{
														"UI.k_recent_filter" | translate
													}}</span>
													<button
														type="button"
														class="btn btn-flat-primary btn-sm"
														id="btnfilters"
														name="btnfilters"
														rippleEffect
														(click)="redirectToadvanceFilter()"
													>
														{{ "UI.k_filter" | translate }}
													</button>
												</div>
												<div
													class="filter-list border-0 p-0"
													*ngIf="searchObject?.recentSearchedData.length > 0"
												>
													<ng-container
														*ngFor="
															let storedItem of searchObject.recentSearchedData;
															let i = index
														"
													>
														<p
															class="cursor-pointer"
															id="recentSearch{{ i }}"
															name="recentSearch{{ i }}"
															*ngIf="i < 5 && storedItem !== ''"
														>
															<i class="fa-light fa-clock-rotate-left sm-font"></i>
															<!--{{ storedItem.key }} : {{ storedItem.keyFilterValue }}-->
															<span
																class="sm-font"
																(click)="recentSearchClickedEvent(storedItem)"
																#toggleButton
															>
																{{ storedItem.name }}</span
															>
														</p>
													</ng-container>
													<!-- <p>
													<i class="fas fa-history mr-50"></i>
													Some randon Keyword
													</p> -->
												</div>
												<div *ngIf="searchObject?.recentSearchedData.length == 0">
													<span class="font-small-3">{{
														"UI.k_no_recent_filter" | translate
													}}</span>
												</div>
											</div>
											<hr />
											<div class="filter-inner">
												<div class="filter-head">
													<span class="sm-font title-color">{{
														"UI.k_saved_filter" | translate
													}}</span>
												</div>
												<div
													class="save-filter-list pt-50 pb-0 suggestion-main"
													[ngClass]="{
														moreSuggestion: this.searchObject?.savedFilterData.length > 5
													}"
												>
													<div
														class=""
														*ngIf="this.searchObject?.savedFilterData.length !== 0"
														id="saveFilterContainer"
														name="saveFilterContainer"
													>
														<ng-container
															*ngFor="
																let data of searchObject.savedFilterData;
																let i = index
															"
														>
															<span
																*ngIf="!isString(data?.ui_display) && data?.ui_display.length > 0"
																id="savedFilter{{ i }}"
																name="savedFilter{{ i }}"
															>
																<div class="save-filter mr-1 mb-50 pb-0">
																	<span
																		class="mr-50 mb-50 cursor-pointer m-0"
																		*ngFor="let e of data.ui_display; let i = index"
																		(click)="applySavedFilter(data, searchObject)"
																	>
																		<span class="col-name sm-font"
																			>{{ e?.key }}:</span
																		>
																		<span class="col-value sm-font">
																			{{ e?.keyFilterValue }}
																		</span>
																	</span>
																</div>
															</span>
															<span
																*ngIf="isString(data?.ui_display) && data?.ui_display.length > 0"
																id="savedFilter{{ i }}"
																name="savedFilter{{ i }}"
															>
																<div class="save-filter mr-1 mb-50 pb-0">
																	<span
																		class="mr-50 mb-50 cursor-pointer m-0"
																		(click)="applyGenericSavedFilter(data, searchObject)"
																	>
																		{{ data?.ui_display }} 
																	</span>
																</div>
															</span>
														</ng-container>
														<!-- <span
														><span class="col-name">Mobile:</span
														><span class="col-value"> contain[321]</span></span
														> -->
													</div>
													<div *ngIf="searchObject?.savedFilterData.length == 0">
														<span class="font-small-3">{{
															"UI.k_no_save_filter" | translate
														}}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</ng-scrollbar>
							</div>
							<div *ngIf="searchObject.userEnterPopup" class="suggestion-filter filter-main">
								<div
									class="filter-inner"
									*ngIf="this.searchObject.filterData && this.searchObject.filterData.length"
								>
									<div
										class="suggestion-main"
										[perfectScrollbar]
										[ngClass]="{
											moreSuggestion: this.searchObject.isScroll === true
										}"
									>
										<ng-container *ngFor="let item of this.searchObject.filterData">
											<div class="suggestion-list" (click)="applyFilter(item)">
												<div class="badge badge-light-warning">
													{{ item.keyFilter }}
												</div>
												<span>{{ item[item.keyFilter] }}</span>
											</div>
										</ng-container>
									</div>
									<p
										class="search-result"
										(click)="loadAllData()"
										[hidden]="this.searchObject.isScroll"
									>
										{{ "UI.k_show" | translate }}
										{{ "UI.k_more" | translate }} (<span>{{
											this.searchObject.filterData?.length
										}}</span>
										{{ "UI.k_result" | translate }})
									</p>
								</div>
							</div>
							<div *ngIf="searchObject.multipleFilterPopup" class="advanced-filter filter-main">
								<ng-scrollbar class="custom-scrollbar section-scrollbar" [visibility]="'always'">
									<div class="inner-modal-padding">
										<div class="filter-inner">
											<div class="back-sec">
												<button
													type="button"
													id="btnback"
													name="btnback"
													class="btn btn-sm btn-flat-primary"
													rippleEffect
													(click)="backEvent()"
												>
													<i class="fa-light fa-arrow-left align-middle mr-50 ml-0"></i>
													{{ "UI.k_back" | translate }}
												</button>
											</div>
											<div class="filter-head">
												<span class="sm-font title-color"
													>{{ "UI.k_filter" | translate }}
													{{ "UI.k_condition" | translate }}</span
												>
											</div>
											<div class="filter-form-group">
												<form
													#filterForm="ngForm"
													class="filter-form"
													(keydown.enter)="$event.preventDefault()"
												>
													<div class="advance-filter-main flex-auto-height">
														<ng-container
															*ngFor="
																let filterItem of searchObject.addFilterArray;
																let i = index
															"
														>
															<div class="filter-condition">
																<!-- <ng-container
															*ngFor="let filterItem of searchObject.addFilterArray; let i=index"
															> -->
																<!-- <form #filterForm="ngForm"> -->
																<div class="single-condition align-items-baseline">
																	<div class="form-group field-input">
																		<ng-select
																			[items]="columnOptions"
																			bindLabel="column_name"
																			id="selField{{ filterItem.id }}"
																			name="selField{{ filterItem.id }}"
																			notFoundText="{{
																				'UI.k_no_items_found' | translate
																			}}"
																			placeholder="{{ 'UI.k_field' | translate }}"
																			[(ngModel)]="filterItem.selectedColumn"
																			(change)="columnSelectionEvent(filterItem)"
																			#TDSelectedColumnDataRef="ngModel"
																			required
																			[class.error]="
																				TDSelectedColumnDataRef.invalid &&
																				filterForm.submitted
																			"
																			class="form-control sm-form-control"
																		>
																		</ng-select>
																		<span
																			*ngIf="
																				filterForm.submitted &&
																				TDSelectedColumnDataRef.invalid
																			"
																			class="invalid-form"
																		>
																			<small
																				class="form-text text-danger"
																				*ngIf="
																					TDSelectedColumnDataRef.errors
																						.required
																				"
																				>{{
																					"UI.k_field_required" | translate
																				}}</small
																			>
																		</span>
																	</div>
																	<div class="form-group conditio-input">
																		<ng-select
																			[items]="
																				filterItem.selectedColumn
																					?.operatorOptions
																			"
																			bindLabel="name"
																			id="selCondition{{ filterItem.id }}"
																			name="selCondition{{ filterItem.id }}"
																			notFoundText="{{
																				'UI.k_no_items_found' | translate
																			}}"
																			placeholder="{{
																				'UI.k_condition' | translate
																			}}"
																			[(ngModel)]="filterItem.selectedCondition"
																			(change)="
																				conditionSelectionEvent(
																					filterItem,
																					filterItem.selectedCondition
																				)
																			"
																			#TDSelectedConditionDataRef="ngModel"
																			required
																			[class.error]="
																				TDSelectedConditionDataRef.invalid &&
																				filterForm.submitted
																			"
																			class="form-control sm-form-control"
																		>
																		</ng-select>
																		<span
																			*ngIf="
																				filterForm.submitted &&
																				TDSelectedConditionDataRef.invalid
																			"
																			class="invalid-form"
																		>
																			<small
																				class="form-text text-danger"
																				*ngIf="
																					TDSelectedConditionDataRef.errors
																						.required
																				"
																				>{{
																					"UI.k_field_required" | translate
																				}}</small
																			>
																		</span>
																	</div>
																	<div class="form-group value-input">
																		<div
																			*ngIf="
																				filterItem.selectedColumn &&
																				filterItem.selectedCondition &&
																				filterItem.selectedCondition.type ===
																					'input'
																			"
																		>
																			<input
																				type="text"
																				class="form-control sm-form-control"
																				id="txtValue{{ filterItem.id }}"
																				name="txtValue{{ filterItem.id }}"
																				placeholder="{{
																					'UI.k_value' | translate
																				}}"
																				[(ngModel)]="filterItem.dataConfig"
																				#TDDataConfigRef="ngModel"
																				required
																				[class.error]="
																					TDDataConfigRef.invalid &&
																					filterForm.submitted
																				"
																				[appWhiteSpaceCheck]="
																					filterItem.dataConfig
																				"
																			/>
																			<span
																				*ngIf="
																					filterItem.selectedColumn &&
																					filterItem.selectedCondition &&
																					filterItem.selectedCondition
																						.type === 'input' &&
																					filterForm.submitted &&
																					TDDataConfigRef.invalid &&
																					(filterForm.dirty ||
																						filterForm.touched)
																				"
																				class="invalid-form"
																			>
																				<small
																					class="form-text text-danger"
																					*ngIf="
																						TDDataConfigRef.errors?.required
																					"
																					>{{
																						"UI.k_field_required"
																							| translate
																					}}</small
																				>
																				<small
																					class="form-text text-danger"
																					*ngIf="
																						TDDataConfigRef.errors
																							?.whitespace
																					"
																					>{{
																						"Error.err_ltspace" | translate
																					}}</small
																				>
																			</span>
																		</div>
																		<div
																			*ngIf="
																				filterItem.selectedColumn &&
																				filterItem.selectedCondition &&
																				filterItem.selectedCondition.type ===
																					'onlyNumberInput'
																			"
																		>
																			<input
																				type="text"
																				class="form-control sm-form-control"
																				id="txtValue{{ filterItem.id }}"
																				name="txtValue{{ filterItem.id }}"
																				placeholder="{{
																					'UI.k_value' | translate
																				}}"
																				[(ngModel)]="filterItem.dataConfig"
																				#TDDataConfigRef="ngModel"
																				required
																				[class.error]="
																					TDDataConfigRef.invalid &&
																					filterForm.submitted
																				"
																				[appWhiteSpaceCheck]="
																					filterItem.dataConfig
																				"
																				pattern="^[0-9]*"
																			/>
																			<span
																				*ngIf="
																					filterItem.selectedColumn &&
																					filterItem.selectedCondition &&
																					filterItem.selectedCondition
																						.type === 'onlyNumberInput' &&
																					filterForm.submitted &&
																					TDDataConfigRef.invalid &&
																					(filterForm.dirty ||
																						filterForm.touched)
																				"
																				class="invalid-form"
																			>
																				<small
																					class="form-text text-danger"
																					*ngIf="
																						TDDataConfigRef.errors?.required
																					"
																					>{{
																						"UI.k_field_required"
																							| translate
																					}}</small
																				>
																				<small
																					class="form-text text-danger"
																					*ngIf="
																						TDDataConfigRef.errors
																							?.whitespace
																					"
																					>{{
																						"Error.err_ltspace" | translate
																					}}</small
																				>
																				<small
																					class="form-text text-danger"
																					*ngIf="
																						TDDataConfigRef.errors?.pattern
																					"
																					>{{
																						"UI.k_number_invalid"
																							| translate
																					}}</small
																				>
																			</span>
																		</div>

																		<div
																			*ngIf="
																				filterItem.selectedColumn &&
																				filterItem.selectedCondition &&
																				filterItem.selectedCondition.type ===
																					'numInput'
																			"
																		>
																			<input
																				type="text"
																				class="form-control sm-form-control"
																				id="txtNumValue{{ filterItem.id }}"
																				name="txtNumValue{{ filterItem.id }}"
																				placeholder="{{
																					'UI.k_value' | translate
																				}}"
																				[(ngModel)]="filterItem.dataConfig"
																				#TDDataConfigRef="ngModel"
																				required
																				[class.error]="
																					!TDDataConfigRef.valid &&
																					filterForm.submitted
																				"
																				pattern="^[0-9]+(,)[0-9]+$"
																				[appWhiteSpaceCheck]="
																					filterItem.dataConfig
																				"
																				(keyup)="
																					validationCheck(
																						filterItem.dataConfig,
																						filterItem.id
																					)
																				"
																			/>
																			<span
																				*ngIf="
																					filterForm.submitted &&
																					TDDataConfigRef.invalid
																				"
																				class="invalid-form"
																			>
																				<small
																					class="form-text text-danger"
																					*ngIf="
																						TDDataConfigRef.errors
																							.pattern && !rangeValidation
																					"
																					>{{
																						"Error.err_search_pattern_validation"
																							| translate
																					}}
																				</small>

																				<small
																					class="form-text text-danger"
																					*ngIf="
																						TDDataConfigRef.errors.required
																					"
																					>{{
																						"UI.k_field_required"
																							| translate
																					}}
																				</small>
																				<small
																					class="form-text text-danger"
																					*ngIf="
																						TDDataConfigRef.errors
																							?.whitespace
																					"
																					>{{
																						"Error.err_ltspace" | translate
																					}}</small
																				>
																			</span>
																			<span
																				*ngIf="
																					rangeValidation &&
																					validationFieldId == filterItem.id
																				"
																			>
																				<small class="form-text text-danger"
																					>{{ validationMsg | translate }}
																				</small>
																			</span>
																		</div>

																		<div
																			*ngIf="
																				filterItem.selectedColumn &&
																				filterItem.selectedCondition &&
																				filterItem.selectedCondition.type ===
																					'dropdown'
																			"
																		>
																			<ng-select
																				[items]="filterItem.optionList"
																				id="selData{{ filterItem.id }}"
																				name="selData{{ filterItem.id }}"
																				notFoundText="{{
																					'UI.k_no_items_found' | translate
																				}}"
																				bindLabel="{{
																					filterItem.selectedColumn
																						.option_name
																				}}"
																				bindValue="{{
																					filterItem.selectedColumn.option_id
																				}}"
																				[(ngModel)]="filterItem.dataConfig"
																				#TDDataConfigRef="ngModel"
																				required
																				[class.error]="
																					TDDataConfigRef.invalid &&
																					filterForm.submitted
																				"
																				class="form-control sm-form-control"
																			>
																				<ng-template
																					ng-option-tmp
																					let-item="item"
																				>
																					<span
																						>{{
																							getTranslatableKey(
																								item[
																									filterItem
																										.selectedColumn
																										.option_name
																								]
																							) | translate
																						}}
																					</span>
																				</ng-template>
																				<!-- <ng-template ng-option-tmp let-item="item">
																					<div title="{{item.full_name}}">{{item.name}}</div>
																				</ng-template> -->
																			</ng-select>
																			<span
																				*ngIf="
																					filterForm.submitted &&
																					TDDataConfigRef.invalid
																				"
																				class="invalid-form"
																			>
																				<small
																					class="form-text text-danger"
																					*ngIf="
																						TDDataConfigRef.errors.required
																					"
																					>{{
																						"UI.k_field_required"
																							| translate
																					}}</small
																				>
																			</span>
																		</div>

																		<div
																			*ngIf="
																				filterItem.selectedColumn &&
																				filterItem.selectedCondition &&
																				filterItem.selectedCondition.type ===
																					'combobox_multiDropdown'
																			"
																			class="selct-feature group-list-feature status-list-picker"
																		>
																			<ng-select
																				[items]="filterItem.optionList"
																				bindLabel="{{
																					filterItem.selectedColumn
																						.option_name
																				}}"
																				bindValue="{{
																					filterItem.selectedColumn.option_id
																				}}"
																				id="selData{{ filterItem.id }}"
																				name="selData{{ filterItem.id }}"
																				notFoundText="{{
																					'UI.k_no_items_found' | translate
																				}}"
																				[(ngModel)]="filterItem.dataConfig"
																				#TDDataConfigRef="ngModel"
																				[multiple]="true"
																				required
																				[class.error]="
																					TDDataConfigRef.invalid &&
																					filterForm.submitted
																				"
																				class="form-control sm-form-control"
																				hideSelected="true"
																			>
																				<ng-template
																					ng-label-tmp
																					let-item="item"
																					let-clear="clear"
																				>
																					<span
																						class="ng-value-icon right"
																						(click)="clear(item)"
																						aria-hidden="true"
																						>×</span
																					>
																					<ng-container>
																						<div class="group-lists">
																							<div
																								class="custom-squre"
																								[ngClass]="
																									'bg-' + item.color
																								"
																								[ngStyle]="{
																									'background-color':
																										item.color
																								}"
																							></div>
																							<span style="width: 100%">{{
																								item.name | translate
																							}}</span>
																							<div
																								class="right-arrow custom-text"
																								[ngStyle]="{
																									'background-color':
																										item.background_color,
																									color: item.text_color
																								}"
																							>
																								{{
																									item.state
																										| translate
																								}}
																							</div>
																							<div
																								class="right-arrow custom-icon"
																								*ngIf="
																									this.inputConfig &&
																									!inputConfig?.isTextMode
																								"
																								[ngStyle]="{
																									'background-color':
																										item.background_color,
																									color: item.text_color
																								}"
																							>
																								<i
																									[class]="item.icon"
																								></i>
																							</div>
																						</div>
																					</ng-container>
																				</ng-template>

																				<ng-template
																					ng-option-tmp
																					ng-label-tmp
																					let-item="item"
																				>
																					<ng-container>
																						<div class="group-lists">
																							<div
																								class="custom-squre"
																								[ngClass]="
																									'bg-' + item.color
																								"
																								[ngStyle]="{
																									'background-color':
																										item.color
																								}"
																							></div>
																							<span style="width: 100%">{{
																								item.name | translate
																							}}</span>
																							<div
																								class="right-arrow custom-text"
																								[ngStyle]="{
																									'background-color':
																										item.background_color,
																									color: item.text_color
																								}"
																							>
																								{{
																									item.state
																										| translate
																								}}
																							</div>
																							<div
																								class="right-arrow custom-icon"
																								*ngIf="
																									this.inputConfig &&
																									!inputConfig?.isTextMode
																								"
																								[ngStyle]="{
																									'background-color':
																										item.background_color,
																									color: item.text_color
																								}"
																							>
																								<i
																									[class]="item.icon"
																								></i>
																							</div>
																						</div>
																					</ng-container>
																				</ng-template>
																			</ng-select>
																		</div>
																		<div
																			*ngIf="
																				filterItem.selectedColumn &&
																				filterItem.selectedCondition &&
																				filterItem.selectedCondition.type ===
																					'combobox'
																			"
																			class="selct-feature group-list-feature status-list-picker"
																		>
																			<ng-select
																				[items]="filterItem.optionList"
																				bindLabel="{{
																					filterItem.selectedColumn
																						.option_name
																				}}"
																				bindValue="{{
																					filterItem.selectedColumn.option_id
																				}}"
																				id="selData{{ filterItem.id }}"
																				name="selData{{ filterItem.id }}"
																				notFoundText="{{
																					'UI.k_no_items_found' | translate
																				}}"
																				[(ngModel)]="filterItem.dataConfig"
																				#TDDataConfigRef="ngModel"
																				required
																				[class.error]="
																					TDDataConfigRef.invalid &&
																					filterForm.submitted
																				"
																				class="form-control sm-form-control"
																			>
																				<ng-template
																					ng-option-tmp
																					ng-label-tmp
																					let-item="item"
																				>
																					<ng-container>
																						<div class="group-lists">
																							<div
																								class="custom-squre"
																								[ngClass]="
																									'bg-' + item.color
																								"
																								[ngStyle]="{
																									'background-color':
																										item.color
																								}"
																							></div>
																							<span style="width: 100%">{{
																								item.name | translate
																							}}</span>
																							<div
																								class="right-arrow custom-text"
																								[ngStyle]="{
																									'background-color':
																										item.background_color,
																									color: item.text_color
																								}"
																							>
																								{{
																									item.state
																										| translate
																								}}
																							</div>
																							<div
																								class="right-arrow custom-icon"
																								*ngIf="
																									this.inputConfig &&
																									!inputConfig?.isTextMode
																								"
																								[ngStyle]="{
																									'background-color':
																										item.background_color,
																									color: item.text_color
																								}"
																							>
																								<i
																									[class]="item.icon"
																								></i>
																							</div>
																						</div>
																					</ng-container>
																				</ng-template>
																			</ng-select>
																		</div>

																		<div
																			*ngIf="
																				filterItem.selectedColumn &&
																				filterItem.selectedCondition &&
																				filterItem.selectedCondition.type ===
																					'toggle'
																			"
																			class="custom-control custom-control-primary custom-switch"
																		>
																			<input
																				type="checkbox"
																				class="custom-control-input"
																				name="toggle{{ filterItem.id }}"
																				id="toggle{{ filterItem.id }}"
																				[(ngModel)]="filterItem.dataConfig"
																			/>
																			<label
																				class="custom-control-label"
																				for="toggle{{ filterItem.id }}"
																			>
																			</label>
																		</div>
																		<div
																			*ngIf="
																				filterItem.selectedColumn &&
																				filterItem.selectedCondition &&
																				filterItem.selectedCondition.type ===
																					'badgebox'
																			"
																			class="selct-feature group-list-feature status-list-picker"
																		>
																			<ng-select
																				[items]="filterItem.optionList"
																				bindLabel="{{
																					filterItem.selectedColumn
																						.option_name
																				}}"
																				bindValue="{{
																					filterItem.selectedColumn.option_id
																				}}"
																				id="selData{{ filterItem.id }}"
																				name="selData{{ filterItem.id }}"
																				notFoundText="{{
																					'UI.k_no_items_found' | translate
																				}}"
																				[(ngModel)]="filterItem.dataConfig"
																				#TDDataConfigRef="ngModel"
																				required
																				[class.error]="
																					TDDataConfigRef.invalid &&
																					filterForm.submitted
																				"
																				class="form-control sm-form-control"
																			>
																				<ng-template
																					ng-label-tmp
																					let-item="item"
																				>
																					<div
																						class="badge badge-light-{{
																							item.priorityColor ||
																								item.color
																						}}"
																					>
																						<i
																							class="fa-light mr-25 {{
																								item.priorityIcon ||
																									item.icon
																							}}"
																						></i>
																						{{ item.name }}
																					</div>
																				</ng-template>
																				<ng-template
																					ng-option-tmp
																					let-item="item"
																				>
																					<div
																						class="badge badge-light-{{
																							item.priorityColor ||
																								item.color
																						}}"
																					>
																						<i
																							class="fa-light mr-25 {{
																								item.priorityIcon ||
																									item.icon
																							}}"
																						></i>
																						{{ item.name }}
																					</div>
																				</ng-template>
																			</ng-select>
																		</div>

																		<div
																			*ngIf="
																				filterItem.selectedColumn &&
																				filterItem.selectedCondition &&
																				filterItem.selectedCondition.type ===
																					'badgebox_multiDropdown'
																			"
																			class="selct-feature group-list-feature status-list-picker"
																		>
																			<ng-select
																				[items]="filterItem.optionList"
																				bindLabel="{{
																					filterItem.selectedColumn
																						.option_name
																				}}"
																				bindValue="{{
																					filterItem.selectedColumn.option_id
																				}}"
																				id="selData{{ filterItem.id }}"
																				name="selData{{ filterItem.id }}"
																				notFoundText="{{
																					'UI.k_no_items_found' | translate
																				}}"
																				[(ngModel)]="filterItem.dataConfig"
																				#TDDataConfigRef="ngModel"
																				[multiple]="true"
																				required
																				[class.error]="
																					TDDataConfigRef.invalid &&
																					filterForm.submitted
																				"
																				class="form-control sm-form-control"
																				hideSelected="true"
																			>
																				<ng-template
																					ng-label-tmp
																					let-item="item"
																				>
																					<div
																						class="badge badge-light-{{
																							item.priorityColor ||
																								item.color
																						}}"
																					>
																						<i
																							class="fa-light mr-25 {{
																								item.priorityIcon ||
																									item.icon
																							}}"
																						></i>
																						{{ item.name }}
																					</div>
																				</ng-template>
																				<ng-template
																					ng-option-tmp
																					let-item="item"
																				>
																					<div
																						class="badge badge-light-{{
																							item.priorityColor ||
																								item.color
																						}}"
																					>
																						<i
																							class="fa-light mr-25 {{
																								item.priorityIcon ||
																									item.icon
																							}}"
																						></i>
																						{{ item.name }}
																					</div>
																				</ng-template>
																			</ng-select>
																		</div>
																		<div
																			*ngIf="
																				filterItem.selectedColumn &&
																				filterItem.selectedCondition &&
																				filterItem.selectedCondition.type ===
																					'treebox'
																			"
																			class="selct-feature group-list-feature status-list-picker"
																		>
																			<app-single-tree-dropdown
																				class="w-100"
																				[items]="filterItem.optionList"
																				[value]="filterItem?.dataConfig?.id"
																				[id]="'txtTreeview'"
																				(itemChange)="
																					serviceValueChange(
																						$event,
																						filterItem
																					)
																				"
																			></app-single-tree-dropdown>
																		</div>

																		<div
																			*ngIf="
																				filterItem.selectedColumn &&
																				filterItem.selectedCondition &&
																				filterItem.selectedCondition.type ===
																					'treebox_multiDropdown'
																			"
																			class="selct-feature group-list-feature"
																		>
																			<app-single-tree-dropdown
																				class="w-100"
																				[items]="filterItem.optionList"
																				[value]="filterItem?.dataConfig?.id"
																				[id]="'txtTreeview'"
																				(itemChange)="
																					this.serviceValueChange($event)
																				"
																			></app-single-tree-dropdown>
																		</div>

																		<div
																			*ngIf="
																				filterItem.selectedColumn &&
																				filterItem.selectedCondition &&
																				filterItem.selectedCondition.type ===
																					'multiDropdown'
																			"
																		>
																			<ng-select
																				[items]="filterItem.optionList"
																				id="selData{{ filterItem.id }}"
																				name="selData{{ filterItem.id }}"
																				notFoundText="{{
																					'UI.k_no_items_found' | translate
																				}}"
																				bindLabel="{{
																					filterItem.selectedColumn
																						.option_name
																				}}"
																				bindValue="{{
																					filterItem.selectedColumn.option_id
																				}}"
																				[(ngModel)]="filterItem.dataConfig"
																				#TDDataConfigRef="ngModel"
																				[closeOnSelect]="true"
																				[multiple]="true"
																				[hideSelected]="true"
																				required
																				[class.error]="
																					TDDataConfigRef.invalid &&
																					filterForm.submitted
																				"
																				class="form-control sm-form-control"
																				hideSelected="true"
																			>
																				<ng-template
																					ng-option-tmp
																					let-item="item"
																				>
																					<span
																						>{{
																							getTranslatableKey(
																								item[
																									filterItem
																										.selectedColumn
																										.option_name
																								]
																							) | translate
																						}}
																					</span>
																				</ng-template>
																				<!-- <ng-template ng-option-tmp let-item="item">
																					<div title="{{item.full_name}}">{{item.name}}</div>
																				</ng-template> -->
																			</ng-select>
																			<span
																				*ngIf="
																					filterForm.submitted &&
																					TDDataConfigRef.invalid
																				"
																				class="invalid-form"
																			>
																				<small
																					class="form-text text-danger"
																					*ngIf="
																						TDDataConfigRef.errors.required
																					"
																					>{{
																						"UI.k_field_required"
																							| translate
																					}}</small
																				>
																			</span>
																		</div>
																		<div
																			*ngIf="
																				filterItem.selectedColumn &&
																				filterItem.selectedCondition &&
																				filterItem.selectedCondition.type ===
																					'multiDropdownAdd'
																			"
																		>
																			<ng-select
																				[items]="filterItem.optionList"
																				id="selData{{ filterItem.id }}"
																				name="selData{{ filterItem.id }}"
																				addTagText="{{
																					'UI.k_add_item' | translate
																				}}"
																				notFoundText="{{
																					'UI.k_no_items_found' | translate
																				}}"
																				bindLabel="{{
																					filterItem.selectedColumn
																						.option_name
																				}}"
																				bindValue="{{
																					filterItem.selectedColumn.option_id
																				}}"
																				[(ngModel)]="filterItem.dataConfig"
																				#TDDataConfigRef="ngModel"
																				[closeOnSelect]="true"
																				[multiple]="true"
																				[hideSelected]="false"
																				[addTag]="selectAddOption"
																				required
																				[class.error]="
																					TDDataConfigRef.invalid &&
																					filterForm.submitted
																				"
																				class="form-control sm-form-control"
																				hideSelected="true"
																			>
																			</ng-select>
																			<span
																				*ngIf="
																					filterForm.submitted &&
																					TDDataConfigRef.invalid
																				"
																				class="invalid-form"
																			>
																				<small
																					class="form-text text-danger"
																					*ngIf="
																						TDDataConfigRef.errors.required
																					"
																					>{{
																						"UI.k_field_required"
																							| translate
																					}}</small
																				>
																			</span>
																		</div>
																		<span
																			*ngIf="
																				filterItem.selectedColumn &&
																				filterItem.selectedCondition &&
																				filterItem.selectedCondition.type ===
																					'calendar'
																			"
																		>
																			<ng2-flatpickr
																				class="flat-picker"
																				placeholder="{{ 'UI.k_sel_date' | translate }}"
																				[config]="dateTimeOptions"
																				id="dateTime{{ filterItem.id }}"
																				name="dateTime{{ filterItem.id }}"
																				[setDate]="filterItem.dataConfig"
																				[(ngModel)]="filterItem.dataConfig"
																				#TDCalendarRef="ngModel"
																				required
																			></ng2-flatpickr>
																			<span
																				*ngIf="
																					filterForm.submitted &&
																					TDCalendarRef.invalid
																				"
																				class="invalid-form"
																			>
																				<small
																					class="form-text text-danger"
																					*ngIf="
																						TDCalendarRef.errors.required
																					"
																					>{{
																						"UI.k_field_required"
																							| translate
																					}}</small
																				>
																			</span>
																		</span>
																		<div
																			*ngIf="
																				filterItem.selectedColumn &&
																				filterItem.selectedCondition &&
																				filterItem.selectedCondition.type ===
																					'inputDropdown'
																			"
																		>
																			<ng-select
																				[items]="filterItem.optionList"
																				[addTag]="selectAddTagMethod"
																				addTagText="{{
																					'UI.k_add_item' | translate
																				}}"
																				style="width: 300px"
																				[hideSelected]="true"
																				name="selInputData{{ filterItem.id }}"
																				id="selInputData{{ filterItem.id }}"
																				notFoundText="{{
																					'UI.k_no_items_found' | translate
																				}}"
																				multiple="true"
																				bindLabel="name"
																				[(ngModel)]="filterItem.dataConfig"
																				#TDDataConfigRef="ngModel"
																				required
																				[class.error]="
																					TDDataConfigRef.invalid &&
																					filterForm.submitted
																				"
																				class="form-control sm-form-control"
																			>
																			</ng-select>
																			<span
																				*ngIf="
																					filterForm.submitted &&
																					TDDataConfigRef.invalid
																				"
																				class="invalid-form"
																			>
																				<small
																					class="form-text text-danger"
																					*ngIf="
																						TDDataConfigRef.errors.required
																					"
																					>{{
																						"UI.k_field_required"
																							| translate
																					}}</small
																				>
																			</span>
																		</div>
																		<div
																			*ngIf="
																				filterItem.selectedColumn &&
																				filterItem.selectedCondition &&
																				filterItem.selectedCondition.type ===
																					'numInputDropdown'
																			"
																		>
																			<ng-select
																				[items]="filterItem.optionList"
																				[addTag]="selectAddTagMethod"
																				style="width: 300px"
																				addTagText="{{
																					'UI.k_add_item' | translate
																				}}"
																				[hideSelected]="true"
																				name="selInputData{{ filterItem.id }}"
																				id="selInputData{{ filterItem.id }}"
																				notFoundText="{{
																					'UI.k_no_items_found' | translate
																				}}"
																				multiple="true"
																				bindLabel="name"
																				[(ngModel)]="filterItem.dataConfig"
																				[appNumberCheck]="filterItem.dataConfig"
																				#TDDataConfigRef="ngModel"
																				required
																				[class.error]="
																					!TDDataConfigRef.valid &&
																					filterForm.submitted
																				"
																				class="form-control sm-form-control"
																			>
																			</ng-select>
																			<span
																				*ngIf="
																					filterForm.submitted &&
																					TDDataConfigRef.invalid
																				"
																				class="invalid-form"
																			>
																				<small
																					class="form-text text-danger"
																					*ngIf="
																						TDDataConfigRef.errors.required
																					"
																					>{{
																						"UI.k_field_required"
																							| translate
																					}}</small
																				>
																				<small
																					class="form-text text-danger"
																					*ngIf="
																						TDDataConfigRef.errors
																							?.invalidnumber
																					"
																					>{{
																						"UI.k_number_invalid"
																							| translate
																					}}</small
																				>
																			</span>
																		</div>
																	</div>
																	<div class="btn-icon-group">
																		<button
																			[hidden]="i === 0"
																			class="btn-icon btn btn-sm btnDelete btn-flat-danger"
																			id="btnDelete{{ i }}"
																			name="btnDelete{{ i }}"
																			(click)="deleteFilter(filterItem)"
																		>
																			<i class="fa-light fa-trash-can"></i>
																		</button>
																	</div>
																</div>
															</div>
															<div
																class="footer-btn-group flex-initial mr-1"
																*ngIf="searchObject.addFilterArray.length - 1 === i"
															>
																<div class="left-sec border-0">
																	<button
																		type="button"
																		id="btnAddFilter"
																		name="btnAddFilter"
																		class="btn btn-sm btn-flat-primary pl-25 pr-25"
																		rippleEffect
																		(click)="addFilter(filterForm)"
																	>
																		<i class="fa-light fa-plus mr-50"></i>
																		{{ "UI.k_add_filter" | translate }}
																	</button>
																</div>
																<div class="right-sec mr-1">
																	<button
																		type="button"
																		id="btnSave"
																		name="btnSave"
																		class="btn btn-sm btn-outline-primary"
																		rippleEffect
																		(click)="
																			applyMultipleFilter(
																				filterForm,
																				filterItem,
																				'save'
																			)
																		"
																	>
																		{{ "UI.k_save" | translate }}
																	</button>
																	<button
																		type="button"
																		id="btnapplyFilter"
																		name="btnapplyFilter"
																		class="btn btn-sm btn-primary"
																		rippleEffect
																		(click)="
																			applyMultipleFilter(
																				filterForm,
																				filterItem,
																				'apply'
																			)
																		"
																	>
																		{{ "UI.k_apply_filter" | translate }}
																	</button>
																</div>
															</div>
														</ng-container>
													</div>
												</form>
											</div>
										</div>
									</div>
								</ng-scrollbar>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="right-sec" *ngIf="showRightsection">
				<button type="button" class="btn btn-icon btn-flat-primary mr-1" rippleEffect>
					<i class="fa-light fa-download"></i>
				</button>
				<button type="button" class="btn btn-primary" rippleEffect>
					<i class="fa-light fa-plus mr-50"></i>New User
				</button>
			</div>
		</div>
	</div>
	<div *ngIf="showDataTable">
		<ngx-datatable
			[rows]="kitchenSinkRows"
			[rowHeight]="58"
			class="bootstrap core-bootstrap"
			[limit]="10"
			[columnMode]="ColumnMode.force"
			[headerHeight]="50"
			[footerHeight]="60"
			[scrollbarH]="true"
			[limit]="basicSelectedOption"
		>
			<ngx-datatable-column name="Name" prop="full_name" [width]="280">
				<ng-template let-row="row" let-name="value" ngx-datatable-cell-template>
					<div class="d-flex align-items-center">
						<div *ngIf="row.avatar.length > 0; else customAvatar">
							<img
								class="rounded-circle mr-1"
								src="assets/images/portrait/small/{{ row.avatar }}"
								height="32"
								width="32"
								alt="datatable-avatar"
							/>
						</div>
						<ng-template #customAvatar>
							<div
								class="avatar mr-1 ml-0"
								[ngClass]="{
									'bg-light-primary': row.status == '1',
									'bg-light-success': row.status == '2',
									'bg-light-danger': row.status == '3',
									'bg-light-warning': row.status == '4',
									'bg-light-info': row.status == '5'
								}"
							>
								<div class="avatar-content">
									{{ name | initials }}
								</div>
							</div>
						</ng-template>
						<div class="cell-line-height">
							<p class="font-medium-1 font-weight-bold line-height-1 mb-25 name-info">
								{{ name }}
							</p>
							<span class="text-muted sm-font"> {{ row.name }}</span>
						</div>
					</div>
				</ng-template>
			</ngx-datatable-column>
			<ngx-datatable-column name="Email" prop="email"></ngx-datatable-column>
			<ngx-datatable-column name="Roll" prop="roll"></ngx-datatable-column>
			<ngx-datatable-column name="Plan" prop="plan"></ngx-datatable-column>
			<ngx-datatable-column name="Status" prop="status">
				<ng-template let-status="value" ngx-datatable-cell-template>
					<div
						class="badge badge-pill"
						[ngClass]="{
							'badge-light-success': status == '1',
							'badge-light-warning': status == '2',
							'badge-light-secondary': status == '3'
						}"
					>
						{{ status == 1 ? "Active" : status == 2 ? "Pending" : status == 3 ? "Inactive" : "Inactive" }}
					</div>
				</ng-template>
			</ngx-datatable-column>
			<ngx-datatable-column name="Actions" [width]="120" [sortable]="false">
				<ng-template ngx-datatable-cell-template>
					<div class="d-flex align-items-center">
						<div ngbDropdown container="body">
							<a
								ngbDropdownToggle
								href="javascript:void(0);"
								class="hide-arrow"
								id="dropdownBrowserState"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<i class="fa-light fa-ellipsis-vertical text-primary cursor-pointer mr-50"></i>
							</a>
							<div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
								<a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"
									><i class="fa-light fa-file-lines mr-50"></i> Details</a
								>
								<a href="javascript:void(0)" ngbDropdownItem class="d-flex align-items-center"
									><i class="fa-light fa-trash-can mr-50"></i> Delete</a
								>
							</div>
						</div>
					</div>
				</ng-template>
			</ngx-datatable-column>
		</ngx-datatable>
		<div class="show-items">
			<label class="d-flex align-items-center item-select">
				Show
				<select class="form-control mx-25" [(ngModel)]="basicSelectedOption">
					<option id="opt10" name="opt10" value="10">10</option>
					<option id="opt25" name="opt25" value="25">25</option>
					<option id="opt50" name="opt50" value="50">50</option>
					<option id="opt100" name="opt100" value="100">100</option>
					<option id="opt200" name="opt200" value="200">200</option>
					<!-- <option id="optShowAll" name="optShowAll" [value]="'all'">
						{{ "UI.k_all" | translate }}
					</option> -->
				</select>
			</label>
		</div>
	</div>
</section>
