<ng-scrollbar class="custom-scrollbar section-scrollbar" track="all">
	<table class="table data-table-main-block-sec">
		<thead>
			<tr>
				<ng-container *ngFor="let headerItem of tableHeader">
					<th 
					 	class="border-top font-medium-1 fw-800 text-center access-point-container"
						scope="col"
						[class.t-width]="
							headerItem.key == 'cancel' ||
							headerItem.key == 'notyet' ||
							headerItem.key == 'achieved' ||
							headerItem.key == 'las1' ||
							headerItem.key == 'las3' ||
							headerItem.key == 'las7' ||
							headerItem.key == 'las15' 
						"
					>
						{{ headerItem.name }}
					</th>
				</ng-container>
			</tr>
		</thead>
		<tbody>
			<ng-container *ngFor="let item of tableData">
				<tr>
					<td scope="row">
						<div style="text-align: left; padding: 0px 8px">
							{{ item.name }}
						</div>
					</td>
						<td>
							<span
								class="access-total text-black"
								tooltipClass="fadeInAnimation"
								placement="top"
								
							>
								<a (click)="showInciSlaSidebar(widgetdata, 'total', item)">{{ item.total }}</a>
							</span>
						</td>
					
					<td style="background: rgba(255, 169, 86, 1);">
						<span
							class="access-data text-white"
							*ngIf="item.cancel"							
							tooltipClass="fadeInAnimation"
							placement="top"
							(click)="showInciSlaSidebar(widgetdata, 'cancel', item)"
							
						>
							<a>{{ item.cancel }}</a>
						</span>
					</td>
					<td style="background: rgba(26, 150, 165, 1);">
						<span
							class="access-data text-white"
							*ngIf="item.notyet"							
							tooltipClass="fadeInAnimation"
							placement="top"
							(click)="showInciSlaSidebar(widgetdata, 'notyet', item)"
							
						>
							<a>{{ item.notyet }}</a>
						</span>
					</td>
					<td style="background: rgba(62, 205, 126, 1);">
						<span
							class="access-data text-white"
							*ngIf="item.achieved"							
							tooltipClass="fadeInAnimation"
							placement="top"
							(click)="showInciSlaSidebar(widgetdata, 'achieved', item)"
							
						>
							<a>{{ item.achieved }}</a>
						</span>
					</td>
					<td style="background: rgba(244, 169, 169, 1);">
						<span
							class="access-data text-white"
							*ngIf="item.las1"							
							tooltipClass="fadeInAnimation"
							placement="top"
							(click)="showInciSlaSidebar(widgetdata, 'las1', item)"
							
						>
							<a>{{ item.las1 }}</a>
						</span>
					</td>
					<td style="background: rgba(238, 118, 119, 1);">
						<span
							class="access-data text-white"
							*ngIf="item.las3"
							tooltipClass="fadeInAnimation"
							placement="top"
						>
							<a (click)="showInciSlaSidebar(widgetdata, 'las3', item)">{{ item.las3 }}</a>
						</span>
					</td>
					<td style="background: rgba(237, 102, 102, 1);">
						<span
							class="access-data text-white"
							*ngIf="item.las7"
							tooltipClass="fadeInAnimation"
							placement="top"
						>
							<a (click)="showInciSlaSidebar(widgetdata, 'las7', item)">{{ item.las7 }}</a>
						</span>
					</td>
					<td style="background: rgba(234, 84, 85, 1);">
						<span
							class="access-data text-white"
							*ngIf="item.las15"
							tooltipClass="fadeInAnimation"
							placement="top"
						>
							<a (click)="showInciSlaSidebar(widgetdata, 'las15', item)">{{ item.las15 }}</a>
						</span>
					</td>
				</tr>
			</ng-container>
		</tbody>
	</table>
</ng-scrollbar>
