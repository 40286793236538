import { Injectable } from '@angular/core';
import { ApiService } from "app/common/services/api/api.service";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class MessengerService extends ApiService {
	public _currentMessengerTab = new Subject<any>();
	messengetTabKey$ = this._currentMessengerTab.asObservable();


	constructor(httpClient: HttpClient) {
		super(httpClient)
	}

	getEmails(payload) {
		return this.get("ux/common/messenger/email/all-emails/", payload);
	}

	getEmailList(payload) {
		return this.get("ux/common/messenger/email/", payload);
	}

	saveEmail(payload) {
		return this.post("ux/common/messenger/email/save-email/", payload);
	}

	getOptionsConfig(payload: any) {
		return this.get("ux/common/messenger/email/options-config/", payload);
	}

	setMessengerTab(data){
		this._currentMessengerTab.next(data);

	}

}
