export  const emojiData :  any = {
    'smiley':{
        id: 'smiley',
        name: 'Smiling Face with Open Mouth',
        colons: ':smiley:',
        text: ':)',
        emoticons: [
          '=)',
          '=-)'
        ],
        skin: null,
        native: '😃'
      },
    'neutral_face': {
        id: 'neutral_face',
        name: 'Neutral Face',
        colons: ':neutral_face:',
        text: ':|',
        emoticons: [
        
        ],
        skin: null,
        native: '😐'
    },
    'angry': 
    {
        id: 'angry',
        name: 'Angry Face',
        colons: ':angry:',
        text: '>:(',
        emoticons: [
        ],
        skin: null,
        native: '😠'
    },
    'sad': {
        id: 'cry',
        name: 'Crying Face',
        colons: ':cry:',
        text: ":'(",
        emoticons: [
        ],
        skin: null,
        native: '😢'
    },
    'disappointed': {
        id: 'disappointed',
        name: 'Disappointed Face',
        colons: ':disappointed:',
        text: ":(",
        emoticons: [
        ],
        skin: null,
        native: '😞'
    }
}