import { Component, OnInit, Input , Output, EventEmitter } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-response-email-template',
  templateUrl: './add-response-email-template.component.html',
  styleUrls: ['./add-response-email-template.component.scss']
})
export class AddResponseEmailTemplateComponent implements OnInit {
  @Input() event;
  @Input() currentItem;
  @Output("onHideSidebar") onHideSidebar = new EventEmitter<any>();
  quickResponseTempText: any;
  quickResponseTempName:any;
  team_id;
  changeType:any = [];
  priorities:any;
  item: any = [];
  selectedItem:any = [];
  statusList = [];
  priorityField:any = [];
  riskField:any = [];
  public addIncident: any  = {}
  public filesList: any = [];
  public dropdown = {
    tagList: [],
    all_services: [],
    teams_and_self_assign: [],
    teams: [],
    classifications: [],
  }
  
  constructor(
    private _translateService: TranslateService,
    private _coreSidebarService: CoreSidebarService,
    private modalService: NgbModal,
  ) { 
    this.changeType = [
      {id:'1', name:'Standard'},
          {id:'2', name:'Minor'},
      {id:'3', name:'Major'},
          {id:'4', name:'Emergency'},
    ];
    this.priorities = [
      {id:'1',display_label:'Critical', class:'danger' },
      {id:'2',display_label:'Major', class:'warning' },
      {id:'3',display_label:'Minor', class:'info' },
      {id:'4',display_label:'Low', class:'success' }
    ];
    this.item = [
      {id:'1', name:'Laptop'},
      {id:'2', name:'Desktop'},
      {id:'3', name:'tablet'},
    ];
  }
  
  ngOnInit(): void {
    //console.log(this.currentItem)
    //console.log(this.event)
  }
  toggleSidebar = (name) => {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
    this.onHideSidebar.emit("hide");
  }
  onStatusChange = (data) => {
		
	};
  openModalAD(AssignmentComp) {
		this.modalService.open(AssignmentComp, {
			centered: true,
			animation: false,
			size: "md",
		});
	}
  groupChange(options){

	}
  removeAttachedFile = function (index, filesList) {
		filesList.splice(index, 1);
	};
  fileUpload = function (evt) {
		var files = evt.target.files;
		var new_file_len = evt.target.files.length;
		var added_file_len = this.filesList.length;
		for (var i = 0; i < new_file_len; i++) {
			var file = files[i];
			var pos = added_file_len + i;
			var reader = new FileReader();
			reader.onload = (function (filesList, file, pos) {
				return function (e) {
					var data = e.target.result;
					filesList[pos] = {
						file_name: file.name,
						file_type: file.type,
						file_size: file.size,
						file_data: data,
						file_date: new Date(),
						//'file_date': this.datepipe.transform(new Date(),'MMM dd,yyyy hh:mm a')
					};
				};
			})(this.filesList, file, pos);
			reader.readAsDataURL(file);
		}
	};
}
