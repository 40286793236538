import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";

@Component({
	selector: "app-profile-avatar",
	templateUrl: "./profile-avatar.component.html",
	styleUrls: ["./profile-avatar.component.scss"],
})
export class ProfileAvatarComponent implements OnInit {
	@Output() avatarDetails: EventEmitter<any> = new EventEmitter<any>();
	@Output() resetImage: EventEmitter<any> = new EventEmitter<any>();
	@Output() avatarBgColor: EventEmitter<any> = new EventEmitter<any>();
	@Output() currentAvatar: EventEmitter<any> = new EventEmitter<any>();

	@Input() profileName: string;
	@Input() profile_image: any;
	@Input() avatarBackgroundColor: string;
	@Input() activeColor: string;
	@Input() editable: boolean = true;

	public imgDetailsObject = {
		aspectRatio: "4 / 4",
		resizeToWidth: "128",
		cropperMinWidth: "128",
		maintainAspectRatio: true,
	};
	public MAX_IMAGE_SIZE = 20971520;
	public ALLOWED_TYPES = ["image/png", "image/jpeg", "image/jpg"];
	public previousAvatar;
	public displayTxt: boolean;
	public txtMargin = true;
	public showQueue = false;
	public multipleFiles = false;
	public displayResoluion = true;
	public squareImage: boolean;
	hideLeaves: boolean = false;
	public imageDetail: boolean;
	public setImage: boolean;
	public allFileList: any;
	public active;
	public change: boolean;
	public stored_image;
	public imageType = "Profile";
	is_accept = false;

	constructor(private modalService: NgbModal, private _translateService: TranslateService) {}

	ngOnInit(): void {
		//this.avatarBackgroundColor = '#978ef2';
		if (!this.avatarBackgroundColor) {
			this.avatarBackgroundColor = "#f59518";
		}
		//this.active= '#978ef2';
		this.active = "#f59518";
		this.previousAvatar = this.avatarBackgroundColor;
		this.stored_image = this.profile_image;
		setTimeout(() => {
			this.previousAvatar = this.avatarBackgroundColor;
			this.stored_image = this.profile_image;
		}, 2000);
	}

	ngOnChanges(changes: SimpleChanges) {
		this.active = this.activeColor;
	}

	// modal Open Small
	modalOpenSM(modalSM: NgbModalRef) {
		const that = this;
		this.is_accept = false;
		//let cssClass = "animation-disable modal-dialog-centered";
		this.modalService
			.open(modalSM, {
				centered: true,
				size: "xs",
				//windowClass: cssClass,
			})
			.dismissed.subscribe((data) => {
				//on dismissed, it will get called
				if (!this.is_accept) {
					this.avatarBackgroundColor = this.previousAvatar;
					this.active = this.previousAvatar;
					if (data == 0) {
						if (this.stored_image) {
							this.profile_image = this.stored_image;
							this.currentAvatar.emit("image");
						} else {
							this.currentAvatar.emit(this.active);
						}
					}
				}
			});
		// (form.form.touched = true);
		this.setImage = true;
	}

	onSelectAvatarColor(value): void {
		this.avatarBackgroundColor = value;
		this.active = value;
	}

	removeImage() {
		this.profile_image = null;
		this.change = true;
		this.imageDetail = false;
		this.resetImage.emit(this.profile_image);
	}

	imageDetails = ($event) => {
		this.profile_image = $event;
		this.imageDetail = true;
		this.change = true;
		this.avatarDetails.emit(this.profile_image);
	};

	customProfile() {
		this.profile_image = this.stored_image;
		this.currentAvatar.emit("image");
	}

	previousColor() {
		this.avatarBackgroundColor = this.previousAvatar;
		this.active = this.previousAvatar;
		if (this.stored_image) {
			this.profile_image = this.stored_image;
			this.currentAvatar.emit("image");
		} else {
			this.currentAvatar.emit(this.active);
		}
		this.modalService.dismissAll();
	}

	saveData(data) {
		if (data.form.valid === true) {
			this.is_accept = true;
			this.previousAvatar = this.avatarBackgroundColor;
			this.avatarBgColor.emit(this.avatarBackgroundColor);
			this.modalService.dismissAll();
		}
	}

	fileUpload = (event: any) => {
		// console.log("file upload emittef");
	};
}
