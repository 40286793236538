import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IncidentViewService } from "app/servicedesk/incident-view/services/incident-view.service";
import * as _ from "lodash";
import { FlatpickrOptions } from "ng2-flatpickr";
import { ToastrService } from "ngx-toastr";
import { TreeDataService } from "app/common/dns/components/single-tree-dropdown/tree-data.service";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { CommentTypes } from "app/servicedesk/incident-view/types/config";
import { ProblemService } from "app/servicedesk/problem/services/problem.service";
import { RequestViewService } from "app/servicedesk/request/services/request-view.service";
import { ChangeViewService } from "app/servicedesk/change-management/services/change-view.service";
import { ReleaseService } from "app/servicedesk/release-manage/services/release-services.service";
import { DomSanitizer } from "@angular/platform-browser";
import { NgScrollbar } from "ngx-scrollbar";
import { environment } from "environments/environment";
import { FileUploader } from "ng2-file-upload";
import { WorkSpaceService } from "app/base/work-space/services/work-space.service";
import { catchError, map, takeUntil } from "rxjs/operators";
import { throwError, Subject } from "rxjs";
import Quill, { Delta } from "quill";
import store from "store";
import { WSSService } from "app/common/services/wss/wss.service";
import { NgbPopover } from "@ng-bootstrap/ng-bootstrap";
import { IMACDService } from "app/base/imacd/service/imacd.service";
const URL = `${environment.instance_url}` + "media";

@Component({
	selector: "app-dns-communication",
	templateUrl: "./dns-communication.component.html",
	styleUrls: ["./dns-communication.component.scss"],
})
export class DnsCommunicationComponent implements OnInit, OnChanges {
	@ViewChild("scrolltoemailwindow", { static: false }) scrolltoemailwindow: ElementRef;
	@ViewChild("scrolltocommentwindow", { static: false }) scrolltocommentwindow: ElementRef;
	// @ViewChild("scrollable", {static: false}) scrollable: any;
	@ViewChild("scrollable") scrollable: NgScrollbar;
	@Output() addRichTextData: EventEmitter<any> = new EventEmitter();
	@Output() editRefData: EventEmitter<any> = new EventEmitter();
	@Output() setClosureNoteData: EventEmitter<any> = new EventEmitter();
	@Output() setApprovalData: EventEmitter<any> = new EventEmitter();
	@Output() setReviewData: EventEmitter<any> = new EventEmitter();
	@Output() handleEditorData: EventEmitter<any> = new EventEmitter();
	@Output() onStatusChange: EventEmitter<any> = new EventEmitter();
	@Output() getRefMailConversations: EventEmitter<any> = new EventEmitter();
	@Output() chooseExistingTicket: EventEmitter<any> = new EventEmitter();
	@Output() messageObjectData: EventEmitter<any> = new EventEmitter();
	@ViewChild("scrollMe") scrollMe: ElementRef;
	@ViewChild("popContent") popContent: ElementRef;
	@ViewChild("popOver") public popover: NgbPopover;
	@Input() ccAddress: any = [];
	@Input() toAddress: any = [];
	@Input() users: any = [];
	@Input() filtered_status: any = [];
	@Input() close_type: any = [];
	@Input() requesters: any = [];
	@Input() basicInfo: any;
	@Input() displayId: any;
	@Input() refId: number;
	@Input() refData: any;
	@Input() imapInputData: any;
	@Input() is_from_disabled: any;
	@Input() isRefDataLoad: boolean = false;
	@Input() permissions: any;
	@Input() internelNotes: any = [];
	@Input() currentUser: any = [];
	@Input() lav_module: any;
	@Input() moduleType: any;
	@Input() ref_data_copy: any;
	@Input() submitActions: any = ["RESOLVE"];
	@Input() custom_field_config: any;
	@Input() custom_field_data: any;
	@Input() isReadUnread: any;
	@Input() isSeenBy: any;
	@Input() disable_config: any;
	@Input() dateTimeOptions: any;
	@Input() signatureData: any;
	@Input() rich_text_data: any;
	@Input() agreedDateTimeOptions: any;
	@Input() imap_list: any = [];
	@Input() transition_status: any;
	@Input() stateKeyIdMap: any;
	@Input() messageOptions = ["Reply", "Forward"];
	@Input() dropdown;
	public imgHeightWidthStr = "img height='400px' width='100%'";
	public isTooltipShow = false;
	addResponseEmail = false;
	addResponseTemplate = false;
	public disabled_options = false;
	userTZ: any;
	public editorId = "";
	mail_list = [];
	showReplyBox = true;
	public toneData = { title: "Professional", type: "professsional" };
	rich_text_list: any;
	previewValue: any;
	is_accept_button = false;
	showAddResponseSidebar = false;
	showQuickResponseSidbar = false;
	public activeButtonIndex: number;
	public activeToneButton: number = 0;
	public productPlan;
	public toneOptionType: any;
	public toneType: any = "professsional";
	public attachmentUploader: FileUploader = new FileUploader({
		url: URL,
		isHTML5: true,
	});
	communicationEmailType = {
		value: "all",
		displayLabel: this._translateService.instant("UI.k_all"),
		keyName: "All",
		actions: ["SENTMAIL", "REPLY", "FORWARD", "COMMENT", "SYMPTOMS", "ANALYSE", "ROOT_CAUSE", "WORKLOG"],
	};
	communicationInternelNoteType = {
		value: "all",
		displayLabel: this._translateService.instant("UI.k_all"),
		keyName: "All",
		actions: ["COMMENT", "SYMPTOMS", "ANALYSE", "ROOT_CAUSE", "WORKLOG"],
	};
	public editorIDMap = {
		"Add Notes": this._translateService.instant("UI.k_add_notes"),
		Worklog: this._translateService.instant("UI.k_worklog"),
		Symptoms: this._translateService.instant("UI.k_symptoms"),
		Analyse: this._translateService.instant("UI.k_analyse"),
		"Root Cause": this._translateService.instant("UI.k_root_cause"),
		"Closure Note": this._translateService.instant("UI.k_close_incident"),
		Resolution: this._translateService.instant("UI.k_resolution"),
		Diagnosis: this._translateService.instant("UI.k_diagnosis"),
		"Service Change": this._translateService.instant("UI.k_service_change"),
		Approval: this._translateService.instant("UI.k_approval"),
		"Priority Change": this._translateService.instant("UI.k_priority_change"),
		PIR: this._translateService.instant("UI.k_pir"),
		Review: this._translateService.instant("UI.k_review"),
		"Reopen Note": this._translateService.instant("UI.k_reopen_note"),
	};
	communicationFilterSelection: any = this.communicationEmailType;
	internalNoteFilterSelection: any = this.communicationInternelNoteType;
	showEmails = true;
	totalEmailTo = 3;
	showComments = true;
	showSMS = true;
	showEditor = false;
	commDataTotalCount = 0;
	commDataCount = 0;
	firstKey = 0;
	selectedOption = 10;
	cardCount = this.selectedOption;
	lastKey = this.cardCount - 1;
	page = 1;
	items_per_page = 10;
	finished = false;
	latestEmail: any;
	notScrolly = true;
	copyOfAllCard = [];
	notEmptyCommData = true;
	scrolltop: number = null;
	isEmailCompOpen: boolean = false;
	comment_types = CommentTypes;
	blured = false;
	focused = false;
	public filesList: any = [];
	public isPrivate: any = "false";
	public workDuration = {
		day: undefined,
		hr: undefined,
		min: undefined,
	};
	public resolution_date = undefined;
	public isOpenCC = false;
	public isOpenBCC = false;
	imapSelectedText: any;
	selectedIMAPValue: any;
	emailContent;
	removeQuotedTxt = false;
	showQuotedTxt = false;
	erichTextData: any;
	that = this;
	selectedActions = [];
	communicationRenderData = {
		communicationTypes: [],
		actions: {},
		messageOptions: {},
		submitActions: {},
	};
	showEmailTemplate = false;
	treeInputData = [];
	signatureInputData = [];
	selectTreeValue;
	emailTemplate;
	event;
	currentItem = {};
	previewData;
	responseEmailTreeHeading = this._translateService.instant("UI.k_quick_response");
	selectQuickResponseText = this._translateService.instant("UI.k_select_quick_response");
	selectSignatureText = this._translateService.instant("UI.k_add_signature");
	signatureTreeHeading = this._translateService.instant("UI.k_manage_signature");
	selectedSignatureValue;
	public toneItem = [
		{ title: "Professional", type: "professsional" },
		{ title: "Casual", type: "casual" },
		{ title: "Empathic", type: "empathic" },
		{ title: "Simple", type: "simple" },
	];
	public toneOptionData = [
		{ icon: "fa-file-magnifying-glass", title: "Proof Read", type: "proof_read" },
		{ icon: "fa-file-magnifying-glass", title: "Rephrase", type: "rephrase" },
		{ icon: "fa-arrow-up-right-and-arrow-down-left-from-center", title: "Expand", type: "expand" },
		{ icon: "fa-arrow-up-right-and-arrow-down-left-from-center", title: "Expand In Details", type: "elaborate" },
	];

	// public selectedMailTextContent:any = 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sequi, ex tempora, debitis cum error modi, omnis minima laborum quae eius provident illo magni est ipsa itaque exercitationem. Quia, dicta assumenda odio possimus illo, inventore quas enim voluptatum autem consequatur minus debitis perspiciatis? Quo id fugiat libero quidem, expedita iste vero amet esse, fuga quod ut facere, voluptatum explicabo quos sed dolorem? Deserunt, voluptatem minima, labore harum corrupti consectetur iusto ut possimus iste repellat, quod suscipit. Aspernatur ab voluptatibus atque impedit hic, nemo inventore quidem quo tempore corrupti nulla saepe, aperiam molestias quam alias iusto ea possimus. Minus sunt et nobis.';
	prevReplyData: any;
	rephraseDisable: boolean = false;
	selectedText = false;
	rephrasedText: any;
	quillEditor: any;
	delta: any;
	quillCursor: any = {};
	rephrase_error: boolean = false;
	@ViewChild("previewResponse") previewResponse: ElementRef;
	@ViewChild("previewSignature") previewSignature: ElementRef;
	is_ssp_login: boolean = false;
	private _unsubscribeAll: Subject<any>;
	requesterList: any = [];
	toSearchFalse: boolean = false;
	ccSearchFalse: boolean = false;
	searcRequestLen: any;
	private timer: any;
	orgToAddress: any = [];
	orgCCAddress: any = [];

	constructor(
		private _incidentViewService: IncidentViewService,
		private _problemService: ProblemService,
		private _toastr: ToastrService,
		private _translateService: TranslateService,
		public treeSvc: TreeDataService,
		private _coreSidebarService: CoreSidebarService,
		private _modalService: NgbModal,
		private _router: Router,
		private _requestViewService: RequestViewService,
		private _changeViewService: ChangeViewService,
		private _releaseService: ReleaseService,
		private _domSanitizerService: DomSanitizer,
		private _workspaceService: WorkSpaceService,
		private _imacdService: IMACDService,
		private _wssService: WSSService
	) {
		this.delta = Quill.import("delta");
	}

	ngOnChanges() {}

	ngOnInit(): void {
		this._unsubscribeAll = new Subject<any>();
		this.signatureInputData = this.signatureData;
		if (this.moduleType == "INCIDENT") {
			this.messageOptions = ["Reply", "Forward", "CreateNewTicket", "AddToExisting"];
			this.communicationEmailType = {
				value: "all",
				displayLabel: this._translateService.instant("UI.k_all"),
				keyName: "All",
				actions: ["SENTMAIL", "REPLY", "FORWARD", "COMMENT", "SYMPTOMS", "ANALYSE", "ROOT_CAUSE", "WORKLOG"],
			};
			this.communicationInternelNoteType = {
				value: "all",
				displayLabel: this._translateService.instant("UI.k_all"),
				keyName: "All",
				actions: ["COMMENT", "SYMPTOMS", "ANALYSE", "ROOT_CAUSE", "WORKLOG"],
			};
			this.editorIDMap["Closure Note"] = this._translateService.instant("UI.k_close_incident");
		}
		if (this.moduleType == "PROBLEM") {
			this.communicationEmailType = {
				value: "all",
				displayLabel: this._translateService.instant("UI.k_all"),
				keyName: "All",
				actions: ["SENTMAIL", "REPLY", "FORWARD", "COMMENT", "WORKLOG"],
			};
			this.communicationInternelNoteType = {
				value: "all",
				displayLabel: this._translateService.instant("UI.k_all"),
				keyName: "All",
				actions: ["COMMENT", "WORKLOG"],
			};
			this.editorIDMap["Closure Note"] = this._translateService.instant("UI.k_close_problem");
		}
		if (this.moduleType == "REQUEST") {
			this.communicationEmailType = {
				value: "all",
				displayLabel: this._translateService.instant("UI.k_all"),
				keyName: "All",
				actions: ["SENTMAIL", "REPLY", "FORWARD", "COMMENT", "ANALYSE", "WORKLOG"],
			};
			this.communicationInternelNoteType = {
				value: "all",
				displayLabel: this._translateService.instant("UI.k_all"),
				keyName: "All",
				actions: ["COMMENT", "ANALYSE", "WORKLOG"],
			};

			this.editorIDMap["Closure Note"] = this._translateService.instant("UI.k_close_request");
		}
		if (this.moduleType == "CHANGE") {
			this.communicationEmailType = {
				value: "all",
				displayLabel: this._translateService.instant("UI.k_all"),
				keyName: "All",
				actions: ["SENTMAIL", "REPLY", "FORWARD", "COMMENT", "WORKLOG"],
			};
			this.communicationInternelNoteType = {
				value: "all",
				displayLabel: this._translateService.instant("UI.k_all"),
				keyName: "All",
				actions: ["COMMENT", "WORKLOG"],
			};
			this.editorIDMap["Closure Note"] = this._translateService.instant("UI.k_close_change");
		}
		if (this.moduleType == "RELEASE") {
			this.communicationEmailType = {
				value: "all",
				displayLabel: this._translateService.instant("UI.k_all"),
				keyName: "All",
				actions: ["SENTMAIL", "REPLY", "FORWARD", "COMMENT", "WORKLOG"],
			};
			this.communicationInternelNoteType = {
				value: "all",
				displayLabel: this._translateService.instant("UI.k_all"),
				keyName: "All",
				actions: ["COMMENT", "WORKLOG"],
			};
			this.editorIDMap["Closure Note"] = this._translateService.instant("UI.k_close_release");
		}
		if (this.moduleType == "IMACD") {
			this.communicationEmailType = {
				value: "all",
				displayLabel: this._translateService.instant("UI.k_all"),
				keyName: "All",
				actions: ["SENTMAIL", "REPLY", "FORWARD", "COMMENT"],
			};
			this.communicationInternelNoteType = {
				value: "all",
				displayLabel: this._translateService.instant("UI.k_all"),
				keyName: "All",
				actions: ["COMMENT"],
			};
			this.editorIDMap["Closure Note"] = this._translateService.instant("UI.k_close_imacd");
		}
		this.productPlan = store.get("plan");
		if (this.productPlan?.length > 0 && this.productPlan?.[0]?.["product"] === "Infraon-Helpdesk") {
			this.disabled_options = true;
		} else {
			this.disabled_options = false;
		}

		this.treeInputData = this.treeSvc.getEmailTemplate();
		let accessType = localStorage.getItem("accessType") || 0;
		if (accessType == 2) {
			this.is_ssp_login = true;
		}
		let that = this;
		this.communicationRenderData.messageOptions = {
			Reply: {
				id: "Reply",
				name: "reply",
				icon: "fa-light fa-arrow-turn-down-left",
				displayLabel: this._translateService.instant("UI.k_reply"),
				keyName: "Reply",
				callBack: function (mail) {
					that.scrollable.scrollToElement("#emailApplication");
					that.composeMail("edit", mail, false, "Re:", that.scrolltoemailwindow);
					that.showReplyBox = false;
					that.showEmailTemplate = true;
				},
			},
			Forward: {
				id: "Forward",
				name: "forward",
				icon: "fa-light fa-share ",
				displayLabel: this._translateService.instant("UI.k_forward"),
				keyName: "Forward",
				callBack: function (mail) {
					that.scrollable.scrollToElement("#emailApplication");
					that.composeMail("edit", mail, false, "Fw:", that.scrolltoemailwindow);
					that.showReplyBox = false;
					that.showEmailTemplate = true;
				},
			},
			CreateNewTicket: {
				id: "CreateNewTicket",
				name: "createNewTicket",
				icon: "fa-light fa-plus",
				// displayLabel: this._translateService.instant("UI.k_forward"),
				displayLabel: this._translateService.instant("UI.k_create_new_ticket"),
				keyName: "CreateNewTicket",
				callBack: function (mail) {
					that.scrollable.scrollToElement("#emailApplication");
					that.composeMail("edit", mail, false, "Fw:", that.scrolltoemailwindow);
					that.showReplyBox = false;
					that.showEmailTemplate = true;
				},
			},
			AddToExisting: {
				id: "AddToExisting",
				name: "addToExisting",
				iconType: "image",
				icon: "twodirection.svg",
				// displayLabel: this._translateService.instant("UI.k_AddToExisting"),
				displayLabel: this._translateService.instant("UI.k_add_to_existing"),
				keyName: "AddToExisting",
				callBack: function (mail) {
					that.chooseExistingTicket.emit(mail);
				},
			},
		};

		this.communicationRenderData.actions = {
			REPLY: {
				id: "Reply",
				name: "reply",
				icon: "fa-light fa-arrow-turn-down-left",
				displayLabel: this._translateService.instant("UI.k_reply"),
				keyName: "Reply",
				callBack: function () {
					that.scrollable.scrollToElement("#emailApplication");
					that.composeMail("edit", that.latestEmail, false, "Re:", that.scrolltoemailwindow);
					that.showReplyBox = false;
					that.showEmailTemplate = true;
				},
				isDisplay: function (keyname) {
					return that.mail_list.length > 0 && that.latestEmail;
				},
			},
			FORWARD: {
				id: "Forward",
				name: "forward",
				icon: "fa-light fa-share",
				displayLabel: this._translateService.instant("UI.k_forward"),
				keyName: "Forward",
				callBack: function () {
					that.scrollable.scrollToElement("#emailApplication");
					that.composeMail("edit", that.latestEmail, false, "Fw:", that.scrolltoemailwindow);
					that.showReplyBox = false;
					that.showEmailTemplate = true;
				},
				isDisplay: function (keyname) {
					return that.mail_list.length > 0 && that.latestEmail;
				},
			},
			SENTMAIL: {
				id: "Sentmail",
				name: "Sentmail",
				icon: "fa-envelope fa-light",
				displayLabel: this._translateService.instant("UI.k_mail"),
				keyName: "Sentmail",
				callBack: function () {
					that.scrollable.scrollToElement("#emailApplication");
					that.composeMail("edit", {}, false, "Sn:", that.scrolltoemailwindow);
					that.showReplyBox = false;
					that.showEmailTemplate = true;
				},
				isDisplay: function (keyname) {
					return (
						((that.moduleType === "PROBLEM" ||
							that.moduleType === "CHANGE" ||
							that.moduleType === "RELEASE" ||
							that.moduleType === "IMACD") &&
							that.mail_list.length === 0) ||
						!that.latestEmail
					);
				},
			},
			COMMENT: {
				id: "Add Notes",
				name: "addNote",
				icon: "fa-light fa-file-lines",
				displayLabel:
					this._translateService.instant("UI.k_add") + " " + this._translateService.instant("UI.k_notes"),
				keyName: "Comment",
				lisenceKey: {
					module_key: that.lav_module,
					keys: [`${that.moduleType}_COMMUNICATION_NOTE`],
				},
				callBack: function () {
					that.enableEditor(this.id, that.scrolltocommentwindow);
					that.showReplyBox = false;
					that.scrollable.scrollToElement("#emailApplication");
				},
				isDisplay: function (keyname) {
					return (
						that.communicationFilterSelection.keyName != "Email" && that.internelNotes?.includes(keyname)
					);
				},
			},
			SYMPTOMS: {
				id: "Symptoms",
				name: "symptoms",
				icon: "fa-light fa-comment-exclamation",
				displayLabel: this._translateService.instant("UI.k_symptoms"),
				keyName: "Symptoms",
				lisenceKey: {
					module_key: that.lav_module,
					keys: [`${that.moduleType}_COMMUNICATION_SYMPTOMS`],
				},
				callBack: function () {
					that.enableEditor(this.id, that.scrolltocommentwindow);
					that.showReplyBox = false;
					that.scrollable.scrollToElement("#emailApplication");
				},
				isDisplay: function (keyname) {
					return that.isCommunicationClosed() && that.internelNotes?.includes(keyname) && !that.is_ssp_login;
				},
			},
			DIAGNOSIS: {
				id: "Diagnosis",
				name: "diagnosis",
				icon: "fa-light fa-file-lines",
				displayLabel: this._translateService.instant("UI.k_diagnosis"),
				keyName: "Diagnosis",
				callBack: function () {
					that.enableEditor(this.id, that.scrolltocommentwindow);
					that.showReplyBox = false;
					that.scrollable.scrollToElement("#emailApplication");
				},
				isDisplay: function (keyname) {
					return that.isCommunicationClosed() && that.internelNotes?.includes(keyname) && !that.is_ssp_login;
				},
			},
			ROOT_CAUSE: {
				id: "Root Cause",
				name: "rootCause",
				icon: "fa-light fa-network-wired",
				displayLabel: this._translateService.instant("UI.k_root_cause"),
				keyName: "RootCause",
				lisenceKey: {
					module_key: that.lav_module,
					keys: [`${that.moduleType}_COMMUNICATION_ROOT_CAUSE`],
				},
				callBack: function () {
					that.enableEditor(this.id, that.scrolltocommentwindow);
					that.showReplyBox = false;
					that.scrollable.scrollToElement("#emailApplication");
				},
				isDisplay: function (keyname) {
					return that.isCommunicationClosed() && that.internelNotes?.includes(keyname) && !that.is_ssp_login;
				},
			},
			ANALYSE: {
				id: "Analyse",
				name: "analyse",
				icon: "fa-light fa-magnifying-glass",
				displayLabel: this._translateService.instant("UI.k_analyse"),
				keyName: "Analyse",
				lisenceKey: {
					module_key: that.lav_module,
					keys: [`${that.moduleType}_COMMUNICATION_ANALYSE`],
				},
				callBack: function () {
					that.enableEditor(this.id, that.scrolltocommentwindow);
					that.showReplyBox = false;
					that.scrollable.scrollToElement("#emailApplication");
				},
				isDisplay: function (keyname) {
					return that.isCommunicationClosed() && that.internelNotes?.includes(keyname) && !that.is_ssp_login;
				},
			},
			WORKLOG: {
				id: "Worklog",
				name: "workLog",
				icon: "fa-light fa-clock",
				displayLabel: this._translateService.instant("UI.k_worklog"),
				keyName: "Worklog",
				lisenceKey: {
					module_key: that.lav_module,
					keys: [`${that.moduleType}_COMMUNICATION_WORKLOG`],
				},
				callBack: function () {
					that.enableEditor(this.id, that.scrolltocommentwindow);
					that.showReplyBox = false;
					that.scrollable.scrollToElement("#emailApplication");
				},
				isDisplay: function (keyname) {
					return that.isCommunicationClosed() && that.internelNotes?.includes(keyname) && !that.is_ssp_login;
				},
			},
		};

		this.communicationRenderData.submitActions = {
			RESOLVE: {
				id: "Resolve",
				name: "resolve",
				displayLabel: this._translateService.instant("UI.k_resolve"),
				keyName: "Resolve",
				callBack: function () {
					that.scrollable.scrollToElement("#emailApplication");
					that.enableEditor("Resolution", that.scrolltoemailwindow);
					that.showReplyBox = false;
				},
				isDisplay: function (keyname) {
					return that.checkCommunicationState();
				},
			},
			APPROVE: {
				id: "Approve",
				name: "approve",
				displayLabel: this._translateService.instant("UI.k_approve"),
				keyName: "Approve",
				callBack: function () {
					that.scrollable.scrollToElement("#emailApplication");
					that.enableEditor("Approval", that.scrolltoemailwindow);
					that.showReplyBox = false;
				},
				isDisplay: function (keyname) {
					return that.ref_data_copy?.approval_data?.can_approve && !that.is_ssp_login;
				},
			},
			REVIEW: {
				id: "Review",
				name: "review",
				displayLabel: this._translateService.instant("UI.k_review"),
				keyName: "Review",
				callBack: function () {
					that.scrollable.scrollToElement("#emailApplication");
					that.enableEditor("Review", that.scrolltoemailwindow);
					that.showReplyBox = false;
				},
				isDisplay: function (keyname) {
					return that.ref_data_copy?.review_data?.can_review && !that.is_ssp_login;
				},
			},
			COMPLETE: {
				id: "Complete",
				name: "complete",
				displayLabel: this._translateService.instant("UI.k_complete"),
				keyName: "Complete",
				callBack: function () {
					that.scrollable.scrollToElement("#emailApplication");
					that.enableEditor("Closure Note", that.scrolltoemailwindow);
					that.showReplyBox = false;
				},
				isDisplay: function (keyname) {
					return (
						(that.ref_data_copy?.basic_info?.state?.id == 4 ||
							(that.ref_data_copy?.basic_info?.state?.id == 5 && that.ref_data_copy?.is_exit == false)) &&
						that.permissions?.edit &&
						!that.is_ssp_login
					);
				},
			},
		};
		if (this.moduleType == "RELEASE") {
			this.communicationRenderData.submitActions = {
				RESOLVE: {
					id: "Resolve",
					name: "resolve",
					displayLabel: this._translateService.instant("UI.k_resolve"),
					keyName: "Resolve",
					callBack: function () {
						that.scrollable.scrollToElement("#emailApplication");
						that.enableEditor("Resolution", that.scrolltoemailwindow);
						that.showReplyBox = false;
					},
					isDisplay: function (keyname) {
						return that.checkCommunicationState();
					},
				},
				APPROVE: {
					id: "Approve",
					name: "approve",
					displayLabel: this._translateService.instant("UI.k_approve"),
					keyName: "Approve",
					callBack: function () {
						that.scrollable.scrollToElement("#emailApplication");
						that.enableEditor("Approval", that.scrolltoemailwindow);
						that.showReplyBox = false;
					},
					isDisplay: function (keyname) {
						return that.ref_data_copy?.approval_data?.can_approve && !that.is_ssp_login;
					},
				},
				REVIEW: {
					id: "Review",
					name: "review",
					displayLabel: this._translateService.instant("UI.k_review"),
					keyName: "Review",
					callBack: function () {
						that.scrollable.scrollToElement("#emailApplication");
						that.enableEditor("Review", that.scrolltoemailwindow);
						that.showReplyBox = false;
					},
					isDisplay: function (keyname) {
						return that.ref_data_copy?.review_data?.can_review && !that.is_ssp_login;
					},
				},
				COMPLETE: {
					id: "Complete",
					name: "complete",
					displayLabel: this._translateService.instant("UI.k_complete"),
					keyName: "Complete",
					callBack: function () {
						that.scrollable.scrollToElement("#emailApplication");
						that.enableEditor("Closure Note", that.scrolltoemailwindow);
						that.showReplyBox = false;
					},
					isDisplay: function (keyname) {
						return that.checkReleaseCondition();
					},
				},
			};
		}
		if (this.moduleType == "INCIDENT") {
			this.communicationRenderData.communicationTypes = [
				{
					id: 0,
					displayLabel: this._translateService.instant("UI.k_email"),
					keyName: "Email",
					// actions: ["REPLY", "FORWARD", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE"],
					lisenceKey: {
						module_key: that.lav_module,
						keys: [`${that.moduleType}_COMMUNICATION_EMAIL`],
					},
					actions: ["REPLY", "FORWARD", "SENTMAIL"],
				},
				{
					id: 1,
					displayLabel: this._translateService.instant("UI.k_sms"),
					keyName: "SMS",
					lisenceKey: {
						module_key: "NOTIFICATION",
						keys: ["SMS_SUPPORT"],
					},
					actions: ["REPLY", "FORWARD"],
				},
				{
					id: 2,
					displayLabel: this._translateService.instant("UI.k_comment"),
					keyName: "InternalNote",
					actions: ["COMMENT", "SYMPTOMS", "ANALYSE", "ROOT_CAUSE", "WORKLOG"],
					nestedTypes: [
						{
							id: "Add Notes",
							displayLabel: this._translateService.instant("UI.k_note"),
							keyName: "Comment",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_NOTE`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["COMMENT"],
						},
						{
							id: "Worklog",
							displayLabel: this._translateService.instant("UI.k_worklog"),
							keyName: "Worklog",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_WORKLOG`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["WORKLOG"],
						},
						{
							id: "Symptoms",
							displayLabel: this._translateService.instant("UI.k_symptoms"),
							keyName: "Symptoms",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_SYMPTOMS`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["SYMPTOMS"],
						},
						{
							id: "Analyse",
							displayLabel: this._translateService.instant("UI.k_analyse"),
							keyName: "Analyse",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_ANALYSE`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["ANALYSE"],
						},
						// {
						// 	id: "Diagnosis",
						// 	displayLabel: this._translateService.instant("UI.k_diagnosis"),
						// 	keyName: "Diagnosis",
						// 	// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
						// 	actions: ["DIAGNOSIS"]
						// },
						{
							id: "Root Cause",
							displayLabel: this._translateService.instant("UI.k_root_cause"),
							keyName: "RootCause",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_ROOT_CAUSE`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["ROOT_CAUSE"],
						},
					],
				},
			];
			this.getMailConversations([
				"SENTMAIL",
				"REPLY",
				"FORWARD",
				"COMMENT",
				"SYMPTOMS",
				"ROOT_CAUSE",
				"ANALYSE",
				"WORKLOG",
			]);
		}
		if (this.moduleType == "PROBLEM") {
			this.communicationRenderData.communicationTypes = [
				{
					id: 0,
					displayLabel: this._translateService.instant("UI.k_email"),
					keyName: "Email",
					// actions: ["REPLY", "FORWARD", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE"],
					lisenceKey: {
						module_key: that.lav_module,
						keys: [`${that.moduleType}_COMMUNICATION_EMAIL`],
					},
					actions: ["REPLY", "FORWARD", "SENTMAIL"],
				},
				{
					id: 1,
					displayLabel: this._translateService.instant("UI.k_sms"),
					keyName: "SMS",
					lisenceKey: {
						module_key: "NOTIFICATION",
						keys: ["SMS_SUPPORT"],
					},
					actions: ["REPLY", "FORWARD"],
				},
				{
					id: 2,
					displayLabel: this._translateService.instant("UI.k_comment"),
					keyName: "InternalNote",
					actions: ["COMMENT", "WORKLOG"],
					nestedTypes: [
						{
							id: "Add Notes",
							displayLabel: this._translateService.instant("UI.k_note"),
							keyName: "Comment",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_NOTE`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["COMMENT"],
						},
						{
							id: "Worklog",
							displayLabel: this._translateService.instant("UI.k_worklog"),
							keyName: "Worklog",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_WORKLOG`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["WORKLOG"],
						},
						{
							id: "Symptoms",
							displayLabel: this._translateService.instant("UI.k_symptoms"),
							keyName: "Symptoms",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_SYMPTOMS`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["SYMPTOMS"],
						},
						{
							id: "Analyse",
							displayLabel: this._translateService.instant("UI.k_analyse"),
							keyName: "Analyse",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_ANALYSE`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["ANALYSE"],
						},
						// {
						// 	id: "Diagnosis",
						// 	displayLabel: this._translateService.instant("UI.k_diagnosis"),
						// 	keyName: "Diagnosis",
						// 	// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
						// 	actions: ["DIAGNOSIS"]
						// },
						{
							id: "Root Cause",
							displayLabel: this._translateService.instant("UI.k_root_cause"),
							keyName: "RootCause",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_ROOT_CAUSE`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["ROOT_CAUSE"],
						},
					],
				},
			];
			this.getMailConversations(["SENTMAIL", "REPLY", "FORWARD", "COMMENT", "WORKLOG"]);
		}
		if (this.moduleType == "REQUEST") {
			this.communicationRenderData.communicationTypes = [
				{
					id: 0,
					displayLabel: this._translateService.instant("UI.k_email"),
					keyName: "Email",
					// actions: ["REPLY", "FORWARD", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE"],
					lisenceKey: {
						module_key: that.lav_module,
						keys: [`${that.moduleType}_COMMUNICATION_EMAIL`],
					},
					actions: ["REPLY", "FORWARD", "SENTMAIL"],
				},
				{
					id: 1,
					displayLabel: this._translateService.instant("UI.k_sms"),
					keyName: "SMS",
					lisenceKey: {
						module_key: "NOTIFICATION",
						keys: ["SMS_SUPPORT"],
					},
					actions: ["REPLY", "FORWARD"],
				},
				{
					id: 2,
					displayLabel: this._translateService.instant("UI.k_comment"),
					keyName: "InternalNote",
					actions: ["COMMENT", "ANALYSE", "WORKLOG"],
					nestedTypes: [
						{
							id: "Add Notes",
							displayLabel: this._translateService.instant("UI.k_note"),
							keyName: "Comment",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_NOTE`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["COMMENT"],
						},
						{
							id: "Worklog",
							displayLabel: this._translateService.instant("UI.k_worklog"),
							keyName: "Worklog",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_WORKLOG`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["WORKLOG"],
						},
						{
							id: "Symptoms",
							displayLabel: this._translateService.instant("UI.k_symptoms"),
							keyName: "Symptoms",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_SYMPTOMS`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["SYMPTOMS"],
						},
						{
							id: "Analyse",
							displayLabel: this._translateService.instant("UI.k_analyse"),
							keyName: "Analyse",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_ANALYSE`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["ANALYSE"],
						},
						// {
						// 	id: "Diagnosis",
						// 	displayLabel: this._translateService.instant("UI.k_diagnosis"),
						// 	keyName: "Diagnosis",
						// 	// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
						// 	actions: ["DIAGNOSIS"]
						// },
						{
							id: "Root Cause",
							displayLabel: this._translateService.instant("UI.k_root_cause"),
							keyName: "RootCause",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_ROOT_CAUSE`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["ROOT_CAUSE"],
						},
					],
				},
			];

			this.getMailConversations(["SENTMAIL", "REPLY", "FORWARD", "COMMENT", "ANALYSE", "WORKLOG"]);
		}
		if (this.moduleType == "CHANGE") {
			this.communicationRenderData.communicationTypes = [
				{
					id: 0,
					displayLabel: this._translateService.instant("UI.k_email"),
					keyName: "Email",
					// actions: ["REPLY", "FORWARD", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE"],
					lisenceKey: {
						module_key: that.lav_module,
						keys: [`${that.moduleType}_COMMUNICATION_EMAIL`],
					},
					actions: ["REPLY", "FORWARD", "SENTMAIL"],
				},
				{
					id: 1,
					displayLabel: this._translateService.instant("UI.k_sms"),
					keyName: "SMS",
					lisenceKey: {
						module_key: "NOTIFICATION",
						keys: ["SMS_SUPPORT"],
					},
					actions: ["REPLY", "FORWARD"],
				},
				{
					id: 2,
					displayLabel: this._translateService.instant("UI.k_comment"),
					keyName: "InternalNote",
					actions: ["COMMENT", "WORKLOG"],
					nestedTypes: [
						{
							id: "Add Notes",
							displayLabel: this._translateService.instant("UI.k_note"),
							keyName: "Comment",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_NOTE`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["COMMENT"],
						},
						{
							id: "Worklog",
							displayLabel: this._translateService.instant("UI.k_worklog"),
							keyName: "Worklog",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_WORKLOG`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["WORKLOG"],
						},
						{
							id: "Symptoms",
							displayLabel: this._translateService.instant("UI.k_symptoms"),
							keyName: "Symptoms",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_SYMPTOMS`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["SYMPTOMS"],
						},
						{
							id: "Analyse",
							displayLabel: this._translateService.instant("UI.k_analyse"),
							keyName: "Analyse",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_ANALYSE`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["ANALYSE"],
						},
						// {
						// 	id: "Diagnosis",
						// 	displayLabel: this._translateService.instant("UI.k_diagnosis"),
						// 	keyName: "Diagnosis",
						// 	// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
						// 	actions: ["DIAGNOSIS"]
						// },
						{
							id: "Root Cause",
							displayLabel: this._translateService.instant("UI.k_root_cause"),
							keyName: "RootCause",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_ROOT_CAUSE`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["ROOT_CAUSE"],
						},
					],
				},
			];
			this.getMailConversations(["SENTMAIL", "REPLY", "FORWARD", "COMMENT", "WORKLOG"]);
		}
		if (this.moduleType == "RELEASE") {
			this.communicationRenderData.communicationTypes = [
				{
					id: 0,
					displayLabel: this._translateService.instant("UI.k_email"),
					keyName: "Email",
					// actions: ["REPLY", "FORWARD", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE"],
					lisenceKey: {
						module_key: that.lav_module,
						keys: [`${that.moduleType}_COMMUNICATION_EMAIL`],
					},
					actions: ["REPLY", "FORWARD", "SENTMAIL"],
				},
				{
					id: 1,
					displayLabel: this._translateService.instant("UI.k_sms"),
					keyName: "SMS",
					lisenceKey: {
						module_key: "NOTIFICATION",
						keys: ["SMS_SUPPORT"],
					},
					actions: ["REPLY", "FORWARD"],
				},
				{
					id: 2,
					displayLabel: this._translateService.instant("UI.k_comment"),
					keyName: "InternalNote",
					actions: ["COMMENT", "WORKLOG"],
					nestedTypes: [
						{
							id: "Add Notes",
							displayLabel: this._translateService.instant("UI.k_note"),
							keyName: "Comment",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_NOTE`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["COMMENT"],
						},
						{
							id: "Worklog",
							displayLabel: this._translateService.instant("UI.k_worklog"),
							keyName: "Worklog",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_WORKLOG`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["WORKLOG"],
						},
						{
							id: "Symptoms",
							displayLabel: this._translateService.instant("UI.k_symptoms"),
							keyName: "Symptoms",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_SYMPTOMS`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["SYMPTOMS"],
						},
						{
							id: "Analyse",
							displayLabel: this._translateService.instant("UI.k_analyse"),
							keyName: "Analyse",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_ANALYSE`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["ANALYSE"],
						},
						// {
						// 	id: "Diagnosis",
						// 	displayLabel: this._translateService.instant("UI.k_diagnosis"),
						// 	keyName: "Diagnosis",
						// 	// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
						// 	actions: ["DIAGNOSIS"]
						// },
						{
							id: "Root Cause",
							displayLabel: this._translateService.instant("UI.k_root_cause"),
							keyName: "RootCause",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_ROOT_CAUSE`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["ROOT_CAUSE"],
						},
					],
				},
			];
			this.getMailConversations(["SENTMAIL", "REPLY", "FORWARD", "COMMENT", "WORKLOG"]);
		}
		if (this.moduleType == "IMACD") {
			this.communicationRenderData.submitActions = {
				RESOLVE: {
					id: "Resolve",
					name: "resolve",
					displayLabel: this._translateService.instant("UI.k_resolve"),
					keyName: "Resolve",
					callBack: function () {
						that.scrollable.scrollToElement("#emailApplication");
						that.enableEditor("Resolution", that.scrolltoemailwindow);
						that.showReplyBox = false;
					},
					isDisplay: function (keyname) {
						return that.checkCommunicationState();
					},
				},
				APPROVE: {
					id: "Approve",
					name: "approve",
					displayLabel: this._translateService.instant("UI.k_approve"),
					keyName: "Approve",
					callBack: function () {
						that.scrollable.scrollToElement("#emailApplication");
						that.enableEditor("Approval", that.scrolltoemailwindow);
						that.showReplyBox = false;
					},
					isDisplay: function (keyname) {
						return that.ref_data_copy?.approval_data?.can_approve && !that.is_ssp_login;
					},
				},
				REVIEW: {
					id: "Review",
					name: "review",
					displayLabel: this._translateService.instant("UI.k_review"),
					keyName: "Review",
					callBack: function () {
						that.scrollable.scrollToElement("#emailApplication");
						that.enableEditor("Review", that.scrolltoemailwindow);
						that.showReplyBox = false;
					},
					isDisplay: function (keyname) {
						return that.ref_data_copy?.review_data?.can_review && !that.is_ssp_login;
					},
				},
				COMPLETE: {
					id: "Complete",
					name: "complete",
					displayLabel: this._translateService.instant("UI.k_complete"),
					keyName: "Complete",
					callBack: function () {
						that.scrollable.scrollToElement("#emailApplication");
						that.enableEditor("Closure Note", that.scrolltoemailwindow);
						that.showReplyBox = false;
					},
					isDisplay: function (keyname) {
						return (
							that.ref_data_copy?.basic_info?.state?.id == 5 &&
							that.ref_data_copy?.is_exit == false &&
							that.permissions?.edit &&
							!that.is_ssp_login
						);
					},
				},
			};
			this.communicationRenderData.communicationTypes = [
				{
					id: 0,
					displayLabel: this._translateService.instant("UI.k_email"),
					keyName: "Email",
					// actions: ["REPLY", "FORWARD", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE"],
					lisenceKey: {
						module_key: that.lav_module,
						keys: [`${that.moduleType}_COMMUNICATION_EMAIL`],
					},
					actions: ["REPLY", "FORWARD", "SENTMAIL"],
				},
				{
					id: 1,
					displayLabel: this._translateService.instant("UI.k_sms"),
					keyName: "SMS",
					lisenceKey: {
						module_key: "NOTIFICATION",
						keys: ["SMS_SUPPORT"],
					},
					actions: ["REPLY", "FORWARD"],
				},
				{
					id: 2,
					displayLabel: this._translateService.instant("UI.k_comment"),
					keyName: "InternalNote",
					actions: ["COMMENT"],
					nestedTypes: [
						{
							id: "Add Notes",
							displayLabel: this._translateService.instant("UI.k_note"),
							keyName: "Comment",
							lisenceKey: {
								module_key: that.lav_module,
								keys: [`${that.moduleType}_COMMUNICATION_NOTE`],
							},
							// actions: ["COMMENT", "SYMPTOMS", "DIAGNOSIS", "ROOT_CAUSE", "WORKLOG"],
							actions: ["COMMENT"],
						},
					],
				},
			];
			this.getMailConversations(["SENTMAIL", "REPLY", "FORWARD", "COMMENT"]);
		}
		const userParams = JSON.parse(localStorage.getItem("userParams"));
		// this.userTZ = userParams?.user_tz;
		if (userParams && userParams["user_tz"]) {
			this.userTZ = userParams["user_tz"];
		}

		this.emailTemplate = [
			{
				title: "Customer not responding",
				tempText:
					"Sample description non deserunt ullamco Sample description non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia est sit aliqua dolor do amet sint. Velit officia ",
				change_type: "2022-06-22",
				status: {
					text: "Open",
					class: "badge-light-success",
				},
				priority: {
					display_label: "Critical",
					class: "danger",
					icon: "fa-chevrons-up",
				},
				risk: "3XD65TG",
				due_date: "20-12-2022",
				impact_service: "3XD65TG",
				change_implementer: "3XD65TG",
				service_classification: "3XD65TG",
				tags: "3XD65TG",
			},
			{
				title: "Resolve tickets",
				tempText:
					"Sample description non deserunt ullamco Sample description non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia est sit aliqua dolor do amet sint. Velit officia ",
				change_type: "2022-06-22",
				status: {
					text: "Open",
					class: "badge-light-success",
				},
				priority: {
					display_label: "Critical",
					class: "danger",
					icon: "fa-chevrons-up",
				},
				risk: "3XD65TG",
				due_date: "20-12-2022",
				impact_service: "3XD65TG",
				change_implementer: "3XD65TG",
				service_classification: "3XD65TG",
				tags: "3XD65TG",
			},
			{
				title: "Downgrade and inform",
				tempText:
					"Sample description non deserunt ullamco Sample description non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia est sit aliqua dolor do amet sint. Velit officia ",
				change_type: "2022-06-22",
				status: {
					text: "Open",
					class: "badge-light-success",
				},
				priority: {
					display_label: "Critical",
					class: "danger",
					icon: "fa-chevrons-up",
				},
				risk: "3XD65TG",
				due_date: "20-12-2022",
				impact_service: "3XD65TG",
				change_implementer: "3XD65TG",
				service_classification: "3XD65TG",
				tags: "3XD65TG",
			},
			{
				title: "Resolve tickets",
				tempText:
					"Sample description non deserunt ullamco Sample description non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia est sit aliqua dolor do amet sint. Velit officia ",
				change_type: "2022-06-22",
				status: {
					text: "Open",
					class: "badge-light-success",
				},
				priority: {
					display_label: "Critical",
					class: "danger",
					icon: "fa-chevrons-up",
				},
				risk: "3XD65TG",
				due_date: "20-12-2022",
				impact_service: "3XD65TG",
				change_implementer: "3XD65TG",
				service_classification: "3XD65TG",
				tags: "3XD65TG",
			},
		];
	}
	ngAfterViewInit() {
		this._wssService
			.getWsDataStore()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((res: any) => {
				if (res && res?.channel === "ai_content_rephrase:content_rephrase") {
					let message_obj = res?.data?.payload?.message?.message_obj;
					let ref_id = res?.data?.payload?.message?.ref_id;
					if (message_obj?.message_start == true && ref_id == this.refId) {
						this.rephrasedText = "";
						this.rephraseDisable = false;
					} else if (message_obj?.message && ref_id == this.refId) {
						this.rephrasedText = this.rephrasedText + message_obj?.message;
					}
				}
			});
	}
	communicationFilter(item) {
		this.communicationFilterSelection = item;
		this.mail_list = [];
		if (item.keyName == "Email") {
			this.showEmails = true;
			this.showComments = false;
			this.showSMS = false;
		} else if (item.keyName == "InternalNote") {
			this.internalNoteFilterSelection = this.communicationInternelNoteType;
			this.showEmails = false;
			this.showComments = true;
			this.showSMS = false;
		} else if (item.keyName == "SMS") {
			this.showEmails = false;
			this.showComments = false;
			this.showSMS = true;
		} else {
			this.showEmails = true;
			this.showComments = true;
			this.showSMS = true;
		}
		this.page = 1;
		this.commDataTotalCount = 0;
		this.getMailConversations(item.actions);
	}

	internalNoteFilter(item) {
		this.selectedActions = item.actions;
		if (item.keyName === "All") {
			this.internalNoteFilterSelection = this.communicationInternelNoteType;
			this.mail_list = this.copyOfAllCard;
			this.getMailConversations(item.actions);
		} else {
			this.internalNoteFilterSelection = item;
			const filterCard = this.copyOfAllCard.filter((id) => id.type === item.id);

			if (filterCard) {
				this.mail_list = filterCard;
				this.getMailConversations(this.selectedActions, item.keyName);
			}
		}
	}

	markAsReadUnread(event, msg_obj) {
		msg_obj["is_user_read"] = !event;
		this.messageObjectData.emit(msg_obj);
	}

	onScrollDown() {
		// this.page = 1;
		this.finished = false;
	}

	onScrollUp = () => {
		if (this.commDataCount <= this.commDataTotalCount) {
			if (this.showEditor == false) {
				this.firstKey += Number(this.selectedOption);
				this.lastKey += Number(this.selectedOption);
				this.loadNextComments(this.selectedActions);
			}
		}
	};
	// @HostListener('window:mouseup', ['$event'])
	// onTextSelection(event: MouseEvent) {
	// 	const selection = window.getSelection();
	// 	this.selectedMailTextContent = selection.toString();
	// }
	onOptionSelection(i, toneOption) {
		if (!this.rephraseDisable) {
			this.activeButtonIndex = i;
			this.toneOptionType = toneOption?.type;
			this.paraphraseText(toneOption?.type);
		}
	}
	onToneSelection(i, tone) {
		if (!this.rephraseDisable) {
			// this.activeToneButton = i;
			this.activeButtonIndex = null;
			this.toneOptionType = null;
			this.toneType = tone?.type;
			// this.quillEditor.setSelection(this.quillCursor?.cursorPosition, this.quillCursor?.cursorSelRange)
		}
	}

	composeMail = (event, dataSet, view_more, append_subject, elem) => {
		this.activeButtonIndex = null;
		this.toneOptionType = null;
		if (this.moduleType == "REQUEST") {
			dataSet["content"] = dataSet?.content?.replace(/<a id='requestURL'.*?<\/a>/i, "");
		}
		this.notEmptyCommData = false;

		if (this.moduleType == "INCIDENT") {
			dataSet["content"] = dataSet?.content?.replace(/<a id='ticketURL'.*?<\/a>/i, "");
			dataSet["content"] = dataSet?.content.replace(/<p><a.id='feedbackURL'.*\/a>/i,"");
			this.showQuotedTxt = false;
			this.removeQuotedTxt = false;
			this.emailContent = {};
			this.filesList = [];
			this.emailContent = _.cloneDeep(dataSet);
			if (append_subject == "Sn:") {
				this.showQuotedTxt = false;
				this.removeQuotedTxt = true;
				this.emailContent["subject"] = "[" + this.displayId + "]: " + this.refData?.basic_info?.summary;
				dataSet["subject"] = "[" + this.displayId + "]: " + this.refData?.basic_info?.summary;
			} else {
				this.emailContent["quoted_text"] = `<hr><br><br>
												   ${
														this.emailContent && this.emailContent.from_address
															? "<b>From:</b> " + this.emailContent.from_address + "<br>"
															: ""
													}
												  <b>Sent:</b> ${this.emailContent?.creation_time}<br>
												  <b>To:</b> ${this.linkifyEmail(dataSet?.to_address)}<br>
												  ${dataSet?.cc_address ? "<b>Cc:</b> " + this.linkifyEmail(dataSet?.cc_address) + "<br>" : ""}
												  <b>Subject:</b> ${this.emailContent?.subject} <br><br>
												  <span class="">${this.emailContent?.content}</span>`;
			}
			let to_address = [];
			if (this.emailContent.type != 'Send'){
				const to_address_str = (dataSet.from_address == "" || dataSet?.to_address_list?.includes(dataSet.from_address)) ? dataSet.to_address : dataSet.to_address + ',' + dataSet.from_address;
				to_address = to_address_str?.split(",") || [];
			}
			else{
				to_address = dataSet.to_address?.split(",") || [];
			}
			let current_imap_config = this.imapInputData.filter(x => x?.value == this.refData?.imap_config_id)
			if(current_imap_config && to_address.includes(current_imap_config?.[0]?.text)){
				_.pull(to_address, current_imap_config?.[0]?.text)
			}
			this.emailContent['to_address'] = to_address;
			this.emailContent["to_address_list"] = to_address;

			let to_addr_li = [];
			if (this.orgToAddress?.length > 0) {
				this.toAddress = _.cloneDeep(this.orgToAddress);
			}
			for (let key of this.toAddress) {
				to_addr_li.push(key["email"]);
			}
			for (let key of this.emailContent["to_address"]) {
				if (to_addr_li.indexOf(key) == -1) {
					this.toAddress.push({
						full_name: key,
						email: key,
					});
				}
			}
			this.orgToAddress = _.cloneDeep(this.toAddress);
			if (dataSet.cc_address) {
				this.emailContent["cc_address"] = dataSet.cc_address.split(",");
				let cc_addr_li = [];
				if (this.orgCCAddress?.length > 0) {
					this.ccAddress = _.cloneDeep(this.orgCCAddress);
				}
				for (let key of this.ccAddress) {
					cc_addr_li.push(key["email"]);
				}
				for (let key of this.emailContent["cc_address"]) {
					if (cc_addr_li.indexOf(key) == -1) {
						this.ccAddress.push({
							full_name: key,
							email: key,
						});
					}
				}
			} else {
				this.emailContent["cc_address"] = undefined;
			}
			this.orgCCAddress = _.cloneDeep(this.ccAddress);
			if (dataSet.cc_address) {
				this.emailContent["bcc_address"] = dataSet.bcc_address;
			} else {
				this.emailContent["bcc_address"] = undefined;
			}

			if (dataSet?.mail_type == "new") {
				this.is_from_disabled = false;
			} else {
				this.is_from_disabled = true;
			}

			if (append_subject == "Fw:") {
				this.emailContent["to_address"] = undefined;
				this.emailContent["cc_address"] = undefined;
				this.emailContent["bcc_address"] = undefined;
				// this.emailContent['from_address'] = 'System';
				this.showQuotedText();
			}
			if (append_subject == undefined) {
				append_subject = "Re:";
			}
			if (
				this.emailContent?.subject?.indexOf("[" + this.displayId + "]:") == -1 &&
				this.displayId &&
				!this.emailContent.subject.includes(this.displayId)
			) {
				this.emailContent["subject"] = "[" + this.displayId + "]" + this.emailContent.subject;
			}

			if (append_subject && this.emailContent?.subject?.indexOf(append_subject) == -1) {
				this.emailContent["subject"] = append_subject + this.emailContent.subject;
			}

			this.isEmailCompOpen = true;
			this.emailContent["attached_files"] = [];
			this.emailContent["signature"] = "";
			this.emailContent.imap_config_id = null;
			this.selectedSignatureValue = null;

			if (this.signatureInputData.length === 1) {
				this.signatureChange(this.signatureInputData[0]);
			}

			if (!this.is_from_disabled) {
				if (this.imapInputData?.length > 0) {
					let imap_config_id = this.imapInputData[0]?.value;
					this.imapChange({
						value: imap_config_id,
						text: this.imapInputData[0]?.value,
					});
				}
			}
			if (this.refData?.imap_config_id) {
				let imap_config_id = this.refData?.imap_config_id;
				let text = "";
				let selected_row = this.imap_list?.filter(function (element) {
					return element.config_id == imap_config_id;
				});
				if (selected_row?.length > 0) {
					text = selected_row[0]?.username;
				}
				this.imapChange({
					value: imap_config_id,
					text: text,
				});
			}
			setTimeout(() => {
				// elem.scrollIntoView();
			}, 0);
		}
		if (
			this.moduleType == "PROBLEM" ||
			this.moduleType == "REQUEST" ||
			this.moduleType == "CHANGE" ||
			this.moduleType == "RELEASE" ||
			this.moduleType == "IMACD"
		) {
			this.showQuotedTxt = false;
			this.removeQuotedTxt = false;
			this.emailContent = {};
			this.filesList = [];
			this.emailContent = _.cloneDeep(dataSet);
			if (append_subject == "Sn:") {
				//send
				this.showQuotedTxt = false;
				this.removeQuotedTxt = true;
				this.emailContent["subject"] = "[" + this.displayId + "]: " + this.refData?.basic_info?.summary;
				dataSet["subject"] = "[" + this.displayId + "]: " + this.refData?.basic_info?.summary;
			} else {
				this.emailContent["quoted_text"] = `<hr><br><br>
											${this.emailContent.from_address ? "<b>From:</b> " + this.emailContent.from_address + "<br>" : ""}
										${this.emailContent.creation_time ? "<b>Sent:</b> " + this.emailContent.creation_time + "<br>" : ""}
										<b>To:</b> ${this.linkifyEmail(dataSet.to_address)}<br>
										${dataSet.cc_address ? "<b>Cc:</b> " + this.linkifyEmail(dataSet.cc_address) + "<br>" : ""}
										<b>Subject:</b> ${this.emailContent?.subject} <br><br>
										<span class="" [innerHTML]=${this.emailContent?.content}></span>`;
				this.showQuotedTxt = false;
				this.removeQuotedTxt = false;
			}
			if (dataSet?.type == "Send" && !dataSet?.from_address && dataSet?.user_id == "-1") {
				this.emailContent["to_address"] = dataSet?.to_address?.split(";").join(",").split(",");
			} else if (this.emailContent?.from_address) {
				this.emailContent["to_address"] = dataSet?.from_address?.split(";").join(",").split(",");
			} else if (this.emailContent?.to_address) {
				this.emailContent["to_address"] = dataSet?.to_address?.split(";").join(",").split(",");
			}
			// this.emailContent["to_address"] = dataSet.to_address.split(",");
			const to_addr_li = [];
			for (const key of this.toAddress) {
				to_addr_li.push(key["email"]);
			}
			const cc_addr_li = [];
			for (const key of this.ccAddress) {
				cc_addr_li.push(key["email"]);
			}
			if (this.emailContent?.to_address) {
				for (const key of this.emailContent?.to_address) {
					if (to_addr_li.indexOf(key) == -1) {
						this.dropdown?.to_address?.push({
							full_name: key,
							email: key,
						});
						to_addr_li.push(key);
					}
					if (cc_addr_li.indexOf(key) == -1) {
						this.dropdown?.cc_address?.push({
							full_name: key,
							email: key,
						});
						cc_addr_li.push(key);
					}
				}
			}
			if (dataSet?.cc_address) {
				this.emailContent["cc_address"] = dataSet?.cc_address?.split(";").join(",").split(",");
				for (const key of this.emailContent?.cc_address) {
					if (cc_addr_li.indexOf(key) == -1) {
						this.dropdown["cc_address"].push({
							full_name: key,
							email: key,
						});
						cc_addr_li.push(key);
					}
					if (to_addr_li.indexOf(key) == -1) {
						this.dropdown["to_address"].push({
							full_name: key,
							email: key,
						});
						to_addr_li.push(key);
					}
				}
				// if (replyall) {
				// 	this.togglCcBcc("cc");
				// }
			} else {
				this.emailContent["cc_address"] = undefined;
			}
			// if (dataSet.cc_address) {
			// 	this.emailContent["bcc_address"] = dataSet.bcc_address;
			// }
			//  else {
			// 	this.emailContent["bcc_address"] = undefined;
			// }

			if (append_subject == "Fw:") {
				this.emailContent["to_address"] = undefined;
				this.emailContent["cc_address"] = undefined;
				// this.emailContent["bcc_address"] = undefined;
				// this.emailContent['from_address'] = 'System';
				this.showQuotedText();
			}
			if (append_subject == undefined) {
				append_subject = "Re:";
			}
			if (
				this.emailContent?.subject?.indexOf("[" + this.displayId + "]:") == -1 &&
				this.displayId &&
				!this.emailContent.subject.includes(this.displayId)
			) {
				this.emailContent["subject"] = "[" + this.displayId + "]" + this.emailContent.subject;
			}
			if (append_subject && this.emailContent?.subject?.indexOf(append_subject) == -1) {
				this.emailContent["subject"] = append_subject + this.emailContent.subject;
			}
			this.isEmailCompOpen = true;
			this.emailContent["attached_files"] = [];
			this.emailContent["signature"] = "";
			this.selectedSignatureValue = null;
			this.emailContent.imap_config_id = null;

			if (this.signatureInputData.length === 1) {
				this.signatureChange(this.signatureInputData[0]);
			}

			setTimeout(() => {
				elem.scrollIntoView();
			}, 0);
		}
		// var emailContent: any;
	};

	downloadAttachment = (path, file) => {
		let file_path = URL + path.split("media")[1];
		let downloadable_file_path = file_path + "/" + file;
		window.open(downloadable_file_path, "_blank");
	};

	enableEditor = (id, elem, data = undefined) => {
		this.activeButtonIndex = null;
		this.toneOptionType = null;
		try {
			const today = new Date(new Date().toLocaleString("en-US", { timeZone: this.userTZ }));
			this.dateTimeOptions["defaultDate"] = today;
		} catch (e) {}
		this.notEmptyCommData = false;
		if (id == "Resolution") {
			this.editorId = id;
			this.handleEditorData.emit({
				type: "Resolution",
				data: {},
			});
		}
		if (id == "Approval") {
			this.handleEditorData.emit({
				type: "Approval",
				data: {},
			});
		}
		if (id == "Review") {
			this.handleEditorData.emit({
				type: "Review",
				data: {},
			});
		}
		if (id == "Closure Note") {
			this.handleEditorData.emit({
				type: "Closure Note",
				data: {},
			});
		}

		if (id == "approvealSet") {
			this.editorId = "Approval";
			this.showEditor = true;
		} else if (id == "closureNoteSet") {
			this.editorId = "Closure Note";
			this.showEditor = true;
			if (!this.refData?.basic_info?.status?.is_pir) {
				this.erichTextData = data;
			}
		} else {
			this.editorId = id;
			this.showEditor = true;
		}

		setTimeout(() => {
			// elem.scrollIntoView();
			// this.scrolltop = this.scrollMe?.nativeElement.scrollHeight+1300;
		}, 0);
	};

	loadNextComments = (actions, is_comment_type_set = true) => {
		this.page = this.page + 1;
		const payload = {
			page: this.page,
			items_per_page: this.selectedOption,
			firstKey: this.firstKey,
			lastKey: this.lastKey,
			ref_id: this.refId,
			comments: this.showComments,
			emails: this.showEmails,
		};
		if (this.moduleType === "INCIDENT") {
			this._incidentViewService.getMailConversations(payload).subscribe(
				(response) => {
					if (response) {
						const newComments = response?.data;
						this.finished = false;
						if (newComments.length > 0) {
							this.mail_list = this.mail_list.concat(newComments);
							if (this.latestEmail == undefined) {
								this.latestEmail = response.latest_email;
							}
						}
						this.finished = true;
						this.notScrolly = true;
						this.commDataCount = this.mail_list.length;
						this.commDataTotalCount = response["count"];
						if (this.commDataCount === this.commDataTotalCount) {
							this.notEmptyCommData = false;
						}
						// this.resetCommunicationData()
						// this.selectedActions = this.selectedActions;
						// this.mail_list.sort(function (a, b) {
						// 	return a.creation_time_int - b.creation_time_int
						// })
						// setTimeout(() => {
						// 	this.scrolltop = this.scrollMe?.nativeElement.scrollHeight-(this.scrollMe?.nativeElement.scrollHeight -100);
						//   }, 0);
					}
				},
				(error) => {
					//todo
				}
			);
		} else if (this.moduleType === "PROBLEM") {
			this._problemService.getMailConversations(payload).subscribe((response) => {
				if (response) {
					const newComments = response?.data;
					if (newComments.length > 0) {
						this.mail_list = this.mail_list.concat(newComments);
						if (this.latestEmail == undefined) {
							this.latestEmail = response.latest_email;
						}
					}
					this.copyOfAllCard = response?.data;
					this.latestEmail = response?.latest_email;
					this.finished = false;
					if (this.mail_list.length === 0) {
						this.notEmptyCommData = false;
						this.finished = true;
					}
					this.finished = true;
					this.notScrolly = true;
					// this.mail_list.sort(function (a, b) {
					// 	return a.creation_time_int - b.creation_time_int
					//   })
					this.commDataCount = this.mail_list.length;
					this.commDataTotalCount = response["count"];
					if (this.commDataCount === this.commDataTotalCount) {
						this.notEmptyCommData = false;
					}
					// setTimeout(() => {
					// 	this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
					// }, 0);
				}
			});
		} else if (this.moduleType === "REQUEST") {
			this._requestViewService.getMailConversations(payload).subscribe((response) => {
				if (response) {
					const newComments = response?.data;
					if (newComments.length > 0) {
						newComments.forEach((element) => {
							element.content = element.content.replace(/<a id='requestURL'.*?<\/a>/i, "");
						});
						this.mail_list = this.mail_list.concat(newComments);
						if (this.latestEmail == undefined) {
							this.latestEmail = response.latest_email;
						}
					}

					this.copyOfAllCard = response?.data;
					this.latestEmail = response?.latest_email;
					this.finished = false;
					if (this.mail_list.length === 0) {
						this.notEmptyCommData = false;
						this.finished = true;
					}
					this.finished = true;
					this.notScrolly = true;
					// this.mail_list.sort(function (a, b) {
					// 	return a.creation_time_int - b.creation_time_int
					//   })
					this.commDataCount = this.mail_list.length;
					this.commDataTotalCount = response["count"];
					if (this.commDataCount === this.commDataTotalCount) {
						this.notEmptyCommData = false;
					}
					// setTimeout(() => {
					// 	this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
					// }, 0);
				}
			});
		} else if (this.moduleType === "CHANGE") {
			this._changeViewService.getMailConversations(payload).subscribe((response) => {
				if (response) {
					const newComments = response?.data;
					if (newComments.length > 0) {
						this.mail_list = this.mail_list.concat(newComments);
						if (this.latestEmail == undefined) {
							this.latestEmail = response.latest_email;
						}
					}

					this.copyOfAllCard = response?.data;
					this.latestEmail = response?.latest_email;
					this.finished = false;
					if (this.mail_list.length === 0) {
						this.notEmptyCommData = false;
						this.finished = true;
					}
					this.finished = true;
					this.notScrolly = true;
					// this.mail_list.sort(function (a, b) {
					// 	return a.creation_time_int - b.creation_time_int
					//   })
					this.commDataCount = this.mail_list.length;
					this.commDataTotalCount = response["count"];
					if (this.commDataCount === this.commDataTotalCount) {
						this.notEmptyCommData = false;
					}
					// setTimeout(() => {
					// 	this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
					// }, 0);
				}
			});
		} else if (this.moduleType === "RELEASE") {
			this._releaseService.getMailConversations(payload).subscribe((response) => {
				if (response) {
					const newComments = response?.data;
					if (newComments.length > 0) {
						this.mail_list = this.mail_list.concat(newComments);
						if (this.latestEmail == undefined) {
							this.latestEmail = response.latest_email;
						}
					}
					this.copyOfAllCard = response?.data;
					this.latestEmail = response?.latest_email;
					if (this.mail_list.length === 0) {
						this.notEmptyCommData = false;
					}
					this.commDataCount = this.mail_list.length;
					this.commDataTotalCount = response["count"];
					if (this.commDataCount === this.commDataTotalCount) {
						this.notEmptyCommData = false;
					}
					// setTimeout(() => {
					// 	this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
					// }, 0);
				}
			});
		} else if (this.moduleType === "IMACD") {
			this._imacdService.getMailConversations(payload).subscribe((response) => {
				if (response) {
					const newComments = response?.data;
					if (newComments.length > 0) {
						this.mail_list = this.mail_list.concat(newComments);
						if (this.latestEmail == undefined) {
							this.latestEmail = response.latest_email;
						}
					}
					this.copyOfAllCard = response?.data;
					this.latestEmail = response?.latest_email;
					if (this.mail_list.length === 0) {
						this.notEmptyCommData = false;
					}
					this.commDataCount = this.mail_list.length;
					this.commDataTotalCount = response["count"];
					if (this.commDataCount === this.commDataTotalCount) {
						this.notEmptyCommData = false;
					}
					// setTimeout(() => {
					// 	this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
					// }, 0);
				}
			});
		}
	};

	getMailConversations(actions, is_comment_type_set = true) {
		this.selectedActions = [];
		const payload = {
			page: this.page,
			items_per_page: this.selectedOption,
			ref_id: this.refId,
			comments: this.showComments,
			emails: this.showEmails,
			sms: this.showSMS,
			comment_type: is_comment_type_set ? this.internalNoteFilterSelection.id : undefined,
		};
		this.getRefMailConversations.emit({
			payload,
			comment_flag: is_comment_type_set,
			actions,
		});

		// this._incidentViewService.getMailConversations(payload).subscribe((response) => {

		// });
	}

	handleMailConversionResponse(event) {
		if (event.response) {
			if (event.response?.data?.length > 0) {
				this.mail_list = event.response?.data;
			}
			this.copyOfAllCard = event.response?.data;
			this.latestEmail = event.response?.latest_email;
			this.finished = false;
			if (this.mail_list.length === 0) {
				this.notEmptyCommData = false;
				this.finished = true;
			}
			this.finished = true;
			this.notScrolly = true;
			// this.mail_list.sort(function (a, b) {
			// 	return a.creation_time_int - b.creation_time_int
			//   })
			this.commDataCount = this.mail_list.length;
			this.commDataTotalCount = event.response["count"];
			this.selectedActions = event.actions;
			if (event.comment_flag == false) {
				this.internalNoteFilter(this.internalNoteFilterSelection);
			}
			if (this.commDataCount === this.commDataTotalCount) {
				this.notEmptyCommData = false;
			} else if (this.commDataTotalCount > 10) {
				this.notEmptyCommData = true;
			}
			setTimeout(() => {
				this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
			}, 0);
		}
	}

	stringToColor(string) {
		if (string && string != undefined) {
			let hash = 0;
			let i;

			/* eslint-disable no-bitwise */
			for (i = 0; i < string.length; i += 1) {
				hash = string.charCodeAt(i) + ((hash << 5) - hash);
			}

			let colour = "#";

			for (i = 0; i < 3; i += 1) {
				const value = (hash >> (i * 8)) & 0xff;
				colour += `00${value.toString(16)}`.substr(-2);
			}
			/* eslint-enable no-bitwise */

			return colour;
		} else {
			return "#2d2d2d";
		}
	}

	isCommunicationClosed() {
		if (this.refData != undefined) {
			let stateObj = this.ref_data_copy.basic_info.state;
			let stateId = parseInt(stateObj.id);
			if (
				stateId == 5 ||
				this.communicationFilterSelection.keyName == "Email" ||
				this.communicationFilterSelection.keyName == "SMS"
			) {
				return false;
			}
		}
		return true;
	}

	checkCommunicationState() {
		if (this.refData != undefined) {
			let stateObj = this.ref_data_copy.basic_info.state;
			let stateId = parseInt(stateObj.id);
			if (
				stateId == 4 ||
				stateId == 5 ||
				stateId == 6 ||
				this.communicationFilterSelection.keyName == "Email" ||
				this.communicationFilterSelection.keyName == "SMS" ||
				this.disable_config
			) {
				return false;
			}
		}
		if (this.is_ssp_login) {
			return false;
		}
		return true;
	}

	checkReleaseCondition() {
		let filtered_status = this.transition_status?.filter((e) => e["state_id"] == this.stateKeyIdMap["Close"]);
		if (
			this.permissions?.edit &&
			filtered_status?.length > 0 &&
			!this.ref_data_copy?.is_exit &&
			this.ref_data_copy?.basic_info?.state?.id !== 6 &&
			!this.is_ssp_login
		) {
			return true;
		}
	}

	linkifyEmail = (emails) => {
		let result = "";
		if (emails) {
			let emailsArray = emails.split(";");
			for (let email of emailsArray) {
				result += `<a href="mailto:${email}">${email};</a>`;
			}
		}
		return result;
	};

	handleEvent(e) {}

	onCancelButtonClick() {
		this.selectedText = null;
		this.rephrase_error = false;
		this.resetCommunicationData();
		this.showEditor = false;
		this.showReplyBox = true;
		this.erichTextData = "";
		this.resolution_date = undefined;
		this.isPrivate = "false";
		this.notEmptyCommData = true;
		this.workDuration = {
			day: undefined,
			hr: undefined,
			min: undefined,
		};
		setTimeout(() => {
			this.scrolltop = this.scrollMe?.nativeElement.scrollHeight;
		}, 0);
	}
	onSelectionChange = (event) => {
		if (!this.quillEditor || this.quillEditor == undefined) {
			this.quillEditor = event?.editor;
		}
		let range = event.range;
		if (range?.length > 0) {
			this.selectedText = event?.editor?.getText(range?.index, range?.length);
			let image_sel = false;
			this.isTooltipShow = true;
			let selContent = event?.editor?.getContents(range?.index, range?.length)?.ops;
			for (let index = 0; index < selContent?.length; index++) {
				if (selContent[index]?.insert.hasOwnProperty("image")) {
					image_sel = true;
					this.selectedText = null;
					this._toastr.error(
						this._translateService.instant(this._translateService.instant("Message.msg_exclude_image")),
						"Error",
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					break;
				}
			}
			if (!image_sel) {
				this.quillCursor["cursorPosition"] = this.quillEditor.getSelection()?.index;
				this.quillCursor["cursorSelRange"] = this.quillEditor.getSelection()?.length;
			}
		}
	};
	handleRephrase = (type) => {
		try {
			const clipboardDelta = this.quillEditor.clipboard.convert(this.rephrasedText);
			const formatted_delta = new this.delta()
				.retain(this.quillCursor?.cursorPosition)
				.delete(this.quillCursor?.cursorSelRange)
				.concat(clipboardDelta);
			this.quillEditor.updateContents(formatted_delta, "user");
			this.quillCursor["cursorPosition"] = this.quillEditor.getSelection()?.index;
			this.quillCursor["cursorSelRange"] = this.quillEditor.getSelection()?.length;
			this.activeButtonIndex = null;
			this.toneOptionType = null;
			this.closeModel();
		} catch (e) {
			if (type == "mail") {
				if (this.emailContent?.reply_mail) {
					this.emailContent["reply_mail"] = this.emailContent["reply_mail"] + this.rephrasedText;
				} else {
					this.emailContent["reply_mail"] = this.rephrasedText;
				}
			} else if (type == "comment") {
				if (this.erichTextData) {
					this.erichTextData = this.erichTextData + this.rephrasedText;
				} else {
					this.erichTextData = this.rephrasedText;
				}
			}
			this.activeButtonIndex = null;
			this.toneOptionType = null;
			this.closeModel();
		}
	};
	getEnrichTextData = (event) => {
		this.quillEditor = event.editor;
		// const tmpHTML = event.html.replace("img", this.imgHeightWidthStr);
		// this.erichTextData = tmpHTML;
		const tempDiv = document.createElement("div");
		tempDiv.innerHTML = event.html;
		const imgElements = tempDiv.querySelectorAll("img");
		imgElements.forEach((img) => {
			img.setAttribute("height", "400px");
			img.setAttribute("width", "100%");
		});
		const tmpHTML = tempDiv.innerHTML;
		this.erichTextData = tmpHTML;
		this.isTooltipShow = true;
	};
	closeEditor = (opt, data) => {
		let final_content = "";
		// if (!data.form.valid) {
		// 	return;
		// }
		if (data.form.valid) {
			this.selectedText = null;
			this.rephrase_error = false;
			if (opt == "Closure Note") {
				this.refData.close_info.closure_note = this.erichTextData;
				this.setClosureNoteData.emit({
					form_ref: data,
					refdata: this.refData,
					custom_field_config: this.custom_field_config,
				});
				this.showEditor = false;
				return true;
			}
			if (opt == "Add Notes") {
				final_content = this.erichTextData;
			}
			if (opt == "Symptoms") {
				final_content = this.erichTextData;
			}
			if (opt == "Analyse") {
				final_content = this.erichTextData;
			}
			if (opt == "Diagnosis") {
				final_content = this.erichTextData;
			}
			if (opt == "Worklog") {
				final_content = this.erichTextData;
			}
			if (opt == "Resolution") {
				let resolution = this.erichTextData;
				this.resolution_date = this.refData.inci_resolution_time[0];
				// this.addIncident.inci_resolution_time = this.resolution_date[0]
				// this.refData.basic_info.status.state_id = 4
				this.editRefData.emit({
					resolution_date: this.refData.inci_resolution_time,
					resolution,
					refdata: this.refData,
					custom_field_config: this.custom_field_config,
				});
			}
			if (opt == "Root Cause") {
				final_content = this.erichTextData;
				// final_content = `Root Cause: ` + root_cause + `<br>Trouble Reason: ` + this.trouble_reason + `<br>Recovery Action: ` + this.recovery_action
			}
			if (opt == "Approval") {
				this.setApprovalData.emit({
					data: this.erichTextData,
					refdata: this.refData,
					approval_state: this.is_accept_button ? 1 : 2,
				});
				return;
			}
			if (opt == "Review") {
				this.setReviewData.emit({
					data: this.erichTextData,
					refdata: this.refData,
					review_state: this.is_accept_button ? 1 : 2,
				});
				return;
			}
			if (this.isPrivate == "true") {
				this.isPrivate = true;
			} else {
				this.isPrivate = false;
			}
			let payload = {
				ref_id: this.refId,
				display_id: this.displayId,
				rich_text_content: {
					description: this.erichTextData,
					type: opt,
					is_private: this.isPrivate,
					work_duration: this.workDuration,
					resolution_date: this.resolution_date,
				},
			};

			//call parent method function for add reachtextdata for ref
			this.addRichTextData.emit(payload);
			this.notEmptyCommData = true;
			setTimeout(() => {
				this.scrollable.scrollTo({
					top: 0,
					duration: 0,
				});
			}, 400);
		}
	};

	getRichTextData() {
		this.rich_text_list = this.rich_text_data;
		for (let idx in this.rich_text_list) {
			this.rich_text_list[idx]["without_dom_sanitizer_descr"] = this.rich_text_list[idx]["description"];
			this.rich_text_list[idx]["description"] = this._domSanitizerService.bypassSecurityTrustHtml(
				this.rich_text_list[idx]["description"]
			);
		}
	}

	resetCommunicationData() {
		this.getRichTextData();
		this.page = 1;
		this.commDataTotalCount = 0;
		let comment_type;
		if (this.communicationFilterSelection.keyName == "InternalNote") {
			this.showComments = true;
			this.showEmails = false;
			this.showSMS = false;
			comment_type = false;
		} else {
			this.showComments = true;
			this.showEmails = true;
			this.showSMS = true;
			comment_type = true;
		}
		this.getMailConversations(this.selectedActions, comment_type);
		setTimeout(() => {
			this.scrolltop = this.scrollMe?.nativeElement.scrollHeight;
		}, 0);
		this.erichTextData = "";
		this.resolution_date = undefined;
		this.showEditor = false;
		this.isPrivate = "false";
		this.workDuration = {
			day: undefined,
			hr: undefined,
			min: undefined,
		};
		this.showEditor = false;
		this.showReplyBox = true;
		// setTimeout(() => {
		// 	this.scrolltop = this.scrollMe?.nativeElement.scrollHeight + 100;
		// }, 0);
	}

	/**
	 * Toggle CC & BCC
	 *
	 * @param toggleRef
	 */
	togglCcBcc(toggleRef) {
		if (toggleRef == "cc") {
			this.isOpenCC = !this.isOpenCC;
		} else {
			this.isOpenBCC = !this.isOpenBCC;
		}
	}

	created(event) {}

	focus($event) {
		this.focused = true;
		this.blured = false;
	}

	blur($event) {
		this.focused = false;
		this.blured = true;
	}

	getEmailContent = (evt) => {
		this.isTooltipShow = true;
		this.quillEditor = evt.editor;
		this.emailContent.reply_mail = evt.html;
	};

	showQuotedText() {
		this.showQuotedTxt = true;
		if (this.emailContent["reply_mail"]) {
			this.emailContent["reply_mail"] = this.emailContent["reply_mail"] + this.emailContent["quoted_text"];
		} else {
			this.emailContent["reply_mail"] = this.emailContent["quoted_text"];
		}
	}

	removeQuotedText() {
		this.removeQuotedTxt = true;
		this.emailContent["attached_files"] = [];
	}

	// removing the file from POP UI.
	removeAttachedFile = function (index, filesList) {
		filesList.splice(index, 1);
	};
	fileUpload = (evt) => {
		var files = evt.target.files;
		var new_file_len = evt.target.files.length;
		var added_file_len = this.filesList.length;
		for (var i = 0; i < new_file_len; i++) {
			var file = files[i];
			var pos = added_file_len + i;
			var reader = new FileReader();
			reader.onload = (function (filesList, file, pos) {
				return function (e) {
					var data = e.target.result;
					filesList[pos] = {
						file_name: file.name,
						file_type: file.type,
						file_size: file.size,
						file_data: data,
						file_date: new Date(),
						//'file_date': this.datepipe.transform(new Date(),'MMM dd,yyyy hh:mm a')
					};
				};
			})(this.filesList, file, pos);
			reader.readAsDataURL(file);
		}
	};

	/**
	 * Close Compose
	 */
	closeMailComp() {
		this.isEmailCompOpen = false;
		this.selectedText = null;
		this.rephrase_error = false;
		setTimeout(() => {
			this.scrolltop = this.scrollMe?.nativeElement.scrollHeight;
		}, 0);
	}

	// updateFieldName(evt, id, dropdown_name, set_field, ref_field) {

	// }
	saveSentEmail = (mailReplyForm, data, files) => {
		if (mailReplyForm.form.valid) {
			this.selectedText = null;
			this.rephrase_error = false;
			if (this.moduleType == "INCIDENT") {
				data["new_reply_mail"] = data["reply_mail"];
				data["reply_mail_modified"] = 0;
				if (this.emailContent["reply_mail"] == undefined) {
					data["reply_mail_modified"] = 0;
				}
				data["newly_added_files"] = files;
				if (this.showQuotedTxt == false && this.removeQuotedTxt == false) {
					if (this.emailContent["reply_mail"]) {
						this.emailContent["reply_mail"] =
							this.emailContent["reply_mail"] + this.emailContent["quoted_text"];
					} else {
						this.emailContent["reply_mail"] = this.emailContent["quoted_text"];
					}
				}
				var messageParam = {
					ref_id: this.refId,
					display_id: this.displayId,
					imap_config_id: this.emailContent.imap_config_id,
					email_content: data,
				};
				this._incidentViewService.saveConversation(messageParam).subscribe((response: any) => {
					if (response.status == "success") {
						this.isOpenCC = false;
						this.isOpenBCC = false;
						this._toastr.success(
							this._translateService.instant(response["message"]),
							this._translateService.instant("Message.msg_mail_sent"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						this.resetCommunicationData();
						this.notEmptyCommData = true;
						this.page = 1;
						this.commDataTotalCount = 0;
						// this.getMailConversations(this.selectedActions);
						this.showReplyBox = true;
						this.isEmailCompOpen = false;
						setTimeout(() => {
							this.scrollable.scrollTo({
								top: 0,
								duration: 0,
							});
						}, 400);
					} else if (response["status"] == "error") {
						this._toastr.error(this._translateService.instant(response["message"]), "Error", {
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						});
					}
				});
			} else if (this.moduleType == "PROBLEM") {
				data["new_reply_mail"] = data["reply_mail"];
				data["reply_mail_modified"] = 0;
				if (this.emailContent["reply_mail"] == undefined) {
					data["reply_mail_modified"] = 0;
				}
				data["newly_added_files"] = files;
				if (this.showQuotedTxt == false && this.removeQuotedTxt == false) {
					if (this.emailContent["reply_mail"]) {
						this.emailContent["reply_mail"] =
							this.emailContent["reply_mail"] + this.emailContent["quoted_text"];
					} else {
						this.emailContent["reply_mail"] = this.emailContent["quoted_text"];
					}
				}
				const messageParam = {
					ref_id: this.refId,
					display_id: this.displayId,
					imap_config_id: this.emailContent.imap_config_id,
					email_content: data,
				};
				this._problemService.saveConversation(messageParam).subscribe((response: any) => {
					if (response.status == "success") {
						this.isOpenCC = false;
						this.isOpenBCC = false;
						this._toastr.success(
							this._translateService.instant(response["message"]),
							this._translateService.instant("Message.msg_save_success"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						this.resetCommunicationData();
						this.notEmptyCommData = true;
						this.page = 1;
						this.commDataTotalCount = 0;
						// this.getMailConversations(this.selectedActions);
						this.showReplyBox = true;
						this.isEmailCompOpen = false;
						setTimeout(() => {
							this.scrollable.scrollTo({
								top: 0,
								duration: 0,
							});
						}, 400);
					} else if (response["status"] == "error") {
						this._toastr.error(this._translateService.instant(response["message"]), "Error", {
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						});
					}
				});
			} else if (this.moduleType == "REQUEST") {
				data["new_reply_mail"] = data["reply_mail"];
				data["reply_mail_modified"] = 0;
				if (this.emailContent["reply_mail"] == undefined) {
					data["reply_mail_modified"] = 0;
				}
				data["newly_added_files"] = files;
				if (this.showQuotedTxt == false && this.removeQuotedTxt == false) {
					if (this.emailContent["reply_mail"]) {
						this.emailContent["reply_mail"] =
							this.emailContent["reply_mail"] + this.emailContent["quoted_text"];
					} else {
						this.emailContent["reply_mail"] = this.emailContent["quoted_text"];
					}
				}
				const messageParam = {
					ref_id: this.refId,
					display_id: this.displayId,
					imap_config_id: this.emailContent.imap_config_id,
					email_content: data,
				};
				this._requestViewService.saveConversation(messageParam).subscribe((response: any) => {
					if (response.status == "success") {
						this.isOpenCC = false;
						this.isOpenBCC = false;
						this._toastr.success(
							this._translateService.instant(response["message"]),
							this._translateService.instant("Message.msg_mail_sent"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						this.resetCommunicationData();
						this.notEmptyCommData = true;
						this.page = 1;
						this.commDataTotalCount = 0;
						// this.getMailConversations(this.selectedActions);
						this.showReplyBox = true;
						this.isEmailCompOpen = false;
						setTimeout(() => {
							this.scrollable.scrollTo({
								top: 0,
								duration: 0,
							});
						}, 400);
						if (files) {
							// this.loadAttachments("");
						}
					} else if (response["status"] == "error") {
						this._toastr.error(this._translateService.instant(response["message"]), "Error", {
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						});
					}
				});
			} else if (this.moduleType == "CHANGE") {
				data["new_reply_mail"] = data["reply_mail"];
				data["reply_mail_modified"] = 0;
				if (this.emailContent["reply_mail"] == undefined) {
					data["reply_mail_modified"] = 0;
				}
				data["newly_added_files"] = files;
				if (this.showQuotedTxt == false && this.removeQuotedTxt == false) {
					if (this.emailContent["reply_mail"]) {
						this.emailContent["reply_mail"] =
							this.emailContent["reply_mail"] + this.emailContent["quoted_text"];
					} else {
						this.emailContent["reply_mail"] = this.emailContent["quoted_text"];
					}
				}
				var messageParam = {
					ref_id: this.refId,
					display_id: this.displayId,
					imap_config_id: this.emailContent.imap_config_id,
					email_content: data,
				};
				this._changeViewService.saveConversation(messageParam).subscribe((response: any) => {
					if (response.status == "success") {
						this.isOpenCC = false;
						this.isOpenBCC = false;
						this._toastr.success(
							this._translateService.instant(response["message"]),
							this._translateService.instant("Message.msg_mail_sent"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						this.resetCommunicationData();
						this.notEmptyCommData = true;
						this.page = 1;
						this.commDataTotalCount = 0;
						// this.getMailConversations(this.selectedActions);
						this.showReplyBox = true;
						this.isEmailCompOpen = false;
						setTimeout(() => {
							this.scrollable.scrollTo({
								top: 0,
								duration: 0,
							});
						}, 400);
						if (files) {
							// this.loadAttachments("");
						}
					} else if (response["status"] == "error") {
						this._toastr.error(this._translateService.instant(response["message"]), "Error", {
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						});
					}
				});
			} else if (this.moduleType == "RELEASE") {
				data["new_reply_mail"] = data["reply_mail"];
				data["reply_mail_modified"] = 0;
				if (this.emailContent["reply_mail"] == undefined) {
					data["reply_mail_modified"] = 0;
				}
				data["newly_added_files"] = files;
				if (this.showQuotedTxt == false && this.removeQuotedTxt == false) {
					if (this.emailContent["reply_mail"]) {
						this.emailContent["reply_mail"] =
							this.emailContent["reply_mail"] + this.emailContent["quoted_text"];
					} else {
						this.emailContent["reply_mail"] = this.emailContent["quoted_text"];
					}
				}
				var messageParam = {
					ref_id: this.refId,
					display_id: this.displayId,
					imap_config_id: this.emailContent.imap_config_id,
					email_content: data,
				};
				this._releaseService.saveConversation(messageParam).subscribe((response: any) => {
					if (response.status == "success") {
						this.isOpenCC = false;
						this.isOpenBCC = false;
						this._toastr.success(
							this._translateService.instant(response["message"]),
							this._translateService.instant("Message.msg_mail_sent"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						this.resetCommunicationData();
						this.notEmptyCommData = true;
						this.page = 1;
						this.commDataTotalCount = 0;
						// this.getMailConversations(this.selectedActions);
						this.showReplyBox = true;
						this.isEmailCompOpen = false;
						setTimeout(() => {
							this.scrollable.scrollTo({
								top: 0,
								duration: 0,
							});
						}, 400);
						// if (files) {
						// 	this.loadAttachments("");
						// }
					} else if (response["status"] == "error") {
						this._toastr.error(this._translateService.instant(response["message"]), "Error", {
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						});
					}
				});
			} else if (this.moduleType == "IMACD") {
				data["new_reply_mail"] = data["reply_mail"];
				data["reply_mail_modified"] = 0;
				if (this.emailContent["reply_mail"] == undefined) {
					data["reply_mail_modified"] = 0;
				}
				data["newly_added_files"] = files;
				if (this.showQuotedTxt == false && this.removeQuotedTxt == false) {
					if (this.emailContent["reply_mail"]) {
						this.emailContent["reply_mail"] =
							this.emailContent["reply_mail"] + this.emailContent["quoted_text"];
					} else {
						this.emailContent["reply_mail"] = this.emailContent["quoted_text"];
					}
				}
				var messageParam = {
					ref_id: this.refId,
					display_id: this.displayId,
					imap_config_id: this.emailContent.imap_config_id,
					email_content: data,
				};
				this._imacdService.saveConversation(messageParam).subscribe((response: any) => {
					if (response.status == "success") {
						this.isOpenCC = false;
						this.isOpenBCC = false;
						this._toastr.success(
							this._translateService.instant(response["message"]),
							this._translateService.instant("Message.msg_mail_sent"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						this.resetCommunicationData();
						this.notEmptyCommData = true;
						this.page = 1;
						this.commDataTotalCount = 0;
						// this.getMailConversations(this.selectedActions);
						this.showReplyBox = true;
						this.isEmailCompOpen = false;
						setTimeout(() => {
							this.scrollable.scrollTo({
								top: 0,
								duration: 0,
							});
						}, 400);
						// if (files) {
						// 	this.loadAttachments("");
						// }
					} else if (response["status"] == "error") {
						this._toastr.error(this._translateService.instant(response["message"]), "Error", {
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						});
					}
				});
			}
		}
	};

	addEmailToDropdown(name) {
		return {
			full_name: name,
			email: name,
			tag: true,
		};
	}

	onClosureStatusChange(data) {
		this.onStatusChange.emit(data);
		this.refData["basic_info"]["status"] = data;
		this.refData["basic_info"]["state"] = this.stateKeyIdMap[data?.state_id];
		if (this.refData?.basic_info?.state?.id != 5) {
			//not close
			this.refData.close_info = {};
			this.refData["actual_closure_date"] = null;
			this.refData["agreed_closure_date"] = null;
		}
	}

	onTemplateClick(message, i) {
		this.emailContent.reply_mail = message;
	}

	valueChange = (eve) => {
		this.selectTreeValue = eve.value;
		this.emailContent.reply_mail = eve.text;
	};
	signatureChange = (eve) => {
		this.selectedSignatureValue = eve.value;
		let prev_text = this.emailContent.signature;
		this.emailContent.reply_mail = this.emailContent.reply_mail?.replace(prev_text, "");
		if (this.emailContent.reply_mail != undefined) {
			this.emailContent.reply_mail = this.emailContent.reply_mail + "<p><br></p><p><br></p>" + eve.value;
			this.emailContent.signature = "<p><br></p><p><br></p>" + eve.value;
		} else {
			this.emailContent.reply_mail = "<p><br></p><p><br></p>" + eve.value;
			this.emailContent.signature = "<p><br></p><p><br></p>" + eve.value;
		}
	};

	showTemplateModal = (eve) => {
		this.previewData = eve;
		this._modalService.open(this.previewResponse, {
			centered: true,
			animation: false,
			size: "md",
		});
	};
	showSignatureModal = (eve) => {
		this.previewData = eve;
		this._modalService.open(this.previewSignature, {
			centered: true,
			animation: false,
			size: "md",
		});
	};

	useItTemplate(data) {
		this.selectTreeValue = data.value;
		this.emailContent.reply_mail = data.text;
		this._modalService.dismissAll();
	}

	useItSignature(eve) {
		this.selectedSignatureValue = eve.value;
		let prev_text = this.emailContent.signature;
		this.emailContent.reply_mail = this.emailContent.reply_mail?.replace(prev_text, "");
		if (this.emailContent.reply_mail != undefined) {
			this.emailContent.reply_mail = this.emailContent.reply_mail + "<p><br></p><p><br></p>" + eve.value;
			this.emailContent.signature = "<p><br></p><p><br></p>" + eve.value;
		} else {
			this.emailContent.reply_mail = "<p><br></p><p><br></p>" + eve.value;
			this.emailContent.signature = "<p><br></p><p><br></p>" + eve.value;
		}
		this._modalService.dismissAll();
	}

	toggleSidebar(name): void {
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
		this.showAddResponseSidebar = true;
		this.showQuickResponseSidbar = true;
	}

	onHideSidebar = (e) => {
		this.showAddResponseSidebar == false;
	};
	onHideQuickResponseSidbar = (e) => {
		this.showQuickResponseSidbar = false;
	};

	curruntResponseTemp(data) {
		this.currentItem = data;
	}

	curruntResponseTempEvent(data) {
		this.event = data;
	}

	editTemplate(data) {
		window.open("/account-settings?signature=" + true, "_blank");
	}

	imapChange = (eve) => {
		this.selectedIMAPValue = eve.value;
		this.imapSelectedText = eve.text;
		this.emailContent.imap_config_id = this.selectedIMAPValue;
		this.refData.imap_config_id = this.selectedIMAPValue;
	};
	resetRephraseError = () => {
		this.rephrase_error = false;
	};
	paraphraseText = (type) => {
		if (this.isEmailCompOpen) this.prevReplyData = this.emailContent.reply_mail;
		else this.prevReplyData = this.erichTextData;

		if (this.selectedText) {
			// this.quillEditor.setSelection(this.quillCursor?.cursorPosition, this.quillCursor?.cursorSelRange)
			this.rephraseDisable = true;
			let payload = {
				content: this.selectedText,
				tone_type: this.toneType,
				tone_optn_type: this.toneOptionType,
				ref_id: this.refId,
				wss_uuid: "content_rephrase",
			};
			this._workspaceService
				.paraphraseText(payload)
				.pipe(
					map((response: any) => {
						if (response?.status == "success") {
							// this._toastr.success(
							// 	this._translateService.instant(response?.message),
							// 	this._translateService.instant("Message.msg_save_success"),
							// 	{
							// 		toastClass: "toast ngx-toastr",
							// 		closeButton: true,
							// 		positionClass: "toast-top-right",
							// 	}
							// );
							// this.rephrasedText = response?.paraphrased_text
							// this.rephraseDisable = false;
						} else {
							if (this.isEmailCompOpen) this.emailContent["reply_mail"] = this.prevReplyData;
							else this.erichTextData = this.prevReplyData;
							this.rephrase_error = true;
							this.rephraseDisable = false;
						}
					}),
					catchError((error) => {
						this.rephraseDisable = false;
						this.rephrase_error = true;
						if (this.isEmailCompOpen) this.emailContent["reply_mail"] = this.prevReplyData;
						else this.erichTextData = this.prevReplyData;
						return throwError(error.statusText);
					})
				)
				.subscribe();
		}
	};

	undoParaphraseText = () => {
		if (this.isEmailCompOpen) this.emailContent["reply_mail"] = this.prevReplyData;
		else this.erichTextData = this.prevReplyData;
	};
	ngOnDestroy() {
		try {
			if (this._unsubscribeAll) {
				this._unsubscribeAll.complete();
			}
		} catch (e) {}
	}
	openPopover() {
		if (!this.popover.isOpen()) {
			this.popover.open();
		} else {
			this.popover.close();
		}
	}
	closeModel() {
		this.popover.close();
		this.activeButtonIndex = null;
		this.rephraseDisable = false;
		this.selectedText = null;
		this.rephrasedText = null;
	}
	searchToRequester = ($event) => {
		if (this.timer) {
			clearTimeout(this.timer);
		}
		var that = this;
		this.timer = setTimeout(function () {
			let searchStr = $event.target?.value;
			this.requesterList = [];
			if (searchStr == searchStr?.length < 0) {
				this.toSearchFalse = true;
			}
			if (searchStr != undefined && searchStr?.length > 2) {
				// that.toSearchFalse = false;
				that.getRequesterList(searchStr, this.orgToAddress, "to");
			}
		}, 1000);
	};

	searchCCRequester = ($event) => {
		if (this.timer) {
			clearTimeout(this.timer);
		}
		var that = this;
		this.timer = setTimeout(function () {
			let searchStr = $event.target?.value;
			this.requesterList = [];
			if (searchStr == searchStr?.length < 0) {
				this.ccSearchFalse = true;
			}
			if (searchStr != undefined && searchStr?.length > 2) {
				that.getRequesterList(searchStr, this.orgCCAddress, "cc");
			}
		}, 1000);
	};
	getRequesterList(searchStr, orgAddressList, key) {
		let requesterList = [];
		this._requestViewService
			.getRequesterList({ full_name: encodeURIComponent(searchStr), is_asset_search: false })
			.subscribe((response) => {
				this.ccSearchFalse = false;
				this.toSearchFalse = false;
				this.requesterList = response;
				// Get email addresses from requesterList
				const emailList: string[] = this.requesterList?.map((ele) => ele?.email).filter(Boolean);
				let to_address = _.cloneDeep(orgAddressList);
				// Add missing email addresses from orgToAddress to requesterList
				to_address?.forEach((element) => {
					if (!emailList.includes(element?.email)) {
						this.requesterList.push({
							full_name: element?.full_name,
							email: element?.email,
						});
					}
				});
				if (key == "to") {
					this.toAddress = _.cloneDeep(this.requesterList);
				} else {
					this.ccAddress = _.cloneDeep(this.requesterList);
				}
			});
	}
}
