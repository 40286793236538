<!-- <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.9.1/jquery.min.js"></script> -->
<div class="custom-dropdown" *ngIf="dynamicDropDown && dynamicDropDown?.value">
  <ng-container *ngFor="let item of dynamicDropDown.value; let i = index">
    <span *ngIf="item.allow === true">
      <div class="form-group">
        <ng-select
          [items]="dropdownData"
          [(ngModel)]="item.ng_model_value"
          bindLabel="name"
          (ngModelChange)="statusChanges($event, item, printLogs)"
          [ngClass]="{ 'has-value': item.ng_model_value }"
          (focus)="focusEvent(item.id, dummyClick)"
          id="test"
          attr.data-testid="btn_dynamic_dropdown{{ i }}"
          #dummyClick
          placeholder="Enter or select"
          [multiple]="item?.multiple"
          [hideSelected]="true"
        >
        </ng-select>
        <label
          class="color-g"
          *ngIf="item.id === 'first_dropdown' && item?.ng_model_value"
          [ngClass]="{ 'margin-10': item.ng_model_value }"
          >{{ dynamicDropDown.labelArray[0] }}
        </label>
        <label
          class="color-g"
          *ngIf="item.id === 'second_dropdown' && item?.ng_model_value"
          [ngClass]="{ 'margin-10': item.ng_model_value }"
          >{{ dynamicDropDown.labelArray[1] }}
        </label>
      </div>
    </span>
  </ng-container>
</div>
