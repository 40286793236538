import { Component, OnDestroy, OnInit } from "@angular/core";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CoreConfigService } from "@core/services/config.service";

@Component({
	selector: "footer",
	templateUrl: "./footer.component.html",
})
export class FooterComponent implements OnInit, OnDestroy {
	public coreConfig: any;
	public year: number = new Date().getFullYear();
	public isSelfServicePortal = false;
	// Private
	private _unsubscribeAll: Subject<any>;
	sspConfig: any;

	/**
	 * Constructor
	 *
	 * @param {CoreConfigService} _coreConfigService
	 */
	constructor(
		public _coreConfigService: CoreConfigService,
		private _translateService: TranslateService,
		private router: Router,
	) {
		this._coreConfigService._selfServiceSubject.subscribe((selfService) => {
			if (selfService === "self-service") {
				this.isSelfServicePortal = true;
			} else {
				this.isSelfServicePortal = false;
			}
		});
		// Set the private defaults
		this._unsubscribeAll = new Subject();
	}

	// Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Subscribe to config changes
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
			this.coreConfig = config;
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(undefined);
		this._unsubscribeAll.complete();
	}
}
