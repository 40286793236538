import { Injectable } from "@angular/core";
import { ApiService } from "app/common/services/api/api.service";
import { HttpClient } from "@angular/common/http";
import { GlobalConstants } from "app/app.globalConstants";
import { AuthService } from "app/base/auth/services/auth.service";
import CryptoJS from "crypto-js";

@Injectable({
	providedIn: "root",
})
export class PermissionService extends ApiService {
	constructor(httpClient: HttpClient, private _authService: AuthService) {
		super(httpClient);
	}

	// function use for check the user have permission on requested data
	isGranted = (moduleList: string[], keyList: string[]) => {
		// let local_promise = new Promise();
		const permission_flag = this.getPromise(moduleList, keyList);
		return permission_flag;
	};

	// used for check if use has any permission on given module and key then it will return the flag as 1 otherwise 0
	checkPermission(moduleList: string[], keyList: string[], user_permissions: any) {
		let flag = 0;
		for (let k = 0; k < moduleList.length; k++) {
			if (user_permissions != undefined && user_permissions.hasOwnProperty(moduleList[k])) {
				let module = user_permissions[moduleList[k]];
				for (let i = 0; i < keyList.length; i++) {
					let key = keyList[i];
					if (module != undefined && module.hasOwnProperty(key)) {
						if (module[key]) {
							flag = 1;
							break;
						}
					}
				}
				if (flag == 1) {
					break;
				}
			}
		}
		return flag;
	}

	/**
	 * here we will check for license from current login user
	 * @param module_key
	 * @param keys: list of license key need to check
	 * @private
	 */
	hasValidLav = (module_key: any, keys: string[]) => {
		if (!GlobalConstants.dnsLavModules) {
			this.setGlobalValue();
		}
		let is_valid_license = false;
		if (typeof module_key == "object") {
			// list
			for (let each_module_key of module_key) {
				let KEYS_DICT = GlobalConstants.dnsLavModules?.keys_dict[each_module_key] || {};
				for (let key of keys) {
					if (KEYS_DICT[key]) {
						is_valid_license = true;
						return is_valid_license;
					}
				}
			}
		} else {
			let KEYS_DICT = GlobalConstants.dnsLavModules.keys_dict[module_key] || {};
			for (let key of keys) {
				if (KEYS_DICT[key]) {
					is_valid_license = true;
					return is_valid_license;
				}
			}
		}
		return is_valid_license;
	};

	// function used for getting the decrypted data from local storage and set to Global Constants.
	setGlobalValue(): void {
		let permission = localStorage.getItem("image_per");
		if (typeof permission == "string") {
			GlobalConstants.dnsPermissions = this.decryptData(permission);
			const temp_data = JSON.parse(GlobalConstants.dnsPermissions);
			GlobalConstants.dnsLavModules = temp_data?.usr_lic;
			const fflag = localStorage.getItem("fflags");
			if (fflag && fflag !== '""' && fflag !== undefined && fflag !== null) {
				GlobalConstants.dnsFlags = JSON.parse(this.decryptData(fflag));
			}
		}
	}

	is_feature_active = (fkeys: string[]) => {
		if (!GlobalConstants.dnsFlags) {
			this.setGlobalValue();
		}
		if (GlobalConstants.dnsFlags) {
			for (const key of fkeys) {
				const active = GlobalConstants.dnsFlags[key] ? GlobalConstants.dnsFlags[key] : false;
				if (active) {
					return true;
				}
			}
		}
		return false;
	};

	// function use to get data from GlobalConstant if data is not loaded it will load from localstorage.
	getPromise(moduleList: string[], keyList: string[]): boolean {
		if (!GlobalConstants.dnsPermissions) {
			this.setGlobalValue();
		}
		try {
			let user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
			let flag = this.checkPermission(moduleList, keyList, user_permissions);
			if (flag == 1) {
				return true;
			} else {
				return false;
			}
		} catch (e) {
			console.log(`Permission not loaded -> ${e.toString()}`);
			return false;
		}
	}

	// decryptData(data: string): any {
	// 	let master_key = "d99630sam7058639e7e80go239f38a32";

	// 	// Decode the base64 data so we can separate iv and crypt text.
	// 	var rawData = Buffer.from(data, "base64").toString("binary");
	// 	// Split by 16 because my IV size
	// 	var iv = rawData.substring(0, 16);
	// 	var crypttext = rawData.substring(16);
	// 	//Parsers
	// 	crypttext = CryptoJS.enc.Latin1.parse(crypttext);
	// 	iv = CryptoJS.enc.Latin1.parse(iv);
	// 	var key = CryptoJS.enc.Utf8.parse(master_key);
	// 	// Decrypt
	// 	var plaintextArray = CryptoJS.AES.decrypt({ ciphertext: crypttext }, key, {
	// 		iv: iv,
	// 		mode: CryptoJS.mode.CBC,
	// 		padding: CryptoJS.pad.Pkcs7,
	// 	});
	// 	// Can be Utf8 too
	// 	var output_plaintext = CryptoJS.enc.Latin1.stringify(plaintextArray);
	// 	return output_plaintext;
	// }
	decryptData(data: string): any {
		const Base64CBC = data.replace('"', "").replace('"', "");
		const iv = CryptoJS.enc.Utf8.parse("Sa2017GO2021AR86");
		let key = "d99630sam7058639"; //key used in Python
		key = CryptoJS.enc.Utf8.parse(key);
		const decrypted = CryptoJS.AES.decrypt(Base64CBC, key, { iv: iv, mode: CryptoJS.mode.CBC });
		const decrypted_data = decrypted.toString(CryptoJS.enc.Utf8);
		return decrypted_data;
	}
}
