<ng-container >
    <h6 style="font-weight: 400;">{{"UI.k_question" | translate}}</h6>

    <div class="question-main-block">
        <div class="question-list" *ngIf="radioButtonRatingConfig.totalQuestions.length">
            <ng-container *ngFor="let noOfQuestion of radioButtonRatingConfig.totalQuestions">
                <div>
                    {{noOfQuestion.id}}
                </div>
            </ng-container>            
        </div>
        <div class="question-block">
            <div class="questions">
               2. How Complex is the change ?
            </div>
        </div>
    </div>
    


    
</ng-container>