import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { GlobalConstants } from 'app/app.globalConstants';
import { User } from 'app/auth/models';
import { AuthService } from 'app/base/auth/services/auth.service';
import { getFileSizeText, validateFile, customSearch } from 'app/common/utils/utils';
import { ProblemService } from 'app/servicedesk/problem/services/problem.service';
import { environment } from 'environments/environment';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ToastrService } from 'ngx-toastr';
import { TreeviewItem } from 'ngx-treeview';
import { from, map, reduce, catchError, throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { InfinityModules } from '../../types/modules';
import { default as _map } from "lodash-es/map";
const URL = `${environment.instance_url}` + "media";
@Component({
  selector: 'app-dns-incident-to-problem',
  templateUrl: './dns-incident-to-problem.component.html',
  styleUrls: ['./dns-incident-to-problem.component.scss']
})
export class DnsIncidentToProblemComponent implements OnInit {
	@Input() incident_data;
	// @Input() options;
	@Output("onHideConvInciToProblem") onHideConvInciToProblem = new EventEmitter<any>();
	@Output() addedProblemID = new EventEmitter<object>();
	@Input() fromEditCall: boolean = true;
	@ViewChild("problemDueBy") Duedate: FlatpickrOptions;
	requester: any = {};
	separateDialCode = true;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
	PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom, CountryISO.India];
	user_type;
	permissions: any;
	public addProblem: any = {};
	currentEvent: String;
	currentRequester: Number;
	hideProfile: boolean = false;
	requester_history = [];
	public quillEditorStyles = { "min-height": "300px" };
	searcRequestLen = 0;
	typeDisplayName = this._translateService.instant("UI.k_internal_note");
	public dropdown = {
		expertise: [],
		level: [],
		assignee: [],
		state: [],
		status: [],
		urgency: [],
		priority: [],
		severity: [],
		event_type: [],
		type: [],
		impact: [],
		users: [],
		teams: [],
		assignee_group: {
			self: [],
			group: [],
		},
		requesters: [],
		problem_source: [],
		groupt_type_list: [],
		close_type: [],
		closure_category_list: [],
		current_user_group: [],
		current_user_expertise: [],
		state_id_map: {},
		teams_and_self_assign: [],
		formatted_services: [],
		formatted_classification_data: [],
		problem_type: [],
		team_user_profile_map: {},
	};
	problem_state_flag: any = {};
	currentUser: User;
	public ALLOWED_TYPES;
	public MAX_IMAGE_SIZE;
	public MAX_HEIGHT;
	public MAX_WIDTH;
	public config: PerfectScrollbarConfigInterface = { suppressScrollX: true };
	selfAssignSel = false;
	searchStr: any;
	requesterList: any = [];
	public searchFalse = true;
	some = true;
	public type = "Internal Note";
	public checkCC = false;
	showHide = true;
	public emailToSelect = [];
	public dataDup = [];
	public basicDPdata: NgbDateStruct;
	public filesList: any = [];
	public inciSaveOpt: any = this._translateService.instant("UI.k_submit_new");
	module = InfinityModules.PROBLEM;
	isReport: boolean = false;
	disableSaveBtn: boolean = false;
	emailAttachments: any = [];
	treeInputData = [];
	public defaultAvatarColor = "#f59518";
	module_requester = InfinityModules.REQUESTER;
	stateKeyIdMap: any = {};
	private timer: any;
	totalCount = 0;
	viewCount = 0;
	selectedCount = 20;
	cardCount = 20;
	selectedOption = 3;
	custom_field_config: any = {};
	custom_field_data: any = {};
	userTZ: any;
	public imgHeightWidthStr = "img height='400px' width='100%'";
	service_config: any = {};
	classification_config: any = {};
	showFileValidationError = (data) => {
		if (data) {
			// invalid file requirement
			const errors: any = [];
			if (data["is_allowed"] === false) {
				errors.push(
					this._translateService.instant("UI.k_supported_format") +
						_map(this.ALLOWED_TYPES, (val) => (val.includes(".") || val.includes("/") ? val : " ." + val))
				);
			}

			if (data["size"]) {
				errors.push(
					this._translateService.instant("Message.msg_max_size") + " " + getFileSizeText(this.MAX_IMAGE_SIZE)
				);
			}

			from(errors)
				.pipe(
					map((err) => `<li>${err}</li>`),
					reduce((acc, value) => acc.concat(value), "")
				)
				.subscribe((res) => {
					this._toastr.warning(
						`<b>${data["name"]}</b></br><ul class="pl-1">${res}</ul`,
						this._translateService.instant("Error.err_file_upload_failed"),
						{
							enableHtml: true,
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
				});
		}
	};

	fileUpload = function (evt) {
		var files = evt.target.files;
		var new_file_len = evt.target.files.length;
		var added_file_len = this.filesList.length;
		for (var i = 0; i < new_file_len; i++) {
			var file = files[i];
			const data = validateFile(file, this.ALLOWED_TYPES);
			if (data && data["is_valid"]) {
				if (this.emailAttachments.indexOf(file.name) == -1) {
					this.emailAttachments.push(file.name);
					var pos = added_file_len + i;
					var reader = new FileReader();
					reader.onload = (function (filesList, file, pos) {
						return function (e) {
							var data = e.target.result;
							filesList[pos] = {
								file_name: file.name,
								file_type: file.type,
								file_size: file.size,
								file_data: data,
								file_date: new Date(),
							};
						};
					})(this.filesList, file, pos);
					reader.readAsDataURL(file);
				}
			} else if (data && !data["is_valid"]) {
				this.showFileValidationError(data);
			}
		}
		evt.srcElement.value = "";
	};
	// removing the file from POP UI.
	removeAttachedFile = function (index, filesList) {
		filesList.splice(index, 1);
		this.emailAttachments.splice(index, 1);
	};

	copyCode(inputTextValue) {
		const selectBox = document.createElement("textarea");
		selectBox.style.position = "fixed";
		selectBox.value = inputTextValue;
		document.body.appendChild(selectBox);
		selectBox.focus();
		selectBox.select();
		document.execCommand("copy");
		document.body.removeChild(selectBox);
		this._toastr.success("", this._translateService.instant("Message.msg_copy_success"), {
			toastClass: "toast ngx-toastr",
			closeButton: true,
		});
	}
	/**
	 * Constructor
	 *
	 */
	constructor(
		// private modalService: NgbModal,
		private _problemViewService: ProblemService,
		private _toastr: ToastrService,
		private _translateService: TranslateService,
		private _authService: AuthService
	) {
		this._authService.currentUser.subscribe((x) => (this.currentUser = x));
	}

	ngOnInit(): void {
		let userParams = JSON.parse(localStorage.getItem("userParams"));
		this.userTZ = userParams?.user_tz;
		try {
			let today = new Date(new Date().toLocaleString("en-US", { timeZone: this.userTZ }));
			this.dateTimeOptions["defaultDate"] = today;
		} catch (e) {
			//console.log("Error in selecting current time based on timezone ", e);
		}
		this.getOptions();
		// this.dropdown["expertise"] = this.options?.expertise;
		// this.dropdown["level"] = this.options?.level;
		// this.dropdown["assignee"] = this.options?.assignee;
		this.addProblem = {};
		this.addProblem["params"] = {};
		this.requester = this.incident_data?.requester;
		this.requester["profile_image"] = this.incident_data?.requester_profile;
		if (this.fromEditCall) {
			this.addProblem["priority_id"] = this.incident_data?.basic_info?.priority?.id;
			this.addProblem["summary"] = this.incident_data?.basic_info?.summary;
			this.addProblem["erichTextData"] = this.incident_data?.basic_info?.description;
			this.addProblem["catalogue"] = this.incident_data?.basic_info?.catalogue;
			this.addProblem["category"] = this.incident_data?.basic_info?.category;
			this.addProblem["catalogue_name"] = this.incident_data?.basic_info?.catalogue_name;
			this.addProblem["category_name"] = this.incident_data?.basic_info?.category_name;
			this.addProblem["service_classification_name"] =
				this.incident_data?.basic_info?.service_classification_name;
			this.addProblem["impact_service_name"] = this.incident_data?.basic_info?.impact_service_name;
			this.addProblem["impact_service"] = this.incident_data?.basic_info?.impact_service;
			this.addProblem["impact_service_tree_path"] = this.incident_data?.basic_info?.impact_service_tree_path;
			this.addProblem["service_classification"] = this.incident_data?.basic_info?.service_classification;
			this.addProblem["service_classification_path"] =
				this.incident_data?.basic_info?.service_classification_path;
			this.addProblem["reporter_name"] = this.incident_data?.reporter_info?.name;
			this.addProblem["reporter_email"] = this.incident_data?.reporter_info?.email;
			this.addProblem["reporter_phone"] = this.incident_data?.reporter_info?.phone_number;
			this.addProblem["current_watcher"] = this.incident_data?.current_watcher || [];
		} else {
			this.addProblem["priority_id"] = this.incident_data?.priority_id;
			this.addProblem["summary"] = this.incident_data?.summary;
			this.addProblem["erichTextData"] = this.incident_data?.description;
			this.addProblem["impact_service"] = this.incident_data?.impact_service;
			this.addProblem["impact_service_tree_path"] = this.incident_data?.impact_service_tree_path;
			this.addProblem["service_classification"] = this.incident_data?.service_classification;
			this.addProblem["service_classification_path"] = this.incident_data?.service_classification_path;
			this.addProblem["reporter_name"] = this.incident_data?.reportee?.reporter_name;
			this.addProblem["reporter_email"] = this.incident_data?.reporter?.reporter_email;
			this.addProblem["reporter_phone"] = this.incident_data?.reporter?.reporter_phone;
			this.addProblem["current_watcher"] = this.incident_data?.current_watcher || [];
		}

		// if(this.incident_data.current_assignment_info != undefined){
		// 	if (this.incident_data["current_assignment_info"]["group_name"]) {
		// 		this.addProblem["support_group"] = this.incident_data["current_assignment_info"]["group"];
		// 		this.addProblem["support_group_tmp"] = this.incident_data["current_assignment_info"]["group"];
		// 		this.addProblem["group_name"] =
		// 		this.incident_data["current_assignment_info"]["group_name"];
		// 		this.addProblem["group_type"] =
		// 		this.incident_data["current_assignment_info"]["group_type"];
		// 	}
		// 	if (this.incident_data["current_assignment_info"]["group"]) {
		// 		this._problemViewService
		// 		.getTeamUserMap({ team_id: this.incident_data?.current_assignment_info?.group })
		// 		.subscribe((response) => {
		// 			this.dropdown['team_user_profile_map'] = response;
		// 		});
		// 	}
		// 	if (this.incident_data["current_assignment_info"]["level"]) {
		// 		this.addProblem["level"] = this.incident_data["current_assignment_info"]["level"];
		// 		this.addProblem["level_id"] = this.incident_data["current_assignment_info"]["level_id"];
		// 	}
		// 	if (this.incident_data["current_assignment_info"]["expertise"]) {
		// 		this.addProblem["expertise"] = this.incident_data["current_assignment_info"]["expertise"];
		// 		this.addProblem["expertise_id"] = this.incident_data["current_assignment_info"]["expertise_id"];
		// 	}
		// 	if (this.incident_data["current_assignment_info"]["assignee"]) {
		// 		this.addProblem["assignee"] = this.incident_data["current_assignment_info"]["assignee"];
		// 	}
		// 	if (this.incident_data["current_assignment_info"]["assignee_profile"]) {
		// 		this.addProblem["assignee_profile"] = this.incident_data["current_assignment_info"]["assignee_profile"];
		// 	}
		// }
		// else {
		// 	this.incident_data["current_assignment_info"] = {}
		// }
		// if (this.addProblem &&
		// 	this.addProblem?.support_group == undefined &&
		// 	this.addProblem?.assignee != undefined
		// ){
		// 	this.addProblem["support_group_tmp"] = "self_assign"
		// 	this.selfAssignSel = true
		// }
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.permissions = user_permissions[this.module];
	}

	public dateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: true,
		dateFormat: "%b %d, %Y %I:%M %p",
		altFormat: "M d, Y H:i K",
		mode: "single",
		altInputClass: "form-control flat-picker flatpickr-input",
	};
	toggleReport() {
		this.isReport = !this.isReport;
	}

	isShownCc: boolean = false; // hidden by default
	toggleCc() {
		this.isShownCc = !this.isShownCc;
	}
	onSubmitNew = (data) => {
		this.inciSaveOpt = this._translateService.instant("UI.k_submit_new");
		this.addProblem["inci_resolution_time"] = null;
		this.addProblem["resolution"] = null;
		if (data.form.valid === true) {
			this.addProblem["inci_add_flag"] = this.problem_state_flag["New"];
			let option_status = this.dropdown?.status.filter((e) => e["state_id"] == this.stateKeyIdMap["Open"]);
			this.addProblem["status_id"] = option_status?.[0]["id"];
			this.addProblem["state_id"] = option_status?.[0]["state_id"];
			this.addProblem["workflow"] = option_status?.[0]?.["workflow_id"];
			this.saveProblem();
		}
	};
	getResolutionNote = (evt) => {
		let tmpHTML = evt.html.replace("img", this.imgHeightWidthStr);
		this.addProblem.resolution = tmpHTML;
	};

	saveProblem() {
		this.addProblem["attached_files"] = this.filesList;
		this.addProblem["custom_field_data"] = this.custom_field_data;
		// this.addProblem["attached_files"] = this.addProblem.files;
		// this.addProblem["state"] = this.dropdown?.state_id_map[this.addProblem.state_id];
		if (
			this.type == "Communication" &&
			(this.addProblem.to_address || this.addProblem.cc_address) &&
			(this.addProblem?.erichTextData == undefined || !this.addProblem?.erichTextData)
		) {
			this._toastr.error(
				this._translateService.instant("Error.err_note_body_missing"),
				this._translateService.instant("Error.err_save_failed"),
				{
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				}
			);
		} else if (
			this.type == "Communication" &&
			this.addProblem?.cc_address &&
			(this.addProblem?.to_address == undefined || !this.addProblem?.to_address)
		) {
			this._toastr.error(
				this._translateService.instant("Error.err_select_to_address"),
				this._translateService.instant("Error.err_select_to_address"),
				{
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				}
			);
		} else {
			this.get_custom_data(this.custom_field_config?.custom_fields, this.addProblem?.custom_field_data);
			this.addProblem["requester_name"] = this.requester?.["full_name"];
			this.addProblem["requester_email"] = this.requester["email"];
			this.addProblem["requester_phone_number"] = this.requester["phone_number"];
			this.addProblem["requester_id"] = this.requester?.requester_id;
			this.addProblem["closure_note"] = "";
			this.addProblem["problem_type"] = this.dropdown?.problem_type[0];
			this.addProblem["communication_type"] = this.type;
			this.disableSaveBtn = true;
			if (this.type == "Internal Note" && this.addProblem.to_address) {
				this.addProblem.to_address = "";
			}
			this._problemViewService
				.saveProblem(this.addProblem)
				.pipe(
					map((response: any) => {
						if (response.status == "success") {
							this._toastr.success(
								this._translateService.instant(response["message"], { id: response?.id }),
								this._translateService.instant("Message.msg_save_success"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
							this.addedProblemID.emit(response?.data);
							this.onHideConvInciToProblem.emit("hide");
						} else {
							this._toastr.error(
								this._translateService.instant(response["message"]),
								this._translateService.instant("Error.err_save_failed"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
							this.disableSaveBtn = false;
						}
						// Success
					}),
					catchError((error) => {
						this.disableSaveBtn = false;
						return throwError(error.statusText);
					})
				)
				.subscribe();
		}
	}

	onHideProfile = (e) => {
		this.hideProfile = false;
	};

	getEnrichTextData = (event) => {
		this.addProblem.erichTextData = event.html;
	};
	getPulicReplyData = (event) => {
		this.addProblem.public_reply = event.html;
	};

	removeMainFile = (idx) => {
		Swal.fire({
			title: this._translateService.instant("Message.msg_delete_title"),
			text: this._translateService.instant("Message.msg_delete_text"),
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: this._translateService.instant("Message.msg_delete_confirm"),
			customClass: {
				confirmButton: "btn btn-primary",
				cancelButton: "btn btn-danger ml-1",
			},
		}).then((result) => {
			if (result.isConfirmed) {
				this.filesList.splice(idx, 1);
				this.emailAttachments.splice(idx, 1);
				// this.uploaded_files.splice(idx, 1);
			}
		});
	};
	getOptions() {
		this._problemViewService.getOptions({ for_add_problem: true }).subscribe(
			(response) => {
				if (response) {
					this.problem_state_flag = response["problem_state_flag"];
					this.dropdown["state"] = response["state"];
					this.dropdown["status"] = response?.status;
					if (this.addProblem?.impact_service) {
						this.getWorkflowStatus();
					}
					this.stateKeyIdMap = response["state_key_id_map"];
					this.custom_field_config = response?.custom_filed_options;
					this.dropdown["priority"] = response["priority"];
					this.dropdown["urgency"] = response["urgency"];
					this.dropdown["priority"] = response["priority"];
					this.dropdown["severity"] = response["severity"];
					this.dropdown["event_type"] = response["event_type"];
					this.dropdown["type"] = response["type"];
					this.dropdown["impact"] = response["impact"];
					this.dropdown["users"] = response["users"];
					this.dropdown["teams"] = response["teams"];
					this.dropdown["problem_type"] = response["problem_type"];
					this.dropdown["teams_and_self_assign"] = [{ team_id: "self_assign", name: "Self Assign" }].concat(
						response["teams"]
					);
					this.dropdown["assignee_group"]["group"] = response["teams"];
					this.dropdown["requesters"] = response["requesters"];
					this.dropdown["problem_source"] = response["problem_source"];
					this.dropdown["groupt_type_list"] = response["groupt_type_list"];
					this.dropdown["attach_category_list"] = response["attach_category_list"];
					this.dropdown["file_icon_color_map"] = response["file_icon_color_map"];
					let imageDefaults = response["image_upload_defaults"];
					this.ALLOWED_TYPES = imageDefaults["allowed_types"];
					this.MAX_IMAGE_SIZE = imageDefaults["max_size"];
					this.MAX_HEIGHT = imageDefaults["max_height"];
					this.MAX_WIDTH = imageDefaults["max_width"];
					this.dropdown["close_type"] = response["close_type"];
					this.dropdown["closure_category_list"] = response["closure_category"];
					this.dropdown["current_user_group"] = response["current_user_group"];
					this.dropdown["state_id_map"] = response["state_id_map"];
				}
			},
			(error) => {
				//todo
			}
		);
	}

	clearExpertiselevelAssignee(assignment) {
		assignment["level"] = null;
		assignment["expertise"] = null;
		assignment["level_id"] = null;
		assignment["expertise_id"] = null;
		assignment["assignee"] = null;
		assignment["assignee_profile"] = null;
	}
	clearlevelAssignee(assignment) {
		assignment["level"] = undefined;
		assignment["level_id"] = undefined;
		assignment["assignee"] = undefined;
		assignment["assignee_profile"] = undefined;
	}

	groupChange(options) {
		// this.assignment_data = {};
		if (this.addProblem.support_group_tmp != "self_assign") {
			this.addProblem.support_group = this.addProblem.support_group_tmp;
		}
		let selected_group = options.filter(
			(e) => e.team_id == this.addProblem.support_group_tmp || e.team_id == this.addProblem.support_group
		);

		if (selected_group.length > 0) {
			this._problemViewService.getTeamUserMap({ team_id: selected_group[0]["team_id"] }).subscribe((response) => {
				this.dropdown.team_user_profile_map = response;
			});
			this.addProblem["group_name"] = selected_group[0]["name"];
			this.addProblem["group_type"] = selected_group[0]["group_type"];
			this.addProblem["group_type_tmp"] = selected_group[0]["group_type"];
			if (selected_group[0]["group_type"] === "Approval" || selected_group[0]["group_type"] === "Servicedesk") {
				this.dropdown["assignee"] = selected_group[0]["staffs"];
				this.dropdown["expertise"] = [];
			} else {
				this.dropdown["expertise"] = selected_group[0]["level_staff"];
				this.dropdown["assignee"] = [];
			}
			this.clearExpertiselevelAssignee(this.addProblem);
			this.dropdown["level"] = [];
			if (this.selfAssignSel) {
				if (
					selected_group[0]["group_type"] === "Approval" ||
					selected_group[0]["group_type"] === "Servicedesk"
				) {
					this.addProblem["assignee"] = selected_group[0]["staffs"][0]["profile_id"];
					this.addProblem["assignee_profile"] = selected_group[0]["staffs"][0];
					this.dropdown["current_user_expertise"] = [];
					this.addProblem["level"] = null;
					this.addProblem["expertise"] = null;
					this.addProblem["level_id"] = null;
					this.addProblem["expertise_id"] = null;
				} else {
					this.addProblem["assignee"] =
						selected_group[0]["level_staff"][0]["expertGroupLevel"][0]["users"][0]["profile_id"];
					this.addProblem["assignee_profile"] =
						selected_group[0]["level_staff"][0]["expertGroupLevel"][0]["users"][0];
					if (selected_group[0]["level_staff"].length > 1) {
						this.dropdown["current_user_expertise"] = selected_group[0]["level_staff"];
						this.addProblem["expertise"] = null;
						this.addProblem["level"] = null;
						this.addProblem["expertise_id"] = null;
						this.addProblem["level_id"] = null;
					} else {
						this.addProblem["expertise"] = selected_group[0]["level_staff"][0]["expertise"];
						this.addProblem["level"] = selected_group[0]["level_staff"][0]["expertGroupLevel"][0]["level"];
						this.addProblem["expertise_id"] = selected_group[0]["level_staff"][0]["expertise_id"];
						this.addProblem["level_id"] =
							selected_group[0]["level_staff"][0]["expertGroupLevel"][0]["level_id"];
					}
				}
			} else {
				this.clearExpertiselevelAssignee(this.addProblem);
			}
		} else {
			if (!this.selfAssignSel) {
				this.dropdown["expertise"] = [];
				this.dropdown["level"] = [];
				this.dropdown["assignee"] = [];
			} else if (this.selfAssignSel) {
				this.dropdown["current_user_expertise"] = [];
			}
			this.addProblem["group_name"] = "";
			this.clearExpertiselevelAssignee(this.addProblem);
		}
	}

	//evt-expertise
	expertiseChange(dropdown) {
		let selected_expertise = dropdown?.filter((e) => e.expertise == this.addProblem.expertise);
		if (selected_expertise?.length > 0) {
			this.addProblem["expertise_id"] = selected_expertise[0]["expertise_id"];
			if (this.selfAssignSel) {
				this.addProblem["level"] = selected_expertise[0]["expertGroupLevel"][0]["level"];
				this.addProblem["level_id"] = selected_expertise[0]["expertGroupLevel"][0]["level_id"];
			} else {
				this.dropdown["assignee"] = [];
				this.addProblem["assignee"] = null;
				this.addProblem["assignee_profile"] = null;
				this.addProblem["level"] = null;
				this.addProblem["level_id"] = null;
				this.dropdown["level"] = selected_expertise[0]["expertGroupLevel"];
			}
		} else {
			this.dropdown["level"] = [];
			this.dropdown["assignee"] = [];
			this.clearExpertiselevelAssignee(this.addProblem);
		}
	}

	//evt-level
	levelChange() {
		let selected_level = this.dropdown["level"].filter((e) => e.level == this.addProblem.level);
		if (selected_level.length > 0) {
			this.addProblem["assignee"] = null;
			this.addProblem["assignee_profile"] = null;
			this.addProblem["level_id"] = selected_level[0]["level_id"];
			this.dropdown["assignee"] = selected_level[0]["users"];
		} else {
			this.dropdown["assignee"] = [];
			this.clearlevelAssignee(this.addProblem);
		}
	}
	ngOnChanges(changes: SimpleChanges) {
		if (changes.requester.currentValue !== changes.requester.previousValue) {
		}
	}

	downloadAttachment = (path, file) => {
		let file_path = URL + path.split("media")[1];
		let downloadable_file_path = file_path + "/" + file;
		window.open(downloadable_file_path, "_blank");
	};

	stringToColor(reqName) {
		if (!reqName) {
			reqName = "anaymous";
		}

		let hash = 0;
		let i;

		/* eslint-disable no-bitwise */
		for (i = 0; i < reqName.length; i += 1) {
			hash = reqName.charCodeAt(i) + ((hash << 5) - hash);
		}

		let colour = "#";

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff;
			colour += `00${value.toString(16)}`.substr(-2);
		}
		/* eslint-enable no-bitwise */

		return colour;
	}
	assignToMyself() {
		this.selfAssignSel = true;
		if (this.dropdown?.current_user_group?.length == 1) {
			this.addProblem["support_group"] = this.dropdown?.current_user_group[0].team_id;
			this.addProblem["group_type"] = this.dropdown?.current_user_group[0].group_type;
			this.addProblem["group_name"] = this.dropdown?.current_user_group[0].name;
			if (
				this.dropdown.current_user_group[0]?.group_type === "Approval" ||
				this.dropdown.current_user_group[0]?.group_type === "Servicedesk"
			) {
				this.addProblem["assignee"] = this.currentUser.profile.profile_id;
				this.addProblem["assignee_profile"] = this.currentUser.profile;
				this.addProblem["expertise"] = null;
				this.addProblem["level"] = null;
				this.addProblem["expertise_id"] = null;
				this.addProblem["level_id"] = null;
			} else {
				this.addProblem["assignee"] = this.currentUser.profile.profile_id;
				this.addProblem["assignee_profile"] = this.currentUser.profile;
				if (this.dropdown.current_user_group[0]["level_staff"].length > 1) {
					this.dropdown["current_user_expertise"] = this.dropdown.current_user_group[0]["level_staff"];
					this.addProblem["expertise"] = null;
					this.addProblem["level"] = null;
					this.addProblem["expertise_id"] = null;
					this.addProblem["level_id"] = null;
				} else {
					this.addProblem["expertise"] = this.dropdown.current_user_group[0]["level_staff"][0]["expertise"];
					this.addProblem["level"] =
						this.dropdown.current_user_group[0]["level_staff"][0]["expertGroupLevel"][0]["level"];
					this.addProblem["expertise_id"] =
						this.dropdown.current_user_group[0]["level_staff"][0]["expertise_id"];
					this.addProblem["level_id"] =
						this.dropdown.current_user_group[0]["level_staff"][0]["expertGroupLevel"][0]["level_id"];
				}
			}
		} else if (this.dropdown?.current_user_group?.length > 1) {
		} else if (this.dropdown?.current_user_group?.length < 1) {
			this.addProblem["level"] = null;
			this.addProblem["expertise"] = null;
			this.addProblem["level_id"] = null;
			this.addProblem["expertise_id"] = null;
			this.addProblem["group"] = null;
			this.addProblem["group_name"] = null;
			this.addProblem["group_type"] = null;
			this.addProblem["assignee"] = this.currentUser?.profile?.profile_id;
			this.addProblem["assignee_profile"] = this.currentUser?.profile;
		}
		this.addProblem.support_group_tmp = "self_assign";
	}

	searchRequester(searchStr, key) {
		this.requesterList = [];
		this.searcRequestLen = searchStr.length;
		if (searchStr == searchStr.length < 0) {
			this.searchFalse = true;
		}
		if (searchStr != undefined && searchStr.length > 2) {
			this.searchFalse = false;
			this._problemViewService
				.getRequesterList({ full_name: encodeURIComponent(searchStr) })
				.subscribe((response) => {
					this.requesterList = response;
				});
		}
	}
	updateWatcher() {
		let watcher = {};
		let profile = {};
		profile["profile_id"] = this.currentUser.profile.profile_id;
		profile["full_name"] = this.currentUser.profile.full_name;
		profile["email"] = this.currentUser.profile.email;
		profile["tags"] = this.currentUser.profile.tags;
		profile["role_type"] = this.currentUser.profile.role_type;
		profile["user"] = this.currentUser.profile.user;
		profile["name"] = this.currentUser.profile.full_name;
		profile["contact_number"] = this.currentUser.profile.contact_number;
		this.addProblem.current_watcher = [profile];
		watcher["Email_id"] = [this.currentUser.profile.email];
		watcher["phone"] = [this.currentUser.profile.contact_number];
	}
	check() {
		this.some = !this.some;
	}

	selectAddEmailMethod(name) {
		return { name: name, tag: true };
	}

	onStatusChange = (data) => {
		this.addProblem["status_id"] = data?.id;
		this.addProblem["state_id"] = data?.state_id;
		this.addProblem["flow_id"] = data?.guid;
	};
	assigneeChange() {
		this.addProblem["assignee"] = this.addProblem?.assignee_profile?.profile_id;
	}
	statusBadgeChange = (data) => {
		this.addProblem[data["field"]] = data["event"];
		if (data?.event?.id) {
			this.addProblem.priority_id = data["event"]["id"];
		}
	};
	getWorkflowStatus() {
		this._problemViewService
			.getServiceBaseWorkflowStatus({ service_id: this.addProblem.impact_service })
			.subscribe((response) => {
				this.dropdown["status"] = response.options;
				this.custom_field_config = response?.custom_filed_options;
				if (response?.custom_field_data) {
					this.custom_field_data = response?.custom_field_data;
				}
			});
	}
	getClassificationdata() {
		this._problemViewService
			.getImpactServiceClassification({ service_id: this.addProblem?.impact_service, format_to_treeview: true })
			.subscribe(
				(res) => {
					this.dropdown["formatted_classification_data"] = [];
					this.addProblem.priority_id = res?.priority;
					res?.classification?.forEach((ele) => {
						const data = new TreeviewItem(ele);
						this.dropdown["formatted_classification_data"].push(data);
					});
				},
				(error) => {
					//console.log("error_while_fetching impact service", error);
				}
			);
	}
	serviceValueChange = (item) => {
		this.addProblem.impact_service = item?.value;
		this.addProblem.impact_service_name = item?.text;
		this.addProblem.impact_service_tree_path = item?.full_path_text;
		this.addProblem.service_classification = "";
		this.addProblem.service_classification_name = "";
		this.addProblem.service_classification_path = "";
		this.addProblem["catalogue"] = item?.sel_tree_view_data[item?.sel_tree_view_data?.length - 1]["value"];
		this.addProblem["catalogue_name"] = item?.sel_tree_view_data[item?.sel_tree_view_data?.length - 1]["text"];
		if (item?.sel_tree_view_data && item?.sel_tree_view_data?.length > 2) {
			this.addProblem["category"] = item?.sel_tree_view_data[1]["value"];
			this.addProblem["category_name"] = item?.sel_tree_view_data[1]["text"];
		} else {
			this.addProblem["category"] = "";
			this.addProblem["category_name"] = "";
		}
		this.getWorkflowStatus();
		this.getClassificationdata();
	};
	classificationValueChange = (item) => {
		this.addProblem.service_classification = item?.value;
		this.addProblem.service_classification_name = item?.text;
		this.addProblem.service_classification_path = item?.full_path_text;
	};

	updateFieldName(evt, id, dropdown_name, set_field, ref_field) {
		let selected = this.dropdown[dropdown_name].filter((e) => e[id] == evt);
		set_field = selected[0][ref_field];
	}

	handleEvent(e) {
		////console.log("handleEvent",e);
	}

	info_action = () => {
		if (this.addProblem?.asset?.ci_id) {
			window.open("/it-asset/asset/" + this.addProblem?.asset?.ci_id, "_blank");
		}
	};

	get_custom_data(config_data, custom_fields) {
		let actual_conf_data = {};
		if (config_data && custom_fields) {
			config_data.forEach((conf) => {
				let data_key = conf?.data_key;
				let label = conf?.label;
				let value = custom_fields[data_key];
				if (conf?.type == "date" || conf?.type == "datetime-local") {
					if (this.addProblem.custom_field_data[data_key] instanceof Array) {
						this.addProblem.custom_field_data[data_key] = this.addProblem.custom_field_data[data_key][0];
					}
					if (value instanceof Array) {
						value = value[0];
					}
				}
				if (value) {
					actual_conf_data[label] = value;
				}
			});
		}
		return actual_conf_data;
	}
	closeSidebar(args) {
		this.onHideConvInciToProblem.emit("hide");
	}
	customSearchFn = (term: string, item: any) => {
		return customSearch(term, item, "full_name", "email");
	};

	ngOnDestroy() {
		Swal.close();
		this.Duedate?.flatpickr.close();
	}

	onImpactServiceClick = (event, key) => {
		event.preventDefault();
		if (key == "impact_service") {
			if (!this.service_config) {
				this.service_config = {};
			}
			this.service_config["is_loading"] = true;
			this.dropdown["formatted_services"] = [];
			this._problemViewService
				.getRequesterImpactService({
					requester_id: this.requester?.requester_id,
					is_published: true,
					bind_classification: false,
					module_name_list: JSON.stringify(["problem"]),
				})
				.subscribe(
					(res) => {
						this.service_config["is_editable"] = true;
						this.dropdown["formatted_services"] = [];
						res?.forEach((ele) => {
							const data = new TreeviewItem(ele);
							this.dropdown["formatted_services"].push(data);
						});
						this.service_config["is_loading"] = false;
					},
					(error) => {
						//console.log("error_while_fetching impact service", error);
						this.service_config["is_loading"] = false;
					}
				);
		} else if (key == "service_classification") {
			if (!this.classification_config) {
				this.classification_config = {};
			}
			this.classification_config["is_loading"] = true;
			this.dropdown["formatted_classification_data"] = [];
			if (this.addProblem?.impact_service) {
				this._problemViewService
					.getImpactServiceClassification({
						service_id: this.addProblem?.impact_service,
						format_to_treeview: true,
					})
					.subscribe(
						(res) => {
							this.classification_config["is_editable"] = true;
							this.dropdown["formatted_classification_data"] = [];
							this.addProblem.priority_id = res?.priority;
							res?.classification?.forEach((ele) => {
								const data = new TreeviewItem(ele);
								this.dropdown["formatted_classification_data"].push(data);
							});
							this.classification_config["is_loading"] = false;
						},
						(error) => {
							//console.log("error_while_fetching impact service", error);
							this.classification_config["is_loading"] = false;
						}
					);
			} else {
				this._toastr.error(this._translateService.instant("Error.err_select_impact_service"));
				this.classification_config["is_loading"] = false;
			}
		}
	};

	getFaultSymptoms = (event) => {
		this.addProblem.fault_symptoms = event.html;
	};
}
