import { Injectable } from "@angular/core";
import { ApiService } from "app/common/services/api/api.service";
import { HttpClient } from "@angular/common/http";
import { Observable, of, Subject } from "rxjs";
@Injectable({
	providedIn: "root",
})
export class IMACDService extends ApiService {
	constructor(httpClient: HttpClient) {
		super(httpClient);
	}

	getSLAMatricsData(payload = {}): Observable<any> {
		return of({});
	}
	getHistoryDetails(payload = {}): Observable<any> {
		return this.get("ux/common/imacd/imacd/history/", payload);
	}

	getOptions(payload = {}) {
		return this.get("ux/common/imacd/imacd/options/", payload);
	}

	saveIMACDProcess(payload: any) {
		return this.post("ux/common/imacd/imacd/", payload);
	}

	getImacdData(pk, payload = {}): Observable<any> {
		return this.get(`ux/common/imacd/imacd/${pk}/`, payload);
	}
	getImacdList(payload = {}) {
		return this.get("ux/common/imacd/imacd/", payload);
	}

	saveConversation(payload) {
		return this.post("ux/common/imacd/conversation/", payload);
	}
	getMailConversations(payload) {
		return this.get("ux/common/imacd/conversation/", payload);
	}

	updateMsgReadStatus(payload) {
		return this.post("ux/common/imacd/conversation/msg_read/", payload);
	}
	saveRichTextData(payload: {}) {
		return this.post("ux/common/imacd/imacd/save-rich-text/", payload);
	}

	saveorGenTemplate = (payload): Observable<any> => {
		return this.post("ux/common/imacd/imacd/gatepass_template/", payload);
	};

	// Attachment related service call start
	addAttachments(payload: any) {
		return this.post("ux/common/imacd/imacd/add-attachment/", payload);
	}

	deleteAttachment(attachmentId: any, payload: {}) {
		return this.put("ux/common/imacd/imacd/delete-attachment/" + attachmentId.toString() + "/", payload);
	}

	getAttachmentDetails(payload = {}): Observable<any> {
		return this.get("ux/common/imacd/imacd/attachment/", payload);
	}

	getGatepassDetails(payload = {}): Observable<any> {
		return this.get("ux/common/imacd/imacd/gatepass/", payload);
	}

	// Attachment related service call ends

	getApprovalStatus = (payload) => {
		return this.get(`ux/common/imacd/imacd/check_approval_user/`, payload);
	};

	getReviewStatus = (payload): Observable<any> => {
		return this.get("ux/common/imacd/imacd/check_review_user/", payload);
	};
	
	getFilterOptions(payload: any) {
		return this.get("ux/common/filters/filters/filter_options/", payload);
	}

	getSavedFilter(payload) {
		return this.get("ux/common/filters/filters/filter/", payload);
	}

	getFilteredIMACD(payload = {}) {
		return this.get("ux/common/imacd/imacd/top_filter/", payload);
	}

	deleteIMACD(pk) {
		return this.delete("ux/common/imacd/imacd/" + pk + "/");
	}
	editIMACD(imacd_id: any, payload: any) {
		return this.put("ux/common/imacd/imacd/" + imacd_id + "/", payload);
	}
}
