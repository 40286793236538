import { CoreConfig } from "@core/types";

/**
 * Default App Config
 *
 * ? TIP:
 *
 * Change app config based on your preferences.
 * You can also change them per component basis. i.e `app/main/pages/authentication/auth-login-v1/auth-login-v1.component.ts`
 *
 * ! IMPORTANT: Make sure you clear the browser local storage (https://developers.google.com/web/tools/chrome-devtools/storage/localstorage#delete)
 * ! If enableLocalStorage option is true, else it will not take the below config changes and use stored config from local storage. *
 *
 */

// prettier-ignore
export const coreConfig: CoreConfig = {
    app: {
        appName: 'Infraon',                                        // App Name
        appTitle: 'Infraon', // App Title
        appLogoImage: 'assets/images/infraon_logo.svg',                  // App Logo
        supportPortalLogoImage:'assets/images/support_logo.svg',
        // appIconImage: 'assets/images/infraon_logo_icon.svg',                  // App Logo
        appIconImage: 'assets/images/infraon_logoDNS.svg',
        appAdminLogo: 'assets/images/infraon-admin-logo.svg', // App Logo
        appLanguage: 'en'
    },
    layout: {
        skin: 'bordered', // 'default',                        // default, dark, bordered, semi-dark, fluent
        borderStyle : "bordered",
		layoutStyle : "fixed",
		themeColor : "default",
        type: 'vertical',                       // vertical, horizontal
        animation: 'fadeIn',                     // fadeInLeft, zoomIn , fadeIn, none
        adminPortal: false,
        supportPortal: false,
        menu: {
            hidden: false,           // Boolean: true, false
            showSearch: true,           // Boolean: true, false
            collapsed: true,           // Boolean: true, false
            logoCustomBackgroundColor: true,            // Boolean: true, false
            logoBackgroundColor: 'bg-card' ,    // BS color i.e bg-primary, bg-success
            logo:'',
            icon:''
        },
        // ? For horizontal menu, navbar type will work for navMenu type
        navbar: {
            hidden: false,           // Boolean: true, false
            showSearch: true,           // Boolean: true, false
            showCalendar: {},
            type: 'fixed-top', // 'fixed-top',  // navbar-static-top, fixed-top, floating-nav, d-none
            background: 'navbar-light',  // navbar-light. navbar-dark
            customBackgroundColor: true,            // Boolean: true, false
            backgroundColor: 'bg-card',    // BS color i.e bg-primary, bg-success
            pageTitle: '',
            pageIcon: '',
            buttonsRight: [],
            bookmark: false,
            loadHTML: false,
            configProgress: [],
            breadcrumb: {},
            showBreadcrumb: true,
            dropdown: true,

        },
        footer: {
            hidden: true,           // Boolean: true, false
            type: 'footer-static', // footer-static, footer-sticky, d-none
            background: 'footer-light',  // footer-light. footer-dark
            customBackgroundColor: false,           // Boolean: true, false
            backgroundColor: '',               // BS color i.e bg-primary, bg-success
            redirect_url:'',
            redirect_page:''
        },
        enableLocalStorage: false,
        customizer: false,                       // Boolean: true, false (Enable theme customizer)
        notifications: false,                       // Boolean: true, false (Enable notifications)
        scrollTop: false,                       // Boolean: true, false (Enable scroll to top button)
        buyNow: false                        // Boolean: true, false (Set false in real project, For demo purpose only)
    }
};
