import { Component, Input, OnInit } from '@angular/core';
import { NgEventBus } from "ng-event-bus";

@Component({
  selector: 'app-event-summary',
  templateUrl: './event-summary.component.html',
  styleUrls: ['./event-summary.component.scss']
})
export class EventSummaryComponent implements OnInit {
  @Input() config: any;
	@Input() modifiedtime: any;
  constructor(
		private eventBus: NgEventBus
	) {}
  ngOnInit(): void {
    //console.log("config", this.config);
  }
  showEventSidebar(dat){
		if (dat?.value > 0 && dat?.item_filter){
			let payload = {
				"title": "Event - " + dat.name,
				"app_type": "event-grid",
				"item_filter": dat.item_filter,
			}
			// console.log("in inci summary widget -> ", payload)
			this.eventBus.cast('dashboard-view:sidebar', payload);
		}
	}
}
