/**
 * This enum will contain all modules key
 */
export enum InfinityModuleIDs {
	USER = 1,
	USER_ROLES = 2,
	ORGANIZATION = 3,
	ORGANIZATION_LOCATION = 4,
	DASHBOARD = 7,
	REPORT = 8,
	REQUESTER = 9,
	INCIDENT = 10,
	MY_PROFILE = 5,
	AUDIT = 6,
	HISTORY = 19,
	BIZ_HOUR = 11,
	DEVICE_PROFILE = 12,
	HOLIDAY = 13,
	MESSENGER = 14,
	TAG = 15,
	CONFIGURATION_PARAMETERS = 48,
	DOWNLOAD_JOBS = 52,
	DISCOVERY = 16,
	PASSWORD_POLICY = 17,
	DEPARTMENT = 18,
	TEAMS = 21,
	LEAVES = 23,
	API_REGISTER = 24,
	SERVICE_CATALOGUE = 20,
	BUSINESS_CATALOGUE = 28,
	WORKFLOW = 25,
	AGENT = 26,
	KNOWLEDGEBASE = 27,
	CMDB = 29,
	EVENTS = 30,
	THRESHOLDS = 32,
	SLA = 33,
	// SERVICES = "services",
	JOBS = 35,
	SMS_CONFIG = 36,
	IMAP_CONFIG = 37,
	TEAM_ESCALATION = 39,
	REQUEST_PROCESS = 42,
	SOFTWARE_LICENSE = 41,
	NETWORK_DIAGRAM = 44,
	PROBLEM = 45,
	BUSINESS_RULE = 46,
	CHANGE = 47,
	TOPOLOGY = 51,
	RELEASE = 53,
	INFINITY_SUPPORT = 54,
	TEMPLATE = 58,
	WORKSPACE = 63,
	TASK = 50,
	SHIFTMANAGEMENT = 64,
	CONTRACT = 66,
	IMACD = 69,
	BOT_CONFIG = 67,
	UNMASK_REQUESTER_CONTACT = 73
}
