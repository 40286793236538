import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";

@Component({
	selector: "app-account-sidebar",
	templateUrl: "./account-sidebar.component.html",
	styleUrls: ["./account-sidebar.component.scss"],
})
export class AccountSidebarComponent implements OnInit {
	@Output() onMenuClick: EventEmitter<any> = new EventEmitter();
	@Input() isSelfServicePortal;
	globalSearch: any = false;
	selectedItem: any = 0;
	public configuration_list = [
		{
			id: 0,
			handle: "general",
			title: "UI.k_general",
			icon: "fa-user",
		},
		{
			id: 1,
			handle: "changePassword",
			title: "UI.k_change_password",
			icon: "fa-lock",
		},
		{
			id: 11,
			handle: "signature",
			title: "UI.k_signature",
			icon: "fa-signature",
		},
		{
			id: 2,
			handle: "information",
			title: "UI.k_information",
			icon: "fa-circle-info",
		},
	];
	constructor(private _coreSidebarService: CoreSidebarService,
		private _activatedRoute: ActivatedRoute,) {}

	toggleSidebar(nameRef, handle, index): void {
		this.onMenuClick.emit(handle);
		this.selectedItem = index;
		localStorage.setItem("lastMenuIndex", index);
	}

	ngOnInit(): void {
		// let menu = localStorage.getItem("lastMenuIndex");
		// if (menu !== null && menu !== undefined) {
		// 	this.selectedItem = Number(localStorage.getItem("lastMenuIndex"));
		// }
		// for(let d of this.configuration_list){
		// 	if(d['id'] == this.selectedItem ){
		// 		this.onMenuClick.emit(d['handle']);
		// 	}
		// }

		const sub = this._activatedRoute.queryParams.subscribe((params) => {
			if (params.globalSearch) {
				this.globalSearch = true;
				this.selectedItem = 3;
				this.toggleSidebar('account-settings-sidebar', this.configuration_list[this.selectedItem].handle, 
				this.selectedItem)
			} 
			else if (params.signature) {
				this.selectedItem = 2;
			} 
			else{
				this.toggleSidebar
			}
		 });
		 if(this.selectedItem == 3){
			this.configuration_list.push({
				id: 3,
				handle: "manageNotify",
				title: "UI.k_manage_notification",
				icon: "fa-gear",
			});
		}
		localStorage.removeItem("lastMenuIndex");

	}
}
