import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';
import { ApiService } from "app/common/services/api/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class MarketplaceService extends ApiService {
	constructor(httpClient: HttpClient) {
		super(httpClient);
	}

	getMarketplaceAPIKey() {
		return this.get("ux/common/marketplace/marketplace/apikey/");
	}
	getUserDataData(payload = {}): Observable<any> {
		return of({column:"Email",userData:["arun@infron.com","shilpa@gmail.com","asd@gmail.com","arun@infron.com","shilpa@gmail.com","asd@gmail.com","arun@infron.com","shilpa@gmail.com","asd@gmail.com","arun@infron.com","shilpa@gmail.com","asd@gmail.com"]});
	}

	getReferenceKeys(key: string, data: any) {
		// if (key !== "") {
		// 	return this.post("ux/common/marketplace/marketplace/" + key + "/", data);
		// }
		return this.post("ux/common/marketplace/marketplace/", data);
	}

	getAppStatus(params: any) {
		return this.get("ux/common/marketplace/marketplace/app_status/", params);
	}

	// verifyADAuth(payload = {}) {
	// 	return this.post("ux/common/ad/azuread/chk_conn/", payload);
	// }

	saveADMap(payload = {}) {
		return this.post("ux/common/marketplace/marketplace/save_map/", payload);
	}

	saveServiceNowMap(payload = {}) {
		return this.post("ux/common/marketplace/marketplace/save_servicenow_config/", payload);
	}

	getAzureSettingsConfiguration(payload) {
		return this.get("ux/common/marketplace/marketplace/azure_settings/", payload);
	}

	getAzureConfiguration(payload) {
		return this.get("ux/common/marketplace/marketplace/azure_config/", payload);
	}

	updateConfig = (payload) => {
		return this.post("ux/common/marketplace/marketplace/update_config/", payload);
	};

	downloadTeamManifest = (payload) => {
		return this.post("ux/common/marketplace/marketplace/download-teams-manifest/", payload);
	};

	uninstallApp = (payload) => {
		return this.post("ux/common/marketplace/marketplace/uninstall-app/", payload);
	};

	getTeamSettingsConfiguration(payload) {
		return this.get("ux/common/marketplace/marketplace/team_settings/", payload);
	}

	getassets(payload) {
		return this.get("ux/common/cmdb/profile/asset-warranty/", payload);
	}

	updateassetwarranty(payload) {
		return this.post("ux/common/cmdb/profile/update-warranty/", payload);
	}

	getServiceNowSettingsConfiguration(payload) {
		return this.get("ux/common/marketplace/marketplace/sn_settings/", payload);
	}
	
	getRingCentralSettingsConfiguration(payload) {
		return this.get("ux/common/marketplace/marketplace/rc_settings/", payload);
	}

	saveRingCentralConfig(payload = {}) {
		return this.post("ux/common/marketplace/marketplace/save_ring_central_config/", payload);
	}
	
	getWhatsAppConfiguration(payload) {
		return this.get("ux/common/marketplace/marketplace/whats_app_config/", payload);
	}

	saveWhatsAppMap(payload = {}) {
		return this.post("ux/common/marketplace/marketplace/save_whatsapp_map/", payload);
	}
	postSyncIdDetails(payload) {
		return this.post("ux/common/marketplace/marketplace/save_config_app/", payload);
	}
	get_requester_phone_no(payload) {
		return this.get("ux/common/marketplace/marketplace/requester_mobile_no/", payload);
	}

	getGoogleWorkspaceSettingsConfiguration(payload) {
		return this.get("ux/common/marketplace/marketplace/google_workspace_settings/", payload);
	}

	signInGoogle(payload = {}) {
		return this.post("ux/common/marketplace/marketplace/google_signin/", payload);
	}

	getGoogleAccessToken(payload = {}) {
		return this.post("ux/common/marketplace/marketplace/google-access-token/", payload);
	}

	getGoogleClientData(payload = {}) {
		return this.get("ux/common/marketplace/marketplace/google-client-data/", payload);
	}

	signInMS(payload = {}) {
		return this.post("ux/common/marketplace/marketplace/ms_signin/", payload);
	}

	getMicrosoftAccessToken(payload = {}) {
		return this.post("ux/common/marketplace/marketplace/microsoft-access-token/", payload);
	}

	getMarketplaceAppDetails(params: any) {
		return this.get("ux/common/marketplace/marketplace/app_details/", params);
	}

	getJiraSettingsConfiguration(payload) {
		return this.get("ux/common/marketplace/marketplace/jira_settings/", payload);
	}

	saveJiraConfig(payload = {}) {
		return this.post("ux/common/marketplace/marketplace/save_jira_config/", payload);
	}
	
	saveLDAPConfig(payload = {}) {
		return this.post("ux/common/marketplace/marketplace/save_ldap_config/", payload);
	}

	getJiraOptions(payload = {}) {
		return this.get("ux/common/marketplace/marketplace/jira_options/", payload);
	}

	update_jira_option_map(payload = {}) {
		return this.post("ux/common/marketplace/marketplace/save_sync_time_count/", payload);
	}
	getOptions(payload: {}) {
		return this.get("ux/common/marketplace/marketplace/options/", payload);
	}
}
