import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import { Subject } from "rxjs";
import { filter, map, take, takeUntil } from "rxjs/operators";
import { PerfectScrollbarDirective } from "ngx-perfect-scrollbar";

import { CoreConfigService } from "@core/services/config.service";
import { CoreMenuService } from "@core/components/core-menu/core-menu.service";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";

import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SearchMenuComponent } from "../../../../../base/system-menu/components/search-menu/search-menu.component";
import { AdminPopoverComponent } from "../../../../../base/system-menu/components/admin-popover/admin-popover.component";
import { CommonService } from "app/services/common.service";
import { UserService } from "app/base/users/services/user.service";
import Lottie from "lottie-web";
import { environment } from "environments/environment";
import store from "store";

@Component({
	selector: "vertical-menu",
	templateUrl: "./vertical-menu.component.html",
	styleUrls: ["./vertical-menu.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class VerticalMenuComponent implements OnInit, OnDestroy, AfterViewInit {
	coreConfig: any;
	menu: any;
	isCollapsed: boolean;
	isScrolled: boolean = false;
	currentscreen: any;
	tempScreen: any;
	activeToggale = false;
	logo: any;
	icon: any;
	public isAdminUser = false;
	public isSuperAdminUser = false;
	public isPartnerUser = false;
	// Private
	private _unsubscribeAll: Subject<any>;
	redirectURL = "";
	current_date = new Date().getTime()
	regex = /data:image\/\w+;base64,/;
	/**
	 * Constructor
	 *@param {NgbModal} modalService
	 * @param {CoreConfigService} _coreConfigService
	 * @param {CoreMenuService} _coreMenuService
	 * @param {CoreSidebarService} _coreSidebarService
	 * @param {Router} _router
	 */
	constructor(
		private _coreConfigService: CoreConfigService,
		private _coreMenuService: CoreMenuService,
		private _coreSidebarService: CoreSidebarService,
		private _router: Router,
		private modalService: NgbModal,
		private _commonService: CommonService,
		private _userService: UserService
	) {
		// Set the private defaults
		this._unsubscribeAll = new Subject();
		this._commonService.productToggal.subscribe((val) => {
			this.activeToggale = val;
		});
	}

	@ViewChild(PerfectScrollbarDirective, { static: false })
	directiveRef?: PerfectScrollbarDirective;

	// Lifecycle Hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On Init
	 */
	ngOnInit(): void {
		// this.redirectURL = environment?.management_url + "admin/dashboard";
		// Subscribe config change
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
			this.coreConfig = config;
			this.current_date = new Date().getTime()
			if (this.coreConfig.layout?.menu?.logo != "") {
				this.logo = this.coreConfig.layout?.menu?.logo;
			} else {
				if (localStorage.getItem("imglogo") != "null" && localStorage.getItem("imglogo") != "undefined") {
					this.logo = localStorage.getItem("imglogo");
				} else {
					this.logo = "assets/images/infraon_logo.svg";
				}
			}
			if (this.coreConfig.layout?.menu?.icon != "") {
				this.icon = this.coreConfig.layout?.menu?.icon;
			} else {
				this.icon = localStorage.getItem("imgicon");
			}
		});

		this.isCollapsed = this._coreSidebarService.getSidebarRegistry("menu").collapsed;

		// Close the menu on router NavigationEnd (Required for small screen to close the menu on select)
		this._router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				takeUntil(this._unsubscribeAll)
			)
			.subscribe(() => {
				if (this._coreSidebarService.getSidebarRegistry("menu")) {
					this._coreSidebarService.getSidebarRegistry("menu").close();
				}
			});

		// scroll to active on navigation end
		this._router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				take(1)
			)
			.subscribe(() => {
				setTimeout(() => {
					this.directiveRef.scrollToElement(".navigation .active", -180, 500);
				});
			});

		// Get current menu
		this._coreMenuService.onMenuChanged
			.pipe(
				filter((value) => value !== null),
				takeUntil(this._unsubscribeAll)
			)
			.subscribe(() => {
				this.menu = this._coreMenuService.getCurrentMenu();
			});
		if (!environment?.management && localStorage.getItem("accessToken")) {
			this._userService
				.getCurrentUserProfile()
				.pipe(
					map((response: any) => {
						if (response) {
							if (response.is_admin) {
								if (response?.profile?.role_type == 3 && response?.show_management) {
									this.isSuperAdminUser = true;
								}
								if (response?.is_partner) {
									this.isPartnerUser = true;
								}
								this.isAdminUser = true;
								setTimeout(() => {
									var iconContainer = document.getElementById("get-container");
									var iconTrigger = document.getElementById("get-trigger");
									var iconContainer = document.getElementById("get-container");
									var icon = Lottie.loadAnimation({
										container: iconContainer,
										path: "../../../../../assets/images/lotty/getting-started.json",
										renderer: "svg",
										loop: true,
										autoplay: false,
									});
									if (iconTrigger) {
										iconTrigger.addEventListener(
											"mouseover",
											() => {
												icon.play();
											},
											false
										);

										iconTrigger.addEventListener(
											"mouseout",
											() => {
												icon.stop();
											},
											false
										);
									}
								}, 300);
							} else {
								this.isAdminUser = false;
							}
						}
						// Success
					})
				)
				.subscribe();
		}
	}

	openModal() {
		let cssClass = "animation-disable modal-dialog-custom";
		if (this.isCollapsed) {
			cssClass = cssClass + " collapsed-left";
		} else {
			cssClass = cssClass + " expanded-left";
		}
		this.modalService.open(SearchMenuComponent, {
			centered: false,
			windowClass: cssClass,
			scrollable: true,
			//animation: false,
			size: "lg",
		});
	}

	openModalAD() {
		let cssClass = "animation-disable modal-dialog-admin";
		if (this.isCollapsed) {
			cssClass = cssClass + " collapsed-left";
		} else {
			cssClass = cssClass + " expanded-left";
		}
		this.modalService.open(AdminPopoverComponent, {
			centered: false,
			windowClass: cssClass,
			animation: false,
			size: "xs",
		});
	}

	/**
	 * On Destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(undefined);
		this._unsubscribeAll.complete();
	}

	// Public Methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On Sidebar scroll set isScrolled as true
	 */
	// onSidebarScroll(): void {
	// 	if (this.directiveRef.position(true).y > 3) {
	// 		this.isScrolled = true;
	// 	} else {
	// 		this.isScrolled = false;
	// 	}
	// }

	/**
	 * Toggle sidebar expanded status
	 */
	toggleSidebar(): void {
		this._coreSidebarService.getSidebarRegistry("menu").toggleOpen();
	}

	/**
	 * Toggle sidebar collapsed status
	 */
	toggleSidebarCollapsible(): void {
		// Get the current menu state
		this._coreConfigService
			.getConfig()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((config) => {
				this.isCollapsed = config.layout.menu.collapsed;
			});

		if (this.isCollapsed) {
			this._coreConfigService.setConfig({ layout: { menu: { collapsed: false } } }, { emitEvent: true });
		} else {
			this._coreConfigService.setConfig({ layout: { menu: { collapsed: true } } }, { emitEvent: true });
		}
	}
	ngAfterViewInit() {
		var iconTrigger = document.getElementById("get-trigger");
		var iconContainer = document.getElementById("get-container");

		var icon = Lottie.loadAnimation({
			container: iconContainer,
			path: "../../../../../assets/images/lotty/getting-started.json",
			renderer: "svg",
			loop: true,
			autoplay: false,
		});
		if (iconTrigger) {
			iconTrigger.addEventListener(
				"mouseover",
				() => {
					icon.play();
				},
				false
			);

			iconTrigger.addEventListener(
				"mouseout",
				() => {
					icon.stop();
				},
				false
			);
		}
	}

	ssoToAdminPortal = (event) => {
		event.preventDefault();
		let accessToken = store.get("accessToken");
		let abs_domain = environment?.management_url + "redirect/admin/" + accessToken;
		window.open(abs_domain, "_blank");
		// let instance_win = window.open(abs_domain, "_blank");
	};
}
