import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgEventBus } from "ng-event-bus";

@Component({
	selector: "app-panel-status-widget",
	templateUrl: "./panel-status-widget.component.html",
	styleUrls: ["./panel-status-widget.component.scss"],
})
export class PanelStatusWidgetComponent implements OnInit {
	@Input() widgetData: any;
	@Input() modifiedtime: any;
	constructor(private eventBus: NgEventBus, private _translateService: TranslateService) {}
	extracolumns: {}[];

	ngOnInit(): void {
		this.extracolumns = [
			{
				label: this._translateService.instant("UI.k_polling"),
				key: "active",
				subkey: "poll_configs",
				remove: true,
			},
		];
	}

	showDataSidebar(dat) {
		let payload = {};
		if (this.widgetData?.object_type == "Resource") {
			payload = {
				title: "Resource List",
				app_type: "resource-grid",
				item_filter: dat.filters,
				extra_columns: this.extracolumns,
				sortKey: "common_info__operational_status__name",
				reverse: "",
			};
		} else {
			payload = {
				title: "Node List",
				app_type: "asset-grid",
				item_filter: dat.filters,
				sortKey: "common_info__operational_status__name",
				reverse: "",
			};
		}
		this.eventBus.cast("dashboard-view:sidebar", payload);
	}
}
