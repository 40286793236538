import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { SupportPortalService } from "app/support-portal/services/support-portal.service";
import Stepper from "bs-stepper";
import { FileUploader } from "ng2-file-upload";
import { CountryISO, PhoneNumberFormat, SearchCountryField } from "ngx-intl-tel-input";
import { ToastrService } from "ngx-toastr";
import { catchError, map, throwError } from "rxjs";
import store from "store";

var AES = require("crypto-js/aes");
const URL = "http://localhost:8080/common/user/profileimage/";

@Component({
	selector: "app-organization-add",
	templateUrl: "./organization-add.component.html",
	styleUrls: ["./organization-add.component.scss"],
})
export class OrganizationAddComponent implements OnInit {
	@Output("onHideEvent") onHideEvent = new EventEmitter<any>();
	@Input() action: String;
	@Input() organization_id: Number;
	@Input() currentEvent: String;
	@Input() callType: String;
	@ViewChild("organizationAddForm") organizationAddForm;
	basicDetailForm: UntypedFormGroup;
	public submitted: boolean = false;
	organization: any = { user: 10, asset: 1000, is_perpetual: false };
	public imageDetail: boolean;
	public setImage: boolean;
	public change: boolean;
	public fullName;
	public currentAvatarColor;
	public tempImage;
	public imageBase64: string;
	employeeStrength: any;
	selectedCompanysize: any;
	public currentImgDetails;
	public activeAvatar;
	CountryISO = CountryISO;
	separateDialCode = true;
	regionData: any;
	selectedRegion: any;
	selectedPlan: any;
	selectedPlan_type: any;
	plan: any;
	planType: any;
	selectedhost_type: any;
	hostType: any;
	SearchCountryField = SearchCountryField;
	PhoneNumberFormat = PhoneNumberFormat;
	machine_ID: any;
	checkAvailability: boolean = false;
	machineIdSelected;
	preferredCountries: CountryISO[] = [
		CountryISO.UnitedStates,
		CountryISO.UnitedKingdom,
		CountryISO.Australia,
		CountryISO.NewZealand,
		CountryISO.Germany,
		CountryISO.Spain,
		CountryISO.India,
		CountryISO.Japan,
		CountryISO.China,
		CountryISO.Singapore,
		CountryISO.UnitedArabEmirates,
		CountryISO.Brazil,
	];
	public uploader: FileUploader = new FileUploader({
		url: URL,
		isHTML5: true,
	});
	salesOrderNo;
	public basicDPdata: NgbDateStruct;

	numberOfassets: any;
	numberOfuser: any;
	selectedAssetssize: [];
	selectedUsersize: [];

	purchaseOrderNo;

	private modernWizardStepper: Stepper;
	private bsStepper;
	public is_save_disabled = false;
	//image uploader
	public imgDetailsObject = {
		aspectRatio: "4/ 2",
		resizeToWidth: "128",
		cropperMinWidth: "128",
		maintainAspectRatio: true,
	};

	emailCheckLoading: boolean = false;
	isEmailAvailable = false;
	isSubmitDisabled = false;
	emailExistsMessage: string = "";
	isOnPremise: boolean = false;
	/**
	 * Constructor
	 *
	 * @param {CoreConfigService} _coreConfigService
	 *  @param _formBuilder
	 */
	currentPlan = "";
	pricingType = "yearly";

	selectedProduct: any;
	activePlan;

	public dropdownOptions = {
		hostTypes: [""],
		companySizes: [],
		regions: [],
		products: [],
		plans: [],
		itsmCount: [],
		assetCount: [],
		partners: [],
		on_premises: [],
	};
	public initialEmail = "";
	public isSupportManager = false;
	public accessPerpetual = 5;
	public minDate = {
		year: new Date().getFullYear(),
		month: new Date().getMonth() + 1,
		day: new Date().getDate(),
	};
	public assetCount;
	public isPartner: boolean;

	constructor(
		private _coreSidebarService: CoreSidebarService,
		private _toastr: ToastrService,
		private _translateService: TranslateService,
		private _formBuilder: UntypedFormBuilder,
		private _supportService: SupportPortalService
	) {}

	ngOnInit(): void {
		this.getOptions();
		this.getRoleType();
		this.modernWizardStepper = new Stepper(document.querySelector("#stepper3"), {
			linear: true,
			animation: true,
		});
		this.isPartner = store.get("is_partner");

		this.bsStepper = document.querySelectorAll(".bs-stepper");

		this.basicDetailForm = this._formBuilder.group({
			company_name: ["", [Validators.required, this.whitespaceValidator]],
			company_size: [""],
			fullname: ["", [Validators.required, this.whitespaceValidator]],
			business_email: ["", [Validators.required, Validators.email]],
			phone: ["", Validators.required],
			region: [""],
			datacenter: [""],
			hostType: [""],
			partner: ["", this.partnerValidator()],
			comment: [""],
		});
	}

	ngOnChanges() {
		if (this.organization_id && this.organization_id !== -1) {
			this.getOrganization(this.organization_id);
		}
	}

	get basicDetailFormControl() {
		return this.basicDetailForm.controls;
	}

	partnerValidator(): ValidatorFn {
		return this.callType === "partner" ? Validators.required : Validators.nullValidator;
	}

	changePreferredCountries() {
		this.preferredCountries = [CountryISO.India, CountryISO.Canada];
	}
	toggleSidebar(name): void {
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
		this.onHideEvent.emit("hide");
	}
	onOrganizationSave(data): void {
		if (this.basicDetailForm.valid) {
		}
	}
	public whitespaceValidator(control: UntypedFormControl) {
		const isWhitespace = (control.value || "").trim().length < 3;
		const isValid = !isWhitespace;
		return isValid ? null : { whitespace: true };
	}
	removeImage = ($event) => {
		this.tempImage = this.organization["profile_image"];
		this.organization["profile_image"] = "";
		this.imageBase64 = null;
		this.imageDetail = false;
		// this.showPreview = false;
	};

	profileImage = ($event) => {
		this.imageBase64 = $event;
		this.organization.profile_image = $event;
		this.imageDetail = true;
		this.change = true;
	};
	ProfileBgColor = ($event) => {
		this.currentAvatarColor = $event;
	};
	currrentColor = ($event) => {
		if ($event == "image") {
			this.organization["profile_image"] = this.tempImage;
		} else {
			//this.getorganization($event);
		}
	};
	modernHorizontalPrevious() {
		this.modernWizardStepper.previous();
	}

	modernHorizontalNext(data, formName) {
		this.submitted = true;

		if (data.invalid) {
			this.isSubmitDisabled = false;
			return;
		} else {
			if (formName === "org") {
				this.checkSupportDateVisibility();
			}
			this.modernWizardStepper.next();
		}
	}
	onCheck(event) {
		if (event.target.checked) {
			this.checkAvailability = true;
		} else {
			this.checkAvailability = false;
		}
		this.organization["is_high_availability"] = this.checkAvailability;
		this.organization["machine_ids"] = "";
	}
	onSave = (data) => {
		this.is_save_disabled = true;
		if (data.form.valid === true) {
			this.organization["email"] = this.organization["business_email"];
			this._supportService
				.saveOrganization(this.organization)
				.pipe(
					map((response: any) => {
						if (response) {
							this.is_save_disabled = false;
							if (response["status"] == "success") {
								// Closing the toolbar
								this.organization = {};
								this.onHideEvent.emit(this.currentEvent);
								this._coreSidebarService.getSidebarRegistry("add-organization").toggleOpen();
								this._toastr.success(
									this._translateService.instant("Message.msg_org_created"),
									this._translateService.instant("Message.msg_save_success"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							} else {
								this._toastr.error(
									this._translateService.instant("Error.err_org_save_failed"),
									this._translateService.instant("Error.err_save_failed"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
								this.is_save_disabled = false;
							}
						}
						// Success
					}),
					catchError((error) => {
						this.is_save_disabled = false;
						this._toastr.error(
							this._translateService.instant("Error.err_org_save_failed"),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						return throwError(error.statusText);
					})
				)
				.subscribe();
		} else {
			this.is_save_disabled = false;
			data.submitted = false;
		}
	};

	encryptPassword(password: string) {
		var iv = "d99630bcc7058639e7e80df239f38a32";
		var ciphertext = AES.encrypt(password, iv).toString();
		var aesPassword = iv + "::" + ciphertext;
		return btoa(aesPassword);
	}

	checkEmailAvailability() {
		if (!(this.currentEvent === "edit" && this.initialEmail === this.organization.business_email)) {
			if (!this.basicDetailFormControl.business_email.valid) {
				return;
			}
			let payload = {};
			payload["enc_email"] = this.encryptPassword(this.basicDetailFormControl.business_email.value);
			if (this.currentEvent === "edit") {
				payload["id"] = this.organization_id;
			}
			this.emailCheckLoading = true;
			this._supportService.checkEmailAvailability(payload).subscribe(
				(response: any) => {
					this.emailCheckLoading = false;
					if (response && response.status == "success") {
						this.isEmailAvailable = true;
						this.emailExistsMessage = response.message;
					} else {
						this.isEmailAvailable = false;
						this.basicDetailFormControl.business_email.setErrors({
							email_exists: true,
						});
						this.emailExistsMessage = response.message;
					}
				},
				(error) => {
					this.emailCheckLoading = false;
				}
			);
		}
	}
	onChangeProduct = (e) => {
		this.dropdownOptions["plans"] = e.plans;
		this.currentPlan = e.key;
		this.organization["plan"] = e.plans[0];
		if (this.currentPlan === "Infraon-Helpdesk") {
			this.organization["user"] = 999;
			this.organization["asset"] = 0;
		} else if (this.currentPlan === "Infraon-Assets") {
			this.organization["asset"] = 1000;
			this.organization["user"] = this.dropdownOptions["asset_user_map"]["1000"];
		} else {
			this.organization["asset"] = 0;
			this.organization["user"] = 10;
		}
		if (this.currentPlan === "Infraon-OSS" || this.currentPlan === "Infraon-UNMS") {
			this.organization["services"] = 0;
			this.organization["circuits"] = 0;
			this.organization["ems"] = 0;
			this.organization["qos"] = 0;
			this.organization["cos"] = 0;
			this.organization["interfaces"] = 0;
		}
	};

	onChangePlan = (e) => {
		if (this.currentPlan === "Infraon-ITSM" && e !== "Essential") {
			this.organization["user"] = 10;
			this.organization["asset"] = 1000;
		}
		if (this.currentPlan === "Infraon-ITSM" && e === "Essential") {
			this.organization["user"] = 10;
			this.organization["asset"] = 0;
		}
		if (this.currentPlan === "Infraon-Assets" && e === "Enterprise") {
			this.assetCount = this.dropdownOptions["assetEnterpriseCount"];
			this.organization["asset"] = 5000;
			this.organization["user"] = 50;
		}
		if (this.currentPlan === "Infraon-Assets" && e !== "Enterprise") {
			this.assetCount = this.dropdownOptions["assetCount"];
		}
	};

	onChangeAssetCount = (e) => {
		if (this.currentPlan === "Infraon-Assets") {
			this.organization["user"] = this.dropdownOptions["asset_user_map"][e];
		}
	};

	getOptions() {
		this._supportService.getOptions({}).subscribe(
			(response) => {
				if (response) {
					// if (this.currentEvent === "add") {
					// 	this.dropdownOptions["hostTypes"] = response["host_types"].filter((host_obj) => {
					// 		return response["on_premises"].includes(host_obj.value);
					// 	});
					// } else {
					this.dropdownOptions["on_premises"] = response["on_premises"];
					this.dropdownOptions["hostTypes"] = response["host_types"];
					// }
					this.dropdownOptions["regions"] = response["regions"];
					this.dropdownOptions["companySizes"] = response["company_sizes"];
					this.dropdownOptions["products"] = response["active_products"];
					this.dropdownOptions["assetCount"] = response["asset_count"];
					this.assetCount = response["asset_count"];
					this.dropdownOptions["assetEnterpriseCount"] = response["enterprise_asset_count"];
					this.dropdownOptions["itsmCount"] = response["itsm_count"];
					this.dropdownOptions["asset_user_map"] = response["asset_user_map"];
					this.dropdownOptions["partners"] = response["partners"];
					if (this.currentEvent === "add") {
						this.dropdownOptions["plans"] = response["active_products"][0]?.plans;
						this.selectedProduct = response["active_products"][0];
						this.currentPlan = this.selectedProduct.key;
						this.organization["product_name"] = this.currentPlan;
						this.organization["plan"] = this.dropdownOptions["plans"][0];
						this.organization["datacenter"] = response["regions"][0]?.dc_id;
						if (this.isPartner) {
							this.organization["host_type"] = response["host_types"][0]?.value;
						} else {
							this.organization["host_type"] = response["host_types"][2]?.value;
						}
					} else {
						this.selectedProduct = this.getSelectedProduct(response["active_products"]);
						this.dropdownOptions["plans"] = this.selectedProduct?.plans;
						this.currentPlan = this.selectedProduct.key;
						this.organization["product_name"] = this.currentPlan;
						this.checkAvailability = this.organization["is_high_availability"];
						if (this.isPartner) {
							this.organization["host_type"] = response["host_types"][0]?.value;
						}
					}
					// console.log("response", response);
					// console.log("this.selectedProduct", this.selectedProduct);
					// console.log("this.currentPlan", this.currentPlan);
					// console.log("this.organization", this.organization);
					// let imageDefaults = response["image_upload_defaults"];
					// this.ALLOWED_TYPES = imageDefaults["allowed_types"];
					// this.MAX_IMAGE_SIZE = imageDefaults["max_size"];
					// this.MAX_HEIGHT = imageDefaults["max_height"];
					// this.MAX_WIDTH = imageDefaults["max_width"];
				}
			},
			(error) => {
				//todo
			}
		);
	}

	getRoleType = () => {
		this._supportService.getRoleType({}).subscribe(
			(response) => {
				if (response) {
					if (response?.role_type === this.accessPerpetual) {
						this.isSupportManager = true;
					}
				}
			},
			(error) => {
				//todo
			}
		);
	};

	getOrganization(organization_id: Number): void {
		this._supportService
			.getOrganization(organization_id)
			.pipe(
				map((response: any) => {
					// console.log("response in getOrganization", response);
					if (response) {
						if (response["organization_id"] !== null || response["organization_id"] !== undefined) {
							this.organization = response;
							this.selectedProduct = this.getSelectedProduct(this.dropdownOptions["products"]);
							this.dropdownOptions["plans"] = this.selectedProduct?.plans;
							this.currentPlan = this.selectedProduct.key;
							this.organization["product_name"] = this.currentPlan;
							this.checkAvailability = this.organization["is_high_availability"];
							this.initialEmail = response?.business_email;
						}
					}
					// Success
				}),
				catchError((error) => {
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}

	getSelectedProduct = (products) => {
		return products.find((x) => x?.key === this.organization?.product_name);
	};

	onToggle(event) {
		if (event.checked) {
			this.organization["is_perpetual"] = true;
			this.organization["expiry_date"] = "";
		} else {
			this.organization["is_perpetual"] = false;
		}
	}

	checkSupportDateVisibility = () => {
		this.isOnPremise = this.dropdownOptions["on_premises"].includes(this.organization?.host_type);
	};
}
