import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { CoreConfigService } from "@core/services/config.service";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { ActivatedRoute } from "@angular/router";
import { interval, Subject, throwError } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { AuditService } from "../../services/audit.service";
import { UserService } from "../../../users/services/user.service";
import { Breadcrumb } from "app/common/layout/components/content-header/breadcrumb/breadcrumb.component";
import { catchError, map } from "rxjs/operators";
import { GlobalConstants } from "app/app.globalConstants";
import { InfinityModules } from "app/common/dns/types/modules";
import {InfraonMixpanelService} from "app/services/mixpanel/infraon-mixpanel.service";

@Component({
	selector: "app-audit-grid",
	templateUrl: "./audit-grid.component.html",
	styleUrls: ["./audit-grid.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class AuditGridComponent implements OnInit, OnDestroy {
	@Input() source = "";
	audits: any;
	columns: Array<any>;
	options: any;
	SelectionType = SelectionType;
	ColumnMode = ColumnMode;
	pageNumber = 0;
	limit = 10;
	auditCount = 0;
	sortKey: any = "creation_time";
	reverse: any = "-";
	private _unsubscribeAll: Subject<any>;
	public isBookmarked: boolean = false;
	showBreadcrumb: any = false;
	module = InfinityModules.AUDIT;
	permissions: any;
	public breadcrumbObj: Breadcrumb;
	searchOptions: any;
	searchParams: any;
	moduleName = "Audit";
	public showData = false;
	public empty_data;
	public filter = [];
	public filterData = false;
	// Search
	public showRightsection: false;
	public showDataTable = false;
	public savedData = [];
	public columnOptions = [];
	public conditionOptions = [];
	public option = [];
	public showAll = false;
	timeFilterObj: any = {
		name: this._translateService.instant("UI.k_last_60_days"),
		key: "last60days",
		addProfile: "",
		startDate: null,
		endDate: null,
		is_configure: false,
		selected: false,
		time_func: "last60days",
		label: this._translateService.instant("UI.k_last_60_days"),
	};
	pageReload: any;
	isExpandSearchWidth: boolean;

	constructor(
		private _coreConfigService: CoreConfigService,
		private _activatedRoute: ActivatedRoute,
		private _translateService: TranslateService,
		private _auditService: AuditService,
		private _userService: UserService,
		private _mixpanelService: InfraonMixpanelService
	) {
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void {
		this.getFilterOption();
		this.getSavedFilters();
		this.empty_data = {
			icon: "fa-history",
			msg: this._translateService.instant("Message.msg_audit_skeleton_heading"),
			description: this._translateService.instant("Message.msg_audit_skeleton_descr"),
			import_csv: false,
		};
		const sub = this._activatedRoute.queryParams.subscribe((params) => {
			if (params.showBreadcrumb) {
				this.showBreadcrumb = true;
			}
			this.getFilterOptions();
		});
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.permissions = user_permissions[this.module];
		this.setNavbarConfig();
		this.audits = [];
		this.columns = [
			{
				label: this._translateService.instant("UI.k_module"),
				key: "module_type",
			},
			{
				label: this._translateService.instant("UI.k_event"),
				key: "event",
			},
			{
				label: this._translateService.instant("UI.k_description"),
				key: "description",
				type: "html",
				width: 280,
			},
			{
				label: this._translateService.instant("UI.k_user_name"),
				key: "user_name",
			},
			{
				label: this._translateService.instant("UI.k_ip_address"),
				key: "user_ip",
			},
			{
				label: this._translateService.instant("UI.k_creation_time"),
				key: "creation_time",
			},
		];
		this.getAuditList();
		this.autoReload(5);
		this._mixpanelService.trackEvent("Audit", {"view": "Data loaded"})
	}

	autoReload(mins = 1) {
		let rinterval = mins * 60 * 1000;
		this.pageReload = interval(rinterval).subscribe((val) => {
			this.getAuditList();
		});
	}

	setNavbarConfig(): void {
		// Configure the layout
		if (this.source === "support") {
			this._coreConfigService.config = {
				layout: {
					navbar: {
						hidden: false,
						pageTitle: this._translateService.instant("UI.k_audit"),
						moduleName: "Audits",
						bookmark: this.isBookmarked,
						showBreadcrumb: false,
						enableLocalStorage: false,
						pageIcon: "fa-history",
						search: [
							{
								popup: this.searchOptions, //this.searchParams,
								callback: this.searchAudit,
							},
						],
						breadcrumb: {},
					},
				},
			};
		} else {
			this._coreConfigService.config = {
				layout: {
					navbar: {
						hidden: false,
						pageTitle: this._translateService.instant("UI.k_audit"),
						moduleName: "Audits",
						bookmark: this.isBookmarked,
						showBreadcrumb: this.showBreadcrumb,
						enableLocalStorage: false,
						pageIcon: "fa-history",
						search: [
							{
								popup: this.searchOptions, //this.searchParams,
								callback: this.searchAudit,
							},
						],
						breadcrumb: {
							type: "",
							links: [
								{
									name: this._translateService.instant("UI.k_general_settings"),
									isLink: true,
									link: "/configuration",
								},
								{
									name: this._translateService.instant("UI.k_audit"),
									isLink: false,
									link: "/",
								},
							],
						},
					},
				},
			};
		}
	}

	ngOnDestroy(): void {
		try {
			if (this.pageReload) this.pageReload.unsubscribe();
		} catch (e) {}
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(undefined);
		this._unsubscribeAll.complete();
	}

	onUpdate(args) {
		//console.log(args);
	}

	onSort(args): void {
		if (args.sorts) {
			this.pageNumber = 0;
			this.sortKey = args.sorts[0].prop;
			if (this.sortKey == "module") {
				this.sortKey = "module_type";
			}
			if (this.sortKey == "userName") {
				this.sortKey = "user_name";
			}
			if (this.sortKey == "ipAddress") {
				this.sortKey = "user_ip";
			}
			if (this.sortKey == "creationTime") {
				this.sortKey = "creation_time";
			}
			if (args.sorts[0].dir == "desc") {
				this.reverse = "-";
			} else {
				this.reverse = "";
			}
		}
		this.getAuditList();
	}

	searchAudit = (args) => {
		const payload = {
			page: this.pageNumber + 1,
			items_per_page: this.limit,
			sort: this.sortKey,
			reverse: this.reverse,
			filterString: args.trim(),
			time_filter: JSON.stringify(this.timeFilterObj),
			moduleName: "audit",
		};
		this._auditService.getAuditList(payload).subscribe(
			(response) => {
				if (response) {
					this.audits = response.results;
					this.auditCount = response.count;
					this.showData = true;
				}
			},
			(error) => {
				//todo
			}
		);
	};

	getUserPreferences(): void {
		// function to get user page preferences
		this._userService
			.getPreferences()
			.pipe(
				map((response: any) => {
					if (response) {
						let bookmarks = response.bookmarks;
						if (bookmarks && bookmarks.includes(this.moduleName)) {
							this.isBookmarked = true;
						}
					} else {
					}
					// Success
				}),
				catchError((error) => {
					return throwError(error.statusText);
				})
			)
			.subscribe();
		this.setNavbarConfig();
	}

	getFilterOptions(): void {
		this._userService.getFilterOptions({ moduleName: "audit" }).subscribe(
			(response) => {
				if (response) {
					this.searchOptions = response;
					this.getUserPreferences();
				} else {
					this.searchOptions = {};
				}
			},
			(error) => {
				this.searchOptions = {};
			}
		);
	}

	getAuditList() {
		const payload = {
			page: this.pageNumber + 1,
			items_per_page: this.limit,
			sort: this.sortKey,
			reverse: this.reverse,
			filter: JSON.stringify(this.filter),
			time_filter: JSON.stringify(this.timeFilterObj),
			module: "audit",
		};
		this._auditService.getAuditList(payload).subscribe(
			(response) => {
				if (response) {
					this.audits = response.results;
					this.auditCount = response.count;
					this.showData = true;
				}
			},
			(error) => {
				//todo
			}
		);
	}

	setPage(pageInfo) {
		this.pageNumber = pageInfo.offset;
		this.getAuditList();
	}

	setLimit(limit): void {
		if (limit === "all") {
			this.showAll = true;
			this.limit = this.auditCount;
		} else {
			this.limit = Number(limit);
			this.showAll = false;
		}
		this.pageNumber = 0;
		this.getAuditList();
	}

	getFilterSearch = ($event) => {
		let dict = {};
		this.filter = [];
		dict["ui_display"] = $event.userSearchData;
		dict["filter_string"] = $event.userSearchString;
		dict["filter_item"] = $event.filterItem;
		dict["type"] = $event.type;
		dict["module"] = "audit";
		this.filter.push(dict);
		this.pageNumber = 0;
		const payload = {
			page: 1,
			items_per_page: this.limit,
			sort: this.sortKey,
			reverse: this.reverse,
			filter: JSON.stringify(this.filter).replace(/\+/gi, "%2B"),
			time_filter: JSON.stringify(this.timeFilterObj),
			module: "audit",
		};

		this.empty_data = {
			icon: "fa-user-group",
			msg: this._translateService.instant("UI.k_no_filtered_data"),
		};
		this.getAuditData(payload);
	};

	getAuditData(payload) {
		this._auditService.getAuditList(payload).subscribe(
			(response) => {
				if (response) {
					this.audits = response.results;
					this.auditCount = response.count;
					this.showData = true;
					this.filterData = true;
				}
				this.getSavedFilters();
			},
			(error) => {
				//todo
			}
		);
	}

	getFilterOption(): void {
		this._auditService.getFilterOptions({ moduleName: "audit" }).subscribe(
			(response) => {
				if (response) {
					this.columnOptions = response;
				} else {
					this.columnOptions = [];
				}
			},
			(error) => {
				this.columnOptions = [];
			}
		);
	}

	getSavedFilters(): void {
		this._auditService
			.getSavedFilter({ module: "audit" })
			.pipe(
				map((response: any) => {
					if (response) {
						this.savedData = response;
					}
				})
			)
			.subscribe();
	}

	onRangeSelect($event) {
		this.timeFilterObj = $event;

		const payload = {
			page: this.pageNumber + 1,
			items_per_page: this.limit,
			sort: this.sortKey,
			reverse: this.reverse,
			filter: JSON.stringify(this.filter),
			time_filter: JSON.stringify(this.timeFilterObj),
			module: "audit",
		};
		this._auditService.getAuditList(payload).subscribe(
			(response) => {
				if (response) {
					this.audits = response.results;
					this.auditCount = response.count;
					this.showData = true;
				}
			},
			(error) => {
				//todo
			}
		);
	}

	genericSearchFilter = ($event) => {
		let search_str = $event.userSearchData.trim();
		let search_db_cols = [
			{ db_col: "module_type", cond: "contains" },
			{ db_col: "event", cond: "contains" },
			{ db_col: "raw_description", cond: "contains" },
			{ db_col: "user_name", cond: "contains" },
			{ db_col: "user_ip", cond: "contains" },
		];
		let filter_options = [];
		search_db_cols.forEach((item) => {
			filter_options.push({
				condition: item.cond,
				db_col: item.db_col,
				value: search_str,
				relation: "or",
			});

			if (filter_options.length == search_db_cols.length) {
				this.pageNumber = 0;
				let dict = {};
				dict["ui_display"] = $event.userSearchData;
				dict["filter_string"] = filter_options;
				dict["filter_item"] = $event.filterItem;
				dict["type"] = $event.type;
				dict["module"] = "audit";
				for (let i = 0; i < this.filter.length; i++) {
					dict["filter_string"].push(this.filter[i]);
				}

				this.filter = [];
				this.filter.push(dict);
				const payload = {
					page: 1,
					items_per_page: this.limit,
					sort: this.sortKey,
					reverse: this.reverse,
					filter: JSON.stringify(this.filter).replace(/\+/gi, "%2B"),
					time_filter: JSON.stringify(this.timeFilterObj),
					module: "audit",
				};

				this.getAuditData(payload);
			}
		});
	};
	getSearchWidth = (e) => {
		if(e){
			this.isExpandSearchWidth = true;
		}
		else{
			this.isExpandSearchWidth = false;
		}
	}
}
