import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CoreCommonModule } from '@core/common.module';
import { CoreMenuModule } from '@core/components';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SystemMenuModule } from 'app/base/system-menu/system-menu.module';
import { SharedModule } from 'app/shared.module';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { AdminMenuComponent } from "app/common/layout/components/menu/admin-menu/admin-menu.component";
import { AdminModule } from 'app/admin/admin.module';
import { NgScrollbarModule } from 'ngx-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true,
	wheelPropagation: false,
};
@NgModule({
  providers: [
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
		},
	],
  imports: [
    CoreMenuModule,
		CoreCommonModule,
		PerfectScrollbarModule,
		RouterModule,
		SystemMenuModule,
		NgbModule,
		SharedModule,
		AdminModule,
		NgScrollbarModule,
  ],
  exports: [AdminMenuComponent],
	declarations: [AdminMenuComponent],
	schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	],
})
export class AdminMenuModule { }
