<ng-scrollbar class="custom-scrollbar section-scrollbar">
	<ul class="sidebar-nav">
		<ng-container *ngFor="let conf of configuration_list; let i = index">
			<li class="sidebar-item" 
				[ngClass]="{ active: selectedItem === i }" 
				*ngIf="(conf.handle != 'information' && conf.handle != 'signature') || 
						((conf.handle == 'information' || conf.handle == 'signature') && 
						!isSelfServicePortal
						)"
			>
				<p
					id="btn{{ conf.handle }}"
					name="menu{{ conf.handle }}"
					class="m-0"
					(click)="toggleSidebar('account-settings-sidebar', conf.handle, i)"
					style="line-height: 1.9"
				>
					<span><i class="fa-light {{ conf.icon }} mr-75"></i></span>
					<span class="align-middle">{{ conf.title | translate }}</span>
				</p>
			</li>
		</ng-container>
	</ul>
</ng-scrollbar>
