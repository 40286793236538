import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

const OPTIONS = {
  stages: [
      {
        "id": 1,
        "name": "Logged"
      },
      {
        "id": 2,
        "name": "Responded"
      },
      {
        "id": 3,
        "name": "Assigned"
      },
      {
        "id": 4,
        "name": "Investigation"
      },
      {
        "id": 5,
        "name": "On Hold"
      },
      {
        "id": 6,
        "name": "Resolution"
      },
      {
        "id": 7,
        "name": "Closure"
      }
    ]
  };

@Component({
  selector: 'status-config',
  templateUrl: './status-config.component.html',
  styleUrls: ['./status-config.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StatusConfigComponent implements OnInit {
  options: any;
  @Input() statusData: any = {};
  constructor() { 
    this.options = OPTIONS;
  }

  ngOnInit(): void {
  }

}
