<div class="list-wrapper">
	<div class="d-flex flex-wrap col-md-12 pt-1">
		<div class="skeleton" class="col-md-3 p-0 d-flex">
			<ngx-skeleton-loader
				appearance="circle"
				[theme]="{ height: '45px', width: '45px', 'margin-right': '0.5rem', 'margin-left': '1rem' }"
			></ngx-skeleton-loader>
			<div>
				<ngx-skeleton-loader
					[theme]="{ height: '13px', width: '180%', 'margin-left': '1rem' }"
				></ngx-skeleton-loader>
				<ngx-skeleton-loader
					[theme]="{ height: '13px', width: '300%', 'margin-left': '1rem' }"
				></ngx-skeleton-loader>
			</div>
		</div>
		<div class="col-md-6 p-0 m-0">
			<ngx-skeleton-loader
				count="1"
				[theme]="{ height: '20px', width: '50%', 'margin-bottom': '1rem' }"
			></ngx-skeleton-loader>
		</div>

		<div class="col-md-2">
			<ngx-skeleton-loader [theme]="{ height: '18px', width: '30%' }"></ngx-skeleton-loader>
		</div>
	</div>

	<div class="d-flex flex-wrap col-md-12">
		<div class="col-md-6 mt-1 d-flex ">
			<div class="col-md-3">
				<ngx-skeleton-loader
					count="2"
					[theme]="{ height: '13px', width: '180px', 'margin-left': '1rem' }"
				></ngx-skeleton-loader>
			</div>
			<div class="col-md-3">
				<ngx-skeleton-loader
					count="2"
					[theme]="{ height: '13px', width: '180px', 'margin-left': '1rem' }"
				></ngx-skeleton-loader>
			</div>
		</div>
		<div class="col-md-6 mt-1"></div>
	</div>
</div>
<hr />

<div class="list-wrapper m-1">
	<div class="skeleton" class="pr-50 mb-1">
		<div class="sekeleton-card item-wrap col-md-12">
			<div class="d-flex">
				<div class="d-flex col-md-12 p-0 mt-1 justify-content-between">
					<div class="col-md-5 p-0">
						<ngx-skeleton-loader [theme]="{ height: '15px', width: '100%' }"></ngx-skeleton-loader>
					</div>
					<div class="d-flex col-md-6 p-0 justify-content-start">
						<div class="col-md-1 pull-right mr-50 p-0">
							<ngx-skeleton-loader [theme]="{ height: '15px', width: '100%' }"></ngx-skeleton-loader>
						</div>
						<div class="col-md-5 pull-right ml-5 pl-5 p-0">
							<ngx-skeleton-loader [theme]="{ height: '35px', width: '100%' }"></ngx-skeleton-loader>
						</div>
					</div>
				</div>
			</div>
			<hr />
			<div class="skeleton" class="p-0 d-flex">
				<ngx-skeleton-loader
					appearance="square"
					[theme]="{ height: '45px', width: '45px', 'margin-right': '0.5rem', 'margin-left': '2rem' }"
				></ngx-skeleton-loader>
				<div>
					<ngx-skeleton-loader
						count="2"
						[theme]="{ height: '13px', width: '94%', 'margin-left': '1rem', 'margin-right': '2rem' }"
					></ngx-skeleton-loader>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="list-wrapper m-1">
	<div class="skeleton" class="pr-50 mb-1">
		<div class="sekeleton-card item-wrap">
			<!-- <div class="d-flex justify-content-center mb-1">
				<ngx-skeleton-loader [theme]="{ height: '13px', width: '100%' }"></ngx-skeleton-loader>
			</div> -->
			<div class="d-flex flex-wrap col-md-12">
				<div class="col-md-3">
					<ngx-skeleton-loader count="4" [theme]="{ height: '13px', width: '100%' }"></ngx-skeleton-loader>
				</div>
				<div class="col-md-9">
					<div class="d-flex flex-wrap">
						<ng-container *ngFor="let item of generateFake(3)">
							<div class="skeleton" class="p-0 d-flex">
								<ngx-skeleton-loader
									appearance="square"
									[theme]="{
										height: '45px',
										width: '45px',
										'margin-right': '0.5rem',
										'margin-left': '2rem'
									}"
								></ngx-skeleton-loader>
								<div>
									<ngx-skeleton-loader
										count="2"
										[theme]="{
											height: '13px',
											width: '94%',
											'margin-left': '1rem',
											'margin-right': '2rem'
										}"
									></ngx-skeleton-loader>
								</div>
							</div>
						</ng-container>
					</div>

					<hr class="mb-1 mt-0" />
					<div class="list-wrapper">
						<div class="skeleton" class="pr-50 mb-1">
							<div class="sekeleton-card item-wrap col-md-12">
								<div class="skeleton" class="p-0 d-flex">
									<ngx-skeleton-loader
										appearance="square"
										[theme]="{
											height: '45px',
											width: '45px',
											'margin-right': '0.5rem',
											'margin-left': '2rem'
										}"
									></ngx-skeleton-loader>
									<div>
										<ngx-skeleton-loader
											count="2"
											[theme]="{
												height: '13px',
												width: '94%',
												'margin-left': '1rem',
												'margin-right': '2rem'
											}"
										></ngx-skeleton-loader>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

