<div class="slideout-content default-stepping" dns-enter-submit>
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
		<div class="modal-dialog sidebar-md">
			<form class="modal-content pt-0" #partnerAddForm="ngForm">
				<div class="modal-header">
					<h5 class="modal-title" *ngIf="action !== 'view'">
						{{ (action === "Edit" ? "UI.k_edit_partner" : "UI.k_add_partner") | translate }}
					</h5>
					<h5 class="modal-title" *ngIf="action === 'view'">
						{{ "UI.k_partner_details" | translate }}
					</h5>
					<button
						id="btnClose"
						name="close"
						type="button"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						(click)="toggleSidebar('create-partner')"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body">
					<div class="flex-auto-height">
						<div class="flex-1">
							<ng-scrollbar class="custom-scrollbar section-scrollbar">
								<div class="p-1 flex-1">
									<div class="fileUploader">
										<app-profile-avatar
											(avatarDetails)="profileImage($event)"
											(resetImage)="removeImage($event)"
											(avatarBgColor)="ProfileBgColor($event)"
											[profileName]="fullName"
											[profile_image]="currentImgDetails"
											[avatarBackgroundColor]="currentAvatarColor"
											[activeColor]="activeAvatar"
											(currentAvatar)="currrentColor($event)"
											[editable]="!readonly"
										>
										</app-profile-avatar>
									</div>
									<div class="row">
										<div class="col-sm-6">
											<div class="form-group">
												<label class="form-label" for="TxtName">
													{{ "UI.k_partner_name" | translate }}
													<span class="required-field">&nbsp;*</span>
												</label>
												<input
													[(ngModel)]="partner.partner_name"
													#TDNameRef="ngModel"
													required
													type="text"
													name="TxtName"
													id="TxtName"
													class="form-control"
													placeholder="Google"
													minlength="3"
													maxlength="40"
													[readonly]="readonly"
													[appWhiteSpaceCheck]="partner.partner_name"
													[class.error]="!TDNameRef.valid && partnerAddForm.submitted"
												/>
												<span
													*ngIf="partnerAddForm.submitted && TDNameRef.invalid"
													class="invalid-form"
												>
													<small
														class="form-text text-danger"
														*ngIf="TDNameRef.errors.required"
													>
														{{ "UI.k_field_required" | translate }}
													</small>
													<small
														class="form-text text-danger"
														*ngIf="TDNameRef.errors.minlength || TDNameRef.errors.maxlength"
													>
														{{ "UI.k_first_name_validation" | translate }}
													</small>
													<small
														class="form-text text-danger"
														*ngIf="TDNameRef.errors?.whitespace"
													>
														{{ "UI.k_space_validation" | translate }}
													</small>
												</span>
											</div>
										</div>
										<div class="col-sm-6">
											<div class="form-group">
												<label class="form-label" for="Txtemail">
													{{ "UI.k_email" | translate }}
													<span class="required-field">&nbsp;*</span>
												</label>
												<input
													[(ngModel)]="partner.email"
													#TDEmailRef="ngModel"
													required
													type="text"
													name="Txtemail"
													id="Txtemail"
													class="form-control"
													[readonly]="readonly"
													[class.error]="!TDEmailRef.valid && partnerAddForm.submitted"
													pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$"
													placeholder="john.doe@email.com"
													aria-label="john.doe"
												/>
												<span
													*ngIf="partnerAddForm.submitted && TDEmailRef.invalid"
													class="invalid-form"
												>
													<small
														class="form-text text-danger"
														*ngIf="TDEmailRef.errors.pattern"
													>
														{{ "UI.k_alert_email_invalid" | translate }}</small
													>
													<small
														class="form-text text-danger"
														*ngIf="TDEmailRef.errors.required"
													>
														{{ "UI.k_field_required" | translate }}
													</small>
												</span>
											</div>
										</div>
										<div class="col-sm-6">
											<div class="form-group">
												<label class="form-label" for="TxtPhoneNumber">
													{{ "UI.k_phone" | translate }}
												</label>
												<div class="w-100">
													<ngx-intl-tel-input
														class="w-100"
														[cssClass]="'custom form-control w-100'"
														[preferredCountries]="preferredCountries"
														[enableAutoCountrySelect]="true"
														[enablePlaceholder]="true"
														[searchCountryFlag]="true"
														[searchCountryField]="[
															SearchCountryField.Iso2,
															SearchCountryField.Name
														]"
														[selectFirstCountry]="false"
														[selectedCountryISO]="CountryISO.UnitedStates"
														[maxLength]="15"
														[phoneValidation]="true"
														[separateDialCode]="separateDialCode"
														[numberFormat]="PhoneNumberFormat.National"
														name="TxtPhoneNumber"
														id="TxtPhoneNumber"
														[(ngModel)]="partner.phone"
														#TDPhoneRef="ngModel"
														[disabled]="readonly"
														[ngClass]="{
															'is-invalid': partnerAddForm.submitted && TDPhoneRef.errors
														}"
													>
													</ngx-intl-tel-input>
													<span
														*ngIf="partnerAddForm.submitted && TDPhoneRef.invalid"
														class="invalid-form"
													>
														<small class="form-text text-danger" *ngIf="TDPhoneRef.errors">
															{{ "UI.k_phone_number_validation" | translate }}
														</small>
													</span>
												</div>
											</div>
										</div>
										<div class="col-sm-6">
											<div class="form-group">
												<label class="form-label" for="TxtPwd">
													{{ "UI.k_password" | translate }}
													<span class="required-field">&nbsp;*</span>
												</label>
												<div class="input-group input-group-merge form-password-toggle">
													<input
														[(ngModel)]="partner.password"
														#TDPasswordRef="ngModel"
														required
														[type]="partnerPwdShow ? 'text' : 'password'"
														class="form-control"
														id="TxtPwd"
														name="TxtPwd"
														placeholder="{{ 'UI.k_password' | translate }}"
														aria-describedby="TxtPwd"
														class="form-control form-control-merge"
														[readonly]="readonly"
														[class.error]="
															!TDPasswordRef.valid &&
															partnerAddForm.submitted &&
															is_password_invalid
														"
														(ngModelChange)="validatePassword(this.partner.password)"
													/>
													<div
														class="input-group-append"
														(click)="partnerPwdShow = !partnerPwdShow"
													>
														<span class="input-group-text cursor-pointer" id="passwordeye"
															><i
																class="feather"
																[ngClass]="{
																	'icon-eye-off': partnerPwdShow,
																	'icon-eye': !partnerPwdShow
																}"
															></i
														></span>
													</div>
												</div>
												<span *ngIf="is_password_invalid" class="invalid-form">
													<small
														class="form-text text-danger"
														[innerHtml]="passwordToaster | translate"
													>
													</small>
												</span>
												<span
													*ngIf="TDPasswordRef.invalid && partnerAddForm.submitted"
													class="invalid-form"
												>
													<small
														*ngIf="TDPasswordRef.errors.required"
														class="form-text text-danger"
													>
														{{ "UI.k_field_required" | translate }}
													</small>
												</span>
											</div>
										</div>
										<div class="col-sm-6">
											<div class="form-group">
												<label for="Txtaddress" class="form-label"
													>{{ "UI.k_address" | translate }}
												</label>
												<input
													[(ngModel)]="partner.address"
													#TDAddressRef="ngModel"
													type="text"
													name="Txtaddress"
													id="Txtaddress"
													class="form-control"
													placeholder="{{ 'UI.k_address' | translate }}"
													minlength="3"
													maxlength="500"
													[readonly]="readonly"
													[appWhiteSpaceCheck]="partner.address"
													[class.error]="!TDNameRef.valid && partnerAddForm.submitted"
												/>
											</div>
										</div>
									</div>
								</div>
							</ng-scrollbar>
						</div>
						<div class="d-flex justify-content-end border-top px-2 py-1 flex-initial">
							<button
								id="btnSubmit"
								name="btnSubmit"
								type="submit"
								class="btn btn-outline-secondary mr-50"
								(click)="toggleSidebar('create-partner')"
								rippleEffect
							>
								<span class="align-middle d-sm-inline-block d-none"
									>{{ "UI.k_cancel" | translate }}
								</span>
							</button>
							<button
								id="btnSubmit"
								name="btnSubmit"
								type="submit"
								class="btn btn-primary"
								(click)="onpartnerSave(partnerAddForm)"
								[disabled]="is_form_submitted"
								rippleEffect
								*ngIf="!readonly"
							>
								<span class="align-middle d-sm-inline-block d-none">{{
									"UI.k_create" | translate
								}}</span>
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
