import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {

  transform(array: any[], field: string): any[] {
    if (!Array.isArray(array) || array.length <= 1 || typeof field !== 'string') {
      return array;
    }

    return array.sort((a, b) => {
      const aValue = (a[field] || '').toString().toLowerCase();
      const bValue = (b[field] || '').toString().toLowerCase();

      if (aValue < bValue) {
        return -1;
      } else if (aValue > bValue) {
        return 1;
      } else {
        return 0;
      }
    });
  }

}
