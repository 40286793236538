<div class="slideout-content default-slideout">
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
		<div class="modal-dialog sidebar-md">
			<div class="modal-content add-new-user pt-0">
				<div class="modal-header d-flex justify-content-between">
					<h5  class="modal-title" id="exampleModalLabel">
						<span class="">
							<img src="assets/images/workspace/infi-logo-squre.svg" />
							{{ title }}
						</span>
					</h5>
					<div class="d-flex align-items-center gap-1">
						<button
							type="button"
							id="btnManualKBAdd"
							name="btnManualKBAdd"
							[attr.data-testid]="'btnManualKBAdd'"
							class="btn btn-sm btn-outline-tertiary waves-effect"
							(click)="onManualConvertToKbSidebar()"
						>
						{{'UI.k_manual_add' | translate}}
						</button>
						<button
							type="button"
							id="btnShow"
							name="btnShow"
							[attr.data-testid]="'btnShow'"
							class="btn btn-flat-secondary btn-icon"
							data-dismiss="modal"
							aria-label="Close"
							(click)="toggleSidebar()"
						>
							<i class="fa-light fa-xmark"></i>
						</button>
					</div>
				</div>
				<div class="modal-body p-0">
					<ng-container *ngIf="is_save_disabled">
						<div class="loading-logo">
							<img src="assets/images/logo.gif" alt="Logo" height="100" width="100" />
						</div>
					</ng-container>
					<ng-container *ngIf="!is_save_disabled">
					<form #AIKbForm="ngForm" class="flex-auto-height">
						<div class="detail-sec flex-1">
							<ng-scrollbar class="custom-scrollbar section-scrollbar">
								<div class="container-fluid">
									<div class="row p-half">
										<div class="col-12 mb-1 border-bottom">
											<div class="card bg-body p-1">
												<div class="">
													<button
														type="button"
														class="btn btn-outline-primary btn-sm pull-left"
														id="btnIssue"
														name="btnIssue"
														[attr.data-testid]="'btnIssue'"
														rippleEffect
													>
													<i class="fa fa-exclamation-circle"></i>
													{{ "UI.k_reported_issue" | translate }}
													</button>
												</div>
												
												<span class="pt-50 font-weight-bold">
													{{ refData?.content }}
												</span>
												<span class="pt-50 font-weight-bold" [innerHTML]="refData?.description">
												</span>
											</div>
											
										</div>
										<ng-container *ngIf="filteredCommunication?.length > 0">
											<div class="col-12 pb-50 wrap-text-around">
												<div>
													<div class="text-dark font-weight-bold"> 
														{{ 'Message.msg_sel_category_infiy' | translate }} 
													</div>
													<div class="text-muted font-small-1"> 
														{{ 'Message.msg_sel_communication_infiy' | translate }} 
													</div>
												</div>
												<div>
						
												</div>
						
											</div>
											<ng-container *ngFor="let option of filteredCommunication; let i = index">
												<div class="col-12">
													<div class="card border py-50">
														<div class="d-flex">
															<div class="col-8 wrap-text-around">
																<div class="d-flex">
																		<div class="custom-control custom-checkbox">
																			<input
																				type="checkbox"
																				class="custom-control-input"
																				[(ngModel)]="option.selected"
																				[checked]="option.selected"
																				id="cb__{{ i }}"
																				name="cb__{{ i }}"
																				attr.data-testid="cb__{{ i }}"
																				(change)="onToggle($event.target, option)"
																			/>
																			<label class="custom-control-label" for="cb__{{ i }}"> </label>
																		</div>
																	
																	<div class="" [innerHTML]="option?.description">
																	</div>
																	<!-- <div class="" [innerHTML]="option?.new_reply_mail ? option?.new_reply_mail : option?.content | safe: 'html'" *ngIf="option.table == 'email'"> -->
																	<!-- </div> -->
																</div>
																
															</div>
															<div class="col-4">
																<ng-select
																	[items]="dropdownOptions?.tagType"
																	[closeOnSelect]="true"
																	[searchable]="true"
																	bindLabel="name"
																	bindValue="id"
																	placeholder="{{
																		'UI.k_sel_type' | translate
																	}}"
																	class="form-control"
																	name="selTagType__{{ i }}"
																	id="selTagType__{{ i }}"
																	[(ngModel)]="option.tagType"
																	[hideSelected]="true"
																	[clearable]="false"
																>
																</ng-select>
															</div>
														</div>
													</div>	
												</div>
						
											</ng-container>
											<div
												class="col-12 d-flex justify-content-center align-items-center py-1"
												*ngIf="communication?.length < AllCommunicationCnt"
											>
												<button
													class="btn-sm btn-outline-primary d-flex align-items-center justify-content-between"
													type="button"
													(click)="loadNextComments()"
												>
													{{ "UI.k_load_more" | translate }}
												</button>
											</div>
										</ng-container>
										

										<ng-container *ngIf="
											!isObjectEmpty(
												allAttachments
											)
											">
											<div class="col-12 pb-50">
												<div>
													<div class="text-dark font-weight-bold"> 
														{{ 'UI.k_select_attachment' | translate }} 
													</div>
													<div class="text-muted font-small-1"> 
														{{ 'Message.msg_sel_attachment_to_map_to_kb' | translate }} 
													</div>
												</div>
												<div>
						
												</div>
						
											</div>
											<div class="col-12 mb-1  border-bottom">
												<ng-container *ngFor="let item of allAttachments | keyvalue">
														<ng-container *ngFor="let file of item.value; let i = index">
															<div class="card border p-1">
																<div class="col-12 d-flex">
																	<div class="custom-control custom-checkbox mt-50 pr-50">
																		<input
																			type="checkbox"
																			[(ngModel)]="file.selected"
																			[checked]="file.selected"
																			class="custom-control-input"
																			id="cb__attachment__{{ i }}"
																			name="cb__attachment__{{ i }}"
																			attr.data-testid="cb__attachment__{{ i }}"
																			(change)="onToggleFileSelect($event.target, file)"
																			
																		/>
																		<label class="custom-control-label" for="cb__attachment__{{ i }}"> </label>
																	</div>
																	<div
																		class="d-flex"
																	>
																		<div id="attachment-{{ file.id }}" class="left-info-sec d-flex">
																			<div class="text-center">
																				<div
																					class="avatar"
																					[ngClass]="
																						file.file_type == '.txt'
																							? 'bg-light-primary'
																							: file.file_type == '.pdf'
																							? 'bg-light-danger'
																							: file.file_type == '.xls' ||
																								file.file_type == '.xlsx'
																							? 'bg-light-success'
																							: file.file_type == '.doc' ||
																								file.file_type == '.docx'
																							? 'bg-light-info'
																							: 'bg-light-primary'
																					"
																				>
																					<div class="avatar-content" id="feather_size">
																						<i
																							class="{{ file.file_icon }} avatar-icon"
																							aria-hidden="true"
																						></i>
																					</div>
																				</div>
																			</div>
																			<div class="media-body d-flex flex-column justify-content-between">
																				<span
																					class="sm-font file_position title-color xs-font mb-0"
																				>
																					<span
																						class="fw-500 mr-50 file-name"
																						tooltipClass="fadeInAnimation"
																						ngbTooltip="{{ file.file_name }}"
																						>{{ file.file_name }}</span
																					><span class="file-version">v{{ file.version }}</span>
																				</span>
																				<span class="pl-50 xs-font line-height-1"
																					>{{ file.file_size_with_unit }} MB <b class="fw-600 xl-font">.</b>
																					{{ file.attachment_date }}</span
																				>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															
															
														</ng-container>
												</ng-container>
											</div>
										</ng-container>
										
					
										<div class="col-12 mb-1">
											<div class="card bg-body p-1">
												<div class="">
													<button
														type="button"
														class="btn btn-outline-primary btn-sm pull-left"
														id="btnSoln"
														name="btnSoln"
														[attr.data-testid]="'btnSoln'"
														rippleEffect
													>
													<i class="fa fa-lightbulb "></i>
													{{ "UI.k_solution" | translate }}
													</button>
												</div>
												<ng-container *ngFor="let soln of refData?.solutions; let i = index">
													<div class="pt-50 font-weight-bold wrap-text-around" id="soln-{{i}}" name="soln-{{i}}" [innerHTML]="soln">
													</div>
												</ng-container>
												
											</div>
											
										</div>
					
									</div>
								</div>
							</ng-scrollbar>
						</div>
						<div class="py-1 px-2 flex-initial border-top">
							<div class="pull-right align-items-center d-flex p-0">
								<button
										class="btn btn-sm btn-outline-tertiary mr-1"
										rippleEffect
										tooltipClass="fadeInAnimation"
										id="btnKbCancel"
										(click)="toggleSidebar()"
										ngbTooltip="{{ 'UI.k_cancel' | translate }}"
									>
										{{ "UI.k_cancel" | translate }}
									</button>
								<button
									id="btnSave"
									name="btnSave"
									[attr.data-testid]="'btnSave'"
									(click)="createAIKB(AIKbForm)"
									type="submit"
									class="btn btn-primary"
									[disabled]="is_save_disabled"
									rippleEffect
								>
									{{ "UI.k_convert" | translate }}
								</button>
							</div>
						</div>
					</form>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</div>

<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="app-ai-kb-add" overlayClass="modal-backdrop">
	<app-kb-add
		[event]="'add'"
		[item]="kbCurrentItem"
		*ngIf="kbHideProfile"
		(onHideProfile)="onHideKBProfile($event)"
		(addedKBID)="getKBID($event)"
		[kbSolution]="ai_kb_data"
		[isExternalCall]="true"
		[refId]="refData?.ref_id"
	>
	</app-kb-add>
</core-sidebar>
