import { Component, Input, OnInit } from "@angular/core";
import { NgEventBus } from "ng-event-bus";

@Component({
	selector: "app-incident-summary",
	templateUrl: "./incident-summary.component.html",
	styleUrls: ["./incident-summary.component.scss"],
})
export class IncidentSummaryComponent implements OnInit {
	@Input() config: any;
	@Input() modifiedtime: any;
	csat_filters :any = {}
	constructor(
		private eventBus: NgEventBus
	) {}

	ngOnInit(): void {
		//console.log("config", this.config);
	}

	showInciSidebar(dat){
		if (dat?.value > 0 && dat?.item_filter){
			if( this.config?.renderType == "csatStatus"){
				this.csat_filters = dat?.item_filter
				this.csat_filters['filters']['rating'] = dat?.id
				var payload = {
					"title": "Feedbacks - " + dat.name,
					"app_type": "csat-grid",
					"item_filter": this.csat_filters,
					"timescale" : this.config?.timescale
				}
			}
			else{
			var payload = {
				"title": "Tickets - " + dat.name,
				"app_type": "inci-grid",
				"item_filter": dat.item_filter,
				"timescale" : this.config?.timescale
			}
		}
			// console.log("in inci summary widget -> ", payload)
			this.eventBus.cast('dashboard-view:sidebar', payload);
		}
	}
}
