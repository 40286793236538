<div class="slideout-content default-slideout incident-view-sidebar processData-view-sidebar">
	<div class="modalsd modal-slide-in sdfade" id="modals-slide-in">
		<div class="modal-dialog full-width-sideout">
			<div class="modal-content pt-0 pb-0">
				<div class="modal-header d-flex justify-content-between align-items-center">
					<h5 class="modal-title fw-600 mb-0">
						<div class="avatar bg-light-primary avatar-sm squer-border mr-25">
							<div class="avatar-content">
								<i 
									class="fa-light fa-{{
										sourceIconMap[
											incident_data?.basic_info?.incident_source?.id
										]
									}}"
								></i>
							</div>
						</div>
						{{ incident_data_copy?.display_id }}
					</h5>
					<div class="d-flex align-items-center">
						<!-- <button class="btn btn-flat-primary btn-sm waves-effect px-50 body-color mr-1" rippleeffect>
							<i class="fa-light fa-rectangle-history-circle-plus"></i>
							{{'UI.k_new_window' | translate}}
						</button> -->
						<button
							type="button"
							data-dismiss="modal"
							id="btnClose"
							name="btnClose"
							aria-label="Close"
							(click)="incidentDetailSidebar('processData-view-sidebar')"
							class="btn btn-flat-secondary btn-icon"
							rippleEffect
						>
							<i class="fa-light fa-xmark"></i>
						</button>
					</div>
				</div>
				<div class="modal-content-body flex-1 position-relative" id="custom-padding">
					<div class="sidebar-menu-left bg-body border-right flex-auto-height">
						<!-- <span class="menu-heading flex-initial"> 
              <i class="fa-light fa-ticket"></i>
              <span>{{"UI.k_ticket_categories" | translate}}</span>
            </span>                                      -->
						<div class="list-group incident-option-list flex-1">
							<ng-scrollbar class="custom-scrollbar section-scrollbar">
								<a
									*ngFor="let item of configuration_list; let i = index"
									id="btn{{ item.handle }}"
									name="menu{{ item.handle }}"
									class="list-group-item list-group-item-action bg-body"
									[class.active]="selectedItem === i"
									(click)="menuClick(item.handle, i)"
								>
									<i class="fa-light {{ item.icon }}"></i>
									<span class="align-middle">{{ item.title }}</span>
								</a>
							</ng-scrollbar>
						</div>
					</div>
					<div *ngIf="dataLoaded" class="flex-auto-height">
						<div class="incident-view-detail-grid flex-1">
							<div class="flex-auto-height">
								<div class="flex-initial">
									<div class="p-1 border-bottom d-flex justify-content-between align-items-start">
										<div class="d-flex flex-column">
											<div class="title-color fw-500">
												{{
													incident_data_copy?.basic_info?.summary?.length > 100
														? (incident_data_copy?.basic_info?.summary | slice: 0:100) + "..."
														: incident_data_copy?.basic_info?.summary
												}}
											</div>
											<span class="xs-font">
												{{ "UI.k_last_updated_on" | translate }}
												{{ incident_data_copy?.last_update_time }}
												{{ "UI.k_and_created_on" | translate }}
												{{ incident_data_copy?.creation_time }}
											</span>
										</div>
										<div class="d-flex justify-content-between align-items-start">
											<div class="inciEditSidebar pr-50 border-right" *ngIf="permissions.edit && disable_config === false">
												<a
													id="btnInciEdit"
													name="inciEdit"
													[attr.data-testid]="'btnInciEdit'"
													tooltipClass="fadeInAnimation"
													ngbTooltip="{{ 'UI.k_edit' | translate }}"
													placement="left"
													id="btnIncidentEdit"
													(click)="toggleInLineEdit('dns-incident-edit-sidebar')"
													class="pull-right"
												>
													<img
														src="assets/images/incident-edit.svg"
														alt="Avatar"
														width="14"
														height="14"
													/>
												</a>
											</div>
											<!-- <span>{{incident_data_copy | json}}</span> -->
											<div
												*ngIf="permissions?.edit ? permissions?.edit && disable_config === false: 											incident_data_copy?.request?.request_id && 
												incident_data_copy?.change?.change_id || 
												incident_data_copy?.problem?.problem_id || 
												incident_data_copy?.kb_id || 
												incident_data_copy?.request?.request_id && 
												disable_config === false"
												ngbDropdown
												class="no-arrow incident-action-dropdown"
												[placement]="'bottom-right'"
											>
												<span ngbDropdownToggle class="px-50 mr-50" id="drdActionUser">
													<i class="fa-light fa-ellipsis-vertical fw-500 lg-font"></i>
												</span>
												<div ngbDropdownMenu>
													<a
														*ngIf="
															incident_data_copy?.basic_info?.state?.id < 4 &&
															this.request_permissions?.add &&
															!incident_data_copy?.request?.request_id && permissions.edit && permissions.view
														"
														(click)="OnShowConvInciToReq()"
														id="btnConvInciToReq"
														name="btnConvInciToReq"
														[attr.data-testid]="'btnConvInciToReq'"
														ngbDropdownItem
														href="javascript:void(0)"
														>{{ "UI.k_convert_in_to_request" | translate }}
													</a>
													<a
														*ngIf="incident_data_copy?.request?.request_id && this.request_permissions?.view"
														(click)="onShowConvInciToReqView('app-request-incident-view')"
														id="btnConvInciToReqView"
														name="btnConvInciToReqView"
														[attr.data-testid]="'btnConvInciToReqView'"
														ngbDropdownItem
														href="javascript:void(0)"
														>{{ "UI.k_view_request" | translate }}
													</a>
													<a
														*ngIf="
															incident_data_copy?.basic_info?.state?.id < 4 &&
															this.change_permissions?.add &&
															!incident_data_copy?.change?.change_id && permissions.edit && permissions.view
														"
														(click)="onShowInciToChn()"
														id="btnProToChn"
														name="btnProToChn"
														[attr.data-testid]="'btnProToChn'"
														ngbDropdownItem
														href="javascript:void(0)"
														>{{ "UI.k_convert_in_to_change" | translate }}
													</a>
													<a
														*ngIf="incident_data_copy?.change?.change_id && this.change_permissions?.view"
														(click)="onShowInciToChnView()"
														id="btnProToChnView"
														name="btnProToChnView"
														[attr.data-testid]="'btnProToChnView'"
														ngbDropdownItem
														href="javascript:void(0)"
														>{{ "UI.k_view_change" | translate }}
													</a>
													<a
														*ngIf="
															incident_data_copy?.basic_info?.state?.id < 4 &&
															this.problem_permissions?.add &&
															!incident_data_copy?.problem?.problem_id && permissions.edit && permissions.view
														"
														(click)="onShowInciToProb()"
														id="btnInciToProb"
														name="btnInciToProb"
														[attr.data-testid]="'btnInciToProb'"
														ngbDropdownItem
														href="javascript:void(0)"
														>{{ "UI.k_convert_in_to_problem" | translate }}
													</a>
													<a
														*ngIf="incident_data_copy?.problem?.problem_id && this.problem_permissions?.view"
														(click)="onShowInciToProbView()"
														id="btnProToProbView"
														name="btnProToProbView"
														[attr.data-testid]="'btnProToProbView'"
														ngbDropdownItem
														href="javascript:void(0)"
														>{{ "UI.k_view_problem" | translate }}
													</a>
													<a
														*ngIf="
															(incident_data_copy?.basic_info?.state?.id === 4 ||
																incident_data_copy?.basic_info?.state?.id === 5) &&
															!incident_data_copy?.kb_id &&
															this.kb_permissions?.add && permissions.edit && permissions.view
														"
														(click)="toggleInciToKbSidebar('app-dns-kb-add')"
														id="btnKbAdd"
														name="btnKbAdd"
														[attr.data-testid]="'btnKbAdd'"
														ngbDropdownItem
														href="javascript:void(0)"
														>{{ "UI.k_conv_incident_kb" | translate }}
													</a>
													<a
														*ngIf="
															(incident_data_copy?.basic_info?.state?.id === 4 ||
																incident_data_copy?.basic_info?.state?.id === 5) &&
															incident_data_copy?.kb_id &&
															this.kb_permissions?.view
														"
														(click)="viewKB()"
														id="btnKbsolution"
														name="btnKbsolution"
														[attr.data-testid]="'btnKbsolution'"
														ngbDropdownItem
														href="javascript:void(0)"
														>{{ "UI.k_view_kb_soln" | translate }}
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="flex-1">
									<div class="flex-auto-height">
										<div class="flex-1">
											<!-- <div *ngIf="selectedItemName === 'incident_details'" class="flex-auto-height">
                        <ng-scrollbar class="custom-scrollbar section-scrollbar">
                          <div class="p-1 border-bottom full-height">
                            <div class="reg-font fw-500 mb-1">{{"UI.k_basic_details" | translate}}</div>
                            <div class="detail-grid"> 
                              <div class="edit-hover">
                                <span class="edit-icon">
                                  <i class="fa-light fa-pen"></i>
                                </span> 
                                <div class="mb-25">
                                  <span class="sm-font">{{ "UI.k_priority" | translate }} </span>
                                </div>
                                <div class="default-value">
                                  <div
                                  class="fw-500 sm-font py-0 badge badge-light-{{
                                    incident_data_copy?.basic_info?.priority?.color
                                  }}"                                                                   
                                  >
                                  <i
                                    class="fa-light mr-25 mt-25 {{
                                      this.incident_data_copy?.basic_info?.priority?.icon
                                    }}"
                                  ></i>
                                  {{
                                    this.incident_data_copy?.basic_info?.priority?.display_key
                                      | translate
                                  }}
                                </div>
                                </div> 
                                <div class="edit-value">
                                  <div class="form-group">                                         
                                    <app-select-badge
                                      [inputConfig]="{
                                        hideLabel: true,
                                        inputOptions: dropdown?.priority,
                                        id: 'priority',
                                        model: this.incident_data_copy?.basic_info?.priority.id,
                                        isEditDisabled: disable_config
                                      }"
                                      (onValueChange)="statusBadgeChange($event)"
                                    ></app-select-badge>
                                  </div>
                                </div>
                              </div>
                              <div class="edit-hover">
                                <span class="edit-icon">
                                  <i class="fa-light fa-pen"></i>
                                </span>                                 
                                <div class="default-value">
                                  <span class="sm-font">{{ "UI.k_status" | translate }} </span>
                                  <div class="group-lists d-flex align-items-center justify-content-start">                                    
                                    <div
                                      class="custom-squre"
                                      [ngClass]="'bg-' + incident_data_copy?.basic_info?.status?.color"
                                      [ngStyle]="{
                                        'background-color':
                                          incident_data_copy?.basic_info?.status?.color
                                      }"
                                    ></div>
                                    <span>{{
                                        incident_data_copy?.basic_info?.status?.name | translate
                                    }}</span>
                                    <div
                                      class="right-arrow custom-text"
                                      [ngStyle]="{
                                        'background-color':
                                          incident_data_copy?.basic_info?.state?.background_color,
                                        color:
                                          incident_data_copy?.basic_info?.state?.text_color ||
                                          incident_data_copy?.basic_info?.state?.color_hex
                                      }"
                                    >
                                      {{ incident_data_copy?.basic_info?.status?.state | translate }}
                                    </div>                             
                                  </div>
                                </div> 
                                <div class="edit-value">                                
                                  <app-status-picker
                                    [inputConfig]="{
                                      isTextMode: true,
                                      stateOptionsMap: dropdown?.state_id_map,
                                      isEditDisabled: disable_config
                                    }"
                                    [options]="dropdown.transition_status"                                  
                                    [value]="incident_data_copy?.basic_info?.status?.id"
                                  ></app-status-picker>
                                </div>
                              </div>        
                              <div class="edit-hover">
                                <span class="edit-icon">
                                  <i class="fa-light fa-pen"></i>
                                </span>  
                                <div class="mb-25">
                                  <span class="sm-font">{{ 'UI.k_urgency' | translate }} </span>
                                </div>
                                <div class="default-value">
                                  <span
                                    class="{{
                                      incident_data_copy?.basic_info?.urgency?.color ? 'badge' : ''
                                    }} badge-light-{{
                                      incident_data_copy?.basic_info?.urgency?.color
                                    }} sm-font title-color my-50"
                                  >
                                    {{
                                      incident_data_copy?.basic_info?.urgency?.name
                                        ? incident_data_copy?.basic_info?.urgency?.name
                                        : "-"
                                    }}
                                  </span>
                                </div>   
                                <div class="edit-value">                                                                        
                                  <div class="form-group">                                                                    
                                    <app-select-badge 
                                    [inputConfig]="{
                                      hideLabel: true,
                                      inputOptions: dropdown?.urgency,id:'urgency',
                                      model: this.incident_data?.basic_info?.urgency.id,
                                      isEditDisabled: disable_config
                                    }"
                                    (onValueChange)="statusBadgeChange($event)"
                                    ></app-select-badge>                                                                
                                  </div> 
                                </div>       
                              </div>  
                              <div class="edit-hover">
                                <span class="edit-icon">
                                  <i class="fa-light fa-pen"></i>
                                </span>  
                                <div class="mb-25">
                                  <span class="sm-font">{{ 'UI.k_severity' | translate }}</span>
                                </div>
                                <div class="default-value">
                                  <span
                                    class="my-50 sm-font title-color {{
                                      incident_data_copy?.basic_info?.severity?.color ? 'badge' : ''
                                    }} badge-light-{{ incident_data_copy?.basic_info?.severity?.color }} "
                                  >
                                    {{
                                      incident_data_copy?.basic_info?.severity?.name
                                        ? incident_data_copy?.basic_info?.severity?.name
                                        : "-"
                                    }}
                                  </span>
                                </div>
                                <div class="edit-value">
                                  <div class="form-group">                                                           
                                    <app-select-badge [inputConfig]="{
                                      hideLabel: true,
                                      inputOptions: dropdown?.severity,
                                      id:'severity',
                                      model: this.incident_data_copy?.basic_info?.severity.id,
                                      isEditDisabled: disable_config
                                    }"
                                    ></app-select-badge>                                                         
                                  </div>
                                </div>
                              </div> 
                              <div class="edit-hover">
                                <span class="edit-icon">
                                  <i class="fa-light fa-pen"></i>
                                </span> 
                                <div class="mb-25">
                                  <span class="sm-font">{{ "UI.k_impact" | translate }} </span>
                                </div>
                                <div class="default-value">
                                  <div class="sm-font title-color">
                                    {{
                                      incident_data_copy?.basic_info?.impact?.name
                                        ? incident_data_copy?.basic_info?.impact?.name
                                        : "-"
                                    }}
                                  </div>
                                </div>
                                <div class="edit-value">
                                  <div class="form-group">                                  
                                    <ng-select
                                      [items]="dropdown.impact"
                                      [hideSelected]="true"
                                      bindLabel="name"
                                      bindValue="id"
                                      [(ngModel)]="incident_data_copy.basic_info.impact.id"
                                      name="txtIncidentImpact"
                                      id="txtIncidentImpact"
                                      placeholder="{{ 'UI.k_select' | translate }} {{
                                        'UI.k_impact' | translate
                                      }}"
                                      [disabled]="disable_config"
                                    >
                                    </ng-select>
                                  </div> 
                                </div>
                              </div>
                              <div class="edit-hover">
                                <span class="edit-icon">
                                  <i class="fa-light fa-pen"></i>
                                </span>  
                                <div class="mb-25">
                                  <span class="sm-font">{{ "UI.k_impact_service" | translate }} </span>
                                </div>
                                <div class="default-value">
                                  <div class="sm-font">
                                    {{
                                      incident_data_copy?.basic_info?.impact_service_tree_path
                                        ? incident_data_copy?.basic_info?.impact_service_tree_path
                                        : "-"
                                    }}
                                  </div>
                                </div>
                                <div class="edit-value">
                                  <div class="form-group input-tree-dropdown">                                  
                                    <app-single-tree-dropdown class="w-100" [items]="this.treeInputData" [value]="11" [id]="incidentId"></app-single-tree-dropdown>
                                  </div>
                                </div>
                              </div>
                              
                              <div class="edit-hover">
                                <span class="edit-icon">
                                  <i class="fa-light fa-pen"></i>
                                </span>  
                                <div class="mb-25">
                                  <span class="sm-font">{{ "UI.k_event_status" | translate }} </span>
                                </div>
                                <div class="default-value">
                                  <div class="sm-font">
                                    {{
                                      incident_data_copy?.basic_info?.event_status
                                        ? incident_data_copy?.basic_info?.event_status
                                        : "-"
                                    }}
                                  </div>
                                </div> 
                                <div class="edit-value">
                                  <div class="form-group">                                 
                                    <ng-select
                                      [items]="dropdown.event_status"
                                      [hideSelected]="true"
                                      bindLabel="name"
                                      bindValue="id"
                                      [(ngModel)]="incident_data_copy.basic_info.event_status"
                                      name="txtProbEventType"
                                      id="txtProbEventType"
                                      placeholder="{{ 'UI.k_select_event_status' | translate }}"
                                      [disabled]="disable_config"
                                    >
                                    </ng-select>
                                  </div> 
                                </div>
                              </div>
                              
                              <div class="edit-hover">
                                <span class="edit-icon">
                                  <i class="fa-light fa-pen"></i>
                                </span>  
                                <div class="mb-25">
                                  <span class="sm-font">{{ "UI.k_callback_options" | translate }} </span>
                                </div>
                                <div class="default-value"></div>                                                           
                                <div class="edit-value">
                                  <i
                                    class="fa-light fa-{{
                                      callbackIconMap[incident_data_copy?.basic_info?.callback]
                                    }} text-primary mr-25"
                                  ></i>
                                  <span class="sm-font fw-500 title-color">
                                    {{
                                      this.incident_data_copy?.basic_info?.callback
                                        ? callbackNameMap[incident_data_copy?.basic_info?.callback]
                                        : "-"
                                    }}
                                  </span>
                                </div>                                
                              </div>                                                                                                                                                                                                                                                            
                            </div>
                          </div>
                          <div class="p-1 border-bottom full-height">
                            <div class="reg-font fw-500 mb-1">{{ "UI.k_reportedby" | translate }}</div>
                            <div class="detail-grid">         
                              <div class="edit-hover">
                                <span class="edit-icon">
                                  <i class="fa-light fa-pen"></i>
                                </span>    
                                <div class="mb-25">
                                  <span class="sm-font">{{ "UI.k_name" | translate }} </span>
                                </div>
                                <div class="default-value">
                                  <span class="sm-font">
                                    {{
                                      incident_data_copy?.reporter_info?.name
                                        ? incident_data_copy?.reporter_info?.name
                                        : "-"
                                    }}</span>
                                </div> 
                                <div class="edit-value">                                                                        
                                  <div class="form-group">
                                    <input
                                      type="text"
                                      [(ngModel)]="incident_data.reporter_info.name"
                                      #TDReqNameRef="ngModel"
                                      name="txtInciRequesterName"
                                      id="txtInciRequesterName"
                                      class="form-control"
                                      placeholder="{{ 'UI.k_name' | translate }}"
                                      minlength="3"
                                      maxlength="100"
                                      pattern="^[^\s]+(\s+[^\s]+)*$"
                                      [class.error]="!TDReqNameRef.valid"
                                      [disabled]="disable_config"
                                    />
                                    <!-- pattern = "^[a-zA-Z]+[a-zA-Z0-9_@. -]*$" 
                                    <span
                                      *ngIf="TDReqNameRef.invalid"
                                      class="invalid-form"
                                    >
                                      <small
                                        class="form-text text-danger font-small-1"
                                        *ngIf="
                                          TDReqNameRef.errors.minlength ||
                                          TDReqNameRef.errors.maxlength
                                        "
                                      >
                                        {{ "UI.k_reporter_validation" | translate }}</small
                                      >
                                      <small
                                        class="form-text text-danger font-small-1"
                                        *ngIf="TDReqNameRef.errors.pattern"
                                        >{{
                                          "UI.k_leading_trailing_space_validation" | translate
                                        }}</small
                                      >
                                    </span>
                                  </div>    
                                </div>  
                              </div>  
                              <div class="edit-hover">
                                <span class="edit-icon">
                                  <i class="fa-light fa-pen"></i>
                                </span>  
                                <div class="mb-25">
                                  <span class="sm-font">{{ "UI.k_email" | translate }} </span>
                                </div>
                                <div class="default-value">
                                  <span class="sm-font title-color">
                                    {{
                                      incident_data_copy?.reporter_info?.email
                                        ? incident_data_copy?.reporter_info?.email
                                        : "-"
                                    }}
                                  </span>
                                </div> 
                                <div class="edit-value">
                                  <div class="form-group">                                  
                                    <input
                                      type="text"
                                      [(ngModel)]="incident_data.reporter_info.email"
                                      #TDReqMailRef="ngModel"
                                      name="txtInciRequesterEmail"
                                      id="txtInciRequesterEmail"
                                      class="form-control"
                                      placeholder="{{ 'UI.k_email' | translate }}"
                                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$"
                                      placeholder="john.doe@email.com"
                                      aria-label="john.doe"
                                      [disabled]="disable_config"
                                    />
                                    <span
                                      *ngIf="TDReqMailRef.invalid"
                                      class="invalid-form"
                                    >
                                      <small
                                        class="form-text text-danger font-small-1"
                                        *ngIf="TDReqMailRef.errors.pattern"
                                        >{{ "UI.k_alert_email_invalid" | translate }}</small
                                      >
                                    </span>                              
                                  </div>
                                </div>
                              </div>                              
                                                                                                                                                                                                                                                                                        
                            </div>
                          </div>
                        </ng-scrollbar>
                      </div> -->
											<div
												*ngIf="selectedItemName === 'incident_details'"
												class="flex-auto-height"
											>
												<ng-scrollbar class="custom-scrollbar section-scrollbar">
													<div class="p-1 basic-details d-flex flex-column gap-50">
														<div class="reg-font fw-500">
															{{ "UI.k_basic_details" | translate }}
														</div>
														<div class="basic-details-row align-items-center">
															<div class="details-label">
																<span class="sm-font"
																	>{{ "UI.k_status" | translate }}
																</span>
															</div>
															<div class="details-value">
																<app-status-picker
																	#sliderStatusPickerComponent
																	[inputConfig]="{
																		isTextMode: true,
																		hideLabel: true,
																		stateOptionsMap: dropdown?.state_id_map,
																		isEditDisabled: disable_status_config
																	}"
																	[hideArrow]="true"
																	[options]="dropdown.transition_status"
																	[value]="incident_data_copy?.basic_info?.status?.id"
																	(setStatus)="onStatusChange($event)"
																	[showLabel]="false"
																></app-status-picker>
															</div>
														</div>
														<div class="basic-details-row align-items-center">
															<div class="details-label">
																<span class="sm-font"
																	>{{ "UI.k_priority" | translate }}
																</span>
															</div>
															<div class="details-value">
																<app-select-badge
																	[inputConfig]="{
																		hideLabel: true,
																		inputOptions: dropdown?.priority,
																		id: 'priority',
																		model: this.incident_data_copy?.basic_info
																			?.priority.id,
																		isEditDisabled: disable_config
																	}"
																	[hideArrow]="true"
																	[showLabel]="false"
																	(onValueChange)="statusBadgeChange($event)"
																></app-select-badge>
															</div>
														</div>
														<div class="basic-details-row align-items-center">
															<div class="details-label">
																<span class="sm-font">{{
																	"UI.k_urgency" | translate
																}}</span>
															</div>
															<div class="details-value">
																<app-select-badge
																	[inputConfig]="{
																		hideLabel: true,
																		inputOptions: dropdown?.urgency,
																		id: 'urgency',
																		model: this.incident_data?.basic_info?.urgency
																			.id,
																		isEditDisabled: disable_config
																	}"
																	(onValueChange)="statusBadgeChange($event)"
																></app-select-badge>
															</div>
														</div>
														<div class="basic-details-row align-items-center">
															<div class="details-label">
																<span class="sm-font">{{
																	"UI.k_impact" | translate
																}}</span>
															</div>
															<div class="details-value">
																<ng-select
																	[items]="dropdown.impact"
																	[hideSelected]="true"
																	bindLabel="name"
																	bindValue="id"
																	[(ngModel)]="incident_data.basic_info.impact.id"
																	name="txtIncidentImpact"
																	id="txtIncidentImpact"
																	placeholder="{{ 'UI.k_select' | translate }} {{
																		'UI.k_impact' | translate
																	}}"
																	(change)="onImpactChange($event)"
																	[disabled]="disable_config"
																>
																</ng-select>
															</div>
														</div>

														<div
															*appDnsLicenseHandler="{
																module_key: 'SERVICEDESK',
																keys: ['SERVICE_CATALOGUE']
															}"
															class="basic-details-row align-items-center"
														>
															<div class="details-label">
																<span class="sm-font">{{
																	"UI.k_impact_service" | translate
																}}</span>
															</div>
															<div class="details-value">
																<div class="form-group input-tree-dropdown">
																	<ng-container *ngIf="service_config?.is_editable">
																		<app-single-tree-dropdown
																			class=""
																			[items]="this.dropdown?.formatted_services"
																			[value]="
																				this.incident_data?.basic_info
																					?.impact_service
																			"
																			[id]="incidentId"
																			(itemChange)="
																				this.serviceValueChange($event)
																			"
																		>
																		</app-single-tree-dropdown>
																	</ng-container>
																	<div
																		class="ng-select ng-select-single ng-select-searchable ng-select-clearable ng-untouched ng-pristine ng-valid"
																		*ngIf="!service_config?.is_editable"
																		(click)="
																			onImpactServiceClick(
																				$event,
																				'impact_service'
																			)
																		"
																	>
																		<div class="ng-select-container">
																			<div class="ng-value-container">
																				<ng-container
																					*ngIf="
																						this.incident_data?.basic_info
																							?.impact_service_tree_path
																					"
																				>
																					<div class="">
																						{{
																							this.incident_data
																								?.basic_info
																								?.impact_service_tree_path
																						}}
																					</div>
																				</ng-container>
																				<ng-container
																					*ngIf="
																						!this.incident_data?.basic_info
																							?.impact_service_tree_path
																					"
																				>
																					<div class="placeholder-txt">
																						{{
																							"UI.k_select_option"
																								| translate
																						}}
																					</div>
																				</ng-container>

																				<div role="combobox" class="ng-input">
																					<input type="text" />
																				</div>
																			</div>
																			<span class="ng-arrow-wrapper"
																				><span class="ng-arrow"></span
																			></span>
																			<span
																				*ngIf="service_config?.is_loading"
																				class="fa fa-spinner fa-spin fa-2x"
																			>
																			</span>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div
															*appDnsLicenseHandler="{
																module_key: 'SERVICEDESK',
																keys: ['SERVICE_CATALOGUE']
															}"
															class="basic-details-row align-items-center"
														>
															<div class="details-label">
																<span class="sm-font">{{
																	"UI.k_service_classification" | translate
																}}</span>
															</div>
															<div class="details-value">
																<div class="form-group input-tree-dropdown">
																	<ng-container
																		*ngIf="classification_config?.is_editable"
																	>
																		<app-single-tree-dropdown
																			class=""
																			[items]="
																				dropdown?.formatted_classification_data
																			"
																			[value]="
																				incident_data.basic_info
																					.service_classification
																			"
																			[id]="'edit-service' + incidentId"
																			[treeInputConfig]="{
																				isParentAllowed: true
																			}"
																			(itemChange)="
																				this.classificationValueChange($event)
																			"
																		>
																		</app-single-tree-dropdown>
																	</ng-container>
																	<div
																		class="ng-select ng-select-single ng-select-searchable ng-select-clearable ng-untouched ng-pristine ng-valid"
																		*ngIf="!classification_config?.is_editable"
																		(click)="
																			onImpactServiceClick(
																				$event,
																				'service_classification'
																			)
																		"
																	>
																		<div class="ng-select-container">
																			<div class="ng-value-container">
																				<ng-container
																					*ngIf="
																						this.incident_data?.basic_info
																							?.service_classification_path
																					"
																				>
																					<div class="">
																						{{
																							this.incident_data
																								?.basic_info
																								?.service_classification_path
																						}}
																					</div>
																				</ng-container>
																				<ng-container
																					*ngIf="
																						!this.incident_data?.basic_info
																							?.service_classification_path
																					"
																				>
																					<div class="placeholder-txt">
																						{{
																							"UI.k_select_option"
																								| translate
																						}}
																					</div>
																				</ng-container>

																				<div role="combobox" class="ng-input">
																					<input type="text" />
																				</div>
																			</div>
																			<span class="ng-arrow-wrapper"
																				><span class="ng-arrow"></span
																			></span>
																			<span
																				*ngIf="
																					classification_config?.is_loading
																				"
																				class="fa fa-spinner fa-spin fa-2x"
																			>
																			</span>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<ng-container
															*appDnsLicenseHandler="{
																module_key: 'MONITORING',
																keys: ['EVENT_MANAGEMENT']
															}"
														>
															<div
																*ngIf="incident_data?.basic_info?.event"
																class="basic-details-row align-items-center"
															>
																<div class="details-label">
																	<span class="sm-font">{{
																		"UI.k_event_status" | translate
																	}}</span>
																</div>
																<div class="details-value">
																	<ng-select
																		[items]="dropdown.event_status"
																		[hideSelected]="true"
																		bindLabel="name"
																		bindValue="id"
																		[(ngModel)]="
																			incident_data_copy.basic_info.event_status
																		"
																		name="txtProbEventType"
																		id="txtProbEventType"
																		placeholder="{{
																			'UI.k_select_event_status' | translate
																		}}"
																		[disabled]="disable_config"
																	>
																	</ng-select>
																</div>
															</div>
														</ng-container>
														<!-- <div class="basic-details-row align-items-center">
															<div class="details-label">
																<span class="sm-font">{{
																	"UI.k_callback_options" | translate
																}}</span>
															</div>
															<div class="details-value">
																<div class="sm-font">
																	<i
																		class="fa-light fa-{{
																			callbackIconMap[
																				incident_data_copy?.basic_info?.callback
																			]
																		}} text-primary mr-25"
																	></i>
																	<span class="sm-font fw-500 title-color">
																		{{
																			this.incident_data_copy?.basic_info
																				?.callback
																				? callbackNameMap[
																						incident_data_copy?.basic_info
																							?.callback
																				  ]
																				: "-"
																		}}
																	</span>
																</div>
															</div>
														</div> -->
														<div class="basic-details-row align-items-start">
															<div class="details-label">
																<span class="sm-font">{{
																	"UI.k_attachment" | translate
																}}</span>
															</div>
															<div class="details-value">
																<div class="upload-file-list">
																	<ng-container
																		*ngFor="
																			let item of filteredAttachmentByCategory
																				| keyvalue
																		"
																	>
																		<div
																			*ngFor="
																				let file of item.value;
																				let i = index
																			"
																			class="single-file"
																		>
																			<div
																				class="border rounded bg-body px-75 py-25 cursor-pointer"
																				ngbTooltip="{{ file.file_name }}"
																			>
																				<div
																					class="d-flex gap-50 align-items-center justify-content-between"
																				>
																					<div class="d-flex gap-50">
																						<i
																							class="fa-light sm-font"
																							[ngClass]="
																								file.file_type === '.txt'
																									? 'text-primary fa-file-lines'
																									: file.file_type ===
																									  '.pdf'
																									? 'text-danger fa-file-pdf'
																									: file.file_type ===
																											'.xls' ||
																									  file.file_type ===
																											'.xlsx'
																									? 'text-success fa-file-excel'
																									: file.file_type ===
																											'.doc' ||
																									  file.file_type ===
																											'.docx'
																									? 'text-info fa-file-word'
																									: 'text-primary fa-file'
																							"
																						></i>
																						<div
																							class="sm-font d-flex gap-50 title-color"
																						>
																							<span class="updaload-file-name">{{
																								file.file_name
																							}}</span>
																							<span class=""
																								>{{
																									file.file_size_with_unit
																								}}
																								MB</span
																							>
																						</div>
																					</div>
																					<div class="sm-font">
																						<i
																							class="fa-light fa-download"
																						></i>
																					</div>
																				</div>
																			</div>
																		</div>
																	</ng-container>
																</div>
															</div>
														</div>
														<!-- <div class="reg-font fw-500 mb-1 mt-2">{{"UI.k_custom_fileds" | translate}}</div>                                
                            <div class="row align-items-start mb-1">
                              <div class="col-lg-2 col-md-3 mt-50">
                                <span class="sm-font">Field name</span>
                              </div>
                              <div class="col-lg-6 col-md-9">                              
                                <div class="sm-font">                                  
                                  <div class="form-group">                                  
                                    <input
                                      type="text"
                                      [(ngModel)]="incident_data_copy.reporter_info.name"                                   
                                      id="txtReporterName"
                                      class="form-control"
                                      placeholder="{{ 'UI.k_name' | translate }}"
                                      name="txtReporterName"                                                                                                           
                                    />                                  
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row align-items-start mb-1">
                              <div class="col-lg-2 col-md-3 mt-50">
                                <span class="sm-font">Field name</span>
                              </div>
                              <div class="col-lg-6 col-md-9">                              
                                <div class="sm-font">                                  
                                  <div class="form-group">                                  
                                    <input
                                      type="text"
                                      [(ngModel)]="incident_data_copy.reporter_info.email"                                   
                                      id="txtReporterEmail"
                                      class="form-control"
                                      placeholder="{{ 'UI.k_email' | translate }}"
                                      name="txtReporterEmail"                                                                                                           
                                    />                                  
                                  </div>
                                </div>
                              </div>
                            </div> -->
														<!-- <div class="reg-font fw-500 mb-1 mt-2">{{"UI.k_resolution" | translate}} {{"UI.k_details" | translate}}</div>
                            <div class="row">
                              <div class="col-lg-12 col-md-12">
                                <div class="mb-50">
                                  <span class="sm-font">Description</span>
                                </div>
                                <div class="sm-font mb-50">
                                  <textarea
                                    type="text"
                                    rows="3"
                                    [(ngModel)]="resolutionDetails"                                   
                                    id="txtResolutionDetails"
                                    class="form-control"
                                    placeholder="{{'UI.k_resolution' | translate}} {{'UI.k_details' | translate}}"
                                    name="txtResolutionDetails"                                                                                                           
                                  ></textarea>                                         
                                </div>
                              </div>
                            </div>
                            <div class="row align-items-start mb-1">
                              <div class="col-lg-2 col-md-3">
                                <span class="sm-font">{{ "UI.k_resolution_date" | translate }}</span>
                              </div>
                              <div class="sm-font">
                                {{ incident_data?.inci_resolution_time | date }}
                              </div>
                            </div> -->
													</div>
												</ng-scrollbar>
											</div>
											<div
												*ngIf="selectedItemName === 'communication_tab'"
												class="flex-auto-height"
											>
												<app-dns-communication
													class="dns-communication-container"
													[ccAddress]="dropdown.cc_address"
													[toAddress]="dropdown.to_address"
													[users]="dropdown.users"
													[close_type]="dropdown.close_type"
													[requesters]="dropdown.requesters"
													[basicProfile]="incident_data?.basic_info"
													[displayId]="incident_data?.display_id"
													[refId]="incidentId"
													[isRefDataLoad]="incident_data ? true : false"
													[permissions]="permissions"
													[internelNotes]="internelNotes"
													[lav_module]="lav_module"
													[moduleType]="'INCIDENT'"
													[submitActions]="submitActions"
													[refData]="incident_data"
													[dateTimeOptions]="dateTimeOptions"
													[agreedDateTimeOptions]="agreedDateTimeOptions"
													[filtered_status]="dropdown.filtered_status"
													[ref_data_copy]="incident_data_copy"
													[custom_field_config]="custom_field_config"
													[custom_field_data]="custom_field_data"
													[disable_config]="disable_config"
													[disableCommentSubmit]="disableCommentSubmit"
													[currentUser]="currentUser"
													[isReadUnread]="true"
													[isSeenBy]="true"
													[imapInputData]="imapInputData"
													[signatureData]="signatureInputData"
													[is_from_disabled]="is_from_disabled"
													[imap_list]="imap_list"
													(addRichTextData)="setRichTextData($event)"
													(editRefData)="editIncidentData($event)"
													(setClosureNoteData)="setClosureNoteData($event)"
													(setApprovalData)="setApprovalData($event)"
													(handleEditorData)="handleEditorData($event)"
													(onClosureStatusChange)="onStatusChange($event)"
													(getRefMailConversations)="getRefMailConversations($event)"
													(messageObjectData)="markAsReadUnread($event)"
													(chooseExistingTicket)="openChooseExisting($event)"
													[showMoreActionBtn]="true"
													class="full-height set-footer"
												></app-dns-communication>
											</div>
											<div *ngIf="selectedItemName === 'task'" class="flex-auto-height">
												<div class="flex-1">
													<ng-scrollbar
														class="custom-scrollbar section-scrollbar"
														#scrollable
													>
														<div class="main-content border-0">
															<div class="main-sec">
																<div class="action-bar">
																	<div class="inner-section">
																		<div class="left-section">
																			<app-search></app-search>
																		</div>
																		<div class="right-section">
																			<button
																				*ngIf="permissions?.add"
																				class="btn btn-primary pull-right ml-25"
																				rippleEffect
																				id="btnAddRisk"
																				name="btnAddRisk"
																				tooltipClass="fadeInAnimation"
																				[ngbTooltip]="'New Task'"
																				placement="left"
																				(click)="
																					toggleSidebar('create-task-sidebar')
																				"
																			>
																				<i class="fa-light fa-plus"></i>
																				<span class="d-none d-sm-inline-block">
																					{{ "UI.k_new" | translate }}
																					{{ "UI.k_task" | translate }}
																				</span>
																			</button>
																		</div>
																	</div>
																</div>
																<div class="action-section">
																	<div class="table-view flex-auto-height">
																		<table class="taskdataTable">
																			<tr
																				*ngFor="
																					let item of taskData;
																					let i = index
																				"
																				class="border-bottom"
																			>
																				<td class="pl-1 pr-25 py-2">
																					<div
																						class="custom-control custom-checkbox"
																					>
																						<input
																							type="checkbox"
																							class="custom-control-input"
																							id="cb_{{ item.id }}"
																							[(ngModel)]="isChecked"
																							(change)="
																								toggleSidebar(
																									'view-task-sidebar'
																								)
																							"
																						/>
																						<label
																							class="custom-control-label"
																							for="cb_{{ item.id }}"
																						></label>
																					</div>
																				</td>
																				<td
																					class="px-1 py-2"
																					style="width: 100%"
																				>
																					<p
																						class="text-color mb-0 fw-300 reg-font"
																					>
																						{{ item?.description }}
																					</p>
																				</td>
																				<td
																					class="px-25 py-2 text-right"
																					style="width: 100px"
																				>
																					<div
																						class="badge badge-light-{{
																							item?.status?.class
																						}}"
																					>
																						<span class="reg-font">
																							{{ item?.status?.name }}
																						</span>
																					</div>
																				</td>
																				<td class="px-25 py-2 text-right">
																					<div
																						class="badge badge-light-{{
																							item?.priority?.class
																						}}"
																					>
																						<i
																							class="fa-light {{
																								item?.priority?.icon
																							}} mr-25"
																							style="
																								vertical-align: middle !important;
																								line-height: 0 !important;
																								height: auto !important;
																							"
																							*ngIf="item?.priority?.icon"
																						></i>
																						<span class="reg-font">
																							{{ item?.priority?.name }}
																						</span>
																					</div>
																				</td>
																				<td class="px-50 py-2 text-right">
																					<p
																						class="text-muted-color mb-0 reg-font"
																					>
																						{{ item.time }}
																					</p>
																				</td>
																				<td class="pr-1 pl-25 py-2">
																					<div
																						class="media d-flex justify-content-center"
																					>
																						<a
																							href="javascript:void(0);"
																							class="d-flex justify-content-center"
																						>
																							<img
																								*ngIf="
																									item?.profile
																										?.profile_image
																								"
																								[src]="
																									item?.profile
																										?.profile_image
																								"
																								class="rounded-circle"
																								alt="profile image"
																								width="100%"
																							/>

																							<div
																								*ngIf="
																									!item?.profile
																										?.profile_image &&
																									item?.profile
																										?.profileName
																								"
																								class="avatar avatar-76-80 rounded-circle"
																								[ngStyle]="{
																									'background-color':
																										avatarBackgroundColor
																								}"
																							>
																								<span
																									class="avatar-content reg-font"
																									[ngStyle]="{
																										'background-color':
																											avatarBackgroundColor
																									}"
																								>
																									{{
																										item?.profile
																											?.profileName
																											| initials
																											| slice: 0:2
																									}}</span
																								>
																							</div>
																							<div
																								*ngIf="
																									!item?.profile
																										?.profile_image &&
																									!item?.profile
																										?.profileName
																								"
																								[ngStyle]="{
																									'background-color':
																										''
																								}"
																								class="avatar avatar-76-80 rounded-circle mr-50"
																							>
																								<span
																									class="avatar-content"
																								>
																									<i
																										class="fa-light fa-user fa-1x user-icon-clr"
																									></i>
																								</span>
																							</div>
																						</a>
																					</div>
																				</td>
																			</tr>
																		</table>
																	</div>
																</div>
															</div>
														</div>
													</ng-scrollbar>
												</div>
											</div>
											<div *ngIf="selectedItemName === 'sla'" class="flex-auto-height">
												<div class="flex-1">
													<ng-scrollbar
														class="custom-scrollbar section-scrollbar max-full-width"
													>
														<app-dns-sla-info-card
															[matricsData]="matricsData"
														></app-dns-sla-info-card>
													</ng-scrollbar>
												</div>
											</div>
											<div class="flex-auto-height" *ngIf="selectedItemName === 'attachment'">
												<div class="flex-1">
													<div class="full-height">
														<ng-scrollbar class="custom-scrollbar section-scrollbar">
															<div class="card mb-0 square border-0">
																<div class="card-body pl-0 pt-1 pr-0 pb-1">
																	<div class="d-flex w-100 mb-2 attachment-dropdown justify-content-between">
																		<div class="form-group mb-0">
																			<ng-select
																				[items]="
																					dropdown.attach_filter_category_list
																				"
																				[hideSelected]="true"
																				bindLabel="name"
																				bindValue="id"
																				(change)="
																					selectAttachmentCategory($event)
																				"
																				id="txtAttachCategory"
																				name="txtAttachCategory"
																				class="form-control"
																				placeholder="{{
																					'UI.k_select_dropdown' | translate
																				}}"
																				[(ngModel)]="selected_attachment_id"
																				[clearable]="false"
																			>
																			</ng-select>
																		</div>
																		<button
																		*ngIf="
																			(permissions?.add || permissions?.edit) &&
																			incident_data_copy?.basic_info?.state?.id !== 5 &&
																			incident_data_copy?.is_exit !== true &&
																			disable_config === false
																		"
																		id="btnAddFiles"
																		name="btnAddFiles"
																		[attr.data-testid]="'btnAddFiles'"
																		class="btn btn-primary pull-right ml-half"
																		tooltipClass="fadeInAnimation"
																		id="btnAttachmentIncident"
																		(click)="attachmentSidebar('app-dns-attachment')"
																		placement="bottom"
																		ngbTooltip="{{ 'UI.k_add_attachment' | translate }}"
																		>
																			<i class="fa-light fa-circle-plus"></i>{{ "UI.k_add" | translate }}
																		</button>
																	</div>
																	<div
																		class="all-attachment-list incident-sidebar-attachments-list"
																	>
																		<ng-container
																			*ngIf="
																				isObjectEmpty(
																					filteredAttachmentByCategory
																				)
																			"
																		>
																			<div class="no-attechments">
																				<div
																					class="d-flex justify-content-center mt-1"
																				>
																					<img
																						class="rounded"
																						src="assets/images/incident/files_empty_state.svg"
																						alt="nodata-bg"
																						width="200"
																						height="106"
																					/>
																				</div>
																				<div
																					class="d-flex justify-content-center mt-1"
																				>
																					{{
																						"UI.k_no_attachment" | translate
																					}}
																				</div>
																			</div>
																		</ng-container>
																		<ng-container
																			*ngFor="
																				let item of filteredAttachmentByCategory
																					| keyvalue
																			"
																		>
																			<ng-container
																				*ngIf="isObjectEmpty(item.value)"
																			>
																				<div class="no-attechments">
																					<div
																						class="d-flex justify-content-center mt-1"
																					>
																						<img
																							class="rounded"
																							src="assets/images/incident/files_empty_state.svg"
																							alt="nodata-bg"
																							width="200"
																							height="106"
																						/>
																					</div>
																					<div
																						class="d-flex justify-content-center mt-1"
																					>
																						{{
																							"UI.k_no_attachment"
																								| translate
																						}}
																					</div>
																				</div>
																			</ng-container>
																			<ng-container
																				*ngFor="
																					let file of item.value;
																					let i = index
																				"
																			>
																				<div
																					class="category-attachment-list bg-body border"
																				>
																					<span class="sm-font text-muted"
																						>{{ item.key }} {{ i + 1 }} ({{
																							item.value.length || 0
																						}})</span
																					>
																					<div
																						class="d-flex justify-content-between align-items-baseline"
																					>
																						<div
																							id="attachment-{{
																								file.id
																							}}"
																							class="left-info-sec align-items-center d-flex gap-50"
																						>
																							<div class="text-center">
																								<div
																									class="avatar"
																									[ngClass]="
																										file.file_type ===
																										'.txt'
																											? 'bg-light-primary'
																											: file.file_type ===
																											  '.pdf'
																											? 'bg-light-danger'
																											: file.file_type ===
																													'.xls' ||
																											  file.file_type ===
																													'.xlsx'
																											? 'bg-light-success'
																											: file.file_type ===
																													'.doc' ||
																											  file.file_type ===
																													'.docx'
																											? 'bg-light-info'
																											: 'bg-light-primary'
																									"
																								>
																									<div
																										class="avatar-content"
																										id="feather_size"
																									>
																										<i
																											class="{{
																												file.file_icon
																											}} fa-light line-height-1 full-height w-100 d-flex align-items-center justify-content-center lg-font avatar-icon"
																											aria-hidden="true"
																										></i>
																									</div>
																								</div>
																							</div>
																							<div
																								class="media-body d-flex flex-column justify-content-between"
																							>
																								<span
																									class="pl-0 sm-font d-flex overflow-wrap file_position title-color mb-0"
																								>
																									<span
																										class="fw-500 mr-50 file-name truncate-txt"
																										tooltipClass="fadeInAnimation"
																										ngbTooltip="{{
																											file.file_name
																										}}"
																										>{{
																											file.file_name
																										}}</span
																									><span
																										class="file-version"
																										>v{{
																											file.version
																										}}</span
																									>
																								</span>
																								<span
																									class="xs-font line-height-1"
																									>{{
																										file.file_size_with_unit
																									}}
																									MB
																									<b
																										class="fw-600 xl-font line-height-1"
																										>.</b
																									>
																									{{
																										file.attachment_date
																									}}</span
																								>
																							</div>
																						</div>
																						<div
																							class="right-btn-sec d-flex"
																						>
																							<button 
																							type="button" 
																							class="btn btn-sm btn-flat-primary btn-icon" 
																							*ngIf="
																							file?.description
																							"
																							ngbTooltip="{{
																								file?.description
																							}}"
																							id="btnInfoAttachment" 
																							name="btnInfoAttachment"
																							[attr.data-testid]="'btnInfoAttachment'"
																							rippleEffect>
																							<i class="fa-light fa-circle-info"></i>
																						    </button>
																							<!-- <span
																								*ngIf="
																									file?.description
																								"
																								class="pull-right"
																								tooltipClass="fadeInAnimation"
																								ngbTooltip="{{
																									file?.description
																								}}"
																								placement="bottom"
																							>
																								<i
																									class="fa-light fa-info-circle mr-half avatar-icon font-small-3 text-primary"
																								></i>
																							</span> -->
																							<button type="button" 
																							id="btnDownloadAttachment" 
																							name="btnDownloadAttachment"
																							[attr.data-testid]="'btnDownloadAttachment'"
																							(click)="
																							downloadAttachment(
																								file.file_path,
																								file.zip_file_name
																							)
																							"
																							class="btn btn-sm btn-flat-primary btn-icon" 
																							ngbTooltip="{{
																								'UI.k_download'
																									| translate
																							}}" 
																							rippleEffect>
																							<i
																							class="fa-light fa-download avatar-icon cursor-pointer reg-font"
																							></i>
																							</button>
																							<!-- <span
																								class=""
																								id="btnDownloadAttachment"
																								(click)="
																									downloadAttachment(
																										file.file_path,
																										file.zip_file_name
																									)
																								"
																								tooltipClass="fadeInAnimation"
																								placement="left"
																								ngbTooltip="{{
																									'UI.k_download'
																										| translate
																								}}"
																							>
																								<i
																									class="fa-light fa-download avatar-icon cursor-pointer reg-font text-primary"
																								></i>
																							</span> -->
																							<button type="button"
																							*ngIf="
																									permissions?.delete &&
																									incident_data_copy?.is_exit !== true &&
																									disable_config === false
																								"
																							class="btn btn-sm btn-flat-danger btn-icon" 
																							id="btnDeleteAttachment"
																							name="btnDeleteAttachment"
																							[attr.data-testid]="'btnDeleteAttachment'"
																							(click)="deleteAttachment(file)"
																							ngbTooltip="{{ 'UI.k_delete' | translate }}"
																							 rippleEffect>
																							 <i
																							 class="fa-light fa-trash-can avatar-icon reg-font"
																						 ></i>
																							  </button>
																							<!-- <span
																								*ngIf="
																									permissions?.delete &&
																									incident_data_copy?.is_exit !== true &&
																									disable_config === false
																								"
																								class="ml-50"
																								id="btnDeleteAttachment"
																								(click)="deleteAttachment(file)"
																								tooltipClass="fadeInAnimation"
																								ngbTooltip="{{ 'UI.k_delete' | translate }}"
																							>
																								<i
																									class="fa-light fa-trash-can cursor-pointer avatar-icon reg-font text-danger"
																								></i>
																							</span> -->
																						</div>
																					</div>
																				</div>
																			</ng-container>
																			<!-- </div> -->
																		</ng-container>
																	</div>
																</div>
															</div>
														</ng-scrollbar>
													</div>
												</div>
											</div>
											<div class="flex-auto-height" *ngIf="selectedItemName === 'knowledge_base'">
												<div class="flex-1">
													<div class="full-height">
														<ng-scrollbar class="custom-scrollbar section-scrollbar">
															<div class="card square" *ngIf="kb_permissions?.view">
																<div class="card-body">
																	<span
																		*ngIf="showKB"
																		class="reg-font title-color fw-500"
																		>{{ "UI.k_knowledgebase" | translate }}</span
																	>
																	<ng-container *ngIf="!disable_config && permissions?.edit">
																		<span
																		*ngIf="showSearch"
																		id="btnkbSeach"
																		name="kbSearch"
																		(click)="ShowAllKb('attach-knowledge-base')"
																		class="cursor-pointer pull-right"
																		><i class="fa-light fa-magnifying-glass"></i
																	></span>
																	</ng-container>


																	<!-- (click)="showKB=false; showSearch=false; showSearchInput=true;" -->
																	<ng-container *ngIf="filteredKBs?.length === 0">
																		<div
																			class="d-flex flex-column py-2 align-items-center"
																		>
																			<div
																				class="avatar bg-secondary rounded-lg mb-25"
																				id="BtnincIcons"
																			>
																				<div
																					class="avatar-content"
																					style="width: 28px; height: 28px"
																				>
																					<span
																						><i
																							class="fa-light fa-book-open fa-lg"
																						></i
																					></span>
																				</div>
																			</div>
																			<span class="sm-font text-muted">{{
																				"UI.k_no_records_yet" | translate
																			}}</span>
																		</div>
																	</ng-container>
																	<ng-container *ngIf="filteredKBs?.length > 0">
																		<div class="kb-list py-1">
																			<ng-container
																				*ngFor="let kb of filteredKBs"
																			>
																				<div
																					id="btn{{ kb.kb_id }}"
																					class="card p-1 border single-kb mb-0 shadow-none"
																				>
																					<div
																						class="d-flex flex-column gap-1"
																					>
																						<div
																							class="d-flex gap-2 top-kb-sec"
																						>
																							<a
																								class="cursor-pointer primary-color fw-600"
																								id="btnRedirectToKB"
																								(click)="
																									redirectToKb(kb.id)
																								"
																								routerLink="/kb/view/{{
																									kb.kb_id
																								}}"
																								ngbTooltip="{{
																									'UI.k_view'
																										| translate
																								}}"
																								target="_blank"
																							>
																								#{{ kb.display_id }}
																							</a>
																							<div
																								class="flex-wrap kb-user d-flex align-items-center"
																							>
																								<div
																									class="avatar avatar-xs mr-50"
																								>
																									<img
																										*ngIf="
																											kb.avatar
																										"
																										src="{{
																											kb.avatar
																										}}"
																										alt="Avatar"
																										width="16"
																										height="16"
																									/>
																									<div
																										*ngIf="
																											!kb.avatar
																										"
																										class="avatar-content"
																										[ngStyle]="{
																											'background-color':
																												kb.avatar_color
																										}"
																									>
																										{{
																											kb.created_by
																												| initials
																												| slice
																													: O
																													: 2
																										}}
																									</div>
																								</div>
																								<span
																									class="title-color fw-500"
																									>{{
																										kb.created_by
																									}}</span
																								>
																							</div>
																						</div>
																						<div
																							class="d-grid gap-1 kb-info"
																						>
																							<div class="img-sec">
																								<img
																									class="card-img-top img-fluid"
																									[ngClass]="{
																										'img-cover':
																											kb.image
																									}"
																									[src]="
																										kb.image
																											? kb.image +
																											  '?'
																											: imageMap[
																													kb
																														.kbtype
																											  ]
																									"
																									alt="KB Default"
																								/>
																							</div>
																							<div class="info-sec">
																								<div
																									class="fw-500 title-color mb-25 summary-ellipse"
																									*ngIf="
																										kb.title
																											?.length >
																										30
																									"
																									ngbTooltip="{{
																										kb.title
																											| slice
																												: 0
																												: 100
																									}}"
																									placement="top"
																								>
																									{{
																										kb.title.slice(
																											0,
																											60
																										)
																									}}
																									...
																								</div>
																								<div
																									class="fw-500 body-color mb-25 summary-ellipse"
																									*ngIf="
																										kb.title
																											?.length <=
																										30
																									"
																								>
																									{{ kb.title }}
																								</div>
																								<div
																									class="summary-ellipse"
																									*ngIf="
																										kb.content
																											?.length >
																										200
																									"
																									tooltip="kb.content"
																									ngbTooltip="{{
																										kb.content
																											| striphtml
																											| slice
																												: 0
																												: 250
																									}}"
																									placement="top"
																									[innerHtml]="
																										kb.content.slice(
																											0,
																											200
																										) + '...'
																									"
																								></div>
																								<div
																									class="summary-ellipse"
																									*ngIf="
																										kb.content
																											?.length <=
																										200
																									"
																									[innerHtml]="
																										kb.content
																									"
																								></div>
																								<ng-container
																									*ngIf="
																										kb.keywords
																											.length > 0
																									"
																								>
																									<div
																										class="keyword-list"
																									>
																										<ng-container
																											*ngFor="
																												let kw of kb.keywords
																											"
																										>
																											<span
																												class="sm-font border"
																												>{{
																													kw
																												}}</span
																											>
																										</ng-container>
																									</div>
																								</ng-container>
																							</div>
																						</div>
																					</div>
																					<div>
																						<!-- <div
																							class="d-flex flex-wrap justify-content-between mb-50 align-items-center"
																						>
																							<div
																								class="text-primary fw-500"
																							>
																								#{{ kb.display_id }}
																							</div>
																							<span
																								class="d-flex align-items-center"
																							>
																								<a
																									class="cursor-pointer mr-1 body-color"
																									id="btnRedirectToKB"
																									(click)="
																										redirectToKb(
																											kb.id
																										)
																									"
																									routerLink="/kb/view/{{
																										kb.kb_id
																									}}"
																									ngbTooltip="{{
																										'UI.k_view'
																											| translate
																									}}"
																									target="_blank"
																								>
																									<i
																										class="fa-light fa-circle-info"
																									></i>
																								</a>
																								<button
																									class="btn btn-sm btn-flat-primary active"
																									id="btnAttachToIncident"
																									name="btnAttachToIncident"
																									[attr.data-testid]="
																										'btnAttachToIncident'
																									"
																									(click)="
																										DettachToIncident(
																											kb
																										)
																									"
																								>
																									<i
																										class="fa-solid fa-thumbtack"
																									></i>
																									{{
																										"UI.k_pinned"
																											| translate
																									}}
																								</button>
																							</span>
																						</div>
																						<div
																							class="fw-500 title-color mb-25 summary-ellipse"
																							*ngIf="
																								kb.title?.length > 30
																							"
																							ngbTooltip="{{
																								kb.title | slice: 0:100
																							}}"
																							placement="top"
																						>
																							{{
																								kb.title.slice(0, 60)
																							}}
																							...
																						</div>
																						<div
																							class="fw-500 body-color mb-25 summary-ellipse"
																							*ngIf="
																								kb.title?.length <= 30
																							"
																						>
																							{{ kb.title }}
																						</div>
																						<div
																							class="mb-25 summary-ellipse"
																							*ngIf="
																								kb.content?.length > 200
																							"
																							tooltip="kb.content"
																							ngbTooltip="{{
																								kb.content
																									| striphtml
																									| slice: 0:150
																							}}"
																							placement="bottom"
																							[innerHtml]="
																								kb.content.slice(
																									0,
																									100
																								) + '...'
																							"
																						></div>
																						<div
																							class="mb-25 summary-ellipse"
																							*ngIf="
																								kb.content?.length <=
																								200
																							"
																							[innerHtml]="kb.content"
																						></div>
																						<div
																							class="d-flex align-items-center mb-75"
																						>
																							<div
																								class="flex-wrap kb-user mr-50 pr-50"
																							>
																								<div
																									class="avatar avatar-xs mr-50"
																								>
																									<img
																										*ngIf="
																											kb.avatar
																										"
																										src="{{
																											kb.avatar
																										}}"
																										alt="Avatar"
																										width="16"
																										height="16"
																									/>
																									<div
																										*ngIf="
																											!kb.avatar
																										"
																										class="avatar-content"
																										[ngStyle]="{
																											'background-color':
																												kb.avatar_color
																										}"
																									>
																										{{
																											kb.created_by
																												| initials
																												| slice
																													: O
																													: 2
																										}}
																									</div>
																								</div>
																								<span class="pt-25">{{
																									kb.created_by
																								}}</span>
																							</div>
																							<div>
																								<span
																									><i
																										class="fa-light fa-star"
																										style="
																											color: #ff9f43;
																											padding-top: 0.35rem;
																										"
																									></i></span
																								>&nbsp;
																								<span class="pt-25">{{
																									kb.rating
																								}}</span>
																							</div>
																						</div>
																						<div class="">
																							{{
																								"UI.k_last_updated_on"
																									| translate
																							}}
																							{{
																								kb.last_update_time
																									| date: "d MMM, y"
																							}}
																						</div> -->
																					</div>
																				</div>
																			</ng-container>
																		</div>
																	</ng-container>
																</div>
															</div>
														</ng-scrollbar>
													</div>
												</div>
											</div>
											<div *ngIf="selectedItemName === 'interaction'" class="flex-auto-height">
												<!-- <div class="p-1 border-bottom flex-initial">
                          <div class="d-flex justify-content-between align-items-center mb-75">
                              <div class="fw-500 text-dark" for="basic-request">
                                  {{ "UI.k_requester_details" | translate }}
                              </div>
                          </div>
                          <div class="d-flex align-items-center mb-1">
                              <div class="avatar mr-half">
                                  <div class="avatar" *ngIf="incident_data?.requester_profile">
                                      <img
                                          [src]="incident_data?.requester_profile"
                                          alt="Avatar"
                                          width="32"
                                          height="32"
                                      />
                                  </div>
                                  <div
                                      *ngIf="!incident_data?.requester_profile"
                                      id="divInitial"
                                      class="avatar-content"
                                      [ngStyle]="{
                                          'background-color': incident_data?.requester?.avatar_color
                                              ? incident_data?.requester?.avatar_color
                                              : '#f59518'
                                      }"
                                  >
                                      {{ incident_data?.requester?.full_name | initials | slice: 0:2 }}
                                  </div>
                              </div>
                              <div>
                                  <div class="fw-500 text-dark mb-0 text-break" id="divFullname">
                                      {{ incident_data?.requester?.full_name }}
                                  </div>
      
                                  <span class="text-break" id="divEmail">{{ incident_data?.requester?.email }}</span>
                              </div>
                          </div>
                          <div class="d-flex justify-content-start align-items-center">
                              <div *ngIf="incident_data?.requester?.phone_ext">
                                  <span class="mr-50 xs-font">
                                      <i class="fa-light fa-phone"></i>
                                  </span>
                                  <span class="sm-font" id="spPhone">{{
                                      incident_data?.requester?.phone_ext
                                  }}</span>
                              </div>
                          </div>
                        </div> -->
												<div class="flex-1 mt-2">
													<div class="full-height">
														<ng-scrollbar class="custom-scrollbar section-scrollbar">
															<div class="px-1">
																<div
																	*ngIf="requester_history?.length === 0"
																	class="my-5"
																>
																	<div
																		class="d-flex align-items-center justify-content-center"
																	>
																		<div
																			class="avatar bg-secondary rounded-lg"
																			id="BtnincIcons"
																		>
																			<div
																				class="avatar-content"
																				style="width: 28px; height: 28px"
																			>
																				<span
																					><i
																						class="fa-light fa-book-open"
																					></i
																				></span>
																			</div>
																		</div>
																	</div>
																	<div
																		class="d-flex align-items-center justify-content-center"
																	>
																		<small class="ml-75 text-muted py-auto">{{
																			"UI.k_no_interaction" | translate
																		}}</small>
																	</div>
																</div>
																<div
																	*ngFor="
																		let history of requester_history;
																		let i = index
																	"
																	class="text-break single-interaction"
																>
																	<div
																		class="mb-50 sm-font history-time body-color fw-500"
																	>
																		{{
																			history?.creation_time
																				| date: "d MMM y, h:mm:ss a"
																		}}
																	</div>
																	<div class="requester-state-status">
																		<div class="group-lists">
																			<div
																				class="custom-squre"
																				style="{{ history?.basic_info?.status?.text_color ? 'background-color: ' + history?.basic_info?.status?.text_color : 'background-color: ' + history?.basic_info?.status?.color
																			}}"
																			></div>
																			<span>{{
																				history?.basic_info?.status?.name
																			}}</span>
																			<div
																				class="right-arrow custom-text"
																				style="{{ history?.basic_info?.status?.text_color ? 'background-color: ' + history?.basic_info?.status?.text_color + '1f' : 'background-color: ' + history?.basic_info?.status?.color + '1f'
																			}}"
																			>
																				<span 
																				style="{{ history?.basic_info?.status?.text_color ? 'color: ' + history?.basic_info?.status?.text_color + '!important' : 'color: ' + history?.basic_info?.status?.color + '!important'
																				}}">{{ history?.basic_info?.state?.name }}</span>
																			</div>
																			<!-- <div
                                                      class="right-arrow custom-icon"
                                                      *ngIf="this.inputConfig && !inputConfig?.isTextMode"
                                                      [ngStyle]="{
                                                          'background-color': item.background_color,
                                                          color: item.text_color
                                                      }"
                                                  >
                                                      <i [class]="item.icon"></i>
                                                  </div> -->
																		</div>
																	</div>
																	<div
																		class="mb-50 fw-500 sm-font title-color incident-title-truncate"
																		placement="bottom"
																		ngbTooltip="{{ history?.basic_info.summary }}"
																	>
																		{{ history?.basic_info.summary }}
																	</div>
																	<div
																		class="d-flex justify-content-between align-items-center mb-50"
																	>
																		<div
																			class="d-flex justify-content-start align-items-center interaction-detail"
																		>
																			<div
																				class="sm-font text-primary mr-50 fw-500 cursor-pointer"
																				(click)="
																					moveToEdit(history.incident_id)
																				"
																			>
																				{{ history?.display_id }}
																			</div>
																			<div
																				class="badge badge-light-primary mr-50"
																			>
																				<i
																					class="fa-light fa-globe xs-font"
																				></i>
																			</div>
																			<div
																				*ngIf="history?.is_parent_incident"
																				class="badge badge-light-primary mr-50"
																			>
																				<i
																					class="fa-light fa-share-nodes xs-font"
																				></i>
																			</div>
																		</div>
																		<div
																			class="badge badge-light-{{
																				history?.basic_info?.priority?.color
																			}} badge-padding mr-50 sm-font"
																			*ngIf="history?.basic_info?.priority?.name"
																		>
																			<i
																				class="fa-light mr-25 mt-25 {{
																					history?.basic_info?.priority?.icon
																				}}"
																			></i
																			>{{
																				history?.basic_info?.priority
																					?.display_key | translate
																			}}
																		</div>
																		<!-- <div
                                                  class="badge badge-light-success badge-padding mr-50 sm-font"
                                              >
                                                  {{ history?.basic_info.state.name }}
                                              </div> -->
																	</div>
																	<div
																		class="d-flex mt-50 mb-75 align-items-center"
																		*ngIf="
																			history?.current_assignee_profile?.full_name
																		"
																	>
																		<div
																			*ngIf="history"
																			class="avatar avatar-xs mr-25"
																		>
																			<img
																				*ngIf="
																					history?.current_assignee_profile
																						?.profile_image
																				"
																				[src]="
																					history?.current_assignee_profile
																						?.profile_image
																				"
																				width="16"
																				height="16"
																			/>
																			<div
																				*ngIf="
																					!history?.current_assignee_profile
																						?.profile_image
																				"
																				class="avatar-content"
																				[ngStyle]="{
																					'background-color': history
																						?.current_assignee_profile
																						?.avatar_color
																						? history
																								?.current_assignee_profile
																								?.avatar_color
																						: '#f59518'
																				}"
																			>
																				{{
																					history?.current_assignee_profile
																						?.full_name
																						| initials
																						| slice: O:2
																				}}
																			</div>
																		</div>
																		<span class="sm-font line-height-1"
																			>&nbsp;&nbsp;{{
																				history?.current_assignee_profile
																					?.full_name
																			}}</span
																		>
																	</div>
																	<div
																		*ngFor="
																			let attachment of history?.attached_files
																		"
																		class="d-flex justify-content-between align-items-center bg-body attachments-detail mt-50 mb-50"
																	>
																		<div>
																			<div
																				class="sm-font d-flex justify-content-start align-items-center"
																			>
																				<i
																					class="fa-light fa-file-lines text-warning mr-25"
																				></i>
																				<span class="title-color ellipse">
																					{{
																						attachment.file_name
																					}}&nbsp;&nbsp;v{{
																						attachment.version
																					}}
																				</span>
																			</div>
																		</div>
																		<div class="download-file">
																			<div
																				(click)="
																					downloadAttachment(
																						attachment.file_path,
																						attachment.zip_file_name
																					)
																				"
																				class="cursor-pointer title-color"
																			>
																				<i class="fa-light fa-download"></i>
																			</div>
																		</div>
																		<!-- <span  class="xs-font ml-75">{{atttachment.file_name}}</span>	 -->
																	</div>
																</div>
																<div
																	class="d-flex justify-content-center pt-50 pb-1"
																	*ngIf="
																		displayAllInteraction &&
																		requester_history_dup.length - 2 > 0
																	"
																>
																	<button
																		type="button"
																		class="btn btn-outline-primary btn-sm"
																		id="btnShow"
																		name="btnShow"
																		[attr.data-testid]="'btnShow'"
																		(click)="ShowAllInteraction()"
																		rippleEffect
																	>
																		{{ "UI.k_all_interaction" | translate }}
																	</button>
																</div>
																<div
																	class="d-flex justify-content-center pt-50 pb-1"
																	*ngIf="!displayAllInteraction"
																>
																	<button
																		type="button"
																		class="btn btn-outline-primary btn-sm"
																		id="btnHide"
																		name="btnHide"
																		[attr.data-testid]="'btnHide'"
																		(click)="HideAllInteraction()"
																		rippleEffect
																	>
																		{{ "UI.k_hide_interaction" | translate }}
																	</button>
																</div>
															</div>
														</ng-scrollbar>
													</div>
												</div>
											</div>
											<div
												*ngIf="selectedItemName === 'resent_activity'"
												class="flex-auto-height"
											>
												<div class="activities flex-1 flex-auto-height">
													<div
														class="p-1 border-bottom d-flex justify-content-between align-items-center flex-initial"
													>
														<div class="col-4 px-0 input-group input-group-merge">
															<div class="input-group-prepend">
																<span class="input-group-text" id="basic-addon-search2"
																	><i class="fa-light fa-magnifying-glass"></i
																></span>
															</div>

															<input
																type="text"
																class="form-control form-control-sm"
																id="config-search"
																placeholder="{{
																	'UI.k_search_configuration' | translate
																}}"
																aria-label="Search..."
																aria-describedby="config-search"
																(keyup)="searchConfig($event)"
															/>
														</div>
														<div class="pull-right align-items-center d-flex p-0 m-0">
															<div class="align-items-center d-flex">
																<div
																	class="btn-group btn-group-toggle mr-1"
																	data-toggle="buttons"
																>
																	<label
																		class="btn btn-icon btn-outline-primary view-btn list-view-btn"
																		[class.active]="gridViewRef !== true"
																		ngbTooltip="{{
																			'UI.k_timeline_view' | translate
																		}}"
																		placement="bottom"
																	>
																		<input
																			type="radio"
																			name="radio_options"
																			id="historyListView"
																			(click)="listView()"
																		/>
																		<i class="fa-light fa-list"></i>
																	</label>
																	<label
																		class="btn btn-icon btn-outline-primary view-btn grid-view-btn"
																		[class.active]="gridViewRef === true"
																		ngbTooltip="{{ 'UI.k_grid_view' | translate }}"
																		placement="bottom"
																	>
																		<input
																			type="radio"
																			name="radio_options"
																			id="historyGridView"
																			(click)="gridView()"
																		/>
																		<i class="fa-light fa-grid"></i>
																	</label>
																</div>
															</div>

															<!-- <button
                                id="BHistory"
                                name="viewKBHistory"
                                class="btn btn-sm btn-outline-primary float-right"
                                rippleEffect
                                tooltipClass="fadeInAnimation"
                              >
                                <span class="d-none d-sm-inline-block sm-font"
                                  ><i class="fa-light fa-download sm-font"></i>
                                  {{ 'UI.k_export' | translate }}
                                </span>
                              </button> -->
														</div>
													</div>
													<div class="flex-1">
														<ng-scrollbar class="custom-scrollbar section-scrollbar">
															<ng-container *ngIf="!gridViewRef">
																<div class="inner-history-table flex-1 py-1">
																	<div class="flex-grow-1">
																		<app-history-table
																			[listview]="historyDataset"
																		></app-history-table>
																	</div>
																</div>
															</ng-container>
															<ng-container *ngIf="gridViewRef">
																<div class="inner-history-table flex-1 py-1">
																	<app-history-table [gridview]="historyDataset">
																	</app-history-table>
																</div>
															</ng-container>
														</ng-scrollbar>
													</div>
												</div>
											</div>
											<div *ngIf="selectedItemName === 'relation'" class="flex-auto-height">
												<div class="flex-1">
													<ng-scrollbar
														class="custom-scrollbar section-scrollbar max-full-width"
													>
														<div class="relations">
															<div class="px-1 pt-1 pb-2 d-flex">
																<div
																	id="divGetIncident"
																	[attr.data-testid]="'divGetIncident'"
																	[ngClass]="
																		relationModuleIDSel ===
																		infinity_module_ids?.INCIDENT
																			? 'badge badge-light-primary d-flex'
																			: 'badge badge-light-secondary d-flex'
																	"
																>
																	<span
																		(click)="
																			relationFilter(
																				infinity_module_ids?.INCIDENT
																			)
																		"
																		class="xs-font text-decoration-underline cursor-pointer"
																		>{{ "UI.k_incident" | translate }}
																	</span>
																	<ng-container
																		*ngIf="
																			permissions?.edit &&
																			!disable_config &&
																			!(
																				incident_data?.is_exit &&
																				incident_data?.basic_info?.status
																					?.state_id === 5
																			)
																		"
																	>
																		<span class="xs-font mx-50">|</span>
																		<span
																			class="pt-25 cursor-pointer"
																			tooltipClass="fadeInAnimation"
																			ngbTooltip="{{
																				'UI.k_add_association_relation'
																					| translate
																			}}"
																			placement="right"
																			(click)="
																				addRelations(infinity_modules?.INCIDENT)
																			"
																		>
																			<i class="fa-solid fa-circle-plus"></i
																		></span>
																	</ng-container>
																</div>
																<ng-container *ngIf="problem_permissions?.view">
																	<div
																		id="divGetProblem"
																		[attr.data-testid]="'divGetProblem'"
																		[ngClass]="
																			relationModuleIDSel ===
																			infinity_module_ids?.PROBLEM
																				? 'ml-1 badge badge-light-primary d-flex'
																				: 'ml-1 badge badge-light-secondary d-flex'
																		"
																	>
																		<span
																			(click)="
																				relationFilter(
																					infinity_module_ids?.PROBLEM
																				)
																			"
																			class="xs-font text-decoration-underline cursor-pointer"
																			>{{ "UI.k_problem" | translate }}
																		</span>
																		<ng-container
																			*ngIf="
																				permissions?.edit &&
																				!disable_config &&
																				!(
																					incident_data?.is_exit &&
																					incident_data?.basic_info?.status
																						?.state_id === 5
																				)
																			"
																		>
																			<span class="xs-font mx-50">|</span>
																			<span
																				class="pt-25 cursor-pointer"
																				tooltipClass="fadeInAnimation"
																				ngbTooltip="{{
																					'UI.k_add_association_relation'
																						| translate
																				}}"
																				placement="right"
																				(click)="
																					addRelations(
																						infinity_modules?.PROBLEM
																					)
																				"
																			>
																				<i class="fa-solid fa-circle-plus"></i
																			></span>
																		</ng-container>
																	</div>
																</ng-container>

																<ng-container *ngIf="change_permissions?.view">
																	<div
																		id="divgetChange"
																		[attr.data-testid]="'divgetChange'"
																		[ngClass]="
																			relationModuleIDSel ===
																			infinity_module_ids?.CHANGE
																				? 'ml-1 badge badge-light-primary d-flex'
																				: 'ml-1 badge badge-light-secondary d-flex'
																		"
																	>
																		<span
																			(click)="
																				relationFilter(
																					infinity_module_ids?.CHANGE
																				)
																			"
																			class="xs-font text-decoration-underline cursor-pointer"
																			>{{ "UI.k_change" | translate }}
																		</span>
																		<ng-container
																			*ngIf="
																				permissions?.edit &&
																				!disable_config &&
																				!(
																					incident_data?.is_exit &&
																					incident_data?.basic_info?.status
																						?.state_id === 5
																				)
																			"
																		>
																			<span class="xs-font mx-50">|</span>
																			<span
																				class="pt-25 cursor-pointer"
																				tooltipClass="fadeInAnimation"
																				ngbTooltip="{{
																					'UI.k_add_association_relation'
																						| translate
																				}}"
																				placement="right"
																				(click)="
																					addRelations(
																						infinity_modules?.CHANGE
																					)
																				"
																			>
																				<i class="fa-solid fa-circle-plus"></i
																			></span>
																		</ng-container>
																	</div>
																</ng-container>
																<ng-container *ngIf="request_permissions?.view">
																	<div
																		id="divgetRequest"
																		[attr.data-testid]="'divgetRequest'"
																		[ngClass]="
																			relationModuleIDSel ===
																			infinity_module_ids?.REQUEST_PROCESS
																				? 'ml-1 badge badge-light-primary d-flex'
																				: 'ml-1 badge badge-light-secondary d-flex'
																		"
																	>
																		<span
																			(click)="
																				relationFilter(
																					infinity_module_ids?.REQUEST_PROCESS
																				)
																			"
																			class="xs-font text-decoration-underline cursor-pointer"
																			>{{ "UI.k_request" | translate }}
																		</span>
																		<ng-container
																			*ngIf="
																				permissions?.edit &&
																				!disable_config &&
																				!(
																					incident_data?.is_exit &&
																					incident_data?.basic_info?.status
																						?.state_id === 5
																				)
																			"
																		>
																			<span class="xs-font mx-50">|</span>
																			<span
																				class="pt-25 cursor-pointer"
																				tooltipClass="fadeInAnimation"
																				ngbTooltip="{{
																					'UI.k_add_association_relation'
																						| translate
																				}}"
																				placement="right"
																				(click)="
																					addRelations(
																						infinity_modules?.REQUEST_PROCESS
																					)
																				"
																			>
																				<i class="fa-solid fa-circle-plus"></i
																			></span>
																		</ng-container>
																	</div>
																</ng-container>
																<ng-container *ngIf="release_permissions?.view">
																	<div
																		id="divgetRelease"
																		[attr.data-testid]="'divgetRelease'"
																		[ngClass]="
																			relationModuleIDSel ===
																			infinity_module_ids?.RELEASE
																				? 'ml-1 badge badge-light-primary d-flex'
																				: 'ml-1 badge badge-light-secondary d-flex'
																		"
																	>
																		<span
																			(click)="
																				relationFilter(
																					infinity_module_ids?.RELEASE
																				)
																			"
																			class="xs-font text-decoration-underline cursor-pointer"
																			>{{ "UI.k_release" | translate }}
																		</span>
																		<ng-container
																			*ngIf="
																				permissions?.edit &&
																				!disable_config &&
																				!(
																					incident_data?.is_exit &&
																					incident_data?.basic_info?.status
																						?.state_id === 5
																				)
																			"
																		>
																			<span class="xs-font mx-50">|</span>
																			<span
																				class="pt-25 cursor-pointer"
																				tooltipClass="fadeInAnimation"
																				ngbTooltip="{{
																					'UI.k_add_association_relation'
																						| translate
																				}}"
																				placement="right"
																				(click)="
																					addRelations(
																						infinity_modules?.RELEASE
																					)
																				"
																			>
																				<i class="fa-solid fa-circle-plus"></i
																			></span>
																		</ng-container>
																	</div>
																</ng-container>
															</div>

															<ng-container *ngIf="incidentRelations?.length > 0">
																<!-- parent child relation and incident association -->
																<div class="summary-card-list">
																	<ng-container *ngFor="let req of incidentRelations">
																		<div class="summary_card_info p-1 border">
																			<div
																				class="summary-card-head d-flex justify-content-between"
																			>
																				<div
																					class="left-sec d-flex gap-50 border-0"
																				>
																					<ng-container
																						*ngIf="
																							checkSourceName(
																								incidentMapping,
																								req,
																								incident_id,
																								module_id
																							)
																						"
																					>
																						<div
																							class="reg-font primary-color"
																							id="quick_icons"
																						>
																							<i
																								*ngIf="
																									req?.parent_module_id ===
																										module_id &&
																									req?.child_module_id ===
																										module_id
																								"
																								tooltipClass="fadeInAnimation"
																								ngbTooltip="{{
																									incidentMapping[
																										req?.parent_ref_id ===
																										incident_id
																											? req?.child_ref_id
																											: req?.parent_ref_id
																									]
																										?.incident_source_name
																								}}"
																								placement="left"
																								class="fa-light fa-{{
																									sourceIconMap[
																										incidentMapping[
																											req?.parent_ref_id ===
																											incident_id
																												? req?.child_ref_id
																												: req?.parent_ref_id
																										]
																											?.incident_source_id
																									]
																								}}"
																							></i>
																							<i
																								*ngIf="
																									checkInterModuleAssociation(
																										req,
																										request_module_id
																									)
																								"
																								tooltipClass="fadeInAnimation"
																								ngbTooltip="{{
																									incidentMapping[
																										req?.parent_ref_id ===
																										incident_id
																											? req?.child_ref_id
																											: req?.parent_ref_id
																									]
																										?.request_source_name
																								}}"
																								placement="left"
																								class="fa-light fa-{{
																									sourceIconMap[
																										incidentMapping[
																											req?.parent_ref_id ===
																											incident_id
																												? req?.child_ref_id
																												: req?.parent_ref_id
																										]
																											?.request_source_id
																									]
																								}}"
																							></i>
																							<i
																								*ngIf="
																									checkInterModuleAssociation(
																										req,
																										problem_module_id
																									)
																								"
																								tooltipClass="fadeInAnimation"
																								ngbTooltip="{{
																									incidentMapping[
																										req?.parent_ref_id ===
																										incident_id
																											? req?.child_ref_id
																											: req?.parent_ref_id
																									]
																										?.problem_source_name
																								}}"
																								placement="left"
																								class="fa-light fa-{{
																									sourceIconMap[
																										incidentMapping[
																											req?.parent_ref_id ===
																											incident_id
																												? req?.child_ref_id
																												: req?.parent_ref_id
																										]
																											?.problem_source_id
																									]
																								}}"
																							></i>
																							<i
																								*ngIf="
																									checkInterModuleAssociation(
																										req,
																										change_module_id
																									)
																								"
																								tooltipClass="fadeInAnimation"
																								ngbTooltip="{{
																									incidentMapping[
																										req?.parent_ref_id ===
																										incident_id
																											? req?.child_ref_id
																											: req?.parent_ref_id
																									]
																										?.change_source_name
																								}}"
																								placement="left"
																								class="fa-light fa-{{
																									sourceIconMap[
																										incidentMapping[
																											req?.parent_ref_id ===
																											incident_id
																												? req?.child_ref_id
																												: req?.parent_ref_id
																										]
																											?.change_source_id
																									]
																								}}"
																							></i>
																							<i
																								*ngIf="
																									checkInterModuleAssociation(
																										req,
																										release_module_id
																									)
																								"
																								tooltipClass="fadeInAnimation"
																								ngbTooltip="{{
																									incidentMapping[
																										req?.parent_ref_id ===
																										incident_id
																											? req?.child_ref_id
																											: req?.parent_ref_id
																									]
																										?.release_source_name
																								}}"
																								placement="left"
																								class="fa-light fa-{{
																									sourceIconMap[
																										incidentMapping[
																											req?.parent_ref_id ===
																											incident_id
																												? req?.child_ref_id
																												: req?.parent_ref_id
																										]
																											?.release_source_id
																									]
																								}}"
																							></i>
																						</div>
																					</ng-container>
																					<a
																						*ngIf="
																							req?.parent_module_id ===
																								module_id &&
																							req?.child_module_id ===
																								module_id
																						"
																						routerLink="/incident_view/incident-edit/{{
																							incidentMapping[
																								req?.parent_ref_id ===
																								incident_id
																									? req?.child_ref_id
																									: req?.parent_ref_id
																							]?.incident_id
																						}}"
																						target="_blank"
																					>
																						<span
																							class="pull-left sm-font body-color"
																							><b></b
																							>{{
																								incidentMapping[
																									req?.parent_ref_id ===
																									incident_id
																										? req?.child_ref_id
																										: req?.parent_ref_id
																								]?.display_id
																							}}</span
																						>
																					</a>
																					<a
																						*ngIf="
																							checkInterModuleAssociation(
																								req,
																								request_module_id
																							)
																						"
																						routerLink="/request_list/request-edit/{{
																							incidentMapping[
																								req?.parent_ref_id ===
																								incident_id
																									? req?.child_ref_id
																									: req?.parent_ref_id
																							]?.request_id
																						}}"
																						target="_blank"
																					>
																						<span
																							class="pull-left body-color sm-font"
																							><b></b
																							>{{
																								incidentMapping[
																									req?.parent_ref_id ===
																									incident_id
																										? req?.child_ref_id
																										: req?.parent_ref_id
																								]?.display_id
																							}}</span
																						>
																					</a>
																					<a
																						*ngIf="
																							checkInterModuleAssociation(
																								req,
																								problem_module_id
																							)
																						"
																						routerLink="/problem_management/problem-edit/{{
																							incidentMapping[
																								req?.parent_ref_id ===
																								incident_id
																									? req?.child_ref_id
																									: req?.parent_ref_id
																							]?.problem_id
																						}}"
																						target="_blank"
																					>
																						<span
																							class="pull-left body-color sm-font"
																							><b></b
																							>{{
																								incidentMapping[
																									req?.parent_ref_id ===
																									incident_id
																										? req?.child_ref_id
																										: req?.parent_ref_id
																								]?.display_id
																							}}</span
																						>
																					</a>
																					<a
																						*ngIf="
																							checkInterModuleAssociation(
																								req,
																								change_module_id
																							)
																						"
																						routerLink="/change/edit/{{
																							incidentMapping[
																								req?.parent_ref_id ===
																								incident_id
																									? req?.child_ref_id
																									: req?.parent_ref_id
																							]?.change_id
																						}}"
																						target="_blank"
																					>
																						<span
																							class="pull-left body-color sm-font"
																							><b></b
																							>{{
																								incidentMapping[
																									req?.parent_ref_id ===
																									incident_id
																										? req?.child_ref_id
																										: req?.parent_ref_id
																								]?.display_id
																							}}</span
																						>
																					</a>
																					<a
																						*ngIf="
																							checkInterModuleAssociation(
																								req,
																								release_module_id
																							)
																						"
																						routerLink="/rel/edit/{{
																							incidentMapping[
																								req?.parent_ref_id ===
																								incident_id
																									? req?.child_ref_id
																									: req?.parent_ref_id
																							]?.release_id
																						}}"
																						target="_blank"
																					>
																						<span
																							class="pull-left body-color sm-font"
																							><b></b
																							>{{
																								incidentMapping[
																									req?.parent_ref_id ===
																									incident_id
																										? req?.child_ref_id
																										: req?.parent_ref_id
																								]?.display_id
																							}}</span
																						>
																					</a>
																				</div>
																				<div class="right-sec">
																					<div
																						class="pull-right status_tag d-flex gap-25"
																					>
																						<div
																							class="fw-500 p-25 badge d-flex gap-25 badge-light-{{
																								incidentMapping[
																									req?.parent_ref_id ===
																									incident_id
																										? req?.child_ref_id
																										: req.parent_ref_id
																								]?.priority_color
																							}}"
																						>
																							<i
																								class="fa-light"
																								[ngClass]="{
																									'fa-triangle-exclamation':
																										incidentMapping[
																											req?.parent_ref_id ===
																											incident_id
																												? req?.child_ref_id
																												: req.parent_ref_id
																										]?.priority ===
																										1,
																									'fa-angles-up':
																										incidentMapping[
																											req?.parent_ref_id ===
																											incident_id
																												? req?.child_ref_id
																												: req.parent_ref_id
																										]?.priority ===
																										2,
																									'fa-angle-up':
																										incidentMapping[
																											req?.parent_ref_id ===
																											incident_id
																												? req?.child_ref_id
																												: req.parent_ref_id
																										]?.priority ===
																										3,
																									'fa-hyphen':
																										incidentMapping[
																											req?.parent_ref_id ===
																											incident_id
																												? req?.child_ref_id
																												: req.parent_ref_id
																										]?.priority ===
																										1
																								}"
																							></i>
																							<span class="comment">{{
																								incidentMapping[
																									req?.parent_ref_id ===
																									incident_id
																										? req?.child_ref_id
																										: req.parent_ref_id
																								]?.priority_name
																									| translate
																							}}</span>
																						</div>
																					</div>
																				</div>
																			</div>
																			<div class="summary-card-body">
																				<div
																					class="my-50 d-flex justify-content-start align-items-center"
																				>
																					<span
																						class="comment-sec pull-left ellipse fw-600 title-color"
																						>{{
																							incidentMapping[
																								req?.parent_ref_id ===
																								incident_id
																									? req?.child_ref_id
																									: req?.parent_ref_id
																							]?.summary | truncate
																						}}</span
																					>
																				</div>
																			</div>
																			<div class="summary-card-footer">
																				<div
																					class="my-50 d-flex justify-content-between align-items-center"
																				>
																					<span
																						*ngIf="
																							!incidentMapping[
																								req?.parent_ref_id ===
																								incident_id
																									? req?.child_ref_id
																									: req.parent_ref_id
																							]?.asset_id
																						"
																						class="comment ellipse"
																					>
																						<span class="sm-font">
																							{{
																								"UI.k_service"
																									| translate
																							}}:
																						</span>
																						<span
																							class="sm-font fw-500 title-color"
																							>{{
																								incidentMapping[
																									req?.parent_ref_id ===
																									incident_id
																										? req?.child_ref_id
																										: req?.parent_ref_id
																								]?.impact_service_name
																							}}</span
																						>
																					</span>
																					<ng-container
																						*ngIf="
																							incidentMapping[
																								req?.parent_ref_id ===
																								incident_id
																									? req?.child_ref_id
																									: req?.parent_ref_id
																							]?.asset_id &&
																							incidentMapping[
																								req?.parent_ref_id ==
																								incident_id
																									? req?.child_ref_id
																									: req?.parent_ref_id
																							]?.cmdb_id
																						"
																					>
																						<span class="comment"
																							><span class="sm-font"
																								>{{
																									"UI.k_asset_id"
																										| translate
																								}}
																								:</span
																							>
																							<span
																								*ngIf="
																									asset_permissions?.view &&
																									!request_data?.is_asset_disabled
																								"
																							>
																								<a
																									href="/it-asset/asset/{{
																										incidentMapping[
																											req?.parent_ref_id ===
																											incident_id
																												? req?.child_ref_id
																												: req?.parent_ref_id
																										]?.cmdb_id
																									}}"
																									target="_blank"
																									class="sm-font"
																									title="{{
																										incidentMapping[
																											req?.parent_ref_id ===
																											incident_id
																												? req?.child_ref_id
																												: req?.parent_ref_id
																										]?.asset_id
																									}}"
																								>
																									<span
																										class="reg-font fw-500 title-color"
																									>
																										{{
																											incidentMapping[
																												req?.parent_ref_id ===
																												incident_id
																													? req?.child_ref_id
																													: req?.parent_ref_id
																											]?.asset_id
																												| slice
																													: 0
																													: 19
																										}}{{
																											incidentMapping[
																												req?.parent_ref_id ===
																												incident_id
																													? req.child_ref_id
																													: req.parent_ref_id
																											]?.asset_id
																												?.length >
																											19
																												? ".."
																												: ""
																										}}
																									</span>
																								</a>
																							</span>
																							<span
																								*ngIf="
																									!asset_permissions?.view ||
																									request_data?.is_asset_disabled
																								"
																								class="sm-font"
																								title="{{
																									incidentMapping[
																										req?.parent_ref_id ===
																										incident_id
																											? req?.child_ref_id
																											: req?.parent_ref_id
																									]?.asset_id
																								}}"
																							>
																								<span
																									class="reg-font fw-500 title-color"
																								>
																									{{
																										incidentMapping[
																											req?.parent_ref_id ===
																											incident_id
																												? req?.child_ref_id
																												: req?.parent_ref_id
																										]?.asset_id
																											| slice
																												: 0
																												: 19
																									}}{{
																										incidentMapping[
																											req?.parent_ref_id ===
																											incident_id
																												? req.child_ref_id
																												: req.parent_ref_id
																										]?.asset_id
																											?.length >
																										19
																											? ".."
																											: ""
																									}}
																								</span>
																							</span>
																						</span>
																					</ng-container>
																					<div class="">
																						<div
																							*ngIf="
																								req?.relation_type ===
																									2 &&
																								req?.parent_ref_id !==
																									incident_id
																							"
																							class="fw-500 badge badge-light-success p-25"
																						>
																							<span class="comment">{{
																								"UI.k_parent"
																									| translate
																							}}</span>
																						</div>
																						<div
																							*ngIf="
																								req?.relation_type ===
																									2 &&
																								req?.parent_ref_id ===
																									incident_id
																							"
																							class="badge badge-light-danger fw-500 p-25"
																						>
																							<span class="comment">{{
																								"UI.k_child" | translate
																							}}</span>
																						</div>
																						<div
																							class="fw-500 badge badge-light-success p-25"
																							*ngIf="
																								req?.relation_type !== 2
																							"
																						>
																							<ng-container
																								*ngIf="
																									checkInterModuleAssociation(
																										req,
																										request_module_id
																									)
																								"
																							>
																								<span class="comment">{{
																									"UI.k_request"
																										| translate
																								}}</span>
																								&nbsp;
																							</ng-container>
																							<ng-container
																								*ngIf="
																									checkInterModuleAssociation(
																										req,
																										problem_module_id
																									)
																								"
																							>
																								<span class="comment">{{
																									"UI.k_problem"
																										| translate
																								}}</span>
																								&nbsp;
																							</ng-container>
																							<ng-container
																								*ngIf="
																									checkInterModuleAssociation(
																										req,
																										change_module_id
																									)
																								"
																							>
																								<span class="comment">{{
																									"UI.k_change"
																										| translate
																								}}</span>
																								&nbsp;
																							</ng-container>
																							<ng-container
																								*ngIf="
																									checkInterModuleAssociation(
																										req,
																										release_module_id
																									)
																								"
																							>
																								<span class="comment">{{
																									"UI.k_release"
																										| translate
																								}}</span>
																								&nbsp;
																							</ng-container>
																							<span class="comment">{{
																								"UI.k_associated"
																									| translate
																							}}</span>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</ng-container>
																</div>
															</ng-container>
															<ng-container
																*ngIf="
																	incidentRelations?.length === 0 ||
																	incidentRelations === undefined
																"
															>
																<div
																	class="d-flex flex-column align-items-center"
																	style="padding: 10rem"
																>
																	<div
																		class="avatar bg-secondary rounded-lg mb-25"
																		id="BtnincIcons"
																	>
																		<div class="avatar-content">
																			<span
																				><i
																					class="fa-light fa-book-open fa-lg"
																				></i
																			></span>
																		</div>
																	</div>
																	<span class="sm-font text-muted">{{
																		"Message.msg_no_relation" | translate
																	}}</span>
																</div>
															</ng-container>
														</div>
													</ng-scrollbar>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div
									class="modal-content-footer flex-initial"
									*ngIf="selectedItemName !== 'communication_tab' && permissions?.edit && incident_data_copy?.basic_info?.status?.state_id !== 5"
								>
									<div class="d-flex justify-content-end border-top px-1 py-1">
										<!-- <div ngbDropdown [placement]="'top-right'" class="btn-group mr-1 pr-1 border-right">
											<button
												ngbDropdownToggle
												type="button"
												class="btn btn-flat-primary btn-sm"
												rippleEffect
											>
												{{ "UI.k_more_action" | translate }}
											</button>
											<div ngbDropdownMenu>
												<a ngbDropdownItem href="javascript:void(0)">{{
													"UI.k_convert_in_to_problem" | translate
												}}</a>
												<a ngbDropdownItem href="javascript:void(0)">{{
													"UI.k_convert_in_to_request" | translate
												}}</a>
											</div>
										</div> -->
										<span
										class="txt-primary fw-500"
										*ngIf="
											!incident_data_copy?.approval_data?.is_approved &&
											incident_data_copy?.approval_data?.is_submitter &&
											incident_data_copy?.basic_info?.state?.id === 6
										"
										>{{ "UI.k_waiting_for_approval" | translate }}</span
										>
										<span
											class="txt-primary fw-500"
											*ngIf="
												!incident_data_copy?.approval_data?.can_review &&
												incident_data_copy?.approval_data?.is_review_submitter &&
												([7].includes(incident_data_copy?.basic_info?.state?.id) ||
												incident_data_copy?.basic_info?.status?.is_pir)
											"
											>{{ "UI.k_waiting_for_review" | translate }}</span
										>
										<button
										type="button"
										(click)="onApproval(incident_data_copy)"
										class="btn btn-primary btn-sm"
										*ngIf="
										incident_data_copy?.approval_data?.can_approve && incident_data_copy?.basic_info?.state?.id === 6 && moduleName !== 'work-space'
										"
											rippleEffect
										>
											<span class="align-middle">{{ "UI.k_approve" | translate }}</span>
										</button>
										<button
											type="button"
											(click)="onResolve($event, 'resolve')"
											class="btn btn-primary btn-sm"
											*ngIf="incident_data_copy?.basic_info?.status?.state_id < 4"
											rippleEffect
										>
											<span class="align-middle">{{ "UI.k_resolve" | translate }}</span>
										</button>
										<button
											type="button"
											(click)="onResolve($event, 'close')"
											class="btn btn-primary btn-sm"
											*ngIf="incident_data_copy?.basic_info?.status?.state_id === 4"
											rippleEffect
										>
											<span class="align-middle">{{ "UI.k_complete" | translate }}</span>
										</button>
									</div>
								</div>
							</div>
							<div class="flex-auto-height border-left bg-body">
								<div class="flex-initial border-bottom pt-1 stage-representation">
									<!-- <app-stage-representation
                    [inputConfig]="stageInputData"
                    class="progress-time"
                  ></app-stage-representation> -->
									<app-dns-stage-representation
										class="progress-time"
										[inputConfig]="copyOfstageInputData"
										[disableConfig]="disable_config || !permissions?.edit"
										(onActionCallback)="actionCallback($event)"
									></app-dns-stage-representation>
								</div>
								<div class="flex-1">
									<ng-scrollbar class="custom-scrollbar section-scrollbar">
										<div class="p-1 border-bottom">
											<div class="d-flex mb-50 gap-1 align-items-center">
												<span class="title-color fw-500">
													{{ "UI.k_requester" | translate }}
												</span>
												<!-- <button
												type="button"
												id="btnEditRequester"
												name="btnEditRequester"
												[attr.data-testid]="'btnEditRequester'"
												(click)="editRequester(incident_data?.requester)"
												class="btn btn-sm btn-icon btn-flat-primary"
												tooltipClass="fadeInAnimation"
												ngbTooltip="{{ 'UI.k_edit' | translate }}"
												rippleEffect
											>
												<i class="fa-light fa-pen-to-square"></i>
											</button> -->
											</div>
											<div class="d-flex justify-content-start align-items-center sidebar-panel-card" 
											[ngClass]="disable_config || !permissions?.edit ? 'no-event' : 'cursor-pointer'"
											(click)="editRequester(incident_data?.requester)">
												<div class="avatar mr-50" *ngIf="incident_data?.requester_profile" >
													<img
														alt="avatar img"
														height="32"
														width="32"
														[src]="incident_data?.requester_profile"
													/>
												</div>
												<div *ngIf="!incident_data?.requester_profile" class="avatar mr-50">
													<span
														class="avatar-content"
														[ngStyle]="{
															'background-color':
																incident_data?.requester?.avatar_color !== undefined
																	? incident_data?.requester?.avatar_color
																	: '#f59518'
														}"
														>{{
															this.incident_data?.requester?.full_name
																| initials
																| slice: O:2
														}}</span
													>
												</div>
												<div class="profile-user-info">
													<div class="mb-0 sm-font title-color fw-500 line-height-1">
														{{
															incident_data?.requester?.full_name?.length > 15
																? (incident_data?.requester?.full_name | slice: 0:15) +
																  "..."
																: incident_data?.requester?.full_name
														}}
													</div>
													<small class="text-muted sm-font line-height-1">{{
														incident_data?.requester.email
													}}</small>
												</div>
											</div>
											<div class="mt-75">
												<div>
													<span class="xs-font">{{ "UI.k_contact_number" | translate }}</span>
												</div>
												<div>
													<span
														*ngIf="this.incident_data?.requester?.contact_number"
														class="title-color sm-font fw-500"
													>
														{{ this.incident_data?.requester?.contact_number }}
													</span>
													<span *ngIf="!this.incident_data?.requester?.phone_number">-</span>
												</div>
											</div>
										</div>
										<div class="p-1 border-bottom">
											<div class="d-flex mb-50 align-items-center justify-content-between">
												<div class="d-flex gap-1 align-items-center">
													<span class="title-color fw-500">{{ "UI.k_assignee" | translate }}</span>
													<ng-container *ngIf="!disable_config && permissions?.edit && !extra_info.selected_assignee_info?.group_id">
														<button
														type="button"
														id="btnCurrentAssignee"
														name="btnCurrentAssignee"
														[attr.data-testid]="'btnCurrentAssignee'"
														(click)="openModalAD(CurrentAssigneeComp)"
														class="btn btn-sm btn-icon btn-flat-primary"
														tooltipClass="fadeInAnimation"
														ngbTooltip="{{ 'UI.k_add' | translate }}"
														rippleEffect
														>
															<i class="fw-500 lg-font fa-light fa-plus"></i>
														</button>
													</ng-container>
												</div>
												<button
												*ngIf="
													incident_data_copy?.current_assignment_info?.level &&
													incident_data_copy?.current_assignment_info?.assignee &&
													incident_data_copy?.is_escalation_configured
												"
												type="button"
												id="btnExpectedAssingee"
												name="btnExpectedAssingee"
												[attr.data-testid]="'btnExpectedAssingee'"
												(click)="expectedAssingee('expected-assingee-sidebar')"
												class="btn btn-sm btn-icon btn-flat-primary cursor-pointer"
												tooltipClass="fadeInAnimation"
												placement="left"
												ngbTooltip="{{ 'UI.k_team_escalation' | translate }}"
												rippleEffect
											>
												<i class="fa-light fa-users"></i>
											</button>
											</div>
											<div
												class="d-flex justify-content-start align-items-center sidebar-panel-card"
												*ngIf="extra_info.selected_assignee_info?.full_name" 
												[ngClass]="disable_config || !permissions?.edit ? 'no-event' : 'cursor-pointer'" 
												(click)="openModalAD(CurrentAssigneeComp)"
											>
												<div
													class="avatar mr-50"
													*ngIf="extra_info.selected_assignee_info?.profile_image"
												>
													<img
														alt="avatar img"
														height="32"
														width="32"
														[src]="extra_info.selected_assignee_info?.profile_image"
													/>
												</div>
												<div
													*ngIf="!extra_info.selected_assignee_info?.profile_image"
													class="avatar mr-50"
													[ngStyle]="{
														'background-color': incident_data?.requester_avatar_color
													}"
												>
													<span
														class="avatar-content"
														[ngStyle]="{
															'background-color':
																extra_info.selected_assignee_info?.profile
																	?.avatar_color !== undefined
																	? extra_info.selected_assignee_info?.profile
																			?.avatar_color
																	: '#f59518'
														}"
													>
														{{
															this.extra_info.selected_assignee_info?.full_name
																| initials
																| slice: O:2
														}}</span
													>
												</div>
												<div class="profile-user-info">
													<div
														class="mb-0 sm-font title-color fw-500 line-height-1"
														*ngIf="
															this.extra_info.selected_assignee_info?.full_name?.length <
															20
														"
													>
														{{ extra_info.selected_assignee_info?.full_name }}
													</div>
													<div
														class="mb-0 sm-font title-color fw-500 line-height-1"
														*ngIf="
															this.extra_info.selected_assignee_info?.full_name?.length >
															20
														"
													>
														{{
															(this.extra_info.selected_assignee_info?.full_name
																| slice: 0:18) + "..."
														}}
													</div>
													<span class="text-muted sm-font line-height-1">{{
														extra_info.selected_assignee_info?.email
													}}</span>
												</div>
											</div>
											<div class="d-flex mt-1 align-items-baseline justify-content-between">
												<div class="mr-50">
													<span class="xs-font">{{ "UI.k_group" | translate }}</span>
													<div>
														<span
															*ngIf="this.extra_info?.group_name"
															class="sm-font title-color fw-500"
														>
															{{ this.extra_info?.group_name }}
														</span>
														<span *ngIf="!this.extra_info?.group_name">-</span>
													</div>
												</div>
												<div class="mr-50">
													<div>
														<span class="xs-font">{{ "UI.k_level" | translate }}</span>
													</div>
													<div>
														<span
															*ngIf="this.extra_info?.level"
															class="sm-font title-color fw-500"
														>
															{{ this.extra_info?.level }}
														</span>
														<span *ngIf="!this.extra_info?.level">-</span>
													</div>
												</div>
												<div class="">
													<div>
														<span class="xs-font">{{ "UI.k_expertise" | translate }}</span>
													</div>
													<div>
														<span
															*ngIf="this.extra_info?.expertise"
															class="sm-font title-color fw-500"
														>
															{{ this.extra_info?.expertise }}
														</span>
														<span *ngIf="!this.extra_info?.expertise">-</span>
													</div>
												</div>
											</div>
										</div>
										<ng-container
											*ngIf="incident_data?.sla_profiles && incident_data.sla_profiles.length > 0"
										>
											<div class="p-1 border-bottom">
												<div class="mb-50 fw-500 title-color">
													{{ "UI.k_sla" | translate }} {{ "UI.k_details" | translate }}
												</div>
												<div class="sla-info-container">
													<div
														class="sla-info-item"
														[ngClass]="isLast === true ? '' : 'border-bottom pb-1'"
														*ngFor="let item of incident_data?.sla_profiles; last as isLast"
													>
														<div class="sla-info-header">
															<label class="sm-font fw-500 mb-50 title-color">{{
																item?.profile
															}}</label>
														</div>
														<div class="d-flex">
															<div class="w-50 inc-edit-prog">
																<app-dns-progress-timer-component
																	[metricData]="item?.metrices[0]"
																	[remaining_time]="item?.metrices[0]?.remaining_time"
																	[elapsed_percentage]="
																		item?.metrices[0]?.elapsed_percentage
																	"
																	[startTime]="item?.metrices[0]?.start_time"
																	[expectedEndTime]="
																		item?.metrices[0]?.expected_end_time
																	"
																	[endTime]="item?.metrices[0]?.end_time"
																	[isBreached]="item?.metrices[0]?.is_breached"
																	[timerClass]="'incident-edit'"
																></app-dns-progress-timer-component>
															</div>
															<div class="sla-info-item-divider border-right"></div>
															<div class="w-50 matric-slider-progress">
																<label class="mat-sl-label">{{
																	item?.metrices[0]?.metric_name
																}}</label>
																<div class="progress_bar_time">
																	<label>{{
																		item?.metrices[0]?.remaining_time
																	}}</label>
																	<label class="percent"
																		>{{
																			item?.metrices[0]?.elapsed_percentage
																		}}%</label
																	>
																</div>
																<div class="progress-wrapper">
																	<ngb-progressbar
																		height=".25rem"
																		class="progress_bar"
																		[type]="
																			getColorName(
																				item?.metrices[0]?.elapsed_percentage
																			)
																		"
																		[value]="item?.metrices[0]?.elapsed_percentage"
																		id="ProgressResponseTime"
																	></ngb-progressbar>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</ng-container>
										<div *ngIf="incident_data?.impacted_asset?.length > 0" class="p-1">
											<div class="d-flex mb-50 gap-1 align-items-center">
												<span class="title-color fw-500">
													{{ "UI.k_impacted_asset" | translate }}
												</span>
												<div class="d-flex align-items-center">
													<!-- <button
														type="button"
														id="btnInfoAction"
														name="btnInfoAction"
														[attr.data-testid]="'btnInfoAction'"
														(click)="info_action()"
														class="btn btn-sm btn-icon btn-flat-primary waves-effect"
														*ngIf="asset_permissions?.edit && !incident_data?.is_asset_disabled"
														rippleEffect
													>
														<i class="fa-light fa-pen-to-square"></i>
													</button> -->
												</div>
											</div>
											<div class="d-flex justify-content-start align-items-center cursor-pointer" (click)="info_action()">
												<div class="avatar asset-avatar bg-light-primary mr-50">
													<span
														*ngIf="
														incident_data?.impacted_asset[0]?.device_type &&
															getImageSrc(incident_data?.impacted_asset[0]?.device_type, 'device_type') !== ''
														"
														container="body"
														class="avatar-content"
														placement="left"
														ngbTooltip="{{ 'UI.k_device_type' | translate }}-{{
															incident_data?.impacted_asset[0]?.device_type
														}}"
													>
														<img
															class="thumbnail-image"
															[src]="getImageSrc(incident_data?.impacted_asset[0]?.device_type, 'device_type')"
														/>
													</span>
												</div>
												<div class="profile-user-info">
													<div class="mb-0 fw-500 title-color">{{ incident_data?.impacted_asset[0]?.ci_name}} </div>
													<small *ngIf="incident_data?.impacted_asset[0]?.ip_address?.length > 0" class="text-muted sm-font line-height-1">{{ incident_data?.impacted_asset[0]?.ip_address[0] }}</small>
												</div>
											</div>
											<div class="d-flex mt-1 align-items-baseline justify-content-between">
												<ng-container
													*ngFor="
														let cmdb_field of incident_data?.impacted_asset[0]
															?.customized_search_fields;
														let cmdbIndx = index
													"
												>
													<div class="mr-50">
														<span class="xs-font">{{ cmdb_field?.label | translate }}</span>
														<div>
															<span class="sm-font title-color fw-500">
																{{cmdb_field?.custom_field_id ? incident_data?.impacted_asset[0]?.[cmdb_field?.custom_field_id] :incident_data?.impacted_asset[0]?.[cmdb_field?.key] }}
															</span>
															<span
																class="sm-font title-color fw-500"
																*ngIf="
																	!incident_data?.impacted_asset[0]?.[cmdb_field?.custom_field_id ? cmdb_field?.custom_field_id : cmdb_field?.key]
																"
																>-</span
															>
														</div>
													</div>
												</ng-container>
												<!-- <div class="mr-50">
													<span class="xs-font">{{ "UI.k_asset_id" | translate }}</span>
													<div>
														<span class="sm-font title-color"> 12343132 </span>
													</div>
												</div>
												<div class="mr-50">
													<div>
														<span class="xs-font">{{ "UI.k_make" | translate }}</span>
													</div>
													<div>
														<span class="sm-font title-color">
															<img
																src="assets/images/asset/brand/dell.png"
																alt="icon"
																height="14"
																width="14"
																class="mr-25"
															/>
															Dell
														</span>
													</div>
												</div>
												<div class="">
													<div>
														<span class="xs-font">{{ "UI.k_os_short" | translate }}</span>
													</div>
													<div>
														<span class="sm-font title-color">
															<img
																src="assets/images/asset/os/windows.png"
																alt="icon"
																height="14"
																width="14"
																class="mr-25"
															/>
															windows
														</span>
													</div>
												</div> -->
											</div>
										</div>
									</ng-scrollbar>
								</div>
							</div>
						</div>
						<!-- <div class="modal-content-footer flex-initial" *ngIf="selectedItemName !== 'communication_tab'">
							<div class="d-flex justify-content-end border-top px-1 py-1">
								<div ngbDropdown [placement]="'top-right'" class="btn-group mr-1 pr-1 border-right">
									<button
										ngbDropdownToggle
										type="button"
										class="btn btn-flat-primary btn-sm"
										rippleEffect
									>
										{{ "UI.k_more_action" | translate }}
									</button>
									<div ngbDropdownMenu>
										<a ngbDropdownItem href="javascript:void(0)">{{
											"UI.k_convert_in_to_problem" | translate
										}}</a>
										<a ngbDropdownItem href="javascript:void(0)">{{
											"UI.k_convert_in_to_request" | translate
										}}</a>
									</div>
								</div>
								<button
									type="button"
									(click)="incidentDetailSidebar('incident-view-sidebar')"
									class="btn btn-primary btn-sm"
									rippleEffect
								>
									<span class="align-middle">{{ "UI.k_resolve" | translate }}</span>
								</button>
							</div>
						</div> -->
					</div>
					<div *ngIf="!dataLoaded" class="d-flex justify-content-center align-items-center height-400">
						<div class="spinner-border text-primary" aria-hidden="true"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<ng-template #appovalChangeComp let-modal>
		<app-approval-config
			[process_data]="incident_data"
			[options]="dropdown"
			[approval_status]="temp_status"
			[module_id]="10"
			(onApprovalConfigClose)="onApprovalConfigClose($event)"
			(onApprovalConfigSubmit)="onApprovalConfigSubmit($event)"
		>
		</app-approval-config>
	</ng-template>
	<ng-template #previewSignature let-modal>
		<div class="modal-header border-bottom">
			<h6 class="mb-0">
				{{ "UI.k_preview_signature" | translate }}
			</h6>
			<button
				type="button"
				id="previewClose"
				name="previewClose"
				[attr.data-testid]="'previewClose'"
				class="close"
				(click)="modal.dismiss('Cross click')"
				aria-label="Close"
			>
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body" tabindex="0" ngbAutofocus>
			<div class="d-flex align-items-center justify-content-between mb-1">
				<h3 class="mb-0">
					{{ previewData.text }}
				</h3>
				<span class="cursor-pointer" (click)="editTemplate(data)">
					<i class="fa-light fa-pen-to-square sm-font"></i>
				</span>
			</div>
			<div class="preview" [innerHtml]="previewData.value | safe: 'html'"></div>
		</div>
		<div class="d-flex justify-content-end border-top px-1 py-1">
			<button
				type="button"
				(click)="modal.dismiss('Cross click')"
				class="btn btn-outline-tertiary btn-prev mr-1"
				rippleEffect
			>
				<span class="align-middle"> {{ "UI.k_cancel" | translate }}</span>
			</button>
			<button
				type="button"
				id="previewData"
				name="previewData"
				[attr.data-testid]="'previewData'"
				(click)="useItSignature(previewData)"
				class="btn btn-primary btn-next"
				rippleEffect
			>
				<span class="align-middle">{{ "UI.k_use_it" | translate }}</span>
			</button>
		</div>
	</ng-template>
	<ng-template #CurrentAssigneeComp let-modal>
		<div class="modal-header">
			<h4 class="modal-title" id="myModalLabel1">
				{{ "UI.k_assignment" | translate }}
			</h4>
			<br />
			<!-- <small *ngIf="selfAssignSel">Please select group</small> -->
			<span
				id="btnAssignClose"
				name="btnAssignClose"
				data-dismiss="modal"
				aria-label="Close"
				(click)="closeCurrAssignPopup()"
			>
				<i class="fa-light fa-xmark mb-0 mr-sm-25 mr-0 pull-right"></i>
			</span>
		</div>
		<div class="modal-body" tabindex="0" ngbAutofocus>
			<form #currentAssigneeForm="ngForm">
				<div class="row mb-2">
					<div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
						<div class="form-group">
							<label for="txtInciSelAssignGroup"
								>{{ "UI.k_sel_team" | translate }}
								<span class="required-field">&nbsp;*</span>
							</label>
							<ng-select
								[items]="dropdown.teams_and_self_assign"
								(clear)="selfAssignSel = false"
								[hideSelected]="true"
								bindLabel="name"
								bindValue="team_id"
								[(ngModel)]="team_id"
								(change)="groupAssignChange(dropdown.teams, team_id)"
								name="txtInciSelAssignGroup"
								id="txtInciSelAssignGroup"
								placeholder="{{ 'UI.k_select' | translate }} {{
									'UI.k_group' | translate
								}}"
								#TDSelGroup="ngModel"
								[closeOnSelect]="true"
								[class.error]="
									!TDSelGroup.valid && currentAssigneeForm.submitted
								"
								required
							>
								<ng-template ng-label-tmp let-item="item">
									<span
										class="d-flex ng-value-label align-items-center"
										[ngbTooltip]="item.name"
										container="body"
									>
										<span
											class="avatar mr-50 avatar-sm"
											[ngClass]="bg - light - primary"
										>
											<div
												class="avatar-content"
												[ngStyle]="{
													'background-color': stringToColor(
														item.name
													)
												}"
											>
												{{ item.name | initials | slice: 0:2 }}
											</div>
										</span>
										<span class="font-small-2">
											{{ item?.name | slice: 0:28 }}
											{{ item?.name.length > 28 ? ".." : "" }}
										</span>
									</span>
								</ng-template>
								<ng-template
									ng-option-tmp
									let-item="item"
									let-index="index"
									let-search="searchTerm"
								>
									<span
										class="d-flex"
										(click)="selfAssignSel = false"
										*ngIf="item.team_id !== 'self_assign'"
									>
										<div
											class="avatar mr-1 ml-0"
											[ngStyle]="{
												'background-color': item.avatar_color
													? item.avatar_color
													: '#f59518'
											}"
											style="
												align-content: center;
												width: 32px;
												height: 32px;
											"
										>
											<div
												class="avatar-content"
												[ngStyle]="{
													'background-color': stringToColor(
														item.name
													)
												}"
											>
												{{ item.name | initials | slice: 0:2 }}
											</div>
										</div>
										<div class="cell-line-height">
											<p
												class="lg-font fw-500 line-height-1 mb-0"
												container="body"
												[ngbTooltip]="item.name"
											>
												{{ item.name | slice: 0:20 }}
												{{
													item?.name?.length > 20 ? ".." : ""
												}}
											</p>
											<span class="text-muted xs-font">
												{{ item.group_type }}</span
											>
										</div>
									</span>
									<div
										class="cursor-pointer"
										*ngIf="item.team_id === 'self_assign'"
									>
										<div class="media" (click)="assignToMyself()">
											<div class="avatar mr-1">
												<ng-container
													*ngIf="currentUser?.profile_image"
												>
													<img
														class="avatar-content"
														class=""
														[src]="
															currentUser?.profile_image
														"
														width="32"
														height="32"
													/>
												</ng-container>
												<ng-container
													id="divAssignee"
													*ngIf="!currentUser?.profile_image"
												>
													<span
														[ngStyle]="{
															'background-color':
																currentUser?.profile
																	?.avatar_color !==
																undefined
																	? currentUser
																			?.profile
																			?.avatar_color
																	: '#f59518',
															width: 32,
															height: 32
														}"
														class="avatar-content"
													>
														{{
															this.currentUser
																.first_name +
																"" +
																this.currentUser
																	.last_name
																| initials
																| slice: 0:2
														}}
													</span>
												</ng-container>
											</div>
											<div class="media-body">
												<h5 class="mb-0">
													{{ "UI.k_self_assign" | translate }}
												</h5>
												<small class="text-muted">{{
													this.currentUser.email
												}}</small>
											</div>
										</div>
									</div>
								</ng-template>
							</ng-select>
							<span
								*ngIf="
									currentAssigneeForm.submitted && TDSelGroup.invalid
								"
								class="invalid-form"
							>
								<small
									class="form-text text-danger"
									*ngIf="TDSelGroup.errors.required"
									>{{ "UI.k_field_required" | translate }}</small
								>
							</span>
						</div>
					</div>
					<div
						class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6"
						*ngIf="
							selfAssignSel && dropdown?.current_user_group?.length > 1
						"
					>
						<label for="txtInciAssignGroup">
							{{ "UI.k_select_group" | translate }}
							<span class="required-field">&nbsp;*</span>
						</label>
						<ng-select
							[items]="dropdown.current_user_group"
							[hideSelected]="true"
							(change)="
								groupChange(
									dropdown.current_user_group,
									currentAssignmentInfo.group
								)
							"
							bindLabel="name"
							bindValue="team_id"
							[(ngModel)]="currentAssignmentInfo.group"
							name="txtInciAssignGroup"
							id="txtInciAssignGroup"
							placeholder="{{ 'UI.k_select_group' | translate }} "
							#TDGroup="ngModel"
							[closeOnSelect]="true"
							[class.error]="
								!TDGroup.valid && currentAssigneeForm.submitted
							"
							required
						>
							<ng-template
								ng-option-tmp
								let-item="item"
								let-index="index"
								let-search="searchTerm"
							>
								<span class="d-flex">
									<div
										class="avatar mr-1 ml-0"
										[ngStyle]="{
											'background-color': item.avatar_color
												? item.avatar_color
												: '#f59518'
										}"
										style="
											align-content: center;
											width: 32px;
											height: 32px;
										"
									>
										<div
											class="avatar-content"
											[ngStyle]="{
												'background-color': stringToColor(
													item.name
												)
											}"
										>
											{{ item.name | initials | slice: 0:2 }}
										</div>
									</div>
									<div class="cell-line-height">
										<p class="lg-font fw-500 line-height-1 mb-0">
											{{ item.name | slice: 0:25 }}
											{{ item?.name?.length > 25 ? ".." : "" }}
										</p>
										<span class="text-muted xs-font">
											{{ item.group_type }}</span
										>
									</div>
								</span>
							</ng-template>
						</ng-select>
						<span
							*ngIf="currentAssigneeForm.submitted && TDGroup.invalid"
							class="invalid-form"
						>
							<small
								class="form-text text-danger"
								*ngIf="TDGroup.errors.required"
								>{{ "UI.k_field_required" | translate }}</small
							>
						</span>
					</div>

					<div
						class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6"
						*ngIf="
							selfAssignSel &&
							dropdown?.current_user_expertise?.length > 1
						"
					>
						<label for="txtInciEditExpertiseSelfAssign"
							>{{ "UI.k_select_expertise" | translate }}
							<span class="required-field">&nbsp;*</span>
						</label>
						<ng-select
							[items]="dropdown?.current_user_expertise"
							(change)="expertiseChange(dropdown?.current_user_expertise)"
							[hideSelected]="true"
							bindLabel="expertise"
							bindValue="expertise"
							[(ngModel)]="currentAssignmentInfo.expertise"
							name="txtInciEditExpertiseSelfAssign"
							id="txtInciEditExpertiseSelfAssign"
							placeholder="{{ 'UI.k_select_expertise' | translate }} "
							#TDInciEdiSelfAssignExpertise="ngModel"
							[closeOnSelect]="true"
							[class.error]="
								!TDInciEdiSelfAssignExpertise.valid &&
								currentAssigneeForm.submitted
							"
							required
						>
						</ng-select>
						<span
							*ngIf="
								currentAssigneeForm.submitted &&
								TDInciEdiSelfAssignExpertise.invalid
							"
							class="invalid-form"
						>
							<small
								class="form-text text-danger"
								*ngIf="TDInciEdiSelfAssignExpertise.errors.required"
								>{{ "UI.k_field_required" | translate }}</small
							>
						</span>
					</div>

					<div
						class="col-xs-12 col-md-6 col-xl-6 col-lg-6"
						*ngIf="
							!selfAssignSel &&
							current_extra_info.group_type === 'Operation and Technical'
						"
					>
						<div class="form-group">
							<label for="txtInciReporterExpertise"
								>{{ "UI.k_expertise" | translate }}
								<!-- <span class="required-field">&nbsp;*</span> -->
							</label>
							<ng-select
								[items]="dropdown?.expertise"
								[hideSelected]="true"
								(change)="expertiseChange(dropdown?.expertise)"
								bindLabel="expertise"
								bindValue="expertise"
								[(ngModel)]="currentAssignmentInfo.expertise"
								name="txtInciExpertise"
								id="txtInciExpertise"
								placeholder="{{ 'UI.k_select_expertise' | translate }} "
								#TDExpertise="ngModel"
								[closeOnSelect]="true"
							>
							</ng-select>
							<!-- <span
								*ngIf="
									currentAssigneeForm.submitted && TDExpertise.invalid
								"
								class="invalid-form"
							>
								<small
									class="form-text text-danger"
									*ngIf="TDExpertise.errors.required"
									>{{ 'UI.k_field_required' | translate }}</small
								>
							</span> -->
						</div>
					</div>
					<div
						class="col-xs-12 col-md-6 col-xl-6 col-lg-6"
						*ngIf="
							!selfAssignSel &&
							current_extra_info.group_type === 'Operation and Technical'
						"
					>
						<div class="form-group">
							<label for="txtInciReporterLevel"
								>{{ "UI.k_level" | translate }}
								<span
									*ngIf="currentAssignmentInfo.expertise"
									class="required-field"
									>&nbsp;*</span
								>
							</label>
							<ng-select
								*ngIf="!currentAssignmentInfo.expertise"
								[items]="dropdown.level"
								[hideSelected]="true"
								(change)="levelChange()"
								bindLabel="level"
								bindValue="level"
								[(ngModel)]="currentAssignmentInfo.level"
								name="txtMainReporterLevel"
								id="txtReporterLevel"
								placeholder="{{ 'UI.k_select_level' | translate }}"
								[closeOnSelect]="true"
							>
							</ng-select>
							<ng-container *ngIf="currentAssignmentInfo.expertise">
								<ng-select
									[items]="dropdown.level"
									[hideSelected]="true"
									(change)="levelChange()"
									bindLabel="level"
									bindValue="level"
									[(ngModel)]="currentAssignmentInfo.level"
									name="txtInciMainReporterLevel"
									id="txtInciMainReporterLevel"
									placeholder="{{ 'UI.k_select_level' | translate }}"
									#TDInciMainLevel="ngModel"
									[closeOnSelect]="true"
									[class.error]="
										!TDInciMainLevel?.valid &&
										currentAssigneeForm.submitted
									"
									required
								>
								</ng-select>
								<span
									*ngIf="
										currentAssigneeForm.submitted &&
										TDInciMainLevel?.invalid
									"
									class="invalid-form"
								>
									<small class="form-text text-danger">{{
										"UI.k_field_required" | translate
									}}</small>
								</span>
							</ng-container>
						</div>
					</div>
					<div
						class="col-xs-12 col-md-6 col-xl-6 col-lg-6"
						*ngIf="!selfAssignSel"
					>
						<div class="form-group">
							<label for="txtInciAssignee"
								>{{ "UI.k_assignee" | translate }}
								<!-- <span class="required-field">&nbsp;*</span> -->
							</label>
							<ng-select
								[items]="dropdown.assignee"
								#assignee="ngModel"
								[(ngModel)]="currentAssignmentInfo.assignee"
								(change)="assigneeChange()"
								bindLabel="full_name"
								bindValue="profile_id"
								name="txtInciAssignee"
								id="txtInciAssignee"
								[searchable]="true"
								[searchFn]="customSearchFn"
								placeholder="{{ 'UI.k_select_assignee' | translate }} "
								[multiple]="false"
								#TDAssignee="ngModel"
								[closeOnSelect]="true"
							>
								<ng-template
									ng-option-tmp
									let-item="item"
									let-index="index"
									let-search="searchTerm"
								>
									<span class="d-flex">
										<div
											*ngIf="
												dropdown.team_user_profile_map[
													item.profile_id
												]?.profile_image
											"
										>
											<div class="avatar mr-50">
												<span class="avatar-content">
													<img
														class="rounded-circle"
														src="{{
															dropdown
																.team_user_profile_map[
																item.profile_id
															].profile_image
														}}"
														alt="User"
														width="32"
														height="32"
													/>
												</span>
											</div>
										</div>
										<div
											*ngIf="
												!dropdown.team_user_profile_map[
													item.profile_id
												]?.profile_image
											"
										>
											<div
												class="avatar mr-50"
												[ngStyle]="{
													'background-color': dropdown
														.team_user_profile_map[
														item.profile_id
													]?.avatar_color
														? dropdown
																.team_user_profile_map[
																item.profile_id
														  ]?.avatar_color
														: '#f59518'
												}"
												style="
													align-content: center;
													width: 32px;
													height: 32px;
												"
											>
												<div class="avatar-content">
													{{
														item.full_name
															| initials
															| slice: 0:2
													}}
												</div>
											</div>
										</div>
										<div class="cell-line-height">
											<p
												class="lg-font fw-500 line-height-1 mb-0"
												[ngbTooltip]="item.full_name"
											>
												{{ item?.full_name | slice: 0:25 }}
												{{
													item?.full_name?.length > 25
														? ".."
														: ""
												}}
											</p>
											<span class="text-muted xs-font">
												{{ item?.email | slice: 0:25 }}
												{{
													item?.email?.length > 25 ? ".." : ""
												}}</span
											>
										</div>
									</span>
								</ng-template>
							</ng-select>
							<!-- <span
								*ngIf="
									currentAssigneeForm.submitted && TDAssignee.invalid
								"
								class="invalid-form"
							>
								<small
									class="form-text text-danger"
									*ngIf="TDAssignee.errors.required"
									>{{ 'UI.k_field_required' | translate }}</small
								>
							</span> -->
						</div>
					</div>
				</div>
				<div class="col-12 pl-0">
					<app-dns-custom-field
						placeholder="assignment"
						[formRef]="currentAssigneeForm"
						[data]="custom_field_data"
						[config]="custom_field_config"
					></app-dns-custom-field>
				</div>
				<div class="d-flex pull-right pb-half">
					<div class="mr-1">
						<button
							(click)="saveIncidentData(currentAssigneeForm)"
							type="submit"
							[disabled]="asgnDisabled"
							class="btn btn-primary"
							*ngIf="
								!(
									incident_data?.is_exit &&
									incident_data?.basic_info?.status?.state_id === 5
								) &&
								permissions?.edit &&
								disable_config === false
							"
							rippleEffect
							id="btnAssignSave"
							name="btnAssignSave"
							[attr.data-testid]="'btnAssignSave'"
						>
							<i class="fa-light fa-floppy-disk"></i>&nbsp;&nbsp;{{
								"UI.k_save_changes" | translate
							}}
						</button>
					</div>
				</div>
			</form>
		</div>
	</ng-template>
</div>

<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	overlayClass="modal-backdrop"
	name="attach-knowledge-base"
>
	<app-attach-knowledge-base
		*ngIf="showAttachedKB"
		[kbInput]="{
			'suggested-kb': suggested_kb,
			'attached-kb': filteredKBs,
			filter_string: ''
		}"
		(hideAllKb)="HideAllKb($event)"
		(updateAllKb)="updateAllKb($event)"
	></app-attach-knowledge-base>
</core-sidebar>

<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="app-associate-incident"
	overlayClass="modal-backdrop"
>
	<app-associate-relation
		*ngIf="showAddRelation"
		[parent_obj]="incident_data_copy"
		[options]="dropdown"
		[permissions]="permissions"
		[relations]="incidentRelations"
		[mapping]="incidentMapping"
		[relationModule]="relationModule"
		(onHideAssociateRelations)="onHideAssociateRelations($event)"
		[module_id]="module_id"
		[module]="module"
		(getRelations)="getRelations($event)"
	>
	</app-associate-relation>
</core-sidebar>

<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="close-resolve-incident"
	overlayClass="modal-backdrop"
>
	<!-- <app-incident-resolve
		*ngIf="load_inci_resolve"
		[incident_id]="incident_data_copy?.incident_id"
		[options]="dropdown"
		[stateKeyIdMap]="stateKeyIdMap"
		[selected_status]="selected_status"
		[is_call_from_edit]="true"
		(onUpdateResolveClose)="onUpdateResolveClose($event)"
		(onHideResolve)="onHideResolve($event)"
	>
	</app-incident-resolve> -->

	<app-dns-resolve-status
		*ngIf="load_inci_resolve"
		[incident_id]="incident_data_copy?.incident_id"
		[options]="dropdown"
		[stateKeyIdMap]="stateKeyIdMap"
		[selected_status]="selected_status"
		[is_call_from_edit]="true"
		(onUpdateResolveClose)="onUpdateResolveClose($event)"
		(onHideResolve)="onHideResolve($event)"
		[callFrom]="'panelCard'"
	>
	</app-dns-resolve-status>
</core-sidebar>

<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="incident-priority-change-incident-sidebar-inner"
	overlayClass="modal-backdrop"
>
	<ng-container *ngIf="is_priority_change">
		<app-priority-change
			sidebarName="incident-priority-change-incident-sidebar-inner"
			[config_data]="incident_data"
			(onPriorityChangeSubmit)="onPriorityChangeSubmit($event)"
			(onPrioritySliderClose)="onPrioritySliderClose($event)"
		>
		</app-priority-change>
	</ng-container>
</core-sidebar>

<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="incident-service-change-incident-sidebar-inner"
	overlayClass="modal-backdrop"
>
	<ng-container *ngIf="is_service_change">
		<app-service-change
			[options]="dropdown"
			sidebarName="incident-service-change-incident-sidebar-inner"
			[service_config]="service_change_data"
			(onServiceChangeSubmit)="onServiceChangeSubmit($event)"
			(onServiceSliderClose)="onServiceSliderClose()"
		>
		</app-service-change>
	</ng-container>
</core-sidebar>

<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="app-dns-kb-add" overlayClass="modal-backdrop">
	<app-dns-kb-add
		[event]="kbEvent"
		[item]="kbCurrentItem"
		*ngIf="kbHideProfile"
		(onHideKBProfile)="onHideKBProfile($event)"
		(addedKBID)="getKBID($event)"
		[kbSolution]="kbSolution"
		[isExternalCall]="true"
		[refId]="incident_id"
	>
	</app-dns-kb-add>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="app-incident-kb-solution"
	overlayClass="modal-backdrop"
>
	<app-dns-incident-kb-solution
		[kbID]="incident_data_copy?.kb_id"
		[kbSolution]="kbSolution"
		*ngIf="kbSolutionHideProfile"
		(onHideKBSolution)="onHideKBSolution($event)"
	>
	</app-dns-incident-kb-solution>
</core-sidebar>

<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="incident-to-request"
	overlayClass="modal-backdrop"
>
	<app-dns-incident-to-request
		*ngIf="showConvInciToReq"
		[request_data]="incident_data"
		(onHideConvInciToReq)="onHideConvInciToReq($event)"
		(addedReqID)="getReqID($event)"
	>
	</app-dns-incident-to-request>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="app-incident-request-view"
	overlayClass="modal-backdrop"
>
	<app-dns-incident-request-view
		[request_data]="request_data"
		[request_id]="incident_data_copy?.request?.request_id"
		*ngIf="showConvInciToReqView"
		(onHideConvInciToReqView)="onHideConvInciToReqView($event)"
	>
	</app-dns-incident-request-view>
</core-sidebar>

<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="incident-to-change"
	overlayClass="modal-backdrop"
>
	<app-dns-incident-to-change
		*ngIf="showConvInciToChange"
		[incident_data]="incident_data"
		(onHideConvInciToChange)="onHideConvInciToChange($event)"
		(addedChangeID)="getChangeID($event)"
		[fromEditCall]="true"
	>
	</app-dns-incident-to-change>
</core-sidebar>

<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="incident-to-change-view"
	overlayClass="modal-backdrop"
>
	<app-dns-change-view
		*ngIf="showConvInciToChangeView"
		[change_data]="change_data"
		[change_id]="incident_data?.change?.change_id"
		(onHideChangeView)="onHideConvInciToChangeView($event)"
	>
	</app-dns-change-view>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="incident-to-problem"
	overlayClass="modal-backdrop"
>
	<app-dns-incident-to-problem
		*ngIf="showConvInciToProblem"
		[incident_data]="incident_data"
		(onHideConvInciToProblem)="onHideConvInciToProblem($event)"
		(addedProblemID)="getProblemID($event)"
		[fromEditCall]="true"
	>
	</app-dns-incident-to-problem>
</core-sidebar>

<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="incident-to-problem-view"
	overlayClass="modal-backdrop"
>
	<app-common-problem-view
		*ngIf="showConvInciToProblemView"
		[problem_data]="problem_data"
		[problem_id]="incident_data?.problem?.problem_id"
		(onHideProblemView)="onHideConvInciToProblemView($event)"
	>
	</app-common-problem-view>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="app-dns-attachment"
	overlayClass="modal-backdrop"
>
	<app-dns-attachment
		*ngIf="!isHideAddAttachment"
		(hideAddAttachment)="hideAddAttachment($event)"
		[incidentId]="incidentId"
		[displayId]="incident_data_copy?.display_id"
		[summary]="incident_data_copy?.basic_info?.summary"
		(loadAttachments)="loadAttachments($event)"
	>
	</app-dns-attachment>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="edit-requester"
	overlayClass="modal-backdrop"
	>
	<app-dns-edit-requester
	*ngIf="hideProfile"
	[action]="currentEvent"
	[requesterId]="currentRequester"
	[refId]="incident_id"
	(onHideProfile)="onHideProfile($event)"
	(shownRequester)="editRequesterUpdate($event)"
	[reqFromExternal]="true"
	></app-dns-edit-requester>
</core-sidebar>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="expected-assingee-sidebar"
	overlayClass="modal-backdrop"
>
	<app-expected-assingee-sidebar
		*ngIf="showEscalation"
		[incident]="incident_data_copy"
		[escalationLevels]="escalataionData"
		(onHideEscalation)="onHideEscalation($event)"
		(getIncident)="getIncident()"
	>
	</app-expected-assingee-sidebar>
</core-sidebar>

<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="dns-incident-edit-sidebar"
	overlayClass="modal-backdrop"
>
	<dns-incident-edit-sidebar
		*ngIf="hideInlineEdit"
		#editSidebar
		[incidentId]="incidentId"
		[incident_data]="incident_data"
		[incident_data_copy]="incident_data_copy"
		[dropdown]="dropdown"
		[stateKeyIdMap]="stateKeyIdMap"
		[inci_extra_info]="inci_extra_info"
		[currentUserProfileId]="currentUserProfileId"
		(onhideInlineEdit)="onhideInlineEdit($event)"
		(reloadCommunicationData)="reloadCommunicationData()"
		(getIncident)="getIncident()"
		[permissions]="permissions"
	>
	</dns-incident-edit-sidebar>
</core-sidebar>
