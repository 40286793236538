import { ChangeDetectorRef, Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, NgForm, ValidationErrors, Validator } from "@angular/forms";
import { isEmpty } from "lodash";

@Directive({
	selector: "[minMaxValidator]",
	providers: [{ provide: NG_VALIDATORS, useExisting: MinMaxValidatorDirective, multi: true }],
})
export class MinMaxValidatorDirective implements Validator {
	@Input("dnsMinValue") minValue = 0;
	@Input("dnsMaxValue") maxValue = 0;
	@Input("isMax") isMaxComponent = false;

	constructor(private _cdRef: ChangeDetectorRef) {}

	validate(control: AbstractControl): ValidationErrors | null {
		let val = control.value;
		this._cdRef.detectChanges();
		if (!val) {
			val = this.isMaxComponent ? this.maxValue : this.minValue;
		}
		let res = {};
		if (val && typeof val === "string") {
			try {
				val = parseInt(val);
			} catch (e) {
				return { dnsInvalidInput: true };
			}
		}
		if (this.isMaxComponent) {
			this.maxValue = val;
		} else {
			this.minValue = val;
		}
		if (this.minValue > this.maxValue) {
			res["dnsMinMaxError"] = true;
			return res;
		}
		return null;
	}
}
