import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { TranslateService } from '@ngx-translate/core';
import { IncidentViewService } from 'app/servicedesk/incident-view/services/incident-view.service';
import { map } from 'rxjs';

@Component({
  selector: 'app-dns-incident-request-view',
  templateUrl: './dns-incident-request-view.component.html',
  styleUrls: ['./dns-incident-request-view.component.scss']
})
export class DnsIncidentRequestViewComponent implements OnInit {

	@Output() onHideConvInciToReqView = new EventEmitter(false);
  @Input() public request_data: any = {};
  @Input() public request_id : any = "";
  dataLoaded : boolean = false
  	location_name = ""

	constructor(
		private _coreSidebarService: CoreSidebarService,
		private _translateService: TranslateService,
		private _incidentViewService: IncidentViewService,
	) { }


	ngOnInit(): void {
		//console.log("this",this.request_data, this.request_data.hasOwnProperty("display_id"))
		if (!this.request_data.hasOwnProperty("display_id")) {
			this._incidentViewService
			.getRequest(this.request_id,{'return_main_info':true})
			.pipe(
				map(
					(response) => {
						this.request_data = response?.request_process;
						this.dataLoaded = true						
					},
					(error) => {
						//todo
					}
					)
		)
		.subscribe();
		}
		else{
			this.dataLoaded = true
		}	

		this.location_name = this?.request_data?.requester?.location?.location_name ||
				this?.request_data?.requester?.location?.city ||
				this?.request_data?.requester?.location?.state ||
				this?.request_data?.requester?.location?.country;
	}

	closeSidebar = (name): void => {
		this.onHideConvInciToReqView.emit(true);
	};

}
