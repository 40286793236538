<div class="notification-bar">
	<div class="notification-txt sm-font">
		<ng-container *ngIf="banner_type==1">
			{{
				isTrial
					? ("Message.msg_trial_banner"
					  | translate
							: {
									rem_days: rem_days
							  })
					: ("Message.msg_prod_banner"
					  | translate
							: {
									rem_days: rem_days
							  })
			}}
		</ng-container>		
		<ng-container *ngIf="banner_type==2">
			<span id="register-privacy-policy"
				[innerHtml]="banner_msg"></span>
		</ng-container>		
	</div>
	<div class="close-btn" (click)="closeNotification()">
		<i class="fa-light fa-xmark"></i>
	</div>
</div>
