import { Injectable } from "@angular/core";
import { ApiService } from "app/common/services/api/api.service";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class ChangeViewService extends ApiService {
	getChanges(payload = {}) {
		return this.get("ux/sd/change/change/", payload);
	}

	saveChange(payload: any) {
		return this.post("ux/sd/change/change/", payload);
	}

	getChange(change_id: any, payload: any = {}) {
		return this.get("ux/sd/change/change/" + change_id + "/", payload);
	}

	editChange(change_id: any, payload: any) {
		return this.put("ux/sd/change/change/" + change_id + "/", payload);
	}

	deleteChange(payload: any) {
		return this.post("ux/sd/change/change/delete_change/", payload);
	}

	getChangeColumns(payload = {}) {
		return this.get("ux/sd/change/change/page_config/", payload);
	}

	getAttachmentDetails(payload = {}) {
		return this.get("ux/sd/change/change/attachment/", payload);
	}

	getHistoryDetails(payload = {}) {
		return this.get("ux/sd/change/change/history/", payload);
	}

	addAttachments(payload: any) {
		return this.post("ux/sd/change/change/add-attachment/", payload);
	}

	savePageConfig(payload) {
		return this.post("ux/sd/change/change/page/", payload);
	}

	getOptions(payload = {}) {
		return this.get("ux/sd/change/change/options/", payload);
	}

	getExpertise(payload = {}) {
		return this.get("ux/sd/change/change/expertise/", payload);
	}

	getExpertiseLevels(payload = {}) {
		return this.get("ux/sd/change/change/team_level/", payload);
	}

	getAssigneeList(payload = {}) {
		return this.get("ux/sd/change/change/assignee/", payload);
	}

	getUserProfile(userid: Number) {
		return this.get("ux/common/user/profile/" + userid.toString() + "/");
	}

	getServicesForSelectedCatalogue(payload: any) {
		return this.get("ux/common/catalogue/servicecatalogue/service-list/", payload);
	}

	getServiceDetails(serviceId: Number) {
		return this.get("ux/common/catalogue/servicecatalogue/" + serviceId.toString() + "/");
	}

	updateChangeAssignee(payload = {}) {
		return this.put("ux/sd/change/change/update-assignee/", payload);
	}

	getFilteredChange(payload = {}) {
		return this.get("ux/sd/change/change/top_filter/", payload);
	}

	saveConversation(payload) {
		return this.post("ux/sd/change/conversation/", payload);
	}

	getMailConversations(payload) {
		return this.get("ux/sd/change/conversation/", payload);
	}

	updateChangeTag(payload = {}) {
		return this.put("ux/sd/change/change/update-tag/", payload);
	}

	loadOrganiationConfig(payload: {}) {
		return this.get("ux/org/profile/orgconfig/", payload);
	}

	saveRichTextData(payload: {}) {
		return this.post("ux/sd/change/change/save-rich-text/", payload);
	}

	getRichTextData(payload: {}) {
		return this.get("ux/sd/change/change/process-rich-text/", payload);
	}

	getRequesterList(payload: {}) {
		return this.get("ux/sd/change/change/requester_search/", payload);
	}

	processChangeRelations(payload: {}) {
		return this.get("ux/sd/change/change/process-relations/", payload);
	}

	getReqLatestChanges(payload: {}) {
		return this.get("ux/sd/change/change/latestchanges/", payload);
	}

	deleteAttachment(attachmentId: any, payload: {}) {
		return this.put("ux/sd/change/change/delete-attachment/" + attachmentId.toString() + "/", payload);
	}

	convertChangeToKB(kbId: any, payload: {}) {
		return this.put("ux/sd/change/change/change-to-kb/" + kbId.toString() + "/", payload);
	}

	convertChangeToIncident(incidentId: any, payload: {}) {
		return this.put("ux/sd/change/change/change-to-inci/" + incidentId.toString() + "/", payload);
	}

	getAllKBs(payload: {}) {
		return this.get("ux/sd/change/change/change-kbs/", payload);
	}

	getSavedFilter(payload) {
		return this.get("ux/common/filters/filters/filter/", payload);
	}

	getChangeTypeBaseWorkflowStatus(payload: any) {
		return this.get("ux/sd/change/change/workflow_status/", payload);
	}

	setEditChangeSidebar(flag) {
		localStorage.setItem("change/change-edit-flag", JSON.stringify(flag));
	}

	getChangeSidebar() {
		return JSON.parse(localStorage.getItem("change-edit-flag"));
	}

	editChangeAssignee(payload) {
		return this.post("ux/sd/change/change/update_change_assignee/", payload);
	}

	addChangeRelation(payload) {
		return this.post("ux/sd/change/change/relation/", payload);
	}

	getWorkflowOptions(payload) {
		return this.post("ux/sd/change/change/workflow_options_map/", payload);
	}

	bulkChangeResolve(payload) {
		return this.post("ux/sd/change/change/bulk-resolve/", payload);
	}

	bulkChangeAssignment(payload) {
		return this.post("ux/sd/change/change/bulk-assignment/", payload);
	}

	getFilterOptions(payload: any) {
		return this.get("ux/common/filters/filters/filter_options/", payload);
	}

	getKBProfiles(payload = {}) {
		return this.get("ux/common/kb/kb/", payload);
	}

	getRequesterImpactService(payload) {
		return this.get("ux/common/catalogue/servicecatalogue/req_services/", payload);
	}

	getImpactServiceClassification(payload) {
		return this.get("ux/common/catalogue/servicecatalogue/service_classes/", payload);
	}

	getTeamUserMap(payload) {
		return this.get("ux/sd/change/change/team_user_map/", payload);
	}

	getChangeData(pk, payload = {}) {
		return this.get(`ux/sd/change/change/change-data/${pk}/`, payload);
	}

	getApprovalStatus = (payload): Observable<any> => {
		return this.get("ux/sd/change/change/check_approval_user/", payload);
	};

	getReviewStatus = (payload): Observable<any> => {
		return this.get("ux/sd/change/change/check_review_user/", payload);
	};

	getIncidentData(pk, payload = {}) {
		return this.get(`ux/sd/inci/incident/incident-data/${pk}/`, payload);
	}

	getIncident(incident_id: any, payload = {}) {
		return this.get("ux/sd/inci/incident/" + incident_id + "/", payload);
	}

	getChangeRiskList(payload, pk) {
		return this.get(`ux/sd/change/change/risk-list/${pk}/`, payload);
	}

	getChangeRisk(pk, payload) {
		return this.get(`ux/sd/change/change/change-risk/${pk}/`, payload);
	}

	saveChangeRisk(payload) {
		return this.post("ux/sd/change/change/save-change-risk/", payload);
	}

	deleteChangeRisk(payload, pk) {
		return this.post(`ux/sd/change/change/delete-change-risk/${pk}/`, payload);
	}

	getChangeTaskList(payload, pk) {
		return this.get(`ux/sd/task/task/change-task-list/${pk}/`, payload);
	}

	getChangeTask(pk, payload) {
		return this.get(`ux/sd/task/task/change-task/${pk}/`, payload);
	}

	saveChangeTask(payload) {
		return this.post("ux/sd/task/task/save-change-task/", payload);
	}

	deleteChangeTask(payload, pk) {
		return this.post(`ux/sd/task/task/delete-change-task/${pk}/`, payload);
	}

	getChangeImpactList(payload, pk) {
		return this.get(`ux/sd/change/change/impact-list/${pk}/`, payload);
	}

	getChangeImpact(pk, payload) {
		return this.get(`ux/sd/change/change/change-impact/${pk}/`, payload);
	}

	saveChangeImpact(payload) {
		return this.post("ux/sd/change/change/save-change-impact/", payload);
	}

	deleteChangeImpact(payload, pk) {
		return this.post(`ux/sd/change/change/delete-change-impact/${pk}/`, payload);
	}
	
	getChangeCalendarList(payload) {
		return this.get("ux/sd/change/change/change-calender-list/", payload);
	}
	
	getTemplates(payload) {
		return this.get('ux/sd/template/template/', payload)
	}
	getTemplate(id) {
		return this.get('ux/sd/template/template/'+id+'/')
	}
	getTemplateAttachmentDetails(payload = {}) {
		return this.get("ux/sd/template/template/attachment/", payload);
	}
	getRequest(request_id: any, payload = {}) {
		return this.get("ux/sd/req/request_process/" + request_id + "/", payload);
	}
	getProblem(problem_id: any, payload: any ={}) {
		return this.get("ux/sd/problem/problem/" + problem_id + "/", payload);
	}
}
