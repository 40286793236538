import { animation, trigger, animateChild, group, transition, animate, style, query} from '@angular/animations';

export const repeaterAnimation = trigger('heightIn', [
    transition(':enter', [
        style({ opacity: '0', height: '0px' }),
        animate('.2s ease-out', style({ opacity: '1', height: '*' }))
    ]), transition(':leave', [
        style({ opacity: '1', height: '*' }),
        animate('.3s ease-out', style({ opacity: '0', height: '0px' }))
    ])
]);
