import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "app-history-table",
	templateUrl: "./history-table.component.html",
	styleUrls: ["./history-table.component.scss"],
})
export class HistoryTableComponent implements OnInit {
	@Input() listview: any;
	@Input() gridview: any;
	@Input() htmlcols?: any;
	index: number;
	id: number;
	readMore = false;
	limit = 150;
	disableshow: boolean = false;
	@Input("textLength") readMoreLength = 150;
	new_text = this._translateService.instant("UI.k_new");
	removed_text = this._translateService.instant("Message.msg_remove");
	@Input() commDataTotalCount = 0;
	@Input() LoadMore: boolean = false;
	page = 0;
	@Output() historypage = new EventEmitter<any>();

	constructor(private _translateService: TranslateService) {}

	ngOnInit(): void {
		// console.log("htmlcols0000000000000", this.htmlcols);
	}

	keepOrder = (a, b) => {
		return a;
	};

	showall(index, id): void {
		this.listview[index]["show"] = true;
	}

	hide(index, id): void {
		this.listview[index]["show"] = false;
	}

	getTranslatableKey = (key: string) => {
		if (key) {
			if (key.startsWith("k_")) {
				key = `UI.${key}`;
			} else if (key.startsWith("msg_")) {
				key = `Message.${key}`;
			} else if (key.startsWith("err_")) {
				key = `Error.${key}`;
			}
		}
		return key;
	};

	getTranslatedData = (key: string) => {
		let regex = "@@@";
		let key_list = key.split(regex);
		let translated_data = "";
		key_list.forEach((item) => {
			if (item.startsWith("k_")) {
				item = `UI.${item}`;
			} else if (item.startsWith("msg_")) {
				item = `Message.${item}`;
			} else if (key.startsWith("err_")) {
				item = `Error.${item}`;
			}
			if (item) {
				item = this._translateService.instant(item);
			}
			translated_data += item;
		});
		return translated_data;
	};
	loadNextComments = () => {
		this.page = this.page + 1;
		this.historypage.emit(this.page);
	};
}
