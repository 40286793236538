import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { CoreConfigService } from "@core/services/config.service";
import { CommonService } from "app/services/common.service";
import { User } from "app/auth/models";
import { AuthService } from "app/base/auth/services/auth.service";
import { getFileSizeText, validateFile, getAssetImageSrc, customSearch } from "../../../../common/utils/utils";
import { SelfServicePortalService } from "../../../../self-service-portal/services/self-service-portal.service";
import { catchError, map, reduce } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { from, throwError } from "rxjs";
import { default as _map } from "lodash-es/map";
import cloneDeep from "lodash-es/cloneDeep";
import { TreeviewItem } from "ngx-treeview";
import { TicketRequest } from "../../types/ticket_request";
import { SspConfigurationService } from "app/base/ssp-configuration/services/ssp-configuration.service";

@Component({
	selector: "app-submit-ticket-sidebar",
	templateUrl: "./submit-ticket-sidebar.component.html",
	styleUrls: ["./submit-ticket-sidebar.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class SubmitTicketSidebarComponent implements OnInit {
	@Output("hideShowDetails") hideShowDetails = new EventEmitter();
	@Input() asset_details: any;
	@Input() asset_id: any = "";
	@Input() submit_type: any = "ticket";
	public helpOptions = [
		"Something is not working",
		"Billing Questions",
		"Login Issue",
		"Data/account recovery",
		"Change/update my company's email domain",
	];
	public addIncident: any = {};
	// public ticketFormData = {
	// 	name: null,
	// 	email: null,
	// 	subject: null,
	// 	help:null,
	// 	details:null
	// };
	currentUser: User;
	public ALLOWED_TYPES: any;
	public MAX_IMAGE_SIZE: any;
	public MAX_HEIGHT: any;
	public MAX_WIDTH: any;
	incident_state_flag: any = {};
	public dropdown = {
		status: [],
		default_req_type: 3,
		// service_category_classification_map: {},
		formatted_services: [],
		incident_type: [],
		ticket_type_list: [],
	};
	public stateKeyIdMap = {};
	public disableSaveBtn: boolean = false;
	setImpactService = false;
	custom_field_config: any = {};
	custom_field_data: any = {};
	service_config: any = {};
	requesterSubscription: any;
	requester: any = {};
	service_form_details: any = {};
	service_form_data: any = {};
	is_asset_mapped = false;
	currentStatus: any;
	private classification_config: any = {};
	impacted_asset_list: any = [];
	public getImageSrc = getAssetImageSrc;
	from_ticket_add: boolean = false
	ssp_config: any = []
	show_asset_drpdwn: boolean = true

	constructor(
		private _coreConfigService: CoreConfigService,
		private _commonService: CommonService,
		private _authService: AuthService,
		private _selfservicePortalService: SelfServicePortalService,
		private _toastr: ToastrService,
		private _translateService: TranslateService,
		private _sspConfigService: SspConfigurationService
	) {
		this._authService.currentUser.subscribe((x) => (this.currentUser = x));
		this.requesterSubscription = this._coreConfigService._currentRequster.subscribe((requester) => {
			this.requester = requester;
		});
		this.addIncident["requester"] = this.currentUser?.profile;
		this.addIncident["requester_id"] = this.requester?.requester_id;
		this.addIncident["communication_type"] = "Internal Note";
		this.addIncident["files"] = [];
		// this._commonService.submitTicket.subscribe((val) => {
		// 	console.log("ascvasc",val);
		// 	if(val === 'submit-ticket-sidebar'){
		// 		this._coreSidebarService.getSidebarRegistry('submit-ticket-sidebar').toggleOpen();

		// 	}
		// })
	}

	ngOnInit(): void {
		this._coreConfigService._ticketSubmitted.next("");
		if (this.submit_type == "request") {
			this.getRequestOptions();
		} else {
			this.getOptions();
		}
		if (this.asset_details && this.asset_details?.is_enabled) {
			this.is_asset_mapped = true;
			this.asset_id = this.asset_details.ci_id;
		}
		if (this.asset_id) {
			this.getAssetDetails(this.asset_id);
		}
		if (!this.asset_id) {
			this.from_ticket_add = true
			this.getImpactedAssetList();
		}
		this.get_ssp_config()
	}

	showFileValidationError = (data) => {
		if (data) {
			// invalid file requirement
			const errors: any = [];

			if (data["is_allowed"] === false) {
				errors.push(
					this._translateService.instant("UI.k_supported_format") +
						_map(this.ALLOWED_TYPES, (val) => (val.includes(".") || val.includes("/") ? val : " ." + val))
				);
			}

			if (data["size"]) {
				errors.push(
					this._translateService.instant("Message.msg_max_size") + " " + getFileSizeText(this.MAX_IMAGE_SIZE)
				);
			}

			from(errors)
				.pipe(
					map((err) => `<li>${err}</li>`),
					reduce((acc, value) => acc.concat(value), "")
				)
				.subscribe((res) => {
					this._toastr.warning(
						`<b>${data["name"]}</b></br><ul class="pl-1">${res}</ul`,
						this._translateService.instant("Error.err_file_upload_failed"),
						{
							enableHtml: true,
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
				});
		}
	};
	getWorkFlowStatus() {
		if (this.addIncident?.submit_type == "request") {
			this._selfservicePortalService
				.getRequestServiceBaseWorkflowStatus({ service_id: this.addIncident.impact_service })
				.subscribe((response) => {
					this.dropdown["status"] = response.options;
					this.service_form_details = response?.service_form_details;
					// let pending_status = this.dropdown?.status.filter((e)=>
					// 		e['state_id'] == this.stateKeyIdMap["On Hold"]
					// 		)
					// this.pending_status = pending_status || []
					this.custom_field_config = response?.custom_filed_options;
					if (response?.custom_field_data) {
						this.custom_field_data = response?.custom_field_data;
					}
				});
		} else {
			this._selfservicePortalService
				.getServiceBaseWorkflowStatus({ service_id: this.addIncident.impact_service })
				.subscribe((response) => {
					this.dropdown["status"] = response.options;
					// let pending_status = this.dropdown?.status.filter((e)=>
					// 		e['state_id'] == this.stateKeyIdMap["On Hold"]
					// 		)
					// this.pending_status = pending_status || []
					this.custom_field_config = response?.custom_filed_options;
					if (response?.custom_field_data) {
						this.custom_field_data = response?.custom_field_data;
					}
				});
		}
	}
	serviceValueChange = (item) => {
		this.addIncident.impact_service = item?.value;
		this.addIncident.impact_service_name = item?.text;
		this.addIncident.impact_service_tree_path = item?.full_path_text;
		this.addIncident.service_classification = "";
		this.addIncident.service_classification_name = "";
		this.addIncident.service_classification_path = "";
		// if (this.dropdown?.service_category_classification_map[item?.value]?.priority) {
		// 	this.addIncident.priority_id = this.dropdown?.service_category_classification_map[item?.value]?.priority;
		// }
		this.addIncident["catalogue"] = item?.sel_tree_view_data[item?.sel_tree_view_data?.length - 1]["value"];
		this.addIncident["catalogue_name"] = item?.sel_tree_view_data[item?.sel_tree_view_data?.length - 1]["text"];
		if (item?.sel_tree_view_data && item?.sel_tree_view_data?.length > 2) {
			this.addIncident["category"] = item?.sel_tree_view_data[1]["value"];
			this.addIncident["category_name"] = item?.sel_tree_view_data[1]["text"];
		} else {
			this.addIncident["category"] = "";
			this.addIncident["category_name"] = "";
		}
		this.getWorkFlowStatus();
		this.getClassificationdata();
	};

	getClassificationdata() {
		this._selfservicePortalService
			.getImpactServiceClassification({ service_id: this.addIncident?.impact_service, format_to_treeview: true })
			.subscribe(
				(res) => {
					this.dropdown["formatted_classification_data"] = [];
					this.addIncident.priority_id = res?.priority;
					res?.classification?.forEach((ele) => {
						const data = new TreeviewItem(ele);
						this.dropdown["formatted_classification_data"].push(data);
					});
				},
				(error) => {
					//console.log("error_while_fetching impact service", error);
				}
			);
	}

	getOptions() {
		this._selfservicePortalService.getOptions({ for_add_incident: true, for_ssp_add: true }).subscribe(
			(response) => {
				if (response) {
					this.incident_state_flag = response["incident_state_flag"];
					this.dropdown["state"] = response?.state;
					this.dropdown["status"] = response?.status;
					this.dropdown["incident_type"] = response?.incident_type;
					this.custom_field_config = response?.custom_filed_options;
					this.getWorkFlowStatus();
					this.stateKeyIdMap = response["state_key_id_map"];
					let imageDefaults = response["image_upload_defaults"];
					this.ALLOWED_TYPES = imageDefaults["allowed_types"];
					this.MAX_IMAGE_SIZE = imageDefaults["max_size"];
					this.MAX_HEIGHT = imageDefaults["max_height"];
					this.MAX_WIDTH = imageDefaults["max_width"];
					this.addIncident["priority_id"] = response["INCIDENT_DEFAULT_PRIORITY"];
					this.dropdown["ticket_type_list"] = response?.ticket_type_list;
					if (response?.ticket_type_list?.length > 0) {
						this.addIncident["submit_type"] = response?.ticket_type_list[0]?.id;
					}
					let def_priority_id = response["INCIDENT_DEFAULT_PRIORITY"];
					const data = response.priority.filter((item) => item?.id === def_priority_id);
					if (data?.length > 0) {
						this.addIncident.priority = data[0];
					}
				}
			},
			(error) => {
				//todo
			}
		);
		if (this.setImpactService == false) {
			this.service_config = {};
			this.dropdown["formatted_services"] = [];
			this.addIncident.impact_service = "";
			this.addIncident.impact_service_name = "";
			this.addIncident.impact_service_tree_path = "";
			this.addIncident.service_classification = "";
			this.addIncident.service_classification_name = "";
			this.addIncident.service_classification_path = "";
			this.custom_field_config = {};
			this.custom_field_data = {};
		}
	}

	getRequestOptions() {
		this.disableSaveBtn = true;
		this._selfservicePortalService.getRequestOptions({ for_add_request: true, for_ssp_add: true }).subscribe(
			(response) => {
				if (response) {
					this.incident_state_flag = response["request_state_flag"];
					this.dropdown["state"] = response?.state;
					this.dropdown["status"] = response?.status;
					this.dropdown["request_type"] = response?.request_type;
					this.custom_field_config = response?.custom_filed_options;
					this.getWorkFlowStatus();
					this.stateKeyIdMap = response["state_key_id_map"];
					let imageDefaults = response["image_upload_defaults"];
					this.ALLOWED_TYPES = imageDefaults["allowed_types"];
					this.MAX_IMAGE_SIZE = imageDefaults["max_size"];
					this.MAX_HEIGHT = imageDefaults["max_height"];
					this.MAX_WIDTH = imageDefaults["max_width"];
					this.addIncident["priority_id"] = response["REQUEST_DEFAULT_PRIORITY"];
					this.dropdown["ticket_type_list"] = response?.ticket_type_list;
					this.dropdown["default_req_type"] = response?.REQUEST_DEFAULT_REQ_TYPE;
					if (response?.ticket_type_list?.length > 1 && !this.addIncident?.submit_type) {
						this.addIncident["submit_type"] = response?.ticket_type_list[1]?.id;
					}
					let def_priority_id = response["REQUEST_DEFAULT_PRIORITY"];
					const data = response.priority.filter((item) => item?.id === def_priority_id);
					if (data?.length > 0) {
						this.addIncident.priority = data[0];
					}
					this.disableSaveBtn = false;
					this.onImpactServiceClick(undefined, "impact_service");
				}
			},
			(error) => {
				//todo
				this.disableSaveBtn = false;
			}
		);
	}

	toggleSidebar(): void {
		this.hideShowDetails.emit();
		// this._commonService.submitTicket.next('')
		// this._coreSidebarService.getSidebarRegistry('submit-ticket-sidebar').toggleOpen();
	}

	fileUpload = function (evt) {
		var files = evt.target.files;
		var new_file_len = evt.target.files.length;
		var added_file_len = this.addIncident?.files?.length;
		for (var i = 0; i < new_file_len; i++) {
			var file = files[i];
			const data = validateFile(file, this.ALLOWED_TYPES);
			if (data && data["is_valid"]) {
				var pos = added_file_len + i;
				var reader = new FileReader();
				reader.onload = (function (filesList, file, pos) {
					return function (e) {
						var data = e.target.result;
						filesList[pos] = {
							file_name: file.name,
							file_type: file.type,
							file_size: file.size,
							file_data: data,
							file_date: new Date(),
						};
					};
				})(this.addIncident?.files, file, pos);
				reader.readAsDataURL(file);
			} else if (data && !data["is_valid"]) {
				this.showFileValidationError(data);
			}
		}
		evt.srcElement.value = "";
	};
	removeAttachedFile = function (index, filesList) {
		filesList.splice(index, 1);
		// this.emailAttachments.splice(index, 1);
	};
	linkifyEmail = (emails) => {
		let result = "";
		if (emails) {
			let emailsArray = emails.split(";");
			for (let email of emailsArray) {
				result += `<a href="mailto:${email}">${email};</a>`;
			}
		}
		return result;
	};

	onSubmit(data) {
		if (data.form.valid === true) {
			this.disableSaveBtn = true;
			this.addIncident["inci_add_flag"] = this.incident_state_flag["New"];
			let option_status = this.dropdown?.status.filter((e) => e["state_id"] == this.stateKeyIdMap["Open"]);
			this.addIncident["status_id"] = option_status?.[0]["id"];
			this.addIncident["state_id"] = option_status?.[0]["state_id"];
			this.addIncident["workflow"] = option_status?.[0]["workflow_id"];
			this.addIncident["flow_id"] = option_status?.[0]?.["guid"];
			this.addIncident["workflow_id"] = option_status?.[0]["workflow_id"];
			this.currentStatus = option_status?.[0];
			this.addIncident["custom_field_data"] = this.custom_field_data;
			this.addIncident["attached_files"] = this.addIncident.files;
			if (this.addIncident?.submit_type == "request") {
				this.addIncident["request_type_id"] = this.dropdown?.default_req_type;
				this.addIncident["service_form_data"] = this.service_form_data;
				this.addIncident["is_ssp_login"] = true
				this._selfservicePortalService
					.saveRequest(this.addIncident)
					.pipe(
						map((response: any) => {
							if (response.status == "success") {
								this._coreConfigService._ticketSubmitted.next("ticket-submitted");
								this._toastr.success(
									this._translateService.instant(response["message"], { id: response?.id }),
									this._translateService.instant("Message.msg_save_success"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
								const ticketPayload = Object.assign({}, this.addIncident);
								ticketPayload["status"] = this.currentStatus;
								const ticketRequest: TicketRequest = new TicketRequest(ticketPayload);
								this.hideShowDetails.emit({
									request_data: ticketRequest,
									request_payload: this.addIncident,
									request_response: response,
								});
							} else {
								this._toastr.error(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Error.err_save_failed"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
								this.disableSaveBtn = false;
							}
							// Success
						}),
						catchError((error) => {
							this.disableSaveBtn = false;
							return throwError(error.statusText);
						})
					)
					.subscribe();
			} else {
				this.addIncident["incident_type"] = this.dropdown?.incident_type[0];
				this.addIncident["is_ssp_login"] = true
				this._selfservicePortalService
					.saveIncident(this.addIncident)
					.pipe(
						map((response: any) => {
							if (response.status == "success") {
								this._coreConfigService._ticketSubmitted.next("ticket-submitted");
								this._toastr.success(
									this._translateService.instant(response["message"], { id: response?.id }),
									this._translateService.instant("Message.msg_save_success"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
								const ticketPayload = Object.assign({}, this.addIncident);
								ticketPayload["status"] = this.currentStatus;
								const ticketRequest: TicketRequest = new TicketRequest(ticketPayload);
								this.hideShowDetails.emit({
									ticket_request: ticketRequest,
									ticket_payload: this.addIncident,
									ticket_response: response,
								});
							} else {
								this._toastr.error(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Error.err_save_failed"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
								this.disableSaveBtn = false;
							}
							// Success
						}),
						catchError((error) => {
							this.disableSaveBtn = false;
							return throwError(error.statusText);
						})
					)
					.subscribe();
			}
		} else {
			this.disableSaveBtn = false;
		}
		// this._commonService.submitTicket.next('')
		// this._coreSidebarService.getSidebarRegistry('submit-ticket-sidebar').toggleOpen();
	}
	onImpactServiceClick = (event, key) => {
		if (event) {
			event.preventDefault();
		}

		if (key == "impact_service") {
			if (!this.service_config) {
				this.service_config = {};
			}
			this.service_config["is_loading"] = true;
			this.dropdown["formatted_services"] = [];
			let module_name = "incident";
			if (this.addIncident?.submit_type == "request") {
				module_name = "request_process";
			}
			this._selfservicePortalService
				.getRequesterImpactService({
					requester_id: this.addIncident?.requester_id,
					is_published: true,
					bind_classification: false,
					module_name_list: JSON.stringify([module_name]),
				})
				.subscribe(
					(res) => {
						this.service_config["is_editable"] = true;
						this.dropdown["formatted_services"] = [];
						// this.addIncident.priority_id = res?.priority
						res?.forEach((ele) => {
							const data = new TreeviewItem(ele);
							this.dropdown["formatted_services"].push(data);
						});
						this.service_config["is_loading"] = false;
					},
					(error) => {
						//console.log("error_while_fetching impact service", error);
						this.service_config["is_loading"] = false;
					}
				);
		} else if (key == "service_classification") {
			if (!this.classification_config) {
				this.classification_config = {};
			}
			this.classification_config["is_loading"] = true;
			this.dropdown["formatted_classification_data"] = [];
			if (this.addIncident?.impact_service) {
				this._selfservicePortalService
					.getImpactServiceClassification({
						service_id: this.addIncident?.impact_service,
						format_to_treeview: true,
					})
					.subscribe(
						(res) => {
							this.classification_config["is_editable"] = true;
							this.dropdown["formatted_classification_data"] = [];
							this.addIncident.priority_id = res?.priority;
							res?.classification?.forEach((ele) => {
								const data = new TreeviewItem(ele);
								this.dropdown["formatted_classification_data"].push(data);
							});
							this.classification_config["is_loading"] = false;
						},
						(error) => {
							//console.log("error_while_fetching impact service", error);
							this.classification_config["is_loading"] = false;
						}
					);
			} else {
				this._toastr.error(this._translateService.instant("Error.err_select_i_need_help"));
				this.classification_config["is_loading"] = false;
			}
		}
	};

	onRequestTypeChange($event) {
		if (this.addIncident?.submit_type == "request") {
			this.getRequestOptions();
		} else {
			this.getOptions();
		}
	}

	info_action = () => {
		if (this.addIncident?.asset?.ci_id) {
			window.open("/self-service/assets-view/" + this.addIncident?.asset?.ci_id, "_blank");
		}
	};

	getAssetDetails = (asset_id) => {
		this._selfservicePortalService.getAssetDetails(asset_id).subscribe((response) => {
			this.asset_details = response;
			if (this.asset_details?.is_enabled) {
				this.is_asset_mapped = true;
				this.addIncident["asset"] = {
					asset_id: this.asset_details.asset_id,
					ci_id: this.asset_details.ci_id,
					ci_name: this.asset_details.ci_name,
					ip_address: this.asset_details.ip_address,
					parent_ci_id: this.asset_details.parent_ci_id,
					hostname: this.asset_details.hostname,
				};
			}
		});
	};

	classificationValueChange = (item) => {
		this.addIncident.service_classification = item?.value;
		this.addIncident.service_classification_name = item?.text;
		this.addIncident.service_classification_path = item?.full_path_text;
	};

	getImpactedAssetList = () => {
		const payload = {
			ssp_login: true,
		}
		this._selfservicePortalService.getImpactedAssetList(payload).subscribe((response) => {
			if(response) {
				this.impacted_asset_list = response
			}
		})
	}
	filterImpactedAssetList = (term: string, item: any) => {
		return this.customAssetSearch(term, item);
	}

	customAssetSearch (term: string, item: any) {
		term = term.toLowerCase();
		if (item.customized_search_fields[0]?.is_custom_field) {
			var primaryKey = item.customized_search_fields[0]?.custom_field_id;
			var primaryMapKey: any = "custom_fields"
		} else {
			var primaryKey = item.customized_search_fields[0]?.key;
			var primaryMapKey = item.customized_search_fields[0]?.map_key
		}

		if (item.customized_search_fields[1]?.is_custom_field) {
			var secondaryKey = item.customized_search_fields[1]?.custom_field_id;
			var secondaryMapKey : any = "custom_fields"
		} else {
			var secondaryKey = item.customized_search_fields[1]?.key; 
			var secondaryMapKey = item.customized_search_fields[1]?.map_key
		}

		if (item.customized_search_fields[2]?.is_custom_field) {
			var thirdKey = item.customized_search_fields[2]?.custom_field_id;
			var thirdMapKey: any = "custom_fields"
		} else {
			var thirdKey = item.customized_search_fields[2]?.key;
			var thirdMapKey = item.customized_search_fields[2]?.map_key
		}
		// Creating and array of space saperated term and removinf the empty values using filter
		const splitTerm = term.split(" ").filter((t) => t);
		let isWordThere = [];
		// Pushing True/False if match is found
		splitTerm.forEach((arr_term) => {
			let searchStatus = ""
			// check if key exist
			if (primaryKey){
				if (primaryMapKey) {
					searchStatus = item[primaryMapKey][primaryKey]?.toLowerCase()|| "";
				}
				else {
					searchStatus = item[primaryKey]?.toLowerCase()|| "";
				}
			}
			let searchState = ""
			if (secondaryKey){
				if (secondaryMapKey) {
					searchState = item[secondaryMapKey][secondaryKey]?.toLowerCase() || "";
				}
				else {
					searchState = item[secondaryKey]?.toLowerCase() || "";
				}
			}
			let searchKey = ""
			if (thirdKey){
				if (thirdMapKey) {
					searchKey = item[thirdMapKey][thirdKey]?.toLowerCase() || "";
				}
				else {
					searchKey = item[thirdKey]?.toLowerCase() || "";
				}
			}
			isWordThere.push(searchStatus.indexOf(arr_term) !== -1 || searchState.indexOf(arr_term) !== -1 || searchKey.indexOf(arr_term) !== -1);
		});
		const all_words = (this_word) => this_word;
		// Every method will return true if all values are true in isWordThere.
		return isWordThere.every(all_words);
	};

	get_ssp_config = () =>  {
		this._sspConfigService
			.getSSPConfiguration().subscribe((response) => {
				this.ssp_config = response
				this.ssp_config.forEach(({ module_key, is_enabled }) => {
					if (module_key === "assets" && !is_enabled) {
						this.show_asset_drpdwn = false;
					}
				});
			})
	}
}
