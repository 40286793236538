import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.scss']
})
export class SummaryCardComponent implements OnInit {
  @Input() summaryInput: any;
  @Input() priorityList: any;
	@Output() onPriorityChange: EventEmitter<any> = new EventEmitter<any>();

  public priorityOption = []

  constructor() { }

  // priorityOption = [
  //   {
  //     id: 1,
  //     priority: 1,
  //     priorityName: 'Critical',
  //     priorityColor: 'danger',
  //     priorityIcon: 'fa-angles-up',
  //     isActive: false
  //   },
  //   {
  //     id: 2,
  //     priority: 2,
  //     priorityName: 'High',
  //     priorityColor: 'warning',
  //     priorityIcon: 'fa-angle-up',
  //     isActive: false
  //   },
  //   {
  //     id: 3,
  //     priority: 3,
  //     priorityName: 'Minor',
  //     priorityColor: 'info',
  //     priorityIcon: 'fa-dash',
  //     isActive: false
  //   },
  //   {
  //     id: 4,
  //     priority: 4,
  //     priorityName: 'Low',
  //     priorityColor: 'success',
  //     priorityIcon: 'fa-circle',
  //     isActive: false
  //   },
  // ];

  ngOnInit(): void {
     this.priorityOption = this.priorityList
     if(this.summaryInput && this.summaryInput.data) {
      this.priorityOption?.forEach(e => {
        e["isActive"] = false
        if (e.id === this.summaryInput.data?.priority) {
          e["isActive"] = true
        }
      });
      // this.summaryInput.data["isActive"] = true;
      // this.priorityOption.unshift(this.summaryInput.data)
    }
  }
  priorityEvent = (item) => {
		this.priorityOption?.forEach(e => {
			e["isActive"] = false
			if (e.id === item.id) {
				e["isActive"] = true
			}
		});
    this.onPriorityChange.emit(item)
  }
}
