<div class="custom-card d-flex">
  <div class="vertical-box">
    <button class="btn btn-flat-secondary btn-icon" rippleEffect>
      <i class="fa-thin fa-grip-dots-vertical"></i>
    </button>
  </div>
  <div class="w-100">
    <div class="d-flex border-bottom w-100 align-items-center escalation-head">
        <div class="escalation-left-sec">
            <div class="tooltip-discription" ngbTooltip="'{{ 'UI.k_trigger_escalation_after' | translate}}'" placement="right">
                <h6 class="mb-0 mr-25" style="font-weight: normal !important">
                    {{ 'UI.k_trigger_escalation_after' | translate}}
                </h6>
                <span class="info-icon">
                  <svg
                    width="4"
                    height="10"
                    viewBox="0 0 4 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.00532 0.625C3.21383 0.625 3.39007 0.688788 3.53404 0.816365C3.67801 0.943942 3.75 1.10011 3.75 1.28488C3.75 1.46525 3.67553 1.62142 3.5266 1.75339C3.38262 1.88097 3.20887 1.94476 3.00532 1.94476C2.80177 1.94476 2.62553 1.88097 2.4766 1.75339C2.33262 1.62142 2.26064 1.46525 2.26064 1.28488C2.26064 1.10011 2.33262 0.943942 2.4766 0.816365C2.62057 0.688788 2.79681 0.625 3.00532 0.625ZM3.10213 3.24472L1.62766 7.87048C1.52837 8.18282 1.47872 8.36978 1.47872 8.43137C1.47872 8.50176 1.50106 8.55895 1.54574 8.60294C1.59539 8.64693 1.65248 8.66893 1.71702 8.66893C1.79149 8.66893 1.88085 8.63374 1.98511 8.56335C2.26809 8.36538 2.55355 8.08384 2.84149 7.7187L3.10213 7.87048C2.76454 8.32799 2.36738 8.71292 1.91064 9.02526C1.57305 9.25842 1.25035 9.375 0.942553 9.375C0.739007 9.375 0.572695 9.32221 0.443617 9.21663C0.314539 9.10665 0.25 8.97027 0.25 8.8075C0.25 8.64473 0.312057 8.37418 0.43617 7.99585L1.40426 5.03959C1.56312 4.55568 1.64255 4.25214 1.64255 4.12896C1.64255 4.03218 1.60284 3.95299 1.5234 3.8914C1.44894 3.82981 1.34468 3.79902 1.21064 3.79902C1.10142 3.79902 0.875532 3.82322 0.532979 3.87161V3.61425L3.10213 3.24472Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>
        
              <div class="form-group d-flex mb-0 ml-1">
                <input
                  style="width: 50px"
                  type="text"
                  class="form-control"
                  id="txtExcludedTime"
                  name="txtExcludedTime"
                  placeholder="00"
                  [(ngModel)]="this.escalationConfig.ack_time_limit"
                  required
                  #TDExcludeTimeRef="ngModel"
                />
                <div class="time-sec">{{ "UI.k_mins" | translate }}</div>
              </div>
        </div>
        <div class="escalation-right-sec d-flex align-items-center">
            <div class="form-group ml-1 custom-control custom-control-primary custom-switch mb-0">
                <input type="checkbox"
                       class="custom-control-input"
                       id="deactivate_escalation"
                       name="deactivate_escalation"
                       [(ngModel)]="this.escalationConfig.esc_after_ack"
                        />
                <label class="custom-control-label"
                       style="font-size: 14px; font-weight: bold"
                       for="escalation"></label>
            </div>

            <div class="tooltip-discription" ngbTooltip="{{ 'UI.k_deactivate_if_acknowledged' | translate}}" placement="left">
                <h6 class="mb-0 mr-25" style="font-weight: normal !important;white-space: nowrap;">
                    {{ 'UI.k_deactivate_if_acknowledged' | translate}}
                </h6>
                <span class="info-icon">
                    <svg width="4"
                         height="10"
                         viewBox="0 0 4 10"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.00532 0.625C3.21383 0.625 3.39007 0.688788 3.53404 0.816365C3.67801 0.943942 3.75 1.10011 3.75 1.28488C3.75 1.46525 3.67553 1.62142 3.5266 1.75339C3.38262 1.88097 3.20887 1.94476 3.00532 1.94476C2.80177 1.94476 2.62553 1.88097 2.4766 1.75339C2.33262 1.62142 2.26064 1.46525 2.26064 1.28488C2.26064 1.10011 2.33262 0.943942 2.4766 0.816365C2.62057 0.688788 2.79681 0.625 3.00532 0.625ZM3.10213 3.24472L1.62766 7.87048C1.52837 8.18282 1.47872 8.36978 1.47872 8.43137C1.47872 8.50176 1.50106 8.55895 1.54574 8.60294C1.59539 8.64693 1.65248 8.66893 1.71702 8.66893C1.79149 8.66893 1.88085 8.63374 1.98511 8.56335C2.26809 8.36538 2.55355 8.08384 2.84149 7.7187L3.10213 7.87048C2.76454 8.32799 2.36738 8.71292 1.91064 9.02526C1.57305 9.25842 1.25035 9.375 0.942553 9.375C0.739007 9.375 0.572695 9.32221 0.443617 9.21663C0.314539 9.10665 0.25 8.97027 0.25 8.8075C0.25 8.64473 0.312057 8.37418 0.43617 7.99585L1.40426 5.03959C1.56312 4.55568 1.64255 4.25214 1.64255 4.12896C1.64255 4.03218 1.60284 3.95299 1.5234 3.8914C1.44894 3.82981 1.34468 3.79902 1.21064 3.79902C1.10142 3.79902 0.875532 3.82322 0.532979 3.87161V3.61425L3.10213 3.24472Z"
                              fill="white" />
                    </svg>
                </span>
            </div>
        </div>


    </div>
    <div class="m-1">
      <div class="btn-group" role="group" aria-label="Basic example">
        <ng-container *ngFor="let item of escalationConfig.notifyMethods">
          <button
            (click)="notifyButtonEventFromEscalation(item)"
            class="btn btn-outline-tertiary"
            rippleEffect
            id="{{ item.id }}"
            name="{{ item.id }}"
          >
            <span class="align-middle d-sm-inline-block d-none">{{
              item.name
            }}</span>
          </button>
        </ng-container>
      </div>
      <div *ngIf="this.escalationConfig.emailFeature" class="my-1">
        <app-email-feature></app-email-feature>
      </div>
    </div>
  </div>

</div>
<button type="button" class="btn btn-lg btn-flat-primary mt-1" rippleEffect>
    <i class="fa-light fa-plus"></i>{{ 'UI.k_add_escalation' | translate}}
</button>
