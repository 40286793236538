import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, NgForm, ValidationErrors, Validator} from '@angular/forms';
import {isEmpty} from 'lodash';

@Directive({
	selector: '[appQuillValidator]',
	providers: [{provide: NG_VALIDATORS, useExisting: QuillValidatorDirective, multi: true}]
})
export class QuillValidatorDirective implements Validator {
	@Input('appQuillValidator') element: any;
	@Input('appQuillForm') formGroup: NgForm;
	allowedTags = ['<img', '<iframe', '<a'];

	validate = (control: AbstractControl): ValidationErrors | null => {
		let builtin_validataion = this.element.validate();
		if (builtin_validataion?.requiredError) {
			if (builtin_validataion.requiredError?.empty) {
				this.allowedTags.every((tag, index) => {
					if (control.value?.includes(tag)) {
						builtin_validataion = null;
						return false;
					}
					return true;
				});
			}
		}
		if (!isEmpty(builtin_validataion)) {
			this.element.invalid = true;
			this.element.valid = true;
			builtin_validataion.invalid = true;
			this.element.errors = builtin_validataion;
			setTimeout(() => {
				this.formGroup.form.setErrors(builtin_validataion);
			}, 300);
		} else {
			builtin_validataion = null;
			this.element.errors = builtin_validataion;
			setTimeout(() => {
				this.formGroup.form.setErrors(null);
			}, 300);
		}
		return builtin_validataion;
	}
}
