import { Component, EventEmitter, OnInit, Output, Input, ViewEncapsulation} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { TreeDataService } from 'app/common/dns/components/single-tree-dropdown/tree-data.service';
import { IncidentViewService } from 'app/servicedesk/incident-view/services/incident-view.service';

@Component({
  selector: 'app-expected-assingee-sidebar',
  templateUrl: './expected-assingee-sidebar.component.html',
  styleUrls: ['./expected-assingee-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExpectedAssingeeSidebarComponent implements OnInit {
	// @Input() escalationLevels:any;
  @Input() incident: any = {};
	@Output("onHideEscalation") onHideEscalation = new EventEmitter<any>();
  expacted_assigneeList :any = [];
  level_map: any;
  public escalationLevels: any = []

  constructor(private _coreSidebarService: CoreSidebarService, 
    public treeSvc: TreeDataService,
    private _incidentViewService: IncidentViewService,
  ) {
    this.level_map = {
      3: {
        'label':'Passed',
        'badge':'badge-light-info'
      },
      1: {
        'label':'Current',
        'badge':'badge-light-success'
      },
      2: {
        'label':'Upcoming',
        'badge':'badge-light-primary'
      },
      4: {
        'label':'Skip',
        'badge':'badge-light-primary'
      },
    }
    // this._translateService.instant("UI.k_close_incident");

    this.expacted_assigneeList = [
      {id:'1', full_name:"Ryan Vetrovs", level:"Level 2",profile_image:"assets/images/asset/image.png",phone:"+91 8976542894", tell:"0484 2601473", email:"roshanashraf165131@gmail.com" },
      {id:'2', full_name:"Lincoln Stanton", level:"Level 3",profile_image:"assets/images/asset/image.png",phone:"+91 8976542894", email:"roshanashraf165131@gmail.com" },
      {id:'3', full_name:"Jordyn Korsgaard", level:"Level 4",profile_image:"assets/images/asset/image.png",phone:"+91 8976542894"},
      {id:'4', full_name:"Alena Geidt", level:"Level 5",profile_image:"assets/images/asset/image.png",phone:"+91 8976542894", tell:"0484 2601473", email:"roshanashraf165131@gmail.com" }
    ]
  }

  ngOnInit(): void {
    this.getEscalationDetails(this.incident.incident_id)
  }

  getEscalationDetails(id) {
		this._incidentViewService.getEscalationData(id, {}).subscribe((response) => {
      this.escalationLevels = response
		});
  }

  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
    this.onHideEscalation.emit('hide');
  }

  onSubmit() {
    this.onHideEscalation.emit('hide');
  }
}
