import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class TreeCrudViewService {
	// treeConfig = [
	//   {
	//     id:'1', label: 'Classification 1', children: [
	//       {
	//         id:'1.1', label: 'Classification 1.1'
	//       },
	//       {
	//         id:'1.2', label: 'Classification 1.2', children : [
	//           {
	//             id:'1.2.1' , label: 'Classification 1.2.1'
	//           }
	//         ]
	//       }
	//     ]
	//   },
	//   {
	//     id:'2', label: 'Classification 2', children: [
	//       {
	//         id:'2.1', label: 'Classification 2.1'
	//       },
	//       {
	//         id:'2.2', label: 'Classification 2.2'
	//       }
	//     ]
	//   },
	//   {
	//     id:'3', label: 'Classification 3', children: [
	//       {
	//         id:'3.1', label: 'Classification 3.1'
	//       },
	//       {
	//         id:'3.2', label: 'Classification 3.2'
	//       }
	//     ]
	//   },
	//   {
	//     id:'4', label: 'Classification 4', children: [
	//       {
	//         id:'4.1', label: 'Classification 4.1'
	//       },
	//       {
	//         id:'4.2', label: 'Classification 4.2'
	//       }
	//     ]
	//   },
	//   {
	//     id:'5',label: 'Classification 5'
	//   }
	// ]
	public treeConfig = [];
	public _treeDataBehaviourSubject = new Subject<[]>();
	public _treeEventSubject = new Subject<any>();
	newTreeConfig$ = this._treeDataBehaviourSubject.asObservable();
	eventObservable = this._treeEventSubject.asObservable();

	constructor() {}

	setTreeConfig(data) {
		this.treeConfig = data;
	}

	setUpdatedData(data) {
		this._treeDataBehaviourSubject.next(data);
	}

	getTreeCOnfig() {
		return this.treeConfig;
	}
}
