<div class="modal-body flex-grow-1 align-items-center">
	<div class="row">
		<div class="col-md-11 col-xl-11 col-lg-11">
			<ul ngbNav #nav="ngbNav" class="nav-tabs">
				<li ngbNavItem>
					<a ngbNavLink href="#collapseExample">Favourites</a>
					<ng-template ngbNavContent> </ng-template>
				</li>
				<li ngbNavItem>
					<a ngbNavLink>ITSM</a>
					<ng-template ngbNavContent>
						<p>
							Muffin cupcake candy chocolate cake gummi bears fruitcake donut
							dessert pie. Wafer toffee bonbon dragée. Jujubes cotton candy
							gummies chupa chups. Sweet fruitcake cheesecake biscuit cotton
							candy. Cookie powder marshmallow donut. Candy cookie sweet roll
							bear claw sweet roll. Cake tiramisu cotton candy gingerbread
							cheesecake toffee cake. Cookie liquorice dessert candy canes
							jelly. Sweet chocolate muffin fruitcake gummies jujubes pie
							lollipop. Brownie marshmallow caramels gingerbread jelly beans
							chocolate bar oat cake wafer. Chocolate bar danish icing sweet
							apple pie jelly-o carrot cake cookie cake.
						</p>
					</ng-template>
				</li>
				<li ngbNavItem>
					<a ngbNavLink>Assets</a>
					<ng-template ngbNavContent>
						<p>
							Chocolate croissant cupcake croissant jelly donut. Cheesecake
							toffee apple pie chocolate bar biscuit tart croissant. Lemon drops
							danish cookie. Oat cake macaroon icing tart lollipop cookie sweet
							bear claw. Carrot cake dragée chocolate. Lemon drops ice cream
							wafer gummies dragée. Chocolate bar liquorice cheesecake cookie
							chupa chups marshmallow oat cake biscuit. Dessert toffee fruitcake
							ice cream powder tootsie roll cake.
						</p>
					</ng-template>
				</li>
				<li ngbNavItem>
					<a ngbNavLink>Orders</a>
					<ng-template ngbNavContent>
						<p></p>
					</ng-template>
				</li>
				<li ngbNavItem>
					<a ngbNavLink>Monitoring</a>
					<ng-template ngbNavContent>
						<p></p>
					</ng-template>
				</li>
				<li ngbNavItem class="d-flex align-items-center">
					<div ngbDropdown class="btn-group dropup dropdown-icon-wrapper">
						<a
							id="drdMenu"
							ngbDropdownToggle
							type="button"
							id="dropdownMenuButton"
						>
							More
							<i class="fa-light fa-ellipsis-vertical dropdown-icon"></i>
						</a>
						<div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
							<a ngbDropdownItem href="javascript:void(0)">Action</a>
							<a ngbDropdownItem href="javascript:void(0)">Another Action </a>
							<a ngbDropdownItem href="javascript:void(0)"
								>Something Else Here</a
							>
							<div class="dropdown-divider"></div>
							<a ngbDropdownItem href="javascript:void(0)">Separated link</a>
						</div>
					</div>
				</li>
			</ul>
		</div>

		<div class="col-md-1 col-xl-1 col-lg-1">
			<button
				type="button"
				class="close"
				aria-label="Close"
				(click)="activeModal.dismiss('Cross click')"
			>
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
	</div>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
	<div class="row" *ngFor="let favourite of favourites">
		<div class="col-1">
			<div class="d-flex align-items-center">
				<div class="avatar bg-primary">
					<div class="avatar-content">
						<span
							[data-feather]="favourite.icon"
							[class]="'avatar-icon'"
							width="32"
						></span>
					</div>
				</div>
			</div>
		</div>
		<div class="col-5">
			<div class="font-weight-bolder">{{ favourite.name }}</div>
			<div class="font-small-2 text-muted">{{ favourite.description }}</div>
		</div>
		<br />
	</div>
	<!-- <div class="row">
		<div class="col-md-1">
			<div class="d-flex align-items-center">
				<div class="avatar bg-primary">
					<div class="avatar-content">
						<span [data-feather]="'airplay'" [class]="'avatar-icon'"></span>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-5">
			<div class="font-weight-bolder">Device Ageing</div>
			<div class="font-small-2 text-muted">
				Muffin cupcake candy chocolate <br />
				cake gummi bears fruitcake
			</div>
		</div>
	</div>
	<br />
	<div class="row">
		<div class="col-md-1">
			<div class="d-flex align-items-center">
				<div class="avatar bg-primary">
					<div class="avatar-content">
						<span
							[data-feather]="'sun'"
							[class]="'avatar-icon'"
							width="32"
						></span>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-5">
			<div class="font-weight-bolder">Network Summary</div>
			<div class="font-small-2 text-muted">
				Muffin cupcake candy chocolate <br />
				cake gummi bears fruitcake
			</div>
		</div>
	</div>
	<br />

	<div class="row">
		<div class="col-md-1">
			<div class="d-flex align-items-center">
				<div class="avatar bg-primary">
					<div class="avatar-content">
						<span
							[data-feather]="'printer'"
							[class]="'avatar-icon'"
							width="32"
						></span>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-5">
			<div class="font-weight-bolder">Active Asset Expenses</div>
			<div class="font-small-2 text-muted">
				Muffin cupcake candy chocolate <br />
				cake gummi bears fruitcake
			</div>
		</div>
	</div>
	<br />

	<div class="row">
		<div class="col-md-1">
			<div class="d-flex align-items-center">
				<div class="avatar bg-primary">
					<div class="avatar-content">
						<span
							[data-feather]="'bell'"
							[class]="'avatar-icon'"
							width="32"
						></span>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-5">
			<div class="font-weight-bolder">Alaram Prediction</div>
			<div class="font-small-2 text-muted">
				Muffin cupcake candy chocolate <br />
				cake gummi bears fruitcake
			</div>
		</div>
	</div>
	<br />

	<div class="row">
		<div class="col-md-1">
			<div class="d-flex align-items-center">
				<div class="avatar bg-primary">
					<div class="avatar-content">
						<span
							[data-feather]="'folder'"
							[class]="'avatar-icon'"
							width="32"
						></span>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-5">
			<div class="font-weight-bolder">Last Day Incident Report</div>
			<div class="font-small-2 text-muted">
				Muffin cupcake candy chocolate <br />
				cake gummi bears fruitcake
			</div>
		</div>
	</div>
	<br />

	<div class="row">
		<div class="col-md-1">
			<div class="d-flex align-items-center">
				<div class="avatar bg-primary">
					<div class="avatar-content">
						<span
							[data-feather]="'message-square'"
							[class]="'avatar-icon'"
							width="32"
						></span>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-5">
			<div class="font-weight-bolder">Messages</div>
			<div class="font-small-2 text-muted">
				Muffin cupcake candy chocolate <br />cake gummi bears fruitcake
			</div>
		</div>
	</div>
	<br />

	<div class="row">
		<div class="col-md-1">
			<div class="d-flex align-items-center">
				<div class="avatar bg-primary">
					<div class="avatar-content">
						<span
							[data-feather]="'settings'"
							[class]="'avatar-icon'"
							width="32"
						></span>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-5">
			<div class="font-weight-bolder">Settings</div>
			<div class="font-small-2 text-muted">
				Muffin cupcake candy chocolate <br />cake gummi bears fruitcake
			</div>
		</div>
	</div> -->
</div>

<div class="modal-footer">
	<div class="input-group input-group-merge">
		<div class="input-group-prepend border-0">
			<span class="input-group-text border-0"><i class="fa-light fa-magnifying-glass"></i></span>
		</div>
		<input
			type="text"
			id="search"
			class="form-control border-0"
			name="search"
			placeholder="Search for menu, Settings, Files, users or messages here..."
		/>
	</div>
</div>
