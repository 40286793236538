import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import store from "store";
import { PermissionService } from "app/common/permission/service";
import { environment } from 'environments/environment.instance';

@Injectable({
	providedIn: "root",
})
export class AuthGuard implements CanActivate {
	authorized: boolean;
	permissions: any;

	constructor(public router: Router, private permissionService: PermissionService) {
		let accessToken = store.get("accessToken");
		if (accessToken) {
			this.authorized = true;
		}
	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		let accessToken = store.get("accessToken");
		let accessType = store.get("accessType");
		let isExpired = store.get("is_expired");
		let isAdminPortal = store.get("is_management");
		let isRegistered = store.get("is_registered");
		if (!isAdminPortal) {
			if (!accessToken) {
				if (state.url !== undefined && state.url !== "/") {
					let query_url = { returnUrl: state?.url };
					let query_param = {};
					if (state?.root?.queryParams) {
						query_param = state?.root?.queryParams;
						let query_url_list = state?.url.split("?");
						query_url = { returnUrl: query_url_list[0] };
					}
					let query_params = Object.assign({}, query_param, query_url);
					this.router.navigate(["/auth/login"], {
						queryParams: query_params,
					});
				} else {
					this.router.navigate(["/auth/login"], {});
				}
				return false;
			}
			if (!isRegistered && environment.on_premise) {
				this.router.navigate(["/license/upload"], {});
				return false;
			}
		}

		if (!isExpired || isAdminPortal) {
			if (!accessToken) {
				if (state.url !== undefined && state.url !== "/") {
					let query_url = { returnUrl: state?.url };
					let query_param = {};
					if (state?.root?.queryParams) {
						query_param = state?.root?.queryParams;
						let query_url_list = state?.url.split("?");
						query_url = { returnUrl: query_url_list[0] };
					}
					let query_params = Object.assign({}, query_param, query_url);
					this.router.navigate(["/auth/login"], {
						queryParams: query_params,
					});
				} else {
					this.router.navigate(["/auth/login"], {});
				}
				return false;
			} else {
				this.authorized = true;
			}
			let module = next.data["module"];
			let keys = next.data["keyList"] || [];
			if (this.authorized) {
				// if(checkPermission(module, keys)){
				const is_permission_granted = this.permissionService.isGranted(module, keys);
				if (next?.data.hasOwnProperty("fflag")) {
					let featureFlagKey = next.data["fflag"] || [];
					if (this.permissionService.is_feature_active(featureFlagKey)) {
						if (is_permission_granted) {
							// avoid other url except self service portal url to requester
							if (!state.url.startsWith("/self-service") && accessType == 2) {
								this.router.navigate(["/error/403"]);
								return false;
							}
							// avoid self service portal url to technician
							if (state.url.startsWith("/self-service") && accessType == 1) {
								this.router.navigate(["/error/403"]);
								return false;
							}

							return true;
						} else {
							this.router.navigate(["/error/403"]);
							return false;
						}
					} else {
						this.router.navigate(["/error/403"]);
						return false;
					}
				} else {
					if (is_permission_granted) {
						// avoid other url except self service portal url to requester
						if (!state.url.startsWith("/self-service") && accessType == 2) {
							this.router.navigate(["/error/403"]);
							return false;
						}
						// avoid self service portal url to technician
						if (state.url.startsWith("/self-service") && accessType == 1) {
							this.router.navigate(["/error/403"]);
							return false;
						}

						return true;
					} else {
						this.router.navigate(["/error/403"]);
						return false;
					}
				}
				// let user_permissions = JSON.parse(
				// 	localStorage.getItem("userPermissions")
				// );
				// // console.log(module, key, user_permissions)
				// if ((user_permissions[module] || {})[key]) {
				// 	return true;
				// } else {
				// 	this.router.navigate(["/error/403"]);
				// 	return false;
				// }
			} else {
				if (state.url !== undefined && state.url !== "/") {
					this.router.navigate(["/auth/login"], {
						queryParams: { returnUrl: state.url },
					});
				} else {
					this.router.navigate(["/auth/login"], {});
				}
				return false;
			}
		} else {
			this.router.navigate(["/pages/inactive"], {});
			return false;
		}
	}
}
