import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dns-stage-representation',
  templateUrl: './dns-stage-representation.component.html',
  styleUrls: ['./dns-stage-representation.component.scss']
})
export class DnsStageRepresentationComponent implements OnInit {
  public stageArray = []
  invalidStageArray = [];
  public incidentTab = false;
  @Input() inputConfig: any;
  @Input() disableConfig: any;
  @Output() onActionCallback: EventEmitter<any> = new EventEmitter<any>();

  constructor( private _toastr: ToastrService,
    private _activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    const sub = this._activatedRoute.url.subscribe((params) => {
      this.stageArray = this.inputConfig
      if(params[0]?.path === 'incident-edit') {
        this.incidentTab = true;
        if(this.inputConfig) {
          this.stageArray = this.inputConfig
        }
       
      } else {
        if(this.inputConfig) {
          this.stageArray = this.inputConfig;
          this.incidentTab = true;
        }
      }
      
		});
  }
  stageSelection(item, subitem, dropdownValue) {
    if (this.invalidStageArray.length === 0) {
      // this.stageArray.forEach((e) => {
      //   if (e.id === item.id) {
      //     e.active = true;
      //   }
      // });
      if (subitem?.value != undefined){
        subitem?.callback(subitem?.value) 
        // this.onActionCallback.emit(subitem) 
      }
      // console.log('stageArray', this.stageArray);
      // console.log('Selected Item', item);
      // console.log('Dropdown Option = ', dropdownValue);
    }
  }

  checkValidation(item) {
    this.invalidStageArray = [];
    this.stageArray.forEach((ele, index) => {
      if (ele.code < item.code && ele.active === false && !ele.is_disabled) {
        this.invalidStageArray.push(ele);
      }

    });
    if (this.invalidStageArray.length > 0) {
      this.displayToastMsg();
    }
  }

  displayToastMsg = (): void => {
    setTimeout(() => {
      this._toastr.info(
        'Please select previous state.',
        'Information !',
        {
          toastClass: 'toast ngx-toastr custom-toast',
          closeButton: true,
          positionClass: 'toast-top-right',
        }
      );
    }, 1000);
  }
  setBackground(item){
    let style;
    if(item.active){
      style = { 
        'background': item.colorCode,
        'border-color': item.colorCode
       }
    } else if (item.iconNonActive && !item.active && !item.currentStage){
      style = { 
        'background': item.nonActiveColor,
        'border-color': item.nonActiveColor
       }
    } else if (item.currentStage && !item.active){
      style = { 
        'background': item.colorCode,
        'border-color': item.colorCode,
        'box-shadow': "0 0 0 4px #CBDCFF"
       }
    }  else{
      style = {}
    }
    return style
  }

  subStageSelection(item, subitem, dropdownValue) {
    if (this.invalidStageArray.length === 0) {
      // this.stageArray.forEach((e) => {
      //   if (e.id === item.id) {
      //     e.active = true;
      //   }
      // });
      if (subitem?.callback != undefined){
        subitem?.callback(dropdownValue) 
        // this.onActionCallback.emit(subitem) 
      }
    }
  }

}
