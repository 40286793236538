import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { TranslateService } from '@ngx-translate/core';
import { IncidentViewService } from 'app/servicedesk/incident-view/services/incident-view.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dns-incident-kb-solution',
  templateUrl: './dns-incident-kb-solution.component.html',
  styleUrls: ['./dns-incident-kb-solution.component.scss']
})
export class DnsIncidentKbSolutionComponent implements OnInit {

	@Output() onHideKBSolution = new EventEmitter(false);
  @Input() public kbID: string;
  @Input() public kbSolution: any;

	constructor(
		private _coreSidebarService: CoreSidebarService,
		private _incidentViewService: IncidentViewService,
		private _toastr: ToastrService,
		private _translateService: TranslateService,
	) { }


	ngOnInit(): void {
	}

	toggleSidebar = (name): void => {
		this._coreSidebarService
			.getSidebarRegistry(name)
			.toggleOpen();

		this.onHideKBSolution.emit(true);
	};

}
