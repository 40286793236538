<div class="selct-feature group-list-feature">
	<div class="select-sec">
		<div class="form-group mb-0">
			<label class="form-label" [for]="inputConfig?.id" [hidden]="inputConfig && inputConfig?.hideLabel">
				Priority
			</label>
			<ng-select
				[items]="inputConfig && inputConfig?.inputOptions ? inputConfig?.inputOptions : priorityOption"
				bindLabel="priorityName || name"
				bindValue="id"
				[name]="inputConfig?.id || ''"
				[id]="inputConfig?.id"
				placeholder="{{ 'UI.k_select_option' | translate }}"
				[dropdownPosition]="'auto'"
				[(ngModel)]="inputConfig.model"
				(change)="valueChange($event)"
				[disabled]="inputConfig?.isEditDisabled"
				[searchable]="true"
				[clearable]="inputConfig?.clearable"
				attr.data-testid="sel_{{ inputConfig?.id }}"
			>
				<ng-template ng-label-tmp let-item="item">
					<div class="badge badge-light-{{ item.priorityColor || item.color }}">
						<i class="fa-light mr-25 {{ item.priorityIcon || item.icon }}"></i>
						{{ item.priorityName || item.name }}
					</div>
				</ng-template>
				<ng-template ng-option-tmp let-item="item" class="wdvewv">
					<div class="badge badge-light-{{ item.priorityColor || item.color }}">
						<i class="fa-light mr-25 {{ item.priorityIcon || item.icon }}"></i>
						{{ item.priorityName || item.name }}
					</div>
				</ng-template>
			</ng-select>
		</div>
	</div>
</div>

<!-- [items]="inputConfig?.priorityInput ? inputConfig?.priorityInput : priorityOption" -->
