<div class="macros-modal">
	<div class="macros-head">
		<h6>Macros</h6>
		<i class="fa-light fa-circle-question"></i>
	</div>
	<div class="macros-list">
		<ng-scrollbar class="custom-scrollbar section-scrollbar">
			<ng-container *ngFor="let item of this.macrosObject.macrosConfig | keyvalue">
				<span class="list-title">{{ item.key | translate }}</span>
				<ul class="group-list">
					<ng-container *ngFor="let i of item.value">
						<ng-container *ngIf="i?.fflag">
							<ng-container *appFeatureActive="[i?.fflag]">
								<li
									class="cursor-pointer custom-tag"
									(click)="macrosEvent(item, i)"
									[ngClass]="i?.isSelected ? 'active primary-color transparent-primary' : ''"
								>
									{{ i[display_key] | translate }}
								</li>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="!i?.fflag">
							<li
								class="cursor-pointer custom-tag"
								(click)="macrosEvent(item, i)"
								[ngClass]="i?.isSelected ? 'active primary-color transparent-primary' : ''"
							>
								{{ i[display_key] | translate }}
							</li>
						</ng-container>

						<!-- [ngClass]="i?.isSelected ? 'active primary-color transparent-primary' : ''" -->
					</ng-container>
				</ul>
			</ng-container>
		</ng-scrollbar>
	</div>
	<div class="macros-bottom">
		<button class="btn btn-icon">
			<i class="fal fa-search"></i>
		</button>
		<div class="form-group">
			<input
				type="text"
				class="form-control macros"
				id="macrosSearch"
				name="macrosSearch"
				placeholder="Search Macros"
				[(ngModel)]="macrosObject.searchData"
				(ngModelChange)="searchEvent($event)"
			/>
		</div>
	</div>
</div>
