import { Injectable } from "@angular/core";
import { ApiService } from "app/common/services/api/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class UserService extends ApiService {
	constructor(httpClient: HttpClient) {
		super(httpClient);
	}

	getUserProfiles(payload = {}) {
		return this.get("ux/common/user/profile/", payload);
	}

	saveUserProfile(payload: any) {
		return this.post("ux/common/user/profile/", payload);
	}

	getUserProfile(userid: Number) {
		return this.get("ux/common/user/profile/" + userid.toString() + "/");
	}

	deleteUserProfile(userid: Number) {
		return this.delete("ux/common/user/profile/" + userid.toString() + "/");
	}

	sendUserInvite(invites: any) {
		return this.post("ux/common/user/profile/user_invite/", invites);
	}

	getOptions(payload = {}) {
		return this.get("ux/common/user/profile/options/", payload);
	}

	saveUserPreference(payload: any) {
		return this.post("ux/common/user/profile/preference/", payload);
	}
	getPreferences() {
		return this.get("ux/common/user/profile/preference/");
	}

	getSearchFilter(payload: any) {
		return this.get("ux/common/filters/filters/search_filter/", payload);
	}

	getFilterOptions(payload: any) {
		return this.get("ux/common/filters/filters/filter_options/", payload);
	}
	getCurrentUserProfile(payload: any = {}) {
		return this.get("ux/common/user/profile/myprofile/", payload);
	}
	getPendingUserCount(payload = {}) {
		return this.get("ux/common/user/profile/user_count/", payload);
	}
	getSearchHistory(payload = {}) {
		return this.get("ux/common/user/profile/search_history/", payload);
	}
	getAssignedModule(payload = {}) {
		return this.get("ux/common/user/profile/used_mod/", payload);
	}
	replaceAssignee(payload: any) {
		return this.post("ux/common/user/profile/replace_assignee/", payload);
	}
	getFilterOption(payload: any) {
		return this.get("ux/common/filters/filters/filter_options/", payload);
	}
	getSavedFilter(payload) {
		return this.get("ux/common/filters/filters/filter/", payload);
	}

	getUserMenu(payload) {
		return this.get("ux/common/user/profile/menu/", payload);
	}

	getCIRelationMap(payload: any) {
		return this.post("ux/common/cmdb/relation/ci-rel-map/", payload);
	}

	saveNotificationSeen(payload) {
		return this.post("ux/common/user/profile/notification/", payload);
	}

	getNotificationCount(payload = {}) {
		return this.get("ux/common/user/profile/notification-count/", payload);
	}

	saveOrgLocationProfile(payload) {
		return this.post("ux/common/org/location/", payload);
	}

	enableRequesterUserProfile(payload: any) {
		return this.post("ux/common/user/profile/convert_req_user/", payload);
	}
}
