<div class="full-body-height">
	<div class="main-content flex-height">
		<div class="main-sec">
			<div class="action-bar" [ngClass]="isExpandSearchWidth ? 'expanded-search' : ''">
				<div class="inner-section">
					<div class="left-section">
						<div class="action-search">
							<app-search
							(getfilterData)="getFilterSearch($event)"
							[filterDetails]="getAuditList"
							[showDataTable]="showDataTable"
							[columnOptions]="columnOptions"
							[conditionOptions]="conditionOptions"
							[showRightsection]="showRightsection"
							[savedData]="savedData"
							[Options]="option"
							[module]="module"
							(genericFilter)="genericSearchFilter($event)"
							(isExpandSearchWidth)="getSearchWidth($event)"
							></app-search>
						</div>
						<div class="date-range" id="dtRange" name="datePicker" style="min-width: 240px">
							<date-range-picker
								(select)="onRangeSelect($event)"
								[selectedDuration]="timeFilterObj"
							></date-range-picker>
						</div>
					</div>
					<div class="right-section"></div>
				</div>
			</div>
			<div class="action-section">
				<div class="table-view flex-height">
					<dns-server-grid
						[rows]="audits"
						[columns]="columns"
						[count]="auditCount"
						(editCallback)="onUpdate($event)"
						(page)="setPage($event)"
						(sort)="onSort($event)"
						[options]="options"
						[isEditable]="false"
						(setlimit)="setLimit($event)"
						[limit]="limit"
						class="bootstrap core-bootstrap"
						[permissions]="permissions"
						[showData]="showData"
						[emptyData]="empty_data"
						[filterData]="filterData"
					>
					</dns-server-grid>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- <div class="content-wrapper">
	<div class="content-body">
		<div class="align-items-center d-flex mb-1 justify-content-start">
				<app-search
					(getfilterData)="getFilterSearch($event)"
					[filterDetails]="getAuditList"
					[showDataTable]="showDataTable"
					[columnOptions]="columnOptions"
					[conditionOptions]="conditionOptions"
					[showRightsection]="showRightsection"
					[savedData]="savedData"
					[Options]="option"
				></app-search>
			</div>
		<div>
			<dns-server-grid
				[rows]="audits"
				[columns]="columns"
				[count]="auditCount"
				(editCallback)="onUpdate($event)"
				(page)="setPage($event)"
				(sort)="onSort($event)"
				[options]="options"
				[isEditable]="false"
				(setlimit)="setLimit($event)"
				class="bootstrap core-bootstrap"
				[permissions]="permissions"
				[showData]="showData"
				[emptyData]="empty_data"
				[filterData]="filterData"
				[offset]="pageNumber"
			>
			</dns-server-grid>
		</div>
	</div>
</div> -->
