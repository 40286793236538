<div class="slideout-content">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog sidebar-md">
            <div class="modal-content add-new-user py-0">
                <div class="modal-header d-flex align-items-center justify-content-between border-bottom">
                    <!-- <h5 class="modal-title" id="exampleModalLabel">{{"UI.k_analysis" | translate}} {{"UI.k_submission" | translate}}</h5> -->
                    <h5 class="modal-title" id="exampleModalLabel">{{(riskDataEdit.name ? "UI.k_edit" : "UI.k_add") | translate}} {{"UI.k_risk" | translate}}</h5>
                    <!-- (riskDataEdit.name ? "UI.k_edit_tag" : "UI.k_add_tag") -->
                    <!-- <button type="button" data-dismiss="modal" id="btnClose" name="btnClose" aria-label="Close"
                        (click)="toggleSidebar('analysis-submition-sidebar')" class="btn btn-flat-secondary btn-icon" rippleEffect>
                        <i class="fa-light fa-xmark"></i>
                    </button> -->
                    <button type="button" id="btnClose" name="btnClose" [attr.data-testid]="'btnClose'" class="btn btn-flat-secondary btn-icon"
                        data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('analysis-submition-sidebar-risk')">
                        <i class="fa-light fa-xmark"></i>
                    </button>
                </div>
                <div class="modal-body flex-1 p-0">
                    <form #submissionForm="ngForm" (ngSubmit)="onSubmit(submissionForm)" class="flex-auto-height">
                        <ng-scrollbar class="custom-scrollbar section-scrollbar">
                            <div class="modal-body-inner flex-1 p-1">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="submissionFormType" class="form-label"
                                                > {{ 'UI.k_name' | translate }}* </label
                                            >
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="submitVlanName"
                                                name="submitVlanName"
                                                placeholder="{{ 'UI.k_type_here' | translate }}"
                                                required
                                                minlength="3"
                                                maxlength="100"
                                                [(ngModel)]="submitVlanName"
                                                #submitVlanNameInput="ngModel"
                                                [appWhiteSpaceCheck]="submitVlanName"
                                                [class.error]="submitVlanNameInput.invalid && submissionForm.submitted"
                                            />
                                            <span class="invalid-form" *ngIf="submissionForm.submitted && submitVlanNameInput.invalid" class="invalid-form">
                                                <small class="form-text text-danger" *ngIf="submitVlanNameInput.errors.required"
                                                    >{{ 'UI.k_field_required' | translate }}
                                                </small>
                                            </span>
                                            <span class="invalid-form" *ngIf="submissionForm.submitted && submitVlanNameInput.invalid">
                                                <small class="form-text text-danger" *ngIf="submitVlanNameInput.errors.minlength">
                                                    {{ 'Error.err_input_min_char' | translate }}
                                                </small>
                                            </span>
                                            <span class="invalid-form" *ngIf="submissionForm.submitted && submitVlanNameInput.invalid">
                                                <small class="form-text text-danger" *ngIf="submitVlanNameInput.errors.whitespace">
                                                    {{ "UI.k_space_validation" | translate }}
                                                </small>
                                            </span>
                                            
                                        </div>

                                    </div>
                                    
                                    <div class="col-lg-6 col-md-6 col-xs-12">

                                        <div class="form-group">
                                            <label for="selrisk" class="form-label">
                                                {{ 'UI.k_risk' | translate }} {{ 'UI.k_type' | translate }}*</label
                                            >
                                            <ng-select
                                                [items]="riskOption"
                                                bindLabel="display_key"
                                                bindValue="display_key"
                                                class="form-control"
                                                [(ngModel)]="selectedRisk" 
                                                name="selrisk"
                                                id="selrisk"
                                                placeholder="{{ 'UI.k_select' | translate }}"
                                                #TDSelRisk="ngModel"
                                                [class.error]="TDSelRisk.invalid && submissionForm.submitted"
                                                required
                                            >
                                            </ng-select>
                                            <span class="invalid-form" *ngIf="submissionForm.submitted && TDSelRisk.invalid">
                                                <small class="form-text text-danger" *ngIf="TDSelRisk.errors.required"
                                                    >{{ 'UI.k_field_required' | translate }}
                                                </small>
                                            </span>
                                            <span class="invalid-form" *ngIf="submissionForm.submitted && TDSelRisk.invalid">
                                                <small class="form-text text-danger" *ngIf="TDSelRisk.errors.minlength">
                                                    {{ 'Error.err_input_min_char' | translate }}
                                                </small>
                                            </span>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12 col-xs-12">
                                        <div class="form-group">
                                            <label for="txtDescription"> {{"UI.k_mitigation_plan" | translate}} </label>
                                            <textarea type="text" id="txtDescription" class="form-control"
                                                name="mitigationPlan" placeholder="{{'UI.k_mitigation_plan' | translate}}"
                                                [(ngModel)]="mitigationPlan"
                                                maxlength="3000"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-xs-12">
                                        <div class="form-group">
                                            <label for="txtDescription"> {{"UI.k_description" | translate}} </label>
                                            <textarea type="text" id="txtDescription" class="form-control"
                                                name="description" placeholder="{{'UI.k_description' | translate}}"
                                                [(ngModel)]="attachmentDescription"
                                                maxlength="3000"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-scrollbar>
                        <div class="d-flex justify-content-end border-top py-1 px-2 stepper-btn">
                            <button
                            type="submit"
                            id="btnSubmit"
                            name="btnSubmit"
                            [attr.data-testid]="'btnSubmit'"
                            class="btn btn-primary btn-next" rippleEffect
                            (click)=" onSubmit(submissionForm)"
                                >
                                <span class="align-middle d-sm-inline-block d-none">{{'UI.k_save' | translate }}</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
