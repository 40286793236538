import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ChangeViewService } from "app/servicedesk/change-management/services/change-view.service";
import { map, catchError } from "rxjs/operators";

@Component({
  selector: 'app-dns-change-view',
  templateUrl: './dns-change-view.component.html',
  styleUrls: ['./dns-change-view.component.scss']
})
export class DnsChangeViewComponent implements OnInit {

	@Output() onHideChangeView = new EventEmitter(false);
  @Input() public change_data: any = {};
  @Input() public change_id : any = "";
  dataLoaded : boolean = false
  	location_name = ""

	constructor(
		private _changeViewService: ChangeViewService,
	) { }


	ngOnInit(): void {
		if (!this.change_data.hasOwnProperty("display_id")) {
			this._changeViewService
			.getChange(this.change_id,{'return_main_info':true})
			.pipe(
				map(
					(response) => {
						this.change_data = response?.change;
						this.dataLoaded = true						
					},
					(error) => {
						//todo
					}
					)
		)
		.subscribe();
		}
		else{
			this.dataLoaded = true
		}

		this.location_name = this?.change_data?.requester?.location?.location_name ||
				this?.change_data?.requester?.location?.city ||
				this?.change_data?.requester?.location?.state ||
				this?.change_data?.requester?.location?.country;
	}

	closeSidebar = (): void => {
		this.onHideChangeView.emit(true);
	};

}
