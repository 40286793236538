import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CommonService } from "../../../app/services/common.service";
import { CoreMenuService } from "@core/components/core-menu/core-menu.service";

@Component({
	selector: "[core-menu]",
	templateUrl: "./core-menu.component.html",
	styleUrls: ["./core-menu.component.scss"],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreMenuComponent implements OnInit {
	currentUser: any;
	currentscreen: any;
	tempScreen: any;
	activeSubmenu: any[];
	selectedId: any;
	activeToggale = false;
	hoverClass: any;
	@Input()
	layout = "vertical";

	@Input()
	menu: any;

	// Private
	private _unsubscribeAll: Subject<any>;

	/**
	 *
	 * @param {ChangeDetectorRef} _changeDetectorRef
	 * @param {CoreMenuService} _coreMenuService
	 */
	constructor(
		private _changeDetectorRef: ChangeDetectorRef,
		private _coreMenuService: CoreMenuService,
		private _commonService: CommonService
	) {
		// Set the private defaults
		let isDarkMode: boolean;
		this._unsubscribeAll = new Subject();
		this.tempScreen = JSON.parse(localStorage.getItem("config")).layout.skin;
		this._commonService.updateCurrentSceen(this.tempScreen);
		this._commonService.currentscreen.subscribe((val) => {
			this.currentscreen = val;
			this.ngOnInit();
		});
		if (this.currentscreen !== this.tempScreen) {
			this.currentscreen = this.tempScreen;
			// window.location.reload();
			this.ngOnInit();
		}
		this._commonService.productToggal.subscribe((val) => {
			this.activeToggale = val;
		});
	}

	// Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Set the menu either from the input or from the service
		this.menu = this.menu || this._coreMenuService.getCurrentMenu();

		// Subscribe to the current menu changes
		this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
			this.currentUser = this._coreMenuService.currentUser;
			// Load menu
			this.menu = this._coreMenuService.getCurrentMenu();
			if (this.menu) {
				this._changeDetectorRef.markForCheck();
				this.selectedId = this.menu[0].id;
				this.activeSubmenu = this.menu[0].children;
			}
		});
	}
	showSubMenu(id: any): void {
		this._commonService.updateSubMenuToggle(true);
		let studentObj = this.menu.find((t: { id: any }) => t.id === id);
		this.activeSubmenu = studentObj.children;
		this.selectedId = id;
	}
	productToggale(): void {
		this.activeToggale = !this.activeToggale;
		this._commonService.updateProductToggle(this.activeToggale);
	}
	changeStyle(e: any, id) {
		var hoverClass = e.type == "mouseover" ? "isOpen" : "isClose";
		this._commonService.updateFluentMenu(hoverClass);
		let studentObj = this.menu.find((t: { id: any }) => t.id === id);
		this.activeSubmenu = studentObj.children;
	}
}
