import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
	name: "trim_plan",
})
export class TrimPlanPipe implements PipeTransform {
	transform(n: string): string {
		return n.split(" ").slice(2).join(" ");
	}
}
