<div

    class="flex-fill mb-1"
    >
    <label
        for="emailSub"
        class="form-label  mr-50 pt-50"
        style="display: flex;align-items: center;"
        >{{ 'UI.k_message' | translate}}<span class="required-field">&nbsp;*</span>
    </label>
    <textarea
        type="text"
        id="emailSub"
        name="emailSub"
        #TDEmailSub="ngModel"
        class="form-control font-small-2"
        [placeholder]="msgPlaceholder"
        [(ngModel)]="smsConfig.message_template"
        minlength="3"
        maxlength="1000"
        required
    >
    </textarea>
    <label
        for="emailSub"
        class="form-label  mr-50 pt-50"
        style="display: flex;align-items: center;"
        >{{ 'UI.k_recipients' | translate}}<span class="required-field">&nbsp;*</span>
    </label>
    <ng-select
        [items]="[]"
        [addTag]="true"
        [multiple]="true"
        [hideSelected]="true"
        id="recipients"
        name="recipients"
        addTagText="{{ 'UI.k_add_item' | translate }}"
        [(ngModel)]="smsConfig.recipients"
        placeholder="xxxxxxxxxx"
        [appEmailCheck]="smsConfig.recipients"
        notFoundText="{{'UI.k_no_items_found'| translate }}"
        hideSelected="true"
    >
    </ng-select> 
</div>
