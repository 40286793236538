import { MspCoreMenu } from "@core/types";

export const MspMenu: MspCoreMenu[] = [
	{
		id: "organizations",
		title: "UI.k_organizations",
		type: "item",
		icon: "home",
		url: "msp/organization",
		position: "sub",
		permission: {
			moduleList: ["user"],
			keyList: ["view"],
		},
	},
	{
		id: "msp-users",
		title: "UI.k_msp_user",
		type: "item",
		icon: "user",
		url: "msp/user",
		position: "sub",
		permission: {
			moduleList: ["infinity_partner"],
			keyList: ["view"],
		},
	},
];
