import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, NgForm, ValidationErrors, Validator } from "@angular/forms";
import { isEmpty } from "lodash";

@Directive({
	selector: "[duplicateLabelValidator]",
	providers: [{ provide: NG_VALIDATORS, useExisting: DuplicateLabelValidatorDirective, multi: true }],
})
export class DuplicateLabelValidatorDirective implements Validator {
	@Input("duplicateLabelValidator") currentArray = [];
	@Input("dlvKey") key = "name";
	@Input("itemIndex") currentIndex = 0;

	validate(control: AbstractControl): ValidationErrors | null {
		let isDuplicate = false;
		let currentInputText = control.value;
		let index = 0;
		for (const item of this.currentArray) {
			if (typeof item === "object") {
				if (item[this.key]?.toLowerCase() === currentInputText?.toLowerCase() && index !== this.currentIndex) {
					isDuplicate = true;
					break;
				} else {
					isDuplicate = false;
				}
			} else if (typeof item === "string") {
				if (item.toLowerCase() === currentInputText.toLowerCase() && index !== this.currentIndex) {
					isDuplicate = true;
					break;
				} else {
					isDuplicate = false;
				}
			}
			index++;
		}
		return isDuplicate ? { duplicateLabel: true } : null;
	}
}
