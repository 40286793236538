import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { DOCUMENT, PlatformLocation } from "@angular/common";
declare var $;

@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss']
})
export class PresentationComponent implements OnInit {
  elem: HTMLElement;
  presentationMode = false;
  constructor(
    @Inject(DOCUMENT) private document: any, location: PlatformLocation
  ) {    location.onPopState(() => {
    this.presentationMode === false
    this.document.body.classList.remove("presentation-mode");
    //console.log('pressed back!');

}); }

  ngOnInit(): void {
    this.elem = document.documentElement;
    if (this.document.exitFullscreen) {
      this.document.body.classList.remove("presentation-mode");
    }
    // var allowed = true;
    // $(document).keydown(function (event) {
    //   console.log("repeat", event)
    //   if (event.repeat != undefined) {
    //     allowed = !event.repeat;
    //   }
    //   if (!allowed) return;
    //   allowed = false;
    //   //...
    // });
    // document.onfullscreenchange = () => {
    //   var e = new Event('keypress');
    //   console.log(e);
    //   // if (this.elem.requestFullscreen) {
    //   //   // this.elem.requestFullscreen();
    //   //   this.document.body.classList.add("presentation-mode");
    //   // } else {
    //   //   this.document.body.classList.remove("presentation-mode");

    //   // }

    // }

  }
  openFullscreen() {
    this.presentationMode = !this.presentationMode;
    if(this.presentationMode === true){
      this.document.body.classList.add("presentation-mode");
    }
    else{
      this.document.body.classList.remove("presentation-mode");
    }
    // if (this.elem.requestFullscreen) {
    //   this.elem.requestFullscreen();
    //   this.document.body.classList.add("presentation-mode");
    // }
  }

  @HostListener("document:fullscreenchange", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    // console.log("fullscreenchange", event);
    // console.log("this.elem", this.elem);
    // console.log("fullscreen", document.fullscreenElement);


    if (!document.fullscreenElement) {
      this.document.body.classList.remove("presentation-mode");
    }

    if (document.fullscreenElement) {
      // if (this.elem.requestFullscreen) {
      //   this.elem.requestFullscreen();
      // }
      this.document.body.classList.add("presentation-mode");
    }
  }


  // @HostListener('document:keypress', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //   console.log(event);

  //   if (event.key == 'F11') {
  //     this.document.body.classList.add("presentation-mode");
  //   }

  //   // this.key = event.key;
  // }

  @HostListener('document: keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    //console.log("keydown", event);
    if (event.key === "Escape") {
      this.document.body.classList.remove("presentation-mode");
    }

    if (event.key == 'F11') {
      this.document.body.classList.add("presentation-mode");
    }
    // console.log(document.fullscreenEnabled);

  }

  // @HostListener("document:fullscreenchange", [])
  // fullScreen() {
  //   if (document.fullscreenElement) {
  //     console.log(`Entered full-screen mode.`);
  //   } else {
  //     console.log('Leaving full-screen mode.');
  //   }
  // }
  // @HostListener('keydown', ['$event']) onKeyDown(event) {
  //   const e = <KeyboardEvent>event;
  //   console.log("eeeeeeee", e);

  //   const charCode = e.which ? e.which : e.keyCode;
  //   console.log(charCode);
  //   // if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode < 96 || charCode > 105) {
  //   //   return false;
  //   // }
  //   return true;
  // }



  // @HostListener('document: keyup', ['$event'])
  // onKeyUp(event: KeyboardEvent) {
  //   console.log("keyup", event);
  //   // console.log("11", this.document.body.classList.includes('presentation-mode'));

  //   if (event.key === "Escape") {
  //     // this.document.body.classList.remove("presentation-mode");
  //   }

  //   if (event.key == 'F11' && document.fullscreenElement) {
  //     // this.document.body.classList.remove("presentation-mode");
  //   }

  // }



  // @HostListener('window:keyup', ['$event'])
  // keyEvent(event: KeyboardEvent) {
  //   console.log(event);
  //   if (event.key === "Escape") {
  //     this.document.body.classList.remove("presentation-mode");
  //   }

  // }

  // @HostListener('keydown', ['$event']) onKeyDown(e) {
  //   console.log("keydown", e);

  //   if (e.shiftKey && e.keyCode == 9) {
  //     console.log('shift and tab');
  //   }
  // }

  // @HostListener('keyup', ['$event']) onKeyUp(e) {
  //   console.log("keyup", e);

  //   if (e.type === 'keyup' && e.keyCode == 122) {
  //     this.document.body.classList.remove("presentation-mode");
  //   }
  // }

  // @HostListener('document:keyup.f11', ['$event']) onfKeydownHandler(evt: KeyboardEvent) {
  //   console.log(evt);

  //   // if (this.document.body.classList.value.indexOf('presentation-mode') > -1) {
  //   //   this.document.body.classList.remove("presentation-mode");
  //   // }
  // }
}

// document.addEventListener('keyup', function (event) {
//   if (event.defaultPrevented) {
//     console.log("default");

//     return;
//   }
//   const ele = document.documentElement;


//   var key = event.key || event.keyCode;

//   if (key === 'F11' && !ele.requestFullscreen) {
//     console.log("f11 event");

//     // doWhateverYouWantNowThatYourKeyWasHit();
//   }
// });

// $(document).keypress(function (e) {
//   console.log("f11");

//   if (e.which == 122) {
//     e.preventDefault();//kill anything that browser may have assigned to it by default
//     //do what ever you wish here :) 
//     console.log("f11");
//     return false;
//   }
// });
window.onresize = function (event) {
  // console.log(window.innerHeight, window.innerWidth);

  // var allowed = true;
  // $(document).keydown(function (event) {
  //   console.log("repeat", event, event.repeat)
  //   if (event.repeat != undefined) {
  //     allowed = !event.repeat;
  //   }
  //   if (!allowed) return;
  //   allowed = false;
  //   //...
  // });

}

// window.onresize = function (event) {
  // if (this.document.exitFullscreen) {
  //   this.document.exitFullscreen();
  //   this.document.body.classList.remove("presentation-mode");
  // } else if (this.document.mozCancelFullScreen) {
  //   /* Firefox */
  //   this.document.mozCancelFullScreen();
  // } else if (this.document.webkitExitFullscreen) {
  //   /* Chrome, Safari and Opera */
  //   this.document.webkitExitFullscreen();
  //   this.document.body.classList.remove("presentation-mode");
  // } else if (this.document.msExitFullscreen) {
  //   /* IE/Edge */
  //   this.document.msExitFullscreen();
  // }
  // document.addEventListener('fullscreenchange', exitHandler, false);
  // document.onfullscreenchange = () => console.log('fullscreenchange event fired!');
  // var e = new Event('keypress');
  // console.log(e);
  // const ele = document.documentElement;
  // console.log(ele.requestFullscreen);

  // console.log(document.fullscreenElement);

  // if (e && ele.requestFullscreen === null) {
    // this.document.body.classList.remove("presentation-mode");
  // }
  // console.log("re", e);

// }

// function exitHandler() {
  // console.log("exithandler");

  //  if (document.webkitIsFullScreen === false)
  //  {
  //   ///fire your event
  //  }
  //  else if (document.mozFullScreen === false)
  //  {
  //   ///fire your event
  //  }
  //  else if (document.msFullscreenElement === false)
  //  {
  //   ///fire your event
  //  }
// }
