import { Injectable } from "@angular/core";
import { ProductPrice, Customer, Product } from "../models";

@Injectable({
	providedIn: "root",
})
export class ComputeService {
	constructor() {}

	computePrice(unit: number, item: ProductPrice, subscription_tiers = []): number {
		if (subscription_tiers.length > 0) {
			subscription_tiers = subscription_tiers.filter((tier) => tier?.item_price_id === item.id);
		}
		// Chargebbe possible pricing models
		if (item.pricing_model === "flat_fee") {
			return item.price || 0;
		} else if (item.pricing_model === "per_unit") {
			return (unit - item.free_quantity) * item.price;
		} else if (item.pricing_model === "tiered") {
			let tiers = subscription_tiers.length > 0 ? subscription_tiers : item.tiers.length > 0 ? item.tiers : [];
			let total_sum = 0;
			let unit_remains = unit;
			for (let i = 0; i < tiers.length; i++) {
				if (unit < tiers[i].starting_unit) {
					break;
				}
				if (unit_remains <= 0) {
					break;
				}
				if (tiers[i].ending_unit) {
					let unit_block = tiers[i].ending_unit - tiers[i].starting_unit + 1;
					unit_remains = unit_remains - unit_block;
					total_sum = total_sum + unit_block * tiers[i].price;
				} else {
					let unit_block = unit_remains;
					unit_remains = 0;
					total_sum = total_sum + unit_block * tiers[i].price;
				}
			}
			return total_sum;
		} else if (item.pricing_model === "volume") {
			let tiers = subscription_tiers.length > 0 ? subscription_tiers : item.tiers.length > 0 ? item.tiers : [];
			let total_sum = 0;
			for (let i = 0; i < tiers.length; i++) {
				if (tiers[i].ending_unit) {
					if (unit >= tiers[i].starting_unit && unit <= tiers[i].ending_unit) {
						total_sum = unit * tiers[i].price;
						break;
					}
				} else {
					if (unit >= tiers[i].starting_unit) {
						total_sum = unit * tiers[i].price;
						break;
					}
				}
			}
			return total_sum;
		} else if (item.pricing_model === "stairstep") {
			let tiers = subscription_tiers.length > 0 ? subscription_tiers : item.tiers.length > 0 ? item.tiers : [];
			let total_sum = 0;
			for (let i = 0; i < tiers.length; i++) {
				if (tiers[i].ending_unit) {
					if (unit >= tiers[i].starting_unit && unit <= tiers[i].ending_unit) {
						total_sum = tiers[i].price;
						break;
					}
				} else {
					if (unit >= tiers[i].starting_unit) {
						total_sum = tiers[i].price;
						break;
					}
				}
			}
			return total_sum;
		}
	}

	totalCredits(customer: Customer): number {
		return customer.refundable_credits;
	}

	unitsData(input: any) {
		// TODO - need to deal unit measurement
		let data: string = "Unit:1::";
		if (input) data = input;
		let unitData = [];
		let units = [];
		if (data !== undefined && data !== "") units = data.split("|");
		else units = ["Unit:1::"];
		for (let i = 0; i < units.length; i++) {
			let x = units[i].split(":");
			let step = +(x[3] || "0");
			let max = +(x[2] || "0");
			let type = "input";
			let options = [];
			if (step !== 0 && max !== 0) {
				let min = +(x[1] || "1");
				type = "select";
				while (min < max) {
					options.push(min);
					min = min + step;
				}
				options.push(min);
			}
			let data = {
				id: i,
				name: x[0],
				value: +(x[1] || "1"),
				min: +(x[1] || "1"),
				max: +(x[2] || "0"),
				step: +(x[3] || "0"),
				type: type,
				options: options,
			};
			unitData.push(data);
		}
		return unitData;
	}
}
