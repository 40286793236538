import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner-name-tag',
  templateUrl: './partner-name-tag.component.html',
  styleUrls: ['./partner-name-tag.component.scss']
})
export class PartnerNameTagComponent implements OnInit {

  data: any;
  public avatarBackgroundColor:string = '';
	public rowindex: any;
	constructor() {
    this.data = {};
  }

	ngOnInit(): void {
    if(this.data?.profile?.avatar_color){
      this.avatarBackgroundColor = this.data?.profile.avatar_color
    }
    else if(this.data?.avatar_color){
      this.avatarBackgroundColor = this.data?.avatar_color
    }
    else {
      this.avatarBackgroundColor = '#f59518'
    }
  
  }		
}
	




