import { Component, OnInit, Input, Output, SimpleChanges } from "@angular/core";

interface EmptyData {
	icon?: string;
	msg?: string;
	description?: string;
	id: any;
	name: string;
	button?: string;
	import_csv?: boolean;
	csv_callback?: any;
	import_csv_text?: string;
	add_callback?: any;
	sidebar_name?: any;
	type?: string;
	config?: any;
	tooltip?: string;
}

@Component({
	selector: "app-dns-empty-grid-data",
	templateUrl: "./dns-empty-grid-data.component.html",
	styleUrls: ["./dns-empty-grid-data.component.scss"],
})
export class DnsEmptyGridDataComponent implements OnInit {
	@Input() emptyData: EmptyData;
	@Input() permissions: any;
	@Input() noFilterData: any = false;
	constructor() {}

	ngOnInit(): void {}
}
