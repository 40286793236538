<div class="single-ticket-status">
	<div class="ticket-item"  id="divTicket" name="divTicket">	
		<div class="d-flex" [ngClass]="config?.data?.count > 0 && config?.data?.item_filter ? 'cursor-pointer' : ''" (click)="showProbSidebar(config?.data)">
			<div class="icon-container">
				<div class="icon-sec" *ngIf="config?.data?.color?.startsWith('#')" [ngStyle]="{'background-color': config?.data?.color}">
					<i class="{{ config?.data?.icon }}"></i>
				</div>				
				
				<div class="icon-sec" *ngIf="!config?.data?.color?.startsWith('#')" [ngClass]="'bg-' + config?.data?.color">
					<i class="{{ config?.data?.icon }}"></i>
				</div>				
			</div>
			<div class="ticket-title align-items-center d-flex">{{ config?.data?.name }}</div>
		</div>
		<div class="d-flex text-center" [ngClass]="config?.data?.count > 0 && config?.data?.item_filter ? 'cursor-pointer' : ''" (click)="showProbSidebar(config?.data)">
			<div class="ticket-value set-color">{{ config?.data?.count }}</div>
		</div>
		<ng-container *ngIf="config.subdata">
			<div class="sub-data d-flex justify-content-start align-items-center border-top">
				<span class="reg-font text-muted-color">To {{config.subdata.since_time}}</span>
				<!-- tooltipClass="fadeInAnimation"
				ngbTooltip="To {{config.subdata.since_time}}:&nbsp;{{ config.subdata.count }} Change:&nbsp;{{ config.subdata.diff_value }}&nbsp;({{ config.subdata.percent }}%)"
				placement="top" -->
				<div class="badge d-flex ml-50" [ngClass]="'badge-light-' + config.subdata.bg_color"
				> 
					<div class="icon" *ngIf="config.subdata.percent >= 0"><i class="fas fa-sort-up" style="margin-top:0.2rem;margin-bottom:-0.2rem;"></i></div>
					<div class="icon" *ngIf="config.subdata.percent < 0"><i class="fas fa-sort-down" style="margin-top:-0.2rem"></i></div>
					<span class="font-small-3">&nbsp;{{config.subdata.percent_value}}</span>
				</div>
			</div>
		</ng-container>
	</div>
</div>
<!--

<div class="single-ticket-status">
	<div class="ticket-item"  id="divTicket" name="divTicket">	
		<div class="icon-sec" [ngClass]="'bg-' + config?.data?.color">
			<i class="{{ config?.data?.icon }}"></i>
		</div>
		<div class="ticket-title">{{ config?.data?.name }}</div>
		<div class="ticket-value">{{ config?.data?.count }}</div>
		<ng-container *ngIf="config.subdata">
			<div class="sub-data d-flex justify-content-start align-items-center">
				<span class="reg-font text-muted-color">To {{config.subdata.since_time}}</span>
				<div class="badge d-flex ml-50" [ngClass]="'badge-light-' + config.subdata.bg_color"
					tooltipClass="fadeInAnimation"
					ngbTooltip="To {{config.subdata.since_time}}:&nbsp;{{ config.subdata.count }} Change:&nbsp;{{ config.subdata.diff_value }}&nbsp;({{ config.subdata.percent }}%)"
					placement="top"
				> 
					<div class="icon" *ngIf="config.subdata.percent >= 0"><i class="fas fa-sort-up" style="margin-top:0.2rem;margin-bottom:-0.2rem;"></i></div>
					<div class="icon" *ngIf="config.subdata.percent < 0"><i class="fas fa-sort-down" style="margin-top:-0.2rem"></i></div>
					<span class="font-small-3">&nbsp;{{config.subdata.percent_value}}</span>
				</div>
			</div>
		</ng-container>
	</div>
</div>
-->