<div class="d-flex align-items-center">
	<div *ngIf="data?.avatar?.length; else customAvatar">
		<img class="rounded-circle mr-1" src="{{ data.avatar }}" height="32" width="32" alt="datatable-avatar" />
	</div>
	<ng-template #customAvatar>
		<ng-container *ngIf="data.status !== 'invited' && data.name !== null">
			<div
				class="avatar mr-1 ml-0"
				[ngStyle]="{
					'background-color': data?.profile?.avatar_color
						? data?.profile?.avatar_color
						: data?.avatar_color
						? data?.avatar_color
						: '#f59518'
				}"
			>
				<div class="avatar-content">{{ data.name | initials | slice: O:2 }}</div>
			</div>
		</ng-container>
	</ng-template>
	<div class="cell-line-height text-break">
		<p
			class="font-medium-1 font-weight-bold line-height-1 mb-25 text-primary darken-3"
			id="username_{{ rowindex }}"
		>
			{{ data.name }}
		</p>
	</div>
</div>
