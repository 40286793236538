<div class="slideout-content default-slideout">
	<div
		class="modalsd modal-slide-in sdfade new-user-modal"
		id="modals-slide-in"
	>
		<div class="modal-dialog">
			<!-- form-width -->
			<div class="modal-content add-new-user pt-0">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">
						{{ "UI.k_invite_coworkers" | translate }}
					</h5>
					<button
						type="button"
						id="btnClose"
						name="btnClose"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						(click)="toggleSidebar('add-user-profile', inviteUserForm)"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body">
					<div class="modal-content-header">
						<div class="p-2 mb-2 border-bottom card-text">
							<div class="font-small-3">
								{{ "UI.k_add_email_invite" | translate }}
								{{ "UI.k_multi_separator" | translate }}
							</div>
							
							<div *ngIf="show_user_disable_badge"
							>
								<ngb-alert class="p-75 m-0 mt-1" [type]="'warning'" [dismissible]="false">
									{{ "Message.msg_invites_limit" | translate :
										{
											user_count: active_users_count,
											limit_count: allowed_users
										}
									}}
								</ngb-alert>
							</div>
						</div>
					</div>
					<form #inviteUserForm="ngForm" class="flex-auto-height">
							<div class="modal-content-body flex-1">
              	<ng-scrollbar class="custom-scrollbar section-scrollbar">
									<div
										*ngFor="
											let item of defaultInvites;
											let i = index;
											let first = first;
											let last = last
										"
										class="px-2"
									>
							<!-- @heightIn -->
										<div class="row">
											<div class="form-group col-xs-5 col-md-5 col-xl-5 col-lg-5">
												<label class="form-label" for="txtUsrEmail{{ i }}">{{
													"UI.k_enter_itagent_email" | translate
												}}</label>
												<input
													type="email"
													class="form-control"
													placeholder="it-agent@example.com"
													id="txtUsrEmail{{ i }}"
													name="txtUsrEmail{{ i }}"
													[(ngModel)]="defaultInvites[i].userEmail"
													#TDEmailRef="ngModel"
													required
													[appEmailCheck]="defaultInvites[i].userEmail"
													[class.error]="
														!TDEmailRef.valid && inviteUserForm.submitted
													"
													attr.data-testid="txtUsrEmail{{ i }}"
												/>
												<span
													id="formMsg{{ i }}"
													*ngIf="inviteUserForm.submitted"
													class="invalid-form"
												>
													<small
														class="form-text text-danger"
														*ngIf="
															(TDEmailRef.errors?.invalidemail || !TDEmailRef.valid) && (!TDEmailRef.errors?.required)
														"
														>{{ "UI.k_alert_email_invalid" | translate }}</small
													>
													<small
														class="form-text text-danger"
														*ngIf="
															TDEmailRef.errors?.required && !TDEmailRef.valid
														"
														>{{ "UI.k_field_required" | translate }}</small
													>
													<small class="form-text text-danger"
														*ngIf="TDEmailRef.errors?.max_email">{{"UI.k_email_max_validation"	| translate:{"maxLimit": TDEmailRef.errors?.limit} }}
													</small>
												</span>
											</div>
											<div class="form-group col-xs-5 col-md-5 col-xl-5 col-lg-5">
												<label class="form-label" for="selUsrRole{{ i }}"
													>{{ "UI.k_select_role" | translate }}
												</label>
												<ng-select
													[items]="roleList"
													[(ngModel)]="defaultInvites[i].userRole"
													bindLabel="name"
													bindValue="group_id"
													notFoundText="{{
														'UI.k_no_items_found' | translate
													}}"
													name="selUsrRole{{ i }}"
													id="selUsrRole{{ i }}"
													class="form-comtrol"
													placeholder="{{ 'UI.k_select_role' | translate }}"
													required
													#TDRoleRef="ngModel"
													[class.error]="!TDRoleRef.valid && inviteUserForm.submitted"
													attr.data-testid="selUsrRole{{ i }}"
												>
												</ng-select>
												<span *ngIf="inviteUserForm.submitted" class="invalid-form">
													<small
														class="form-text text-danger"
														*ngIf="
															defaultInvites[i].userRole === -1 ||
															!defaultInvites[i].userRole
														"
														>{{ "UI.k_please_select_role" | translate }}</small
													>
												</span>
											</div>
											<div
												*ngIf="!first"
												class="form-group col-xs-1 col-md-1 col-xl-1 col-lg-1 p-0"
											>
												<button
													id="btnDelete__{{ i }}"
													name="delete__{{ i }}"
													class="btn text-danger px-1 mt-2"
													(click)="deleteItem(i)"
													rippleEffect
												>
													<i
														class="fa-light fa-trash-can mr-25"
														style="font-size: 16px"
													></i>
												</button>
											</div>
										</div>
										<div *ngIf="last" class="form-group">
											<button
												id="btnAdd__{{ i }}"
												name="add__{{ i }}"
												class="btn btn-flat-primary btn-sm"
												(click)="addItem()"
												*ngIf="!show_max_error_msg"
												type="button"
												rippleEffect
												[attr.data-testid]="'btnAddInvite'"
											>
												<i
													class="fa-light fa-plus font-small-3 mr-25 text-primary"
												></i
												>&nbsp;
												<span>{{ "UI.k_add_invite" | translate }}</span>
											</button>
											<div *ngIf="show_max_error_msg" class="form-group">
												<span id="error_msg" class="form-text text-danger">
													{{ 'Message.msg_invites_max_limit' | translate : 
													{
														count: invite_users_max_limit
													}
													}}
												</span>
											</div>
										</div>
									</div>
              </ng-scrollbar>
							</div>
							<div class="modal-content-footer flex-initial">
								<div class="d-flex justify-content-end border-top py-1 px-2">
									<button
										id="btnInvite"
										name="invite"
										class="btn btn-primary"
										type="submit"
										(click)="
											inviteUsers(
												'add-user-profile',
												inviteUserForm,
												defaultInvites
											)
										"
										rippleEffect
										[attr.data-testid]="'btnInvite'"
									>
                  <i class="fa-light fa-envelope"></i>
                  <span>{{ 'UI.k_send_invite' | translate }}</span>
									</button>
								</div>
							</div>
						</form>
				</div>
			</div>
		</div>
	</div>
</div>
