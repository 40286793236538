<div class="d-flex align-items-center" id="{{ key }}-{{ rowindex }}">
	<ng-container *ngIf="subkey === 'img_str'">
		<ng-container *ngIf="data?.img && key !== 'product'">
			<span class="mr-1"><img src="{{ data?.img }}" alt="" width="14" height="14" /></span>
		</ng-container>

		<ng-container *ngIf="data?.icon && key !== 'product'">
			<span class="mr-1"><i class="fa-light fa-{{ data?.icon }}"></i></span>
		</ng-container>

		<ng-container *ngIf="key === 'product' && data?.product !== '-'">
			<span class="mr-1"
				><img [src]="logoMap[data?.product_key]" [alt]="product_name" width="14" height="14"
			/></span>
		</ng-container>

		<ng-container *ngIf="key === 'expiry_date' && data?.expiry_date">
			<span class="mr-1"><i class="fa-light fa-calendar-clock"></i></span>
			<span class="font-weight-bold">
				{{ data["expiry_date"] }}
			</span>
		</ng-container>

		<ng-container *ngIf="key === 'company_name'">
			<span class="profile-name">
				{{ this.data[this.key] }}
			</span>
		</ng-container>

		<ng-container *ngIf="key !== 'company_name' && key !== 'expiry_date'">
			<span class="font-weight-bold">
				{{ this.data[this.key] }}
			</span>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="subkey === 'badge'">
		<div
			class="badge d-flex align-items-center"
			[ngClass]="[
				this.data?.license_type == 'Trial' ? 'badge-light-warning' : '',
				this.data?.license_type == 'Perpetual' ? 'badge-light-primary' : '',
				this.data?.license_type == 'Subscription' ? 'badge-light-danger' : ''
			]"
		>
			<span class="mr-50" *ngIf="data?.license_type == 'Subscription'"
				><img src="/assets/images/error.gif" alt="erro" height="14" width="14"
			/></span>
			{{ this.data?.license_type | translate }}
		</div>
	</ng-container>
	<ng-container *ngIf="subkey === 'payment-badge'">
		<div
			class="badge d-flex align-items-center"
			[ngClass]="[
				this.data?.payment_status === 'k_due' || ['pending', 'payment_due'].includes(this.data?.status)
					? 'badge-light-warning'
					: '',
				this.data?.payment_status == 'k_paid' || ['paid', 'posted'].includes(this.data?.status)
					? 'badge-light-success'
					: '',
				this.data?.payment_status == 'k_pw_expired' || ['not_paid'].includes(this.data?.status)
					? 'badge-light-danger'
					: ''
			]"
		>
			<ng-container *ngIf="this.data?.payment_status">{{
				"UI." + this.data?.payment_status | translate
			}}</ng-container>
			<ng-container *ngIf="this.data?.status">{{ this.data?.status | titlecase }}</ng-container>
		</div>
	</ng-container>
</div>
