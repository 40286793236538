import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";

@Component({
	selector: "app-dns-sla-info-card",
	templateUrl: "./dns-sla-info-card.component.html",
	styleUrls: ["./dns-sla-info-card.component.scss"],
})
export class DnsSlaInfoCardComponent implements OnInit {
	gridColumns: any;
	isBreached: any = false;
	@Input() matricsData: any;
	emptyData: any;
	public is_achieved :any;
	
	constructor(private _translateService: TranslateService) {
		this.emptyData = {
			msg: this._translateService.instant("Error.err_data_not_available"),
			description: undefined,
		};
	}

	ngOnInit(): void {
		this.gridColumns = [
			{
				label: "Serial Number",
				key: "sr_no",
				db_col: "requester__sr_no",
				pre_selected: true,
				isEditable: false,
				filter_col: "requester__sr_no",
			},
			{
				label: "Start Time",
				key: "start_time",
				db_col: "requester__start_time",
				pre_selected: true,
				isEditable: false,
				filter_col: "requester__start_time",
			},
			{
				label: "End Time",
				key: "end_time",
				db_col: "requester__end_time",
				pre_selected: true,
				isEditable: false,
				filter_col: "requester__end_time",
			},
			{
				label: "Business Time",
				key: "biz_time",
				db_col: "requester__business_time",
				pre_selected: true,
				isEditable: false,
				filter_col: "requester__business_time",
			},
			{
				label: "Status",
				key: "status_name",
				db_col: "requester__status_name",
				pre_selected: true,
				isEditable: false,
				filter_col: "requester__status_name",
			},
		];
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.matricsData && this.matricsData.length > 0) {
			this.matricsData.forEach((matricItem) => {
				if (matricItem.metrices && matricItem.metrices.length > 0) {
					matricItem.metrices.forEach((metrice) => {
						metrice.is_event_collapse = false;
						if (metrice.metric_events && metrice.metric_events.length > 0) {
							metrice.metric_events.forEach((matric, index) => {
								matric.sr_no = index + 1;
								if (!matric.end_time) {
									matric.end_time = "-";
								}
								matric.biz_time = matric.biz_time + " " + this._translateService.instant("UI.k_min");
							});
						}
						if (metrice.end_time) {
							metrice.completed_time = {
								sign: this.isDatePassed(metrice.end_time) ? "+ " : "",
								days: this.getDay(metrice.start_time, metrice.end_time, true),
								time: this.getTime(metrice.start_time, metrice.end_time, true),
								colorName: this.getColorName(metrice, metrice.end_time),
							};
						}
					});
				}
			});
		}
	}

	getRemainTime(startTime, endtime, is_completed: boolean = false) {
		let current = is_completed ? startTime : moment(new Date()).format("MMM DD, YYYY hh:mm A");
		const total = Date.parse(endtime) - Date.parse(new Date(current).toString());
		let seconds: any = Math.abs(Math.trunc((total / 1000) % 60));
		let minutes: any = Math.abs(Math.trunc((total / 1000 / 60) % 60));
		let hours: any = Math.abs(Math.trunc((total / (1000 * 60 * 60)) % 24));
		let days: any = Math.abs(Math.trunc(total / (1000 * 60 * 60 * 24)));

		if (days < 10) {
			days = "0" + days;
		}

		if (hours < 10) {
			hours = "0" + hours;
		}

		if (minutes < 10) {
			minutes = "0" + minutes;
		}

		if (seconds < 10) {
			seconds = "0" + seconds;
		}
		return {
			days,
			hours,
			minutes,
			seconds,
		};
	}

	getPassTime(startTime, endtime, is_completed: boolean = false) {
		let current = is_completed ? startTime : moment(new Date()).format("MMM DD, YYYY hh:mm A");
		let currentDate = Math.abs(Number((new Date(current).getTime() / 1000).toFixed(0)));
		let futureDate = Math.abs(Number((new Date(endtime).getTime() / 1000).toFixed(0)));
		let diff = currentDate - futureDate;
		let days: any = Math.abs(Math.trunc(diff / 86400));
		let hours: any = Math.abs(Math.trunc(diff / 3600) % 24);
		let minutes: any = Math.abs(Math.trunc(diff / 60) % 60);
		let seconds: any = diff % 60;

		if (days == 0) {
			days = "0" + days;
		}

		if (hours < 10) {
			hours = "0" + hours;
		}

		if (minutes < 10) {
			minutes = "0" + minutes;
		}

		if (seconds < 10) {
			seconds = "0" + seconds;
		}
		return {
			days,
			hours,
			minutes,
			seconds,
		};
	}

	isDatePassed(date) {
		const today = new Date();
		today.setHours(0, 0, 0, 0);
		return new Date(moment(new Date()).format("MMM DD, YYYY hh:mm A")) > new Date(date);
	}

	getDay(startTime, expectedEndTime, is_completed: boolean = false, completed_time = undefined) {
		if (is_completed && completed_time) {
			return completed_time.days;
		} else {
			let days = this.isDatePassed(expectedEndTime)
				? this.getPassTime(startTime, expectedEndTime, is_completed).days
				: this.getRemainTime(startTime, expectedEndTime, is_completed).days;
			if (days == "0") {
				return "";
			} else {
				return days;
			}
		}
	}

	getTime(startTime, expectedEndTime, is_completed: boolean = false, completed_time = undefined) {
		if (is_completed && completed_time) {
			return completed_time.time;
		} else {
			let hours, minutes;
			if (this.isDatePassed(expectedEndTime)) {
				let time = this.getPassTime(startTime, expectedEndTime, is_completed);
				hours = time.hours;
				minutes = time.minutes;
			} else {
				let time = this.getRemainTime(startTime, expectedEndTime, is_completed);
				hours = time.hours;
				minutes = time.minutes;
			}
			if (minutes == "00") {
				this.isBreached = true;
			}
			return `${hours}:${minutes}`;
		}
	}

	getChartColor(color) {
		if (color === "danger") {
			return "#EA5455";
		}
		if (color === "warning") {
			return "#FF9F43";
		}
		if (color === "success") {
			return "#28C76F";
		}
		if (color === "cancel") {
			return "#B4B6BD";
		}

		return "#28C76F";
	}

	getPercent(startTime, expectedEndTime) {
		if (this.isBreached) {
			return 100;
		} else {
			const start: any = new Date(startTime);
			const end: any = new Date(expectedEndTime);
			const current: any = new Date(moment(new Date()).format("MMM DD, YYYY hh:mm A"));
			const range = end - start;
			const diff = Math.max(0, end - current);
			let percent = 100 - (100 * diff) / range;
			if (percent >= 100) {
				this.isBreached = true;
				return 100;
			} else {
				return percent;
			}
		}
	}

	getColorName(metricData, is_completed: boolean = false, completed_time = undefined) {
		const elapsed_percentage = metricData?.elapsed_percentage;
		if (is_completed && completed_time) {
			return completed_time.colorName;
		} else if (metricData?.status == 4) {
			return "cancel";
		} else {
			if (metricData.is_breached) {
				return "danger";
			} else {
				if (elapsed_percentage > 75) {
					return "warning";
				} else {
					return "success";
				}
			}
		}
	}

	toggleGrid(matricesItem) {
		matricesItem.is_event_collapse = !matricesItem.is_event_collapse;
	}

	getRunningStatusTitle = (obj) => {
		// 1. Start_time is nUll -> Not yet Started
		// 2. if is_breached is False and End_time is null and status is in-procress/pause ---> In progress
		// 3. if is_breached is False and status is end and End_time ---> Achive
		// 4. if is_breached is True -> Brach
		// SLA_METRIC_MONITOR_DICT = {1: 'Running', 2: 'Pause', 3: 'End', 4: 'Cancelled',  5: 'Exclude'}
		let status = 1;
		let status_name = "";
		let color_name = "";
		let icon = "";
		let name = "";
		this.is_achieved = !obj?.is_breached && obj?.end_time && obj?.status === 3
		if (!obj?.start_time && obj?.status === 4) {
			status_name = this._translateService.instant("UI.k_cancelled");
			color_name = "#AC67F0";
			status = 5;
			name = this._translateService.instant("UI.k_cancelled");
		} else if (!obj?.start_time) {
			status_name = this._translateService.instant("UI.k_not_yet_start");
			color_name = "#00CFE8";
			status = 1;
			name = this._translateService.instant("UI.k_not_yet_start");
		} else if (!obj?.is_breached && obj?.end_time && obj?.status === 3) {
			status_name = this._translateService.instant("UI.k_achieved");
			color_name = "#28C76F";
			status = 2;
			icon = "fa fa-check";
			name = this._translateService.instant("UI.k_achieved");
		} else if (obj?.is_breached) {
			status_name = this._translateService.instant("UI.k_breached");
			color_name = "#EA5455";
			status = 3;
			icon = "fa-exclamation-triangle";
			if(obj?.end_time){
				name = this._translateService.instant("UI.k_breached");
			}else{
				name = this._translateService.instant("UI.k_running");
			}			
		} else if (obj?.status === 4) {
			status_name = this._translateService.instant("UI.k_cancelled");
			color_name = "#AC67F0";
			status = 5;
			name = this._translateService.instant("UI.k_cancelled");
		} else if (obj?.status === 5) {
			status_name = this._translateService.instant("UI.k_exclude");
			color_name = "#AC67F0";
			status = 6;
			name = this._translateService.instant("UI.k_exclude");
		} else {
			status_name = this._translateService.instant("UI.k_left");
			color_name = "#FF9F43";
			status = 4;
			name = this._translateService.instant("UI.k_running");
		}
		return {
			status,
			status_name,
			color_name,
			icon,
			name,
		};
	};

	getTimerData(metricData: any): any {
		const targetTime = metricData?.metric_target;
		const business_elapsed = metricData?.biz_elapsed;
		// Getting remaining time
		const remaining_time: number = targetTime - business_elapsed;
		if (remaining_time > 0) {
			const timer_data = this.min_to_day_hours_minutes(remaining_time);
			return timer_data;
		} else if (remaining_time < 0) {
			const temp_breached_time = metricData?.total_elapsed - targetTime;
			const timer_data = this.min_to_day_hours_minutes(temp_breached_time);
			timer_data["is_breached"] = true;
			return timer_data;
		} else {
			return this.min_to_day_hours_minutes(0);
		}
	}

	min_to_day_hours_minutes(minutes: number) {
		const days = Math.floor(minutes / 1440);
		const hours = Math.floor((minutes % 1440) / 60);
		const min = minutes % 60;
		return { days: days, hours: hours, minutes: min };
	}
}
