import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { TranslateService } from "@ngx-translate/core";
import { InfinityModuleIDs } from "app/common/dns/types/module_type";
import { customSearch } from "app/common/utils/utils";
import { ChangeViewService } from "app/servicedesk/change-management/services/change-view.service";
import { FlatpickrOptions } from "ng2-flatpickr";
import { ToastrService } from "ngx-toastr";
import { catchError, map, throwError } from "rxjs";

@Component({
  selector: 'app-dns-create-task',
  templateUrl: './dns-create-task.component.html',
  styleUrls: ['./dns-create-task.component.scss']
})
export class DnsCreateTaskComponent implements OnInit {
  @Input() options: any = [];
  @Input() change_data: any = {};
  @Input() dueInDate:any;
  @Input() task_id = "-1"
  @Input() taskTag: any
  @Input() taskDataEdit : any ;
  public taskDataEditTemp : any;
  @Input() template_task:string;
  @Output() onHideTask = new EventEmitter(false);
  @ViewChild("datepicker") dueDate: FlatpickrOptions;
  @Input() dueindays : number;
  public task_obj : any = {}
  public disableSaveBtn: boolean = false;
  public task_title = this._translateService.instant("UI.k_task_add");
  public priority: any = []
  public status: any = []
  public flag = false
  module_id = InfinityModuleIDs?.CHANGE;
  public basicDateOptions: FlatpickrOptions = {
      altInput: true,
      // dateFormat: "%b %d, %Y",
  // altFormat: "M d, Y",
  altInputClass: "form-control flat-picker flatpickr-input",  
  }
  public basicDateTimeOptions: FlatpickrOptions = {
      altInput: true,
      enableTime:true,
  altInputClass: "form-control flat-picker flatpickr-input",  
  }
  users_list: any = [];
  disableData: any = [];
  public modules = {
      formula: true,
      toolbar: [      
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }]
      ]
    };
constructor(private _coreSidebarService: CoreSidebarService,
  private _toastr: ToastrService,
  private _translateService: TranslateService,
  private _changeService: ChangeViewService
) {}

  ngOnInit(): void {
      this.priority = this.options?.priority
      this.status = this.options?.task_status
      this.users_list = this.options?.users
      if (this.dueInDate == "showDueInDate"){
          this.flag=true
          this.status = this.options.task_status.filter((status) => status.state_id === 1 || status.state_id === 2);
      }
      else{
          this.task_obj["ref_id"] = this.change_data?.change_id
          this.task_obj["ref_display_id"] = this.change_data?.display_id
          this.task_obj["module_id"] = this.module_id
          this.flag=false
      }
      if (this.priority?.length > 0){
          this.task_obj["priority"] = this.priority[0]
      }
      if (this.status?.length > 0){
          this.task_obj["status"] = this.status[0]
      }
      if (this.change_data?.due_date){
          this.basicDateTimeOptions["maxDate"] = this.change_data?.due_date;
      }
      if (this.task_id != "-1"){
          this.task_title = this._translateService.instant("UI.k_task_edit");
          this.getTaskData()
      }
      if (this.taskDataEditTemp.name?.length>2){
          this.task_title = this._translateService.instant("UI.k_task_edit");
          this.editTask()
      }
      if (this.dueindays) { this.task_obj.due_date = (this.dueindays < 3) ? 1 : 3; }
      for (let i = 1; i <= this.dueindays; i++) {
          this.disableData.push({
              id: i,
              name: i.toString(),
          });
          }
}
toggleSidebar = (name): void => {
  this._coreSidebarService.getSidebarRegistry(name).close();
  this.onHideTask.emit()
  this.taskDataDel()
  };
  taskDataDel(){
  if (this.taskDataEdit){
  delete this.taskDataEdit.ISchecked;
  }
  }

getTaskData() {
    this._changeService.getChangeTask(this.task_id, {}).subscribe(
    (response) => {
        if (response) {
            this.task_obj = response?.data
        }
    },
    (error) => {
        //todo
    }
    ); 
}

editTask(){
  this.task_obj=this.taskDataEditTemp

}

SaveTask = (data) => {
    if (data.form.valid === true) {
      if (this.task_obj.due_date instanceof Array) {
        this.task_obj.due_date = this.task_obj.due_date[0];
      //   this.task_obj.due_date = this.task_obj.due_date.toDateString()
      }
      this.disableSaveBtn = true
      if(this.template_task==='template_task'){
          this.onHideTask.emit(this.task_obj)
          setTimeout(() => {
              this.toggleSidebar('create-task-sidebar');
            });
      }
      else{
          this._changeService.saveChangeTask(this.task_obj).pipe(
          map((response: any) => {
              this.disableSaveBtn = false
              if (response) {
                  if (response["status"] == "success") {
                      this.task_obj = {};
                      this._toastr.success(
                          this._translateService.instant(
                              response["message"]
                          ),
                          this._translateService.instant(
                              "Message.msg_save_success"
                          ),
                          {
                              toastClass: "toast ngx-toastr",
                              closeButton: true,
                              positionClass: "toast-top-right",
                          }
                      );
                      this.toggleSidebar('create-task-sidebar')
                  } 
                  else {
                      this._toastr.error(
                          this._translateService.instant(
                              response["message"], {existing_rule: response?.data?.existing_rule}
                          ),
                          this._translateService.instant(
                              "Error.err_save_failed"
                          ),
                          {
                              toastClass: "toast ngx-toastr",
                              closeButton: true,
                              positionClass: "toast-top-right",
                          }
                      );
                  }   
              }
              // Success
              // this.clear=true
          }),
          catchError((error) => {
              this._toastr.error(
                  this._translateService.instant(error.error.error.message),
                  this._translateService.instant("Error.err_save_failed"),
                  {
                      toastClass: "toast ngx-toastr",
                      closeButton: true,
                      positionClass: "toast-top-right",
                  }
              );
              this.disableSaveBtn = false
              return throwError(error.statusText);
          })
          ).subscribe();
      }
    }
  };

onStatusChange = (data) => {
  this.task_obj.status = data;
};

statusBadgeChange = (data) => {
  this.task_obj[data["field"]] = data?.event ? data.event : {};
};

customSearchFn(term: string, item: any) {
  return customSearch(term, item, "full_name", "email");
}

  onAssigneeChange = ($event) => {
      this.task_obj["assignee_profile"] = {
          "profile_id": $event?.profile_id,
          "name": $event?.full_name,
          "full_name": $event?.full_name, 
          "email": $event?.email,
      }
  }
  ngOnChanges(changes) {
      this.taskDataEditTemp = Object.assign({}, this.taskDataEdit);
  }
  
ngOnDestroy() {
      if(!(this.dueInDate == "showDueInDate")){
      this.dueDate.flatpickr.close()
      }
}
}
