import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/base/auth/services/auth.service';
import { RequestViewService } from 'app/servicedesk/request/services/request-view.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-select-team',
  templateUrl: './select-team.component.html',
  styleUrls: ['./select-team.component.scss']
})
export class SelectTeamComponent implements OnInit {

  @Input() selectTeamData: any;
  @Input() selectTeamMemberData: any;
  @Input() newTeamHasCreated: any;
  @Output() onHideQuickEdit = new EventEmitter<any>();
  @Output() createNewHandler = new EventEmitter<any>();
  @Output() newTeamCreatedHandler = new EventEmitter<any>();
  @Output() toggleSidebarClose = new EventEmitter<any>();
  @ViewChild('inputVal') inputVal:ElementRef;
  showResln: boolean = false;
  
  isExisting: boolean = true;
  checkedItemList: any = [];
  selectedData: any;
  isDisabled: boolean = true;
  defaultVal: any = [];
  listData: any = [];
  searchVal: any;
  isCreatePayload: any;
  isEnableDropDown: boolean = true

  constructor(
    private _requestViewService: RequestViewService,
		private _translateService: TranslateService,
		private _coreSidebar: CoreSidebarService,
		private _router: Router,
		private _authService: AuthService,
		private _toastr: ToastrService,
    public fb: FormBuilder
  ) { 
    //console.log("new team", this.newTeamHasCreated);
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.isExisting = true;
  }

  createNeGroupHandler(){
    this.isExisting = false;
    this.createNewHandler.emit();
  }

  backStepHandler(){
    this.isExisting = true;
  }

  onSubmitHandler(evt: any){
    this.isExisting = true;
    let payLoad = {
      "teamName": this.inputVal.nativeElement.value,
      "selectedTeamMember": this.selectedData
    };
    this.isCreatePayload = payLoad;
    this.defaultVal = [this.inputVal.nativeElement.value];
    // console.log("this.defaultVal", this.defaultVal);
    this.listData = payLoad.selectedTeamMember;
    // this.newTeamCreatedHandler.emit(payLoad);
  }

  selectAll() {
    // this.heroForm.get("selectedCitiesIds").setValue(this.cities);
  }

  unselectAll() {
    // this.heroForm.get("selectedCitiesIds").setValue([]);
  }

  searchHandler(term: string, item) {
    item.name = item.name.replace(',','');
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1;
  }

  changeHandler(data: any){
    //console.log(data.target.value)
    if(data.target.value){
      this.isEnableDropDown = false;
    }else{
      this.isEnableDropDown = true;
    }
  }

  onGroupItemChange(item: any){
    this.selectedData = item;
    // const valueInput = this.inputVal.nativeElement.value;
    // if(this.selectedData.length && valueInput){
    //   this.isDisabled = false;
    // }else{
    //   this.isDisabled = true;
    // }
  }

  selectedTeamHandler(isChecked: any, item){
    item.checked = isChecked;
    // console.log("event", evt);
    // console.log("item", item);
    // console.log("selectTeamMemberData", this.selectTeamMemberData);
    // this.checkedItemList = this.selectTeamMemberData.filter(itm => itm.checked == true);
    // console.log("checked data", this.checkedItemList);
  }

  createNewSubmitHandler(){
    this.isExisting = true;
    this._coreSidebar.getSidebarRegistry('select-team').toggleOpen();
    this.toggleSidebarClose.emit(false)
    const valueInput = this.inputVal.nativeElement.value;
    this.isCreatePayload.teamName = this.inputVal.nativeElement.value;
    //console.log("isCreatePayload", this.isCreatePayload);
  }

  cancelNewTeamHandler(){
    this.isExisting = true;
    this._coreSidebar.getSidebarRegistry('select-team').toggleOpen();
    this.toggleSidebarClose.emit(false)
  }

  

}
