import {Directive, ElementRef, HostListener, Input, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[dns-enter-submit]'
})
export class EnterSubmitDirective implements OnInit{
  /**
   * elementList: pass the list of element which you want to bypass enter button event.
   */
  @Input("elementList") elementName = ["BUTTON", "TEXTAREA"];
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngOnInit() {
  }

  @HostListener('keydown', ['$event']) //keydown, not keyup
  onKeyDown(event: any) {
    if (event.keyCode === 13 && !this.elementName.includes(event.target?.tagName)) { // 13 = enter
      event.preventDefault();
      return false;
    }
  }
}
