<div class="slideout-content default-slideout" id="modals-slide-in">
	<div class="modal-dialog">
		<div class="modal-content add-new-user pt-0">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">
					{{ typeMapping?.[type_id] | translate }}
				</h5>
				<div class="d-flex">
					<button
						type="button"
						id="btnClose"
						name="btnClose"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						(click)="toggleSidebar('add-task')"
						[attr.data-testid]="'btnClose'" 
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
			</div>
			<div class="modal-body flex-1">
				<form #newTaskForm="ngForm" class="flex-auto-height">
					<ng-scrollbar class="custom-scrollbar section-scrollbar">
						<div
							class="input-group"
							style="width: 98%; margin: 0 auto; margin-top: 10px; margin-bottom: 10px"
						>
							<div class="input-group-prepend view-task-title-section">
								<span class="input-group-text" id="title">{{ "UI.k_title" | translate }}&nbsp;*</span>
							</div>
							<input
								type="text"
								class="form-control"
								name="txtTitle"
								id="txtTitle"
								placeholder="Type title here"
								aria-label="txtTitle"
								aria-describedby="basic-addon1"
								required
								minlength="3"
								maxlength="1000"
								[(ngModel)]="task_obj.name"
								#submitVlanNameInput="ngModel"
								[class.error]="submitVlanNameInput.invalid && newTaskForm.submitted"
								[appWhiteSpaceCheck]="task_obj.name"
							/>
						</div>
						<span class="invalid-form" *ngIf="newTaskForm.submitted && submitVlanNameInput.invalid">
							<small class="col-12 form-text text-danger" *ngIf="submitVlanNameInput.errors.required"
								>{{ "UI.k_field_required" | translate }}
							</small>
							<small
								class="col-12 form-text text-danger"
								*ngIf="submitVlanNameInput.errors.minlength || submitVlanNameInput.errors.maxlength"
								>{{ "UI.k_title_name_validation" | translate }}
							</small>
						</span>
						<div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
							<div class="row">
								<div class="col-xs-4 col-md-4 col-xl-4 col-lg-4">
									<app-status-picker
										[inputConfig]="{
											isTextMode: true,
											required: true
										}"
										[options]="options?.task_status"
										(setStatus)="onStatusChange($event)"
										[value]="task_obj?.status?.id"
									>
									</app-status-picker>
								</div>
								<div class="col-xs-4 col-md-4 col-xl-4 col-lg-4" style="padding: 0">
									<div class="panel-edit-select-box">
										<label>{{ "UI.k_priority" | translate }}</label>
										<app-select-badge
											[inputConfig]="{
												hideLabel: true,
												inputOptions: options?.priority,
												id: 'priority',
												model: this.task_obj?.priority?.id
											}"
											(onValueChange)="statusBadgeChange($event)"
										></app-select-badge>
									</div>
								</div>
								<div class="col-xs-4 col-md-4 col-xl-4 col-lg-4">
									<div class="panel-edit-select-box">
										<label for="taskDueDate">{{ "UI.k_due_date" | translate }} </label>
										<div class="input-group custom-input-group">
											<ng2-flatpickr
												#datepicker
												[config]="basicDateTimeOptions"
												name="Basic Date Picker"
												id="txtDueDate"
												[(ngModel)]="task_obj.due_date"
												[setDate]="task_obj.due_date"
												placeholder="{{ 'UI.k_placeholder_yyyy_mm_dd' | translate }}"
											>
											</ng2-flatpickr>
											<div class="input-group-append">
												<button
													class="btn btn-outline-secondary feather icon-calendar"
													(click)="datepicker.flatpickr.toggle()"
													type="button"
													rippleEffect
												></button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-xs-12 col-md-12 col-xl-12 col-lg-12" style="margin-bottom: 10px">
							<div class="row">
								<div class="col-xs-4 col-md-4 col-xl-4 col-lg-4">
									<div class="panel-edit-select-box">
										<label for="selAssignee" class="form-label">{{
											"UI.k_assignee" | translate
										}}</label>
										<ng-select
											[items]="options?.users"
											[searchable]="true"
											bindLabel="full_name"
											[searchFn]="customSearchFn"
											bindValue="profile_id"
											name="selAssignee"
											id="selAssignee"
											placeholder="{{ 'UI.k_select' | translate }}"
											class=""
											[(ngModel)]="task_obj.assignee"
											(change)="onAssigneeChange($event)"
										>
											<ng-template
												ng-option-tmp
												let-item="item"
												let-index="index"
												let-search="searchTerm"
											>
												<span class="d-flex">
													<div *ngIf="item?.profile_image">
														<div class="avatar mr-50 ml-25">
															<span class="avatar-content">
																<img
																	class="rounded-circle"
																	src="{{ item.profile_image }}"
																	alt="User"
																	width="32"
																	height="32"
																/>
															</span>
														</div>
													</div>
													<div *ngIf="!item?.profile_image">
														<div
															class="avatar mr-50 ml-25"
															[ngStyle]="{
																'background-color': item?.avatar_color
																	? item?.avatar_color
																	: '#f59518'
															}"
															style="align-content: center; width: 32px; height: 32px"
														>
															<div class="avatar-content">
																{{ item.full_name | initials | slice: 0:2 }}
															</div>
														</div>
													</div>

													<div class="cell-line-height">
														<p class="font-medium-1 font-weight-bold line-height-1 mb-0">
															{{ item?.full_name | slice: 0:28 }}
															{{ item?.full_name?.length > 28 ? ".." : "" }}
														</p>
														<span class="text-muted font-small-2">
															{{ item?.email | slice: 0:28 }}
															{{ item?.email.length > 28 ? ".." : "" }}</span
														>
													</div>
												</span>
											</ng-template>
										</ng-select>
									</div>
								</div>
							</div>
						</div>

						<div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
							<div class="row">
								<div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
									<div class="panel-edit-select-box">
										<label style="font-size: 14px; font-weight: 600">
											{{ aboutTaskMapping?.[type_id] | translate}}&nbsp;*
										</label>
									</div>
									<div class="p-0 card border quill-card">
										<quill-editor
											[(ngModel)]="task_obj.description"
											id="qlDescription"
											name="qlDescription"
											#qlDescription
											[trimOnValidation]="true"
											[appQuillValidator]="qlDescription"
											[appQuillForm]="newTaskForm"
											placeholder="{{ 'UI.k_add_content' | translate }}"
											[minLength]="3"
											[class.error]="qlDescription?.errors && newTaskForm.submitted"
											[required]="true"
										>
											<div
												quill-editor-toolbar
												class="border-0 border-bottom px-0"
												style="padding: 0px"
											>
												<div class="border-bottom editor-item-flex">
													<span
														class="ql-formats"
														style="margin: 0; border-right: 1px solid #e5e6f0"
													>
														<select class="ql-header">
															<option value="1">Heading 1</option>
															<option value="2">Heading 2</option>
															<option value="3">Heading 3</option>
															<option value="">Normal</option>
														</select>
													</span>
													<span
														class="ql-formats"
														style="margin: 0; border-right: 1px solid #e5e6f0"
													>
														<button
															id="qlBoldNoteEmail-Inc"
															name="qlBold"
															class="ql-bold"
															title="{{ 'UI.k_bold' | translate }}"
														></button>
													</span>
													<span
														class="ql-formats"
														style="margin: 0; border-right: 1px solid #e5e6f0"
													>
														<button class="ql-align" value=""></button>
														<button class="ql-align" value="center"></button>
														<button class="ql-align" value="right"></button>
														<button class="ql-align" value="justify"></button>
													</span>
												</div>
											</div>
										</quill-editor>
									</div>
									<div>
										<span
											class="row col-12 error"
											*ngIf="newTaskForm.submitted && qlDescription?.errors"
											class="invalid-form"
										>
											<small
												*ngIf="qlDescription?.errors?.minLengthError"
												class="form-text text-danger xs-font"
												>{{ "Error.err_min_3_character" | translate }}</small
											>
											<small
												*ngIf="qlDescription?.errors?.requiredError?.empty"
												class="form-text text-danger xs-font"
											>
												{{ "UI.k_field_required" | translate }}
											</small>
										</span>
									</div>
								</div>
							</div>
						</div>
					</ng-scrollbar>
					<div class="modal-btn-group flex-initial border-top sticky-footer">
						<div class="d-flex justify-content-end stepper-btn flex-initial feature-cancel">
							<button
								class="btn btn-sm btn-light btn-next ml-1 d-cancel mr-1"
								rippleEffect
								(click)="toggleSidebar('add-task')"
								style="background: transparent"
								id="btTaskCancel"
								name="btTaskCancel"
								type="button"
								[attr.data-testid]="'btTaskCancel'" 
							>
								<span class="align-middle d-sm-inline-block"> {{ "UI.k_cancel" | translate }}</span>
							</button>
							<button
								*ngIf="
									!(release_data?.is_exit && release_data?.basic_info?.status?.state_id == 5) &&
									release_data.basic_info?.state?.id != 6
								"
								[disabled]="disableSaveBtn"
								type="submit"
								class="btn btn-primary btn-next"
								(click)="SaveTask(newTaskForm)"
								rippleEffect
								id="btTaskSave"
								name="btTaskSave"
								[attr.data-testid]="'btTaskSave'" 
							>
								<i class="fa-light fa-floppy-disk align-middle mr-50 ml-0"></i>
								<span class="align-middle d-sm-inline-block d-none">{{
									"UI.k_submit" | translate
								}}</span>
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
