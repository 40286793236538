import { Injectable } from '@angular/core';
import * as  getStatusData  from "../../../../assets/json-response/status.json"
import { of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SelectPickerFeatureService {

  constructor() { }


  getStatusDetails() {
    return of(getStatusData)
}

}
