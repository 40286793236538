<!-- NAVBAR -->
<ng-container *ngIf="!isAdminPortal && !isSelfServicePortal && !isInfraonAdmin && !isSupportPortal">
	<ng-container *ngTemplateOutlet="navbar"></ng-container>
</ng-container>
<!--/ NAVBAR -->
<!-- Admin-Portal NAVBAR -->
<ng-container *ngIf="isInfraonAdmin">
	<ng-container *ngTemplateOutlet="adminNavbar"></ng-container>
</ng-container>
<ng-container *ngIf="isAdminPortal">
	<ng-container *ngTemplateOutlet="adminNavbar"></ng-container>
</ng-container>
<ng-container *ngIf="isSupportPortal">
	<ng-container *ngTemplateOutlet="supportNavbar"></ng-container>
</ng-container>
<!-- Admin-Portal NAVBAR -->
<!-- Self Service NAVBAR -->
<ng-container *ngIf="isSelfServicePortal">
	<ng-container *ngTemplateOutlet="selfServiceNavbar"></ng-container>
</ng-container>
<!-- Self Service NAVBAR -->
<!-- MENU -->
<ng-container *ngIf="!isAdminPortal && !isSelfServicePortal && !isInfraonAdmin">
	<ng-container *ngTemplateOutlet="menu"></ng-container>
</ng-container>
<!--/ MENU -->
<!-- Admin-Portal MENU -->
<ng-container *ngIf="isAdminPortal && !isSupportPortal">
	<ng-container *ngTemplateOutlet="adminMenu"></ng-container>
</ng-container>
<!-- Admin-Portal MENU -->
<!-- Admin-Portal MENU -->
<ng-container *ngIf="isInfraonAdmin">
	<ng-container *ngTemplateOutlet="infraonAdminMenu"></ng-container>
</ng-container>
<!-- Admin-Portal MENU -->
<!-- Support Portal Menu-->
<ng-container *ngIf="isSupportPortal">
	<ng-container *ngTemplateOutlet="infraonSupportMenu"></ng-container>
</ng-container>

<ng-container *ngIf="isMspPortal">
	<ng-container *ngTemplateOutlet="infraonMspMenu"></ng-container>
</ng-container>
<!-- APP-CONTENT -->
<ng-container *ngIf="!isSelfServicePortal">
	<div class="app-content content" [ngClass]="submenu === true ? 'open-submenu' : ''">
		<!-- <ng-scrollbar class="custom-scrollbar"> -->
		<div class="inner-main-content">
			<div class="content-overlay"></div>
			<div class="header-navbar-shadow" *ngIf="!coreConfig?.layout?.navbar?.hidden"></div>
			<!-- CONTENT -->
			<content></content>
			<!--/ CONTENT -->
		</div>
		<!-- </ng-scrollbar> -->
	</div>
</ng-container>
<ng-container *ngIf="isSelfServicePortal">
	<div class="app-content content self-service-content" [ngClass]="submenu === true ? 'open-submenu' : ''">
		<div class="inner-main-content">
			<div class="content-overlay"></div>
			<div class="header-navbar-shadow" *ngIf="!coreConfig?.layout?.navbar?.hidden"></div>
			<!-- CONTENT -->
			<content></content>
			<!--/ CONTENT -->
		</div>
	</div>
</ng-container>
<!--/ APP-CONTENT -->
<!-- Self Service FOOTER -->
<ng-container *ngIf="isSelfServicePortal">
	<ng-container *ngTemplateOutlet="selfServiceFooter"></ng-container>
</ng-container>
<!-- End of Self Service FOOTER -->
<!-- FOOTER -->
<ng-container *ngIf="!isSelfServicePortal">
	<ng-container *ngTemplateOutlet="footer"></ng-container>
</ng-container>
<!--/ FOOTER -->

<!-- PARTIALS ---------------------------------------------------------------------------------------- -->

<!-- NAVBAR -->
<ng-template #navbar>
	<app-navbar
		*ngIf="!coreConfig?.layout?.navbar?.hidden"
		[ngClass]="
			coreConfig?.layout?.navbar?.customBackgroundColor === true &&
			!coreConfig?.layout?.navbar?.backgroundColor?.includes('#')
				? coreConfig?.layout?.navbar?.background +
				  ' ' +
				  coreConfig?.layout?.navbar?.type +
				  ' ' +
				  coreConfig?.layout?.navbar?.backgroundColor
				: coreConfig?.layout?.navbar?.background + ' ' + coreConfig?.layout?.navbar?.type
		"
		[ngStyle]="{
			'background-color': coreConfig?.layout?.navbar?.backgroundColor.includes('#')
				? coreConfig?.layout?.navbar?.backgroundColor
				: '#fff'
		}"
		class="header-navbar navbar navbar-expand-lg align-items-center navbar-shadow floating-nav"
	>
	</app-navbar>
</ng-template>
<!--/ NAVBAR -->
<ng-template #adminNavbar>
	<app-navbar
		*ngIf="!coreConfig?.layout?.navbar?.hidden"
		[ngClass]="
			coreConfig?.layout?.navbar?.customBackgroundColor === true &&
			!coreConfig?.layout?.navbar?.backgroundColor.includes('#')
				? coreConfig?.layout?.navbar?.background +
				  ' ' +
				  coreConfig?.layout?.navbar?.type +
				  ' ' +
				  coreConfig?.layout?.navbar?.backgroundColor
				: coreConfig?.layout?.navbar?.background + ' ' + coreConfig?.layout?.navbar?.type
		"
		[ngStyle]="{
			'background-color': coreConfig?.layout?.navbar?.backgroundColor.includes('#')
				? coreConfig?.layout?.navbar?.backgroundColor
				: '#fff'
		}"
		class="header-navbar navbar navbar-expand-lg align-items-center navbar-shadow admin-navbar"
	>
	</app-navbar>
</ng-template>
<ng-template #supportNavbar>
	<app-navbar
		*ngIf="!coreConfig?.layout?.navbar?.hidden"
		[ngClass]="
			coreConfig?.layout?.navbar?.customBackgroundColor === true &&
			!coreConfig?.layout?.navbar?.backgroundColor.includes('#')
				? coreConfig?.layout?.navbar?.background +
				  ' ' +
				  coreConfig?.layout?.navbar?.type +
				  ' ' +
				  coreConfig?.layout?.navbar?.backgroundColor
				: coreConfig?.layout?.navbar?.background + ' ' + coreConfig?.layout?.navbar?.type
		"
		[style.display]="style$ | async"
		[ngStyle]="{
			'background-color': coreConfig?.layout?.navbar?.backgroundColor.includes('#')
				? coreConfig?.layout?.navbar?.backgroundColor
				: '#fff'
		}"
		class="header-navbar navbar navbar-expand-lg align-items-center card-bg navbar-shadow"
	>
	</app-navbar>
</ng-template>
<ng-template #selfServiceNavbar>
	<app-navbar
		*ngIf="!coreConfig?.layout.navbar?.hidden"
		[ngClass]="
			coreConfig?.layout.navbar?.customBackgroundColor === true &&
			!coreConfig?.layout.navbar?.backgroundColor.includes('#')
				? coreConfig?.layout.navbar?.background +
				  ' ' +
				  coreConfig?.layout.navbar?.type +
				  ' ' +
				  coreConfig?.layout.navbar?.backgroundColor
				: coreConfig?.layout.navbar?.background + ' ' + coreConfig?.layout.navbar?.type
		"
		[ngStyle]="{
			'background-color': coreConfig?.layout.navbar?.backgroundColor.includes('#')
				? coreConfig?.layout.navbar?.backgroundColor
				: '#fff'
		}"
		class="header-navbar navbar navbar-expand-lg align-items-center navbar-shadow self-service-navbar"
	>
	</app-navbar>
</ng-template>
<!-- MENU -->
<ng-template #menu>
	<core-sidebar
		name="menu"
		[collapsed]="coreConfig?.layout?.menu?.collapsed"
		collapsibleSidebar="bs-gt-xl"
		*ngIf="!coreConfig?.layout?.menu?.hidden"
		class="menu-light main-menu menu-fixed menu-accordio menu-shadow"
		overlayClass="sidenav-overlay"
		[ngClass]="hoverClass"
		[ngClass]="[
			coreConfig?.layout?.themeColor === 'semi-dark' || coreConfig?.layout?.themeColor === 'dark'
				? 'menu-dark'
				: 'menu-light',
			coreConfig?.layout?.themeColor === 'fluent' ? 'menu-light' : '',
			coreConfig?.layout?.themeColor === 'default' || coreConfig?.layout?.themeColor === 'bordered'
				? 'menu-light'
				: '',
			coreConfig?.layout?.menu?.collapsed ? '' : '',
			submenu === true ? 'open-submenu' : ''
		]"
	>
		<app-menu menuType="vertical-menu" class="vertical-menu"></app-menu>
	</core-sidebar>
</ng-template>
<!--/ MENU -->
<!-- MENU -->
<ng-template #adminMenu>
	<core-sidebar
		name="menu"
		[collapsed]="coreConfig?.layout?.menu?.collapsed"
		collapsibleSidebar="bs-gt-xl"
		*ngIf="!coreConfig?.layout?.menu?.hidden"
		class="menu-light main-menu menu-fixed menu-accordio menu-shadow admin-menu"
		overlayClass="sidenav-overlay"
		[ngClass]="hoverClass"
		[ngClass]="[
			coreConfig?.layout?.skin === 'semi-dark' || coreConfig?.layout?.skin === 'dark'
				? 'menu-dark'
				: 'menu-light',
			coreConfig?.layout?.skin === 'fluent' ? 'menu-light' : '',
			coreConfig?.layout?.skin === 'default' || coreConfig?.layout?.skin === 'bordered' ? 'menu-light' : '',
			coreConfig?.layout?.menu?.collapsed ? '' : 'expanded',
			submenu === true ? 'open-submenu' : ''
		]"
	>
		<app-menu
			menuType="admin-menu"
			class="vertical-menu admin-menu"
			(hideShowDetails)="onHideGetInTouch()"
		></app-menu>
	</core-sidebar>
</ng-template>

<ng-template #infraonAdminMenu>
	<core-sidebar
		name="menu"
		[collapsed]="coreConfig.layout.menu.collapsed"
		collapsibleSidebar="bs-gt-xl"
		*ngIf="!coreConfig.layout.menu.hidden"
		class="menu-light main-menu menu-fixed menu-accordio menu-shadow admin-menu"
		overlayClass="sidenav-overlay"
		[ngClass]="hoverClass"
		[ngClass]="[
			coreConfig.layout.skin === 'semi-dark' || coreConfig.layout.skin === 'dark' ? 'menu-dark' : 'menu-light',
			coreConfig.layout.skin === 'fluent' ? 'menu-light' : '',
			coreConfig.layout.skin === 'default' || coreConfig.layout.skin === 'bordered' ? 'menu-light' : '',
			coreConfig.layout.menu.collapsed ? '' : 'expanded',
			submenu === true ? 'open-submenu' : ''
		]"
	>
		<app-menu
			menuType="infraon-admin-menu"
			class="vertical-menu admin-menu"
			(hideShowDetails)="onHideGetInTouch()"
		></app-menu>
	</core-sidebar>
</ng-template>
<!--/ MENU -->

<!-- Support MENU -->
<ng-template #infraonSupportMenu>
	<core-sidebar
		name="menu"
		[collapsed]="coreConfig.layout.menu.collapsed"
		collapsibleSidebar="bs-gt-xl"
		*ngIf="!coreConfig.layout.menu.hidden"
		class="main-menu menu-fixed menu-accordio menu-shadow support-menu"
		overlayClass="sidenav-overlay"
		[ngClass]="[
			coreConfig.layout.skin === 'semi-dark' || coreConfig.layout.skin === 'dark' ? 'menu-dark' : 'menu-light',
			coreConfig.layout.skin === 'fluent' ? 'menu-light' : '',
			coreConfig.layout.skin === 'default' || coreConfig.layout.skin === 'bordered' ? 'menu-light' : '',
			coreConfig.layout.menu.collapsed ? '' : 'expanded',
			submenu === true ? 'open-submenu' : ''
		]"
	>
		<app-menu
			menuType="support-menu"
			class="vertical-menu support-menu"
			(hideShowDetails)="onHideGetInTouch()"
		></app-menu>
	</core-sidebar>
</ng-template>
<!--/ MENU -->

<!-- msp MENU -->
<ng-template #infraonMspMenu>
	<core-sidebar
		name="menu"
		[collapsed]="coreConfig.layout.menu.collapsed"
		collapsibleSidebar="bs-gt-xl"
		*ngIf="!coreConfig.layout.menu.hidden"
		class="main-menu menu-fixed menu-accordio menu-shadow msp-menu"
		overlayClass="sidenav-overlay"
		[ngClass]="[
			coreConfig.layout.skin === 'semi-dark' || coreConfig.layout.skin === 'dark' ? 'menu-dark' : 'menu-light',
			coreConfig.layout.skin === 'fluent' ? 'menu-light' : '',
			coreConfig.layout.skin === 'default' || coreConfig.layout.skin === 'bordered' ? 'menu-light' : '',
			coreConfig.layout.menu.collapsed ? '' : 'expanded',
			submenu === true ? 'open-submenu' : ''
		]"
	>
		<app-menu
			menuType="msp-menu"
			class="vertical-menu msp-menu"
			(hideShowDetails)="onHideGetInTouch()"
		></app-menu>
	</core-sidebar>
</ng-template>
<!-- msp MENU -->

<!-- FOOTER -->
<ng-template #footer>
	<footer
		*ngIf="!coreConfig?.layout?.footer?.hidden"
		class="footer"
		[ngClass]="
			coreConfig?.layout?.footer?.customBackgroundColor === true
				? coreConfig?.layout?.footer?.background +
				  ' ' +
				  coreConfig?.layout?.footer?.type +
				  ' ' +
				  coreConfig?.layout?.footer?.backgroundColor
				: coreConfig?.layout?.footer?.background + ' ' + coreConfig?.layout?.footer?.type
		"
	></footer>
</ng-template>
<!-- / FOOTER -->

<!-- Self Service FOOTER-->
<ng-template #selfServiceFooter>
	<footer
		*ngIf="!coreConfig?.layout?.footer?.hidden"
		class="footer self-service-footer"
		[ngClass]="
			coreConfig?.layout?.footer?.customBackgroundColor === true
				? coreConfig?.layout?.footer?.background +
				  ' ' +
				  coreConfig?.layout?.footer?.type +
				  ' ' +
				  coreConfig?.layout?.footer?.backgroundColor
				: coreConfig?.layout?.footer?.background + ' ' + coreConfig?.layout?.footer?.type
		"
	></footer>
</ng-template>
<!-- End of Self Service FOOTER -->

<!-- <core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="get-in-touch"
	overlayClass="modal-backdrop"
>
	<app-get-in-touch (hideShowDetails)="onHideGetInTouch()"></app-get-in-touch>
</core-sidebar> -->
<ng-container *ngIf="requesterData?.requester?.requester_id">
	<app-requester-calling-modal [requester] = 'requesterData' [webPhone]="webPhone"></app-requester-calling-modal>
</ng-container>
