import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { CoreSidebarService } from "../../../../../@core/components/core-sidebar/core-sidebar.service";
import { TranslateService } from "@ngx-translate/core";
import { StatusPickerComponent } from "../../../../common/dns/components/status-picker/status-picker.component";

@Component({
	selector: "app-service-change",
	templateUrl: "./service-change.component.html",
	styleUrls: ["./service-change.component.scss"],
})
export class ServiceChangeComponent implements OnInit {
	service_change_comment: any;
	isIncludeBusRule: boolean = true;
	@Input("service_config") workflow_config: any;
	@Output("onServiceChangeSubmit") onServiceChangeSubmit: EventEmitter<any> = new EventEmitter<any>();
	@Output("onServiceSliderClose") onServiceSliderClose: EventEmitter<any> = new EventEmitter<any>();
	@Input() options: any;
	service_change_data: any;
	@Input() sidebarName = "service-change-config";
	@ViewChild("aspSelectedServiceConfig", { static: false }) aspSelectedServiceConfig: StatusPickerComponent;

	constructor(private _configSidebarService: CoreSidebarService, private _translateService: TranslateService) {
		this.service_change_data = {
			custom_field_data: {},
		};
	}

	ngOnInit(): void {}

	onSubmit = (ServiceChangeForm: NgForm, workflow_config: any) => {
		if (ServiceChangeForm.valid) {
			let args = this.workflow_config.args;
			if (workflow_config?.current_workflow) {
				if (args?.value) {
					args["value"]["status"] = this.service_change_data.current_status_obj;
				} else {
					args["value"] = {};
					args["value"]["status"] = this.service_change_data.current_status_obj;
				}
				args["config"] = {
					workflow_id: this.service_change_data.current_status_obj.workflow_id,
					flow_id: this.service_change_data.current_status_obj.guid,
				};
				args["custom_field_data"] = this.service_change_data.custom_field_data;
				args["comment"] = {
					description: `${this.service_change_data.comment}`,
					type: "Service Change",
					is_private: false,
				};
				args["state_id"] = this.service_change_data.current_status_obj?.state_id;
			} else {
				args["comment"] = {
					description: `${this.service_change_data.comment}`,
					type: "Service Change",
					is_private: false,
				};
				args["is_comment_only"] = true;
			}
			if (!this.isIncludeBusRule && this.workflow_config?.args?.current_assignment_info?.group){
				args.current_assignment_info = undefined
			}
			this.onServiceChangeSubmit.emit(args);
			this.toggleSidebar(this.sidebarName, ServiceChangeForm);
		}
	};

	toggleSidebar = (sidebarName: string, dataForm: NgForm) => {
		this._configSidebarService.getSidebarRegistry(sidebarName).toggleOpen();
		if (!this._configSidebarService.getSidebarRegistry(sidebarName).isOpened) {
			this.onServiceSliderClose.emit();
			if (dataForm) {
				dataForm.resetForm();
				this.aspSelectedServiceConfig?.reset();
				this.service_change_data = {
					custom_field_data: {},
				};
			}
		}
	};

	onStatusChange = ($event: any, key: string) => {
		this.service_change_data[key] = $event;
	};

	changeIncludeBusRule = (event: any) => {
		this.isIncludeBusRule = event.target.checked
	}
}