<div class="slideout-content default-slideout">
    <div
      class="modalsd modal-slide-in sdfade new-user-modal"
      id="modals-slide-in"
    >
      <div class="modal-dialog sidebar-sm">
        <div class="modal-content add-new-user pt-0">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ "UI.k_add_attachment" | translate }}
            </h5>
            <button
              type="button"
              class="btn btn-flat-secondary btn-icon"
              data-dismiss="modal"
              aria-label="Close"
              id="btnAttachmentClose"
              [attr.data-testid]="'btnAttachmentClose'"
              (click)="toggleSidebar('app-change-attachment')"
            >
              <i class="fa-light fa-xmark"></i>
            </button>
            </div>
            <div class="modal-body">
                <form #attachmentsAddForm="ngForm" class="flex-auto-height">
                    <ng-scrollbar class="custom-scrollbar section-scrollbar">
                        <div class="p-1">
                            <app-img-upload-feature
                            (fileUploader)="fileDetails($event)"
                            [imgDetailsObject]="imgDetailsObject"
                            [multipleFiles]="multipleFiles"
                            [ALLOWED_TYPES]="allowed_file_types"
                            [MAX_IMAGE_SIZE]="MAX_IMAGE_SIZE"
                            [displayResoluion]="true"
                            [isOnlyImage]="false"
                            *ngIf="load_attachment_upload"
                            >
                            </app-img-upload-feature>
                            <div class="row">
                            <div class="col-md-12 col-12">
                                <div class="form-group">
                                <label for="attachCategory"
                                    >{{ "UI.k_category" | translate }}
                                    <span class="required-field">&nbsp;*</span>
                                </label>
                                <ng-select
                                    [items]="options?.attach_category_list"
                                    [(ngModel)]="attachment_category"
                                    bindLabel="name"
                                    bindValue="id"
                                    (change)="onCategoryChange($event)"
                                    name="attachmentCategory"
                                    id="selAttachmentCategory"
                                    required
                                    placeholder="--{{ 'UI.k_sel_category' | translate }}--"
                                    notFoundText="{{ 'UI.k_no_items_found' | translate }}"
                                    #TDCategoryRef="ngModel"
                                    [class.error]="
                                    !TDCategoryRef.valid && attachmentsAddForm.submitted
                                    "
                                >
                                </ng-select>
                                <span
                                    *ngIf="
                                    attachmentsAddForm.submitted && TDCategoryRef.invalid
                                    "
                                    class="invalid-form"
                                >
                                    <small
                                    class="form-text text-danger"
                                    *ngIf="TDCategoryRef.errors.required"
                                    >{{ "UI.k_field_required" | translate }}</small
                                    >
                                </span>
                                </div>
                            </div>
                            </div>
                            <div class="row">
                            <div class="col-md-12 col-12">
                                <div class="form-group">
                                <label for="txtDescription">{{
                                    "UI.k_description" | translate
                                }}</label>
                                <textarea
                                    type="text"
                                    id="txtDescription"
                                    class="form-control"
                                    name="description"
                                    maxlength="2000"
                                    placeholder="{{ 'UI.k_description' | translate }}"
                                    [(ngModel)]="attachment_description"
                                >
                                </textarea>
                                </div>
                            </div>
                            </div>
            
                            <ng-container *ngIf="selectedFiles.length > 0">
                            <div
                                class="upload-file-sec d-flex flex-column border-top pt-2"
                            >
                                <h6 class="mb-1">
                                {{ "UI.k_upload_preview" | translate }}
                                </h6>
                                <ul class="w-100">
                                <ng-container
                                    *ngFor="let file of selectedFiles; let i = index"
                                >
                                    <li
                                    class="d-flex justify-content-between align-items-center border-bottom"
                                    >
                                    <div class="file-detail">
                                        <div
                                        class="avatar"
                                        [ngClass]="
                                            file.name.split('.')[1] == 'txt'
                                            ? 'bg-light-primary'
                                            : file.name.split('.')[1] == 'pdf'
                                            ? 'bg-light-danger'
                                            : file.name.split('.')[1] == 'xls' ||
                                                file.name.split('.')[1] == 'xlsx'
                                            ? 'bg-light-success'
                                            : file.name.split('.')[1] == 'doc' ||
                                                file.name.split('.')[1] == 'docx'
                                            ? 'bg-light-info'
                                            : 'bg-light-primary'
                                        "
                                        >
                                        <div class="avatar-content" id="feather_size">
                                            <i
                                            [ngClass]="
                                                file.name.split('.')[1] == 'txt'
                                                ? 'fa-file-lines'
                                                : file.name.split('.')[1] == 'pdf'
                                                ? 'fa-file-pdf'
                                                : file.name.split('.')[1] == 'xls' ||
                                                    file.name.split('.')[1] == 'xlsx'
                                                ? 'fa-file-excel'
                                                : file.name.split('.')[1] == 'doc' ||
                                                    file.name.split('.')[1] == 'docx'
                                                ? 'fa-file-word'
                                                : 'fa-file'
                                            "
                                            class="fa-light lg-font"
                                            aria-hidden="true"
                                            ></i>
                                        </div>
                                        </div>
                                        <div class="file-info d-flex flex-column ml-50">
                                        <span
                                            class="title-color fw-500"
                                            tooltipClass="fadeInAnimation"
                                            placement="top"
                                            ngbTooltip="{{ file.file_name || file.name }}"
                                            >{{ file.file_name || file.name }}</span
                                        >
                                        <div class="extra-info">
                                            <span class="file-size">{{
                                            file?.formatted_size
                                            }}</span>
                                            <i class="fa-solid fa-period"></i>
                                            <span class="file-date">{{
                                            file.lastModified
                                                | date: "d MMM y" || file.lastModified
                                                | date: "d MMM y"
                                            }}</span>
                                        </div>
                                        </div>
                                    </div>
                                    <button
                                        type="button"
                                        class="btn btn-icon btn-flat-danger"
                                        id="spDeleteAttachment-{{ i }}"
                                        attr.data-testid="spDeleteAttachment-{{ i }}"
                                        (click)="removeFile(i)"
                                        tooltipClass="fadeInAnimation"
                                        ngbTooltip="{{ 'UI.k_delete' | translate }}"
                                        rippleEffect
                                    >
                                        <i class="fa-light fa-trash-can"></i>
                                    </button>
                                    </li>
                                </ng-container>
                                </ul>
                            </div>
                            </ng-container>
                        </div>
                        </ng-scrollbar>
                        <div class="py-1 px-2 flex-initial border-top">
                        <div class="pull-right align-items-center d-flex p-0 m-0">
                            <button
                            class="btn btn-sm btn-outline-tertiary mr-1"
                            rippleEffect
                            tooltipClass="fadeInAnimation"
                            id="btnKbDeleteAll"
                            [attr.data-testid]="'btnKbDeleteAll'"
                            [disabled]="selectedFiles.length == 0"
                            (click)="deleteAllFiles()"
                            ngbTooltip="{{ 'UI.k_delete_all' | translate }}"
                            >
                            {{ "UI.k_cancel" | translate }}
                            </button>
                            <button
                            class="btn btn-sm btn-primary"
                            rippleEffect
                            id="btnKbUploadAll"
                            [attr.data-testid]="'btnKbUploadAll'"
                            [disabled]="disableUploadBtn"
                            tooltipClass="fadeInAnimation"
                            (click)="uploadAttachments()"
                            ngbTooltip="{{ 'UI.k_upload' | translate }}"
                            >
                            <i class="fa-light fa-upload"></i>&nbsp;
                            {{
                                "Message.msg_upload_file"
                                | translate: { count: selectedFiles?.length }
                            }}
                            </button>
                        </div>
                        </div>
                </form>
            </div>
        </div>
    </div>
</div>
</div>

