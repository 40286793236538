<!-- app-breadcrumb start -->
<div
	class="breadcrumb-wrapper d-flex"
	[ngClass]="{
		'justify-content-center': breadcrumb?.alignment == 'center',
		'justify-content-end': breadcrumb?.alignment == 'right'
	}"
>
	<ol
		class="breadcrumb p-0"
		[ngClass]="{
			'mr-1': breadcrumb?.alignment == 'right'
		}"
	>
		<li class="" *ngFor="let link of breadcrumb?.links">
			<a
				*ngIf="link.isLink"
				routerLink="{{ link.link | translate }}"
				id="sp{{ (link.name | translate).replaceAll(' ', '') }}"
				name="sp{{ (link.name | translate).replaceAll(' ', '') }}"
			>
				<span class="font-small-1 mr-75">{{ link.name | translate }}</span>
				<span> <i class="fa-light fa-chevron-right mr-75" style="font-size: 8px"></i></span>
			</a>

			<span *ngIf="!link.isLink">
				<span
					class="font-small-1 text-primary"
					id="spNotLink{{ (link.name | translate).replaceAll(' ', '') }}"
					name="spNotLink{{ (link.name | translate).replaceAll(' ', '') }}"
					>{{ link.name | translate }}</span
				>
			</span>
		</li>
	</ol>
</div>
<!-- app-breadcrumb end -->
<!-- [ngClass]="{
  'breadcrumb-slash': breadcrumb.type == 'slash',
  'breadcrumb-dots': breadcrumb.type == 'dots',
  'breadcrumb-dashes': breadcrumb.type == 'dashes',
  'breadcrumb-pipes': breadcrumb.type == 'pipes',
  'breadcrumb-chevron': breadcrumb.type == 'chevron',
  'mr-1': breadcrumb?.alignment == 'right'
}" -->
