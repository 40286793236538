import { Injectable } from "@angular/core";
import { ApiService } from "app/common/services/api/api.service";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class IncidentViewService extends ApiService {
	private _incidentSubject: BehaviorSubject<boolean>;

	constructor(httpClient: HttpClient) {
		super(httpClient);
	}

	getIncidents(payload = {}) {
		return this.get("ux/sd/inci/incident/", payload);
	}

	saveIncident(payload: any) {
		return this.post("ux/sd/inci/incident/", payload);
	}

	getIncident(incident_id: any, payload={}) {
		return this.get("ux/sd/inci/incident/" + incident_id + "/", payload);
	}

	editIncident(incident_id: any, payload: any) {
		return this.put("ux/sd/inci/incident/" + incident_id + "/", payload);
	}
	deleteIncident(payload: any) {
		return this.post("ux/sd/inci/incident/delete_incident/", payload);
	}
	getIncidentColumns(payload = {}) {
		return this.get("ux/sd/inci/incident/page_config/", payload);
	}
	getAttachmentDetails(payload = {}) {
		return this.get("ux/sd/inci/incident/attachment/", payload);
	}

	getHistoryDetails(payload = {}) {
		return this.get("ux/sd/inci/incident/history/", payload);
	}

	addAttachments(payload: any) {
		return this.post("ux/sd/inci/incident/add-attachment/", payload);
	}

	savePageConfig(payload) {
		return this.post("ux/sd/inci/incident/page/", payload);
	}
	getOptions(payload = {}) {
		return this.get("ux/sd/inci/incident/options/", payload);
	}
	getExpertise(payload = {}) {
		return this.get("ux/sd/inci/incident/expertise/", payload);
	}
	getExpertiseLevels(payload = {}) {
		return this.get("ux/sd/inci/incident/team_level/", payload);
	}
	getAssigneeList(payload = {}) {
		return this.get("ux/sd/inci/incident/assignee/", payload);
	}
	getUserProfile(userid: Number) {
		return this.get("ux/common/user/profile/" + userid.toString() + "/");
	}
	getServicesForSelectedCatalogue(payload: any) {
		return this.get("ux/common/catalogue/servicecatalogue/service-list/", payload);
	}
	getServiceDetails(serviceId: Number) {
		return this.get("ux/common/catalogue/servicecatalogue/" + serviceId.toString() + "/");
	}
	updateIncidentAssignee(payload = {}) {
		return this.put("ux/sd/inci/incident/update-assignee/", payload);
	}
	getFilteredIncident(payload = {}) {
		return this.get("ux/sd/inci/incident/top_filter/", payload);
	}
	saveConversation(payload) {
		return this.post("ux/sd/inci/conversation/", payload);
	}
	getMailConversations(payload) {
		return this.get("ux/sd/inci/conversation/", payload);
	}
	updateIncidentTag(payload = {}) {
		return this.put("ux/sd/inci/incident/update-tag/", payload);
	}
	loadOrganiationConfig(payload: {}) {
		return this.get("ux/org/profile/orgconfig/", payload);
	}

	saveRichTextData(payload: {}) {
		return this.post("ux/sd/inci/incident/save-rich-text/", payload);
	}

	getRichTextData(payload: {}) {
		return this.get("ux/sd/inci/incident/process-rich-text/", payload);
	}
	getRequesterList(payload: {}) {
		return this.get("ux/sd/inci/incident/requester_search/", payload);
	}

	processIncidentRelations(payload: {}) {
		return this.get("ux/sd/inci/incident/process-relations/", payload);
	}

	mergeIncident(payload: {}) {
		return this.post("ux/sd/inci/incident/merge/", payload);
	}
	getReqLatestIncidents(payload: {}) {
		return this.get("ux/sd/inci/incident/latestincidents/", payload);
	}
	deleteAttachment(attachmentId: any, payload: {}) {
		return this.put("ux/sd/inci/incident/delete-attachment/" + attachmentId.toString() + "/", payload);
	}
	convertIncidentToKB(kbId: any, payload: {}) {
		return this.put("ux/sd/inci/incident/incident-to-kb/" + kbId.toString() + "/", payload);
	}

	getAllKBs(payload: {}) {
		return this.get("ux/sd/inci/incident/incident-kbs/", payload);
	}

	getFilterOption(payload: any) {
		return this.get("ux/common/filters/filters/filter_options/", payload);
	}

	getSavedFilter(payload) {
		return this.get("ux/common/filters/filters/filter/", payload);
	}

	getServiceBaseWorkflowStatus(payload: any) {
		return this.get("ux/sd/inci/incident/workflow_status/", payload);
	}

	setEditIncidentSidebar(flag) {
		localStorage.setItem("incident-edit-flag", JSON.stringify(flag));
	}

	getIncidentSidebar() {
		return JSON.parse(localStorage.getItem("incident-edit-flag"));
	}

	editIncidentAssignee(payload) {
		return this.post("ux/sd/inci/incident/update_incident_assignee/", payload);
	}
	addIncidentRelation(payload) {
		return this.post("ux/sd/inci/incident/relation/", payload);
	}

	getWorkflowOptions(payload) {
		return this.post("ux/sd/inci/incident/workflow_options_map/", payload);
	}

	bulkIncidentResolve(payload) {
		return this.post("ux/sd/inci/incident/bulk-resolve/", payload);
	}

	bulkIncidentAssignment(payload) {
		return this.post("ux/sd/inci/incident/bulk-assignment/", payload);
	}

	getTeamUserMap(payload) {
		return this.get("ux/sd/inci/incident/team_user_map/", payload);
	}

	getRequesterImpactService(payload) {
		return this.get("ux/common/catalogue/servicecatalogue/req_services/", payload);
	}

	getImpactServiceClassification(payload) {
		return this.get("ux/common/catalogue/servicecatalogue/service_classes/", payload);
	}
	getSLAMatricsData(payload) {
		return this.get("ux/common/sla/processdata/", payload);
	}
	getIncidentData(pk, payload = {}) {
		return this.get(`ux/sd/inci/incident/incident-data/${pk}/`, payload);
	}

	getEscalationData(pk, payload = {}) {
		return this.get(`ux/sd/inci/incident/escalation-data/${pk}/`, payload);
	}
	getApprovalStatus = (payload) => {
		return this.get(`ux/sd/inci/incident/check_approval_user/`, payload);
	};
	convertIncidentToRequest(requestId: any, payload: {}) {
		return this.put("ux/sd/inci/incident/inci-to-request/" + requestId.toString() + "/", payload);
	}
	getRequest(request_id: any, payload = {}) {
		return this.get("ux/sd/req/request_process/" + request_id + "/", payload);
	}
	getSlaData(payload = {}) {
		return this.get("ux/common/sla/processdata/", payload);
	}
	convertInciToChange(changeId: any, payload: {}) {
		return this.put("ux/sd/inci/incident/inci-to-change/" + changeId.toString() + "/", payload);
	}
	getChange(change_id: any, payload: any ={}) {
		return this.get("ux/sd/change/change/" + change_id + "/", payload);
	}
	getProblems(payload = {}) {
		return this.get("ux/sd/problem/problem/", payload);
	}
	getProblemOptions(payload = {}) {
		return this.get("ux/sd/problem/problem/options/", payload);
	}
	getChanges(payload = {}) {
		return this.get("ux/sd/change/change/", payload);
	}
	getChangeOptions(payload = {}) {
		return this.get("ux/sd/change/change/options/", payload);
	}
	getRequests(payload = {}) {
		return this.get("ux/sd/req/request_process/", payload);
	}
	getRequestOptions(payload = {}) {
		return this.get("ux/sd/req/request_process/options/", payload);
	}
	getReleases(payload = {}) {
		return this.get("ux/sd/release/release/", payload);
	}
	getReleaseOptions(payload = {}) {
		return this.get("ux/sd/release/release/options/", payload);
	}
	getProblemRelations(payload: {}) {
		return this.get("ux/sd/problem/problem/process-relations/", payload);
	}
	getChangeRelations(payload: {}) {
		return this.get("ux/sd/change/change/process-relations/", payload);
	}
	getRequestRelations(payload: {}) {
		return this.get("ux/sd/req/request_process/process-relations/", payload);
	}
	getReleaseRelations(payload: {}) {
		return this.get("ux/sd/release/release/process-relations/", payload);
	}
	addProblemRelation(payload) {
		return this.post("ux/sd/problem/problem/relation/", payload);
	}
	addChangeRelation(payload) {
		return this.post("ux/sd/change/change/relation/", payload);
	}
	addRequestRelation(payload) {
		return this.post("ux/sd/req/request_process/relation/", payload);
	}
	addReleaseRelation(payload) {
		return this.post("ux/sd/release/release/relation/", payload);
	}
	updateMsgReadStatus(payload){
		return this.post("ux/sd/inci/conversation/msg_read/", payload)
	}
	getProblem(problem_id: any, payload: any ={}) {
		return this.get("ux/sd/problem/problem/" + problem_id + "/", payload);
	}
	convertInciToProblem(problemId: any, payload: {}) {
		return this.put("ux/sd/inci/incident/inci-to-problem/" + problemId.toString() + "/", payload);
	}
	createIncident(payload) {
		return this.post("ux/sd/inci/incident/create_inci/", payload);
	}
	fetchIncident(payload) {
		return this.get("ux/sd/inci/incident/fetch_inci/", payload);
	}
	assignToUser(payload) {
		return this.post("ux/sd/inci/incident/assign-to-user/", payload);
	}
	editIncidentAssigneeTeam(payload) {
		return this.post("ux/sd/inci/incident/update_assignee_team/", payload);
	}
	incidentListFilter(payload: {}) {
		return this.post("ux/sd/inci/incident/incident_list_filter/", payload);
	}
}
