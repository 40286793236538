import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation} from "@angular/core";
// import { takeUntil } from 'rxjs/operators';
import {SystemMenuService} from "../../services/system-menu.service";
import {CoreConfigService} from "@core/services/config.service";
import {ActivatedRoute, Router} from "@angular/router";
import {catchError, map} from "rxjs/operators";
import {User} from "app/auth/models";
import {ToastrService} from "ngx-toastr";
import {PolicyService} from "../../../../base/policy/services/policy.service";
import {AuthService} from "app/base/auth/services/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {CountryISO, PhoneNumberFormat, SearchCountryField} from "ngx-intl-tel-input";
import {CoreSidebarService} from "@core/components/core-sidebar/core-sidebar.service";
import { Subscription, throwError } from "rxjs";
import cloneDeep from "lodash-es/cloneDeep";
import includes from "lodash-es/includes";
import { environment } from "environments/environment";
import { InfinityModules } from "app/common/dns/types/modules";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { GlobalConstants } from "app/app.globalConstants";
import Swal from "sweetalert2";
import { ReCaptchaV3Service } from 'ng-recaptcha';
const AES = require("crypto-js/aes");

@Component({
	selector: "app-account-settings",
	templateUrl: "./account-settings.component.html",
	styleUrls: ["./account-settings.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class AccountSettingsComponent implements OnInit , OnDestroy , AfterViewInit{
	@HostListener("window:resize", [])
	@ViewChild("toggleSideMenu")
	toggleSideMenu: ElementRef;
	@ViewChild("sidebar") sidebar: ElementRef;

	public currentMenu = "general";
	submenu: boolean;
	public imgDetailsObject = {
		aspectRatio: "4/ 2",
		resizeToWidth: "128",
		cropperMinWidth: "128",
		maintainAspectRatio: true,
	};
	public roundedAvatar = false;
	public showQueue = false;
	public multipleFiles = true;
	public displayResoluion = false;
	public squareImage = true;
	public fullName: string;
	public imageDetail: boolean;
	public setImage: boolean;
	public allFileList: any;
	public currentImgDetails;
	public avatarColor;
	public tempImage;
	public activeAvatar;
	// for password policy
	public changePassword;
	public passwordHistory;
	public minPasswordLength;
	public maxPasswordLength;
	public minUpperCase;
	public minLowerCase;
	public minNumChar;
	public minSplChar;
	public contUpperCase;
	public contLowerCase;
	public contNumChar;
	public contSplChar;
	public currentAvatarColor;
	public is_password_invalid = false;
	fileUploadError = false;
	public passwordToaster;
	public agreeCondition: any = false;
	public file_uploaded: boolean = false;
	public showData: boolean = false;
	public isBookmarked = false;
	currentUser: User;
	ALLOWED_TYPES: Array<any>;
	MAX_IMAGE_SIZE;
	MAX_HEIGHT;
	MAX_WIDTH;

	public accountSetting: any;
	public new_password = "";
	public old_password = "";
	public userPwdShow = false;
	public userOldPwdShow = false;
	public file: any;
	timezones: any;
	email: any;
	separateDialCode = true;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
	PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom, CountryISO.India];
	departmentList: Array<any>;
	public isSelfServicePortal: boolean = false;
	globalSearch: any = false;

	//signature 
	event;
	currentItem = {};
	signatureTemplateColumns: Array<any>;
	public signatureTemplate: any;
	totalSignatureTemplate:any;
  	pageNumber = 0;
	sortKey: any = 'name';
	limit: any = 10;
	permissions: any;
	module = InfinityModules.USER;

	public captcha_temp = "";
	public codeImage: any;
	public captchaCode = "";
	public captchaToken = "";
	public showCaptcha: boolean = false;
	public generated_otp = ""
	is_otp_validate: boolean = false;
	reverse: any = "";

	public filterData = false;

	filter = [];
	// Search
	public showRightsection: false;
	public showDataTable = false;
	public savedData = [];
	public columnOptions = [];
	public conditionOptions = [];
	public showAll = false;
	signatureTemplateCount: any;
	sign_id: any = "-1";
	is_sign_loaded = false
	private recaptchaSubscription:Subscription
	/**
	 * Constructor
	 *
	 * @param {SystemMenuService} _systemMenuService
	 *
	 */
	constructor(
		private _coreConfigService: CoreConfigService,
		private _configService: SystemMenuService,
		private _coreSidebarService: CoreSidebarService,
		private _router: Router,
		private _toastr: ToastrService,
		private _translateService: TranslateService,
		private _policyService: PolicyService,
		private _authService: AuthService,
		private _activatedRoute: ActivatedRoute,
		private modalService: NgbModal,
		private renderer: Renderer2,
		private reCaptchaV3Service: ReCaptchaV3Service,
	) {
		this.updateSidebar();
		this._coreConfigService._selfServiceSubject.subscribe((selfService) => {
			if (selfService === "self-service") {
				this.isSelfServicePortal = true;
			} else {
				this.isSelfServicePortal = false;
			}
		});
		this._authService.currentUser.subscribe((x) => (this.currentUser = x));
		this.accountSetting = {
			general: {
				profile: {},
			},
			info: {},
			notification: {
				commentOnArticle: true,
				AnswerOnForm: true,
				followMe: false,
				newAnnouncements: true,
				productUpdates: true,
				blogDigest: false,
			},
		};

		// Navbar
		this._coreConfigService.config = {
			layout: {
				navbar: {
					loadHTML: false,
					hidden: false,
					showSearch: false,
					pageTitle: this._translateService.instant("UI.k_account_set"),
					moduleName: "Account Settings",
					pageIcon: "fa-user",
					bookmark: this.isBookmarked,
					//backgroundColor: '',
					breadcrumb: "",
				},

				menu: {
					hidden: false,
				},
			},
		};
	}

	// Public Methods
	// -----------------------------------------------------------------------------------------------------

	encryptPassword(password: string) {
		var iv = "d99630bcc7058639e7e80df239f38a32";
		var ciphertext = AES.encrypt(password, iv).toString();
		var aesPassword = iv + "::" + ciphertext;
		return btoa(aesPassword);
	}

	validatePassword(password) {
		this.is_password_invalid = false;
		this.passwordToaster = this._translateService.instant("Message.msg_pwd_contain") + "</br>";
		if (password.length < this.minPasswordLength) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_minimum_character", {
				minimum_character: this.minPasswordLength,
			});
			this.is_password_invalid = true;
		}
		if (password.length > this.maxPasswordLength) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_maximum_character", {
				maximum_character: this.maxPasswordLength,
			});
			this.is_password_invalid = true;
		}
		if (
			password.replace(/[^\\!\\@\\#\\$\\%\\^\\&\\*\\(\\)\\_\\?\\<\\>]/g, "").length < this.minSplChar &&
			this.minSplChar != this.contSplChar
		) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_minimum_special_character", {
				minimum_special_character: this.minSplChar,
			});
			this.is_password_invalid = true;
		}
		let pattern = "([\\!\\@\\#\\$\\%\\^\\&\\*\\(\\)\\_\\?\\<\\>])" + "{" + this.contSplChar + "}";
		if (!password.match(pattern)) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_continous_special_character", {
				continous_special_character: this.contSplChar,
			});
			this.is_password_invalid = true;
		}
		if (password.replace(/[^0-9]/g, "").length < this.minNumChar && this.minNumChar != this.contNumChar) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_minimum_number", {
				minimum_number: this.minNumChar,
			});
			this.is_password_invalid = true;
		}
		pattern = "([\\d])" + "{" + this.contNumChar + "}";
		if (!password.match(pattern)) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_continous_number", {
				continous_number: this.contNumChar,
			});
			this.is_password_invalid = true;
		}
		if (password.replace(/[^a-z]/g, "").length < this.minLowerCase && this.minLowerCase != this.contLowerCase) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_minimum_lowercase_letter", {
				minimum_lowercase_letter: this.minLowerCase,
			});
			this.is_password_invalid = true;
		}
		pattern = "([a-z])" + "{" + this.contLowerCase + "}";
		if (!password.match(pattern)) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_continous_lowercase_letter", {
				continous_lowercase_letter: this.contLowerCase,
			});
			this.is_password_invalid = true;
		}
		if (password.replace(/[^A-Z]/g, "").length < this.minUpperCase && this.minUpperCase != this.contUpperCase) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_minimum_uppercase_letter", {
				minimum_uppercase_letter: this.minUpperCase,
			});
			this.is_password_invalid = true;
		}
		pattern = "([A-Z])" + "{" + this.contUpperCase + "}";
		if (!password.match(pattern)) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_continous_uppercase_letter", {
				continous_uppercase_letter: this.contUpperCase,
			});
			this.is_password_invalid = true;
		}
	}

	generatePasswordPolicy(policy_objs) {
		this.changePassword = policy_objs.changePassword;
		this.passwordHistory = policy_objs.passwordHistory;
		this.minPasswordLength = policy_objs.minPasswordLength;
		this.maxPasswordLength = policy_objs.maxPasswordLength;
		this.minUpperCase = policy_objs.minUpperCase;
		this.minLowerCase = policy_objs.minLowerCase;
		this.minNumChar = policy_objs.minNumChar;
		this.minSplChar = policy_objs.minSplChar;
		this.contUpperCase = policy_objs.contUpperCase;
		this.contLowerCase = policy_objs.contLowerCase;
		this.contNumChar = policy_objs.contNumChar;
		this.contSplChar = policy_objs.contSplChar;
	}

	onUpload(event: any, form): void {
		let files = event.target.files;
		if (files.length > 0) {
			this.file = files[0];
			if (this.file && this.file["size"] > this.MAX_IMAGE_SIZE) {
				this.fileUploadError = true;
				this._toastr.warning(
					this._translateService.instant("Message.msg_max_size") + this.MAX_IMAGE_SIZE / 1024 + "KB",
					this._translateService.instant("Error.err_upload_fail"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
			}

			if (!includes(this.ALLOWED_TYPES, this.file["type"])) {
				this.fileUploadError = true;
				this._toastr.warning(
					this._translateService.instant("Message.msg_image_allowed"),
					this._translateService.instant("Error.err_upload_fail"),
					{
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					}
				);
			}
			if (this.fileUploadError === false) {
				const reader = new FileReader();
				reader.onload = (e: any) => {
					const image = new Image();
					image.src = e.target.result;
					image.onload = (rs) => {
						const img_height = rs.currentTarget["height"];
						const img_width = rs.currentTarget["width"];
						if (img_height > this.MAX_HEIGHT && img_width > this.MAX_WIDTH) {
							this._toastr.warning(
								this._translateService.instant("Message.msg_max_dimension") +
									this.MAX_HEIGHT +
									"*" +
									this.MAX_WIDTH +
									"px",
								this._translateService.instant("Error.err_upload_fail"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
						} else {
							const imgBase64Path = e.target.result;
							this.accountSetting.general.profile_image = imgBase64Path;
							this.file_uploaded = true;
							this.accountSetting.general.new_image = true;
							form.form.touched = true;
						}
					};
				};
				reader.readAsDataURL(this.file);
			}
		}
	}

	getUserProfile() {
		this._configService
			.getUserProfile()
			.pipe(
				map((response: any) => {
					if (response) {
						if (Object.keys(response).length === 0) {
							this.showData = false;
						} else {
							this.showData = true;
							this.email = response.email;
							this.accountSetting.general = cloneDeep(response);
							this.accountSetting["general"]["username"] = this.accountSetting.general.actual_username;
							this.accountSetting["general"]["profile"]["full_name"] =
								this.accountSetting.general.first_name;
							if (this.accountSetting.general.profile.gender != null) {
								this.accountSetting.general.profile.gender =
									"" + this.accountSetting.general.profile.gender;
							}
							this.fullName = this.accountSetting["general"]["profile"]["full_name"];
							this.currentImgDetails = this.currentUser.profile_image;
							if (response?.profile?.avatar_color) {
								this.currentAvatarColor = response?.profile?.avatar_color;
								this.activeAvatar = response?.profile?.avatar_color;
								this.avatarColor = response?.profile?.avatar_color;
							} else {
								this.currentAvatarColor = "#a5c1d4";
								this.activeAvatar = "#a5c1d4";
							}
							//this.activeAvatar= '#f59518';
							this.accountSetting.info = cloneDeep(response);
							this._authService.currentUserSubject.next(response);
						}
						const payload = {
							page: 1,
							items_per_page: 10,
							sort: "policyName",
							reverse: "",
							group_id: response.group_id,
						};
						this._policyService
							.fetchPasswordPolicy(payload)
							.pipe(
								map((response: any) => {
									if (response) {
										this.generatePasswordPolicy(response);
									}
									// Success
								})
							)
							.subscribe();
					}
					// Success
				}),
				catchError((error) => {
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}

	updateUserProfile(userdata) {
		if (userdata?.profile){
			userdata.profile["otp"] = this.generated_otp
		}
		this._configService
			.saveUserProfile(userdata)
			.pipe(
				map((response: any) => {
					if (response) {
						if (response["status"] == "success") {
							let userParams = JSON.parse(localStorage.getItem("userParams"));
							userParams["user_tz"] =
								this.accountSetting.general.profile.timezone?.id ||
								this.accountSetting.general.profile.timezone_id;
							localStorage.setItem("userParams", JSON.stringify(userParams));
							//handling user gender value for proper checkbox selection
							if (userdata && userdata?.profile?.gender) {
								userdata.profile.gender = "" + userdata.profile.gender;
							}
							this._authService.currentUserSubject.next(userdata);
							this.accountSetting.info = cloneDeep(userdata);
							this.accountSetting.general = cloneDeep(userdata);
							this.tempImage = "";
							this.is_otp_validate = false
							this._toastr.success(
								this._translateService.instant(response["message"]),
								this._translateService.instant("Message.msg_save_success"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
						}
						else {
							this._toastr.error(
								this._translateService.instant(response["message"]),
								this._translateService.instant("Error.err_save_failed"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
						}

					} else {
						this._toastr.error(
							this._translateService.instant(response["message"]),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
					}
				}),
				catchError((error) => {
					this._toastr.error(
						this._translateService.instant(error.error.error.message),
						this._translateService.instant("Error.err_save_failed"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					//   this.notification.error('Failed User Creation', 'Something went wrong')
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}
	saveUserProfile(userdata, form) {
		form.form.touched = false;
		userdata.action = "Edit";
		if (this.isSelfServicePortal) {
			let payload = {};
			payload['is_login_enable'] = true;
			payload["full_name"] = userdata?.profile?.full_name;
			payload["user_tz"] = userdata?.user_tz;
			payload["phone_number"] = userdata?.profile?.phone_number;
			payload["phone_ext"] = userdata?.profile?.contact_extension;
			payload["land_line"] = userdata?.profile?.landline;
			payload["profile_image"] = userdata?.profile_image;
			payload["user_details"] = {};
			// username edit not allowed//hence passing null
			payload["user_details"]["username"] = null;
			payload["user_details"]["timezone"] = userdata?.profile?.timezone?.id;

			// if (userdata?.profile?.reporting_manager) {
			// 	payload["user_details"]["is_reporting_manager"] = true
			// 	payload["user_details"]["reporting_manager"] = userdata?.profile?.reporting_manager
			// }
			// payload["user_details"]["dashboard"] = userdata?.profile?.dashboard
			payload["update_req_user_profile"] = true;
			userdata["update_req_user_profile"] = true;
			userdata["requester_data"] = payload;
			if (userdata?.profile){
				userdata.profile["otp"] = this.generated_otp
			}
			this._configService
				.saveRequesterProfile(userdata)
				.pipe(
					map((response: any) => {
						if (response) {
							// this.notification.success('Saved User', 'User has been saved successfully')
							if (response["status"] == "success") {
								let userParams = JSON.parse(localStorage.getItem("userParams"));
								userParams["user_tz"] =
									this.accountSetting.general.profile.timezone?.id ||
									this.accountSetting.general.profile.timezone_id;
								localStorage.setItem("userParams", JSON.stringify(userParams));
								//handling user gender value for proper checkbox selection
								if (userdata && userdata?.profile?.gender) {
									userdata.profile.gender = "" + userdata.profile.gender;
								}
								this._authService.currentUserSubject.next(userdata);
								this.accountSetting.info = cloneDeep(userdata);
								this.accountSetting.general = cloneDeep(userdata);
								this.tempImage = "";
								this.is_otp_validate = false
								this._toastr.success(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Message.msg_save_success"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							} else {
								this._toastr.error(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Error.err_save_failed"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							}
						}
						// Success
					}),
					catchError((error) => {
						this._toastr.error(
							this._translateService.instant("Error.err_save_failed"),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						//   this.notification.error('Failed User Creation', 'Something went wrong')
						return throwError(error.statusText);
					})
				)
				.subscribe();
		} else {
			this.updateUserProfile(userdata);
		}
	}

	/*
	 *Save Settings General tab data
	 */
	saveGeneralSettingsData(data) {
		if (data.form.valid === true) {
			let userData = cloneDeep(this.accountSetting);
			userData["general"]["profile"]["full_name"] = this.accountSetting.general.first_name;
			// + " " + this.accountSetting.general.last_name;
			try {
				userData.general.profile.gender = parseInt(this.accountSetting.general.profile.gender);
			} catch (e) {
				userData.general.profile.gender = null;
				console.error(e);
			}
			userData["general"]["profile"]["avatar_color"] = this.avatarColor;
			this.saveUserProfile(userData.general, data);
		}
	}

	saveUserProfileImage() {
		let profileImageParam = {};
		profileImageParam["profile_image"] = this.accountSetting.general.profile_image;
		profileImageParam["user_id"] = this.accountSetting.general.id;
		profileImageParam["avatarColor"] = this.avatarColor;
		this._configService
			.saveUserProfileImage(profileImageParam)
			.pipe(
				map((response: any) => {
					this.currentUser.profile_image = this.accountSetting.general.profile_image;
					this._authService.currentUserSubject.next(this.currentUser);
					// if (response) {
					// }
				}),
				catchError((error) => {
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}

	removeImage = ($event, form) => {
		this.tempImage = this.currentUser.profile_image;
		this.currentUser.profile_image = "";
		this.accountSetting.general.profile_image = "";
		this.accountSetting.general.delete_image = true;
		// if (this.currentUser.profile_image) {
		// 	this.accountSetting.general.profile_image =
		// 		this.currentUser.profile_image;
		// } else {
		// 	this.accountSetting.general.profile_image = "";
		// }
		this.file_uploaded = false;
		form.form.touched = true;
	};

	/*
	save account settings information tab data
	*/
	saveSettingsInfo(data) {
		if (data.form.valid === true) {
			this.saveUserProfile(this.accountSetting.info, data);
		}
	}

	changeUserPassword(data) {
		if (data.form.valid && !this.is_password_invalid) {
			let old_password = this.encryptPassword(this.old_password);
			let new_password = this.encryptPassword(this.new_password);
			let user_data = {};
			user_data["action"] = "Edit";
			user_data["is_active"] = true;
			user_data["is_password_modify"] = true;
			user_data["username"] = this.encryptPassword(this.accountSetting.general.actual_username);
			user_data["password"] = new_password;
			user_data["old_password"] = old_password;
			user_data["change_password"] = true;
			user_data["captchaOpt"] = this.captchaCode
			user_data["captchaToken"] = this.captchaToken
			user_data["loginCaptcha"] = this.captcha_temp
			user_data["CaptchaValidation"] = this.showCaptcha
			this.recaptchaSubscription = this.reCaptchaV3Service.execute('importantAction')
			.subscribe((token: string) => {
					this._configService
						.changePassword(user_data)
						.pipe(
							map((response: any) => {
								if (response) {
									if (response["status"] == "success") {
										this._toastr.success(
											this._translateService.instant(response["message"]),
											this._translateService.instant("Message.msg_password_reset"),
											{
												toastClass: "toast ngx-toastr",
												closeButton: true,
												positionClass: "toast-top-right",
											}
										);
										this._router.navigate(["/auth/login"]);
									} else {
										this.is_password_invalid = false;
										this._toastr.error(
											this._translateService.instant(response["message"]),
											this._translateService.instant("Error.err_password_reset"),
											{
												toastClass: "toast ngx-toastr",
												closeButton: true,
												positionClass: "toast-top-right",
											}
										);
									}
									// this.getCaptcha();
								}
								// Success
							}),
							catchError((error) => {
								this._toastr.error(
									this._translateService.instant(error.error.error.message),
									this._translateService.instant("Error.err_save_failed"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
								return throwError(error.statusText);
							})
						)
						.subscribe();
			});
		}	
	}

	stringToColor(string) {
		let hash = 0;
		let i;

		/* eslint-disable no-bitwise */
		for (i = 0; i < string.length; i += 1) {
			hash = string.charCodeAt(i) + ((hash << 5) - hash);
		}

		let colour = "#";

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff;
			colour += `00${value.toString(16)}`.substr(-2);
		}
		/* eslint-enable no-bitwise */

		return colour;
	}

	/**
	 * On init
	 */
	ngOnInit() {
		//this.avatarColor = '#978ef2'
		this._configService.getOptions().subscribe(
			(response) => {
				if (response) {
					let imageDefaults = response["image_upload_defaults"];
					this.ALLOWED_TYPES = imageDefaults["allowed_types"];
					this.MAX_IMAGE_SIZE = imageDefaults["max_size"];
					this.MAX_HEIGHT = imageDefaults["max_height"];
					this.MAX_WIDTH = imageDefaults["max_width"];
					this.timezones = response["timezones"];
					this.departmentList = response["departments"];
				}
			},
			(error) => {
				//todo
			}
		);
		this.old_password = "";
		this.new_password = "";
		this.is_password_invalid = false;
		if(localStorage.getItem("lastMenuSelected")){
			this.currentMenu = localStorage.getItem("lastMenuSelected");
			localStorage.removeItem("lastMenuSelected");
		}
		this.getUserProfile();
		// let menu = localStorage.getItem("lastMenuSelected");
		// if (menu !== null && menu !== undefined) {
		// 	this.currentMenu = localStorage.getItem("lastMenuSelected");
		// }

		const sub = this._activatedRoute.queryParams.subscribe((params) => {
			if (params.globalSearch) {
				this.globalSearch = true;
				this.currentMenu = "manageNotify";
			}
			else if (params?.signature){
				this.currentMenu = "signature";
			}
			else {
				this.currentMenu = "general";
			}

		});
// signature
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.permissions = user_permissions[this.module];
		this.getUserSignatureList()
		this.signatureTemplate = [
				{
					title : "My signature",
					tempText :"With regards,Walter White",	
					signature_img : "<p><img src='assets/images/infraon_logo.svg'/></p>"			
				},
				{
					title : "Team signature",
					tempText :"With regards,Walter White",		
					signature_img : "<p><img src='assets/images/infraon_logo.svg'/></p>"		
				},
				{
					title : "Company signature #1",
					tempText :"With regards,Walter White",
					signature_img : "<p><img src='assets/images/infraon_logo.svg'/></p>"				
				},
				{
					title : "Company signature #2",
					tempText :"With regards,Walter White",	
					signature_img : "<p><img src='assets/images/infraon_logo.svg'/></p>"			
				},
				
			]
		this.totalSignatureTemplate = this.signatureTemplate.length;
		this.signatureTemplateColumns = [
			{
				label: this._translateService.instant("UI.k_title"),
				key: "name", 
				width: 250,
			},            
			{
				label: this._translateService.instant("UI.k_signature"),
				key: "content",  
				type: "html"    			
			},
			{
				label: this._translateService.instant('UI.k_action'),
				key: 'actions',
				width: 150,
				actions: [
					{
						label: this._translateService.instant('UI.k_edit'),
						icon: 'fa-pen-to-square',
						key: 'edit',
						class: 'text-primary mr-50',
						listener: this.editSignature,
					},
					{
						label: this._translateService.instant('UI.k_delete'),
						icon: 'fa-trash-can',
						key: 'edit',
						class: 'text-danger mr-50',	
						listener: this.deleteSignature,					
					},  				
				],
			},                     
		]
	}

	getUserSignatureList() {
		let payload = {
			page: this.pageNumber + 1,
			items_per_page: this.limit,
			sort: this.sortKey,
			reverse: this.reverse,
			filter: JSON.stringify(this.filter),
			module: "signature",
		}
		this._configService.getUserSignatureList(payload).subscribe(
			(response) => {
				if (response) {
					this.signatureTemplate = response?.results
					this.signatureTemplate.forEach(function (value) {
						if (value?.content != undefined) {
							value.content = value?.content
								.replace(/<.*?>/g, " ")
								.replace(/<img[^>]*>/g, "")
								.replace(/&nbsp;/g, " ")
								.replace(/&amp;/g, "&")
								.slice(0, 120);
						}
					});
					this.signatureTemplateCount = response?.count
				}
			},
			(error) => {
				//todo
			}
		);	
	}

	/**
	 * After init
	 */
	 ngAfterViewInit(): void {
		if (environment.captcha) {
			// if (this.cookieService.check("captch-cookie")) {
				// this.showCaptcha = false;
			// } else {
				// this.getCaptcha();

			// }
			// this.cookieService.set('captch-cookie','2',1)
			// this.cookieValue = this.cookieService.get("captch-cookie");
		}
		const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
		if (element) {
		  element.style.visibility = 'visible';
		}
	}
	
	onFormChange = (form) => {
		form.form.touched = true;
	};

	profileImage = ($event, form) => {
		this.accountSetting.general.profile_image = $event;
		this.accountSetting.general.new_image = true;
		form.form.touched = true;
	};
	ProfileBgColor = ($event) => {
		this.avatarColor = $event;
	};

	currrentColor = ($event) => {
		if ($event == "image") {
			this.currentUser.profile_image = this.tempImage;
		} else {
			this.getUserProfile();
		}
	};

	changeGender = (args, form) => {
		this.accountSetting.general.profile.gender = args.target.defaultValue;
		this.onFormChange(form);
	};

	filterSelection(selection: string) {
		this.currentMenu = selection;
		if (window.innerWidth >= 1200) {
			this.submenu = true; // lg
		} else {
			this.submenu = false; //md
		}
		// localStorage.setItem("lastMenuSelected", selection);
	}

	toggleSidebar(name): void {
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
	}

	submenuToggaleClick(): void {
		this.submenu = !this.submenu;
		//console.log("sadvasdvsdvsdv", this.submenu);
	}

	updateSidebar() {
		if (window.innerWidth >= 1200) {
			this.submenu = true; // lg
		} else {
			this.submenu = false; //md
			// this.renderer.listen('window', 'click',(e:Event)=>{
			// 	if (e.target !== this.toggleSideMenu.nativeElement && e.target!==this.sidebar.nativeElement){
			// 		this.submenu= false;
			// 		console.log("ssssssssss",this.submenu);
			// 	}
			// })
		}
	}

	numberOnly(event): boolean {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	notification = 
	{

		"chat_notification" : {
		is_selected:false,
		"index":1,
		"label":"Chat Notification",
			"list": [
				{
					"id":"chat_notification1",
					"context":"Notify me when a chat conversation gets assigned to me",
					"switch":true,
					
				},
				{
					"id":"chat_notification2",
					"context":"Notify me when a chat conversation gets assigned to a group I am part of",
					"switch":true,
					
				},
				{
					"id":"chat_notification3",
					"context":"Notify me when a message is sent by the contact or my team on a converstaion I am assign",
					"switch":true,
					
				},
				{
					"id":"chat_notification4",
					"context":"paly a sound every time I receive a 	notification",
					"switch":true,
					
				},
				{
					"id":"chat_notification5",
					"context":"Notify me when a chat conversation gets assigned to me",
					"switch":true,
					
				},
			],
		},

		"email_track_notification":{
		is_selected:true,
		"index":2,
		"label":"Email Tracking Notification",
			"list": [
				{
					"id":"email_track_notification1",
					"context":"Notify me when a chat conversation gets assigned to me",
					"switch":true
				},
				{
					"id":"email_track_notification2",
					"context":"Notify me when a chat conversation gets assigned to me",
					"switch":true
				},
				{
					"id":"email_track_notification3",
					"context":"Notify me when a chat conversation gets assigned to me",
					"switch":true
				},
				{
					"id":"email_track_notification4",
					"context":"Notify me when a chat conversation gets assigned to me",
					"switch":true
				},
				{
					"id":"email_track_notification5",
					"context":"Notify me when a chat conversation gets assigned to me",
					"switch":true
				},
			]
		},

		"email_alert":{
		is_selected:false,
		"index":3,
		"label":"Email Alert",
			"list":  [
				{
					"id":"email_alert1",
					"context":"Get email when task is assigned to you",
					"switch":true
				},
				{
					"id":"email_alert2",
					"context":"Get email when task is assigned to you",
					"switch":true
				},
				{
					"id":"email_alert3",
					"context":"Get email when task is assigned to you",
					"switch":true
				},
				{
					"id":"email_alert4",
					"context":"Get email when a contact, account and deals assigned to you",
					"switch":true
				},
			],
		},

	 	"daily_email_digest" :{
		is_selected:true,
		"index":4,
		"label":"Daily Email Digest",
			"list": [
				{
					"id":"daily_email_digest1",
					"context":"Get daily email summary of contacts, accounts, and deals assigned to you",
					"switch":false
				},
				{
					"id":"daily_email_digest2",
					"context":"Get daily email summary of contacts, accounts, and deals assigned to you",
					"switch":true
				},
			],
		},

	 	"assignment_notification" : {
		is_selected:false,
		"index":5,
		"label":"Daily Email Digest",
			"list": [
				{
					"id":"assignment_notification1",
					"context":"Get daily email summary of contacts, accounts, and deals assigned to you",
					"switch":true
				},
				{
					"id":"assignment_notification2",
					"context":"Get Notification when a contacts, accounts or deal is assigned to you",
					"switch":false
				},
			]
		}
	}

	onChange($event, data){
		for(let i in data.value ){
			//console.log("iiiii",i)
			// this.notification[i]
		}
	}

	getCaptcha() {
		var code = "";
		this.captcha_temp = "";
		this._authService.getCaptcha({ type: "login" }).subscribe(
			(response: any) => {
				this.codeImage = response.captchaImage;
				this.captchaCode = response.captchaCode;
				this.captchaToken = response.captchaToken;
				this.showCaptcha = true;
			},
			(err) => {
				this._toastr.error(
					this._translateService.instant("Error.err_captcha_failed"),
					this._translateService.instant("Error.err_something_went_wrong")
				);
			}
		);
	}

	onEnable2FA(value){
		this.accountSetting.general.profile.is_2fa_enabled = !value
		this.generated_otp = ""
		if (this.accountSetting.general.profile.is_2fa_enabled){
		    this.is_otp_validate = true
		}
	}

	setPage(pageInfo) {
		this.pageNumber = pageInfo.offset;
		this.getUserSignatureList()
	}
	onSort(args): void {
		if (args.sorts) {
			this.pageNumber = 0;
			let sortKey = args.sorts[0].prop;
			this.sortKey = sortKey;
			if (args.sorts[0].dir == "desc") {
				this.reverse = "-";
			} else {
				this.reverse = "";
			}
			if (this.sortKey == 'title'){
				this.sortKey = 'name'
			}
			if (this.sortKey == 'signature'){
				this.sortKey = 'content'
			}
		}
		this.getUserSignatureList()
	}
	setLimit(args): void {
		this.pageNumber = 0;
		this.limit = parseInt(args);
		this.getUserSignatureList()
	}
	addSignature = (name) => {
		this.is_sign_loaded = true
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
		this.event = "add";
		this.sign_id = '-1'
		this.currentItem = {}
	}
	editSignature = (data) => {
		this.is_sign_loaded = true
		this.sign_id = data?.sign_id
		this._coreSidebarService.getSidebarRegistry('add-signature').toggleOpen();
		this.event = "edit";
		this.currentItem = data		
	}
	deleteSignature = (data) => {
		this.sign_id = data?.sign_id
		Swal.fire({
			title: this._translateService.instant("Message.msg_delete_title"),
			text: this._translateService.instant("Message.msg_delete_text"),
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: this._translateService.instant("Message.msg_delete_confirm"),
			cancelButtonText: this._translateService.instant("Message.msg_delete_cancel"),
			customClass: {
				confirmButton: "btn btn-primary",
				cancelButton: "btn btn-danger ml-1",
			},
		}).then((result) => {
			if (result.value) {
				this._configService
					.deleteUserSignature(data?.sign_id)
					.pipe(
						map((response: any) => {
							if (response.status == "success") {
								this.getUserSignatureList();
								this._toastr.success(
									this._translateService.instant("Message.msg_tag_deleted"),
									this._translateService.instant("Message.msg_delete_success"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							} else {
								this._toastr.error(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Error.err_delete_failed"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							}
							// Success
						}),
						catchError((error) => {
							this._toastr.error(
								this._translateService.instant("Error.err_delete_failed"),
								this._translateService.instant("Error.err_fail"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
							return throwError(error.statusText);
						})
					)
					.subscribe();
			}
		});
	};
	viewSignature = (data) => {
		this.is_sign_loaded = true
		this._coreSidebarService.getSidebarRegistry('add-signature').toggleOpen();
		this.event = "view";
		this.currentItem = data
	}
	setActiveMenu(){
		this.currentMenu = "signature"
	}
	hideSignSidebar($event) {
		this.sign_id = '-1'
		this.is_sign_loaded = false
		this.getUserSignatureList()
	}
	ngOnDestroy(): void {
		if (this.recaptchaSubscription) {
			this.recaptchaSubscription.unsubscribe();
		}
		const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
         if (element) {
              element.style.visibility = 'hidden';
        }
	}

}
