import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UntypedFormControl, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AdminService, ComputeService, ChannelService } from "../../service";
import {
	Product,
	ProductPlan,
	ProductPrice,
	ProductPlansWithPrices,
	PlanWithPrices,
	UnitOfMeasure,
	InstanceProduct,
	InstanceIdGroup,
	InstanceTypeGroup,
	Instance,
	Subscription,
	PreCheckSubscription,
} from "../../models";

@Component({
	selector: "app-product-detail",
	templateUrl: "./product-detail.component.html",
	styleUrls: ["./product-detail.component.scss"],
})
export class ProductDetailComponent implements OnInit {
	productId = "";
	infraonProduct: Product;
	pricingType = "year"; // "day", "week", "month", "year"
	pricingTypeData: PlanWithPrices[];
	productPlansWithPricesRaw: ProductPlansWithPrices;
	unitData: UnitOfMeasure[];
	// instance - display list on page
	instanceData: InstanceIdGroup[];
	// Model - instance select
	modalInstanceType = "trial"; // instance type
	selectedInstanceProduct: InstanceProduct;
	instanceTypeList: InstanceTypeGroup[];
	instanceList: Instance[];
	instanceForm: any;
	instanceFormName: any;
	selectedSubscription: Subscription;
	loader: boolean;

	// activePlan = null;
	// pricingPlansYearlyData = pricingPlansYearlyData;
	// pricingPlansMonthlyData = pricingPlansMonthlyData;

	constructor(
		private _modalService: NgbModal,
		private router: Router,
		private _route: ActivatedRoute,
		private _service: AdminService,
		private _compute: ComputeService,
		private _channel: ChannelService
	) {
		this._route.paramMap.subscribe((params: any) => {
			this.productId = params.get("id");
		});
		this.instanceForm = new UntypedFormControl("", Validators.required);
		this.instanceFormName = new UntypedFormControl("", Validators.required);
	}

	ngOnInit(): void {
		this._service.getProductPlans({ productId: this.productId }).subscribe({
			next: (response: any) => {
				this.productPlansWithPricesRaw = <ProductPlansWithPrices>response;
				this.infraonProduct = this.productPlansWithPricesRaw.product;
				for (let mapped of this.productPlansWithPricesRaw.mapped) {
					if (mapped.data.length > 0 && mapped.period === this.pricingType)
						this.pricingTypeData = mapped.data;
				}
				this.unitData = this._compute.unitsData(this.infraonProduct?.unit_of_measure);
			},
			error: (e: any) => {
				this.unitData = [];
				console.error(e);
			},
		});

		this._service
			.getInstanceSubscriptions({
				type: "instance",
				productId: this.productId,
			})
			.subscribe({
				next: (response: InstanceIdGroup[]) => {
					this.instanceData = response;
				},
				error: (e: any) => {
					this.instanceData = [];
					console.error(e);
				},
			});

		this._service.getInstances({}).subscribe({
			next: (response: InstanceTypeGroup[]) => {
				this.instanceTypeList = response;
				let newInstanceData = [];
				if (response && response.length < 2) {
					for (let d of this.instanceTypeList) {
						if (d.instance_type !== "trial")
							newInstanceData.push({
								instance_type: "trial",
								instances: [],
							});
						if (d.instance_type !== "prod")
							newInstanceData.push({
								instance_type: "prod",
								instances: [],
							});
					}
				}
				if (newInstanceData.length > 0) {
					this.instanceTypeList = newInstanceData.concat(this.instanceTypeList);
				}
			},
			error: (e: any) => {
				console.error(e);
			},
		});
	}

	togglePricingType = (name: string) => {
		this.pricingType = name;
		for (let mapped of this.productPlansWithPricesRaw.mapped) {
			if (mapped.period === this.pricingType) this.pricingTypeData = mapped.data;
		}
		// console.log(".....this.unitData", this.unitData);
	};

	onChangeUnits(t: number, num: number) {
		this.unitData.forEach((e) => {
			if (e.id === t) e.value = +num;
		});
	}

	getItemPrice = (item: ProductPrice) => {
		if (item.price) {
			return item.price / 100;
		} else if (item.tiers && item.tiers.length > 0) {
			return item.tiers[0].price / 100;
		}
	};

	getPerUnit = (name: string) => {
		if (name === "total") {
			return `${this.unitData[0].value} ${this.unitData[0].name}`;
		} else {
			return `${this.unitData[0].min} ${this.unitData[0].name}`;
		}
	};

	getTotalPrice = (item: ProductPrice): number => {
		let unit = this.unitData[0].value; // always takes first value to compute - rest value belong sot addons
		return this._compute.computePrice(unit, item) / 100;
	};

	togglePublishModal(modalPreview: string, instanceType: string, data: any) {
		const modalRef = this._modalService.open(modalPreview, {
			centered: true,
			size: "lg", // size: 'xs' | 'sm' | 'lg' | 'xl',
			windowClass: "modal-body-inner-footer",
		});
		this.loader = false;
		//  adding subscription binding with
		if (data.subscription) this.selectedSubscription = <Subscription>data.subscription;
		else this.selectedSubscription = null;
		this.modalInstanceType = instanceType;
		// console.log(".........data", typeof data, data);
		if (!data.product) data.product = this.infraonProduct;
		this.selectedInstanceProduct = <InstanceProduct>data;
		for (let d of this.instanceTypeList) {
			if (d.instance_type === instanceType) {
				this.instanceList = d.instances;
			}
		}
	}

	toggleModalInstanceType = (handle: string) => {
		this.instanceForm.setValue("");
		this.instanceFormName.setValue("");
		this.modalInstanceType = handle;
		for (let d of this.instanceTypeList) {
			if (d.instance_type === this.modalInstanceType) {
				this.instanceList = d.instances;
			}
		}
	};

	// setActivePlan = (name: any) => {
	// 	this.activePlan = name;
	// };

	closePopup() {
		this.loader = false;
		this._modalService.dismissAll();
	}

	onInstanceChange(val: any) {
		this.instanceForm.setValue(val);
	}

	onChangeInstanceInput(val: string) {
		this.instanceFormName.setValue(val);
	}

	preparePayload(item_price: ProductPrice, instance_id: string) {
		let subscription_items = [];
		if (item_price.pricing_model === "flat_fee") {
			// if pricing model is flat fee - no need to send quantity parameter
			subscription_items.push({
				item_price_id: item_price.id,
			});
		} else {
			subscription_items.push({
				item_price_id: item_price.id,
				// TODO-  quantity need to be taken care
				quantity: this.unitData[0].value,
			});
		}
		let payload = {
			subscription_items: subscription_items,
			subscription: {
				cf_infraon_instance: instance_id,
			},
		};
		// Binding subscription reference
		if (this.selectedSubscription) payload.subscription["id"] = this.selectedSubscription.id;
		return payload;
	}

	createTrialSubscription(payload: any) {
		this._service
			.createSubscription({
				payload: JSON.stringify(payload),
			})
			.subscribe({
				next: (response: any) => {
					////console.log("....response", response);
					this.closePopup();
					// TODO - need to deal properly
					window.location.reload();
				},
				error: (err: any) => {
					//console.log(err);
					this.closePopup();
				},
			});
	}

	proceedToPayment = (product: Product, item: ProductPlan, item_price: ProductPrice) => {
		if (this.instanceForm.value && !this.loader) {
			this.loader = true;
			console.warn("submitted: ", this.instanceForm.value, this.instanceFormName.value);
			let subscriptionId = "";
			if (this.selectedSubscription) subscriptionId = this.selectedSubscription.id;

			this._service
				.doPreCheck({
					instance: this.instanceForm.value,
					subscription: subscriptionId,
				})
				.subscribe({
					next: (response: PreCheckSubscription) => {
						if (response.valid) {
							if (response.checkout) {
								this.closePopup();
								let quantity = "";
								this.unitData.forEach((u) => {
									quantity = quantity + `,${u.value}`;
								});
								let params = {
									// binding the instance is which got created in precheck
									instance: response.instance_id,
									items: item.id,
									period: item_price.period_unit,
									quantity: quantity.substring(1),
								};
								if (subscriptionId !== "") {
									params["subscription"] = subscriptionId;
								}
								this.router.navigate(["/admin/payment"], { queryParams: params });
							} else {
								let payload = this.preparePayload(item_price, response.instance_id);
								this.createTrialSubscription(payload);
							}
						} else {
							console.warn("Checkout prechecked failed");
							this.closePopup();
						}
					},
					error: (err: any) => {
						//console.log(err);
						this.closePopup();
					},
				});
		}
	};
}
