import { Injectable } from "@angular/core";
import { ApiService } from "app/common/services/api/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class OrganizationService extends ApiService {
	constructor(http: HttpClient) {
		super(http);
	}
	loadOrgLocations(payload = {}) {
		return this.get("ux/common/org/location/", payload);
	}
	saveOrgLocationProfile(payload) {
		return this.post("ux/common/org/location/", payload);
	}
	loadOrganizationLocation(id) {
		return this.get("ux/common/org/location/" + id + "/");
	}
	deleteOrganizationLocation(id) {
		return this.delete("ux/common/org/location/" + id + "/");
	}
	uploadLicense(payload) {
		return this.post("ux/lic/license/", payload);
	}
	getLicenseDetails() {
		return this.get("ux/lic/license/details/");
	}
	getFileDetails() {
		return this.get("ux/lic/license/filedetails/");
	}
	download_service(payload: {}) {
		return this.get("ux/common/export/export/export_data/", payload);
	}
	downloadSampleCSV(payload = {}) {
		return this.get("ux/common/org/location/download_csv/", payload);
	}
	getCSVColumns(payload: any) {
		return this.post("ux/common/org/location/csv_cols/", payload);
	}
	validateCsvLocation(payload: any) {
		return this.post("ux/common/org/location/upload-csv/", payload);
	}
	createOrganizationLocation(payload: any) {
		return this.post("ux/common/org/location/create-csv/", payload);
	}
	getFilterOptions(payload: any) {
		return this.get("ux/common/filters/filters/filter_options/", payload);
	}
	getSavedFilter(payload) {
		return this.get("ux/common/filters/filters/filter/", payload);
	}
	deleteOrganizationLocations(payload: any) {
		return this.put("ux/common/org/location/bulk-delete/", payload);
	}
	getCIRelationMap(payload: any) {
		return this.post("ux/common/cmdb/relation/ci-rel-map/", payload);
	}
	getNewFeatures() {
		return this.get("ux/org/profile/get_feature_list/", {});
	}
	getSystemNotifications(payload) {
		return this.get("ux/common/sys_notify/system_notification/", payload);
	}
}
