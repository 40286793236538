<!-- <span>{{requester | json}}</span> -->
<div class="requester-call border card-bg p-1">
    <div class="requester-head d-flex justify-content-between align-items-center fw-500 gap-50">
        <span class="title-color">{{requester?.requester?.full_name}}</span>
        <div class="time title-color">{{showCallInterval()}}</div>
    </div>
    <div class="requester-user my-3 d-flex justify-content-center">
        <div class="avatar">
            <ng-container *ngIf="requester?.requesterProfile">
                <img
                class="avatar-content"
                class=""
                width="78"
                height="78"
                [src]="requester?.requesterProfile"
                alt="requester?.requester?.full_name"
            />
            </ng-container>
            <ng-container *ngIf="!requester?.requesterProfile">
                <span
                class="avatar-content xxl-font"
                [ngStyle]="{
                    'background-color':
                    requester?.requester?.avatar_color != undefined
                            ? requester?.requester?.avatar_color
                            : '#f59518'
                }"
                >{{
                    requester?.requester?.full_name
                        | initials
                        | slice: O:2
                }}</span
            >
            </ng-container>
        </div>

    </div>
    <div class="requester-btn-group d-flex justify-content-center align-items-center">
        <!-- <button type="button" (click)="increaseVolume()" class="btn btn-lg btn-dark rounded-circle btn-dial btn-icon" rippleEffect>
            <i class="fa-solid fa-plus"></i>
        </button>
        <button type="button" (click)="decreaseVolume()" class="btn btn-lg btn-dark rounded-circle btn-dial btn-icon" rippleEffect>
            <i class="fa-solid fa-hyphen"></i>
        </button> -->
        <button type="button" class="btn btn-lg btn-dark rounded-circle btn-mic btn-icon" (click)="onMute()" rippleEffect>
            <i *ngIf="!call_on_mute" class="fa-solid fa-microphone"></i>
            <i *ngIf="call_on_mute" class="fa-solid fa-microphone-slash"></i>
        </button>
        <button type="button" class="btn btn-lg btn-dark rounded-circle btn-call-hangup btn-danger btn-icon" (click)="closeRequesterCalling()" rippleEffect>
            <i class="fa-solid fa-phone-hangup"></i>
        </button>
    </div>
</div>