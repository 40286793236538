import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { NgForm } from "@angular/forms";
import Stepper from "bs-stepper";
import { FlatpickrOptions } from "ng2-flatpickr";
import { catchError, map, throwError } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { ProblemService } from "app/servicedesk/problem/services/problem.service";

@Component({
  selector: 'app-dns-problem-analysis-sidebar',
  templateUrl: './dns-problem-analysis-sidebar.component.html',
  styleUrls: ['./dns-problem-analysis-sidebar.component.scss']
})
export class DnsProblemAnalysisSidebarComponent implements OnInit {
	@Output("onHideProfile") onHideProfile = new EventEmitter<any>();
	@Input() options: any = [];
	@Input() problem_data: any = [];
	@Input() root_cause_id = "-1";
	@Input() core_sidebar_name = "app-problem-analysis-sidebar";
	@ViewChild("datepicker") datepicker: FlatpickrOptions;
	public causeData: any = {
		submitter: null,
		category: null,
		type: null,
		analysisTechnique: null,
		submission_date: null,
		details: {},
	};
	currentWhyIndex: number;
	isRootCauseFound: boolean;
	is_save_disabled = false;

	public techniqueDetailsWhy = [
		{
			reason: "",
			active: true,
		},
		{
			reason: "",
			active: false,
		},
		{
			reason: "",
			active: false,
		},
		{
			reason: "",
			active: false,
		},
		{
			reason: "",
			active: false,
		},
	];

	public isRootCauseFoundWhy = {
		isRootCauseFound: true,
	};

	public techniqueDetailsChronological = {
		details: "",
		isRootCause: false,
	};

	public techniqueDetailsKepner = {
		definition: "",
		location: "",
		time: "",
		frequency: "",
		size: "",
	};

	public basicDateOptions: FlatpickrOptions = {
		altInput: true,
	};
	private _bsStepper;
	private _horizontalWizardStepper: Stepper;
	submittorList = [];
	analysisTechniqueList: string[];
	categoryList = [];
	typeList = [];
	selectedTechnique: any = {};
	disableSaveBtn: boolean;
	userTZ: any;
	user_profile_map: any = {};
	rootLoad = false;

	constructor(
		@Inject(DOCUMENT) private document,
		private _coreSidebarService: CoreSidebarService,
		private _problemService: ProblemService,
		private _toastr: ToastrService,
		private _translateService: TranslateService
	) {}

	ngOnInit(): void {
		this.causeData["problem_id"] = this.problem_data?.problem_id;
		this.typeList = this.options?.analysis_type_list;
		this.analysisTechniqueList = this.options?.analysis_technique_list;
		this.submittorList = this.options?.users;
		this.user_profile_map = {};
		this.options?.users.forEach((element) => {
			this.user_profile_map[element.profile_id] = element;
		});
		this.categoryList = [];

		const userParams = JSON.parse(localStorage.getItem("userParams"));
		this.userTZ = userParams?.user_tz;
		if (this.analysisTechniqueList?.length > 0) {
			this.causeData["analysis_technique"] = this.analysisTechniqueList[0];
		}
		this.causeData["submitter"] = this.options?.current_user_id;
		this.onSubmitterChange(this.causeData?.submitter);
		this._bsStepper = document.querySelectorAll(".bs-stepper");
		this._horizontalWizardStepper = new Stepper(document.querySelector("#stepper1"), {
			linear: true,
			animation: true,
		});
		this.isRootCauseFound = true;
		if (this.root_cause_id != "-1") {
			this.getRootCauseData();
		}
	}

	hideAnaysisModal() {
		this.onHideProfile.emit("hide");
		this._coreSidebarService.getSidebarRegistry(this.core_sidebar_name).toggleOpen();
	}

	submitForm(form: NgForm) {
		// console.log(form)
	}

	/**
	 * Horizontal Wizard Stepper Next
	 *
	 * @param data
	 */
	horizontalWizardStepperNext = (data) => {
		if (data.form.valid === true) {
			this._horizontalWizardStepper.next();
		}
	};
	/**
	 * Horizontal Wizard Stepper Previous
	 */
	horizontalWizardStepperPrevious() {
		this._horizontalWizardStepper.previous();
	}

	onChangeOfTechnqiue(event) {
		this.selectedTechnique = event;
	}

	onCalenderClick() {
		this.datepicker.flatpickr.toggle();
	}

	ngOnDestroy() {
		this.datepicker?.flatpickr.close();
	}
	/**
	 * On Submit
	 */
	onSubmit = (data) => {
		if (data.form.valid === true) {
			this.is_save_disabled = true;
			if (this.causeData.submission_date instanceof Array) {
				this.causeData.submission_date = this.causeData.submission_date[0];
				this.causeData["submission_date"] = this.causeData.submission_date?.toLocaleString("en-US", {
					timeZone: "Asia/Kolkata",
				});
			}
			this.causeData["actual"] = this.isRootCauseFoundWhy?.isRootCauseFound;

			if (this.causeData?.analysis_technique?.name === "5 Why Analysis Technique") {
				this.causeData["details"] = {
					technique_details: this.techniqueDetailsWhy,
				};
			}

			if (this.causeData?.analysis_technique?.name === "Chronological Analysis Technique") {
				this.causeData["details"] = this.techniqueDetailsChronological;
			}

			if (this.causeData?.analysis_technique?.name === "Kepner-Tregoe Technique") {
				this.causeData["details"] = this.techniqueDetailsKepner;
			}

			this._problemService
				.saveProblemCause(this.causeData)
				.pipe(
					map((response: any) => {
						this.disableSaveBtn = false;
						if (response) {
							this._toastr.success(
								this._translateService.instant(response["message"]),
								this._translateService.instant("Message.msg_save_success"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
							this.hideAnaysisModal();
						} else {
							this._toastr.error(
								this._translateService.instant(response["message"]),
								this._translateService.instant("Error.err_save_failed"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
							this.is_save_disabled = false;
						}
						// Success
					}),
					catchError((error) => {
						this.is_save_disabled = false;
						return throwError(error.statusText);
					})
				)
				.subscribe();
		}
	};

	setCurrentIndex(index) {
		this.currentWhyIndex = index;
	}

	activateNextWhy() {
		// this.isRootCauseFound = !this.isRootCauseFound
		this.rootLoad = true;
		if (this.currentWhyIndex < this.techniqueDetailsWhy?.length - 1) {
			if (
				this.techniqueDetailsWhy[this.currentWhyIndex]?.reason.length > 0 &&
				this.isRootCauseFoundWhy.isRootCauseFound === false &&
				!this.techniqueDetailsWhy[this.currentWhyIndex + 1]?.active
			) {
				this.isRootCauseFoundWhy.isRootCauseFound = true;
				// {
				// "isRootCauseFound": true
				// }
				this.techniqueDetailsWhy[this.currentWhyIndex + 1].active = true;
			}
		}
		setTimeout(() => {
			this.rootLoad = false;
		}, 500);
	}

	onSubmitterChange($event) {
		const submitter_profiles = this.options?.users.filter((item) => item.profile_id == this.causeData.submitter);
		if (submitter_profiles?.length > 0) {
			this.causeData["submitter_profile"] = submitter_profiles[0];
		}
	}

	getRootCauseData() {
		this._problemService.getRootCauseData(this.root_cause_id, { root_cause_id: this.root_cause_id }).subscribe(
			(response) => {
				if (response) {
					this.causeData = response;
					this.isRootCauseFoundWhy["isRootCauseFound"] = this.causeData?.actual;
					if (this.causeData?.analysis_technique?.name === "5 Why Analysis Technique") {
						this.techniqueDetailsWhy = this.causeData?.details?.technique_details;
						let count = 0;
						this.techniqueDetailsWhy?.forEach((element, index) => {
							if (!element?.active && count != 1) {
								this.currentWhyIndex = index - 1;
								count = 1;
							}
						});
					}

					if (this.causeData?.analysis_technique?.name === "Chronological Analysis Technique") {
						this.techniqueDetailsChronological = this.causeData?.details;
					}

					if (this.causeData?.analysis_technique?.name === "Kepner-Tregoe Technique") {
						this.techniqueDetailsKepner = this.causeData?.details;
					}

					if (this.causeData?.analysis_type?.id == undefined) {
						this.causeData["analysis_type"] = undefined;
					}
				}
			},
			(error) => {
				//todo
			}
		);
	}
}
