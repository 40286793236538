<div class="full-body-height">
	<div class="main-content flex-height" [ngClass]="{ activeSidebarPanel: submenu === true }">
		<div class="left-sec">
			<div class="side-pannel">
				<div class="sidebar">
					<div class="assert-side-menu full-height">
						<div class="sidebar-content email-app-sidebar flex-auto-height">
							<div class="top-sidebar flex-initial">
								<div class="card mb-0 d-flex justify-content-between">
									<div class="d-inline-flex">
										<div class=" org-image-container border ng-star-inserted">
											<img
												class="thumbnail-image"
												src="{{ orgData.organization_name.profile_image || '' }}"
												width="40"
												height="40"
											/>
										</div>
										<div class="m-1">
											<span class="fw-500">{{ orgData.organization_name.name | translate }}</span>
											<span class="primary-color">
												{{ orgData.org_id }}
											</span>
										</div>
									</div>
									<div class="m-1">
										<span class="primary-color">
											{{ orgData.website }}
											<i class="fa-light fa-external-link"></i>
										</span>
									</div>

								</div>
							</div>
							<div class="email-app-menu flex-1">
								<div class="sidebar-menu-list msp-org-menu-list flex-auto-height">
									<div id="menu" class="list-group list-group-messages flex-1">
										<ng-scrollbar class="custom-scrollbar section-scrollbar">
											<ng-container *ngFor="let opt of org_options; let i = index">
												<a
													id="btn{{ opt.handle }}"
													name="menu{{ opt.handle }}"
													class="list-group-item list-group-item-action"
													[class.active]="currentMenu === opt?.handle"
													(click)="filterSelection(opt.handle)"
													attr.data-testid="btn_{{ opt.handle }}"
												>
													<span class="align-middle">{{ opt.title }}</span>
												</a>
											</ng-container>
										</ng-scrollbar>
									</div>
								</div>
							</div>
							<div class="footer-menu-content">
								<div class="card footer-card m-1">
									<div class="d-flex">
										<div class="d-inline-flex py-1 pl-1 pr-3">
											<div class="mt-2">
												<i class="fa-2x fa-light fa-wallet"></i>
											</div>
											<div class="m-1">
												<span>{{ "UI.k_available_bal" | translate }}</span>
												<h5>$ 8,0000</h5>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="main-sec">
			<div class="action-section">
				<div class="email-user-list full-heigth" *ngIf="currentMenu == 'Products'">
					<app-organization-product-details>
					</app-organization-product-details>
				</div>
				<div class="email-user-list full-heigth" *ngIf="currentMenu == 'MSP Assigned Users'">
					<app-msp-assigned-user
					>
					</app-msp-assigned-user>
				</div>
			</div>
		</div>
	</div>
</div>