<!-- <app-notify-banner
	*ngIf="hasNotification"
	[rem_days]="rem_days"
	(clsNotification)="getNotificationToggle($event)"
></app-notify-banner> -->
<app-notify-banner
	*ngIf="hasCountNotification"
	[banner_type]="2"
	[banner_msg]="banner_msg"
	(clsNotification)="getNotificationCountToggle($event)"
></app-notify-banner>
<ng-container *ngIf="isMaintainanceWindowActive && maintainance_msg !== ''">
	<div class="notification-bar maintenance-alert">
		<div class="notification-txt sm-font">{{ maintainance_msg | translate }}</div>
		<div class="close-btn" (click)="onRemoveMaintenance()">
			<i class="fa-light fa-xmark"></i>
		</div>
	</div>
</ng-container>
<!-- Progressbar changes -->
<ng-container *ngIf="this.showProgressBar">
	<div class="progressbar modal-dialog d-flex justify-content-between px-1">
		<div class="d-flex justify-content-center align-items-between">
			<div class="d-flex justify-content-between align-items-center gap-1">
				<img width="" class="" src="assets/images/getting-started/exclamation-light.svg" alt="" />

				<p *ngIf="showProgressDefaultTxt" class="text-primary fw-600 text-nowrap">
					{{ "UI.k_demo_video_title_progress" | translate }}
				</p>
				<p *ngIf="showProgressCompleteTxt" class="text-center fw-600 text-primary text-nowrap">
					{{ "UI.k_demo_video_title_complete" | translate }}
				</p>
				<div class="progress-wrapper">
					<ngb-progressbar
						class="progresswidth"
						type="primary"
						[value]="currentValue"
						[max]="100"
						[striped]="true"
						[animated]="true"
						[height]="progressbarHeight"
					></ngb-progressbar>
				</div>
				<span class="text-primary fw-600">{{ this.currentValue }}%</span>
			</div>
		</div>
		<!-- <img src="assets/images/getting-started/Close.svg"  class="mr-2" width="20px" alt="" /> -->
		<!-- <button
			type="button"
			data-dismiss="modal"
			id="btnClose"
			name="btnClose"
			aria-label="Close"
			(click)="hideBar()"
			class="btn btn-flat-secondary btn-icon"
			rippleEffect
			[attr.data-testid]="'btnClose'"
		>
			<i class="fa-light fa-xmark"></i>
		</button> -->
	</div>
</ng-container>

<!-- vertical-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'vertical'">
	<ng-container *ngIf="!isOnline">
		<div class="online-alertbox d-flex">
			<div class="alert-content">
				<div class="alert-message">
					<span class="mr-50">
						<i class="fal fa-exclamation-triangle"></i>
					</span>
					<strong>{{ "Error.err_no_internet_connection_code" | translate }}</strong>
					{{ "Message.msg_no_internet_connection" | translate }}
				</div>
			</div>
			<div class="alert-close">
				<button class="btn btn-flat-secondary btn-icon waves-effect" (click)="closeAlertModel()">
					<i class="fa-light fa-xmark"></i>
				</button>
			</div>
		</div>
	</ng-container>
	<vertical-layout></vertical-layout>
</ng-container>
<!-- / vertical-layout -->

<!-- horizontal-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'horizontal'">
	<horizontal-layout></horizontal-layout>
</ng-container>
<!-- / horizontal-layout -->

<!-- theme customizer -->
<core-sidebar name="themeCustomizer" class="customizer d-none d-md-block" *ngIf="coreConfig.layout.customizer">
	<a
		class="customizer-toggle d-flex align-items-center justify-content-center"
		(click)="toggleSidebar('themeCustomizer')"
	>
		<span [data-feather]="'settings'" [class]="'spinner'"></span>
	</a>
	<core-theme-customizer></core-theme-customizer>
</core-sidebar>

<!-- theme customizer -->
<!-- <core-sidebar name="notification" class="customizer d-none d-md-block" *appFeatureActive="['UINotifications']"> -->
	<!-- <a
		*ngIf="coreConfig.layout.notifications"
		class="customizer-toggle d-flex align-items-center justify-content-center"
		(click)="toggleSidebar('notification')"
	>
		<div class="position-relative d-inline-block">
			<span [data-feather]="'bell'" [class]="'font-medium-4'"></span>
			<div *ngFor="let notify of notification; let i = index">
				<span class="badge badge-danger badge-up font-small-1">{{ notify.count }}</span>
			</div>
		</div>
	</a>
	<app-notification></app-notification> -->

	<!-- <core-theme-customizer></core-theme-customizer> -->
<!-- </core-sidebar> -->
<!-- / theme customizer -->

<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="shortcutkey" overlayClass="modal-backdrop">
	<app-keyboard-shortcut *ngIf="hideProfile" (onHideProfile)="onHideProfile($event)"></app-keyboard-shortcut>
</core-sidebar>
