<div class="full-body-height">
    <div class="main-content">
        <div class="main-sec bg-body">
            <div class="action-bar d-flex align-items-center justify-content-between p-1 bg-card">
                <div class="left-section">
                    <div class="action-search">
                        <!-- <app-search
                            (getfilterData)="getFilterSearch($event)"
                            [filterDetails]="getItemList"
                            (genericFilter)="genericSearchFilter($event)"
                            [showDataTable]="showDataTable"
                            [columnOptions]="columnOptions"
                            [conditionOptions]="conditionOptions"
                            [savedData]="savedData"
                            [Options]="option"
                            (isExpandSearchWidth)="getSearchWidth($event)"
                        ></app-search> -->
                    </div>
                </div>
                <div class="right-section">
                    <button type="button" 
                    id="createBot"
                    [attr.data-testid]="'createBot'"
                    rippleeffect="" 
                    (click) = "openCreateBotSidebar()"
                    class="btn btn-primary waves-effect waves-float waves-light ng-star-inserted">
                    <i class="fas fa-plus"></i>{{ "UI.k_create_new_bot" | translate }}</button>
                </div>

            </div>
            <div class="flex-height">
                
                    <ng-scrollbar class="custom-scrollbar section-scrollbar">
                        <div class="bot-content  p-1">
                            <ng-container *ngFor = "let item of menu; let i=index">
                                <div class="inner-card p-1 border bg-card">
                                    <div class="assistance-card">
                                        <div class="assistance-card-container d-flex gap-1 justify-content-between">
                                            <div class="d-flex gap-1">
                                                <div class="assistance-image">
                                                    <img *ngIf="predefined_bot_img_map?.[item?.name]" [src]=predefined_bot_img_map?.[item?.name] alt="" id="img-{{i}}">
                                                    <img *ngIf="!predefined_bot_img_map?.[item?.name]" src="../../../../../assets/images/bots-configuration/ops-assistant.svg" alt="" id="img-{{i}}">
                                                </div>
                                                <div class="assistance-content">
                                                    <div class="assistance-header">
                                                        <div>
                                                            <span class="title-color fw-500">{{ item.name }}</span>
                                                        <span class="text-muted ml-50" *ngIf = "item?.topics?.length > 0">{{ item?.topics?.length }} Topics</span>
                                                        </div>
                                                        
                                                    </div>
                                                    <div class="assistance-body">
                                                        <span class="fw-400">{{ item.description }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div
                                                                ngbDropdown
                                                                class="no-arrow"
                                                            >
                                                                <button
                                                                    ngbDropdownToggle
                                                                    class="btn btn-flat-secondary p-0-5rem"
                                                                    type="button"
                                                                    id="option_dropdown"
                                                                    rippleEffect
                                                                >
                                                                    <i
                                                                        class="fa-light fa-ellipsis-vertical fw-500 font-medium-1"
                                                                    ></i>
                                                                </button>
                                                                <div ngbDropdownMenu aria-labelledby="option_dropdown">
                                                                    <a
                                                                        ngbDropdownItem
                                                                        (click)="openEditBotSidebar(item)"
                                                                        id="linkEdit{{ i }}"
                                                                        name="linkEdit{{ i }}"
                                                                        class="text-primary"
                                                                        ><i class="fa-light fa-pen-to-square mr-75"> </i
                                                                        >{{ "UI.k_edit" | translate }}</a
                                                                    >
                                                                    <a
                                                                        ngbDropdownItem
                                                                        (click)="deleteBot(item)"
                                                                        id="linkDelete{{ i }}"
                                                                        name="linkDelete{{ i }}"
                                                                        class="text-danger"
                                                                        ><i class="fa-light fa-trash-can mr-75"> </i
                                                                        >{{ "UI.k_delete" | translate }}</a
                                                                    >
                                                                </div>
                                                            </div>
                                            </div>
                                        </div>
                                        <div class="assistance-card-footer mt-1 d-flex justify-content-end">
                                            <button
                                            *ngIf="item?.is_enabled == false"
                                            id="bot-initate-btn-{{i}}" 
                                            class="btn btn-xs sm-font btn-outline-primary" 
                                            [attr.data-testid]="item.id"
                                            (click) = "initiateBot(item)"
                                            >
                                            <span>Click to initate </span>
                                        </button>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                    </ng-scrollbar>
            </div>
            <div>
                <div class="card-pagination">
                    <div class="pagination-inner">
                        <div class="w-100">
                            <dns-pagination
                                [totalCount]="botConfigCount"
                                [currentCount]="currentCount"
                                (setPage)="setPage($event)"
                                (setLimit)="setLimit($event)"
                                [page]="pageNumber"
                                [pageSize]="limit"
                            ></dns-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="create-bot-sidebar" overlayClass="modal-backdrop">
<app-create-bot
*ngIf = "showCreateBotSlider"
[isEdit] = "isEdit"
[itemObj] = "selectedItem"
(onHideSidebar) = hidecreateBotSidebar($event)

>
</app-create-bot>
</core-sidebar>