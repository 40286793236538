import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import Stepper from "bs-stepper";
import { FlatpickrOptions } from "ng2-flatpickr";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CoreConfigService } from "@core/services/config.service";
import { AdminService, ChannelService, ChargebeeService, ComputeService } from "../../service";
import {
	ProductPlansWithPrices,
	PlanWithPrices,
	Product,
	ProductPrice,
	UnitOfMeasure,
	ChannelChangePayload,
	PreCheckSubscription,
	CustomerCard,
} from "../../models";
import { map } from "rxjs/operators";
import isEqual from "lodash-es/isEqual";
import store from "store";
import { generate_uuid } from "app/common/utils/utils";
@Component({
	selector: "app-payment",
	templateUrl: "./payment.component.html",
	styleUrls: ["./payment.component.scss"],
})
export class PaymentComponent implements OnInit, OnDestroy {
	@Output("hideShowDetails") hideShowDetails = new EventEmitter();
	private bsStepper: any;
	private horizontalWizardStepper: Stepper;
	public organizationData: any = {
		user: { email: "", full_name: "" },
		organization: {
			company_name: "",
			shipping_address: "",
			billing_address: "",
			is_billing_address: false,
			tax_id: "",
		},
	};

	// product info pushed
	productSubscription?: string;
	productInstance: string;
	productItemIds: string[];
	productInfo: Product;
	baseProductInfo: Product;
	pricingType: string = "month";
	pricingTypeData: PlanWithPrices[];
	productDataRaw: ProductPlansWithPrices;
	step: number = 0;
	productQuantity: string;
	unitData: UnitOfMeasure[];
	defaultCurrency: string = "USD";
	hostedPageId: string;
	assetMapObj: any;
	userMapObj: any;
	addOnObj: any;
	currentAssetCount: number = 0;
	currentUserCount: number = 0;
	nonDiscPrice: number = 0;
	discValue: number = 0;
	editAsset: boolean = false;
	editUser: boolean = false;
	assetOptions: [];
	totalVPrice = 0;
	totalSubPrice = 0;
	priceMap = {};
	productPlansWithPricesRaw: ProductPlansWithPrices;
	productPlans: any = [];
	showPlan: boolean = false;
	congratulationUser = false;
	defaultPlan = "Infraon-Assets-Enterprise";
	instancePlanMap: any = {};
	defaultProducts = ["Infraon-Assets", "Infraon-Helpdesk", "Infraon-ITSM"];
	currentKey = "helpdesk";
	logoKey = "helpdesk";
	event = "add";
	infraonProd = 1;
	domain = "";
	product_name = "";
	itsmPriceMap = {};
	shipping_address_obj = {};
	billing_address_obj = {};
	showOrgData = false;
	public usageObj: any;
	public usageMetrics = [];
	public isTrial = true;
	activePlan: any;
	logoMap = {
		asset: "assets/images/products/product-asset.svg",
		helpdesk: "assets/images/products/product-helpdesk.svg",
		infinity: "assets/images/products/product-infinity.svg",
		itim: "assets/images/products/product-itim.svg",
		itsm: "assets/images/products/product-itsm.svg",
		nms: "assets/images/products/product-nms.svg",
		uptime: "assets/images/products/product-uptime.svg",
	};
	plan = "Enterprise";
	public infraonProducts = [
		{
			name: "Infraon Assets",
			key: "asset",
			units: ["user", "asset"],
			default_products: ["Infy_Ats_Pro_1"],
		},
		{
			name: "Infraon Helpdesk",
			key: "helpdesk",
			units: ["user"],
			default_products: ["Infy_HD_Ent_1"],
		},
		// {
		// 	name: "Infraon Infinity",
		// 	key: "infinity",
		// 	units: [],
		// 	default_products: [],
		// },
		// {
		// 	name: "Infraon ITIM",
		// 	key: "itim",
		// 	units: [],
		// 	default_products: [],
		// },
		{
			name: "Infraon ITSM",
			key: "itsm",
			units: ["user", "asset"],
			default_products: ["Infy_ITSM_Pro_1"],
		},
		// {
		// 	name: "Infraon NMS",
		// 	key: "nms",
		// 	units: [],
		// 	default_products: [],
		// },
		// {
		// 	name: "Infraon Uptime",
		// 	key: "uptime",
		// 	units: [],
		// 	default_products: [],
		// },
	];
	allowedUnits = [];
	unitsMap = {
		asset: ["user", "asset"],
		helpdesk: ["user"],
		infinity: [],
		itim: [],
		itsm: ["user", "asset"],
		nms: [],
		uptime: [],
	};
	showCreditApplied: boolean = false;
	showCreditMsg: boolean = false;
	refundCredits = 0;
	remainingCredits = 0;
	showPriceSkeleton: boolean = true;
	public disableSave = false;
	totalPricePay = 0;
	prodChangeName = "";
	allowManage = true;
	initDefault = {};
	subTotal = 0;
	purchasedCount = {};
	initialCredits = 0;
	showPricing = false;
	nextInvoice = false;
	currentTrigger = "";
	public isPartnerOrg: boolean = false;
	public currentSubscriptionTiers = [];
	public userEditDisabled: boolean = false;
	public addOnOptions = [];
	public currentAssetAddOn: any;
	public addonItem: any = [];
	public addonTypes: any = [
		{
			id: "asset",
			name: "Asset",
			icon: "fa-regular fa-box-archive",
		},
		// {
		// 	id: "user",
		// 	name: "User",
		// 	icon: "fa-regular fa-user-group"
		// }
	];
	public extraAddOns = [];
	public addonUnitPrice = 0;
	public defaultAddonObj = null;
	public addonMapped = [];
	public totalAddonPrice = 0;
	public totalPriceWithAddon = 0;
	public addonDropdownOptions = [];
	public showDuplicateAddonMsg = false;

	constructor(
		private _coreSidebarService: CoreSidebarService,
		private _coreConfigService: CoreConfigService,
		private router: Router,
		private _route: ActivatedRoute,
		private ref: ChangeDetectorRef,
		private _modalService: NgbModal,
		private _compute: ComputeService,
		private _service: AdminService,
		private _chargebee: ChargebeeService,
		private _channel: ChannelService,
		private _changeDetectorRef: ChangeDetectorRef
	) {
		this._route.queryParamMap.subscribe((params: any) => {
			this.productInstance = params.get("instance");
			let items = params.get("items");
			this.productItemIds = items.split(",");
			this.pricingType = params.get("period");
			this.productQuantity = params.get("quantity");
			this.productSubscription = params.get("subscription");
			this.event = params.get("event");
			this.domain = params.get("domain");
			this.getProductPlans();
		});
		this._coreConfigService.setConfig({
			layout: {
				adminPortal: true,
				layoutStyle: "fixed",
				navbar: { type: "fixed-top", hidden: true },
				menu: { collapsed: false, hidden: true },
				footer: { hidden: true, type: "footer-static" },
			},
		});
	}

	ngOnInit(): void {
		this.isPartnerOrg = store.get("is_partner_org");
		if (this.isPartnerOrg) {
			this.router.navigate(["/error/403"]);
		}
		if (this.event === "edit") {
			this.allowManage = false;
		}
		this.horizontalWizardStepper = new Stepper(document.querySelector("#stepper1"), {});
		this.bsStepper = document.querySelectorAll(".bs-stepper");
		if (this.productSubscription && this.productSubscription.split("_")[0] === "trial") {
			this.isTrial = true;
		} else {
			this.isTrial = false;
		}
		this.getUsageMetrics();
		// TODO - need to deal the currency code here
		this.getProductDetailsOnPlanChange(this.productItemIds, "init");

		this._service.getChargebeeConfiguration().subscribe({
			next: (response: any) => {
				this._chargebee.initChargebee(response.key, response.site);
			},
			error: (e: any) => {
				console.error(e);
			},
		});
		// setTimeout(() => {
		// 	this.initDefault = {
		// 		product: this.currentKey,
		// 		plan: this.activePlan,
		// 		user: this.currentUserCount,
		// 		asset: this.currentAssetCount,
		// 		tenure: this.pricingType,
		// 	};
		// }, 2000);

		this.getCreditNotes();
	}

	ngOnDestroy(): void {
		this._coreConfigService.config = {
			layout: {
				navbar: {
					hidden: false,
				},
				menu: {
					hidden: false,
				},
			},
		};
		this._modalService.dismissAll();
	}

	onChangeUnits = (t, num) => {
		// console.log("entering onChangeUnits ", t, num);
		if (t === "selAssets") {
			if (this.currentKey === "asset") {
				this.currentUserCount = this.assetMapObj[String(num)];
			}
			this.currentAssetCount = Number(num);
			// this.editAsset = !this.editAsset;
		} else {
			this.currentUserCount = Number(num);
			this.editUser = !this.editUser;
		}
		this.showPriceSkeleton = true;
		this.setPriceMap(this.pricingTypeData);
		this.totalVPrice = this.getTotalPrice(num);
		this.totalSubPrice = this.getSubTotalPrice(num);
		this.subTotal = this.totalSubPrice;
		if (this.event === "edit") {
			setTimeout(() => {
				this.manageSubscription();
			}, 1000);
		}
		if (!this.isTrial) {
			this.getCostEstimate();
			// this.getCreditNotes();
		}
		// console.log("exiting onChangeUnits ");
		this.setOverallPrice();
	};

	onUserCountChange = ($event) => {
		this.currentUserCount = Number($event);
		this.setPriceMap(this.pricingTypeData);
		this.totalVPrice = this.getTotalPrice(this.currentUserCount);
		this.totalSubPrice = this.getSubTotalPrice(this.currentUserCount);
		// console.log("305 this.totalSubPrice", this.totalSubPrice);
		this.subTotal = this.totalSubPrice;
		if (this.isTrial) {
			// console.log("before false 292");
			this.showPriceSkeleton = false;
		}
		this.setOverallPrice();
	};

	totalPrice(data: ProductPrice, num): any {
		let quantity = num; // always takes first value to compute - rest value belongs to addons
		let totalValue = this._compute.computePrice(quantity, data, this.currentSubscriptionTiers) / 100;
		let tempDisc = 0;
		let tempnonDisc = 0;
		// console.log("totalValue totalPrice", totalValue);
		// this.discValue = ((16.67 / 100) * totalValue).toFixed();
		tempDisc = Number(((1 / 6) * totalValue).toFixed());
		// this.discValue += tempDisc;
		tempnonDisc = Number(totalValue) + Number(tempDisc);
		// this.nonDiscPrice += tempnonDisc;
		return { price: totalValue, discount: tempDisc, nonDiscount: tempnonDisc };
	}

	getSubTotalPrice(num): number {
		let s = 0;
		let tempDisc = 0;
		let tempnonDisc = 0;
		let data = this.pricingTypeData || [];
		// console.log("getSubTotalPrice 351 data", data);
		// console.log("this.currentKey", this.currentKey);
		data.forEach((d) => {
			if (d?.item?.cf_infraon_addon_type === undefined || d?.item?.cf_infraon_addon_type !== "count") {
				if (this.currentKey === "itsm") {
					if (d?.item_price?.item_type === "addon") {
						let assetPrice = this.totalPrice(d.item_price, this.currentAssetCount);
						// console.log("assetPrice pp 338", assetPrice);
						s += assetPrice["price"];
						tempDisc += assetPrice["discount"];
						tempnonDisc += assetPrice["nonDiscount"];
						this.itsmPriceMap["asset"] = assetPrice["price"];
					} else {
						let userPrice = this.totalPrice(d.item_price, this.currentUserCount);
						// console.log("userPrice pp 345", userPrice);
						s += userPrice["price"];
						tempDisc += userPrice["discount"];
						tempnonDisc += userPrice["nonDiscount"];
						this.itsmPriceMap["user"] = userPrice["price"];
					}
					// console.log("this.itsmPriceMap 351", this.itsmPriceMap);
				} else {
					// console.log("in else");
					let Price = this.totalPrice(d.item_price, num);
					// console.log("PRICEEEEEEEEEEEEEEE pp else", Price);
					// s += this.totalPrice(d.item_price, num);
					s += Price["price"];
					tempDisc += Price["discount"];
					tempnonDisc += Price["nonDiscount"];
				}
			}
		});
		this.nonDiscPrice = tempnonDisc;
		this.discValue = tempDisc;
		return s;
	}

	getTotalPrice(num): number {
		let s = 0;
		let tempDisc = 0;
		let tempnonDisc = 0;
		let data = this.pricingTypeData || [];
		// console.log("data Total PRice 392", data);
		// console.log("this.currentKey", this.currentKey);
		data.forEach((d) => {
			if (d?.item?.cf_infraon_addon_type === undefined || d?.item?.cf_infraon_addon_type !== "count") {
				if (this.currentKey === "itsm") {
					if (d?.item_price?.item_type === "addon") {
						// console.log("calling total price addon", d.item_price);
						// console.log("calling total price currentAssetCount", this.currentAssetCount);
						let assetPrice = this.totalPrice(d.item_price, this.currentAssetCount);
						// console.log("assetPrice pp 380", assetPrice);
						s += assetPrice["price"];
						tempDisc += assetPrice["discount"];
						tempnonDisc += assetPrice["nonDiscount"];
						this.itsmPriceMap["asset"] = assetPrice["price"];
						// console.log("this.itsmPriceMap", this.itsmPriceMap);
					} else {
						// console.log("calling total price", d.item_price);
						// console.log("calling total price currentAssetCount", this.currentAssetCount);
						let userPrice = this.totalPrice(d.item_price, this.currentUserCount);
						// console.log("userPrice pp 390", userPrice);
						s += userPrice["price"];
						tempDisc += userPrice["discount"];
						tempnonDisc += userPrice["nonDiscount"];
						this.itsmPriceMap["user"] = userPrice["price"];
						// console.log("this.itsmPriceMap else", this.itsmPriceMap);
					}
				} else {
					let Price = this.totalPrice(d.item_price, num);
					// s += this.totalPrice(d.item_price, num);
					// console.log("Price pp 400", Price);
					s += Price["price"];
					tempDisc += Price["discount"];
					tempnonDisc += Price["nonDiscount"];
				}
			}
		});
		this.nonDiscPrice = tempnonDisc;
		this.discValue = tempDisc;
		return s;
	}

	// getTotalPrice(num): number {
	// 	let s = 0;
	// 	let data = this.pricingTypeData || [];
	// 	// console.log("data getTotalPrice", data);
	// 	// console.log("this.currentKey", this.currentKey);
	// 	data.forEach((d) => {
	// 		if (this.currentKey === "itsm") {
	// 			if (d?.item_price?.item_type === "addon") {
	// 				let assetPrice = this.totalPrice(d.item_price, this.currentAssetCount);
	// 				s += assetPrice;
	// 				this.itsmPriceMap["asset"] = assetPrice;
	// 			} else {
	// 				let userPrice = this.totalPrice(d.item_price, this.currentUserCount);
	// 				s += userPrice;
	// 				this.itsmPriceMap["user"] = userPrice;
	// 			}
	// 		} else {
	// 			s += this.totalPrice(d.item_price, num);
	// 		}
	// 	});
	// 	console.log("getTotalPrice 321", s);
	// 	return s;
	// }

	togglePricingType = (name: string, change_type = "tenure_change") => {
		// console.log("entering togglePricingType name", name);
		this.pricingType = name;
		for (let mapped of this.productDataRaw.mapped) {
			if (mapped.period === this.pricingType) {
				this.pricingTypeData = mapped.data;
				// console.log("togglePricingType", this.pricingTypeData);
				for (let mapped_data of mapped.data) {
					// console.log("mapped_data", mapped_data);
					if (mapped_data?.item?.type === "plan") {
						this.setParams(mapped_data, change_type);
					}
					if (mapped_data?.item?.type === "addon" && mapped_data?.item?.cf_infraon_addon_type !== "count") {
						mapped_data?.item_price?.tiers.forEach((d) => {
							d["item_price_id"] = mapped_data?.item_price?.id;
						});
						this.currentSubscriptionTiers = this.currentSubscriptionTiers.concat(
							mapped_data?.item_price?.tiers
						);
						// console.log("this.currentSubscriptionTiers", this.currentSubscriptionTiers);
					}
				}
			}
		}
		this.showPriceSkeleton = true;
		this.setPriceMap(this.pricingTypeData);
		if (this.event === "edit") {
			setTimeout(() => {
				this.manageSubscription();
			}, 1000);
		}
		this.setAddonPricing();
		if (!this.isTrial) {
			this.getCostEstimate();
			// this.getCreditNotes();
		}
		// console.log("exiting togglePricingType");
		this.setOverallPrice();
	};

	toggleSidebar(name: any): void {
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
		this.hideShowDetails.emit("hide");
	}

	// horizontalWizardStepperPrevious() {
	// 	this.horizontalWizardStepper.previous();
	// }

	preparePayload() {
		// console.log("SHYAM this.currentUserCount", this.currentUserCount);
		// console.log("SHYAM this.currentAssetCount", this.currentAssetCount);
		// [{ item_price_id: "cbdemo_advanced-USD-monthly", quantity: 1 }]
		let subscription_items = [];
		// console.log("this.pricingTypeData", this.pricingTypeData)
		if (this.addonItem.length > 0 && this.extraAddOns?.length > 0) {
			this.addonItem.forEach((d) => {
				let item = {
					item_price_id: d.item_price_id,
				};
				if (d?.unit) {
					item["addon_unit"] = d?.unit;
				}
				if (d?.quantity) {
					item["addon_quantity"] = d?.quantity;
				}
				if (d?.pricing_model !== "flat_fee") {
					item["quantity"] = d?.quantity;
				}
				subscription_items.push(item);
			});
		}
		this.pricingTypeData.forEach((d) => {
			let current_unit;
			if (d.item?.type === "addon") {
				current_unit = d.item?.unit;
			} else {
				current_unit = d.item?.metadata?.unit;
			}
			if (d?.item?.cf_infraon_addon_type !== "count") {
				if (d.item_price.pricing_model === "flat_fee") {
					// if pricing model is flat fee - no need to send quantity parameter
					subscription_items.push({
						item_price_id: d.item_price.id,
					});
				} else {
					subscription_items.push({
						item_price_id: d.item_price.id,
						// TODO-  quantity need to be taken care
						// quantity: this.currentKey === "itsm" ? this.currentUserCount : this.currentAssetCount,
						quantity: current_unit === "user" ? this.currentUserCount : this.currentAssetCount,
					});
				}
			}
		});
		// console.log("subscription_items", subscription_items)
		let payload = {
			subscription_items: subscription_items.reverse(), // todo: need to always pass plan first and then add ons
			subscription: {
				cf_infraon_instance: this.productInstance,
			},
		};
		// console.log("SHYAM PAYLOAD", payload);
		// Binding subscription reference
		if (this.productSubscription) payload.subscription["id"] = this.productSubscription;
		if (this.shipping_address_obj) payload["shipping_address"] = this.shipping_address_obj;
		if (this.billing_address_obj) payload["billing_address"] = this.billing_address_obj;
		if (this.organizationData.organization.company_name)
			payload["organization"] = this.organizationData.organization.company_name;
		return payload;
	}

	horizontalWizardStepperNext = (data: any) => {
		this.showDuplicateAddonMsg = this.checkForDuplicateAddons(this.addonItem);
		if (data.form.valid === true && !this.showDuplicateAddonMsg) {
			if (this.step === 1) {
				this.disableSave = true;
				this.saveOrgDetails();
				let payload = this.preparePayload();
				// TODO - customer ID need to take care
				this._service
					.doPreCheck({
						instance: this.event === "edit" ? this.productInstance : this.infraonProd,
						subscription: this.productSubscription || "",
						sub_domain: this.domain,
						type: this.infraonProd,
					})
					.subscribe({
						next: (response: PreCheckSubscription) => {
							if (response.valid) {
								// binding the instance is which got created in precheck
								payload.subscription.cf_infraon_instance = response.instance_id;
								this.disableSave = false;
								if (response.checkout) {
									this.processCheckout(payload);
								} else {
									this._service
										.createSubscription({
											payload: JSON.stringify(payload),
										})
										.subscribe({
											next: (response: any) => {
												console.log("....response", response);
											},
											error: (err: any) => {
												console.log(err);
											},
										});
								}
							} else {
								console.warn("Checkout prechecked failed");
							}
						},
						error: (err: any) => {
							//console.log(err);
							this.disableSave = false;
						},
					});
			} else if (this.step === 0) {
				if (this.editUser) {
					this.onChangeUnits("inpUsers", this.currentUserCount);
				}
				this.step = this.step + 1;
				this.horizontalWizardStepper.next();
				this.getOrgDetails();
			} else {
				this.step = this.step + 1;
				this.horizontalWizardStepper.next();
			}
		}
	};

	horizontalWizardStepperPrev(data: any) {
		this.step = this.step - 1;
		this.horizontalWizardStepper.previous();
	}

	onSubmit() {
		this.hideShowDetails.emit("hide");
	}

	closePaymentPage() {
		this.router.navigateByUrl("admin/dashboard");
	}
	// data: PlanWithPrices
	togglePricingModal = (modalPreview: string) => {
		// console.log("entering togglePricingModal this.pricingTypeData", this.pricingTypeData);
		// opens the plan change model
		const modalRef = this._modalService.open(modalPreview, {
			centered: true,
			size: "xl", // size: 'xs' | 'sm' | 'lg' | 'xl',
			windowClass: "modal-body-inner-footer",
		});
		for (let mapped_data of this.pricingTypeData) {
			if (mapped_data?.item?.type === "plan") {
				let data = mapped_data;
				let payload = {
					data: {
						product: data.product,
						productItemId: data.item.id,
						pricingType: this.pricingType,
						unitData: this.unitData,
					},
					extras: {
						tag: "payment_page",
					},
				};
				this._channel.pushToChannel(<ChannelChangePayload>payload);
			}
		}
		// console.log("......upgrading the price model", payload);
		// console.log("exiting togglePricingModal");
	};

	callbackAfterChange(payload: any) {
		// console.log("on Plan Change");
		this.closePopup();
		// console.log(".....after change", payload);
		// console.log("....pricingTypeData", this.pricingTypeData);
		let itemIdChanged = true;
		for (let i = 0; i < this.productItemIds.length; i++) {
			if (this.productItemIds[i] === payload.data.item.id) {
				itemIdChanged = false;
				break;
			}
		}
		if (payload.pricingType !== this.pricingType) {
			this.togglePricingType(payload.pricingType, "plan_change");
		}
		this.setParams(payload.data, "plan_change");
		this.unitData = payload.unitData;
		this.activePlan = payload.data.item_price?.external_name;
		// console.log("this.activePlan", this.activePlan);
		if (itemIdChanged) {
			let newItemIds = [payload.data.item.id];
			// this.productItemIds.forEach((d) => {
			// 	// removing old key
			// 	if (d !== payload.productItemId) {
			// 		newItemIds.push(d);
			// 	}
			// });
			this.productItemIds = newItemIds;
			// Calling again the api on plan change
			this.showPriceSkeleton = true;
			this.getProductDetailsOnPlanChange(this.productItemIds, "plan_change");
			if (this.event === "edit") {
				setTimeout(() => {
					this.manageSubscription();
				}, 1000);
			}
		}
		// console.log("exit on Plan Change");
	}

	closePopup() {
		this._modalService.dismissAll();
	}

	processCheckout(payload: any) {
		// console.log("...checkout triggered");
		this._chargebee.chargebee.openCheckout({
			hostedPage: () => {
				this.hostedPageId = "";
				return this._service
					.getChargebeeCheckoutOptions({
						payload: JSON.stringify(payload),
					})
					.toPromise();
			},
			layout: "in_app", // in_app, full_page
			loaded: () => {
				//console.log("...checkout loaded");
			},
			error: (err: any) => {
				this.ref.markForCheck();
				this._chargebee.chargebee.closeAll();
				console.warn("...checkout err" + err);
			},
			close: () => {
				this.ref.detectChanges();
				//console.log("...checkout closed");
				// TODO - need to redriect to home page
				// if (this.hostedPageId !== "") {
				// 	this.router.navigate(["/admin"]);
				// }
			},
			success: (hostedPageId: any) => {
				//console.log("...checkout success", hostedPageId);
				this.hostedPageId = hostedPageId;
				// Calling on success - a post checkout
				// console.log("...checkout success - payload", payload, JSON.stringify(payload));
				this.step = this.step + 1;
				this.horizontalWizardStepper.next();
				this._chargebee.chargebee.closeAll();
				setTimeout(() => {
					if (this.prodChangeName) {
						this.product_name = this.prodChangeName;
					}
					this.congratulationUser = true;
					this._changeDetectorRef.detectChanges();
				}, 10000);
				// this._service
				// 	.doChargebeePostCheckout({ payload: JSON.stringify(payload), hosted_id: hostedPageId })
				// 	.subscribe({
				// 		next: (response: any) => {
				// 			// TODO - if needed then handle
				// 			console.warn(response);
				// 			this._chargebee.chargebee.closeAll();
				// 		},
				// 		error: (e: any) => {
				// 			console.error(e);
				// 		},
				// 	});
			},
			step: (currentStep: string) => {
				// value -> which step in checkout
				// console.log("step ---> ", currentStep);
				// if (currentStep === "thankyou_screen") {
				// 	this.step = this.step + 1;
				// 	this.horizontalWizardStepper.next();
				// }
			},
		});
	}

	setParams = (payload, trigger) => {
		let productName = payload?.product?.id;
		this.activePlan = payload?.item_price?.external_name;
		// console.log("this.activePlan 1", this.activePlan);
		if (trigger === "init" || trigger === "product_change") {
			// get add ons for product
			if (this.productItemIds.length > 0) {
				let current_product = this.productItemIds[0];
				this.plan = this.activePlan.split(" ").pop();
				this.getProductAddOns(current_product);
			}
		}

		if (trigger !== "product_change" && trigger !== "plan_change" && trigger !== "tenure_change") {
			this.product_name = payload?.product?.name;
			this.plan = this.activePlan.split(" ").pop();
		}

		if (productName === "Infraon-Helpdesk") {
			this.currentAssetCount = 0;
			if (trigger === "init") {
				this.currentUserCount = 10;
			}
			this.allowedUnits = this.unitsMap[this.currentKey];
			if (payload?.item?.id === "Infy_HD_Free") {
				this.currentUserCount = 3;
				this.userEditDisabled = true;
			} else {
				this.userEditDisabled = false;
			}
			this.totalVPrice = this.getTotalPrice(this.currentUserCount);
			this.totalSubPrice = this.getSubTotalPrice(this.currentUserCount);
		} else {
			this.addOnObj = payload?.item?.metadata?.data;
			// console.log("in set params", this.addOnObj);
			for (let payload_obj of payload?.item?.metadata?.data) {
				if (payload_obj?.key) {
					this.currentKey = payload_obj?.key;
					// if (payload.item.id === "Infy_ITSM_Ess_1") {
					// 	this.allowedUnits = ["user"];
					// } else {
					this.allowedUnits = this.unitsMap[this.currentKey];
					// }
					if (trigger !== "product_change" && trigger !== "plan_change" && trigger !== "tenure_change") {
						this.logoKey = payload_obj?.key;
					}
				}
				if (payload_obj?.obj?.id === "selAssets") {
					this.assetMapObj = payload_obj?.map;
					// console.log("SHYAM trigger", trigger);
					// console.log("SHYAM this.purchasedCount", this.purchasedCount);
					// console.log("SHYAM payload_obj?", payload_obj);
					if (trigger !== "tenure_change") {
						if (this.event === "edit" && trigger === "usage") {
							this.currentAssetCount = this.purchasedCount["asset"];
						} else {
							if (trigger === "plan_change" && productName === "Infraon-ITSM") {
								continue;
							} else {
								this.currentAssetCount = payload_obj?.obj?.value;
							}
						}
						// this.currentAssetCount = payload_obj?.obj?.value;
					}
					// console.log("this.currentAssetCount 794", this.currentAssetCount);
					// console.log("2222222222222222222222222222222222", payload_obj?.obj?.options);
					if (trigger === "plan_change" && productName === "Infraon-ITSM") {
						continue;
					} else {
						// if (payload?.item?.type === "addon") {
						this.assetOptions = payload_obj?.obj?.options;
						// }
						// console.log("this.assetOptions sam s809", this.assetOptions);
					}
					if (payload_obj?.key === "asset") {
						this.currentUserCount = this.assetMapObj[String(this.currentAssetCount)];
					}
					this.totalVPrice = this.getTotalPrice(this.currentAssetCount);
					this.totalSubPrice = this.getSubTotalPrice(this.currentAssetCount);
				} else {
					this.userMapObj = payload_obj?.map;
					if (trigger !== "tenure_change") {
						if (this.event === "edit" && trigger === "usage") {
							this.currentUserCount = this.purchasedCount["user"];
						} else {
							this.currentUserCount = payload_obj?.obj?.value;
						}
						// this.currentUserCount = payload_obj?.obj?.value;
					}
					this.totalVPrice = this.getTotalPrice(this.currentUserCount);
					this.totalSubPrice = this.getSubTotalPrice(this.currentUserCount);
				}
			}
		}
		// console.log("775 this.totalSubPrice", this.totalSubPrice);
		this.subTotal = this.totalSubPrice;
		// this.showPricing = true;
		let extraAddonInit = [];
		if (this.addonItem.length > 0) {
			for (const item of this.addonItem) {
				extraAddonInit.push(item.item_id);
			}
		}
		if (trigger === "usage") {
			this.initDefault = {
				product: this.currentKey,
				plan: this.activePlan,
				user: this.currentUserCount,
				asset: this.currentAssetCount,
				tenure: this.pricingType,
				addon: this.currentAssetAddOn?.id,
				extraAddon: extraAddonInit,
			};
		}
		if (trigger === "usage") {
			this.showPricing = true;
		}
	};

	setPriceMap = (payload) => {
		let tempDisc = 0;
		let tempnonDisc = 0;
		for (let obj_data in payload) {
			let payload_obj = payload[obj_data];
			let current_type;
			if (payload_obj?.item?.type === "addon") {
				current_type = payload_obj?.item?.unit;
			} else {
				current_type = payload_obj?.item?.metadata?.data[0]?.key;
			}
			// console.log("current_type", current_type);
			let item_id = payload_obj?.item?.id;
			let data = payload_obj?.item_price;
			let quantity = current_type === "asset" ? this.currentAssetCount : this.currentUserCount; // always takes first value to compute - rest value belongs to addons
			let totalValue = this._compute.computePrice(quantity, data, this.currentSubscriptionTiers) / 100;
			// console.log("totalValue setPriceMap", totalValue);
			let discVal = Number(((1 / 6) * totalValue).toFixed());
			let nonDiscVal = Number(totalValue) + Number(discVal);
			this.priceMap[item_id] = totalValue;
			// console.log("this.priceMap", this.priceMap);
			tempDisc += discVal;
			tempnonDisc += nonDiscVal;
			// console.log("this.discValue", this.discValue);
		}
		this.nonDiscPrice = tempnonDisc;
		this.discValue = tempDisc;
		if (this.isTrial) {
			this.showPriceSkeleton = false;
		}
	};

	editAssets = () => {
		this.editAsset = true;
	};

	editUsers = () => {
		this.editUser = true;
	};

	getProductPlans = () => {
		for (let item of this.defaultProducts) {
			// console.log("item", item);
			this._service.getProductPlans({ productId: item }).subscribe({
				next: (response: any) => {
					// console.log("respone getProductPlans", response);
					this.productPlans = [];
					this.productPlansWithPricesRaw = <ProductPlansWithPrices>response;
					for (let mapped of this.productPlansWithPricesRaw.mapped) {
						if (mapped.data.length > 0 && mapped.period === this.pricingType) {
							// console.log("getProductPlans----------", mapped.data);
							// this.pricingTypeData = mapped.data;
							// mapped.data.forEach((d) => {
							// 	if (d.item.id === this.productInfo.data.productItemId)
							// 		this.productPlanSeq = d.item?.cf_infraon_seq || "0";
							// });
							mapped.data.forEach((d) => {
								d.item["color"] = "warning";
								this.productPlans.push(d.item);
								// this.setParams(d);
							});
							// console.log("this.productPlans", this.productPlans);
							this.instancePlanMap[item] = this.productPlans;
						}
					}
					// this.loader = false;
				},
				error: (e: any) => {
					console.error(e);
				},
			});
		}
		// console.log("this.instancePlanMap", this.instancePlanMap);
	};

	getProductDetailsOnPlanChange = (products, trigger) => {
		let paramTrigger = "change";
		if (trigger === "product_change" || trigger === "plan_change" || trigger === "init" || trigger === "usage") {
			paramTrigger = trigger;
			this.currentTrigger = trigger;
		}
		if (trigger === "plan_change") {
			if (products.length === 1 && products[0]?.includes("ITSM")) {
				products.push(this.currentAssetAddOn.id);
			}
		}
		this._service
			.getProductDetailByItemId({
				limit: 100,
				attached: true,
				item_ids: products.join(","),
				// TODO - need to deal the currecny code here
				currency: "USD",
			})
			.subscribe({
				next: (response: any) => {
					this.pricingTypeData = [];
					this.defaultPlan = this.productItemIds[0];
					this.productDataRaw = <ProductPlansWithPrices>response;
					this.productInfo = this.productDataRaw.product;
					this.baseProductInfo = this.productDataRaw?.base;
					this.extraAddOns = response?.addon_obj?.count_addons || [];
					// this.addonDropdownOptions = [...this.extraAddOns]
					this.addonMapped = response?.addon_obj?.mapped;
					if (this.extraAddOns?.length > 0) {
						if (trigger === "init" || trigger === "product_change" || trigger === "plan_change") {
							this.addonItem = [];
							this.defaultAddonObj = this.extraAddOns[0];
						}
						this.setAddonPricing();
					} else {
						this.totalPriceWithAddon = 0;
						this.totalAddonPrice = 0;
						this.addonItem = [];
					}
					// console.log("second 612", this.productDataRaw);
					for (let mapped of this.productDataRaw.mapped) {
						if (mapped.period === this.pricingType) {
							this.pricingTypeData = mapped.data;
							for (let mapped_data of mapped.data) {
								if (mapped_data?.item?.type === "plan") {
									this.setParams(mapped_data, paramTrigger);
								}
								if (
									mapped_data?.item?.type === "addon" &&
									trigger === "init" &&
									mapped_data?.item?.metadata?.data
								) {
									let curr_addon = mapped_data?.item?.metadata?.data[0];
									this.assetOptions = curr_addon?.obj?.options;
									this.currentAssetAddOn = mapped_data;
								}
							}
							if (trigger === "product_change" || trigger === "plan_change" || trigger === "usage") {
								// trigger === "init" ||
								if (!this.isTrial) {
									this.getCostEstimate();
									// this.getCreditNotes();
								}
							}
							// console.log("pricingTypeData 2", this.pricingTypeData);
						}
					}
					this.unitData = this._compute.unitsData(this.productInfo?.unit_of_measure);
					this.setPriceMap(this.pricingTypeData);
					this.defaultCurrency = "USD"; // TODO - need to take care currency
					// this.totalVPrice = this.getTotalPrice();
					// this.totalSubPrice = this.getSubTotalPrice();
					// console.log("this.pricingTypeData getProductDetailsOnPlanChange", this.pricingTypeData);
					this.setOverallPrice();
				},
				error: (e: any) => {
					console.error(e);
				},
			});
		// console.log("exiting getProductDetailsOnPlanChange");
	};

	onPlanChange = (event, data) => {
		// console.log("on plan change");
		this.productItemIds = [event.id];
		this.priceMap = {};
		this.itsmPriceMap = {};
		// this.pricingTypeData = [];
		this.showPriceSkeleton = true;
		this.getProductDetailsOnPlanChange(this.productItemIds, "plan_change");
		// console.log("exit on plan change");
	};

	onProductChange = (event) => {
		// console.log("entering on Product Change");
		this.currentKey = event?.key;
		this.allowedUnits = this.unitsMap[this.currentKey];
		this.productItemIds = event?.default_products;
		this.showPriceSkeleton = true;
		this.prodChangeName = event?.name;
		this.getProductDetailsOnPlanChange(this.productItemIds, "product_change");
		if (this.event === "edit") {
			setTimeout(() => {
				this.manageSubscription();
			}, 1000);
		}
		// console.log("exiting Product Change");
	};

	showPlans(intance_id) {
		this.showPlan = !this.showPlan;
	}

	getOrgDetails = () => {
		this._service.getCustomerData({}).subscribe({
			next: (response: CustomerCard) => {
				this.organizationData = response;
				this.showOrgData = true;
			},
			error: (e: any) => {
				console.error(e);
			},
		});
	};

	saveOrgDetails = () => {
		let profile = {};
		profile["first_name"] = this.organizationData.user.full_name;
		profile["email"] = this.organizationData.user.email;
		profile["phone"] = this.organizationData?.user?.phone_number?.internationalNumber;
		profile["company"] = this.organizationData.organization.company_name;
		this.billing_address_obj = {
			first_name: this.organizationData.user.full_name,
			company: this.organizationData.organization.company_name,
			email: this.organizationData.user.email,
			phone: this.organizationData?.user?.phone_number?.internationalNumber,
			line1: this.organizationData.organization.billing_address,
		};
		this.shipping_address_obj = {
			first_name: this.organizationData.user.full_name,
			company: this.organizationData.organization.company_name,
			email: this.organizationData.user.email,
			phone: this.organizationData?.user?.phone_number?.internationalNumber,
			line1: this.organizationData.organization.is_billing_address
				? this.organizationData.organization.billing_address
				: this.organizationData.organization.shipping_address,
		};
		profile["meta_data"] = {
			tax_no: this.organizationData.organization.tax_id,
			billing_address: this.billing_address_obj,
		};
		this._service.saveCustomerData(profile).subscribe({
			next: (response) => {
				// console.log("response", response);
			},
			error: (e: any) => {
				console.error(e);
			},
		});
	};

	showCongratsModal = (modalCongrats) => {
		const modalRef = this._modalService.open(modalCongrats, {
			centered: true,
			windowClass: "congratulationModal",
		});
	};

	getUsageMetrics = () => {
		// console.log("entering getUsageMetrics");
		this._service
			.getUsageCount()
			.pipe(
				map(async (response: any) => {
					if (response) {
						this.usageObj = response;
						await this.getSubscription();
						////console.log("getUsageMetrics", response);
					}
					// Success
				})
			)
			.subscribe();
		// console.log("getusage this.usageObj", this.usageObj);
		// console.log("exiting getUsageMetrics");
	};

	getSubscription = () => {
		// console.log("entering getSubscription");
		let payload = {
			subscription: this.productSubscription || "",
		};
		this._service
			.getSubscription(payload)
			.pipe(
				map((response: any) => {
					if (response) {
						// console.log("response currentSubscriptionTiers", response?.item_tiers);
						// 'COUNT': {'asset': {'Infraon-ITSM': 1000}, 'user': {'Infraon-ITSM': 50}}
						let curr_product = this.productInfo?.id;
						let count_obj = response?.meta_data?.COUNT;
						// console.log("this.currentSubscriptionTiers 212121", this.currentSubscriptionTiers);
						this.currentSubscriptionTiers = this.currentSubscriptionTiers.concat(response?.item_tiers);
						// console.log("this.currentSubscriptionTiers 1", this.currentSubscriptionTiers);
						// console.log("count_obj---------------", count_obj);
						// console.log("curr_product", curr_product);
						// if (response?.meta_data?.PLANS) {
						// 	this.plan = response?.meta_data?.PLANS[0].plan;
						// }
						if (count_obj?.user) {
							if (curr_product === undefined || curr_product === null) {
								curr_product = Object.keys(count_obj?.user)[0];
							}
							let userData = {
								key: "user",
								icon: "fa-user",
								purchased: Number(count_obj?.user[curr_product]) || 0,
								consumed: Number(this.usageObj?.user),
								available: Number(count_obj?.user[curr_product]) - Number(this.usageObj?.user),
							};
							this.usageMetrics.push(userData);
							this.purchasedCount["user"] = Number(count_obj?.user[curr_product]) || 0;
						}
						if (count_obj?.asset) {
							if (curr_product === undefined || curr_product === null) {
								curr_product = Object.keys(count_obj?.asset)[0];
							}
							let assetData = {
								key: "asset",
								icon: "fa-box-archive",
								purchased: Number(count_obj?.asset[curr_product]) || 0,
								consumed: Number(this.usageObj?.asset),
								available: Number(count_obj?.asset[curr_product]) - Number(this.usageObj?.asset),
							};
							this.usageMetrics.push(assetData);
							this.purchasedCount["asset"] = Number(count_obj?.asset[curr_product]) || 0;
						}
						if (response?.status === "active") {
							this.isTrial = false;
						} else if (response?.status === "cancelled") {
							this.allowManage = true;
						}
					}
					// console.log("in 975 this.purchasedCount usageMetrics", this.usageMetrics);
					if (this.event === "edit") {
						this.setUsageCount();
					}
					this.setAddons(response?.subscription_items);
					// Success
				})
			)
			.subscribe();
		// console.log("exiting getSubscription");
	};

	convertCentToDollars = (cents) => {
		return parseInt(cents) / 100;
	};

	getCostEstimate = () => {
		// console.log("entering getCostEstimate");
		// console.log("in getCost Estimate this.currentTrigger", this.currentTrigger);
		let payload = this.preparePayload();
		let credit_estimate_amount = 0;
		let credit_amount_allocated = 0;
		this._service
			.getCostEstimate({ payload: JSON.stringify(payload) })
			.pipe(
				map((response: any) => {
					if (response) {
						let invoice_estimate;
						// console.log("response---------getCostEstimate", response);
						// console.log("1091 this.initialCredits", this.initialCredits);
						if ("invoice_estimate" in response) {
							this.nextInvoice = false;
							invoice_estimate = response["invoice_estimate"];
							this.refundCredits = this.convertCentToDollars(invoice_estimate["credits_applied"]);
							this.totalPricePay = this.convertCentToDollars(invoice_estimate["amount_due"]);
							if (this.totalAddonPrice > 0) {
								this.subTotal = this.convertCentToDollars(
									invoice_estimate["amount_due"] + invoice_estimate["credits_applied"]
								);
								-this.totalAddonPrice;
							} else {
								this.subTotal = this.convertCentToDollars(
									invoice_estimate["amount_due"] + invoice_estimate["credits_applied"]
								);
							}
							this.showCreditApplied = true;
						}
						if ("next_invoice_estimate" in response) {
							invoice_estimate = response["next_invoice_estimate"];
							this.nextInvoice = true;
							this.refundCredits = 0;
							this.totalPricePay = 0;
							response["credit_note_estimates"].forEach((d) => {
								credit_estimate_amount += d?.amount_available;
								credit_amount_allocated += d?.amount_allocated;
							});
							// console.log("credit_estimate_amount", credit_estimate_amount);
							if (this.initialCredits !== 0) {
								this.remainingCredits =
									this.initialCredits +
									this.convertCentToDollars(credit_estimate_amount) +
									this.convertCentToDollars(credit_amount_allocated);
								// console.log("in if 1099", this.remainingCredits);
							} else {
								this.remainingCredits =
									// this.convertCentToDollars(invoice_estimate["credits_applied"]) +
									this.convertCentToDollars(credit_estimate_amount) +
									this.convertCentToDollars(credit_amount_allocated);
								// console.log("in else 1104", this.remainingCredits);
							}
							if (!this.allowManage) {
								if (this.totalAddonPrice > 0) {
									this.subTotal =
										this.convertCentToDollars(
											invoice_estimate["amount_due"] + invoice_estimate["credits_applied"]
										) - this.totalAddonPrice;
								} else {
									this.subTotal = this.convertCentToDollars(
										invoice_estimate["amount_due"] + invoice_estimate["credits_applied"]
									);
								}
							} else {
								this.subTotal = 0;
								this.totalVPrice = 0;
							}
							this.showCreditApplied = false;
						}
						// this.subTotal = this.convertCentToDollars(
						// 	invoice_estimate["amount_due"] + invoice_estimate["credits_applied"]
						// );
						// this.showCreditApplied = true;
						// this.refundCredits = this.convertCentToDollars(invoice_estimate["credits_applied"]);
						// this.totalPricePay = this.convertCentToDollars(invoice_estimate["amount_due"]);
						if (this.pricingType === "year") {
							this.nonDiscPrice =
								Number(this.totalPricePay) +
								Number(this.discValue) +
								this.convertCentToDollars(invoice_estimate["credits_applied"]);
						}

						if (!this.nextInvoice) {
							if ("credit_note_estimates" in response && this.currentTrigger !== "init") {
								if (response["credit_note_estimates"].length > 0) {
									// let credit_estimate_amount = 0;
									response["credit_note_estimates"].forEach((d) => {
										credit_estimate_amount += d?.amount_available;
									});
									// console.log("credit_estimate", credit_estimate_amount);
									// console.log("this.initialCredits", this.initialCredits);
									// console.log("this.refundCredits", this.refundCredits);
									// console.log("xxx this.currentTrigger", this.currentTrigger);
									if (credit_estimate_amount === 0) {
										// console.log("if 1042");
										this.remainingCredits = 0;
									} else {
										if (this.refundCredits < this.initialCredits) {
											this.remainingCredits =
												this.initialCredits -
												this.refundCredits +
												this.convertCentToDollars(credit_estimate_amount);
										} else {
											// if (this.initialCredits > 0) {
											// 	this.remainingCredits =
											// 		this.convertCentToDollars(credit_estimate_amount) + this.initialCredits;
											// } else {
											this.remainingCredits = this.convertCentToDollars(credit_estimate_amount);
											// }
										}
										// if (this.initialCredits > 0) {
										// 	this.remainingCredits =
										// 		this.convertCentToDollars(credit_estimate_amount) +
										// 		this.initialCredits -
										// 		this.refundCredits;
										// } else {
										// }
										// console.log("line 1188 this.remainingCredits", this.remainingCredits);
									}
								} else {
									// console.log("else 1149 this.initialCredits", this.initialCredits);
									// console.log("else 1150 this.refundCredits", this.refundCredits);
									if (this.refundCredits <= this.initialCredits) {
										this.remainingCredits = this.initialCredits - this.refundCredits;
									}
									// 	// this.remainingCredits = this.initialCredits;
									// 	console.log("this.nextInvoice", this.nextInvoice);
									// 	if (this.nextInvoice) {
									// 		// if (this.initialCredits > 0) {
									// 		this.remainingCredits = this.initialCredits;
									// 		// }
									// 	} else {
									// 		this.remainingCredits = this.initialCredits - this.refundCredits;
									// 		// console.log("1110 this.refundCredits", this.refundCredits);
									// 	}
								}
							}
						}
						// console.log("before false 1061");
						if (
							this.event === "edit" &&
							(this.currentTrigger === "usage" ||
								this.currentTrigger === "product_change" ||
								this.currentTrigger === "plan_change" ||
								this.currentTrigger === "addon_change")
						) {
							if (this.remainingCredits > 0) {
								this.showCreditMsg = true;
							} else {
								this.showCreditMsg = false;
							}
							if (this.currentTrigger === "addon_change") {
								this.setOverallPrice();
							}
							this.showPriceSkeleton = false;
						}
						// console.log("getCostEstimate this.showPriceSkeleton", this.showPriceSkeleton);
						// console.log("this.remainingCredits after", this.remainingCredits);
						// this.showPricing = true;
					}
					// Success
				})
			)
			.subscribe();
		// console.log("getCostEstimate this.showPriceSkeleton", this.showPriceSkeleton);
		// console.log("getCostEstimate this.showCreditMsg", this.showCreditMsg);
		// console.log("getCostEstimate this.initialCredits", this.initialCredits);
		// console.log("getCostEstimate this.remainingCredits", this.remainingCredits);
		// console.log("exiting getCostEstimate");
	};

	manageSubscription = () => {
		// console.log("entering manageSubscription");
		// console.log("manageSubscription initDefault", this.initDefault);
		let extraAddon = [];
		if (this.addonItem.length > 0) {
			for (const item of this.addonItem) {
				extraAddon.push(item.item_id);
			}
		}
		let currentObj = {
			product: this.currentKey,
			plan: this.activePlan,
			user: this.currentUserCount,
			asset: this.currentAssetCount,
			tenure: this.pricingType,
			addon: this.currentAssetAddOn?.id,
			extraAddon: extraAddon,
		};
		// console.log("manageSubscription currentObj", currentObj);
		if (isEqual(this.initDefault, currentObj)) {
			this.showCreditApplied = false;
			this.allowManage = false;
		} else {
			this.allowManage = true;
			this.showCreditApplied = true;
		}
		// console.log("this.showCreditApplied", this.showCreditApplied);
		// console.log("this.allowManage", this.allowManage);
		// console.log("exiting manageSubscription");
		// console.log("totalAddonPrice manageSubscription", this.totalAddonPrice);
		// console.log("nextInvoice manageSubscription", this.nextInvoice);
		// console.log("this.totalVPrice manageSubscription", this.totalVPrice);
		// console.log("this.totalPricePay manageSubscription", this.totalPricePay);
		if (this.totalAddonPrice > 0) {
			if (this.nextInvoice) {
				this.totalVPrice = this.subTotal + this.totalAddonPrice;
			} else {
				this.totalPricePay = this.subTotal + this.totalAddonPrice;
			}
			// console.log("totalVPrice manageSubscription", this.totalVPrice);
		}
	};

	setUsageCount = () => {
		this.getProductDetailsOnPlanChange(this.productItemIds, "usage");
	};

	getCreditNotes = () => {
		// console.log(" entering getCreditNotes");
		let payload = { subscription: this.productSubscription };
		this._service
			.getCreditNotes({ payload: JSON.stringify(payload) })
			.pipe(
				map((response: any) => {
					if (response) {
						response.forEach((d) => {
							this.initialCredits += this.convertCentToDollars(d?.amount_available);
							// this.showCreditMsg = true;
						});
						this.remainingCredits = this.initialCredits;
						// this.showPriceSkeleton = false;
					}
					// Success
				})
			)
			.subscribe();
		// console.log("getCreditNotes this.initialCredits", this.initialCredits);
		// console.log(" exit getCreditNotes this.showCreditMsg", this.showCreditMsg);
		// console.log(" exit getCreditNotes");
	};

	getProductAddOns = (curr_prod) => {
		let payload = { product: curr_prod };
		let modified_products = [curr_prod];
		this._service
			.getProdAddOns({ payload: JSON.stringify(payload) })
			.pipe(
				map((response: any) => {
					if (response) {
						// console.log("add ons", response);
						this.addOnOptions = response;
						if (this.event === "add") {
							for (const obj of this.addOnOptions) {
								// console.log("obj", obj);
								if (obj.hasOwnProperty("item")) {
									// console.log("plan", this.plan);
									if (obj["item"]?.name.includes(this.plan)) {
										// console.log("matched", obj["item"]);
										this.currentAssetAddOn = obj;
										modified_products.push(obj?.id);
										this.handleAddOn(modified_products);
										// this.getProductDetailsOnPlanChange(modified_products, "addon_change");
										// this.totalVPrice = this.getTotalPrice(this.currentAssetCount);
										// console.log("this.totalVPrice", this.totalVPrice);
										// this.totalSubPrice = this.getSubTotalPrice(this.currentAssetCount);
										// console.log("this.this.totalSubPrice", this.totalSubPrice);
										// console.log("itsmPriceMap", this.itsmPriceMap);
									}
								}
							}
						}
						// console.log("currentAssetAddOn", this.currentAssetAddOn);
					}
					// Success
				})
			)
			.subscribe();
	};

	onChangeAddOn = (event) => {
		let modified_products = [this.productItemIds[0]];
		modified_products.push(event?.id);
		// this.getProductDetailsOnPlanChange(modified_products, "addon_change");
		let curr_addon = event?.item?.metadata?.data[0];
		this.assetOptions = curr_addon?.obj?.options;
		// console.log("this.assetOptions 1418 sam", this.assetOptions);
		this.currentAssetCount = curr_addon?.obj?.value;
		this.assetMapObj = curr_addon?.map;
		this.handleAddOn(modified_products);
		// this.totalVPrice = this.getTotalPrice(this.currentAssetCount);
		// console.log("in on change add on ", this.totalVPrice);
		// this.totalSubPrice = this.getSubTotalPrice(this.currentAssetCount);
		if (this.event === "edit") {
			setTimeout(() => {
				this.manageSubscription();
			}, 1000);
		}
	};

	handleAddOn = (products) => {
		this._service
			.getProductDetailByItemId({
				limit: 100,
				attached: true,
				item_ids: products.join(","),
				// TODO - need to deal the currecny code here
				currency: "USD",
			})
			.subscribe({
				next: (response: any) => {
					// this.pricingTypeData = [];
					// this.defaultPlan = this.productItemIds[0];
					this.productDataRaw = <ProductPlansWithPrices>response;
					// this.productInfo = this.productDataRaw.product;
					// this.baseProductInfo = this.productDataRaw?.base;
					// console.log("second 612", this.productDataRaw);
					for (let mapped of response.mapped) {
						if (mapped.period === this.pricingType) {
							this.pricingTypeData = mapped.data;
							for (let mapped_data of mapped.data) {
								if (mapped_data?.item?.type === "addon") {
									mapped_data?.item_price?.tiers.forEach((d) => {
										d["item_price_id"] = mapped_data?.item_price?.id;
									});
									// console.log("this.currentSubscriptionTiers af1", this.currentSubscriptionTiers);
									this.currentSubscriptionTiers = this.currentSubscriptionTiers.concat(
										mapped_data?.item_price?.tiers
									);
									// console.log("this.currentSubscriptionTiers af", this.currentSubscriptionTiers);
									this.totalVPrice = this.getTotalPrice(this.currentAssetCount);
									// console.log("this.totalVPrice 1529", this.totalVPrice)
									// console.log("this.totalVPrice", this.totalVPrice);
									this.totalSubPrice = this.getSubTotalPrice(this.currentAssetCount);
									// console.log("this.totalSubPrice 1441", this.totalSubPrice);
									this.subTotal = this.totalSubPrice;
								}
							}
							// if (trigger === "product_change" || trigger === "plan_change" || trigger === "usage") {
							// 	// trigger === "init" ||
							// 	if (!this.isTrial) {
							// 		this.getCostEstimate();
							// 		// this.getCreditNotes();
							// 	}
						}
					}

					// this.unitData = this._compute.unitsData(this.productInfo?.unit_of_measure);
					// this.setPriceMap(this.pricingTypeData);
					// this.defaultCurrency = "USD"; // TODO - need to take care currency
					// this.totalVPrice = this.getTotalPrice();
					// this.totalSubPrice = this.getSubTotalPrice();
					// console.log("this.pricingTypeData getProductDetailsOnPlanChange", this.pricingTypeData);
				},
				error: (e: any) => {
					console.error(e);
				},
			});
	};

	// Addon
	addNewAddonItem = () => {
		// let defaultAddonObj = this.addonDropdownOptions[0]
		this.addonItem.push({
			id: generate_uuid("addon"),
			productType: this.defaultAddonObj,
			price: null,
			pricing_model: "",
			unit: this.defaultAddonObj?.metadata?.unit,
			quantity: this.defaultAddonObj?.metadata?.count,
			item_id: this.defaultAddonObj?.id,
		});
		this.setAddonPricing();
		this.currentTrigger = "addon_change";
		if (!this.isTrial) {
			this.getCostEstimate();
		} else {
			this.setOverallPrice();
		}
		if (this.event === "edit") {
			setTimeout(() => {
				this.manageSubscription();
			}, 1000);
		}
		// this.modifyAddonOptions(defaultAddonObj, 'remove')
	};
	deleteRowAddonItem = (sItem, addonItem) => {
		this.showPriceSkeleton = true;
		addonItem.forEach((element, index) => {
			if (sItem.id === element.id) {
				addonItem.splice(index, 1);
			}
		});
		this.currentTrigger = "addon_change";
		this.setAddonPricing();
		if (!this.isTrial) {
			this.getCostEstimate();
		} else {
			this.setOverallPrice();
		}
		// this.setAddonPricing();
		if (this.event === "edit") {
			setTimeout(() => {
				this.manageSubscription();
			}, 1000);
		}
		this.showPriceSkeleton = false;
		// this.modifyAddonOptions(sItem, 'add')
		this.showDuplicateAddonMsg = this.checkForDuplicateAddons(this.addonItem);
	};
	setAddonPricing = () => {
		this.totalAddonPrice = 0;
		this.addonItem.forEach((element, index) => {
			// default pricing set here
			let current_item_id = element?.item_id;
			// getting the item price
			let item_price = this.getAddonItemPrice(this.addonMapped, current_item_id);
			element["item_price_id"] = item_price?.id;
			element["pricing_model"] = item_price?.pricing_model;
			element["price"] = this._compute.computePrice(1, item_price, this.currentSubscriptionTiers) / 100;
			this.addonItem[index] = element;
			this.totalAddonPrice += element["price"];
		});
	};

	getAddonItemPrice = (mapped_data, item_id) => {
		for (let mapped of mapped_data) {
			if (mapped.period === this.pricingType) {
				for (let data of mapped.data) {
					if (data?.item_price?.item_type === "addon" && data?.item_price?.item_id === item_id) {
						return data?.item_price;
					}
				}
			}
		}
	};

	onAddonChange = (e, current_addon) => {
		this.showPriceSkeleton = true;
		this.addonItem.forEach((element, index) => {
			if (current_addon?.id === element.id) {
				let item_price = this.getAddonItemPrice(this.addonMapped, e?.id);
				element["item_id"] = e?.id;
				element["item_price_id"] = item_price?.id;
				element["price"] = this._compute.computePrice(1, item_price, this.currentSubscriptionTiers) / 100;
				this.addonItem[index] = element;
			}
		});
		this.setAddonPricing();
		if (!this.isTrial) {
			this.getCostEstimate();
			// this.getCreditNotes();
		} else {
			this.setOverallPrice();
		}
		this.currentTrigger = "addon_change";
		// this.setAddonPricing();
		if (this.event === "edit") {
			setTimeout(() => {
				this.manageSubscription();
			}, 1000);
		}
		this.showPriceSkeleton = false;
		// this.modifyAddonOptions(current_addon, 'remove')
		this.showDuplicateAddonMsg = this.checkForDuplicateAddons(this.addonItem);
	};

	setOverallPrice = () => {
		if (this.addonItem.length > 0) {
			this.totalPriceWithAddon = this.subTotal + this.totalAddonPrice;
		}
	};

	setAddons = (subscriptionItems: any[]): any => {
		let mappedData = [];
		// Iterate over each item in subscriptionItems
		for (const mapped_addon of this.addonMapped) {
			if (mapped_addon?.period === this.pricingType) {
				mappedData = mapped_addon?.data;
			}
		}
		for (let i = 0; i < subscriptionItems.length; i++) {
			const subscriptionItem = subscriptionItems[i];
			const itemPriceId = subscriptionItem.item_price_id;

			// Find the matching item in mappedData.data
			const matchingItem = mappedData.find((data) => {
				return data?.item_price?.id === itemPriceId;
			});
			if (matchingItem) {
				this.addonItem.push({
					id: i,
					productType: matchingItem?.item,
					price: null,
					unit: matchingItem?.item?.metadata?.unit,
					quantity: matchingItem?.item?.metadata?.count,
					item_id: matchingItem?.item?.id,
				});
				this.setAddonPricing();
				this.setOverallPrice();
			}
		}
	};

	// modifyAddonOptions = (item, action) => {
	// 	console.log("modifyAddonOptions item", item)
	// 	console.log("modifyAddonOptions action", action)
	// 	if (action === 'add'){
	// 		this.addonDropdownOptions.push(item)
	// 	}
	// 	else{
	// 		this.addonDropdownOptions.forEach((element, index) => {
	// 			if (item.id === element.id) {
	// 				this.addonDropdownOptions.splice(index, 1);
	// 			}
	// 		});
	// 	}
	// 	console.log("modifyAddonOptions extraAddons", this.extraAddOns)
	// 	console.log("modifyAddonOptions addonDropdownOptions", this.addonDropdownOptions)
	// }
	checkForDuplicateAddons = (data) => {
		const uniqueItems = data.reduce((acc, current) => {
			const existingItem = acc.find((item) => item.productType.id === current.productType.id);
			if (!existingItem) {
				acc.push(current);
			}
			return acc;
		}, []);
		if (data.length !== uniqueItems.length) {
			return true;
		}
		return false;
	};
}
