import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FileUploader } from "ng2-file-upload";
import { CountryISO, SearchCountryField, PhoneNumberFormat } from "ngx-intl-tel-input";
import { SupportPortalService } from "app/support-portal/services/support-portal.service";
import { TranslateService } from "@ngx-translate/core";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import includes from "lodash-es/includes";
import { PolicyService } from "app/base/policy/services/policy.service";
import { group } from "console";

const AES = require("crypto-js/aes");
const URL = "http://localhost:8080/common/user/profileimage/";

@Component({
	selector: "app-create-partner",
	templateUrl: "./create-partner.component.html",
	styleUrls: ["./create-partner.component.scss"],
})
export class CreatePartnerComponent implements OnInit {
	@Output("onHideProfile") onHideProfile = new EventEmitter<any>();
	@ViewChild("partnerAddForm") partnerAddForm;
	@Output("callback") callback = new EventEmitter<any>();
	@Input() partnerid: Number;
	@Input() action: String;

	// public partner;
	is_form_submitted = false;
	public readonly = false;
	public changePassword;
	public passwordHistory;
	public minPasswordLength;
	public maxPasswordLength;
	public minUpperCase;
	public minLowerCase;
	public minNumChar;
	public minSplChar;
	public contUpperCase;
	public contLowerCase;
	public contNumChar;
	public contSplChar;
	public passwordToaster;

	address;
	partner: any = {};
	public change: boolean;
	public imageDetail: boolean;
	public imageBase64: string;
	public tempImage;
	public currentAvatarColor;
	public fullName;
	public currentImgDetails;
	public activeAvatar;
	public showPreview = false;
	public ALLOWED_TYPES = ["image/png", "image/jpeg"];
	public MAX_IMAGE_SIZE = 20971520;
	public MAX_HEIGHT = 15200;
	public MAX_WIDTH = 25600;
	public file: any;

	public partnerPwdShow = false;
	public is_password_invalid;

	CountryISO = CountryISO;
	separateDialCode = true;
	SearchCountryField = SearchCountryField;
	PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom, CountryISO.India];

	public uploader: FileUploader = new FileUploader({
		url: URL,
		isHTML5: true,
	});

	//image uploader
	public imgDetailsObject = {
		aspectRatio: "4/ 2",
		resizeToWidth: "128",
		cropperMinWidth: "128",
		maintainAspectRatio: true,
	};

	constructor(
		private _coreSidebarService: CoreSidebarService,
		private _toastr: ToastrService,
		private _service: SupportPortalService,
		private _translateService: TranslateService,
		private _policyService: PolicyService
	) {}

	ngOnInit(): void {
		this.getPasswordPolicy();
		if (this.action === "view") {
			this.readonly = true;
		}
		this.partner = {};
		if (this.partnerid && this.partnerid !== -1) {
			this.getPartner(this.partnerid);
		}
	}

	ngOnChanges() {
		this.imageBase64 = undefined;
		this.showPreview = false;
		if (this.partnerid && this.partnerid !== -1) {
			this.getPartner(this.partnerid);
		}
	}

	toggleSidebar(name): void {
		this.partnerid = -1;
		this.partner = {};
		this.partnerAddForm.resetForm();
		this.onHideProfile.emit("hide");
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
	}

	profileImage = ($event) => {
		this.imageBase64 = $event;
		this.partner.profile_image = $event;
		this.imageDetail = true;
		this.change = true;
	};

	removeImage = ($event) => {
		this.tempImage = this.partner["profile_image"];
		this.partner["profile_image"] = "";
		this.imageBase64 = null;
		this.imageDetail = false;
		// this.showPreview = false;
	};

	ProfileBgColor = ($event) => {
		this.currentAvatarColor = $event;
	};

	currrentColor = ($event) => {
		if ($event == "image") {
			this.partner["profile_image"] = this.tempImage;
		} else {
			this.getPartner($event);
		}
	};

	onUpload(files: any): void {
		if (files.length > 0) {
			this.file = files[0];
			if (this.file && this.file["size"] > this.MAX_IMAGE_SIZE) {
				this._toastr.warning("Maximum size allowed is " + this.MAX_IMAGE_SIZE / 1000 + "Mb", "Failed Upload", {
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				});
			}

			if (!includes(this.ALLOWED_TYPES, this.file["type"])) {
				this._toastr.warning("Only Images are allowed ( JPG | PNG )", "Failed Upload", {
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				});
			}
			const reader = new FileReader();
			reader.onload = (e: any) => {
				const image = new Image();
				image.src = e.target.result;
				image.onload = (rs) => {
					const img_height = rs.currentTarget["height"];
					const img_width = rs.currentTarget["width"];
					if (img_height > this.MAX_HEIGHT && img_width > this.MAX_WIDTH) {
						this._toastr.warning(
							"Maximum dimentions allowed " + this.MAX_HEIGHT + "*" + this.MAX_WIDTH + "px",
							"Failed Upload",
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
					} else {
						const imgBase64Path = e.target.result;
						this.imageBase64 = imgBase64Path;
						this.partner.profile_image = imgBase64Path;
						this.partner.new_image = true;
						// this.previewImagePath = imgBase64Path;
					}
				};
			};
			reader.readAsDataURL(this.file);
			this.showPreview = true;
		}
	}

	generatePasswordPolicy(policy_objs) {
		this.changePassword = policy_objs.changePassword;
		this.passwordHistory = policy_objs.passwordHistory;
		this.minPasswordLength = policy_objs.minPasswordLength;
		this.maxPasswordLength = policy_objs.maxPasswordLength;
		this.minUpperCase = policy_objs.minUpperCase;
		this.minLowerCase = policy_objs.minLowerCase;
		this.minNumChar = policy_objs.minNumChar;
		this.minSplChar = policy_objs.minSplChar;
		this.contUpperCase = policy_objs.contUpperCase;
		this.contLowerCase = policy_objs.contLowerCase;
		this.contNumChar = policy_objs.contNumChar;
		this.contSplChar = policy_objs.contSplChar;
	}

	validatePassword(password) {
		this.is_password_invalid = false;
		this.passwordToaster = this._translateService.instant("Message.msg_pwd_contain") + "</br>";
		if (password.length < this.minPasswordLength) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_minimum_character", {
				minimum_character: this.minPasswordLength,
			});
			this.is_password_invalid = true;
		}
		if (password.length > this.maxPasswordLength) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_maximum_character", {
				maximum_character: this.maxPasswordLength,
			});
			this.is_password_invalid = true;
		}
		if (
			password.replace(/[^\\!\\@\\#\\$\\%\\^\\&\\*\\(\\)\\_\\?\\<\\>]/g, "").length < this.minSplChar &&
			this.minSplChar != this.contSplChar
		) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_minimum_special_character", {
				minimum_special_character: this.minSplChar,
			});
			this.is_password_invalid = true;
		}
		let pattern = "([\\!\\@\\#\\$\\%\\^\\&\\*\\(\\)\\_\\?\\<\\>])" + "{" + this.contSplChar + "}";
		if (!password.match(pattern)) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_continous_special_character", {
				continous_special_character: this.contSplChar,
			});
			this.is_password_invalid = true;
		}
		if (password.replace(/[^0-9]/g, "").length < this.minNumChar && this.minNumChar != this.contNumChar) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_minimum_number", {
				minimum_number: this.minNumChar,
			});
			this.is_password_invalid = true;
		}
		pattern = "([\\d])" + "{" + this.contNumChar + "}";
		if (!password.match(pattern)) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_continous_number", {
				continous_number: this.contNumChar,
			});
			this.is_password_invalid = true;
		}
		if (password.replace(/[^a-z]/g, "").length < this.minLowerCase && this.minLowerCase != this.contLowerCase) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_minimum_lowercase_letter", {
				minimum_lowercase_letter: this.minLowerCase,
			});
			this.is_password_invalid = true;
		}
		pattern = "([a-z])" + "{" + this.contLowerCase + "}";
		if (!password.match(pattern)) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_continous_lowercase_letter", {
				continous_lowercase_letter: this.contLowerCase,
			});
			this.is_password_invalid = true;
		}
		if (password.replace(/[^A-Z]/g, "").length < this.minUpperCase && this.minUpperCase != this.contUpperCase) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_minimum_uppercase_letter", {
				minimum_uppercase_letter: this.minUpperCase,
			});
			this.is_password_invalid = true;
		}
		pattern = "([A-Z])" + "{" + this.contUpperCase + "}";
		if (!password.match(pattern)) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_continous_uppercase_letter", {
				continous_uppercase_letter: this.contUpperCase,
			});
			this.is_password_invalid = true;
		}
	}

	encryptPassword(password: string) {
		var iv = "d99630bcc7058639e7e80df239f38a32";
		var ciphertext = AES.encrypt(password, iv).toString();
		var aesPassword = iv + "::" + ciphertext;
		return btoa(aesPassword);
	}

	onpartnerSave(data): void {
		if (data.form.valid === true) {
			if (this.is_password_invalid === true) {
				return;
			}
			if (this.partner["password"] != "************Aa1") {
				this.partner["password"] = this.encryptPassword(this.partner.password);
			}
			if (this.imageDetail === true) {
				this.partner["profile_image"] = this.imageBase64;
				this.partner["new_image"] = true;
			}
			this.partner["avatar_color"] = this.currentAvatarColor;
			this.partner["module_name"] = "Infinity Partner";
			// this.partner["group_id"] = this.partner.group_id
			this.savePartner(this.partner, data);
		}
	}

	savePartner = (partnerObj: {}, form): void => {
		this.is_form_submitted = true;
		this._service
			.savePartner(partnerObj)
			.pipe(
				map((response: any) => {
					if (response) {
						this.is_form_submitted = false;
						if (response["status"] == "success") {
							// Closing the toolbar
							this.callback.emit(true);
							this.partner = {};
							setTimeout(() => form.resetForm(), 200);
							this.onHideProfile.emit("hide");
							this._coreSidebarService.getSidebarRegistry("create-partner").toggleOpen();
							this._toastr.success(
								this._translateService.instant(response["message"]),
								this._translateService.instant("Message.msg_save_success"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
						} else {
							this._toastr.error(
								this._translateService.instant(response["message"]),
								this._translateService.instant("Error.err_save_failed"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
						}
					}
					// Success
				}),
				catchError((error) => {
					this.is_form_submitted = false;
					this._toastr.error(
						this._translateService.instant("Error.err_partner_save_failed"),
						this._translateService.instant("Error.err_save_failed"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					return throwError(error.statusText);
				})
			)
			.subscribe();
	};

	getPasswordPolicy = () => {
		const payload = {
			page: 1,
			items_per_page: 10,
			sort: "policyName",
			reverse: "",
			group_id: this.partner.group_id,
		};
		this._policyService.fetchPasswordPolicy(payload).subscribe(
			(response) => {
				if (response) {
					this.generatePasswordPolicy(response);
				}
			},
			(error) => {
				//todo
			}
		);
	};

	getPartner(partnerId: Number): void {
		this.imageBase64 = undefined;
		this.showPreview = false;
		this._service
			.getPartner(partnerId)
			.pipe(
				map((response: any) => {
					if (response) {
						this.partner = response;
						this.getPasswordPolicy();
						this.imageBase64 = response.profile_image;
						this.currentImgDetails = this.imageBase64;
						this.fullName = this.partner.partner_name;
						if (response?.avatar_color) {
							this.currentAvatarColor = response.avatar_color;
							this.activeAvatar = response.avatar_color;
						} else {
							this.currentAvatarColor = "#f59518";
							this.activeAvatar = "#f59518";
						}
						this.showPreview = true;
						// this.action = "Edit";
					}
				}),
				catchError((error) => {
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}
}
