import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select-badge',
  templateUrl: './select-badge.component.html',
  styleUrls: ['./select-badge.component.scss']
})
export class SelectBadgeComponent implements OnInit {
  priorityOption = [
    {
      id: 1,
      priority: 1,
      priorityName: 'Critical',
      priorityColor: 'danger',
      priorityIcon: 'fa-triangle-exclamation', 
    },
    {
      id: 2,
      priority: 2,
      priorityName: 'High',
      priorityColor: 'warning',
      priorityIcon: 'fa-angles-up', 
    },
    {
      id: 3,
      priority: 3,
      priorityName: 'Medium',
      priorityColor: 'info',
      priorityIcon: 'fa-angle-up', 
    },
    {
      id: 4,
      priority: 4,
      priorityName: 'Low',
      priorityColor: 'success',
      priorityIcon: 'fa-dash', 
    },
    
  ];
  @Input() inputConfig: any;
  @Output() onValueChange: EventEmitter<any> = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
  }
  valueChange($event){
    this.onValueChange.emit({'event':$event,'field':this.inputConfig?.id})
  }
}
