<div class="row p-0 form-group">
	<div
		*ngFor="let item of filterConfigData?.custom_fields"
		class="{{ item.col_val }}"
		[ngClass.col-12]="item.type == 'header'"
		[ngSwitch]="item.type"
	>
		<ng-template *ngIf="(selfservice && item.is_customer_ui) || !selfservice" [ngSwitchCase]="'header'">
			<h2>{{ item.heading }}</h2>
			<h5 class="sub_heading">{{ item.sub_heading }}</h5>
			<hr />
		</ng-template>
		<ng-template *ngIf="(selfservice && item.is_customer_ui) || !selfservice" [ngSwitchCase]="'text'">
			<label>{{ item.label }}</label> <label *ngIf="item.required" class="text-danger">*</label>
			<input
				type="text"
				[class.error]="!TDLabelNameRef.valid && formRef?.submitted"
				#TDLabelNameRef="ngModel"
				class="form-control"
				[id]="item?.name"
				[name]="item?.name"
				[(ngModel)]="customFieldData[item?.name]"
				placeholder="{{ item.placeholder }}"
				[pattern]="item?.regex"
				[required]="item?.required"
				[disabled]="disableField"
				[appWhiteSpaceCheck]="customFieldData[item?.name]"
			/>
			<dns-error-validation
				[uiRef]="TDLabelNameRef"
				[formRef]="formRef"
				[validationMsgConfig]="{ pattern: item.errorText }"
			></dns-error-validation>
		</ng-template>
		<ng-template *ngIf="(selfservice && item.is_customer_ui) || !selfservice" [ngSwitchCase]="'email'">
			<label>{{ item.label }}</label> <label *ngIf="item.required" class="text-danger">*</label>
			<input
				type="email"
				class="form-control"
				[id]="item?.name"
				[name]="item?.name"
				[class.error]="!TDEmailRef.valid && formRef?.submitted"
				#TDEmailRef="ngModel"
				[disabled]="disableField"
				[appEmailCheck]="customFieldData[item?.name]"
				[(ngModel)]="customFieldData[item?.name]"
				placeholder="{{ item.placeholder }}"
				[required]="item?.required"
			/>
			<dns-error-validation
				[uiRef]="TDEmailRef"
				[formRef]="formRef"
				[validationMsgConfig]="{ appEmailCheck: item.errorText }"
			></dns-error-validation>
		</ng-template>
		<ng-template *ngIf="(selfservice && item.is_customer_ui) || !selfservice" [ngSwitchCase]="'phone'">
			<label>{{ item.label }}</label> <label *ngIf="item.required" class="text-danger">*</label>
			<input
				type="number"
				class="form-control"
				[id]="item?.name"
				[name]="item?.name"
				[(ngModel)]="customFieldData[item?.name]"
				placeholder="{{ item.placeholder }}"
				[disabled]="disableField"
				[class.error]="!TDPhoneRef.valid && formRef.submitted"
				#TDPhoneRef="ngModel"
				[required]="item?.required"
			/>
			<dns-error-validation
				[uiRef]="TDPhoneRef"
				[formRef]="formRef"
				[validationMsgConfig]="{ appEmailCheck: item.errorText }"
			></dns-error-validation>
		</ng-template>
		<ng-template *ngIf="(selfservice && item.is_customer_ui) || !selfservice" [ngSwitchCase]="'number'">
			<label>{{ item.label }}</label> <label *ngIf="item.required" class="text-danger">*</label>
			<input
				type="number"
				class="form-control"
				[id]="item?.name"
				[name]="item?.name"
				[(ngModel)]="customFieldData[item?.name]"
				placeholder="{{ item?.placeholder }}"
				[disabled]="disableField"
				[class.error]="!TDNumberRef.valid && formRef.submitted"
				#TDNumberRef="ngModel"
				[required]="item?.required"
			/>
			<dns-error-validation
				[uiRef]="TDNumberRef"
				[formRef]="formRef"
				[validationMsgConfig]="{
					min: getTranslatedText('Error.err_min_val', { minValue: item?.min }),
					max: getTranslatedText('Error.err_max_val', { maxValue: item?.max })
				}"
			></dns-error-validation>
		</ng-template>
		<ng-template *ngIf="(selfservice && item.is_customer_ui) || !selfservice" [ngSwitchCase]="'date'">
			<label>{{ item.label }}</label> <label *ngIf="item.required" class="text-danger">*</label>
			<ng2-flatpickr
				[config]="basicDateOptions"
				[id]="item?.name"
				[name]="item?.name"
				[(ngModel)]="customFieldData[item?.name]"
				[setDate]="customFieldData[item?.name]"
				placeholder="{{ item.placeholder }}"
				[required]="item?.required"
				#TDFDate="ngModel"
			></ng2-flatpickr>
			<dns-error-validation [uiRef]="TDFDate" [formRef]="formRef"></dns-error-validation>
		</ng-template>

		<ng-template *ngIf="(selfservice && item.is_customer_ui) || !selfservice" [ngSwitchCase]="'datetime-local'">
			<label>{{ item.label }}</label> <label *ngIf="item.required" class="text-danger">*</label>
			<ng2-flatpickr
				[config]="dateTimeOptions"
				[name]="item?.name"
				[setDate]="customFieldData[item?.name]"
				[id]="item?.name"
				placeholder="{{ 'UI.k_sel_date' | translate }}"
				[(ngModel)]="customFieldData[item?.name]"
				[required]="item?.required"
				#TDFDateTime="ngModel"
			></ng2-flatpickr>
			<dns-error-validation [uiRef]="TDFDateTime" [formRef]="formRef"></dns-error-validation>
		</ng-template>

		<ng-template *ngIf="(selfservice && item.is_customer_ui) || !selfservice" [ngSwitchCase]="'textarea'">
			<label>{{ item.label }}</label> <label *ngIf="item.required" class="text-danger">*</label>
			<textarea
				class="form-control"
				[id]="item?.name"
				[name]="item?.name"
				[class.error]="!TDFtextArea.valid && formRef.submitted"
				[(ngModel)]="customFieldData[item?.name]"
				[disabled]="disableField"
				placeholder="{{ item.placeholder }}"
				#TDFtextArea="ngModel"
				[required]="item?.required"
			>
			</textarea>
			<dns-error-validation [uiRef]="TDFtextArea" [formRef]="formRef"></dns-error-validation>
		</ng-template>
		<ng-template *ngIf="(selfservice && item.is_customer_ui) || !selfservice" [ngSwitchCase]="'file'">
			<label for="file-upload-multiple"
				>{{ item.label }}<label *ngIf="item.required" class="required-field">*</label></label
			>
			<div class="custom-file">
				<input
					type="file"
					class="custom-file-input"
					type="file"
					ng2FileSelect
					(change)="onChange($event)"
					[disabled]="disableField"
					[uploader]="uploader"
					multiple
					id="file-upload-multiple"
				/>
				<label class="custom-file-label">Choose file</label>
			</div>
			<!-- <small class="form-text text-danger">(File can't be uploaded right now)</small> -->
		</ng-template>
		<ng-template *ngIf="(selfservice && item.is_customer_ui) || !selfservice" [ngSwitchCase]="'paragraph'">
			<p class="form-text" [ngClass.col-12]="item.type == 'paragraph'">{{ item.label }}</p>
		</ng-template>
		<ng-template *ngIf="(selfservice && item.is_customer_ui) || !selfservice" [ngSwitchCase]="'dropdown'">
			<label>{{ item.label }}</label> <label *ngIf="item.required" class="text-danger">*</label>
			<ng-select
				[items]="item.values"
				bindLabel="label"
				[id]="item?.name"
				[name]="item?.name"
				[(ngModel)]="customFieldData[item?.name]"
				name="{{ item.name }}"
				bindValue="value"
				[disabled]="disableField"
				placeholder="{{ item.placeholder }}"
				[required]="item?.required"
				#TDFDropdown="ngModel"
			>
			</ng-select>

			<dns-error-validation [uiRef]="TDFDropdown" [formRef]="formRef"></dns-error-validation>
		</ng-template>
		<ng-template *ngIf="(selfservice && item.is_customer_ui) || !selfservice" [ngSwitchCase]="'checkbox'">
			<label>{{ item.label }}<span *ngIf="item.required" class="required-field">*</span></label>
			<input
				type="hidden"
				name="collapse-sidebar-switch-hidden"
				id="collapse-sidebar-switch-hidden"
				[required]="item?.required"
				#TDFCbHidden="ngModel"
				[ngModel]="customFieldData[item?.name]"
			/>
			<ng-container *ngFor="let v of item.values; let indx = index">
				<div class="inline-form-group">
					<!-- <label class="cust-check-bx"> -->
					<div class="custom-control custom-checkbox">
						<input
							type="checkbox"
							[value]="v?.value"
							name="collapse-sidebar-switch-{{ item.data_key }}-{{ v?.id }}--{{ indx }}"
							(click)="toggleValue(v, item?.name)"
							[checked]="onCheckBoxLoad(v, item)"
							[disabled]="disableField"
							class="custom-control-input"
							id="collapse-sidebar-switch-{{ item.data_key }}-{{ v?.id }}--{{ indx }}"
						/>
						<label
							class="custom-control-label"
							for="collapse-sidebar-switch-{{ item.data_key }}-{{ v?.id }}--{{ indx }}"
							>{{ v.label }}</label
						>
					</div>
				</div>
			</ng-container>
			<span *ngIf="customFieldData[item?.name] === {} && formRef?.submitted">
				<small class="form-text text-danger">{{ "UI.k_field_required" | translate }}</small>
			</span>
		</ng-template>
		<ng-template *ngIf="(selfservice && item.is_customer_ui) || !selfservice" [ngSwitchCase]="'radio'">
			<div class="col-xl-6 col-md-6 col-12 mb-1 form-group p-0">
				<label>{{ item.label }}:<span *ngIf="item.required" class="required-field">*</span></label>
				<div fxLayout="column" fxLayoutGap="12px">
					<div
						*ngFor="let v of item.values; let i = index"
						[attr.data-index]="i"
						class="custom-control custom-radio pb-50"
					>
						<input
							id="radio-{{ item?.name + '-' + i }}"
							type="radio"
							value="{{ v?.value }}"
							name="{{ item?.name }}"
							[(ngModel)]="customFieldData[item?.name]"
							[disabled]="disableField"
							class="custom-control-input"
							#TDFRadioButton="ngModel"
							[checked]="customFieldData[item?.name] === v.value"
							[required]="item?.required"
						/>
						<label class="custom-control-label" for="radio-{{ item?.name + '-' + i }}"
							>&nbsp; {{ v.label }}</label
						>
						<dns-error-validation
							[uiRef]="TDFRadioButton"
							[formRef]="formRef"
							*ngIf="i === item?.values?.length - 1"
						></dns-error-validation>
					</div>
				</div>
			</div>
		</ng-template>
		<ng-template *ngIf="(selfservice && item.is_customer_ui) || !selfservice" [ngSwitchCase]="'divider'">
			<hr />
		</ng-template>
	</div>
</div>
