<div class="slideout-content default-slideout">
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
		<div class="modal-dialog sidebar-md">
			<div class="modal-content add-new-user py-0">
				<div class="modal-header d-flex align-items-center justify-content-between border-bottom">
					<div class="d-flex align-items-center">
						<h5 class="modal-title fw-600" id="exampleModalLabel">
							{{ impact_title }}
						</h5>
					</div>
					<button
						type="button"
						data-dismiss="modal"
						id="btnClose"
						name="btnClose"
						[attr.data-testid]="'btnClose'"
						aria-label="Close"
						(click)="toggleSidebar()"
						class="btn btn-flat-secondary btn-icon"
						rippleEffect
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body flex-1">
					<form #ChnImactForm="ngForm" class="flex-auto-height">
						<ng-scrollbar class="custom-scrollbar section-scrollbar">
							<div class="modal-body-inner flex-1 py-1">
								<!-- <div class="row"> -->
									<div class="col-sm-12">
											<div class="form-group">
												<label for="txtReqImpact">{{ "UI.k_impact" | translate }}&nbsp;* </label>
												<ng-select
													[items]="options?.impact"
													[hideSelected]="true"
													bindLabel="name"
													[(ngModel)]="impact_obj.impact"
													#plnImpact="ngModel"
													name="txtChnImpact"
													id="txtChnImpact"
													placeholder="{{ 'UI.k_select' | translate }} {{
														'UI.k_impact' | translate
													}}"
													required
												>
												</ng-select>
                                            <span
                                                class="invalid-form"
                                                *ngIf="ChnImactForm.submitted && plnImpact.invalid"
                                            >
											<small
												class="form-text text-danger xs-font"
												*ngIf="plnImpact.errors.required"
												>{{ "UI.k_field_required" | translate }}
											</small>
                                            </span>
										</div>
									</div>
									<div class="col-sm-12">
										<div class="form-group">
											<label for="txtReqImpact">{{ "UI.k_description" | translate }}&nbsp;* </label>
											<textarea
												id="qlDescription"
												name="qlDescription"
												#qlDescription="ngModel"
												minlength="3"
												rows="8"
												class="form-control"
												[(ngModel)]="impact_obj.description"
												placeholder="{{ 'UI.k_description' | translate }}"
												required
											>
											</textarea>
											<div>
												<span
													class="row col-12 error"
													*ngIf="ChnImactForm.submitted && qlDescription?.invalid"
													class="invalid-form"
												>
												<small class="form-text text-danger xs-font"
													*ngIf="
													qlDescription?.errors.required
													"
													>
													{{ "UI.k_field_required" | translate }}
												</small>
												<small
													class="form-text text-danger xs-font"
													*ngIf="qlDescription.errors.minlength"
													>{{ "Error.err_min_3_character" | translate }}
												</small>
												</span>
											</div>
										</div>
									</div>
								<!-- </div> -->
							</div>
						</ng-scrollbar>
						<div class="d-flex justify-content-end border-top py-1 px-2 stepper-btn">
							<button
								id="btnCancle"
								name="btnCancle"
								[attr.data-testid]="'btnCancle'"
								(click)="toggleSidebar()"
								class="btn btn-outline-tertiary btn-prev mr-1"
								rippleEffect
							>
								<span class="align-middle d-sm-inline-block d-none">
									{{ "UI.k_cancel" | translate }}
								</span>
							</button>
							<button
								id="btnSubmit"
								name="btnSubmit"
								[attr.data-testid]="'btnSubmit'"
								[disabled]="disableSaveBtn"
								type="submit"
								class="btn btn-primary btn-next"
								(click)="SaveImpact(ChnImactForm)"
								rippleEffect
							>
								<i class="fa-light fa-floppy-disk align-middle mr-50 ml-0"></i>
								<span class="align-middle d-sm-inline-block d-none">{{
									"UI.k_submit" | translate
								}}</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
