<div class="slideout-content default-slideout">
	<div
		class="modalsd modal-slide-in sdfade new-user-modal"
		id="modals-slide-in"
	>
		<div class="modal-dialog">
			<div class="modal-content pt-0 pb-0">

				<div class="modal-header">
					<h5 id="exampleModalLabel">
						{{ "UI.k_pending_invitations" | translate }}
					</h5>
					<button
					id="btnClose"
					name="Close"
					type="button"
					class="btn btn-flat-secondary btn-icon"
					data-dismiss="modal"
					aria-label="Close"
					(click)="toggleSidebar('invite-user-grid')"
					[attr.data-testid]="'btnClose'"
				>
					<i class="fa-light fa-xmark"></i>
				</button>
				</div>
				<div class="main-content border-0">
					<div class="main-sec">
						<div class="action-section p-1">
							<div class="table-view flex-height" [ngClass]="(userCount > 0) ? 'border':''">
								<dns-server-grid
									[rows]="users"
									[columns]="columns"
									[count]="userCount"
									(page)="setPage($event)"
									(sort)="onSort($event)"
									[options]="options"
									(setlimit)="setLimit($event)"
									class="bootstrap core-bootstrap"
									[permissions]="permissions"
									[offset]="pageNumber"
									[limit]="limit"
								>
								</dns-server-grid>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
