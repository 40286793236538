<div class="slideout-content">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog sidebar-sm">
            <div class="modal-content add-new-user pt-0">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="toggleSidebar()">
                    ×
                </button>
                <div class="modal-header">
                    <h5 *ngIf="kbSolution?.is_deleted == false" class="modal-title" id="exampleModalLabel">{{"UI.k_kb_solution" | translate}}</h5>
                    <h5 *ngIf="kbSolution?.is_deleted == true" class="modal-title" id="exampleModalLabel">{{"UI.k_knowledgebase" | translate}} 
                        <span class="text-primary">
                            &nbsp;#{{kbSolution?.display_id}}
                        </span>
                    </h5>
                </div><br>

                <div class="row kb-search-content-info match-height p-half" *ngIf="kbSolution?.is_deleted == false">
                    <div class="col-12 kb-search-content">
                        <div class="card">
                            <a routerLink="/kb/view/{{ kbID }}" target="_blank">
                                <img
									class="card-img-top img-fluid height-100"
                                    [src]="kbSolution?.imag
                                            ? kbSolution?.imag
                                            : 'assets/images/illustration/email.svg'
                                    "
                                    alt="Related Post Pic"
												/>
                                <div class="card-body text-center">
                                    <h4>{{ kbSolution?.title }}</h4>
                                    <p class="text-body mt-1 mb-0">{{ kbSolution?.content }}</p>
                                    <div class="text-muted mb-0">
                                        {{  kbSolution?.last_update_time }}
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>                    
                </div>
                
                <!-- kb deleted -->
                <ng-container *ngIf="kbSolution?.is_deleted == true">
                    <div class="col-12 text-center no-result">
                        <h4 class="mt-4">
                            {{"Message.msg_kb_removed" | translate}}
                        </h4>
                    </div>
                </ng-container>

            </div>
        </div>
    </div>
</div>
