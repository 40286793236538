<div class="flex-auto-height">
	<div class="flex-initial">
		<div class="px-1 py-50 d-flex communication-badges bg-body">
			<!-- <div
				id="tb__txtAll"
				class="pull-left badge"
				id="btnCommunicationAll"
				(click)="communicationFilter('all')"
				[ngClass]="communicationFilterSelection == 'all' ? 'badge badge-primary' : 'badge-light-secondary'"
			>
				<span class="xs-font">{{ "UI.k_all" | translate }}</span>
			</div> -->
			<div
				id="btnCommunication{{ communicationEmailType.keyName }}"
				[attr.data-testid]="'btnCommunication' + communicationEmailType.keyName"
				class="pull-left badge cursor-pointer"
				(click)="communicationFilter(communicationEmailType)"
				[ngClass]="
					communicationFilterSelection.keyName == communicationEmailType.keyName
						? 'badge badge-primary'
						: 'badge-light-secondary'
				"
			>
				<span class="xs-font">{{ communicationEmailType.displayLabel }}</span>
			</div>
			<!-- <div
				id="tb__txtEmail"
				class="pull-left ml-50 badge"
				id="btnCommunicationEmail"
				(click)="communicationFilter('email')"
				[ngClass]="
					communicationFilterSelection == 'email' ? 'badge badge-primary' : 'badge-light-secondary'
				"
			>
				<span class="xs-font">{{ "UI.k_email" | translate }}</span>
			</div>
			<div
				id="tb__txtComments"
				class="pull-left ml-50 badge"
				id="btnCommunicationComments"
				(click)="communicationFilter('internalNote')"
				[ngClass]="
					communicationFilterSelection == 'internalNote' ? 'badge badge-primary' : 'badge-light-secondary'
				"
			>
				<span class="xs-font">{{ "UI.k_internal_note" | translate }}</span>
			</div> -->
			<ng-container *ngFor="let item of communicationRenderData.communicationTypes">
				<ng-container *ngIf="item.lisenceKey">
					<div
						*appDnsLicenseHandler="item.lisenceKey"
						class="pull-left ml-50 badge cursor-pointer"
						id="btnCommunication{{ item.keyName }}"
						[attr.data-testid]="'btnCommunication' + item.keyName"
						(click)="communicationFilter(item)"
						[ngClass]="
							communicationFilterSelection.keyName == item.keyName
								? 'badge badge-primary'
								: 'badge-light-secondary'
						"
					>
						<span class="xs-font">{{ item.displayLabel }}</span>
					</div>
				</ng-container>
				<div
					*ngIf="!item.lisenceKey"
					class="pull-left ml-50 badge cursor-pointer"
					id="btnCommunication{{ item.keyName }}"
					[attr.data-testid]="'btnCommunication' + item.keyName"
					(click)="communicationFilter(item)"
					[ngClass]="
						communicationFilterSelection.keyName == item.keyName
							? 'badge badge-primary'
							: 'badge-light-secondary'
					"
				>
					<span class="xs-font">{{ item.displayLabel }}</span>
				</div>
			</ng-container>
		</div>
	</div>
	<div class="flex-1 communication-main-sec">
		<ng-scrollbar
			class="custom-scrollbar communication-scrollbar section-scrollbar bg-body"
			#scrollable
			[ngClass]="{
				'com-height-default': showReplyBox && isRefDataLoad != false,
				'com-height-new': !showReplyBox && isRefDataLoad != false,
				'com-height-new-temp': !showReplyBox && isRefDataLoad && showEmailTemplate != false
			}"
		>
			<div class="bg-body">
				<!-- <ng-container *ngIf="communicationFilterSelection == 'internalNote'">
				<div class="comment-filter border-top d-flex p-50 card-bg communication-badges">
					<div
						id="tb__txtAll"
						class="pull-left badge mr-50"
						id="btnCommunicationAll"
						(click)="internalNoteFilter('all')"
						[ngClass]="
							internalNoteFilterSelection == 'all' ? 'badge badge-primary' : 'badge-light-secondary'
						"
					>
						<span class="xs-font">{{ "UI.k_all" | translate }}</span>
					</div>
					<div
						id="tb__txtAll"
						class="pull-left badge mr-50"
						id="btnCommunicationAll"
						(click)="internalNoteFilter('Add Notes')"
						[ngClass]="
							internalNoteFilterSelection == 'Add Notes' ? 'badge badge-primary' : 'badge-light-secondary'
						"
					>
						<span class="xs-font">{{ "UI.k_comment" | translate }}</span>
					</div>
					<div
						id="tb__txtAll"
						class="pull-left badge mr-50"
						id="btnCommunicationAll"
						(click)="internalNoteFilter('Worklog')"
						[ngClass]="
							internalNoteFilterSelection == 'Worklog' ? 'badge badge-primary' : 'badge-light-secondary'
						"
					>
						<span class="xs-font">{{ "UI.k_worklog" | translate }}</span>
					</div>
					<div
						id="tb__txtAll"
						class="pull-left badge mr-50"
						id="btnCommunicationAll"
						(click)="internalNoteFilter('Symptoms')"
						[ngClass]="
							internalNoteFilterSelection == 'Symptoms' ? 'badge badge-primary' : 'badge-light-secondary'
						"
					>
						<span class="xs-font">{{ "UI.k_symptoms" | translate }}</span>
					</div>
					<div
						id="tb__txtAll"
						class="pull-left badge mr-50"
						id="btnCommunicationAll"
						(click)="internalNoteFilter('Diagnosis')"
						[ngClass]="
							internalNoteFilterSelection == 'Diagnosis' ? 'badge badge-primary' : 'badge-light-secondary'
						"
					>
						<span class="xs-font">{{ "UI.k_diagnosis" | translate }}</span>
					</div>
					<div
						id="tb__txtAll"
						class="pull-left badge mr-50"
						id="btnCommunicationAll"
						(click)="internalNoteFilter('Root Cause')"
						[ngClass]="
							internalNoteFilterSelection == 'Root Cause'
								? 'badge badge-primary'
								: 'badge-light-secondary'
						"
					>
						<span class="xs-font">{{ "UI.k_root_cause" | translate }}</span>
					</div>
				</div>
			</ng-container> -->
				<ng-container *ngIf="communicationFilterSelection && communicationFilterSelection?.nestedTypes">
					<div class="comment-filter border-top d-flex p-50 bg-body communication-badges">
						<div
							class="pull-left badge mr-50 cursor-pointer"
							id="btnCommunication{{ communicationInternelNoteType.keyName }}"
							[attr.data-testid]="'btnCommunication' + communicationInternelNoteType.keyName"
							(click)="internalNoteFilter(communicationInternelNoteType)"
							[ngClass]="
								internalNoteFilterSelection.keyName == 'All'
									? 'badge badge-primary'
									: 'badge-light-secondary'
							"
						>
							<span class="xs-font">{{ communicationInternelNoteType.displayLabel }}</span>
						</div>
						<ng-container *ngFor="let item of communicationFilterSelection.nestedTypes">
							<ng-container *ngIf="item.lisenceKey && internelNotes.includes(item.keyName)">
								<div
									*appDnsLicenseHandler="item.lisenceKey"
									class="pull-left badge mr-50 cursor-pointer"
									id="btnCommunication{{ item.keyName }}"
									[attr.data-testid]="'btnCommunication' + item.keyName"
									(click)="internalNoteFilter(item)"
									[ngClass]="
										internalNoteFilterSelection.keyName == item.keyName
											? 'badge badge-primary'
											: 'badge-light-secondary'
									"
								>
									<span class="xs-font">{{ item.displayLabel }}</span>
								</div>
							</ng-container>
							<div
								class="pull-left badge mr-50 cursor-pointer"
								id="btnCommunication{{ item.keyName }}"
								[attr.data-testid]="'btnCommunication' + item.keyName"
								(click)="internalNoteFilter(item)"
								*ngIf="!item.lisenceKey && internelNotes.includes(item.keyName)"
								[ngClass]="
									internalNoteFilterSelection.keyName == item.keyName
										? 'badge badge-primary'
										: 'badge-light-secondary'
								"
							>
								<span class="xs-font">{{ item.displayLabel }}</span>
							</div>
						</ng-container>
					</div>
				</ng-container>
				<div
					class="right-panel-height message-container"
					infiniteScroll
					[infiniteScrollDistance]="1"
					[infiniteScrollUpDistance]="3"
					[infiniteScrollThrottle]="100"
					(scrolled)="onScrollUp()"
					[scrollWindow]="false"
					(scrolledUp)="onScrollDown()"
					[scrollTop]="scrolltop"
				>
					<div class="mails full-width px-1">
						<ng-container *ngFor="let mail of mail_list; let mindex = index">
							<div class="full-width pb-1">
								<div
									*ngIf="mail.table == 'email' && (showEmails == true || showSMS == true)"
									class="card mb-0 pl-0 pr-0 border rounded"
								>
									<div
										class="p-1 card-body border-bottom d-flex align-items-center justify-content-between"
									>
										<div class="media d-flex justify-content-start align-items-center">
											<div class="avatar avatar-sm mr-50" id="divUserInitial">
												<span
													class="avatar-content"
													*ngIf="!mail?.user_profile?.profile_image"
													[ngStyle]="{
														'background-color':
															mail.user_profile?.avatar_color != undefined
																? mail.user_profile?.avatar_color
																: '#f59518'
													}"
												>
													{{
														(mail?.user_profile?.full_name
															? mail?.user_profile?.full_name
															: "System"
														)
															| initials
															| slice: O:2
													}}
												</span>
												<span class="avatar-content" *ngIf="mail.user_profile?.profile_image">
													<img [src]="mail.user_profile?.profile_image" alt="avatar" />
												</span>
											</div>
											<div class="media-body d-flex flex-column">
												<span class="d-flex">
													<span class="fw-500 title-color" id="UserFullname">{{
														mail?.user_profile?.full_name
															? mail?.user_profile?.full_name
															: "System"
													}}</span>
													<span
														class="fw-500 title-color"
														id="UserFullname"
														*ngIf="mail?.user_profile?.email"
														>{{ "<" + mail?.user_profile?.email + ">" }}</span
													>
												</span>
												<div class="cc-list">
													<span class="to-tag title-color xs-font"
														>{{ "UI.k_to" | translate }}&nbsp;</span
													>
													<span class="email-to title-color xs-font">{{
														mail?.to_address_list[0]
													}}</span>
													<span
														class="cc-list-dropdown primary-color xs-font"
														*ngIf="
															mail?.to_address_list?.length > 1 ||
															mail?.cc_address_list?.length > 0
														"
														[ngbPopover]="popContent"
														placement="bottom auto"
														popoverClass="cc-mail-list"
														>&nbsp;<i class="fa-light fa-plus"></i
														>{{
															mail?.to_address_list?.length > 1
																? mail?.to_address_list?.length - 1
																: mail?.cc_address_list?.length
														}}</span
													>
													<ng-template #popContent>
														<div class="mail-list">
															<div class="email-list">
																<div class="from-list comman-list">
																	<span class="sm-font fw-500 title-color"
																		>{{ "UI.k_from" | translate
																		}}<i class="fa-solid fa-colon"></i
																	></span>
																	<div class="mail-id">
																		<ul class="list-unstyled">
																			<li>
																				<span
																					class="sm-font fw-500 title-color"
																					>{{
																						mail?.user_profile?.email
																							? mail?.user_profile?.email
																							: "System"
																					}}</span
																				>
																			</li>
																		</ul>
																	</div>
																</div>
																<div
																	*ngIf="mail?.to_address_list?.length > 0"
																	class="from-list comman-list"
																>
																	<span class="sm-font fw-500 title-color"
																		>{{ "UI.k_to" | translate
																		}}<i class="fa-solid fa-colon"></i
																	></span>
																	<div class="mail-id">
																		<ul>
																			<li
																				*ngFor="
																					let to_mail of mail?.to_address_list
																				"
																			>
																				<span
																					class="sm-font fw-500 title-color"
																					>{{ to_mail }}</span
																				>
																			</li>
																		</ul>
																	</div>
																</div>
																<div
																	*ngIf="mail?.cc_address_list?.length > 0"
																	class="from-list comman-list"
																>
																	<span class="sm-font fw-500 title-color"
																		>{{ "UI.k_cc" | translate
																		}}<i class="fa-solid fa-colon"></i
																	></span>
																	<div class="mail-id">
																		<ul
																			*ngFor="
																				let cc_mail of mail?.cc_address_list
																			"
																		>
																			<li>
																				<span
																					class="sm-font fw-500 title-color"
																					>{{ cc_mail }}</span
																				>
																			</li>
																		</ul>
																	</div>
																</div>
															</div>
														</div>
													</ng-template>
												</div>
											</div>

											<!-- <div class="cell-line-height">
									<span
										class="fw-500 text-nowrap sm-font"
										id="UserFullname"
										>{{
											mail?.user_profile?.full_name
												? mail?.user_profile
														?.full_name
												: "System"
										}}</span
									>
									<span
											class="text-muted d-block font-small-2"
											>{{ mail?.user_profile?.email }}</span
										>
								</div> -->
										</div>
										<div class="d-flex justify-content-start align-items-center">
											<div
												id="lb_unread"
												[attr.data-testid]="lb_unread"
												*ngIf="isReadUnread"
												class="pull-right badge mx-50 badge-light-secondary cursor-pointer"
												(click)="markAsReadUnread(mail.is_user_read, mail)"
												[ngClass]="mail?.is_user_read ? 'btn-outline-primary' : ''"
											>
												<i
													class="pt-25 mr-50 fa-light {{
														mail?.is_user_read ? 'fa-eye-slash text-primary' : 'fa-eye'
													}}"
												></i>
												<span
													class="xs-font"
													[ngClass]="mail?.is_user_read ? 'text-primary' : ''"
													>{{
														mail?.is_user_read
															? ("UI.k_unread" | translate)
															: ("UI.k_read" | translate)
													}}</span
												>
											</div>
											<div
												*ngIf="mail?.is_private == true && mail.table != 'email'"
												class="mx-50"
											>
												<span class="badge badge-light-danger"
													>{{ "UI.k_private" | translate }}/{{
														"UI.k_internal_note" | translate
													}}</span
												>
											</div>
											<div *ngIf="mail?.user_name == 'Teams'" class="mx-50">
												<img
													class="thumbnail-image"
													src="assets/images/marketplace/ms-teams.png"
													width="24"
													height="24"
												/>
											</div>
											<div *ngIf="mail?.user_name == 'Slack'" class="mx-50">
												<img
													class="thumbnail-image"
													src="assets/images/marketplace/slack.png"
													width="24"
													height="24"
												/>
											</div>
											<div *ngIf="mail?.user_name == 'Azure'" class="mx-50">
												<img
													class="thumbnail-image"
													src="assets/images/marketplace/infraon/azureAD.svg"
													width="24"
													height="24"
												/>
											</div>
											<div
												*ngIf="
													(!mail?.is_private || mail?.is_private == false) &&
													mail.table != 'email'
												"
												class="mx-50"
											>
												<span class="badge badge-light-success">{{
													"UI.k_public" | translate
												}}</span>
											</div>
											<span
												class="title-color xs-font"
												[attr.data-testid]="'divCommunicationDate'"
												id="divCommunicationDate"
												>{{ mail.creation_time }}</span
											>
											<div
												ngbDropdown
												class="ml-2"
												*ngIf="mail?.msg_type == 1 && !disable_config"
											>
												<div
													role="button"
													class="hide-arrow"
													id="email_more"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
													ngbDropdownToggle
												>
													<i class="fa-light fa-ellipsis-vertical"></i>
												</div>
												<div
													id="btnmailOpt"
													name="mailOpt"
													ngbDropdownMenu
													class="dropdown-menu-right"
													aria-labelledby="email_more"
												>
													<div
														id="btnMailReply"
														[attr.data-testid]="'btnMailReply'"
														name="mailReply"
														ngbDropdownItem
														(click)="
															scrollable.scrollToElement('#emailApplication');
															composeMail(
																$index,
																mail,
																'edit',
																'Re:',
																false,
																scrolltocommentwindow
															);
															showReplyBox = false
														"
													>
														<i class="fa-light fa-arrow-turn-down-left mr-50"></i>
														{{ "UI.k_reply" | translate }}
													</div>
													<div
														id="btnMailReplyAll"
														[attr.data-testid]="'btnMailReplyAll'"
														name="mailReplyAll"
														ngbDropdownItem
														(click)="
															scrollable.scrollToElement('#emailApplication');
															composeMail(
																$index,
																mail,
																'edit',
																'Re:',
																false,
																scrolltocommentwindow,
																true
															);
															showReplyBox = false
														"
													>
														<i class="fa-light fa-reply-all mr-50"></i>
														{{ "UI.K_reply_all" | translate }}
													</div>
													<div
														id="btnMailForward"
														[attr.data-testid]="'btnMailForward'"
														name="mailForward"
														ngbDropdownItem
														(click)="
															scrollable.scrollToElement('#emailApplication');
															composeMail(
																$index,
																mail,
																'edit',
																'Fw:',
																false,
																scrolltocommentwindow
															);
															showReplyBox = false
														"
													>
														<i class="fa-light fa-share mr-50"></i>
														{{ "UI.k_forward" | translate }}
													</div>
													<!-- <div *ngIf="!mail?.is_user_read"
                                                    id="btnRead"
                                                    name="mailForward"
                                                    ngbDropdownItem
                                                    (click)="
                                                    markAsReadUnread(
                                                            true, mail
                                                        );
                                                        showReplyBox = false
                                                    "
                                                >
                                                    <i
                                                        class="fa-light fa-envelope-open-text mr-50"
                                                    ></i>
                                                    {{ "UI.k_mark_as_read" | translate }}
                                                </div>
                                                <div *ngIf="mail?.is_user_read"
                                                    id="btnMailForward"
                                                    name="mailForward"
                                                    ngbDropdownItem
                                                    (click)="
                                                    markAsReadUnread(
                                                        false, mail
                                                    );
                                                        showReplyBox = false
                                                    "
                                                >
                                                    <i
                                                        class="fa-light fa-envelope mr-50"
                                                    ></i>
                                                    {{ "UI.k_mark_as_unread" | translate }}
                                                </div> -->
													<div
														id="btnMailSeen"
														[attr.data-testid]="'btnMailSeen'"
														*ngIf="isSeenBy"
														name="nameMailSeen"
														ngbDropdownItem
														class="dropdown-item seenby-dropdown"
													>
														<i class="fa-light fa-eye"></i>
														{{ "UI.k_seen_by" | translate }}
														&nbsp;<span class="primary-color">
															{{ mail?.message_read_mapping?.length }}
														</span>
														<div class="seen-user-list dropdown-menu body-color">
															<div class="flex-auto-height">
																<div class="flex-initial px-75 mb-1 mt-25">
																	{{ "UI.k_seen_by" | translate }}
																</div>
																<div class="flex-1">
																	<ng-scrollbar
																		class="custom-scrollbar section-scrollbar"
																	>
																		<div
																			class="d-flex align-items-center px-75 mb-75"
																			*ngFor="
																				let user_read of mail?.message_read_mapping
																			"
																		>
																			<!-- <div
                                                                            class="avatar avatar-sm mr-50"
                                                                            *ngIf="
                                                                                item?.profile_image
                                                                            "
                                                                        >
                                                                            <img
                                                                                src="{{
                                                                                    item.profile_image
                                                                                }}"
                                                                                alt="avatar"
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            *ngIf="
                                                                                !item?.profile_image
                                                                            "
                                                                            class="avatar avatar-sm mr-50"
                                                                        >
                                                                            <span
                                                                                class="avatar-content"
                                                                                [ngStyle]="{
                                                                                    'background-color':
                                                                                        item?.avatar_color !=
                                                                                        undefined
                                                                                            ? item?.avatar_color
                                                                                            : '#f59518'
                                                                                }"
                                                                                >{{
                                                                                    item?.name
                                                                                        | initials
                                                                                        | slice
                                                                                            : O
                                                                                            : 2
                                                                                }}</span
                                                                            >
                                                                        </div> -->
																			<span>{{ user_read.read_by_name }}</span>
																		</div>
																	</ng-scrollbar>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="px-1 py-2 card-body full-width pull-left">
										<div *ngIf="mail.msg_type === 2" class="mail-content d-flex mb-half">
											<div>
												{{ "UI.k_to" | translate }}:
												<span>
													{{ mail?.to_address }}
												</span>
											</div>
										</div>
										<div class="px-1 py-2 card-body full-width pull-left">
											<div (scroll)="handleScroll($event)" [class.read]="mail?.is_user_read">
												<div
													class="mail-content reg-font"
													id="divMailcontent"
													[innerHTML]="
														(mail?.new_reply_mail ? mail.new_reply_mail : mail?.content)
															| safe: 'html'
													"
												></div>
											</div>

											<div class="pt-1">
												<span
													*ngFor="let attachment of mail?.attached_files"
													id="btnDownloadAttachedFile"
													[attr.data-testid]="'btnDownloadAttachedFile'"
													(click)="
														downloadAttachment(
															attachment.file_path,
															attachment.zip_file_name
														)
													"
													class="cursor-pointer pull-left pr-3 text-primary sm-font"
												>
													<i class="fa-light fa-paperclip"></i>&nbsp;&nbsp;<b>{{
														attachment.file_name
													}}</b></span
												>
											</div>
										</div>
									</div>
								</div>
								<!-- </div> -->

								<!-- <div *ngIf="showComments==true"  class="pr-2 row full-width"> -->
								<div
									*ngIf="
										mail.table == 'comments' ||
										mail.table == 'incident' ||
										mail.table == 'problem' ||
										mail.table == 'request_process' ||
										mail.table == 'change' ||
										mail.table == 'imacd_process' ||
										(mail.table == 'release' && showComments == true)
									"
									class="card mb-0 pl-0 pr-0 border"
								>
									<div
										class="p-1 card-body border-bottom d-flex align-items-center justify-content-between"
									>
										<div class="media d-flex justify-content-start align-items-center">
											<div class="avatar avatar-sm mr-50">
												<span
													class="avatar-content"
													*ngIf="!mail.user_profile?.profile_image"
													[ngStyle]="{
														'background-color':
															mail.user_profile?.avatar_color != undefined
																? mail.user_profile?.avatar_color
																: '#f59518'
													}"
												>
													{{ mail?.user_profile?.full_name | initials | slice: O:2 }}
												</span>
												<span class="avatar-content" *ngIf="mail.user_profile?.profile_image">
													<img [src]="mail.user_profile?.profile_image" alt="avatar" />
												</span>
											</div>

											<div class="cell-line-height">
												<span class="fw-500 text-nowrap sm-font">{{
													mail?.user_profile?.full_name
												}}</span>

												<span class="text-muted d-block font-small-2">{{
													mail?.user_profile?.email
												}}</span>
											</div>
										</div>
										<div class="d-flex align-items-center">
											<ng-container *ngIf="comment_types.includes(mail?.type) && isReadUnread">
												<div
													id="lb_unread"
													[attr.data-testid]="'lb_unread'"
													class="pull-right badge mx-50 badge-light-secondary"
													(click)="markAsReadUnread(mail.is_user_read, mail)"
													[ngClass]="mail?.is_user_read ? 'btn-outline-primary' : ''"
												>
													<i
														class="pt-25 mr-50 fa-light {{
															mail?.is_user_read ? 'fa-eye-slash text-primary' : 'fa-eye'
														}}"
													></i>
													<span
														class="xs-font"
														[ngClass]="mail?.is_user_read ? 'text-primary' : ''"
														>{{
															mail?.is_user_read
																? ("UI.k_unread" | translate)
																: ("UI.k_read" | translate)
														}}</span
													>
												</div>
											</ng-container>
											<div
												*ngIf="mail?.is_private == true && mail.table != 'email'"
												class="mx-50"
											>
												<span class="badge badge-light-danger"
													>{{ "UI.k_private" | translate }}/{{
														"UI.k_internal_note" | translate
													}}</span
												>
											</div>
											<div
												*ngIf="
													(!mail?.is_private || mail?.is_private == false) &&
													mail.table != 'email'
												"
												class="mx-50"
											>
												<span class="badge badge-light-success">{{
													"UI.k_public" | translate
												}}</span>
											</div>
											<div *ngIf="mail?.user_name == 'Teams'" class="mx-50">
												<img
													class="thumbnail-image"
													src="assets/images/marketplace/ms-teams.png"
													width="24"
													height="24"
												/>
											</div>
											<div *ngIf="mail?.user_name == 'Slack'" class="mx-50">
												<img
													class="thumbnail-image"
													src="assets/images/marketplace/slack.png"
													width="24"
													height="24"
												/>
											</div>
											<div *ngIf="mail?.user_name == 'Azure'" class="mx-50">
												<img
													class="thumbnail-image"
													src="assets/images/marketplace/infraon/azureAD.svg"
													width="24"
													height="24"
												/>
											</div>
											<span class="pt-half text-secondary xs-font">{{ mail.creation_time }}</span>
											<div
												ngbDropdown
												class="ml-2"
												*ngIf="
													this.moduleType == 'INCIDENT' && comment_types.includes(mail?.type)
												"
											>
												<div
													role="button"
													class="hide-arrow"
													id="email_more"
													[attr.data-testid]="'email_more'"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
													ngbDropdownToggle
												>
													<i class="fa-light fa-ellipsis-vertical"></i>
												</div>
												<div
													id="btnmailOpt"
													name="mailOpt"
													[attr.data-testid]="'btnmailOpt'"
													ngbDropdownMenu
													class="dropdown-menu-right"
													aria-labelledby="email_more"
												>
													<!-- <div *ngIf="!mail?.is_user_read"
                                                    id="btnUnRead"
                                                    name="mailRead"
                                                    ngbDropdownItem
                                                    (click)="
                                                    markAsReadUnread(
                                                            true, mail
                                                        );
                                                        showReplyBox = false
                                                    "
                                                >
                                                    <i
                                                        class="fa-light fa-envelope-open-text mr-50"
                                                    ></i>
                                                    {{ "UI.k_mark_as_read" | translate }}
                                                </div>
                                                <div *ngIf="mail?.is_user_read"
                                                    id="btnMailUnread"
                                                    name="mailUnread"
                                                    ngbDropdownItem
                                                    (click)="
                                                    markAsReadUnread(
                                                        false, mail
                                                    );
                                                        showReplyBox = false
                                                    "
                                                >
                                                    <i
                                                        class="fa-light fa-envelope mr-50"
                                                    ></i>
                                                    {{ "UI.k_mark_as_unread" | translate }}
                                                </div> -->
													<div
														id="btnMailSeen"
														name="nameMailSeen"
														*ngIf="isSeenBy"
														[attr.data-testid]="'btnMailSeen'"
														ngbDropdownItem
														class="dropdown-item seenby-dropdown"
													>
														<i class="fa-light fa-eye"></i>
														{{ "UI.k_seen_by" | translate }}
														&nbsp;<span class="primary-color">
															{{ mail?.message_read_mapping?.length }}
														</span>
														<div class="seen-user-list dropdown-menu body-color">
															<div class="flex-auto-height">
																<div class="flex-initial px-75 mb-1 mt-25">
																	{{ "UI.k_seen_by" | translate }}
																</div>
																<div class="flex-1">
																	<ng-scrollbar
																		class="custom-scrollbar section-scrollbar"
																	>
																		<div
																			class="d-flex align-items-center px-75 mb-75"
																			*ngFor="
																				let user_read of mail?.message_read_mapping
																			"
																		>
																			<!-- <div
                                                                            class="avatar avatar-sm mr-50"
                                                                            *ngIf="
                                                                                item?.profile_image
                                                                            "
                                                                        >
                                                                            <img
                                                                                src="{{
                                                                                    item.profile_image
                                                                                }}"
                                                                                alt="avatar"
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            *ngIf="
                                                                                !item?.profile_image
                                                                            "
                                                                            class="avatar avatar-sm mr-50"
                                                                        >
                                                                            <span
                                                                                class="avatar-content"
                                                                                [ngStyle]="{
                                                                                    'background-color':
                                                                                        item?.avatar_color !=
                                                                                        undefined
                                                                                            ? item?.avatar_color
                                                                                            : '#f59518'
                                                                                }"
                                                                                >{{
                                                                                    item?.name
                                                                                        | initials
                                                                                        | slice
                                                                                            : O
                                                                                            : 2
                                                                                }}</span
                                                                            >
                                                                        </div> -->
																			<span>{{ user_read.read_by_name }}</span>
																		</div>
																	</ng-scrollbar>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div
												ngbDropdown
												class="ml-2"
												*ngIf="
													(this.moduleType == 'PROBLEM' ||
														this.moduleType == 'REQUEST' ||
														this.moduleType == 'CHANGE' ||
														this.moduleType == 'RELEASE') &&
													mail?.msg_type == 1 &&
													!disable_config
												"
											>
												<div
													role="button"
													class="hide-arrow"
													id="email_more"
													[attr.data-testid]="'email_more'"
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
													ngbDropdownToggle
												>
													<i class="fa-light fa-ellipsis-vertical"></i>
												</div>
												<div
													id="btnmailOpt"
													name="mailOpt"
													[attr.data-testid]="'btnmailOpt'"
													ngbDropdownMenu
													class="dropdown-menu-right"
													aria-labelledby="email_more"
												>
													<!-- <div *ngIf="!mail?.is_user_read"
                                                    id="btnUnRead"
                                                    name="mailRead"
                                                    ngbDropdownItem
                                                    (click)="
                                                    markAsReadUnread(
                                                            true, mail
                                                        );
                                                        showReplyBox = false
                                                    "
                                                >
                                                    <i
                                                        class="fa-light fa-envelope-open-text mr-50"
                                                    ></i>
                                                    {{ "UI.k_mark_as_read" | translate }}
                                                </div>
                                                <div *ngIf="mail?.is_user_read"
                                                    id="btnMailUnread"
                                                    name="mailUnread"
                                                    ngbDropdownItem
                                                    (click)="
                                                    markAsReadUnread(
                                                        false, mail
                                                    );
                                                        showReplyBox = false
                                                    "
                                                >
                                                    <i
                                                        class="fa-light fa-envelope mr-50"
                                                    ></i>
                                                    {{ "UI.k_mark_as_unread" | translate }}
                                                </div> -->
													<div
														id="btnMailSeen"
														name="nameMailSeen"
														*ngIf="isSeenBy"
														[attr.data-testid]="'btnMailSeen'"
														ngbDropdownItem
														class="dropdown-item seenby-dropdown"
													>
														<i class="fa-light fa-eye"></i>
														{{ "UI.k_seen_by" | translate }}
														&nbsp;<span class="primary-color">
															{{ mail?.message_read_mapping?.length }}
														</span>
														<div class="seen-user-list dropdown-menu body-color">
															<div class="flex-auto-height">
																<div class="flex-initial px-75 mb-1 mt-25">
																	{{ "UI.k_seen_by" | translate }}
																</div>
																<div class="flex-1">
																	<ng-scrollbar
																		class="custom-scrollbar section-scrollbar"
																	>
																		<div
																			class="d-flex align-items-center px-75 mb-75"
																			*ngFor="
																				let user_read of mail?.message_read_mapping
																			"
																		>
																			<!-- <div
                                                                            class="avatar avatar-sm mr-50"
                                                                            *ngIf="
                                                                                item?.profile_image
                                                                            "
                                                                        >
                                                                            <img
                                                                                src="{{
                                                                                    item.profile_image
                                                                                }}"
                                                                                alt="avatar"
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            *ngIf="
                                                                                !item?.profile_image
                                                                            "
                                                                            class="avatar avatar-sm mr-50"
                                                                        >
                                                                            <span
                                                                                class="avatar-content"
                                                                                [ngStyle]="{
                                                                                    'background-color':
                                                                                        item?.avatar_color !=
                                                                                        undefined
                                                                                            ? item?.avatar_color
                                                                                            : '#f59518'
                                                                                }"
                                                                                >{{
                                                                                    item?.name
                                                                                        | initials
                                                                                        | slice
                                                                                            : O
                                                                                            : 2
                                                                                }}</span
                                                                            >
                                                                        </div> -->
																			<span>{{ user_read.read_by_name }}</span>
																		</div>
																	</ng-scrollbar>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<ng-template #popContent>
												<div class="mail-list">
													<div
														*ngFor="
															let user_read of mail?.message_read_mapping;
															let i = index
														"
														class="from-list comman-list"
													>
														<span class="sm-font fw-500 title-color"
															>{{ user_read?.read_by_name
															}}<i class="fa-solid fa-colon"></i
														></span>
													</div>
												</div>
											</ng-template>
										</div>
									</div>
									<div class="pl-1 pr-3 pt-2 pb-2 card-body full-width pull-left">
										<div class="d-flex mb-half" *ngIf="mail?.type">
											<span class="xs-font mr-75 title-color"
												>{{ "UI.k_type" | translate }}:</span
											>

											<span class="xs-font">
												{{ editorIDMap[mail.type] }}
											</span>
										</div>
										<div *ngIf="mail.type === 'Worklog'" class="mail-content xs-font">
											<span
												*ngIf="
													mail.work_duration_day ||
													mail.work_duration_hr ||
													mail.work_duration_min
												"
											>
												{{ "UI.k_work_duration" | translate }}:
												<span *ngIf="mail?.work_duration_day">
													{{ mail.work_duration_day }}
													{{ "UI.k_days" | translate }} </span
												><span *ngIf="mail?.work_duration_hr"
													>{{ mail.work_duration_hr }} {{ "UI.k_hours" | translate }}</span
												>

												<span *ngIf="mail?.work_duration_min"
													>{{ mail.work_duration_min }} {{ "UI.k_mins" | translate }}</span
												>
											</span>
										</div>

										<div *ngIf="mail.type === 'Closure Note'" class="mail-content xs-font">
											<div *ngIf="mail.closed_type">
												{{ "UI.k_closed_type" | translate }}
												:
												<span>
													{{ mail.closed_type }}
												</span>
											</div>
											<div *ngIf="mail.closed_by">
												{{ "UI.k_closed_by" | translate }}
												:
												<span>
													{{ mail.closed_by }}
												</span>
											</div>
											<div>
												{{ "UI.k_agreed_closure_date" | translate }}:
												<span>
													{{ mail.agreed_closure_date }}
												</span>
											</div>
											<div>
												{{ "UI.k_actual_closure_date" | translate }}:
												<span>
													{{ mail.actual_closure_date }}
												</span>
												<span *ngFor="let item of mail?.custom_field_data | keyvalue">
													<br />
													{{ item?.key }}:
													<span *ngIf="(item?.value | diclen) == 0">
														{{ (item?.value | json) == "{}" ? "-" : item?.value }}
													</span>
													<span *ngIf="(item?.value | diclen) >= 1">
														<span
															*ngFor="
																let checked_data of item?.value | keyvalue;
																let dindex = index
															"
														>
															<span *ngIf="dindex == 0">
																{{ checked_data.key }}
															</span>
															<span *ngIf="dindex > 0"> , {{ checked_data.key }} </span>
														</span>
													</span>
												</span>
											</div>
										</div>
										<div
											*ngIf="mail.type === 'Resolution'"
											class="mail-content xs-font d-flex mb-half"
										>
											<div>
												{{ "UI.k_resolution_date" | translate }}:
												<span>
													{{ mail?.resolution_date }}
												</span>
												<span *ngFor="let item of mail?.custom_field_data | keyvalue">
													<br />
													{{ item?.key }}:
													<span *ngIf="(item?.value | diclen) == 0">
														{{ (item?.value | json) == "{}" ? "-" : item?.value }}
													</span>
													<span *ngIf="(item?.value | diclen) >= 1">
														<span
															*ngFor="
																let checked_data of item?.value | keyvalue;
																let dindex = index
															"
														>
															<span *ngIf="dindex == 0">
																{{ checked_data.key }}
															</span>
															<span *ngIf="dindex > 0"> , {{ checked_data.key }} </span>
														</span>
													</span>
												</span>
											</div>
										</div>

										<div
											(scroll)="handleScroll($event)"
											class="mail-content xs-font"
											style="font-size: 8px"
											[innerHTML]="mail.description | safe: 'html'"
										></div>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
					<form
						#cmtForm="ngForm"
						#scrolltocommentwindow
						id="emailApplication"
						[attr.data-testid]="'emailApplication'"
						class="communication_mail_form"
					>
						<div class="email_box border-top card-bg" *ngIf="showEditor === true || isEmailCompOpen">
							<div
								*ngIf="showEditor === true"
								class="form-group enrich-text-editor m-0 p-0 com-text-editor-container"
							>
								<label class="form-label pt-half pl-half c-fs-12" for="txtDate">
									{{
										!refData?.basic_info?.status?.is_pir
											? editorIDMap[editorId]
											: ("UI.k_pir_comment" | translate)
									}}
								</label>
								<div class="diagnosis-panel m-0">
									<!--									<editor-->
									<!--									[(ngModel)]="erichTextData"-->
									<!--									[disabled]="false"-->
									<!--									id="inci-edit-tiny"-->
									<!--									[init]="{}"-->
									<!--									initialValue=""-->
									<!--									plugins=" ['advlist autolink lists link image charmap print preview anchor',-->
									<!--                                                                    'searchreplace visualblocks code fullscreen',-->
									<!--                                                                    'insertdatetime media table contextmenu paste code']"-->
									<!--									branding="false"-->
									<!--									[inline]="false"-->
									<!--									(onSelectionChange)="handleEvent($event)"-->
									<!--								>-->
									<!--								</editor>-->

									<quill-editor
										[(ngModel)]="erichTextData"
										id="{{ moduleType }}TextEditor"
										attr.data-testid="{{ moduleType }}TextEditor"
										name="{{ moduleType }}TextEditor"
										[styles]="quillEditorStyles"
										(onContentChanged)="getEnrichTextData($event)"
										(onSelectionChanged)="onSelectionChange($event)"
										[ngModelOptions]="{ standalone: true }"
										[trimOnValidation]="true"
										#TDInciEditor
										[appQuillValidator]="TDInciEditor"
										[appQuillForm]="cmtForm"
										[minLength]="3"
										[required]="true"
										[class.error]="TDInciEditor?.errors && cmtForm.submitted"
										placeholder="{{ 'UI.k_insert_text_here' | translate }}..."
									>
										<div
											quill-editor-toolbar
											class="row p-50 align-items-center justify-content-center ql-toolbar ql-snow pb-0"
										>
											<div class="col-9">
												<span class="ql-formats">
													<button
														class="ql-bold"
														title="{{ 'UI.k_bold' | translate }}"
													></button>
													<button
														class="ql-italic"
														title="{{ 'UI.k_italic' | translate }}"
													></button>
													<button
														class="ql-underline"
														title="{{ 'UI.k_underline' | translate }}"
													></button>
												</span>
												<span class="ql-formats">
													<select class="ql-align" title="{{ 'UI.k_alignment' | translate }}">
														<option selected></option>
														<option value="center"></option>
														<option value="right"></option>
														<option value="justify"></option>
													</select>
												</span>
												<span class="ql-formats">
													<button class="ql-list" value="ordered" type="button"></button>
												</span>
												<span class="ql-formats">
													<button class="ql-link" value="ordered" type="button"></button>
													<button class="ql-image" value="ordered" type="button"></button>
													<button class="ql-video" value="ordered" type="button"></button>
												</span>
												<span class="ql-formats">
													<button
														class="ql-code-block"
														value="ordered"
														type="button"
													></button>
												</span>
												<span class="ql-formats">
													<button class="ql-clean" value="ordered" type="button"></button>
												</span>
											</div>
											<div class="col-3 cursor-pointer">
												<div
													class="text-enhancement mr-25 cursor-pointer"
													[ngbPopover]="popContent"
													id="cmnt-txt-enhancement"
													[attr.data-testid]="'cmnt-txt-enhancement'"
													[autoClose]="false"
													popoverClass="textenhancementpopover bg-body"
													[placement]="['left-bottom', 'left']"
													container="body"
													triggers="manual"
													#popOver="ngbPopover"
													(click)="openPopover()"
													(mouseover)="isTooltipShow = false"
												>
													<button class="action-button">
														<img src="assets/images/workspace/infi-logo.svg" alt="" />
														<span class="sm-font fw-400 line-height-1 ml-25 title-color"
															>{{ "UI.k_text_enhancement" | translate }}
														</span>
													</button>
												</div>
											</div>
										</div>

										<ng-template #popContent>
											<div class="text-enhancement-model">
												<div class="text-enhancement-header primary-bg">
													<div class="header-title p-75 white-color">
														<img src="assets/images/workspace/infi-logo-squre.svg" alt="" />
														<span class="line-height-1 ml-25 fw-500">{{
															"UI.k_infiy_text_enhancement" | translate
														}}</span>
													</div>
													<div class="header-action d-flex p-75">
														<button
															class="btn btn-reg insert btn-icon waves-effect"
															id="txt-insert"
															(click)="closeModel()"
															[attr.data-testid]="'txt-insert'"
														>
															<i class="far fa-times text-white"></i>
														</button>
													</div>
												</div>
												<div class="text-enhancement-body">
													<div class="no-data-model" *ngIf="rephrase_error">
														<div class="no-data-container p-75 border">
															<div class="contain-header">
																<i class="fas fa-exclamation-triangle"></i
																><span class="text-title fw-700 ml-25">{{
																	"Error.err_something_went_wrong" | translate
																}}</span>
															</div>
															<div class="contain-body sm-font">
																{{ "Message.msg_rephrase_try_error" | translate }}
																{{ "Message.msg_try_again" | translate }}
															</div>
															<div class="content-action mt-50">
																<button
																	class="btn-xs border reg-font fw-600"
																	[attr.data-testid]="'content-action'"
																	(click)="resetRephraseError()"
																>
																	{{ "UI.k_ok" | translate }}
																</button>
															</div>
														</div>
													</div>
													<ng-container *ngIf="!rephrase_error">
														<div
															class="tone-body d-flex p-1 align-items-center border-bottom"
														>
															<div class="sm-font title-color fw-600 tone-container">
																<!-- {{'UI.k_select_option' | translate}} -->
																<ng-select
																	required
																	bindLabel="title"
																	class="form-control"
																	name="selToneOptions"
																	id="selToneOptions"
																	placeholder="{{ 'UI.k_select_Tone' | translate }}"
																	[(ngModel)]="toneData"
																	(change)="onToneSelection(i, toneData)"
																	#selToneInput="ngModel"
																	[items]="toneItem"
																></ng-select>
															</div>
															<div
																class="d-flex align-items-center ml-50 gap-75 tone-option"
																*ngFor="let tone of toneOptionData; let i = index"
															>
																<button
																	class="sm-font p-25 border rounded cursor-pointer"
																	id="{{ tone.title }}"
																	[attr.data-testid]="tone.title"
																	(click)="onOptionSelection(i, tone)"
																	[ngClass]="{
																		activeToneButton: activeButtonIndex === i,
																		disabledToneButton: !selectedText,
																		'tone-action': selectedText
																	}"
																	[disabled]="!selectedText"
																>
																	<i class="fa-light {{ tone.icon }} mr-25"></i>
																	{{ tone.title }}
																</button>
															</div>
														</div>
													</ng-container>

													<div
														class="tone-description mx-1 mt-1 border title-color"
														*ngIf="!rephrase_error"
													>
														<quill-editor
															[placeholder]="'UI.k_infiy_text_placeholder' | translate"
															[(ngModel)]="selectedText"
															[ngModelOptions]="{
																standalone: true
															}"
															class="content-editor"
															(onFocus)="focus($event)"
															(onBlur)="blur($event)"
															id="mail-tone-text-description"
														>
															<div quill-editor-toolbar class="row pb-0 d-none"></div>
														</quill-editor>
													</div>
													<div
														class="tone-description m-1 border title-color"
														*ngIf="!rephraseDisable && !rephrase_error"
													>
														<quill-editor
															[placeholder]="false"
															[(ngModel)]="rephrasedText"
															[ngModelOptions]="{
																standalone: true
															}"
															class="content-editor"
															(onFocus)="focus($event)"
															(onBlur)="blur($event)"
															id="mail-tone-rephrase-description"
														>
															<div quill-editor-toolbar class="row pb-0 d-none"></div>
														</quill-editor>
													</div>
													<div
														class="loader-tone border-top title-color d-flex justify-content-center align-items-center"
														*ngIf="rephraseDisable"
													>
														<div
															class="spinner-border text-primary"
															aria-hidden="true"
														></div>
													</div>
												</div>
												<div class="text-enhancement-footer border-top">
													<div
														class="footer-action d-flex align-items-center justify-content-end p-75"
													>
														<button
															class="btn btn-primary white-color waves-effect insert"
															id="txt-insert"
															(click)="handleRephrase('comment')"
															[disabled]="
																!toneOptionType || (toneOptionType && rephraseDisable)
															"
															[attr.data-testid]="'txt-insert'"
														>
															{{ "UI.k_insert" | translate }}
														</button>
													</div>
												</div>
											</div>
										</ng-template>
									</quill-editor>
								</div>
								<div class="row justify-content-end ml-0 p-1 mt-1 mb-1 w-100">
									<div class="col ml-1" *ngIf="editorId === 'Add Notes' || editorId === 'Worklog'">
										<div class="custom-control custom-control-primary custom-switch">
											<div
												id="commentType"
												[attr.data-testid]="'commentType'"
												name="commentType"
												class="btn-group btn-group-toggle"
												ngbRadioGroup
												name="radioBasic"
												[(ngModel)]="isPrivate"
											>
												<label
													ngbButtonLabel
													class="btn-outline-primary btn-sm c-fs-12"
													rippleEffect
												>
													<input
														checked="true"
														ngbButton
														id="publicComment"
														[attr.data-testid]="'publicComment'"
														type="radio"
														value="false"
													/>Public
												</label>
												<label
													ngbButtonLabel
													class="btn-outline-primary btn-sm c-fs-12"
													rippleEffect
												>
													<input
														ngbButton
														id="privateComment"
														[attr.data-testid]="'privateComment'"
														type="radio"
														value="true"
													/>Private
												</label>
											</div>
										</div>
									</div>
									<div class="col ml-1" *ngIf="editorId === 'Worklog'">
										<div class="input-group d-flex">
											<input
												id="timeSpnt"
												[attr.data-testid]="'timeSpnt'"
												name="timeSpnt"
												value="Time Spent"
												class="form-control form-control-sm worklog-ttl"
												disabled
											/>
											<input
												class="form-control form-control-sm input-group-append worklog-input-1"
												[class.error]="WorklogDayRef.invalid && cmtForm.submitted"
												#WorklogDayRef="ngModel"
												[(ngModel)]="workDuration.day"
												pattern="^[0-9]*$"
												id="txtWorkDurationDay"
												[attr.data-testid]="'txtWorkDurationDay'"
												name="txtWorkDurationDay"
												type="text"
												placeholder="Day(s)"
											/>
											<input
												class="form-control form-control-sm input-group-append worklog-input-1"
												[class.error]="WorklogHourRef.invalid && cmtForm.submitted"
												#WorklogHourRef="ngModel"
												[(ngModel)]="workDuration.hr"
												pattern="([0-9]|[1][0-9]|2[0-3])"
												id="txtWorkDurationHr"
												[attr.data-testid]="'txtWorkDurationHr'"
												name="txtWorkDurationHr"
												type="text"
												placeholder="Hour(s)"
											/>
											<input
												class="form-control form-control-sm input-group-append worklog-input-1"
												[class.error]="WorklogMinRef.invalid && cmtForm.submitted"
												#WorklogMinRef="ngModel"
												[(ngModel)]="workDuration.min"
												pattern="([0-9]|[1-5][0-9])"
												id="txtWorkDurationMin"
												[attr.data-testid]="'txtWorkDurationMin'"
												name="txtWorkDurationMin"
												type="text"
												placeholder="Minute(s)"
											/>
										</div>
										<div class="error" *ngIf="cmtForm.submitted">
											<small
												class="form-text text-danger"
												*ngIf="WorklogDayRef?.errors?.pattern && WorklogDayRef.invalid"
												>{{ "Error.err_numeric_data" | translate }}
											</small>
											<small
												*ngIf="WorklogHourRef?.errors?.pattern && WorklogHourRef.invalid"
												class="form-text text-danger"
											>
												{{ "Message.msg_validate_number_0_23" | translate }} 0
												{{ "Message.msg_and" | translate }} 23.
											</small>
											<small
												*ngIf="WorklogMinRef?.errors?.pattern && WorklogMinRef.invalid"
												class="form-text text-danger"
											>
												{{ "Message.msg_validate_number_0_59" | translate }} 0
												{{ "Message.msg_and" | translate }} 59.
											</small>
										</div>
									</div>
									<div class="row" *ngIf="editorId == 'Resolution' || editorId == 'Closure Note'">
										<div
											[ngClass]="
												editorId == 'Closure Note'
													? 'col-xs-12 col-md-12 col-xl-12 col-lg-12 form-group'
													: 'col-xs-12 col-md-6 col-xl-6 col-lg-6 form-group mb-0'
											"
										>
											<app-status-picker
												[inputConfig]="{
													isTextMode: true,
													stateOptionsMap: dropdown?.state_id_map
												}"
												[options]="filtered_status"
												(setStatus)="onClosureStatusChange($event)"
												[value]="refData.basic_info?.status?.id"
											></app-status-picker>
										</div>
										<div
											*ngIf="editorId == 'Resolution'"
											class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6"
										>
											<label for="txtInciResolutionDate"
												>{{ "UI.k_resolution_date" | translate }}
												<span class="required-field">&nbsp;*</span>
											</label>
											<div>
												<ng2-flatpickr
													[config]="dateTimeOptions"
													name="txtInciResolutionDate"
													[setDate]="
														refData?.inci_resolution_time
															? refData?.inci_resolution_time
															: (refData['inci_resolution_time'] =
																	dateTimeOptions?.defaultDate)
													"
													id="txtInciResolutionDate"
													[attr.data-testid]="'txtInciResolutionDate'"
													[(ngModel)]="refData.inci_resolution_time"
													placeholder="{{ 'UI.k_resolution_date' | translate }}"
													class="input my-class"
													#TDInciResolveDateRef="ngModel"
													required
												></ng2-flatpickr>
												<div
													*ngIf="cmtForm.submitted && TDInciResolveDateRef.invalid"
													class="invalid-form"
												>
													<small
														class="form-text text-danger xs-font"
														*ngIf="TDInciResolveDateRef?.errors?.required"
														>{{ "UI.k_field_required" | translate }}
													</small>
												</div>
											</div>
										</div>
										<div class="col-12">
											<app-dns-custom-field
												*ngIf="editorId == 'Resolution'"
												placeholder="resolution"
												[formRef]="cmtForm"
												class=""
												[data]="custom_field_data"
												[config]="custom_field_config"
											></app-dns-custom-field>
										</div>
									</div>

									<div
										*ngIf="
											(ref_data_copy?.basic_info?.state?.id == 4 ||
												(ref_data_copy?.basic_info?.state?.id == 5 &&
													ref_data_copy?.is_exit == false &&
													disable_config == false)) &&
											editorId == 'Closure Note' &&
											permissions?.edit
										"
										class="row"
									>
										<div class="col-xs-12 col-md-6 col-xl-3 col-lg-3">
											<div class="form-group">
												<label for="txtInciClosedBy">{{ "UI.k_closedby" | translate }} </label>
												<ng-select
													[items]="users"
													[(ngModel)]="refData.close_info.closed_by"
													bindLabel="full_name"
													bindValue="profile_id"
													name="txtInciClosedBy"
													id="txtInciClosedBy"
													placeholder="{{ 'UI.k_select_users' | translate }}"
													[hideSelected]="true"
												>
													<ng-template
														ng-option-tmp
														let-item="item"
														let-index="index"
														let-search="searchTerm"
													>
														<span class="row" style="padding-left: 1rem">
															<div
																class="avatar mr-1 ml-0"
																[ngStyle]="{
																	'background-color': item.avatar_color
																		? item.avatar_color
																		: '#f59518'
																}"
																style="align-content: center; width: 32px; height: 32px"
															>
																<div class="avatar-content">
																	{{ item.full_name | initials | slice: O:2 }}
																</div>
															</div>
															<div class="cell-line-height">
																<p
																	class="font-medium-1 font-weight-bold line-height-1 mb-25"
																>
																	{{ item?.full_name | slice: 0:28 }}
																	{{ item?.full_name?.length > 28 ? ".." : "" }}
																</p>
																<span class="text-muted font-small-2">
																	{{ item?.email | slice: 0:28 }}
																	{{ item?.email?.length > 28 ? ".." : "" }}
																</span>
															</div>
														</span>
													</ng-template>
												</ng-select>
											</div>
										</div>
										<div class="col-xs-12 col-md-6 col-xl-3 col-lg-3">
											<div class="form-group">
												<label for="txtInciCloseType"
													>{{ "UI.k_close_type" | translate }}
												</label>
												<ng-select
													[items]="close_type"
													[hideSelected]="true"
													bindLabel="name"
													bindValue="id"
													(change)="
														updateFieldName(
															refData.basic_info.closed_type.id,
															'id',
															'closed_type',
															refData.basic_info.closed_type.name,
															'name'
														)
													"
													[(ngModel)]="refData.close_info.closed_type.id"
													name="txtInciCloseType"
													id="txtInciCloseType"
													placeholder="{{ 'UI.k_sel_close_type' | translate }}"
												>
												</ng-select>
											</div>
										</div>
										<div class="col-xs-12 col-md-6 col-xl-3 col-lg-3">
											<div class="form-group">
												<label for="txtIncidentActlClsrDate"
													>{{ "UI.k_actual_closure_date" | translate }}
												</label>
												<ng2-flatpickr
													[config]="dateTimeOptions"
													name="txtIncidentActlClsrDate"
													[setDate]="
														ref_data_copy?.actual_closure_date
															? refData?.actual_closure_date
															: (refData['actual_closure_date'] =
																	dateTimeOptions?.defaultDate)
													"
													id="txtIncidentActlClsrDate"
													[attr.data-testid]="'txtIncidentActlClsrDate'"
													placeholder="{{ 'UI.k_sel_date' | translate }}"
													[(ngModel)]="refData.actual_closure_date"
												></ng2-flatpickr>
											</div>
										</div>
										<div class="col-xs-12 col-md-6 col-xl-3 col-lg-3">
											<div class="form-group">
												<label for="txtInciclsrAgrdTime"
													>{{ "UI.k_agreed_closure_date" | translate }}
												</label>
												<ng2-flatpickr
													[config]="agreedDateTimeOptions"
													name="txtInciclsrAgrdTime"
													[setDate]="refData.agreed_closure_date"
													id="txtInciclsrAgrdTime"
													[attr.data-testid]="'txtInciclsrAgrdTime'"
													placeholder="{{ 'UI.k_sel_date' | translate }}"
													[(ngModel)]="refData.agreed_closure_date"
												></ng2-flatpickr>
											</div>
										</div>
									</div>
									<ng-container
										*ngIf="refData?.basic_info?.status?.is_pir && editorId == 'Closure Note'"
									>
										<div class="col-xs-12 col-md-12 col-lg-6 col-xl-6">
											<div class="form-group">
												<label>{{ "UI.k_review_team" | translate }}</label>
												<ng-select
													[items]="dropdown?.review_team"
													name="selTeam"
													id="selTeam"
													#selTeam="ngModel"
													[clearable]="false"
													[placeholder]="'UI.k_sel_team' | translate"
													[bindLabel]="'name'"
													[(ngModel)]="refData.review_team"
												></ng-select>
												<dns-error-validation
													[formRef]="cmtForm"
													[uiRef]="selTeam"
												></dns-error-validation>

												<span class="text-muted">{{
													"UI.k_note_team_member" | translate
												}}</span>
											</div>
										</div>
									</ng-container>
									<div class="col-12 p-0">
										<app-dns-custom-field
											*ngIf="editorId == 'Closure Note'"
											placeholder="closure"
											[formRef]="cmtForm"
											[data]="custom_field_data"
											[config]="custom_field_config"
										></app-dns-custom-field>
									</div>
									<div class="col-auto d-flex justify-content-start align-items-center pull-right">
										<button
											type="reset"
											id="btnCommunicationCancel"
											[attr.data-testid]="'btnCommunicationCancel'"
											class="btn btn-sm btn-flat-primary mr-half ml-1 input-sm"
											(click)="onCancelButtonClick()"
										>
											{{ "UI.k_cancel" | translate }}
										</button>
										<div class="d-inline-flex dropup" ngbDropdown>
											<button
												type="button"
												class="btn btn-primary btn-sm d-flex input-sm"
												type="submit"
												*ngIf="
													editorId != 'Closure Note' &&
													editorId != 'Resolution' &&
													editorId != 'Approval' &&
													editorId != 'Review'
												"
												[disabled]="disableCommentSubmit"
												id="btnSaveResolve"
												[attr.data-testid]="'btnSave_Resolve'"
												(click)="closeEditor(editorId, cmtForm)"
												rippleEffect
											>
												<b>&nbsp;{{ "UI.k_save" | translate }}</b>
											</button>
											<button
												type="button"
												class="btn btn-primary btn-sm d-flex input-sm"
												type="submit"
												*ngIf="editorId == 'Approval'"
												[disabled]="disableCommentSubmit"
												id="btnSaveAccept"
												[attr.data-testid]="'btnSaveAccept'"
												(click)="is_accept_button = true; closeEditor(editorId, cmtForm)"
												rippleEffect
											>
												<b>&nbsp;{{ "UI.k_accept" | translate }}</b>
											</button>
											<button
												type="button"
												class="btn btn-primary btn-sm d-flex input-sm ml-1"
												type="submit"
												*ngIf="editorId == 'Approval'"
												[disabled]="disableCommentSubmit"
												id="btnSaveReject"
												[attr.data-testid]="'btnSaveReject'"
												(click)="is_accept_button = false; closeEditor(editorId, cmtForm)"
												rippleEffect
											>
												<b>&nbsp;{{ "UI.k_reject" | translate }}</b>
											</button>
											<button
												class="btn btn-primary btn-sm d-flex input-sm"
												type="submit"
												*ngIf="editorId == 'Review'"
												[disabled]="disableCommentSubmit"
												id="btnSaveReviewAccept"
												(click)="is_accept_button = true; closeEditor(editorId, cmtForm)"
												rippleEffect
												[attr.data-testid]="'btnSaveReviewAccept'"
											>
												<b
													>&nbsp;{{
														(refData?.basic_info?.status?.is_pir
															? "UI.k_submit"
															: "UI.k_approve"
														) | translate
													}}</b
												>
											</button>
											<button
												class="btn btn-primary btn-sm d-flex input-sm ml-1"
												type="submit"
												*ngIf="editorId == 'Review' && !refData?.basic_info?.status?.is_pir"
												[disabled]="disableCommentSubmit"
												id="btnSaveReviewReject"
												(click)="is_accept_button = false; closeEditor(editorId, cmtForm)"
												rippleEffect
												[attr.data-testid]="'btnSaveReviewReject'"
											>
												<b>&nbsp;{{ "UI.k_ask_for_change" | translate }}</b>
											</button>
											<button
												type="button"
												class="btn btn-primary btn-sm d-flex input-sm"
												type="submit"
												*ngIf="editorId == 'Closure Note'"
												id="btnSaveResolve"
												(click)="closeEditor(editorId, cmtForm)"
												[attr.data-testid]="'btnComplete'"
												[disabled]="disableCommentSubmit"
												rippleEffect
											>
												<b>&nbsp;{{ "UI.k_complete" | translate }}</b>
											</button>
											<button
												type="button"
												class="btn btn-primary btn-sm d-flex input-sm"
												type="submit"
												*ngIf="editorId == 'Resolution'"
												id="btnSaveResolve"
												(click)="closeEditor(editorId, cmtForm)"
												[attr.data-testid]="'btnResolve'"
												[disabled]="disableCommentSubmit"
												rippleEffect
											>
												<b>&nbsp;{{ "UI.k_resolve" | translate }}</b>
											</button>
										</div>
									</div>
								</div>
							</div>
							<!-- mail component  -->
							<div class="email-application" *ngIf="isEmailCompOpen" #scrolltoemailwindow>
								<div class="">
									<div id="compose-mail" [attr.data-testid]="'compose-mail'" class="">
										<div class="">
											<form class="compose-form" #mailReplyForm="ngForm">
												<div class="mail-header p-1">
													<div class="select2-primary">
														<div
															class="compose-mail-form-field mail-subject align-items-center mb-0 d-flex gap-50 form-group p-0 border-0"
														>
															<!-- <label for="emailSub" class="form-label sm-font c-fs-12"
																>{{ "UI.k_subject" | translate }}:
															</label> -->
															<input
																type="text"
																id="emailSub"
																[attr.data-testid]="'emailSub'"
																name="emailSub"
																#TDEmailSub="ngModel"
																class="form-control pl-0 fw-500"
																placeholder="{{ 'UI.k_subject' | translate }}"
																[(ngModel)]="emailContent.subject"
																minlength="3"
																maxlength="1000"
																[class.error]="
																	!TDEmailSub.valid && mailReplyForm.submitted
																"
																[appWhiteSpaceCheck]="emailContent.subject"
																required
															/>
														</div>
														<span
															*ngIf="mailReplyForm.submitted && TDEmailSub.invalid"
															class="invalid-form"
														>
															<small
																class="form-text text-danger xs-font pr-1 pl-2"
																*ngIf="TDEmailSub.errors.required"
																>{{ "UI.k_field_required" | translate }}
															</small>
															<small
																class="form-text text-danger xs-font pr-1 pl-2"
																*ngIf="
																	TDEmailSub.errors.minlength ||
																	TDEmailSub.errors.maxlength
																"
																>{{ "UI.k_subject_name_validation" | translate }}
															</small>
															<small
																class="form-text text-danger xs-font pr-1 pl-2"
																*ngIf="TDEmailSub.errors?.whitespace"
																>{{
																	"UI.k_leading_trailing_space_validation" | translate
																}}</small
															>
														</span>
													</div>
													<div class="select2-primary">
														<div
															class="compose-mail-form-field align-items-center border-0 d-flex mb-0 gap-25"
														>
															<ng-container
																*ngIf="
																	imapInputData?.length > 0 && refData?.imap_config_id
																"
															>
																<label
																	for="txtFromAddress"
																	class="form-label sm-font mr-25 mb-0"
																	>{{ "UI.k_from" | translate }}:
																</label>
																<div class="flex-grow-1 mail-from-address">
																	<ng-container *ngIf="!is_from_disabled">
																		<app-tree-dropdown-with-icon
																			[id]="3"
																			class="tree-view-icon quill-tree-view mr-1"
																			[items]="this.imapInputData"
																			[value]="selectedIMAPValue"
																			(itemChange)="imapChange($event)"
																			[showTemplateEdit]="false"
																			[selectedTreeData]="selectImapText"
																			[showPreviewIcon]="false"
																		>
																		</app-tree-dropdown-with-icon>
																	</ng-container>
																	<ng-container *ngIf="is_from_disabled">
																		<div
																			class="ng-select ng-select-single ng-select-searchable ng-select-clearable ng-untouched ng-pristine ng-valid"
																		>
																			<div class="ng-select-container">
																				<div class="ng-value-container">
																					<div
																						class="subject-value pl-1 pr-1"
																					>
																						{{ imapSelectedText }}
																					</div>
																				</div>
																				<span class="ng-arrow-wrapper"
																					><span class="ng-arrow"></span
																				></span>
																			</div>
																		</div>
																	</ng-container>
																</div>
															</ng-container>
														</div>
													</div>
													<div class="select2-primary">
														<div
															class="compose-mail-form-field align-items-center text-center border-0 d-flex gap-50"
														>
															<label
																for="txtToAddress"
																class="form-label sm-font mr-50 mb-0"
																>{{ "UI.k_to" | translate
																}}<span class="required-field">&nbsp;*&nbsp;</span>:
															</label>
															<div class="flex-grow-1 mail-to-address">
																<div class="form-group">
																	<ng-select
																		[items]="toAddress"
																		[addTag]="addEmailToDropdown"
																		[multiple]="true"
																		bindLabel="email"
																		bindValue="email"
																		name="to_address"
																		id="to_address"
																		[type-to-search]="true"
																		[addTag]="true"
																		[(ngModel)]="emailContent.to_address"
																		placeholder="{{ 'UI.k_email' | translate }}"
																		#TDToAddress="ngModel"
																		[appEmailCheck]="emailContent.to_address"
																		[loading]="toSearchFalse"
																		(keyup)="searchToRequester($event)"
																		[class.error]="
																			TDToAddress.invalid &&
																			mailReplyForm.submitted
																		"
																		required
																	>
																		<ng-template
																			ng-label-tmp
																			let-item="item"
																			let-clear="clear"
																		>
																			<span class="ng-value-label">
																				<span
																					class="row px-1 d-flex justify-content-start align-items-center"
																				>
																					<div *ngIf="item?.profile_image">
																						<div
																							class="avatar avatar-sm mr-1 ml-0"
																						>
																							<span
																								class="avatar-content"
																							>
																								<img
																									class="rounded-circle"
																									src="{{
																										item?.profile_image
																									}}"
																									alt="User"
																									width="32"
																									height="32"
																								/>
																							</span>
																						</div>
																					</div>
																					<div
																						*ngIf="
																							!item?.profile_image && item
																						"
																					>
																						<div
																							class="avatar avatar-sm mr-1 ml-0"
																							[ngStyle]="{
																								'background-color':
																									item?.avatar_color
																										? item.avatar_color
																										: '#f59518'
																							}"
																							style="
																								align-content: center;
																							"
																						>
																							<div class="avatar-content">
																								{{
																									item.email
																										| initials
																								}}
																							</div>
																						</div>
																					</div>
																					<div class="">
																						<span
																							class="fw-500 sm-font text-dark"
																						>
																							{{ item.email }}
																						</span>
																					</div>
																				</span>
																			</span>
																			<span
																				class="ng-value-icon right"
																				(click)="clear(item)"
																				aria-hidden="true"
																				>×</span
																			>
																		</ng-template>

																		<ng-template ng-option-tmp let-item="item">
																			<span
																				class="row px-1 d-flex justify-content-start align-items-center"
																			>
																				<div *ngIf="item?.profile_image">
																					<div
																						class="avatar avatar-sm mr-1 ml-0"
																					>
																						<span class="avatar-content">
																							<img
																								class="rounded-circle"
																								src="{{
																									item?.profile_image
																								}}"
																								alt="User"
																								width="32"
																								height="32"
																							/>
																						</span>
																					</div>
																				</div>

																				<div *ngIf="!item?.profile_image">
																					<div
																						class="avatar avatar-sm mr-1 ml-0"
																						[ngStyle]="{
																							'background-color':
																								item?.avatar_color
																									? item.avatar_color
																									: '#f59518'
																						}"
																						style="align-content: center"
																					>
																						<div class="avatar-content">
																							{{
																								item.full_name
																									| initials
																									| slice: 0:2
																							}}
																						</div>
																					</div>
																				</div>
																				<div
																					class="cell-line-height text-justify"
																				>
																					<span
																						class="fw-500 text-nowrap sm-font"
																						id="UserFullname"
																						>{{
																							item?.full_name
																								? item?.full_name
																								: item?.email
																						}}</span
																					>
																					<span
																						class="text-muted d-block font-small-2"
																						>{{ item?.email }}</span
																					>
																				</div>
																			</span>
																		</ng-template>
																	</ng-select>
																	<span
																		class="invalid-form"
																		*ngIf="
																			mailReplyForm.submitted &&
																			TDToAddress.invalid
																		"
																	>
																		<small
																			class="form-text text-danger xs-font pr-1 pl-2"
																			*ngIf="TDToAddress.errors?.invalidemail"
																			>{{ "UI.k_email_validation" | translate }}
																		</small>
																		<small
																			class="form-text text-danger xs-font pr-1 pl-2"
																			*ngIf="TDToAddress.errors?.max_email"
																			>{{
																				"UI.k_email_max_validation"
																					| translate
																						: {
																								maxLimit:
																									TDToAddress.errors
																										?.limit
																						  }
																			}}
																		</small>
																		<small
																			class="form-text text-danger xs-font pr-1 pl-2"
																			*ngIf="TDToAddress.errors.required"
																			>{{ "UI.k_field_required" | translate }}
																		</small>
																	</span>
																</div>
															</div>
															<div>
																<a
																	class="toggle-cc text-body reg-font"
																	id="btnIncEditCc"
																	[attr.data-testid]="'btnIncEditCc'"
																	(click)="togglCcBcc('cc')"
																	*ngIf="!isOpenCC"
																	>Cc</a
																>
																<!-- <a class="toggle-bcc ml-2 text-body" id="btnIncEditBcc"
																			(click)="togglCcBcc('bcc')" *ngIf="!isOpenBCC">Bcc</a> -->
															</div>
														</div>
													</div>
													<div class="select2-primary my-50" *ngIf="isOpenCC == true">
														<div
															class="compose-mail-form-field text-center border-0 d-flex gap-50 cc-wrapper"
														>
															<label for="txtCcAddress" class="form-label sm-font"
																>CC :
															</label>
															<div class="flex-grow-1 ml-1 mail-cc">
																<div class="form-group">
																	<ng-select
																		[items]="ccAddress"
																		[multiple]="true"
																		[addTag]="addEmailToDropdown"
																		bindLabel="full_name"
																		bindValue="email"
																		name="cc_address"
																		id="cc_address"
																		[(ngModel)]="emailContent.cc_address"
																		[attr.data-testid]="'cc_address'"
																		placeholder="{{ 'UI.k_email' | translate }}"
																		[loading]="ccSearchFalse"
																		(keyup)="searchCCRequester($event)"
																		#TDCcAddress="ngModel"
																		[appEmailCheck]="emailContent.cc_address"
																	>
																		<ng-template
																			ng-label-tmp
																			let-item="item"
																			let-clear="clear"
																		>
																			<span class="ng-value-label">
																				<span
																					class="row px-1 d-flex justify-content-start align-items-center"
																				>
																					<div *ngIf="item?.profile_image">
																						<div
																							class="avatar avatar-sm mr-1 ml-0"
																						>
																							<span
																								class="avatar-content"
																							>
																								<img
																									class="rounded-circle"
																									src="{{
																										item?.profile_image
																									}}"
																									alt="User"
																									width="32"
																									height="32"
																								/>
																							</span>
																						</div>
																					</div>

																					<div *ngIf="!item?.profile_image">
																						<div
																							class="avatar avatar-sm mr-1 ml-0"
																							[ngStyle]="{
																								'background-color':
																									item?.avatar_color
																										? item.avatar_color
																										: '#f59518'
																							}"
																							style="
																								align-content: center;
																							"
																						>
																							<div class="avatar-content">
																								{{
																									item.email
																										| initials
																								}}
																							</div>
																						</div>
																					</div>
																					<div class="">
																						<span
																							class="fw-500 sm-font text-dark"
																						>
																							{{ item?.email }}
																						</span>
																					</div>
																				</span>
																			</span>
																			<span
																				class="ng-value-icon right"
																				(click)="clear(item)"
																				aria-hidden="true"
																				>×</span
																			>
																		</ng-template>

																		<ng-template ng-option-tmp let-item="item">
																			<span
																				class="row px-1 d-flex justify-content-start align-items-center"
																			>
																				<div *ngIf="item?.profile_image">
																					<div
																						class="avatar avatar-sm mr-1 ml-0"
																					>
																						<span class="avatar-content">
																							<img
																								class="rounded-circle"
																								src="{{
																									item?.profile_image
																								}}"
																								alt="User"
																								width="32"
																								height="32"
																							/>
																						</span>
																					</div>
																				</div>

																				<div *ngIf="!item?.profile_image">
																					<div
																						class="avatar avatar-sm mr-1 ml-0"
																						[ngStyle]="{
																							'background-color':
																								item?.avatar_color
																									? item.avatar_color
																									: '#f59518'
																						}"
																						style="align-content: center"
																					>
																						<div class="avatar-content">
																							{{
																								item.full_name
																									| initials
																									| slice: 0:2
																							}}
																						</div>
																					</div>
																				</div>
																				<div
																					class="cell-line-height text-justify"
																				>
																					<span
																						class="fw-500 text-nowrap sm-font"
																						id="UserFullname"
																						[attr.data-testid]="
																							'UserFullname'
																						"
																						>{{
																							item?.full_name
																								? item?.full_name
																								: item?.email
																						}}</span
																					>
																					<span
																						class="text-muted d-block font-small-2"
																						>{{ item?.email }}</span
																					>
																				</div>
																			</span>
																		</ng-template>
																	</ng-select>
																</div>
															</div>
															<a
																class="text-body toggle-cc"
																id="btnToggleCC"
																[attr.data-testid]="'btnToggleCC'"
																(click)="togglCcBcc('cc')"
																><i class="fa-light fa-xmark"></i
															></a>
														</div>
														<span
															class="row col-12"
															*ngIf="mailReplyForm.submitted && isOpenCC === true"
															class="invalid-form"
															><small
																class="form-text text-danger xs-font pr-1 pl-2"
																*ngIf="TDCcAddress.errors?.invalidemail"
																>{{ "UI.k_email_validation" | translate }}</small
															>
														</span>
													</div>
													<div class="select2-primary" *ngIf="isOpenBCC == true">
														<div
															class="compose-mail-form-field align-items-center text-center bcc-wrapper border-0"
														>
															<label
																for="txtBccAddress"
																class="form-label w-27 xs-font c-fs-12"
																>{{ "UI.k_bcc" | translate }}:
															</label>
															<div class="flex-grow-1 c-input-group">
																<div class="form-group">
																	<ng-select
																		[items]="requesters"
																		[multiple]="true"
																		[addTag]="true"
																		bindLabel="full_name"
																		bindValue="email"
																		name="bcc_address"
																		id="bcc_address"
																		[attr.data-testid]="'bcc_address'"
																		[(ngModel)]="emailContent.bcc_address"
																		placeholder="{{ 'UI.k_email' | translate }}"
																		#TDBccAddress="ngModel"
																		[appEmailCheck]="emailContent.bcc_address"
																		[class.error]="
																			TDBccAddress.invalid &&
																			mailReplyForm.submitted
																		"
																	>
																		<ng-template
																			ng-label-tmp
																			let-item="item"
																			let-clear="clear"
																		>
																			<span class="ng-value-label">
																				<span
																					class="row px-1 d-flex justify-content-start align-items-center"
																				>
																					<div *ngIf="item?.profile_image">
																						<div
																							class="avatar avatar-sm mr-1 ml-0"
																						>
																							<span
																								class="avatar-content"
																							>
																								<img
																									class="rounded-circle"
																									src="{{
																										item?.profile_image
																									}}"
																									alt="User"
																									width="32"
																									height="32"
																								/>
																							</span>
																						</div>
																					</div>

																					<div *ngIf="!item?.profile_image">
																						<div
																							class="avatar avatar-sm mr-1 ml-0"
																							[ngStyle]="{
																								'background-color':
																									item?.avatar_color
																										? item.avatar_color
																										: '#f59518'
																							}"
																							style="
																								align-content: center;
																							"
																						>
																							<div class="avatar-content">
																								{{
																									item.email
																										| initials
																								}}
																							</div>
																						</div>
																					</div>
																					<div class="">
																						<span class="fw-500 sm-font">
																							{{ item?.email }}
																						</span>
																					</div>
																				</span>
																			</span>
																			<span
																				class="ng-value-icon right"
																				(click)="clear(item)"
																				aria-hidden="true"
																				>×</span
																			>
																		</ng-template>

																		<ng-template ng-option-tmp let-item="item">
																			<span
																				class="row px-1 d-flex justify-content-start align-items-center"
																			>
																				<div *ngIf="item?.profile_image">
																					<div
																						class="avatar avatar-sm mr-1 ml-0"
																					>
																						<span class="avatar-content">
																							<img
																								class="rounded-circle"
																								src="{{
																									item?.profile_image
																								}}"
																								alt="User"
																								width="32"
																								height="32"
																							/>
																						</span>
																					</div>
																				</div>

																				<div *ngIf="!item?.profile_image">
																					<div
																						class="avatar avatar-sm mr-1 ml-0"
																						[ngStyle]="{
																							'background-color':
																								item?.avatar_color
																									? item.avatar_color
																									: '#f59518'
																						}"
																						style="align-content: center"
																					>
																						<div class="avatar-content">
																							{{
																								item.full_name
																									| initials
																									| slice: 0:2
																							}}
																						</div>
																					</div>
																				</div>
																				<div class="cell-line-height">
																					<span
																						class="fw-500 text-nowrap sm-font"
																						id="UserFullname"
																						[attr.data-testid]="
																							'UserFullname'
																						"
																						>{{
																							item?.full_name
																								? item?.full_name
																								: item?.email
																						}}</span
																					>
																					<span
																						class="text-muted d-block font-small-2"
																						>{{ item?.email }}</span
																					>
																				</div>
																			</span>
																		</ng-template>
																	</ng-select>
																</div>
															</div>
															<a
																class="text-body toggle-bcc"
																href="javascript:void(0);"
																(click)="togglCcBcc('bcc')"
															>
																<!-- <i [data-feather]="'x'"></i> -->
																<i class="fa-light fa-xmark"></i>
															</a>
														</div>
														<span
															class="row col-12"
															*ngIf="mailReplyForm.submitted && isOpenBCC === true"
															class="invalid-form"
															><small
																class="form-text text-danger xs-font pr-1 pl-2"
																*ngIf="TDBccAddress.errors?.invalidemail"
																>{{ "UI.k_email_validation" | translate }}</small
															>
														</span>
													</div>
												</div>
												<div class="form-group email-quill-editor m-0">
													<quill-editor
														[(ngModel)]="emailContent.reply_mail"
														[ngModelOptions]="{
															standalone: true
														}"
														class="content-editor"
														(onFocus)="focus($event)"
														(onBlur)="blur($event)"
														(onSelectionChanged)="onSelectionChange($event)"
														(onContentChanged)="getEmailContent($event)"
														(onEditorCreated)="created($event)"
														id="txtAreaEmail"
														[attr.data-testid]="'txtAreaEmail'"
													>
														<div quill-editor-toolbar class="row pb-0">
															<div class="col-6">
																<span class="ql-formats">
																	<button
																		type="button"
																		class="ql-bold"
																		[title]="'Bold'"
																	></button>
																	<button
																		class="ql-italic"
																		[title]="'italic'"
																	></button>
																	<button
																		class="ql-underline"
																		[title]="'underline'"
																	></button>
																</span>
																<span class="ql-formats">
																	<select class="ql-align" [title]="'Alignment'">
																		<option selected></option>
																		<option value="center"></option>
																		<option value="right"></option>
																		<option value="justify"></option>
																	</select>
																</span>
																<span class="ql-formats">
																	<button
																		class="ql-list"
																		value="ordered"
																		type="button"
																	></button>
																</span>
																<span class="ql-formats">
																	<button
																		class="ql-link"
																		value="ordered"
																		type="button"
																	></button>
																	<button
																		class="ql-image"
																		value="ordered"
																		type="button"
																	></button>
																	<button
																		class="ql-video"
																		value="ordered"
																		type="button"
																	></button>
																</span>
																<span class="ql-formats">
																	<button
																		class="ql-code-block"
																		value="ordered"
																		type="button"
																	></button>
																</span>
																<span class="ql-formats">
																	<button
																		class="ql-clean"
																		value="ordered"
																		type="button"
																	></button>
																</span>
															</div>
															<div
																class="text-enhancement mt-25 cursor-pointer"
																[ngbPopover]="popContent"
																id="txt-enhancement"
																[attr.data-testid]="'txt-enhancement'"
																[autoClose]="false"
																popoverClass="textenhancementpopover bg-body"
																[placement]="['left-bottom', 'left']"
																container="body"
																triggers="manual"
																#popOver="ngbPopover"
																(click)="openPopover()"
																(mouseover)="isTooltipShow = false"
															>
																<button class="action-button">
																	<img
																		src="assets/images/workspace/infi-logo.svg"
																		alt=""
																	/>
																	<span
																		class="sm-font fw-400 line-height-1 ml-25 title-color"
																		>{{ "UI.k_text_enhancement" | translate }}
																	</span>
																</button>
															</div>
															<ng-template #popContent>
																<div class="text-enhancement-model">
																	<div class="text-enhancement-header primary-bg">
																		<div class="header-title p-75 white-color">
																			<img
																				src="assets/images/workspace/infi-logo-squre.svg"
																				alt=""
																			/>
																			<span class="line-height-1 ml-25 fw-500">{{
																				"UI.k_infiy_text_enhancement"
																					| translate
																			}}</span>
																		</div>
																		<div class="header-action d-flex p-75">
																			<button
																				class="btn btn-reg insert btn-icon waves-effect"
																				id="txt-insert"
																				(click)="closeModel()"
																				[attr.data-testid]="'txt-insert'"
																			>
																				<i class="far fa-times text-white"></i>
																			</button>
																		</div>
																	</div>
																	<div class="text-enhancement-body">
																		<div
																			class="no-data-model"
																			*ngIf="rephrase_error"
																		>
																			<div class="no-data-container p-75 border">
																				<div class="contain-header">
																					<i
																						class="fas fa-exclamation-triangle"
																					></i
																					><span
																						class="text-title fw-700 ml-25"
																						>{{
																							"Error.err_something_went_wrong"
																								| translate
																						}}</span
																					>
																				</div>
																				<div class="contain-body sm-font">
																					{{
																						"Message.msg_rephrase_try_error"
																							| translate
																					}}
																					{{
																						"Message.msg_try_again"
																							| translate
																					}}
																				</div>
																				<div class="content-action mt-50">
																					<button
																						class="btn-xs border reg-font fw-600"
																						[attr.data-testid]="
																							'content-action'
																						"
																						(click)="resetRephraseError()"
																					>
																						{{ "UI.k_ok" | translate }}
																					</button>
																				</div>
																			</div>
																		</div>
																		<ng-container *ngIf="!rephrase_error">
																			<div
																				class="tone-body d-flex p-1 align-items-center border-bottom"
																			>
																				<div
																					class="sm-font title-color fw-600 tone-container"
																				>
																					<!-- {{'UI.k_select_option' | translate}} -->
																					<ng-select
																						required
																						bindLabel="title"
																						class="form-control"
																						name="selToneOptions"
																						id="selToneOptions"
																						placeholder="{{
																							'UI.k_select_Tone'
																								| translate
																						}}"
																						[(ngModel)]="toneData"
																						(change)="
																							onToneSelection(i, toneData)
																						"
																						#selToneInput="ngModel"
																						[items]="toneItem"
																					></ng-select>
																				</div>
																				<div
																					class="d-flex align-items-center ml-50 gap-75 tone-option"
																					*ngFor="
																						let tone of toneOptionData;
																						let i = index
																					"
																				>
																					<button
																						class="sm-font p-25 border rounded cursor-pointer"
																						id="{{ tone.title }}"
																						attr.data-testid="btn_{{
																							tone.title
																						}}"
																						(click)="
																							onOptionSelection(i, tone)
																						"
																						[ngClass]="{
																							activeToneButton:
																								activeButtonIndex === i,
																							disabledToneButton:
																								!selectedText,
																							'tone-action': selectedText
																						}"
																						[disabled]="!selectedText"
																					>
																						<i
																							class="fa-light {{
																								tone.icon
																							}} mr-25"
																						></i>
																						{{ tone.title }}
																					</button>
																				</div>
																			</div>
																		</ng-container>

																		<div
																			class="tone-description mx-1 mt-1 border title-color"
																			*ngIf="!rephrase_error"
																		>
																			<quill-editor
																				[placeholder]="
																					'UI.k_infiy_text_placeholder'
																						| translate
																				"
																				[(ngModel)]="selectedText"
																				[ngModelOptions]="{
																					standalone: true
																				}"
																				class="content-editor"
																				(onFocus)="focus($event)"
																				(onBlur)="blur($event)"
																				id="mail-tone-text-description"
																			>
																				<div
																					quill-editor-toolbar
																					class="row pb-0 d-none"
																				></div>
																			</quill-editor>
																		</div>
																		<div
																			class="tone-description m-1 border title-color"
																			*ngIf="!rephraseDisable && !rephrase_error"
																		>
																			<quill-editor
																				[placeholder]="false"
																				[(ngModel)]="rephrasedText"
																				[ngModelOptions]="{
																					standalone: true
																				}"
																				class="content-editor"
																				(onFocus)="focus($event)"
																				(onBlur)="blur($event)"
																				id="mail-tone-rephrase-description"
																			>
																				<div
																					quill-editor-toolbar
																					class="row pb-0 d-none"
																				></div>
																			</quill-editor>
																		</div>
																		<div
																			class="loader-tone border-top title-color d-flex justify-content-center align-items-center"
																			*ngIf="rephraseDisable"
																		>
																			<div
																				class="spinner-border text-primary"
																				aria-hidden="true"
																			></div>
																		</div>
																	</div>
																	<div class="text-enhancement-footer border-top">
																		<div
																			class="footer-action d-flex align-items-center justify-content-end p-75"
																		>
																			<button
																				class="btn btn-primary white-color waves-effect insert"
																				id="txt-insert"
																				(click)="handleRephrase('mail')"
																				[disabled]="
																					!toneOptionType ||
																					(toneOptionType && rephraseDisable)
																				"
																				[attr.data-testid]="'txt-insert'"
																			>
																				{{ "UI.k_insert" | translate }}
																			</button>
																		</div>
																	</div>
																</div>
															</ng-template>

															<div class="col-3">
																<app-tree-dropdown-with-icon
																	[id]="2"
																	class="tree-view-icon quill-tree-view"
																	[items]="this.signatureInputData"
																	[value]="selectedSignatureValue"
																	(itemChange)="signatureChange($event)"
																	(showTemplateModal)="showSignatureModal($event)"
																	[showTemplateEdit]="true"
																	[headingText]="signatureTreeHeading"
																	[selectedTreeData]="selectSignatureText"
																	(editTemplate)="editTemplate($event)"
																>
																</app-tree-dropdown-with-icon>
															</div>
														</div>
													</quill-editor>
												</div>

												<div class="wrap-marker p-1" *ngIf="!showQuotedTxt && !removeQuotedTxt">
													<span
														class="q-marker m-0"
														tooltipClass="fadeInAnimation"
														ngbTooltip="{{ 'UI.k_show_quoted' | translate }}"
														id="CommunicationShowText"
														[attr.data-testid]="'CommunicationShowText'"
														(click)="showQuotedText()"
													>
														<i class="fa-light fa-ellipsis avatar-icon sm-font"></i>
													</span>
													<span
														class="quote-remove"
														tooltipClass="fadeInAnimation"
														ngbTooltip="Close Preview"
														id="CommunicationRemoveText"
														[attr.data-testid]="'CommunicationRemoveText'"
														(click)="removeQuotedText()"
													>
														<i class="fa-light fa-xmark avatar-icon sm-font"></i>
													</span>
												</div>
												<div class="pl-2 pr-2 row">
													<span
														*ngFor="let file of filesList"
														class="text-primary sm-font attach-file-border m-half"
														><i class="fa-light fa-file-lines"></i>&nbsp;<b>{{
															file.file_name
														}}</b
														>&nbsp;
														<span
															(click)="removeAttachedFile($index, filesList)"
															id="btnFileAttachmentClose"
															[attr.data-testid]="'btnFileAttachmentClose'"
														>
															<i class="fa-light fa-xmark"></i>
														</span>
													</span>
												</div>

												<div
													class="compose-footer-wrapper d-flex email-footer pt-50 p-1 justify-content-between"
												>
													<div class="btn-wrapper mr-1 d-flex align-items-center">
														<div
															class="email-attachement"
															id="email-attachement"
															[attr.data-testid]="'email-attachement'"
														>
															<label
																for="file-input-{{ refId }}"
																class="add_attachment align-items-center d-flex mb-0"
															>
																<img
																	src="assets/images/icons/icon_link.svg"
																	width="12"
																	height="12"
																/>
																<span class="fw-500 text-primary xs-font ml-50"
																	>{{ "UI.k_add" | translate }}
																	{{ "UI.k_attachment" | translate }}
																</span>
															</label>
															<input
																(change)="fileUpload($event)"
																id="file-input-{{ refId }}"
																[attr.data-testid]="'file-input' + refId"
																type="file"
																class="d-none"
															/>
														</div>
													</div>
													<div
														class="footer-action d-flex align-items-center justify-content-between"
													>
														<button
															type="reset"
															id="btnCloseMailComp"
															[attr.data-testid]="'btnCloseMailComp'"
															class="btn cancel-btn mr-1 btn-flat-primary btn-sm"
															(click)="
																closeMailComp();
																showReplyBox = true;
																showEmailTemplate = false;
																notEmptyCommData = true
															"
														>
															{{ "UI.k_cancel" | translate }}
														</button>
														<div ngbDropdown class="btn-group">
															<button
																type="submit"
																id="btnEmail"
																[attr.data-testid]="'btnEmail'"
																class="btn btn-primary btn-sm"
																(click)="
																	saveSentEmail(
																		mailReplyForm,
																		emailContent,
																		filesList
																	)
																"
																rippleEffect
															>
																{{ "UI.k_send" | translate }}
															</button>
															<!-- <button
																ngbDropdownToggle
																type="button"
																class="btn btn-primary dropdown-toggle-split"
																rippleEffect
															>
																<span class="sr-only">{{
																	"UI.k_toggle_dropdown" | translate
																}}</span>
															</button> -->
															<!-- <div ngbDropdownMenu>
																<a ngbDropdownItem href="javascript:void(0)">{{
																	"UI.k_schedule" | translate
																}}</a>
																<a
																	ngbDropdownItem
																	class="active"
																	href="javascript:void(0)"
																	>{{ "UI.k_submit" | translate }}</a
																>
																<a ngbDropdownItem href="javascript:void(0)"
																	>{{ "UI.k_option" | translate }} 3</a
																>
																<div class="dropdown-divider"></div>
																<a ngbDropdownItem href="javascript:void(0)">{{
																	"UI.k_separated_link" | translate
																}}</a>
															</div> -->
														</div>
													</div>
												</div>

												<!-- <div class="mail-footer">
												<div class="btn-wrapper d-flex align-items-center">
													
													<div class="email-attachement pl-1">
														<label for="file-input" class="add_attachment c-fs-12">
															<img
																src="assets/images/icons/icon_link.svg"
																width="18"
																height="18"
															/>
															<span class="fw-500 text-primary ml-50 align-middle"
																>{{ "UI.k_add" | translate }}
																{{ "UI.k_attachment" | translate }}
															</span>
														</label>
														<input
															(change)="fileUpload($event)"
															id="file-input"
															type="file"
															class="d-none"
														/>
													</div>
												</div>
												<div class="footer-action bg-body justify-content-end d-flex border-top">
													<div class=" d-flex align-items-center pull-right p-1">
														<button
														type="reset"
														id="btnCloseMailComp"
														class="btn cancel-btn mr-1 btn-flat-primary"
														(click)="closeMailComp(); showReplyBox = true"
													>
														{{ "UI.k_cancel" | translate }}
													</button>

													<div ngbDropdown class="btn-group">
														<button
															type="submit"
															id="btnEmail"
															class="btn btn-primary btn-sm"
															(click)="
																saveSentEmail(mailReplyForm, emailContent, filesList)
															"
															rippleEffect
														>
															{{ "UI.k_send" | translate }}
														</button>
														<button
															ngbDropdownToggle
															type="button"
															class="btn btn-primary dropdown-toggle-split"
															rippleEffect
														>
															<span class="sr-only">{{
																"UI.k_toggle_dropdown" | translate
															}}</span>
														</button>
														<div ngbDropdownMenu>
															<a ngbDropdownItem href="javascript:void(0)">{{
																"UI.k_schedule" | translate
															}}</a>
															<a
																ngbDropdownItem
																class="active"
																href="javascript:void(0)"
																>{{ "UI.k_submit" | translate }}</a
															>
															<a ngbDropdownItem href="javascript:void(0)"
																>{{ "UI.k_option" | translate }} 3</a
															>
															<div class="dropdown-divider"></div>
															<a ngbDropdownItem href="javascript:void(0)">{{
																"UI.k_separated_link" | translate
															}}</a>
														</div>
													</div>
													</div>
												</div>
											</div> -->
											</form>
										</div>
									</div>
								</div>
							</div>
							<!-- mail component ends here -->
						</div>
					</form>
				</div>
				<div
					class="d-flex justify-content-center align-items-center py-1"
					*ngIf="copyOfAllCard?.length < commDataTotalCount && notEmptyCommData"
				>
					<button
						class="btn-sm btn-outline-primary d-flex align-items-center justify-content-between"
						type="button"
						(click)="loadNextComments()"
					>
						{{ "UI.k_load_more" | translate }}
					</button>
				</div>
			</div>
		</ng-scrollbar>
	</div>
	<div class="p-1 border-top flex-initial" *ngIf="showReplyBox && isRefDataLoad != false">
		<div class="d-flex bd-highlight justify-content-between align-items-center" *ngIf="showReplyBox">
			<div class="pull-left flex-fill bd-highlight d-flex">
				<ng-container
					*ngIf="
						((!this.ref_data_copy?.approval_data?.can_approve &&
							!this.ref_data_copy?.approval_data?.is_submitter) ||
							(!this.ref_data_copy?.review_data?.is_review_submitter &&
								this.ref_data_copy?.review_data?.can_review)) &&
						![6, 7].includes(refData?.basic_info?.state?.id)
					"
				>
					<ng-container
						*ngIf="
							communicationFilterSelection.keyName === 'All' ||
							communicationFilterSelection.keyName === 'Email' ||
							communicationFilterSelection.keyName === 'SMS'
						"
					>
						<ng-container *ngFor="let item of selectedActions; let i = index">
							<ng-container
								*ngIf="
									communicationRenderData.actions[item].isDisplay(
										communicationRenderData.actions[item].keyName
									) &&
									communicationRenderData.actions[item].lisenceKey &&
									i < 4
								"
							>
								<button
									*appDnsLicenseHandler="communicationRenderData.actions[item].lisenceKey"
									id="btn{{ communicationRenderData.actions[item].keyName }}"
									attr.data-testid="btn{{ communicationRenderData.actions[item].keyName }}"
									[name]="communicationRenderData.actions[item].name"
									(click)="communicationRenderData.actions[item].callBack(latestEmail)"
									type="button"
									class="btn btn-sm btn-flat-primary waves-effect"
									rippleEffect
								>
									<i class="{{ communicationRenderData.actions[item].icon }}"></i>
									{{ communicationRenderData.actions[item].displayLabel }}
								</button>
							</ng-container>
							<button
								id="btn{{ communicationRenderData.actions[item].keyName }}"
								attr.data-testid="btn{{ communicationRenderData.actions[item].keyName }}"
								[name]="communicationRenderData.actions[item].name"
								*ngIf="
									communicationRenderData.actions[item].isDisplay(
										communicationRenderData.actions[item].keyName
									) &&
									!communicationRenderData.actions[item].lisenceKey &&
									i < 4
								"
								(click)="communicationRenderData.actions[item].callBack(latestEmail)"
								type="button"
								class="btn btn-sm btn-flat-primary waves-effect"
								rippleEffect
							>
								<i class="{{ communicationRenderData.actions[item].icon }}"></i>
								{{ communicationRenderData.actions[item].displayLabel }}
							</button>
						</ng-container>
						<ng-container
							*ngIf="
								refData?.basic_info?.status?.state_id !== 5 &&
								!refData.basic_info?.status?.is_exit &&
								!is_ssp_login
							"
						>
							<div
								ngbDropdown
								class="dropdown"
								*ngIf="selectedActions?.length >= 4 && moduleType != 'IMACD' && !disabled_options"
							>
								<button
									ngbDropdownToggle
									class="dropdown-toggle btn btn-sm btn-flat-primary waves-effect"
									type="button"
									id="CommunicationDataAction"
									[attr.data-testid]="'CommunicationDataAction'"
								>
									{{ "UI.k_more" | translate }}
								</button>
								<div ngbDropdownMenu aria-labelledby="dropdownMenuButton" class="dropdown-menu">
									<ng-container *ngFor="let item of selectedActions; let i = index">
										<ng-container
											*ngIf="
												communicationRenderData.actions[item].isDisplay(
													communicationRenderData.actions[item].keyName
												) &&
												communicationRenderData.actions[item].lisenceKey &&
												i >= 4
											"
										>
											<a
												*appDnsLicenseHandler="communicationRenderData.actions[item].lisenceKey"
												ngbDropdownItem
												href="javascript:void(0)"
												id="btn{{ communicationRenderData.actions[item].keyName }}"
												attr.data-testid="btn{{
													communicationRenderData.actions[item].keyName
												}}"
												tooltipclass="fadeInAnimation"
												placement="top"
												[name]="communicationRenderData.actions[item].name"
												class="dropdown-item d-flex gap-1 align-items-center"
												(click)="communicationRenderData.actions[item].callBack(latestEmail)"
												><i class="{{ communicationRenderData.actions[item].icon }}"></i>
												{{ communicationRenderData.actions[item].displayLabel }}
											</a>
										</ng-container>
										<ng-container
											*ngIf="
												communicationRenderData.actions[item].isDisplay(
													communicationRenderData.actions[item].keyName
												) &&
												!communicationRenderData.actions[item].lisenceKey &&
												i >= 4
											"
										>
											<a
												ngbDropdownItem
												href="javascript:void(0)"
												id="btn{{ communicationRenderData.actions[item].keyName }}"
												attr.data-testid="btn{{
													communicationRenderData.actions[item].keyName
												}}"
												tooltipclass="fadeInAnimation"
												placement="top"
												[name]="communicationRenderData.actions[item].name"
												class="dropdown-item d-flex gap-1 align-items-center"
												(click)="communicationRenderData.actions[item].callBack(latestEmail)"
												><i class="{{ communicationRenderData.actions[item].icon }}"></i>
												{{ communicationRenderData.actions[item].displayLabel }}
											</a>
										</ng-container>
									</ng-container>
								</div>
							</div>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="communicationFilterSelection.keyName === 'InternalNote'">
						<ng-container *ngFor="let item of selectedActions; let i = index">
							<ng-container
								*ngIf="
									communicationRenderData.actions[item].isDisplay(
										communicationRenderData.actions[item].keyName
									) &&
									communicationRenderData.actions[item].lisenceKey &&
									i < 1
								"
							>
								<button
									*appDnsLicenseHandler="communicationRenderData.actions[item].lisenceKey"
									id="btn{{ communicationRenderData.actions[item].keyName }}"
									attr.data-testid="btn{{ communicationRenderData.actions[item].keyName }}"
									[name]="communicationRenderData.actions[item].name"
									(click)="communicationRenderData.actions[item].callBack(latestEmail)"
									type="button"
									class="btn btn-sm btn-flat-primary waves-effect"
									rippleEffect
								>
									<i class="{{ communicationRenderData.actions[item].icon }}"></i>
									{{ communicationRenderData.actions[item].displayLabel }}
								</button>
							</ng-container>
							<button
								id="btn{{ communicationRenderData.actions[item].keyName }}"
								attr.data-testid="btn{{ communicationRenderData.actions[item].keyName }}"
								[name]="communicationRenderData.actions[item].name"
								*ngIf="
									communicationRenderData.actions[item].isDisplay(
										communicationRenderData.actions[item].keyName
									) &&
									!communicationRenderData.actions[item].lisenceKey &&
									i < 1
								"
								(click)="communicationRenderData.actions[item].callBack(latestEmail)"
								type="button"
								class="btn btn-sm btn-flat-primary waves-effect"
								rippleEffect
							>
								<i class="{{ communicationRenderData.actions[item].icon }}"></i>
								{{ communicationRenderData.actions[item].displayLabel }}
							</button>
						</ng-container>
						<ng-container
							*ngIf="refData?.basic_info?.status?.state_id !== 5 && !refData.basic_info?.status?.is_exit"
						>
							<div ngbDropdown class="dropdown" *ngIf="selectedActions?.length > 1">
								<button
									ngbDropdownToggle
									class="dropdown-toggle btn btn-sm btn-flat-primary waves-effect"
									type="button"
									id="CommunicationDataAction"
									[attr.data-testid]="'CommunicationDataAction'"
								>
									{{ "UI.k_more" | translate }}
								</button>
								<div ngbDropdownMenu aria-labelledby="dropdownMenuButton" class="dropdown-menu">
									<ng-container *ngFor="let item of selectedActions; let i = index">
										<ng-container
											*ngIf="
												communicationRenderData.actions[item].isDisplay(
													communicationRenderData.actions[item].keyName
												) &&
												communicationRenderData.actions[item].lisenceKey &&
												i >= 1
											"
										>
											<a
												*appDnsLicenseHandler="communicationRenderData.actions[item].lisenceKey"
												ngbDropdownItem
												href="javascript:void(0)"
												id="btn{{ communicationRenderData.actions[item].keyName }}"
												attr.data-testid="btn{{
													communicationRenderData.actions[item].keyName
												}}"
												tooltipclass="fadeInAnimation"
												placement="top"
												[name]="communicationRenderData.actions[item].name"
												class="dropdown-item d-flex gap-1 align-items-center"
												(click)="communicationRenderData.actions[item].callBack(latestEmail)"
												><i class="{{ communicationRenderData.actions[item].icon }}"></i>
												{{ communicationRenderData.actions[item].displayLabel }}
											</a>
										</ng-container>
										<ng-container
											*ngIf="
												communicationRenderData.actions[item].isDisplay(
													communicationRenderData.actions[item].keyName
												) &&
												!communicationRenderData.actions[item].lisenceKey &&
												i >= 1
											"
										>
											<a
												ngbDropdownItem
												href="javascript:void(0)"
												id="btn{{ communicationRenderData.actions[item].keyName }}"
												attr.data-testid="btn{{
													communicationRenderData.actions[item].keyName
												}}"
												tooltipclass="fadeInAnimation"
												placement="top"
												[name]="communicationRenderData.actions[item].name"
												class="dropdown-item d-flex gap-1 align-items-center"
												(click)="communicationRenderData.actions[item].callBack(latestEmail)"
												><i class="{{ communicationRenderData.actions[item].icon }}"></i>
												{{ communicationRenderData.actions[item].displayLabel }}
											</a>
										</ng-container>
									</ng-container>
								</div>
							</div>
						</ng-container>
					</ng-container>
				</ng-container>
				<!-- <button
					id="btnReply"
					name="reply"
					*ngIf="mail_list.length > 0 && latestEmail"
					(click)="
						composeMail($index, latestEmail, 'edit', false, 'Re:', scrolltocommentwindow); showReplyBox = false
					"
					type="button"
					class="btn btn-sm btn-flat-primary"
					rippleEffect
				>
					<i class="fa-light fa-arrow-turn-down-left"></i>
					{{ "UI.k_reply" | translate }}
				</button> -->
				<!-- <span
														id="btnReply"
														name="reply"
														*ngIf="mail_list.length > 0 && latestEmail"
														class="cursor-pointer pull-left pr-1 text-primary xs-font"
														(click)="
															composeMail(
																$index,
																latestEmail,
																'edit',
																false,
																'Re:',
																scrolltocommentwindow
															);
															showReplyBox = false
														"
													>
														<i class="fa-light fa-arrow-turn-down-left mr-50"></i>
														{{ 'UI.k_reply' | translate }}</span
													> -->
				<!-- <span *ngIf="mail_list.length > 0 && showEmails == true" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> -->
				<!-- <button
					id="btnForward"
					name="forward"
					(click)="
						composeMail($index, latestEmail, 'edit', false, 'Fw:', scrolltocommentwindow); showReplyBox = false
					"
					*ngIf="mail_list.length > 0 && latestEmail"
					type="button"
					class="btn btn-sm btn-flat-primary"
					rippleEffect
				>
					<i class="fa-light fa-envelope"></i>
					{{ "UI.k_forward" | translate }}
				</button> -->
				<!-- <span
														id="btnForward"
														name="forward"
														*ngIf="mail_list.length > 0 && latestEmail"
														class="cursor-pointer pull-left pr-1 text-primary xs-font"
														(click)="
															composeMail(
																$index,
																latestEmail,
																'edit',
																false,
																'Fw:',
																scrolltocommentwindow
															);
															showReplyBox = false
														"
													>
														<i class="fa-light fa-envelope"></i>&nbsp;&nbsp;{{
															'UI.k_forward' | translate
														}}</span
													> -->
				<!-- <span *ngIf="mail_list.length > 0" >&nbsp;&nbsp;&nbsp;&nbsp;</span> -->
				<!-- <button
					*ngIf="communicationFilterSelection.keyName != 'Email'"
					(click)="enableEditor('Add Notes', scrolltocommentwindow); showReplyBox = false"
					id="btnAddNote"
					name="addNote"
					type="button"
					class="btn btn-sm btn-flat-primary"
					rippleEffect
				>
					<i class="fa-light fa-file-lines"></i>{{ "UI.k_add" | translate }}
					{{ "UI.k_notes" | translate }}
				</button> -->
				<!-- <span
														*ngIf="communicationFilterSelection != 'email'"
														id="btnAddNote"
														name="addNote"
														class="cursor-pointer pull-left pr-1 text-primary xs-font"
														(click)="
															enableEditor('Add Notes', scrolltocommentwindow);
															showReplyBox = false
														"
													>
														<i class="fa-light fa-file-lines"></i>
														&nbsp;&nbsp;{{ 'UI.k_add' | translate }}
														{{ 'UI.k_notes' | translate }}</span
													> -->
				<!-- <span>&nbsp;</span> -->
				<!-- <button
					*ngIf="isCommunicationClosed()"
					id="btnSymptom"
					(click)="enableEditor('Symptoms', scrolltocommentwindow); showReplyBox = false"
					name="Symptoms"
					type="button"
					class="btn btn-sm btn-flat-primary"
					rippleEffect
				>
					<i class="fa-light fa-file-lines"></i>{{ "UI.k_symptoms" | translate }}
				</button> -->
				<!-- <span
														*ngIf="isCommunicationClosed()"
														id="btnSymptom"
														name="Symptoms"
														class="cursor-pointer pull-left pr-1 text-primary xs-font"
														(click)="
															enableEditor('Symptoms', scrolltocommentwindow);
															showReplyBox = false
														"
													>
														<i class="fa-light fa-file-lines"></i>&nbsp;&nbsp;{{
															'UI.k_symptoms' | translate
														}}</span
													> -->
				<!-- <button
					*ngIf="isCommunicationClosed()"
					id="btnDiagnosis"
					name="diagnosis"
					(click)="enableEditor('Diagnosis', scrolltocommentwindow); showReplyBox = false"
					type="button"
					class="btn btn-sm btn-flat-primary"
					rippleEffect
				>
					<i class="fa-light fa-file-lines"></i>{{ "UI.k_diagnosis" | translate }}
				</button> -->
				<!-- <span
														*ngIf="isCommunicationClosed()"
														id="btnDiagnosis"
														name="diagnosis"
														class="cursor-pointer pull-left pr-1 text-primary xs-font"
														(click)="
															enableEditor('Diagnosis', scrolltocommentwindow);
															showReplyBox = false
														"
														><i class="fa-light fa-file-lines"></i>&nbsp;&nbsp;{{
															'UI.k_diagnosis' | translate
														}}</span
													> -->
				<!-- <span id="btnResolution" name="resolution" class="cursor-pointer pull-left pr-1 text-primary xs-font" (click)="enableEditor('Resolution');showReplyBox=false;"><i [data-feather]="'file-text'"></i>&nbsp;&nbsp;Resolution</span> -->
				<!-- <button
					*ngIf="isCommunicationClosed()"
					id="btnRootCause"
					name="rootCause"
					(click)="enableEditor('Root Cause', scrolltocommentwindow); showReplyBox = false"
					type="button"
					class="btn btn-sm btn-flat-primary"
					rippleEffect
				>
					<i class="fa-light fa-file-lines"></i>{{ "UI.k_root_cause" | translate }}
				</button> -->
				<!-- <span
														*ngIf="isCommunicationClosed()"
														id="btnRootCause"
														name="rootCause"
														class="cursor-pointer pull-left pr-1 text-primary xs-font"
														(click)="
															enableEditor('Root Cause', scrolltocommentwindow);
															showReplyBox = false
														"
														><i class="fa-light fa-file-lines"></i>&nbsp;&nbsp;{{
															'UI.k_root_cause' | translate
														}}</span
													> -->
				<!-- <button
					*ngIf="communicationFilterSelection.keyName != 'Email'"
					id="btnWorkLog"
					(click)="enableEditor('Worklog', scrolltocommentwindow); showReplyBox = false"
					name="workLog"
					type="button"
					class="btn btn-sm btn-flat-primary"
					rippleEffect
				>
					<i class="fa-light fa-file-lines"></i>{{ "UI.k_worklog" | translate }}
				</button> -->
				<!-- <span
														*ngIf="communicationFilterSelection != 'email'"
														id="btnWorkLog"
														name="workLog"
														class="cursor-pointer pull-left pr-1 text-primary xs-font"
														(click)="
															enableEditor('Worklog', scrolltocommentwindow);
															showReplyBox = false
														"
														><i class="fa-light fa-file-lines"></i>&nbsp;&nbsp;{{
															'UI.k_worklog' | translate
														}}</span
													> -->
			</div>
			<ng-container *ngFor="let item of submitActions">
				<button
					id="btn{{ communicationRenderData.submitActions?.[item].keyName }}"
					attr.data-testid="btn{{ communicationRenderData.submitActions[item]?.keyName }}"
					[name]="communicationRenderData.submitActions[item].name"
					*ngIf="communicationRenderData.submitActions?.[item]?.isDisplay()"
					[permissions]="permissions"
					(click)="communicationRenderData.submitActions?.[item]?.callBack()"
					class="btn btn-sm btn-primary pull-right"
				>
					<b>{{ communicationRenderData.submitActions[item]?.displayLabel }}</b>
				</button>
			</ng-container>
			<ng-container>
				<span
					class="txt-info"
					*ngIf="
						!ref_data_copy?.approval_data?.can_approve &&
						ref_data_copy?.approval_data?.is_submitter &&
						refData?.basic_info?.state?.id == 6
					"
					>{{ "UI.k_waiting_for_approval" | translate }}</span
				>
				<span
					class="txt-info"
					*ngIf="
						!ref_data_copy?.review_data?.can_review &&
						ref_data_copy?.review_data?.is_review_submitter &&
						([7].includes(refData?.basic_info?.state?.id) || ref_data_copy?.basic_info?.status?.is_pir)
					"
					>{{ "UI.k_waiting_for_review" | translate }}</span
				>
			</ng-container>

			<!-- <button
				*ngIf="checkCommunicationState()"
				[permissions]="permissions"
				(click)="enableEditor('Resolution', scrolltocommentwindow); showReplyBox = false"
				class="btn btn-sm btn-primary pull-right"
			>
				<b>{{ "UI.k_resolve" | translate }}</b>
			</button>
			<button
				type="button"
				*ngIf="incident_data_copy?.approval_data?.can_approve"
				(click)="enableEditor('Approval', scrolltocommentwindow); showReplyBox = false"
				class="btn btn-xs btn-primary pull-right mr-1"
			>
				<b>{{ "UI.k_approve" | translate }}</b>
			</button>
			<span
				class="txt-info"
				*ngIf="
					!incident_data_copy?.approval_data?.can_approve &&
					incident_data_copy?.approval_data?.is_submitter &&
					incident_data?.basic_info?.state?.id == 6
				"
				>{{ "UI.k_waiting_for_approval" | translate }}</span
			>
			<button
				type="button"
				*ngIf="
					(this.incident_data_copy?.basic_info?.state?.id == 4 ||
						(incident_data_copy?.basic_info?.state?.id == 5 && incident_data_copy?.is_exit == false)) &&
					permissions?.edit
				"
				(click)="enableEditor('Closure Note', scrolltocommentwindow); showReplyBox = false"
				class="btn btn-xs btn-primary pull-right ml-1"
			>
				<b>{{ "UI.k_complete" | translate }}</b>
			</button> -->
		</div>
	</div>
	<div class="border-top flex-initial" *ngIf="!showReplyBox && isRefDataLoad && showEmailTemplate != false">
		<!-- <div class="d-flex align-items-center p-50">
			<div class="flex-initial pr-1">
				<div class="pl-50" (click)="toggleSidebar('quick-response-template')">
					<i class="fa-light fa-pen-to-square text-primary"></i>
				</div>
			</div>
			<div class="flex-1 temp-badge-outer border-right border-left px-1">
				<ng-scrollbar class="custom-scrollbar section-scrollbar" track="horizontal">
					<div class="d-flex align-items-center email-temp-badge">
						<ng-container *ngFor="let item of emailTemplate; let i = index">
							<div
								(click)="onTemplateClick(item.tempText, i)"
								class="badge badge-pill badge-light-primary fw-500"
							>
								<span class="text-body">{{ item.title }}</span>
							</div>
						</ng-container>
					</div>
				</ng-scrollbar>
			</div>
			<div class="flex-initial pl-1">
				<app-tree-dropdown-with-icon
					[id]="1"
					class="w-100 tree-open-top tree-view-icon"
					[items]="this.treeInputData"
					[value]="selectTreeValue"
					(itemChange)="valueChange($event)"
					(showTemplateModal)="showTemplateModal($event)"
					[showTemplateEdit]="false"
					[headingText]="responseEmailTreeHeading"
					[selectedTreeData]="selectQuickResponseText"
				>
				</app-tree-dropdown-with-icon>
			</div>
		</div> -->
	</div>
</div>
<core-sidebar
	*ngIf="showQuickResponseSidbar"
	class="modal modal-slide-in sidebar-todo-modal fade"
	id="quickresponsetemplate"
	[attr.data-testid]="'quickresponsetemplate'"
	name="quick-response-template"
	overlayClass="modal-backdrop"
>
	<app-quick-response-template
		[responseEmailTemplate]="emailTemplate"
		(currentItemEmit)="curruntResponseTemp($event)"
		(eventEmit)="curruntResponseTempEvent($event)"
		(onHideQuickResponseSidbar)="onHideQuickResponseSidbar($event)"
	>
	</app-quick-response-template>
</core-sidebar>

<core-sidebar
	*ngIf="showAddResponseSidebar"
	id="addquickresponsetemplate"
	[attr.data-testid]="'addquickresponsetemplate'"
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="add-quick-response-template"
	overlayClass="modal-backdrop"
>
	<app-add-response-email-template
		[event]="event"
		(onHideSidebar)="onHideSidebar($event)"
		[currentItem]="currentItem"
	></app-add-response-email-template>
</core-sidebar>
<ng-template #previewResponse let-modal>
	<div class="modal-header border-bottom">
		<h6 class="mb-0">
			{{ "UI.k_preview_quick_response" | translate }}
		</h6>
		<button
			type="button"
			id="previewClose"
			[attr.data-testid]="'previewClose'"
			name="previewClose"
			class="close"
			(click)="modal.dismiss('Cross click')"
			aria-label="Close"
		>
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body" tabindex="0" ngbAutofocus>
		<h3 class="fw-600">{{ previewData.text }}</h3>
		<p>
			Lorem ipsum dolor sit amet consectetur. Bibendum tortor risus lobortis ac lacus ornare id magna.Lorem ipsum
			dolor sit amet consectetur. Bibendum tortor risus lobortis ac lacus ornare id magna. ac lacus ornare id
			magna.
		</p>
	</div>
	<div class="d-flex justify-content-end border-top px-1 py-1">
		<button
			type="button"
			(click)="modal.dismiss('Cross click')"
			class="btn btn-outline-tertiary btn-prev mr-1"
			rippleEffect
		>
			<span class="align-middle"> {{ "UI.k_cancel" | translate }}</span>
		</button>
		<button type="button" (click)="useItTemplate(previewData)" class="btn btn-primary btn-next" rippleEffect>
			<span class="align-middle">{{ "UI.k_use_it" | translate }}</span>
		</button>
	</div>
</ng-template>
<ng-template #previewSignature let-modal>
	<div class="modal-header border-bottom">
		<h6 class="mb-0">
			{{ "UI.k_preview_signature" | translate }}
		</h6>
		<button
			type="button"
			id="previewClose"
			[attr.data-testid]="'previewClose'"
			name="previewClose"
			class="close"
			(click)="modal.dismiss('Cross click')"
			aria-label="Close"
		>
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body" tabindex="0" ngbAutofocus>
		<div class="d-flex align-items-center justify-content-between">
			<h3 class="mb-0">
				{{ previewData.text }}
			</h3>
			<span class="cursor-pointer" (click)="editTemplate(data)">
				<i class="fa-light fa-pen-to-square sm-font"></i>
			</span>
		</div>
		<div>
			<div class="communication-preview" [innerHtml]="previewData.value | safe: 'html'"></div>
		</div>

		<!-- <p>
			With regards,<br />
			Walter White
		</p> -->
	</div>
	<div class="d-flex justify-content-end border-top px-1 py-1">
		<button
			type="button"
			(click)="modal.dismiss('Cross click')"
			class="btn btn-outline-tertiary btn-prev mr-1"
			rippleEffect
		>
			<span class="align-middle"> {{ "UI.k_cancel" | translate }}</span>
		</button>
		<button type="button" (click)="useItSignature(previewData)" class="btn btn-primary btn-next" rippleEffect>
			<span class="align-middle">{{ "UI.k_use_it" | translate }}</span>
		</button>
	</div>
</ng-template>
