import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
} from "@angular/core";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import Stepper from "bs-stepper";
import { catchError, map } from "rxjs/operators";
import { Subject, throwError } from "rxjs";
import { GlobalConfig, ToastrService } from "ngx-toastr";
import { FileUploader } from "ng2-file-upload";
import includes from "lodash-es/includes";
import { NgbDateStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { UntypedFormControl, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { GlobalConstants } from "app/app.globalConstants";
import { FlatpickrOptions } from "ng2-flatpickr";
import moment from "moment";
import { customSearch } from "app/common/utils/utils";

import { CountryISO, PhoneNumberFormat, SearchCountryField } from "ngx-intl-tel-input";
import { RequesterService } from "app/base/requester/services/requester.service";
import { TagService } from "app/base/tags/services/tag.service";
import { PolicyService } from "app/base/policy/services/policy.service";

const AES = require("crypto-js/aes");
const URL = "http://localhost:8080/common/user/profileimage/";
@Component({
  selector: 'app-dns-edit-requester',
  templateUrl: './dns-edit-requester.component.html',
  styleUrls: ['./dns-edit-requester.component.scss']
})
export class DnsEditRequesterComponent implements OnInit {
	@ViewChild("TagComp") TagComp: ElementRef;
	@ViewChild("UserAddForm") UserAddForm;
	@ViewChild("accountDetailsForm") accountDetailsForm;
	@ViewChild("personalInfoForm") personalInfoForm;
	@ViewChild("addressForm") addressForm;
	@Input() requesterId: Number;
	@Input() action: String;
	@Input() reqFromExternal: boolean;
	@Input() refId: Number;
	@Output("shownRequester") shownRequester = new EventEmitter<any>();
	@Output("getRequesterList") getRequesterList = new EventEmitter<any>();
	@Output("onHideProfile") onHideProfile = new EventEmitter<any>();
	requester: any = {};
	dropdownData: any = {};
	public uploader: FileUploader = new FileUploader({
		url: URL,
		isHTML5: true,
	});

	//image uploader
	public imgDetailsObject = {
		aspectRatio: "4/ 2",
		resizeToWidth: "128",
		cropperMinWidth: "128",
		maintainAspectRatio: true,
	};
	public tempImage;
	public activeAvatar;
	public currentAvatarColor;
	public showQueue = false;
	public multipleFiles = true;
	public displayResoluion = false;
	public squareImage = true;
	public currentImgDetails;
	public avatarBackgroundColor: string = "";
	public active;
	public imageDetail: boolean;
	public setImage: boolean;
	public change: boolean;
	public fullName;
	public hasBaseDropZoneOver: boolean = false;
	private _horizontalWizardStepper: Stepper;
	private _bsStepper;
	private _options: GlobalConfig;
	public contentHeader: object;
	public file: any;
	public userConfirmPassword;
	public userPwdShow = false;
	public showPreview = false;
	public TDValidationForm: any; //TODO change to relevant one
	public ALLOWED_TYPES = ["image/png", "image/jpeg"];
	public MAX_IMAGE_SIZE = 20971520;
	public MAX_HEIGHT = 15200;
	public MAX_WIDTH = 25600;

	public selectMultiSelected = [{ name: "Timezone 2" }];
	public selectedTimezone;
	public imageBase64: string;
	public CustomDayDPdata: NgbDateStruct;
	private _unsubscribeAll: Subject<any>;
	// isCollapsed: boolean;
	tag: any;
	public passwordToaster;
	public passwordPattern;
	public is_password_invalid;
	public changePassword;
	public passwordHistory;
	public minPasswordLength;
	public maxPasswordLength;
	public minUpperCase;
	public minLowerCase;
	public minNumChar;
	public minSplChar;
	public contUpperCase;
	public contLowerCase;
	public contNumChar;
	public contSplChar;
	is_email_invalid: any = false;
	is_disable_save: any = false;
	public submitButton: string = this._translateService.instant("UI.k_submit");
	public requesterType;
	public reportingMgr;
	tagPermission: any;
	action_label: any = "UI.k_action_edit_requester";
	separateDialCode = true;
	SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
	PhoneNumberFormat = PhoneNumberFormat;
	preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom, CountryISO.India];
	is_save_disabled = false;
	select_location = false;
	showAddNewLocationSidebar = false
	selectedBaseLocation: any = null
	selectedWorkLocation: any = null
	home_address: any = false;

	public basicDateOptions: FlatpickrOptions = {
		altInput: true,
	};

	constructor(
		private _coreSidebarService: CoreSidebarService,
		private _toastr: ToastrService,
		private _service: RequesterService,
		private modalService: NgbModal,
		private _tagService: TagService,
		private _policyService: PolicyService,
		private _translateService: TranslateService
	) {
		this._options = this._toastr.toastrConfig;
		this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void {
    this._horizontalWizardStepper = new Stepper(document.querySelector("#editRequester"), {});
    this._bsStepper = document.querySelectorAll(".bs-stepper");
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.tagPermission = user_permissions["tag"];
		if (this.action == "Add") {
			this.action_label = "UI.k_action_add_requester";
			this.submitButton = this._translateService.instant("UI.k_submit");

			this._service
				.getDropdownData()
				.pipe(
					map((response: any) => {
						this.dropdownData = response;
						if (this.dropdownData?.default_type) {
							this.requester["type"] = "" + this.dropdownData?.default_type;
							this.requester["user_details"]["language"] = this.dropdownData.default_lang;
							this.requester["user_details"]["timezone"] = this.dropdownData.default_timezone;
						}

						this.prepareMangerList();
						this.getPasswordPolicy();
						this.dropdownData.WORK_LOCATION_LIST = this.dropdownData.LOCATION_LIST.concat([{ 'location_name': 'Home Address' }]);
					})
				)
				.subscribe();
		} else {
			this._service
				.getDropdownData()
				.pipe(
					map((response: any) => {
						this.dropdownData = response;
						this.dropdownData.WORK_LOCATION_LIST = this.dropdownData.LOCATION_LIST.concat([{ 'location_name': 'Home Address'}]);
						// if (this.dropdownData?.default_type) {
						// 	this.requester["type"] = "" + this.dropdownData?.default_type;
						// }

						this.prepareMangerList();
					})
				)
				.subscribe();
			this.action_label = "UI.k_action_edit_requester";
			this.submitButton = this._translateService.instant("UI.k_submit");
			// this._horizontalWizardStepper = new Stepper(document.querySelector("editRequester"), {});
		}
		this.requester = {};
		this.requester["is_login_active"] = true;
		this.requester["base_location"] = {};
		this.requester["user_details"] = {};
		this.requester["work_location"] = {};
		// this._bsStepper = document.querySelectorAll(".bs-stepper");
	}

	ngOnChanges(changes: SimpleChanges) {
		this.imageBase64 = undefined;
		this.showPreview = false;
		this.requester["is_login_active"] = true;
		if (changes.requesterId.previousValue != changes.requesterId.currentValue && this.requesterId != -1) {
			this.getRequester(this.requesterId);
		}
	}

	openModalAD(TagComp, name) {
		this.tag = {};
		this.tag["name"] = name;
		if (this.dropdownData.TAG_TYPE_LIST != undefined) {
			this.tag["type"] = this.dropdownData.TAG_TYPE_LIST[0].id;
		}
		this.modalService.open(TagComp, {
			centered: true,
			animation: false,
			size: "md",
		});
	}

	selectAddTagMethod = (name) => {
		if (this.tagPermission.add) {
			this.openModalAD(this.TagComp, name);
		}
	};

	onCreateTag(data) {
		if (data.form.valid === true) {
			this.is_save_disabled = true;
			this._tagService
				.saveTag(this.tag)
				.pipe(
					map((response: any) => {
						if (response) {
							if (response["status"] == "success") {
								this._toastr.success(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Message.msg_save_success"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
								this.modalService.dismissAll();
								this._service
									.getDropdownData()
									.pipe(
										map((response: any) => {
											this.dropdownData = response;
										})
									)
									.subscribe();
							} else {
								this._toastr.error(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Error.err_fail"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							}
							this.is_save_disabled = false;
						}
						// Success
					}),
					catchError((error) => {
						this._toastr.error(
							this._translateService.instant("Error.err_tag_save_failed"),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						return throwError(error.statusText);
					})
				)
				.subscribe();
		}
	}

	onCancel() {
		this.modalService.dismissAll();
	}

	fileOverBase(e: any): void {
		this.hasBaseDropZoneOver = e;
	}

	onUpload(files: any): void {
		if (files.length > 0) {
			this.file = files[0];
			if (this.file && this.file["size"] > this.MAX_IMAGE_SIZE) {
				this._toastr.warning("Maximum size allowed is " + this.MAX_IMAGE_SIZE / 1000 + "Mb", "Failed Upload", {
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				});
			}

			if (!includes(this.ALLOWED_TYPES, this.file["type"])) {
				this._toastr.warning("Only Images are allowed ( JPG | PNG )", "Failed Upload", {
					toastClass: "toast ngx-toastr",
					closeButton: true,
					positionClass: "toast-top-right",
				});
			}
			const reader = new FileReader();
			reader.onload = (e: any) => {
				const image = new Image();
				image.src = e.target.result;
				image.onload = (rs) => {
					const img_height = rs.currentTarget["height"];
					const img_width = rs.currentTarget["width"];
					if (img_height > this.MAX_HEIGHT && img_width > this.MAX_WIDTH) {
						this._toastr.warning(
							"Maximum dimentions allowed " + this.MAX_HEIGHT + "*" + this.MAX_WIDTH + "px",
							"Failed Upload",
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
					} else {
						const imgBase64Path = e.target.result;
						this.imageBase64 = imgBase64Path;
						this.requester.profile_image = imgBase64Path;
						this.requester.new_image = true;
						// this.previewImagePath = imgBase64Path;
					}
				};
			};
			reader.readAsDataURL(this.file);
			this.showPreview = true;
		}
	}

	/**
	 * On Submit
	 */
	onSubmit = (data) => {
		if (data.form.valid === true) {
			if (this.requester.joining_date != undefined) {
				if (typeof this.requester?.joining_date[0] == "object") {
					let start_date = new Date(this.requester?.joining_date[0]?.getTime());
					this.requester["joining_date"] = start_date.toLocaleString("en-US", {
						timeZone: "Asia/Kolkata",
					});
				} else {
					this.requester["joining_date"] = moment(this.requester?.joining_date).format("MM/DD/YYYY");
				}
			}
			if (this.requester.is_login_enable) {
				if (this.is_password_invalid === true) {
					return;
				}
				if (this.requester.user_details.password != "************Aa1") {
					this.requester.user_details.password = this.encryptPassword(this.requester.user_details.password);
				}
			}
			// if (this.uploader.queue.length > 0) {
			// 	this.requester["profile_image"] = this.imageBase64;
			// }
			if (this.imageDetail === true) {
				this.requester["profile_image"] = this.imageBase64;
				this.requester["new_image"] = true;
			}
			this.requester["avatar_color"] = this.currentAvatarColor;
			if(this.home_address  || (this.requester?.organization_name == null && this.requester?.type == 2)) {
				this.requester.work_location['is_home_address'] = true
			}
			if(!this.requester?.base_location){
                this.requester['base_location'] = {}
            }
            if(!this.requester?.work_location){
                this.requester['work_location'] = {}
            }
			this.saveRequesterProfile(this.requester, data);
		}
	};

	validateEmail(emailInput) {
		const forbidden = emailInput.some((email) => Validators.email(new UntypedFormControl(email)));
		this.is_email_invalid = forbidden;
	}

	getPasswordPolicy = () => {
		const payload = {
			page: 1,
			items_per_page: 10,
			sort: "policyName",
			reverse: "",
			group_id: this.requester?.user_group_id | this.dropdownData.group_id,
		};
		this._policyService.fetchPasswordPolicy(payload).subscribe(
			(response) => {
				if (response) {
					this.generatePasswordPolicy(response);
				}
			},
			(error) => {
				//todo
			}
		);
	};

	getRequester(requesterId: Number): void {
		this.imageBase64 = undefined;
		this.showPreview = false;
		this._service
			.getRequesterProfile(requesterId)
			.pipe(
				map((response: any) => {
					if (response) {
						if (response["id"] !== null || response["id"] !== undefined) {
							this.requester = response;
							this.requester['full_name'] = response.full_name
							this.requester['email'] = response.email
							this.getPasswordPolicy();
							if (typeof this.requester?.emp_type == "string") {
								this.requester.emp_type = parseInt(this.requester?.emp_type);
							}
							if (typeof response?.type == "number") {
								this.requesterType = response.type;
								this.requester.type = "" + response?.type;
							}
							this.imageBase64 = response.profile_image;
							this.currentImgDetails = this.imageBase64;
							this.reportingMgr = this.requester.user_details.reporting_manager;
							this.fullName = this.requester.full_name;
							//this.currentAvatarColor = '#f59518';
							//this.activeAvatar= '#f59518';
							if (response?.avatar_color) {
								this.currentAvatarColor = response.avatar_color;
								this.activeAvatar = response.avatar_color;
							} else {
								this.currentAvatarColor = "#f59518";
								this.activeAvatar = "#f59518";
							}
							this.showPreview = true;
							if (this.requester?.work_location?.is_home_address) {
								this.home_address = true
								this.selectedWorkLocation = { 'location_name': 'Home Address'}
							}
							else if (this.requester?.work_location?.location_name) {
								this.selectedWorkLocation = this.requester?.work_location
							}
							if (this.requester.base_location.location_name){
								this.selectedBaseLocation = this.requester?.base_location
							}
							// if (this.imageBase64){
							// 	this.showPreview = true
							// }
							this.action = "Edit";
							this.action_label = "UI.k_action_edit_requester";
							this.submitButton = this._translateService.instant("UI.k_submit");
						}
					}
					// Success
				}),
				catchError((error) => {
					return throwError(error.statusText);
				})
			)
			.subscribe();
		this.action = "Edit";
		this.action_label = "UI.k_action_edit_requester";
		this.submitButton = this._translateService.instant("UI.k_submit");
	}

	prepareMangerList = () => {
		if (this.requesterId) {
			//console.log(this.requester);
			this.dropdownData.REP_MGER_LIST = this.dropdownData.REP_MGER_LIST.filter(
				(d) => d?.email !== this.requester?.email
			);
			// //console.log(
			// 	"rmanger",
			// 	this.dropdownData.REP_MGER_LIST.filter((d) => d?.email !== this.requester?.email)
			// );
		}
	};

	generatePasswordPolicy(policy_objs) {
		this.changePassword = policy_objs.changePassword;
		this.passwordHistory = policy_objs.passwordHistory;
		this.minPasswordLength = policy_objs.minPasswordLength;
		this.maxPasswordLength = policy_objs.maxPasswordLength;
		this.minUpperCase = policy_objs.minUpperCase;
		this.minLowerCase = policy_objs.minLowerCase;
		this.minNumChar = policy_objs.minNumChar;
		this.minSplChar = policy_objs.minSplChar;
		this.contUpperCase = policy_objs.contUpperCase;
		this.contLowerCase = policy_objs.contLowerCase;
		this.contNumChar = policy_objs.contNumChar;
		this.contSplChar = policy_objs.contSplChar;
	}

	validatePassword(password) {
		this.is_password_invalid = false;
		this.passwordToaster = this._translateService.instant("Message.msg_pwd_contain") + "</br>";
		if (password.length < this.minPasswordLength) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_minimum_character", {
				minimum_character: this.minPasswordLength,
			});
			this.is_password_invalid = true;
		}
		if (password.length > this.maxPasswordLength) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_maximum_character", {
				maximum_character: this.maxPasswordLength,
			});
			this.is_password_invalid = true;
		}
		if (
			password.replace(/[^\\!\\@\\#\\$\\%\\^\\&\\*\\(\\)\\_\\?\\<\\>]/g, "").length < this.minSplChar &&
			this.minSplChar != this.contSplChar
		) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_minimum_special_character", {
				minimum_special_character: this.minSplChar,
			});
			this.is_password_invalid = true;
		}
		let pattern = "([\\!\\@\\#\\$\\%\\^\\&\\*\\(\\)\\_\\?\\<\\>])" + "{" + this.contSplChar + "}";
		if (!password.match(pattern)) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_continous_special_character", {
				continous_special_character: this.contSplChar,
			});
			this.is_password_invalid = true;
		}
		if (password.replace(/[^0-9]/g, "").length < this.minNumChar && this.minNumChar != this.contNumChar) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_minimum_number", {
				minimum_number: this.minNumChar,
			});
			this.is_password_invalid = true;
		}
		pattern = "([\\d])" + "{" + this.contNumChar + "}";
		if (!password.match(pattern)) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_continous_number", {
				continous_number: this.contNumChar,
			});
			this.is_password_invalid = true;
		}
		if (password.replace(/[^a-z]/g, "").length < this.minLowerCase && this.minLowerCase != this.contLowerCase) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_minimum_lowercase_letter", {
				minimum_lowercase_letter: this.minLowerCase,
			});
			this.is_password_invalid = true;
		}
		pattern = "([a-z])" + "{" + this.contLowerCase + "}";
		if (!password.match(pattern)) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_continous_lowercase_letter", {
				continous_lowercase_letter: this.contLowerCase,
			});
			this.is_password_invalid = true;
		}
		if (password.replace(/[^A-Z]/g, "").length < this.minUpperCase && this.minUpperCase != this.contUpperCase) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_minimum_uppercase_letter", {
				minimum_uppercase_letter: this.minUpperCase,
			});
			this.is_password_invalid = true;
		}
		pattern = "([A-Z])" + "{" + this.contUpperCase + "}";
		if (!password.match(pattern)) {
			this.passwordToaster += this._translateService.instant("Error.err_pwd_continous_uppercase_letter", {
				continous_uppercase_letter: this.contUpperCase,
			});
			this.is_password_invalid = true;
		}
	}

	encryptPassword(password: string) {
		var iv = "d99630bcc7058639e7e80df239f38a32";
		var ciphertext = AES.encrypt(password, iv).toString();
		var aesPassword = iv + "::" + ciphertext;
		return btoa(aesPassword);
	}

	changeRequesterType(type) {
		if (type != this.requesterType) {
			this.requester.user_details.reporting_manager = undefined;
		} else {
			this.requester.user_details.reporting_manager = this.reportingMgr;
		}
		this.selectedBaseLocation = null
		this.selectedWorkLocation = null
		this.requester["work_location"] = {}
		this.requester["base_location"] = {}
		this.home_address = false
	}

	toggleSidebar = (name, form: any): void => {
		this._horizontalWizardStepper.reset();
		setTimeout(() => form.resetForm(), 200);
		this.accountDetailsForm.resetForm();
		this.personalInfoForm.resetForm();
		this.addressForm.resetForm();
		this._coreSidebarService.getSidebarRegistry("edit-requester").toggleOpen();
		this.action = "Add";
		this.action_label = "UI.k_action_add_requester";
		this.submitButton = this._translateService.instant("UI.k_submit");
		this.requesterId = -1;
		this.requester = {};
		this.requester["location"] = {};
		this.requester["user_details"] = {};
		this.onHideProfile.emit("hide");
	};
	/**
	 * Horizontal Wizard Stepper Next
	 *
	 * @param data
	 */
	horizontalWizardStepperNext = (data) => {
		if (data.form.valid === true) {
			this._horizontalWizardStepper.next();
		}
	};

	/**
	 * Horizontal Wizard Stepper Previous
	 */
	horizontalWizardStepperPrevious() {
		this._horizontalWizardStepper.previous();
	}

	saveRequesterProfile = (userObj: any, form): void => {
		this.is_disable_save = true;
		if (typeof userObj.type == "string") {
			userObj.type = parseInt(userObj.type);
		}
		if (this.refId != undefined) {
			userObj["ref_id"] = this.refId;
		}

		this._service
			.saveRequesterProfile(userObj)
			.pipe(
				map((response: any) => {
					if (response) {
						// this.notification.success('Saved User', 'User has been saved successfully')
						if (response["status"] == "success") {
							this.getRequesterList.emit();
							this.onHideProfile.emit("hide");
							this._coreSidebarService.getSidebarRegistry("edit-requester").toggleOpen();
							this._horizontalWizardStepper.reset();
							// setTimeout(() => form.resetForm(), 200);
							this._toastr.success(
								this._translateService.instant(response["message"]),
								this._translateService.instant("Message.msg_save_success"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);

							if (this.reqFromExternal && this.reqFromExternal == true) {
								this.shownRequester.emit(userObj);
							}
						} else {
							this._toastr.error(
								this._translateService.instant(response["message"]),
								this._translateService.instant("Error.err_save_failed"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
						}
						this.is_disable_save = false;
					}
					// Success
				}),
				catchError((error) => {
					this._toastr.error(
						this._translateService.instant("Error.err_requester_save_failed"),
						this._translateService.instant("Error.err_save_failed"),
						{
							toastClass: "toast ngx-toastr",
							closeButton: true,
							positionClass: "toast-top-right",
						}
					);
					//   this.notification.error('Failed User Creation', 'Something went wrong')
					return throwError(error.statusText);
					this.is_disable_save = false;
				})
			)
			.subscribe();
	};

	removeImage = ($event) => {
		this.tempImage = this.requester["profile_image"];
		this.requester["profile_image"] = "";
		this.imageBase64 = null;
		this.imageDetail = false;
		// this.showPreview = false;
	};

	profileImage = ($event) => {
		this.imageBase64 = $event;
		this.requester.profile_image = $event;
		this.imageDetail = true;
		this.change = true;
	};

	ProfileBgColor = ($event) => {
		this.currentAvatarColor = $event;
	};

	currrentColor = ($event) => {
		if ($event == "image") {
			this.requester["profile_image"] = this.tempImage;
		} else {
			this.getRequester($event);
		}
	};

	onEnter($event: any) {
		//console.log($event.target.tagName, $event.target);
	}

	timezoneChange(timezone_id) {
		let timezone_tmp = this.dropdownData?.TIMEZONE_LIST?.filter((timezone) => timezone?.id === timezone_id);
		this.requester["timezone"] = timezone_tmp[0];
	}

	@HostListener("keydown", ["$event"]) //keydown event
	onKeyDown(event: any) {
		// Do stuff
		if (event.keyCode === 13 && event.target?.tagName != "BUTTON") {
			// 13 = enter
			event.preventDefault();
			return false;
		}
	}

	ngOnDestroy() {
		this.modalService.dismissAll();
	}

	customSearchFn(term: string, item: any) {
		return customSearch(term, item, "full_name", "email");
	}

	LocationMap(event, form){
		if(event !== undefined) {
			event["postalcode"] = event?.pincode
			event["building"] = event?.address
			this.requester["base_location"] = event
		}
		else {
			this.requester["base_location"] = {}
		}
	}

	WorkLocationMap(event, form) {
		if (event?.location_name == 'Home Address') {
			this.home_address = true
			this.requester["work_location"] = {}
		}
		else if(event !== undefined) {
			event["postalcode"] = event?.pincode
			event["building"] = event?.address
			event["is_home_address"] = false
			this.requester["work_location"] = event
			this.home_address = false
		}
		else {
			this.home_address = false
			this.requester["work_location"] = {}
		}
	}

	changeOrgName(orgName) {
		if(orgName) {
			this.selectedWorkLocation = null
			this.selectedBaseLocation = null
		}
		else{
			this.requester["work_location"] = {}
		}
	}

	customOrgSearchFn(term: string, item: any) {
		term = term.toLowerCase();
		return customSearch(term, item, "customer_name", "customer_code");
	}

	onImgError(item) {
		item["showAvatar"] = true;
	}

}
