<div #adminpopup id="admin-menu">
	<div class="modal-size-xs">
		<div class="border-bottom d-flex justify-content-start align-items-center modal-footer">
			<span class="avatar mr-50">
				<img
					*ngIf="currentUser.profile_image"
					class="round"
					[src]="currentUser.profile_image"
					alt="avatar"
					height="40"
					width="40" />
				<div
					*ngIf="!currentUser.profile_image"
					class="avatar avatar-40"
					[ngStyle]="{
						'background-color': currentUser.profile.avatar_color
							? currentUser.profile.avatar_color
							: '#a5c1d4'
					}"
				>
					<span class="avatar-content">{{
						this.currentUser.first_name + " " + this.currentUser.last_name | initials | slice: O:2
					}}</span>
				</div>
				<span> </span>
				<span class="avatar-status-online"></span>
				<span class="avatar-status-online"></span
			></span>

			<div class="text-dark fw-500 font-medium-1">
				<span title="{{ currentUser.first_name }}"
					>{{ currentUser.first_name | slice: 0:18 }}{{ currentUser.first_name.length > 18 ? "..." : "" }}
				</span>
				<span title="{{ currentUser.last_name }}"
					>{{ currentUser.last_name | slice: 0:18 }}{{ currentUser.last_name.length > 18 ? "..." : "" }}</span
				>
				<div class="font-small-3 text-center">
					<span class="text-muted">
						{{ currentUser.email | slice: 0:24 }}{{ currentUser.email.length > 24 ? "..." : "" }}
					</span>
				</div>
			</div>
		</div>
		<div class="border-bottom" *ngIf="!isAdminPortal && !isSupportPortal">
			<div class="d-flex justify-content-between align-items-center my-75 px-1">
				<div class="">
					<i class="fa-light fa-wave-pulse mr-75 font-small-3"></i>
					<span class="font-small-3 mr-25">{{ "UI.k_status" | translate }}</span>
					<span ngbTooltip="User Status">
						<img src="assets/images/search/info.png" alt="info" height="12px" />
					</span>
				</div>

				<div ngbDropdown [placement]="'left-top'" class="btn-group dropup dropdown-icon-wrapper">
					<span ngbDropdownToggle type="button" class="p-0 btn dropdown-toggle-split" rippleEffect>
						<div>
							<div
								*ngIf="active"
								type="button"
								(click)="toggleShow(false, 'active')"
								class="badge badge-light-success"
							>
								<span class="bullet bullet-success bullet-xs mr-50 cursor-pointer"></span>
								{{ "UI.k_active" | translate }}
							</div>

							<div *ngIf="away" type="button" class="badge badge-light-warning">
								<span class="bullet bullet-warning bullet-xs mr-50 cursor-pointer"></span>
								{{ "UI.k_away" | translate }}
							</div>

							<div *ngIf="donotdisturb" type="button" class="badge badge-light-danger">
								<span class="bullet bullet-danger bullet-xs mr-50 cursor-pointer"></span>
								{{ "UI.k_dn't_disturb" | translate }}
							</div>
							<!-- <i class="fa-light fa-angle-right font-small-3 ml-75"></i> -->
						</div>
					</span>
					<!-- <div ngbDropdownMenu>
            <a ngbDropdownItem id="drdactive" (click)="activeSelected()">
              <div type="button" class="badge badge-light-success">
                <span
                  class="bullet bullet-success bullet-xs mr-50 cursor-pointer"
                ></span>
                {{ "UI.k_active" | translate }}
              </div>
            </a>
            <a ngbDropdownItem id="drdaway" (click)="awaySelected()">
              <div type="button" class="badge badge-light-warning">
                <span
                  class="bullet bullet-warning bullet-xs mr-50 cursor-pointer"
                ></span>
                {{ "UI.k_away" | translate }}
              </div></a
            >
            <a ngbDropdownItem id="drddonotd" (click)="DNDSelected()">
              <div type="button" class="badge badge-light-danger">
                <span
                  class="bullet bullet-danger bullet-xs mr-50 cursor-pointer"
                ></span>
                {{ "UI.k_dn't_disturb" | translate }}
              </div>
            </a>
          </div> -->
				</div>
			</div>
			<div>
				<div
					class="my-50 px-1 profileset cursor-pointer"
					*appIsGranted="permission"
					(click)="editAccountSettings()"
					[attr.data-testid]="'btn_accountSettings'"
				>
					<i class="fa-light fa-user-cog mr-75 font-small-3"></i>
					<span class="font-small-3">{{ "UI.k_account_set" | translate }}</span>
				</div>
			</div>
			<!-- <div class="my-75 px-1 cursor-pointer profileset" *ngIf="!isSelfServicePortal">
        <i class="fa-light fa-cog mr-75 font-small-3"></i>
        <span class="font-small-3">{{ "UI.k_admin_con" | translate }}</span>
      </div> -->
			<!-- <div class="my-75 px-1 cursor-pointer profileset" (click)="redirectToSelfService()" *ngIf="!isSelfServicePortal">
        <i class="fa-light fa-cog  mr-75 font-small-3" ></i>
        <span class="font-small-3">{{ "UI.k_self_service" | translate }}</span>
      </div> -->
		</div>
		<!-- <div class="border-bottom cursor-pointer" *ngIf="!isSelfServicePortal">
      <div class="my-75 px-1 profileset">
        <i class="fa-light fa-question-circle mr-75 font-small-3"></i>
        <span class="font-small-3">{{ "UI.k_help_center" | translate }}</span>
      </div>
      <div class="my-75 px-1 profileset" *ngIf="!isSelfServicePortal">
        <i class="fa-light fa-headphones mr-75 font-small-3"></i>
        <span class="font-small-3">{{
          "UI.k_contact_support" | translate
        }}</span>
      </div>
    </div> -->
		<div class="border-bottom cursor-pointer profileset" *ngIf="!isAdminPortal && !isSupportPortal" [attr.data-testid]="'btn_shortcuts'">
			<div class="my-75 px-1" (click)="toggleSidebar('shortcutkey1')" *ngIf="!isSelfServicePortal">
				<i class="fa-light fa-command mr-75 font-small-3"></i>
				<span class="font-small-3">{{ "UI.k_shortcuts" | translate }}</span>
				<div class="text-muted font-small-3 pl-1 ml-75 pt-25">
					{{ "UI.k_view_shortcut" | translate }}
				</div>
			</div>
		</div>
		<div
			class="border-bottom cursor-pointer profileset"
			*ngIf="!isAdminPortal && isNCCMenabled && !isSupportPortal"
		>
			<div class="my-75 px-1" (click)="goToPage('nccm')" *ngIf="!isSelfServicePortal">
				<i class="fa-light fa-cog mr-75 font-small-3"></i>
				<span class="font-small-3">{{ "Message.msg_go_to_nccm" | translate }}</span>
			</div>
		</div>

		<div class="border-bottom profileset">
			<div class="my-75 px-1 cursor-pointer" type="button" id="btnLogout" (click)="logout(adminpopup)">
				<i class="fa-light fa-sign-out mr-75 font-small-3"></i>
				<span class="font-small-3">{{ "UI.k_logout" | translate }}</span>
			</div>
		</div>
		<div class="modal-header d-flex justify-content-center rounded">
			<span class="font-small-3 cursor-pointer profileset" (click)="privacyPolicyNavigation()"
				>{{ "UI.k_privacy_profile" | translate }}
			</span>
			<span class="dot mx-25"> </span>
			<span (click)="termsServicesNavigation()" class="font-small-3 cursor-pointer profileset"
				>{{ "UI.k_term_service" | translate }}
			</span>
		</div>
	</div>
</div>

<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="shortcutkey1" overlayClass="modal-backdrop">
	<app-keyboard-shortcut *ngIf="hideProfile" (onHideProfile)="onHideProfile($event)"></app-keyboard-shortcut>
</core-sidebar>
