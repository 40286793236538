<div class="misc-wrapper">
	<a class="brand-logo" href="javascript:void(0);">
		<img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="40" />
	</a>
	<div class="misc-inner p-2 p-sm-3">
		<div class="w-100 text-left">
			<article>
				<header class="entry-title text-center">
					<h2>TERMS OF USE</h2>
				</header>
				<h5 class="text-decoration-underline">Effective Date: 3rd March 2021</h5>
				<h5 class="text-decoration-underline">Last Updated on: 3rd March 2021</h5>

				<p>
					These Terms of Use <b>("Terms")</b> describe the terms under which Infraon Corp ("We," "Our," "Us")
					provide subscriber access to and use of Our Service(s) ("You," "Your," "Yourself"). By accessing
					and/or using Our Service(s), a) You agree to be bound by these Terms and acknowledge having read the
					privacy policy located at <a href="https://infraon.io/ ">https://infraon.io/ </a
					><b> ("Privacy Policy").</b> b) You warrant to us that you are of 18 years or above and are
					competent to enter into this agreement c) That, in the event, You are entering into these Terms on
					behalf of any entity/company or its group, You possess the requisite authority to bind such
					entities, Company or its groups to these Terms. If You do not agree to these Terms, You should
					immediately cease using our Service(s). You and Us will be individually referred to as
					<b>"Party"</b> and collectively as <b>"Parties.</b>
				</p>
				<ol class="ml-3">
					<li>
						<h5>YOUR RIGHTS</h5>
						<ol>
							<li>
								<p>
									Subject to Your compliance with these Terms and solely during the Subscription Term,
									You shall have the limited, non-exclusive, revocable right to access and use the
									Service(s) for your internal business purposes by the subscription plan as
									specifically stated in Our Website or an Order Form.
								</p>
							</li>
							<li>
								<p>
									We grant You a limited, personal, worldwide, non-sublicensable, non-transferable,
									non-exclusive, royalty-free license during the Subscription Term to install and
									execute Software on Your machines or network, solely to facilitate Your authorized
									access to and use of the Service(s).
								</p>
							</li>
						</ol>
					</li>

					<li>
						<h5>YOUR RESPONSIBILITIES</h5>
						<ol>
							<li>
								<p>
									<b class="text-decoration-underline">Your Account:</b> Your access and use of the
									Service(s) is restricted to the specified number of individual Users as specified in
									the relevant Order Form, if any, executed between Us and Yourself. Each User shall
									be identified using unique login information such as usernames and passwords
									<b>("User Login")</b>, and such User Login shall be used only by one individual.
								</p>
							</li>

							<li>
								<p>
									<b class="text-decoration-underline">Acceptable Use:</b> You agree not to (a)
									license, sublicense, sell, resell, rent, lease, transfer, assign, distribute, time
									share, disassemble, reverse engineer, decompile or make the Service(s) available to
									any third party, other than Users in furtherance of Your internal business purposes
									as expressly permitted by these Terms; (b) modify, adapt, or hack the Service(s) or
									otherwise attempt to gain or gain unauthorized access to the Service(s) or related
									systems or networks; (c) use the Service(s), store or transmit Sensitive Personal
									Information; (d) use the Service(s), store or transmit Customer Data in violation of
									applicable laws and regulations, including but not limited to violation of any
									person's privacy rights, export control laws/regulations; (e) access it for purposes
									of creating derivative works based on, or developing or operating products or
									services for third-parties in competition with the Service(s); (f) use the
									Service(s) to store or transmit any content that infringes upon any person's
									intellectual property rights or is unlawful, racist, hateful, abusive, libelous,
									obscene, or discriminatory; (g) use the Service(s) to knowingly post, transmit,
									upload, link to, send or store any viruses, malware, trojan horses, time bombs, or
									any other similar harmful software; (h) "crawl," "scrape," or "spider" any page,
									data, or portion of or relating to the Service(s) (through use of manual or
									automated means).
								</p>
							</li>
							<li>
								<p>
									You represent and warrant to Us that You own or have the necessary rights to
									transmit the Customer Data to Us and that doing so does not violate any applicable
									law, proprietary or privacy rights.
								</p>
							</li>
						</ol>
					</li>

					<li>
						<h5>SERVICE(S)</h5>
						<ol>
							<li>
								<p>
									You may request a demo of Our Service(s) or a trial of the Service(s) by the
									creation of accounts for trial use for a limited period of Fourteen days<span
										class="text-primary"
										>[Ma1]</span
									>(14) Trial Period"). The Trial Period shall be subject to these Terms and any
									additional terms that We specify. We, in our sole discretion, shall have the right
									to terminate the Service(s) and Your right to use the Service(s) at any time during
									the Trial Period and for any reason, without being liable to You
								</p>
							</li>
							<li>
								<p>
									Any enhancements, new features, or updates <b>("Updates")</b>to the Service(s) are
									also subject to these Terms, and We reserve the right to deploy Updates at any time
								</p>
							</li>
							<li>
								<p>
									The Service(s) may temporarily be unavailable due to scheduled downtime for upgrades
									and maintenance, in which case We shall use commercially reasonable endeavors to
									notify You in advance.
								</p>
							</li>
						</ol>
					</li>
					<li>
						<h5>INTELLECTUAL PROPERTY RIGHTS</h5>
						<ol>
							<li>
								<p>
									Except for the rights granted to You under clause 1, all rights, title, and interest
									in and to all intellectual property and/or proprietary rights title and interest in
									or related to the Service(s), including patents, inventions, copyrights, trademarks,
									domain names, trade secrets or know-how (collectively,
									<b>"Intellectual Property Rights"</b>) shall belong to and remain exclusively with
									Us and/or Our licensors.
								</p>
							</li>

							<li>
								<p>
									You will retain all rights, title, and interest in and to all intellectual property
									rights in Customer Data that You provide to Us and Customer Technology. You grant Us
									a royalty-free license and the right to use Customer Technology solely to provide
									and support the Service(s)
								</p>
							</li>
							<li>
								<p>
									You own the rights to the Customer Data that You provide to Us. We do not claim
									ownership over such Customer Data. We shall have a right and license to incorporate
									into the Service(s) or otherwise use any suggestions, enhancement requests,
									recommendations, or other feedback We receive from You.
								</p>
							</li>
							<li><p>All rights not expressly provided to You herein are reserved.</p></li>
						</ol>
					</li>
					<li>
						<h5>THIRD-PARTY SERVICES</h5>
						<ol>
							<li>
								<p>
									You acknowledge and agree that Your use of Third-party Services will be subject to
									the terms and conditions and privacy policies of such third-party and that We shall
									not be liable for Your enablement, access, or use of such Third-party Services,
									including Your data processed by a such third party. You should contact that
									Third-party service provider for any issues arising in connection with the use of
									such Third-party Service
								</p>
							</li>
						</ol>
					</li>
					<li>
						<h5>CHARGES AND PAYMENT</h5>
						<ol>
							<li>
								<p>
									Subscription Charges: All charges associated with Your Account shall be based on the
									plan You have subscribed to at the prices listed on Our Website ("Subscription
									Charges"), and any other details regarding such Subscription Charges shall be
									mentioned in an Order Form. The Subscription Charges are due in full and payable in
									advance by clause 6.2 when You subscribe to the Service(s).
								</p>
							</li>
							<li>
								<p>
									<u>Payment</u>: You hereby authorize Us or Our authorized agents, as applicable, to
									bill You upon Your subscription to the Services (and any renewal thereof) against
									advance payment.
								</p>
							</li>

							<li>
								<p>
									<u>Refunds</u>: Unless otherwise specified in these Terms, all Subscription Charges
									are non-refundable. No refunds shall be issued for partial use or non-use of the
									Service(s)
								</p>
							</li>
							<li>
								<p>
									<u>Late Payments/Non-payment of Subscription Charges:</u> We will notify You in the
									event of non-payment of Subscription Charges within the due date. We must receive
									payments within a maximum of ten (10) days from the date of Our notice. If We do not
									receive a payment within the foregoing period, in addition to our right to other
									remedies available under law, We may (i) charge interest for late payment @ 1.5% per
									month and/or; (ii) suspend Your access to and use of the Services until We receive
									Your payment towards the Subscription Charges as specified herein and/or; (iii)
									terminate Your Account.
								</p>
							</li>

							<li>
								<p>
									Applicable Taxes: Unless otherwise stated, the Subscription Charges do not include
									any taxes, levies, duties, or similar governmental assessments, including
									value-added, sales, use, or withholding taxes assessable by any local, state,
									provincial, or foreign jurisdiction (collectively <b>"Taxes"</b>).
								</p>
							</li>
						</ol>
					</li>
					<li>
						<h5>TERM, TERMINATION, AND SUSPENSION</h5>
						<ol>
							<li>
								<p>
									The Subscription Term shall be set forth on the Website or in a relevant Order Form.
								</p>
							</li>
							<li>
								<p>
									<u>Termination by You:</u> You may terminate one or more of your Account(s)on the
									occurrence of which We materially breach these Terms, provided that You shall
									provide advance notice of such breach and afford Us not less than thirty (30) days
									to cure such breach. In case of such termination, We shall, pro rata, refund the
									Subscription Charges for the remainder of the Subscription Term.
								</p>
							</li>
							<li>
								<p>
									<u>Suspension and Termination by Us:</u> In addition to suspension for late payment
									or non-payment of Subscription Charges, We may suspend Your access to and use of
									Your Account or the Service(s) if You are in violation of these Terms. We will
									notify You if your activities violate These Terms and, at Our sole discretion,
									provide You with a period of fifteen (15) days ("Cure Period") to cure or cease such
									activities. If You fail to cure or cease such activities within said Cure Period or
									if We believe that such breaches cannot be cured, Your Account shall be terminated.
									We may also terminate a Trial Period by clause 3.1. Further, We also reserve the
									right to terminate Your Account at any time by written notice due to business
									reasons which shall include discontinuation of the Service(s).
								</p>
							</li>
							<li>
								<p>
									<u>Termination for Insolvency:</u> Notwithstanding anything contained herein, either
									party may terminate these Terms with notice if the other party becomes insolvent,
									makes or has made an assignment for the benefit of creditors, is the subject of
									proceedings in voluntary or involuntary bankruptcy instituted on behalf of or
									against the such party (except for involuntary bankruptcies) which are dismissed
									within sixty (60) days, or has a receiver or trustee appointed for substantially all
									of its property.
								</p>
							</li>
							<li>
								<p>
									<u>Effect of Termination of Your Account:</u>
									Following the termination of Your Account for any reason, Your access and use of the
									Service(s) shall cease. Following the expiry of Your Trial Period, We delete
									Customer Data immediately unless You have subscribed to a pricing plan. Following
									the termination of Your Account (other than due to expiry of the Trial Period), We
									retain all Customer Data in our possession unless deleted by You. You may request Us
									to delete all Customer Data, and such Customer Data will be deleted within a period
									of Ninety (90) days. In the event of Customer Data relating to live chat history,
									the same will be deleted within a period of Ninety (90) days. ("Data Retention
									Period").
								</p>
							</li>
						</ol>
					</li>
					<li>
						<h5>CONFIDENTIALITY; DATA PRIVACY AND SECURITY</h5>
						<ol>
							<li>
								<p>
									If You choose, or are provided with, a user identification code, login, password, or
									any other piece of information as part of Our security procedures, You must treat
									such information as confidential. You must not disclose it to any third party. We
									shall have the right to disable any user identification code or password, whether
									chosen by You or allocated by Us, at any time if, in Our reasonable opinion, You
									have failed to comply with any of the provisions of these Terms. We will not be
									responsible for any activities, including any attempted or actual access or loss of
									data occurring in Your Account as a result of your non-compliance with obligations
									under this clause
								</p>
							</li>
							<li>
								<p>
									Each of the Parties will protect the other's Confidential Information from
									unauthorized use, access, or disclosure in the same manner as each of the Parties
									protects its own Confidential Information, and in any event, no less than reasonable
									care. Except as otherwise expressly permitted pursuant to these Terms, each of the
									Parties may use the other's Confidential information solely to exercise its
									respective rights and perform its respective obligations under these Terms and shall
									disclose such Confidential Information solely to those of its respective employees,
									representatives, and agents who have a need to know such Confidential Information
									for such purposes and who are bound to maintain the confidentiality of, and not
									misuse such Confidential Information. The provisions of this clause shall supersede
									any non-disclosure agreement by and between the Parties entered prior to these Terms
									that would purport to address the confidentiality of Customer Data, and such
									agreement shall have no further force or effect with respect to Customer Data
								</p>
							</li>
							<li>
								<p></p>
								We shall use appropriate technical and organizational measures to protect the Customer
								Data. The measures used are designed to provide a level of security appropriate to the
								risk of Processing Customer Data. We shall, without undue delay, notify You of any
								accidental or unlawful destruction, loss, alteration, or unauthorized disclosure of, or
								access to, the Customer Data processed by Us.
							</li>
							<li>
								<p>
									You acknowledge that We shall Process Customer Data only to provide, maintain and
									improve the Services, or prevent or address any technical problems, or at Your
									request in connection with support requests and in accordance with these Terms and
									Our Privacy Policy. We shall not Process Customer Data for any purposes other than
									what is mentioned in these Terms and Our Privacy Policy.
								</p>
							</li>
							<li>
								<p>
									You understand and acknowledge that, in connection with the use of the Service by
									Yourself, Your Users, and/or End Users, We Process any Personal Data only on Your
									behalf and as a data processor.
								</p>
							</li>
							<li>
								<p>
									You acknowledge and agree that We may access or disclose information about You, Your
									Account, and Users, including Customer Data, in order to (a) comply with the law or
									respond to lawful requests or legal processes; or (b) prevent any infringement of
									group companies' or Our
								</p>
							</li>
							<li>
								<p>
									Customers' proprietary rights. Further, at Our sole discretion, any suspected
									fraudulent, abusive, or illegal activity by You may be referred to law enforcement
									authorities.
								</p>
							</li>
							<li>
								<p>
									We shall reasonably assist You, at Your expense, in meeting Your obligations under
									applicable data protection laws
								</p>
							</li>
							<li>
								<p>
									The terms of the Data Processing Addendum form an integral part of the services
									agreement between the Customer and Company describing and governing the Customer's
									access to and use of the Service(s) (the "Agreement") and is applicable where the
									Company Processes the Customer's Personal Data forming part of the Customer Data.
								</p>
							</li>
						</ol>
					</li>

					<li>
						<h5>DISCLAIMER OF WARRANTIES</h5>
						<ol>
							<li>
								<p>
									THE SERVICE(S), INCLUDING ALL SERVER AND NETWORK COMPONENTS, ARE PROVIDED ON AN "AS
									IS" AND "AS AVAILABLE" BASIS. ALL EXPRESS OR IMPLIED REPRESENTATIONS AND WARRANTIES,
									INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
									OR NON-INFRINGEMENT, ARE HEREBY EXCLUDED.
								</p>
							</li>
							<li>
								<p>
									YOU ACKNOWLEDGE THAT WE DO NOT WARRANT THAT THE ACCESS TO THE SERVICE(S), WHICH IS
									PROVIDED OVER THE INTERNET AND VARIOUS TELECOMMUNICATIONS NETWORKS, ALL OF WHICH ARE
									BEYOND OUR CONTROL, WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR FREE FROM
									VIRUSES OR OTHER MALICIOUS SOFTWARE.
								</p>
							</li>
						</ol>
					</li>
					<li>
						<h5>LIMITATION OF LIABILITY</h5>
						<ol>
							<li>
								<p>
									TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL EITHER PARTY BE
									LIABLE TO ANY PERSON FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, COVER, OR
									CONSEQUENTIAL DAMAGES (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS, LOST
									REVENUE, LOST SALES, LOST GOODWILL, LOSS OF USE OR LOST CONTENT, IMPACT ON BUSINESS,
									BUSINESS INTERRUPTION, LOSS OF ANTICIPATED SAVINGS, LOSS OF BUSINESS OPPORTUNITY)
									HOWEVER CAUSED, UNDER ANY THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION,
									CONTRACT, TORT, WARRANTY, BREACH OF STATUTORY DUTY, NEGLIGENCE OR OTHERWISE, EVEN IF
									A PARTY HAS BEEN ADVISED AS TO THE POSSIBILITY OF SUCH DAMAGES OR COULD HAVE
									FORESEEN SUCH DAMAGES. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, OUR
									AGGREGATE LIABILITY AND THAT OF OUR AFFILIATES, OFFICERS, EMPLOYEES, AGENTS,
									SUPPLIERS, AND LICENSORS, RELATING TO THE SERVICE(S) WILL BE LIMITED TO AN AMOUNT
									EQUAL TO TWELVE MONTHS OF THE SUBSCRIPTION CHARGES PAID BY YOU FOR THE SERVICE(S)
									PRIOR TO THE FIRST EVENT OR OCCURRENCE GIVING RISE TO SUCH LIABILITY
								</p>
							</li>
							<li>
								<p>
									IN JURISDICTIONS THAT DO NOT PERMIT THE EXCLUSION OF IMPLIED WARRANTIES OR
									LIMITATION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, OUR LIABILITY WILL
									BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
								</p>
							</li>
							<li>
								<p>
									NOTWITHSTANDING ANYTHING ELSE TO THE CONTRARY, WE DISCLAIM ALL LIABILITIES, TO THE
									MAXIMUM EXTENT PERMITTED BY LAW, WITH RESPECT TO THE SERVICES OFFERED DURING THE
									TRIAL PERIOD.
								</p>
							</li>
						</ol>
					</li>
					<li>
						<h5>INDEMNIFICATION</h5>
						<ol>
							<li>
								<p>
									<u>Indemnification by You:</u> You will indemnify and hold Us harmless against any
									claim brought by a third party against Us, Our respective employees, officers,
									directors, and agents arising from Your acts or omissions in connection with clause
									2 of these Terms provided that (a) We promptly notify You of the threat or notice of
									such a claim, (b) You will have the sole and exclusive control and authority to
									select defense attorneys, defend and/or settle any such claim; and (c) We shall
									fully cooperate with You in connection therewith.
								</p>
							</li>
						</ol>
					</li>
					<li>
						<h5>MISCELLANEOUS</h5>
						<ol>
							<li>
								<p>
									<u>Assignment</u>: These Terms and any rights or obligations hereunder may not be
									assigned by You without Our prior written consent, whereas We can assign any of our
									rights and obligations hereunder without Your prior written consent. These Terms
									bind and inure to the benefit of the Parties and their respective successors and
									permitted assigns.
								</p>
							</li>
							<li>
								<p>
									<u>Amendment:</u> We may amend these Terms from time to time, in which case the new
									Terms will supersede prior versions. We will notify You not less than ten (10) days
									prior to the effective date of any amendments to these Terms, and Your continued use
									of the Service(s) following the effective date of any such amendment may be relied
									upon by Us as Your acceptance of any such amendment.
								</p>
							</li>
							<li>
								<p>
									<u>Severability; No Waiver:</u>If any provision in these Terms is held by a court of
									competent jurisdiction to be unenforceable, such provision shall be modified by the
									court and interpreted so as to best accomplish the original provision to the fullest
									extent permitted by applicable law, and the remaining provisions of these Terms
									shall remain in effect. Our non-exercise of any right under or provision of these
									Terms does not constitute a waiver of that right or provision of These Terms.
								</p>
							</li>
							<li>
								<p>
									<u>Relationship of the Parties:</u> The Parties are independent contractors. These
									Terms do not create a partnership, franchise, joint venture, agency, fiduciary, or
									employment relationship among the Parties.
								</p>
							</li>
							<li>
								<p>
									<u>Survival:</u> All clauses which, by their nature, are intended to survive,
									including without limitation Clauses 4 (Intellectual Property Rights), 6 (Charges
									and Payment), 7 (Term, Suspension, and Termination), 8 (Confidentiality, Data
									Privacy, and Security), 9 (Disclaimer of Warranties), 10 (Limitation of Liability),
									11 (Indemnification), 12 (Miscellaneous) and 13 (Definitions) shall survive any
									termination of Our agreement with Yourself regarding the use of the Service(s).
									Termination shall not limit either party's liability for obligations accrued as of
									or prior to such termination or for any breach of These Terms
								</p>
							</li>
							<li>
								<p>
									Notices and Consent to Electronic Communications: All notices from Us under these
									Terms may be delivered in writing (i) by a nationally recognized overnight delivery
									service ("Courier") or to the contact mailing address provided by You while
									subscribing to the Service(s); or (ii) electronic mail to the e-mail address
									provided to Your Account. Our address for notice is Infraon Corp, 611 Gateway Blvd
									Suite 120, South San Francisco, California, 94080, United States, at
									<a href="mailto: privacy@infraon.io">(satish@infraon.io)</a> with a CC to
									<a href="mailto: privacy@infraon.io">arun@infraon.io</a> by electronic mail. All
									notices shall be deemed to have been given immediately upon delivery by electronic
									mail, or if otherwise delivered upon receipt or, if earlier, two (2) business days
									after being deposited in the mail or with a Courier as permitted above.
								</p>
							</li>
							<li>
								<p>
									<u>Publicity Rights:</u> You hereby grant Us a right and license to use Your
									trademark or logo to identify You as Our Customer on Our websites and/or marketing
									collateral and to include Your use of the Services in case studies
								</p>
							</li>
							<li>
								<p>
									<u>Governing Law and Dispute Resolution:</u> These Terms shall be governed by the
									laws of the State of Delaware without regard to any conflict of laws principles. You
									hereby expressly agree to submit to the exclusive personal jurisdiction of the
									courts in Delaware. Any dispute, claim, or controversy arising out of or relating to
									these Terms or the breach, termination, enforcement, interpretation, or validity
									thereof, including the determination of the scope or applicability of these Terms to
									arbitrate, shall be settled by arbitration administered by the American Arbitration
									Association in accordance with its commercial arbitration rules
									<b>("AAA Rules")</b>, and judgment on the award rendered by the arbitrator may be
									entered in any court having jurisdiction thereof. The language of the arbitration
									shall be English. The dispute shall be resolved by a sole arbitrator who shall be
									appointed in accordance with the AAA Rules. The decision of the sole arbitrator
									shall be final and binding on the Parties.
								</p>
							</li>
							<li>
								<p>
									<u>Entire Agreement:</u> These Terms, together with any Order Forms, constitute the
									entire agreement and supersede any and all prior agreements between Us and Yourself
									with regard to the subject matter hereof. In the event of a conflict between the
									terms of any Order Form and these Terms, the Terms shall prevail. In case of a
									conflict between two Order Forms, the Order Form later in date shall prevail.
								</p>
							</li>
							<li>
								<p>
									<u>Force Majeure:</u> Notwithstanding anything to the contrary contained elsewhere,
									We shall not be liable for unavailability of the Service(s) caused by circumstances
									beyond Our reasonable control, such as but not limited to, acts of God, acts of
									government, acts of terror or civil unrest, epidemics or pandemics, technical
									failures beyond Our reasonable control (including, without limitation, inability to
									access the internet, unauthorized loss, distribution or dissemination of Customer
									Data), or acts undertaken by third parties, including without limitation,
									distributed denial of Service attacks.
								</p>
							</li>
						</ol>
					</li>
					<li>
						<h5>DEFINITIONS</h5>
						When used in these Terms with the initial letters capitalized, in addition to terms defined
						elsewhere in these Terms, the following terms have the following meanings:
						<p>
							<b>Account:</b> means any accounts or instances created by You or on Your behalf for access
							and use of the Services.
						</p>
						<p>
							<b>API:</b> means the application programming interfaces developed, enabled by, or licensed
							to Us that permits access to certain functionality provided by the Service(s)
						</p>
						<p>
							<b>Confidential information:</b> means all information disclosed by one party to the other
							party which is in tangible form and labeled "confidential" (or with a similar legend) or
							which a reasonable person would understand to be confidential given the nature of the
							information and circumstances of disclosure. For purposes of These Terms, Customer Data
							shall be deemed Confidential Information. Notwithstanding the foregoing, Confidential
							Information shall not include any information which (a) was publicly known and made
							generally available in the public domain prior to the time of disclosure by the disclosing
							party; (b) becomes publicly known and made generally available after disclosure by the
							disclosing party to the receiving party through no action or inaction of the receiving
							party; (c) is already in the possession of the receiving party at the time of disclosure by
							the disclosing party as shown by the receiving party's files and records prior to the time
							of disclosure; (d) is obtained by the receiving party from a third party without a breach of
							such third party's obligations of confidentiality; (e) is independently developed by the
							receiving party without use of or reference to the disclosing party's Confidential
							Information, as shown by documents and other competent evidence in the receiving party's
							possession; or (f) is required by law to be disclosed by the receiving party, provided that
							the receiving party shall, to the extent legally permitted, give the disclosing party
							written notice of such requirement prior to disclosing so that the disclosing party may seek
							a protective order or other appropriate relief.
						</p>
						<p>
							<b>Customer Data:</b> means all electronic data, text, messages, personal data, or other
							materials, including without limitation Personal Data of Users and End Users, submitted to
							the Services by You through Your Account in connection with Your use of the Services.
						</p>
						<p>
							<b>Customer Technology:</b> means software, methodologies, templates, business processes,
							documentation, or other material originally authored, invented, or otherwise created by or
							for Customer (but not by or for Us) for use with the Service(s), excluding the Service(s).
						</p>

						<p>
							<b>Documentation:</b> means any written or electronic documentation, images, video, text, or
							sounds specifying the functionalities of the Service(s) provided or made available by Us to
							You or Your Users through the Service(s) or otherwise.
						</p>

						<p>
							<b>End User:</b> means any person or entity other than You or Your Users with whom You
							interact using the Service(s).
						</p>
						<p>
							<b>Order Form:</b> means any service order form or statement of work specifying the
							Service(s) subscribed to, particular features and functionalities in the Service(s) that You
							wish to avail, and the Subscription Term.
						</p>
						<p>
							<b>Personal Data:</b> means data relating to a living individual who is or can be identified
							either from the data or from the data in conjunction with other information that is in, or
							is likely to come into, the possession of the data controller.
						</p>
						<p>
							<b>Processing/To Process:</b> means any operation or set of operations which is performed
							upon Personal Data, whether or not by automatic means, such as collection, recording,
							organization, storage, adaptation or alteration, retrieval, consultation, use, disclosure by
							transmission, dissemination or otherwise making available, alignment or combination,
							blocking, erasure or destruction.
						</p>
						<p>
							<b>Sensitive Personal Information</b> means information that relates to an individual's
							racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union
							membership, genetic data, biometric data for the purpose of uniquely identifying a natural
							person, data concerning health, or data concerning a natural person's sex life or sexual
							orientation. It also includes information about an individual's criminal offenses or
							convictions, as well as any other information deemed sensitive under applicable data
							protection laws.
						</p>
						<p>
							<b>Service(s):</b> means the cloud system of Infraon and any new services that We may
							introduce as a Service to which You may subscribe, and any updates, modifications, or
							improvements thereto, including individually and collectively, Software, the API, and any
							Documentation.
						</p>
						<p>
							<b>Software:</b>
							means software provided by Us (either by download or access through the internet) that
							allows Customer to use any functionality in connection with the Service(s).
							<b>Subscription Term: </b>means the period during which You have agreed to subscribe to the
							Service(s) specified in the Website or in a relevant Order Form
						</p>
						<p>
							<b>Third-party Service(s)</b> shall mean third-party application(s) or service(s)
							integrating with the Service(s) through APIs.
						</p>

						<p>
							<b>User:</b> means those who are designated users within the Service(s), including an
							Account administrator, agents, and other designated users.
						</p>
					</li>
					<h5>Website(s) shall mean the websites owned and operated by Us.</h5>
				</ol>
			</article>
		</div>
	</div>
</div>
