import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BotsConfigurationGridComponent } from './components/bots-configuration-grid/bots-configuration-grid.component';
import { NavbarModule } from 'app/common/layout/components/navbar/navbar.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule } from '@core/components';
import { CoreDirectivesModule } from '@core/directives/directives';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SharedModule } from 'app/shared.module';
import { BotsConfigurationRoutingModule } from './bots-configuration-routing.module';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CreateBotComponent } from './components/create-bot/create-bot.component';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { FilterPipe } from './filter.pipe';
import { FileUploadModule } from 'ng2-file-upload';
import { SortPipe } from './sort.pipe';




@NgModule({
  declarations: [
    BotsConfigurationGridComponent,
    CreateBotComponent,
    FilterPipe,
    SortPipe
  ],
  imports: [
    CommonModule,
    NavbarModule,
    CoreCommonModule,
    CoreSidebarModule,
    CoreDirectivesModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    SweetAlert2Module.forRoot(),
    SharedModule,
    BotsConfigurationRoutingModule,
    NgApexchartsModule,
    NgScrollbarModule,
    CorePipesModule,
    FileUploadModule,

    
  ]
})
export class BotsConfigurationModule { }
