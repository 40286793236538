<div class="slideout-content default-slideout">
    <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
        <div class="modal-dialog sidebar-md">
            <div *ngIf="request_data != undefined" class="modal-content pt-0">
                <div class="modal-header">
                    <h5 class="modal-title" id="resolveReq">
                        {{ "UI.k_resolve" | translate }}
                    </h5>
                        <button
                        type="button"
                        id="btnResolve"
                        name="btnResolve"
                        [attr.data-testid]="'btnResolve'"
                        class="btn btn-flat-secondary btn-icon"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="toggleSidebar('resolve-request')"
                    >
                        <i class="fa-light fa-xmark"></i>
                    </button>
                </div>
                <form #resolvedRequestForm="ngForm" class="modal-body">
                    <div class="flex-auto-height">
                        <ng-scrollbar class="custom-scrollbar section-scrollbar">
                            <div class="modal-body-inner p-1">
                                <div class="card border border-rounded">
                                    <div class="summary-info p-1">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex justify-content-start align-items-center">
                                                <div>
                                                    <span class="fw-500 reg-font primary-color">{{
                                                        this.request_data?.display_id
                                                    }}</span>
                                                </div>
                                                <div class="rounded icon-class mx-50">
                                                    <div class="resolve-card-config" ngbTooltip ="{{ request_data?.basic_info?.request_source?.name }}"
                                                    placement="bottom"
                                                    id="btnGlobe">
                                                        <i
                                                            class="fa-light fa-{{
                                                                sourceIconMap[
                                                                    request_data?.basic_info?.request_source?.id
                                                                ]
                                                            }}"
                                                        ></i>
                                                    </div>
                                                </div>
                                                <div
                                                    *ngIf="request_data?.is_parent_request"
                                                    class="rounded"
                                                    id="btnShare"
                                                >
                                                    <div class="resolve-card-config"  placement="right" container="body"
                                                    ngbTooltip ="{{ 'UI.k_request_source' | translate }}">
                                                        <i class="fa-light fa-share-nodes"></i>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                class="mx-50"
                                                *ngIf="this.request_data?.basic_info?.priority?.name"
                                            >
                                                <!-- <div *ngIf="this.request_data?.basic_info?.priority?.name"> -->
                                                <div
                                                    class="badge badge-light-{{
                                                        request_data?.basic_info?.priority?.color
                                                    }}"
                                                >
                                                    <!-- <span class="sm-font fw-500">Priority:</span> -->
                                                    {{
                                                        this.request_data?.basic_info?.priority?.display_key
                                                            | translate
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex justify-content-start align-items-center">
                                                <div *ngIf="request_data?.basic_info?.impact_service_tree_path">
                                                    <span class="sm-font"
                                                        >{{ "UI.k_service" | translate }} :
                                                    </span>
                                                    <span
                                                        class="sm-font"
                                                        title="{{ request_data?.basic_info?.impact_service_tree_path }}"
                                                    >
                                                        {{ request_data?.basic_info?.impact_service_tree_path | slice: 0:19
                                                        }}{{
                                                            request_data?.basic_info?.impact_service_tree_path?.length > 19
                                                                ? ".."
                                                                : ""
                                                        }}</span
                                                    >
                                                </div>

                                                <div *ngIf="request_data?.asset_id && request_data?.cmdb_id">
                                                    <span class="sm-font"
                                                        >{{ "UI.k_asset_id" | translate }} :
                                                    </span>
                                                    <span
                                                        *ngIf="asset_permissions?.view && request_data?.is_asset_disabled"
                                                    >
                                                        <a
                                                            href="/it-asset/asset/{{ request_data?.cmdb_id }}"
                                                            target="_blank"
                                                            class="sm-font"
                                                            title="{{ request_data?.asset_id }}"
                                                        >
                                                            {{ request_data?.asset_id | slice: 0:19
                                                            }}{{
                                                                request_data?.asset_id?.length > 19 ? ".." : ""
                                                            }}</a
                                                        >
                                                    </span>
                                                    <span
                                                        *ngIf="!asset_permissions?.view || !request_data?.is_asset_disabled"
                                                        class="sm-font"
                                                        title="{{ request_data?.asset_id }}"
                                                    >
                                                        {{ request_data?.asset_id | slice: 0:19
                                                        }}{{
                                                            request_data?.asset_id?.length > 19 ? ".." : ""
                                                        }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="my-50 d-flex justify-content-start align-items-center">
                                            <div class="avatar avatar-xs mr-50">
                                                <!-- <ng-container > -->
                                                <span
                                                    class="avatar-content" *ngIf="!request_data?.requester_profile"
                                                    [ngStyle]="{
                                                        'background-color':
                                                            request_data?.requester_avatar_color != undefined
                                                                ? request_data?.requester_avatar_color
                                                                : '#f59518'
                                                    }"
                                                    >{{
                                                        this.request_data?.requester?.full_name
                                                            | initials
                                                            | slice: 0:2
                                                    }}</span
                                                >
                                                <!-- </ng-container> -->
                                                <ng-container *ngIf="request_data?.requester_profile">
                                                    <img
                                                        class="avatar-content"
                                                        class=""
                                                        [src]="request_data?.requester_profile"
                                                        alt=""
                                                    />
                                                </ng-container>
                                            </div>

                                            <span
                                                class="sm-font fw-500 title-color summary-ellipse"
                                                ngbTooltip="{{ request_data?.basic_info?.summary  | slice: 0:200 }}"
                                                placement="bottom"
                                                >{{ request_data?.basic_info?.summary | slice: 0:150 }}
                                                {{
                                                    request_data?.basic_info?.summary.length > 150 ? ".." : ""
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div >
                                    <div class="form-group col-xs-7 col-md-7 col-xl-7 col-lg-7 p-0">
                                        <!-- <label class="sm-font" for="selStatus">{{
                                "UI.k_status" | translate
                            }}</label> -->
                                        <app-status-picker
                                            [inputConfig]="{ isTextMode: true, stateOptionsMap:  options?.state_id_map}"
                                            [options]="resolved_status"
                                            (setStatus)="onStatusChange($event)"
                                            [value]="request_data.basic_info?.status?.id"
                                        ></app-status-picker>
                                    </div>

                                    <div class="form-group col-xs-6 col-md-6 col-xl-6 col-lg-6 p-0">
                                        <label for="resolution_date" class="sm-font"
                                            >{{ "UI.k_resolution" | translate }} Date<span
                                                class="required-field"
                                                >&nbsp;*</span
                                            ></label
                                        >
                                        <div>
                                            <ng2-flatpickr
                                                [config]="dateTimeOptions"
                                                name="smartGridResolutionDate"
                                                id="smartGridResolutionDate"
                                                [setDate]="request_data?.request_resolution_time ? request_data?.request_resolution_time : request_data['request_resolution_time'] = dateTimeOptions?.defaultDate"
                                                [(ngModel)]="request_data.request_resolution_time"
                                                placeholder="{{ 'UI.k_request_resolution_time' | translate }}"
                                                class="my-class"
                                                #TDReqSmartResolveDateRef="ngModel"
                                                required
                                            >
                                            </ng2-flatpickr>
                                            <div
                                                *ngIf="
                                                    resolvedRequestForm.submitted &&
                                                    TDReqSmartResolveDateRef.invalid
                                                "
                                                class="invalid-form"
                                            >
                                                <small
                                                    class="form-text text-danger xs-font"
                                                    *ngIf="TDReqSmartResolveDateRef.errors.required"
                                                    >{{ "UI.k_field_required" | translate }}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 p-0">
                                        <div class="form-group">
                                            <label for="basic-search" class="sm-font">
                                                {{ "UI.k_resolution" | translate
                                                }}<span class="required-field">&nbsp;*</span>
                                            </label>
                                            <div class="card p-0 pb-50">
                                                <quill-editor
                                                    [(ngModel)]="request_data.resolution_comment"
                                                    #TDReqGridResolveCmnt
                                                    id="txtAreaReqGridResolve"
                                                    name="txtAreaReqGridResolve"
                                                    [required]="true"
                                                    [trimOnValidation]="true"
                                                    [appQuillValidator]="TDReqGridResolveCmnt"
                                                    [appQuillForm]="resolvedRequestForm"
                                                    [minLength]="3"
                                                    [class.error]="
                                                        TDReqGridResolveCmnt?.errors &&
                                                        resolvedRequestForm.submitted
                                                    "
                                                    placeholder="{{ 'UI.k_insert_text_here' | translate }}..."

                                                >
                                                    <div quill-editor-toolbar class="px-0 py-50">
                                                        <div class="p-0">
                                                            <span class="ql-formats pb-50">
                                                                <button class="ql-bold" title="{{ 'UI.k_bold' | translate }}"></button>
                                                                <button class="ql-italic" title="{{ 'UI.k_italic' | translate }}"></button>
                                                                <button
                                                                    class="ql-underline"
                                                                    title="{{ 'UI.k_underline' | translate }}"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <select class="ql-align" title="{{ 'UI.k_alignment' | translate }}">
                                                                    <option selected></option>
                                                                    <option value="center"></option>
                                                                    <option value="right"></option>
                                                                    <option value="justify"></option>
                                                                </select>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    class="ql-list"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    class="ql-link"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                                <button
                                                                    class="ql-image"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                                <button
                                                                    class="ql-video"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    class="ql-formula"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                                <button
                                                                    class="ql-code-block"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                            </span>
                                                            <span class="ql-formats">
                                                                <button
                                                                    class="ql-clean"
                                                                    value="ordered"
                                                                    type="button"
                                                                ></button>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </quill-editor>
                                            </div>
                                            <div>
                                                <span
                                                    class="row col-12 error"
                                                    *ngIf="
                                                        resolvedRequestForm.submitted &&
                                                        TDReqGridResolveCmnt?.errors
                                                    "
                                                    class="invalid-form"
                                                >
                                                    <small
                                                        *ngIf="
                                                            TDReqGridResolveCmnt?.errors?.requiredError
                                                                ?.empty
                                                        "
                                                        class="form-text text-danger xs-font"
                                                        >{{ "UI.k_field_required" | translate }}</small
                                                    >
                                                    <small
                                                        *ngIf="
                                                            TDReqGridResolveCmnt?.errors?.minLengthError
                                                        "
                                                        class="form-text text-danger xs-font"
                                                        >{{ "Error.err_min_3_character" | translate }}</small
                                                    >
                                                </span>
                                            </div>
                                        </div>
                                            <app-dns-custom-field
                                                [formRef]="resolvedRequestForm"
                                                placeholder="resolution"
                                                [data]="request_data?.custom_field_data"
                                                [config]="req_extra_info?.custom_field_config"
                                            ></app-dns-custom-field>  
                                    </div>
                                </div>
                            </div>
                        </ng-scrollbar>
                        <div class="modal-btn-group flex-initial border-top">
                            <div class="d-flex justify-content-end stepper-btn flex-initial">
                                <button
                                    class="btn btn-sm btn-primary btn-next ml-1"
                                    id="btnComplete"
                                    name="btnComplete"
                                    [attr.data-testid]="'btnComplete'"
                                    rippleEffect
                                    type="submit"
                                    (click)="onResolveSubmit(resolvedRequestForm, request_data)"
                                    [disabled]="is_submit_disabled"
                                >
                                    <span class="align-middle d-sm-inline-block d-none">
                                        {{ "UI.k_resolve" | translate }}</span
                                    >
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
