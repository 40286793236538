<div class="misc-wrapper">
	<a class="brand-logo" href="javascript:void(0);">
		<img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" height="40" />
	</a>
	<div class="misc-inner p-2 p-sm-3">
		<div class="w-100 text-left">
			<article>
				<header class="entry-title text-center">
					<h2>PRIVACY POLICY</h2>
				</header>
				<div class="entry-content">
					<p>Effective date: 2021-03-03</p>
					<ol type="1">
						<li>
							<strong>Introduction</strong>
							<p class="my-2">Welcome to <strong>Infraon Corp.</strong></p>

							<p class="mb-1">
								<strong>Infraon Corp </strong>(“us”, “we”, or “our”) operates
								<strong>infraon.io</strong> (hereinafter referred to as <strong>“Service”</strong>).
							</p>

							<p>
								Our Privacy Policy governs your visit to <strong>infraon.io</strong>and explains how we
								collect, safeguard and disclose information that results from your use of our Service.
							</p>
							<br />
							<p>
								We use your data to provide and improve Service. By using the Service, you agree to the
								collection and use of information in accordance with this policy. Unless otherwise
								defined in this Privacy Policy, the terms used in this Privacy Policy have the same
								meanings as in our Terms and Conditions.
							</p>
							<br />
							<p>
								Our Terms and Conditions <strong>(“Terms”)</strong> govern all use of our Service and
								together with the Privacy Policy constitute your agreement with us
								<strong>(“Agreement”)</strong>.
							</p>
						</li>
						<li>
							<strong>Definitions</strong>
						</li>

						<p class="my-2">
							<strong>SERVICE</strong> means the infraon.io website operated by Infraon Corp.
						</p>
						<p>
							<strong>PERSONAL DATA</strong> means data about a living individual who can be identified
							from those data (or from those and other information either in our possession or likely to
							come into our possession).
						</p>

						<p>
							<strong> USAGE DATA </strong>is data collected automatically either generated by the use of
							the Service or from the Service infrastructure itself (for example, the duration of a page
							visit)
						</p>

						<p>
							<strong>COOKIES</strong> are small files stored on your device (computer or mobile device).
						</p>
						<p>
							<strong>DATA CONTROLLER</strong> means a natural or legal person who (either alone or
							jointly or in common with other persons) determines the purposes for which and the manner in
							which any personal data are, or are to be, processed. For the purpose of this Privacy
							Policy, we are a Data Controller of your data.
						</p>
						<p>
							<strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> means any natural or legal person
							who processes the data on behalf of the Data Controller. We may use the services of various
							Service Providers to process your data more effectively.
						</p>

						<p>
							<strong>DATA SUBJECT </strong> is any living individual who is the subject of Personal Data.
						</p>

						<p>
							<strong>THE USER</strong> is the individual using our Service. The User corresponds to the
							Data Subject, who is the subject of Personal Data.
						</p>

						<li><strong>Information Collection and Use</strong></li>
						<p>
							We collect several different types of information for various purposes to provide and
							improve our Service to you
						</p>

						<li><strong>Types of Data Collected</strong></li>
						<h5 class="my-1">Personal Data</h5>

						<p>
							While using our Service, we may ask you to provide us with certain personally identifiable
							information that can be used to contact or identify you (“Personal Data”). Personally
							identifiable information may include, but is not limited to:
						</p>

						<div>
							<p>0.1. &nbsp; Email address</p>
							<p>0.2. &nbsp;First name and last name</p>
							<p>0.3. &nbsp;Phone number</p>
							<p>0.4. &nbsp;Address, Country, State, Province, <b>ZIP</b>/Postal code, City</p>
							<p>0.5. &nbsp;Cookies and Usage Data</p>
						</div>

						<p class="my-1">
							We may use your Personal Data to contact you with newsletters, marketing or promotional
							materials, and other information that may be of interest to you. You may opt-out of
							receiving any, or all, of these communications from us by following the unsubscribe link.
						</p>

						<h5>Usage Data</h5>

						<p>
							We may also collect information that your browser sends whenever you visit our Service or
							when you access the Service by or through any device <strong>(“Usage Data”)</strong>.
						</p>
						<p>
							This Usage Data may include information such as your computer’s Internet Protocol address
							(e.g. IP address), browser type, browser version, the pages of our Service that you visit,
							the time and date of your visit, the time spent on those pages, unique device identifiers
							and other diagnostic data.
						</p>

						<p>
							When you access Service with a device, this Usage Data may include information such as the
							type of device you use, your device unique ID, the IP address of your device, your device
							operating system, the type of Internet browser you use, unique device identifiers and other
							diagnostic data.
						</p>

						<h5>Single Sign-On</h5>

						<p>
							You can log in to our site and application using sign-in services such as an Open ID
							provider. These services will authenticate your identity and provide you the option to share
							certain personal information with us such as your name and email address to pre-populate our
							sign-up form.
						</p>

						<h5>Location Data</h5>

						<p>
							We may use and store information about your location if you permit us to do so
							<strong>(“Location Data”)</strong>. We use this data to provide features of our Service and
							to improve and customize our Service.
						</p>

						<p>
							You can enable or disable location services when you use our Service at any time by way of
							your device settings.
						</p>

						<h5>Tracking Cookies Data</h5>
						<p>
							We use cookies and similar tracking technologies to track the activity on our Service and we
							hold certain information.
						</p>

						<p>
							Cookies are files with a small amount of data which may include an anonymous unique
							identifier. Cookies are sent to your browser from a website and stored on your device. Other
							tracking technologies are also used such as beacons, tags, and scripts to collect and track
							information and to improve and analyze our Service.
						</p>

						<p>
							You can instruct your browser to refuse all cookies or to indicate when a cookie is being
							sent. However, if you do not accept cookies, you may not be able to use some portions of our
							Service
						</p>

						<p>Examples of Cookies we use:</p>

						<div>
							<div class="d-flex align-items-center justify-content-start mb-1">
								0.1. <strong> &nbsp; Session Cookies: </strong> &nbsp; We use Session Cookies to operate
								our Service.
							</div>
							<div class="d-flex align-items-center justify-content-start mb-1">
								0.2. <strong> &nbsp; Preference Cookies: </strong>&nbsp;We use Preference Cookies to
								remember your preferences and various settings.
							</div>
							<div class="d-flex align-items-center justify-content-start mb-1">
								0.3.<strong> &nbsp; Security Cookies: </strong>&nbsp; We use Security Cookies for
								security purposes.
							</div>
							<div class="d-flex align-items-center justify-content-start mb-1">
								0.4. <strong> &nbsp; Advertising Cookies: </strong> &nbsp; Advertising Cookies are used
								to serve you with advertisements that may be relevant to you and your interests.
							</div>
						</div>
						<h5>Other Data</h5>

						<p>
							While using our Service, we may also collect the following information: like Name, email
							address, phone/mobile number, organization name, and billing address.
						</p>

						<li><strong>Use of Data</strong></li>
						<p class="my-1">Infraon Corp uses the collected data for various purposes:</p>
						<div>
							<p>0.1. &nbsp;to provide and maintain our Service;</p>
							<p>0.2. &nbsp;to notify you about changes to our Service;</p>
							<p>
								0.3. &nbsp;to allow you to participate in interactive features of our Service when you
								choose to do so;
							</p>
							<p>0.4. &nbsp;to provide customer support;</p>
							<p>
								0.5. &nbsp;to gather analysis or valuable information so that we can improve our
								Service;
							</p>
							<p>0.6 &nbsp;to monitor the usage of our Service;</p>
							<p>0.7. &nbsp;to detect, prevent and address technical issues;</p>
							<p>0.8. &nbsp;to fulfill any other purpose for which you provide it;</p>
							<p>
								0.9. &nbsp;to carry out our obligations and enforce our rights arising from any
								contracts entered into between you and us, including for billing and collection;
							</p>
							<p>
								0.10. &nbsp;to provide you with notices about your account and/or subscription,
								including expiration and renewal notices, email instructions, etc.;
							</p>
							<p>
								0.11. &nbsp;to provide you with news, special offers, and general information about
								other goods, services, and events that we offer that are similar to those that you have
								already purchased or enquired about unless you have opted not to receive such
								information;
							</p>
							<p>0.12. &nbsp; in any other way we may describe when you provide the information;</p>
							<p>0.13. &nbsp; for any other purpose with your consent.</p>
						</div>

						<li><strong>Retention of Data</strong></li>

						<p class="my-1">
							We will retain your Personal Data only for as long as is necessary for the purposes set out
							in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to
							comply with our legal obligations (for example, if we are required to retain your data to
							comply with applicable laws), resolve disputes, and enforce our legal agreements and
							policies.
						</p>

						<p>
							We will also retain Usage Data for internal analysis purposes. Usage Data is generally
							retained for a shorter period, except when this data is used to strengthen the security or
							to improve the functionality of our Service, or we are legally obligated to retain this data
							for longer periods.
						</p>

						<li><strong>Transfer of Data</strong></li>
						<p>
							Your information, including Personal Data, may be transferred to – and maintained on –
							computers located outside of your state, province, country, or other governmental
							jurisdiction where the data protection laws may differ from those of your jurisdiction.
						</p>

						<p>
							If you are located outside India and choose to provide information to us, please note that
							we transfer the data, including Personal Data, to the US/India/Europe/other parts of the
							world based on the data store location selected by you and process it there.
						</p>

						<p>
							Your consent to this Privacy Policy followed by your submission of such information
							represents your agreement to that transfer.
						</p>

						<p>
							Infraon Corp will take all the steps reasonably necessary to ensure that your data is
							treated securely and in accordance with this Privacy Policy and no transfer of your Personal
							Data will take place to an organization or a country unless there are adequate controls in
							place including the security of your data and other personal information.
						</p>

						<li><strong>Disclosure of Data</strong></li>

						<p>We may disclose personal information that we collect, or you provide:</p>

						<p>0.1. &nbsp; <b>Disclosure for Law Enforcement.</b></p>
						<p>
							Under certain circumstances, we may be required to disclose your Personal Data if required
							to do so by law or in response to valid requests by public authorities.
						</p>

						<p><strong>0.2. &nbsp;Business Transaction.</strong></p>
						<p>
							If we or our subsidiaries are involved in a merger, acquisition, or asset sale, your
							Personal Data may be transferred.
						</p>

						<p><b>0.3. &nbsp;Other cases. We may disclose your information also:</b></p>

						<p>0.3.1. &nbsp; to our subsidiaries and affiliates;</p>
						<p>
							0.3.2.&nbsp; to contractors, service providers, and other third parties we use to support
							our business;
						</p>
						<p>0.3.3. &nbsp; to fulfill the purpose for which you provide it;</p>
						<p>0.3.4. &nbsp; include your company’s logo on our website</p>
						<p>0.3.5. &nbsp; for any other purpose disclosed by us when you provide the information;</p>
						<p>0.3.6. &nbsp; with your consent in any other cases;</p>
						<p>
							0.3.7. &nbsp; if we believe disclosure is necessary or appropriate to protect the rights,
							property, or safety of the Company, our customers, or others
						</p>

						<li><strong>Security of Data</strong></li>
						<p class="my-1">
							The security of your data is important to us but remember that no method of transmission
							over the Internet or method of electronic storage is 100% secure. While we strive to use
							commercially acceptable means to protect your Personal Data, we cannot guarantee its
							absolute security.
						</p>

						<li>
							<strong>
								Your Data Protection Rights Under General Data Protection Regulation (GDPR)</strong
							>
						</li>
						<p class="my-1">
							If you are a resident of the European Union (EU) and European Economic Area (EEA), you have
							certain data protection rights, covered by GDPR.
						</p>
						<p>
							We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of
							your Personal Data.
						</p>

						<p>
							If you wish to be informed what Personal Data we hold about you and if you want it to be
							removed from our systems, please email us at
							<b><a href="mailto: privacy@infraon.io">privacy@infraon.io</a></b>
						</p>

						<p>In certain circumstances, you have the following data protection rights:</p>

						<p>0.1. &nbsp; the right to access, update, or delete the information we have on you;</p>
						<p>
							0.2. &nbsp; the right of rectification. You have the right to have your information
							rectified if that information is inaccurate or incomplete
						</p>
						<p>
							0.3. &nbsp; the right to object. You have the right to object to our processing of your
							Personal Data;
						</p>
						<p>
							0.4. &nbsp; the right of restriction. You have the right to request that we restrict the
							processing of your personal information;
						</p>
						<p>
							0.5. &nbsp;the right to data portability. You have the right to be provided with a copy of
							your Personal Data in a structured, machine-readable, and commonly used format;
						</p>
						<p>
							0.6. &nbsp; the right to withdraw consent. You also have the right to withdraw your consent
							at any time when we rely on your consent to process your personal information;
						</p>

						<p>
							Please note that we may ask you to verify your identity before responding to such requests.
							Please note, we may not able to provide Service without some necessary data.
						</p>
						<p>
							You have the right to complain to a Data Protection Authority about our collection and use
							of your Personal Data. Please contact your local data protection authority in the European
							Economic Area (EEA) for more information.
						</p>

						<li>
							<strong
								>Your Data Protection Rights under the California Privacy Protection Act (CalOPPA)
							</strong>
						</li>
						<p class="my-1">
							CalOPPA is the first state law in the nation to require commercial websites and online
							services to post a privacy policy. The law’s reach stretches well beyond California to
							require a person or company in the United States (and conceivable the world) that operates
							websites collecting personally identifiable information from California consumers to post a
							conspicuous privacy policy on its website stating exactly the information being collected
							and those individuals with whom it is being shared and to comply with this policy.
						</p>

						<p>According to CalOPPA, we agree to the following:</p>

						<p>0.1. &nbsp; users can visit our site anonymously</p>
						<p>
							0.2. &nbsp; our Privacy Policy link includes the word “Privacy”, and can easily be found on
							the home page of our website;
						</p>
						<p>
							0.3. &nbsp;users will be notified of any privacy policy changes on our Privacy Policy Page;
						</p>
						<p>
							0.4. &nbsp;users can change their personal information by emailing us at
							<a href="mailto: privacy@infraon.io"><b>privacy@infraon.io</b></a
							>.
						</p>
						<p>Our Policy on “Do Not Track” Signals:</p>
						<p>
							We honor Do Not Track signals and do not track plant cookies, or use advertising when a Do
							Not Track browser mechanism is in place. Do Not Track is a preference you can set in your
							web browser to inform websites that you do not want to be tracked.
						</p>

						<p>
							You can enable or disable Do Not Track by visiting the Preferences or Settings page of your
							web browser.
						</p>
						<li>
							<strong
								>Your Data Protection Rights under the California Consumer Privacy Act (CCPA)</strong
							>
						</li>
						<p class="my-1">
							If you are a California resident, you are entitled to learn what data we collect about you,
							ask to delete your data, and not to sell (share) it. To exercise your data protection
							rights, you can make certain requests and ask us:
						</p>

						<p>
							<b
								>0.1. &nbsp; What personal information do we have about you? If you make this request,
								we will return to you:</b
							>
						</p>
						<p>0.0.1. &nbsp;The categories of personal information we have collected about you</p>
						<p>0.0.2. &nbsp; The categories of sources from which we collect your personal information.</p>
						<p>
							0.0.3. &nbsp; The business or commercial purpose for collecting or selling your personal
							information.
						</p>
						<p>0.0.4. &nbsp; The categories of third parties with whom we share personal information</p>
						<p>0.0.5. &nbsp; The specific pieces of personal information we have collected about you.</p>
						<p>
							0.0.6 &nbsp; A list of categories of personal information that we have sold, along with the
							category of any other company we sold it to. If we have not sold your personal information,
							we will inform you of that fact.
						</p>
						<p>
							0.0.7.&nbsp; A list of categories of personal information that we have disclosed for a
							business purpose, along
						</p>
						<p>
							Please note, you are entitled to ask us to provide you with this information up to two times
							in a rolling twelve-month period. When you make this request, the information provided may
							be limited to the personal information we collected about you in the previous 12 months
						</p>

						<p>
							<b
								>0.2. &nbsp;To delete your personal information. If you make this request, we will
								delete the personal information we hold about you as of the date of your request from
								our records and direct any service providers to do the same. In some cases, deletion may
								be accomplished through the deidentification of the information. If you choose to delete
								your personal information, you may not be able to use certain functions that require
								your personal information to operate</b
							>
						</p>
						<p>
							<b
								>0.3.&nbsp;To stop selling your personal information. We don’t sell or rent your
								personal information to any third parties for any purpose. We do not sell your personal
								information for monetary consideration. However, under some circumstances, a transfer of
								personal information to a third party, or within our family of companies, without
								monetary consideration may be considered a “sale” under California law. You are the only
								owner of your Personal Data and can request disclosure or deletion at any time.</b
							>
						</p>
						<p>
							If you submit a request to stop selling your personal information, we will stop making such
							transfers.
						</p>
						<p>
							Please note, if you ask us to delete or stop selling your data, it may impact your
							experience with us, and you may not be able to participate in certain programs or membership
							services that require the usage of your personal information to function. But in no
							circumstances, we will discriminate against you for exercising your rights.
						</p>

						<p>
							To exercise your California data protection rights described above, please send your
							request(s) by email: <a href="mailto: privacy@infraon.io"><b>privacy@infraon.io</b></a
							>.
						</p>
						<li><strong>Service Providers</strong></li>
						<p class="my-1">
							We may employ third-party companies and individuals to facilitate our Service
							<b>(“Service Providers”),</b> provide Service on our behalf, perform Service-related
							services or assist us in analyzing how our Service is used.
						</p>

						<p>
							These third parties have access to your Personal Data only to perform these tasks on our
							behalf and are obligated not to disclose or use it for any other purpose.
						</p>
						<li><strong>Analytics</strong></li>
						<p class="my-1">
							We may use third-party Service Providers to monitor and analyze the use of our Service.
						</p>
						<p class="square">Google Analytics</p>

						<p>
							Google Analytics is a web analytics service offered by Google that tracks and reports
							website traffic. Google uses the data collected to track and monitor the use of our Service.
							This data is shared with other Google services. Google may use the collected data to
							contextualize and personalize the ads of its own advertising network.
						</p>
						<p>
							<b
								>For more information on the privacy practices of Google, please visit the Google
								Privacy & Terms web page:
								<a
									href="http://www.google.com/intl/en/policies/privacy/"
									class="text-decoration-underline"
									>www.google.com/intl/en/policies/privacy/</a
								></b
							>
						</p>

						<li><strong>CI/CD tools</strong></li>
						<p class="my-1">
							We may use third-party Service Providers to automate the development process of our Service.
						</p>
						<li><strong>Behavioral Remarketing</strong></li>
						<p class="my-1">
							We may use remarketing services to advertise on third-party websites to you after you
							visited our Service. We and our third-party vendors use cookies to inform, optimize and
							serve ads based on your past visits to our Service.
						</p>

						<p class="d-flex justify-content-start align-items-center">
							<span class="circle mr-1"></span><b>Google AdWords</b>
						</p>

						<p class="my-1">
							<strong
								>Google also recommends installing the Google Analytics Opt-out Browser Addon:
								tools.google.com/dlpage/gaoptout</strong
							>
						</p>
						<p>
							<strong
								>You can opt out of Google Analytics for Display Advertising and customize the Google
								Display Network ads by visiting the Google Ads Settings page:<a
									href="www.google.com/settings/ads"
									class="text-decoration-underline"
									>www.google.com/settings/ads</a
								>
							</strong>
						</p>
						<p class="my-1">
							<b
								>For more information on the privacy practices of Google, please visit the Google
								Privacy & Terms web page:
								<a href="www.google.com/intl/en/policies/privacy" class="text-decoration-underline"
									>www.google.com/intl/en/policies/privacy</a
								></b
							>
						</p>

						<p class="d-flex justify-content-start align-items-center">
							<span class="circle mr-1"></span><b>Facebook</b>
						</p>

						<p><b>Facebook remarketing service is provided by Facebook Inc.</b></p>

						<p class="my-1 my-1">
							<b
								>You can learn more about interest-based advertising from Facebook by visiting this
								page: Facebook:</b
							><a href=" www.facebook.com/help/568137493302217" class="text-decoration-underline">
								www.facebook.com/help/568137493302217</a
							>
						</p>

						<p class="mb-1">
							Facebook adheres to the Self-Regulatory Principles for Online Behavioral Advertising
							established by the Digital Advertising Alliance. You can also opt out from Facebook and
							other participating companies through the Digital Advertising Alliance in the USA
							<a href=" www.aboutads.info/choices" class="link-dark"></a>www.aboutads.info/choices, the
							Digital Advertising Alliance of Canada in Canada
							<a href="youradchoices.ca" class="link-dark">youradchoices.ca or</a> or the European
							Interactive Digital Advertising Alliance in Europe
							<a href="www.youronlinechoices.eu" class="link-dark">www.youronlinechoices.eu</a>, or
							opt-out using your mobile device settings.
						</p>
						<p class="mb-1">
							For more information on the privacy practices of Facebook, please visit Facebook’s Data
							Policy:<a href="www.facebook.com/privacy/explanation" class="text-decoration-underline"
								>www.facebook.com/privacy/explanation</a
							>
						</p>

						<p class="d-flex justify-content-start align-items-center">
							<span class="circle mr-1"></span><b>AdRoll</b>
						</p>

						<p class="mb-1"><b>AdRoll remarketing service is provided by Semantic Sugar, Inc.</b></p>
						<p class="mb-1">
							<b
								>You can opt out of AdRoll remarketing by visiting this AdRoll Advertising Preferences
								web page:
							</b>
							<a href="info.evidon.com/pub_info/573?v=1&nt=1&nw=false" class="text-decoration-underline"
								>info.evidon.com/pub_info/573?v=1&nt=1&nw=false</a
							>
						</p>

						<p class="mb-1">
							<b
								>For more information on the privacy practices of AdRoll, please visit the AdRoll
								Privacy Policy web page: </b
							><a href="www.adroll.com/about/privacy" class="text-decoration-underline"
								>www.adroll.com/about/privacy</a
							>
						</p>

						<li><strong>Payments</strong></li>
						<p class="my-1">
							We may provide paid products and/or services within the Service. In that case, we use
							third-party services for payment processing (e.g. payment processors).
						</p>

						<p class="mb-1">
							We will not store or collect your payment card details. That information is provided
							directly to our third-party payment processors whose use of your personal information is
							governed by their Privacy Policy. These payment processors adhere to the standards set by
							PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands
							like Visa, Mastercard, American Express, and Discover. PCI-DSS requirements help ensure the
							secure handling of payment information. The payment processors we work with are:
						</p>

						<p class="d-flex justify-content-start align-items-center mb-0">
							<span class="circle mr-1"></span>Stripe
						</p>

						<p>
							<b
								>Their Privacy Policy can be viewed at
								<a href="stripe.com/us/privacy" class="text-dark">stripe.com/us/privacy</a></b
							>
						</p>

						<li><strong> Links to Other Sites</strong></li>

						<p class="my-1">
							Our Service may contain links to other sites that are not operated by us. If you click a
							third-party link, you will be directed to that third-party’s site. We strongly advise you to
							review the Privacy Policy of every site you visit.
						</p>
						<p>
							We have no control over and assume no responsibility for the content, privacy policies, or
							practices of any third-party sites or services.
						</p>

						<li><strong>Children’s Privacy</strong></li>
						<p class="my-1">
							Our Services are not intended for use by children under the age of 18
							<b>(“Child” or “Children”).</b>
						</p>

						<p>
							We do not knowingly collect personally identifiable information from children under 18. If
							you become aware that a Child has provided us with Personal Data, please contact us. If we
							become aware that we have collected Personal Data from Children without verification of
							parental consent, we take steps to remove that information from our servers.
						</p>

						<li><strong>Changes to This Privacy Policy</strong></li>

						<p class="my-1">
							We may update our Privacy Policy from time to time. We will notify you of any changes by
							posting the new Privacy Policy on this page.
						</p>

						<p>
							We will let you know via email and/or a prominent notice on our Service before the change
							becomes effective and update the “effective date” at the top of this Privacy Policy.
						</p>

						<p>
							You are advised to review this Privacy Policy periodically for any changes. Changes to this
							Privacy Policy are effective when they are posted on this page.
						</p>

						<li><strong>Contact us</strong></li>
						<p class="mb-0">
							if you have any questions about this Privacy Policy, please contact us by email:
						</p>
						<b><a href="privacy@infraon.io." class="text-decoration-underline">privacy@infraon.io.</a></b>

						<p class="my-1">
							This <a href="Privacy Policy" class="text-decoration-underline">Privacy Policy</a> was
							created for <b>infraon.io</b> by
							<a href="PolicyMaker.io" class="text-decoration-underline">PolicyMaker.io</a> on 2021-03-03.
						</p>
					</ol>
				</div>
			</article>
		</div>
	</div>
</div>
