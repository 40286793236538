<div class="slideout-content default-stepping">
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
		<div class="modal-dialog">
			<form #UserAddForm="ngForm" class="modal-content add-new-user pt-0 pb-0">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">
						{{ action_label | translate }}
					</h5>
					<button
						type="button"
						id="btnRequesterClose"
						name="btnRequesterClose"
						[attr.data-testid]="'btnRequesterClose'"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						(click)="toggleSidebar('edit-requester', UserAddForm)"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body">
					<section class="horizontal-wizard" style="height: 100%">
						<div id="editRequester" class="bs-stepper horizontal-wizard-example">
							<div class="bs-stepper-header">
								<div id="divAccntTab" name="divAccntTab" class="step" data-target="#account-details">
									<button class="step-trigger" id="btnAccountDetail" name="accountDetail">
										<span class="bs-stepper-box">1</span>
										<span class="bs-stepper-label">
											<span class="bs-stepper-title">{{ "UI.k_requester" | translate }} </span>
											<span class="bs-stepper-subtitle">{{
												"UI.k_add_req_info" | translate
											}}</span>
										</span>
									</button>
								</div>
								<div class="line">
									<i class="fa-light fa-chevron-right font-medium-2"></i>
								</div>
								<div id="divPsnlInfo" name="divPsnlInfo" class="step" data-target="#personal-info">
									<button class="step-trigger" id="btnWorkDetail" name="workDetail">
										<span class="bs-stepper-box">2</span>
										<span class="bs-stepper-label">
											<span class="bs-stepper-title">{{ "UI.k_work" | translate }} </span>
											<span class="bs-stepper-subtitle">{{
												"UI.k_add_work_details" | translate
											}}</span>
										</span>
									</button>
								</div>
								<div class="line">
									<i class="fa-light fa-chevron-right font-medium-2"></i>
								</div>
								<div id="divAddr" name="divAddr" class="step" data-target="#address">
									<button class="step-trigger" id="btnAddressDetail" name="addressDetail">
										<span class="bs-stepper-box">3</span>
										<span class="bs-stepper-label">
											<span class="bs-stepper-title">{{ "UI.k_address" | translate }}</span>
											<span class="bs-stepper-subtitle"
												>{{ "UI.k_add_loc_info" | translate }}
											</span>
										</span>
									</button>
								</div>
							</div>
							<div class="bs-stepper-content">
								<!-- (ngSubmit)="(UserAddForm.form.valid)" -->
								<div class="bs-stepper-inner">
									<div id="account-details" class="content">
										<form #accountDetailsForm="ngForm" class="stepper-inner">
											<div class="stepper-body py-1">
												<ng-scrollbar class="custom-scrollbar section-scrollbar">
													<div class="d-flex">
														<div class="col-xs-12 col-md-2 col-xl-2 col-lg-2 px-2">
															<app-profile-avatar
																(avatarDetails)="profileImage($event)"
																(resetImage)="removeImage($event)"
																(avatarBgColor)="ProfileBgColor($event)"
																[profileName]="fullName"
																[profile_image]="currentImgDetails"
																[avatarBackgroundColor]="currentAvatarColor"
																[activeColor]="activeAvatar"
																(currentAvatar)="currrentColor($event)"
															>
															</app-profile-avatar>
														</div>
														<!-- <div
                                            class="col-xs-12 col-md-4 col-xl-4 col-lg-4 uploadfile"
                                        >
                                            <div class="form-group">
                                                <a
                                                    id="linkDelImg"
                                                    name="linkDelImg"
                                                    (click)="removeImage()"
                                                    *ngIf="showPreview"
                                                    class="mr-10"
                                                    ><span class="d-flex justify-content-end">
                                                    <i class="fa-light fa-trash-can text-danger mr-50"></i>
                                                </span
                                                ></a>
                                                    <div
                                                    *ngIf="!showPreview"
                                                    ng2FileDrop
                                                    ng2FileSelect
                                                    [ngClass]="{
                                                        'file-drop-over': hasBaseDropZoneOver
                                                    }"
                                                    (fileOver)="fileOverBase($event)"
                                                    (onFileDrop)="onUpload($event)"
                                                    [uploader]="uploader"
                                                    class="py-2 mb-0 text-center file-drop-zone"
                                                    id="drpZoneImg"
                                                    name="drpZoneImg"
                                                >
                                                Drop JPG/PNG files to add a Profile Picture
                                                </div>

                                                <span
                                                    ><img
                                                        *ngIf="showPreview && imageBase64"
                                                        [src]="imageBase64"
                                                        width="300"
                                                        height="200"
                                                />
                                                <div *ngIf="showPreview && !imageBase64" class="avatar bg-light-primary avatar-200-300">
                                                    <span class="avatar-content ">{{ this.requester.full_name | initials | slice:O:2 }}</span>
                                                </div>
                                            </span>

                                            </div>
                                        </div> -->
														<div class="col-xs-12 col-md-10 col-xl-10 col-lg-10">
															<div class="row">
																<div
																	class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6"
																>
																	<label class="form-label" for="txtName"
																		>{{ "UI.k_fname" | translate
																		}}<span class="required-field"
																			>&nbsp;*</span
																		></label
																	>
																	<ng-container *ngIf="!requester?.mask_full_name">
																		<input
																			[(ngModel)]="requester.full_name"
																			#TDNameRef="ngModel"
																			required
																			type="text"
																			name="fullName"
																			id="txtName"
																			class="form-control"
																			placeholder="{{
																				'UI.k_placeholder_name' | translate
																			}}"
																			minlength="3"
																			maxlength="40"
																			[appWhiteSpaceCheck]="requester.full_name"
																			[class.error]="
																				!TDNameRef.valid &&
																				accountDetailsForm.submitted
																			"
																		/>
																		<span
																			*ngIf="
																				accountDetailsForm.submitted &&
																				TDNameRef.invalid
																			"
																			class="invalid-form"
																		>
																			<small
																				class="form-text text-danger"
																				*ngIf="TDNameRef.errors.required"
																				>{{
																					"UI.k_field_required" | translate
																				}}</small
																			>
																			<small
																				class="form-text text-danger"
																				*ngIf="
																					TDNameRef.errors.minlength ||
																					TDNameRef.errors.maxlength
																				"
																			>
																				{{
																					"UI.k_first_name_validation" | translate
																				}}</small
																			>
																			<small
																				class="form-text text-danger"
																				*ngIf="TDNameRef.errors?.whitespace"
																			>
																				{{ "UI.k_space_validation" | translate }}
																			</small>
																		</span>
																	</ng-container>
																	<ng-container *ngIf="requester?.mask_full_name">
																		<input
																			[(ngModel)]="requester.full_name"
																			#TDNameRef="ngModel"
																			type="text"
																			name="fullName"
																			id="txtName"
																			class="form-control"
																			placeholder="{{
																				'UI.k_placeholder_name' | translate
																			}}"
																			[appWhiteSpaceCheck]="requester.full_name"
																			[attr.data-testid]="'cb__multiselect'"
																			[disabled]="true"
																			/>
																	</ng-container>
																</div>
																<div
																	class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6"
																>
																	<label class="form-label" for="email"
																		>{{ "UI.k_email" | translate
																		}}<span class="required-field"
																			>&nbsp;*</span
																		></label
																	>
																	<ng-container *ngIf="!requester?.mask_email">
																		<input
																			[(ngModel)]="requester.email"
																			#TDEmailRef="ngModel"
																			required
																			type="text"
																			name="email"
																			id="txtEmail"
																			class="form-control"
																			[class.error]="
																				!TDEmailRef.valid &&
																				accountDetailsForm.submitted
																			"
																			pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$"
																			placeholder="john.doe@email.com"
																			aria-label="john.doe"
																		/>
																		<span
																			*ngIf="
																				accountDetailsForm.submitted &&
																				TDEmailRef.invalid
																			"
																			class="invalid-form"
																		>
																			<small
																				class="form-text text-danger"
																				*ngIf="TDEmailRef.errors.pattern"
																				>{{
																					"UI.k_alert_email_invalid" | translate
																				}}</small
																			>
																			<small
																				class="form-text text-danger"
																				*ngIf="TDEmailRef.errors.required"
																				>{{
																					"UI.k_field_required" | translate
																				}}</small
																			>
																		</span>
																	</ng-container>
																	<ng-container *ngIf="requester?.mask_email">
																		<input
																			[(ngModel)]="requester.email"
																			#TDEmailRef="ngModel"
																			required
																			type="text"
																			name="email"
																			id="txtEmail"
																			class="form-control"
																			pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$"
																			placeholder="john.doe@email.com"
																			aria-label="john.doe"
																			[disabled]="true"
																		/>
																	</ng-container>
																</div>
															</div>
															<div class="row">
																<div
																	class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6"
																>
																	<label class="form-label" for="txtPhoneNumber"
																		>{{ "UI.k_contact_number" | translate }}
																	</label>
																	<!-- <input
                                      [(ngModel)]="requester.phone_number"
                                      #TDPhoneRef="ngModel"
                                      type="text"
                                      name="phoneNumber"
                                      id="txtPhoneNumber"
                                      class="form-control"
                                      placeholder="xxxxxxxxxx"
                                      pattern="^[0-9]{6,10}$"
                                      [class.error]="
                                          !TDPhoneRef.valid && accountDetailsForm.submitted
                                      "
                                  />
                                  <span
                                      *ngIf="
                                          accountDetailsForm.submitted && TDPhoneRef.invalid
                                      "
                                      class="invalid-form"
                                  >
                                      <small
                                          class="form-text text-danger"
                                          *ngIf="TDPhoneRef.errors.pattern"
                                          >{{ "UI.k_phone_no_pattern" | translate }}</small
                                      >
                                  </span> -->
																	<div class="w-100">
																		<ngx-intl-tel-input
																			class="w-100"
																			[cssClass]="'custom form-control w-100'"
																			[preferredCountries]="preferredCountries"
																			[enableAutoCountrySelect]="true"
																			[enablePlaceholder]="true"
																			[searchCountryFlag]="true"
																			[searchCountryField]="[
																				SearchCountryField.Iso2,
																				SearchCountryField.Name
																			]"
																			[selectFirstCountry]="false"
																			[selectedCountryISO]="
																				CountryISO.UnitedStates
																			"
																			[maxLength]="15"
																			[phoneValidation]="!requester?.mask_phone_number"
																			[separateDialCode]="separateDialCode"
																			[numberFormat]="PhoneNumberFormat.National"
																			name="txtPhoneNumber"
																			id="txtPhoneNumber"
																			[(ngModel)]="requester.phone_number"
																			#TDPhoneRef="ngModel"
																			[disabled]="requester?.mask_phone_number"
																			[ngClass]="{
																				'is-invalid':
																					accountDetailsForm.submitted &&
																					TDPhoneRef.errors
																			}"
																		>
																		</ngx-intl-tel-input>
																		<div *ngIf="!requester?.mask_phone_number">
																			<span
																				*ngIf="
																					accountDetailsForm.submitted &&
																					TDPhoneRef.invalid
																				"
																				class="invalid-form"
																			>
																				<small
																					class="form-text text-danger"
																					*ngIf="TDPhoneRef.errors"
																					>{{
																						"UI.k_phone_number_validation"
																							| translate
																					}}</small
																				>
																			</span>
																		</div>
																	</div>
																</div>
																<div
																	class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4"
																>
																	<label class="form-label" for="landline">{{
																		"UI.k_landline" | translate
																	}}</label>
																	<ng-container *ngIf="!requester?.mask_phone_number">
																		<input
																			[(ngModel)]="requester.land_line"
																			#TDLandLineRef="ngModel"
																			type="text"
																			id="txtLandline"
																			name="landline"
																			landline
																			class="form-control"
																			placeholder="xxxxxxxxxx"
																			pattern="[0-9-]{6,12}$"
																			[class.error]="
																				!TDLandLineRef.valid &&
																				accountDetailsForm.submitted
																			"
																		/>
																		<span
																			*ngIf="
																				accountDetailsForm.submitted &&
																				TDLandLineRef.invalid
																			"
																			class="invalid-form"
																		>
																			<small
																				class="form-text text-danger"
																				*ngIf="TDLandLineRef.errors.pattern"
																				>{{
																					"UI.k_landline_pattern" | translate
																				}}</small
																			>
																		</span>
																	</ng-container>
																	<ng-container *ngIf="requester?.mask_phone_number">
																		<input [(ngModel)]="requester.land_line"
																			type="text"
																			id="txtExt"
																			name="extension"
																			class="form-control"
																			placeholder="xxxxxxxxxx"
																			[required]="false"
																			[disabled]="true"
																		/>
																	</ng-container>
																</div>
																<div
																	class="form-group col-xs-12 col-md-2 col-xl-2 col-lg-2"
																>
																	<label class="form-label" for="ext">
																		{{
																			"UI.k_contact_extension" | translate
																		}}</label
																	>
																	<ng-container *ngIf="!requester?.mask_phone_number">
																		<input
																			TDExtRef
																			[(ngModel)]="requester.phone_ext"
																			#TDExtRef="ngModel"
																			type="text"
																			id="txtExt"
																			name="extension"
																			class="form-control"
																			placeholder="xxxxxxxxxx"
																			pattern="^[0-9-+]*$"
																			minlength="2"
																			maxlength="10"
																			[class.error]="
																				!TDExtRef.valid &&
																				accountDetailsForm.submitted
																			"
																		/>
																		<span
																			*ngIf="
																				accountDetailsForm.submitted &&
																				TDExtRef.invalid
																			"
																			class="invalid-form"
																		>
																			<small
																				class="form-text text-danger"
																				*ngIf="
																					TDExtRef.errors.minlength ||
																					TDExtRef.errors.maxlength
																				"
																			>
																				{{
																					"UI.k_extension_validation" | translate
																				}}</small
																			>
																			<small
																				class="form-text text-danger"
																				*ngIf="TDExtRef.errors.pattern"
																				>{{
																					"UI.k_extension_pattern" | translate
																				}}</small
																			>
																		</span>
																	</ng-container>
																	<ng-container *ngIf="requester?.mask_phone_number">
																		<input [(ngModel)]="requester.phone_ext"
																			   type="text"
																			   id="txtExt"
																			   name="extension"
																			   class="form-control"
																			   placeholder="xxxxxxxxxx"
																			   readonly
																		/>
																	</ng-container>
																</div>
															</div>
															<div class="row">
																<div class="col-md-6">
																	<div class="form-group">
																		<label class="mb-50">{{
																			"UI.k_type" | translate
																		}}</label>
																		<div
																			class="custom-control custom-control-primary custom-switch"
																		>
																			<div
																				id="divType"
																				name="type"
																				[attr.data-testid]="'divType'"
																				class="btn-group btn-group-toggle"
																				ngbRadioGroup
																				name="radioBasic"
																				[(ngModel)]="requester.type"
																				(change)="
																					changeRequesterType(
																						this.requester.type
																					)
																				"
																			>
																				<ng-container
																					*ngFor="
																						let rType of dropdownData?.TYPE_LIST
																					"
																				>
																					<label
																						ngbButtonLabel
																						class="btn-outline-primary btn-sm"
																						rippleEffect
																					>
																						<input
																							id="rdType{{ rType?.id }}"
																							ngbButton
																							type="radio"
																							value="{{ rType?.id }}"
																							attr.data-testid="rdType{{ rType?.id }}"
																						/>{{ rType?.name }}
																					</label>
																				</ng-container>
																			</div>
																		</div>
																	</div>
																</div>
																<div
																	class="col-md-3"
																	*ngIf="requester?.is_login_active"
																>
																	<div class="form-group">
																		<label class="mb-50">{{
																			"UI.k_is_enable_login" | translate
																		}}</label>
																		<div
																			class="custom-control custom-control-primary custom-switch"
																		>
																			<input
																				type="checkbox"
																				name="enableLogin"
																				[checked]="requester.is_login_enable"
																				[(ngModel)]="requester.is_login_enable"
																				class="custom-control-input"
																				id="cbEnableLogin"
																			/>
																			<label
																				class="custom-control-label"
																				for="cbEnableLogin"
																			></label>
																		</div>
																	</div>
																</div>
																<div class="col-md-3">
																	<div
																		class="form-group custom-control custom-control-primary custom-switch"
																	>
																		<p class="mb-50 font-small-2">
																			{{ "UI.k_is_active" | translate }}
																		</p>
																		<input
																			type="checkbox"
																			checked="requester.is_login_active"
																			class="custom-control-input"
																			id="togUsrActv"
																			name="togUsrActv"
																			[(ngModel)]="requester.is_login_active"
																		/>
																		<label
																			class="custom-control-label"
																			for="togUsrActv"
																		>
																		</label>
																	</div>
																</div>
																<div
																	class="col-md-12"
																	*ngIf="
																		requester?.is_login_enable &&
																		requester?.is_login_active
																	"
																>
																	<div class="row mt-1">
																		<div class="col-md-6">
																			<div class="form-group">
																				<label for="user_id"
																					>{{ "UI.k_user_id" | translate
																					}}<span class="required-field"
																						>&nbsp;*</span
																					>
																				</label>
																				<input
																					readonly
																					[(ngModel)]="requester.email"
																					#TDUserRef="ngModel"
																					required
																					type="text"
																					name="userName"
																					id="txtUserName"
																					class="form-control"
																					placeholder="{{
																						'UI.k_user' | translate
																					}}"
																					minlength="3"
																					[class.error]="
																						!TDUserRef.valid &&
																						accountDetailsForm.submitted
																					"
																				/>
																				<span
																					*ngIf="
																						accountDetailsForm.submitted &&
																						TDUserRef.invalid
																					"
																					class="invalid-form"
																				>
																					<small
																						class="form-text text-danger"
																						*ngIf="
																							TDUserRef.errors.required
																						"
																						>{{
																							"UI.k_field_required"
																								| translate
																						}}</small>
																				</span>
																			</div>
																		</div>
																		<div
																			class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6"
																		>
																			<label for="userPassword"
																				>{{ "UI.k_password" | translate
																				}}<span class="required-field"
																					>&nbsp;*</span
																				></label
																			>
																			<div
																				class="input-group input-group-merge form-password-toggle"
																			>
																				<input
																					[type]="
																						userPwdShow
																							? 'text'
																							: 'password'
																					"
																					class="form-control"
																					id="userPassword"
																					name="userPassword"
																					placeholder="{{
																						'UI.k_password' | translate
																					}}"
																					aria-describedby="userPassword"
																					class="form-control form-control-merge"
																					[class.error]="
																						!TDPasswordOnlyRef.valid &&
																						accountDetailsForm.submitted
																					"
																					[(ngModel)]="
																						requester.user_details.password
																					"
																					#TDPasswordOnlyRef="ngModel"
																					required
																					minlength="6"
																					(ngModelChange)="
																						validatePassword(
																							this.requester.user_details
																								.password
																						)
																					"
																				/>
																				<div
																					*ngIf="
																						requester.user_details
																							.password !=
																						'************Aa1'
																					"
																					class="input-group-append"
																					(click)="userPwdShow = !userPwdShow"
																				>
																					<span
																						class="input-group-text cursor-pointer"
																						id="passwordeye"
																						><i
																							class="feather"
																							[ngClass]="{
																								'icon-eye-off':
																									userPwdShow,
																								'icon-eye': !userPwdShow
																							}"
																						></i
																					></span>
																				</div>
																			</div>
																			<span
																				*ngIf="is_password_invalid"
																				class="invalid-form"
																			>
																				<small
																					class="form-text text-danger"
																					[innerHtml]="passwordToaster"
																				>
																				</small>
																			</span>
																			<span
																				*ngIf="
																					TDPasswordOnlyRef.invalid &&
																					accountDetailsForm.submitted
																				"
																				class="invalid-form"
																			>
																				<small
																					*ngIf="
																						TDPasswordOnlyRef.errors
																							.required
																					"
																					class="form-text text-danger"
																				>
																					{{
																						"UI.k_password_required"
																							| translate
																					}}
																				</small>
																			</span>
																		</div>
																	</div>
																</div>
																<div
																	class="col-xl-6 col-md-6 col-xs-12 col-lg-6"
																	*ngIf="
																		requester.type == 2 &&
																		!requester.is_login_enable
																	"
																>
																	<div class="form-group">
																		<label>{{
																			"UI.k_organization_name" | translate
																		}}</label>
																		<ng-select
																			[items]="dropdownData.ORGANIZATION_LIST"
																			[seachable]="true"
																			[(ngModel)]="requester.organization_name"
																			[multiple]="false"
																			[closeonSelect]="true"
																			notFoundText="{{ 'UI.k_no_items_found' | translate }}"
																			[attr.data-testid]="'selCustomerDetails'"
																			placeholder="{{
																				'UI.k_organization_name'
																				| translate
																			}}"
																			name="selCustomerDetails"
																			id="selCustomerDetails"
																			[searchFn]="customOrgSearchFn"
																			(change)="changeOrgName(requester.organization_name)"
																		>
																			<ng-template ng-label-tmp let-item="item" let-clear="clear">
																				<div class="mainclass d-flex justify-content-between">
																					<div class="d-flex justify-content-start align-items-center gap-1">
																						<div class="ng-value-label">
																							<span *ngIf="!item?.showAvatar; else customAvatar">
																								<img class="rounded-circle" [src]="item.logo_or_icon_path" width="28px" height="28px" (error)="onImgError(item)" />
																							</span>
																							<ng-template #customAvatar>
																								<div class="avatar mr-50" style="width: 28px; height: 28px" [ngStyle]="{
																										'background-color': item.avatar_color ? item.avatar_color : '#f59518'
																									}">
																									<div class="avatar-content">
																											{{ item.customer_name | initials | slice: O:2 }}
																									</div>
																								</div>
																							</ng-template>
																						</div>
																						<div>
																							{{ item?.customer_name | slice: 0:12 }}
																							{{ item.customer_name.length > 12 ? "..." : "" }}
																						</div>
																					</div>
																				</div>
																			</ng-template>
																			<ng-template ng-option-tmp let-item="item">
																				<div class="mainclass d-flex justify-content-between">
																					<div class="d-flex justify-content-start align-items-center gap-1">
																						<div class="ng-value-label">
																							<span *ngIf="!item?.showAvatar; else customAvatar">
																								<img class="rounded-circle" [src]="item.logo_or_icon_path" width="28px" height="28px" (error)="onImgError(item)" />
																							</span>
																							<ng-template #customAvatar>
																								<div class="avatar" style="width: 28px; height: 28px" [ngStyle]="{
																															'background-color': item.avatar_color ? item.avatar_color : '#f59518'
																														}">
																									<div class="avatar-content">
																										{{ item.customer_name | initials | slice: O:2 }}
																									</div>
																								</div>
																							</ng-template>
																						</div>
																						<div>
																							{{ item?.customer_name | slice: 0:12 }}
																							{{ item.customer_name.length > 12 ? "..." : "" }}
																						</div>
																					</div>
																				</div>
																			</ng-template>
																		</ng-select>
																	</div>
																</div>
															</div>
															<div
																class="row"
																*ngIf="
																	requester.is_login_enable &&
																	requester?.is_login_active
																"
															>
																<!--Login form starts-->
																<div class="col-xl-6 col-md-6 col-xs-12 col-lg-6">
																	<div class="form-group">
																		<label for="selTimezone">{{
																			"UI.k_timezone" | translate
																		}}</label>
																		<ng-select
																			[items]="dropdownData.TIMEZONE_LIST"
																			[hideSelected]="true"
																			bindLabel="name"
																			notFoundText="{{
																				'UI.k_no_timezone' | translate
																			}}"
																			(change)="
																				timezoneChange(
																					requester.user_details.timezone
																				)
																			"
																			bindValue="id"
																			[(ngModel)]="
																				requester.user_details.timezone
																			"
																			id="selTimezone"
																			name="selTimezone"
																			placeholder="{{
																				'UI.k_select_timezone' | translate
																			}}"
																		>
																		</ng-select>
																	</div>
																</div>
																<!-- <div class="col-xl-6 col-md-6 col-xs-12 col-lg-6">
																	<div class="form-group">
																		<label for="selDashboard">{{
																			"UI.k_dashboard" | translate
																		}}</label>
																		<ng-select
																			[items]="dropdownData.DASHBOARD_LIST"
																			[hideSelected]="true"
																			bindLabel="name"
																			notFoundText="{{
																				'UI.k_no_dashboard' | translate
																			}}"
																			bindValue="id"
																			[(ngModel)]="
																				requester.user_details.dashboard
																			"
																			id="selDashboard"
																			name="selDashboard"
																			placeholder="{{
																				'UI.k_select_dashboard' | translate
																			}}"
																		>
																		</ng-select>
																	</div>
																</div> -->
																<div class="col-xl-6 col-md-6 col-xs-12 col-lg-6">
																	<div class="form-group">
																		<label for="selLanguage">{{
																			"UI.k_language" | translate
																		}}</label>
																		<ng-select
																			[items]="dropdownData.DEFAULT_LANGUAGE_OPTIONS"
																			[hideSelected]="true"
																			bindLabel="name"
																			bindValue="id"
																			name="language"
																			notFoundText="{{
																				'UI.k_no_items_found' | translate
																			}}"
																			[(ngModel)]="
																				requester.user_details.language
																			"
																			id="selLanguage"
																			placeholder="{{
																				'UI.k_select_language' | translate
																			}}"
																		>
																		</ng-select>
																	</div>
																</div>
																<div
																	class="col-xl-6 col-md-6 col-xs-12 col-lg-6"
																	*ngIf="requester.type == 1"
																>
																	<div class="form-group">
																		<label>{{
																			"UI.k_enable_reporting" | translate
																		}}</label>
																		<div
																			class="custom-control custom-control-primary custom-switch"
																		>
																			<input
																				type="checkbox"
																				[(ngModel)]="
																					requester.user_details
																						.is_reporting_manager
																				"
																				name="enableReporting"
																				class="custom-control-input"
																				id="cbEableReporting"
																			/>
																			<label
																				class="custom-control-label"
																				for="cbEableReporting"
																			></label>
																		</div>
																	</div>
																</div>
																<div
																	class="col-xl-6 col-md-6 col-xs-12 col-lg-6"
																	*ngIf="
																		requester.type == 1 &&
																		requester.user_details.is_reporting_manager
																	"
																>
																	<div class="form-group" *ngIf="requester.type == 1">
																		<label for="reporting_manager">{{
																			"UI.k_reporting_manager" | translate
																		}}</label>
																		<ng-select
																			[items]="dropdownData.REP_MGER_LIST"
																			[hideSelected]="true"
																			bindLabel="full_name"
																			bindValue="profile_id"
																			[searchable]="true"
																			[searchFn]="customSearchFn"
																			notFoundText="{{
																				'UI.k_no_reporting_manger' | translate
																			}}"
																			[(ngModel)]="
																				requester.user_details.reporting_manager
																			"
																			id="reporting_manager"
																			name="reporting_manager"
																			placeholder="{{
																				'UI.k_select_reporting_manager'
																					| translate
																			}}"
																			[dropdownPosition]="'top'"
																		>
																			<ng-template
																				ng-option-tmp
																				let-item="item"
																				let-index="index"
																				let-search="searchTerm"
																			>
																				<span
																					class="row"
																					style="padding-left: 1rem"
																				>
																					<div
																						class="avatar mr-1 ml-0"
																						[ngClass]="bg - light - primary"
																						style="
																							align-content: center;
																							width: 32px;
																							height: 32px;
																						"
																					>
																						<div
																							*ngIf="
																								item?.profile_image;
																								else customAvatar
																							"
																						>
																							<img
																								class="rounded-circle mr-1"
																								src="{{
																									item.profile_image
																								}}"
																								height="32"
																								width="32"
																								alt="datatable-avatar"
																							/>
																						</div>
																						<ng-template #customAvatar>
																							<div
																								class="avatar mr-1 ml-0"
																								[ngStyle]="{
																									'background-color':
																										item.avatar_color
																											? item.avatar_color
																											: '#f59518'
																								}"
																							>
																								<div
																									class="avatar-content"
																								>
																									{{
																										item.full_name
																											| initials
																											| slice: O:2
																									}}
																								</div>
																							</div>
																						</ng-template>
																					</div>
																					<div class="cell-line-height">
																						<p
																							class="font-medium-1 font-weight-bold line-height-1 mb-25 d-flex flex-direction-row"
																						>
																							{{ item.full_name }}
																						</p>
																						<span
																							class="text-muted font-small-2"
																						>
																							{{ item.email }}</span
																						>
																					</div>
																				</span>
																			</ng-template>
																		</ng-select>
																	</div>
																</div>
																<div
																	class="col-xl-6 col-md-6 col-xs-12 col-lg-6"
																	*ngIf="requester.type == 2"
																>
																	<div class="form-group">
																		<label>{{
																			"UI.k_organization_name" | translate
																		}}</label>
																		<ng-select
																			[items]="dropdownData.ORGANIZATION_LIST"
																			[seachable]="true"
																			[(ngModel)]="requester.organization_name"
																			[multiple]="false"
																			[closeonSelect]="true"
																			notFoundText="{{ 'UI.k_no_items_found' | translate }}"
																			[attr.data-testid]="'selCustomerDetails'"
																			placeholder="{{
																				'UI.k_organization_name'
																				| translate
																			}}"
																			name="selCustomerDetails"
																			id="selCustomerDetails"
																			[searchFn]="customOrgSearchFn"
																			(change)="changeOrgName(requester.organization_name)"
																		>
																		<ng-template ng-label-tmp let-item="item" let-clear="clear">
																			<div class="mainclass d-flex justify-content-between">
																				<div class="d-flex justify-content-start align-items-center gap-1">
																					<div class="ng-value-label">
																						<span *ngIf="!item?.showAvatar; else customAvatar">
																							<img class="rounded-circle" [src]="item.logo_or_icon_path" width="28px" height="28px" (error)="onImgError(item)" />
																						</span>
																						<ng-template #customAvatar>
																							<div class="avatar mr-50" style="width: 28px; height: 28px" [ngStyle]="{
																									'background-color': item.avatar_color ? item.avatar_color : '#f59518'
																								}">
																								<div class="avatar-content">
																										{{ item.customer_name | initials | slice: O:2 }}
																								</div>
																							</div>
																						</ng-template>
																					</div>
																					<div>
																						{{ item?.customer_name | slice: 0:12 }}
																						{{ item.customer_name.length > 12 ? "..." : "" }}
																					</div>
																				</div>
																				<!-- <div class="d-flex justify-content-end">
																					<span class="badge mr-1">
																						{{ item?.customer_code | slice: 0:8 }}
																						{{ item.customer_code.length > 8 ? "..." : ""}}
																					</span>
																				</div> -->
																			</div>
																		</ng-template>
																		<ng-template ng-option-tmp let-item="item">
																			<div class="mainclass d-flex justify-content-between">
																				<div class="d-flex justify-content-start align-items-center gap-1">
																					<div class="ng-value-label">
																						<span *ngIf="!item?.showAvatar; else customAvatar">
																							<img class="rounded-circle" [src]="item.logo_or_icon_path" width="28px" height="28px" (error)="onImgError(item)" />
																						</span>
																						<ng-template #customAvatar>
																							<div class="avatar" style="width: 28px; height: 28px" [ngStyle]="{
																														'background-color': item.avatar_color ? item.avatar_color : '#f59518'
																													}">
																								<div class="avatar-content">
																									{{ item.customer_name | initials | slice: O:2 }}
																								</div>
																							</div>
																						</ng-template>
																					</div>
																					<div>
																						{{ item?.customer_name | slice: 0:12 }}
																						{{ item.customer_name.length > 12 ? "..." : "" }}
																					</div>
																				</div>
																				<!-- <div class="d-flex justify-content-end">
																					<span class="badge mr-1">
																						{{ item?.customer_code | slice: 0:8 }}
																						{{ item.customer_code.length > 8 ? "..." : "" }}
																					</span>
																				</div> -->
																			</div>
																	</ng-template>
																</ng-select>
															</div>
														</div>

																<!-- <div class="col-xl-8 col-md-8 col-xs-12 col-lg-8">
                                  <div class="row">

                                  </div>
                                  <div class="row">

                                  </div>
                                </div> -->
															</div>
														</div>
													</div>
												</ng-scrollbar>
											</div>
											<div class="stepper-btn d-flex justify-content-end">
												<button
													(click)="onSubmit(accountDetailsForm)"
													type="submit"
													*ngIf="!requester?.is_deleted"
													[disabled]="is_disable_save"
													class="btn btn-outline-tertiary mr-1"
													rippleEffect
													id="btnAcntSave"
													name="btnAcntSave"
													[attr.data-testid]="'btnAcntSave'"
												>
													<i class="fa-light fa-floppy-disk align-middle mr-50 ml-0"></i>
													<span class="align-middle d-sm-inline-block d-none">{{
														"UI.k_submit" | translate
													}}</span>
												</button>

												<button
													(click)="horizontalWizardStepperNext(accountDetailsForm)"
													type="submit"
													class="btn btn-primary btn-next"
													rippleEffect
													id="btnAcntNxt"
													name="btnAcntNxt"
													[attr.data-testid]="'btnAcntNxt'"
												>
													<span class="align-middle d-sm-inline-block d-none">{{
														"UI.k_next" | translate
													}}</span>
													<i class="fa-light fa-arrow-right align-middle ml-50 ml-0"></i>
												</button>
											</div>
										</form>
									</div>
									<div id="personal-info" class="content">
										<form #personalInfoForm="ngForm" class="stepper-inner">
											<!-- <div class="content-header">
                        <h5 class="mb-0">Work Info</h5>
                        <small>Enter Your Work Info.</small>
                      </div> -->
											<div class="stepper-body py-1">
												<ng-scrollbar class="custom-scrollbar section-scrollbar">
													<div class="d-flex">
														<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">
															<label class="form-label" for="txtDesignation">{{
																"UI.k_designation" | translate
															}}</label>
															<input
																[(ngModel)]="requester.designation"
																#TDDesignationRef="ngModel"
																type="text"
																name="designation"
																id="txtDesignation"
																class="form-control"
																minlength="2"
																maxlength="100"
																pattern="^[^\s]+(\s+[^\s]+)*$"
																placeholder="{{ 'UI.k_place_designation' | translate }}"
																[class.error]="
																	personalInfoForm.submitted &&
																	TDDesignationRef.invalid
																"
															/>
															<span
																*ngIf="
																	personalInfoForm.submitted &&
																	TDDesignationRef.invalid &&
																	(TDDesignationRef.dirty || TDDesignationRef.touched)
																"
																class="invalid-form"
															>
																<small
																	class="form-text text-danger"
																	*ngIf="TDDesignationRef.errors.pattern"
																	>{{ "Error.err_ltspace" | translate }}
																</small>
																<small
																	class="form-text text-danger"
																	*ngIf="
																		TDDesignationRef.errors.minlength ||
																		TDDesignationRef.errors.maxlength
																	"
																	>{{ "UI.k_designation_validation" | translate }}
																</small>
															</span>
														</div>
														<!--														<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">-->
														<!--															<label class="form-label" for="txtOrgName">{{-->
														<!--																"UI.k_organization_role" | translate }}-->
														<!--															</label>-->
														<!--											<input-->
														<!--												[(ngModel)]="requester.organization_name"-->
														<!--												#TDOrgRoleRef="ngModel"-->
														<!--												type="text"-->
														<!--												name="orgName"-->
														<!--												id="txtOrgName"-->
														<!--												class="form-control"-->
														<!--												minlength="2"-->
														<!--												maxlength="100"-->
														<!--												placeholder="{{'UI.k_place_orgrole' | translate}}"-->
														<!--												pattern="^[^\s]+(\s+[^\s]+)*$"-->
														<!--												[class.error]="-->
														<!--															personalInfoForm.submitted && TDOrgRoleRef.invalid-->
														<!--														" />-->
														<!--															<span *ngIf="-->
														<!--															personalInfoForm.submitted &&-->
														<!--															TDOrgRoleRef.invalid &&-->
														<!--															(TDOrgRoleRef.dirty || TDOrgRoleRef.touched)-->
														<!--														" class="invalid-form">-->
														<!--																<small class="form-text text-danger" *ngIf="-->
														<!--																TDOrgRoleRef.errors.minlength ||-->
														<!--																TDOrgRoleRef.errors.maxlength-->
														<!--															">Organization Role must be between 2 and 100-->
														<!--																	characters long.-->
														<!--																</small>-->
														<!--																<small class="form-text text-danger"-->
														<!--																	*ngIf="TDOrgRoleRef.errors.pattern">{{-->
														<!--																	"Error.err_ltspace" | translate }}-->
														<!--																</small>-->
														<!--															</span>-->
														<!--														</div>-->
														<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">
															<label class="form-label" for="selDepartment">{{
																"UI.k_department" | translate
															}}</label>
															<ng-select
																[items]="dropdownData.DEPARTMENT_LIST"
																[(ngModel)]="requester.department"
																bindLabel="dept_name"
																bindValue="dept_id"
																name="department"
																notFoundText="{{ 'UI.k_no_department' | translate }}"
																id="selDepartment"
																placeholder="{{ 'UI.k_select_department' | translate }}"
																#TDDepartmentRef="ngModel"
																[class.error]="
																	personalInfoForm.submitted &&
																	TDDepartmentRef.invalid
																"
															>
															</ng-select>
															<span
																*ngIf="
																	personalInfoForm.submitted &&
																	TDDepartmentRef.invalid &&
																	(TDDepartmentRef.dirty || TDDepartmentRef.touched)
																"
																class="invalid-form"
															>
																<small
																	class="form-text text-danger"
																	*ngIf="
																		TDDepartmentRef.errors.minlength ||
																		TDDepartmentRef.errors.maxlength
																	"
																	>{{ "UI.k_department_validation" | translate }}
																</small>
																<small
																	class="form-text text-danger"
																	*ngIf="TDDepartmentRef.errors.pattern"
																	>{{ "Error.err_ltspace" | translate }}
																</small>
															</span>
														</div>
													</div>
													<div class="d-flex" *ngIf="requester.type == 2">
														<!--														<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">-->
														<!--															<label class="form-label" for="selSite">{{-->
														<!--																"UI.k_customer_site_group" | translate-->
														<!--																}}</label>-->
														<!--															<ng-select [items]="dropdownData.CUST_SITE_GRP"-->
														<!--																[(ngModel)]="requester.customer_site_group"-->
														<!--																bindLabel="name" bindValue="id" name="site" id="selSite"-->
														<!--																placeholder="{{ 'UI.k_select' | translate }} {{-->
														<!--															'UI.k_customer_site_group' | translate-->
														<!--														}}">-->
														<!--															</ng-select>-->
														<!--														</div>-->
														<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">
															<label class="form-label" for="txtCustId">{{
																"UI.k_customer_id" | translate
															}}</label>
															<input
																[(ngModel)]="requester.customer_id"
																#TDCustIdRef="ngModel"
																type="text"
																name="CustId"
																id="txtCustId"
																class="form-control"
																minlength="2"
																maxlength="100"
																pattern="^[^\s]+(\s+[^\s]+)*$"
																[class.error]="
																	personalInfoForm.submitted && TDCustIdRef.invalid
																"
																placeholder="{{
																	'UI.k_customer_id_placeholder' | translate
																}}"
															/>
															<span
																*ngIf="
																	personalInfoForm.submitted &&
																	TDCustIdRef.invalid &&
																	(TDCustIdRef.dirty || TDCustIdRef.touched)
																"
																class="invalid-form"
															>
																<small
																	class="form-text text-danger"
																	*ngIf="
																		TDCustIdRef.errors.minlength ||
																		TDCustIdRef.errors.maxlength
																	"
																	>{{
																		"Error.err_cutomer_id_length" | translate
																	}}</small
																>
																<small
																	class="form-text text-danger"
																	*ngIf="TDCustIdRef.errors.pattern"
																	>{{ "Error.err_ltspace" | translate }}
																</small>
															</span>
														</div>
														<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">
															<label class="form-label" for="txtSubId">{{
																"UI.k_subscriber_id" | translate
															}}</label>
															<input
																[(ngModel)]="requester.subscriber_id"
																#TDSubIdRef="ngModel"
																type="text"
																name="subId"
																id="txtSubId"
																class="form-control"
																minlength="2"
																maxlength="100"
																placeholder="{{
																	'UI.k_subscriber_id_placeholder' | translate
																}}"
																pattern="^[^\s]+(\s+[^\s]+)*$"
																[class.error]="
																	personalInfoForm.submitted && TDSubIdRef.invalid
																"
															/>
															<span
																*ngIf="
																	personalInfoForm.submitted &&
																	TDSubIdRef.invalid &&
																	(TDSubIdRef.dirty || TDSubIdRef.touched)
																"
																class="invalid-form"
															>
																<small
																	class="form-text text-danger"
																	*ngIf="
																		TDSubIdRef.errors.minlength ||
																		TDSubIdRef.errors.maxlength
																	"
																	>{{ "UI.k_subscriber_id_validation" | translate }}
																</small>
																<small
																	class="form-text text-danger"
																	*ngIf="TDSubIdRef.errors.pattern"
																	>{{ "Error.err_ltspace" | translate }}
																</small>
															</span>
														</div>
													</div>
													<div class="d-flex" *ngIf="requester.type == 1">
														<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">
															<label class="form-label" for="selEmpType">{{
																"UI.k_employee_type" | translate
															}}</label>
															<ng-select
																[items]="dropdownData.EMP_TYPE_LIST"
																[(ngModel)]="requester.emp_type"
																bindLabel="name"
																bindValue="id"
																name="empType"
																notFoundText="{{ 'UI.k_no_employee_type' | translate }}"
																id="selEmpType"
																placeholder="{{
																	'UI.k_select_employee_type' | translate
																}}"
															>
															</ng-select>
														</div>
														<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">
															<label class="form-label" for="txtJoiningDate">{{
																"UI.k_joining_date" | translate
															}}</label>

															<ng2-flatpickr
																[config]="basicDateOptions"
																name="joiningDate"
																id="txtJoiningDate"
																[(ngModel)]="requester.joining_date"
																[setDate]="requester.joining_date"
																placeholder="{{
																	'UI.k_placeholder_joining_date' | translate
																}}"
															>
															</ng2-flatpickr>

															<!--<div class="input-group">-->
															<!--<input class="form-control" placeholder="{{-->
															<!--'UI.k_placeholder_yyyy_mm_dd' | translate-->
															<!--}}" name="joiningDate" id="txtJoiningDate"-->
															<!--[(ngModel)]="requester.joining_date" #TDJoiningDateRef="ngModel" ngbDatepicker-->
															<!--#CustomDayDP="ngbDatepicker" [class.error]="-->
															<!--!TDJoiningDateRef.valid &&-->
															<!--personalInfoForm.submitted-->
															<!--" />-->

															<!--<div class="input-group-append">-->
															<!--<button id="btnSelDate" class="-->
															<!--btn btn-outline-secondary-->
															<!--feather-->
															<!--icon-calendar-->
															<!--" (click)="CustomDayDP.toggle()" type="button" rippleEffect></button>-->
															<!--</div>-->
															<!--</div>-->
															<!--<span *ngIf="-->
															<!--personalInfoForm.submitted && !TDJoiningDateRef.valid-->
															<!--" class="invalid-form">-->
															<!--<small class="form-text text-danger" *ngIf="!TDJoiningDateRef.valid">{{-->
															<!--"UI.k_joining_data_validation" | translate-->
															<!--}}</small>-->
															<!--</span>-->
														</div>
													</div>
													<div class="d-flex">
														<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">
															<label class="form-label" for="selNotifyEmail"
																>{{ "UI.k_notify_email" | translate }}
																ID(s)
															</label>
															<span
																id="notifyEmailTooltip"
																name="notifyEmailTooltip"
																class="ml-50"
																ngbTooltip="{{ 'UI.k_notify_email_descr' | translate }}"
																placement="right"
																tooltipClass="custom-class"
																><i class="fa-light fa-info-circle"></i
															></span>
															<ng-container *ngIf="!requester?.mask_email">
																<ng-select
																	[items]="[]"
																	[addTag]="true"
																	[multiple]="true"
																	[hideSelected]="true"
																	notFoundText="{{ 'UI.k_no_notify_email' | translate }}"
																	id="selNotifyEmail"
																	name="notifyEmail"
																	addTagText="{{ 'UI.k_add_item' | translate }}"
																	[(ngModel)]="requester.notify_email"
																	#TDNEmailRef="ngModel"
																	[class.error]="
																		TDNEmailRef.errors?.invalidemail &&
																		personalInfoForm.submitted
																	"
																	placeholder="{{ 'UI.k_email_placeholder' | translate }}"
																	[appEmailCheck]="requester.notify_email"
																	hideSelected="true"
																>
																</ng-select>
																<span
																	*ngIf="personalInfoForm.submitted"
																	class="invalid-form"
																>
																	<small
																		class="form-text text-danger"
																		*ngIf="TDNEmailRef.errors?.invalidemail"
																	>
																		{{
																			"UI.k_alert_notify_email_invalid" | translate
																		}}</small
																	>
																	<small class="form-text text-danger"
																		*ngIf="TDNEmailRef.errors?.max_email">{{UI.k_email_max_validation"	| translate:{"maxLimit": TDNEmailRef.errors?.limit} }}
																	</small>
																</span>
															</ng-container>
															<ng-container >
																<ng-select
																	[items]="[]"
																	[addTag]="true"
																	[multiple]="true"
																	[hideSelected]="true"
																	notFoundText="{{ 'UI.k_no_notify_email' | translate }}"
																	id="selNotifyEmail"
																	name="notifyEmail"
																	addTagText="{{ 'UI.k_add_item' | translate }}"
																	[(ngModel)]="requester.notify_email"
																	#TDNEmailRef="ngModel"
																	[class.error]="
																		TDNEmailRef.errors?.invalidemail &&
																		personalInfoForm.submitted
																	"
																	placeholder="{{ 'UI.k_email_placeholder' | translate }}"
																	[appEmailCheck]="requester.notify_email"
																	hideSelected="true"
																	[disabled]="requester?.mask_email"
																>
																</ng-select>
															</ng-container>
														</div>
														<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">
															<label class="form-label" for="selNotifyNumber">{{
																"UI.k_notify_phone" | translate
															}}</label>
															<span
																id="notifyPhoneTooltip"
																name="notifyPhoneTooltip"
																class="ml-50"
																ngbTooltip="{{ 'UI.k_notify_phone_descr' | translate }}"
																placement="right"
																tooltipClass="custom-class"
																><i class="fa-light fa-info-circle"></i
															></span>
															<ng-container *ngIf="!requester?.mask_phone_number">
																<ng-select
																	[items]="[]"
																	[addTag]="true"
																	[multiple]="true"
																	id="selNotifyNumber"
																	name="notifyNumber"
																	addTagText="{{ 'UI.k_add_item' | translate }}"
																	notFoundText="{{ 'UI.k_no_notify_phone' | translate }}"
																	[(ngModel)]="requester.notify_phone"
																	#TDNPhoneRef="ngModel"
																	[class.error]="
																		!TDNPhoneRef.valid && personalInfoForm.submitted
																	"
																	[appPhoneCheck]="requester.notify_phone"
																	placeholder="xxxxxxxxxx"
																	hideSelected="true"
																>
																</ng-select>
																<span
																	*ngIf="personalInfoForm.submitted"
																	class="invalid-form"
																>
																<small
																	class="form-text text-danger"
																	*ngIf="TDNPhoneRef.errors?.invalidphone"
																	>{{ "UI.k_alert_phone_invalid" | translate }}</small
																>
																</span>
															</ng-container>
															<ng-container *ngIf="requester?.mask_phone_number">
																<ng-select
																	[items]="[]"
																	[addTag]="true"
																	[multiple]="true"
																	id="selNotifyNumber"
																	name="notifyNumber"
																	addTagText="{{ 'UI.k_add_item' | translate }}"
																	notFoundText="{{ 'UI.k_no_notify_phone' | translate }}"
																	[(ngModel)]="requester.notify_phone"
																	[appPhoneCheck]="requester.notify_phone"
																	placeholder="xxxxxxxxxx"
																	hideSelected="true"
																	[disabled]="requester?.mask_phone_number">
																</ng-select>
															</ng-container>
														</div>
													</div>
													<div class="d-flex">
														<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">
															<label class="form-label" for="selTag">{{
																"UI.k_tags" | translate
															}}</label>
															<ng-select
																[items]="dropdownData.TAGS_LIST"
																[addTag]="false"
																[hideSelected]="true"
																multiple="true"
																bindLabel="name"
																notFoundText="{{ 'UI.k_no_tags' | translate }}"
																bindValue="tag_id"
																[closeOnSelect]="true"
																[(ngModel)]="requester.tags"
																id="selTag"
																name="tags"
																[addTag]="selectAddTagMethod"
																addTagText="{{ 'UI.k_add_tag' | translate }}"
																[ngModelOptions]="{ standalone: true }"
																placeholder="{{ 'UI.k_select_tags' | translate }}"
															>
															</ng-select>
															<ng-template #TagComp let-modal>
																<div class="modal-header">
																	<h4 class="modal-title" id="myModalLabel1">
																		{{ "UI.k_add_tags" | translate }}
																	</h4>
																	<span
																		id="btnTagPupClose"
																		name="btnTagPupClose"
																		data-dismiss="modal"
																		aria-label="Close"
																		(click)="modal.dismiss('Cross click')"
																	>
																		<i
																			class="fa-light fa-xmark mb-0 mr-sm-25 mr-0 pull-right"
																		></i>
																	</span>
																</div>
																<div class="modal-body" tabindex="0" ngbAutofocus>
																	<form #addTagForm="ngForm">
																		<div class="row mb-2">
																			<div
																				class="col-xs-12 col-md-6 col-sm-6 col-lg-6"
																			>
																				<div class="form-group">
																					<label for="txtReqTagName"
																						>{{
																							"UI.k_tags_name" | translate
																						}}
																						<span class="required-field"
																							>&nbsp;*</span
																						>
																					</label>
																					<input
																						#TDTagNameRef="ngModel"
																						required
																						type="text"
																						class="form-control"
																						placeholder="{{
																							'UI.k_tag_name' | translate
																						}}"
																						name="reqTagName"
																						[(ngModel)]="tag.name"
																						id="txtReqTagName"
																						minlength="3"
																						maxlength="40"
																						[appWhiteSpaceCheck]="tag.name"
																						[class.error]="
																							!TDTagNameRef.valid &&
																							addTagForm.submitted
																						"
																					/>
																					<span
																						*ngIf="
																							addTagForm.submitted &&
																							TDTagNameRef.invalid
																						"
																						class="invalid-form"
																					>
																						<small
																							class="form-text text-danger"
																							*ngIf="
																								TDTagNameRef.errors
																									.required
																							"
																							>{{
																								"UI.k_field_required"
																									| translate
																							}}</small
																						>
																						<small
																							class="form-text text-danger"
																							*ngIf="
																								TDTagNameRef.errors
																									.minlength ||
																								TDTagNameRef.errors
																									.maxlength
																							"
																						>
																							{{
																								"UI.k_tag_validation"
																									| translate
																							}}</small
																						>
																						<small
																							class="form-text text-danger"
																							*ngIf="
																								TDNameRef.errors
																									?.whitespace
																							"
																						>
																							{{
																								"UI.k_space_validation"
																									| translate
																							}}
																						</small>
																					</span>
																				</div>
																			</div>
																			<div
																				class="col-xs-12 col-md-6 col-sm-6 col-lg-6"
																			>
																				<div class="form-group">
																					<label for="drpType">{{
																						"UI.k_type" | translate
																					}}</label>
																					<ng-select
																						[items]="
																							dropdownData.TAG_TYPE_LIST
																						"
																						[multiple]="false"
																						[closeOnSelect]="true"
																						[searchable]="false"
																						bindLabel="name"
																						notFoundText="{{
																							'UI.k_no_tag_type'
																								| translate
																						}}"
																						bindValue="id"
																						placeholder="{{
																							'UI.k_select' | translate
																						}}"
																						name="type"
																						id="drpType"
																						[(ngModel)]="tag.type"
																						disabled
																						[attr.data-testid]="'drpType'"
																					>
																					</ng-select>
																				</div>
																			</div>
																		</div>
																		<div class="d-flex pull-right">
																			<div class="mr-1">
																				<button
																					(click)="onCreateTag(addTagForm)"
																					type="submit"
																					class="btn btn-primary"
																					rippleEffect
																					id="btnCreateTag"
																					name="btnCreateTag"
																					[attr.data-testid]="'btnCreateTag'"
																					[disabled]="is_save_disabled"
																				>
																					<span
																						class="align-middle d-sm-inline-block d-none"
																						>{{
																							"UI.k_create_tags"
																								| translate
																						}}</span
																					>
																					<i
																						class="fa-light fa-arrow-right align-middle ml-sm-25 ml-0"
																					></i>
																				</button>
																			</div>
																		</div>
																	</form>
																</div>
															</ng-template>
														</div>
														<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">
															<label class="form-label" for="selHub"
																>{{ "UI.k_service_hub" | translate }}
															</label>
															<ng-select
																[items]="dropdownData.SERVICE_HUB_LIST"
																[(ngModel)]="requester.support_location"
																bindLabel="location_name"
																bindValue="location_id"
																notFoundText="{{ 'UI.k_no_service_hub' | translate }}"
																name="serviceHub"
																id="selHub"
																placeholder="{{
																	'UI.k_select_service_hub' | translate
																}}"
															>
															</ng-select>
														</div>
													</div>
													<div class="d-flex">
														<div *ngIf="requester.type == 1" class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">
															<label class="form-label" for="empId"
															>{{"UI.k_employee_id" | translate}}
															</label>
															<input
																[(ngModel)]="requester.employee_id"
																#TDEmpIdRef="ngModel"
																type="text"
																name="empId"
																id="empId"
																class="form-control"
																minlength="3"
																maxlength="40"
																pattern="^[^\s]+(\s+[^\s]+)*$"
																placeholder="{{ 'UI.k_employee_id' | translate }}"
																[class.error]=" personalInfoForm.submitted && TDEmpIdRef.invalid" 
															/>
															<span *ngIf="
																	personalInfoForm.submitted &&
																	TDEmpIdRef.invalid &&
																	(TDEmpIdRef.dirty || TDEmpIdRef.touched)
																"
																class="invalid-form">
																<small
																	class="form-text text-danger"
																	*ngIf="TDEmpIdRef.errors.pattern">{{
																	"Error.err_ltspace" | translate }}
																</small>
																<small
																	class="form-text text-danger"
																	*ngIf="
																	TDEmpIdRef.errors.minlength ||
																	TDEmpIdRef.errors.maxlength
																	">{{ "UI.k_emp_id_validation" | translate
																	}}
																</small>
															</span>
														</div>
													</div>

													<!-- Start of commenting: Commenting below code to hide this field may be used in neaar future -->
													<!--													<div class="d-flex">-->
													<!--														<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">-->
													<!--															<label class="form-label" for="txtSegment">{{-->
													<!--																"UI.k_business_segment" | translate-->
													<!--																}}</label>-->
													<!--															<input [(ngModel)]="requester.business_segment"-->
													<!--																#TDBusinessSegRef="ngModel" type="text" name="segment"-->
													<!--																id="txtSegment" class="form-control" minlength="2"-->
													<!--																maxlength="100"-->
													<!--																placeholder="{{ 'UI.k_business_segment' | translate }}"-->
													<!--																pattern="^[^\s]+(\s+[^\s]+)*$" [class.error]="-->
													<!--															personalInfoForm.submitted && TDBusinessSegRef.invalid-->
													<!--														" />-->
													<!--															<span *ngIf="-->
													<!--															personalInfoForm.submitted &&-->
													<!--															TDBusinessSegRef.invalid &&-->
													<!--															(TDBusinessSegRef.dirty || TDBusinessSegRef.touched)-->
													<!--														" class="invalid-form">-->
													<!--																<small class="form-text text-danger" *ngIf="-->
													<!--																TDBusinessSegRef.errors.minlength ||-->
													<!--																TDBusinessSegRef.errors.maxlength-->
													<!--															">{{ "UI.k_business_segment_validation" | translate }}-->
													<!--																</small>-->
													<!--																<small class="form-text text-danger"-->
													<!--																	*ngIf="TDBusinessSegRef.errors.pattern">{{-->
													<!--																	"Error.err_ltspace" | translate }}-->
													<!--																</small>-->
													<!--															</span>-->
													<!--														</div>-->
													<!--														<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">-->
													<!--															<label class="form-label" for="txtCategory">{{-->
													<!--																"UI.k_req_category" | translate-->
													<!--																}}</label>-->
													<!--															<input [(ngModel)]="requester.req_category"-->
													<!--																#TDRequesterCatRef="ngModel" type="text" name="category"-->
													<!--																id="txtCategory" class="form-control" minlength="2"-->
													<!--																maxlength="100"-->
													<!--																placeholder="{{ 'UI.k_req_category' | translate }}"-->
													<!--																pattern="^[^\s]+(\s+[^\s]+)*$" [class.error]="-->
													<!--															personalInfoForm.submitted &&-->
													<!--															TDRequesterCatRef.invalid-->
													<!--														" />-->
													<!--															<span *ngIf="-->
													<!--															personalInfoForm.submitted &&-->
													<!--															TDRequesterCatRef.invalid &&-->
													<!--															(TDRequesterCatRef.dirty || TDRequesterCatRef.touched)-->
													<!--														" class="invalid-form">-->
													<!--																<small class="form-text text-danger" *ngIf="-->
													<!--																TDRequesterCatRef.errors.minlength ||-->
													<!--																TDRequesterCatRef.errors.maxlength-->
													<!--															">{{-->
													<!--																	"UI.k_requester_category_validation" | translate-->
													<!--																	}}-->
													<!--																</small>-->
													<!--																<small class="form-text text-danger"-->
													<!--																	*ngIf="TDRequesterCatRef.errors.pattern">-->
													<!--																	{{ "Error.err_ltspace" | translate }}-->
													<!--																</small>-->
													<!--															</span>-->
													<!--														</div>-->
													<!--													</div>-->
													<!--													<div class="d-flex">-->
													<!--														<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">-->
													<!--															<label class="form-label" for="txtEntity">{{-->
													<!--																"UI.k_business_entity" | translate-->
													<!--																}}</label>-->
													<!--															<input [(ngModel)]="requester.business_entity"-->
													<!--																#TDBusinessEntityRef="ngModel" type="text" name="entity"-->
													<!--																id="txtEntity" class="form-control" minlength="2"-->
													<!--																maxlength="100"-->
													<!--																placeholder="{{ 'UI.k_business_entity' | translate }}"-->
													<!--																pattern="^[^\s]+(\s+[^\s]+)*$" [class.error]="-->
													<!--															personalInfoForm.submitted &&-->
													<!--															TDBusinessEntityRef.invalid-->
													<!--														" />-->
													<!--															<span *ngIf="-->
													<!--															personalInfoForm.submitted &&-->
													<!--															TDBusinessEntityRef.invalid &&-->
													<!--															(TDBusinessEntityRef.dirty ||-->
													<!--																TDBusinessEntityRef.touched)-->
													<!--														" class="invalid-form">-->
													<!--																<small class="form-text text-danger" *ngIf="-->
													<!--																TDBusinessEntityRef.errors.minlength ||-->
													<!--																TDBusinessEntityRef.errors.maxlength-->
													<!--															">{{ "UI.k_business_entity_validation" | translate }}-->
													<!--																</small>-->
													<!--																<small class="form-text text-danger"-->
													<!--																	*ngIf="TDBusinessEntityRef.errors.pattern">{{-->
													<!--																	"Error.err_ltspace" | translate }}-->
													<!--																</small>-->
													<!--															</span>-->
													<!--														</div>-->
													<!--														<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">-->
													<!--															<label class="form-label" for="txtBranch">{{-->
													<!--																"UI.k_branch_partner" | translate-->
													<!--																}}</label>-->
													<!--															<input [(ngModel)]="requester.branch_partner"-->
													<!--																#TDBranchPartnerRef="ngModel" type="text" name="branch"-->
													<!--																id="txtBranch" class="form-control" minlength="2"-->
													<!--																maxlength="100"-->
													<!--																placeholder="{{ 'UI.k_branch_partner' | translate }}"-->
													<!--																pattern="^[^\s]+(\s+[^\s]+)*$" [class.error]="-->
													<!--															personalInfoForm.submitted &&-->
													<!--															TDBranchPartnerRef.invalid-->
													<!--														" />-->
													<!--															<span *ngIf="-->
													<!--															personalInfoForm.submitted &&-->
													<!--															TDBranchPartnerRef.invalid &&-->
													<!--															(TDBranchPartnerRef.dirty ||-->
													<!--																TDBranchPartnerRef.touched)-->
													<!--														" class="invalid-form">-->
													<!--																<small class="form-text text-danger" *ngIf="-->
													<!--																TDBranchPartnerRef.errors.minlength ||-->
													<!--																TDBranchPartnerRef.errors.maxlength-->
													<!--															">{{ "UI.k_branch_partner_validation" | translate }}-->
													<!--																</small>-->
													<!--																<small class="form-text text-danger"-->
													<!--																	*ngIf="TDBranchPartnerRef.errors.pattern">{{-->
													<!--																	"Error.err_ltspace" | translate }}-->
													<!--																</small>-->
													<!--															</span>-->
													<!--														</div>-->
													<!--													</div>-->
													<!--													<div class="d-flex">-->
													<!--														<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">-->
													<!--															<label class="form-label" for="txtSubPartner">{{-->
													<!--																"UI.k_sub_partner" | translate-->
													<!--																}}</label>-->
													<!--															<input [(ngModel)]="requester.sub_partner"-->
													<!--																#TDSubPartnerRef="ngModel" type="text" name="subPartner"-->
													<!--																id="txtSubPartner" class="form-control" minlength="2"-->
													<!--																maxlength="100"-->
													<!--																placeholder="{{ 'UI.k_sub_partner' | translate }}"-->
													<!--																pattern="^[^\s]+(\s+[^\s]+)*$" [class.error]="-->
													<!--															personalInfoForm.submitted && TDSubPartnerRef.invalid-->
													<!--														" />-->
													<!--															<span *ngIf="-->
													<!--															personalInfoForm.submitted &&-->
													<!--															TDSubPartnerRef.invalid &&-->
													<!--															(TDSubPartnerRef.dirty || TDSubPartnerRef.touched)-->
													<!--														" class="invalid-form">-->
													<!--																<small class="form-text text-danger" *ngIf="-->
													<!--																TDSubPartnerRef.errors.minlength ||-->
													<!--																TDSubPartnerRef.errors.maxlength-->
													<!--															">{{-->
													<!--																	"UI.k_subscriber_partner_validation" | translate-->
													<!--																	}}-->
													<!--																</small>-->
													<!--																<small class="form-text text-danger"-->
													<!--																	*ngIf="TDSubPartnerRef.errors.pattern">{{-->
													<!--																	"Error.err_ltspace" | translate }}-->
													<!--																</small>-->
													<!--															</span>-->
													<!--														</div>-->
													<!--														<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">-->
													<!--															<label class="form-label" for="txtSubStatus">{{-->
													<!--																"UI.k_subscriber_status" | translate-->
													<!--																}}</label>-->
													<!--															<input [(ngModel)]="requester.subscriber_status"-->
													<!--																#TDSubStatusRef="ngModel" type="text" name="subStatus"-->
													<!--																id="txtSubStatus" class="form-control" minlength="2"-->
													<!--																maxlength="100"-->
													<!--																placeholder="{{ 'UI.k_subscriber_status' | translate }}"-->
													<!--																pattern="^[^\s]+(\s+[^\s]+)*$" [class.error]="-->
													<!--															personalInfoForm.submitted && TDSubStatusRef.invalid-->
													<!--														" />-->
													<!--															<span *ngIf="-->
													<!--															personalInfoForm.submitted &&-->
													<!--															TDSubStatusRef.invalid &&-->
													<!--															(TDSubStatusRef.dirty || TDSubStatusRef.touched)-->
													<!--														" class="invalid-form">-->
													<!--																<small class="form-text text-danger" *ngIf="-->
													<!--																TDSubStatusRef.errors.minlength ||-->
													<!--																TDSubStatusRef.errors.maxlength-->
													<!--															">{{ "UI.k_subscriber_status_validation" | translate }}-->
													<!--																</small>-->
													<!--																<small class="form-text text-danger"-->
													<!--																	*ngIf="TDSubStatusRef.errors.pattern">{{-->
													<!--																	"Error.err_ltspace" | translate }}-->
													<!--																</small>-->
													<!--															</span>-->
													<!--														</div>-->
													<!--													</div>-->
													<!-- End of commenting -->
												</ng-scrollbar>
											</div>
											<div class="stepper-btn d-flex justify-content-between">
												<button
													(click)="horizontalWizardStepperPrevious()"
													class="btn btn-flat-primary btn-prev"
													rippleEffect
													id="btnPrsnPrev"
													name="btnPrsnPrev"
													[attr.data-testid]="'btnPrsnPrev'"
												>
													<i class="fa-light fa-arrow-left align-middle mr-50 mr-0"></i>
													<span class="align-middle d-sm-inline-block d-none"
														>{{ "UI.k_back" | translate }}
													</span>
												</button>
												<button
													(click)="horizontalWizardStepperNext(personalInfoForm)"
													class="btn btn-primary btn-next"
													rippleEffect
													id="btnPrsnNxt"
													name="btnPrsnNxt"
													[attr.data-testid]="'btnPrsnNxt'"
												>
													<span class="align-middle d-sm-inline-block d-none">{{
														"UI.k_next" | translate
													}}</span>
													<i class="fa-light fa-arrow-right align-middle ml-50 ml-0"></i>
												</button>
											</div>
										</form>
									</div>
									<div id="address" class="content">
										<form #addressForm="ngForm" class="stepper-inner">
											<!-- <div class="content-header">
                        <h5 class="mb-0">Address</h5>
                        <small>Enter Your Address.</small>
                      </div> -->
											<div class="stepper-body py-1">
												<ng-scrollbar class="custom-scrollbar section-scrollbar">
													<div class="d-flex" *ngIf='requester.type == 1 || requester.organization_name'>
														<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">
															<div class="d-flex justify-content-between align-items-center">
																<label class="form-label" for="txtLoc">{{
																	"UI.k_base_location" | translate
																	}}</label>
															</div>
															<ng-select
																[(ngModel)]="selectedBaseLocation"
																[items]="dropdownData.LOCATION_LIST"
																[multiple]="false"
																[closeOnSelect]="true"
																[searchable]="true"
																bindLabel="location_name"
																name="sel_Location"
																id="sel_Location"
																placeholder="{{
																	'UI.k_sel_base_location'
																	| translate
																}}"
																notFoundText="{{
																	'UI.k_no_items_found'
																	| translate
																}}"
																[attr.data-testid]="'sel_Location'"
																(change)="LocationMap($event, addressForm)"
															>
															</ng-select>
														</div>
														<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">
															<div class="d-flex justify-content-between align-items-center">
																<label class="form-label" for="txtLoc">{{
																	"UI.k_work_location" | translate
																	}}</label>
															</div>
															<ng-select
																[(ngModel)]="selectedWorkLocation"
																[items]="dropdownData.WORK_LOCATION_LIST"
																[multiple]="false"
																[closeOnSelect]="true"
																[searchable]="true"
																bindLabel="location_name"
																name="sel_work_Location"
																id="sel_work_Location"
																placeholder="{{
																	'UI.k_select_work_location'
																	| translate
																}}"
																notFoundText="{{
																	'UI.k_no_items_found'
																	| translate
																}}"
																[attr.data-testid]="'sel_work_Location'"
																(change)="WorkLocationMap($event, addressForm)"
															>
															</ng-select>
														</div>
													</div>
													<div *ngIf="home_address || (!requester.organization_name && requester.type == 2)">
														<hr class="ml-1 mr-1" *ngIf="home_address"/>
														<h6 class="mb-1 ml-1" *ngIf="home_address">Work From Home Address</h6>
														<div class="d-flex">
															<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">
																<label class="form-label" for="txtLoc">{{
																	"UI.k_location_name" | translate
																}}</label>
																<input
																	[(ngModel)]="requester.work_location.location_name"
																	#TDLocRef="ngModel"
																	type="text"
																	id="txtLoc"
																	name="txtLoc"
																	class="form-control"
																	placeholder="{{
																		'UI.k_placeholder_org_location' | translate
																	}}"
																	minlength="2"
																	maxlength="100"
																	pattern="^[^\s]+(\s+[^\s]+)*$"
																	[class.error]="
																		addressForm.submitted && TDLocRef.invalid
																	"
																/>
																<span
																	*ngIf="
																		addressForm.submitted &&
																		TDLocRef.invalid &&
																		(TDLocRef.dirty || TDLocRef.touched)
																	"
																	class="invalid-form"
																>
																	<small
																		class="form-text text-danger"
																		*ngIf="
																			TDLocRef.errors.minlength ||
																			TDLocRef.errors.maxlength
																		"
																	>
																		{{ "UI.k_location_validation" | translate }}
																	</small>
																	<small
																		class="form-text text-danger"
																		*ngIf="TDLocRef.errors.pattern"
																		>{{ "Error.err_ltspace" | translate }}
																	</small>
																</span>
															</div>
															<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">
																<label class="form-label" for="txtCtry">{{
																	"UI.k_country" | translate
																}}</label>
																<input
																	[(ngModel)]="requester.work_location.country"
																	#TDCountryRef="ngModel"
																	type="text"
																	id="txtCtry"
																	name="txtCtry"
																	class="form-control"
																	placeholder="{{
																		'UI.k_placeholder_country' | translate
																	}}"
																	minlength="2"
																	maxlength="100"
																	pattern="^[^\s]+(\s+[^\s]+)*$"
																	[class.error]="
																		addressForm.submitted && TDCountryRef.invalid
																	"
																/>
																<span
																	*ngIf="
																		addressForm.submitted &&
																		TDCountryRef.invalid &&
																		(TDCountryRef.dirty || TDCountryRef.touched)
																	"
																	class="invalid-form"
																>
																	<small
																		class="form-text text-danger"
																		*ngIf="
																			TDCountryRef.errors.minlength ||
																			TDCountryRef.errors.maxlength
																		"
																		>{{ "UI.k_country_validation" | translate }}
																	</small>
																	<small
																		class="form-text text-danger"
																		*ngIf="TDCountryRef.errors.pattern"
																		>{{ "Error.err_ltspace" | translate }}
																	</small>
																</span>
															</div>
															<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">
																<label class="form-label" for="txtCity">{{
																	"UI.k_city" | translate
																}}</label>
																<input
																	[(ngModel)]="requester.work_location.city"
																	#TDCityRef="ngModel"
																	type="text"
																	id="txtCity"
																	name="txtCity"
																	class="form-control"
																	placeholder="{{ 'UI.k_placeholder_city' | translate }}"
																	minlength="2"
																	maxlength="100"
																	pattern="^[^\s]+(\s+[^\s]+)*$"
																	[class.error]="
																		addressForm.submitted && TDCityRef.invalid
																	"
																/>
																<span
																	*ngIf="
																		addressForm.submitted &&
																		TDCityRef.invalid &&
																		(TDCityRef.dirty || TDCityRef.touched)
																	"
																	class="invalid-form"
																>
																	<small
																		class="form-text text-danger"
																		*ngIf="
																			TDCityRef.errors.minlength ||
																			TDCityRef.errors.maxlength
																		"
																		>{{ "UI.k_city_validation" | translate }}
																	</small>
																	<small
																		class="form-text text-danger"
																		*ngIf="TDCityRef.errors.pattern"
																		>{{ "Error.err_ltspace" | translate }}
																	</small>
																</span>
															</div>
														</div>
														<div class="d-flex">
															<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">
																<label class="form-label" for="txtState">{{
																	"UI.k_state" | translate
																}}</label>
																<input
																	[(ngModel)]="requester.work_location.state"
																	#TDStateRef="ngModel"
																	type="text"
																	id="txtState"
																	name="txtState"
																	class="form-control"
																	placeholder="{{ 'UI.k_placeholder_state' | translate }}"
																	minlength="2"
																	maxlength="100"
																	pattern="^[^\s]+(\s+[^\s]+)*$"
																	[class.error]="
																		addressForm.submitted && TDStateRef.invalid
																	"
																/>
																<span
																	*ngIf="
																		addressForm.submitted &&
																		TDStateRef.invalid &&
																		(TDStateRef.dirty || TDStateRef.touched)
																	"
																	class="invalid-form"
																>
																	<small
																		class="form-text text-danger"
																		*ngIf="
																			TDStateRef.errors.minlength ||
																			TDStateRef.errors.maxlength
																		"
																		>{{ "UI.k_state_validation" | translate }}
																	</small>
																	<small
																		class="form-text text-danger"
																		*ngIf="TDStateRef.errors.pattern"
																		>{{ "Error.err_ltspace" | translate }}
																	</small>
																</span>
															</div>
															<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">
																<label class="form-label" for="txtPinCode">{{
																	"UI.k_postal_code" | translate
																}}</label>
																<input
																	type="text"
																	id="txtPinCode"
																	name="txtPinCode"
																	class="form-control"
																	placeholder="{{
																		'UI.k_placeholder_pincode' | translate
																	}}"
																	[(ngModel)]="requester.work_location.postalcode"
																	#TDPostalRef="ngModel"
																	minlength="2"
																	maxlength="100"
																	pattern="^[^\s]+(\s+[^\s]+)*$"
																	[class.error]="
																		addressForm.submitted && TDPostalRef.invalid
																	"
																/>
																<span
																	*ngIf="
																		addressForm.submitted &&
																		TDPostalRef.invalid &&
																		(TDPostalRef.dirty || TDPostalRef.touched)
																	"
																	class="invalid-form"
																>
																	<small
																		class="form-text text-danger"
																		*ngIf="
																			TDPostalRef.errors.minlength ||
																			TDPostalRef.errors.maxlength
																		"
																		>{{ "UI.k_postal_code_validation" | translate }}
																	</small>
																	<small
																		class="form-text text-danger"
																		*ngIf="TDPostalRef.errors.pattern"
																		>{{ "Error.err_ltspace" | translate }}
																	</small>
																</span>
															</div>
															<!-- <div class="form-group col-md-6">
								<label class="form-label" for="city1">City</label>
								<input
									type="text"
									id="city1"
									class="form-control"
									placeholder="Birmingham"
								/>
								</div> -->
															<div class="form-group col-xs-12 col-md-4 col-xl-4 col-lg-4">
																<label class="form-label" for="txtLndmrk">{{
																	"UI.k_landmark" | translate
																}}</label>
																<input
																	[(ngModel)]="requester.work_location.landmark"
																	type="text"
																	name="txtLndmrk"
																	id="txtLndmrk"
																	class="form-control"
																	placeholder="{{
																		'UI.k_placeholder_landmark' | translate
																	}}"
																	#TDLandmarkRef="ngModel"
																	minlength="2"
																	maxlength="100"
																	pattern="^[^\s]+(\s+[^\s]+)*$"
																	[class.error]="
																		addressForm.submitted && TDLandmarkRef.invalid
																	"
																/>
																<span
																	*ngIf="
																		addressForm.submitted &&
																		TDLandmarkRef.invalid &&
																		(TDLandmarkRef.dirty || TDLandmarkRef.touched)
																	"
																	class="invalid-form"
																>
																	<small
																		class="form-text text-danger"
																		*ngIf="
																			TDLandmarkRef.errors.minlength ||
																			TDLandmarkRef.errors.maxlength
																		"
																		>{{ "UI.k_landmark_validation" | translate }}
																	</small>
																	<small
																		class="form-text text-danger"
																		*ngIf="TDLandmarkRef.errors.pattern"
																		>{{ "Error.err_ltspace" | translate }}
																	</small>
																</span>
															</div>
														</div>
														<div class="d-flex">
															<div class="form-group col-xs-12 col-md-8 col-xl-8 col-lg-8">
																<label class="form-label" for="txtUsrBldng"
																	>{{ "UI.k_flat" | translate }},
																	{{ "UI.k_house_no" | translate }},
																	{{ "UI.k_building" | translate }},
																	{{ "UI.k_company" | translate }}</label
																>
																<input
																	[(ngModel)]="requester.work_location.building"
																	#TDFlatRef="ngModel"
																	type="text"
																	id="txtUsrBldng"
																	name="txtUsrBldng"
																	class="form-control"
																	placeholder="{{
																		'UI.k_placeholder_building' | translate
																	}}"
																	minlength="2"
																	maxlength="100"
																	pattern="^[^\s]+(\s+[^\s]+)*$"
																	[class.error]="
																		addressForm.submitted && TDFlatRef.invalid
																	"
																/>
																<span
																	*ngIf="
																		addressForm.submitted &&
																		TDFlatRef.invalid &&
																		(TDFlatRef.dirty || TDFlatRef.touched)
																	"
																	class="invalid-form"
																>
																	<small
																		class="form-text text-danger"
																		*ngIf="
																			TDFlatRef.errors.minlength ||
																			TDFlatRef.errors.maxlength
																		"
																		>{{ "UI.k_validation" | translate }}
																	</small>
																	<small
																		class="form-text text-danger"
																		*ngIf="TDFlatRef.errors.pattern"
																		>{{ "Error.err_ltspace" | translate }}
																	</small>
																</span>
															</div>
														</div>

														<div class="d-flex">
															<div class="form-group col-xs-12 col-md-8 col-xl-8 col-lg-8">
																<label class="form-label" for="txtUsrArea"
																	>{{ "UI.k_area" | translate }},
																	{{ "UI.k_colony" | translate }},
																	{{ "UI.k_street" | translate }},
																	{{ "UI.k_sector" | translate }},
																	{{ "UI.k_village" | translate }}</label
																>
																<input
																	[(ngModel)]="requester.work_location.area"
																	#TDAreaRef="ngModel"
																	type="text"
																	id="txtUsrArea"
																	name="txtUsrArea"
																	class="form-control"
																	placeholder="{{ 'UI.k_placeholder_area' | translate }}"
																	minlength="2"
																	maxlength="100"
																	pattern="^[^\s]+(\s+[^\s]+)*$"
																	[class.error]="
																		addressForm.submitted && TDAreaRef.invalid
																	"
																/>
																<span
																	*ngIf="
																		addressForm.submitted &&
																		TDAreaRef.invalid &&
																		(TDAreaRef.dirty || TDAreaRef.touched)
																	"
																	class="invalid-form"
																>
																	<small
																		class="form-text text-danger"
																		*ngIf="
																			TDAreaRef.errors.minlength ||
																			TDAreaRef.errors.maxlength
																		"
																		>{{ "UI.k_area_validation" | translate }}
																	</small>
																	<small
																		class="form-text text-danger"
																		*ngIf="TDAreaRef.errors.pattern"
																		>{{ "Error.err_ltspace" | translate }}
																	</small>
																</span>
															</div>
														</div>

														<div class="d-flex">
															<div class="form-group col-xs-12 col-md-8 col-xl-8 col-lg-8">
																<label class="form-label" for="txtLocDescr">{{
																	"UI.k_add_info" | translate
																}}</label>
																<input
																	[(ngModel)]="requester.work_location.description"
																	#TDlocDescrRef="ngModel"
																	type="text"
																	id="txtLocDescr"
																	name="locDescr"
																	class="form-control"
																	placeholder="{{
																		'UI.k_address_description' | translate
																	}}"
																	minlength="3"
																	maxlength="3000"
																	pattern="^[^\s]+(\s+[^\s]+)*$"
																	[class.error]="
																		addressForm.submitted && TDlocDescrRef.invalid
																	"
																/>
																<span
																	*ngIf="
																		addressForm.submitted &&
																		TDlocDescrRef.invalid &&
																		(TDlocDescrRef.dirty || TDlocDescrRef.touched)
																	"
																	class="invalid-form"
																>
																	<small
																		class="form-text text-danger"
																		*ngIf="TDlocDescrRef.errors.pattern"
																		>{{ "Error.err_ltspace" | translate }}
																	</small>
																	<small
																		class="form-text text-danger"
																		*ngIf="
																			TDlocDescrRef.errors.minlength ||
																			TDlocDescrRef.errors.maxlength
																		"
																		>{{ "UI.k_description_validation" | translate }}
																	</small>
																</span>
															</div>
														</div>
													</div>
												</ng-scrollbar>
											</div>

											<div class="stepper-btn d-flex justify-content-between">
												<button
													(click)="horizontalWizardStepperPrevious()"
													class="btn btn-flat-primary btn-prev"
													rippleEffect
													id="btnLocPrev"
													name="btnLocPrev"
													[attr.data-testid]="'btnLocPrev'"
												>
													<i class="fa-light fa-arrow-left align-middle mr-50 mr-0"></i>
													<span class="align-middle d-sm-inline-block d-none">{{
														"UI.k_back" | translate
													}}</span>
												</button>
												<button
													*ngIf="!requester?.is_deleted"
													class="btn btn-primary btn-submit"
													(click)="onSubmit(addressForm)"
													[disabled]="is_disable_save"
													rippleEffect
													id="btnLocSub"
													name="btnLocSub"
													[attr.data-testid]="'btnLocSub'"
												>
													<i class="fa-light fa-floppy-disk align-middle mr-50 ml-0"></i>
													<span class="align-middle d-sm-inline-block d-none">{{
														submitButton
													}}</span>
													<!--                          <i class="fa-light fa-arrow-right align-middle ml-50 ml-0"></i>-->
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</form>
		</div>
	</div>
</div>

