import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class ChannelService {
	public channelMessage = new BehaviorSubject(null);
	// public channelMessage = this.channel.asObservable();

	constructor() {}

	pushToChannel(message: any) {
		this.channelMessage.next(message);
	}
}
