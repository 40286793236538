<ng-container *ngIf="gridview">
	<table class="mtable history-table border" *ngIf="gridview">
		<thead>
			<tr class="gtr border-bottom">
				<th class="thcolor pl-2" style="border: none; width: 25%">
					{{ "UI.k_user" | translate }}
				</th>
				<th class="thcolor" style="border: none; width: 20%">
					{{ "UI.k_time" | translate }}
				</th>
				<th class="thcolor" style="border: none; width: 55%">
					{{ "UI.k_change" | translate }}
				</th>
			</tr>
		</thead>
		<tbody>
			<tr class="gtr border-bottom" *ngFor="let history of gridview; let i = index">
				<td class="gtd">
					<div class="avatar avatar-sm">
						<img *ngIf="history?.user_profile" [src]="history?.user_profile" class="avatar-content" />
						<span
							*ngIf="!history?.user_profile"
							[ngStyle]="{
								'background-color': history?.avatar_color ? history?.avatar_color : '#a5c1d4'
							}"
							class="avatar-content"
							>{{ history.owner_name | initials | slice: O:2 }}</span
						>
					</div>
					<span [ngbTooltip]="history?.owner_name">
						{{ history?.owner_name | slice: 0:15 }}
						{{ history?.owner_name?.length > 15 ? "..." : "" }}
					</span>
				</td>
				<td class="gtd">{{ history.creation_time }}</td>
				<table class="mtable">
					<tr class="gtr border-bottom" *ngFor="let his of history.event_data | keyvalue">
						<td
							class="gtd"
							style="width: 53%"
							*ngIf="
								!his.key.includes('k_') &&
								!his.key.includes('@@@') &&
								(history.event_data | diclen) == 1
							"
						>
							{{ history.event_title }}
						</td>
						<td
							class="gtd"
							style="width: 53%"
							*ngIf="
								!his.key.includes('k_') && !his.key.includes('@@@') && (history.event_data | diclen) > 1
							"
						>
							{{ his?.key }}
						</td>
						<td
							class="gtd"
							style="width: 53%"
							*ngIf="his.key === 'k_attachment' && (his.key.includes('k_') || his.key.includes('@@@'))"
						>
							{{
								getTranslatableKey(
									his.key === "k_attachment" ? "k_file_name" : his.key.replaceAll("@@@", "")
								) | translate
							}}
						</td>
						<td
							class="gtd"
							style="width: 53%"
							*ngIf="(his.key !== 'k_attachment' && his.key.includes('k_')) || his.key.includes('@@@')"
						>
							{{ getTranslatedData(his.key) | translate }}
						</td>

						<span
							*ngIf="his.key != 'k_attachment' && his.key != 'k_api_link_data' && his.key != 'k_kb_image'"
						>
							<td class="gtd pl-2" style="width: 30%" *ngIf="(his.value | diclen) >= 1">
								<div *ngFor="let content of his.value | keyvalue: keepOrder">
									<div>
										{{ content.key }}:
										<span
											*ngIf="htmlcols?.includes(his.key)"
											[innerHTML]="readMore ? content.value : content.value.slice(0, limit)"
										></span>
										<span *ngIf="!htmlcols?.includes(his.key) && his.key != 'k_kb_image'">
											{{ readMore ? content.value : (content.value | slice: 0:limit) }}
											{{ content.value?.length > limit ? "..." : "" }}
										</span>
										<span *ngIf="!htmlcols?.includes(his.key) && his.key == 'k_kb_image'">
											<img
												class="default-img mr-1"
												src="{{ his.value }}"
												alt="datatable-avatar"
											/>
										</span>
										<a
											*ngIf="!readMore && content.value.length > limit"
											class="text-primary"
											(click)="limit = content.value.length; readMore = true"
											>{{ "UI.k_read_more" | translate }}</a
										>
									</div>
								</div>
							</td>
							<td class="ql-snow gtd" *ngIf="(his.value | diclen) === 0">
								<span *ngIf="history['user_profile_map']">
									<span *ngIf="history['user_profile_map'][his.key]">
										<div class="avatar avatar-sm">
											<img
												*ngIf="history['user_profile_map'][his.key]"
												[src]="history['user_profile_map'][his.key]"
												width="32"
												height="32"
												class="avatar-content"
											/>
											<span
												*ngIf="!history['user_profile_map'][his.key]"
												[ngStyle]="{
													'background-color': history?.avatar_color
														? history?.avatar_color
														: '#a5c1d4'
												}"
												class="avatar-content"
												>{{ his.value | initials | slice: O:2 }}
											</span>
										</div>
									</span>
								</span>
								<span
									class="ql-editor"
									*ngIf="
										!his.value.includes('@@@') &&
										!his.key.includes('k_') &&
										((history.event_data | diclen) > 1 || history?.show_table)
									"
									[innerHTML]="his.value"
									dnsReadMore
									[readMore-length]="readMoreLength"
								></span>
								<!--<span-->
								<!--*ngIf="-->
								<!--!htmlcols?.includes(his.key) && !his.value.includes('@@@') &&  !his.key.includes('k_') && (history.event_data | diclen) > 1-->
								<!--"-->
								<!--[innerHTML]="his.value"-->
								<!--dnsReadMore-->
								<!--[readMore-length]="readMoreLength"-->
								<!--&gt;-->
								<!---->
								<!--&lt;!&ndash; {{ readMore ? his.value : (his.value | slice: 0:limit) }} &ndash;&gt;-->
								<!--</span>-->
								<div
									class="media align-items-center"
									*ngIf="
										!his.key.includes('k_') &&
										!his.key.includes('@@@') &&
										(history.event_data | diclen) == 1 &&
										!history?.show_table
									"
								>
									<div class="frame_box border">
										<span
											class="badge badge-light-warning badge-content mb-1"
											[innerHTML]="his.key"
											dnsReadMore
											[readMore-length]="readMoreLength"
										>
										</span>
										<div *ngIf="history.event_type != removed_text">
											<i class="fa fa-arrow-right fa-xs px-2"></i>
										</div>
										<span
											class="badge badge-light-success badge-content"
											[innerHTML]="his.value"
											dnsReadMore
											[readMore-length]="readMoreLength"
										>
										</span>
									</div>
								</div>
								<div
									class="px-1"
									*ngIf="
										(his.key.includes('k_') || his.key.includes('@@@')) &&
										!his.value.includes('@@@')
									"
									[innerHTML]="his.value | safe: 'html'"
									dnsReadMore
									[readMore-length]="readMoreLength"
								></div>

								<span *ngIf="!htmlcols?.includes(his.key)" class="ql-editor">
									<span *ngIf="readMore && his.value.includes('@@@')"
										>{{ getTranslatedData(his.value) }}
									</span>
									<span *ngIf="!readMore && his.value.includes('@@@')"
										>{{ getTranslatedData(his.value) }}
									</span>
								</span>
								<!--<a-->
								<!--*ngIf="!readMore && his.value.length > limit"-->
								<!--class="text-primary"-->
								<!--(click)="limit = his.value.length; readMore = true"-->
								<!--&gt;{{ 'UI.k_read_more' | translate }}</a-->
								<!--&gt;-->
							</td>
						</span>
						<span *ngIf="his.key == 'k_api_link_data'">
							<span *ngIf="htmlcols?.includes(his.key)" [innerHTML]="his.value"></span>
						</span>
						<span *ngIf="his.key == 'k_kb_image'">
							<img class="default-img p-50 mb-0" src="{{ his.value }}" />
						</span>
						<span *ngIf="his.key == 'k_attachment'">
							<td class="text-break border-0" *ngFor="let file of his.value" style="font-size: 12px">
								<!-- <img src="assets/images/icons/file-icons/pdf.png" alt="invoice" height="20" /> -->
								<span class="avatar-content" id="feather_size">
									<i
										[ngClass]="
											file.file_name.split('.')[1] == 'txt'
												? 'fa-file-lines'
												: file.file_name.split('.')[1] == 'pdf'
												? 'fa-file-pdf'
												: file.file_name.split('.')[1] == 'xls' ||
												  file.file_name.split('.')[1] == 'xlsx'
												? 'fa-file-excel'
												: file.file_name.split('.')[1] == 'doc' ||
												  file.file_name.split('.')[1] == 'docx'
												? 'fa-file-word'
												: 'fa-file'
										"
										class="fa-light lg-font"
										aria-hidden="true"
									></i> </span
								>&nbsp;
								<span [innerHTML]="file.file_name" dnsReadMore [readMore-length]="readMoreLength">
								</span
								>&nbsp;&nbsp;
							</td>
						</span>
					</tr>
				</table>
			</tr>
		</tbody>
	</table>
	<div
		class="d-flex justify-content-center align-items-center py-1"
		*ngIf="LoadMore && gridview?.length < commDataTotalCount"
	>
		<button
			class="btn-sm btn-outline-primary d-flex align-items-center justify-content-between"
			type="button"
			(click)="loadNextComments()"
		>
			{{ "UI.k_load_more" | translate }}
		</button>
	</div>
</ng-container>

<ul class="timeline" *ngIf="listview">
	<ng-container *ngFor="let history of listview | filter: searchText; let i = index">
		<li class="timeline-item">
			<span class="timeline-point">
				<i *ngIf="history.label" class="fa-light {{ history.label }} align-middle font-small-1"></i>
			</span>
			<div class="timeline-event">
				<h6 *ngIf="!history.event_title.includes('k_')" class="Card-Title">{{ history.event_title }}</h6>
				<h6 *ngIf="history.event_title.includes('k_')" class="Card-Title">
					{{ getTranslatedData(history.event_title) }}
				</h6>
				<div
					class="Frame-6774"
					[ngClass]="{
						'mb-2': history.event_type == 'Bookmarked',
						'mb-2': history.event_type == 'Like'
					}"
				>
					<div class="avatar avatar-sm">
						<img
							*ngIf="history?.user_profile"
							[src]="history?.user_profile"
							width="32"
							height="32"
							class="avatar-content"
						/>
						<div
							*ngIf="!history?.user_profile"
							[ngStyle]="{
								'background-color': history?.avatar_color ? history?.avatar_color : '#a5c1d4'
							}"
							class="avatar-content"
						>
							{{ history.owner_name | initials | slice: O:2 }}
						</div>
					</div>
					<span class="Card-Title-Sub"
						>{{ history?.owner_name | slice: 0:15 }}
						{{ history?.owner_name?.length > 15 ? "..." : "" }}
					</span>
					<span class="dot"></span>
					<span style="font-size: 12px"> {{ history.creation_time }}</span>
				</div>
				<div *ngIf="history.event_type === 'attachment'">
					<table class="table mb-2">
						<tbody *ngFor="let his of history.event_data | keyvalue; let j = index" [attr.data-index]="">
							<tr>
								<th class="thcolor">
									{{
										getTranslatableKey(his?.key === "k_attachment" ? "k_file_name" : his?.key)
											| translate
									}}
								</th>
								<td>
									<ng-container *ngIf="his?.key === 'k_attachment'">
										<span
											class="frame_box border font-small-3 text-break"
											*ngFor="let file of history.event_data.k_attachment"
										>
											<!-- <img class="mr-1" src="assets/images/icons/file-icons/pdf.png" alt="invoice" height="15" /> -->
											<span class="avatar-content" id="feather_size">
												<i
													[ngClass]="
														file.file_name.split('.')[1] == 'txt'
															? 'fa-file-lines'
															: file.file_name.split('.')[1] == 'pdf'
															? 'fa-file-pdf'
															: file.file_name.split('.')[1] == 'xls' ||
															  file.file_name.split('.')[1] == 'xlsx'
															? 'fa-file-excel'
															: file.file_name.split('.')[1] == 'doc' ||
															  file.file_name.split('.')[1] == 'docx'
															? 'fa-file-word'
															: 'fa-file'
													"
													class="fa-light lg-font"
													aria-hidden="true"
												></i> </span
											>&nbsp;
											<span
												[innerHTML]="file.file_name"
												dnsReadMore
												[readMore-length]="readMoreLength"
											>
											</span
											>&nbsp;&nbsp;
										</span>
									</ng-container>
									<ng-container *ngIf="his?.key !== 'k_attachment'">
										<span [innerHTML]="his?.value" dnsReadMore [readMore-length]="readMoreLength">
										</span>
									</ng-container>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div
					*ngIf="
						history.event_type != 'attachment' && ((history.event_data | diclen) > 1 || history.show_table)
					"
				>
					<table class="table mb-2">
						<tbody *ngFor="let his of history.event_data | keyvalue; let j = index" [attr.data-index]="">
							<tr *ngIf="j <= 2">
								<th class="thcolor">
									{{ getTranslatedData(his?.key) }}
								</th>
								<td *ngIf="(his.value | diclen) >= 1">
									<div *ngFor="let content of his.value | keyvalue: keepOrder">
										<div class="ql-snow">
											<span class="ql-editor">{{ content.key }}:</span>
											<span
												*ngIf="htmlcols?.includes(his.key)"
												class="ql-editor"
												[innerHTML]="content.value | translate"
											></span>
											<span *ngIf="!htmlcols?.includes(his.key)" class="ql-editor">
												{{ content.value | translate }}
											</span>
										</div>
									</div>
								</td>
								<td class="ql-snow" *ngIf="(his.value | diclen) === 0">
									<span *ngIf="history['user_profile_map']">
										<span *ngIf="history['user_profile_map'][his.key]">
											<div class="avatar avatar-xs">
												<img
													*ngIf="history['user_profile_map'][his.key]"
													[src]="history['user_profile_map'][his.key]"
													width="32"
													height="32"
													class="avatar-content"
												/>
												<div
													*ngIf="!history['user_profile_map'][his.key]"
													[ngStyle]="{
														'background-color': history?.avatar_color
															? history?.avatar_color
															: '#a5c1d4'
													}"
													class="avatar-content"
												>
													{{ his.value | initials | slice: O:2 }}
												</div>
											</div>
										</span>
									</span>
									<span *ngIf="his.key == 'k_kb_image'">
										<img class="default-img mr-1" src="{{ his.value }}" />
									</span>
									<!--*ngIf="htmlcols?.includes(his.key)"-->
									<span
										*ngIf="!his.value.includes('@@@') && his.key != 'k_kb_image'"
										class="ql-editor"
										[innerHTML]="his.value | safe: 'html'"
										dnsReadMore
										[readMore-length]="readMoreLength"
									></span>
									<!--<span-->
									<!--*ngIf="-->
									<!--!htmlcols?.includes(his.key) && !his.value.includes('@@@')-->
									<!--"-->
									<!--[innerHTML]="his.value"-->
									<!--dnsReadMore-->
									<!--[readMore-length]="readMoreLength"-->
									<!--&gt;-->
									<!--&lt;!&ndash;{{ readMore ? his.value : (his.value | slice: 0:limit) }}&ndash;&gt;-->
									<!--</span>-->
									<span
										class="ql-editor"
										*ngIf="!htmlcols?.includes(his.key) && his.key != 'k_kb_image'"
									>
										<span *ngIf="readMore && his.value.includes('@@@')">{{
											getTranslatedData(his.value)
										}}</span>
										<span *ngIf="!readMore && his.value.includes('@@@')">{{
											getTranslatedData(his.value) | slice: 0:limit
										}}</span>
									</span>
									<!--<a-->
									<!--*ngIf="!readMore && his.value.length > limit"-->
									<!--class="text-primary"-->
									<!--(click)="limit = his.value.length && (readMore = true)"-->
									<!--&gt;{{ 'UI.k_read_more' | translate }}</a-->
									<!--&gt;-->
								</td>
							</tr>
							<tr *ngIf="history?.show && j >= 3">
								<th class="thcolor">
									{{ getTranslatedData(his.key) }}
								</th>
								<td class="gtd" *ngIf="(his.value | diclen) >= 1">
									<div *ngFor="let content of his.value | keyvalue: keepOrder">
										<div>
											<span class="ql-editor">{{ content.key }}:</span>
											<span
												class="ql-editor"
												*ngIf="htmlcols?.includes(his.key)"
												[innerHTML]="content.value"
											></span>
											<span
												*ngIf="!htmlcols?.includes(his.key) && his.key != 'k_kb_image'"
												class="ql-editor"
												>{{ content.value }}</span
											>
											<span *ngIf="!htmlcols?.includes(his.key) && his.key == 'k_kb_image'">
												<img class="default-img mr-1" src="{{ his.value }}" />
											</span>
										</div>
									</div>
								</td>
								<td *ngIf="(his.value | diclen) === 0">
									<span *ngIf="history['user_profile_map']">
										<span *ngIf="history['user_profile_map'][his.key]">
											<div class="avatar avatar-sm">
												<img
													*ngIf="history['user_profile_map'][his.key]"
													[src]="history['user_profile_map'][his.key]"
													width="32"
													height="32"
													class="avatar-content"
												/>
												<div
													*ngIf="!history['user_profile_map'][his.key]"
													[ngStyle]="{
														'background-color': history?.avatar_color
															? history?.avatar_color
															: '#a5c1d4'
													}"
													class="avatar-content"
												>
													{{ his.value }}
												</div>
											</div>
										</span> </span
									><span
										class="ql-editor"
										*ngIf="htmlcols?.includes(his.key)"
										[innerHTML]="his.value | safe: 'html'"
										dnsReadMore
										[readMore-length]="readMoreLength"
									></span>
									<div
										class="mb-25 px-1"
										*ngIf="
											!htmlcols?.includes(his.key) &&
											!his.value.includes('@@@') &&
											his.key != 'k_kb_image'
										"
										[innerHTML]="his.value"
										dnsReadMore
										[readMore-length]="readMoreLength"
									>
										<!--{{ readMore ? his.value : his.value | slice: 0:limit) }}-->
									</div>
									<span *ngIf="his.key == 'k_kb_image'">
										<img class="default-img mr-1" src="{{ his.value }}" />
									</span>
									<span
										class="ql-editor"
										*ngIf="
											!htmlcols?.includes(his.key) &&
											his.key != 'k_kb_image' &&
											readMore &&
											his.value.includes('@@@')
										"
										>{{ getTranslatedData(his.value) }}
									</span>
									<span
										class="ql-editor"
										*ngIf="
											!htmlcols?.includes(his.key) &&
											his.key != 'k_kb_image' &&
											!readMore &&
											his.value.includes('@@@')
										"
										>{{ getTranslatedData(his.value) | slice: 0:limit }}</span
									>
								</td>
							</tr>
							<tr *ngIf="!history.show && j == 3">
								<td colspan="2">
									<button
										id="btnShowAll{{ i }}"
										name="show"
										class="btn btn-sm btn-flat-primary btn-prev"
										(click)="showall(i, history.id)"
										rippleEffect
									>
										<i class="fa-light fa-angle-down align-middle mr-sm-25 mr-0"></i>
										<span class="align-middle d-sm-inline-block d-none">{{
											"UI.k_show_all" | translate
										}}</span>
									</button>
								</td>
							</tr>
							<tr *ngIf="history.show && (history.event_data | diclen) == j + 1">
								<td colspan="2">
									<button
										id="btnHide{{ i }}"
										name="hide"
										class="btn btn-sm btn-flat-primary btn-prev"
										(click)="hide(i, history.id)"
										rippleEffect
									>
										<i class="fa-light fa-angle-up align-middle mr-sm-25 mr-0"></i>
										<span class="align-middle d-sm-inline-block d-none">{{
											"UI.k_hide" | translate
										}}</span>
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div *ngFor="let his of history.event_data | keyvalue">
					<div
						*ngIf="
							(history.event_data | diclen) == 1 &&
							history?.event_type != 'Like' &&
							history?.event_type != 'Bookmarked' &&
							history?.event_type != 'Dislike' &&
							history?.event_type != 'Upload Image' &&
							history?.event_type != 'showlabel' &&
							!history?.show_table
						"
					>
						<div
							*ngIf="history.event_type === new_text"
							class="media align-items-center mt-1 mb-2"
							style="font-size: small; overflow-wrap: anywhere"
						>
							<span
								class="frame_box border"
								dnsReadMore
								[readMore-length]="readMoreLength"
								[innerHTML]="his.value | safe: 'html'"
							></span>
						</div>
						<div
							*ngIf="history.event_type === removed_text"
							class="media align-items-center mt-1 mb-2"
							style="font-size: small; overflow-wrap: anywhere"
						>
							<span
								class="frame_box border"
								dnsReadMore
								[readMore-length]="readMoreLength"
								[innerHTML]="his.key"
							></span>
						</div>
						<div
							*ngIf="history.icon === 'attachment'"
							class="media align-items-center mt-1 mb-2"
							style="font-size: small; overflow-wrap: anywhere"
						>
							<!-- <img
								class="mr-1"
								src="assets/images/icons/file-icons/pdf.png"
								alt="invoice"
								height="23"
							/> -->
							<span class="avatar-content" id="feather_size">
								<i
									[ngClass]="
										file.file_name.split('.')[1] == 'txt'
											? 'fa-file-lines'
											: file.file_name.split('.')[1] == 'pdf'
											? 'fa-file-pdf'
											: file.file_name.split('.')[1] == 'xls' ||
											  file.file_name.split('.')[1] == 'xlsx'
											? 'fa-file-excel'
											: file.file_name.split('.')[1] == 'doc' ||
											  file.file_name.split('.')[1] == 'docx'
											? 'fa-file-word'
											: 'fa-file'
									"
									class="fa-light lg-font"
									aria-hidden="true"
								></i>
							</span>

							{{ his.value }}
						</div>
						<div
							*ngIf="
								history.icon !== 'attachment' &&
								history.event_type !== 'attachment' &&
								history.event_type != new_text &&
								history.event_type != removed_text
							"
							class="mt-1 mb-2"
						>
							<!-- <div class="badge badge-light-warning">{{ "UI."+his.key | translate}}</div> -->
							<div style="display: inline-grid" *ngIf="(his.value | diclen) >= 0">
								<div *ngFor="let content of his.value | keyvalue: keepOrder">
									<span>{{ content.key }} : {{ content.value }}</span>
								</div>
							</div>
							<div class="media align-items-center" *ngIf="(his.value | diclen) === 0">
								<div class="frame_box border d-flex align-items-center flex-wrap gap-1">
									<div
										class="badge badge-light-warning badge-content"
										*ngIf="
											his.key.includes('msg_') ||
											his.key.includes('err_') ||
											his.key.includes('k_')
										"
									>
										{{ getTranslatedData(his.key) }}
									</div>
									<div
										class="badge badge-light-warning badge-content"
										*ngIf="
											!(
												his.key.includes('msg_') ||
												his.key.includes('err_') ||
												his.key.includes('k_')
											)
										"
										[innerHTML]="his.key"
										dnsReadMore
										[readMore-length]="readMoreLength"
									>
										<!-- {{ his.key }} -->
									</div>
									<i class="fa fa-arrow-right fa-xs"></i>
									<span *ngIf="history['user_profile_map']">
										<span *ngIf="history['user_profile_map'][his.key]">
											<div class="avatar">
												<img
													*ngIf="history['user_profile_map'][his.key]"
													[src]="history['user_profile_map'][his.key]"
													width="32"
													height="32"
													class="avatar-content avatar-size-20"
												/>
												<span
													*ngIf="!history['user_profile_map'][his.key]"
													[ngStyle]="{
														'background-color': history?.avatar_color
															? history?.avatar_color
															: '#a5c1d4'
													}"
													class="avatar-content avatar-size-16"
												>
													{{ his.value | initials | slice: O:2 }}
												</span>
											</div> </span
										>&nbsp;
									</span>
									<span *ngIf="his.key == 'k_kb_image'">
										<img class="default-img mr-1" src="{{ his.value }}" />
									</span>
									<div class="ql-snow" class="badge badge-light-success badge-content">
										<span
											class=""
											*ngIf="!his.value.includes('@@@') && his.key != 'k_kb_image'"
											[innerHTML]="getTranslatedData(his.value) | safe: 'html' | translate"
											dnsReadMore
											[readMore-length]="readMoreLength"
										></span>
										<!-- <span
										*ngIf="
											!htmlcols?.includes(his.key) &&
											!his.value.includes('@@@')
										"
									>
										{{ his.value }}
									</span> -->
										<span
											class=""
											*ngIf="!htmlcols?.includes(his.key) && his.value.includes('@@@')"
										>
											{{ getTranslatedData(his.value) }}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</li>
	</ng-container>
	<div
		class="d-flex justify-content-center align-items-center py-1"
		*ngIf="LoadMore && listview?.length < commDataTotalCount"
	>
		<button
			class="btn-sm btn-outline-primary d-flex align-items-center justify-content-between"
			type="button"
			(click)="loadNextComments()"
		>
			{{ "UI.k_load_more" | translate }}
		</button>
	</div>
</ul>

<div *ngIf="gridview?.length == 0 || listview?.length == 0">
	<div class="full-body-height">
		<div class="main-sec">
			<div class="action-section">
				<div class="main-card-view">
					<div
						class="skeleton-data searchEmpty"
						[ngClass]="gridview?.length == 0 || listview?.length == 0 ? 'py-0' : 'py-1'"
					>
						<div *ngIf="gridview?.length == 0 || listview?.length == 0">
							<app-dns-empty-grid-data [noFilterData]="true"></app-dns-empty-grid-data>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
