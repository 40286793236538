import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ComingSoonComponent } from "./components/coming-soon/coming-soon.component";
import { TermsComponent } from "./components/terms/terms.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";
import { InActiveComponent } from "./components/in-active/in-active.component";

const routes: Routes = [
	{
		path: "coming-soon",
		component: ComingSoonComponent,
	},
	{
		path: "terms",
		component: TermsComponent,
	},
	{
		path: "privacy-policy",
		component: PrivacyPolicyComponent,
	},
	{
		path: "inactive",
		component: InActiveComponent,
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class PagesRoutingModule {}
