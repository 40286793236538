import { Injectable } from "@angular/core";
import { ApiService } from "app/common/services/api/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class AuditService extends ApiService {
	constructor(httpClient: HttpClient) {
		super(httpClient);
	}

	getAuditList(payload = {}) {
		return this.get("ux/common/audit/auditinfo/", payload);
	}

	getFilterOptions(payload: any) {
		return this.get("ux/common/filters/filters/filter_options/", payload);
	}

	getSavedFilter(payload) {
		return this.get("ux/common/filters/filters/filter/", payload);
	}
}
