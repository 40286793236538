import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OrgDataService {

  _dataSubject: BehaviorSubject<any>;
	private readonly defaultData = {};

	constructor() {
		this._dataSubject = new BehaviorSubject<any>(this.defaultData);
	}

	public getData = () => {
		return this._dataSubject.asObservable();
	};

	public setData = (data: any) => {
    //console.log("OrgDataService::::", data)
		this._dataSubject.next(data);
	};
}

