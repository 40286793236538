import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChangeViewService } from 'app/servicedesk/change-management/services/change-view.service';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const URL = `${environment.instance_url}` + "media";

@Component({
  selector: 'app-dns-change-impact',
  templateUrl: './dns-change-impact.component.html',
  styleUrls: ['./dns-change-impact.component.scss']
})
export class DnsChangeImpactComponent implements OnInit {
  @Input() options: any = [];
  @Input() change_data: any = {};
  @Output() onHideImpact = new EventEmitter(false);
  @Input() impact_id = "-1"
  impact_obj: any = {};
  public disableSaveBtn: boolean = false;
  public impact_title = this._translateService.instant("UI.k_impact_add");

  constructor(
  private _toastr: ToastrService,
  private _translateService: TranslateService,
  private _changeService: ChangeViewService,) { 

  }

  ngOnInit(): void {
      this.impact_obj["ref_id"] = this.change_data?.change_id
      this.impact_obj["ref_display_id"] = this.change_data?.display_id
      if (this.impact_id != "-1"){
          this.impact_title = this._translateService.instant("UI.k_impact_edit");
          this.getImpactData()
      }

  }
  getImpactData() {
      this._changeService.getChangeImpact(this.impact_id, {}).subscribe(
      (response) => {
          if (response) {
              this.impact_obj = response
          }
      },
      (error) => {
          //todo
      }
      ); 
  }
  toggleSidebar = (): void => {
      this.onHideImpact.emit()
  };
  
  SaveImpact = (data) => {
      if (data.form.valid === true) {
          this.disableSaveBtn = true
          this._changeService.saveChangeImpact(this.impact_obj).pipe(
          map((response: any) => {
              if (response) {
                  if (response["status"] == "success") {
                      this._toastr.success(
                          this._translateService.instant(
                              response["message"]
                          ),
                          this._translateService.instant(
                              "Message.msg_save_success"
                          ),
                          {
                              toastClass: "toast ngx-toastr",
                              closeButton: true,
                              positionClass: "toast-top-right",
                          }
                      );
                      this.toggleSidebar()
                      this.impact_obj = {};
                  } else {
                      this._toastr.error(
                          this._translateService.instant(response["message"]),
                          this._translateService.instant(
                              "Error.err_save_failed"
                          ),
                          {
                              toastClass: "toast ngx-toastr",
                              closeButton: true,
                              positionClass: "toast-top-right",
                          }
                      );
                      this.disableSaveBtn = false
                  }   
              }
              // Success
          }),
          catchError((error) => {
              this._toastr.error(
                  this._translateService.instant("Error.err_save_failed"),
                  this._translateService.instant("Error.err_save_failed"),
                  {
                      toastClass: "toast ngx-toastr",
                      closeButton: true,
                      positionClass: "toast-top-right",
                  }
              );
              this.disableSaveBtn = false
              return throwError(error.statusText);
          })
          ).subscribe();
      }
  };

}
