<div class="payment-page-wrapper">
	<div id="stepper1" class="bs-stepper horizontal-wizard-example">
		<div class="payment-page-header d-flex justify-content-between align-items-center border">
			<div class="brand-logo ng-star-inserted">
				<img alt="brand-logo" height="21" width="159" src="assets/images/infraon-admin-logo.svg" />
			</div>
			<div class="bs-stepper-header">
				<div class="step" data-target="#instance-info">
					<button class="step-trigger">
						<span class="bs-stepper-box">1</span>
						<span class="bs-stepper-label">
							<span class="bs-stepper-title"> {{ "UI.k_instance_info" | translate }}</span>
						</span>
					</button>
				</div>
				<div class="line">
					<i data-feather="chevron-right" class="font-medium-2"></i>
				</div>
				<div class="step" data-target="#organizational-details">
					<button class="step-trigger">
						<span class="bs-stepper-box">2</span>
						<span class="bs-stepper-label">
							<span class="bs-stepper-title">{{ "UI.k_organizational_details" | translate }}</span>
						</span>
					</button>
				</div>
				<div class="line">
					<i data-feather="chevron-right" class="font-medium-2"></i>
				</div>
				<div class="step" data-target="#payment">
					<button class="step-trigger">
						<span class="bs-stepper-box">3</span>
						<span class="bs-stepper-label">
							<span class="bs-stepper-title"> {{ "UI.k_payment_info" | translate }}</span>
						</span>
					</button>
				</div>
				<!-- <div class="step" data-target="#update" *ngIf="event === 'edit'">
					<button class="step-trigger">
						<span class="bs-stepper-box">2</span>
						<span class="bs-stepper-label">
							<span class="bs-stepper-title"> {{ "UI.k_status" | translate }}</span>
						</span>
					</button>
				</div> -->
			</div>
			<div>
				<button
					type="button"
					data-dismiss="modal"
					id="btnClose"
					name="btnClose"
					aria-label="Close"
					(click)="closePaymentPage()"
					class="btn btn-flat-secondary btn-icon h5"
					rippleEffect
				>
					<i class="fa-light fa-xmark"></i>
				</button>
			</div>
		</div>
		<div class="payment-page-content bg-body">
			<section class="stepping-form">
				<div class="bs-stepper-content">
					<div class="bs-stepper-inner">
						<form (ngSubmit)="(HWForm.form.valid)" #HWForm="ngForm">
							<div id="instance-info" class="content">
								<app-premium-plan-skeleton
									*ngIf="usageMetrics.length === 0 && !showPricing"
								></app-premium-plan-skeleton>
								<form #applicationDetailsForm="ngForm" class="stepper-inner flex-auto-height">
									<div class="stepper-main flex-1">
										<ng-scrollbar class="custom-scrollbar section-scrollbar">
											<div class="p-1">
												<!-- <div
													class="d-flex align-items-center justify-content-between mb-1"
													*ngIf="usageMetrics.length > 0 && showPricing"
												>
													<h5 class="modal-title fw-600">
														{{
															isTrial
																? ("UI.k_trial_allocation" | translate)
																: ("UI.k_plan_allocation" | translate)
														}}
													</h5>
												</div>
												<div
													class="current-product card-bg border p-1 card"
													*ngIf="usageMetrics.length > 0"
												>
													<div class="d-flex mb-1 cip-card-start">
														<div class="mr-1">
															<img
																[src]="logoMap[logoKey]"
																[alt]="product_name"
																width="40"
																height="40"
															/>
														</div>
														<div class="d-flex align-items-center">
															<h6 class="fw-600 mb-0">
																{{ product_name }}
															</h6>
															<div class="badge badge-light-warning ml-1">
																{{ plan }}
															</div>
														</div>
													</div>
													<div class="module-table-list">
														<div class="table-responsive">
															<table class="table">
																<thead>
																	<tr>
																		<th>{{ "UI.k_module" | translate }}</th>
																		<th>
																			{{
																				isTrial
																					? ("UI.k_allocated" | translate)
																					: ("UI.k_purchased" | translate)
																			}}
																		</th>
																		<th>{{ "UI.k_consumed" | translate }}</th>
																		<th>{{ "UI.k_available" | translate }}</th>
																	</tr>
																</thead>
																<tbody>
																	<ng-container
																		*ngFor="
																			let usage of usageMetrics;
																			let i = index
																		"
																	>
																		<tr>
																			<td>
																				<div
																					class="icon-sec py-25 d-flex align-items-center"
																				>
																					<span class="title-color fw-500">
																						{{
																							"UI.k_" + usage?.key
																								| translate
																						}}
																					</span>
																				</div>
																			</td>
																			<td>
																				<div
																					class="value fw-500 title-color px-2 py-25"
																					*ngIf="
																						product_name !==
																						'Infraon Helpdesk'
																					"
																				>
																					{{ usage?.purchased }}
																				</div>
																				<div
																					*ngIf="
																						product_name ===
																						'Infraon Helpdesk'
																					"
																				>
																					&infin;
																				</div>
																			</td>
																			<td>
																				<div
																					class="value fw-500 title-color px-2 py-25"
																				>
																					{{ usage?.consumed }}
																				</div>
																			</td>
																			<td>
																				<div
																					class="value fw-500 title-color px-2 py-25"
																					*ngIf="
																						product_name !==
																						'Infraon Helpdesk'
																					"
																				>
																					{{ usage?.available }}
																				</div>
																				<div
																					*ngIf="
																						product_name ===
																						'Infraon Helpdesk'
																					"
																				>
																					&infin;
																				</div>
																			</td>
																		</tr>
																	</ng-container>
																</tbody>
															</table>
														</div>
													</div>
												</div> -->
												<!-- <ng-container *ngFor="let usage of usageMetrics; let i = index">
														<div class="user-asset">
															<div class="user-asset-card card border bg-body">
																<div class="user-asset-inner">
																	<div
																		class="icon-sec border-right px-1 py-50 d-flex align-items-center"
																	>
																		<i
																			class="fa-regular {{
																				usage?.icon
																			}} primary-color mr-50"
																		></i>
																		<span class="title-color fw-500">
																			{{ "UI.k_" + usage?.key | translate }}
																		</span>
																	</div>
																	<div
																		class="user-asset-info border-right px-1 py-50"
																	>
																		<div class="key reg-font">
																			{{
																				isTrial
																					? ("UI.k_allocated" | translate)
																					: ("UI.k_purchased" | translate)
																			}}
																		</div>
																		<div
																			class="value fw-500 title-color"
																			*ngIf="product_name !== 'Infraon Helpdesk'"
																		>
																			{{ usage?.purchased }}
																		</div>
																		<div
																			*ngIf="product_name === 'Infraon Helpdesk'"
																		>
																			&infin;
																		</div>
																	</div>
																	<div
																		class="user-asset-info border-right px-1 py-50"
																	>
																		<div class="key reg-font">
																			{{ "UI.k_consumed" | translate }}
																		</div>
																		<div class="value fw-500 title-color">
																			{{ usage?.consumed }}
																		</div>
																	</div>
																	<div class="user-asset-info px-1 py-50">
																		<div class="key reg-font">
																			{{ "UI.k_available" | translate }}
																		</div>
																		<div
																			class="value fw-500 title-color"
																			*ngIf="product_name !== 'Infraon Helpdesk'"
																		>
																			{{ usage?.available }}
																		</div>
																		<div
																			*ngIf="product_name === 'Infraon Helpdesk'"
																		>
																			&infin;
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</ng-container> -->
												<!-- </div> -->
												<div
													class="d-flex align-items-center justify-content-between mb-1"
													*ngIf="usageMetrics.length > 0"
												>
													<h5 class="modal-title fw-600" id="ModalLabelAsset">
														{{ "UI.k_manage_subscription" | translate }}
													</h5>
													<ng-container *ngIf="productInfo">
														<div
															class="pricing-modal-header-info d-flex align-items-center"
														>
															<!-- <ng-container *ngFor="let inp of addOnObj; let i = index">
																<div class="d-flex align-items-center mr-1">
																	<label
																		class="d-flex align-items-center mr-50 mb-0 fw-500"
																		for="inp.obj.name"
																	>
																		<span *ngIf="inp?.key === 'asset'">{{
																			"UI.k_assets" | translate
																		}}</span>
																		<span *ngIf="inp?.key === 'users'">{{
																			"UI.k_users" | translate
																		}}</span>
																	</label>
																	<ng-container *ngIf="inp.obj.type == 'input'">
																		<input
																			type="number"
																			id="{{ inp.obj.name }}"
																			min="{{ inp.obj.min }}"
																			name="{{ inp.obj.name }}"
																			value="{{ inp.obj.value }}"
																			class="form-control form-control-sm"
																			style="width: 60px"
																			(change)="
																				onChangeUnits(
																					inp.obj.id,
																					$event.target.value
																				)
																			"
																		/>
																	</ng-container>
																	<ng-container *ngIf="inp.obj.type == 'select'">
																		<select
																			id="inp.obj.name"
																			name="inp.obj.name"
																			class="form-control form-control-sm"
																			(change)="
																				onChangeUnits(
																					inp.obj.id,
																					$event.target.value
																				)
																			"
																		>
																			<ng-container
																				*ngFor="let op of inp.obj.options"
																			>
																				<option
																					id="opt{{ op }}"
																					name="opt{{ op }}"
																					value="{{ op }}"
																					[selected]="op == inp.obj.value"
																				>
																					{{ op }}
																				</option>
																			</ng-container>
																		</select>
																	</ng-container>
																</div>
															</ng-container> -->
															<div class="d-flex align-items-center">
																<div
																	class="px-50 cursor-pointer fw-500"
																	[ngClass]="
																		this.pricingType === 'month'
																			? 'badge badge-primary'
																			: 'title-color'
																	"
																	(click)="togglePricingType('month')"
																	[ngStyle]="{ width: '70px' }"
																>
																	{{ "UI.k_monthly" | translate }}
																</div>
																<div class="pl-50 pr-50 body-color font-small-5">|</div>
																<div
																	class="px-50 cursor-pointer fw-500"
																	[ngClass]="
																		this.pricingType === 'year'
																			? 'badge badge-primary'
																			: 'title-color'
																	"
																	(click)="togglePricingType('year')"
																	[ngStyle]="{ width: '70px' }"
																>
																	{{ "UI.k_yearly" | translate }}
																</div>
																<div class="text-primary ml-50 font-small-2 fw-700">
																	{{ "Message.msg_save_2_months" | translate }}
																</div>
															</div>
														</div>
													</ng-container>
												</div>
												<!-- custom instance product card -->
												<!-- <ng-container *ngIf="!productInfo">
													<div class="card border card-bg cip-card">
														<div class="cip-card-header m-5">
															<h4>No Product Selected</h4>
														</div>
													</div>
												</ng-container> -->
												<div class="manage-subscription mb-1" *ngIf="usageMetrics.length > 0">
													<div class="manage-input-row">
														<div class="form-group">
															<label for="selProduct" class="form-label">
																{{ "UI.k_product" | translate }}
																*</label
															>
															<ng-select
																required
																bindLabel="name"
																bindValue="key"
																class="form-control"
																name="selProduct"
																id="selProduct"
																placeholder="{{ 'UI.k_select' | translate }}"
																[(ngModel)]="currentKey"
																#serTypeInput="ngModel"
																[items]="infraonProducts"
																(change)="onProductChange($event)"
																[searchable]="false"
																[clearable]="false"
															>
																<ng-template ng-option-tmp ng-label-tmp let-item="item">
																	<img
																		src="{{ logoMap[item.key] }}"
																		alt="item.name"
																		width="16"
																		height="16"
																		class="mr-50"
																	/>
																	{{ item.name }}
																</ng-template>
															</ng-select>
														</div>
														<div class="form-group">
															<span
																class="d-flex align-items-center justify-content-between"
															>
																<label for="txtPlan">{{
																	"UI.k_plan" | translate
																}}</label>
																<span
																	class="primary-color reg-font cursor-pointer mb-25"
																	(click)="togglePricingModal(pricingModal)"
																>
																	{{ "UI.k_choose_a_plan" | translate }}
																</span>
															</span>
															<input
																type="text"
																class="form-control"
																id="txtPlan"
																name="txtPlan"
																[(ngModel)]="activePlan"
																[disabled]="true"
																placeholder="{{ 'UI.k_type_here' | translate }}"
															/>
														</div>
													</div>
												</div>
												<div
													class="product-info-sec user-info-sec card-bg card border mb-1"
													*ngIf="allowedUnits.includes('user') && usageMetrics.length > 0"
												>
													<div
														class="icon-sec title-color border-right d-flex align-items-center"
													>
														<i class="fa-regular fa-user-group primary-color mr-50"></i>
														<span class="title-color fw-500">
															{{ "UI.k_users" | translate }}
														</span>
													</div>
													<div class="dropdown-sec border-right">
														<div
															class="form-group w-100 mb-0"
															*ngIf="currentKey === 'asset'"
														>
															<input
																type="text"
																class="form-control"
																id="txtUserCount"
																name="txtUserCount"
																[(ngModel)]="currentUserCount"
																[disabled]="true"
															/>
														</div>
														<div
															class="form-group w-100 mb-0"
															*ngIf="currentKey !== 'asset'"
														>
															<div *ngIf="!editUser" class="inner-detail d-flex">
																<div
																	class="user-detail d-flex align-items-center reg-font mr-auto"
																>
																	<div class="total-user">
																		{{ currentUserCount }}
																	</div>
																	<span class="ml-25">{{
																		"UI.k_users" | translate
																	}}</span>
																</div>
																<div
																	id="spEditUsers"
																	name="spEditUsers"
																	(click)="editUsers()"
																	tooltipClass="fadeInAnimation"
																	placement="bottom"
																	ngbTooltip="{{ 'UI.k_edit' | translate }}"
																	class="cursor-pointer ml-2 pull-right"
																	*ngIf="!userEditDisabled"
																>
																	<i
																		class="fa-light fa-pen-to-square text-primary mr-1"
																	></i>
																</div>
															</div>
															<div *ngIf="editUser">
																<div class="inner-detail d-flex align-items-center">
																	<input
																		type="number"
																		id="inpUser"
																		name="inpUser"
																		[ngModel]="currentUserCount"
																		class="w-100 form-control"
																		placeholder="{{
																			'Error.err_enter_numbers_only' | translate
																		}}"
																		#countUserInput="ngModel"
																		[class.error]="countUserInput.invalid"
																		required
																		min="3"
																		max="999"
																		(keydown.enter)="$event.preventDefault()"
																		(ngModelChange)="onUserCountChange($event)"
																		[appCompare]
																		[currentValue]="usageObj?.user"
																		[operator]="'<'"
																	/>
																	<button
																		type="button"
																		class="btn btn-save-user btn-md btn-icon btn-flat-primary ml-50"
																		rippleEffect
																		[disabled]="countUserInput.invalid"
																		(click)="
																			onChangeUnits('inpUsers', currentUserCount)
																		"
																	>
																		<i class="fa-light fa-floppy-disk"></i>
																	</button>
																</div>
																<div
																	class="invalid-form"
																	*ngIf="countUserInput.invalid"
																>
																	<small
																		class="form-text text-danger"
																		*ngIf="countUserInput.errors.required"
																		>{{ "UI.k_field_required" | translate }}
																	</small>
																	<small
																		class="form-text text-danger"
																		*ngIf="countUserInput.errors.min"
																	>
																		{{
																			"Error.err_min_val"
																				| translate: { minValue: 3 }
																		}}
																	</small>
																	<small
																		class="form-text text-danger"
																		*ngIf="countUserInput.errors.max"
																	>
																		{{ "UI.k_please_mail" | translate }}
																		<a
																			class="reg-font text-danger text-decoration-underline"
																			href="mailto:sales@infraon.io"
																		>
																			<span>sales@infraon.io</span>
																		</a>
																		{{ "Message.msg_contact_sales" | translate }}
																	</small>
																	<small
																		class="form-text text-danger"
																		*ngIf="countUserInput.errors.dnsInvalidValue"
																		>{{
																			"Message.msg_allocate_user"
																				| translate: { count: usageObj?.user }
																		}}
																	</small>
																</div>
															</div>
														</div>
														<!-- <div
															class="form-group w-100 mb-0"
															*ngIf="currentKey === 'helpdesk'"
														>
															<input
																type="text"
																class="form-control"
																id="txtUserCount"
																name="txtUserCount"
																value="{{ 'UI.k_unlimited_users' | translate }}"
																[disabled]="true"
															/>
														</div> -->
													</div>
													<div class="value-sec title-color">
														<span *ngIf="currentKey === 'itsm'">
															{{ currentUserCount }} X
															{{
																(pricingType === "month"
																	? itsmPriceMap.user / currentUserCount
																	: itsmPriceMap.user / (10 * currentUserCount)
																) | currency: defaultCurrency
															}}
														</span>
														<span class="ml-50" *ngIf="currentKey === 'itsm'">=</span>
														<span
															class="ml-50 fw-600 reg-font"
															*ngIf="currentKey === 'itsm'"
														>
															{{ itsmPriceMap.user | currency: defaultCurrency }}
															/
															{{
																pricingType === "month"
																	? ("UI.k_mt" | translate)
																	: ("UI.k_yr" | translate)
															}}
														</span>
														<span
															class="ml-50 fw-600 reg-font"
															*ngIf="currentKey !== 'itsm' && currentKey !== 'asset'"
														>
															<span *ngIf="pricingType !== 'year'">
																{{ totalSubPrice | currency: defaultCurrency }}
																/
																{{ "UI.k_mt" | translate }}
															</span>
															<span *ngIf="pricingType === 'year'">
																{{ totalVPrice | currency: defaultCurrency }}
																/
																{{ "UI.k_yr" | translate }}
															</span>
														</span>
													</div>
												</div>
												<div
													class="product-info-sec asset-info-sec card-bg card border mb-1"
													*ngIf="allowedUnits.includes('asset') && usageMetrics.length > 0"
												>
													<div
														class="icon-sec title-color border-right d-flex align-items-center"
														*ngIf="productInfo?.id !== 'Infraon-ITSM'"
													>
														<i class="fa-regular fa-box-archive primary-color mr-50"></i>
														<span class="title-color fw-500">
															{{ "UI.k_assets" | translate }}
														</span>
													</div>
													<div
														class="dropdown-sec border-right"
														*ngIf="productInfo?.id === 'Infraon-ITSM'"
													>
														<div class="form-group w-100 mb-0">
															<ng-select
																required
																bindLabel="item.external_name"
																class="form-control"
																name="selAssetAddOn"
																id="selAssetAddOn"
																placeholder="{{ 'UI.k_select' | translate }}"
																[(ngModel)]="currentAssetAddOn"
																#TDAssetAddonRef="ngModel"
																[items]="addOnOptions"
																(change)="onChangeAddOn($event)"
																[closeOnSelect]="true"
																[searchable]="false"
																[clearable]="false"
																[clearOnBackspace]="false"
															>
															</ng-select>
														</div>
													</div>
													<div class="dropdown-sec border-right">
														<div class="form-group w-100 mb-0">
															<ng-select
																required
																bindLabel="count"
																class="form-control"
																name="selAssetCount"
																id="selAssetCount"
																placeholder="{{ 'UI.k_select' | translate }}"
																[(ngModel)]="currentAssetCount"
																#TDAssetRef="ngModel"
																[items]="assetOptions"
																(change)="onChangeUnits('selAssets', $event)"
																[closeOnSelect]="true"
																[searchable]="false"
																[clearable]="false"
																[clearOnBackspace]="false"
																[appCompare]
																[currentValue]="usageObj?.asset"
																[operator]="'<'"
															>
															</ng-select>
														</div>
														<div class="invalid-form" *ngIf="TDAssetRef.invalid">
															<small
																class="form-text text-danger"
																*ngIf="TDAssetRef.errors.dnsInvalidValue"
																>{{
																	"Message.msg_allocate_asset"
																		| translate: { count: usageObj?.asset }
																}}
															</small>
														</div>
													</div>
													<div class="value-sec title-color">
														<!-- {{ currentAssetCount }} = -->
														<!-- <span class="ml-50 fw-600 reg-font">{{
															currentAssetCount * 23 | currency: defaultCurrency
														}}</span> -->

														<span
															*ngIf="pricingType !== 'year' && currentKey !== 'itsm'"
															class="ml-50 fw-600 reg-font"
														>
															{{ totalSubPrice | currency: defaultCurrency }}
															/
															{{ "UI.k_mt" | translate }}
														</span>
														<span
															*ngIf="pricingType === 'year' && currentKey !== 'itsm'"
															class="ml-50 fw-600 reg-font"
														>
															{{ totalVPrice | currency: defaultCurrency }}
															/
															{{ "UI.k_yr" | translate }}
														</span>

														<span
															*ngIf="currentKey === 'itsm'"
															class="ml-50 fw-600 reg-font"
														>
															{{ itsmPriceMap.asset | currency: defaultCurrency }}
															/
															{{
																pricingType === "month"
																	? ("UI.k_mt" | translate)
																	: ("UI.k_yr" | translate)
															}}
														</span>
													</div>
												</div>
												<div class="addon-sec" *ngIf="extraAddOns?.length > 0">
													<form #addAddonItemForm="ngForm" class="d-grid gap-1">
														<div class="addon-btn-sec d-flex gap-50">
															<span class="title-color fw-600">{{
																"UI.k_add_ons" | translate
															}}</span>
															<button
																type="button"
																class="btn btn-xs btn-primary"
																rippleEffect
																(click)="addNewAddonItem()"
															>
																<i class="fa-light fa-plus"></i
																><span>{{ "UI.k_add_new" | translate }}</span>
															</button>
														</div>
														<div class="addon-card-list d-grid gap-1">
															<ng-container
																*ngFor="let sItem of addonItem; let subIndex = index"
															>
																<div
																	class="single-service card-bg border align-items-start"
																>
																	<div class="input-field d-grid gap-25 border-right">
																		<div class="form-group mb-0">
																			<label for="selProductType{{ subIndex }}">{{
																				"UI.k_type" | translate
																			}}</label>
																			<ng-select
																				required
																				bindLabel="name"
																				class="form-control"
																				name="selProductType{{ subIndex }}"
																				id="selProductType{{ subIndex }}"
																				[attr.data-testid]="
																					'selProductType{{ subIndex }}'
																				"
																				[closeOnSelect]="true"
																				[searchable]="true"
																				placeholder="{{
																					'UI.k_type' | translate
																				}}"
																				[(ngModel)]="sItem.productType"
																				#productTypeInput="ngModel"
																				[class.error]="
																					productTypeInput.invalid &&
																					addAddonItemForm.submitted
																				"
																				notFoundText="{{
																					'UI.k_no_items_found' | translate
																				}}"
																				[items]="extraAddOns"
																				(change)="onAddonChange($event, sItem)"
																			>
																				<ng-template
																					ng-label-tmp
																					let-item="item"
																				>
																					<div
																						class="type-item reg-font fw-500 d-flex gap-25 align-items-center"
																					>
																						<span>
																							<i
																								class="{{ item.icon }}"
																							></i>
																						</span>
																						<span>{{ item.name }}</span>
																					</div>
																				</ng-template>
																				<ng-template
																					ng-option-tmp
																					let-item="item"
																				>
																					<div
																						class="type-item reg-font fw-500 d-flex gap-25 align-items-center"
																					>
																						<span>
																							<i
																								class="{{ item.icon }}"
																							></i>
																						</span>
																						<span>{{ item.name }}</span>
																					</div>
																				</ng-template>
																			</ng-select>
																		</div>
																		<span
																			class="invalid-form"
																			*ngIf="
																				addAddonItemForm.submitted &&
																				productTypeInput.invalid
																			"
																		>
																			<small
																				class="form-text text-danger"
																				*ngIf="productTypeInput.errors.required"
																			>
																				{{ "UI.k_field_required" | translate }}
																			</small>
																		</span>
																	</div>
																	<div class="value-sec d-flex gap-25 border-right" id="price{{ subIndex }}">
																		<!-- <span>1</span>
																		<span>X</span>
																		<span>{{ addonUnitPrice }}</span>
																		<span>=</span> -->
																		<span class="title-color fw-600 lg-font">{{
																			sItem?.price | currency: "USD"
																		}}</span>
																	</div>
																	<button
																		type="button"
																		class="btn sm-btn btn-icon btn-flat-danger delete-btn"
																		id="deleteBtn{{subIndex}}"
																		rippleEffect
																		(click)="deleteRowAddonItem(sItem, addonItem)"
																	>
																		<i class="fa-light xl-font fa-trash-can"></i>
																	</button>
																</div>
															</ng-container>
														</div>
														<div class="form-text text-danger" *ngIf="showDuplicateAddonMsg">{{ "Message.msg_duplicate_addons" | translate }}</div>
													</form>
												</div>
											</div>
										</ng-scrollbar>
									</div>
									<ng-container *ngIf="productInfo && usageMetrics.length > 0">
										<div class="steppper-side p-1">
											<div class="card checkout-card card-bg rounded border">
												<div class="card-body">
													<div class="price-details" *ngIf="showPriceSkeleton">
														<div class="list-wrapper">
															<div class="skeleton" class="pr-50 mb-1">
																<div
																	class="sekeleton-card item-wrap col-md-12 pb-0 pt-0"
																>
																	<div class="d-flex flex-column">
																		<div
																			class="d-flex col-md-12 p-0 mt-1 justify-content-between"
																		>
																			<div
																				class="d-flex col-md-10 p-0 justify-content-start"
																			>
																				<div class="col-md-8 mr-1 p-0">
																					<ngx-skeleton-loader
																						[theme]="{
																							height: '15px',
																							width: '100%'
																						}"
																					></ngx-skeleton-loader>
																				</div>
																			</div>
																			<div class="col-md-1 p-0">
																				<ngx-skeleton-loader
																					[theme]="{
																						height: '15px',
																						width: '100%'
																					}"
																				></ngx-skeleton-loader>
																			</div>
																		</div>
																		<div
																			class="d-flex col-md-12 p-0 mt-1 justify-content-between"
																		>
																			<div
																				class="d-flex col-md-10 p-0 justify-content-start"
																			>
																				<div class="col-md-8 mr-1 p-0">
																					<ngx-skeleton-loader
																						[theme]="{
																							height: '15px',
																							width: '100%'
																						}"
																					></ngx-skeleton-loader>
																				</div>
																			</div>
																			<div class="col-md-1 p-0">
																				<ngx-skeleton-loader
																					[theme]="{
																						height: '15px',
																						width: '100%'
																					}"
																				></ngx-skeleton-loader>
																			</div>
																		</div>
																	</div>
																	<hr class="mb-1 mt-0" />
																	<div
																		class="d-flex col-md-12 p-0 justify-content-between"
																	>
																		<div class="col-md-4 mr-1 p-0">
																			<ngx-skeleton-loader
																				[theme]="{
																					height: '15px',
																					width: '100%'
																				}"
																			></ngx-skeleton-loader>
																		</div>
																		<div class="col-md-1 p-0">
																			<ngx-skeleton-loader
																				[theme]="{
																					height: '15px',
																					width: '100%'
																				}"
																			></ngx-skeleton-loader>
																		</div>
																	</div>
																	<div>
																		<ngx-skeleton-loader
																			[theme]="{ height: '30px', width: '100%' }"
																		></ngx-skeleton-loader>
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div class="price-details" *ngIf="!showPriceSkeleton">
														<ul class="list-unstyled">
															<li class="price-detail">
																<div class="detail-title fw-500">
																	<span *ngIf="showCreditApplied">
																		{{ "UI.k_prorated" | translate }}
																	</span>
																	{{ "UI.k_subtotal" | translate }}
																</div>
																<div
																	class="detail-amt fw-500"
																	*ngIf="pricingType !== 'year'"
																>
																	{{ subTotal | currency: defaultCurrency }}
																</div>
																<div
																	class="detail-amt fw-500"
																	*ngIf="pricingType === 'year'"
																>
																	{{ nonDiscPrice | currency: defaultCurrency }}
																</div>
															</li>
															<li class="price-detail" *ngIf="pricingType === 'year'">
																<div class="detail-title text-primary reg-font">
																	{{ "UI.k_yearly_plan_discount" | translate }}
																</div>
																<div class="detail-amt fw-500">
																	-{{ discValue | currency: defaultCurrency }}
																</div>
															</li>
															<li class="price-detail" *ngIf="showCreditApplied && refundCredits > 0">
																<div class="detail-title text-primary reg-font">
																	{{ "Message.msg_refund_credits" | translate }}
																</div>
																<div class="detail-amt fw-500">
																	-{{ refundCredits | currency: defaultCurrency }}
																</div>
															</li>
															<li class="price-detail" *ngIf="addonItem.length > 0">
																<div class="detail-title text-primary reg-font">
																	{{ "UI.k_add_ons" | translate }}
																</div>
																<div class="detail-amt fw-500">
																	+{{ totalAddonPrice | currency: defaultCurrency }}
																</div>
															</li>
														</ul>
														<hr />
														<ul class="list-unstyled">
															<li class="price-detail">
																<div class="detail-title detail-total reg-font">
																	{{ "UI.k_total" | translate }}
																	( {{ "UI.k_paynow" | translate }} )
																</div>
																<div
																	class="detail-amt reg-font fw-500"
																	*ngIf="!showCreditApplied"
																>
																<!-- && !allowManage -->
																	{{ (addonItem.length > 0 ? totalPriceWithAddon: totalVPrice) | currency: defaultCurrency }}
																</div>
																<div
																	class="detail-amt reg-font fw-500"
																	*ngIf="showCreditApplied"
																>
																	{{ (addonItem.length > 0 && event === "add" ? totalPriceWithAddon: totalPricePay) | currency: defaultCurrency }}
																</div>
															</li>
															<li class="price-detail" *ngIf="showCreditMsg">
																<div class="detail-title text-primary reg-font">
																	{{ "Message.msg_remaining_credits" | translate }}
																</div>
																<div class="detail-amt reg-font fw-500 text-success">
																	+{{ remainingCredits | currency: defaultCurrency }}
																</div>
															</li>
														</ul>
														
														<button
															(click)="
																horizontalWizardStepperNext(applicationDetailsForm)
															"
															type="submit"
															rippleEffect
															[disabled]="applicationDetailsForm.invalid || !allowManage"
															class="btn btn-primary btn-block btn-next place-order waves-effect waves-float waves-light"
														>
															{{
																(event === "edit"
																	? "UI.k_manage_plan"
																	: "UI.k_proceed_buy"
																) | translate
															}}
														</button>
													</div>
												</div>
											</div>
										</div>
									</ng-container>
								</form>
							</div>
							<div id="organizational-details" class="content">
								<app-payment-skeleton *ngIf="!showOrgData"></app-payment-skeleton>
								<form #organizationDetailForm="ngForm" class="stepper-inner flex-auto-height">
									<div class="stepper-main flex-1" *ngIf="showOrgData">
										<ng-scrollbar class="custom-scrollbar section-scrollbar">
											<div class="p-1">
												<h6 class="mb-2">
													<b>{{ "UI.k_tell_about_organization" | translate }}</b>
												</h6>
												<div class="form-control-grid">
													<div class="form-group">
														<label for="txtOrgAdminEmail" class="form-label">
															{{ "UI.k_admin_email" | translate }}
															<span class="required-field">&nbsp;*</span></label
														>
														<input
															type="email"
															class="form-control"
															id="txtOrgAdminEmail"
															name="txtOrgAdminEmail"
															placeholder="{{ 'UI.k_business_placeholder' | translate }}"
															[(ngModel)]="organizationData.user.email"
															#adminEmailInput="ngModel"
															[class.error]="
																adminEmailInput.invalid &&
																organizationDetailForm.submitted
															"
															required
															[appEmailCheck]="organizationData.user.email"
															tabindex="1"
														/>
														<span
															class="invalid-form"
															*ngIf="
																organizationDetailForm.submitted &&
																adminEmailInput.invalid
															"
														>
															<small
																class="form-text text-danger"
																*ngIf="adminEmailInput.errors.required"
																>{{ "UI.k_field_required" | translate }}
															</small>
															<small
																class="form-text text-danger"
																*ngIf="adminEmailInput.errors?.invalidemail"
																>{{ "UI.k_alert_email_invalid" | translate }}</small
															>
														</span>
													</div>

													<div class="form-group">
														<label for="txtOrgAdminName" class="form-label">
															{{ "UI.k_fname" | translate }}
															<span class="required-field">&nbsp;*</span></label
														>
														<input
															type="text"
															class="form-control"
															id="txtOrgAdminName"
															name="txtOrgAdminName"
															placeholder="{{ 'UI.k_full_name_placeholder' | translate }}"
															[(ngModel)]="organizationData.user.full_name"
															#adminNameInput="ngModel"
															[class.error]="
																adminNameInput.invalid &&
																organizationDetailForm.submitted
															"
															required
															minlength="3"
															tabindex="2"
														/>
														<span
															class="invalid-form"
															*ngIf="
																organizationDetailForm.submitted &&
																adminNameInput.invalid
															"
														>
															<small
																class="form-text text-danger"
																*ngIf="adminNameInput.errors.required"
																>{{ "UI.k_field_required" | translate }}
															</small>
															<small
																class="form-text text-danger"
																*ngIf="adminNameInput.errors.minlength"
															>
																{{ "Error.err_input_min_char" | translate }}
															</small>
														</span>
													</div>
												</div>
												<div class="form-group">
													<label for="txtOrgName" class="form-label">
														{{ "UI.k_organization_name" | translate }}
														<span class="required-field">&nbsp;*</span></label
													>
													<input
														type="text"
														class="form-control"
														id="txtOrgName"
														name="txtOrgName"
														placeholder="{{ 'UI.k_company_placeholder' | translate }}"
														[(ngModel)]="organizationData.organization.company_name"
														#adminOrgNameInput="ngModel"
														[class.error]="
															adminOrgNameInput.invalid &&
															organizationDetailForm.submitted
														"
														required
														minlength="2"
														tabindex="3"
													/>
													<span
														class="invalid-form"
														*ngIf="
															organizationDetailForm.submitted &&
															adminOrgNameInput.invalid
														"
													>
														<small
															class="form-text text-danger"
															*ngIf="adminOrgNameInput.errors.required"
															>{{ "UI.k_field_required" | translate }}
														</small>
														<small
															class="form-text text-danger"
															*ngIf="adminOrgNameInput.errors.minlength"
														>
															{{ "Error.err_input_min_char" | translate }}
														</small>
													</span>
												</div>
												<div class="form-group">
													<label for="txtTaxID" class="form-label">
														{{ "UI.k_tax_id" | translate }}
														<!-- <span class="required-field">&nbsp;*</span> -->
													</label>
													<input
														type="text"
														class="form-control"
														id="txtTaxID"
														name="txtTaxID"
														placeholder="{{ 'UI.k_tax_id_placeholder' | translate }}"
														[(ngModel)]="organizationData.organization.tax_id"
														#adminTaxIDInput="ngModel"
														[class.error]="
															adminTaxIDInput.invalid && organizationDetailForm.submitted
														"
														minlength="2"
														tabindex="4"
													/>
													<span
														class="invalid-form"
														*ngIf="
															organizationDetailForm.submitted &&
															adminOrgNameInput.invalid
														"
													>
														<small
															class="form-text text-danger"
															*ngIf="adminOrgNameInput.errors.minlength"
														>
															{{ "Error.err_input_min_char" | translate }}
														</small>
													</span>
												</div>
												<div class="form-group">
													<label for="txtOrgBillAddr" class="form-label">
														{{ "UI.k_billing_address" | translate }}</label
													>
													<textarea
														class="form-control"
														id="txtOrgBillAddr"
														name="txtOrgBillAddr"
														placeholder="{{ 'UI.k_billing_address' | translate }}"
														[(ngModel)]="organizationData.organization.billing_address"
														tabindex="5"
													></textarea>
												</div>
												<div class="form-group">
													<label for="txtOrgShipAddr" class="form-label">
														{{ "UI.k_shipping_address" | translate }}</label
													>
													<div class="custom-control custom-checkbox mt-1 mb-1">
														<input
															class="custom-control-input"
															id="inSameAsBillAddr"
															name="inSameAsBillAddr"
															type="checkbox"
															tabindex="6"
															[(ngModel)]="
																organizationData.organization.is_billing_address
															"
														/>
														<label class="custom-control-label" for="inSameAsBillAddr">
															{{ "Message.msg_same_billing" | translate }}</label
														>
													</div>
													<textarea
														class="form-control"
														id="txtOrgShipAddr"
														name="txtOrgShipAddr"
														placeholder="{{ 'UI.k_shipping_address' | translate }}"
														[(ngModel)]="organizationData.organization.shipping_address"
														tabindex="7"
														*ngIf="!organizationData.organization.is_billing_address"
													></textarea>
												</div>
											</div>
										</ng-scrollbar>
									</div>
									<div class="steppper-side p-1" *ngIf="showOrgData">
										<div class="card checkout-card card-bg rounded border">
											<div class="card-body">
												<div class="price-details">
													<ul class="list-unstyled">
														<li class="price-detail">
															<div class="detail-title fw-500">
																<span *ngIf="showCreditApplied">
																	{{ "UI.k_prorated" | translate }}
																</span>
																{{ "UI.k_subtotal" | translate }}
															</div>
															<div
																class="detail-amt fw-500"
																*ngIf="pricingType !== 'year'"
															>
																{{ subTotal | currency: defaultCurrency }}
															</div>
															<div
																class="detail-amt fw-500"
																*ngIf="pricingType === 'year'"
															>
																{{ nonDiscPrice | currency: defaultCurrency }}
															</div>
														</li>
														<li class="price-detail" *ngIf="pricingType === 'year'">
															<div class="detail-title text-primary reg-font">
																{{ "UI.k_yearly_plan_discount" | translate }}
															</div>
															<div class="detail-amt fw-500">
																-{{ discValue | currency: defaultCurrency }}
															</div>
														</li>
														<li class="price-detail" *ngIf="showCreditApplied && refundCredits > 0">
															<div class="detail-title text-primary reg-font">
																{{ "Message.msg_refund_credits" | translate }}
															</div>
															<div class="detail-amt fw-500">
																-{{ refundCredits | currency: defaultCurrency }}
															</div>
														</li>
														<li class="price-detail" *ngIf="addonItem.length > 0">
															<div class="detail-title text-primary reg-font">
																{{ "UI.k_add_ons" | translate }}
															</div>
															<div class="detail-amt fw-500">
																+{{ totalAddonPrice | currency: defaultCurrency }}
															</div>
														</li>
													</ul>
													<hr />
													<ul class="list-unstyled">
														<li class="price-detail">
															<div class="detail-title detail-total reg-font">
																{{ "UI.k_total" | translate }}
																( {{ "UI.k_paynow" | translate }} )
															</div>
															<div
																class="detail-amt reg-font fw-500"
																*ngIf="!showCreditApplied"
															>
																{{ (addonItem.length > 0 ? totalPriceWithAddon: totalVPrice) | currency: defaultCurrency }}
															</div>
															<div
																class="detail-amt reg-font fw-500"
																*ngIf="showCreditApplied"
															>
																{{ (addonItem.length > 0  && event === "add" ? totalPriceWithAddon: totalPricePay) | currency: defaultCurrency }}
															</div>
														</li>
														<li class="price-detail" *ngIf="showCreditMsg">
															<div class="detail-title text-primary reg-font">
																{{ "Message.msg_remaining_credits" | translate }}
															</div>
															<div class="detail-amt reg-font fw-500 text-success">
																+{{ remainingCredits | currency: defaultCurrency }}
															</div>
														</li>
													</ul>
													<button
														(click)="horizontalWizardStepperNext(organizationDetailForm)"
														type="submit"
														rippleeffect=""
														class="btn btn-primary btn-block btn-next place-order waves-effect waves-float waves-light"
														[disabled]="disableSave"
													>
														{{ "UI.k_continue" | translate }}
													</button>
													<button
														(click)="horizontalWizardStepperPrev(organizationDetailForm)"
														type="button"
														rippleeffect=""
														class="btn btn-outline-primary btn-block btn-next place-order waves-effect waves-float waves-light"
													>
														{{ "UI.k_previous" | translate }}
													</button>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
							<div id="payment" class="content">
								<form
									#completePaymentForm="ngForm"
									class="flex-auto-height d-flex justify-content-center align-items-center"
								>
									<!-- <button
										type="button"
										class="btn btn-primary"
										rippleEffect
										(click)="showCongratsModal(modalcongratulation)"
									>
										Primary
									</button> -->
									<ng-container *ngIf="!congratulationUser">
										<div class="d-flex justify-content-center align-items-center">
											<div class="spinner-border text-primary mr-50" role="status"></div>
											<div class="ml-1 mt-25">Processing...</div>
										</div>

										<div class="mt-1">You will be redirected. It might take a few seconds.</div>
										<div class="mt-1">
											Please do not refresh the page or click "Back" or "Close" button of your
											browser.
										</div>
									</ng-container>
									<ng-container *ngIf="congratulationUser">
										<app-congratulations
											[userName]="organizationData.user.full_name"
											[productName]="product_name"
										>
										</app-congratulations>
									</ng-container>
								</form>
							</div>
							<!-- <div id="manage" class="content" *ngIf="event === 'edit'">
								<form #updateStatusForm="ngForm" class="stepper-inner flex-auto-height">
									<ng-container>
										<div class="d-flex flex-column align-items-center justify-content-center">
											<div>{{ "Message.msg_changes_plan" | translate }}</div>
											<div>{{ "Message.msg_changes_time" | translate }}</div>
										</div>
									</ng-container>
								</form>
							</div> -->
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
</div>

<!--Preview modal template starts-->
<ng-template #pricingModal let-modal>
	<app-pricing-modal tag="payment_page" (callback)="callbackAfterChange($event)"></app-pricing-modal>
</ng-template>
<!--Preview modal template ends-->

<!--Congratulation modal template starts-->
<ng-template #modalcongratulation let-modal>
	<div class="modal-body card-bg p-0" tabindex="0" ngbAutofocus>
		<div class="congratulation-modal">
			<app-congratulations [userName]="organizationData.user.full_name" [productName]="product_name">
			</app-congratulations>
		</div>
	</div>
</ng-template>
<!--Congratulation modal template ends-->
