<div *ngIf="selection.selected.length > 0 && show === true" class="bg-primary flex-initial">
	<!--style="margin-top:-62px"-->
	<div class="p-25 d-flex align-items-center justify-content-between">
		<div class="ml-1 d-flex align-items-center">
			<span class="mx-1 bullet bg-white bullet-xs"> </span>
			<span class="text-white"> {{ selection.selected.length }}&nbsp; Selected </span>
		</div>
		<ul class="list-inline text-center mb-0">
			<li class="list-inline-item m-0" *ngFor="let menu of mselectMenu; let i = index">
				<button
					class="btn btn-primary"
					(click)="menu.listner('selected-data')"
					tooltipClass="fadeInAnimation"
					[ngbTooltip]="menu.label"
					placement="bottom"
					id="btn{{ menu?.id ? menu?.id : menu.icon }}"
					attr.data-testid="btn{{ menu?.id ? menu?.id : menu.icon }}"
					*ngIf="selection.selected.length >= menu.min_rows && permissions[menu?.permissionKey]"
				>
					<i class="fa-light fa-{{ menu.icon }} "></i>&nbsp;
					<span class="font-small-3">{{ menu.label }}</span>
				</button>
			</li>
		</ul>
		<button
			(click)="toggle()"
			type="button"
			id="btnCloseSel"
			[attr.data-testid]="'btnCloseSel'"
			class="btn btn-icon btn-primary"
			rippleEffect
		>
			<i class="fa-light fa-xmark"></i>
		</button>
	</div>
</div>

<div class="full-height flex-1">
	<div class="table-container full-height flex-auto-height">
		<div class="flex-1 flex-auto-height">
			<ng-scrollbar
				class="custom-scrollbar section-scrollbar"
				[disabled]="'false'"
				track="all"
				[visibility]="'always'"
			>
				<table
					class="table-class custom-material-table incident-smart-grid-table"
					[ngClass]="scrollClass | async"
					mat-table
					[dataSource]="dataSource"
				>
					<p>{{ confDisplayedColumns }}</p>
					<ng-container *ngFor="let column of confDisplayedColumns">
						<ng-container *ngIf="column.type == 'summary'">
							<ng-container matColumnDef="{{ column.label }}" sticky>
								<mat-header-cell
									class="summary_card_title table-column-340"
									*matHeaderCellDef
									[ngClass]="stickyClassMap[column.label]"
								>
									<div class="mat_header_checkbox d-flex align-items-center">
										<!-- <mat-checkbox
                      *ngIf="multiselect"
                      (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [color]="'primary'"
                      [aria-label]="checkboxLabel()"
                      id="cd__multiselect"
                    >
                    </mat-checkbox> -->
										<div class="custom-control custom-checkbox" *ngIf="multiselect">
											<input
												type="checkbox"
												[aria-label]="checkboxLabel()"
												(change)="$event ? masterToggle() : null"
												[checked]="selection.hasValue() && isAllSelected()"
												class="custom-control-input"
												id="cb__multiselect"
												[attr.data-testid]="'cb__multiselect'"
											/>
											<label class="custom-control-label" for="cb__multiselect"></label>
										</div>
										<span *ngIf="!multiselect"></span>
										<span>{{ column.label | translate | uppercase }} </span>
										<span
											*ngIf="column.sortable != false"
											(click)="sortAsc(column)"
											class="summary_sort_up pointer"
											id="btnSortAsc__{{ column.label }}"
											attr.data-testid="btnSortAsc__{{ column.label }}"
										>
											<i class="fa-light fa-chevron-up fa-xs"></i>
										</span>
										<span
											*ngIf="column.sortable != false"
											(click)="sortDesc(column)"
											class="summary_sort_down pointer"
											id="btnSortDesc__{{ column.label }}"
											attr.data-testid="btnSortDesc__{{ column.label }}"
										>
											<i class="fa-light fa-chevron-down fa-xs"></i>
										</span>
									</div>
								</mat-header-cell>
								<mat-cell
									*matCellDef="let element"
									class="mat_cell summary_card border-right table-column-320 state-{{
										element.state_id
									}}"
									[ngClass]="stickyClassMap[column.label]"
								>
									<div class="summary-info">
										<div class="summary-sec">
											<div class="left-info">
												<div class="custom-control custom-checkbox" *ngIf="multiselect" attr.data-testid="multiSel__{{ element.display_id }}">
													<input
														type="checkbox"
														[aria-label]="checkboxLabel(element)"
														[(ngModel)]="element.checked"
														[checked]="selection.isSelected(element)"
														(change)="$event ? selectRow(element) : null"
														(click)="$event.stopPropagation()"
														class="custom-control-input"
														id="cb__{{ element.display_id }}"
														checked
													/>
													<label
														class="custom-control-label"
														for="cb__{{ element.display_id }}"
													></label>
												</div>
												<span
													class="mb-0 primary-color sm-font fw-500 mr-50 cursor-pointer"
													(click)="onActionIconClick('comment', element, true)"
													id="sp__{{ element[column.key] }}"
													attr.data-testid="sp__{{ element[column.key] }}"
													>{{ element[column.key] }}</span
												>
												<div class="summary-config-icons">
													<ng-container
														class="ml-50"
														*ngFor="let option of summaryCardConfig['title_icons'].options"
													>
														<ng-container
															*ngIf="
																option.selected &&
																option.key != 'incident_source_id' &&
																option.key != 'callback'
															"
															class="rounded icon-class"
														>
															<div
																class="summary-card-config"
																id="link__{{ option.key }}"
																*ngIf="
																	option.key == 'root_alarm'
																		? element?.is_parent_incident
																		: true
																"
																placement="top"
																container="body"
																ngbTooltip="{{ option.option_name | translate }}"
																attr.data-testid="link__{{ option.key }}"
															>
																<i
																	class="fa-light fa-{{ option.icon }} text-primary"
																></i>
															</div>
														</ng-container>
														<ng-container
															*ngIf="
																option.selected && option.key == 'incident_source_id'
															"
															class="rounded icon-class"
														>
															<div
																class="summary-card-config"
																id="link__{{ option.key }}"
																placement="bottom"
																ngbTooltip="{{ element?.source_name }}"
																container="body"
																attr.data-testid="link__{{ option.key }}"
															>
																<i
																	class="fa-light fa-{{
																		options.sourceIconMap?.[element[option.key]]
																	}} text-primary"
																></i>
															</div>
														</ng-container>
														<ng-container
															*ngIf="
																option.selected &&
																option.key == 'callback' &&
																element[option.key]
															"
															class="rounded icon-class"
														>
															<div
																class="summary-card-config"
																id="link__{{ option.key }}"
																placement="bottom"
																ngbTooltip="{{ option.option_name | translate }}"
																container="body"
																attr.data-testid="link__{{ option.key }}"
															>
																<!-- <i class="fa-light fa-{{option.icon}}"></i> -->
																<i
																	class="fa-light fa-{{
																		options.callbackIconMap[element[option.key]]
																	}} text-primary"
																></i>
																<!--<i class="fa-light {{ option.icon }}"></i> &lt;!&ndash;if fontawesme icons&ndash;&gt;-->
															</div>
														</ng-container>
													</ng-container>
													<ng-container
														*ngFor="
															let summaryOption of summaryCardConfig['sub_heading']
																.options
														"
													>
														<ng-container *ngIf="element[summaryOption.key]">
															<div
																class="summary-card-config"
																[ngbPopover]="popContent"
																[autoClose]="'outside'"
																popoverClass="summary-profile-popover"
																container="body"
															>
																<i class="fa-light fa-desktop text-primary"></i>
																<ng-template #popContent>
																	<ng-container
																		*ngFor="
																			let option of summaryCardConfig[
																				'sub_heading'
																			].options
																		"
																	>
																		<!-- (click)="moveToEdit(element.incident_id)" -->
																		<div class="d-flex">
																			<!-- <span>{{element[option.key]}}</span> -->
																			<span
																				class="title-color fw-500"
																				*ngIf="element[option.key]"
																				>{{ option.option_name | translate }} :
																				&nbsp;
																			</span>
																			<div *ngIf="option.key != 'asset_id'">
																				<span
																					class="cursor-pointer"
																					title="{{ element[option.key] }}"
																				>
																					{{
																						element[option.key]
																							| slice: 0:19
																					}}{{
																						element[option.key]?.length > 19
																							? ".."
																							: ""
																					}}</span
																				>
																			</div>
																			<div
																				*ngIf="
																					option.key == 'asset_id' &&
																					element.cmdb_id
																				"
																			>
																				<span
																					*ngIf="!element?.is_asset_disabled"
																					class="text-primary cursor-pointer"
																					title="{{ element[option.key] }}"
																					(click)="info_action(element)"
																				>
																					{{
																						element[option.key]
																							| slice: 0:19
																					}}{{
																						element[option.key]?.length > 19
																							? ".."
																							: ""
																					}}</span
																				>
																				<span
																					*ngIf="element?.is_asset_disabled"
																					class=""
																					title="{{ element[option.key] }}"
																				>
																					{{
																						element[option.key]
																							| slice: 0:19
																					}}{{
																						element[option.key]?.length > 19
																							? ".."
																							: ""
																					}}</span
																				>
																			</div>
																		</div>
																	</ng-container>
																	<!-- <ng-container
																*ngFor="let option of summaryCardConfig['profile'].options"
															>
																<ng-container *ngIf="option.selected == true">
																	<div
																		class="avatar profile_image avatar-sm"
																		[ngClass]="{ 'bg-light-danger': !element.state_id }"
																		[ngStyle]="{
																			'background-color':
																				element[option.avatar_color_key]
																		}"
																		(mouseover)="(option.profile)"
																		class="btn-{{ option.profile }}"
																	>
																		<div class="avatar-content">
																			<ng-container *ngIf="element[option.profile]">
																				<img
																					[src]="element[option.profile]"
																					width="16"
																					height="16"
																				/>
																			</ng-container>
																			<div *ngIf="!element[option.profile]">
																				{{
																					getTranslatableKey(
																						element[option.key]?.replaceAll(
																							"@@@",
																							""
																						)
																					)
																						| translate
																						| initials
																						| slice: O:2
																				}}
																			</div>
																		</div>
																		<ng-container *ngIf="option.profile">
																			<div class="popover-comment card-bg border">
																				<div
																					class="d-flex justify-content-start align-items-center"
																				>
																					<div
																						class="avatar avatar-lg"
																						[ngClass]="{
																							'bg-light-danger':
																								!element.state_id
																						}"
																						[ngStyle]="{
																							'background-color':
																								element[
																									option.avatar_color_key
																								]
																						}"
																					>
																						<div class="avatar-content">
																							<ng-container
																								*ngIf="
																									element[option.profile]
																								"
																							>
																								<img
																									[src]="
																										element[
																											option.profile
																										]
																									"
																									style="
																										width: 24px;
																										height: 24px;
																									"
																								/>
																							</ng-container>
																							<div
																								*ngIf="
																									!element[option.profile]
																								"
																							>
																								{{
																									getTranslatableKey(
																										element[
																											option.key
																										]?.replaceAll(
																											"@@@",
																											""
																										)
																									)
																										| translate
																										| initials
																										| slice: O:2
																								}}
																							</div>
																						</div>
																					</div>
																					<div class="ml-1">
																						<p
																							title="{{
																								element[option.key]
																							}}"
																							class="sm-font font-weight-bold mb-0"
																						>
																							{{
																								element[option.key]
																									| slice: O:30
																							}}
																						</p>
																					</div>
																				</div>
																				<div class="row border-bottom mt-1"></div>
																				<div
																					*ngIf="
																						element?.requester.requester_email
																					"
																					class="d-flex align-items-center justify-content-start my-50"
																				>
																					<i
																						class="fa-light fa-envelope text-primary mx-1"
																					></i>
																					<span class="text-muted sm-font">
																						{{
																							element?.requester.requester_email.slice(
																								0,
																								50
																							)
																						}}
																					</span>
																				</div>
																				<div
																					*ngIf="
																						element?.requester.requester_phone
																					"
																					class="d-flex align-items-center justify-content-start my-50"
																				>
																					<i
																						class="fa-light fa-phone text-primary mx-1"
																					></i>
																					<span class="text-muted sm-font">
																						{{
																							element?.requester
																								.requester_phone
																						}}
																					</span>
																				</div>
																				<div
																					*ngIf="
																						element?.requester.organization_name
																					"
																					class="d-flex align-items-center justify-content-start my-50"
																				>
																					<i
																						class="fa-light fa-phone text-primary mx-1"
																					></i>
																					<span class="text-muted sm-font">
																						{{
																							element?.requester
																								.organization_name
																						}}
																					</span>
																				</div>
																			</div>
																		</ng-container>
																	</div>
																</ng-container>
															</ng-container> -->
																</ng-template>
															</div>
														</ng-container>
													</ng-container>
												</div>
											</div>
											<div class="right-info">
												<ng-container *ngIf="element?.sentimental_data">
													<span class="pr-25">
														<ngx-emoji [emoji]="emojiData?.[element?.sentimental_data]" size="16"></ngx-emoji>
													</span>
												</ng-container>
												<span
													id="sp__sla_action"
													class="btn-btn__sla_action summary-config-icons"
													*ngIf="element?.sla_info?.length > 0"
													(click)="onActionIconClick('sla_action', element, false)"
													[attr.data-testid]="'sp__sla_action'"
												>
													<span class="summary-card-config">
														<i
															class="fa-light fa-timer text-primary"
															id="link__sla_action"
															tooltipClass="fadeInAnimation"
															placement="left"
															ngbTooltip="{{ 'UI.k_sla' | translate }}"
															[attr.data-testid]="'link__sla_action'"
														>
														</i>
													</span>
												</span>
												<ng-container
													*ngFor="let option of summaryCardConfig['status'].options"
												>
													<div class="card_state">
														<!-- (dblclick)="
																doEdit(
																	(option.sub_key
																		? option.sub_key + option.key
																		: option.key) +
																		'editable' +
																		element.incident_id,
																	option,
																	element.incident_id,
																	element
																)
															"
															(click)="
																singleClickEvent(
																	(column.sub_key
																		? column.sub_key + column.key
																		: column.key) +
																		'editable' +
																		element.incident_id,
																	column,
																	element.incident_id,
																	element,
																	searchObjectByKeyValue(
																		options[option?.optionKey],
																		'id',
																		element[option.key]
																	),
																	searchObjectByKeyValue(options[option?.optionKey], 'id', element[option.key]
																		)?.color
																)
															" -->
														<div
															*ngIf="
																!editData[
																	(option.sub_key
																		? option.sub_key + option.key
																		: option.key) +
																		'editable' +
																		element.incident_id
																]
															"
															class="font-small-2 {{
																searchObjectByKeyValue(
																	options[option?.optionKey],
																	'id',
																	element[option.key]
																)?.color
																	? 'badge'
																	: ''
															}} badge-light-{{
																searchObjectByKeyValue(
																	options[option?.optionKey],
																	'id',
																	element[option.key]
																)?.color
															}}"
															ngbTooltip="{{ option.option_name | translate }}"
															placement="left"
															[hidden]="
																editData?.isLoadSummaryCard &&
																element.incident_id === this.summaryInput.incident_id
															"
														>
															<i
																class="fa-light mr-25 {{
																	searchObjectByKeyValue(
																		options[option?.optionKey],
																		'id',
																		element[option.key]
																	)?.icon
																}}"
															></i>
															{{
																searchObjectByKeyValue(
																	options[option?.optionKey],
																	"id",
																	element[option.key]
																)?.name
															}}
														</div>
														<ng-container
															*ngIf="
																editData[
																	(column.sub_key
																		? column.sub_key + column.key
																		: column.key) +
																		'editable' +
																		element.incident_id
																] &&
																editData?.isLoadSummaryCard &&
																element.incident_id === this.summaryInput.incident_id
															"
														>
															<app-summary-card [summaryInput]="this.summaryInput">
															</app-summary-card>
														</ng-container>
														<select
															*ngIf="
																editData[
																	(option.sub_key
																		? option.sub_key + option.key
																		: option.key) +
																		'editable' +
																		element.incident_id
																]
															"
															class="form-control"
															ngbTooltip="{{ option.option_name | translate }}"
															placement="left"
															id="sel__{{ column.key }}__{{ element.display_id }}"
															(change)="
																updateInlineData(
																	$event,
																	option.id,
																	option.sub_key,
																	element,
																	option
																)
															"
															attr.data-testid="sel__{{ column.key }}__{{
																element.display_id
															}}"
														>
															<option
																[value]="opt['id']"
																*ngFor="let opt of options['priority']"
																[selected]="(opt['id'] == element[column.key]) == true"
															>
																{{ opt["name"] }}
															</option>
														</select>
													</div>
												</ng-container>
											</div>
										</div>
										<div class="summary-sec">
											<!-- <ng-container *ngFor="let option of summaryCardConfig['profile'].options">
												<ng-container *ngIf="option.selected == true">
													<div
														class="avatar profile_image avatar-sm"
														[ngClass]="{ 'bg-light-danger': !element.state_id }"
														[ngStyle]="{
															'background-color': element[option.profile]
																? null
																: element[option.avatar_color_key]
														}"
														(mouseover)="(option.profile)"
														class="btn-{{ option.profile }}"
													>
														<div class="avatar-content">
															<ng-container *ngIf="element[option.profile]">
																<img
																	[src]="element[option.profile]"
																	width="16"
																	height="16"
																/>
															</ng-container>
															<div *ngIf="!element[option.profile]">
																{{
																	getTranslatableKey(
																		element[option.key]?.replaceAll("@@@", "")
																	)
																		| translate
																		| initials
																		| slice: O:2
																}}
															</div>
														</div>
														<ng-container *ngIf="option.profile">
															<div class="popover-comment card-bg border">
																<div
																	class="d-flex justify-content-start align-items-center"
																>
																	<div
																		class="avatar avatar-lg"
																		[ngClass]="{
																			'bg-light-danger': !element.state_id
																		}"
																		[ngStyle]="{
																			'background-color':
																				element[option.avatar_color_key]
																		}"
																	>
																		<div class="avatar-content">
																			<ng-container
																				*ngIf="element[option.profile]"
																			>
																				<img
																					[src]="element[option.profile]"
																					style="width: 24px; height: 24px"
																				/>
																			</ng-container>
																			<div *ngIf="!element[option.profile]">
																				{{
																					getTranslatableKey(
																						element[option.key]?.replaceAll(
																							"@@@",
																							""
																						)
																					)
																						| translate
																						| initials
																						| slice: O:2
																				}}
																			</div>
																		</div>
																	</div>
																	<div class="ml-1">
																		<p
																			title="{{ element[option.key] }}"
																			class="sm-font font-weight-bold mb-0"
																		>
																			{{ element[option.key] | slice: O:30 }}
																		</p>
																	</div>
																</div>
																<div class="row border-bottom mt-1"></div>
																<ng-container *ngIf="option?.key == 'requester_name'">
																	<div
																		*ngIf="element?.requester.requester_email"
																		class="d-flex align-items-center justify-content-start my-50"
																	>
																		<i
																			class="fa-light fa-envelope text-primary mr-1"
																		></i>
																		<span class="text-muted sm-font">
																			{{
																				element?.requester.requester_email.slice(
																					0,
																					50
																				)
																			}}
																		</span>
																	</div>
																	<div
																		*ngIf="element?.requester.requester_phone"
																		class="d-flex align-items-center justify-content-start my-50"
																	>
																		<i
																			class="fa-light fa-phone text-primary mr-1"
																		></i>
																		<span class="text-muted sm-font">
																			+{{ element?.requester.requester_phone }}
																		</span>
																	</div>
																	<div
																		*ngIf="element?.requester.organization_name"
																		class="d-flex align-items-center justify-content-start my-50"
																	>
																		<i
																			class="fa-light fa-sitemap text-primary mr-1"
																		></i>
																		<span class="text-muted sm-font">
																			{{ element?.requester.organization_name }}
																		</span>
																	</div>
																</ng-container>
																<ng-container *ngIf="option?.key == 'created_by_name'">
																	
																	<div
																		*ngIf="element?.creator?.email"
																		class="d-flex align-items-center justify-content-start my-50"
																	>
																		<i
																			class="fa-light fa-envelope text-primary mr-1"
																		></i>
																		<span class="text-muted sm-font">
																			{{ element?.creator.email?.slice(0, 50) }}
																		</span>
																	</div>
																	<div
																		*ngIf="element?.creator?.contact_number"
																		class="d-flex align-items-center justify-content-start my-50"
																	>
																		<i
																			class="fa-light fa-phone text-primary mr-1"
																		></i>
																		<span class="text-muted sm-font">
																			+{{ element?.creator?.contact_number }}
																		</span>
																	</div>
																</ng-container>
															</div>
														</ng-container>
													</div>
												</ng-container>
											</ng-container> -->
											<ng-container *ngFor="let option of summaryCardConfig['summary'].options">
												<span
													class="summary-detail title-color truncate summary-ellipse-ticket"
													placement="bottom"
													container="body"
													ngbTooltip="{{
														option.sub_key
															? element[option.sub_key][option.key]
															: (element[option.key] | striphtml | slice: 0:150)
													}}"
												>
													{{
														option.sub_key
															? element[option.sub_key][option.key]
															: (element[option.key] | striphtml | slice: 0:150)
													}}</span
												>
											</ng-container>
										</div>
										<div class="summary-sec">
											<div class="left-info">
												<!-- <ng-container
													*ngFor="let option of summaryCardConfig['sub_heading'].options"
												>
													<span class="sm-font" *ngIf="element[option.key]"
														>{{ option.option_name | translate }} : &nbsp;
													</span>
													<div *ngIf="option.key != 'asset_id'">
														<span class="sm-font" title="{{ element[option.key] }}">
															{{ element[option.key] | slice: 0:19
															}}{{ element[option.key]?.length > 19 ? ".." : "" }}</span
														>
													</div>
													<div *ngIf="option.key == 'asset_id' && element.cmdb_id">
														<span
															*ngIf="
																asset_permissions?.view && !element?.is_asset_disabled
															"
															class="btn-{{ option.key }} sm-font "
															tooltipClass="fadeInAnimation"
															title="{{ element[option.key] }}"
															(click)="info_action(element)"
															(mouseover)="isShowAssetDetail = true"
															(mouseleave)="isShowAssetDetail = false"
														>
															<span class="text-primary">
																{{ element[option.key] | slice: 0:19
																}}{{ element[option.key]?.length > 19 ? ".." : "" }}
															</span>
														<ng-container
																*ngIf="
																isShowAssetDetail
																"
															>
																<div class="popover-comment card-bg border">
																	<div class="row comment-info">
																		<div class="mb-1">
																			<span class="mb-0 fw-600 mr-50 d-flex " style="overflow-wrap: anywhere" 
																				*ngIf="element?.impacted_asset[0]?.hostname">
																				{{ "UI.k_hostname" | translate }} :
																				{{
																					element?.impacted_asset[0]?.hostname
																				}}
																			</span>
																			<small class="font-small-2" *ngIf="element?.impacted_asset[0]?.ci_name" style="overflow-wrap: anywhere">
																				{{ "UI.k_ci_name" | translate }} : {{ element?.impacted_asset[0]?.ci_name }}
																			</small>
																			<small class="font-small-2" *ngIf="element?.impacted_asset[0]?.ip_address?.length > 0" style="overflow-wrap: anywhere">
																				{{ "UI.k_ip_address" | translate }} : {{ element?.impacted_asset[0]?.ip_address[0] }}
																				<span
																					*ngIf="
																					element?.impacted_asset[0]?.ip_address?.length > 1
																					"
																					class="font-small-2 primary-color ml-50"
																					#popoverHook="ngbPopover"
																					[ngbPopover]="popContent"
																					triggers="mouseenter:mouseleave"
																					placement="right"
																					popoverClass="my-custom-class"
																					container="body"
																					><i class="fa-light fa-circle-info"></i
																				></span>
																			</small>
																		</div>
																	</div>
																</div>
															</ng-container> 
														</span>
														<span
															*ngIf="
																!asset_permissions?.view || element?.is_asset_disabled
															"
															class="sm-font"
															title="{{ inci?.asset_id }}"
														>
															{{ element[option.key] | slice: 0:19
															}}{{ element[option.key]?.length > 19 ? ".." : "" }}
														</span>
													</div>
												</ng-container> -->
											</div>
											<div class="right-info">
												<div class="summary-action">
													<ng-container
														*ngFor="let option of summaryCardConfig['actions'].options"
													>
														<span
															id="sp__{{ option.key }}"
															class="btn-{{ option.key }}"
															(click)="onActionIconClick(option.key, element, false)"
															(mouseover)="
																option.key == 'comment'
																	? getComment(element.incident_id)
																	: false
															"
															attr.data-testid="sp__{{ option.key }}"
														>
															<ng-container *ngIf="option.key == 'comment'">
																<i
																	class="fa-light fa-{{ option.icon }} text-primary"
																	id="link__{{ option.key }}"
																	tooltipClass="fadeInAnimation"
																	placement="left"
																	ngbTooltip="{{ option.option_name | translate }}"
																	attr.data-testid="link__{{ option.key }}"
																>
																</i>
																<span
																	*ngIf="element.is_assignee_read == false"
																	class="showMsghighlight"
																>
																</span>
															</ng-container>
															<ng-container *ngIf="option.key != 'comment'">
																<i
																	class="fa-light fa-{{ option.icon }} text-primary"
																	id="link__{{ option.key }}"
																	tooltipClass="fadeInAnimation"
																	placement="left"
																	ngbTooltip="{{ option.option_name | translate }}"
																	attr.data-testid="link__{{ option.key }}"
																>
																</i>
															</ng-container>

															<ng-container
																*ngIf="
																	option.key == 'comment' &&
																	latest_comment?.mail_content
																"
															>
																<div class="popover-comment card-bg border">
																	<div *ngIf="mail_list?.length > 0">
																		<div class="comment-info">
																			<div class="left-sec">
																				<div class="user-detail">
																					<div class="avatar avatar-sm mr-50">
																						<span
																							class="avatar-content"
																							*ngIf="
																								!latest_comment
																									?.user_profile
																									?.profile_image
																							"
																							[ngStyle]="{
																								'background-color':
																									latest_comment
																										?.user_profile
																										?.avatar_color
																							}"
																						>
																							{{
																								latest_comment
																									?.user_profile
																									?.full_name
																									| initials
																									| slice: O:2
																							}}
																						</span>
																						<span
																							class="avatar-content"
																							*ngIf="
																								latest_comment
																									?.user_profile
																									?.profile_image
																							"
																						>
																							<img
																								[src]="
																									latest_comment
																										?.user_profile
																										?.profile_image
																								"
																								alt="avatar"
																							/>
																						</span>
																					</div>
																					<div
																						class="user-name sm-font title-color fw-500"
																					>
																						{{
																							latest_comment?.user_profile
																								?.full_name
																						}}
																					</div>
																				</div>
																			</div>
																			<div class="right-sec">
																				<button
																					type="button"
																					class="btn btn-xs btn-flat-primary"
																					rippleEffect
																				>
																					{{
																						"UI.k_all_conversations"
																							| translate
																					}}
																				</button>
																			</div>
																		</div>
																		<div class="date-time xs-font">
																			{{ latest_comment?.creation_time }}
																		</div>
																		<div
																			class="comment-txt"
																			style="overflow-wrap: anywhere"
																		>
																			<span
																				class="sm-font"
																				[innerHTML]="
																					latest_comment?.mail_content
																				"
																			></span>
																		</div>
																	</div>
																</div>
															</ng-container>
														</span>
													</ng-container>
												</div>
											</div>
										</div>
									</div>
								</mat-cell>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="column.type == 'sla'">
							<ng-container matColumnDef="{{ column.label }}" [sticky]="stickyColMap[column.label]">
								<mat-header-cell
									*matHeaderCellDef
									class="table-column-242"
									[ngClass]="stickyClassMap[column.label]"
								>
									<span>{{ column.label | translate | uppercase }}</span>
									<span
										*ngIf="column.sortable != false"
										(click)="sortAsc(column)"
										class="sort_up pointer"
										id="btnSortAsc__{{ column.label }}"
										attr.data-testid="btnSortAsc__{{ column.label }}"
									>
										<i class="fa-light fa-chevron-up fa-xs"></i>
									</span>
									<span
										*ngIf="column.sortable != false"
										(click)="sortDesc(column)"
										class="sort_down pointer"
										id="btnSortDesc__{{ column.label }}"
										attr.data-testid="btnSortDesc__{{ column.label }}"
									>
										<i class="fa-light fa-chevron-down fa-xs pl-25"></i>
									</span>
								</mat-header-cell>
								<mat-cell
									*matCellDef="let element"
									class="table-column-242"
									[ngClass]="stickyClassMap[column.label]"
								>
									<div
										class="table_content_text"
										(click)="show_sla_info_view(element)"
										id="divShowSLA"
										[attr.data-testid]="'divShowSLA'"
									>
										<!-- <app-dns-sla-timer 
											[startTime]="element?.sla?.[column.key]?.start_time" 
											[expectedEndTime]="element?.sla?.[column.key]?.expected_end_time" 
											[endTime]="element?.sla?.[column.key]?.end_time" 
											[isBreached]="element?.sla?.[column.key]?.is_breached"
											[metricData]="element?.sla?.[column.key]"
										>
										</app-dns-sla-timer> -->
										<app-dns-sla-timer
											[key]="column?.key"
											[subkey]="column?.subkey"
											[id]="column?.id"
											[data]="element"
											[rowindex]="i"
										>
										</app-dns-sla-timer>
										<!-- {{ column | json}}
										{{element?.sla?.[column.key] | json}}
										{{element?.sla | json }} -->
										<!-- <div class="table_content_text">
											Response time
											<div class="progress_bar_time">
												<span>02:56:51</span>
												<span class="percent">25%</span>
											</div>
											<div class="progress-wrapper">
												<ngb-progressbar
													class="progress_bar"
													type="success"
													[value]="25"
												></ngb-progressbar>
											</div>
											Response time
											<div class="progress_bar_time">
												<span>02:56:51</span>
												<span class="percent">25%</span>
											</div>
											<div class="progress_bar progress-wrapper">
												<ngb-progressbar
													class="progress_bar"
													type="success"
													[value]="25"
												></ngb-progressbar>
											</div>
										</div> -->
									</div>
								</mat-cell>
							</ng-container>
						</ng-container>
						<ng-container
							*ngIf="
								column.type != 'summary' &&
								column.type != 'sla' &&
								column.type != 'actions' &&
								column.key != 'requester_name' &&
								column.type != 'aging_period' &&
								column.type != 'organization_name'
							"
						>
							<ng-container
								matColumnDef="{{ column.label }}"
								[sticky]="column.freezed === true ? true : false"
							>
								<mat-header-cell
									*matHeaderCellDef
									[ngClass]="
										column_list_200.includes(column.key)
											? 'table-column-350 ' + stickyClassMap[column.label]
											: 'table-column-200 ' + stickyClassMap[column.label]
									"
								>
									<span>{{ column.label | translate | uppercase }} </span>
									<span
										*ngIf="column.sortable != false"
										(click)="sortAsc(column)"
										class="sort_up pointer"
										id="btnSortAsc__{{ column.label }}"
										attr.data-testid="btnSortAsc__{{ column.label }}"
									>
										<i class="fa-light fa-chevron-up fa-xs"></i>
									</span>
									<span
										*ngIf="column.sortable != false"
										(click)="sortDesc(column)"
										class="sort_down pointer"
										id="btnSortDesc__{{ column.label }}"
										attr.data-testid="btnSortDesc__{{ column.label }}"
									>
										<i class="fa-light fa-chevron-down fa-xs pl-25"></i>
									</span>
								</mat-header-cell>
								<mat-cell
									*matCellDef="let element; let i = index"
									[ngClass]="
										column_list_200.includes(column.key)
											? 'table-column-350 '
											: 'table-column-200 ' + stickyClassMap[column.label]
									"
								>
									<ng-container *ngIf="!column.type">
										<div class="{{ column['class'] }} table_content_text w-100">
											<ng-container
												*ngIf="
													!editData[
														(column.sub_key ? column.sub_key + column.key : column.key) +
															'editable' +
															element.incident_id
													]
												"
											>
												<!-- <ng-container *ngIf="column.key === 'assignee' && !column.type">
                                                <app-user-picker  [customClass]="'right'" [inputConfig]="{isLabelHide: true,userInput: this.userInput,id:'user'+element.incident_id}"></app-user-picker>
                                            </ng-container> -->

												<div
													(dblclick)="
														doEdit(
															(column.sub_key
																? column.sub_key + column.key
																: column.key) +
																'editable' +
																element.incident_id,
															column,
															element.incident_id,
															element
														)
													"
													(click)="
														singleClickEvent(
															(column.sub_key
																? column.sub_key + column.key
																: column.key) +
																'editable' +
																element.incident_id,
															column,
															element.incident_id,
															element
														)
													"
													class="text-break incident-title-truncate"
													id="txt__{{ column.key }}__{{ element.display_id }}"
													attr.data-testid="txt__{{ column.key }}__{{ element.display_id }}"
													placement="bottom"
													ngbTooltip="{{
														column.sub_key
															? element[column.sub_key][column.key]
															: element[column.key]
													}}"
												>
													<span
														*ngIf="
															column.key !== 'requester_name' && column.key !== 'assignee'
														"
													>
														{{
															column.sub_key
																? element[column.sub_key][column.key]
																: element[column.key]
														}}
													</span>

													<ng-container *ngIf="column.key === 'assignee'">
														<div class="d-flex align-items-center justify-content-start">
															<div class="avatar avatar-sm mr-75 text-dark">
																<div class="avatar-content">
																	<span
																		*ngIf="
																			!element?.assignment_info
																				?.assignee_profile_image &&
																			element?.assignment_info?.assignee !=
																				undefined
																		"
																		class="avatar-content"
																		[ngStyle]="{
																			'background-color': element?.assignment_info
																				?.assignee_avatar_color
																				? element?.assignment_info
																						?.assignee_avatar_color
																				: defaultAvatarColor
																		}"
																	>
																		{{
																			element?.assignment_info?.assignee
																				| initials
																				| slice: O:2
																		}}
																	</span>
																	<span
																		class="avatar-content"
																		*ngIf="
																			element?.assignment_info
																				?.assignee_profile_image
																		"
																	>
																		<img
																			[src]="
																				element?.assignment_info
																					?.assignee_profile_image
																			"
																			alt="avatar"
																		/>
																	</span>
																</div>
															</div>
															<span class="text-muted sm-font">
																{{ element?.assignment_info?.assignee }}
															</span>
														</div>
													</ng-container>

													<!-- <ng-container *ngIf="column.key === 'requester_name'">
														<div class="d-flex align-items-center justify-content-start">
															<div class="avatar avatar-sm mr-75">
																<div class="avatar-content">
																	<span
																		*ngIf="
																			!element?.requester_profile &&
																			element?.requester?.requester_name
																		"
																		class="avatar-content text-dark"
																		[ngStyle]="{
																			'background-color':
																				element?.requester_avatar_color
																					? element?.requester_avatar_color
																					: defaultAvatarColor
																		}"
																	>
																		{{
																			element?.requester?.requester_name
																				| initials
																				| slice: O:2
																		}}
																	</span>
																	<span
																		*ngIf="
																			!element?.requester_profile &&
																			!element?.requester?.requester_name
																		"
																		class="avatar-content text-dark"
																		[ngStyle]="{
																			'background-color':
																				element?.requester_avatar_color
																					? element?.requester_avatar_color
																					: defaultAvatarColor
																		}"
																	>
																		{{
																			element?.requester?.requester_email
																				| initials
																				| slice: O:1
																		}}
																	</span>
																	<span
																		class="avatar-content"
																		*ngIf="element?.requester_profile"
																	>
																		<img
																			[src]="element?.requester_profile"
																			alt="avatar"
																		/>
																	</span>
																</div>
															</div>

															<div class="d-grid">
																<span class="title-color sm-font line-height-1 mb-50">
																	{{ element?.requester?.requester_name }}
																</span>
																<span class="xs-font line-height-1">
																	{{ element?.requester?.requester_email }}
																</span>
															</div>
														</div>
													</ng-container> -->
												</div>
											</ng-container>
											<ng-container
												*ngIf="
													editData[
														(column.sub_key ? column.sub_key + column.key : column.key) +
															'editable' +
															element.incident_id
													] && !editData?.isLoadUserPicker
												"
											>
												<input
													autofocus
													class="form-control form-control-sm"
													(blur)="
														updateInlineData(
															$event,
															column.key,
															column.sub_key,
															element,
															column
														)
													"
													(keyup)="
														onEnterInlineEdit(
															$event,
															column.key,
															column.sub_key,
															element,
															(column.sub_key
																? column.sub_key + column.key
																: column.key) +
																'editable' +
																element.incident_id,
															column
														)
													"
													type="text"
													id="txt__{{ column.key }}__{{ element.display_id }}"
													attr.data-testid="txt__{{ column.key }}__{{ element.display_id }}"
													[value]="
														column.sub_key
															? element[column.sub_key][column.key]
															: element[column.key]
													"
												/>
											</ng-container>
										</div>
									</ng-container>

									<ng-container
										*ngIf="column.type == 'requester_tag' && this.element?.requester?.tag"
									>
										<div class="remove-wrap">
											<ng-container
												*ngFor="let req_tag of element?.requester?.tag; let i = index"
											>
												<div class="badge {{ colorArray[i % 6] }} mr-50">
													<i class="fa-tag fa-light mr-25"></i> {{ req_tag }}
												</div>
											</ng-container>
										</div>
									</ng-container>
									<ng-container *ngIf="column.type == 'tag' && this.element?.tag">
										<div class="remove-wrap">
											<ng-container *ngFor="let tag of element?.tag; let i = index">
												<div class="badge {{ colorArray[i % 6] }} mr-50">
													<i class="fa-tag fa-light mr-25"></i> {{ tag }}
												</div>
											</ng-container>
										</div>
									</ng-container>

									<ng-container *ngIf="column.type == 'rich_text'">
										<div class="{{ column['class'] }} text-secondary table_content_text ">
											<ng-container
												*ngIf="
													!editData[
														(column.sub_key ? column.sub_key + column.key : column.key) +
															'editable' +
															element.incident_id
													]
												"
											>
												<div
													(dblclick)="
														doEdit(
															(column.sub_key
																? column.sub_key + column.key
																: column.key) +
																'editable' +
																element.incident_id,
															column,
															element.incident_id,
															element
														)
													"
													class="text-secondary text-break"
													id="txt__{{ column.key }}__{{ element.display_id }}"
													attr.data-testid="txt__{{ column.key }}__{{ element.display_id }}"
													placement="bottom"
													container="body"
													ngbTooltip="{{
														onDisplaykey(
															column.sub_key
																? element[column.sub_key][column.key]
																: element[column.key]
														)
													}}"
												>
													{{
														onDisplaykey(
															column.sub_key
																? element[column.sub_key][column.key]
																: element[column.key]
														)
													}}
												</div>
											</ng-container>
											<ng-container
												*ngIf="
													editData[
														(column.sub_key ? column.sub_key + column.key : column.key) +
															'editable' +
															element.incident_id
													]
												"
											>
												<input
													autofocus
													class="form-control form-control-sm"
													(blur)="
														updateInlineData(
															$event,
															column.key,
															column.sub_key,
															element,
															column
														)
													"
													(keyup)="
														onEnterInlineEdit(
															$event,
															column.key,
															column.sub_key,
															element,
															(column.sub_key
																? column.sub_key + column.key
																: column.key) +
																'editable' +
																element.incident_id,
															column
														)
													"
													type="text"
													id="txt__{{ column.key }}__{{ element.display_id }}"
													attr.data-testid="txt__{{ column.key }}__{{ element.display_id }}"
													[value]="
														column.sub_key
															? element[column.sub_key][column.key]
															: element[column.key]
													"
												/>
											</ng-container>
										</div>
									</ng-container>
									<ng-container *ngIf="column.type == 'link'">
										<a
											[href]="column.url ? column.url : '#'"
											id="link__{{ column.key }}__{{ element.display_id }}"
											attr.data-testid="link__{{ column.key }}__{{ element.display_id }}"
											>{{
												column.sub_key
													? element[column.sub_key][column.key]
													: element[column.key]
											}}</a
										>
									</ng-container>
									<ng-container
										*ngIf="
											element['dropdown_options'][column.optionKey + '_config']?.is_editable &&
											column.type == 'tree_view_select' &&
											permissions?.edit
										"
									>
										<div class="input-tree-dropdown w-100">
											<app-single-tree-dropdown
												class="w-100"
												[items]="element['dropdown_options'][column.optionKey]"
												[value]="element[column.key]"
												[id]="element.incident_id"
												(itemChange)="this.serviceValueChange($event, column.key, element)"
											>
											</app-single-tree-dropdown>
										</div>
									</ng-container>
									<div
										*ngIf="
											(!element['dropdown_options'][column.optionKey + '_config']?.is_editable ||
												!permissions?.edit) &&
											(column.type == 'tree_view_select' ||
												column.type == 'tree_view_parent_select')
										"
										(click)="onImpactServiceClick(element, column, $event)"
									>
										<div
											id="treeView{{ column.key }}_{{ i }}"
											attr.data-testid="treeView{{ column.key }}_{{ i }}"
										>
											<label
												class="form-check-label text-truncate-grid"
												ngbTooltip="{{ element[column.value] }}"
												placement="bottom"
												container="body"
												[class.pointer-event]="![1, 2].includes(element?.state_id)"
												>{{ element[column.value] || ("UI.k_select_option" | translate) }}
												<span
													*ngIf="
														element['dropdown_options'][column?.optionKey + '_config']
															?.is_loading
													"
													class="fa fa-spinner fa-spin fa-2x"
												></span
											></label>
										</div>
									</div>

									<ng-container *ngIf="column.type == 'badge_select' && !is_priority_load">
										<app-select-badge
											[inputConfig]="{
												hideLabel: true,
												inputOptions: options[column.optionKey],
												id: column.key,
												model: element[column.key],
												isEditDisabled:
													element?.state_id == 6 ||
													(element?.is_exit && element?.state_id == 5) ||
													!permissions?.edit
											}"
											(onValueChange)="
												statusBadgeChange($event, column.key, column.subkey, element, column)
											"
										></app-select-badge>
									</ng-container>

									<ng-container
										*ngIf="
											element['dropdown_options'][column?.optionKey + '_config']?.is_editable &&
											column.type == 'tree_view_parent_select' &&
											permissions?.edit
										"
									>
										<div class="input-tree-dropdown w-100">
											<app-single-tree-dropdown
												class="w-100"
												[items]="
													element?.impact_service
														? element?.dropdown_options[column?.optionKey]
														: []
												"
												[value]="element[column.key]"
												[id]="'smart-service' + element.incident_id"
												[treeInputConfig]="{ isParentAllowed: true }"
												(itemChange)="
													this.classificationValueChange($event, column.key, element)
												"
											>
											</app-single-tree-dropdown>
										</div>
									</ng-container>
									<ng-container
										*ngIf="
											column.type == 'select' &&
											column.key != 'impact_service' &&
											column.key != 'service_classification'
										"
									>
										<ng-container
											*ngIf="
												!editData[
													(column.sub_key ? column.sub_key + column.key : column.key) +
														'editable' +
														element.incident_id
												]
											"
										>
											<div
												class="text-secondary table_content_text"
												(dblclick)="
													doEdit(
														(column.sub_key ? column.sub_key + column.key : column.key) +
															'editable' +
															element.incident_id,
														column,
														element.incident_id,
														element
													)
												"
											>
												{{
													searchObjectByKeyValue(
														options[column.optionKey],
														"id",
														column.sub_key
															? element[column.sub_key][column.key]
															: element[column.key]
													)?.name
												}}
											</div>
										</ng-container>
										<ng-container
											*ngIf="
												editData[
													(column.sub_key ? column.sub_key + column.key : column.key) +
														'editable' +
														element.incident_id
												]
											"
										>
											<select
												class="form-control"
												(change)="
													updateInlineData(
														$event,
														column.key,
														column.sub_key,
														element,
														column
													)
												"
												id="sel__{{ column.key }}__{{ element.display_id }}"
												data_testid="sel__{{ column.key }}__{{ element.display_id }}"
											>
												<option
													[value]="opt['id']"
													*ngFor="let opt of options[column.optionKey]"
													[selected]="
														(opt['id'] ==
															(column.sub_key
																? element[column.sub_key][column.key]
																: element[column.key])) ==
														true
													"
												>
													{{ opt["name"] }}
												</option>
											</select>
										</ng-container>
									</ng-container>
									<ng-container
										*ngIf="column.type == 'select-template-status' && column.key === 'status'"
									>
										<div class="w-100">
											<app-status-picker
												[inputConfig]="{
													isTextMode: true,
													hideLabel: true,
													isEditDisabled:
														(element?.is_exit && element?.state_id == 5) ||
														!permissions?.edit ||
														element?.state_id == 6,
													stateOptionsMap: options?.state_id_map,
													id: i
												}"
												[options]="element['dropdown_options'][column.optionKey]"
												[dropdownPosition]="'top'"
												[value]="element[column.key]?.id"
												(setStatus)="
													statusEvent(
														$event,
														column.key,
														column.sub_key,
														element,
														column,
														'app-respond'
													)
												"
											></app-status-picker>
										</div>
									</ng-container>

									<ng-container *ngIf="column.type == 'multi-select'">
										<div class="position-absolute">
											<ng-container
												*ngIf="
													!editData[
														(column.sub_key ? column.sub_key + column.key : column.key) +
															'editable' +
															element.incident_id
													]
												"
											>
												<div
													class="text-secondary table_content_text"
													(dblclick)="
														doEdit(
															(column.sub_key
																? column.sub_key + column.key
																: column.key) +
																'editable' +
																element.incident_id,
															column,
															element.incident_id,
															element
														)
													"
													id="sel__{{ column.key }}__{{ element.display_id }}"
												>
													{{
														searchMultiObjectByKeyValue(
															options[column?.optionKey],
															"id",
															column.sub_key
																? element[column.sub_key][column.key]
																: element[column.key]
														)
													}}
												</div>
											</ng-container>

											<ng-container
												*ngIf="
													editData[
														(column.sub_key ? column.sub_key + column.key : column.key) +
															'editable' +
															element.incident_id
													]
												"
											>
												<ng-select
													[items]="options[column.optionKey]"
													id="txtIncidentMSelect"
													name="txtIncidentMSelect"
													[(ngModel)]="element[column.key]"
													(change)="
														updateInlineData(
															$event,
															column.key,
															column.sub_key,
															element,
															column
														)
													"
													[multiple]="true"
													[searchable]="true"
													[closeOnSelect]="true"
													bindLabel="name"
													bindValue="id"
													hideSelected="true"
												>
												</ng-select>
											</ng-container>
										</div>
									</ng-container>
									<ng-container *ngIf="column?.type == 'location'">
										<app-location-design
											[data]="element"
											[rowindex]="i"
											[key]="column?.key"
											[subkey]="column?.sub_key"
										>
										</app-location-design>
									</ng-container>
								</mat-cell>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="column.key === 'requester_name'">
							<ng-container
								matColumnDef="{{ column.label }}"
								[sticky]="column.freezed === true ? true : false"
							>
								<mat-header-cell *matHeaderCellDef class="table-column-300">
									<span>{{ column.label | translate | uppercase }} </span>
									<span
										*ngIf="column.sortable != false"
										(click)="sortAsc(column)"
										class="sort_up pointer"
										id="btnSortAsc__{{ column.label }}"
										attr.data-testid="btnSortAsc__{{ column.label }}"
									>
										<i class="fa-light fa-chevron-up fa-xs"></i>
									</span>
									<span
										*ngIf="column.sortable != false"
										(click)="sortDesc(column)"
										class="sort_down pointer"
										id="btnSortDesc__{{ column.label }}"
										attr.data-testid="btnSortDesc__{{ column.label }}"
									>
										<i class="fa-light fa-chevron-down fa-xs pl-25"></i>
									</span>
								</mat-header-cell>
								<mat-cell *matCellDef="let element; let i = index" class="table-column-300">
									<ng-container *ngIf="!column.type">
										<div class="{{ column['class'] }} table_content_text w-100">
											<ng-container
												*ngIf="
													!editData[
														(column.sub_key ? column.sub_key + column.key : column.key) +
															'editable' +
															element.incident_id
													]
												"
											>
												<!-- <ng-container *ngIf="column.key === 'assignee' && !column.type">
											<app-user-picker  [customClass]="'right'" [inputConfig]="{isLabelHide: true,userInput: this.userInput,id:'user'+element.incident_id}"></app-user-picker>
										</ng-container> -->

												<div
													(dblclick)="
														doEdit(
															(column.sub_key
																? column.sub_key + column.key
																: column.key) +
																'editable' +
																element.incident_id,
															column,
															element.incident_id,
															element
														)
													"
													(click)="
														singleClickEvent(
															(column.sub_key
																? column.sub_key + column.key
																: column.key) +
																'editable' +
																element.incident_id,
															column,
															element.incident_id,
															element
														)
													"
													class="text-break incident-title-truncate"
													id="txt__{{ column.key }}__{{ element.display_id }}"
													attr.data-testid="txt__{{ column.key }}__{{ element.display_id }}"
													placement="bottom"
													ngbTooltip="{{
														column.sub_key
															? element[column.sub_key][column.key]
															: element[column.key]
													}}"
												>
													<span
														*ngIf="
															column.key !== 'requester_name' && column.key !== 'assignee'
														"
													>
														{{
															column.sub_key
																? element[column.sub_key][column.key]
																: element[column.key]
														}}
													</span>
													<ng-container *ngIf="column.key === 'requester_name'">
														<!-- <p>{{ element?.requester | json}}</p> -->
														<div class="d-flex align-items-center justify-content-start">
															<div class="avatar avatar-sm mr-75">
																<div class="avatar-content">
																	<span
																		*ngIf="
																			!element?.requester_profile &&
																			element?.requester?.requester_name
																		"
																		class="avatar-content text-dark"
																		[ngStyle]="{
																			'background-color':
																				element?.requester_avatar_color
																					? element?.requester_avatar_color
																					: defaultAvatarColor
																		}"
																	>
																		{{
																			element?.requester?.requester_name
																				| initials
																				| slice: O:2
																		}}
																	</span>
																	<span
																		*ngIf="
																			!element?.requester_profile &&
																			!element?.requester?.requester_name
																		"
																		class="avatar-content text-dark"
																		[ngStyle]="{
																			'background-color':
																				element?.requester_avatar_color
																					? element?.requester_avatar_color
																					: defaultAvatarColor
																		}"
																	>
																		{{
																			element?.requester?.requester_email
																				| initials
																				| slice: O:1
																		}}
																	</span>
																	<span
																		class="avatar-content"
																		*ngIf="element?.requester_profile"
																	>
																		<img
																			[src]="element?.requester_profile"
																			alt="avatar"
																		/>
																	</span>
																</div>
															</div>

															<div class="d-grid">
																<span
																	class="title-color fw-500 sm-font line-height-1 mb-25"
																>
																	{{ element?.requester?.requester_name }}
																</span>
																<span class="sm-font">
																	{{ element?.requester?.requester_email }}
																</span>
															</div>
														</div>
													</ng-container>
												</div>
											</ng-container>
											<ng-container
												*ngIf="
													editData[
														(column.sub_key ? column.sub_key + column.key : column.key) +
															'editable' +
															element.incident_id
													] && !editData?.isLoadUserPicker
												"
											>
												<input
													autofocus
													class="form-control form-control-sm"
													(blur)="
														updateInlineData(
															$event,
															column.key,
															column.sub_key,
															element,
															column
														)
													"
													(keyup)="
														onEnterInlineEdit(
															$event,
															column.key,
															column.sub_key,
															element,
															(column.sub_key
																? column.sub_key + column.key
																: column.key) +
																'editable' +
																element.incident_id,
															column
														)
													"
													type="text"
													id="txt__{{ column.key }}__{{ element.display_id }}"
													[value]="
														column.sub_key
															? element[column.sub_key][column.key]
															: element[column.key]
													"
												/>
											</ng-container>
										</div>
									</ng-container>
								</mat-cell>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="column.type == 'actions' && isEditable == false">
							<ng-container matColumnDef="actions" stickyEnd>
								<mat-header-cell *matHeaderCellDef>
									<span>{{ column.label | translate | uppercase }} </span>
								</mat-header-cell>
								<mat-cell
									class="row_icon"
									*matCellDef="let element"
									id="hover_{{ element.display_id }}"
								>
									<div class="sticky-col d-flex align-items-center">
										<ng-container *ngFor="let action of column.action_list; let i = index">
											<ng-container
												*ngIf="
													action.type == 'button' &&
													i < 6 &&
													checkCondition(action.key, element)
												"
											>
												<button
													type="button"
													*ngIf="element.state_id < 4"
													(click)="
														action.key == 'resolve'
															? onResolve('resolve-incident', element)
															: action.callback(element)
													"
													id="btn__{{ action['key'] }}__{{ element.display_id }}"
													attr.data-testid="btn__{{ action['key'] }}__{{
														element.display_id
													}}"
													class="btn {{ action.class }} btn-sm mr-50"
													rippleEffect
												>
													{{ action["btn_text"] | translate }}
												</button>
												<button
													type="button"
													*ngIf="element.state_id >= 4 && element.state_id != 6"
													(click)="
														action.key == 'resolve'
															? onResolve('resolve-incident', element)
															: action.callback(element)
													"
													id="btn__complete__{{ element.display_id }}"
													attr.data-testid="btn__complete__{{ element.display_id }}"
													class="btn {{ action.class }} btn-sm mr-50"
													rippleEffect
												>
													{{ "UI.k_complete" | translate }}
												</button>
												<button
													type="button"
													*ngIf="element.state_id == 6 && element?.can_approve"
													(click)="onApproval('approval', element, $event)"
													id="btn__{{ action['key'] }}__{{ element.display_id }}"
													attr.data-testid="btn__{{ action['key'] }}__{{
														element.display_id
													}}"
													class="btn {{ action.class }} btn-sm mr-50"
													rippleEffect
												>
													{{ "UI.k_approve" | translate }}
												</button>
											</ng-container>
										</ng-container>
										<ng-container *ngFor="let action of selectedActionList; let i = index">
											<ng-container
												*ngIf="
													action.type == 'icon' &&
													(permissions[
														action?.permissionKey ? action.permissionKey : action.key
													] ||
														action.key == undefined) &&
													checkConvertToKBCondition(action, element)
												"
											>
												<!--<div *ngIf="action.type=='icon'">-->
												<!--<button type="button" id="btn{{i}}" ngbTooltip="{{ action.tooltip }}" placement="left" name="{{ action.tooltip }}" class="btn btn-icon btn-outline-white mr-75" (click)="action.callback(element)" rippleEffect><img src="assets/images/icons/ticket.svg" width="12" height="12" /></button>&nbsp;-->
												<!--</div>-->
												<button
													type="button"
													class="btn btn-sm btn-icon btn-outline-tertiary pointer mr-50"
													*ngIf="action.key !== 'requester_calling'"
													[ngStyle]="{
														color:
															action.action_name == 'UI.k_delete' ? '#ea5455' : '#0d51d9'
													}"
													(click)="action.callback(element)"
													tooltipClass="fadeInAnimation"
													placement="bottom"
													ngbTooltip="{{ action.tooltip | translate }}"
													id="link__{{ action['key'] }}__{{ element.display_id }}"
													attr.data-testid="link__{{ action['key'] }}__{{
														element.display_id
													}}"
												>
													<!--<i [size]="14" [data-feather]="action.icon_name"></i>-->
													<span
														><i
															class="fa-light {{ action.icon_name }} {{ action?.class }}"
														></i
													></span>
												</button>
												<ng-container *ngIf="action?.key === 'requester_calling' && element?.requester?.requester_phone">
													<app-infraon-call
														[requester]="element?.requester"
														[requester_profile]="element?.requester_profile"
														[view_type]="'grid'"
														[action]="action"
														[currentCardDisplayId]="element.display_id"
													>
													</app-infraon-call>
												</ng-container>
											</ng-container>
											<ng-container
												*ngIf="
													action.type == 'icon' &&
													action?.controlled_action == true &&
													action?.enable(element, action?.key)
												"
											>
												<button
													placement="bottom"
													ngbTooltip="{{
														action.getTooltip(element, action?.key) | translate
													}}"
													type="button"
													class="btn btn-sm btn-icon btn-outline-tertiary mr-50 {{
														action?.class
													}}"
													(click)="action?.callback(element)"
													rippleEffect
												>
													<span
														><i
															class="fa-light {{ action?.icon_name }} {{ action?.class }}"
														></i
													></span>
												</button>
											</ng-container>
											<!-- <ng-container
												*ngIf="action.type == 'icon' && action.action_name == 'UI.k_history'"
											>
												<button
													type="button"
													class="btn btn-sm btn-icon btn-outline-tertiary pointer mr-50 {{
														action?.class
													}}"
													(click)="action.callback(element)"
													*appDnsLicenseHandler="{
														module_key: 'SERVICEDESK',
														keys: ['INCIDENT_HISTORY']
													}"
													tooltipClass="fadeInAnimation"
													placement="bottom"
													ngbTooltip="{{ action.tooltip | translate }}"
													id="link__{{ action['key'] }}__{{ element.display_id }}"
													attr.data-testid="link__{{ action['key'] }}__{{
														element.display_id
													}}"
												>
													<span><i class="fa-light {{ action.icon_name }}"></i></span>
												</button>
											</ng-container> -->
										</ng-container>
										<ng-container *ngIf="column.action_list.length > 6">
											<div ngbDropdown container="mat-table">
												<div
													id="dropdownMenuButtonbtn__{{ element.display_id }}"
													ngbDropdownToggle
													class="d-flex"
													attr.data-testid="dropdownMenuButtonbtn__{{ element.display_id }}"
												>
													<button
														class="btn btn-sm btn-icon btn-outline-tertiary pointer mr-50"
														placement="bottom"
														ngbTooltip="{{ 'UI.k_more_action' | translate }}"
														*ngIf="permissions?.edit"
													>
														<i class="fa-light fa-ellipsis-vertical"></i>
													</button>
													<div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
														<!-- <ng-container *ngFor="let action of actionList; let i = index">
															<a
																*ngIf="i == 1"
																class="pointer active"
																ngbDropdownItem
																href="javascript:void(0)"
																id="link__{{ action['action_name'] }}__{{
																	element.display_id
																}}"
															>
																
																<i class="fa-light {{ action.icon_name }} fa-lg"></i
																>&nbsp;&nbsp;
																{{ action.action_name | translate }}
															</a>
															<a
																*ngIf="i != 1"
																class="pointer"
																ngbDropdownItem
																href="javascript:void(0)"
																id="btn__{{ action['action_name'] }}__{{
																	element.display_id
																}}"
															>
																<i class="fa-light {{ action.icon_name }} fa-lg"></i
																>&nbsp;&nbsp;
																{{ action.action_name | translate }}
															</a>
														</ng-container> -->
														<!-- <div class="dropdown-divider"></div> -->
														<a
															class="pointer text-primary"
															ngbDropdownItem
															href="javascript:void(0)"
															(click)="modalOpenVC(modalVC)"
															id="linkEditAction__{{ element.display_id }}"
															attr.data-testid="linkEditAction__{{ element.display_id }}"
															>{{ "UI.k_edit" | translate }}
															{{ "UI.k_actions" | translate }}
															<i class="fa-light fa-chevron-right fa-lg"></i>
														</a>
													</div>
												</div>
											</div>
										</ng-container>
									</div>
								</mat-cell>
							</ng-container>
						</ng-container>
						<!-- aging -->
						<ng-container *ngIf="column.type == 'aging_period'">
							<ng-container matColumnDef="{{ column.label }}" [sticky]="stickyColMap[column.label]">
								<mat-header-cell
									*matHeaderCellDef
									class="table-column-242"
									[ngClass]="stickyClassMap[column.label]"
								>
									<span>{{ column.label | translate | uppercase }}</span>
									<span
										*ngIf="column.sortable != false"
										(click)="sortAsc(column)"
										class="sort_up pointer"
										id="btnSortAsc__{{ column.label }}"
										attr.data-testid="btnSortAsc__{{ column.label }}"
									>
										<i class="fa-light fa-chevron-up fa-xs"></i>
									</span>
									<span
										*ngIf="column.sortable != false"
										(click)="sortDesc(column)"
										class="sort_down pointer"
										id="btnSortDesc__{{ column.label }}"
										attr.data-testid="btnSortDesc__{{ column.label }}"
									>
										<i class="fa-light fa-chevron-down fa-xs pl-25"></i>
									</span>
								</mat-header-cell>
								<mat-cell
									*matCellDef="let element"
									class="table-column-242"
									[ngClass]="stickyClassMap[column.label]"
								>
									<div
										class="table_content_text"
										(click)="show_sla_info_view(element)"
										id="divShowSLA"
										[attr.data-testid]="'divShowSLA'"
									>

										<app-dns-aging-timer
										[data] = "element"
										[key]="column?.key"
										[subkey]="column?.subkey"
										[id]="column?.id"
										[data]="element"
										[rowindex]="i"
											
										>
										</app-dns-aging-timer>
									</div>
								</mat-cell>
							</ng-container>
						</ng-container>
						<ng-container *ngIf="column.key === 'organization_name'">
							<ng-container
								matColumnDef="{{ column.label }}"
								[sticky]="column.freezed === true ? true : false"
							>
								<mat-header-cell *matHeaderCellDef class="table-column-300">
									<span>{{ column.label | translate | uppercase }} </span>
									<span
										*ngIf="column.sortable != false"
										(click)="sortAsc(column)"
										class="sort_up pointer"
										id="btnSortAsc__{{ column.label }}"
										attr.data-testid="btnSortAsc__{{ column.label }}"
									>
										<i class="fa-light fa-chevron-up fa-xs"></i>
									</span>
									<span
										*ngIf="column.sortable != false"
										(click)="sortDesc(column)"
										class="sort_down pointer"
										id="btnSortDesc__{{ column.label }}"
										attr.data-testid="btnSortDesc__{{ column.label }}"
									>
										<i class="fa-light fa-chevron-down fa-xs pl-25"></i>
									</span>
								</mat-header-cell>
								<mat-cell *matCellDef="let element; let i = index" class="table-column-300">
									<ng-container>
										<div class="{{ column['class'] }} table_content_text w-100">
											<ng-container
												*ngIf="
													!editData[
														(column.sub_key ? column.sub_key + column.key : column.key) +
															'editable' +
															element.incident_id
													]
												"
											>
												<!-- <ng-container *ngIf="column.key === 'assignee' && !column.type">
											<app-user-picker  [customClass]="'right'" [inputConfig]="{isLabelHide: true,userInput: this.userInput,id:'user'+element.incident_id}"></app-user-picker>
										</ng-container> -->

												<div
													(dblclick)="
														doEdit(
															(column.sub_key
																? column.sub_key + column.key
																: column.key) +
																'editable' +
																element.incident_id,
															column,
															element.incident_id,
															element
														)
													"
													(click)="
														singleClickEvent(
															(column.sub_key
																? column.sub_key + column.key
																: column.key) +
																'editable' +
																element.incident_id,
															column,
															element.incident_id,
															element
														)
													"
													class="text-break incident-title-truncate"
													id="txt__{{ column.key }}__{{ element.display_id }}"
													attr.data-testid="txt__{{ column.key }}__{{ element.display_id }}"
													placement="bottom"
													ngbTooltip="{{
														column.sub_key
															? element[column.sub_key][column.key]?.customer_name
															: element[column.key]
													}}"
												>
													<span
														*ngIf="
															column.key !== 'organization_name'
														"
													>
														{{
															column.sub_key
																? element[column.sub_key][column.key]
																: element[column.key]
														}}
													</span>
													<ng-container *ngIf="column.key === 'organization_name'">
														<div class="d-flex align-items-center justify-content-start">
															<div class="avatar avatar-sm mr-75">
																<div class="avatar-content">
																	<span
																		*ngIf="
																			!element?.requester?.organization_name?.logo_or_icon_path &&
																			element?.requester?.organization_name?.customer_name
																		"
																		class="avatar-content text-dark"
																		[ngStyle]="{
																			'background-color':
																				element?.requester_avatar_color
																					? element?.requester_avatar_color
																					: defaultAvatarColor
																		}"
																	>
																		{{
																			element?.requester?.organization_name?.customer_name
																				| initials
																				| slice: 0:2
																		}}
																	</span>
																	<span
																		class="avatar-content"
																		*ngIf="element?.requester?.organization_name?.logo_or_icon_path"
																	>
																		<img
																			[src]="element?.requester?.organization_name?.logo_or_icon_path"
																			alt="avatar"
																		/>
																	</span>
																</div>
																<div class="d-grid">
																	<span
																		class="title-color fw-500 sm-font ml-50"
																	>
																		{{ element?.requester?.organization_name?.customer_name }}
																	</span>
																</div>
															</div>
														</div>
													</ng-container>
												</div>
											</ng-container>
											<ng-container
												*ngIf="
													editData[
														(column.sub_key ? column.sub_key + column.key : column.key) +
															'editable' +
															element.incident_id
													] && !editData?.isLoadUserPicker
												"
											>
												<input
													autofocus
													class="form-control form-control-sm"
													(blur)="
														updateInlineData(
															$event,
															column.key,
															column.sub_key,
															element,
															column
														)
													"
													(keyup)="
														onEnterInlineEdit(
															$event,
															column.key,
															column.sub_key,
															element,
															(column.sub_key
																? column.sub_key + column.key
																: column.key) +
																'editable' +
																element.incident_id,
															column
														)
													"
													type="text"
													id="txt__{{ column.key }}__{{ element.display_id }}"
													[value]="
														column.sub_key
															? element[column.sub_key][column.key]
															: element[column.key]
													"
												/>
											</ng-container>
										</div>
									</ng-container>
								</mat-cell>
							</ng-container>
						</ng-container>
					</ng-container>
					<mat-header-row class="datatable-header" *matHeaderRowDef="displayedColumns"> </mat-header-row>
					<mat-row
						*matRowDef="let row; columns: displayedColumns"
						[ngClass]="{ highlight: row.incident_id == selectedRowIndex }"
						(click)="highlight(row, $event)"
						id="row__{{ row.display_id }}"
						attr.data-testid="row__{{ row.display_id }}"
					>
					</mat-row>
				</table>
			</ng-scrollbar>
		</div>
		<div class="card-pagination" *ngIf="rowCount > 0">
			<div class="pagination-inner">
				<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl-0 pr-0">
					<dns-pagination
						[totalCount]="rowCount"
						[currentCount]="currentCount"
						[maxPageLimit]="maxPageLimit"
						(setPage)="paginate($event)"
						(setLimit)="setLimitGrid($event)"
						[page]="page"
						[pageSize]="size"
					></dns-pagination>
				</div>
			</div>
		</div>

		<ng-template #modalVC let-modal>
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalCenterTitle">
					{{ "UI.k_edit" | translate }} {{ "UI.k_actions" | translate }}
				</h5>
				<span
					id="btnClose"
					[attr.data-testid]="'btnClose'"
					name="btnClose"
					data-dismiss="modal"
					aria-label="Close"
					(click)="closePopup()"
				>
					<i class="fa-light fa-xmark mb-0 mr-sm-25 mr-0 pull-right"></i>
				</span>
			</div>
			<span class="modal_sub_heading font-small-2 ml-2">
				{{ "UI.k_edit_act_sub_heading" | translate }}
			</span>
			<div class="modal-body pb-3" tabindex="0" ngbAutofocus>
				<div class="row">
					<div class="col-md-5 avail_actions">
						<span class="col_heading">
							{{ "UI.k_available" | translate | uppercase }}
							{{ "UI.k_actions" | translate | uppercase }}</span
						><br />
						<div *ngFor="let action of actionList; let i = index">
							<div
								class="row on_hover"
								id="col__{{ action.action_name.replace('UI.', '') }}"
								[dndDraggable]="action"
								[dndEffectAllowed]="'copy'"
								(dndEnd)="onDragEnd($event)"
								attr.data-testid="col__{{ action.action_name.replace('UI.', '') }}"
							>
								<span class="col-4 col_count font-small-2">
									{{ "UI.k_action" | translate }} {{ i + 1 }}</span
								>
								<span class="col-8 col_names">{{ action.action_name | translate | titlecase }}</span>
							</div>
						</div>
					</div>
					<div class="col-md-7">
						<span class="margin_sel_col_heading col_heading"
							>{{ "UI.k_selected" | translate | uppercase }}
							{{ "UI.k_actions" | translate | uppercase }}</span
						>
						<section
							dndDropzone
							(dndDragover)="onDragover($event)"
							(dndDrop)="onDrop($event, selActionList, $event.event.target, 'action')"
							class="dndDropArea"
							(dndEnd)="onDragEnd($event)"
							[attr.data-testid]="'sel_DropAction'"
						>
							<div
								class="on_hover"
								*ngFor="let sel_action of selActionList; let i = index"
								(dndStart)="onDragStart($event)"
								(dndCanceled)="onDragCanceled($event)"
								(dndMoved)="onDragged(sel_action, selectedCols, 'move')"
								(dndDragover)="onDragover($event)"
								(dndEnd)="onDragEnd($event)"
								[dndEffectAllowed]="'move'"
								[dndDraggable]="sel_action"
								id="col__{{ sel_action.action_name.replace('UI.', '') }}"
								attr.data-testid="col__{{ sel_action.action_name.replace('UI.', '') }}"
							>
								<div class="row">
									<span class="col-3 col_count font-small-2">
										{{ "UI.k_action" | translate }} {{ i + 1 }}</span
									>
									<span class="col-3 col_names">{{
										sel_action.action_name | translate | titlecase
									}}</span>
									<a
										*ngIf="i > 1"
										class="col-2"
										(click)="removeSelAction(i)"
										id="linkRemove__{{ sel_action.action_name.replace('UI.', '') }}"
										attr.data-testid="linkRemove__{{ sel_action.action_name.replace('UI.', '') }}"
									>
										<i class="fa-light fa-circle-xmark"></i>
									</a>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button
					type="button"
					(click)="saveSelActions(); modal.close('Accept click')"
					class="btn btn-primary"
					id="btnSaveAtn"
					[attr.data-testid]="'btnSaveAction'"
					name="saveAtn"
				>
					{{ "UI.k_save" | translate }}
				</button>
			</div>
		</ng-template>
		<div class="slideout-content default-slideout">
			<core-sidebar
				class="modal modal-slide-in sidebar-todo-modal fade"
				name="resolve-incident"
				sidebarSize="medium"
				overlayClass="modal-backdrop"
			>
				<div *ngIf="incident_data != undefined" class="modal-dialog sidebar-md">
					<div class="modal-content add-new-user pt-0">
						<div class="modal-header">
							<h5 class="modal-title" id="exampleModalLabel">
								{{ resolve_title }}
							</h5>
							<div class="d-flex">
								<button
									type="button"
									id="btnResolveClose"
									[attr.data-testid]="'btnResolveClose'"
									name="btnResolveClose"
									class="btn btn-flat-secondary btn-icon"
									data-dismiss="modal"
									aria-label="Close"
									(click)="toggleSidebar('resolve-incident', resolvedIncidentForm)"
								>
									<i class="fa-light fa-xmark"></i>
								</button>
							</div>
						</div>
						<form #resolvedIncidentForm="ngForm" class="modal-body">
							<div class="flex-auto-height">
								<ng-scrollbar class="custom-scrollbar section-scrollbar">
									<div class="modal-body-inner p-1">
										<div class="card border border-rounded">
											<div class="summary-info p-1">
												<div class="d-flex justify-content-between align-items-center">
													<div class="d-flex justify-content-start align-items-center">
														<div>
															<span class="fw-500 reg-font primary-color">{{
																this.incident_data?.display_id
															}}</span>
														</div>
														<div
															class="rounded icon-class mx-50"
															placement="right"
															tooltipClass="fadeInAnimation"
															ngbTooltip="{{
																incident_data?.basic_info?.incident_source?.name
															}}"
														>
															<div class="resolve-card-config" id="btnGlobe">
																<i
																	class="fa-light fa-{{
																		sourceIconMap?.[
																			incident_data?.basic_info?.incident_source
																				?.id
																		]
																	}}"
																></i>
															</div>
														</div>
														<div
															*ngIf="incident_data?.is_parent_incident"
															class="rounded"
															id="btnShare"
														>
															<div class="resolve-card-config">
																<i class="fa-light fa-share-nodes"></i>
															</div>
														</div>
													</div>

													<div
														class="mx-50"
														*ngIf="this.incident_data?.basic_info?.priority?.name"
													>
														<!-- <div *ngIf="this.incident_data?.basic_info?.priority?.name"> -->
														<div
															class="badge badge-light-{{
																incident_data?.basic_info?.priority?.color
															}}"
															(click)="
																showSummaryCard(
																	this.incident_data?.basic_info?.priority,
																	incident_data?.basic_info?.priority?.color,
																	this.incident_data.incident_id
																)
															"
															[hidden]="this.summaryInput['isLoadSummaryCard']"
														>
															<!-- <span class="sm-font fw-500">Priority:</span> -->
															{{
																this.incident_data?.basic_info?.priority?.display_key
																	| translate
															}}
														</div>
														<app-summary-card
															[summaryInput]="this.summaryInput"
															*ngIf="
																this.summaryInput &&
																this.summaryInput['isLoadSummaryCard']
															"
														>
														</app-summary-card>
													</div>
												</div>
												<div class="d-flex justify-content-between align-items-center">
													<div
														class="d-flex col-gap-2 justify-content-start align-items-center"
													>
														<ng-container *ngIf="incident_data?.impact_service">
															<div class="d-flex col-gap-50">
																<span class="sm-font">
																	{{ "UI.k_service" | translate }} :
																</span>
																<span
																	class="sm-font title-color fw-500"
																	title="{{
																		incident_data?.basic_info?.impact_service_name
																	}}"
																>
																	{{
																		incident_data?.basic_info?.impact_service_name
																			| slice: 0:19
																	}}{{
																		incident_data?.basic_info?.impact_service_name
																			?.length > 19
																			? ".."
																			: ""
																	}}</span
																>
															</div>
														</ng-container>
														<ng-container
															*ngIf="incident_data?.asset_id && incident_data?.cmdb_id"
														>
															<div class="d-flex col-gap-50">
																<span class="sm-font"
																	>{{ "UI.k_asset_id" | translate }} :
																</span>
																<span
																	*ngIf="
																		asset_permissions?.view &&
																		!incident_data?.is_asset_disabled
																	"
																>
																	<a
																		href="/it-asset/asset/{{
																			incident_data?.cmdb_id
																		}}"
																		target="_blank"
																		class="sm-font primary-color fw-100"
																		title="{{ incident_data?.asset_id }}"
																	>
																		{{ incident_data?.asset_id | slice: 0:19
																		}}{{
																			incident_data?.asset_id?.length > 19
																				? ".."
																				: ""
																		}}</a
																	>
																</span>
																<span
																	*ngIf="
																		!asset_permissions?.view ||
																		incident_data?.is_asset_disabled
																	"
																	class="sm-font primary-color fw-500"
																	title="{{ inci?.asset_id }}"
																>
																	{{ incident_data?.asset_id | slice: 0:19
																	}}{{
																		incident_data?.asset_id?.length > 19 ? ".." : ""
																	}}
																</span>
															</div>
														</ng-container>
													</div>
												</div>

												<div class="my-50 d-flex justify-content-start align-items-center">
													<div class="avatar avatar-xs mr-50">
														<!-- <ng-container > -->
														<span
															class="avatar-content"
															*ngIf="!incident_data?.requester_profile"
															[ngStyle]="{
																'background-color':
																	incident_data?.requester_avatar_color != undefined
																		? incident_data?.requester_avatar_color
																		: '#f59518'
															}"
															>{{
																this.incident_data?.requester?.full_name
																	| initials
																	| slice: 0:2
															}}</span
														>
														<!-- </ng-container> -->
														<ng-container *ngIf="incident_data?.requester_profile">
															<img
																class="avatar-content"
																[src]="incident_data?.requester_profile"
																alt=""
															/>
														</ng-container>
													</div>

													<span
														class="sm-font fw-500 title-color summary-ellipse"
														ngbTooltip="{{
															incident_data?.basic_info?.summary | slice: 0:200
														}}"
														placement="bottom"
														>{{ incident_data?.basic_info?.summary | slice: 0:150 }}
														{{
															incident_data?.basic_info?.summary.length > 150 ? ".." : ""
														}}
													</span>
												</div>
											</div>
										</div>
										<div *ngIf="incident_data?.state_id == 4">
											<div class="form-group col-xs-7 col-md-7 col-xl-7 col-lg-7 p-0">
												<!-- <label class="sm-font" for="selStatus">{{
                                        "UI.k_status" | translate
                                    }}</label> -->
												<app-status-picker
													[inputConfig]="{
														isTextMode: true,
														stateOptionsMap: options?.state_id_map
													}"
													[options]="resolved_status"
													(setStatus)="onStatusChange($event)"
													[value]="incident_data?.resolved_status_id"
												></app-status-picker>
											</div>

											<div class="form-group col-xs-6 col-md-6 col-xl-6 col-lg-6 p-0">
												<label for="resolution_date" class="sm-font"
													>{{ "UI.k_resolution" | translate }} Date<span
														class="required-field"
														>&nbsp;*</span
													></label
												>
												<div>
													<ng2-flatpickr
														[config]="dateTimeOptions"
														name="smartGridResolutionDate"
														id="smartGridResolutionDate"
														[setDate]="
															incident_data?.inci_resolution_time
																? incident_data?.inci_resolution_time
																: (incident_data['inci_resolution_time'] =
																		dateTimeOptions?.defaultDate)
														"
														[(ngModel)]="incident_data.inci_resolution_time"
														placeholder="{{ 'UI.k_inci_resolution_time' | translate }}"
														class="my-class"
														#TDInciSmartResolveDateRef="ngModel"
														required
													>
													</ng2-flatpickr>
													<div
														*ngIf="
															resolvedIncidentForm.submitted &&
															TDInciSmartResolveDateRef.invalid
														"
														class="invalid-form"
													>
														<small
															class="form-text text-danger xs-font"
															*ngIf="TDInciSmartResolveDateRef.errors.required"
															>{{ "UI.k_field_required" | translate }}
														</small>
													</div>
												</div>
											</div>
											<div class="col-md-12 p-0">
												<div class="form-group">
													<label for="basic-search" class="sm-font">
														{{ "UI.k_resolution" | translate
														}}<span class="required-field">&nbsp;*</span>
													</label>
													<div class="card p-0 pb-50">
														<!-- <editor 
															[disabled]="false"
															[(ngModel)]="incident_data.resolution_comment"
															id="articleNote"
															name="articleNote"
															[init]="{ }"
															initialValue=""
															branding="false"
															[inline]="false"
															(onSelectionChange)="handleEvent($event)"
															(onContentChanged)="getEnrichTextData($event)"
															#TDSmartGridResoltionComment
															[class.error]="
																TDSmartGridResoltionComment?.errors &&
																resolvedIncidentForm.submitted
															"
														></editor>  -->
														<quill-editor
															[(ngModel)]="incident_data.resolution_comment"
															#TDSmartGridResoltionComment
															id="txtAreaSmartGridResolution"
															name="txtAreaSmartGridResolution"
															[required]="true"
															[trimOnValidation]="true"
															[appQuillValidator]="TDSmartGridResoltionComment"
															[appQuillForm]="resolvedIncidentForm"
															[minLength]="3"
															[class.error]="
																TDSmartGridResoltionComment?.errors &&
																resolvedIncidentForm.submitted
															"
															placeholder="{{ 'UI.k_insert_text_here' | translate }}..."
														>
															<div quill-editor-toolbar class="px-0 py-50">
																<div class="p-0">
																	<span class="ql-formats pb-50">
																		<button
																			class="ql-bold"
																			title="{{ 'UI.k_bold' | translate }}"
																		></button>
																		<button
																			class="ql-italic"
																			title="{{ 'UI.k_italic' | translate }}"
																		></button>
																		<button
																			class="ql-underline"
																			title="{{ 'UI.k_underline' | translate }}"
																		></button>
																	</span>
																	<span class="ql-formats">
																		<select
																			class="ql-align"
																			title="{{ 'UI.k_alignment' | translate }}"
																		>
																			<option selected></option>
																			<option value="center"></option>
																			<option value="right"></option>
																			<option value="justify"></option>
																		</select>
																	</span>
																	<span class="ql-formats">
																		<button
																			class="ql-list"
																			value="ordered"
																			type="button"
																		></button>
																	</span>
																	<span class="ql-formats">
																		<button
																			class="ql-link"
																			value="ordered"
																			type="button"
																		></button>
																		<button
																			class="ql-image"
																			value="ordered"
																			type="button"
																		></button>
																		<button
																			class="ql-video"
																			value="ordered"
																			type="button"
																		></button>
																	</span>
																	<span class="ql-formats">
																		<button
																			class="ql-formula"
																			value="ordered"
																			type="button"
																		></button>
																		<button
																			class="ql-code-block"
																			value="ordered"
																			type="button"
																		></button>
																	</span>
																	<span class="ql-formats">
																		<button
																			class="ql-clean"
																			value="ordered"
																			type="button"
																		></button>
																	</span>
																</div>
															</div>
														</quill-editor>
													</div>
													<div>
														<span
															class="row col-12 error"
															*ngIf="
																resolvedIncidentForm.submitted &&
																TDSmartGridResoltionComment?.errors
															"
															class="invalid-form"
														>
															<small
																*ngIf="
																	TDSmartGridResoltionComment?.errors?.requiredError
																		?.empty
																"
																class="form-text text-danger xs-font"
																>{{ "UI.k_field_required" | translate }}</small
															>
															<small
																*ngIf="
																	TDSmartGridResoltionComment?.errors?.minLengthError
																"
																class="form-text text-danger xs-font"
																>{{ "Error.err_min_3_character" | translate }}</small
															>
														</span>
													</div>
												</div>
												<app-dns-custom-field
													[formRef]="resolvedIncidentForm"
													placeholder="resolution"
													[data]="incident_data?.custom_field_data"
													[config]="inci_extra_info?.custom_field_config"
												></app-dns-custom-field>
											</div>
										</div>
										<div class="row p-0" *ngIf="incident_data?.state_id == 5">
											<div class="form-group col-xs-6 col-md-6 col-xl-6 col-lg-6">
												<app-status-picker
													[inputConfig]="{
														isTextMode: true,
														stateOptionsMap: options?.state_id_map
													}"
													[options]="resolved_status"
													(setStatus)="onStatusChange($event)"
													[value]="incident_data.basic_info?.status?.id"
												></app-status-picker>
											</div>

											<div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
												<div class="form-group">
													<label for="txtInciCloserInfo"
														>{{ "UI.k_close_incident" | translate }}
														<span class="required-field">&nbsp;*</span>
													</label>
													<div>
														<quill-editor
															[(ngModel)]="incident_data.close_info.closure_note"
															id="smartQuillIncidentClosure"
															name="smartQuillIncidentClosure"
															(onContentChanged)="getClosureNote($event)"
															(onEditorCreated)="editorCreated($event)"
															[trimOnValidation]="true"
															#TDInciClosureNoteRef
															[appQuillValidator]="TDInciClosureNoteRef"
															[appQuillForm]="resolvedIncidentForm"
															[minLength]="3"
															[required]="true"
															[class.error]="
																TDInciClosureNoteRef?.errors &&
																resolvedIncidentForm.submitted
															"
															placeholder="{{ 'UI.k_closure_note' | translate }}"
														>
															<div id="quill-toolbar" quill-editor-toolbar>
																<div class="">
																	<span class="ql-formats">
																		<button
																			class="ql-bold"
																			title="{{ 'UI.k_bold' | translate }}"
																		></button>
																		<button
																			class="ql-italic"
																			title="{{ 'UI.k_italic' | translate }}"
																		></button>
																		<button
																			class="ql-underline"
																			title="{{ 'UI.k_underline' | translate }}"
																		></button>
																	</span>
																	<span class="ql-formats">
																		<select
																			class="ql-align"
																			title="{{ 'UI.k_alignment' | translate }}"
																		>
																			<option selected></option>
																			<option value="center"></option>
																			<option value="right"></option>
																			<option value="justify"></option>
																		</select>
																	</span>
																	<span class="ql-formats">
																		<button
																			class="ql-list"
																			value="ordered"
																			type="button"
																		></button>
																	</span>
																	<span class="ql-formats">
																		<button
																			class="ql-link"
																			value="ordered"
																			type="button"
																		></button>
																		<button
																			class="ql-image"
																			value="ordered"
																			type="button"
																		></button>
																		<button
																			class="ql-video"
																			value="ordered"
																			type="button"
																		></button>
																	</span>
																	<span class="ql-formats">
																		<button
																			class="ql-formula"
																			value="ordered"
																			type="button"
																		></button>
																		<button
																			class="ql-code-block"
																			value="ordered"
																			type="button"
																		></button>
																	</span>
																	<span class="ql-formats">
																		<button
																			class="ql-clean"
																			value="ordered"
																			type="button"
																		></button>
																	</span>
																	<span class="ql-formats">
																		<button
																			id="insert-table"
																			type="button"
																			(click)="addNewtable($event)"
																			[attr.data-testid]="'insert-table'"
																		>
																			Table
																		</button>
																	</span>
																</div>
															</div>
														</quill-editor>
													</div>

													<div
														class="error"
														*ngIf="
															resolvedIncidentForm.submitted &&
															TDInciClosureNoteRef?.errors
														"
													>
														<small
															*ngIf="TDInciClosureNoteRef?.errors?.requiredError?.empty"
														>
															{{ "UI.k_field_required" | translate }}
														</small>
														<small *ngIf="TDInciClosureNoteRef?.errors?.minLengthError">
															{{ "Error.err_closure_note_min" | translate }}
														</small>
													</div>
												</div>
											</div>
											<div class="col-xs-12 col-md-6 col-xl-6 col-lg-6">
												<div class="form-group">
													<label for="txtInciClosedBy"
														>{{ "UI.k_closedby" | translate }}
													</label>
													<ng-select
														[items]="options.users"
														[(ngModel)]="incident_data.close_info.closed_by"
														bindLabel="full_name"
														bindValue="profile_id"
														name="txtInciClosedBy"
														id="txtInciClosedBy"
														placeholder="{{ 'UI.k_select_users' | translate }}"
														[hideSelected]="true"
													>
														<ng-template
															ng-option-tmp
															let-item="item"
															let-index="index"
															let-search="searchTerm"
														>
															<span class="d-flex">
																<div class="avatar avatar-sm mr-1 ml-0">
																	<span
																		class="avatar-content"
																		*ngIf="!item.profile_image"
																		[ngStyle]="{
																			'background-color': item.avatar_color
																				? item.avatar_color
																				: '#f59518'
																		}"
																	>
																		{{ item.full_name | initials }}
																	</span>
																	<span
																		class="avatar-content"
																		*ngIf="item?.profile_image"
																	>
																		<img
																			class="rounded-circle"
																			src="{{ item?.profile_image }}"
																			alt="User"
																		/>
																	</span>
																</div>
																<div class="cell-line-height">
																	<p
																		class="font-medium-1 font-weight-bold line-height-1 mb-25"
																	>
																		{{ item?.full_name | slice: 0:28 }}
																		{{ item?.full_name?.length > 28 ? ".." : "" }}
																	</p>
																	<span class="text-muted font-small-2">
																		{{ item?.email | slice: 0:28 }}
																		{{ item?.email?.length > 28 ? ".." : "" }}
																	</span>
																</div>
															</span>
														</ng-template>
													</ng-select>
												</div>
											</div>

											<div class="col-xs-12 col-md-6 col-xl-6 col-lg-6">
												<div class="form-group">
													<label for="txtInciCloseType"
														>{{ "UI.k_close_type" | translate }}
													</label>
													<ng-select
														[items]="options.close_type"
														[hideSelected]="true"
														bindLabel="name"
														bindValue="id"
														(change)="
															updateFieldName(
																incident_data.basic_info.closed_type.id,
																'id',
																'closed_type',
																incident_data.basic_info.closed_type.name,
																'name'
															)
														"
														[(ngModel)]="incident_data.close_info.closed_type.id"
														name="txtInciCloseType"
														id="txtInciCloseType"
														placeholder="{{ 'UI.k_sel_close_type' | translate }}"
													>
													</ng-select>
												</div>
											</div>
											<div class="col-xs-12 col-md-6 col-xl-6 col-lg-6">
												<div class="form-group">
													<label for="txtIncidentActlClsrDate"
														>{{ "UI.k_actual_closure_date" | translate }}
													</label>
													<ng2-flatpickr
														[config]="dateTimeOptions"
														name="txtIncidentActlClsrDate"
														[setDate]="
															incident_data?.actual_closure_date
																? incident_data?.actual_closure_date
																: (incident_data['actual_closure_date'] =
																		dateTimeOptions?.defaultDate)
														"
														id="txtIncidentActlClsrDate"
														placeholder="{{ 'UI.k_sel_date' | translate }}"
														[(ngModel)]="incident_data.actual_closure_date"
													></ng2-flatpickr>
												</div>
											</div>
											<div class="col-xs-12 col-md-6 col-xl-6 col-lg-6">
												<div class="form-group">
													<label for="txtInciclsrAgrdTime"
														>{{ "UI.k_agreed_closure_date" | translate }}
													</label>
													<ng2-flatpickr
														[config]="agreedDateTimeOptions"
														name="txtInciclsrAgrdTime"
														[setDate]="incident_data.agreed_closure_date"
														placeholder="{{ 'UI.k_sel_date' | translate }}"
														id="txtInciclsrAgrdTime"
														[(ngModel)]="incident_data.agreed_closure_date"
													></ng2-flatpickr>
												</div>
											</div>

											<div class="col-12">
												<app-dns-custom-field
													[formRef]="resolvedIncidentForm"
													placeholder="closure"
													[data]="incident_data?.custom_field_data"
													[config]="inci_extra_info?.custom_field_config"
												></app-dns-custom-field>
											</div>
										</div>
									</div>
								</ng-scrollbar>
								<div class="modal-btn-group flex-initial border-top">
									<div class="d-flex justify-content-end stepper-btn flex-initial">
										<button
											class="btn btn-sm btn-primary btn-next ml-1"
											id="btnComplete"
											rippleEffect
											type="submit"
											(click)="onResolveSubmit(resolvedIncidentForm, incident_data)"
											[attr.data-testid]="'btnComplete'"
										>
											<span class="align-middle d-sm-inline-block d-none">
												{{ resolve_btn_text }}</span
											>
										</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</core-sidebar>
		</div>
	</div>
	<ng-template #AppovalChangeComp let-modal>
		<div class="modal-header">
			<h4 class="modal-title" id="myModalLabel1">
				{{ "UI.k_approval" | translate }}
			</h4>
			<br />
			<span
				id="spAssignClose"
				name="spAssignClose"
				data-dismiss="modal"
				aria-label="Close"
				(click)="closeApprovalPopup()"
				[attr.data-testid]="'spAssignClose'"
			>
				<i class="fa-light fa-xmark mb-0 mr-sm-25 mr-0 pull-right"></i>
			</span>
		</div>
		<div class="modal-body" tabindex="0" ngbAutofocus>
			<form #currentApprovalForm="ngForm">
				<div class="row mb-2">
					<div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
						<div class="form-group">
							<label for="txtChnSelAssignGroup"
								>{{ "UI.k_sel_team" | translate }}
								<span class="required-field">&nbsp;*</span>
							</label>
							<ng-select
								[items]="dropdown.teams"
								[hideSelected]="true"
								bindLabel="name"
								bindValue="team_id"
								[(ngModel)]="approval_team_id"
								name="txtChnSelAssignGroup"
								id="txtChnSelAssignGroup"
								placeholder="{{ 'UI.k_select' | translate }} {{ 'UI.k_group' | translate }}"
								#TDSelAppGroup="ngModel"
								[closeOnSelect]="true"
								[class.error]="!TDSelAppGroup.valid && currentApprovalForm.submitted"
								required
							>
								<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
									<span
										class="d-flex"
										(click)="selfAssignSel = false"
										*ngIf="item.team_id != 'self_assign'"
									>
										<div
											class="avatar mr-1 ml-0"
											[ngStyle]="{
												'background-color': item.avatar_color ? item.avatar_color : '#f59518'
											}"
											style="align-content: center; width: 32px; height: 32px"
										>
											<div
												class="avatar-content"
												[ngStyle]="{
													'background-color': stringToColor(item.name)
												}"
											>
												{{ item.name | initials | slice: 0:2 }}
											</div>
										</div>
										<div class="cell-line-height">
											<p class="lg-font fw-500 line-height-1 mb-0">
												{{ item.name | slice: 0:20 }}
												{{ item?.name?.length > 20 ? ".." : "" }}
											</p>
											<span class="text-muted xs-font"> {{ item.group_type }}</span>
										</div>
									</span>
									<div class="cursor-pointer" *ngIf="item.team_id == 'self_assign'">
										<div class="media" (click)="assignToMyself()">
											<div class="avatar mr-1">
												<ng-container *ngIf="currentUser?.profile_image">
													<img
														class="avatar-content"
														class=""
														[src]="currentUser?.profile_image"
														width="32"
														height="32"
													/>
												</ng-container>
												<ng-container id="divAssignee" *ngIf="!currentUser?.profile_image">
													<span
														[ngStyle]="{
															'background-color':
																currentUser?.profile?.avatar_color != undefined
																	? currentUser?.profile?.avatar_color
																	: '#f59518',
															width: 32,
															height: 32
														}"
														class="avatar-content"
													>
														{{
															this.currentUser.first_name +
																"" +
																this.currentUser.last_name
																| initials
																| slice: 0:2
														}}
													</span>
												</ng-container>
											</div>
											<div class="media-body">
												<h5 class="mb-0">
													{{ "UI.k_self_assign" | translate }}
												</h5>
												<small class="text-muted">{{ this.currentUser.email }}</small>
											</div>
										</div>
									</div>
								</ng-template>
							</ng-select>
							<span *ngIf="currentApprovalForm.submitted && TDSelAppGroup.invalid" class="invalid-form">
								<small class="form-text text-danger" *ngIf="TDSelAppGroup.errors.required">{{
									"UI.k_field_required" | translate
								}}</small>
							</span>
						</div>
					</div>
				</div>
				<div class="col-12 pl-0">
					<app-dns-custom-field
						placeholder="assignment"
						[formRef]="currentApprovalForm"
						[data]="custom_field_data"
						[config]="custom_field_config"
					></app-dns-custom-field>
				</div>
				<div class="d-flex pull-right pb-half">
					<div class="mr-1">
						<button
							(click)="saveApprovalChangeData(currentApprovalForm)"
							type="submit"
							[disabled]="apprChnDisabled"
							class="btn btn-primary"
							rippleEffect
							name="btnAssignSave"
							id="btnAssignSave"
							[attr.data-testid]="'btnAssignSave'"
						>
							<i class="fa-light fa-floppy-disk"></i>&nbsp;&nbsp;{{ "UI.k_save_changes" | translate }}
						</button>
					</div>
				</div>
			</form>
		</div>
	</ng-template>
	<ng-template #appovalChangeComp let-modal>
		<app-approval-config
			*ngIf="incident_data"
			[process_data]="incident_data"
			[options]="options"
			[approval_status]="temp_status"
			[module_id]="10"
			(onApprovalConfigClose)="onApprovalConfigClose($event)"
			(onApprovalConfigSubmit)="onApprovalConfigSubmit($event)"
		>
		</app-approval-config>
	</ng-template>

	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="incident-edit-sidebar"
		overlayClass="modal-backdrop"
	>
		<incident-edit-sidebar
			*ngIf="hideInlineEdit"
			#editSidebar
			[incidentId]="incidentId"
			[incident_data]="incident_data"
			[dropdown]="dropdown"
			[inci_extra_info]="inci_extra_info"
			[currentUserProfileId]="currentUserProfileId"
			(onhideInlineEdit)="onhideInlineEdit($event)"
		>
		</incident-edit-sidebar>
	</core-sidebar>

	<core-sidebar
		class="modal modal-slide-in sidebar-todo-modal fade"
		name="incident-priority-change-incident-smart"
		overlayClass="modal-backdrop"
	>
		<ng-container *ngIf="is_priority_change">
			<app-priority-change
				sidebarName="incident-priority-change-incident-smart"
				[config_data]="incident_data"
				(onPriorityChangeSubmit)="onPriorityChangeSubmit($event)"
				(onPrioritySliderClose)="onPrioritySliderClose($event)"
			>
			</app-priority-change>
		</ng-container>
	</core-sidebar>
</div>
