<div class="full-body-height incident-grid-data p-1">
    <div class="main-content flex-height">
        <div class="main-sec">
            <div class="action-bar">
                <div class="inner-section">
                    <div class="left-section">
                        <div class="action-search">
                            <app-search
                                [showDataTable]="showDataTable"
                                [showRightsection]="showRightsection"
                                [columnOptions]="columnOptions"
                                [savedData]="savedData"
                                (genericFilter)="genericSearchFilter($event)"
                                [module]="module"
                                [filterDetails]="getPartnerList"
                                (getfilterData)="getFilterSearch($event)"
							></app-search>
                        </div>
                    </div>
                    <div class="right-section">
                        <div *ngIf="permissions?.add" class="pull-right align-items-center d-flex p-0 m-0">
                            <button
                                class="btn btn-primary pull-right ml-25"
                                rippleEffect
                                id="btnAddPartner"
                                name="addpartner"
                                (click)="toggleAddPartner('create-partner')"
                                tooltipClass="fadeInAnimation"
                                [ngbTooltip]="'New partner'"
                                placement="left"
                            >
                                <i class="fa-light fa-plus"></i>
                                <span class="d-none d-sm-inline-block">
                                {{ 'UI.k_create_partner' | translate }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="action-section">
                <div class="table-view">
                    <ng-container>
                        <dns-server-grid
                            [rows]="partners"
                            [columns]="columns"
                            [count]="partnerCount"
                            [offset]="pageNumber"
                            (sort)="onSort($event)"
                            [limit]="limit"
                            (setlimit)="setLimit($event)"
                            (editCallback)="onUpdate($event)"
                            (page)="setPage($event)"
                            [isEditable]="true"
                            [permissions]="permissions"
                            class="bootstrap core-bootstrap"
                            [virtualization]="false"
						    [showData]="showData"
                            [emptyData]="empty_data"
                            [filterData]="filterData"
                        >
                        </dns-server-grid>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<core-sidebar
  class="modal modal-slide-in sidebar-todo-modal fade"
  name="create-partner"
  overlayClass="modal-backdrop"
>
  <app-create-partner
        [action]="currentEvent"
		[partnerid]="partnerid"
		(callback)="getPartnerList($event)"
		*ngIf="hideProfile"
        (onHideProfile)="onHideProfile($event)"
  ></app-create-partner>
</core-sidebar>


<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="invite-partneruser"
	overlayClass="modal-backdrop"
>
	<app-invite-partneruser *ngIf="hideInviteProfile"  [partnerid]="partnerid" (onHideInviteProfile)="onHideInviteProfile($event)">
	</app-invite-partneruser>
</core-sidebar>
