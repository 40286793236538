import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { NgForm, NgModel } from "@angular/forms";

@Component({
	selector: "dns-error-validation",
	templateUrl: "./dns-error-validation.component.html",
	styleUrls: ["./dns-error-validation.component.scss"],
})
export class DnsErrorValidationComponent implements OnInit {
	@Input("uiRef") templateRef: NgModel;
	@Input("formRef") formRef: NgForm;
	@Input("validationMsgConfig") validationMessageConfig;

	constructor() {}

	ngOnInit(): void {}
}
