import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { User } from "app/auth/models/user";
import { FlatpickrOptions } from "ng2-flatpickr";
import { elementAt, map } from "rxjs/operators";
// import * as _ from "lodash";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "app/base/auth/services/auth.service";
import { TreeviewItem } from "ngx-treeview";
import { AssetService } from "app/base/asset/services/asset.service";
import { RequestViewService } from "app/servicedesk/request/services/request-view.service";
import { ToastrService } from "ngx-toastr";

import { _deepCopyJson } from "app/common/utils/utils";
import { keyframes } from "@angular/animations";

@Component({
	selector: "app-common-pannel-view",
	templateUrl: "./common-pannel-view.component.html",
	styleUrls: ["./common-pannel-view.component.scss"],
})
export class CommonPannelViewComponent implements OnInit {
	@Input() data: Array<any>;
	@Input() rowCount;
	pageStart: any = 0;
	@Input() size;
	@Input() page;
	@Input() sort = true;
	pageEnd: any;
	@Output() onSetLimit: EventEmitter<any> = new EventEmitter<any>();
	@Output() onInlineEditCallback: EventEmitter<any> = new EventEmitter<any>();
	@Input() options;
	@Output() onSort: EventEmitter<any> = new EventEmitter<any>();
	@Output() onSavePageConfig: EventEmitter<any> = new EventEmitter<any>();
	@Output() onSelectCheckbox: EventEmitter<any> = new EventEmitter<any>();
	@Output() onActionCallback: EventEmitter<any> = new EventEmitter<any>();
	@Output() infoAction: EventEmitter<any> = new EventEmitter<any>();
	@Output() hideAssetSidebar: EventEmitter<any> = new EventEmitter<any>();
	@Output() onCallBack: EventEmitter<any> = new EventEmitter<any>();
	@Input() assetType: string;
	@Input() event: any;
	@Input() state: any;
	@Input() itemID: any;
	@Input() panelCardConfig: any;
	@Input() permissions: any;
	@Input() actionList: any;
	@Input() processPrimaryKey = "request_id";
	@Input() processMoudleName = "request";
	@Input() module_name = "request_process";
	@Input() module_id = "42";
	@ViewChild("appovalChangeComp") appovalChangeComp: ElementRef;
	public defaultAvatarColor = "#f59518";
	public val: string;
	selection: string[];
	public dropDownOptions: any = [];
	public copyOfDropdownOptions: any = [];
	priorites: any = [];
	priorityField: "1";
	public allTableData;
	public allTableData_copy;
	req_extra_info: any = {};
	hideInlineEdit: boolean = false;
	custom_field_config: any;
	public extra_info = {
		selected_assignee_info: {
			profile_image: "",
			full_name: "",
			email: "",
		},
		group_type: "Operation and Technical",
		level: "",
		expertise: "",
		group_name: "",
	};
	@Input() summaryInput: any;
	currentUser: User;
	currentAssignmentInfo: any = {};
	current_extra_info: any = {};
	assignment_data: any = {};
	// public dropdown = {
	// 	expertise : [],
	// 	level : [],
	// 	assignee : [],
	// 	classifications : [],
	// 	state : [],
	// 	status : {},
	// 	urgency : [],
	// 	priority : [],
	// 	severity : [],
	// 	event_type : [],
	// 	type : [],
	// 	impact : [],
	// 	tagList : [],
	// 	tagTypeList : [],
	// 	users : [],
	// 	teams : [],
	// 	assignee_group:{
	// 		self:[],
	// 		group:[]
	// 	},
	// 	requesters :[],
	// 	all_services :[],
	// 	request_source : [],
	// 	groupt_type_list : [],
	// 	close_type : [],
	// 	closure_category_list : [],
	// 	catalogue_list : [],
	// 	current_user_group:[]
	// };
	public addRequest: any = {};
	resolve_title: string;
	// severityInput = [];
	// urgencyInput = [];
	public dateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: true,
		dateFormat: "%b %d, %Y %I:%M %p",
		altFormat: "M d, Y H:i K",
		mode: "single",
		altInputClass: "form-control flat-picker flatpickr-input",
	};

	public agreedDateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime: true,
		dateFormat: "%b %d, %Y %I:%M %p",
		altFormat: "M d, Y H:i K",
		mode: "single",
		altInputClass: "form-control flat-picker flatpickr-input",
	};

	resolved_status = [];
	mail_list = [];
	latest_comment: any;
	loading_comment = false;
	status = "1";
	historydup: any;
	userTZ: any;
	public gridViewRef = false;
	resolve_btn_text: string;
	temp_status: any;
	process_data: any;
	temp_obj: any;

	public editorCreated(event): void {
		// Example on how to add new table to editor
		// this.addNewtable();
	}

	team_id: string;
	selfAssignSel = false;
	quickEdit = false;
	showResln = false;
	public itemObj: any = {};
	showChangeMangerForProcess: Array<any> = ["change_id"];
	showAssigneeForProcess: Array<any> = ["request_id", "problem_id"];
	approval_module_list = ["request_process"];

	/**
	 * Constructor
	 *
	 * @param {NgbModal} _modalService
	 */

	constructor(
		private route: ActivatedRoute,
		private _translateService: TranslateService,
		private _coreSidebar: CoreSidebarService,
		private modalService: NgbModal,
		private _requestViewService: RequestViewService,
		private _router: Router,
		private ngZone: NgZone,
		private _service: AssetService,
		public _authService: AuthService,
		private _toastarService: ToastrService
	) {
		this._authService.currentUser.subscribe((x) => (this.currentUser = x));
		this.dropDownOptions = [
			{ id: "1", full_name: "Harshil Dave", profile_image: "assets/images/asset/image.png" },
			{ id: "2", full_name: "Dhruval Parmar", profile_image: "assets/images/asset/image.png" },
			{ id: "3", full_name: "Muhammad Nusaif", profile_image: "assets/images/asset/image.png" },
			{ id: "4", full_name: "bhupendrarsihn zala", profile_image: "assets/images/asset/image.png" },
		];
		this.priorites = [
			{
				id: "1",
				status: "Medium",
				icon: "fa-chevrons-up mr-25",
				class: "light-info priority",
			},
			{
				id: "2",
				status: "High",
				icon: "fa-chevrons-up mr-25",
				class: "light-danger priority",
			},
			{
				id: "3",
				status: "Low",
				icon: "fa-chevrons-up mr-25",
				class: "light-dark priority",
			},
		];
		// this.priorityField = this.priorites.id == 1
	}

	ngOnInit(): void {
		if (this.rowCount > 0) {
			this.pageStart = this.size * (this.page - 1) + 1;
		}
		this.pageEnd = this.size * this.page;
		if (this.pageEnd > this.rowCount) {
			this.pageEnd = this.rowCount;
		}
		// this.getOptions()
	}

	statusBadgeChange = (eventData, key, row) => {
		let obj = {};
		obj["key"] = key;
		obj["value"] = eventData?.event?.id;
		obj[this.processPrimaryKey] = row?.[this.processPrimaryKey];
		this.onInlineEditCallback?.emit(obj);
	};

	ngOnChanges() {
		this.pageStart = 0;

		if (this.rowCount > 0) {
			this.pageStart = this.size * (this.page - 1) + 1;
		}
		this.pageEnd = this.size * this.page;
		if (this.pageEnd > this.rowCount) {
			this.pageEnd = this.rowCount;
		}
	}

	serviceValueChange = (item, key, element) => {
		if (item.value != element.impact_service) {
			let basic_info = {
				impact_service: item?.value,
				impact_service_name: item?.text,
				service_classification: "",
				service_classification_name: "",
				service_classification_path: "",
				impact_service_tree_path: item?.full_path_text,
			};
			basic_info["catalogue"] = item?.sel_tree_view_data[item?.sel_tree_view_data?.length - 1]["value"];
			basic_info["catalogue_name"] = item?.sel_tree_view_data[item?.sel_tree_view_data?.length - 1]["text"];
			if (item?.sel_tree_view_data && item?.sel_tree_view_data?.length > 2) {
				basic_info["category"] = item?.sel_tree_view_data[1]["value"];
				basic_info["category_name"] = item?.sel_tree_view_data[1]["text"];
			} else {
				basic_info["category"] = "";
				basic_info["category_name"] = "";
			}
			let obj = {
				value: basic_info,
				key: "basic_info",
				col_key: key,
				element: element,
			};
			obj[this.processPrimaryKey] = element?.[this.processPrimaryKey];
			this.onInlineEditCallback?.emit(obj);
		}
	};

	stringToColor(reqName) {
		if (!reqName) {
			reqName = "anaymous";
		}

		let hash = 0;
		let i;

		/* eslint-disable no-bitwise */
		for (i = 0; i < reqName.length; i += 1) {
			hash = reqName.charCodeAt(i) + ((hash << 5) - hash);
		}

		let colour = "#";

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff;
			colour += `00${value.toString(16)}`.substr(-2);
		}
		/* eslint-enable no-bitwise */

		return colour;
	}

	paginate(page) {
		page = page?.page ? parseInt(page.page) : page;
		this.pageStart = this.size * (page - 1) + 1;
		this.pageEnd = this.size * page;
		let params = { page: page, limit: this.size };

		this.onSetLimit.emit(params);
	}

	setLimitGrid($event) {
		let page = 1;
		this.size = $event;
		if ($event == "all") {
			this.size = this.rowCount;
		}
		this.pageStart = this.size * (page - 1) + 1;
		this.pageEnd = this.size * page;
		let params = { page: page, limit: this.size };
		this.onSetLimit.emit(params);
	}

	onStatusChange = (data) => {
		if (data?.state_id == 4) {
			this.allTableData["resolved_status_id"] = data?.id;
		}

		this.allTableData["status_id"] = data?.id;
		this.allTableData.basic_info["status"] = data;
		this.allTableData["state_id"] = data?.state_id;
		this.allTableData.basic_info["state"] = this.options?.state_id_map[data?.state_id];
	};

	statusEvent(args, key, row) {
		let obj = { value: args, key: key };
		obj[this.processPrimaryKey] = row?.[this.processPrimaryKey];
		obj["mulitple_key"] = true;
		obj["multiple_ke_vl"] = { state_id: args?.state_id };
		if (
			row?.state_id != args?.state_id ||
			args?.state_id == this.options?.state_key_id_map?.Resolved ||
			args?.state_id == this.options?.state_key_id_map?.Close ||
			(args?.state_id == this.options?.state_key_id_map?.Approval &&
				this.approval_module_list.includes(this.module_name))
		) {
			if (args?.state_id == this.options?.state_key_id_map?.Resolved) {
				let comp = this.actionList?.filter((e) => e?.controlled_action == true && e?.key == "resolve");
				if (comp && comp[0]) {
					comp[0].callback(row, args);
				}
			} else if (args?.state_id == this.options?.state_key_id_map?.Close) {
				let comp = this.actionList?.filter((e) => e?.controlled_action == true && e?.key == "complete");
				if (comp && comp[0]) {
					comp[0].callback(row, args);
				}
			} else if (
				args?.state_id == this.options?.state_key_id_map?.Approval &&
				this.approval_module_list.includes(this.module_name)
			) {
				this.process_data = row;
				setTimeout(() => {
					if (args?.state_id == 6) {
						this.temp_status = args;
						this.temp_obj = obj;
						this.modalService.open(this.appovalChangeComp, {
							centered: true,
							animation: false,
							size: "md",
						});
					}
				}, 500);
			} else {
				this.onInlineEditCallback?.emit(obj);
			}
		} else {
			this.onInlineEditCallback?.emit(obj);
		}
	}

	// getOptions() {
	// 	this._requestViewService.getOptions().subscribe(
	// 		(response) => {
	// 			if (response) {
	// 				this.dropdown['state'] = response["state"];
	// 					this.dropdown['status'] = response["state_status_map"];
	// 					this.dropdown['status_config'] = response["status"];
	// 					this.dropdown['urgency'] = response["urgency"];
	// 					this.dropdown['priority'] = response["priority"];
	// 					if (this.dropdown['priority'].length > 0){
	// 						this.addRequest.priority_id = this.dropdown['priority'][2]['id']
	// 					}
	// 					this.dropdown['severity'] = response["severity"];
	// 					this.dropdown['event_type'] = response["event_type"];
	// 					this.dropdown['type'] = response['type'];
	// 					this.dropdown['impact'] = response['impact'];
	// 					this.dropdown['tagList'] = response['TAGS_LIST'];
	// 					this.dropdown['tagTypeList'] = response["TAG_TYPE_LIST"];
	// 					this.dropdown['users'] = response['users'];
	// 					this.dropdown['teams'] = response['teams']
	// 					if (response.teams && response.current_user_group.length > 0){
	// 						this.dropdown['teams_and_self_assign'] = [{'team_id':'self_assign','name':'Self Assign'}].concat(response['teams'])
	// 					}
	// 					else{
	// 						this.dropdown['teams_and_self_assign'] = response['teams']
	// 					}
	// 					this.dropdown['assignee_group']['group'] = response['teams']
	// 					this.dropdown['requesters'] = response['requesters']
	// 					// this.dropdown['all_services'] = response['all_services']
	// 					this.dropdown['request_source'] = response['request_source'];
	// 					this.dropdown['groupt_type_list'] = response['groupt_type_list']
	// 					this.dropdown['attach_category_list'] = response['attach_category_list']
	// 					this.dropdown['file_icon_color_map'] = response['file_icon_color_map']
	// 					this.dropdown['close_type'] = response["close_type"];
	// 					this.dropdown['closure_category_list'] = response["closure_category"];
	// 					this.dropdown['current_user_group'] = response["current_user_group"];

	// 			}
	// 		},
	// 		(error) => {
	// 			//todo
	// 		}
	// 	);
	// }
	changeFn(val) {}

	showSummaryCard(summaryData, priorityColor, processPrimaryKey) {
		summaryData["priorityColor"] = priorityColor;
		summaryData["priority"] = summaryData.id;
		this.summaryInput = {
			data: summaryData,
			isLoadSummaryCard: true,
		};
		this.summaryInput[this.processPrimaryKey] = processPrimaryKey;
	}

	buildOnCallBack(rowData, key, value, directCallback = false) {
		if (!directCallback) {
			if (key == "self_assign") {
				this.panelCardConfig["disableTakeIt"] = true;
			}
			if (key == "team_change") {
				rowData.assignment_info.group = value;
			}

			if ([6, 7].includes(rowData?.state_id)) {
				return;
			}
			let obj = {};
			obj["key"] = key;
			obj["value"] = value;
			obj["rowData"] = rowData;
			this.onCallBack.emit(obj);
		} else {
			let obj = {};
			obj["key"] = key;
			obj["value"] = value;
			obj[this.processPrimaryKey] = rowData?.[this.processPrimaryKey];
			this.onInlineEditCallback?.emit(obj);
		}
	}

	onImpactServiceClick = (element: any, columnObj, event) => {
		if (![1, 2].includes(element?.state_id)) {
			return;
		}
		columnObj = {
			label: "UI.k_impact_service",
			key: "impact_service",
			value: "impact_service_tree_path",
			db_col: "basic_info__impact_service",
			optionKey: "formatted_services",
			type: "tree_view_select",
			filter_col: "basic_info__impact_service_tree_path",
		};
		event.preventDefault();
		// if (![1, 2].includes(element?.state_id)) {
		// 	return;
		// }
		if (columnObj?.key == "impact_service") {
			if (!element["dropdown_options"][columnObj?.optionKey + "_config"]) {
				element["dropdown_options"][columnObj?.optionKey + "_config"] = {};
			}
			element["dropdown_options"][columnObj?.optionKey + "_config"]["is_loading"] = true;
			element["dropdown_options"][columnObj.optionKey] = [];
			this._requestViewService
				.getRequesterImpactService({
					requester_id: element?.requester_id,
					is_published: true,
					module_name_list: JSON.stringify([this.module_name]),
				})
				.subscribe(
					(res) => {
						element["dropdown_options"][columnObj?.optionKey + "_config"]["is_editable"] = true;
						element["dropdown_options"][columnObj.optionKey] = [];
						res?.forEach((ele) => {
							const data = new TreeviewItem(ele);
							element["dropdown_options"][columnObj.optionKey].push(data);
						});
						element["dropdown_options"][columnObj?.optionKey + "_config"]["is_loading"] = false;
					},
					(error) => {
						//console.log("error_while_fetching impact service", error);
						element["dropdown_options"][columnObj?.optionKey + "_config"]["is_loading"] = false;
					}
				);
		} else if (columnObj?.key == "service_classification") {
			element["dropdown_options"][columnObj?.optionKey + "_config"]["is_loading"] = true;
			element["dropdown_options"][columnObj.optionKey] = [];
			if (element.impact_service) {
				this._requestViewService
					.getImpactServiceClassification({ service_id: element.impact_service, format_to_treeview: true })
					.subscribe(
						(res) => {
							element["dropdown_options"][columnObj?.optionKey + "_config"]["is_editable"] = true;
							element["dropdown_options"][columnObj.optionKey] = [];
							res?.classification?.forEach((ele) => {
								const data = new TreeviewItem(ele);
								element["dropdown_options"][columnObj.optionKey].push(data);
							});
							element["dropdown_options"][columnObj?.optionKey + "_config"]["is_loading"] = false;
						},
						(error) => {
							//console.log("error_while_fetching impact service", error);
							element["dropdown_options"][columnObj?.optionKey + "_config"]["is_loading"] = false;
						}
					);
			} else {
				this._toastarService.error(this._translateService.instant("Error.err_select_impact_service"));
				element["dropdown_options"][columnObj?.optionKey + "_config"]["is_loading"] = false;
			}
		}
	};

	checkControlledActions(element) {
		let comp = this.actionList?.filter(
			(e) => e?.controlled_action == true && e?.type == "button" && e?.enable(element, e?.key)
		);
		if (comp && comp?.length > 0) {
			return true;
		} else {
			return false;
		}
	}

	onDisplaykey = (key: string) => {
		if (key) {
			key = key
				.replace(/<.*?>/g, " ")
				.replace(/<img[^>]*>/g, "")
				.replace(/&nbsp;/g, " ")
				.replace(/&amp;/g, "&");
			// key = key.slice(0, 220);
		}
		return key;
	};

	onApprovalConfigClose = ($event) => {
		this.temp_obj["value"] = this.process_data?.status;
		this.temp_obj["value"]["id"] = this.process_data?.status_id;
		this.temp_obj["multiple_ke_vl"] = { state_id: this.process_data?.state_id };
		this.process_data = {};
		this.onInlineEditCallback?.emit(this.temp_obj);
		this.modalService.dismissAll();
		this.temp_status = null;
		this.temp_obj = null;
	};

	onApprovalConfigSubmit = ($event) => {
		this.modalService.dismissAll();
		this.process_data = {};
		this.temp_obj["approval_config"] = $event;
		this.onInlineEditCallback?.emit(this.temp_obj);
		this.temp_status = null;
		this.temp_obj = null;
	};
	info_action($event) {
		$event.key = "asset_id"
		this.onCallBack?.emit($event);
	}
}
