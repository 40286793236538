import { Injectable } from "@angular/core";
import { ApiService } from "app/common/services/api/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class DiscoveryService extends ApiService {
	constructor(httpClient: HttpClient) {
		super(httpClient);
	}

	getDiscoveryConfigs(payload = {}) {
		return this.get("ux/ims/discovery/profile/", payload);
	}

	saveDiscoveryConfig(payload: any) {
		return this.post("ux/ims/discovery/profile/", payload);
	}

	getDiscoveryConfig(id: Number) {
		return this.get("ux/ims/discovery/profile/" + id.toString() + "/");
	}

	deleteDiscoveryConfig(id: Number) {
		return this.delete("ux/ims/discovery/profile/" + id.toString() + "/");
	}

	getAgentJson() {
		return this.get("ux/ims/agent/profile/json/");
	}

	getDiscoveryOptions() {
		return this.get("ux/ims/discovery/profile/options/");
	}

	getDiscoveryDetails(payload) {
		return this.get("ux/ims/discovery/profile/details/", payload);
	}

	getDiscoveryAudit(payload: any) {
		return this.get("ux/ims/discovery/profile/audit/", payload);
	}

	downloadSampleCSV(payload: any) {
		return this.get("ux/ims/discovery/profile/download_csv/", payload);
	}

	validateUploadedFile(payload: any) {
		return this.post("ux/ims/discovery/profile/validate_csv/", payload);
	}

	getIterationList(payload) {
		return this.get("ux/ims/discovery/profile/iteration-list/", payload);
	}

	getSavedFilter(payload) {
		return this.get("ux/common/filters/filters/filter/", payload);
	}

	getFilterOptions(payload: any) {
		return this.get("ux/common/filters/filters/filter_options/", payload);
	}

	download_services(payload: {}) {
		return this.get("ux/common/export/export/export_data/", payload);
	}

	doRescan(payload: any) {
		return this.post("ux/ims/discovery/profile/do-rescan/", payload);
	}
}
