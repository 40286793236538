import { AfterViewInit, Component, Input, ViewEncapsulation } from '@angular/core';
import { CoreMenuItem } from '@core/types';
import { environment } from 'environments/environment.instance';
import Lottie from 'lottie-web';

@Component({
  selector: '[core-menu-vertical-item]',
  templateUrl: './item.component.html',
	encapsulation: ViewEncapsulation.None

})
export class CoreMenuVerticalItemComponent implements AfterViewInit {
  @Input()
  item: CoreMenuItem;
  onPremise: boolean = false;

  ngAfterViewInit() {
    this.onPremise = environment.on_premise
    if (this.item.icon){
      let iconTrigger = document.getElementById(this.item.icon + '-trigger');
      let iconContainer = document.getElementById(this.item.icon + '-container')
      let icon = Lottie.loadAnimation({
          container: iconContainer,
          path: '../../../../../assets/images/lotty/' + this.item.icon + '.json',
          renderer: 'svg',
          loop: true,
          autoplay: false,
      });
      if( iconTrigger){
        iconTrigger.addEventListener("mouseover", () => {
          icon.play();
        }, false);
        iconTrigger.addEventListener("mouseout", () => {
          icon.stop()
        }, false);
      }
    }
  }
}
