import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'kb-solution-view',
  templateUrl: './kb-solution-view.component.html',
  styleUrls: ['./kb-solution-view.component.scss']
})
export class KbSolutionViewComponent implements OnInit {

 @Output() onHideKBSolution = new EventEmitter(false);
  @Input() public kbID: string;
  @Input() public kbSolution: any;

	constructor(
	) { }


	ngOnInit(): void {
	}

	toggleSidebar = (): void => {
		this.onHideKBSolution.emit(true);
	};

}
