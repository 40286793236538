import { Component, EventEmitter, Input, OnInit, Output, HostListener, ViewChild } from "@angular/core";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FlatpickrOptions } from "ng2-flatpickr";
import { ToastrService } from "ngx-toastr";
import { customSearch, isValidDateRange } from "app/common/utils/utils";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ReleaseService } from "app/servicedesk/release-manage/services/release-services.service";
import { TranslateService } from "@ngx-translate/core";
import cloneDeep from "lodash-es/cloneDeep";

@Component({
  selector: 'app-dns-release-view-task',
  templateUrl: './dns-release-view-task.component.html',
  styleUrls: ['./dns-release-view-task.component.scss']
})
export class DnsReleaseViewTaskComponent implements OnInit {
  @Input() featureViewTaskData;
	@Input() release_data: any = {};
	@Input() options: any = [];
	@Input() disable_config: any;
	@ViewChild("datepicker") Duedate: FlatpickrOptions;
	public task_obj: any = {};
	public disableSaveBtn: boolean = false;
	@Output("onHideQuickEdit") onHideQuickEdit = new EventEmitter<any>();
	@Output("getTaskList") getTaskList = new EventEmitter<any>();
	public basicDateOptions: FlatpickrOptions = {
		altInput: true,
		altInputClass: "form-control flat-picker flatpickr-input",
	};
	public basicDateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime:true,
		altInputClass: "form-control flat-picker flatpickr-input",
	};
	taskMappingDropdown = {
		1: [
			{
				key: "relate_issue",
				name: "UI.k_relate_issue",
				type: 3,
			},
			{
				key: "add_issue",
				name: "UI.k_add_issue",
				type: 3,
			},
		],
		2: [
			{
				key: "relate_issue",
				name: "UI.k_relate_issue",
				type: 3,
			},
			{
				key: "add_issue",
				name: "UI.k_add_issue",
				type: 3,
			},
			{
				key: "relate_task",
				name: "UI.k_relate_task",
				type: 1,
			},
			{
				key: "add_task",
				name: "UI.k_add_task",
				type: 1,
			},
		],
	};
	// taskDropDown: any = [
	//   {
	//     "key": "related_issue",
	//     "name": "UI.k_relate_task",
	//   },
	//   {
	//     "key": "create_issue",
	//     "name": "UI.k_add_task",
	//   },
	// ]

	typeMapping = {
		1: "UI.k_view_task",
		2: "UI.k_view_feature",
		3: "UI.k_view_issue",
	};
	aboutTaskMapping = {
		1: "UI.k_about_task",
		2: "UI.k_about_feature",
		3: "UI.k_about_issue",
	};
	relateNameMapping = {
		1: "UI.k_relate_issue",
		2: "UI.k_relate_task_issue",
	};
	relationMapping = {
		1: "UI.k_related_issue",
		2: "UI.k_related_issue_task",
	};

	isRelateTask: boolean = false;
	isNewTask: boolean = false;
	isAddTask: boolean = false;

	constructor(
		private _coreSidebar: CoreSidebarService,
		private _toastr: ToastrService,
		private _translateService: TranslateService,
		private _releasService: ReleaseService
	) {}

	ngOnInit(): void {
		this.task_obj["ref_id"] = this.release_data?.change_id;
		this.task_obj["ref_display_id"] = this.release_data?.display_id;
		if (this.release_data?.due_date) {
			this.basicDateTimeOptions["maxDate"] = this.release_data?.due_date;
		}
		this.getTaskData();
	}
	getTaskData() {
		this._releasService.getReleaseTask(this.featureViewTaskData?.task_id, {}).subscribe(
			(response) => {
				if (response) {
					this.task_obj = response?.data;
					this.featureViewTaskData["related_task"] = response?.related_task;

					//   let related_task_id = [this.featureViewTaskData?.task_id]
					//   for (var idx in this.featureViewTaskData?.related_task) {
					//     related_task_id.push(this.featureViewTaskData?.related_task?.[idx]?.task_id)
					//   }
					//   this.featureViewTaskData["taskData"] = this.featureViewTaskData?.taskData.filter((item) =>
					// related_task_id.indexOf(item?.task_id) == -1
					//   )
				}
			},
			(error) => {
				//todo
			}
		);
	}

	toggleSidebar(key) {
		this._coreSidebar.getSidebarRegistry(key).close();
		this.onHideQuickEdit.emit();
	}

	SaveTask = (data) => {
		if (data.form.valid === true) {
			let task_obj_tmp = cloneDeep(this.task_obj);
			if (task_obj_tmp.due_date instanceof Array) {
				task_obj_tmp.due_date = task_obj_tmp.due_date[0];
				// task_obj_tmp.due_date = task_obj_tmp.due_date.toDateString();
			}
			this.disableSaveBtn = true;
			this._releasService
				.saveReleaseTask(task_obj_tmp)
				.pipe(
					map((response: any) => {
						this.disableSaveBtn = false;
						if (response) {
							this.getTaskList.emit();
							if (response["status"] == "success") {
								this._toastr.success(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Message.msg_save_success"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
								this.toggleSidebar("view-task");
							} else {
								this._toastr.error(
									this._translateService.instant(response["message"], {
										existing_rule: response?.data?.existing_rule,
									}),
									this._translateService.instant("Error.err_save_failed"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							}
						}
						// Success
					}),
					catchError((error) => {
						// this._toastr.error(
						//     this._translateService.instant("Error.err_save_failed"),
						//     this._translateService.instant("Error.err_save_failed"),
						//     {
						//         toastClass: "toast ngx-toastr",
						//         closeButton: true,
						//         positionClass: "toast-top-right",
						//     }
						// );
						this.disableSaveBtn = false;
						return throwError(error.statusText);
					})
				)
				.subscribe();
		}
	};

	onStatusChange = (data) => {
		this.task_obj.status = data;
	};

	statusBadgeChange = (data) => {
		this.task_obj[data["field"]] = data?.event ? data.event : {};
	};

	customSearchFn(term: string, item: any) {
		return customSearch(term, item, "full_name", "email");
	}

	onAssigneeChange = ($event) => {
		this.task_obj["assignee_profile"] = {
			profile_id: $event?.profile_id,
			name: $event?.full_name,
			full_name: $event?.full_name,
			email: $event?.email,
		};
	};

	nameExtractorHandler(data: any) {
		return data
			?.split(" ")
			.map((n) => n[0])
			.join("");
	}

	relateTaskHandler(task: any) {
		this.featureViewTaskData["relate_type"] = task?.type;
		if (task.key == "relate_issue" || task.key == "relate_task") {
			this._coreSidebar.getSidebarRegistry("relate-task").toggleOpen();
			this.isRelateTask = true;
		} else {
			this._coreSidebar.getSidebarRegistry("add-task-from-view").toggleOpen();
			this.isAddTask = true;
		}
	}

	onHideTask = (e) => {
		this.isAddTask = false;
		this._coreSidebar.getSidebarRegistry("add-task-from-view").close();
		this.getTaskData();
		this.getTaskList.emit();
	};
	onHideRelateHandler() {
		this.isRelateTask = false;
	}

	onHideNewTaskHandler() {
		this.isNewTask = false;
	}

	newTaskCreateHandler(evt) {
		// //console.log(evt);
	}
	@HostListener("keydown", ["$event"]) //keydown, not keyup
	onKeyDown(event: any) {
		if (event.keyCode === 13 && event.target?.tagName != "BUTTON") {
			// 13 = enter
			event.preventDefault();
			return false;
		}
	}
	ngOnDestroy() {
		this.Duedate?.flatpickr.close();
	}
}
