import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-msp-grid-name-design',
  templateUrl: './msp-grid-name-design.component.html',
  styleUrls: ['./msp-grid-name-design.component.scss']
})
export class MSPGridNameDesignComponent implements OnInit {
  data: any;

	public target: any = "_self";
  constructor() { }

  ngOnInit(): void {
  }
  getUrl(data){
    return "/" + "msp/organization/" + "123344";
  }

}
