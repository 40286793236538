import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrganizationsComponent } from './component/organizations/organizations.component';
import { AuthGuard } from 'app/common/guards/auth.guards';
import { MspUserComponent } from './component/msp-user/msp-user.component';
import { OrganisationViewComponent } from './component/organisation-view/organisation-view.component';

const routes: Routes = [
	{
		path: "",
		component: OrganizationsComponent,
		canActivate: [AuthGuard],
		data: {
			module: ['knowledgebase'],
			keyList: ['view'],
		}
	},
	{
		path: "organization",
		component: OrganizationsComponent,
		canActivate: [AuthGuard],
		data: {
			module: ['knowledgebase'],
			keyList: ['view'],
		}
	},
	{
		path: "user",
		component: MspUserComponent,
		canActivate: [AuthGuard],
		data: {
			module: ['knowledgebase'],
			keyList: ['view'],
		}
	},
	{
		path: "organization/:id",
		component: OrganisationViewComponent,
		canActivate: [AuthGuard],
		data: {
			module: ['knowledgebase'],
			keyList: ['view'],
		}
	}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MspPortalRoutingModule { }
