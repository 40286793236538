import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { count } from 'rxjs/operators';
@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GlobalSearchComponent implements OnInit {
  public changeText = []
  public searchText = '';
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    ) { }

  ngOnInit(): void {
  }

  
  
  public searchData = 
    {
      recentsearch:[
                      {name:"Recent search 1"}, 
                      {name:"Recent searh 2"},
                      {name:"Recent searh 3"}
                   ],
        recentView:[
                      {icon:"ticket", key:"Incident", value:"some thing here"}, 
                      {icon:"ticket", key:"Recent", value:"How to edit discovery Schedule"},
                      {icon:"ticket", key:"Recent", value:"How to edit discovery Schedule"}
                  ],

      bookMarkedpages:[
                      {icon:"file-chart-column", name:"Reports"}, 
                      {icon:"book-open", name:"Knowledge Base"},
                      {icon:"tag", name:"Tags"},
                      {icon:"robot", name:"Agents"},
                      {icon:"user-group", name:"Users"},
                      {icon:"ticket", name:"Incidents"},
                      {icon:"tag", name:"Tags"},
                     
                    ],

          footericons:[
                      {icon:"arrow-up", name:"Reports"}, 
                      {icon:"arrow-down", name:"Knowledge Base"},
                      {icon:"arrow-left", name:"Tags"},
                      {icon:"arrow-right", name:"Agents"},
                    ],
                    newKB:[
                      {icon:"1.png", describe:"The more important to work, The more important to rest"}, 
                      {icon:"1.png", describe:"The more important to work, The more important to rest"},
                      {icon:"1.png", describe:"The more important to work, The more important to rest"},
                    ],

                    pages:[
                      {
                        icon:"tachometer-alt-slowest", 
                        name:"Dashboard",
                        type:"Dashboard",
                        handle: "/dashboard",
                      }, 
                      {
                        icon:"tachometer-alt-slowest",
                        name:"New Dashboard", 
                        type:"Dashboard",
                        handle: "/dashboard/add",
                      },
                      {
                        icon:"globe",
                        name:"Schedule discovery",
                        type:"Incident",
                        handle: "/discovery",
                      },
                      {
                        icon:"ticket", 
                        name:"New Incident", 
                        type:"Incident",
                        handle: "/incident_view/add",
                      },
                      {
                        icon:"ticket",
                        name:"Incident grid", 
                        type:"Incident",
                        handle: "/incident_view",
                      },
                      {
                        icon:"robot", 
                        name:"View Agents", 
                        type:"Incident",
                        handle: "/agent",
                      },
                      // {
                      //   icon:"exclamation-circle",
                      //   name:"View Alerts",
                      //   type:"Setup"
                      // },
                      {
                        icon:"user", 
                        name:"Users", 
                        type:"Setup",
                        handle: "/users",
                      },
                      {
                        icon:"stopwatch", 
                        name:"Bussiness hours",
                        type:"Genral",
                        handle: "/business_hour",
                      },
                      {
                        icon:"tag", 
                        name:"Tags", 
                        type:"Genral",
                        handle: "/tags",
                      },
                      {
                        icon:"bell",
                        name:"Notifications",
                        type:"Notifications",
                        handle: "",
                      },
                      {
                        icon:"robot", 
                        name:"View Agents", 
                        type:"Incident",
                        handle: "/agent",
                      }
                    ],
                   count:{
                     bookMark:"7"

                   }
    }

  OpenSearch() {
    this.modalService.open(GlobalSearchComponent);
  }
  toggleclose(){
      this.modalService.dismissAll()

  }
}
