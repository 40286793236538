<ng-container>
	<div class="bg-primary selected-data flex-initial table-grid-top-bar" *ngIf="isMultipleSelected.length">
		<div class="px-1 d-flex align-items-center justify-content-between">
			<div class="d-flex align-items-center">
				<input type="checkbox" (change)="allSelectHandler($event)" [checked]="isAllSelected" />
				<span class="text-white font-small-2 text-nowrap" style="margin-left: 0.5rem; font-weight: 600">
					{{ isMultipleSelected?.length }} {{ "UI.k_selected" | translate }}
				</span>
			</div>
			<div class="d-flex align-items-center">
				<ng-container *ngIf="panelConfig?.multiselectOptions?.multiple_action_list?.length">
					<ul class="list-inline text-center mb-0">
						<ng-container
							*ngFor="let menu of panelConfig?.multiselectOptions?.multiple_action_list; let i = index"
						>
							<li class="list-inline-item m-0" *ngIf="i < 6">
								<button
									class="btn btn-primary bg-darken-2 px-75"
									(click)="menu.callback(menu)"
									id="btn{{ menu.key }}"
									attr.data-testid="btn{{ menu.key }}"
								>
									<i class="fa-light fa-{{ menu.icon }} fa-sm text-white mr-50"></i>
									<span class="font-small-3">{{ menu.label }}</span>
								</button>
							</li>
						</ng-container>
					</ul>
				</ng-container>

				<ng-container *ngIf="panelConfig?.multiselectOptions?.multiple_action_list?.length">
					<div ngbDropdown>
						<button
							ngbDropdownToggle
							class="btn btn-primary bg-darken-2"
							type="button"
							id="dropdownMenuButton100"
						>
							{{ "UI.k_more" | translate }}
						</button>
						<div
							ngbDropdownMenu
							aria-labelledby="dropdownMenuButton100"
							style="height: 300px; width: 250px"
						>
							<ng-scrollbar class="custom-scrollbar section-scrollbar">
								<ng-container
									*ngFor="
										let menu of panelConfig?.multiselectOptions?.multiple_action_list;
										let i = index
									"
								>
									<ng-container *ngIf="i >= 6">
										<a
											ngbDropdownItem
											href="javascript:void(0)"
											(click)="menu.callback(menu)"
											id="btn{{ menu.key }}"
											attr.data-testid="btn{{ menu.key }}"
										>
											<i class="fa-light fa-{{ menu.icon }} fa-sm mr-50"></i>
											{{ menu.label }}</a
										>
									</ng-container>
								</ng-container>
							</ng-scrollbar>
						</div>
					</div>
				</ng-container>
			</div>
			<div
				class="btn btn-primary btn-icon"
				(click)="emptySelected()"
				id="divClose"
				[attr.data-testid]="'divClose'"
			>
				<i class="fa-light fa-xmark"></i>
			</div>
		</div>
	</div>
	<div class="d-grid full-height">
		<ng-scrollbar class="custom-scrollbar section-scrollbar" track="all">
			<div class="dns-panel-view-scroll bg-body">
				<ng-container *ngFor="let element of panelData.results | filter: inputValue; let idx = index">
					<div
						class="dns-panel-main-body-sec bg-card border common-pannel-view custom-material-table state-color state-{{
							element.state_id
						}} w-100"
						[ngClass]="{ maxContentActive: isExpanded }"
						id="div__{{ element?.display_id }}-{{ idx }}"
						attr.data-testid="div__{{ element?.display_id }}"
					>
						<div class="dns-panel-header-sec border-bottom">
							<ng-container *ngFor="let column of columns">
								<ng-container *ngIf="column?.type == 'summary'">
									<div class="dns-panel-contact-sec">
										<ng-container>
											<div class="dns-panel-contact-name cursor-pointer">
												<!-- <input type="checkbox" (change)="onChecklistChangeHandler($event.target.checked, element)" [checked]="element.checked" [value]="element.display_id" id=""/> -->
												<span
													class="text-primary fw-500"
													tooltipClass="fadeInAnimation"
													ngbTooltip="{{ element[column?.key] }}"
													placement="bottom"
													(click)="column?.callback(element)"
													id="sp__{{ element[column?.key] }}-{{ idx }}"
													attr.data-testid="sp__{{ element[column?.key] }}"
												>
													{{
														element[column?.key]?.length > 10
															? (element[column?.key] | slice: 0:10) + "..."
															: element[column?.key]
													}}
												</span>
											</div>
											<div class="dns-panel-icon-list">
												<ng-container
													*ngFor="let titleIcon of column?.summary_card_data?.title_icons"
												>
													<ng-container *ngIf="titleIcon?.show(titleIcon, element)">
														<button
															type="button"
															class="btn btn-xs btn-icon btn-outline-tertiary"
															rippleEffect
															tooltipClass="fadeInAnimation"
															ngbTooltip="{{
																titleIcon?.tooltip(titleIcon, element) | translate
															}}"
															placement="right"
															attr.data-testid="btn__{{ titleIcon?.key }}"
															id="btn__{{ titleIcon?.key }}-{{ idx }}"
														>
															<i
																class="fa-light text-primary fa-{{
																	titleIcon?.icon(titleIcon, element)
																}}"
															></i>
														</button>
													</ng-container>
													<!-- <span class="panel-global-icon" 
												tooltipClass="fadeInAnimation"
												ngbTooltip="{{titleIcon?.tooltip(titleIcon, element) | translate}}"
												placement="bottom"
												*ngIf="titleIcon?.show(titleIcon, element)">
		
													<i class="fa-light text-primary fa-{{titleIcon?.icon(titleIcon, element)}}" 
														></i>
												</span> -->
												</ng-container>
												<ng-container
													*ngFor="let actionIcon of column?.summary_card_data?.actions"
												>
													<button
														type="button"
														class="btn btn-xs btn-icon btn-outline-tertiary"
														rippleEffect
														(click)="actionIcon.callback(element)"
														container="body"
														ngbTooltip="{{ actionIcon.label | translate }}"
														placement="right"
														attr.data-testid="btn__{{ actionIcon?.key }}"
														id="btn__{{ actionIcon?.key }}-{{ idx }}"
													>
														<i class="fa-light fa-{{ actionIcon?.icon }} text-primary"></i>
													</button>
													<!-- <span 
                                                    container="body"
                                                    ngbTooltip="{{actionIcon.label | translate }}"
                                                    placement="right"
                                                    >
                                                        <i (click)="actionIcon.callback(element)" class="fa-light fa-{{actionIcon?.icon}} text-primary" tooltip="{{actionIcon.label | translate }}"></i>
                                                    </span> -->
												</ng-container>
											</div>
										</ng-container>
									</div>
								</ng-container>
								<ng-container *ngIf="column?.type == 'title'">
									<div class="panel-desc-main-block border-left">
										<ng-container>
											<div class="panel-title">
												<span class="panel-title-desc">
													{{ element?.[column?.title?.key] | slice: 0:140 }}
													{{ element?.[column?.title?.key]?.length > 140 ? "..." : " " }}
												</span>
												<span class="panel-title-date">
													{{element?.[column?.top_data?.key]}}</span
												>
											</div>
											<div
												[innerHTML]="element?.[column?.description?.key]"
												dnsReadMore
												[readMore-length]="200"
												class="description text panel-desc"
												placement="bottom"
											></div>
										</ng-container>
									</div>
								</ng-container>
							</ng-container>
						</div>
						<ng-container>
							<div class="dns-panel-main-body-block">
								<ng-container *ngFor="let column of columns; let colIdx = index">
									<ng-container *ngIf="column?.type != 'summary' && column?.type != 'title'">
										<ng-container *ngIf="column?.type == 'summary_column'">
											<div class="dns-panel-body-sec">
												<div class="panel-data-block">
													<div class="panel-data-title sm-font">
														{{ column.label | translate }}
													</div>
													<div class="custom-drop-down-block">
														<ng-select
															[items]="options?.[column.optionKey]"
															placeholder="{{ 'UI.k_select' | translate }} {{
																column.label | translate
															}}"
															[bindLabel]="column?.bind_label"
															class="sm-form-control form-control assign-user-dropdown"
															[bindValue]="column?.bind_value"
															[clearable]="false"
															(change)="buildOnCallBack($event, column, element)"
															id="sel{{ column.key }}-{{ idx }}"
															name="sel{{ column.key }}-{{ idx }}"
															[(ngModel)]="element[column.key]"
															[disabled]="column?.disabled(column, element)"
															appendTo="body"
															attr.data-testid="sel{{ column.key }}"
														>
															<ng-template ng-label-tmp let-item="item">
																<div class="list-item-block" *ngIf="item?.full_name">
																	<ng-container *ngIf="item?.profile_image">
																		<div class="avatar avatar-sm">
																			<span
																				class="avatar-content"
																				*ngIf="item?.profile_image"
																			>
																				<img
																					class="rounded-circle"
																					src="{{ item?.profile_image }}"
																					alt="User"
																				/>
																			</span>
																		</div>
																	</ng-container>
																	<ng-container *ngIf="!item?.profile_image">
																		<div class="avatar avatar-sm">
																			<span
																				class="avatar-content"
																				*ngIf="!item.profile_image"
																				[ngStyle]="{
																					'background-color':
																						item.avatar_color
																							? item.avatar_color
																							: '#f59518'
																				}"
																			>
																				{{
																					item.full_name
																						| initials
																						| slice: 0:2
																				}}
																			</span>
																		</div>
																	</ng-container>
																	<span class="title-color"
																		>{{ item?.full_name | slice: 0:25 }}
																		{{
																			item?.full_name?.length > 25 ? ".." : ""
																		}}</span
																	>
																</div>
															</ng-template>
															<ng-template
																ng-option-tmp
																let-item="item"
																let-index="index"
															>
																<div>
																	<ng-container *ngIf="item?.profile_image">
																		<div class="avatar avatar-sm">
																			<span
																				class="avatar-content"
																				*ngIf="item?.profile_image"
																			>
																				<img
																					class="rounded-circle"
																					src="{{ item?.profile_image }}"
																					alt="User"
																				/>
																			</span>
																		</div>
																	</ng-container>
																	<ng-container *ngIf="!item?.profile_image">
																		<div class="avatar avatar-sm">
																			<span
																				class="avatar-content"
																				*ngIf="!item.profile_image"
																				[ngStyle]="{
																					'background-color':
																						item.avatar_color
																							? item.avatar_color
																							: '#f59518'
																				}"
																			>
																				{{
																					item.full_name
																						| initials
																						| slice: 0:2
																				}}
																			</span>
																		</div>
																	</ng-container>
																	<span
																		>{{ item?.full_name | slice: 0:25 }}
																		{{
																			item?.full_name?.length > 25 ? ".." : ""
																		}}</span
																	>
																</div>
															</ng-template>
														</ng-select>
													</div>
												</div>
											</div>
										</ng-container>
										<ng-container *ngIf="column?.type == 'assignment'">
											<div class="dns-panel-body-sec br-left">
												<div class="panel-data-block">
													<div class="d-flex justify-content-between align-items-center">
														<div class="panel-data-title sm-font">
															{{ column?.label | translate }}
														</div>
														<button
															*ngIf="column?.show_takeit(column, element)"
															class="btn btn-sm text-primary cursor-pointer p-0"
															(click)="buildOnCallBack('self_assign', column, element)"
															[disabled]="panelConfig?.disableTakeIt"
															id="btnSelfAssign-{{ idx }}"
															name="btnSelfAssign-{{ idx }}"
															[attr.data-testid]="'btnSelfAssign'"
														>
															{{ "UI.k_take_it" | translate }}
														</button>
													</div>
													<ng-select
														[items]="options?.[column?.optionKey]"
														[multiple]="false"
														[closeOnSelect]="true"
														[(ngModel)]="element[column.key]"
														[searchable]="true"
														bindLabel="{{ column?.bind_label }}"
														bindValue="{{ column?.bind_value }}"
														name="sel{{ column?.key }}-{{ idx }}"
														id="sel{{ column?.key }}-{{ idx }}"
														class="sm-form-control form-control assign-user-dropdown assign-group-dropdown"
														[clearable]="false"
														placeholder="{{ 'UI.k_select' | translate }} {{
															column?.label | translate
														}}"
														[disabled]="column?.disabled(column, element)"
														appendTo="body"
														attr.data-testid="sel{{ column?.key }}"
													>
														<ng-template ng-label-tmp let-item="item">
															<span
																class="media"
																*ngIf="item.team_id != 'self_assign'"
																(click)="buildOnCallBack(item.team_id, column, element)"
																id="spAssignTeam{{ item.team_id }}"
																attr.data-testid="spAssignTeam{{ item.team_id }}"
															>
																<div
																	class="avatar avatar-sm"
																	[ngClass]="bg - light - primary"
																>
																	<div
																		class="avatar-content"
																		[ngStyle]="{
																			'background-color': stringToColor(item.name)
																		}"
																	>
																		{{ item.name | initials | slice: 0:2 }}
																	</div>
																</div>
																<div class="cell-line-height">
																	<span
																		class="title-color mb-0"
																		container="body"
																		[ngbTooltip]="item?.name"
																	>
																		{{ item?.name | slice: 0:25 }}
																		{{ item?.name.length > 25 ? ".." : "" }}
																	</span>
																</div>
															</span>
															<ng-container *ngIf="item.team_id == 'self_assign'">
																<div
																	class="media"
																	(click)="
																		buildOnCallBack(item.team_id, column, element)
																	"
																	id="divSelfAssignTeam{{ item.team_id }}"
																	attr.data-testid="divSelfAssignTeam{{
																		item.team_id
																	}}"
																>
																	<div class="avatar avatar-sm">
																		<div
																			class="avatar-content"
																			[ngStyle]="{
																				'background-color': stringToColor(
																					currentUser?.first_name +
																						'' +
																						currentUser?.last_name
																				)
																			}"
																		>
																			{{
																				currentUser?.first_name +
																					"" +
																					currentUser?.last_name
																					| initials
																					| slice: 0:2
																			}}
																		</div>
																	</div>
																	<div class="media-body">
																		<span class="mb-0 title-color">
																			{{ "UI.k_self_assign" | translate }}
																		</span>
																	</div>
																</div>
															</ng-container>
														</ng-template>
														<ng-template
															ng-option-tmp
															let-item="item"
															let-index="index"
															let-search="searchTerm"
														>
															<span
																class="media"
																*ngIf="item.team_id != 'self_assign'"
																(click)="buildOnCallBack(item.team_id, column, element)"
															>
																<div
																	class="avatar avatar-sm"
																	[ngClass]="bg - light - primary"
																>
																	<div
																		class="avatar-content"
																		[ngStyle]="{
																			'background-color': stringToColor(item.name)
																		}"
																	>
																		{{ item.name | initials | slice: 0:2 }}
																	</div>
																</div>
																<div class="cell-line-height">
																	<span
																		class="font-medium-1 fw-500 line-height-1 mb-0"
																		container="body"
																		[ngbTooltip]="item.name"
																	>
																		{{ item.name | slice: 0:25 }}
																		{{ item.name.length > 25 ? ".." : "" }}
																	</span>
																	<span class="text-muted sm-font">
																		{{ item.group_type | slice: 0:25
																		}}{{
																			item.group_type.length > 25 ? ".." : ""
																		}}</span
																	>
																</div>
															</span>
															<div
																class="cursor-pointer"
																*ngIf="item.team_id == 'self_assign'"
																(click)="buildOnCallBack(item.team_id, column, element)"
															>
																<div class="media">
																	<div class="avatar avatar-sm">
																		<div
																			class="avatar-content"
																			[ngStyle]="{
																				'background-color': stringToColor(
																					currentUser?.first_name +
																						'' +
																						currentUser?.last_name
																				)
																			}"
																		>
																			{{
																				currentUser?.first_name +
																					"" +
																					currentUser?.last_name
																					| initials
																					| slice: 0:2
																			}}
																		</div>
																	</div>
																	<div class="media-body">
																		<h5 class="mb-0">
																			{{ "UI.k_self_assign" | translate }}
																		</h5>
																		<small class="text-muted">{{
																			currentUser?.email
																		}}</small>
																	</div>
																</div>
															</div>
														</ng-template>
													</ng-select>
												</div>
											</div>
										</ng-container>
										<ng-container *ngIf="column?.type == 'select-template-status'">
											<div class="dns-panel-body-sec br-left">
												<div class="panel-data-block">
													<div class="panel-data-title sm-font">
														{{ column?.label | translate }}
													</div>
													<app-status-picker
														[class]="sm - select - feature"
														[inputConfig]="{
															isTextMode: true,
															hideLabel: true,
															stateOptionsMap: options?.state_id_map,
															id: idx,
															isEditDisabled: column?.disabled(column, element)
														}"
														[options]="element?.['dropdown_options']?.[column.optionKey]"
														(setStatus)="buildOnCallBack($event, column, element)"
														[value]="element?.[column.key]?.id"
														[showLabel]="false"
														class="sm-select-feature"
													></app-status-picker>
												</div>
											</div>
										</ng-container>
										<ng-container *ngIf="column?.type == 'badge_select'">
											<div class="dns-panel-body-sec br-left">
												<div class="panel-data-block">
													<div class="panel-data-title sm-font">
														{{ column.label | translate }}
													</div>
													<app-select-badge
														class="sm-select-feature"
														[inputConfig]="{
                                                        hideLabel: true,
                                                        inputOptions: options[column.optionKey],
                                                        id: 'sel' + column.key,
                                                        model: element?.[column?.key],
                                                        clearable:false,
                                                        isEditDisabled: column?.disabled(column, element)
                                                    }"
														(onValueChange)="statusBadgeChange($event, column, element)"
													></app-select-badge>
												</div>
											</div>
										</ng-container>
										<ng-container *ngIf="column?.type == 'select'">
											<div class="dns-panel-body-sec br-left">
												<div class="panel-data-block">
													<div class="panel-data-title sm-font">
														{{ column.label | translate }}
													</div>
													<ng-select
														[items]="options?.[column.optionKey]"
														placeholder="{{ 'UI.k_select' | translate }} {{
															column.label | translate
														}}"
														[hideSelected]="true"
														class="sm-form-control form-control assign-user-dropdown"
														[clearable]="false"
														[bindLabel]="column?.bind_label"
														[bindValue]="column?.bind_value"
														id="sel{{ column.key }}-{{ idx }}"
														name="sel{{ column.key }}-{{ idx }}"
														[(ngModel)]="element[column.key]"
														(change)="buildOnCallBack($event, column, element)"
														[disabled]="column?.disabled(column, element)"
														appendTo="body"
														attr.data-testid="sel{{ column.key }}"
													>
													</ng-select>
												</div>
											</div>
										</ng-container>
										<ng-container *ngIf="column?.type == 'date'">
											<div class="dns-panel-body-sec br-left">
												<div class="panel-data-block">
													<div class="panel-data-title sm-font">
														{{ column.label | translate }}
													</div>
													<!-- remove mt-10 class after inline edit -->
													<div
														class="panel-edit-select-box releaseDueDate title-color sm-font"
														id="div__{{ column?.key }}-{{ idx }}"
														attr.data-testid="div__{{ column?.key }}"
													>
														<span>
															{{ element?.[column.key] | translate}}
														</span>
														<div class="input-group custom-input-group2">
															<!-- <div class="input-group-prepend">
                                                                <span class="input-group-text">
                                                                    <i class="fa-light fa-calendar-clock"></i>
                                                                </span>
                                                            </div> -->
															<!-- <ng2-flatpickr
                                                                [config]="dateOptions"
                                                                id="{{column.key}}-{{idx}}"
                                                                name="{{column.key}}-{{idx}}"
                                                                [setDate]="element[column.key]"
                                                                [(ngModel)]="element[column.key]"
                                                                (change)="buildOnCallBack($event)"
                                                        ></ng2-flatpickr> -->
														</div>
													</div>
												</div>
											</div>
										</ng-container>
										<ng-container *ngIf="column?.type == 'controlled_actions'">
											<div
												class="dns-panel-body-sec br-left bt-flex"
												*ngIf="checkControlledActions(element, column?.action_list)"
											>
												<div class="panel-data-block">
													<ng-container *ngFor="let action of column?.action_list">
														<button
															tooltipclass="fadeInAnimation"
															*ngIf="action?.enable(action, element)"
															id="{{ action?.key }}-{{ idx }}"
															name="{{ action?.key }}-{{ idx }}"
															class="btn btn-primary waves-effect waves-float waves-light"
															style="padding: 0.5rem; font-size: 10px"
															(click)="action?.callback(element)"
															attr.data-testid="btn__{{ action?.key }}"
														>
															<span class="d-none d-sm-inline-block">
																{{ action?.label | translate }}
															</span>
														</button>
													</ng-container>
												</div>
											</div>
										</ng-container>
										<ng-container *ngIf="column?.type == 'actions'">
											<div class="dns-panel-body-sec border-left">
												<div class="action-btn-list panel-data-block">
													<ng-container *ngFor="let action of column?.action_list">
														<button
															*ngIf="
																permissions[
																	action?.permissionKey
																		? action.permissionKey
																		: action.key
																]
															"
															type="button"
															class="btn btn-sm btn-icon btn-outline-tertiary"
															rippleEffect
															(click)="action.callback(element)"
															tooltipClass="fadeInAnimation"
															ngbTooltip="{{ action?.tooltip | translate }}"
															placement="bottom"
															id="btn{{ action?.key }}-{{ idx }}"
															attr.data-testid="btn__{{ action?.key }}"
														>
															<i
																class="fa-light {{ action?.icon_name }} {{
																	action?.class
																}}"
															></i>
														</button>

														<!-- <span class="p-icon"
                                                        tooltipClass="fadeInAnimation"
                                                        ngbTooltip="{{action?.tooltip | translate}}"
                                                        placement="bottom"
                                                        (click)="action?.callback(element)"
                                                        >
                                                            <i class="fa-light {{action?.icon_name}} {{ action?.class}}"></i>
                                                        </span> -->
													</ng-container>
												</div>
												<!-- <span class="p-icon"
                                                    tooltipClass="fadeInAnimation"
                                                    ngbTooltip="More"
                                                    placement="bottom"
                                                    >
                                                    <i class="fa-light fa-ellipsis-vertical text-primary"></i>
                                                </span> -->
											</div>
										</ng-container>
									</ng-container>
								</ng-container>
							</div>
						</ng-container>
					</div>
				</ng-container>
			</div>
		</ng-scrollbar>
	</div>
	<div class="card-pagination custome-style" *ngIf="panelData?.results?.length > 0">
		<div class="pagination-inner">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl-0 pr-0">
				<dns-pagination
					[totalCount]="panelData?.count"
					[currentCount]="panelData?.results?.length"
					(setPage)="paginate($event)"
					(setLimit)="setLimitGrid($event)"
					[page]="page"
					[pageSize]="size"
				></dns-pagination>
			</div>
		</div>
	</div>
</ng-container>
