<div class="d-flex align-items-center">
    <div class="text-break">
        <a [href]="getUrl(data)"
            [target]="'_self'">
            <div class="mb-0 text-body">
                <span>{{ data?.organization_name?.name }}</span>
            </div>
        </a>
    </div>
</div>
