import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

// antd components module
import { DnsModule } from "./common/dns/dns.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CoreCommonModule } from "@core/common.module";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { CoreCardModule } from "../@core/components/core-card/core-card.module";
import { ChartsModule } from "ng2-charts";
import { NgSelectModule } from "@ng-select/ng-select";
import { Ng2FlatpickrModule } from "ng2-flatpickr";
import { CoreSidebarModule } from "../@core/components";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { QuillModule } from "ngx-quill";
import { DragulaModule } from "ng2-dragula";
import { FileUploadModule } from "ng2-file-upload";
import { BreadcrumbModule } from "./common/layout/components/content-header/breadcrumb/breadcrumb.module";
import { ImgUploadFeatureModule } from "./common/dns/components/img-upload-feature/img-upload-feature.module";
// import { ProfileAvatarModule } from './common/dns/components/profile-avatar/profile-avatar.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CsvUploadFeatureModule } from "./common/dns/components/csv-uploader/csv-upload-feature.module";
import { CustomThemeModule } from "./common/dns/components/custom-theme/custom-theme.module";
import { NgScrollbarModule } from "ngx-scrollbar";
const MODULES = [
	CommonModule,
	RouterModule,
	TranslateModule,
	DnsModule,
	NgbModule,
	CoreCommonModule,
	NgxDatatableModule,
	CoreCardModule,
	ChartsModule,
	NgSelectModule,
	Ng2FlatpickrModule,
	CoreSidebarModule,
	PerfectScrollbarModule,
	DragulaModule,
	FileUploadModule,
	BreadcrumbModule,
	ImgUploadFeatureModule,
	//ProfileAvatarModule,
	NgxSkeletonLoaderModule,
	CsvUploadFeatureModule,
	CustomThemeModule,
	QuillModule,
	NgScrollbarModule
];

@NgModule({
	imports: [...MODULES],
	declarations: [],
	exports: [...MODULES],
})
export class SharedModule {}
