import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TopAppsMenuComponent } from "./components/top-apps-menu/top-apps-menu.component";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { DnsClickDirective } from "./directives/dns-click.directive";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DnsGridComponent } from "./components/dns-grid/dns-grid.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { CoreDirectivesModule } from "../../../@core/directives/directives";
import { NgSelectModule } from "@ng-select/ng-select";
import {
	NgbCollapseModule,
	NgbNavModule,
	NgbPopoverModule,
	NgbTooltipModule,
	NgbAlertModule,
	NgbModule,
	NgbAccordionModule,
} from "@ng-bootstrap/ng-bootstrap";
import { DnsWorkflowComponent } from "./components/dns-workflow/dns-workflow.component";
import { CoreSidebarModule } from "../../../@core/components";
import { CorePipesModule } from "@core/pipes/pipes.module";
import { DnsSidebarComponent } from "./components/dns-sidebar/dns-sidebar.component";
import { StatusConfigComponent } from "./components/status-config/status-config.component";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { WhiteSpaceValidator } from "./validators/whitespace/whitespace.directive";
import { EmailValidator } from "./validators/multiemail/multiemail.directive";
import { NumberValidator } from "./validators/multinumber/multinumber.directive";
import { PhoneValidator } from "./validators/multiphone/multiphone.directive";
import { ComponentLoaderDirective } from "./directives/component-loader.directive";
import { GridCellCardComponent } from "./components/grid-cell-card/grid-cell-card.component";
import { QuillModule } from "ngx-quill";
import { DynamicDropdownComponent } from "./components/dynamic-dropdown/dynamic-dropdown.component";
import { WorkflowConditionsComponent } from "./components/workflow-conditions/workflow-conditions.component";
import { DnsMacroSelectorComponent } from "./components/dns-macro-selector/dns-macro-selector.component";
import { DnsValueSelectorComponent } from "./components/dns-value-selector/dns-value-selector.component";
import { DnsCustomFieldComponent } from "./components/dns-custom-field/dns-custom-field.component";
import { DnsSmsConfigComponent } from "./components/dns-sms-config/dns-sms-config.component";
import { GridNameDesignComponent } from "./components/grid-name-design/grid-name-design.component";
import { GridNameAvatarComponent } from "./components/grid-name-avatar/grid-name-avatar.component";
import { DnsIconViewComponent } from "./components/dns-icon-view/dns-icon-view.component";
import { DnsRatingComponent } from "./components/dns-rating/dns-rating.component";
import { DnsTagsComponent } from "./components/dns-tags/dns-tags.component";
import { DateRangePickerComponent } from "./components/date-range-picker/date-range-picker.component";
import { BurgerMenuComponent } from "./components/burger-menu/burger-menu.component";
import { FilterBarComponent } from "./components/filter-bar/filter-bar.component";
import { CustomCategoryListComponent } from "./components/custom-category-list/custom-category-list.component";
import { DnsEmptyGridDataComponent } from "./components/dns-empty-grid-data/dns-empty-grid-data.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { HistoryTableComponent } from "./components/history-table/history-table.component";
import { PresentationComponent } from "./components/presentation/presentation.component";
import { AssigneeComponent } from "./components/assignee/assignee.component";
import { SearchComponent } from "./components/search/search.component";
import { Ng2FlatpickrModule } from "ng2-flatpickr";
import { DateTimePipe } from "./pipes/date-time.pipe";
import { DnsHelpComponent } from "./components/dns-help/dns-help.component";
import { CardSkeletonComponent } from "./components/card-skeleton/card-skeleton.component";
import { GridSkeletonComponent } from "./components/grid-skeleton/grid-skeleton.component";
import { ReadMoreDirective } from "./directives/read-more.directive";
import { DnsPaginationComponent } from "./components/dns-pagination/dns-pagination.component";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { CardSelectComponent } from "./components/card-select/card-select.component";
import { EmailFeatureComponent } from "./components/email-feature/email-feature.component";
import { AddEscalationComponent } from "./components/add-escalation/add-escalation.component";
import { EnterSubmitDirective } from "./directives/enter-submit.directive";
import { SmsComponent } from "./components/sms/sms.component";
import { TreeCrudComponent } from "./components/tree-crud/tree-crud.component";
import { TreeModule } from "@circlon/angular-tree-component";
import { TreeChildComponent } from "./components/tree-crud/tree-child/tree-child.component";
import { NgScrollbarModule } from "ngx-scrollbar";
import { StatusPickerComponent } from "./components/status-picker/status-picker.component";
import { UserPickerComponent } from "./components/user-picker/user-picker.component";
import { QuillValidatorDirective } from "./validators/quill-validator.directive";
import { DnsSmartGridComponent } from "./components/dns-smart-grid/dns-smart-grid.component";
import { MatTableModule } from "@angular/material/table";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatPaginatorModule } from "@angular/material/paginator";
import { FileUploadModule } from "ng2-file-upload";
import { TruncatePipeComponent } from "./components/truncate-pipe/truncate-pipe.component";
import { MatSortModule } from "@angular/material/sort";
import { MatInputModule } from "@angular/material/input";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { DndModule } from "ngx-drag-drop";
import { UrlValidatorDirective } from "./validators/url-validator";
import { StageRepresentationComponent } from "./components/stage-representation/stage-representation.component";
import { IncidentSummaryComponent } from "./components/incident-summary/incident-summary.component";
import { PanelViewWidgetComponent } from "./components/panel-view-widget/panel-view-widget.component";
import { DeviceSummaryComponent } from "./components/device-summary/device-summary.component";
import { TreeDuplicateValidatorDirective } from "./validators/tree-duplicate-validator/tree-duplicate-validators.directive";
import { SimpleTableComponent } from "./components/simple-table/panel-view-widget/simple-table.component";
import { SummaryCardComponent } from "./components/summary-card/summary-card.component";
import { StatusBadgeComponent } from "./components/status-badge/status-badge.component";
import { SelectBadgeComponent } from "./components/select-badge/select-badge.component";
import { NgxDropdownTreeviewModule } from "./components/ngx-dropdown-treeview-select/ngx-dropdown-treeview.module";
// import { StageRepresentationComponent } from './components/stage-representation/stage-representation.component';
import { DuplicateLabelValidatorDirective } from "./validators/duplicate-label/duplicate-label-validator";
import { MinMaxValidatorDirective } from "./validators/min-max/min-max-validator";
import { ColorCircleModule } from "ngx-color/circle";
import { ColorChromeModule } from "ngx-color/chrome";
import { SwatchModule } from "ngx-color";
import { DnsErrorValidationComponent } from "./components/dns-error-validation/dns-error-validation.component";

import { MacrosModalComponent } from "./components/macros-modal/macros-modal.component";
import { DnsOptionPickerComponent } from "./components/dns-option-picker/dns-option-picker.component";
import { SubmitTicketSidebarComponent } from "./components/submit-ticket-sidebar/submit-ticket-sidebar.component";
import { PrettySelectComponent } from "./components/pretty-select/pretty-select.component";
import { CompareDirective } from "./validators/compare/compare.directive";
import { ExpectedAssingeeSidebarComponent } from "./components/expected-assingee-sidebar/expected-assingee-sidebar.component";
import { AttachKnowledgeBaseComponent } from "./components/attach-knowledge-base/attach-knowledge-base.component";
import { ServiceChangeComponent } from "./components/service-change/service-change.component";
import { DnsServiceFormComponent } from "./components/dns-service-form/dns-service-form.component";
import { CommonPannelViewComponent } from "./components/common-pannel-view/common-pannel-view.component";
import { OpenLayersComponent } from "./components/open-layers/open-layers.component";
import { RequestProcessCountComponent } from "./components/request-process-count-card/request-process-count.component";
import { RequestProcessSummaryComponent } from "./components/request-process-summary/request-process-summary.component";
import { BulkTagComponent } from "./components/bulk-tag/bulk-tag.component";
import { EventSummaryComponent } from "./components/event-summary/event-summary.component";
import { EventCountComponent } from "./components/event-count/event-count.component";
import { SlaMetricCardComponent } from "./components/sla-metric-card/sla-metric-card.component";
import { SlaMetricDetailSidebarComponent } from "./components/sla-metric-detail-sidebar/sla-metric-detail-sidebar.component";
import { CoreCardModule } from "../../../@core/components/core-card/core-card.module";
import { SlaBusinessCardComponent } from "./components/sla-business-card/sla-business-card.component";
import { ProblemCountComponent } from "./components/problem-count/problem-count.component";
import { ProblemSummaryComponent } from "./components/problem-summary/problem-summary.component";
import { DnsTableCountComponent } from "./components/dns-table-count/dns-table-count.component";
import { StatisticPanelComponent } from "./components/statistic-panel/statistic-panel.component";
import { ChangeSummaryComponent } from "./components/change-summary/change-summary.component";
import { ChangeCountComponent } from "./components/change-count/change-count.component";
import { NgApexchartsModule } from "ng-apexcharts";
import { DnsProgressTimerComponentComponent } from "./components/dns-progress-timer-component/dns-progress-timer-component.component";
import { CloseTicketSidebarComponent } from "./components/close-ticket-sidebar/close-ticket-sidebar.component";
import { NetworkDiagramWidgetComponent } from "./components/network-diagram-widget/network-diagram-widget.component";
import { DnsChangeViewComponent } from "./components/dns-change-view/dns-change-view.component";
import { DnsPanelViewComponent } from "./components/dns-panel-view/dns-panel-view.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { UpdateCustomDropDownComponent } from "./components/update-custom-drop-down/update-custom-drop-down.component";
import { SelectTeamComponent } from "./components/select-team/select-team.component";
import { RadioButtonProgressbarComponent } from "./components/radio-button-progressbar/radio-button-progressbar.component";
import { RoundChartCardComponent } from "./components/round-chart-card/round-chart-card.component";
import { NgxEchartsModule } from "ngx-echarts";
import { KbSolutionViewComponent } from "./components/kb-solution-view/kb-solution-view.component";
import { PriorityChangeComponent } from "./components/priority-change/priority-change.component";
import { TimelineGraphComponent } from "./components/timeline-graph/timeline-graph.component";
import { DownloadProgressButtonComponent } from "./components/download-progress-button/download-progress-button.component";
import { ProgressButtonModule } from "progress-button";
import { SlaResponseCountComponent } from "./components/sla-response-count/sla-response-count.component";
import { ReleaseSummaryComponent } from "./components/release-summary/release-summary.component";
import { ReleaseCountComponent } from "./components/release-count/release-count.component";
import { MicroChartComponent } from "./components/micro-chart/micro-chart.component";
import { CommonViewTaskComponent } from "./components/common-view-task/common-view-task.component";
import { AssociateRelationComponent } from "./components/associate-relation/associate-relation.component";
import { ProcessRelationComponent } from "./components/process-relations/process-relations.component";
import { DnsSlaTimerComponent } from "./components/dns-sla-timer/dns-sla-timer.component";
import { DnsGridTagComponent } from "./components/dns-grid-tag/dns-grid-tag.component";
import { CommonProblemViewComponent } from "./components/common-problem-view/common-problem-view.component";
import { DnsCommunicationComponent } from "./components/dns-communication/dns-communication.component";
import { TreeDropdownWithIconComponent } from "./components/tree-dropdown-with-icon/tree-dropdown-with-icon.component";
import { QuickResponseTemplateComponent } from "app/base/response-email-template/components/quick-response-template/quick-response-template.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { AddResponseEmailTemplateComponent } from "./components/add-response-email-template/add-response-email-template.component";
import { TreeviewModule } from "ngx-treeview";

import { LocationDesignComponent } from "./components/location-design/location-design.component";
import { ApprovalConfigComponent } from "./components/approval-config/approval-config.component";
import { PannelViewSkeletonComponent } from "./components/pannel-view-skeleton/pannel-view-skeleton.component";
import { RiskTabComponent } from "./components/risk-tab/risk-tab.component";
import { TaskTabComponent } from "./components/task-tab/task-tab.component";
import { PlanningTabComponent } from "./components/planning-tab/planning-tab.component";
import { AnalysisSubmitionComponent } from "app/servicedesk/change-management/component/analysis-submition/analysis-submition.component";
import { PanelStatusWidgetComponent } from "./components/panel-status-widget/panel-status-widget.component";
import { DnsThresholdColorSelectorComponent } from "./components/dns-threshold-color-selector/dns-threshold-color-selector.component";
import { DnsColorDropdownComponent } from "./components/dns-color-dropdown/dns-color-dropdown.component";
import { GettingStartedSidebarComponent } from "./components/getting-started-sidebar/getting-started-sidebar.component";
import { DnsGridPipeComponent } from "./components/dns-grid-pipe/dns-grid-pipe.component";
import { PanelCardViewComponent } from "./components/panel-card-view/panel-card-view.component";
import { DnsIncidentViewSidebarComponent } from "./components/dns-incident-view-sidebar/dns-incident-view-sidebar.component";
import { DnsStageRepresentationComponent } from "./components/dns-stage-representation/dns-stage-representation.component";
import { DnsResolveStatusComponent } from "./components/dns-resolve-status/dns-resolve-status.component";
import { DnsIncidentKbSolutionComponent } from "./components/dns-incident-kb-solution/dns-incident-kb-solution.component";
import { DnsKbAddComponent } from "./components/dns-kb-add/dns-kb-add.component";
import { ImgUploadFeatureModule } from "./components/img-upload-feature/img-upload-feature.module";
import { DnsSlaInfoCardComponent } from "../../common/dns/components/dns-sla-info-card/dns-sla-info-card.component";
import { DnsIncidentToRequestComponent } from "./components/dns-incident-to-request/dns-incident-to-request.component";
import { DnsIncidentRequestViewComponent } from "./components/dns-incident-request-view/dns-incident-request-view.component";
import { DnsIncidentToChangeComponent } from "./components/dns-incident-to-change/dns-incident-to-change.component";
import { DnsIncidentToProblemComponent } from "./components/dns-incident-to-problem/dns-incident-to-problem.component";
import { DnsAttachmentComponent } from "./components/dns-attachment/dns-attachment.component";
import { DnsEditRequesterComponent } from "./components/dns-edit-requester/dns-edit-requester.component";
import { ProfileAvatarModule } from "./components/profile-avatar/profile-avatar.module";
import { DnsIncidentEditSidebarComponent } from "./components/dns-incident-edit-sidebar/dns-incident-edit-sidebar.component";
import { CommonPanelCardSkeletonComponent } from "./components/common-panel-card-skeleton/common-panel-card-skeleton.component";
import { DNSAddTaskComponent } from "./components/dns-add-task/dns-add-task.component";
import { CommonProcessViewSidebarComponent } from "./components/common-process-view-sidebar/common-process-view-sidebar.component";
import { DnsRequestAttachmentComponent } from "./components/dns-request-attachment/dns-request-attachment.component";
import { DnsRequestResolveComponent } from "./components/dns-request-resolve/dns-request-resolve.component";
import { DnsRequestCloseComponent } from "./components/dns-request-close/dns-request-close.component";
import { DnsRequestToChangeComponent } from "./components/dns-request-to-change/dns-request-to-change.component";
import { DnsRequestToIncidentComponent } from "./components/dns-request-to-incident/dns-request-to-incident.component";
import { DnsRequestIncidentViewComponent } from "./components/dns-request-incident-view/dns-request-incident-view.component";
import { DnsRequestEditSidebarComponent } from "./components/dns-request-edit-sidebar/dns-request-edit-sidebar.component";
import { DnsProblemEditSidebarComponent } from "./components/dns-problem-edit-sidebar/dns-problem-edit-sidebar.component";
import { DnsProblemAttachmentComponent } from "./components/dns-problem-attachment/dns-problem-attachment.component";
import { DnsProblemResolveComponent } from "./components/dns-problem-resolve/dns-problem-resolve.component";
import { DnsProblemCloseComponent } from "./components/dns-problem-close/dns-problem-close.component";
import { DnsProblemToChangeComponent } from "./components/dns-problem-to-change/dns-problem-to-change.component";
import { DnsProblemToIncidentComponent } from "./components/dns-problem-to-incident/dns-problem-to-incident.component";
import { DnsProblemIncidentViewComponent } from "./components/dns-problem-incident-view/dns-problem-incident-view.component";
import { DnsProblemAnalysisSidebarComponent } from "./components/dns-problem-analysis-sidebar/dns-problem-analysis-sidebar.component";
import { DnsProblemSolutionSidebarComponent } from "./components/dns-problem-solution-sidebar/dns-problem-solution-sidebar.component";
import { DnsProblemViewDraftSidebarComponent } from "./components/dns-problem-view-draft-sidebar/dns-problem-view-draft-sidebar.component";
import { DnsChangeAttachmentComponent } from "./components/dns-change-attachment/dns-change-attachment.component";
import { DnsChangeIncidentViewComponent } from "./components/dns-change-incident-view/dns-change-incident-view.component";
import { DnsChangeToIncidentComponent } from "./components/dns-change-to-incident/dns-change-to-incident.component";
import { DnsChangeImpactComponent } from "./components/dns-change-impact/dns-change-impact.component";
import { DnsChangeRiskComponent } from "./components/dns-change-risk/dns-change-risk.component";
import { DnsCreateTaskComponent } from "./components/dns-create-task/dns-create-task.component";
import { DnsViewTaskComponent } from "./components/dns-view-task/dns-view-task.component";
import { DnsChangeEditSidebarComponent } from "./components/dns-change-edit-sidebar/dns-change-edit-sidebar.component";
import { DnsReleaseEditSidebarComponent } from "./components/dns-release-edit-sidebar/dns-release-edit-sidebar.component";
import { DnsReleaseAddTaskComponent } from "./components/dns-release-add-task/dns-release-add-task.component";
import { DnsReleaseViewTaskComponent } from "./components/dns-release-view-task/dns-release-view-task.component";
import { DnsReleaseRelatedTaskComponent } from "./components/dns-release-related-task/dns-release-related-task.component";
import { DnsReleaseAttachmentComponent } from "./components/dns-release-attachment/dns-release-attachment.component";
import { DnsReleaseCloseComponent } from "./components/dns-release-close/dns-release-close.component";
import { DnsChangeCloseComponent } from "./components/dns-change-close/dns-change-close.component";
import { DnsAssetSelectComponent } from "./components/dns-asset-select/dns-asset-select.component";
import { SortComponent } from "./components/sort/sort.component";
import { DnsAgingTimerComponent } from "./components/dns-aging-timer/dns-aging-timer.component";
import { AIKBComponent } from "./components/ai-based-kb/ai-based-kb.component";
import { EmojiComponent } from "@ctrl/ngx-emoji-mart/ngx-emoji";
import { ContractSummaryWidgetComponent } from "./components/contract-summary-widget/contract-summary-widget.component";
import { InfiAttachmentComponent } from "./components/infi-attachment/infi-attachment.component";
import { ConsumableSummaryWidgetComponent } from './components/consumable-summary-widget/consumable-summary-widget.component';
import { WorkflowSkeletonComponent } from './components/workflow-skeleton/workflow-skeleton.component';
import { FeedbackRaitingPopoverComponent } from './components/feedback-raiting-popover/feedback-raiting-popover.component';
import { ImacdSummaryWidgetComponent } from './components/imacd-summary-widget/imacd-summary-widget.component';
import { ImacdCountComponent } from './components/imacd-count/imacd-count.component';
import { RequesterCallingModalComponent } from './components/requester-calling-modal/requester-calling-modal.component';
import { InfraonCallComponent } from "./components/infraon-call/infraon-call.component";
import { DnsMspCustomerDropdownComponent } from "./components/dns-msp-customer-dropdown/dns-msp-customer-dropdown.component";
import { GridOrganizationNameDesignComponent } from './components/grid-organization-name-design/grid-organization-name-design.component';
import { InventoryCountComponent } from './components/inventory-count/inventory-count.component';
import { MaskEmailPipe } from "./pipes/masking-requester-info.pipe";
import { DnsDrilldownTableComponent } from './components/dns-drilldown-table/dns-drilldown-table.component';
import { AssetDrilldownSummaryWidgetComponent } from './components/asset-drilldown-summary-widget/asset-drilldown-summary-widget.component';
import { RequestSlaResponseCountComponent } from "./components/request-sla-response-count/request-sla-response-count.component";
const COMPONENTS = [
	TopAppsMenuComponent,
	DnsClickDirective,
	DnsGridComponent,
	ComponentLoaderDirective,
	DnsWorkflowComponent,
	DnsSidebarComponent,
	StatusConfigComponent,
	WhiteSpaceValidator,
	EmailValidator,
	NumberValidator,
	WorkflowConditionsComponent,
	DnsMacroSelectorComponent,
	DnsValueSelectorComponent,
	GridCellCardComponent,
	DnsSmsConfigComponent,
	GridNameDesignComponent,
	GridNameAvatarComponent,
	DateRangePickerComponent,
	DynamicDropdownComponent,
	BurgerMenuComponent,
	FilterBarComponent,
	CustomCategoryListComponent,
	HistoryTableComponent,
	PresentationComponent,
	AssigneeComponent,
	SearchComponent,
	DnsHelpComponent,
	ReadMoreDirective,
	DnsPaginationComponent,
	CardSelectComponent,
	EmailFeatureComponent,
	AddEscalationComponent,
	EnterSubmitDirective,
	SmsComponent,
	TreeCrudComponent,
	TreeChildComponent,
	StatusPickerComponent,
	PhoneValidator,
	UserPickerComponent,
	QuillValidatorDirective,
	DnsSmartGridComponent,
	TruncatePipeComponent,
	UrlValidatorDirective,
	StageRepresentationComponent,
	IncidentSummaryComponent,
	PanelViewWidgetComponent,
	DeviceSummaryComponent,
	TreeDuplicateValidatorDirective,
	SimpleTableComponent,
	StatusBadgeComponent,
	DuplicateLabelValidatorDirective,
	MinMaxValidatorDirective,
	SummaryCardComponent,
	StatusBadgeComponent,
	SelectBadgeComponent,
	MacrosModalComponent,
	DnsErrorValidationComponent,
	DnsOptionPickerComponent,
	SubmitTicketSidebarComponent,
	PrettySelectComponent,
	CompareDirective,
	ExpectedAssingeeSidebarComponent,
	AttachKnowledgeBaseComponent,
	ServiceChangeComponent,
	DnsServiceFormComponent,
	CommonPannelViewComponent,
	OpenLayersComponent,
	RequestProcessCountComponent,
	RequestProcessSummaryComponent,
	BulkTagComponent,
	EventSummaryComponent,
	EventCountComponent,
	ProblemCountComponent,
	ProblemSummaryComponent,
	DnsTableCountComponent,
	StatisticPanelComponent,
	ChangeSummaryComponent,
	ChangeCountComponent,
	CloseTicketSidebarComponent,
	NetworkDiagramWidgetComponent,
	DnsPanelViewComponent,
	UpdateCustomDropDownComponent,
	DnsTableCountComponent,
	SelectTeamComponent,
	RadioButtonProgressbarComponent,
	DnsChangeViewComponent,
	KbSolutionViewComponent,
	PriorityChangeComponent,
	SlaResponseCountComponent,
	ReleaseSummaryComponent,
	ReleaseCountComponent,
	CommonViewTaskComponent,
	AssociateRelationComponent,
	ProcessRelationComponent,
	CommonProblemViewComponent,
	AddResponseEmailTemplateComponent,
	LocationDesignComponent,
	ApprovalConfigComponent,
	AnalysisSubmitionComponent,
	PanelStatusWidgetComponent,
	DnsGridPipeComponent,
	DnsIncidentViewSidebarComponent,
	DnsStageRepresentationComponent,
	DnsResolveStatusComponent,
	DnsIncidentKbSolutionComponent,
	DnsKbAddComponent,
	DnsAttachmentComponent,
	DnsIncidentEditSidebarComponent,
	DNSAddTaskComponent,
	CommonProcessViewSidebarComponent,
	DnsEditRequesterComponent,
	DnsProblemResolveComponent,
	DnsProblemCloseComponent,
	DnsAgingTimerComponent,
	DnsAssetSelectComponent,
	AIKBComponent,
	ContractSummaryWidgetComponent,
	ConsumableSummaryWidgetComponent,
	ImacdSummaryWidgetComponent,
	ImacdCountComponent,
	DnsMspCustomerDropdownComponent,
	RequestSlaResponseCountComponent
];

@NgModule({
	declarations: [
		...COMPONENTS,
		DnsCustomFieldComponent,
		// RequestAttachmentComponent,
		// RequestResolveComponent,
		// RequestCloseComponent,
		DnsIconViewComponent,
		DnsRatingComponent,
		DnsTagsComponent,
		DnsEmptyGridDataComponent,
		HistoryTableComponent,
		PresentationComponent,
		DateTimePipe,
		MaskEmailPipe,
		CardSkeletonComponent,
		GridSkeletonComponent,
		TreeChildComponent,
		IncidentSummaryComponent,
		SimpleTableComponent,
		SubmitTicketSidebarComponent,
		OpenLayersComponent,
		SlaMetricCardComponent,
		SlaMetricDetailSidebarComponent,
		SlaBusinessCardComponent,
		ChangeSummaryComponent,
		ChangeCountComponent,
		CloseTicketSidebarComponent,
		DnsProgressTimerComponentComponent,
		DnsProgressTimerComponentComponent,
		RoundChartCardComponent,
		TimelineGraphComponent,
		DownloadProgressButtonComponent,
		SlaResponseCountComponent,
		ReleaseSummaryComponent,
		ReleaseCountComponent,
		MicroChartComponent,
		DnsSlaTimerComponent,
		DnsGridTagComponent,
		DnsCommunicationComponent,
		TreeDropdownWithIconComponent,
		QuickResponseTemplateComponent,
		QuickResponseTemplateComponent,
		AddResponseEmailTemplateComponent,
		PannelViewSkeletonComponent,
		RiskTabComponent,
		TaskTabComponent,
		PlanningTabComponent,
		PanelStatusWidgetComponent,
		DnsThresholdColorSelectorComponent,
		DnsColorDropdownComponent,
		GettingStartedSidebarComponent,
		PanelCardViewComponent,
		DnsSlaInfoCardComponent,
		DnsIncidentToRequestComponent,
		DnsIncidentRequestViewComponent,
		DnsIncidentToChangeComponent,
		DnsIncidentToProblemComponent,
		CommonPanelCardSkeletonComponent,
		DnsRequestAttachmentComponent,
		DnsRequestResolveComponent,
		DnsRequestCloseComponent,
		DnsRequestToChangeComponent,
		DnsRequestToIncidentComponent,
		DnsRequestIncidentViewComponent,
		DnsRequestEditSidebarComponent,
		DnsProblemEditSidebarComponent,
		DnsProblemAttachmentComponent,
		DnsProblemToChangeComponent,
		DnsProblemToIncidentComponent,
		DnsProblemIncidentViewComponent,
		DnsProblemAnalysisSidebarComponent,
		DnsProblemSolutionSidebarComponent,
		DnsProblemViewDraftSidebarComponent,
		DnsChangeAttachmentComponent,
		DnsChangeIncidentViewComponent,
		DnsChangeToIncidentComponent,
		DnsChangeEditSidebarComponent,
		DnsChangeImpactComponent,
		DnsChangeRiskComponent,
		DnsCreateTaskComponent,
		DnsViewTaskComponent,
		DnsReleaseEditSidebarComponent,
		DnsReleaseAddTaskComponent,
		DnsReleaseViewTaskComponent,
		DnsReleaseRelatedTaskComponent,
		DnsReleaseAttachmentComponent,
		DnsReleaseCloseComponent,
		DnsChangeCloseComponent,
		SortComponent,
		ContractSummaryWidgetComponent,
		InfiAttachmentComponent,
		ConsumableSummaryWidgetComponent,
  		WorkflowSkeletonComponent,
  		FeedbackRaitingPopoverComponent,
    	ImacdSummaryWidgetComponent,
     	ImacdCountComponent,
      	RequesterCallingModalComponent,
		InfraonCallComponent,
  		GridOrganizationNameDesignComponent,
    	InventoryCountComponent,
		DnsDrilldownTableComponent,
		AssetDrilldownSummaryWidgetComponent,
		RequestSlaResponseCountComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		NgxDatatableModule,
		CoreDirectivesModule,
		CoreSidebarModule,
		NgSelectModule,
		NgbTooltipModule,
		NgbNavModule,
		NgbAlertModule,
		NgbModule,
		NgbAccordionModule,
		CorePipesModule,
		PerfectScrollbarModule,
		FileUploadModule,
		QuillModule.forRoot(),
		NgbPopoverModule,
		NgbCollapseModule,
		NgxSkeletonLoaderModule,
		Ng2FlatpickrModule,
		NgxIntlTelInputModule,
		TreeModule,
		NgScrollbarModule,
		MatTableModule,
		MatSidenavModule,
		MatPaginatorModule,
		MatCheckboxModule,
		MatCardModule,
		MatInputModule,
		MatSortModule,
		DndModule,
		NgxDropdownTreeviewModule,
		ColorCircleModule,
		ColorChromeModule,
		SwatchModule,
		CoreCardModule,
		NgApexchartsModule,
		NgxEchartsModule,
		ProgressButtonModule,
		Ng2SearchPipeModule,
		InfiniteScrollModule,
		ImgUploadFeatureModule,
		ProfileAvatarModule,
		TreeviewModule.forRoot(),
		EmojiComponent,
	],
	exports: [
		...COMPONENTS,
		DnsCustomFieldComponent,
		DnsEmptyGridDataComponent,
		GridSkeletonComponent,
		CardSkeletonComponent,
		SearchComponent,
		SubmitTicketSidebarComponent,
		CommonPannelViewComponent,
		SlaMetricCardComponent,
		SlaMetricDetailSidebarComponent,
		SlaBusinessCardComponent,
		CloseTicketSidebarComponent,
		DnsProgressTimerComponentComponent,
		RoundChartCardComponent,
		TimelineGraphComponent,
		DownloadProgressButtonComponent,
		MicroChartComponent,
		DnsSlaTimerComponent,
		DnsGridTagComponent,
		DnsCommunicationComponent,
		TreeDropdownWithIconComponent,
		PannelViewSkeletonComponent,
		RiskTabComponent,
		TaskTabComponent,
		PlanningTabComponent,
		DnsThresholdColorSelectorComponent,
		GettingStartedSidebarComponent,
		PanelCardViewComponent,
		DnsSlaInfoCardComponent,
		SortComponent,
		InfiAttachmentComponent,
		InfiAttachmentComponent,
		DnsResolveStatusComponent,
		FeedbackRaitingPopoverComponent,
		RequesterCallingModalComponent,
		InfraonCallComponent,
		GridOrganizationNameDesignComponent,
		InventoryCountComponent,
		DnsDrilldownTableComponent,
		AssetDrilldownSummaryWidgetComponent
	],
})
export class DnsModule {}
