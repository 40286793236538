import { Component, Input, OnInit } from '@angular/core';
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";

@Component({
  selector: 'app-dns-help',
  templateUrl: './dns-help.component.html',
  styleUrls: ['./dns-help.component.scss']
})
export class DnsHelpComponent implements OnInit {
    @Input() data: any;

    constructor(private _coreSidebarService: CoreSidebarService) { }

    ngOnInit(): void {
    }

    toggleSidebar = (name): void => {
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
    };


}
