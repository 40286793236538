import { WORKFLOW_BLOCK_TYPE, WORKFLOW_TEMPLATE_TYPE } from "../../../app/common/dns/types";
import { generate_uuid } from "../../../app/common/utils/utils";

var flowy = function (
	canvas,
	grab,
	release,
	snapping,
	rearrange,
	spacing_x,
	spacing_y,
	onAdd,
	wfGuidMap = {},
	isAttachable
) {
	if (!grab) {
		grab = function () {};
	}
	if (!release) {
		release = function () {};
	}
	if (!snapping) {
		snapping = function () {
			return true;
		};
	}
	if (!rearrange) {
		rearrange = function () {
			return false;
		};
	}

	if (!onAdd) {
		onAdd = function () {
			return false;
		};
	}

	if (!isAttachable) {
		isAttachable = function () {
			return true;
		};
	}

	if (!spacing_x) {
		spacing_x = 20;
	}
	if (!spacing_y) {
		spacing_y = 80;
	}
	if (!Element.prototype.matches) {
		Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
	}
	if (!Element.prototype.closest) {
		Element.prototype.closest = function (s) {
			var el = this;
			do {
				if (Element.prototype.matches.call(el, s)) return el;
				el = el.parentElement || el.parentNode;
			} while (el !== null && el.nodeType === 1);
			return null;
		};
	}
	var loaded = false;
	flowy.load = function () {
		if (!loaded) loaded = true;
		else return;
		var blocks = [];
		var blockstemp = [];
		var canvas_div = canvas;
		var absx = 0;
		var absy = 0;
		if (
			window.getComputedStyle(canvas_div).position == "absolute" ||
			window.getComputedStyle(canvas_div).position == "fixed"
		) {
			absx = canvas_div.getBoundingClientRect().left;
			absy = canvas_div.getBoundingClientRect().top;
		}
		var active = false;
		var paddingx = spacing_x;
		var paddingy = spacing_y;
		var offsetleft = 0;
		var rearrange = false;
		var drag, dragx, dragy, original;
		var mouse_x, mouse_y;
		var dragblock = false;
		var prevblock = 0;
		var currentBlockId;
		var el = document.createElement("DIV");
		el.classList.add("indicator");
		el.classList.add("invisible");
		canvas_div.appendChild(el);
		var ele = document.createElement("DIV");
		ele.classList.add("indicator-danger");
		ele.classList.add("invisible");
		canvas_div.appendChild(ele);

		flowy.getParentBlockGuid = function (guid) {
			if (blocks && blocks.length > 0) {
				return blocks.filter((d) => d.id === guid)[0].parent;
			}
		};

		flowy.import = function (output) {
			if (output?.workflow_guid_data_map) {
				wfGuidMap = output?.workflow_guid_data_map;
			}
			canvas_div.innerHTML = output.html;
			for (var a = 0; a < output.blockarr.length; a++) {
				blocks.push({
					childwidth: parseFloat(output.blockarr[a].childwidth),
					parent: output.blockarr[a].parent,
					id: output.blockarr[a].id,
					x: parseFloat(output.blockarr[a].x),
					y: parseFloat(output.blockarr[a].y),
					width: parseFloat(output.blockarr[a].width),
					height: parseFloat(output.blockarr[a].height),
				});
			}
			if (blocks.length > 1) {
				// rearrangeMe();
				checkOffset();
			}
		};
		flowy.output = function () {
			var html_ser = canvas_div.innerHTML;
			var json_data = {
				html: html_ser,
				blockarr: blocks,
				blocks: [],
				flow: [],
				flow_id_seq: [],
			};
			if (blocks.length > 0) {
				for (var i = 0; i < blocks.length; i++) {
					var blockParent = document.querySelector(".blockid[value='" + blocks[i].id + "']").parentNode;
					var type = blockParent.querySelector(".blockelemtype[name='blockelemtype']").getAttribute("value");
					json_data.blocks.push({
						id: blocks[i].id,
						parent: blocks[i].parent,
						data: [],
						attr: [],
					});

					//preparing workflow data
					json_data.flow.push({
						flow_id: blocks[i].id,
						data: [],
						type: type,
						is_start: blocks[i].parent === -1,
						parent: blocks[i].parent,
						level: blocks[i].level,
						condition_block:
							type == WORKFLOW_BLOCK_TYPE.CONDITION_SUCCESS
								? 1
								: type == WORKFLOW_BLOCK_TYPE.CONDITION_FAILED
								? 0
								: -1,
						approval_block:
							type == WORKFLOW_BLOCK_TYPE.APPROVAL_ACCEPTED
								? 1
								: type == WORKFLOW_BLOCK_TYPE.APPROVAL_REJECTED
								? 0
								: -1,
						review_block:
							type == WORKFLOW_BLOCK_TYPE.REVIEW_ACCEPTED
								? 1
								: type == WORKFLOW_BLOCK_TYPE.REVIEW_REJECTED
								? 0
								: -1,
					});
					json_data.flow_id_seq.push(blocks[i].id);

					blockParent.querySelectorAll("input").forEach(function (block) {
						var json_name = block.getAttribute("name");
						var json_value = block.value;
						json_data.blocks[i].data.push({
							name: json_name,
							value: json_value,
						});
					});
					Array.prototype.slice.call(blockParent.attributes).forEach(function (attribute) {
						var jsonobj = {};
						jsonobj[attribute.name] = attribute.value;
						json_data.blocks[i].attr.push(jsonobj);
					});
				}
				return json_data;
			}
		};

		//return all block data
		flowy.getBlocksData = function () {
			return blocks;
		};

		//get individual block data
		function getBlockData(id) {
			if (wfGuidMap) {
				let blockData = wfGuidMap[id];
				return blockData;
			}
			return null;
		}

		flowy.deleteBlocks = function () {
			blocks = [];
			canvas_div.innerHTML =
				"<div class='indicator invisible'></div><div class='indicator-danger invisible'></div>";
		};

		flowy.beginDrag = function (event) {
			if (
				window.getComputedStyle(canvas_div).position == "absolute" ||
				window.getComputedStyle(canvas_div).position == "fixed"
			) {
				absx = canvas_div.getBoundingClientRect().left;
				absy = canvas_div.getBoundingClientRect().top;
			}
			if (event.targetTouches) {
				mouse_x = event.changedTouches[0].clientX;
				mouse_y = event.changedTouches[0].clientY;
			} else {
				mouse_x = event.clientX;
				mouse_y = event.clientY;
			}
			if (event.which != 3 && event.target.closest(".create-flowy")) {
				original = event.target.closest(".create-flowy");
				var newNode = event.target.closest(".create-flowy").cloneNode(true);
				event.target.closest(".create-flowy").classList.add("dragnow");
				newNode.classList.add("block");
				newNode.classList.remove("create-flowy");
				const id = generate_uuid("wf");
				newNode.innerHTML += `<input type="hidden" name="blockid" class="blockid" value="${id}">`;
				document.body.appendChild(newNode);
				drag = document.querySelector(".blockid[value='" + id + "']").parentNode;
				var btn = drag.querySelector(".blockyright");
				if (btn) {
					btn.setAttribute("blockid", id);
				}
				blockGrabbed(event.target.closest(".create-flowy"));
				drag.classList.add("dragging");
				active = true;
				dragx = mouse_x - event.target.closest(".create-flowy").getBoundingClientRect().left;
				dragy = mouse_y - event.target.closest(".create-flowy").getBoundingClientRect().top;
				drag.style.left = mouse_x - dragx + "px";
				drag.style.top = mouse_y - dragy + "px";
			}
		};

		flowy.getCurrentId = function () {
			return currentBlockId;
		};

		flowy.endDrag = function (event) {
			//console.log("endDrag =========================== wfGuidMap", wfGuidMap);
			if (event.which != 3 && (active || rearrange)) {
				dragblock = false;
				currentBlockId = getBlockId(drag);
				var indicatorBlock = document.querySelector(".indicator");
				var indicatorDangerBlock = document.querySelector(".indicator-danger");
				if (indicatorBlock && !indicatorBlock.classList.contains("invisible")) {
					indicatorBlock.classList.add("invisible");
				}
				if (indicatorDangerBlock && !indicatorDangerBlock.classList.contains("invisible")) {
					indicatorDangerBlock.classList.add("invisible");
				}
				if (active) {
					original.classList.remove("dragnow");
					drag.classList.remove("dragging");
					//removing disabled on release
					if (original.classList.contains("blockdisabled")) {
						original.classList.remove("blockdisabled");
					}
					if (drag.classList.contains("blockdisabled")) {
						drag.classList.remove("blockdisabled");
					}
				}
				blockReleased(drag);
				const blockData = getBlockData(currentBlockId);
				//console.log("endDrag ==================>", blockData, rearrange, currentBlockId);
				if (blockData?.is_first && rearrange) {
					firstBlock("rearrange");
				} else if (
					active &&
					blocks.length == 0 &&
					drag.getBoundingClientRect().top + window.scrollY >
						canvas_div.getBoundingClientRect().top + window.scrollY &&
					drag.getBoundingClientRect().left + window.scrollX >
						canvas_div.getBoundingClientRect().left + window.scrollX
				) {
					firstBlock("drop");
				} else if (active && blocks.length == 0) {
					removeSelection();
				} else if (active) {
					var blocko = blocks.map((a) => a.id);
					for (var i = 0; i < blocks.length; i++) {
						if (checkAttach(blocko[i])) {
							active = false;
							const tempParentBlock = document.querySelector(".blockid[value='" + blocko[i] + "']");
							//console.log("endDrag ===================== tempParentBlock", tempParentBlock, drag);
							if (blockSnap(drag, false, tempParentBlock?.parentNode)) {
								snap(drag, i, blocko);
								onAdd(drag);
							} else {
								active = false;
								removeSelection();
							}
							break;
						} else if (i == blocks.length - 1) {
							active = false;
							removeSelection();
						}
					}
				} else if (rearrange) {
					var blocko = blocks.map((a) => a.id);
					for (var i = 0; i < blocks.length; i++) {
						if (checkAttach(blocko[i])) {
							active = false;
							drag.classList.remove("dragging");
							snap(drag, i, blocko);
							break;
						} else if (i == blocks.length - 1) {
							if (beforeDelete(drag, blocks.filter((id) => id.id == blocko[i])[0])) {
								active = false;
								drag.classList.remove("dragging");
								snap(drag, blocko.indexOf(prevblock), blocko);
								break;
							} else {
								rearrange = false;
								blockstemp = [];
								active = false;
								removeSelection();
								break;
							}
						}
					}
				}
			}
		};

		function checkAttach(id) {
			let type = drag.querySelector(".blockelemtype").value;
			let dataExtra = drag.querySelector(".blockelemtype").getAttribute("data-extra");
			if (!dataExtra) {
				let draggedBlockId = drag.querySelector(".blockid").value;
				if (draggedBlockId) {
					dataExtra = wfGuidMap[draggedBlockId];
				}
			}
			const xpos =
				drag.getBoundingClientRect().left +
				window.scrollX +
				parseInt(window.getComputedStyle(drag).width) / 2 +
				canvas_div.scrollLeft -
				canvas_div.getBoundingClientRect().left;
			const ypos =
				drag.getBoundingClientRect().top +
				window.scrollY +
				canvas_div.scrollTop -
				canvas_div.getBoundingClientRect().top;
			if (
				checkAttachable(type, blocks.filter((a) => a.id == id)[0], dataExtra) &&
				xpos >=
					blocks.filter((a) => a.id == id)[0].x - blocks.filter((a) => a.id == id)[0].width / 2 - paddingx &&
				xpos <=
					blocks.filter((a) => a.id == id)[0].x + blocks.filter((a) => a.id == id)[0].width / 2 + paddingx &&
				ypos >= blocks.filter((a) => a.id == id)[0].y - blocks.filter((a) => a.id == id)[0].height / 2 &&
				ypos <= blocks.filter((a) => a.id == id)[0].y + blocks.filter((a) => a.id == id)[0].height
			) {
				return true;
			} else {
				showIndicator(id, "indicator-danger");
				return false;
			}
		}

		function checkAttachable(type, data, draggedData) {
			const blockData = wfGuidMap[data?.id];
			//console.log("checkAttachable", type, data, wfGuidMap[data?.id], draggedData);
			return isAttachable(blocks, blockData, type, draggedData);
		}

		function removeSelection() {
			const successIndicator = document.querySelector(".indicator");
			const failedIndicator = document.querySelector(".indicator-danger");
			if (canvas_div) {
				if (successIndicator) {
					canvas_div.appendChild(successIndicator);
				}
				if (failedIndicator) {
					canvas_div.appendChild(failedIndicator);
				}
			}

			drag.parentNode.removeChild(drag);
		}

		function firstBlock(type) {
			//console.log("firstBlock=====", type);
			if (type == "drop") {
				//console.log("firstBlock=====Drop", type);
				blockSnap(drag, true, undefined);
				active = false;
				drag.querySelector(".blockyright").innerHTML = "";
				drag.style.top =
					drag.getBoundingClientRect().top +
					window.scrollY -
					(absy + window.scrollY) +
					canvas_div.scrollTop +
					"px";
				drag.style.left =
					drag.getBoundingClientRect().left +
					window.scrollX -
					(absx + window.scrollX) +
					canvas_div.scrollLeft +
					"px";
				canvas_div.appendChild(drag);
				var tempBlockId = getBlockId(drag);
				let tempBlockData = {
					parent: -1,
					level: 0,
					childwidth: 0,
					is_first: true,
					id: tempBlockId,
					x:
						drag.getBoundingClientRect().left +
						window.scrollX +
						parseInt(window.getComputedStyle(drag).width) / 2 +
						canvas_div.scrollLeft -
						canvas_div.getBoundingClientRect().left,
					y:
						drag.getBoundingClientRect().top +
						window.scrollY +
						parseInt(window.getComputedStyle(drag).height) / 2 +
						canvas_div.scrollTop -
						canvas_div.getBoundingClientRect().top,
					width: parseInt(window.getComputedStyle(drag).width),
					height: parseInt(window.getComputedStyle(drag).height),
				};
				blocks.push(tempBlockData);
				if (checkAttach(tempBlockId)) {
					onAdd(drag);
				}
			} else if (type == "rearrange") {
				//console.log("firstBlock=====rearrange", type, blocks, "blockstemp=========>", blockstemp);
				drag.classList.remove("dragging");
				rearrange = false;
				for (var w = 0; w < blockstemp.length; w++) {
					//console.log("firstBlockFor", blockstemp[w]);
					const tempBlockTemp = blockstemp[w];
					if (tempBlockTemp.id != getBlockId(drag)) {
						const blockParent = document.querySelector(
							".blockid[value='" + tempBlockTemp.id + "']"
						).parentNode;
						const arrowParent = document.querySelector(
							".arrowid[value='" + tempBlockTemp.id + "']"
						).parentNode;
						blockParent.style.left =
							blockParent.getBoundingClientRect().left +
							window.scrollX -
							window.scrollX +
							canvas_div.scrollLeft -
							1 -
							absx +
							"px";
						blockParent.style.top =
							blockParent.getBoundingClientRect().top +
							window.scrollY -
							window.scrollY +
							canvas_div.scrollTop -
							absy -
							1 +
							"px";
						arrowParent.style.left =
							arrowParent.getBoundingClientRect().left +
							window.scrollX -
							window.scrollX +
							canvas_div.scrollLeft -
							absx -
							1 +
							"px";
						arrowParent.style.top =
							arrowParent.getBoundingClientRect().top +
							window.scrollY +
							canvas_div.scrollTop -
							1 -
							absy +
							"px";
						canvas_div.appendChild(blockParent);
						canvas_div.appendChild(arrowParent);
						blockstemp[w].x =
							blockParent.getBoundingClientRect().left +
							window.scrollX +
							parseInt(blockParent.offsetWidth) / 2 +
							canvas_div.scrollLeft -
							canvas_div.getBoundingClientRect().left -
							1;
						blockstemp[w].y =
							blockParent.getBoundingClientRect().top +
							window.scrollY +
							parseInt(blockParent.offsetHeight) / 2 +
							canvas_div.scrollTop -
							canvas_div.getBoundingClientRect().top -
							1;
					}
				}
				//gettting for block reference
				let tempBlockStemp = blockstemp.filter((a) => a?.is_first)[0];
				if (tempBlockStemp) {
					tempBlockStemp.x =
						drag.getBoundingClientRect().left +
						window.scrollX +
						parseInt(window.getComputedStyle(drag).width) / 2 +
						canvas_div.scrollLeft -
						canvas_div.getBoundingClientRect().left;
					tempBlockStemp.y =
						drag.getBoundingClientRect().top +
						window.scrollY +
						parseInt(window.getComputedStyle(drag).height) / 2 +
						canvas_div.scrollTop -
						canvas_div.getBoundingClientRect().top;
					blocks = blocks.concat(blockstemp);
				}
				blockstemp = [];
			}
		}

		function isArrowBlock(id) {}

		function drawArrow(arrow, x, y, id) {
			var tempBlockID = getBlockId(drag);
			if (x < 0) {
				canvas_div.innerHTML +=
					'<div class="arrowblock"><input type="hidden" class="arrowid" value="' +
					tempBlockID +
					'"><svg preserveaspectratio="none" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M' +
					(blocks.filter((a) => a.id == id)[0].x - arrow.x + 5) +
					" 0L" +
					(blocks.filter((a) => a.id == id)[0].x - arrow.x + 5) +
					" " +
					paddingy / 2 +
					"L5 " +
					paddingy / 2 +
					"L5 " +
					y +
					'" stroke="#7367f0" stroke-width="2px"/><path d="M0 ' +
					(y - 5) +
					"H10L5 " +
					y +
					"L0 " +
					(y - 5) +
					'Z" fill="#7367f0"/></svg></div>';
				var tempArrowBlock = document.querySelector(`.arrowid[value="${tempBlockID}"]`);
				if (tempArrowBlock?.parentNode) {
					tempArrowBlock.parentNode.style.left =
						arrow.x -
						5 -
						(absx + window.scrollX) +
						canvas_div.scrollLeft +
						canvas_div.getBoundingClientRect().left +
						"px";
				}
			} else {
				// console.log(
				// 	"===============================",
				// 	`x: ${x}, y: ${y}, paddingx: ${paddingx}, paddingy: ${paddingy}`
				// );
				if (!isNaN(x) && !isNaN(paddingx) && !isNaN(paddingy) && !isNaN(y)) {
					canvas_div.innerHTML +=
						'<div class="arrowblock"><input type="hidden" class="arrowid" value="' +
						tempBlockID +
						'"><svg preserveaspectratio="none" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 0L20 ' +
						paddingy / 2 +
						"L" +
						x +
						" " +
						paddingy / 2 +
						"L" +
						x +
						" " +
						y +
						'" stroke="#7367f0" stroke-width="2px"/><path d="M' +
						(x - 5) +
						" " +
						(y - 5) +
						"H" +
						(x + 5) +
						"L" +
						x +
						" " +
						y +
						"L" +
						(x - 5) +
						" " +
						(y - 5) +
						'Z" fill="#7367f0"/></svg></div>';
					var tempArrowBlock = document.querySelector(`.arrowid[value="${tempBlockID}"]`);
					if (tempArrowBlock?.parentNode) {
						tempArrowBlock.parentNode.style.left =
							blocks.filter((a) => a.id == id)[0].x -
							20 -
							(absx + window.scrollX) +
							canvas_div.scrollLeft +
							canvas_div.getBoundingClientRect().left +
							"px";
					}
				}
			}
			var tempArrowBlock = document.querySelector(`.arrowid[value="${tempBlockID}"]`);
			if (tempArrowBlock?.parentNode) {
				tempArrowBlock.parentNode.style.top =
					blocks.filter((a) => a.id == id)[0].y +
					blocks.filter((a) => a.id == id)[0].height / 2 +
					canvas_div.getBoundingClientRect().top -
					absy +
					"px";
			}
		}

		function updateArrow(arrow, x, y, children) {
			if (x < 0) {
				document.querySelector('.arrowid[value="' + children.id + '"]').parentNode.style.left =
					arrow.x - 5 - (absx + window.scrollX) + canvas_div.getBoundingClientRect().left + "px";
				document.querySelector('.arrowid[value="' + children.id + '"]').parentNode.innerHTML =
					'<input type="hidden" class="arrowid" value="' +
					children.id +
					'"><svg preserveaspectratio="none" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M' +
					(blocks.filter((id) => id.id == children.parent)[0].x - arrow.x + 5) +
					" 0L" +
					(blocks.filter((id) => id.id == children.parent)[0].x - arrow.x + 5) +
					" " +
					paddingy / 2 +
					"L5 " +
					paddingy / 2 +
					"L5 " +
					y +
					'" stroke="#7367f0" stroke-width="2px"/><path d="M0 ' +
					(y - 5) +
					"H10L5 " +
					y +
					"L0 " +
					(y - 5) +
					'Z" fill="#7367f0"/></svg>';
			} else {
				document.querySelector('.arrowid[value="' + children.id + '"]').parentNode.style.left =
					blocks.filter((id) => id.id == children.parent)[0].x -
					20 -
					(absx + window.scrollX) +
					canvas_div.getBoundingClientRect().left +
					"px";
				document.querySelector('.arrowid[value="' + children.id + '"]').parentNode.innerHTML =
					'<input type="hidden" class="arrowid" value="' +
					children.id +
					'"><svg preserveaspectratio="none" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 0L20 ' +
					paddingy / 2 +
					"L" +
					x +
					" " +
					paddingy / 2 +
					"L" +
					x +
					" " +
					y +
					'" stroke="#7367f0" stroke-width="2px"/><path d="M' +
					(x - 5) +
					" " +
					(y - 5) +
					"H" +
					(x + 5) +
					"L" +
					x +
					" " +
					y +
					"L" +
					(x - 5) +
					" " +
					(y - 5) +
					'Z" fill="#7367f0"/></svg>';
			}
		}

		function addBlock() {}

		function appendConditionalBlock(data = {}) {
			const tempID = generate_uuid("wf");
			const d = getNode(`<div class="blockelem noselect block cond-block bg-success" >
			  <input class="blockelemtype" name="blockelemtype" type="hidden" value="4" data-extra="">
			  <div class="blockyinfo" ><i class="feather icon-check fa-lg"></i></div>
			  <div class="blockplaceholder-cond">Drop Here</div>
			</div>`);
			d.innerHTML += `<input type="hidden" name="blockid" class="blockid" value="${tempID}">`;
			drag = d;
			var blocko = blocks.map((a) => a.id);
			var index = getIndex(blocks, "id", data.id);
			snap(d, index, blocko);
			const dnTempID = generate_uuid("wf");
			const dn = getNode(`<div class="blockelem noselect block cond-block bg-warning" >
			  <input class="blockelemtype" name="blockelemtype" type="hidden" value="5" data-extra="">
			  <div class="blockyinfo" ><i class="feather icon-x fa-lg"></i></div>
			  <div class="blockplaceholder-cond">Drop Here</div>
			</div>`);
			dn.innerHTML += `<input type="hidden" name="blockid" class="blockid" value="${dnTempID}">`;
			drag = dn;
			var blocko = blocks.map((a) => a.id);
			var index = getIndex(blocks, "id", data.id);
			snap(dn, index, blocko);
		}

		function appendApprovalBlock(data = {}) {
			const tempID = generate_uuid("wf");
			const d = getNode(`<div class="blockelem noselect block approval-block bg-success" >
			  <input class="blockelemtype" name="blockelemtype" type="hidden" value="${WORKFLOW_BLOCK_TYPE.APPROVAL_ACCEPTED}" data-extra="">
			  <div class="blockyinfo" >Accepted <i class="feather icon-check fa-lg"></i></div>
			  <div class="blockplaceholder-cond">Drop Here</div>
			</div>`);
			d.innerHTML += `<input type="hidden" name="blockid" class="blockid" value="${tempID}">`;
			drag = d;
			var blocko = blocks.map((a) => a.id);
			var index = getIndex(blocks, "id", data.id);
			snap(d, index, blocko);
			const dnTempID = generate_uuid("wf");
			const dn = getNode(`<div class="blockelem noselect block approval-block bg-warning" >
			  <input class="blockelemtype" name="blockelemtype" type="hidden" value="${WORKFLOW_BLOCK_TYPE.APPROVAL_REJECTED}" data-extra="">
			  <div class="blockyinfo" >Rejected <i class="feather icon-x fa-lg"></i></div>
			  <div class="blockplaceholder-cond">Drop Here</div>
			</div>`);
			dn.innerHTML += `<input type="hidden" name="blockid" class="blockid" value="${dnTempID}">`;
			drag = dn;
			var blocko = blocks.map((a) => a.id);
			var index = getIndex(blocks, "id", data.id);
			snap(dn, index, blocko);
		}

		function appendTransferStateBlock(data, templateType = 3, is_deattachable = false) {
			const tempID = generate_uuid("wf");
			// prettier-ignore
			const d = getNode(`<div class="blockelem noselect block" >
			  <input class="blockelemtype" name="blockelemtype" type="hidden" value="${WORKFLOW_BLOCK_TYPE.ACTION}" 
			  data-extra='{"title": "UI.k_transfer_state","description": "Message.msg_transfer_state_msg","icon": "repeat",
			"type": 3,"templateType\": 3,\"sub_type\": 3,\"excluded_module\": [], \"is_deattachable\": ${is_deattachable},
			"is_recurring": true}'>
			  <input type="hidden" name="blockelemtemplatetype" class="blockelemtemplatetype" value="${templateType}" 
							data-extra='{"title": "UI.k_transfer_state","description": "Message.msg_transfer_state_msg","icon": "repeat",
			"type": 3,"templateType\": 3,\"sub_type\": 3,\"excluded_module\": [], \"is_deattachable\": ${is_deattachable},
			"is_recurring": true}' >
			  <input type="hidden" name="blockid" class="blockid" value="${tempID}">
			  <div class="blockyleft">
					<span class="img ico-actions feather icon-repeat"></span>
					<p class="blockyname" id="blockTitle${tempID}">Transfer State</p>
			  </div>
			  <div class="blockyright"></div>
			  <div class="blockydiv"></div>
			  <div class="blockyinfo" id="blockDesc${tempID}">It will jump to selected state & status</div>
			  <div class="blockplaceholder">Drop Here</div>
			</div>`);
			drag = d;
			var blocko = blocks.map((a) => a.id);
			var index = getIndex(blocks, "id", data.id);
			snap(d, index, blocko);
		}

		function appendReviewBlock(data = {}) {
			const tempID = generate_uuid("wf");
			const d = getNode(`<div class="blockelem noselect block review-block bg-success" >
			  <input class="blockelemtype" name="blockelemtype" type="hidden" value="${WORKFLOW_BLOCK_TYPE.REVIEW_ACCEPTED}" data-extra="">
			  <div class="blockyinfo" >Approved <i class="feather icon-check fa-lg"></i></div>
			  <div class="blockplaceholder-cond">Drop Here</div>
			</div>`);
			d.innerHTML += `<input type="hidden" name="blockid" class="blockid" value="${tempID}">`;
			drag = d;
			var blocko = blocks.map((a) => a.id);
			var index = getIndex(blocks, "id", data.id);
			snap(d, index, blocko);
			const dnTempID = generate_uuid("wf");
			let dn = getNode(`<div class="blockelem noselect block review-block bg-warning" >
			  <input class="blockelemtype" name="blockelemtype" type="hidden" value="${WORKFLOW_BLOCK_TYPE.REVIEW_REJECTED}" data-extra="">
			  <div class="blockyinfo" >Ask For Changes <i class="feather icon-x fa-lg"></i></div>
			  <div class="blockplaceholder-cond">Drop Here</div>
			</div>`);
			dn.innerHTML += `<input type="hidden" name="blockid" class="blockid" value="${dnTempID}">`;
			// var tr_arrow_block = `<div class="arrowblock" style="{left: ${data.leftN}, top: ${data.leftY} }" id="tempArrow${dnTempID}">
			// 						<input type="hidden" class="arrowid" value="${dnTempID}">
			// 						<svg preserveaspectratio="none" fill="none" xmlns="http://www.w3.org/2000/svg">
			// 							<path d="M20 20V100 100h-100" fill="#7367f0"/>
			// 						</svg>
			// 						</div>`;
			// drag = dn;
			// drag.parentNode.append(tr_arrow_block);
			drag = dn;
			var blocko = blocks.map((a) => a.id);
			var index = getIndex(blocks, "id", data.id);
			snap(dn, index, blocko);
		}

		function getIndex(arr, key, value) {
			let index = -1;
			for (let i = 0; i < arr.length; i++) {
				if (arr[i][key] == value) {
					index = i;
					break;
				}
			}
			return index;
		}

		function snap(drag, i, blocko) {
			if (!rearrange) {
				canvas_div.appendChild(drag);
			}
			var totalwidth = 0;
			var totalremove = 0;
			var maxheight = 0;
			var blockData = {};
			const blockType = drag.querySelector(".blockelemtype").value;
			const blockTemplateType = drag.querySelector(".blockelemtemplatetype")?.value;
			for (var w = 0; w < blocks.filter((id) => id.parent == blocko[i]).length; w++) {
				var children = blocks.filter((id) => id.parent == blocko[i])[w];
				if (children.childwidth > children.width) {
					totalwidth += children.childwidth + paddingx;
				} else {
					totalwidth += children.width + paddingx;
				}
			}
			totalwidth += parseInt(window.getComputedStyle(drag).width);
			for (var w = 0; w < blocks.filter((id) => id.parent == blocko[i]).length; w++) {
				var children = blocks.filter((id) => id.parent == blocko[i])[w];
				if (children.childwidth > children.width) {
					const tempChildBlock = document.querySelector(".blockid[value='" + children.id + "']");
					if (tempChildBlock?.parentNode) {
						tempChildBlock.parentNode.style.left =
							blocks.filter((a) => a.id == blocko[i])[0].x -
							totalwidth / 2 +
							totalremove +
							children.childwidth / 2 -
							children.width / 2 +
							"px";
					}

					children.x =
						blocks.filter((id) => id.parent == blocko[i])[0].x -
						totalwidth / 2 +
						totalremove +
						children.childwidth / 2;
					totalremove += children.childwidth + paddingx;
				} else {
					const tempChildBlock = document.querySelector(".blockid[value='" + children.id + "']");
					if (tempChildBlock?.parentNode) {
						tempChildBlock.parentNode.style.left =
							blocks.filter((a) => a.id == blocko[i])[0].x - totalwidth / 2 + totalremove + "px";
					}

					children.x =
						blocks.filter((id) => id.parent == blocko[i])[0].x -
						totalwidth / 2 +
						totalremove +
						children.width / 2;
					totalremove += children.width + paddingx;
				}
			}
			drag.style.left =
				blocks.filter((id) => id.id == blocko[i])[0].x -
				totalwidth / 2 +
				totalremove -
				(window.scrollX + absx) +
				canvas_div.scrollLeft +
				canvas_div.getBoundingClientRect().left +
				"px";
			drag.style.top =
				blocks.filter((id) => id.id == blocko[i])[0].y +
				blocks.filter((id) => id.id == blocko[i])[0].height / 2 +
				paddingy -
				(window.scrollY + absy) +
				canvas_div.getBoundingClientRect().top +
				"px";
			if (rearrange) {
				blockstemp.filter((a) => a.id == getBlockId(drag))[0].x =
					drag.getBoundingClientRect().left +
					window.scrollX +
					parseInt(window.getComputedStyle(drag).width) / 2 +
					canvas_div.scrollLeft -
					canvas_div.getBoundingClientRect().left;
				blockstemp.filter((a) => a.id == getBlockId(drag))[0].y =
					drag.getBoundingClientRect().top +
					window.scrollY +
					parseInt(window.getComputedStyle(drag).height) / 2 +
					canvas_div.scrollTop -
					canvas_div.getBoundingClientRect().top;
				blockstemp.filter((a) => a.id == getBlockId(drag))[0].parent = blocko[i];
				for (var w = 0; w < blockstemp.length; w++) {
					if (blockstemp[w].id != getBlockId(drag)) {
						const blockParent = document.querySelector(
							".blockid[value='" + blockstemp[w].id + "']"
						).parentNode;
						const arrowParent = document.querySelector(
							".arrowid[value='" + blockstemp[w].id + "']"
						).parentNode;
						blockParent.style.left =
							blockParent.getBoundingClientRect().left +
							window.scrollX -
							(window.scrollX + canvas_div.getBoundingClientRect().left) +
							canvas_div.scrollLeft +
							"px";
						blockParent.style.top =
							blockParent.getBoundingClientRect().top +
							window.scrollY -
							(window.scrollY + canvas_div.getBoundingClientRect().top) +
							canvas_div.scrollTop +
							"px";
						arrowParent.style.left =
							arrowParent.getBoundingClientRect().left +
							window.scrollX -
							(window.scrollX + canvas_div.getBoundingClientRect().left) +
							canvas_div.scrollLeft +
							20 +
							"px";
						arrowParent.style.top =
							arrowParent.getBoundingClientRect().top +
							window.scrollY -
							(window.scrollY + canvas_div.getBoundingClientRect().top) +
							canvas_div.scrollTop +
							"px";
						canvas_div.appendChild(blockParent);
						canvas_div.appendChild(arrowParent);

						blockstemp[w].x =
							blockParent.getBoundingClientRect().left +
							window.scrollX +
							parseInt(window.getComputedStyle(blockParent).width) / 2 +
							canvas_div.scrollLeft -
							canvas_div.getBoundingClientRect().left;
						blockstemp[w].y =
							blockParent.getBoundingClientRect().top +
							window.scrollY +
							parseInt(window.getComputedStyle(blockParent).height) / 2 +
							canvas_div.scrollTop -
							canvas_div.getBoundingClientRect().top;
					}
				}
				blocks = blocks.concat(blockstemp);
				blockstemp = [];
			} else {
				let parent = blocko[i];
				let level = blocks.filter((x) => x.id == parent)[0].level + 1;
				blockData = {
					childwidth: 0,
					parent: parent,
					level: level,
					id: getBlockId(drag),
					x:
						drag.getBoundingClientRect().left +
						window.scrollX +
						parseInt(window.getComputedStyle(drag).width) / 2 +
						canvas_div.scrollLeft -
						canvas_div.getBoundingClientRect().left,
					y:
						drag.getBoundingClientRect().top +
						window.scrollY +
						parseInt(window.getComputedStyle(drag).height) / 2 +
						canvas_div.scrollTop -
						canvas_div.getBoundingClientRect().top,
					width: parseInt(window.getComputedStyle(drag).width),
					height: parseInt(window.getComputedStyle(drag).height),
					type: parseInt(blockType),
				};
				blocks.push(blockData);
			}

			var arrowblock = blocks.filter((a) => a.id == getBlockId(drag))[0];
			var arrowx = arrowblock.x - blocks.filter((a) => a.id == blocko[i])[0].x + 20;
			var arrowy = paddingy;
			drawArrow(arrowblock, arrowx, arrowy, blocko[i]);

			if (blocks.filter((a) => a.id == blocko[i])[0].parent != -1) {
				var flag = false;
				var idval = blocko[i];
				while (!flag) {
					if (blocks.filter((a) => a.id == idval)[0].parent == -1) {
						flag = true;
					} else {
						var zwidth = 0;
						for (var w = 0; w < blocks.filter((id) => id.parent == idval).length; w++) {
							var children = blocks.filter((id) => id.parent == idval)[w];
							if (children.childwidth > children.width) {
								if (w == blocks.filter((id) => id.parent == idval).length - 1) {
									zwidth += children.childwidth;
								} else {
									zwidth += children.childwidth + paddingx;
								}
							} else {
								if (w == blocks.filter((id) => id.parent == idval).length - 1) {
									zwidth += children.width;
								} else {
									zwidth += children.width + paddingx;
								}
							}
						}
						blocks.filter((a) => a.id == idval)[0].childwidth = zwidth;
						idval = blocks.filter((a) => a.id == idval)[0].parent;
					}
				}
				blocks.filter((id) => id.id == idval)[0].childwidth = totalwidth;
			}
			if (rearrange) {
				rearrange = false;
				drag.classList.remove("dragging");
			}
			rearrangeMe();
			checkOffset();
			if (blockType == WORKFLOW_BLOCK_TYPE.CONDITION && Object.keys(blockData)?.length > 0) {
				var data = {
					leftY: blockData.x - blockData.width / 2 - 42,
					leftN: blockData.x + blockData.width / 2 - 42,
					topY: blockData.y,
					topN: blockData.y + 84,
					arrowYLeft: blockData.x - blockData.width / 2 - 42,
					arrowYTop: blockData.y,
					arrowNLeft: blockData.x + blockData.width / 2 - 42,
					arrowNTop: blockData.y,
					id: blockData.id,
				};
				appendConditionalBlock(data);
			} else if (
				blockType == WORKFLOW_BLOCK_TYPE.STATE &&
				blockTemplateType == WORKFLOW_TEMPLATE_TYPE.ASK_FOR_APPROVAL &&
				Object.keys(blockData)?.length > 0
			) {
				var data = {
					leftY: blockData.x - blockData.width / 2 - 42,
					leftN: blockData.x + blockData.width / 2 - 42,
					topY: blockData.y,
					topN: blockData.y + 84,
					arrowYLeft: blockData.x - blockData.width / 2 - 42,
					arrowYTop: blockData.y,
					arrowNLeft: blockData.x + blockData.width / 2 - 42,
					arrowNTop: blockData.y,
					id: blockData.id,
				};
				appendApprovalBlock(data);
			} else if (
				blockType == WORKFLOW_BLOCK_TYPE.STATE &&
				blockTemplateType == WORKFLOW_TEMPLATE_TYPE.ASK_FOR_REVIEW &&
				Object.keys(blockData)?.length > 0
			) {
				var data = {
					leftY: blockData.x - blockData.width / 2 - 42,
					leftN: blockData.x + blockData.width / 2 - 42,
					topY: blockData.y,
					topN: blockData.y + 84,
					arrowYLeft: blockData.x - blockData.width / 2 - 42,
					arrowYTop: blockData.y,
					arrowNLeft: blockData.x + blockData.width / 2 - 42,
					arrowNTop: blockData.y,
					id: blockData.id,
				};
				appendReviewBlock(data);
			} else if (blockType == WORKFLOW_BLOCK_TYPE.REVIEW_REJECTED && Object.keys(blockData)?.length > 0) {
				var data = {
					leftY: blockData.x - blockData.width / 2 - 42,
					leftN: blockData.x + blockData.width / 2 - 42,
					topY: blockData.y,
					topN: blockData.y + 84,
					arrowYLeft: blockData.x - blockData.width / 2 - 42,
					arrowYTop: blockData.y,
					arrowNLeft: blockData.x + blockData.width / 2 - 42,
					arrowNTop: blockData.y,
					id: blockData.id,
				};
				appendTransferStateBlock(data);
			}
		}

		function getBlockId(drag) {
			const id = drag.querySelector(".blockid").value;
			return id;
		}

		function getNode(htmlString) {
			var wrapper = document.createElement("div");
			wrapper.innerHTML = htmlString;
			return wrapper.firstChild;
		}

		flowy.touchblock = function (event) {
			dragblock = false;
			if (hasParentClass(event.target, "block")) {
				var theblock = event.target.closest(".block");
				if (event.targetTouches) {
					mouse_x = event.targetTouches[0].clientX;
					mouse_y = event.targetTouches[0].clientY;
				} else {
					mouse_x = event.clientX;
					mouse_y = event.clientY;
				}
				if (event.type !== "mouseup" && hasParentClass(event.target, "block")) {
					if (event.which != 3) {
						if (!active && !rearrange) {
							dragblock = true;
							drag = theblock;
							dragx = mouse_x - (drag.getBoundingClientRect().left + window.scrollX);
							dragy = mouse_y - (drag.getBoundingClientRect().top + window.scrollY);
						}
					}
				}
			}
		};

		function hasParentClass(element, classname) {
			if (element.className) {
				if (element.className.split(" ").indexOf(classname) >= 0) return true;
			}
			return element.parentNode && hasParentClass(element.parentNode, classname);
		}

		flowy.deleteBlock = function (id) {
			let newParentId;

			if (Number.isInteger(id)) {
				id = parseInt(id);
			}

			for (var i = 0; i < blocks.length; i++) {
				if (blocks[i].id === id) {
					newParentId = blocks[i].parent;
					const successIndicatorElement = document.querySelector(".indicator");
					const failedIndicatorElement = document.querySelector(".indicator-danger");
					if (successIndicatorElement) {
						canvas_div.appendChild(successIndicatorElement);
					}
					if (failedIndicatorElement) {
						canvas_div.appendChild(failedIndicatorElement);
					}
					removeBlockEls(blocks[i].id);
					blocks.splice(i, 1);
					modifyChildBlocks(id);
					delete wfGuidMap[id];
					break;
				}
			}

			if (blocks.length > 1) {
				rearrangeMe();
			}

			function modifyChildBlocks(parentId) {
				let children = [];
				let blocko = blocks.map((a) => a.id);
				for (var i = blocko.length - 1; i >= 0; i--) {
					let currentBlock = blocks.filter((a) => a.id == blocko[i])[0];
					if (currentBlock.parent === parentId) {
						children.push(currentBlock.id);
						removeBlockEls(currentBlock.id);
						blocks.splice(i, 1);
					}
				}

				for (var i = 0; i < children.length; i++) {
					modifyChildBlocks(children[i]);
				}
			}

			function removeBlockEls(id) {
				document.querySelector(".blockid[value='" + id + "']").parentNode.remove();
				document.querySelector(".arrowid[value='" + id + "']").parentNode.remove();
			}
		};

		flowy.moveBlock = function (event) {
			if (event.targetTouches) {
				mouse_x = event.targetTouches[0].clientX;
				mouse_y = event.targetTouches[0].clientY;
			} else {
				mouse_x = event.clientX;
				mouse_y = event.clientY;
			}
			if (dragblock && blocks?.length > 0) {
				rearrange = true;
				drag.classList.add("dragging");
				var blockid = getBlockId(drag);
				prevblock = blocks.filter((a) => a.id == blockid)[0].parent;
				blockstemp.push(blocks.filter((a) => a.id == blockid)[0]);
				blocks = blocks.filter(function (e) {
					return e.id != blockid;
				});
				let block = getBlockData(blockid);
				if (!block?.is_start) {
					document.querySelector(".arrowid[value='" + blockid + "']")?.parentNode.remove();
				}
				var layer = blocks.filter((a) => a.parent == blockid);
				var flag = false;
				var foundids = [];
				var allids = [];
				while (!flag) {
					for (var i = 0; i < layer.length; i++) {
						if (layer[i] != blockid) {
							blockstemp.push(blocks.filter((a) => a.id == layer[i].id)[0]);
							const blockParent = document.querySelector(
								".blockid[value='" + layer[i].id + "']"
							).parentNode;
							const arrowParent = document.querySelector(
								".arrowid[value='" + layer[i].id + "']"
							).parentNode;
							blockParent.style.left =
								blockParent.getBoundingClientRect().left +
								window.scrollX -
								(drag.getBoundingClientRect().left + window.scrollX) +
								"px";
							blockParent.style.top =
								blockParent.getBoundingClientRect().top +
								window.scrollY -
								(drag.getBoundingClientRect().top + window.scrollY) +
								"px";
							arrowParent.style.left =
								arrowParent.getBoundingClientRect().left +
								window.scrollX -
								(drag.getBoundingClientRect().left + window.scrollX) +
								"px";
							arrowParent.style.top =
								arrowParent.getBoundingClientRect().top +
								window.scrollY -
								(drag.getBoundingClientRect().top + window.scrollY) +
								"px";
							drag.appendChild(blockParent);
							drag.appendChild(arrowParent);
							foundids.push(layer[i].id);
							allids.push(layer[i].id);
						}
					}
					if (foundids.length == 0) {
						flag = true;
					} else {
						layer = blocks.filter((a) => foundids.includes(a.parent));
						foundids = [];
					}
				}
				for (var i = 0; i < blocks.filter((a) => a.parent == blockid).length; i++) {
					var blocknumber = blocks.filter((a) => a.parent == blockid)[i];
					blocks = blocks.filter(function (e) {
						return e.id != blocknumber;
					});
				}
				for (var i = 0; i < allids.length; i++) {
					var blocknumber = allids[i];
					blocks = blocks.filter(function (e) {
						return e.id != blocknumber;
					});
				}
				if (blocks.length > 1) {
					rearrangeMe();
				}
				dragblock = false;
			}
			if (active) {
				drag.style.left = mouse_x - dragx + "px";
				drag.style.top = mouse_y - dragy + "px";
			} else if (rearrange) {
				drag.style.left = mouse_x - dragx - (window.scrollX + absx) + canvas_div.scrollLeft + "px";
				drag.style.top = mouse_y - dragy - (window.scrollY + absy) + canvas_div.scrollTop + "px";
				const blockID = getBlockId(drag);
				blockstemp.filter((a) => a.id == blockID).x =
					drag.getBoundingClientRect().left +
					window.scrollX +
					parseInt(window.getComputedStyle(drag).width) / 2 +
					canvas_div.scrollLeft;
				blockstemp.filter((a) => a.id == blockID).y =
					drag.getBoundingClientRect().top +
					window.scrollY +
					parseInt(window.getComputedStyle(drag).height) / 2 +
					canvas_div.scrollTop;
			}
			if (active || rearrange) {
				if (
					mouse_x > canvas_div.getBoundingClientRect().width + canvas_div.getBoundingClientRect().left - 10 &&
					mouse_x < canvas_div.getBoundingClientRect().width + canvas_div.getBoundingClientRect().left + 10
				) {
					canvas_div.scrollLeft += 10;
				} else if (
					mouse_x < canvas_div.getBoundingClientRect().left + 10 &&
					mouse_x > canvas_div.getBoundingClientRect().left - 10
				) {
					canvas_div.scrollLeft -= 10;
				} else if (
					mouse_y > canvas_div.getBoundingClientRect().height + canvas_div.getBoundingClientRect().top - 10 &&
					mouse_y < canvas_div.getBoundingClientRect().height + canvas_div.getBoundingClientRect().top + 10
				) {
					canvas_div.scrollTop += 10;
				} else if (
					mouse_y < canvas_div.getBoundingClientRect().top + 10 &&
					mouse_y > canvas_div.getBoundingClientRect().top - 10
				) {
					canvas_div.scrollLeft -= 10;
				}
				var xpos =
					drag.getBoundingClientRect().left +
					window.scrollX +
					parseInt(window.getComputedStyle(drag).width) / 2 +
					canvas_div.scrollLeft -
					canvas_div.getBoundingClientRect().left;
				var ypos =
					drag.getBoundingClientRect().top +
					window.scrollY +
					canvas_div.scrollTop -
					canvas_div.getBoundingClientRect().top;
				var blocko = blocks.map((a) => a.id);
				for (var i = 0; i < blocks.length; i++) {
					if (checkAttach(blocko[i])) {
						showIndicator(blocko[i], "indicator");
						break;
					} else if (i == blocks.length - 1) {
						if (!document.querySelector(".indicator")?.classList.contains("invisible")) {
							document.querySelector(".indicator")?.classList.add("invisible");
						}
						showIndicator(blocko[i], "indicator-danger");
					}
				}
			}
		};

		function showIndicator(block, indicatorClass) {
			let blockElement = document.querySelector(".blockid[value='" + block + "']")?.parentNode;
			if (blockElement) {
				let indicatorBlock = document.querySelector(`.${indicatorClass}`);
				if (!indicatorBlock) {
					var ele = document.createElement("DIV");
					ele.classList.add(indicatorClass);
					ele.classList.add("invisible");
					canvas_div.appendChild(ele);
					indicatorBlock = ele;
				}

				if (indicatorBlock) {
					blockElement.appendChild(indicatorBlock);
					document.querySelector(`.${indicatorClass}`).style.left = blockElement.offsetWidth / 2 - 5 + "px";
					document.querySelector(`.${indicatorClass}`).style.top = blockElement.offsetHeight + "px";
					document.querySelector(`.${indicatorClass}`).classList.remove("invisible");
				}
			}
		}

		function checkOffset() {
			offsetleft = blocks.map((a) => a.x);
			var widths = blocks.map((a) => a.width);
			var mathmin = offsetleft.map(function (item, index) {
				return item - widths[index] / 2;
			});
			offsetleft = Math.min.apply(Math, mathmin);
			if (offsetleft < canvas_div.getBoundingClientRect().left + window.scrollX - absx) {
				var blocko = blocks.map((a) => a.id);
				for (var w = 0; w < blocks.length; w++) {
					document.querySelector(
						".blockid[value='" + blocks.filter((a) => a.id == blocko[w])[0].id + "']"
					).parentNode.style.left =
						blocks.filter((a) => a.id == blocko[w])[0].x -
						blocks.filter((a) => a.id == blocko[w])[0].width / 2 -
						offsetleft +
						canvas_div.getBoundingClientRect().left -
						absx +
						20 +
						"px";
					if (blocks.filter((a) => a.id == blocko[w])[0].parent != -1) {
						var arrowblock = blocks.filter((a) => a.id == blocko[w])[0];
						var arrowx =
							arrowblock.x -
							blocks.filter((a) => a.id == blocks.filter((a) => a.id == blocko[w])[0].parent)[0].x;
						if (arrowx < 0) {
							document.querySelector('.arrowid[value="' + blocko[w] + '"]').parentNode.style.left =
								arrowblock.x -
								offsetleft +
								20 -
								5 +
								canvas_div.getBoundingClientRect().left -
								absx +
								"px";
						} else {
							document.querySelector('.arrowid[value="' + blocko[w] + '"]').parentNode.style.left =
								blocks.filter((id) => id.id == blocks.filter((a) => a.id == blocko[w])[0].parent)[0].x -
								20 -
								offsetleft +
								canvas_div.getBoundingClientRect().left -
								absx +
								20 +
								"px";
						}
					}
				}
				for (var w = 0; w < blocks.length; w++) {
					blocks[w].x =
						document
							.querySelector(".blockid[value='" + blocks[w].id + "']")
							.parentNode.getBoundingClientRect().left +
						window.scrollX +
						canvas_div.scrollLeft +
						parseInt(
							window.getComputedStyle(
								document.querySelector(".blockid[value='" + blocks[w].id + "']").parentNode
							).width
						) /
							2 -
						20 -
						canvas_div.getBoundingClientRect().left;
				}
			}
		}

		function rearrangeMe() {
			var result = blocks.map((a) => a.parent);
			var blockParentIdMap = blocks.reduce((map, obj) => {
				map[obj.id] = obj;
				return map;
			}, {});
			//console.log(blockParentIdMap);
			for (var z = 0; z < result.length; z++) {
				if (result[z] == -1) {
					z++;
				}
				var totalwidth = 0;
				var totalremove = 0;
				var maxheight = 0;
				let currentBlock = blockParentIdMap[result[z]];
				let tempChildBlocks = blocks.filter((id) => id.parent == result[z]);
				for (var w = 0; w < tempChildBlocks.length; w++) {
					var children = tempChildBlocks[w];
					// setting level & parent for childs
					if (currentBlock?.level) {
						children.level = currentBlock?.level + 1;
						children.parent = currentBlock?.id;
					}
					if (blocks.filter((id) => id.parent == children.id).length == 0) {
						children.childwidth = 0;
					}
					if (children.childwidth > children.width) {
						if (w == blocks.filter((id) => id.parent == result[z]).length - 1) {
							totalwidth += children.childwidth;
						} else {
							totalwidth += children.childwidth + paddingx;
						}
					} else {
						if (w == blocks.filter((id) => id.parent == result[z]).length - 1) {
							totalwidth += children.width;
						} else {
							totalwidth += children.width + paddingx;
						}
					}
				}
				if (!currentBlock?.is_first) {
					blocks.filter((a) => a.id == result[z])[0].childwidth = totalwidth;
				}
				for (var w = 0; w < blocks.filter((id) => id.parent == result[z]).length; w++) {
					var children = blocks.filter((id) => id.parent == result[z])[w];
					const r_block = document.querySelector(".blockid[value='" + children.id + "']")?.parentNode;
					const r_array = blocks.filter((id) => id.id == result[z]);
					if (r_block) {
						r_block.style.top = r_array.y + paddingy + canvas_div.getBoundingClientRect().top - absy + "px";
						r_array.y = r_array.y + paddingy;
						if (children.childwidth > children.width) {
							r_block.style.left =
								r_array[0].x -
								totalwidth / 2 +
								totalremove +
								children.childwidth / 2 -
								children.width / 2 -
								(absx + window.scrollX) +
								canvas_div.getBoundingClientRect().left +
								"px";
							children.x = r_array[0].x - totalwidth / 2 + totalremove + children.childwidth / 2;
							totalremove += children.childwidth + paddingx;
						} else {
							r_block.style.left =
								r_array[0].x -
								totalwidth / 2 +
								totalremove -
								(absx + window.scrollX) +
								canvas_div.getBoundingClientRect().left +
								"px";
							children.x = r_array[0].x - totalwidth / 2 + totalremove + children.width / 2;
							totalremove += children.width + paddingx;
						}
					}
					var arrowblock = blocks.filter((a) => a.id == children.id)[0];
					var arrowx = arrowblock.x - blocks.filter((a) => a.id == children.parent)[0].x + 20;
					var arrowy = paddingy;
					updateArrow(arrowblock, arrowx, arrowy, children);
				}
			}
		}

		document.addEventListener("mousedown", flowy.beginDrag);
		document.addEventListener("mousedown", flowy.touchblock, false);
		document.addEventListener("touchstart", flowy.beginDrag);
		document.addEventListener("touchstart", flowy.touchblock, false);

		document.addEventListener("mouseup", flowy.touchblock, false);
		document.addEventListener("mousemove", flowy.moveBlock, false);
		document.addEventListener("touchmove", flowy.moveBlock, false);

		document.addEventListener("mouseup", flowy.endDrag, false);
		document.addEventListener("touchend", flowy.endDrag, false);
	};

	flowy.destroy = function () {
		// console.log("onDestroy flowy");
		document.removeEventListener("mousedown", flowy.beginDrag);
		document.removeEventListener("mousedown", flowy.touchblock, false);
		document.removeEventListener("touchstart", flowy.beginDrag);
		document.removeEventListener("touchstart", flowy.touchblock, false);

		document.removeEventListener("mouseup", flowy.touchblock, false);
		document.removeEventListener("mousemove", flowy.moveBlock, false);
		document.removeEventListener("touchmove", flowy.moveBlock, false);

		document.removeEventListener("mouseup", flowy.endDrag, false);
		document.removeEventListener("touchend", flowy.endDrag, false);
	};

	function blockGrabbed(block) {
		grab(block);
	}

	function blockReleased(drag = null) {
		release(drag);
	}

	function blockSnap(drag, first, parent) {
		return snapping(drag, first, parent, wfGuidMap);
	}

	function beforeDelete(drag, parent) {
		return rearrange(drag, parent);
	}

	function addEventListenerMulti(type, listener, capture, selector) {
		var nodes = document.querySelectorAll(selector);
		for (var i = 0; i < nodes.length; i++) {
			nodes[i].addEventListener(type, listener, capture);
		}
	}

	function removeEventListenerMulti(type, listener, capture, selector) {
		var nodes = document.querySelectorAll(selector);
		for (var i = 0; i < nodes.length; i++) {
			nodes[i].removeEventListener(type, listener, capture);
		}
	}

	flowy.load();
};

export default flowy;
