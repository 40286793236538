import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { PagesRoutingModule } from "./pages-routing.module";
import { ComingSoonComponent } from "./components/coming-soon/coming-soon.component";
import { TermsComponent } from "./components/terms/terms.component";
import { PrivacyPolicyComponent } from "./components/privacy-policy/privacy-policy.component";
import { InActiveComponent } from "./components/in-active/in-active.component";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
	declarations: [ComingSoonComponent, TermsComponent, PrivacyPolicyComponent, InActiveComponent],
	imports: [CommonModule, PagesRoutingModule, TranslateModule],
})
export class PagesModule {}
