import { AfterViewInit, Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { TranslateService } from "@ngx-translate/core";
import { ModalDismissReasons, NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
	selector: "app-keyboard-shortcut",
	templateUrl: "./keyboard-shortcut.component.html",
	styleUrls: ["./keyboard-shortcut.component.scss"],
})
export class KeyboardShortcutComponent implements OnInit, AfterViewInit {
	@Output("onHideProfile") onHideProfile = new EventEmitter<any>();
	@Input() name = "shortcutkey";
	public keytoshortcut = false;
	public selectedList;
	public searchText;
	constructor(
		private _coreSidebarService: CoreSidebarService,
		private _translateService: TranslateService,
		private _modalService: NgbModal
	) {}

	public searchModules = [
		// {
		//   name:this._translateService.instant('UI.k_global_search'),
		//   shortKeys:[
		//     'alt','g'
		//   ],
		//   handle: "",
		//   sidebar:false,
		// },
		{
			name: this._translateService.instant("UI.k_user"),
			shortKeys: ["g", "u"],
			handle: "/users",
			sidebar: false,
		},
		{
			name: this._translateService.instant("UI.k_requester"),
			shortKeys: ["g", "r"],
			handle: "/requester",
			sidebar: false,
		},
		{
			name: this._translateService.instant("UI.k_knowledgebase"),
			shortKeys: ["g", "k"],
			handle: "/kb/all",
			sidebar: false,
			module_key: "BASE",
			keys: ["KNOWLEDGE_BASE"],
		},
		{
			name: this._translateService.instant("UI.k_dashboard"),
			shortKeys: ["g", "d"],
			handle: "/dashboard",
			sidebar: true,
			module_key: "BASE",
			keys: ["DASHBOARD"],
		},
		// {
		// 	name: this._translateService.instant("UI.k_dashboard_add"),
		// 	shortKeys: ["f", "d"],
		// 	handle: "/dashboard/add",
		// 	sidebar: false,
		// 	module_key: "BASE",
		// 	keys: ["DASHBOARD"],
		// },
		{
			name: this._translateService.instant("UI.k_incident"),
			shortKeys: ["g", "i"],
			handle: "/incident_view",
			sidebar: true,
			module_key: "SERVICEDESK",
			keys: ["INCIDENT_MANAGEMENT"],
		},
		{
			name: this._translateService.instant("UI.k_incident_add"),
			shortKeys: ["f", "i"],
			handle: "/incident_view/add",
			sidebar: false,
			module_key: "SERVICEDESK",
			keys: ["INCIDENT_MANAGEMENT"],
		},
		{
			name: this._translateService.instant("UI.k_events"),
			shortKeys: ["g", "e"],
			handle: "/events",
			sidebar: false,
			module_key: "MONITORING",
			keys: ["EVENT_MANAGEMENT"],
		},
		{
			name: this._translateService.instant("UI.k_asset"),
			shortKeys: ["g", "a"],
			handle: "/it-asset",
			sidebar: false,
			module_key: "ASSET",
			keys: ["ASSET_MANAGEMENT"],
		},
		{
			name: this._translateService.instant("UI.k_dcagents"),
			shortKeys: ["g", "c"],
			handle: "/agent/datacollector",
			sidebar: false,
			module_key: "AGENT",
			keys: ["AGENT"],
		},
		{
			name: this._translateService.instant("UI.k_invagents"),
			shortKeys: ["g", "l"],
			handle: "/agent/inventory",
			sidebar: false,
			module_key: "AGENT",
			keys: ["DESKTOP_AGENT"],
		},
		{
			name: this._translateService.instant("UI.k_discovery"),
			shortKeys: ["g", "j"],
			handle: "/discovery",
			sidebar: false,
			module_key: "MONITORING",
			keys: ["DISCOVERY"],
		},
		{
			name: this._translateService.instant("UI.k_my_leaves"),
			shortKeys: ["g", "z"],
			handle: "/my-leaves",
			sidebar: false,
			module_key: "BASE",
			keys: ["MY_LEAVE"],
		},
		{
			name: this._translateService.instant("UI.k_teams"),
			shortKeys: ["g", "t"],
			handle: "/teams",
			sidebar: false,
			module_key: "BASE",
			keys: ["TEAMS_CONFIG"],
		},
		{
			name: this._translateService.instant("UI.k_tags"),
			shortKeys: ["g", "x"],
			handle: "/tags",
			sidebar: false,
			module_key: "BASE",
			keys: ["TAG_MANAGEMENT"],
		},
		{
			name: this._translateService.instant("UI.k_configuration_parameters"),
			shortKeys: ["g", "p"],
			handle: "/configuration-parameters",
			sidebar: false,
			module_key: "NCCM",
			keys: ["CONFIGURATION_DOWNLOAD"],
		},
		{
			name: this._translateService.instant("UI.k_threshold"),
			shortKeys: ["g", "n"],
			handle: "/threshold",
			sidebar: false,
			module_key: "MONITORING",
			keys: ["THRESHOLDS_CONFIG"],
		},
		{
			name: this._translateService.instant("UI.k_add_requesters"),
			shortKeys: ["f", "r"],
			handle: "/requester",
			sidebar: true,
		},
		{
			name: this._translateService.instant("UI.k_servicecatalogue"),
			shortKeys: ["g", "s"],
			handle: "/service-catalogue",
			sidebar: false,
			module_key: "SERVICEDESK",
			keys: ["SERVICE_CATALOGUE"],
		},
		{
			name: this._translateService.instant("UI.k_workflow"),
			shortKeys: ["g", "w"],
			handle: "/workflow",
			sidebar: false,
			module_key: "BASE",
			keys: ["WORKFLOW"],
		},
		{
			name: this._translateService.instant("UI.k_report"),
			shortKeys: ["g", "g"],
			handle: "/report",
			sidebar: false,
			module_key: "BASE",
			keys: ["REPORT"],
		},
		// {
		// 	name: this._translateService.instant("UI.k_topology"),
		// 	shortKeys: ["g", "y"],
		// 	handle: "/topology",
		// 	sidebar: false,
		// 	module_key: "BASE",
		// 	keys: ["TOPOLOGY"],
		// },
		{
			name: this._translateService.instant("UI.k_download_jobs"),
			shortKeys: ["d", "j"],
			handle: "/download-job",
			sidebar: false,
			module_key: "NCCM",
			keys: ["CONFIGURATION_DOWNLOAD"],
		},
		{
			name: this._translateService.instant("UI.k_baseline_scheduler"),
			shortKeys: ["b", "s"],
			handle: "/baseline-scheduler",
			sidebar: false,
			module_key: "NCCM",
			keys: ["CONFIGURATION_DOWNLOAD"],
		},
		{
			name: this._translateService.instant("UI.k_diagnosis_tools"),
			shortKeys: ["d", "t"],
			handle: "/diagnosis-tools",
			sidebar: false,
			module_key: "MONITORING",
			keys: ["PERFORMANCE_MONITORING"],
		},
	];
	ngAfterViewInit() {
		this.keytoshortcut = true;
	}

	ngOnInit(): void {
		// this.keytoshortcut = true;
		this.selectedList = this.searchModules;
	}

	toggleSidebarshortkey = (name): void => {
		this.onHideProfile.emit("hide");
		this._modalService.dismissAll();
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
	};

	filterPageModules(searchText) {
		searchText = searchText.toLowerCase();
		this.selectedList = this.searchModules.filter(function (item) {
			return item.name.toLowerCase().indexOf(searchText) !== -1 || !searchText;
		});
	}

	toggleclose() {
		this._modalService.dismissAll();
		this.onHideProfile.emit("hide");
		if (this._coreSidebarService.getSidebarRegistry(this.name).isOpened)
			this._coreSidebarService.getSidebarRegistry(this.name).toggleOpen();
		this._modalService.dismissAll();
	}
}
