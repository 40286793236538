<div class="slideout-content default-stepping sm-stepping">
    <div class="modalsd modal-slide-in sdfade" id="modals-slide-in">
      <div class="modal-dialog sidebar-sm">
        <!-- form-width -->
        <div class="modal-content pt-0 pb-0">
            <div class="modal-header d-flex justify-content-between align-items-center">
                <h5 class="modal-title mb-0" id="exampleModalLabel">{{ 'UI.k_expected_assingee' | translate }}</h5>
                <button type="button" data-dismiss="modal" id="btnClose" name="btnClose" aria-label="Close"
                    (click)="toggleSidebar('expected-assingee-sidebar')" class="btn btn-flat-secondary btn-icon" rippleEffect>
                    <i class="fa-light fa-xmark text-primary mr-50"></i>
                </button>
            </div>
            <div class="modal-body">
                <ng-scrollbar class="custom-scrollbar section-scrollbar">
                    <div class="modal-body-inner p-1">
                        <div class="content-list">
                            <ng-container  *ngFor="let esc_obj of escalationLevels">
                                <div class="item card bg-body p-1 border" >
                                    <div class="userinfo">
                                        <div class="d-flex justify-content-between align-items-center mb-1">
                                            <div>
                                                <h6 class="font-500"> {{'UI.k_level' | translate}} {{esc_obj?.level_id}} </h6>
                                                <div
                                                    class="badge {{level_map[esc_obj?.flag]?.badge}} fw-500 p-25"
                                                >
                                                    {{ 'UI.k_team_name' | translate }}: 
                                                    <span class="comment" ngbTooltip="{{ esc_obj.group_name }}">{{
                                                        esc_obj.group_name | slice:0:32
                                                    }} {{ esc_obj.group_name.length > 32 ? ".." :"" }}</span>
                                                </div>
                                                <!-- <div
                                                    class="badge {{level_map[esc_obj?.flag]?.badge}} fw-500 p-25"
                                                >
                                                    {{ 'UI.k_expertise' | translate }}: 
                                                    <span class="comment">{{
                                                        esc_obj.expertise
                                                    }}</span>
                                                </div> -->

                                            </div>
                                            <div
                                                class="badge {{level_map[esc_obj?.flag]?.badge}} fw-500 p-25"
                                            >
                                                <span class="comment">{{
                                                    level_map[esc_obj?.flag]?.label
                                                }}</span>
                                            </div>
                                        </div>
                                       
                                        <div class="d-flex align-items-center">
                                            <div class="avatar bg-light-primary mr-1">
                                                <div class="avatar-content">
                                                    <ng-container
                                                        *ngIf="esc_obj?.assignee_profile?.profile_image"
                                                    >
                                                        <img
                                                            width="32" height="32"
                                                            alt="datatable-avatar" 
                                                            class="rounded-circle"
                                                            [src]="
                                                            esc_obj?.assignee_profile?.profile_image
                                                            "
                                                        />
                                                    </ng-container>
                                                    <ng-container
                                                        id="divAssignee"
                                                        *ngIf="!esc_obj?.assignee_profile?.profile_image"
                                                    >
                                                        <span
                                                            [ngStyle]="{
                                                                'background-color':
                                                                esc_obj?.assignee_profile?.avatar_color !=
                                                                    undefined
                                                                        ? esc_obj?.assignee_profile?.avatar_color
                                                                        : '#f59518',
                                                                'width' : 32,
                                                                'height' :32
                                                            }"
                                                            class="avatar-content"
                                                        >
                                                            {{
                                                                esc_obj?.assignee_profile?.full_name
                                                                    | initials
                                                                    | slice: 0:2
                                                            }}
                                                        </span>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="text-break">
                                                <h6 class="mb-0" id="created_0" [ngbTooltip]="esc_obj?.assignee_profile?.full_name"> {{ esc_obj?.assignee_profile?.full_name | slice: 0:16 }}</h6>
                                            </div>
                                        </div>
                                        <div class="social-info">
                                            <ul class="m-0 p-0">
                                                <li *ngIf="esc_obj?.assignee_profile?.phone" class="d-flex align-items-center list-unstyled py-50">
                                                    <i class="fa-light fa-phone text-primary mr-50"></i>
                                                    <span>{{esc_obj?.assignee_profile?.phone}}</span>
                                                </li>
                                                <li *ngIf="esc_obj?.assignee_profile?.email" class="d-flex align-items-center list-unstyled py-50">
                                                    <i class="fa-light fa-envelope text-primary mr-50"></i>
                                                    <span [ngbTooltip]="esc_obj?.assignee_profile?.email">{{esc_obj?.assignee_profile?.email | slice: 0:25 }}</span>
                                                </li>
                                                <li *ngIf="esc_obj?.assignee_profile?.contact_number" class="d-flex align-items-center list-unstyled py-50">
                                                    <i class="fa-light fa-phone-rotary text-primary mr-50"></i>
                                                    <span>{{esc_obj?.assignee_profile?.contact_number}}</span>
                                                </li>
                                                <!-- <li *ngIf="esc_obj?.expected_assign_time && esc_obj?.flag != 1" class="d-flex align-items-center list-unstyled py-50">
                                                    <span>Expected Assignee Time: </span>
                                                    <span>{{esc_obj?.expected_assign_time}}</span>
                                                </li> -->
                                                <li *ngIf="esc_obj?.assignee_time" class="d-flex align-items-center list-unstyled py-50">
                                                    <span>Assignee Time: </span>
                                                    <span>{{esc_obj?.assignee_time}}</span>
                                                </li>
                                                <li *ngIf="esc_obj?.hold_time" class="d-flex align-items-center list-unstyled py-50">
                                                    <span>Hold Time: {{esc_obj?.hold_time}} mins</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>                                
                            </ng-container>
                        </div>
                    </div>
                </ng-scrollbar>
            </div>
        </div>
      </div>
    </div>
  </div>