import { Component, OnInit } from '@angular/core';
import { InfinityModules } from 'app/common/dns/types/modules';
import { GlobalConstants } from "app/app.globalConstants";
import { GridNameDesignComponent } from 'app/common/dns/components/grid-name-design/grid-name-design.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-msp-assigned-user',
  templateUrl: './msp-assigned-user.component.html',
  styleUrls: ['./msp-assigned-user.component.scss']
})
export class MspAssignedUserComponent implements OnInit {
  public isExpandSearchWidth: boolean;
  public showDataTable = false;
	public showRightsection: false;
  public searchOptions: any = {};
	public conditionOptions = [];
  public savedData = [];
	public dropdownOptions: any = [];
  public module = InfinityModules.USER;
  public permissions: any;
  public userData = [];
  public columnData;
  public empty_data: any;
  public ciCount = 3;
  

  constructor(
    private _translateService: TranslateService,
  ) { }

  ngOnInit(): void {

	this.columnData = [
		{
			label: "Name",
			key: "msp_user_name",
			pre_selected: true,
			sortkey: "msp_user_name",
			width: 180,
			component: GridNameDesignComponent,
			// callback: this.OnShowDetailSidebar,
		},
		{
			label: "email",
			key: "msp_user_email",
			pre_selected: true,
			sortkey: "msp_user_email",
			width: 200,
			component: GridNameDesignComponent,
		},
		{
			label: "status",
			key: "msp_status",
			pre_selected: true,
			sortkey: "msp_status",
			width: 150,
			component: GridNameDesignComponent,
		},
		{
			label: "Last Active",
			key: "msp_last_active_user",
			pre_selected: true,
			sortkey: "msp_last_active_user",
			width: 200,
			component: GridNameDesignComponent,
		},
		{
			label: this._translateService.instant("UI.k_action"),
			key: "action",
			width: 130,
			actions: [
				{
					label: this._translateService.instant("UI.k_edit"),
					icon: "fa-pen-to-square",
					key: "edit",
					class: "text-primary mr-50",
					listener: (raw: any) => {
						// this.editOrg(raw);
					},
				},
				{
					label: this._translateService.instant("UI.k_delete"),
					icon: "fa-trash-can",
					key: "delete",
					class: "text-danger",
					listener: (raw: any) => {
						// this.deleteOrg(raw);
					},
				},
				{
					label: this._translateService.instant("UI.k_unassign"),
					icon: "fa-user-slash",
					key: "edit",
					class: "text-primary mr-50",
					listener: (raw: any) => {
						// this.deleteOrg(raw);
					},
				},
			],
		},
	];

	this.userData = [
		{
			user_name: {
				name: "Theresa Webb",
				profile_image: "../../../../assets/images/avatars/1.png",
				email: "theresa@gmail.com",
				avatar_color: "#f59518",
			},
			status: "inactive",
			last_active: "22 May 2021",
			assigned_org: [
				{
					name: "Faderal Bank",
					profile_image: "../../../../assets/images/msp/image 21.svg",
					avatar_color: "#f59518",
				},
				{
					name: "Jio",
					profile_image: "../../../../assets/images/msp/image 23.svg",
					avatar_color: "#f59518",
				},
			],
		},
	];

    let user_permissions: any = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		if (Object.keys(user_permissions).length != 0) {
			// this.licenseData = user_permissions?.usr_lic?.keys_dict?.MONITORING;
		}
		this.permissions = user_permissions[this.module];

    this.empty_data = {
			icon: "fa-box-taped",
			msg: this._translateService.instant("Message.msg_user_skeleton_heading"),
			description: this._translateService.instant("Message.msg_user_skeleton_descr"),
			// add_callback: this.toggleAddOrganization,
			// sidebar_name: "add-organization-sidebar",
			button: this._translateService.instant("UI.k_assign_users"),
			import_csv: false,
			id: "unassign_msp_user",
		};
  }

  getFilterSearch = (e) => {};
  genericSearchFilter = (e) => {};
  getSearchWidth = (e) => {
		if (e) {
			this.isExpandSearchWidth = true;
		} else {
			this.isExpandSearchWidth = false;
		}
    
	};

  unAssignUser() {}

}
