import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-grid-skeleton',
  templateUrl: './grid-skeleton.component.html',
  styleUrls: ['./grid-skeleton.component.scss']
})
export class GridSkeletonComponent implements OnInit {

    @Input() columns : any;
    public generateFakes = [{'name':"dd"},{'name':"dd"},{'name':"dd"},{'name':"dd"},{'name':"dd"}]
    constructor() { }

    ngOnInit(): void {
        this.generateFake(5);
    }

    generateFake(count: number): Array<number> {
        const indexes = [];
        for (let i = 0; i < count; i++) {
            indexes.push(i);
        }
        return indexes;
    }

    ngOnChanges(changes: SimpleChanges): void {
    }
}
