import { keyframes } from "@angular/animations";
import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, Validators, FormControl } from "@angular/forms";

@Directive({
	selector: "[appEmailCheck]",
	providers: [{ provide: NG_VALIDATORS, useExisting: EmailValidator, multi: true }],
})
export class EmailValidator implements Validator {
	@Input("appEmailCheck") invalidemail: "";
	@Input("maxLimit") maxLimit = 25;

	validate(control: AbstractControl): ValidationErrors | [] {
		let emailInput = control.value;
		let isValid = true;
		let pattern = "[a-zA-Z0-9][a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]+$";
		// let pattern = "[a-zA-Z0-9][a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";
		if (typeof emailInput === "string") {
			emailInput = emailInput.split(",").join(";").split(";");
		}
		if (emailInput) {
			// added for limiting no. of email allowed on one field, default we have added as 25
			if (emailInput.length > this.maxLimit) {
				return { max_email: true, limit: this.maxLimit };
			}
			for (let id in emailInput) {
				let emailid = emailInput[id];
				if (typeof emailid === "object") {
					emailid = emailid.email;
				}
				if (emailid?.split("@")?.length > 2) {
					return { invalidemail: true };
				}
				if (emailid?.length > 0 && !emailid.match(pattern)) {
					return { invalidemail: true };
				}
			}
			return isValid ? null : { invalidemail: true };
		}
		return null;
	}
}
