import { Component, OnInit } from '@angular/core';
import { ColumnData, ColumnAction } from "app/common/dns/types";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { GlobalConstants } from "app/app.globalConstants";
import { InfinityModules } from "app/common/dns/types/modules";
import { TranslateService } from "@ngx-translate/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CoreConfigService } from '@core/services/config.service';
@Component({
  selector: 'app-notification-grid',
  templateUrl: './notification-grid.component.html',
  styleUrls: ['./notification-grid.component.scss']
})
export class NotificationGridComponent implements OnInit {

  notification: any;
	columns: Array<ColumnData>;
	SelectionType = SelectionType;
	ColumnMode = ColumnMode;
	pageNumber = 0;
	limit = 10;
	notificationCount = 5;
	permissions: any;
  module = InfinityModules.TAG;
  public showAll = false;
  sortKey: string = "name";
	reverse: string = "";
  public showData = true;
	public empty_data;

  constructor(
    private _coreConfigService: CoreConfigService,
    private modalService: NgbModal,
    private _translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.updateNavBar();
    let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.permissions = user_permissions[this.module];
    this.notification = [
      {"name":"Requester",
       "time":"Mar 12:05pm",
       "type":"alert",
       "event":"Requester CSV failed",
       "description":"Failed to add requester(s) from the CSV. Check CSV error log, rectify errors and try again"
      },
      {"name":"Asset",
       "time":"Mar 12:05pm",
       "type":"alert",
       "event":"Asset CSV failed",
       "description":"Failed to add Asset(s) from the CSV. Check CSV error log, rectify errors and try again"
      },

    ]

    this.columns = [
			{
				label: this._translateService.instant("UI.k_module_name"),
				key: "name",
				sortable: true,
			},
			{
				label: this._translateService.instant("UI.k_notification_time"),
				key: "time",
			},
      {
				label: this._translateService.instant("UI.k_type"),
				key: "type",
			},
			{
				label: this._translateService.instant("UI.k_event"),
				key: "event",
			},
      {
				label: this._translateService.instant("UI.k_description"),
				key: "description",
			},
		
		];
  }


  setLimit(limit): void {
		this.pageNumber = 0;
		if (limit === "all") {
			this.showAll = true;
			this.limit = this.notificationCount;
		} else {
			this.limit = Number(limit);
			this.showAll = false;
		}

		this.pageNumber = 0;
		// this.limit = limit;
	}

  
	onSort(args): void {
		if (args.sorts) {
			this.pageNumber = 0;
			this.sortKey = args.sorts[0].prop;
			if (this.sortKey === "type") {
				this.sortKey = "type.name";
			}
			if (args.sorts[0].dir == "desc") {
				this.reverse = "-";
			} else {
				this.reverse = "";
			}
		}
	}

  setPage(pageInfo) {
		this.pageNumber = pageInfo.offset;
	}
  updateNavBar() {
		if (this.permissions?.add) {
			this._coreConfigService.config = {
				layout: {
					navbar: {
						hidden: false,
						pageTitle: this._translateService.instant("UI.k_tag_management"),
						moduleName: "Tag Management",
						pageIcon: "fa-tag",
						//backgroundColor: "",
						breadcrumb: {
							type: "",
							links: [
								{
									name: this._translateService.instant("UI.k_general_settings"),
									isLink: true,
									link: "/configuration",
								},
								{
									name: this._translateService.instant("UI.k_tags"),
									isLink: false,
									link: "/",
								},
							],
						},
					},
				},
			};
		} else {
			this._coreConfigService.config = {
				layout: {
					navbar: {
						hidden: false,
						pageTitle: this._translateService.instant("UI.k_notifications"),
						moduleName: "Notification",
						pageIcon: "fa-tag",
						//	backgroundColor: "",
						breadcrumb: {
							type: "",
							links: [
								{
									name: this._translateService.instant("UI.k_general_settings"),
									isLink: true,
									link: "/configuration",
								},
								{
									name: this._translateService.instant("UI.k_tags"),
									isLink: false,
									link: "/",
								},
							],
						},
					},
				},
			};
		}
	}
}
