import { Directive, Input, OnInit } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";
import cloneDeep from "lodash-es/cloneDeep";

@Directive({
	selector: "[treeDuplicateLabelValidator]",
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: TreeDuplicateValidatorDirective,
			multi: true,
		},
	],
})
export class TreeDuplicateValidatorDirective implements OnInit, Validator {
	@Input() currentIndex = 0;
	@Input("treeConfig") treeConfig = [];
	@Input("child_key") childKey = "children";
	@Input("key") key = "label";
	@Input("currentItem") currentItem: any = null;
	treeConfigCopy = [];

	ngOnInit() {
		if (this.treeConfig) {
			this.treeConfigCopy = cloneDeep(this.treeConfig);
		}
	}

	validate(control: AbstractControl): ValidationErrors | null {
		let isDuplicate = false;
		if (this.currentIndex !== -1) {
			const data = JSON.parse(JSON.stringify(this.treeConfigCopy[this.currentIndex]));
			isDuplicate = this.validateTree(data, control.value);
		} else if (this.currentIndex === -1) {
			for (const treeConfigElement of this.treeConfigCopy) {
				if (treeConfigElement[this.key] === control.value) {
					if (this.currentItem && treeConfigElement?.id === this.currentItem?.id) {
						continue;
					} else {
						isDuplicate = true;
						break;
					}
				}
			}
		}
		return isDuplicate ? { duplicateLabel: true } : null;
	}

	validateTree = (data, value) => {
		let is_duplicate = false;
		if (Array.isArray(data)) {
			for (const valueElement of data) {
				if (valueElement[this.key]?.toLowerCase() === value?.toLowerCase()) {
					if (this.currentItem && valueElement?.id === this.currentItem?.id) {
						if (this.validateTree(valueElement[this.childKey], value)) {
							is_duplicate = true;
						} else {
							is_duplicate = false;
						}
					} else {
						is_duplicate = true;
						break;
					}
				} else if (valueElement[this.childKey]?.length > 0) {
					if (this.validateTree(valueElement[this.childKey], value)) {
						if (this.currentItem && valueElement?.id === this.currentItem?.id) {
							if (this.validateTree(valueElement[this.childKey], value)) {
								is_duplicate = true;
							} else {
								is_duplicate = false;
							}
						} else {
							is_duplicate = true;
							break;
						}
					}
				}
			}
		} else {
			if (data[this.key]?.toLowerCase() === value?.toLowerCase()) {
				if (this.currentItem && data?.id === this.currentItem?.id) {
					if (this.validateTree(data[this.childKey], value)) {
						is_duplicate = true;
					}
				} else {
					is_duplicate = true;
				}
			} else if (data[this.childKey]?.length > 0) {
				if (this.validateTree(data[this.childKey], value)) {
					is_duplicate = true;
				}
			}
		}
		return is_duplicate;
	};
}
