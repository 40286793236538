import { Component, OnInit,AfterViewInit,  Input, OnDestroy} from "@angular/core";

@Component({
	selector: "app-location-design",
	templateUrl: "./location-design.component.html",
	styleUrls: ["./location-design.component.scss"],
})
export class LocationDesignComponent implements OnInit, AfterViewInit, OnDestroy{
	@Input() data: any;
	@Input() rowindex: any;
	@Input() key: any;
	@Input() subkey: any;
	url:any;
	id:any;
	location: any;
	constructor() {
		this.data = {};
		this.key = {};
		this.url = ""
		this.id = ""
	}
	ngOnInit(): void {
		this.location = this.subkey ? this.data[this.subkey][this.key] : this.data[this.key]
	}
	ngAfterViewInit() {

	}
	ngOnDestroy() {

	}
}
