import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CoreSidebarModule } from "@core/components";
import { CoreDirectivesModule } from "@core/directives/directives";

import { NavbarModule } from "app/common/layout/components/navbar/navbar.module";
import { CoreCommonModule } from "@core/common.module";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "../../shared.module";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { FileUploadModule } from "ng2-file-upload";

import { AuditRoutingModule } from "./audit-routing.module";
import { AuditGridComponent } from "./components/audit-grid/audit-grid.component";

@NgModule({
	declarations: [AuditGridComponent],
	imports: [
		CommonModule,
		AuditRoutingModule,
		NavbarModule,
		CoreCommonModule,
		CoreSidebarModule,
		CoreDirectivesModule,
		NgbModule,
		NgSelectModule,
		FormsModule,
		SharedModule,
		SweetAlert2Module.forRoot(),
		FileUploadModule,
	],
	exports: [AuditGridComponent],
})
export class AuditModule {}
