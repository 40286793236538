import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { ReleaseService } from "app/servicedesk/release-manage/services/release-services.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import {map, catchError, takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-dns-release-related-task',
  templateUrl: './dns-release-related-task.component.html',
  styleUrls: ['./dns-release-related-task.component.scss']
})
export class DnsReleaseRelatedTaskComponent implements OnInit {
 
  @Input() featureViewTaskData;
  @Output() onHideRelateHandler = new EventEmitter<any>();
  @Output() getTaskData = new EventEmitter<any>();
  @Input() options;
  public selectedData: any = [];
  dataLoaded: boolean = false;
  taskDataList : any = [];
  taskLimit = 1000;
  taskSortKey: any = "name";
  taskReverse: any = "";
  taskPageNumber = 1;
  taskFilter: any;
  is_task_loading: boolean;
  taskCount: any;
  showTaskData: boolean = false;
  taskData : any = [];
  filterData : any = []
  taskFilterData : boolean = false;
  public taskColumnOptions = [];
  public taskConditionOptions = [];
  public savedTaskData = []
  relatedTaskId : any = [];
  showDataTable = false;
  relationMapping = {
    1: "UI.k_selecting_existing_task",
    3: "UI.k_selecting_existing_issue"
  }
  search_db_cols = [
    { name: "task", db_col: "display_id", cond: "equal to" },
    { name: "Name", db_col: "name", cond: "contains" },
    { name: "Status", db_col: "status__name", cond: "contains" },
    { name: "Status", db_col: "status__state", cond: "contains" },
    { name: "Priority", db_col: "priority__name", cond: "contains" },
    { name: "Assignee", db_col: "assignee_profile__full_name", cond: "contains" },
    // { name: "Type", db_col: "type__name", cond: "contains" },
  ];
  public showRightsection: false;
  constructor(
    private _coreSidebar: CoreSidebarService,
    private _releaseService: ReleaseService,
    private _toastr: ToastrService,
    private _translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.getTaskList()
    
  }

  getFilterData =()=>{
    this.relatedTaskId = [this.featureViewTaskData?.task_id]
    for (var idx in this.featureViewTaskData?.related_task) {
      this.relatedTaskId.push(this.featureViewTaskData?.related_task?.[idx]?.task_id)
    }
    this.filterData = this.taskData?.filter((item) =>
      this.relatedTaskId.indexOf(item?.task_id) == -1 && this.featureViewTaskData?.relate_type == item?.type?.id
        )
      this.taskCount = this.filterData?.length;
  }
  getTaskList =()=> {
		const payload = {
			page: 1,
			items_per_page: this.taskLimit,
			sort: this.taskSortKey,
			reverse: this.taskReverse,
			filter: JSON.stringify(this.taskFilter),
			module: "task",
		};
		this.is_task_loading = true;
		this._releaseService.getReleaseTaskList(payload, this.featureViewTaskData?.ref_id).subscribe(
			(response) => {
				if (response) {
          this.taskData = response["results"];					
					this.taskCount = response["count"];
          this.getFilterData()
					this.showTaskData = true;
					this.is_task_loading = false;
					this.getFilterOptions();
					this.getSavedFilters()
				}
        this.dataLoaded = true
			},
			(error) => {
				this.is_task_loading = false;
				//todo
			}
		);
	}
  getSavedFilters(): void {
		this._releaseService
			.getSavedFilter({ module: "task" })
			.pipe(
				map((response: any) => {
					if (response) {
						this.savedTaskData = response;
					}
				})
			)
			.subscribe();
	}
  getFilterOptions(): void {
		this._releaseService.getFilterOptions({ moduleName: "task" }).subscribe(
			(response) => {
				if (response) {
					this.taskColumnOptions = response;
				} else {
					this.taskColumnOptions = [];
				}
			},
			(error) => {
				this.taskColumnOptions = [];
			}
		);
	}
  getTaskFilterSearch = ($event) => {
		this.taskPageNumber = 1;
		let dict = {};
		this.taskFilter = [];
		dict["ui_display"] = $event.userSearchData;
		dict["filter_string"] = $event.userSearchString;
    let filterCleared = false
    if ($event?.filterItem?.length == 0){
      filterCleared = true
    }
		dict["filter_item"] = $event.filterItem;
		dict["type"] = $event.type;
		dict["module"] = "task";
		this.taskFilter.push(dict);
		const payload = {
			page: 1,
			items_per_page: this.taskLimit,
			sort: this.taskSortKey,
			reverse: this.taskReverse,
			filter: JSON.stringify(this.taskFilter),
			module: "task",
		};
		this.getFilteredTaskList(payload, filterCleared);
	};
  getFilteredTaskList(payload, filterCleared: any=false) {
		this.is_task_loading = true;
		this._releaseService.getReleaseTaskList(payload, this.featureViewTaskData?.ref_id).subscribe(
			(response) => {
				if (response) {
					this.taskData = response["results"];
					this.taskCount = response["count"];
          this.getFilterData();
					this.showTaskData = true;
					if (filterCleared== true){
            // filter cleared
            this.taskFilterData = false
          }else{
            this.taskFilterData = true;
          }
					this.is_task_loading = false;
					this.getSavedFilters();
				}
			},
			(error) => {
				this.is_task_loading = false;
				//todo
			}
		);
	}
  toggleSidebar(key){
    this._coreSidebar.getSidebarRegistry(key).close();
    this.onHideRelateHandler.emit();
  }

  nameExtractorHandler(data:any){
    return data
      ?.split(' ')
      .map(n => n[0])
      .join('');
  }
  onSelectTask = ($event) =>{
    this.selectedData.push(($event))
    this.relatedTaskId.push($event?.task_id)
    this.relateTask()
  }
  relateTask(){
    this.dataLoaded = false;
    let rel_obj = {}
    rel_obj["ref_id"] = this.featureViewTaskData?.ref_id;
    rel_obj['parent_obj'] = { "task_id": this.featureViewTaskData?.task_id, "display_id": this.featureViewTaskData?.display_id}
    rel_obj['child_obj'] = this.selectedData
    rel_obj["type_id"] = this.selectedData?.[0]?.type?.id
    this._releaseService.addTaskRelation(rel_obj).subscribe(
      (response: any) => {
        if(response["status"] == "success") {
          this.filterData = this.filterData?.filter((item) =>
          this.relatedTaskId.indexOf(item?.task_id) == -1
            )
          this.taskCount = this.filterData?.length;
          this._toastr.success(
            this._translateService.instant(
              response?.message
            ),
            this._translateService.instant(
              "Message.msg_save_success"
            ),
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              positionClass: "toast-top-right",
            }
          );
        }
        else{
          this._toastr.error(
            this._translateService.instant(
              response?.message
            ),
            this._translateService.instant(
              "Error.err_save_failed"
            ),
            {
              toastClass: "toast ngx-toastr",
              closeButton: true,
              positionClass: "toast-top-right",
            }
          );
        }
        this.getTaskData.emit()
        this.dataLoaded = true;
      }
      )
  }
  genericTaskSearchFilter = (search_str) => {
		search_str = search_str.trim();
		let filter_options = [];
		this.search_db_cols.forEach((item) => {
			filter_options.push({
				condition: item.cond,
				db_col: item.db_col,
				value: search_str,
				relation: "or",
			});

			if (filter_options.length == this.search_db_cols.length) {
				let dict = { filter_string: filter_options };
				for (let i = 0; i < this.taskFilter?.length; i++) {
					dict["filter_string"].push(this.taskFilter[i]);
				}

				this.taskFilter = [];
				this.taskFilter.push(dict);
				this.taskPageNumber = 1;
				const payload = {
					page: 1,
					items_per_page: this.taskLimit,
					sort: this.taskSortKey,
					reverse: this.taskFilter,
					filter: JSON.stringify(this.taskFilter),
					module: "task",
				};
				this.getFilteredTaskList(payload);
			}
		});
	};
}
