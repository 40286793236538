import {
	Component,
	Input,
	Output,
	EventEmitter,
	ViewChild,
	OnChanges,
	Injectable,
	OnInit,
	SimpleChange,
} from "@angular/core";
import {
	TreeviewI18n,
	TreeviewItem,
	TreeviewConfig,
	DropdownTreeviewComponent,
	TreeviewHelper,
	DownlineTreeviewItem,
	TreeviewEventParser,
	OrderDownlineTreeviewEventParser,
	TreeviewComponent
} from "ngx-treeview";

import { isNil, remove, reverse } from "lodash";
import { TreeDataService } from "../single-tree-dropdown/tree-data.service";
import { TranslateService } from "@ngx-translate/core";


@Injectable()
export class ProductTreeviewConfig extends TreeviewConfig {
	hasAllCheckBox = true;
	hasFilter = true;
	hasCollapseExpand = false;
	maxHeight = 200;
}

@Component({
  selector: 'app-tree-dropdown-with-icon',
  templateUrl: './tree-dropdown-with-icon.component.html',
  styleUrls: ['./tree-dropdown-with-icon.component.scss'],
  providers: [
		TreeDataService,
		{ provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser },
		{ provide: TreeviewConfig, useClass: ProductTreeviewConfig },
	],
})
export class TreeDropdownWithIconComponent implements OnInit {

  @Input() config: TreeviewConfig;
	@Input() items: TreeviewItem[];
	@Input() value: any;
	@Input() id: any;
	@Input() treeInputConfig: any;
	@Output() itemChange = new EventEmitter<any>();
  @Output() showTemplateModal = new EventEmitter<any>();
	@Output() editTemplate = new EventEmitter<any>();
	@Input() showTemplateEdit:boolean;
	@Input() headingText;
	@Input() selectedTreeData;
	@Input() showPreviewIcon = true;
	@ViewChild(DropdownTreeviewComponent, { static: false }) dropdownTreeviewComponent: DropdownTreeviewComponent;
	filterText: string;
	rows: string[];
	allTreeData: any;
	// selectedTreeData = this._translateService.instant("UI.k_select_option");
	buttonClasses = [
		"",
		"btn-outline-primary",
		"btn-outline-secondary",
		"btn-outline-success",
		"btn-outline-danger",
		"btn-outline-warning",
		"btn-outline-info",
		"btn-outline-light",
		"btn-outline-dark",
	];
	buttonClass = this.buttonClasses[0];
	itemTemplate = [];
	dynamicText = [];

	constructor(public i18n: TreeviewI18n, private _translateService: TranslateService) {
		this.config = TreeviewConfig.create({
			hasAllCheckBox: false,
			hasCollapseExpand: false,
			hasFilter: false,
			maxHeight: 300,
		});
	}

	ngOnInit(): void {}

	addDynamicId(array) {
		array.forEach((ele) => {
			if (ele["internalChildren"]) {
				this.addDynamicId(ele["internalChildren"]);
			} else {
				var iDiv = document.createElement("span");
				iDiv.id = "block" + ele.value;
				iDiv.className = "block";
				document.getElementsByTagName("ngx-dropdown-treeview")[0].childNodes[0].childNodes[0].appendChild(iDiv);
			}
		});
	}

	ngOnChanges(changes: SimpleChange): void {
		// console.log("ngOnChange called", changes, this.value);
		// this.updateSelectedItem();
		const selectedItem = TreeviewHelper.findItemInList(this.items, this.value);
		this.treeviewFilter(selectedItem);
	}

	select(item: DownlineTreeviewItem[]): void {

		this.treeviewFilter(item, true);
	}

	treeviewFilter(item, emit_event = false) {
		let customTreeData = "";
		this.allTreeData?.forEach((e, index) => {
			if (item && item?.value === e?.item?.value) {
				const item = e["item"];
				const value = item.value;
				item["id"] = this.id;
				const texts = [item?.text];
				let parentId = "";
				let parent = e?.parent;
				while (!isNil(parent)) {
					texts.push(parent?.item.text);
					parentId = parent?.item.value;
					parent = parent?.parent;
				}
				const reverseTexts = reverse(texts);
				const row = `${reverseTexts.join(" / ")}`;
				customTreeData = row;
				item["parent"] = parentId;
			} else {
				// console.log("minaz",item['value'] === e['parent']['item'].value, item['value'] , e['parent']);
				if (item && item?.value && e?.parent && e?.parent?.item && item?.value === e?.parent?.item?.value) {
					const texts = [];
					let parent = e?.parent;
					while (!isNil(parent)) {
						texts.push(parent.item.text);
						parent = parent?.parent;
					}
					const reverseTexts = reverse(texts);
					const row = `${reverseTexts.join(" / ")}`;
					customTreeData = row;
				} else if (
					item &&
					item?.value &&
					e?.parent?.parent &&
					e?.parent?.parent?.item &&
					item["value"] === e?.parent?.parent?.item?.value
				) {
					// console.log("else exe", item['text'] , item['internalChildren'][0].text,item['internalChildren'][0].value);
					// console.log(item['value'] === e['parent']['parent']['item'].value, e['parent']['parent']);
					let parent = e?.parent?.parent?.parent;
					if (parent) {
						customTreeData = parent.item.text + "/" + item["text"];
					} else {
						customTreeData = item["text"];
					}
				}
			}
		});
		if (customTreeData) {
			this.selectedTreeData = customTreeData;
			if (emit_event) {
				item["full_path_text"] = this.selectedTreeData;
				this.itemChange.emit(item);
			}
		} else {
			this.selectedTreeData = this.selectedTreeData;
		}
		var iDiv = document.createElement("span");
		iDiv.id = "block" + this.id + item?.value;
		iDiv.className = "block text-truncate";
		document.getElementById(this.id).childNodes[0].childNodes[0].textContent = "  ";
		document.getElementById(this.id)?.childNodes[0]?.childNodes[0]?.appendChild(iDiv);
		document.getElementById("block" + this.id + item?.value).innerHTML = this.selectedTreeData;
		document.getElementById(this.id)?.childNodes[0]["classList"]?.remove("show");
		document.getElementById(this.id)?.childNodes[0]?.childNodes[1]["classList"]?.remove("show");
	}

	setTextEvent(e) {
		const item = e["item"];
		const value = item.value;


		this.itemChange.emit(item);
		const texts = [item.text];
		let parent = e["parent"];
		while (!isNil(parent)) {
			texts.push(parent.item.text);
			parent = parent?.parent;
		}
		const reverseTexts = reverse(texts);
		const row = `${reverseTexts.join(" / ")}`;
		return row;
	}

	onSelectedChange(downlineItems: DownlineTreeviewItem[]): void {
		this.allTreeData = downlineItems;
		this.rows = [];
		downlineItems.forEach((downlineItem) => {
			const item = downlineItem.item;
			const value = item.value;
			const texts = [item.text];
			let parent = downlineItem?.parent;
			while (!isNil(parent)) {
				texts.push(parent.item.text);
				parent = parent?.parent;
			}
			const reverseTexts = reverse(texts);
			const row = `${reverseTexts.join(" / ")}`;
			this.rows.push(row);
		});
		if (!isNil(this.items)) {
			const selectedItem = TreeviewHelper.findItemInList(this.items, this.value);
			//console.log(selectedItem, "selectedItem------------------")
			this.treeviewFilter(selectedItem);
		}
	}
  showTemplateDetail(item){
    this.showTemplateModal.emit(item)
  }
	clickEditTemplate(item){
    this.editTemplate.emit(item)
  }
} 

