import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { customSearch, isValidDateRange } from "app/common/utils/utils";
import { ChangeViewService } from "app/servicedesk/change-management/services/change-view.service";
import { environment } from "environments/environment";
import moment from "moment";
import { FlatpickrOptions } from "ng2-flatpickr";
import { ToastrService } from "ngx-toastr";
import { throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";


@Component({
  selector: 'app-dns-view-task',
  templateUrl: './dns-view-task.component.html',
  styleUrls: ['./dns-view-task.component.scss']
})
export class DnsViewTaskComponent implements OnInit {
	@Input() options: any = [];
	@Input() change_data: any = {};
	@Input() task_id = "-1";
	@Output() onHideTask = new EventEmitter(false);
	@ViewChild("datepicker") dueDate: FlatpickrOptions;
	public task_obj: any = {};
	public disableSaveBtn: boolean = false;
	public task_title = this._translateService.instant("UI.k_view_task");
	public priority: any = [];
	public status: any = [];
	public users_list: any = [];
	public basicDateOptions: FlatpickrOptions = {
	    dateFormat: "%b %d, %Y",
	    altInput: true
	}
	public basicDateTimeOptions: FlatpickrOptions = {
		altInput: true,
		enableTime:true,
		altInputClass: "form-control flat-picker flatpickr-input",
	};

	constructor(
		private _coreSidebarService: CoreSidebarService,
		private _toastr: ToastrService,
		private _translateService: TranslateService,
		private _changeService: ChangeViewService
	) {}

	ngOnInit(): void {
		this.priority = this.options?.priority;
		this.status = this.options?.task_status;
		this.users_list = this.options?.users;
		this.task_obj["ref_id"] = this.change_data?.change_id;
		this.task_obj["ref_display_id"] = this.change_data?.display_id;
		if (this.change_data?.due_date) {
			this.basicDateTimeOptions["maxDate"] = this.change_data?.due_date;
		}
		if (this.task_id != "-1") {
			this.task_title = this._translateService.instant("UI.k_task_edit");
			this.getTaskData();
		}
	}
	toggleSidebar = (name): void => {
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
		this.onHideTask.emit();
	};

	getTaskData() {
		this._changeService.getChangeTask(this.task_id, {}).subscribe(
			(response) => {
				if (response) {
					this.task_obj = response?.data;
				}
			},
			(error) => {
				//todo
			}
		);
	}

	SaveTask = (data) => {
		if (data.form.valid === true) {
			if (this.task_obj.due_date instanceof Array) {
				this.task_obj.due_date = this.task_obj.due_date[0];
				// this.task_obj.due_date = this.task_obj.due_date.toDateString();
			}
			this.disableSaveBtn = true;
			this._changeService
				.saveChangeTask(this.task_obj)
				.pipe(
					map((response: any) => {
						this.disableSaveBtn = false;
						if (response) {
							if (response["status"] == "success") {
								this.task_obj = {};
								this._toastr.success(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Message.msg_save_success"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
								this.toggleSidebar("view-task-sidebar");
							} else {
								this._toastr.error(
									this._translateService.instant(response["message"], {
										existing_rule: response?.data?.existing_rule,
									}),
									this._translateService.instant("Error.err_save_failed"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							}
						}
						// Success
					}),
					catchError((error) => {
						this._toastr.error(
							this._translateService.instant(error.error.error.message),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						this.disableSaveBtn = false;
						return throwError(error.statusText);
					})
				)
				.subscribe();
		}
	};

	onStatusChange = (data) => {
		this.task_obj.status = data;
	};

	statusBadgeChange = (data) => {
		this.task_obj[data["field"]] = data?.event ? data.event : {};
	};

	customSearchFn(term: string, item: any) {
		return customSearch(term, item, "full_name", "email");
	}

	onAssigneeChange = ($event) => {
		this.task_obj["assignee_profile"] = {
			profile_id: $event?.profile_id,
			name: $event?.full_name,
			full_name: $event?.full_name,
			email: $event?.email,
		};
	};

	ngOnDestroy() {
		this.dueDate?.flatpickr.close();
	}
}
