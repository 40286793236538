import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ChangeViewService } from 'app/servicedesk/change-management/services/change-view.service';
import { map, catchError } from "rxjs/operators";

@Component({
  selector: 'app-dns-change-incident-view',
  templateUrl: './dns-change-incident-view.component.html',
  styleUrls: ['./dns-change-incident-view.component.scss']
})
export class DnsChangeIncidentViewComponent implements OnInit {

  @Output() onHideConvChnToInciView = new EventEmitter(false);
  @Input() public incident_data: any = {};
  @Input() public incident_id : any = "";
  dataLoaded : boolean = false
  	location_name = ""

	constructor(
		private _changeViewService: ChangeViewService,
	) { }


	ngOnInit(): void {
		if (!this.incident_data.hasOwnProperty("display_id")) {
			this._changeViewService
			.getIncident(this.incident_id,{'return_main_info':true})
			.pipe(
				map(
					(response) => {
						this.incident_data = response?.incident;
						this.dataLoaded = true						
					},
					(error) => {
						//todo
					}
					)
		)
		.subscribe();
		}
		else{
			this.dataLoaded = true
		}

		this.location_name = this?.incident_data?.requester?.location?.location_name ||
				this?.incident_data?.requester?.location?.city ||
				this?.incident_data?.requester?.location?.state ||
				this?.incident_data?.requester?.location?.country;
	}

	closeSidebar = (name): void => {
		this.onHideConvChnToInciView.emit(true);
	};

}
