import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-grid-cell-card",
	templateUrl: "./grid-cell-card.component.html",
	styleUrls: ["./grid-cell-card.component.scss"],
})
export class GridCellCardComponent implements OnInit {
	data: any;
	public rowindex: any;
	public avatarBackgroundColor:string = '';
	constructor() {
		this.data = {};
	}

	ngOnInit(): void {
	if(this.data?.profile?.avatar_color){
		this.avatarBackgroundColor = this.data?.profile.avatar_color
	}
	else if(this.data?.avatar_color){
	    this.avatarBackgroundColor = this.data?.avatar_color
    }
    else {
        this.avatarBackgroundColor = '#f59518'
    }

    }
}
