import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MspMenuComponent } from './msp-menu.component';
import { CoreMenuModule } from '@core/components';
import { CoreCommonModule } from '@core/common.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { RouterModule } from '@angular/router';
import { SystemMenuModule } from 'app/base/system-menu/system-menu.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'app/shared.module';
import { MspPortalModule } from 'app/msp-portal/msp-portal.module';
import { NgScrollbarModule } from 'ngx-scrollbar';



@NgModule({
  declarations: [
    MspMenuComponent
  ],
  imports: [
		CoreMenuModule,
		CommonModule,
		CoreCommonModule,
		PerfectScrollbarModule,
		RouterModule,
		SystemMenuModule,
		NgbModule,
		SharedModule,
		MspPortalModule,
		NgScrollbarModule,
	],
  exports: [MspMenuComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MspMenuModule { }
