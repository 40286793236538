import { Component, EventEmitter, OnInit, Output , Input} from '@angular/core';
import { NgForm } from '@angular/forms';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { TranslateService } from '@ngx-translate/core';
import { ChangeViewService } from '../../services/change-view.service';
@Component({
  selector: 'app-analysis-submition',
  templateUrl: './analysis-submition.component.html',
  styleUrls: ['./analysis-submition.component.scss']
})
export class AnalysisSubmitionComponent implements OnInit {
  public submitVlanName:'';
  public selectedRisk: any
  public mitigationPlan = ''
  public attachmentDescription = ''
  public riskOption:any;
  @Output() riskObj: EventEmitter<any> = new EventEmitter<any>();
  @Input() action: String;
  @Input() riskid: Number;
  @Input() riskDataEdit : any;
  public riskDataEditDummy : any;
  public risk_title = this._translateService.instant("UI.k_task_add");
  public risk : any
  public riskidtemp : any
  constructor(private _coreSidebarService: CoreSidebarService,
    private _translateService: TranslateService,
    public _changeService: ChangeViewService
    ) { }

  ngOnInit(): void {
    this.getOptions();

    if (this.riskDataEditDummy.name?.length>2){
      this.risk_title = this._translateService.instant("UI.k_task_edit");
      this.editRisk()
  }

  }
  editRisk(){
    this.submitVlanName=this.riskDataEditDummy.name
    this.selectedRisk=this.riskDataEditDummy.risk_type
    this.mitigationPlan=this.riskDataEditDummy.mitigation_plan
    this.attachmentDescription=this.riskDataEditDummy.description
    this.riskidtemp=this.riskDataEditDummy.id
  }
  toggleSidebar = (name): void => {
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
    this.riskObj.emit("False")
	};

  onSubmit(form: NgForm) {
    if (form.form.value.submitVlanName && form.form.valid === true) { 
      if (form.submitted === true) {
          const riskObj = {
            'name': form.value['submitVlanName'],
            'mitigation_plan': form.value['mitigationPlan'],
            'risk_type': form.value['selrisk'],
            'description': form.value['description']
          };
        
          if (this.riskidtemp) {
            riskObj['id'] = this.riskidtemp;
          }
          
        this.toggleSidebar('analysis-submition-sidebar-risk');
        this.riskObj.emit(riskObj)
      }
    }
  }
  getOptions() {
		this._changeService.getOptions({ for_add_change: true }).subscribe(
			(response) => {
				if (response) {
					if (response) {
            this.riskOption = response?.risk_type
					}
				}
			},
			(error) => {
				//todo
			}
		);
	}
  ngOnChanges(changes) {
    this.riskDataEditDummy = Object.assign({}, this.riskDataEdit);
  }
}
