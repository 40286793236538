<div class="slideout-content default-slideout">
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
		<div class="modal-dialog">
			<div class="modal-content pt-0">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">
						{{ "UI.k_add" | translate }} {{ "UI.k_change" | translate }}
					</h5>
					<button
						id="btnClose"
						name="Close"
						[attr.data-testid]="'btnClose'"
						type="button"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						(click)="closeSidebar()"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body flex-auto-height">
					<div class="full-body-height">
						<div class="main-content flex-height activeSidebarPanel">
							<div class="main-sec">
								<div class="action-section">
									<form #addChangeMainForm="ngForm" class="full-height">
										<div class="add-incident bg-card h-100 d-flex">
											<div class="left-sec">
												<ng-scrollbar class="custom-scrollbar section-scrollbar">
													<div class="left-inner-sec">
														<div class="p-1 border-bottom">
															<div
																class="d-flex justify-content-between align-items-center mb-75"
															>
																<div class="fw-500 text-dark" for="basic-request">
																	{{ "UI.k_requester" | translate }}
																	{{ "UI.k_details" | translate | lowercase }}
																</div>
															</div>
															<div class="d-flex align-items-center mb-1">
																<div class="avatar mr-half">
																	<div
																		class="avatar"
																		*ngIf="requester?.profile_image"
																	>
																		<img
																			[src]="requester?.profile_image"
																			alt="Avatar"
																			width="32"
																			height="32"
																		/>
																	</div>
																	<div
																		*ngIf="!requester?.profile_image"
																		id="divInitial"
																		class="avatar-content"
																		[ngStyle]="{
																			'background-color': requester?.avatar_color
																				? requester?.avatar_color
																				: '#f59518'
																		}"
																	>
																		{{
																			requester?.full_name | initials | slice: 0:2
																		}}
																	</div>
																</div>
																<div>
																	<div
																		class="fw-500 text-dark mb-0 text-break"
																		id="divFullname"
																	>
																		{{ requester?.full_name }}
																	</div>

																	<span class="text-break" id="divEmail">{{
																		requester?.email
																	}}</span>
																</div>
															</div>
															<div
																class="d-flex justify-content-start align-items-center"
															>
																<div *ngIf="requester?.base_location?.city" class="mr-1">
																	<span class="mr-50 xs-font">
																		<i class="fa-light fa-location-dot"></i>
																	</span>
																	<span class="sm-font">{{
																		requester?.base_location?.city
																	}}</span>
																</div>
																<div *ngIf="requester?.phone_number">
																	<span class="mr-50 xs-font">
																		<i class="fa-light fa-phone"></i>
																	</span>
																	<span class="sm-font" id="spPhone">{{
																		requester?.phone_number?.internationalNumber
																	}}</span>
																</div>
															</div>
															<div
																class="card bg-body p-1 mt-1 border"
																*ngIf="
																	addChange?.asset?.customized_search_fields?.length > 0
																"
															>
																<div class="d-flex justify-content-between align-items-center">
																	<span class="sm-font fw-500 title-color">{{
																		"UI.k_asset" | translate
																	}}</span>
																	<div class="d-flex align-items-center">
																		<button
																			type="button"
																			id="btnRemoveAssetAction"
																			name="btnRemoveAssetAction"
																			[attr.data-testid]="'btnRemoveAssetAction'"
																			(click)="remove_asset()"
																			class="btn btn-sm btn-icon btn-flat-primary waves-effect"
																			placement="left"
																			ngbTooltip="{{ 'UI.k_remove_asset' | translate }}"
																			rippleEffect
																		>
																			<i class="fa-light fa-times"></i>
																		</button>
																	</div>
																</div>
																<div class="mt-50 align-items-baseline justify-content-between asset-field">
																	<div *ngFor="let cmdb_field of addChange?.asset?.customized_search_fields; let cmdbIndx = index">
																		<div class="impacted-asset-fields" id="cmdb-field-{{cmdbIndx}}" name="cmdb-field-{{cmdbIndx}}" attr.data-testid="cmdb-field-{{ cmdbIndx }}">
																			<div class="d-flex justify-content-between align-items-center">
																				<span class="xs-font">{{ cmdb_field?.label | translate }}</span>
																			</div>
																			
																			<div class="">
																				<span
																					*ngIf="
																						!addChange?.asset?.[cmdb_field?.custom_field_id ? cmdb_field?.custom_field_id : cmdb_field?.key]
																					"
																					>-</span
																				>
																				<span
																					class="xs-font title-color wrap-text-around"
																				>
																				{{cmdb_field?.custom_field_id ? addChange?.asset?.[cmdb_field?.custom_field_id] :addChange?.asset?.[cmdb_field?.key] }}
																				</span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<ng-container>
															<div
																class="form-group d-flex justify-content-between cursor-pointer align-items-center border-bottom pt-1 px-1 highlighted"
																(click)="toggleReport()"
																id="lblReport"
																[ngClass]="
																	isReport === false
																		? 'border-bottom  pb-1'
																		: 'border-0'
																"
															>
																<div
																	id="btnReported"
																	class="title-color fw-500"
																	for="basicInput"
																>
																	{{ "UI.k_reportedby" | translate }}
																</div>
																<span
																	*ngIf="!isReport"
																	id="btnReporter"
																	name="reporter"
																>
																	<span
																		id="btnReportedPlus"
																		placement="left"
																		ngbTooltip="{{
																			'UI.k_reporter_info' | translate
																		}}"
																	>
																		<i
																			class="fa-light fa-plus text-primary cursor-pointer ml-50"
																		></i>
																	</span>
																</span>
																<span *ngIf="isReport" id="btnReporter" name="reporter">
																	<span id="btnReportedMinus">
																		<i
																			class="fa-light fa-minus text-primary cursor-pointer ml-50"
																		></i>
																	</span>
																</span>
															</div>
															<div *ngIf="isReport" class="border-bottom my-1 px-1">
																<div class="form-group">
																	<label for="txtReqRequesterName" class=""
																		>{{ "UI.k_name" | translate }}
																	</label>
																	<input
																		type="text"
																		[(ngModel)]="addChange.reporter_name"
																		#TDReqNameRef="ngModel"
																		name="txtReqRequesterName"
																		id="txtReqRequesterName"
																		class="form-control form-control-sm"
																		placeholder="{{ 'UI.k_name' | translate }}"
																		minlength="3"
																		maxlength="100"
																		[class.error]="
																			TDReqNameRef.invalid &&
																			addChangeMainForm.submitted
																		"
																		[appWhiteSpaceCheck]="addChange.reporter_name"
																	/>
																	<span
																		*ngIf="
																			addChangeMainForm.submitted &&
																			TDReqNameRef.invalid
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger xs-font"
																			*ngIf="
																				TDReqNameRef.errors.minlength ||
																				TDReqNameRef.errors.maxlength
																			"
																		>
																			{{
																				"UI.k_requester_validation" | translate
																			}}</small
																		>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDReqNameRef.errors.pattern"
																			>{{
																				"UI.k_leading_trailing_space_validation"
																					| translate
																			}}</small
																		>
																	</span>
																</div>
																<div class="form-group">
																	<label for="txtReqRequesterEmail" class=""
																		>{{ "UI.k_email" | translate }}
																	</label>
																	<input
																		type="text"
																		[(ngModel)]="addChange.reporter_email"
																		#TDReqMailRef="ngModel"
																		name="txtReqRequesterEmail"
																		id="txtReqRequesterEmail"
																		class="form-control form-control-sm"
																		placeholder="{{ 'UI.k_email' | translate }}"
																		pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$"
																		placeholder="john.doe@email.com"
																		aria-label="john.doe"
																		[appWhiteSpaceCheck]="addChange.reporter_email"
																	/>
																	<span
																		*ngIf="
																			addChangeMainForm.submitted &&
																			TDReqMailRef.invalid
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDReqMailRef.errors.pattern"
																			>{{
																				"UI.k_alert_email_invalid" | translate
																			}}</small
																		>
																		<small
																			class="form-text text-danger"
																			*ngIf="TDReqMailRef.errors?.whitespace"
																			>{{
																				"UI.k_leading_trailing_space_validation"
																					| translate
																			}}</small
																		>
																	</span>
																</div>
																<div class="form-group">
																	<label class="" for="txtReqRequesterPhone"
																		>{{ "UI.k_contact_number" | translate }}
																	</label>
																	<div class="w-100">
																		<ngx-intl-tel-input
																			class="w-100"
																			[cssClass]="'custom form-control w-100'"
																			[preferredCountries]="preferredCountries"
																			[enableAutoCountrySelect]="true"
																			[enablePlaceholder]="true"
																			[searchCountryFlag]="true"
																			[searchCountryField]="[
																				SearchCountryField.Iso2,
																				SearchCountryField.Name
																			]"
																			[selectFirstCountry]="false"
																			[selectedCountryISO]="
																				CountryISO.UnitedStates
																			"
																			[maxLength]="15"
																			[phoneValidation]="true"
																			[separateDialCode]="separateDialCode"
																			[numberFormat]="PhoneNumberFormat.National"
																			name="txtReqRequesterPhone"
																			id="txtReqRequesterPhone"
																			[(ngModel)]="addChange.reporter_phone"
																			#TDPhoneRef="ngModel"
																			[ngClass]="{
																				'is-invalid':
																					addChangeMainForm.submitted &&
																					TDPhoneRef.errors
																			}"
																		>
																		</ngx-intl-tel-input>
																		<span
																			*ngIf="
																				addChangeMainForm.submitted &&
																				TDPhoneRef.invalid
																			"
																			class="invalid-form"
																		>
																			<small
																				class="form-text text-danger"
																				*ngIf="TDPhoneRef.errors"
																				>{{
																					"UI.k_phone_number_validation"
																						| translate
																				}}</small
																			>
																		</span>
																	</div>
																</div>
															</div>
															<div class="px-1">
																<div class="form-group">
																	<label for="selDepartment" class="form-label">
																		{{ "UI.k_change_manager" | translate }}*</label
																	>
																	<ng-select
																		[items]="dropdown.users"
																		bindLabel="full_name"
																		class="form-control"
																		[(ngModel)]="addChange.change_manager"
																		name="selManager"
																		id="selManager"
																		placeholder="{{ 'UI.k_select' | translate }} {{
																			'UI.k_change_manager' | translate
																		}}"
																		[multiple]="false"
																		[closeOnSelect]="true"
																		[searchable]="true"
																		[clearable]="false"
																		#TDChangeManagerRef="ngModel"
																		required
																	>
																	<ng-template ng-label-tmp let-item="item">
																		<span
																			class="d-flex ng-value-label align-items-center"
																		>																			
																			<span class="font-small-2" [ngbTooltip]="item.full_name" >
																				{{ item?.full_name | slice: 0:28 }}
																				{{
																					item?.full_name?.length > 28
																						? ".."
																						: ""
																				}}
																			</span>
																			
																		</span>
																	</ng-template>
																		<ng-template
																			ng-option-tmp
																			let-item="item"
																			let-index="index"
																			let-search="searchTerm"
																		>
																			<span class="d-flex">
																				<div *ngIf="item?.profile_image">
																					<div class="avatar mr-50 ml-25">
																						<span class="avatar-content">
																							<img
																								class="rounded-circle"
																								src="{{
																									item?.profile_image
																								}}"
																								alt="User"
																								width="32"
																								height="32"
																							/>
																						</span>
																					</div>
																				</div>
																				<div *ngIf="!item?.profile_image">
																					<div
																						class="avatar mr-50 ml-25"
																						[ngStyle]="{
																							'background-color':
																								item.avatar_color
																									? item.avatar_color
																									: '#f59518'
																						}"
																						style="
																							align-content: center;
																							width: 32px;
																							height: 32px;
																						"
																					>
																						<div class="avatar-content">
																							{{
																								item.full_name
																									| initials
																									| slice: 0:2
																							}}
																						</div>
																					</div>
																				</div>

																				<div class="cell-line-height">
																					<div
																						class="font-medium-1 font-weight-bold line-height-1 mb-0"
																					>
																						{{
																							item?.full_name
																								| slice: 0:28
																						}}
																						{{
																							item?.full_name?.length > 28
																								? ".."
																								: ""
																						}}
																					</div>
																					<span
																						class="text-muted font-small-2"
																					>
																						{{ item?.email | slice: 0:28 }}
																						{{
																							item?.email.length > 28
																								? ".."
																								: ""
																						}}</span
																					>
																				</div>
																			</span>
																		</ng-template>
																	</ng-select>
																	<div
																		*ngIf="
																			addChangeMainForm.submitted &&
																			TDChangeManagerRef.invalid
																		"
																		class="invalid-form"
																	>
																		<small
																			class="form-text text-danger xs-font"
																			*ngIf="TDChangeManagerRef.errors.required"
																			>{{ "UI.k_field_required" | translate }}
																		</small>
																	</div>
																</div>
																<div class="form-group">
																	<div
																		class="d-flex justify-content-between align-items-center"
																	>
																		<label class="" for="selApprovers">{{
																			"UI.k_change_implementer" | translate
																		}}</label>
																		<small
																			id="selApprovers"
																			name="selApprovers"
																			class="text-primary cursor-pointer"
																			(click)="assignToMyself(modalGroup)"
																			>{{ "UI.k_take_it" | translate }}</small
																		>
																	</div>
																	<ng-select
																		[items]="dropdown?.teams_and_self_assign"
																		(clear)="selfAssignSel = false"
																		[multiple]="false"
																		[closeOnSelect]="true"
																		[(ngModel)]="addChange.support_group_tmp"
																		[searchable]="true"
																		(change)="groupChange(dropdown.teams)"
																		bindLabel="name"
																		bindValue="team_id"
																		name="selAssignee"
																		id="selAssignee"
																		class=""
																		placeholder="{{ 'UI.k_select' | translate }} {{
																			'UI.k_assignee' | translate
																		}}"
																	>
																		<ng-template ng-label-tmp let-item="item">
																			<span class="d-flex">
																				<span
																					class="avatar mr-50 avatar-sm"
																					[ngClass]="bg - light - primary"
																				>
																					<div
																						class="avatar-content"
																						[ngStyle]="{
																							'background-color':
																								stringToColor(item.name)
																						}"
																					>
																						{{
																							item.name
																								| initials
																								| slice: 0:2
																						}}
																					</div>
																				</span>
																				<div class="cell-line-height">
																					<p
																						class="font-medium-1 fw-500 line-height-1 mb-0"
																						[ngbTooltip]="item?.name"
																					>
																						{{ item?.name | slice: 0:28 }}
																						{{
																							item?.name.length > 28
																								? ".."
																								: ""
																						}}
																					</p>
																				</div>
																			</span>
																		</ng-template>
																		<ng-template
																			ng-option-tmp
																			let-item="item"
																			let-index="index"
																			let-search="searchTerm"
																		>
																			<span
																				class="d-flex"
																				(click)="
																					modalOpenGroup(modalGroup);
																					selfAssignSel = false
																				"
																				*ngIf="item.team_id != 'self_assign'"
																			>
																				<div
																					class="avatar mr-50 ml-0"
																					[ngClass]="bg - light - primary"
																					style="
																						align-content: center;
																						width: 32px;
																						height: 32px;
																					"
																				>
																					<div
																						class="avatar-content"
																						[ngStyle]="{
																							'background-color':
																								stringToColor(item.name)
																						}"
																					>
																						{{
																							item.name
																								| initials
																								| slice: 0:2
																						}}
																					</div>
																				</div>
																				<div class="cell-line-height">
																					<div
																						class="font-medium-1 fw-500 line-height-1 mb-0"
																					>
																						{{ item?.name | slice: 0:25 }}
																						{{
																							item?.name?.length > 25
																								? ".."
																								: ""
																						}}
																					</div>
																					<span class="text-muted sm-font">
																						{{ item.group_type }}</span
																					>
																				</div>
																			</span>
																			<div
																				class="cursor-pointer"
																				*ngIf="item.team_id == 'self_assign'"
																			>
																				<div
																					class="media"
																					(click)="assignToMyself(modalGroup)"
																				>
																					<div class="avatar mr-1">
																						<div
																							class="avatar-content"
																							[ngStyle]="{
																								'background-color':
																									stringToColor(
																										currentUser.first_name +
																											'' +
																											currentUser.last_name
																									)
																							}"
																						>
																							{{
																								this.currentUser
																									.first_name +
																									"" +
																									this.currentUser
																										.last_name
																									| initials
																									| slice: 0:2
																							}}
																						</div>
																					</div>
																					<div class="media-body">
																						<h5 class="mb-0">
																							{{
																								"UI.k_self_assign"
																									| translate
																							}}
																						</h5>
																						<small class="text-muted">{{
																							this.currentUser.email
																						}}</small>
																					</div>
																				</div>
																			</div>
																		</ng-template>
																	</ng-select>
																</div>
																<div
																	class="form-group"
																	*ngIf="addChange.support_group_tmp == 'self_assign'"
																>
																	<div
																		class="p-50 d-flex align-items-center justify-content-start bg-light-secondary rounded"
																	>
																		<span>
																			<i class="fa-light fa-user mr-25"></i>
																		</span>
																		<span class="text-dark">{{
																			"UI.k_user" | translate
																		}}</span>
																		<span class="text-dark">:</span>
																		<span
																			class="fw-500 text-dark"
																			name="txtClientId"
																			[(ngModel)]="addChange.support_group"
																			id="txtCurrentUser"
																			>{{ currentUser.full_name }}</span
																		>
																	</div>
																</div>
																<div
																	class="form-group input-tree-dropdown"
																	*appDnsLicenseHandler="{
																		module_key: 'SERVICEDESK',
																		keys: ['SERVICE_CATALOGUE']
																	}"
																>
																	<label class="">{{
																		"UI.k_impact_service" | translate
																	}}</label>
																	<ng-container *ngIf="service_config?.is_editable">
																		<app-single-tree-dropdown
																			class="w-100"
																			[items]="dropdown?.formatted_services"
																			[value]="addChange.impact_service"
																			[id]="'changeService'"
																			(itemChange)="serviceValueChange($event)"
																		>
																		</app-single-tree-dropdown>
																	</ng-container>
																	<div
																		class="ng-select ng-select-single ng-select-searchable ng-select-clearable ng-untouched ng-pristine ng-valid"
																		*ngIf="!service_config?.is_editable"
																		(click)="
																			onImpactServiceClick(
																				$event,
																				'impact_service'
																			)
																		"
																	>
																		<div class="ng-select-container">
																			<div class="ng-value-container">
																				<div class="truncated">
																					{{
																						addChange?.impact_service_tree_path ||
																							("UI.k_select_option"
																								| translate)
																					}}
																				</div>
																				<div role="combobox" class="ng-input">
																					<input type="text" />
																				</div>
																			</div>
																			<span class="ng-arrow-wrapper"
																				><span class="ng-arrow"></span
																			></span>
																			<span
																				*ngIf="service_config?.is_loading"
																				class="fa fa-spinner fa-spin fa-2x"
																			>
																			</span>
																		</div>
																	</div>
																</div>

																<div
																	class="form-group input-tree-dropdown"
																	*appDnsLicenseHandler="{
																		module_key: 'SERVICEDESK',
																		keys: ['SERVICE_CATALOGUE']
																	}"
																>
																	<label for="txtChangeServiceClass" class=""
																		>{{ "UI.k_service_classification" | translate }}
																	</label>
																	<!-- [treeInputConfig]="{'isParentAllowed': true}"   -->
																	<ng-container
																		*ngIf="classification_config?.is_editable"
																	>
																		<app-single-tree-dropdown
																			class="w-100"
																			[items]="
																				dropdown?.formatted_classification_data
																			"
																			[value]="addChange.service_classification"
																			[id]="'changeClassification'"
																			[treeInputConfig]="{
																				isParentAllowed: true
																			}"
																			(itemChange)="
																				classificationValueChange($event)
																			"
																		>
																		</app-single-tree-dropdown>
																	</ng-container>
																	<div
																		class="ng-select ng-select-single ng-select-searchable ng-select-clearable ng-untouched ng-pristine ng-valid"
																		*ngIf="!classification_config?.is_editable"
																		(click)="
																			onImpactServiceClick(
																				$event,
																				'service_classification'
																			)
																		"
																	>
																		<div class="ng-select-container">
																			<div class="ng-value-container">
																				<div class="">
																					{{
																						addChange?.service_classification_path ||
																							("UI.k_select_option"
																								| translate)
																					}}
																				</div>
																				<div role="combobox" class="ng-input">
																					<input type="text" />
																				</div>
																			</div>
																			<span class="ng-arrow-wrapper"
																				><span class="ng-arrow"></span
																			></span>
																			<span
																				*ngIf="
																					classification_config?.is_loading
																				"
																				class="fa fa-spinner fa-spin fa-2x"
																			>
																			</span>
																		</div>
																	</div>
																</div>
															</div>
															<div class="px-1">
																<!-- <div class="form-group">
																	<label for="selAlarmSeverity" class=""
																		>{{ "UI.k_priority" | translate }}*</label
																	>
																	<ng-select
																		[items]="severity"
																		bindLabel="name"
																		bindValue="name"
																		name="selSeverity"
																		id="selSeverity"
																		placeholder="{{ 'UI.k_choose_one' | translate }}"
																		[(ngModel)]="addChange.priority"
																		class=""
																	>
																		<ng-template ng-option-tmp ng-label-tmp let-item="item">
																			<div class="avatar bg-light-{{ item.color }} avatar-sm mr-50">
																				<div class="avatar-content">
																					<span
																						[data-feather]="item.icon"
																						[class]="'avatar-icon'"
																					></span>
																				</div>
																			</div>
																			{{ item.name }}
																		</ng-template>
																	</ng-select>
																</div> -->

																<div class="form-group">
																	<div
																		class="d-flex justify-content-between align-items-center"
																	>
																		<label class="" for="selApprovers">{{
																			"UI.k_followers" | translate
																		}}</label>
																		<small
																			id="followers"
																			name="followers"
																			class="text-primary cursor-pointer"
																			(click)="updateWatcher()"
																			>{{ "UI.k_follow" | translate }}</small
																		>
																	</div>
																	<ng-select
																		[items]="dropdown.users"
																		[multiple]="true"
																		[closeOnSelect]="true"
																		[searchable]="true"
																		[searchFn]="customSearchFn"
																		bindLabel="full_name"
																		bindValue=""
																		name="selectFollowers"
																		id="selectFollowers"
																		placeholder="{{ 'UI.k_select' | translate }} {{
																			'UI.k_followers' | translate
																		}}"
																		class=""
																		[(ngModel)]="addChange.current_watcher"
																		hideSelected="true"
																	>
																		<ng-template ng-label-tmp let-item="item">
																			<span
																				class="d-flex ng-value-label align-items-center"
																			>
																				<span
																					class="avatar mr-50 avatar-sm"
																					[ngClass]="bg - light - primary"
																				>
																					<div
																						class="avatar-content"
																						[ngStyle]="{
																							'background-color':
																								stringToColor(
																									item.full_name
																								)
																						}"
																					>
																						{{
																							item.full_name
																								| initials
																								| slice: 0:2
																						}}
																					</div>
																				</span>
																				<div class="cell-line-height">
																				<span class="font-small-1">
																					{{ item?.full_name | slice: 0:28 }}
																					{{
																						item?.full_name?.length > 28
																							? ".."
																							: ""
																					}}
																				</span>
																				</div>
																			</span>
																		</ng-template>
																		<ng-template
																			ng-option-tmp
																			let-item="item"
																			let-index="index"
																			let-search="searchTerm"
																		>
																			<span class="d-flex">
																				<div *ngIf="item?.profile_image">
																					<div class="avatar mr-50 ml-25">
																						<span class="avatar-content">
																							<img
																								class="rounded-circle"
																								src="{{
																									item?.profile_image
																								}}"
																								alt="User"
																								width="32"
																								height="32"
																							/>
																						</span>
																					</div>
																				</div>
																				<div *ngIf="!item?.profile_image">
																					<div
																						class="avatar mr-50 ml-25"
																						[ngStyle]="{
																							'background-color':
																								item.avatar_color
																									? item.avatar_color
																									: '#f59518'
																						}"
																						style="
																							align-content: center;
																							width: 32px;
																							height: 32px;
																						"
																					>
																						<div class="avatar-content">
																							{{
																								item.full_name
																									| initials
																									| slice: 0:2
																							}}
																						</div>
																					</div>
																				</div>

																				<div class="cell-line-height">
																					<div
																						class="font-medium-1 font-weight-bold line-height-1 mb-0"
																					>
																						{{
																							item?.full_name
																								| slice: 0:28
																						}}
																						{{
																							item?.full_name?.length > 28
																								? ".."
																								: ""
																						}}
																					</div>
																					<span
																						class="text-muted font-small-2"
																					>
																						{{ item?.email | slice: 0:28 }}
																						{{
																							item?.email?.length > 28
																								? ".."
																								: ""
																						}}</span
																					>
																				</div>
																			</span>
																		</ng-template>
																	</ng-select>
																</div>
																<div class="form-group">
																	<div
																		class="d-flex justify-content-between align-items-center"
																	>
																		<label for="txtChangeTags">{{
																			"UI.k_tags" | translate
																		}}</label>
																	</div>
																	<ng-select
																		[(ngModel)]="addChange.tag"
																		#TDTagsRef="ngModel"
																		name="txtChangeTags"
																		id="txtChangeTags"
																		[items]="dropdown.tagList"
																		bindLabel="name"
																		bindValue="tag_id"
																		[closeOnSelect]="true"
																		[addTag]="false"
																		[multiple]="true"
																		[hideSelected]="true"
																		[addTag]="selectAddTagMethod"
																		addTagText="{{ 'UI.k_add_tag' | translate }}"
																		[ngModelOptions]="{ standalone: true }"
																		placeholder="{{ 'UI.k_select' | translate }} {{
																			'UI.k_tag' | translate
																		}}"
																	></ng-select>
																</div>

																<ng-template #tagComp let-modal>
																	<div class="modal-header">
																		<h4 class="modal-title" id="myModalLabel1">
																			{{ "UI.k_add" | translate }}
																			{{ "UI.k_tags" | translate }}
																		</h4>
																		<button
																			type="button"
																			id="btnTagClose"
																			name="btnTagClose"
																			[attr.data-testid]="'btnTagClose'"
																			class="close"
																			(click)="modal.dismiss('Cross click')"
																			aria-label="Close"
																		>
																			<span aria-hidden="true">&times;</span>
																		</button>
																	</div>
																	<div class="modal-body" tabindex="0" ngbAutofocus>
																		<form #addTagForm="ngForm">
																			<div class="row mb-2">
																				<div
																					class="col-xs-12 col-md-6 col-sm-6 col-lg-6"
																				>
																					<div class="form-group">
																						<label for="txtTName"
																							>{{
																								"UI.k_tags" | translate
																							}}
																							{{ "UI.k_name" | translate
																							}}<span
																								class="required-field"
																								>&nbsp;*</span
																							></label
																						>
																						<input
																							#TDTagNameRef="ngModel"
																							required
																							type="text"
																							class="form-control form-control-sm"
																							placeholder="{{
																								'UI.k_tag' | translate
																							}} {{
																								'UI.k_name' | translate
																							}}"
																							name="usrTagName"
																							[appWhiteSpaceCheck]="
																								tag?.name
																							"
																							[(ngModel)]="tag.name"
																							id="txtTName"
																							minlength="3"
																							maxlength="40"
																							[class.error]="
																								!TDTagNameRef.valid &&
																								addTagForm.submitted
																							"
																						/>
																						<span
																							*ngIf="
																								addTagForm.submitted &&
																								TDTagNameRef.invalid
																							"
																							class="invalid-form"
																						>
																							<small
																								class="form-text text-danger xs-font"
																								*ngIf="
																									TDTagNameRef.errors
																										.required
																								"
																								>{{
																									"UI.k_field_required"
																										| translate
																								}}</small
																							>
																							<small
																								class="form-text text-danger xs-font"
																								*ngIf="
																									TDTagNameRef.errors
																										.minlength ||
																									TDTagNameRef.errors
																										.maxlength
																								"
																							>
																								{{
																									"UI.k_tag_validation"
																										| translate
																								}}</small
																							>
																							<small
																								class="form-text text-danger xs-font"
																								*ngIf="
																									TDTagNameRef.errors
																										?.whitespace
																								"
																								>{{
																									"UI.k_leading_trailing_space_validation"
																										| translate
																								}}</small
																							>
																						</span>
																					</div>
																				</div>
																				<div
																					class="col-xs-12 col-md-6 col-sm-6 col-lg-6"
																				>
																					<div class="form-group">
																						<label for="drpType">{{
																							"UI.k_type" | translate
																						}}</label>
																						<ng-select
																							[items]="
																								dropdown.tagTypeList
																							"
																							[multiple]="false"
																							[closeOnSelect]="true"
																							[searchable]="false"
																							bindLabel="name"
																							bindValue="id"
																							placeholder="{{
																								'UI.k_select'
																									| translate
																							}}"
																							name="type"
																							id="drpType"
																							class=""
																							[(ngModel)]="tag.type"
																							disabled
																						>
																						</ng-select>
																					</div>
																				</div>
																			</div>
																			<div class="d-flex pull-right">
																				<div class="mb-1">
																					<button
																						(click)="
																							onCreateTag(addTagForm)
																						"
																						[disabled]="
																							is_tag_save_disabled
																						"
																						type="submit"
																						class="btn btn-primary"
																						rippleEffect
																						id="btnTagSave"
																						name="tagSave"
																						[attr.data-testid]="'btnTagSave'"
																					>
																						<span
																							class="align-middle d-sm-inline-block d-none"
																							>{{
																								"UI.k_submit"
																									| translate
																							}}
																						</span>
																					</button>
																				</div>
																			</div>
																		</form>
																	</div>
																</ng-template>
															</div>
														</ng-container>
														<ng-container>
															<div class="px-1 py-2">
																<div
																	class="d-flex justify-content-center mt-1"
																	*ngIf="
																		this.filesList?.length == 0 || !this.filesList
																	"
																>
																	<img
																		class="rounded"
																		src="assets/images/incident/files_empty_state.svg"
																		alt="nodata-bg"
																		width="200"
																		height="106"
																	/>
																</div>
																<div
																	class="d-flex justify-content-center mt-1"
																	*ngIf="
																		this.filesList?.length == 0 || !this.filesList
																	"
																>
																	{{ "UI.k_no_attachment" | translate }}
																</div>

																<div
																	*ngFor="let file of filesList; let i = index"
																	class="row pl-half d-flex align-items-center"
																>
																	<div
																		id="attachment-{{ file.id }}"
																		class="col-10 pull-left pt-1 pr-2 media"
																	>
																		<div class="text-center">
																			<div
																				class="avatar"
																				[ngClass]="
																					file.file_name.split('.')[1] ==
																					'txt'
																						? 'bg-light-primary'
																						: file.file_name.split(
																								'.'
																						  )[1] == 'pdf'
																						? 'bg-light-danger'
																						: file.file_name.split(
																								'.'
																						  )[1] == 'xls' ||
																						  file.name == 'xlsx'
																						? 'bg-light-success'
																						: file.file_name.split(
																								'.'
																						  )[1] == 'doc' ||
																						  file.file_name.split(
																								'.'
																						  )[1] == 'docx'
																						? 'bg-light-info'
																						: 'bg-light-primary'
																				"
																			>
																				<div
																					class="avatar-content"
																					id="feather_size"
																				>
																					<i
																						[ngClass]="
																							file.file_name.split(
																								'.'
																							)[1] == 'txt'
																								? 'fa-file-lines'
																								: file.file_name.split(
																										'.'
																								  )[1] == 'pdf'
																								? 'fa-file-pdf'
																								: file.file_name.split(
																										'.'
																								  )[1] == 'xls' ||
																								  file.file_name.split(
																										'.'
																								  )[1] == 'xlsx'
																								? 'fa-file-excel'
																								: file.file_name.split(
																										'.'
																								  )[1] == 'doc' ||
																								  file.file_name.split(
																										'.'
																								  )[1] == 'docx'
																								? 'fa-file-word'
																								: 'fa-file'
																						"
																						class="fa-light lg-font"
																						aria-hidden="true"
																					></i>
																				</div>
																			</div>
																		</div>
																		<div class="media-body ml-50">
																			<small
																				class="fw-500 overflow-wrap file_position title-color xs-font mb-0 d-block ellipse"
																			>
																				<b>{{ file.file_name }}</b
																				>&nbsp;&nbsp;v{{ file.version }}
																			</small>
																			<span class="file_time xs-font fw-500"
																				>{{
																					file.file_size / 1024 / 1024
																						| number: ".2"
																				}}
																				MB
																				<b class="h5">.</b>
																				{{
																					file.file_date
																						| date
																							: "d MMM y" ||
																									file.file_date
																						| date: "d MMM y"
																				}}</span
																			>
																		</div>
																	</div>
																	<div
																		class="right-btn-sec d-flex justify-content-center align-items-center"
																	>
																		<span
																			*ngIf="file?.description"
																			class="pull-right"
																			tooltipClass="fadeInAnimation"
																			ngbTooltip="{{ file.description }}"
																			container="body"
																		>
																			<i
																				class="fa-light fa-info-circle mr-half avatar-icon font-small-3 text-primary"
																			></i>
																		</span>
																		<span
																			class="pull-right"
																			id="btnRemoveMail"
																			(click)="removeMainFile(i)"
																			tooltipClass="fadeInAnimation"
																			ngbTooltip="{{ 'UI.k_delete' | translate }}"
																			container="body"
																		>
																			<i
																				class="fa-light fa-trash-can mr-half cursor-pointer avatar-icon font-small-4 text-danger"
																			></i
																		></span>
																	</div>
																</div>
															</div>
														</ng-container>
													</div>
												</ng-scrollbar>
											</div>
											<div class="middle-sec">
												<div
													id="divChangeWidth"
													class="flex-auto-height"
													[ngClass]="showHide === true ? '' : ''"
												>
													<div class="border-bottom p-1 flex-initial">
														<div class="form-group">
															<div class="input-group">
																<div class="input-group-prepend">
																	<span
																		class="input-group-text bg-light-secondary"
																		id="basic-addon-search1"
																		>{{ "UI.k_subject" | translate }}&nbsp;*</span
																	>
																</div>
																<input
																	type="text"
																	class="form-control"
																	placeholder="												
																	{{ 'UI.k_title' | translate }}"
																	id="txtSubject"
																	name="txtSubject"
																	minlength="3"
																	maxlength="1000"
																	[(ngModel)]="addChange.summary"
																	#TDSubjectRef="ngModel"
																	[class.error]="
																		!TDSubjectRef.valid &&
																		addChangeMainForm.submitted
																	"
																	[appWhiteSpaceCheck]="addChange.summary"
																	required
																/>
															</div>
															<div
																*ngIf="
																	addChangeMainForm.submitted && TDSubjectRef.invalid
																"
																class="invalid-form"
															>
																<small
																	class="form-text text-danger xs-font"
																	*ngIf="TDSubjectRef.errors.required"
																	>{{ "UI.k_field_required" | translate }}
																</small>
																<small
																	class="form-text text-danger xs-font"
																	*ngIf="
																		TDSubjectRef.errors.minlength ||
																		TDSubjectRef.errors.maxlength
																	"
																	>{{ "UI.k_title_name_validation" | translate }}
																</small>
																<small
																	class="form-text text-danger xs-font"
																	*ngIf="TDSubjectRef.errors?.whitespace"
																	>{{
																		"UI.k_leading_trailing_space_validation"
																			| translate
																	}}</small
																>
															</div>
														</div>
														<div class="form-group">
															<label for="txtDescription" class="form-label">
																{{ "UI.k_description" | translate }}</label
															>
															<textarea
																name="txtDescription"
																id="txtDescription"
																rows="5"
																class="form-control"
																[(ngModel)]="addChange.description"
																placeholder="{{
																	'UI.k_let_us_know_what_you_need' | translate
																}}"
															>
															</textarea>
														</div>
													</div>
													<div class="mb-25 flex-1">
														<ng-scrollbar class="custom-scrollbar section-scrollbar">
															<div class="change-for mb-25 px-1">
																<div class="py-1">
																	<h5 class="mb-0">
																		{{ "UI.k_change" | translate }}
																		{{ "UI.k_details" | translate }}
																	</h5>
																</div>
																<form #changeDetailForm="ngForm">
																	<div class="row border-bottom">
																		<div class="col-sm-6">
																			<div class="form-group">
																				<label
																					for="dtDueDate"
																					class="form-label"
																				>
																					{{
																						"UI.k_due_date" | translate
																					}}</label
																				>
																				<ng2-flatpickr
																					#ReqDuedate
																					[config]="dateTimeOptions"
																					[setDate]="addChange?.due_date"
																					id="dueDate"
																					name="dueDate"
																					[(ngModel)]="addChange.due_date"
																					placeholder="{{
																						'UI.k_due_by' | translate
																					}}"
																					class="my-class"
																					#TDDueDateRef="ngModel"
																					required
																				>
																				</ng2-flatpickr>
																			</div>
																		</div>
																		<div class="col-sm-6">
																			<div class="form-group">
																				<label
																					for="selAlarmSeverity"
																					class=""
																					>{{
																						"UI.k_priority" | translate
																					}}</label
																				>
																				<div class="form-group">
																					<app-select-badge
																						[inputConfig]="{
																							hideLabel: true,
																							inputOptions:
																								this.dropdown[
																									'priority'
																								],
																							id: 'priority_id',
																							model: addChange.priority_id
																						}"
																						(onValueChange)="
																							statusBadgeChange($event)
																						"
																					></app-select-badge>
																				</div>
																			</div>
																		</div>
																		<div class="col-sm-6">
																			<div class="form-group">
																				<label
																					for="selAlarmSeverity"
																					class=""
																					>{{
																						"UI.k_risk" | translate
																					}}</label
																				>
																				<div class="form-group">
																					<app-select-badge
																						[inputConfig]="{
																							hideLabel: true,
																							inputOptions:
																								this.dropdown['risk'],
																							id: 'risk_id',
																							model: addChange.risk_id
																						}"
																						(onValueChange)="
																							statusBadgeChange($event)
																						"
																					></app-select-badge>
																				</div>
																			</div>
																		</div>
																		<div class="col-sm-6">
																			<div class="form-group">
																				<label for="selChangeType"
																					>{{
																						"UI.k_change_type" | translate
																					}}
																				</label>
																				<ng-select
																					[items]="dropdown?.change_type"
																					[hideSelected]="true"
																					[multiple]="false"
																					[closeOnSelect]="true"
																					[searchable]="true"
																					bindLabel="name"
																					bindValue="id"
																					#TDChangeType="ngModel"
																					id="selChangeType"
																					name="selChangeType"
																					placeholder="{{
																						'UI.k_select' | translate
																					}} {{
																						'UI.k_change_type' | translate
																					}}"
																					[(ngModel)]="
																						addChange.change_type_id
																					"
																					(change)="changeTypeChange()"
																					notFoundText="{{
																						'UI.k_no_items_found'
																							| translate
																					}}"
																					[clearable]="false"
																				>
																				</ng-select>
																			</div>
																		</div>
																	</div>
																</form>
																<div
																	class="py-1"
																	*ngIf="
																		custom_field_config?.config?.custom_field
																			?.length > 0
																	"
																>
																	<h5 class="mb-0">
																		{{ "UI.k_custom" | translate }}
																		{{ "UI.k_field" | translate }}s
																	</h5>
																</div>
																<div class="col-12 pl-0">
																	<app-dns-custom-field
																		placeholder="general"
																		[formRef]="addChangeMainForm"
																		[data]="custom_field_data"
																		[config]="custom_field_config"
																	></app-dns-custom-field>
																</div>
															</div>
															<div class="p-1">
																<div class="d-flex wrap">
																	<div class="p-0">
																		<h5>{{ "UI.k_planning" | translate }}</h5>
																	</div>
																</div>
																<div class="form-group">
																	<label
																		for="feedbackViewPermissionTo"
																		class="form-label"
																	>
																		{{
																			"UI.k_reason_for_change" | translate
																		}}
																		*</label
																	>
																	<div class="p-0 card border quill-card">
																		<quill-editor
																			[(ngModel)]="addChange.planning"
																			id="qlPlanning"
																			name="qlPlanning"
																			[minLength]="3"
																			[required]="true"
																			#qlPlanning
																			[trimOnValidation]="true"
																			[appQuillValidator]="qlPlanning"
																			[appQuillForm]="addChangeMainForm"
																			[class.error]="
																				qlPlanning?.errors &&
																				addChangeMainForm.submitted
																			"
																			placeholder="{{
																				'UI.k_add_content' | translate
																			}}"
																		>
																		</quill-editor>
																		<div class="pl-2 pr-2 row">
																			<span
																				*ngFor="
																					let file of filesList;
																					let i = index
																				"
																				class="text-primary border rounded m-half p-25"
																				><i class="fa-light fa-file-lines"></i
																				>&nbsp;
																				<span class="text-truncate">
																					<span
																						ngbTooltip="{{
																							file.file_name
																						}}"
																						>{{
																							file.file_name | slice: 0:35
																						}}
																						{{
																							file.file_name.length > 35
																								? ".."
																								: " "
																						}}
																					</span>
																				</span>
																				&nbsp;
																				<span
																					(click)="
																						removeAttachedFile(i, filesList)
																					"
																					id="btnFileAttachmentClose{{ i }}"
																				>
																					<i class="fa-light fa-xmark"></i>
																				</span>
																			</span>
																		</div>
																		<div
																			class="compose-footer-wrapper email-footer p-75 d-flex card-bg"
																		>
																			<div
																				class="btn-wrapper d-flex align-items-center"
																			>
																				<!-- add attachment -->
																				<div class="email-attachement">
																					<label
																						for="file-input"
																						class="add_attachment"
																					>
																						<img
																							src="assets/images/icons/icon_link.svg"
																							width="12"
																							height="12"
																						/>
																						<span
																							class="fw-500 text-primary sm-font ml-50 cursor-pointer"
																							>{{
																								"UI.k_add" | translate
																							}}
																							{{
																								"UI.k_attachment"
																									| translate
																							}}</span
																						>
																					</label>
																					<input
																						(change)="fileUpload($event)"
																						id="file-input"
																						type="file"
																						class="d-none"
																					/>
																				</div>
																			</div>
																			<br />
																		</div>
																		<div
																			class="error"
																			*ngIf="
																				addChangeMainForm.submitted &&
																				qlPlanning?.errors
																			"
																		>
																			<small
																				*ngIf="
																					qlPlanning?.errors?.requiredError
																						?.empty
																				"
																			>
																				{{ "UI.k_field_required" | translate }}
																			</small>
																			<small
																				*ngIf="
																					qlPlanning?.errors?.minLengthError
																				"
																			>
																				{{
																					"Error.err_min_3_character"
																						| translate
																				}}
																			</small>
																		</div>
																	</div>
																</div>
																<div class="form-group">
																	<label for="qlRollOut" class="form-label">
																		{{ "UI.k_rollout" | translate }}</label
																	>
																	<div class="p-0 card border quill-card">
																		<quill-editor
																			[(ngModel)]="addChange.rollout"
																			id="qlRollOut"
																			name="qlRollOut"
																			#qlRollOut
																			[trimOnValidation]="true"
																			[appQuillValidator]="qlRollOut"
																			[appQuillForm]="addChangeMainForm"
																			placeholder="{{
																				'UI.k_add_content' | translate
																			}}"
																		>
																		</quill-editor>
																	</div>
																</div>
																<div class="form-group">
																	<label for="qlRollOut" class="form-label">
																		{{ "UI.k_rollback" | translate }}</label
																	>
																	<div class="p-0 card border quill-card">
																		<quill-editor
																			[(ngModel)]="addChange.rollback"
																			id="qlRollOut"
																			name="qlRollBack"
																			#qlRollBack
																			[trimOnValidation]="true"
																			[appQuillValidator]="qlRollBack"
																			[appQuillForm]="addChangeMainForm"
																			placeholder="{{
																				'UI.k_add_content' | translate
																			}}"
																		>
																		</quill-editor>
																	</div>
																</div>
															</div>
														</ng-scrollbar>
													</div>
													<div class="border-top card-bg mb-25 flex-initial">
														<div class="p-1">
															<div class="d-flex justify-content-end">
																<button
																	id="btnResetAdd"
																	name="btnResetAdd"
																	[attr.data-testid]="'btnResetAdd'"
																	type="reset"
																	class="btn btn-outline-tertiary mr-1"
																	(click)="cancel()"
																	rippleEffect
																>
																	{{ "UI.k_cancel" | translate }}
																</button>
																<div ngbDropdown class="btn-group">
																	<button
																		id="btnSubmitNew"
																		name="btnSubmitNew"
																		[attr.data-testid]="'btnSubmitNew'"
																		name="submit"
																		(click)="onSubmitNew(addChangeMainForm)"
																		class="btn btn-primary"
																		rippleEffect
																		[disabled]="disableSaveBtn"
																	>
																		{{ "UI.k_submit_new" | translate }}
																	</button>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
						<!-- / Modal -->
						<ng-template #modalGroup let-modal>
							<div class="d-flex align-items-center justify-content-end mr-50">
								<button
									id="btnAssignModalClose"
									[attr.data-testid]="'btnAssignModalClose'"
									type="button"
									class="close"
									(click)="closeCurrAssignPopup()"
									aria-label="Close"
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div class="modal-body" tabindex="0" ngbAutofocus>
								<h5 class="modal-title mb-1" id="myModalLabel160">
									{{ "UI.k_assign_group" | translate }}
								</h5>
								<form #AssignForm="ngForm">
									<div class="row">
										<div
											*ngIf="!selfAssignSel"
											class="form-group col-xs-12 col-md-6 col-xl-6 col-lg-6"
										>
											<label for="txtReqAssignee"
												>{{ "UI.k_change_implementer" | translate }}
											</label>
											<ng-select
												[items]="dropdown.assignee"
												[(ngModel)]="assignment_data.assignee_profile"
												(change)="assigneeChange()"
												bindLabel="full_name"
												name="txtReqAssignee"
												id="txtReqAssignee"
												placeholder="{{ 'UI.k_select_assignee' | translate }}"
												[ngModelOptions]="{ standalone: true }"
												[multiple]="false"
												#TDAssignee="ngModel"
												[closeOnSelect]="true"
												notFoundText="{{ 'UI.k_no_items_found' | translate }}"
											>
												<ng-template
													ng-option-tmp
													let-item="item"
													let-index="index"
													let-search="searchTerm"
												>
													<span class="d-flex align-items-center">
														<div
															*ngIf="
																dropdown?.team_user_profile_map[item?.profile_id]
																	?.profile_image
															"
														>
															<div class="avatar mr-1">
																<span class="avatar-content">
																	<img
																		class="rounded-circle"
																		src="{{
																			dropdown?.team_user_profile_map[
																				item?.profile_id
																			].profile_image
																		}}"
																		alt="User"
																		width="32"
																		height="32"
																	/>
																</span>
															</div>
														</div>
														<div
															*ngIf="
																!dropdown?.team_user_profile_map[item?.profile_id]
																	?.profile_image
															"
														>
															<div
																class="avatar mr-50 ml-25"
																[ngStyle]="{
																	'background-color': dropdown?.team_user_profile_map[
																		item.profile_id
																	]?.avatar_color
																		? dropdown.team_user_profile_map[
																				item?.profile_id
																		  ]?.avatar_color
																		: '#f59518'
																}"
																style="align-content: center; width: 32px; height: 32px"
															>
																<div class="avatar-content">
																	{{ item.full_name | initials | slice: 0:2 }}
																</div>
															</div>
														</div>
														<div class="cell-line-height">
															<div class="font-medium-1 fw-500 line-height-1 mb-0">
																{{ item?.full_name | slice: 0:25 }}
																{{ item?.full_name?.length > 28 ? ".." : "" }}
															</div>
															<span class="text-muted sm-font">
																{{ item?.email | slice: 0:28 }}
																{{ item?.email.length > 28 ? ".." : "" }}</span
															>
														</div>
													</span>
												</ng-template>
											</ng-select>
										</div>
									</div>
									<div>
										<app-dns-custom-field
											[formRef]="AssignForm"
											placeholder="assignment"
											[data]="custom_field_data"
											[config]="custom_field_config"
										>
										</app-dns-custom-field>
									</div>
									<div class="d-flex align-items-center justify-content-end mb-2">
										<button
											id="btnAssigneeSbmt"
											name="btnAssigneeSbmt"
											[attr.data-testid]="'btnAssigneeSbmt'"
											type="submit"
											class="btn btn-primary"
											(click)="onAssigneeSubmit(AssignForm)"
											rippleEffect
										>
											<!-- <span type="button" class="btn btn-primary"  rippleEffect> -->
											<small>{{ "UI.k_assign_group" | translate }}</small>
										</button>
									</div>
								</form>
							</div>
						</ng-template>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
