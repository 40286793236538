import { ChangeDetectorRef, Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";

@Directive({
	selector: "[appCompare]",
	providers: [{ provide: NG_VALIDATORS, useExisting: CompareDirective, multi: true }],
})
export class CompareDirective implements Validator {
	@Input("currentValue") currentValue = 0;
	@Input("operator") operator = "==";

	constructor(private _cdRef: ChangeDetectorRef) {}

	validate(control: AbstractControl): ValidationErrors | null {
		let val = control.value;
		this._cdRef.detectChanges();
		// if (!val) {
		// 	val = this.currentValue;
		// }
		if (val && typeof val === "string") {
			try {
				val = parseInt(val);
			} catch (e) {
				return { dnsInvalidValue: true };
			}
		}
		switch (this.operator) {
			case "==": {
				if (val == this.currentValue) {
					return { dnsInvalidValue: true };
				}
				break;
			}
			case ">": {
				if (val > this.currentValue) {
					return { dnsInvalidValue: true };
				}
				break;
			}
			case "<": {
				if (val < this.currentValue) {
					return { dnsInvalidValue: true };
				}
				break;
			}
			case ">=": {
				if (val >= this.currentValue) {
					return { dnsInvalidValue: true };
				}
				break;
			}
			case "<=": {
				if (val <= this.currentValue) {
					return { dnsInvalidValue: true };
				}
				break;
			}
			case "!=": {
				if (val != this.currentValue) {
					return { dnsInvalidValue: true };
				}
				break;
			}
			default: {
				return null;
			}
		}
	}
}
