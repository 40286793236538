import { Component, EventEmitter, Input, OnInit, Output, Inject, HostListener } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { AdminService } from "app/admin/service";
import { GlobalConstants } from "app/app.globalConstants";
import { DnsGridTagComponent } from "app/common/dns/components/dns-grid-tag/dns-grid-tag.component";
import { InfinityModules } from "app/common/dns/types/modules";
import { Invoice } from "../../models";
import { DnsGridPipeComponent } from "app/common/dns/components/dns-grid-pipe/dns-grid-pipe.component";
import { DOCUMENT } from "@angular/common";
import { CoreConfigService } from "@core/services/config.service";

@Component({
	selector: "app-invoices",
	templateUrl: "./invoices.component.html",
	styleUrls: ["./invoices.component.scss"],
})
export class InvoicesComponent implements OnInit {
	public ColumnMode = ColumnMode;
	@Input() trigger: any = "";
	@Input() module: any = InfinityModules.SUBSCRIPTION;
	invoices = [];
	columns: Array<any>;
	totalCount: any;
	totalInvoices: any;
	pageNumber: any = 1;
	sortKey: any = "display_id";
	reverse: any = "";
	limit: any = 20;
	calendarCustomWidth = "width:224px";
	SelectionType = SelectionType;
	permissions: any;
	offset: [];
	public showData = false;
	public filterData = false;
	public empty_data;
	public options = {};
	public invoiceLoaded: boolean = false;
	public showLoadMore: boolean = false;
	public showSearch: boolean = false
	constructor(
		private _adminService: AdminService,
		private _coreConfigService: CoreConfigService,
		private _translateService: TranslateService,
		@Inject(DOCUMENT) private document: Document
	) {}

	ngOnInit(): void {
		// window.addEventListener('scroll', this.scrollEvent, true);
		// this.getTotalInvoicesCount();
		// this.getDropdownData();
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.permissions = user_permissions[this.module];
		this.setNavbarConfig();
		this.empty_data = {
			icon: "fa-file-invoice",
			msg: this._translateService.instant("Message.msg_no_invoices"),
			description: this._translateService.instant("Message.msg_invoices_not_gen"),
			import_csv: false,
		};
		this.columns = [
			{
				label: this._translateService.instant("UI.k_payment_status"),
				key: "status",
				component: DnsGridTagComponent,
				subkey: "payment-badge",
				width: 220,
				sortable: false,
			},
			{
				label: this._translateService.instant("UI.k_invoice_amount"),
				key: "amount_paid",
				component: DnsGridPipeComponent,
				subkey: "currency",
				width: 220,
				sortable: false,
			},
			{
				label: this._translateService.instant("UI.k_generated_date"),
				key: "generated_at",
				component: DnsGridPipeComponent,
				subkey: "date",
				width: 220,
				sortable: false,
			},
			{
				label: this._translateService.instant("UI.k_paid_on"),
				key: "paid_at",
				component: DnsGridPipeComponent,
				subkey: "date",
				width: 220,
				sortable: false,
			},
			{
				label: this._translateService.instant("UI.k_due_date"),
				key: "due_date",
				component: DnsGridPipeComponent,
				subkey: "date",
				width: 220,
				sortable: false,
			},
			{
				label: this._translateService.instant("UI.k_action"),
				key: "action",
				actions: [
					{
						label: this._translateService.instant("UI.k_download"),
						icon: "fa-download",
						key: "edit",
						class: "text-primary btn btn-icon btn-outline-secondary",
						listener: (raw: any) => {
							// //console.log("......actions.raw", raw);
							this.downloadInvoice(raw.id);
						},
					},
				],
			},
		];
		if (this.trigger === "support") {
			this.columns.splice(0, 0, {
				label: this._translateService.instant("UI.k_organization"),
				key: "company",
				subkey: "billing_address",
				width: 220,
				sortable: false,
			});
		}
		this.getInvoices();
	}

	setPage(pageInfo) {
		this.pageNumber = pageInfo.offset;
	}

	setLimitGrid(args): void {
		this.pageNumber = 1;
		// if (args === "all") {
		// 	this.limit = this.invoices.data;
		// } else {
		// 	this.limit = parseInt(args);
		// }
	}
	onSort(args): void {
		if (args.sorts) {
			this.pageNumber = 0;
			let sortKey = args.sorts[0].prop;
			this.sortKey = sortKey;
			if (args.sorts[0].dir == "desc") {
				this.reverse = "-";
			} else {
				this.reverse = "";
			}
		}
	}

	setLimit(args): void {
		this.pageNumber = 0;
		this.limit = args.target.value;
	}

	getInvoices = () => {
		let payload = {
			limit: this.limit,
			offset: this.offset,
			trigger: this.trigger,
		};
		let allInvoices = [];
		this._adminService.getInvoices(payload).subscribe({
			next: (response: { result: Invoice[]; offset: any; count: any }) => {
				// //console.log("....invoices", response);
				if (response.result) {
					allInvoices = [...this.invoices, ...response.result];
					this.invoices = allInvoices;
					// this.invoices = response?.result;
					// //console.log("invoices", this.invoices, this.invoices.length);
					this.totalCount = this.invoices.length;
					// this.limit = this.totalCount;
					if (response?.offset) {
						this.showLoadMore = true;
					} else {
						this.showLoadMore = false;
					}
					this.offset = response?.offset;
					this.showData = true;
					this.invoiceLoaded = true;
				}
			},
			error: (e: any) => {
				console.error(e);
			},
		});
	};

	downloadInvoice(invoice_id: any) {
		// //console.log("......download", invoice_id);
		this._adminService.downloadInvoice({ invoiceId: invoice_id }).subscribe({
			next: (response: any) => {
				// //console.log(response);
				const link = this.document.createElement("a");
				link.setAttribute("target", "");
				link.setAttribute("rel", "noopener");
				link.setAttribute("href", response.download_url);
				link.setAttribute("download", `invoice${invoice_id}.pdf`);
				this.document.body.appendChild(link);
				link.click();
				link.remove();
			},
			error: (e: any) => {
				console.error(e);
			},
		});
	}

	setNavbarConfig(): void {
		this._coreConfigService.config = {
			layout: {
				navbar: {
					hidden: false,
					pageTitle: this._translateService.instant("UI.k_invoices"),
					moduleName: this.module,
					bookmark: false,
					showBreadcrumb: false,
					enableLocalStorage: false,
					pageIcon: "",
					search: [],
					breadcrumb: {},
				},
			},
		};
	}

	getTotalInvoicesCount = () => {
		let payload = {
			limit: this.limit,
			offset: this.offset,
			trigger: this.trigger,
		};
		this._adminService.getInvoicesCount(payload).subscribe({
			next: (response: { count: 0 }) => {
				// //console.log("....response", response);
				if (response) {
					this.totalInvoices = response?.count;
				}
			},
			error: (e: any) => {
				console.error(e);
			},
		});
	};

	loadNextData = () => {
		this.showData = false;
		this.invoiceLoaded = false;
		this.getInvoices();
	};
	// scrollEvent = (event: any): void => {
	// 	const n = event.target.scrollHeight;		
	// 	if(((n - event.srcElement.scrollTop) - event.target.clientHeight) < 10){
	// 		this.showLoadMore = true
	// 	}else{
	// 		this.showLoadMore = false
	// 	}
	// }
}
