<!--Preview modal template starts-->
<div class="modal-header" *ngIf="!loader">
	<h5 class="modal-title fw-600" id="ModalLabelAsset">{{ "UI.k_choose_a_plan" | translate }}</h5>
	<div class="pricing-modal-header-info d-flex align-items-center">
		<!-- <ng-container *ngFor="let inp of unitData; let i = index">
			<div class="d-flex align-items-center mr-1">
				<label class="d-flex align-items-center mr-50 mb-0 fw-500" for="inp.name">
					{{ inp.name }}
				</label>
				<ng-container *ngIf="inp.type == 'input'">
					<input
						type="number"
						id="{{ inp.name }}"
						min="{{ inp.min }}"
						name="{{ inp.name }}"
						value="{{ inp.value }}"
						class="form-control form-control-sm"
						style="width: 60px"
						(change)="onChangeUnits(inp.id, $event.target.value)"
					/>
				</ng-container>
				<ng-container *ngIf="inp.type == 'select'">
					<select
						id="inp.name"
						name="inp.name"
						class="form-control form-control-sm"
						(change)="onChangeUnits(inp.id, $event.target.value)"
					>
						<ng-container *ngFor="let op of inp.options">
							<option id="opt{{ op }}" name="opt{{ op }}" value="{{ op }}" [selected]="op == inp.value">
								{{ op }}
							</option>
						</ng-container>
					</select>
				</ng-container>
			</div>
		</ng-container> -->
		<!-- <div class="d-flex align-items-center">
			<div
				class="px-50 cursor-pointer border-right fw-500"
				[ngClass]="this.pricingType === 'month' ? 'text-primary' : 'title-color'"
				(click)="togglePricingType('month')"
			>
				{{ "UI.k_monthly" | translate }}
			</div>
			<div
				class="px-50 cursor-pointer fw-500"
				[ngClass]="this.pricingType === 'year' ? 'text-primary' : 'title-color'"
				(click)="togglePricingType('year')"
			>
				{{ "UI.k_yearly" | translate }}
			</div>
			<div class="badge badge-primary">{{ "UI.k_save" | translate }} 20%</div>
		</div> -->

		<div class="d-flex align-items-center mr-1">
			<div
				class="px-50 cursor-pointer fw-500"
				[ngClass]="this.pricingType === 'month' ? 'badge badge-primary' : 'title-color'"
				(click)="togglePricingType('month')"
				[ngStyle]="{ width: '70px' }"
			>
				{{ "UI.k_monthly" | translate }}
			</div>
			<div class="pl-50 pr-50 body-color font-small-5">|</div>
			<div
				class="px-50 cursor-pointer fw-500"
				[ngClass]="this.pricingType === 'year' ? 'badge badge-primary' : 'title-color'"
				(click)="togglePricingType('year')"
				[ngStyle]="{ width: '70px' }"
			>
				{{ "UI.k_yearly" | translate }}
			</div>
			<div class="text-primary ml-50 font-small-2 fw-700">
				{{ "Message.msg_save_2_months" | translate }}
			</div>
		</div>

		<span id="btnClose" name="btnClose" data-dismiss="modal" aria-label="Close" (click)="closePopup()">
			<i class="fa-light fa-xmark lg-font title-color"></i>
		</span>
	</div>
</div>
<div class="modal-body" tabindex="0" ngbAutofocus>
	<!-- <div class="pricing-cards-container" *ngIf="loader">
		<h6 class="pricing-card-plan-name">loading...</h6>
	</div> -->
	<app-features-skeleton *ngIf="loader"></app-features-skeleton>
	<div class="pricing-cards-container" *ngIf="pricingTypeData && pricingTypeData.length !== 0 && !loader">
		<ng-container *ngFor="let plan of pricingTypeData; let i = index">
			<div class="pricing-card card border card-bg" (click)="setActivePlan(plan.item.id)">
				<ng-container *ngIf="activePlan === plan.item.id">
					<div class="badge badge-warning pricing-card-active-badge px-1">
						{{ "UI.k_active" | translate }}
					</div>
				</ng-container>
				<div class="p-1">
					<h5 class="pricing-card-plan-name">
						{{ plan.item.name }}
					</h5>
					<!-- <div class="sm-font mb-2 fw-500">
						{{ plan.item.description }}
					</div> -->
					<h4>
						{{ planPriceMap[plan.item_price?.item_id] | currency: plan.item_price.currency_code }}
					</h4>
					<div class="sm-font">
						{{ "UI.k_per" | translate }} {{ perUnitMap[plan.item_price?.item_id] }},
						{{ "UI.k_billed" | translate | lowercase }}
					</div>
				</div>
				<div class="bg-body p-1">
					<h3>
						<span class="font-weight-normal mr-50">{{ "UI.k_total" | translate }}</span
						><span class="fw-500 mr-50">
							<ng-container *ngIf="product?.id !== 'Infraon-Helpdesk'">
								{{ totalPriceMap[plan.item_price?.item_id] | currency: plan.item_price.currency_code }}
							</ng-container>

							<ng-container *ngIf="product?.id === 'Infraon-Helpdesk'">
								{{ planPriceMap[plan.item_price?.item_id] | currency: plan.item_price.currency_code }}
							</ng-container>
							<!-- <app-animated-counter
                                [end]="
                                    getTotalPrice(1, plan.item_price) / 1000
                                        | currency: plan.item_price.currency_code
                                "
                                [countInterval]="200"
                            ></app-animated-counter> -->
						</span>
						<!--						<span class="text-muted"-->
						<!--							><i class="fa-light fa-circle-info" [ngbTooltip]="plan.item_price.pricing_model"></i-->
						<!--						></span>-->
					</h3>
					<div class="sm-font">{{ "UI.k_for" | translate }} {{ perUnitMap[plan.item_price?.item_id] }}</div>
				</div>
				<div class="p-1 border-bottom d-flex align-items-center">
					<ng-container *ngIf="productPlanSeq > plan.item?.cf_infraon_seq">
						<button class="btn btn-outline-secondary btn-block" (click)="changePlan(plan)">
							{{ "UI.k_downgrade" | translate }}
						</button>
					</ng-container>
					<ng-container *ngIf="productPlanSeq === plan.item?.cf_infraon_seq">
						<button class="btn btn-primary btn-block" (click)="changePlan(plan)">
							{{ "UI.k_current" | translate }} {{ "UI.k_plan" | translate }}
						</button>
					</ng-container>
					<ng-container *ngIf="productPlanSeq < plan.item?.cf_infraon_seq">
						<button class="btn btn-outline-primary btn-block" (click)="changePlan(plan)">
							{{ "UI.k_upgrade" | translate }} {{ "UI.k_plan" | translate }}
						</button>
					</ng-container>
				</div>
				<div class="product-feature">
					<ng-scrollbar class="custom-scrollbar section-scrollbar">
						<div class="p-1">
							<ng-container *ngIf="pricingTypeData[i - 1]">
								<h6 class="fw-600">
									{{ "UI.k_everything_in" | translate }} {{ pricingTypeData[i - 1].item.name }}
									{{ "UI.k_and" | translate | lowercase }}...
								</h6>
							</ng-container>
							<ng-container *ngFor="let feature of plan.item?.metadata?.features">
								<div class="single-feature">
									<span class="badge badge-round badge-light-success mr-50">
										<i class="fa-light fa-check"></i>
									</span>
									<span>
										{{ feature }}
									</span>
								</div>
							</ng-container>
						</div>
					</ng-scrollbar>
				</div>
			</div>
		</ng-container>
	</div>
</div>
<!--Preview modal template ends-->
