import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CoreSidebarService} from "@core/components/core-sidebar/core-sidebar.service";
import {GlobalConfig, ToastrService} from "ngx-toastr";
import {TagService} from 'app/base/tags/services/tag.service';
import {catchError, map} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {GlobalConstants} from "app/app.globalConstants";
import {InfinityModules} from "app/common/dns/types/modules";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {throwError} from "rxjs";

@Component({
	selector: 'app-bulk-tag',
	templateUrl: './bulk-tag.component.html',
	styleUrls: ['./bulk-tag.component.scss'],
})
export class BulkTagComponent implements OnInit {

	@Input() tag_type_list: any = [];
	@Input() tags: any = [];
	@Input() selectedTags: any = [];
	@Output('callback') callback = new EventEmitter<any>()
	@Output("onHideSidebar") onHideSidebar = new EventEmitter<any>();
	@Output("getTagOptions") getTagOptions = new EventEmitter<any>();
	@ViewChild("modAddTagPopup") modAddTagPopup: ElementRef;
    public tagname;
    public type;
	public tag;
	label: any = this._translateService.instant('UI.k_submit');
	event: any = 'Select';
	UIEvent: any = this._translateService.instant('UI.k_select_tag');
	is_tag_save_disabled = false;
	permissions: any = {};
  	constructor(
		private _coreSidebarService: CoreSidebarService,
		private _toastr: ToastrService,
		private _service: TagService,
		private _translateService: TranslateService,
		private _modalService: NgbModal,
		//private _tagService: TagService,
	){ 
	}

	ngOnInit(): void {
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
		  user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.permissions = user_permissions[InfinityModules.TAG];
		this.tag = {}
	}
	ngOnChanges() {
	}
	onSubmit = (data) => {
		if (data.form.valid === true) {
			if (this.event == 'Select'){
				this.callback.emit(this.selectedTags);
				// data.resetForm()
				// this.onHideSidebar.emit("hide");
			}
			else{
				this.saveTag(this.tag, data);
			}
		}
	};
	onCreateTag(data){
		// this.event = 'Add'
		// this.UIEvent = this._translateService.instant('UI.k_add_tag');
		this.label = this._translateService.instant('UI.k_submit');
		this.event = 'Select'
		this.label = this._translateService.instant('UI.k_submit');
		this.UIEvent = this._translateService.instant('UI.k_select_tag');

		this.is_tag_save_disabled = true;
		if (data.form.valid === true) {
			this._service
				.saveTag(this.tag)
				.pipe(
					map((response: any) => {
						if (response) {
							if (response["status"] == "success") {
								// console.log("tttteeee", response)
								// if (this.tag == undefined) {
								// 	this.tag = [];
								// }
								
								this._toastr.success(
									this._translateService.instant(
										response["message"]
									),
									this._translateService.instant(
										"Message.msg_save_success"
									),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
								this.getTagOptions.emit()
								// this.getTag(this.tag['type'])
								this._modalService.dismissAll();
								this.selectedTags.push(response?.id)
								this.selectedTags = [
									...this.selectedTags,
								];

							} else {
								this._toastr.error(
									this._translateService.instant(
										response["message"]
									),
									this._translateService.instant(
										"Error.err_save_failed"
									),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							}
							this.is_tag_save_disabled = false;
						}
						// Success
					}),
					catchError((error) => {
						this.is_tag_save_disabled = false;
						this._toastr.error(
							this._translateService.instant(
								"Error.err_tag_save_failed"
							),
							this._translateService.instant(
								"Error.err_save_failed"
							),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						//console.log("error", error)
						return throwError(error.statusText);
							
					})
				)
				.subscribe();
		} else {
			this.is_tag_save_disabled = false;
		}
	} 

	openModalAD(TagComp, name) {
		this.tag = {};
		this.tag["name"] = name;
		if (this.tag['type'] == undefined) {
			this.tag["type"] = this.tag_type_list[0].id
		}
		this._modalService.open(TagComp, {
			centered: true,
			animation: false,
			size: "md",
		});
	}
	
    toggleSidebar = (form): void => {
		this.tag = {};
		form.resetForm()
		this.onHideSidebar.emit("hide");
    };

	saveTag = (tagObj: {}, form): void => {
		this._service
			.saveTag(tagObj)
			.pipe(
				map((response: any) => {
					if (response) {
						if (response["status"] == "success") {
							// console.log("tttteeee", response)
							this.getTagOptions.emit()
							this.tag = {}
							this.event = 'Select'
							this.label = this._translateService.instant('UI.k_submit');
							this.UIEvent = this._translateService.instant('UI.k_select_tag');
							// form.resetForm()
							this._toastr.success(this._translateService.instant(response["message"]), this._translateService.instant('Message.msg_save_success'), {
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							});
						} else {
							this._toastr.error(this._translateService.instant(response["message"]), this._translateService.instant('Error.err_save_failed'), {
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							});
						}
					}
					// Success
				}),
				catchError((error) => {
					this._toastr.error(this._translateService.instant('Error.err_tag_save_failed'), this._translateService.instant('Error.err_save_failed'), {
						toastClass: "toast ngx-toastr",
						closeButton: true,
						positionClass: "toast-top-right",
					});
					return throwError(error.statusText);
				})
			)
			.subscribe();

		
	};
	// getTag(tagid: Number): void {
	// 	this._service
	// 		.getTag(tagid)
	// 		.pipe(
	// 			map((response: any) => {
	// 				if (response) {
	// 					if (response["id"] !== null || response["id"] !== undefined) {
	// 						this.tag = response;
	// 					}
	// 				}
	// 				// Success
	// 			}),
	// 			catchError((error) => {
	// 				return throwError(error.statusText);
	// 			})
	// 		)
	// 		.subscribe();
	// }
	selectAddTagMethod = (name) => {
		if (this.permissions.add) {
			this.openModalAD(this.modAddTagPopup, name);
		}
	};
	
	ngOnDestroy() {
		this._modalService.dismissAll();
	}
}
