<div class="card list-wrapper full-height">
	<div class="sekeleton-card item-wrap p-0 flex-auto-height">
		<div class="flex-1 overflow-hidden">
			<div class="d-flex border-bottom mb-1 p-25 grid-header">
				<div *ngFor="let col of columns; let i = index" class="col-md-2">
					<span *ngIf="i<6" class="font-weight-bolder grid-head-txt text-color">{{ col.label | uppercase }}</span>
				</div>
			</div>
			<div
				*ngFor="let generateFakef of generateFakes"
				class="col-md-12 pl-0 pr-0 py-50"
			>
				<div class="d-flex">
					<div
						*ngFor="let item of generateFake(columns?.length); let j = index"
						class="col-md-2"
					>
						<span *ngIf="j < 12"
							><ngx-skeleton-loader animation="pulse"></ngx-skeleton-loader
						></span>
					</div>
				</div>
			</div>
		</div>
		<div class="flex-initial">
			<div class="d-flex justify-content-between border-top">
				<div class="pl-1 pt-1">
					<ngx-skeleton-loader
						animation="pulse"
						[theme]="{ height: '20px', width: '120px', 'margin-right': '1rem' }"
					></ngx-skeleton-loader>
				</div>
				<div class="pl-1 pt-1">
					<ngx-skeleton-loader
						animation="pulse"
						[theme]="{
							height: '35px',
							width: '220px',
							'margin-right': '1rem',
							'border-radius': '0.8rem'
						}"
					></ngx-skeleton-loader>
				</div>
				<div class="pl-1 pt-1">
					<ngx-skeleton-loader
						animation="pulse"
						[theme]="{ height: '30px', width: '120px', 'margin-right': '1rem' }"
					></ngx-skeleton-loader>
				</div>
			</div>
		</div>
	</div>
</div>
