<div class="content-wrapper" appCheckRouteCondition>
    <div class="content-body">
      <div
        class="custom-feature incident-stage"
        [ngStyle]="{
          padding: incidentTab === true ? '0' : ''
        }"
      >
        <div class="custom-main">
          <div class="custom-row">
            <ng-container *ngFor="let item of stageArray">
              <div *ngIf="!item?.is_disabled " ngbDropdown class="dropdown">
                <hr
                  [ngClass]="{ 'hr-custom': item.active === true }"
                  [ngStyle]="{
                    'border-color': item.active === true ? item.borderColorCode : '',
                    opacity: item.id === 'closure' ? '0' : '1'
                  }"
                />
                <button
                  class="dot dropdown-toggle"
                  type="button"
                  id="{{ item.id }}"
                  data-toggle="dropdown"
                  ngbDropdownToggle
                  aria-haspopup="true"
                  aria-expanded="false"
                  [ngClass]="{ 'stage-active': item.active === true, 'stage-current' : item.currentStage === true}"
                  [ngStyle]="setBackground(item)"
                  [disabled]="disableConfig"
                  (click)="checkValidation(item)"
                  [ngClass]="{ 'stage-active': item.active === true, 'stage-current' : item.currentStage === true}"
                  [ngStyle]="setBackground(item)"
                >
                    <ng-container>
                        <i
                          class="fa fa-check"
                          aria-hidden="true"
                          *ngIf="item.active  && !item.currentStage"
                        ></i>
                        <i
                          class="fa fa-info"
                          aria-hidden="true"
                          *ngIf="!item.active && !item.currentStage"
                        ></i>
                        <i
                          class="fa fa-info"
                          aria-hidden="true"
                          *ngIf="item.currentStage"
                        ></i>
                      </ng-container>
                </button>
                <p class="lable">{{ item.label }}</p>
                <ul
                    class="dropdown-menu multi-level"
                    role="menu"
                    aria-labelledby="dropdownMenu"
                    style="margin-top: 20px"
                  >
                    <!-- <i class="arrow down custom-down"></i> -->
                    <ng-container *ngFor="let subItem of item.optionList">
                      <li class="dropdown-divider"></li>
                      <li
                        (click)="stageSelection(item, subitem, subItem.label)"
                        [ngClass]="
                          subItem?.subOptionList
                            ? 'dropdown-submenu'
                            : 'dropdown-item'
                        "
                      >
                        <a class="dropdown-item" tabindex="-1">
                            {{subItem.label | translate}}</a>
                        <ul class="dropdown-menu">
                          <ng-container *ngFor="let subOpt of subItem?.subOptionList">
                            <li class="dropdown-item" (click)="subStageSelection(item, subItem, subOpt)">
                              <a> {{ subOpt?.name | translate}}</a>
                            </li>
                          </ng-container>
                        </ul>
                        <!-- <ul class="dropdown-menu">
                          <li
                            class="dropdown-item"
                            (click)="stageSelection(item, subitem, 'in_progress')"
                          >
                            <a tabindex="-1">In Progress</a>
                          </li>
                          <li
                            class="dropdown-item"
                            (click)="stageSelection(item, subitem, 'analysis')"
                          >
                            <a>Analysis</a>
                          </li>
                        </ul> -->
                      </li>
                      <!-- <li class="dropdown-submenu">
                        <a class="dropdown-item" tabindex="-1">On Hold</a>
                        <ul class="dropdown-menu">
                          <li
                            class="dropdown-item"
                            (click)="stageSelection(item, 'on_hold_in_progress')"
                          >
                            <a tabindex="-1">In Progress</a>
                          </li>
                          <li
                            class="dropdown-item"
                            (click)="stageSelection(item, 'on_old_analysis')"
                          >
                            <a>Analysis</a>
                          </li>
                        </ul>
                      </li>
                      <li
                        class="dropdown-item"
                        (click)="stageSelection(item, 'resolution')"
                      >
                        <a>Resolution</a>
                      </li> -->
                    </ng-container>
                  </ul>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  