import { AdminCoreMenu } from "@core/types";

export const infraonAdminMenu: AdminCoreMenu[] = [
	{
		id: "instances",
		title: "UI.k_instances",
		type: "item",
		icon: "ticket",
		url: "infraon-admin/instances",
		permission: {
			moduleList: ["subscription"],
			keyList: ["view"],
		},
	},
	{
		id: "environments",
		title: "UI.k_environments",
		type: "item",
		icon: "ticket",
		url: "infraon-admin/environments",
		permission: {
			moduleList: ["subscription"],
			keyList: ["view"],
		},
	},
	{
		id: "organizations",
		title: "UI.k_organizations",
		type: "item",
		icon: "ticket",
		url: "infraon-admin/organizations",
		permission: {
			moduleList: ["subscription"],
			keyList: ["view"],
		},
	},
];
