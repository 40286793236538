import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { GlobalConstants } from "app/app.globalConstants";
import { InfinityModules } from 'app/common/dns/types/modules';
import { GridNameDesignComponent } from 'app/common/dns/components/grid-name-design/grid-name-design.component';
import Swal from 'sweetalert2';
import { MSPGridNameDesignComponent } from '../msp-grid-name-design/msp-grid-name-design.component';
@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})
export class OrganizationsComponent implements OnInit {

  public permissions: any;
  public module = InfinityModules.USER;

  public searchOptions: any = {};
  public conditionOptions = [];
  public savedData = [];
  public dropdownOptions: any = [];
  public orgData = [];
  public empty_data:any;
  public ciCount = 3;
  public pageNumberGrid = 0;
  public eventType:any = 'add';
  public isExpandSearchWidth: boolean;
  public showDataTable = false;
  public showRightsection: false;
  public showAddOrganization = false;
  public showEditOrganization = false;



  public columnData = [
	{
		label: "Organization Name",
		key: "organization_name",
		pre_selected: true,
		sortkey: "organization_name",
		width: 180,
		component: MSPGridNameDesignComponent,
		// callback: this.OnShowDetailSidebar,
	},
	{
		label: "org id",
		key: "org_id",
		pre_selected: true,
		sortkey: "org_id",
		width: 200,
		component: GridNameDesignComponent,
	},
	{
		label: "License",
		key: "license",
		pre_selected: true,
		sortkey: "license",
		width: 250,
		component: GridNameDesignComponent,
	},
	{
		label: "Plan",
		key: "plan",
		width: 250,
		pre_selected: true,
		sortkey: "plan",
		component: GridNameDesignComponent,
	},
	{
		label: "Assigned MSP User",
		key: "assigned_msp_user",
		width: 300,
		pre_selected: true,
		sortkey: "assigned_msp_user",
		component: GridNameDesignComponent,
	},
	{
		label: "user",
		key: "msp_user",
		pre_selected: true,
		sortkey: "msp_use",
		width: 250,
		component: GridNameDesignComponent,
	},
	{
		label: "It Asset",
		key: "msp_it_asset",
		pre_selected: true,
		sortkey: "msp_it_asset",
		width: 250,
		component: GridNameDesignComponent,
	},
	{
		label: "Non It Asset",
		key: "msp_non_it_asset",
		pre_selected: true,
		sortkey: "msp_non_it_asset",
		width: 250,
		component: GridNameDesignComponent,
	},
	{
		label: "Availability Monitoring",
		key: "msp_availability_monitoring",
		pre_selected: true,
		sortkey: "msp_availability_monitoring",
		width: 250,
		component: GridNameDesignComponent,
	},
	{
		label: "Network Monitoring",
		key: "msp_network_monitoring",
		pre_selected: true,
		sortkey: "msp_network_monitoring",
		width: 250,
		component: GridNameDesignComponent,
	},
	{
		label: this._translateService.instant("UI.k_action"),
		key: "action",
		width: 100,
		actions: [
			{
				label: this._translateService.instant("UI.k_edit"),
				icon: "fa-pen-to-square",
				key: "edit",
				class: "text-primary mr-50",
				listener: (raw: any) => {
					this.editOrg(raw);
				},

			},
			{
				label: this._translateService.instant("UI.k_delete"),
				icon: "fa-trash-can",
				key: "delete",
				class: "text-danger",
				listener: (raw: any) => {
					this.deleteOrg(raw);
				},
			},
		],
	},
];

  constructor(
    @Inject(DOCUMENT) private document,
		private _activatedRoute: ActivatedRoute,
		private _coreSidebarService: CoreSidebarService,
		private modalService: NgbModal,
		private _coreConfigService: CoreConfigService,
		private _translateService: TranslateService,
		private _router: Router,
		private _toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this. updateNavBar();

    let user_permissions: any = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		if (Object.keys(user_permissions).length != 0) {
			// this.licenseData = user_permissions?.usr_lic?.keys_dict?.MONITORING;
		}
		this.permissions = user_permissions[this.module];
		this.orgData = [
			{
				org_id: "#ORG3426612",
				organization_name: {
					name: "Faderal Bank",
					profile_image: "../../../../assets/images/msp/image 21.svg",
					avatar_color: "#f59518",
				},
				licence: {
					name: "Infraon Assets",
					key: "asset",
					units: ["user", "asset"],
					default_products: ["Infy_Ats_Pro_1"],
					icon: "assets/images/products/product-asset.svg",
				},
				plan: "Professional Plan",
				msp_user: {
					max_count: 500,
					count: 450,
					status:'danger'
				},
				msp_it_asset: {
					max_count: 500,
					count: 400,
					status:'warning'
				},
				msp_non_it_asset: {
					max_count: 500,
					count: 200,
					status:'success'
				},
				msp_availability_monitoring: {
					max_count: 500,
					count: 300,
					status:'success'
				},
				msp_network_monitoring: {
					max_count: 500,
					count: 200,
					status:'success'
				},
				assigned_msp_user:[
					{name:"Theresa Webb",email:'theresa@gmail.com',profile_image:'../../../../assets/images/avatars/1.png'},
					{name:"Wade Warren",email:'Wade@gmail.com',profile_image:'../../../../assets/images/avatars/2.png'},
					{name:"Alex Warren",email:'alex@gmail.com',profile_image:'../../../../assets/images/avatars/3.png'},
					{name:"Alex Warren",email:'alex@gmail.com',profile_image:''},
					{name:"Alex Warren",email:'alex@gmail.com',profile_image:'../../../../assets/images/avatars/4.png'},
					{name:"Alex Warren",email:'alex@gmail.com',profile_image:'../../../../assets/images/avatars/5.png'},
					{name:"Alex Warren",email:'alex@gmail.com',profile_image:'../../../../assets/images/avatars/6.png'},
					{name:"Alex Warren",email:'alex@gmail.com',profile_image:'../../../../assets/images/avatars/7.png'},
					{name:"Theresa Webb",email:'theresa@gmail.com',profile_image:'../../../../assets/images/avatars/1.png'},
				]
				
			},
		];
		this.empty_data = {
			icon: "fa-box-taped",
			msg: this._translateService.instant("Message.msg_organization_skeleton_heading"),
			description: this._translateService.instant("Message.msg_organization_skeleton_descr"),
			add_callback: this.toggleAddOrganization,
			sidebar_name: "add-organization-sidebar",
			button: this._translateService.instant("UI.k_add_organization"),
			import_csv: false,
			id: "add_organization"
		};
	this.constructObjectGrid(this.columnData)
  }


  updateNavBar() {
		// Configure the layout
		this._coreConfigService.config = {
			layout: {
				navbar: {
					hidden: false,
					pageTitle: this._translateService.instant("UI.k_organization"),
					moduleName: this.module,
					backgroundColor: "",
					pageIcon: "fa-box-taped",
					// search: [
					// 	{
					// 		popup: this.searchparams(),
					// 		callback: this.searchUser,
					// 	},
					// ],
					showBreadcrumb: false,
					breadcrumb: "",
				},
			},
		};
	}

  getFilterSearch = (e) => {

  }
  genericSearchFilter = (e) => {

  }
  getSearchWidth = (e) => {
		if (e) {
			this.isExpandSearchWidth = true;
		} else {
			this.isExpandSearchWidth = false;
		}
	};
  AddOrganization = () => {
    this.showAddOrganization = true;
    this._coreSidebarService.getSidebarRegistry("add-organization-sidebar").toggleOpen();
  }
  hideAddOrganizationSidebar = (e) => {
    this.showAddOrganization = false;
    this._coreSidebarService.getSidebarRegistry("add-organization-sidebar").close();
  }
  deleteOrg = (org) => {
	Swal.fire({
		title: this._translateService.instant("Message.msg_delete_title"),
		text: this._translateService.instant("Message.msg_delete_text"),
		icon: "warning",
		showCancelButton: true,
		confirmButtonText: this._translateService.instant("Message.msg_delete_confirm"),
		cancelButtonText: this._translateService.instant("Message.msg_delete_cancel"),
		customClass: {
			confirmButton: "btn btn-primary",
			cancelButton: "btn btn-danger ml-1",
		},
	}).then((result) => {
		if (result.value) {

		}
	});
  }
  editOrg = (org) => {
	this.eventType = 'edit'
	this.AddOrganization();
  }
  toggleAddOrganization = () => {
	this.AddOrganization();
  }

  constructObjectGrid(columns) {
	for (const gridcol of columns) {
		if (gridcol?.component == "MSPGridNameDesignComponent") {
			gridcol.component = MSPGridNameDesignComponent;
			gridcol.target = "_self"
		}
	}
  }

  OnShowDetailSidebar() {
  }

}
