import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "app/common/guards/auth.guards";
import { UsersComponent } from "./components/users-grid/users-grid.component";
import { UsersCardComponent } from './components/users-card/users-card.component';

const routes: Routes = [
	{
		path: "",
		component: UsersComponent,
		canActivate: [AuthGuard],
		data: {
			module: ['user'],
			keyList: ['add', 'edit', 'delete', 'view'],
			// redirectTo: 'signin'
		}
	},
	{
		path: "card",
		component: UsersCardComponent ,
		canActivate: [AuthGuard],
		data: {
				module: ['user'],
				keyList: ['add', 'edit', 'delete', 'view'],
				// redirectTo: 'signin'
		}
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class UsersRoutingModule {}
