import { Component, OnInit, Input } from '@angular/core';
import { NgEventBus } from "ng-event-bus";

@Component({
  selector: 'app-release-count',
  templateUrl: './release-count.component.html',
  styleUrls: ['./release-count.component.scss']
})
export class ReleaseCountComponent implements OnInit {
  @Input() widgetData: any;
	@Input() modifiedtime: any;
	config: any;

  constructor(
    private eventBus: NgEventBus
  ) { }

  ngOnInit(): void {
    this.config = this.widgetData?.data
  }
  showReleaseSidebar(dat){
		if (dat?.count > 0 && dat?.item_filter){
			let payload = {
				"title": "Release - " + dat.name,
				"app_type": "release-grid",
				"item_filter": dat.item_filter,
			}
			// console.log("in inci count widget -> ", payload)
			this.eventBus.cast('dashboard-view:sidebar', payload);
		}
	}

}
