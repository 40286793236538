import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from "@angular/core";
import {CoreConfigService} from "@core/services/config.service";
import {CommonService} from "app/services/common.service";
import {User} from "app/auth/models";
import {AuthService} from "app/base/auth/services/auth.service";
import {getFileSizeText, validateFile} from "../../../../common/utils/utils";
import {SelfServicePortalService} from "../../../../self-service-portal/services/self-service-portal.service";
import {catchError, map, reduce} from "rxjs/operators";
import {ToastrService} from "ngx-toastr";
import {TranslateService} from "@ngx-translate/core";
import {from, throwError} from "rxjs";
import {default as _map} from "lodash-es/map";
import cloneDeep from "lodash-es/cloneDeep";
import {TreeviewItem} from "ngx-treeview";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { RequestViewService } from "app/servicedesk/request/services/request-view.service";

@Component({
	selector: "app-close-ticket-sidebar",
	templateUrl: "./close-ticket-sidebar.component.html",
	styleUrls: ["./close-ticket-sidebar.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class CloseTicketSidebarComponent implements OnInit {
	@Output("onHideClose") onHideClose = new EventEmitter<any>();
	@Input() request_id = "";
	@Input() incident_id = "";
	@Input() options: any  = {};
	@Input() stateKeyIdMap = {};
	@Input() selected_status: any;
	@Input() action: any = "close";
	@Input() sidebarName: any = 'close-ticket';
	public request_data;
	public request_data_copy;
	currentUserProfileId: any;
	hideInlineEdit: boolean = false;
	req_extra_info: any = {};
	close_status = [];
	transition_status: any = [];
	title_text = ""
	is_submit_disabled = false
	is_loading = true

	public editorCreated(event): void {
		// Example on how to add new table to editor
		// this.addNewtable();
	}

	userTZ: string | undefined;

	constructor(
		private _requestViewService: RequestViewService,
		private _coreSidebar: CoreSidebarService,
		private _translateService: TranslateService,
		private _toastr: ToastrService,
		private _selfservicePortalService: SelfServicePortalService,
	) {}

	ngOnInit(): void {
		const userParams = JSON.parse(localStorage.getItem("userParams"));
		// this.userTZ = userParams?.user_tz;
		if (userParams && userParams["user_tz"]) {
			this.userTZ = userParams["user_tz"];
		}
		if (this.request_id != ""){
			if (this.action == "close"){
				this.title_text = this._translateService.instant("UI.k_close_a_request")
			}
			else{
				this.title_text = this._translateService.instant("UI.k_reopen_a_request")
			}
			this.onRequestClose(this.request_id);
		}
		else{
			if (this.action == "close"){
				this.title_text = this._translateService.instant("UI.k_close_a_ticket")
			}
			else{
				this.title_text = this._translateService.instant("UI.k_reopen_a_ticket")
			}
			this.onIncidentClose(this.incident_id);
		}
	}

	toggleSidebar = (key) => {
		this._coreSidebar.getSidebarRegistry(this.sidebarName).toggleOpen();
		this.onHideClose.emit();
	};

	onRequestClose(request_id) {
		this._selfservicePortalService
			.getRequest(request_id, {})
			.pipe(
				map(
					(response) => {
						this.request_data = response?.request_process;
						if (this.request_data?.close_info == null || this.request_data?.close_info == undefined){
							this.request_data["close_info"] = {
								"closed_type": {}
							}
						}
						if (this.options?.status == undefined){
							this.options["status"] = response?.status
							this.options["state_id_map"] = response?.options?.state_key_id_map
						}
						this.request_data_copy = cloneDeep(this.request_data);
						this.currentUserProfileId = response["current_user_id"];
						this.req_extra_info["custom_field_config"] = response["custom_field_config"];
						this.hideInlineEdit = true;
						if (this.request_data?.close_info == undefined) {
							this.request_data["close_info"] = {
								closed_type: {},
							};
						}
						this.transition_status = response?.transition_status
						this.is_loading = false
					},
					(error) => {
						//todo
					}
				)
			)
			.subscribe();
	}


	onIncidentClose(incident_id) {
		this._selfservicePortalService
			.getIncident(incident_id, {})
			.pipe(
				map(
					(response) => {
						this.request_data = response?.incident;
						if (this.request_data?.close_info == null || this.request_data?.close_info == undefined){
							this.request_data["close_info"] = {
								"closed_type": {}
							}
						}
						if (this.options?.status == undefined){
							this.options["status"] = response?.status
							this.options["state_id_map"] = response?.options?.state_key_id_map
						}
						this.request_data_copy = cloneDeep(this.request_data);
						this.currentUserProfileId = response["current_user_id"];
						this.req_extra_info["custom_field_config"] = response["custom_field_config"];
						this.hideInlineEdit = true;
						if (this.request_data?.close_info == undefined) {
							this.request_data["close_info"] = {
								closed_type: {},
							};
						}
						this.transition_status = response?.transition_status
						this.is_loading = false
					},
					(error) => {
						//todo
					}
				)
			)
			.subscribe();
	}

	onCloseSubmit(data, key) {
		console.log(this.options, "opt------------")
		if (data.form.status === "VALID") {
			this.is_submit_disabled = true
			this.request_data.basic_info["state"] =
				this.options?.state_id_map[this.request_data.basic_info?.status?.state_id];
			// let cf_data = this.req_extra_info?.custom_field_config?.custom_fields?.filter(
			// 	(d) => d?.section == "closure"
			// );
			// this.request_data.close_info["custom_field_data"] = this.get_custom_data(
			// 	cf_data,
			// 	this.request_data?.custom_field_data
			// );

			let payload = {}
			if (key == 'close') {
				this.request_data["is_reopened"] = false
				this.close_status = this.options?.status?.filter(
					(e) => e["state_id"] == this.options?.state_id_map["Close"]
				);
				let close_state = this.options?.state?.filter(
					(e) => e["id"] == this.options?.state_id_map["Close"]
				);
				if (close_state){
					this.request_data.basic_info["state"] = close_state[0];
				}
				this.request_data.basic_info["status"] = this.close_status[0];
				this.request_data.close_info.closure_note = this.request_data.notes
				try {
					const today = new Date(new Date().toLocaleString("en-US", { timeZone: this.userTZ }));
					this.request_data.actual_closure_date = today;
				} catch (e) {
					//console.log("Error in selecting current time based on timezone ", e);
				}
			} else {
				let progress_status = []
				this.request_data["is_reopened"] = true
				progress_status = this.options?.status?.filter(
					(e) => e["state_id"] == this.options?.state_id_map["In Progress"]
				);
				console.log (progress_status, this.options?.state_id_map)
				this.request_data.basic_info["status"] = progress_status[0];
				let progress_state = this.options?.state?.filter(
					(e) => e["id"] == this.options?.state_id_map["In Progress"]
				);
				if (progress_state){
					this.request_data.basic_info["state"] = progress_state[0];
				}
				
				payload = {
					display_id: this.request_data.display_id,
					rich_text_content: {
						description: this.request_data?.notes,
						type: "Reopen Note",
					},
					custom_fields: {},
					custom_field_data: {},
					is_ssp_login: true
				};
				this.request_data["close_info"] = {}
				this.request_data["is_ssp_login"] = true

			}
			if (this.request_id == ""){
				this.saveIncidentData(this.request_data, payload);
			}
			else{
				this.saveRequestData(this.request_data, payload);
			}
		}
	}

	saveRequestData(data, payload) {
		if (data.actual_start_time instanceof Array) {
			data.actual_start_time = data.actual_start_time[0];
		}
		if (data.agreed_closure_date instanceof Array) {
			data.agreed_closure_date = data.agreed_closure_date[0];
		}
		if (data.actual_closure_date instanceof Array) {
			data.actual_closure_date = data.actual_closure_date[0];
		}
		this._selfservicePortalService
			.editRequest(data.request_id, data)
			.pipe(
				map((response: any) => {
					if (response?.status == "success") {
						this._toastr.success(
							this._translateService.instant(response["message"]),
							this._translateService.instant("Message.msg_save_success"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						if (this.action != "close"){
							payload["ref_id"] = this.request_id
							this._selfservicePortalService.saveRequestRichTextData(payload).subscribe((response) => {
								// saved successfully
							});
						}
						this.toggleSidebar(this.sidebarName)
					} else {
						let error_msg = "Error.err_incident_save_failed"
						this.is_submit_disabled = false
						if (response?.message != undefined){
							error_msg = response?.message
						}
						this._toastr.error(
							this._translateService.instant(error_msg),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
					}
					// Success
				}),
				catchError((error) => {
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}

	saveIncidentData(data, payload) {
		if (data.actual_start_time instanceof Array) {
			data.actual_start_time = data.actual_start_time[0];
		}
		if (data.agreed_closure_date instanceof Array) {
			data.agreed_closure_date = data.agreed_closure_date[0];
		}
		if (data.actual_closure_date instanceof Array) {
			data.actual_closure_date = data.actual_closure_date[0];
		}
		this._selfservicePortalService
			.editIncident(data.incident_id, data)
			.pipe(
				map((response: any) => {
					if (response?.status == "success") {
						this._toastr.success(
							this._translateService.instant(response["message"]),
							this._translateService.instant("Message.msg_save_success"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
						if (this.action != "close"){
							payload["ref_id"] = this.incident_id
							this._selfservicePortalService.saveRichTextData(payload).subscribe((response) => {
								// saved successfully
							});
						}

						this.toggleSidebar(this.sidebarName)
					} else {
						this.is_submit_disabled = false
						this._toastr.error(
							this._translateService.instant("Error.err_incident_save_failed"),
							this._translateService.instant("Error.err_save_failed"),
							{
								toastClass: "toast ngx-toastr",
								closeButton: true,
								positionClass: "toast-top-right",
							}
						);
					}
					// Success
				}),
				catchError((error) => {
					return throwError(error.statusText);
				})
			)
			.subscribe();
	}

	onStatusChange = (data) => {
		this.request_data["status_id"] = data?.id;
		this.request_data.basic_info["status"] = data;
		this.request_data["state_id"] = data?.state_id;
		this.request_data.basic_info["state"] = this.options?.state_id_map[data?.state_id];
	};

	getClosureNote = (evt) => {
		this.request_data.notes = evt.html;
	};

	// updateFieldName(evt, id, dropdown_name, set_field, ref_field) {
	// 	let selected = this.options[dropdown_name].filter((e) => e[id] == evt);
	// 	set_field = selected[0][ref_field];
	// }

	// get_custom_data(config_data, custom_fields) {
	// 	let actual_conf_data = {};
	// 	if (config_data && custom_fields) {
	// 		config_data.forEach((conf) => {
	// 			let data_key = conf?.data_key;
	// 			let label = conf?.label;
	// 			let value = custom_fields[data_key];
	// 			if (conf?.type == "date" || conf?.type == "datetime-local") {
	// 				if (this.request_data.custom_field_data[data_key] instanceof Array) {
	// 					this.request_data.custom_field_data[data_key] =
	// 						this.request_data.custom_field_data[data_key][0];
	// 				}
	// 				if (value instanceof Array) {
	// 					value = value[0];
	// 				}
	// 			}
	// 			if (value) {
	// 				actual_conf_data[label] = value;
	// 			}
	// 		});
	// 	}
	// 	return actual_conf_data;
	// }
}
