<div class="slideout-content default-slideout">
	<div class="modal-dialog">
		<div class="modal-content add-new-user pt-0">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">
					{{  relationMapping?.[featureViewTaskData?.relate_type] | translate }}
				</h5>
				<div class="d-flex">
                    <button
                        class="btn btn-outline-tertiary mr-1"
                        rippleEffect
                        tooltipClass="fadeInAnimation"
                        id="btnCancel"
                        name="btnCancel"
                        (click)="toggleSidebar('relate-task')"
                        [attr.data-testid]="'btnCancel'"
                        >
                        {{ "UI.k_back" | translate }}
                    </button>					
					<button
						type="button"
						id="btnClose"
						name="btnClose"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						(click)="toggleSidebar('relate-task')"
                        [attr.data-testid]="'btnClose'"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
			</div>
			<div class="modal-body flex-auto-height">
                <div class="flex-auto-height">
                    <ng-scrollbar class="custom-scrollbar section-scrollbar">
                        <form class="panel-edit-form-sec-main-block">

                            <div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
                                <div class="row">
                                    <div class="col-xs-12 col-md-12 col-xl-12 col-lg-12">
                                        <div class="task-main-block">
                                            <div class="task-filter no-cursor" *ngIf="
                                            filterData?.length > 0 || taskFilterData
                                            ">
                                                <div  class="inner-section" style="padding: 10px 0px;">
                                                    <div  class="left-section" style="width: 100%;">
                                                        <app-search
                                                            [showDataTable]="showDataTable"
                                                            [columnOptions]="taskColumnOptions"
                                                            [conditionOptions]="taskConditionOptions"
                                                            [showRightsection]="showRightsection"
                                                            (getfilterData)="
                                                            getTaskFilterSearch($event)
                                                            "
                                                            [savedData]="savedTaskData"
                                                            [Options]="options"
                                                            [module]="'task'"
                                                            (genericFilter)="
                                                            genericTaskSearchFilter($event)
                                                            "
                                                        ></app-search>
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="dataLoaded">
                                                <ng-container *ngFor="let taskItem of filterData; let i = index">
                                                    <div class="task-list" (click)="onSelectTask(taskItem)">
                                                        <div class="task-left-side">
                                                            <div class="f-color">
                                                                {{taskItem.display_id}}
                                                            </div>
                                                            <div
                                                            >
                                                                {{
                                                                    taskItem?.name?.length > 100
                                                                        ? (taskItem?.name | slice: 0:100) +
                                                                        "..."
                                                                        : taskItem?.name
                                                                }}
                                                            </div>
                                                        </div>
                                                        <div class="task-right-side">
                                                            <div class="pbr" style="background-color: {{taskItem?.type?.color}}">
                                                                <i class="fa-light" class="{{taskItem.type?.class}}"></i>
                                                                {{taskItem.type?.name}}
                                                            </div>
                                                            <div
                                                                class="pbr"
                                                                class="pbr badge badge-light-{{
                                                                taskItem?.status?.state_color
                                                                    }}"
                                                            >
                                                                {{ taskItem?.status?.state | translate }}
                                                            </div>
                                                            <div class="pbr p-all-edit badge badge-light-{{taskItem.priority?.color}}" style="background-color: {{taskItem.priority?.background_color}}">
                                                                <i class="fa-light" class="{{taskItem.priority?.icon}}"></i>
                                                                {{taskItem.priority?.name}}
                                                            </div>
                                                            <div class="pbr">
                                                                {{taskItem?.due_date}}
                                                            </div>
                                                            <div class="avatar avatar-76-80 rounded-circle"
                                                                id="divAssigneeTask{{ i }}"
                                                                tooltipClass="fadeInAnimation"
                                                                ngbTooltip="{{ taskItem?.assignee_profile?.full_name }}"
                                                                placement="left"
                                                                *ngIf="options?.user_profile_map?.[taskItem?.assignee]?.profile_image">
                                                                <span class="avatar-content">
                                                                    <img [src]="options?.user_profile_map?.[taskItem?.assignee]?.profile_image"
                                                                        class="rounded-circle"
                                                                        alt="profile image"
                                                                        width="100%" />
                                                                </span>
                                                            </div>
                                                            <div *ngIf="!options?.user_profile_map?.[taskItem?.assignee]?.profile_image && taskItem?.assignee_profile?.full_name"
                                                                class="avatar avatar-76-80 rounded-circle"
                                                                [ngStyle]="{ 'background-color': options?.user_profile_map[taskItem?.assignee].avatar_color }">
                                                                <span
                                                                    class="avatar-content reg-font"
                                                                    [ngStyle]="{ 'background-color': options?.user_profile_map[taskItem?.assignee].avatar_color }">
                                                                    {{
                                                                        taskItem?.assignee_profile?.full_name
                                                                    | initials | slice: 0:2
                                                                    }}</span>
                                                            </div>
                                                            <div *ngIf="!options?.user_profile_map?.[taskItem?.assignee]?.profile_image && !taskItem?.assignee_profile?.full_name"
                                                                [ngStyle]="{ 'background-color': '' }"
                                                                class="avatar avatar-76-80 rounded-circle mr-50">
                                                                <span class="avatar-content">
                                                                    <i
                                                                        class="fa-light fa-user fa-1x user-icon-clr"></i>
                                                                </span>
                                                            </div>
                                                            <!-- <div class="task-assignee pbr" *ngIf="taskItem.assignee_profile?.full_name">
                                                                {{nameExtractorHandler(taskItem.assignee_profile?.full_name)}}
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="(filterData?.length == 0 || !filterData) && dataLoaded && !taskFilterData">
                                                    <div class="d-flex flex-column align-items-center"
                                                        style="padding: 10rem;">
                                                        <div class="avatar bg-secondary rounded-lg mb-25"
                                                            id="BtnincIcons">
                                                            <div class="avatar-content">
                                                                <span><i
                                                                        class="fa-light fa-book-open fa-lg"></i></span>
                                                            </div>
                                                        </div>
                                                        <span class="sm-font text-muted">{{
                                                            "UI.k_no_records_yet" | translate
                                                            }}</span>
                                                    </div>
                                                </ng-container>
                                                <div
                                                    *ngIf="
                                                        filterData?.length == 0 &&
                                                        showTaskData &&
                                                        taskFilterData &&
                                                        dataLoaded
                                                    "
                                                    style="padding: 10rem"
                                                >
                                                    <app-dns-empty-grid-data
                                                        [noFilterData]="true"
                                                    ></app-dns-empty-grid-data>
                                                </div>
                                            </ng-container>
                                            <div *ngIf="!dataLoaded" class="d-flex justify-content-center align-items-center height-400">
                                                <div class="spinner-border text-primary" aria-hidden="true"></div>
                                            </div>
                                                                                    
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </form>
                    </ng-scrollbar>
                </div>
			</div>
		</div>
	</div>
</div>