<core-sidebar
	name="{{ sidebarName }}"
	class="modal modal-slide-in sidebar-todo-modal fade {{ sidebarCustomClass }} {{ sidebarName }}"
	overlayClass="{{modalBackDropClass}}"
>
	<div
		class="slideout-content"
		[class.default-slideout]="!is_wizard_content"
		[class.default-stepping]="is_wizard_content"
	>
		<div class="modalsd modal-slide-in sdfade" id="modals-slide-in">
			<div
				class="modal-dialog"
				[ngClass]="{
					'sidebar-md': sidebarSize === 'medium',
					'sidebar-sm': sidebarSize === 'small',
					'full-width-sideout': sidebarSize === 'x-large'
				}"
			>
				<div class="modal-content pt-0">
					<div class="modal-header">
						<h5 class="modal-title" id="title_{{ sidebarName }}">{{ title | translate }}</h5>
						<button
							type="button"
							id="btnSidebarClose{{ sidebarName }}"
							name="btnSidebarClose"
							class="btn btn-flat-secondary btn-icon"
							data-dismiss="modal"
							aria-label="Close"
							(click)="toggleSidebar(sidebarName)"
						>
							<i class="fa-light fa-xmark"></i>
						</button>
					</div>
					<div class="modal-body">
						<ng-content></ng-content>
					</div>
				</div>
			</div>
		</div>
	</div>
</core-sidebar>
