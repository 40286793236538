import { NgModule } from "@angular/core";

import { FeatherIconDirective } from "@core/directives/core-feather-icons/core-feather-icons";
import { RippleEffectDirective } from "@core/directives/core-ripple-effect/core-ripple-effect.directive";
import { IsGrantedDirective } from "app/common/permission/directive";
import { DnsLicenseHandlerDirective } from "app/common/dns/directives/dns-license-handler.directive";
import { IsFeatureActiveDirective } from "../../app/common/permission/feature-flag-directive";

@NgModule({
	declarations: [
		RippleEffectDirective,
		FeatherIconDirective,
		IsGrantedDirective,
		DnsLicenseHandlerDirective,
		IsFeatureActiveDirective,
	],
	exports: [
		RippleEffectDirective,
		FeatherIconDirective,
		IsGrantedDirective,
		DnsLicenseHandlerDirective,
		IsFeatureActiveDirective,
	],
})
export class CoreDirectivesModule {}
