import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { NgEventBus } from "ng-event-bus";

@Component({
selector: 'app-panel-view-widget',
templateUrl: './panel-view-widget.component.html',
styleUrls: ['./panel-view-widget.component.scss']
})
export class PanelViewWidgetComponent implements OnInit {
	@Input() widgetData: any;
	@Input() modifiedtime: any;
	extracolumns: {}[];

	constructor(private eventBus: NgEventBus, private _translateService: TranslateService) {}

	ngOnInit(): void {
		// console.log("widgetData >> filters ", this.widgetData?.filters)
		this.extracolumns = [
			{
				label: this.widgetData?.stat_name,
				key: "val",
				sortable: false,
				index: 1,
			},
			{
				label: this._translateService.instant("UI.k_polling"),
				key: "active",
				subkey: "poll_configs",
				remove: true,
			},
			{
				label: this._translateService.instant("UI.k_operational_status"),
				key: "operational_status",
				remove: true,
			},
		];
	}

	showDataSidebar(dat) {
		let	payload = {
				title: "Resource List",
				app_type: "resource-grid",
				item_filter: dat?.filters,
				extra_columns: this.extracolumns,
				extra_payload: {
					"psql_ci_filter": 1,
					"stat_name": this.widgetData?.stat_name,
					"stat_unit": this.widgetData?.stat_unit,
					"page_count_override": this.widgetData?.breached
				},
				sortKey: "stat_value",
				reverse: "",
			};

		this.eventBus.cast("dashboard-view:sidebar", payload);
	}

}
