<div class="slideout-content default-slideout">
    <div class="modal-dialog">
        <div class="modal-content add-new-user pt-0">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    {{ process_data?.display_id}}-{{ "UI.k_relation" | translate }}
                </h5>
                <div class="d-flex">
                    <button
                        type="button"
                        id="btnClose"
                        name="btnClose"
                        class="btn btn-flat-secondary btn-icon"
                        data-dismiss="modal"
                        aria-label="Close"
                        (click)="toggleSidebar()"
                    >
                        <i class="fa-light fa-xmark"></i>
                    </button>
                </div>
            </div>
            <div class="modal-body flex-auto-height">
                <div class="flex-1">
                    <ng-scrollbar class="custom-scrollbar section-scrollbar">
                        <div class="relations">
                            <div class="px-1 pt-1 pb-2 d-flex">
                                <ng-container *ngIf="incident_permissions?.view">
                                    <div
                                        id="divGetIncident"
                                        name="divGetIncident"
                                        [attr.data-testid]="'divGetIncident'"
                                        [ngClass]="
                                            relationModuleIDSel == incident_module_id
                                                    ? 'badge badge-light-primary d-flex'
                                                    : 'badge badge-light-secondary d-flex'
                                            "
                                        >
                                            <span (click)="relationFilter(incident_module_id)"
                                                class="xs-font text-decoration-underline cursor-pointer">{{ "UI.k_incident" | translate }}
                                            </span>
                                            <ng-container *ngIf="permissions?.edit && !disable_config && !(
                                                process_data?.is_exit &&
                                                process_data?.state_id == 5
                                            )">
                                                <span class="xs-font mx-50">|</span>
                                                <span class="pt-5 cursor-pointer" tooltipClass="fadeInAnimation"
                                                ngbTooltip="{{
                                                    'UI.k_add_association_relation' | translate
                                                }}"
                                                placement="right"
                                                (click)="addRelations(infinity_modules?.INCIDENT)">
                                                <i class="fa-solid fa-circle-plus"></i></span>
                                            </ng-container>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="problem_permissions?.view">
                                    <div
                                        id="divGetProblem"
                                        name="divGetProblem"
                                        [attr.data-testid]="'divGetProblem'"
                                        [ngClass]="
                                        relationModuleIDSel == problem_module_id
                                                ? 'ml-1 badge badge-light-primary d-flex'
                                                : 'ml-1 badge badge-light-secondary d-flex'
                                        "
                                    >
                                        <span (click)="relationFilter(problem_module_id)" id="spProblem" name="spProblem" [attr.data-testid]="'spProblem'"
                                            class="xs-font text-decoration-underline cursor-pointer">{{ "UI.k_problem" | translate }}
                                        </span>
                                        <ng-container *ngIf="permissions?.edit && !disable_config && !(
                                            process_data?.is_exit &&
                                            process_data?.state_id == 5
                                        )">
                                            <span class="xs-font mx-50">|</span>
                                            <span class="pt-5 cursor-pointer" tooltipClass="fadeInAnimation"
                                            ngbTooltip="{{
                                                'UI.k_add_association_relation' | translate
                                            }}"
                                            placement="right"
                                            (click)="addRelations(infinity_modules?.PROBLEM)">
                                            <i class="fa-solid fa-circle-plus"></i></span>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="change_permissions?.view">
                                    <div
                                        id="divgetChange"
                                        name="divgetChange"
                                        [attr.data-testid]="'divgetChange'"
                                        [ngClass]="
                                        relationModuleIDSel == change_module_id
                                                ? 'ml-1 badge badge-light-primary d-flex'
                                                : 'ml-1 badge badge-light-secondary d-flex'
                                        "
                                    >
                                        <span (click)="relationFilter(change_module_id)" id="spChange" name="spChange" [attr.data-testid]="'spChange'"
                                            class="xs-font text-decoration-underline cursor-pointer">{{ "UI.k_change" | translate }}
                                        </span>
                                        <ng-container *ngIf="permissions?.edit && !disable_config && !(
                                            process_data?.is_exit &&
                                            process_data?.state_id == 5
                                        )">
                                            <span class="xs-font mx-50">|</span>
                                            <span class="pt-5 cursor-pointer" tooltipClass="fadeInAnimation"
                                            ngbTooltip="{{
                                                'UI.k_add_association_relation' | translate
                                            }}"
                                            placement="right"
                                            (click)="addRelations(infinity_modules?.CHANGE)">
                                            <i class="fa-solid fa-circle-plus"></i></span>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="request_permissions?.view">
                                    <div
                                        id="divgetRequest"
                                        name="divgetRequest"
                                        [attr.data-testid]="'divgetRequest'"
                                        [ngClass]="
                                        relationModuleIDSel == request_module_id
                                                ? 'ml-1 badge badge-light-primary d-flex'
                                                : 'ml-1 badge badge-light-secondary d-flex'
                                        "
                                    >
                                        <span (click)="relationFilter(request_module_id)" id="spRequest" name="spRequest" [attr.data-testid]="'spRequest'"
                                            class="xs-font text-decoration-underline cursor-pointer">{{ "UI.k_request" | translate }}
                                        </span>
                                        <ng-container *ngIf="permissions?.edit && !disable_config && !(
                                            process_data?.is_exit &&
                                            process_data?.state_id == 5
                                        )">
                                            <span class="xs-font mx-50">|</span>
                                            <span class="pt-5 cursor-pointer" tooltipClass="fadeInAnimation"
                                            ngbTooltip="{{
                                                'UI.k_add_association_relation' | translate
                                            }}"
                                            placement="right"
                                            (click)="addRelations(infinity_modules?.REQUEST_PROCESS)">
                                            <i class="fa-solid fa-circle-plus"></i></span>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="release_permissions?.view">
                                    <div
                                        id="divgetRelease"
                                        name="divgetRelease"
                                        [attr.data-testid]="'divgetRelease'"
                                        [ngClass]="
                                        relationModuleIDSel == release_module_id
                                                ? 'ml-1 badge badge-light-primary d-flex'
                                                : 'ml-1 badge badge-light-secondary d-flex'
                                        "
                                    >
                                        <span (click)="relationFilter(release_module_id)" id="spRelease" name="spRelease" [attr.data-testid]="'spRelease'"
                                            class="xs-font text-decoration-underline cursor-pointer">{{ "UI.k_release" | translate }}
                                        </span>
                                        <ng-container *ngIf="permissions?.edit && !disable_config && !(
                                            process_data?.is_exit &&
                                            process_data?.state_id == 5
                                        )">
                                            <span class="xs-font mx-50">|</span>
                                            <span class="pt-5 cursor-pointer" tooltipClass="fadeInAnimation"
                                            ngbTooltip="{{
                                                'UI.k_add_association_relation' | translate
                                            }}"
                                            placement="right"
                                            (click)="addRelations(infinity_modules?.RELEASE)">
                                            <i class="fa-solid fa-circle-plus"></i></span>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                
                            </div>

                            <div class="row ml-1 mt-2" *ngIf="relations?.length > 0">
                                <!-- parent child relation and incident association -->
                                <ng-container *ngFor="let rel of relations">
                                    <div
                                        class="summary_card_info ml-2 p-1 pt-50 mb-1"
                                    >
                                        <ng-container *ngIf="
                                        (rel?.parent_module_id === module_id && rel?.child_module_id === module_id); else interProcessRelations
                                        ">
                                            <a
                                                target="_blank"
                                                [routerLink]="moveToEdit(rel)"
                                            >
                                                <span class="pull-left sm-font text-primary"
                                                    ><b></b
                                                    >{{
                                                        mapping[
                                                            rel?.parent_ref_id == process_id
                                                                ? rel?.child_ref_id
                                                                : rel?.parent_ref_id
                                                        ]?.display_id
                                                    }}</span
                                                >
                                        </a>
                                        </ng-container>
                                        
                                        <ng-template #interProcessRelations>
                                            <a *ngIf="checkInterModuleAssociation(rel, incident_module_id)"	routerLink="/incident_view/incident-edit/{{ mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.incident_id  }}" target="_blank">
                                                <span class="pull-left sm-font text-primary"
                                                ><b></b>{{  mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.display_id }}</span
                                                >
                                            </a>
                                            <a *ngIf="checkInterModuleAssociation(rel, request_module_id)"	routerLink="/request_list/request-edit/{{ mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.request_id  }}" target="_blank">
                                                <span class="pull-left sm-font text-primary"
                                                ><b></b>{{  mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.display_id }}</span
                                                >
                                            </a>
                                            <a *ngIf="checkInterModuleAssociation(rel, problem_module_id)"	routerLink="/problem_management/problem-edit/{{ mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.problem_id  }}" target="_blank">
                                                <span class="pull-left sm-font text-primary"
                                                ><b></b>{{  mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.display_id }}</span
                                                >
                                            </a>
                                            <a *ngIf="checkInterModuleAssociation(rel, change_module_id)"	routerLink="/change/edit/{{ mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.change_id  }}" target="_blank">
                                                <span class="pull-left sm-font text-primary"
                                                ><b></b>{{  mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.display_id }}</span
                                                >
                                            </a>
                                            <a *ngIf="checkInterModuleAssociation(rel, release_module_id)"	routerLink="/rel/edit/{{ mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.release_id  }}" target="_blank">
                                                <span class="pull-left sm-font text-primary"
                                                ><b></b>{{  mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.display_id }}</span
                                                >
                                            </a>
                                        </ng-template>
                                        

                                        <div class="pull-left">&nbsp;&nbsp;</div>
                                        <ng-container *ngIf="checkSourceName(mapping, rel, process_id, module_id)">
                                            <div
                                                class="pl-1"
                                            >
                                                <div class="avatar bg-light-primary pull-left rounded">
                                                    <div
                                                        class="avatar-content"
                                                        id="quick_icons"
                                                        style="width: 20px; height: 20px"
                                                    >
                                                    <i *ngIf="
                                                        rel?.parent_module_id == module_id &&
                                                        rel?.child_module_id == module_id; else interProcessSource
                                                        "
                                                        tooltipClass="fadeInAnimation"
                                                            ngbTooltip="{{ mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.[source_name_mapping?.[module]] }}"
                                                            placement="left"
                                                        class="fa-light fa-{{
                                                            sourceIconMap[
                                                            mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.[source_id_mapping?.[module]]
                                                            ]
                                                        }} fa-xs"
                                                        
                                                    ></i>
                                                    <ng-template #interProcessSource>
                                                        <i *ngIf="checkInterModuleAssociation(rel, incident_module_id)"
                                                            tooltipClass="fadeInAnimation"
                                                                ngbTooltip="{{ mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.incident_source_name }}"
                                                                placement="left"
                                                            class="fa-light fa-{{
                                                                sourceIconMap[
                                                                mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.incident_source_id
                                                                ]
                                                            }} fa-xs"
                                                        ></i>
                                                        <i *ngIf="checkInterModuleAssociation(rel, request_module_id)"
                                                            tooltipClass="fadeInAnimation"
                                                                ngbTooltip="{{ mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.request_source_name }}"
                                                                placement="left"
                                                            class="fa-light fa-{{
                                                                sourceIconMap[
                                                                mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.request_source_id
                                                                ]
                                                            }} fa-xs"
                                                        ></i>
                                                        <i *ngIf="checkInterModuleAssociation(rel, problem_module_id)"
                                                            tooltipClass="fadeInAnimation"
                                                                ngbTooltip="{{ mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.problem_source_name }}"
                                                                placement="left"
                                                            class="fa-light fa-{{
                                                                sourceIconMap[
                                                                mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.problem_source_id
                                                                ]
                                                            }} fa-xs"
                                                        ></i>
                                                        <i *ngIf="checkInterModuleAssociation(rel, change_module_id)"
                                                            tooltipClass="fadeInAnimation"
                                                                ngbTooltip="{{ mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.change_source_name }}"
                                                                placement="left"
                                                            class="fa-light fa-{{
                                                                sourceIconMap[
                                                                mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.change_source_id
                                                                ]
                                                            }} fa-xs"
                                                        ></i>
                                                        <i *ngIf="checkInterModuleAssociation(rel, release_module_id)"
                                                            tooltipClass="fadeInAnimation"
                                                                ngbTooltip="{{ mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.release_source_name }}"
                                                                placement="left"
                                                            class="fa-light fa-{{
                                                                sourceIconMap[
                                                                mapping[rel?.parent_ref_id == process_id ? rel?.child_ref_id : rel?.parent_ref_id]?.release_source_id
                                                                ]
                                                            }} fa-xs"
                                                        ></i>
                                                    </ng-template>
                                                    
                                                    </div>
                                                </div>
                                                <div class="pull-left">&nbsp;</div>
                                            </div>
                                        </ng-container>
                                        

                                        <div class="pull-right status_tag">
                                            <span class="comment-data">
                                                {{ "UI.k_priority" | translate }}</span
                                            >
                                            <div
                                                class="fw-500 p-25 badge  badge-light-{{
                                                    mapping[
                                                        rel?.parent_ref_id == process_id
                                                            ? rel?.child_ref_id
                                                            : rel.parent_ref_id
                                                    ]?.priority_color
                                                }}"
                                            >
                                                <span class="comment-data">{{
                                                    mapping[
                                                        rel?.parent_ref_id == process_id
                                                            ? rel?.child_ref_id
                                                            : rel.parent_ref_id
                                                    ]?.priority_name | translate
                                                }}</span>
                                            </div>
                                        </div>
                                        <br />
                                        <div
                                            class="my-50 d-flex justify-content-between align-items-center"
                                        >
                                            <span
                                                *ngIf="
                                                    !mapping[
                                                        rel?.parent_ref_id == process_id
                                                            ? rel?.child_ref_id
                                                            : rel.parent_ref_id
                                                    ]?.asset_id
                                                "
                                                class="comment-data ellipse"
                                            >
                                                {{ "UI.k_service" | translate }}:
                                                <span
                                                    *ngIf="
                                                        mapping[
                                                            rel?.parent_ref_id == process_id
                                                                ? rel?.child_ref_id
                                                                : rel.parent_ref_id
                                                        ]?.impact_service_name
                                                    "
                                                >
                                                {{
                                                    mapping[
                                                        rel?.parent_ref_id == process_id
                                                            ? rel?.child_ref_id
                                                            : rel?.parent_ref_id
                                                    ]?.impact_service_name  | truncate
                                                }}
                                                </span>
                                            </span>
                                            <div
                                                *ngIf="
                                                    mapping[
                                                        rel?.parent_ref_id == process_id
                                                            ? rel?.child_ref_id
                                                            : rel?.parent_ref_id
                                                    ]?.asset_id &&
                                                    mapping[
                                                        rel?.parent_ref_id == process_id
                                                            ? rel?.child_ref_id
                                                            : rel?.parent_ref_id
                                                    ]?.cmdb_id
                                                "
                                            >
                                                <span class="comment-data"
                                                    >{{ "UI.k_asset_id" | translate }} :
                                                    <span
                                                        *ngIf="
                                                            asset_permissions?.view &&
                                                            !request_data?.is_asset_disabled
                                                        "
                                                    >
                                                        <a
                                                            href="/it-asset/asset/{{
                                                                mapping[
                                                                    rel?.parent_ref_id ==
                                                                    process_id
                                                                        ? rel?.child_ref_id
                                                                        : rel?.parent_ref_id
                                                                ]?.cmdb_id
                                                            }}"
                                                            target="_blank"
                                                            class="sm-font"
                                                            title="{{
                                                                mapping[
                                                                    rel?.parent_ref_id ==
                                                                    process_id
                                                                        ? rel?.child_ref_id
                                                                        : rel?.parent_ref_id
                                                                ]?.asset_id
                                                            }}"
                                                        >
                                                            {{
                                                                mapping[
                                                                    rel?.parent_ref_id ==
                                                                    process_id
                                                                        ? rel?.child_ref_id
                                                                        : rel?.parent_ref_id
                                                                ]?.asset_id | slice: 0:19
                                                            }}{{
                                                                mapping[
                                                                    rel?.parent_ref_id ==
                                                                    process_id
                                                                        ? rel.child_ref_id
                                                                        : rel.parent_ref_id
                                                                ]?.asset_id?.length > 19
                                                                    ? ".."
                                                                    : ""
                                                            }}
                                                        </a>
                                                    </span>
                                                    <span
                                                        *ngIf="
                                                            !asset_permissions?.view ||
                                                            request_data?.is_asset_disabled
                                                        "
                                                        class="sm-font"
                                                        title="{{
                                                            mapping[
                                                                rel?.parent_ref_id == process_id
                                                                    ? rel?.child_ref_id
                                                                    : rel?.parent_ref_id
                                                            ]?.asset_id
                                                        }}"
                                                    >
                                                        {{
                                                            mapping[
                                                                rel?.parent_ref_id == process_id
                                                                    ? rel?.child_ref_id
                                                                    : rel?.parent_ref_id
                                                            ]?.asset_id | slice: 0:19
                                                        }}{{
                                                            mapping[
                                                                rel?.parent_ref_id == process_id
                                                                    ? rel.child_ref_id
                                                                    : rel.parent_ref_id
                                                            ]?.asset_id?.length > 19
                                                                ? ".."
                                                                : ""
                                                        }}
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="">
                                                <div
                                                    *ngIf="
                                                        rel?.relation_type == 2 &&
                                                        rel?.parent_ref_id != process_id
                                                    "
                                                    class="fw-500 badge badge-light-success p-25"
                                                >
                                                    <span class="comment-data">{{
                                                        "UI.k_parent" | translate
                                                    }}</span>
                                                </div>
                                                <div
                                                    *ngIf="
                                                        rel?.relation_type == 2 &&
                                                        rel?.parent_ref_id == process_id
                                                    "
                                                    class="badge badge-light-danger fw-500 p-25"
                                                >
                                                    <span class="comment-data">{{
                                                        "UI.k_child" | translate
                                                    }}</span>
                                                </div>
                                                <div
                                                    class="fw-500 badge badge-light-success p-25"
                                                    *ngIf="rel?.relation_type != 2"
                                                >
                                                <ng-container *ngIf="!(rel?.parent_module_id == module_id && rel?.child_module_id == module_id)">
                                                    <ng-container **ngIf="checkInterModuleAssociation(rel, request_module_id)" id="ngcRequest" name="ngcRequest" [attr.data-testid]="'ngcRequest'">
                                                        <span 	 class="comment-data">{{
                                                            "UI.k_request" | translate
                                                        }}</span> &nbsp;
                                                    </ng-container>
                                                    <ng-container *ngIf="checkInterModuleAssociation(rel, incident_module_id)"  id="ngcIncident" name="ngcIncident" [attr.data-testid]="'ngcIncident'">
                                                        <span 	 class="comment-data">{{
                                                            "UI.k_incident" | translate
                                                        }}</span> &nbsp;
                                                    </ng-container>
                                                    <ng-container *ngIf="checkInterModuleAssociation(rel, problem_module_id)"  id="ngcProblem" name="ngcProblem" [attr.data-testid]="'ngcProblem'">
                                                        <span 	 class="comment-data">{{
                                                            "UI.k_problem" | translate
                                                        }}</span> &nbsp;
                                                    </ng-container>
                                                    <ng-container *ngIf="checkInterModuleAssociation(rel, change_module_id)"  id="ngcChange" name="ngcChange" [attr.data-testid]="'ngcChange'">
                                                        <span 	 class="comment-data">{{
                                                            "UI.k_change" | translate
                                                        }}</span> &nbsp;
                                                    </ng-container>
                                                    <ng-container *ngIf="checkInterModuleAssociation(rel, release_module_id)"  id="ngcRelease" name="ngcRelease" [attr.data-testid]="'ngcRelease'">
                                                        <span 	 class="comment-data">{{
                                                            "UI.k_release" | translate
                                                        }}</span> &nbsp;
                                                    </ng-container>
                                                </ng-container>	
                                                
                                                    <span class="comment-data">{{
                                                        "UI.k_associated" | translate
                                                    }}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            class="my-50 d-flex justify-content-start align-items-center"
                                        >
                                            <span class="comment-data pull-left ellipse">{{
                                                mapping[
                                                    rel?.parent_ref_id == process_id
                                                        ? rel?.child_ref_id
                                                        : rel?.parent_ref_id
                                                ]?.summary | truncate
                                            }}</span>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <ng-container
                                *ngIf="
                                    (relations?.length == 0 || relations == undefined)
                                "
                            >
                                <div
                                    class="d-flex flex-column align-items-center"
                                    style="padding: 10rem"
                                >
                                    <div
                                        class="avatar bg-secondary rounded-lg mb-25"
                                        id="BtnincIcons"
                                    >
                                        <div class="avatar-content">
                                            <span><i class="fa-light fa-book-open fa-lg"></i></span>
                                        </div>
                                    </div>
                                    <span class="sm-font text-muted">{{
                                        "Message.msg_no_relation" | translate
                                    }}</span>
                                </div>
                            </ng-container>
                        </div>
                    </ng-scrollbar>
                </div>
            </div>
        </div>
    </div>
</div>
<core-sidebar
	class="modal modal-slide-in sidebar-todo-modal fade"
	name="app-associate"
	overlayClass="modal-backdrop"
>
	<app-associate-relation
		*ngIf="showAddRelation"
		[parent_obj]="process_data"
		[options]="options"
		[permissions]="permissions"
		[relations]="allRelations"
		[mapping]="mapping"
		[relationModule]="relationModule"
		(onHideAssociateRelations)="onHideAssociateRelations($event)"
		[module_id]="module_id"
        [module]="module"
		(getRelations)="getRelations($event)"
	>
	</app-associate-relation>
</core-sidebar>