<!-- Footer -->
<ng-container *ngIf="!isSelfServicePortal">
	<p class="clearfix mb-0">
		<span class="float-md-left d-block d-md-inline-block mt-25">
			COPYRIGHT &copy; {{ year }}
			<a class="ml-25" href="https://infraon.io/" target="_blank">Infraon</a>
			<span class="d-none d-sm-inline-block">, All rights Reserved</span>
		</span>
		<!-- <span class="float-md-right d-none d-md-block">
		Hand-crafted & Made with <i class="fa-light fa-heart pink"></i>
	  </span> -->
	</p>
	<!--/ Footer -->

	<!-- Move to top Button-->
	<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

	<!-- Buynow Button-->
</ng-container>
<div [ngClass]="isSelfServicePortal ? 'container' : 'w-100'">
	<ng-container *ngIf="isSelfServicePortal">
		<div class="d-flex justify-content-between">
				<a
				class="sm-font title-color"
				href=""
				[routerLink]="coreConfig?.layout?.footer?.redirect_url"
				*ngIf="coreConfig?.layout?.footer?.redirect_url"
				><i class="fa-light fa-arrow-left mr-50"></i>{{ coreConfig?.layout?.footer?.redirect_page }}</a
			>
			<span class="sm-font title-color ml-auto" [innerHTML]="'UI.k_help_desk_infraon' | translate"> </span>
		</div>
	</ng-container>
</div>

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

<!-- Buynow Button-->
<!-- <div class="buy-now">
  <a href="https://infraon.io/" target="_blank" class="btn btn-danger" *ngIf="coreConfig.layout.buyNow"
    >Buy Now</a
  >
</div> -->
