<div class="slideout-content default-stepping">
	<div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
		<div class="modal-dialog">
			<div class="modal-content add-new-user pt-0">
				<div class="modal-header">
					<h5 *ngIf = "eventType === 'add'" class="modal-title" id="exampleModalLabel">
						{{ "UI.k_add_organization" | translate }}
					</h5>
                    <h5 *ngIf = "eventType === 'edit'" class="modal-title" id="exampleModalLabel">
						{{ "UI.k_edit_organization" | translate }}
					</h5>
					
					<button
						id="btnClose"
						name="close"
						type="button"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						[attr.data-testid]="'btnClose'"
						(click)="closeSidebar()"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body">
					<section class="stepping-form">
						<div id="addOrganizationStepper" class="bs-stepper horizontal-wizard-example">
							<div class="bs-stepper-header">
								<div class="step" data-target="#organizationDetails">
									<button class="step-trigger">
										<span class="bs-stepper-box">1</span>
										<span class="bs-stepper-label">
											<span class="bs-stepper-title">{{
												"UI.k_organization_details" | translate
											}}</span>
                                            <span class="bs-stepper-subtitle"
												>{{ "UI.k_select_organization_details" | translate }}
											</span>
										</span>
									</button>
								</div>
								<div class="line">
									<i data-feather="chevron-right" class="font-medium-2"></i>
								</div>
								<div class="step" data-target="#licenseDetails">
									<button class="step-trigger">
										<span class="bs-stepper-box">2</span>
										<span class="bs-stepper-label">
											<span class="bs-stepper-title">{{
												"UI.k_license_details" | translate
											}}</span>
                                            <span class="bs-stepper-subtitle"
												>{{ "UI.k_select_license_details" | translate }}
											</span>
										</span>
									</button>
								</div>
								<div class="line">
									<i data-feather="chevron-right" class="font-medium-2"></i>
								</div>
							</div>
							<div class="bs-stepper-content">
								<div class="bs-stepper-inner">
									<form>
										<div id="organizationDetails" class="content">
											<form #organizationDetailsForm="ngForm" class="stepper-inner">
												<div class="stepper-body">
													<ng-scrollbar class="custom-scrollbar section-scrollbar"   [visibility]="'always'">
														<div class="stepper-main">
															<div class="row">
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <div *ngIf="!showPreview">
                                                                            <app-img-upload-feature
																				(imgUploaderDetails)="
																					imageDetails($event)
																				"
																				(Removeimg)="removeImage($event)"
																				[showQueue]="showQueue"
																				[multipleFiles]="multipleFiles"
																				[imgDetailsObject]="imgDetailsObject"
																				[ALLOWED_TYPES]="ALLOWED_TYPES"
																				[MAX_IMAGE_SIZE]="MAX_IMAGE_SIZE"
																				[displayResoluion]="displayResoluion"
																				[isOnlyImage]="true"
																			>
																			</app-img-upload-feature>
                                                                        </div>
                                                                        <div class="asset-closer">
																			<img
																				*ngIf="showPreview"
																				[src]="itemObj?.image"
																				width="100%"
																			/>
																			<span
																				id="linkDelImg"
																				name="linkDelImg"
																				(click)="removeImage()"
																				class="d-flex justify-content-center align-items-center delete-btn"
																				[attr.data-testid]="'linkDelImg'"
																			>
																				<i
																					class="fa-light fa-trash-can text-white close-mark"
																				></i>
																			</span>
																		</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-8">
                                                                    <div class="row">
                                                                        <div class="col-md-6">
                                                                            <div class="form-group">
                                                                                <label for="txtCompanyName"
                                                                                    >{{ "UI.k_company_name" | translate
                                                                                    }}<span
                                                                                        class="required-field text-danger"
                                                                                        >&nbsp;*</span
                                                                                    ></label
                                                                                >
                                                                                <input
                                                                                    type="text"
                                                                                    id="txtCompanyName"
                                                                                    class="form-control"
                                                                                    placeholder="{{
                                                                                        'UI.k_enter_name' | translate
                                                                                    }}"
                                                                                    name="txtCompanyName"
                                                                                    [(ngModel)]="itemObj.company_name"
                                                                                    #TDCompanyNameRef="ngModel"
                                                                                    [class.error]="
                                                                                        !TDCompanyNameRef.valid &&
                                                                                        organizationDetailsForm.submitted
                                                                                    "
                                                                                    required
                                                                                    maxlength="40"
                                                                                    pattern="^[^\s]+(\s+[^\s]+)*$"
                                                                                    [attr.data-testid]="'txtCompanyName'"
                                                                                />
                                                                                <dns-error-validation
                                                                                    [uiRef]="TDCompanyNameRef"
                                                                                    [formRef]="organizationDetailsForm"
                                                                                ></dns-error-validation>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class="form-group">
                                                                                <label for="txtContactPersonName"
                                                                                    >{{ "UI.k_contact_person_name" | translate
                                                                                    }}<span
                                                                                        class="required-field text-danger"
                                                                                        >&nbsp;*</span
                                                                                    ></label
                                                                                >
                                                                                <input
                                                                                    type="text"
                                                                                    id="txtContactPersonName"
                                                                                    class="form-control"
                                                                                    placeholder="{{
                                                                                        'UI.k_enter_name' | translate
                                                                                    }}"
                                                                                    name="txtContactPersonName"
                                                                                    [(ngModel)]="itemObj.contactPerson_name"
                                                                                    #TDContactPersonNameRef="ngModel"
                                                                                    [class.error]="
                                                                                        !TDContactPersonNameRef.valid &&
                                                                                        organizationDetailsForm.submitted
                                                                                    "
                                                                                    required
                                                                                    (change) = "onNameChange($event)"
                                                                                    maxlength="40"
                                                                                    pattern="^[^\s]+(\s+[^\s]+)*$"
                                                                                    [attr.data-testid]="'txtContactPersonName'"
                                                                                />
                                                                                <dns-error-validation
                                                                                    [uiRef]="TDContactPersonNameRef"
                                                                                    [formRef]="organizationDetailsForm"
                                                                                ></dns-error-validation>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class="form-group">
                                                                                <label for="organizationEmail"
                                                                                    >{{ "UI.k_email_id" | translate
                                                                                    }}<span
                                                                                        class="required-field text-danger"
                                                                                        >&nbsp;*</span
                                                                                    ></label
                                                                                >
                                                                                <input
                                                                                    type="email"
                                                                                    id="organizationEmail"
                                                                                    class="form-control"
                                                                                    placeholder="{{ 'UI.k_email_placeholder' | translate }}"
                                                                                    name="organizationEmail"
                                                                                    [(ngModel)]="itemObj.organization_email"
                                                                                    #TDOrganizationEmailRef="ngModel"
                                                                                    [class.error]="
                                                                                        !TDOrganizationEmailRef.valid &&
                                                                                        organizationDetailsForm.submitted
                                                                                    "
                                                                                    required
                                                                                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$"
                                                                                    [attr.data-testid]="'organizationEmail'"
                                                                                />
                                                                                <dns-error-validation
                                                                                    [uiRef]="TDOrganizationEmailRef"
                                                                                    [formRef]="organizationDetailsForm"
                                                                                ></dns-error-validation>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class="form-group">
                                                                                <label for="txtPhone"
                                                                                    >{{
                                                                                        "UI.k_phone"
                                                                                            | translate
                                                                                    }}<span
                                                                                        class="required-field text-danger"
                                                                                        >&nbsp;*</span
                                                                                    ></label
                                                                                >
                                                                                <ngx-intl-tel-input
                                                                                    class="w-100"
                                                                                    [preferredCountries]="
                                                                                        preferredCountries
                                                                                    "
                                                                                    [enableAutoCountrySelect]="
                                                                                        true
                                                                                    "
                                                                                    [separateDialCode]="separateDialCode"
                                                                                    [enablePlaceholder]="true"
                                                                                    [searchCountryFlag]="true"
                                                                                    [searchCountryField]="[
                                                                                        SearchCountryField.Iso2,
                                                                                        SearchCountryField.Name
                                                                                    ]"
                                                                                    [selectFirstCountry]="false"
                                                                                    [selectedCountryISO]="
                                                                                        CountryISO.UnitedStates
                                                                                    "
                                                                                    [maxLength]="15"
                                                                                    [phoneValidation]="true"
                                                                                    
                                                                                    [numberFormat]="
                                                                                        PhoneNumberFormat.National
                                                                                    "
                                                                                    required
                                                                                    name="txtPhone"
                                                                                    id="txtPhone"
                                                                                    [(ngModel)]="
                                                                                    itemObj.phone
                                                                                    "
                                                                                    (change) = "onNumberChange($event)"
                                                                                    #TDPhoneRef="ngModel"
                                                                                >
                                                                                </ngx-intl-tel-input>
                                                                                <dns-error-validation
                                                                                    [uiRef]="TDPhoneRef"
                                                                                    [formRef]="organizationDetailsForm"
                                                                                ></dns-error-validation>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <div class="form-group">
                                                                                <label for="selCompanySize"
                                                                                    >{{
                                                                                        "UI.k_company_size"
                                                                                            | translate
                                                                                    }}
                                                                                    </label
                                                                                >
                                                                                <ng-select
                                                                                    [items]="Options"
                                                                                    class="form-control"
                                                                                    name="selCompanySize"
                                                                                    id="selCompanySize"
                                                                                    placeholder="{{ 'UI.k_select' | translate }}"
                                                                                    [(ngModel)]="itemObj.company_size"
                                                                                    #selCompanySizeRef="ngModel"
                                                                                    [class.error]="selCompanySizeRef.invalid && organizationDetailsForm.submitted"
                                                                                    
										                                            ></ng-select>
                                                                                <dns-error-validation
                                                                                    [uiRef]="selCompanySizeRef"
                                                                                    [formRef]="organizationDetailsForm"
                                                                                ></dns-error-validation> 
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
															</div>
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <span class="fw-500 ref-font title-color">{{'UI.k_billing_details' | translate}}</span>
                                                                </div>
                                                            </div>
                                                            <div class="row mt-1">
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="txtTaxID"
                                                                            >{{ "UI.k_tax_id" | translate
                                                                            }}</label
                                                                        >
                                                                        <input
                                                                            type="text"
                                                                            id="txtTaxID"
                                                                            class="form-control"
                                                                            placeholder="{{
                                                                                'UI.k_enter_tax_id' | translate
                                                                            }}"
                                                                            name="txtTaxID"
                                                                            [(ngModel)]="itemObj.tax_id"
                                                                            #TDTaxIDRef="ngModel"
                                                                            [class.error]="
                                                                                !TDTaxIDRef.valid &&
                                                                                organizationDetailsForm.submitted
                                                                            "
                                                                            maxlength="40"
                                                                            pattern="^[^\s]+(\s+[^\s]+)*$"
                                                                            [attr.data-testid]="'txtTaxID'"
                                                                        />
                                                                        <dns-error-validation
                                                                            [uiRef]="TDTaxIDRef"
                                                                            [formRef]="organizationDetailsForm"
                                                                        ></dns-error-validation>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <div class="d-flex align-items-center justify-content-between">
                                                                            <div class="sm-font"
                                                                            >{{ "UI.k_billing_person_name" | translate
                                                                            }}</div>
                                                                            <div class="custom-control custom-checkbox">
																				<input
																					type="checkbox"
																					class="custom-control-input"
																					id="cbSameAsAbove"
																					[attr.data-testid]="'cbSameAsAbove'"
																					name="cbSameAsAbove"
																					checked = "is_same_as_above"
                                                                                    [(ngModel)]="is_same_as_above"
                                                                                    (change) = "isSameAsAbove($event)"
																				/>
																				<label
																					class="custom-control-label text-no-wrap"
																					for="cbSameAsAbove"
																					>{{
																						"UI.k_same_as_above"
																							| translate
																					}}</label
																				>
																			</div>
                                                                        </div>
                                                                        <input
                                                                            type="text"
                                                                            id="txtBillingPersonName"
                                                                            class="form-control"
                                                                            placeholder="{{
                                                                                'UI.k_enter_name' | translate
                                                                            }}"
                                                                            name="txtBillingPersonName"
                                                                            [(ngModel)]="itemObj.billingPerson_name"
                                                                            #TDBillingPersonNameRef="ngModel"
                                                                            [class.error]="
                                                                                !TDBillingPersonNameRef.valid &&
                                                                                organizationDetailsForm.submitted
                                                                            "
                                                                            [disabled] = "isDissabled"
                                                                            maxlength="40"
                                                                            pattern="^[^\s]+(\s+[^\s]+)*$"
                                                                            [attr.data-testid]="'txtBillingPersonName'"
                                                                        />
                                                                        <dns-error-validation
                                                                            [uiRef]="TDBillingPersonNameRef"
                                                                            [formRef]="organizationDetailsForm"
                                                                        ></dns-error-validation>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="telContactNumber"
                                                                            >{{ "UI.k_contact_number" | translate
                                                                            }}</label
                                                                        >
                                                                        <ngx-intl-tel-input
                                                                                    class="w-100"
                                                                                    [preferredCountries]="
                                                                                        preferredCountries
                                                                                    "
                                                                                    [enableAutoCountrySelect]="
                                                                                        true
                                                                                    "
                                                                                    [separateDialCode]="separateDialCode"
                                                                                    [enablePlaceholder]="true"
                                                                                    [searchCountryFlag]="true"
                                                                                    [searchCountryField]="[
                                                                                        SearchCountryField.Iso2,
                                                                                        SearchCountryField.Name
                                                                                    ]"
                                                                                    [selectFirstCountry]="false"
                                                                                    [selectedCountryISO]="
                                                                                        CountryISO.UnitedStates
                                                                                    "
                                                                                    [maxLength]="15"
                                                                                    [phoneValidation]="true"
                                                                                    
                                                                                    [numberFormat]="
                                                                                        PhoneNumberFormat.National
                                                                                    "
                                                                                    [disabled] = "isDissabled"
                                                                                    name="telContactNumber"
                                                                                    id="telContactNumber"
                                                                                    [(ngModel)]="
                                                                                    itemObj.contact_number
                                                                                    "
                                                                                    #TDContactNumberRef="ngModel"
                                                                                >
                                                                                </ngx-intl-tel-input>
                                                                                <dns-error-validation
                                                                                    [uiRef]="TDContactNumberRef"
                                                                                    [formRef]="organizationDetailsForm"
                                                                                ></dns-error-validation>
                                                                        
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="txtorganizationPinCode"
                                                                            >{{ "UI.k_pincode" | translate
                                                                            }}</label
                                                                        >
                                                                        <input
                                                                            type="number"
                                                                            id="txtorganizationPinCode"
                                                                            class="form-control"
                                                                            placeholder="{{
                                                                                'UI.k_pincode' | translate
                                                                            }}"
                                                                            name="txtorganizationPinCode"
                                                                            [(ngModel)]="itemObj.pincode"
                                                                            #TDPinCodeRef="ngModel"
                                                                            [class.error]="
                                                                                !TDPinCodeRef.valid &&
                                                                                organizationDetailsForm.submitted
                                                                            "
                                                                            minlength="1"
																		    maxlength="10"
                                                                            [attr.data-testid]="'txtorganizationPinCode'"
                                                                        />
                                                                        <dns-error-validation
                                                                            [uiRef]="TDPinCodeRef"
                                                                            [formRef]="organizationDetailsForm"
                                                                        ></dns-error-validation>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="txtCity"
                                                                            >{{ "UI.k_city" | translate
                                                                            }}</label
                                                                        >
                                                                        <input
                                                                            type="text"
                                                                            id="txtCity"
                                                                            class="form-control"
                                                                            placeholder="{{
                                                                                'UI.k_city' | translate
                                                                            }}"
                                                                            name="txtCity"
                                                                            [(ngModel)]="itemObj.city"
                                                                            #TDCityRef="ngModel"
                                                                            [class.error]="
                                                                                !TDCityRef.valid &&
                                                                                organizationDetailsForm.submitted
                                                                            "
                                                                            maxlength="40"
                                                                            pattern="^[^\s]+(\s+[^\s]+)*$"
                                                                            [attr.data-testid]="'txtCity'"
                                                                        />
                                                                        <dns-error-validation
                                                                            [uiRef]="TDCityRef"
                                                                            [formRef]="organizationDetailsForm"
                                                                        ></dns-error-validation>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="txtCountry"
                                                                            >{{ "UI.k_country" | translate
                                                                            }}</label
                                                                        >
                                                                        <input
                                                                            type="text"
                                                                            id="txtCountry"
                                                                            class="form-control"
                                                                            placeholder="{{
                                                                                'UI.k_country' | translate
                                                                            }}"
                                                                            name="txtCountry"
                                                                            [(ngModel)]="itemObj.country"
                                                                            #TDCountryRef="ngModel"
                                                                            [class.error]="
                                                                                !TDCountryRef.valid &&
                                                                                organizationDetailsForm.submitted
                                                                            "
                                                                            maxlength="40"
                                                                            pattern="^[^\s]+(\s+[^\s]+)*$"
                                                                            [attr.data-testid]="'txtCountry'"
                                                                        />
                                                                        <dns-error-validation
                                                                            [uiRef]="TDCountryRef"
                                                                            [formRef]="organizationDetailsForm"
                                                                        ></dns-error-validation>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <label for="txtState"
                                                                            >{{ "UI.k_state" | translate
                                                                            }}</label
                                                                        >
                                                                        <input
                                                                            type="text"
                                                                            id="txtState"
                                                                            class="form-control"
                                                                            placeholder="{{
                                                                                'UI.k_state' | translate
                                                                            }}"
                                                                            name="txtState"
                                                                            [(ngModel)]="itemObj.state"
                                                                            #TDStateRef="ngModel"
                                                                            [class.error]="
                                                                                !TDStateRef.valid &&
                                                                                organizationDetailsForm.submitted
                                                                            "
                                                                            maxlength="40"
                                                                            pattern="^[^\s]+(\s+[^\s]+)*$"
                                                                            [attr.data-testid]="'txtState'"
                                                                        />
                                                                        <dns-error-validation
                                                                            [uiRef]="TDStateRef"
                                                                            [formRef]="organizationDetailsForm"
                                                                        ></dns-error-validation>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <div class="form-group">
                                                                        <label for="txtOrgBillAddr" class="form-label">
                                                                            {{ "UI.k_address" | translate }} ({{ "UI.k_area" | translate }} {{ "UI.k_and" | translate }} {{ "UI.k_street" | translate }} )</label
                                                                        >
                                                                        <textarea
                                                                            class="form-control"
                                                                            id="txtOrgBillAddr"
                                                                            name="txtOrgBillAddr"
                                                                            placeholder="{{ 'UI.k_address' | translate }}"
                                                                            [(ngModel)]="itemObj.address"
                                                                            tabindex="5"
                                                                        ></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
														</div>
													</ng-scrollbar>
												</div>
												<div
													class="d-flex justify-content-end border-top py-1 px-2 stepper-btn"
												>
													<button
														type="submit"
														class="btn btn-primary btn-next ml-1"
														rippleEffect
														id="btnSysNxt"
														[attr.data-testid]="'btnSysNxt'"
														name="btnSysNxt"
                                                        (click)="horizontalWizardStepperNext(organizationDetailsForm)"
													>
														<span class="align-middle d-sm-inline-block d-none">{{
															"UI.k_next" | translate
														}}</span>
														<i class="fa-light fa-arrow-right align-middle ml-sm-25"></i>
													</button>
												</div>
											</form>
										</div>
										<div id="licenseDetails" class="content">
											<form #licenseDetailsForm="ngForm" class="stepper-inner">
												<div class="stepper-body">
													<ng-scrollbar class="custom-scrollbar section-scrollbar"   [visibility]="'always'">
														<div class="stepper-main pt-0">
															<div class="row mt-1">
                                                                    <div class="col-md-6">
                                                                        <div class="form-group">
                                                                            <label for="selProduct" class="form-label">
                                                                                {{ "UI.k_product" | translate }}
                                                                                <span class="text-danger">*</span></label
                                                                            >
                                                                            <ng-select
                                                                                required
                                                                                bindLabel="name"
                                                                                bindValue="key"
                                                                                class="form-control"
                                                                                name="selProduct"
                                                                                id="selProduct"
                                                                                placeholder="{{ 'UI.k_select' | translate }}"
                                                                                [(ngModel)]="itemObj.currentKey"
                                                                                #serTypeInput="ngModel"
                                                                                [items]="infraonProducts"
                                                                                (change)="onProductChange($event)"
                                                                                [searchable]="false"
                                                                                [clearable]="false"
                                                                            >
                                                                                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                                                                                    <img
                                                                                        src="{{ logoMap[item.key] }}"
                                                                                        alt="item.name"
                                                                                        width="16"
                                                                                        height="16"
                                                                                        class="mr-50"
                                                                                    />
                                                                                    {{ item.name }}
                                                                                </ng-template>
                                                                            </ng-select>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <div class="form-group">
                                                                            <span
                                                                                class="d-flex align-items-center justify-content-between"
                                                                            >
                                                                                <label for="txtPlan">{{
                                                                                    "UI.k_plan" | translate
                                                                                }}</label>
                                                                                <span
                                                                                    class="primary-color reg-font cursor-pointer sm-font mb-25"
                                                                                    (click)="togglePricingModal(pricingModal)"
                                                                                    
                                                                                >
                                                                                    {{ "UI.k_choose_a_plan" | translate }}
                                                                                </span>
                                                                            </span>
                                                                            <input
                                                                                type="text"
                                                                                class="form-control"
                                                                                id="txtPlan"
                                                                                name="txtPlan"
                                                                                [(ngModel)]="itemObj.activePlan"
                                                                                [disabled]="true"
                                                                                placeholder="{{ 'UI.k_type_here' | translate }}"
                                                                            />
                                                                        </div>
                                                                    </div>
															</div>
                                                            <div class="row">
                                                                <div class="col-md-8">
                                                                    <div class="product-info-sec user-info-sec  card-bg card border mb-1"
                                                                    *ngFor = "let product of productOption"
                                                                    >
                                                                        <div class="icon-sec p-1 title-color border-right d-flex align-items-center">
                                                                            <i class="fa-light {{product.icon}} primary-color mr-50"></i>
                                                                            <span class="title-color fw-500">
                                                                                {{ product.name | translate }}
                                                                            </span>
                                                                        </div>
                                                                        <div class="user-count-container border-right p-1">
                                                                            <div class="form-group px-50 w-100 mb-0 d-flex align-items-center justify-content-center"
                                                                                [ngClass]="{'focus-input': product.onInputFocus , 'border': !product.onInputFocus}">
                                                                                <div>
                                                                                    <i class="fa-light sm-font {{product.icon}} mr-50"></i>
                                                                                </div>
                                                                                <div>
                                                                                    <input type="number" (focus)="onFocus(product)" (blur)="onBlur(product)" class="form-control" id="txtUserCount"
                                                                                        name="txtUserCount" [(ngModel)]="productCount[product.id]" />
                                                                                </div>
                                                                                <div>
                                                                                    <span class="text-muted">{{product.type}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="value-sec text-center p-1 title-color">
                                                                            <span *ngIf="productCount[product.id] != 0">{{ productCount[product.id] }} X {{userCount}}</span>
                                                                            <span *ngIf="productCount[product.id] != 0">=</span>
                                                                            <span class="fw-600 lg-font" *ngIf="productCount[product.id] != 0">{{
                                                                                productCount[product.id] * userCount | currency: defaultCurrency}}</span>
                                                                            <span class="fw-600 lg-font" *ngIf="productCount[product.id] === 0 || productCount[product.id] === undefined">0</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="payment-container border p-1">
                                                                        <div class="payment-body border-bottom">
                                                                            <div class="row justify-content-between px-1 mb-1 fw-500 reg-font">
                                                                                <div>{{ "UI.k_subtotal" | translate }}</div>
                                                                                <div>{{ subTotal | currency: defaultCurrency }}</div>
                                                                            </div>
                                                                            <div class="row justify-content-between px-1 mb-1 fw-500 reg-font">
                                                                                <div>{{ "UI.k_discount" | translate }}</div>
                                                                                <div>{{ dicValue | currency: defaultCurrency }}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row justify-content-between px-1 mt-1 fw-500 reg-font">
                                                                            <div>{{ "UI.k_total" | translate }}</div>
                                                                            <div>{{ totalPrice | currency: defaultCurrency }}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
														</div>
													</ng-scrollbar>
												</div>
                                                <div class="stepper-btn d-flex justify-content-between px-2 py-1">
													<div>
														<button
															id="btnSystemBack"
															class="btn btn-outline-primary btn-prev"
															(click)="horizontalWizardStepperPrevious()"
															rippleEffect
															[attr.data-testid]="'btnSystemBack'"
														>
															<i
																class="fa-light fa-arrow-left align-middle mr-sm-25 mr-0"
															></i>
															<span class="align-middle d-sm-inline-block d-none">{{
																"UI.k_back" | translate
															}}</span>
														</button>
													</div>
													<div class="d-flex">
														<button
															type="submit"
															[disabled]="disableSave"
															(click)="onOrganizationAdd(licenseDetailsForm)"
															class="btn btn-primary mr-1"
															rippleEffect
															id="btnBaseSave"
															name="btnBaseSave"
															[attr.data-testid]="'btnBaseSave'"
														>
															<i class="fa-light fa-floppy-disk align-middle mr-25"></i>
															<span class="align-middle d-sm-inline-block d-none">{{
																"UI.k_submit" | translate
															}}</span>
														</button>
													</div>
												</div>
											</form>
										</div>
									</form>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #pricingModal let-modal>
	<app-pricing-modal tag="payment_page" (callback)="callbackAfterChange($event)"></app-pricing-modal>
</ng-template>
<!--Preview modal template ends-->

