import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CoreConfigService } from "@core/services/config.service";
import { TranslateService } from "@ngx-translate/core";
import { ColumnMode, SelectionType } from "@swimlane/ngx-datatable";
import { GlobalConstants } from "app/app.globalConstants";
import { UserService } from "app/auth/service";
import { InfinityModules } from "app/common/dns/types/modules";
import { SupportPortalService } from "app/support-portal/services/support-portal.service";
import { Subject, catchError, interval, map, throwError } from "rxjs";
import { DnsGridTagComponent } from "app/common/dns/components/dns-grid-tag/dns-grid-tag.component";

@Component({
  selector: 'app-support-audit',
  templateUrl: './support-audit.component.html',
  styleUrls: ['./support-audit.component.scss']
})
export class SupportAuditComponent implements OnInit {
	pageNumber: any = 1;
	public ColumnMode = ColumnMode;
	public dataRow: any;
	public basicSelectedOption: number = 10;
	sortKey: any = "display_id";
	reverse: any = "";
	limit: any = 10;
	totalCount: any;
	permissions: any;
	module = InfinityModules.ORGANIZATION;
	columns: Array<any>;
	public filterData = true;
	submenu: boolean = false;
	showFilter: boolean = false;
	showRightActions: boolean = true;
	viewType: string = "grid";
	pageNumberGrid = 0;
	pageNumberCard = 1;
	public filter: any;
	public showAll = false;
	serviceCount: 0;
	cardCount = 0;
  constructor(private _translateService: TranslateService) { }

  ngOnInit(): void {
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}

		this.permissions = user_permissions[this.module];
		this.dataRow = [
			{
				id: "6455f7efe53a3076e091d506",
				creation_time: "May 06, 2023 06:47 AM",
				organization: "118334704648390184960",
				module_type: "User",
				raw_module_type: "",
				event: "Login",
				raw_event: "Login",
				description: "User Logged In",
				raw_description: "User Logged In",
				user: 3,
				user_name: "Infraon Dev",
				user_ip: "10.81.234.2",
				last_update_time: null,
				is_deleted: false,
			},
			{
				id: "64550a48cf431d6471bf2ccc",
				creation_time: "May 05, 2023 01:53 PM",
				organization: "118334704648390184960",
				module_type: "User",
				raw_module_type: "",
				event: "Login",
				raw_event: "Login",
				description: "User Logged In",
				raw_description: "User Logged In",
				user: 345,
				user_name: "Arun U",
				user_ip: "127.0.0.1",
				last_update_time: null,
				is_deleted: false,
			},
			{
				id: "645503b86db11d9ca86d2120",
				creation_time: "May 05, 2023 01:25 PM",
				organization: "118334704648390184960",
				module_type: "User",
				raw_module_type: "",
				event: "Login",
				raw_event: "Login",
				description: "User Logged In",
				raw_description: "User Logged In",
				user: 345,
				user_name: "Arun U",
				user_ip: "127.0.0.1",
				last_update_time: null,
				is_deleted: false,
			},
			{
				id: "6454fb68fe24c8ea4f1326ac",
				creation_time: "May 05, 2023 12:49 PM",
				organization: "118334704648390184960",
				module_type: "User",
				raw_module_type: "",
				event: "Login",
				raw_event: "Login",
				description: "User Logged In",
				raw_description: "User Logged In",
				user: 138,
				user_name: "Mobile User",
				user_ip: "10.0.6.64",
				last_update_time: null,
				is_deleted: false,
			},
			{
				id: "6454fad09f1d8cce4e6f5d72",
				creation_time: "May 05, 2023 12:47 PM",
				organization: "118334704648390184960",
				module_type: "User",
				raw_module_type: "",
				event: "Login",
				raw_event: "Login",
				description: "User Logged In",
				raw_description: "User Logged In",
				user: 3,
				user_name: "Infraon Dev",
				user_ip: "192.168.51.1",
				last_update_time: null,
				is_deleted: false,
			},
			{
				id: "6454faa012dfdb317d4c6ef5",
				creation_time: "May 05, 2023 12:46 PM",
				organization: "118334704648390184960",
				module_type: "User",
				raw_module_type: "",
				event: "Login",
				raw_event: "Login",
				description: "User Logged In",
				raw_description: "User Logged In",
				user: 3,
				user_name: "Infraon Dev",
				user_ip: "192.168.51.1",
				last_update_time: null,
				is_deleted: false,
			},
			{
				id: "6454fa5b94063965e3d1f36b",
				creation_time: "May 05, 2023 12:45 PM",
				organization: "118334704648390184960",
				module_type: "Asset Category",
				raw_module_type: "Asset Category",
				event: "Add",
				raw_event: "Add",
				description:
					"Created Asset Category:</br> <b>Name</b>: <b>Mob App test</b></br><b>Asset Class</b>: <b>IT Assets</b></br>",
				raw_description: "Created Asset Category: Name: Mob App test Asset Class: IT Assets ",
				user: 138,
				user_name: "Mobile User",
				user_ip: "192.168.51.1",
				last_update_time: null,
				is_deleted: false,
			},
			{
				id: "6454fa09fe24c8ea4f1326ab",
				creation_time: "May 05, 2023 12:43 PM",
				organization: "118334704648390184960",
				module_type: "User",
				raw_module_type: "",
				event: "Login",
				raw_event: "Login",
				description: "User Logged In",
				raw_description: "User Logged In",
				user: 138,
				user_name: "Mobile User",
				user_ip: "192.168.51.1",
				last_update_time: null,
				is_deleted: false,
			},
			{
				id: "6454f978b9efbc77c91e2fa2",
				creation_time: "May 05, 2023 12:41 PM",
				organization: "118334704648390184960",
				module_type: "User",
				raw_module_type: "",
				event: "Login",
				raw_event: "Login",
				description: "User Logged In",
				raw_description: "User Logged In",
				user: 3,
				user_name: "Infraon Dev",
				user_ip: "192.168.51.1",
				last_update_time: null,
				is_deleted: false,
			},
			{
				id: "6454f9730518454098d66788",
				creation_time: "May 05, 2023 12:41 PM",
				organization: "118334704648390184960",
				module_type: "User",
				raw_module_type: "",
				event: "Login",
				raw_event: "Login",
				description: "User Logged In",
				raw_description: "User Logged In",
				user: 3,
				user_name: "Infraon Dev",
				user_ip: "192.168.51.1",
				last_update_time: null,
				is_deleted: false,
			},
		];
		this.totalCount = this.dataRow.length;
		this.columns = [
      {
				label: this._translateService.instant("UI.k_module"),
				key: "module_type",
			},
			{
				label: this._translateService.instant("UI.k_event"),
				key: "event",
			},
			{
				label: this._translateService.instant("UI.k_description"),
				key: "description",
				type: "html",
				width: 200,
			},
			{
				label: this._translateService.instant("UI.k_user_name"),
				key: "user_name",
			},
			{
				label: this._translateService.instant("UI.k_ip_address"),
				key: "user_ip",
			},
			{
				label: this._translateService.instant("UI.k_creation_time"),
				key: "creation_time",
			},
		];
  }
	onSort(args): void {
		if (args.sorts) {
			this.pageNumber = 0;
			let sortKey = args.sorts[0].prop;
			this.sortKey = sortKey;
			if (args.sorts[0].dir == "desc") {
				this.reverse = "-";
			} else {
				this.reverse = "";
			}
		}
	}
	setLimit(args): void {
		this.pageNumber = 0;
		this.limit = args.target.value;
	}
	submenuToggaleClick(): void {
		this.submenu = !this.submenu;
	}
	updateSidebar() {
		if (window.innerWidth >= 1200) {
			this.submenu = true; // lg
		} else {
			this.submenu = false; //md
		}
	}
	checkView(viewName) {
		this.viewType = viewName;
		if (viewName == "card") {
			this.limit = 10;
			this.pageNumber = 1;
		}
		if (viewName == "grid") {
			this.limit = 10;
			this.pageNumber = 0;
		}
	}
	onLimitChange = (limit) => {
		this.pageNumber = 1;
		if (limit === "all") {
			this.showAll = true;
			this.limit = this.serviceCount;
		} else {
			this.limit = Number(limit);
			this.showAll = false;
		}
	};
	loadPage = (data) => {
		this.pageNumber = data?.page;
	};

	ngOnDestroy(): void {
		// try {
		// 	if (this.pageReload) this.pageReload.unsubscribe();
		// } catch (e) {}
		// // Unsubscribe from all subscriptions
		// this._unsubscribeAll.next(undefined);
		// this._unsubscribeAll.complete();
	}
}
