import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "environments/environment";
import { ApiService } from "app/common/services/api/api.service";

@Injectable({
	providedIn: "root",
})
export class AdminService extends ApiService {
	constructor(http: HttpClient) {
		super(http);
	}

	getCustomerData(payload: any) {
		return this.http.get<any>(`${environment.instance_url}ux/subs/customer/data/`, {
			params: payload,
		});
	}

	getProductFamilies(payload: any): Observable<any> {
		return this.http.get<any>(`${environment.instance_url}ux/subs/product/families/`, {
			params: payload,
		});
	}

	getProductPlans(payload: any): Observable<any> {
		return this.http.get<any>(`${environment.instance_url}ux/subs/product/plans/`, {
			params: payload,
		});
	}

	getProductDetailByItemId(payload: any): Observable<any> {
		return this.http.get<any>(`${environment.instance_url}ux/subs/product/plan/`, {
			params: payload,
		});
	}

	getInstanceSubscriptions(payload: any): Observable<any> {
		return this.http.get<any>(`${environment.instance_url}ux/subs/subscription/list/`, {
			params: payload,
		});
	}

	getInstances(payload: any): Observable<any> {
		return this.http.get<any>(`${environment.instance_url}ux/subs/instance/list/`, {
			params: payload,
		});
	}

	getInstance(payload: any): Observable<any> {
		return this.http.get<any>(`${environment.instance_url}ux/subs/instance/data/`, {
			params: payload,
		});
	}

	getChargebeeConfiguration() {
		return this.http.get(`${environment.instance_url}ux/subs/subscription/access/`);
	}

	doPreCheck(payload: any): Observable<any> {
		return this.http.get<any>(`${environment.instance_url}ux/subs/subscription/pre-check/`, {
			params: payload,
		});
	}

	createSubscription(payload: any): Observable<any> {
		return this.http.get<any>(`${environment.instance_url}ux/subs/subscription/direct/`, {
			params: payload,
		});
	}

	getChargebeeCheckoutOptions(payload: any): Observable<any> {
		return this.http.get<any>(`${environment.instance_url}ux/subs/subscription/checkout/`, {
			params: payload,
			headers: new HttpHeaders({
				"Content-Type": "application/x-www-form-urlencoded",
			}),
		});
	}

	doChargebeePostCheckout(payload: any): Observable<any> {
		return this.http.get<any>(`${environment.instance_url}ux/subs/subscription/checkout_post/`, {
			params: payload,
		});
	}

	getInvoices(payload: any): Observable<any> {
		return this.http.get<any>(`${environment.instance_url}ux/subs/subscription/invoices/`, {
			params: payload,
		});
	}

	getInvoicesCount(payload: any): Observable<any> {
		return this.http.get<any>(`${environment.instance_url}ux/subs/subscription/invoices_count/`, {
			params: payload,
		});
	}

	downloadInvoice(payload: any): Observable<any> {
		return this.http.get<any>(`${environment.instance_url}ux/subs/subscription/invoice_pdf/`, {
			params: payload,
		});
	}

	reactiveSubscription(payload: any): Observable<any> {
		return this.http.get<any>(`${environment.instance_url}ux/subs/subscription/reactivate/`, {
			params: payload,
		});
	}

	saveOrgConfig(payload: any) {
		return this.post("ux/org/profile/portal_org/", payload);
	}

	saveCustomerData(payload: any) {
		return this.http.post<any>(`${environment.instance_url}ux/subs/customer/savedata/`, payload);
		// return this.post("ux/subs/customer/datan/", payload);
	}

	getUsageCount() {
		return this.get("ux/common/user/profile/org_summary/");
	}

	getSubscription(payload = {}) {
		return this.get("ux/subs/subscription/data/", payload);
	}

	getInstanceReactivateCount() {
		return this.get("ux/subs/instance/reactivate_summary/");
	}

	getCostEstimate(payload) {
		return this.get("ux/subs/subscription/estimate/", payload);
	}

	getCreditNotes(payload) {
		return this.get("ux/subs/subscription/creditnotes/", payload);
	}

	getProdAddOns(payload) {
		return this.get("ux/subs/product/addons/", payload);
	}

	// doChargebeeCollectNow(payload: any): Observable<any> {
	// 	return this.http.get<any>(`${environment.instance_url}ux/subs/subscription/pay/`, {
	// 		params: payload,
	// 		headers: new HttpHeaders({
	// 			"Content-Type": "application/x-www-form-urlencoded",
	// 		}),
	// 	});
	// }
}
