import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from "app/common/services/api/api.service";
import { of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SspConfigurationService extends ApiService{
	constructor(httpClient: HttpClient) {
		super(httpClient);
	}
  	getSSPConfiguration() {
		return this.get("ux/common/ssp/config/");
	}

	saveSSPConfiguration(payload) {
		return this.post("ux/common/ssp/config/", payload);
	}
}
