import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, NgForm, ValidationErrors, Validator} from '@angular/forms';
import {isEmpty} from 'lodash';

@Directive({
	selector: '[urlValidator]',
	providers: [{provide: NG_VALIDATORS, useExisting: UrlValidatorDirective, multi: true}]
})
export class UrlValidatorDirective implements Validator {
	@Input('urlValidator') invalidemail: '';
	validate = (control: AbstractControl): ValidationErrors | null => {
		if (control?.value !== "") {
			let url;
			let isValid = true;
			try {
				url = new URL(control?.value);
			} catch (_) {
				return {invalidUrl: true};
			}
			isValid = url.protocol === 'http:' || url.protocol === 'https:';
			return isValid ? null : {invalidUrl: true};
		}
	}
}
