import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { TranslateService } from "@ngx-translate/core";
import { GlobalConstants } from "app/app.globalConstants";
import { InfinityModules } from "app/common/dns/types/modules";
import { SupportPortalService } from "app/support-portal/services/support-portal.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { catchError, map } from "rxjs/operators";
import { throwError } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { PartnerNameTagComponent } from "../partner-name-tag/partner-name-tag.component";
import { CoreConfigService } from "@core/services/config.service";

@Component({
	selector: "app-partners-list",
	templateUrl: "./partners-list.component.html",
	styleUrls: ["./partners-list.component.scss"],
})
export class PartnersListComponent implements OnInit {
	@Output("hideShowDetails") hideShowDetails = new EventEmitter();
	partners: any;
	columns: Array<any>;
	showSidebarDetails = false;
	permissions: any;
	partnerCount: any;
	pageNumber = 0;
	limit = 10;
	partnerid: any;
	currentEvent: String;
	selectedOption: any = "10";
	public showData = false;
	sortKey: any = "";
	reverse: any = "";
	hideProfile: boolean = false;
	globalSearch: any = false;
	public empty_data;
	hideInviteProfile = false;

	module = InfinityModules.INFINITY_PARTNER;
	filter = [];

	public showAll = false;
	public showDataTable = false;
	public showRightsection: false;
	public filterData = false;
	public columnOptions = [];
	public savedData = [];

	constructor(
		private _service: SupportPortalService,
		private _coreSidebarService: CoreSidebarService,
		private _translateService: TranslateService,
		private router: Router,
		private _toastr: ToastrService,
		private _coreConfigService: CoreConfigService
	) {}

	ngOnInit(): void {
		this.getSavedFilters();
		this.getFilterOptions();
		this.setNavbarConfig();

		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.permissions = user_permissions[this.module];

		this.empty_data = {
			icon: "fa-user",
			msg: this._translateService.instant("Message.msg_partner_skeleton_heading"),
			description: this._translateService.instant("Message.msg_partner_skeleton_descr"),
			add_callback: this.toggleAddPartner,
			sidebar_name: "create-partner",
			button: this._translateService.instant("UI.k_create_partner"),
			import_csv: false,
			id: "btnAddPartner",
		};

		this.partners = [];
		this.partnerCount = this.partners.length;
		this.currentEvent = "Add";
		this.columns = [
			{
				label: this._translateService.instant("UI.k_partner_name"),
				key: "partner_name",
				width: 220,
				component: PartnerNameTagComponent,
			},
			{
				label: this._translateService.instant("UI.k_email"),
				key: "email",
				width: 220,
			},
			{
				label: this._translateService.instant("UI.k_phone"),
				key: "e164Number",
				subkey: "phone",
				sortable: false,
			},
			{
				label: this._translateService.instant("UI.k_address"),
				key: "address",
			},
			// {
			// 	label: this._translateService.instant("UI.k_partner_operators"),
			// 	key: "partner_operators",
			// },
			{
				label: this._translateService.instant("UI.k_action"),
				key: "action",
				actions: [
					{
						label: this._translateService.instant("UI.k_edit"),
						icon: "fa-pen-to-square",
						key: "edit",
						class: "text-primary btn btn-icon btn-outline-secondary",
						listener: this.toggleEditPartner,
					},
					{
						label: this._translateService.instant("UI.k_delete"),
						icon: "fa-trash-can",
						key: "delete",
						class: "text-danger btn btn-icon btn-outline-secondary",
						listener: this.deletePartner,
					},
					// {
					// 	label: this._translateService.instant("UI.k_invite_user"),
					// 	icon: "fa-paper-plane",
					// 	key: "edit",
					// 	class: "text-warning btn btn-icon btn-outline-secondary",
					// 	listener: this.invitePartneruser,
					// },
				],
			},
		];
		this.getPartnerList();
	}

	toggleEditPartner = (args): void => {
		this.currentEvent = "Edit";
		this.partnerid = args.partner_id;
		this.showSidebarDetails = true;
		this._coreSidebarService.getSidebarRegistry("create-partner").toggleOpen();
		this.hideProfile = true;
	};

	toggleAddPartner = (name): void => {
		this.partnerid = -1;
		this.currentEvent = "Add";
		this.showSidebarDetails = true;
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
		this.hideShowDetails.emit("hide");
		this.hideProfile = true;
	};

	onSort(args): void {
		if (args.sorts) {
			this.pageNumber = 0;
			this.sortKey = args.sorts[0].prop;

			if (this.sortKey === "partner_name") {
				this.sortKey = "partner_name";
			}
			if (this.sortKey === "email") {
				this.sortKey = "email";
			}
			if (this.sortKey === "e164Number") {
				this.sortKey = "phone";
			}
			if (this.sortKey === "address") {
				this.sortKey = "address";
			}
			if (args.sorts[0].dir == "desc") {
				this.reverse = "-";
			} else {
				this.reverse = "";
			}
		}
		this.getPartnerList();
	}

	setPage(pageInfo) {
		this.pageNumber = pageInfo.offset;
		this.getPartnerList();
	}

	setLimit(limit): void {
		this.pageNumber = 0;
		if (limit === "all") {
			this.showAll = true;
			this.limit = this.partnerCount;
			this.selectedOption = "Show All";
		} else {
			this.limit = Number(limit);
			this.showAll = false;
			this.selectedOption = limit;
		}

		this.pageNumber = 0;
		// this.limit = limit
		this.getPartnerList();
	}

	onHideProfile = (e) => {
		this.hideProfile = false;
		if ((this.globalSearch = true))
			//this.router.navigateByUrl('/teams');
			this.router.navigate([], { queryParams: {} });
	};

	onUpdate(args): void {}

	getPartnerList(fromAdd: any = false) {
		if (fromAdd == true && this.currentEvent == "Add") {
			if (this.selectedOption == "Show All") {
				this.limit = this.limit + 1;
			}
		}
		if (this.currentEvent == "Delete") {
			if (this.selectedOption == "Show All") {
				this.limit = this.limit - 1;
			}
		}
		const payload = {
			page: this.pageNumber + 1,
			items_per_page: this.limit,
			sort: this.sortKey,
			reverse: this.reverse,
			filter: JSON.stringify(this.filter),
			module: "infinity_partner",
		};
		this._service.getPartners(payload).subscribe(
			(response) => {
				if (response) {
					this.partners = response.results;
					this.partnerCount = response.count;
					this.showData = true;
				}
			},
			(error) => {
				//todo
			}
		);
	}

	deletePartner = (row) => {
		this.currentEvent = "Delete";
		Swal.fire({
			title: this._translateService.instant("Message.msg_delete_title"),
			text: this._translateService.instant("Message.msg_delete_text"),
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: this._translateService.instant("Message.msg_delete_confirm"),
			cancelButtonText: this._translateService.instant("Message.msg_delete_cancel"),
			customClass: {
				confirmButton: "btn btn-primary",
				cancelButton: "btn btn-danger ml-1",
			},
		}).then((result) => {
			if (result.value) {
				this._service
					.deletePartner(row.partner_id)
					.pipe(
						map((response: any) => {
							if (response.status == "success") {
								if (
									this.partnerCount != 1 &&
									this.partnerCount - 1 < this.pageNumber * this.limit + 1
								) {
									this.pageNumber = this.pageNumber - 1;
								}
								this.getPartnerList();
								this._toastr.success(
									this._translateService.instant("Message.msg_partner_deleted"),
									this._translateService.instant("Message.msg_delete_success"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							} else {
								this._toastr.error(
									this._translateService.instant(response["message"]),
									this._translateService.instant("Error.err_delete_failed"),
									{
										toastClass: "toast ngx-toastr",
										closeButton: true,
										positionClass: "toast-top-right",
									}
								);
							}
							// Success
						}),
						catchError((error) => {
							this._toastr.error(
								this._translateService.instant("Error.err_delete_failed"),
								this._translateService.instant("Error.err_fail"),
								{
									toastClass: "toast ngx-toastr",
									closeButton: true,
									positionClass: "toast-top-right",
								}
							);
							return throwError(error.statusText);
						})
					)
					.subscribe();
			}
		});
	};

	ngOnDestroy(): void {
		Swal.close();
	}

	getFilterSearch = ($event) => {
		this.pageNumber = 0;
		let dict = {};
		this.filter = [];
		dict["ui_display"] = $event.userSearchData;
		dict["filter_string"] = $event.userSearchString;
		dict["filter_item"] = $event.filterItem;
		dict["type"] = $event.type;
		dict["module"] = "infinity_partner";
		this.filter.push(dict);
		const payload = {
			page: 1,
			items_per_page: this.limit,
			sort: this.sortKey,
			reverse: this.reverse,
			filter: JSON.stringify(this.filter),
			module: "infinity_partner",
		};
		this.getPartnerListData(payload);
	};

	getFilterOptions(): void {
		this._service.getFilterOptions({ moduleName: "infinity_partner" }).subscribe(
			(response) => {
				if (response) {
					this.columnOptions = response;
				} else {
					this.columnOptions = [];
				}
			},
			(error) => {
				this.columnOptions = [];
			}
		);
	}

	getPartnerListData(payload) {
		this._service.getPartners(payload).subscribe(
			(response) => {
				if (response) {
					this.partners = response.results;
					this.partnerCount = response.count;
					this.showData = true;
					this.filterData = true;
				}
				this.getSavedFilters();
			},
			(error) => {
				//todo
			}
		);
	}

	getSavedFilters(): void {
		this._service
			.getSavedFilter({ module: "infinity_partner" })
			.pipe(
				map((response: any) => {
					if (response) {
						this.savedData = response;
					}
				})
			)
			.subscribe();
	}

	genericSearchFilter = ($event) => {
		let search_str = $event.userSearchData.trim();
		let search_db_cols = [
			{ db_col: "partner_name", cond: "contains" },
			{ db_col: "email", cond: "contains" },
		];
		let filter_options = [];
		search_db_cols.forEach((item) => {
			filter_options.push({
				condition: item.cond,
				db_col: item.db_col,
				value: search_str,
				relation: "or",
			});

			if (filter_options.length == search_db_cols.length) {
				this.pageNumber = 0;
				let dict = {};
				dict["ui_display"] = $event.userSearchData;
				dict["filter_string"] = filter_options;
				dict["filter_item"] = $event.filterItem;
				dict["type"] = $event.type;
				dict["module"] = "infinity_partner";
				for (let i = 0; i < this.filter.length; i++) {
					dict["filter_string"].push(this.filter[i]);
				}

				this.filter = [];
				this.filter.push(dict);
				const payload = {
					page: 1,
					items_per_page: this.limit,
					sort: this.sortKey,
					reverse: this.reverse,
					filter: JSON.stringify(this.filter),
					module: "infinity_partner",
				};
				this.getPartnerListData(payload);
			}
		});
	};

	invitePartneruser = (args: any): void => {
		this.partnerid = args.partner_id;
		this._coreSidebarService.getSidebarRegistry("invite-partneruser").toggleOpen();
		this.hideInviteProfile = true;
	};

	onHideInviteProfile = (e) => {
		this.hideInviteProfile = false;
	};

	setNavbarConfig(): void {
		this._coreConfigService.config = {
			layout: {
				navbar: {
					hidden: false,
					pageTitle: this._translateService.instant("UI.k_infinity_partners"),
					moduleName: this.module,
					bookmark: false,
					showBreadcrumb: false,
					enableLocalStorage: false,
					pageIcon: "",
					search: [],
					breadcrumb: {},
				},
			},
		};
	}
}
