import { Component, Input, OnInit } from '@angular/core';
import { NgEventBus } from "ng-event-bus";


@Component({
  selector: 'app-dns-table-count',
  templateUrl: './dns-table-count.component.html',
  styleUrls: ['./dns-table-count.component.scss']
})
export class DnsTableCountComponent implements OnInit {

  @Input() tableHeader: any;
  @Input() tableData: any;
  @Input() widgetData:any;

  constructor(
    private eventBus: NgEventBus
  ) { }

  ngOnInit(): void {
    if (this.tableHeader){
      //console.log(this.tableHeader,this.tableData)
    }
  }

  showEventTable(dat,alarm_id){
    //console.log(dat)
    let payload = {
        "title": "Events",
        "app_type": "event-grid",
        "item_filter": {"organization":dat.filters.organization,"alarm_id":alarm_id},
        "alarm_id":alarm_id,
    }
    //console.log(">>",payload)
    this.eventBus.cast('dashboard-view:sidebar', payload);

}
}
