import { Injectable } from "@angular/core";
import { ApiService } from "app/common/services/api/api.service";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
// import * as  getAllOptionsList from "../../../../assets/json-response/dashboard_options.json"
// import * as  getWidgetOptions from "../../../../assets/json-response/widget_options.json"
// import { of } from 'rxjs';

@Injectable({
	providedIn: "root",
})
export class DashboardService extends ApiService {
	public dashboardConfig: any = {};
	public isDashboadChange = new BehaviorSubject<object>({ changeType: "", id: "" });
	public isDefaultDashboard = new BehaviorSubject<object>({ id: "" });
	public showLoader = new BehaviorSubject<boolean>(true);
	public updateScroll = new BehaviorSubject<boolean>(true);
	public showSlider = new BehaviorSubject<string>(null);
	public widgetFilter: any = {}
	constructor(http: HttpClient) {
		super(http);
	}

	setFilter(filter: any) {
		this.widgetFilter = { ...this.widgetFilter, ...filter };
	  }
	
	getFilter() {
		return this.widgetFilter;
	}

	removeFilter(key: string) {
		if (key === "all"){
			this.widgetFilter = {};
		}
		else{
			delete this.widgetFilter[key];
		}
	}

	getDashboards(payload) {
		return this.get("ux/common/dashboard/dashboard/", payload);
	}

	getDashboard(dashboard_id: any) {
		return this.get("ux/common/dashboard/dashboard/" + dashboard_id + "/");
	}

	getViewDashboard(dashboard_id: any) {
		return this.get("ux/common/dashboard/dashboard/view/" + dashboard_id + "/");
	}

	saveDashboard(payload) {
		return this.post("ux/common/dashboard/dashboard/", payload);
	}

	setDefaultDashboard(payload) {
		return this.post("ux/common/dashboard/dashboard/", payload);
	}

	deleteDashboard(id) {
		return this.delete("ux/common/dashboard/dashboard/" + id + "/");
	}

	getOptions(payload) {
		return this.get("ux/common/dashboard/dashboard/options/", payload);
	}

	// getWidgetOptions(payload: {}) {
	// 	return this.get("ux/common/dashboard/dashboard/widgetoptions/", payload);
	// }

	setDashboardDetails(event) {
		this.dashboardConfig = event;
	}

	getDashboardDetails() {
		return this.dashboardConfig;
	}

	getWidgetData(payload: {}) {
		return this.get("ux/common/dashboard/dashboard/widgetdata/", payload);
	}

	getFilterOptions(payload: any) {
		return this.get("ux/common/filters/filters/filter_options/", payload);
	}

	getSavedFilter(payload) {
		return this.get("ux/common/filters/filters/filter/", payload);
	}

	getWidgetDataforConf(payload: any) {
		return this.get("ux/common/dashboard/dashboard/widgetconfig/", payload);
	}

	getCustomData(payload: any) {
		return this.get("ux/common/dashboard/dashboard/custom-options/", payload);
	}
	postUserDefaultDashboard(payload: any) {
		return this.post("ux/common/dashboard/dashboard/pin-dashboard/", payload);
	}
	getUserDefaultDashboard(payload: any) {
		return this.get("ux/common/dashboard/dashboard/default-dashboard/", payload);
	}

	getAllDashboards(payload) {
		return this.get("ux/common/dashboard/dashboard/all-dashboards/", payload);
	}

	saveDashboardSlideConfig(payload) {
		return this.post("ux/common/dashboard/dashboard/save-slide-config/", payload);
	}

	getSlidesConfigured(payload) {
		return this.get("ux/common/dashboard/dashboard/slides-dashboards/", payload);
	}
}
