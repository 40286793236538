import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
// import {
//   PerfectScrollbarConfigInterface,
//   PerfectScrollbarModule,
//   PERFECT_SCROLLBAR_CONFIG
// } from 'ngx-perfect-scrollbar';
import { CoreCommonModule } from "@core/common.module";
import { CoreSidebarModule } from "@core/components";
import { PagesModule } from "../../pages/pages.module";
import { NavbarModule } from "app/common/layout/components/navbar/navbar.module";
import { ContentModule } from "app/common/layout/components/content/content.module";
import { MenuModule } from "app/common/layout/components/menu/menu.module";
import { FooterModule } from "app/common/layout/components/footer/footer.module";
import { NgScrollbarModule } from "ngx-scrollbar";
import { VerticalLayoutComponent } from "app/common/layout/vertical/vertical-layout.component";
import { AdminModule } from "app/admin/admin.module";
import { SupportPortalModule } from "app/support-portal/support-portal.module";
import { SupportMenuModule } from "../components/menu/support-menu/support-menu.module";
import { DnsModule } from "app/common/dns/dns.module";

// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//   suppressScrollX: true,
//   wheelPropagation: true
// };
@NgModule({
	declarations: [VerticalLayoutComponent],
	imports: [
		RouterModule,
		CoreCommonModule,
		CoreSidebarModule,
		NavbarModule,
		MenuModule,
		ContentModule,
		FooterModule,
		PagesModule,
		NgScrollbarModule,
		AdminModule,
		SupportPortalModule,
		SupportMenuModule,
		DnsModule
	],
	exports: [VerticalLayoutComponent],
	// providers: [
	//   {
	//     provide: PERFECT_SCROLLBAR_CONFIG,
	//     useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
	//   }
	// ],
})
export class VerticalLayoutModule {}
