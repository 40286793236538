import { Injectable } from "@angular/core";
import { ApiService } from "app/common/services/api/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class SystemMenuService extends ApiService {
	constructor(http: HttpClient) {
		super(http);
	}
	getFavourites() {
		return this.get("ux/common/user/profile/favourites/");
	}

	saveAwayReason(payload) {
		return this.post("ux/common/user/profile/away/", payload);
	}
	getOptions() {
		return this.get("ux/common/user/profile/options/");
	}
	getUserProfile() {
		return this.get("ux/common/user/profile/myprofile/");
	}
	getProfileImage = function (id) {
		return this.get("ux/common/user/profileimage/" + id + "/");
	};
	saveUserProfileImage(payload) {
		return this.post("ux/common/user/profileimage/", payload);
	}
	saveUserProfile(payload) {
		return this.post("ux/common/user/profile/myprofile/", payload);
	}
	changePassword(payload: any) {
		return this.post("ux/common/user/profile/reset_password/", payload);
	}
	saveRequesterProfile(payload: any) {
		return this.post("ux/common/requester/profile/", payload);
	}
	getSSOLogoutUrl() {
		return this.get("ux/oidc/logout");
	}
	getNccmUrl(payload: any) {
		return this.get("ux/common/cmdb/profile/nccm_url/", payload);
	}
	getUserSignatureList(payload: any) {
		return this.get("ux/common/user/profile/sign_list/", payload);
	}
	saveUserSignature(payload) {
		return this.post("ux/common/user/profile/save_sign/", payload);
	}
	getUserSignatureData(id) {
		return this.get('ux/common/user/profile/user_sign_data/'+id+'/');
	}
	deleteUserSignature(id) {
		return this.post('ux/common/user/profile/delete_user_sign/'+id+'/');
	}
}
