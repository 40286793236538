import { Component, OnInit, Input } from '@angular/core';
import { NgEventBus } from "ng-event-bus";

@Component({
  selector: 'app-change-summary',
  templateUrl: './change-summary.component.html',
  styleUrls: ['./change-summary.component.scss']
})
export class ChangeSummaryComponent implements OnInit {
  @Input() config: any;
	@Input() modifiedtime: any;

  constructor(
    private eventBus: NgEventBus
  ) { }

  ngOnInit(): void {
    //console.log("config", this.config);
  }
  showChangeSidebar(dat){
    //console.log(dat, "dat-------------------------")
		if (dat?.value > 0 && dat?.item_filter){
      //console.log(dat?.value > 0 && dat?.item_filter, "dat?.value > 0 && dat?.item_filter---------------")
			let payload = {
				"title": "Changes - " + dat.name,
				"app_type": "change-grid",
				"item_filter": dat.item_filter,
        "timescale" : this.config?.timescale
			}
			// //console.log("in inci summary widget -> ", payload)
			this.eventBus.cast('dashboard-view:sidebar', payload);
		}
	}

}
