import {
  Directive,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  HostListener,
} from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

@Directive({
  selector: '[dnsClick]',
})
export class DnsClickDirective implements OnInit, OnDestroy {
  @Output() dnsClickListiner = new EventEmitter()
  @Input() clickDelay = 200
  private clicks = new Subject()
  private subscription: Subscription

  constructor() {}

  ngOnInit() {
    this.subscription = this.clicks
      .pipe(debounceTime(this.clickDelay))
      .subscribe(e => this.dnsClickListiner.emit(e))
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault()
    event.stopPropagation()
    this.clicks.next(event)
  }
}
