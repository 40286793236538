<div class="slideout-content default-slideout">
	<div
		class="modalsd modal-slide-in sdfade new-user-modal"
		id="modals-slide-in"
	>
		<div class="modal-dialog">
			<!-- form-width -->
			<div class="modal-content add-new-user pt-0">
				<div class="modal-header">
					<h5 class="modal-title" id="exampleModalLabel">
						{{ "UI.k_knowledgebase" | translate }}
					</h5>
					<button
						type="button"
						id="btnClose"
						name="btnClose"
						class="btn btn-flat-secondary btn-icon"
						data-dismiss="modal"
						aria-label="Close"
						(click)="toggleSidebar('attach-knowledge-base')"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
				</div>
				<div class="modal-body">
					<ng-scrollbar class="custom-scrollbar section-scrollbar">
						<div class="p-1">
							<div class="show-attach-kb">
								<div class="custom-control custom-control-primary custom-switch mb-1 d-flex">
									<span class="mb-0 mr-50"> {{ "UI.k_show_attached" | translate }} ( {{attachedKb?.length}} )</span>
									<input type="checkbox" class="custom-control-input" id="swAttachKB" [(ngModel)]="this.isAttachFlag" [checked]="this.isAttachFlag"  (click)="attachKbEvent($event)" checked />
									<label class="custom-control-label" for="swAttachKB"></label>
								  </div>
							</div>
							<ng-container *ngIf="this.isAttachFlag">
								<ng-container *ngIf="attachedKb?.length > 0"> 
									<div class="attached-kb border-bottom">
										<!-- <div class="title-color sm-font py-1">
											{{ "UI.k_attached_kb" | translate | uppercase }} ({{
												suggested_kb.length
											}})
										</div> -->
										<!-- <div *ngIf="attachedKb?.length == 0" class="no-data">
											<div
												class="d-flex align-items-center justify-content-center pt-3"
											>
												<div
													class="cursor-pointer mr-1 body-color"
													id="btnRedirectToKB"
													(click)="redirectToKb(kb.id)"
													routerLink="/kb/view/{{ kb.kb_id }}"
													ngbTooltip="{{ 'UI.k_view' | translate }}"
												>
													<i class="fa-light fa-circle-info"></i>
												</div>
												<button
												class="btn btn-sm btn-flat-primary active" type="button"
													id="btnDettachToIncident"
													(click)="DettachToIncident(kb)"
													ngbTooltip="{{ 'UI.k_unpin' | translate }}"
												>
													<div
														class="avatar-content"
														style="width: 28px; height: 28px"
													>
														<span><i class="fa-light fa-book-open"></i></span>
													</div>
												</div>
											</div>
											<div class="d-flex align-items-center justify-content-center">
												<small class="ml-75 text-muted py-auto"
													>{{ "UI.k_no" | translate }}
													{{ "UI.k_attached_kb" | translate }}</small
												>
											</div>
										</div> -->
										<div  *ngIf="attachedKb?.length > 0" class="suggested-kb-list">
											<ng-container
											*ngFor="let kb of attachedKb; let i = index"
											class=""
										>
											<div class="card p-1 border single-kb shadow-none">
												<div
													class="d-flex flex-wrap justify-content-between mb-50 align-items-center"
												>
													<div class="text-primary fw-500">
														#{{ kb.display_id }}
													</div>
													<span class="d-flex align-items-center">
														<div
															class="cursor-pointer mr-1 body-color"
															id="btnRedirectToKB"
															(click)="redirectToKb(kb.id)"
														>
															<i class="fa-light fa-circle-info"></i>
														</div>
														<button
														class="btn btn-sm btn-flat-primary active" type="button"
															id="btnDettachToIncident"
															(click)="DettachToIncident(kb)"
														>
														<i class="fa-solid fa-thumbtack"></i>
														{{"UI.k_pinned" | translate}}
														</button>
													</span>
												</div>
												<div
													class="fw-500 title-colormb-25"
													*ngIf="kb?.title?.length > 30"
													ngbTooltip="{{ kb.title | slice: 0:100 }}"
													placement="top"
													container = "body"
												>
													{{ kb.title.slice(0, 60) }} ...
												</div>
												<div
													class="fw-500 body-colormb-25"
													*ngIf="kb.title.length <= 30"
												>
													{{ kb.title }}
												</div>
												<div
													class="mb-25"
													*ngIf="kb?.content?.length > 200"
													tooltip="kb?.content"
													ngbTooltip="{{ kb?.content | striphtml | slice: 0:250 }}"
													placement="top"
													container="body"
													[innerHtml]="kb?.content?.slice(0, 200) + '...'"
												></div>
												<div
													class="mb-25"
													*ngIf="kb?.content?.length <= 70"
													[innerHtml]="kb?.content"
												></div>
												<div
													class="d-flex align-items-center mb-75"
												>
													<div class="flex-wrap kb-user mr-50 pr-50">
														<div class="avatar avatar-sm mr-50">
															<img
																*ngIf="kb.avatar"
																src="{{kb.avatar}}"
																alt="Avatar"
																width="16"
																height="16"
															/>
															<div
																*ngIf="!kb.avatar"
																class="avatar-content"
																[ngStyle]="{
																	'background-color': kb.avatar_color
																}"
															>
																{{ kb.created_by | initials | slice: O:2 }}
															</div>
														</div>
														<span class="pt-25">{{ kb.created_by }}</span>
													</div>
													<div>
														<span
															><i
																class="fa-light fa-star"
																style="color: #ff9f43; padding-top: 0.35rem"
															></i></span
														>&nbsp;
														<span class="pt-25">{{ kb.rating }}</span>
													</div>
												</div>
												<div class="">
													{{ "UI.k_last_updated_on" | translate
													}} {{ kb.last_update_time | date:'d MMM, y'}}
												</div>
											</div>
										</ng-container>
										</div>
									</div>
								</ng-container>
							</ng-container> 
							<div class="suggested-kb">
								<!-- <div class="title-color sm-font py-1">
									{{ "UI.k_suggested_kb" | translate | uppercase }} ({{
										suggested_kb.length
									}})
								</div> -->
								<div class="search-kb-sec d-flex justify-content-between align-items-center py-1">
									<div class="search-sec">
										<div class="input-group input-group-merge">
											<div class="input-group-prepend">
												<span class="input-group-text">
													<i class="fa-light fa-magnifying-glass text-muted"></i>
												</span>
											</div>
									  <input type="text" class="form-control" id="config-search" placeholder="{{ 'UI.k_search_kb' | translate }}"
										aria-label="Search..." aria-describedby="config-search" [(ngModel)]="suggestion_filter" (keyup)="searchKB($event)" />
									</div>
									</div>
									<div class="category-sec">
										<ng-select
										[items]="KbCategories"
										bindLabel="name"
										bindValue="id"
										name="selKbCategories"
										id="selKbCategories"
										[(ngModel)]="category_filter"
										(change)="searchKBCategory(category_filter)"
										class="form-control"
										placeholder="{{ 'UI.k_categories' | translate }}"
									>
									</ng-select>
									</div>
								</div>
								<div *ngIf="suggested_kb?.length == 0">
									<div
										class="d-flex align-items-center justify-content-center pt-3"
									>
										<div
											class="avatar bg-secondary rounded-lg"
											id="BtnincIcons"
										>
											<div
												class="avatar-content"
												style="width: 28px; height: 28px"
											>
												<span><i class="fa-light fa-book-open"></i></span>
											</div>
										</div>
									</div>
									<div class="d-flex align-items-center justify-content-center">
										<small class="ml-75 text-muted py-auto"
											>{{ "UI.k_no_suggested_kb" | translate }}
											</small
										>
									</div>
								</div>
								<div  *ngIf="suggested_kb?.length !== 0" class="suggested-kb-list">
									<ng-container
									*ngFor="let kb of suggested_kb; let i = index"
									class=""
								>
									<div class="card p-1 border single-kb shadow-none">
										<div
											class="d-flex flex-wrap justify-content-between mb-50 align-items-center"
										>
											<div class="text-primary fw-500">
												#{{ kb.display_id }}
											</div>
											<span class="d-flex align-items-center">
												<div
													class="cursor-pointer mr-25 body-color"
													id="btnRedirectToKB"
													routerLink="/kb/view/{{ kb.kb_id }}"
													ngbTooltip="{{ 'UI.k_view' | translate }}"
													target="_blank"
												>
													<i class="fa-light fa-circle-info"></i>
												</div>
												<button class="btn btn-sm btn-flat-primary" type="button" id="btnAttachToIncident"
												(click)="AttachToIncident(kb)">
												<i class="fa-solid fa-thumbtack"></i>
												{{"UI.k_pin" | translate}}
												</button>
											</span>
										</div>
										<div
											class="fw-500 title-colormb-25"
											*ngIf="kb.title?.length > 30"
											ngbTooltip="{{ kb.title | slice: 0:200}}"
											placement="top"
										>
											{{ kb.title.slice(0, 60) }} ...
										</div>
										<div
											class="fw-500 body-colormb-25"
											*ngIf="kb.title?.length <= 30"
										>
											{{ kb.title }}
										</div>
										<div
											class="mb-25"
											*ngIf="kb?.content?.length > 200"
											ngbTooltip="{{ kb.content | striphtml | slice: 0:150}}"
											placement="top"
											[innerHtml]="kb.content?.slice(0, 100) + '...'"
										></div>
										<div
											class="mb-25"
											*ngIf="kb.content?.length <= 70"
											[innerHtml]="kb.content"
										></div>
										<div
											class="d-flex align-items-center mb-75"
										>
											<div class="flex-wrap kb-user mr-50 pr-50">
												<div class="avatar avatar-sm mr-50">
													<img
														*ngIf="kb.avatar"
														src="{{kb.avatar}}"
														alt="Avatar"
														width="16"
														height="16"
													/>
													<div
														*ngIf="!kb.avatar"
														class="avatar-content"
														[ngStyle]="{
															'background-color': kb.avatar_color
														}"
													>
														{{ kb.created_by | initials | slice: O:2 }}
													</div>
												</div>
												<span class="pt-25">{{ kb.created_by }}</span>
											</div>
											<div>
												<span
													><i
														class="fa-light fa-star"
														style="color: #ff9f43; padding-top: 0.35rem"
													></i></span
												>&nbsp;
												<span class="pt-25">{{ kb.rating }}</span>
											</div>
										</div>
										<div class="">
											{{ "UI.k_last_updated_on" | translate
											}} {{ kb.last_update_time | date:'d MMM, y'}}
										</div>
									</div>
								</ng-container>
								</div>
							</div>
						</div>
					</ng-scrollbar>
					<div class="card-pagination" *ngIf= "suggested_kb?.length > 0" >
						<div class="pagination-inner">
							<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl-0 pr-0">
								<dns-pagination
									[totalCount]="cardCount"
									[currentCount]="kbCount"
									(setPage)="paginate($event)"
									(setLimit)="limit($event)"
									[page]="page"
									[pageSize]="size"
								></dns-pagination>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

