import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { GetStartedService } from "app/base/get-started/services/get-started.service";
import { map } from "rxjs";
import store from "store";
import { GlobalConstants } from "app/app.globalConstants";
import { PermissionService } from "app/common/permission/service";
import { DOCUMENT } from "@angular/common";
@Component({
	selector: "app-getting-started-sidebar",
	templateUrl: "./getting-started-sidebar.component.html",
	styleUrls: ["./getting-started-sidebar.component.scss"],
})
export class GettingStartedSidebarComponent implements OnInit {
	public totalGettingStep: any = [];
	public completedStep: any = [];
	public rem_days: any;
	public additionalConfiguration: any[];
	public showAccessUrl: boolean = true;
	public is_mail_config: boolean = true;
	public showRole: boolean = true;
	public showItAssetAgents: boolean = false;
	public showInviteAgent: boolean = true;
	public showBranch: boolean = true;
	public showBrandStyling: boolean = true;
	public showBrand: boolean = true;
	agent_permissions: any = {};
	showRemainDays: boolean = true;
	permission: any;
	public stepCountMap = {
		access_url: 0,
		support_email: 0,
		role: 0,
		invite_agent: 0,
		it_asset_agents: 0,
		branch: 0,
		branding: 0,
	};
	public stepCountWithoutConfigMap = {
		access_url: 0,
		support_email: 1,
		role: 2,
		invite_agent: 3,
		it_asset_agents: 4,
		branch: 5,
		branding: 6,
	};
	rebrand_obj = {
		id: "rebrand_infraon",
		name: "UI.k_rebrand_infraon",
		handle: "/brand-styling",
		colors: "#49d5c5",
		icon: "fa-cog",
		description: "UI.k_rebrand_infraon_descr",
		permissionModule: "organization",
		permissionKey: "view",
		license: {
			module_key: "BASE",
			keys: ["INFRAON_REBRAND"],
		},
	}
	public stepName = ["access_url", "support_email", "role", "invite_agent", "it_asset_agents", "branch", "branding"];
	// assetConfiguration: any[];
	// ITSMConfiguration: any[];
	assetConfiguration = [
		{
			id: "add_requesters",
			configName: "UI.k_add_req_customer",
			configDescription: "UI.k_add_req_customer_desc",
			configIcon: "fa-user-tie",
			iconColor: "#E35CC0",
			configRoute: "/requester",
			permissionModule: "requester",
			permissionKey: "view",
		},
		{
			id: "teams",
			configName: "UI.k_teams",
			configDescription: "UI.k_teams_desc",
			configIcon: "fa-users",
			iconColor: "#EA5455",
			configRoute: "/teams",
			permissionModule: "teams",
			permissionKey: "view",
			license: {
				module_key: "BASE",
				keys: ["TEAMS_CONFIG"],
			},
		},
		{
			id: "business_hours",
			configName: "UI.k_business_hr",
			configDescription: "UI.k_business_hr_desc",
			configIcon: "fa-stopwatch",
			iconColor: "#eaa11b",
			configRoute: "/business_hours",
			permissionModule: "biz_hour",
			permissionKey: "view",
			license: {
				module_key: "BASE",
				keys: ["BUSINESS_HOUR"],
			},
		},
		{
			id: "sla_profile",
			configName: "UI.k_sla_profile",
			configDescription: "Message.msg_sla_profile_descr",
			configIcon: "fa-timer",
			iconColor: "#EA5455",
			configRoute: "/sla/profile",
			permissionModule: "",
			permissionKey: "view",
			license: {
				module_key: "BASE",
				keys: ["SLA"],
			},
		},
		{
			id: "customize_asset",
			configName: "UI.k_customize_asset_id",
			configDescription: "UI.k_customize_asset_id_desc",
			configIcon: "fa-scanner-touchscreen",
			iconColor: "#FF9F43",
			configRoute: "/setting",
			permissionModule: "",
			permissionKey: "view",
			license: {
				module_key: "ASSET",
				keys: ["ASSET_MANAGEMENT"],
			},
		},

		{
			id: "tag",
			configName: "UI.k_tag_management",
			configDescription: "UI.k_tag_management_desc",
			configIcon: "fa-tag",
			iconColor: "#f74a4a",
			configRoute: "/tags",
			permissionModule: "tag",
			permissionKey: "view",
			license: {
				module_key: "BASE",
				keys: ["TAG_MANAGEMENT"],
			},
		},

		{
			id: "black_white_list",
			configName: "UI.k_black_white_list",
			configDescription: "UI.k_black_white_list_desc",
			configIcon: "fa-user-xmark",
			iconColor: "#eaa11b",
			configRoute: "/blacklist_whitelist",
			permissionModule: "cmdb",
			permissionKey: "view",
			license: {
				module_key: "ASSET",
				keys: ["ASSET_MANAGEMENT"],
			},
		},
		{
			id: "email-template",
			configName: "UI.k_mail_template",
			configDescription: "UI.k_mail_template_desc",
			configIcon: "fa-envelope-open-text",
			iconColor: "#49D5C5",
			configRoute: "/email-template",
			permissionModule: "",
			permissionKey: "view",
			license: {
				module_key: "ASSET",
				keys: ["ASSET_MANAGEMENT"],
			},
		},
		{
			id: "servicecatalogue",
			configName: "UI.k_servicecatalogue",
			configDescription: "UI.k_servicecatalogue_desc",
			configIcon: "fa-file-lines",
			iconColor: "#49d5f5",
			configRoute: "/service-catalogue",
			permissionModule: "servicecatalogue",
			permissionKey: "view",
			license: {
				module_key: "SERVICEDESK",
				keys: ["SERVICE_CATALOGUE"],
			},
		},
		{
			id: "vendor",
			configName: "UI.k_vendor",
			configDescription: "UI.k_vendor_desc",
			configIcon: "fa-user",
			iconColor: "#49d5c5",
			configRoute: "/vendor",
			permissionModule: "vendor",
			permissionKey: "view",
			license: {
				module_key: "ASSET",
				keys: ["ASSET_SOFTWARE"],
			},
		},
		{
			id: "workflow",
			configName: "UI.k_workflow",
			configDescription: "UI.k_workflow_desc",
			configIcon: "fa-code-branch",
			iconColor: "#737FD3",
			configRoute: "/workflow",
			permissionModule: "workflow",
			permissionKey: "view",
			license: {
				module_key: "BASE",
				keys: ["WORKFLOW"],
			},
		},
	];
	ITSMConfiguration = [
		{
			id: "requester",
			configName: "UI.k_add_req_customer",
			configDescription: "UI.k_add_req_customer_desc",
			configIcon: "fa-user-tie",
			iconColor: "#E35CC0",
			configRoute: "/requester",
			permissionModule: "requester",
			permissionKey: "view",
		},
		{
			id: "teams",
			configName: "UI.k_teams",
			configDescription: "UI.k_teams_desc",
			configIcon: "fa-users",
			iconColor: "#EA5455",
			configRoute: "/teams",
			permissionModule: "teams",
			permissionKey: "view",
			license: {
				module_key: "BASE",
				keys: ["TEAMS_CONFIG"],
			},
		},
		{
			id: "department",
			configName: "UI.k_department",
			configDescription: "Message.msg_create_department",
			configIcon: "fa-user-group",
			iconColor: "#e45dc1",
			configRoute: "/department",
			permissionModule: "department",
			permissionKey: "view",
			license: {
				module_key: "BASE",
				keys: ["DEPARTMENT_SUPPORT"],
			},
		},
		{
			id: "servicecatalogue",
			configName: "UI.k_servicecatalogue",
			configDescription: "UI.k_servicecatalogue_desc",
			configIcon: "fa-file-lines",
			iconColor: "#49d5f5",
			configRoute: "/service-catalogue",
			permissionModule: "servicecatalogue",
			permissionKey: "view",
			license: {
				module_key: "SERVICEDESK",
				keys: ["SERVICE_CATALOGUE"],
			},
		},
		{
			id: "business_hours",
			configName: "UI.k_business_hr",
			configDescription: "UI.k_business_hr_desc",
			configIcon: "fa-stopwatch",
			iconColor: "#eaa11b",
			configRoute: "/business_hours",
			permissionModule: "biz_hour",
			permissionKey: "view",
			license: {
				module_key: "BASE",
				keys: ["BUSINESS_HOUR"],
			},
		},
		{
			id: "workflow",
			configName: "UI.k_workflow",
			configDescription: "UI.k_workflow_desc",
			configIcon: "fa-code-branch",
			iconColor: "#737FD3",
			configRoute: "/workflow",
			permissionModule: "workflow",
			permissionKey: "view",
			license: {
				module_key: "BASE",
				keys: ["WORKFLOW"],
			},
		},
		{
			id: "sla_metric",
			configName: "UI.k_sla_metric",
			configDescription: "UI.k_sla_desc",
			configIcon: "fa-timer",
			iconColor: "#49d5c5",
			configRoute: "/sla/metric",
			permissionModule: "",
			permissionKey: "view",
			license: {
				module_key: "BASE",
				keys: ["SLA"],
			},
		},
		{
			id: "sla_profile",
			configName: "UI.k_sla_profile",
			configDescription: "Message.msg_sla_profile_descr",
			configIcon: "fa-timer",
			iconColor: "#EA5455",
			configRoute: "/sla/profile",
			permissionModule: "",
			permissionKey: "view",
			license: {
				module_key: "BASE",
				keys: ["SLA"],
			},
		},
		{
			id: "customize_asset_proces",
			configName: "UI.k_customize_asset_proces_id",
			configDescription: "UI.k_customize_asset_process_id_desc",
			configIcon: "fa-scanner-touchscreen",
			iconColor: "#FF9F43",
			configRoute: "/setting",
			permissionModule: "",
			permissionKey: "view",
			license: {
				module_key: "ASSET",
				keys: ["ASSET_MANAGEMENT"],
			},
		},
		{
			id: "tag",
			configName: "UI.k_tag_management",
			configDescription: "UI.k_tag_management_desc",
			configIcon: "fa-tag",
			iconColor: "#f74a4a",
			configRoute: "/tags",
			permissionModule: "tag",
			permissionKey: "view",
			license: {
				module_key: "BASE",
				keys: ["TAG_MANAGEMENT"],
			},
		},
	];
	helpDeskConfiguration = [
		{
			id: "requester",
			configName: "UI.k_add_req_customer",
			configDescription: "UI.k_add_req_customer_desc",
			configIcon: "fa-user-tie",
			iconColor: "#E35CC0",
			configRoute: "/requester",
			permissionModule: "requester",
			permissionKey: "view",
		},
		{
			id: "servicecatalogue",
			configName: "UI.k_servicecatalogue",
			configDescription: "UI.k_servicecatalogue_desc",
			configIcon: "fa-file-lines",
			iconColor: "#49d5f5",
			configRoute: "/service-catalogue",
			permissionModule: "servicecatalogue",
			permissionKey: "view",
			license: {
				module_key: "SERVICEDESK",
				keys: ["SERVICE_CATALOGUE"],
			},
		},
		{
			id: "sla_metric",
			configName: "UI.k_sla_metric",
			configDescription: "UI.k_sla_desc",
			configIcon: "fa-timer",
			iconColor: "#49d5c5",
			configRoute: "/sla/metric",
			permissionModule: "",
			permissionKey: "view",
			license: {
				module_key: "BASE",
				keys: ["SLA"],
			},
		},
		{
			id: "sla_profile",
			configName: "UI.k_sla_profile",
			configDescription: "Message.msg_sla_profile_descr",
			configIcon: "fa-timer",
			iconColor: "#EA5455",
			configRoute: "/sla/profile",
			permissionModule: "",
			permissionKey: "view",
			license: {
				module_key: "BASE",
				keys: ["SLA"],
			},
		},
		{
			id: "business_hours",
			configName: "UI.k_business_hr",
			configDescription: "UI.k_business_hr_desc",
			configIcon: "fa-stopwatch",
			iconColor: "#eaa11b",
			configRoute: "/business_hours",
			permissionModule: "biz_hour",
			permissionKey: "view",
			license: {
				module_key: "BASE",
				keys: ["BUSINESS_HOUR"],
			},
		},
	];
	constructor(
		@Inject(DOCUMENT) private document: Document,
		private _coreSidebarService: CoreSidebarService,
		private _getStartedService: GetStartedService,
		private _router: Router,
		private _permissionService: PermissionService
	) {}

	ngOnInit(): void {
		let product = store.get("product");
		let showBanner = store.get("show_banner");
		this.showRemainDays = showBanner;
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		this.agent_permissions = user_permissions["agent"];
		if (this.agent_permissions?.view) {
			if (
				this._permissionService.hasValidLav("AGENT", ["AGENT"]) ||
				this._permissionService.hasValidLav("AGENT", ["DESKTOP_AGENT"])
			) {
				this.showItAssetAgents = true;
			}
		}
		let permissions = user_permissions["userroles"];
		this.showRole = permissions?.view;
		permissions = user_permissions["organization_location"];
		this.permission = user_permissions["organization"];
		this.showBranch = permissions?.view;
		if (this.showBranch == false) {
			this.stepCountMap["branding"] = this.stepCountMap["branding"] - 1;
		}
		this.is_mail_config = this._permissionService.hasValidLav("BASE", ["MAIL_CONFIG"]);
		if (!this.is_mail_config) {
			this.stepCountMap = this.stepCountWithoutConfigMap;
		}
		if (product === "Infraon-Assets") {
			this.additionalConfiguration = this.assetConfiguration;
		} else if (product === "Infraon-ITSM") {
			this.additionalConfiguration = this.ITSMConfiguration;
		} else if (product === "Infraon-Helpdesk") {
			this.additionalConfiguration = this.helpDeskConfiguration;
		} else {
			this.additionalConfiguration = null;
		}

		let count = 1;
		this.stepName.forEach((element) => {
			if (element == "access_url" && this.showAccessUrl) {
				this.stepCountMap[element] = count;
				count += 1;
			}

			if (element == "support_email" && this.is_mail_config) {
				this.stepCountMap[element] = count;
				count += 1;
			}

			if (element == "role" && this.showRole) {
				this.stepCountMap[element] = count;
				count += 1;
			}
			if (element == "invite_agent" && this.showInviteAgent) {
				this.stepCountMap[element] = count;
				count += 1;
			}
			if (element == "it_asset_agents" && this.showItAssetAgents) {
				this.stepCountMap[element] = count;
				count += 1;
			}
			if (element == "branch" && this.showBranch) {
				this.stepCountMap[element] = count;
				count += 1;
			}
			if (element == "branding" && this.showBrandStyling) {
				this.stepCountMap[element] = count;
				count += 1;
			}
		});
		this.rem_days = store.get("remaining_days");
		this.getGettingStartedProgress();
		this._getStartedService.updateStepProgress.subscribe((isChange: boolean) => {
			if (isChange) {
				this.getGettingStartedProgress();
			}
		});
	}
	toggleSidebar = (name: string) => {
		this.document.body.classList.remove("getting-started-modal");
		this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
	};
	getGettingStartedProgress = () => {
		this._getStartedService
			.getConfigurationProgress()
			.pipe(
				map((response: any) => {
					//console.log("steps Details", response);
					let totalGetStep = [];
					for (let i = 0; i < response.total_steps; i++) {
						totalGetStep.push(i);
					}
					this.totalGettingStep = totalGetStep;
					this.completedStep = response.completed_step_list;
				})
			)
			.subscribe();
	};
	getRoute = (route) => {
		this.document.body.classList.remove("getting-started-modal");
		this._coreSidebarService.getSidebarRegistry("getting-stared").toggleOpen();
		this._router.navigate([route]);
	};
	goSelectedStep = (step) => {
		this._getStartedService.selectedStep.next(step);
		this.document.body.classList.remove("getting-started-modal");
		this._coreSidebarService.getSidebarRegistry("getting-stared").toggleOpen();
		this._router.navigate(["/get-started"]);
	};
}
