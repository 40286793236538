import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
declare var $;

@Component({
	selector: "app-dynamic-dropdown",
	templateUrl: "./dynamic-dropdown.component.html",
	styleUrls: ["./dynamic-dropdown.component.scss"],
})
export class DynamicDropdownComponent implements OnInit {
	@Input() dynamicDropDown: any;
	dropdownData = [];
	selectedValue = "";
	@ViewChild("dummyClick") dummyClick: ElementRef;
	@Output() emittedFromDynamicDropdown: EventEmitter<any> = new EventEmitter();

	constructor() {}

	ngOnInit() {
		if (this.dynamicDropDown && this.dynamicDropDown.value) {
			this.dropdownData = this.dynamicDropDown.value[0].data;
		}
	}

	focusEvent(e, element) {
		this.dropdownData = this.dynamicDropDown.value.filter((id) => id.id === e)[0].data;
		element.open();
	}

	statusChanges(e, item, callback: (e, item) => any): void {
		// let list = item.data.filter(id => id.label.indexOf(e) > -1)
		if (item.id === "first_dropdown" && item.ng_model_value) {
			this.dynamicDropDown.value.forEach((element) => {
				if (element.id === "second_dropdown") {
					element.allow = true;
					element.ng_model_value = null;
				}
			});
		}
		if (item.id === "first_dropdown" && !item.ng_model_value) {
			this.dynamicDropDown.value.forEach((element) => {
				if (element.id === "second_dropdown") {
					element.allow = false;
					element.ng_model_value = null;
				}
				if (element.id === "third_dropdown") {
					element.allow = false;
					element.ng_model_value = null;
				}
			});
		}
		if (item.id === "second_dropdown" && item.ng_model_value) {
			this.dynamicDropDown.value.forEach((element) => {
				if (element.id === "third_dropdown") {
					element.allow = true;
					element.ng_model_value = null;
				}
			});
		}
		if (item.id === "second_dropdown" && !item.ng_model_value) {
			this.dynamicDropDown.value.forEach((element) => {
				if (element.id === "third_dropdown") {
					element.allow = false;
					element.ng_model_value = null;
				}
			});
		}
		// if (e && e.target && e.target?.value) {
		//   callback(e.target.value, item);
		// }
		if (e) {
			//console.log("selectedValue", e);
		}
		this.emittedFromDynamicDropdown.emit({ event: "multipleSelect", data: this.dynamicDropDown });
	}

	printLogs(e) {
		if (e) {
			//console.log("Selected DropDown Value = ", e);
		}
	}
}
