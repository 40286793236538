import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { repeaterAnimation } from "../../animations/animations";
import { NgForm } from "@angular/forms";

@Component({
	selector: "dns-value-selector",
	templateUrl: "./dns-value-selector.component.html",
	styleUrls: ["./dns-value-selector.component.scss"],
	animations: [repeaterAnimation],
})
export class DnsValueSelectorComponent implements OnInit, AfterViewInit, OnChanges {
	@Input() options: any;
	@Output("output") resultData = new EventEmitter<any>();
	@Input() macros: any;
	@Input("data") result: Array<any>;
	@Input("dataForm") dataForm: NgForm;
	@Input("is_required") isRequired = false;
	conditionsOptions: any = {};
	rowIndex = 0;
	is_duplicate = false;

	constructor() {
		this.macros = [];
		this.conditionsOptions = {
			rowConditions0: {
				options: [],
			},
		};
		this.result = [{}];
	}

	ngOnInit(): void {}

	ngAfterViewInit() {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes) {
			this.prepareOperandOptions();
		}
	}

	public validateSetValueData = (data: Array<any>) => {
		let is_valid = true;
		for (const values of data) {
			if (!(values?.operand && values?.value)) {
				is_valid = false;
				break;
			}
		}
		return is_valid;
	};

	onChange = (args: any, index: any) => {
		if (args["type"] == "list") {
			if (
				!this.conditionsOptions["rowConditions" + index] ||
				this.conditionsOptions["rowConditions" + index] === "undefined"
			) {
				this.conditionsOptions["rowConditions" + index] = {};
			}
			this.conditionsOptions["rowConditions" + index].options = args.options || [];
			this.conditionsOptions["rowConditions" + index].bindKey = args.bindKey;
			this.conditionsOptions["rowConditions" + index].bindValue = args.bindValue;
			this.result[index].value = null;
		} else {
			const bindValue = this.conditionsOptions["rowConditions" + index].bindValue;
			if (bindValue) {
				this.result[index].value = args[bindValue];
			} else {
				this.result[index].value = args;
			}
		}
		if (args?.name) {
			this.result[index]["name"] = args?.name || "";
		}
		this.resultData.emit(this.result);
	};

	addRemove = (i: number) => {
		if (i == 0) {
			this.result.push({});
			this.rowIndex++;
			this.conditionsOptions["rowConditions" + this.rowIndex] = [];
		} else {
			this.result.splice(i, 1);
			this.rowIndex--;
		}
	};

	public isDuplicate = () => {
		let tempValueArray = [];
		let index = 0;
		for (const resultElement of this.result) {
			const tempVal = JSON.stringify(resultElement);
			if (!tempValueArray.includes(tempVal)) {
				tempValueArray.push(tempVal);
				this.is_duplicate = false;
				this.conditionsOptions["rowConditions" + index].is_duplicate = false;
			} else {
				this.is_duplicate = true;
				this.conditionsOptions["rowConditions" + index].is_duplicate = true;
				break;
			}
			index++;
		}
		return this.is_duplicate;
	};

	setCondition(data) {}

	prepareOperandOptions = () => {
		if (this.result && this.result?.length > 0 && this.options?.length > 0) {
			const options_macro_map = Object.assign({}, ...this.options.map((x) => ({ [x.macro]: x })));
			for (let i = 0; i < this.result.length; i++) {
				const option_data = options_macro_map[this.result[i]["operand"]];
				if (!this.conditionsOptions["rowConditions" + i]) {
					this.conditionsOptions["rowConditions" + i] = {};
				}
				this.conditionsOptions["rowConditions" + i].options = option_data?.options || [];
				this.conditionsOptions["rowConditions" + i].bindKey = option_data?.bindKey || "name";
				this.conditionsOptions["rowConditions" + i].bindValue = option_data?.bindValue || "id";
			}
		}
	};
}
