import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";

@Component({
	selector: "dns-pagination",
	templateUrl: "./dns-pagination.component.html",
	styleUrls: ["./dns-pagination.component.scss"],
})
export class DnsPaginationComponent implements OnInit {
	@Output() setLimit = new EventEmitter<any>();
	@Output() setPage = new EventEmitter<any>();
	@Input() totalCount;
	@Input() currentCount;
	@Input() page = 1;
	@Input() pageSize: any = 10;
	@Input() maxPageLimit:number = 0;
	@Input() isAll: boolean = false;
	@Input() dataLoading = undefined;
	public pageLimit: any = 10;

	constructor() {}

	ngOnInit(): void {
		if (this.isAll) {
			this.pageLimit = "all";
		} else {
			this.pageLimit = this.pageSize;
		}
	}

	onPageSizeChange = (data) => {
		if (data.target.value === "all") {
			this.pageSize = this.totalCount;
		} else {
			this.pageSize = data?.target?.value;
		}
		this.setLimit.emit(this.pageLimit);
	};

	onPageChange = (data) => {
		this.setPage.emit(data);
	};


	getMaxKString = (total, limit) => {
		if (total <= limit){
			return total
		}else{
			return (limit/1000).toString() + 'K+'
		}
	}
}
