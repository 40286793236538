import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { RequestViewService } from "app/servicedesk/request/services/request-view.service";
import { map, catchError } from "rxjs/operators";

@Component({
  selector: 'app-dns-request-incident-view',
  templateUrl: './dns-request-incident-view.component.html',
  styleUrls: ['./dns-request-incident-view.component.scss']
})
export class DnsRequestIncidentViewComponent implements OnInit {

	@Output() onHideConvReqToInciView = new EventEmitter(false);
  @Input() public incident_data: any = {};
  @Input() public incident_id : any = "";
  dataLoaded : boolean = false
  	location_name = ""

	constructor(
		private _requestViewService: RequestViewService,
	) { }


	ngOnInit(): void {
		if (!this.incident_data.hasOwnProperty("display_id")) {
			this._requestViewService
			.getIncident(this.incident_id,{'return_main_info':true})
			.pipe(
				map(
					(response) => {
						this.incident_data = response?.incident;
						this.dataLoaded = true						
					},
					(error) => {
						//todo
					}
					)
		)
		.subscribe();
		}
		else{
			this.dataLoaded = true
			this.incident_data = this.incident_data
		}

		this.location_name = this?.incident_data?.requester?.location?.location_name ||
				this?.incident_data?.requester?.location?.city ||
				this?.incident_data?.requester?.location?.state ||
				this?.incident_data?.requester?.location?.country;
	}

	closeSidebar = (name): void => {
		this.onHideConvReqToInciView.emit(true);
	};

}
