<!-- Vertical Menu -->
<ng-container *ngIf="menuType === 'vertical-menu'">
	<vertical-menu></vertical-menu>
</ng-container>
<!--/ Vertical Menu -->

<!-- Horizontal Menu -->
<ng-container *ngIf="menuType === 'horizontal-menu'">
	<horizontal-menu></horizontal-menu>
</ng-container>
<!--/ Horizontal Menu -->
<ng-container *ngIf="menuType === 'admin-menu'">
	<app-admin-menu class="menus"></app-admin-menu>
</ng-container>

<ng-container *ngIf="menuType === 'support-menu'">
	<app-support-menu class="menus" (hideShowDetails)="onHideGetInTouch()" [isSupportAdmin]="true"></app-support-menu>
</ng-container>

<ng-container *ngIf="menuType === 'msp-menu'">
	<app-msp-menu class="menus"></app-msp-menu>
</ng-container>
