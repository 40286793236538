import { AdminCoreMenu } from "@core/types";

export const adminMenu: AdminCoreMenu[] = [
	{
		id: "admin",
		title: "UI.k_home",
		type: "item",
		icon: "home ",
		url: "admin/dashboard",
		position: "sub",
		permission: {
			moduleList: ["subscription"],
			keyList: ["view"],
		},
	},
	{
		id: "invoices",
		title: "UI.k_billing_invoices",
		type: "item",
		icon: "file-invoice",
		url: "admin/invoices",
		position: "sub",
		permission: {
			moduleList: ["subscription"],
			keyList: ["view"],
		},
	},
	// {
	// 	id: "adminUsers",
	// 	title: "UI.k_users",
	// 	type: "item",
	// 	icon: "ticket",
	// 	url: "admin/users",
	//	position: 'sub',
	// 	permission: {
	// 		moduleList: ["subscription"],
	// 		keyList: ["view"],
	// 	},
	// },
	{
		id: "adminHistory",
		title: "UI.k_audits",
		type: "item",
		icon: "ticket",
		url: "admin/audits",
		position: "sub",
		permission: {
			moduleList: ["subscription"],
			keyList: ["view"],
		},
	},
];
