<div class="full-height flex-1">
	<div class="table-container custom-material-table full-height flex-auto-height">
		<div class="flex-1 flex-auto-height">
			<ng-scrollbar
				class="custom-scrollbar section-scrollbar"
				[disabled]="'false'"
				[visibility]="'always'"
				ishorizontallyscrollable="false"
				track="all"
			>
				<div
					class="text-card position-relative border rounded-lg card-bg p-0 mt-25 card-{{
						element.state_name
					}} state-{{ element.state_id }}"
					*ngFor="let element of data; let i = index"
				>
					<!-- <ng-container *ngFor="let column of confDisplayedColumns"> -->
					<div
						class="taxt-card-main"
						id="div__{{ element.display_id }}-{{ i }}"
						attr.data-testid="div__{{ element.display_id }}"
					>
						<div class="text-card-left border-bottom">
							<div class="requester-detail d-flex">
								<div class="request-left-top py-50 pl-50 customwidthdiv">
									<div class="d-flex align-items-center pb-1">
										<div class="card-checkbox d-flex align-items-center">
											<h6 class="cursor-pointer title-color mb-0 fw-600">
												<span
													class="mb-0 primary-color sm-font fw-500 mr-50 cursor-pointer"
													(click)="buildOnCallBack(element, 'comment', 'comment', false)"
													id="sp__{{ element.display_id }}-{{ i }}"
													attr.data-testid="sp__{{ element.display_id }}"
													>{{ element.display_id }}</span
												>
											</h6>
											<div class="summary-config-icons">
												<ng-container
													class="ml-50"
													*ngFor="let option of panelCardConfig['title_icons']?.options"
												>
													<ng-container
														*ngIf="
															option.key != processMoudleName + '_source_id' &&
															option.key != 'callback'
														"
														class="rounded icon-class"
													>
														<div
															class="summary-card-config"
															id="link__{{ option.key }}-{{ i }}"
															*ngIf="
																option.key == 'root_alarm' ? element?.['is_parent_'+processMoudleName] : true
															"
															placement="top"
															container="body"
															ngbTooltip="{{ option.option_name | translate }}"
															attr.data-testid="link__{{ option.key }}"
														>
															<i class="fa-light fa-{{ option.icon }} text-primary"></i>
														</div>
													</ng-container>
													<ng-container
														*ngIf="option.key == processMoudleName + '_source_id'"
														class="rounded icon-class"
													>
														<div
															class="summary-card-config"
															id="div__{{ option.key }}-{{ i }}"
															placement="bottom"
															ngbTooltip="{{ element?.source_name }}"
															container="body"
															attr.data-testid="div__{{ option.key }}"
														>
															<i
																class="fa-light fa-{{
																	options?.sourceIconMap[element[option.key]]
																}} text-primary"
															></i>
														</div>
													</ng-container>
													<ng-container
														*ngIf="option.key == 'callback' && element[option.key]"
														class="rounded icon-class"
													>
														<div
															class="summary-card-config"
															placement="bottom"
															ngbTooltip="{{ option.option_name | translate }}"
															container="body"
															id="div__{{ option.key }}-{{ i }}"
															attr.data-testid="div__{{ option.key }}"
														>
															<i
																class="fa-light fa-{{
																	options?.callbackIconMap[element[option.key]]
																}} text-primary"
															></i>
														</div>
													</ng-container>
												</ng-container>
												<ng-container
													*ngFor="
														let summaryOption of panelCardConfig['sub_heading']
															.options
													"
												>
													<ng-container *ngIf="element[summaryOption.key]">
														<div
															class="summary-card-config"
															[ngbPopover]="popContent"
															[autoClose]="'outside'"
															popoverClass="summary-profile-popover"
															container="body"
														>
															<i class="fa-light fa-desktop text-primary"></i>
															<ng-template #popContent>
																<ng-container
																	*ngFor="
																		let option of panelCardConfig[
																			'sub_heading'
																		].options
																	"
																>
																	<div class="d-flex">
																		<span
																			class="title-color fw-500"
																			*ngIf="element[option.key]"
																			>{{ option.option_name | translate }} :
																			&nbsp;
																		</span>
																		<div *ngIf="option.key != 'asset_id'">
																			<span
																				class="cursor-pointer"
																				title="{{ element[option.key] }}"
																			>
																				{{
																					element[option.key]
																						| slice: 0:19
																				}}{{
																					element[option.key]?.length > 19
																						? ".."
																						: ""
																				}}</span
																			>
																		</div>
																		<div
																			*ngIf="
																				option.key == 'asset_id' &&
																				element.cmdb_id
																			"
																		>
																			<span
																				*ngIf="!element?.is_asset_disabled"
																				class="text-primary cursor-pointer"
																				title="{{ element[option.key] }}"
																				(click)="info_action(element)"
																			>
																				{{
																					element[option.key]
																						| slice: 0:19
																				}}{{
																					element[option.key]?.length > 19
																						? ".."
																						: ""
																				}}</span
																			>
																			<span
																				*ngIf="element?.is_asset_disabled"
																				class=""
																				title="{{ element[option.key] }}"
																			>
																				{{
																					element[option.key]
																						| slice: 0:19
																				}}{{
																					element[option.key]?.length > 19
																						? ".."
																						: ""
																				}}</span
																			>
																		</div>
																	</div>
																</ng-container>
															</ng-template>
														</div>
													</ng-container>
												</ng-container>
											</div>
											
										</div>
									</div>
									<div class="action-button d-flex align-items-center">
										<ng-container *ngFor="let action of panelCardConfig?.summary_actions">
											<button
												placement="right"
												ngbTooltip="{{ action.option_name | translate }}"
												type="button"
												class="btn btn-icon btn-outline-secondary mr-50"
												(click)="action.callback(element)"
												rippleEffect
												id="btn__{{ action.key }}-{{ i }}"
												attr.data-testid="btn__{{ action.key }}"
											>
												<span><i class="fa-light fa-{{ action.icon }} text-primary"></i></span>
											</button>
										</ng-container>
									</div>
								</div>
								<div class="request-left-right p-50 w-100">
									<div class="title-header">
										<div class="d-flex justify-content-between align-items-center">
											<h6
												placement="bottom"
												ngbTooltip="{{ element?.summary | slice: 0:180 }}"
												container="body"
												class="pb-50 mb-0 fw-600"
												id="headSummary-{{ i }}"
												[attr.data-testid]="'headSummary'"
											>
												{{ element?.summary | slice: 0:140 }}
												{{ element?.summary.length > 140 ? "..." : " " }}
											</h6>
											<span
												class="text-muted font-small-2"
												id="spanCtime-{{ i }}"
												[attr.data-testid]="'spanCtime'"
												>{{ element?.creation_time }}</span
											>
										</div>
									</div>
									<div class="description" *ngIf="processMoudleName == 'problem'">
										<span
											class="text"
											placement="bottom"
											container="body"
											ngbTooltip="{{ onDisplaykey(element?.fault_symptoms | slice: 0:200) }}"
											[innerHTML]="onDisplaykey(element?.fault_symptoms)"
											dnsReadMore
											[readMore-length]="200"
											class="description text"
											id="spanDesc-{{ i }}"
											[attr.data-testid]="'spanDesc'"
										>
										</span>
									</div>
									<div class="description" *ngIf="processMoudleName == 'change'">
										<span
											class="text"
											placement="bottom"
											container="body"
											ngbTooltip="{{ onDisplaykey(element?.planning | slice: 0:200) }}"
											[innerHTML]="onDisplaykey(element?.planning)"
											dnsReadMore
											[readMore-length]="200"
											class="description text"
											id="spanDesc-{{ i }}"
											[attr.data-testid]="'spanDesc'"
										>
										</span>
									</div>
									<div class="description" *ngIf="processMoudleName == 'request'">
										<span
											class="text"
											placement="bottom"
											container="body"
											ngbTooltip="{{ onDisplaykey(element?.description | slice: 0:200) }}"
											[innerHTML]="onDisplaykey(element?.description)"
											dnsReadMore
											[readMore-length]="200"
											class="description text"
											id="spanDesc-{{ i }}"
											[attr.data-testid]="'spanDesc'"
										>
										</span>
									</div>
								</div>
							</div>
						</div>
						<div class="text-card-right">
							<div class="right-bottom-left">
								<div class="request-data">
									<div class="customwidthdiv p-50">
										<label for="selRequester">{{ "UI.k_requester" | translate }}</label>
										<div class="text-break">
											<!-- <div class="avatar bg-warning mr-50">
                                            <div class="avatar-content">
                                                <img width="32" alt="datatable-avatar" class="rounded-circle" src="assets/images/asset/image.png">
                                            </div>
                                        </div> -->
											<!-- <h6 class="mb-0 fw-400"> Requester </h6>
                                        <h5 class="mb-0 sm-font">{{element.requester_name}}</h5> -->
											<span class="d-flex align-items-center justify-content-start">
												<div class="avatar avatar-sm mr-75 ml-0">
													<div
														*ngIf="!element?.requester_profile"
														class="avatar-content"
														[ngStyle]="{
															'background-color': element?.requester_avatar_color
																? element?.requester_avatar_color
																: defaultAvatarColor
														}"
													>
														{{ element?.requester?.requester_name | initials | slice: 0:2 }}
													</div>
													<span class="avatar-content" *ngIf="element?.requester_profile">
														<img [src]="element?.requester_profile" alt="avatar" />
													</span>
												</div>
												<div
													class="cell-line-height"
													id="divReqName-{{ i }}"
													[attr.data-testid]="'divReqName'"
												>
													<p class="font-medium-1 fw-500 line-height-1 mb-0">
														{{ element?.requester?.requester_name }}
													</p>
													<!-- <span class="text-muted sm-font">
														{{ element?.requester?.requester_email }}</span
													> -->
												</div>
											</span>
										</div>
									</div>
									<div
										class="assignee element border-right px-50 h-100 p-50"
										style="min-width: 200px"
										*ngIf="showAssigneeForProcess.includes(processPrimaryKey)"
									>
										<!-- <div class="d-flex align-items-center">
                                        <div class="avatar mr-50" style="background-color: #5093FF;">
                                            <div class="avatar-content">
                                                J
                                            </div>
                                        </div>
                                        <div class="text-break">
                                            <h5 class="mb-0 sm-font">Jackey Potter</h5>
                                        </div>
                                    </div> -->
										<div class="form-group mb-0">
											<div class="d-flex justify-content-between align-items-center">
												<label class="" for="selAssignee">{{ "UI.k_team" | translate }}</label>
												<button
													*ngIf="
														!element?.assignment_info?.assignee_id &&
														!element?.is_exit &&
														element?.state_id != 6
													"
													name="btnSelfAssign"
													class="btn btn-sm text-primary cursor-pointer"
													[disabled]="panelCardConfig?.disableTakeIt"
													(click)="buildOnCallBack(element, 'self_assign', 'self_assign')"
													id="btnSelfAssign-{{ i }}"
													[attr.data-testid]="'btnSelfAssign'"
												>
													{{ "UI.k_take_it" | translate }}
												</button>
											</div>
											<ng-select
												[items]="options?.teams_and_self_assign"
												[multiple]="false"
												[closeOnSelect]="true"
												[(ngModel)]="element.assignment_info.team_id"
												[searchable]="true"
												[disabled]="
													(element?.is_exit && element?.state_id == 5) ||
													!permissions?.edit ||
													[6, 7].includes(element?.state_id) ||
													element?.basic_info?.status?.is_pir
												"
												bindLabel="name"
												bindValue="team_id"
												name="selAssignee-{{ i }}"
												id="selAssignee-{{ i }}"
												class=""
												[clearable]="false"
												placeholder="{{ 'UI.k_select' | translate }} {{
													'UI.k_assignee' | translate
												}}"
												[attr.data-testid]="'selAssignee'"
											>
												<ng-template
													ng-option-tmp
													let-item="item"
													let-index="index"
													let-search="searchTerm"
												>
													<span
														class="media"
														*ngIf="item.team_id != 'self_assign'"
														(click)="buildOnCallBack(element, 'team_change', item.team_id)"
														id="assignTeam{{ item.team_id }}"
														attr.data-testid="assignTeam{{ item.team_id }}"
													>
														<div
															class="avatar avatar-sm mr-1 ml-0"
															[ngClass]="bg - light - primary"
														>
															<div
																class="avatar-content"
																[ngStyle]="{
																	'background-color': stringToColor(item.name)
																}"
															>
																{{ item.name | initials | slice: 0:2 }}
															</div>
														</div>
														<div class="cell-line-height">
															<span
																class="font-medium-1 fw-500 line-height-1 mb-0"
																container="body"
																[ngbTooltip]="item.name"
															>
																{{ item.name | slice: 0:25 }}
																{{ item.name.length > 25 ? ".." : "" }}
															</span>
															<span class="text-muted sm-font">
																{{ item.group_type | slice: 0:25
																}}{{ item.group_type.length > 25 ? ".." : "" }}</span
															>
														</div>
													</span>
													<div
														class="cursor-pointer"
														*ngIf="item.team_id == 'self_assign'"
														(click)="buildOnCallBack(element, 'team_change', item.team_id)"
														id="selfAssignTeam{{ item.team_id }}"
														attr.data-testid="selfAssignTeam{{ item.team_id }}"
													>
														<div class="media">
															<div class="avatar avatar-sm mr-1">
																<div
																	class="avatar-content"
																	[ngStyle]="{
																		'background-color': stringToColor(
																			currentUser.first_name +
																				'' +
																				currentUser.last_name
																		)
																	}"
																>
																	{{
																		this.currentUser.first_name +
																			"" +
																			this.currentUser.last_name
																			| initials
																			| slice: 0:2
																	}}
																</div>
															</div>
															<div class="media-body">
																<h5 class="mb-0">
																	{{ "UI.k_self_assign" | translate }}
																</h5>
																<small class="text-muted">{{
																	this.currentUser.email
																}}</small>
															</div>
														</div>
													</div>
												</ng-template>
											</ng-select>
										</div>
									</div>
									<div
										class="assignee element border-right px-50 h-100 p-50"
										style="min-width: 200px"
										*ngIf="showChangeMangerForProcess.includes(processPrimaryKey)"
									>
										<div class="form-group mb-0">
											<div class="d-flex justify-content-between align-items-center">
												<label class="" for="selManager">{{
													"UI.k_change_manager" | translate
												}}</label>
											</div>
											<ng-select
												[items]="options?.users"
												[multiple]="false"
												[closeOnSelect]="true"
												[(ngModel)]="element.change_manager"
												[searchable]="true"
												[disabled]="
													(element?.is_exit && element?.state_id == 5) ||
													!permissions?.edit ||
													[6, 7].includes(element?.state_id) ||
													element?.basic_info?.status?.is_pir
												"
												(change)="
													buildOnCallBack(
														element,
														'change_manager',
														element?.change_manager,
														true
													)
												"
												name="selManager{{ i }}"
												id="selManager{{ i }}"
												class=""
												bindLabel="full_name"
												[clearable]="false"
												placeholder="{{ 'UI.k_select' | translate }} {{
													'UI.k_change_manager' | translate
												}}"
												[attr.data-testid]="'selManager'"
											>
												<ng-template ng-label-tmp let-item="item">
													<span class="d-flex pl-1">
														<div class="avatar avatar-sm mr-1 ml-0">
															<span
																class="avatar-content"
																*ngIf="!item.profile_image"
																[ngStyle]="{
																	'background-color': item.avatar_color
																		? item.avatar_color
																		: '#f59518'
																}"
															>
																{{ item.full_name | initials }}
															</span>
															<span class="avatar-content" *ngIf="item?.profile_image">
																<img
																	class="rounded-circle"
																	src="{{ item?.profile_image }}"
																	alt="User"
																/>
															</span>
														</div>
														<div class="cell-line-height">
															<p
																class="font-medium-1 font-weight-bold line-height-1 mb-25"
																container="body"
																[ngbTooltip]="item.full_name"
															>
																{{ item?.full_name | slice: 0:25 }}
																{{ item?.full_name?.length > 25 ? ".." : "" }}
															</p>
														</div>
													</span>
												</ng-template>
												<ng-template
													ng-option-tmp
													let-item="item"
													let-index="index"
													let-search="searchTerm"
												>
													<span class="d-flex">
														<div class="avatar avatar-sm mr-1 ml-0">
															<span
																class="avatar-content"
																*ngIf="!item.profile_image"
																[ngStyle]="{
																	'background-color': item.avatar_color
																		? item.avatar_color
																		: '#f59518'
																}"
															>
																{{ item.full_name | initials }}
															</span>
															<span class="avatar-content" *ngIf="item?.profile_image">
																<img
																	class="rounded-circle"
																	src="{{ item?.profile_image }}"
																	alt="User"
																/>
															</span>
														</div>
														<div class="cell-line-height">
															<p
																class="font-medium-1 font-weight-bold line-height-1 mb-25"
																container="body"
																[ngbTooltip]="item.full_name"
															>
																{{ item?.full_name | slice: 0:25 }}
																{{ item?.full_name?.length > 25 ? ".." : "" }}
															</p>
															<span class="text-muted font-small-2">
																{{ item?.email | slice: 0:28 }}
																{{ item?.email?.length > 28 ? ".." : "" }}
															</span>
														</div>
													</span>
												</ng-template>
											</ng-select>
										</div>
									</div>
									<div class="status-main element border-right px-50 h-100 p-50">
										<div class="d-flex justify-content-between align-items-center">
											<ng-container>
												<div class="w-100">
													<app-status-picker
														[inputConfig]="{
															isTextMode: true,
															hideLabel: true,
															isEditDisabled:
																(element?.is_exit && element?.state_id == 5) ||
																!permissions?.edit ||
																[6, 7].includes(element?.state_id) ||
																element?.basic_info?.status?.is_pir,
															stateOptionsMap: options?.state_id_map,
															id: i
														}"
														[options]="element?.dropdown_options?.transition_status"
														[dropdownPosition]="'top'"
														[value]="element?.status?.id"
														(setStatus)="statusEvent($event, 'status', element)"
													></app-status-picker>
												</div>
											</ng-container>
										</div>
									</div>
									<div class="element border-right px-50 h-100 p-50">
										<label class="form-label" for="UI.k_priority">{{
											"UI.k_priority" | translate
										}}</label>
										<div class="priority-sec" style="height: 2.25rem">
											<div class="current-priority">
												<app-select-badge
													[inputConfig]="{
														hideLabel: true,
														inputOptions: options?.priority,
														id: 'selPriority',
														model: element?.priority_id,
														isEditDisabled:
															(element?.is_exit && element?.state_id == 5) ||
															!permissions?.edit ||
															[6, 7].includes(element?.state_id) ||
															element?.basic_info?.status?.is_pir,
														clearable: false
													}"
													(onValueChange)="statusBadgeChange($event, 'priority_id', element)"
												></app-select-badge>
											</div>
										</div>
									</div>
									<div class="category element border-right px-50 h-100 p-50">
										<!-- <div class="d-block"> -->
										<label class="form-label" for="UI.k_service">{{
											"UI.k_service" | translate
										}}</label>
										<!-- <h6 class="mb-0 fw-400">{{ "UI.k_service" | translate }}</h6> -->
										<ng-container
											*ngIf="
												element['dropdown_options']['formatted_services_config']?.is_editable &&
												permissions?.edit
											"
										>
											<div
												class="input-tree-dropdown"
												style="height: 2.25rem"
												placement="bottom"
												ngbTooltip="{{ element?.impact_service_tree_path }}"
												type="button"
											>
												<app-single-tree-dropdown
													class="w-100"
													[items]="element['dropdown_options']['formatted_services']"
													[value]="element['impact_service']"
													[id]="'panel-view-service' + element[processPrimaryKey]"
													(itemChange)="
														this.serviceValueChange($event, 'impact_service', element)
													"
												>
												</app-single-tree-dropdown>
											</div>
										</ng-container>
										<div
											*ngIf="
												!element['dropdown_options']['formatted_services_config']
													?.is_editable || !permissions?.edit
											"
											(click)="onImpactServiceClick(element, {}, $event)"
										>
											<div id="treeView{{ 'impact_service' }}_{{ i }}">
												<label
													class="form-check-label text-truncate pt-75"
													[class.pointer-event]="![1, 2].includes(element?.state_id)"
													placement="bottom"
													ngbTooltip="{{ element?.impact_service_tree_path }}"
												>
													{{
														(element?.impact_service_tree_path | slice: 0:35) ||
															("UI.k_select_option" | translate)
													}}{{ element?.impact_service_tree_path?.length > 35 ? ".." : "" }}
													<span
														*ngIf="
															element['dropdown_options']['formatted_services_config']
																?.is_loading
														"
														class="fa fa-spinner fa-spin fa-2x"
													></span
												></label>
											</div>
										</div>
										<!-- <h5 class="mb-0 sm-font">{{ element?.impact_service_tree_path }}</h5> -->
										<!-- </div> -->
									</div>
									<div
										class="due-in element border-right d-flex align-items-center px-50 h-100 p-50"
										*ngIf="showChangeMangerForProcess.includes(processPrimaryKey)"
									>
										<div class="d-block">
											<h6 class="mb-1 fw-400">Due In</h6>
											<h5
												class="mb-0 sm-font"
												style="height: 2.25rem"
												id="head__duedate-{{ i }}"
												[attr.data-testid]="'head__duedate'"
											>
												{{ element?.due_date }}
											</h5>
										</div>
									</div>
									<div
										class="complete element border-right d-flex align-items-center px-50 h-100 p-50"
										*ngIf="!element?.is_exit && checkControlledActions(element)"
									>
										<ng-container *ngFor="let action of actionList">
											<ng-container
												*ngIf="action?.controlled_action == true && action?.type == 'button'"
											>
												<button
													type="button"
													*ngIf="action?.enable(element, action?.key)"
													(click)="action?.callback(element)"
													class="btn {{ action.class }} btn-sm mx-auto"
													rippleEffect
													id="btn__{{ action.key }}-{{ i }}"
													attr.data-testid="btn__{{ action.key }}"
												>
													{{ action["btn_text"] | translate }}
												</button>
											</ng-container>
										</ng-container>
										<!-- <button type="button" id="complete" name="complete" class="btn btn-primary mx-auto" rippleEffect>Complete</button> -->
									</div>
									<div class="action-button d-flex align-items-center px-50 h-100 p-50">
										<ng-container *ngFor="let action of actionList">
											<ng-container
												*ngIf="
													(action?.controlled_action
														? action?.enable(element, action?.key)
														: true) &&
													action?.type == 'icon' &&
													permissions[
														action?.permissionKey ? action.permissionKey : action.key
													]
												"
											>
												<button
													placement="bottom"
													ngbTooltip="{{ action.tooltip | translate }}"
													type="button"
													class="btn btn-icon btn-outline-tertiary mr-50 {{ action?.class }}"
													(click)="action?.callback(element)"
													rippleEffect
													id="btn__{{ action.key }}-{{ i }}"
													attr.data-testid="btn__{{ action.key }}"
												>
													<span
														><i
															class="fa-light {{ action?.icon_name }} {{ action?.class }}"
														></i
													></span>
												</button>
											</ng-container>
										</ng-container>
										<!-- <div ngbDropdown>
											<button ngbDropdownToggle id="dropdownMenuButton" type="button" class="btn btn-icon btn-outline-primary mr-50 text-primary" rippleEffect>
								
												<span><i class="fa-light fa-ellipsis-vertical"></i></span>
											</button>
	
											<div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
											  <a ngbDropdownItem href="javascript:void(0)">Option 1</a>
											  <a ngbDropdownItem href="javascript:void(0)">Option 2</a>
											  <a ngbDropdownItem href="javascript:void(0)">Option 3</a>
											</div>
										  </div>     -->
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- </ng-container> -->
				</div>
			</ng-scrollbar>
		</div>
		<div class="card-pagination" *ngIf="rowCount > 0">
			<div class="pagination-inner">
				<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl-0 pr-0">
					<dns-pagination
						[totalCount]="rowCount"
						[currentCount]="currentCount"
						(setPage)="paginate($event)"
						(setLimit)="setLimitGrid($event)"
						[page]="page"
						[pageSize]="size"
					></dns-pagination>
				</div>
			</div>
		</div>
	</div>
	<ng-template #appovalChangeComp let-modal>
		<app-approval-config
			*ngIf="process_data"
			[process_data]="process_data"
			[options]="options"
			[approval_status]="temp_status"
			[module_id]="module_id"
			(onApprovalConfigClose)="onApprovalConfigClose($event)"
			(onApprovalConfigSubmit)="onApprovalConfigSubmit($event)"
		>
		</app-approval-config>
	</ng-template>
</div>
