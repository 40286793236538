import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class RingCentralService {
	_window?: any;
	_ringCentral: any;

	constructor(@Inject(DOCUMENT) private document: Document) {
		this._window = window;
	}

	public getRingCentralInstance(): any {
		return this._ringCentral;
	}

	public logout(): any {
		this._ringCentral
			.logout()
			.then(() => console.log("Logout sucess"))
			.catch(() => console.log("catch block ring central"));
		this._ringCentral = null;
		return null;
	}

	initRingCentral(clientId: string, clientSecret: string, serverURL: string): void {
		// 'infraoncorp-test'
		this._ringCentral = new this._window["RingCentral"]["SDK"]({
			server: serverURL,
			clientId: clientId,
			clientSecret: clientSecret,
			redirectUri: "", // optional, but is required for Implicit Grant and Authorization Code OAuth Flows (see below),
			wsReconnection: false
		});
	}
}
