<ul class="tree tree-view" *ngIf="treeConfig && treeConfig.length">
	<ng-container *ngFor="let item of treeConfig; let i = index">
		<form #treeCrudForm="ngForm">
			<li id="{{ item.label }}">
				<input type="checkbox" id="{{ item.id }}" class="my-75" [checked]="true" />
				<label
					class="tree-label"
					[ngClass]="{ tree_label: item?.children && item.children.length }"
					for="{{ item.id }}"
					[hidden]="item.isEditable"
					><span>{{ item.label }}</span>
					<div class="icon-list-hover">
						<i
							class="fa-light fa-pen-to-square text-primary"
							ngbTooltip="{{ 'UI.k_edit' | translate }}"
							*ngIf="permissions?.edit"
							placement="left"
							tooltipClass="custom-class"
							(click)="editRootNode(item)"
						></i>
						<i
							class="fa-light fa-square-plus"
							ngbTooltip="{{ 'UI.k_add' | translate }}"
							*ngIf="permissions?.add"
							placement="left"
							tooltipClass="custom-class"
							(click)="addChildNode(item)"
						></i>
						<i
							class="fa-light fa-trash-can text-danger"
							ngbTooltip="{{ 'UI.k_delete' | translate }}"
							*ngIf="permissions?.delete"
							placement="left"
							tooltipClass="custom-class"
							(click)="deleteMainEvent(item)"
						></i>
					</div>
				</label>
				<div class="edit-input-sec" *ngIf="item.isEditable">
					<button
						type="button"
						class="btn btn-icon btn-flat-secondary"
						rippleEffect
						id="btnClsWidget"
						name="btnClsWidget"
						(click)="cancelEditNode(item, treeCrudForm)"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
					<div class="form-group my-75">
						<input
							type="text"
							class="form-control"
							[(ngModel)]="item.label"
							[appWhiteSpaceCheck]="item.label"
							treeDuplicateLabelValidator
							[treeConfig]="treeConfig"
							[currentItem]="item"
							[currentIndex]="i"
							#TDLabel="ngModel"
							required
							maxlength="40"
							id="cusLabel{{ i }}"
							name="cusLabel{{ i }}"
							[class.error]="TDLabel.invalid && treeCrudForm.submitted"
						/>
						<span *ngIf="treeCrudForm.submitted && TDLabel.invalid" class="invalid-form">
							<small class="form-text text-danger" *ngIf="TDLabel.errors?.required">{{
								"Error.err_invalid_classification_name" | translate
							}}</small>
							<small class="form-text text-danger" *ngIf="TDLabel.errors.maxLength">{{
								"Error.err_max_classification_name" | translate
							}}</small>
							<small class="form-text text-danger" *ngIf="TDLabel.errors?.whitespace">{{
								"UI.k_leading_trailing_space_validation" | translate
							}}</small>
							<small class="form-text text-danger" *ngIf="TDLabel.errors?.duplicateLabel">{{
								"UI.k_duplicate_classification_name" | translate
							}}</small>
						</span>
					</div>
					<button
						type="button"
						class="btn btn-flat-primary btn-sm"
						id="btnSave"
						name="btnSave"
						(click)="saveEditNodeEvent(item, treeCrudForm)"
					>
						{{ "UI.k_save" | translate }}
					</button>
				</div>
				<app-tree-child
					[childData]="item"
					*ngIf="item?.children && item?.children.length > 0"
					(selectEvents)="emittedSelectEvents($event)"
					[is_edit]="is_edit"
					[treeConfig]="treeConfig"
					[depth]="depth"
					[permissions]="permissions"
					[rootIndex]="i"
					(isInputEnable)="onInputAddEdit($event)"
					class="child-tree"
				></app-tree-child>
			</li>
			<li *ngIf="item.addNode" class="d-flex" [ngClass]="{ 'pl-0 input-li': item.addNode }">
				<div class="edit-input-sec">
					<button
						type="button"
						class="btn btn-icon btn-flat-secondary"
						rippleEffect
						id="btnClsWidget"
						name="btnClsWidget"
						(click)="cancelCloseNode(item, treeCrudForm)"
					>
						<i class="fa-light fa-xmark"></i>
					</button>
					<div class="form-group">
						<input
							type="text"
							id="cusLabel{{ i }}"
							name="cusLabel{{ i }}"
							class="form-control"
							maxlength="40"
							placeholder="{{ 'UI.k_new_classification_placeholder' | translate }}"
							[appWhiteSpaceCheck]="newNodeData"
							treeDuplicateLabelValidator
							[treeConfig]="treeConfig"
							[currentIndex]="i"
							[(ngModel)]="newNodeData"
							#TDAddLabel="ngModel"
							required
							[class.error]="TDAddLabel.invalid && treeCrudForm.submitted"
						/>
						<span *ngIf="treeCrudForm.submitted && TDAddLabel.invalid" class="invalid-form">
							<small class="form-text text-danger" *ngIf="TDAddLabel.errors?.required">{{
								"Error.err_invalid_classification_name" | translate
							}}</small>
							<small class="form-text text-danger" *ngIf="TDAddLabel.errors.maxLength">{{
								"Error.err_max_classification_name" | translate
							}}</small>
							<small class="form-text text-danger" *ngIf="TDAddLabel.errors?.whitespace">{{
								"UI.k_leading_trailing_space_validation" | translate
							}}</small>
							<small class="form-text text-danger" *ngIf="TDAddLabel.errors?.duplicateLabel">{{
								"UI.k_duplicate_classification_name" | translate
							}}</small>
						</span>
					</div>

					<button
						type="button"
						class="btn btn-flat-primary btn-sm"
						id="btnSave"
						name="btnSave"
						(click)="saveNode(item, treeCrudForm)"
					>
						{{ "UI.k_save" | translate }}
					</button>
				</div>
			</li>
		</form>
	</ng-container>
</ul>

<form #rootForm="ngForm">
	<div>
		<div class="d-flex justify-content-start mx-1">
			<button
				type="button"
				class="btn btn-sm btn-outline-primary waves-effect"
				rippleEffect
				id="btnAddClassification"
				*ngIf="permissions?.add"
				name="btnAddClassification"
				(click)="addClassification()"
				[hidden]="this.treeDataConfig.addFirstNode === true"
			>
				<i class="fa-light fa-plus avatar-icon"></i>{{ "UI.k_add_classification" | translate }}
			</button>
		</div>

		<div class="edit-input px-50" *ngIf="this.treeDataConfig.addFirstNode">
			<button
				type="button"
				class="btn btn-icon btn-flat-secondary mr-50"
				rippleEffect
				id="btnFirstCancle"
				name="btnFirstCancle"
				(click)="cancelFirstNodeEntry(rootForm)"
			>
				<i class="fa-light fa-xmark"></i>
			</button>
			<div class="form-group my-75 mr-50">
				<input
					type="text"
					id="rootlabel"
					name="rootlabel"
					class="form-control"
					maxlength="40"
					[appWhiteSpaceCheck]="treeDataConfig.inputData"
					treeDuplicateLabelValidator
					[treeConfig]="treeConfig"
					[currentIndex]="-1"
					[(ngModel)]="treeDataConfig.inputData"
					#TDInputLabel="ngModel"
					required
					[class.error]="TDInputLabel.invalid && rootForm.submitted"
					placeholder="{{ 'UI.k_new_classification_placeholder' | translate }}"
				/>
				<span *ngIf="rootForm.submitted && TDInputLabel.invalid" class="invalid-form">
					<small class="form-text text-danger" *ngIf="TDInputLabel.errors?.required">{{
						"Error.err_invalid_classification_name" | translate
					}}</small>
					<small class="form-text text-danger" *ngIf="TDInputLabel.errors.maxLength">{{
						"Error.err_max_classification_name" | translate
					}}</small>
					<small class="form-text text-danger" *ngIf="TDInputLabel.errors?.whitespace">{{
						"UI.k_leading_trailing_space_validation" | translate
					}}</small>
					<small class="form-text text-danger" *ngIf="TDInputLabel.errors?.duplicateLabel">{{
						"UI.k_duplicate_classification_name" | translate
					}}</small>
				</span>
			</div>
			<button
				type="button"
				class="btn btn-flat-primary btn-sm mr-50"
				id="btnFirstSave"
				name="btnFirstSave"
				(click)="addFirstNode(rootForm)"
			>
				{{ "UI.k_save" | translate }}
			</button>
		</div>
	</div>
</form>
