<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pl-0 pr-0 ngx-datatable">
	<div class="m-0 datatable-footer border-0" style="padding: 0.5rem">
		<div class="d-flex justify-content-between align-items-center">
			<label class="d-flex align-items-center mb-0">
				{{
					"UI.k_showing_pagination"
						| translate
							: {
									currentCount: (page - 1) * pageSize + 1,
									totalCount: !maxPageLimit ? totalCount : getMaxKString(totalCount, maxPageLimit),
									maxLimit: page * pageSize >= totalCount ? totalCount : page * pageSize
							  }
				}}
				<span *ngIf="dataLoading == true" class="spinner-border spinner-border-sm ml-25"></span>
			</label>
			<div>
				<datatable-pager
					[page]="page"
					pagerPreviousIcon="datatable-icon-prev"
					pagerLeftArrowIcon="datatable-icon-left"
					pagerNextIcon="datatable-icon-skip"
					pagerRightArrowIcon="datatable-icon-right"
					[size]="pageSize"
					[count]="totalCount"
					class="datatable-pager"
					[hidden]="false"
					(change)="onPageChange($event)"
				>
				</datatable-pager>
			</div>
			<div class="d-flex align-items-center mr-50">
				<label class="d-flex align-items-center mb-0"
					>{{ "UI.k_show" | translate }}
					<select
						id="selPagOpt"
						class="form-control form-control-sm mx-25"
						(change)="onPageSizeChange($event)"
						[(ngModel)]="pageLimit"
					>
						<option id="opt10" name="opt10" value="10">10</option>
						<option id="opt25" name="opt25" value="25">25</option>
						<option id="opt50" name="opt50" value="50">50</option>
						<option id="opt100" name="opt100" value="100">100</option>
						<option id="opt200" name="opt200" value="200">200</option>
						<!-- <option id="optShowAll" name="optShowAll" [value]="'all'">
							{{ "UI.k_all" | translate }}
						</option> -->
					</select>
					{{ "UI.k_entries" | translate }}
				</label>
			</div>
		</div>
	</div>
</div>
