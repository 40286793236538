import { Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { getAssetImageSrc } from "app/common/utils/utils";

@Component({
	selector: "app-grid-name-design",
	templateUrl: "./grid-name-design.component.html",
	styleUrls: ["./grid-name-design.component.scss"],
})
export class GridNameDesignComponent implements OnInit {
	@Output("callback") callback = new EventEmitter<any>();
	data: any;
	public rowindex: any;
	public attachments = [];
	key: any;
	subkey: any;
	url: any;
	id: any;
	getImageSrc = getAssetImageSrc;
	public defaultAvatarColor = "#f59518";
	constructor() {
		this.data = {};
		this.key = {};
		this.url = "";
		this.id = "";
	}
	ngOnInit(): void {}
	onclick(data, extra_data = {}) {
		if (data === "add") {
			this.callback.emit({ action: "addstock", ...extra_data });
		} else if (data === "remove") {
			this.callback.emit({ action: "removestock", ...extra_data });
		} else if (data === "viewSideBar") {
			this.callback.emit({ action: "viewSideBar", ...extra_data });
		} else if (data === "viewimageCarusal") {
			this.callback.emit({ action: "viewimageCarusal", ...extra_data });
		} else if (data === "showfeedbackDetails") {
			this.callback.emit(extra_data);
		} else if(data === "showTicketDetails") {
			this.callback.emit(extra_data);
		}
	}

	protected readonly Object = Object;
	openDetailSidebar(data) {
		this.callback.emit(data);
	}
	openAddAssignSidebar(data) {
		this.callback.emit();
	}
}
