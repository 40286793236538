import { Component, OnInit, Input, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { ColumnMode, DatatableComponent, SelectionType } from "@swimlane/ngx-datatable";
import { TranslateService } from "@ngx-translate/core";
import { InfinityModules } from "app/common/dns/types/modules";
import { GlobalConstants } from "app/app.globalConstants";
import { InstanceIdGroup, Invoice, Instance, Customer, Card } from "../../models";
import { AdminService } from "../../service";

@Component({
	selector: "app-dashboard-instance-invoices",
	templateUrl: "./dashboard-instance-invoices.component.html",
	styleUrls: ["./dashboard-instance-invoices.component.scss"],
})
export class DashboardInstanceInvoicesComponent implements OnInit {
	customerInfo: Customer;
	cardInfo: Card;
	@Input() instanceInfo: Instance;
	private _instanceData: InstanceIdGroup;
	@Input() set instanceData(value: InstanceIdGroup) {
		this._instanceData = value;
		if (value?.data) {
			for (let d of value.data) {
				this.customerInfo = d.customer;
				if (this.customerInfo) break;
				this.cardInfo = d.card;
				if (this.cardInfo) break;
			}
		}
	}
	get instanceData(): InstanceIdGroup {
		return this._instanceData;
	}
	invoiceDataRaw: any;
	invoiceData: Invoice[];
	columns: Array<any>;
	public ColumnMode = ColumnMode;
	// public ethernetServicesData: any;
	public basicSelectedOption: number = 10;
	module = InfinityModules.CMDB;
	@Input() options: any = {};
	pageNumber: any = 1;
	sortKey: any = "display_id";
	reverse: any = "";
	limit: any = 10;
	totalCount: any;
	permissions: any = {};
	SelectionType = SelectionType;

	constructor(
		private _translateService: TranslateService,
		private _service: AdminService,
		@Inject(DOCUMENT) private document: Document
	) {}

	ngOnInit(): void {
		let user_permissions = {};
		if (GlobalConstants.dnsPermissions != undefined) {
			user_permissions = JSON.parse(GlobalConstants.dnsPermissions);
		}
		// this.permissions = user_permissions[this.module];
		this.permissions = { id: true };

		// TODO - need to deal the customer id option
		this._service.getInvoices({ limit: 100 }).subscribe({
			next: (response: { result: Invoice[]; offset: any }) => {
				this.invoiceDataRaw = response;
				// console.log("....invoices", response);
				if (response.result) {
					this.invoiceData = response.result;
					this.totalCount = response.result.length;
				}
			},
			error: (e: any) => {
				console.error(e);
			},
		});

		this.columns = [
			{
				label: this._translateService.instant("UI.k_date"),
				key: "date",
			},
			{
				label: this._translateService.instant("UI.k_status"),
				key: "status",
				width: 60,
			},
			{
				label: this._translateService.instant("UI.k_recurring"),
				key: "recurring",
				width: 60,
			},
			{
				label: this._translateService.instant("UI.k_subscription_id"),
				key: "subscription_id",
				width: 150,
			},
			{
				label: this._translateService.instant("UI.k_amount"),
				key: "total",
				width: 150,
			},
			{
				label: this._translateService.instant("UI.k_action"),
				key: "action",
				actions: [
					{
						label: this._translateService.instant("UI.k_download"),
						icon: "fa-download",
						key: "id",
						class: "text-primary",
						listener: (raw: any) => {
							// console.log("......actions.raw", raw);
							this.downloadInvoice(raw.id);
						},
					},
				],
			},
		];
	}

	downloadInvoice(invoice_id: any) {
		// console.log("......download", invoice_id);
		this._service.downloadInvoice({ invoiceId: invoice_id }).subscribe({
			next: (response: any) => {
				// console.log(response);
				const link = this.document.createElement("a");
				link.setAttribute("target", "");
				link.setAttribute("rel", "noopener");
				link.setAttribute("href", response.download_url);
				link.setAttribute("download", `invoice${invoice_id}.pdf`);
				this.document.body.appendChild(link);
				link.click();
				link.remove();
			},
			error: (e: any) => {
				console.error(e);
			},
		});
	}

	editUser = (args: any): void => {};

	deleteUser = (row: any) => {};

	onSelectRow(data: any) {}

	setPage(pageInfo: any) {
		this.pageNumber = pageInfo.offset;
	}

	onSort(args: any): void {
		if (args.sorts) {
			this.pageNumber = 0;
			let sortKey = args.sorts[0].prop;
			this.sortKey = sortKey;
			if (args.sorts[0].dir == "desc") {
				this.reverse = "-";
			} else {
				this.reverse = "";
			}
		}
	}

	setLimit(args: any): void {
		this.pageNumber = 0;
		this.limit = args.target.value;
	}

	billingAddress(): string {
		let customer = this.customerInfo;
		if (customer && customer.billing_address)
			return `${customer?.billing_address?.first_name} ${customer?.billing_address?.last_name}, ${customer?.billing_address?.company}, ${customer?.billing_address?.country} ${customer?.billing_address?.phone} `;
		return "";
	}

	instanceCurrency(): string {
		return this.customerInfo?.preferred_currency_code || "USD";
	}

	cardMaskedNumber(): string {
		return `Ending with ${this.cardInfo?.iin || "xxxx"}`;
	}

	cardType(): string {
		return this.cardInfo?.card_type || "";
	}

	cardExpiry(): string {
		return `Expiry ${this.cardInfo?.expiry_month || "MM"}/${this.cardInfo?.expiry_year || "YYYY"}`;
	}

	instanceTotalPrice(): number {
		let sum = 0;
		let data = this.instanceData?.data || [];
		if (data) {
			for (let i = 0; i < data.length; i++) {
				if (data[i].subscription.mrr) sum += data[i].subscription.mrr;
				else
					data[i].subscription.subscription_items.forEach((x) => {
						sum += x.amount;
					});
			}
		}
		if (sum) sum = sum / 100;
		return sum;
	}

	instanceBillingUnit(): string {
		let unit = "";
		let data = this.instanceData?.data || [];
		for (let i = 0; i < data.length; i++) {
			unit = data[i].subscription.billing_period_unit;
			if (unit) {
				break;
			}
		}
		return unit;
	}
}
