<div class="full-body-height">
    <div class="align-items-center d-flex p-1 justify-content-between admin-user-page-header">
        <div>
    
            <div class="input-group search-form-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basicddon-search1"><i
                            class="fa-light fa-magnifying-glass"></i></span>
                </div>
                <input id="txtAvailableSectionsSearch" name="txtAvailableSectionsSearch" type="text" class="form-control"
                    placeholder="{{ 'UI.k_search' | translate }}" aria-label="Search..."
                    aria-describedby="basic-addon-search1" />
            </div>
        </div>
        <div class="pull-right align-items-center d-flex p-0 m-0">
            <fieldset>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Invite by email" aria-label="Amount" />
                    <div class="input-group-append" style="width: 175px;">
                        <ng-select [items]="['Finance admin','Option 2', 'Option 3']" bindLabel="name" bindValue="name"
                            class="form-control" name="bandwidthDownstreamPirType" id="bandwidthDownstreamPirType"
                            placeholder="{{ 'UI.k_select' | translate }}">
                        </ng-select>
                    </div>
                    <div class="input-group-append">
                        <button class="btn btn-primary" type="button" rippleEffect [attr.data-testid]="'btn_inviteUser'">{{"UI.k_invite" | translate}}</button>
                    </div>
                </div>
            </fieldset>
        </div>
    </div>
    <div class="p-1">
        <div class="table-view flex-height">
            <dns-server-grid [rows]="users" [columns]="columns" (page)="setPage($event)" [count]="totalCount"
                [columns]="columns" [isEditable]="true" (sort)="onSort($event)" (setlimit)="setLimit($event)"
                [limit]="limit" class="bootstrap core-bootstrap" scrollbarH="true" [permissions]="permissions"
                [selectionType]="SelectionType.checkbox" (onSelectCheckbox)="onSelectRow($event)" hideFooter="true">
            </dns-server-grid>
        </div>
    </div>
</div>