import {
	Component,
	EventEmitter,
	Inject,
	OnDestroy,
	OnInit,
	Output,
	Input,
	SimpleChanges,
} from "@angular/core";
import { CoreConfigService } from "@core/services/config.service";
import { CommonService } from "app/services/common.service";
import { DOCUMENT } from "@angular/common";

@Component({
	selector: "app-burger-menu",
	templateUrl: "./burger-menu.component.html",
	styleUrls: ["./burger-menu.component.scss"],
})
export class BurgerMenuComponent implements OnInit, OnDestroy {
	@Output() emitSidebarDetails: EventEmitter<any> = new EventEmitter<any>();
	@Output() categoryDetails: EventEmitter<any> = new EventEmitter<any>();
	@Input() submenu: boolean;
	@Input() mainArray: {};
	public type;
	public incidentOptionList;

	public selectedItem = 0;
	selectedSubItem = 0;
	selectedCategory: any;
	subCategoryInfo: any;

	//submenu: boolean;
	filterBarObject = {
		activeTab: "getting_started",
		isFilterBarVisiable: true,
		value: {
			title: "Filter",
			isDropdown: true,
			dropdownCount: 3,
			options: [],
		},
	};
	categoryObject = {
		activeTab: "getting_started",
		isCategoryList: true,
		iconList: [
			{
				iconName: "search",
				class: "fa fa-search custom-margin",
			},
			{
				iconName: "add",
				class: "fa fa-plus",
			},
		],
		value: {
			title: "CATEGORIES",
			isDropdown: true,
			dropdownCount: 5,
			options: [],
		},
	};
	customInputObject = {
		isCustomInputVisiable: true,
		radioList: [
			{
				id: "radio1",
				label: "Options 1",
				value: "val1",
				name: "radioBtn",
				class: "custom-control-input",
				checked: false,
			},
			{
				id: "radio2",
				label: "Options 2",
				value: "val2",
				name: "radioBtn",
				class: "custom-control-input",
				checked: false,
			},
			{
				id: "radio3",
				label: "Options 3",
				value: "val3",
				name: "radioBtn",
				class: "custom-control-input",
				checked: false,
			},
		],
		checkBoxList: [
			{
				id: "customCheck1",
				value: "val1",
				name: "customCheck1",
				class: "custom-control-input",
				label: "Checked",
				checked: false,
			},
			{
				id: "customCheck2",
				value: "val2",
				name: "customCheck2",
				class: "custom-control-input",
				label: "Unchecked",
				checked: false,
			},
		],
		inputTypeList: [
			{ id: "basicInput", name: "basicInput", ng_model_value: null },
		],
	};
	constructor(
		@Inject(DOCUMENT) private document: Document,
		private _commonService: CommonService,
		private _coreConfigService: CoreConfigService
	) {}

	ngOnInit(): void {
		const first_dropdown = [
			{ label: "filter1", id: "filter1" },
			{ label: "filter2", id: "filter2" },
			{ label: "filter3", id: "filter3" },
			{ label: "filter4", id: "filter4" },
		];

		const second_dropdown = [
			{ label: "filter1", id: "filter21" },
			{ label: "filter2", id: "filter22" },
			{ label: "filter3", id: "filter23" },
			{ label: "filter4", id: "filter24" },
		];
		const third_dropdown = [
			{ label: "filter1", id: "filter31" },
			{ label: "filter2", id: "filter32" },
			{ label: "filter3", id: "filter33" },
			{ label: "filter4", id: "filter34" },
		];
		const fourth_dropdown = [
			{ label: "filter1", id: "filter41" },
			{ label: "filter2", id: "filter42" },
			{ label: "filter3", id: "filter43" },
			{ label: "filter4", id: "filter44" },
		];
		const fifth_dropdown = [
			{ label: "filter1", id: "filter51" },
			{ label: "filter2", id: "filter52" },
			{ label: "filter3", id: "filter53" },
			{ label: "filter4", id: "filter54" },
		];
		this.filterBarObject.value.options.push({
			options: first_dropdown,
			ng_model_value: null,
			id: "firstDropdown",
		});
		this.filterBarObject.value.options.push({
			options: second_dropdown,
			ng_model_value: null,
			id: "secondDropdown",
		});
		this.filterBarObject.value.options.push({
			options: third_dropdown,
			ng_model_value: null,
			id: "thirdDropdown",
		});
		this.filterBarObject.value.options.push({
			options: fourth_dropdown,
			ng_model_value: null,
			id: "fourthDropdown",
		});
		this.filterBarObject.value.options.push({
			options: fifth_dropdown,
			ng_model_value: null,
			id: "fifthDropdown",
		});
		this.categoryObject.value.options.push({
			title: "Server",
			subcategory1: "Sub Category 1",
			subcategory2: "Sub Category 2",
			subcategory3: "Sub Category 3",
			ng_model_value: null,
			isCollapsible: true,
		});
		this.categoryObject.value.options.push({
			title: "Desktop",
			subcategory1: "Sub Category 1",
			subcategory2: "Sub Category 2",
			subcategory3: "Sub Category 3",
			ng_model_value: null,
			isCollapsible: true,
		});
		this.categoryObject.value.options.push({
			title: "Server",
			subcategory1: "Sub Category 1",
			subcategory2: "Sub Category 2",
			subcategory3: "Sub Category 3",
			ng_model_value: null,
			isCollapsible: true,
		});
		this.categoryObject.value.options.push({
			title: "Server",
			subcategory1: "Sub Category 1",
			subcategory2: "Sub Category 2",
			subcategory3: "Sub Category 3",
			ng_model_value: null,
			isCollapsible: false,
		});
		// this.mainArray.push({ id: 1, type: 'filterComponent', filterData: this.filterBarObject });
		// this.mainArray.push({ id: 2, type: 'categoryComponent', categoryData: this.categoryObject });
		// this.mainArray.push({ id: 3, type: 'customComponent', customData: this.customInputObject });
	}

	emittedFilterValues(event) {
		this.emitSidebarDetails.emit(event);
	}

	emittedCategoryValues(event) {
		this.emitSidebarDetails.emit(event);
	}

	emittedCustomInputData(event) {
		if (event) {
			event.checked = !event?.checked;
			this.emitSidebarDetails.emit(event);
		}
	}

	//   submenuToggaleClick(): void {
	//     this.submenu = !this.submenu;
	//     this._commanService.updateSubMenuToggle(this.submenu);
	//     console.log("before Config", this._coreConfigService.config)
	//     this._coreConfigService.config = {
	//       layout: {menu: {collapsed: this.submenu }}
	//     }
	//     console.log("after Config", this._coreConfigService.config)
	// }
	submenuToggaleClick(): void {
		this.submenu = !this.submenu;
		this._commonService.updateSubMenuToggle(this.submenu);
		if (this.submenu) {
			this.document.body.classList.add("hasSubmenu");
		} else {
			this.document.body.classList.remove("hasSubmenu");
		}
		let obj = {};
		obj["submenu"] = this.submenu;

		this.emitSidebarDetails.emit(obj);
	}
	//   let currentSkin = JSON.parse(localStorage.getItem("config")).layout.skin;
	//   this._commanService.updateSubMenuToggle(this.submenu);
	//   console.log("before Config", this._coreConfigService.config)
	//   this._coreConfigService.config = {
	//     layout: {
	//       menu: {collapsed: this.submenu },
	//       skin: currentSkin
	//     }
	//   }
	//   console.log("after Config", this._coreConfigService.config)
	// }
	ngOnDestroy(): void {
		this.document.body.classList.remove("hasSubmenu");
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.submenu) {
			this.document.body.classList.add("hasSubmenu");
		} else {
			this.document.body.classList.remove("hasSubmenu");
		}
		this.type = "State";
	}

	onSelect = ($evt, itemid) => {
		this.selectedItem = $evt["id"];
		this.selectedSubItem = undefined;
		if ("parent_id" in $evt && $evt["parent_id"]) {
			this.selectedItem = itemid;
			this.selectedSubItem = $evt["id"];
		}
		let selectedCategory = $evt;
		let filterString =
			this.mainArray["incidentOpt"] + ":(" + $evt["filter_item"] + ")";
		let obj = {};
		obj["getTopFilter"] = false;
		obj["filterString"] = filterString;
		obj["Options"] = "Sub";
		if ($evt["filter_item"] == "All") {
			obj["getTopFilter"] = false;
			obj["filterString"] = "";
			this.emitSidebarDetails.emit(obj);
		} else {
			this.emitSidebarDetails.emit(obj);
		}
	};

	ChangeType = (id, name) => {
		this.mainArray["incidentOptType"] = name;
		//this.selectedItem = 0
		//this.selectedSubItem = 0
		//this.selectedCategory = {}
		let obj = {};
		obj["filterType"] = id;
		obj["filterOptType"] = name;
		obj["filterString"] = "";
		obj["Options"] = "Main";
		this.emitSidebarDetails.emit(obj);
	};
}
