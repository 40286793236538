import { Component, EventEmitter, Inject, OnInit, Output, ViewChild, Input } from '@angular/core';
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { TranslateService } from '@ngx-translate/core';
import { ProblemService } from 'app/servicedesk/problem/services/problem.service';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, throwError } from 'rxjs';

export interface tabOption{
  optionName:string,
  selected:boolean
}

@Component({
  selector: 'app-dns-problem-solution-sidebar',
  templateUrl: './dns-problem-solution-sidebar.component.html',
  styleUrls: ['./dns-problem-solution-sidebar.component.scss']
})
export class DnsProblemSolutionSidebarComponent implements OnInit {
  @Output("onHideProfile") onHideProfile = new EventEmitter<any>();
  tabOptions : tabOption[] = []
  selectedItem : string
  @Input() currentSolutionType : string
  @Input() options: any = [];
  @Input() problem_data: any = [];
  @Input() root_cause_id = "-1"
  @Input() root_cause_type = "solution"
  public solutionObj : any =  {}

  tagsList = [];
  stateList: string []
  statusList = [];
  serviceCatalogList = [];
  departmentList = []
  is_edit: boolean = false
  is_save_disabled = false

  public createSolution ={
      title: ''
  }

  public createSolutonData = {
      tag:"",
      state: "",
      status:"",
      serviceCatalog:"",
      department:""
  }
  rootCauseList: any = [];
  solutionList: any = [];
  disableSaveBtn: boolean;
  add_text = this._translateService.instant("UI.k_add_solution_workaround")
  edit_text = this._translateService.instant("UI.k_edit_solution_workaround")
  is_know_error: any = false;

constructor(
      private _coreSidebarService :CoreSidebarService,
      private _problemService: ProblemService,
      private _toastr: ToastrService,
      private _translateService: TranslateService,
  ) {

  }


  ngOnInit(): void {
      this.rootCauseList = this.options?.root_cause_list
      this.solutionList = this.options?.solution_type_list
      this.is_know_error = this.problem_data.is_known_error_enable
      if (this.root_cause_id != "-1"){
          this.getRootCauseSolutionData()
          this.is_edit = true
      }
  }

  hideAnaysisModal(data: any = undefined){
      this.onHideProfile.emit(data);
      this._coreSidebarService.getSidebarRegistry("app-problem-solution-sidebar").toggleOpen();
  }


onSubmit = (data) => {
      this.solutionObj.details = this.solutionObj.details.trim();
      if(this.solutionObj.details.length == 0){
          return;
      }
  if (data.form.valid === true) {
          this.is_save_disabled = true
          this.solutionObj["problem_id"] = this.problem_data?.problem_id
          this._problemService.saveProblemSolution(this.solutionObj).pipe(
              map((response: any) => {
                  this.disableSaveBtn = false
                  if (response) {
                  this._toastr.success(
                      this._translateService.instant(response["message"]),
                      this._translateService.instant("Message.msg_save_success"),
                      {
                      toastClass: "toast ngx-toastr",
                      closeButton: true,
                      positionClass: "toast-top-right",
                      }
                  );
                  this.hideAnaysisModal(this.problem_data)
                  } else {
                  this._toastr.error(
                      this._translateService.instant(response["message"]),
                      this._translateService.instant("Error.err_save_failed"),
                      {
                      toastClass: "toast ngx-toastr",
                      closeButton: true,
                      positionClass: "toast-top-right",
                      }
                  );
                  this.is_save_disabled = false
                  }
                  // Success
              }),
              catchError((error) => {
                  this.is_save_disabled = false
                  return throwError(error.statusText);
              })
              ).subscribe();
  }
};

  getRootCauseSolutionData() {
  this._problemService.getRootCauseSolutionData(this.root_cause_id, {"type": this.root_cause_type}).subscribe(
    (response) => {
      if (response) {
        this.solutionObj = response

      }
    },
    (error) => {
      //todo
    }
  ); 
  }

}
