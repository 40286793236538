import { Injectable } from "@angular/core";
import { ApiService } from "../../../common/services/api/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class WorkflowService extends ApiService {
	constructor(httpClient: HttpClient) {
		super(httpClient);
	}

	getWorkflow(payload = {}) {
		return this.get("ux/common/workflow/config/", payload);
	}

	saveWorkflowConfig(payload: any) {
		return this.post("ux/common/workflow/config/", payload);
	}

	getWorkflowById(id, payload = {}) {
		return this.get(`ux/common/workflow/config/${id}/`, payload);
	}

	getWorkflowOptionsByModuleId(id, payload = {}) {
		return this.get(`ux/common/workflow/config/options/${id}/`, payload);
	}

	searchStatus(state, keyword) {
		return this.get(`ux/common/workflow/config/status_search/${state}/${keyword}/`);
	}

	deleteworkflow(id) {
		return this.delete("ux/common/workflow/config/" + id + "/");
	}

	getApprovalOptionsByModuleId(id, payload = {}) {
		return this.get(`ux/common/workflow/config/approval_options/${id}/`, payload);
	}
}
