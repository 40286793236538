import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-update-custom-drop-down',
  templateUrl: './update-custom-drop-down.component.html',
  styleUrls: ['./update-custom-drop-down.component.scss']
})
export class UpdateCustomDropDownComponent implements OnInit {

  @Input() panelData;
  @Input() panelConfig;
  @Input() releaseManager;
  @Input() assignee;
  @Input() status;
  @Input() priority;
  @Input() risk;
  @Input() releaseType;
  @Input() dueDate;
  @Output() onManagersChangeStatus: EventEmitter<any> = new EventEmitter<any>();
  @Output() onStatusesChangeStatus: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBadgesChangeStatus: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDatesChangeStatus: EventEmitter<any> = new EventEmitter<any>();
  isOpen:boolean = false

  
  statusName: any;
  statusBg: any;
  statusColor: any;

  priorityName: any;
  priorityIcon: any;
  priorityBg: any;
  priorityColor: any;

  riskName: any;
  riskIcon: any;
  riskBg: any;
  riskColor: any;

  managerName:any;
  assigneeName: any;

  defaultDueDate: any;

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.isOpenDrowHandler(2,event);
    event.stopPropagation()  
  }

  constructor() { }

  ngOnInit(): void {


    // console.log("panel cofig", this.panelConfig.dropdown_section);


    this.statusName = this.panelData.status.name;
    this.statusBg = this.panelData.status.background_color;
    this.statusColor = this.panelData.status.color;

    this.priorityName = this.panelData.priority_name;
    this.priorityBg = this.panelData.priority_color;
    this.priorityIcon = 'fa-angles-up';
    this.priorityColor = this.panelData.priority_color;

    this.riskName = this.panelData.priority_name;
    this.riskBg = this.panelData.priority_color;
    this.riskIcon = 'fa-angles-up';
    this.riskColor = this.panelData.priority_color;

    this.managerName = this.panelData.created_by_name;
    this.assigneeName = this.panelData.requester_name;
  }

  nameExtractorHandler(data:any){
    return data
      ?.split(' ')
      .map(n => n[0])
      .join('');
  }

  isOpenDrowHandler(id, event){
    // this.isOpen = !this.isOpen
    if(id == 1) {
      this.isOpen = !this.isOpen;
      event.stopPropagation()
    } else {
      this.isOpen = false;
    }
  }

  statusUpdateHandler(item){
    this.isOpen = false;
    this.statusName = item.name;
    this.statusBg = item.background_color;
    this.statusColor = item.color
  }

  priorityUpdate(item){
    this.isOpen = false;
    this.priorityName = item.name;
    this.priorityBg = item.color;
    this.priorityIcon = item.icon;
    this.priorityColor = item.color_hex;
  }

  riskUpdate(item){
    this.isOpen = false;
    this.riskName = item.name;
    this.riskBg = item.color;
    this.riskIcon = item.icon;
    this.riskColor = item.color_hex;
  }

  managerUpdate(item){
    this.isOpen = false;
    this.managerName = item.name;
  }

  assigneeUpdate(item){
    this.isOpen = false;
    this.assigneeName = item.name;
  }

  onChangeData(evt: any){
    this.onManagersChangeStatus.emit(evt);
  }

  onStatusChangeEdit(data){
    this.onStatusesChangeStatus.emit(data);
	};

  statusBadgeChange(data){
    this.onBadgesChangeStatus.emit(data);
	}

  isSelectedDate(evt: any){
    this.onDatesChangeStatus.emit(evt);
  }

  

}
