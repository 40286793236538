import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { hmrBootstrap } from "./hmr";

if (environment.production) {
	enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.management) {
	const scriptEl = window.document.createElement("script");
	if (environment.on_premise) {
		scriptEl.src = "assets/js/chargebee.js";
	} else {
		scriptEl.src = "https://js.chargebee.com/v2/chargebee.js";
	}
	window.document.body.appendChild(scriptEl);
}

if (environment.hmr) {
	if (module["hot"]) {
		hmrBootstrap(module, bootstrap);
	} else {
		console.error("HMR is not enabled for webpack-dev-server!");
		//console.log("Are you using the --hmr flag for ng serve?");
	}
} else {
	bootstrap().catch((err) => {
		console.log(err);
	});
}
