import { Component, OnInit, Input } from '@angular/core';
import { NgEventBus } from "ng-event-bus";

@Component({
  selector: 'app-problem-count',
  templateUrl: './problem-count.component.html',
  styleUrls: ['./problem-count.component.scss']
})
export class ProblemCountComponent implements OnInit {
  @Input() widgetData: any;
	@Input() modifiedtime: any;
	config: any;

  constructor(
    private eventBus: NgEventBus
  ) { }

  ngOnInit(): void {
    this.config = this.widgetData?.data
  }

  showProbSidebar(dat){
		if (dat?.count > 0 && dat?.item_filter){
			let payload = {
				"title": "Problems - " + dat.name,
				"app_type": "prob-grid",
				"item_filter": dat.item_filter,
			}
			// console.log("in req count widget -> ", payload)
			this.eventBus.cast('dashboard-view:sidebar', payload);
		}
	}

}
