import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import store from "store";

@Component({
	selector: "app-notify-banner",
	templateUrl: "./notify-banner.component.html",
	styleUrls: ["./notify-banner.component.scss"],
})
export class NotifyBannerComponent implements OnInit {
	@Input() rem_days: any;
	// Type 1: For Trail/prod  2: Mail Count
	@Input() banner_type = 1;
	@Input() banner_msg: string;
	@Output() clsNotification = new EventEmitter();
	currentMsgToParent: boolean;
	public isTrial: boolean = false;
	constructor(private _translateService: TranslateService) {}
	ngOnInit(): void {
		this.isTrial = store.get("is_trial");
	}
	closeNotification() {
		this.currentMsgToParent = false;
		this.clsNotification.emit(this.currentMsgToParent);
	}
}
