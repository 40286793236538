import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { _deepCopyJson } from "app/common/utils/utils";

@Component({
	selector: "app-dns-msp-customer-dropdown",
	templateUrl: "./dns-msp-customer-dropdown.component.html",
	styleUrls: ["./dns-msp-customer-dropdown.component.scss"],
})
export class DnsMspCustomerDropdownComponent implements OnInit {
	@Input() customerDropDownList: Array<any>;
	@Input() bindValue: any;
	@Input() readonly: Boolean;
	@Input() placeholder: String = "Select Customer or entities";
	@Output() onOptionSelect = new EventEmitter<any>();
	public bindId;
	copyCustomerDropDownList: any;
	selected_customer: any;

	constructor() {}

	ngOnInit(): void {
		this.copyCustomerDropDownList = _deepCopyJson(this.customerDropDownList);
		let logo = localStorage.getItem("imglogo");
		let icon = localStorage.getItem("imgicon");
		this.customerSelection();
	}

	ngOnChanges(changes: any) {
		this.copyCustomerDropDownList = _deepCopyJson(this.customerDropDownList);
		this.customerSelection();
	}

	onOptionSelectEvent(event) {
		this.onOptionSelect.emit(this.selected_customer);
	}

	customerSelection() {
		let customer_id = this.bindValue?.customer_id;
		let customer_entity_id = this.bindValue?.customer_entity_id;
		let nullList = ["", null, undefined];
		if (!nullList.includes(customer_entity_id) && !nullList.includes(customer_id)) {
			this.selected_customer = this.customerDropDownList.find(
				(cust) => cust?.customer_entity_id == customer_entity_id && cust?.customer_id == customer_id
			);
		} else if (!nullList.includes(customer_id)) {
			this.selected_customer = this.customerDropDownList.find(
				(cust) => cust?.customer_id == customer_id && !("customer_entity_id" in cust)
			);
		}
		if(["", null, undefined].includes(this.bindValue)){
			this.selected_customer = null
		}
	}

	searchCustomerEvent(event) {
		if (event) {
			let filter = [];
			let lowerEvent = event.toLowerCase(); // Convert search event to lowercase
			this.copyCustomerDropDownList.forEach((e) => {
				const lowerCustomerName = e.customer_name.toLowerCase();
				const lowerCustomerCode = e.customer_code.toLowerCase();
				const lowerEntityName = (e.entity_name || "").toLowerCase();
				const lowerEntityCode = (e.entity_code || "").toLowerCase();
				if (
					lowerCustomerName.indexOf(lowerEvent) > -1 ||
					lowerCustomerCode.indexOf(lowerEvent) > -1 ||
					lowerEntityName.indexOf(lowerEvent) > -1 ||
					lowerEntityCode.indexOf(lowerEvent) > -1
				) {
					filter.push(e);
				}
			});
			if (filter.length > 0) {
				// Check if any results were found
				this.customerDropDownList = [...filter];
			} else {
				this.customerDropDownList = [...[]]; // No results found, clear the list
			}
		} else {
			this.customerDropDownList = [...this.copyCustomerDropDownList];
		}
	}

	onImgError(item) {
		item["showAvatar"] = true;
	}
}
