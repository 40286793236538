import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CoreConfigService } from '@core/services/config.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-stage-representation',
  templateUrl: './stage-representation.component.html',
  styleUrls: ['./stage-representation.component.scss']
})
export class StageRepresentationComponent implements OnInit {
  public stageArray = []
  invalidStageArray = [];
  public incidentTab = false;
  @Input() inputConfig: any;
  @Input() disableConfig: any;
  @Output() onActionCallback: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _toastr: ToastrService,
    private _activatedRoute: ActivatedRoute


  ) {
  }

  /**
 * On init
 */
  ngOnInit(): void {
    const sub = this._activatedRoute.url.subscribe((params) => {
      if(params[0].path === 'incident-edit') {
        if(this.inputConfig) {
          this.stageArray = this.inputConfig;
          this.incidentTab = true;
        }
       
      } else {
        if(this.inputConfig) {
          this.stageArray = this.inputConfig;
          this.incidentTab = true;
        }
      }
      
		});
    
  }

  stageSelection(item, subitem, dropdownValue) {
    if (this.invalidStageArray.length === 0) {
      // this.stageArray.forEach((e) => {
      //   if (e.id === item.id) {
      //     e.active = true;
      //   }
      // });
      if (subitem?.value != undefined){
        subitem?.callback(subitem?.value) 
        // this.onActionCallback.emit(subitem) 
      }
    }
  }

  subStageSelection(item, subitem, dropdownValue) {
    if (this.invalidStageArray.length === 0) {
      // this.stageArray.forEach((e) => {
      //   if (e.id === item.id) {
      //     e.active = true;
      //   }
      // });
      if (subitem?.callback != undefined){
        subitem?.callback(dropdownValue) 
        // this.onActionCallback.emit(subitem) 
      }
    }
  }

  checkValidation(item) {
    this.invalidStageArray = [];
    this.stageArray.forEach((ele, index) => {
      if (ele.code < item.code && ele.active === false) {
        this.invalidStageArray.push(ele);
      }

    });
    if (this.invalidStageArray.length > 0) {
      this.displayToastMsg();
    }
  }

  displayToastMsg = (): void => {
    setTimeout(() => {
      this._toastr.info(
        'Please select previous state.',
        'Information !',
        {
          toastClass: 'toast ngx-toastr custom-toast',
          closeButton: true,
          positionClass: 'toast-top-right',
        }
      );
    }, 1000);
  }



}


