<div class="vh-100">
    <div class="row">
        <div class="col-6">
            <div class="form-group">
                <label for="statusColor">Status color</label>
                <input type="color" class="form-control" id="statusColor">
            </div>
        </div>
        <div class="col-6">
            <div class="form-group">
                <label for="statusSequence">Sequence</label>
                <input type="text" class="form-control" id="statusSequence">
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label for="statusStage">Stage</label>
                <ng-select [items]="options.stages" [(ngModel)]="statusData.stage" bindLabel="name" bindValue="id" name="statusStage" id="statusStage">
                </ng-select>
            </div>
        </div>
    </div>
    <label>Workflow State</label>
    <div class="row">
        <div class="col-6">
            <div class="custom-control custom-control-primary custom-switch">
                <input type="checkbox" class="custom-control-input" id="customSwitchEntry" />
                <label class="custom-control-label" for="customSwitchEntry">Enter</label>
            </div>
        </div>
        <div class="col-6">
            <div class="custom-control custom-control-primary custom-switch">
                <input type="checkbox" class="custom-control-input" id="customSwitchExit" />
                <label class="custom-control-label" for="customSwitchExit">Exit</label>
            </div>
        </div>
    </div>
    <label>Stage</label>
    <div class="row">
        <div class="col-6">
            <div class="custom-control custom-control-primary custom-switch">
                <input type="checkbox" class="custom-control-input" id="customSwitchResponse" />
                <label class="custom-control-label" for="customSwitchResponse">Response</label>
            </div>
        </div>
        <div class="col-6">
            <div class="custom-control custom-control-primary custom-switch">
                <input type="checkbox" class="custom-control-input" id="customSwitchResolution" />
                <label class="custom-control-label" for="customSwitchResolution">Resolution</label>
            </div>
        </div>
    </div>
</div>
